module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n  <div class="header-menu">\n      <div class=\'header__item-wrapper pull-right fixed-header-menu\' id=\'user-dropdown\'>\n        <div class=\'header__userpic\' id=\'user-icon\'>\n          ');
    
      if (this.avatarUrl) {
        __out.push('\n            <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'header__userpic-img\'>\n          ');
      } else {
        __out.push('\n            <i class=\'clients__client-status-icon icon icon-smile in-blue-400\'></i>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class=\'header__dropdown-menu-wrapper\'>\n          <ul class=\'header__dropdown-menu m-b-0\'>\n            <li>\n              <button type="button" class=\'sign-out-js\'>\n                <icon class="icon icon-signout"></icon>\n                Sign Out\n              </button>\n            </li>\n          </ul>\n        </div>\n      </div>\n      <a class=\'header-logo-sign-up\' href=\'/\'>\n        <img src="');
    
      __out.push(__sanitize(configData.logoURL));
    
      __out.push('" class="width-80px-important">\n      </a>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}