import { QueryKey } from '@src/constants/query_keys';
import {
  getReportServiceQBOLineItemDetails,
  IReportServiceQBOLineItemDetailsParams,
  IReportServiceQBOLineItemDetailsResponse,
} from '@src/requests/report_service/report_service_qbo_line_item_details';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

const useGetReportServiceQBOLineItemDetailsQuery = createUseGetInfiniteCollection<
  IReportServiceQBOLineItemDetails,
  IReportServiceQBOLineItemDetailsParams,
  IReportServiceQBOLineItemDetailsResponse
>({
  queryKey: QueryKey.reportServiceQboLineItemDetails,
  request:  getReportServiceQBOLineItemDetails,
});

export {
  useGetReportServiceQBOLineItemDetailsQuery,
};
