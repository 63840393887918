module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-inline-flex">\n  <a class="btn dropdown-toggle in-grey-800 import-action" type="button" id="import-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <span class="icon icon-3-dots font-24"></span>\n  </a>\n  <ul class="import-dropdown dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="import-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    ');
    
      if (this.state === 'error' || this.state === 'uploaded') {
        __out.push('\n    <li><a tabindex="-1" class="map-columns-btn" href="/businesses/');
        __out.push(__sanitize(this.business.id));
        __out.push('/banking_accounts/transaction_imports/');
        __out.push(__sanitize(this.id));
        __out.push('/map_columns">\n      <i class="fa fa-random font-18 m-r-18"></i>Map Columns\n    </a></li>\n    <li><a tabindex="-1" class="re-upload-btn"><i class="icon icon-undo font-18 m-r-18"></i>Re-upload CSV</a></li>\n    ');
      }
    
      __out.push('\n    <li><a tabindex="-1" class="download-btn"><i class="icon icon-download font-18 m-r-18"></i>Download CSV</a></li>\n    <li><a tabindex="-1" class="danger-link delete-btn"><i class="icon icon-trashcan font-18 m-r-18"></i>Delete CSV</a></li>\n  </ul>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}