module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="business-select-group">\n  <div class="relative">\n    <div class="business-select ');
    
      if (this.is_readonly || this.is_business_readonly) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' display-flex align-items-center dropdown-toggle" data-toggle="dropdown" id="select-business-dropdown">\n      ');
    
      if (this.business.business_avatar_url) {
        __out.push('\n      <img class="business-avatar-img" src="');
        __out.push(__sanitize(this.business.business_avatar_url));
        __out.push('"/>\n      ');
      } else {
        __out.push('\n      <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
      }
    
      __out.push('\n      <span class="font-16 m-l-20 m-r-10 in-grey-1400 business-select-field" data-business_id="');
    
      __out.push(__sanitize(this.business.business_id));
    
      __out.push('">');
    
      __out.push(__sanitize(this.business.business_name));
    
      __out.push('</span>\n      <span class="m-l-14 dropdown-arrow">\n    </div>\n    ');
    
      if (!(this.is_readonly || this.is_business_readonly)) {
        __out.push('\n    <ul class="dropdown-menu m-t-5" aria-labelledby="select-business-dropdown">\n      ');
        ref = this.businesses;
        for (i = 0, len = ref.length; i < len; i++) {
          business = ref[i];
          __out.push('\n      <li\n        class="pointer display-flex align-items-center business-dropdown-item ');
          if (business.business_id === this.business.business_id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('"\n        data-business_id="');
          __out.push(__sanitize(business.business_id));
          __out.push('"\n      >\n        ');
          if (business.business_avatar_url) {
            __out.push('\n        <img src="');
            __out.push(__sanitize(business.business_avatar_url));
            __out.push('"/>\n        ');
          } else {
            __out.push('\n        <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n        ');
          }
          __out.push('\n        <span class="font-14 m-l-14 in-grey-1400">');
          __out.push(__sanitize(business.business_name));
          __out.push('</span>\n      </li>\n      ');
        }
        __out.push('\n      <li class="business-spiner text-center hidden">\n        <i class="fa fa-spin fa-spinner font-20"></i>\n      </li>\n      ');
        if (this.businesses.length === 0) {
          __out.push('\n      <li class="business-empty-results text-center">No Businesses</li>\n      ');
        }
        __out.push('\n    </ul>\n    ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (!this.is_readonly) {
        __out.push('\n  <div class="btn-remove-split delete-split-js pointer" data-index="@splitIndex">\n    &#x2715;\n  </div>\n  ');
      }
    
      __out.push('\n</div>\n<div class="split-business-wrapper">\n  <div class="split-form-group classes-container-js hidden">\n    <label class="form-label font-16">Department</label>\n    <div class="form-inline relative vendor-typeahead-wrapper">\n      <input class="form-control font-14 class-input-js" placeholder="Select Department" autocomplete="off"/>\n    </div>\n  </div>\n  <div class="split-form-group">\n    <label class="form-label font-16">Chart of Account</label>\n    <div class="form-inline relative vendor-typeahead-wrapper">\n      <input class="form-control font-14 chart-of-account-input-js" placeholder="Select Chart of Account" autocomplete="off"/>\n    </div>\n  </div>\n  <div class="split-form-group">\n    <label class="form-label font-16">Amount</label>\n    <div class="form-inline amount-input-wrapper ');
    
      if (this.split_by_percentage !== 0) {
        __out.push(__sanitize('percent-val'));
      }
    
      __out.push('">\n      <input class="form-control font-14 amount-input-js text-right" placeholder="');
    
      __out.push(__sanitize(this.split_by_percentage === 0 ? 'Amount' : 'Percent'));
    
      __out.push('" value="');
    
      __out.push(__sanitize(this.split_by_percentage === 0 ? parseFloat(this.amount || 0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      }) : this.percentage));
    
      __out.push('" autocomplete="off"/>\n    </div>\n  </div>\n  <div class="split-form-group ');
    
      __out.push(__sanitize(this.hiddenWhenDisabled('departments_classes')));
    
      __out.push('">\n    <label class="form-label font-16">Memo</label>\n    <div class="form-inline">\n      <input class="form-control font-14 memo-input-js" placeholder="Add memo" autocomplete="off"/>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}