module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog person-edit-form-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">Rename ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body">\n        <label class="m-t-22">Enter a new name</label>\n        <div class="profile-input-wrap m-b-20">\n          <input class="input-entity-name form-control" type="text" placeholder="Enter a new name" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n          <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n        <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}