module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var column, columns, i, index, j, k, len, len1, len2, ref, ref1;
    
      __out.push('\n<div class="report-item-list custom-report-item-list">\n  <div class="column-heads-wrapper font-semi-bold ');
    
      if (this.isDaily) {
        __out.push(__sanitize('thin'));
      }
    
      __out.push('">\n    <div class="head-column"></div>\n    <div class="display-flex-column footer__nav">\n      ');
    
      if (this.headerValues.length > 0 && this.headerValues[0].type) {
        __out.push('\n        <div class="main-box">\n          <div class="display-flex align-items-center flex-1">\n            ');
        ref = this.headerValues;
        for (i = 0, len = ref.length; i < len; i++) {
          column = ref[i];
          __out.push('\n              ');
          if (column.type === "title") {
            __out.push('\n                <div class="general-column">');
            __out.push(__sanitize(column.value));
            __out.push('</div>\n              ');
          } else {
            __out.push('\n                <div class="column-border-right"></div>\n              ');
          }
          __out.push('\n            ');
        }
        __out.push('\n          </div>\n        </div>\n      ');
      } else {
        __out.push('\n        ');
        ref1 = this.headerValues;
        for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
          columns = ref1[index];
          __out.push('\n          <div class="top-head main-box ');
          if (index > 0) {
            __out.push(__sanitize('column-border-top'));
          }
          __out.push('">\n            <div class="monthly-report-box">\n              ');
          for (k = 0, len2 = columns.length; k < len2; k++) {
            column = columns[k];
            __out.push('\n                ');
            if (column.type === "title") {
              __out.push('\n                  <div class="general-column">');
              __out.push(__sanitize(column.value));
              __out.push('</div>\n                ');
            } else {
              __out.push('\n                  <div class="column-border-right"></div>\n                ');
            }
            __out.push('\n              ');
          }
          __out.push('\n            </div>\n          </div>\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}