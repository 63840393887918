module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, state;
    
      __out.push('<div class="col-xs-4 p-r-0">\n  <div class="col-xs-1 p-l-20">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="check-all-js pointer" type="checkbox">\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="form-group">\n      <input type="text" class="form-control input-check-num" placeholder="Check #" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.check_num ? this.filter.check_num.trim() : ''));
    
      __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-4 p-l-0">\n    <div class="form-group">\n      <div class="vendors-region"></div>\n    </div>\n  </div>\n  <div class="col-xs-4 p-l-0">\n    <div class="form-group">\n      <input type="text" class="form-control input-account" placeholder="Account" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.account_name ? this.filter.account_name.trim() : ''));
    
      __out.push('"/>\n    </div>\n  </div>\n</div>\n\n<div id="dateFilterGroup" class="col-xs-2 p-l-0 p-r-0"></div>\n\n<div class="col-xs-4 p-l-10 p-r-0">\n  <div class="col-xs-5 p-l-0">\n    <div class="form-group">\n      <input type="text" class="form-control date-picker filter-outstanding-date" placeholder="Outstanding since" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.outstanding_date));
    
      __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-4 p-l-0">\n     <div class="form-group">\n      <input type="text" class="form-control input-check-memo" placeholder="Check Memo" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.check_memo ? this.filter.check_memo.trim() : ''));
    
      __out.push('"/>\n    </div>\n  </div>\n\n  <div class="col-xs-3 p-l-0">\n    <div class="form-group check-filter-status">\n      <select class="form-control filter-status-select">\n        ');
    
      ref = Docyt.Common.Constants.CHECK_FILTER_AVAILABLE_STATES;
      for (i = 0, len = ref.length; i < len; i++) {
        state = ref[i];
        __out.push('\n        <option value="');
        __out.push(__sanitize(state['key']));
        __out.push('">');
        __out.push(__sanitize(state['label']));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n</div>\n\n<div class="col-xs-2 p-l-0 p-r-0">\n  <div id="amountFilterGroup" class="col-xs-8 p-l-0"></div>\n\n  <div class="col-xs-4 p-l-0">\n    <div class="permission-dropdown-wrapper">\n      <div class="dropdown-toggle permission-dropdown-control" id="check-type-dropdown-toggle" data-toggle="dropdown">\n        <span class="filter-check-type-label all-selected check-type-picker-js">All</span>\n        <span class="fa fa-caret-down"></span>\n      </div>\n      <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="check-type-dropdown-toggle">\n        <li class="pointer filter-all-checks-js"><span class="m-l-38">All</span></li>\n        <li class="pointer filter-docyt-check-js">\n          <span class="icon-docyt-check-card font-24 m-r-8"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span> Docyt Check\n        </li>\n        <li class="pointer filter-manual-check-js"><span class="icon-manual-check font-14 m-r-15"></span> Manual Check</li>\n        <li class="pointer filter-self-print-check-js"><span class="icon-print font-14 m-r-15"></span> Self-Print Check</li>\n      </ul>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}