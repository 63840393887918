import React, { useCallback } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { useCreateDataExport } from '@src/hooks/queries/data_exports';
import { formatDate } from '@src/utils/date_helpers';

import Modal from '@src/components/ui/modal/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './add_data_export_modal_form';
import { IAddDataExportModalFormValues } from './schema';

import styles from './styles.module.scss';

interface IAddDataExportModalProps {
  isOpen: boolean,
  onDone: () => void,
  onCancel: () => void,
}

const AddDataExportModal = ({
  isOpen,
  onDone,
  onCancel,
}: IAddDataExportModalProps) => {
  const createDataExport = useCreateDataExport();

  const { mutate } = createDataExport;
  const handleSubmit = useCallback((data: IAddDataExportModalFormValues) => {
    let businessIds = [];
    // eslint-disable-next-line max-len
    if (data.exportType === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT
    // eslint-disable-next-line max-len
    || data.exportType === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF) {
      businessIds = data.multiBusinesses.map((item) => Number(item.value));
    } else {
      businessIds = [Number(data.businessId)];
    }

    mutate({
      businessIds,
      dataExport: {
        exportType:        data.exportType,
        startDate:         data.date?.startDate ? formatDate(data.date.startDate) : undefined,
        endDate:           data.date?.endDate ? formatDate(data.date.endDate) : undefined,
        includeOption:     data.includeOption,
        // eslint-disable-next-line max-len
        chartOfAccountIds: data.multiChartOfAccounts && data.exportType === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.PL_CHART_OF_ACCOUNTS_XLS
          ? data.multiChartOfAccounts.map((item) => item.id) : undefined,
      },
    }, {
      onSuccess: () => {
        onDone();
        window.Docyt.vent.trigger('data:export:added');
      },
    });
  }, [mutate, onDone]);
  return (
    <>
      <MutationStatus mutation={ createDataExport } />
      <Modal.Form
        className={ styles['add-data-export-modal'] }
        proceedTitle="Export Data"
        show={ isOpen }
        title="Export Data"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <Form
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useAddDataExportModal = makeUseModal(AddDataExportModal);

export {
  IAddDataExportModalProps,
  useAddDataExportModal,
  AddDataExportModal as default,
};
