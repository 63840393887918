import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { NoteIcon } from '@src/components/utils/icomoon';

interface ITransactionDetailsActionProps {
  transaction: ITransactionServiceDocument,
}

const TransactionDetailsAction = ({
  transaction,
}: ITransactionDetailsActionProps) => {
  return (
    <ActionsDropdown.Action
      href={ `/document/${transaction.documentId}` }
      icon={ <NoteIcon fontSize={ 18 } /> }
      name="Transaction Details"
    />
  );
};

export default TransactionDetailsAction;
