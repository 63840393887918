import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import {
  ITransactionServiceDocument,
  TTransactionServiceDocumentsSortColumn,
} from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './transactions_table_item';

interface IBankingAccountsTransactionsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
  transactions: ITransactionServiceDocument[],
  isAdmin: boolean,
  isTrash: boolean,
  businessId: TID,
}

const BankingAccountsTransactionsTable = ({
  query,
  sorting,
  transactions,
  isAdmin,
  isTrash,
  businessId,
}: IBankingAccountsTransactionsTableProps) => {
  return (
    <Table
      className="banking-transactions-table"
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.SelectAllHCell />
          <Table.HCell<TTransactionServiceDocumentsSortColumn>
            columnName="transaction_date"
            sorting={ sorting }
            widthPercent={ 15 }
          >
            Date
          </Table.HCell>
          <Table.HCell<TTransactionServiceDocumentsSortColumn>
            columnName="description"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Transaction Description
          </Table.HCell>
          <Table.HCell<TTransactionServiceDocumentsSortColumn>
            columnName="transaction_type"
            sorting={ sorting }
            widthPercent={ 18 }
          >
            Transaction Type
          </Table.HCell>
          <Table.HCell<TTransactionServiceDocumentsSortColumn>
            columnName="payment_account"
            sorting={ sorting }
            widthPercent={ 18 }
          >
            Banking Account
          </Table.HCell>
          <Table.HCell<TTransactionServiceDocumentsSortColumn>
            columnName="amount"
            sorting={ sorting }
            style={ { paddingRight: '30px' } }
            textAlign="right"
            widthPercent={ 12 }
          >
            Amount
          </Table.HCell>
          {
            isAdmin && (
              <Table.HCell<TTransactionServiceDocumentsSortColumn>
                className="transaction-actions-column"
                widthPercent={ 17 }
              />
            )
          }
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          transactions.map((transaction) => (
            <Item
              key={ transaction.id }
              businessId={ businessId }
              isAdmin={ isAdmin }
              isTrash={ isTrash }
              transaction={ transaction }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(BankingAccountsTransactionsTable);
