import React, { useCallback, useState } from 'react';

import { useUnmarkNoStatementAvailableBankStatement } from '@src/hooks/queries/bank_statements';
import { getBankStatementByDocytId } from '@src/requests/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { apiDateToMonth } from '@src/utils/date_helpers';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { UnverifyStatementIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IUnmarkNoStatementAvailableActionProps {
  businessId: TID,
  bankStatement: IBankStatement,
}

const UnmarkNoStatementAvailableAction = ({
  businessId,
  bankStatement,
}: IUnmarkNoStatementAvailableActionProps) => {
  const [hasNextVerifiedStatements, setHasNextVerifiedStatements] = useState<boolean>(false);
  const [hasNextApprovedStatements, setHasNextApprovedStatements] = useState<boolean>(false);

  const unmarkNoStatementAvailable = useUnmarkNoStatementAvailableBankStatement();
  const { mutate } = unmarkNoStatementAvailable;

  const handleConfirm = useCallback(() => {
    mutate(
      { id: bankStatement.id },
    );
  }, [bankStatement.id, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  const handleClickAction = useCallback(() => {
    getBankStatementByDocytId(businessId, bankStatement.docytId).then((res) => {
      setHasNextVerifiedStatements(res.bankStatement?.hasNextVerifiedStatements || false);
      setHasNextApprovedStatements(res.bankStatement?.hasNextApprovedStatements || false);

      confirmModal.open();
    });
  }, [bankStatement.docytId, businessId, confirmModal]);

  return (
    <>
      <MutationStatus mutation={ unmarkNoStatementAvailable } successMessage="Un-marked 'No Statement Available' successfully" />
      <confirmModal.Component
        text={
          hasNextVerifiedStatements || hasNextApprovedStatements ? (
            <span>
              Bank feed has been verified beyond this statement.
              { ' ' }
              If you Un-mark &quot;No Statement&quot; available for
              { ' ' }
              { `${apiDateToMonth(bankStatement.date)}` }
              , all statements beyond this month will need reverification.
            </span>
          ) : (
            <span>
              Are you sure you want to proceed and unmark
              { ' ' }
              &quot;No Statement &quot; available this statement?
            </span>
          )
        }
        title={
          hasNextVerifiedStatements || hasNextApprovedStatements
            ? 'Critical Warning' : 'Un-mark "No Statement Available"'
        }
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <UnverifyStatementIcon fontSize={ 18 } /> }
        name="Un-mark 'No Statement Available'"
        onClick={ handleClickAction }
      />
    </>
  );
};

export default UnmarkNoStatementAvailableAction;
