module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="invoice-header-region" class="docyt-id-header">\n  <div class="header-title-and-menu">\n    <div class="pull-left">\n      <div class="header-icon">\n        <i class="svg-icon-accounts-payable font-30"></i>\n      </div>\n      <div class="header-text">\n        <span class="header-breadcrumb font-16">\n          ');
    
      __out.push(__sanitize(this.business.get('display_name')));
    
      __out.push(' / Invoices & Receipts / Accounts Payable /\n        </span>\n        <span class="invoice-status-panel badge font-14 docyt-id-number">');
    
      __out.push(__sanitize(this.document.get('docyt_id')));
    
      __out.push('</span>\n        <div class="copy-tooltip-container">\n          <a class="copy-url-button pointer inline-block vertical-align-middle" data-clipboard-text="');
    
      __out.push(__sanitize(this.documentURL));
    
      __out.push('" data-placement="bottom">\n            <span class="icon icon-copy-icon font-24"></span>\n          </a>\n        </div>\n      </div>\n    </div>\n    <div id="header-menu-container" class="header-action">\n      <a class="pointer pull-right m-t-5 m-l-10 close-link close-icon-btn"><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n      <div class="document-status-container pull-left">\n        <p class="inline-block vertical-align-middle">\n          <span class="invoice-status-panel badge font-14 ');
    
      __out.push(__sanitize(this.service_document.get('label_state')));
    
      __out.push('">');
    
      __out.push(__sanitize(this.service_document.get('check_status')));
    
      __out.push('\n        </p>\n      </div>\n      <div class="header-separator pull-left"></div>\n      <div id="invoice-qbo-region" class="pull-left"></div>\n      <div id="invoice-action-region" class="m-l-10 pull-left service-document-action-region relative"></div>\n    </div>\n  </div>\n</div>\n<div class=\'flex-container\'>\n  <div class=\'clients-drop-zone\'>\n    <div class="client-main-pane pdf-client-document-wrapper">\n      <div id=\'invoice-document-detail-region\'></div>\n    </div>\n  </div>\n  <div id="invoice-document-right-side-region" class="client-document-right-side-region"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}