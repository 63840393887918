import React, { useCallback } from 'react';

import { IFilter } from '@src/types/filter';

import { IBusinessVendorsFilter } from './filter/types';

interface IHideMyVendorsProps {
  filter: IFilter<IBusinessVendorsFilter>,
}

const HideMyVendorCheckBox = ({
  filter: { data, update },
}: IHideMyVendorsProps): JSX.Element => {
  const handleChange = useCallback((e) => {
    update({ excludeBusinessVendor: e.target.checked });
  }, [update]);

  return (
    <div className="display-flex align-items-center m-t-10">
      <div className="hide-business-vendors checkbox checkbox-primary inline-block m-l-auto">
        <input
          checked={ Boolean(data?.excludeBusinessVendor) }
          className="pointer check-hide-business-vendors"
          id="hide-my-vendors"
          type="checkbox"
          onChange={ handleChange }
        />
        <label htmlFor="hide-my-vendors">Hide vendors in My Vendors List</label>
      </div>
    </div>
  );
};

export default HideMyVendorCheckBox;
