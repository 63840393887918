module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, industryClass, j, k, len, len1, len2, parentClass, ref, ref1;
    
      __out.push('<div class="text-center update-profile-container m-t-14">\n  <div class="profile-content-wrapper">\n    ');
    
      if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
        __out.push('\n      <div class="warning-content-wrapper text-left">\n        The initial verification attempt failed because the information provided did not satisfy our verification check. You can make one additional attempt by changing some or all the attributes. If we are still not able to verify your identity, we might ask you to upload additional documents.\n      </div>\n    ');
      } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["SUSPENDED"]) {
        __out.push('\n      <div class="error-content-wrapper text-left">\n        We were unable to verify your information. Please contact us at <a href="mailto:support@docyt.com">support@docyt.com</a>\n      </div>\n    ');
      } else if (this.dwollaCustomer.shouldUploadBusinessDocument()) {
        __out.push('\n      <div class="warning-content-wrapper text-left">\n        <div>The initial verification attempt failed because the information provided did not satisfy our verification check. Please upload a colored scan of the identifying document.</div>\n        ');
        if (this.dwollaCustomer.isFailedToVerifyBusiness()) {
          __out.push('\n        <div class="m-t-20 in-red-800 font-semi-bold">Document upload failed. Reason: ');
          __out.push(__sanitize(this.dwollaCustomer.docFailedDescriptionForBusiness()));
          __out.push(' </div>\n        ');
        }
        __out.push('\n        <div class="display-flex align-items-center m-t-20 m-b-20">\n          ');
        if (this.dwollaCustomer.isReviewingBusiness()) {
          __out.push('\n            <div class="document-uploaded-wrapper">Document Uploaded. Pending Review. </div>\n          ');
        } else {
          __out.push('\n            <select class="input-document-type form-control m-r-20">\n              <option value="other">EIN Letter</option>\n              <option value="other">Fictitious Business Name Statement</option>\n              <option value="other">Certificate of Assumed Name</option>\n              <option value="other">Business License</option>\n              <option value="other">Sales/Use Tax License; Registration of Trade Name</option>\n              <option value="other">EIN documentation</option>\n              <option value="other">Color copy of a valid government-issued photo ID</option>\n            </select>\n            ');
          if (!this.isUploading) {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <button type="submit" class="btn btn-browse-js ');
            __out.push(__sanitize(this.dwollaCustomer.isFailedToVerifyBusiness() ? 'btn-warning' : 'ladda-button'));
            __out.push(' fileinput-button width-200px" data-color="blue" data-style="expand-right">\n                ');
            if (this.dwollaCustomer.isFailedToVerifyBusiness()) {
              __out.push('\n                  Try Again\n                ');
            } else {
              __out.push('\n                  Browse\n                ');
            }
            __out.push('\n                <input type="file" name="files[]" multiple="" class="document-file-input-js" accept=".jpeg, .jpg, .png, .pdf">\n              </button>\n            </div>\n            ');
          } else {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n              <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                <div class=\'file-status-line\' style=\'width:0%\'></div>\n              </div>\n            </div>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        </div>\n        ');
        if (this.isFullReadMode) {
          __out.push('\n        <div class="m-t-20">- Sole Proprietorship: One or more of the following, as applicable to your sole proprietorship: Fictitious Business Name Statement; Certificate of Assumed Name; Business License; Sales/Use Tax License; Registration of Trade Name; EIN documentation (IRS-issued SS4 confirmation letter); Color copy of a valid government-issued photo ID (e.g., a driver’s license, passport, or state ID card).</div>\n        <div class="m-t-20">Other business documents that are applicable includes any US government entity (federal, state, local) issued business formation or licensing exhibiting the name of the business, or; Any business formation documents exhibiting the name of the business entity in addition to being filed and stamped by a US government entity. Examples include:</div>\n        <div class="m-t-20">- Filed and stamped Articles of Organization or Incorporation</div>\n        <div>- Sales/Use Tax License</div>\n        <div>- Business License</div>\n        <div>- Certificate of Good Standing</div>\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read less</span></div>\n        ');
        } else {
          __out.push('\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read more</span></div>\n        ');
        }
        __out.push('\n      </div>\n    ');
      }
    
      __out.push('\n    ');
    
      if (!this.isEditMode && this.dwollaCustomer.id) {
        __out.push('\n      <div class="profile-view-wrap">\n        <label class="">Legal Entity Type</label>\n        <span class="in-grey-950">\n        ');
        if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["SOLE"]) {
          __out.push('\n        Sole Proprietorship\n        ');
        } else if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["CORP"]) {
          __out.push('\n        Corporation\n        ');
        } else if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["LLC"]) {
          __out.push('\n        LLC\n        ');
        } else {
          __out.push('\n        Partnership\n        ');
        }
        __out.push('\n        </span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Legal Entity Name</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.get('business_name')));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Legal Entity Address</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.address()));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Status</label>\n        ');
        if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["VERIFIED"]) {
          __out.push('\n        <span class="font-semi-bold in-green-500">Verified</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
          __out.push('\n        <span class="font-semi-bold in-orange-300">Retry</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["SUSPENDED"]) {
          __out.push('\n        <span class="font-semi-bold in-red-800">Verification Failed</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["DOCUMENT"]) {
          __out.push('\n        <span class="font-semi-bold in-orange-300">Please upload additional documents</span>\n        ');
        }
        __out.push('\n      </div>\n      <div class="display-flex align-items-center in-blue-950 font-semi-bold pointer edit-link-js ');
        __out.push(__sanitize(this.dwollaCustomer.isEditable() ? '' : 'hidden'));
        __out.push('">\n        <span class="font-20 m-t-6"><i class="icon icon-edit_account"></i></span>\n        <span class="font-16 m-l-12">Edit information</span>\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="text-left">This information will be used for verification to activate ACH payments to your bank account. Wrong information may permanently disable ACH access.</div>\n      <div class="profile-input-wrap m-t-20 m-b-20">\n        <label class="width-40-percent text-left">Legal Entity Type</label>\n        <select class="form-control width-60-percent input-entity-type" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n          ');
        if (!this.dwollaCustomer.get('business_type')) {
          __out.push('\n          <option></option>\n          ');
        }
        __out.push('\n          <option value=');
        __out.push(__sanitize(configData.dwolla_customer_business_types["SOLE"]));
        __out.push(' ');
        if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["SOLE"]) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Sole Proprietorship</option>\n          <option value=');
        __out.push(__sanitize(configData.dwolla_customer_business_types["CORP"]));
        __out.push(' ');
        if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["CORP"]) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Corporation</option>\n          <option value=');
        __out.push(__sanitize(configData.dwolla_customer_business_types["LLC"]));
        __out.push(' ');
        if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["LLC"]) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>LLC</option>\n          <option value=');
        __out.push(__sanitize(configData.dwolla_customer_business_types["PARTNER"]));
        __out.push(' ');
        if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["PARTNER"]) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Partnership</option>\n        </select>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-t-20 m-b-20 area-businesss-js ');
        __out.push(__sanitize(this.dwollaCustomer.id ? 'hidden' : ''));
        __out.push('">\n        <label class="width-40-percent text-left">Business Classification</label>\n        <select class="form-control width-60-percent input-business-class" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n          ');
        if (!this.curParentClassId) {
          __out.push('\n          <option></option>\n          ');
        }
        __out.push('\n          ');
        ref = this.allParentClasses;
        for (i = 0, len = ref.length; i < len; i++) {
          parentClass = ref[i];
          __out.push('\n          <option value=');
          __out.push(__sanitize(parentClass.id));
          __out.push(' ');
          if (parentClass.id === this.curParentClassId) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(parentClass.name));
          __out.push('</option>\n          ');
        }
        __out.push('\n        </select>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-t-20 m-b-20 area-industry-js ');
        __out.push(__sanitize(this.dwollaCustomer.id ? 'hidden' : ''));
        __out.push('">\n        <label class="width-40-percent text-left">Industry Classification</label>\n        <select class="form-control width-60-percent input-industry-class" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n          ');
        if (!this.curIndustryClassId) {
          __out.push('\n          <option></option>\n          ');
        }
        __out.push('\n          ');
        ref1 = this.curIndustryClasses;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          industryClass = ref1[j];
          __out.push('\n          <option value=');
          __out.push(__sanitize(industryClass.id));
          __out.push(' ');
          if (industryClass.id === this.curIndustryClassId) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(industryClass.name));
          __out.push('</option>\n          ');
        }
        __out.push('\n        </select>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Legal Entity Name</label>\n        <input class="input-entity-name form-control" type="text" placeholder="Enter Legal Entity Name" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('business_name')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      ');
        if (this.dwollaCustomer.get('business_type') !== configData.dwolla_customer_business_types["SOLE"]) {
          __out.push('\n      <div class="profile-input-wrap m-b-20 ');
          __out.push(__sanitize(this.dwollaCustomer.id ? 'hidden' : ''));
          __out.push('">\n        <label class="width-40-percent text-left">EIN</label>\n        <input class="input-ein form-control" type="text" placeholder="XX - XXXXXXX" value="');
          __out.push(__sanitize(this.dwollaCustomer.get('ein')));
          __out.push('" ');
          if (!this.dwollaCustomer.isCreatingOrRetrying()) {
            __out.push(__sanitize('disabled'));
          }
          __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      ');
        }
        __out.push('\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Legal Entity Address</label>\n        <input class="input-address-street form-control" placeholder="Address Line 1" type="text" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('address1')));
        __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent"></label>\n        <input class="input-address-street2 form-control" type="text" placeholder="Address Line 2 (optional)" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('address2')));
        __out.push('">\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent"></label>\n        <div class="business-address-wrapper">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-address-city form-control" type="text" placeholder="City" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('city')));
        __out.push('">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap p-r-5">\n            <select class="input-address-state form-control">\n            ');
        for (k = 0, len2 = usaStateList.length; k < len2; k++) {
          address_state = usaStateList[k];
          __out.push('\n              <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" ');
          if (this.dwollaCustomer.get('state') === address_state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n            ');
        }
        __out.push('\n            </select>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-address-zip form-control" type="text" placeholder="Zip" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('postal_code')));
        __out.push('">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      ');
        if (this.dwollaCustomer.get('business_type') !== configData.dwolla_customer_business_types["SOLE"]) {
          __out.push('\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent p-r-30 text-left">Does any of the owners own 25% or more? *</label>\n        <div class="radio-box">\n          <div class="radio radio-inline m-r-25">\n            <input type="radio" name="type" class="pointer valid-heavy-js" tabindex="0" ');
          if (this.hasHeavyOwner) {
            __out.push(__sanitize('checked'));
          }
          __out.push('>\n            <label class="pointer">Yes</label>\n          </div>\n          <div class="radio radio-inline">\n            <input type="radio" name="type" class="pointer invalid-heavy-js" tabindex="1" ');
          if (!this.hasHeavyOwner) {
            __out.push(__sanitize('checked'));
          }
          __out.push('>\n            <label class="pointer">No</label>\n          </div>\n        </div>\n      </div>\n      ');
        }
        __out.push('\n      <div class="checkbox m-t-0 m-b-0 text-left font-12">\n        <input id="checkterm" type="checkbox" class="styled pointer accept-term-js" data-type="checkbox" ');
        __out.push(__sanitize(this.dwollaCustomer.get('is_dwolla_terms_accepted') ? 'checked' : ''));
        __out.push('>\n        <label for="checkterm">By checking this box you agree to our partner Dwolla\'s <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/tos/">Terms and Conditions</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a></label>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}