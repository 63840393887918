module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="servicemenu-region"></div>\n');
    
      if (!this.isGlobalnavigationNewUi) {
        __out.push('\n<ul class="nav nav-sidebar">\n  ');
        if (!this.isReceivableOnly) {
          __out.push('\n  <li class="hidden ');
          __out.push(__sanitize(this.tab === 'summary' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('">\n      <span class="icon-money-exchange service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">Summary</span>\n    </a>\n  </li>\n  ');
        }
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.tab === 'invoices' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/invoices?type=completed">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Invoices</span>\n      <span class="nav-item-number">');
        __out.push(__sanitize(this.service.get('count_payments')));
        __out.push('</span>\n    </a>\n  </li>\n  ');
        if (!this.isReceivableOnly) {
          __out.push('\n  <li class="');
          __out.push(__sanitize(this.tab === 'payments' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/payments">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Payments</span>\n      <span class="nav-item-number">');
          __out.push(__sanitize(this.service.get('count_receivable_payments')));
          __out.push('</span>\n    </a>\n  </li>\n  <li class="');
          __out.push(__sanitize(this.tab === 'credit-memos' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/credit-memos">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Credit Memo</span>\n      <span class="nav-item-number">');
          __out.push(__sanitize(this.service.get('count_credit_memos')));
          __out.push('</span>\n    </a>\n  </li>\n  <li class="');
          __out.push(__sanitize(this.tab === 'customers' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/customers">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Customers</span>\n      <span class="nav-item-number"></span>\n    </a>\n  </li>\n  ');
        }
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.tab === 'vendor_profile' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/vendor_profile">\n      <span class="icon-vendor-setting in-purple-200 service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">My Vendor Profile</span>\n      <span class="nav-item-number"></span>\n    </a>\n  </li>\n  <li class="');
        __out.push(__sanitize(this.tab === 'settings' ? 'active' : ''));
        __out.push('">\n    <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/settings">\n      <span class="icon-settings-green in-green-500 service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Settings</span>\n    </a>\n  </li>\n</ul>\n');
      }
    
      __out.push('\n');
    
      if (this.isGlobalnavigationNewUi) {
        __out.push('\n  <div id="servicesidebar-menu-region"></div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}