import React from 'react';

import { useUniqueDomId } from '@src/hooks/dom';
import { IUseModalProps } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';

import Actions from './actions';
import Body from './body';
import Content from './content';
import Header from './header';

interface IFormSideViewChildrenParams {
  formId: string,
}

interface IStandardSideViewProps {
  children: (params: IFormSideViewChildrenParams) => React.ReactNode,
  formId?: string,
  isOpen: IUseModalProps['isOpen'],
  isRoot?: boolean,
  doneTitle?: string,
  title: string,
  onCancel: IUseModalProps['onCancel'],
}

const FormSideView = ({
  children,
  formId,
  isOpen,
  isRoot,
  doneTitle = 'Done',
  title,
  onCancel,
}: IStandardSideViewProps): JSX.Element => {
  const uniqueFormId = useUniqueDomId('modal_form_');

  return (
    <Content isOpen={ isOpen } isRoot={ isRoot }>
      <Header title={ title } onClose={ onCancel } />
      <Body onClosed={ onCancel }>
        { children({ formId: formId ?? uniqueFormId }) }
      </Body>
      <Actions>
        <Button
          bsColor="blue"
          bsStyle="primary"
          form={ formId ?? uniqueFormId }
          type="submit"
        >
          { doneTitle }
        </Button>
      </Actions>
    </Content>
  );
};

export default React.memo(FormSideView);
