import React, { useMemo } from 'react';

import classNames from 'classnames';
import castArray from 'lodash/castArray';
import { UseQueryResult } from 'react-query';

import styles from './styles.module.scss';

interface ICollectionTableNoDataMessage extends React.ComponentPropsWithoutRef<'div'> {
  message?: React.ReactNode,
  query?: UseQueryResult<any, Error>
  | UseQueryResult<any, Error>[],
  recordsLength: number,
}

const TableSectionNoDataMessage = ({
  className,
  message,
  query,
  recordsLength,
}: ICollectionTableNoDataMessage): JSX.Element | null => {
  const isLoading = useMemo(() => {
    if (!query) return false;

    return castArray(query).some((q) => q?.isLoading || q?.isFetching);
  }, [query]);

  if (!message) return null;
  if (isLoading) return null;
  if (recordsLength !== 0) return null;

  const classes = classNames(styles['table-section-no-data-message'], className);

  return (
    <div className={ classes }>{ message }</div>
  );
};

export default React.memo(TableSectionNoDataMessage);
