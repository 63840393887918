module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountClass, coa, i, item, j, k, len, len1, len2, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<table class="table transaction-list-container bg-white">\n  <tbody>\n    ');
    
      ref = this.chart_of_accounts;
      for (i = 0, len = ref.length; i < len; i++) {
        coa = ref[i];
        __out.push('\n    <tr>\n      <td class="vertical-align-middle-important width-30px">\n        <div class="checkbox checkbox-primary">\n          ');
        if (this.type === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_METRIC']) {
          __out.push('\n            <input type="checkbox" id="revenue-metric-');
          __out.push(__sanitize(coa));
          __out.push('-checkbox" name="');
          __out.push(__sanitize(this.checkboxName));
          __out.push('" class="pointer" value="');
          __out.push(__sanitize(this.chart_of_accounts.indexOf(coa)));
          __out.push('" ');
          if (indexOf.call(this.selectionIds, coa) >= 0) {
            __out.push('checked');
          }
          __out.push('>\n            <label for="revenue-metric-');
          __out.push(__sanitize(coa));
          __out.push('-checkbox"></label>\n          ');
        } else {
          __out.push('\n            <input type="checkbox" id="revenue-coa-');
          __out.push(__sanitize(coa.id));
          __out.push('-');
          __out.push(__sanitize(this.checkboxName));
          __out.push('-checkbox" name="');
          __out.push(__sanitize(this.checkboxName));
          __out.push('" class="pointer" value="');
          __out.push(__sanitize(coa.id));
          __out.push('" ');
          if (ref1 = coa.id, indexOf.call(this.selectionIds, ref1) >= 0) {
            __out.push('checked');
          }
          __out.push(' ');
          if (ref2 = coa.id, indexOf.call(this.disabled, ref2) >= 0) {
            __out.push('disabled');
          }
          __out.push(' >\n            <label for="revenue-coa-');
          __out.push(__sanitize(coa.id));
          __out.push('-');
          __out.push(__sanitize(this.checkboxName));
          __out.push('-checkbox"></label>\n          ');
        }
        __out.push('\n        </div>\n      </td>\n      <td class="vertical-align-middle-important">\n        <p class="m-t-10">\n          ');
        if (this.type === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_METRIC']) {
          __out.push('\n            ');
          __out.push(__sanitize(coa));
          __out.push('\n          ');
        } else {
          __out.push('\n            ');
          ref3 = coa.display_name.split("-");
          for (j = 0, len1 = ref3.length; j < len1; j++) {
            item = ref3[j];
            __out.push('\n              ');
            if (coa.display_name.split("-").indexOf(item) > 0) {
              __out.push('\n                <i class=\'fa fa-caret-right\'></i>\n              ');
            }
            __out.push('\n              ');
            __out.push(__sanitize(item));
            __out.push('\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        </p>\n      </td>\n      ');
        if (this.checkboxName === 'revenue_categories') {
          __out.push('\n        ');
          if (this.accountClasses.length > 0) {
            __out.push('\n          <td class="vertical-align-middle-important">\n            <div>\n              <select class="account-class-select selectpicker" data-dropup-auto="false" data-id="');
            __out.push(__sanitize(coa.id));
            __out.push('" title="Enter Department">\n                ');
            ref4 = this.accountClasses;
            for (k = 0, len2 = ref4.length; k < len2; k++) {
              accountClass = ref4[k];
              __out.push('\n                  <option value="');
              __out.push(__sanitize(accountClass.id));
              __out.push('" ');
              if (parseInt((ref5 = this.getClassFromSelection(coa.id)) != null ? ref5.accountClass : void 0) === accountClass.id) {
                __out.push(__sanitize('selected'));
              }
              __out.push('>');
              __out.push(__sanitize(accountClass.name));
              __out.push('</option>\n                ');
            }
            __out.push('\n              </select>\n            </div>\n          </td>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n      ');
        if (this.type !== Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_METRIC']) {
          __out.push('\n        <td class="vertical-align-middle-important">\n          <select class="entry-type-select selectpicker" data-dropup-auto="false" data-id="');
          __out.push(__sanitize(coa.id));
          __out.push('" title="Select Entry Type" ');
          if (ref6 = coa.id, indexOf.call(this.disabled, ref6) >= 0) {
            __out.push('disabled');
          }
          __out.push('>\n            <option value="debit" ');
          if ((ref7 = this.getEntryTypeFromSelection(coa.id)) != null ? ref7.is_debit : void 0) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Debit</option>\n            <option value="credit"');
          if (!((ref8 = this.getEntryTypeFromSelection(coa.id)) != null ? ref8.is_debit : void 0)) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Credit</option>\n          </select>\n        </td>\n      ');
        }
        __out.push('\n    </tr>\n    ');
      }
    
      __out.push('\n  </tbody>\n</table>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}