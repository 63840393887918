import React, { useCallback } from 'react';

import classNames from 'classnames';

import { ISplitTransaction } from '@src/types/split_transactions';

import AmountInput from '@src/components/ui/form/inputs/amount_input';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface ISplitTransactionListProps {
  splittedTransaction: ISplitTransaction,
  hasBlankItems: boolean,
  onValueChange: (value: string, id: number) => void,
  onMemoChange: (value: string, id: number) => void,
  removeSplitedTransaction: (id: number) => void,
}

const SplitTransactionList = ({
  splittedTransaction,
  hasBlankItems,
  removeSplitedTransaction,
  onMemoChange,
  onValueChange,
}: ISplitTransactionListProps): JSX.Element => {
  const handleValueChange = useCallback((e) => {
    onValueChange(e, splittedTransaction.id);
  }, [onValueChange, splittedTransaction.id]);

  const handleMemoChange = useCallback((e) => {
    onMemoChange(e.target.value, splittedTransaction.id);
  }, [onMemoChange, splittedTransaction.id]);

  const onRemoveSplitedTransaction = useCallback(() => {
    removeSplitedTransaction(splittedTransaction.id);
  }, [removeSplitedTransaction, splittedTransaction.id]);

  const inputAmountClasses = classNames(
    'range-filter split-transaction-amount width-25-percent m-r-20 p-l-10 p-r-10',
    {
      'border-danger': hasBlankItems && splittedTransaction.amount === 0,
    },
  );

  const inputMemoClasses = classNames(
    'split-transaction-memo width-80-percent m-r-10 p-l-10 p-r-10',
    {
      'border-danger': hasBlankItems && splittedTransaction.memo === '',
    },
  );

  return (
    <div className="display-flex m-b-10">
      <AmountInput
        autoComplete="off"
        className={ inputAmountClasses }
        onChange={ handleValueChange }
      />
      <input
        className={ inputMemoClasses }
        type="text"
        value={ splittedTransaction.memo }
        onChange={ handleMemoChange }
      />
      <button
        type="submit"
        onClick={ onRemoveSplitedTransaction }
      >
        <TrashcanIcon fontSize={ 18 } />
      </button>
    </div>
  );
};

export {
  ISplitTransactionListProps,
  SplitTransactionList as default,
};
