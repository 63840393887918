import { useCallback, useEffect } from 'react';

import { IFayeEvent } from '@src/types/docyt_events/common';
import { camelizeKeys } from '@src/utils/transform_keys';

const useFayeChannel = <T extends IFayeEvent>(
  queueName: string | null,
  onDataReceived: (fayeEvent: T) => void,
  enabled: boolean = true,
) => {
  const handleMessageReceived = useCallback((fayeEvent) => {
    onDataReceived(camelizeKeys(fayeEvent) as T);
  }, [onDataReceived]);

  useEffect(() => {
    if (queueName && enabled) {
      window.Docyt.fayeClient.subscribe(queueName, handleMessageReceived);
    }
    return () => {
      if (queueName && enabled) {
        window.Docyt.fayeClient.unsubscribe(queueName, handleMessageReceived);
      }
    };
  }, [queueName, handleMessageReceived, enabled]);
};

export {
  useFayeChannel,
};
