import React from 'react';

import { BotNotificationIcon, CloseIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const ContextExpand: React.FC<{ onClick: () => void }> = ({ onClick, children }) => {
  return (
    <div className={ styles['context-expanded'] }>
      <div className={ styles['context-content-header'] }>
        <BotNotificationIcon fontSize={ 24 } />

        <span>DOCYT AI</span>
        <CloseIcon pointer fontSize={ 40 } inColor="grey-1050" onClick={ onClick } />
      </div>
      <div className={ styles['context-body'] }>
        { children }
      </div>
    </div>
  );
};

export default ContextExpand;
