/* eslint-disable import/order */
import '@lib/docyt_promise';

// import '@vendor_js/jquery/jquery';
import '@lib/jquery_time_ago';

import '@lib/malihu_custom_scrollbar_plugin';
import '@lib/jquery_mask_money';
import '@lib/bootstrap_multiselect';
import '@lib/ladda';
import '@lib/intl_tel_input';

import '@lib/bootstrap';
import '@lib/bootstrap_select';
import '@lib/bootstrap_datepicker';

import '@lib/chart';
import '@lib/bootstrap_typeahead';
import '@lib/imgareaselect';
import '@lib/jquery_caret';
import '@lib/jquery_atwho';
// Imported via ProvidePlugin
// import '@lib/clipboard';
import '@lib/aws_sdk';
import '@lib/toastr';
import '@lib/font_awesome';
import { loadGoogleTagManager } from '@lib/googletagmanager';
import { loadGoogleApi } from '@lib/googleapis';
import { initMixPanel } from '@lib/mixpanel';
import { loadFaye } from '@lib/faye';
import { loadRollbar } from '@lib/rollbar';

import '@assets_js/i18n/translations';

import { IConfig } from '@src/types/config';

import { loadConfigData } from './config';

import '@assets_js/docyt-client/apps/advisor_home/documents/show/document_fields/data_types/usa_state_list';
import '@assets_js/docyt-client/apps/advisor_home/documents/show/document_fields/data_types/country_list';

import '@lib/backbone_client';

import '@lib/stripe';
import '@lib/quiltt';

import '@src/styles/stubs.scss';

import '@lib/tui_calendar';
import '@lib/jsonform';
import '@lib/sortable';
import { checkGlobalFeatureEnabled } from '@lib/launch_darkly';

loadConfigData().then((config: IConfig) => {
  const gtmLoader = loadGoogleTagManager(config.google_analytics_id);
  const gapiLoader = config.google_client_id && config.google_api_key ? loadGoogleApi(config.google_client_id, config.google_api_key) : null;
  const mixPanelLoader = initMixPanel(config.mixpanel_token);
  const fayeLoader = loadFaye(config.fayeHost);

  if (config.env != 'development') {
    loadRollbar(config.rollbar_access_token, config.env);
  }

  return Promise.all([gtmLoader, gapiLoader, mixPanelLoader, fayeLoader]);
}).then(() => {
  // eslint-disable-next-line global-require
  require('@src/docyt_client');

  checkGlobalFeatureEnabled();

  $(() => { window.Docyt.start(); });
  window.App = window.App || {};
});
