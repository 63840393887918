import { useQuery, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetReportBySlugParams,
  ICreateReportParams,
  IUpdateReportParams,
  IReportsFileParams,
  IReportsFileResponse,
  ICopyMappingParams,
  IResetDefaultMappingParams,
  IGetReportLastUpdatedDateParams,
  IGetReportLastUpdatedDateResponse,
  getReportById,
  getReportBySlug,
  getReports,
  getLightReportsList,
  getReportServiceAccess,
  createReport,
  updateReport,
  exportReport,
  getReportAvailableBusinesses,
  copyReportMapping,
  resetDefaultMapping,
  getReportTemplates,
  refreshReport,
  deleteReport,
  getReportLastUpdatedDate,
  getReportBudgetComparison,
  IAdvancedReportsFileParams,
  exportReportData,
  getReportBudgetComparerIds,
  IGetReportByBusinessParams,
  getReportByBusinessId,
} from '@src/requests/report_service/custom_reports';
import { getReportServiceByBusinessId } from '@src/requests/report_service/report_services';
import { IBusiness } from '@src/types/businesses';
import { TID, TMongoID } from '@src/types/common';
import { IReport, IReportBudgetComparison, IReportServiceAccess, ITemplate } from '@src/types/report_service/report';

const useGetReportById = (reportId: TMongoID) => {
  return useQuery<IReport, Error>(
    [QueryKey.report, reportId],
    () => getReportById(reportId),
    {
      enabled: !!reportId,
    },
  );
};

const useGetReportByBusinessId = (params: IGetReportByBusinessParams, enabled: boolean) => {
  return useQuery<IReport[], Error>(
    [QueryKey.businessReports, params],
    () => getReportByBusinessId(params),
    {
      enabled,
    },
  );
};

const useGetReportBySlug = (params: IGetReportBySlugParams) => {
  return useQuery<IReport, Error>(
    [QueryKey.report, params],
    () => getReportBySlug(params),
    {
      enabled: !!params.businessId && !!params.slug,
    },
  );
};

const useGetReports = (reportServiceId: TID) => {
  return useQuery<IReport[], Error>(
    [QueryKey.reports, reportServiceId],
    () => getReports(reportServiceId),
  );
};

const useGetLightReportsList = (reportServiceId: TID) => {
  return useQuery<IReport[], Error>(
    [QueryKey.reportsList, reportServiceId],
    () => getLightReportsList(reportServiceId),
    {
      refetchOnMount: false
    }
  );
};

const useGetReportServiceAccess = (businessId: TID) => {
  return useQuery<IReportServiceAccess, Error>(
    [QueryKey.reportServiceAccess, businessId],
    () => getReportServiceAccess(businessId),
    {
      refetchOnMount: false
    }
  );
};

const useCreateReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IReport, Error, ICreateReportParams>(
    createReport,
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([QueryKey.reportsList, params.reportServiceId]);
      },
    },
  );
};

const useUpdateReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IReport, Error, IUpdateReportParams>(
    updateReport,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(QueryKey.report);
        queryClient.setQueriesData<IReport[]>(
          QueryKey.reportsList,
          (
            data: IReport[] = [],
          ) => {
            return data.map((report) => {
              if (report.id === response.id) {
                return { ...report, ...response };
              }
              return report;
            });
          },
        );
      },
    },
  );
};

const useRefreshReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IReport, Error, string>(
    refreshReport,
    {
      onSuccess: (response) => {
        queryClient.setQueriesData<IReport[]>(
          QueryKey.reportsList,
          (
            data: IReport[] = [],
          ) => {
            return data.map((report) => {
              if (report.id === response.id) {
                return { ...report, ...response };
              }
              return report;
            });
          },
        );
      },
    },
  );
};

const useDeleteReport = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    deleteReport,
    {
      onSuccess: (_, reportId) => {
        queryClient.setQueriesData<IReport[]>(
          QueryKey.reportsList,
          (data: IReport[] = []) => data.filter((report) => report.id !== reportId),
        );
      },
    },
  );
};

const useExportReportAsExcel = () => {
  return useMutation<IReportsFileResponse, Error, IReportsFileParams>(exportReport);
};

const useExportReportData = () => {
  return useMutation<IReportsFileResponse, Error, IAdvancedReportsFileParams>(exportReportData);
}

const useGetReportAvailableBusinesses = (templateId: string) => {
  return useQuery<IBusiness[], Error>(
    [QueryKey.reportAvailableBusiness, templateId],
    () => getReportAvailableBusinesses(templateId),
    {
      enabled: Boolean(templateId)
    }
  );
};

const useCopyReportMapping = () => {
  return useMutation<void, Error, ICopyMappingParams>(
    (params: ICopyMappingParams) => {
      return getReportServiceByBusinessId(params.currentBusinessId)
        .then((data) => copyReportMapping({ 
          currentBusinessId:  params.currentBusinessId,
          srcReportId: params.srcReportId,
          srcReportServiceId: data.id,
          tarReportId:        params.tarReportId,
          templateId:         params.templateId }));
    },
  );
};

const useResetDefaultMapping = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IResetDefaultMappingParams>(
    resetDefaultMapping,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.report);
      },
    },
  );
};

const useGetReportTemplates = (standardCategoryId: TID) => {
  return useQuery<ITemplate[], Error>(
    [QueryKey.reportTemplates, standardCategoryId],
    () => getReportTemplates(standardCategoryId),
  );
};

const useGetReportLastUpdatedDate = (params: IGetReportLastUpdatedDateParams) => {
  return useQuery<IGetReportLastUpdatedDateResponse, Error>(
    [QueryKey.reportLastUpdatedDate, params],
    () => getReportLastUpdatedDate(params),
  );
};

const useGetBudgetsComparison = (params: {report_service_id: TID; 'report_comparer_ids[]': string[]}) => {
  return useQuery<IReportBudgetComparison[], Error>(
    [QueryKey.reportBudgetComparison, params],
    () => getReportBudgetComparison(params),
    {
      enabled: params['report_comparer_ids[]'].length !== 0
    }
  )
}

const useGetReportBudgetComparerIds = (reportId: string, params: {from: string; to: string}) => {
  return useQuery<string[], Error>(
    [QueryKey.reportBudgetComarerIds, params],
    () => getReportBudgetComparerIds(reportId, params),
    {
      enabled: params.from !== '' && params.to !== ''
    }
  )
}

export {
  useGetReportById,
  useGetReportByBusinessId,
  useGetReportBySlug,
  useGetReports,
  useGetLightReportsList,
  useGetReportServiceAccess,
  useCreateReport,
  useUpdateReport,
  useRefreshReport,
  useDeleteReport,
  useExportReportAsExcel,
  useExportReportData,
  useGetReportAvailableBusinesses,
  useCopyReportMapping,
  useResetDefaultMapping,
  useGetReportTemplates,
  useGetReportLastUpdatedDate,
  useGetBudgetsComparison,
  useGetReportBudgetComparerIds
};
