import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdateReconciliationPaymentAccount } from '@src/hooks/queries/reconciliation_payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { formatDate } from '@src/utils/date_helpers';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { WarningTriangleWhiteIcon } from '@src/components/utils/icomoon';

import { useConfirmLastFourModal } from '../confirm_last_four_modal';
import { useConfirmImportStartingDateModal } from './confirm_import_starting_date_modal/modal';
import Form from './form';
import { IAccountSettingsInput } from './schema';

interface IAccountSettingsProps {
  isOpen?: boolean,
  account: IReconciliationPaymentAccount,
  onDone: () => void,
  onCancel: () => void,
}

const AccountSettings = ({
  isOpen = true,
  account,
  onCancel,
  onDone,
}: IAccountSettingsProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [setupAccountData, setSetupAccountData] = useState<
    IAccountSettingsInput | undefined>(undefined);

  const confirmImportStartingDate = useConfirmImportStartingDateModal();
  const { openWithValue } = confirmImportStartingDate;

  const business = useBusinessContext();

  const update = useUpdateReconciliationPaymentAccount();
  const { mutate } = update;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleUpdateAccount = useCallback((data?: IAccountSettingsInput) => {
    if (data === undefined) return;

    mutate({
      accountId:  account.id,
      businessId: business.id,
      data:       {
        name:                              data.name,
        qboId:                             data.qbo.id,
        qboName:                           data.qbo.name,
        startImportDate:                   formatDate(data.startImportDate, 'YYYY/MM/DD'),
        startBalance:                      data.startBalance,
        transactionImportType:             data.transactionImportType,
        financialInstitutionBankAccountId: data.bankAccount?.bankAccountId,
        last4:                             data.last4,
        plaidStartImportDate:              data.plaidStartImportDate,
        aggregatorStartImportDate:         data.plaidStartImportDate
      },
    }, {
      onSuccess: onDone,
      onError:   handleError,
    });
  }, [account.id, business.id, handleError, mutate, onDone]);

  const confirmLast4Modal = useConfirmLastFourModal({
    onDone: () => handleUpdateAccount(setupAccountData),
  });

  const handleSubmit = useCallback((data: IAccountSettingsInput) => {
    if (!account) return;

    if (data.transactionImportType === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.AUTO
      && data.bankAccount?.bankAccountLast4 !== data.last4) {
      setSetupAccountData(data);
      confirmLast4Modal.open();
    } else {
      handleUpdateAccount(data);
    }
  }, [account, confirmLast4Modal, handleUpdateAccount]);

  const handleDone = useCallback((data: IAccountSettingsInput) => {
    if (account.transactionImportType
      === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.MANUAL
      && data.transactionImportType === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.AUTO
      && account.transactionDocumentsCount > 0 && account.lastImportDate) {
      openWithValue({ data });
    } else {
      handleSubmit(data);
    }
  }, [account, handleSubmit, openWithValue]);

  return (
    <>
      <confirmImportStartingDate.Component
        { ...confirmImportStartingDate.props }
        account={ account }
        onConfirm={ handleSubmit }
      />
      <confirmLast4Modal.Component { ...confirmLast4Modal.props } />
      <SideView.Form
        isRoot
        isOpen={ isOpen }
        title="Account Settings"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <div className="account-settings-view">
            {
              update.isLoading && <Spinner />
            }
            {
              errorMessage && (
                <SideView.Error>
                  <WarningTriangleWhiteIcon fontSize={ 18 } />
                  <div className="error-message">
                    <span>Account could not be updated.</span>
                    <span>{ errorMessage }</span>
                  </div>
                </SideView.Error>
              )
            }
            <SideView.Subtitle>{ account?.name }</SideView.Subtitle>
            <p>{ business.name }</p>

            <hr />

            { (account) && (
              <Form
                // This `key` property is required here to rebuild whole subtree on account change
                // (including rebuilding form with new values and removing child side view
                key={ account.id }
                account={ account }
                businessId={ business.id }
                formId={ formId }
                onSubmit={ handleDone }
              />
            )}
          </div>
        )}
      </SideView.Form>
    </>
  );
};

export default AccountSettings;
