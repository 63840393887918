import React, { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetRevenueReconciliation } from '@src/hooks/queries/revenue_reconciliations';
import { revenueServiceReconcilePaymentProcessorPath } from '@src/routes';
import { TUUID } from '@src/types/common';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';
import { currencyFormater3 } from '@src/utils/currency';
import { formatApiDate, formatDate, parseApiDate } from '@src/utils/date_helpers';

import { useRevenueSystemContext } from '@src/components/revenue_center/revenue_system_provider';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import { amountForPaymentProcessor } from '../utils';

interface IDepositTooltipProps {
  revenue: IRevenueServiceDocument,
  paymentProcessor: IPaymentProcessor,
  revenueReconciliationId: TUUID,
}

const DepositTooltip = ({
  revenue,
  paymentProcessor,
  revenueReconciliationId,
}: IDepositTooltipProps) => {
  const business = useBusinessContext();
  const revenueSystem = useRevenueSystemContext();
  const query = useGetRevenueReconciliation({ revenueReconciliationId });
  const delta = query.data?.delta || 0.0;
  const fistTransaction = query.data?.firstTransactionServiceDocument;
  const txnItems = useMemo(() => {
    return (query.data?.revenueReconciliationItems || [])
      .filter((item) => item.itemType === 'TransactionServiceDocument');
  }, [query.data]);

  if (txnItems.length === 0) return <SpinnerIcon spin size="2x" />;
  const txnDates = txnItems.map((p) => parseApiDate(p.date)!)
    .sort((a: Date, b: Date) => (b > a ? 1 : -1));
  const txnMaxDate = txnDates[0];
  const txnMinDate = txnDates[txnDates.length - 1];
  const txnAmounts = txnItems.map((p) => Math.abs(parseFloat(p.amount)))
    .sort((a: number, b: number) => b - a);
  const txnMaxAmount = txnAmounts[0];
  const txnMinAmount = txnAmounts[txnAmounts.length - 1];

  return (
    <div className="text-left">
      <span>
        Date:
        {' '}
        {fistTransaction && formatDate(fistTransaction.transactionDate)}
      </span>
      <br />
      <span>
        Account:
        {' '}
        {fistTransaction && fistTransaction.reconciliationPaymentAccount.name}
      </span>
      <br />
      <span>
        Description:
        {' '}
        {fistTransaction && fistTransaction.description}
      </span>
      <br />
      <span>
        Amount:
        {' '}
        {fistTransaction && currencyFormater3(fistTransaction.amount)}
      </span>
      <br />
      {
        (delta !== 0.0) && (
          <span>
            {'Cash Over or Short: '}
            {delta > 0.0 ? '(' : ''}
            { currencyFormater3(Math.abs(delta)) }
            {delta > 0.0 ? ')' : ''}
          </span>
        )
      }
      {
        (txnItems.length > 1) && (
          <div>
            <a href={
              revenueServiceReconcilePaymentProcessorPath(
                business.id,
                revenueSystem.id,
                paymentProcessor.id,
                {
                  revenueParams: {
                    endDate: {
                      gte: revenue.startDate,
                      lte: revenue.endDate,
                    },
                    [paymentProcessor.code]: {
                      val: amountForPaymentProcessor(revenue, paymentProcessor.id),
                    },
                    onlyUnreconciled: false,
                  },
                  incomeParams: {
                    transaction_date: {
                      gte: formatApiDate(txnMinDate),
                      lte: formatApiDate(txnMaxDate),
                    },
                    transaction_amount: {
                      gte: txnMinAmount,
                      lte: txnMaxAmount,
                    },
                  },
                },
              )
            }
            >
              +
              {' '}
              {txnItems.length - 1}
              {' '}
              more
            </a>
          </div>
        )
      }
    </div>
  );
};

export default React.memo(DepositTooltip);
