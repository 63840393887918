module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="dashboard-table-column width-5-percent avatar-column">\n  <a class="display-flex middle-avatar-wrapper m-l-auto m-r-auto border-gray" href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/clients/');
    
      __out.push(__sanitize(this.client_business_id));
    
      __out.push('/details">\n    ');
    
      if (this.image_url) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(this.image_url));
        __out.push('">\n    ');
      } else {
        __out.push('\n    <span class=\'icon-business-default-avatar font-48\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n    ');
      }
    
      __out.push('\n  </a>\n</div>\n<div class="dashboard-table-column width-28-percent">\n  <div class="client-name-column">\n    <div class="flex-full-width dashboard-business-name-wrapper">\n      <div>\n        <a class="font-20" href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/clients/');
    
      __out.push(__sanitize(this.client_business_id));
    
      __out.push('">\n          ');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('\n        </a>\n        <br>\n        <a class="font-14 in-grey-1100" href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/clients/');
    
      __out.push(__sanitize(this.client_business_id));
    
      __out.push('">\n          ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n        </a>\n      </div>\n      ');
    
      if (this.is_reviewed) {
        __out.push('\n      <a class="reviewed-status-badge" href="/client_central/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('">Reviewed</a>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.bookkeeping_status_blocked) {
        __out.push('\n      <a class="blocked-status-badge" href="/client_central/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('">BLOCKED</a>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.needs_update_billing) {
        __out.push('\n      <a class="update-billing-badge" href="/setup-client-business/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('?accounting_firm_business_id=');
        __out.push(__sanitize(this.business_id));
        __out.push('&step=9">Update Billing</a>\n      ');
      }
    
      __out.push('\n    </div>\n    ');
    
      if (!this.is_setup_done) {
        __out.push('\n    <a href="/setup-client-business/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('?accounting_firm_business_id=');
        __out.push(__sanitize(this.business_id));
        __out.push('">\n      <button class="btn btn-blue">Finish Setup</button>\n    </a>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div class="dashboard-table-column width-8-percent">\n  <div class="display-inline-flex">\n    ');
    
      if (this.tasks_count > 0) {
        __out.push('\n      <i class="icon icon-warning in-red-600 m-t-2"></i>\n      <a class="dashboard-default-link m-l-5 tasks-link">\n        <span class="in-blue-900 font-16">\n          ');
        __out.push(__sanitize(this.tasks_count));
        __out.push(' ');
        __out.push(__sanitize(this.tasks_count === 1 ? 'Task' : 'Tasks'));
        __out.push('\n        </span>\n      </a>\n    ');
      } else {
        __out.push('\n    -\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div class="dashboard-table-column width-8-percent">\n  ');
    
      if (this.inbox_status) {
        __out.push('\n  <a class="dashboard-default-link" href="/businesses/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('/business_inbox">\n    ');
        if (this.inbox_status.unread_docs > 0) {
          __out.push('\n    <span class="in-blue-900 font-16">\n      ');
          __out.push(__sanitize(this.inbox_status.unread_docs));
          __out.push(' ');
          __out.push(__sanitize(this.inbox_status.unread_docs === 1 ? 'Doc' : 'Docs'));
          __out.push('\n    </span>\n    ');
        } else {
          __out.push('\n    -\n    ');
        }
        __out.push('\n  </a>\n  ');
      } else {
        __out.push('\n  -\n  ');
      }
    
      __out.push('\n</div>\n<div class="dashboard-table-column width-10-percent">\n  ');
    
      if (this.accounts_payable_status) {
        __out.push('\n  <div class="m-b-0 line-height-20">\n    <a class="dashboard-default-link" href="/businesses/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('/accounts_payable/queue?type=queued">\n      ');
        if (this.accounts_payable_status.count_queued > 0) {
          __out.push('\n      <span class="in-blue-900 font-16">\n        ');
          __out.push(__sanitize(this.accounts_payable_status.count_queued));
          __out.push('\n        ');
          __out.push(__sanitize(this.accounts_payable_status.count_queued === 1 ? 'Invoice' : 'Invoices'));
          __out.push('\n      </span>\n      <br>\n      ');
        } else {
          __out.push('\n      -\n      ');
        }
        __out.push('\n    </a>\n    ');
        if (this.accounts_payable_status.count_queued > 0) {
          __out.push('\n    <div class="m-t-5 accounts-payable-dashboard-badge-wrapper" data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
          __out.push(__sanitize(this.accounts_payable_tooltip));
          __out.push('">\n      ');
          if (this.accounts_payable_status.count_unverified > 0) {
            __out.push('\n      <div class="accounts-payable-dashboard-badge unverified-counts-badge">\n        <div class="icon icon-unverified font-10 m-t-2">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n        </div>\n        <span class="badge-counter">');
            __out.push(__sanitize(this.accounts_payable_status.count_unverified));
            __out.push('</span>\n      </div>\n      ');
          }
          __out.push('\n      ');
          if (this.accounts_payable_status.count_approval > 0) {
            __out.push('\n      <div class="accounts-payable-dashboard-badge unapproved-counts-badge">\n        <div class="icon icon-unapproved font-10 m-t-2">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n        </div>\n        <span class="badge-counter">');
            __out.push(__sanitize(this.accounts_payable_status.count_approval));
            __out.push('</span>\n      </div>\n      ');
          }
          __out.push('\n      ');
          if (this.accounts_payable_status.count_ready_for_pay > 0) {
            __out.push('\n      <div class="accounts-payable-dashboard-badge unpaid-counts-badge">\n        <div class="icon icon-unpaid font-10 m-t-2">\n          <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n        </div>\n        <span class="badge-counter">');
            __out.push(__sanitize(this.accounts_payable_status.count_ready_for_pay));
            __out.push('</span>\n      </div>\n      ');
          }
          __out.push('\n    </div>\n    ');
        }
        __out.push('\n  </div>\n  ');
      } else {
        __out.push('\n  -\n  ');
      }
    
      __out.push('\n</div>\n<div class="dashboard-table-column width-10-percent">\n  ');
    
      if (this.receipt_box_status) {
        __out.push('\n  <p class="m-b-0 line-height-20">\n    <a class="dashboard-default-link" href="/businesses/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('/receipt_box/queue?type=unverified">\n      ');
        if (this.receipt_box_status.count_queued > 0) {
          __out.push('\n      <span class="in-blue-900 font-16">');
          __out.push(__sanitize(this.receipt_box_status.count_queued));
          __out.push(' Unverified</span><br>\n      ');
        } else {
          __out.push('\n      -\n      ');
        }
        __out.push('\n    </a>\n  </p>\n  ');
      } else {
        __out.push('\n  -\n  ');
      }
    
      __out.push('\n</div>\n<div class="dashboard-table-column width-11-percent">\n  <p class="m-b-0 line-height-20">\n    ');
    
      if (this.reconciliation_status) {
        __out.push('\n      <div class="status-box">\n      <a class="dashboard-default-link transaction" href="/businesses/');
        __out.push(__sanitize(this.client_business_id));
        __out.push('/reconciliation_center">\n      ');
        if (this.reconciliation_status.count_queued > 0) {
          __out.push('\n      <span class="in-blue-900 font-16">');
          __out.push(__sanitize(this.reconciliation_status.count_queued));
          __out.push(' Transactions</span><br>\n      ');
        } else {
          __out.push('\n      -\n      ');
        }
        __out.push('\n      </a>\n      </div>\n    ');
      } else {
        __out.push('\n    -\n    ');
      }
    
      __out.push('\n  </p>\n</div>\n<div class="dashboard-table-column width-10-percent">\n    <p class="m-b-0 line-height-20">');
    
      __out.push(__sanitize(this.business_admins ? this.business_admins : ''));
    
      __out.push('</p>\n</div>\n<div class="dashboard-table-column width-10-percent">\n  <div data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.account_managers_tooltip));
    
      __out.push('">\n    <p class="m-b-0 line-height-20">');
    
      __out.push(__sanitize(this.account_managers));
    
      __out.push('</p>\n  </div>\n</div>\n<div class="dashboard-table-column width-3-percent relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="clientmanagement-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="client-management-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="clientmanagement-action-dropdown-btn">\n    <a href="/client_central/');
    
      __out.push(__sanitize(this.client_business_id));
    
      __out.push('" class="dropdown-item">\n      <i class=\'icon icon-client-central font-24\'></i>\n      <span class="font-16 m-l-15">View in Client Central</span>\n    </a>\n    <a href="/settings/client_management" class="dropdown-item">\n      <img src="');
    
      __out.push(__sanitize(configData.dashboard_images.CLIENT_MANAGEMENT));
    
      __out.push('" class="img-client-management"/>\n      <span class="font-16 m-l-15">Go to Client Management</span>\n    </a>\n    <a href="/setup-client-business/');
    
      __out.push(__sanitize(this.client_business_id));
    
      __out.push('?accounting_firm_business_id=');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('" class="dropdown-item">\n      <i class=\'icon icon-setup font-24\'></i>\n      <span class="font-16 m-l-15">Business Settings</span>\n    </a>\n  </div>\n</div>\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}