module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="payment-processors-list table m-b-0">\n  ');
    
      if (this.type === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_PAYMENT_PROCESSORS_ADJUSTMENT']) {
        __out.push('\n    <thead>\n      <tr>\n        <th class="pointer relative sortable sorted-desc" data-orderby="payment_processor" data-orderdir="ASC">Payment Processor</th>\n        <th class="pointer relative sortable sorted-desc" data-orderby="class" data-orderdir="ASC">Department</th>\n        <th class="pointer relative sortable sorted-desc" data-orderby="line_item_name" data-orderdir="ASC">Report Line Item</th>\n        <th class="pointer relative sortable sorted-desc" data-orderby="entry_position" data-orderdir="ASC">Journal Entry Position</th>\n        <th style="width:50px;"></th>\n      </tr>\n    </thead>\n  ');
      }
    
      __out.push('\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}