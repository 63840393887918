import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IRevenueReconciliationModifiedFayeEvent } from '@src/types/docyt_events/revenue_reconciliation_modified';

import { useFayeChannel } from './common';

export const useRevenueSystemFayeChannel = (
  revenueServiceId: string,
  callback: TFayeEventCallback<IRevenueReconciliationModifiedFayeEvent>,
) => {
  useFayeChannel(
    `/revenue-service/${revenueServiceId}/revenue-system`,
    callback,
  );
};
