import React from 'react';

import { MultiEntityReportsContext } from '@src/hooks/contexts/multi_entity_reports_context';
import { useGetMultiEntityReportsServiceQuery } from '@src/hooks/queries/multi_entity_reports';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

const MultiEntityReportsProvider = ({ children }: { children: React.ReactNode }): JSX.Element | null => {
  const { isLoading, isError, error, data } = useGetMultiEntityReportsServiceQuery();

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Multi entity reports loaded" />;

  return (
    <MultiEntityReportsContext.Provider value={ data }>
      { children }
    </MultiEntityReportsContext.Provider>
  );
};

export default MultiEntityReportsProvider;
