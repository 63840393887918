import React, { FC, useMemo } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { amountFormatterX, buildItemTypesFromArray } from '@src/components/reconciliation_center/month_end/utils';

import AddBtn from './add_btn';
import JournalEntryItem from './journal_entry_item';
import { useTotalAmount } from './use_journal_entry_amount';
import ViewBtn from './view_btn';

import styles from '../styles.module.scss';

interface EditWithEntriesProps {
  adjustmentEntries: IAdjustmentEntry[]
}

const EditWithEntries: FC<EditWithEntriesProps> = ({ adjustmentEntries }) => {
  return (
    <>
      <div>Adjustment Journal Entry</div>
      <div className={ styles['entry-container'] }>
        <div>
          { adjustmentEntries.map((it) => <JournalEntryItem key={ it.id } item={ it } />) }
        </div>
        <AddBtn text="Add Another" />
      </div>
    </>
  );
};

const Edit = () => {
  return (
    <>
      <div>Adjustment Journal Entry</div>
      <div className={ styles['item-amount'] }>
        <span>{ amountFormatterX(0) }</span>
        <AddBtn />
      </div>
    </>
  );
};

interface ViewProps {
  adjustmentEntries: IAdjustmentEntry[]
}

const View: FC<ViewProps> = ({ adjustmentEntries }) => {
  const totalAmount = useTotalAmount(adjustmentEntries);

  return (
    <>
      <div>
        { adjustmentEntries.length }
        { ' ' }
        Adjustment Journal Entry
      </div>
      <div className={ styles['item-amount'] }>
        <span>{ amountFormatterX(totalAmount) }</span>
        <ViewBtn />
      </div>
    </>
  );
};

const AdjustmentJournalEntry = () => {
  // eslint-disable-next-line max-len
  const { adjustmentEntries, bankAccountReconciliationItems } = useBankAccountReconciliationContext();

  const { difference } = useMemo(
    () => buildItemTypesFromArray(bankAccountReconciliationItems),
    [bankAccountReconciliationItems],
  );
  const hasEntry = adjustmentEntries.length > 0;

  let content;

  if (difference.status === 'acknowledged') {
    content = <View adjustmentEntries={ adjustmentEntries } />;
  } else if (hasEntry) {
    content = <EditWithEntries adjustmentEntries={ adjustmentEntries } />;
  } else {
    content = <Edit />;
  }

  return (
    <div className={ styles['item-container'] }>
      <div className={ styles['item-title'] }>
        <div className={ styles['item-title-left'] } style={ { alignItems: hasEntry ? 'flex-start' : '' } }>
          { content }
        </div>
      </div>
    </div>
  );
};

export default AdjustmentJournalEntry;
