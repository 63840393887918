import React, { useCallback } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableService } from '@src/types/services';

import Table from '@src/components/ui/table';
import { ChatIcon, PreviewIcon, BusinessDefaultAvatarIcon, SmileIcon } from '@src/components/utils/icomoon';

import Actions from './actions';

import styles from './styles.module.scss';

interface ISettlementsTableItemProps {
  settlement: IAccountsPayableServiceDocument,
  accountsPayableServices?: IAccountsPayableService,
}

const SettlementsTableItem = ({
  settlement,
  accountsPayableServices,
}: ISettlementsTableItemProps) => {
  const handleSettlementClick = useCallback(() => {
    Backbone.history.navigate(`/document/${settlement.documentId}`, { trigger: true });
  }, [settlement]);

  const handlePreviewClick = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:preview', [settlement.documentId, settlement.settlementBusiness?.name]);
  }, [settlement]);

  const handleChatClick = useCallback(() => {
    Backbone.history.navigate(`/document/${settlement.documentId}/chat`, { trigger: true });
  }, [settlement]);

  let businessAvatar = (
    <div className="small-avatar pull-left">
      <BusinessDefaultAvatarIcon />
    </div>
  );

  if (settlement.settlementBusiness?.imageUrl) {
    businessAvatar = (
      <img
        alt="Business avatar"
        className="small-avatar"
        src={ settlement.settlementBusiness?.imageUrl }
      />
    );
  }

  let vendorAvatar = (
    <div className="small-avatar pull-left">
      <SmileIcon />
    </div>
  );

  if (settlement.settlementVendor?.imageUrl) {
    vendorAvatar = (
      <img
        alt="Vendor avatar"
        className="small-avatar"
        src={ settlement.settlementVendor?.imageUrl }
      />
    );
  }

  return (
    <Table.Row>
      <Table.SelectItemCell item={ settlement } />
      <Table.Cell
        pointer
        onClick={ handleSettlementClick }
      >
        <div className={ styles['avatar-label-col'] }>
          { businessAvatar }
          <span className="name">
            { settlement.settlementBusiness?.name || '---' }
          </span>
        </div>
      </Table.Cell>
      <Table.Cell
        pointer
        onClick={ handleSettlementClick }
      >
        <div className={ styles['avatar-label-col'] }>
          { vendorAvatar }
          <span className="name">
            { settlement.settlementVendor?.name || '---' }
          </span>
        </div>
      </Table.Cell>
      <Table.Cell>
        {
          settlement.isDocumentUploaded && (
            <PreviewIcon pointer fontSize={ 30 } onClick={ handlePreviewClick } />
          )
        }
      </Table.Cell>
      <Table.Cell pointer onClick={ handleSettlementClick }>
        { settlement.invoiceNumber || '---' }
      </Table.Cell>
      <Table.DateCell
        pointer
        date={ settlement.invoiceDate }
        onClick={ handleSettlementClick }
      />
      <Table.DateCell
        pointer
        date={ settlement.paidAt }
        onClick={ handleSettlementClick }
      />
      <Table.AmountCell
        pointer
        amount={ settlement.invoiceAmount }
        fontVariant="bold"
        onClick={ handleSettlementClick }
      />
      <Table.Cell pointer onClick={ handleChatClick }>
        <ChatIcon fontSize={ 30 } variant="empty" />
      </Table.Cell>
      <Table.Cell className="transaction-actions-column">
        <div className={ styles['action-col'] }>
          <Actions
            serviceDocument={ settlement }
            userRole={ accountsPayableServices?.businessServiceRole }
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(SettlementsTableItem);
