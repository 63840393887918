import React from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { settingsIntegrationExternalSystemsPath } from '@src/routes';

import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import { AppLinkButton } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import Panel from '@src/components/ui_v2/panel';
import { RevenueCenterLogo } from '@src/components/utils/img_icons';

import AddReportType from '../report_type/add_report_type';
import AddSystem from './add_system';

const RevenueCenterLanding = () => {
  const service = useRevenueServiceContext();

  return (
    <PageContainer sidemenu={ <Sidemenu /> }>
      <DetailsRegion>
        <DetailsRegion.Header title="Revenue System" />
        <Panel.Landing
          image={ <RevenueCenterLogo /> }
          text={
            'Docyt offers continuous reconciliation of merchant processor deposits. View your revenue in detail.'
              + ' Understand what you earn by the payment processor, by spend category, or by day.'
              + ' Connect your revenue system to get started!'
          }
        >
          <AppLinkButton
            href={ settingsIntegrationExternalSystemsPath(service.businessId) }
            variant="primary"
          >
            Connect Revenue System
          </AppLinkButton>
          <AddSystem revenueService={ service } />
          <AddReportType />
        </Panel.Landing>
      </DetailsRegion>
    </PageContainer>
  );
};

export default React.memo(RevenueCenterLanding);
