import { apiGet, apiPost } from '@src/requests/helpers';
import {
  IGlobalSearchFilterResponse,
  IGlobalSearchParams,
  ISearchResultItem,
} from '@src/types/global_search/global_search_requests';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

export interface IGlobalSearchResponse {
  meta: {
    totalCount: number,
  },
  collection: ISearchResultItem[],
}

interface IGetGlobalSearchFilterParams {
  accountType: string;
  searchType?: string;
}

const getSearchFilter = (
  params: IGetGlobalSearchFilterParams,
): Promise<IGlobalSearchFilterResponse> => {
  return apiGet('/search/api/v1/search-filter', underscoreKeys(params)).then((result) => {
    return camelizeKeys(result) as IGlobalSearchFilterResponse;
  });
};

const getSearchResults = (
  params: IGlobalSearchParams,
): Promise<IGlobalSearchResponse> => {
  return apiPost('/search/api/v1/search', underscoreKeys(params)).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.totalResults,
      },
      collection: cdata.searchDtoList as ISearchResultItem[],
    };
  });
};

export {
  IGetGlobalSearchFilterParams,
  getSearchFilter,
  getSearchResults,
};
