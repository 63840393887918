module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="document-fields m-t-20">\n  <div class="alert alert-info alert-panel self-print-check-panel">\n    <div>\n      <span class="font-12 font-bold print-check-title">Self-print check</span>\n    </div>\n    <div>\n      <span class="font-12 m-t-10">When you click to generate check, Docyt will generate a check pdf that you can print and mail.</span>\n    </div>\n    <div class="display-flex">\n      <span class="alert-button send-docyt-check-instead pointer">Send Docyt Check Instead</span>\n    </div>\n  </div>\n  ');
    
      if (this.preferred_payment_method === 'Docyt ACH') {
        __out.push('\n    <div class="alert alert-info alert-panel incorrect-payment-type-panel">\n      <div>\n        <span class="font-12 font-bold">Incorrect Payment Type</span>\n      </div>\n      <div>\n        <span class="font-12 m-t-10">This vendor prefers to be paid by ACH. Continue to pay by Docyt Check or select the button below to pay by ACH</span>\n      </div>\n      <div class="display-flex">\n        <span class="alert-button pay-vendor-ach pointer">Pay Vendor by ACH</span>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n  <div>\n    <div class="document-field-item document-field-name">Receiver/Payee:</div>\n    <div class="background-white document-field-item document-field-value">\n      <div class="value-show-wrapper visible font-16 font-semi-bold">\n        ');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('\n      </div>\n    </div>\n  </div>\n  <div>\n    <div class="document-field-item document-field-name">Check Amount:</div>\n    <div class="background-white document-field-item document-field-value">\n      <div class="value-show-wrapper visible font-16 font-semi-bold">\n        ');
    
      __out.push(__sanitize(this.total_amount));
    
      __out.push('\n      </div>\n    </div>\n  </div>\n  <div>\n    <div class="document-field-item document-field-name">Mailing Address:</div>\n    <div class="background-white document-field-item document-field-value font-16 font-semi-bold">\n      ');
    
      if (this.address) {
        __out.push('\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.name));
        __out.push('</span>\n        </div>\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.company));
        __out.push('</span>\n        </div>\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.address_line1));
        __out.push('</span>\n        </div>\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.address_line2));
        __out.push('</span>\n        </div>\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.address_city));
        __out.push(' ');
        __out.push(__sanitize(this.address.address_state));
        __out.push(' ');
        __out.push(__sanitize(this.address.address_zip));
        __out.push('</span>\n        </div>\n        <div class="document-address-name">\n          <span>');
        __out.push(__sanitize(this.address.address_country));
        __out.push('</span>\n        </div>\n        ');
        if (this.address.is_authorized) {
          __out.push('\n          <div class="document-address-name m-t-5 m-b-5">\n            <span class="vendor-address-authorized-label p-l-10 p-t-7">Authorized</span>\n          </div>\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n      <a class="pointer edit-receiver-address-link">Change Payment Address</a>\n      ');
    
      if (this.address && !this.is_authorized_payer && !this.address.is_authorized) {
        __out.push('\n        <div class="font-12 display-flex m-t-5 in-red-600 warning-wrapper">\n          <span class="icon-warning-triangle m-t-3"></span>\n          <span class="inline-block nav-item-text">Only authorized payment addresses can be used to generate Docyt Checks.</span>\n        </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n  <div>\n    <div class="document-field-item document-field-name p-t-6">Memo on check:</div>\n    <div class="background-white document-field-item document-field-value">\n      <div class="value-show-wrapper visible">\n        <input class="form-control memo-edit-input bg-grey-300" type="text" value="');
    
      __out.push(__sanitize(this.check_memo));
    
      __out.push('" maxlength="40">\n      </div>\n    </div>\n  </div>\n  <div class="check-number-wrapper">\n    <div class="document-field-item document-field-name p-t-6">Check number:</div>\n    <div class="background-white document-field-item document-field-value">\n      <div class="value-show-wrapper visible">\n        <input class="form-control check-number-edit-input bg-grey-300" type="number" value="');
    
      __out.push(__sanitize(this.last_check_number + 1));
    
      __out.push('" maxlength="20">\n      </div>\n    </div>\n  </div>\n  <div class="document-attachments">\n    <div class="document-field-item document-field-name">Attachments:</div>\n    <div class="background-white document-field-item document-field-value font-semi-bold">\n      <div class="value-show-wrapper visible font-16">\n        ');
    
      if (this.attachments.length > 0) {
        __out.push('\n          ');
        __out.push(__sanitize(this.attachments.length));
        __out.push(' Selected\n        ');
      } else {
        __out.push('\n          None\n        ');
      }
    
      __out.push('\n      </div>\n      ');
    
      if (this.has_pages) {
        __out.push('\n      <a class="pointer add-attachment-btn">Add Attachments</a>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}