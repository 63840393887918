import React from 'react';

import { useResetDefaultMappingModal } from '@src/components/business_reports/custom_reports/edit_mapping_actions/reset_default_mapping/reset_default_mapping_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import { IEditMappingLaborProps } from '../../hooks';

const BusinessReportsEditMappingResetDefaultMappingAction = ({ data }: IEditMappingLaborProps) => {
  const modal = useResetDefaultMappingModal();

  return (
    <>
      <modal.Component
        { ...modal.props }
        reportId={ data.report.id as string }
      />
      <ActionsDropdown.Action
        title="Reset to Default mapping"
        onSelect={ modal.open }
      />
    </>
  );
};

export default React.memo(BusinessReportsEditMappingResetDefaultMappingAction);
