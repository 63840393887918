import React, { useCallback } from 'react';

import { useSetTransactionServiceDocumentBankStatement } from '@src/hooks/queries/transaction_service_documents';
import { documentPath } from '@src/routes';
import { IBankStatement } from '@src/types/bank_statements';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Actions from '../actions';

import styles from '../../../styles.module.scss';

interface IBankFeedTransactionsItemProps {
  bankStatement: IBankStatement,
  transaction: ITransactionServiceDocument,
  hasEmployeeCards: boolean,
  isCreditCard: boolean,
}

const BankFeedTransactionsItem = ({
  bankStatement,
  transaction,
  hasEmployeeCards,
  isCreditCard,
}: IBankFeedTransactionsItemProps) => {
  const setBankStatement = useSetTransactionServiceDocumentBankStatement();
  const { mutate: setStatement } = setBankStatement;

  const handleChange = useCallback((e) => {
    const selected = e.target.checked;
    setStatement({
      bankStatementId: bankStatement.id,
      id:              transaction.id,
      selected,
    });
  }, [bankStatement.id, setStatement, transaction.id]);

  const handleClickItem = useCallback(() => {
    window.open(documentPath(transaction.documentId), '_blank');
  }, [transaction.documentId]);

  return (
    <Table.Row>
      <Table.Cell>
        {
          setBankStatement.isLoading ? (
            <div className="checkbox-loader checkbox-item" />
          ) : (
            <div className="checkbox checkbox-primary checkbox-item">
              <input
                checked={ transaction.bankStatement?.id === bankStatement.id || transaction.bankStatement?.state === 'verified' }
                disabled={ bankStatement.state
                   === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED
                  || transaction.bankStatement?.state
                    === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED }
                id={ String(transaction.id) }
                title="Select"
                type="checkbox"
                onChange={ handleChange }
              />
              <label
                aria-label="Select"
                htmlFor={ String(transaction.id) }
              />
            </div>
          )
        }
      </Table.Cell>
      <Table.DateCell
        pointer
        date={ transaction.transactionDate }
        onClick={ handleClickItem }
      />
      {
        hasEmployeeCards && (
          <Table.Cell pointer onClick={ handleClickItem }>
            <div className="display-flex align-items-center justify-content-between">
              <span>{ transaction.employeeCardName }</span>
              <span className="in-grey-1100">{ transaction.accountOwner }</span>
            </div>
          </Table.Cell>
        )
      }
      <Table.Cell pointer onClick={ handleClickItem }>
        { transaction.description }
      </Table.Cell>
      <Table.AmountCell
        pointer
        amount={ isCreditCard ? (-1 * Number(transaction.amount)) : (transaction.amount) }
        fontVariant="bold"
        style={ { paddingRight: '30px' } }
        onClick={ handleClickItem }
      />
      <Table.Cell className={ styles['bank-statement-actions-column'] }>
        <Actions transaction={ transaction } />
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(BankFeedTransactionsItem);
