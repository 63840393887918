/* eslint-disable import/order */
import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import {
  vendorServiceAuditLogPath,
  vendorServicePaymentPath,
  vendorServiceProfilePath,
  vendorServiceBusinessPath,
  vendorServiceTaxInfoPath,
} from '@src/routes';
import { IBusinessVendor } from '@src/types/business_vendors';
import { backboneNavigateToUrl } from '@src/utils/navigate';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import AuditLog from './audit_log/audit_log';
import Payments from './payments/payments';
import VendorLinkLedgerEditContainer from './profile/edit_link_ledger/link_ledger_edit_container';
import VendorProfileEditContainer from './profile/edit_profile/profile_edit_container';
import Profile from './profile/profile';
import TaxInfo from './tax_info/tax_info';
import { BusinessesView } from './businesses';
import styles from './styles.module.scss';
import ClaimedIcon from '@src/components/utils/icomoon/claimed';
import Tooltip from '@src/components/ui_v2/tooltip';
import { VendorConnectivityIcon, W9VendorOnIcon, W9VendorOffIcon } from '@src/components/utils/icomoon';

type TTabOption = {
  label: string,
  value: string,
  link: string,
};

const MyVendorsDetailPage = ({ vendor }: { vendor: any }) => {
  const businessId = vendor.changed.business_id;
  const vendorId = vendor.changed.id;
  const [tabsData, setTabsData] = useState<TTabOption[]>([]);
  const [editProfile, setEditProfile] = useState<boolean>(true);
  const [vendorData, setVendorData] = useState<IBusinessVendor | null>(null);
  const [editLinkLedger, setEditLinkLedger] = useState<boolean>(true);

  const [businessTabEnabled, setBusinessTabEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.VENDOR_LINKED_BUSINESSES_FLAG,
      String(vendor.businessId),
    ),
  );

  const editProfileClasses = classNames(styles['edit-vendor-profile-content'], {
    [styles.hidden]: editProfile,
  });

  const editLinkLedgerClasses = classNames(styles['edit-link-ledger'], {
    [styles.hidden]: editLinkLedger,
  });

  useEffect(() => {
    if (businessTabEnabled === undefined) {
      LDFeatureForBusinessEnabled(
        window.Docyt.Common.Constants.VENDOR_LINKED_BUSINESSES_FLAG,
        String(vendor.businessId),
      ).then((res) => {
        setBusinessTabEnabled(res);
      });
    }
  }, [
    businessTabEnabled,
    setBusinessTabEnabled,
    vendor.businessId,
  ]);

  useEffect(() => {
    if (vendor) {
      setVendorData(camelizeKeys(vendor.changed) as IBusinessVendor);

      const newTabsData: TTabOption[] = [
        {
          label: 'Payments',
          value: 'payments',
          link:  vendorServicePaymentPath(businessId, vendorId),
        },
        {
          label: 'Profile',
          value: 'profile',
          link:  vendorServiceProfilePath(businessId, vendorId),
        },
        {
          label: 'Tax info and Docs',
          value: 'tax-info',
          link:  vendorServiceTaxInfoPath(businessId, vendorId),
        },

        ...(businessTabEnabled && vendor.changed.is_global === false
          ? [
            {
              label: 'Businesses',
              value: 'business',
              link:  vendorServiceBusinessPath(businessId, vendorId),
            },
          ]
          : []),
        {
          label: 'Audit Log',
          value: 'audit-log',
          link:  vendorServiceAuditLogPath(businessId, vendorId),
        },
      ];

      setTabsData(newTabsData);
    }
  }, [vendor, businessId, vendorId, businessTabEnabled]);

  const activeTab: TTabOption | null | undefined = useMemo(() => {
    if (tabsData && tabsData.length > 0) {
      return tabsData.find((it: TTabOption) => window.location.pathname === it.link);
    }

    return null;
  }, [tabsData]);

  return (
    <Root>
      <div className={ styles['vendor-service-container'] }>
        <div className={ styles['vendor-service-content'] }>
          <div className={ styles['vendor-title-container'] }>
            <span className={ styles['label-breadcrumbs'] }>My Vendors</span>
            <DetailsPageTitle
              isSmallTitle
              className={ styles.breadcrumbs }
              title={ <span className="font-bold">{vendorData?.name}</span> }
            />
            { vendorData?.isVerified && <ClaimedIcon className={ styles['badge-icon'] } fontSize={ 12 } /> }
          </div>
          <div className={ styles['vendor-service-layout'] }>
            <div className={ styles['vendor-service-tabs'] }>
              {tabsData && tabsData.length > 0 && tabsData.map((tab: TTabOption) => (
                <Button
                  key={ tab.value }
                  className={ classNames(styles['vendor-service-tab'], {
                    [styles.active]: activeTab?.value === tab.value,
                  }) }
                  variant="ghost"
                  onClick={ () => backboneNavigateToUrl(tab.link) }
                >
                  <span title={ tab.label }>{tab.label}</span>
                </Button>
              ))}
            </div>
            <div className={ styles['vendor-status-icon'] }>
              <Tooltip.Hover
                className={ styles['icon-wrapper'] }
                content={ vendorData?.businessVendorId ? 'Connected Vendor' : 'Un-Connected Vendor' }
                popoverParams={ { showDelay: 0 } }
              >
                <VendorConnectivityIcon
                  className={ vendorData?.businessVendorId ? styles['icon-wrapper-available']
                    : styles['icon-wrapper-not-available'] }
                  fontSize={ 25 }
                />
              </Tooltip.Hover>

              <Tooltip.Hover
                className={ styles['icon-wrapper'] }
                content={ vendorData?.isW9FileUploaded ? 'W9 Info Available' : 'W9 Info Not Available' }
                popoverParams={ { showDelay: 0 } }
              >
                {vendorData?.isW9FileUploaded ? <W9VendorOnIcon fontSize={ 25 } />
                  : <W9VendorOffIcon className={ styles['icon-wrapper-not-available'] } fontSize={ 25 } /> }
              </Tooltip.Hover>
            </div>
          </div>

          {activeTab?.value === 'payments' && <Payments businessVendor={ vendorData as IBusinessVendor } />}
          {activeTab?.value === 'profile'
            && (
              <Profile
                businessId={ businessId }
                editLinkLedger={ editLinkLedger }
                editProfile={ editProfile }
                setEditLinkLedger={ setEditLinkLedger }
                setEditProfile={ setEditProfile }
                setVendorData={ setVendorData }
                vendor={ vendorData as IBusinessVendor }
              />
            )}
          {activeTab?.value === 'tax-info' && (
          <TaxInfo
            businessId={ businessId }
            vendorId={ vendorId }
          />
          )}
          {activeTab?.value === 'business' && (
            <BusinessesView
              businessId={ businessId }
              vendorId={ vendorId }
            />
          )}
          {activeTab?.value === 'audit-log' && <AuditLog />}
        </div>
        <div className={ editProfileClasses }>
          <VendorProfileEditContainer
            editProfile={ editProfile }
            setEditProfile={ setEditProfile }
            setVendorData={ setVendorData }
            vendor={ vendorData as IBusinessVendor }
          />
        </div>
        <div className={ editLinkLedgerClasses }>
          <VendorLinkLedgerEditContainer
            editLinkLedger={ editLinkLedger }
            setEditLinkLedger={ setEditLinkLedger }
            setVendorData={ setVendorData }
            vendor={ vendorData as IBusinessVendor }
          />
        </div>
      </div>
    </Root>
  );
};

export default MyVendorsDetailPage;
