import React, { useCallback, useMemo } from 'react';

import { useGetRecentDataExports } from '@src/hooks/queries/data_exports';
import { dataExportPath } from '@src/routes';

import DataExportItemAction from '@src/components/header/settings/data_export_Item_action';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '@src/components/header/styles.module.scss';

const DataExportAction = () => {
  const query = useGetRecentDataExports();

  const { refetch: refetchDataExports } = query;

  const dataExports = useMemo(() => {
    return query.data?.dataExports || [];
  }, [query.data?.dataExports]);

  const handleFocus = useCallback(() => {
    refetchDataExports();
  }, [refetchDataExports]);

  return (
    <ActionsDropdown.Submenu
      className={ styles['settings-item'] }
      title="Data Export"
      onFocus={ handleFocus }
    >
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href={ dataExportPath() }
        title="View All"
      />
      <div className="item-divider width-100-percent" role="separator" />
      <span className={ styles['settings-section'] }>
        RECENT EXPORTS
      </span>
      {
          dataExports.map((dataExport) => (
            <ActionsDropdown.Action
              key={ dataExport.id }
              className={ styles['settings-item'] }
              disabled={ dataExport.state === 'processing' }
              title={ <DataExportItemAction dataExport={ dataExport } /> }
            />
          ))
        }
    </ActionsDropdown.Submenu>
  );
};

export default DataExportAction;
