import React from 'react';

import { useSideView } from './hooks';
import Region from './region';

import styles from './styles.module.scss';

const SideViewRender = (): JSX.Element | null => {
  const { hasContent, views } = useSideView();

  if (!hasContent) return null;

  return (
    <Region>
      {
        views.map((view) => (
          <div
            key={ view.id }
            className={ styles.content }
          >
            { view.content }
          </div>
        ))
      }
    </Region>
  );
};

export default React.memo(SideViewRender);
