import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetCustomReportService } from '@src/hooks/queries/report_service/custom_report_service';
import { useGetReportServiceBudgets } from '@src/hooks/queries/report_service/report_service_budgets';
import { useSorting } from '@src/hooks/url_params';
import { TReportServiceBudgetsSortColumn } from '@src/types/report_service/report_service_budgets';
import { ISortingParams } from '@src/types/sorting';

import BlankPage from './blank_page';
import BudgetsHeader from './header';
import BudgetsList from './list';

const DefaultSorting: ISortingParams<TReportServiceBudgetsSortColumn> = {
  orderColumn:    'created_at',
  orderDirection: 'desc',
};

const Budgets = () => {
  const business = useBusinessContext();
  const reportService = useReportServiceContext();
  const sorting = useSorting<TReportServiceBudgetsSortColumn>({ defaultSorting: DefaultSorting });
  const budgetsQuery = useGetReportServiceBudgets({
    reportServiceId: reportService.id,
    ...sorting.data,
  });
  const budgets = useMemo(() => {
    const budgetsPages = budgetsQuery.data?.pages || [];
    return flatten(budgetsPages.map((p) => p.collection));
  }, [budgetsQuery.data?.pages]);

  const { data: customReportService } = useGetCustomReportService(business.id);

  return (
    <>
      <BudgetsHeader existBudget={ budgets.length !== 0 } />
      <div className="m-t-20">
        {
          budgets.length === 0 && !budgetsQuery.isLoading
            ? (
              <BlankPage />
            )
            : (
              <BudgetsList
                budgets={ budgets }
                defaultBudgetId={ customReportService?.defaultBudgetId }
                query={ budgetsQuery }
                sorting={ sorting }
              />
            )
        }
      </div>
    </>
  );
};

export default Budgets;
