import React from 'react';

import { makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import ChartOfAccounts from '../chart_of_accounts';

import styles from './styles.module.scss';

interface IChartOfAccountProps {
  selectedItem: string | null | undefined,
  setOpenChartOfAccount: (value: boolean) => void,
  handleChangeFormula: any,
  chartOfAccount: any,
  coaForMonth: boolean
}

const PercentageChartOfAccount = ({
  chartOfAccount,
  setOpenChartOfAccount,
  selectedItem,
  handleChangeFormula,
  coaForMonth,
}: IChartOfAccountProps) => {
  const onCancel = () => {
    setOpenChartOfAccount(false);
  };

  return (
    <Modal
      show
      className={ styles['import-budget-modal'] }
      title="Percentage of Chart Of Account"
    >
      <div className={ styles['import-budget-modal-body'] }>
        <Modal.Body>
          <ChartOfAccounts
            chartOfAccount={ chartOfAccount }
            coaForMonth={ coaForMonth }
            handleChangeFormula={ handleChangeFormula }
            selectedItem={ selectedItem }
            setOpenChartOfAccount={ setOpenChartOfAccount }
          />
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="Cancel"
          onClick={ onCancel }
        />
      </Modal.Footer>
    </Modal>
  );
};

const usePercentageChartModal = makeUseModal(PercentageChartOfAccount);

export {
  usePercentageChartModal,
  PercentageChartOfAccount as default,
};
