import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';

import Form from './edit_linked_businesses_form';
import { IBusinessesInput } from './schema';

interface IEditLinkedBusinessesModalProps extends Omit<IUseModalProps, 'onDone'> {
  businessId: TID,
  linkedBusinessIds: TID[],
  onDone: (businesses: IBusiness[]) => void
}

const EditLinkedBusinessesModal = ({
  businessId,
  linkedBusinessIds,
  isOpen,
  onCancel,
  onDone,
}: IEditLinkedBusinessesModalProps): JSX.Element => {
  const handleSubmit = useCallback((values: IBusinessesInput) => {
    onDone(values.businesses);
    onCancel();
  }, [onCancel, onDone]);

  return (
    <Modal.Form
      dialogClassName="edit-linked-businesses-modal"
      formId="edit_linked_businesses_form"
      proceedTitle="Save Changes"
      show={ isOpen }
      title="Edit Linked Businesses"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          businessId={ businessId }
          formId={ formId }
          linkedBusinessIds={ linkedBusinessIds }
          onSubmit={ handleSubmit }
        />
      )}
    </Modal.Form>
  );
};

const useEditLinkedBusinessesModal = makeUseModal(EditLinkedBusinessesModal);

export {
  IEditLinkedBusinessesModalProps,
  useEditLinkedBusinessesModal,
  EditLinkedBusinessesModal as default,
};
