import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const GrayDocytNetwork = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="grey-docyt-network"
    />
  );
};

export default GrayDocytNetwork;
