import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ILabelProps<T extends React.ElementType> {
  as?: T,
  variant: 'normal' | 'warning' | 'success' | 'failed' | 'info' | 'info-alt' | 'muted',
}

const Label = <T extends React.ElementType = 'span'>({
  as,
  children,
  className,
  variant = 'normal',
}: ILabelProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ILabelProps<T>>) => {
  const classes = classNames(
    styles.label,
    styles[`label-${variant}`],
    className,
  );

  const Component = as || 'span';

  return (
    <Component className={ classes }>
      { children }
    </Component>
  );
};

export default Label;
