import React, { useCallback } from 'react';

import { useInboxServiceDocumentFlag } from '@src/hooks/inbox_service_documents';
import { useSetInboxServiceDocumentFlag } from '@src/hooks/queries/inbox_service_documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import MutationStatus from '@src/components/utils/mutation_status';

interface IBusinessInboxListItemFlagStateProps {
  document: IInboxServiceDocument,
}

const BusinessInboxListItemFlagState = ({
  document,
}: IBusinessInboxListItemFlagStateProps): JSX.Element => {
  const { flag, nextFlag } = useInboxServiceDocumentFlag(document.flagState);

  const setFlag = useSetInboxServiceDocumentFlag();

  const { mutate } = setFlag;
  const handleClick = useCallback((e) => {
    e.stopPropagation();
    mutate({
      documentId: document.id,
      flagState:  nextFlag.flagState,
    });
  }, [document.id, nextFlag.flagState, mutate]);

  return (
    <>
      <MutationStatus hideSpinner mutation={ setFlag } />
      <span
        className="font-22 m-r-15 flag-state-wrapper"
        role="button"
        tabIndex={ -1 }
        onClick={ handleClick }
      >
        <flag.Component { ...flag.props } className="flag-selected" />
        <nextFlag.Component { ...nextFlag.props } className="flag-next" />
      </span>
    </>
  );
};

export default BusinessInboxListItemFlagState;
