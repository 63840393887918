import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import flatten from 'lodash/flatten';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetReconciliationPaymentAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown/dropdown';
import { useFilterContext, FilterFieldWrapper } from '@src/components/ui/filter';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Option from './payment_account_select_option';
import { ITransactionsFilter } from './types';

import styles from '../../styles.module.scss';

interface ITransactionsFilterPaymentAccountSelectProps {
  className?: string,
  widthPercent?: IUIStyleProps['widthPercent'],
}

const BlankOptionName = 'All Accounts';

const TransactionsFilterPaymentAccountSelect = ({
  className,
  widthPercent,
}: ITransactionsFilterPaymentAccountSelectProps) => {
  const { data, update } = useFilterContext<ITransactionsFilter>();

  const business = useBusinessContext();

  const accountQuery = useGetReconciliationPaymentAccounts({
    businessId:        business.id,
    noConsiderArchive: true,
  });

  const handleAllSelect = useCallback(() => {
    update({
      reconciliation_payment_account_id: undefined,
      payment_account_id:                undefined,
    });
  }, [update]);

  const handleRPASelect = useCallback((account: IReconciliationPaymentAccount) => {
    update({
      reconciliation_payment_account_id: String(account.id),
      payment_account_id:                undefined,
    });
  }, [update]);

  const handlePaymentAccountSelect = useCallback((account: IPaymentAccount) => {
    update({
      reconciliation_payment_account_id: undefined,
      payment_account_id:                String(account.id),
    });
  }, [update]);

  const accounts = useMemo(() => {
    return accountQuery.data?.reconciliationPaymentAccounts || [];
  }, [accountQuery.data?.reconciliationPaymentAccounts]);

  const selectedName = useMemo(() => {
    const reconciliationPaymentAccountId = data?.reconciliation_payment_account_id;
    if (reconciliationPaymentAccountId) {
      return accounts.find((a) => String(a.id) === reconciliationPaymentAccountId)?.name;
    }

    const paymentAccountId = data?.payment_account_id;
    if (paymentAccountId) {
      const paymentAccounts = flatten(accounts.map((a) => a.paymentAccounts));

      return paymentAccounts.find((a) => String(a.id) === paymentAccountId)?.name;
    }

    return BlankOptionName;
  }, [accounts, data?.payment_account_id, data?.reconciliation_payment_account_id]);

  const mainClass = classNames(
    className,
    'form-control nowrap flex-container align-items-center',
    { filtered: (data?.payment_account_id || data?.reconciliation_payment_account_id) },
  );

  return (
    <FilterFieldWrapper className={ className } widthPercent={ widthPercent }>
      <Dropdown
        className="display-flex relative transaction-filter-banking-account"
      >
        <div className={ mainClass }>
          { selectedName }
        </div>
        <Dropdown.LinkToggle
          noButton
          pointer
          className="date-select-toggle range-select-menu-btn"
          mb={ 6 }
        >
          <CaretIcon fontSize={ 14 } variant="down" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu pointer alignment="left" className={ styles['payment-account-dropdown-menu'] } fontSize={ 24 }>
          <Dropdown.Item className="nowrap" onSelect={ handleAllSelect }>
            All Accounts
          </Dropdown.Item>

          {
            accounts.map((account) => (
              <Option
                key={ account.id }
                account={ account }
                onPaymentAccountSelect={ handlePaymentAccountSelect }
                onReconciliationPaymentAccountSelect={ handleRPASelect }
              />
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </FilterFieldWrapper>
  );
};

export default React.memo(TransactionsFilterPaymentAccountSelect);
