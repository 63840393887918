import React from 'react';

import UnlockDashboardsImg from '@assets/images/dashboards/unlocked.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const DashboardsUnlockedIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Dashboards unlocked icon"
      src={ UnlockDashboardsImg }
      { ...props }
    />
  );
};

export default DashboardsUnlockedIcon;
