import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBusiness } from '@src/types/businesses';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IMultiEntityItemAccountValueListProps {
  query: UseInfiniteQueryResult<any, Error>,
  business: IBusiness,
  lineItemDetails: IReportServiceQBOLineItemDetails[],
}

const MultiEntityItemAccountValueList = ({
  business,
  query,
  lineItemDetails,
}: IMultiEntityItemAccountValueListProps): JSX.Element => {
  return (
    <Table
      className="multi-entity-item-values-table"
      infiniteQuery={ query }
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell textAlign="center">Business</Table.HCell>
          <Table.HCell textAlign="center">Date</Table.HCell>
          <Table.HCell textAlign="center">Transaction Type</Table.HCell>
          <Table.HCell textAlign="center">NUM</Table.HCell>
          <Table.HCell textAlign="center">Name</Table.HCell>
          <Table.HCell textAlign="center">Memo</Table.HCell>
          <Table.HCell textAlign="center">Split</Table.HCell>
          <Table.HCell textAlign="center">Amount</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          lineItemDetails.map((lineItemDetail) => {
            return (
              <Item
                key={ lineItemDetail.id }
                business={ business }
                lineItemDetail={ lineItemDetail }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default MultiEntityItemAccountValueList;
