import React from 'react';

import { ICreateChartOfAccountParams } from '@src/requests/simple_chart_of_accounts';

import Root from '@src/components/root';

import MultipleCreateCoas from './multiple_create_coas';

const EntryPoint: React.FC<{ rows: ICreateChartOfAccountParams[] }> = ({ rows }) => {
  return (
    <Root>
      <MultipleCreateCoas rows={ rows } />
    </Root>
  );
};
export default EntryPoint;
