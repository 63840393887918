import React, { useState } from 'react';

import { IReport } from '@src/types/report_service/report';
import { IReportItem } from '@src/types/report_service/report_item';

import Section from '@src/components/utils_v2/section';

import AcceptedDepartment from './accepted_department/accepted_department';
import CopyMappingActions from './actions/copy_mapping_dropdown';
import { useQueryData } from './hooks';
import MapDimension from './map_dimension/map_dimension';

import styles from './styles.module.scss';

interface IBusinessReportsEditMappingProps {
  items: IReportItem[],
  report: IReport,
  parentId: string,
  childId: string,
}

const BusinessReportsEditMapping = ({
  items,
  report,
  parentId,
  childId,
}: IBusinessReportsEditMappingProps) => {
  const data = useQueryData(report, items);
  const [department, setDepartment] = useState<{ dimensionCode: string, dimensionLabel: string }[]>([]);

  if (data.report) {
    return (
      <Section.Provider
        section={ { businessId: data.business?.id, section: 'reports-edit-mapping-labor', skipURLParams: true } }
      >
        <div className={ styles['edit-mapping-main-view'] }>
          <div className={ styles['edit-mapping-view'] }>
            <div className={ styles['accept-accounting-class-region'] }>
              <AcceptedDepartment
                childId={ childId }
                data={ data }
                parentId={ parentId }
                setDepartment={ setDepartment }
              />
            </div>
            <div className={ styles['mapping-actions-region'] }>
              <div className={ styles['mapping-actions-header-region'] }>
                <div />
                <CopyMappingActions data={ data } />
              </div>
              <div className={ styles['mapping-actions-body-view'] }>
                <MapDimension
                  childId={ childId }
                  data={ data }
                  department={ department }
                  parentId={ parentId }
                />
              </div>
            </div>
          </div>
        </div>
      </Section.Provider>
    );
  }

  return null;
};

export default React.memo(BusinessReportsEditMapping);
