import React, { useMemo } from 'react';

import startCase from 'lodash/startCase';

import { IDataExport } from '@src/types/data_exports';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';

import { newExportFormatDate } from '../details/helpers';
import Actions from './actions';

interface IDataExportsItemProps {
  dataExport: IDataExport,
}

const DataExportsItemProps = ({
  dataExport,
}: IDataExportsItemProps): JSX.Element => {
  const dataExportRevampV1Enabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.DATA_EXPORT_REVAMP_V_1_FLAG);

  const businessNames = useMemo(() => {
    const names = dataExport.businessNames.split(', ');
    if (names.length === 0) {
      return '';
    } if (names.length > 3) {
      return (
        <Tooltip
          as="span"
          className="pointer"
          title={ dataExport.businessNames }
        >
          <span>{ `${names.slice(0, 3).join(', ')}, ` }</span>
          <span style={ { color: '#0080ff' } }>
            { `${names.length - 3} more` }
          </span>
        </Tooltip>
      );
    }
    return (
      <span>{ dataExport.businessNames }</span>
    );
  }, [dataExport.businessNames]);

  return (
    <Table.Row
      className={ dataExport.state === 'processing' ? 'data-export-row-processing' : '' }
      id={ `data-export-row-${dataExport.id}` }
    >
      <Table.SelectItemCell
        item={ dataExport }
      />
      <Table.Cell
        style={ { paddingLeft: 21 } }
      >
        {dataExport.exportFileName}
      </Table.Cell>
      {!dataExportRevampV1Enabled && (
        <Table.Cell>
          { `From: ${dataExport.startDate ? dataExport.startDate : '-'} To: ${dataExport.endDate ? dataExport.endDate : '-'}` }
        </Table.Cell>
        )}
      <Table.Cell>
        {startCase(dataExport.exportType)}
      </Table.Cell>
      <Table.Cell>
        {
          dataExportRevampV1Enabled ? (
            businessNames
          ) : (
            dataExport.businessNames
          )
        }
      </Table.Cell>
      <Table.Cell>
        {dataExport.state === 'processing' ? 'Processing...' : newExportFormatDate(dataExport.createdAt)}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Actions
          dataExport={ dataExport }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default DataExportsItemProps;
