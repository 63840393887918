import findIndex from 'lodash/findIndex';

import styles from './styles.module.scss';

const focusFirstMenuItem = (menuElement: HTMLElement | null) => {
  if (!menuElement) return;

  const items = menuElement.querySelectorAll(`:scope .${styles['dropdown-menu-item']}`);
  if (items.length < 1) return;
  (items[0] as HTMLElement).focus({ preventScroll: true });
};

const focusNextMenuItem = (currentEl: HTMLElement | null) => {
  if (!currentEl) return;

  const menu = currentEl.closest(`.${styles['dropdown-menu']}`) || currentEl.parentNode;
  if (!menu) return;

  const items = menu.querySelectorAll(`:scope > .${styles['dropdown-menu-item']}`);
  const currentIdx = findIndex(items, (el) => el === currentEl);
  if (currentIdx < 0) return;

  const nextItem = items.length > currentIdx + 1 ? items[currentIdx + 1] : items[0];
  if (!nextItem) return;
  (nextItem as HTMLElement).focus();
};

const focusPreviousMenuItem = (currentEl: HTMLElement | null) => {
  if (!currentEl) return;

  const menu = currentEl.closest(`.${styles['dropdown-menu']}`) || currentEl.parentNode;
  if (!menu) return;

  const items = menu.querySelectorAll(`:scope > .${styles['dropdown-menu-item']}`);
  const currentIdx = findIndex(items, (el) => el === currentEl);
  if (currentIdx < 0) return;

  const previousItem = currentIdx > 0 ? items[currentIdx - 1] : items[items.length - 1];
  if (!previousItem) return;
  (previousItem as HTMLElement).focus();
};

const focusParentSubmenuItem = (currentEl: HTMLElement | null) => {
  if (!currentEl) return;

  const submenuItem = currentEl.closest(`.${styles['dropdown-menu-submenu']}`);
  if (!submenuItem) return;

  (submenuItem as HTMLElement).focus();
};

export {
  focusFirstMenuItem,
  focusNextMenuItem,
  focusPreviousMenuItem,
  focusParentSubmenuItem,
};
