import React from 'react';

import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { QboLink } from '@src/components/common/qbo';
import Table from '@src/components/ui_v2/table';

interface IAdvancedSettlementProps {
  adjustmentEntry: IAdjustmentEntry;
}

const displayCategory = (categories: string[]) => {
  if (categories.length > 1) {
    return categories[1];
  }

  return categories[0] || '';
};

const AdvancedSettlement = ({ adjustmentEntry }: IAdvancedSettlementProps) => {
  return (
    <Table className="related-journal-entries-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell>Category</Table.HCell>
          <Table.AmountHCell className="text-nowrap">Adjustment Amount</Table.AmountHCell>
          <Table.EmptyHCell style={ { width: 18 } } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{displayCategory(adjustmentEntry.categories)}</Table.Cell>
          <Table.AmountCell
            amount={ adjustmentEntry.amount }
            className="font-bold"
            textAlign="right"
          />
          <Table.Cell>
            <QboLink entry={ adjustmentEntry.qboLink } />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default React.memo(AdvancedSettlement);
