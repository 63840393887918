import React, { useCallback, useMemo } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetManagementGroup } from '@src/hooks/queries/management_groups';
import { IGetManagementGroupsParams, getManagementGroups } from '@src/requests/management_groups';
import { TID } from '@src/types/common';

import {
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { AsyncPaginateReactSelectInput } from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface IManagementGroupInputProps extends
  Omit<IIconSelectInputProps, 'value' | 'onChange'>
{
  businessId?: TID,
  queryParams?: IGetManagementGroupsParams,
  value?: TID,
  onChange?: (value: TID | null) => void,
}

const perPage = 20;

const ManagementGroupInput = ({
  queryParams,
  value,
  onChange,
  ...props
}: IManagementGroupInputProps): JSX.Element => {
  const managementGroupQuery = useGetManagementGroup(value);

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    if (!onChange) return;

    onChange(item?.value ? Number(item.value) : null);
  }, [onChange]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
    useCallback((query, options, { page }) => {
      return getManagementGroups({
        filter: { name: query },
        page,
        perPage,
        ...queryParams,
      }).then((data) => {
        const hasMore = options.length + data.collection.length < data.meta.totalCount;
        const newOptions = data.collection.map((managementGroup) => ({
          label: managementGroup.name,
          value: String(managementGroup.id),
        }));

        return {
          hasMore,
          options:    newOptions,
          additional: {
            page: page + 1,
          },
        };
      });
    }, [queryParams]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!managementGroupQuery.data) return undefined;

    const managementGroup = managementGroupQuery.data.managementGroup;
    if (!managementGroup) return undefined;

    return {
      label: managementGroup.name,
      value: String(managementGroup.id),
    };
  }, [value, managementGroupQuery.data]);

  return (
    <AsyncPaginateReactSelectInput
      defaultOptions
      hideClear
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      isLoading={ managementGroupQuery.isLoading }
      { ...props }
      loadOptions={ handleSource }
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default React.memo(ManagementGroupInput);
