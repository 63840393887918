import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBalanceSheetStatement, TStatementDetailsSortColumn } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from './styles.module.scss';

interface IStatementDetailsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TStatementDetailsSortColumn>,
  year: TYear,
  balanceSheetStatements: IBalanceSheetStatement[],
  chartOfAccountId: TID,
  businessId: TID,
  bankStatementsDocRequestEnabled: boolean,
  onCloseDetailsModal?: () => void,
}

const StatementDetailsTable = ({
  query,
  sorting,
  year,
  balanceSheetStatements,
  chartOfAccountId,
  businessId,
  bankStatementsDocRequestEnabled,
  onCloseDetailsModal,
}: IStatementDetailsTableProps) => {
  const values = useMemo(() => {
    switch (sorting.data.orderColumn) {
      case 'month':
        if (sorting.data.orderDirection === 'asc') {
          return balanceSheetStatements.sort(
            (a, b) => ((new Date(a.month) > new Date(b.month)) ? 1 : -1),
          );
        }
        return balanceSheetStatements.sort(
          (a, b) => ((new Date(a.month) > new Date(b.month)) ? -1 : 1),
        );
      case 'status':
        if (sorting.data.orderDirection === 'asc') {
          return balanceSheetStatements.sort((a, b) => a.state.localeCompare(b.state));
        }
        return balanceSheetStatements.sort((a, b) => b.state.localeCompare(a.state));
      case 'uploaded_by':
        if (sorting.data.orderDirection === 'asc') {
          return balanceSheetStatements.sort(
            (a, b) => a.uploadedByName.localeCompare(b.uploadedByName),
          );
        }
        return balanceSheetStatements.sort(
          (a, b) => b.uploadedByName.localeCompare(a.uploadedByName),
        );
      case 'verified_by':
        if (sorting.data.orderDirection === 'asc') {
          return balanceSheetStatements.sort(
            (a, b) => a.verifiedByName.localeCompare(b.verifiedByName),
          );
        }
        return balanceSheetStatements.sort(
          (a, b) => b.verifiedByName.localeCompare(a.verifiedByName),
        );
      default:
        return balanceSheetStatements;
    }
  }, [balanceSheetStatements, sorting.data.orderColumn, sorting.data.orderDirection]);
  return (
    <Table
      className={ styles['bank-statement-reviewed-details-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TStatementDetailsSortColumn>
            columnName="month"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Month
          </Table.HCell>
          { bankStatementsDocRequestEnabled && (
            <Table.HCell<TStatementDetailsSortColumn>
              columnName="uploaded_by"
              sorting={ sorting }
              widthPercent={ 10 }
            >
              Uploaded By
            </Table.HCell>
          ) }
          <Table.HCell<TStatementDetailsSortColumn>
            columnName="verified_by"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Verified By
          </Table.HCell>
          <Table.HCell<TStatementDetailsSortColumn>
            columnName="status"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Status
          </Table.HCell>
          <Table.HCell<TStatementDetailsSortColumn>
            className="bank-statement-actions-column"
            widthPercent={ 5 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          values.map((balanceSheetStatement) => (
            <Item
              key={ balanceSheetStatement.id }
              balanceSheetStatement={ balanceSheetStatement }
              bankStatementsDocRequestEnabled={ bankStatementsDocRequestEnabled }
              businessId={ businessId }
              chartOfAccountId={ chartOfAccountId }
              year={ year }
              onCloseDetailsModal={ onCloseDetailsModal }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(StatementDetailsTable);
