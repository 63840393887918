module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive invoices-table-wrapper service-detail-view">\n    <table class="table">\n    <thead>\n        <tr>\n          <th class="font-13 font-bold invoice-check-column-header width-60px">        \n          ');
    
      if (this.service_name === Docyt.Common.Constants.SERVICE_NAMES['AP_SERVICE']) {
        __out.push('\n            <th class="font-13 font-bold sortable" data-orderby="vendor_name" data-orderdir="ASC">Payee</th>\n            <th class="font-13 font-bold width-60px"></th>\n            <th class="font-13 font-bold sortable" data-orderby="status" data-orderdir="ASC">Status</th>\n            <th class="font-13 font-bold sortable" data-orderby="category" data-orderdir="ASC">Category</th>\n            <th class="font-13 font-bold sortable" data-orderby="invoice_number" data-orderdir="ASC">Invoice #</th>\n            <th class="font-13 font-bold sortable" data-orderby="account_number" data-orderdir="ASC">Account</th>\n            <th class="font-13 font-bold sortable" data-orderby="invoice_date_formated" data-orderdir="ASC">Bill Date</th>\n            <th class="font-13 font-bold sortable" data-orderby="invoice_due_date_formated" data-orderdir="ASC">Due In</th>\n            <th class="font-13 font-bold invoice-amount-column-header text-right sortable" data-orderby="display_amount" data-orderdir="ASC">Amount</th>\n          ');
      } else if (this.service_name === Docyt.Common.Constants.SERVICE_NAMES['RECEIPT_SERVICE']) {
        __out.push('\n            <th class="font-13 font-bold sortable" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n            <th class="font-13 font-bold width-60px"></th>\n            <th class="font-13 font-bold sortable" data-orderby="status" data-orderdir="ASC">Status</th>\n            <th class="font-13 font-bold sortable" data-orderby="category" data-orderdir="ASC">Category</th>\n            <th class="font-13 font-bold sortable" data-orderby="payment_account" data-orderdir="ASC">Payment Account</th>\n            <th class="font-13 font-bold sortable" data-orderby="receipt_date" data-orderdir="ASC">Receipt Date</th>\n            <th class="font-13 font-bold invoice-amount-column-header text-right sortable" data-orderby="amount" data-orderdir="ASC">Amount</th>\n          ');
      }
    
      __out.push('\n        </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}