import React from 'react';

import { IReport, ITemplate, TReportCategory } from '@src/types/report_service/report';

import ReportItem from './report_item';

interface IReportsListProps {
  reports: IReport[],
  templates: ITemplate[],
  category: TReportCategory,
}

const ReportsList = ({
  reports,
  templates,
  category,
}: IReportsListProps): JSX.Element => {

  const getScheduleNumber = (name: string) => {
    const match = name.match(/Schedule (\d+)/i);
    return match ? parseInt(match[1], 10) : null;
  };

  const sortReportsByTemplateName = () => {
    const getTemplateById = (id: string) => templates.find((template: ITemplate) => template.id === id);

    const sortFunction = (a: IReport, b: IReport) => {
      const templateA = getTemplateById(a.templateId);
      const templateB = getTemplateById(b.templateId);

      const nameA = templateA ? templateA.name : '';
      const nameB = templateB ? templateB.name : '';

      const scheduleNumberA = getScheduleNumber(nameA);
      const scheduleNumberB = getScheduleNumber(nameB);

      if (scheduleNumberA !== null && scheduleNumberB !== null) {
          return scheduleNumberA - scheduleNumberB;
      }
      if (scheduleNumberA !== null) {
          return -1;
      }
      if (scheduleNumberB !== null) {
          return 1;
      }
      return nameA.localeCompare(nameB);
    };

    return reports.sort(sortFunction);
  };

  const sortedReports = category === 'department' ? sortReportsByTemplateName() : reports;

  return (
    <div>
      <div className="col-xs-4 line-height-60 font-16 font-bold p-l-20">Report Name</div>
      <div className="col-xs-3 line-height-60 font-16 font-bold">Shared With</div>
      <div className="col-xs-4 line-height-60 font-16 font-bold">Report Type</div>

      {sortedReports.map((report) => (
        <ReportItem
          key={ report.id }
          category={ category }
          report={ report }
          templates={ templates }
        />
      ))}
    </div>
  );
};

export default ReportsList;
