import React from 'react';

import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { metricsValuePath } from '@src/routes';

import { useCategorizedMetricContext } from './categorized_metrics_provider';

const MetricServiceRoot = (): JSX.Element | null => {

  const categorizedMetrics = useCategorizedMetricContext();
  const service = useMetricsServiceContext();
  const selectedMetric = categorizedMetrics?.businessMetrics[0]

  if (selectedMetric) {
     Backbone.history.navigate(
       metricsValuePath(service.businessId, selectedMetric?.id),
       { trigger: true },
     );
   }

 return null;
};

export default React.memo(MetricServiceRoot);
