import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';

import { TOption, TSubOption } from '../types';
import InnerInput from './inner_input';
import Item from './item';

import styles from '../styles.module.scss';

interface IDropdownInputProps extends
  Omit<React.ComponentPropsWithoutRef<typeof InnerInput>, 'onClear'>
{
  className?: string,
  options: TSubOption[],
  value: TOption | null,
  onChange?: (value: TOption | null) => void,
  onClear?: () => void,
}

const DropdownInput = ({
  className,
  options,
  value,
  onChange,
  onClear,
  ...props
}: IDropdownInputProps): JSX.Element => {
  const handleSelect = useCallback((option: TOption) => {
    if (onChange) onChange(option);
  }, [onChange]);

  const handleClear = useCallback(() => {
    if (onChange) onChange(null);
    if (onClear) onClear();
  }, [onChange, onClear]);

  const classes = classNames(
    styles['dropdown-input'],
    className,
  );

  return (
    <Dropdown.Provider className={ classes }>
      <InnerInput
        value={ value }
        onClear={ handleClear }
        { ...props }
      />
      <Dropdown.Menu>
        { options.map((option) => (
          <Item
            key={ option.value }
            currentValue={ value }
            option={ option }
            onSelect={ handleSelect }
          />
        )) }
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export {
  IDropdownInputProps,
  DropdownInput as default,
};
