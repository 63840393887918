module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      this.first_name = this.dwollaCustomer.get('first_name');
    
      __out.push('\n');
    
      this.last_name = this.dwollaCustomer.get('last_name');
    
      __out.push('\n');
    
      this.email = this.dwollaCustomer.get('email');
    
      __out.push('\n');
    
      this.last4 = this.dwollaFundingSource.get('last4');
    
      __out.push('\n');
    
      this.routing_number = this.dwollaFundingSource.get('routing_number');
    
      __out.push('\n<div class=\'modal fade\'>\n    <div class=\'modal-dialog delete-employee-ach-modal\'>\n        <div class=\'modal-content\'>\n            <div class="modal-header">\n                <h1 class="modal-title text-center font-semi-bold">\n                    Delete ACH Information\n                </h1>\n            </div>\n            <div class=\'modal-body\'>\n                <p class="font-14 m-b-20">Following information related to employee will be deleted. Are you sure?</p>\n                <div class="field m-b-16">\n                    <span class="name">Employee Name</span>\n                    <span class="value">');
    
      __out.push(__sanitize(this.first_name ? this.first_name + ' ' + this.last_name : this.last_name));
    
      __out.push('</span>\n                </div>\n                <div class="field m-b-16">\n                    <span class="name">Email Address</span>\n                    <span class="value">');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</span>\n                </div>\n                <div class="field m-b-16">\n                    <span class="name">Account Number</span>\n                    <span class="value">');
    
      __out.push(__sanitize(this.last4 ? "**** " + this.last4 : '-'));
    
      __out.push('</span>\n                </div>\n                <div class="field m-b-16">\n                    <span class="name">Routing Number</span>\n                    <span class="value">');
    
      __out.push(__sanitize(this.routing_number));
    
      __out.push('</span>\n                </div>\n            </div>\n            <div class=\'modal-footer\'>\n                <button type=\'button\' class="btn btn-blue ladda-button delete-js" tabindex="10" data-color="red" data-style="expand-right">\n                    <span class="ladda-label">Delete</span>\n                </button>\n                <a class=\'cancel-link cancel pointer font-semi-bold\'>Cancel</a>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}