import React, { forwardRef, useCallback } from 'react';

import classNames from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { TNumber } from '@src/types/common';

import IndicatorWrapper from './input_indicator_wrapper';
import { IInputCommonProps } from './types';

import styles from './styles.module.scss';

interface INumberInputProps extends
  IInputCommonProps,
  Omit<NumberFormatProps, 'value' | 'onChange' | 'size'>
{
  value?: TNumber | null,
  onChange: (value: TNumber | string) => void,
  onClear?: () => void,
}

const NumberInput = forwardRef<HTMLInputElement, INumberInputProps>(({
  className,
  size = 'normal',
  value,
  onChange,
  onClear,
  ...props
}: INumberInputProps, ref) => {
  const handleChange = useCallback((values) => {
    if (onChange) onChange(values.value || null);
  }, [onChange]);

  const handleClear = useCallback(() => {
    if (onChange) onChange('');
    if (onClear) onClear();
  }, [onChange, onClear]);

  const classes = classNames(
    styles['text-input'],
    className,
    { [styles['input-compact']]: size === 'compact' },
  );

  return (
    <IndicatorWrapper
      hasValue={ Boolean(value) }
      size={ size }
      onClear={ handleClear }
    >
      <NumberFormat
        isNumericString
        className={ classes }
        getInputRef={ ref }
        inputMode="numeric"
        value={ value || '' }
        { ...props }
        onValueChange={ handleChange }
      />
    </IndicatorWrapper>
  );
});

NumberInput.displayName = 'NumberInput';
const MemoizedNumberInput = React.memo(NumberInput);

export {
  INumberInputProps,
  MemoizedNumberInput as default,
};
