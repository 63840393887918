import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getReportServiceByBusinessId } from '@src/requests/report_service/report_services';
import { TID } from '@src/types/common';
import { IReportService } from '@src/types/services';

const useGetReportServiceByBusinessId = (businessId: TID) => {
  return useQuery<IReportService, Error>(
    [QueryKey.reportService, businessId],
    () => getReportServiceByBusinessId(businessId),
    {
      enabled: !!businessId,
    },
  );
};

export {
  useGetReportServiceByBusinessId,
};
