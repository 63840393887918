import React, { useCallback } from 'react';

import { GroupBase, StylesConfig } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getAllChartOfAccountsForBusiness } from '@src/requests/chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import { AsyncSelectPaginateInput } from '@src/components/ui/form';
import MultiReactSelectedValue from '@src/components/ui_v2/inputs/react_select/multi_react_selected_value';

interface IMultiChartOfAccountsInputProps
{
  businessId: TID,
  accountType?: string,
  value: IChartOfAccount[],
  onChange: (items: any) => void,
}

const getOptionLabel = (item: IChartOfAccount) => item.displayName;
const getOptionValue = (item: IChartOfAccount) => String(item.id);

const customStyles: StylesConfig<IChartOfAccount, any> = {
  option: (provided) => (
    {
      ...provided,
      padding: '5px 8px',
    }
  ),

  menu: (provided) => (
    {
      ...provided,
      minWidth: '500px',
      width:    '500px',
      margin:   '2px 0 0',
    }
  ),

  menuList: (provided) => (
    {
      ...provided,
      padding: '0 0 2px',
    }
  ),
};

const MultiChartOfAccountsInput = ({
  accountType,
  businessId,
  value,
  onChange,
  ...props
}: IMultiChartOfAccountsInputProps) => {
  const handleSource: LoadOptions<IChartOfAccount, GroupBase<IChartOfAccount>, any> =
    useCallback((query, options, { page }) => {
      if (!businessId) return { options: [] };

      return getAllChartOfAccountsForBusiness({
        businessId,
        search: query,
        filter: { accountType },
        page,
      }).then((data) => {
        const hasMore = data.length > 0;

        return {
          hasMore,
          options:    data,
          additional: {
            page: page + 1,
          },
        };
      });
    }, [accountType, businessId]);

  const handleRemoveItem = useCallback((itemValue: string | number) => {
    if (onChange) { onChange(value?.filter((v) => v.id !== itemValue)); }
  }, [onChange, value]);

  return (
    <>
      <AsyncSelectPaginateInput
        { ...props }
        hideIndicators
        isClearable
        isMulti
        additional={ {
          page: 1,
        } }
        controlShouldRenderValue={ false }
        debounceTimeout={ 300 }
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        id="multi-chart-of-accounts-input"
        loadOptions={ handleSource }
        placeholder="Select Chart of Accounts"
        styles={ customStyles }
        value={ value }
        onChange={ onChange }
      />
      { value?.length ? (
        value.map((v) => (
          <MultiReactSelectedValue
            key={ v.id }
            label={ v.displayName }
            value={ v.id }
            onRemoveItem={ handleRemoveItem }
          />
        ))
      ) : (<span>{ null }</span>)}
    </>
  );
};

export default MultiChartOfAccountsInput;
