import React from 'react';

import { revenueServicePathDepositTrackingPath, revenueServicePathRevenueCapturePath } from '@src/routes';
import { TID, TUUID } from '@src/types/common';

import styles from './styles.module.scss';

interface IRevenueServiceTabsProps {
  businessId: TID,
  revenueSystemId: TUUID,
  activeTab: string,
}

const RevenueServiceTabs = ({
  businessId,
  revenueSystemId,
  activeTab,
}: IRevenueServiceTabsProps) => {
  return (
    <div className={ styles['tab-container-button'] }>
      <a
        className={ activeTab === 'RevenueCapture' ? styles.active : '' }
        href={ revenueServicePathRevenueCapturePath(businessId, revenueSystemId) }
      >
        REVENUE CAPTURE
      </a>
      <a
        className={ activeTab === 'DepositTracking' ? styles.active : '' }
        href={ revenueServicePathDepositTrackingPath(businessId, revenueSystemId) }
      >
        DEPOSIT TRACKING
      </a>
    </div>
  );
};

export default React.memo(RevenueServiceTabs);
