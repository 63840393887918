import React, { useCallback } from 'react';

import { multiEntityReportsExportPath } from '@src/routes';

import { DocytLogoIcon, NotAChargebackIcon } from '@src/components/utils/icomoon';

const MultiReportsExportHeader = ({ multiReportId }: { multiReportId: string }) => {
  const handleClick = useCallback(() => {
    Backbone.history.navigate(
      multiEntityReportsExportPath(multiReportId),
      { trigger: true },
    );
  }, [multiReportId]);

  return (
    <div className="reports-export-header multi-reports-header">
      <DocytLogoIcon fontSize={ 20 } />
      <h2 className="header-title">Export Reports</h2>
      <NotAChargebackIcon pointer fontSize={ 24 } onClick={ handleClick } />
    </div>
  );
}

export default MultiReportsExportHeader;