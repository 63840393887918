import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  useLayoutEffect,
} from 'react';

import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import { usePopper } from 'react-popper';

import { useStateCallback } from './utils';

type PopperOptions = Parameters<typeof usePopper>[2];

interface IPopoverShowOnHoverAttributes {
  onMouseEnter: () => void,
  onMouseLeave: () => void,
}

interface IPopoverShowWithKeyboardAttributes {
  onKeyDown: (e: ReactKeyboardEvent) => void,
}

interface IPopoverShowOnClickAttributes {
  onClick: (e: ReactMouseEvent) => void,
}

interface IPopoverParams {
  /**
   * If provided and returns true, then the popover will not be shown.
   *
   * This method is called before showing the popover and the reference element is passed.
   * It may be used to prevent showing popover if there are some conflicts (another
   * popover is shown).
   * @param reference - reference element passed to popover as `referenceRef`.
   * @return - if `true` then popover will not be shown.
   */
  hasConflict?: (reference: HTMLElement) => boolean,
  /**
   * If provided and > 0 then popover will be hided after delay.
   */
  hideDelay?: number,
  /**
   * If `true` then popover will be hidden when clicked outside `referenceElement`.
   */
  hideOnClickOutside?: boolean,
  /**
   * Options passed directly to Popper.js library.
   */
  opts?: PopperOptions,
  /**
   * If provided and > 0 then popover will be shown after delay.
   */
  showDelay?: number,
  onShow?: () => void,
  onHide?: () => void,
  onItemSelected?: () => void,
}

interface IPopover {
  isVisible: boolean,
  toggle: () => void,
  hide: () => void;
  show: () => void;
  arrowRef: (el:HTMLElement | null) => void,
  referenceRef: (el: HTMLElement | null) => void,
  popperElement: HTMLElement | null,
  popperRef: (el: HTMLElement | null) => void,
  popperStyles: { [key: string]: CSSProperties },
  popperAttributes: { [key: string]: { [key: string]: string } | undefined },
  showOnHoverAttributes: IPopoverShowOnHoverAttributes,
  showWithKeyboardAttributes: IPopoverShowWithKeyboardAttributes,
  showOnClickAttributes: IPopoverShowOnClickAttributes,
  select: () => void,
}

const mergePopoverOpts = (opts1: PopperOptions, opts2: PopperOptions): PopperOptions => {
  return mergeWith(opts1, opts2, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }

    return undefined;
  });
};

const useCreatePopover = ({
  hasConflict,
  hideDelay = 0,
  hideOnClickOutside = true,
  showDelay = 0,
  opts = {},
  onHide,
  onShow,
  onItemSelected,
}: IPopoverParams = {}): IPopover => {
  const [isVisible, setVisible] = useStateCallback<boolean>(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const popperOptions: PopperOptions = useMemo(() => {
    return mergePopoverOpts({
      placement: 'bottom-start',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
      ],
    }, opts);
  }, [opts, arrowElement]);

  const { styles: popperStyles, attributes: popperAttributes, update: popperUpdate } = usePopper(
    referenceElement,
    popperElement,
    popperOptions,
  );

  const showTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const hideTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleToggleVisibility = useCallback(() => {
    setVisible(
      (value) => !value,
      (value) => {
        if (value) {
          if (onShow) onShow();
        } else if (onHide) onHide();
      },
    );
  }, [onHide, onShow, setVisible]);

  useLayoutEffect(() => {
    if (!popperUpdate || !isVisible) return;
    popperUpdate(); // Update popper position on show
  }, [isVisible, popperUpdate]);

  const handleShow = useCallback(() => {
    setVisible(true, () => {
      if (onShow) onShow();
    });
  }, [onShow, setVisible]);

  const handleHide = useCallback(() => {
    setVisible(false, () => {
      if (onHide) onHide();
    });
  }, [onHide, setVisible]);

  const showWithoutConflict = useCallback(() => {
    if (hasConflict && referenceElement && hasConflict(referenceElement)) return;

    handleShow();
  }, [hasConflict, referenceElement, handleShow]);

  const handleMouseEnter = useCallback(() => {
    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
    }

    if (!showDelay || showDelay === 0) {
      showWithoutConflict();
      return;
    }

    if (showTimer.current) {
      clearTimeout(showTimer.current);
    }

    showTimer.current = setTimeout(() => {
      showWithoutConflict();
    }, showDelay);
  }, [showDelay, showWithoutConflict]);

  const handleMouseLeave = useCallback(() => {
    if (showTimer.current) {
      clearTimeout(showTimer.current);
    }

    if (!hideDelay || hideDelay === 0) {
      handleHide();
      return;
    }

    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
    }

    hideTimer.current = setTimeout(handleHide, hideDelay);
  }, [hideDelay, handleHide]);

  const handleSelect = useCallback(() => {
    if (onItemSelected) onItemSelected();
  }, [onItemSelected]);

  useEffect(() => {
    // Clear timers on unmount. On unmounting everything will be removed and timer become useless
    return () => {
      if (showTimer.current) clearTimeout(showTimer.current);
      if (hideTimer.current) clearTimeout(hideTimer.current);
    };
  }, []);

  useEffect(() => {
    if (!hideOnClickOutside) return undefined;
    if (!isVisible) return undefined;

    const documentClickHandler = (e: MouseEvent | TouchEvent) => {
      if (!popperElement) return;

      if (popperElement.contains(e.target as Node)) return;
      handleHide();
    };

    document.addEventListener('mousedown', documentClickHandler);
    document.addEventListener('touchstart', documentClickHandler);

    return () => {
      document.removeEventListener('mousedown', documentClickHandler);
      document.removeEventListener('touchstart', documentClickHandler);
    };
  }, [isVisible, popperElement, hideOnClickOutside, handleHide]);

  const handleOnClick = useCallback((e: ReactMouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleVisibility();
  }, [handleToggleVisibility]);

  const handleKeyDown = useCallback((e: ReactKeyboardEvent) => {
    switch (e.code) {
      case 'Space':
        handleToggleVisibility();
        break;
      case 'ArrowDown':
        handleShow();
        break;
      case 'ArrowUp':
      case 'Escape':
        handleHide();
        break;
      default:
        break;
    }
  }, [handleToggleVisibility, handleShow, handleHide]);

  const contextValue: IPopover = useMemo(() => ({
    isVisible,
    toggle:                handleToggleVisibility,
    show:                  handleShow,
    hide:                  handleHide,
    arrowRef:              setArrowElement,
    referenceRef:          setReferenceElement,
    popperElement,
    popperRef:             setPopperElement,
    popperStyles,
    popperAttributes,
    showOnHoverAttributes: {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
    showOnClickAttributes: {
      onClick: handleOnClick,
    },
    showWithKeyboardAttributes: {
      onKeyDown: handleKeyDown,
    },
    select: handleSelect,
  }), [
    isVisible,
    popperAttributes,
    popperElement,
    popperStyles,
    handleToggleVisibility,
    handleShow,
    handleHide,
    handleMouseEnter,
    handleMouseLeave,
    handleOnClick,
    handleKeyDown,
    handleSelect,
  ]);

  return contextValue;
};

export {
  IPopover,
  IPopoverParams,
  mergePopoverOpts,
  useCreatePopover,
};
