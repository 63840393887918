import React from 'react';

import { Controller, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';

import DocumentInput from './select_standard_document_input';

interface ISelectStandardDocumentFormFormValues {
  query: string,
  documentId: TID,
}

interface ISelectStandardDocumentFormProps {
  business: IBusiness,
  formId: string,
  onSubmit: (values: ISelectStandardDocumentFormFormValues) => void,
}

const SelectStandardDocumentForm = ({
  business,
  formId,
  onSubmit,
}: ISelectStandardDocumentFormProps): JSX.Element => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<ISelectStandardDocumentFormFormValues>();

  return (
    <form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {
        errors.documentId && <ErrorNotification message="Please Select Document" title="Warning!" />
      }
      <div className="flex-wrapper p-l-15 p-r-15 m-b-20 relative width-100-percent search-input-wrapper">
        <input
          className="form-control p-l-25"
          placeholder="Search Document Type"
          type="text"
          { ...register('query') }
        />
      </div>
      <Controller
        control={ control }
        name="documentId"
        render={
          ({ field }) => (
            <DocumentInput business={ business } field={ field } query={ watch('query') } />
          )
        }
        rules={ { required: true } }
      />
    </form>
  );
};

export {
  ISelectStandardDocumentFormFormValues,
  SelectStandardDocumentForm as default,
};
