import { useCallback } from 'react';

import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useRevenueServiceFayeChannel } from '@src/hooks/faye/revenue_service';
import {
  getRevenueServiceByBusinessId,
  IGetRevenueServiceByBusinessIdParams,
  IGetRevenueServiceByBusinessIdResponse,
} from '@src/requests/revenue_services';

const useGetRevenueServiceByBusinessId = (params: IGetRevenueServiceByBusinessIdParams) => {
  const query = useQuery<IGetRevenueServiceByBusinessIdResponse, Error>(
    [QueryKey.revenueServiceByBusinessId, params],
    () => getRevenueServiceByBusinessId(params),
    {
      enabled: Boolean(params.businessId),
    },
  );

  const { refetch } = query;
  const handleServiceUpdated = useCallback(() => {
    refetch();
  }, [refetch]);

  useRevenueServiceFayeChannel(params.businessId, handleServiceUpdated);

  return query;
};

export {
  useGetRevenueServiceByBusinessId,
};
