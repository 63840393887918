import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ReportExpenseIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="expense-report"
      pathsNumber={ 5 }
    />
  );
};

export default ReportExpenseIcon;
