module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n  <div class="header-menu">\n      <!-- User Avatar Dropdown Menu Begin -->\n      <div class=\'header__item-wrapper pull-right\' id=\'user-dropdown\'>\n          <div class=\'header__userpic\' id=\'user-icon\'>\n              ');
    
      if (this.avatarUrl) {
        __out.push('\n              <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'header-avatar-wrapper\'>\n              ');
      } else {
        __out.push('\n              <i class=\'clients__client-status-icon icon icon-smile in-blue-400\'></i>\n              ');
      }
    
      __out.push('\n          </div>\n          <div class=\'header__dropdown-menu-wrapper\'>\n              <ul class=\'header__dropdown-menu m-b-0\'>\n                  <li>\n                      <button class="display-table-row header__dropdown-workspace-wrap">\n                          <div class="display-table-cell header-dropdown-user-img-wrapper line-height-0">\n                              ');
    
      if (this.avatarUrl) {
        __out.push('\n                              <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'user-avatar-wrapper\'>\n                              ');
      } else {
        __out.push('\n                              <i class="icon icon-smile in-blue-400 font-64 line-height-0"></i>\n                              ');
      }
    
      __out.push('\n                          </div>\n\n                          <div class="display-table-cell header__dropdown-workspace p-l-12">\n                              <p class="line-height-15 m-b-5 font-18 font-bold text-center">');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('full_name')));
    
      __out.push('</p>\n                              <a class="font-12 in-blue-900 edit-profile-link edit-profile-js pointer">Edit</a>\n                          </div>\n                      </button>\n                  </li>\n                  <li>\n                      <a href=\'/settings\' class=\'profile-link dropdown-menu-icon\'>\n                        <span class="icon-settings-cog m-l-5 m-r-5"></span> Settings\n                      </a>\n                  </li>\n                  <li>\n                    <a href="/feedback" class=\'profile-link dropdown-menu-icon\'>\n                      <span class="icon-feedback_message m-l-5 m-r-5"></span> Feedback\n                    </a>\n                  </li>\n                  <li>\n                      <button type="button" class=\'sign-out-js dropdown-menu-icon\'>\n                        <span class="icon-settings-signout m-l-5 m-r-5"></span> Sign Out\n                      </button>\n                  </li>\n              </ul>\n          </div>\n      </div>\n      <!-- User Avatar Dropdown Menu End -->\n      <a class=\'header-logo-sign-up\' href=\'/\'>\n        <img src="');
    
      __out.push(__sanitize(configData.logoURL));
    
      __out.push('" class="width-80px-important">\n      </a>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}