import React from 'react';

import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import {
  portfolioDashboardPath,
  multiEntityReportsPath,
  chartOfAccountsPath,
} from '@src/routes';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
}

const PortfolioDropDownList = ({
  launchDarklyFeature,
}: IServiceMenuProps) => {
  let title: string = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE;

  if (window.location.pathname === portfolioDashboardPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD;
  } else if (window.location.pathname === multiEntityReportsPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS;
  } else if (window.location.pathname === chartOfAccountsPath()) {
    title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS;
  }

  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const coasEnabled = useUserFeatureFlag(featureFlag);

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        className="font-16 font-bold"
        showSelect={ false }
        title={ title }
      >
        <ServiceItem
          serviceUrl={ portfolioDashboardPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD }
        />
        <ServiceItem
          serviceUrl={ multiEntityReportsPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
        />
        {
          coasEnabled && (
            <ServiceItem
              serviceUrl={ chartOfAccountsPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.CHART_OF_ACCOUNTS }
            />
          )
        }

      </ModuleHeaderMenu>
    </div>
  );
};

export default PortfolioDropDownList;
