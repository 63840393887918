import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import Footer from './footer';

interface IBottomModalStandardFooterProps {
  cancelTitle?: string,
  children?: React.ReactNode,
  destructive?: boolean,
  doneTitle?: string,
  isDoneDisabled?: boolean,
  onCancel: () => void,
  onDone: () => void,
}

const BottomModalStandardFooter = ({
  cancelTitle = 'Cancel',
  children,
  destructive,
  doneTitle = 'Done',
  isDoneDisabled,
  onCancel,
  onDone,
}: IBottomModalStandardFooterProps) => {
  return (
    <Footer>
      <Button
        variant="link"
        onClick={ onCancel }
      >
        { cancelTitle }
      </Button>
      { children }
      <Button
        disabled={ isDoneDisabled }
        variant={ destructive ? 'destructive' : 'primary' }
        onClick={ onDone }
      >
        { doneTitle }
      </Button>
    </Footer>
  );
};

export {
  IBottomModalStandardFooterProps,
  BottomModalStandardFooter as default,
};
