import React, { useCallback } from 'react';

import Select, { Props, StylesConfig } from 'react-select';
import { withAsyncPaginate } from 'react-select-async-paginate';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import { IInputCommonProps } from '../types';
import {
  ReactSelectClearIndicator,
  ReactSelectDropdownIndicator,
  ReactSelectMenuWithFooter,
} from './helpers';
import { reactSelectStyles } from './styles';
import { ReactSelectClassNamePrefix } from './utils';

interface IReactSelectInputProps<TOption, TIsMulti extends boolean = false>
  extends IInputCommonProps,
    Props<TOption, TIsMulti> {
  alwaysShowIndicator?: boolean;
}

const ReactSelectInput = <TOption, TIsMulti extends boolean = false>({
  components: componentsProp = {},
  hideClear,
  menuFooter,
  size,
  styles,
  value,
  alwaysShowIndicator = false,
  ...props
}: IReactSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  const MenuWithFooter = useCallback(
    (menuListProps) => {
      return (
        <ReactSelectMenuWithFooter { ...menuListProps } menuFooter={ menuFooter } />
      );
    },
    [menuFooter],
  );

  return (
    <Select
      className={ classes }
      classNamePrefix={ ReactSelectClassNamePrefix }
      components={ {
        ClearIndicator:    ReactSelectClearIndicator,
        DropdownIndicator:
          value && !alwaysShowIndicator
            ? () => null
            : ReactSelectDropdownIndicator,
        IndicatorSeparator: () => null,
        Menu:               MenuWithFooter,
        ...componentsProp,
      } }
      isClearable={ !hideClear }
      styles={ {
        ...(reactSelectStyles({ size }) as StylesConfig<TOption, TIsMulti>),
        ...styles,
      } }
      value={ value || null }
      { ...selectProps }
    />
  );
};

const MemoizedIconSelectInput = React.memo(
  ReactSelectInput,
) as typeof ReactSelectInput;
const AsyncPaginateReactSelectInput = withAsyncPaginate(
  MemoizedIconSelectInput,
);

export {
  IReactSelectInputProps,
  AsyncPaginateReactSelectInput,
  ReactSelectInput as default,
};
