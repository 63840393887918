import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IDocument } from '@src/types/documents';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import Content from './document_field_content';

interface IDocumentFieldsProps extends IUseModalProps {
  document: IDocument,
  title?: string,
}

const DocumentFields = ({
  document,
  title,
  isOpen,
  onCancel,
}: IDocumentFieldsProps): JSX.Element => {
  const handleConfirm = useCallback((e) => {
    e.preventDefault();

    onCancel();
  }, [onCancel]);

  return (
    <Modal
      dialogClassName="document-fields-modal"
      region="second-modal-region"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        { isOpen && (
          <Content documentId={ document.id } />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsColor="blue"
          title="Done"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useDocumentFieldsModal = makeUseModal(DocumentFields);

export {
  IDocumentFieldsProps,
  DocumentFields,
  useDocumentFieldsModal as default,
};
