import { object, string } from 'yup';

import { TAccountType } from '@src/types/common';

interface ISetupNewEployeeCardInputData {
    account_type: TAccountType
    cvv?: string,
    last4?: string,
    name: string,
    accountOwner?: string,
}

const setupNewEployeeCardInputDataValidation = object({
  name:  string().trim().required('Bank Name is required.'),
  last4: string().test((value, validationContext) => {
    const { createError } = validationContext;
    if (value === '') return createError({ message: 'Last 4 digits is required' });
    if (value?.length !== 4) return createError({ message: 'Card number must 4 digits' });
    if (!/^[0-9]+$/.test(value)) return createError({ message: 'Card number must be a valid number' });

    return true;
  }),
  cvv: string().test((value, validationContext) => {
    const { createError } = validationContext;
    if (value) {
      if (!/^[0-9]+$/.test(value)) return createError({ message: 'CVV must be a valid number' });
    }
    return true;
  }),
});

export {
  ISetupNewEployeeCardInputData,
  setupNewEployeeCardInputDataValidation,
};
