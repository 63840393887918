import React from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';
import { SetupSettingsIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ITableSectionSettingsProps {
  children: React.ReactNode,
}

const TableSectionSettings = ({
  children,
}: ITableSectionSettingsProps) => {
  return (
    <Dropdown.Provider className={ styles['table-section-settings'] }>
      <Dropdown.ToggleButton>
        <SetupSettingsIcon />
      </Dropdown.ToggleButton>
      <Dropdown.Menu>
        { children }
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export default TableSectionSettings;
