import React, { useMemo } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface IImgIconProps extends React.ComponentPropsWithoutRef<'img'>, IUIStyleProps {
  size?: string,
}

const ImgIcon = ({
  size,
  style,
  ...props
}: IImgIconProps): JSX.Element => {
  const [classes, imgProps] = uiStyleProps(undefined, props);
  const imgStyles = useMemo(() => {
    if (!size) return style;

    return {
      ...style || {},
      width:  size,
      height: size,
    };
  }, [size, style]);

  return (
    <img
      alt=""
      className={ classes }
      style={ imgStyles }
      { ...imgProps }
    />
  );
};

export {
  IImgIconProps,
  ImgIcon as default,
};
