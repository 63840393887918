import React from 'react';

import aiChatExternalLinkIcon from '@assets/images/ai_chatbot/arrow_outward.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const aiChatExternalIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon alt="AI Chat Logo icon" src={ aiChatExternalLinkIcon } { ...props } />
  );
};

export default aiChatExternalIcon;
