import { createContext, useContext } from 'react';

import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

const ReportServiceBudgetContext = createContext<IReportServiceBudget | null>(null);

// force convert to `IReportServiceBudget` type without null because provider children are
// rendered only then business present.
const useReportServiceBudgetContext =
  () => <IReportServiceBudget>useContext(ReportServiceBudgetContext);

export {
  ReportServiceBudgetContext,
  useReportServiceBudgetContext,
};
