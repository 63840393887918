import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './item';

interface IResultantTransactionListProps {
  resultantTransactions: ITransactionServiceDocument[],
}

const ResultantTransactionList = ({
  resultantTransactions,
}: IResultantTransactionListProps): JSX.Element => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 20 }>Date</Table.HCell>
          <Table.HCell widthPercent={ 25 }>Description</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Type</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Account</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Amount</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          resultantTransactions.map((resultantTransaction) => (
            <Item
              key={ resultantTransaction.id }
              resultantTransaction={ resultantTransaction }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default ResultantTransactionList;
