import React from 'react';

import { TAmount } from '@src/types/common';
import {
  currencyFormater,
  currencyFormater2,
} from '@src/utils/currency';

import Cell, { ITableCellProps } from './cell';

interface ITableAmountCellProps extends Omit<ITableCellProps, 'children'> {
  absolute?: boolean,
  amount: TAmount | number,
  formater?: (amount: TAmount | number | null) => string,
}

/**
 * Cell with formatted amount value
 *
 * @visibleName Table.AmountCell
 */
const TableAmountCell = ({
  absolute,
  amount,
  formater,
  ...cellProps
}: ITableAmountCellProps) => {
  let value = null;
  if (formater) {
    value = formater(amount);
  } else {
    value = absolute ? currencyFormater2(amount) : currencyFormater(amount);
  }

  return (
    <Cell
      textAlign="right"
      { ...cellProps }
    >
      { value }
    </Cell>
  );
};

export default TableAmountCell;
