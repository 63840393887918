module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="ach-setting-item-wrapper in-black">\n  <div class="display-table width-100-percent">\n    <div class="display-table-cell middle-avatar-wrapper">\n      <div class="middle-avatar-wrapper">\n      <img src="');
    
      __out.push(__sanitize(configData.images['user_self']));
    
      __out.push('">\n      </div>\n    </div>\n    <div class="display-table-cell vertical-align-middle p-l-30">\n      <span class="font-18">Beneficial Owners</span>\n    </div>\n    <div class="display-table-cell vertical-align-middle text-right ');
    
      __out.push(__sanitize(this.dwollaCustomer.id && this.dwollaCustomer.get('dwolla_beneficial_owners').length > 0 ? 'hidden' : ''));
    
      __out.push('">\n      <a class="btn btn-blue add-owner-js width-120px">Add</a>\n    </div>\n  </div>\n  <div class="table-responsive beneficial-owners-table-wrapper ');
    
      __out.push(__sanitize(this.dwollaCustomer.id && this.dwollaCustomer.get('dwolla_beneficial_owners').length > 0 ? '' : 'hidden'));
    
      __out.push('">\n    <table class="table m-b-0" cellspacing="0" cellpadding="0">\n      <tbody>\n      </tbody>\n      <tfoot>\n        <tr>\n          <td>\n            <a class="display-flex align-items-center pointer add-owner-js">\n              <div class="small-avatar-wrapper m-r-15">\n                <i class="icon icon-o-plus font-24 in-blue-950"></i>\n              </div>\n              <div class="font-14 font-semi-bold">Add More</div>\n            </a>\n          </td>\n          <td></td>\n          <td></td>\n          <td></td>\n          <td></td>\n        </tr>\n      </tfoot>\n    </table>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}