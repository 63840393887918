import { IAchTransaction, TAchTransactionsSortColumn } from '@src/types/accounts_payable/ach_transactions';
import { TID, TNumber, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetAchTransactionsParams {
  businessId: TID,
  filters?: object,
  perPage?: TNumber,
  orderColumn?: TAchTransactionsSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetAchTransactionsResponse {
  meta: {
    totalCount: number,
  },
  collection: IAchTransaction[],
}

const getAchTransactions = (
  params: IGetAchTransactionsParams,
): Promise<IGetAchTransactionsResponse> => {
  return apiGet(
    '/api/v1/dwolla_transfers',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.dwollaTransfers as IAchTransaction[],
    };
  });
};

const getAchTransaction = (
  id: TNumber,
): Promise<IAchTransaction> => {
  return apiGet(`/api/v1/dwolla_transfers/${id}`)
    .then((data) => camelizeKeys(data.service_document) as IAchTransaction);
};

export {
  IGetAchTransactionsParams,
  IGetAchTransactionsResponse,
  getAchTransactions,
  getAchTransaction,
};
