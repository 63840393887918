import React, { useState } from 'react';

import { useGetAccountsPayableServiceDocument } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useGetDocumentFields } from '@src/hooks/queries/document_fields';
import { useGetDocument } from '@src/hooks/queries/documents';
import { TID } from '@src/types/common';

import { TOption } from '@src/components/ui_v2/inputs';
import SideView from '@src/components/ui_v2/simple_side_view';

import DataTab from './data';
import DetailTab from './detail';
import Footer from './footer';
import PastPayments from './past_payments';
import RelatedDocuments from './related_documents';

interface IInvoiceDetailSideMenu {
  serviceId: TID,
  businessId: TID,
  serviceDocumentId: TID,
  documentId: TID,
  activeTab: string,
  email: string,
}

const InvoiceDetailSideMenu = ({
  serviceId,
  businessId,
  serviceDocumentId,
  documentId,
  activeTab,
  email,
}: IInvoiceDetailSideMenu) => {
  const [paymentType, setPaymentType] = useState<TOption | null>(null);
  const { data: info } = useGetBusinessServicesInfo(businessId);
  const documentFieldsQuery = useGetDocumentFields({ documentId });
  const documentFields = documentFieldsQuery.data?.documentFields || [];
  const { data: serviceDocument } = useGetAccountsPayableServiceDocument(serviceDocumentId);
  const { data: document } = useGetDocument(documentId);

  return (
    <SideView.Content>
      <SideView.Body>
        {
          activeTab === 'detail' ? (
            <DetailTab
              document={ document }
              isAuthorizedPayer={ info?.isBankAccountAdmin || true }
              paymentType={ paymentType }
              serviceDocument={ serviceDocument }
              onSelectType={ setPaymentType }
            />
          ) : (
            activeTab === 'data' && (
              <DataTab
                businessId={ businessId }
                documentFields={ documentFields }
                documentId={ documentId }
                serviceDocument={ serviceDocument }
              />
            )
          )
        }
        <PastPayments
          businessId={ businessId }
          serviceId={ serviceId }
          vendorId={ serviceDocument?.vendor?.id || 0 }
        />
        <RelatedDocuments
          documentId={ documentId }
        />
      </SideView.Body>
      {
        activeTab === 'detail' && (
          <Footer
            businessId={ businessId }
            email={ email }
            paymentType={ paymentType }
            serviceDocument={ serviceDocument }
          />
        )
      }
    </SideView.Content>
  );
};

export default React.memo(InvoiceDetailSideMenu);
