import { createContext, useContext } from 'react';

import { IPopover } from '@src/hooks/popover';

const TooltipContext = createContext<IPopover | null>(null);

const useTooltip = (): IPopover | null => {
  return useContext(TooltipContext);
};

export {
  TooltipContext,
  useTooltip,
};
