module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="mobile-confirm-main-container">\n  <span class="login-form__switch-item">Enter your Docyt app mobile PIN</span>\n  <div class="mobile-confirm-code-wrapper">\n    <input type="password" class="pincode-input-js">\n    <p class=\'main-input__hint in-red-400 invalid-pin-code-js\'>\n      Please enter only 6 numbers\n    </p>\n    <p class=\'main-input__hint in-red-400 incorrect-pin-code-js\'>\n      PIN code is incorrect\n    </p>\n    <div class="mobile-confirm-submit-wrapper text-right">\n      <button type=\'submit\' class="btn btn-info ladda-button submit-js" data-color="blue" data-style="expand-right"><span class="ladda-label">Continue</span></button>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}