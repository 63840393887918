module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">Add Another Business</h4>\n      </div>\n      <div class="modal-body modern-body">\n        <div class="flex-wrapper">\n          <div class="width-40-percent text-left m-t-10">Business Name</div>\n          <div class="m-l-auto width-60-percent">\n            <input class="form-control business-display-name-input" type="text" placeholder="Business Display Name (12 characters)">\n            <p class=\'main-input__hint in-red-400\'>\n              You have to input valid business name.\n            </p>\n          </div>\n        </div>\n       \n        <div data-global-feature-toggle="management-group-enabled">\n          <div class="flex-wrapper m-t-10">\n            <div class="width-40-percent text-left m-t-10">Management Group</div>\n            <div class="m-l-auto width-60-percent business-typeahead-wrapper">\n              <div id="management_group_input_wrapper" ></div>\n              <p class=\'main-input__hint in-red-400\'>\n                You have to select a management group.\n              </p>\n            </div>\n          </div>\n        </div>\n      \n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue business-save-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}