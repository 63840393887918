import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IRemoveIconProps extends Omit<IFaIconProps, 'icon'> {
}

const RemoveIcon = ({
  ...props
}: IRemoveIconProps): JSX.Element => {
  return <FaIcon icon="remove" { ...props } />;
};

export default RemoveIcon;
