module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="client-central-businesss-item ');
    
      if (this.id === this.active_business_id) {
        __out.push(__sanitize('active'));
      }
    
      __out.push('">\n  <div class="display-flex align-items-center width-100-percent">\n    ');
    
      if (this.avatar) {
        __out.push('\n    <span class="avatar-wrapper">\n      <img src="');
        __out.push(__sanitize(this.avatar.avatar_url));
        __out.push('" />\n    </span>\n    ');
      } else {
        __out.push('\n    <span class="display-flex icon-business-default-avatar font-34">\n     <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>\n     <span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span>\n    </span>\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.is_client_business) {
        __out.push('\n    <div class="business-profile-wrapper m-l-13">\n      <span class="business-name font-14">');
        __out.push(__sanitize(this.display_name));
        __out.push('</span>\n      <span class="business-name-role in-grey-1050 font-12"><span>');
        __out.push(__sanitize(this.name));
        __out.push('</span>  |  <span>');
        __out.push(__sanitize(this.current_user_role));
        __out.push('</span></span>\n    </div>\n    ');
      } else {
        __out.push('\n      <span class="business-name m-l-13 font-14">');
        __out.push(__sanitize(this.name));
        __out.push('</span>\n    ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (this.bookkeeping_status === 'blocked') {
        __out.push('\n  <span class="blocked-status-badge">BLOCKED</span>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.has_qbo_connection_history && !this.qbo_connected) {
        __out.push('\n  <span class="icon-unable-to-sync in-red m-l-10"><span class="path1"></span><span class="path2"></span></span>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}