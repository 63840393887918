import { useCallback } from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useUpdateBankAccountReconciliation } from '@src/hooks/queries/bank_account_reconciliations';
import { IUpdateBankAccountReconciliationResponse } from '@src/requests/bank_account_reconciliations';
import {
  IBankAccountReconciliationItem,
  TBankAccountReconciliationItemStatus,
} from '@src/types/bank_account_reconciliations';

const useAcknowledge = (baReconciliationItem: IBankAccountReconciliationItem) => {
  const baReconciliation = useBankAccountReconciliationContext();
  const updateBankAccountReconciliation = useUpdateBankAccountReconciliation();
  const { mutateAsync, isLoading } = updateBankAccountReconciliation;

  const handleAcknowledge = useCallback((
    status: TBankAccountReconciliationItemStatus,
    note?: string,
  ) => {
    const itemData = {
      itemType: baReconciliationItem.itemType,
      status,
      note,
    };
    return mutateAsync(
      { id: baReconciliation.id, bankAccountReconciliationItems: [itemData] },
      {
        onSettled: (resp?: IUpdateBankAccountReconciliationResponse) => {
          if (!resp) return;

          window.Docyt.vent.trigger(
            'month_end:ba_reconciliation:status_change',
            resp.baReconciliation.status,
          );

          if (resp.baReconciliation.status !== 'reconciled') {
            window.Docyt.vent.trigger(
              'month_end:bookkeeping_status:changed',
              { status: 'in-progress' },
            );
          }
        },
      },
    );
  }, [
    baReconciliation,
    baReconciliationItem,
    mutateAsync,
  ]);

  return { handleAcknowledge, isLoading };
};

export default useAcknowledge;
