import React, { useCallback, useEffect, useState } from 'react';

import { IBusiness } from '@src/types/businesses';
import { TServiceType } from '@src/types/services';

import { SelectStandardDocumentModal } from '@src/components/common/select_standard_document';

import ForwardDocumentModal, { IForwardDocumentFormValues } from './forward_document_modal';
import ForwardToBalanceSheetStatementsModal from './forward_to_balance_sheet_statements_modal/modal';
import ForwardToBankStatementModal from './forward_to_bank_statements_modal/forward_to_bank_statements_modal';
import {
  IForwardDocumentData,
  IForwardToBankStatementsFormValues,
  IForwardToBalanceSheetStatementsFormValues,
} from './types';

interface IForwardDocumentWizardProps {
  business: IBusiness,
  currentServiceType: TServiceType,
  documentIds: number[],
  show: boolean,
  onCancel: () => void,
  onDone: (data: IForwardDocumentData) => void,
}

const ForwardDocumentWizard = ({
  business,
  currentServiceType,
  documentIds,
  show,
  onCancel,
  onDone,
}: IForwardDocumentWizardProps): JSX.Element => {
  const [step, setStep] = useState<null | 'forward' | 'selectDocument' | 'bankStatement' | 'balanceSheetStatement'>(show ? 'forward' : null);
  const [data, setData] = useState<IForwardDocumentData | null>(null);

  useEffect(() => {
    setStep(show ? 'forward' : null);
  }, [setStep, show]);

  const handleForwardContinue = useCallback((formData: IForwardDocumentFormValues) => {
    setData(formData);
    if (formData.forwardToService === 'BankStatement') {
      setStep('bankStatement');
    } else if (formData.forwardToService === 'BalanceSheetDocument') {
      setStep('balanceSheetStatement');
    } else {
      onDone(formData);
    }
  }, [setStep, onDone, setData]);

  const handleSelectDocumentCancel = useCallback(() => {
    setStep('forward');
  }, [setStep]);

  const handleSelectDocumentDone = useCallback((standardDocumentId: number) => {
    if (!data) return;

    onDone({ ...data, standardDocumentId });
    setStep(null);
  }, [setStep, onDone, data]);

  const handleForwardToBankStatementDone = useCallback((
    value: IForwardToBankStatementsFormValues,
  ) => {
    if (!data) return;

    onDone({ ...data, bankStatementValues: value });
    setStep(null);
  }, [setStep, onDone, data]);

  const handleForwardToBalanceSheetStatementDone = useCallback((
    value: IForwardToBalanceSheetStatementsFormValues,
  ) => {
    if (!data) return;

    onDone({ ...data, balanceSheetStatementValues: value });
    setStep(null);
  }, [setStep, onDone, data]);

  return (
    <>
      <ForwardDocumentModal
        business={ business }
        currentServiceType={ currentServiceType }
        show={ step === 'forward' }
        onCancel={ onCancel }
        onContinue={ handleForwardContinue }
      />
      <SelectStandardDocumentModal
        toForwardDocument
        business={ business }
        show={ step === 'selectDocument' }
        onCancel={ handleSelectDocumentCancel }
        onDone={ handleSelectDocumentDone }
      />
      <ForwardToBankStatementModal
        business={ business }
        documentIds={ documentIds }
        show={ step === 'bankStatement' }
        onCancel={ handleSelectDocumentCancel }
        onContinue={ handleForwardToBankStatementDone }
      />
      <ForwardToBalanceSheetStatementsModal
        business={ business }
        documentIds={ documentIds }
        show={ step === 'balanceSheetStatement' }
        onCancel={ handleSelectDocumentCancel }
        onContinue={ handleForwardToBalanceSheetStatementDone }
      />
    </>
  );
};

export default React.memo(ForwardDocumentWizard);
