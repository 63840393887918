import React, { useCallback, useState } from 'react';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useImportBudgetFayeChannel } from '@src/hooks/faye/import_budget';
import { useImportBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { dataExportPath } from '@src/routes';
import { IImportBudgetFinishedFayeEvent } from '@src/types/docyt_events/import_budget_finished';

import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Alert from '@src/components/ui_v2/alerts/alert';
import { DataExportInProgressIcon } from '@src/components/utils/icomoon';

import BudgetDetailHeader from './header';
import BudgetDetailsList from './list';

const BudgetDetail = (): JSX.Element => {
  const budget = useReportServiceBudgetContext();
  const [isDraft, setIsDraft] = useState(budget.status === 'draft');
  const [visibleBanner, setVisibleBanner] = useState(false);
  const [importing, setImporting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [successImported, setSuccessImported] = useState(false);

  const importBudget = useImportBudget();
  const { mutate } = importBudget;
  const emptyDivs = Array.from({ length: 15 });

  const handleImportFinished =
      useCallback((fayeData: IImportBudgetFinishedFayeEvent) => {
        if (fayeData.event.status === 'success') {
          setSuccessImported(true);
          setIsDraft(true);
        } else setErrorMsg(`${budget.type} line Items doesn’t match`);

        setImporting(false);
        window.location.reload();
      }, []);

  const onCreateExportData = (value: boolean) => {
    setVisibleBanner(value);
  };

  const onSuccessUpload = (token: string) => {
    setImporting(true);

    mutate({
      fileToken: token,
      budgetId:  budget.id.toString(),
    }, {
      onSuccess: () => {},
    });
  };

  useImportBudgetFayeChannel(budget.id, handleImportFinished);

  return (
    <>
      <BudgetDetailHeader isDraft={ isDraft } />
      { successImported && <SuccessNotification message={ `${budget.type} has been imported successfully` } title="Imported Successfully" onHidden={ () => setSuccessImported(false) } /> }
      { errorMsg && <ErrorNotification message={ errorMsg } title="Import Failed" onHidden={ () => setErrorMsg(undefined) } /> }
      {importing
        ? (
          <div className="importing_progress_wrap">
            <Spinner />
            <h3 className="text-center font-semi-bold font-20 m-b-20">{ `${budget.type} is being processed` }</h3>
            <div className="importing_progress">
              <div className="empty_line m-b-56" />
              {emptyDivs.map((el) => (
                <div key={ `empty_line-${el}` } className="empty_sub_line_wrap m-b-36">
                  <div className="empty_sub_line empty_sub_first_line" />
                  <div className="empty_sub_line empty_sub_second_line" />
                </div>
              ))}
            </div>
          </div>
        )
        : (
          <>
            {visibleBanner && (
              <div className="report-budget-export-banner-container">
                <Alert
                  className="m-t-15 m-r-60 m-b-0 m-l-60"
                  icon={ <DataExportInProgressIcon relative fontSize={ 18 } mr={ 8 } style={ { top: '3px' } } /> }
                  variant="info"
                >
                  We’ve started preparing your report. It can take a few seconds to get it done.
                  Once report is ready and available, it will start showing up in the
                  {' '}
                  <a href={ dataExportPath() }>Data Export</a>
                  {' '}
                  section. We will also send you an automated email.
                </Alert>
              </div>
            )}
            <div className="report-budget-detail-container">
              <div className="report-budget-detail-list">
                <BudgetDetailsList
                  isDraft={ isDraft }
                  onCreateExportData={ onCreateExportData }
                  onSetDraft={ setIsDraft }
                  onSuccessUpload={ onSuccessUpload }
                />
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default BudgetDetail;
