import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const LaunchIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="launch"
      pathsNumber={ 1 }
    />
  );
});

LaunchIcon.displayName = 'LaunchIcon';

export default LaunchIcon;
