module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.rcPaymentAccount || this.archived === true) {
        __out.push('\n  <div class="payment-account-list row"></div>\n');
      } else {
        __out.push('\n  <table id="banking-accounts-table" class="table banking-accounts-table">\n    <thead>\n      <tr>\n        <th width="6%"></th>\n        <th>Banking Account</th>\n        <th width="10%">Last 4 Digits</th>\n        <th width="20%">Chart of Account</th>\n        ');
        if (!this.for_onboarding_setup) {
          __out.push('\n          ');
          if (!this.type) {
            __out.push('\n            <th width="10%">Last Sync Date</th>\n            <th width="8%">Balance</th>\n            <th width="10%"></th>\n            <th width="3%"></th>\n            <th width="3%"></th>\n          ');
          }
          __out.push('\n          <th width="3%"></th>\n        ');
        }
        __out.push('\n      </tr>\n    </thead>\n    <tbody class="payment-account-list">\n    </tbody>\n  </table>\n');
      }
    
      __out.push('\n');
    
      if (this.rcPaymentAccount && this.archived === false) {
        __out.push('\n  <div class="payment-account-item-wrapper new-account-view">\n    <div class="payment-account-item add-payment-accounts-wrapper">\n      <a class="add-payment-account-link pointer">\n        <span class="icon icon-o-plus font-56"></span>\n        <h2 class="font-18 font-bold">Add Employee Card</h2>\n      </a>\n    </div>\n  </div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}