import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { metricsValuePath } from '@src/routes';
import { IMetric } from '@src/types/metrics';

import ServiceSidebar from '@src/components/ui_v2/service_sidebar';
import { Button } from '@src/components/ui_v2/buttons';
import {
  PlusIcon,
} from '@src/components/utils/icomoon';

import { useImportMetricsServiceModal } from '../modal/import_metric_service_modal';

import styles from './styles.module.scss';

interface IBusinessMetricsProps {
  businessMetrics: IMetric[]
}

const BusinessMetricsSidebar = ({ businessMetrics }: IBusinessMetricsProps): JSX.Element  => {
  const business = useBusinessContext();
  const service = useMetricsServiceContext();
  const importMetricsServiceModal = useImportMetricsServiceModal();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.METRIC_REPORT_MAPPING_TEMPLATE,
  );

  return (
    <>
      <ServiceSidebar.SubMenu
        expandedKey="business_metrics_expanded"
        title="Business Metric"
      >
        {
          businessMetrics?.map((metric) => {
            return (
              <ServiceSidebar.Item
                key={ metric.id }
                isHighlightRequired
                href={ metricsValuePath(business.id, metric.id) }
                title={ metric.name }
              />
            );
          })
        }

        {featureQuery.data && (
        <Button className={ styles['custom-button'] } variant="link" onClick={ importMetricsServiceModal.open }>
          <PlusIcon fontVariant="bold" />
          {' '}
          Import Data
        </Button>
        )}
      </ServiceSidebar.SubMenu>
      <ServiceSidebar.Separator />
      <importMetricsServiceModal.Component
        businessId={ business.id }
        businessName={ `${business.name}` }
        serviceEmail={ `${service?.uploadEmail}` }
        standardIndustryId={ business.standardCategoryId }
        { ...importMetricsServiceModal.props }
      />
    </>
  );
};

export default BusinessMetricsSidebar;
