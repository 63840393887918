import React from 'react';

import classNames from 'classnames';

import { AppLink } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface IWidgetItemProps {
  itemUrl: string,
  title: string,
  active: boolean,
}

const WidgetItem = ({
  itemUrl,
  title,
  active,
}: IWidgetItemProps) => {
  const classes = classNames(
    styles['widget-item'],
    {
      [styles['widget-item-active']]: active,
    },
  );
  return (
    <li className={ classes }>
      <AppLink className={ styles['widget-item-link'] } href={ itemUrl }>
        { title }
      </AppLink>
    </li>
  );
};

export default WidgetItem;
