import ActionsColumn from './actions_column';
import AmountColumn from './amount_column';
import ButtonColumn from './button_column';
import CollectionTable from './collection_table';
import Column from './column';
import DateColumn from './date_column';
import IconColumn from './icon_column';
import LabelColumn from './label_column';
import SelectItemColumn from './select_item_column';
import TextColumn from './text_column';

export default Object.assign(
  CollectionTable,
  {
    ActionsColumn,
    AmountColumn,
    ButtonColumn,
    Column,
    DateColumn,
    IconColumn,
    LabelColumn,
    TextColumn,
    SelectItemColumn,
  },
);
