import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const MultiCustomReportIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="multi-custom-report"
      pathsNumber={ 2 }
    />
  );
};

export default MultiCustomReportIcon;
