import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import {
  IFormFieldWrapperProps,
} from '@src/components/ui/form';

import { IBankAccountData } from '../schema';
import { useSelectBankAccount } from './bank_account_selector';
import BankAccountEditField from './bank_accounts_field/bank_accounts_edit_field';
import BankAccountSelectField from './bank_accounts_field/bank_accounts_select_field';

import styles from '../styles.module.scss';

interface IBankAccountsFieldProps extends
  Omit<IFormFieldWrapperProps, 'children'>
{
  value?: IBankAccountData,
  businessId: TID,
  isEditable?: boolean,
  error?: string,
  onChange: (value: IBankAccountData) => void,
}

const BankAccountsField = ({
  value,
  businessId,
  isEditable = true,
  error,
  onChange,
}: IBankAccountsFieldProps) => {
  const handleSelectorDone = useCallback((bankAccount: IBankAccountData) => {
    onChange({
      financialInstitutionName: bankAccount.financialInstitutionName,
      financialInstitutionLogo: bankAccount.financialInstitutionLogo,
      bankAccountId:            bankAccount.bankAccountId,
      bankAccountName:          bankAccount.bankAccountName,
      bankAccountLast4:         bankAccount.bankAccountLast4,
    });
  }, [onChange]);

  const selector = useSelectBankAccount({ onDone: handleSelectorDone });

  return (
    <>
      <selector.Component
        { ...selector.props }
        businessId={ businessId }
      />
      <div className={ styles['bank-accounts-field'] }>
        <div className="value-container">
          <span className="m-b-10">Mapped Financial Institution Account</span>
          {
            value?.bankAccountId
              ? (
                <BankAccountEditField
                  isEditable={ isEditable }
                  value={ value }
                  onEdit={ selector.open }
                />
              )
              : (
                <BankAccountSelectField
                  error={ error }
                  onSelect={ selector.open }
                />
              )
          }
        </div>
      </div>
    </>
  );
};

export default BankAccountsField;
