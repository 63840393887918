module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, country, i, j, len, len1;
    
      __out.push('<div class="setup-business-step-section setup-business-detail-section">\n  <div class="main-panel">\n    <h2 class="panel-title">Add Business Details</h2>\n    <p class="panel-description">\n      Add details about your Accounting / Tax / Bookkeeping Firm.\n    </p>\n    <div class="setup-business-detail-wrapper">\n      <div class="setup-business-detail-content">\n        <form class="business-name-setup-form" onsubmit="return false;">\n          <div class="form-group">\n            <label class="form-label">Display Name</label>\n            <input class="form-control input-display-name business-display-name-input" name="display_name" type="text" placeholder="Enter the formal business name" value="');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('">\n          </div>\n          <div class="form-group">\n            <label class="form-label">Legal Entity Name *</label>\n            <input class="form-control input-name business-name-input" name="name" type="text" placeholder="Business Legal Entity Name" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n            <p class=\'main-input__hint valid-hint-js in-red-400 invalid-name\'><i class="fa fa-warning m-r-5"></i>Please input the business name.</p>\n          </div>\n          <div class="form-group">\n            <label class="form-label">Contact details</label>\n            <input class="form-control input-phone phone-number-input" type="text" value="');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('" placeholder="Business Phone number">\n            <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i>Please input the business phone number.</p>\n          </div>\n          <div class="form-group">\n            <input class="form-control input-email email-input" type="text" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('" placeholder="Business Email">\n            <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i>Please input the business email.</p>\n          </div>\n          <div class="form-row address-inputs-container">\n            <label class="form-label">Address *</label>\n            <div class="form-group">\n              <div class="form-group col-md-12 p-l-0">\n                <label for="inputAddress">Street</label>\n                <input type="text" class="input-address-street form-control" id="inputAddress" placeholder="1234 Main St" value="');
    
      __out.push(__sanitize(this.address_street));
    
      __out.push('">\n                <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i>Please input the street address.</p>\n              </div>\n              <div class="form-group col-md-12 p-l-0">\n                <label for="inputAddress2">Street (continued)</label>\n                <input type="text" class="input-address-street2 form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" value="');
    
      __out.push(__sanitize(this.address_street_2));
    
      __out.push('">\n              </div>\n              <div class="form-group col-md-4 p-l-0">\n                <label for="inputCity">City</label>\n                <input type="text" class="input-address-city form-control" id="inputCity" value="');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('">\n                <p class="valid-hint-js business-info m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i>Please input the city address.</p>\n              </div>\n              <div class="form-group col-md-4">\n                <label for="inputState">State</label>\n                <select id="inputState" class="select-state font-15 form-control">\n                  ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n                    ');
        if (address_state.abbreviation === this.address_state) {
          __out.push('\n                    <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" selected>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                    ');
        } else {
          __out.push('\n                    <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('">');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                    ');
        }
        __out.push('\n                  ');
      }
    
      __out.push('\n                </select>\n                <input id="inputState" class="input-address-state input-text-state form-control font-15" type="text" placeholder="State" value="');
    
      __out.push(__sanitize(this.address_state));
    
      __out.push('">\n                <p class="valid-hint-js business-info m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i>Please input the state address</p>\n              </div>\n              <div class="form-group col-md-4">\n                <label for="inputZip">Zip</label>\n                <input type="text" class="input-address-zip form-control" id="inputZip" value="');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n                <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i>Please input the zip address</p>\n              </div>\n              <div class="form-group col-md-12 p-l-0">\n                <label for="inputAddress2">Country</label>\n                <select class="form-control input-address-country select-country-wrapper">\n                  ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n                      ');
        if (this.address_country && this.address_country !== "") {
          __out.push('\n                      <option value="');
          __out.push(__sanitize(country.code));
          __out.push('" ');
          if (country.code === this.address_country) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(country.text));
          __out.push('</option>\n                      ');
        } else {
          __out.push('\n                      <option value="');
          __out.push(__sanitize(country.code));
          __out.push('" ');
          if (country.code === 'US') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(country.text));
          __out.push('</option>\n                      ');
        }
        __out.push('\n                  ');
      }
    
      __out.push('\n                </select>\n              </div>\n            </div>\n          </div>\n          <div class="clearfix"></div>\n          <div class="form-group">\n            <label class="form-label">Number of Clients</label>\n            <input class="form-control clients-count-input" type="number" value="');
    
      __out.push(__sanitize(this.clients_count));
    
      __out.push('" placeholder="Enter number of clients">\n          </div>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}