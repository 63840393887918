import React from 'react';

import classNames from 'classnames';

import { IVendorPayment } from '@src/types/vendor_payments';
import { capitalize } from '@src/utils/transform_keys';

import { PaymentStatus } from '../options';
import {
  paymentStatusVariant,
} from '../utils';

import styles from '../styles.module.scss';

type TVendorPaymentStatusProps = {
  vendorPayment: IVendorPayment,
}

const VendorPaymentStatusItem = ({
  vendorPayment,
}: TVendorPaymentStatusProps) => {
  const normalizedPaymentStatus =
    vendorPayment.paymentStatus === PaymentStatus.PAYMENT_ERROR
      ? PaymentStatus.PAYMENT_FAILED
      : vendorPayment.paymentStatus;

  const variant = paymentStatusVariant({ ...vendorPayment,
    paymentStatus: normalizedPaymentStatus });

  const classes = classNames(
    styles.label,
    'pointer',
    styles[`label-${variant}`],
  );

  return (
    <div>
      <span className={ classes }>
        {capitalize(normalizedPaymentStatus)}
      </span>
    </div>
  );
};

export default VendorPaymentStatusItem;
