module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var account, i, len, ref;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">Tasks: ');
    
      __out.push(__sanitize(this.display_name || this.name));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body modern-body">\n        <p class="font-16 font-semi-bold">\n          ');
    
      __out.push(__sanitize(this.tasks_count));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.tasks_count === 1 ? 'Task' : 'Tasks'));
    
      __out.push(' Remaining\n        </p>\n        <div class="m-t-20 business-client-setup-task-list">\n          ');
    
      if (!this.setup_profile.qbo_connect) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Connect to QuickBooks</div>\n            <a class="setup-business-client-link" data-step="2">Connect</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.signature_enabled) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Digital Signature - Bank Authorized Signatory</div>\n            <a class="setup-business-client-link" data-step="3">Add Signature</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.setup_profile.bank_account) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Connect Primary Operating Bank Account</div>\n            <a class="setup-business-client-link" data-step="4">Verify Account</a>\n          </div>\n          ');
      }
    
      __out.push('\n          <!--Activate Services Tasks -->\n          ');
    
      if (!this.activate_services.accounts_payable) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Accounts Payable</div>\n            <a class="not-activated-link pointer" data-service="accounts_payable">Activate</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.activate_services.receipt_box) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Receipt Box</div>\n            <a class="not-activated-link pointer" data-service="receipt_box">Activate</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.activate_services.reconciliation_center) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Banking Accounts</div>\n            <a class="not-activated-link pointer" data-service="banking_accounts">Activate</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.activate_services.inbox) {
        __out.push('\n          <div class="business-client-setup-task">\n            <div class="m-t-10">Business Mailroom</div>\n            <a class="not-activated-link pointer" data-service="inbox_service">Activate</a>\n          </div>\n          ');
      }
    
      __out.push('\n          ');
    
      ref = this.deactivated_accounts;
      for (i = 0, len = ref.length; i < len; i++) {
        account = ref[i];
        __out.push('\n          <div class="business-client-setup-task">\n            <p class="font-14 m-b-0">');
        __out.push(__sanitize(account.type));
        __out.push(' Disconnected</p>\n            <p class="font-12 in-grey-950 m-b-0">');
        __out.push(__sanitize(account.name));
        __out.push(' ');
        __out.push(__sanitize(account.last4));
        __out.push('</p>\n            <a class="not-connected-link pointer" data-account_id="');
        __out.push(__sanitize(account.reconciliation_payment_account_id));
        __out.push('">Connect</a>\n          </div>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <div class="modal-footer">\n        <button type=\'button\' class="btn btn-blue done-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Done</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}