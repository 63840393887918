import React, { useCallback } from 'react';

import { TOrderDirection } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import SortIndicator from '@src/components/utils/sort_indicator';

interface ITableHeaderCellCommonProps extends React.ComponentPropsWithoutRef<'th'>, IUIStyleProps {
}

interface ITableHeaderCellSortableProps<TSortColumn extends string> extends
  ITableHeaderCellCommonProps
{
  columnName: TSortColumn,
  sorting: ISorting<TSortColumn>,
  longArrowIndicator?: boolean,
}

interface ITableHeaderCellNotSortableProps extends ITableHeaderCellCommonProps {
  columnName?: undefined,
  sorting?: undefined,
  longArrowIndicator?: boolean,
}

type TTableHeaderCellProps<TSortColumn extends string = never> =
  ITableHeaderCellNotSortableProps | ITableHeaderCellSortableProps<TSortColumn>;

const TableHeaderCell = <TSortColumn extends string = never>({
  children,
  columnName,
  sorting,
  longArrowIndicator = false,
  ...props
}: TTableHeaderCellProps<TSortColumn>) => {
  const sortedDirection: TOrderDirection | undefined =
    sorting && columnName ? sorting.getColumnSortDirection(columnName) : undefined;

  const [classes, thProps] = uiStyleProps(
    undefined,
    props,
    sorting && 'sortable',
    sortedDirection && `sorted-${sortedDirection}`,
  );

  const { toggleColumSort } = sorting || {};
  const handleSort = useCallback(() => {
    if (!toggleColumSort || !columnName) return;

    toggleColumSort(columnName);
  }, [columnName, toggleColumSort]);

  return (
    <th
      className={ classes }
      onClick={ handleSort }
      { ...thProps }
    >
      { children }
      {
        sorting && longArrowIndicator && (
          <SortIndicator aria-hidden="true" className="m-l-10" direction={ sortedDirection } />
        )
      }
    </th>
  );
};

export {
  ITableHeaderCellSortableProps,
  ITableHeaderCellNotSortableProps,
  TableHeaderCell as default,
};
