import React, { forwardRef } from 'react';

import CheckBoxCollectionInput from '../inputs/check_box_collection_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

import styles from '../styles.module.scss';

interface ICheckBoxCollectionFieldProps extends
  React.ComponentPropsWithoutRef<typeof CheckBoxCollectionInput>,
  Omit<IFormFieldWrapperProps, 'children'>
{
  label: string,
}

const CheckBoxCollectionField = forwardRef<HTMLInputElement, ICheckBoxCollectionFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: ICheckBoxCollectionFieldProps, ref): JSX.Element => {
  if (label === '') {
    return (
      <CheckBoxCollectionInput
        ref={ ref }
        { ...props }
      />
    );
  }

  return (
    <Wrapper
      className={ styles['checkbox-collection-field'] }
      error={ error }
      hint={ hint }
      label={ label }
      mark={ mark }
    >
      <CheckBoxCollectionInput
        ref={ ref }
        { ...props }
      />
    </Wrapper>
  );
});

CheckBoxCollectionField.displayName = 'CheckBoxCollectionField';

export default CheckBoxCollectionField;
