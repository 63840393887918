import React, { useEffect, useState } from 'react';

import last from 'lodash/last';
import { UseInfiniteQueryResult } from 'react-query';

import { TID } from '@src/types/common';
import {
  ITransactionServiceDocument,
} from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';
import { useItemsSelector } from '@src/components/utils/items_selector';

import DuplicatedTransactionTableItem from './duplicated_transaction_table_item';

interface IDuplicatedTransactionsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  transactions: ITransactionServiceDocument[],
  onSelected: (duplicatedIds: TID[]) => void,
}

const DuplicatedTransactionsTable = ({
  query,
  transactions,
  onSelected,
}: IDuplicatedTransactionsTableProps) => {
  const [loadedPage, setLoadedPage] = useState<number>(0);
  const { selected, mark } = useItemsSelector<TID>();

  useEffect(() => {
    onSelected(selected);

    if (query.data?.pages === undefined
      || query.data?.pages.length <= loadedPage
      || query.isRefetching
      || !query.isSuccess) return;

    const newLoadedTransactions = last(query.data?.pages).collection;
    setLoadedPage(loadedPage + 1);

    const notDuplicated = localStorage.getItem('notDuplicated');
    if (!notDuplicated) return;

    const notDuplicatedIds = JSON.parse(notDuplicated)?.ids;
    if (!notDuplicatedIds) return;

    newLoadedTransactions.forEach(
      (transaction: ITransactionServiceDocument) => {
        if (!notDuplicatedIds.includes(transaction.id)) {
          mark(transaction.id, true);
        }
      },
    );
  }, [onSelected, selected, query, loadedPage, mark]);

  return (
    <Table
      className="banking-transactions-table"
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.SelectAllHCell widthPercent={ 12 } />
          <Table.HCell
            widthPercent={ 12 }
          >
            Docyt ID
          </Table.HCell>
          <Table.HCell
            widthPercent={ 12 }
          >
            Date
          </Table.HCell>
          <Table.HCell
            widthPercent={ 20 }
          >
            Description
          </Table.HCell>
          <Table.HCell
            widthPercent={ 12 }
          >
            Type
          </Table.HCell>
          <Table.HCell
            widthPercent={ 15 }
          >
            Account
          </Table.HCell>
          <Table.HCell
            textAlign="right"
            widthPercent={ 10 }
          >
            Amount
          </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          transactions.map((transaction) => (
            <DuplicatedTransactionTableItem
              key={ transaction.id }
              transaction={ transaction }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(DuplicatedTransactionsTable);
