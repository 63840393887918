module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountOwner, i, len, ref;
    
      __out.push('<h3 class="right-side-subtitle">');
    
      __out.push(__sanitize(this.accName));
    
      __out.push('</h3>\n<p st>');
    
      __out.push(__sanitize(this.businessName));
    
      __out.push('</p>\n<hr />\n<div class="document-field">\n  <div class="document-field-item document-field-name">Account Name</div>\n  <div class="document-field-item document-field-value">\n    <input class="account-name-input form-control" type="text" value="');
    
      __out.push(__sanitize(this.accName));
    
      __out.push('" placeholder="Bank Name - XXXX" />\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Account Name is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Chart of Accounts</div>\n  <div class="document-field-item document-field-value" style="background: transparent;">\n    <span class="pull-left font-14 text-grey m-t-10 p-t-0 chart-of-account-name-label" style="');
    
      __out.push(__sanitize(!this.archived ? 'width: calc(100% - 30px);' : void 0));
    
      __out.push('" type="text" readonly>Select Chart of Accounts</span>\n    ');
    
      if (!this.archived) {
        __out.push('\n    <span class="pull-right font-16 font-bold"><a class="pointer select-chart-of-account-btn">Edit</a></span>\n    ');
      }
    
      __out.push('\n  </div>\n  <p class="in-red-400 main-input__hint" style="padding-top: 0;"><i class="fa fa-warning"></i> Chart of Accounts is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">');
    
      __out.push(__sanitize(this.isCreditCard ? 'Credit Card No.' : 'Account No.'));
    
      __out.push('<div class="optional-field-mark">(last 4 digits)</div></div>\n  <div class="document-field-item document-field-value">\n    <input class="account-number-input form-control" type="text" value="');
    
      __out.push(__sanitize(this.last4));
    
      __out.push('" maxlength="4" ');
    
      if (this.isAutoImportType) {
        __out.push(__sanitize('readonly'));
      }
    
      __out.push(' />\n  </div>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Starting Date</div>\n  <div class="document-field-item document-field-value">\n    <input class="starting-date-input form-control" type="text" placeholder="Starting Date" autocomplete="off" />\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Starting Date is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Starting Balance</div>\n  <div class="document-field-item document-field-value">\n    <input class="starting-balance-input form-control" type="text" value="');
    
      __out.push(__sanitize(this.start_balance));
    
      __out.push('" placeholder="Starting Balance (Optional)" />\n  </div>\n  ');
    
      if (this.isCreditCard) {
        __out.push('\n  <p class="font-12 p-t-5" style="margin-left: 30%;">If you owe money, please, enter a negative amount</p>\n  ');
      }
    
      __out.push('\n</div>\n');
    
      if (this.isEmployeeCard) {
        __out.push('\n<div class="document-field">\n <div class="document-field-item document-field-name">Account Owner<div class="optional-field-mark">(optional)</div></div>\n  <div class="document-field-item document-field-value" style="position: relative">\n    <input class="account-owner-input form-control" style="position:absolute; z-index: -1" type="text" value="');
        __out.push(__sanitize(this.account_owner));
        __out.push('" placeholder="Account Owner (Optional)" />\n    <select id="account-owner-select" class="form-control" title="Account Owner (Optional)">\n      <option value="" ');
        if (this.accountOwners.length === 0) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>None</option>\n      ');
        ref = this.accountOwners;
        for (i = 0, len = ref.length; i < len; i++) {
          accountOwner = ref[i];
          __out.push('\n      <option value="');
          __out.push(__sanitize(accountOwner));
          __out.push('" ');
          if (this.account_owner === accountOwner) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(accountOwner));
          __out.push('</option>\n      ');
        }
        __out.push('\n    </select>\n  </div>\n</div>\n');
      } else {
        __out.push('\n<div class="document-field">\n  <div class="document-field-item document-field-name">Transaction Import</div>\n  <div class="document-field-item document-field-value">\n    <select class="transaction-import-type-select form-control" ');
        __out.push(__sanitize(this.archived ? 'disabled' : ''));
        __out.push('>\n      <option value="auto">Automatic</option>\n      <option value="manual">Manual</option>\n    </select>\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Transaction Import Type is required.</p>\n</div>\n');
      }
    
      __out.push('\n<div class="document-field">\n  <button class="btn connect-plaid-btn unconnected" disabled>\n    <img src="');
    
      __out.push(__sanitize(configData.banking_accounts_images.TRANSACTION_IMPORT));
    
      __out.push('" class=\'auto_import_transactions-img\'>\n    <div class="plaid-connected">\n      <h3>Auto Import Transactions</h3>\n      <span class="plaid-error-badge icon icon-warning-triangle"></span>\n      <span class="plaid-connected-badge">Connected</span>\n    </div>\n    <div class="plaid-unconnected">\n      <h3>Secure Connect to Financial Institution</h3>\n    </div>\n  </button>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}