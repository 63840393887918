import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useMergerCustomer } from '@src/hooks/queries/quickbooks_customers';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IMergeCustomerFormValues } from './merge_customer_modal_form';

import styles from './styles.module.scss';

interface IMergeCustomerModalProps extends IUseModalProps {
  businessId: TID,
  customerId: TID,
}

const MergeCustomerModal = ({
  businessId,
  customerId,
  isOpen,
  onCancel,
  onDone,
}: IMergeCustomerModalProps) => {
  const mergeCustomer = useMergerCustomer();

  const { mutate } = mergeCustomer;
  const handleSubmit = useCallback((values: IMergeCustomerFormValues) => {
    mutate(
      {
        newCustomerId: customerId,
        businessId,
        oldCustomerId: values.selectedCustomerId,
      },
      {
        onSuccess: () => {
          window.Docyt.vent.trigger('customer:merged:success');
          onDone();
        },
      },
    );
  }, [businessId, customerId, onDone, mutate]);

  return (
    <>
      <MutationStatus mutation={ mergeCustomer } successMessage="Merging has started and will be finished soon." />
      <Modal.Form
        className={ styles['customer-merge-modal'] }
        proceedTitle="Merge"
        show={ isOpen }
        title="Merge Customers"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            businessId={ businessId }
            customerId={ customerId }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useMergeCustomerModal = makeUseModal(MergeCustomerModal);

export {
  IMergeCustomerModalProps,
  useMergeCustomerModal,
  MergeCustomerModal as default,
};
