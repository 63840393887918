import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Details from './details';

interface IJournalEntryProps {
  transactionId: TID,
  adjustmentEntry: any,
}

const JournalEntrySection = ({
  transactionId,
  adjustmentEntry,
}: IJournalEntryProps): JSX.Element => {
  return (
    <Root>
      <Details adjustmentEntry={ adjustmentEntry } transactionId={ transactionId } />
    </Root>
  );
};

export default JournalEntrySection;
