module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, itemAccountName, len, ref;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog person-edit-form-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">\n        ');
    
      if (this.isMultiUnlink) {
        __out.push('\n        Unlink Multiple Chart of Accounts\n        ');
      } else {
        __out.push('\n        Unlink Chart of Account\n        ');
      }
    
      __out.push('\n        </h4>\n      </div>\n        ');
    
      if (this.isMultiUnlink) {
        __out.push('\n          <div class="modal-body text-left">\n            <p class="m-t-22 font-16">If you unlink an account with existing transactions, then you will need to take one of the following actions to maintain sanctity of your report:</p>\n            <ul class="tips-desc m-l-16 font-16 text-left">\n              <li>Point the account being unlinked to another parent category of SAME type; OR</li>\n              <li>Merge the account being unlinked with another account.</li>\n            </ul>\n            <p class="m-t-22 font-16">Are you sure you want to unlink follwowing chart of accounts from Food and Beverage?</p>\n            ');
        ref = this.accountNames;
        for (i = 0, len = ref.length; i < len; i++) {
          itemAccountName = ref[i];
          __out.push('\n            <div class="item-divider font-14 line-height-42">');
          __out.push(__sanitize(itemAccountName));
          __out.push('</div>\n            ');
        }
        __out.push('\n          </div>\n        ');
      } else {
        __out.push('\n          <div class="modal-body text-center">\n            <p class="m-t-22 font-16">Are you sure you want to unlink chart of account <b>“');
        __out.push(__sanitize(this.accountNames[0]));
        __out.push('”</b> from ');
        __out.push(__sanitize(this.childItem.get('name')));
        __out.push('?</p>\n          </div>\n        ');
      }
    
      __out.push('\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n        <button type="submit" class="btn btn-blue btn-save" data-color="blue" data-style="expand-right">Unlink</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}