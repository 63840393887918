import React from 'react';

import { useGetAccountsPayableServiceByBusinessId } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';

import Text from '@src/components/ui/text';

import styles from './styles.module.scss';

interface ISettlementsHeaderProps {
  businessId: TID,
  type: string,
}

const SettlementsHeader = ({
  type,
  businessId,
}: ISettlementsHeaderProps): JSX.Element | null => {
  const { data: accountsPayableServices } = useGetAccountsPayableServiceByBusinessId(businessId);

  return (
    <div className="page-main-header clearfix">
      <div className={ styles['header-container'] }>
        <div className="header-title">
          <Text fontSize={ 24 } fontVariant="bold">Settlement Items</Text>
        </div>
        <ul className="nav sub-header-menu settlement-sub-menu m-t-0 m-b-0">
          <li className={ `m-r-48 ${type === 'settle_queued' && 'active'}` }>
            <a
              href={ `/businesses/${businessId}/accounts_payable/settlement_items?type=settle_queued` }
              role="button"
              tabIndex={ 0 }
            >
              { `QUEUED (${accountsPayableServices?.countQueuedSettled || 0})` }
            </a>
          </li>
          <li className={ `${type === 'settle_paid' && 'active'}` }>
            <a
              href={ `/businesses/${businessId}/accounts_payable/settlement_items?type=settle_paid` }
              role="button"
              tabIndex={ 0 }
            >
              { `SETTLED (${accountsPayableServices?.countPaidSettled || 0})` }
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettlementsHeader;
