import Item from './item';
import ServiceSidebar from './service_sidebar';
import SubItem from './sub_item';
import SubMenu from './sub_menu';

export default Object.assign(ServiceSidebar, {
  SubMenu,
  Item,
  SubItem,
});
