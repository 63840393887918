import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IGetAchTransactionsParams, IGetAchTransactionsResponse, getAchTransaction, getAchTransactions } from '@src/requests/accounts_payable/ach_transactions';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TNumber } from '@src/types/common';

import {
  createUseGetInfiniteCollection,
} from '../infinite_collection_queries';

const useGetAchTransactions = createUseGetInfiniteCollection<
  IAchTransaction,
  IGetAchTransactionsParams,
  IGetAchTransactionsResponse
>({
  queryKey: QueryKey.accountsPayableAchTransactions,
  request:  getAchTransactions,
});

const useGetLimitedAchTransactions = (
  params: IGetAchTransactionsParams,
) => {
  return useQuery<IGetAchTransactionsResponse, Error>(
    [QueryKey.accountsPayableAchTransactions, params],
    () => getAchTransactions(params),
  );
};

const useGetAchTransaction = (id: TNumber) => {
  return useQuery<IAchTransaction, Error>(
    [QueryKey.accountsPayableAchTransaction, id],
    () => getAchTransaction(id),
  );
};

export {
  useGetAchTransactions,
  useGetLimitedAchTransactions,
  useGetAchTransaction,
};
