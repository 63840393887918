import { object, string, number } from 'yup';

import { TID } from '@src/types/common';

const confirmValidation = object({
  businessId: number().required('You have to select business'),
  name:       string().trim().required('You have to input valid business name.'),
});

interface IAddBusinessClientFormValues {
  businessId: TID,
  name: string,
}

export {
  IAddBusinessClientFormValues,
  confirmValidation,
};
