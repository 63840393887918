import { createContext, useContext } from 'react';

import { IBankAccountReconciliationWithLoading } from '@src/types/bank_account_reconciliations';

const BankAccountReconciliationContext =
    createContext<IBankAccountReconciliationWithLoading | null>(null);

const useBankAccountReconciliationContext =
  () => <IBankAccountReconciliationWithLoading>useContext(BankAccountReconciliationContext);

export {
  BankAccountReconciliationContext,
  useBankAccountReconciliationContext,
};
