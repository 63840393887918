import React from 'react';

import castArray from 'lodash/castArray';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Text from '@src/components/ui/text';

import Badge from './badge';
import Breadcrumb from './breadcrumb';
import CopyClipboard from './copy_clipboard';

import styles from './styles.module.scss';

interface IBreadcrumb {
  title: React.ReactNode,
  href?: string,
}

interface IDetailsPageTitleProps extends IUIStyleProps {
  badge?: React.ReactNode,
  breadcrumbs?: IBreadcrumb | IBreadcrumb[],
  children?: React.ReactNode,
  subtitle?: React.ReactNode,
  title: React.ReactNode,
  isSmallTitle?: boolean,
  markIcon?: React.ReactNode,
  copyUrl?: string,
}

const DetailsPageTitle = ({
  badge,
  breadcrumbs,
  children,
  subtitle,
  title,
  isSmallTitle = false,
  markIcon,
  copyUrl,
  ...props
}: IDetailsPageTitleProps): JSX.Element => {
  const [classes] = uiStyleProps(styles['details-page-title'], props);
  const crumbs = breadcrumbs && castArray(breadcrumbs);

  return (
    <div className={ classes }>
      <div className={ styles.title }>
        <div className={ styles.breadcrumbs }>
          { markIcon && (markIcon) }
          { crumbs && crumbs.map((crumb) => (
            <Breadcrumb key={ crumb.href } href={ crumb.href } title={ crumb.title } />
          )) }
          {
            isSmallTitle ? (
              <span>{ title }</span>
            ) : (
              <Text as="h2">{ title }</Text>
            )
          }
          { badge && <Badge title={ badge } /> }
          { copyUrl && <CopyClipboard copyUrl={ copyUrl } /> }
        </div>
        { subtitle && <div className={ styles.subtitle }>{ subtitle }</div> }
      </div>
      { children && (
        <div className={ styles.actions }>
          { children }
        </div>
      ) }
    </div>
  );
};

DetailsPageTitle.Badge = Badge;
DetailsPageTitle.Breadcrumb = Breadcrumb;

export default DetailsPageTitle;
