import React from 'react';

import { TDate } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import Cell, { ITableCellProps } from './cell';

interface ITableDateCellProps extends Omit<ITableCellProps, 'children'> {
  date: TDate | null | undefined,
  emptyValue?: React.ReactNode,
}

const TableDateCell = ({
  date,
  emptyValue,
  ...cellProps
}: ITableDateCellProps) => {
  return (
    <Cell { ...cellProps }>
      { (date && formatDate(date)) || emptyValue }
    </Cell>
  );
};

export default TableDateCell;
