import React, { useCallback, useMemo } from 'react';

import { useController, useForm } from 'react-hook-form';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';
import { IMultiReportDetailBusiness } from '@src/types/report_service/multi_report_detail';

import { ErrorNotification } from '@src/components/ui/notification';

import BusinessItem from './business_item';
import { IUpdateBusinessFormValues } from './schema';

import styles from '../styles.module.scss';

interface IEditBusinessFormProps {
  businesses: IBusiness[],
  formId: string,
  item: IMultiBusinessReportDetail,
  onSubmit: (data: IUpdateBusinessFormValues) => void,
}

type BusinessItemType = {
  avatar: string,
  label: string,
  value: number,
  availableStatus: boolean,
}

const EditBusinessForm = ({
  businesses,
  formId,
  item,
  onSubmit,
}: IEditBusinessFormProps) => {
  const checkedBusinessData = useMemo(() => (
    item.businesses.map((itm: IMultiReportDetailBusiness) => itm.id)
  ), [item.businesses]);

  const businessData = useMemo(() => {
    const tmpData = businesses.map((business: IBusiness) => (
      {
        avatar:          business.avatarUrl,
        label:           business.displayName,
        value:           business.id,
        availableStatus: !!business?.availableStatus,
      }
    )) as BusinessItemType[];

    return tmpData
      .filter((v: BusinessItemType) => checkedBusinessData.includes(v.value))
      .concat(tmpData.filter((v: BusinessItemType) => !checkedBusinessData.includes(v.value)));
  }, [businesses, checkedBusinessData]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateBusinessFormValues>({
    defaultValues: {
      reportServiceIds: checkedBusinessData,
    },
  });

  const businessItemController = useController({
    name:  'reportServiceIds',
    control,
    rules: { required: true },
  });

  const handleSelect = useCallback((checked: boolean, val: string | undefined) => {
    let tmpData = businessItemController.field.value;

    if (checked) {
      const find = tmpData.find((f: number) => f === Number(val));

      if (!find) {
        tmpData.push(Number(val));
      }
    } else {
      tmpData = tmpData.filter((f: number) => f !== Number(val));
    }

    businessItemController.field.onChange(tmpData);
  }, [businessItemController]);

  const handleDone = useCallback((data: IUpdateBusinessFormValues) => {
    onSubmit({
      id:               item.id,
      reportServiceIds: data.reportServiceIds,
    });
  }, [item.id, onSubmit]);

  return (
    <form className={ styles['height-500px-with-scroll'] } id={ formId } onSubmit={ handleSubmit(handleDone) }>
      { errors.reportServiceIds ? <ErrorNotification message="Please Select Businesses" title="Warning!" /> : null }

      <ul>
        { businessData.map((itm: BusinessItemType) => (
          <BusinessItem
            key={ itm.value }
            active={ itm.availableStatus }
            avatar={ itm.avatar }
            field={ businessItemController.field }
            label={ itm.label }
            value={ itm.value }
            onSelect={ handleSelect }
          />
        )) }
      </ul>
    </form>
  );
};

export default React.memo(EditBusinessForm);
