import React, { ComponentPropsWithoutRef } from 'react';

import Body from '../body';
import Root, { IModalProps } from '../root';
import StandardFooter from './standard_footer';

interface IStandardModalProps extends ComponentPropsWithoutRef<typeof StandardFooter> {
  /** Content of modal dialog. */
  children: () => React.ReactNode,
  className?: IModalProps['className'],
  dialogClassName?: IModalProps['dialogClassName'],
  /** Show modal. Usually just pass `isOpen` prop provided by `makeUseModal` wrapper. */
  show?: IModalProps['show'],
  /** Show close button at modal header */
  showClose?: IModalProps['showClose'],
  /** Modal dialog title */
  title: IModalProps['title'],
}

/**
 * Standard modal dialog with content and "Cancel" and "Proceed" buttons.
 *
 * Use this components if you want to show modal or use [Modal.Form](#/Modal/FormModal)
 * if modal dialog have form that should be submitted by clicking on "Proceed" button
 * @visibleName Modal.Standard
 */
const StandardModal = ({
  children,
  className,
  dialogClassName,
  show,
  showClose,
  title,
  ...footerProps
}: IStandardModalProps) => {
  return (
    <Root
      className={ className }
      dialogClassName={ dialogClassName }
      show={ show }
      showClose={ showClose }
      title={ title }
      { ...footerProps }
    >
      <Body>
        { show && children() }
      </Body>
      <StandardFooter { ...footerProps } />
    </Root>
  );
};

export default StandardModal;
