import React, { useEffect, useMemo } from 'react';

import { useRecoilState } from 'recoil';

import { IReport, ITemplate } from '@src/types/report_service/report';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import Wrapper from '@src/components/ui_v2/filter/filter_field_wrapper';
import { TSubOption } from '@src/components/ui_v2/inputs';

import BusinessReportsMultiSelect from './multi_select/multi_select';
import { reportsExportValueType } from '../atoms';

interface IBusinessReportsMultiSelectFieldProps extends 
  Omit<React.ComponentPropsWithoutRef<typeof BusinessReportsMultiSelect>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps {
    reports:   IReport[],
    templates:  ITemplate[],
    category:   string,
    type:       string,
    slug:       string
    onChange?:  (val: string[]) => void,
    preSelectedValues?: string[],
}

// Removed Basic category as it is not a part of consolidate report
// Removed Labor category as backend is not ready yet for this category
const categories = [
  {
    label: 'Revenue',
    value: 'revenue'
  },
  {
    label: 'Expense',
    value: 'expense'
  },
  {
    label: 'Management Reports',
    value: 'management'
  },
  {
    label: 'Departmental Reports',
    value: 'department'
  },
]

const BusinessReportsMultiSelectField = ({
  reports,
  templates,
  category,
  type,
  slug,
  onChange,
  preSelectedValues = [],
  ...props
}: IBusinessReportsMultiSelectFieldProps) => {
  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const [multiValue, setMultiValue] = useRecoilState(reportsExportValueType);

  const options = useMemo(() => {
    const categoriesData = categories.map((cat: TSubOption) => {
      const reportData = reports.filter((report: IReport) => (
        templates.find((template: ITemplate) => (
          template.id === report.templateId && template.category === cat.value
        ))
      ));
  
      return {
        ...cat,
        ...(reportData?.length !== 0
          ? {
              children: reportData?.map((v: IReport) => ({
                label: v.name,
                value: v.id
              }))
            }
          : {}),
      }
    })
    return categoriesData.filter(item => !!item.children);
  }, [reports, templates]);

  const selectedOptions = useMemo(() => {
    if(preSelectedValues.length > 0) return preSelectedValues;
    const filter = reports.filter((report: IReport) => {
      if (type === 'report') {
        return report.slug === slug;
      }
      // Hiding option to select labor report for now. Wil remove this condition When backend is ready
      return templates.find((template: ITemplate) => (
        category !== 'labor' && template.id === report.templateId && template.category === category
      ));
    });

    return filter?.map((v: IReport) => v.id);
  }, [reports, type, templates, slug, category]);

  useEffect(() => {
    if (selectedOptions) setMultiValue(selectedOptions);
  }, [selectedOptions, setMultiValue]);

  const handleChange = (opt: string[]) => {
    setMultiValue(opt);
    if(onChange){
      onChange(opt)
    }
  }

  return (
    <Wrapper { ...wrapperProps }>
      <BusinessReportsMultiSelect
        options={ options }
        value={ multiValue }
        { ...inputProps }
        onChange={ handleChange }
      />
    </Wrapper>
  );
}

export default BusinessReportsMultiSelectField;