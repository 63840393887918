import React from 'react';

import Select, {
  components,
  GroupBase,
  Props,
  StylesConfig,
} from 'react-select';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import {
  CommonSelectProps,
  ReactSelectControl,
  ReactSelectDropdownIndicator,
  ReactSelectStyles,
  WideMenuStyles,
} from './helpers';

import styles from '../../styles.module.scss';

type TIconOption = {
  label: string,
  value: string | undefined,
  icon: React.ReactElement,
}

interface IIconSelectInputProps<
  TOption extends TIconOption = TIconOption,
  TIsMulti extends boolean = false
> extends
  Props<TOption, TIsMulti, GroupBase<TOption>>,
  CommonSelectProps
{
}

const formatOptionLabel = (
  item: TIconOption,
  { context }: { context: 'menu' | 'value' },
): JSX.Element => {
  return (
    <div className={ styles['icon-select-option'] }>
      {
        context === 'menu' && <span className="option-icon">{ item.icon }</span>
      }
      <span className="option-label">{ item.label }</span>
    </div>
  );
};

const IconSelectInput = <
  TOption extends TIconOption = TIconOption,
  TIsMulti extends boolean = false
>({
    components: componentsProp = {},
    hideIndicators,
    styles: stylesProp,
    wideMenu,
    ...props
  }: IIconSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  return (
    <Select
      className={ classes }
      components={ {
        Control:             ReactSelectControl,
        DropdownIndicator:   ReactSelectDropdownIndicator,
        IndicatorsContainer: hideIndicators ? () => null : components.IndicatorsContainer,
        ...componentsProp,
      } }
      formatOptionLabel={ formatOptionLabel }
      styles={ {
        ...ReactSelectStyles as StylesConfig<TOption, TIsMulti>,
        ...(wideMenu ? WideMenuStyles : {}) as StylesConfig<TOption, TIsMulti>,
        ...stylesProp,
      } }
      { ...selectProps }
    />
  );
};

export {
  TIconOption,
  IIconSelectInputProps,
  IconSelectInput as default,
};
