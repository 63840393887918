import React, { useCallback } from 'react';

import { useUpdateDocument } from '@src/hooks/queries/documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';
import { IDocumentField } from '@src/types/document_field';
import { formatDate } from '@src/utils/date_helpers';

import QuickbooksCustomerField from '@src/components/common_v2/form_fields/quickbooks_customer_field';
import Form from '@src/components/ui_v2/form';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDataTabProps {
  businessId: TID,
  documentId: TID,
  documentFields: IDocumentField[],
  serviceDocument?: IAccountsPayableServiceDocument,
}

const DataTab = ({
  businessId,
  documentId,
  documentFields,
  serviceDocument,
}: IDataTabProps) => {
  let period = '';
  if (serviceDocument?.accrualStartDate && serviceDocument.accrualEndDate) {
    period = `${formatDate(serviceDocument?.accrualStartDate)} - ${formatDate(serviceDocument?.accrualEndDate)}`;
  }
  const splitsCount = serviceDocument?.serviceDocumentSplitsCount || 0;
  const handleValueChange = useCallback(() => {
  }, []);

  const selectCustomer = useUpdateDocument();
  const { mutate } = selectCustomer;
  const handleCustomerChange = useCallback((id) => {
    mutate(
      {
        id:       documentId,
        document: {
          quickbooksCustomerId: id,
        },
      },
    );
  }, [documentId, mutate]);

  return (
    <>
      {
        documentFields.map((documentField) => {
          return (
            <>
              {
                documentField.name === 'Category' && (
                  <Form.StaticField
                    label="Category"
                    value={ splitsCount > 1 ? `${splitsCount} Splits` : documentField.value }
                  />
                )
              }
              {
                documentField.dataType === 'currency' && (
                  <Form.AmountField
                    label="Amount"
                    value={ documentField.value }
                    onChange={ handleValueChange }
                  />
                )
              }
              {
                (documentField.name !== 'Category' && documentField.dataType !== 'currency') && (
                  <Form.TextField
                    label={ documentField.name }
                    value={ documentField.value }
                    onChange={ handleValueChange }
                  />
                )
              }
              {
                documentField.name === 'Invoice Date' && (
                  <Form.StaticField
                    label="Period"
                    value={ period }
                  />
                )
              }
            </>
          );
        })
      }
      <MutationStatus mutation={ selectCustomer } />
      <QuickbooksCustomerField
        businessId={ businessId }
        label="Billable Client"
        onChange={ handleCustomerChange }
      />
    </>
  );
};

export default React.memo(DataTab);
