import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';

import { TDate, TID } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { Button } from '@src/components/ui/buttons';
import Form from '@src/components/ui/form';
import { ErrorNotification } from '@src/components/ui/notification';
import { PlusIcon } from '@src/components/utils/icomoon';

import { IAddTransactionInput, IFormValues, defaultValues, transactionsSchema } from './schema';
import List from './transaction_fields';

import styles from './styles.module.scss';

interface IAddTransactionsFormProps {
  formId?: string,
  startDate?: TDate,
  endDate?: TDate,
  reconciliationPaymentAccount?: IReconciliationPaymentAccount,
  onSubmit: SubmitHandler<IFormValues>,
  businessId: TID,
  bulkResponseIndexes?: number[],
  isOpen: boolean,
  oldTransactions?: IAddTransactionInput[],
  onChange: (data: IFormValues) => void,
}

const AddTransactionsForm = ({
  formId,
  startDate,
  endDate,
  reconciliationPaymentAccount,
  onSubmit,
  businessId,
  bulkResponseIndexes,
  onChange,
  isOpen,
  oldTransactions,
}: IAddTransactionsFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(transactionsSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newTransactions',
  });

  const handleAddAnotherTransaction = useCallback((txn?: IAddTransactionInput) => {
    append({
      transactionDate: '',
      description:     txn?.description || '',
      amount:          txn?.amount || '',
      amountType:      txn?.amountType || '',
      selectedAccount: {
        reconciliationPaymentAccountId:
        txn?.selectedAccount.reconciliationPaymentAccountId || undefined,
        paymentAccountId: txn?.selectedAccount.paymentAccountId || undefined,
        accountType:      txn?.selectedAccount.accountType || undefined,
      },
    }, { shouldFocus: false });
  }, [append]);

  const handleChange = useCallback(() => {
    onChange(getValues());
  }, [getValues, onChange]);

  const removeFormRow = useCallback((index: number) => {
    remove(index);
    handleChange();
  }, [handleChange, remove]);

  useEffect(() => {
    if (bulkResponseIndexes === undefined) return;

    bulkResponseIndexes.reverse().forEach((index) => {
      remove(index);
    });
    handleChange();
  }, [bulkResponseIndexes, getValues, handleChange, remove]);

  useEffect(() => {
    if (!oldTransactions && fields.length === 0) {
      handleAddAnotherTransaction();
    }
    if (!oldTransactions) return;
    if (fields.length > 0) { remove(0); }

    oldTransactions.forEach((txn: IAddTransactionInput) => {
      handleAddAnotherTransaction(txn);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, handleAddAnotherTransaction, isOpen, remove]);

  return (
    <Form
      className={ styles['add-transactions-modal-form'] }
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      {
        errors.newTransactions
          ? <ErrorNotification message="Please fill blank fields" title="Warning!" />
          : null
      }
      <div className="add-transaction-list p-b-30">
        <div className="display-flex add-transaction-list-title m-b-10 border-bottom-gray">
          <span className="title width-20-percent font-bold">Date</span>
          <span className="title width-30-percent font-bold">Banking Account</span>
          <span className="title width-30-percent font-bold">Description</span>
          <span className="title width-26-percent font-bold">Type</span>
          <span className="title width-25-percent font-bold">Amount</span>
          <span className="title width-5-percent" />
        </div>
        {
          fields.map((item, index) => (
            <List
              key={ item.id }
              businessId={ businessId }
              control={ control }
              endDate={ endDate }
              errors={ errors }
              index={ index }
              oldTransactions={ oldTransactions }
              reconciliationPaymentAccount={ reconciliationPaymentAccount }
              remove={ () => removeFormRow(index) }
              setValue={ setValue }
              startDate={ startDate }
              watch={ watch }
              onChange={ handleChange }
            />
          ))
        }
        <Button
          bsColor="blue"
          className="pull-right add-transaction-btn"
          fontSize={ 14 }
          onClick={ () => handleAddAnotherTransaction() }
        >
          <PlusIcon pr={ 4 } variant="o" />
          {' '}
          Add another transaction
        </Button>
      </div>
    </Form>
  );
};

export {
  IAddTransactionsFormProps,
  AddTransactionsForm as default,
};
