import React, { forwardRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

type TOption = {
  name: string,
  value: string,
}

interface ISelectFieldProps extends
  Omit<React.ComponentPropsWithoutRef<'select'>, 'onChange'>,
  IUIStyleProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
  options: TOption[]
}

/**
 * Field to select from list of options.
 *
 * @visibleName Form.SelectField
 */
const SelectField = forwardRef<HTMLSelectElement, ISelectFieldProps>(({
  error,
  hideLabel,
  hint,
  label,
  mark,
  options,
  ...props
}: ISelectFieldProps, ref): JSX.Element => {
  const [classes, selectProps] = uiStyleProps('form-control', props);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <select
        ref={ ref }
        className={ classes }
        { ...selectProps }
      >
        {
          options.map((option) => (
            <option
              key={ option.value }
              value={ option.value }
            >
              { option.name }
            </option>
          ))
        }
      </select>
    </Wrapper>
  );
});

SelectField.displayName = 'SelectField';

export default SelectField;
