import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { ISorting } from '@src/types/sorting';
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import { VendorAvatar } from '@src/components/ui/avatars';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';

import DocumentIcon from '../../action_icons/document_icon';
import TransactionIcon from '../../action_icons/transaction_icon';
import MatchDocumentsFilter from '../filter';
import Actions from './actions';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

interface IMatchDocumentsExpensesTableProps {
  includeUnverified?: boolean,
  infiniteQuery: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
}

const vendorAvatar = (document: ITransactionServiceDocument) => {
  return <VendorAvatar size="100%" vendor={ document.vendor } />;
};

const accountName = (document: ITransactionServiceDocument) => {
  if (document.employeeCardName && document.employeeCardName !== document.paymentAccountName) {
    return [document.paymentAccountName, document.employeeCardName].join(' / ');
  }

  return document.paymentAccountName;
};

const documentType = (document: ITransactionServiceDocument) => {
  return <DocumentIcon document={ document } />;
};

const transactionType = (document: ITransactionServiceDocument) => {
  return (
    <AppLink newWindow href={ `/document/${document.documentId}` }>
      <TransactionIcon document={ document } />
    </AppLink>
  );
};

const MatchDocumentsExpensesTable = ({
  includeUnverified,
  infiniteQuery,
  records,
  sorting,
}: IMatchDocumentsExpensesTableProps) => {
  const business = useBusinessContext()

  const actions = (document: ITransactionServiceDocument) => {
    return <Actions document={ document } disabledRow={document.transactionDate < business.reconciliationStartDate } />;
  };
  const handleIsRowSelectable = useCallback((tsd: ITransactionServiceDocument ) => {
    return (tsd.transactionMatch === null && (business.reconciliationStartDate == null || tsd.transactionDate >= business.reconciliationStartDate));
  }, []);
  return (
    <CollectionTable<ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ (
        <MatchDocumentsFilter includeUnverified={ includeUnverified } listType="expenses" />
      ) }
      height="750px"
      isRowSelectable={ handleIsRowSelectable }
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        icon={ vendorAvatar }
        name="vendor_id"
        sortColumn="vendor_id"
        subtitle={ (document) => document.description }
        title="Payee"
        value={ (document) => document.vendor?.name ?? 'Unknown Vendor' }
        width="50%"
      />
      <CollectionTable.DateColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="transaction_date"
        sortColumn="transaction_date"
        title="Date"
        value={ (document) => document.transactionDate }
      />
      <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="payment_account"
        sortColumn="payment_account"
        title="Account"
        value={ accountName }
        width="50%"
      />
      <CollectionTable.AmountColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (document) => document.amount }
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="document_type"
        title="Document type"
        value={ documentType }
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="transaction_type"
        title="Transaction type"
        value={ transactionType }
      />
      <CollectionTable.ActionsColumn<ITransactionServiceDocument>
        name="actions"
        title="Actions"
        value={ actions }
      />
    </CollectionTable>
  );
};

export default React.memo(MatchDocumentsExpensesTable);
