import React, { forwardRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface ITextAreaFieldProps extends
  React.ComponentPropsWithoutRef<'textarea'>,
  IUIStyleProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
}

/**
 * Field for large text.
 *
 * @visibleName Form.TextAreaField
 */
const TextAreaField = forwardRef<HTMLTextAreaElement, ITextAreaFieldProps>(({
  id,
  defaultValue,
  error,
  hint,
  label,
  mark,
  ...props
}: ITextAreaFieldProps, ref): JSX.Element => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <textarea
        ref={ ref }
        className={ classes }
        defaultValue={ defaultValue }
        id={ id }
        { ...inputProps }
      />
    </Wrapper>
  );
});

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
