import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { metricsValuePath } from '@src/routes';
import { IMetric } from '@src/types/metrics';

import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface ILaborMetricsProps {
  laborMetrics: IMetric[]
}

const LaborMetricsSidebar = ({ laborMetrics }: ILaborMetricsProps): JSX.Element => {
  const business = useBusinessContext();
  return (
    <ServiceSidebar.SubMenu
      expandedKey="labor_metrics_expanded"
      title="Labor Metric"
    >
      {
        laborMetrics?.map((metric) => {
          return (
            <ServiceSidebar.Item
              key={ metric.id }
              isHighlightRequired
              href={ metricsValuePath(business.id, metric.id) }
              title={ metric.name }
            />
          );
        })
      }
    </ServiceSidebar.SubMenu>
  );
};

export default LaborMetricsSidebar;
