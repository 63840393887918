import React from 'react';

import { useTooltip } from '@src/components/ui_v2/tooltip/hooks';

interface ITooltipClickContainerProps<T extends React.ElementType> {
  as?: T,
  children: React.ReactNode,
  showTooltip?: boolean,
}

type TTooltipClickContainerPropsAs<T extends React.ElementType> =
  ITooltipClickContainerProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof ITooltipClickContainerProps<T>>

const TooltipClickContainer = <T extends React.ElementType = 'div'>({
  as,
  children,
  showTooltip,
  ...props
}: TTooltipClickContainerPropsAs<T>) => {
  const { referenceRef, show, hide } = useTooltip() || {};

  const Component = as || 'div';

  if (showTooltip && show) show();
  if (!showTooltip && hide) hide();

  return (
    <Component
      ref={ referenceRef }
      { ...props }
    >
      { children }
    </Component>
  );
};

export default TooltipClickContainer;
