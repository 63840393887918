import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { WrenchIcon } from '@src/components/utils/fa_icons';

interface IReportIssueActionProps {
  documentId: TID,
}

const ReportIssueAction = ({
  documentId,
}: IReportIssueActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:report:issue', documentId);
  }, [documentId]);

  return (
    <ActionsDropdown.Action
      icon={ <WrenchIcon fontSize={ 18 } /> }
      name="Report an Issue"
      onClick={ handleSelect }
    />
  );
};

export default ReportIssueAction;
