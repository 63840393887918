module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider m-t-35 p-b-15">\n  <h2 class="service-main-title">Notifications</h2>\n</div>\n<div class="setting-notifications-new-content m-t-40">\n  <div id="edit-financial-insights-email-region"></div>\n</div>\n<div class="setting-notifications-content m-t-40">\n  <div>\n    <h2 class="font-semi-bold font-16">Accounts Payable</h2>\n    <p class="font-14">Bills that are due or ready to be paid,<br>approved or verified.</p>\n  </div>\n  <div class="permission-dropdown-wrapper width-300px">\n    <div class="dropdown-toggle permission-dropdown-control" id="notification-dropdown-btn" data-toggle="dropdown">\n      <span class="');
    
      if (this.preference.get('accounts_payable')) {
        __out.push(__sanitize('in-black'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.preference.get('accounts_payable') ? 'Email Notifications Enabled' : 'Email Notifications Disabled'));
    
      __out.push('</span>\n      <span class="fa fa-caret-down"></span>\n    </div>\n    <ul class="dropdown-menu" aria-labelledby="notification-dropdown-btn">\n      <li class="accounts-payable-select-item ');
    
      if (!this.preference.get('accounts_payable')) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('" data-value="false">Email Notifications Disabled</li>\n      <li class="accounts-payable-select-item ');
    
      if (this.preference.get('accounts_payable')) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('" data-value="true">Email Notifications Enabled</li>\n    </ul>\n  </div>\n</div>\n<div class="setting-notifications-content m-t-40">\n  <div>\n    <h2 class="font-semi-bold font-16">Undocumented Check Transactions</h2>\n    <p class="font-14">Daily notification on check transactions that<br>have been imported but not matched to any<br>invoice.</p>\n  </div>\n  <div class="permission-dropdown-wrapper width-300px">\n    <div class="dropdown-toggle permission-dropdown-control" id="notification-dropdown-btn" data-toggle="dropdown">\n      <span class="');
    
      if (this.preference.get('undocumented_transaction')) {
        __out.push(__sanitize('in-black'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.preference.get('undocumented_transaction') ? 'Email Notifications Enabled' : 'Email Notifications Disabled'));
    
      __out.push('</span>\n      <span class="fa fa-caret-down"></span>\n    </div>\n    <ul class="dropdown-menu" aria-labelledby="notification-dropdown-btn">\n      <li class="undocumented-select-item ');
    
      if (!this.preference.get('undocumented_transaction')) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('" data-value="false">Email Notifications Disabled</li>\n      <li class="undocumented-select-item ');
    
      if (this.preference.get('undocumented_transaction')) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('" data-value="true">Email Notifications Enabled</li>\n    </ul>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}