import React from 'react'

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IItemValue } from '@src/types/report_service/report_data';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';

import { searchColumnValueType } from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table';

import Row from './row';
import { IHeaderColumn } from '../../hooks';
import { useReportOtherDetailItemsBySearchName } from '../../tableHooks';

interface IReportOtherDetailTableBodyProps {
  report: IReport,
  itemAccountValues: IItemAccount[],
  itemValues: IItemValue[],
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  itemIdentifier: IReportItem,
  headerTopRowColumns: IHeaderColumn[],
  items: IItemAccount[]
}

const ReportOtherDetailTableBody = (
  {
    report,
    itemAccountValues,
    itemValues,
    multiMonthColumns,
    ptdColumns,
    ytdColumns,
    itemIdentifier,
    headerTopRowColumns,
    items
  }: IReportOtherDetailTableBodyProps
) => {
  const searchValue = useRecoilValue(searchColumnValueType);
  useReportOtherDetailItemsBySearchName(items, searchValue);

  return (
    <Table.Body>
      {items?.map((item: IItemAccount) => (
        <React.Fragment key={ item.id }>
          { item.show && item.search && (
            <Row
              headerTopRowColumns={ headerTopRowColumns }
              item={ item }
              itemAccountValues={ itemAccountValues }
              itemIdentifier={ itemIdentifier }
              itemValues={ itemValues }
              multiMonthColumns={ multiMonthColumns }
              ptdColumns={ ptdColumns }
              report={ report }
              ytdColumns={ ytdColumns }
            />
          ) }
        </React.Fragment>
      ))}
    </Table.Body>
  );
}

export default React.memo(ReportOtherDetailTableBody);