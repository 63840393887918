module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, len, len1, ref, ref1, value;
    
      if (this.child_items && this.child_items.length > 0) {
        __out.push('\n  <div class="nav-item pointer header-js">\n    <div class="head-column display-flex font-semi-bold title-row1-js">\n      <i class="fa ');
        __out.push(__sanitize(this.childrenVisible ? 'fa-caret-up' : 'fa-caret-down'));
        __out.push(' font-16"></i>\n      <span class="inline-block in-black m-l-10">');
        __out.push(__sanitize(this.name));
        __out.push('</span>\n    </div>\n    <div class="main-box height-100-percent">\n      ');
        ref = this.itemValues.values;
        for (i = 0, len = ref.length; i < len; i++) {
          value = ref[i];
          __out.push('\n        ');
          if (value.type === 'devide_column') {
            __out.push('\n          <div class="column-border-right"></div>\n        ');
          } else {
            __out.push('\n          <div class="monthly-report-box">\n            ');
            if (value.hasLink) {
              __out.push('\n              <a href=\'');
              __out.push(__sanitize(value.hrefLink));
              __out.push('\' class=\'general-column\'>');
              __out.push(__sanitize(value.value));
              __out.push('</a>\n            ');
            } else {
              __out.push('\n              <div class="general-column">');
              __out.push(__sanitize(value.value));
              __out.push('</div>\n            ');
            }
            __out.push('\n          </div>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </div>\n  </div>\n  <div class="child-item-list ');
        if (!this.childrenVisible) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('"></div>\n');
      } else {
        __out.push('\n  <div class=\'item-box\'>\n    <div class=\'head-column line-height-42 font-semi-bold title-row2-js\'>\n      ');
        if (this.itemValues.hasLink) {
          __out.push('\n        <a class="report-parent-line-item pointer">');
          __out.push(__sanitize(this.name));
          __out.push('</a>\n      ');
        } else {
          __out.push('\n        <span>');
          __out.push(__sanitize(this.name));
          __out.push('</span>\n      ');
        }
        __out.push('\n    </div>\n    <div class="main-box height-100-percent">\n      ');
        ref1 = this.itemValues.values;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          value = ref1[j];
          __out.push('\n        ');
          if (value.type === 'devide_column') {
            __out.push('\n          <div class="column-border-right"></div>\n        ');
          } else {
            __out.push('\n          <div class="monthly-report-box">\n            ');
            if (value.hasLink) {
              __out.push('\n              <a href=\'');
              __out.push(__sanitize(value.hrefLink));
              __out.push('\' class=\'general-column\'>');
              __out.push(__sanitize(value.value));
              __out.push('</a>\n            ');
            } else {
              __out.push('\n              <div class="general-column">');
              __out.push(__sanitize(value.value));
              __out.push('</div>\n            ');
            }
            __out.push('\n          </div>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </div>\n  </div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}