import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const OutsideThisMonthIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="outside-this-month"
    />
  );
});

OutsideThisMonthIcon.displayName = 'OutsideThisMonthIcon';

export default OutsideThisMonthIcon;
