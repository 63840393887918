module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table bg-white">\n  <thead>\n    <tr>\n      <th style="width: 30px;"></th>\n      ');
    
      if (this.order_column === "transaction_date") {
        __out.push('\n        <th class="pointer relative sortable ');
        __out.push(__sanitize(this.order_direction === 'asc' ? 'sorted-asc' : 'sorted-desc'));
        __out.push('" data-orderby="transaction_date" data-orderdir="ASC">Date</th>\n      ');
      } else {
        __out.push('\n        <th class="pointer relative sortable sorted-desc" data-orderby="transaction_date" data-orderdir="ASC">Date</th>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.order_column === "description") {
        __out.push('\n        <th class="pointer relative sortable ');
        __out.push(__sanitize(this.order_direction === 'asc' ? 'sorted-asc' : 'sorted-desc'));
        __out.push('" data-orderby="description" data-orderdir="ASC">Description</th>\n      ');
      } else {
        __out.push('\n        <th class="pointer relative sortable" data-orderby="description" data-orderdir="ASC">Description</th>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.order_column === "vendor") {
        __out.push('\n        <th class="pointer relative sortable ');
        __out.push(__sanitize(this.order_direction === 'asc' ? 'sorted-asc' : 'sorted-desc'));
        __out.push('" data-orderby="vendor" data-orderdir="ASC">Vendor</th>\n      ');
      } else {
        __out.push('\n        <th class="pointer relative sortable" data-orderby="vendor" data-orderdir="ASC">Vendor</th>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.order_column === "payment_account") {
        __out.push('\n        <th class="pointer relative sortable ');
        __out.push(__sanitize(this.order_direction === 'asc' ? 'sorted-asc' : 'sorted-desc'));
        __out.push('" data-orderby="payment_account" data-orderdir="ASC">Account</th>\n      ');
      } else {
        __out.push('\n        <th class="pointer relative sortable" data-orderby="payment_account" data-orderdir="ASC">Account</th>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.order_column === "amount") {
        __out.push('\n        <th class="pointer relative sortable ');
        __out.push(__sanitize(this.order_direction === 'asc' ? 'sorted-asc' : 'sorted-desc'));
        __out.push('" data-orderby="amount" data-orderdir="ASC" style="width: 100px;">Amount</th>\n      ');
      } else {
        __out.push('\n        <th class="pointer relative sortable" data-orderby="amount" data-orderdir="ASC" style="width: 100px;">Amount</th>\n      ');
      }
    
      __out.push('\n      <th></th>\n      <th></th>\n      <th></th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}