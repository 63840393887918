import React from 'react';

import { TID } from '@src/types/common';
import { TReportCategory } from '@src/types/report_service/report';

import Root from '@src/components/root';

import BusinessProvider from '../providers/business_provider';
import ReportsServiceProvider from '../providers/reports_service_provider';
import Sidemenu from './sidemenu';

interface IBusinessReportsSideMenuProps {
  businessId: TID,
  category: TReportCategory,
}

const BusinessReportsSideMenu = ({
  businessId,
  category,
}: IBusinessReportsSideMenuProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReportsServiceProvider businessId={ businessId }>
          <Sidemenu category={ category } />
        </ReportsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BusinessReportsSideMenu;
