import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const MoneyExchangeIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="money-exchange"
      pathsNumber={ 12 }
    />
  );
};

export default MoneyExchangeIcon;
