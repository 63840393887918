import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface IFaIconProps extends IUIStyleProps, React.ComponentPropsWithoutRef<'span'> {
  icon: string,
  spin?: boolean,
  size?: '2x' | '3x' | '4x',
}

const FaIcon = ({
  icon,
  size,
  spin,
  ...props
}: IFaIconProps) => {
  const [classes, iProps] = uiStyleProps(
    `fa fa-${icon}`,
    props,
    {
      'fa-spin':      spin,
      [`fa-${size}`]: size,
    },
  );

  return (
    <i className={ classes } { ...iProps } />
  );
};

export {
  IFaIconProps,
  FaIcon as default,
};
