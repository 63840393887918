/* eslint-disable import/order */
import React from 'react';

import { TID } from '@src/types/common';

import { CancelModalButton } from '@src/components/ui/buttons';
import { Button } from '@src/components/ui_v2/buttons';

import Modal from '@src/components/ui/modal';
import { businessSettingsStepPath } from '@src/routes';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import styles from './styles.module.scss';

interface IProps extends IUseModalProps {
  businessId: TID,
}

const SubscriptionModal = ({ 
   businessId,
   isOpen,
   onCancel,
  }: IProps) => {
  const onUpgradeClick = () => {
    const billingPageUrl = businessSettingsStepPath(businessId, 7);
    Backbone.history.navigate(billingPageUrl, { trigger: true });
  }

  return (
    <Modal dialogClassName={ styles['subscription-upgrade-modal'] } show={ isOpen } title="Upgrade Subscription Plan">
      <Modal.Body>
        <h3>You do not have access to this feature based on your current plan. Upgrade for complete access.</h3>
        <Button
          variant="outline"
          onClick={ onUpgradeClick }
        >
          UPGRADE
        </Button>
        <h4 className='mt-3'>
          Interested in learning more about Docyt&apos;s payment plans?
          {' '}
          <a
            href={ window.configData.docyt_pricing_url }
            rel="noreferrer"
            role="button"
            tabIndex={ 0 }
            target="_blank"
          >
            Click here
          </a>
          {' '}
          for details!
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <CancelModalButton onClick={ onCancel } />
      </Modal.Footer>
    </Modal>
  );
};

const useSubscriptionModal = makeUseModal(SubscriptionModal);

export {
  useSubscriptionModal,
  SubscriptionModal as default,
};
