module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, len;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog person-edit-form-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">\n          ');
    
      if (this.dwollaBeneficialOwner.id) {
        __out.push('\n            ');
        if (this.isEditMode) {
          __out.push('\n            Edit Beneficial Owner\n            ');
        } else {
          __out.push('\n            Beneficial Owner\n            ');
        }
        __out.push('\n          ');
      } else {
        __out.push('\n            Add Beneficial Owner\n          ');
      }
    
      __out.push('\n        </h4>\n      </div>\n      ');
    
      if (this.dwollaBeneficialOwner.get('status') === configData.dwolla_beneficial_owner_statuses["DOCUMENT"]) {
        __out.push('\n      <div class="document-content-wrapper text-left">\n        <div>The initial verification attempt failed because the information provided did not satisfy our verification check. Please upload a colored scan of the identifying document.</div>\n        ');
        if (this.dwollaBeneficialOwner.isFailed()) {
          __out.push('\n        <div class="m-t-20 in-red-800 font-semi-bold">Document upload failed. Reason: ');
          __out.push(__sanitize(this.dwollaBeneficialOwner.docFailedDescription()));
          __out.push(' </div>\n        ');
        }
        __out.push('\n        <div class="display-flex align-items-center m-t-20 m-b-20">\n          ');
        if (this.dwollaBeneficialOwner.isReviewing()) {
          __out.push('\n            <div class="document-uploaded-wrapper">Document Uploaded. Pending Review. </div>\n          ');
        } else {
          __out.push('\n            <select class="input-document-type form-control m-r-20">\n              <option value="license">State issued driver’s license</option>\n              <option value="idCard">U.S. government-issued photo id card</option>\n              <option value="passport">Passport</option>\n              <option value="other">Other</option>\n            </select>\n            ');
          if (!this.isUploading) {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <button type="submit" class="btn btn-browse-js ');
            __out.push(__sanitize(this.dwollaBeneficialOwner.isFailed() ? 'btn-warning' : 'ladda-button'));
            __out.push(' fileinput-button width-200px" data-color="blue" data-style="expand-right">\n                ');
            if (this.dwollaBeneficialOwner.isFailed()) {
              __out.push('\n                Try Again\n                ');
            } else {
              __out.push('\n                Browse\n                ');
            }
            __out.push('\n                <input type="file" name="files[]" multiple="" class="document-file-input-js" accept=".jpeg, .jpg, .png">\n              </button>\n            </div>\n            ');
          } else {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n              <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                <div class=\'file-status-line\' style=\'width:0%\'></div>\n              </div>\n            </div>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        </div>\n        ');
        if (this.isFullReadMode) {
          __out.push('\n        <p><strong>US persons:</strong> A color scanned photo of the Controller’s identifying document can be specified as documentType: <code class="in-black">license</code> (state issued driver\'s license), or <code class="in-black">idCard</code> (U.S. government-issued photo id card).</p>\n        <p>Supported Document Examples:</p>\n        <ul>\n          <li>Non-expired State Issued Driver’s License/Identification Card</li>\n          <li>Non-expired US Passport</li>\n          <li>Federal Employment Authorization Card</li>\n          <li>US Visa</li>\n        </ul>\n        <p>Unsupported Document Examples:</p>\n        <ul><li>Military IDs</li><li>Expired government-issued IDs</li></ul>\n        <p><strong>Non-US persons:</strong> A color scanned photo of the Controller’s identifying document can be specified as documentType: <code class="in-black">passport</code>. Examples include:</p>\n        <ul>\n          <li>Non-expired Foreign Passport <em><strong>Note:</strong> Foreign Passports are only accepted when the individual does not have an ITIN or SSN and the user must alternatively enter the Passport number</em>.</li>\n        </ul>\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read less</span></div>\n        ');
        } else {
          __out.push('\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read more</span></div>\n        ');
        }
        __out.push('\n      </div>\n      ');
      }
    
      __out.push('\n      <div class="modal-body m-t-20">\n        <div class="text-center">\n          <div class="profile-content-wrapper">\n          ');
    
      if (!this.isEditMode && this.dwollaBeneficialOwner.id) {
        __out.push('\n            <div class="profile-input-wrap">\n              <label class="">Name</label>\n              <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaBeneficialOwner.name()));
        __out.push('</span>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Title</label>\n              <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('title')));
        __out.push('</span>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Address</label>\n              <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaBeneficialOwner.address()));
        __out.push('</span>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Status</label>\n              ');
        if (this.dwollaBeneficialOwner.get('status') === configData.dwolla_beneficial_owner_statuses["VERIFIED"]) {
          __out.push('\n              <span class="font-semi-bold in-green-500">Verified</span>\n              ');
        } else if (this.dwollaBeneficialOwner.get('status') === configData.dwolla_beneficial_owner_statuses["INCOMPLETE"]) {
          __out.push('\n              <span class="font-semi-bold in-grey-950">Pending Verification</span>\n              ');
        } else {
          __out.push('\n              <span class="font-semi-bold in-orange-300 display-flex align-items-center">Please upload additional documents</span>\n              ');
        }
        __out.push('\n            </div>\n            ');
        if (this.dwollaBeneficialOwner.get('status') === configData.dwolla_beneficial_owner_statuses["INCOMPLETE"]) {
          __out.push('\n            <div class="display-flex align-items-center in-blue-950 font-semi-bold pointer edit-link-js">\n              <span class="font-20 m-t-6"><i class="icon icon-edit_account"></i></span>\n              <span class="font-16 m-l-12">Edit information</span>\n            </div>\n            ');
        }
        __out.push('\n          ');
      } else {
        __out.push('\n            <div class="profile-input-wrap m-b-8 text-left">\n              <span> As requested by federal regulation, please provide additional information on all beneficial owners that own 25% or more of the business.</span>\n            </div>\n            <div class="profile-input-wrap approvers-typeahead-wrapper">\n              <label class="">Docyt User</label>\n              <input class="form-control user-input-js" type="text" placeholder="Select Docyt user" value="">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap m-b-20">\n              <label class="">Beneficial Owner Legal Name</label>\n              <div class="">\n                <div class="p-r-5 relative">\n                  <input class="input-first-name form-control" type="text" placeholder="First name" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('first_name')));
        __out.push('">\n                  <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="relative">\n                  <input class="input-last-name form-control" type="text" placeholder="Last name" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('last_name')));
        __out.push('">\n                  <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n              </div>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Title</label>\n              <input class="input-title-js form-control" type="text" placeholder="Title in company e.g. Chief Financial Officer" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('title')));
        __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Date of Birth</label>\n              <input class="input-date-birth dropdown-toggle form-control" type="text" placeholder="MM-DD-YYYY" autocomplete="off" value="');
        __out.push(__sanitize(this.dateBirth));
        __out.push('" ');
        if (this.dwollaBeneficialOwner.id) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n              <p class="calendar-js"><i class="icon icon-calendar-wall"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Address</label>\n              <input class="input-address-street form-control" placeholder="Address Line 1 (Street number, street name)" type="text" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('address1')));
        __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class=""></label>\n              <input class="input-address-street2 form-control" type="text" placeholder="Address Line 2 (Apartment, floor, suite, building number)" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('address2')));
        __out.push('">\n            </div>\n            <div class="profile-input-wrap">\n              <label class=""></label>\n              <div class="business-address-wrapper">\n                <div class="p-r-5 relative">\n                  <input class="input-address-city form-control" type="text" placeholder="City" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('city')));
        __out.push('">\n                  <p class="valid-hint-js  in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="p-r-5">\n                  <select class="input-address-state form-control">\n                    ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          address_state = usaStateList[i];
          __out.push('\n                    ');
          if (address_state.abbreviation === this.dwollaBeneficialOwner.get('state')) {
            __out.push('\n                    <option value="');
            __out.push(__sanitize(address_state.abbreviation));
            __out.push('" selected>');
            __out.push(__sanitize(address_state.text));
            __out.push('</option>\n                    ');
          } else {
            __out.push('\n                    <option value="');
            __out.push(__sanitize(address_state.abbreviation));
            __out.push('">');
            __out.push(__sanitize(address_state.text));
            __out.push('</option>\n                    ');
          }
          __out.push('\n                    ');
        }
        __out.push('\n                  </select>\n                </div>\n                <div class="relative">\n                  <input class="input-address-zip form-control" type="text" placeholder="Zip" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('postal_code')));
        __out.push('">\n                  <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n              </div>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Full nine digits of SSN</label>\n              <input class="input-last-four-js form-control" type="text" placeholder="XXX-XX-XXXX" value="');
        __out.push(__sanitize(this.dwollaBeneficialOwner.get('ssn')));
        __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class=""></label>\n              <div class="note-area">Note: Docyt does not save Social Security Numbers in the database and is only passing it over secure network to the service responsible for ACH transfers</div>\n            </div>\n          ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        ');
    
      if (!this.dwollaBeneficialOwner.id || this.isEditMode) {
        __out.push('\n          <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n          <button type="submit" class="btn btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        ');
      } else {
        __out.push('\n          <button type="submit" class="btn btn-done ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Done</span></button>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}