import React from 'react';

import classNames from 'classnames';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';

import styles from '@src/components/ui_v2/inputs/styles.module.scss';

interface IBusinessReportsMultiSelectInputProps {
  placeholder?: string,
  value:        string[],
}

const BusinessReportsMultiSelectInput = ({ placeholder, value }: IBusinessReportsMultiSelectInputProps) => {
  const {
    isVisible,
    showOnClickAttributes,
    showWithKeyboardAttributes,
  } = useDropdown() || {};

  const inputClasses = classNames(
    value.length > 0 ? styles['dropdown-input-value'] : styles['dropdown-input-placeholder'],
  );

  return (
    <>
      <div
        aria-expanded={ isVisible ? 'true' : 'false' }
        aria-haspopup="true"
        className={ inputClasses }
        role="button"
        tabIndex={ 0 }
        { ...showOnClickAttributes }
        { ...showWithKeyboardAttributes }
      >
        { value.length > 0 ? `${value.length} Selected` : placeholder }
      </div>
      <Dropdown.InputIndicator tabIndex={ -1 } />
    </>
  );
}

export default BusinessReportsMultiSelectInput;
