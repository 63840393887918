import React, { useCallback } from 'react';

import { useDestroyBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDeleteStatementActionProps {
  statement: IStatementState,
  businessId: TID,
  onDeleted?: () => void,
}

const DeleteStatementAction = ({
  statement,
  businessId,
  onDeleted,
}: IDeleteStatementActionProps) => {
  const deleteStatement = useDestroyBalanceSheetStatement();
  const { mutate } = deleteStatement;

  const handleConfirm = useCallback(() => {
    if (!statement.id) return;

    mutate(
      { businessId, id: statement.id },
      { onSuccess: onDeleted },
    );
  }, [statement.id, mutate, businessId, onDeleted]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ deleteStatement } />
      <confirmModal.Component
        text="Are you sure want to delete this document?"
        title="Delete Document"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        danger
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        title="Delete"
        onClick={ confirmModal.open }
      />
    </>
  );
};

export default DeleteStatementAction;
