import { InfiniteData, useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetDataExportsParams,
  IGetDataExportsResponse,
  IDeleteDataExportParams,
  getDataExports,
  deleteDataExport,
  ICreateDataExportResponse,
  ICreateDataExportParams,
  createDataExport,
  downloadDataExport,
  IDownloadDataExportParams,
  IDownloadDataExportResponse,
  IGetRecentDataExportsResponse,
  getRecentDataExports,
  IUpdateDataExportResponse,
  IUpdateDataExportParams,
  updateDataExport,
} from '@src/requests/data_exports';
import { IDataExport } from '@src/types/data_exports';

import { IBulkMutationParams, makeBulkRequest, IBulkMutationReturn } from './bulk_mutations';
import {
  createUseGetInfiniteCollection, removeItemsFromInfiniteCollection,
} from './infinite_collection_queries';

const DATA_EXPORTS_QUERY = 'data_exports';

const useGetDataExports = createUseGetInfiniteCollection<
  IDataExport,
  IGetDataExportsParams,
  IGetDataExportsResponse
>({
  queryKey: DATA_EXPORTS_QUERY,
  request:  getDataExports,
});

const useCreateDataExport = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataExportResponse, Error, ICreateDataExportParams>(
    createDataExport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DATA_EXPORTS_QUERY);
      },
    },
  );
};

const useUpdateDataExport = () => {
  const queryClient = useQueryClient();

  return useMutation<IUpdateDataExportResponse, Error, IUpdateDataExportParams>(
    updateDataExport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DATA_EXPORTS_QUERY);
      },
    },
  );
};

const useBulkDownloadDataExports = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IDownloadDataExportResponse>,
    Error,
    IBulkMutationParams<IDownloadDataExportParams, IDownloadDataExportResponse>
  >(
    makeBulkRequest(downloadDataExport),
    {
      onSettled: (res) => {
        if (!res?.responses || res?.responses.length === 0) return;

        res.responses.forEach((response: any) => {
          window.Docyt.vent.trigger('data_export:download', response.exportedFileUrl);
        });
        queryClient.invalidateQueries(DATA_EXPORTS_QUERY);
      },
    },
  );
};

const useBulkDeleteDataExports = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IDeleteDataExportParams>
  >(
    makeBulkRequest(deleteDataExport),
    {
      onSettled: () => {
        queryClient.invalidateQueries(DATA_EXPORTS_QUERY);
      },
    },
  );
};

const useDeleteDataExport = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteDataExportParams>(
    deleteDataExport,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          DATA_EXPORTS_QUERY,
          (
            data?: InfiniteData<IGetDataExportsResponse>,
          ): InfiniteData<IGetDataExportsResponse> => {
            return removeItemsFromInfiniteCollection(
              data,
              [params.dataExportId],
            );
          },
        );
      },
    },
  );
};

const useDownloadDataExport = () => {
  const queryClient = useQueryClient();

  return useMutation<IDownloadDataExportResponse, Error, IDownloadDataExportParams>(
    downloadDataExport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DATA_EXPORTS_QUERY);
      },
    },
  );
};

const useGetRecentDataExports = () => {
  return useQuery<IGetRecentDataExportsResponse, Error>(
    [QueryKey.recentDataExports],
    () => getRecentDataExports(),
  );
};

export {
  DATA_EXPORTS_QUERY,
  useCreateDataExport,
  useUpdateDataExport,
  useDeleteDataExport,
  useGetDataExports,
  useDownloadDataExport,
  useBulkDeleteDataExports,
  useBulkDownloadDataExports,
  useGetRecentDataExports,
};
