module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, j, len, len1, ref, ref1, standard_document, standard_folder;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog standard-document-select-modal\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          ');
    
      if (this.to_forward_document) {
        __out.push('\n          <h1 class="modal-title font-28 font-bold">Select Document Type</h1>\n          ');
      } else {
        __out.push('\n          <h1 class="modal-title font-28 font-bold">Add Document: Select Document Type</h1>\n          <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n            <span aria-hidden="true">&times;</span>\n          </button>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-body\'>\n            <div class="flex-wrapper p-l-15 p-r-15 m-b-20 relative width-100-percent search-input-wrapper">\n                <input type="text" class="form-control p-l-25" placeholder="Search Document Type">\n            </div>\n            <div class="standard-documents-list border-top-gray">\n              ');
    
      ref = this.standard_folders;
      for (i = 0, len = ref.length; i < len; i++) {
        standard_folder = ref[i];
        __out.push('\n                <div class="standard-folder-standard-documents-wrapper" data-id="');
        __out.push(__sanitize(standard_folder.id));
        __out.push('">\n                    <p class="bg-grey-150 font-13 font-bold m-b-0 p-b-5 p-l-10 p-t-5">');
        __out.push(__sanitize(standard_folder.name));
        __out.push('</p>\n                    ');
        ref1 = standard_folder.standard_documents;
        for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
          standard_document = ref1[index];
          __out.push('\n                      <p class="pointer border-bottom-gray font-16 m-b-0 m-l-20 m-r-20 p-b-10 p-t-10 standard-document-item" data-value="');
          __out.push(__sanitize(standard_document.id));
          __out.push('">');
          __out.push(__sanitize(standard_document.name));
          __out.push('</p>\n                    ');
        }
        __out.push('\n                </div>\n              ');
      }
    
      __out.push('\n            </div>\n        </div>\n        <div class="modal-footer">\n          ');
    
      if (this.to_forward_document) {
        __out.push('\n          <a class=\'back settings-cancel-btn m-t-8 pull-left\'>Back</a>\n          <button type=\'button\' class="btn btn-blue save-btn">Forward</button>\n          ');
      } else {
        __out.push('\n          <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n          <button type=\'button\' class="btn btn-blue save-btn">Done</button>\n          ');
      }
    
      __out.push('\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}