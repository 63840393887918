import React, { useCallback, useEffect, useState } from 'react';

import {
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import {
  ICreateBankAccountFromPlaidParams,
  ICreateCreditCardParams,
} from '@src/requests/billing_payment_methods';
import { ISelfOnboardingBusiness } from '@src/types/self_onboarding_invitation';
import { openPlaidConnectWindow } from '@src/utils/plaid';

import { Button } from '@src/components/ui/buttons';
import SideView from '@src/components/ui/side_view';
import { TextInput } from '@src/components/ui_v2/inputs';
import {
  BankPaymentMethodIcon,
  CreditCardPaymentMethodIcon,
} from '@src/components/utils/img_icons';

import styles from './styles.module.scss';

interface INewAccountProps {
  business: ISelfOnboardingBusiness,
  onCancel: () => void,
  onAddNewAccount: (newAccount: ICreateBankAccountFromPlaidParams | ICreateCreditCardParams) => void,
}

const NewAccount = ({
  business,
  onCancel,
  onAddNewAccount,
}: INewAccountProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isAddingBank, setIsAddingBank] = useState<boolean>(true);
  const [zipCode, setZipCode] = useState<string>('');
  const handleAddBankAccount = useCallback(() => {
    openPlaidConnectWindow('auth', business.id, true);
  }, [business.id]);
  const handleChangeZipCode = useCallback((e) => {
    setZipCode(e.target.value);
  }, [setZipCode]);

  const handleAddCreditCard = useCallback(() => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement('cardNumber');
    stripe.createToken(cardElement!, { address_zip: zipCode }).then((result) => {
      const token = result.token!;
      const card = token.card!;
      onAddNewAccount({
        businessId:  business.id,
        stripeToken: token.id,
        zip:         zipCode,
        last4:       card.last4,
        brand:       card.brand,
        cardId:      card.id,
        expYear:     card.exp_year,
        expMonth:    card.exp_month,
      } as ICreateCreditCardParams);
    });
  }, [stripe, elements, zipCode, onAddNewAccount]);

  useEffect(() => {
    window.Docyt.vent.on('plaid:account:connected', (publicToken: string, metadata: any) => {
      onAddNewAccount({
        businessId:       business.id,
        plaidPublicToken: publicToken,
        plaidAccountId:   metadata.account_id,
        last4:            metadata.account?.mask,
      } as ICreateBankAccountFromPlaidParams);
    });

    return () => {
      window.Docyt.vent.off('plaid:account:connected');
    };
  }, [business.id, onAddNewAccount]);

  return (
    <SideView.Standard
      hideActions
      isOpen
      title="Billing Info"
      onCancel={ onCancel }
      onDone={ onCancel }
    >
      { () => (
        <div className="display-flex-column align-items-center">
          <div className="switch-tab width-100-percent">
            <div
              className={ isAddingBank ? 'activated-switch-tab-item' : 'switch-tab-item' }
              role="button"
              tabIndex={ 0 }
              onClick={ () => setIsAddingBank(true) }
            >
              Bank Account
            </div>
            <div
              className={ isAddingBank ? 'switch-tab-item' : 'activated-switch-tab-item' }
              role="button"
              tabIndex={ -1 }
              onClick={ () => setIsAddingBank(false) }
            >
              Credit Card
            </div>
          </div>
          {
            isAddingBank && (
              <>
                <BankPaymentMethodIcon />
                <Button
                  bsColor="blue"
                  className={ styles['add-payment-method-btn'] }
                  onClick={ handleAddBankAccount }
                >
                  + Securely Link Bank Account using Stripe
                </Button>
              </>
            )
          }
          {
            !isAddingBank && (
              <>
                <div className={ styles['add-credit-card-info'] }>
                  * An additional 3% processing fee will be charged for credit card transactions.
                </div>
                <CreditCardPaymentMethodIcon />
                <span className="width-100-percent m-t-10">Card Number</span>
                <CardNumberElement
                  className={ styles['stripe-input-field'] }
                  onReady={ (el) => el.focus() }
                />
                <div className={ styles['stripe-input-wrapper'] }>
                  <div className="child-input">
                    <CardExpiryElement className={ styles['stripe-input-field'] } />
                  </div>
                  <div className="child-input last-child">
                    <CardCvcElement className={ styles['stripe-input-field'] } />
                  </div>
                </div>
                <span className="width-100-percent m-t-10">ZipCode</span>
                <TextInput
                  aria-label="Search"
                  autoComplete="off"
                  className={ styles['stripe-input-field'] }
                  placeholder="Enter ZIP Code"
                  value={ zipCode }
                  onChange={ handleChangeZipCode }
                />
                <Button
                  bsColor="blue"
                  className={ styles['add-payment-method-btn'] }
                  onClick={ handleAddCreditCard }
                >
                  Add Credit Card
                </Button>
              </>
            )
          }
        </div>
      ) }
    </SideView.Standard>
  );
};

export default NewAccount;
