import React, { useCallback, useState } from 'react';

import { useDestroyBankStatement } from '@src/hooks/queries/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useConfirmModal } from './confirm_modal';

interface IDeleteActionProps {
  bankStatement: IBankStatement,
  fromDetails?: boolean,
  onDeleted?: () => void,
}

const DeleteAction = ({
  bankStatement,
  fromDetails = false,
  onDeleted,
}: IDeleteActionProps): JSX.Element | null => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const destroy = useDestroyBankStatement(fromDetails);

  const { mutate: destroyBankStatement } = destroy;

  const handleConfirm = useCallback(() => {
    destroyBankStatement(
      { id: bankStatement.id, state: bankStatement.state },
      { onSuccess: onDeleted },
    );
  }, [destroyBankStatement, bankStatement.id, bankStatement.state, onDeleted]);

  const confirmModal = useConfirmModal({
    onDone: handleConfirm,
  });

  const handleError = useCallback((response) => {
    if (response?.response?.data?.errors[0].includes('All the statements verified after', 'will be unverified and transactions removed')) {
      setErrorMessage(response?.response?.data?.errors[0]);
      confirmModal.open();
    }
  }, [confirmModal]);

  const handleDeleteBankStatement = useCallback(() => {
    destroyBankStatement(
      { id: bankStatement.id, state: bankStatement.state, confirm: false },
      { onSuccess: onDeleted, onError: handleError },
    );
  }, [destroyBankStatement, bankStatement.id, bankStatement.state, onDeleted, handleError]);

  const destroyModal = useConfirmDeleteModal({
    onDone: handleDeleteBankStatement,
  });

  return (
    <>
      <MutationStatus
        mutation={ destroy }
        successMessage="Deleted successfully"
      />

      <destroyModal.Component
        text={ bankStatement.state === 'verified'
          ? window.Docyt.Common.Constants.Messages.VERIFIED_BANK_STATEMENT_DELETE_MSG
          : window.Docyt.Common.Constants.Messages.BANK_STATEMENT_DELETE_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_DELETE_TITLE }
        { ...destroyModal.props }
      />

      <confirmModal.Component
        { ...confirmModal.props }
        content={ errorMessage }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_DELETE_TITLE }
      />

      <ActionsDropdown.DangerAction
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        name="Delete"
        onClick={ destroyModal.open }
      />
    </>
  );
};

export default DeleteAction;
