import { TID } from '@src/types/common';
import { IIntegration } from '@src/types/integrations/integrations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiDelete, apiPut } from '../helpers';

interface IGetIntegrationParams {
  businessId: TID,
  integrationId: TID | null | undefined,
}

interface IDisconnectIntegrationParams {
  integrationId: TID,
}

interface ISetIntegrationPausedParams {
  integrationId: TID,
  isPaused: boolean,
}

const getIntegration = (params: IGetIntegrationParams): Promise<IIntegration> => {
  return apiGet(`/integrations/api/v1/integrations/${params.integrationId}`, underscoreKeys(params))
    .then((data) => camelizeKeys(data.integration) as IIntegration);
};

const disconnectIntegration = (params: IDisconnectIntegrationParams): Promise<void> => {
  return apiDelete(
    `/integrations/api/v1/integrations/${params.integrationId}`,
  );
};

const setIntegrationPaused = (params: ISetIntegrationPausedParams): Promise<void> => {
  return apiPut(
    `/integrations/api/v1/integrations/${params.integrationId}/set_is_paused`,
    underscoreKeys(params),
  );
};

export {
  IGetIntegrationParams,
  getIntegration,
  disconnectIntegration,
  IDisconnectIntegrationParams,
  setIntegrationPaused,
  ISetIntegrationPausedParams,
};
