import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  deleteRevenueReconciliation,
  getRevenueReconciliation,
  IDeleteRevenueReconciliationParams,
  IGetRevenueReconciliationParams,
  IPostRevenueReconciliationParams,
  postRevenueReconciliation,
} from '@src/requests/revenue_reconciliations';
import { IRevenueReconciliation } from '@src/types/revenue_reconciliations';

import { IBulkMutationParams, IBulkMutationReturn, makeBulkRequest } from './bulk_mutations';

const useCreateRevenueReconciliation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IPostRevenueReconciliationParams>(
    postRevenueReconciliation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.revenueServiceDocuments);
      },
    },
  );
};

const useGetRevenueReconciliation = (params: IGetRevenueReconciliationParams) => {
  return useQuery<IRevenueReconciliation, Error>({
    queryKey: [QueryKey.revenueReconciles, params],
    queryFn:  () => getRevenueReconciliation(params),
  });
};

const useDestroyRevenueReconciliation = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteRevenueReconciliationParams>(
    deleteRevenueReconciliation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.revenueServiceDocuments);
      },
    },
  );
};

const useBulkDestroyRevenueReconciliation = () => {
  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IDeleteRevenueReconciliationParams>
  >(
    makeBulkRequest(deleteRevenueReconciliation),
  );
};

export {
  useCreateRevenueReconciliation,
  useGetRevenueReconciliation,
  useDestroyRevenueReconciliation,
  useBulkDestroyRevenueReconciliation,
};
