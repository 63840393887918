import { useMemo } from 'react';

import { flatten } from 'lodash';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetExpenseReportsServiceDocuments } from '@src/hooks/queries/expense_reports/expense_reports_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetExpenseReportsServiceDocumentsResponse } from '@src/requests/expense_reports/expense_reports_service_documents';
import { TID, TSection } from '@src/types/common';
import {
  IExpenseReportsServiceDocument,
  IExpenseReportsServiceDocumentsFilterQuery,
  TExpenseReportsServiceDocumentsSortColumn,
} from '@src/types/expense_reports/expense_reports_service_documents';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseExpenseReportsTrashCollectionParams {
  businessId: TID,
}

interface IExpenseReportsTrashCollection {
  query: UseInfiniteQueryResult<IGetExpenseReportsServiceDocumentsResponse, Error>,
  records: IExpenseReportsServiceDocument[],
  section: TSection,
  sorting: ISorting<TExpenseReportsServiceDocumentsSortColumn>,
  selectedRecords: IExpenseReportsServiceDocument[],
}

const defaultSorting: ISortingParams<TExpenseReportsServiceDocumentsSortColumn> = {
  orderColumn:    'payment_date',
  orderDirection: 'desc',
};

const filterToQuery = (
  filterData: IExpenseReportsServiceDocumentsFilterQuery | undefined,
): IExpenseReportsServiceDocumentsFilterQuery | undefined => {
  const query = omit(filterData, 'inTrash');

  return {
    ...query,
    inTrash: true,
  };
};

const useExpenseReportsTrashCollection = ({
  businessId,
}: IUseExpenseReportsTrashCollectionParams): IExpenseReportsTrashCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.ExpenseReportsTrash,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => {
    return filterToQuery(filterData);
  }, [filterData]);

  const sorting = useSorting<TExpenseReportsServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetExpenseReportsServiceDocuments({
    businessId,
    filter: filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    sorting,
  };
};

export {
  IExpenseReportsTrashCollection,
  useExpenseReportsTrashCollection,
};
