module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="document-header">\n    <h2 class="font-bold font-20 m-t-0 m-b-0">Invoice Detail</h2>\n    <a class="close-page-btn pointer">\n        <i class="icon icon-reject-invite font-40 font-bold in-gray-important"></i>\n    </a>\n</div>\n<div class="document-content">\n    <div class="form-group">\n        <label for="payee-input">Payee</label>\n        <input id="payee-input" type="text" class="form-control" value="');
    
      __out.push(__sanitize(this.invoice.get('vendor') ? this.invoice.get('vendor').name : ''));
    
      __out.push('" readonly>\n    </div>\n    <div class="form-group">\n        <label for="amount-input">Amount</label>\n        <input id="amount-input" type="text" class="form-control" value="');
    
      __out.push(__sanitize(this.invoice.get('display_amount')));
    
      __out.push('" readonly>\n    </div>\n    <div class="form-group">\n        <label for="category-input">Category</label>\n        <p id="category-input" class="width-200px m-t-3">Multiple Categories</p>\n    </div>\n    <div class="form-group">\n        <label for="date-input">Invoice Date</label>\n        <input id="date-input" type="text" class="form-control" value="');
    
      __out.push(__sanitize(this.invoice.get('date_of_invoice_formated')));
    
      __out.push('" readonly>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}