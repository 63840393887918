import Caption from './caption';
import ClearFilter from './clear_filter';
import Footer from './footer';
import NoDataMessage from './no_data_message';
import PaginationInfo from './pagination_info';
import Panel from './panel';
import Settings from './settings';
import TableSection from './table_section';

export default Object.assign(TableSection, {
  Caption,
  ClearFilter,
  Footer,
  NoDataMessage,
  PaginationInfo,
  Panel,
  Settings,
});
