/* eslint-disable import/order */
import React, { memo, useRef, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetListOfThreads } from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';

import { PlusIcon } from '@src/components/utils/fa_icons';
import { AiChatExternalIcon } from '@src/components/utils/img_icons';

import ChatListSection from './chat_list_section';
import StartChatThread from './start_chat_thread';

import { FAQ } from '../constants';

import styles from '../styles.module.scss';

const NavBar = () => {
  const query = useGetListOfThreads({});

  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll({
    elementRef: infiniteScrollRef,
    query,
  });

  const history = useMemo(() => {
    const historyResponse = query.data?.pages || [];
    return flatten(historyResponse.map((p) => p.collection));
  }, [query.data?.pages]);

  return (
    <div className={ styles['chat-list-section'] }>
      <div className={ `${styles['chat-btn-section']} ${styles['p-0']}` }>
        <StartChatThread
          isNewThread
          buttonText="New Thread"
          prefixIcon={ <PlusIcon fontSize={ 14 } /> }
        />
      </div>
      <div className={ styles['chat-list-scroll'] }>
        <ChatListSection
          header="frequently asked"
          list={ FAQ }
          prefixIcon={ <AiChatExternalIcon /> }
        />
        <ChatListSection
          header="History"
          infiniteScrollRef={ infiniteScrollRef }
          list={ history }
        />
      </div>
    </div>
  );
};

export default memo(NavBar);
