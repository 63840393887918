import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateManagementGroupSettings } from '@src/hooks/queries/dashboards/user_management_group_settings';
import { useUpdateUserSettings } from '@src/hooks/queries/users';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './edit_modal_form';
import { IFinancialInsightsInput } from './schema';

import styles from '../styles.module.scss';

interface IEditFinancialInsightsModalProps extends IUseModalProps {
  frequency: string,
}

const EditFinancialInsightsModal = ({
  frequency,
  isOpen,
  onCancel,
  onDone,
}: IEditFinancialInsightsModalProps) => {
  const updateFrequency = useUpdateUserSettings();
  const updateManagementGroups = useUpdateManagementGroupSettings();

  const { mutate: updateUserSettings } = updateFrequency;
  const { mutate: updateManagementGroupSettings } = updateManagementGroups;

  const handleDone = useCallback((values: IFinancialInsightsInput) => {
    const settingItems = values.selectedGroups.map((selectedGroup) => (
      { managementGroupId: selectedGroup.id }
    ));

    updateManagementGroupSettings(
      {
        settingValues: settingItems,
      },
      {
        onSuccess: () => {
          window.Docyt.vent.trigger('management_group:settings:changed');
          onDone();
        },
      },
    );
  }, [onDone, updateManagementGroupSettings]);

  const handleSubmit = useCallback((values: IFinancialInsightsInput) => {
    updateUserSettings(
      {
        financialInsightsEmailFrequency: values.frequency,
      },
      {
        onSuccess: () => {
          handleDone(values);
        },
      },
    );
  }, [handleDone, updateUserSettings]);

  return (
    <>
      <MutationStatus mutation={ updateFrequency } />
      <MutationStatus mutation={ updateManagementGroups } />
      <Modal.Form
        formId="edit_financial_insights_form"
        proceedTitle="Save"
        show={ isOpen }
        title="Financial Insights"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <div className={ styles['edit-financial-insights-modal-container'] }>
            <Form
              formId={ formId }
              frequency={ frequency }
              onSubmit={ handleSubmit }
            />
          </div>
        )}
      </Modal.Form>
    </>
  );
};

const useEditFinancialInsightsModal = makeUseModal(EditFinancialInsightsModal);

export {
  IEditFinancialInsightsModalProps,
  useEditFinancialInsightsModal,
  EditFinancialInsightsModal as default,
};
