import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useEmptyTrashInboxServiceDocument } from '@src/hooks/queries/inbox_service_documents';

import { useConfirmRestoreDocumentsModal } from '@src/components/common/confirm_delete';
import Dropdown from '@src/components/ui/dropdown';
import { RotateLeftIcon } from '@src/components/utils/fa_icons';
import MutationStatus from '@src/components/utils/mutation_status';

const BusinessInboxRestoreAllAction = () => {
  const business = useBusinessContext();
  const service = useInboxServiceContext();
  const emptyTrash = useEmptyTrashInboxServiceDocument();

  const { mutate } = emptyTrash;
  const handleConfirm = useCallback(() => {
    mutate({ serviceId: service.id, isRestore: true });
  }, [service.id, mutate]);

  const confirm = useConfirmRestoreDocumentsModal({
    business,
    documentIds: 'all',
    onDone:      handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ emptyTrash } />
      <confirm.Component { ...confirm.props } />
      <Dropdown.Item onSelect={ confirm.open }>
        <RotateLeftIcon fontSize={ 20 } mr={ 22 } />
        {' '}
        Restore All
      </Dropdown.Item>
    </>
  );
};

export default BusinessInboxRestoreAllAction;
