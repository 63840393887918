import React from 'react';

import aiChatSendIcon from '@assets/images/ai_chatbot/chat_send_button.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const aiChatSendButtonIcon = (props: IImgIconProps): JSX.Element => {
  return <ImgIcon alt="AI Chat Send" src={ aiChatSendIcon } { ...props } />;
};

export default aiChatSendButtonIcon;
