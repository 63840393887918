/* eslint-disable max-len */
import React, { FC, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { TFilterData } from '@src/types/filter';

import DownloadIcon from '@src/components/reconciliation_center/month_end/download_action/download_icon';
import inDrawer from '@src/components/reconciliation_center/month_end/drawers/in_drawer';
import { useFilterField } from '@src/components/ui_v2/filter';

import { useExcludedAndNotPushedCollection, usePushedOutsideCollection } from './hooks';
import StepAcknowledge from './step_acknowledge';
import StepReview from './step_review';
import Wizard from './wizard';
import WizardStep, { STEP_ACKNOWLEDGE, STEP_MATCH } from './wizard_step';

interface ComponentProps {
  items: IBankAccountReconciliationItemTypes;
  onClose?: () => void;
}

interface ControlsProps {
  items: IBankAccountReconciliationItemTypes;
}

const Component: FC<ComponentProps> = ({ items, onClose }) => {
  const business = useBusinessContext();
  const itemAcknowledged = items.additionalUnclearedBalance?.status === 'acknowledged';
  const [step, setStep] = useState(itemAcknowledged ? STEP_ACKNOWLEDGE : STEP_MATCH);
  const [showUnmatchedOnly, setShowUnmatchedOnly] = useState(true);

  const pushedOutsideCollection = usePushedOutsideCollection({
    businessId: business.id,
    params:     {
      showUnmatchedOnly,
      item_id: [
        items.pushedByOutsideTransactions?.id.toString(),
        items.pushedByOutsideTransactionsCarriedOver?.id.toString(),
      ],
    },
  });

  const excludedAndNotPushedCollection = useExcludedAndNotPushedCollection({
    businessId: business.id,
    params:     {
      showUnmatchedOnly,
      item_id: [
        items.excludedTransactions?.id.toString(),
        items.excludedTransactionsCarriedOver?.id.toString(),
        items.notPushedTransactions?.id.toString(),
        items.notPushedTransactionsCarriedOver?.id.toString(),
      ],
    },
  });

  const [, updatePushedOutsideFilter] = useFilterField<TFilterData, boolean>('showUnmatchedOnly', pushedOutsideCollection.section);
  const [, updateExcludedAndNotPushedFilter] = useFilterField<TFilterData, boolean>('showUnmatchedOnly', excludedAndNotPushedCollection.section);

  const updateParams = (unmatchedOnly: boolean) => {
    setShowUnmatchedOnly(unmatchedOnly);
    updatePushedOutsideFilter(unmatchedOnly);
    updateExcludedAndNotPushedFilter(unmatchedOnly);
  };

  return (
    <>
      <Wizard step={ step } />
      <WizardStep show={ step === STEP_MATCH }>
        <StepReview
          closeDrawer={ onClose }
          excludedAndNotPushedCollection={ excludedAndNotPushedCollection }
          pushedOutsideCollection={ pushedOutsideCollection }
          setShowUnmatchedOnly={ updateParams }
          setStep={ setStep }
          showUnmatchedOnly={ showUnmatchedOnly }
        />
      </WizardStep>
      <WizardStep show={ step === STEP_ACKNOWLEDGE }>
        <StepAcknowledge
          closeDrawer={ onClose }
          excludedAndNotPushedCollection={ excludedAndNotPushedCollection }
          items={ items }
          pushedOutsideCollection={ pushedOutsideCollection }
          setStep={ setStep }
        />
      </WizardStep>
    </>
  );
};

const Controls: FC<ControlsProps> = ({ items }) => {
  const queryParams = {
    item_id: [
      items.excludedTransactions?.id.toString(),
      items.excludedTransactionsCarriedOver?.id.toString(),
      items.pushedByOutsideTransactions?.id.toString(),
      items.pushedByOutsideTransactionsCarriedOver?.id.toString(),
      items.notPushedTransactions?.id.toString(),
      items.notPushedTransactionsCarriedOver?.id.toString(),
    ],
  };

  return (
    <DownloadIcon
      exportParams={ queryParams as object }
      fontSize={ 18 }
      inColor="black"
      type="additional_uncleared_balance"
    />
  );
};

export default inDrawer(Component, Controls, {
  title:       'Review Additional Uncleared Balance',
  sectionName: 'month-end-additional-uncleared-balance',
});
