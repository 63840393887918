import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IDropdownItemIconProps extends React.ComponentPropsWithoutRef<'span'> {}

const DropdownItemIcon = ({
  children,
  className,
  ...props
}: IDropdownItemIconProps) => {
  const classes = classNames(styles['dropdown-menu-item-icon'], className);

  return (
    <span className={ classes } { ...props }>
      { children }
    </span>
  );
};

export default DropdownItemIcon;
