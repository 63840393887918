import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IButtonProps<T extends React.ElementType> {
  as?: T,
  prefixIcon?: React.ReactNode,
  size?: 'normal' | 'compact',
  suffixIcon?: React.ReactNode,
  variant: 'primary' | 'outline' | 'success' | 'destructive' | 'ghost' | 'link',
}

const Button = <T extends React.ElementType = 'button'>({
  as,
  children,
  className,
  prefixIcon,
  size = 'normal',
  suffixIcon,
  variant,
  ...props
}: IButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof IButtonProps<T>>) => {
  const classes = classNames(
    styles.button,
    styles[`button-${variant}`],
    styles[`button-${size}`],
    className,
  );

  const Component = as || 'button';

  return (
    <Component
      className={ classes }
      type="button"
      { ...props }
    >
      { prefixIcon && (
        <span className={ styles['button-prefix-icon'] }>{ prefixIcon }</span>
      ) }
      { children }
      { suffixIcon && (
        <span className={ styles['button-suffix-icon'] }>{ suffixIcon }</span>
      ) }
    </Component>
  );
};

export {
  Button as default,
  IButtonProps,
};
