module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="checkbox-clickable relative pointer">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" id="vendor-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push(' class="check-vendor pointer">\n    <label for="vendor-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="pointer">\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.image_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.image_url));
        __out.push(' class=\'vendor-clickable small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <div class="m-l-14 width-100-percent">\n    <div class="vendor-clickable display-flex align-items-center">\n      <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n      ');
    
      if (this.is_claimed) {
        __out.push('\n      <span class="icon-claimed m-l-6"><span class="path1"></span><span class="path2"></span></span>\n      ');
      }
    
      __out.push('\n    </div>\n    <div id="merge-option-available-action-region"></div>\n  </div>\n  </div>\n</td>\n<td class="vendor-clickable pointer">');
    
      __out.push(__sanitize(this.qbo_name));
    
      __out.push('</td>\n<td class="vendor-clickable pointer">');
    
      __out.push(__sanitize(this.docyt_id));
    
      __out.push('</td>\n<td class="vendor-clickable pointer">\n  ');
    
      if (this.ach_status === 'activated') {
        __out.push('\n    <span class="invoice-status-panel font-bold approval"> ACH Active </span>\n  ');
      } else if (this.invited_to_docyt) {
        __out.push('\n    ');
        if (this.accepted_to_docyt) {
          __out.push('\n      <span class="invoice-status-panel font-bold accepted"> Accepted </span>\n    ');
        } else {
          __out.push('\n      <span class="invoice-status-panel font-bold verifying"> Invite Sent </span>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</td>\n<td class="vendor-clickable pointer qbo-status-icon-column"></td>\n');
    
      if (this.businesses_tab_enabled) {
        __out.push('\n<td class="vendor-clickable pointer">\n  ');
        if (this.is_linked_to_businesses) {
          __out.push('\n    <span class="icon-multi-business font-24 multi-business-tooltip-js" data-html="true" data-toggle="tooltip" data-placement="bottom" title="This vendor is linked to multiple businesses"></span>\n  ');
        }
        __out.push('\n</td>\n');
      }
    
      __out.push('\n<td class="vendor-clickable pointer">\n  ');
    
      if (this.eligibility_1099) {
        __out.push('\n    ');
        if (this.is_w9_file_uploaded) {
          __out.push('\n    <span class="icon-1099-icon-active font-20"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n    ');
        } else {
          __out.push('\n    <span class="icon-1099-icon-deactive font-20"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</td>\n<td class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="vendor-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right vendor-action-menu" aria-labelledby="vendor-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    ');
    
      if (!this.archived_at) {
        __out.push('\n    ');
        if (!(this.ach_status === 'activated' || this.accepted_to_docyt)) {
          __out.push('\n    <div class="dropdown-item invite-docyt-js pointer display-flex font-14">\n      <span class="icon icon-mail m-t-3"></span>\n      <span class="m-l-10">');
          __out.push(__sanitize(this.invited_to_docyt ? 'Resend Invitation' : 'Invite Vendor to Docyt'));
          __out.push('</span>\n    </div>\n    ');
        }
        __out.push('\n    <div id="merge-vendor-action-region"></div>\n    ');
        if (!this.eligibility_1099) {
          __out.push('\n    <div class="dropdown-item set-eligibility-js pointer display-flex font-14">\n      <span class="icon icon-1099-icon font-18"></span>\n      <span class="m-l-10">Track Vendor to Generate 1099</span>\n    </div>\n    ');
        } else {
          __out.push('\n    <div class="dropdown-item remove-eligibility-js pointer display-flex font-14">\n      <span class="icon icon-1099-icon-deactive font-18"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n      <span class="m-l-10">Remove tracking for 1099 Generation</span>\n    </div>\n    ');
        }
        __out.push('\n    <div class="dropdown-item archive-item-js pointer display-flex font-14">\n      <span class="icon icon-account-archive"></span>\n      <span class="m-l-10">Archive Vendor</span>\n    </div>\n    <div class="dropdown-item remove-item-js pointer display-flex font-14">\n      <span class="icon icon-cross-icon font-18"></span>\n      <span class="m-l-10">Remove from "My Vendors List"</span>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="dropdown-item vendor-clickable pointer display-flex font-14">\n      <span class="icon icon-Payee-profile font-18"></span>\n      <span class="m-l-10">View Vendor Profile</span>\n    </div>\n    <div id="merge-vendor-action-region"></div>\n    <div class="dropdown-item unarchive-item-js pointer display-flex font-14">\n      <span class="icon icon-account-unarchive"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n      <span class="m-l-10">Unarchive Vendor</span>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}