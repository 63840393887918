import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TFilterData } from '@src/types/filter';

import { IconSelectInput, IIconSelectInputProps, TIconOption } from '@src/components/ui/form';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface IIconSelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  name: keyof TFilter,
}

const IconSelectFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: IIconSelectFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((item: TIconOption | null) => {
    update(item?.value);
  }, [update]);

  const options = props.options as TIconOption[] | undefined;
  const selectedItem = (options || []).find((i) => i.value === value);
  const wrapperClasses = classNames(wrapperProps?.className, { filtered: value });

  return (
    <Wrapper { ...wrapperProps } className={ wrapperClasses }>
      <IconSelectInput
        className={ props.rightAlignMenu ? 'right-align-select-menu' : '' }
        value={ selectedItem }
        onChange={ handleChange }
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(IconSelectFilterField);
