module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var bookkeeping_status, i, j, len, ref, ref1, ref2, year;
    
      __out.push('<div class="clearfix m-b-20">\n  <h2 class="m-b-5 font-bold font-18 pull-left">Month-End Close</h2>\n\n  <div class="m-t-15 pull-right">\n    <select class="form-control selectpicker" id="year-select" title="');
    
      __out.push(__sanitize(this.curYear || 'Select Year'));
    
      __out.push('">\n      ');
    
      for (year = i = ref = this.startYear, ref1 = this.maxYear; ref <= ref1 ? i <= ref1 : i >= ref1; year = ref <= ref1 ? ++i : --i) {
        __out.push('\n        <option value="');
        __out.push(__sanitize(year));
        __out.push('" ');
        if (this.curYear === year) {
          __out.push(__sanitize('active'));
        }
        __out.push('>');
        __out.push(__sanitize(year));
        __out.push('</option>\n      ');
      }
    
      __out.push('\n    </select>\n  </div>\n</div>\n\n<div class="panel panel-default table-responsive month-end-reconciliation-table-wrapper">\n  <table class="table bg-white">\n    <thead>\n      <tr>\n        <th class="pointer relative sortable ');
    
      if (this.order_column === 'month') {
        __out.push('\n          ');
        __out.push(__sanitize(this.order_direction === 'desc' ? 'sorted-desc' : this.order_direction === 'asc' ? 'sorted-asc' : void 0));
        __out.push('\n        ');
      }
    
      __out.push('" data-orderby="month">\n          Month\n          <i class="fa fa-long-arrow-down" />\n          <i class="fa fa-long-arrow-up" />\n        </th>\n        <th class="pointer relative sortable ');
    
      if (this.order_column === 'status') {
        __out.push('\n          ');
        __out.push(__sanitize(this.order_direction === 'desc' ? 'sorted-desc' : this.order_direction === 'asc' ? 'sorted-asc' : void 0));
        __out.push('\n        ');
      }
    
      __out.push('" data-orderby="status">\n          Status\n          <i class="fa fa-long-arrow-down" />\n          <i class="fa fa-long-arrow-up" />\n        </th>\n        <th class="pointer relative sortable ');
    
      if (this.order_column === 'completed_on') {
        __out.push('\n          ');
        __out.push(__sanitize(this.order_direction === 'desc' ? 'sorted-desc' : this.order_direction === 'asc' ? 'sorted-asc' : void 0));
        __out.push('\n        ');
      }
    
      __out.push('" data-orderby="completed_on">\n          Completed On\n          <i class="fa fa-long-arrow-down" />\n          <i class="fa fa-long-arrow-up" />\n        </th>\n        <th class="pointer relative sortable ');
    
      if (this.order_column === 'completed_by_user') {
        __out.push('\n          ');
        __out.push(__sanitize(this.order_direction === 'desc' ? 'sorted-desc' : this.order_direction === 'asc' ? 'sorted-asc' : void 0));
        __out.push('\n        ');
      }
    
      __out.push('" data-orderby="completed_by_user">\n          Completed By\n          <i class="fa fa-long-arrow-down" />\n          <i class="fa fa-long-arrow-up" />\n        </th>\n      </tr>\n    </thead>\n    <tbody>\n      ');
    
      ref2 = this.bookkeeping_statuses;
      for (j = 0, len = ref2.length; j < len; j++) {
        bookkeeping_status = ref2[j];
        __out.push('\n        ');
        if (bookkeeping_status.year <= this.startYear && bookkeeping_status.month < this.startMonth) {
          continue;
        }
        __out.push('\n        <tr>\n          <td>');
        __out.push(__sanitize(Docyt.Utils.titleize(this.MONTH_SHORT_NAMES[bookkeeping_status.month - 1])));
        __out.push(' ');
        __out.push(__sanitize(this.curYear));
        __out.push('</td>\n\n          <td>\n            ');
        if (bookkeeping_status.status === 'reconciled') {
          __out.push('\n              <div class="display-flex p-t-5 p-b-5">\n                <a href="/businesses/');
          __out.push(__sanitize(this.business_id));
          __out.push('/reconciliation_center/month_end_reconciliation?year=');
          __out.push(__sanitize(this.curYear));
          __out.push('&month=');
          __out.push(__sanitize(bookkeeping_status.month));
          __out.push('" class="display-flex">\n                  <span class="text-success pull-left">Completed</span>\n                </a>\n\n                ');
          if (bookkeeping_status.can_reopen) {
            __out.push('\n                  <div class="relative">\n                    <a class="m-l-10 pointer dropdown-toggle" type="button" id="bookkeeping-status-');
            __out.push(__sanitize(bookkeeping_status.id));
            __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n                      <span class="fa fa-caret-down font-15" style="color: #242B33"></span>\n                    </a>\n                    <ul class="dropdown-menu dropdown-menu-left font-24" role="menu" aria-labelledby="bookkeeping-status-');
            __out.push(__sanitize(bookkeeping_status.id));
            __out.push('-action-dropdown-btn">\n                      <li><a tabindex="-1" class="pointer re-open-month" data-month="');
            __out.push(__sanitize(bookkeeping_status.month));
            __out.push('">Re-open Month</a></li>\n                    </ul>\n                  </div>\n                ');
          }
          __out.push('\n              </div>\n            ');
        } else if (bookkeeping_status.status === 'in-progress') {
          __out.push('\n              <a href="/businesses/');
          __out.push(__sanitize(this.business_id));
          __out.push('/reconciliation_center/month_end_reconciliation?year=');
          __out.push(__sanitize(this.curYear));
          __out.push('&month=');
          __out.push(__sanitize(bookkeeping_status.month));
          __out.push('" class="btn btn-sm btn-secondary font-12 font-semi-bold text-center">\n                In Progress\n              </a>\n            ');
        } else if (bookkeeping_status.status === 'not-started') {
          __out.push('\n              <a href="/businesses/');
          __out.push(__sanitize(this.business_id));
          __out.push('/reconciliation_center/month_end_reconciliation?year=');
          __out.push(__sanitize(this.curYear));
          __out.push('&month=');
          __out.push(__sanitize(bookkeeping_status.month));
          __out.push('" class="btn btn-sm btn-blue font-12 font-semi-bold x-begin-month-end" data-month="');
          __out.push(__sanitize(bookkeeping_status.month));
          __out.push('">\n                Begin Month-end Close\n              </a>\n            ');
        } else if (bookkeeping_status.status === 'broken') {
          __out.push('\n              <div class="bookkeeping-status-broken">\n                <span class="icon-warning-triangle font-20 warning-color"></span>\n                <a\n                  class=\'broken-text\' \n                  href="/businesses/');
          __out.push(__sanitize(this.business_id));
          __out.push('/reconciliation_center/month_end_reconciliation?year=');
          __out.push(__sanitize(this.curYear));
          __out.push('&month=');
          __out.push(__sanitize(bookkeeping_status.month));
          __out.push('"\n                >\n                  Broken Reconciliation\n                </a>\n                 ');
          if (bookkeeping_status.month === this.latest_broken_month) {
            __out.push('\n                   <span\n                    data-toggle="tooltip" \n                    data-placement="bottom" \n                    class="broken-status-tooltip-js"\n                    data-html="true"\n                    title="There is a discrepancy because at least one transaction was changed or deleted since last reconciliation"\n                  >\n                   <span class="fa fa-caret-down font-15"></span>\n                  </span>\n                 ');
          }
          __out.push('\n              </div>\n            ');
        }
        __out.push('\n          </td>\n\n          <td>\n            ');
        if (bookkeeping_status.completed_on) {
          __out.push('\n              ');
          __out.push(__sanitize(moment(bookkeeping_status.completed_on).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
          __out.push('\n            ');
        } else {
          __out.push('\n              &mdash;\n            ');
        }
        __out.push('\n          </td>\n\n          <td>\n            ');
        if (bookkeeping_status.completed_by_user) {
          __out.push('\n              <div class="display-flex align-items-center">\n                ');
          if (bookkeeping_status.completed_by_user.avatar && bookkeeping_status.completed_by_user.avatar.avatar_url) {
            __out.push('\n                  <div class="small-avatar-wrapper">\n\n                    <img src="');
            __out.push(__sanitize(bookkeeping_status.completed_by_user.avatar.avatar_url));
            __out.push('" class="avatar-img" />\n                  </div>\n                ');
          } else {
            __out.push('\n                  <span class="icon-default-avatar font-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n                ');
          }
          __out.push('\n                <div class="m-l-10">\n                  ');
          __out.push(__sanitize(bookkeeping_status.completed_by_user.parsed_fullname));
          __out.push('\n                </div>\n              </div>\n            ');
        } else {
          __out.push('\n              &mdash;\n            ');
        }
        __out.push('\n          </td>\n        </tr>\n      ');
      }
    
      __out.push('\n    </tbody>\n  </table>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}