import React, { useCallback, useState, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useRemoveFromBusiness } from '@src/hooks/queries/business_vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

interface IDeleteConfirmProps extends IUseModalProps {
  icon?: JSX.Element,
  text: JSX.Element | string,
  title: JSX.Element | string,
  vendor: IBusinessVendor;
}

const DeleteConfirm = ({
  vendor,
  icon,
  isOpen,
  text,
  title,
  onCancel,
  onDone,
}: IDeleteConfirmProps): JSX.Element => {
  const removedVendor = useRemoveFromBusiness();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
    onDone();
  }, [onDone]);

  const { mutate: removeVendor } = removedVendor;

  const handleDeleteConfirm = useCallback(() => {
    setErrorMessage(undefined);
    removeVendor(
      {
        vendorId:   vendor.id,
        businessId: vendor.businessId,
      },
      {
        onSuccess: () => {
          onDone();
          setSuccessMessage('Remove vendor successfully .');
          setTimeout(() => {
            window.location.pathname = `/businesses/${vendor.businessId}/vendor_service`;
          }, 1000);
        },
        onError: handleError,
      },
    );
  }, [removeVendor, vendor.id, vendor.businessId, handleError, onDone]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(undefined);
        onCancel();
      }, 3000);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [successMessage, onCancel]);

  return (
    <>
      {(removedVendor.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Cannot Remove Vendor" />}
      {successMessage && <SuccessNotification message={ successMessage } />}

      <Modal
        className="modal-v2"
        show={ isOpen }
        title={ title }
      >
        <Modal.Body>
          {
          icon && (
            <div className="text-center delete-icon-wrapper">
              <div className="inline-block relative">
                { icon }
              </div>
            </div>
          )
        }
          <p className="p-b-0 text-center font-18">{ text }</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button className="btn-outline" variant="link" onClick={ onCancel }>
            Cancel
          </Button>
          <Button
            className="btn-destructive"
            variant="destructive"
            onClick={ handleDeleteConfirm }
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useConfirmDeleteModal = makeUseModal(DeleteConfirm);

export {
  IDeleteConfirmProps,
  useConfirmDeleteModal,
  DeleteConfirm as default,
};
