import { useQueryClient, useQuery, useMutation, UseMutationOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useBankAccountReconciliationFayeChannel } from '@src/hooks/faye/bank_account_reconciliation';
import {
  getBankAccountReconciliation,
  calculateBankAccountReconciliation,
  updateBankAccountReconciliation,
  getTransactionAIContext,
  getTransactionClientContext,
  getMatchedRlues,
  getPaymentProcessors,
  getTransactionMessages,
  IGetBankAccountReconciliationResponse,
  IBankAccountReconciliationUpdateParams,
  IUpdateBankAccountReconciliationResponse,
  ITransactionAIContextParams,
  ITransactionAIContextResponse,
  ITransactionClientContextParams,
  ITransactionClientContextResponse,
  IMatchedRuleResponse,
  IGetPaymentProcessorsParams,
  IGetPaymentProcessorsResponse,
  ITransactionMessagesResponse,
  getBankAccountReconciliationPDF,
  matchBankAccountReconciliationDocuments,
  IMatchDocumentsParams,
  IMatchDocumentsResponse,
  unmatchBankAccountReconciliationDocuments, unReconcile,
} from '@src/requests/bank_account_reconciliations';
import { TID } from '@src/types/common';
import { IReconciliationUpdatedFayeEvent } from '@src/types/docyt_events/reconciliation_updated';

import { useDebouncedCallback } from '../utils';

const FAYE_EVENT_DEBOUNCE_INTERVAL = 5000;

const useGetBankAccountReconciliation = (
  businessId: TID,
  id: TID,
  setCalculateLoading: Function,
) => {
  const query = useQuery<IGetBankAccountReconciliationResponse, Error>({
    queryKey:         [QueryKey.bankAccountReconciliation, id],
    queryFn:          () => getBankAccountReconciliation(id),
    keepPreviousData: true,
  });

  const { refetch } = query;
  const handleDataReceived = useDebouncedCallback((
    fayeData: IReconciliationUpdatedFayeEvent,
  ) => {
    if (fayeData.event.reconciliationId !== id) return;
    setCalculateLoading(false);
    refetch();
  }, [id, refetch], FAYE_EVENT_DEBOUNCE_INTERVAL);
  useBankAccountReconciliationFayeChannel(businessId, handleDataReceived);

  return query;
};

const useCalculateBankAccountReconciliation = (id: TID) => {
  return useQuery<IGetBankAccountReconciliationResponse, Error>({
    queryKey: [QueryKey.calculateBankAccountReconciliation, id],
    queryFn:  () => calculateBankAccountReconciliation(id),
    enabled:  false,
  });
};

const useUpdateBankAccountReconciliation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IUpdateBankAccountReconciliationResponse,
    Error,
    IBankAccountReconciliationUpdateParams
  >(
    updateBankAccountReconciliation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankAccountReconciliation);
        queryClient.invalidateQueries(QueryKey.calculateBankAccountReconciliation);
      },
    },
  );
};

const useGetMatchedRules = (id: TID) => {
  return useQuery<IMatchedRuleResponse, Error>({
    queryKey: [QueryKey.bankAccountReconciliationMatchedRules, id],
    queryFn:  () => getMatchedRlues(id),
  });
};

const useGetPaymentProcessors = (params: IGetPaymentProcessorsParams) => {
  return useQuery<IGetPaymentProcessorsResponse, Error>(
    [QueryKey.reconciliationPaymentProcessors, params],
    () => getPaymentProcessors(params),
  );
};
const useGetTransactionAIContext = (
  options?: UseMutationOptions<ITransactionAIContextResponse, Error, ITransactionAIContextParams>,
) => {
  return useMutation<
    ITransactionAIContextResponse,
    Error,
    ITransactionAIContextParams
  >(
    getTransactionAIContext,
    options,
  );
};

const useGetTransactionClientContext = (
  options?: UseMutationOptions<
    ITransactionClientContextResponse,
    Error,
    ITransactionClientContextParams>,
) => {
  return useMutation<
    ITransactionClientContextResponse,
    Error,
    ITransactionClientContextParams
  >(
    getTransactionClientContext,
    options,
  );
};

const useGetTransactionMessages = (documentId: TID) => {
  return useQuery<ITransactionMessagesResponse, Error>({
    queryKey: [QueryKey.bankAccountReconciliationMessages, documentId],
    queryFn:  () => getTransactionMessages(documentId),
  });
};

const useGetBankAccountReconciliationPDF = () => {
  return useMutation<Blob, Error, TID>(getBankAccountReconciliationPDF);
};
const useMatchBankAccountReconciliationDocuments = (
  options?: UseMutationOptions<IMatchDocumentsResponse, Error, IMatchDocumentsParams>,
) => {
  return useMutation<IMatchDocumentsResponse, Error, IMatchDocumentsParams>(
    matchBankAccountReconciliationDocuments,
    options,
  );
};

const useUnmatchBankAccountReconciliationDocuments = (
  options?: UseMutationOptions<IMatchDocumentsResponse, Error, IMatchDocumentsParams>,
) => {
  return useMutation<IMatchDocumentsResponse, Error, IMatchDocumentsParams>(
    unmatchBankAccountReconciliationDocuments,
    options,
  );
};

const useUnReconcile = () => {
  return useMutation<IGetBankAccountReconciliationResponse, Error, TID>(unReconcile);
};

export {
  useGetBankAccountReconciliation,
  useCalculateBankAccountReconciliation,
  useUpdateBankAccountReconciliation,
  useGetMatchedRules,
  useGetPaymentProcessors,
  useGetTransactionAIContext,
  useGetTransactionClientContext,
  useGetTransactionMessages,
  useGetBankAccountReconciliationPDF,
  useMatchBankAccountReconciliationDocuments,
  useUnmatchBankAccountReconciliationDocuments,
  useUnReconcile,
};
