import React, { useCallback } from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { currencyFormater } from '@src/utils/currency';

import Table from '@src/components/ui/table';

import { getTransactionType, getTransactionTypeClass } from '../utils';

interface IDuplicatedTransactionTableItemProps {
  transaction: ITransactionServiceDocument,
}

const DuplicatedTransactionTableItem = ({
  transaction,
}: IDuplicatedTransactionTableItemProps) => {
  const handleDuplicatedTransactionClick = useCallback(() => {
    window.open(`/document/${transaction.duplicateOriginTransaction.docytId}`, '_blank');
  }, [transaction.duplicateOriginTransaction.docytId]);

  const handleTransactionClick = useCallback(() => {
    window.open(`/document/${transaction.docytId}`, '_blank');
  }, [transaction.docytId]);

  return (
    <Table.Row>
      <Table.SelectItemCell
        checkedLabel={ <span className="in-red">Duplicate</span> }
        item={ transaction.id }
        uncheckedLabel={ <span>Not Duplicate</span> }
      />
      <Table.Cell>
        <div className="display-flex-column">
          <a
            className="pointer"
            role="button"
            tabIndex={ -1 }
            onClick={ handleDuplicatedTransactionClick }
          >
            {transaction.duplicateOriginTransaction.docytId}
          </a>
          <a
            className="m-t-6 pointer"
            role="button"
            tabIndex={ -1 }
            onClick={ handleTransactionClick }
          >
            {transaction.docytId}
          </a>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="display-flex-column">
          <div>{ transaction.duplicateOriginTransaction.transactionDate }</div>
          <div className="m-t-6">{ transaction.transactionDate }</div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="display-flex-column">
          <div>{ transaction.duplicateOriginTransaction.description }</div>
          <div className="m-t-6">{ transaction.description }</div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="display-flex-column">
          <div className={ `transaction-type-badge m-r-auto ${getTransactionTypeClass(transaction.duplicateOriginTransaction)}` }>
            { getTransactionType(transaction.duplicateOriginTransaction) }
          </div>
          <div className={ `m-t-6 m-r-auto transaction-type-badge ${getTransactionTypeClass(transaction)}` }>
            { getTransactionType(transaction) }
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="display-flex-column">
          <div>{ transaction.duplicateOriginTransaction.employeeCardName }</div>
          <div className="m-t-6">{ transaction.employeeCardName }</div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="display-flex-column text-right">
          <div>{ currencyFormater(transaction.duplicateOriginTransaction.amount) }</div>
          <div className="m-t-6">{ currencyFormater(transaction.amount) }</div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(DuplicatedTransactionTableItem);
