module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog billing-credit-card-modal">\n    <div class="modal-content">\n      ');
    
      if (!this.bankAccountPaymentMethodsBillingFlag) {
        __out.push('\n      <div class="modal-header">\n        <h4 class="modal-title">');
        __out.push(__sanitize(this.title));
        __out.push('</h4>\n      </div>\n      ');
      }
    
      __out.push('\n      <div class="modal-body">\n        ');
    
      if (this.bankAccountPaymentMethodsBillingFlag) {
        __out.push('\n        <div class="switch-tab">\n          <div class="activated-switch-tab-item credit-card-tab">Credit Card</div>\n          <div class="switch-tab-item bank-account-tab">Bank Account</div>\n        </div>\n        ');
      }
    
      __out.push('\n        <div class="stripe-credit-card-region"></div>\n        <div class="stripe-bank-account-region hidden"></div>\n        <p class="billing-card-hint">Securely submit your card details to set up automatic monthly payments for this business.</p>\n        <div class=\'select-existing-credit-cards\'>\n          ');
    
      if (this.creditCards.length > 0) {
        __out.push('\n          <hr>\n          <a class="btn-select-existing-credit-card pointer">Select from ');
        __out.push(__sanitize(this.creditCards.length));
        __out.push(' existing credit cards</a>\n          ');
      }
    
      __out.push('\n        </div>\n        ');
    
      if (this.bankAccountPaymentMethodsBillingFlag) {
        __out.push('\n        <div class="select-existing-bank-accounts hidden">\n          ');
        if (this.bankAccounts.length > 0) {
          __out.push('\n          <hr>\n          <a class="btn-select-existing-bank-account pointer">Select from ');
          __out.push(__sanitize(this.bankAccounts.length));
          __out.push(' existing bank accounts</a>\n          ');
        }
        __out.push('\n        </div>\n        ');
      }
    
      __out.push('\n        ');
    
      if (this.subscription_plan) {
        __out.push('\n        <hr>\n        <h3 class="font-16 m-b-10 font-semi-bold">\n          Selected Plan:\n          ');
        __out.push(__sanitize(this.subscription_plan.name));
        __out.push('\n          <p class="subscription-plan-hint">\n            Free for ');
        __out.push(__sanitize(configData.free_trial_days));
        __out.push(' days\n          </p>\n          <p class="subscription-plan-hint">Switch to Free plan at any time from Settings > Account</p>\n        </h3>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n        <button type="submit" class="btn btn-info btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}