import { createContext, useContext } from 'react';

import { IBusiness } from '@src/types/businesses';

const BusinessContext = createContext<IBusiness | null>(null);

// force convert to `IBusiness` type without null because childs of provider are
// rendered only then business present.
const useBusinessContext = () => <IBusiness>useContext(BusinessContext);

export {
  BusinessContext,
  useBusinessContext,
};
