/* global __WebpackModuleApi */

import sortBy from 'lodash/sortBy';

const templatesContext = require.context('@assets_js/docyt-client/templates', true, /.*\.jst.eco/);

window.JST = {};

const importTemplates = (reqContext: __WebpackModuleApi.RequireContext) => {
  sortBy(templatesContext.keys()).forEach((file: string) => {
    const templateName = `docyt-client/templates${file.substring(1, file.length - 8)}`;
    window.JST[templateName] = reqContext(file);
  });
};
importTemplates(templatesContext);
