/* eslint-disable import/order */
import React, { memo } from 'react';

import ChannelList from '@sendbird/uikit-react/ChannelList';

import ChannelListHeader from './header';
import ChannelListItem from './item';
import Loader from './loader';

import { IChannelListProps, IRenderChannelPreviewProps } from '../../types';
import styles from '../../styles.module.scss';

interface IChannelListViewProps extends IChannelListProps {
  setChannelUrl: (channelUrl: string) => void;
  currentChannelUrl?: string;
}

const ChannelListView = (
  {
    setChannelUrl,
    currentChannelUrl,
  }
  :IChannelListViewProps,
) => {
  return (
    <ChannelList
      className={ styles['chat-list-container'] }
      queries={ {
        channelListQuery: {
          limit:             30,
          includeEmpty:      true,
          customTypesFilter: ['business_channel'],
          order:             'latest_last_message',
        },
      } }
      renderChannelPreview={ (item: IRenderChannelPreviewProps) => {
        return <ChannelListItem { ...item } currentChannelUrl={ currentChannelUrl } />;
      } }
      renderHeader={ () => <ChannelListHeader /> }
      renderPlaceHolderLoading={ () => <Loader /> }
      onChannelSelect={ (channel) => {
        if (channel && channel?.url) {
          setChannelUrl(channel.url);
        }
      } }
    />
  );
};

export default memo(ChannelListView);
