import { TID } from '@src/types/common';
import { IQuickbooksCustomer } from '@src/types/quickbooks_customers';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetQuickbooksCustomersParams {
  businessId: TID
}

interface IGetQuickbooksCustomersResponse {
  quickbooksCustomers: IQuickbooksCustomer[]
}

interface IMergeQuickbooksCustomerParams {
  newCustomerId: TID,
  businessId: TID,
  oldCustomerId: TID,
}

const getQuickbooksCustomers = (
  params: IGetQuickbooksCustomersParams,
): Promise<IGetQuickbooksCustomersResponse> => {
  return apiGet(
    '/api/v1/quickbooks_customers',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetQuickbooksCustomersResponse);
};

const mergeQuickbooksCustomer = (
  params: IMergeQuickbooksCustomerParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/quickbooks_customers/merge_customers',
    underscoreKeys(params),
  );
};

export {
  IGetQuickbooksCustomersParams,
  IMergeQuickbooksCustomerParams,
  IGetQuickbooksCustomersResponse,
  getQuickbooksCustomers,
  mergeQuickbooksCustomer,
};
