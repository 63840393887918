module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class=\'modal-add-client invite-form-modal-content ');
    
      __out.push(__sanitize(this.modal_class));
    
      __out.push('\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>');
    
      __out.push(__sanitize(this.modal_title));
    
      __out.push('</h4>\n        </div>\n        <div class=\'modal-body\'>\n          <div class=\'main-input__wrap\'>\n            <div class=\'relative\'>\n              <i class=\'login-form__input-icon icn icn-user\'></i>\n              <input class=\'login-form__input main-input\' id="name" placeholder=\'First Name  Middle  Last\' type=\'text\' value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n            </div>\n            <p class=\'main-input__hint in-red-400\' id="name-invalid">\n              Please enter correct name\n            </p>\n            <p class=\'main-input__hint in-red-400\' id="name-exists">\n              This name is already in use\n            </p>\n          </div>\n          <div class=\'main-input__wrap\'>\n            <div class=\'relative\'>\n              <i class=\'clients__action-icon icon icon-envelope font-14\'></i>\n              <input class=\'login-form__input main-input\' id="email" placeholder=\'Email\' type=\'email\'  value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n            </div>\n            <p class=\'main-input__hint in-red-400\' id="email-invalid">\n              Please enter correct email\n            </p>\n            <p class=\'main-input__hint in-red-400\' id="email-exists">\n              This email is already in use\n            </p>\n          </div>\n          <div class=\'main-input__wrap category-size-wrapper selectize-wrapper\'>\n            <i class=\'login-form__input-icon icon icon-relationship\'></i>\n            <select class=\'file-category relationship-input-js\' placeholder=\'This person is my...\'></select>\n            <p class=\'main-input__hint in-red-400 relationship-invalid-js\'>\n              Please select relationship for contact\n            </p>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          ');
    
      if (this.add_another_enabled) {
        __out.push('\n          <button type=\'button\' class="btn btn-info ladda-button" id=\'add-another\' data-color="blue" data-style="expand-right"><span class="ladda-label">Add Another</span></button>\n          <button type=\'button\' class="btn btn-info ladda-button" id=\'create-invite\' data-color="blue" data-style="expand-right"><span class="ladda-label">Done</span></button>\n          ');
      } else {
        __out.push('\n          <button type=\'button\' class="btn btn-info ladda-button" id=\'create-invite\' data-color="blue" data-style="expand-right">\n            <span class="ladda-label">\n              ');
        if (this.modal_submittitle) {
          __out.push('\n                ');
          __out.push(__sanitize(this.modal_submittitle));
          __out.push('\n              ');
        } else {
          __out.push('\n                ');
          __out.push(__sanitize(this.id ? 'Save' : 'Add'));
          __out.push('\n              ');
        }
        __out.push('\n            </span>\n          </button>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <div class=\'modal-add-client choose-matching-modal-content hidden\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>');
    
      __out.push(__sanitize(this.modal_title));
    
      __out.push('</h4>\n        </div>\n        <div class=\'modal-body\'>\n          <div class=\'\'>\n            <h2 class="font-16 font-bold text-center">Is this the person you are trying to connect?</h2>\n          </div>\n          <div class="account-type__image-wrap m-t-20">\n            <div class="clients__userpic">\n              <img src="" class="clients__userpic-img matching-user-image">\n            </div>\n          </div>\n          <div class="">\n            <h2 class="text-center text-bold font-22 matching-user-name"></h2>\n            <h3 class="text-center text-bold font-12 matching-user-info"></h3>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link settings-cancel-btn\' id=\'create-invite-ignore-matching\'>I don\'t recognize this person.</a>\n          <button type=\'button\' class="btn btn-info ladda-button" id=\'send-invite-to-matching-user\' data-color="blue" data-style="expand-right"><span class="ladda-label">Yes, send invitation to connect</span></button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}