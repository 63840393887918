import React from 'react';

import { useParams } from 'react-router-dom';

import { invoiceQueueTabsPath } from '@src/routes';

import { Button } from '@src/components/ui_v2/buttons';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import { PlusIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import { useInvoiceQueueCollection } from './hooks';
import InvoiceQueueTable from './table';

const TABS = [
  {
    id: 1,
    title: 'All Invoices',
    type: 'queued',
  },
  {
    id: 2,
    title: 'Paid',
    type: 'paid',
  },
  {
    id: 3,
    title: 'Stopped',
    type: 'stopped',
  }
]

const InvoiceQueue = () => {
  const { businessId, queueType } = useParams();
  const collection = useInvoiceQueueCollection({
    businessId: Number(businessId),
    type: queueType as string,
  });

  return (
    <>
      <HeaderRegion id="invoice-queue-action">
        <HeaderRegion.Tabs>
          { TABS.map((tab: { id: number, title: string, type: string }) => (
            <HeaderRegion.Tab
              key={ tab.id }
              active={ queueType === tab.type }
              href={ invoiceQueueTabsPath(Number(businessId), tab.type) }
              title={ tab.title }
            />
          )) }
        </HeaderRegion.Tabs>
        <HeaderRegion.ActionsArea>
          <Button
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="primary"
          >
            Add invoice
          </Button>
        </HeaderRegion.ActionsArea>
      </HeaderRegion>
      <Section.Provider section={ collection.section }>
        <TableSection>
          <InvoiceQueueTable collection={ collection } />
        </TableSection>
      </Section.Provider>
    </>
  );
};

export default React.memo(InvoiceQueue);
