import React from 'react';

import { VendorsServiceContext } from '@src/hooks/contexts/vendors_service_context';
import { useGetVendorsServiceByBusinessIdQuery } from '@src/hooks/queries/vendors_services';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IVendorsServiceProviderProps {
  businessId: TID,
  children: React.ReactNode,
}

const VendorsServiceProvider = ({
  businessId,
  children,
}: IVendorsServiceProviderProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetVendorsServiceByBusinessIdQuery(businessId);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Vendor Service loaded" />;

  return (
    <VendorsServiceContext.Provider value={ data }>
      { children }
    </VendorsServiceContext.Provider>
  );
};

export default VendorsServiceProvider;
