import React, { useCallback, useMemo, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateDashboard, useUpdateDashboard } from '@src/hooks/queries/dashboards/dashboards';
import { useGetTemplates } from '@src/hooks/queries/dashboards/templates';
import { TID, TMongoID } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { IDashboard } from '@src/types/dashboards/dashboards';

import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './create_dashboard_modal_form';
import { IDashboardInput } from './schema';

interface ICreateDashboardModalProps extends Omit<IUseModalProps, 'onDone'> {
  businessId: TID,
  dashboard?: IDashboard,
  standardCategoryId?: number,
  onDone: (dashboard?: IDashboard) => void,
}

const CreateDashboardModal = ({
  businessId,
  dashboard,
  standardCategoryId,
  isOpen,
  onCancel,
  onDone,
}: ICreateDashboardModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const query = useGetTemplates({
    standardCategoryId: standardCategoryId || -1,
  });

  const templates = useMemo(() => {
    return query.data?.templates || [];
  }, [query.data]);

  const widgets = useMemo(() => {
    return templates.map((template) => (
      {
        identifier: template.id,
        name:       template.name,
      } as unknown as IWidget
    ));
  }, [templates]);

  const create = useCreateDashboard();
  const update = useUpdateDashboard();

  const { mutate: createBusinessDashboard } = create;
  const { mutate: updateBusinessDashboard } = update;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const createDashboard = useCallback((values: IDashboardInput) => {
    createBusinessDashboard(
      {
        businessId,
        name:        values.name,
        templateIds: values.widgets.map((widget) => (widget.identifier)),
      },
      {
        onSuccess: (data) => {
          Backbone.history.navigate(`/businesses/${businessId}/dashboards/${data.dashboard.id}`, { trigger: true });
        },
        onError: handleError,
      },
    );
  }, [createBusinessDashboard, businessId, handleError]);

  const updateDashboard = useCallback((dashboardId: TMongoID, values: IDashboardInput) => {
    updateBusinessDashboard(
      {
        id:          dashboardId,
        name:        values.name,
        templateIds: values.widgets.map((widget) => (widget.identifier)),
      },
      {
        onSuccess: (data) => onDone(data.dashboard),
      },
    );
  }, [updateBusinessDashboard, onDone]);

  const handleSubmit = useCallback((values: IDashboardInput) => {
    if (!dashboard) {
      createDashboard(values);
    } else {
      updateDashboard(dashboard.id, values);
    }
  }, [createDashboard, dashboard, updateDashboard]);

  return (
    <>
      { (create.isLoading || update.isLoading) && <Spinner /> }
      { query.isError && <ErrorNotification message="No Templates loaded" /> }
      { errorMessage && <ErrorNotification message={ errorMessage } /> }
      <MutationStatus mutation={ update } successMessage="Updated a dashboard successfully." />
      <Modal.Form
        formId="create_dashboard_form"
        proceedTitle={ dashboard ? 'Save' : 'Create' }
        show={ isOpen }
        title={ dashboard ? 'Edit Dashboard' : 'Create New Dashboard' }
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <div className="create_dashboard_modal_container">
            <Form
              dashboard={ dashboard }
              formId={ formId }
              widgets={ widgets }
              onSubmit={ handleSubmit }
            />
          </div>
        )}
      </Modal.Form>
    </>
  );
};

const useCreateDashboardModal = makeUseModal(CreateDashboardModal);

export {
  ICreateDashboardModalProps,
  useCreateDashboardModal,
  CreateDashboardModal as default,
};
