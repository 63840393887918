import React, { useMemo } from 'react';

import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { useGetJournalNames, useGetFilterManagementGroups } from '@src/hooks/queries/joural_entries';
import { IFilter } from '@src/types/filter';
import { IJournalFilter } from '@src/types/journal_entries';

import Filter from '@src/components/ui/filter';
import { DummyIcon } from '@src/components/utils/fa_icons';

const ListFilter: React.FC<{ filter: IFilter<IJournalFilter> }> = ({ filter }) => {
  const businessId = useJournalEntriesContext();
  const journalNamesQuery = useGetJournalNames(businessId);

  const journalNames = useMemo(() => {
    return journalNamesQuery.data?.names || [];
  }, [journalNamesQuery.data?.names]);

  const managementGroupsQuery = useGetFilterManagementGroups(businessId);

  const managementGroups = useMemo(() => {
    return managementGroupsQuery.data?.managementGroups || [];
  }, [managementGroupsQuery.data?.managementGroups]);

  const nameOptions = journalNames.map((i) => ({
    value: i,
    label: i,
    icon:  <DummyIcon fontSize={ 17 } />,
  }));

  const managementGroupOptions = managementGroups.map((i) => ({
    value: String(i.id),
    label: i.name,
    icon:  <DummyIcon fontSize={ 17 } />,
  }));

  return (
    <Filter<IJournalFilter> filter={ filter }>
      <Filter.IconSelectField
        isClearable
        isSearchable
        wideMenu
        name="name"
        options={ nameOptions }
        placeholder="Journal Name"
        widthPercent={ 25 }
      />
      <Filter.DateField
        name="created_at"
        widthPercent={ 25 }
      />
      <Filter.IconSelectField
        isClearable
        isSearchable
        wideMenu
        name="management_group_id"
        options={ managementGroupOptions }
        placeholder="Management Group"
        widthPercent={ 25 }
      />
      <Filter.TextField
        name="description"
        placeholder="Notes"
        widthPercent={ 25 }
      />
    </Filter>
  );
};

export default ListFilter;
