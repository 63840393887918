import React, { useMemo } from 'react';

import map from 'lodash/map';

import { useGetRevenueReconciliation } from '@src/hooks/queries/revenue_reconciliations';
import { TDate, TUUID } from '@src/types/common';

import QueryStatus from '@src/components/utils/query_status';

import Item from './related_deposits_item';

interface IUnreconcileRelatedDepositListProps {
  revenueReconciliationId: TUUID,
  paymentProcessorName: string,
}

const UnreconcileRelatedDepositList = ({
  revenueReconciliationId,
  paymentProcessorName,
}: IUnreconcileRelatedDepositListProps): JSX.Element => {
  const reconciliationQuery = useGetRevenueReconciliation({ revenueReconciliationId });

  const revenues = useMemo(() => {
    const items = (reconciliationQuery.data?.revenueReconciliationItems || [])
      .filter((item) => item.itemType !== 'TransactionServiceDocument');

    return items.reduce((acc, item) => {
      acc[item.date] = (acc[item.date] || 0.0) + Number(item.amount);
      return acc;
    }, {} as Record<TDate, number>);
  }, [reconciliationQuery.data?.revenueReconciliationItems]);

  return (
    <>
      <QueryStatus query={ reconciliationQuery } />
      {
        map(revenues, (amount, date) => (
          <Item
            key={ date }
            amount={ amount }
            date={ date }
            paymentProcessorName={ paymentProcessorName }
          />
        ))
      }
    </>
  );
};

export default React.memo(UnreconcileRelatedDepositList);
