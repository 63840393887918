import { TID } from '@src/types/common';
import { IQuickbooksPaymentAccounts } from '@src/types/quickbooks_payment_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetQuickbooksPaymentAccountsParams {
  businessId: TID
  paymentAccountId?: TID
}

interface IGetQuickbooksPaymentAccountsResponse {
  accounts: IQuickbooksPaymentAccounts[],
}

const getQuickbooksPaymentAccounts = (
  params: IGetQuickbooksPaymentAccountsParams,
): Promise<IGetQuickbooksPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/quickbooks_payment_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetQuickbooksPaymentAccountsResponse);
};

export {
  IGetQuickbooksPaymentAccountsParams,
  IGetQuickbooksPaymentAccountsResponse,
  getQuickbooksPaymentAccounts,
};
