import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import ReviewedSection from './reviewed/reviewed_view/reviewed_section';

interface IReviewedPageProps {
  businessId: TID,
}

const ReviewedPage = ({
  businessId,
}: IReviewedPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReviewedSection businessId={ businessId } />
      </BusinessProvider>
    </Root>
  );
};

export default ReviewedPage;
