module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="business-header">\n  <div class="header-title-wrapper">\n    <div class="business-avatar-wrapper">\n      ');
    
      if (this.busienssAvatarUrl) {
        __out.push('\n      <img src="');
        __out.push(__sanitize(this.busienssAvatarUrl));
        __out.push('" />\n      ');
      } else {
        __out.push('\n      <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
      }
    
      __out.push('\n    </div>\n    <span class="m-l-13 font-24 font-bold in-black">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n  </div>\n  <div class="inputs-group-container">\n    <div class="inputs-container pull-left">\n      <div class="checkbox checkbox-primary m-t-10 m-r-20 m-l-auto">\n        <input id="checkhide" type="checkbox" class="styled form-control pointer hide-docyt-js" data-type="checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n        <label for="checkhide" class="font-15">Hide Docyt chart of accounts</label>\n      </div>\n      <div class="checkbox checkbox-primary m-t-10 m-r-20 m-l-auto">\n        <input id="checkhide" type="checkbox" class="styled form-control pointer hide-prior-date-js" data-type="checkbox" ');
    
      __out.push(__sanitize(this.hidePriorDateItem ? 'checked' : ''));
    
      __out.push('>\n        <label for="checkhide" class="font-15">Hide items with dates prior to Books Lock Date</label>\n      </div>\n    </div>\n    <div class="inputs-container pull-right">\n      <button class="btn btn-resync resync-js disabled m-t-10">\n        <span class="icon icon-qbo-on font-20 resync-on-js hidden"></span>\n        <span class="icon icon-qbo-off font-20 resync-off-js"></span>\n        <span class="font-semi-bold font-14 m-l-10 sync-text">Re-Sync</span>\n      </button>\n    </div>\n  </div>\n</div>\n<div class="issue-content-wrapper">\n  <div id="filterRegion"></div>\n  <div id="issuesRegion"></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}