import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

export interface groupInvoicesProp {
  [key: string]: IAccountsPayableServiceDocument[];
}

const sortInvoicesByKey = (groupedInvoices: groupInvoicesProp) => {
  const sortedObj = Object.keys(groupedInvoices)
    .sort()
    .reduce((acc: any, key) => {
      acc[key] = groupedInvoices[key];
      return acc;
    }, {});

  return sortedObj;
};

const groupByAccountNumber = (invoices: IAccountsPayableServiceDocument[]): groupInvoicesProp => {
  const result: groupInvoicesProp = {};

  invoices.forEach((invoice) => {
    const key = `${invoice.vendor?.name}_${invoice.vendor?.id}_${invoice.accountNumber}`;
    if (!result[key]) {
      result[key] = [invoice];
    } else {
      result[key].push(invoice);
    }
  });

  return sortInvoicesByKey(result);
};

const splitInvoices = (invoices: Array<number>, limitCountToPay: number) => {
  const chunkedIds = invoices.reduce((resultArray: any, item, i) => {
    const chunkIndex = Math.floor(i / limitCountToPay);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  return chunkedIds;
};

export {
  groupByAccountNumber,
  splitInvoices,
};
