import React, { useCallback, useMemo } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetBusinessQuery } from '@src/hooks/queries/businesses';
import { getBusinesses, IGetBusinessesParams } from '@src/requests/businesses';
import { IBusiness, IBusinessesFilter } from '@src/types/businesses';
import { TID } from '@src/types/common';

import {
  AsyncPaginateIconSelectInput,
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

interface IBusinessInputProps extends
  Omit<IIconSelectInputProps, 'value' | 'onChange'>
{
  value?: TID | null,
  filter?: IBusinessesFilter,
  onChange?: (value?: TID | null) => void,
  forDataExport?: boolean,
}

const businessIcon = (business: IBusiness): React.ReactElement => {
  return (
    (business.avatar?.avatarUrl || business.avatarUrl)
      ? (
        <img
          alt="Business avatar"
          className="small-avatar"
          src={ business.avatar.avatarUrl || business.avatarUrl }
        />
      )
      : (<BusinessDefaultAvatarIcon fontSize={ 25 } />)
  );
};

const businessLabel = (business: IBusiness): string => {
  return business.displayName;
};

const BusinessInput = ({
  value,
  onChange,
  ...props
}: IBusinessInputProps) => {
  let queryParams: IGetBusinessesParams;

  if (props.forDataExport) {
    queryParams = {
      simple:        true,
      filter:        props.filter,
      forDataExport: props.forDataExport,
    };
  } else {
    queryParams = {
      includeEmployedBusiness: true,
      simple:                  true,
      filter:                  props.filter,
    };
  }

  const businessQuery = useGetBusinessQuery(Number(value));

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    if (!onChange) return;

    onChange(item?.value ? Number(item.value) : null);
  }, [onChange]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
    useCallback((query, options, { page }) => {
      return getBusinesses({
        search: query,
        page,
        ...queryParams,
      }).then((data) => {
        const hasMore = data.collection.length > 0;

        const newOptions = data.collection.map((business) => ({
          icon:  businessIcon(business),
          label: businessLabel(business),
          value: String(business.id),
        }));
        return {
          options:    newOptions,
          hasMore,
          additional: {
            page: page + 1,
          },
        };
      });
    }, [queryParams]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!businessQuery.data) return undefined;
    if (String(businessQuery.data.id) !== String(value)) return undefined;

    const business = businessQuery.data;

    return {
      icon:  businessIcon(business),
      label: businessLabel(business),
      value: String(business.id),
    };
  }, [value, businessQuery.data]);

  return (
    <AsyncPaginateIconSelectInput
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      isLoading={ businessQuery.isLoading }
      loadOptions={ handleSource as LoadOptions<any, any, any> }
      placeholder="Select Business"
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default React.memo(BusinessInput);
