import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const InfoFilledIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="info-filled"
      pathsNumber={ 1 }
    />
  );
};

export default InfoFilledIcon;
