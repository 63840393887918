import React, { useCallback } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';
import { currencyFormater } from '@src/utils/currency';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { TOption } from '@src/components/ui_v2/inputs';
import { Label } from '@src/components/ui_v2/labels';

interface IDetailInfoProps {
  isAuthorizedPayer: boolean,
  serviceDocument?: IAccountsPayableServiceDocument,
  document?: IDocument,
  paymentType: TOption | null,
  onSelectType: (paymentType: TOption | null) => void,
}

const options: TOption[] = [
  {
    value: 'docyt_check',
    label: 'Docyt Check',
  },
  {
    value: 'docyt_ach',
    label: 'Docyt ACH',
  },
];

const DetailInfo = ({
  isAuthorizedPayer,
  serviceDocument,
  document,
  paymentType,
  onSelectType,
}: IDetailInfoProps): JSX.Element | null => {
  const handleChangPaymentAddressClick = useCallback(() => {
  }, []);

  let checkMemo = '';
  if (serviceDocument?.invoiceNumber && serviceDocument?.accountNumber) {
    checkMemo = `Inv: ${serviceDocument?.invoiceNumber}, Acc: ${serviceDocument?.accountNumber}`;
  } else if (serviceDocument?.invoiceNumber) {
    checkMemo = `Inv: ${serviceDocument?.invoiceNumber}`;
  } else if (serviceDocument?.accountNumber) {
    checkMemo = `Acc: ${serviceDocument?.accountNumber}`;
  }
  let address = '';
  if (document?.serviceDocumentAddress) {
    address = [
      document.serviceDocumentAddress.name,
      document.serviceDocumentAddress.company,
      document.serviceDocumentAddress.addressLine1,
      document.serviceDocumentAddress.addressLine2,
      `${document.serviceDocumentAddress.addressCity} ${document.serviceDocumentAddress.addressState} ${document.serviceDocumentAddress.addressZip}`,
      document.serviceDocumentAddress.addressCountry,
    ].join('\n');
  }

  return (
    <>
      <Form.DropdownField
        label="Payment Type"
        options={ options }
        value={ paymentType || options[0] }
        onChange={ onSelectType }
      />
      <Form.StaticField
        label="Receiver/Payee"
        value={ serviceDocument?.vendor?.name }
      />
      <Form.StaticField
        label="Docyt ID"
        value={ document?.docytId }
      />
      <Form.StaticField
        label="Amount"
        value={ currencyFormater(serviceDocument?.invoiceAmount || 0.0) }
      />
      <Form.StaticField
        label="Mailing Address"
        value={ address }
      />
      {
        document?.serviceDocumentAddress?.isAuthorized && (
          <Form.StaticField label="">
            <Label variant="success">Authorized</Label>
          </Form.StaticField>
        )
      }
      {
        document?.serviceDocumentAddress && isAuthorizedPayer && (
          <Form.StaticField label="">
            <Button
              variant="link"
              onClick={ handleChangPaymentAddressClick }
            >
              Change Payment Address
            </Button>
          </Form.StaticField>
        )
      }
      <Form.TextField
        label="Memo on check"
        value={ checkMemo }
      />
      <Form.StaticField
        label="Attachments"
        value="None"
      />
    </>
  );
};

export default DetailInfo;
