import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { usePayAccountsPaymentServiceViaAch } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';
import { IDwollaCustomer } from '@src/types/dwolla_customer';
import { formatApiDate } from '@src/utils/date_helpers';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './review_ach_transfer_form';

interface IReviewACHTransferModalProps extends IUseModalProps {
  businessId: TID,
  serviceDocument?: IAccountsPayableServiceDocument,
  businessDwollaCustomer?: IDwollaCustomer,
  vendorDwollaCustomer?: IDwollaCustomer,
}

const ReviewACHTransferModal = ({
  businessId,
  serviceDocument,
  businessDwollaCustomer,
  vendorDwollaCustomer,
  isOpen,
  onCancel,
  onDone,
}: IReviewACHTransferModalProps) => {
  const payViaAch = usePayAccountsPaymentServiceViaAch();

  const { mutate } = payViaAch;
  const handleSubmit = useCallback(() => {
    mutate(
      {
        businessId,
        serviceDocumentIds: [serviceDocument?.id || 0],
        paidAt:             formatApiDate(new Date()),
      },
      {
        onSuccess: onDone,
      },
    );
  }, [businessId, serviceDocument, onDone, mutate]);

  if (businessDwollaCustomer === null || vendorDwollaCustomer === null) return null;

  return (
    <>
      <MutationStatus mutation={ payViaAch } />
      <Modal.Form
        proceedTitle="Confirm"
        show={ isOpen }
        title="Review ACH Transfer"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            businessDwollaCustomer={ businessDwollaCustomer }
            formId={ formId }
            serviceDocument={ serviceDocument }
            vendorDwollaCustomer={ vendorDwollaCustomer }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useReviewACHTransferModal = makeUseModal(ReviewACHTransferModal);

export {
  IReviewACHTransferModalProps,
  useReviewACHTransferModal,
  ReviewACHTransferModal as default,
};
