module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="clients__client document-item-wrapper">\n  <div class="clients__client-cell col-sm-12 col-md-1">\n    <i class=\'client__docs-li-type-icon icon icon-document\'></i>\n  </div>\n  <div class=\'clients__client-cell col-sm-12 col-md-2\'>\n    <span class=\'clients__client-name-text\'>\n      ');
    
      __out.push(__sanitize(this.documentName));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'category-size-wrapper category-size-wrapper-right selectize-wrapper hidden\'>\n    <select class=\'file-category\' placeholder=\'Select category\'></select>\n  </div>\n  <div class=\'clients__client-cell clients__client-cell--timeago col-sm-12 col-md-2\'>\n    <span class=\'clients__client-name-text\'>\n      ');
    
      __out.push(__sanitize($.timeago(this.created_at)));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'clients__client-cell actions col-sm-12 col-md-1\'>\n    <i class=\'actions__main-icon actions__icon icon icon-dots\'></i>\n    <div class=\'actions__icons-list\'>\n      <a class=\'actions__icon\' data-tooltip=\'Message\'>\n        <i class=\'icon icon-pencil change-category-js\'></i>\n      </a>\n      <a class=\'actions__icon\' data-tooltip=\'Message\'>\n        <i class=\'icon icon-trashcan delete-document-js\'></i>\n      </a>\n    </div>\n  </div>\n  <div class=\'clients__client-extras clients__client-cell\'></div>\n  <div class=\'clients__client-right clients__client-cell\'>\n    ');
    
      if (this.detailsUrl) {
        __out.push('\n      <a class="link-detail" href="');
        __out.push(__sanitize(this.detailsUrl));
        __out.push('"></a>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}