import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  PaymentAccountFilterField,
} from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

const UnmatchedTransfersFilter = () => {
  const business = useBusinessContext();

  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('transaction_date') && (
      <Filter.DateField
        name="transaction_date"
      />
      )) }
      { (visibleColumnNames.includes('from_account') && (
      <PaymentAccountFilterField
        businessId={ business.id }
        placeholder="From Account"
        reconciliationPaymentAccountName="from_account_id"
      />
      )) }
      { (visibleColumnNames.includes('to_account') && (
      <PaymentAccountFilterField
        businessId={ business.id }
        placeholder="To Account"
        reconciliationPaymentAccountName="to_account_id"
      />
      )) }
      { (visibleColumnNames.includes('amount') && (
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(UnmatchedTransfersFilter);
