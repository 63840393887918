import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRemoveFromBusiness } from '@src/hooks/queries/business_vendors';
import { TID } from '@src/types/common';

import Dropdown from '@src/components/ui/dropdown';
import CrossIcon from '@src/components/utils/icomoon/cross_icon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IRemoveFromMyVendorsActionProps {
  vendorId: TID,
  onFinished: () => void,
}

const RemoveFromMyVendorsAction = ({
  vendorId,
  onFinished,
}: IRemoveFromMyVendorsActionProps): JSX.Element => {
  const business = useBusinessContext();
  const removeFromBusiness = useRemoveFromBusiness();

  const { mutate } = removeFromBusiness;
  const handleConfirm = useCallback(() => {
    mutate(
      {
        vendorId,
        businessId: business.id,
      },
      { onSuccess: onFinished },
    );
  }, [business.id, vendorId, mutate, onFinished]);

  return (
    <>
      <MutationStatus mutation={ removeFromBusiness } />
      <Dropdown.Item onSelect={ handleConfirm }>
        <CrossIcon mr={ 22 } />
        Remove From &quot;My Vendors List&quot;
      </Dropdown.Item>
    </>
  );
};

export default RemoveFromMyVendorsAction;
