import React, { FC, useState } from 'react';

import { InvalidateQueryFilters, useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useDeleteAdjustmentEntry } from '@src/requests/adjustment_entries';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { TrashcanIcon } from '@src/components/utils/icomoon';

const DeleteIcon: FC<{ id: TID }> = ({ id }) => {
  const { mutateAsync } = useDeleteAdjustmentEntry();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const business = useBusinessContext();
  const baReconciliation = useBankAccountReconciliationContext();

  const handleClick = async () => {
    const params = { id, businessId: business.id };
    try {
      setIsSubmitting(true);
      await mutateAsync(params);
      const section = [QueryKey.bankAccountReconciliation, baReconciliation.id];
      await queryClient.invalidateQueries(section as InvalidateQueryFilters);
      toastr.success('Deleted Journal Entry successfully.', 'Success');
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors) {
        const errorMessages = Object.values(errors);
        const errorMessageString = errorMessages.join(' ');
        toastr.error(errorMessageString, 'Error');
      } else {
        toastr.error('Failed to Deleted Journal Entry.', 'Error');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const confirm = useConfirmDeleteModal({
    onDone: handleClick,
  });

  if (isSubmitting) {
    return <SpinnerIcon spin />;
  }

  return (
    <>
      <confirm.Component
        confirmTitle="Delete"
        text="Are you sure you want to delete this journal entry? Once deleted, this will be permanently removed from the ledger."
        title="Delete Journal Entry"
        { ...confirm.props }
      />
      <TrashcanIcon pointer onClick={ confirm.open } />
    </>

  );
};

export default DeleteIcon;
