import { useQuery } from 'react-query';

import { getBusinessServicesInfo } from '@src/requests/business_services_info';
import { IBusinessServicesInfo } from '@src/types/business_services_info';

const useGetBusinessServicesInfo = (businessId: number) => {
  return useQuery<IBusinessServicesInfo, Error>(
    ['businessId', businessId],
    () => getBusinessServicesInfo(businessId),
  );
};

export {
  useGetBusinessServicesInfo,
};
