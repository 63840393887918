import React from 'react';

import { IAccountMangers } from '@src/types/account_managers';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';

import styles from '../styles.module.scss';

interface IAccountManagersProps {
  accountMangers: IAccountMangers[];
}

const AccountManagers = ({
  accountMangers,
}: IAccountManagersProps) => {
  let content;
  if (accountMangers.length === 0) {
    content = '';
  } else if (accountMangers.length > 1) {
    content = (
      <Tooltip
        as="span"
        className="pointer"
        title={ accountMangers.map((user) => user.name).join(', ') }
      >
        { `${accountMangers[0].name}, +${accountMangers.length - 1} more` }
      </Tooltip>
    );
  } else {
    content = (
      <span>{ `${accountMangers[0].name}` }</span>
    );
  }

  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 8 }
    >
      { content }
    </Table.Cell>
  );
};

export default AccountManagers;
