import React, { useCallback, useState } from 'react';

import maxBy from 'lodash/maxBy';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { usePostDocumentRequests } from '@src/hooks/queries/document_requests';
import { IDocumentRequest } from '@src/types/document_requests';

import DocumentRequestItem from '@src/components/client_central/document_request_item';
import Modal from '@src/components/ui/modal';
import { PlusIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface ICreateDocumentRequestProps extends IUseModalProps {
  businessId: number
}

const CreateDocumentRequestModal = ({
  businessId,
  isOpen,
  onCancel,
  onDone,
}: ICreateDocumentRequestProps): JSX.Element => {
  const createDocumentRequests = usePostDocumentRequests();
  const { mutate } = createDocumentRequests;

  const [documentRequests, setDocumentRequests] = useState<IDocumentRequest[]>([
    {
      id:          1,
      name:        '',
      description: '',
    },
    {
      id:          2,
      name:        '',
      description: '',
    },
  ]);

  const handleRequestsInfoChange = useCallback((
    id: number,
    name?: string,
    description?: string,
  ) => {
    setDocumentRequests((originDocumentRequests) => {
      const newDocumentRequests = originDocumentRequests.map((documentRequest) => {
        if (documentRequest.id !== id) return documentRequest;

        return {
          ...documentRequest,
          name:        name !== undefined ? name : documentRequest.name,
          description: description !== undefined ? description : documentRequest.description,
        };
      });

      return newDocumentRequests;
    });
  }, []);

  const handleProceed = useCallback(() => {
    mutate(
      {
        businessId,
        documentRequests,
      },
      {
        onSuccess: () => {
          onDone();
        },
      },
    );
  }, [mutate, businessId, documentRequests, onDone]);

  const onNameChange = useCallback((value: string, id: number) => {
    handleRequestsInfoChange(id, value, undefined);
  }, [handleRequestsInfoChange]);

  const onDescriptionChange = useCallback((value: string, id: number) => {
    handleRequestsInfoChange(id, undefined, value);
  }, [handleRequestsInfoChange]);

  const handleAddRequests = useCallback(() => {
    setDocumentRequests((originDocumentRequests) => {
      const maxId = originDocumentRequests.length > 0 ? maxBy(originDocumentRequests, 'id')!.id : 0;
      const newDocumentRequest: IDocumentRequest = {
        id:          maxId + 1,
        name:        '',
        description: '',
      };
      return [...originDocumentRequests, newDocumentRequest];
    });
  }, []);

  const handleRemoveDocumentRequest = useCallback((id: number) => {
    setDocumentRequests(() => {
      return documentRequests.filter((documentRequest) => documentRequest.id !== id);
    });
  }, [documentRequests]);

  let filledAllNames = documentRequests.length > 0;
  documentRequests.forEach((documentRequest) => {
    if (documentRequest.name === '') filledAllNames = false;
  });

  return (
    <>
      <MutationStatus mutation={ createDocumentRequests } successMessage="Created document requests successfully." />
      <Modal.Standard
        className="modal-position-middle"
        dialogClassName="modal-lg"
        isProceedDisabled={ !filledAllNames }
        proceedTitle="Create"
        show={ isOpen }
        title="Create Document Requests"
        onCancel={ onCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div
            className="split-transactions-modal"
            style={ { margin: '0 -20px' } }
          >
            <div className="split-transaction-list m-t-10 p-b-30 p-l-5 p-r-5 border-bottom-gray">
              <div className="display-flex split-transaction-list-title m-b-10">
                <span className="title width-20-percent font-bold">Name</span>
                <span className="title width-20-percent font-bold">Type</span>
                <span className="title font-bold">Description</span>
              </div>
              {
                documentRequests.map((documentRequest) => {
                  return (
                    <DocumentRequestItem
                      key={ documentRequest.id }
                      documentRequest={ documentRequest }
                      removeDocumentRequest={ handleRemoveDocumentRequest }
                      onDescriptionChange={ onDescriptionChange }
                      onNameChange={ onNameChange }
                    />
                  );
                })
              }
              <button
                className="pull-right add-split-btn"
                type="submit"
                onClick={ handleAddRequests }
              >
                <PlusIcon pr={ 4 } variant="o" />
                {' '}
                Add Request
              </button>
            </div>
          </div>
        )}
      </Modal.Standard>
    </>
  );
};

const useCreateDocumentRequestModal = makeUseModal(CreateDocumentRequestModal);

export {
  CreateDocumentRequestModal,
  useCreateDocumentRequestModal as default,
};
