import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { object, string, number } from 'yup';

import ManagementGroupField from '@src/components/common/form_fields/management_group_field';
import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import Form from '@src/components/ui_v2/form';

import UploadCSV from './upload_csv';

import styles from './styles.module.scss';

const schema = object({
  name:              string().trim().required('Journal entry name is required'),
  notes:             string().trim().required('Journal entry notes are required'),
  managementGroupId: number().required('Management group is required'),
});

const ImportJournal: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const { register, handleSubmit, control, watch, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    setShowUploadModal(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const info = watch() as {
    name: string;
    description: string;
    managementGroupId: number;
  };

  return (
    <>
      <Button className={ styles['upload-csv-csv'] } onClick={ () => setShow(true) }>
        Upload CSV
      </Button>
      <Modal show={ show }>
        <Form onSubmit={ handleSubmit(onSubmit) }>
          <Modal.Body>
            <div className={ styles['upload-modal-title'] }>Import Journal Entry</div>
            <div className={ styles['import-content'] }>
              <Form.TextField
                label="Journal Entry Name"
                placeholder="Add journal entry name"
                { ...register('name') }
                error={ errors.name?.message }
              />
              <Form.TextField
                label="Journal Entry Notes"
                placeholder="Add journal entry notes"
                { ...register('notes') }
                error={ errors.notes?.message }
              />
              <Controller
                control={ control }
                name="managementGroupId"
                // Avoid Warning: Function components cannot be given refs
                render={ ({ field: { ref: _ref, ...rest } }) => (
                  <div className={ styles['management-group-container'] }>
                    <ManagementGroupField
                      { ...rest }
                      error={ errors.managementGroupId?.message }
                      label="Management Group"
                      placeholder="Select Management Group"
                    />
                  </div>
                ) }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={ styles['modal-footer'] }>
              <Button className={ styles['button-secondary'] } onClick={ closeModal }>Cancel</Button>
              <Button className={ styles['upload-csv-csv'] } type="submit">Import</Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <UploadCSV
        closeParentModal={ closeModal }
        info={ info }
        setShow={ setShowUploadModal }
        show={ showUploadModal }
      />
    </>
  );
};

export default ImportJournal;
