import React, { useCallback } from 'react';

import { IBusiness } from '@src/types/businesses';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';
import { currencyFormater3 } from '@src/utils/currency';

import Table from '@src/components/ui/table';

interface IMultiEntityItemValueDetailItemProps {
  business: IBusiness,
  lineItemDetail: IReportServiceQBOLineItemDetails,
}

const MultiEntityItemValueDetailItem = ({
  business,
  lineItemDetail,
}: IMultiEntityItemValueDetailItemProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (lineItemDetail.link) {
      window.open(lineItemDetail.link, '_blank');
    }
  }, [lineItemDetail.link]);

  return (
    <Table.Row
      className="pointer"
      onClick={ handleClick }
    >
      <Table.Cell textAlign="center">
        <span>{ business.displayName }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.transactionDate }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.transactionType }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.transactionNumber }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.vendor }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.memo }</span>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <span>{ lineItemDetail.split }</span>
      </Table.Cell>
      <Table.Cell
        textAlign="center"
      >
        <span>
          {currencyFormater3(lineItemDetail.amount)}
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

export default MultiEntityItemValueDetailItem;
