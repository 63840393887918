import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetBankAccountReconciliationDocuments } from '@src/hooks/queries/bank_account_reconciliation_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetBankAccountReconciliationDocumentsResponse } from '@src/requests/bank_account_reconciliation_documents';
import { IBankAccountReconciliationDocument, TIBankAccountReconciliationDocumentSortColumn } from '@src/types/bank_account_reconciliation_document';
import { TID, TSection } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseCollectionParams {
  businessId: TID,
  section: TSection,
  params: TFilterData,
}

interface ICollection {
  query: UseInfiniteQueryResult<IGetBankAccountReconciliationDocumentsResponse, Error>,
  records: IBankAccountReconciliationDocument[],
  section: TSection,
  sorting: ISorting<TIBankAccountReconciliationDocumentSortColumn>,
}

const defaultSorting: ISortingParams<TIBankAccountReconciliationDocumentSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const useCollection = ({
  businessId,
  section,
  params,
}: IUseCollectionParams): ICollection => {
  const filterData = useFilterData(section, params);
  const filterParams = useMemo(() => {
    return { filter: filterData };
  }, [filterData]);

  const sorting = useSorting<TIBankAccountReconciliationDocumentSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetBankAccountReconciliationDocuments({
    businessId,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  ICollection,
  useCollection,
};
