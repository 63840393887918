import React, { useCallback, useMemo } from 'react';

import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';

import styles from '../../styles.module.scss';

interface IResolveDuplicatesResultProps {
  status: IBulkActionStatus<any, any>,
}

const ResolveDuplicatesResult = ({
  status,
}: IResolveDuplicatesResultProps) => {
  const [duplicatedTxnCount, notDuplicatedTxnCount] = useMemo(() => {
    return [
      status.responses.filter((res) => res[0].resolveDuplication.type === 'Duplicate').length,
      status.responses.filter((res) => res[0].resolveDuplication.type === 'Not Duplicate').length,
    ];
  }, [status.responses]);
  const failedCount = status.failedCount;

  const handleTransactionClick = useCallback((docytId: string) => {
    window.open(`/document/${docytId}`, '_blank');
  }, []);
  return (
    <div className={ styles.result }>
      <p className="p-b-0">
        <span className={ styles['success-count'] }>{ duplicatedTxnCount }</span>
        { ' ' }
        { duplicatedTxnCount === 1 ? 'duplicate transaction was' : 'duplicate transactions were' }
        { ' ' }
        successfully deleted and
        { ' ' }
        <span className={ styles['success-count'] }>{ notDuplicatedTxnCount }</span>
        { ' ' }
        { notDuplicatedTxnCount === 1 ? 'transaction was' : 'transactions were'}
        { ' ' }
        marked as not duplicate.
      </p>
      {
        status.failedCount > 0 && (
          <p className="p-b-0">
            <span className={ styles['failed-count'] }>{ failedCount }</span>
            {' '}
            { status.failedCount === 1 ? 'object' : 'objects' }
            {' '}
            have failed with the following errors:
            <ol>
              { status.errors.map((error, i) => (
                // There is no anything that may be used as key
                // eslint-disable-next-line react/no-array-index-key
                <li key={ i }>
                  <a
                    className="pointer"
                    role="button"
                    tabIndex={ -1 }
                    onClick={ () => handleTransactionClick(error[0].resolveDuplication.docytId) }
                  >
                    { error[0].resolveDuplication.docytId }
                    :
                    { ' ' }
                  </a>
                  <span>{ error[1] }</span>
                </li>
              )) }
            </ol>
          </p>
        )
      }
    </div>
  );
};

export default React.memo(ResolveDuplicatesResult);
