import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { capitalize } from '@src/utils/transform_keys';

import AchStatus from '@src/components/accounts_payable/ach_transactions/status_item/status';
import {
  statusVariant,
  statusValue,
} from '@src/components/accounts_payable/ach_transactions/utils';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

const TOOLTIP_HIDE_DELAY = 15000;

type TAchStatusItemProps = {
  document: IAchTransaction,
}

const AchStatusItem = ({
  document,
}: TAchStatusItemProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean|undefined>(undefined);

  const handleCancelInvoice = useCallback(() => {
    setShowTooltip(false);
    if (document.serviceDocumentIds.length > 0) {
      const documentId = document.serviceDocumentIds[0];
      window.Docyt.vent.trigger('ap_service:document:cancel:pay', documentId);
    }
  }, [setShowTooltip, document.serviceDocumentIds]);

  const variant = statusVariant(document);

  const classes = classNames(
    styles.label,
    'pointer',
    styles[`label-${variant}`],
  );

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, [setShowTooltip]);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  const content = (
    ['pending', 'failed'].includes(document.status)
    && (
    <AchStatus
      document={ document }
      onCancelInvoice={ handleCancelInvoice }
    />
    )
  );

  return (
    <div
      className="p-t-12 height-100-percent width-100-percent"
      onMouseLeave={ handleMouseLeave }
    >
      <Tooltip.Hover
        isControl
        content={ content }
        isVisibleArrow={ false }
        popoverParams={ { hideDelay: TOOLTIP_HIDE_DELAY } }
        showTooltip={ showTooltip }
        tooltipClassName={ styles['status-tooltip'] }
      >
        <span
          className={ classes }
          onMouseEnter={ handleMouseEnter }
        >
          { capitalize(statusValue(document.status)) }
        </span>
      </Tooltip.Hover>
    </div>
  );
};

export default AchStatusItem;
