module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var creditCard, i, len, ref;
    
      __out.push('<div class="billing-method-header">\n  <h1 class="">Billing Method</h1>\n</div>\n<div class="billing-method-body display-flex align-items-center">\n');
    
      if (this.accountingFirm && this.isAccountingFirmUser) {
        __out.push('\n  <div>\n    <div class="accounting-firm-credit-cards-section">\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioBillToAccountingFirm" name="bill_from" class="pointer radio-bill-from radio-bill-to-accounting-firm" value="accounting_firm" ');
        if (this.billFrom === 'accounting_firm') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' ');
        if (this.subscription && this.creditCard && this.creditCard.business_id === this.id) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <label for="radioBillToAccountingFirm">Accounting Firm: ');
        __out.push(__sanitize(this.accountingFirm.get('display_name')));
        __out.push('</label>\n      </div>\n      ');
        if (this.subscription && this.creditCard && this.creditCard.business_id === this.id) {
          __out.push('\n      <p class="switch-card-description">The accounting firm account is being billed. If you want to switch to the client, please contact customer support.</p>\n      ');
        } else if (this.subscription && this.bankAccount && this.bankAccount.business_id === this.id) {
          __out.push('\n      <p class="switch-card-description">The accounting firm account is being billed. If you want to switch to the client, please contact customer support.</p>\n      ');
        } else {
          __out.push('\n      <div class="credit-cards-wrapper">\n        <select class="selectpicker credit-card-list" name="accounting_firm_credit_card" title="Select Payment Account" ');
          if (this.billFrom === 'business') {
            __out.push(__sanitize('disabled'));
          }
          __out.push('>\n          ');
          ref = this.accountingFirmCards;
          for (i = 0, len = ref.length; i < len; i++) {
            creditCard = ref[i];
            __out.push('\n          <option class="credit-card-item" value="');
            __out.push(__sanitize(creditCard.id));
            __out.push('" ');
            if (this.creditCard && this.creditCard.id === creditCard.id) {
              __out.push(__sanitize('selected'));
            }
            __out.push(' data-content="<div class=\'credit-card-icon-wrapper\'><span class=\'icon icon-credit-card-account\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span></span></div><span class=\'text\'>**** **** **** ');
            __out.push(__sanitize(creditCard.last4));
            __out.push('</span>">**** **** **** ');
            __out.push(__sanitize(creditCard.last4));
            __out.push('</option>\n          ');
          }
          __out.push('\n          <option class="credit-card-item add-accounting-firm-card" value="-1" data-content="<span class=\'icon icon-plus-circle\'></span><p class=\'credit-card-no\'>Add New Credit Card</p>">Add New Credit Card</option>\n        </select>\n      </div>\n      ');
        }
        __out.push('\n    </div>\n    <div class="business-credit-cards-section">\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioBillToBusiness" name="bill_from" class="pointer radio-bill-from radio-bill-to-business" value="business" ');
        if (this.billFrom === 'business') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' ');
        if (this.subscription && this.creditCard && this.creditCard.business_id !== this.id) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <label for="radioBillToBusiness">Client: ');
        __out.push(__sanitize(this.display_name));
        __out.push('</label>\n      </div>\n      ');
        if (this.subscription && this.creditCard && this.creditCard.business_id !== this.id) {
          __out.push('\n      <p class="switch-card-description">The client account is being billed. If you want to switch to the accounting firm billing, please contact customer support.</p>\n      ');
        } else if (this.subscription && this.bankAccount && this.bankAccount.business_id !== this.id) {
          __out.push('\n      <p class="switch-card-description">The client account is being billed. If you want to switch to the accounting firm billing, please contact customer support.</p>\n      ');
        } else {
          __out.push('\n      <div class="credit-cards-wrapper">\n        ');
          if (this.businessCards.length === 0) {
            __out.push('\n        <a class="edit-business-card-link">Add New Card</a>\n        ');
          } else if (this.creditCard && this.billFrom === 'business') {
            __out.push('\n        <span class="pull-left m-t-15">**** **** **** ');
            __out.push(__sanitize(this.creditCard.last4));
            __out.push('</span>\n        <a class="edit-business-card-link pull-right">Edit</a>\n        ');
          } else {
            __out.push('\n        <a class="edit-business-card-link">Select Payment Account</a>\n        ');
          }
          __out.push('\n      </div>\n      ');
        }
        __out.push('\n    </div>\n  </div>\n');
      } else {
        __out.push('\n  ');
        if (this.subscription) {
          __out.push('\n    ');
          if (this.creditCard) {
            __out.push('\n      <span class="icon icon-credit-card-account font-22 m-r-8">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n      </span>\n      <span class="credit-card-no">**** **** **** ');
            __out.push(__sanitize(this.creditCard.last4));
            __out.push('</span>\n      ');
            if (this.creditCard.business_id === this.id) {
              __out.push('\n        <a class="edit-method-js">Change</a>\n      ');
            } else if (this.billFrom === "business") {
              __out.push('\n        <p class="switch-card-description" style="display: inline-block; float: none; margin: 0; vertical-align: super;">The client account is being billed. If you want to switch to the accounting firm billing, please contact customer support.</p>\n      ');
            } else {
              __out.push('\n        <p class="switch-card-description" style="display: inline-block; float: none; margin: 0; vertical-align: super;">The accounting firm account is being billed. If you want to switch to the client, please contact customer support.</p>\n      ');
            }
            __out.push('\n    ');
          } else if (this.bankAccount) {
            __out.push('\n      <span class="icon-financial-institution font-22 m-r-8"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n      <span class="credit-card-no">***** ');
            __out.push(__sanitize(this.bankAccount.last4));
            __out.push('</span>\n      ');
            if (this.bankAccount.business_id === this.id) {
              __out.push('\n      <a class="edit-method-js">Change</a>\n      ');
            } else if (this.billFrom === "business") {
              __out.push('\n        <p class="switch-card-description" style="display: inline-block; float: none; margin: 0; vertical-align: super;">The client account is being billed. If you want to switch to the accounting firm billing, please contact customer support.</p>\n      ');
            } else {
              __out.push('\n        <p class="switch-card-description" style="display: inline-block; float: none; margin: 0; vertical-align: super;">The accounting firm account is being billed. If you want to switch to the client, please contact customer support.</p>\n      ');
            }
            __out.push('\n    ');
          } else {
            __out.push('\n      <span class="credit-card-no">No Payment Account</span>\n      <a class="edit-method-js">Add</a>\n    ');
          }
          __out.push('\n  ');
        } else {
          __out.push('\n    ');
          if (this.creditCard) {
            __out.push('\n    <span class="icon icon-credit-card-account font-22 m-r-8">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    <span class="credit-card-no">**** **** **** ');
            __out.push(__sanitize(this.creditCard.last4));
            __out.push('</span>\n    <a class="edit-method-js">Change</a>\n    ');
          } else if (this.bankAccount) {
            __out.push('\n    <span class="icon-financial-institution font-22 m-r-8"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n    <span class="credit-card-no">***** ');
            __out.push(__sanitize(this.bankAccount.last4));
            __out.push('</span>\n    <a class="edit-method-js">Change</a>\n    ');
          } else if (this.businessCards.length === 0) {
            __out.push('\n    <a class="edit-method-js">\n      <i class="icon icon-o-plus font-20 m-r-12"></i>\n      <span style="vertical-align: top;">Select Payment Account</span>\n    </a>\n    ');
          } else {
            __out.push('\n    <a class="edit-method-js">Select Payment Account</a>\n    ');
          }
          __out.push('\n  ');
        }
        __out.push('\n');
      }
    
      __out.push('\n  <div class="billing-receipts-download-region"></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}