import React, { useState, useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useRemoveBankAccount } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

interface IDeleteProps extends IUseModalProps {
  confirmTitle?: JSX.Element | string,
  icon?: JSX.Element,
  text: JSX.Element | string,
  title: JSX.Element | string,
  vendor: IBusinessVendor;
  handleDeleteBank: () => void;
}

const DeleteConfirm = ({
  confirmTitle = 'Delete',
  vendor,
  icon,
  isOpen,
  text,
  title,
  onDone,
  onCancel,
  handleDeleteBank,
}: IDeleteProps): JSX.Element => {
  const removeAchBankAccount = useRemoveBankAccount();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const { mutate: removeAch } = removeAchBankAccount;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
    onDone();
  }, [onDone]);

  const handleSuccess = useCallback(() => {
    setSuccessMessage('ACH bank account info deleted');
    handleDeleteBank();
  }, [handleDeleteBank]);

  const handleRemoveBank = useCallback(() => {
    setErrorMessage(undefined);
    removeAch(
      {
        vendorId:   vendor.id,
        businessId: vendor.businessId,
      },
      {
        onError:   handleError,
        onSuccess: handleSuccess,
      },
    );
  }, [removeAch, vendor.id, vendor.businessId, handleError, handleSuccess]);

  return (
    <>
      {(removeAchBankAccount.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Cannot Delete Bank Info" />}
      {successMessage && <SuccessNotification message={ successMessage } />}

      <Modal className="modal-v2" show={ isOpen } title={ title }>
        <Modal.Body>
          {icon && (
          <div className="text-center delete-icon-wrapper">
            <div className="inline-block relative">
              {icon}
            </div>
          </div>
          )}
          <p className="p-b-0 text-center font-18">{text}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            className="btn-destructive"
            variant="destructive"
            onClick={ handleRemoveBank }
          >
            { confirmTitle }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useDeleteModal = makeUseModal(DeleteConfirm);
export {
  IDeleteProps,
  useDeleteModal,
  DeleteConfirm as default,
};
