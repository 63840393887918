/* eslint-disable import/order */
import React, { createContext, useContext, useEffect } from 'react';

import { Outlet, useParams } from 'react-router-dom';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReconcilePaymentProcessorPath } from '@src/routes';
import { IPaymentProcessor } from '@src/types/payment_processors';

import { useRevenueSystemContext } from './revenue_system_provider';
import { paymentProcessorsForRevenueReportTypeId } from './utils';

const PaymentProcessorContext = createContext<IPaymentProcessor | null>(null);

const usePaymentProcessorContext = (): IPaymentProcessor => {
  const value = useContext(PaymentProcessorContext);

  if (!value) {
    throw new Error('"useRevenuePaymentProcessorContext" must be used within <PaymentProcessorProvider />');
  }

  return value;
};

const PaymentProcessorProvider = (): JSX.Element | null => {
  const { paymentProcessorId } = useParams();

  const service = useRevenueServiceContext();
  const rSystem = useRevenueSystemContext();

  const allPaymentProcessors = paymentProcessorsForRevenueReportTypeId({
    revenueReportTypeId: rSystem.revenueReportTypeId,
    revenueService:      service,
  });

  const paymentProcessor = allPaymentProcessors.find((p) => String(p.id) === paymentProcessorId);

  useEffect(() => {
    if (paymentProcessor) return;
    if (!allPaymentProcessors[0]) return;

    // Wrong paymentProcessorId passed, redirect to the first payment processor
    Backbone.history.navigate(
      revenueServiceReconcilePaymentProcessorPath(
        service.businessId,
        rSystem.id,
        allPaymentProcessors[0].id,
        {},
      ),
      { trigger: true },
    );
  }, [paymentProcessor, allPaymentProcessors, service.businessId, rSystem.id]);

  if (!paymentProcessor) {
    return null;
  }

  return (
    <PaymentProcessorContext.Provider value={ paymentProcessor }>
      <Outlet />
    </PaymentProcessorContext.Provider>
  );
};

export {
  usePaymentProcessorContext,
  PaymentProcessorProvider as default,
};
