import React from 'react';

import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import { ChartOfAccountSelector } from '@src/components/common/inputs';
import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import { IAsyncSelectPaginateInputProps } from '@src/components/ui/form';
import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';

interface IChartOfAccountFieldProps extends
  Omit<IAsyncSelectPaginateInputProps<IChartOfAccount, any, boolean>, 'name' | 'value' | 'onChange' | 'className' | 'loadOptions'>,
  IFilterFieldUIProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
  accountType?: string,
  businessId: TID,
  handleSelected: (items: any) => void,
}

const ChartOfAccountField = ({
  error,
  hint,
  label,
  mark,
  accountType,
  businessId,
  handleSelected,
}: IChartOfAccountFieldProps) => {
  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <ChartOfAccountSelector
        accountType={ accountType }
        businessId={ businessId }
        handleSelected={ handleSelected }
      />
    </Wrapper>
  );
};

export default ChartOfAccountField;
