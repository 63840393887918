import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon, BankTransactionsIcon } from '@src/components/utils/icomoon';

import { useBankAccountModal } from './modal';

import styles from '../styles.module.scss';

interface IAchBankAccountInfoProps {
  businessId: number
  vendor: IBusinessVendor
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>;
}

const AchBankAccountInfo = ({ vendor, businessId, setVendorData } : IAchBankAccountInfoProps) => {
  const confirmModal = useBankAccountModal();

  return (
    <>
      {vendor.dwollaFundingSource?.id ? (
        <div className={ styles.content }>
          <div className={ styles.item }>
            <div className={ styles['label-item'] }>
              <p>Bank Name</p>
            </div>
            <div className={ styles['value-item'] }>
              <p>{vendor.dwollaFundingSource?.name ? vendor.dwollaFundingSource.name : '-'}</p>
            </div>
          </div>
          <div className={ styles.item }>
            <div className={ styles['label-item'] }>
              <p>Account Number</p>
            </div>
            <div className={ styles['value-item'] }>
              <p>
                {vendor.dwollaFundingSource?.last4 ? `**** **** **** ${vendor.dwollaFundingSource.last4}` : '-'}
              </p>
            </div>
          </div>
          <div className={ styles.item }>
            <div className={ styles['label-item'] }>
              <p>Routing Number</p>
            </div>
            <div className={ styles['value-item'] }>
              <p>
                {vendor.dwollaFundingSource?.routingNumber ? vendor.dwollaFundingSource.routingNumber : '-'}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={ styles['no-data-container'] }>
          <BankTransactionsIcon className={ styles['bank-empty-icon'] } fontSize={ 90 } />
          <h4 className={ styles['no-info-txt'] }>
            No information available
          </h4>
          <Button
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="outline"
            onClick={ confirmModal.open }
          >
            Add Bank Account Info
          </Button>
        </div>
      )}
      <confirmModal.Component
        formId="ach_bank_form"
        setVendorData={ setVendorData }
        { ...confirmModal.props }
        businessId={ businessId }
        vendor={ vendor }
      />
    </>

  );
};

export default React.memo(AchBankAccountInfo);
