import { SelfOnboardintSteps } from "@src/constants/self_onboarding_steps";
import { ISelfOnboardingInformation } from "@src/types/self_onboarding_invitation";

const necessarySteps = [
  SelfOnboardintSteps.STEP_REQUEST_DOCUMENT,
  SelfOnboardintSteps.STEP_BILLING_INFO,
  SelfOnboardintSteps.STEP_CONNECT_QBO,
  SelfOnboardintSteps.STEP_CONNECT_FIN
];

const doLater = (onboardingInformation: ISelfOnboardingInformation) => {
  const uncompletedStep = necessarySteps.find((step) => !onboardingInformation.completedSteps.includes(step));
  if (uncompletedStep === undefined) {
    Backbone.history.navigate('/dashboard', { trigger: true });
  } else {
    Backbone.history.navigate(`/self_onboarding?step=${uncompletedStep}`, { trigger: true });
  }
};

export {
  doLater,
};