import React from 'react';

import classNames from 'classnames';
import { SubmitHandler, useForm, useController } from 'react-hook-form';

import { TAmount } from '@src/types/common';
import { currencyFormater2 } from '@src/utils/currency';

import Form from '@src/components/ui/form';

interface IWithdrawAmountFormValues {
  amount: TAmount,
}

interface IWithdrawAmountFormProps {
  formId?: string,
  dwollaBalance: TAmount,
  bankAccountName: string,
  onSubmit: SubmitHandler<IWithdrawAmountFormValues>,
}

const WithdrawAmountForm = ({
  formId,
  dwollaBalance,
  bankAccountName,
  onSubmit,
}: IWithdrawAmountFormProps): JSX.Element => {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IWithdrawAmountFormValues>();

  const inputClasses = classNames(
    'form-control',
    {
      'border-danger': errors.amount,
    },
  );

  const amountController = useController({
    name:  'amount',
    control,
    rules: { required: true, max: dwollaBalance },
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div>
        <p className="font-16">
          Entered amount will be withdrawn to your ACH bank account
        </p>
        <p className="font-16 font-semi-bold">
          {`${bankAccountName}.`}
        </p>
      </div>
      <div className="form-field m-t-20">
        <div className="form-field-item form-field-name">Docyt Wallet Balance</div>
        <p className="p-b-0 font-16 in-green-1000 font-semi-bold">{currencyFormater2(dwollaBalance)}</p>
      </div>
      <Form.AmountField
        className={ inputClasses }
        label="Withdraw Amount"
        placeholder="Enter Amount"
        { ...amountController.field }
      />
    </Form>
  );
};

export {
  IWithdrawAmountFormProps,
  IWithdrawAmountFormValues,
  WithdrawAmountForm as default,
};
