import React from 'react';

import { uiStyleProps, IUIStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface ITableFilterContainerProps extends
  React.ComponentPropsWithoutRef<'div'>,
  IUIStyleProps
{
  children: React.ReactNode,
}

const TableFilterContainer = ({
  children,
  ...props
}: ITableFilterContainerProps): JSX.Element => {
  const [classes, divProps] = uiStyleProps(styles['table-filter'], props);

  return (
    <div className={ classes } { ...divProps }>
      { children }
    </div>
  );
};

export default TableFilterContainer;
