import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

import BalanceSheetInformationBody from './balance_sheet_statements/balance_sheet_informations_body';

interface BalanceSheetStatementsPageProps {
  businessId: TID,
}

const BalanceSheetStatementsPage = ({
  businessId,
}: BalanceSheetStatementsPageProps) => {
  const BalanceSheetStatementsDocRequestEnabled =
    window.configData.launch_darkly_enabled_global_features
      .includes(window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG);

  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          {
            (BalanceSheetStatementsDocRequestEnabled) && (
              <BalanceSheetInformationBody businessId={ businessId } />
            )
          }
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BalanceSheetStatementsPage;
