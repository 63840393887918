import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import texts from '@src/constants/month_end_reconciliation_items';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';
import { IBankStatement } from '@src/types/bank_statements';

import Form from '@src/components/ui_v2/form';

import { amountFormatter } from '../utils';
import { noteValidate, IAcknowledgeFormValues } from './schema';

import styles from './styles.module.scss';

interface IAcknowledgeFormProps {
  baReconciliationItem: IBankAccountReconciliationItem,
  noteRequired: boolean,
  formId: string,
  onSubmit: (data: IAcknowledgeFormValues) => void;
  total?: string
}

const bankStatementStateText = (bankStatement: IBankStatement) => {
  switch (bankStatement?.state) {
    case 'verified':
      return 'Verified';
    case 'not_available':
      return 'No Statement Available';
    default:
      return 'Unverified';
  }
};

const AcknowledgeForm = ({
  baReconciliationItem,
  noteRequired,
  formId,
  onSubmit,
  total,
}: IAcknowledgeFormProps) => {
  const bankStatement = useBankStatementContext();
  const reconciliationPaymentAccount = useReconciliationPaymentAccountContext();

  const { register, formState: { errors }, handleSubmit } = useForm<IAcknowledgeFormValues>({
    reValidateMode: 'onChange',
    resolver:       yupResolver(noteValidate(noteRequired)),
  });

  const { transactionType, title } = texts[baReconciliationItem.itemType];
  let label = null;

  let numbers = null;
  if (['difference', 'additional_uncleared_balance', 'pushed_by_docyt_transactions'].includes(baReconciliationItem.itemType)) {
    if (total !== undefined) {
      numbers = total;
    } else {
      numbers = amountFormatter(baReconciliationItem.amount);
    }
    label = title;
  } else {
    label = `Number of ${transactionType || title}`;
    const { count } = baReconciliationItem;
    if (total !== undefined) {
      numbers = `${count} (${total})`;
    } else {
      numbers = `${count} (${amountFormatter(baReconciliationItem.amount)})`;
    }
  }

  let secondField = null;
  if (baReconciliationItem.itemType === 'bank_feed_verification') {
    secondField = (
      <Form.StaticField
        className={ styles['numbers-value'] }
        label="Bank Feed"
        value={ bankStatementStateText(bankStatement) }
      />
    );
  } else {
    secondField = (
      <Form.StaticField
        className={ styles['numbers-value'] }
        label={ label }
        value={ numbers }
      />
    );
  }
  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.StaticField
        className={ styles['bank-account-value'] }
        label="Bank Account"
        value={ reconciliationPaymentAccount.name }
      />
      { secondField }
      <Form.TextField
        autoComplete="off"
        error={ errors.note?.message }
        label="Note"
        placeholder="Add acknowledgement notes"
        { ...register('note') }
      />

      <span>Are you sure you want to acknowledge the above?</span>
    </Form>
  );
};

export default React.memo(AcknowledgeForm);
