import { TID } from '@src/types/common';
import { IReportService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

const getReportServiceByBusinessId = (businessId: TID): Promise<IReportService> => {
  return apiGet(
    '/api/v1/report_services/get_by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.report_service) as IReportService);
};

export {
  getReportServiceByBusinessId,
};
