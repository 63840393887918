import React from 'react';

import Root from '@src/components/root';

import SignUpPage from './sign_up/sign_up_page';

interface ISelfOnboardingPageProps {
  email: string,
  onSignUp: (data: any) => void,
}

const SelfOnboardingSignUpPage = ({
  email,
  onSignUp,
}: ISelfOnboardingPageProps) => {
  return (
    <Root>
      <SignUpPage email={ email } onSignUp={ onSignUp } />
    </Root>
  );
};

export default SelfOnboardingSignUpPage;
