import React, { FC } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { TFilterData } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import CategorizedByIcon from '@src/components/reconciliation_center/action_icons/categorized_by_icon';
import DocumentIcon from '@src/components/reconciliation_center/action_icons/document_icon';
import FlaggedStateIcon from '@src/components/reconciliation_center/action_icons/flagged_state_icon';
import TransactionType from '@src/components/reconciliation_center/action_icons/transaction_type';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { MoreInfoIcon } from '@src/components/utils/icomoon';

import FlaggedTransactionsFilter from './filter';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface FlaggedTransactionsTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
  queryParams: TFilterData,
}

const categorizedIcon = (document: ITransactionServiceDocument) => {
  return <CategorizedByIcon document={ document } />;
};

const transactionQBOStatusIcon = (document: ITransactionServiceDocument) => {
  return <QBOStatusIcon fontSize={ 18 } model={ document } />;
};

const flaggedStateIcon = (document: ITransactionServiceDocument) => {
  return <FlaggedStateIcon document={ document } />;
};

const documentIcon = (document: ITransactionServiceDocument) => {
  return <DocumentIcon document={ document } />;
};

const transactionType = (document: ITransactionServiceDocument) => {
  return <TransactionType document={ document } />;
};

const detailLink = (document: ITransactionServiceDocument) => {
  return (
    <AppLink newWindow href={ `/document/${document.docytId}` }>
      <MoreInfoIcon fontSize={ 16 } inColor="black" />
    </AppLink>
  );
};

const FlaggedTransactionsTable: FC<FlaggedTransactionsTableProps> =
  ({ records, sorting, infiniteQuery, queryParams }) => {
    return (
      <CollectionTable<ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn>
        isRegionScroll
        className={ styles['table-section'] }
        filter={ <FlaggedTransactionsFilter queryParams={ queryParams } /> }
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.DateColumn<
            ITransactionServiceDocument,
            TTransactionServiceDocumentsSortColumn
          >
          name="transaction_date"
          sortColumn="transaction_date"
          title="Date"
          value={ (document) => document.transactionDate }
        />

        <CollectionTable.TextColumn<
            ITransactionServiceDocument,
            TTransactionServiceDocumentsSortColumn
          >
          name="description"
          sortColumn="description"
          title="Description"
          value={ (document) => document.description }
        />

        <CollectionTable.TextColumn<
            ITransactionServiceDocument,
            TTransactionServiceDocumentsSortColumn
          >
          name="account"
          sortColumn="payment_account"
          title="Account"
          value={ (document) => document.paymentAccountName }
        />

        <CollectionTable.LabelColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="transaction_type"
          sortColumn="transaction_type"
          title="Type"
          value={ transactionType }
          variant={ (document) => document.transactionType }
          width="110px"
        />

        <CollectionTable.AmountColumn<
            ITransactionServiceDocument,
            TTransactionServiceDocumentsSortColumn
          >
          name="amount"
          sortColumn="amount"
          title="Amount"
          value={ (document) => document.amount }
          width="120px"
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="flagged_state"
          title="Flagged State"
          value={ flaggedStateIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="categorized_by"
          title="Categorized By"
          value={ categorizedIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="qbo_status"
          title="QBO Status"
          value={ transactionQBOStatusIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="document_type"
          title="Document Type"
          value={ documentIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="detail_link"
          title="Detail Link"
          value={ detailLink }
        />
      </CollectionTable>
    );
  };

export default FlaggedTransactionsTable;
