import { createContext, useContext } from 'react';

import { IBankStatement } from '@src/types/bank_statements';

const BankStatementContext = createContext<IBankStatement | null>(null);

const useBankStatementContext = () => <IBankStatement>useContext(BankStatementContext);

export {
  BankStatementContext,
  useBankStatementContext,
};
