import React from 'react';

import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IReportItem } from '@src/types/report_service/report_item';

import Header from './header';
import List from './list';
import { useQueryData } from '../hooks';

interface IReportOtherDetailProps {
  businessId: TID,
  report: IReport,
  itemIdentifier: string
}

const ReportOtherDetail = ({ businessId, report, itemIdentifier }: IReportOtherDetailProps) => {
  const data = useQueryData(report, 'reportOtherDetail', itemIdentifier);

  return (
    <div className="p-l-50 p-r-50">
      <Header
        businessId={ businessId }
        identifier={ data?.itemIdentifier?.name as string }
        report={ report }
        template={ data.template }
      />
      <List
        comparerIds={ data.comparerIds as string[] }
        configurations={ data.configurations }
        itemIdentifier={ data.itemIdentifier as IReportItem }
        report={ report }
      />
    </div>
  );
};

export default ReportOtherDetail;
