import React from 'react';

import {
  IMatchedItem,
} from '@src/types/matched_items';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IMatchedItemListProps {
  matchedItems: IMatchedItem[],
}

const MatchedItemList = ({
  matchedItems,
}: IMatchedItemListProps): JSX.Element => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HCell>Docyt ID</Table.HCell>
          <Table.HCell
            textAlign="right"
            widthPercent={ 15 }
          >
            Amount
          </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          matchedItems.map((matchedItem) => {
            return (
              <Item
                key={ matchedItem.id }
                matchedItem={ matchedItem }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default MatchedItemList;
