import React, { useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useResendEmailVerificaiton } from '@src/hooks/queries/docyt_check_email_verifications';

import Form from '@src/components/ui/form';
import Text from '@src/components/ui/text';
import Alert from '@src/components/ui_v2/alerts/alert';
import { Button } from '@src/components/ui_v2/buttons';
import { TextInput } from '@src/components/ui_v2/inputs';
import MutationStatus from '@src/components/utils/mutation_status';

interface IVerifyIdentityFormValues {
  accessCode: string,
}

interface IVerifyIdentityFormProps {
  formId?: string,
  email: string,
  onSubmit: SubmitHandler<IVerifyIdentityFormValues>,
}

const VerifyIdentityForm = ({
  formId,
  email,
  onSubmit,
}: IVerifyIdentityFormProps): JSX.Element => {
  const resend = useResendEmailVerificaiton();

  const { mutate } = resend;
  const handleResendCodeClick = useCallback(() => {
    mutate();
  }, [mutate]);

  const {
    register,
    handleSubmit,
  } = useForm<IVerifyIdentityFormValues>();

  return (
    <>
      <MutationStatus
        mutation={ resend }
        successMessage={ window.Docyt.Common.Constants.Messages.SECURITY_CODE_SENT_TO_EMAIL }
      />
      <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
        <Text
          as="h2"
          fontVariant="bold"
        >
          Enter Secret Code
        </Text>
        <Text>
          { `Sent to your email ${email}` }
        </Text>
        <TextInput
          className="m-t-10"
          placeholder="Security code"
          { ...register('accessCode') }
        />
        <Button
          variant="link"
          onClick={ handleResendCodeClick }
        >
          Resend code
        </Button>
        <Alert
          className="m-t-10"
          variant="info"
        >
          TIP: To keep your account secure,
          sign out of your account after you are done with payments
        </Alert>
      </Form>
    </>
  );
};

export {
  IVerifyIdentityFormProps,
  IVerifyIdentityFormValues,
  VerifyIdentityForm as default,
};
