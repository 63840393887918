import React, { useCallback } from 'react';

import { useDestroyDashboard } from '@src/hooks/queries/dashboards/dashboards';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { IDashboard } from '@src/types/dashboards/dashboards';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { PencilIcon } from '@src/components/utils/fa_icons';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useConfirmDeleteModal } from '../../common/confirm_delete/confirm_delete';
import { useCreateDashboardModal } from '../create_dashboard/create_dashboard_modal';

import styles from '../dashboard/styles.module.scss';

interface IDashboardActionsMenuProps {
  currentDashboard: IDashboard,
  businessServiceInfo?: IBusinessServicesInfo,
}

const DashboardActionsMenu = ({
  currentDashboard,
  businessServiceInfo,
}: IDashboardActionsMenuProps): JSX.Element => {
  const businessId = currentDashboard.businessId;
  const updateDashboard = useCreateDashboardModal();

  const destroy = useDestroyDashboard();

  const { mutate: destroyBusinessDashboard } = destroy;
  const handleConfirm = useCallback(() => {
    destroyBusinessDashboard(
      {
        id: currentDashboard.id,
      },
      {
        onSuccess: () => {
          Backbone.history.navigate(`/businesses/${businessId}/dashboards`, { trigger: true });
        },
      },
    );
  }, [destroyBusinessDashboard, currentDashboard.id, businessId]);

  const destroyModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus
        mutation={ destroy }
        successMessage="Deleted successfully"
      />
      <ActionsDropdown
        className={ styles['actions-dropdown-content'] }
      >
        <ActionsDropdown.Action
          icon={ <PencilIcon fontSize={ 16 } /> }
          title="Edit Dashboard"
          onSelect={ updateDashboard.open }
        />
        <ActionsDropdown.Action
          danger
          icon={ <TrashcanIcon fontSize={ 16 } /> }
          title="Delete Dashboard"
          onSelect={ destroyModal.open }
        />
      </ActionsDropdown>
      <updateDashboard.Component
        { ...updateDashboard.props }
        businessId={ businessId }
        dashboard={ currentDashboard }
        standardCategoryId={ businessServiceInfo?.standardCategoryId }
      />
      <destroyModal.Component
        confirmTitle="Delete"
        text={ (
          <div className="text-left">
            <p>{`Are you sure you want to delete ${currentDashboard.name}?`}</p>
            <br />
            <p>
              If this dashboard is shared with other people,
              they will no longer be able to view it.
            </p>
          </div>
        ) }
        title="Delete Dashboard"
        { ...destroyModal.props }
      />
    </>
  );
};

export default DashboardActionsMenu;
