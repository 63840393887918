import React from 'react';

import { ReconciliationCenterContext } from '@src/hooks/contexts/reconciliation_center_context';
import { useReconciliationCenterByBusinessId } from '@src/hooks/queries/reconciliation_centers';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IReconciliationCenterProviderProps {
  businessId: number,
  children: React.ReactNode,
  useLocalSpinner?: boolean,
}

const ReconciliationCenterProvider = ({
  businessId,
  children,
  useLocalSpinner,
}: IReconciliationCenterProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useReconciliationCenterByBusinessId({ businessId });

  if (isLoading) {
    return <Spinner localSpinnerId={ useLocalSpinner && 'detail-region' } />;
  }
  if (isError) return <ErrorNotification error={ error } />;
  if (!data?.reconciliationCenter) return <ErrorNotification message="No Reconciliation Center loaded" />;

  return (
    <ReconciliationCenterContext.Provider value={ data.reconciliationCenter }>
      { children }
    </ReconciliationCenterContext.Provider>
  );
};

export default ReconciliationCenterProvider;
