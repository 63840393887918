module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<ul class="nav nav-sidebar">\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'forwarding_email' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/forwarding_email">\n      <span class="icon-business-inbox service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Forwarding Email</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'payers' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/ach_or_check">\n        <span class="icon-payers service-icon-font inline-flex-wrapper">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>\n            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>\n            <span class="path9"></span>\n        </span>\n        <span class="nav-item-text">Setup Payment Methods</span>\n        ');
    
      if (this.isShowWarning) {
        __out.push('\n        <span class="icon-unable-to-sync in-red nav-item-number">\n            <span class="path1"></span><span class="path2"></span>\n        </span>\n        ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'roles' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/approval_rules">\n      <span class="icon-approval-rules service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Approval Rules</span>\n      <span class="nav-item-number">');
    
      __out.push(__sanitize(this.service.get('count_approval_rules')));
    
      __out.push('</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'compliance_policy' ? 'active' : ''));
    
      __out.push('" data-global-feature-toggle="compliance-rules-page">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/compliance_policy">\n      <span class="icon-compliance-policy service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">Compliance Policy</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'trash' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/trash">\n      <span class="icon-trash-fill service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Trash</span>\n      <span class="nav-item-number">');
    
      __out.push(__sanitize(this.service.get('count_deleted_invoices')));
    
      __out.push('</span>\n    </a>\n  </li>\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}