import React from 'react';

import apServiceLogoImg from '@assets/images/categories/APService@3x.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const ApServiceLogoIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="AP Service Logo icon"
      src={ apServiceLogoImg }
      { ...props }
    />
  );
};

export default ApServiceLogoIcon;
