import { string, object } from 'yup';

import { TID } from '@src/types/common';

const w9InfoValidation = object({
  w9LegalName:    string().trim().required('Legal Business Name is required.'),
  addressLine1:   string().trim().required('Address is required.'),
  addressCity:    string().trim().required('City is required.'),
  addressState:   string().trim().required('State is required.'),
  w9Email:        string().email('Enter a valid email address').optional(),
  addressZip:     string().trim().required('Zip is required.').matches(/^\d{5}(-\d{4})?$/, 'ZIP Code must be in a valid ZIP or ZIP+4'),
  addressCountry: string().trim().required('Country is required.'),
  w9Ssn:          string()
    .transform((value) => (value === '' ? '' : value))
    .notRequired()
    .test('ssn-length', 'SSN must be exactly 9 digits', (value) => {
      return !value || value.length === 9;
    }),
  w9Ein: string()
    .transform((value) => (value === '' ? '' : value))
    .notRequired()
    .test('ein-length', 'EIN must be exactly 9 digits', (value) => {
      return !value || value.length === 9;
    }),
});

interface IW9InfoFormValues {
 addressCity: string;
 addressCountry: string;
 addressLine1: string;
 addressState: string;
 addressZip: string;
 businessId: TID;
 id: TID;
 w9BusinessType: string;
 w9Ein: string;
 w9Email: string;
 w9LegalName: string;
 w9Name: string;
 w9Phone: string;
 w9Ssn: string;
 w9SignedDate: string;
}

export {
  IW9InfoFormValues,
  w9InfoValidation,
};
