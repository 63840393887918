import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateCompliancePoliciesParams,
  IDeleteCompliancePoliciesParams,
  IEditCompliancePoliciesParams,
} from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import {
  IAccountsPayableRelatedJournal,
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IVendor } from '@src/types/business_vendors';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';

interface IGetAccountsPayableServiceDocumentsParams {
    serviceId: TID;
    filters?: object;
    perPage?: number;
    orderColumn?: TAccountsPayableServiceDocumentsSortColumn;
    orderDirection?: TOrderDirection;
}

interface IGetAccountsPayableServiceDocumentsResponse {
    meta: {
        totalCount: number;
    };
    collection: IAccountsPayableServiceDocument[];
}

interface IGetRemainingApproverNamesResponse {
    approverNames: string;
}

interface IGetPossibleMatchesParams {
    type: string;
    ids: TID[];
}

interface ISearchBusinessVendorResponse {
    meta: {
        totalCount: number;
    };
    collection: IVendor[];
}

const getAccountsPayableServiceDocuments = (
  params: IGetAccountsPayableServiceDocumentsParams,
): Promise<IGetAccountsPayableServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/accounts_payable_service_documents',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection:
                cdata.accountsPayableServiceDocuments as IAccountsPayableServiceDocument[],
    };
  });
};

const getPossibleMatches = (
  parentData: any,
  params: IGetPossibleMatchesParams,
): Promise<any> => {
  return apiGet(
    '/api/v1/possible_matches',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    const documents = parentData.accountsPayableServiceDocuments as IAccountsPayableServiceDocument[];

    documents.forEach((document) => {
      const resultHash = cdata[document.id] || {
        transactionServiceDocuments: [],
      };
      document.possibleMatches = resultHash.transactionServiceDocuments;
    });

    return {
      meta: {
        totalCount: parentData.count,
      },
      collection: documents,
    };
  });
};

const getAccountsPayableServiceDocumentsWithPossibleMatches = (
  params: IGetAccountsPayableServiceDocumentsParams,
): Promise<IGetAccountsPayableServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/accounts_payable_service_documents',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    const ids = (cdata.accountsPayableServiceDocuments as IAccountsPayableServiceDocument[]).map(s => s.id) || [];

    if (ids.length !== 0) {
      return getPossibleMatches(cdata, {
        type: 'service_document',
        ids,
      });
    }

    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.accountsPayableServiceDocuments,
    };
  });
};

const getAccountsPayableServiceDocument = (
  id: number,
): Promise<IAccountsPayableServiceDocument> => {
  return apiGet(`/api/v1/accounts_payable_service_documents/${id}`).then(
    (data) => camelizeKeys(
      data.service_document,
    ) as IAccountsPayableServiceDocument,
  );
};

const getAccountsPayableRelatedJournal = (
  documentId: TID,
): Promise<IAccountsPayableRelatedJournal> => {
  return apiGet(`/api/v1/documents/${documentId}/related_journal_entries`).then(
    (data) => camelizeKeys(
      data.related_journal_entries,
    ) as IAccountsPayableRelatedJournal,
  );
};

const getRemainingApproverNames = (
  id: number,
): Promise<IGetRemainingApproverNamesResponse> => {
  return apiGet(
    `/api/v1/accounts_payable_service_documents/${id}/remaining_approver_names`,
  ).then((data) => camelizeKeys(data) as IGetRemainingApproverNamesResponse);
};

const cancelAchPayment = (invoiceId: TID): Promise<void> => {
  return apiPut(
    `/api/v1/accounts_payable_service_documents/${invoiceId}/cancel_ach_payment`,
    {},
  );
};

interface IMarkAsPaidServiceDocumentParams {
    checkId?: string;
    paidAt?: string;
    paymentAccountId?: TID;
    balanceSheetAccountId?: TID;
    paymentMode?: string;
    serviceDocumentIds: Array<number>;
    ignoreCheckQboSynced?: boolean;
    memo?: string;
}

const markAsPaidServiceDocument = (
  params: IMarkAsPaidServiceDocumentParams,
): Promise<void> => {
  return apiPut(
    '/api/web/v1/accounts_payable_service_documents/mark_invoices_as_paid',
    underscoreKeys(params),
  );
};

const getSearchBusinessVendors = (
  params: ISearchBusinessVendorParams,
): Promise<ISearchBusinessVendorResponse> => {
  return apiGet(
    '/api/v1/business_vendors/search.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.total_count,
      },
      collection: cdata.vendors as IVendor[],
    };
  });
};

const useGetBusinessVendorsCollection = createUseGetInfiniteCollection<
    any,
    ISearchBusinessVendorParams,
    any
>({
  queryKey: QueryKey.accountsReceivablePayments,
  request:  getSearchBusinessVendors,
});

interface ISearchBusinessVendorParams {
    businessId: TID;
    search: string;
}

interface IGetCompliancePoliciesParams {
    businessId: TID;
}
interface IGetCompliancePoliciesResponse {
    policy: {
        accountsPayableServiceId: TID;
        approveAllInvoice: boolean;
        id: TID;
        policyType: string;
    };
}

const getCompliancePolicies = (
  params: IGetCompliancePoliciesParams,
): Promise<IGetCompliancePoliciesResponse> => {
  return apiGet(
    '/api/web/v1/accounts_payable_compliance_policies/by_business_id',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      policy: cdata.accountsPayableCompliancePolicy,
    };
  });
};

const deleteCompliancePolicy = (
  params: IDeleteCompliancePoliciesParams,
): Promise<void> => {
  return apiDelete(
    `/api/web/v1/accounts_payable_compliance_policy_rules/${params.id}`,
    underscoreKeys(params),
  );
};

const updateCompliancePolicy = (
  params: IEditCompliancePoliciesParams,
): Promise<void> => {
  return apiPut(
    `/api/web/v1/accounts_payable_compliance_policy_rules/${params.id}`,
    underscoreKeys({
      business_id:            params.business_id,
      policy_type:            params.policy_type,
      compliance_policy_rule: params.compliance_policy_rule,
    }),
  );
};

const createCompliancePolicy = (
  params: ICreateCompliancePoliciesParams,
): Promise<void> => {
  return apiPost(
    '/api/web/v1/accounts_payable_compliance_policy_rules',
    underscoreKeys(params),
  );
};

interface IUpdateCompliancePolicyServiceDocumentParams {
    id: TID;
    policyType: string;
    approveAllInvoice: boolean;
    businessId: TID;
}

interface IUpdateCompliancePolicyServiceResponse {
    policy: {
        accountsPayableServiceId: TID;
        approveAllInvoice: boolean;
        id: TID;
        policyType: string;
    };
}

const updateCompliancePolicyServiceDocument = (
  params: IUpdateCompliancePolicyServiceDocumentParams,
): Promise<IUpdateCompliancePolicyServiceResponse> => {
  return apiPut(
    `/api/web/v1/accounts_payable_compliance_policies/${params.id}`,
    underscoreKeys(params),
  );
};

interface ICreateCompliancePolicyServiceDocumentParams {
    policyType: string;
    approveAllInvoice: boolean;
    businessId: TID;
}

interface ICreateCompliancePolicyServiceResponse {
    policy: {
        accountsPayableServiceId: TID;
        approveAllInvoice: boolean;
        id: TID;
        policyType: string;
    };
}

const createCompliancePolicyServiceDocument = (
  params: ICreateCompliancePolicyServiceDocumentParams,
): Promise<ICreateCompliancePolicyServiceResponse> => {
  return apiPost(
    '/api/web/v1/accounts_payable_compliance_policies',
    underscoreKeys(params),
  );
};

export {
  IGetAccountsPayableServiceDocumentsParams,
  IGetAccountsPayableServiceDocumentsResponse,
  IGetRemainingApproverNamesResponse,
  IMarkAsPaidServiceDocumentParams,
  ISearchBusinessVendorParams,
  ISearchBusinessVendorResponse,
  getAccountsPayableServiceDocuments,
  getAccountsPayableServiceDocumentsWithPossibleMatches,
  getAccountsPayableServiceDocument,
  getAccountsPayableRelatedJournal,
  getRemainingApproverNames,
  cancelAchPayment,
  markAsPaidServiceDocument,
  getSearchBusinessVendors,
  getCompliancePolicies,
  updateCompliancePolicyServiceDocument,
  createCompliancePolicyServiceDocument,
  useGetBusinessVendorsCollection,
  deleteCompliancePolicy,
  updateCompliancePolicy,
  createCompliancePolicy,
};
