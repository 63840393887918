import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { IWidget } from '@src/types/dashboards';
import { IDashboard } from '@src/types/dashboards/dashboards';

import Form from '@src/components/ui/form';

import { dashboardValidation, IDashboardInput } from './schema';
import SelectAllWidgetCheckbox from './select_all_widget_checkbox';
import SelectWidgetCheckbox from './select_widget_checkbox';

import styles from './styles.module.scss';

interface ICreateDashboardModalFormProps {
  dashboard?: IDashboard,
  widgets?: IWidget[],
  formId?: string,
  onSubmit: SubmitHandler<IDashboardInput>,
}

const CreateDashboardModalForm = ({
  dashboard,
  widgets,
  formId,
  onSubmit,
}: ICreateDashboardModalFormProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IDashboardInput>({
    defaultValues: {
      name:    dashboard?.name,
      widgets: dashboard?.widgets || [],
    },
    resolver: yupResolver(dashboardValidation),
  });

  const widgetControl = useController({ control, name: 'widgets' });

  const handleSubmitError = useCallback((err) => {
    if (!err.widgets) return;

    toastr.error(
      err.widgets.message,
      'Something wrong',
    );
  }, []);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit, handleSubmitError) }>
      <div className={ styles['create-dashboard-container'] }>
        <div className="bottom-divider">
          <Form.TextField
            { ...register('name') }
            error={ errors.name?.message }
            label="Dashboard Name"
            placeholder="Enter Dashboard Name"
          />
        </div>
        <div className="form-field m-t-20">
          <div className="form-field-item form-field-name">Select KPIs</div>
          {
            widgets
            && (
            <div className="form-field-item form-field-value widgets-list">
              <div
                className={ classNames(styles.widget, 'bottom-divider') }
                role="button"
                tabIndex={ 0 }
              >
                <SelectAllWidgetCheckbox
                  field={ widgetControl.field }
                  widgets={ widgets }
                />
              </div>
              {
                widgets.map((widget, index) => (
                  <div
                    key={ widget.id }
                    className={ styles.widget }
                    role="button"
                    tabIndex={ index }
                  >
                    <SelectWidgetCheckbox
                      field={ widgetControl.field }
                      widget={ widget }
                    />
                  </div>
                ))
              }
            </div>
            )
          }
        </div>
      </div>
    </Form>
  );
};

export {
  ICreateDashboardModalFormProps,
  CreateDashboardModalForm as default,
};
