import React, { useEffect, useMemo, useState } from 'react';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import Wrapper from '@src/components/ui_v2/filter/filter_field_wrapper';
import { TOption } from '@src/components/ui_v2/inputs';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';

interface IBusinessReportsMultiAddItemFieldProps extends 
  Omit<React.ComponentPropsWithoutRef<typeof MultiSelectAddItem>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps {
    businessId: TID,
    businesses: IBusiness[]
}

const BusinessReportsMultiAddItemField = ({
  businessId,
  businesses,
  ...props
}: IBusinessReportsMultiAddItemFieldProps) => {
  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const [multiValue, setMultiValue] = useState<TOption[]>([]);
  
  const options: TOption[] = useMemo(() => (
    businesses.map((business: IBusiness) => ({
      label: business.displayName,
      value: business.id.toString()
    }))
  ), [businesses]);

  useEffect(() => {
    const find = options.find((f: TOption) => Number(f.value) === businessId);
    
    if (find) setMultiValue([find]);
  }, [businessId, options])

  const handleChange = (selectedOptions: TOption[]) => {
    setMultiValue(selectedOptions);
  }

  return (
    <Wrapper { ...wrapperProps }>
      <MultiSelectAddItem
        addItem
        disabled
        options={ options }
        value={ multiValue }
        { ...inputProps }
        onChange={ handleChange }
      />
    </Wrapper>
  );
}

export default BusinessReportsMultiAddItemField;
