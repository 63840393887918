import { useMemo } from 'react';

import { omit, merge, flatten } from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetTransfers } from '@src/hooks/queries/transaction_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import {
  ITransactionServiceDocument,
  TTransfersSortColumn,
} from '@src/types/transaction_service_documents';

import { TSubTransactionTypeOption } from '@src/components/common_v2/filter_fields/transaction_type_filter_field';
import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseTSDCollectionParams {
  businessId: TID,
  section: TSection,
  params: object,
}

interface ITSDCollection {
  query: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  section: TSection,
  sorting: ISorting<TTransfersSortColumn>,
}

const transferDefaultSorting: ISortingParams<TTransfersSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const getTransactionsParamsFromFilter = (filterData: Partial<TSubTransactionTypeOption>) => {
  const filterParams = omit(filterData || {}, 'excluded');

  return {
    filter: filterParams,
  };
};

const useTransfersCollection = ({
  businessId,
  section,
  params,
}: IUseTSDCollectionParams): ITSDCollection => {
  const filterData = useFilterData(section);
  const filterParams = useMemo(() => {
    return getTransactionsParamsFromFilter(filterData);
  }, [filterData]);

  const sorting = useSorting<TTransfersSortColumn>({
    section:        section.section,
    defaultSorting: transferDefaultSorting,
  });

  const q = merge({
    businessId,
    ...filterParams,
    ...sorting.data,
  }, params);
  const query = useGetTransfers(q);

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  ITSDCollection,
  useTransfersCollection,
};
