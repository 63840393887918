import React, { useCallback, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBusinesses } from '@src/hooks/queries/businesses';
import { IBusinessesFilter } from '@src/types/businesses';

import {
  ISelectInputProps,
  TOption,
} from '@src/components/ui_v2/inputs';
import MultiReactSelectedValue from '@src/components/ui_v2/inputs/react_select/multi_react_selected_value';
import ReactSelectInput from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface IMultiBusinessesInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  value?: TOption[],
  filter?: IBusinessesFilter,
  onChange?: (value?: TOption[]) => void,
  forDataExport?: boolean,
}

const MultiBusinessesInput = ({
  value,
  onChange,
  filter,
  forDataExport,
}: IMultiBusinessesInputProps) => {
  let queryParams;

  if (forDataExport) {
    queryParams = {
      simple: true,
      filter,
      forDataExport,
    }
  } else {
    queryParams = {
      includeEmployedBusiness: true,
      simple:                  true,
      filter
    }
  }

  const query = useGetBusinesses(queryParams);

  const businesses = useMemo(() => {
    const businessesPages = query.data?.pages || [];
    return flatten(businessesPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const options: TOption[] = useMemo(() => {
    return (businesses ?? []).map((business) => ({
      value: String(business.id),
      label: business.displayName,
    }));
  }, [businesses]);

  const handleChange = useCallback((newValue) => {
    if (onChange) { onChange(newValue); }
  }, [onChange]);

  const hanldeRemoveItem = useCallback((itemValue: string | number) => {
    if (onChange) { onChange(value?.filter((v) => v.value !== itemValue)); }
  }, [onChange, value]);

  return (
    <>
      <ReactSelectInput
        isClearable
        isMulti
        isSearchable
        controlShouldRenderValue={ false }
        options={ options }
        placeholder="Select Businesses"
        value={ value }
        onChange={ handleChange }
      />
      { value?.length ? (
        value.map((v) => (
          <MultiReactSelectedValue
            key={ v.value }
            label={ v.label }
            value={ v.value }
            onRemoveItem={ hanldeRemoveItem }
          />
        ))
      ) : (<span>{ null }</span>)}
    </>
  );
};

export default React.memo(MultiBusinessesInput);
