/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { IBusinessItem, IBusinessUpdatedItem, IDashboardItem, IWidgetIdentifiers } from '@src/types/financial_insight';

import Dropdown from '@src/components/ui_v2/dropdown';
import InnerInput from '@src/components/ui_v2/inputs/dropdown_input/inner_input';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from '@src/components/ui_v2/inputs/styles.module.scss';

import style from './styles.module.scss';

interface IFinancialInsightsDropdownProps {
  updatedData : IBusinessUpdatedItem[];
  row: IBusinessItem;
  className: string;
  onChange: (row : IBusinessItem, option : IDashboardItem) => void;
  onClear: () => void;
}

const FinancialInsightsDropdown = ({
  updatedData,
  row,
  className,
  onChange,
  onClear,
} : IFinancialInsightsDropdownProps): JSX.Element => {
  const selectedValue = useMemo(() => {
    const isUpdatedExists = updatedData.find((it : IBusinessUpdatedItem) => it.businessId === row.businessId);
    if (isUpdatedExists) {
      return isUpdatedExists.updated;
    }
    return row.default;
  }, [row, updatedData]);

  const classes = classNames(
    styles['dropdown-input'],
    className,
  );

  const getAllKpiWidgetData = useCallback((widgetIdentifiers : IWidgetIdentifiers) => {
    if (widgetIdentifiers.length === 0) return null;
    return (
      <p className={ `${style['text-wrap-options']} m-t-5 mb-0` }>
        {widgetIdentifiers.join(', ')}
      </p>
    );
  }, []);

  const tooltipContent = useCallback(() => (
    <div className={ `${style['tooltip-kpi-content']} padding-0` }>
      {selectedValue && selectedValue.widgetIdentifiers.join(', ')}
    </div>

  ), [selectedValue]);

  const getActiveKpiWidgetData = useCallback(() => {
    if (!selectedValue) return null;
    const pendingCount = (selectedValue.widgetIdentifiers.length || 0) - 3;
    if (selectedValue.widgetIdentifiers.length === 0) return null;
    return (
      <div className="row margin-0 m-t-5 m-b-0">
        <div className={ `${style['text-wrap-kpi']} col-sm-10 padding-0` }>
          {selectedValue.widgetIdentifiers.slice(0, 3).join(', ')}
        </div>
        {pendingCount > 0 && (
        <div className="padding-0 display-flex justify-content-end">
          <Tooltip.Hover
            content={ tooltipContent() }
            tooltipClassName="width-max-content"
            variant="light"
          >
            <span
              className={ style['text-wrap-kpi'] }
            >
              +
              {pendingCount}
            &nbsp;more
            </span>
          </Tooltip.Hover>
        </div>
        )}
      </div>
    );
  }, [selectedValue, tooltipContent]);

  return (
    <div>
      <Dropdown.Provider className={ classes }>
        <InnerInput
          value={ selectedValue ? { value: selectedValue.dashboardId, label: selectedValue.dashboardName } : null }
          onClear={ onClear }
        />
        <Dropdown.Menu className={ style['financial-dropdown'] }>
          { row.dashboards.map((option : IDashboardItem) => (
            <Dropdown.Item
              key={ option.dashboardId }
              className={ style['financial-dropdown-item'] }
              isSelected={ selectedValue.dashboardId === option.dashboardId }
              style={ { backgroundColor: selectedValue.dashboardId === option.dashboardId ? 'silver' : '#FFFFFF' } }
              onSelect={ () => { onChange(row, option); } }
            >
              <div className="width-100-percent">
                <h2 className="font-normal font-12 margin-0 display-flex justify-content-between">
                  { option.dashboardName }
                  {selectedValue.dashboardId === option.dashboardId
                && <span className={ style['check-color'] }>&#10003;</span>}
                </h2>

                {getAllKpiWidgetData(option.widgetIdentifiers || [])}
              </div>
            </Dropdown.Item>

          )) }
        </Dropdown.Menu>
      </Dropdown.Provider>
      {selectedValue && getActiveKpiWidgetData()}
    </div>
  );
};

export default FinancialInsightsDropdown;
