import React from 'react';

import loaderSvg from '@assets/images/loader.svg';

import ImgIcon, { IImgIconProps } from '../img_icon';

import styles from './styles.module.scss';

const SolidLoader = (props: IImgIconProps): JSX.Element => {
  return (
    <div className={ styles['loader-container'] }>
      <ImgIcon
        alt="Search Icon"
        src={ loaderSvg }
        { ...props }
      />
    </div>
  );
};

export default SolidLoader;
