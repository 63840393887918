module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="row">\n  ');
    
      if (this.is_business_admin || this.can_access_ap_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="AccountsPayableAdvisor">\n      <div class="m-r-23">\n        <div class="avatar">\n          <span class="icon icon-accounts-payable font-48">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n          </span>\n        </div>\n      </div>\n      <div class="title-information">Accounts Payable</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_rp_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="ReceiptService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <span class="icon icon-receipt-box font-48">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n          </span>\n        </div>\n      </div>\n      <div class="title-information">Receipt Box</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_report_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="ReportService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <img src="');
        __out.push(__sanitize(configData.reports_images['FINANCIAL_REPORTS']));
        __out.push('" class=\'width-48px\'>\n        </div>\n      </div>\n      <div class="title-information">Business Reports</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_inbox_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="InboxService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <img src="');
        __out.push(__sanitize(configData.business_inbox_images['INBOX_SERVICE']));
        __out.push('" class=\'width-48px\'>\n        </div>\n      </div>\n      <div class="title-information">Business Mailroom</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_revenue_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="RevenueService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <span class="icon icon-revenue-chart font-48">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n          </span>\n        </div>\n      </div>\n      <div class="title-information">Revenue Center</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_reconciliation_center) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="ReconciliationCenterService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <span class="icon icon-reconciliation-circle-o font-48 inline-flex-wrapper"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n        </div>\n      </div>\n      <div class="title-information">Reconciliation Center</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_banking_service) {
        __out.push('\n  <div class="col-md-6">\n    <a class=\'business-service-info\' data-name="BankingAccountsService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <i class="icon icon-bank-circle-o font-48 inline-flex-wrapper">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>\n            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>\n            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>\n            <span class="path13"></span><span class="path14"></span>\n          </i>\n        </div>\n      </div>\n      <div class="title-information">Banking Accounts</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_expense_report_service) {
        __out.push('\n  <div class="col-md-6 ');
        __out.push(__sanitize(this.hiddenWhenDisabled('expense_ach')));
        __out.push('">\n    <a class=\'business-service-info\' data-name="ExpenseReportsService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <img src="');
        __out.push(__sanitize(configData.expense_images['EXPENSE_SERVICE']));
        __out.push('" class=\'width-48px\'>\n        </div>\n      </div>\n      <div class="title-information">Expense Reports</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_accounts_receivable_service) {
        __out.push('\n  <div class="col-md-6 ');
        __out.push(__sanitize(this.hiddenWhenDisabled('accounts_receivable')));
        __out.push('">\n    <a class=\'business-service-info\' data-name="AccountsReceivableService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <i class="icon icon-accounts-receivable-service font-48 inline-flex-wrapper">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>\n            <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>\n            <span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>\n          </i>\n        </div>\n      </div>\n      <div class="title-information">Accounts Receivable</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_business_admin || this.can_access_vendor_service) {
        __out.push('\n  <div class="col-md-6 ');
        __out.push(__sanitize(this.hiddenWhenDisabled('vendor_service')));
        __out.push('">\n    <a class=\'business-service-info\' data-name="VendorService">\n      <div class="m-r-23">\n        <div class="avatar">\n          <img src="');
        __out.push(__sanitize(configData.vendor_images['VENDOR_SERVICE']));
        __out.push('" class=\'width-48px\'>\n        </div>\n      </div>\n      <div class="title-information">Vendors</div>\n    </a>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.metrics_service_available && (this.is_business_admin || this.can_access_metrics_service)) {
        __out.push('\n    <div class="col-md-6" data-global-feature-toggle="metrics-service">\n      <a class=\'business-service-info\' data-name="MetricsService">\n        <div class="m-r-23">\n          <div class="avatar">\n            <img src="');
        __out.push(__sanitize(configData.metric_images['METRICS_SERVICE']));
        __out.push('" class=\'width-48px\'>\n          </div>\n        </div>\n        <div class="title-information">Metrics</div>\n      </a>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}