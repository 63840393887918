import React, { useEffect } from 'react';

import styles from './styles.module.scss';

/**
 * Render global or local spinner
 */
interface SpinnerProps {
  localSpinnerId?: string | false | undefined | null,
}

const Spinner = ({ localSpinnerId }: SpinnerProps): JSX.Element => {
  useEffect(() => {
    let spinnerContainer = window.document.body;
    if (localSpinnerId) {
      spinnerContainer = window.document.getElementById(localSpinnerId) || spinnerContainer;
    }

    spinnerContainer.classList.add(styles['spinner-open']);
    const spinnerElement = window.document.createElement('div');
    spinnerElement.classList.add(
      localSpinnerId ? styles['spinner-local-overlay'] : styles['spinner-overlay'],
    );
    spinnerElement.innerHTML = '<i class="fa fa-spinner"></i>';
    spinnerContainer.appendChild(spinnerElement);

    return () => {
      spinnerElement.remove();
      spinnerContainer.classList.remove(styles['spinner-open']);
    };
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ null }</>;
};

export default Spinner;
