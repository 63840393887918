import React from 'react';

import { DateInput } from '@src/components/ui_v2/inputs/date_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IDateFieldProps extends
  React.ComponentPropsWithoutRef<typeof DateInput>,
  Omit<IWrapperProps, 'suffix'>
{
}

const DateField = ({
  ...props
}: IDateFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <DateInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default DateField;
