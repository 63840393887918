import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IUndoSplitTransactionServiceDocumentFayeEvent } from '@src/types/docyt_events/undo_split_transaction_service_document';

import { useFayeChannel } from './common';

export const useUndoSplitTransactionServiceDocumentFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<IUndoSplitTransactionServiceDocumentFayeEvent>,
) => {
  useFayeChannel(
    `/undo_split_transaction_service_document-${businessId}`,
    callback,
  );
};
