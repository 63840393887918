module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n      <div class="modal-content">\n        <div class="modal-header">\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class="modal-title">Reset Runs</h4>\n        </div>\n        <div class="modal-body">\n          <p>Reset multiple runs from start date to end date</p>\n          <div class="row">\n            <div class="col-xs-6">\n              <label>Start Date</label>\n              <div class="relative">\n                <input type="text" class="form-control start-date date-picker" readonly="readonly" placeholder="Start Date" autocomplete="off"/>\n              </div>\n            </div>\n            <div class="col-xs-6">\n              <label>End Date</label>\n              <div class="relative">\n                <input type="text" class="form-control end-date date-picker" readonly="readonly" placeholder="End Date" autocomplete="off"/>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-info btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Reset</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}