module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="integration-content">\n  <div class="item-divider m-t-25 p-b-15 dashboard-header-view">\n    <div class="pull-right display-inline-flex">\n      <div class=\'search-input-wrapper\'>\n        <i class=\'icon icon-search\'></i>\n        <input class=\'form-control text-right input-filter-business business-client-search-input\' placeholder=\'\' type=\'text\'>\n      </div>\n    </div>\n    <h2 class="service-main-title p-t-10">Integrations</h2>\n  </div>\n  <div class="documents-table-wrapper">\n    <div class="setting-business-content m-t-30 business-list">\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}