import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { BotNotificationIcon, VerifiedManuallyIcon } from '@src/components/utils/icomoon';

const categorizedByOptions: TIconOption[] = [
  {
    icon:  <BotNotificationIcon fontSize={ 22 } />,
    label: 'Verified Automatically',
    value: 'true',
  },
  {
    icon:  <VerifiedManuallyIcon fontSize={ 22 } />,
    label: 'Verified Manually',
    value: 'false',
  },
];

interface IVerifiedByFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.IconSelectField>, 'options' | 'name'>
{
}

const VerifiedByFilterField = ({
  ...props
}: IVerifiedByFilterFieldProps): JSX.Element => {
  return (
    <Filter.IconSelectField
      isSearchable={ false }
      name="verified_by_auto"
      options={ categorizedByOptions }
      placeholder="Select Verified Status..."
      { ...props }
    />
  );
};

export default React.memo(VerifiedByFilterField);
