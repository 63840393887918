import React from 'react';

import styles from './styles.module.scss';

interface ISideViewBodyProps {
  children: React.ReactNode,
}

const SideViewBody = ({
  children,
}: ISideViewBodyProps) => {
  return (
    <div className={ styles.body }>
      { children }
    </div>
  );
};

export default SideViewBody;
