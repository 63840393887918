import React from 'react';

import { Label } from '@src/components/ui_v2/labels';
import Tooltip from '@src/components/ui_v2/tooltip';

import Cell, { ITableCellProps } from './cell';

interface ITableLabelCellProps extends Omit<ITableCellProps, 'children' | 'title'> {
  title: React.ReactNode,
  variant?: React.ComponentPropsWithoutRef<typeof Label>['variant'],
  tooltip?: React.ReactNode,
}

const TableLabelCell = ({
  title,
  variant,
  tooltip,
  ...cellProps
}: ITableLabelCellProps) => {
  let cellContent = (
    <Label variant={ variant }>
      { title }
    </Label>
  );
  if (tooltip) {
    cellContent = (
      <Tooltip.Hover
        content={ tooltip }
      >
        { cellContent }
      </Tooltip.Hover>
    );
  }
  return (
    <Cell { ...cellProps }>
      {cellContent}
    </Cell>
  );
};

export default TableLabelCell;
