import React from 'react';

const MetricsServiceHeader = (): JSX.Element => {
  return (
    <div>
      {/* Header content here */ }
    </div>
  );
};

export default React.memo(MetricsServiceHeader);
