import React from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { currencyFormater2 } from '@src/utils/currency';
import { formatDate } from '@src/utils/date_helpers';

import DetailsAchTypeFiled from './fields/details_ach_type_filed';
import DetailsStaticFiled from './fields/details_static_filed';

import styles from './styles.module.scss';

interface IAchDetailsInfoProps {
  document?: IAchTransaction,
}

const AchDetailsInfo = ({
  document,
}: IAchDetailsInfoProps) => {
  return (
    <div className={ styles.details }>
      <DetailsStaticFiled
        label="Payee"
        value={ document?.receiverName }
      />
      <DetailsStaticFiled
        label="Amount"
        value={ currencyFormater2(document?.amount || 0) }
      />
      <DetailsStaticFiled
        label="Payment Date"
        value={ document?.dwollaCreatedAt && formatDate(document?.dwollaCreatedAt) }
      />
      <DetailsAchTypeFiled
        label="ACH Type"
        value={ document?.sourceClearingMethod }
      />
      <DetailsStaticFiled
        label="Payee Bank Account"
        value={ document?.destinationDwollaFundingSourceName }
      />
      <DetailsStaticFiled
        label="Payer Bank Account"
        value={ document?.sourceDwollaFundingSourceName }
      />
      <DetailsStaticFiled
        label="ACH Transfer ID"
        value={ document?.dwollaId }
      />
    </div>
  );
};

export default React.memo(AchDetailsInfo);
