import { string, number, object } from 'yup';

const forwardToBankStatementsValidation = object({
  reconciliationPaymentAccountId: number().label('Account').required(),
  date:                           string().required('Statement Date is required.'),
});

export {
  forwardToBankStatementsValidation,
};
