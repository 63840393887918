module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header text-center">\n                <i class="icon icon-unable-to-sync font-18 in-red m-r-8 vertical-align-middle"><span class="path1"></span><span class="path2"></span></i>\n                ');
    
      if (this.is_authorized) {
        __out.push('\n                    <span class="vertical-align-middle font-24">Cannot Authorize Address</span>\n                ');
      } else {
        __out.push('\n                    <span class="vertical-align-middle font-24">Cannot Unauthorize Address</span>\n                ');
      }
    
      __out.push('\n            </div>\n            <div class="modal-body">\n                <div>\n                    ');
    
      if (this.is_authorized) {
        __out.push('\n                        <p class=\'font-16\'>Only an authorized signatory can authorize payment addresses to be used when making payments by Docyt Check.</p>\n                    ');
      } else {
        __out.push('\n                        <p class=\'font-16\'>Only an authorized signatory can unauthorize payment addresse.</p>\n                    ');
      }
    
      __out.push('\n                </div>\n                <div class="m-t-10">\n                    <p class=\'font-16\'>Contact <a href=\'mailto:support@docyt.com\'>support@docyt.com</a> for more information.</p>\n                </div>\n            </div>\n            <div class="modal-footer">\n                <button type="button" class="btn btn-blue">Close</button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}