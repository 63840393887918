import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  TBankAccountReconciliationItemStatus,
  IBankAccountReconciliationItem,
} from '@src/types/bank_account_reconciliations';

import Modal from '@src/components/ui/modal';

import Form from './form';
import { IAcknowledgeFormValues } from './schema';

interface IAcknowledgeModalProps extends Omit<IUseModalProps, 'onDone'> {
  baReconciliationItem: IBankAccountReconciliationItem,
  noteRequired: boolean,
  onDone: (status: TBankAccountReconciliationItemStatus, note?: string) => void,
  closeDrawer?: () => void;
  total?: string,
}

const AcknowledgeModal = ({
  baReconciliationItem,
  noteRequired,
  isOpen,
  onDone,
  closeDrawer,
  total,
  ...props
}: IAcknowledgeModalProps) => {
  const handleSubmit = useCallback((data: IAcknowledgeFormValues) => {
    onDone('acknowledged' as TBankAccountReconciliationItemStatus, data.note);
    closeDrawer?.();
  }, [onDone, closeDrawer]);

  return (
    <Modal.Form
      proceedTitle="Confirm"
      show={ isOpen }
      title="Acknowledge"
      { ...props }
    >
      { ({ formId }) => (
        <Form
          baReconciliationItem={ baReconciliationItem }
          formId={ formId }
          noteRequired={ noteRequired }
          total={ total }
          onSubmit={ handleSubmit }
        />
      ) }
    </Modal.Form>
  );
};

const useAcknowledgeModal = makeUseModal(AcknowledgeModal);

export {
  useAcknowledgeModal,
};
