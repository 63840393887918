import React from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import AuthorizedSignatoryView from './authorized_signatory/authorized_signatory_view';
import BillingInfoList from './billing_info_details/billing_info_list';
import FinDetailsView from './connect_fin_details/fin_details_view';
import ConnectQboList from './connect_qbo_details/connect_qbo_list';
import MicroDepositList from './micro_deposit_details/micro_deposit_list';
import KycDocumentView from './request_document_details/kyc_document_view';
import RequestDocumentView from './request_document_details/request_document_view';

interface ISelfOnboardingMainViewProps {
  step: number,
  invitation: ISelfOnboardingInvitation,
}

const SelfOnboardingMainView = ({
  step,
  invitation,
}: ISelfOnboardingMainViewProps): JSX.Element | null => {
  switch (step) {
    case SelfOnboardintSteps.STEP_REQUEST_DOCUMENT:
      return <RequestDocumentView invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_BILLING_INFO:
      return <BillingInfoList invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_CONNECT_QBO:
      return <ConnectQboList invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_CONNECT_FIN:
      return <FinDetailsView invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_KYC_DOCUMENTS:
      return <KycDocumentView invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_AUTHORIZED_SIGN:
      return <AuthorizedSignatoryView invitation={ invitation } />;
    case SelfOnboardintSteps.STEP_MICRO_DEPOSIT:
      return <MicroDepositList invitation={ invitation } />;
    default:
      return null;
  }
};

export default SelfOnboardingMainView;
