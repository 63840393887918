import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TFilterData } from '@src/types/filter';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface ITextFieldFieldProps<TFilter extends TFilterData> extends
  Omit<React.ComponentPropsWithoutRef<'input'>, 'name' | 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  inputClassName?: string
  name: keyof TFilter,
}

const TextFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: ITextFieldFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string>(name);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    update(e.currentTarget.value);
  }, [update]);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const wrapperClasses = classNames(wrapperProps?.className, { filtered: value });
  const inputClasses = classNames('form-control', inputProps?.inputClassName);

  return (
    <Wrapper { ...wrapperProps } className={ wrapperClasses }>
      <input
        className={ inputClasses }
        type="text"
        value={ value || '' }
        onChange={ handleChange }
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default TextFilterField;
