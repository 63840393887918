import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import {
  activateDepositTrackingValidation,
  IActivateDepositTrackingValues,
} from './schema';

import styles from './styles.module.scss';

interface IAddRevenueSystemAdjustmentProps {
  formId: string,
  onSubmit: (data: IActivateDepositTrackingValues) => void;
}

const AddRevenueSystemAdjustment = ({
  formId,
  onSubmit,
}: IAddRevenueSystemAdjustmentProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IActivateDepositTrackingValues>({
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(activateDepositTrackingValidation),
  });

  const dateControl = useController({ control, name: 'startDate' });

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <p>
        To activate deposit tracking, please choose the most recent deposit reconciliation date.
      </p>
      <p className={ styles['activation-note'] }>
        Kindly note, all the deposits before the date you choose, will be automatically marked
        as processed. In order to make changes to the deposits processed, you will have to
        reopen reconciliation again.
      </p>
      <Form.DateField
        error={ errors.startDate?.message }
        label="Date"
        { ...omit(dateControl.field, 'ref') }
      />
    </Form>
  );
};

export default React.memo(AddRevenueSystemAdjustment);
