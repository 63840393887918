import React from 'react';

import aiChatLogoIcon from '@assets/images/ai_chatbot/ai_chat_logo_icon.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const AiChatLogoIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="AI Chat Logo icon"
      src={ aiChatLogoIcon }
      { ...props }
    />
  );
};

export default AiChatLogoIcon;
