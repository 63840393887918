import React, { useCallback } from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

interface IRelatedTransactionListItemProps {
  relatedTransaction: ITransactionServiceDocument,
}

const RelatedTransactionListItem = ({
  relatedTransaction,
}: IRelatedTransactionListItemProps): JSX.Element => {
  const handleDocumentClick = useCallback(() => {
    window.open(`/document/${relatedTransaction.docytId}`, '_blank');
  }, [relatedTransaction]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        className="in-blue-900"
        onClick={ handleDocumentClick }
      >
        { relatedTransaction.docytId }
      </Table.Cell>
      <Table.DateCell pointer date={ relatedTransaction.transactionDate } />
      <Table.Cell pointer>
        { relatedTransaction.description }
      </Table.Cell>
      <Table.AmountCell pointer amount={ relatedTransaction.amount } />
    </Table.Row>
  );
};

export default RelatedTransactionListItem;
