import React, { useCallback, useMemo } from 'react';

import { useAccountTypeCategories } from '@src/hooks/queries/account_types';
import { IAccountSubType } from '@src/types/account_types';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';
import IconSelectInput, { IIconSelectInputProps, TIconOption }
  from '@src/components/ui/form/inputs/react_select/icon_select_input';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IAccountTypeCategoryFieldProps extends
  Omit<
    IIconSelectInputProps,
    'isMulti' | 'options' | 'value' | 'onChange'
  >,
  Omit<IFormFieldWrapperProps, 'children'>
{
  subType?: IAccountSubType['subtypeCode']
  businessId: TID;
  value?: IChartOfAccount['id'],
  onChange: (value?: IChartOfAccount['id']) => void,
}

const chartOfAccountOption = (account: IChartOfAccount): TIconOption => {
  let icon;
  let label;

  if (account.qboId === '0' || account.qboStatus === 'invalid') {
    icon = <WarningIcon className="in-red-400" />;
  } else if (account.qboId !== null || account.qboStatus === 'synced') {
    icon = <QBOOnIcon />;
  } else {
    icon = <QBOOffIcon />;
  }

  if (account.parentId) {
    label = `${account.accType}: ${account.parentName} ► ${account.name}`;
  } else {
    label = `${account.accType}: ${account.name}`;
  }

  if (account.number) {
    label = `${account.number}: ${label}`;
  }

  return { icon, label, value: String(account.id) };
};

const AccountTypeCategoryField = ({
  businessId,
  error,
  hideLabel,
  hint,
  label,
  mark,
  subType,
  value,
  onChange,
  ...selectProps
}: IAccountTypeCategoryFieldProps): JSX.Element => {
  const query = useAccountTypeCategories({ businessId, subtypeCode: subType });
  const options = useMemo(() => {
    if (!query.data?.chartOfAccounts) return [];

    return query.data.chartOfAccounts.map(chartOfAccountOption);
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return options[0];

    return options.find((option) => option.value === String(value));
  }, [value, options]);

  const handleChange = useCallback((option: TIconOption | null) => {
    onChange(option ? Number(option.value) : undefined);
  }, [onChange]);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <IconSelectInput
        isSearchable
        isDisabled={ !subType }
        options={ options }
        widthPercent={ 100 }
        { ...selectProps }
        value={ selectedOption }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

AccountTypeCategoryField.displayName = 'AccountTypeCategoryField';

export default AccountTypeCategoryField;
