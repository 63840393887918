import React, { useCallback, useMemo } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { TID } from '@src/types/common';
import { IReport, TReportCategory } from '@src/types/report_service/report';
import { formatFullMonthYear } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import { PlusIcon } from '@src/components/utils/icomoon';

import Actions from '../actions/reports_list_header_actions';

import styles from '../styles.module.scss';

interface IBusinessReportListHeaderProps {
  businessId: TID,
  category: TReportCategory,
  reports: IReport[],
  canCreateNewReport: boolean,
  onCreateExportData: (value: boolean) => void,
  onOpenCreateNewReportModal: () => void,
}

const BusinessReportListHeader = ({
  businessId,
  category,
  reports,
  canCreateNewReport,
  onCreateExportData,
  onOpenCreateNewReportModal,
}: IBusinessReportListHeaderProps): JSX.Element => {
  const title = useMemo(() => (
    window.Docyt.Common.Constants.REPORT_CATEGORY_NAMES[category]
  ), [category]);
  const business = useBusinessContext();
  const { data: info } = useGetBusinessServicesInfo(business.id);

  const handleClick = useCallback(() => {
    if (canCreateNewReport) {
      onOpenCreateNewReportModal();
    } else {
      toastr.error(window.Docyt.Common.Constants.Messages.ALL_CUSTOM_REPORTS_CREATED, '');
    }
  }, [canCreateNewReport, onOpenCreateNewReportModal]);

  return (
    <DetailsRegion.Header
      divider="horizontal"
      title={ title }
    >
      { info?.lastReconciledMonthData && (
        <>
          <Text fontSize={ 18 }>Last Reconciled on : </Text>
          <Text className="m-r-15" fontSize={ 18 } inColor="grey-1050">{ formatFullMonthYear(info.lastReconciledMonthData.year, info.lastReconciledMonthData.month - 1) }</Text>
        </>
      )}

      { reports.length > 0 && category !== 'basic' && (
        <Button
          className={ styles['create-new-report-btn'] }
          variant="primary"
          onClick={ handleClick }
        >
          <PlusIcon fontSize={ 20 } />
        </Button>
      ) }
      
      { category !== 'basic' && (
        <Actions
          businessId={ businessId }
          category={ category }
          onCreateExportData={ onCreateExportData }
          reports={ reports || [] } 
        />
      ) }
    </DetailsRegion.Header>
  );
};

export default BusinessReportListHeader;
