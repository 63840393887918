import React from 'react';

import { useHelpModal } from '@src/components/accounts_payable/ach_transactions/help_modal';
import { achTypeValue } from '@src/components/accounts_payable/ach_transactions/utils';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import Wrapper from './details_filed_wrapper';

import styles from './styles.module.scss';

interface IDetailsAchTypeFiledProps {
  label: string,
  value?: string;
}

const DetailsAchTypeFiled = ({
  label,
  value,
}: IDetailsAchTypeFiledProps) => {
  const helpModal = useHelpModal();

  return (
    <Wrapper
      label={ label }
    >
      <div className={ styles['ach-type-value'] }>
        {
          value && (
            <>
              <span className={ styles['static-input'] }>
                { achTypeValue(value) }
              </span>
              <DocytSupportIcon onClick={ helpModal.open } />
            </>
          )
        }
      </div>
      <helpModal.Component
        { ...helpModal.props }
      />
    </Wrapper>
  );
};

export default React.memo(DetailsAchTypeFiled);
