import React from 'react';

import CreditCardPaymentMethodImg from '@assets/images/billing/credit_card.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const CreditCardPaymentMethodIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Credit Card Payment Method icon"
      src={ CreditCardPaymentMethodImg }
      { ...props }
    />
  );
};

export default CreditCardPaymentMethodIcon;
