import { useMutation } from 'react-query';

import {
  generatePlaidItemLinkToken,
  IGeneratePlaidItemLinkTokenParams,
  IGeneratePlaidItemLinkTokenResponse,
  IRetrievePlaidItemLinkTokenParams,
  IRetrievePlaidItemLinkTokenResponse,
  retrievePlaidItemLinkToken,
} from '@src/requests/plaid_items';

const useRetrievePlaidItemLinkToken = () => {
  return useMutation<
  IRetrievePlaidItemLinkTokenResponse,
    Error,
    IRetrievePlaidItemLinkTokenParams
  >(retrievePlaidItemLinkToken);
};

const useGeneratePlaidItemLinkToken = () => {
  return useMutation<
    IGeneratePlaidItemLinkTokenResponse,
    Error,
    IGeneratePlaidItemLinkTokenParams
  >(generatePlaidItemLinkToken);
};

export {
  useGeneratePlaidItemLinkToken,
  useRetrievePlaidItemLinkToken,
};
