import React from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

interface IActionsDropdownSubmenuProps
  extends React.ComponentPropsWithoutRef<typeof Dropdown.Submenu>
{
  /** Submenu icon. */
  icon?: JSX.Element,
  /**
   * Actions that will be shown in this submenu.
   * Should be `ActionsDropdown.Action` or `ActionsDropdown.LinkAction`.
   */
}

/**
 * Actions dropdown submenu that contains action items.
 * @visibleName ActionsDropdown.Submenu
 */
const ActionsDropdownSubmenu = ({
  icon,
  title,
  className,
  children,
  ...props
}: IActionsDropdownSubmenuProps): JSX.Element => {
  return (
    <Dropdown.Submenu
      icon={ icon }
      itemClassName={ className }
      title={ title }
      { ...props }
    >
      { children }
    </Dropdown.Submenu>
  );
};

export default ActionsDropdownSubmenu;
