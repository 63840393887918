import React from 'react';

import { IPracticeInformation } from '@src/types/practice_informations';

import Dropdown from '@src/components/ui/dropdown';
import { EllipsisIcon } from '@src/components/utils/fa_icons';

import ItemActions from './item_actions';

interface IMyPracticeListItemActionsProps {
  information: IPracticeInformation,
}

const MyPracticeListItemActions = ({
  information,
}: IMyPracticeListItemActionsProps): JSX.Element => {
  return (
    <Dropdown>
      <Dropdown.LinkToggle
        className="in-grey-800"
        fontSize={ 15 }
        fontVariant="bold"
        id={ `inbox-${information.id}-action-dropdown-btn` }
      >
        <EllipsisIcon className="m-l-5" variant="horizontal" />
      </Dropdown.LinkToggle>
      <Dropdown.Menu
        alignment="right"
        aria-labelledby={ `inbox-${information.id}-action-dropdown-btn` }
        className="toggle-action-dropdown"
        fontSize={ 24 }
      >
        <ItemActions information={ information } />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MyPracticeListItemActions;
