import React from 'react';

import { ISimpleUserInfo } from '@src/types/users';

import { UserAvatar } from '@src/components/ui/avatars';

import { ITableCellProps } from './cell';
import TextCell from './text_cell';

import styles from './styles.module.scss';

interface ITableUserCellProps extends ITableCellProps {
  user?: ISimpleUserInfo,
  emptyValue?: React.ReactNode,
}

const TableUserCell = ({
  user,
  emptyValue,
  ...props
}: ITableUserCellProps) => {
  if (!user) {
    return (
      <TextCell
        className={ styles['table-user-cell'] }
        { ...props }
      >
        { emptyValue }
      </TextCell>
    );
  }

  return (
    <TextCell
      className={ styles['table-user-cell'] }
      icon={ <UserAvatar avatar={ { avatarUrl: user.imageUrl || '' } } size={ 24 } /> }
      { ...props }
    >
      { user.parsedFullname }
    </TextCell>
  );
};

export default React.memo(TableUserCell);
