import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReconcilePaymentProcessorPath } from '@src/routes';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import PaymentProcessorIcon from '@src/components/utils/payment_processor_icon';

import { usePaymentProcessorContext } from '../../payment_processor_provider';
import { paymentProcessorsForRevenueReportTypeId } from '../../utils';

interface IRevenueCenterReconcileSideMenuProps {
  revenueSystem: IRevenueSystem,
}

const RevenueCenterReconcileSideMenu = ({
  revenueSystem,
}: IRevenueCenterReconcileSideMenuProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();
  const currentPaymentProcessor = usePaymentProcessorContext();
  const allPaymentProcessors = paymentProcessorsForRevenueReportTypeId({
    revenueReportTypeId: revenueSystem.revenueReportTypeId,
    revenueService,
  });
  if (allPaymentProcessors.length === 0) return <ServiceSidebar>{ null }</ServiceSidebar>;

  return (
    <ServiceSidebar>
      {
        allPaymentProcessors.map((paymentProcessor) => {
          return (
            <ServiceSidebar.Item
              key={ paymentProcessor.id }
              active={ currentPaymentProcessor.id === paymentProcessor.id }
              href={
                revenueServiceReconcilePaymentProcessorPath(
                  business.id,
                  revenueSystem.id,
                  paymentProcessor.id,
                  {},
                )
              }
              icon={ <PaymentProcessorIcon paymentProcessorCode={ paymentProcessor.code } /> }
              title={ paymentProcessor.name }
            />
          );
        })
      }
    </ServiceSidebar>
  );
};

export default RevenueCenterReconcileSideMenu;
