module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog add-receipt-modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title">Add Receipt</h4>\n      </div>\n      <div class="modal-body receipt-drop-zone">\n        <div class="select-files">\n          <p>Drag and drop a receipt here.<br/>OR</p>\n          <p><button type="button" class="modal-link-btn fileinput-button">Select from your computer<input type="file" name="files[]" multiple="" class="receipt-service-document-file-input"></button></p>\n        </div>\n        <div class="selected-files">\n          <div class="selected-files-list">\n          </div>\n          <button type="button" class="modal-link-btn remove-files">Clear</button>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id=\'cancel-upload\' class="cancel-link cancel modal-link-btn">Cancel</button>\n        <button type="button" id=\'confirm-upload\' class="main-btn">Upload Receipt</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}