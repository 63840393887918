import React, { FC } from 'react';

import styles from './styles.module.scss';

interface FooterProps {}

const FooterContainer: FC<FooterProps> = ({ children }) => {
  return (
    <div className={ styles.footer }>
      <div className={ styles['footer-content'] }>
        { children }
      </div>
    </div>
  );
};

export default FooterContainer;
