module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade split">\n  <div class="modal-dialog modal-lg relative chart-of-account-split-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="modal-title font-28 font-bold">\n          ');
    
      __out.push(__sanitize(this.is_category_readonly ? 'Category Detail' : 'Select Category'));
    
      __out.push('\n        </h1>\n      </div>\n      <div class="modal-body">\n        <div class="split-container-region"></div>\n      </div>\n      <div class=\'modal-footer\'>\n        ');
    
      if (!this.is_category_readonly) {
        __out.push('\n        <a class=\'cancel-link cancel pointer font-18\'>Cancel</a>\n        ');
      }
    
      __out.push('\n        <button type=\'button\' class="btn btn-info ladda-button save-js">');
    
      __out.push(__sanitize(this.is_category_readonly ? 'Close' : 'Save'));
    
      __out.push('</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}