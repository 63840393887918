import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBankStatement, TBankStatementsSortColumn } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';

import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IBankStatementsFilter } from './filter';
import Table from './pending_review_table';

import styles from '../styles.module.scss';

interface IBankStatementPendingReviewListProps {
  businessId: TID,
  filter: IFilter<IBankStatementsFilter>;
  bankStatements: IBankStatement[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBankStatementsSortColumn>,
  onUploadFile: () => void,
  onCloseModal: () => void,
}

const BankStatementPendingReviewList = ({
  businessId,
  filter,
  bankStatements,
  query,
  sorting,
  onUploadFile,
  onCloseModal,
}: IBankStatementPendingReviewListProps): JSX.Element => {
  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        breadcrumbs={ {
          title: 'Bank Statements',
        } }
        title="Pending Review"
      >
        <Button
          bsColor="blue"
          onClick={ onUploadFile }
        >
          <PlusIcon mr={ 10 } />
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10">
        <Filter
          filter={ filter }
        />
        <Table
          bankStatements={ bankStatements }
          businessId={ businessId }
          query={ query }
          sorting={ sorting }
          onCloseModal={ onCloseModal }
        />
      </div>
    </div>
  );
};

export default BankStatementPendingReviewList;
