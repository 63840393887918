import { string, number, object } from 'yup';

const forwardToBalanceSheetStatementsValidation = object({
  chartOfAccountId: number().label('Account').required(),
  date:             string().required('Statement Date is required.'),
});

export {
  forwardToBalanceSheetStatementsValidation,
};
