import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const BankStatementUploaded = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="bank-statement-uploaded"
      pathsNumber={ 3 }
    />
  );
});

BankStatementUploaded.displayName = 'BankStatementUploaded';

export default BankStatementUploaded;
