import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';

import StateItem from './bank_statement_state_item';

interface IBankStatementStateListProps {
  states: IReviewedBankStatementState[],
}

const BankStatementStateList = ({
  states,
}: IBankStatementStateListProps): JSX.Element => {
  return (
    <>
      {
        states.map((state) => {
          return (
            <StateItem
              key={ state.month }
              state={ state }
            />
          );
        })
      }
    </>
  );
};

export default BankStatementStateList;
