import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getWidgets,
  IGetWidgetsParams,
  IGetWidgetsResponse,
} from '@src/requests/dashboards/widgets';

const useGetWidgets = (params: IGetWidgetsParams) => {
  return useQuery<IGetWidgetsResponse, Error>(
    [QueryKey.dashboardWidgets, params],
    () => getWidgets(params),
  );
};

export {
  useGetWidgets,
};
