module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, len;
    
      __out.push('<div class="setup-business-step-section m-t-50">\n  <div class="main-panel">\n    <h2 class="panel-title">Additional Info</h2>\n    <p class="panel-description">\n      To be in compliance with federal laws, some additional information about your business is needed.\n    </p>\n    <div class="text-left profile-content-wrapper font-14">\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Legal Entity Name</label>\n        <label class="width-60-percent text-left in-grey-1050">');
    
      __out.push(__sanitize(this.dwollaCustomer.get('business_name')));
    
      __out.push('</label>\n      </div>\n      <div class="profile-input-wrap m-t-20">\n        <label class="width-40-percent text-left">Legal Entity Type</label>\n        <select class="form-control width-60-percent input-entity-type">\n          ');
    
      if (this.businessType === "") {
        __out.push('\n          <option></option>\n          ');
      }
    
      __out.push('\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["SOLE"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Sole Proprietorship</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["CORP"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["CORP"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Corporation</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["LLC"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["LLC"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>LLC</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["PARTNER"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["PARTNER"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Partnership</option>\n        </select>\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Address</label>\n        <input class="input-address-street form-control width-60-percent" placeholder="Address Line 1" type="text" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_line1')));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent"></label>\n        <div class="address-wrapper width-60-percent">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-address-city form-control" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_city')));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap p-r-5">\n            <select class="input-address-state form-control">\n            ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n              <option value="');
        __out.push(__sanitize(address_state.abbreviation));
        __out.push('" ');
        if (this.w9_address.get('address_state') === address_state.abbreviation) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(address_state.text));
        __out.push('</option>\n            ');
      }
    
      __out.push('\n            </select>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-address-zip form-control" type="text" placeholder="Zip" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_zip')));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="label-tax-id width-40-percent text-left">Taxpayer Identification Number (TIN)</label>\n        <input class="input-tax-id form-control width-60-percent" type="text" placeholder="" value="');
    
      __out.push(__sanitize(this.taxId));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning in-red-400"></i></p>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}