module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, j, len, len1, ref, ref1, report_type, revenueSystem;
    
      __out.push('<div class="document-fields p-t-15 p-l-15 p-r-15 hide-on-subview">\n  ');
    
      if (this.revenueSystemEnabled) {
        __out.push('\n    <div class="document-field-item document-field-name">Revenue System</div>\n    ');
        if (this.selectedRevenueSystem) {
          __out.push('\n      <div class="document-field-item document-field-value selected-revenue-report-type">\n        ');
          __out.push(__sanitize(this.getRevenueSystemName(this.selectedRevenueSystem)));
          __out.push('\n      </div>\n    ');
        } else {
          __out.push('\n      <div class="document-field-item document-field-value revenue-report-type-field-value">\n        <select id="select-revenue-system" class="selectpicker" title="Select Revenue System">\n          ');
          ref = this.activeSystems;
          for (index = i = 0, len = ref.length; i < len; index = ++i) {
            revenueSystem = ref[index];
            __out.push('\n            <option class="revenue-system-option" value="');
            __out.push(__sanitize(revenueSystem.id));
            __out.push('" ');
            if (this.revenueSystemId === revenueSystem.id) {
              __out.push('selected="selected"');
            }
            __out.push('>');
            __out.push(__sanitize(this.getRevenueSystemName(revenueSystem)));
            __out.push('</option>\n          ');
          }
          __out.push('\n        </select>\n      </div>\n    ');
        }
        __out.push('\n    ');
        if (this.selected_report_type) {
          __out.push('\n    <div class="document-field-item document-field-name">Revenue Mapping</div>\n      <div class="document-field-item document-field-value selected-revenue-report-type">\n        ');
          __out.push(__sanitize(this.selected_report_type.name));
          __out.push('\n      </div>\n    </div>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    <div class="document-field-item document-field-name">Revenue Mapping</div>\n    ');
        if (this.selected_report_type) {
          __out.push('\n      <div class="document-field-item document-field-value selected-revenue-report-type">\n        ');
          __out.push(__sanitize(this.selected_report_type.name));
          __out.push('\n      </div>\n    ');
        } else {
          __out.push('\n      <div class="document-field-item document-field-value revenue-report-type-field-value">\n        <select id="select-report-type" class="selectpicker" title="Select Revenue Mapping">\n          ');
          ref1 = this.active_types;
          for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
            report_type = ref1[index];
            __out.push('\n          <option value="');
            __out.push(__sanitize(report_type.id));
            __out.push('" ');
            if (this.revenue_report_type_id === report_type.id) {
              __out.push('selected="selected"');
            }
            __out.push('>');
            __out.push(__sanitize(report_type.name));
            __out.push('</option>\n          ');
          }
          __out.push('\n        </select>\n      </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  <div style="clear: both;"></div>\n  <hr />\n</div>\n<div class="revenue-fields-region">\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}