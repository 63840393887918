import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableService } from '@src/types/services';

import Table from '@src/components/ui/table';

import Item from './settlements_table_item';

interface ISettlementsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  settlements: IAccountsPayableServiceDocument[],
  accountsPayableServices?: IAccountsPayableService,
}

const SettlementsTable = ({
  query,
  settlements,
  accountsPayableServices,
}: ISettlementsTableProps) => {
  return (
    <Table
      className="banking-transactions-table"
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.SelectAllHCell />
          <Table.HCell
            widthPercent={ 20 }
          >
            Business
          </Table.HCell>
          <Table.HCell
            widthPercent={ 20 }
          >
            Vendor
          </Table.HCell>
          <Table.HCell
            widthPercent={ 3 }
          />
          <Table.HCell
            widthPercent={ 15 }
          >
            Invoice #
          </Table.HCell>
          <Table.HCell
            widthPercent={ 10 }
          >
            Bill Date
          </Table.HCell>
          <Table.HCell
            widthPercent={ 10 }
          >
            Payment Date
          </Table.HCell>
          <Table.HCell
            textAlign="right"
            widthPercent={ 12 }
          >
            Amount
          </Table.HCell>
          <Table.HCell
            className="chat"
            textAlign="right"
            widthPercent={ 3 }
          />
          <Table.HCell
            className="actions-column"
            textAlign="right"
            widthPercent={ 3 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          settlements.map((settlement) => (
            <Item
              key={ settlement.id }
              accountsPayableServices={ accountsPayableServices }
              settlement={ settlement }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(SettlementsTable);
