import React, { useCallback, useState } from 'react';

import {
  getManagementGroupDashboard,
} from '@src/requests/dashboards/management_group_dashboards';
import { managementGroupDashboardWidgetPath } from '@src/routes';
import { IBusinessUser } from '@src/types/business_users';
import { TMongoID } from '@src/types/common';
import { IManagementGroupDashboard } from '@src/types/dashboards/dashboards';

import ShareAction from '@src/components/business_dashboards/actions/share_action';
import { ErrorNotification } from '@src/components/ui/notification';
import { TOption } from '@src/components/ui_v2/inputs';
import { ShareDashboardIcon } from '@src/components/utils/icomoon';

import BusinessesDropdown from './businesses_dropdown';
import ManagementGroupDashboardField from './management_group_dashobard_input';

import styles from './styles.module.scss';

interface IDashboardDetailsHeaderProps {
  dashboard: IManagementGroupDashboard,
  onShareDone: (accessibleUsers: IBusinessUser[]) => void,
  options: TOption[],
  selectedOptions: TOption[],
  onChange: (value: TOption[]) => void,
}

const DashboardDetailsHeader = ({
  dashboard,
  onShareDone,
  options,
  selectedOptions,
  onChange,
}: IDashboardDetailsHeaderProps): JSX.Element => {
  const [managementGroupDashboard, setManagementGroupDashboard] =
    useState<IManagementGroupDashboard>(dashboard);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const handleManagementGroupChange = useCallback((value?: TMongoID) => {
    if (!value) return;

    getManagementGroupDashboard({
      id: value,
    }).then((res) => {
      setManagementGroupDashboard(res.dashboard);

      const dashboardId = res.dashboard.id;
      let widgetId;
      if (res.dashboard.widgetTemplates.length > 0) widgetId = res.dashboard.widgetTemplates[0].id;
      Backbone.history.navigate(
        managementGroupDashboardWidgetPath(dashboardId, widgetId),
        { trigger: true },
      );
    }).catch((error) => {
      setErrorMsg(error.message);
    });
  }, []);

  return (
    <div className={ styles['details-header'] }>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <ManagementGroupDashboardField
        className={ styles['management-group-filed'] }
        dashboard={ managementGroupDashboard }
        onChange={ handleManagementGroupChange }
      />
      <div className={ styles['right-wrapper'] }>
        {
          options.length > 0
          && (
            <BusinessesDropdown
              options={ options }
              value={ selectedOptions }
              onChange={ onChange }
            />
          )
        }
        <ShareAction
          currentDashboard={ managementGroupDashboard }
          managementGroupId={ managementGroupDashboard.managementGroupId }
          prefixIcon={ <ShareDashboardIcon fontSize={ 20 } /> }
          onDone={ onShareDone }
        />
      </div>
    </div>
  );
};

export default DashboardDetailsHeader;
