module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var bankAccount, i, len, ref;
    
      __out.push('<div class="accounts-receivable-settings-detail left-side-region">\n  ');
    
      if (this.askBankAccountSetupNeeded) {
        __out.push('\n    <div class="accounts-receivable-container">\n      <img src="');
        __out.push(__sanitize(configData.receivable_images.GET_STARTED));
        __out.push('" class="pull-left activation-image m-t-60" />\n      <div class="activation-home-content-region">\n        <h2 class="font-26 font-semi-bold m-b-15">Get paid from your customers in a faster, more secure way</h2>\n        <p class="font-16 m-b-30">\n          Docyt\'s Accounts Receivable lets you keep track of all the payments you receive from your customers. View reports on you received payments and chat directly with your customers.\n        </p>\n        <a class="btn btn-blue btn-activate">Get Started</a>\n      </div>\n      <table>\n        <tbody>\n        </tbody>\n      </table>\n    </div>\n  ');
      } else {
        __out.push('\n    ');
        if (this.fundingSource.get('is_verified')) {
          __out.push('\n      <div class="display-flex align-items-center border-bottom-gray p-b-25">\n        <span class="font-26 font-bold">Bank Account</span>\n        <a class="m-l-auto m-r-0 font-16 font-semi-bold in-red-600 disconnect-js pointer">Disconnect Bank Account</a>\n      </div>\n      <div class="m-t-25">\n        Your bank account is connected. This will be used to receive payments through ACH.\n      </div>\n      <div class="m-t-10">\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Bank: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.get('name')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Account #: </span>\n          <span>**** **** **** ');
          __out.push(__sanitize(this.fundingSource.get('account_last4')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Routing #: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.get('routing_number')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Status: </span>\n          <span class="in-green-600 font-bold">Verified</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Connected since: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.connectedSince()));
          __out.push('</span>\n        </p>\n      </div>\n    ');
        } else if (this.dwollaCustomer.get('has_funding_source')) {
          __out.push('\n      <div class="display-flex align-items-center">\n        <span class="font-26 font-bold">Bank Account</span>\n      </div>\n      <div class="border-bottom-gray p-b-20">\n        Your bank account needs to be connected to be able to receive payments.\n      </div>\n      <div class="m-t-25">\n        You bank account is connected but is pending verification.\n      </div>\n      <div class="m-t-10 p-b-20 border-bottom-gray">\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Bank: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.get('name')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Account #: </span>\n          <span>**** **** **** ');
          __out.push(__sanitize(this.fundingSource.get('account_last4')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Routing #: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.get('routing_number')));
          __out.push('</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Status: </span>\n          <span class="font-bold in-orange-200">Unverified</span>\n        </p>\n        <p>\n          <span class="font-bold width-200px display-inline-flex">Connected since: </span>\n          <span>');
          __out.push(__sanitize(this.fundingSource.connectedSince()));
          __out.push('</span>\n        </p>\n      </div>\n      <div class="m-t-20">\n        We have sent two small fractional dollar transactions to your bank account. It takes about a day for these transactions to appear in your account. Enter the values for these two transactions below.\n      </div>\n      <div class="display-flex m-t-20">\n        <input type="number" class="form-control amount-first text-center width-260px" placeholder="0.00">\n        <input type="number" class="form-control amount-second text-center width-260px m-l-20" placeholder="0.00">\n      </div>\n      <div class="font-12 display-flex align-items-center m-t-5 in-red-600 warning-wrapper">\n        <span class="icon-warning-triangle"></span>\n        <span class="inline-block nav-item-text">The entered amounts could not be verified. Please make sure you entered the correct amounts.</span>\n      </div>\n      <div class="display-flex align-items-center width-100-percent m-t-20">\n        <button class="btn btn-blue font-semi-bold verify-trans-js m-t-auto"> Verify Transactions </button>\n        <div class="m-l-auto">\n          <a class="font-bold pointer disconnect-js">Exit manual connection</a>\n        </div>\n      </div>\n    ');
        } else {
          __out.push('\n      <div class="display-flex align-items-center">\n        <span class="font-26 font-bold">Verify Bank Account</span>\n      </div>\n      <div class="border-bottom-gray p-b-20">\n        <p class="font-16">\n          Verify your bank account to start receiving payments through ACH.\n        </p>\n      </div>\n      <div class="bank-connection-container font-16">\n        <div class="display-flex">\n          <div class="m-t-10 flex-container"> Bank Account </div>\n          <div class="bank-account-picker flex-container m-l-auto">\n            <div class="permission-dropdown-wrapper">\n              <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n                <span class="bank-account-label-js">Select Bank Account</span>\n                <span class="fa fa-caret-down"></span>\n              </div>\n              <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n                <div class="bank-account-list">\n                ');
          ref = this.bizBankAccounts;
          for (i = 0, len = ref.length; i < len; i++) {
            bankAccount = ref[i];
            __out.push('\n                <li class="bank-account-item-js" data-value=');
            __out.push(__sanitize(bankAccount.get('document_id')));
            __out.push('>\n                  <div class=\'display-flex align-items-center\'>\n                    <span class=\'icon icon-bank-account\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span></span>\n                    <span class=\'text m-t-3 m-l-10 bank-name-label-js\'>');
            __out.push(__sanitize(bankAccount.get('display_name')));
            __out.push('</span>\n                  </div>\n                </li>\n                ');
          }
          __out.push('\n                </div>\n                <li class="add-new-bank-account">\n                  <a href="');
          __out.push(__sanitize(this.bankAccountURL));
          __out.push('">\n                    <div class="in-blue-950" tabindex="-1">\n                      <span class=\'text m-t-3 m-l-10\'>Add New Bank Account</span>\n                    </div>\n                  </a>\n                </li>\n              </ul>\n            </div>\n            <div class="font-10 m-t-8">\n              <a class="in-green-700" href="">\n                <span class="icon-lockedalt"></span>\n                <span class="inline-block nav-item-text">All connections are encrypted</span>\n              </a>\n            </div>\n          </div>\n        </div>\n        <div class="bank-verification-container font-16 font-semi-bold">\n          <a class="pointer secure-btn display-flex align-items-center verify-auto-btn">\n            <span class="icon-bank-circle-o icon-title vertical-align-middle font-35 m-l-auto m-r-10">\n              <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span>\n            </span>\n            <span class="m-r-auto">Verify instantly</span>\n          </a>\n          <a class="pointer verify-manual-btn">\n            Verify manually\n          </a>\n        </div>\n      </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</div>\n<div id="bank-right-side-region" class="bank-right-side-region"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}