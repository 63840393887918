import React from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReportPath } from '@src/routes';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

interface IMenuActionProps {
  report: IRevenueServiceDocument;
}

const MenuAction = ({
  report,
}: IMenuActionProps) => {
  const revenueService = useRevenueServiceContext();

  return (
    <ActionsDropdown.LinkAction
      href={ revenueServiceReportPath(revenueService.businessId, report.id) }
      title="View Report"
    />
  );
};

export default MenuAction;
