import React from 'react';

import styles from './styles.module.scss';

interface IBottomModalFooterProps {
  children: React.ReactNode,
}

const BottomModalFooter = ({
  children,
}: IBottomModalFooterProps): JSX.Element => {
  return (
    <div className={ styles['bottom-modal-footer'] }>
      { children }
    </div>
  );
};

export default BottomModalFooter;
