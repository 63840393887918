import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

interface IBusinessMetricDimensionFilterProps {
  hideInactive: boolean,
  onHideInactiveChanged: (checked: boolean) => void,
}

const BusinessMetricDimensionFilter = ({
  hideInactive,
  onHideInactiveChanged,
}: IBusinessMetricDimensionFilterProps): JSX.Element => {
  return (
    <>
      <Filter.TableContainer>
        <span />
        { /* TODO: Put filter components inside */ }
      </Filter.TableContainer>
      <CheckboxInput
        small
        checked={ hideInactive }
        title="Hide Inactive employees"
        onChange={ onHideInactiveChanged }
      />
    </>
  );
};

export default React.memo(BusinessMetricDimensionFilter);
