import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo } from 'react';

import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

import { TSection } from '@src/types/common';

import { useSection } from '@src/components/utils_v2/section';

import { tableColumns, tableColumnsWithHidden } from './atoms';
import { TColumn, TModel } from './types';
import { mapChildrenToColumns } from './utils';

const MAX_COLUMN_COUNT = 99;

type IUseCollectionColumnsState<Model extends TModel, TSortColumn extends string = never> =
  [
    TColumn<Model, TSortColumn>[],
    Dispatch<SetStateAction<TColumn<Model, TSortColumn>[]>>,
  ];

const useCollectionColumnsState = <Model extends TModel, TSortColumn extends string = never>(
  children: ReactElement<TColumn<Model, TSortColumn>>
    | ReactElement<TColumn<Model, TSortColumn>>[],
  sectionParam?: TSection,
  defaultColumnCount?: number,
): IUseCollectionColumnsState<Model, TSortColumn> => {
  const section = useSection(sectionParam);

  const setColumns = useSetRecoilState(tableColumns(section));
  const [columns, setColumnsWithHidden] = useRecoilState(tableColumnsWithHidden(section));

  useEffect(() => {
    let columnCount = defaultColumnCount || MAX_COLUMN_COUNT;
    setColumns(mapChildrenToColumns(children) as TColumn<TModel, string>[]);
    setColumnsWithHidden((oldColumns) => {
      return oldColumns.map((c) => {
        if (columnCount > 0) {
          if (!c.hidden) columnCount -= 1;
          return c;
        }

        return {
          ...c,
          hidden: true,
        };
      });
    });
  }, [children, setColumns, setColumnsWithHidden, defaultColumnCount]);

  return [
    columns as TColumn<Model, TSortColumn>[],
    setColumnsWithHidden as Dispatch<SetStateAction<TColumn<Model, TSortColumn>[]>>,
  ];
};

const useVisibleColumnNames = () => {
  const section = useSection();
  const columns = useRecoilValue(tableColumnsWithHidden(section));
  return useMemo(() => {
    return columns.filter((c) => !c.hidden).map((c) => c.name);
  }, [columns]);
};

export {
  useCollectionColumnsState,
  useVisibleColumnNames,
};
