import React, { useCallback, useState } from 'react';

import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import RemoveFromMyVendorsAction
  from '@src/components/vendors/details/list_item/docyt_network_item_actions/remove_from_my_vendors_action';

import AddToMyVendorsAction from './add_to_my_vendors_action';
import DocytNetworkItemViewProfileAction from './view_profile_action';

interface IDocytNetworkItemActionsProps {
  vendor: IGlobalVendorWithBusinessInfo,
}

const DocytNetworkItemActions = ({ vendor }: IDocytNetworkItemActionsProps): JSX.Element => {
  const [addedToBusiness, setAddedToBusiness] = useState(vendor.addedToBusiness);

  const handleFinishedToAdd = useCallback(() => {
    setAddedToBusiness(true);
  }, []);
  const handleRemovedFromMyBusiness = useCallback(() => {
    setAddedToBusiness(false);
  }, []);

  return (
    <>
      {
        !addedToBusiness
        && (
          <AddToMyVendorsAction vendors={ [vendor] } onFinished={ handleFinishedToAdd } />
        )
      }
      <DocytNetworkItemViewProfileAction vendor={ vendor } />
      {
        addedToBusiness
        && (
          <RemoveFromMyVendorsAction
            vendorId={ vendor.id }
            onFinished={ handleRemovedFromMyBusiness }
          />
        )
      }
    </>
  );
};

export default DocytNetworkItemActions;
