import { createContext, useContext } from 'react';

import { IAccountsPayableService } from '@src/types/services';

const AccountsPayableServiceContext = createContext<IAccountsPayableService | null>(null);

const useAccountsPayableServiceContext = (): IAccountsPayableService => {
  const value = useContext(AccountsPayableServiceContext);

  if (!value) {
    throw new Error('"useAccountsPayableServiceContext" must be used within <AccountsPayableServiceProvider />');
  }

  return value;
};

export {
  AccountsPayableServiceContext,
  useAccountsPayableServiceContext,
};
