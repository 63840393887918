import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TReviewedStatementSortColumn, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';

import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IReviewedStatementViewFilter } from './filter';
import Table from './table';

import styles from '../styles.module.scss';

interface IReviewedStatementsListProps {
  businessId: TID,
  filter: IFilter<IReviewedStatementViewFilter>;
  reviewedBankStatements: IReviewedBankStatementsData[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementSortColumn>,
  onUploadFile: () => void,
}

const ReviewedStatementsList = ({
  businessId,
  filter,
  reviewedBankStatements,
  query,
  sorting,
  onUploadFile,
}: IReviewedStatementsListProps): JSX.Element => {
  const bankStatements = useMemo(() => {
    switch (sorting.data.orderColumn) {
      case 'banking_account_name':
        if (sorting.data.orderDirection === 'asc') {
          return reviewedBankStatements.sort(
            (a, b) => ((a.name > b.name) ? 1 : -1),
          );
        }
        return reviewedBankStatements.sort(
          (a, b) => ((a.name > b.name) ? -1 : 1),
        );
      case 'current_balance':
        if (sorting.data.orderDirection === 'asc') {
          return reviewedBankStatements.sort(
            (a: any, b: any) => a.currentBalance - b.currentBalance,
          );
        }
        return reviewedBankStatements.sort(
          (a: any, b: any) => b.currentBalance - a.currentBalance,
        );
      case 'missing_statements':
        if (sorting.data.orderDirection === 'asc') {
          return reviewedBankStatements.sort(
            (a: any, b: any) => a.missingStatementsCount - b.missingStatementsCount,
          );
        }
        return reviewedBankStatements.sort(
          (a: any, b: any) => b.missingStatementsCount - a.missingStatementsCount,
        );
      default:
        return reviewedBankStatements;
    }
  }, [reviewedBankStatements, sorting.data.orderColumn, sorting.data.orderDirection]);

  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        breadcrumbs={ {
          title: 'Bank Statements',
        } }
        title="Reviewed"
      >
        <Button
          bsColor="blue"
          onClick={ onUploadFile }
        >
          <PlusIcon mr={ 10 } />
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10">
        <Filter
          filter={ filter }
        />
        <Table
          businessId={ businessId }
          query={ query }
          reviewedBankStatements={ bankStatements }
          sorting={ sorting }
          year={ filter.data?.year }
        />
      </div>
    </div>
  );
};

export default ReviewedStatementsList;
