import React, { useCallback } from 'react';

import { useUnlinkDocumentToRequest } from '@src/hooks/queries/document_requests';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { DocumentRequestIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IBusinessInboxItemUnlinkDocumentRequestActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemUnlinkDocumentRequestAction = ({
  document,
}: IBusinessInboxItemUnlinkDocumentRequestActionProps) => {
  const unlinkDocumentRequest = useUnlinkDocumentToRequest();

  const handleUnlink = useCallback(() => {
    if (!document.documentRequestId) return; // Nothing to unlink

    unlinkDocumentRequest.mutate({
      documentId:        document.id,
      documentRequestId: document.documentRequestId,
    });
  }, [document, unlinkDocumentRequest]);

  return (
    <>
      <MutationStatus mutation={ unlinkDocumentRequest } />
      <ActionsDropdown.Action
        icon={ <DocumentRequestIcon fontSize={ 22 } /> }
        name="Unlink from Document Request"
        onClick={ handleUnlink }
      />
    </>
  );
};

export default BusinessInboxItemUnlinkDocumentRequestAction;
