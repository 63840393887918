/* eslint-disable eqeqeq */
import React from 'react';

import { businessInboxPath, clientVaultPath, vaultPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  focusToMyClient: boolean,
  hasVaultServiceRole: boolean,
  sunsetVaultFeatureFlagEnabled: boolean,
  info: IBusinessServicesInfo,
}

const DocumentsItem = ({
  businessId,
  activeItem,
  hasVaultServiceRole,
  focusToMyClient,
  sunsetVaultFeatureFlagEnabled,
  info,
}: IServiceMenuProps) => {
  const canVisibleInboxService = info.isBusinessAdmin || info.canAccessInboxService;
  if (!canVisibleInboxService && !hasVaultServiceRole) return null;

  let vaultUrl;
  if (focusToMyClient) {
    vaultUrl = info?.accountingFirmBusinessId
      ? clientVaultPath(businessId, info?.accountingFirmBusinessId)
      : '';
  } else {
    vaultUrl = vaultPath(businessId);
  }

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.MAILROOM
        || activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DOCUMENTS }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DOCUMENTS }
    >
      { canVisibleInboxService && (
        <ServiceItem
          serviceUrl={ businessInboxPath(businessId) }
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM }
        />
      ) }
      { (!sunsetVaultFeatureFlagEnabled && hasVaultServiceRole) && (
        <ServiceItem
          serviceUrl={ vaultUrl }
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.VAULT }
        />
      ) }
    </ModuleHeaderMenu>
  );
};

export default DocumentsItem;
