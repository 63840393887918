import React, { useCallback, useMemo } from 'react';

import { useGetRevenueReportTypes } from '@src/hooks/queries/revenue_report_types';
import { revenueServiceSettingsReportTypesPath } from '@src/routes';
import { TID } from '@src/types/common';

import { AppLinkButton } from '@src/components/ui_v2/buttons';
import {
  ISelectInputProps,
  SelectInput,
} from '@src/components/ui_v2/inputs';
import { PlusIcon } from '@src/components/utils/icomoon';

interface IRevenueReportTypeInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  value?: TID | null,
  onChange?: (value?: TID | null) => void,
}

const CreateNewReportLink = ({ businessId }: { businessId: TID }): JSX.Element => {
  return (
    <AppLinkButton
      newWindow
      href={ revenueServiceSettingsReportTypesPath(businessId) }
      prefixIcon={ <PlusIcon fontSize={ 16 } variant="o" /> }
      variant="link"
    >
      Create New Revenue Mapping
    </AppLinkButton>
  );
};

const RevenueReportTypeInput = ({
  businessId,
  value,
  onChange,
  ...props
}: IRevenueReportTypeInputProps): JSX.Element => {
  const query = useGetRevenueReportTypes({ businessId, onlyActive: true });

  const handleChange = useCallback((newValue) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null);
  }, [onChange]);

  const options = useMemo(() => {
    return query.data?.collection?.map((rrt) => ({
      label: rrt.name,
      value: String(rrt.id),
    })) || [];
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return options.find((rrt) => rrt.value === String(value)) || null;
  }, [value, options]);

  return (
    <SelectInput
      { ...props }
      menuFooter={ <CreateNewReportLink businessId={ businessId } /> }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(RevenueReportTypeInput);
