import React, { useCallback } from 'react';

import { TAccountType, TID } from '@src/types/common';

import {
  IFormFieldWrapperProps,
} from '@src/components/ui/form';

import { useSelectAccount } from './account_selector';
import AccountEditField from './accounts_edit_field';
import AccountSelectField from './accounts_select_field';
import { IAccountData } from './schema';

import styles from '../../styles.module.scss';

interface IBankAccountsFieldProps extends
  Omit<IFormFieldWrapperProps, 'children'>
{
  value?: IAccountData,
  accountType: TAccountType,
  businessId?: TID,
  isEditable?: boolean,
  error?: string,
  onChange: (value: IAccountData) => void,
}

const AccountsField = ({
  value,
  accountType,
  businessId,
  isEditable = true,
  error,
  onChange,
}: IBankAccountsFieldProps) => {
  const handleSelectorDone = useCallback((account: IAccountData) => {
    onChange({
      name:  account.name,
      id:    account.id,
      last4: account.last4,
    });
  }, [onChange]);

  const selector = useSelectAccount({ onDone: handleSelectorDone });

  return (
    <>
      <selector.Component
        { ...selector.props }
        accountType={ accountType }
        businessId={ businessId }
      />
      <div className={ styles['bank-accounts-field'] }>
        <div className={ styles['value-container'] }>
          <span className="m-b-10 m-t-10">
            {`Select ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}`}
          </span>
          {
            value?.id
              ? (
                <AccountEditField
                  isEditable={ isEditable }
                  value={ value }
                  onEdit={ selector.open }
                />
              )
              : (
                <AccountSelectField
                  error={ error }
                  onSelect={ selector.open }
                />
              )
          }
        </div>
      </div>
    </>
  );
};

export default AccountsField;
