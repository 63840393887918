import { useMemo } from 'react';

import { flatten } from 'lodash';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetVendorPayments } from '@src/hooks/queries/vendor_payments';
import { useSorting } from '@src/hooks/url_params';
import { IVendorPaymentsResponse } from '@src/requests/vendor_payments';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { IVendorPayment, TVendorPaymentsSortColumn, IVendorPaymentsFilter, IVendorPaymentsQueryFilter } from '@src/types/vendor_payments';
import { dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IVendorPaymentsCollectionParams {
  businessVendor: IBusinessVendor,
}

interface IVendorPaymentsCollection {
  query: UseInfiniteQueryResult<IVendorPaymentsResponse, Error>;
  records: IVendorPayment[];
  section: TSection;
  sorting: ISorting<TVendorPaymentsSortColumn>;
  selectedRecord?: IVendorPayment;
  refetch: () => void;
}

const defaultSorting: ISortingParams<TVendorPaymentsSortColumn> = {
  orderColumn:    'payment_date',
  orderDirection: 'desc',
};

const filterToQuery = (
  filterData: IVendorPaymentsFilter | undefined,
): IVendorPaymentsQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, ['payment_date', 'document_date']);

  return {
    ...query,
    ...dateFilterToQuery(filterData.payment_date, 'payment_date') || {},
    ...dateFilterToQuery(filterData.document_date, 'document_date') || {},
  };
};

const useVendorPaymentsCollection = ({
  businessVendor,
}: IVendorPaymentsCollectionParams): IVendorPaymentsCollection => {
  const section = useMemo(() => {
    return {
      businessId: businessVendor.id,
      section:    'vendor_payments',
    };
  }, [businessVendor.id]);

  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => filterToQuery(filterData), [filterData]);

  const sorting = useSorting<TVendorPaymentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetVendorPayments({
    vendorId:   businessVendor.id,
    businessId: businessVendor.businessId,
    filters:    filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecord = useMemo(() => {
    if (selectedIds.length === 0) return undefined;

    return records.find((r) => r.id === selectedIds[0]);
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecord,
    refetch: query.refetch,
  };
};

export {
  IVendorPaymentsCollection,
  useVendorPaymentsCollection,
};
