import { useQuery } from 'react-query';

import {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
} from '@src/requests/document_field';

const DOCUMENT_FIELDS_QUERY = 'document_fields';

const useGetDocumentFields = (params: IGetDocumentFieldsParams) => {
  return useQuery<IGetDocumentFieldsResponse, Error>(
    [DOCUMENT_FIELDS_QUERY, params],
    () => getDocumentFields(params),
  );
};

export {
  useGetDocumentFields,
};
