import { TID } from '@src/types/common';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IReportServiceQBOLineItemDetailsParams {
  reportId: string,
  itemIdentifier: string,
  from: string,
  to: string,
  chartOfAccountId: TID,
  accountingClassId?: TID | null,
}

interface IReportServiceQBOLineItemDetailsResponse {
  meta: {
    totalCount: number,
  },
  collection: IReportServiceQBOLineItemDetails[]
}

const getReportServiceQBOLineItemDetails = (
  params: IReportServiceQBOLineItemDetailsParams,
): Promise<IReportServiceQBOLineItemDetailsResponse> => {
  return apiGet(
    '/reports/api/v1/quickbooks/line_item_details/by_period',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta: {
        totalCount: cdata.total,
      },
      collection: cdata.lineItemDetails as IReportServiceQBOLineItemDetails[],
    };
  });
};

export {
  IReportServiceQBOLineItemDetailsParams,
  IReportServiceQBOLineItemDetailsResponse,
  getReportServiceQBOLineItemDetails,
};
