import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IReport } from '@src/types/report_service/report';

import Modal from '@src/components/ui/modal';

import Form from '../forms/rename_report';

interface IRenameReportModalProps extends IUseModalProps {
  report: IReport,
}

const RenameReportModal = ({
  report,
  isOpen,
  onCancel,
  onDone,
}: IRenameReportModalProps) => {
  return (
    <Modal.Form
      proceedTitle="Save"
      show={ isOpen }
      title="Rename Report"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          formId={ formId }
          report={ report }
          onSubmit={ onDone }
        />
      )}
    </Modal.Form>
  );
};

const useRenameReportModal = makeUseModal(RenameReportModal);

export {
  IRenameReportModalProps,
  useRenameReportModal,
  RenameReportModal as default,
};
