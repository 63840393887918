import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetReportServicesByBusinessId, useUpdateMultiEntityReport } from '@src/hooks/queries/multi_entity_reports';
import { useGetReportAvailableBusinesses } from '@src/hooks/queries/report_service/custom_reports';
import { IGetReportServicesByBusinessIdResponse } from '@src/requests/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from '../forms/edit_business';
import { IUpdateBusinessFormValues } from '../forms/schema';

interface IEditBusinessModalProps extends IUseModalProps {
  action: string,
  item: IMultiBusinessReportDetail,
  businesses: IBusiness[],
}

const EditBusinessModal = ({
  action,
  businesses,
  item,
  isOpen,
  onCancel,
  onDone,
}: IEditBusinessModalProps) => {
  const [formValue, setFormValue] = useState<IUpdateBusinessFormValues | null>(null);

  const updateReport = useUpdateMultiEntityReport();
  const { mutate } = updateReport;

  const availableBusinessesQuery = useGetReportAvailableBusinesses(isOpen ? item.templateId : '');
  const availableBusinesses = useMemo(
    () => availableBusinessesQuery.data || [],
    [availableBusinessesQuery.data],
  );
  const businessData = useMemo(() => {
    const tmpBusinesses = [...businesses];

    return tmpBusinesses.map((business: IBusiness) => {
      const find = availableBusinesses.find((f: IBusiness) => f.id === business.id);

      if (find) {
        business.availableStatus = true;
      } else {
        business.availableStatus = false;
      }

      return business;
    });
  }, [availableBusinesses, businesses]);

  const reportServicesQuery = useGetReportServicesByBusinessId({ 'business_ids[]': formValue?.reportServiceIds as number[] });
  const reportIds = useMemo(() => reportServicesQuery.data || [], [reportServicesQuery.data]);
  const reportServiceIds = useMemo(
    () => reportIds.map((val: IGetReportServicesByBusinessIdResponse) => val.id),
    [reportIds],
  );

  useEffect(() => {
    if (reportServiceIds.length !== 0 && item.id === formValue?.id && action === 'edit') {
      mutate(
        {
          ...item,
          reportServiceIds,
        },
        {
          onSuccess: () => {
            setFormValue(null);
            onDone();
          },
        },
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportServiceIds, setFormValue, item.id, action, onDone]);

  const handleDone = useCallback((data: IUpdateBusinessFormValues) => {
    setFormValue(null);
    setFormValue(data);
  }, [setFormValue]);

  return (
    <>
      <Modal.Form
        proceedTitle="Save"
        show={ isOpen }
        title="Edit Advanced Report"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            businesses={ businessData }
            formId={ formId }
            item={ item }
            onSubmit={ handleDone }
          />
        )}
      </Modal.Form>
      <MutationStatus mutation={ updateReport } />
    </>
  );
};

const useBusinessModal = makeUseModal(EditBusinessModal);

export { IEditBusinessModalProps, useBusinessModal, EditBusinessModal as default };
