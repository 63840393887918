import React, { useCallback } from 'react';

import classNames from 'classnames';

import { RemoveIcon } from '@src/components/utils/fa_icons';

import { IInputCommonProps } from './types';

import styles from './styles.module.scss';

interface IClearInputIndicatorProps<T extends React.ElementType> {
  absolute?: boolean;
  as?: T,
  size?: IInputCommonProps['size'];
  onClick?: () => void;
}

type TClearInputIndicatorProps<T extends React.ElementType> =
  IClearInputIndicatorProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof IClearInputIndicatorProps<T> | 'onClick'>
const ClearInputIndicator = <T extends React.ElementType = 'button'>({
  absolute,
  as,
  className,
  size,
  onClick,
  ...props
}: TClearInputIndicatorProps<T>) => {
  const classes = classNames(
    styles['input-clear-indicator'],
    {
      [styles['input-clear-indicator-absolute']]: absolute,
      [styles['input-clear-indicator-compact']]:  size === 'compact',
    },
    className,
  );

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) onClick();
  }, [onClick]);

  const Component = as || 'button';

  return (
    <Component
      className={ classes }
      type="button"
      onClick={ handleClick }
      { ...props }
    >
      <RemoveIcon />
    </Component>
  );
};

export default ClearInputIndicator;
