import React, { useEffect, useState, useCallback } from 'react';

import {
  useCreateApproverCompliances,
  useUpdateApproverCompliances,
} from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { AccountsPayableIcon, EditIcon } from '@src/components/utils/icomoon';

import { IAccountsPayablePolicy } from './types';
import { useUpgradeAccountsModal } from './upgrade_account_modal';

import styles from './styles.module.scss';

interface IRuleItemViewProp {
    hasAdvancePlan?: boolean;
    approverPolicy: IAccountsPayablePolicy | undefined;
    businessId: TID;
    policyType: string;
}

const BasicRuleItemView = ({
  approverPolicy,
  businessId,
  hasAdvancePlan = false,
  policyType,
}: IRuleItemViewProp) => {
  const modal = useUpgradeAccountsModal();
  const [isDefaultEdit, setIsDefaultEdit] = useState(false);
  const [canApproveAllInvoices, setCanApproveAllInvoices] = useState<boolean>(
    approverPolicy?.approveAllInvoice || true,
  );
  const createCompliancePolicyServiceDocument = useCreateApproverCompliances;
  const updateCompliancePolicyServiceDocument = useUpdateApproverCompliances;
  const { mutate: updateDefaultPolicy, isLoading: isLoadingUpdate } =
        updateCompliancePolicyServiceDocument();
  const { mutate: createDefaultPolicy, isLoading: isLoadingCreate } =
        createCompliancePolicyServiceDocument();

  const isLoading = isLoadingUpdate || isLoadingCreate;

  useEffect(() => {
    if (approverPolicy) {
      setCanApproveAllInvoices(approverPolicy.approveAllInvoice);
    }
  }, [approverPolicy]);

  const handleBasicRuleSelection = useCallback((approve: boolean) => {
    setCanApproveAllInvoices(approve);
  }, []);

  const handleEditButtonClicked = useCallback(() => {
    setIsDefaultEdit(!isDefaultEdit);
  }, [isDefaultEdit]);

  const handleDoneButtonClicked = useCallback(() => {
    if (approverPolicy && approverPolicy.id) {
      updateDefaultPolicy({
        id:                approverPolicy?.id,
        approveAllInvoice: canApproveAllInvoices,
        policyType,
        businessId,
      });
    } else {
      createDefaultPolicy({
        policyType,
        approveAllInvoice: canApproveAllInvoices,
        businessId,
      });
    }
    setIsDefaultEdit(!isDefaultEdit);
  }, [approverPolicy,
    isDefaultEdit,
    updateDefaultPolicy,
    canApproveAllInvoices,
    policyType,
    createDefaultPolicy,
    businessId]);

  const hasBasicRule = () => {
    return isDefaultEdit ? (
      <>
        <td className="relative">
          <div className="approvers-dropdown-wrapper relative editing">
            <button
              aria-expanded="false"
              aria-haspopup="true"
              className="pointer dropdown-toggle approvers-menu-toggle-btn"
              data-container="body"
              data-toggle="dropdown"
              id="rule-"
              type="button"
            >
              {canApproveAllInvoices
                ? 'Can approve all invoices'
                : "Can't approve any invoice"}
            </button>
            <div
              aria-labelledby="rule-"
              className="dropdown-menu dropdown-menu-right dropdown-menu-round-border"
            >
              <button
                className="dropdown-item font-14 any-approvers-operator-js pointer"
                type="button"
                onClick={ () => handleBasicRuleSelection(true) }
              >
                <span className="font-14">
                  Can approve any invoices
                </span>
              </button>
              <button
                className="dropdown-item font-14 all-approvers-operator-js pointer"
                type="button"
                onClick={ () => handleBasicRuleSelection(false) }
              >
                <span className="font-14">
                  Can&#39;t approve any invoice
                </span>
              </button>
            </div>
          </div>
        </td>
        <td aria-label="Edit icon">
          <div className={ styles['edit-icon'] }>
            <Button
              variant="primary"
              onClick={ handleDoneButtonClicked }
            >
              Done
            </Button>
          </div>
        </td>
      </>
    ) : (
      <>
        <td className="relative">
          <div className="rule-condition-wrapper">
            <div className="small-rule-logo">
              <AccountsPayableIcon fontSize={ 30 } />
            </div>
            {canApproveAllInvoices ? (
              <span className="font-14 m-l-19">
                <b>Can approve</b>
                &nbsp;all Invoices unless an invoice matches an advanced rule
              </span>
            ) : (
              <span className="font-14 m-l-19">
                <b>Can&#39;t approve</b>
                &nbsp;any invoices unless an invoice matches an advanced rule
              </span>
            )}
          </div>
        </td>
        <td aria-label="Edit icon">
          <div className={ styles['edit-icon'] }>
            <EditIcon
              className="edit-icon pointer"
              fontSize={ 18 }
              onClick={ handleEditButtonClicked }
            />
          </div>
        </td>
      </>
    );
  };

  const clickToActivate = () => {
    return (
      <td className="relative">
        <div className="add-link-wrapper">
          <a className="add-rule-link">
            <Button
              className="font-14 font-semi-bold"
              variant="link"
              onClick={ modal.open }
            >
              Click to Activate
            </Button>
          </a>
        </div>
      </td>
    );
  };

  return (
    <>
      <modal.Component { ...modal.props } />
      {isLoading && <Spinner />}
      <tr>{hasAdvancePlan ? hasBasicRule() : clickToActivate()}</tr>
    </>
  );
};

export default BasicRuleItemView;
