import React from 'react';

import { InboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useGetInboxServiceByBusinessIdQuery } from '@src/hooks/queries/inbox_services';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IInboxServiceProviderProps {
  businessId: TID,
  children: React.ReactNode,
}

const InboxServiceProvider = ({
  businessId,
  children,
}: IInboxServiceProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetInboxServiceByBusinessIdQuery(businessId);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Inbox Service loaded" />;

  return (
    <InboxServiceContext.Provider value={ data }>
      { children }
    </InboxServiceContext.Provider>
  );
};

export default InboxServiceProvider;
