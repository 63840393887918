import React from 'react';

import { IDocumentField } from '@src/types/document_field';

interface IDocumentFieldItemProps {
  documentField: IDocumentField,
}

const DocumentFieldItem = ({
  documentField,
}: IDocumentFieldItemProps): JSX.Element => {
  return (
    <div className="display-flex m-b-15">
      <span className="width-150px">
        { documentField.name }
        { ' ' }
        :
      </span>
      <span>{ documentField.value }</span>
    </div>
  );
};

export default DocumentFieldItem;
