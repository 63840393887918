import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

import AllTransactions from './all_transactions';

interface AllTransactionsPageProps {
  businessId: TID,
}

const AllTransactionsPage = ({
  businessId,
}: AllTransactionsPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          <AllTransactions />
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default AllTransactionsPage;
