import React from 'react';

import { uiStyleProps, IUIStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface IFilterContainerProps extends
  React.ComponentPropsWithoutRef<'div'>,
  IUIStyleProps
{
  children: React.ReactNode,
}

const FilterContainer = ({
  children,
  ...props
}: IFilterContainerProps): JSX.Element => {
  const [classes, divProps] = uiStyleProps(styles.filter, props);

  return (
    <div className={ classes } { ...divProps }>
      { children }
    </div>
  );
};

export default FilterContainer;
