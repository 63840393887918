import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getMultiBusinessReportItemAccountValues,
  IMultiBusinessReportItemAccountValuesParams,
  IMultiBusinessReportItemAccountValuesResponse,
  getMultiBusinessReportDetail,
  IMultiBusinessReportDetail,
  getMultiBusinessReports
} from '@src/requests/report_service/multi_business_report';

const useGetMultiBusinessReportDetail = (multiReportId: string) => {
  return useQuery<IMultiBusinessReportDetail, Error>(
    [QueryKey.getMultiBusinessReportDetail, multiReportId],
    () => getMultiBusinessReportDetail(multiReportId)
  );
};

const useGetMultiBusinessReportItemAccountValuesQuery = (
  params: IMultiBusinessReportItemAccountValuesParams,
) => {
  return useQuery<IMultiBusinessReportItemAccountValuesResponse, Error>(
    [QueryKey.multiBusinessReportItemAccountValues, params],
    () => getMultiBusinessReportItemAccountValues(params),
  );
};

export const useGetMultiBusinessReports = (endPoint: string) => {
  return useQuery<IMultiBusinessReportDetail[], Error>(
    [QueryKey.getMultiBusinessReports],
    () => getMultiBusinessReports(endPoint)
  );
};

export {
  useGetMultiBusinessReportItemAccountValuesQuery,
  useGetMultiBusinessReportDetail
};
