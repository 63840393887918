import React, { forwardRef, useCallback } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

interface IPercentInputProps extends Omit<NumberFormatProps, 'value' | 'onChange'>
{
  value?: number | undefined,
  onChange: (value: number | undefined) => void,
}

const PercentInput = forwardRef<HTMLInputElement, IPercentInputProps>(({
  onChange,
  ...props
}: IPercentInputProps, ref) => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  const handleChange = useCallback((values) => {
    if (onChange) onChange(values.floatValue);
  }, [onChange]);

  return (
    <NumberFormat
      isNumericString
      thousandSeparator
      className={ classes }
      getInputRef={ ref }
      inputMode="numeric"
      suffix="%"
      { ...inputProps }
      autoComplete="off"
      onValueChange={ handleChange }
    />
  );
});

PercentInput.displayName = 'PercentInput';
const MemoizedPercentInput = React.memo(PercentInput);

export {
  IPercentInputProps,
  MemoizedPercentInput as default,
};
