import React, { useCallback, useMemo, useState } from 'react';

import uniq from 'lodash/uniq';

import { Button } from '@src/components/ui_v2/buttons';

import { ICSDCollection, ITSDCollection } from '../hooks';
import EnabledButton from './add_adjustment_enabled';

interface IMatchDocumentsAddAdjustmentProps {
  csdCollection: ICSDCollection,
  tsdCollection: ITSDCollection,
}

const MatchDocumentsAddAdjustment = ({
  csdCollection,
  tsdCollection,
}: IMatchDocumentsAddAdjustmentProps) => {
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const { selectedRecords: selectedCSDRecords } = csdCollection;
  const { selectedRecords: selectedTSDRecords, resetSelector: resetTSDSelector } = tsdCollection;

  const isEnabled: boolean = useMemo(() => {
    if (selectedCSDRecords.length === 0 || selectedTSDRecords.length === 0) return false;

    const csdAccountIds = uniq(selectedCSDRecords.map((r) => r.reconciliationPaymentAccount.id));
    const tsdAccountIds = uniq(selectedTSDRecords.map((r) => r.reconciliationPaymentAccount.id));
    // If any selected records have non unique accounts then return false
    if (csdAccountIds.length > 1 || tsdAccountIds.length > 1) return false;

    const documentPaymentAccountId = csdAccountIds[0];
    const expensePaymentAccountId = tsdAccountIds[0];

    if (!documentPaymentAccountId || !expensePaymentAccountId) return false;

    return documentPaymentAccountId === expensePaymentAccountId;
  }, [selectedCSDRecords, selectedTSDRecords]);

  const handleStartVerifying = useCallback(() => {
    setIsVerifying(true);
  }, []);

  const handleFinishVerifying = useCallback(() => {
    setIsVerifying(false);
    resetTSDSelector();
  }, [resetTSDSelector]);

  const handleCancelVerifying = useCallback(() => {
    setIsVerifying(false);
  }, []);

  if (!isEnabled && !isVerifying) {
    return (
      <Button
        disabled={ !isEnabled }
        size="compact"
        variant="primary"
      >
        Match
      </Button>
    );
  }

  return (
    <EnabledButton
      csdCollection={ csdCollection }
      tsdCollection={ tsdCollection }
      onCancelVerifying={ handleCancelVerifying }
      onFinishVerifying={ handleFinishVerifying }
      onStartVerifying={ handleStartVerifying }
    />
  );
};

export default MatchDocumentsAddAdjustment;
