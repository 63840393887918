import React, { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FormValues } from '@src/types/adjustment_entries';

import { DateInput } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

const DateSelect: FC<{dateRange?: Date[]}> = ({ dateRange }) => {
  const { control, formState: { errors } } = useFormContext<FormValues>();

  const minDate = dateRange && dateRange[0];
  const maxDate = dateRange && dateRange[1];

  return (
    <div className={ styles['date-select-panel'] }>
      <div>
        <span>Journal Date</span>
        <Controller
          control={ control }
          name="date"
          render={ ({ field: { value, onChange } }) => (
            <DateInput
              maxDate={ maxDate }
              minDate={ minDate }
              value={ value }
              onChange={ onChange }
            />
          ) }
        />
      </div>
      {errors.date && <p className={ styles['error-message'] }>{errors.date.message}</p>}
    </div>
  );
};

export default DateSelect;
