import { createContext, useContext } from 'react';

import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

const ReconciliationPaymentAccountContext =
  createContext<IReconciliationPaymentAccount | null>(null);

const useReconciliationPaymentAccountContext =
  () => <IReconciliationPaymentAccount>useContext(ReconciliationPaymentAccountContext);

export {
  ReconciliationPaymentAccountContext,
  useReconciliationPaymentAccountContext,
};
