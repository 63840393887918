import React, { useState } from 'react';

import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { IJournal } from '@src/types/journal_entries';

import DeleteIcon from '@src/components/journal_entries/main_page/delete_icon';
import Table from '@src/components/ui/table';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import DownloadDocumentIcon from '../../utils/icomoon/download_document';

import styles from './styles.module.scss';

const Item: React.FC<{ i: IJournal }> = ({ i }) => {
  const businessId = useJournalEntriesContext();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const navToDetail = () => {
    Backbone.history.navigate(`businesses/${businessId}/journal_entries/${i.id}?name=${encodeURIComponent(i.name)}`, { trigger: true });
  };

  const downloadJournal = () => {
    setDownloadLoading(true);
    const link = document.createElement('a');
    link.href = i.csvDownloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => setDownloadLoading(false), 1000);
  };

  return (
    <Table.Row>
      <Table.Cell
        pointer
        widthPercent={ 22 }
        onClick={ navToDetail }
      >
        <span className={ styles.highlight }>{ i.name }</span>
      </Table.Cell>
      <Table.DateCell
        pointer
        date={ i.createdAt }
        style={ { paddingLeft: '15px' } }
        widthPercent={ 22 }
      />
      <Table.Cell
        pointer
        style={ { paddingLeft: '15px' } }
        widthPercent={ 22 }
      >
        {i.managementGroupName}
      </Table.Cell>
      <Table.Cell
        pointer
        style={ { paddingLeft: '15px' } }
        widthPercent={ 22 }
      >
        {i.description}
      </Table.Cell>
      <Table.Cell
        widthPercent={ 8 }
      >
        <span className={ styles.icons }>
          {
            downloadLoading ? <SpinnerIcon spin /> : <DownloadDocumentIcon pointer fontSize={ 15 } inColor="grey-1050" onClick={ downloadJournal } />
          }
          <DeleteIcon id={ i.id } />
        </span>
      </Table.Cell>
    </Table.Row>
  );
};

export default Item;
