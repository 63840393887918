import React from 'react';

import Table from '@src/components/ui/table';

import styles from '../../styles.module.scss';

interface IBlankBusinessMetricValuesProps {
  businessMetricName: string,
  onAddValues: () => void,
}

const BlankBusinessMetricValues = ({
  businessMetricName,
  onAddValues,
}: IBlankBusinessMetricValuesProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.Cell colSpan={ 3 }>
        <div className="text-center display-flex align-items-center p-t-25 p-b-25" style={ { flexDirection: 'column' } }>
          <span className="p-b-20">
            No data available for
            { ' ' }
            { businessMetricName }
          </span>
          <button
            className={ styles['add-data-manually'] }
            type="submit"
            onClick={ onAddValues }
          >
            Add Data Manually
          </button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default BlankBusinessMetricValues;
