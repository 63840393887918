import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { StopIcon } from '@src/components/utils/icomoon';

interface IVoidCheckActionProps {
  invoiceId: TID,
}

const VoidCheckAction = ({
  invoiceId,
}: IVoidCheckActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:void:check', invoiceId);
  }, [invoiceId]);

  return (
    <ActionsDropdown.Action
      icon={ <StopIcon fontSize={ 18 } /> }
      name="Cancel Payment"
      onClick={ handleSelect }
    />
  );
};

export default VoidCheckAction;
