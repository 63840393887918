import React, { useState } from 'react';

import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { FormValues } from '@src/types/adjustment_entries';

import { AmountInput, SelectInput, TextInput, TOption } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';

import ChartOfAccountInput from './chart_of_account_input';
import TableRowDelete from './table_row_delete';
import VendorCustomerInput from './vendor_customer_input';

import styles from './styles.module.scss';

interface ISelectedCoa {
  id: string;
  name: string;
  accType: string;
  mappedClassIds: string[];
  mappedClassNames: string[];
  displayName?: string;
}

interface ITableRow {
  index: number;
  deleteLine: (i: number) => void;
  editModel: boolean;
}

const TableRow: React.FC<ITableRow> = ({ index, deleteLine, editModel }) => {
  const business = useBusinessContext();
  const businessId = business.id;

  const { watch, trigger,
    control,
    formState: { errors, isSubmitted },
    getValues,
    setValue } = useFormContext<FormValues>();

  const hasAccountValue = getValues(`rows.${index}.hasAccountValue`);

  const initialSelectedCoa = (editModel || hasAccountValue) ? (getValues(`rows.${index}.accountObj`) as ISelectedCoa) : null;
  const initialSelectedName = editModel ? (getValues(`rows.${index}.entityObj`)) : null;
  const [selectedCoa, setSelectedCoa] = useState<ISelectedCoa | null>(initialSelectedCoa);

  const initialDepartmentOptions = initialSelectedCoa
    ? initialSelectedCoa.mappedClassIds.map((id, i) => ({
      label: initialSelectedCoa.mappedClassNames[i],
      value: id,
    })) : [];

  const [departmentOptions, setDepartmentOptions] = useState<TOption[]>(initialDepartmentOptions);

  const creditValue = watch(`rows.${index}.credit`);
  const debitValue = watch(`rows.${index}.debit`);

  const getErrorMessage = (fieldName: string) => {
    const error = (errors.rows?.[index] as any)?.[fieldName];
    const isAmount = fieldName === 'eitherDebitOrCredit';
    const className = isAmount ? `${styles['error-message']} ${styles['amount-error-message']}` : styles['error-message'];
    return error ? <p className={ className }>{error.message}</p> : null;
  };

  const handleAccountSelected = (selected: ISelectedCoa) => {
    setSelectedCoa(selected);

    const tmp = [];
    for (let i = 0; i < selected.mappedClassIds.length; i += 1) {
      tmp.push({
        label: selected.mappedClassNames[i],
        value: selected.mappedClassIds[i],
      });
    }
    setDepartmentOptions(tmp);
    if (tmp.length > 0) {
      setValue(`rows.${index}.department`, tmp[0].value);
    }
  };

  const renderCoaInput = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.account`> }) => {
    const { onChange } = field;
    const handleSelected = (selected: ISelectedCoa) => {
      onChange(selected.id);
      handleAccountSelected(selected);
    };

    const props: any = {
      businessId,
      handleSelected,
    };

    if (selectedCoa) {
      props.selectedValue = selectedCoa.displayName;
    }

    if (editModel || hasAccountValue) {
      props.initValue = initialSelectedCoa?.id;
    }

    return (
      <ChartOfAccountInput
        { ...props }
      />
    );
  };

  const renderDesInput = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.department`> }) => {
    const { onChange, value } = field;
    return (
      <TextInput
        placeholder="Add Description"
        value={ value }
        onChange={ onChange }
      />
    );
  };

  const renderNameInput = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.name`> }) => {
    const { onChange } = field;
    if (selectedCoa) {
      return (
        <VendorCustomerInput
          businessId={ businessId }
          handleSelected={ (item) => {
            onChange(item?.value || '');
            setValue(`rows.${index}.type`, item?.type);
          } }
          initValue={ initialSelectedName }
        />
      );
    }
    return (
      <Tooltip.Hover content="Please select account first">
        <input disabled placeholder="Add Name" />
      </Tooltip.Hover>
    );
  };

  const renderDepartments = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.department`> }) => {
    const { onChange, value } = field;
    if (selectedCoa) {
      const target = departmentOptions.find((i) => i.value === value) || null;
      return (
        <SelectInput
          hideClear
          className={ editModel ? styles['edit-department-column'] : '' }
          options={ departmentOptions }
          placeholder="Add Department"
          value={ target }
          onChange={ (selected) => onChange(selected?.value || null) }
          onKeyDown={ (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) {
              if (navigator.clipboard) {
                navigator.clipboard.writeText(target?.label as string);
              }
            }
          } }
        />
      );
    }
    return (
      <Tooltip.Hover content="Please select account first">
        <input disabled placeholder="Add Department" />
      </Tooltip.Hover>
    );
  };

  const renderDebitAmount = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.debit`> }) => {
    const { onChange, value } = field;

    const handleChange = (amount: string | null) => {
      onChange(amount);
      setValue(`rows.${index}.credit`, null);
      trigger([`rows.${index}.eitherDebitOrCredit`, 'totalAmount']);
    };

    if (creditValue) {
      return (
        <Tooltip.Hover content={ (
          <div>
            Cannot have values in both Credit and Debit
            <br />
            {' '}
            in the same row
          </div>
        ) }
        >
          <input disabled placeholder="$0.00" />
        </Tooltip.Hover>
      );
    }
    return (
      <AmountInput
        placeholder="$0.00"
        value={ String(value || '') }
        onChange={ handleChange }
      />
    );
  };

  const renderCreditAmount = ({ field }: { field: ControllerRenderProps<FormValues, `rows.${number}.credit`> }) => {
    const { onChange, value } = field;

    const handleChange = (amount: string | null) => {
      onChange(amount);
      setValue(`rows.${index}.debit`, null);
      trigger([`rows.${index}.eitherDebitOrCredit`, 'totalAmount']);
    };

    if (debitValue) {
      return (
        <Tooltip.Hover content={ (
          <div>
            Cannot have values in both Credit and Debit
            <br />
            {' '}
            in the same row
          </div>
        ) }
        >
          <input disabled placeholder="$0.00" />
        </Tooltip.Hover>
      );
    }
    return (
      <AmountInput
        placeholder="$0.00"
        value={ String(value || '') }
        onChange={ handleChange }
      />
    );
  };

  return (
    <div className={ styles['table-row'] }>
      <span className={ styles['text-column-container'] }>
        <span>
          { index + 1 }
          .
        </span>
      </span>
      <span className={ styles['select-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.account` }
          render={ renderCoaInput }
        />
        { getErrorMessage('account') }
      </span>
      <span className={ styles['input-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.description` as any }
          render={ renderDesInput }
        />
        { getErrorMessage('description') }
      </span>
      <span className={ styles['select-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.name` }
          render={ renderNameInput }
        />
        { getErrorMessage('name') }
      </span>
      <span className={ styles['select-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.department` }
          render={ renderDepartments }
        />
        { getErrorMessage('department') }
      </span>
      <span className={ styles['input-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.debit` }
          render={ renderDebitAmount }
        />
        { isSubmitted && getErrorMessage('eitherDebitOrCredit') }
      </span>
      <span className={ styles['input-column-container'] }>
        <Controller
          control={ control }
          name={ `rows.${index}.credit` }
          render={ renderCreditAmount }
        />
      </span>
      <span className={ styles['text-column-container'] }>
        <TableRowDelete index={ index } remove={ deleteLine } />
      </span>
    </div>
  );
};

export default TableRow;
