import React from 'react';

import { TID, TYear } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import BalanceSheetInformationDetails from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_details/balance_sheet_information_details';
import Root from '@src/components/root';

interface IBalanceSheetInformationsDetailsPageProps {
  businessId: TID,
  chartOfAccountId: TID,
  year: TYear,
}

const BalanceSheetInformationsDetailsPage = ({
  businessId,
  chartOfAccountId,
  year,
}: IBalanceSheetInformationsDetailsPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          <BalanceSheetInformationDetails
            businessId={ businessId }
            chartOfAccountId={ chartOfAccountId }
            year={ year }
          />
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BalanceSheetInformationsDetailsPage;
