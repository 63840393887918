import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface IVersionGuardBannerProps {
  onRefreshClick: () => void,
}

const VersionGuardBanner = ({
  onRefreshClick,
}: IVersionGuardBannerProps): JSX.Element => {
  return (
    <div className={ styles['version-guard'] }>
      <p className={ styles.message }>
        <span className={ styles['upgrade-time'] }>Upgrade time!</span>
        { ' ' }
        A new Docyt version is ready for you. Refresh your browser to try it out.
      </p>
      <Button
        className={ styles['refresh-button'] }
        variant="outline"
        onClick={ onRefreshClick }
      >
        Refresh Now
      </Button>
    </div>
  );
};

export default VersionGuardBanner;
