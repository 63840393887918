module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog team-multi-select-modal select-multi-item-area">\n      <div class="modal-content">\n        <div class="modal-header">\n          <h4 class="modal-title font-bold font-26">Add Portfolio Report</h4>\n        </div>\n        <div class="modal-body">\n          <div class="businesses-container-region"></div>\n          <div class="report-type-container display-flex-column m-t-20">\n            <label class="font-16 business-names-js"></label>\n            <div class="font-semi-bold font-16 m-t-24">Type of Report</div>\n            <div class="display-flex">\n              <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                <input type="radio" id="typePL" name="radio-type" class="pointer radio-type" value="');
    
      __out.push(__sanitize(configData.multi_entity_report_types.PROFIT_LOSS));
    
      __out.push('" checked>\n                <label for="typePL">Profit & Loss</label>\n              </div>\n              <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                <input type="radio" id="typeBS" name="radio-type" class="pointer radio-type" value="');
    
      __out.push(__sanitize(configData.multi_entity_report_types.BALANCE));
    
      __out.push('">\n                <label for="typeBS">Balance Sheet</label>\n              </div>\n              <div class="checkbox-singleline"></div>\n            </div>\n            <div class="font-semi-bold font-16 m-t-24">Period</div>\n            <div class="display-flex">\n              <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                <input type="radio" id="periodMonth" name="radio-period" class="pointer radio-period" value="');
    
      __out.push(__sanitize(configData.multi_entity_report_periods.MONTHLY));
    
      __out.push('" checked>\n                <label for="periodMonth">Monthly</label>\n              </div>\n              <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                <input type="radio" id="periodQuart" name="radio-period" class="pointer radio-period" value="');
    
      __out.push(__sanitize(configData.multi_entity_report_periods.QUARTERLY));
    
      __out.push('">\n                <label for="periodQuart">Quarterly</label>\n              </div>\n              <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n                <input type="radio" id="periodYear" name="radio-period" class="pointer radio-period" value="');
    
      __out.push(__sanitize(configData.multi_entity_report_periods.YEARLY));
    
      __out.push('">\n                <label for="periodYear">Yearly</label>\n              </div>\n            </div>\n            <div class="font-semi-bold font-16 m-t-28">Report Name</div>\n            <div class="display-flex align-items-center m-t-14">\n              <input type="text" class="form-control report-name-js width-150px" placeholder="Report Name" value="">\n              <label class="form-label m-l-10 report-name-label-js"></label>\n            </div>\n          </div>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-blue btn-submit ladda-button" data-color="blue" data-style="expand-right" disabled><span class="ladda-label">Continue</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}