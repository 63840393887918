import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { formatDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  BankStatementApprovedInactive,
  BankStatementMissing,
  BankStatementRequested,
  BankStatementUploadedInactive,
  BankStatementVerifiedInactive,
} from '@src/components/utils/icomoon';

import DeleteRequestStatementAction from './actions/delete_request_statement_action';

import styles from '../../../styles.module.scss';

interface IRequestedStatusItemProps {
  state: IReviewedBankStatementState,
}

const RequestedStatusItem = ({
  state,
}: IRequestedStatusItemProps): JSX.Element => {
  const requestedTooltip = (
    <div className="requested-status">
      <div className={ styles['state-status'] }>
        <div className={ styles['state-info'] }>
          <hr className={ styles['missing-requested-progress-line'] } />
          <BankStatementMissing fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className="font-12">Statement missing</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['requested-uploaded-progress-disabled-line'] } />
          <BankStatementRequested fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.requestedAt ? formatDate(state.requestedAt) : '--'}</span>
          <span className="font-12">Statement requested</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-approved-progress-disabled-line'] } />
          <BankStatementUploadedInactive fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Statement uploaded</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['approved-verified-progress-disabled-line'] } />
          <BankStatementApprovedInactive fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Statement approved</span>
        </div>
        <div className={ styles['state-info'] }>
          <BankStatementVerifiedInactive fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Bank feed verified</span>
        </div>
      </div>
      <div className={ styles['state-details'] }>
        <span>
          Status:
          { ' ' }
          <span className={ styles['sub-details'] }>Statement requested but not uploaded</span>
        </span>
        <br />
        <span>Statement is yet to be uploaded by the client</span>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ requestedTooltip } tooltipClassName={ styles['state-item'] }>
      <BankStatementRequested fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['status-item-button'] }>
      { content }
    </Dropdown.ToggleButton>
  );

  return (
    <ActionsDropdown toggleButton={ toggleButton }>
      <DeleteRequestStatementAction
        state={ state }
      />
    </ActionsDropdown>
  );
};

export default RequestedStatusItem;
