import React, { useEffect, useMemo, useState } from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';
import { IMultiReportDetailBusiness } from '@src/types/report_service/multi_report_detail';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import Wrapper from '@src/components/ui_v2/filter/filter_field_wrapper';
import { TOption } from '@src/components/ui_v2/inputs';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';

interface IMultiAddItemFieldProps
  extends Omit<React.ComponentPropsWithoutRef<typeof MultiSelectAddItem>, 'options' | 'value' | 'onChange'>,
    IFilterFieldUIProps {
  businesses:   IBusiness[],
  multiReport:  IMultiBusinessReportDetail | undefined
}

const MultiAddItemField = ({ businesses, multiReport, ...props }: IMultiAddItemFieldProps) => {
  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const [multiValue, setMultiValue] = useState<TOption[]>([]);

  const options: TOption[] = useMemo(
    () =>
      businesses.map((business: IBusiness) => ({
        label: business.displayName,
        value: business.id.toString(),
      })),
    [businesses]
  );

  useEffect(() => {
    const filter = options.filter((op: TOption) => {
      return multiReport?.businesses?.find((f: IMultiReportDetailBusiness) => (
        f.id === Number(op.value)
      ));
    });
    
    setMultiValue(filter);
  }, [options, multiReport]);

  const handleChange = (selectedOptions: TOption[]) => {
    setMultiValue(selectedOptions);
  };

  return (
    <Wrapper { ...wrapperProps }>
      <MultiSelectAddItem
        addItem
        options={ options }
        value={ multiValue }
        { ...inputProps }
        onChange={ handleChange }
      />
    </Wrapper>
  );
}

export default MultiAddItemField;
