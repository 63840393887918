import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  IGetQuickbooksCustomersParams,
  IMergeQuickbooksCustomerParams,
  IGetQuickbooksCustomersResponse,
  getQuickbooksCustomers,
  mergeQuickbooksCustomer,
} from '@src/requests/quickbooks_customers';

const QUICKBOOKS_CUSTOMERS_QUERY = 'quickbooks_customers';

const useGetQuickbooksCustomers = (params: IGetQuickbooksCustomersParams) => {
  return useQuery<IGetQuickbooksCustomersResponse, Error>(
    [QUICKBOOKS_CUSTOMERS_QUERY, params],
    () => getQuickbooksCustomers(params),
  );
};

const useMergerCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IMergeQuickbooksCustomerParams>(
    mergeQuickbooksCustomer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUICKBOOKS_CUSTOMERS_QUERY);
      },
    },
  );
};

export {
  useGetQuickbooksCustomers,
  useMergerCustomer,
};
