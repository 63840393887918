module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var featureContent, i, j, k, l, len, len1, len2, len3, ref, ref1, ref2, ref3, ref4, subscriptionModule, subscriptionPlan;
    
      if (this.allSubscriptionPlans.length > 0) {
        __out.push('\n<div class="billing-plans-header">\n  <h1 class="">Plans</h1>\n</div>\n<div class="subscription-plans">\n  ');
        ref = this.allSubscriptionPlans.models;
        for (i = 0, len = ref.length; i < len; i++) {
          subscriptionPlan = ref[i];
          __out.push('\n  <div class="subscription-plan-card-wrapper ');
          if (!this.isVisiblePlan(subscriptionPlan)) {
            __out.push(__sanitize('hidden'));
          }
          __out.push('">\n    <div class="subscription-plan-card ');
          __out.push(__sanitize(((ref1 = this.subscription) != null ? ref1.subscription_plan_identifier : void 0) === subscriptionPlan.get('identifier') ? 'active' : ''));
          __out.push('">\n      ');
          if (this.subscription && this.subscription.subscription_plan_identifier === subscriptionPlan.get('identifier')) {
            __out.push('\n        ');
            if (subscriptionPlan.get('is_deprecated')) {
              __out.push('\n        <div class="discontinued-plan">\n          Discontinued Plan\n          <span class="icon icon-info-circle" data-toggle="tooltip" data-placement="bottom" title="This plan has been discontinued by Docyt. Upgrade to one of our new plans for the best possible service."></span>\n        </div>\n        ');
            }
            __out.push('\n        <span class="span-subscribed core-plan-subscribed">CURRENT PLAN</span>\n      ');
          } else {
            __out.push('\n      <div class="m-b-20"></div>\n      ');
          }
          __out.push('\n      <div class="plan-header ');
          __out.push(__sanitize(this.subscription ? 'border-none' : ''));
          __out.push('">\n        ');
          if (!(this.subscription && this.subscription.subscription_plan_id === subscriptionPlan.get('identifier'))) {
            __out.push('\n          ');
            if (subscriptionPlan.get('is_new_plan')) {
              __out.push('\n            <span class="span-subscribed core-plan-subscribed new-plan">NEW PLAN</span>\n          ');
            }
            __out.push('\n        ');
          }
          __out.push('\n        <h2 class="plan-title">');
          __out.push(__sanitize(subscriptionPlan.get('name')));
          __out.push('</h2>\n        ');
          if (!this.subscription) {
            __out.push('\n        <h3 class="plan-sub-title">');
            __out.push(__sanitize(subscriptionPlan.get('description')));
            __out.push('</h3>\n        ');
          }
          __out.push('\n      </div>\n      ');
          if (!this.subscription) {
            __out.push('\n      <div class="plan-body">\n        ');
            ref2 = subscriptionPlan.get('feature_descriptions');
            for (j = 0, len1 = ref2.length; j < len1; j++) {
              featureContent = ref2[j];
              __out.push('\n          ');
              if (featureContent === 'split') {
                __out.push('\n          <hr/>\n          ');
              } else {
                __out.push('\n          <p class="feature-panel ');
                __out.push(__sanitize(featureContent.lastIndexOf('(Available)') === -1 ? 'panel-green' : 'panel-gray'));
                __out.push('">');
                __out.push(__sanitize(this.featureContent(featureContent)));
                __out.push('</p>\n          ');
              }
              __out.push('\n        ');
            }
            __out.push('\n      </div>\n       ');
          }
          __out.push('\n      ');
          if (this.subscription && this.businessBilling.get('needs_update_billing')) {
            __out.push('\n      <div class="plan-body needs-update-billing">\n        <div class="description">\n          ');
            __out.push(__sanitize(this.showSubscriptionDurationAlert));
            __out.push('\n        </div>\n        <a class="renew-subscription-link font-bold" href="https://docyt.com/contact" target="_blank">Contact Sales to Renew Subscription</a>\n      </div>\n      ');
          }
          __out.push('\n      <div class="mobile-price-section">\n        <div class="">\n          <span class="font-14">$</span>\n          <span class="font-24 font-bold">');
          __out.push(__sanitize(subscriptionPlan.getCostDigitPart()));
          __out.push('</span>\n          <span class="in-grey-1000">/month</span>\n        </div>\n        ');
          if (this.subscription && this.subscription.subscription_plan_identifier === subscriptionPlan.get('identifier') && this.subscription.subscription_plan_identifier === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n        <a class="cancel-subscription-link">Cancel Subscription</a>\n        ');
          } else if (this.subscription && this.subscription.subscription_plan_identifier === subscriptionPlan.get('identifier')) {
            __out.push('\n        <a class="cancel-subscription-link" href="https://docyt.com/contact" target="_blank">Contact Sales to Cancel Subscription</a>\n        ');
          } else if (this.subscription === null && subscriptionPlan.get('identifier') === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n        <button class="btn btn-blue select-plan" data-prefix="');
            __out.push(__sanitize(configData.subscription_plans['BIZ_BASIC_PLAN']));
            __out.push('">Start Free Trial</button>\n        ');
          } else {
            __out.push('\n        <a class="btn btn-blue-on-hover billing-schedule-link" href="https://docyt.com/contact" target="_blank">Contact Sales</a>\n        ');
          }
          __out.push('\n      </div>\n      ');
          if (this.subscription && this.subscription.subscription_plan_identifier === subscriptionPlan.get('identifier') && this.subscription.subscription_plan_identifier === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n      <a class="cancel-subscription-link">Cancel Subscription</a>\n      ');
          } else if (this.subscription && this.subscription.subscription_plan_identifier === subscriptionPlan.get('identifier')) {
            __out.push('\n      <a class="cancel-subscription-link" href="https://docyt.com/contact" target="_blank">Contact Sales to Cancel Subscription</a>\n      ');
          } else if (this.subscription === null && subscriptionPlan.get('identifier') === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n      <button class="btn btn-blue select-plan" data-prefix="');
            __out.push(__sanitize(configData.subscription_plans['BIZ_BASIC_PLAN']));
            __out.push('">Start Free Trial</button>\n      ');
          } else {
            __out.push('\n      <a class="btn btn-blue-on-hover billing-schedule-link" href="https://docyt.com/contact" target="_blank">Contact Sales</a>\n      ');
          }
          __out.push('\n    </div>\n  </div>\n  ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n\n');
    
      if (this.subscriptionModules && this.subscriptionModules.length > 0) {
        __out.push('\n<div class="billing-plans-header m-t-25">\n  <h1 class="">Back Office Apps</h1>\n</div>\n<div class="subscription-plans">\n  ');
        ref3 = this.subscriptionModules;
        for (k = 0, len2 = ref3.length; k < len2; k++) {
          subscriptionModule = ref3[k];
          __out.push('\n  <div class="subscription-plan-card-wrapper">\n    <div class="subscription-plan-card module ');
          __out.push(__sanitize(this.isSubscribedForModule(subscriptionModule.identifier) ? 'active' : ''));
          __out.push('">\n      ');
          if (this.isSubscribedForModule(subscriptionModule.identifier)) {
            __out.push('\n        ');
            if (this.subscription.subscription_plan_identifier === this.backOfficePlan.get('identifier')) {
              __out.push('\n          <span class="span-subscribed core-plan-subscribed m-b-20">CURRENT PLAN</span>\n        ');
            } else {
              __out.push('\n          <span class="span-subscribed core-plan-subscribed m-b-20">Included</span>\n        ');
            }
            __out.push('\n      ');
          } else if (this.isSupportedBackOffice) {
            __out.push('\n      <div class="m-b-45"></div>\n      ');
          }
          __out.push('\n      <div class="plan-header module-type">\n        <h2 class="plan-title">');
          __out.push(__sanitize(subscriptionModule.name));
          __out.push('</h2>\n      </div>\n      <div class="plan-body">\n        ');
          ref4 = subscriptionModule.feature_descriptions;
          for (l = 0, len3 = ref4.length; l < len3; l++) {
            featureContent = ref4[l];
            __out.push('\n          ');
            if (featureContent === 'split') {
              __out.push('\n          <hr/>\n          ');
            } else {
              __out.push('\n          <p class="feature-panel">');
              __out.push(__sanitize(featureContent));
              __out.push('</p>\n          ');
            }
            __out.push('\n        ');
          }
          __out.push('\n      </div>\n      <div class="mobile-price-section">\n        ');
          if (this.isSubscribedForModule(subscriptionModule.identifier) && this.subscription.subscription_plan_identifier === this.backOfficePlan.get('identifier') && this.subscription.subscription_plan_identifier === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n        <a class="cancel-subscription-link">Cancel Subscription</a>\n        ');
          } else if (this.isSubscribedForModule(subscriptionModule.identifier) && this.subscription.subscription_plan_identifier === this.backOfficePlan.get('identifier')) {
            __out.push('\n        <a class="cancel-subscription-link" href="https://docyt.com/contact" target="_blank">Contact Sales to Cancel Subscription</a>\n        ');
          } else if (!this.isSubscribedForModule(subscriptionModule.identifier)) {
            __out.push('\n        <a class=\'btn btn-blue-on-hover billing-schedule-link\' href="https://docyt.com/contact" target="_blank">Contact Sales</a>\n        ');
          }
          __out.push('\n      </div>\n      ');
          if (this.isSubscribedForModule(subscriptionModule.identifier) && this.subscription.subscription_plan_identifier === this.backOfficePlan.get('identifier') && this.subscription.subscription_plan_identifier === configData.subscription_plans['BIZ_BASIC_PLAN']) {
            __out.push('\n      <a class="cancel-subscription-link">Cancel Subscription</a>\n      ');
          } else if (this.isSubscribedForModule(subscriptionModule.identifier) && this.subscription.subscription_plan_identifier === this.backOfficePlan.get('identifier')) {
            __out.push('\n      <a class="cancel-subscription-link" href="https://docyt.com/contact" target="_blank">Contact Sales to Cancel Subscription</a>\n      ');
          } else if (!this.isSubscribedForModule(subscriptionModule.identifier)) {
            __out.push('\n      <a class=\'btn btn-blue-on-hover billing-schedule-link\' href="https://docyt.com/contact" target="_blank">Contact Sales</a>\n      ');
          }
          __out.push('\n    </div>\n  </div>\n  ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n\n<div class="mobile-billing-plans-footer">\n  <p class="contact-support-link">For volume discounts, or any other billing related questions, please contact <a class="font-bold" href="mailto:support@docyt.com">support@docyt.com</a>.</p>\n  <p class="price-comparision-link"><a class="font-bold" href="https://docyt.com/pricing" target="_blank">Get Detailed Plan Comparision Here</a></p>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}