import { createContext, useContext } from 'react';

import { IVendorsService } from '@src/types/services';

const VendorsServiceContext = createContext<IVendorsService | null>(null);

const useVendorsServiceContext = () => useContext(VendorsServiceContext);

export {
  VendorsServiceContext,
  useVendorsServiceContext,
};
