import React from 'react';

import { IPaymentProcessor } from '@src/types/payment_processors';

import Filter from '@src/components/ui_v2/filter';

interface IDepositTrackingFilterProps {
  paymentProcessors: IPaymentProcessor[],
}

const DepositTrackingFilter = ({
  paymentProcessors,
}: IDepositTrackingFilterProps) => {
  return (
    <Filter.TableContainer>
      <Filter.MonthField name="month" />
      {
        paymentProcessors.map((paymentProcessor) => (
          <Filter.AmountField
            key={ paymentProcessor.id }
            name={ paymentProcessor.code }
            valueProps={ { placeholder: `${paymentProcessor.name} Amount` } }
          />
        ))
      }
    </Filter.TableContainer>
  );
};

export default React.memo(DepositTrackingFilter);
