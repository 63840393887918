import React from 'react';

import Drawer from 'react-modern-drawer';

import CoaForm from '@src/components/chart_of_accounts/add_btn/coa_form';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import { Button } from '@src/components/ui_v2/buttons';
import { AttachIcon, NotAChargebackIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const AddBtn = () => {
  const { isOpen, open, close } = useDrawer();
  // let { isOpen, open, close } = useDrawer();
  // isOpen = true;

  return (
    <>
      <Button variant="primary" onClick={ open }>
        <AttachIcon fontSize={ 16 } />
        Add Chart of Account
      </Button>
      {
        isOpen && (
          <Drawer
            direction="right"
            open={ isOpen }
            size="30vw"
            onClose={ close }
          >
            <div className={ styles.container }>
              <div className={ styles.header }>
                <div>Add New Category</div>
                <NotAChargebackIcon pointer fontSize={ 20 } onClick={ close } />
              </div>

              <div className={ styles['form-container'] }>
                <CoaForm close={ close } />
              </div>
            </div>
          </Drawer>
        )
      }
    </>
  );
};

export default AddBtn;
