import React, { useMemo } from 'react';

import { useGetMatchedRules } from '@src/hooks/queries/bank_account_reconciliations';
import { TID } from '@src/types/common';

import { BotNotificationIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const RuleMatchedContainer: React.FC<{ id: TID }> = ({ id }) => {
  const rulesQuery = useGetMatchedRules(id);

  const rules = useMemo(() => {
    return rulesQuery.data?.matchedRules || [];
  }, [rulesQuery.data?.matchedRules]);

  if (rules.length === 0) return null;

  return (
    <div className={ styles['rule-container'] }>
      <BotNotificationIcon fontSize={ 24 } />
      <div>
        <div className={ styles['rule-list-title'] }>Docyt AI Setting Matched</div>
        {
          rules.map((i) => (
            // eslint-disable-next-line react/no-danger
            <p key={ i.id } dangerouslySetInnerHTML={ { __html: i.formattedDescription } } />
          ))
        }
      </div>
    </div>
  );
};

export default RuleMatchedContainer;
