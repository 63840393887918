import React, { useMemo } from 'react';

import { IExpenseReportsServiceDocument } from '@src/types/expense_reports/expense_reports_service_documents';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import DeletePermanentlyAction from './actions/delete_permanently_action';
import RestoreExpenseReportsAction from './actions/restore_action';

interface IExpenseReportsTrashHeaderProps {
  selectedRecords: IExpenseReportsServiceDocument[],
}

const ExpenseReportsTrashHeader = ({
  selectedRecords,
}: IExpenseReportsTrashHeaderProps) => {
  const selectedDocumentIds = useMemo(() => {
    return selectedRecords.map((d) => d.documentId) || [];
  }, [selectedRecords]);

  return (
    <DetailsPageTitle title="Trash">
      <ActionsDropdown>
        <RestoreExpenseReportsAction documentIds={ selectedDocumentIds } />
        <DeletePermanentlyAction documentIds={ selectedDocumentIds } />
      </ActionsDropdown>
    </DetailsPageTitle>
  );
};

export default React.memo(ExpenseReportsTrashHeader);
