import React, { useState } from 'react';

import classNames from 'classnames';

import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface IAccordionProps {
  children: () => React.ReactNode,
  title: React.ReactNode,
  suffix?: React.ReactNode,
  size?: 'normal' | 'compact',
}

const Accordion = ({
  children,
  title,
  suffix,
  size = 'normal',
}: IAccordionProps) => {
  const [expand, onExpand] = useState<boolean>(true);

  const headerClasses = classNames(
    styles['accordion-header'],
    { [styles['accordion-header-compact']]: size === 'compact' },
  );

  const childrenClasses = classNames(
    styles['accordion-children'],
    { [styles['accordion-children-compact']]: size === 'compact' },
  );

  return (
    <>
      <div
        className={ headerClasses }
        role="button"
        tabIndex={ 0 }
        onClick={ () => onExpand(!expand) }
      >
        <CaretIcon
          fontSize={ 14 }
          mr={ 6 }
          variant={ expand ? 'down' : 'right' }
        />
        { title }
        { suffix && (
        <span className={ styles['accordion-header-suffix'] }>{ suffix }</span>
        ) }
      </div>
      <div className={ childrenClasses }>
        {
          expand && (
            children()
          )
        }
      </div>
    </>
  );
};

export default Accordion;
