import React from 'react';

import { TDateTime } from '@src/types/common';
import { formatDateAge } from '@src/utils/date_helpers';

import Cell, { ITableCellProps } from './cell';

interface ITableDateTimeCellProps extends Omit<ITableCellProps, 'children'> {
  dateTime: TDateTime,
  // Only relative datetime allowed for now.
  // If you want not relative value, then add formatter to `date_helpers` and use it here.
  relative: true,
}

/**
 * @visibleName Table.DateTimeCell
 */
const TableDateTimeCell = ({
  dateTime,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  relative,
  ...cellProps
}: ITableDateTimeCellProps) => {
  return (
    <Cell { ...cellProps }>
      { formatDateAge(dateTime) }
    </Cell>
  );
};

export default TableDateTimeCell;
