import React from 'react';

import Body from './body';
import BottomModal, { IBottomModalProps } from './bottom_modal';
import StandardFooter, { IBottomModalStandardFooterProps } from './standard_footer';

interface IStandardModalProps extends Omit<IBottomModalStandardFooterProps, 'children'> {
  children: () => React.ReactNode,
  className?: IBottomModalProps['className'],
  dialogClassName?: IBottomModalProps['dialogClassName'],
  footer?: IBottomModalStandardFooterProps['children'],
  isOpen?: IBottomModalProps['isOpen'],
  title: IBottomModalProps['title'],
}

const StandardModal = ({
  children,
  className,
  dialogClassName,
  footer,
  isOpen,
  title,
  ...footerProps
}: IStandardModalProps) => {
  return (
    <BottomModal
      className={ className }
      dialogClassName={ dialogClassName }
      isOpen={ isOpen }
      title={ title }
    >
      <Body>
        { isOpen && children() }
      </Body>
      <StandardFooter { ...footerProps }>
        { footer }
      </StandardFooter>
    </BottomModal>
  );
};

export default StandardModal;
