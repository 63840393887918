import { object, string } from 'yup';

import { TDate } from '@src/types/common';

interface IActivateDepositTrackingValues {
  startDate?: TDate,
}

const activateDepositTrackingValidation = object({
  startDate: string().trim().label('Date').required(),
});

export {
  IActivateDepositTrackingValues,
  activateDepositTrackingValidation,
};
