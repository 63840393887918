module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="p-b-50 text-center dashboard-clients-container">\n  <div class="m-t-20 text-center dashboard-updated-panel">\n    <p class="dashboard-updates-panel refresh-view-panel pointer">\n      <span>Dashboard has been updated. Click here to reload</span>\n    </p>\n  </div>\n  <div class="m-t-20">\n    ');
    
      if (this.search_query !== '') {
        __out.push('\n      <h2 class="font-18 font-semi-bold text-left">\n        ');
        __out.push(__sanitize(this.search_count));
        __out.push(' Search ');
        __out.push(__sanitize(this.search_count === 1 ? 'Result' : 'Results'));
        __out.push(' for "');
        __out.push(__sanitize(this.search_query));
        __out.push('"\n      </h2>\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.business_clients.length > 0) {
        __out.push('\n      <div class="display-table-row header-row pointer">\n        <div class="dashboard-table-column width-5-percent avatar-column"></div>\n        <div class="dashboard-table-column width-28-percent">Client</div>\n        <div class="dashboard-table-column width-8-percent">Tasks</div>\n        <div class="dashboard-table-column width-8-percent">Inbox</div>\n        <div class="dashboard-table-column width-10-percent">Accounts Payable</div>\n        <div class="dashboard-table-column width-10-percent">Receipts</div>\n        <div class="dashboard-table-column width-11-percent">Transactions</div>\n        <div class="dashboard-table-column width-10-percent">Business Admins</div>\n        <div class="dashboard-table-column width-10-percent">Account Managers</div>\n        <div class="dashboard-table-column width-3-percent action-column"></div>\n      </div>\n      <div class="display-table width-100-percent text-left child-container">\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="p-b-30 text-center dashboard-onboarding-introduce-view-container">\n        ');
        if (this.total_count < 1) {
          __out.push('\n        <div class="videos-card">\n          <div class="card-action-wrapper">\n            <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="introduction-container-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n              <i class=\'icon icon-3-dots font-20\'></i>\n            </button>\n            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="introduction-container-action-dropdown-btn">\n              <a class="dropdown-item font-16 pointer hide-introduction-widget-js">\n                <span class=\'\'>Hide Widget</span>\n              </a>\n            </div>\n          </div>\n          <h2 class="card-header">Welcome to Docyt</h2>\n          <p class="card-description">\n            Provide your clients with a powerful digital back-office that replaces 3-5 different software with just 1 integrated tool.\n            Watch the video below to see how Docyt works.\n          </p>\n          <div class="card-video-wrapper">\n            <iframe width="500" height="280" src="');
          __out.push(__sanitize(configData.onboarding_accountant_video_embed_url));
          __out.push('"></iframe>\n          </div>\n        </div>\n        ');
        }
        __out.push('\n        <div class="">\n          ');
        if (this.total_count < 1) {
          __out.push('\n            <p class="m-t-25 m-b-25 font-16">Add your clients to get started.</p>\n          ');
        }
        __out.push('\n          <button class="btn btn-blue add-business-client-btn">\n            <i class="display-inline-flex icon icon-plus m-r-10"></i>\n            <span>Add Business Client</span>\n          </button>\n          <div class="child-container hidden"></div>\n        </div>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}