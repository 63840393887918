import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { useUniqueDomId } from '@src/hooks/dom';

import { TOption } from './types';

import styles from './styles.module.scss';

interface IRadioInputProps extends Omit<React.ComponentPropsWithoutRef<'input' >, 'value' | 'size'> {
  options: TOption[],
}

const RadioCollectionInput = forwardRef<HTMLInputElement, IRadioInputProps>(({
  options,
  ...inputProps
}: IRadioInputProps, ref) => {
  const inputId = useUniqueDomId('radio_collection_input_');

  const classes = classNames(styles['radio-collection-input']);

  return (
    <>
      { options.map((option) => (
        <div key={ option.value } className={ classes }>
          <input
            ref={ ref }
            id={ option.value || inputId }
            type="radio"
            value={ option.value }
            { ...inputProps }
          />
          <label htmlFor={ `radio_collection_${option.value}` }>
            { option.label }
          </label>
        </div>
      )) }
    </>
  );
});

RadioCollectionInput.displayName = 'RadioCollectionInput';

export default React.memo(RadioCollectionInput);
