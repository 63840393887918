import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDwollaCustomer } from '@src/types/dwolla_customer';
import { currencyFormater2 } from '@src/utils/currency';

import Form from '@src/components/ui_v2/form';

interface IReviewACHTransferFormProps {
  formId?: string,
  serviceDocument?: IAccountsPayableServiceDocument,
  businessDwollaCustomer?: IDwollaCustomer,
  vendorDwollaCustomer?: IDwollaCustomer,
  onSubmit: () => void,
}

const ReviewACHTransferForm = ({
  serviceDocument,
  businessDwollaCustomer,
  vendorDwollaCustomer,
  formId,
  onSubmit,
}: IReviewACHTransferFormProps): JSX.Element => {
  let recipient = vendorDwollaCustomer?.businessName;
  if (vendorDwollaCustomer?.customerType === 'receive-only') {
    recipient = vendorDwollaCustomer?.displayName;
  }
  let bankAccount = vendorDwollaCustomer?.dwollaFundingSource?.name || '';
  if (vendorDwollaCustomer?.dwollaFundingSource?.last4) {
    bankAccount += vendorDwollaCustomer.dwollaFundingSource.last4;
  } else {
    bankAccount += '————';
  }
  let address = '---';
  if (vendorDwollaCustomer?.address1 || vendorDwollaCustomer?.address2) {
    address = [
      vendorDwollaCustomer?.address1,
      vendorDwollaCustomer?.address2,
      vendorDwollaCustomer?.city,
      vendorDwollaCustomer?.state,
      vendorDwollaCustomer?.postalCode,
    ].join(', ');
  }

  return (
    <Form id={ formId } onSubmit={ onSubmit }>
      <Form.StaticField
        label="Sender Bank Account"
        value={ businessDwollaCustomer?.dwollaFundingSource?.name }
      />
      <Form.StaticField
        label="Amount"
        value={ currencyFormater2(serviceDocument?.invoiceAmount || 0.0) }
      />
      <Form.StaticField
        label="Recipient"
        value={ recipient }
      />
      <Form.StaticField
        label="Recipient Bank Account"
        value={ bankAccount }
      />
      <Form.StaticField
        label="Recipient Address"
        value={ address }
      />
    </Form>
  );
};

export {
  IReviewACHTransferFormProps,
  ReviewACHTransferForm as default,
};
