import React from 'react';

import map from 'lodash/map';

const getFilesFromDragEvent = (e: React.DragEvent): File[] | null => {
  if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return null;

  return map(e.dataTransfer.files, (f) => f);
};

const getFirstFileFromDragEvent = (e: React.DragEvent): File | null => {
  if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return null;

  return e.dataTransfer.files[0];
};

const getPdfFilesFromDragEvent = (e: React.DragEvent): File[] | null => {
  if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return null;

  return map(e.dataTransfer.files, (file) => {
    if (file.type !== 'application/pdf') {
      throw new Error('Only PDFs are allowed.');
    }

    return file;
  });
};

export {
  getFilesFromDragEvent,
  getFirstFileFromDragEvent,
  getPdfFilesFromDragEvent,
};
