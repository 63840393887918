import React from 'react';

import classNames from 'classnames';

import Tooltip from '@src/components/ui_v2/tooltip';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ITableNumberCellProps extends Omit<ITableCellProps, 'children'> {
  number: number | undefined | null,
  emptyValue?: React.ReactNode,
  icon?: React.ReactNode,
  negativeValue?: boolean,
  tooltip?: React.ReactNode,
  tooltipProps?: Omit<React.ComponentPropsWithoutRef<typeof Tooltip.Hover>, 'content' | 'children'>,
}

const TableNumberCell = ({
  number,
  emptyValue = '—',
  className,
  icon,
  tooltip,
  tooltipProps,
  ...cellProps
}: ITableNumberCellProps) => {
  // eslint-disable-next-line max-len
  const classes = classNames(styles['table-number-cell'], className);

  let cellContent = (
    <>
      { icon }
      { ' ' }
      { number || emptyValue }
    </>
  );

  if (tooltip) {
    cellContent = (
      <Tooltip.Hover
        content={ tooltip }
        { ...tooltipProps }
      >
        { cellContent }
      </Tooltip.Hover>
    );
  }

  return (
    <Cell
      className={ classes }
      { ...cellProps }
    >
      { cellContent }
    </Cell>
  );
};

export default React.memo(TableNumberCell);
