import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import BankStatementDetailsModal from './bank_statement_details/details_modal';

interface IPendingReviewDetailsPageProps {
  businessId: TID,
  docytId: string,
  isAdmin: boolean,
  onCancel: () => void,
}

const PendingReviewDetailsPage = ({
  businessId,
  docytId,
  isAdmin,
  onCancel,
}: IPendingReviewDetailsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <BankStatementDetailsModal
          isOpen
          businessId={ businessId }
          isAdmin={ isAdmin }
          openValue={ { docytId } }
          onCancel={ onCancel }
          onDone={ onCancel }
        />
      </BusinessProvider>
    </Root>
  );
};

export default PendingReviewDetailsPage;
