/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';
import DropdownCheckboxItem from '@src/components/ui_v2/dropdown/checkbox_item';
import { TSubOption } from '@src/components/ui_v2/inputs/types';

interface IBusinessReportsMultiSelectItemProps {
  selected:             string[],
  option:               TSubOption,
  budgetsSelectValue?:  string[],
  forecastSelectValue?: string[],
  kind?:                string,
  childrenKind?:        string;
  onSelect:             (checked: boolean, value: string, itemKind?: string) => void,
  onSubMenuSelect:      (checked: boolean, option: TSubOption) => void,
}

const BusinessReportsMultiSelectItem = ({
  selected,
  option,
  budgetsSelectValue,
  forecastSelectValue,
  kind,
  childrenKind,
  onSelect,
  onSubMenuSelect
}: IBusinessReportsMultiSelectItemProps) => {
  const renderMainCheck = () => {
    if(childrenKind === 'budgets_comparison') {
      return budgetsSelectValue?.includes(option.value);
    }

    if (childrenKind === 'forecast_comparison') {
      return forecastSelectValue?.includes(option.value);
    }
    
    return selected.includes(option.value); 
  };

  const renderSubCheck = () => {
    if (option.children && childrenKind === option.value) {
      if (childrenKind === 'budgets_comparison') {
        return budgetsSelectValue?.length !== 0;
      }

      if (childrenKind === 'forecast_comparison') {
        return forecastSelectValue?.length !== 0;
      }

      const filter = selected.filter((f: string) => {
        return option.children?.find((ff: TSubOption) => ff.value === f);
      })

      return filter.length !== 0;
    }

    return false;
  };

  /**
   * Desc: Method to check is input partially checked (-)
   * @returns boolean
   */
  const isIntermediate = (): boolean => {
    const filter = selected.filter((f: string) => {
      return option.children?.find((ff: TSubOption) => ff.value === f);
    })
    
    return filter.length > 0 && filter.length !== option.children?.length;
  }

  const handleOnSelectSubMenu = () => {
    const checkedStatus = renderSubCheck();
    onSubMenuSelect(!checkedStatus, option);
  }

  const isDisabled = kind !== 'reportDetail' && kind !== 'reportOtherDetail' && kind !== 'reportDownload' ? { disabled: true } : {}

  return (
    <>
      { !option.children ? (
        <DropdownCheckboxItem
          { ...isDisabled }
          autoHide={ false }
          checked={ renderMainCheck() }
          value={ option.value }
          onSelect={ (checked: boolean) => onSelect(checked, option.value) }
        >
          {option.label}
        </DropdownCheckboxItem>
      ) : (
        <Dropdown.Submenu
          showCheckbox
          autoHide={ false }
          checked={ renderSubCheck() }
          onSelect={ handleOnSelectSubMenu }
          { 
            ...(option.label.search('Budgets') !== -1 
              ? { style: { borderTop: '1px solid rgba(0, 0, 0, 0.12)' } } 
              : { }
            ) 
          }
          isIntermediate={ isIntermediate() }
          title={ option.label }
        >
          { option.children.map((op: TSubOption) => (
            <BusinessReportsMultiSelectItem
              key={ op.value }
              budgetsSelectValue={ budgetsSelectValue }
              childrenKind={ childrenKind }
              forecastSelectValue={ forecastSelectValue }
              kind={ kind }
              option={ op }
              selected={ selected }
              onSelect={ (checked: boolean) => {
                if (kind === 'reportDetail' || kind === 'reportOtherDetail') {
                  onSelect(checked, op.value, childrenKind);
                } else {
                  onSelect(checked, op.value);
                }
              } }
              onSubMenuSelect={ onSubMenuSelect }
            />
          )) }
        </Dropdown.Submenu>
      ) }
    </>
  );
}

export default BusinessReportsMultiSelectItem;