import { TBookkeepingStatus, IBusinessBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';
import { TID } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

interface IBusinessBookkeepingStatusParam {
  status: TBookkeepingStatus,
  year: number,
  month: number,
}

interface IBusinessBookkeepingStatusUpdateParams {
  businessId: TID,
  businessBookkeepingStatus: IBusinessBookkeepingStatusParam,
}

const updateBusinessBookkeepingStatus = (
  { businessId, businessBookkeepingStatus }: IBusinessBookkeepingStatusUpdateParams,
): Promise<IBusinessBookkeepingStatus> => {
  const url = `/api/v1/businesses/${businessId}/business_bookkeeping_statuses`;
  const params = { businessBookkeepingStatus };

  return apiPost(url, underscoreKeys(params)).then((data) => {
    return data.business_bookkeeping_status as IBusinessBookkeepingStatus;
  });
};

export {
  updateBusinessBookkeepingStatus,
  IBusinessBookkeepingStatusUpdateParams,
};
