import React, { useState } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IReportServiceBudget,
  TReportServiceBudgetsSortColumn,
} from '@src/types/report_service/report_service_budgets';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IBudgetListProps {
  defaultBudgetId: string | undefined,
  budgets: IReportServiceBudget[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReportServiceBudgetsSortColumn>,
}

const BudgetList = ({
  defaultBudgetId,
  budgets,
  query,
  sorting,
}: IBudgetListProps): JSX.Element => {
  const [defaultBudget, setDefaultBudget] = useState(defaultBudgetId);

  return (
    <Table
      isRegionScroll
      infiniteQuery={ query }
      wrapperClassName="budget-list-table-wrapper budget-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            columnName="name"
            sorting={ sorting }
            style={ { paddingLeft: '20px' } }
            widthPercent={ 24 }
          >
            Name
          </Table.HCell>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            columnName="type"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Type
          </Table.HCell>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            columnName="year"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Period
          </Table.HCell>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            columnName="created_at"
            sorting={ sorting }
            widthPercent={ 13 }
          >
            Created
          </Table.HCell>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            columnName="creator_name"
            sorting={ sorting }
            widthPercent={ 18 }
          >
            Added By
          </Table.HCell>
          <Table.HCell<TReportServiceBudgetsSortColumn>
            className="text-right"
            columnName="total_amount"
            sorting={ sorting }
            style={ { paddingRight: '30px' } }
            widthPercent={ 15 }
          >
            Total Budget
          </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          budgets.map((budget) => (
            <Item
              key={ budget.id }
              budget={ budget }
              defaultBudgetId={ defaultBudget ?? defaultBudgetId }
              setAsDefault={ setDefaultBudget }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default BudgetList;
