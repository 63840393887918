import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IEllipsisIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'horizontal' | 'vertical',
}

const EllipsisHIcon = ({
  variant,
  ...props
}: IEllipsisIconProps) => {
  return (
    <FaIcon
      icon={ variant === 'horizontal' ? 'ellipsis-h' : 'ellipsis-v' }
      { ...props }
    />
  );
};

export default EllipsisHIcon;
