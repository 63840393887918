import React from 'react';

import { useAddReportTypeModal } from './add_report_type_modal';

const AddRevenueSystem = (): JSX.Element => {
  const addReportTypeModal = useAddReportTypeModal();

  return (
    <addReportTypeModal.Component
      { ...addReportTypeModal.props }
    />
  );
};

export default AddRevenueSystem;
