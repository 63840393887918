import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TSelectInputType } from '@src/types/common';

import { CheckboxInput, RadioInput } from '@src/components/ui_v2/inputs';
import { useItemSelector } from '@src/components/utils_v2/items_selector';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ISelectTableItemCellProps<Type> extends Omit<ITableCellProps, 'children'> {
  inputType?: TSelectInputType | string | undefined | null,
  item: Type,
  checkedLabel?: React.ReactNode,
  uncheckedLabel?: React.ReactNode,
  sticky?: boolean,
  inputProps?: Omit<React.ComponentPropsWithoutRef<typeof CheckboxInput>, 'onChange' | 'checked'>,
  onCheckboxClick? : (id: Type) => void
}

const SelectTableItemCell = <Type, >({
  inputType = 'checkbox',
  className,
  item,
  inputProps,
  sticky,
  onCheckboxClick,
  ...cellProps
}: ISelectTableItemCellProps<Type>) => {
  const classes = classNames(
    styles['table-select-item-cell'],
    {
      [styles.sticky]: sticky,
      [styles.left]: sticky,
    },
    className
  );

  const { mark, isSelected, setSelected } = useItemSelector();

  const handleChange = useCallback((checked) => {
    if (inputType === 'radio') {
      setSelected(item);
    } else {
      mark(item, checked);
    }
    onCheckboxClick?.(item);
  }, [inputType, item, mark, onCheckboxClick, setSelected]);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Cell className={ classes } onClick={ handleClick } { ...cellProps }>
      {
        inputType === 'radio' ? (
          <RadioInput
            small
            checked={ isSelected(item) }
            hiddenTitle={ isSelected(item) ? 'Unselect' : 'Select' }
            onChange={ handleChange }
            { ...inputProps }
          />
        ) : (
          <CheckboxInput
            small
            checked={ isSelected(item) }
            hiddenTitle={ isSelected(item) ? 'Unselect' : 'Select' }
            onChange={ handleChange }
            { ...inputProps }
          />
        )
      }
    </Cell>
  );
};

export default React.memo(SelectTableItemCell);
