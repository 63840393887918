module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td>');
    
      if (this.start_date) {
        __out.push(__sanitize(moment(this.start_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
      }
    
      __out.push('</td>\n<td>');
    
      if (this.end_date) {
        __out.push(__sanitize(moment(this.end_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
      }
    
      __out.push('</td>\n<td>');
    
      if (this.uploaded_at) {
        __out.push(__sanitize(moment(this.uploaded_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
      }
    
      __out.push('</td>\n<td class="status-column display-flex align-items-center">\n  ');
    
      if (this.state === 'processing') {
        __out.push('\n  <span class="text-grey m-r-10">');
        __out.push(__sanitize(this.processing_percentage));
        __out.push('%</span>\n  <div class="progress m-b-3" style="flex:1; height:10px">\n    <div class="progress-bar" style="width:');
        __out.push(__sanitize(this.processing_percentage));
        __out.push('%">\n    </div>\n  </div>\n  ');
      } else if (this.state === 'uploaded') {
        __out.push('\n  <span class="text-grey">Uploaded</span>\n  ');
      } else if (this.state === 'error') {
        __out.push('\n  <a class="text-danger pointer view-error-btn"><span class="icon icon-warning-triangle font-22 m-r-4"></span>Error: View Details</a>\n  ');
      } else {
        __out.push('\n  <span class="text-grey">Last updated: ');
        __out.push(__sanitize(moment(this.updated_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push(' </span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="import-actions-column"></td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}