module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.street));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.address_state));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  <span class="block-wrapper p-l-10 p-t-7">');
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('</span>\n</td>\n<td class="pointer">\n  ');
    
      if (this.id === this.ledger_address_id) {
        __out.push('\n    <span class="icon-qbo-on vertical-align-middle p-l-10"></span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="pointer">\n  ');
    
      if (this.address_country !== 'US') {
        __out.push('\n    <span class="vendor-address-international-label p-l-10 p-t-7">International</span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="pointer">\n  ');
    
      if (this.is_authorized) {
        __out.push('\n    <span class="vendor-address-authorized-label p-l-10 p-t-7">Authorized</span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="pointer relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="address-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="address-item-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="address-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    ');
    
      if (this.id !== this.ledger_address_id) {
        __out.push('\n    <a class="dropdown-item font-14 set-ledger-address-link">\n      <i class="icon icon-qbo-notebook"></i>\n      <span class=\'item-label\'>Set as Ledger Address</span>\n    </a>\n    ');
      }
    
      __out.push('\n    <a class="dropdown-item font-14 edit-address-link">\n      <i class="icon icon-pencil"></i>\n      <span class="item-label">Edit Address</span>\n    </a>\n    ');
    
      if (this.is_authorized) {
        __out.push('\n      <a class="dropdown-item font-14 unauthorize-address-link">\n        <i class="icon icon-thumbs-down"></i>\n        <span class="item-label">Unauthorize Address</span>\n      </a>\n    ');
      } else {
        __out.push('\n      <a class="dropdown-item font-14 authorize-address-link">\n        <i class="icon icon-thumbs-up"></i>\n        <span class="item-label">Authorize Address</span>\n      </a>\n    ');
      }
    
      __out.push('\n    <a class="dropdown-item font-14 remove-address-link">\n      <i class="icon icon-trashcan in-red-400"></i>\n      <span class=\'in-red-400 item-label\'>Delete Address</span>\n    </a>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}