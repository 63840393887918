import React, { useState, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useDeleteW9InfoFile } from '@src/hooks/queries/vendor_w9_info';
import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon, VendorDownloadFormIcon } from '@src/components/utils/icomoon';

import styles from '../modals/w9_modal/styles.module.scss';

interface IW9InfoModalActionProps {
  vendor: IW9InfoResponse;
  businessId: TID,
  id: TID,
}

const W9InfoModalAction = ({
  vendor,
  businessId,
  id,
}: IW9InfoModalActionProps) => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const deleteVendorW9File = useDeleteW9InfoFile();

  const { mutate: deleteW9File } = deleteVendorW9File;

  const handleError = useCallback((response) => {
    setErrorMsg(response?.response?.data?.errors[0]);
  }, []);

  const handleDeleteW9File = useCallback(() => {
    deleteW9File(
      {
        businessId,
        vendorId: id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.vendorW9Info);
        },
        onError: handleError,
      },
    );
  }, [deleteW9File, businessId, id, queryClient, handleError]);

  const downloadFile = useCallback(() => {
    if (vendor?.w9FileUrl && vendor?.w9FileOriginalName) {
      const link = document.createElement('a');
      link.href = vendor.w9FileUrl;
      link.download = vendor.w9FileOriginalName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [vendor]);

  return (
    <>
      {(deleteVendorW9File.isLoading) && <Spinner />}
      {errorMsg && <ErrorNotification message={ errorMsg } title="Something went wrong" onHidden={ () => setErrorMsg(undefined) } />}
      <div className={ styles.header }>
        <ActionsDropdown className={ styles['dropdown-icon'] } classNameMenus={ styles['dropdown-menu'] }>
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <VendorDownloadFormIcon fontSize={ 20 } /> }
            title="Download W9 Form"
            onClick={ downloadFile }
          />
          <ActionsDropdown.Action
            danger
            className={ styles['dropdown-item'] }
            icon={ <TrashcanIcon fontSize={ 18 } /> }
            title="Remove W9 Form"
            onClick={ handleDeleteW9File }
          />
        </ActionsDropdown>
      </div>
    </>
  );
};

export default React.memo(W9InfoModalAction);
