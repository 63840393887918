import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import StateItem from './bank_statement_state_item';

interface IBankStatementStateListProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  states: IReviewedBankStatementState[],
  onCloseUploadModal: () => void,
}

const BankStatementStateList = ({
  businessId,
  rcPaymentAccountId,
  states,
  onCloseUploadModal,
}: IBankStatementStateListProps): JSX.Element => {
  return (
    <>
      {
        states.map((state) => {
          return (
            <StateItem
              key={ state.month }
              businessId={ businessId }
              rcPaymentAccountId={ rcPaymentAccountId }
              state={ state }
              onCloseUploadModal={ onCloseUploadModal }
            />
          );
        })
      }
    </>
  );
};

export default BankStatementStateList;
