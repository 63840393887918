import React, { useEffect } from 'react';

import toastr, { IToastrOptions } from '@lib/toastr';

type TNotificationType = 'error' | 'info' | 'success';

interface IBaseNotificationProps extends IToastrOptions {
  type: TNotificationType,
  title?: string,
}

interface IErrorNotificationProps extends IBaseNotificationProps {
  type: 'error',
  error?: Error | null,
  message?: string,
}

interface IInfoNotificationProps extends IBaseNotificationProps {
  type: 'info',
  error?: undefined,
  message: string,
}

interface ISuccessNotificationProps extends IBaseNotificationProps {
  type: 'success',
  error?: undefined,
  message: string,
}

type TNotificationProps =
  IErrorNotificationProps |
  IInfoNotificationProps |
  ISuccessNotificationProps;

/**
 * Show notification on mount using the [Toastr](https://github.com/CodeSeven/toastr) library
 *
 * Usually you don't have to use this components directly and may use
 * `MutationStatus` and `QueryStatus` components.
 */
const Notification = ({
  error,
  message,
  title,
  type,
  ...toastrOptions
}: TNotificationProps): JSX.Element => { // This component doesn't return any HTML, only runs effect
  useEffect(() => {
    switch (type) {
      case 'error':
        toastr.error(
          error?.message || message || 'Something wrong',
          title || 'Error',
          toastrOptions,
        );
        break;
      case 'info':
        // message always present for this type
        toastr.info(message!, title || 'Info', toastrOptions);
        break;
      case 'success':
        // message always present for this type
        toastr.success(message!, title || 'Success', toastrOptions);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Unknown notification type: ', type);
        break;
    }
  // This effect should be run only once during mount, so there is no dependencies
  // to prevent new runs.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ null }</>;
};

export {
  IErrorNotificationProps,
  IInfoNotificationProps,
  ISuccessNotificationProps,
  Notification as default,
};
