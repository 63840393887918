import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetMetricsReports } from '@src/hooks/queries/metrics/metrics_reports';
import { useSorting } from '@src/hooks/url_params';
import { IGetMetricsReportsResponse } from '@src/requests/metrics/metrics_reports';
import { TID, TSection } from '@src/types/common';
import { IMetricsReport, MetricReportStatusEnum, TMetricReportsSortColumn } from '@src/types/metrics/metrics_reports';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IMetricsReportCollection {
  query: UseInfiniteQueryResult<IGetMetricsReportsResponse, Error>;
  records: IMetricsReport[];
  section: TSection;
  sorting: ISorting<TMetricReportsSortColumn>;
  selectedRecords: IMetricsReport[];
  onMetricReportUpdated: (status: MetricReportStatusEnum) => void;
}

interface IMetricsReportCollectionParams {
  businessId: TID;
  onMetricReportUpdated: (status: MetricReportStatusEnum) => void;
}

const defaultSorting: ISortingParams<TMetricReportsSortColumn> = {
  orderColumn:    'created_at',
  orderDirection: 'desc',
};

const useMetricsReportCollection = ({
  businessId,
  onMetricReportUpdated,
}: IMetricsReportCollectionParams): IMetricsReportCollection => {
  const section = useMemo(() => ({
    businessId,
    section: Section.MetricsReport,
  }), [businessId]);

  const sorting = useSorting<TMetricReportsSortColumn>({
    defaultSorting,
  });

  const query = useGetMetricsReports({
    businessId,
    ...sorting.data,
  }, onMetricReportUpdated);

  const records: IMetricsReport[] = useMemo(() => {
    const pages = query.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(
    () => records.filter((r) => selectedIds.includes(r.id)),
    [selectedIds, records],
  );

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
    onMetricReportUpdated,
  };
};

export {
  useMetricsReportCollection,
  IMetricsReportCollection,
};
