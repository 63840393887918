import React, { useCallback, useMemo } from 'react';

import { useGetQuickbooksCustomers } from '@src/hooks/queries/quickbooks_customers';
import { TID } from '@src/types/common';

import {
  ISelectInputProps,
  SelectInput,
} from '@src/components/ui_v2/inputs';

interface IQuickbooksCustomerInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  value?: TID | null,
  onChange?: (value?: TID | null) => void,
}

const QuickbooksCustomerInput = ({
  businessId,
  value,
  onChange,
  ...props
}: IQuickbooksCustomerInputProps): JSX.Element => {
  const query = useGetQuickbooksCustomers({ businessId });

  const handleChange = useCallback((newValue) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null);
  }, [onChange]);

  const options = useMemo(() => {
    return query.data?.quickbooksCustomers.map((customer) => ({
      value: String(customer.id),
      label: customer.name,
    })) || [];
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return options.find((customer) => customer.value === String(value)) || null;
  }, [value, options]);

  return (
    <SelectInput
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(QuickbooksCustomerInput);
