import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { currencyFormater3 } from '@src/utils/currency';

import { totalAmount } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';

import style from '@src/components/accounts_payable/review_payment/styles.module.scss';

interface IReviewPaymentTableItemProps {
  serviceDocuments: IAccountsPayableServiceDocument[],
}

const ReviewPaymentTableItemTotalAmount = ({
  serviceDocuments,
}: IReviewPaymentTableItemProps) => {
  const amount = totalAmount(serviceDocuments);
  const subtotal = `Subtotal: ${currencyFormater3(amount)}`;
  return (
    <tr className={ style['with-border-row'] }>
      <td colSpan={ 6 }>
        <span className="pull-right p-b-15">
          { subtotal }
        </span>
      </td>
    </tr>
  );
};

export default React.memo(ReviewPaymentTableItemTotalAmount);
