import React from 'react';

import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import CollectionTable from '@src/components/ui_v2/collection_table';

import Actions from './actions';
import BulkUnreconcileAction from './bulk_unreconcile_action';
import ReconcileDocumentsFilter from './filter';
import StatusIcon from '../action_icons/income_status_icon';
import { ITSDCollected } from '../hooks';

interface IReconcileDocumentsIncomesTableProps {
  collection: ITSDCollected,
  paymentProcessorName: string,
}

const statusIcon = (document: ITransactionServiceDocument) => {
  return <StatusIcon document={ document } />;
};

const accountName = (document: ITransactionServiceDocument) => {
  if (document.employeeCardName && document.employeeCardName !== document.paymentAccountName) {
    return [document.paymentAccountName, document.employeeCardName].join(' / ');
  }

  return document.paymentAccountName;
};

const actions = (document: ITransactionServiceDocument, paymentProcessorName: string) => {
  return (
    <Actions
      document={ document }
      paymentProcessorName={ paymentProcessorName }
    />
  );
};

const ReconcileDocumentsIncomesTable = ({
  collection,
  paymentProcessorName,
}: IReconcileDocumentsIncomesTableProps) => {
  return (
    <CollectionTable<ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      bulkActions={ (
        <BulkUnreconcileAction
          paymentProcessorName={ paymentProcessorName }
          selectedRecords={ collection.selectedRecords }
        />
      ) }
      filter={ (
        <ReconcileDocumentsFilter />
      ) }
      height="750px"
      query={ collection.query }
      records={ collection.records }
      sorting={ collection.sorting }
    >
      <CollectionTable.DateColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="transaction_date"
        sortColumn="transaction_date"
        title="Date"
        value={ (document) => document.transactionDate }
        width="20%"
      />
      <CollectionTable.TextColumn<
        ITransactionServiceDocument,
        TTransactionServiceDocumentsSortColumn
      >
        name="description"
        sortColumn="description"
        title="Description"
        value={ (document) => document.description }
        width="30%"
      />
      <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="payment_account"
        sortColumn="payment_account"
        title="Banking Account"
        value={ accountName }
        width="30%"
      />
      <CollectionTable.AmountColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (document) => document.amount }
        width="20%"
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="status"
        title="Status"
        value={ statusIcon }
      />
      <CollectionTable.ActionsColumn<ITransactionServiceDocument>
        name="actions"
        title="Actions"
        value={ (document) => actions(document, paymentProcessorName) }
      />
    </CollectionTable>
  );
};

export default React.memo(ReconcileDocumentsIncomesTable);
