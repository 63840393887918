import React from 'react';

import { TID } from '@src/types/common';

import AddAdvanceRuleItemView from './advance_rule_item_view';
import BasicRuleItemView from './basic_rule_item_view';
import { IAccountsPayablePolicy, IInitialData } from './types';

interface IRuleItemViewProp {
    handleDeleteTransaction: () => void;
    handleAddEditClick: (data: IInitialData | null) => void;
    handleClickDone: (policyType : string) => void;
    initialData: IInitialData | null;
    isAdvanceRule?: boolean;
    hasAdvancePlan?: boolean;
    onConditionSelected: (condition: string, open: boolean) => void;
    handleOnTransactionDeleted: (id: number) => void;
    openSideview: (condition: string, open: boolean) => void;
    handleVendorDeleted: () => void;
    approverPolicy: IAccountsPayablePolicy | undefined;
    businessId: TID;
    policyType: string;
}

const RuleListView = ({
  handleDeleteTransaction,
  handleAddEditClick,
  handleClickDone,
  initialData,
  hasAdvancePlan,
  onConditionSelected,
  isAdvanceRule = false,
  handleOnTransactionDeleted,
  openSideview,
  handleVendorDeleted,
  businessId,
  approverPolicy,
  policyType,
}: IRuleItemViewProp) => {
  return (
    <div className="table-responsive roles-table-wrapper service-detail-view m-b-20">
      <table className="table m-b-0">
        {hasAdvancePlan && isAdvanceRule ? (
          <>
            <thead>
              <th className="font-16 font-semi-bold" colSpan={ 3 }>
                Advanced Rule
              </th>
            </thead>
            <tbody>
              <AddAdvanceRuleItemView
                approverPolicy={ approverPolicy }
                handleAddEditClick={ handleAddEditClick }
                handleClickDone={ handleClickDone }
                handleDeleteTransaction={
                                    handleDeleteTransaction
                                }
                handleOnTransactionDeleted={
                                    handleOnTransactionDeleted
                                }
                handleVendorDeleted={ handleVendorDeleted }
                initialData={ initialData }
                openSideview={ openSideview }
                onConditionSelected={ onConditionSelected }
              />
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <th className="font-16 font-semi-bold" colSpan={ 3 }>
                Default Rule
              </th>
            </thead>
            <tbody>
              <BasicRuleItemView
                approverPolicy={ approverPolicy }
                businessId={ businessId }
                hasAdvancePlan={ hasAdvancePlan }
                policyType={ policyType }
              />
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default RuleListView;
