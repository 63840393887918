import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import {
  IAnalyticsResponse,
  IAnalyticsParams,
  IAverageBookkeepingRateParams,
} from '@src/components/ai_analytics/types';

import { apiGet, apiPut } from '../helpers';

const getAnalytics = (
  params: IAnalyticsParams,
): Promise<IAnalyticsResponse> => {
  return apiGet(
    '/api/v1/ai_analytics/show_analytics',
    underscoreKeys(params),
  ).then((result) => {
    return camelizeKeys(result) as IAnalyticsResponse;
  });
};

const putAverageBookkeepingRate = (
  params: IAverageBookkeepingRateParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/ai_analytics/update_average_bookkeeping_rate',
    underscoreKeys(params),
  );
};

export { getAnalytics, putAverageBookkeepingRate };
