module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="external-invoice-filter-container">\n    <div class="filter-group width-50px"></div>\n    <div class="filter-group invoice-filter-payee width-30-percent">\n        <input type="text" class="form-control input-vendor typeahead" placeholder="Payee" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.vendor ? this.filter.vendor.trim() : ''));
    
      __out.push('"/>\n    </div>\n    <div class="input-group filter-group transaction-filter-date-range invoice-filter-date-range width-30-percent">\n        <input type="text" class="form-control date-picker filter-start-date" placeholder="Start Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.start_date));
    
      __out.push('" />\n        <span class="input-group-addon">-</span>\n        <input type="text" class="form-control date-picker filter-end-date" placeholder="End Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.end_date));
    
      __out.push('" />\n    </div>\n    <div class="filter-group invoice-filter-status">\n        <select class="form-control filter-status selectpicker" title="Status">\n            <option value="ready_for_payment">Ready to pay</option>\n            <option value="paid">Paid</option>\n        </select>\n    </div>\n    <div class="input-group filter-group transaction-filter-amount-range invoice-filter-amount-range">\n        <input type="number" class="form-control filter-min-amount" placeholder="Min" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.amount_min));
    
      __out.push('" />\n        <span class="input-group-addon">-</span>\n        <input type="number" class="form-control filter-max-amount" placeholder="Max" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.amount_max));
    
      __out.push('" />\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}