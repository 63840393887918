import React, { useCallback } from 'react';

import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useEmptyTrashInboxServiceDocument } from '@src/hooks/queries/inbox_service_documents';

import { useConfirmDeleteDocumentsPermanentlyModal } from '@src/components/common/confirm_delete';
import Dropdown from '@src/components/ui/dropdown';
import { TrashIcon } from '@src/components/utils/fa_icons';
import MutationStatus from '@src/components/utils/mutation_status';

const BusinessInboxPermanentDeleteAllAction = () => {
  const service = useInboxServiceContext();
  const emptyTrash = useEmptyTrashInboxServiceDocument();

  const { mutate } = emptyTrash;
  const handleConfirm = useCallback(() => {
    mutate(
      { serviceId: service.id, isRestore: false },
    );
  }, [mutate, service.id]);

  const confirm = useConfirmDeleteDocumentsPermanentlyModal({
    documentIds: 'all',
    onDone:      handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ emptyTrash } />
      <confirm.Component { ...confirm.props } />
      <Dropdown.Item onSelect={ confirm.open }>
        <TrashIcon fontSize={ 20 } mr={ 22 } />
        {' '}
        Empty Trash
      </Dropdown.Item>
    </>
  );
};

export default BusinessInboxPermanentDeleteAllAction;
