import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import StatusSelect from './status_select';
import { IMyPracticeFilter } from './types';

const roleOptions = [
  { value: '', name: 'All roles' },
  { value: 'staff', name: 'Staff (not visible to clients)' },
  { value: 'account_manager', name: 'Account Manager' },
];

const titleOptions = [
  { value: '', name: 'All titles' },
  { value: 'CFO', name: 'CFO' },
  { value: 'Controller', name: 'Controller' },
  { value: 'Partner', name: 'Partner' },
  { value: 'Manager', name: 'Manager' },
  { value: 'Staff', name: 'Staff' },
  { value: 'BookKeeper', name: 'Bookkeeper' },
  { value: 'Intern', name: 'Intern' },
  { value: 'Sr. BookKeeper', name: 'Sr. BookKeeper' },
];

interface IMyPracticeFilterProps {
  filter: IFilter<IMyPracticeFilter>;
  accountingFirms: IBusiness[],
}

const MyPracticeFilter = ({
  filter,
  accountingFirms,
}: IMyPracticeFilterProps): JSX.Element => {
  const firmOptions = accountingFirms.map((accountingFirm) => ({
    value: String(accountingFirm.id),
    name:  accountingFirm.displayName,
  }));

  return (
    <Filter<IMyPracticeFilter> filter={ filter }>
      <Filter.TextField
        name="name"
        placeholder="Filter by Client/Name/Email"
        widthPercent={ 18 }
      />
      <Filter.TextField
        name="memberName"
        placeholder="Filter by Team Member"
        widthPercent={ 15 }
      />
      <Filter.SelectPickerField
        name="accountantRole"
        options={ roleOptions }
        title="All roles"
        widthPercent={ 10 }
      />
      {
        filter.data?.accountantRole === 'account_manager' && (
          <Filter.SelectPickerField
            name="accountantTitle"
            options={ titleOptions }
            title="All titles"
            widthPercent={ 10 }
          />
        )
      }
      <Filter.YearField
        name="bookkeepingYear"
        options={ { endDate: new Date() } }
        placeholder="Year"
        widthPercent={ 12 }
      />
      <Filter.SelectPickerField
        name="accountingFirmBusinessId"
        options={ firmOptions }
        title="Accounting Firm"
        widthPercent={ 18 }
      />
      <StatusSelect widthPercent={ 15 } />
      <Filter.ResetButton />
    </Filter>
  );
};

export default MyPracticeFilter;
