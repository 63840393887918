import React, { useCallback } from 'react';

import { useUpdateReport } from '@src/hooks/queries/report_service/custom_reports';
import { IReport } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { IRenameReportFormValues } from '../forms/schema';
import { useRenameReportModal } from '../modals/rename_report';

import styles from '../styles.module.scss';

interface IRenameReportActionProps {
  report: IReport,
}

const RenameReportAction = ({
  report,
}: IRenameReportActionProps): JSX.Element => {
  const updateReport = useUpdateReport();

  const handleRenameReport = useCallback((values: IRenameReportFormValues) => {
    updateReport.mutate({
      reportId: report.id,
      name:     values.name,
    });
  }, [report.id, updateReport]);
  const renameReportModal = useRenameReportModal({ onDone: handleRenameReport });

  return (
    <>
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <EditIcon fontSize={ 18 } /> }
        title="Rename Report"
        onSelect={ renameReportModal.open }
      />
      <MutationStatus mutation={ updateReport } />
      <renameReportModal.Component
        { ...renameReportModal.props }
        report={ report }
      />
    </>
  );
};

export default RenameReportAction;
