import React, { forwardRef, useCallback } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { TAmount } from '@src/types/common';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

interface IAmountInputProps extends Omit<NumberFormatProps, 'value' | 'onChange'>
{
  value?: TAmount | undefined,
  onChange: (value: TAmount | undefined) => void,
}

const AmountInput = forwardRef<HTMLInputElement, IAmountInputProps>(({
  onChange,
  ...props
}: IAmountInputProps, ref) => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  const handleChange = useCallback((values) => {
    if (onChange) onChange(values.value);
  }, [onChange]);

  return (
    <NumberFormat
      isNumericString
      thousandSeparator
      className={ classes }
      decimalScale={ 2 }
      getInputRef={ ref }
      inputMode="numeric"
      prefix="$"
      { ...inputProps }
      onValueChange={ handleChange }
    />
  );
});

AmountInput.displayName = 'AmountInput';
const MemoizedAmountInput = React.memo(AmountInput);

export {
  IAmountInputProps,
  MemoizedAmountInput as default,
};
