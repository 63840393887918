import { NumberFormatProps } from 'react-number-format';

const amountFormatterProps: NumberFormatProps = {
  fixedDecimalScale: true,
  isNumericString:   true,
  thousandSeparator: true,
  decimalScale:      2,
  displayType:       'text',
  inputMode:         'numeric',
  prefix:            '$',
};

const amountInputProps: NumberFormatProps = {
  fixedDecimalScale: true,
  isNumericString:   true,
  thousandSeparator: true,
  autoComplete:      'off',
  decimalScale:      2,
  inputMode:         'numeric',
  prefix:            '$ ',
};

export {
  amountFormatterProps,
  amountInputProps,
};
