import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { SettingsCogIcon } from '@src/components/utils/icomoon';

import Actions from './actions';

interface ISettingsPageProps {
  currentBusinessClientId?: TID,
  curBusinessId: TID,
  isReceivableOnly: boolean,
  isBusinessUser: boolean,
  isClientView: boolean,
}

const SettingsPage = ({
  currentBusinessClientId,
  curBusinessId,
  isReceivableOnly,
  isBusinessUser,
  isClientView,
}: ISettingsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ curBusinessId }>
        <ActionsDropdown
          className="header-item-dropdown"
          title={ <SettingsCogIcon fontSize={ 24 } /> }
          titleClassName="header__notifications"
        >
          <Actions
            curBusinessId={ curBusinessId }
            currentBusinessClientId={ currentBusinessClientId }
            isBusinessUser={ isBusinessUser }
            isClientView={ isClientView }
            isReceivableOnly={ isReceivableOnly }
          />
        </ActionsDropdown>
      </BusinessProvider>
    </Root>
  );
};

export default SettingsPage;
