import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useAddBudgetModal } from './modal';

const AddBudgetReportAction = () => {
  const business = useBusinessContext();
  const reportService = useReportServiceContext();
  const add = useAddBudgetModal();

  return (
    <>
      <add.Component
        { ...add.props }
        businessId={ business.id }
        reportServiceId={ reportService.id }
        standardCategoryId={ business.standardCategoryId }
      />
      <Button
        style={{ borderRadius: '100%', padding: '7px', marginRight: '5px' }}
        variant="primary"
        onClick={ add.open }
      >
        <PlusIcon fontSize={20} />
      </Button>
    </>
  );
};

export default AddBudgetReportAction;
