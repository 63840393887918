module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="radio radio-inline m-t-0 m-b-0 font-15 pointer m-b-20 payment-address-radio width-100-percent">\n  <input type="radio" class="styled form-control pointer input-element" name="payment-address">\n  <label class="width-100-percent">\n    <div class="p-t-5 display-flex justify-content-between width-100-percent">\n      ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n      <div>\n      ');
    
      if (this.has_ledger_address) {
        __out.push('\n        <span class="icon-qbo-on vertical-align-middle font-20 p-r-10"></span>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.is_authorized) {
        __out.push('\n        <span class="vendor-address-authorized-label p-l-10 p-t-7">Authorized</span>\n      ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div>');
    
      __out.push(__sanitize(this.company));
    
      __out.push('</div>\n    <div class="display-flex justify-content-between width-100-percent">\n      ');
    
      __out.push(__sanitize(this.address_line1));
    
      __out.push('\n      <div>\n      ');
    
      if (this.address_country !== 'US') {
        __out.push('\n        <span class="vendor-address-international-label p-l-10">International</span>\n      ');
      }
    
      __out.push('\n      </div>\n    </div>\n    ');
    
      if (this.address_line2) {
        __out.push('<div class="">');
        __out.push(__sanitize(this.address_line2));
        __out.push('</div>');
      }
    
      __out.push('\n    <div class="">');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.address_state));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push(' </div>\n    <div class="">');
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('</div>\n  </label>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}