import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateBusiness } from '@src/hooks/queries/businesses';
import { clientBusinessSettingsPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IAddBusinessClientFormValues } from './add_business_client_form';

const AddBusinessClient = ({
  isOpen,
  onCancel,
  onDone,
}: IUseModalProps) => {
  const createBusiness = useCreateBusiness();
  const handleSubmit = useCallback((value: IAddBusinessClientFormValues) => {
    createBusiness.mutate(
      {
        business: {
          addBusinessAdvisor:        true,
          displayName:               value.name,
          name:                      value.name,
          serviceProviderBusinessId: value.businessId,
        },
      },
      {
        onSuccess: (res) => {
          onDone();
          backboneNavigateToUrl(
            clientBusinessSettingsPath(res.business.id, value.businessId),
          );
        },
      },
    );
  }, [createBusiness, onDone]);
  return (
    <>
      <MutationStatus
        mutation={ createBusiness }
        successMessage="Created new management group successfully."
      />
      <Modal.Form
        proceedTitle="Confirm"
        show={ isOpen }
        title="Add Business Client"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useAddBusinessClientModal = makeUseModal(AddBusinessClient);

export {
  useAddBusinessClientModal,
  AddBusinessClient as default,
};
