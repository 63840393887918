import React from 'react';

import { IPaymentAccount } from '@src/types/payment_accounts';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import EmployeeCardArchiveAction from './archive_action';
import EmployeeCardDeleteAction from './delete_action';
import EmployeeCardUnarchiveAction from './unarchive_action';

interface IEmployeeCardActionMenuProps {
  employeeCardItem: IPaymentAccount,
  refetchEmployeeCard(): void
}

const EmployeeCardActionMenu = ({
  employeeCardItem,
  refetchEmployeeCard,
}: IEmployeeCardActionMenuProps) => {
  return (
    <ActionsDropdown>
      {employeeCardItem.isArchived ? (
        <EmployeeCardUnarchiveAction employeeCardItem={ employeeCardItem } />
      ) : (
        <EmployeeCardArchiveAction
          employeeCardItem={ employeeCardItem }
          refetchEmployeeCard={ refetchEmployeeCard }
        />
      )}
      <EmployeeCardDeleteAction
        employeeCardItem={ employeeCardItem }
        refetchEmployeeCard={ refetchEmployeeCard }
      />
    </ActionsDropdown>
  );
};

export default EmployeeCardActionMenu;
