import React, { useCallback, useMemo, useState } from 'react';

import { useGetWithMappingAccountingClasses } from '@src/hooks/queries/accounting_classes';
import { TID } from '@src/types/common';

import useAddDepartmentModal from './add_department_modal';

interface IAddDepartmentProps {
  businessId: TID,
}

const AddDepartment = ({
  businessId,
}: IAddDepartmentProps) => {
  const [mappedIds, setMappedIds] = useState<string[]>([]);

  const addDepartment = useAddDepartmentModal();

  const query = useGetWithMappingAccountingClasses({ businessId });
  const accountingClasses = useMemo(() => {
    return query.data?.accountingClasses ?? [];
  }, [query.data]);

  const handleContinue = useCallback((value) => {
    if (!value.mappingClassIds) {
      setMappedIds([]);
    } else {
      setMappedIds(value.mappingClassIds);
    }
  }, []);

  if (accountingClasses.length === 0) return null;

  return (
    <>
      <addDepartment.Component
        { ...addDepartment.props }
        accountingClasses={ accountingClasses }
        onDone={ handleContinue }
      />
      <div className="display-flex">
        <span className="width-25-percent">Mapped Departments</span>
        {
          mappedIds.length === 0 ? (
            <a
              className="font-bold"
              role="button"
              tabIndex={ 0 }
              onClick={ addDepartment.open }
            >
              Add Departments
            </a>
          ) : (
            <div className="display-flex width-100-percent m-l-30 m-r-20 justify-content-between">
              <div className="display-flex-column">
                {
                  mappedIds.map((id) => (
                    <span key={ id }>
                      { accountingClasses.find((item) => item.id === Number(id))?.name }
                    </span>
                  ))
                }
              </div>
              <a
                className="font-bold"
                role="button"
                tabIndex={ 0 }
                onClick={ addDepartment.open }
              >
                Edit
              </a>
            </div>
          )
        }
      </div>
    </>
  );
};

export default AddDepartment;
