import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { IMetric } from '@src/types/metrics';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';

import Actions from './actions';

interface IMetricsListItemProps {
  metric: IMetric,
  businessId: TID
}

const MetricsListItem = ({
  metric,
  businessId,
}: IMetricsListItemProps): JSX.Element => {
  const handleMetricClick = useCallback(() => {
    Backbone.history.navigate(`businesses/${businessId}/metrics_service/${metric.id}/values`, { trigger: true });
  }, [metric, businessId]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        widthPercent={ 40 }
        onClick={ handleMetricClick }
      >
        <Tooltip
          className="pointer"
          style={ { color: 'black' } }
          title={ metric.description }
        >
          { metric.name }
        </Tooltip>
      </Table.Cell>
      <Table.DateCell
        pointer
        date={ metric.lastUpdatedAt }
        style={ { paddingLeft: '15px', height: '50px' } }
        widthPercent={ 20 }
        onClick={ handleMetricClick }
      />
      <Table.DateCell
        pointer
        date={ metric.startDate }
        style={ { paddingLeft: '15px' } }
        widthPercent={ 20 }
        onClick={ handleMetricClick }
      />
      <Table.Cell
        pointer
        style={ { paddingLeft: '15px' } }
        widthPercent={ 20 }
        onClick={ handleMetricClick }
      >
        {metric.frequency}
      </Table.Cell>
      <Table.Cell
        hidden
        pointer
        style={ { paddingLeft: '15px' } }
        widthPercent={ 15 }
        onClick={ handleMetricClick }
      >
        { metric.missedEntries }
      </Table.Cell>
      <Table.Cell>
        <Actions metric={ metric } />
      </Table.Cell>
    </Table.Row>
  );
};

export default MetricsListItem;
