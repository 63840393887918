import React from 'react';

import Header from './header';
import List from './list_item/list';

const MetricsServiceDetailsV2 = (): JSX.Element => {

  return (
    <div>
      <Header />
      <List />
    </div>
  );
};

export {
  MetricsServiceDetailsV2 as default,
};
