import { QueryKey } from '@src/constants/query_keys';
import {
  getVendorPayments,
  IGetVendorPaymentsParams,
  IVendorPaymentsResponse,
} from '@src/requests/vendor_payments';
import { IVendorPayment } from '@src/types/vendor_payments';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const useGetVendorPayments = createUseGetInfiniteCollection<
  IVendorPayment,
  IGetVendorPaymentsParams,
  IVendorPaymentsResponse
>({
  queryKey: QueryKey.vendorPayments,
  request:  getVendorPayments,
});

export {
  useGetVendorPayments,
};
