import { IReceivableAccountsPayableService } from '@src/types/accounts_payable/accounts_payable_services';
import { TDate, TID } from '@src/types/common';
import { IServiceSummary } from '@src/types/service_summary';
import { IAccountsPayableService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from '../helpers';

const getAccountsPayableServiceSummary = (
  businessId: TID,
  invoiceType: string,
): Promise<IServiceSummary> => {
  return apiGet(
    '/api/v1/accounts_payable_services/service_summary',
    underscoreKeys({ businessId, invoiceType }),
  ).then(
    (data) => camelizeKeys(data) as IServiceSummary,
  );
};

const getAccountsPayableServiceByBusinessId = (
  businessId: TID,
): Promise<IAccountsPayableService> => {
  return apiGet(
    '/api/v1/accounts_payable_services/get_by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.accounts_payable_advisor) as IAccountsPayableService);
};

interface IGetReceivableAccountsPayableServicesParams {
  businessId: TID,
}

const getReceivableAccountsPayableServices = ({
  businessId,
}: IGetReceivableAccountsPayableServicesParams): Promise<IReceivableAccountsPayableService[]> => {
  return apiGet(
    '/api/v1/accounts_payable_services/list_receivables',
    underscoreKeys({ businessId, simple: true }),
  ).then(
    (data) => camelizeKeys(data.accounts_payable_services) as IReceivableAccountsPayableService[],
  );
};

interface IPayAccountsPaymentServiceViaAchParams {
  businessId: TID,
  serviceDocumentIds: TID[],
  paidAt: TDate,
}

const payAccountsPaymentServiceViaAch = (
  params: IPayAccountsPaymentServiceViaAchParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/accounts_payable_services/pay_via_ach',
    underscoreKeys({ ...params }),
  );
};

interface ICreateCheckParams {
  addressId?: TID,
  attachmentPages: string[],
  businessId: TID,
  checkMemo: string,
  isPrePrintedCheck: boolean,
  paidAt: string,
  selfPrintCheck: boolean,
  attachmentServiceDocuments: Array<number>,
}

const createCheck = (
  params: ICreateCheckParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/accounts_payable_services/create_check',
    underscoreKeys(params),
  );
};

interface IDownloadPrintableCheckPdfParams {
  attachmentServiceDocuments: Array<number>,
  businessId: TID,
  isPrePrintedCheck: boolean,
}

interface IDownloadPrintableCheckPdfResponse {
  data: any,
  fileName: string,
}

const getDownloadPrintableCheckPdf = (
  params: IDownloadPrintableCheckPdfParams,
): Promise<IDownloadPrintableCheckPdfResponse> => {
  return apiGet(
    '/api/v1/accounts_payable_services/download_printable_check_pdf',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IDownloadPrintableCheckPdfResponse);
};

interface IPayViaAchParams {
  businessId: TID,
  paidAt: string,
  serviceDocumentIds: Array<number>,
}

const payViaAch = (
  params: IPayViaAchParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/accounts_payable_services/pay_via_ach',
    underscoreKeys(params),
  );
};

export {
  IGetReceivableAccountsPayableServicesParams,
  IPayAccountsPaymentServiceViaAchParams,
  ICreateCheckParams,
  IDownloadPrintableCheckPdfParams,
  IDownloadPrintableCheckPdfResponse,
  IPayViaAchParams,
  getAccountsPayableServiceSummary,
  getAccountsPayableServiceByBusinessId,
  getReceivableAccountsPayableServices,
  payAccountsPaymentServiceViaAch,
  createCheck,
  getDownloadPrintableCheckPdf,
  payViaAch,
};
