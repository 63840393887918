module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="expense-filters">\n  <div class="filter-group expense-filter-merchant ');
    
      __out.push(__sanitize(this.type));
    
      __out.push('">\n    <input type="text" class="form-control input-vendor typeahead" placeholder="Merchant" autocomplete="off">\n  </div>\n  ');
    
      if (this.type === 'submitted' || this.type === 'reimbursed') {
        __out.push('\n  <div class="filter-group expense-filter-status ');
        __out.push(__sanitize(this.type));
        __out.push('">\n  <select class="form-control filter-status selectpicker" title="Status">\n    <option value=""></option>\n    ');
        if (this.type === 'submitted') {
          __out.push('\n    <option value="verifying">In Verification</option>\n    <option value="verified">Needs approval</option>\n    <option value="ready">Ready to reimburse</option>\n    ');
        } else if (this.type === 'reimbursed') {
          __out.push('\n    <option value="paid">Reimbursed</option>\n    <option value="in-progress">In-progress</option>\n    <option value="generating">Generating Check</option>\n    <option value="error">Payment Error</option>\n    ');
        }
        __out.push('\n  </select>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="input-group filter-group expense-filter-date-range ');
    
      __out.push(__sanitize(this.type));
    
      __out.push('">\n    <input type="text" class="form-control date-picker" placeholder="Start Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.payment_date ? this.filter.payment_date.gte : ''));
    
      __out.push('" data-filterkey="payment_date_start"/>\n    <span class="input-group-addon">-</span>\n    <input type="text" class="form-control date-picker" placeholder="End Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.payment_date ? this.filter.payment_date.lte : ''));
    
      __out.push('" data-filterkey="payment_date_end"/>\n  </div>\n  ');
    
      if (this.type === 'reimbursed') {
        __out.push('\n  <div class="input-group filter-group expense-filter-reimburse-date-range">\n    <input type="text" class="form-control date-picker" placeholder="Start Date" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.reimbursement_date ? this.filter.reimbursement_date.gte : ''));
        __out.push('" data-filterkey="reimbursement_date_start"/>\n    <span class="input-group-addon">-</span>\n    <input type="text" class="form-control date-picker" placeholder="End Date" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.reimbursement_date ? this.filter.reimbursement_date.lte : ''));
        __out.push('" data-filterkey="reimbursement_date_end"/>\n  </div>\n  <div class="filter-group expense-filter-mode ');
        __out.push(__sanitize(this.type));
        __out.push('">\n  <select class="form-control filter-payment-mode selectpicker" title="Payment Type">\n    <option value=""></option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['DOCYT_ACH']));
        __out.push('>Docyt ACH</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['DOCYT_CHECK']));
        __out.push('>Docyt Check</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['BANK_CHECK']));
        __out.push('>Bank Check</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['BANK_ACCOUNT']));
        __out.push('>Bank Account</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['ADVANCED_SETTLEMENT']));
        __out.push('>Advanced Settlement</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['CREDIT_CARD']));
        __out.push('>Credit Card</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['CASH']));
        __out.push('>Cash</option>\n    <option value=');
        __out.push(__sanitize(configData.ap_payment_modes['PAID_EXTERNALLY']));
        __out.push('>Externally</option>\n  </select>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="input-group filter-group expense-filter-amount-range ');
    
      __out.push(__sanitize(this.type));
    
      __out.push('">\n    <input type="number" class="form-control filter-min-amount" placeholder="Min" value="');
    
      __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
    
      __out.push('" />\n    <span class="input-group-addon">-</span>\n    <input type="number" class="form-control filter-max-amount" placeholder="Max" value="');
    
      __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
    
      __out.push('" />\n  </div>\n</div>\n<table class="table banking-accounts-table receivable-payment-table">\n  <thead>\n    <tr>\n      ');
    
      if (this.isEmployeeMode) {
        __out.push('\n        ');
        if (this.type === 'submitted') {
          __out.push('\n          <th class="sortable width-20-percent" style="padding-left: 60px !important" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          <th class="sortable width-20-percent" data-orderby="status" data-orderdir="ASC">Status</th>\n          <th class="sortable width-20-percent" data-orderby="category" data-orderdir="ASC">Category</th>\n          <th class="sortable width-20-percent" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n          <th class="sortable width-20-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        ');
        } else if (this.type === 'reimbursed') {
          __out.push('\n          <th class="sortable width-15-percent" style="padding-left: 60px !important" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          <th class="sortable width-14-percent" data-orderby="status" data-orderdir="ASC">Status</th>\n          <th class="sortable width-14-percent" data-orderby="category" data-orderdir="ASC">Category</th>\n          <th class="sortable width-14-percent" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n          <th class="sortable width-15-percent" data-orderby="reimbursement_date" data-orderdir="ASC">Reimbursement Date</th>\n          <th class="sortable width-14-percent" data-orderby="payment_mode" data-orderdir="ASC">Payment Type</th>\n          <th class="sortable width-14-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        ');
        }
        __out.push('\n      ');
      } else {
        __out.push('\n        ');
        if (this.type === 'uploaded') {
          __out.push('\n          <th class="sortable width-28-percent" style="padding-left: 60px !important" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          <th class="sortable width-28-percent" data-orderby="category" data-orderdir="ASC">Category</th>\n          <th class="sortable width-20-percent" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n          <th></th>\n          <th class="sortable width-20-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        ');
        } else if (this.type === 'submitted') {
          __out.push('\n          <th class="sortable width-20-percent" style="padding-left: 60px !important" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          <th class="sortable width-18-percent" data-orderby="status" data-orderdir="ASC">Status</th>\n          <th class="sortable width-22-percent" data-orderby="category" data-orderdir="ASC">Category</th>\n          <th class="sortable width-18-percent" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n          <th class="sortable width-18-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        ');
        } else if (this.type === 'reimbursed') {
          __out.push('\n          <th class="sortable width-18-percent" style="padding-left: 60px !important" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          <th class="sortable width-12-percent" data-orderby="status" data-orderdir="ASC">Status</th>\n          <th class="sortable width-18-percent" data-orderby="category" data-orderdir="ASC">Category</th>\n          <th class="sortable width-12-percent" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n          <th class="sortable width-12-percent" data-orderby="reimbursement_date" data-orderdir="ASC">Reimbursement Date</th>\n          <th class="sortable width-12-percent" data-orderby="payment_mode" data-orderdir="ASC">Payment Type</th>\n          <th class="sortable width-12-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        ');
        }
        __out.push('\n      ');
      }
    
      __out.push('\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}