import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useCreateChartOfAccount } from '@src/requests/simple_chart_of_accounts';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import Tooltip from '@src/components/ui_v2/tooltip';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import AddBusiness from './add_business';
import { AccountType, DetailType, ParentCode } from './inputs';
import useProgressModal from './progress_modal';

import styles from './styles.module.scss';

interface IFormInput {
  accountType: string;
  accountTypeName?: string;
  detailType: string;
  detailTypeName?: string;
  categoryName: string;
  categoryCode?: string;
  isSubAccount: string;
  parentCategory?: string;
  businessIds: number[];
}

const schema = yup.object().shape({
  accountType:    yup.string().required('Account Type is required'),
  detailType:     yup.string().required('Detail Type is required'),
  categoryName:   yup.string().trim().required('Category Name is required'),
  categoryCode:   yup.string(),
  isSubAccount:   yup.string(),
  parentCategory: yup.string().when('isSubAccount', {
    is:        'true',
    then:      yup.string().required('Parent Category is required'),
    otherwise: yup.string().notRequired(),
  }),
  businessIds: yup.array().min(1, 'At least one business must be selected').of(yup.number()),
});

const isSubAccountOptions = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' },
];

const CoaForm: FC<{ close: () => void }> = ({ close }) => {
  const formMethods = useForm<IFormInput>({
    resolver:      yupResolver(schema),
    defaultValues: {
      isSubAccount: 'false',
      businessIds:  [],
    },
  });

  const { control,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted } } = formMethods;

  const queryClient = useQueryClient();
  const { mutateAsync } = useCreateChartOfAccount();
  const progressModal = useProgressModal();
  const [doneCount, setDoneCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: IFormInput) => {
    progressModal.open();
    const { businessIds } = data;
    const params: any = {
      accType:     data.accountType,
      accTypeName: data.accountTypeName,
      subTypeCode: data.detailType,
      subType:     data.detailTypeName,
      name:        data.categoryName,
      number:      data.categoryCode || '',
      parentId:    data.isSubAccount === 'true' ? data.parentCategory : '',
    };

    try {
      setIsSubmitting(true);
      const updatePromises = businessIds
        .map((i) => mutateAsync({ ...params, businessId: i }).then(() => {
          setDoneCount((count) => count + 1);
        }));
      await Promise.all(updatePromises);
      await queryClient.invalidateQueries(QueryKey.simpleChartOfAccounts);
      toastr.success('Successfully created', 'Success');
      close();
    } catch (error) {
      await queryClient.invalidateQueries(QueryKey.simpleChartOfAccounts);
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to create: ${errorMessage}`, 'Error');
      close();
    } finally {
      progressModal.props.onDone();
      setDoneCount(0);
      setIsSubmitting(false);
    }
  };
  const isSubAccount = watch('isSubAccount');
  const accountType = watch('accountType');
  const detailType = watch('detailType');
  const businessLength = watch('businessIds').length;

  const detailTypeFieldProps = {
    label:       'Detail Type',
    placeholder: 'Select Detail Type',
    error:       errors.detailType?.message,
  };

  const parentCategoryFieldProps = {
    label:       'Parent Category',
    placeholder: 'Select Parent Category',
    error:       errors.parentCategory?.message,
  };

  return (
    <>
      <FormProvider { ...formMethods }>
        <div>
          <Controller
            control={ control }
            name="accountType"
            render={ ({ field: { value, onChange } }) => (
              <AccountType
                error={ errors.accountType?.message }
                value={ value }
                onChange={ (val) => {
                  onChange(val?.code);
                  setValue('accountTypeName', val?.name);
                  setValue('detailType', '', { shouldValidate: isSubmitted });
                } }
              />
            ) }
          />

          {accountType ? (
            <Controller
              key={ accountType }
              control={ control }
              name="detailType"
              render={ ({ field: { value, onChange } }) => (
                <DetailType
                  accountType={ accountType }
                  error={ errors.detailType?.message }
                  value={ value }
                  onChange={ (val) => {
                    onChange(val?.subtypeCode);
                    setValue('detailTypeName', val?.subtypeName);
                    setValue('parentCategory', undefined, { shouldValidate: isSubmitted });
                  } }
                />
              ) }
            />
          ) : (
            <Tooltip.Hover content="Please select account type first">
              <Form.TextField { ...detailTypeFieldProps } disabled />
            </Tooltip.Hover>
          )}

          <Form.TextField
            label="Category Name"
            placeholder="E.g. Food Supplies"
            { ...register('categoryName') }
            error={ errors.categoryName?.message }
          />

          <Form.TextField
            label="Category Code"
            placeholder="Eg 1200"
            { ...register('categoryCode') }
            error={ errors.categoryCode?.message }
          />

          <div className={ styles['radio-row'] }>
            <div>Is this a sub-account of another category?</div>
            <div>
              {
                isSubAccountOptions.map((i) => (
                  <div key={ i.label } className="checkbox checkbox-primary checkbox-circle">
                    <input
                      { ...register('isSubAccount') }
                      id={ `radio_collection_${i.label}` }
                      type="radio"
                      value={ i.value }
                    />
                    <label htmlFor={ `radio_collection_${i.label}` }>
                      { i.label }
                    </label>
                  </div>
                ))
              }
            </div>
          </div>

          {isSubAccount === 'true' && (
            detailType ? (
              <Controller
                key={ detailType }
                control={ control }
                name="parentCategory"
                render={ ({ field: { value, onChange } }) => (
                  <ParentCode
                    error={ errors.parentCategory?.message }
                    subType={ detailType }
                    value={ value }
                    onChange={ onChange }
                  />
                ) }
              />
            ) : (
              <Tooltip.Hover content="Please select detail type first">
                <Form.TextField { ...parentCategoryFieldProps } disabled />
              </Tooltip.Hover>
            )
          )}

          <AddBusiness />

          <div className={ styles['submit-panel'] }>
            <Button disabled={ isSubmitting } type="button" variant="primary" onClick={ handleSubmit(onSubmit) }>
              { isSubmitting ? <SpinnerIcon spin /> : 'Add' }
            </Button>
          </div>
        </div>
      </FormProvider>

      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ businessLength }
        { ...progressModal.props }
      />
    </>
  );
};

export default CoaForm;
