import { OperatorMap } from './types';

const operatorValue: OperatorMap = {
  greater_than: 'More than',
  less_than:    'Less than',
  equal_to:     'Equal to',
};

export {
  operatorValue,
};
