module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="clients__client pointer select-profile-js">\n  <div class=\'clients__client-status clients__client-cell\'>\n    <span class=\'contacts__contact-name-text\'>\n      ');
    
      __out.push(__sanitize(this.customer_name));
    
      __out.push(' / ');
    
      __out.push(__sanitize(this.business_name));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'clients__client-status clients__client-cell\'>\n    <span class=\'contacts__contact-name-text\'>\n      ');
    
      __out.push(__sanitize(this.bank_info));
    
      __out.push('\n    </span>\n  </div>\n  <a class=\'clients__client-status clients__client-cell\' href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('">\n    <span class=\'contacts__contact-name-text in-blue-400 text-decoration-blank\'>\n      ');
    
      if (this.invoice_number) {
        __out.push('\n        ');
        __out.push(__sanitize(this.invoice_number));
        __out.push('\n      ');
      } else {
        __out.push('\n        --\n      ');
      }
    
      __out.push('\n    </span>\n  </a>\n  ');
    
      if (this.check_id) {
        __out.push('\n    <a class=\'clients__client-status clients__client-cell\' href="/document/');
        __out.push(__sanitize(this.check_id));
        __out.push('">\n        <span class=\'contacts__contact-name-text in-blue-400 text-decoration-blank\'>\n          ');
        __out.push(__sanitize(this.check_amount));
        __out.push('\n        </span>\n    </a>\n  ');
      } else {
        __out.push('\n    <div class=\'clients__client-status clients__client-cell\'>\n        <span class=\'contacts__contact-name-text\'>\n          ');
        __out.push(__sanitize(this.check_amount));
        __out.push('\n        </span>\n    </div>\n  ');
      }
    
      __out.push('\n  <div class=\'clients__client-status clients__client-cell\'>\n      <span class=\'contacts__contact-name-text\'>\n        ');
    
      __out.push(__sanitize(this.qbo_status));
    
      __out.push('\n      </span>\n  </div>\n  <div class=\'clients__client-status clients__client-cell\'>\n      <span class=\'contacts__contact-name-text\'>\n        ');
    
      __out.push(__sanitize(this.check_status));
    
      __out.push('\n      </span>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}