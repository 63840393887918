module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.isUpdating) {
        __out.push('\n  <div class="report-status-panel text-left display-flex align-items-center">\n    <label class="report-status-label m-l-10 m-b-0"> ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['CUSTOM_REPORT_UPDATING_STATE']));
        __out.push(' </label>\n  </div>\n');
      } else if (this.isFailed) {
        __out.push('\n  <div class="report-status-panel warning text-left display-flex align-items-center refresh-panel pointer">\n    <label class="report-status-label m-l-10 m-b-0 font-light in-black-600"> ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['CUSTOM_REPORT_FAILED_STATE']));
        __out.push(': ');
        __out.push(__sanitize(this.reportDataErrorMsg));
        __out.push(' Click here to refresh.</label>\n  </div>\n');
      } else {
        __out.push('\n  <div class="report-status-panel text-left display-flex align-items-center refresh-panel pointer m-t-10">\n    <label class="report-status-label m-l-10 m-b-0 pointer">\n      This report was generated<span class=\'time-tooltip\' data-toggle="tooltip" data-placement="top" title="');
        __out.push(__sanitize(this.minUpdatedAt));
        __out.push('" ');
        if (!this.minUpdatedAtString) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('> ');
        __out.push(__sanitize(this.minUpdatedAtString));
        __out.push('</span>. Click here to refresh.\n    </label>\n  </div>\n');
      }
    
      __out.push('\n');
    
      if (this.unIncludedTransactionsCount > 0) {
        __out.push('\n  <div class="report-status-panel warning text-left display-flex align-items-center m-t-10">\n    <span class="icon-unable-to-sync in-red m-r-3"><span class="path1"></span><span class="path2"></span></span>\n    <label class="m-l-10 m-b-0 font-light in-black-600"> ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['CUSTOM_REPORT_MISSING_TRANSACTIONS']));
        __out.push(' </label>\n    <a class=\'m-l-auto missing-transactions-link pointer\'>Learn More</a>\n  </div>\n');
      }
    
      __out.push('\n<div id="export-data-baneer"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}