module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="page-main-header clearfix">\n  <div class="breadcrumb-container">\n    <div class="breadcrumb">\n      <span class="font-24 font-bold">Summary</span>\n    </div>\n  </div>\n</div>\n<div class="page-main-body">\n  <div class="banking-accounts-summary-chart-container">\n    <h3 class="font-18 font-semi-bold m-b-35">Transaction by Month</h3>\n    <div class="banking-accounts-monthly-chart-container relative">\n      <canvas id="banking-accounts-monthly-chart"></canvas>\n      <button class="btn btn-default btn-circle btn-prev-year"><i class="fa fa-angle-left font-16"></i></button>\n      <button class="btn btn-default btn-circle btn-next-year"><i class="fa fa-angle-right font-16"></i></button>\n    </div>\n    <div class="relative" style="height: 7px;">\n      <span class="fa fa-caret-down font-24 btn-show-detail-chart hidden"></span>\n    </div>\n    <hr class="m-t-16 m-b-30" />\n    <h3 class="font-18 font-semi-bold">Transactions by Payment Accounts | <span class="label-selected-month">YTD</span></h3>\n    <a class="pointer btn-close-detail-chart hidden"><span aria-hidden="true">&times;</span></a>\n    <div class="banking-accounts-detail-chart-container">\n      <canvas id="banking-accounts-detail-chart"></canvas>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}