import React from 'react';

import DetailsPageTitle from '@src/components/ui/details_page_title';

import { BulkActions, IBulkActionsProps } from './bulk_actions';

const DocytNetworkHeader = ({
  ...props
}: IBulkActionsProps): JSX.Element => {
  return (
    <DetailsPageTitle title="Docyt Network Business">
      <BulkActions { ...props } />
    </DetailsPageTitle>
  );
};

export default DocytNetworkHeader;
