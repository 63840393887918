import { useCallback, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ISortingParams } from '@src/types/sorting';

import { getSearchParamsFromFilter, useFilter } from './filter';
import { useSorting } from './sorting';
import { IURLParams } from './types';

interface IUseURLParams<TSortColumn extends string, TFilter extends TFilterData> {
  businessId?: TID,
  section: string,
  defaultSorting: ISortingParams<TSortColumn>,
  filterInitData?: TFilter,
}

const useURLParams = <TFilter extends TFilterData, TSortColumn extends string>({
  businessId,
  section,
  defaultSorting,
  filterInitData,
}: IUseURLParams<TSortColumn, TFilter>): IURLParams<TFilter, TSortColumn> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useFilter<TFilter>({
    businessId,
    section,
    initSearchParams: searchParams,
    initData:         filterInitData,
  });
  const sorting = useSorting<TSortColumn>({
    section,
    defaultSorting,
    initSearchParams: searchParams,
  });

  const { data: filterData } = filter;
  useEffect(() => {
    const newSearchParams = getSearchParamsFromFilter(filterData);

    // Copy existing sorting params
    searchParams.forEach((value, key) => {
      if (key.endsWith('_order')) {
        newSearchParams.set(key, value);
      }
    });

    if (searchParams.toString() !== newSearchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [filterData, searchParams, setSearchParams]);

  const { data: sortingData } = sorting;
  useEffect(() => {
    const newSearchParams = new URLSearchParams();

    // Copy existing non sorting (filter) params
    searchParams.forEach((value, key) => {
      if (!key.endsWith('_order')) {
        newSearchParams.set(key, value);
      }
    });

    // Add sorting param
    if (sortingData.orderColumn && sortingData.orderDirection) {
      newSearchParams.set(`${sortingData.orderColumn}_order`, sortingData.orderDirection);
    }

    if (searchParams.toString() !== newSearchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [sortingData, searchParams, setSearchParams]);

  const { clear: clearFilter } = filter;
  const { clear: clearSorting } = sorting;
  const clearAll = useCallback(() => {
    clearFilter();
    clearSorting();
  }, [clearFilter, clearSorting]);

  return {
    filter,
    sorting,
    clearAll,
  };
};

export { useURLParams };
