import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const WarningShieldIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="warning_shield"
    />
  );
};

export default WarningShieldIcon;
