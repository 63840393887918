import React from 'react';

import Tooltip from '@src/components/ui/tooltip';
import { QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IBusinessQBOStatusIconProps {
  qboConnected: boolean,
}

const BusinessQBOStatusIcon = ({
  qboConnected,
}: IBusinessQBOStatusIconProps) => {
  if (qboConnected) {
    return <QBOOnIcon fontSize={ 20 } mr={ 10 } />;
  }

  return (
    <Tooltip
      as={ QBOOffIcon }
      fontSize={ 20 }
      mr={ 10 }
      title={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_STARTED }
    />
  );
};

export default BusinessQBOStatusIcon;
