import React, { useMemo } from 'react';

import { useGetAllUsers } from '@src/hooks/queries/users';
import { IBusinessUser } from '@src/types/business_users';
import { TID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards/dashboards';

import { useEditUsersModal } from '@src/components/common/edit_users_modal/edit_users_modal';
import { Button } from '@src/components/ui_v2/buttons';

interface IDashboardShareActionProps {
  currentDashboard: IDashboard,
  managementGroupId?: TID,
  businessId?: TID,
  serviceProviderBusinessId?: TID | null,
  title?: string,
  prefixIcon?: React.ReactNode,
  onDone: (accessibleUsers: IBusinessUser[]) => void,
}

const DashboardShareAction = ({
  currentDashboard,
  managementGroupId,
  businessId,
  serviceProviderBusinessId,
  prefixIcon,
  title = 'Share Dashboard',
  onDone,
}: IDashboardShareActionProps): JSX.Element => {
  const shareDashboard = useEditUsersModal();

  const query = useGetAllUsers({
    managementGroupId,
    businessId,
    serviceProviderBusinessId,
  });

  const users = useMemo(() => {
    return (query.data?.users || [])
      .filter((user) => currentDashboard.dashboardUserIds.includes(user.id));
  }, [query.data?.users, currentDashboard.dashboardUserIds]);

  return (
    <>
      <Button
        prefixIcon={ prefixIcon }
        variant="primary"
        onClick={ shareDashboard.open }
      >
        { title }
      </Button>
      <shareDashboard.Component
        { ...shareDashboard.props }
        businessId={ businessId }
        editUsers={ users }
        managementGroupId={ managementGroupId }
        serviceProviderBusinessId={ serviceProviderBusinessId }
        title="Share Dashboard"
        onDone={ onDone }
      />
    </>
  );
};

export default DashboardShareAction;
