import { TOrderDirection } from '@src/types/common';
import {
  IPracticeInformation,
  TPracticeInformationsSortColumn,
} from '@src/types/practice_informations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetPracticeInformationsParams {
  bookkeepingYear: string,
  status?: string,
  filter?: object,
  orderColumn?: TPracticeInformationsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
}

interface IGetPracticeInformationsResponse {
  meta: {
    totalCount: number,
  },
  collection: IPracticeInformation[],
}

const getPracticeInformations = (
  params: IGetPracticeInformationsParams,
): Promise<IGetPracticeInformationsResponse> => {
  return apiGet(
    '/api/v1/practice_informations',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.clients,
    };
  });
};

export {
  IGetPracticeInformationsParams,
  IGetPracticeInformationsResponse,
  getPracticeInformations,
};
