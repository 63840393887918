import React, { useCallback, useState } from 'react';

import { QBOSyncOptions } from '@src/constants/qbo_sync';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { FilterFieldWrapper } from '@src/components/ui/filter';
import { IconSelectInput } from '@src/components/ui/form';
import { TIconOption } from '@src/components/ui/form/inputs/react_select/icon_select_input';

interface IFilterQBOSyncInputParams {
  widthPercent?: IUIStyleProps['widthPercent'],
  value?: string,
  onChange?: (value?: string) => void,
}

const FilterQBOSyncInput = ({
  widthPercent,
  value,
  onChange,
}: IFilterQBOSyncInputParams): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  const handleChange = useCallback((item: TIconOption | null) => {
    setSelectedValue(item?.value);
    if (onChange) onChange(item?.value);
  }, [onChange]);

  const options = QBOSyncOptions;
  const selectedItem = (options || []).find((i) => i.value === selectedValue);

  return (
    <FilterFieldWrapper widthPercent={ widthPercent }>
      <IconSelectInput
        hideIndicators
        wideMenu
        isSearchable={ false }
        options={ options }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </FilterFieldWrapper>
  );
};

export default FilterQBOSyncInput;
