module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, item, j, k, len, len1, len2, message, messages, ref, ref1, ref2;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">');
    
      __out.push(__sanitize("" + (I18n.t('global_services.uncategorized_update_transactions'))));
    
      __out.push('</h4>\n      </div>\n      <div class="p-l-40 p-r-40">\n        <div class="m-t-20">\n          <p class="m-t-0 font-16 m-b-20">\n            <span class="in-green-400">');
    
      __out.push(__sanitize(this.successCount));
    
      __out.push('</span> ');
    
      __out.push(__sanitize("" + (I18n.t('global_services.uncategorized_transactions_update_succeed'))));
    
      __out.push('\n          </p>\n          ');
    
      if (this.failInfo.length > 0) {
        __out.push('\n            <p class="m-t-0 font-16 m-b-5">\n              <span class="in-red-400">');
        __out.push(__sanitize(this.failInfo.length));
        __out.push('</span> ');
        __out.push(__sanitize("" + (I18n.t('global_services.uncategorized_transactions_update_failed'))));
        __out.push('\n            </p>\n            <ul class="m-l-5">\n              ');
        messages = new Set();
        __out.push('\n              ');
        ref = this.failInfo;
        for (i = 0, len = ref.length; i < len; i++) {
          item = ref[i];
          __out.push('\n                ');
          ref1 = item.error;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            message = ref1[j];
            __out.push('\n                  ');
            messages.add(message);
            __out.push('\n                ');
          }
          __out.push('\n              ');
        }
        __out.push('\n              ');
        ref2 = Array.from(messages);
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          message = ref2[k];
          __out.push('\n                <li>');
          __out.push(__sanitize(message));
          __out.push('</li>\n              ');
        }
        __out.push('\n            </ul>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <div class="modal-footer">\n        <button type=\'button\' class="btn btn-blue cancel">Close</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}