import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';
import { ShareIcon } from '@src/components/utils/fa_icons';

import { useMergeCustomerModal } from '../merge_customer/merge_customer_modal';

interface IMergeCustomerActionProps {
  businessId: TID,
  customerId: TID,
}

const MergeCustomerAction = ({
  businessId,
  customerId,
}: IMergeCustomerActionProps) => {
  const mergeCustomer = useMergeCustomerModal();

  return (
    <Root>
      <mergeCustomer.Component
        { ...mergeCustomer.props }
        businessId={ businessId }
        customerId={ customerId }
      />
      <a
        className="dropdown-item pointer display-flex"
        role="button"
        tabIndex={ 0 }
        onClick={ mergeCustomer.open }
      >
        <ShareIcon fontSize={ 18 } />
        <span className="font-14 m-l-5">Merge with another Customer</span>
      </a>
    </Root>
  );
};

export default MergeCustomerAction;
