import React, { FC } from 'react';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface TableRowDeleteProps {
  index: number
  remove: (index: number) => void
}

const TableRowDelete: FC<TableRowDeleteProps> = ({ index, remove }) => {
  const confirm = useConfirmDeleteModal({ onDone: () => remove(index) });

  return (
    <>
      <confirm.Component
        confirmTitle="Delete"
        text="Are you sure you want to delete this Chart of Account row?"
        title="Delete Journal Entry Line"
        { ...confirm.props }
      />
      <TrashcanIcon pointer onClick={ () => confirm.open() } />
    </>
  );
};

export default TableRowDelete;
