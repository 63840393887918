import React, { useCallback } from 'react';

import { CheckboxInput } from '@src/components/ui_v2/inputs';

import Item from './item';
import Label from './item_label';

import styles from './styles.module.scss';

interface IDropdownCheckboxPropsItem extends
  Omit<React.ComponentPropsWithoutRef<typeof Item>, 'title' | 'onSelect'>
{
  checked?: React.ComponentPropsWithoutRef<typeof CheckboxInput>['checked'],
  value?: React.ComponentPropsWithoutRef<typeof CheckboxInput>['value'],
  onSelect?: (
    checked: boolean,
    value?: React.ComponentPropsWithoutRef<typeof CheckboxInput>['value']
  ) => void;
}

const DropdownCheckboxItem = ({
  checked,
  children,
  value,
  onSelect,
  ...props
}: IDropdownCheckboxPropsItem) => {
  const handleSelect = useCallback(() => {
    if (!onSelect) return;

    onSelect(!checked, value);
  }, [checked, value, onSelect]);

  return (
    <Item { ...props } onSelect={ handleSelect }>
      <span className={ styles['dropdown-menu-item-checkbox'] }>
        <CheckboxInput
          checked={ checked }
          value={ value }
        />
      </span>
      <Label>
        { children }
      </Label>
    </Item>
  );
};

export default DropdownCheckboxItem;
