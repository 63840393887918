import React, { memo } from 'react';

import Root from '@src/components/root';

import AiChatbotSession from './ai_chatbot_session';

const AiChatBotMainView = () => {
  return (
    <Root>
      <AiChatbotSession />
    </Root>
  );
};

export default memo(AiChatBotMainView);
