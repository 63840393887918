import React from 'react';

import EmailFrequencyInput from '@src/components/common_v2/inputs/email_frequency_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IEmailFrequencyFieldProps extends
  React.ComponentPropsWithoutRef<typeof EmailFrequencyInput>, IWrapperProps {
    labelClass?: string,
}

const EmailFrequencyField = ({
  labelClass,
  ...props
}: IEmailFrequencyFieldProps) => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps } labelClass={ labelClass }>
      <EmailFrequencyInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(EmailFrequencyField);
