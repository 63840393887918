module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">Certification</h4>\n      </div>\n      <div class="modal-body modern-body">\n        <div class="">\n          <p class="m-t-0 font-16">I, ');
    
      __out.push(__sanitize(this.dwollaCustomer.name()));
    
      __out.push(', hereby certify, to the best of my knowledge, that the information provided here is complete and correct.</p>\n          <p class="m-t-20 font-12 in-grey-1050">By certifying that all beneficial owner information is correct, the requirements imposed by the United States Federal customer due diligence rule will be successfully fulfilled</p>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left font-18 font-semi-bold\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue business-save-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Certify</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}