module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="expense-clickable pointer">\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.avatar_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.avatar_url));
        __out.push(' class=\'small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <span class="m-l-14">');
    
      __out.push(__sanitize(this.full_name));
    
      __out.push('</span>\n  </div>\n</td>\n');
    
      this.email = this.dwollaCustomer.get('email');
    
      __out.push('\n');
    
      this.last4 = this.dwollaFundingSource.get('last4');
    
      __out.push('\n');
    
      this.is_verified = this.dwollaFundingSource.get('is_verified');
    
      __out.push('\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.email || '-'));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.last4 ? "**** " + this.last4 : '-'));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">\n  ');
    
      if (this.last4 || this.is_verified) {
        __out.push('\n  <div class="invoice-status-panel font-bold approval font-11 font-semi-bold">ACH Active</div>\n  ');
      } else {
        __out.push('\n  <div class="in-grey-700 font-14 font-semi-bold">Inactive</div>\n  ');
      }
    
      __out.push('\n</td>\n<td class="pointer">\n  <div class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="employee-account-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="employee-account-action-dropdown dropdown-menu dropdown-menu-right dropdown-menu-custom" aria-labelledby="employee-account-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    ');
    
      if (!(this.last4 || this.is_verified)) {
        __out.push('\n    <a class="dropdown-item font-12 add-employee-account-js">\n      <span class="icon-bank-transfers-nav font-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> <span>Add ACH Information</span>\n    </a>\n    ');
      } else {
        __out.push('\n    <a class="dropdown-item font-12 view-employee-account-js">\n      <span class="icon-bank-transfers-nav font-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span> <span>View ACH Information</span>\n    </a>\n    <a class="dropdown-item font-12 edit-employee-account-js">\n      <i class="icon icon-edit font-16"></i> <span>Edit ACH Information</span>\n    </a>\n    <a class="dropdown-item font-12 remove-employee-account-js">\n      <i class="icon icon-destroy font-24 in-red-600"></i> <span class=\'in-red-600\'>Delete ACH Information</span>\n    </a>\n    ');
      }
    
      __out.push('\n  </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}