import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { AchIcon } from '@src/components/utils/icomoon';

interface IDocytAchActionProps {
  invoiceIds: TID[],
}

const DocytAchAction = ({
  invoiceIds,
}: IDocytAchActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:docyt:ach', invoiceIds);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <AchIcon fontSize={ 18 } /> }
      name="Pay by Docyt ACH"
      onClick={ handleSelect }
    />
  );
};

export default DocytAchAction;
