import React from 'react';

import { TIconOption } from '@src/components/ui/form';
import { DummyIcon } from '@src/components/utils/fa_icons';
import {
  DocumentIcon,
  SplitBadgeIcon,
  ManualTransactionIcon,
  DuplicatedTransactionIcon,
  VerifyDataIcon,
} from '@src/components/utils/icomoon';

const RelatedDocumentOptions: TIconOption[] = [
  {
    label: 'All',
    value: undefined,
    icon:  <DummyIcon />,
  },
  {
    label: 'Documented Txns',
    value: 'documented',
    icon:  <DocumentIcon fontSize={ 17 } ml={ 4 } />,
  },
  {
    label: 'Undocumented Txns',
    value: 'undocumented',
    icon:  <DocumentIcon color="grey" fontSize={ 20 } />,
  },
];

const TransactionTypeOptions: TIconOption[] = [
  {
    value: undefined,
    label: 'All Transactions',
    icon:  <DummyIcon fontSize={ 17 } />,
  },
  {
    value: 'splitted',
    label: 'Split Transactions',
    icon:  <SplitBadgeIcon fontSize={ 20 } />,
  },
  {
    value: 'manually_added',
    label: 'Manually Added',
    icon:  <ManualTransactionIcon fontSize={ 20 } />,
  },
  {
    value: 'is_duplicated',
    label: 'Duplicate Transactions',
    icon:  <DuplicatedTransactionIcon fontSize={ 20 } />,
  },
  {
    value: 'verified',
    label: 'Categorized Transactions',
    icon:  <VerifyDataIcon fontSize={ 20 } />,
  },
];

export {
  RelatedDocumentOptions,
  TransactionTypeOptions,
};
