import React from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CommentIcon } from '@src/components/utils/fa_icons';

interface ISendMessageActionProps {
  documentId: TID,
}

const SendMessageAction = ({
  documentId,
}: ISendMessageActionProps) => {
  return (
    <ActionsDropdown.Action
      href={ `/document/${documentId}/chat` }
      icon={ <CommentIcon fontSize={ 18 } /> }
      name="Send Message"
    />
  );
};

export default SendMessageAction;
