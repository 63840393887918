module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, receipt, ref;
    
      __out.push('<div class="modal fade invoice-action-modal">\n  <div class="modal-dialog select-receipt-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="font-24 font-bold m-l-auto m-r-auto m-t-27">Receipts for ');
    
      __out.push(__sanitize(this.selected_month));
    
      __out.push('</h1>\n      </div>\n      <div class="modal-body receipt-modal-body">\n        ');
    
      ref = this.receipts;
      for (i = 0, len = ref.length; i < len; i++) {
        receipt = ref[i];
        __out.push('\n          <div class="display-flex align-items-center m-l-40">\n            <div class="radio blue-radio">\n              <input id="chk_pa_');
        __out.push(__sanitize(receipt.id));
        __out.push('" data-id="');
        __out.push(__sanitize(receipt.id));
        __out.push('" class="check-js pointer" type="radio" name="receipt_radio" value="');
        __out.push(__sanitize(receipt.id));
        __out.push('">\n              <label class="pointer"></label>\n            </div>\n            <label class="col-md-3 font-16 m-b-0 pointer" for="chk_pa_');
        __out.push(__sanitize(receipt.id));
        __out.push('">');
        __out.push(__sanitize(this.currencyFormater(receipt.amount)));
        __out.push('</label>\n            <label class="col-md-4 font-16 m-b-0 pointer" for="chk_pa_');
        __out.push(__sanitize(receipt.id));
        __out.push('">');
        __out.push(__sanitize(receipt.pay_date));
        __out.push('</label>\n            <label class="col-md-3 font-16 m-b-0 pointer" for="chk_pa_');
        __out.push(__sanitize(receipt.id));
        __out.push('">');
        __out.push(__sanitize(receipt.status));
        __out.push('</label>\n          </div>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class=\'modal-footer fixed-modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button pull-right download-js width-160px" tabindex="10" data-color="blue" data-style="expand-right">Download</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}