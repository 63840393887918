import React from 'react';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';
import { Button } from '@src/components/ui/buttons';

import { IBankAccountData } from '../../schema';

interface IBankAccountEditFieldProps {
  value: IBankAccountData,
  isEditable: boolean,
  onEdit: () => void;
}

const BankAccountEditField = ({
  value,
  isEditable,
  onEdit,
}: IBankAccountEditFieldProps) => {
  return (
    <div className="selected-bank-accounts-field">
      <div>
        <div className="display-flex align-items-center m-b-10">
          <FinancialInstitutionAvatar
            logo={ value?.financialInstitutionLogo }
          />
          <span className="financial-institution-name">{ value?.financialInstitutionName }</span>
        </div>
        <span>{ `${value?.bankAccountName} ${value?.bankAccountLast4}` }</span>
      </div>
      {
        isEditable && (
          <Button
            bsStyle="link"
            className="pointer font-bold"
            onClick={ onEdit }
          >
            Edit
          </Button>
        )
      }
    </div>
  );
};

export default BankAccountEditField;
