import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { WarningIcon } from '@src/components/utils/fa_icons';

import styles from '../styles.module.scss';

interface IFormFieldWrapperProps {
  children: React.ReactNode,
  className?: string,
  error?: string,
  hideLabel?: boolean,
  hint?: string,
  label?: string,
  mark?: string,
  valueProps?: ComponentPropsWithoutRef<'div'>,
}

const FormFieldWrapper = ({
  children,
  className,
  error,
  label,
  mark,
  hint,
  hideLabel,
  valueProps,
}: IFormFieldWrapperProps): JSX.Element => {
  const wrapperClasses = classNames('form-field', className, styles['form-field']);

  const valueClasses = classNames(
    'form-field-item form-field-value',
    valueProps?.className,
  );

  return (
    <div className={ wrapperClasses }>
      {
        !hideLabel && (
          <div className="form-field-item form-field-name">
            { label }
            { mark && <div className="optional-field-mark">{ mark }</div> }
          </div>
        )
      }
      <div { ...valueProps } className={ valueClasses }>
        { children }
        {
          error && (
            <p className="in-red-400 main-input-hint">
              <WarningIcon />
              {' '}
              { error }
            </p>
          )
        }
        {
          hint && (
            <p className="hint">{ hint }</p>
          )
        }
      </div>
    </div>
  );
};

const MemoizedFormFieldWrapper = React.memo(FormFieldWrapper);

export {
  IFormFieldWrapperProps,
  MemoizedFormFieldWrapper as default,
};
