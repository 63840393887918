import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface BannerProps extends IUIStyleProps {
  children: React.ReactNode,
  icon?: React.ReactNode,
  bsStyle: 'success' | 'info' | 'warning' | 'danger',
  onClose?: () => void;
}

const Alert = ({
  children,
  icon,
  bsStyle,
  onClose,
  ...props
}: BannerProps) => {
  const [classes, divProps] = uiStyleProps(
    styles.alert,
    props,
    'alert',
    `alert-${bsStyle}`,
  );

  return (
    <div className={ classes } { ...divProps }>
      { icon && (
        <>
          { icon }
          { ' ' }
        </>
      ) }
      { children }
      { onClose && (
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={ onClose }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) }
    </div>
  );
};

export default Alert;
