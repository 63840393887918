module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, s3Object;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <button class=\'close cancel\' type=\'button\'>\n          <span>&times;</span>\n        </button>\n        <h4 class="modal-title">Day: ');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body integrations">\n        <div class="row m-l-20 m-r-20">\n          <div class="col-md-6">\n            <span class="label ');
    
      __out.push(__sanitize(this.labelClass));
    
      __out.push(' status-label">');
    
      __out.push(__sanitize(this.status));
    
      __out.push('</span>\n            <span class="display-inline-flex status-tooltip-icon" data-toggle="tooltip" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.description));
    
      __out.push('">\n              <span class="icon icon-info"></span>\n            </span>\n          </div>\n          <div class="col-md-6">\n            <a class=\'restart-run copy-link\'>Reset</a>\n            <a class=\'copy-link\' id=\'showFiles\'>Show Files</a>\n          </div>\n          <div class="col-md-12 hidden" id="nofiles">\n            <span>No Files Available</span>\n          </div>\n          <div class="col-md-12 hidden" id="downloadUrlsTable">\n            <table class="table table-responsive">\n              <thead>\n                <tr>\n                  <td>Files</td>\n                </tr>\n              </thead>\n              <tbody>\n              ');
    
      ref = this.downloadUrls;
      for (i = 0, len = ref.length; i < len; i++) {
        s3Object = ref[i];
        __out.push('\n              <tr>\n                <td><a href="');
        __out.push(__sanitize(s3Object.object_url));
        __out.push('" download="');
        __out.push(__sanitize(s3Object.object_url));
        __out.push('" target="_blank">');
        __out.push(__sanitize(s3Object.object_key));
        __out.push('</a></td>\n              </tr>\n              ');
      }
    
      __out.push('\n              </body>\n            </table>\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}