import React from 'react';

import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import StateItem from './balance_sheet_statement_state_item';

interface IBalanceSheetStatementStateListProps {
  businessId: TID,
  chartOfAccountId: TID,
  states: IStatementState[],
}

const BalanceSheetStatementStateList = ({
  businessId,
  chartOfAccountId,
  states,
}: IBalanceSheetStatementStateListProps): JSX.Element => {
  return (
    <>
      {
        states.map((state) => {
          return (
            <StateItem
              key={ state.month }
              businessId={ businessId }
              chartOfAccountId={ chartOfAccountId }
              state={ state }
            />
          );
        })
      }
    </>
  );
};

export default BalanceSheetStatementStateList;
