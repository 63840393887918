import React, { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { revenueServiceReconcilePath } from '@src/routes';

import {
  useDepositTrackingRSDCollection,
} from '@src/components/revenue_center/deposit_tracking/hooks';
import { DepositTrackingRevenues } from '@src/components/revenue_center/deposit_tracking/index';
import {
  usePaymentProcessorsForRevenueReportType,
} from '@src/components/revenue_center/revenue_capture/hooks';
import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import { AppLinkButton } from '@src/components/ui_v2/buttons';
import { useFilterField } from '@src/components/ui_v2/filter';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import UtilSection from '@src/components/utils_v2/section';

import RevenueServiceTabs from '../common/revenue_service_tabs/revenue_service_tabs';
import RevenueCaptureHeaderTable from '../revenue_capture/revenue_capture_header_table';
import { useRevenueSystemContext } from '../revenue_system_provider';
import Landing from './landing';
import RevenueDepositListView from './revenue_deposit_list/list';

import styles from './styles.module.scss';

const DepositTracking = (): JSX.Element | null => {
  const service = useRevenueServiceContext();
  const revenueSystem = useRevenueSystemContext();
  const business = useBusinessContext();
  const collection = useDepositTrackingRSDCollection({
    revenueService: service,
    revenueSystem,
  });
  const [currentMonth] = useFilterField('month', collection.section);
  const revenueReportType = useMemo(() => {
    return service.revenueReportTypes.find((item) => {
      return item.id === revenueSystem.revenueReportTypeId;
    });
  }, [service.revenueReportTypes, revenueSystem.revenueReportTypeId]);
  const paymentProcessors = usePaymentProcessorsForRevenueReportType(
    revenueReportType,
    service,
  ) || [];
  const revenueCaptureHeaderFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CAPTURE_NEW_HEADERS,
  );
  return (
    <PageContainer sidemenu={ <Sidemenu /> }>
      <UtilSection.Provider section={ collection.section }>
        <DetailsRegion>
          <DetailsRegion.Header
            className={ styles['tab-container'] }
            disableTooltip={ revenueCaptureHeaderFeatureQuery.data }
            divider="horizontal"
            title={ !revenueCaptureHeaderFeatureQuery.data ? revenueSystem.name
              : (
                <RevenueCaptureHeaderTable
                  integrationId={ revenueSystem.integrationId }
                  revenueSystemName={ revenueSystem.name }
                />
              ) }
          >
            <RevenueServiceTabs
              activeTab="DepositTracking"
              businessId={ business.id }
              revenueSystemId={ revenueSystem.id }
            />

            {revenueSystem.depositTrackingStartDate && (
              <AppLinkButton
                className={ styles['ml-auto'] }
                href={ revenueServiceReconcilePath(
                  service.businessId,
                  revenueSystem.id,
                  { month: currentMonth },
                ) }
                variant="primary"
              >
                Reconcile
              </AppLinkButton>
            )}
          </DetailsRegion.Header>

          {revenueSystem.depositTrackingStartDate ? (
            <>
              {paymentProcessors.length > 0 && (
                <div className={ styles['list-view'] }>
                  <RevenueDepositListView revenueSystem={ revenueSystem } />
                </div>
              )}

              <DepositTrackingRevenues
                collection={ collection }
                revenueSystem={ revenueSystem }
              />
            </>
          ) : (
            <Landing revenueSystem={ revenueSystem } />
          )}
        </DetailsRegion>
      </UtilSection.Provider>
    </PageContainer>
  );
};

export default React.memo(DepositTracking);
