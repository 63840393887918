import React from 'react';

import { TDate } from '@src/types/common';

import { DatePickerInput } from '@src/components/ui/form';

interface IChangeStartDateFormProps {
  lastTransactionDate?: TDate,
  startImportDate?: TDate,
  onChangedStartDate: (value: TDate | undefined) => void,
}

const ChangeStartDateForm = ({
  lastTransactionDate,
  startImportDate,
  onChangedStartDate,
}: IChangeStartDateFormProps): JSX.Element => {
  return (
    <div className="change_start_date_form">
      <p className="text-center m-t-0 font-14">
        This account already has transactions in Docyt and the last transaction date is:
        { ' ' }
        { lastTransactionDate }
      </p>
      <p className="text-center m-t-16 m-b-10 font-14">
        To avoid duplicates, we recommend the import starting date to
        { ' ' }
        be after the last transaction date
      </p>
      <div className="display-flex align-items-center p-t-25 p-b-10">
        <span className="title width-40-percent m-r-10">Starting Date:</span>
        <DatePickerInput
          value={ startImportDate }
          onChange={ onChangedStartDate }
        />
      </div>
    </div>
  );
};

export {
  IChangeStartDateFormProps,
  ChangeStartDateForm as default,
};
