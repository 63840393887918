import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetManagementGroups } from '@src/hooks/queries/management_groups';
import { useFilter } from '@src/hooks/url_params';

import ManagementGroupsFilter from '@src/components/management_groups/filter/filter';
import { IManagementGroupsFilter } from '@src/components/management_groups/filter/types';
import ManagementGroupsHeader from '@src/components/management_groups/management_groups_header';
import ManagementGroupsTable from '@src/components/management_groups/management_groups_table';

import styles from './styles.module.scss';

interface IManagementGroupsProps {
  showNew: boolean,
}

const ManagementGroups = ({
  showNew,
}: IManagementGroupsProps): JSX.Element => {
  const filter = useFilter<IManagementGroupsFilter>({});

  const managementGroupsQuery = useGetManagementGroups({ filter: filter.data });

  const managementGroups = useMemo(() => {
    const managementGroupPages = managementGroupsQuery.data?.pages || [];
    return flatten(managementGroupPages.map((p) => p.collection));
  }, [managementGroupsQuery.data]);

  return (
    <div className="page-main">
      <ManagementGroupsHeader showNew={ showNew } />
      <div className={ styles['management-groups-container'] }>
        <div className="display-flex align-items-center">
          <ManagementGroupsFilter
            filter={ filter }
          />
        </div>
        <div className="management-groups-table-wrapper">
          <ManagementGroupsTable
            managementGroups={ managementGroups }
            query={ managementGroupsQuery }
          />
        </div>
      </div>
    </div>
  );
};

export default ManagementGroups;
