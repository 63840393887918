import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IRevenueServiceDocumentUpsertedFayeEvent } from '@src/types/docyt_events/revenue_service_document_upserted';

import { useFayeChannel } from './common';

export const useRevenueServiceDocumentsFayeChannel = (
  revenueServiceId: TID,
  callback: TFayeEventCallback<IRevenueServiceDocumentUpsertedFayeEvent>,
) => {
  useFayeChannel(
    `/revenue-service/${revenueServiceId}/revenue-service-documents`,
    callback,
  );
};
