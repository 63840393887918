import React, { useMemo } from 'react';

import { useGetWidgets } from '@src/hooks/queries/dashboards/widgets';
import { TID, TMongoID } from '@src/types/common';

import BriefChartView from './brief_chart_view';

interface IBriefViewProps {
  businessId: TID,
  dashboardId: TMongoID,
}

const BriefView = ({
  businessId,
  dashboardId,
}: IBriefViewProps) => {
  const query = useGetWidgets({ dashboardId });
  const widgets = useMemo(() => {
    return query.data?.collection || [];
  }, [query.data?.collection]);

  return (
    <>
      {
        widgets.map((widget) => (
          <BriefChartView
            key={ widget.id }
            businessId={ businessId }
            className="col-sm-6 col-md-4 pointer"
            dashboardId={ dashboardId }
            widget={ widget }
          />
        ))
      }
    </>
  );
};

export default BriefView;
