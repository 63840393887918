import React, { useMemo } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';
import { BusinessDefaultAvatarIcon, DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IBusinessItemProps {
  active: boolean,
  avatar: string,
  label: string,
  value: number,
  field: ControllerRenderProps<any, 'reportServiceIds'>,
  onSelect: (checked: boolean, val: string | undefined) => void
}

const BusinessItem = ({
  active,
  avatar,
  label,
  value,
  field: { value: fieldValue },
  onSelect,
}: IBusinessItemProps) => {
  const isChecked = useMemo(() => fieldValue.includes(value), [value, fieldValue]);

  return (
    <li className={ styles['business-item'] }>
      <div className={ styles['label-item'] }>
        { avatar ? (
          <img
            alt="Avatar"
            className={ styles.avatar }
            src={ avatar }
          />
        ) : (
          <BusinessDefaultAvatarIcon fontSize={ 40 } />
        ) }

        <p className={ styles.name }>{ label }</p>

        { !active && (
          <div className={ styles.notice }>
            <p className={ styles.label }>Report not active</p>
            <div className={ styles.icon }>
              <Tooltip.Hover content="One or more chart of accounts have not been mapped, which might result in incorrect report data.">
                <DocytSupportIcon fontSize={ 16 } />
              </Tooltip.Hover>
            </div>
          </div>
        ) }
      </div>
      <div>
        <CheckboxInput
          checked={ isChecked }
          disabled={ !active }
          value={ value }
          onChange={ onSelect }
        />
      </div>
    </li>
  );
};

export default React.memo(BusinessItem);
