import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { TAmount } from '@src/types/common';
import { currencyLocaleFormatter } from '@src/utils/currency';

import Form from '@src/components/ui_v2/form';

import CategorySplitsField from './category_splits_field';
import { adjustmentValidation, IAddAdjustmentValues } from './schema';

interface IMatchDocumentAddAdjustmentFormProps {
  adjustmentAmount: TAmount,
  formId: string,
  onSubmit: (data: IAddAdjustmentValues) => void;
}

const MatchDocumentAddAdjustmentForm = ({
  adjustmentAmount,
  formId,
  onSubmit,
}: IMatchDocumentAddAdjustmentFormProps) => {
  const { control, formState: { errors }, handleSubmit } = useForm<IAddAdjustmentValues>({
    defaultValues: {
      adjustmentAmount,
    },
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(adjustmentValidation),
  });

  const categoryControl = useController({ control, name: 'categorySplits' });

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <p>
        Document amount is not the same as transaction amount.
        Add an adjustment entry to match the document with transaction.
      </p>
      <Form.StaticField
        label="Adjustment Amount"
        value={ currencyLocaleFormatter(adjustmentAmount) }
      />
      <CategorySplitsField
        adjustmentAmount={ adjustmentAmount }
        // @ts-ignore Errors has wrong typing. This object actually has `message`
        error={ errors.categorySplits?.message }
        label="Category"
        { ...omit(categoryControl.field, 'ref') }
      />
    </Form>
  );
};

export default React.memo(MatchDocumentAddAdjustmentForm);
