import React, { FC } from 'react';

import { ResourceFilterField } from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

interface Props {
}

const Component: FC<Props> = () => {
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('account') && (
      <Filter.TextField
        label="Chart of Account"
        name="account"
        placeholder="Chart of Account"
      />
      )) }
      { (visibleColumnNames.includes('account_type') && (
      <ResourceFilterField
        label="Account Type"
        name="account_type"
        placeholder="Type"
        resource="chart_of_account_types"
      />
      )) }
      { (visibleColumnNames.includes('detail_type') && (
      <ResourceFilterField
        label="Detail Type"
        name="detail_type"
        placeholder="Detail Type"
        resource="chart_of_account_detail_types"
      />
      )) }
      { (visibleColumnNames.includes('business') && (
      <ResourceFilterField
        multiple
        label="Business"
        name="business"
        placeholder="Business"
        resource="businesses"
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
