import React from 'react';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { ICSDCollection } from '../hooks';
import SelectedAmount from '../selected_amount';
import Table from './table';

interface IMatchDocumentsCombinedListProps {
  collection: ICSDCollection,
}

const MatchDocumentsCombinedList = ({
  collection,
}: IMatchDocumentsCombinedListProps) => {
  return (
    <Section.Provider section={ collection.section }>
      <TableSection>
        <TableSection.Caption>Documents</TableSection.Caption>
        <TableSection.Footer>
          <TableSection.PaginationInfo infiniteQuery={ collection.query } />
          <SelectedAmount
            amountField="amountVerified"
            selectedRecords={ collection.selectedRecords }
          />
          <TableSection.ClearFilter />
        </TableSection.Footer>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(MatchDocumentsCombinedList);
