import React, { useCallback } from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import { Accordion } from '@src/components/ui_v2/accordion';

import AchStatus from '../../status_item/status';

import styles from '../styles.module.scss';

interface IAchTrackingProps {
  document: IAchTransaction,
}

const AchTracking = ({
  document,
}: IAchTrackingProps) => {
  const handleCancelInvoice = useCallback(() => {
    if (document.serviceDocumentIds.length > 0) {
      const documentId = document.serviceDocumentIds[0];
      window.Docyt.vent.trigger('ap_service:document:cancel:pay', documentId);
    }
  }, [document.serviceDocumentIds]);

  const content = (
    <AchStatus
      document={ document }
      showTitle={ false }
      onCancelInvoice={ handleCancelInvoice }
    />
  );

  return (
    <Accordion
      size="compact"
      title="Tracking"
    >
      {() => (
        <div className={ styles.tracking }>
          { content }
        </div>
      )}
    </Accordion>
  );
};

export default React.memo(AchTracking);
