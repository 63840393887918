import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { amountFormatterX } from '@src/components/reconciliation_center/month_end/utils';
import { MoreInfoIcon } from '@src/components/utils/icomoon';

import DeleteIcon from './delete_icon';
import EditIcon from './edit_icon';
import { useLineAmount } from './use_journal_entry_amount';

import styles from '../styles.module.scss';

const JournalEntryItem: FC<{item: IAdjustmentEntry}> = ({ item }) => {
  const amount = useLineAmount(item);
  const business = useBusinessContext();

  const viewMore = () => {
    Backbone.history.navigate(
      `/businesses/${business.id}/reconciliation_center/journal_entry?docyt_id=${item.docytId}`,
      { trigger: true },
    );
  };

  return (
    <div className={ styles['entry-actions'] }>
      <div>{ amountFormatterX(amount) }</div>
      {
        item.source.type === 'Normal' ? (
          <>
            <div><EditIcon id={ item.id } /></div>
            <div><DeleteIcon id={ item.id } /></div>
          </>
        ) : <MoreInfoIcon pointer onClick={ viewMore } />
      }
    </div>
  );
};

export default JournalEntryItem;
