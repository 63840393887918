import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import {
  SuccessIcon,
  QuestionMarkOrangeIcon,
} from '@src/components/utils/icomoon';

interface IFlaggedStateIconProps {
  document: ITransactionServiceDocument,
}

const FlaggedStateIcon = ({
  document,
}: IFlaggedStateIconProps) => {
  if (document.flaggedState === 'open') {
    return <QuestionMarkOrangeIcon fontSize={ 18 } />;
  }

  if (document.flaggedState === 'resolved') {
    return <SuccessIcon fontSize={ 18 } />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ null }</>;
};

export default React.memo(FlaggedStateIcon);
