import React from 'react';

import { TID } from '@src/types/common';
import { API_DATE_FORMAT, endOfMonthDate, formatDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import { useBalanceSheetStatementUploadModal } from '../../../balance_sheet_statement_modal/upload_modal/upload_modal';

interface IUploadStatementActionProps {
  businessId: TID,
  chartOfAccountId: TID,
  month: number | string,
}

const UploadStatementAction = ({
  businessId,
  chartOfAccountId,
  month,
}: IUploadStatementActionProps): JSX.Element => {
  const selectedMonthStr = month;
  const uploadModal = useBalanceSheetStatementUploadModal();

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
        preSelectedChartOfAccountId={ chartOfAccountId }
        preSelectedDate={ formatDate(endOfMonthDate(new Date(selectedMonthStr)), API_DATE_FORMAT) }
      />
      <ActionsDropdown.Action
        title="Upload Document"
        onClick={ uploadModal.open }
      />
    </>
  );
};

export default UploadStatementAction;
