import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IReconciliationUpdatedFayeEvent } from '@src/types/docyt_events/reconciliation_updated';

import { useFayeChannel } from './common';

export const useBankAccountReconciliationFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<IReconciliationUpdatedFayeEvent>,
) => {
  useFayeChannel(
    `/bank_account_reconciliation-${businessId}`,
    callback,
  );
};
