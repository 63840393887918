module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade modal-upload-files\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class=\'custom-modal\'>\n        <div class=\'modal-header\'>\n          <button class=\'close\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>Categorize Documents</h4>\n          <p class=\'modal-user-name\'>');
    
      __out.push(__sanitize(this.objectName));
    
      __out.push('</p>\n        </div>\n        <div class=\'modal-body clearfix\'>\n          <div class=\'upload-files-wrapper\'>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'modal-link-btn cancel-link\'>Cancel</a>\n          <button class=\'btn btn-blue m-t-5\' id=\'save\' type=\'button\'>Save</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}