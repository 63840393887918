import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TDate, TID } from '@src/types/common';

import ChartOfAccountField from '@src/components/common_v2/form_fields/chart_of_account_field';
import Form from '@src/components/ui/form';

import { IBalanceSheetStatementDetailsFormValues, statementValidation } from '../schema';
import UploadView from './upload_view';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IBalanceSheetStatementDetailsFormProps {
  businessId: TID,
  balanceSheetStatement?: IBalanceSheetStatement,
  preSelectedChartOfAccountId?: TID,
  preSelectedDate?: TDate,
  formId?: string,
  isFileUploading?: boolean,
  onSubmit: SubmitHandler<IBalanceSheetStatementDetailsFormValues>,
  onChange?: (values: IBalanceSheetStatementDetailsFormValues) => void,
}

const BalanceSheetStatementDetailsForm = ({
  businessId,
  balanceSheetStatement,
  preSelectedChartOfAccountId,
  preSelectedDate,
  isFileUploading,
  formId,
  onSubmit,
}: IBalanceSheetStatementDetailsFormProps): JSX.Element => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IBalanceSheetStatementDetailsFormValues>({
    defaultValues: {
      balanceSheetStatement,
      chartOfAccountId:
        preSelectedChartOfAccountId || balanceSheetStatement?.chartOfAccount?.id,
      date: preSelectedDate || balanceSheetStatement?.date,
    },
    resolver: yupResolver(statementValidation),
  });

  const dateController = useController({ name: 'date', control });
  const accountController = useController({ name: 'chartOfAccountId', control });
  const descriptionController = useController({ name: 'description', control });

  return (
    <Form className="display-flex width-100-percent" id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <UploadView
        balanceSheetStatement={ balanceSheetStatement }
        error={ errors.balanceSheetStatement?.id?.message }
        isFileUploading={ isFileUploading }
        onUploaded={ (value) => setValue('file', value) }
      />
      <div className={ styles['form-container'] }>
        <div className={ styles['form-header'] }>
          <span className="font-15 font-bold">Document Details</span>
        </div>
        <div className={ styles['statement-detail-form'] }>
          <ChartOfAccountField
            accountType="balance_sheet"
            businessId={ businessId }
            error={ errors.chartOfAccountId?.message }
            label="Balance Sheet Account"
            labelClass="width-28-percent"
            { ...accountController.field }
          />
          <Form.DateField
            error={ errors.date?.message }
            label="Date"
            placeholder="Select Date"
            { ...dateController.field }
          />
          <Form.TextField
            error={ errors.date?.message }
            label="Description"
            placeholder="Enter Description"
            { ...descriptionController.field }
          />
        </div>
      </div>
    </Form>
  );
};

export {
  IBalanceSheetStatementDetailsFormProps,
  IBalanceSheetStatementDetailsFormValues,
  BalanceSheetStatementDetailsForm as default,
};
