import React from 'react';

import { IPaymentProcessor } from '@src/types/payment_processors';

import { IReconcileRSDCollection } from '@src/components/revenue_center/reconcile/hooks';
import TotalAmount from '@src/components/revenue_center/reconcile/total_amount';
import { totalAmountForPaymentProcessor } from '@src/components/revenue_center/utils';
import TableSection from '@src/components/ui_v2/table_section';

import Table from './table';

interface IReconcileRevenueListProps {
  collection: IReconcileRSDCollection,
  currentPaymentProcessor: IPaymentProcessor,
}

const ReconcileRevenueList = ({
  collection,
  currentPaymentProcessor,
}: IReconcileRevenueListProps) => {
  const totalAmount = totalAmountForPaymentProcessor(
    collection.records,
    currentPaymentProcessor.id,
  );

  return (
    <TableSection>
      <TableSection.Footer>
        <TableSection.Caption>
          Revenue Collected from
          { ' ' }
          { currentPaymentProcessor.name }
        </TableSection.Caption>
        <TotalAmount amount={ totalAmount } />
      </TableSection.Footer>
      <Table
        currentPaymentProcessor={ currentPaymentProcessor }
        query={ collection.query }
        records={ collection.records }
        sorting={ collection.sorting }
      />
    </TableSection>
  );
};

export default React.memo(ReconcileRevenueList);
