import { useQuery } from 'react-query';

import {
  getAccountAllBigTypes, getAccountAllBusinesses,
  getAccountAllSubTypes,
  getAccountAllTypeCategories,
  getAccountBigTypes,
  getAccountSubTypes,
  getAccountTypeCategories,
  IGetAccountAllTypeCategoriesParams,
  IGetAccountBigTypesParams,
  IGetAccountBigTypesResponse,
  IGetAccountSubTypesParams,
  IGetAccountSubTypesResponse,
  IGetAccountTypeCategoriesParams,
  IGetAccountTypeCategoriesResponse,
  IGetAllAccountSubTypesParams,
} from '@src/requests/account_types';
import { IGetBusinessesParams, IGetBusinessesResponse } from '@src/requests/businesses';

import Error from '@src/components/ui/side_view/error';

const ACCOUNT_BIG_TYPES_QUERY = 'account_big_types';
const ACCOUNT_SUB_TYPES_QUERY = 'account_sub_types';
const ACCOUNT_TYPE_CATEGORIES_QUERY = 'account_type_categories';
const ACCOUNT_ALL_BIG_TYPES_QUERY = 'account_all_big_types';
const ACCOUNT_ALL_SUB_TYPES_QUERY = 'account_all_sub_types';
const ACCOUNT_ALL_TYPE_CATEGORIES_QUERY = 'account_all_type_categories';
const ACCOUNT_ALL_BUSINESSED_QUERY = 'account_all_businesses';

const useAccountBigTypes = (params: IGetAccountBigTypesParams) => {
  return useQuery<IGetAccountBigTypesResponse, Error>(
    [ACCOUNT_BIG_TYPES_QUERY, params],
    () => getAccountBigTypes(params),
  );
};

const useAccountSubTypes = (params: IGetAccountSubTypesParams) => {
  return useQuery<IGetAccountSubTypesResponse, Error>(
    [ACCOUNT_SUB_TYPES_QUERY, params],
    () => getAccountSubTypes(params),
  );
};

const useAccountTypeCategories = (params: IGetAccountTypeCategoriesParams) => {
  return useQuery<IGetAccountTypeCategoriesResponse, Error>(
    [ACCOUNT_TYPE_CATEGORIES_QUERY, params],
    () => getAccountTypeCategories(params),
  );
};

const useAccountAllBigTypes = () => {
  return useQuery<IGetAccountBigTypesResponse, Error>(
    ACCOUNT_ALL_BIG_TYPES_QUERY,
    getAccountAllBigTypes,
  );
};

const useAccountAllSubTypes = (params: IGetAllAccountSubTypesParams) => {
  return useQuery<IGetAccountSubTypesResponse, Error>(
    [ACCOUNT_ALL_SUB_TYPES_QUERY, params],
    () => getAccountAllSubTypes(params),
  );
};

const useAccountAllTypeCategories = (params: IGetAccountAllTypeCategoriesParams) => {
  return useQuery<IGetAccountTypeCategoriesResponse, Error>(
    [ACCOUNT_ALL_TYPE_CATEGORIES_QUERY, params],
    () => getAccountAllTypeCategories(params),
  );
};

const useAccountAllBusinesses = (params: IGetBusinessesParams) => {
  return useQuery<IGetBusinessesResponse, Error>(
    [ACCOUNT_ALL_BUSINESSED_QUERY, params],
    () => getAccountAllBusinesses(params),
  );
};

export {
  useAccountBigTypes,
  useAccountSubTypes,
  useAccountTypeCategories,
  useAccountAllBigTypes,
  useAccountAllSubTypes,
  useAccountAllTypeCategories,
  useAccountAllBusinesses,
};
