module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="p-b-50 text-center dashboard-onboarding-introduce-view-container">\n  <div class="videos-card">\n    <div class="card-action-wrapper">\n      <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="introduction-container-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class=\'icon icon-3-dots font-20\'></i>\n      </button>\n      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="introduction-container-action-dropdown-btn">\n        <a class="dropdown-item font-16 pointer hide-introduction-widget-js">\n          <span class=\'\'>Hide Widget</span>\n        </a>\n      </div>\n    </div>\n    <h2 class="card-header">Welcome to Docyt</h2>\n    <p class="card-description">\n      Docyt is a digital back-office, that can cut your accounting costs by up to 80%. Watch video to learn more.\n    </p>\n    <div class="card-video-wrapper">\n      <iframe width="500" height="280" src="');
    
      __out.push(__sanitize(configData.onboarding_video_embed_url));
    
      __out.push('"></iframe>\n    </div>\n    <h3 class="card-sub-description">Single software with powerful automation</h3>\n    <div class="clearfix">\n      <table class="introduction-table">\n        <tbody>\n          <tr>\n            <td class="avatar-column">\n              <div class="small-avatar-wrapper">\n                <img src="');
    
      __out.push(__sanitize(configData.dashboard_images.BANK_TRANSACTIONS));
    
      __out.push('">\n              </div>\n            </td>\n            <td class="description-column">\n              <p class="introduction-sub-title">Real-time Bank Feed</p>\n              <p class="introduction-description">\n                All Bank/CC transactions in one place, Automatically categorized. Military grade encryption & data security.\n              </p>\n            </td>\n          </tr>\n          <tr>\n            <td class="avatar-column">\n              <div class="small-avatar-wrapper">\n                <img src="');
    
      __out.push(__sanitize(configData.dashboard_images.RECEIPT_BOX));
    
      __out.push('">\n              </div>\n            </td>\n            <td class="description-column">\n              <p class="introduction-sub-title">Receipt Box</p>\n              <p class="introduction-description">\n                Replaces tools like Receipt Bank, Expensify, Auto updates QuickBooks with all receipts.\n                <br>\n                Protects from audits.\n              </p>\n            </td>\n          </tr>\n          <tr>\n            <td class="avatar-column">\n              <div class="small-avatar-wrapper">\n                <img src="');
    
      __out.push(__sanitize(configData.dashboard_images.ACCOUNTS_PAYABLE));
    
      __out.push('">\n              </div>\n            </td>\n            <td class="description-column">\n              <p class="introduction-sub-title">Accounts Payable</p>\n              <p class="introduction-description">\n                Scan your bills and generate paper checks or ACH payments. 100% paperless system.\n                <br>\n                Replaces Bill.com. Generates 1099 forms for contractors.\n              </p>\n            </td>\n          </tr>\n          <tr>\n            <td class="avatar-column">\n              <div class="small-avatar-wrapper">\n                <img src="');
    
      __out.push(__sanitize(configData.dashboard_images.RECONCILIATION_CENTER));
    
      __out.push('">\n              </div>\n            </td>\n            <td class="description-column">\n              <p class="introduction-sub-title">Bookkeeping & Reconciliation</p>\n              <p class="introduction-description">\n                Docyt will automatically reconcile your bank/CC transactions with bills & receipts to generate Real-time Business Financials.\n              </p>\n            </td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n  <div class="setup-businesses-card">\n    <div class="border-bottom-gray p-b-10 text-left">\n      <div class="card-action-wrapper">\n        <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="setup-businesses-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n          <i class=\'icon icon-3-dots font-20\'></i>\n        </button>\n        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="setup-businesses-action-dropdown-btn">\n          <a class="dropdown-item font-16 finish-onboarding-js pointer">\n            <span class=\'\'>Done with Setup</span>\n          </a>\n        </div>\n      </div>\n      <p class="display-table-row m-b-0">\n        <i class="display-inline-flex icon-cog font-28 in-blue-900 p-r-10"></i>\n        <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n          Setup Business\n        </span>\n      </p>\n    </div>\n    <div class="clearfix">\n      <table class="business-status-table">\n        <tbody>\n          ');
    
      ref = Docyt.currentAdvisor.get('user_dashboard_businesses');
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n          <tr class="business-row">\n            <td class="avatar-column">\n              <div class="small-avatar-wrapper">\n                <img src="');
        __out.push(__sanitize(configData.dashboard_images.BUSINESS_CLIENTS));
        __out.push('">\n              </div>\n            </td>\n            <td class="business-name-column">\n              ');
        __out.push(__sanitize(business.legal_name));
        __out.push('\n            </td>\n            <td class="action-column">\n              ');
        if (business.setup_state === 'setup') {
          __out.push('\n                <a type=\'button\' class="btn btn-blue finish-business-btn" href="/setup-business/');
          __out.push(__sanitize(business.business_id));
          __out.push('">Finish Setup</a>\n              ');
        } else {
          __out.push('\n                <i class="icon-check"></i>\n              ');
        }
        __out.push('\n            </td>\n          </tr>\n          ');
      }
    
      __out.push('\n        </tbody>\n      </table>\n    </div>\n    <div class="clearfix">\n      <button type=\'button\' class="btn btn-info ladda-button finish-onboarding-js m-t-15" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Done with Setup</span></button>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}