import React from 'react';

import { useGetManagementGroupByBusinessId } from '@src/hooks/queries/management_groups';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import ManagementGroupFilterSelect from './management_group_filter_select';
import { IFinancialInstitutionConnectionFilter } from './types';

import styles from '../styles.module.scss';

interface IFinancialInstitutionConnectionFilterProps {
  businessId?: TID,
  filter: IFilter<IFinancialInstitutionConnectionFilter>;
}

const FinancialInstitutionConnectionFilter = ({
  businessId,
  filter,
}: IFinancialInstitutionConnectionFilterProps): JSX.Element => {
  const query = useGetManagementGroupByBusinessId(businessId);
  const managementGroup = query.data?.managementGroup;

  return (
    <Filter<IFinancialInstitutionConnectionFilter>
      className={ styles['management-group-filter'] }
      filter={ filter }
    >
      <ManagementGroupFilterSelect
        managementGroup={ managementGroup }
        widthPercent={ 100 }
      />
    </Filter>
  );
};

export default React.memo(FinancialInstitutionConnectionFilter);
