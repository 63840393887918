import React from 'react';

import { useTooltip } from './hooks';

interface ITooltipHoverContainerProps<T extends React.ElementType> {
  as?: T,
  children: React.ReactNode,
  isControl?: boolean,
  showTooltip?: boolean,
}

type TTooltipHoverContainerPropsAs<T extends React.ElementType> =
  ITooltipHoverContainerProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof ITooltipHoverContainerProps<T>>

const TooltipHoverContainer = <T extends React.ElementType = 'div'>({
  as,
  children,
  isControl,
  showTooltip,
  ...props
}: TTooltipHoverContainerPropsAs<T>) => {
  const {
    isVisible,
    referenceRef,
    showOnHoverAttributes,
    show,
    hide,
  } = useTooltip() || {};

  const Component = as || 'div';

  if (isControl) {
    if (!isVisible && showTooltip && show) show();
    if (isVisible && !showTooltip && hide) hide();
  }

  return (
    <Component
      ref={ referenceRef }
      { ...props }
      { ...(showOnHoverAttributes || {}) }
    >
      { children }
    </Component>
  );
};

export default TooltipHoverContainer;
