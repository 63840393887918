module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!this.deletedAt) {
        __out.push('\n<div class="display-flex inbox-document-actions-container">\n  <a class="btn btn-default m-l-auto m-r-5" id="btnRename">Rename</a>\n  <a class="btn btn-default m-r-5" id="btnForward"><i class="fa fa-share m-r-5"></i> Forward</a>\n  ');
        if (this.isSplitEnabled) {
          __out.push('\n  <a class="btn btn-default m-r-5" id="btnSplit"><span class="icon-split_to_invoices m-r-5 in-black"></span> Split</a>\n  ');
        }
        __out.push('\n  <a class="btn btn-default m-r-5 in-red-400" id="btnDelete"><i class="fa fa-trash m-r-5"></i> Delete</a>\n</div>\n');
      }
    
      __out.push('\n<div class="inbox-document-container">\n  <div id="document-pdf-viewer-region" class=""></div>\n  <div id="inbox-document-actions-region" class="">\n    <div class="split-actions-container hidden">\n      <span class="split-points-label m-r-20 hidden">Split Points: <span class="split-points-label-content"></span></span>\n      <a class="btn btn-circle btn-default m-r-5" id="btnPrevPage"><i class="fa fa-angle-left"></i></a>\n      <a class="btn btn-circle btn-default m-r-5" id="btnNextPage"><i class="fa fa-angle-right"></i></a>\n      <a class="btn btn-default in-blue-900 m-r-5" id="btnAddSplit">Add Split below this page</a>\n      <a class="btn btn-default in-blue-900 m-r-5 hidden" id="btnClearSplit">Clear this Split</a>\n      <a class="btn btn-blue m-r-5" id="btnSplitDone">Done</a>\n      <a class="btn btn-default" id="btnSplitCancel">Cancel</a>\n    </div>\n  </div>\n  <div id="inbox-document-title-container">\n    <h3 class="font-20 font-bold title-inbox-document">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</h3>\n    <div class="input-group rename-container hidden">\n      <input type="text" class="form-control font-20 font-semi-bold height-36px" id="txtDocumentName" value="');
    
      __out.push(__sanitize(this.documentName));
    
      __out.push('">\n      <span class="input-group-btn">\n        <button class="btn btn-blue" id="btnSaveName" type="button">Save</button>\n      </span>\n    </div>\n  </div>\n  <div id="inbox-document-status-container" class="hidden">\n    <div class="well font-semi-bold in-white">\n      <i class="fa fa-clock-o"></i> Document Split in progress. It may take a few seconds for split documents to appear.\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}