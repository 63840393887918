import React, { useEffect } from 'react';

import { useEditManagementGroupModal } from '@src/components/management_groups/modals/edit_management_group_modal';
import { Button } from '@src/components/ui/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

interface IAddManagementGroupActionProps {
  showNew: boolean,
}

const AddManagementGroupAction = ({
  showNew,
}: IAddManagementGroupActionProps) => {
  const editManagementGroup = useEditManagementGroupModal({});

  useEffect(() => {
    if (showNew) {
      editManagementGroup.open();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return true if user has admin access to atleast one of the businesses listed in business view
  const isAdminInAnyBusiness: boolean = window.Docyt.currentAdvisor.get('is_admin_in_any_business')

  return (
    <>
      <editManagementGroup.Component { ...editManagementGroup.props } />
      {isAdminInAnyBusiness && (
        <Button
        bsColor="blue"
        disabled={ editManagementGroup.props.isOpen }
        onClick={ editManagementGroup.open }
        >
          <PlusIcon mr={ 10 } />
          New Management Group
        </Button>
      )}
    </>
  );
};

export default AddManagementGroupAction;
