import React, { useCallback, useMemo } from 'react';

import { ITransactionServiceDocumentAmountType } from '@src/types/transaction_service_documents';

import { ReactSelectInput } from '@src/components/ui/form';
import { IReactSelectInputProps } from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface ITransactionAmountTypeInputProps extends
  Omit<
    IReactSelectInputProps<ITransactionServiceDocumentAmountType, false>,
    'isMulti' | 'options' | 'value' | 'onChange'
  >
{
  value?: ITransactionServiceDocumentAmountType['value'],
  accountType?: string,
  onChange: (value?: ITransactionServiceDocumentAmountType['value']) => void,
}

const getOptionLabel = (type: ITransactionServiceDocumentAmountType) => type.name;
const getOptionValue = (type: ITransactionServiceDocumentAmountType) => type.value;

const BlankOption = { name: 'None', value: '' };

const TransactionAmountTypeInput = ({
  accountType,
  className,
  value,
  onChange,
  ...selectProps
}: ITransactionAmountTypeInputProps): JSX.Element => {
  const options = useMemo(() => {
    switch (accountType) {
      case 'bank':
        return [
          { name: window.Docyt.Common.Constants.TRANSACTION_AMOUNT_TYPES.DEPOSIT, value: 'deposit' },
          { name: window.Docyt.Common.Constants.TRANSACTION_AMOUNT_TYPES.WITHDRAWAL, value: 'withdrawal' },
        ];
      case 'credit_card':
        return [
          { name: window.Docyt.Common.Constants.TRANSACTION_AMOUNT_TYPES.CHARGE, value: 'charge' },
          { name: window.Docyt.Common.Constants.TRANSACTION_AMOUNT_TYPES.PAYMENT, value: 'payment' },
        ];
      default:
        return [BlankOption];
    }
  }, [accountType]);

  const selectedType = useMemo(() => {
    if (!value) return null;

    return options.find((at: any) => at.value === value);
  }, [value, options]);

  const handleChange = useCallback((type: ITransactionServiceDocumentAmountType | null) => {
    if (type === null) return;
    onChange(type.value);
  }, [onChange]);

  return (
    <ReactSelectInput<ITransactionServiceDocumentAmountType, false>
      className={ className }
      getOptionLabel={ getOptionLabel }
      getOptionValue={ getOptionValue }
      options={ options }
      placeholder="Type"
      widthPercent={ 100 }
      { ...selectProps }
      value={ selectedType }
      onChange={ handleChange }
    />
  );
};

export default React.memo(TransactionAmountTypeInput);
