import React, { memo } from 'react';

import { aiChatBotPath } from '@src/routes';

import { AppLink } from '@src/components/ui_v2/buttons';
import { AiChatLogoIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

const NavigateBtn = () => {
  return (
    <div className={ styles['navigate-btn'] }>
      <AppLink className={ styles.logo } href={ aiChatBotPath() }>
        <AiChatLogoIcon />
        <span>
          Ask Docyt AI
        </span>
      </AppLink>
    </div>
  );
};
export default memo(NavigateBtn);
