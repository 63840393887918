import React from 'react';

import Panel from './panel';

import styles from './styles.module.scss';

interface ILandingPanel extends React.ComponentPropsWithoutRef<typeof Panel> {
  image: React.ReactNode,
  title?: string,
  text?: string,
}

const LandingPanel = ({
  children,
  image,
  title,
  text,
}: ILandingPanel): JSX.Element => {
  return (
    <Panel className={ styles.landing }>
      <div className={ styles['landing-image'] }>
        { image }
      </div>
      {
        title
          && <p className={ styles['landing-title'] }>{ title }</p>
      }
      {
        text
          && <p className={ styles['landing-text'] }>{ text }</p>
      }
      <div className={ styles['landing-actions'] }>
        { children }
      </div>
    </Panel>
  );
};

export default React.memo(LandingPanel);
