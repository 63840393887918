import React, { useCallback, useMemo, useState } from 'react';

import {
  IPracticeInformation,
  IBookkeepingStatus,
  TBookkeepingStatus,
} from '@src/types/practice_informations';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import { UserAvatarsList } from '@src/components/ui/avatars';
import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';
import { BlockIcon, OnboardingIcon } from '@src/components/utils/icomoon';
import NoTeamAssignedIcon from '@src/components/utils/icomoon/no_team_assigned';
import SuccessIcon from '@src/components/utils/icomoon/success';

import Actions from './actions';
import BookkeepingStatusList from './bookkeeping_status_list';

interface IMyPracticeListItemProps {
  information: IPracticeInformation,
}

const MyPracticeListItem = ({
  information,
}: IMyPracticeListItemProps): JSX.Element => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }
  const statuses = useMemo(() => {
    let result: Array<IBookkeepingStatus> = [];
    for (let i = 0; i < 12; i += 1) {
      const bStatus = information.bookkeepingStatuses.find((b) => b.month === i + 1);
      if (bStatus != null) {
        result = [...result, { month: i + 1, status: bStatus.status as TBookkeepingStatus }];
      } else {
        result = [...result, { month: i + 1, status: 'not-started' }];
      }
    }
    return result;
  }, [information.bookkeepingStatuses]);

  const classes = information.bookkeepingStatusBlocked ? 'blocked-item' : '';

  const handleClick = useCallback(() => {
    if (sunsetClientCentralEnabled) {
      window.open(`/businesses/${information.id}/open_items/document_requests`, '_blank');
    } else {
      window.open(`/client_central/${information.id}`, '_blank');
    }
  }, [information.id, sunsetClientCentralEnabled]);

  return (
    <Table.Row className={ classes }>
      <Table.Cell
        className="pointer border-right-black"
      >
        <div className="display-flex align-items-center justify-content-between">
          <div className="display-flex-column m-r-auto">
            <a className="font-semi-bold pointer m-r-auto" role="button" tabIndex={ -1 } onClick={ handleClick }>
              { information.displayName }
            </a>
            <a className="pointer font-12 in-grey-1100" role="button" tabIndex={ -1 } onClick={ handleClick }>
              { information.legalName }
            </a>
          </div>
          {
            information.isReviewed && (<SuccessIcon mr={ 10 } />)
          }
          {
            (information.members.length === 0) && (<NoTeamAssignedIcon mr={ 10 } />)
          }
          {
            information.bookkeepingStatusBlocked && (
              <Tooltip
                as={ BlockIcon }
                mr={ 10 }
                title={
                '<span class="in-grey-800">Bookkeeping status:</span><br/>'
                + '<p class="in-grey-300">Blocked by '
                + `${information.bookkeepingBlocker} on ${information.bookkeepingBlockedAt}</p>`
                + '<span class="in-grey-800">Description:</span><br/>'
                + `<span class="in-grey-300">${information.bookkeepingStatusDescription}</span>`
              }
              />
            )
          }
          {
            information.bookkeepingStatusOnboarding && (
              <Tooltip
                as={ OnboardingIcon }
                mr={ 10 }
                title={
                  '<span class="in-grey-300">Onboarding</span><br/>'
                  + `<p class="in-grey-800">Updated on ${information.bookkeepingOnboardedAt}</p>`
                }
              />
            )
          }
        </div>
      </Table.Cell>
      <Table.Cell
        className="border-right-black"
      >
        <UserAvatarsList users={ information.members } />
      </Table.Cell>
      <BookkeepingStatusList
        statuses={ statuses }
      />
      <Table.Cell className="inbox-action-column relative text-right pointer">
        <Actions information={ information } />
      </Table.Cell>
    </Table.Row>
  );
};

export default MyPracticeListItem;
