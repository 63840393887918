import React from 'react';

import useCreateDocumentRequestModal from '@src/components/client_central/create_document_request_modal';

interface ICreateDocumentRequestActionProps {
  businessId: number,
  onCreateConfirmed: () => void,
}

const CreateDocumentRequestAction = ({
  businessId,
  onCreateConfirmed,
}: ICreateDocumentRequestActionProps) => {
  const alert = useCreateDocumentRequestModal({
    onDone: onCreateConfirmed,
  });

  return (
    <>
      <alert.Component
        { ...alert.props }
        businessId={ businessId }
      />
      <a
        className="font-bold pointer"
        role="button"
        tabIndex={ -1 }
        onClick={ alert.open }
      >
        <i className="icon-plus m-r-6" />
        Create Document Request
      </a>
    </>
  );
};

export default CreateDocumentRequestAction;
