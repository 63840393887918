import { object, string } from 'yup';

import { IManagementGroup } from '@src/types/management_groups';

const financialInsightsValidation = object({
  frequency: string().nullable().required('Frequency is required.'),
});

interface IFinancialInsightsInput {
  frequency: string,
  selectedGroups: IManagementGroup[],
}

export {
  IFinancialInsightsInput,
  financialInsightsValidation,
};
