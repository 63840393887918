import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IConfirmModalProps extends IUseModalProps {
  title: string,
  content?: string,
  confirmTitle?: string,
  onDone: () => void,
  onCancel: () => void,
}

const ConfirmModal = ({
  isOpen,
  title,
  content,
  confirmTitle = 'Proceed',
  onDone,
  onCancel,
}: IConfirmModalProps): JSX.Element => {
  return (
    <Modal
      dialogClassName="confirm-bank-statement-modal"
      region="second-modal-region"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        <p className="p-b-0 text-center font-16 in-black">{ content }</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link cancel modal-link-btn"
          fontSize={ 18 }
          title="Cancel"
          onClick={ onCancel }
        />
        <Button
          bsSize="xlarge"
          bsStyle="primary"
          fontSize={ 16 }
          title={ confirmTitle }
          onClick={ onDone }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useConfirmModal = makeUseModal(ConfirmModal);

export {
  IConfirmModalProps,
  useConfirmModal,
  ConfirmModal as default,
};
