module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="clients__client pointer add-person-js person-information-row">\n  <div class=\'clients__client-status clients__client-cell\'>\n    ');
    
      if (this.avatarUrl) {
        __out.push('\n      <div class="clients__userpic">\n        <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'clients__userpic-img\'>\n      </div>\n    ');
      } else {
        __out.push('\n      <i class=\'clients__client-status-icon icon icon-smile in-orange-500\'></i>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class=\'clients__client-name clients__client-cell\'>\n    <span class=\'contacts__contact-name-text\'>\n      ');
    
      __out.push(__sanitize(this.parsed_fullname || this.email || this.phone));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'clients__client-extras clients__client-cell\'>\n    <span class=\'contacts__exp-documents\'>\n      ');
    
      __out.push(__sanitize(this.contactType));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'clients__client-right clients__client-cell\'>\n    ');
    
      if (!this.isDefault) {
        __out.push('\n    <a class="link-detail" href="');
        __out.push(__sanitize(this.detailsUrl));
        __out.push('"></a>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="clients__client-cell person-actions-cell text-center">\n    ');
    
      if (!(this.isDefault || this.type === 'BusinessAdvisor')) {
        __out.push('\n    <i class="actions__main-icon actions__icon icon icon-dots"></i>\n    <div class="actions__icons-list">\n      <a class="actions__icon edit-person-js ');
        __out.push(__sanitize(this.is_external ? '' : 'hidden'));
        __out.push('" data-tooltip="Message">\n        <i class="icon icon-pencil"></i>\n      </a>\n      <a class="actions__icon unlink-person-js" data-tooltip="Message">\n        <i class="icon icon-trashcan"></i>\n      </a>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}