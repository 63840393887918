import React, { useState } from 'react';

import { UseMutationOptions, UseMutationResult } from 'react-query';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';
import { TransactionContextData } from '@src/types/bank_account_reconciliations';

type TFetchFunction<TResponse, TParams> = (
    options: UseMutationOptions<TResponse, Error, TParams>
) => UseMutationResult<TResponse, Error, TParams>;

type TSetData = React.Dispatch<React.SetStateAction<TransactionContextData>>;
function useTransactionContext<TResponse, TParams>(
  fetchFunction: TFetchFunction<TResponse, TParams>,
  onSuccess: (response: TResponse, setData: TSetData) => void,
  getContextData: (data: TransactionContextData) => { context: string; requestParams: TParams; },
) {
  const { data, setData } = useTCDContext();
  const [expand, setExpand] = useState(false);

  const options = {
    onSuccess: (response: TResponse) => {
      onSuccess(response, setData);
    },
  };

  const mutation = fetchFunction(options);

  const { mutate, isLoading, error } = mutation;

  const openExpand = () => {
    setExpand(true);
    window.Docyt.vent.trigger('context:region:toggle', true);
    const { context, requestParams } = getContextData(data);
    if (!context) {
      mutate(requestParams);
    }
  };

  const closeExpand = () => {
    window.Docyt.vent.trigger('context:region:toggle', false);
    return setExpand(false);
  };

  return {
    data,
    expand,
    openExpand,
    closeExpand,
    isLoading,
    error,
  };
}

export default useTransactionContext;
