import React, { useCallback } from 'react';

import { useCreateNoRevenueDate } from '@src/hooks/queries/no_revenue_dates';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { useRevenueSystemContext } from '@src/components/revenue_center/revenue_system_provider';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { MarkAsMatchedIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IMarkNoRevenueDayActionProps {
  report: IRevenueServiceDocument,
}

const MarkNoRevenueDayAction = ({
  report,
}: IMarkNoRevenueDayActionProps) => {
  const revenueSystem = useRevenueSystemContext();
  const mutation = useCreateNoRevenueDate();
  const { mutate } = mutation;

  const handleOnClick = useCallback((e) => {
    e.stopPropagation();

    mutate(
      {
        revenueSystemId: revenueSystem.id,
        date:            report.startDate,
      },
    );
  }, [report, mutate, revenueSystem.id]);

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <ActionsDropdown.Action
        icon={ <MarkAsMatchedIcon fontSize={ 20 } /> }
        title="Mark as No Revenue Day"
        onClick={ handleOnClick }
      />
    </>
  );
};

export default MarkNoRevenueDayAction;
