import React, { useCallback } from 'react';

import { SearchIcon } from '@src/components/utils/icomoon';

import { TextInput } from '../inputs';

interface ISearchInputProps extends
  Omit<React.ComponentPropsWithoutRef<typeof TextInput>, 'name' | 'onChange' | 'className'>
{
  onChange: (value: string) => void,
}

const SearchInput = ({
  placeholder,
  value,
  onChange,
  ...props
}: ISearchInputProps) => {
  const handleChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);
  return (
    <TextInput
      aria-label="Search"
      autoComplete="off"
      placeholder={ placeholder }
      prefixIcon={ <SearchIcon /> }
      value={ value }
      onChange={ handleChange }
      { ...props }
    />
  );
};

export default SearchInput;
