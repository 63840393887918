import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TDataExportsSortColumn, IDataExport } from '@src/types/data_exports';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from '../list_item/item';

interface IDataExportListProps {
  dataExports: IDataExport[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TDataExportsSortColumn>,
}

const DataExportList = ({
  dataExports,
  sorting,
  query,
}: IDataExportListProps): JSX.Element => {
  const dataExportRevampV1Enabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.DATA_EXPORT_REVAMP_V_1_FLAG);

  return (
    <Table infiniteQuery={ query } wrapperClassName="business-inbox-table-wrapper">
      <Table.Head>
        <Table.Row className="bg-grey-180">
          <Table.SelectAllHCell />
          <Table.HCell<TDataExportsSortColumn>
            className="border-right-black"
            columnName="export_file_name"
            sorting={ sorting }
            style={ { paddingLeft: 21 } }
            widthPercent={ 20 }
          >
            {dataExportRevampV1Enabled ? 'Export Name' : 'Report Name'}
          </Table.HCell>
          {!dataExportRevampV1Enabled && (
            <Table.HCell<TDataExportsSortColumn>
              className="border-right-black"
              columnName="created_at"
              sorting={ sorting }
              widthPercent={ 25 }
            >
              Period
            </Table.HCell>
          )}
          <Table.HCell<TDataExportsSortColumn>
            columnName="export_type"
            sorting={ sorting }
            widthPercent={ 15 }
          >
            {dataExportRevampV1Enabled ? 'Export Type' : 'Report Type'}
          </Table.HCell>
          <Table.HCell<TDataExportsSortColumn>
            columnName="business_ids"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Businesses
          </Table.HCell>
          <Table.HCell<TDataExportsSortColumn>
            columnName="last_downloaded_at"
            sorting={ sorting }
            widthPercent={ 15 }
          >
            {dataExportRevampV1Enabled ? 'Exported' : 'Last Downloaded At'}
          </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          dataExports.map((dataExport) => {
            return (
              <Item
                key={ `${dataExport.id}` }
                dataExport={ dataExport }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default DataExportList;
