import { useCallback, useEffect, useMemo } from 'react';

import { flatten } from 'lodash';

import { Section } from '@src/constants/sections';
import { useGetManagementGroupsWithNotificationSetting } from '@src/hooks/queries/management_groups';
import { useSorting } from '@src/hooks/url_params';
import { TSection } from '@src/types/common';
import { IManagementGroup, TFinancialInsightsEmailSettingsSortColumn } from '@src/types/management_groups';
import { ISorting, ISortingParams } from '@src/types/sorting';

interface IFinancialInsightsEmailSettingsCollection {
  records: IManagementGroup[],
  section: TSection,
  sorting: ISorting<TFinancialInsightsEmailSettingsSortColumn>,
}

const defaultSorting: ISortingParams<TFinancialInsightsEmailSettingsSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'desc',
};

const useFinancialInsightsEmailSettingsListCollection =
  (): IFinancialInsightsEmailSettingsCollection => {
    const section = useMemo(() => {
      return {
        section: Section.FinancialInsightsEmailSettings,
      };
    }, []);

    const sorting = useSorting<TFinancialInsightsEmailSettingsSortColumn>({
      section: section.section,
      defaultSorting,
    });

    const query = useGetManagementGroupsWithNotificationSetting({});

    const { refetch: refetchManagementGroupsWithNotificationSetting } = query;

    const handleManagementGroupSettingsChanged = useCallback(() => {
      refetchManagementGroupsWithNotificationSetting();
    }, [refetchManagementGroupsWithNotificationSetting]);

    useEffect(() => {
      window.Docyt.vent.on('management_group:settings:changed', handleManagementGroupSettingsChanged);

      return () => {
        window.Docyt.vent.off('management_group:settings:changed');
      };
    }, [handleManagementGroupSettingsChanged]);

    const records = useMemo(() => {
      const pages = query?.data?.pages || [];
      return flatten(pages.map((p) => p.collection));
    }, [query?.data?.pages]);

    return {
      records,
      section,
      sorting,
    };
  };

export {
  IFinancialInsightsEmailSettingsCollection,
  useFinancialInsightsEmailSettingsListCollection,
};
