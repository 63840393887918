import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import DisconnectAction from './disconnect_action';
import ReconnectAction from './reconnect_action';

interface IBusinessInboxListItemActionsProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
}

const BusinessInboxListItemActions = ({
  financialInstitutionConnection,
}: IBusinessInboxListItemActionsProps): JSX.Element => {
  return (
    <ActionsDropdown>
      { !financialInstitutionConnection.isActive ? (
        <>
          <ReconnectAction
            financialInstitutionConnection={ financialInstitutionConnection }
          />
          {
            !financialInstitutionConnection.hasMappedBankAccount && (
              <DisconnectAction
                financialInstitutionConnection={ financialInstitutionConnection }
                status="inActive"
              />
            )
          }
        </>
      ) : (
        <DisconnectAction financialInstitutionConnection={ financialInstitutionConnection } />
      ) }
    </ActionsDropdown>
  );
};

export default BusinessInboxListItemActions;
