import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IDocumentRequest } from '@src/types/document_requests';

import { TrashcanIcon } from '@src/components/utils/icomoon';

interface IDocumentRequestItemProps {
  documentRequest: IDocumentRequest,
  onNameChange: (value: string, id: number) => void,
  onDescriptionChange: (value: string, id: number) => void,
  removeDocumentRequest: (id: number) => void,
}

const DocumentRequestItem = ({
  documentRequest,
  removeDocumentRequest,
  onDescriptionChange,
  onNameChange,
}: IDocumentRequestItemProps): JSX.Element => {
  const handleNameChange = useCallback((e) => {
    onNameChange(e.target.value, documentRequest.id);
  }, [onNameChange, documentRequest.id]);

  const handleDescriptionChange = useCallback((e) => {
    onDescriptionChange(e.target.value, documentRequest.id);
  }, [onDescriptionChange, documentRequest.id]);

  const onRemoveDocumentRequest = useCallback(() => {
    removeDocumentRequest(documentRequest.id);
  }, [removeDocumentRequest, documentRequest.id]);

  const inputNameClasses = classNames('range-filter split-transaction-amount width-20-percent m-r-10 p-l-10 p-r-10');
  const inputDescriptionClasses = classNames('split-transaction-memo width-60-percent m-r-10 p-l-10 p-r-10');

  return (
    <div className="display-flex m-b-10">
      <input
        autoComplete="off"
        className={ inputNameClasses }
        type="text"
        value={ documentRequest.name }
        onChange={ handleNameChange }
      />
      <input
        disabled
        autoComplete="off"
        className={ inputNameClasses }
        type="text"
        value="Mailroom"
      />
      <input
        autoComplete="off"
        className={ inputDescriptionClasses }
        type="text"
        value={ documentRequest.description }
        onChange={ handleDescriptionChange }
      />
      <button
        type="submit"
        onClick={ onRemoveDocumentRequest }
      >
        <TrashcanIcon fontSize={ 18 } />
      </button>
    </div>
  );
};

export {
  IDocumentRequestItemProps,
  DocumentRequestItem as default,
};
