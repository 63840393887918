import React, { useMemo, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useBulkMoveDocumentsToTrash } from '@src/hooks/queries/documents';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteDocumentsModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

type UnassignedDocumentsBulkActionsProps = {
  selectedRecords: IRevenueServiceDocument[],
};

const BulkDeleteUnassignedDocumentsAction = ({
  selectedRecords,
}: UnassignedDocumentsBulkActionsProps) : JSX.Element => {
  const moveToTrash = useBulkMoveDocumentsToTrash();

  const documentIds = useMemo(() => {
    return selectedRecords
      .map((rsd) => rsd.document?.id)
      .filter((id): id is number => id !== undefined);
  }, [selectedRecords]);

  const isDisabled = documentIds.length === 0;

  const queryClient = useQueryClient();

  const bulkAction = useBulkActionModal({
    mutation: moveToTrash,
    onDone:   useCallback(() => {
      queryClient.invalidateQueries(QueryKey.revenueServiceDocuments);
      queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
    }, [queryClient]),
  });

  const confirm = useConfirmDeleteDocumentsModal({
    documentIds,
    onDone: useCallback(() => {
      bulkAction.runMutation(documentIds.map((documentId) => ({ documentIds: [documentId], value: true })));
    }, [bulkAction, documentIds]),
  });

  return (
    <>
      <confirm.Component
        { ...confirm.props }
        confirmTitle="Proceed"
        icon={ undefined }
        text={ (
          <div className="text-center">
            <p>
              Are you sure you want to delete
              <b>
                {' '}
                { documentIds.length }
              </b>
            </p>
            <p>
              revenue reports?
            </p>
          </div>
        ) }
        title="Remove Report"
      />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Revenue Report"
        title="Delete Revenues"
      />
      <ActionsDropdown.Action
        disabled={ isDisabled }
        icon={ <DeleteDocumentIcon fontSize={ 20 } /> }
        title="Delete"
        onClick={ !isDisabled ? confirm.open : undefined }
      />
    </>
  );
};

export default BulkDeleteUnassignedDocumentsAction;
