import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IEnvelopeIconProps extends Omit<IFaIconProps, 'icon'> {}

const EnvelopeIcon = (props: IEnvelopeIconProps): JSX.Element => {
  return <FaIcon icon="envelope" { ...props } />;
};

export default EnvelopeIcon;
