module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="service-header btn-group">\n  <div class="pull-left">\n    ');
    
      if (this.service_type === 'report') {
        __out.push('\n    <img src="');
        __out.push(__sanitize(configData.reports_images.FINANCIAL_REPORTS));
        __out.push('" class="service-icon-font"/>\n    ');
      } else if (this.service_type === Docyt.Common.Constants.SERVICE_TYPES['EXPENSE_REPORT_SERVICE']) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(configData.expense_images['EXPENSE_SERVICE']));
        __out.push('" class="service-icon-font">\n    ');
      } else if (this.service_type === Docyt.Common.Constants.SERVICE_TYPES['VENDOR_SERVICE']) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(configData.vendor_images['VENDOR_SERVICE']));
        __out.push('" class="service-icon-font">\n    ');
      } else if (this.service_type === Docyt.Common.Constants.SERVICE_TYPES['MAILROOM_SERVICE']) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(configData.business_inbox_images['INBOX_SERVICE']));
        __out.push('" class="service-icon-font"/>\n    ');
      } else {
        __out.push('\n    <span class="icon-');
        __out.push(__sanitize(this.service_icon));
        __out.push(' icon-title service-icon-font vertical-align-middle"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="business-service-dropdown-menu-wrapper">\n    <span class="service-header-title pointer dropdown-toggle" data-toggle="dropdown">');
    
      __out.push(__sanitize(this.service_name));
    
      __out.push('</span>\n    ');
    
      if (this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['RECONCILIATION_SERVICE'] && this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['BANKING_ACCOUNT_SERVICE'] && this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['REPORT_SERVICE'] && this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['AR_SERVICE'] && this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['VENDOR_SERVICE'] && this.service_type !== Docyt.Common.Constants.SERVICE_TYPES['OPEN_ITEMS']) {
        __out.push('\n    <p class="service-email-panel in-blue-900 font-12">\n      <a class="pointer copy-email-wrapper" data-clipboard-text="');
        __out.push(__sanitize(this.upload_email));
        __out.push('"><i class="icon icon-forwarding-email font-16 m-r-5"></i>\n       <span>');
        __out.push(__sanitize(this.upload_email));
        __out.push('</label>\n      </a>\n    </p>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}