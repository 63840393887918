import { IPostRevenueReconciliationParams } from '@src/requests/revenue_reconciliations';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { IRevenueReconciliationItemData } from '@src/types/revenue_reconciliations';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { NO_REPORT_ID } from '../../utils';

export const getPostReconciliationParams = (
  revenueSystem: IRevenueSystem,
  currentPaymentProcessor: IPaymentProcessor,
  revenues: IRevenueServiceDocument[],
  incomes: ITransactionServiceDocument[],
): IPostRevenueReconciliationParams => {
  const revenueReconciliationItems: IRevenueReconciliationItemData[] = [];
  revenues.forEach((revenue) => {
    if (revenue.id !== NO_REPORT_ID) {
      revenueReconciliationItems.push({
        id:   revenue.id,
        type: 'RevenueServiceDocument',
      });
    }
  });
  incomes.forEach((income) => {
    revenueReconciliationItems.push({
      id:   income.id,
      type: 'TransactionServiceDocument',
    });
  });
  return {
    revenueReconciliation: {
      revenueSystemId:     revenueSystem.id,
      paymentProcessorId:  currentPaymentProcessor.id,
      reconciliationItems: revenueReconciliationItems,
    },
  };
};
