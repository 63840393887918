module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('\\<td class="expandable-icon-column"></td>\n<td class="relative document-clickable pointer" colspan="2">\n  ');
    
      __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('\n</td>\n<td class="document-clickable pointer" colspan="2">\n  ');
    
      __out.push(__sanitize(this.from_account_name));
    
      __out.push('\n</td>\n<td class="document-clickable pointer" colspan="2">\n  ');
    
      __out.push(__sanitize(this.to_account_name));
    
      __out.push('\n</td>\n<td class="document-clickable pointer transaction-amount-column relative">\n  <span class="font-16 font-semi-bold m-r-10">');
    
      __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</span>\n  <span class="vertical-align-middle-important m-r-10" style="width:50px">\n    ');
    
      if (this.verified_by_user) {
        __out.push('\n        <span class="icon-verified-manually font-22" title="Verified manually"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      ');
      } else {
        __out.push('\n        <span class="icon-bot-notification-icon font-22" title="Verified automatically"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n      ');
      }
    
      __out.push('\n  </span>\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <a class="dropdown-item" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat">\n      <i class="fa fa-comment-o font-20 m-r-5"></i> <span>Send a Message</span>\n    </a>\n    ');
    
      if (this.business.get('can_edit_transaction_match')) {
        __out.push('\n      <a class="dropdown-item not-transfer-btn"><i class="fa fa-file-text-o font-20 m-r-5"></i> <span>Edit</span></a>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="check-mark-column"></td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}