import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import VendorsServiceProvider from '@src/components/providers/vendors_service_provider';
import Root from '@src/components/root';

import { DocytNetworkDetails } from './details';

interface IDocytNetworkPageProps {
  businessId: TID,
}

const DocytNetworkPage = ({
  businessId,
}: IDocytNetworkPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <VendorsServiceProvider businessId={ businessId }>
          <DocytNetworkDetails />
        </VendorsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default DocytNetworkPage;
