import { TDate, TUUID } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';
import { INoRevenueDate } from '@src/types/no_revenue_dates';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost } from './helpers';

interface ICreateNoRevenueDateParams {
  revenueSystemId: TUUID,
  date: TDate,
}

interface ICreateNoRevenueDateResponse {
  noRevenueDate: INoRevenueDate;
}

const createNoRevenueDate = (
  params: ICreateNoRevenueDateParams,
): Promise<ICreateNoRevenueDateResponse> => {
  return apiPost(
    `/api/v1/revenue_systems/${params.revenueSystemId}/no_revenue_dates`,
    underscoreKeys({ noRevenueDate: { date: params.date } }),
  ).then((data) => {
    return camelizeKeys(data) as ICreateNoRevenueDateResponse;
  });
};

interface IDeleteNoRevenueDateParams {
  revenueSystemId: TUUID,
  date: TDate,
}

interface IDeleteNoRevenueDateResponse {
  noRevenueDate: INoRevenueDate;
}

const deleteNoRevenueDate = (
  params: IDeleteNoRevenueDateParams,
): Promise<IDeleteNoRevenueDateResponse> => {
  return apiDelete(
    `/api/v1/revenue_systems/${params.revenueSystemId}/no_revenue_dates/${params.date}`,
    underscoreKeys({ noRevenueDate: { date: params.date } }),
  ).then((data) => {
    return camelizeKeys(data) as IDeleteNoRevenueDateResponse;
  });
};

interface IGetNoRevenueDateParams {
  revenueSystemId: TUUID,
  filter: IGetNoRevenueDateFilterParams | null,
}

interface IGetNoRevenueDateFilterParams {
  date: TDateFilter,
}
interface IGetNoRevenueDatesResponse {
  collection: INoRevenueDate[],
}

const getNoRevenueDates = (
  params: IGetNoRevenueDateParams,
): Promise<IGetNoRevenueDatesResponse> => {
  return apiGet(
    `/api/v1/revenue_systems/${params.revenueSystemId}/no_revenue_dates`,
    underscoreKeys(params),
  ).then((data) => {
    return {
      collection: camelizeKeys(data.no_revenue_dates) as INoRevenueDate[],
    };
  });
};

export {
  IGetNoRevenueDateParams,
  IGetNoRevenueDatesResponse,
  ICreateNoRevenueDateParams,
  ICreateNoRevenueDateResponse,
  IDeleteNoRevenueDateParams,
  IDeleteNoRevenueDateResponse,
  IGetNoRevenueDateFilterParams,
  getNoRevenueDates,
  createNoRevenueDate,
  deleteNoRevenueDate,
};
