import React from 'react';

import NumberFormat from 'react-number-format';

import { TRichAmount } from './types';
import { amountFormatterProps } from './utils';

interface IRichAmountValueFormatterProps {
  value?: TRichAmount,
}

const RichAmountValueFormatter = ({
  value,
}: IRichAmountValueFormatterProps) => {
  return (
    <NumberFormat
      { ...amountFormatterProps }
      value={ value?.val || '' }
    />
  );
};

export default React.memo(RichAmountValueFormatter);
