import Action from './action';
import ActionsDropdown from './actions_dropdown';
import Divider from './divider';
import LinkAction from './link_action';
import Submenu from './submenu';

export default Object.assign(ActionsDropdown, {
  Action,
  LinkAction,
  Divider,
  Submenu,
});
