import React, { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IGetTemplatesResponse } from '@src/requests/multi_entity_reports';

import Form from '@src/components/ui/form';

import { ICreateNewReportFormValues, createReportValidation } from './schema';

import styles from '../styles.module.scss';

interface ISelectReportTypeFormProps {
  formId: string,
  name: string,
  templateId: string,
  templates: IGetTemplatesResponse[],
  onSubmit: SubmitHandler<ICreateNewReportFormValues>,
}

const SelectReportTypeForm = ({
  formId,
  name,
  templateId,
  templates,
  onSubmit,
}: ISelectReportTypeFormProps) => {
  const templateOptions = useMemo(() => {
    return templates.map((template: IGetTemplatesResponse) => {
      const disabled = template.draft;
      return { value: template.id, label: template.name, disabled };
    });
  }, [templates]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateNewReportFormValues>({
    defaultValues: {
      name,
      templateId: templateId === '' ? templateOptions.find((option) => !option.disabled)?.value : templateId,
    },
    resolver: yupResolver(createReportValidation),
  });

  return (
    <Form className={ styles['height-500px-with-scroll'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Report Name"
        placeholder="Enter Name of Report"
        { ...register('name') }
      />
      <Form.RadioCollectionField
        vertical
        error={ errors.templateId?.message }
        label="Report Type"
        options={ templateOptions }
        { ...register('templateId') }
      />
    </Form>
  );
};

export default React.memo(SelectReportTypeForm);
