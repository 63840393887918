import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';

import ClearIndicator from '../clear_indicator';
import { TRichAmountType } from './types';

import styles from './styles.module.scss';

interface IRichAmountContainerProps {
  children: React.ReactNode,
  currentType: TRichAmountType,
  hasValue: boolean,
  onClear: () => void,
  onTypeSelected: (type: TRichAmountType) => void,
  isInvalid?: boolean
}

const RichAmountContainer = ({
  children,
  currentType,
  hasValue,
  onClear,
  onTypeSelected,
  isInvalid = false,
}: IRichAmountContainerProps) => {
  const handleAmountSelect = useCallback(() => {
    onTypeSelected('amount');
  }, [onTypeSelected]);

  const handleRangeSelect = useCallback(() => {
    onTypeSelected('range');
  }, [onTypeSelected]);

  const inputContainerClasses = classNames(
    styles['rich-amount-container'],
    {
      'no-value': !hasValue,
      'range':    currentType === 'range',
      'amount':   currentType === 'amount',
      'invalid':  isInvalid,
    },
  );

  return (
    <Dropdown.Provider className={ inputContainerClasses }>
      { children }
      {
        hasValue ? (
          <ClearIndicator onClick={ onClear } />
        ) : (
          <>
            <Dropdown.InputIndicator />
            <Dropdown.Menu>
              <Dropdown.Item
                isSelected={ currentType === 'amount' }
                title="Filter by Exact amount"
                onSelect={ handleAmountSelect }
              />
              <Dropdown.Item
                isSelected={ currentType === 'range' }
                title="Filter by Range"
                onSelect={ handleRangeSelect }
              />
            </Dropdown.Menu>
          </>
        )
      }
    </Dropdown.Provider>
  );
};

export default React.memo(RichAmountContainer);
