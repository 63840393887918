import React, { useMemo, forwardRef } from 'react';

import { useGetReconciliationPaymentAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';

import Form from '@src/components/ui/form';

const BlankOption = { name: '', value: '' };

interface IBankingAccountFieldProps {
  businessId: TID,
  error?: string,
  isArchived?: boolean,
  noConsiderArchive?: boolean,
}

const BankingAccountField = forwardRef<HTMLSelectElement, IBankingAccountFieldProps>(({
  businessId,
  error,
  isArchived = true,
  noConsiderArchive = false,
  ...props
}: IBankingAccountFieldProps, ref): JSX.Element => {
  const reconciliationAccountsQuery = useGetReconciliationPaymentAccounts({
    businessId,
    isArchived,
    noConsiderArchive,
  });

  const bankingAccountOptions = useMemo(() => {
    const reconciliationPaymentAccounts =
      reconciliationAccountsQuery.data?.reconciliationPaymentAccounts;

    if (!reconciliationPaymentAccounts) { return [BlankOption]; }
    return [BlankOption,
      ...reconciliationPaymentAccounts.map((recAccount) => ({
        name:  recAccount.name,
        value: String(recAccount.id),
      })),
    ];
  }, [reconciliationAccountsQuery.data?.reconciliationPaymentAccounts]);

  return (
    <Form.SelectField
      error={ error }
      { ...props }
      ref={ ref }
      label="Banking Account"
      options={ bankingAccountOptions }
      placeholder="Select Account"
    />
  );
});

BankingAccountField.displayName = 'BankingAccountField';

export default BankingAccountField;
