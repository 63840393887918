import React, { useCallback, useState } from 'react';

import Dropzone from 'react-dropzone';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUploadBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { NewDocumentIcon } from '@src/components/utils/img_icons';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IImportBudgetModalProps extends IUseModalProps {
  budget: IReportServiceBudget,
  error?: string,
  successUpload: (token: string) => void,
}

const allowedBudgetFileFormat = window.configData.allowed_budget_file_upload_format;

const ImportBudgetModal = ({
  budget,
  isOpen,
  onCancel,
  error,
  successUpload,
}: IImportBudgetModalProps) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
  const [progress, setProgress] = useState(0);

  const upload = useUploadBudget();
  const { mutate } = upload;

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setProgress(percentCompleted);
  };

  const handleFileSelected = useCallback((files) => {
    const file = files[0];

    if (!allowedBudgetFileFormat.includes(file.name.split('.').pop().toLowerCase())) {
      setErrorMsg(`File you uploaded is not supported. You can only upload in this format: ${allowedBudgetFileFormat.join(', ')}`);
      return;
    }

    mutate({
      file,
      onUploadProgress,
    }, {
      onSuccess: (data) => {
        onCancel();
        successUpload(data.token);
      },
    });
  }, [mutate, onCancel, successUpload]);

  return (
    <Modal
      className="modal-position-middle import-budget-modal"
      show={ isOpen }
      title={ `Import ${budget.type}` }
    >
      <Modal.Body>
        { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
        <div className="height-100-percent">
          {
            upload.isLoading ? (
              <div className={ styles['progress-status'] }>
                <p className="file-upload-status-text font-18 text-center in-black">
                  <span className="m-r-12">Importing...</span>
                  <span>
                    {progress}
                    %
                  </span>
                </p>
                <div className="progress-bar">
                  <div className="progress" style={ { width: `${progress}%` } } />
                </div>
              </div>
            ) : (
              <>
                {
                  error && (
                    <p className="in-red-400 main-input-hint upload-validation-error">
                      <WarningIcon mr={ 10 } />
                      {' '}
                      { error }
                    </p>
                  )
                }
                <Dropzone multiple={ false } onDrop={ handleFileSelected }>
                  {({ getRootProps, getInputProps }) => (
                    <section className="import-budget-upload-section">
                      <div className={ styles['drop-zone-section-details'] } { ...getRootProps() }>
                        <NewDocumentIcon height={ 72 } width={ 72 } />
                        <p className="m-t-20 font-16 p-t-20">{ `Drag and drop ${budget.type.toLowerCase()} here.` }</p>
                        <p className="m-t-10 m-b-10 font-16">OR</p>
                        <Button
                          bsStyle="blue-on-hover"
                          className="fileinput-button"
                          fontSize={ 16 }
                        >
                          Select from your computer
                          <input { ...getInputProps() } />
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </>
            )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="Cancel"
          onClick={ onCancel }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useImportBudgetModal = makeUseModal(ImportBudgetModal);

export {
  IImportBudgetModalProps,
  useImportBudgetModal,
  ImportBudgetModal as default,
};
