import React from 'react';

import { AccountsPayableServiceContext } from '@src/hooks/contexts/accounts_payable_service_context';
import { useGetAccountsPayableServiceByBusinessId } from '@src/hooks/queries/accounts_payable/accounts_payable_services';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IAccountsPayableServiceProviderProps {
  businessId: number,
  children: React.ReactNode,
  useLocalSpinner?: boolean,
}

const AccountsPayableServiceProvider = ({
  businessId,
  children,
  useLocalSpinner,
}: IAccountsPayableServiceProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetAccountsPayableServiceByBusinessId(businessId);

  if (isLoading) {
    return <Spinner localSpinnerId={ useLocalSpinner && 'detail-region' } />;
  }
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Accounts Payable Service loaded" />;

  return (
    <AccountsPayableServiceContext.Provider value={ data }>
      { children }
    </AccountsPayableServiceContext.Provider>
  );
};

export default AccountsPayableServiceProvider;
