import React from 'react';

import { useGetCurrentAdvisor } from '@src/hooks/queries/users';
import { capitalize } from '@src/utils/transform_keys';

import EditAction from './actions/edit_action';

const FinancialInsightsEmail = () => {
  const { data: info } = useGetCurrentAdvisor();

  return (
    <div className="display-flex align-items-center justify-content-between">
      <span className="notification-title-with-right-mark">
        <span className="font-semi-bold font-16">Financial Insights Email</span>
        {
          info?.advisor.financialInsightsEmailFrequency && (
            <span className="notification-mark">
              { capitalize(info?.advisor.financialInsightsEmailFrequency) }
            </span>
          )
        }
      </span>
      {
        info?.advisor.financialInsightsEmailFrequency && (
          <EditAction frequency={ info?.advisor.financialInsightsEmailFrequency } />
        )
      }
    </div>
  );
};

export default FinancialInsightsEmail;
