import React from 'react';

import LoginButton from '../login_button';

export interface LoginButtonProps {
  ssoLogin: (
    ssoToken: string,
    callBackFun: () => void,
    errorHandler: () => void
  ) => void;
}

const LoginButtonRoot = ({ ssoLogin }: LoginButtonProps) => {
  return (
    <LoginButton ssoLogin={ ssoLogin } />
  );
};

export default LoginButtonRoot;
