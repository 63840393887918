module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="integrations-');
    
      __out.push(__sanitize(this.externalSystem.get('name')));
    
      __out.push('">\n  <div class="business-item-wrapper display-table p-l-15 p-r-15 p-t-15 p-b-15 m-b-15 pointer in-black">\n    <div class="display-table-cell business-avatar-wrapper">\n      <span class=\'icon-business-default-avatar font-50\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n    </div>\n    <div class="display-table-cell vertical-align-middle p-l-30">\n        <span class="font-18">');
    
      __out.push(__sanitize(this.description));
    
      __out.push('</span>\n        ');
    
      if (this.configured) {
        __out.push('\n          ');
        if (this.isEnabled) {
          __out.push('\n            <span class="invoice-status-panel font-bold accepted"> Active </span>\n          ');
        } else {
          __out.push('\n            <span class="invoice-status-panel font-bold verifying"> Paused </span>\n          ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n    </div>\n    <div class="display-table-cell vertical-align-middle text-right dropdown">\n      <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="business-');
    
      __out.push(__sanitize(this.business.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class=\'icon icon-3-dots font-20\'></i>\n      </button>\n      ');
    
      if (this.configured) {
        __out.push('\n      <div class="business-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="business-');
        __out.push(__sanitize(this.business.id));
        __out.push('-action-dropdown-btn">\n        <a href="/settings/integrations/');
        __out.push(__sanitize(this.business.id));
        __out.push('/integrations/');
        __out.push(__sanitize(this.integration.id));
        __out.push('" class="dropdown-item font-16">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">View History</span>\n        </a>\n        <a class="integration dropdown-item">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">Configure</span>\n        </a>\n        ');
        if (!this.isEnabled) {
          __out.push('\n        <a class="dropdown-item activate-integration">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">Activate</span>\n        </a>\n        ');
        } else {
          __out.push('\n        <a class="pause-integration dropdown-item">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">Pause</span>\n        </a>\n        ');
        }
        __out.push('\n        <a class="restart-runs dropdown-item">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">Reset</span>\n        </a>\n        <a class="dropdown-item disconnect-integration">\n          <i class="icon icon-settings-signout font-18"></i>\n          <span class="item-text">Disconnect</span>\n        </a>\n      </div>\n      ');
      } else {
        __out.push('\n      <div class="business-action-dropdown dropdown-menu dropdown-menu-right qbo-connect-large" aria-labelledby="business-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n        <a class="integration dropdown-item">\n          <i class="icon icon-service font-18"></i>\n          <span class="item-text">Configure</span>\n        </a>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}