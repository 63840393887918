module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="header-container">\n    <h3 class="font-18 font-semi-bold in-black">');
    
      __out.push(__sanitize(this.headerTitle));
    
      __out.push('</h3>\n    <a id="close-employee-account"><span class="pointer icon-not-a-chargeback-icon font-20 in-black"></span></a>\n</div>\n<div class="content-container">\n');
    
      if (this.viewType !== 'add') {
        __out.push('\n');
        this.first_name = this.dwollaCustomer.get('first_name');
        __out.push('\n');
        this.last_name = this.dwollaCustomer.get('last_name');
        __out.push('\n');
        this.email = this.dwollaCustomer.get('email');
        __out.push('\n');
        this.last4 = this.dwollaFundingSource.get('last4');
        __out.push('\n');
        this.routing_number = this.dwollaFundingSource.get('routing_number');
        __out.push('\n');
        this.is_dwolla_terms_accepted = this.dwollaCustomer.get('is_dwolla_terms_accepted');
        __out.push('\n');
      }
    
      __out.push('\n');
    
      if (this.viewType === 'add' || this.viewType === 'edit') {
        __out.push('\n<div class="category-content-region">\n    <div class="form-group relative">\n        <label class="form-label">Employee Name</label>\n        <input class="form-control input-first-name-js" placeholder="First Name" value="');
        __out.push(__sanitize(this.first_name));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> First Name is required.</p>\n    <div class="form-group relative">\n        <label class="form-label"></label>\n        <input class="form-control input-last-name-js" placeholder="Last Name" value="');
        __out.push(__sanitize(this.last_name));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> Last Name is required.</p>\n    <div class="form-group relative">\n        <label class="form-label">Email Address</label>\n        <input class="form-control input-email-js" placeholder="Enter Employee’s Email Address" value="');
        __out.push(__sanitize(this.email));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> Email Address is required.</p>\n    <div class="form-group relative">\n        <label class="form-label">Account Number</label>\n        <input class="form-control input-account-js" type="password" placeholder="Enter Account Number">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> Account Number is required.</p>\n    <div class="form-group relative">\n        <label class="form-label">Re-enter Account Number</label>\n        <input class="form-control input-account-confirmation-js" type="password" placeholder="Enter Account Number">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> Account Numbers don’t match.</p>\n    <div class="form-group relative">\n        <label class="form-label">Routing Number</label>\n        <input class="form-control input-routing-js" placeholder="Enter Banks Routing Number" value="');
        __out.push(__sanitize(this.routing_number));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142"><i class="fa fa-warning"></i> Routing Number is required.</p>\n    <div class="form-group relative">\n        <div class="checkbox m-t-0 m-b-0">\n            <input type="checkbox" class="styled form-control pointer input-element confirm-authorization-js" data-type="checkbox" ');
        __out.push(__sanitize(this.is_dwolla_terms_accepted ? 'checked' : ''));
        __out.push('>\n            <label>\n              <p>By checking this box, you confirm you have received written authorization from this employee to:</p>\n              <p>•  Collect, use, and share the user\'s personal identifying and banking account information with Docyt, Inc.</p>\n              <p>•  Originate transfers to this bank account.</p>\n              <p>You must be able to provide a copy of this authorization to Docyt, Inc. at any time upon request.</p>\n            </label>\n        </div>\n    </div>\n    <hr />\n    <label>\n        <p>Important: Adding or editing the ACH information for an employee will also change the employee\'s ACH information for all other businesses that he is part of.</p>\n    </label>\n</div>\n');
      } else {
        __out.push('\n<div class="category-content-region">\n    <div class="form-group relative">\n        <label class="form-label">Employee Name</label>\n        <label class="form-label">\n            ');
        __out.push(__sanitize(this.first_name ? this.first_name + ' ' + this.last_name : this.last_name));
        __out.push('\n        </label>\n    </div>\n    <div class="form-group relative">\n        <label class="form-label">Email Address</label>\n        <label class="form-label">');
        __out.push(__sanitize(this.email));
        __out.push('</label>\n    </div>\n    <div class="form-group relative">\n        <label class="form-label">Account Number</label>\n        <label class="form-label">');
        __out.push(__sanitize(this.last4 ? "**** " + this.last4 : '-'));
        __out.push('</label>\n    </div>\n    <div class="form-group relative">\n        <label class="form-label">Routing Number</label>\n        <label class="form-label">');
        __out.push(__sanitize(this.routing_number));
        __out.push('</label>\n    </div>\n</div>\n');
      }
    
      __out.push('\n');
    
      if (this.viewType !== 'view') {
        __out.push('\n<div class="footer-action-container">\n    <button  class="btn btn-action  ');
        __out.push(__sanitize(this.viewType === 'add' || this.is_dwolla_terms_accepted === false ? 'disabled' : ''));
        __out.push('">');
        __out.push(__sanitize(this.buttonTitle));
        __out.push('</button>\n</div>\n');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}