import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IBankStatementsFilter } from './types';

interface IPendingReviewFilterProps {
  filter: IFilter<IBankStatementsFilter>;
}

const PendingReviewFilter = ({
  filter,
}: IPendingReviewFilterProps) => {
  return (
    <Filter<IBankStatementsFilter> filter={ filter }>
      <Filter.TextField
        name="name"
        placeholder="Name"
        widthPercent={ 15 }
      />
    </Filter>
  );
};

export default React.memo(PendingReviewFilter);
