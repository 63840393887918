import React, { useCallback } from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { CancelPaymentIcon } from '@src/components/utils/icomoon';

type TCancelAchPaymentActionProps = {
  document: IAchTransaction,
}

const CancelAchPaymentAction = ({
  document,
}: TCancelAchPaymentActionProps) => {
  const handleCancelAchPayment = useCallback(() => {
    if (document.serviceDocumentIds.length > 0) {
      const documentId = document.serviceDocumentIds[0];
      window.Docyt.vent.trigger('ap_service:document:cancel:pay', documentId, 'ach_transactions');
    }
  }, [document.serviceDocumentIds]);

  return (
    <ActionsDropdown.Action
      danger
      icon={ <CancelPaymentIcon fontSize={ 26 } /> }
      title="Cancel ACH Payment"
      onSelect={ handleCancelAchPayment }
    />
  );
};

export default CancelAchPaymentAction;
