module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="rule-column-js">\n  <div class="display-flex align-items-center">\n    ');
    
      if (this.vendor_avatar_url) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(this.vendor_avatar_url));
        __out.push('" class="payment-rule-vendor-img m-r-20">\n    ');
      } else {
        __out.push('\n    <i class="icon icon-smile in-orange-500 font-25"></i>\n    ');
      }
    
      __out.push('\n    <p class="font-15 font-semi-bold m-b-0">');
    
      __out.push(__sanitize(this.vendor.name));
    
      __out.push('</p>\n  </div>\n</td>\n<td class="rule-column-js">\n  <div class="display-flex align-items-center">\n    ');
    
      if (this.type === 'recurring') {
        __out.push('\n    <i class="icon icon-restore-flip font-23 payment-rule-img m-r-12"></i>\n    ');
      } else {
        __out.push('\n    <i class="icon icon-plane-outline font-23 payment-rule-img m-r-12"></i>\n    ');
      }
    
      __out.push('\n    <p class="font-14 inline-block m-b-0">\n        ');
    
      if (this.type === 'recurring') {
        __out.push('\n        <span class="text-capital">');
        __out.push(__sanitize(Docyt.Utils.getFrequencyPeriod(this.frequency)));
        __out.push('</span>\n        ');
      } else {
        __out.push('\n        Mark as Paid\n        ');
      }
    
      __out.push('\n      </p>\n  </div>\n</td>\n<td class="rule-column-js">\n  <div class="font-14">\n    ');
    
      if (this.type === 'recurring') {
        __out.push('\n    ');
        __out.push(__sanitize(this.delivery_date));
        __out.push('\n    ');
      } else {
        __out.push('\n    ----\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="rule-column-js">\n    <p class="font-15 font-bold m-b-0">');
    
      __out.push(__sanitize(this.rulePaymentAccount));
    
      __out.push('</p>\n</td>\n<td class="text-right rule-column-js">\n    <p class="font-15 font-bold m-b-0">');
    
      __out.push(__sanitize(this.ruleAmount));
    
      __out.push('</p>\n</td>\n<td class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <a class="dropdown-item edit-rule-js pointer display-flex">\n      <i class="fa fa-edit font-20"></i>\n      <span class="font-14 m-l-5">Edit Payment Rule</span>\n    </a>\n    <a class="dropdown-item delete-rule-js in-red-400 pointer display-flex">\n      <i class="icon icon-new-trash font-18"></i>\n      <span class="font-14 m-l-5">Delete Payment Rule</span>\n    </a>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}