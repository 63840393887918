import React from 'react';

import { useRecoilState } from 'recoil';

import Filter from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import styles from '@src/components/business_reports/custom_reports/styles.module.scss'

const MultiEntityItemAccountValueListTableFilter = () => {
  const [value, setValue] = useRecoilState(hideZeroRowsType);

  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div />
      <div className={ styles['filter-area'] }>
        <div className="p-t-10 p-b-10">
          <CheckboxInput
            checked={ value }
            title="Hide empty rows"
            onChange={ (checked: boolean) => setValue(checked) }
          />
        </div>
      </div>
    </Filter.TableContainer>
  );
};

export default React.memo(MultiEntityItemAccountValueListTableFilter);
