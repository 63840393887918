import React from 'react';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import AddBudgetReportAction from './add_budget_action';

interface IBudgetsHeaderProps {
  existBudget: boolean
}

const BudgetsHeader = ({
  existBudget,
}: IBudgetsHeaderProps) => {
  return (
    <DetailsRegion.Header
      divider="horizontal"
      title="Budget and Forecast"
    >
      { existBudget && (<AddBudgetReportAction />) }
    </DetailsRegion.Header>
  );
};

export default BudgetsHeader;
