import React, { forwardRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import TextInput from '../inputs/text_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface ITextFieldProps extends
  React.ComponentPropsWithoutRef<'input'>,
  IUIStyleProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
  wrapperClassName?: string,
}

/**
 * Simple text input
 *
 * @visibleName Form.TextField
 */
const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(({
  id,
  defaultValue,
  error,
  hideLabel,
  hint,
  label,
  mark,
  wrapperClassName,
  ...props
}: ITextFieldProps, ref): JSX.Element => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  return (
    <Wrapper
      className={ wrapperClassName }
      error={ error }
      hideLabel={ hideLabel }
      hint={ hint }
      label={ label }
      mark={ mark }
    >
      <TextInput
        ref={ ref }
        className={ classes }
        defaultValue={ defaultValue }
        id={ id }
        { ...inputProps }
      />
    </Wrapper>
  );
});

TextField.displayName = 'TextField';

export default TextField;
