import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ICommentIconProps extends Omit<IFaIconProps, 'icon'> {}

const CommentIcon = (props: ICommentIconProps): JSX.Element => {
  return <FaIcon icon="comment-o" { ...props } />;
};

export default CommentIcon;
