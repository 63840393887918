module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n  <h1 class="font-40 font-bold m-b-40 m-t-40 text-center">Enter Account Details</h1>\n  <div class="background-white row">\n      <div class="row m-b-50 add-credentials-wrapper">\n        <div class="login-form__fieldset m-t-50 relative">\n          <div class="text-center m-b-30 add-photo-wrapper ');
    
      __out.push(__sanitize(this.avatarUrl ? 'avatar-exist' : ''));
    
      __out.push('">\n            <img class="personal-logo" src="');
    
      __out.push(__sanitize(this.avatarUrl));
    
      __out.push('">\n            <button class="font-bold fileinput-button">\n              Add Your Photo\n              <input type="file" name="files[]" class="logo-file-input">\n            </button>\n            <p class="main-input__hint in-red-400 logo-invalid">\n              File format is not supported.\n            </p>\n          </div>\n          <div class="main-input__wrap">\n            <div class="form-group-wrapper">\n              <label class="font-14 font-bold m-r-20 text-right">Your Full Name</label>\n              <input class="main-input name-input" type="text" placeholder="First Middle Last" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n            </div>\n            <p class="main-input__hint in-red-400 name-invalid">\n              Your name is required\n            </p>\n          </div>\n          <div class="main-input__wrap">\n            <div class="form-group-wrapper">\n              <label class="font-14 font-bold m-r-20 text-right">Account Username(Email)</label>\n              <input class="main-input email-input" type="email" placeholder="Email" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n            </div>\n            <p class="main-input__hint in-red-400 email-invalid">\n              Please enter correct email\n            </p>\n            <p class="main-input__hint in-red-400 email-exists">\n              This email is already in use\n            </p>\n          </div>\n          <div class="main-input__wrap m-t-50">\n            <div class="form-group-wrapper">\n              <label class="font-14 font-bold m-r-20 text-right">Create Web Password</label>\n              <input class="main-input password-input" type="password" placeholder="Password" value="');
    
      __out.push(__sanitize(this.password));
    
      __out.push('">\n            </div>\n            <p class="main-input__hint in-red-400 password-too-short">\n              Password too short\n            </p>\n          </div>\n          <div class="main-input__wrap">\n            <div class="form-group-wrapper">\n              <label class="font-14 font-bold m-r-20 text-right">Confirm Password</label>\n              <input class="main-input password-confirmation-input" type="password" placeholder="Confirm Password" value="');
    
      __out.push(__sanitize(this.password_confirmation));
    
      __out.push('">\n            </div>\n            <p class="main-input__hint in-red-400 password-dont-match">\n              Password doesn\'t match\n            </p>\n          </div>\n        </div>\n        <div class="text-center">\n          <button type=\'submit\' class="btn btn-primary ladda-button submit-sign-up-js" data-color="blue" data-style="expand-right"><span class="ladda-label">Continue</span></button>\n        </div>\n      </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}