module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table expense-unreconcil-row-table m-b-0" style="table-layout: fixed;">\n  <colgroup span="');
    
      __out.push(__sanitize(this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] ? '8' : '9'));
    
      __out.push('">\n    <col width="30"></col>\n    <col width="30"></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col width="100"></col>\n    ');
    
      if (this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
        __out.push('\n      <col width="25"></col>\n    ');
      }
    
      __out.push('\n    <col width="50"></col>\n    <col width="50"></col>\n  </colgroup>\n  <thead>\n    <tr>\n      <th></th>\n      <th></th>\n      <th class="pointer relative sortable ');
    
      __out.push(__sanitize(this.order_column === 'vendor_id' && this.order_direction === 'desc' ? 'sorted-desc' : this.order_column === 'vendor_id' && this.order_direction === 'asc' ? 'sorted-asc' : void 0));
    
      __out.push('" data-orderby="vendor_id" data-orderdir="ASC">Vendor</th>\n      <th class="pointer relative sortable ');
    
      __out.push(__sanitize(this.order_column === 'transaction_date' && this.order_direction === 'desc' ? 'sorted-desc' : this.order_column === 'transaction_date' && this.order_direction === 'asc' ? 'sorted-asc' : void 0));
    
      __out.push('" data-orderby="transaction_date" data-orderdir="ASC">');
    
      if (this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE']) {
        __out.push(__sanitize('Payment '));
      }
    
      __out.push('Date</th>\n      <th class="pointer relative sortable ');
    
      __out.push(__sanitize(this.order_column === 'payment_account' && this.order_direction === 'desc' ? 'sorted-desc' : this.order_column === 'payment_account' && this.order_direction === 'asc' ? 'sorted-asc' : void 0));
    
      __out.push('" data-orderby="payment_account" data-orderdir="ASC">Account</th>\n      <th class="pointer relative sortable ');
    
      __out.push(__sanitize(this.order_column === 'amount' && this.order_direction === 'desc' ? 'sorted-desc' : this.order_column === 'amount' && this.order_direction === 'asc' ? 'sorted-asc' : void 0));
    
      __out.push('" data-orderby="amount" data-orderdir="ASC">Amount</th>\n      ');
    
      if (this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
        __out.push('\n        <th></th>\n      ');
      }
    
      __out.push('\n      <th></th>\n      <th></th>\n    </tr>\n  </thead>\n</table>\n<div class="expense-unreconcile-row-table-body">\n  <table class="table m-b-0" style="table-layout: fixed;">\n    <colgroup span="');
    
      __out.push(__sanitize(this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] ? '8' : '9'));
    
      __out.push('">\n      <col width="30"></col>\n      <col width="30"></col>\n      <col></col>\n      <col></col>\n      <col></col>\n      <col width="100"></col>\n      ');
    
      if (this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
        __out.push('\n        <col width="25"></col>\n      ');
      }
    
      __out.push('\n      <col width="50"></col>\n      <col width="50"></col>\n    </colgroup>\n    <tbody>\n    </tbody>\n  </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}