import React, { useCallback } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReportPath } from '@src/routes';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { Button } from '@src/components/ui_v2/buttons';

interface IVerifyRevenueReportActionProps {
  report: IRevenueServiceDocument;
}

const VerifyRevenueReportAction = ({
  report,
}: IVerifyRevenueReportActionProps) => {
  const revenueService = useRevenueServiceContext();

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    Backbone.history.navigate(
      revenueServiceReportPath(revenueService.businessId, report.id),
      { trigger: true },
    );
  }, [revenueService.businessId, report.id]);

  return (
    <Button variant="ghost" onClick={ handleClick }>
      Verify
    </Button>
  );
};

export default VerifyRevenueReportAction;
