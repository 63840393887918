import React, { useCallback } from 'react';

import classNames from 'classnames';
import { ControllerRenderProps } from 'react-hook-form';

import { useUniqueDomId } from '@src/hooks/dom';
import { IBusiness } from '@src/types/businesses';

import { IBusinessesInput } from './schema';

interface ISelectBusinessCheckboxProps
{
  business: IBusiness,
  field: ControllerRenderProps<IBusinessesInput, 'businesses'>,
  disabled: boolean,
}

const SelectBusinessCheckbox = ({
  business,
  field: { value, onChange },
  disabled,
}: ISelectBusinessCheckboxProps): JSX.Element => {
  const inputId = useUniqueDomId('business_selector_');

  const handleChange = useCallback((e) => {
    if (!e.target.checked) {
      const index = value.map((item) => item.id).indexOf(business.id);
      value.splice(index, 1);
      onChange(value);
    } else {
      onChange(value.concat([business]));
    }
  }, [value, business, onChange]);

  const isChecked = () => {
    return value.filter((item) => item.id === business.id).length !== 0;
  };

  return (
    <div className="checkbox checkbox-primary">
      <input
        checked={ isChecked() }
        className="pointer"
        disabled={ disabled }
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
      />
      <label
        aria-label="Select"
        className={ classNames({ 'font-semi-bold': isChecked() }) }
        htmlFor={ inputId }
      >
        { business.name }
      </label>
    </div>
  );
};

export default SelectBusinessCheckbox;
