import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useAddMetricsModal } from '../add_metric';

import styles from '../../../styles.module.scss';

const AddMetricAction = () => {
  const addMetric = useAddMetricsModal();

  return (
    <>
      <addMetric.Component
        { ...addMetric.props }
      />
      <Button
        className={ styles['add-metric-btn'] }
        fontSize={ 20 }
        ml={ 5 }
        onClick={ addMetric.open }
      >
        <PlusIcon className="p-r-10" variant="o" />
        Add New Metrics
      </Button>
    </>
  );
};

export default AddMetricAction;
