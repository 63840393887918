module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      this.disabled_view = moment(this.transaction_date) < moment(this.business.get('reconciliation_start_date'), 'YYYY-MM-DD') ? 'disabled' : '';
    
      __out.push('\n<button class="btn dropdown-toggle ');
    
      __out.push(__sanitize(this.button_text ? 'transaction-amount-button' : 'header-icon-button'));
    
      __out.push(' font-15 font-bold ');
    
      __out.push(__sanitize(this.button_class));
    
      __out.push('" type="button" id="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  ');
    
      if (this.button_text) {
        __out.push('\n    ');
        __out.push(__sanitize(this.button_text));
        __out.push('\n    <span class="fa fa-angle-down m-l-5"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-more-icon font-22"></span>\n  ');
      }
    
      __out.push('\n</button>\n');
    
      if (this.docytUser || (!this.no_document_required && !this.has_documents) || this.state === 'verified') {
        __out.push('\n<div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="transaction-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n  ');
        if (this.docytUser) {
          __out.push('\n  <button class="dropdown-item font-14 report-transaction-btn" type="button">\n    <i class="fa fa-wrench"></i> <span>Report an Issue</span>\n  </button>\n  ');
        }
        __out.push('\n  ');
        if (!(this.no_document_required || this.has_documents)) {
          __out.push('\n  <li><a tabindex="-1" class="pointer no-document-required ');
          __out.push(__sanitize(this.disabled_view));
          __out.push('"><span class="icon-mark-as-matched vertical-align-middle font-18 m-r-22"></span>No document needed</a></li>\n  ');
        }
        __out.push('\n  ');
        if (this.state === 'verified') {
          __out.push('\n    <button class="dropdown-item font-14 edit-transaction-btn ');
          __out.push(__sanitize(this.disabled_view));
          __out.push('" type="button">\n      <i class="fa fa-pencil-square-o"></i> <span>Uncategorize Transaction</span>\n    </button>\n    ');
          if (this.hasQboError) {
            __out.push('\n      <button class="dropdown-item font-14 re-sync-btn pointer ');
            __out.push(__sanitize(this.disabled_view));
            __out.push('" type="button">\n        <i class="icon-qbo-on font-25"></i><span>Re-sync</span>\n      </button>\n    ');
          }
          __out.push('\n  ');
        }
        __out.push('\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}