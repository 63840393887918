import React from 'react';

import { IManagementGroup } from '@src/types/management_groups';

import { useEditManagementGroupModal } from '@src/components/management_groups/modals/edit_management_group_modal';
import ActionsDropdown from '@src/components/ui/actions_dropdown/actions_dropdown';

interface IEditManagementGroupActionProps {
  managementGroup: IManagementGroup,
}

const EditManagementGroupAction = ({
  managementGroup,
}: IEditManagementGroupActionProps) => {
  const editManagementGroup = useEditManagementGroupModal();

  return (
    <>
      <editManagementGroup.Component
        { ...editManagementGroup.props }
        managementGroup={ managementGroup }
      />
      <ActionsDropdown.Action
        name="Edit"
        onClick={ editManagementGroup.open }
      />
    </>
  );
};

export default EditManagementGroupAction;
