import '@vendor_js/mixpanel-jslib-snippet';

const initMixPanel = (mixPanelToken?: string): Promise<void> => {
  return new Promise((resolve) => {
    if (!mixPanelToken) {
      // eslint-disable-next-line no-console
      console.error('No mixpanel token provided. MixPanel is not initialized!');
      resolve();
    }

    window.mixpanel.init(mixPanelToken);
    resolve();
  });
};

export { initMixPanel };
