import React, { useCallback } from 'react';

import { IBusiness } from '@src/types/businesses';
import { TServiceType } from '@src/types/services';

import Modal from '@src/components/ui/modal';

import Form, { IForwardDocumentFormValues } from './forward_document_form';

interface IForwardDocumentModalProps {
  business: IBusiness,
  currentServiceType: TServiceType,
  show?: boolean,
  onCancel: () => void,
  onContinue: (data: IForwardDocumentFormValues) => void,
}

const ForwardDocumentModal = ({
  business,
  currentServiceType,
  show,
  onCancel,
  onContinue,
}: IForwardDocumentModalProps): JSX.Element => {
  const handleSubmit = useCallback((data: IForwardDocumentFormValues) => {
    onContinue(data);
  }, [onContinue]);

  return (
    <Modal.Form
      dialogClassName="forward-document-modal"
      proceedTitle="Continue"
      show={ show }
      title="Forward Document"
      onCancel={ onCancel }
    >
      { ({ formId }) => (
        <Form
          business={ business }
          currentServiceType={ currentServiceType }
          formId={ formId }
          onSubmit={ handleSubmit }
        />
      ) }
    </Modal.Form>
  );
};

export {
  IForwardDocumentFormValues,
  ForwardDocumentModal as default,
};
