module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, keyword, len, ref;
    
      if (this.document_confirm_keywords.length > 0) {
        __out.push('\n<div class="">\n    <button class="btn ladda-button" id="rest_confirm_keywords_btn" data-color="blue" data-style="zoom-in"><span class="ladda-label">Reset</span></button>\n    <button class="btn btn-info" id="add_confirm_keywords_btn">+</button>\n</div>\n');
        ref = this.document_confirm_keywords;
        for (i = 0, len = ref.length; i < len; i++) {
          keyword = ref[i];
          __out.push('\n    <div class="m-t-10">\n        <input class="col-sm-10 document-confirmation-keyword padding-2" data-id="');
          __out.push(__sanitize(keyword.id));
          __out.push('" value="');
          __out.push(__sanitize(keyword.words.join(',')));
          __out.push('">\n        <button class="btn-danger keyword-remove-btn pointer m-l-10" data-id="');
          __out.push(__sanitize(keyword.id));
          __out.push('"><i class="fa fa-close"></i></button>\n    </div>\n');
        }
        __out.push('\n');
      } else {
        __out.push('\n<div class="">\n    <button class="btn ladda-button" id="load_confirm_keywords_btn" data-color="blue" data-style="zoom-in"><span class="ladda-label">Load</span></button>\n    <button class="btn btn-info" id="add_confirm_keywords_btn">+</button>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}