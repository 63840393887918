import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import ForwardToBalanceSheetDocument from './forward_to_balance_sheet_document';
import ForwardToBankStatement from './forward_to_bank_statement';

interface IForwardDocumentPageProps {
  businessId: TID,
  documentIds: TID[],
  forwardTo: string,
  onCancel: () => void,
  onContinue: () => void,
}
const ForwardDocumentPage = ({
  businessId,
  documentIds,
  forwardTo,
  onCancel,
  onContinue,
}: IForwardDocumentPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ForwardToBankStatement
          documentIds={ documentIds }
          show={ forwardTo === 'BankStatement' }
          onCancel={ onCancel }
          onContinue={ onContinue }
        />
        <ForwardToBalanceSheetDocument
          documentIds={ documentIds }
          show={ forwardTo === 'BalanceSheetDocument' }
          onCancel={ onCancel }
          onContinue={ onContinue }
        />
      </BusinessProvider>
    </Root>
  );
};
export default ForwardDocumentPage;
