import React from 'react';

import { TID, TYear } from '@src/types/common';

import ReviewedDetails from '@src/components/banking_accounts/bank_statements/reviewed/reviewed_details/reviewed_details';
import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

interface IBankStatementsDetailsPageProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  year: TYear,
}

const BankStatementsDetailsPage = ({
  businessId,
  rcPaymentAccountId,
  year,
}: IBankStatementsDetailsPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          <ReviewedDetails
            businessId={ businessId }
            rcPaymentAccountId={ rcPaymentAccountId }
            year={ year }
          />
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BankStatementsDetailsPage;
