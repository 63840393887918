module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="p-b-50 text-center dashboard-clients-container">\n  <div class="m-t-20">\n    ');
    
      if (this.individual_clients.length > 0) {
        __out.push('\n      <div class="display-table-row header-row pointer">\n        <div class="dashboard-table-column width-10-percent"></div>\n        <div class="dashboard-table-column width-40-percent">Name</div>\n        <div class="dashboard-table-column width-25-percent">Documents</div>\n        <div class="dashboard-table-column width-25-percent">Messages</div>\n      </div>\n      <div class="display-table width-100-percent text-left child-container">\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="text-center">\n        <img src="');
        __out.push(__sanitize(configData.images['user_management_logo']));
        __out.push('" class="width-150px m-t-30">\n        <h1 class="font-28 font-bold">Add Some Clients</h1>\n        <h2 class="font-20">Get 2 months free trial and then its only $100 per month</h2>\n      </div>\n      <div class="child-container hidden"></div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}