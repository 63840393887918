module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive invoices-table-wrapper">\n    <table class="table">\n        <thead>\n        <tr>\n            <th style="width: 30px;">\n                <div class="checkbox checkbox-primary">\n                    <input type="checkbox" class="pointer checked-all-invoices" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n                    <label></label>\n                </div>\n            </th>\n            <th class="sortable font-13 font-bold width-30-percent" data-orderby="merchant" data-orderdir="ASC">Payee</th>\n            <th class="sortable font-13 font-bold width-30-percent" data-orderby="bill_date" data-orderdir="ASC">Bill Date</th>\n            <th class="sortable font-13 font-bold width-20-percent" data-orderby="state" data-orderdir="ASC">Status</th>\n            <th class="sortable font-13 font-bold width-15-percent" data-orderby="amount" data-orderdir="ASC">Amount</th>\n        </tr>\n        </thead>\n        <tbody>\n        </tbody>\n    </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}