/* eslint-disable indent */
import React from 'react';

import { AiChatBotContext } from '../context/ai_chat_context';
import useChatProvider from '../hooks/use_chat_provider';

interface IAiChatBotProviderProps {
  children: React.ReactNode,
}

const AiChatBotProvider = ({
  children,
}: IAiChatBotProviderProps): JSX.Element | null => {
  const data = useChatProvider();
  return (
    <AiChatBotContext.Provider value={ data }>
      { children }
    </AiChatBotContext.Provider>
  );
};

export default AiChatBotProvider;
