import React, { ComponentProps, ComponentType, useState } from 'react';

import Drawer from 'react-modern-drawer';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { useClearFilter } from '@src/components/ui_v2/filter';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';
import DrawerUpIcon from '@src/components/utils/icomoon/drawer-up';
import Section from '@src/components/utils_v2/section';

import 'react-modern-drawer/dist/index.css';

import styles from './styles.module.scss';

interface Options {
  title?: string;
  sectionName: string;
}

const inDrawer = <T extends ComponentType<any>>(
  Component: T,
  Control: T,
  options: Options,
) => {
  type Props = ComponentProps<T>

  const EnhancedComponent = (props: Props) => {
    const business = useBusinessContext();
    const section = { businessId: business.id, section: options.sectionName };
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const clearFilter = useClearFilter(section);

    const toggleDrawer = () => {
      clearFilter();
      setIsOpen((prevState) => !prevState);
    };

    const onClose = () => {
      clearFilter();
      setIsOpen(false);
    };

    return (
      <>
        <DrawerUpIcon fontSize={ 22 } inColor="grey-500" onClick={ toggleDrawer } />

        <Drawer
          lockBackgroundScroll
          direction="bottom"
          open={ isOpen }
          size="80vh"
          onClose={ onClose }
        >
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div className={ styles['header-col'] } />

              <div className={ styles['header-col'] }>
                <div className={ styles['header-title'] }>
                  <span>{options.title}</span>
                </div>
              </div>

              <div className={ styles['header-col'] }>
                <div className={ styles['header-controls'] }>
                  <Section.Provider section={ section }>
                    <Control { ...props } />
                  </Section.Provider>
                  <NotAChargebackIcon pointer fontSize={ 20 } onClick={ onClose } />
                </div>
              </div>
            </div>

            <Section.Provider section={ section }>
              {
                // @ts-ignore
                isOpen && <Component { ...props } onClose={ onClose } />
              }
            </Section.Provider>
          </div>
        </Drawer>
      </>
    );
  };

  return EnhancedComponent;
};

export default inDrawer;
