import React, { ComponentPropsWithoutRef } from 'react';

import { dataExportPath } from '@src/routes';

import Alert from '@src/components/ui_v2/alerts';
import { DataExportInProgressIcon } from '@src/components/utils/icomoon';

type TExportType = 'business_report' | 'printable_checks';

interface IExportExportBannerProps extends Omit<ComponentPropsWithoutRef<typeof Alert>, 'children' | 'variant'> {
  type?: TExportType;
}

const ExportReportBanner = ({
  type = 'business_report',
  ...props
}: IExportExportBannerProps): JSX.Element | null => {
  let itemName: string;

  switch (type) {
    case 'business_report':
      itemName = 'report';
      break;
    case 'printable_checks':
      itemName = 'file';
      break;
    default:
      return null; // wrong type
  }

  return (
    <Alert
      { ...props }
      icon={ <DataExportInProgressIcon relative fontSize={ 18 } mr={ 8 } style={ { top: '3px' } } /> }
      variant="info"
    >
      <span>
        We’ve started preparing your
        {' '}
        { itemName }
        . It can take a few seconds to get it done.
        Once
        {' '}
        { itemName }
        {' '}
        is ready and available, it will start showing up in the
        {' '}
        <a href={ dataExportPath() }>Data Export</a>
        {' '}
        section. We will also send you an automated email.
      </span>
    </Alert>
  );
};

export default ExportReportBanner;
