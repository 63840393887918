import React from 'react';

import ChartOfAccountInput from '@src/components/common_v2/inputs/chart_of_account_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IChartOfAccountFieldProps extends
  React.ComponentPropsWithoutRef<typeof ChartOfAccountInput>, IWrapperProps {
    labelClass?: string,
}

const ChartOfAccountField = ({
  labelClass,
  ...props
}: IChartOfAccountFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps } labelClass={ labelClass }>
      <ChartOfAccountInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(ChartOfAccountField);
