import React from 'react';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import AddBtn from './add_btn';

import styles from './styles.module.scss';

const Header = () => {
  return (
    <DetailsRegion.Header
      className={ styles.container }
      title="Journal Entries"
    >
      <AddBtn />
      {/* <DownloadCsv /> */}
    </DetailsRegion.Header>
  );
};

export default Header;
