import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { revenueServiceReportPath } from '@src/routes';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';

interface IEditRevenueReportActionProps {
  report: IRevenueServiceDocument,
}

const EditRevenueReportAction = ({
  report,
}: IEditRevenueReportActionProps) => {
  const business = useBusinessContext();

  return (
    <ActionsDropdown.LinkAction
      href={ revenueServiceReportPath(business.id, report.id) }
      icon={ <EditIcon fontSize={ 20 } /> }
      title="Edit Revenue Report"
    />
  );
};

export default EditRevenueReportAction;
