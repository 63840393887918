import React, { forwardRef, useCallback } from 'react';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

type TOption = {
  name: string,
  value: string,
}

interface ISelectInputProps extends
  Omit<React.ComponentPropsWithoutRef<'select'>, 'onChange'>
{
  options: TOption[],
  value?: string,
  onChange: (value?: string) => void,
}

const SelectInput = forwardRef<HTMLSelectElement, ISelectInputProps>(({
  options,
  value,
  onChange,
  ...props
}: ISelectInputProps, ref) => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  const handleChange = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value);
  }, [onChange]);

  return (
    <select
      ref={ ref }
      className={ classes }
      { ...inputProps }
      value={ value }
      onChange={ handleChange }
    >
      {
        options.map((option) => (
          <option
            key={ option.value }
            value={ option.value }
          >
            { option.name }
          </option>
        ))
      }
    </select>
  );
});

SelectInput.displayName = 'SelectInput';

export {
  ISelectInputProps,
  SelectInput as default,
};
