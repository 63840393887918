import React, { useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { JOURNALS_QUERY, useDeleteJournal } from '@src/hooks/queries/joural_entries';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import ErrorNoti from './error_noti';

import styles from './styles.module.scss';

const DeleteIcon: React.FC<{ id: TID }> = ({ id }) => {
  const [show, setShow] = useState(false);
  const deleteRequest = useDeleteJournal();
  const { mutate: deleteMute, isLoading, error } = deleteRequest;
  const queryClient = useQueryClient();
  const [isRefetching, setIsRefetching] = useState(false);
  const businessId = useJournalEntriesContext();

  const deleteJournal = () => {
    setIsRefetching(true);
    const params = { id, businessId };
    deleteMute(params, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(JOURNALS_QUERY);
        setShow(false);
        toastr.success('Successfully deleted', 'Delete Successful');
        setIsRefetching(false);
      },
      onError: () => {
        setIsRefetching(false);
      },
    });
  };

  const showLoading = isLoading || isRefetching;

  return (
    <>
      <TrashcanIcon pointer fontSize={ 16 } inColor="grey-1050" onClick={ () => setShow(true) } />
      <Modal
        show={ show }
      >
        <Modal.Body>
          <div className={ styles['delete-modal-title'] }>Delete Journal Entry</div>

          <div className={ styles['delete-modal-content'] }>
            Are you sure you want to delete this journal entry?
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={ styles['modal-footer'] }>
            <Button className={ styles['button-secondary'] } onClick={ () => setShow(false) }>Cancel</Button>
            <Button className={ styles['delete-btn'] } disabled={ showLoading } onClick={ deleteJournal }>
              { showLoading ? <SpinnerIcon spin /> : 'Delete' }
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      { error && <ErrorNoti error={ error } title="Cannot delete" /> }
    </>
  );
};

export default DeleteIcon;
