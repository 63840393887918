module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog review-ach-payment-modal\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title font-bold">Review ACH Transfer</h1>\n        </div>\n        <div class=\'modal-body modern-body\'>\n          <div class="review-ach-payment-line">\n            <label>Sender Bank Account:</label>\n            <div>\n              <span>');
    
      __out.push(__sanitize(this.sender_dwolla_funding_source.name));
    
      __out.push('</span>\n            </div>\n          </div>\n          <div class="review-ach-payment-line">\n            <label>Amount:</label>\n            <div>\n              <span>');
    
      __out.push(__sanitize(parseFloat(this.total_amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</span>\n            </div>\n          </div>\n          <div class="review-ach-payment-line">\n            <label>Recipient:</label>\n            <div>\n              ');
    
      if (this.receiver.get('customer_type') === configData.dwolla_customer_types.RECEIVE_ONLY) {
        __out.push('\n                <span>');
        __out.push(__sanitize(this.receiver.get('display_name')));
        __out.push('</span>\n              ');
      } else {
        __out.push('\n                <span>');
        __out.push(__sanitize(this.receiver.get('business_name')));
        __out.push('</span>\n              ');
      }
    
      __out.push('\n            </div>\n          </div>\n          <div class="review-ach-payment-line">\n            <label>Recipient Bank Account:</label>\n            <div>\n              <span>');
    
      __out.push(__sanitize(this.receiver_dwolla_funding_source.name));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.receiver_dwolla_funding_source.last4 ? this.receiver_dwolla_funding_source.last4 : '————'));
    
      __out.push('</span>\n            </div>\n          </div>\n          <div class="review-ach-payment-line">\n            <label>Recipient Address:</label>\n            <div>');
    
      __out.push(__sanitize(this.receiver.address()));
    
      __out.push('</div>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button confirm-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}