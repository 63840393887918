import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDataExport } from '@src/hooks/queries/data_exports';
import { IDataExport } from '@src/types/data_exports';
import { downloadFile } from '@src/utils/download_file';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDataExportItemDownloadActionProps {
  dataExport: IDataExport,
}

const DataExportItemDownloadAction = ({
  dataExport,
}: IDataExportItemDownloadActionProps) => {
  const download = useDownloadDataExport();

  const { mutate } = download;
  const handleDownload = useCallback(() => {
    mutate(
      { dataExportId: dataExport.id },
      { onSuccess: (data) => {
        toastr.success(
          window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
          'Download in progress',
        );
        downloadFile(data.exportedFileUrl);
      } },
    );
  }, [mutate, dataExport]);

  return (
    <>
      <MutationStatus mutation={ download } />
      <ActionsDropdown.Action
        name="Download"
        onClick={ handleDownload }
      />
    </>
  );
};

export default DataExportItemDownloadAction;
