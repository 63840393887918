module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address, country, i, index, j, k, len, len1, len2, ref, state;
    
      __out.push('<div class="main-view-area">\n    <div class="m-l-20 m-r-20 m-t-20">\n      <a class=\'cancel m-t-10 pull-right pointer\'>\n        <span>Close</span>\n      </a>\n      <h4 class=\'font-26 font-bold\'>Add Payment Address</h4>\n    </div>\n    <div class="m-t-25 p-l-15 p-r-15">\n      <p>Add the address where you want the payment check to be sent. Please double check the accuracy of this information.</p>\n    </div>\n    <div class="m-t-30">\n      <div class="main-form-data-part p-l-10 p-r-10">\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for="">Check payable to:</label>\n            <input type="text" class="acount-type__input form-control name-js" placeholder="Name on the payment checks" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('" tabindex="4">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for="">Department:</label>\n            <input type="text" class="acount-type__input form-control company-js" placeholder="Optional" value="');
    
      __out.push(__sanitize(this.company));
    
      __out.push('" tabindex="4">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for="">Address:</label>\n            <input type="text" class="acount-type__input form-control address1-js" placeholder="Street" value="');
    
      __out.push(__sanitize(this.address_line1));
    
      __out.push('" tabindex="4">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for=""></label>\n            <input type="text" class="acount-type__input form-control address2-js" placeholder="Street (line 2) or Suite Number" value="');
    
      __out.push(__sanitize(this.address_line2));
    
      __out.push('" tabindex="5">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          ');
    
      if (this.address_country === 'US') {
        __out.push('\n            <div class="acount-type__input-wrap main-input-wrap wrap-city-state-zip">\n              <label class="acount-type__input-label" for=""></label>\n              <div class="city-state-zip relative">\n                <input type="text" class="acount-type__input form-control city-js" placeholder="City" value="');
        __out.push(__sanitize(this.address_city));
        __out.push('" tabindex="6">\n                <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n              </div>\n              <div class="city-state-zip relative">\n                <select class="acount-type__input form-control state-js" placeholder="State" tabindex="7">\n                  ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          state = usaStateList[i];
          __out.push('\n                  <option value="');
          __out.push(__sanitize(state.abbreviation));
          __out.push('" ');
          if (this.address_state === state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(state.text));
          __out.push('</option>\n                  ');
        }
        __out.push('\n                </select>\n                <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n              </div>\n            </div>\n          ');
      } else {
        __out.push('\n            <div class="acount-type__input-wrap main-input-wrap relative">\n              <label class="acount-type__input-label" for=""></label>\n              <input type="text" class="acount-type__input form-control city-js" placeholder="City" value="');
        __out.push(__sanitize(this.address_city));
        __out.push('" tabindex="6">\n              <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="acount-type__input-wrap main-input-wrap relative">\n              <label class="acount-type__input-label" for=""></label>\n              <input type="text" class="acount-type__input form-control state-js" placeholder="State/ Providence/ Region" value="');
        __out.push(__sanitize(this.address_state));
        __out.push('" tabindex="7">\n              <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n          ');
      }
    
      __out.push('\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for=""></label>\n            <input type="text" class="acount-type__input form-control zip-js" placeholder="ZIP" value="');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('" tabindex="8">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for=""></label>\n            <select class="acount-type__input form-control country-js" placeholder="Country" value="');
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('" tabindex="9">\n              ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n                <option value="');
        __out.push(__sanitize(country.code));
        __out.push('" ');
        if (this.address_country === country.code) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(country.text));
        __out.push('</option>\n              ');
      }
    
      __out.push('\n            </select>\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="acount-type__input-wrap main-input-wrap relative">\n            <label class="acount-type__input-label" for=""></label>\n            <div class="checkbox checkbox-inline in-green-600" style="width: 100%">\n              <input type="checkbox" class="styled authorized-address-js" id="checkbox-authorized-address" tabindex="10">\n              <label for="checkbox-authorized-address">Add as Authorized Address</label>\n            </div>\n          </div>\n          <div class="m-t-15 text-center">\n            <div class="checkbox checkbox-inline in-green-600">\n              <input type="checkbox" class="styled" id="checkbox-verify-accuracy-address" tabindex="11">\n              <label for="checkbox-verify-accuracy-address">I verify the accuracy of this address</label>\n            </div>\n          </div>\n      </div>\n      <div class="bg-grey-150 m-t-50 p-r-10 p-l-10"><p class="font-bold">Pre-fill from Docyt Verified Addresses</p></div>\n      <div class="m-t-25 p-l-15 p-r-15">\n        <p>This vendor has previously received payments on the addresses shown below. You can use these to pre-fill the address form above.</p>\n      </div>\n      <div class="pre-fill-form-data-part">\n        ');
    
      ref = this.addresses;
      for (index = k = 0, len2 = ref.length; k < len2; index = ++k) {
        address = ref[index];
        __out.push('\n          <div class="padding-10">\n            <a class="pointer copy-address-js p-r-10 vertical-align-top" data-address-index="');
        __out.push(__sanitize(index));
        __out.push('">Copy this address</a>\n            <label class="address-info-label">\n                <div class="">');
        __out.push(__sanitize(address.name));
        __out.push(' ');
        __out.push(__sanitize(address.company));
        __out.push('</div>\n                <div class="">');
        __out.push(__sanitize(address.address_line1));
        __out.push('</div>\n                ');
        if (address.address_line2) {
          __out.push('<div class="">');
          __out.push(__sanitize(address.address_line2));
          __out.push('</div>');
        }
        __out.push('\n                <div class="">');
        __out.push(__sanitize(address.address_city));
        __out.push(' ');
        __out.push(__sanitize(address.address_state));
        __out.push(' ');
        __out.push(__sanitize(address.address_zip));
        __out.push(' </div>\n                <div class="">');
        __out.push(__sanitize(address.address_country));
        __out.push('</div>\n            </label>\n          </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div class="text-center m-t-80">\n      <button type=\'button\' class="btn btn-blue save-js" tabindex="12">Save Vendor Payment Address</button>\n    </div>\n    <div class="text-center m-t-10 m-b-50">\n      <a class=\'cancel settings-cancel-btn\'>Cancel</a>\n    </div>\n</div>\n<div class="second-view-region height-100-percent">\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}