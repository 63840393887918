import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IDetailsRegionProps extends React.ComponentPropsWithoutRef<'div'> {}

const DetailsRegion = ({
  children,
  className,
  ...props
}: IDetailsRegionProps) => {
  const classes = classNames(styles['details-region'], className);

  return (
    <div className={ classes } { ...props }>
      { children }
    </div>
  );
};

export default DetailsRegion;
