import React, {
  Children,
  ComponentPropsWithoutRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AngleIcon from '@src/components/utils/angle_icon';

import styles from './styles.module.scss';

interface CarouselViewProps extends ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode | React.ReactNode[],
  defaultChildWidth?: number;
  height: number,
}

const MINIMUM_CHILD_WIDTH = 100;

const CarouselView = ({
  children,
  defaultChildWidth,
  height,
  ...props
}: CarouselViewProps) => {
  const defaultItemWidth =
    ((defaultChildWidth || 0) > MINIMUM_CHILD_WIDTH) ? defaultChildWidth! : MINIMUM_CHILD_WIDTH;
  const [currentPos, setCurrentPos] = useState<number>(0);
  const [viewableCount, setViewableCount] = useState<number>(1);
  const [itemWidth, setItemWidth] = useState<number>(defaultItemWidth);
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(false);
  const totalChildCount = useMemo(() => {
    return Children.count(children);
  }, [children]);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback((scrollOffset: number) => {
    const newPos = currentPos + scrollOffset;
    if (containerRef.current && (newPos >= 0) && (newPos + viewableCount <= totalChildCount)) {
      setIsLeftArrowDisabled(newPos === 0);
      setIsRightArrowDisabled(newPos + viewableCount >= totalChildCount);
      setCurrentPos(newPos);
      containerRef.current.scrollLeft = newPos * itemWidth;
    }
  }, [setIsLeftArrowDisabled, setIsRightArrowDisabled, setCurrentPos,
    containerRef, totalChildCount, currentPos, viewableCount, itemWidth]);
  useLayoutEffect(() => {
    const updateSize = () => {
      if (containerRef.current && containerRef.current.clientWidth > 0) {
        let itemCount = Math.ceil(containerRef.current.clientWidth / defaultItemWidth);
        if (itemCount >= totalChildCount) {
          setIsRightArrowDisabled(true);
          itemCount = totalChildCount;
        } else {
          setIsRightArrowDisabled(false);
        }
        setViewableCount(itemCount);
        setItemWidth(Math.ceil(containerRef.current.clientWidth / itemCount));
      }
    };
    updateSize();
    window.addEventListener('resize', updateSize, true);
    return () => {
      window.removeEventListener('resize', updateSize, true);
    };
  }, [setItemWidth, defaultItemWidth, setIsRightArrowDisabled, totalChildCount, setViewableCount]);

  return (
    <div
      className={ styles['carousel-list-view'] }
      style={ { height: `${height}px` } }
      { ...props }
    >
      <button
        className={ styles['left-arrow'] }
        disabled={ isLeftArrowDisabled }
        type="button"
        onClick={ () => handleScroll(-1) }
      >
        <AngleIcon fontSize={ 16 } variant="left" />
      </button>
      <div ref={ containerRef } className={ styles['carousel-list-container'] }>
        <div className={ styles['carousel-items-container'] }>
          {
            Children.map(children, (child) => (
              <div
                className={ styles['carousel-item'] }
                style={ { width: `${itemWidth}px` } }
              >
                { child }
              </div>
            ))
          }
        </div>
      </div>
      <button
        className={ styles['right-arrow'] }
        disabled={ isRightArrowDisabled }
        type="button"
        onClick={ () => handleScroll(1) }
      >
        <AngleIcon fontSize={ 16 } variant="right" />
      </button>
    </div>
  );
};

export default CarouselView;
