import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useUpdateTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CrossIcon, MoveItemIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IExcludeTransactionActionProps {
  transaction: ITransactionServiceDocument,
}

const ExcludeTransactionAction = ({ transaction }: IExcludeTransactionActionProps): JSX.Element => {
  const updateTransactionServiceDocument = useUpdateTransactionServiceDocument();
  const { mutate } = updateTransactionServiceDocument;

  const handleClick = useCallback(() => {
    if (transaction.state === window.Docyt.Common.Constants.TRANSACTION_STATES.VERIFIED
      && !transaction.excluded) {
      toastr.error(window.Docyt.Common.Constants.Messages.TRANSACTION_EXCLUDE_ERROR_MSG, '');
    } else {
      mutate({ id: transaction.id, excluded: !transaction.excluded });
    }
  }, [transaction, mutate]);

  return (
    <>
      <MutationStatus mutation={ updateTransactionServiceDocument } />
      <ActionsDropdown.Action
        icon={ transaction.excluded ? (
          <MoveItemIcon fontSize={ 18 } />
        ) : (
          <CrossIcon fontSize={ 18 } />
        ) }
        name={ transaction.excluded ? 'Move to Transactions' : 'Exclude Transaction' }
        onClick={ handleClick }
      />
    </>
  );
};

export default ExcludeTransactionAction;
