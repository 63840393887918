import React from 'react';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';

import { useDownloadReportModal } from '../../common/download_report_modal';

interface IDownloadRevenueReportActionProps {
  report: IRevenueServiceDocument,
}

const DownloadRevenueReportAction = ({
  report,
}: IDownloadRevenueReportActionProps) => {
  const modal = useDownloadReportModal();

  return (
    <>
      <modal.Component
        revenueReport={ report }
        { ...modal.props }
      />
      <ActionsDropdown.Action
        icon={ <DownloadDocumentIcon fontSize={ 20 } variant="thin" /> }
        title="View Downloaded Files"
        onSelect={ modal.open }
      />
    </>
  );
};

export default DownloadRevenueReportAction;
