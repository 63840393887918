import React, { useEffect, useState, useMemo, useRef } from 'react';

import { BankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useGetBankAccountReconciliation,
  useCalculateBankAccountReconciliation,
} from '@src/hooks/queries/bank_account_reconciliations';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IBankAccountReconciliationProviderProps {
  baReconciliationId: TID,
  children: React.ReactNode,
}

const BankAccountReconciliationProvider = ({
  baReconciliationId,
  children,
}: IBankAccountReconciliationProviderProps): JSX.Element | null => {
  const business = useBusinessContext();

  const [calculateLoading, setCalculateLoading] = useState(false);

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
  } = useGetBankAccountReconciliation(business.id, baReconciliationId, setCalculateLoading);

  const { refetch: calculateBankAccountReconciliation } =
    useCalculateBankAccountReconciliation(baReconciliationId);

  // Ensure the calculation function is called only when the reconciliation ID changes,
  // such as when the month or account changes.
  // It should not be called when acknowledging or retracting an acknowledgment item.
  const previousReconciliationIdRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    const reconciliation = data?.baReconciliation;
    if (
      reconciliation
      && reconciliation.status !== 'reconciled'
      && reconciliation.id !== previousReconciliationIdRef.current
    ) {
      setCalculateLoading(true);
      calculateBankAccountReconciliation();
      previousReconciliationIdRef.current = reconciliation.id;
    }
  }, [data, calculateBankAccountReconciliation]);

  const contextValue = useMemo(
    () => (data ? { ...data.baReconciliation, isFetching, calculateLoading } : null),
    [data, isFetching, calculateLoading],
  );

  if (isLoading) return <Spinner localSpinnerId="detail-region" />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Bank Account Reconciliation loaded" />;

  return (
    <BankAccountReconciliationContext.Provider value={ contextValue }>
      { children }
    </BankAccountReconciliationContext.Provider>
  );
};

export default BankAccountReconciliationProvider;
