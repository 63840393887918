import React, { forwardRef } from 'react';

import styles from '../styles.module.scss';

type TOption = {
  label: string,
  value: string,
}

interface ICheckBoxCollectionInputProps extends React.ComponentPropsWithoutRef<'input'> {
  options: TOption[]
}

const CheckBoxCollectionInput = forwardRef<HTMLInputElement, ICheckBoxCollectionInputProps>(({
  options,
  ...inputProps
}: ICheckBoxCollectionInputProps, ref): JSX.Element => {
  return (
    <div className={ styles['radio-collection-input'] }>
      { options.map((option) => (
        <div key={ option.value } className="checkbox checkbox-primary">
          <input
            ref={ ref }
            id={ `check_box_collection_${option.value}` }
            type="checkbox"
            value={ option.value }
            { ...inputProps }
          />
          <label htmlFor={ `check_box_collection_${option.value}` }>
            { option.label }
          </label>
        </div>
      )) }
    </div>
  );
});

CheckBoxCollectionInput.displayName = 'CheckBoxCollectionInput';

export default React.memo(CheckBoxCollectionInput);
