import React, { useCallback, useEffect, useState, useMemo } from 'react';

import maxBy from 'lodash/maxBy';
import sumBy from 'lodash/sumBy';

import { ISplitTransaction } from '@src/types/split_transactions';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { currencyFormater } from '@src/utils/currency';

import { PlusIcon } from '@src/components/utils/icomoon';

import Details from './split_transaction_details';
import List from './split_transaction_list';

interface ISplitTransactionsFormProps {
  hasBlankItems: boolean,
  transaction: ITransactionServiceDocument,
  onSplitted: (value: ISplitTransaction[], difference: number) => void,
}

const SplitTransactionsForm = ({
  hasBlankItems,
  transaction,
  onSplitted,
}: ISplitTransactionsFormProps): JSX.Element => {
  const transactionAmount: number = Number(transaction.amount);

  const [splittedTransactions, setSplittedTransactions] = useState<ISplitTransaction[]>([
    {
      id:     1,
      amount: 0,
      memo:   '',
    },
    {
      id:     2,
      amount: 0,
      memo:   '',
    },
  ]);

  const handleSplitTransactionsInfoChange = useCallback((id: number, v?: string, m?: string) => {
    setSplittedTransactions((originSplittedTransactions) => {
      const newSplittedTransactions = originSplittedTransactions.map((splittedTransaction) => {
        if (splittedTransaction.id !== id) return splittedTransaction;

        return {
          ...splittedTransaction,
          amount: v !== undefined ? Number(v) : splittedTransaction.amount,
          memo:   m !== undefined ? m : splittedTransaction.memo,
        };
      });

      return newSplittedTransactions;
    });
  }, []);

  const onSplitTransactionValueChange = useCallback((value: string, id: number) => {
    handleSplitTransactionsInfoChange(id, value, undefined);
  }, [handleSplitTransactionsInfoChange]);

  const onSplitTransactionMemoChange = useCallback((value: string, id: number) => {
    handleSplitTransactionsInfoChange(id, undefined, value);
  }, [handleSplitTransactionsInfoChange]);

  const handleAddSplitTransaction = useCallback(() => {
    setSplittedTransactions((originSplittedTransactions) => {
      const maxId = originSplittedTransactions.length > 0 ? maxBy(originSplittedTransactions, 'id')!.id : 0;
      const newSplitTransaction: ISplitTransaction = {
        id:     maxId + 1,
        amount: 0,
        memo:   '',
      };
      return [...originSplittedTransactions, newSplitTransaction];
    });
  }, []);

  const handleRemoveSplitedTransaction = useCallback((id: number) => {
    setSplittedTransactions(() => {
      return splittedTransactions.filter((splittedTransaction) => splittedTransaction.id !== id);
    });
  }, [splittedTransactions]);

  const totalAmount = useMemo(() => {
    return sumBy(splittedTransactions, 'amount');
  }, [splittedTransactions]);

  const differenceAmount = Number((transactionAmount - totalAmount).toFixed(2));

  useEffect(() => {
    onSplitted(splittedTransactions, differenceAmount);
  }, [splittedTransactions, differenceAmount, onSplitted]);

  return (
    <div
      className="split-transactions-modal"
      style={ { margin: '0 -20px' } }
    >
      <Details
        transaction={ transaction }
      />
      <div className="split-transaction-list m-t-10 p-b-30 border-bottom-gray">
        <div className="display-flex split-transaction-list-title m-b-10">
          <span className="title width-25-percent font-bold">Amount</span>
          <span className="title font-bold p-l-5">Description</span>
        </div>
        {
          splittedTransactions.map((splittedTransaction) => {
            return (
              <List
                key={ splittedTransaction.id }
                hasBlankItems={ hasBlankItems }
                removeSplitedTransaction={ handleRemoveSplitedTransaction }
                splittedTransaction={ splittedTransaction }
                onMemoChange={ onSplitTransactionMemoChange }
                onValueChange={ onSplitTransactionValueChange }
              />
            );
          })
        }
        <button
          className="pull-right add-split-btn"
          type="submit"
          onClick={ handleAddSplitTransaction }
        >
          <PlusIcon pr={ 4 } variant="o" />
          {' '}
          Add Split
        </button>
      </div>
      <div className="m-t-10">
        <div className="display-flex justify-content-between total-amount">
          <span>
            Total Amount:
            { ' ' }
            <span className="font-bold">{ currencyFormater(totalAmount) }</span>
          </span>
          <span>
            Transaction Amount:
            { ' ' }
            <span className="font-bold">{ currencyFormater(transactionAmount) }</span>
          </span>
          <span>
            Difference Amount:
            { ' ' }
            <span className="font-bold" style={ differenceAmount === 0 ? { color: 'black' } : { color: 'red' } }>
              { currencyFormater(differenceAmount) }
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export {
  ISplitTransactionsFormProps,
  SplitTransactionsForm as default,
};
