module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, template;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog team-multi-select-modal select-multi-item-area">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">Create New Advanced Report: Select Report Type</h4>\n      </div>\n      <div class="modal-body multi-entity-select-region">\n        <div class="report-type-container display-flex-column m-t-48 m-b-8 m-l-24">\n          <div class="display-flex align-items-center main-input-wrap relative">\n            <div class="font-16 width-200px">Report Name</div>\n            <input type="text" class="acount-type__input width-400px form-control report-name-js" placeholder="Enter Name of Report" value="">\n            <p class="m-t-5 m-l-5 main-input__hint in-red-400 invalid-name-js"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="display-flex m-t-25">\n            <div class="font-16 width-200px m-t-10">Report Type</div>\n            <div class="display-flex-column">\n              ');
    
      ref = this.templates;
      for (i = 0, len = ref.length; i < len; i++) {
        template = ref[i];
        __out.push('\n                <div class="checkbox checkbox-primary checkbox-circle">\n                  <input type="radio" id=');
        __out.push(__sanitize(template.get('id')));
        __out.push(' name="radio-type" class="pointer radio-type" value=');
        __out.push(__sanitize(template.get('id')));
        __out.push(' ');
        __out.push(__sanitize(template.get('draft') ? 'disabled' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.curTemplateId === template.get('id') ? 'checked' : ''));
        __out.push('>\n                  <label for=');
        __out.push(__sanitize(template.get('id')));
        __out.push('>');
        __out.push(__sanitize(template.get('name')));
        __out.push('</label>\n                </div>\n              ');
      }
    
      __out.push('\n            </div>\n          </div>\n        </div>\n        <div class="businesses-container-region"></div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type="button" class="btn btn-blue proceed-btn">Continue</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}