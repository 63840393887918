import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface DetailsPageBreadcrumbProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'title'>, IUIStyleProps {
  title: React.ReactNode,
}

const DetailsPageBreadcrumb = ({
  href,
  title,
  ...props
}: DetailsPageBreadcrumbProps): JSX.Element => {
  const [classes, anchorProps] = uiStyleProps(styles.breadcrumb, props);

  return (
    href ? (
      <a className={ classes } { ...anchorProps } href={ href }>
        { title }
      </a>
    ) : (
      <span className={ classes } { ...anchorProps }>
        { title }
      </span>
    )
  );
};

export default DetailsPageBreadcrumb;
