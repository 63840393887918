import React, { useCallback, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { IResolveDuplicateParams } from '@src/requests/transaction_service_documents';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import Modal from '@src/components/ui/modal/modal';

import DuplicatedTransactions from './duplicated_transactions';

interface IResolveDuplicatesModalProps {
  businessId: TID,
  isOpen: boolean,
  onCancel: () => void,
  onDone: (resolveDuplicateparams: IResolveDuplicateParams[]) => void,
}

const ResolveDuplicatesModal = ({
  businessId,
  isOpen,
  onCancel,
  onDone,
}: IResolveDuplicatesModalProps): JSX.Element => {
  const [resolveParams, setResolveParams] =
    useState<{ params: IResolveDuplicateParams[] }>({ params: [] });
  const duplicatedCount = resolveParams.params.filter((res) => res.resolveDuplication.type === 'Duplicate').length;
  const notDuplicatedCount = resolveParams.params.filter((res) => res.resolveDuplication.type === 'Not Duplicate').length;

  const handleDone = useCallback(() => {
    onDone(resolveParams.params);
  }, [onDone, resolveParams.params]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleDone,
  });

  const handleProceed = useCallback(() => {
    confirmModal.open();
  }, [confirmModal]);

  const handleCancel = useCallback(() => {
    const notIds = resolveParams.params.filter((res) => res.resolveDuplication.type === 'Not Duplicate')
      .map((notDulicate) => notDulicate.resolveDuplication.id);

    localStorage.setItem('notDuplicated', JSON.stringify({ ids: notIds }));
    onCancel();
  }, [resolveParams.params, onCancel]);

  const onChange = useCallback((
    params: IResolveDuplicateParams[],
  ) => {
    setResolveParams({ params });
  }, []);
  return (
    <>
      <confirmModal.Component
        { ...confirmModal.props }
        confirmStyle="primary"
        confirmTitle="Confirm"
        text={ (
          <div className="text-left">
            <p>
              The duplicates for
              { ` ${duplicatedCount} selected ` }
              { (duplicatedCount === 1) ? 'transaction ' : 'transactions ' }
              will be deleted. Original transactions will not be affected.
              { ` ${notDuplicatedCount} unselected ` }
              { (notDuplicatedCount === 1) ? 'transaction ' : 'transactions ' }
              will be marked as not duplicates.
            </p>
            <p>Are you sure you want to continue?</p>
          </div>
        ) }
        title={ `Update ${resolveParams.params.length} ${(resolveParams.params.length === 1) ? 'transaction' : 'transactions'}` }
      />
      <Modal.Standard
        className="resolve_duplicate_form"
        proceedTitle="Resolve Duplicates"
        show={ isOpen }
        title="Resolve Duplicate Transactions"
        onCancel={ handleCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <DuplicatedTransactions
            businessId={ businessId }
            onChange={ onChange }
          />
        )}
      </Modal.Standard>
    </>
  );
};

const useResolveDuplicatesModal = makeUseModal(ResolveDuplicatesModal);

export {
  IResolveDuplicatesModalProps,
  useResolveDuplicatesModal,
  ResolveDuplicatesModal as default,
};
