import React, { ChangeEvent, DragEvent, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { JOURNALS_QUERY, useUploadCSV } from '@src/hooks/queries/joural_entries';

import ErrorNoti from '@src/components/journal_entries/main_page/error_noti';
import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import CsvIcon from '@src/components/utils/icomoon/csv';

import styles from './styles.module.scss';

interface IUploadCSVProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  closeParentModal: () => void;
  info: {
    name: string;
    description: string;
    managementGroupId: number;
  }
}

const UploadCSV : React.FC<IUploadCSVProps> = (props) => {
  const { show, setShow, info, closeParentModal } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [id, setId] = useState(0);

  const uploadRequest = useUploadCSV();
  const { mutate, isLoading, error } = uploadRequest;
  const [isRefetching, setIsRefetching] = useState(false);

  const queryClient = useQueryClient();

  const businessId = useJournalEntriesContext();

  const close = () => {
    setShow(false);
    setSelectedFile(null);
    setUploadProgress(0);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        setSelectedFile(event.dataTransfer.files[0]);
      } else {
        toastr.error('Docyt only supports CSV file format when importing journal entries.', 'Something went wrong');
      }
    }
  };

  const upload = () => {
    if (!selectedFile) {
      return toastr.error('No file selected.', 'Upload Failed');
    }

    const onUploadProgress = (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(percentCompleted);
    };

    setIsRefetching(true);
    return mutate({
      businessId,
      file: selectedFile,
      onUploadProgress,
      ...info,
    }, {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(JOURNALS_QUERY);
        setIsRefetching(false);
        closeParentModal();
        close();
        setId(res.journal?.id);
        toastr.success('Successfully imported', 'Import Successful');
      },
      onError: () => {
        setIsRefetching(false);
        setUploadProgress(0);
      },
    });
  };

  let displayUploadProgress;

  if (id) {
    displayUploadProgress = uploadProgress;
  } else if (uploadProgress > 1) {
    displayUploadProgress = uploadProgress - 1;
  } else {
    displayUploadProgress = uploadProgress;
  }

  const loadingPanel = (
    <div className={ styles['csv-input-panel'] }>
      <div className={ styles['loading-text'] }>
        Uploading…
        {' '}
        { displayUploadProgress }
        %
      </div>
      <div className={ styles['progress-bar-container'] }>
        <div
          className={ styles['progress-bar'] }
          style={ { width: `${displayUploadProgress}%` } }
        />
      </div>
    </div>
  );

  const uploadInput = (
    <div
      className={ styles['csv-input-panel'] }
      onDragOver={ (e) => e.preventDefault() }
      onDrop={ handleFileDrop }
    >
      <CsvIcon fontSize={ 60 } />
      <p>Drag and drop the transaction CSV here.</p>
      <p>OR</p>

      <label className={ styles['button-secondary'] } htmlFor="fileInput">
        <input
          accept=".csv"
          id="fileInput"
          type="file"
          onChange={ handleFileSelect }
        />
        Select from your computer
      </label>
    </div>
  );

  const showLoading = isLoading || isRefetching;

  return (
    <>
      <Modal
        show={ show }
      >
        <Modal.Body>
          <div className={ styles['upload-modal-title'] }>
            { showLoading ? 'Uploading ' : 'Upload ' }
            CSV
          </div>

          { showLoading ? loadingPanel : uploadInput }

          {selectedFile && (
            <div className={ styles['selected-file-name'] }>
              Selected File:
              { selectedFile.name }
            </div>
          )}

        </Modal.Body>
        <Modal.Footer>
          <div className={ styles['modal-footer'] }>
            <Button className={ styles['button-secondary'] } onClick={ close }>Cancel</Button>
            <Button className={ `${styles['upload-csv-csv']} ${styles['upload-btn']}` } disabled={ showLoading } onClick={ upload }>
              { showLoading ? <SpinnerIcon spin /> : 'Upload CSV' }
            </Button>
          </div>
        </Modal.Footer>

      </Modal>
      { error && <ErrorNoti error={ error } title="Import Failed" />}
    </>

  );
};

export default UploadCSV;
