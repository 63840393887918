import React from 'react';

import { IPracticeInformation } from '@src/types/practice_informations';

import GotoBusinessHome from './goto_business_home_action';
import ViewInClientCentral from './view_in_client_central_action';

interface IMyPracticeItemActionsProps {
  information: IPracticeInformation,
}

const MyPracticeItemActions = ({
  information,
}: IMyPracticeItemActionsProps): JSX.Element => {
  return (
    <>
      <ViewInClientCentral information={ information } />
      <GotoBusinessHome information={ information } />
    </>
  );
};

export default MyPracticeItemActions;
