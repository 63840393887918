import React, { useCallback } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';
import { useUpdateBusinessVendor } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import SideView from '@src/components/ui_v2/side_view';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './profile_edit_form';
import { IVendorProfileParams } from './schema';

const VendorProfileEditContainer = ({
  vendor,
  editProfile,
  setEditProfile,
  setVendorData,
}: {
  vendor: IBusinessVendor,
  editProfile: boolean,
  setEditProfile: React.Dispatch<React.SetStateAction<boolean>>,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor | null>>,
}) => {
  const formId = useUniqueDomId('modal_form_');
  const updateVendor = useUpdateBusinessVendor();
  const { mutate } = updateVendor;

  const handleSubmit = useCallback((data: IVendorProfileParams) => {
    const params = {
      ...vendor,
      ...data,
      vendor: {
        ...vendor,
        ...data,
      },
    };

    mutate(params, {
      onSuccess: (res: IBusinessVendor) => {
        setVendorData(res);
      },
    });
  }, [mutate, vendor, setVendorData]);

  return (
    <>
      <SideView.Provider>
        <SideView.Standard
          isRoot
          isOpen={ !editProfile }
          title="Edit Vendor Profile"
          onCancel={ () => setEditProfile(true) }
        >
          {
            () => (
              <Form
                formId={ formId }
                vendor={ vendor }
                onSubmit={ handleSubmit }
              />
            )
          }
        </SideView.Standard>
        <SideView.Render />
      </SideView.Provider>
      <MutationStatus
        mutation={ updateVendor }
        successMessage="Vendor details have been successfully updated."
        successTitle="Success"
      />
    </>
  );
};

export default React.memo(VendorProfileEditContainer);