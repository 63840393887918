import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useAddBusinessClientModal } from '../add_business_client/add_business_client_modal';

import styles from '../styles.module.scss';

const AddBusinessClient = (): JSX.Element => {
  const addBusinessClientModal = useAddBusinessClientModal();

  return (
    <>
      <addBusinessClientModal.Component
        { ...addBusinessClientModal.props }
      />
      <div className={ styles['add-business-client-btn'] }>
        <Button
          variant="primary"
          onClick={ addBusinessClientModal.open }
        >
          <PlusIcon />
          Add Business Client
        </Button>
      </div>
    </>
  );
};

export default AddBusinessClient;
