import React from 'react';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import HCell from '@src/components/ui_v2/table/hcell';

interface IBusinessReportsExportSelectTableItemHCellProps extends
  Omit<React.ComponentPropsWithoutRef<'th'>, 'children'>
{
  inputProps?: React.ComponentPropsWithoutRef<typeof CheckboxInput>,
  title: string,
}

const BusinessReportsExportSelectTableItemHCell = ({
  inputProps,
  title,
  ...cellProps
}: IBusinessReportsExportSelectTableItemHCellProps) => {
  return (
    <HCell widthPercent={ 3 } { ...cellProps }>
      <CheckboxInput checked small title={ title } { ...inputProps } />
    </HCell>
  );
};

export default React.memo(BusinessReportsExportSelectTableItemHCell);
