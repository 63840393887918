import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { RelatedDocumentOptions, TransactionTypeOptions } from './options';
import PaymentAccountSelect from './payment_account_select';
import TypeSelect from './type_select';
import { ITransactionsFilter } from './types';

interface ITransactionsFilterProps {
  filter: IFilter<ITransactionsFilter>;
}

const TransactionsFilter = ({
  filter,
}: ITransactionsFilterProps) => {
  return (
    <Filter<ITransactionsFilter> filter={ filter }>
      <Filter.DateField name="transaction_date" widthPercent={ 22 } />
      <Filter.TextField
        name="description"
        placeholder="Transaction Description"
        widthPercent={ 17 }
      />
      <TypeSelect widthPercent={ 13 } />
      <PaymentAccountSelect widthPercent={ 12 } />
      <Filter.AmountField name="amount" widthPercent={ 17 } />
      <Filter.IconSelectField
        hideIndicators
        wideMenu
        isSearchable={ false }
        name="created_type"
        options={ TransactionTypeOptions }
        widthPercent={ 8 }
      />
      <Filter.IconSelectField
        hideIndicators
        rightAlignMenu
        wideMenu
        isSearchable={ false }
        name="has_related_document"
        options={ RelatedDocumentOptions }
        widthPercent={ 8 }
      />
    </Filter>
  );
};

export default React.memo(TransactionsFilter);
