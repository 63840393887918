import { TVendorMergeType } from '@src/types/business_vendors';

type TReviewMergeType = {
  label: string,
  value: TVendorMergeType,
}

export const ReviewMergeTypes: TReviewMergeType[] = [
  {
    label: 'Merge with this vendor',
    value: 'enable_merge',
  },
  {
    label: 'Do not merge with this vendor',
    value: 'disable_merge',
  },
];
