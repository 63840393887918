module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<ul class="nav nav-sidebar font-14 font-semi-bold in-black">\n  <li class="');
    
      __out.push(__sanitize(this.step === 1 ? 'active' : ''));
    
      __out.push('">\n    <a class="display-flex align-items-center">\n      <span class="nav-item-text">Step 1: Entity Info</span>\n      ');
    
      if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"] || this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["DOCUMENT"]) {
        __out.push('\n        <div class="in-orange-300 font-20 m-l-auto m-r-0"><i class="icon icon-qbo-error m-r-8"></i></div>\n      ');
      } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["SUSPENDED"]) {
        __out.push('\n        <div class="in-orange-300 font-24 m-l-auto m-r-0"><i class="icon icon-warning-triangle m-r-8"></i></div>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  ');
    
      if (this.dwollaCustomer.get('business_type') === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === 2 ? 'active' : ''));
        __out.push('">\n    <a class="">\n      <span class="nav-item-text">Step 2: Business Owner</span>\n    </a>\n  </li>\n  ');
      } else if (this.dwollaCustomer.get('business_type')) {
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.step === 2 ? 'active' : ''));
        __out.push('">\n    <a class="">\n      <span class="nav-item-text">Step 2: Account Admin</span>\n    </a>\n  </li>\n  <li class="');
        __out.push(__sanitize(this.step === 3 ? 'active' : ''));
        __out.push('">\n    <a class="">\n      <span class="nav-item-text">Step 3: Controller</span>\n      ');
        if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"] || this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["DOCUMENT"]) {
          __out.push('\n        <div class="in-orange-300 font-20 m-l-auto m-r-0"><i class="icon icon-qbo-error m-r-8"></i></div>\n      ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["SUSPENDED"]) {
          __out.push('\n        <div class="in-orange-300 font-24 m-l-auto m-r-0"><i class="icon icon-warning-triangle m-r-8"></i></div>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}