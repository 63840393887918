module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<a class="clients__client pointer select-profile-js" href="/invoice_profiles/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">\n  <div class=\'clients__client-status clients__client-cell\'>\n    ');
    
      if (this.avatar_url) {
        __out.push('\n      <div class="clients__userpic">\n        <img src="');
        __out.push(__sanitize(this.avatar_url));
        __out.push('" class=\'clients__userpic-img\'>\n      </div>\n    ');
      } else {
        __out.push('\n      <i class=\'clients__client-status-icon icon icon-smile in-orange-500\'></i>\n    ');
      }
    
      __out.push('\n    <span class=\'contacts__contact-name-text\'>\n      ');
    
      __out.push(__sanitize(this.owner_name));
    
      __out.push(' / ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n    </span>\n  </div>\n  <div class=\'clients__client-status clients__client-cell\'>\n    ');
    
      if (this.complete) {
        __out.push('\n      <i class="fa fa-check-circle in-green-400 font-16" aria-hidden="true">Ready</i>\n    ');
      } else {
        __out.push('\n      <i class="fa fa-warning in-red-400 font-16" aria-hidden="true">Needs Review</i>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class=\'clients__client-status clients__client-cell\' style="width: 10%;">\n    <button type="button" class="btn btn-default btn-delete-profile inline-block m-b-10">Delete</button>\n  </div>\n</a>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}