module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table banking-accounts-table receivable-payment-table">\n  <thead>\n    <tr>\n      <th width="2%"></th>\n      <th class="sortable width-30-percent" style="padding-left: 50px !important" data-orderby="vendor" data-orderdir="ASC">Vendors</th>\n      <th class="sortable width-30-percent" data-orderby="ledger_name" data-orderdir="ASC">Name in Ledger</th>\n      <th class="sortable width-20-percent" data-orderby="docyt_id" data-orderdir="ASC">Docyt ID</th>\n      <th class="sortable width-20-percent" data-orderby="ach_status" data-orderdir="ASC">Status</th>\n      <th class="width-50px"></th>\n      ');
    
      if (this.businesses_tab_enabled) {
        __out.push('\n      <th class="width-50px"></th>\n      ');
      }
    
      __out.push('\n      <th class="width-50px"></th>\n      <th class="actions-column"></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}