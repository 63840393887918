module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title font-bold">Edit Forwarding Email</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <div class="text-center m-t-10">\n                <label class="m-b-10" for="upload-email-input">Keep it simple, easy to remember. Forward your ');
    
      __out.push(__sanitize(this.email_prefix));
    
      __out.push(' to this email.</label>\n                <div class="input-wrapper relative upload-email-input-wrapper">\n                    <span class="upload-email-prefix">');
    
      __out.push(__sanitize(this.email_prefix));
    
      __out.push('-</span>\n                    <input type="text" id="upload-email-input" class="form-control p-l-70" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n                    <span class="upload-email-suffix">@');
    
      __out.push(__sanitize(configData.docyt_io_domain));
    
      __out.push('</span>\n                </div>\n                <p class="text-left in-red-400 input-email-warning hidden m-t-10 m-b-0">\n                    <i class="fa fa-warning m-r-5"></i>Please input valid email.\n                </p>\n            </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}