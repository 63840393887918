import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const GrayEnvelope = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="grey-envelope"
    />
  );
};

export default GrayEnvelope;
