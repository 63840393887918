import { IRevenueServiceDocumentCategoryValue } from 'src/types/revenue_service_document_category_values';

export const calculateValue = (
  isDebit: boolean,
  section: string,
  revenueServiceDocumentCategoryValue?: IRevenueServiceDocumentCategoryValue
): string | null | undefined => {
  if (!isDebit) return null;
  if (section === 'other_ledgers') return revenueServiceDocumentCategoryValue?.netChange;
  if (revenueServiceDocumentCategoryValue?.netChange !== undefined) return revenueServiceDocumentCategoryValue?.netChange

  return revenueServiceDocumentCategoryValue?.value;
};
