import React from 'react';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IManagementGroup } from '@src/types/management_groups';

import { Button } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table';

import { useSetupAccount } from './new_bank_or_credit_card/new_account';

import styles from '../styles.module.scss';

interface IfinancialInstitutionBankAccountCenterTableItemProps {
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount,
  employeeCardFeatureFlag: boolean,
  managementGroup: IManagementGroup,
  refetchFinancialConenctionList: () => void
}

const FinancialInstitutionBankAccountCenterTableItem = ({
  financialInstitutionBankAccount,
  employeeCardFeatureFlag,
  managementGroup,
  refetchFinancialConenctionList,
}: IfinancialInstitutionBankAccountCenterTableItemProps) => {
  const setupAccount = useSetupAccount();

  const accountType = financialInstitutionBankAccount.accountType === 'credit' ? 'credit_card' : 'bank';

  return (
    <>
      <setupAccount.Component
        accountType={ accountType }
        financialConnectionBankAccount={ financialInstitutionBankAccount.id }
        managementGroupId={ managementGroup.id }
        refetchFinancialConenctionList={ refetchFinancialConenctionList }
        { ...setupAccount.props }
      />
      <Table.Row className={ styles['financial-banking-accounts-item'] }>
        <Table.Cell>
          { financialInstitutionBankAccount.name }
        </Table.Cell>
        <Table.Cell>
          { financialInstitutionBankAccount.last4 }
        </Table.Cell>
        <Table.Cell />
        {employeeCardFeatureFlag ? (
          <Table.Cell className={ styles.cell }>
            {financialInstitutionBankAccount.bankAccountName ? (
              <span>{financialInstitutionBankAccount.bankAccountName}</span>
            ) : (
              <>
                <span className={ styles['no-bank-account-mapped-for-employee-cards'] }>
                  { `No ${accountType === 'credit_card' ? 'Credit Card' : 'Banking Account'} mapped` }
                </span>
                <Button
                  bsStyle="link"
                  className={ styles['map-credit-card'] }
                  onClick={ setupAccount.open }
                >
                  { `Map ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}` }
                </Button>
              </>
            )}
          </Table.Cell>
        ) : (
          <Table.Cell>
            {
          financialInstitutionBankAccount.bankAccountName
            ? (<span>{ financialInstitutionBankAccount.bankAccountName }</span>)
            : (<span className={ styles['no-bank-account-mapped'] }>No Banking Account mapped</span>)
        }
          </Table.Cell>
        )}
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
    </>
  );
};

export default React.memo(FinancialInstitutionBankAccountCenterTableItem);
