module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="m-l-10 pull-left relative">\n    <div class="checkbox pointer checkbox-primary">\n    <input class="check-all-js pointer" type="checkbox">\n    <label class="pointer"></label>\n    </div>\n</div>\n<div class="filter-group receipt-filter-status">\n  <div class="permission-dropdown-wrapper">\n    <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n      <span class="flag-label-js">All</span>\n      <span class="fa fa-caret-down"></span>\n    </div>\n    <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n      <div class="bank-account-list">\n      <li class="flag-all-js" data-value=\'all\'>\n        <div class=\'display-flex align-items-center\'>\n          <i class=\'\'></i>\n          <span class=\'text m-t-3 m-l-28\'>All</span>\n        </div>\n      </li>\n      <li class="flag-off-js" data-value=\'unflagged\'>\n        <div class=\'display-flex align-items-center\'>\n          <i class=\'font-18 fa fa-star-o in-dark-100 flag-selected\'></i>\n          <span class=\'text m-t-3 m-l-10\'>Unstarred</span>\n        </div>\n      </li>\n      <li class="flag-on-js" data-value=\'flagged\'>\n        <div class=\'display-flex align-items-center\'>\n          <i class=\'font-18 fa fa-star in-vivid-orange\'></i>\n          <span class=\'text m-t-3 m-l-10\'>Starred</span>\n        </div>\n      </li>\n      </div>\n    </ul>\n  </div>\n</div>\n<div class="filter-group receipt-filter-payee relative">\n    <div class="vendors-region"></div>\n</div>\n<div class="filter-group receipt-filter-payment-account">\n    <input type="text" class="form-control filter-payment-account" placeholder="Account" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.payment_account));
    
      __out.push('"/>\n</div>\n<div class="input-group filter-group transaction-filter-date-range width-20-percent">\n    <input type="text" class="form-control date-picker filter-start-date" placeholder="Start" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.receipt_date_start_string));
    
      __out.push('" />\n    <span class="input-group-addon">-</span>\n    <input type="text" class="form-control date-picker filter-end-date" placeholder="End" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.receipt_date_end_string));
    
      __out.push('" />\n</div>\n<div class="filter-group receipt-filter-uploader">\n    <input type="text" class="form-control filter-uploader" placeholder="Uploader" value="');
    
      __out.push(__sanitize(this.filter.uploader));
    
      __out.push('" />\n</div>\n<div id="qboFilterGroup" class="receipt-filter-qbo-status"></div>\n<div class="input-group filter-group transaction-filter-amount-range receipt-filter-amount-range width-15-percent">\n    <input type="number" class="form-control filter-min-amount range-filter hidden" placeholder="$ Min" value="');
    
      __out.push(__sanitize(this.filter.amount_min));
    
      __out.push('" />\n    <span class="input-group-addon range-filter hidden">-</span>\n    <input type="number" class="form-control filter-max-amount range-filter hidden" placeholder="$ Max" value="');
    
      __out.push(__sanitize(this.filter.amount_max));
    
      __out.push('" />\n    <input class="form-control filter-amount" placeholder="$ Amount" value="');
    
      __out.push(__sanitize(this.filter.amount_val));
    
      __out.push('" />\n    <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-caret-down font-14"></span>\n    </a>\n    <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n      <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n      <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n    </ul>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}