import React, { useMemo } from 'react';

import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { useGetJournal } from '@src/hooks/queries/joural_entries';
import { TID } from '@src/types/common';

import Entry from './entry';

import styles from './styles.module.scss';

const Container: React.FC<{ journalId: TID }> = ({ journalId }) => {
  const businessId = useJournalEntriesContext();
  const journalQuery = useGetJournal(journalId, businessId);
  const entries = useMemo(() => journalQuery.data?.journal?.entries || [], [journalQuery]);

  return (
    <div className={ styles.container }>
      { entries.map((entry) => <Entry key={ entry.id } entry={ entry } />) }
    </div>
  );
};

export default Container;
