import React, { useState, useCallback } from 'react';

import { useUploadAvatar } from '@src/hooks/queries/business_avatar';
import { IBusiness } from '@src/types/businesses';

import { useImageSelectionModal } from '@src/components/common/image_selection_modal';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IProfileAvatarProps {
  business: IBusiness,
}

const ProfileAvatar = ({
  business,
}: IProfileAvatarProps): JSX.Element => {
  const [avatarUrl, setAvatarUrl] = useState(business.avatarUrl);
  const ImageSelectionModal = useImageSelectionModal();
  const upload = useUploadAvatar();

  const { mutate } = upload;
  const handleFileSelected = useCallback((files) => {
    mutate({
      avatarParams: {
        advisorId:  business.id,
        avatarType: 'biz-update',
      },
      file: files[0],
    }, {
      onSuccess: (data) => {
        setAvatarUrl(`https://${window.configData.bucketName}.s3.amazonaws.com/${data.s3ObjectKey}`);
        ImageSelectionModal.props.onDone();
      },
    });
  }, [mutate, business.id, ImageSelectionModal.props]);

  return (
    <>
      <MutationStatus mutation={ upload } />
      <ImageSelectionModal.Component
        { ...ImageSelectionModal.props }
        title="Change Profile Picture"
        onDone={ handleFileSelected }
      />
      <div className="profile-avatar-wrapper m-l-auto m-r-auto">
        {
          business.avatar ? (
            <img
              alt="Avatar"
              className="avatar-img-wrapper"
              src={ avatarUrl }
            />
          ) : (
            <BusinessDefaultAvatarIcon fontSize={ 98 } />
          )
        }
      </div>
      <a
        className="change-avatar-link"
        role="button"
        tabIndex={ 0 }
        onClick={ ImageSelectionModal.open }
      >
        Change Profile Picture
      </a>
    </>
  );
};

ProfileAvatar.displayName = 'ProfileAvatar';

export {
  ProfileAvatar as default,
};
