import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const SettingsCogIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="settings-cog"
    />
  );
};

export default SettingsCogIcon;
