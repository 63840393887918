import React, { useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import { useReportContext } from '@src/hooks/contexts/report_context';
import { IChartOfAccount } from '@src/types/report_service/report';

import Text from '@src/components/ui/text';

interface IAcceptedAccountsListProps {
  chartOfAccounts: IChartOfAccount[],
}

const AcceptedAccountsList = ({
  chartOfAccounts,
}: IAcceptedAccountsListProps): JSX.Element => {
  const report = useReportContext();
  const acceptedAccountTypes = useMemo(() => (
    uniqBy(chartOfAccounts.filter((chartOfAccount) => report.acceptedChartOfAccountIds.includes(
      chartOfAccount.id,
    )), 'accountType').map((chartOfAccount) => chartOfAccount.accountType)
  ), [chartOfAccounts, report.acceptedChartOfAccountIds]);

  return (
    <div className="display-flex-column">
      {
        acceptedAccountTypes.length === 0 ? (
          <Text className="no-select-color font-semi-bold">Select Accepted Account Types</Text>
        ) : (
          <>
            <Text className="font-semi-bold">Accepted Account Types:</Text>
            <Text className="accepted-item-list">{acceptedAccountTypes.join(', ')}</Text>
          </>
        )
      }
    </div>
  );
};

export default AcceptedAccountsList;
