import React, { useMemo } from 'react';

import uniq from 'lodash/uniq';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { UnreconcileIcon } from '@src/components/utils/icomoon';

import useBulkUnreconcileModal from '../unreconcile/bulk_unreconcile_modal';

type TReconcileDocumentsIncomesBulkActionsProps = {
  paymentProcessorName: string,
  selectedRecords: ITransactionServiceDocument[],
}

const ReconcileDocumentsIncomesBulkActions = ({
  paymentProcessorName,
  selectedRecords,
}: TReconcileDocumentsIncomesBulkActionsProps) => {
  const isDisabled = useMemo(() => {
    if (selectedRecords.length === 0) return true;

    return !selectedRecords.every((txn) => txn.revenueReconciliationItem);
  }, [selectedRecords]);

  const bulkUnreconcileModal = useBulkUnreconcileModal();

  return (
    <>
      { !isDisabled && (
        <bulkUnreconcileModal.Component
          { ...bulkUnreconcileModal.props }
          paymentProcessorName={ paymentProcessorName }
          revenueReconciliationIds={
            uniq(
              selectedRecords.map((tsd) => tsd.revenueReconciliationItem.revenueReconciliationId),
            )
          }
        />
      ) }
      <ActionsDropdown.Action
        disabled={ isDisabled }
        icon={ <UnreconcileIcon fontSize={ 20 } /> }
        title="Unreconcile"
        onClick={ bulkUnreconcileModal.open }
      />
    </>
  );
};

export default ReconcileDocumentsIncomesBulkActions;
