import React from 'react';

import { TID } from '@src/types/common';

import EditManagementGroups from '@src/components/business/edit_management_group/edit_management_groups';
import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

interface IEditManagementGroupProps {
  businessId: TID,
}

const EditManagementGroupPage = ({
  businessId,
}: IEditManagementGroupProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <EditManagementGroups />
      </BusinessProvider>
    </Root>
  );
};

export default EditManagementGroupPage;
