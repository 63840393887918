import React, { useCallback, useMemo } from 'react';

import { SingleValue } from 'react-select';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import {
  IconSelectInput,
  TIconOption,
  formatIconSelectOption,
  IIconSelectInputProps,
} from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface IIconSelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange' | 'className' | 'options'>,
  IFilterFieldUIProps
{
  label?: string,
  name: keyof TFilter,
  options: TIconOption[],
}

const formatTooltip = (option: TIconOption | undefined): JSX.Element | null => {
  if (!option) return null;

  return formatIconSelectOption({...option, tooltip: true}, { context: 'value' });
};

const IconSelectFilterField = <TFilter extends TFilterData>({
  label,
  name,
  options,
  ...props
}: IIconSelectFilterFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedOption: SingleValue<TIconOption>) => {
    update(selectedOption?.value);
  }, [update]);

  const currentOption = useMemo(() => {
    if (!value) return undefined;

    return options.find((o) => o.value === value);
  }, [value, options]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption) }
      tooltipLabel={ label && `${label}: ` }
    >
      <IconSelectInput
        { ...inputProps }
        options={ options }
        value={ currentOption }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(IconSelectFilterField);
