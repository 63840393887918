import React, { FC } from 'react';

import { TFilterData } from '@src/types/filter';

import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

interface Props {
  queryParams: TFilterData,
}

const Component: FC<Props> = () => {
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('transaction_date') && (
      <Filter.DateField
        name="transaction_date"
      />
      )) }
      { (visibleColumnNames.includes('transaction_type') && (
      <Filter.TextField
        label="Txn Type"
        name="transaction_type"
        placeholder="Txn Type"
      />
      )) }
      { (visibleColumnNames.includes('name') && (
      <Filter.TextField
        label="Name"
        name="name"
        placeholder="Name"
      />
      )) }
      { (visibleColumnNames.includes('memo') && (
      <Filter.TextField
        label="Memo"
        name="memo"
        placeholder="Memo"
      />
      )) }
      { (visibleColumnNames.includes('split') && (
      <Filter.TextField
        label="Split"
        name="split"
        placeholder="Split"
      />
      )) }
      { (visibleColumnNames.includes('amount') && (
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
