import React, { useCallback } from 'react';

import {
  useGetDwollaCustomersByBusiness,
  useGetDwollaCustomersByVendor,
} from '@src/hooks/queries/dwolla_customers';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import { TOption } from '@src/components/ui_v2/inputs';
import SideView from '@src/components/ui_v2/simple_side_view';

import { useReviewACHTransferModal } from '../review_ach_transfer_modal';
import { useVerifyIdentityModal } from '../verifiy_identity_modal';

interface IFooter {
  businessId: TID,
  serviceDocument?: IAccountsPayableServiceDocument,
  paymentType: TOption | null,
  email: string,
}

const Footer = ({
  businessId,
  serviceDocument,
  paymentType,
  email,
}: IFooter) => {
  const verifyIdentity = useVerifyIdentityModal();
  const reviewAchTransfer = useReviewACHTransferModal();
  const { data: businessDwollaCustomer } = useGetDwollaCustomersByBusiness({ businessId });
  const { data: vendorDwollaCustomer } = useGetDwollaCustomersByVendor(
    { businessId, vendorId: serviceDocument?.vendor?.id || 0 },
  );
  const { open: openVerifyIdentity } = verifyIdentity;
  const { open: openReviewAchTransfer } = reviewAchTransfer;

  const handleGenerateCheckClick = useCallback(() => {
    if (paymentType?.value === 'docyt_ach') {
      if (businessDwollaCustomer?.dwollaFundingSource === null) {
        // showActiveDocytAchModal
      } else if (vendorDwollaCustomer?.dwollaFundingSource === null) {
        // showVendorAchWarningModal
      } else {
        openReviewAchTransfer();
      }
    } else {
      openVerifyIdentity();
    }
  }, [businessDwollaCustomer, vendorDwollaCustomer,
    paymentType, openReviewAchTransfer, openVerifyIdentity]);

  const handleCancelClick = useCallback(() => {
  }, []);

  return (
    <SideView.Footer>
      <verifyIdentity.Component
        { ...verifyIdentity.props }
        email={ email }
      />
      <reviewAchTransfer.Component
        { ...reviewAchTransfer.props }
        businessDwollaCustomer={ businessDwollaCustomer }
        businessId={ businessId }
        serviceDocument={ serviceDocument }
        vendorDwollaCustomer={ vendorDwollaCustomer }
      />
      <>
        <Button
          variant="link"
          onClick={ handleCancelClick }
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={ handleGenerateCheckClick }
        >
          Generate Check
        </Button>
      </>
    </SideView.Footer>
  );
};

export default React.memo(Footer);
