import React, { RefObject, useCallback, useMemo, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { useCalculateReportServiceValuesPerMonthItem, useUpdateReportServiceBudgetItem } from '@src/hooks/queries/report_service/report_service_budget_items';
import {
} from '@src/requests/report_service/report_service_budget_items';
import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { IReportServiceBudgetItemValue } from '@src/types/report_service/report_service_budget_item_values';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import MonthValues from '../month_values';

import styles from './styles.module.scss';

interface IValuePerMonthProps {
  selectedItem: string | null | undefined,
  setOpenValuePerMonth: (value: boolean) => void,
  MONTHS: Array<string>,
  item: IReportServiceBudgetItem,
  currentFormula: IReportServiceBudgetFormula,
  budget: IReportServiceBudget,
  selectedCoa: RefObject<any>,
  showBudgetItemMonthlyValue: any;
}

const ValuePerMonth = ({
  MONTHS,
  setOpenValuePerMonth,
  selectedItem,
  item,
  currentFormula,
  budget,
  selectedCoa,
  showBudgetItemMonthlyValue,
}: IValuePerMonthProps) => {
  const calculateMonthValue = useCalculateReportServiceValuesPerMonthItem();
  const [amountValue, setAmountValue] = useState<
    Array<IReportServiceBudgetItemValue>
  >(item?.reportComparerItemFormulaMonthlyValues);
  const { mutate } = calculateMonthValue;
  const updateReportService = useUpdateReportServiceBudgetItem(true);
  const { mutate: updateBudgetItem } = updateReportService;

  const monthlyInputValue = useMemo(() => {
    return item.reportComparerItemFormulaMonthlyValues.reduce((acc: any, { month, value }) => {
      acc[month] = value;
      return acc;
    }, {});
  }, [item.reportComparerItemFormulaMonthlyValues]);

  const monthlyAmountValue = useMemo(() => {
    if (!showBudgetItemMonthlyValue.reportComparerItemValues) {
      return (Object as any).fromEntries(Array.from({ length: 12 }, (_, i) => [i + 1, 0.0]));
    }

    return showBudgetItemMonthlyValue.reportComparerItemValues.reduce((
      acc: any,
      { month, value }: { month: number, value: number },
    ) => {
      acc[month] = value;
      return acc;
    }, {});
  }, [showBudgetItemMonthlyValue.reportComparerItemValues]);

  const handleChangeFormulaMonthValue = useCallback((value: number, month: number) => {
    item.formulaValue = value;
    mutate(
      {
        id:                      item.id,
        budgetId:                budget.id,
        formulaId:               currentFormula.id,
        formulaInput:            value,
        month:                   month || 1,
        formulaChartOfAccountId: selectedCoa.current,
      },
      {
        onSuccess: (response: any) => {
          setAmountValue(response);
        },
      },
    );
  }, [currentFormula, item, mutate, budget, selectedCoa]);

  const onCancel = () => {
    setOpenValuePerMonth(false);
  };

  const saveMonthlyValues = () => {
    const reportComparerItemFormulaMonthlyValues = item.budgetItemValues.map((budgetItemValue) => {
      return {
        id:    budgetItemValue.id,
        month: budgetItemValue.month,
        value: monthlyInputValue[budgetItemValue.month] || 0.0,
      };
    });

    const budgetItemValues = item.budgetItemValues.map((budgetItemValue) => {
      return {
        id:    budgetItemValue.id,
        month: budgetItemValue.month,
        value: monthlyAmountValue[budgetItemValue.month] || 0.0,
      };
    });

    updateBudgetItem(
      {
        budgetId: budget.id,
        id:       item.id,
        budgetItemValues,
        reportComparerItemFormulaMonthlyValues,
      },
      {
        onSuccess: () => {
          setOpenValuePerMonth(false);
        },
      },
    );
  };

  return (
    <Modal
      show
      className={ styles['import-budget-modal'] }
      title="Values Per Month"
    >
      <Modal.Body>
        <MonthValues
          MONTHS={ MONTHS }
          amountValue={ amountValue }
          currentFormula={ currentFormula }
          handleChangeFormulaMonthValue={ handleChangeFormulaMonthValue }
          monthlyAmountValue={ monthlyAmountValue }
          monthlyInputValue={ monthlyInputValue }
          selectedItem={ selectedItem }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="Cancel"
          onClick={ onCancel }
        />

        <Button
          bsColor="blue"
          type="submit"
          onClick={ saveMonthlyValues }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useValuePerMonthModel = makeUseModal(ValuePerMonth);

export {
  useValuePerMonthModel,
  ValuePerMonth as default,
};
