module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="documents-cat-header receipt-cat-header text-center m-t-10">\n    <h3 class="pull-left font-24 font-bold">Receipt List</h3>\n    <div class="pull-right header-input-area m-t-10">\n        <button class="btn add-receipt-btn font-23 pull-right">\n            <i class="icon icon-plus"></i>\n        </button>\n    </div>\n    <ul class="nav sub-header-menu receipts-sub-menu inline-block font-semi-bold">\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'unverified' ? 'active' : ''));
    
      __out.push('">\n            <a class="unverified-receipts-link" href="');
    
      __out.push(__sanitize(this.unverifiedURL));
    
      __out.push('">\n                UPLOADED (');
    
      __out.push(__sanitize(this.service.get('unverified_receipts_count')));
    
      __out.push(')\n            </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'verified' ? 'active' : ''));
    
      __out.push('">\n            <a class="verified-receipts-link" href="');
    
      __out.push(__sanitize(this.verifiedURL));
    
      __out.push('">\n                APPROVED (');
    
      __out.push(__sanitize(this.service.get('verified_receipts_count')));
    
      __out.push(')\n            </a>\n        </li>\n    </ul>\n    <hr class="m-t-5" />\n</div>\n<div class=\'pointer new-data-reload-container hidden\'>\n    <a class=\'pointer new-data-link\'>New data available. Click here to reload.</a>\n</div>\n<ul class="pointer bulk-action-result-container m-t-5 m-b-5">\n    <div class="font-16 result-item-wrapper success-result-item">\n        <i class="icon icon-check_circle_large"></i>\n        <span class=\'success-result m-l-4\'>2 Receipts were forwarded</span>\n    </div>\n    <hr class="m-t-10 m-b-10 sparation-line" />\n    <div class="font-16 result-item-wrapper fail-result-item">\n        <i class="icon icon-alert in-red-400"></i>\n        <span class=\'fail-result m-l-4 in-red-400\'>1 receipt could not be forward because of missing details.</span>\n    </div>\n</ul>\n<div class="display-flex m-b-20">\n    <div id="bulk-action-region"></div>    \n</div>\n<div class="receipts-queue-table-wrapper">\n    <div id="filter-region"></div>\n    <div id="receipts-region"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}