import React from 'react';

import compact from 'lodash/compact';
import pluralize from 'pluralize';

import { Button } from '@src/components/ui_v2/buttons';

import { IAddAdjustmentValues } from '../schema';

interface ICategorySplitsValueProps {
  value: IAddAdjustmentValues['categorySplits'],
  onEdit: () => void,
}

const CategorySplitsValue = ({
  value,
  onEdit,
}: ICategorySplitsValueProps): JSX.Element => {
  if (!value || value.length === 0) {
    return <Button variant="link" onClick={ onEdit }>Select Category</Button>;
  }

  if (value.length === 1) {
    const categoryName = compact([
      value[0].accountingClassName,
      value[0].chartOfAccountName,
    ]).join(' | ');

    return (
      <>
        { categoryName }
        <br />
        <Button variant="link" onClick={ onEdit }>Edit</Button>
      </>
    );
  }

  return (
    <Button variant="link" onClick={ onEdit }>
      { `${value.length} ${pluralize('Split', value.length)}` }
    </Button>
  );
};

export default React.memo(CategorySplitsValue);
