import classNames, { Argument } from 'classnames';
import omit from 'lodash/omit';

interface IUIStyleProps {
  className?: string
  display?: 'inline-flex',
  fontSize?: number,
  fontVariant?: 'bold' | 'semi-bold',
  inColor?: 'blue-400' | 'orange-500' | 'red-400' | 'grey-1050' | 'grey-800' | 'grey-500' | 'black',
  justifyContent?: 'between'
  mb?: number,
  ml?: number | 'auto',
  mr?: number,
  mt?: number,
  pb?: number,
  pl?: number,
  pointer?: boolean,
  pr?: number,
  pt?: number,
  pull?: 'right' | 'left',
  relative?: boolean,
  nowrap?: boolean,
  textAlign?: 'right' | 'left' | 'center',
  verticalAlign?: 'middle-important' | 'middle',
  widthPercent?: 3 | 5 | 8 | 10 | 12 | 13 | 14 | 15 | 17 | 18 | 20 | 22 | 24 | 25 | 26 | 28 | 30 |
    33 | 40 | 45 | 50 | 60 | 70 | 80 | 100,
}

const uiStyleClassNames = (
  baseClassName: string | undefined,
  {
    className,
    display,
    fontSize,
    fontVariant,
    inColor,
    justifyContent,
    mb,
    ml,
    mr,
    mt,
    nowrap,
    pb,
    pl,
    pointer,
    pr,
    pt,
    pull,
    relative,
    textAlign,
    verticalAlign,
    widthPercent,
  }: IUIStyleProps,
  ...args: Argument[]
): string => {
  return classNames(
    baseClassName,
    display && `display-${display}`,
    fontSize && `font-${fontSize}`,
    fontVariant && `font-${fontVariant}`,
    inColor && `in-${inColor}`,
    justifyContent && `justify-content-${justifyContent}`,
    mb !== undefined && `m-b-${mb}`,
    ml !== undefined && `m-l-${ml}`,
    mr !== undefined && `m-r-${mr}`,
    mt !== undefined && `m-t-${mt}`,
    nowrap && 'no-wrap-text',
    pb !== undefined && `p-b-${pb}`,
    pl !== undefined && `p-l-${pl}`,
    pointer && 'pointer',
    pr !== undefined && `p-r-${pr}`,
    pt !== undefined && `p-t-${pt}`,
    pull && `pull-${pull}`,
    relative && 'relative',
    textAlign && `text-${textAlign}`,
    verticalAlign && `vertical-align-${verticalAlign}`,
    widthPercent && `width-${widthPercent}-percent`,
    className,
    ...args,
  );
};

const cleanUIStyleProps = <Type extends IUIStyleProps>(
  props: Type,
): Omit<Type, keyof IUIStyleProps> => {
  return omit(
    props,
    [
      'className',
      'display',
      'fontSize',
      'fontVariant',
      'inColor',
      'justifyContent',
      'mb',
      'ml',
      'mr',
      'mt',
      'nowrap',
      'pb',
      'pl',
      'pointer',
      'pr',
      'pt',
      'pull',
      'relative',
      'textAlign',
      'verticalAlign',
      'widthPercent',
    ],
  );
};

const uiStyleProps = <Type extends IUIStyleProps>(
  baseClassName: string | undefined,
  props: Type,
  ...args: Argument[]
): [string, Omit<Type, keyof IUIStyleProps>] => {
  const className = uiStyleClassNames(baseClassName, props, args);

  return [
    className,
    cleanUIStyleProps(props),
  ];
};

export {
  IUIStyleProps,
  cleanUIStyleProps,
  uiStyleClassNames,
  uiStyleProps,
};
