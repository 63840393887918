module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var coa, i, industry, j, len, len1, ref, ref1;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-lg select-category-modal-dialog\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title pull-left m-l-10 font-bold">Select Category</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <div class="relative">\n                <div class="flex-wrapper m-b-5 m-l-20 m-t-5">\n                    <label class="form-label font-16 text-right m-t-3 width-150px">Industry: </label>\n                    <select class="form-control industry-select-js width-180px">\n                        ');
    
      ref = configData.industries;
      for (i = 0, len = ref.length; i < len; i++) {
        industry = ref[i];
        __out.push('\n                            <option value="');
        __out.push(__sanitize(industry.standard_category_id));
        __out.push('" ');
        __out.push(__sanitize(industry.standard_category_id === this.industry_id ? 'checked' : ''));
        __out.push('>\n                                ');
        __out.push(__sanitize(industry.name));
        __out.push('\n                            </option>\n                        ');
      }
    
      __out.push('\n                    </select>\n                </div>\n                <div class="flex-wrapper m-b-5 m-l-20 m-t-5 relative">\n                    <label class="form-label font-16 text-right m-t-3 width-150px">Categories: </label>\n                    <input class="form-control width-180px" placeholder="Type Name" id="coa-search-input"/>\n                </div>\n            </div>\n            ');
    
      if (this.coas.length === 0) {
        __out.push('\n                <div class="text-center m-t-40">\n                    <p class="font-14">No Categories selected yet. All selected categories will appear here.</p>\n                </div>\n            ');
      } else {
        __out.push('\n            <div class="contacts-list padding-10">\n                <h3 class="font-16 font-semi-bold">Selected Categories:</h3>\n                <table class="table table-responsive">\n                    <tbody>\n                      ');
        ref1 = this.coas;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          coa = ref1[j];
          __out.push('\n                      <tr>\n                        <td class="font-bold">');
          __out.push(__sanitize(coa.name));
          __out.push('</td>\n                        <td>');
          __out.push(__sanitize(coa.industry_name));
          __out.push('</td>\n                        <td>');
          __out.push(__sanitize(coa.acc_type_name));
          __out.push('</td>\n                        <td>');
          __out.push(__sanitize(coa.sub_type));
          __out.push('</td>\n                        <td><a class="pointer remove-coa-js" data-id="');
          __out.push(__sanitize(coa.id));
          __out.push('">Remove</a></td>\n                      </tr>\n                      ');
        }
        __out.push('\n                    </tbody>\n                </table>\n            </div>\n            ');
      }
    
      __out.push('\n            <div class="m-t-10 text-right">\n                <a class="view-suggestions-js pointer">View Suggestions</a>\n            </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js ');
    
      __out.push(__sanitize(this.coas.length === 0 && !this.empty_enabled ? 'disabled' : ''));
    
      __out.push('" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}