import React from 'react';

import { MONTHS } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

interface IBudgetDetailsTableFootProps {
  formulaEnabled: string | boolean | undefined,
  totalAmounts: number[],
}

const BudgetDetailsTableFoot = ({
  formulaEnabled,
  totalAmounts,
}: IBudgetDetailsTableFootProps): JSX.Element => (
  <Table.Foot>
    <Table.Row>
      <Table.Cell />
      <Table.Cell />
      {
        formulaEnabled
        && <Table.Cell />
      }
      {
        formulaEnabled
        && <Table.Cell />
      }
      <Table.Cell fontVariant="semi-bold" textAlign="right">Total</Table.Cell>
      <Table.CurrencyAmountCell
        fontVariant="semi-bold"
        value={ totalAmounts.reduce((partialSum, a) => partialSum + a, 0) }
      />
      {MONTHS.map((month, index) => (
        <Table.CurrencyAmountCell
          key={ month }
          fontVariant="semi-bold"
          value={ totalAmounts[index] }
        />
      ))}
    </Table.Row>
  </Table.Foot>
);

export default BudgetDetailsTableFoot;
