import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IAllTransactionsIconProps extends IIcomoonIconProps {
  variant?: 'purple',
}

const AllTransactionsIcon = ({
  variant,
  ...props
}: IAllTransactionsIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ variant ? `all-transactions-${variant}` : 'all-transactions' }
      pathsNumber={ 4 }
    />
  );
};

export default AllTransactionsIcon;
