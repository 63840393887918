import React from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { BankCircleIcon } from '@src/components/utils/icomoon';

import DeleteStatementAction from '../../balance_sheet_statement_state/items/actions/delete_statement_action';
import UnverifyStatementAction from '../../balance_sheet_statement_state/items/actions/unverify_statement_action';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IDetailsModalTitleProps {
  businessId: TID,
  balanceSheetStatement?: IBalanceSheetStatement,
  title: string,
  onActionSuccess?: () => void,
}

const DetailsModalTitle = ({
  businessId,
  balanceSheetStatement,
  title,
  onActionSuccess,
}: IDetailsModalTitleProps): JSX.Element => {
  let badge;
  if (balanceSheetStatement?.state
    === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.UPLOADED) {
    badge = (
      <span className={ styles['approved-state-badge'] }>
        Document Uploaded
      </span>
    );
  } else if (balanceSheetStatement?.state
    === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.VERIFIED) {
    badge = (
      <span className={ styles['verified-state-badge'] }>
        Document Verified
      </span>
    );
  }

  return (
    <div>
      <DetailsPageTitle
        isSmallTitle
        breadcrumbs={
          [
            { title: balanceSheetStatement?.businessName },
            { title: 'Reconciliation Center' },
            { title: 'Other Documents' },
          ]
        }
        className={ styles['bank-statement-details-title'] }
        markIcon={ <BankCircleIcon className={ styles['bank-circle-icon'] } /> }
        title={ title }
      >
        { badge }
        { balanceSheetStatement && (
          <ActionsDropdown>
            { balanceSheetStatement?.state === 'verified' && (
              <UnverifyStatementAction
                businessId={ businessId }
                statement={ balanceSheetStatement }
                onUnverified={ onActionSuccess }
              />
            ) }
            <DeleteStatementAction
              businessId={ businessId }
              statement={ balanceSheetStatement }
              onDeleted={ onActionSuccess }
            />
          </ActionsDropdown>
        )}
      </DetailsPageTitle>
    </div>
  );
};

export default DetailsModalTitle;
