import React, { useCallback } from 'react';

import { useUnapproveBankStatement } from '@src/hooks/queries/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { UnverifyStatementIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IUnapproveActionProps {
  bankStatement: IBankStatement,
  onUnapproved?: () => void,
}

const UnapproveAction = ({
  bankStatement,
  onUnapproved,
}: IUnapproveActionProps) => {
  const unapprove = useUnapproveBankStatement();
  const { mutate } = unapprove;

  const handleConfirm = useCallback(() => {
    mutate(
      { id: bankStatement.id },
      { onSuccess: onUnapproved },
    );
  }, [bankStatement.id, mutate, onUnapproved]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ unapprove } />
      <confirmModal.Component
        text={
          bankStatement.hasNextApprovedStatements ? (
            <span>
              Bank feed has been approved beyond this statement. If you unapprove this statement,
              { ' ' }
              all statements recent to this statement will need re-verification.
            </span>
          ) : (
            <span>Are you sure you want to proceed and unapprove this bank statement?</span>
          )
        }
        title={ bankStatement.hasNextApprovedStatements ? 'Critical Warning' : 'Unapprove Bank Statement' }
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <UnverifyStatementIcon fontSize={ 18 } /> }
        name="Unapprove"
        onClick={ confirmModal.open }
      />
    </>
  );
};

export default UnapproveAction;
