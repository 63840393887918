import React from 'react';

import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import Dropdown from '@src/components/ui/dropdown';
import { EllipsisIcon } from '@src/components/utils/fa_icons';

import DocytNetworkItemActions from './docyt_network_item_actions';

interface IBusinessInboxListItemActionsProps {
  vendor: IGlobalVendorWithBusinessInfo,
}

const DocytNetworkActions = ({
  vendor,
}: IBusinessInboxListItemActionsProps): JSX.Element => {
  return (
    <div className="pointer dropdown-toggle">
      <Dropdown>
        <Dropdown.LinkToggle
          className="in-grey-800"
          fontSize={ 15 }
          fontVariant="bold"
          id={ `vendor-${vendor.id}-action-dropdown-btn` }
        >
          <EllipsisIcon className="m-l-5" variant="horizontal" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu
          alignment="right"
          aria-labelledby={ `vendor-${vendor.id}-action-dropdown-btn` }
          className="toggle-action-dropdown"
          fontSize={ 24 }
        >
          <DocytNetworkItemActions vendor={ vendor } />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DocytNetworkActions;
