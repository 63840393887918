import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import Delete from './delete_action';
import DocumentRequest from './document_request_action';
import Download from './download_action';
import Forward from './forward';
import MarkAsRead from './mark_as_read';
import Rename from './rename_action';

interface IBusinessInboxItemActionsProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemActions = ({
  document,
}: IBusinessInboxItemActionsProps): JSX.Element => {
  const business = useBusinessContext();

  return (
    <>
      <MarkAsRead document={ document } />
      <Forward document={ document } />
      <Download document={ document } />
      <Rename document={ document } />
      <ActionsDropdown.Divider />
      <DocumentRequest business={ business } document={ document } />
      <ActionsDropdown.Divider />
      <Delete document={ document } />
    </>
  );
};

export default BusinessInboxItemActions;
