import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getReconciliationCenterByBusinessId,
  getReconciliationCenterExceptionMessages,
  IGetReconciliationCenterByBusinessIdParams,
  IGetReconciliationCenterByBusinessIdResponse,
  IGetReconciliationCenterExceptionMessagesResponse,
} from '@src/requests/reconciliation_centers';

const useReconciliationCenterByBusinessId = (
  params: IGetReconciliationCenterByBusinessIdParams,
) => {
  return useQuery<IGetReconciliationCenterByBusinessIdResponse, Error>(
    [QueryKey.reconciliationCenterByBusinessId, params],
    () => getReconciliationCenterByBusinessId(params),
  );
};

const useReconciliationCenterExceptionMessages = () => {
  return useQuery<IGetReconciliationCenterExceptionMessagesResponse[], Error>(
    [QueryKey.reconciliationCenterExceptionMessages],
    () => getReconciliationCenterExceptionMessages()
  );
};

export {
  useReconciliationCenterByBusinessId,
  useReconciliationCenterExceptionMessages,
};
