import React from 'react';

import { Button } from '@src/components/ui/buttons';

import { IAccountData } from './schema';

import styles from '../../styles.module.scss';

interface IBankAccountEditFieldProps {
  value: IAccountData,
  isEditable: boolean,
  onEdit: () => void;
}

const AccountEditField = ({
  value,
  isEditable,
  onEdit,
}: IBankAccountEditFieldProps) => {
  return (
    <div className={ styles['selected-bank-accounts-field'] }>
      <div>
        <span className={ styles['account-name'] }>
          {`${value?.name} - ${value?.last4}`}
        </span>
      </div>
      {
        isEditable && (
          <Button
            bsStyle="link"
            className="pointer font-bold"
            onClick={ onEdit }
          >
            Edit
          </Button>
        )
      }
    </div>
  );
};

export default AccountEditField;
