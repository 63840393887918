import { TID, TAmount } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

interface IWithdrawAmountParams {
  businessId: TID,
  amount: TAmount,
}

const postWithdrawAmount = (
  params: IWithdrawAmountParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/businesses/${params.businessId}/withdraw`,
    underscoreKeys({ ...params }),
  );
};

export {
  IWithdrawAmountParams,
  postWithdrawAmount,
};
