module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider m-b-20">\n  <div class="documents-cat-header font-14 font-semi-bold in-blue-400">\n    <a class="text-decoration-blank m-l-6" href="/businesses/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/reports?category=');
    
      __out.push(__sanitize(this.templateInfo.get('category')));
    
      __out.push('"> ');
    
      __out.push(__sanitize(Docyt.Common.Constants.REPORT_CATEGORY_NAMES[this.templateInfo.get('category')]));
    
      __out.push(' </a>\n    <div class="client__breadcrumbs-item font-18 in-grey-600"></div>\n  </div>\n  <div id="data-export-animation"></div>\n  <div class="display-flex align-items-center">\n    <div class="font-bold font-26 m-r-auto">');
    
      __out.push(__sanitize(this.report.get('name')));
    
      __out.push('</div>\n    ');
    
      if (this.report.isDailyReport()) {
        __out.push('\n      <div class="permission-dropdown-wrapper width-150px">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n          <span class="period-label-js">Daily</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n          <div class="bank-account-list">\n          <li class="period-daily-js" data-value=\'daily\'>\n              <span class=\'text m-t-3 m-l-10\'>Daily</span>\n          </li>\n          <li class="period-monthly-js" data-value=\'flagged\'>\n              <span class=\'text m-t-3 m-l-10\'>Monthly</span>\n          </li>\n          </div>\n        </ul>\n      </div>\n    ');
      }
    
      __out.push('\n    <div class="last-month-option-region m-l-20 ');
    
      if (!this.report.isDailyReport()) {
        __out.push(__sanitize('flex-1'));
      }
    
      __out.push('"></div>\n    <div class="relative m-t-8">\n      <a class="btn" type="button" id="custom-report-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-ellipsis-h font-24 in-grey-800"></span>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-right font-24 width-180px" role="menu" aria-labelledby="custom-report-action-dropdown-btn">\n        <li>\n          <a class="rename-btn pointer">\n            <div class="dropdown-menu-action-item">\n              <div class=\'action-icon\'><i class="icon icon-edit action-icon"></i></div>\n              <span>Rename Report</span>\n            </div>\n          </a>\n        </li>\n        ');
    
      if (!this.report.get('edit_mapping_disabled')) {
        __out.push('\n          <li>\n            <a href="/businesses/');
        __out.push(__sanitize(this.businessId));
        __out.push('/reports/');
        __out.push(__sanitize(this.report.get('slug')));
        __out.push('/edit_map" tabindex="-1">\n              <div class=\'dropdown-menu-action-item\'>\n                <div class=\'action-icon\'><i class="icon icon-mapping action-icon"></i></div>\n                <span>Edit Mapping</span>\n              </div>\n            </a>\n          </li>\n        ');
      }
    
      __out.push('\n\n        ');
    
      if (this.report.get('edit_mapping_disabled')) {
        __out.push('\n          <li class= ');
        __out.push(__sanitize(this.report.renderDrillDownBtn()));
        __out.push(' >\n            <a class= "');
        __out.push(__sanitize(this.report.enableExportBtn()));
        __out.push(' pointer" type="button" data-value="');
        __out.push(__sanitize(false));
        __out.push('">\n              <div class="dropdown-menu-action-item">\n                <div class=\'action-icon\'><i class="icon icon-Export-as-Excel action-icon"></i></div>\n                <span>Export as Excel</span>\n              </div>\n            </a>\n\n            ');
        if (this.report.isDepartmentReport()) {
          __out.push('\n              <ul class="dropdown-menu" style="border-radius: 6px 0 6px 6px;">\n                <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
          __out.push(__sanitize(true));
          __out.push('" style="padding: 10px 20px;">Include drill down</a></li>\n                <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
          __out.push(__sanitize(false));
          __out.push('" style="padding: 10px 20px;">Exclude drill down</a></li>\n              </ul>\n            ');
        }
        __out.push('\n\n          </li>\n        ');
      } else {
        __out.push('\n          <li class="dropdown-submenu">\n            <a class="pointer">\n              <div class="dropdown-menu-action-item">\n                <div class=\'action-icon\'><i class="icon icon-pdf-1 action-icon"></i></div>\n                <span>Export as Excel</span>\n              </div>\n            </a>\n            <ul class="dropdown-menu" style="border-radius: 6px 0 6px 6px;">\n              <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
        __out.push(__sanitize(true));
        __out.push('" style="padding: 10px 20px;">Include drill down</a></li>\n              <li><a class="export-excel-btn dropdown-item pointer" type="button" data-value="');
        __out.push(__sanitize(false));
        __out.push('" style="padding: 10px 20px;">Exclude drill down</a></li>\n            </ul>\n          </li>\n        ');
      }
    
      __out.push('\n      <li ');
    
      __out.push(__sanitize(this.isExportAsPdfEnabled ? '' : 'hidden'));
    
      __out.push('>\n        <a class="export-pdf-btn pointer" type="button" data-value="');
    
      __out.push(__sanitize(false));
    
      __out.push('">\n          <div class="dropdown-menu-action-item">\n            <div class=\'action-icon\'><i class="icon icon-Export-as-Excel action-icon"></i></div>\n            <span>Export as PDF</span>\n          </div>\n        </a>\n      </li>\n      </ul>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}