import { atomFamily } from 'recoil';

const isExportCSVCreatedFamily = atomFamily<boolean, string>({
  key:     'ExportCSV',
  default: false,
});

export {
  isExportCSVCreatedFamily,
};
