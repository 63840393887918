import { createContext, useContext } from 'react';

import { IAiChatBot } from '../types';

const AiChatBotContext = createContext<IAiChatBot | null>(null);

const useAiChatBotContext =
  () => <IAiChatBot>useContext(AiChatBotContext);

export {
  AiChatBotContext,
  useAiChatBotContext,
};
