import React from 'react';

import { TDate, TAmount } from '@src/types/common';
import { currencyFormater2 } from '@src/utils/currency';
import { formatDate } from '@src/utils/date_helpers';

import FileView from '../bank_statement_file_view';

import styles from './styles.module.scss';

interface IFileViewProps {
  reconciliationPaymentAccountName: string,
  date?: TDate,
  closingBalance?: TAmount,
  possibleDuplicateMark?: boolean,
  fileName?: string,
  fileUrl: string | Uint8Array
}

const BankStatementView = ({
  reconciliationPaymentAccountName,
  date,
  closingBalance,
  possibleDuplicateMark = false,
  fileName,
  fileUrl,
}: IFileViewProps): JSX.Element => {
  return (
    <div className={ styles['duplicated-statement-file-view'] }>
      <FileView
        className="statement-file-view"
        fileName={ fileName }
        fileUrl={ fileUrl }
        showStatementName={ false }
      />

      <div className={ styles['duplicated-statement-file-info'] }>
        <div className={ styles['payment-account-name'] }>
          <span>{ reconciliationPaymentAccountName }</span>
          { possibleDuplicateMark && <span className="in-red-400 font-11">Possible Duplicate</span> }
        </div>
        <div className={ styles['statement-date'] }>
          <span>Date:</span>
          { ' ' }
          <span>{ formatDate(date) }</span>
        </div>
        <div className="font-bold">
          <span>Closing Balance:</span>
          { ' ' }
          <span>{ closingBalance && currencyFormater2(closingBalance) }</span>
        </div>
      </div>
    </div>
  );
};

export default BankStatementView;
