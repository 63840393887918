import React from 'react';

import { receiptListPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: TID
}

const CorporateCardsSidebarMenuItems = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.CORPORATE_CARDS }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ receiptListPath(businessId) }
          title="All Receipts"
        />
        <ServiceSidebar.Item
          href=""
          title="Missing Receipts"
        />
        <ServiceSidebar.Item
          href=""
          title="Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default CorporateCardsSidebarMenuItems;
