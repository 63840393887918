import { InfiniteData, useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IGetBankFeedTransactionServiceDocumentsResponse } from '@src/requests/bank_feed_transaction_service_documents';
import {
  ICreateBankStatementParams,
  IUpdateBankStatementParams,
  ICreateBankStatementResponse,
  IGetBankStatementsParams,
  IGetBankStatementsResponse,
  IDeleteBankStatementParams,
  IBankStatementParams,
  getBankStatements,
  IGetReviewedBankStatementsParams,
  IGetReviewedBankStatementsResponse,
  IGetBankStatementResponse,
  createBankStatement,
  updateBankStatement,
  deleteBankStatement,
  approveBankStatement,
  verifyBankStatement,
  getBankStatement,
  getBankStatementByDocytId,
  getNextBankStatement,
  postUnverifyBankStatement,
  postMarkAsNoAvailableBankStatement,
  getReviewedBankStatements,
  IUnverifyBankStatementParams,
  IMarkAsNoAvailableBankStatementParams,
  IResolveDuplicateBankStatementParams,
  postResolveDuplicateBankStatement,
  postUnapproveBankStatement,
  postUnmarkNoStatementAvailable,
  ISetBankStatementToTransactionServiceDocumentsParams,
  postSetBankStatementToTransactionServiceDocuments,
  IRequestBankStatementParams,
  postRequestBankStatement,
} from '@src/requests/bank_statements';
import { IBankStatement, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { createUseGetInfiniteCollection, updateItemsInInfiniteCollection } from './infinite_collection_queries';

const useGetBankStatements = createUseGetInfiniteCollection<
  IBankStatement,
  IGetBankStatementsParams,
  IGetBankStatementsResponse
>({
  queryKey: QueryKey.bankStatement,
  request:  getBankStatements,
});

const useCreateBankStatement = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateBankStatementResponse, Error, ICreateBankStatementParams>(
    createBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useUpdateBankStatement = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, IUpdateBankStatementParams >(
    updateBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useApproveBankStatement = (fromDetails?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, IBankStatementParams >(
    approveBankStatement,
    {
      onSuccess: () => {
        if (fromDetails) return;
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useVerifyBankStatement = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, IBankStatementParams >(
    verifyBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useDestroyBankStatement = (fromDetails?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteBankStatementParams>(
    deleteBankStatement,
    {
      onSuccess: () => {
        if (fromDetails) return;
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
        window.Docyt.vent.trigger('open_items:information:updated');
      },
    },
  );
};

const useGetBankStatement = (businessId: TID, id: TID) => {
  return useQuery<IGetBankStatementResponse, Error>(
    [QueryKey.bankStatementById, id],
    () => getBankStatement(businessId, id),
  );
};

const useGetBankStatementByDocytId = (businessId?: TID, docytId?: string, enabled?: boolean) => {
  return useQuery<IGetBankStatementResponse, Error>(
    [QueryKey.bankStatementByDocytId, businessId, docytId],
    () => getBankStatementByDocytId(businessId, docytId),
    {
      enabled: enabled && Boolean(businessId),
    },
  );
};

const useGetNextBankStatement = (id?: TID, enabled?: boolean) => {
  return useQuery<IGetBankStatementResponse, Error>(
    [QueryKey.nextBankStatement, id],
    () => getNextBankStatement(id),
    {
      enabled: enabled && Boolean(id),
    },
  );
};

const useGetReviewedBankStatements = createUseGetInfiniteCollection<
  IReviewedBankStatementsData,
  IGetReviewedBankStatementsParams,
  IGetReviewedBankStatementsResponse
>({
  queryKey: QueryKey.reviewedBankStatement,
  request:  getReviewedBankStatements,
});

const useUnverifyBankStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IUnverifyBankStatementParams>(
    postUnverifyBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useUnapproveBankStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IBankStatementParams>(
    postUnapproveBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useUnmarkNoStatementAvailableBankStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IBankStatementParams>(
    postUnmarkNoStatementAvailable,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useMarkAsNoAvailableBankStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IMarkAsNoAvailableBankStatementParams>(
    postMarkAsNoAvailableBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useResolveDuplicateBankStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IResolveDuplicateBankStatementParams>(
    postResolveDuplicateBankStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankStatement);
        queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
      },
    },
  );
};

const useSetBankStatementToTransactionServiceDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IGetBankStatementResponse, Error, ISetBankStatementToTransactionServiceDocumentsParams>(
      postSetBankStatementToTransactionServiceDocuments,
      {
        onSuccess: (response, params) => {
          queryClient.invalidateQueries(QueryKey.bankFeedInformation);
          queryClient.setQueriesData(
            QueryKey.bankFeedTransactionServiceDocument,
            (
              data?: InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse>,
            ): InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse> => {
              return updateItemsInInfiniteCollection<
                ITransactionServiceDocument,
                IGetBankFeedTransactionServiceDocumentsResponse
              >(
                data,
                params.transactionIds,
                {
                  bankStatement: params.selected ? response.bankStatement : undefined,
                },
              );
            },
          );
        },
      },
    );
};

const useRequestBankStatement = () => {
  return useMutation<void, Error, IRequestBankStatementParams>(
    postRequestBankStatement,
  );
};

export {
  useGetBankStatements,
  useCreateBankStatement,
  useUpdateBankStatement,
  useDestroyBankStatement,
  useApproveBankStatement,
  useVerifyBankStatement,
  useGetBankStatement,
  useGetBankStatementByDocytId,
  useGetNextBankStatement,
  useGetReviewedBankStatements,
  useUnverifyBankStatement,
  useMarkAsNoAvailableBankStatement,
  useResolveDuplicateBankStatement,
  useUnapproveBankStatement,
  useUnmarkNoStatementAvailableBankStatement,
  useSetBankStatementToTransactionServiceDocuments,
  useRequestBankStatement,
};
