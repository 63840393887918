import {
  IAccountsReceivablePayment,
  IAccountsReceivablePaymentQueryFilter,
  TAccountsReceivablePaymentsSortColumn,
} from '@src/types/accounts_receivable_payments';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountsReceivablePaymentsParams {
  businessId: TID,
  filter?: IAccountsReceivablePaymentQueryFilter,
  orderColumn?: TAccountsReceivablePaymentsSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetAccountsReceivablePaymentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IAccountsReceivablePayment[],
}

const getAccountsReceivablePayments = (
  params: IGetAccountsReceivablePaymentsParams,
): Promise<IGetAccountsReceivablePaymentsResponse> => {
  return apiGet(
    '/api/v1/accounts_receivable_payments',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.accountsReceivablePayments as IAccountsReceivablePayment[],
    };
  });
};

export {
  IGetAccountsReceivablePaymentsParams,
  IGetAccountsReceivablePaymentsResponse,
  getAccountsReceivablePayments,
};
