import React, { useCallback } from 'react';

import { IPracticeInformation } from '@src/types/practice_informations';

import Dropdown from '@src/components/ui/dropdown';
import { GoBusinessHomeIcon } from '@src/components/utils/icomoon';

interface IMyPracticeGotoBusinessHomeActionProps {
  information: IPracticeInformation,
}

const MyPracticeGotoBusinessHomeAction = ({
  information,
}: IMyPracticeGotoBusinessHomeActionProps) => {
  const handleSelect = useCallback(() => {
    if (information.accountingFirmBusinessId != null) {
      window.open(`/businesses/${information.accountingFirmBusinessId}/clients/${information.id}`, '_blank');
    } else {
      window.open(`/businesses/${information.id}`, '_blank');
    }
  }, [information]);

  return (
    <Dropdown.Item className="display-flex-important align-items-center" onSelect={ handleSelect }>
      <GoBusinessHomeIcon fontSize={ 16 } mr={ 22 } />
      { ' ' }
      Go to Business Home
    </Dropdown.Item>
  );
};

export default MyPracticeGotoBusinessHomeAction;
