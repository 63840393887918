import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import Table from '@src/components/ui_v2/table';

interface IVendorDetailsViewProps {
  businessVendor: IBusinessVendor,
}

const VendorDetailsView = ({
  businessVendor,
}: IVendorDetailsViewProps): JSX.Element | null => {
  return (
    <>
      <div className="p-l-8">Vendor Details</div>
      <div className="bottom-divider">
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell widthPercent={ 30 }>
                <div className="font-bold">{ businessVendor.name }</div>
                {
                  businessVendor.docytId && (
                    <div>
                      @
                      {businessVendor.docytId}
                    </div>
                  )
                }
              </Table.Cell>
              <Table.Cell widthPercent={ 20 }>
                { businessVendor.phone }
              </Table.Cell>
              <Table.Cell widthPercent={ 30 }>
                { businessVendor.email }
              </Table.Cell>
              <Table.Cell widthPercent={ 20 }>
                { businessVendor.dwollaFundingSource?.last4
                  ? `xxxx ${businessVendor.dwollaFundingSource?.last4}`
                  : '' }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </>
  );
};

export default VendorDetailsView;
