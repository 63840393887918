import React from 'react';

import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IDetailsRegionTitleHelpProps {
  text?: React.ReactNode,
}

const DetailsRegionTitleHelp = ({
  text,
}: IDetailsRegionTitleHelpProps) => {
  return (
    <Tooltip.Hover as="span" className={ styles.help } content={ text }>
      <DocytSupportIcon />
    </Tooltip.Hover>
  );
};

export default DetailsRegionTitleHelp;
