import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ExportAsExcelIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="Export-as-Excel"
      pathsNumber={ 11 }
    />
  );
};

export default ExportAsExcelIcon;
