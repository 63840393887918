import React from 'react';

import Root from '@src/components/root';

import { DataExportDetails } from './details';

const DataExportPage = (): JSX.Element => {
  return (
    <Root>
      <DataExportDetails />
    </Root>
  );
};

export {
  DataExportPage as default,
};
