import React from 'react';

import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IRelatedTransactionListProps {
  relatedTransactions: ITransactionServiceDocument[],
  transactionId: TID,
}

const RelatedTransactionItemList = ({
  relatedTransactions,
  transactionId,
}: IRelatedTransactionListProps): JSX.Element => {
  return (
    <Table wrapperClassName="related-transactions-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell>Docyt ID</Table.HCell>
          <Table.HCell>Date</Table.HCell>
          <Table.HCell>Description</Table.HCell>
          <Table.HCell textAlign="right" widthPercent={ 15 }>Amount</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          relatedTransactions.map((relatedTransaction) => {
            if (transactionId === relatedTransaction.id) return null;

            return (
              <Item
                key={ relatedTransaction.id }
                relatedTransaction={ relatedTransaction }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default RelatedTransactionItemList;
