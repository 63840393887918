import React, { useCallback, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateBankStatement } from '@src/hooks/queries/bank_statements';
import { IBankStatement, IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

type TOpenValue = {
  rcPaymentAccountId?: TID,
  closingBalance?: string,
  bankStatement?: IBankStatement | IReviewedBankStatementState,
}

interface IStartDateErrorProps extends IUseModalProps<TOpenValue> {
  businessId: TID,
  needUpdate?: boolean
}

const StartDateError = ({
  businessId,
  needUpdate = true,
  isOpen,
  openValue,
  onCancel,
}: IStartDateErrorProps) => {
  const { rcPaymentAccountId, closingBalance, bankStatement } = openValue || {};

  const update = useUpdateBankStatement();
  const { mutate: updateBankStatement } = update;

  useEffect(() => {
    if (bankStatement?.id === undefined || !needUpdate) return;

    updateBankStatement({
      id:                             bankStatement.id,
      businessId,
      reconciliationPaymentAccountId: rcPaymentAccountId,
      bankStatement:                  {
        closingBalance,
      },
    });
  }, [bankStatement?.id,
    businessId,
    closingBalance,
    needUpdate,
    rcPaymentAccountId,
    updateBankStatement]);

  const handleConfirm = useCallback((e) => {
    e.preventDefault();

    onCancel();
  }, [onCancel]);

  return (
    <Modal
      dialogClassName="bank-statement-start-date-error-modal"
      region="second-modal-region"
      show={ isOpen }
      title="Warning!"
    >
      <Modal.Body>
        <span>
          The statement date entered falls before the account import date.
          {' '}
          <a
            className="pointer font-bold select-all-similar-js"
            href={ `/businesses/${businessId}/banking_accounts/accounts/${rcPaymentAccountId}?statementDocytId=${bankStatement?.docytId || window.Docyt.Common.Constants.MARK_AS_NO_AVAILABLE}` }
          >
            Click Here
          </a>
          {' '}
          to change the import date
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsColor="blue"
          title="Ok"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const MemoizedStartDateErrorModal = React.memo(StartDateError);
const useStartDateErrorModal = makeUseModal<
 typeof MemoizedStartDateErrorModal, TOpenValue>(MemoizedStartDateErrorModal);

export {
  IStartDateErrorProps,
  useStartDateErrorModal,
  StartDateError as default,
};
