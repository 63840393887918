import React, { useCallback } from 'react';

import { useMoveDocumentsToTrash } from '@src/hooks/queries/documents';
import { TID } from '@src/types/common';

import { useConfirmRestoreDocumentsModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { RestoreIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IRestoreExpenseReportsActionProps {
  documentIds: TID[],
}

const RestoreExpenseReportsAction = ({
  documentIds,
}: IRestoreExpenseReportsActionProps) => {
  const moveToTrash = useMoveDocumentsToTrash();

  const { mutate } = moveToTrash;
  const handleConfirm = useCallback(() => {
    mutate({
      documentIds, value: false,
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('expense_reports_settings:trash:items:removed', documentIds.length);
      },
    });
  }, [documentIds, mutate]);

  const confirm = useConfirmRestoreDocumentsModal({
    documentIds,
    itemName:    'Expense',
    serviceName: 'Expense Reports',
    onDone:      handleConfirm,
  });

  const handleSelect = useCallback(() => {
    if (!documentIds.length) return;

    confirm.open();
  }, [documentIds, confirm]);

  return (
    <>
      <MutationStatus mutation={ moveToTrash } />
      <confirm.Component { ...confirm.props } />
      <ActionsDropdown.Action
        icon={ <RestoreIcon /> }
        title="Restore"
        onSelect={ handleSelect }
      />
    </>
  );
};

export default React.memo(RestoreExpenseReportsAction);
