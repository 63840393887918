import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';

import Form from '@src/components/ui/form';

import List from './business_fields';
import { IFormValues, businessesSchema } from './schema';

import styles from '../styles.module.scss';

interface IAddBusinessFormProps {
  addBusinessEvented: boolean,
  formId?: string,
  parsedBusinessNames: string[],
  onSubmit: SubmitHandler<IFormValues>,
}

const AddBusinessForm = ({
  addBusinessEvented,
  formId,
  parsedBusinessNames,
  onSubmit,
}: IAddBusinessFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(businessesSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newBusinesses',
  });

  useEffect(() => {
    if (!addBusinessEvented) return;

    append({
      businessName: '',
    }, { shouldFocus: false });
  }, [append, addBusinessEvented]);

  useEffect(() => {
    if (parsedBusinessNames.length === 0) return;

    parsedBusinessNames.forEach((businessName) => {
      append({
        businessName,
      }, { shouldFocus: false });
    });
  }, [append, parsedBusinessNames]);

  return (
    <Form
      className={ styles['add-businesses-modal-form'] }
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <div className="add-business-list p-b-30">
        {
          fields.map((item, index) => (
            <List
              key={ item.id }
              control={ control }
              errors={ errors }
              index={ index }
              onRemove={ () => remove(index) }
            />
          ))
        }
      </div>
    </Form>
  );
};

export {
  IAddBusinessFormProps,
  AddBusinessForm as default,
};
