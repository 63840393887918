module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, x;
    
      __out.push('<div class=\'input-container text-center\'>\n  <div class=\'main-view p-t-40\'>\n    <span class=\'font-14 font-semi-bold\'>\n      You will receive an email soon with details.\n    </span>\n    <table>\n      <tr class=\'font-semi-bold\'>\n        <th>Installment #</th>\n        <th>Principal</th>\n        <th>Interest</th>\n        <th>Payment</th>\n      </tr>\n      ');
    
      for (x = i = 1; i <= 24; x = ++i) {
        __out.push('\n      <tr>\n        <td>');
        __out.push(__sanitize(x));
        __out.push('</td>\n        <td>');
        __out.push(__sanitize(this.formatAsCurrency(Math.abs(this.monthly_detail.get("monthly_payment")) - this.monthly_detail.get("" + x))));
        __out.push('</td>\n        <td>');
        __out.push(__sanitize(this.formatAsCurrency(this.monthly_detail.get("" + x))));
        __out.push('</td>\n        <td>');
        __out.push(__sanitize(this.formatAsCurrency(Math.abs(this.monthly_detail.get("monthly_payment")))));
        __out.push('</td>\n      </tr>\n      ');
      }
    
      __out.push('\n    </table>\n    <div class="result-area">\n        <span>Need further help in the following:</span>\n        <ul class="m-t-16">\n          <li>Understanding Loan Forgiveness calculations</li>\n          <li>Preparing financial statements (P&L, Balance Sheet, Cash Flow Statement)</li>\n          <li>Preparing a budget for lenders</li>\n          <li>Digitizing and automating your back-office processes</li>\n        </ul>\n    </div>\n    <a class="btn btn-blue-on-hover text-decoration-blank btn-schedule-link font-14 font-semi-bold m-t-40" href="https://meetings.hubspot.com/john864" target="_blank">\n      <span class="icon icon-blue-calendar m-r-5"></span>\n      Schedule Free Consultation\n    </a>\n  </div>\n</div>\n<div class=\'cares-act-footer\'>\n  <div class=\'main-area\'>\n    <a class="cancel-link cancel settings-cancel-btn font-15 font-semi-bold" href="/covid">Back</a>\n    <button type="submit" class="btn btn-info btn-start m-l-auto m-r-0 font-16" data-color="blue" data-style="expand-right">START AGAIN</button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}