import Item from './item';
import Separator from './separator';
import ServiceSidebar from './service_sidebar';
import SubMenu from './sub_menu';

export default Object.assign(ServiceSidebar, {
  Item,
  Separator,
  SubMenu
});
