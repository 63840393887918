import React, { ReactNode } from 'react';

interface IHeaderRegionBulkActionsProps {
  children: ReactNode;
}

const HeaderRegionBulkActions = ({ children }: IHeaderRegionBulkActionsProps): JSX.Element => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default React.memo(HeaderRegionBulkActions);
