module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var current_activity, current_date, i, j, len, len1, ref, ref1;
    
      ref = this.uniqDates;
      for (i = 0, len = ref.length; i < len; i++) {
        current_date = ref[i];
        __out.push('\n  <div class="width-100-percent font-bold font-12 p-t-2 p-b-0 p-l-10 bg-grey-1350 in-grey-800">');
        __out.push(__sanitize(current_date));
        __out.push('</div>\n  ');
        ref1 = this.filterByPerformedAt(current_date);
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          current_activity = ref1[j];
          __out.push('\n  ');
          if (current_activity.get('activity_type') !== 'document_category_changed' || this.canShowCategorization) {
            __out.push('\n  <div class="display-flex align-items-center">\n    <div class="m-l-10 m-r-0 m-t-5 m-b-5">\n      ');
            if (current_activity.getAvatarUrl()) {
              __out.push('\n      <img src="');
              __out.push(__sanitize(current_activity.getAvatarUrl()));
              __out.push('" class=\'avatar-wrapper\'>\n      ');
            } else {
              __out.push('\n      <i class="avatar-wrapper icon icon-smile in-blue-400 font-40"></i>\n      ');
            }
            __out.push('\n    </div>\n    <span class="m-l-20">');
            __out.push(__sanitize(this.formatName(current_activity)));
            __out.push('</span>\n    ');
            if (current_activity.get('activity_type') === 'transaction_rule_applied') {
              __out.push('\n      <span class=\'icon icon-unverified inline-block m-l-10 font-16 activity-tooltip-js\' data-html="true" data-placement="top" title="');
              __out.push(__sanitize(current_activity.get('parsed_activity')));
              __out.push('">\n        <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n      </span>\n    ');
            }
            __out.push('\n  </div>\n  ');
          }
          __out.push('\n  ');
        }
        __out.push('\n');
      }
    
      __out.push(' ');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}