import React from 'react';

import { TDate } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import Cell, { ITableCellProps } from './cell';

interface ITableDateCellProps extends Omit<ITableCellProps, 'children'> {
  date: TDate,
}

/**
 * @visibleName Table.DateCell
 */
const TableDateCell = ({
  date,
  ...cellProps
}: ITableDateCellProps) => {
  return (
    <Cell { ...cellProps }>
      { formatDate(date) }
    </Cell>
  );
};

export default TableDateCell;
