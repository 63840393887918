import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IImportBudgetFinishedFayeEvent } from '@src/types/docyt_events/import_budget_finished';

import { useFayeChannel } from './common';

export const useImportBudgetFayeChannel = (
  budgetId: TID,
  callback: TFayeEventCallback<IImportBudgetFinishedFayeEvent>,
) => {
  useFayeChannel(
    `/import_budget-${budgetId}`,
    callback,
  );
};
