import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

import styles from './styles.module.scss';

interface IProgressModalProps extends IUseModalProps {
  doneCount: number;
  totalCount: number;
}

const ProgressModal = ({
  isOpen,
  doneCount,
  totalCount,
}: IProgressModalProps): JSX.Element => {
  const percent = Math.floor((doneCount * 100) / totalCount);
  return (
    <Modal
      className={ styles['progress-modal'] }
      show={ isOpen }
      title={ (
        <p className={ styles['progress-modal-title'] }>
          Vendor Links in Progress
        </p>
      ) }
    >
      <Modal.Body>
        <div>
          <p className={ styles['progress-modal-content'] }>
            <b>
              { doneCount }
              /
              { totalCount }
            </b>
            { ' ' }
            vendor links created
          </p>
          <div className={ styles['progress-modal-background'] }>
            <div className={ styles['progress-modal-bar'] } style={ { width: `${percent}%` } } />
          </div>
          <p className={ styles['progress-modal-warning'] }>
            Do not close this window or click the back button on your browser,
            this will stop the process.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const useProgressModal = makeUseModal(ProgressModal);

export default useProgressModal;
