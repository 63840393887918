import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSetLinkBusinessLaborReportsDepartment } from '@src/hooks/queries/report_service/report_datas';
import { IReportItem, IItemDimension } from '@src/types/report_service/report_item';

import { Button } from '@src/components/ui_v2/buttons';
import { TSubOption } from '@src/components/ui_v2/inputs';
import MultiSelect from '@src/components/ui_v2/inputs/multi_select';
import MutationStatus from '@src/components/utils/mutation_status';

import { IEditMappingLaborProps } from '../hooks';

import styles from '../styles.module.scss';

interface IBusinessReportsEditMappingMapDimensionProps extends IEditMappingLaborProps {
  department: { dimensionCode: string, dimensionLabel: string }[],
}

const BusinessReportsEditMappingMapDimension = ({
  data,
  parentId,
  childId,
  department
}: IBusinessReportsEditMappingMapDimensionProps) => {
  const [dimensions, setDimensions] = useState<string[]>([]);

  const linkDimention = useSetLinkBusinessLaborReportsDepartment();
  const { mutate } = linkDimention;
  const item = useMemo(() => data.items.find((itm: IReportItem) => 
    itm.id === parentId || itm.id === childId),
  [data.items, parentId, childId]);
  const mappedDimensionLabels = useMemo(() => data.items.reduce(
    (all: string[], currentItem: IReportItem) => all.concat(
      currentItem.itemDimensions.map((itemDimension: IItemDimension) => itemDimension.dimensionLabel)
    ),
    []
  ), [data]);
  const departmentOptions = data.departments ? data.departments.filter((dp) => !mappedDimensionLabels.includes(dp.label)) : []

  useEffect(() => {
    setDimensions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDimensions, department, data.departments]);

  const handleChange = useCallback((values: string[]) => {
    setDimensions(values);
  }, [setDimensions]);

  const handleClick = useCallback(() => {
    mutate({
      reportId: data.report.id as string,
      dimensionCode: item?.metricDimensionCode as string,
      dimensionLabels: dimensions,
      itemId: childId ? childId as string : parentId as string
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:link:created');
        setDimensions([]);
      },
    });
  }, [mutate, data.report, item, parentId, childId, dimensions]);

  return (
    <>
      <p className={ styles.title }>Map Dimension Labels</p>
      <div className={ styles['action-item'] }>
        <p className={ styles.label }>Dimension Labels</p>
        <MultiSelect
          options={ departmentOptions as TSubOption[] }
          placeholder="Select Dimensions"
          value={ dimensions }
          onChange={ handleChange }
        />
        <Button
          variant="outline"
          onClick={ handleClick }
          disabled={ dimensions.length === 0 }
        >
          Apply
        </Button>
      </div>
      <p className={ styles.info }>
        Dimension label in Labor Report refers to the list of department names that is sourced from Paychex
      </p>
      <MutationStatus mutation={ linkDimention } />
    </>
  );
};

export default React.memo(BusinessReportsEditMappingMapDimension);
