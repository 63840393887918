import React from 'react';

import { CaretIcon } from '@src/components/utils/fa_icons';

interface ICollapseSectionProps {
  title: string,
  children: React.ReactNode,
}

const CollapseSection = ({ title, children }: ICollapseSectionProps): JSX.Element => {
  return (
    <>
      <div id="matched-items">
        <div aria-controls="matched-items-collapse" aria-expanded="true" className="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#matched-items-collapse" data-toggle="collapse">
          <div>
            <CaretIcon className="m-r-5" variant="down" />
            <span className="font-bold">{ title }</span>
          </div>
        </div>
      </div>
      <div aria-expanded="true" aria-labelledby="matched-items" className="p-t-10 collapse in background-white document-detail-sidebar-section-body" id="matched-items-collapse">
        { children }
      </div>
    </>
  );
};

export default CollapseSection;
