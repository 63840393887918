import React, { useEffect, useMemo } from 'react';

import { useForm, useWatch } from 'react-hook-form';

import { useGetReportAvailableBusinesses, useGetReportByBusinessId } from '@src/hooks/queries/report_service/custom_reports';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';

import Form from '@src/components/ui/form';
import { ErrorNotification } from '@src/components/ui/notification';

type TCopyMappingFormValues = {
  businessId: TID,
  reportId: string
}

interface ICopyMappingFormProps{
  currentBusiness: IBusiness
  templateId: string
  formId?: string
  onSubmit: (values: TCopyMappingFormValues) => void,
}

const CopyMappingForm = ({
  currentBusiness,
  templateId,
  formId,
  onSubmit,
}: ICopyMappingFormProps): JSX.Element => {
  const availableBusinessesQuery = useGetReportAvailableBusinesses(templateId);
  const businessOptions = useMemo(() => {
    const availableBusinesses = availableBusinessesQuery.data || [];
    return availableBusinesses.map((bs: IBusiness) => (
      { value: String(bs.id), name: bs.displayName }
    )).filter((businessOption: any) => businessOption.value !== String(currentBusiness.id));
  }, [availableBusinessesQuery.data, currentBusiness.id]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TCopyMappingFormValues>({
    defaultValues: {
      businessId: parseInt(businessOptions[0]?.value, 10),
    },
  });

  const selectedBusinessId = useWatch({
    control,
    name: 'businessId',
  });
  
  const { data: reportsData } = useGetReportByBusinessId({businessId: selectedBusinessId, templateId}, !!selectedBusinessId);
  const reports = useMemo(() => {
    if (!reportsData) return [];
    return reportsData.map((report: IReport) => (
      { value: String(report.id), name: report.name }
    ));
  }, [reportsData])

  useEffect(() => {
    if (businessOptions.length > 0) {
      reset({
        businessId: !selectedBusinessId ? parseInt(businessOptions[0]?.value, 10) : selectedBusinessId,
        reportId: reports.length > 0 ? reports[0].value : undefined,
      });
    }
  }, [businessOptions, reports, reset, selectedBusinessId]);

  return (
    <form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {
        errors.businessId && <ErrorNotification message="Please Select Business" title="Warning!" />
      }
      <div className="copy-mapping-modal-region display-flex-column">
        <span className="font-12">
          Below is the list of all Business in which you have Admin access
          to Business Report Service.
        </span>
        <span className="font-14">
          All existing mapping information will be replaced with the
          mapping from the selected Business.
        </span>
        <div className="m-t-20 display-flex align-items-center">
          <span className="m-r-12">Business</span>
          <Form.SelectField
            options={ businessOptions }
            placeholder="Select Business"
            { ...register('businessId') }
          />
        </div>
        <div className="m-t-20 display-flex align-items-center">
          <span className="m-r-12">Reports</span>
          <Form.SelectField
            options={ reports }
            placeholder="Select Reports"
            { ...register('reportId') }
          />
        </div>
      </div>
    </form>
  );
};

export {
  ICopyMappingFormProps,
  TCopyMappingFormValues,
  CopyMappingForm as default,
};
