import { useCallback, useMemo, useState } from 'react';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { TID } from '@src/types/common';
import {
  IFilter,
  TAmountFilter,
  TDateFilter,
  TFilterData,
} from '@src/types/filter';
import { restoreFilterFromStorage, storeFilterToStorage } from '@src/utils/filter';
import { getSearchParamsFromFilter, parseSearchParams } from '@src/utils/filter/url_helpers';

interface IUseFilterParams<TFilter extends TFilterData> {
  businessId?: TID
  section?: string,
  initSearchParams?: URLSearchParams,
  initData?: TFilter
}

const getFilteredCount = (filter: TFilterData | undefined) => {
  if (!filter) return 0;

  let count = 0;
  forEach(filter, (value, key) => {
    if (key === 'transaction_date' || key === 'invoice_date') {
      const filterValue = filter[key] as TDateFilter;
      if (!filterValue) return;

      if (filterValue.gte && filterValue.gte !== '') count += 1;
      if (filterValue.lte && filterValue.lte !== '') count += 1;
    } else if (key === 'amount') {
      const filterValue = filter[key] as TAmountFilter;
      if (!filterValue) return;

      if (filterValue.val && filterValue.val !== '') {
        count += 1;
      }
      if (filterValue.gte && filterValue?.gte !== '') {
        count += 1;
      }
      if (filterValue.lte && filterValue?.lte !== '') {
        count += 1;
      }
    } else if (key === 'excluded') {
      if (filter[key] === 'true') count += 1;
    } else if (filter[key]) count += 1;
  });

  return count;
};

const useFilter = <TFilter extends TFilterData>({
  businessId,
  section,
  initSearchParams,
  initData,
}: IUseFilterParams<TFilter>): IFilter<TFilter> => {
  const [data, setData] = useState<TFilter | undefined>(() => {
    let tempData: TFilter | undefined;
    if (initSearchParams && initSearchParams.toString().length > 0) {
      tempData = <TFilter>parseSearchParams(initSearchParams);
    } else if (section) {
      tempData = <TFilter>restoreFilterFromStorage({ businessId, section });
    }

    if (!isEmpty(tempData)) return tempData;

    return initData;
  });

  const filteredCount = useMemo(() => getFilteredCount(data), [data]);

  // 4. On filter update if data were changed then update search params and save in local storage
  const update = useCallback((newData: TFilter) => {
    setData((oldData) => {
      if (!newData) return oldData;

      const mergeData: TFilter = {
        ...(oldData || {}),
        ...newData,
      };

      if (isEqual(oldData, mergeData)) return oldData;

      if (section) storeFilterToStorage({ businessId, section }, mergeData);

      return mergeData;
    });
  }, [section, businessId]);

  // 5. On filter clearing remove all search params and remove saved values in local storage
  const clear = useCallback(() => {
    setData(() => {
      if (section) storeFilterToStorage({ businessId, section }, undefined);

      return initData;
    });
  }, [section, initData, businessId]);

  return {
    data,
    filteredCount,
    update,
    clear,
  };
};

export {
  getFilteredCount,
  getSearchParamsFromFilter,
  useFilter,
};
