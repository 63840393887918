import React from 'react';

import Select, {
  GroupBase,
  Props,
  StylesConfig,
} from 'react-select';
import { withAsyncPaginate } from 'react-select-async-paginate';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import { IInputCommonProps, TIconOption } from '../types';
import {
  ReactSelectClearIndicator,
  ReactSelectDropdownIndicator,
} from './helpers';
import { formatIconSelectOption } from './select_option';
import { iconSelectStyles } from './styles';
import { ReactSelectClassNamePrefix } from './utils';

interface IIconSelectInputProps<
  TOption extends TIconOption = TIconOption,
  TIsMulti extends boolean = false
> extends
  IInputCommonProps,
  Props<TOption, TIsMulti, GroupBase<TOption>>
{
}

const IconSelectInput = <
  TOption extends TIconOption = TIconOption,
  TIsMulti extends boolean = false
>({
    components: componentsProp = {},
    hideClear,
    size,
    styles: stylesProp,
    value,
    ...props
  }: IIconSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  return (
    <Select
      className={ classes }
      classNamePrefix={ ReactSelectClassNamePrefix }
      components={ {
        ClearIndicator:     ReactSelectClearIndicator,
        DropdownIndicator:  value ? () => null : ReactSelectDropdownIndicator,
        IndicatorSeparator: () => null,
        ...componentsProp,
      } }
      formatOptionLabel={ formatIconSelectOption }
      isClearable={ !hideClear }
      styles={ {
        ...iconSelectStyles({ size }) as StylesConfig<TOption, TIsMulti>,
        ...stylesProp,
      } }
      value={ value || null }
      { ...selectProps }
    />
  );
};

const MemoizedIconSelectInput = React.memo(IconSelectInput) as typeof IconSelectInput;
const AsyncPaginateIconSelectInput = withAsyncPaginate(MemoizedIconSelectInput);

export {
  IIconSelectInputProps,
  AsyncPaginateIconSelectInput,
  MemoizedIconSelectInput as default,
};
