import React from 'react';

import { SplitBadgeIcon } from '@src/components/utils/icomoon';

const SplitTransactionModalTitle = (): JSX.Element => {
  return (
    <div>
      <SplitBadgeIcon fontSize={ 18 } mr={ 10 } />
      <span>Split Transaction</span>
    </div>
  );
};

export default SplitTransactionModalTitle;
