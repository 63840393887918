import { InfiniteData, useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetReportServiceBudgetsParams,
  IGetReportServiceBudgetsResponse,
  IAddReportServiceBudgetParams,
  IAddReportServiceBudgetResponse,
  IDeleteReportServiceBudgetParams,
  IEditReportServiceBudgetResponse,
  IEditReportServiceBudgetParams,
  ISaveReportServiceBudgetResponse,
  ISaveReportServiceBudgetParams,
  getReportServiceBudgets,
  getReportServiceBudget,
  postReportServiceBudget,
  deleteReportServiceBudget,
  putEditReportServiceBudget,
  putSaveReportServiceBudget,
  importBudget,
  uploadBudget,
  IImportBudgetParams,
  IImportBudgetResponse,
  IUploadBudgetParams,
  IUploadBudgetResponse,
} from '@src/requests/report_service/report_service_budgets';
import { TMongoID } from '@src/types/common';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import {
  createUseGetInfiniteCollection,
  updateItemsInInfiniteCollection,
  removeItemsFromInfiniteCollection,
} from '../infinite_collection_queries';

const useGetReportServiceBudgets = createUseGetInfiniteCollection<
  IReportServiceBudget,
  IGetReportServiceBudgetsParams,
  IGetReportServiceBudgetsResponse
>({
  queryKey: QueryKey.reportServiceBudgets,
  request:  getReportServiceBudgets,
});

const useGetReportServiceBudget = (budgetId: TMongoID) => {
  return useQuery<IReportServiceBudget, Error>(
    [QueryKey.reportServiceBudget, budgetId],
    () => getReportServiceBudget(budgetId),
    {
      enabled: !!budgetId,
    },
  );
};

const useAddReportServiceBudget = () => {
  return useMutation<IAddReportServiceBudgetResponse, Error, IAddReportServiceBudgetParams>(
    postReportServiceBudget,
  );
};

const useDeleteReportServiceBudget = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteReportServiceBudgetParams>(
    deleteReportServiceBudget,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          QueryKey.reportServiceBudgets,
          (
            data?: InfiniteData<IGetReportServiceBudgetsResponse>,
          ): InfiniteData<IGetReportServiceBudgetsResponse> => {
            return removeItemsFromInfiniteCollection(
              data,
              [params.id],
            );
          },
        );
      },
    },
  );
};

const useEditReportServiceBudget = () => {
  const queryClient = useQueryClient();

  return useMutation<IEditReportServiceBudgetResponse, Error, IEditReportServiceBudgetParams>(
    putEditReportServiceBudget,
    {
      onSuccess: (response) => {
        queryClient.setQueriesData(
          QueryKey.reportServiceBudgets,
          (
            data?: InfiniteData<IGetReportServiceBudgetsResponse>,
          ): InfiniteData<IGetReportServiceBudgetsResponse> => {
            return updateItemsInInfiniteCollection<
              IReportServiceBudget,
              IGetReportServiceBudgetsResponse
            >(
              data,
              [response.budget.id],
              {
                name: response.budget.name,
                year: response.budget.year,
                type: response.budget.type,
              },
            );
          },
        );
      },
    },
  );
};

const useSaveReportServiceBudget = () => {
  return useMutation<
    ISaveReportServiceBudgetResponse,
    Error,
    ISaveReportServiceBudgetParams
  >(putSaveReportServiceBudget);
};

const useImportBudget = () => {
  return useMutation<IImportBudgetResponse, Error, IImportBudgetParams>(importBudget);
};

const useUploadBudget = () => {
  return useMutation<IUploadBudgetResponse, Error, IUploadBudgetParams>(uploadBudget);
};

export {
  useGetReportServiceBudgets,
  useGetReportServiceBudget,
  useAddReportServiceBudget,
  useDeleteReportServiceBudget,
  useEditReportServiceBudget,
  useSaveReportServiceBudget,
  useImportBudget,
  useUploadBudget,
};
