import React from 'react';

import classNames from 'classnames';

import { ITooltipVariant } from '@src/types/tooltip';

import { useTooltip } from './hooks';

import styles from './styles.module.scss';

interface ITooltipProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  children: React.ReactNode | (() => React.ReactNode),
  isVisibleArrow?: boolean,
  label?: string,
  keepShowOnHover?: boolean,
  variant? : ITooltipVariant,
}

const Tooltip = ({
  children,
  className,
  isVisibleArrow = true,
  keepShowOnHover = false,
  label,
  variant = 'dark',
  ...props
}: ITooltipProps) => {
  const {
    arrowRef,
    isVisible,
    popperRef,
    popperAttributes,
    popperStyles,
    showOnHoverAttributes,
  } = useTooltip() || {};

  if (isVisible === false) return null;

  const classes = classNames(styles.tooltip, className, styles[variant]);

  let actualChildren = null;
  if (typeof children === 'function' && isVisible) {
    actualChildren = children();
  } else if (typeof children !== 'function') {
    actualChildren = children;
  }

  return (
    <div
      ref={ popperRef }
      className={ classes }
      role="tooltip"
      { ...props }
      style={ popperStyles?.popper }
      { ...(popperAttributes?.popper || {}) }
      { ...(keepShowOnHover ? showOnHoverAttributes : null) }
    >
      { label && <span className={ styles['tooltip-label'] }>{ label }</span> }
      <span className={ styles['tooltip-content'] }>
        { actualChildren }
      </span>
      {
        isVisibleArrow && (
          <div
            ref={ arrowRef }
            className={ styles.arrow }
            style={ popperStyles?.arrow }
          />
        )
      }
    </div>
  );
};

export default React.memo(Tooltip);
