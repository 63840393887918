import { createContext, useContext } from 'react';

import { IReport } from '@src/types/report_service/report';

const ReportContext = createContext<IReport | null>(null);

// force convert to `IReport` type without null because childs of provider are
// rendered only then report present.
const useReportContext = () => <IReport>useContext(ReportContext);

export {
  ReportContext,
  useReportContext,
};
