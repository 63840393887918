import React, { useCallback } from 'react';

import snakeCase from 'lodash/snakeCase';

import { TVendorMergeType } from '@src/types/business_vendors';

interface IReviewMergeVendorTypeInputProps {
  label: string,
  value: TVendorMergeType,
  mergeType: TVendorMergeType,
  onChange: (value: TVendorMergeType) => void
}

const ReviewMergeVendorTypeInput = ({
  label,
  value,
  mergeType,
  onChange,
}: IReviewMergeVendorTypeInputProps): JSX.Element => {
  const inputId = `review_merge_vendor_${snakeCase(value)}`;

  const handleSelect = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <div
      role="button"
      tabIndex={ -1 }
      onClick={ handleSelect }
    >
      <div className="checkbox checkbox-primary checkbox-circle">
        <input
          checked={ value === mergeType }
          id={ inputId }
          name="mergeType"
          type="radio"
          value={ value }
          onChange={ handleSelect }
        />
        <label
          htmlFor={ inputId }
        >
          {' '}
          { label }
        </label>
      </div>
    </div>
  );
};

export default ReviewMergeVendorTypeInput;
