import { TID } from '@src/types/common';
import { ITemplate } from '@src/types/dashboards/templates';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetTemplatesParams {
  standardCategoryId: TID,
}

interface IGetTemplateResponse {
  templates: ITemplate[],
}

const getTemplates = (
  params: IGetTemplatesParams,
): Promise<IGetTemplateResponse> => {
  return apiGet(
    '/dashboards/api/v1/templates.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetTemplateResponse);
};

export {
  IGetTemplatesParams,
  IGetTemplateResponse,
  getTemplates,
};
