import React, { useCallback, useState } from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';

import DropInput from '../balance_sheet_statement_drop_input';
import FileView from '../balance_sheet_statement_file_view';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IBalanceSheetStatementUploadViewProps {
  balanceSheetStatement?: IBalanceSheetStatement,
  error?: string,
  isFileUploading?: boolean,
  onUploaded: (data: File) => void,
}

const BalanceSheetStatementUploadView = ({
  balanceSheetStatement,
  error,
  isFileUploading,
  onUploaded,
}: IBalanceSheetStatementUploadViewProps): JSX.Element => {
  const [fileUrl, setFileUrl] = useState<any>(balanceSheetStatement?.statementFileUrl);
  const [fileName, setFileName] = useState<string | undefined>(balanceSheetStatement?.name);

  const handleUploadfile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      setFileUrl(e?.target?.result);
    };
    setFileName(file.name);
    onUploaded(file);
  }, [onUploaded]);

  return (
    <div className={ styles['upload-file-container'] }>
      {
        fileUrl ? (
          <FileView fileName={ fileName } fileUrl={ fileUrl } />
        ) : (
          <DropInput
            balanceSheetStatement={ balanceSheetStatement }
            error={ error }
            isFileUploading={ isFileUploading }
            onUploaded={ handleUploadfile }
          />
        )
      }
    </div>
  );
};

export default BalanceSheetStatementUploadView;
