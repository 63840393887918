import React from 'react';

import Select, { components } from 'react-select';

type Props = {
  options: Array<any>;
  onChange: (value: any) => void;
  renderValue: any;
  isMenuOpenCheck?: boolean;
  handleMenuOpen:(value:string)=> void;
  handleMenuClose:(value:string)=> void;
  uniqueId:number
}

const CustomSelect = ({ options, onChange, uniqueId, renderValue, isMenuOpenCheck, handleMenuOpen, handleMenuClose }: Props) => {
  const selectCustomStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: '#f7f5f6',
      border:          '0', // Customize the border
      borderRadius:    '5px', // Customize the border-radius
      boxShadow:       state.isFocused ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none', // Add a box shadow on focus
      whiteSpace:      'nowrap',
      fontSize:        '14px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? 'black' : 'inherit',
    }),
  };

  const customValueContainer = (props: any) => {
    return (
      <components.ValueContainer { ...props }>
        {renderValue()}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      components={ {
        ValueContainer: customValueContainer,
      } }
      menuIsOpen={ isMenuOpenCheck }
      options={ options }
      styles={ selectCustomStyles }
      onBlur={ () => handleMenuClose("") }
      onChange={ onChange }
      closeMenuOnSelect
      onMenuClose={ () => handleMenuClose("") }
      onMenuOpen={ () => handleMenuOpen(uniqueId.toString()) }
    />
  );
};

export default CustomSelect;
