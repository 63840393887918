import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBankStatement, TReviewedStatementDetailsSortColumn } from '@src/types/bank_statements';
import { TDate, TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';
import { API_DATE_FORMAT, formatApiDate, formatDate, formatMonth, formatLastMonth, getUTCTimezone } from '@src/utils/date_helpers';

import DetailsPageTitle from '@src/components/ui/details_page_title';

import Filter, { IReviewedStatementDetailsFilter } from './filter';
import Table from './table';

import styles from '../styles.module.scss';

interface IReviewedStatementDetailsListProps {
  businessId: TID,
  filter: IFilter<IReviewedStatementDetailsFilter>;
  reviewedBankStatements: IBankStatement[],
  rcPaymentAccountId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementDetailsSortColumn>,
  startImportDate: TDate,
  rcPaymentAccountName: string,
  onCloseDetailsModal?: () => void,
}

const ReviewedStatementDetailsList = ({
  businessId,
  filter,
  reviewedBankStatements,
  rcPaymentAccountId,
  query,
  sorting,
  startImportDate,
  rcPaymentAccountName,
  onCloseDetailsModal,
}: IReviewedStatementDetailsListProps): JSX.Element => {
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  const values: any = useMemo(() => {
    const endDate = filter.data?.year
      ? new Date(parseInt(filter.data?.year, 10), 11, 31) : new Date();

    const nextDate = filter.data?.year
      && parseInt(filter.data?.year, 10) > getUTCTimezone(new Date(startImportDate)).getFullYear()
      ? new Date(parseInt(filter.data?.year, 10), 0, 1)
      : getUTCTimezone(new Date(startImportDate));
    const days = [];

    for (let i = nextDate; i <= endDate; nextDate.setMonth(nextDate.getMonth() + 1)) {
      days.push(formatApiDate(nextDate));
    }
    return days.map((d) => {
      const index = reviewedBankStatements.findIndex(
        (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
           === formatMonth(getUTCTimezone(new Date(d))),
      );

      if (index === -1) {
        const prevStatement = reviewedBankStatements.find(
          (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
          === formatLastMonth(getUTCTimezone(new Date(d))),
        );

        const prevStatementDate = prevStatement ? getUTCTimezone(new Date(prevStatement.date)) : '';
        const startingDate = prevStatementDate ? prevStatementDate.setDate(prevStatementDate.getDate() + 1) : '';

        return {
          month:          formatMonth(getUTCTimezone(new Date(d))),
          date:           '',
          state:          '',
          uploadedByName: '',
          approvedByName: '',
          verifiedByName: '',
          closingBalance: 0,
          startingDate:   startingDate ? formatDate(new Date(startingDate), API_DATE_FORMAT) : '',
        };
      }
      const statement = reviewedBankStatements.find(
        (s) => formatMonth(getUTCTimezone(new Date(s.date)))
          === formatMonth(getUTCTimezone(new Date(d))),
      );
      return {
        id:             statement?.id,
        month:          statement?.month,
        date:           statement?.date,
        state:          statement?.state,
        uploadedByName: statement?.uploadedByName ? statement.uploadedByName : '',
        approvedByName: statement?.approvedByName ? statement.approvedByName : '',
        verifiedByName: statement?.verifiedByName ? statement.verifiedByName : '',
        closingBalance: statement?.closingBalance,
        startingDate:   statement?.startingDate,
        docytId:        statement?.docytId,
      };
    });
  }, [filter.data?.year, reviewedBankStatements, startImportDate]);

  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        breadcrumbs={ bankStatementsDocRequestEnabled ? [
          { href: `/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`, title: 'Bank Statements' },
        ] : [
          { title: 'Bank Statements' },
          { href: `/businesses/${businessId}/banking_accounts/reviewed`, title: 'Reviewed' },
        ] }
        title={ rcPaymentAccountName }
      />
      <div className="banking-accounts-table-wrapper m-t-10">
        <Filter
          filter={ filter }
        />
        <Table
          bankStatements={ values }
          bankStatementsDocRequestEnabled={ bankStatementsDocRequestEnabled }
          businessId={ businessId }
          query={ query }
          rcPaymentAccountId={ rcPaymentAccountId }
          sorting={ sorting }
          onCloseDetailsModal={ onCloseDetailsModal }
        />
      </div>
    </div>
  );
};

export default ReviewedStatementDetailsList;
