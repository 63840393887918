module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<input type="number" class="form-control filter-min-amount range-filter" placeholder="$ Min" />\n<span class="input-group-addon range-filter">-</span>\n<input type="number" class="form-control filter-max-amount range-filter" placeholder="$ Max" />\n<input class="form-control filter-amount hidden" placeholder="$ Amount" autocomplete="off" />\n<a class="dropdown-toggle range-select-menu-btn" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  <span class="fa fa-caret-down font-14"></span>\n</a>\n<ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n  <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n  <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}