import React, { FC } from 'react';

import toastr from '@lib/toastr';
import { useGetChartOfAccount } from '@src/requests/simple_chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import CoaEditForm from '@src/components/chart_of_accounts/edit_btn/coa_edit_form';
import { ErrorNotification } from '@src/components/ui/notification';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import styles from '../add_btn/styles.module.scss';

const Content: FC<{ id: TID, businessId: TID, close: () => void }> =
  ({ id, businessId, close }) => {
    const { data, isFetching, isError, error } =
      useGetChartOfAccount({ id, businessId, includeMappedClasses: true });

    const chartOfAccount = data?.chartOfAccount as IChartOfAccount;

    if (isFetching) {
      return <div className={ styles['loading-container'] }><SpinnerIcon spin fontSize={ 30 } /></div>;
    }

    if (!businessId) {
      toastr.error('System chart of account cannot be modified.', 'Error');
      close();
      return null;
    }

    if (isError) {
      close();
      return <ErrorNotification error={ error } />;
    }

    return (
      <CoaEditForm close={ close } data={ chartOfAccount } />
    );
  };

export default Content;
