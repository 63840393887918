import React from 'react';

import { IMetric } from '@src/types/metrics';

import { useChangeStartDateModal } from '@src/components/metrics_service/details/list_item/change_start_date';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { MetircsChangeStartDateIcon } from '@src/components/utils/icomoon';

interface IMetricItemChangeStartDateActionProps {
  metric: IMetric,
}

const MetricItemChangeStartDateAction = ({
  metric,
}: IMetricItemChangeStartDateActionProps) => {
  const changeStartDate = useChangeStartDateModal();

  return (
    <>
      <changeStartDate.Component { ...changeStartDate.props } metric={ metric } />
      <ActionsDropdown.Action
        icon={ <MetircsChangeStartDateIcon /> }
        name="Change Start Date"
        onClick={ changeStartDate.open }
      />
    </>
  );
};

export default MetricItemChangeStartDateAction;
