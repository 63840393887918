module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider p-b-30">\n  <div class="m-t-0">\n    <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/integrations">Integrations</a></span>\n    <div class="client__breadcrumbs-item in-grey-600 font-20"></div>\n  </div>\n  <h2 class="service-main-title m-t-0">');
    
      __out.push(__sanitize(this.business.get('display_name')));
    
      __out.push(' - Integrations</h2>\n  <div class="notification-banner-container p-t-30 hidden">\n    <div class="font-14 font-bold notification-message-wrapper">\n      <span class="icon icon-check_circle_large"></span>\n      <span class=\'notification-message m-l-10\'></span>\n      <a class="pointer pull-right close-icon-btn"><span class="icon icon-not-a-chargeback-icon"></span></a>\n    </div>\n  </div>\n</div>\n<div class="setting-business-content m-t-30">\n  <div class="business-integrations-list"></div>\n  <div class="no-integrations-list hidden">\n    <h3>No Integrations Available</h3>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}