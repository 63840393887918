import React, { useCallback, useMemo } from 'react';

import { DropdownInput } from '@src/components/ui_v2/inputs';
import { IDropdownInputProps } from '@src/components/ui_v2/inputs/dropdown_input/dropdown_input';

interface IEmailFrequencyInputProps extends
  Omit<IDropdownInputProps, 'value' | 'onChange'>
{
  value?: string | null,
  onChange?: (value?: string | null) => void,
}

const EmailFrequencyInput = ({
  options,
  value,
  onChange,
  ...props
}: IEmailFrequencyInputProps) => {
  const handleChange = useCallback((newValue) => {
    if (!onChange) return;

    onChange(newValue ? String(newValue.value) : null);
  }, [onChange]);

  const selectedOption = useMemo(() => {
    return options.find((item) => item.value === String(value)) || null;
  }, [value, options]);

  return (
    <DropdownInput
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(EmailFrequencyInput);
