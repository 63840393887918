import React, { useCallback, useState } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { useBulkUploadKYCDocuments } from '@src/hooks/queries/self_onboarding_invitations';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';

import EntityInfo from './entity_info';
import KYCDocuments from './kyc_documents/kyc_documents_details';
import { IAddBusinessInput, IFormValues } from './kyc_documents/schema';
import { doLater } from '../utils';

import styles from './styles.module.scss';

const formId = 'kyc_documents';

interface IKycDocumentViewProps {
  invitation: ISelfOnboardingInvitation,
}

const KycDocumentView = ({
  invitation,
}: IKycDocumentViewProps) => {
  const [uploadedEntityInfo, setUploadedEntityInfo] = useState<boolean>(
    Boolean(true),
  );
  const [parsedBusinessNames, setParsedBusinessNames] = useState<string[]>(
    invitation.parsedBusinessNames,
  );
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const uploadKYCDocuments = useBulkUploadKYCDocuments();
  const bulkAction = useBulkActionModal({
    mutation: uploadKYCDocuments,
  });

  const submit = () => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_AUTHORIZED_SIGN}`,
      { trigger: true },
    );
  };

  const { runMutation } = bulkAction;

  const handleSubmit = useCallback((value: IFormValues) => {
    if (value.newBusinesses.length === 0) {
      setErrorMsg('Please add at least 1 business.');
      return;
    }
    const params: any[] = [];
    value.newBusinesses.forEach((addedBusiness: IAddBusinessInput) => {
      if (addedBusiness.einLetterFile) {
        params.push({
          index:        addedBusiness.index,
          id:           invitation.id,
          businessName: addedBusiness.businessName,
          documentType: 'EIN_LETTER',
          file:         addedBusiness.einLetterFile,
        });
      }
      if (addedBusiness.businessLicenseFile) {
        params.push({
          index:        addedBusiness.index,
          id:           invitation.id,
          businessName: addedBusiness.businessName,
          documentType: 'BUSINESS_LICENSE',
          file:         addedBusiness.businessLicenseFile,
        });
      }
      if (addedBusiness.driverLicenseFile) {
        params.push({
          index:        addedBusiness.index,
          id:           invitation.id,
          businessName: addedBusiness.businessName,
          documentType: 'AUTHORIZED_SIGNATORY_DRIVER_LICENSE',
          file:         addedBusiness.driverLicenseFile,
        });
      }
    });

    if (params.length === 0) {
      submit();
    } else {
      runMutation(params);
    }
  }, [invitation.id, runMutation]);

  const handleBulkDone = useCallback(() => {
    submit();
  }, []);

  const handleFileUploaded = useCallback((value: boolean, businessNames: string[]) => {
    setUploadedEntityInfo(value);
    setParsedBusinessNames(businessNames);
  }, []);

  const handleDoLater = useCallback(() => {
    doLater(invitation.onboardingInformation);
  }, [invitation.onboardingInformation]);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_CONNECT_FIN}`,
      { trigger: true },
    );
  }, []);

  return (
    <>
      {errorMsg
      && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } />}
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="KYC Documents"
        title="Upload KYC Documents"
        onDone={ handleBulkDone }
      />
      <div className={ styles['self-onboarding-request-document-container'] }>
        <div className={ styles['tasks-container'] }>
          <div className={ styles['message-container'] }>
            You can skip this step if you don&apos;t need to pay the vendors through Docyt
            { ' ' }
            Check or Docyt ACH
          </div>
          <EntityInfo
            invitation={ invitation }
            onFileUploaded={ handleFileUploaded }
          />
          { uploadedEntityInfo && (
            <KYCDocuments
              formId={ formId }
              parsedBusinessNames={ parsedBusinessNames }
              onSubmit={ handleSubmit }
            />
          )}
        </div>
        <div className="setup-client-footer">
          <a
            className="cancel-link pointer"
            role="button"
            tabIndex={ -1 }
            onClick={ onBack }
          >
            Previous
          </a>
          <Button
            className="pull-right bg-purple-1000 in-white width-180px"
            data-color="$purple-1000"
            disabled={ !uploadedEntityInfo }
            form={ formId }
            type="submit"
          >
            Next
          </Button>
          <a
            className="cancel-link pointer pull-right m-r-10"
            role="button"
            tabIndex={ -1 }
            onClick={ handleDoLater }
          >
            I&apos;ll do later
          </a>
        </div>
      </div>
    </>
  );
};

export default KycDocumentView;
