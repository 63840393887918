import React from 'react';

import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsMenu from './actions_menu';
import ChatAction from './chat_action';
import DocumentsAction from './documents_action';
import TransactionTypeBadge from './transaction_type_badge';
import TrashActionMenu from './trash_actions_menu';

interface ITransactionActionsProps {
  businessId: TID,
  transaction: ITransactionServiceDocument,
  isTrash: boolean,
}

const TransactionActions = ({
  businessId,
  transaction,
  isTrash,
}: ITransactionActionsProps) => {
  return (
    <>
      <TransactionTypeBadge transaction={ transaction } />
      <DocumentsAction inTrash={ isTrash } transaction={ transaction } />
      <ChatAction transaction={ transaction } />
      { isTrash ? <TrashActionMenu businessId={ businessId } transaction={ transaction } />
        : <ActionsMenu transaction={ transaction } /> }
    </>
  );
};

export default TransactionActions;
