import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import QBOAction from '@src/components/banking_accounts/transactions/actions/qbo_action';
import TransactionTypeBadge from '@src/components/banking_accounts/transactions/actions/transaction_type_badge';

import ActionsMenu from './actions_menu';

interface ITransactionActionsProps {
  transaction: ITransactionServiceDocument,
}

const TransactionActions = ({
  transaction,
}: ITransactionActionsProps) => {
  return (
    <>
      <TransactionTypeBadge transaction={ transaction } widthPercentage={ 40 } />
      <QBOAction transaction={ transaction } />
      {
        transaction.bankStatement?.state
          !== window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED
          && <ActionsMenu transaction={ transaction } />
      }
    </>
  );
};

export default TransactionActions;
