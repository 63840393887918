import React, { useEffect, useState } from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { TAmount, TDate } from '@src/types/common';

import FileView from './file_view';

import styles from './styles.module.scss';

interface IDuplicateStatementFormProps {
  originBankStatement?: IBankStatement,
  statementDate?: TDate,
  closingBalance?: TAmount,
  duplicateStatementFile?: File,
}

const DuplicateStatementForm = ({
  originBankStatement,
  statementDate,
  closingBalance,
  duplicateStatementFile,
}: IDuplicateStatementFormProps): JSX.Element | null => {
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!duplicateStatementFile) return;

    const reader = new FileReader();
    reader.readAsDataURL(duplicateStatementFile);
    reader.onloadend = (e) => {
      setFileUrl(e?.target?.result);
    };
    setFileName(duplicateStatementFile.name);
  }, [duplicateStatementFile]);

  if (!originBankStatement || !fileUrl) return null;

  return (
    <div className={ styles['file-view-body'] }>
      <FileView
        closingBalance={ originBankStatement.closingBalance }
        date={ originBankStatement.date }
        fileName={ originBankStatement.name }
        fileUrl={ originBankStatement.statementFileUrl }
        reconciliationPaymentAccountName={
          originBankStatement.reconciliationPaymentAccount.name
        }
      />
      <FileView
        possibleDuplicateMark
        closingBalance={ closingBalance }
        date={ statementDate }
        fileName={ fileName }
        fileUrl={ fileUrl }
        reconciliationPaymentAccountName={
          originBankStatement.reconciliationPaymentAccount.name
        }
      />
    </div>
  );
};

export {
  IDuplicateStatementFormProps,
  DuplicateStatementForm as default,
};
