import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  PaymentAccountFilterField,
} from '@src/components/common_v2/filter_fields';
import Filter from '@src/components/ui_v2/filter';

const ReconcileDocumentsFilter = () => {
  const business = useBusinessContext();

  return (
    <Filter.TableContainer>
      <Filter.DateField
        name="transaction_date"
      />
      <Filter.TextField
        label="Description"
        name="description"
        placeholder="Description"
      />
      <PaymentAccountFilterField
        businessId={ business.id }
        placeholder="All Accounts"
      />
      <Filter.AmountField
        name="amount"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(ReconcileDocumentsFilter);
