import React from 'react';

import MultiBusinessesInput from '@src/components/common_v2/inputs/multi_businesses_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IMultiBusinessesFieldProps extends
  React.ComponentPropsWithoutRef<typeof MultiBusinessesInput>, IWrapperProps {
}

const MultiBusinessesField = ({
  ...props
}: IMultiBusinessesFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <MultiBusinessesInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(MultiBusinessesField);
