module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="');
    
      __out.push(__sanitize(this.isHeader ? 'header__notifications-content' : 'main-chats-content'));
    
      __out.push('">\n  <div class="display-flex align-items-center header-container">\n    <div class="in-grey-700 font-14 font-bold m-l-10" ');
    
      if (!this.isHeader) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('>');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('unread_chats_count')));
    
      __out.push(' Unread</div>\n    <select class="form-control form-control-lg filter-select width-15-percent background-white m-t-5 m-b-5" ');
    
      if (this.isHeader) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('>\n      <option value="all">All</option>\n      <option value="read" ');
    
      if (this.filter.state === 'read') {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Read</option>\n      <option value="unread" ');
    
      if (this.filter.state === 'unread') {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Unread</option>\n    </select>\n    <a class="btn font-bold m-l-auto m-r-10 mark-all-as-read">Mark all as read</a>\n  </div>\n  <div class=\'header__notifications-spinner\' id=\'notification-spinner\'>\n    <i class=\'fa fa-spinner fa-pulse fa-4x fa-fw\'></i>\n  </div>\n  <div class="notifications-list display-table"></div>\n  <div class="header__notifications-empty">\n    <p class="header__notifications-empty-text">There are no new chat messages</p>\n  </div>\n  <div class="p-b-16 p-t-16 text-center all-notifications-link">\n    <a class="font-14 font-bold" href="/my_chats">\n      View All Messages\n    </a>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}