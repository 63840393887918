import React from 'react';

import { SmileIcon } from '@src/components/utils/icomoon/';

interface IFinancialInstitutionAvatarProps {
  logo: string,
}

const FinancialInstitutionAvatar = ({
  logo,
}: IFinancialInstitutionAvatarProps) => {
  if (!logo) {
    return (
      <SmileIcon
        className="in-orange-500 font-25"
      />
    );
  }

  return (
    <img
      alt=""
      src={ `data:image/jpeg;base64, ${logo}` }
      style={ { width: '25px' } }
    />
  );
};

export default FinancialInstitutionAvatar;
