import React, { forwardRef, useCallback } from 'react';

import classNames from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import IndicatorWrapper from './input_indicator_wrapper';
import { IInputCommonProps } from './types';

import styles from './styles.module.scss';

interface IPercentageInputProps extends
  IInputCommonProps,
  Omit<NumberFormatProps, 'value' | 'onChange' | 'size'>
{
  value?: number | null,
  onChange: (value: number | null) => void,
  onClear?: () => void,
}

const PercentageInput = forwardRef<HTMLInputElement, IPercentageInputProps>(({
  className,
  size = 'normal',
  disabled,
  value,
  onChange,
  onClear,
  ...props
}: IPercentageInputProps, ref) => {
  const handleChange = useCallback((values) => {
    if (onChange) onChange(values.value || null);
  }, [onChange]);

  const handleClear = useCallback(() => {
    if (onChange) onChange(null);
    if (onClear) onClear();
  }, [onChange, onClear]);

  const classes = classNames(
    styles['text-input'],
    className,
    { [styles['input-compact']]: size === 'compact' },
  );

  return (
    <IndicatorWrapper
      hasValue={ Boolean(value) }
      hideClear={ disabled }
      size={ size }
      onClear={ handleClear }
    >
      <NumberFormat
        fixedDecimalScale
        className={ classes }
        decimalScale={ 2 }
        disabled={ disabled }
        getInputRef={ ref }
        inputMode="numeric"
        suffix="%"
        value={ value || '' }
        { ...props }
        onValueChange={ handleChange }
      />
    </IndicatorWrapper>
  );
});

PercentageInput.displayName = 'PercentageInput';
const MemoizedPercentageInput = React.memo(PercentageInput);

export {
  IPercentageInputProps,
  MemoizedPercentageInput as default,
};
