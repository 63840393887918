import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useGetBusinessQuery } from '@src/hooks/queries/businesses';

import BusinessView from './business_view';
import ClientView from './client_view';

interface IDetailsProps {
  focusToMyClient: boolean,
}

const Details = ({
  focusToMyClient,
}: IDetailsProps) => {
  const location = useLocation();
  let currentBusinessId: string | undefined;

  if (focusToMyClient) {
    currentBusinessId = location.pathname.split('/')[3] === 'clients' ? location.pathname.split('/')[4] : location.pathname.split('/')[2];
  } else {
    currentBusinessId = location.pathname.split('/')[1] === 'businesses' ? location.pathname.split('/')[2] : undefined;
  }

  const businessQuery = useGetBusinessQuery(Number(currentBusinessId));
  const currentBusiness = useMemo(() => {
    return businessQuery.data;
  }, [businessQuery.data]);

  return (
    <div>
      {
        focusToMyClient ? (
          <ClientView currentClientBusiness={ currentBusiness } />
        ) : (
          <BusinessView currentBusiness={ currentBusiness } />
        )
      }
    </div>
  );
};

export default Details;
