import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { revenueServiceResolveReportPath } from '@src/routes';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { CheckMarkIcon } from '@src/components/utils/icomoon';

interface IEditRevenueReportActionProps {
  report: IRevenueServiceDocument,
}

const ResolveIssueRevenueReportAction = ({
  report,
}: IEditRevenueReportActionProps) => {
  const business = useBusinessContext();

  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_ISSUE_FLAG,
  );

  if (!featureQuery?.data) return null;

  return (
    <ActionsDropdown.LinkAction
      href={ revenueServiceResolveReportPath(business.id, report.revenueSystemId!, report.id) }
      icon={ <CheckMarkIcon color="black" fontSize={ 20 } /> }
      title="Resolve Issue"
    />
  );
};

export default ResolveIssueRevenueReportAction;
