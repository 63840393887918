import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const NonApprovedStatementIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="non-approved-statement"
      pathsNumber={ 3 }
    />
  );
});

NonApprovedStatementIcon.displayName = 'NonApprovedStatementIcon';

export default NonApprovedStatementIcon;
