import React, { useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useGetQuickbooksCustomers } from '@src/hooks/queries/quickbooks_customers';
import { TID } from '@src/types/common';

import Form from '@src/components/ui/form';
import { ArrowIcon } from '@src/components/utils/fa_icons';

import CustomerSelect from './customer_select';

interface IMergeCustomerFormValues {
  selectedCustomerId: TID,
}

interface IMergeCustomerFormProps {
  formId?: string,
  businessId: TID,
  customerId: TID,
  onSubmit: SubmitHandler<IMergeCustomerFormValues>,
}

const MergeCustomerForm = ({
  businessId,
  customerId,
  formId,
  onSubmit,
}: IMergeCustomerFormProps): JSX.Element => {
  const query = useGetQuickbooksCustomers({
    businessId,
  });

  const quickbooksCustomers = useMemo(() => {
    return (query.data?.quickbooksCustomers || []);
  }, [query?.data]);

  const quickbooksCustomer = quickbooksCustomers.filter((customer) => {
    return customer.id === customerId;
  });

  const {
    register,
    handleSubmit,
  } = useForm<IMergeCustomerFormValues>();

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <p className="font-16">
        Select another customer to merge
      </p>
      <div className="m-t-20 display-flex">
        <div className="flex-1">
          <p className="font-18 font-bold">
            Merge this customer...
          </p>
          <p className="description">
            (This customer will be deleted)
          </p>
          <CustomerSelect
            { ...register('selectedCustomerId') }
            customerId={ customerId }
            quickbooksCustomers={ quickbooksCustomers }
          />
        </div>
        <div>
          <ArrowIcon fontSize={ 25 } ml={ 20 } mr={ 20 } mt={ 40 } variant="right" />
        </div>
        <div className="align-items-center flex-1">
          <p className="font-18 font-bold">
            ... with this customer
          </p>
          <p className="description">
            (This customer will stay)
          </p>
          {
            quickbooksCustomer[0] && (
              <p className="font-14">
                { quickbooksCustomer[0].name }
              </p>
            )
          }
        </div>
      </div>
    </Form>
  );
};

export {
  IMergeCustomerFormProps,
  IMergeCustomerFormValues,
  MergeCustomerForm as default,
};
