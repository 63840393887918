import React, { useCallback } from 'react';

import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ISideViewHeaderProps {
  title: React.ReactNode,
  onClose: () => void;
}

const SideViewHeader = ({
  title,
  onClose,
}: ISideViewHeaderProps) => {
  const handleCloseClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    onClose();
  }, [onClose]);

  return (
    <div className={ styles.header }>
      <p className={ styles.title }>{ title }</p>
      <a
        className="close-link"
        role="button"
        tabIndex={ -1 }
        onClick={ handleCloseClick }
      >
        <NotAChargebackIcon fontSize={ 24 } />
      </a>
    </div>
  );
};

export default SideViewHeader;
