import React, { useCallback } from 'react';

import { Button } from '@src/components/ui/buttons';
import SideView from '@src/components/ui/side_view';

import { useSetupAccount } from './setup_account';

interface INewAccountProps {
  isBlankPage?: boolean,
  isOpen?: boolean,
  onDone: () => void,
  onCancel: () => void,
}

const NewAccount = ({
  isOpen = true,
  isBlankPage = false,
  onCancel,
  onDone,
}: INewAccountProps) => {
  const setupAccount = useSetupAccount({ onDone });

  const { openWithValue } = setupAccount;
  const handleAutoAddBankAccount = useCallback(() => {
    openWithValue({
      accountMode: 'auto',
      accountType: 'bank',
    });
  }, [openWithValue]);

  const handleAutoAddCreditCardAccount = useCallback(() => {
    openWithValue({
      accountMode: 'auto',
      accountType: 'credit_card',
    });
  }, [openWithValue]);

  const handleManualAddBankAccount = useCallback(() => {
    openWithValue({
      accountMode: 'manual',
      accountType: 'bank',
    });
  }, [openWithValue]);

  const handleManualAddCreditCardAccount = useCallback(() => {
    openWithValue({
      accountMode: 'manual',
      accountType: 'credit_card',
    });
  }, [openWithValue]);

  return (
    <SideView.Standard
      isRoot
      isOpen={ isOpen }
      title="Add New Account"
      onCancel={ onCancel }
      onDone={ onDone }
    >
      { () => (
        <div className="add-payment-account-view">
          <setupAccount.Component { ...setupAccount.props } isBlankPage={ isBlankPage } />
          <p className="m-b-20">
            Note: Do not use personal Credit/Debit cards for business expenses.
          </p>
          <Button
            bsColor="blue"
            className="secure-add-account-btn add-secure-bank-account-btn"
            onClick={ handleAutoAddBankAccount }
          >
            <span className="icon icon-plaid font-20" />
            Securely Add Bank Account
          </Button>
          <Button
            bsColor="blue"
            className="secure-add-account-btn add-secure-credit-card-account-btn"
            onClick={ handleAutoAddCreditCardAccount }
          >
            <span className="icon icon-plaid font-20" />
            Securely Add Credit Card Account
          </Button>
          <Button
            bsStyle="link"
            className="pointer manual-add-account-link add-manual-bank-account-btn"
            style={ { marginTop: '30px' } }
            onClick={ handleManualAddBankAccount }
          >
            Add Bank Account Manually
          </Button>
          <hr style={ { margin: 0 } } />
          <Button
            bsStyle="link"
            className="pointer manual-add-account-link add-manual-credit-card-account-btn"
            onClick={ handleManualAddCreditCardAccount }
          >
            Add Credit Card Account Manually
          </Button>
        </div>
      ) }
    </SideView.Standard>
  );
};

export default NewAccount;
