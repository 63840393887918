import { TID } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

interface IAddAchBankParams {
  businessId: TID,
  vendorId: TID,
}

const postAchBankAccount = (
  params: IAddAchBankParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/business_vendors/link_multiple_to_ach_bank',
    underscoreKeys({ ...params }),
  );
};

export {
  IAddAchBankParams,
  postAchBankAccount,
};
