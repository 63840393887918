import { IGetBusinessesParams, IGetBusinessesResponse } from '@src/requests/businesses';
import { IAccountBigType, IAccountSubType } from '@src/types/account_types';
import { IBusiness } from '@src/types/businesses';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountBigTypesParams {
  businessId: TID,
}

interface IGetAccountBigTypesResponse {
  accountTypes: IAccountBigType[],
}

const getAccountBigTypes = (
  params: IGetAccountBigTypesParams,
): Promise<IGetAccountBigTypesResponse> => {
  return apiGet('/api/v1/account_types/big_types', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetAccountBigTypesResponse);
};

const getAccountAllBigTypes = (): Promise<IGetAccountBigTypesResponse> => {
  return apiGet('/api/v1/account_types/all_big_types')
    .then((data) => camelizeKeys(data) as IGetAccountBigTypesResponse);
};

interface IGetAccountSubTypesParams {
  businessId: TID,
  code?: IAccountBigType['code'],
}

interface IGetAllAccountSubTypesParams {
  code?: IAccountBigType['code'],
}

interface IGetAccountSubTypesResponse {
  subTypes: IAccountSubType[],
}

const getAccountSubTypes = (
  params: IGetAccountSubTypesParams,
): Promise<IGetAccountSubTypesResponse> => {
  return apiGet('/api/v1/account_types/sub_types', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetAccountSubTypesResponse);
};

const getAccountAllSubTypes =
  (params: IGetAllAccountSubTypesParams): Promise<IGetAccountSubTypesResponse> => {
    return apiGet('/api/v1/account_types/all_sub_types', underscoreKeys(params))
      .then((data) => camelizeKeys(data) as IGetAccountSubTypesResponse);
  };

interface IGetAccountTypeCategoriesParams {
  businessId: TID,
  subtypeCode?: IAccountSubType['subtypeCode'],
}

interface IGetAccountAllTypeCategoriesParams {
  subtypeCode?: IAccountSubType['subtypeCode'],
}

interface IGetAccountTypeCategoriesResponse {
  chartOfAccounts: IChartOfAccount[],
}

const getAccountTypeCategories = (
  params: IGetAccountTypeCategoriesParams,
): Promise<IGetAccountTypeCategoriesResponse> => {
  return apiGet('/api/v1/account_types/categories_by_account_type', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetAccountTypeCategoriesResponse);
};

const getAccountAllTypeCategories = (
  params: IGetAccountAllTypeCategoriesParams,
): Promise<IGetAccountTypeCategoriesResponse> => {
  return apiGet('/api/v1/account_types/all_categories_by_account_type', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetAccountTypeCategoriesResponse);
};

const getAccountAllBusinesses = (
  params: IGetBusinessesParams,
): Promise<IGetBusinessesResponse> => {
  return apiGet('/api/v1/account_types/all_businesses', underscoreKeys(params))
    .then((data) => {
      const cdata = <any>camelizeKeys(data);
      return {
        meta: {
          totalCount: cdata.count,
        },
        collection: cdata.businesses as IBusiness[],
      };
    });
};

export {
  IGetAccountBigTypesParams,
  IGetAccountBigTypesResponse,
  IGetAccountSubTypesParams,
  IGetAllAccountSubTypesParams,
  IGetAccountSubTypesResponse,
  IGetAccountTypeCategoriesParams,
  IGetAccountTypeCategoriesResponse,
  IGetAccountAllTypeCategoriesParams,
  getAccountBigTypes,
  getAccountSubTypes,
  getAccountTypeCategories,
  getAccountAllBigTypes,
  getAccountAllSubTypes,
  getAccountAllTypeCategories,
  getAccountAllBusinesses,
};
