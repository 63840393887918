import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

interface IReviewMergeVendorFooterProps {
  globalVendor?: IBusinessVendor,
  isSelected: boolean,
  onChecked: () => void,
}

const ReviewMergeVendorFooter = ({
  globalVendor,
  isSelected,
  onChecked,
}: IReviewMergeVendorFooterProps) => {
  if (!globalVendor) return null;

  return (
    <div>
      <div className="checkbox checkbox-primary">
        <input
          checked={ Boolean(isSelected) }
          className="pointer"
          id="by-check-box"
          type="checkbox"
          onChange={ onChecked }
        />
        <label className="font-12 m-l-10" htmlFor="by-check-box">
          {
            globalVendor.isClaimed
              ? (
                <span>
                  By selecting this check box you are confirming
                  you have reviewed this vendors information and understand you will be paying
                  this vendor with this information going forward.
                </span>
              )
              : (
                <span>
                  By Selecting this check box you are confirming
                  you have reviewed this vendors information and
                  that it will be changed your &quot;My Vendors&quot; list.
                </span>
              )
          }
        </label>
      </div>
    </div>
  );
};

export default React.memo(ReviewMergeVendorFooter);
