import React from 'react';

import styles from './styles.module.scss';

interface ICountAvatarProps {
  count: number,
  size: number,
}

const CountAvatar = ({
  count,
  size,
}: ICountAvatarProps) => {
  return (
    <span
      className="text-center display-flex"
      style={ {
        height:       `${size}px`,
        width:        `${size}px`,
        border:       '1px solid lightgray',
        borderRadius: '50%',
      } }
    >
      <span className={ styles['count-avatar'] }>
        +
        {count}
      </span>
    </span>
  );
};

export default CountAvatar;
