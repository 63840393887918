import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetExpenseReportsServiceDocumentsResponse } from '@src/requests/expense_reports/expense_reports_service_documents';
import { IExpenseReportsServiceDocument, TExpenseReportsServiceDocumentsSortColumn } from '@src/types/expense_reports/expense_reports_service_documents';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

import styles from './styles.module.scss';

interface IExpenseReportsTrashTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetExpenseReportsServiceDocumentsResponse, Error>,
  records: IExpenseReportsServiceDocument[],
  sorting: ISorting<TExpenseReportsServiceDocumentsSortColumn>,
}

const ExpenseReportsTrashTable = ({
  infiniteQuery,
  records,
  sorting,
}: IExpenseReportsTrashTableProps) => {
  return (
    <CollectionTable<IExpenseReportsServiceDocument, TExpenseReportsServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      className={ styles['table-section'] }
      height="750px"
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<
          IExpenseReportsServiceDocument,
          TExpenseReportsServiceDocumentsSortColumn
        >
        name="status"
        sortColumn="status"
        title="Status"
        value={ (document) => document.state }
        width="50%"
      />
      <CollectionTable.DateColumn<
          IExpenseReportsServiceDocument,
          TExpenseReportsServiceDocumentsSortColumn
        >
        name="paymentDate"
        sortColumn="payment_date"
        title="Payment Date"
        value={ (document) => document.paymentDate }
        width="50%"
      />
      <CollectionTable.AmountColumn<
          IExpenseReportsServiceDocument,
          TExpenseReportsServiceDocumentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (document) => document.amount }
        width="120px"
      />
    </CollectionTable>
  );
};

export default React.memo(ExpenseReportsTrashTable);
