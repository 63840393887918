import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Tooltip from '@src/components/ui_v2/tooltip';
import {
  BankTransfersNavIcon,
  ChargebackNavIcon,
  EquityLiabilityAssetsIcon,
  ExpensesNavIcon,
  LoanPaymentsIcon,
  PayrollLiabilityNavIcon,
  RevenueNavIcon,
  UnverifiedIcon,
} from '@src/components/utils/icomoon';

interface ITransactionIconProps {
  document: ITransactionServiceDocument,
}

const TransactionIcon = ({
  document,
}: ITransactionIconProps) => {
  let icon: React.ReactNode | null = null;
  let title: string | null = null;

  if (document.state === 'unverified') {
    icon = <UnverifiedIcon fontSize={ 18 } />;
    title = 'Unverified';
  } else if (
    window.Docyt.Common.Constants.EXPENSE_TRANSACTION_TYPES.includes(document.transactionType)
  ) {
    icon = <ExpensesNavIcon fontSize={ 28 } />;
    title = 'Expense';
  } else if (
    window.Docyt.Common.Constants.REVENUE_TRANSACTION_TYPES.includes(document.transactionType)
  ) {
    icon = <RevenueNavIcon fontSize={ 28 } />;
    title = 'Revenue';
  } else if (
    document.transactionType === window.Docyt.Common.Constants.TRANSACTION_TYPES.TRANSFER_TYPE
  ) {
    icon = <BankTransfersNavIcon fontSize={ 28 } />;
    title = 'Transfer';
  } else if (
    document.transactionType === window.Docyt.Common.Constants.TRANSACTION_TYPES.PAYROLL_TYPE
  ) {
    icon = <PayrollLiabilityNavIcon fontSize={ 28 } />;
    title = 'Payroll';
  } else if (
    window.Docyt.Common.Constants.BALANCE_SHEET_TRANSACTION_TYPES.includes(document.transactionType)
  ) {
    icon = <EquityLiabilityAssetsIcon fontSize={ 28 } />;
    title = 'Equity';
  } else if (
    document.transactionType === window.Docyt.Common.Constants.TRANSACTION_TYPES.CHARGEBACK_TYPE
  ) {
    icon = <ChargebackNavIcon fontSize={ 28 } />;
    title = 'Chargeback';
  } else if (
    document.transactionType === window.Docyt.Common.Constants.TRANSACTION_TYPES.LOAN_PAYMENTS_TYPE
  ) {
    icon = <LoanPaymentsIcon fontSize={ 28 } />;
    title = 'Loan Payments';
  }

  if (!icon) return null;

  return (
    <Tooltip.Hover content={ title }>
      { icon }
    </Tooltip.Hover>
  );
};

export default React.memo(TransactionIcon);
