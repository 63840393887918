import React from 'react';

import { useReportContext } from '@src/hooks/contexts/report_context';
import { IBusiness } from '@src/types/businesses';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import { useCopyMappingModal } from './copy_mapping_modal';

interface ICopyMappingActionProps {
  currentBusiness: IBusiness,
  templateId: string,
}

const CopyMappingAction = ({
  currentBusiness,
  templateId,
}: ICopyMappingActionProps): JSX.Element => {
  const report = useReportContext();
  const alert = useCopyMappingModal();

  return (
    <>
      <alert.Component
        { ...alert.props }
        currentBusiness={ currentBusiness }
        tarReportId={ report.id }
        templateId={ templateId }
      />
      <ActionsDropdown.Action
        name="Copy mapping from other Business"
        onClick={ alert.open }
      />
    </>
  );
};

export default CopyMappingAction;
