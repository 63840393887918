import { TLabelColumn, TModel } from './types';

const CollectionTableLabelColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TLabelColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableLabelColumn;
