import React, { useCallback } from 'react';

import { useRefreshMultiEntityReport } from '@src/hooks/queries/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { UpdateReportIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '../styles.module.scss';

const UpdateReportAction = ({ item }: { item: IMultiBusinessReportDetail }) => {
  const refreshReport = useRefreshMultiEntityReport();
  const { mutate } = refreshReport;

  const handleRefreshReport = useCallback(() => {
    mutate(item.id);
  }, [item.id, mutate]);

  return (
    <>
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <UpdateReportIcon fontSize={ 18 } /> }
        title="Update Report"
        onSelect={ handleRefreshReport }
      />
      <MutationStatus mutation={ refreshReport } />
    </>
  );
};

export default React.memo(UpdateReportAction);
