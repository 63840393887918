module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="flex-wrapper relative document-clickable pointer">\n  <div class="checkbox checkbox-primary m-l-15 m-r-15">\n    <input type="checkbox" id="inbox-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_selected ? 'checked' : ''));
    
      __out.push(' class="check-inbox-document pointer">\n    <label for="inbox-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n  <span class="icon-inbox-document font-36"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n  <p class="font-16 font-semi-bold inline-block m-l-15 m-t-10 vertical-align-top">\n    ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n  </p>\n</td>\n<td class="inbox-created-at-column relative text-right">\n  <span class="font-12 p-l-10" style="color: #667786;">');
    
      __out.push(__sanitize(moment().diff(moment(this.created_at), 'days') < 10 ? moment(this.created_at).fromNow() : moment(this.created_at).format('D MMMM')));
    
      __out.push('</span>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}