import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useDestroyRevenueReconciliation } from '@src/hooks/queries/revenue_reconciliations';
import { TUUID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import RelatedDepositsList from './related_deposits_list';

interface IUnreconcileModalProps extends IUseModalProps {
  revenueReconciliationId: TUUID,
  paymentProcessorName: string,
}

const UnreconcileModal = ({
  revenueReconciliationId,
  isOpen,
  onCancel,
  onDone,
  paymentProcessorName,
}: IUnreconcileModalProps): JSX.Element => {
  const destroyMutation = useDestroyRevenueReconciliation();

  const { mutate: destroy } = destroyMutation;
  const handleProceed = useCallback(() => {
    destroy({ revenueReconciliationId }, { onSuccess: onDone });
  }, [destroy, revenueReconciliationId, onDone]);

  return (
    <>
      <MutationStatus
        mutation={ destroyMutation }
        successMessage="Unreconciliation has been finished."
      />
      <Modal.Standard
        dialogClassName="modal-confirm"
        proceedTitle="Unreconcile"
        show={ isOpen }
        title="Unreconcile the deposit"
        onCancel={ onCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="related_transactions">
            <p>
              Are you sure you want to unreconcile the deposit?
              The following revenue matches with the selected deposit
            </p>
            <RelatedDepositsList
              paymentProcessorName={ paymentProcessorName }
              revenueReconciliationIds={ [revenueReconciliationId] }
            />
          </div>
        )}
      </Modal.Standard>
    </>
  );
};

const useUnreconcileModal = makeUseModal(UnreconcileModal);

export {
  IUnreconcileModalProps,
  UnreconcileModal,
  useUnreconcileModal as default,
};
