import React from 'react';

import { TID } from '@src/types/common';

import BankStatementDetailsModal from '@src/components/banking_accounts/bank_statements/bank_statement_details/details_modal';
import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

interface IBankStatementApprovePageProps {
  businessId: TID,
  docytId: string,
  isAdmin: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const BankStatementApprovePage = ({
  businessId,
  docytId,
  isAdmin,
  onDone,
  onCancel,
}: IBankStatementApprovePageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <BankStatementDetailsModal
          isOpen
          businessId={ businessId }
          isAdmin={ isAdmin }
          openValue={ { docytId } }
          onCancel={ onCancel }
          onDone={ onDone }
        />
      </BusinessProvider>
    </Root>
  );
};

export default BankStatementApprovePage;
