module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n    <div class=\'modal-dialog edit-invoice-modal\'>\n        <div class=\'modal-content\'>\n            <div class="modal-header">\n                <h1 class="modal-title text-center font-semi-bold">\n                    ');
    
      if (this.is_imported) {
        __out.push('\n                    Delete Invoice Imported from QuickBooks\n                    ');
      } else {
        __out.push('\n                        ');
        if (this.is_paid && this.count_paid_invoices > 1) {
          __out.push('\n                        Delete Documents\n                        ');
        } else {
          __out.push('\n                        Delete Document\n                        ');
        }
        __out.push('\n                    ');
      }
    
      __out.push('\n                </h1>\n            </div>\n            <div class=\'modal-body\'>\n                ');
    
      if (this.is_imported) {
        __out.push('\n                    <h4 class="font-14 line-height-20">Are you sure you want to delete this invoice?<br><br> It has been imported from QuickBooks as a read only invoice and will only be deleted in Docyt. Therefore, in order to permanently delete it, you will also need to delete it in QuickBooks.</h4>\n                ');
      } else {
        __out.push('\n                    ');
        if (this.is_paid) {
          __out.push('\n                        <h4 class="font-14 m-b-20">Are you sure you want to delete this document?</h4>\n                        ');
          if (this.count_paid_invoices > 1) {
            __out.push('\n                            <p class="font-14">&bullet; It has been paid with other invoices.</p>\n                            <p class="font-14">&bullet; All Bill and Bill payment will be removed from QuickBooks.</p>\n                            <p class="font-14">&bullet; All Invoices will move to Trash section in AP service.</p>\n                            <h4 class="font-14 m-t-20 line-height-20">You will be able to restore them in the Settings → Trash section in Accounts Payable.</h4>\n                        ');
          } else {
            __out.push('\n                            <p class="font-14">&bullet; Bill and Bill payment will be removed from QuickBooks</p>\n                            <p class="font-14">&bullet; Invoice will move to Trash section in AP service</p>\n                            <h4 class="font-14 m-t-20 line-height-20">You will be able to restore it in the Settings → Trash section in Accounts Payable.</h4>\n                        ');
          }
          __out.push('\n                    ');
        } else {
          __out.push('\n                        <h4 class="font-14 text-center line-height-30">Are you sure you want to delete this invoice?<br> This can not be reversed.</h4>\n                    ');
        }
        __out.push('\n                ');
      }
    
      __out.push('\n            </div>\n            <div class=\'modal-footer\'>\n                <a class=\'cancel-link cancel pointer\'>Cancel</a>\n                <button type=\'button\' class="btn btn-danger ladda-button confirm-js" tabindex="10" data-color="red" data-style="expand-right" data-integration-test="modal-confirm">\n                    <span class="ladda-label">');
    
      __out.push(__sanitize(this.is_imported ? 'Delete from Docyt' : 'Delete'));
    
      __out.push('</span>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}