import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { getFilesFromDragEvent, getFirstFileFromDragEvent } from '@src/utils/file_helpers';

interface IUploadDocumentDropzoneProps {
  children: React.ReactNode,
  isUploading: boolean,
  onFileSelected?: (file: File) => void,
  onFilesSelected?: (files: File[]) => void,
}

const isInsideOfBounds = (e: React.DragEvent) => {
  const pointX = e.pageX;
  const pointY = e.pageY;
  return pointX || pointY;
};

const UploadDocumentDropzone = ({
  children,
  isUploading,
  onFileSelected,
  onFilesSelected,
}: IUploadDocumentDropzoneProps): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive((current) => (isUploading ? false : current));
  }, [isUploading, setIsActive]);

  const handleDropzoneHighlight = useCallback((e) => {
    e.preventDefault();
    if (isUploading) return;
    if (isActive) return;

    setIsActive(Boolean(isInsideOfBounds(e)));
  }, [isUploading, isActive, setIsActive]);

  const handleDropzoneUnhighlight = useCallback((e) => {
    e.preventDefault();
    if (isUploading) return;

    setIsActive(!isInsideOfBounds(e));
  }, [isUploading, setIsActive]);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    if (isUploading) return;

    e.dataTransfer.dropEffect = 'copy';

    const file = getFirstFileFromDragEvent(e);
    if (file && onFileSelected) onFileSelected(file);

    const files = getFilesFromDragEvent(e);
    if (files && onFilesSelected) onFilesSelected(files);
  }, [onFileSelected, onFilesSelected, isUploading]);

  const classes = classNames('file-drop-zone relative', { 'drop-zone-active': isActive });

  return (
    <div className="update-file-container">
      <div
        className={ classes }
        onDragEnd={ handleDropzoneUnhighlight }
        onDragEnter={ handleDropzoneHighlight }
        onDragLeave={ handleDropzoneUnhighlight }
        onDragOver={ handleDropzoneHighlight }
        onDrop={ handleDrop }
      >
        { children }
      </div>
    </div>
  );
};

export default UploadDocumentDropzone;
