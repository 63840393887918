import React, { useEffect } from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import useSimilarTransactionsModal from './similar_transactions_modal';

const EntryPoint: React.FC<{ id: TID, businessId: TID }> = ({ id, businessId }) => {
  const similarTransactionsModal = useSimilarTransactionsModal();

  useEffect(() => {
    similarTransactionsModal.open(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <similarTransactionsModal.Component
        businessId={ businessId }
        id={ id }
        { ...similarTransactionsModal.props }
      />
    </Root>
  );
};
export default EntryPoint;
