import React, { useState } from 'react';

import { journalEntriesPath } from '@src/routes';
import { TID } from '@src/types/common';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '@src/components/header/styles.module.scss';

const JournalEntriesAction: React.FC<{ businessId: TID }> = ({ businessId }) => {
  const [journalEntriesEnabled, setJournalEntriesEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.JOURNAL_ENTRIES_FLAG,
      String(businessId),
    ),
  );

  if (journalEntriesEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.JOURNAL_ENTRIES_FLAG,
      String(businessId),
    ).then((res) => {
      setJournalEntriesEnabled(res);
    });
  }

  if (journalEntriesEnabled) {
    return (
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href={ journalEntriesPath(businessId) }
        title="Journal Entries"
      />
    );
  }
  return null;
};

export default JournalEntriesAction;
