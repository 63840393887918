import React from 'react';

import { TAmount } from '@src/types/common';
import { currencyFormater, currencyFormater2 } from '@src/utils/currency';

import Tooltip from '@src/components/ui/tooltip';

import styles from './styles.module.scss';

interface IBalanceInfoProps {
  closingBalance: TAmount,
  bankFeedBalance: number,
  beginningBalance: string,
  deposit: number,
  withdrawals: number,
  charges: number,
  payments: number,
  isCreditCard: boolean,
}

const BalanceInfo = ({
  closingBalance,
  bankFeedBalance,
  beginningBalance,
  deposit,
  withdrawals,
  charges,
  payments,
  isCreditCard,
}: IBalanceInfoProps): JSX.Element => {
  const difference = parseFloat(closingBalance) - bankFeedBalance;

  const tooltipLabel = isCreditCard
    ? 'Difference = statement closing balance- (beginning balance + Charges - Payments)'
    : 'Difference = statement closing balance- (beginning balance + deposits - withdrawal)';

  return (
    <div className={ styles['balance-info-table'] }>
      <div className={ styles['bank-feed-balance-info'] }>
        <div className={ styles['bank-feed-balance-tree'] }>
          <span>
            Bank Feed Balance:
            {' '}
            <span className="font-bold">{ currencyFormater(bankFeedBalance) }</span>
          </span>
          <div className={ styles.tree }>
            <div className={ styles['tree-item'] }>
              <div className={ styles['table-item'] }>
                <span className="font-12">Beginning Balance</span>
                <span className="font-bold font-14">{ currencyFormater(beginningBalance) }</span>
              </div>
            </div>
            <div className={ styles['tree-item'] }>
              <span className={ styles['math-symbol'] }>+</span>
            </div>
            <div className={ styles['tree-item'] }>
              <div className={ styles['table-item'] }>
                <span className="font-12">{ isCreditCard ? 'Charges' : 'Deposit' }</span>
                <span className="font-bold font-14">{ isCreditCard ? currencyFormater2(Math.abs(charges)) : currencyFormater2(deposit) }</span>
              </div>
            </div>
            <div className={ styles['tree-item'] }>
              <span className={ styles['math-symbol'] }>-</span>
            </div>
            <div className={ styles['tree-item'] }>
              <div className={ styles['table-item'] }>
                <span className="font-12">{ isCreditCard ? 'Payments' : 'Withdrawals'}</span>
                <span className="font-bold font-14">{ isCreditCard ? currencyFormater2(payments) : currencyFormater2(Math.abs(withdrawals)) }</span>
              </div>
            </div>
          </div>
        </div>
        <div className={ styles['bank-feed-closing-balance'] }>
          <span className={ styles['math-symbol'] }>=</span>
          <div className={ styles['table-item'] }>
            <span className="font-12">Closing Balance</span>
            <span className="font-bold font-14">{ currencyFormater(closingBalance) }</span>
          </div>
        </div>
      </div>
      <Tooltip
        style={ { color: 'black' } }
        title={ tooltipLabel }
      >
        <div className={ styles['bank-feed-difference'] }>
          <span className={ difference === 0 ? 'font-12 in-green-1000' : 'font-12 in-red-400' }>Difference</span>
          <span className={ difference === 0 ? 'in-green-1000 font-bold' : 'in-red-400 font-bold' }>{ currencyFormater(difference) }</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default BalanceInfo;
