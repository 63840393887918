import React, { useCallback, useState, useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricValueByDate, useCreateBusinessMetricBatchValues } from '@src/hooks/queries/business_metric_value';
import { metricsPath, metricsValuePath } from '@src/routes';
import { IBusinessMetricValueByDate } from '@src/types/business_metric_value';
import { formatDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section';
import MutationStatus from '@src/components/utils/mutation_status';

import { useBusinessMetricContext } from '../business_metric_context';
import ItemActions from '../business_metric_value_details/list_item/actions';
import Filter from './filter';
import Table from './table';

import styles from '../styles.module.scss';

const BusinessMetricDimensionDetails = (): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const business = useBusinessContext();
  const businessMetric = useBusinessMetricContext();

  const { date } = useParams();

  const valueByDateQuery = useMetricValueByDate({
    date,
    businessMetricId: businessMetric.id,
  });

  const businessMetricValuesByDate = valueByDateQuery.data;

  const methods = useForm<{
    values: IBusinessMetricValueByDate[];
  }>({
    defaultValues: { values: businessMetricValuesByDate || [] },
  });

  const { handleSubmit, reset } = methods;

  const [hideInactive, setHideInactive] = useState<boolean>(true);

  useEffect(() => {
    if (businessMetricValuesByDate) {
      reset({ values: businessMetricValuesByDate });
    }
  }, [businessMetricValuesByDate, reset]);

  const createBusinessMetricBatchValues = useCreateBusinessMetricBatchValues();
  const { mutate } = createBusinessMetricBatchValues;

  const handleCancelEdit = useCallback(() => {
    setIsEditMode(false);
    reset({ values: businessMetricValuesByDate || [] });
  }, [businessMetricValuesByDate, reset]);

  const handleSaveData = useCallback((data) => {
    mutate({
      businessMetricId: businessMetric.id,
      values:           data.values,
    }, {
      onSuccess: () => {
        setIsEditMode(false);
      },
    });
  }, [mutate, businessMetric]);

  const handleEditData = useCallback(() => {
    setIsEditMode(true);
  }, []);

  return (
    <DetailsRegion>
      <MutationStatus
        mutation={ createBusinessMetricBatchValues }
        successMessage="Updated Successfully"
      />
      {!isEditMode && (
        <div className={ styles['edit-drop-down'] }>
          <ActionsDropdown>
            <ItemActions
              onEditData={ handleEditData }
            />
          </ActionsDropdown>
        </div>
      )}
      <DetailsRegion.Header
        breadcrumbs={ [
          { href: metricsPath(business.id), title: 'Industry Metrics' },
          { href: metricsValuePath(business.id, businessMetric.id), title: businessMetric.name },
        ] }
        title={ formatDate(date) }
      />
      {isEditMode && (
        <div className={ styles['edit-data-action'] }>
          <Button
            variant="link"
            onClick={ handleCancelEdit }
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={ handleSubmit(handleSaveData) }
          >
            Save
          </Button>
        </div>
      )}

      <div className={ styles['business-metric-dimension-table'] }>
        <TableSection.Panel
          filter={ (
            <Filter
              hideInactive={ hideInactive }
              onHideInactiveChanged={ setHideInactive }
            />
          ) }
        >
          <FormProvider { ...methods }>
            <Table
              businessMetric={ businessMetric }
              hideInactive={ hideInactive }
              isEditMode={ isEditMode }
              query={ valueByDateQuery }
            />
          </FormProvider>
        </TableSection.Panel>
      </div>
    </DetailsRegion>
  );
};

export default React.memo(BusinessMetricDimensionDetails);
