import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { AttachIcon } from '@src/components/utils/icomoon';

import JournalDrawer from './journal_drawer';
import Table from './table';
import useDrawer from './use_drawer';

const AddBtn = () => {
  const { isOpen, open, close } = useDrawer();

  return (
    <>
      <Button variant="primary" onClick={ open }>
        <AttachIcon fontSize={ 16 } />
        Add
      </Button>
      <JournalDrawer drawerControl={ { isOpen, open, close } } title="Add Journal Entry">
        <Table close={ close } />
      </JournalDrawer>
    </>
  );
};

export default AddBtn;
