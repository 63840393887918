import React, { useEffect, useMemo, useState } from 'react';

import toastr from '@lib/toastr';
import {
  useGetPortfolioReportConfigurations,
  useGetPortfolioReportExportConfiguration,
  useUpdatePortfolioReportExportConfiguration,
} from '@src/hooks/queries/report_service/report_configurations';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IPortfolioReportExportConfigParams } from '@src/types/report_service/report_configurations';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ExportAsExcelIcon, SettingsCogIcon } from '@src/components/utils/icomoon';

import { useMultiEntityDownloadConsolidatedReportModal } from '../modals/download_consolidated_report';
import { useDownloadPortfolioReportConfigurationModal } from '../modals/download_report_configuration';

import styles from '../styles.module.scss';

const PortfolioListHeaderAction = ({
  multiBusinessReports,
  onCreateExportData,
}: {
  onCreateExportData: (value: boolean) => void,
  multiBusinessReports: IMultiBusinessReportDetail[]
}) => {
  const [updateData, setUpdateData] = useState<IPortfolioReportExportConfigParams>();
  const { mutate } = useUpdatePortfolioReportExportConfiguration();

  const reportConfig = useGetPortfolioReportExportConfiguration();
  const reportConfigData = useMemo(() => reportConfig.data, [reportConfig.data]);

  const handleUpdate = () => {
    if (updateData) {
      mutate(
        updateData,
        {
          onSuccess: () => {
            reportConfig.refetch();
            toastr.success(
              'Settings updated successfully', // message
              'Portfolio Report Export Setting', // title
            );
          },
        },
      );
    }
  };

  const ConfirmModal = useConfirmDeleteModal({
    onDone: handleUpdate,
  });

  const modal = useMultiEntityDownloadConsolidatedReportModal();
  const ConfigModal = useDownloadPortfolioReportConfigurationModal();

  const configQuery = useGetPortfolioReportConfigurations();
  const configurations = useMemo(() => configQuery.data || [], [configQuery.data]);

  const hideExportSetting = localStorage.getItem('hidePortfolioExportSetting') === 'true';
  const [isHideModalEnabled, setIsHideModalEnabled] = useState(hideExportSetting);
  const [showCustomizeOption, setShowCustomizeOption] = useState(false);
  const [isEditSetting, setIsEditSetting] = useState(false);

  useEffect(() => {
    if (reportConfigData) {
      setShowCustomizeOption(true);
    }
  }, [reportConfigData]);

  const handleSubmit = (state: boolean) => {
    reportConfig.refetch();
    setIsHideModalEnabled(state);
    setShowCustomizeOption(true);
  };

  const onUpdateConfig = (data: IPortfolioReportExportConfigParams) => {
    setUpdateData(data);
    ConfirmModal.open();
  };

  return (
    <>
      <ActionsDropdown className={ `${styles['actions-dropdown']} m-r-15 m-t-5` }>
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
          title="Download Excel"
          onSelect={ () => {
            if (!isHideModalEnabled) {
              setIsEditSetting(false);
              ConfigModal.open();
            } else {
              modal.open();
            }
          } }
        />
        {showCustomizeOption && (
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <SettingsCogIcon fontSize={ 18 } /> }
            style={ { minWidth: 200 } }
            title="Customize"
            onSelect={ () => {
              setIsEditSetting(true);
              ConfigModal.open();
            } }
          />
        )}
      </ActionsDropdown>
      <ConfirmModal.Component
        { ...ConfirmModal.props }
        confirmStyle="primary"
        confirmTitle="Update"
        text={ (
          <div>
            <p>Updating the configuration will override the existing settings for reports download.</p>
          </div>
        ) }
        title="Are you sure you want to update?"
      />
      <modal.Component
        { ...modal.props }
        reportConfigData={ reportConfigData }
        onCreateExportData={ onCreateExportData }
      />
      <ConfigModal.Component
        { ...ConfigModal.props }
        configurations={ configurations }
        handleSubmit={ (state) => handleSubmit(state) }
        isEdit={ isEditSetting }
        multiBusinessReports={ multiBusinessReports }
        reportConfigData={ reportConfigData }
        showConfigModal={ !isHideModalEnabled }
        onCreateExportData={ (state) => onCreateExportData(state) }
        onUpdate={ (data) => onUpdateConfig(data) }
      />
    </>
  );
};

export default React.memo(PortfolioListHeaderAction);
