import React, { useCallback } from 'react';

import castArray from 'lodash/castArray';
import Select, { Props, StylesConfig } from 'react-select';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import { IInputCommonProps, TOption } from '../types';
import {
  ReactSelectClearIndicator,
  ReactSelectDropdownIndicator,
  ReactSelectMenuWithFooter,
} from './helpers';
import SelectResponseOutside from './select_response_outside';
import { reactSelectStyles } from './styles';
import { ReactSelectClassNamePrefix } from './utils';

interface IReactSelectOutsideInputProps<OptionType extends TOption>
  extends IInputCommonProps,
    Props<OptionType, true> {
}

const ReactSelectOutsideInput = <OptionType extends TOption, >({
  components: componentsProp = {},
  hideClear,
  menuFooter,
  size,
  styles,
  value,
  onChange,
  ...props
}: IReactSelectOutsideInputProps<OptionType>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  const MenuWithFooter = useCallback(
    (menuListProps) => {
      return (
        <ReactSelectMenuWithFooter { ...menuListProps } menuFooter={ menuFooter } />
      );
    },
    [menuFooter],
  );

  const handleRemoveItem = useCallback((element: OptionType) => {
    if (!value || !onChange) return;

    const values = castArray(value);
    onChange(
      values.filter((item) => item.value !== element.value),
      {
        action:       'remove-value',
        removedValue: element,
      },
    );
  }, [onChange, value]);

  return (
    <>
      <Select
        className={ classes }
        classNamePrefix={ ReactSelectClassNamePrefix }
        components={ {
          ClearIndicator:     ReactSelectClearIndicator,
          DropdownIndicator:  value ? () => null : ReactSelectDropdownIndicator,
          IndicatorSeparator: () => null,
          Menu:               MenuWithFooter,
          ...componentsProp,
        } }
        isClearable={ !hideClear }
        styles={ {
          ...(reactSelectStyles({ size }) as StylesConfig<OptionType, true>),
          ...styles,
        } }
        value={ value || null }
        onChange={ onChange }
        { ...selectProps }
      />
      <SelectResponseOutside
        value={ value }
        onRemove={ handleRemoveItem }
      />
    </>
  );
};

export {
  IReactSelectOutsideInputProps,
  ReactSelectOutsideInput as default,
};
