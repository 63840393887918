import React, { FC, ReactNode } from 'react';

import Drawer from 'react-modern-drawer';

import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { DownloadDocumentIcon, NotAChargebackIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface DrawerControl {
  isOpen: boolean;
  isDownloading?: boolean;
  open: () => void;
  close: () => void;
  download?: () => void;
}

interface JournalDrawerProps {
  drawerControl: DrawerControl;
  title: string;
  children: ReactNode;
}

interface DownloadIconProps {
  isDownloading?: boolean;
  download?: () => void;
}

const DownloadIcon: FC<DownloadIconProps> = ({ isDownloading, download }) => {
  if (!download) return null;

  if (isDownloading) {
    return <SpinnerIcon spin fontSize={ 20 } />;
  }

  return <DownloadDocumentIcon pointer fontSize={ 20 } inColor="black" onClick={ download } />;
};

const JournalDrawer: FC<JournalDrawerProps> = ({ drawerControl, title, children }) => {
  const { isOpen, close, download, isDownloading } = drawerControl;

  return (
    isOpen ? (
      <Drawer
        direction="bottom"
        open={ isOpen }
        size="80vh"
        onClose={ close }
      >
        <div className={ styles.container }>
          <div className={ styles.header }>
            <div className={ styles['header-col'] } />
            <div className={ styles['header-col'] }>
              <div className={ styles['header-title'] }>
                <span>{title}</span>
              </div>
            </div>
            <div className={ styles['header-col'] }>
              <div className={ styles['header-controls'] }>
                <DownloadIcon download={ download } isDownloading={ isDownloading } />
                <NotAChargebackIcon pointer fontSize={ 20 } onClick={ close } />
              </div>
            </div>
          </div>
          {children}
        </div>
      </Drawer>
    ) : null
  );
};

export default JournalDrawer;
