import { object } from 'yup';

import { IBusinessVendor } from '@src/types/business_vendors';

const SelectMergeableVendorValidation = object({
  mergeableVendor: object().required('Vendor to merge is required.'),
});

interface ISelectMergeableVendorFormValues {
  mergeableVendor?: IBusinessVendor,
}

export {
  ISelectMergeableVendorFormValues,
  SelectMergeableVendorValidation,
};
