module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n    <div class="m-t-40 m-b-40 relative text-right header-panel m-l-auto m-r-auto">\n        ');
    
      if (!this.sso_token) {
        __out.push('\n        <h1 class="font-24 font-bold text-center">Sign in</h1>\n        ');
      } else {
        __out.push('\n        <h1 class="font-24 font-bold text-center">Impersonate Sign in</h1>\n        ');
      }
    
      __out.push('\n    </div>\n    <div class="background-white sign-in-main-panel m-l-auto m-r-auto">\n        <form class="login-form-sign-in">\n            <div class="padding-50">\n                <div class="row relative m-t-10 m-b-30">\n                ');
    
      if (this.sso_token) {
        __out.push('\n                <div class="main-input__wrap">\n                    <div class="profile-input-wrap">\n                        <input class="form-control p-l-10" type="sso_token" value="sso_token: ');
        __out.push(__sanitize(this.sso_token));
        __out.push('" placeholder="Enter Email" readonly></input>\n                    </div>\n                </div>\n                ');
      }
    
      __out.push('\n                ');
    
      if (!this.isEmailSelected) {
        __out.push('\n                <div class="main-input__wrap">\n                    <div class="profile-input-wrap">\n                        <input class="form-control phone-input"  type="tel" name="phone" placeholder="Enter Phone Number">\n                        <p class="phone-valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class=\'main-input__hint in-red-400\' id="invalid-ph">This phone number is not a valid phone number.</p>\n                </div>\n                <div class="main-input__wrap">\n                    <div class="profile-input-wrap">\n                        <span class="sign-up-key-icon icon-password-key-icon"></span>\n                        <input class="form-control p-l-40 pin-code-input" type="password" placeholder="Enter Pin Code"></input>\n                        <p class="valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class="main-input__hint in-red-400" id="invalid-pin">\n                        Enter valid pin code\n                    </p>\n                    <p class="main-input__hint in-red-400" id="invalid-phone-and-pin">\n                        Provided combination of phone number and PIN code is invalid.\n                    </p>\n                    <p class="main-input__hint in-red-400" id="locked-phone-account">\n                        Your user account has been locked because of too many incorrect login attempts. Please, try again later.\n                    </p>\n                </div>\n                <div>\n                    <a class="font-14 font-semi-bold pointer" id="sign-in-email">Sign-In with email address</a>\n                </div>\n                ');
      } else {
        __out.push('\n                <div class="main-input__wrap">\n                    <div class="profile-input-wrap">\n                        <span class="sign-up-mail-icon icon-mail"></span>\n                        <input class="form-control p-l-40 email-input" type="email" placeholder="Enter Email"></input>\n                        <p class="valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class="main-input__hint in-red-400" id="invalid-email-address">\n                      Enter valid email address\n                    </p>\n                </div>\n                <div class="main-input__wrap">\n                    <div class="profile-input-wrap">\n                        <span class="sign-up-key-icon icon-password-key-icon"></span>\n                        <input class="form-control p-l-40 password-input" type="password" placeholder="Enter Password"></input>\n                        <p class="valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class="main-input__hint in-red-400" id="invalid-password">\n                        Enter valid password\n                    </p>\n                    <p class="main-input__hint in-red-400" id="invalid-email-and-password">\n                        Provided combination of email and password is invalid.\n                    </p>\n                    <p class="main-input__hint in-red-400" id="locked-email-account">\n                        Your user account has been locked because of too many incorrect login attempts. Please, try again later.\n                    </p>\n                </div>\n                <div>\n                    ');
        if (!this.sso_token) {
          __out.push('\n                    <a class="font-14 font-semi-bold pointer" id="sign-in-phone">Sign-In with phone number</a>\n                    <a class="font-14 font-semi-bold pointer force-right" id="forgot-password-link">Forgot Password?</a>\n                    ');
        }
        __out.push('\n                </div>\n                ');
      }
    
      __out.push('\n                </div>\n            </div>\n            <div class="sign-in-action-panel">\n                ');
    
      if (!this.sso_token) {
        __out.push('\n                    <button type=\'submit\' class="btn btn-primary pull-right continue-js ladda-button" data-color="blue" data-style="expand-right" tabindex="3"><span class="ladda-label font-semi-bold">Next</span></button>\n                ');
      } else {
        __out.push('\n                <button type=\'submit\' class="btn btn-primary pull-right continue-js ladda-button" data-color="blue" data-style="expand-right" tabindex="3"><span class="ladda-label font-semi-bold">Impersonate</span></button>\n                ');
      }
    
      __out.push('\n            </div>\n        </form>\n    </div>\n        <div id="sso-sign-in-button" \n         data-global-feature-toggle=');
    
      __out.push(__sanitize(Docyt.Common.Constants.SSO_LOGIN));
    
      __out.push('\n        ></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}