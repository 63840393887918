import { object, string } from 'yup';

const managementGroupValidation = object({
  name: string().trim().required('Name is required.'),
});

interface IManagementGroupInput {
  name: string,
}

export {
  IManagementGroupInput,
  managementGroupValidation,
};
