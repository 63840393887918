import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import { DocumentIcon } from '@src/components/utils/icomoon';

interface ITransactionDocumentsActionProps {
  transaction: ITransactionServiceDocument,
  inTrash?: boolean,
}

const TransactionDocumentsAction = ({
  transaction,
  inTrash,
}: ITransactionDocumentsActionProps) => {
  const hasDocuments = transaction.hasDocuments || transaction.noDocumentRequired;

  const classes = uiStyleClassNames(
    'display-inline-flex',
    {
      pull:         'left',
      pl:           hasDocuments ? 4 : undefined,
      pt:           hasDocuments ? 7 : 5,
      widthPercent: 20,
    },
  );

  return (
    <div className={ classes }>
      {
        hasDocuments && !inTrash
          ? <DocumentIcon fontSize={ 18 } />
          : <DocumentIcon color="grey" fontSize={ 22 } />
      }
    </div>
  );
};

export default TransactionDocumentsAction;
