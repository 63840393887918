module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="table-responsive revenue-report-table-wrapper">\n  ');
    
      if (this.collection.length <= 0) {
        __out.push('\n  <div class="text-center add-revenue-report-container">\n    <p><span class="icon-revenue-reports font-80"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span></p>\n    <p class="font-20 font-semi-bold">Add a Revenue Report</p>\n    <p>\n      Upload a Revenue Report from your computer to get started.<br/>\n      Or just forward your revenue report to <a href="mailto:');
        __out.push(__sanitize(this.rv_service.get('upload_email')));
        __out.push('">');
        __out.push(__sanitize(this.rv_service.get('upload_email')));
        __out.push('</a>.\n    </p>\n    <p><button type=\'button\' class="btn btn-info ladda-button btn-add-report" data-color="blue" data-style="expand-right"><span class="ladda-label">Add Revenue Report</span></button></p>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="panel panel-default">\n    <table class="table bg-white">\n      <tbody>\n      </tbody>\n    </table>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}