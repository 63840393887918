module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1;
    
      __out.push('<td class="receipt-detail-column checkbox-clickable relative pointer">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" id="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push(' class="check-receipt-document pointer">\n    <label for="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="receipt-detail-column flag-panel pointer">\n  ');
    
      if (this.starred_by_id) {
        __out.push('\n  <i class=\'font-20 fa fa-star in-vivid-orange\'></i>\n  ');
      } else {
        __out.push('\n  <i class=\'font-20 fa fa-star-o in-dark-100 flag-selected\'></i>\n  ');
      }
    
      __out.push('\n</td>\n');
    
      if (!this.forVendor) {
        __out.push('\n<td class="receipt-avatar-column flex-wrapper relative receipt-clickable pointer">\n  <div class="header__userpic inline-block m-l-10">\n    <img src="');
        __out.push(__sanitize(this.vendor_avatar));
        __out.push('" class="header__userpic-img">\n  </div>\n  <p class="payee-name-truncate font-15 inline-block m-l-15 m-t-10 vertical-align-top" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize((ref = this.vendor) != null ? ref.name : void 0));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n<td class="relative pointer">\n  ');
    
      if (this.is_document_uploaded) {
        __out.push('\n  <i class="font-24 icon icon-preview relative m-l-3 preview-clickable"></i>\n  ');
      }
    
      __out.push('\n</td>\n<td>\n  ');
    
      if (this.hasPossibleMatch) {
        __out.push('\n    <a class="pointer icon icon-cash-2 font-18 possible-match-icon receipt-clickable" /></a>\n  ');
      }
    
      __out.push('\n</td>\n');
    
      if (this.type === 'unverified') {
        __out.push('\n<td class="receipt-detail-column invoice-status-panel-wrapper relative receipt-clickable pointer">\n    <span class="invoice-status-panel font-bold ');
        __out.push(__sanitize(this.label_state));
        __out.push('">');
        __out.push(__sanitize(this.check_status));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.isAccountantMember || this.canShowCategorization) {
        __out.push('\n  <td class="receipt-detail-column receipt-chart-of-account-column relative">\n    ');
        if (this.chart_of_account_name) {
          __out.push('\n    <p>\n      ');
          __out.push(__sanitize(this.chart_of_account_parent_name));
          __out.push('\n      <i class="fa fa-caret-right"></i>\n      ');
          __out.push(__sanitize(this.chart_of_account_name));
          __out.push('\n    </p>\n    ');
        }
        __out.push('\n  </td>\n');
      }
    
      __out.push('\n');
    
      if (!this.forPaymentAccount) {
        __out.push('\n<td class="receipt-detail-column receipt-payment-account-column pointer">\n  <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize((ref1 = this.payment_account) != null ? ref1.account_name : void 0));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n<td class="receipt-detail-column relative receipt-clickable pointer">\n  <span class="font-14 p-l-10">');
    
      __out.push(__sanitize(this.receipt_date_string));
    
      __out.push('</span>\n</td>\n<td class="receipt-detail-column relative receipt-clickable pointer">\n  <span class="font-14">');
    
      __out.push(__sanitize(this.document_uploader_name));
    
      __out.push('</span>\n</td>\n');
    
      if (this.forPaymentAccount || this.forVendor) {
        __out.push('\n<td class="receipt-detail-column relative receipt-clickable pointer">\n  <span class="receipt-status-panel font-semi-bold ');
        __out.push(__sanitize(this.label_state));
        __out.push('">');
        __out.push(__sanitize(this.check_status));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n<td class="qbo-status-icon-column ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' document-clickable pointer vertical-align-middle-important" style="width:50px"></td>\n<td class="receipt-amount-column relative text-right">\n</td>\n<td class="receipt-detail-column receipt-unread-messages-count-column text-right relative pointer">\n  <a class="" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat">\n    ');
    
      if (this.unread_document_messages > 0) {
        __out.push('\n    <i class="font-24 icon icon-unread relative m-l-3 unread-messages-count"><span>');
        __out.push(__sanitize(this.unread_document_messages));
        __out.push('</span></i>\n    ');
      } else {
        __out.push('\n    <!-- For Icon Read -->\n    <svg width="24px" height="24px" viewBox="0 0 24 24" class="m-l-3">\n        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n            <g id="03-Business---02-APS-Queue-01" transform="translate(-591.000000, -566.000000)" fill-rule="nonzero" stroke="#4DA1FF" stroke-width="2">\n                <g id="Group-10" transform="translate(140.000000, 368.000000)">\n                    <g id="Group-9" transform="translate(451.000000, 198.000000)">\n                        <path d="M2,20.8357864 L6.78578644,16.05 L22.7,16.05 C23.0002153,16.05 23.25,15.8002153 23.25,15.5 L23.25,1.55 C23.25,1.24978475 23.0002153,1 22.7,1 L2.55,1 C2.24978475,1 2,1.24978475 2,1.55 L2,20.8357864 Z" id="Shape"></path>\n                    </g>\n                </g>\n            </g>\n        </g>\n    </svg>\n    ');
      }
    
      __out.push('\n  </a>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}