import React from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';

import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { useUnassignedRSDCollection } from './hooks';
import Table from './table';

const UnassignedRevenue = (): JSX.Element => {
  const revenueService = useRevenueServiceContext();
  const collection = useUnassignedRSDCollection({
    revenueService,
  });
  return (
    <PageContainer sidemenu={ <Sidemenu /> }>
      <DetailsRegion>
        <DetailsRegion.Header
          title="For Review"
        />
        <Section.Provider section={ collection.section }>
          <TableSection>
            <Table
              collection={ collection }
            />
          </TableSection>
        </Section.Provider>
      </DetailsRegion>
    </PageContainer>
  );
};

export default React.memo(UnassignedRevenue);
