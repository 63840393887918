import React from 'react';

import { useGetDwollaCustomersByVendor } from '@src/hooks/queries/dwolla_customers';
import { TID } from '@src/types/common';
import { currencyFormater3 } from '@src/utils/currency';

import { dwollAddress } from '../../utils/review_payment_utils';

interface IReviewPaymentTableItemProps {
  businessId: TID,
  vendorId?: TID,
  amounts: number,
}

const ReviewPaymentTableItem = ({
  businessId,
  vendorId,
  amounts,
}: IReviewPaymentTableItemProps) => {
  const { data: vendorDwollaCustomer } = useGetDwollaCustomersByVendor(
    { businessId, vendorId },
  );
  return (
    <tr>
      <td>
        {
          vendorDwollaCustomer?.customerType
            === window.configData.dwolla_customer_types.RECEIVE_ONLY
            ? (<span>{ vendorDwollaCustomer?.displayName || '---' }</span>)
            : (<span>{ vendorDwollaCustomer?.businessName || '---' }</span>)
        }
      </td>
      <td>
        <span className="in-grey-1100">
          { vendorDwollaCustomer?.dwollaFundingSource?.name }
          { ' ' }
          { vendorDwollaCustomer?.dwollaFundingSource?.last4 || '----' }
        </span>
      </td>
      <td className="in-grey-1100">
        { dwollAddress(vendorDwollaCustomer) }
      </td>
      <td>
        <span className="pull-right font-bold">
          {currencyFormater3(amounts)}
        </span>
      </td>
    </tr>
  );
};

export default React.memo(ReviewPaymentTableItem);
