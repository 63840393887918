import React, { memo } from 'react';

import ChatBody from './chat_body';
import NavBar from './nav_bar';

import styles from '../styles.module.scss';

const ChatContainer = () => {
  return (
    <div className={ styles['chat-main-container'] }>
      <NavBar />
      <ChatBody />
    </div>
  );
};

export default memo(ChatContainer);
