import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';

import { useForwardDocumentModal } from '@src/components/common/forward_document';
import { LinkButton } from '@src/components/ui/buttons';

interface IBusinessInboxForwardActionProps {
  selectedIds: number[],
  onDone: () => void,
}

const BusinessInboxForwardAction = ({
  selectedIds,
  onDone,
}: IBusinessInboxForwardActionProps): JSX.Element => {
  const business = useBusinessContext();
  const service = useInboxServiceContext();

  const forward = useForwardDocumentModal({ onDone });

  return (
    <>
      <forward.Component
        { ...forward.props }
        business={ business }
        currentServiceType={ service.type }
        documentIds={ selectedIds }
      />
      <div className="btn-group">
        <LinkButton
          className="font-16 font-semi-bold"
          disabled={ selectedIds.length === 0 }
          title="Forward"
          onClick={ forward.open }
        />
      </div>
    </>
  );
};

export default BusinessInboxForwardAction;
