import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';
import { TSubOption } from '@src/components/ui_v2/inputs';

import Input from './Input';
import Item from './item';

import styles from '../styles.module.scss';

interface IMultiSelectProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Input>, 'onClear'>
{
  placeholder?: string,
  options: TSubOption[],
  value: string[],
  onChange: (value: string[]) => void,
  className?: string,
  menuClassName?: string,
  isDisabled?: boolean
}

const MultiSelect = ({
  placeholder = 'Select items',
  options,
  value,
  onChange,
  className,
  menuClassName,
  isDisabled,
  ...props
}: IMultiSelectProps) => {
  const containerClasses = classNames(
    styles['dropdown-input'], 
    className,
    isDisabled && styles['dropdown-disabled-input'],
  );

  const handleSelect = useCallback((checked: boolean, optionValue: string) => {
    const newValue = checked ? [...value, optionValue] : value.filter((val) => val !== optionValue);
    onChange(newValue);
  }, [value, onChange]);

  const handleClear = useCallback(() => onChange([]), [onChange]);

  return (
    <Dropdown.Provider className={ containerClasses }>
      <Input
        { ...props }
        isDisabled={ isDisabled }
        placeholder={ placeholder }
        value={ value }
        onClear={ handleClear }
      />

      <Dropdown.Menu className={ menuClassName }>
        {
          options.map((option: TSubOption) => (
            <Item
              key={ option.value }
              option={ option }
              selected={ value }
              onSelect={ handleSelect }
            />
          ))
        }
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export {
  IMultiSelectProps,
  MultiSelect as default,
};
