import React, { useEffect, useState } from 'react';

import { Member } from '@sendbird/chat/groupChannel';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';

import userDefault from '@assets/images/user_default.png';

import styles from '../../styles.module.scss';

interface IMemberListProps {
  channelUrl: string;
}

const MemberList = ({
  channelUrl,
}: IMemberListProps) => {
  const [members, setMembers] = useState<Member[]>([]);
  const context = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(context);

  useEffect(() => {
    const fetchMembers = async () => {
      if (sdk && sdk.groupChannel) {
        try {
          const channel = await sdk.groupChannel.getChannel(channelUrl);
          const memberListQuery = channel.createMemberListQuery();
          const membersofChannel = await memberListQuery.next();
          setMembers(membersofChannel);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching members:', error);
        }
      }
    };
    fetchMembers();
  }, [sdk, channelUrl]);

  return (
    <div className={ styles['member-list-container'] }>
      {members?.map((item) => (
        <div key={ item.userId } className={ styles['member-list'] }>
          <div className={ styles['member-avatar'] }>
            <img
              alt={ item.nickname }
              src={ item.profileUrl }
              onError={ ({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = userDefault;
              } }
            />
          </div>
          <div className={ styles['member-title'] }>{item.nickname}</div>
        </div>
      ))}
    </div>
  );
};

export default MemberList;
