import React from 'react';

import QuickbooksCustomerInput from '@src/components/common_v2/inputs/quickbooks_customer_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IQuickbooksCustomerFieldProps extends
  React.ComponentPropsWithoutRef<typeof QuickbooksCustomerInput>, IWrapperProps {
}

const QuickbooksCustomerField = ({
  ...props
}: IQuickbooksCustomerFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <QuickbooksCustomerInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(QuickbooksCustomerField);
