import React, { useCallback, useMemo } from 'react';

import { IReviewedBankStatementState, TBankStatementState, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID, TYear } from '@src/types/common';
import { API_DATE_FORMAT, formatApiDate, formatDate, formatLastMonth, formatMonth, getUTCTimezone } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import StatementStateList from './bank_statement_state/bank_statement_state_list';

interface IBankStatementPendingReviewTableItemProps {
  businessId: TID,
  reviewedBankStatement: IReviewedBankStatementsData,
  months: number,
  year?: TYear,
  onCloseUploadModal: () => void,
}

const statementStates = (
  reviewedBankStatement: IReviewedBankStatementsData,
  year?: TYear,
  months?: number,
) => {
  let result: Array<IReviewedBankStatementState> = [];
  if (!year || !months) return result;

  const endDate = new Date(parseInt(year, 10), months - 1, 31);
  const nextDate = new Date(parseInt(year, 10), 0, 1);
  const days = [];

  for (let i = nextDate; i < endDate; nextDate.setMonth(nextDate.getMonth() + 1)) {
    days.push(formatApiDate(nextDate));
  }

  days.forEach((d) => {
    const bState = reviewedBankStatement.bankStatements.find(
      (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
      === formatMonth(getUTCTimezone(new Date(d))),
    );
    if (bState != null) {
      result = [
        ...result,
        {
          month:         formatMonth(getUTCTimezone(new Date(d))),
          id:            bState.id,
          state:         bState.state as TBankStatementState,
          statementDate: bState.date,
          approvedAt:    bState.approvedAt,
          verifiedAt:    bState.verifiedAt,
          createdAt:     bState.createdAt,
          uploadedAt:    bState.uploadedAt,
          requestedAt:   bState.requestedAt,
          docytId:       bState.docytId,
          date:          bState.date,
        }];
    } else {
      const prevStatement = reviewedBankStatement.bankStatements.find(
        (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
        === formatLastMonth(getUTCTimezone(new Date(d))),
      );

      const prevStatementDate = prevStatement ? getUTCTimezone(new Date(prevStatement.date)) : '';
      const startingDate = prevStatementDate ? prevStatementDate.setDate(prevStatementDate.getDate() + 1) : '';

      const startDate = new Date(reviewedBankStatement.startImportDate);
      startDate.setDate(1);
      result = [...result, {
        month:        formatMonth(getUTCTimezone(new Date(d))),
        state:        new Date(d) < startDate ? 'not_available' : 'missing',
        startingDate: startingDate ? formatDate(new Date(startingDate), API_DATE_FORMAT) : '',
      }];
    }
  });

  return result;
};

const BankStatementPendingReviewTableItem = ({
  businessId,
  reviewedBankStatement,
  year,
  months,
  onCloseUploadModal,
}: IBankStatementPendingReviewTableItemProps) => {
  const states = useMemo(() => {
    return statementStates(reviewedBankStatement, year, months);
  }, [months, reviewedBankStatement, year]);

  const handleClickReviewedItem = useCallback(() => {
    Backbone.history.navigate(`/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements/${reviewedBankStatement.id}?year=${year}`, { trigger: true });
  }, [businessId, reviewedBankStatement.id, year]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        style={ { color: '#0080FF' } }
        widthPercent={ 20 }
        onClick={ handleClickReviewedItem }
      >
        { reviewedBankStatement.name }
      </Table.Cell>

      <StatementStateList
        businessId={ businessId }
        rcPaymentAccountId={ reviewedBankStatement.id }
        states={ states }
        onCloseUploadModal={ onCloseUploadModal }
      />
    </Table.Row>
  );
};

export default React.memo(BankStatementPendingReviewTableItem);
