import React from 'react';

import { IUseModalProps } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';

import Actions from './actions';
import Body from './body';
import Content from './content';
import Header from './header';

interface IStandardSideViewProps {
  children: () => React.ReactNode,
  isOpen: IUseModalProps['isOpen'],
  isRoot?: boolean,
  doneTitle?: string,
  title: string,
  hideActions?: boolean,
  onCancel: IUseModalProps['onCancel'],
  onDone: IUseModalProps['onDone'],
}

const StandardSideView = ({
  children,
  isOpen,
  isRoot,
  doneTitle = 'Done',
  title,
  hideActions = false,
  onCancel,
  onDone,
}: IStandardSideViewProps): JSX.Element => {
  return (
    <Content isOpen={ isOpen } isRoot={ isRoot }>
      <Header title={ title } onClose={ onCancel } />
      <Body onClosed={ onCancel }>
        { children() }
      </Body>
      {
        !hideActions && (
          <Actions>
            <Button
              bsColor="blue"
              bsStyle="primary"
              onClick={ onDone }
            >
              { doneTitle }
            </Button>
          </Actions>
        )
      }
    </Content>
  );
};

export default React.memo(StandardSideView);
