import { UseQueryOptions, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getAppVersion } from '@src/requests/app_version';

const useAppVersion = (opts: UseQueryOptions<string, Error> | undefined = undefined) => {
  return useQuery<string, Error>(
    [QueryKey.appVersion],
    () => getAppVersion(),
    opts,
  );
};

export {
  useAppVersion,
};
