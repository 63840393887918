import React from 'react';

import { TID } from '@src/types/common';

import VerifyBankFeedModal from '@src/components/banking_accounts/bank_statements/bank_statement_details/verify_bank_feed/verify_bank_feed_modal';
import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

interface IBankStatementVerifyPageProps {
  businessId: TID,
  docytId: string,
  isAdmin: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const BankStatementVerifyPage = ({
  businessId,
  docytId,
  isAdmin,
  onDone,
  onCancel,
}: IBankStatementVerifyPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <VerifyBankFeedModal
          isOpen
          businessId={ businessId }
          docytId={ docytId }
          isAdmin={ isAdmin }
          onCancel={ onCancel }
          onDone={ onDone }
        />
      </BusinessProvider>
    </Root>
  );
};

export default BankStatementVerifyPage;
