module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var country, i, j, len, len1, state;
    
      __out.push('<div class="m-l-20 m-r-20 m-t-20">\n  <a class=\'cancel m-t-10 pull-right pointer\'>\n    <span>Close</span>\n  </a>\n  <h4 class=\'font-26 font-bold\'>');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n</div>\n<div class=\'m-t-50\'>\n  <!-- Address Area Begin -->\n  <div class="p-r-10 p-l-10">\n    <div class="inline-flex-wrapper width-100-percent">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for="">Name</label>\n      <input type="text" class="inline-wrapper form-control address-name-js" placeholder="Name" value = "');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n    </div>\n    <p class="in-red-400 m-t-5 main-input__hint p-l-120"><i class="fa fa-warning"></i> Name is required.</p>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for="">Department</label>\n      <input type="text" class="inline-wrapper form-control address-company-js" placeholder="Optional" value = "');
    
      __out.push(__sanitize(this.company));
    
      __out.push('">\n    </div>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for="">Address</label>\n      <input type="text" class="inline-wrapper form-control address-line1-js" placeholder="Street" value = "');
    
      __out.push(__sanitize(this.address_line1));
    
      __out.push('">\n    </div>\n    <p class="in-red-400 m-t-5 main-input__hint p-l-120"><i class="fa fa-warning"></i> Street is required.</p>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for=""></label>\n      <input type="text" class="inline-wrapper form-control address-line2-js" placeholder="Street (line 2) or Suite Number" value = "');
    
      __out.push(__sanitize(this.address_line2));
    
      __out.push('">\n    </div>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for=""></label>\n      <div class="inline-wrapper m-r-10">\n        <input type="text" class="form-control address-city-js" placeholder="City" value = "');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('">\n      </div>\n      <div class="inline-wrapper">\n        ');
    
      if (this.address_country === 'US') {
        __out.push('\n        <select class="form-control address-state-js" placeholder="State" >\n          ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          state = usaStateList[i];
          __out.push('\n          <option value="');
          __out.push(__sanitize(state.abbreviation));
          __out.push('" ');
          if (this.address_state === state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(state.text));
          __out.push('</option>\n          ');
        }
        __out.push('\n        </select>\n        ');
      } else {
        __out.push('\n        <input type="text" class="form-control address-state-js" placeholder="State" value = "');
        __out.push(__sanitize(this.address_state));
        __out.push('">\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <p class="in-red-400 m-t-5 main-input__hint p-l-120"><i class="fa fa-warning"></i> City and State are required.</p>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for=""></label>\n      <input type="text" class="inline-wrapper form-control address-zip-js" placeholder="ZIP" value = "');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n    </div>\n    <p class="in-red-400 m-t-5 main-input__hint p-l-120"><i class="fa fa-warning"></i> Zip is required.</p>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for=""></label>\n      <select class="inline-wrapper form-control address-country-js" placeholder="Country" >\n        ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n        <option value="');
        __out.push(__sanitize(country.code));
        __out.push('" ');
        if (this.address_country === country.code) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(country.text));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n    <p class="in-red-400 m-t-5 main-input__hint p-l-120"><i class="fa fa-warning"></i> Country is required.</p>\n    <div class="inline-flex-wrapper width-100-percent m-t-10">\n      <label class="inline-wrapper in-grey-800 p-r-15 text-right width-120px" for=""></label>\n      <div class="checkbox m-t-0 m-b-0">\n        <input type="checkbox" class="styled form-control pointer input-element global-address-js" data-type="checkbox" ');
    
      __out.push(__sanitize(this.is_global ? 'checked' : ''));
    
      __out.push('>\n        <label>Global Address</label>\n      </div>\n    </div>\n  </div>\n  <!-- Address Area End -->\n</div>\n<div class=\'p-r-10 p-l-10 m-t-30 text-center\'>\n  <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n</div>\n<div class=\'p-r-10 p-l-10 m-t-20 m-b-50 text-center\'>\n  <a class=\'cancel settings-cancel-btn\'>Cancel</a>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}