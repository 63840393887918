import React from 'react';

import { businessDashboardPath, businessDashboardWidgetPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TMongoID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards';

import { FinancialReportsIcon } from '@src/components/utils/img_icons';

import SidemenuHeader from './sidemenu_header';
import WidgetItem from './widget_item';

import styles from './styles.module.scss';

interface IDashboardsSidemenuProps {
  dashboards: IDashboard[],
  currentDashboard: IDashboard,
  widgetId: TMongoID | null,
  businessServiceInfo?: IBusinessServicesInfo,
}

const DashboardsSidemenu = ({
  dashboards,
  currentDashboard,
  widgetId,
  businessServiceInfo,
}: IDashboardsSidemenuProps) => {
  const widgets = currentDashboard.widgets || [];
  const businessId = currentDashboard.businessId;
  const dashboardId = currentDashboard.id;

  return (
    <div className={ styles['dashboards-side-menu'] }>
      <div className="service-header">
        <FinancialReportsIcon
          className={ styles['dashboards-sidemenu-icon'] }
        />
        <span className="font-16 m-l-12 font-bold">Dashboard</span>
      </div>
      <SidemenuHeader
        businessServiceInfo={ businessServiceInfo }
        currentDashboard={ currentDashboard }
        dashboards={ dashboards }
      />
      <ul className="dashboards-widgets-list">
        <WidgetItem
          key={ `${currentDashboard.id}_all_kpi_widget` }
          active={ !widgetId }
          itemUrl={ businessDashboardPath(currentDashboard.businessId, currentDashboard.id) }
          title="All KPIs"
        />
        {
          widgets.map((widget) => (
            <WidgetItem
              key={ widget.id }
              active={ widgetId === widget.id }
              itemUrl={ businessDashboardWidgetPath(businessId, dashboardId, widget.id) }
              title={ widget.name }
            />
          ))
        }
      </ul>
    </div>
  );
};

export default DashboardsSidemenu;
