import { TID, TOrderDirection } from '@src/types/common';
import { IVendorPayment, TVendorPaymentsSortColumn } from '@src/types/vendor_payments';
import { camelizeKeys, trimNumKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetVendorPaymentsParams {
    vendorId: TID;
    businessId: TID;
    filters?: object;
    perPage?: number;
    orderColumn?: TVendorPaymentsSortColumn;
    orderDirection?: TOrderDirection;
  }

interface IVendorPaymentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IVendorPayment[],
}

const getVendorPayments = (
  params: IGetVendorPaymentsParams,
): Promise<IVendorPaymentsResponse> => {
  const { vendorId, ...restParams } = params;

  return apiGet(
    `api/v1/business_vendors/${vendorId}/payments`,
    trimNumKeys({
      ...restParams,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.payments as IVendorPayment[],
    };
  });
};

export {
  IVendorPaymentsResponse,
  IGetVendorPaymentsParams,
  getVendorPayments,
};
