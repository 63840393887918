import { IVendor } from '@src/types/business_vendors';

const notAvailableText = 'Not Available';

const getVendorAddressText = (vendor: IVendor): string | null => {
  if (!vendor.addresses?.length) return null;

  const address = vendor.addresses[0];
  const addressLine = (`${address.addressLine1} ${address.addressLine2 || ''}`).trim();
  return [addressLine, address.addressCity, address.addressState, address.addressZip, address.addressCountry].join(', ');
};

export {
  getVendorAddressText,
  notAvailableText,
};
