import React, { useCallback } from 'react';

import { expenseReportsAddExpensePath, expenseReportsEmployeeSubmissionsPath, expenseReportsReimbursementCategoriesPath, expenseReportsServicePath, expenseReportsSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

import AddCircleIcon from '../utils/icomoon/add_circle';
import OpenInNewIcon from '../utils/icomoon/open_in_new';

interface IProps {
  businessId: TID
}

const ExpenseReportsSidebarMenuItems = ({ businessId }: IProps) => {
  const handleOpenAddExpensePage = useCallback(() => {
    window.open(expenseReportsAddExpensePath(businessId), '_blank');
  }, [businessId]);

  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORTS }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ expenseReportsAddExpensePath(businessId) }
          prefixIcon={ <AddCircleIcon fontSize={ 18 } /> }
          suffixIcon={ <OpenInNewIcon fontSize={ 18 } /> }
          title="Add Expense"
          onClick={ handleOpenAddExpensePage }
        />
        <ServiceSidebar.Item
          href={ expenseReportsServicePath(businessId) }
          title="My Reimbursements"
        />
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href={ expenseReportsEmployeeSubmissionsPath(businessId) }
          title="Employee Submissions"
        />
        <ServiceSidebar.Item
          href={ expenseReportsReimbursementCategoriesPath(businessId) }
          title="Reimbursement Categories"
        />
        <ServiceSidebar.Item
          href={ expenseReportsSettingsPath(businessId) }
          title="Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default ExpenseReportsSidebarMenuItems;
