module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="documents-cat-header standard-document-header font-16">\n  <a class="font-16 in-blue-400 text-decoration-blank" href = "');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('">\n    Vault\n  </a>\n  ');
    
      if (this.isDocumentsView) {
        __out.push('\n    <span class="font-20 m-l-5 m-r-5 in-grey-600">/</span>\n    <a class="font-16 in-blue-400 text-decoration-blank" href = "');
        __out.push(__sanitize(this.linkURL));
        __out.push('">\n      ');
        __out.push(__sanitize(this.title));
        __out.push('\n    </a>\n  ');
      }
    
      __out.push('\n  <span class="font-20 m-l-5 m-r-5 in-grey-600">/</span>\n  ');
    
      if (this.isDocumentsView) {
        __out.push('\n  <h1 class="font-32 font-bold m-b-20 m-t-10">\n      ');
        if (this.isPasswordView) {
          __out.push('\n        <button type="button" class="btn btn-blue-on-hover add-password-js pull-right">\n          Add Password\n        </button>\n      ');
        } else {
          __out.push('\n        <button class="btn btn-add-document font-23 pull-right">\n          <i class="icon icon-plus"></i>\n        </button>\n      ');
        }
        __out.push('\n      ');
        __out.push(__sanitize(this.subTitle));
        __out.push('\n      <p class="m-t-15 m-r-20 font-16">\n        ');
        __out.push(__sanitize(this.documentsCount));
        __out.push(' Item');
        __out.push(__sanitize(this.documentsCount !== 1 ? 's' : ''));
        __out.push('\n        <span class="in-grey-1050 font-14 font-normal m-l-10">You are only seeing documents that you have access to.</span>\n      </p>\n  </h1>\n  ');
      } else {
        __out.push('\n  ');
        if (this.isPasswordView) {
          __out.push('\n    <button type="button" class="btn btn-blue-on-hover add-password-js pull-right">\n      Add Password\n    </button>\n  ');
        } else {
          __out.push('\n    <button class="btn btn-add-document font-23 pull-right">\n      <i class="icon icon-plus"></i>\n    </button>\n  ');
        }
        __out.push('\n  <h1 class="font-32 font-bold">');
        __out.push(__sanitize(this.title));
        __out.push('</h1>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}