import React from 'react';

import ManagementGroups from '@src/components/management_groups/management_groups';
import Root from '@src/components/root';

interface IManagementGroupsPageProps {
  showNew: boolean,
}

const ManagementGroupsPage = ({
  showNew,
}: IManagementGroupsPageProps): JSX.Element => {
  return (
    <Root>
      <ManagementGroups showNew={ showNew } />
    </Root>
  );
};

export default ManagementGroupsPage;
