import React, { useCallback } from 'react';

import moment from 'moment';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

type TOpenValue = {
  title?: string
  message?: React.ReactNode,
}

interface ISplitTransactionErrorProps extends IUseModalProps<TOpenValue> {
  transaction: ITransactionServiceDocument,
}

const SplitTransactionError = ({
  transaction,
  isOpen,
  openValue,
  onCancel,
}: ISplitTransactionErrorProps): JSX.Element => {
  const { message, title } = openValue || {};
  const handleConfirm = useCallback((e) => {
    e.preventDefault();

    onCancel();
  }, [onCancel]);

  return (
    <Modal
      dialogClassName="transaction-date-in-reconciled-modal"
      region="second-modal-region"
      show={ isOpen }
      title={ title || 'Transaction date is in a reconciled month' }
    >
      <Modal.Body>
        { message || (
          <span>
            The selected transaction is in a reconciled month. The status of the Reports for
            {' '}
            { moment(transaction.transactionDate).format('MMM YYYY') }
            {' '}
            will need to be changed from Reconciled to In-Progress before you can proceed.
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsColor="blue"
          title="Ok"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const MemoizedDeleteTransactionsModal = React.memo(SplitTransactionError);
const useSplitTransactionErrorModal = makeUseModal<
 typeof MemoizedDeleteTransactionsModal, TOpenValue>(MemoizedDeleteTransactionsModal);

export {
  ISplitTransactionErrorProps,
  useSplitTransactionErrorModal,
  SplitTransactionError as default,
};
