import React from 'react';

import { TDateRange } from '@src/types/common';

import { DateRangeInput } from '@src/components/ui_v2/inputs';
import Title from '@src/components/ui_v2/layout/details_region/header';

import CriticalNumber from '../critical_number';

import styles from './styles.module.scss';

interface IFullViewHeaderProps {
  widgetName?: string,
  widgetDescription?: string,
  unit?: string,
  criticalNumber?: number,
  dateRange?: TDateRange,
  onChange?: (dateRange: TDateRange) => void,
}

const FullViewHeader = ({
  widgetName,
  widgetDescription,
  unit,
  criticalNumber,
  dateRange,
  onChange,
}: IFullViewHeaderProps) => {
  return (
    <div className={ styles['full-view-header'] }>
      <div className={ styles.info }>
        <Title
          className={ styles.title }
          help={ widgetDescription }
          title={ widgetName }
        />
        {
          (criticalNumber || criticalNumber === 0) && (
            <div className={ styles.total }>
              <CriticalNumber
                className={ styles['critical-number'] }
                criticalNumber={ criticalNumber }
                unit={ unit }
              />
            </div>
          )
        }
      </div>
      <div className={ styles.range }>
        <DateRangeInput
          value={ dateRange }
          onChange={ onChange }
        />
      </div>
    </div>
  );
};

export default FullViewHeader;
