import { useMutation } from 'react-query';

import { TID } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiPost } from '../helpers';

interface ICreateDocumentNoteParams {
  documentId: TID;
  note: string;
}

interface IDeleteDocumentNoteParams {
  documentId: TID;
  noteId: TID;
}

const createDocumentNote = (params: ICreateDocumentNoteParams): Promise<void> => {
  return apiPost(`/api/v1/documents/${params.documentId}/notes`, underscoreKeys(params));
};

const useCreateDocumentNote = () => {
  return useMutation<void, Error, { documentId: TID, note: string }>(createDocumentNote);
};

const deleteDocumentNote = (params: IDeleteDocumentNoteParams): Promise<void> => {
  return apiDelete(`/api/v1/documents/${params.documentId}/notes/${params.noteId}`);
};

const useDeleteDocumentNote = () => {
  return useMutation<void, Error, { documentId: TID, noteId: TID }>(deleteDocumentNote);
};

export {
  createDocumentNote,
  useCreateDocumentNote,
  deleteDocumentNote,
  useDeleteDocumentNote,
};
