import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import CategorizedMetricProvider from './categorized_metrics_provider';
import MetricsServiceValuesRouter from './router';
import MetricsServiceValuesRouterV2 from './router_v2';
import Sidemenu from './sidemenu';
import SidemenuV2 from './sidemenu_v2';

interface IMetricsServiceDetailsPageProps {
  businessId: number,
  isIndustryMetrics: boolean,
}

const MetricsServiceLandingPage = ({
  businessId,
  isIndustryMetrics,
}: IMetricsServiceDetailsPageProps): JSX.Element => {

  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.METRIC_SERVICE_REVAMP,
  );
    if (featureQuery.data){
      return (
        <CategorizedMetricProvider>
          <div id="sidemenu-region">
            <SidemenuV2 />
          </div>
          <div id="detail-region">
            <MetricsServiceValuesRouterV2 />
          </div>
        </CategorizedMetricProvider>
      );
    }

return (
  <>
    <div id="sidemenu-region">
      <Sidemenu />
    </div>
    <div id="detail-region">
      <MetricsServiceValuesRouter
        isIndustryMetrics={ isIndustryMetrics }
      />
    </div>
  </>
);
};

export default MetricsServiceLandingPage;
