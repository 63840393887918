import React, { useState } from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { DropdownInput, TOption } from '@src/components/ui_v2/inputs';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { useVendorPaymentsCollection } from './hooks';
import VendorPaymentsTable from './table';

import styles from './styles.module.scss';

interface ISelectMergeableVendorProps
{
  businessVendor: IBusinessVendor,
}

const viewSelectOptions : TOption[] = [
  {
    value: 'viewByPayments',
    label: 'View by Payments',
  },
];

const VendorPayments = ({ businessVendor } : ISelectMergeableVendorProps) => {
  const collection = useVendorPaymentsCollection({ businessVendor });
  const [selectedView, setSelectedView] = useState<TOption | null>(null);

  return (
    <>
      <HeaderRegion>
        <HeaderRegion.Title><span className={ styles['total-payment-txt'] }>1Y Total Payment:-</span></HeaderRegion.Title>
        <HeaderRegion.ActionsArea>
          <DropdownInput
            options={ viewSelectOptions }
            placeholder="Select View"
            value={ selectedView }
            onChange={ (selectedOption) => {
              setSelectedView(selectedOption);
            } }
          />
        </HeaderRegion.ActionsArea>
      </HeaderRegion>
      <Section.Provider section={ collection.section }>
        <TableSection>
          <VendorPaymentsTable
            bussinessId={ businessVendor.businessId }
            collection={ collection }
          />
        </TableSection>
      </Section.Provider>
    </>
  );
};

export default VendorPayments;
