import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { IReport } from '@src/types/report_service/report';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';

import Details from './details';

interface IMultiEntityItemAccountValuesPageProps {
  multiBusinessReportId: string,
  from: string,
  to: string,
  itemIdentifier: string,
  businesses: any[],
  reports: any[],
}

const MultiEntityItemAccountValuesPage = ({
  multiBusinessReportId,
  from,
  to,
  itemIdentifier,
  businesses,
  reports,
}: IMultiEntityItemAccountValuesPageProps): JSX.Element => {
  return (
    <Root>
      <div id="detail-region">
        <Details
          businesses={ camelizeKeys(businesses) as IBusiness[] }
          from={ from }
          itemIdentifier={ itemIdentifier }
          multiBusinessReportId={ multiBusinessReportId }
          reports={ camelizeKeys(reports) as IReport[] }
          to={ to }
        />
      </div>
    </Root>
  );
};

export default MultiEntityItemAccountValuesPage;
