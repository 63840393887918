import React, { useCallback } from 'react';

import Action from './action';

interface ILinkActionItemProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Action>, 'href' | 'onClick'>
{
  href: string,
  trigger?: boolean,
}

const LinkAction = ({
  href,
  trigger = true,
  ...props
}: ILinkActionItemProps): JSX.Element => {
  const handleClick = useCallback(() => {
    Backbone.history.navigate(href, { trigger });
  }, [href, trigger]);

  return (
    <Action href={ href } onClick={ handleClick } { ...props } />
  );
};

export default LinkAction;
