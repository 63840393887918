module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var field, i, len, ref;
    
      __out.push('<div class="clients__client">\n  <div class="clients__client-cell col-sm-1">\n    <i class=\'client__docs-li-type-icon icon icon-document\'></i>\n  </div>\n  <div class=\'clients__client-cell col-sm-2\'>\n    <span class=\'clients__client-name-text font-16\'>\n      ');
    
      __out.push(__sanitize(this.documentName));
    
      __out.push('\n    </span>\n  </div>\n  ');
    
      ref = this.unencryptedFields;
      for (i = 0, len = ref.length; i < len; i++) {
        field = ref[i];
        __out.push('\n  <div class=\' clients__client-cell col-sm-2\'>\n    <span class=\'clients__client-name-text font-16\'>\n        ');
        if (field.data_type !== "url") {
          __out.push('\n          <span class=\'document-field-value\'>');
          __out.push(__sanitize(field.value));
          __out.push('</span>\n        ');
        } else {
          __out.push('\n          ');
          if (/^((http|https):\/\/)/.test(field.value)) {
            __out.push('\n            <span class=\'document-field-value\'><a href="');
            __out.push(__sanitize(field.value));
            __out.push('"></a></span>\n          ');
          } else {
            __out.push('\n            <span class=\'document-field-value\'><a href="http://');
            __out.push(__sanitize(field.value));
            __out.push('"></a></span>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n    </span>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class=\'clients__client-right clients__client-cell\'>\n    <a class="link-detail" href="');
    
      __out.push(__sanitize(this.detailsUrl));
    
      __out.push('"></a>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}