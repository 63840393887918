module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="business-header">\n    <div class="header-title-wrapper">\n        <span class="m-l-13 font-24 font-bold in-black">Ledger Syncing Center</span>\n    </div>\n</div>\n<div class="ledger-syncing-centre-connect-view-container">\n    <div class="qbo-bkg-circle">\n        <i class="font-48 icon icon-quickbooks-logo"><span class="path1"></span><span class="path2"></span></i>\n     </div>\n    <div class="qbo-connec-hint">\n        Quickbooks is disconnected for this Business. Connect to publish items to Quickbooks.\n    </div>\n    <div>\n        <button class="btn btn-connect">\n            Connect to Quickbooks\n        </button>\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}