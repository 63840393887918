import React, { useEffect } from 'react';

import classNames from 'classnames';
import { TransitionStatus } from 'react-transition-group';

import styles from './styles.module.scss';

interface IBottomModalDialogProps {
  children: React.ReactNode,
  className?: string,
  dialogClassName?: string,
  state: TransitionStatus,
  title: React.ReactNode,
}

const BottomModalDialog = ({
  children,
  className,
  dialogClassName,
  state,
  title,
}: IBottomModalDialogProps) => {
  const modalClasses = classNames(
    styles['bottom-modal'],
    'fade',
    { in: state === 'entered' },
    className,
  );
  const dialogClasses = classNames(styles['bottom-modal-dialog'], dialogClassName);
  const backClasses = classNames(
    styles['bottom-modal-backdrop'],
    'fade',
    { in: state === 'entered' },
  );

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  });

  return (
    <>
      <div
        aria-labelledby="modal_title"
        className={ modalClasses }
        role="dialog"
        tabIndex={ -1 }
      >
        <div className={ dialogClasses }>
          <div className={ styles['bottom-modal-content'] }>
            <div className={ styles['bottom-modal-header'] }>
              <h1
                className={ styles['bottom-modal-title'] }
                id="modal_title"
              >
                { title }
              </h1>
            </div>
            { children }
          </div>
        </div>
      </div>
      <div className={ backClasses } />
    </>
  );
};

export {
  IBottomModalDialogProps,
  BottomModalDialog as default,
};
