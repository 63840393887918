import React from 'react';

import { TID } from '@src/types/common';
import { IReport, ITemplate } from '@src/types/report_service/report';

import { AppLink } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import styles from '../styles.module.scss';

interface IBusinessReportOtherDetailHeader {
  businessId: TID,
  report: IReport,
  template?: ITemplate,
  identifier: string
}

const BusinessReportOtherDetailHeader = ({businessId, report, template, identifier}: IBusinessReportOtherDetailHeader) => {
  const params = new URLSearchParams(window.location.search);
  
  return (
    <DetailsRegion.Header
      className={ styles['blue-color'] }
      divider="horizontal"
      title={
        (
          <div>
            <AppLink href={ `/businesses/${businessId}/reports?category=${template?.category}` } style={ { fontSize: '14px' } }>
              { template && window.Docyt.Common.Constants.REPORT_CATEGORY_NAMES[template.category] }
            </AppLink>
            &nbsp;&nbsp;
            <span style={ { fontSize: '14px' } }>/</span>
            &nbsp;&nbsp;
            <AppLink href={ `/businesses/${businessId}/reports/${report.slug}?from=${params.get('from')}&to=${params.get('to')}` } style={ { fontSize: '14px' } }>
              { report.name }
            </AppLink>
            &nbsp;&nbsp;
            <span style={ { fontSize: '14px' } }>/</span>
            &nbsp;&nbsp;
            <span style={ { fontSize: '14px' } }>{ identifier }</span>
          </div>
        )
      }
    />
  );
};

export default BusinessReportOtherDetailHeader;
