import React from 'react';

import vendorServiceImg from '@assets/images/vendor_service/vendor-service-icon.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const VendorIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Vendor icon"
      src={ vendorServiceImg }
      { ...props }
    />
  );
};

export default VendorIcon;
