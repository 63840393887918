import React from 'react';

import RevenueReportTypeInput from '@src/components/common_v2/inputs/revenue_report_type_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IRevenueReportTypeFieldProps extends
  React.ComponentPropsWithoutRef<typeof RevenueReportTypeInput>, IWrapperProps {
}

const RevenueReportTypeField = ({
  ...props
}: IRevenueReportTypeFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <RevenueReportTypeInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(RevenueReportTypeField);
