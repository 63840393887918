import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { IAddInviteClientValues, confirmValidation } from './schema';

import styles from '../styles.module.scss';

interface IAddInviteClientProps {
  formId?: string,
  onSubmit: SubmitHandler<IAddInviteClientValues>,
}

const AddInviteClient = ({
  formId,
  onSubmit,
}: IAddInviteClientProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IAddInviteClientValues>({
    resolver: yupResolver(confirmValidation),
  });

  return (
    <Form className={ styles['add-business-client-form'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <span className={ styles['add-business-client-note'] }>Client First Name</span>
      <Form.TextField
        hiddenLabel
        error={ errors.firstName?.message }
        label="Client First Name"
        placeholder="John"
        { ...register('firstName') }
      />
      <span className={ styles['add-business-client-note'] }>Client Last Name</span>
      <Form.TextField
        hiddenLabel
        error={ errors.lastName?.message }
        label="Client Last Name"
        placeholder="Doe"
        { ...register('lastName') }
      />
      <span className={ styles['add-business-client-note'] }>Email ID</span>
      <Form.TextField
        hiddenLabel
        error={ errors.email?.message }
        label="Email ID"
        placeholder="example@domain.com"
        { ...register('email') }
      />
      <span className={ styles['add-business-client-note'] }>Welcome email message</span>
      <Form.TextAreaField
        hiddenLabel
        error={ errors.welcomeMessage?.message }
        label="Welcome email message"
        placeholder="Input email message"
        style={ { resize: 'vertical', height: '250px', overflow: 'auto' } }
        { ...register('welcomeMessage') }
      />
    </Form>
  );
};

export {
  IAddInviteClientProps,
  IAddInviteClientValues,
  AddInviteClient as default,
};
