import React, { useCallback, useEffect } from 'react';

import sumBy from 'lodash/sumBy';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { numberToAmount } from '@src/utils/currency';

import { Button } from '@src/components/ui_v2/buttons';
import Table from '@src/components/ui_v2/table';
import { PlusIcon } from '@src/components/utils/icomoon';

import SplitFields from './category_split_fields';
import { defaultCategorySplit, ICategorySplitsData } from './schema';

import styles from './styles.module.scss';

interface ICategorySplitsFormProps {
  business: IBusiness,
  form: UseFormReturn<ICategorySplitsData>
  isReadonly?: boolean,
  isBusinessReadonly?: boolean,
}

const CategorySplitsForm = ({
  business,
  form,
  isReadonly,
  isBusinessReadonly,
}: ICategorySplitsFormProps) => {
  const { control, setValue, trigger, watch } = form;
  const { fields: splitFields, remove: removeSplit, append } = useFieldArray({ control, name: 'splits' });

  // For some reasons `watch` for array values doesn't work
  const splits = useWatch({ control, name: 'splits' });
  const adjustmentAmount = watch('adjustmentAmount');

  useEffect(() => {
    const total = sumBy(splits, (split) => parseFloat(split.amount || '0'));
    const diff = total - parseFloat(adjustmentAmount || '0');

    setValue('totalAmount', numberToAmount(total));
    setValue('differenceAmount', numberToAmount(diff));
    trigger('differenceAmount');
  }, [splits, adjustmentAmount, trigger, setValue]);

  const handleAdd = useCallback(() => {
    append(
      defaultCategorySplit(business.id),
      { shouldFocus: false },
    );
  }, [append, business]);

  return (
    <Table sticky>
      <Table.Head>
        <Table.Row>
          <Table.HCell width="20%">Business</Table.HCell>
          <Table.HCell width="20%">Department</Table.HCell>
          <Table.HCell width="20%">Chart of Account</Table.HCell>
          <Table.HCell width="10%">Amount</Table.HCell>
          <Table.HCell width="10%">Percentage</Table.HCell>
          <Table.HCell width="20%">Memo</Table.HCell>
          <Table.EmptyHCell aria-label="Actions" />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        { splitFields.map((field, index) => (
          <SplitFields
            key={ field.id }
            form={ form }
            index={ index }
            isBusinessReadonly={ isBusinessReadonly }
            isReadonly={ isReadonly }
            remove={ removeSplit }
          />
        )) }
        <Table.Row>
          <Table.Cell className={ styles['append-split'] } colSpan={ 6 }>
            {
              !isReadonly && (
                <Button
                  prefixIcon={ <PlusIcon variant="o" /> }
                  size="compact"
                  variant="link"
                  onClick={ handleAdd }
                >
                  Add Split
                </Button>
              )
            }
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default React.memo(CategorySplitsForm);
