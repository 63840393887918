import React from 'react';

import { useRecoilValue } from 'recoil';

import Text from '@src/components/ui/text';
import { OutlinedSuccessIcon } from '@src/components/utils/icomoon';

import { reportUpdatingState } from '../../atoms';

const ReportStateBanner = (): JSX.Element => {
  const state = useRecoilValue(reportUpdatingState);

  return (
    <div className="report-status-panel">
      <OutlinedSuccessIcon className="m-r-8" />
      <Text fontSize={ 14 }>{ state }</Text>
    </div>
  );
};

export default ReportStateBanner;
