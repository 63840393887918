import React, { forwardRef } from 'react';

import DatePickerInput, { IDatePickerInputProps } from '../inputs/datepicker_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface IDateFieldProps extends
  IDatePickerInputProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
}

/**
 * Field for date values. Based on
 * [bootstrap-datepicker](https://bootstrap-datepicker.readthedocs.io/en/latest/) library.
 * Provide value in string formatted as 'YYYY-MM-DD'.
 *
 * @visibleName Form.DateField
 */
const DateField = forwardRef<HTMLInputElement, IDateFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: IDateFieldProps, ref): JSX.Element => {
  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <DatePickerInput
        ref={ ref }
        { ...props }
      />
    </Wrapper>
  );
});

DateField.displayName = 'DateField';

export default DateField;
