import React from 'react';

import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';
import { formatPeriod } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import Actions from './item_actions';

interface IBudgetsItemProps {
  budget: IReportServiceBudget,
  defaultBudgetId: string | undefined;
  setAsDefault: (defaultBudgetId: string) => void,
}

const BudgetsItemProps = ({
  budget,
  defaultBudgetId,
  setAsDefault,
}: IBudgetsItemProps): JSX.Element => (
  <Table.Row>
    <Table.Cell>
      <div className="display-flex align-items-center justify-content-between">
        { budget.name }
        <span className={ `budget-type-badge ${String(budget.id) !== defaultBudgetId && 'invisible'}` }>
          Default
        </span>
      </div>
    </Table.Cell>
    <Table.Cell>
      { budget.type }
    </Table.Cell>
    <Table.Cell>
      {formatPeriod(budget.year)}
    </Table.Cell>
    <Table.DateCell date={ budget.createdAt } />
    <Table.Cell>
      { budget.creatorName }
    </Table.Cell>
    <Table.CurrencyAmountCell
      value={ budget.totalAmount }
    />
    <Table.Cell
      className="budget-action-column"
      style={ { textAlign: 'right' } }
    >
      <Actions
        budget={ budget }
        setAsDefault={ setAsDefault }
      />
    </Table.Cell>
  </Table.Row>
);

export default BudgetsItemProps;
