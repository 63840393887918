module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, partner, ref, ref1;
    
      __out.push('<div class=\'person-detail-info relative\'>\n  <div class=\'client-detail-info-col first-col\'>\n    <div class=\'client-detail-field\' id=\'client-detail-name\'>\n      <div class=\'client-detail-info-title\'>\n        <span>DISPLAY NAME</span>\n      </div>\n      <div class=\'client-detail-info-value\'>\n        <span>');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'client-detail-field\' id=\'client-detail-name\'>\n      <div class=\'client-detail-info-title\'>\n        <span>ENTITY TYPE</span>\n      </div>\n      <div class=\'client-detail-info-value\'>\n        <span>');
    
      __out.push(__sanitize(this.entity_type));
    
      __out.push('</span>\n      </div>\n    </div>\n  </div>\n  <div class=\'client-detail-info-col second-col\'>\n    <div class=\'client-detail-field\' id=\'client-detail-email\'>\n      <div class=\'client-detail-info-title\'>\n        <span>Email</span>\n      </div>\n      <div class=\'client-detail-info-value\'>\n        <a href=\'mailto:');
    
      __out.push(__sanitize(this.email));
    
      __out.push('\'>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</a>\n      </div>\n    </div>\n    <div class=\'client-detail-field\' id=\'client-detail-phone\'>\n      <div class=\'client-detail-info-title\'>\n        <span>Phone</span>\n      </div>\n      <div class=\'client-detail-info-value\'>\n        <span>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n      </div>\n    </div>\n  </div>\n  <div class=\'client-detail-info-col third-col\'>\n    <div class=\'client-detail-field\' id=\'client-detail-email\'>\n      <div class=\'client-detail-info-title vertical-align-top\'>\n        <span>Address</span>\n      </div>\n      <div class=\'client-detail-info-value\'>\n        <p class="m-b-1">');
    
      __out.push(__sanitize(this.address_street));
    
      __out.push('</p>\n        <p class="m-b-1">');
    
      __out.push(__sanitize(this.address_street_2));
    
      __out.push('</p>\n        <p class="m-b-1">');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('</p>\n        <p class="m-b-1">');
    
      __out.push(__sanitize(this.address_state));
    
      __out.push('</p>\n        <p class="m-b-1">');
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('</p>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\'\'>\n  <div class="m-t-10 m-b-10">\n    <button class="btn btn-add-member font-23 pull-right">\n      <i class="icon icon-plus" />\n    </button>\n    <h2 class=\'font-18 in-brown-700 font-bold p-t-15 p-b-10\'>BUSINESS ADMINS</h2>\n  </div>\n  <div class=\'business-partners-list m-b-50\'>\n    ');
    
      ref = this.business_partners;
      for (i = 0, len = ref.length; i < len; i++) {
        partner = ref[i];
        __out.push('\n      <div class="business-partner-item-row pointer">\n        <div class=\'avatar-field-cell information-cell\'>\n          ');
        if ((ref1 = partner.user.avatar) != null ? ref1.avatar_url : void 0) {
          __out.push('\n            <div class="avatar-wrapper">\n              <img src="');
          __out.push(__sanitize(partner.user.avatar.avatar_url));
          __out.push('" class=\'avatar-img\'>\n            </div>\n          ');
        } else {
          __out.push('\n            <i class=\'icon icon-smile in-orange-500\'></i>\n          ');
        }
        __out.push('\n        </div>\n        <div class=\'information-cell\'>\n          <span class=\'font-16 in-brown-700\'>\n            ');
        __out.push(__sanitize(partner.user.parsed_fullname));
        __out.push('\n          </span>\n        </div>\n        <div class=\'information-cell\'>\n          <span class=\'font-16 in-brown-700\'>\n            ');
        __out.push(__sanitize(partner.user.email));
        __out.push('\n          </span>\n        </div>\n        <div class=\'information-cell\'>\n          <span class=\'font-16 in-brown-700\'>\n            ');
        __out.push(__sanitize(partner.user.phone));
        __out.push('\n          </span>\n        </div>\n        <div class=\'information-cell text-right p-r-20\'>\n          <a class=\'font-20 text-center\' href="/messaging?ptype=custom&pid=');
        __out.push(__sanitize(partner.user_id));
        __out.push('" >\n            <i class="icon icon-chat-round"></i>\n          </a>\n        </div>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}