import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ViewClientCentralIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="view-client-central"
      pathsNumber={ 15 }
    />
  );
};

export default ViewClientCentralIcon;
