module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade modal-middle" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body">\n        <ul>\n          <li>\n            <a class="link-service pointer ');
    
      __out.push(__sanitize(this.serviceType === 'ap' ? 'hidden' : ''));
    
      __out.push('" data-type="AccountsPayableAdvisor">\n              <div class="pull-left m-r-22 service-trademark">\n                <span class="icon-accounts-payable font-50"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span></span>\n              </div>\n              <p class="font-16 font-bold m-b-0 in-black p-t-5">Accounts Payable</p>\n              <p class="font-14 in-grey-1000">Forward your invoices here (both paid & unpaid)</p>\n            </a>\n            <a class="link-service pointer m-t-30 ');
    
      __out.push(__sanitize(this.serviceType === 'receipt' ? 'hidden' : ''));
    
      __out.push('" data-type="ReceiptService">\n              <div class="pull-left m-r-22 service-trademark">\n                <span class="icon-receipt-box font-50"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n              </div>\n              <p class="font-16 font-bold m-b-0 in-black p-t-5">Receipt Box</p>\n              <p class="font-14 in-grey-1000">Record all your expense receipts here.</p>\n            </a>\n            <a class="link-service pointer m-t-30 ');
    
      __out.push(__sanitize(this.serviceType === 'inbox' ? 'hidden' : ''));
    
      __out.push('" data-type="InboxService">\n              <div class="pull-left m-r-22 service-trademark">\n                <span class="icon-business-inbox font-50"><span class="path1"></span><span class="path2"></span></span>\n              </div>\n              <p class="font-16 font-bold m-b-0 in-black p-t-5">Mailroom</p>\n              <p class="font-14 in-grey-1000">Your holding area for any kind of document.</p>\n            </a>\n            <a class="link-service pointer m-t-30 ');
    
      __out.push(__sanitize(this.serviceType === 'revenue' ? 'hidden' : ''));
    
      __out.push('" data-type="RevenueService">\n              <div class="pull-left m-r-22 service-trademark">\n                <span class="icon-revenue-chart font-50"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n              </div>\n              <p class="font-16 font-bold m-b-0 in-black p-t-5">Revenue Center</p>\n              <p class="font-14 in-grey-1000">Forward your documents here.</p>\n            </a>\n          </li>\n        </ul>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id=\'done-btn\' class="btn ');
    
      __out.push(__sanitize(this.btnClass));
    
      __out.push(' btn-xlarge font-16">');
    
      __out.push(__sanitize(this.confirmText));
    
      __out.push('</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}