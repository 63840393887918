import { useMutation } from 'react-query';

import {
  IConnectTokenParams,
  IRetrieveAggregatorLinkTokenParams,
  IConnectTokenResponse,
  IReconnectRetrieveLinkTokenResponse,
  generateToken,
  retrieveLinkToken
} from '@src/requests/generate_aggregator_token';

const useGenerateLinkToken = () => {
  return useMutation<
    IConnectTokenResponse,
    Error,
    IConnectTokenParams
  >(generateToken);
};

const useAggregatorRetrieveLinkToken = () => {
  return useMutation<
    IReconnectRetrieveLinkTokenResponse,
    Error,
    IRetrieveAggregatorLinkTokenParams
  >(retrieveLinkToken);
};

export {
  useGenerateLinkToken,
  useAggregatorRetrieveLinkToken
};
