import React, { useCallback, useState } from 'react';

import { useUnverifyBankStatement } from '@src/hooks/queries/bank_statements';
import { getBankStatementByDocytId } from '@src/requests/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { UnverifyStatementIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IUnverifyActionProps {
  businessId: TID,
  bankStatement: IBankStatement,
}

const UnverifyAction = ({
  businessId,
  bankStatement,
}: IUnverifyActionProps) => {
  const [hasNextVerifiedStatements, setHasNextVerifiedStatements] = useState<boolean>(false);

  const unverify = useUnverifyBankStatement();
  const { mutate } = unverify;

  const handleConfirm = useCallback(() => {
    mutate(
      { id: bankStatement.id },
    );
  }, [bankStatement.id, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  const handleClickAction = useCallback(() => {
    getBankStatementByDocytId(businessId, bankStatement.docytId).then((res) => {
      setHasNextVerifiedStatements(res.bankStatement?.hasNextVerifiedStatements || false);

      confirmModal.open();
    });
  }, [bankStatement.docytId, businessId, confirmModal]);

  return (
    <>
      <MutationStatus mutation={ unverify } />
      <confirmModal.Component
        text={
          hasNextVerifiedStatements ? (
            <span>
              Bank feed has been verified beyond this statement. If you unverify this statement,
              { ' ' }
              all statements recent to this statement will need re-verification.
            </span>
          ) : (
            <span>Are you sure you want to proceed and unverify this bank statement?</span>
          )
        }
        title={ hasNextVerifiedStatements ? 'Critical Warning' : 'Unverify Bank Statement' }
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <UnverifyStatementIcon fontSize={ 18 } /> }
        name="Unverify"
        onClick={ handleClickAction }
      />
    </>
  );
};

export default UnverifyAction;
