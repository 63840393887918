import React, { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useUpdateUserSettings, useUpsertNotificationPreferences } from '@src/hooks/queries/financial_insights';
import { useGetCurrentAdvisor } from '@src/hooks/queries/users';
import { IBusinessItem, IBusinessUpdatedItem, IDashboardItem, IFrequency, IUpdateFrequencyFormData } from '@src/types/financial_insight';

import Spinner from '@src/components/ui/spinner';

import FinancialInsightsEdit from './financial_insights_edit';
import FinancialInsightsTable from './financial_insights_table';
import { useGetFinancialInsightsCollection } from './hooks';

import styles from './styles.module.scss';

const FinancialInsights = () => {
  const queryClient = useQueryClient();
  const { data } = useGetCurrentAdvisor();
  const { mutate: updateUserSettingsMutate, isLoading: updateUserSettingsLoading } =
  useUpdateUserSettings();
  const { mutate: upsertNotificationMutate, isLoading: upsertNotificationLoading } =
  useUpsertNotificationPreferences();
  const collection = useGetFinancialInsightsCollection({
    sect: 'financial_insights',
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [frequency, setFrequency] = useState<IFrequency>('daily');
  const [updatedData, setUpdatedData] = useState<IBusinessUpdatedItem[]>([]);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onChange = useCallback((row : IBusinessItem, option : IDashboardItem) => {
    const isUpdatedExists =
    updatedData.find((it : IBusinessUpdatedItem) => it.businessId === row.businessId);
    if (isUpdatedExists) {
      // checking default value and current options are same or not
      if (JSON.stringify(row.default) === JSON.stringify(option)) {
        // if both options are same then no need to update
        const tempData =
          updatedData.filter((item : IBusinessUpdatedItem) => item.businessId !== row.businessId);
        setUpdatedData(tempData);
      } else {
        // if options are not same then update this value
        const tempData = updatedData.map((item : IBusinessUpdatedItem) => {
          if (item.businessId === row.businessId) {
            return {
              ...item,
              updated: option,
            };
          }
          return item;
        });
        setUpdatedData(tempData);
      }
    } else {
      // if not exists on updatedData then add new object with businessId
      const tempData = [...updatedData,
        { businessId: row.businessId, updated: option, default: row.default }];
      setUpdatedData(tempData);
    }
  }, [updatedData]);

  const handleSubmit = useCallback((values: IUpdateFrequencyFormData) => {
    if (frequency !== values.frequency) {
      setFrequency(values.frequency);
      updateUserSettingsMutate({
        financial_insights_email_frequency: values.frequency,
      });
    }
    if (updatedData.length > 0) {
      const tempData = updatedData.map((it : IBusinessUpdatedItem) => {
        return {
          businessId:               it.businessId,
          dashboardId:              it.updated.dashboardId === 'none' ? it.default.dashboardId : it.updated.dashboardId,
          emailNotificationEnabled: it.updated.dashboardId !== 'none',
          pushNotificationEnabled:  false,
        };
      });

      upsertNotificationMutate({
        financialInsightNotificationPreference: tempData,
      }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(
            QueryKey.financialInsights,
          );
          setUpdatedData([]);
          toastr.success('Successfully updated', 'Update Successful');
        },
        onError: () => {
          toastr.error('Failed to updated.', 'Error');
        },
      });
    }
    handleToggle();
  }, [frequency,
    handleToggle,
    queryClient,
    updateUserSettingsMutate,
    updatedData,
    upsertNotificationMutate]);

  useEffect(() => {
    if (data && data.advisor) {
      setFrequency(data.advisor.financialInsightsEmailFrequency as IFrequency);
    }
  }, [data]);

  return (
    <>
      {(updateUserSettingsLoading || upsertNotificationLoading) && <Spinner />}

      <div className="display-flex align-items-center justify-content-center">
        <div
          className={ `${styles.financial} bg-white col-xs-12 col-sm-12` }
        >
          <div className="row m-t-5 m-l-5 m-r-5">
            <div className="col-sm-12 display-flex align-items-center justify-content-between">
              <span className="notification-title-with-right-mark">
                <span className="font-semi-bold font-16">
                  Financial Insights Email
                </span>
                &nbsp;&nbsp;
                <span className={ `${styles.capitalize} notification-mark font-light font-14` }>
                  {frequency}
                </span>
              </span>
              <a
                className={ `${styles['edit-button']} font-14 font-bold pointer` }
                role="button"
                tabIndex={ -1 }
                onClick={ handleToggle }
              >
                Edit
              </a>
            </div>

            <div className="col-sm-12 display-flex align-items-center justify-content-center">
              <div className="item-divider width-100-percent m-t-10 m-b-10" />
            </div>

            <div className="col-sm-12 display-flex align-items-center justify-content-center">
              <FinancialInsightsTable
                isPopup={ false }
                query={ collection.query }
                records={ collection.records }
                section={ collection.section }
                sorting={ collection.sorting }
                updatedData={ updatedData }
                onChange={ onChange }
                onClear={ () => {} }
              />
            </div>
          </div>
        </div>
      </div>

      <FinancialInsightsEdit
        frequency={ frequency }
        isOpen={ isOpen }
        updatedData={ updatedData }
        onCancel={ handleToggle }
        onChange={ onChange }
        onClear={ () => {} }
        onSubmit={ handleSubmit }
      />

    </>
  );
};
export default React.memo(FinancialInsights);
