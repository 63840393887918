import React, { useMemo, useState } from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';
import {
  useGetTransactionClientContext, useGetTransactionMessages,
} from '@src/hooks/queries/bank_account_reconciliations';
import {
  ITransactionClientContextResponse,
} from '@src/requests/bank_account_reconciliations';
import { TransactionContextData } from '@src/types/bank_account_reconciliations';

import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import ContextExpand from './context_expand';
import ContextSummary from './context_summary';
import useTransactionContext from './use_transaction_context';
import ViewAIContext from './view_ai_context';

import styles from './styles.module.scss';

const FlaggedTransactionContext = () => {
  const { data: tcData } = useTCDContext();
  const messagesQuery = useGetTransactionMessages(tcData.documentId);
  const chatMessages = useMemo(
    () => messagesQuery.data?.chatMessages || [],
    [messagesQuery.data?.chatMessages],
  );
  const formattedNames = useMemo(() => {
    const uniqueNames = chatMessages.map((chatMessage) => chatMessage.senderName)
      .filter((name, index, self) => self.indexOf(name) === index);
    if (uniqueNames.length === 0) return '';
    if (uniqueNames.length === 1) return uniqueNames[0];
    if (uniqueNames.length === 2) return uniqueNames.join(' and ');
    return `${uniqueNames[0]}, ${uniqueNames[1]} and others`;
  }, [chatMessages]);

  const fetchFunction = useGetTransactionClientContext;

  const onSuccess = (
    response: ITransactionClientContextResponse,
    setData: React.Dispatch<React.SetStateAction<TransactionContextData>>,
  ) => {
    setData((pre: TransactionContextData) => ({ ...pre, clientContext: response.summarized }));
    sessionStorage.setItem(`vtc_${tcData.id}_cc`, response.summarized);
  };

  const getContextData = (data: TransactionContextData) => {
    return {
      context:       data.clientContext,
      requestParams: {
        businessId:    data.businessId,
        transactionId: data.id,
        chatMessages,
      },
    };
  };

  const {
    data,
    expand,
    openExpand,
    closeExpand,
    isLoading,
    error,
  } = useTransactionContext(fetchFunction, onSuccess, getContextData);
  const { clientContext } = data;
  const [more, setMore] = useState(false);
  const showMore = () => setMore(true);

  const renderContent = () => {
    if (!clientContext) {
      if (isLoading) {
        return (
          <div className={ styles['spinner-container'] }>
            <SpinnerIcon spin size="2x" />
            <span className={ styles.label }>Loading...</span>
          </div>
        );
      }
      if (error) return <ErrorNotification error={ error } />;
    }

    const hasEllipsis = clientContext.length > 130;
    const displayContent = hasEllipsis ? clientContext.slice(0, 130) : clientContext;

    const ellipsisSign = hasEllipsis && !more && (
      <Button className={ styles['ellipsis-sign'] } type="button" onClick={ showMore }>
        …more
      </Button>
    );

    const showContent = more ? clientContext : displayContent;

    return (
      <>
        <div className={ styles['context-from-client'] }>
          <div className={ styles['context-title'] }>
            { clientContext.includes('Overall summary of conversation') ? formattedNames : 'From Client' }
          </div>
          { showContent }
          { ellipsisSign }
        </div>
        <ViewAIContext />
      </>
    );
  };

  if (expand) {
    return (
      <ContextExpand onClick={ closeExpand }>
        { renderContent() }
      </ContextExpand>
    );
  }

  return <ContextSummary onClick={ openExpand } />;
};

export default FlaggedTransactionContext;
