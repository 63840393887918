import React from 'react';

import PaymentAccountInput from '@src/components/common_v2/inputs/payment_accounts_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IPaymentAccountsFieldProps extends
  React.ComponentPropsWithoutRef<typeof PaymentAccountInput>, IWrapperProps {
    labelClass?: string,
}

const PaymentAccountsField = ({
  labelClass,
  ...props
}: IPaymentAccountsFieldProps) => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps } labelClass={ labelClass }>
      <PaymentAccountInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(PaymentAccountsField);
