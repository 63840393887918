import React, { FC } from 'react';

import { currencyLocaleFormatter } from '@src/utils/currency';

import styles from './styles.module.scss';

interface TableFooterProps {
  amount?: number|string
}

const TotalAmount: FC<TableFooterProps> = ({ amount }) => {
  return (
    <span className={ styles['total-amount-wrapper'] }>
      Total:
      { ' ' }
      <span className={ styles['total-amount-detail'] }>
        { currencyLocaleFormatter(amount) }
      </span>
    </span>
  );
};

export default TotalAmount;
