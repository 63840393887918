import React, { forwardRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface ICheckboxFieldProps extends
  React.ComponentPropsWithoutRef<'input'>,
  IUIStyleProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
  label: string,
}

/**
 * Checkbox field
 *
 * @visibleName Form.CheckboxField
 */
const CheckboxField = forwardRef<HTMLInputElement, ICheckboxFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: ICheckboxFieldProps, ref): JSX.Element => {
  const [classes, selectProps] = uiStyleProps('pointer', props);

  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <input
        ref={ ref }
        className={ classes }
        type="checkbox"
        { ...selectProps }
      />
    </Wrapper>
  );
});

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
