import { ICreateTransactionMatchData, ITransactionMatch } from '@src/types/transaction_matches';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

interface ICreateTransactionMatchParams {
  data: ICreateTransactionMatchData,
}

interface ICreateTransactionMatchResponse {
  transactionMatch: ITransactionMatch,
}

const createTransactionMatch = (
  params: ICreateTransactionMatchParams,
): Promise<ICreateTransactionMatchResponse> => {
  return apiPost(
    '/api/v1/transaction_matches',
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTransactionMatchResponse;
  });
};

export {
  ICreateTransactionMatchParams,
  ICreateTransactionMatchResponse,
  createTransactionMatch,
};
