import React from 'react';

import classNames from 'classnames';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';

import ClearIndicator from '../clear_indicator';
import { TOption } from '../types';

import styles from '../styles.module.scss';

interface IDropdownInnerInputProps {
  placeholder?: string,
  value: TOption | null,
  onClear: () => void,
}

const DropdownInnerInput = ({
  placeholder,
  value,
  onClear,
}: IDropdownInnerInputProps) => {
  const {
    isVisible,
    showOnClickAttributes,
    showWithKeyboardAttributes,
  } = useDropdown() || {};

  const classes = classNames(
    value ? styles['dropdown-input-value'] : styles['dropdown-input-placeholder'],
  );

  return (
    <>
      <div
        aria-expanded={ isVisible ? 'true' : 'false' }
        aria-haspopup="true"
        className={ classes }
        role="button"
        tabIndex={ 0 }
        { ...showOnClickAttributes }
        { ...showWithKeyboardAttributes }
      >
        { value ? value.label : placeholder }
      </div>
      {
        value ? (
          <ClearIndicator onClick={ onClear } />
        ) : (
          <Dropdown.InputIndicator tabIndex={ -1 } />
        )
      }
    </>
  );
};

export default DropdownInnerInput;
