import React, { forwardRef } from 'react';

import NumberInput from '@src/components/ui_v2/inputs/number_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface INumberFieldProps extends
  React.ComponentPropsWithoutRef<typeof NumberInput>,
  Omit<IWrapperProps, 'suffix'>
{
}

const NumberField = forwardRef<HTMLInputElement, INumberFieldProps>(({
  ...props
}: INumberFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <NumberInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

NumberField.displayName = 'NumberField';

export default NumberField;
