import React, { useRef } from 'react';

import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';

import Table from '@src/components/ui/table';
import TableBody from '@src/components/ui/table/body';
import TableHead from '@src/components/ui/table/head';
import TableRow from '@src/components/ui/table/row';
import TableCell from '@src/components/ui_v2/table/cell';

import MonthValueCell from '../details/month_value_cell';

import styles from './styles.module.scss';

interface IChartOfAccountProps {
  selectedItem: string | null | undefined,
  MONTHS: Array<string>,
  currentFormula: IReportServiceBudgetFormula
  handleChangeFormulaMonthValue: (value: any, month: number) => void
  amountValue: any,
  monthlyAmountValue: any;
  monthlyInputValue: any;
}

const MonthValues = ({ MONTHS,
  amountValue, selectedItem,
  currentFormula, handleChangeFormulaMonthValue,
  monthlyInputValue, monthlyAmountValue }: IChartOfAccountProps) => {
  const changeValue = (value: string, month: number) => {
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
    monthlyInputValue[month] = numericValue;
    handleChangeFormulaMonthValue(numericValue, month);
  };
  const monthlyValues = useRef<any>(
    (Object as any).fromEntries(Array.from({ length: 12 }, (_, i) => [i + 1, 0.0])),
  );

  const showValue = (index: number) => {
    const month = index + 1;
    if (amountValue?.month === index + 1) {
      monthlyValues.current[month] = amountValue?.value || 0.0;
    }
    const amount = amountValue?.month === index + 1
      ? (monthlyValues.current[month] || 0.0) : monthlyAmountValue[index + 1];

    monthlyAmountValue[month] = amount;
    return amount;
  };

  return (
    <div>
      <div>
        <p>
          The budget for
          <strong>
            {' '}
            { selectedItem }
            {' '}
          </strong>
          will be calculated by multiplying Rooms with
          <strong>
            {' '}
            {currentFormula.name}
            .
            {' '}
          </strong>
          Define the per occupied room value for each month.
        </p>
      </div>
      <div className={ styles['import-budget-modal'] }>
        <Table>
          <TableHead>
            <TableRow>
              <th>Month</th>
              <th>Value</th>
              <th>Amount</th>
            </TableRow>
          </TableHead>
          <TableBody>
            {MONTHS.map((month: string, index: number) => (
              <TableRow key={ month }>
                <TableCell>{month}</TableCell>

                <MonthValueCell
                  className="text-fields"
                  currentFormula={ currentFormula }
                  initValue={ monthlyInputValue[index + 1] || 0.0}
                  onChange={ (value: any) => changeValue(value, index + 1) }
                />

                <Table.CurrencyAmountCell
                  className="text-left"
                  style={ { color: '#070C2580' } }
                  value={ showValue(index) }
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default MonthValues;
