import React from 'react';

import { TID } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import BankAccountReconciliationProvider from '@src/components/providers/bank_account_reconciliation_provider';
import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

import MonthEnd from './month_end';

interface MonthEndPageProps {
  businessId: TID,
  baReconciliationId: TID,
  reconciliationPaymentAccount: IReconciliationPaymentAccount,
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[],
}

const MonthEndPage = ({
  businessId,
  baReconciliationId,
  reconciliationPaymentAccount,
  reconciliationPaymentAccounts,
}: MonthEndPageProps) => {
  return (
    <Root>
      <BusinessProvider useLocalSpinner businessId={ businessId }>
        <ReconciliationCenterProvider useLocalSpinner businessId={ businessId }>
          <BankAccountReconciliationProvider baReconciliationId={ baReconciliationId }>
            <MonthEnd
              reconciliationPaymentAccount={ reconciliationPaymentAccount }
              reconciliationPaymentAccounts={ reconciliationPaymentAccounts }
            />
          </BankAccountReconciliationProvider>
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default MonthEndPage;
