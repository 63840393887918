import { useQuery } from 'react-query';

import {
  IGetMatchedItemsParams,
  getMatchedItems,
} from '@src/requests/matched_items';
import { IMatchedItem } from '@src/types/matched_items';

const MATCHED_ITEMS_QUERY = 'matched_items';

const useGetMatchedItems = (params: IGetMatchedItemsParams) => {
  return useQuery<IMatchedItem[], Error>(
    [MATCHED_ITEMS_QUERY, params],
    () => getMatchedItems(params.documentId),
  );
};

export {
  MATCHED_ITEMS_QUERY,
  useGetMatchedItems,
};
