import React from 'react';

import { TID } from '@src/types/common';

import { ChartOfAccountField } from '@src/components/common/form_fields';
import Root from '@src/components/root';

interface IChartOfAccountSelectProps {
  businessId: TID,
  onSelectItems: (items: any[]) => void,
}

const ChartOfAccountSelectAction = ({
  businessId,
  onSelectItems,
}: IChartOfAccountSelectProps): JSX.Element => {
  return (
    <Root>
      <ChartOfAccountField
        accountType="profit_loss"
        businessId={ businessId }
        className="width-100-percent"
        handleSelected={ onSelectItems }
        label="Chart of Accounts"
      />
    </Root>
  );
};

export {
  ChartOfAccountSelectAction as default,
};
