import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TReviewedStatementSortColumn, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID, TYear } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from '../styles.module.scss';

interface IBankStatementPendingReviewTableProps {
  businessId: TID
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementSortColumn>,
  reviewedBankStatements: IReviewedBankStatementsData[],
  year?: TYear,
}

const BankStatementPendingReviewTable = ({
  businessId,
  query,
  sorting,
  reviewedBankStatements,
  year,
}: IBankStatementPendingReviewTableProps) => {
  return (
    <Table
      className={ styles['bank-statement-reviewed-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TReviewedStatementSortColumn>
            columnName="banking_account_name"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Banking Account
          </Table.HCell>
          <Table.HCell<TReviewedStatementSortColumn>
            columnName="current_balance"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Current Balance
          </Table.HCell>
          <Table.HCell<TReviewedStatementSortColumn>
            columnName="missing_statements"
            sorting={ sorting }
            widthPercent={ 13 }
          >
            Missing Statements
          </Table.HCell>
          <Table.HCell className="reviewed-item"> Jan </Table.HCell>
          <Table.HCell className="reviewed-item"> Feb </Table.HCell>
          <Table.HCell className="reviewed-item"> Mar </Table.HCell>
          <Table.HCell className="reviewed-item"> Apr </Table.HCell>
          <Table.HCell className="reviewed-item"> May </Table.HCell>
          <Table.HCell className="reviewed-item"> Jun </Table.HCell>
          <Table.HCell className="reviewed-item"> Jul </Table.HCell>
          <Table.HCell className="reviewed-item"> Aug </Table.HCell>
          <Table.HCell className="reviewed-item"> Sep </Table.HCell>
          <Table.HCell className="reviewed-item"> Oct </Table.HCell>
          <Table.HCell className="reviewed-item"> Nov </Table.HCell>
          <Table.HCell className="reviewed-item"> Dec </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          reviewedBankStatements.map((reviewedBankStatement) => (
            <Item
              key={ reviewedBankStatement.id }
              businessId={ businessId }
              reviewedBankStatement={ reviewedBankStatement }
              year={ year }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(BankStatementPendingReviewTable);
