import Actions from './actions';
import Body from './body';
import Content from './content';
import Error from './error';
import Form from './form';
import Header from './header';
import Provider from './provider';
import Render from './render';
import Standard from './standard';
import Subtitle from './subtitle';

export { useSideView } from './hooks';

export default {
  Actions,
  Body,
  Content,
  Form,
  Header,
  Provider,
  Render,
  Standard,
  Subtitle,
  Error,
};
