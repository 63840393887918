import React, { useCallback, useMemo } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { useGetQRCodeForSelfOnboardingInvitation } from '@src/hooks/queries/digital_signatures';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { Button } from '@src/components/ui/buttons';
import { SuccessIcon } from '@src/components/utils/icomoon';

import { doLater } from '../utils';

import styles from './styles.module.scss';

interface IAuthorizedSignatoryViewProps {
  invitation: ISelfOnboardingInvitation,
}

const AuthorizedSignatoryView = ({
  invitation,
}: IAuthorizedSignatoryViewProps) => {
  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleSubmit = useCallback(() => {
    Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_MICRO_DEPOSIT}`, { trigger: true });
  }, []);

  const handleDoLater = useCallback(() => {
    doLater(invitation.onboardingInformation);
  }, [invitation.onboardingInformation]);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_KYC_DOCUMENTS}`,
      { trigger: true },
    );
  }, []);

  const businesses = useMemo(() => {
    return invitation.businesses.filter((business) => !business.isSetupAuthorizedPayer);
  }, [invitation]);

  const qrCode = useGetQRCodeForSelfOnboardingInvitation(invitation.id);
  const qrCodeResult = useMemo(() => qrCode.data || '', [qrCode.data]);

  if (invitation.businesses.length !== 0 && businesses.length === 0) {
    return (
      <div className={ styles['authorized-signatory-container'] }>
        <div className={ styles['authorized-signatory-success'] }>
          <SuccessIcon fontSize={ 80 } mt={ 80 } />
          <h2 className="font-18 in-black m-t-30">
            Successfully added the authorized signatory.
          </h2>
          <span className="m-t-10">As a next step,</span>
          <span className="m-t-5">we’ll deposit a micro amounts into your operating bank account(s).</span>
          <span className="m-t-5">You will receive an email and this might take upto 24~48 hours.</span>
        </div>
        <div className="setup-client-footer">
          <a
            className="cancel-link pointer"
            role="button"
            tabIndex={ -1 }
            onClick={ onBack }
          >
            Previous
          </a>
          <Button
            className="pull-right bg-purple-1000 in-white width-180px"
            data-color="$purple-1000"
            onClick={ handleSubmit }
          >
            Next
          </Button>
          <a
            className="cancel-link pointer pull-right m-r-10"
            role="button"
            tabIndex={ -1 }
            onClick={ handleDoLater }
          >
            I&apos;ll do later
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={ styles['authorized-signatory-container'] }>
      <div className="authorized-signatory-body authorized-payer-container">
        <h2>
          {' '}
          Hi
          {' '}
          {window.Docyt.currentAdvisor.get('first_name')}
          ,
          {' '}
        </h2>
        <h2>
          {
            (businesses.length === 1) ? (
              `Set up authorized signatory for ${businesses[0].displayName}`
            ) : (
              'Set up authorized signatory for the following businesses'
            )
          }
        </h2>
        {
          (businesses.length > 1) && (
            <div className="business-list">
              {
                businesses.map((business) => (
                  <div key={ business.id } className="business-item">
                    {' '}
                    { business.displayName }
                    {' '}
                  </div>
                ))
              }
            </div>
          )
        }
        <p className="font-11 in-grey-1050">
          Add signature that will be used on bank checks. Please ensure it matches the signature on file with your bank.
        </p>
        <div className="digital-signature-panel">
          <img alt="authorized_signatory" className="digital-signature-qr-code width-100-percent" src={ qrCodeResult } />
        </div>
        <div className="text-center">
          <div className="display-inline-block text-left">
            <p className="m-t-20">Step 1: Scan QR code with your phone and follow the link.</p>
            <p className="m-t-10">Step 2: Add digital signature on the drawpad that shows up.</p>
            <p className="m-t-10">Step 3: Tap the Save button.</p>
            <p className="m-t-10">
              Step 4:
              <a
                className="font-semi-bold pointer m-l-5"
                role="button"
                tabIndex={ -1 }
                onClick={ handleRefresh }
              >
                Click here to Refresh.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="setup-client-footer">
        <a
          className="cancel-link pointer"
          role="button"
          tabIndex={ -1 }
          onClick={ onBack }
        >
          Previous
        </a>
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Next
        </Button>
        <a
          className="cancel-link pointer pull-right m-r-10"
          role="button"
          tabIndex={ -1 }
          onClick={ handleDoLater }
        >
          I&apos;ll do later
        </a>
      </div>
    </div>
  );
};

export default AuthorizedSignatoryView;
