import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetAllChartOfAccountsForBusinessParams,
  getAllChartOfAccountsForBusiness,
  ICreateChartOfAccountResponse,
  ICreateChartOfAccountParams,
  createChartOfAccount,
  getChartOfAccountsByIds,
  IGetChartOfAccountsByIdsParams,
  IGetChartOfAccountResponse,
  getChartOfAccount,
  IGetChartOfAccountParams,
} from '@src/requests/chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';

import { QUICKBOOKS_PAYMENT_ACCOUNTS_QUERY } from './quickbooks_payment_accounts';

const useGetAllChartOfAccountsForBusiness =
  (
    params: IGetAllChartOfAccountsForBusinessParams,
    options?: UseQueryOptions<IChartOfAccount[], Error>,
  ) => {
    return useQuery<IChartOfAccount[], Error>(
      [QueryKey.chartOfAccounts, params],
      () => getAllChartOfAccountsForBusiness(params),
      options,
    );
  };

const useGetChartOfAccountsByIds = (params: IGetChartOfAccountsByIdsParams) => {
  return useQuery<IChartOfAccount[], Error>(
    [QueryKey.chartOfAccountsByIds, params],
    () => getChartOfAccountsByIds(params),
    {
      enabled: params.chartOfAccountIds.length !== 0,
    },
  );
};

const useGetChartOfAccount = (params: IGetChartOfAccountParams) => {
  return useQuery<IGetChartOfAccountResponse, Error>(
    [QueryKey.chartOfAccountById, params],
    () => getChartOfAccount(params),
    {
      enabled: Boolean(params.id),
    },
  );
};

const useCreateChartOfAccounts = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateChartOfAccountResponse, Error, ICreateChartOfAccountParams>(
    createChartOfAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.chartOfAccounts);
        queryClient.invalidateQueries(QUICKBOOKS_PAYMENT_ACCOUNTS_QUERY);
      },
    },
  );
};

export {
  useCreateChartOfAccounts,
  useGetAllChartOfAccountsForBusiness,
  useGetChartOfAccountsByIds,
  useGetChartOfAccount,
};
