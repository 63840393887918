module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td style="width: 30px;">\n    <div class="checkbox checkbox-primary">\n        <input type="checkbox" class="pointer checked-invoice" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n        <label></label>\n    </div>\n</td>\n<td class="invoice-avatar-column flex-wrapper relative invoice-clickable pointer">\n    <div class="header__userpic inline-block m-l-10">\n        <img src="');
    
      __out.push(__sanitize(this.vendor ? this.vendor.image_url : ''));
    
      __out.push('" class="header__userpic-img">\n    </div>\n    <p class="font-15 inline-block m-l-15 m-t-10 vertical-align-top">');
    
      __out.push(__sanitize(this.vendor ? this.vendor.name : ''));
    
      __out.push('</p>\n</td>\n<td class="invoice-detail-column relative invoice-clickable pointer">\n    <span class="font-14 p-l-10">');
    
      __out.push(__sanitize(this.date_of_invoice_formated));
    
      __out.push('</span>\n</td>\n<td class="invoice-detail-column invoice-status-panel-wrapper relative invoice-clickable pointer">\n    <span class="invoice-status-panel font-bold ');
    
      __out.push(__sanitize(this.label_state));
    
      __out.push('">');
    
      __out.push(__sanitize(this.check_status));
    
      __out.push('</span>\n</td>\n<td class="invoice-amount-column relative invoice-clickable pointer font-bold">\n    <span class="font-14 p-l-10">');
    
      __out.push(__sanitize(this.display_amount));
    
      __out.push('</span>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}