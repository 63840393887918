import React from 'react';

import { IPaymentProcessor } from '@src/types/payment_processors';

import {
  IReconcileRSDCollection,
  ITSDCollected,
} from '@src/components/revenue_center/reconcile/hooks';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import UtilSection from '@src/components/utils_v2/section';

import IncomesList from './incomes_list';
import RevenuesList from './revenues_list';

import styles from './styles.module.scss';

interface IReconcileDocumentsProps {
  rsdCollection: IReconcileRSDCollection,
  tsdCollection: ITSDCollected,
  currentPaymentProcessor: IPaymentProcessor,
  showOnlyUnreconciled: boolean,
  onClickCheckBox: (checked: boolean) => void,
}

const ReconcileDocuments = ({
  rsdCollection,
  tsdCollection,
  currentPaymentProcessor,
  showOnlyUnreconciled,
  onClickCheckBox,
}: IReconcileDocumentsProps) => {
  return (
    <DetailsRegion>
      <DetailsRegion.Header
        subtitle="Select a deposit from left and match it with respective transaction(s) from bank accounts from right."
        title=""
      >
        <CheckboxInput
          checked={ showOnlyUnreconciled }
          title="Show unreconciled only"
          onChange={ onClickCheckBox }
        />
      </DetailsRegion.Header>
      <div className={ styles['tables-container'] }>
        <UtilSection.Provider section={ rsdCollection.section }>
          <RevenuesList
            collection={ rsdCollection }
            currentPaymentProcessor={ currentPaymentProcessor }
          />
        </UtilSection.Provider>
        <UtilSection.Provider section={ tsdCollection.section }>
          <IncomesList
            collection={ tsdCollection }
            paymentProcessorName={ currentPaymentProcessor.name! }
          />
        </UtilSection.Provider>
      </div>
    </DetailsRegion>
  );
};

export default React.memo(ReconcileDocuments);
