import {
  signOut,
  fetchAuthSession,
  signInWithRedirect,
} from 'aws-amplify/auth';

export const GetCurrentUserSSOToken = async () => {
  window.Docyt.vent.trigger('show:spinner');
  const resp = await fetchAuthSession();
  const token = resp?.tokens?.accessToken?.toString();
  if (!token) {
    window.Docyt.vent.trigger('hide:spinner');
  }
  return token;
};

export const SSOSignIn = async () => {
  window.Docyt.vent.trigger('show:spinner');
  const resp = await fetchAuthSession();
  if (resp?.tokens?.accessToken?.toString()) {
    signOut();
  }
  await signInWithRedirect({ provider: 'Google' });
};

export const SSOSignOut = async () => {
  await signOut();
};
