import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetReportServiceBudgetFormulasParams {
  standardCategoryId: number,
}

const getReportServiceBudgetFormulas = (
  params: IGetReportServiceBudgetFormulasParams,
): Promise<IReportServiceBudgetFormula[]> => {
  return apiGet(
    '/reports/api/v1/budget_formulas',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return cdata.budgetFormulas;
  });
};

export {
  IGetReportServiceBudgetFormulasParams,
  getReportServiceBudgetFormulas,
};
