import React from 'react';

import Root from '@src/components/root';

import Sidemenu from './sidemenu';

const MultiEntityReportsSideMenu = ({ index }: { index: string }) => {
  return (
    <Root>
      <Sidemenu index={ index } />
    </Root>
  );
};

export default MultiEntityReportsSideMenu;
