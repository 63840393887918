import React from 'react';

import castArray from 'lodash/castArray';
import { Props } from 'react-select';

import { CloseIcon } from '@src/components/utils/icomoon';

import { TOption } from '../types';

import styles from './styles.module.scss';

interface SelectResponseOutsideProps<OptionType extends TOption> {
  value: Props<OptionType, true>['value'];
  onRemove(item: OptionType): void;
}

const SelectResponseOutside = <OptionType extends TOption, >({
  value,
  onRemove,
}: SelectResponseOutsideProps<OptionType>) => {
  if (!value) return null;

  const values = castArray(value);
  if (!values.length) return null;

  return (
    <div className={ styles['chip-outer-container'] }>
      {
        values.map((item) => (
          <div key={ item.value } className={ styles.chip }>
            {item.label}
            <CloseIcon
              className={ styles['chip-close-icon'] }
              fontSize={ 15 }
              fontVariant="semi-bold"
              onClick={ () => {
                onRemove(item);
              } }
            />
          </div>
        ))
      }
    </div>
  );
};

export default React.memo(SelectResponseOutside);
