module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div>\n  <div class="clearfix">\n    <div class="pull-left">\n      <h2 class="m-b-0 m-t-0 font-bold font-26 pull-left">Revenue Mappings</h2>\n    </div>\n    <div class="pull-right">\n      <div class="dropdown">\n        <a class="btn dropdown-toggle font-15 font-bold in-grey-800" type="button" id="revenue-report-types-');
    
      __out.push(__sanitize(this.rv_service.get('id')));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n          <span class="fa fa-ellipsis-h m-l-5"></span>\n        </a>\n        <ul class="revenue-action-dropdown dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="revenue-report-types-');
    
      __out.push(__sanitize(this.rv_service.get('id')));
    
      __out.push('-action-dropdown-btn">\n          <li><a class="btn-copy-report-type"><span class="icon icon-copy-icon font-24"></span>Copy from Another Business</a></li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <hr/>\n  <ul class="nav sub-header-menu p-t-5">\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.type !== 'archived' ? 'active' : ''));
    
      __out.push('">\n      <a class="queued-invoices-link" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('?type=active"> ACTIVE </a>\n    </li>\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.type === 'archived' ? 'active' : ''));
    
      __out.push('">\n      <a class="paid-invoices-link" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('?type=archived"> ARCHIVED </a>\n    </li>\n  </ul>\n  <div class="m-t-10 bg-white-rows">\n    <div class="revenue-types-list">\n    </div>\n    ');
    
      if (this.type !== 'archived') {
        __out.push('\n    <p class="m-b-0 line-height-50 p-l-15"><a class="btn-add-report-type font-14 font-semi-bold vertical-align-middle pointer"><span class="icon-o-plus font-24 vertical-align-middle m-r-5"></span> Add New Revenue Mapping</a></p>\n    ');
      }
    
      __out.push('\n  </div>\n</div> ');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}