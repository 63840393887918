/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';

import moment from 'moment';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useMetricsServiceTemplateExcel } from '@src/requests/metrics_services';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon, CsvIcon, DownloadDocumentIcon, ContentCopyIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IImportMetricsService extends IUseModalProps {
  serviceEmail: string,
  businessId: TID,
  standardIndustryId: TID,
  businessName: string,
}

const ImportMetricsServiceModal: React.FC<IImportMetricsService> =
({
  isOpen,
  onDone,
  serviceEmail,
  businessId,
  standardIndustryId,
  businessName,
}) => {
  const [badgeShow, setBadgeShow] = useState(false);
  const { mutateAsync } = useMetricsServiceTemplateExcel();
  const params = { businessId, standardIndustryId };

  const downloadMetricsServiceTemplate = useCallback(async () => {
    try {
      const data = await mutateAsync(params);
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `metrics-${moment().format('DD MM YYYY')}-${businessName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error: any) {
      toastr.error(`${error?.errors[0]}`, 'Error');
    }
  }, [params, businessName]);

  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(serviceEmail);
    setBadgeShow(true);
  }, [serviceEmail]);

  const handleOnClick = useCallback(() => {
    setBadgeShow(false);
    onDone();
  }, [onDone]);

  return (
    <Modal
      className={ styles['modal-template-excel'] }
      show={ isOpen }
    >
      <Modal.Body>
        <Text as="h2" fontSize={ 20 } fontVariant="bold" textAlign="center">Import Data Using Excel file</Text>
        <div className={ styles['download-template'] }>
          <CsvIcon fontSize={ 60 } />
          <div className={ styles['wrapper-download'] }>
            <Text fontSize={ 14 } fontVariant="semi-bold">Download the Template</Text>
            <Text fontSize={ 12 }>Download the Excel Template, add data, and send it to email address given below.</Text>
            <Button
              variant="link"
              onClick={ downloadMetricsServiceTemplate }
            >
              <Text className={ styles['email-text'] } fontSize={ 12 } fontVariant="semi-bold">
                <DownloadDocumentIcon mr={ 8 } />
                Download
              </Text>
            </Button>
          </div>
        </div>
        <div className={ styles['wrapper-copy'] }>
          <div className={ styles['text-container'] }>
            <Text fontSize={ 12 } inColor="black">
              Email the populated Excel file to
              <Text className={ styles['email-text'] } fontSize={ 12 } fontVariant="semi-bold">
                {serviceEmail}
              </Text>
            </Text>
            <Button
              variant="link"
              onClick={ copyEmail }
            >
              <ContentCopyIcon fontSize={ 16 } ml={ 10 } />
            </Button>
          </div>
          {badgeShow ? (
            <Text className={ styles['badge-copy'] } fontSize={ 12 }>
              <CheckGreenIcon fontSize={ 17 } />
              Copied to Clipboard
            </Text>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <span />
        <Button
          type="submit"
          variant="primary"
          onClick={ handleOnClick }
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useImportMetricsServiceModal = makeUseModal(ImportMetricsServiceModal);
export {
  useImportMetricsServiceModal,
  ImportMetricsServiceModal as default,
};
