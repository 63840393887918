import loadjs from 'loadjs';

const loadFaye = (fayeHost: string): Promise<void> => {
  return loadjs(
    `${fayeHost}/client.js`,
    { returnPromise: true },
  ).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error on loading Faye: ', error);
  });
};

export { loadFaye };
