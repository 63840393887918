import React, { forwardRef, useCallback } from 'react';

import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { TRichAmount } from './types';
import { amountInputProps } from './utils';

import styles from './styles.module.scss';

interface IRichAmountValueControlProps extends
  Omit<React.ComponentPropsWithoutRef<typeof NumberFormat>, 'customInput' | 'value' | 'onChange'>
{
  placeholder?: string,
  value?: TRichAmount,
  onChange: (value: TRichAmount | undefined) => void,
}

const RichAmountValueControl = forwardRef(({
  className,
  value,
  onChange,
  ...props
}: IRichAmountValueControlProps, ref) => {
  const handleChange = useCallback((values) => {
    if (!values?.value) {
      onChange(undefined);
      return;
    }

    onChange({ val: values.value });
  }, [onChange]);

  const classes = classNames(styles['rich-amount-control'], className);

  return (
    <NumberFormat
      { ...amountInputProps }
      placeholder="$ Enter Amount"
      { ...props }
      className={ classes }
      getInputRef={ ref }
      value={ value?.val || '' }
      onValueChange={ handleChange }
    />
  );
});

RichAmountValueControl.displayName = 'RichAmountValueControl';

export default React.memo(RichAmountValueControl);
