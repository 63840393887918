import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Tooltip from '@src/components/ui_v2/tooltip';
import {
  VerifiedManuallyIcon,
  BotNotificationIcon,
  UncategorizedAltIcon,
} from '@src/components/utils/icomoon';

interface ICategorizedByIconProps {
  document: ITransactionServiceDocument,
}

const CategorizedByIcon = ({
  document,
}: ICategorizedByIconProps) => {
  let icon: React.ReactNode | null = null;
  let title: string | null = null;

  if (document.state === 'unverified') {
    icon = <UncategorizedAltIcon fontSize={ 18 } />;
    title = 'Unverified';
  } else if (document.verifiedByUser) {
    icon = <VerifiedManuallyIcon fontSize={ 18 } />;
    title = 'Verified manually';
  } else {
    icon = <BotNotificationIcon fontSize={ 18 } />;
    title = 'Verified automatically';
  }

  return (
    <Tooltip.Hover content={ title }>
      { icon }
    </Tooltip.Hover>
  );
};

export default React.memo(CategorizedByIcon);
