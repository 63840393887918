import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IReport, ITemplate } from '@src/types/report_service/report';

import Modal from '@src/components/ui/modal';

import Form from '../forms/create_new_report';

interface ICreateNewReportModalProps extends IUseModalProps {
  reports: IReport[],
  templates: ITemplate[],
}

const CreateNewReportModal = ({
  reports,
  templates,
  isOpen,
  onCancel,
  onDone,
}: ICreateNewReportModalProps) => {
  return (
    <Modal.Form
      dialogClassName="width-700px"
      proceedTitle="Create Report"
      show={ isOpen }
      title="Create New Advanced Report"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          formId={ formId }
          reports={ reports }
          templates={ templates }
          onSubmit={ onDone }
        />
      )}
    </Modal.Form>
  );
};

const useCreateNewReportModal = makeUseModal(CreateNewReportModal);

export {
  ICreateNewReportModalProps,
  useCreateNewReportModal,
  CreateNewReportModal as default,
};
