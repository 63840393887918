import React, { useCallback } from 'react';

import { useLDManagementGroupFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useLocalStorage } from '@src/hooks/storage';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';
import Table from '@src/components/ui/table';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Actions from './actions/actions';
import SubItem from './financial_institution_connection_sub_item';

import styles from '../styles.module.scss';

interface IFinancialInstitutionConnectionCenterTableItemProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
  refetchFinancialConenctionList: () => void
}

const FinancialInstitutionConnectionCenterTableItem = ({
  financialInstitutionConnection,
  refetchFinancialConenctionList,
}: IFinancialInstitutionConnectionCenterTableItemProps) => {
  const expand = useLocalStorage(`financial_institution_${financialInstitutionConnection.id}`, true);
  const employeeCardFeatureQuery = useLDManagementGroupFeatureQuery(
    financialInstitutionConnection.managementGroup.id,
    window.Docyt.Common.Constants.EMPLOYEE_CARDS_MULTI_ENTITY_SUPPORT,
  );

  const employeeCardFeatureFlag = financialInstitutionConnection.aggregator === 'plaid'
    ? !!employeeCardFeatureQuery.data
    : false;

  const handleExpand = useCallback(() => {
    expand.setValue(!expand.storedValue);
  }, [expand]);

  return (
    <>
      <Table.Row>
        <Table.Cell className={ styles['financial-institution-name-section'] } onClick={ handleExpand }>
          <div className="display-flex align-items-center">
            {
              expand.storedValue ? <CaretIcon ml={ 10 } mr={ 10 } variant="down" />
                : <CaretIcon ml={ 10 } mr={ 10 } variant="up" />
            }
            <FinancialInstitutionAvatar
              logo={ financialInstitutionConnection.financialInstitution.logo }
            />
            <span className="m-l-10">{ financialInstitutionConnection.financialInstitution.name }</span>
          </div>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          { financialInstitutionConnection.managementGroup.name }
        </Table.Cell>
        <Table.Cell />
        <Table.Cell>
          {
            financialInstitutionConnection.isActive
              ? (<span className={ styles['connected-banner'] }>Connected</span>)
              : (<span className={ styles['disconnected-banner'] }>Disconnected</span>)
          }
        </Table.Cell>
        <Table.Cell className={ styles['actions-column'] }>
          <Actions financialInstitutionConnection={ financialInstitutionConnection } />
        </Table.Cell>
      </Table.Row>
      {
        expand.storedValue && financialInstitutionConnection.financialInstitutionBankAccounts
          .map((financialInstitutionBankAccount) => (
            <SubItem
              key={ financialInstitutionBankAccount.id }
              employeeCardFeatureFlag={ !!employeeCardFeatureFlag }
              financialInstitutionBankAccount={ financialInstitutionBankAccount }
              managementGroup={ financialInstitutionConnection.managementGroup }
              refetchFinancialConenctionList={ refetchFinancialConenctionList }
            />
          ))
      }
    </>
  );
};

export default React.memo(FinancialInstitutionConnectionCenterTableItem);
