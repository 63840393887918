import React, { useCallback } from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import { useBalanceSheetStatementVerifyModal } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_statement_modal/verify_modal/verify_modal';
import { Button } from '@src/components/ui_v2/buttons';

import styles from '../../styles.module.scss';

interface IVerifiedStatusItemProps {
  businessId: TID,
  state: IBalanceSheetStatement,
}

const VerifiedStatusItem = ({
  businessId,
  state,
}: IVerifiedStatusItemProps) => {
  const verifyModal = useBalanceSheetStatementVerifyModal();
  const { openWithValue } = verifyModal;

  const handleClick = useCallback(() => {
    if (!state.docytId) return;

    openWithValue({
      docytId: state.docytId,
    });
  }, [state.docytId, openWithValue]);

  if (!state.docytId) return null;

  return (
    <>
      <verifyModal.Component
        { ...verifyModal.props }
        businessId={ businessId }
      />
      <Button
        className={ styles['status-item-button'] }
        variant="link"
        onClick={ handleClick }
      >
        { state.statementFileName ? state.statementFileName : '--' }
      </Button>
    </>
  );
};

export default VerifiedStatusItem;
