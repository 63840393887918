import {
  IBankStatement,
  IBankStatementData,
  TBankStatementsSortColumn,
  IBankStatementsFilter,
  IReviewedStatementsDetailsFilter,
  TReviewedStatementDetailsSortColumn,
  IReviewedBankStatementsData,
  IReviewedStatementsFilter,
  TReviewedStatementSortColumn,
} from '@src/types/bank_statements';
import { TDate, TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetBankStatementsParams {
  businessId: TID,
  reconciliationPaymentAccountId?: TID,
  filter?: IBankStatementsFilter | IReviewedStatementsDetailsFilter,
  page?: number,
  orderColumn?: TBankStatementsSortColumn | TReviewedStatementDetailsSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetBankStatementsResponse {
  meta: {
    totalCount: number,
    noFilteredCount: number,
    startImportDate: TDate,
    reconciliationPaymentAccountName: string,
  },
  collection: IBankStatement[],
}

interface ICreateBankStatementParams {
  businessId: TID,
  file: File,
  reconciliationPaymentAccountId?: TID,
  date?: TDate,
  closingBalance?: string,
}

interface ICreateBankStatementResponse {
  bankStatement: IBankStatement,
}

interface IUpdateBankStatementParams {
  id: TID,
  businessId: TID,
  reconciliationPaymentAccountId?: TID,
  bankStatement?: IBankStatementData
}

interface IDeleteBankStatementParams {
  id: TID;
  state?: string;
  confirm?: boolean;
}

interface IGetBankStatementResponse {
  bankStatement?: IBankStatement,
}

interface IBankStatementParams {
  id: TID,
}

interface IUnverifyBankStatementParams {
  id?: TID,
  businessId?: TID,
  reconciliationPaymentAccountId?: TID,
  date?: string,
}

interface IMarkAsNoAvailableBankStatementParams {
  businessId: TID,
  reconciliationPaymentAccountId: TID,
  date?: string,
}

const getBankStatements = (
  params: IGetBankStatementsParams,
): Promise<IGetBankStatementsResponse> => {
  return apiGet(
    '/api/v1/bank_statements',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.bankStatements,
    } as IGetBankStatementsResponse;
  });
};

const createBankStatement = (
  params: ICreateBankStatementParams,
): Promise<ICreateBankStatementResponse> => {
  const formData = new FormData();
  formData.append('business_id', String(params.businessId));
  formData.append('reconciliation_payment_account_id', String(params.reconciliationPaymentAccountId));
  formData.append('date', String(params.date));
  formData.append('closing_balance', String(params.closingBalance));
  formData.append('file', params.file);
  return apiPost(
    '/api/v1/bank_statements',
    formData,
  ).then((data) => camelizeKeys(data) as ICreateBankStatementResponse);
};

const updateBankStatement = (
  params: IUpdateBankStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/bank_statements/${params.id}`,
    underscoreKeys(params),
  );
};

const deleteBankStatement = (
  params: IDeleteBankStatementParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/bank_statements/${params.id}`,
    underscoreKeys(params),
  );
};

const approveBankStatement = (
  params: IBankStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/bank_statements/${params.id}/approve`,
    underscoreKeys(params),
  );
};

const verifyBankStatement = (
  params: IBankStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/bank_statements/${params.id}/verify`,
    underscoreKeys(params),
  );
};

const getBankStatement = (businessId: TID, id: TID): Promise<IGetBankStatementResponse> => {
  return apiGet(
    `/api/v1/bank_statements/${id}`,
    underscoreKeys({ businessId }),
  ).then((data) => {
    return {
      bankStatement: camelizeKeys(data.bank_statement),
    } as IGetBankStatementResponse;
  });
};

const getBankStatementByDocytId = (
  businessId?: TID,
  docytId?: string,
): Promise<IGetBankStatementResponse> => {
  return apiGet(
    '/api/v1/bank_statements/by_docyt_id',
    underscoreKeys({ businessId, docytId }),
  ).then((data) => {
    return {
      bankStatement: camelizeKeys(data.bank_statement),
    } as IGetBankStatementResponse;
  });
};

const getNextBankStatement = (
  id?: TID,
): Promise<IGetBankStatementResponse> => {
  return apiGet(
    '/api/v1/bank_statements/next',
    underscoreKeys({ id }),
  ).then((data) => {
    return {
      bankStatement: camelizeKeys(data.bank_statement),
    } as IGetBankStatementResponse;
  });
};

interface IGetReviewedBankStatementsParams {
  businessId: TID,
  months?: number,
  monthEndDocument?: boolean,
  filter?: IReviewedStatementsFilter,
  page?: number,
  orderColumn?: TReviewedStatementSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetReviewedBankStatementsResponse {
  meta: {
    totalCount: number,
    noFilteredCount: number,
  },
  collection: IReviewedBankStatementsData[],
}

const getReviewedBankStatements = (
  params: IGetReviewedBankStatementsParams,
): Promise<IGetReviewedBankStatementsResponse> => {
  return apiGet(
    '/api/v1/reviewed_bank_statements',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.reviewedBankStatements,
    } as IGetReviewedBankStatementsResponse;
  });
};

const postUnverifyBankStatement = (
  params: IUnverifyBankStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/bank_statements/unverify',
    underscoreKeys(params),
  );
};

const postUnapproveBankStatement = (
  params: IBankStatementParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/bank_statements/${params.id}/unapprove`,
    underscoreKeys(params),
  );
};

const postUnmarkNoStatementAvailable = (
  params: IBankStatementParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/bank_statements/${params.id}/unmark_no_statement_available`,
    underscoreKeys(params),
  );
};

const postMarkAsNoAvailableBankStatement = (
  params: IMarkAsNoAvailableBankStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/bank_statements/mark_as_no_available',
    underscoreKeys(params),
  );
};

interface IGetDuplicatedBankStatementParams {
  businessId?: TID,
  reconciliationPaymentAccountId?: TID,
  date?: TDate,
}

const getDuplicatedBankStatement = (
  params: IGetDuplicatedBankStatementParams,
): Promise<IGetBankStatementResponse> => {
  return apiGet(
    '/api/v1/bank_statements/duplicated_statement',
    underscoreKeys(params),
  ).then((data) => {
    return {
      bankStatement: camelizeKeys(data.bank_statement),
    } as IGetBankStatementResponse;
  });
};

interface IResolveDuplicateBankStatementParams {
  businessId: TID,
  reconciliationPaymentAccountId?: TID,
  originBankStatementId?: TID,
  closingBalance?: string,
  date?: TDate,
  file: File,
}

const postResolveDuplicateBankStatement = (
  params: IResolveDuplicateBankStatementParams,
): Promise<void> => {
  const formData = new FormData();
  formData.append('business_id', String(params.businessId));
  formData.append('reconciliation_payment_account_id', String(params.reconciliationPaymentAccountId));
  formData.append('origin_bank_statement_id', String(params.originBankStatementId));
  formData.append('date', String(params.date));
  formData.append('closing_balance', String(params.closingBalance));
  formData.append('file', params.file);
  return apiPost(
    '/api/v1/bank_statements/resolve_duplicate',
    formData,
  );
};

interface ISetBankStatementToTransactionServiceDocumentsParams {
  id: TID,
  businessId: TID,
  selected: boolean,
  transactionIds: TID[],
  from?: TDate,
  to?: TDate,
}

const postSetBankStatementToTransactionServiceDocuments = (
  params: ISetBankStatementToTransactionServiceDocumentsParams,
): Promise<IGetBankStatementResponse> => {
  return apiPost(
    '/api/v1/bank_statements/set_bank_statement_to_transaction_service_documents',
    underscoreKeys(params),
  ).then((data) => {
    return {
      bankStatement: camelizeKeys(data.bank_statement),
    } as IGetBankStatementResponse;
  });
};

interface IRequestBankStatementParams {
  businessId: TID,
  reconciliationPaymentAccountId: TID,
  date: TDate,
}

const postRequestBankStatement = (
  params: IRequestBankStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/bank_statements/request_statement',
    underscoreKeys(params),
  );
};

export {
  IGetBankStatementsParams,
  IGetBankStatementsResponse,
  ICreateBankStatementParams,
  IUpdateBankStatementParams,
  ICreateBankStatementResponse,
  IDeleteBankStatementParams,
  IGetBankStatementResponse,
  IBankStatementParams,
  IUnverifyBankStatementParams,
  IGetReviewedBankStatementsParams,
  IGetReviewedBankStatementsResponse,
  IMarkAsNoAvailableBankStatementParams,
  IResolveDuplicateBankStatementParams,
  ISetBankStatementToTransactionServiceDocumentsParams,
  IRequestBankStatementParams,
  getBankStatements,
  deleteBankStatement,
  createBankStatement,
  updateBankStatement,
  approveBankStatement,
  verifyBankStatement,
  getBankStatement,
  getBankStatementByDocytId,
  getNextBankStatement,
  getReviewedBankStatements,
  postUnverifyBankStatement,
  postMarkAsNoAvailableBankStatement,
  getDuplicatedBankStatement,
  postResolveDuplicateBankStatement,
  postUnapproveBankStatement,
  postUnmarkNoStatementAvailable,
  postSetBankStatementToTransactionServiceDocuments,
  postRequestBankStatement,
};
