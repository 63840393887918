module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var date_format, header, i, index, j, k, l, len, len1, len2, len3, len4, len5, len6, m, n, o, ref, ref1, ref2, ref3, ref4, ref5, ref6;
    
      __out.push('<h3 class="font-16 m-t-0 m-b-25 font-bold">Match columns from CSV to the fields on left.</h3>\n<div class="document-fields p-t-15">\n  <div class="document-field">\n    <div class="document-field-item document-field-name">CSV Properties</div>\n    <div class="document-field-item document-field-value transaction-import-include-header">\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioIncludeHeader" name="include_header" class="pointer radio-include-header" value="true" ');
    
      if (this.params.include_header) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n        <label for="radioIncludeHeader">First Row is Header</label>\n      </div>\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioNoHeader" name="include_header" class="pointer radio-include-header" value="false" ');
    
      if (!this.params.include_header) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n        <label for="radioNoHeader">No Header Row</label>\n      </div>\n    </div>\n  </div>\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Amount Columns</div>\n    <div class="document-field-item document-field-value transaction-import-include-header">\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-multiline">\n        <input type="radio" id="radioAmountColumns1" name="amount_columns" class="pointer radio-amount-columns" value="1" ');
    
      if (this.amount_columns === 1) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n        <label for="radioAmountColumns1">1 Column <br><small class="text-muted">Same column has positive & negative values</small></label>\n      </div>\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-multiline">\n        <input type="radio" id="radioAmountColumns2" name="amount_columns" class="pointer radio-amount-columns" value="2" ');
    
      if (this.amount_columns === 2) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n        <label for="radioAmountColumns2">2 Columns <br><small class="text-muted">Different columns for money coming in and going out</small></label>\n      </div>\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-multiline">\n        <input type="radio" id="radioAmountColumns3" name="amount_columns" class="pointer radio-amount-columns" value="3" ');
    
      if (this.amount_columns === 3) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n        <label for="radioAmountColumns3">2 Columns <br><small class="text-muted">Different columns for debit/credit and amount</small></label>\n      </div>\n    </div>\n  </div>\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Date</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_date_column" title="Select Column">\n        ');
    
      ref = this.headers;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        header = ref[index];
        __out.push('\n        <option value=');
        __out.push(__sanitize(index));
        __out.push(' ');
        if (this.params.transaction_date_column === index) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Column ');
        __out.push(__sanitize(index + 1));
        __out.push(': ');
        __out.push(__sanitize(header));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Date Format</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_date_format" title="Select Format">\n        ');
    
      ref1 = this.date_formats;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        date_format = ref1[j];
        __out.push('\n        <option value="');
        __out.push(__sanitize(date_format.value));
        __out.push('" ');
        if (this.params.transaction_date_format === date_format.value) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(date_format.display_name));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Description</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_description_column" title="Select Column">\n        ');
    
      ref2 = this.headers;
      for (index = k = 0, len2 = ref2.length; k < len2; index = ++k) {
        header = ref2[index];
        __out.push('\n        <option value=');
        __out.push(__sanitize(index));
        __out.push(' ');
        if (this.params.transaction_description_column === index) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Column ');
        __out.push(__sanitize(index + 1));
        __out.push(': ');
        __out.push(__sanitize(header));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Amount</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_amount_column1" title="');
    
      __out.push(__sanitize(this.amount_columns === 2 ? 'Select money spent' : 'Select Column'));
    
      __out.push('">\n        ');
    
      ref3 = this.headers;
      for (index = l = 0, len3 = ref3.length; l < len3; index = ++l) {
        header = ref3[index];
        __out.push('\n        <option value=');
        __out.push(__sanitize(index));
        __out.push(' ');
        if (this.params.transaction_amount_column1 === index) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Column ');
        __out.push(__sanitize(index + 1));
        __out.push(': ');
        __out.push(__sanitize(header));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n  ');
    
      if (this.amount_columns === 2) {
        __out.push('\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4"></div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_amount_column2" title="Select money received">\n        ');
        ref4 = this.headers;
        for (index = m = 0, len4 = ref4.length; m < len4; index = ++m) {
          header = ref4[index];
          __out.push('\n        <option value=');
          __out.push(__sanitize(index));
          __out.push(' ');
          if (this.params.transaction_amount_column2 === index) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Column ');
          __out.push(__sanitize(index + 1));
          __out.push(': ');
          __out.push(__sanitize(header));
          __out.push('</option>\n        ');
        }
        __out.push('\n      </select>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.amount_columns === 3) {
        __out.push('\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Credit or Debit</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="credit_or_debit_column" title="Select column">\n        ');
        ref5 = this.headers;
        for (index = n = 0, len5 = ref5.length; n < len5; index = ++n) {
          header = ref5[index];
          __out.push('\n        <option value=');
          __out.push(__sanitize(index));
          __out.push(' ');
          if (this.params.credit_or_debit_column === index) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>Column ');
          __out.push(__sanitize(index + 1));
          __out.push(': ');
          __out.push(__sanitize(header));
          __out.push('</option>\n        ');
        }
        __out.push('\n      </select>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="document-field">\n    <div class="document-field-item document-field-name m-t-4">Account Holder</div>\n    <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n      <select class="selectpicker" name="transaction_account_holder_column" title="Select Column">\n        <option value="-1" ');
    
      if (!this.params.transaction_account_holder_column) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('></option>\n        ');
    
      ref6 = this.headers;
      for (index = o = 0, len6 = ref6.length; o < len6; index = ++o) {
        header = ref6[index];
        __out.push('\n        <option value=');
        __out.push(__sanitize(index));
        __out.push(' ');
        if (this.params.transaction_account_holder_column === index) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Column ');
        __out.push(__sanitize(index + 1));
        __out.push(': ');
        __out.push(__sanitize(header));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n  </div>\n  ');
    
      if (this.amount_columns === 1) {
        __out.push('\n  <div class="document-field m-t-25">\n    <div class="document-field-item document-field-name">Business Expense</div>\n    <div class="document-field-item document-field-value transaction-import-expense-value">\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioExpenseNegative" name="expense_value" class="pointer radio-expense-value" value="false" ');
        if (!this.params.is_expense_positive) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n        <label for="radioExpenseNegative">Business expenses are negative numbers</label>\n      </div>\n      <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n        <input type="radio" id="radioExpensePositive" name="expense_value" class="pointer radio-expense-value" value="true" ');
        if (this.params.is_expense_positive) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n        <label for="radioExpensePositive">Business expenses are positive numbers</label>\n      </div>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n</div>\n<div class="save-btn-container">\n  <button class="btn btn-blue save-btn">Save</button>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}