import React from 'react';

import { useParams } from 'react-router-dom';

import { useLocalStorage } from '@src/hooks/storage';
import { TUUID } from '@src/types/common';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import Tooltip from '@src/components/ui_v2/tooltip';
import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface IServiceSubmenuProps {
  current?: TUUID
  subTitle?: React.ReactNode;
  children?: React.ReactNode;
  expandedKey?: string;
  icon: React.ReactNode;
  title: string;
  href?: string;
  launchDarklyFeature?: TLaunchDarklyFeature;
}

const ServiceSubmenu = ({
  current,
  subTitle,
  children,
  expandedKey,
  icon,
  title,
  href,
  launchDarklyFeature,
}: IServiceSubmenuProps): JSX.Element | null => {
  const expand = useLocalStorage(expandedKey || '', true);

  const handleExpand = () => {
    expand.setValue(!expand.storedValue);
  };

  const { revenueSystemId } = useParams();

  return (
    <>
      <li className="nav-transaction-type" data-global-feature-toggle={ launchDarklyFeature }>
        {expand && (
          <a
            className={ `${revenueSystemId === current ? styles['current-submenu-item-title'] : ''} transaction-types-toggle` }
            role="button"
            tabIndex={ 0 }
            onClick={ handleExpand }
          >
            <span className={ styles.icon }>{icon}</span>
            <div className={ styles['content-wrapper'] }>
              <a className="submenu-title" href={ href }>
                <Tooltip.Hover className={ styles['submenu-item-container'] } content={ title }>
                  <span className={ styles['submenu-item-title'] }>{title}</span>
                </Tooltip.Hover>
                {subTitle && <span className={ styles['sub-title'] }>{subTitle}</span>}
              </a>
            </div>
            {expandedKey && (expand.storedValue ? (
              <CaretIcon ml={ 10 } variant="up" />
            ) : (
              <CaretIcon ml={ 10 } variant="down" />
            ))}
          </a>
        )}
      </li>
      {expand.storedValue && children}
    </>
  );
};

export default React.memo(ServiceSubmenu);
