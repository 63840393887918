import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IStatementViewFilter } from './types';

interface IFilterProps {
  filter: IFilter<IStatementViewFilter>;
}

const StatementFilter = ({
  filter,
}: IFilterProps) => {
  return (
    <Filter<IStatementViewFilter> filter={ filter }>
      <Filter.TextField
        name="accountName"
        placeholder="Balance Sheet Account"
        widthPercent={ 15 }
      />
      <Filter.YearField
        name="year"
        options={ { endDate: new Date(), date: new Date() } }
        placeholder="Year"
        widthPercent={ 12 }
      />
    </Filter>
  );
};

export default React.memo(StatementFilter);
