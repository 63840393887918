import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const NoTeamAssignedIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="No-user-assigned"
      pathsNumber={ 3 }
    />
  );
};

export default NoTeamAssignedIcon;
