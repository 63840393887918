import React, { useMemo, useCallback } from 'react';

import { useBulkDeleteDataExports } from '@src/hooks/queries/data_exports';
import { IDataExport } from '@src/types/data_exports';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

type DataExportBulkActionsProps = {
  dataExports: IDataExport[],
};

const BulkDeleteDataExportsAction = ({
  dataExports,
}: DataExportBulkActionsProps) : JSX.Element => {
  const deleteDataExport = useBulkDeleteDataExports();

  const dataExportIds = useMemo(() => {
    return dataExports
      .map((rsd) => rsd?.id)
      .filter((id): id is number => id !== undefined);
  }, [dataExports]);

  const isDisabled = dataExportIds.length === 0;

  const bulkAction = useBulkActionModal({
    mutation: deleteDataExport,
  });

  const confirm = useConfirmDeleteModal({
    onDone: useCallback(() => {
      bulkAction.runMutation(dataExportIds.map((dataExportId) => ({ dataExportId })));
    }, [bulkAction, dataExportIds]),
  });

  return (
    <>
      <confirm.Component
        { ...confirm.props }
        confirmTitle="Proceed"
        icon={ undefined }
        text={ (
          <div className="text-center">
            <p>
              Are you sure you want to delete
              <b>
                {' '}
                { dataExportIds.length }
              </b>
            </p>
            <p>
              data exports?
            </p>
          </div>
        ) }
        title="Remove Data Export"
      />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Data Export"
        title="Delete exports"
      />
      <ActionsDropdown.Action
        disabled={ isDisabled }
        icon={ <DeleteDocumentIcon fontSize={ 20 } /> }
        title="Delete"
        onClick={ !isDisabled ? confirm.open : undefined }
      />
    </>
  );
};

export default BulkDeleteDataExportsAction;
