import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DocumentRequestIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="document-request"
    />
  );
};

export default DocumentRequestIcon;
