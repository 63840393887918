import React, { useEffect } from 'react';

import styles from './styles.module.scss';

interface IFullPageModalProps {
  children: React.ReactNode,
}

const FullPageModal = ({
  children,
}: IFullPageModalProps): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);
  return (
    <div className={ styles['full-page-modal'] }>
      { children }
    </div>
  );
};

export default FullPageModal;
