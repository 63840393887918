/* eslint-disable import/order */

// Imported via aliases (see ../library_aliases.js file)
// import '@vendor_js/backbone-client/underscore';
// Imported via aliases (see ../library_aliases.js file) and ProvidePlugin
// import '@vendor_js/backbone-client/backbone';
// Imported via aliases (see ../library_aliases.js file) and ProvidePlugin
// import '@vendor_js/backbone-client/marionette';
import '@vendor_js/backbone-client/backbone-paramRoot';
import '@vendor_js/backbone-client/backbone-route-filter';
import './selectize';
import './launch_darkly';
// NPM package is used instead because moment requires locales
// that are not available in project.
// import '@vendor_js/backbone-client/moment';
import '@vendor_js/backbone-client/jquery-highlight';
import '@vendor_js/backbone-client/jquery.inputmask.bundle';
import './picker';
import './toastr';
import filesize from '@vendor_js/backbone-client/filesize';
import '@vendor_js/backbone-client/bootstrap-pincode-input';

window.filesize = filesize;
