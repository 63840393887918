import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import DeleteAction from './delete_action';

interface ITransactionActionsMenuProps {
  transaction: ITransactionServiceDocument,
}

const TransactionActionsMenu = ({
  transaction,
}: ITransactionActionsMenuProps) => {
  return (
    <ActionsDropdown mt={ 5 } widthPercent={ 30 }>
      <DeleteAction transaction={ transaction } />
    </ActionsDropdown>
  );
};

export default TransactionActionsMenu;
