import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useGetNotificationPreference, useUpdateNotificationPreference } from '@src/hooks/queries/financial_insights';
import { INotificationOption, INotificationPreference } from '@src/types/financial_insight';

import { DropdownInput } from '@src/components/ui_v2/inputs';

const options = [
  {
    value: 'false',
    label: 'Email Notifications Disabled',
  },
  {
    value: 'true',
    label: 'Email Notifications Enabled',
  },
];

const EmailNotifications = () => {
  const { data } = useGetNotificationPreference();
  const [initialData, setInitialData] = useState<INotificationPreference | null>(null);
  const { mutate: updateNotificationPreferenceMutate } = useUpdateNotificationPreference();

  useEffect(() => {
    if (data) {
      setInitialData(data);
    }
  }, [data]);

  const handleUpdate = useCallback((name :string, value: boolean) => {
    if (initialData !== null) {
      const updatedData : INotificationPreference = {
        ...initialData,
        [name]: value,
      };
      setInitialData(updatedData);
      updateNotificationPreferenceMutate(updatedData);
    }
  }, [initialData, updateNotificationPreferenceMutate]);

  const selectedAccountsPayableOption = useMemo(() => {
    if (initialData) {
      return options.find((item) => item.value.toString()
        === initialData.accountsPayable.toString()) || null;
    }
    return null;
  }, [initialData]);

  const selectedUndocumentedTransaction = useMemo(() => {
    if (initialData) {
      return options.find((item) => item.value.toString()
        === initialData.undocumentedTransaction.toString()) || null;
    }
    return null;
  }, [initialData]);

  return (
    <div className="row">

      <div className="col-sm-12 m-t-40 padding-0">
        <div className="display-flex align-items-center justify-content-between">
          <div className="col-sm-2">
            <h2 className="font-semi-bold font-16">
              Accounts Payable
            </h2>
            <p className="font-14">
              Bills that are due or ready to be paid,
              approved or verified.
            </p>
          </div>

          <div className="col-sm-3">
            <DropdownInput
              className="width-100-percent"
              options={ options }
              value={ selectedAccountsPayableOption }
              onChange={ (option : INotificationOption | null) => {
                if (option) {
                  handleUpdate('accountsPayable', option.value.toString() === 'true');
                }
              } }
            />
          </div>
        </div>
      </div>

      <div className="col-sm-12 m-t-40  padding-0">
        <div className="display-flex align-items-center justify-content-between">
          <div className="col-sm-3">
            <h2 className="font-semi-bold font-16">
              Undocumented Check Transactions
            </h2>
            <p className="font-14">
              Daily notification on check transactions that
              have been imported but not matched to any
              invoice.
            </p>
          </div>

          <div className="col-sm-3">
            <DropdownInput
              className="width-100-percent"
              options={ options }
              value={ selectedUndocumentedTransaction }
              onChange={ (option : INotificationOption | null) => {
                if (option) {
                  handleUpdate('undocumentedTransaction', option.value.toString() === 'true');
                }
              } }
            />
          </div>
        </div>
      </div>

    </div>
  );
};
export default EmailNotifications;
