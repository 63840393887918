import React from 'react';

import newDocumentLogoImg from '@assets/images/Group2.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const NewDocumentIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="New document"
      src={ newDocumentLogoImg }
      { ...props }
    />
  );
};

export default NewDocumentIcon;
