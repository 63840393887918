import React from 'react';

import {
  DashboardsLockedIcon,
} from '@src/components/utils/img_icons';

import styles from './styles.module.scss';

const DashboardNoAccessView = () => {
  return (
    <div className={ styles['dashboard-empty-container'] }>
      <div className={ styles['empty-wrapper'] }>
        <div className={ styles['empty-image'] }>
          <DashboardsLockedIcon />
        </div>
        <p className={ styles['empty-title'] }>
          You do not have access to this dashboard
        </p>
        <p className={ styles['empty-text'] }>
          This data will appear as soon as you are given access to the dashboard.
          Please reach out to your Admin to provide access.
        </p>
      </div>
    </div>
  );
};

export default DashboardNoAccessView;
