module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountClass, i, j, len, len1, ref, ref1, type;
    
      __out.push('<div class="m-l-20 m-r-20 m-t-20 relative">\n  <h4 class=\'font-24 font-bold text-center p-b-10\'>Add Chart of Account</h4>\n  <a class="pointer font-30 cancel btn-cancel"><span aria-hidden="true">&times;</span></a>\n</div>\n<div class=\'\'>\n  <div class="p-l-10 p-r-10">\n    <div class="inline-flex-wrapper width-100-percent">\n      <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px" for="">Category Type *</label>\n      <select class="inline-wrapper form-control account-type-js" placeholder="Category Type">\n        ');
    
      ref = this.accTypes;
      for (i = 0, len = ref.length; i < len; i++) {
        type = ref[i];
        __out.push('\n        <option value="');
        __out.push(__sanitize(type.code));
        __out.push('">');
        __out.push(__sanitize(type.name));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n      </div>\n      <div class="inline-flex-wrapper width-100-percent account-sub-type-select-wrapper m-t-10">\n        <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px" for="">Detail Type *</label>\n        <select class="inline-wrapper form-control sub-type-select"></select>\n      </div>\n      <p class="in-red-400 m-t-5 main-input__hint p-l-145 sub-type-invalid-js"><i class="fa fa-warning"></i> Detail Type is required.</p>\n      <div class="inline-flex-wrapper width-100-percent m-t-10">\n        <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px" for="">Category Name *</label>\n        <input type="text" class="inline-wrapper form-control account-name-js" placeholder="Category Name" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n      </div>\n      <p class="in-red-400 m-t-5 main-input__hint p-l-145 name-invalid-js"><i class="fa fa-warning"></i> Name is required.</p>\n      <div class="inline-flex-wrapper width-100-percent m-t-10">\n        <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px">Category Code</label>\n        <input class="inline-wrapper form-control account-code-js" placeholder="Category Code" value="');
    
      __out.push(__sanitize(this.number));
    
      __out.push('">\n      </div>\n      <div class="inline-flex-wrapper width-100-percent m-t-10">\n        <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px" for="">Is sub-account *</label>\n        <select class="inline-wrapper form-control account-has-parent-js">\n          <option>No</option>\n          <option>Yes</option>\n        </select>\n      </div>\n      <div class="parent-category-wrapper">\n        <div class="inline-flex-wrapper width-100-percent m-t-10 account-parent-category-select-wrapper">\n          <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px" for="">Parent Category *</label>\n          <select class="inline-wrapper form-control parent-category-select"></select>\n        </div>\n      </div>\n      <p class="in-red-400 m-t-5 main-input__hint p-l-145 parent-category-invalid-js"><i class="fa fa-warning"></i> Parent Category is required.</p>\n      ');
    
      if (this.accountingClasses.length > 0) {
        __out.push('\n      <div class="inline-flex-wrapper align-items-center width-100-percent account-class-section m-t-10 ');
        if (!this.isPLType()) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('">\n        <label class="inline-wrapper p-t-3 p-l-5 font-16 width-140px">Mapped Departments</label>\n        ');
        if (this.mappedClasses.length > 0) {
          __out.push('\n        <div class="display-flex">\n          <div class="class-list">\n            ');
          ref1 = this.mappedClasses;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            accountClass = ref1[j];
            __out.push('\n            <span>');
            __out.push(__sanitize(accountClass.name));
            __out.push('</span>\n            ');
          }
          __out.push('\n          </div>\n          <div id="add-class-action" class="width-50px pointer font-bold in-blue-900 text-right">Edit</div>\n        </div>\n        ');
        } else {
          __out.push('\n        <div id="add-class-action" class="add-class-action">Add Departments</div>\n        ');
        }
        __out.push('\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n</div>\n<div class=\'p-r-10 p-l-10 m-t-30 text-center\'>\n  <button type=\'button\' class="btn btn-info ladda-button add-account-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n</div>\n<div class=\'p-r-10 p-l-10 m-t-20 m-b-50 text-center\'>\n  <a class=\'cancel settings-cancel-btn\'>Cancel</a>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}