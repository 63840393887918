import React, { useCallback, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useConfirmEmailVerification, useCreateEmailVerificaiton } from '@src/hooks/queries/docyt_check_email_verifications';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IVerifyIdentityFormValues } from './verifiy_identity_form';

interface IVerifyIdentityModalProps extends IUseModalProps {
  email: string,
}

const VerifyIdentityModal = ({
  email,
  isOpen,
  onCancel,
  onDone,
}: IVerifyIdentityModalProps) => {
  const create = useCreateEmailVerificaiton();
  const { mutate: createEmailVerificaiton } = create;

  const verify = useConfirmEmailVerification();

  const { mutate } = verify;
  const handleSubmit = useCallback((values: IVerifyIdentityFormValues) => {
    mutate(
      {
        accessCode: values.accessCode,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [onDone, mutate]);

  useEffect(() => {
    if (isOpen) {
      createEmailVerificaiton();
    }
  }, [createEmailVerificaiton, isOpen]);

  return (
    <>
      <MutationStatus mutation={ verify } />
      <MutationStatus
        mutation={ create }
        successMessage="A verification link has been sent to your email."
        successTitle="Email was sent."
      />
      <Modal.Form
        proceedTitle="Generate Payment"
        show={ isOpen }
        title="Verify Identity"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            email={ email }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useVerifyIdentityModal = makeUseModal(VerifyIdentityModal);

export {
  IVerifyIdentityModalProps,
  useVerifyIdentityModal,
  VerifyIdentityModal as default,
};
