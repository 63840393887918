module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var account, accountClass, i, index, j, len, len1, ref, ref1;
    
      __out.push('<div class="header-container">\n    <h3 class="font-18 font-semi-bold in-black">');
    
      __out.push(__sanitize(this.headerTitle));
    
      __out.push('</h3>\n    <a id="close-category"><span class="pointer icon-not-a-chargeback-icon font-20 in-black"></span></a>\n</div>\n<div class="content-container ');
    
      __out.push(__sanitize(this.viewType === 'select' ? 'select-container' : ''));
    
      __out.push('">\n');
    
      if (this.viewType === 'edit_ledger_link') {
        __out.push('\n<div class="category-content-region">\n    <div class="form-group">\n        <label class="form-label">Link Status</label>\n        <div class="flex-wrapper">\n          <span class="font-20 m-r-5 icon ');
        __out.push(__sanitize(this.qbo_status === 'synced' ? 'icon-qbo-on' : this.qbo_status === 'invalid' ? 'icon-warning in-red-400' : 'icon-qbo-off'));
        __out.push('">\n            <span class=\'path1\'></span>\n          </span>\n          <span>');
        __out.push(__sanitize(this.qbo_status === 'synced' ? 'Connected' : 'Disconnected'));
        __out.push('</span>\n        </div>\n    </div>\n    <div class="form-group">\n        <label class="form-label">Account in Docyt</label>\n        <input class="form-control" value="');
        __out.push(__sanitize(this.name));
        __out.push('" disabled>\n    </div>\n    <div class="form-group relative">\n        <label class="form-label">Account in Ledger</label>\n        <input class="form-control input-ledger-account typeahead" placeholder="Select Account In Ledger" value="');
        __out.push(__sanitize(this.qbo_name));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142 ledger-account-invalid-js"><i class="fa fa-warning"></i> Account In Ledger is required.</p>\n</div>\n');
      } else if (this.viewType !== 'select') {
        __out.push('\n<div class="category-content-region">\n    <div class="form-group relative">\n        <label class="form-label">Account Type</label>\n        <input class="form-control input-account-type typeahead" placeholder="Select Account Type" value="');
        if (this.account_type) {
          __out.push(__sanitize(this.account_type.name));
        }
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142 account-type-invalid-js"><i class="fa fa-warning"></i> Account Type is required.</p>\n    <div class="form-group relative">\n        <label class="form-label">Detail Type</label>\n        <input class="form-control input-detail-type typeahead" placeholder="Select Detail Type" value="');
        __out.push(__sanitize(this.sub_type));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142 sub-type-invalid-js"><i class="fa fa-warning"></i> Detail Type is required.</p>\n    <div class="form-group">\n        <label class="form-label">Category Name</label>\n        <input class="form-control input-category-name" placeholder="E.g. Food Supplies"  value="');
        __out.push(__sanitize(this.name));
        __out.push('">\n    </div>\n    <div class="form-group">\n        <label class="form-label">Category Code</label>\n        <input class="form-control input-category-number" placeholder="E.g. 124040" value="');
        __out.push(__sanitize(this.number));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142 category-number-invalid-js"><i class="fa fa-warning"></i> Code is required.</p>\n    <label class="sub-category-label">Is this a sub-account of another category?</label>\n    <div class="sub-category-options-wrapper">\n        <div class="checkbox checkbox-primary sub-category-option">\n            <input class="pointer hide-sub-category-radio" type="radio" name="sub_category" value="no" ');
        __out.push(__sanitize(!this.showParentCategory ? 'checked' : ''));
        __out.push('>\n            <label>No</label>\n        </div>\n        <div class="checkbox checkbox-primary sub-category-option">\n            <input class="pointer show-sub-category-radio" type="radio" name="sub_category" value="yes"  ');
        __out.push(__sanitize(this.showParentCategory ? 'checked' : ''));
        __out.push('>\n            <label>Yes</label>\n        </div>\n    </div>\n    <div class="form-group relative sub-category-select-group ');
        __out.push(__sanitize(!this.parent_display_name ? 'hidden' : ''));
        __out.push('">\n        <label class="form-label">Parent Category</label>\n        <input class="form-control relative input-parent-category typeahead" placeholder="Select Parent Type" value="');
        __out.push(__sanitize(this.parent_display_name));
        __out.push('">\n    </div>\n    <p class="in-red-400 main-input__hint p-l-142 parent-category-invalid-js"><i class="fa fa-warning"></i> Parent Category is required.</p>\n    ');
        if (this.accountingClasses.length > 0) {
          __out.push('\n    <div class="form-group relative account-class-section ');
          if (!this.isPLType()) {
            __out.push(__sanitize('hidden'));
          }
          __out.push('">\n        <label>Mapped Departments</label>\n        ');
          if (this.mappedClasses.length > 0) {
            __out.push('\n        <div class="display-flex">\n          <div class="class-list">\n            ');
            ref = this.mappedClasses;
            for (i = 0, len = ref.length; i < len; i++) {
              accountClass = ref[i];
              __out.push('\n            <span>');
              __out.push(__sanitize(accountClass.name));
              __out.push('</span>\n            ');
            }
            __out.push('\n          </div>\n          <div id="add-class-action" class="width-50px pointer font-bold in-blue-900 text-right">Edit</div>\n        </div>\n        ');
          } else {
            __out.push('\n        <div id="add-class-action" class="add-class-action">Add Departments</div>\n        ');
          }
          __out.push('\n    </div>\n    ');
        }
        __out.push('\n</div>\n');
      } else {
        __out.push('\n<ul class="accounts-container">\n');
        ref1 = this.accounts;
        for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
          account = ref1[index];
          __out.push('\n<li class="account-item">\n    <div class="checkbox checkbox-primary">\n        <input class="pointer category-item-radio" type="radio" name="account-item" value="');
          __out.push(__sanitize(account.id));
          __out.push('" ');
          __out.push(__sanitize(index === 0 ? 'checked' : ''));
          __out.push('>\n        <label class="account-name-wrapper">\n        ');
          __out.push(this.accountName(account));
          __out.push('\n        </label>\n    </div>\n</li>\n');
        }
        __out.push('\n</ul>\n</div>\n');
      }
    
      __out.push('\n<div class="footer-action-container">\n    ');
    
      if (this.viewType === 'select') {
        __out.push('\n    <div class="form-group search-category-wrapper m-b-10">\n        <i class="icon icon-search"></i>\n        <input class="form-control font-14 search-category-input" placeholder="Filter by name or number">\n    </div>\n    ');
      }
    
      __out.push('\n    <button  class="btn btn-action  ');
    
      __out.push(__sanitize((this.viewType === 'add' || this.viewType === 'select' && this.accounts.length === 0) ? 'disabled' : ''));
    
      __out.push('">');
    
      __out.push(__sanitize(this.buttonTitle));
    
      __out.push('</button>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}