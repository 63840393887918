/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useRef } from 'react';

import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';

import { useCreatePopover } from '@src/hooks/popover';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { CaretIcon } from '@src/components/utils/fa_icons';

import { DropdownContext, useDropdown } from './hooks';
import Item from './item';
import ItemIcon from './item_icon';
import ItemLabel from './item_label';
import Menu from './menu';
import {
  focusFirstMenuItem,
  focusNextMenuItem,
  focusPreviousMenuItem,
} from './utils';

import styles from './styles.module.scss';

const SUBMENU_HIDE_DELAY = 150;

interface IDropdownSubmenuProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Item>, 'children' | 'title'>
{
  children: React.ReactNode,
  isFocusFirstMenuItem?: boolean,
  icon?: React.ReactNode,
  itemClassName?: string,
  isIntermediate?: boolean,
  title: React.ReactNode,
  autoHide?: boolean,
  showCheckbox?: boolean,
  checked?: boolean,
}

const DropdownSubmenu = ({
  children,
  isFocusFirstMenuItem = true,
  className,
  icon,
  itemClassName,
  isIntermediate,
  title,
  autoHide = true,
  showCheckbox = false,
  checked,
  ...props
}: IDropdownSubmenuProps) => {
  const dropdown = useDropdown();
  const itemRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const { hide: hideDropdown } = dropdown || {};

  const handleItemSelected = useCallback(() => {
    if (hideDropdown && autoHide) hideDropdown();
  }, [hideDropdown]);

  const handleShow = useCallback(() => {
    if (isFocusFirstMenuItem) focusFirstMenuItem(menuRef.current);
  }, [isFocusFirstMenuItem]);

  const popover = useCreatePopover({
    hideDelay:      SUBMENU_HIDE_DELAY,
    opts:           { placement: 'right-start' },
    onItemSelected: handleItemSelected,
    onShow:         handleShow,
  });

  const classes = classNames(
    styles['dropdown-menu-submenu'],
    itemClassName,
  );

  const { hide: hideSubmenu, show: showSubmenu, toggle: toggleSubmenu } = popover;
  const { disabled } = props;
  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        if (toggleSubmenu) toggleSubmenu();
        break;
      case 'Escape':
        e.preventDefault();
        e.stopPropagation();
        if (hideDropdown) hideDropdown();
        break;
      case 'ArrowLeft':
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        if (hideSubmenu) hideSubmenu();
        break;
      case 'ArrowRight':
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        if (showSubmenu) showSubmenu();
        focusFirstMenuItem(menuRef.current);
        break;
      case 'Tab':
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        hideSubmenu();
        focusNextMenuItem(itemRef.current);
        break;
      case 'ArrowUp':
        e.preventDefault();
        e.stopPropagation();
        hideSubmenu();
        focusPreviousMenuItem(itemRef.current);
        break;
      default:
        break;
    }
  }, [disabled, hideDropdown, hideSubmenu, showSubmenu, toggleSubmenu]);

  return (
    <DropdownContext.Provider value={ popover }>
      <Item
        ref={ mergeRefs([popover.referenceRef, itemRef]) }
        className={ classes }
        role="menu"
        { ...popover.showOnHoverAttributes }
        onKeyDown={ handleKeyDown }
        { ...props }
      >
        { icon && <ItemIcon>{ icon }</ItemIcon> }
        { showCheckbox && (
          <span className={ styles['dropdown-menu-item-checkbox'] }>
            <CheckboxInput checked={ checked } intermediate={ isIntermediate } />
          </span>
        ) }
        <ItemLabel title={ title } />
        <CaretIcon variant="right" />
        <Menu
          ref={ menuRef }
          className={ className }
          { ...popover.showOnHoverAttributes }
        >
          { children }
        </Menu>
      </Item>
    </DropdownContext.Provider>
  );
};

export default React.memo(DropdownSubmenu);
