import React, { useCallback } from 'react';

import { useUnmarkNoStatementAvailableBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_informations';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { apiDateToMonth } from '@src/utils/date_helpers';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import NotReconciledIcon from '@src/components/utils/icomoon/not_reconciled';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '../../styles.module.scss';

interface INotAvailableStatusItemProps {
  state: IStatementState,
  businessId: TID,
}

const NotAvailableStatusItem = ({
  state,
  businessId,
}: INotAvailableStatusItemProps): JSX.Element => {
  const notAvailableTooltip = (
    <div className="not-available-status">
      <div className={ styles['state-status'] }>
        <NotReconciledIcon fontSize={ 20 } />
        <span className={ styles['statement-state'] }>No Document Available</span>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ notAvailableTooltip } tooltipClassName={ styles['missing-state-item'] }>
      <NotReconciledIcon fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['status-item-button'] }>
      { content }
    </Dropdown.ToggleButton>
  );

  const unmarkNoStatementAvailable = useUnmarkNoStatementAvailableBalanceSheetStatement();
  const { mutate } = unmarkNoStatementAvailable;

  const handleConfirm = useCallback(() => {
    if (!state.id) return;

    mutate(
      { businessId, id: state.id },
    );
  }, [businessId, state.id, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ unmarkNoStatementAvailable } successMessage="Un-marked 'No Document Available' successfully" />
      <confirmModal.Component
        text={
          state.hasNextVerifiedStatements ? (
            <span>
              Bank feed has been verified beyond this document.
              { ' ' }
              If you Un-mark &quot;No Document&quot; available for
              { ' ' }
              { `${apiDateToMonth(state.date)}` }
              , all documents beyond this month will need reverification.
            </span>
          ) : (
            <span>
              Are you sure you want to proceed and unmark
              { ' ' }
              &quot;No Document &quot; available this document?
            </span>
          )
        }
        title={
          state.hasNextVerifiedStatements
            ? 'Critical Warning' : 'Un-mark "No Document Available"'
        }
        { ...confirmModal.props }
      />
      <ActionsDropdown toggleButton={ toggleButton }>
        <ActionsDropdown.Action
          title="Unmark 'No Document Available'"
          onClick={ confirmModal.open }
        />
      </ActionsDropdown>
    </>
  );
};

export default NotAvailableStatusItem;
