import { useEffect, useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useSorting } from '@src/hooks/url_params';
import { IGetAdjustmentEntriesResponse, useGetAdjustmentEntries } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry, TIAdjustmentEntrySortColumn } from '@src/types/adjustment_entries';
import { TID, TSection } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useClearFilter, useFilterData } from '@src/components/ui_v2/filter';

interface IUseCollectionParams {
  businessId: TID,
  reconciliationId?: TID,
  entryDate?: TDateFilter
}

interface ICollection {
  query: UseInfiniteQueryResult<IGetAdjustmentEntriesResponse, Error>,
  records: IAdjustmentEntry[],
  section: TSection,
  sorting: ISorting<TIAdjustmentEntrySortColumn>,
}

const defaultSorting: ISortingParams<TIAdjustmentEntrySortColumn> = {
  orderColumn:    'id',
  orderDirection: 'desc',
};

const useAdjustmentEntriesCollection = ({
  businessId,
  reconciliationId,
  entryDate,
}: IUseCollectionParams): ICollection => {
  const section = useMemo(() => {
    return { businessId, section: 'adjustment_entries' };
  }, [businessId]);

  const filterData = useFilterData(section, { reconciliationId, entry_date: entryDate });
  const filterParams = useMemo(() => {
    return { filter: filterData };
  }, [filterData]);

  const clearFilter = useClearFilter(section);
  useEffect(() => clearFilter(), [clearFilter]);

  const sorting = useSorting<TIAdjustmentEntrySortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetAdjustmentEntries({
    businessId,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  ICollection,
  useAdjustmentEntriesCollection,
};
