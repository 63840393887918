import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { PlusIcon, LockedaltIcon } from '@src/components/utils/icomoon';
import { FinancialInstitutionConnectionBlankIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IFinancialInstitutionConnectionBlankListProps {
  onConnectFinancialInstitution: () => void,
}

const FinancialInstitutionConnectionBlankList = ({
  onConnectFinancialInstitution,
}: IFinancialInstitutionConnectionBlankListProps): JSX.Element => {
  return (
    <div className={ styles['blank-details'] }>
      <FinancialInstitutionConnectionBlankIcon
        className={ styles['blank-icon'] }
      />
      <span className="width-80-percent text-center m-b-30">
        Get started by connecting your financial institutions to Docyt to streamline
        your accounting processes and reduce overall accounting costs.
      </span>
      <Button
        bsColor="blue"
        mb={ 10 }
        onClick={ onConnectFinancialInstitution }
      >
        <PlusIcon mr={ 10 } />
        Connect Financial Institution
      </Button>
      <div className="display-flex m-l-10 m-r-10">
        <LockedaltIcon className="m-r-10" />
        <span className={ styles['lock-text'] }>
          This is a secure, read-only connection to buisness financial institution.
          It allows Docyt to pull financial transactions for accounting & reconciliation.
        </span>
      </div>
    </div>
  );
};

export default FinancialInstitutionConnectionBlankList;
