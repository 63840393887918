import React, { forwardRef } from 'react';

import RadioCollectionInput from '../inputs/radio_collection_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

import styles from '../styles.module.scss';

interface IRadioCollectionFieldProps extends
  React.ComponentPropsWithoutRef<typeof RadioCollectionInput>,
  Omit<IFormFieldWrapperProps, 'children'>
{
  label: string,
}

const RadioCollectionField = forwardRef<HTMLInputElement, IRadioCollectionFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: IRadioCollectionFieldProps, ref): JSX.Element => {
  return (
    <Wrapper
      className={ styles['radio-collection-field'] }
      error={ error }
      hint={ hint }
      label={ label }
      mark={ mark }
    >
      <RadioCollectionInput
        ref={ ref }
        { ...props }
      />
    </Wrapper>
  );
});

RadioCollectionField.displayName = 'RadioCollectionField';

export default RadioCollectionField;
