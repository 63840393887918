import { useState, useEffect } from 'react';

import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

const useUserFeatureFlag = (featureFlag: string) => {
  const userId = String(window.Docyt.currentAdvisor.id);
  const [flagEnabled, setFlagEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForUser(featureFlag, userId),
  );

  useEffect(() => {
    if (flagEnabled === undefined) {
      LDFeatureForUserEnabled(featureFlag, userId).then((res) => {
        setFlagEnabled(res);
      });
    }
  }, [flagEnabled, featureFlag, userId]);

  return flagEnabled;
};

export default useUserFeatureFlag;
