import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateRevenueReportType } from '@src/hooks/queries/revenue_report_types';
import { ICreateRevenueReportTypeResponse } from '@src/requests/revenue_report_types';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAddReportTypeValues } from './schema';

interface IAddReportTypeModalProps extends IUseModalProps {}

const AddReportTypeModal = ({
  isOpen,
  onDone,
  ...props
}: IAddReportTypeModalProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();

  const mutation = useCreateRevenueReportType();
  const { mutate : createRevenueReportType } = mutation;

  const handleSubmit = useCallback((values: IAddReportTypeValues) => {
    // TODO: Refactor the API call triggers. The create revenue report type API should be called only after all report type details are collected.
    // Currently, it is triggered upon entering just the name, which is not the intended behavior.
    createRevenueReportType({
      revenueId: revenueService.id,
      name:      values.name,
      // please update if select box for frequency avail
      frequency: 'month_to_date',
    }, {
      onSuccess: (data : ICreateRevenueReportTypeResponse) => {
        Backbone.history.navigate(
          `/businesses/${business.id}/new_revenue_service/settings/report_types/${data.revenueReportType.id}`,
          { trigger: true },
        );
        onDone();
      },
    });
  }, [business.id, createRevenueReportType, onDone, revenueService.id]);

  return (
    <>
      <MutationStatus
        mutation={ mutation }
      />
      <Modal.Form
        cancelTitle="Go Back"
        proceedTitle="Continue"
        show={ isOpen }
        title="Add Revenue Mapping Name"
        { ...props }
      >
        { ({ formId }) => (
          <>
            <p>Please add your revenue mapping name</p>
            <Form
              formId={ formId }
              onSubmit={ handleSubmit }
            />
          </>
        )}
      </Modal.Form>
    </>
  );
};

const useAddReportTypeModal = makeUseModal(AddReportTypeModal);

export {
  useAddReportTypeModal,
  AddReportTypeModal as default,
};
