module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table id="loan-payment-table" class="table loan-payments-table-view bg-white bg-white" style="table-layout: fixed;">\n  <colgroup span="13">\n    <col width="30"></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n  </colgroup>\n  <thead>\n    <tr>\n      <th class="relative width-30px"></th>\n      <th class="pointer relative sortable sorted-desc" data-orderby="transaction_date">Date</th>\n      <th class="pointer relative sortable" data-orderby="payment_account">Account</th>\n      <th class="pointer relative sortable" data-orderby="description">Description</th>\n      <th class="pointer relative sortable" data-orderby="principal_category">Principal Category</th>\n      <th class="pointer relative sortable" data-orderby="interest_category">Interest Category</th>\n      <th class="pointer relative sortable" data-orderby="principal_amount">Liability</th>\n      <th class="pointer relative sortable" data-orderby="interest_amount">Interest</th>\n      <th class="pointer relative sortable" data-orderby="amount" style="width:60px;padding-left:0;padding-right:20px;text-align:right;">Total</th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}