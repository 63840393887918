import React from 'react';

import { useRecoilState } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';

import MultiColumnAddField from '@src/components/business_reports/common/multi_column_add_field';
import Filter from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import { reportFilterConfiguration } from '../hooks';

import styles from '../styles.module.scss';

interface IReportOtherDetailTableFilterProps {
  configurations: IReportConfiguration[],
  report: IReport,
  isShownCustomize: boolean,
  comparerIds?: string[]
}

const ReportOtherDetailTableFilter = ({
  configurations,
  report,
  isShownCustomize,
  comparerIds,
}: IReportOtherDetailTableFilterProps) => {
  const [value, setValue] = useRecoilState(hideZeroRowsType);
  const reportFilters = reportFilterConfiguration(configurations);

  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div className={ styles['filter-area'] }>
        <Filter.TextNormalFiled label="Search by Name" placeholder="Search by Name" />
        <Filter.DateField
          hideAllTime
          hideClear
          className={ styles['filter-width'] }
          name="reportDataRange"
          periodType="monthly"
        />

        {isShownCustomize && (
          <MultiColumnAddField
            comparerIds={ comparerIds }
            kind="reportOtherDetail"
            placeholder="Customize"
            report={ report }
            reportFilters={ reportFilters }
          />
        )}

        <CheckboxInput checked={ value } title="Hide empty rows" onChange={ (checked: boolean) => setValue(checked) } />
      </div>
    </Filter.TableContainer>
  );
};

export default React.memo(ReportOtherDetailTableFilter);
