import React, { useCallback } from 'react';

import { IBusiness } from '@src/types/businesses';

import Modal from '@src/components/ui/modal';

import Form, { ISelectStandardDocumentFormFormValues } from './select_standard_document_form';

interface ISelectStandardDocumentModalProps {
  business: IBusiness
  show?: boolean,
  toForwardDocument: boolean,
  onCancel: () => void,
  onDone: (documentId: number) => void,
}

const SelectStandardDocumentModal = ({
  business,
  show,
  toForwardDocument,
  onCancel,
  onDone,
}: ISelectStandardDocumentModalProps): JSX.Element => {
  const handleSubmit = useCallback((data: ISelectStandardDocumentFormFormValues) => {
    onDone(data.documentId);
  }, [onDone]);

  return (
    <Modal.Form
      cancelTitle={ toForwardDocument ? 'Back' : 'Cancel' }
      dialogClassName="standard-document-select-modal"
      proceedTitle={ toForwardDocument ? 'Forward' : 'Done' }
      show={ show }
      showClose={ !toForwardDocument }
      title={ toForwardDocument ? 'Select Document Type' : 'Add Document: Select Document Type' }
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form business={ business } formId={ formId } onSubmit={ handleSubmit } />
      )}
    </Modal.Form>
  );
};

export {
  ISelectStandardDocumentModalProps,
  SelectStandardDocumentModal as default,
};
