import React from 'react';

interface INoScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  wrapperClassName?: string,
}

const NoScrollTable = ({
  children,
  wrapperClassName,
  ...tableProps
}: INoScrollTableProps) => {
  return (
    <div className={ wrapperClassName }>
      <table { ...tableProps }>
        { children }
      </table>
    </div>
  );
};

export default React.memo(NoScrollTable);
