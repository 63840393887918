import { useMutation } from 'react-query';

import {
  achDocumentConfirmMicroDeposit,
  IAchDocumentConfirmMicroDepositParams
} from "@src/requests/accounts_payable_ach_document";

const useAchDocumentConfirmMicroDeposit = () => {
  return useMutation<void, Error, IAchDocumentConfirmMicroDepositParams>(achDocumentConfirmMicroDeposit);
};

export {
  useAchDocumentConfirmMicroDeposit
};