module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, standard_category;
    
      __out.push('<div class="');
    
      if (this.is_client) {
        __out.push(__sanitize('business-detail-settings-wrapper'));
      }
    
      __out.push(' ');
    
      if (this.for_onboarding_setup) {
        __out.push(__sanitize('client-setup-container'));
      }
    
      __out.push('">\n');
    
      if (!(this.is_client || this.for_onboarding_setup)) {
        __out.push('\n<div class="item-divider m-t-40">\n    <span class="font-18 m-r-10">Business Profiles</span>\n    <span class="font-18 m-r-10">/</span>\n    <span class="font-bold font-26">');
        __out.push(__sanitize(this.display_name ? this.display_name : this.name));
        __out.push('</span>\n</div>\n');
      }
    
      __out.push('\n\n<div class="business-detail-wrapper item-divider p-t-30">\n    <div class="row m-b-20 m-l-0 m-r-0 ');
    
      if (this.for_onboarding_setup) {
        __out.push(__sanitize('client-name-border-wrapper'));
      }
    
      __out.push('">\n        <div class="col-md-1 text-left business-avatar-wrapper border-gray p-l-0">\n            ');
    
      if (this.business_avatar) {
        __out.push('\n            <img src="');
        __out.push(__sanitize(this.business_avatar));
        __out.push('">\n            ');
      } else {
        __out.push('\n            <span class=\'icon-business-default-avatar font-64\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n            ');
      }
    
      __out.push('\n        </div>\n        <div class="col-md-9 text-left m-t-15 m-l-10">\n            <span class="font-20 font-bold">');
    
      __out.push(__sanitize(this.display_name ? this.display_name : this.name));
    
      __out.push('</span>\n        </div>\n        <div class="pull-right m-t-15">\n            <div id="edit-business-profile-region"></div>\n        </div>\n    </div>\n    <div class="font-14">\n        ');
    
      if (this.is_client) {
        __out.push('\n        <div class="detail-item">\n            <label>Display Name</label>\n            <span>');
        __out.push(__sanitize(this.display_name));
        __out.push('</span>\n        </div>\n        ');
      }
    
      __out.push('\n        <div class="detail-item">\n            <label>Business Name</label>\n            <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n        </div>\n        <div class="detail-item">\n            <label>Email</label>\n            <span>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</span>\n        </div>\n        <div class="detail-item">\n            <label>Phone Number</label>\n            <span>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n        </div>\n        <div class="detail-item">\n            <label>Address</label>\n            <span>');
    
      __out.push(__sanitize(this.address_info));
    
      __out.push('</span>\n        </div>\n    </div>\n</div>\n\n<div class="item-divider p-t-30 font-14">\n    <span class="font-bold font-16">Corporate Entity Info</span>\n    <a class="font-14 font-bold pointer pull-right edit-corporate-js">Edit</a>\n    <div class="detail-item m-t-10">\n        <label>Entity Type</label>\n        <span>');
    
      __out.push(__sanitize(this.entity_type));
    
      __out.push('</span>\n    </div>\n    <div class="detail-item">\n        <label>EIN Number</label>\n        <span>');
    
      __out.push(__sanitize(this.ein_number));
    
      __out.push('</span>\n    </div>\n    <div class="detail-item">\n        <label>Industry</label>\n        ');
    
      ref = this.standard_categories;
      for (i = 0, len = ref.length; i < len; i++) {
        standard_category = ref[i];
        __out.push('\n          ');
        if (standard_category.id === this.original_standard_category_id) {
          __out.push('\n            <span>');
          __out.push(__sanitize(this.standard_category.name));
          __out.push('</span>\n            ');
          break;
          __out.push('\n          ');
        }
        __out.push('\n          ');
        if (this.standard_category) {
          __out.push('\n            <span>');
          __out.push(__sanitize(this.standard_category.name));
          __out.push('</span>\n            ');
          break;
          __out.push('\n          ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n    </div>\n</div>\n\n<div class="item-divider p-t-30 font-14">\n    <span class="font-16 font-bold">Forwarding Emails</span>\n    <a class="font-14 font-bold pointer pull-right edit-email-js">Edit</a>\n    <div class="detail-item m-t-7">\n        <label>Business Mailroom</label>\n        <a class="in-blue-900 pointer" href="mailto: ');
    
      __out.push(__sanitize(this.service_info.inbox_service_email));
    
      __out.push('">');
    
      __out.push(__sanitize(this.service_info.inbox_service_email));
    
      __out.push('</a>\n    </div>\n    <div class="detail-item">\n        <label>Accounts Payable</label>\n        ');
    
      if (this.service_info.ap_service_ready && this.service_info.ap_service_email) {
        __out.push('\n        <a class="in-blue-900 pointer" href="mailto: ');
        __out.push(__sanitize(this.service_info.ap_service_email));
        __out.push('">');
        __out.push(__sanitize(this.service_info.ap_service_email));
        __out.push('</a>\n        ');
      } else {
        __out.push('\n        <span>');
        __out.push(__sanitize(!this.service_info.ap_service_ready ? 'Service Not Active' : ''));
        __out.push('</span>\n        ');
      }
    
      __out.push('\n    </div>\n    <div class="detail-item">\n        <label>Receipt Box</label>\n        ');
    
      if (this.service_info.rp_service_ready && this.service_info.rp_service_email) {
        __out.push('\n        <a class="in-blue-900 pointer" href="mailto: ');
        __out.push(__sanitize(this.service_info.rp_service_email));
        __out.push('">');
        __out.push(__sanitize(this.service_info.rp_service_email));
        __out.push('</a>\n        ');
      } else {
        __out.push('\n        <span>');
        __out.push(__sanitize(!this.service_info.rp_service_ready ? 'Service Not Active' : ''));
        __out.push('</span>\n        ');
      }
    
      __out.push('\n    </div>\n    <div class="detail-item">\n        <label>Revenue Center</label>\n        <a class="in-blue-900 pointer" href="mailto: ');
    
      __out.push(__sanitize(this.service_info.revenue_service_email));
    
      __out.push('">');
    
      __out.push(__sanitize(this.service_info.revenue_service_email));
    
      __out.push('</a>\n    </div>\n</div>\n\n');
    
      if (!this.for_onboarding_setup) {
        __out.push('\n<div class="item-divider p-t-30 font-14">\n    <span class="font-16 font-bold">Accounting Info</span>\n    <div class="detail-item display-flex align-items-center m-t-20">\n      <label>Location</label>\n      <div class="location-field-wrapper">\n        <div class="value-show-wrapper visible">\n          ');
        if (this.accounting_location) {
          __out.push('\n          ');
          __out.push(__sanitize(this.accounting_location));
          __out.push('\n          ');
        } else {
          __out.push('\n          <span class="in-grey-1400">Enter Location</span>\n          ');
        }
        __out.push('\n        </div>\n        <div class="value-edit-wrapper">\n          <input class="value-edit-input" type="text" value="');
        __out.push(__sanitize(this.accounting_location));
        __out.push('" />\n        </div>\n      </div>\n    </div>\n    <div class="display-flex">\n      <div class="inline-note">\n        <p>Departments</p>\n        <span class="in-black">Important:</span>\n        <span class="in-black">In Docyt QuickBooks Classes are labeled as Departments</span>\n      </div>\n      <div class="width-auto">\n        <a class="pointer font-bold class-btn-js">\n          ');
        __out.push(__sanitize(this.accounting_classes_count));
        __out.push(' Departments\n        </a>\n        <div class="checkbox checkbox-primary m-t-40 text-left">\n          <input id="checkclass" type="checkbox" class="styled form-control pointer warn-class-js" data-type="checkbox" ');
        __out.push(__sanitize(this.ask_class ? 'checked' : ''));
        __out.push('>\n          <label for="checkclass">Warn me when a transaction isn\'t assigned a department</label>\n        </div>\n      </div>\n      <div class="flex-grow-container">\n           <a class="pointer font-bold refresh-class-btn-js">Refresh</a>\n      </div>\n    </div>\n    <div class="detail-item">\n        <label>QuickBooks Connection Status</label>\n        ');
        if (this.qbo_connected) {
          __out.push('\n            <span class="in-green-1100 font-semi-bold">Connected</span>\n            <a class="pointer font-bold pull-right btn-qbo disconnect-qbo-js">Disconnect</a>\n        ');
        } else {
          __out.push('\n            <span class="in-red font-semi-bold">Not Connected</span>\n            <a class="pointer font-bold pull-right btn-qbo qbo-connect-button connect-qbo-js">Connect</a>\n        ');
        }
        __out.push('\n    </div>\n\n    <div class="detail-item">\n        <label>Bookkeeping Start Date</label>\n        <span>');
        if (this.bookkeeping_start_date) {
          __out.push(__sanitize(moment(this.bookkeeping_start_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        }
        __out.push('</span>\n    </div>\n    <div class="display-flex m-t-7">\n      <label class="width-30-percent m-t-10">Department mapping</label>\n      <div class="display-flex-column">\n          <div class="checkbox checkbox-primary checkbox-circle">\n            <input type="radio" id="any" name="radio-mapping-type" class="pointer radio-mapping-type" ');
        if (this.accounting_classes_count < 1) {
          __out.push(__sanitize('disabled'));
        }
        __out.push(' value="');
        __out.push(__sanitize(configData.business_class_map_types['ANY']));
        __out.push('" ');
        if (this.class_mapping_type === configData.business_class_map_types['ANY']) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n            <label for="any">A department can be assigned to any chart of account</label>\n          </div>\n          <div class="checkbox checkbox-primary checkbox-circle">\n            <input type="radio" id="specific" name="radio-mapping-type" class="pointer radio-mapping-type" ');
        if (this.accounting_classes_count < 1) {
          __out.push(__sanitize('disabled'));
        }
        __out.push(' value="');
        __out.push(__sanitize(configData.business_class_map_types['SPECIFIC']));
        __out.push('" ');
        if (this.class_mapping_type === configData.business_class_map_types['SPECIFIC']) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n            <label for="specific">Map departments to specific chart of accounts</label>\n          </div>\n      </div>\n      <a href="/businesses/');
        __out.push(__sanitize(this.id));
        __out.push('/class_mappings" class="font-14 font-bold m-l-30 m-t-auto m-b-10 edit-mapping-js ');
        if (this.class_mapping_type !== configData.business_class_map_types['SPECIFIC']) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('">Edit Mapping </a>\n    </div>\n</div>\n');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}