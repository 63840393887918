import React, { useCallback } from 'react';

import { useDestroyBatchDocument } from '@src/hooks/queries/documents';
import { TID } from '@src/types/common';

import { useConfirmDeleteDocumentsPermanentlyModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { NewTrashIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDeletePermanentlyActionProps {
  documentIds: TID[],
}

const DeletePermanentlyAction = ({
  documentIds,
}: IDeletePermanentlyActionProps) => {
  const deleteDocuments = useDestroyBatchDocument();

  const { mutate } = deleteDocuments;
  const handleConfirm = useCallback(() => {
    mutate({
      documentIds,
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('expense_reports_settings:trash:items:removed', documentIds.length);
      },
      onError: (error: any) => {
        let errorMessage;
        if (error.response?.data?.errors) {
          errorMessage = error.response.data.errors.join(', ');
        }
        error.message = errorMessage;
      },
    });
  }, [documentIds, mutate]);

  const confirm = useConfirmDeleteDocumentsPermanentlyModal({
    documentIds,
    itemName: 'Expense',
    onDone:   handleConfirm,
  });

  const handleSelect = useCallback(() => {
    if (!documentIds.length) return;

    confirm.open();
  }, [documentIds, confirm]);

  return (
    <>
      <MutationStatus mutation={ deleteDocuments } />
      <confirm.Component { ...confirm.props } />
      <ActionsDropdown.Action
        danger
        icon={ <NewTrashIcon fontSize={ 20 } /> }
        title="Delete Permanently"
        onSelect={ handleSelect }
      />
    </>
  );
};

export default React.memo(DeletePermanentlyAction);
