import React from 'react';

import styles from './styles.module.scss';

import classNames from 'classnames';

interface IFullPageModalBodyProps {
  children: React.ReactNode,
  hasSidemenu?: boolean;
}

const FullPageModalBody = ({
  children,
  hasSidemenu,
}: IFullPageModalBodyProps): JSX.Element => {
  
  const classes = classNames(styles['full-page-modal-body'], {
    [styles['with-side-menu']]: hasSidemenu,
  });

  return (
    <div className={ classes }>
      { children }
    </div>
  );
};

export default FullPageModalBody;
