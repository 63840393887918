import React, { FC, useMemo, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAttachments } from '@src/hooks/queries/joural_entries';
import { useGetAdjustmentEntryByParams } from '@src/requests/adjustment_entries';

import { useUploadJournalEntryModal } from '@src/components/common/upload_journal_entry';

import Attachment from './attachment';

import styles from './styles.module.scss';

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const qboId = params.get('qbo_id') || undefined;
  const docytId = params.get('docyt_id') || undefined;
  return { qboId, docytId };
};

const Footer: FC = () => {
  const business = useBusinessContext();
  const upload = useUploadJournalEntryModal();
  const params = getQueryParams();
  const [selectedChild, setSelectedChild] = useState('');

  const adjustmentEntryQuery = useGetAdjustmentEntryByParams(
    params,
    business.id,
  );
  const adjustmentEntry = useMemo(() => {
    return adjustmentEntryQuery.data?.adjustmentEntry || { id: 0, documentId: 0 };
  }, [adjustmentEntryQuery.data?.adjustmentEntry]);

  const attachmentsQuery = useGetAttachments(adjustmentEntry.id);
  const attachments = useMemo(() => {
    return attachmentsQuery.data?.attachments || [];
  }, [attachmentsQuery.data?.attachments]);

  const handleItemClick = (childId: string) => {
    setSelectedChild(childId);
  };

  return (
    <>
      <upload.Component { ...upload.props } business={ business } />
      <div className={ styles.footer }>
        <span
          aria-label="add document"
          className="icon-plus"
          role="button"
          tabIndex={ -1 }
          onClick={ upload.open }
        />
        <span className={ styles['footer-divide'] } />
        <span
          className={
            selectedChild === '' ? styles['footer-item-focus'] : undefined
          }
          role="button"
          tabIndex={ -1 }
          onClick={ () => { handleItemClick(''); } }
        >
          {`Journal Entry ${params.docytId}`}
        </span>
        <span className={ styles['footer-divide'] } />
        {attachments.length > 0
          ? attachments.map(
            (attachment: { id: React.Key | null | undefined }) => (
              <Attachment
                key={ attachment.id }
                data={ attachment }
                documentId={ adjustmentEntry.documentId }
                selectedChild={ selectedChild }
                onChildClick={ handleItemClick }
              />
            ),
          )
          : null}
      </div>
    </>
  );
};
export default Footer;
