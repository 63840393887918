import React, { useEffect } from 'react';

import { Control, FieldErrors, useController, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { TDate, TID } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { getUTCTimezone } from '@src/utils/date_helpers';

import PaymentAccountSelectField from '@src/components/common/form_fields/banking_account_select_field/banking_account_select_field';
import TransactionAmountTypeInput from '@src/components/common_v2/inputs/transaction_amount_type_input';
import { Button } from '@src/components/ui/buttons';
import Form from '@src/components/ui/form';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import { IAddTransactionInput, IFormValues } from './schema';

import styles from './styles.module.scss';

interface IAddTransactionsFormTransactionFieldsProps {
  control: Control<IFormValues, object>,
  setValue: UseFormSetValue<IFormValues>,
  watch: UseFormWatch<IFormValues>,
  businessId: TID,
  index: number,
  errors: FieldErrors<IFormValues>,
  startDate?: TDate,
  endDate?: TDate,
  reconciliationPaymentAccount?: IReconciliationPaymentAccount,
  oldTransactions?: IAddTransactionInput[],
  remove: () => void,
  onChange: () => void,
}

const AddTransactionsFormTransactionFields = ({
  control,
  setValue,
  watch,
  index,
  businessId,
  errors,
  startDate,
  endDate,
  reconciliationPaymentAccount,
  oldTransactions,
  remove,
  onChange,
}: IAddTransactionsFormTransactionFieldsProps): JSX.Element => {
  const transactionDate = oldTransactions && oldTransactions[index]
    ? oldTransactions[index].transactionDate : undefined;

  const dateController = useController({ name: `newTransactions.${index}.transactionDate`, control });
  const accountController = useController({ name: `newTransactions.${index}.selectedAccount`, control });
  const descriptionController = useController({ name: `newTransactions.${index}.description`, control });
  const amountTypeController = useController({ name: `newTransactions.${index}.amountType`, control });
  const amountController = useController({ name: `newTransactions.${index}.amount`, control });

  const date = watch(`newTransactions.${index}.transactionDate`);
  const account = watch(`newTransactions.${index}.selectedAccount`);
  const description = watch(`newTransactions.${index}.description`);
  const amountType = watch(`newTransactions.${index}.amountType`);
  const amount = watch(`newTransactions.${index}.amount`);

  useEffect(() => {
    onChange();
  }, [date, account, description, amountType, amount, onChange]);

  useEffect(() => {
    switch (accountController.field.value.accountType) {
      case 'bank':
        setValue(`newTransactions.${index}.amountType`, 'deposit');
        break;
      case 'credit_card':
        setValue(`newTransactions.${index}.amountType`, 'charge');
        break;
      default:
        break;
    }
  }, [accountController.field.value.accountType, index, setValue]);

  useEffect(() => {
    if (reconciliationPaymentAccount === undefined) return;

    setValue(`newTransactions.${index}.selectedAccount.reconciliationPaymentAccountId`, reconciliationPaymentAccount.id);
    setValue(`newTransactions.${index}.selectedAccount.accountType`, reconciliationPaymentAccount.accountType);
  }, [index, reconciliationPaymentAccount, setValue]);

  useEffect(() => {
    setValue(`newTransactions.${index}.index`, index);
  }, [index, setValue]);

  useEffect(() => {
    const transactionAmountType = oldTransactions && oldTransactions[index]
      ? oldTransactions[index].amountType : undefined;

    if (transactionAmountType) {
      setValue(`newTransactions.${index}.amountType`, transactionAmountType);
    }
  }, [index, oldTransactions, setValue]);

  return (
    <div className={ styles['modal-form-details'] }>
      <Form.DateField
        className="add-transaction-input add-transaction-date"
        error={ errors.newTransactions && errors.newTransactions[index]?.transactionDate?.message }
        options={ {
          startDate:      startDate ? getUTCTimezone(new Date(startDate)) : undefined,
          endDate:        endDate ? getUTCTimezone(new Date(endDate)) : undefined,
          todayHighlight: true,
        } }
        placeholder="Starting Date"
        { ...dateController.field }
        value={ transactionDate || startDate }
      />
      <PaymentAccountSelectField
        businessId={ businessId }
        className="add-transaction-input add-transaction-account"
        error={ errors.newTransactions
          && errors.newTransactions[index]?.selectedAccount?.paymentAccountId?.message }
        selectedAccountData={ accountController.field.value }
        onAccountSelected={ accountController.field.onChange }
      />
      <Form.TextField
        className="add-transaction-input add-transaction-memo"
        error={ errors.newTransactions && errors.newTransactions[index]?.description?.message }
        placeholder="Description"
        { ...descriptionController.field }
      />
      <TransactionAmountTypeInput
        accountType={ accountController.field.value.accountType }
        className={ styles['transaction-type-field'] }
        value={ amountTypeController.field.value }
        onChange={ amountTypeController.field.onChange }
      />
      <Form.AmountField
        className="range-filter add-transaction-input add-transaction-amount"
        error={ errors.newTransactions && errors.newTransactions[index]?.amount?.message }
        placeholder="Amount"
        { ...amountController.field }
      />
      <Button
        className="remove-transaction-btn width-5-percent"
        onClick={ remove }
      >
        <TrashcanIcon fontSize={ 18 } />
      </Button>
    </div>
  );
};

export {
  IAddTransactionsFormTransactionFieldsProps,
  AddTransactionsFormTransactionFields as default,
};
