import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { useUploadDocumentModal } from '@src/components/common/upload_document';
import { Button } from '@src/components/ui/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

const BusinessInboxAddDocumentAction = () => {
  const business = useBusinessContext();
  const upload = useUploadDocumentModal();

  return (
    <>
      <upload.Component
        { ...upload.props }
        business={ business }
      />
      <Button
        className="add-inbox-document-btn"
        fontSize={ 20 }
        ml={ 5 }
        onClick={ upload.open }
      >
        <PlusIcon />
      </Button>
    </>
  );
};

export default BusinessInboxAddDocumentAction;
