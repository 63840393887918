import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { formatDate } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';
import {
  StatementApprovedIcon,
} from '@src/components/utils/icomoon';
import NotReconciledIcon from '@src/components/utils/icomoon/not_reconciled';
import ReconciledIcon from '@src/components/utils/icomoon/reconciled';
import WaitingForClientIcon from '@src/components/utils/icomoon/waiting-for-client';

import styles from '../styles.module.scss';

interface IBankStatementStateItemProps {
  state: IReviewedBankStatementState,
}

const BankStatementStateItem = ({
  state,
}: IBankStatementStateItemProps): JSX.Element => {
  let content;
  if (state.state === 'approved') {
    content = (
      <Tooltip
        as={ StatementApprovedIcon }
        fontSize={ 20 }
        title={
          '<div class="approved-status">'
          + '<div class="state-status">'
          + '<div class="missing state-info">'
          + '<hr class="progress-line green-line">'
          + "<span class='icon-not-reconciled font-20 m-b-10'></span>"
          + `<span class='in-grey-800'>${formatDate(state.createdAt)}</span>`
          + '<span>Statement missing</span>'
          + '</div>'
          + '<div class="approved state-info">'
          + '<hr class="progress-line approved-verified-progress disabled-line">'
          + '<span class="icon-approved-statement font-20 m-b-10"><span class="path1"></span><span class="path2"></span></span>'
          + `<span class='in-grey-800'>${state.approvedAt ? formatDate(state.approvedAt) : '--'}</span>`
          + '<span>Statement approved</span>'
          + '</div>'
          + '<div class="verified state-info">'
          + '<span class="icon-reconciled font-20 m-b-10"><span class="path1"></span><span class="path2"></span></span>'
          + "<span class='in-grey-800'>--</span>"
          + "<span class='in-grey-800'>Bank feed verified</span>"
          + '</div>'
          + '</div>'
          + '<div class="state-details">'
          + '<span>Status: <span class="sub-details">Bank Statement Approved but not verified</span></span><br/>'
          + '<span>Verify the bank feed in next step to reconcile the bank statement with Docyt transactions.</span>'
          + '</div>'
          + '</div>'
        }
      />
    );
  } else if (state.state === 'verified') {
    content = (
      <Tooltip
        as={ ReconciledIcon }
        fontSize={ 20 }
        title={
          '<div class="approved-status">'
          + '<div class="state-status">'
          + '<div class="missing state-info">'
          + '<hr class="progress-line green-line">'
          + "<span class='icon-not-reconciled font-20 m-b-10'></span>"
          + `<span class='in-grey-800'>${formatDate(state.createdAt)}</span>`
          + '<span>Statement missing</span>'
          + '</div>'
          + '<div class="approved state-info">'
          + '<hr class="progress-line approved-verified-progress green-line">'
          + '<span class="icon-approved-statement font-20 m-b-10"><span class="path1"></span><span class="path2"></span></span>'
          + `<span class='in-grey-800'>${state.approvedAt ? formatDate(state.approvedAt) : '--'}</span>`
          + '<span>Statement approved</span>'
          + '</div>'
          + '<div class="verified state-info">'
          + '<span class="icon-Group-1 font-20 m-b-10"><span class="path1"></span><span class="path2"></span></span>'
          + `<span class='in-grey-800'>${state.verifiedAt ? formatDate(state.verifiedAt) : '--'}</span>`
          + '<span>Bank feed verified</span>'
          + '</div>'
          + '</div>'
          + '<div class="state-details">'
          + '<span>Status: <span class="sub-details">Bank feed has been verified</span></span><br/>'
          + '<span>Bank feed in Docyt has been cross checked against the Bank statement to ensure there are no missing or duplicate transactions.</span>'
          + '</div>'
          + '</div>'
        }
      />
    );
  } else if (state.state === 'not_available') {
    content = (
      <Tooltip
        as={ WaitingForClientIcon }
        fontSize={ 20 }
        title="No Statement Available"
      />
    );
  } else {
    content = (
      <Tooltip
        as={ NotReconciledIcon }
        fontSize={ 20 }
        title="Statement Missing"
      />
    );
  }

  return (
    <Table.Cell
      className={ (state.state === 'approved' || state.state === 'verified') ? styles['state-item'] : undefined }
    >
      { content }
    </Table.Cell>
  );
};

export default BankStatementStateItem;
