module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, payment_account, ref;
    
      if (!this.is_team) {
        __out.push('\n<div class="business-divider m-t-40 p-b-15">\n  <div class="team-large-avatar-wrapper">\n    ');
        if (this.business.image_url) {
          __out.push('\n      <img src="');
          __out.push(__sanitize(this.business.image_url));
          __out.push('">\n    ');
        } else {
          __out.push('\n    <span class=\'icon-business-default-avatar font-78\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n    ');
        }
        __out.push('\n  </div>\n  <label class="font-semi-bold font-24 m-l-30 vertical-align-middle workspace-content"> ');
        __out.push(__sanitize(this.business.legal_name));
        __out.push(' </label>\n</div>\n');
      }
    
      __out.push('\n<div class="m-r-0">\n  ');
    
      if (this.is_team) {
        __out.push('\n  <div class="font-18 font-semi-bold m-t-40 m-b-20">Payment Methods</div>\n  ');
      }
    
      __out.push('\n  <div class="font-14 font-semi-bold m-t-20 m-b-20">\n    Enabling access to a payment method lets your team member use this payment method in services like Accounts Payable, Receipt Box, etc. They will then be able to mark invoices as paid, view banking transactions, view receipts and also view the payment method document in the data vault.\n  </div>\n  ');
    
      if (!this.is_team) {
        __out.push('\n  <div class="font-18 font-semi-bold m-t-20 m-b-20">Payment Methods</div>\n  ');
      }
    
      __out.push('\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16">All Payment Methods</label>\n    <label class="col-md-4 font-16 in-grey-800"></label>\n    <div class = "col-md-3 height-100-percent">\n      <select class="form-control permission-form-control select-all-option-js ');
    
      if (this.is_team && !this.all_option_state) {
        __out.push(__sanitize('disabled-control'));
      }
    
      __out.push('">\n        <option value="ENABLED" ');
    
      if (this.all_option_state) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Enabled</option>\n        <option value="DISABLED" ');
    
      if (!this.all_option_state) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Disabled</option>\n      </select>\n    </div>\n  </div>\n  ');
    
      ref = this.payment_accounts;
      for (i = 0, len = ref.length; i < len; i++) {
        payment_account = ref[i];
        __out.push('\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16">');
        __out.push(__sanitize(payment_account.name));
        __out.push('</label>\n    <label class="col-md-4 font-16 in-grey-800">');
        __out.push(__sanitize(payment_account.last4));
        __out.push('</label>\n    <div class = "col-md-3 height-100-percent">\n      <select class="form-control permission-form-control select-account-option-js ');
        if (payment_account.disable_edit || (this.is_team && !payment_account.state)) {
          __out.push(__sanitize('disabled-control'));
        }
        __out.push('" id="');
        __out.push(__sanitize(payment_account.id ? payment_account.id : 'null'));
        __out.push('" ');
        if (payment_account.disable_edit) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <option value="ENABLED" ');
        if (payment_account.state) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Enabled</option>\n        <option value="DISABLED" ');
        if (!payment_account.state) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Disabled</option>\n      </select>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}