import React, { useCallback, useMemo } from 'react';

import { useAccountBigTypes } from '@src/hooks/queries/account_types';
import { IAccountBigType } from '@src/types/account_types';
import { TID } from '@src/types/common';

import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';
import ReactSelectInput, { IReactSelectInputProps } from '@src/components/ui/form/inputs/react_select/react_select_input';

interface IAccountBigTypeFieldProps extends
  Omit<
    IReactSelectInputProps<IAccountBigType, false>,
    'isMulti' | 'options' | 'value' | 'onChange'
  >,
  Omit<IFormFieldWrapperProps, 'children'>
{
  businessId: TID;
  value?: IAccountBigType['code'],
  onChange: (value?: IAccountBigType['code']) => void,
}

const getOptionLabel = (type: IAccountBigType) => type.name;
const getOptionValue = (type: IAccountBigType) => type.code;

const AccountBigTypeField = ({
  businessId,
  error,
  hideLabel,
  hint,
  label,
  mark,
  value,
  onChange,
  ...selectProps
}: IAccountBigTypeFieldProps): JSX.Element => {
  const query = useAccountBigTypes({ businessId });
  const accountTypes = useMemo(() => {
    return query.data?.accountTypes ?? [];
  }, [query.data]);

  const selectedAccountType = useMemo(() => {
    if (!value) return null;

    return accountTypes.find((at) => at.code === value);
  }, [value, accountTypes]);

  const handleChange = useCallback((accountType: IAccountBigType | null) => {
    onChange(accountType?.code);
  }, [onChange]);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <ReactSelectInput<IAccountBigType, false>
        isSearchable
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        options={ accountTypes }
        widthPercent={ 100 }
        { ...selectProps }
        value={ selectedAccountType }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

AccountBigTypeField.displayName = 'AccountBigTypeField';

export default AccountBigTypeField;
