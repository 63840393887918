import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const MarkReviewedIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="mark-reviewed"
    />
  );
};

export default MarkReviewedIcon;
