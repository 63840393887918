import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUnverifyBankStatement } from '@src/hooks/queries/bank_statements';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface IUnverifyBankStatementProps extends IUseModalProps {
  businessId: TID,
  transaction: ITransactionServiceDocument,
}

const UnverifyBankStatement = ({
  businessId,
  transaction,
  isOpen,
  onCancel,
}: IUnverifyBankStatementProps): JSX.Element => {
  const unverify = useUnverifyBankStatement();
  const { mutate } = unverify;

  const handleUnverify = useCallback(() => {
    mutate(
      {
        businessId,
        reconciliationPaymentAccountId: transaction.reconciliationPaymentAccount.id,
        date:                           transaction.transactionDate,
      },
      {
        onSuccess: onCancel,
      },
    );
  }, [
    mutate,
    onCancel,
    businessId,
    transaction.reconciliationPaymentAccount.id,
    transaction.transactionDate,
  ]);

  return (
    <>
      <MutationStatus mutation={ unverify } successMessage="Unverified bank statement successfully" />
      <Modal
        dialogClassName="unverify_bank_statement_modal"
        region="second-modal-region"
        show={ isOpen }
        title="Warning!"
      >
        <Modal.Body>
          <span>
            The statement for this month is verified.
            {' '}
            You need to unverify the statement to restore transactions.
            {' '}
            <a
              role="button"
              tabIndex={ -1 }
              onClick={ handleUnverify }
            >
              Click here
            </a>
            {' '}
            to unverify.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsColor="blue"
            title="Cancel"
            onClick={ onCancel }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useUnverifyBankStatementModal = makeUseModal(UnverifyBankStatement);

export {
  IUnverifyBankStatementProps,
  UnverifyBankStatement,
  useUnverifyBankStatementModal as default,
};
