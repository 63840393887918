import React, { useMemo } from 'react';

import { TID } from '@src/types/common';
import { IStandardDocument, IStandardDocumentField } from '@src/types/standard_documents';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';

import DocumentsTable from './documents_details';

interface IBankingAccountsTransactionsPageProps {
  businessId?: TID,
  standardDocument: object,
}

const DocumentsPage = ({
  businessId,
  standardDocument,
}: IBankingAccountsTransactionsPageProps): JSX.Element => {
  const StandardDocument = useMemo(() => {
    return camelizeKeys(standardDocument) as IStandardDocument;
  }, [standardDocument]);

  const unEncryptedStandardDocumentFields = useMemo(() => {
    if (StandardDocument === undefined) return [];

    const fields: IStandardDocumentField[] = [];
    const standardDocumentFields = StandardDocument.standardDocumentFields;
    standardDocumentFields.forEach((standardDocumentField) => {
      if (fields.length === 2) return;

      if (!standardDocumentField.encryption) {
        fields.push(standardDocumentField);
      }
    });

    return fields;
  }, [StandardDocument]);

  return (
    <Root>
      <DocumentsTable
        businessId={ businessId }
        standardDocument={ StandardDocument }
        unEncryptedStandardDocumentFields={ unEncryptedStandardDocumentFields }
      />
    </Root>
  );
};

export default DocumentsPage;
