import React from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import { useDeleteReportModal } from '../modals/delete_report';

import styles from '../styles.module.scss';

const DeleteReportAction = ({ item }: { item: IMultiBusinessReportDetail }) => {
  const modal = useDeleteReportModal();

  return (
    <>
      <ActionsDropdown.Action
        danger
        className={ styles['dropdown-item'] }
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        title="Delete Report"
        onSelect={ modal.open }
      />
      <modal.Component
        { ...modal.props }
        item={ item }
      />
    </>
  );
};

export default React.memo(DeleteReportAction);
