import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';
import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import ConfirmDialog, { useConfirmAddModal, IConfirmAddProps } from './confirm_add';

interface IUseConfirmAddVendorsModalParams extends IUseModalParams {
  vendors: IGlobalVendorWithBusinessInfo[],
}

interface IUseConfirmAddVendorsModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmAddProps,
}

const ConfirmAddVendorsTitle = ({ vendors }: { vendors: IGlobalVendorWithBusinessInfo[] }) => (
  <>
    The following
    { (vendors.length > 1) && ` ${vendors.length} vendors ` }
    { (vendors.length === 1) && ' 1 vendor ' }
    will be added to My Vendors list.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmAddVendorsModal = ({
  vendors,
  ...modalParams
}: IUseConfirmAddVendorsModalParams): IUseConfirmAddVendorsModalReturn => {
  const modal = useConfirmAddModal(modalParams);

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmTitle: 'Add',
      text:         <ConfirmAddVendorsTitle vendors={ vendors } />,
      vendors,
      title:        'Add Vendors to My Vendors list',
    },
  };
};

export {
  useConfirmAddVendorsModal,
};
