import React, { useCallback, useMemo } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetBusinessVendor } from '@src/hooks/queries/vendors';
import { searchBusinessVendors } from '@src/requests/business_vendors';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import { VendorAvatar } from '@src/components/ui/avatars';
import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import {
  AsyncPaginateIconSelectInput,
  formatIconSelectOption,
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';

interface IVendorSelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  name: keyof TFilter,
  businessId: TID,
}

const PER_PAGE = 50;

const formatTooltip = (option: TIconOption | undefined): JSX.Element | null => {
  if (!option) return null;

  return formatIconSelectOption({ ...option, tooltip: true }, { context: 'value' });
};

const VendorFilterField = <TFilter extends TFilterData>({
  name,
  businessId,
  placeholder = 'Payees…',
  ...props
}: IVendorSelectFilterFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string | null>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    update(item?.value);
  }, [update]);

  const vendorQuery = useGetBusinessVendor({ businessId, vendorId: Number(value) });

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
    useCallback((query, options, { page }) => {
      return searchBusinessVendors({ businessId, search: query, page, perPage: PER_PAGE })
        .then((data) => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          const newOptions = data.collection.map((vendor) => ({
            label:  vendor.name,
            value:  String(vendor.vendorId),
            icon:   <VendorAvatar size="100%" vendor={ vendor } />,
            helper: vendor.individualUserId ? 'Employee' : 'Vendor',
          }));

          return {
            hasMore,
            options:    newOptions,
            additional: {
              page: page + 1,
            },
          };
        });
    }, [businessId]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!vendorQuery.data) return undefined;
    if (String(vendorQuery.data.id) !== String(value)) return undefined;

    return {
      icon:   <VendorAvatar size="100%" vendor={ vendorQuery.data } />,
      label:  vendorQuery.data.name,
      helper: vendorQuery.data.qboEntityType,
      value:  String(vendorQuery.data.id),
    };
  }, [value, vendorQuery.data]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(selectedItem) }
      tooltipLabel={ selectedItem?.helper && `${selectedItem.helper}: ` }
    >
      <AsyncPaginateIconSelectInput
        { ...inputProps }
        defaultOptions
        additional={ {
          page: 1,
        } }
        debounceTimeout={ 300 }
        isLoading={ vendorQuery.isLoading }
        loadOptions={ handleSource }
        placeholder={ placeholder }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(VendorFilterField);
