import React from 'react';

import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';
import { MONTHS } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

interface IBudget {
  budget: IReportServiceBudget,
  formulaEnabled: string | boolean | undefined,
}

const BudgetDetailsTableHead = ({
  budget,
  formulaEnabled,
}: IBudget) => (
  <Table.Head>
    <Table.Row>
      <Table.HCell aria-label="default" widthPercent={ 13 }>{ `${budget.type} Line Items` }</Table.HCell>
      <Table.HCell aria-label="default" widthPercent={ 8 }>Type</Table.HCell>
      <Table.HCell aria-label="default" className="table-divider" widthPercent={ 8 }>Department</Table.HCell>
      {
        formulaEnabled
        && <Table.HCell aria-label="default" className="table-divider" widthPercent={ 8 }>Autofill By</Table.HCell>
      }
      {
        formulaEnabled
        && <Table.HCell aria-label="default" className="table-divider" widthPercent={ 8 }>Value</Table.HCell>
      }
      <Table.HCell aria-label="default" textAlign="center" widthPercent={ 8 }>{ `${budget.type} Total` }</Table.HCell>
      {
        MONTHS.map((month) => (
          <Table.HCell key={ month } aria-label="default" textAlign="center" widthPercent={ 5 }>{month}</Table.HCell>
        ))
      }
    </Table.Row>
  </Table.Head>
);

export default BudgetDetailsTableHead;
