import React from 'react';

import { TIconOption } from '@src/components/ui/form';
import { DummyIcon } from '@src/components/utils/fa_icons';
import {
  QBOBlockIcon,
  QBOErrorIcon,
  QBOOffIcon,
  QBOOnIcon,
} from '@src/components/utils/icomoon';

const QBOSyncOptions: TIconOption[] = [
  {
    value: undefined,
    label: 'All',
    icon:  <DummyIcon fontSize={ 13 } />,
  },
  {
    value: window.Docyt.Common.Constants.QBO_SYNC_STATUS.SYNCED,
    label: 'Pushed to QuickBooks',
    icon:  <QBOOnIcon fontSize={ 18 } />,
  },
  {
    value: window.Docyt.Common.Constants.QBO_SYNC_STATUS.DISABLED,
    label: 'Push to QuickBooks Disabled',
    icon:  <QBOBlockIcon fontSize={ 18 } />,
  },
  {
    value: window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED,
    label: 'Push to QuickBooks Pending',
    icon:  <QBOOffIcon fontSize={ 18 } />,
  },
  {
    value: window.Docyt.Common.Constants.QBO_SYNC_STATUS.FAILED,
    label: 'Error',
    icon:  <QBOErrorIcon fontSize={ 18 } />,
  },
];

export {
  QBOSyncOptions,
};
