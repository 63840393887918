import React, { useCallback, useMemo } from 'react';

import { useGetPaymentAccounts } from '@src/hooks/queries/payment_accounts';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import { SelectInput, formatSelectOption, TOption } from '@src/components/ui_v2/inputs';

interface IPaymentAccountFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof SelectInput>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
   paymentAccountName?: string,
    businessId: TID
}

const formatTooltip = (option: TOption | null): JSX.Element | null => {
  if (!option) return null;
  return formatSelectOption(option);
};

const PaymentAccountFilterField = <TFilter extends TFilterData>({
  paymentAccountName = 'payment_account_last4',
  businessId,
  ...props
}: IPaymentAccountFilterFieldProps): JSX.Element => {
  const paymentAccountQuery = useGetPaymentAccounts({
    businessId,
    accountType:           'bank',
    noConsiderArchive:     true,
    excludeSystemAccounts: true,
  }, true);

  const paymentAccounts = useMemo(() => {
    return paymentAccountQuery.data?.paymentAccounts || [];
  }, [paymentAccountQuery.data?.paymentAccounts]);

  const TYPE_OPTIONS: TOption[] = useMemo(() => {
    return paymentAccounts.map((account) => ({
      label: `${account.name}`,
      value: `${account.last4}`,
    }));
  }, [paymentAccounts]);

  const [chartOfAccount, updateChartOfAccount] = useFilterField<TFilter, string | null>(
    paymentAccountName,
  );

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedValue?: TOption | null) => {
    updateChartOfAccount(selectedValue?.value || null);
  }, [updateChartOfAccount]);

  const currentOption = useMemo(() => {
    return TYPE_OPTIONS.find((opt) => chartOfAccount && opt.value === chartOfAccount) || null;
  }, [TYPE_OPTIONS, chartOfAccount]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption) }
      tooltipLabel="Type: "
    >
      <SelectInput
        { ...inputProps }
        options={ TYPE_OPTIONS }
        value={ currentOption }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(PaymentAccountFilterField);
