import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import { RightArrowIcon, GrayLocationIcon, GrayDocytNetworkIcon, GrayEnvelopeIcon, GrayPhoneIcon, SmileIcon } from '@src/components/utils/icomoon';
import { getVendorAddressText, notAvailableText } from '@src/components/vendors/utils';

interface IReviewChangesDescriptionProps {
  localVendor: IBusinessVendor,
  globalVendor?: IBusinessVendor,
}

const ReviewChangesDescription = ({
  localVendor,
  globalVendor,
}: IReviewChangesDescriptionProps): JSX.Element | null => {
  if (!globalVendor) return null;

  return (
    <div className="font-14">
      <div className="review-description-title">Docyt</div>
      <ul className="review-description">
        <li>
          <b>{ localVendor.name }</b>
          {' '}
          will be deleted
        </li>
        <li>
          Vendor in
          {' '}
          <b>
            { localVendor.countReceipts }
            {' '}
            Receipt
            {
              localVendor.countReceipts > 1 && ('s')
            }
          </b>
          ,
          {' '}
          <b>
            { localVendor.countTransactions }
            {' '}
            Transaction
            {
              localVendor.countTransactions > 1 && ('s')
            }
          </b>
          ,
          {' '}
          <b>
            { localVendor.countInvoices }
            {' '}
            Invoice
            {
              localVendor.countInvoices > 1 && ('s')
            }
          </b>
          , and
          {' '}
          <b>
            { localVendor.addresses?.length }
            {' '}
            address
            {
              (localVendor.addresses && localVendor.addresses?.length > 1) && ('es')
            }
          </b>
          {' '}
          will be updated to
          {' '}
          <b>
            “
            { globalVendor.name }
            ”
          </b>
        </li>
        {
          globalVendor.businessVendorId
            ? (
              <li>
                <b>
                  “
                  { globalVendor.name }
                  ”
                </b>
                {' '}
                in Docyt will be linked to Vendor in Ledger
                {' '}
                <b>
                  “
                  { globalVendor.name }
                  ”
                </b>
              </li>
            )
            : (
              <li>
                <b>
                  “
                  { globalVendor.name }
                  ”
                </b>
                {' '}
                in Docyt will be linked to Vendor in Ledger
                {' '}
                <b>
                  “
                  { localVendor.name }
                  ”
                </b>
              </li>
            )
        }
      </ul>
      <div className="review-description-title">Ledger</div>
      <ul className="review-description">
        <li>
          No changes will be pushed to the Ledger.
          This existing data in Ledger is not impacted by this migration.
        </li>
      </ul>
      <div className="vendors-info-block display-flex">
        <div className="width-40-percent">
          <div className="info-top">
            { localVendor.imageUrl ? (
              <img alt="Local Vendor avatar" className="small-avatar-wrapper" src={ localVendor.imageUrl } />
            ) : (
              <SmileIcon className="in-blue-400 line-height-0" fontSize={ 24 } />
            )}
            <span className="m-l-12 text-line-through">{ localVendor.name }</span>
          </div>
          <div>
            <div className="details-item">
              <GrayLocationIcon fontSize={ 16 } mr={ 5 } />
              <span>
                {
                getVendorAddressText(localVendor)
                  ? getVendorAddressText(localVendor)
                  : notAvailableText
              }
              </span>
            </div>
          </div>
        </div>
        <div className="width-10-percent mark-right-arrow">
          <RightArrowIcon />
        </div>
        <div className="width-50-percent">
          <div className="info-top">
            { globalVendor.imageUrl ? (
              <img alt="Local Vendor avatar" className="small-avatar-wrapper" src={ globalVendor.imageUrl } />
            ) : (
              <SmileIcon className="in-blue-400 line-height-0" fontSize={ 24 } />
            )}
            <span className="m-l-12">{ globalVendor.name }</span>
            {(globalVendor.achStatus === 'activated') && (
              <span className="invoice-status-panel font-bold approval m-l-12"> ACH Active </span>
            )}
          </div>
          <div>
            <div className="details-item">
              <GrayLocationIcon fontSize={ 16 } mr={ 5 } />
              <span>
                {
                getVendorAddressText(globalVendor)
                  ? getVendorAddressText(globalVendor)
                  : notAvailableText
              }
              </span>
            </div>
            <div className="details-item">
              <GrayDocytNetworkIcon fontSize={ 16 } mr={ 5 } />
              <span>
                { globalVendor.docytId ? `Docyt Network ID: ${globalVendor.docytId}` : notAvailableText }
              </span>
            </div>
            <div className="details-item">
              <GrayEnvelopeIcon fontSize={ 16 } mr={ 5 } />
              <span>{ globalVendor.email ? globalVendor.email : notAvailableText }</span>
            </div>
            <div className="details-item">
              <GrayPhoneIcon fontSize={ 16 } mr={ 5 } />
              <span>{ globalVendor.phone ? globalVendor.phone : notAvailableText }</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReviewChangesDescription);
