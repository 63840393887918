import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGeneratePlaidItemLinkTokenParams {
  product: 'transactions',
}

interface IGeneratePlaidItemLinkTokenResponse {
  linkToken: string,
}

const generatePlaidItemLinkToken = (
  params: IGeneratePlaidItemLinkTokenParams,
): Promise<IGeneratePlaidItemLinkTokenResponse> => {
  return apiPost('/api/v1/plaid_items/generate_link_token', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGeneratePlaidItemLinkTokenResponse);
};

interface IRetrievePlaidItemLinkTokenParams {
  financialInstitutionConnectionId?: TID,
}

interface IRetrievePlaidItemLinkTokenResponse {
  linkToken: string,
}

const retrievePlaidItemLinkToken = (
  params: IRetrievePlaidItemLinkTokenParams,
): Promise<IRetrievePlaidItemLinkTokenResponse> => {
  return apiGet(
    '/api/v1/plaid_items/retrieve_link_token',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IRetrievePlaidItemLinkTokenResponse);
};

export {
  IGeneratePlaidItemLinkTokenParams,
  IGeneratePlaidItemLinkTokenResponse,
  IRetrievePlaidItemLinkTokenParams,
  IRetrievePlaidItemLinkTokenResponse,
  generatePlaidItemLinkToken,
  retrievePlaidItemLinkToken,
};
