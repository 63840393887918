import React, { forwardRef, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';

import { useDropdown } from './hooks';
import { focusNextMenuItem, focusParentSubmenuItem, focusPreviousMenuItem } from './utils';

import styles from './styles.module.scss';

interface IDropdownItemProp extends
  Omit<React.ComponentPropsWithoutRef<'div'>, 'children' | 'title'>
{
  children?: React.ReactNode,
  disabled?: boolean,
  isSelected?: boolean,
  title?: React.ReactNode,
  onSelect?: () => void;
  autoHide?: boolean;
}

const DropdownItem = forwardRef<HTMLDivElement, IDropdownItemProp>(({
  children,
  className,
  disabled,
  isSelected,
  title,
  onSelect,
  autoHide = true,
  ...props
}: IDropdownItemProp, ref) => {
  const { hide, select } = useDropdown() || {};
  const itemRef = useRef<HTMLDivElement>(null);

  const classes = classNames(
    styles['dropdown-menu-item'],
    className,
    { selected: isSelected, disabled },
  );

  const handleSelect = useCallback(() => {
    if (disabled) return;

    if (onSelect) onSelect();
    if (hide && autoHide) hide();
    if (select) select();
  }, [onSelect, hide, disabled, select]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    handleSelect();
  }, [handleSelect]);

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        e.stopPropagation();
        handleSelect();
        break;
      case 'Escape':
        e.preventDefault();
        e.stopPropagation();
        if (hide) hide();
        break;
      case 'Tab':
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        focusNextMenuItem(itemRef.current);
        break;
      case 'ArrowUp':
        e.preventDefault();
        e.stopPropagation();
        focusPreviousMenuItem(itemRef.current);
        break;
      case 'ArrowLeft':
        e.preventDefault();
        e.stopPropagation();
        focusParentSubmenuItem(itemRef.current);
        break;
      default:
        break;
    }
  }, [handleSelect, hide]);

  return (
    <div
      ref={ mergeRefs([ref, itemRef]) }
      className={ classes }
      role="menuitem"
      tabIndex={ 0 }
      onClick={ handleClick }
      onKeyDown={ handleKeyDown }
      { ...props }
    >
      { title || children }
    </div>
  );
});

DropdownItem.displayName = 'DropdownItem';

export default React.memo(DropdownItem);
