import { TAmountColumn, TModel } from './types';

const CollectionTableAmountColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TAmountColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableAmountColumn;
