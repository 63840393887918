import React from 'react';

import { TSection } from '@src/types/common';

import { SectionContext } from './hooks';

interface ISectionProviderProps {
  children: React.ReactNode,
  section: TSection,
}

const SectionProvider = ({
  children,
  section,
}: ISectionProviderProps) => {
  return (
    <SectionContext.Provider value={ section }>
      { children }
    </SectionContext.Provider>
  );
};

export default SectionProvider;
