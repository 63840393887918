import React from 'react';

import AppLink from './app_link';
import Button, { IButtonProps } from './button';

interface AppLinkButtonProps extends
  Omit<React.ComponentPropsWithoutRef<'a'>, 'href'>,
  Omit<IButtonProps<typeof AppLink>, 'as'>
{
  href: string,
  newWindow?: boolean,
  trigger?: boolean,
}

const AppLinkButton = ({
  ...props
}: AppLinkButtonProps) => {
  return (
    <Button
      as={ AppLink }
      { ...props }
    />
  );
};

export default AppLinkButton;
