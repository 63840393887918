import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ITableSectionCaptionProps<T extends React.ElementType> {
  as?: T,
}

const TableSectionCaption = <T extends React.ElementType = 'h3'>({
  as,
  children,
  className,
  ...props
}: ITableSectionCaptionProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof ITableSectionCaptionProps<T>>,
) => {
  const classes = classNames(styles['table-section-caption'], className);

  const Component = as || 'h3';

  return (
    <Component className={ classes } { ...props }>
      { children }
    </Component>
  );
};

export default TableSectionCaption;
