import { Children, ReactElement } from 'react';

import ActionsColumn from './actions_column';
import AmountColumn from './amount_column';
import ButtonColumn from './button_column';
import Column from './column';
import DateColumn from './date_column';
import IconColumn from './icon_column';
import LabelColumn from './label_column';
import SelectItemColumn from './select_item_column';
import TextColumn from './text_column';
import {
  TActionsColumn,
  TAmountColumn,
  TButtonColumn,
  TColumn,
  TDateColumn,
  TIconColumn,
  TLabelColumn,
  TModel, TSelectItemColumn,
  TTextColumn,
} from './types';

const mapChildrenToColumns = <Model extends TModel, TSortColumn extends string = never>(
  children: ReactElement<TColumn<Model, TSortColumn>>
    | ReactElement<TColumn<Model, TSortColumn>>[],
): TColumn<Model, TSortColumn>[] => {
  return Children.map(children, (child) => {
    switch (child.type) {
      case Column:
        return child.props;
      case TextColumn:
        return {
          ...child.props,
          type: 'text',
        } as TTextColumn<Model, TSortColumn>;
      case AmountColumn:
        return {
          ...child.props,
          type: 'amount',
        } as TAmountColumn<Model, TSortColumn>;
      case ButtonColumn:
        return {
          ...child.props,
          type: 'button',
        } as TButtonColumn<Model, TSortColumn>;
      case DateColumn:
        return {
          ...child.props,
          type: 'date',
        } as TDateColumn<Model, TSortColumn>;
      case IconColumn:
        return {
          ...child.props,
          type: 'icon',
        } as TIconColumn<Model, TSortColumn>;
      case LabelColumn:
        return {
          ...child.props,
          type: 'label',
        } as TLabelColumn<Model, TSortColumn>;
      case SelectItemColumn:
        return {
          ...child.props,
          type: 'select_item',
        } as TSelectItemColumn<Model, TSortColumn>;
      case ActionsColumn:
        return {
          ...child.props,
          type: 'actions',
        } as TActionsColumn<Model, TSortColumn>;
      default:
        throw new Error(
          `Only \`CollectionTable.Column\` child type is allowed in \`CollectionTable\`. Used type: ${child.type}`,
        );
    }
  });
};

export {
  mapChildrenToColumns,
};
