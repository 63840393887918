import React from 'react';

import Wrapper from './details_filed_wrapper';

import styles from './styles.module.scss';

interface IDetailsStaticFiledProps {
  label: string,
  value?: string | number;
}

const DetailsStaticFiled = ({
  label,
  value,
}: IDetailsStaticFiledProps) => {
  return (
    <Wrapper
      label={ label }
    >
      <span className={ styles['static-input'] }>
        { value }
      </span>
    </Wrapper>
  );
};

export default React.memo(DetailsStaticFiled);
