import React from 'react';

import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import BusinessFilterSelect from './business_select';
import { ISettlementsFilter } from './types';

interface ISettlementsFilterProps {
  businessId: TID,
  filter: IFilter<ISettlementsFilter>,
}

const SettlementsFilter = ({
  businessId,
  filter,
}: ISettlementsFilterProps) => {
  return (
    <Filter<ISettlementsFilter> filter={ filter }>
      <BusinessFilterSelect
        businessId={ businessId }
        widthPercent={ 10 }
      />
      <Filter.TextField
        name="invoiceNumber"
        placeholder="Invoice #"
        widthPercent={ 10 }
      />
      <Filter.DateField
        endPlaceholder="End Bill Date"
        name="invoiceDate"
        startPlaceholder="Start Bill Date"
        widthPercent={ 20 }
      />
      <Filter.DateField
        endPlaceholder="End Payment Date"
        name="paymentDate"
        startPlaceholder="Start Payment Date"
        widthPercent={ 20 }
      />
      <Filter.AmountField
        name="amount"
        widthPercent={ 15 }
      />
      <Filter.ResetButton />
    </Filter>
  );
};

export default React.memo(SettlementsFilter);
