module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, participant, ref, ref1, ref2;
    
      __out.push('<a class="block-wrapper" href="');
    
      __out.push(__sanitize(this.business ? '/businesses/' + this.business.id + '/business_inbox' : '/'));
    
      __out.push('">\n  <div class="business-inbox-item-wrapper ');
    
      __out.push(__sanitize((((ref = this.business) != null ? ref.id : void 0) === ((ref1 = this.bi_service.get('business')) != null ? ref1.id : void 0)) ? 'active' : ''));
    
      __out.push('">\n    <div class="business-item-left">\n      <div class="avatar-wrapper ');
    
      __out.push(__sanitize(this.business && this.business.s3_object_key ? 'border-gray' : ''));
    
      __out.push('">\n        ');
    
      if (this.business && this.business.s3_object_key) {
        __out.push('\n        <img src="https://');
        __out.push(__sanitize(configData.bucketName));
        __out.push('.s3.amazonaws.com/');
        __out.push(__sanitize(this.business.s3_object_key));
        __out.push('">\n        ');
      } else if (this.business) {
        __out.push('\n        <span class=\'icon-business-default-avatar font-36\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n        ');
      } else {
        __out.push('\n        <img src="');
        __out.push(__sanitize(Docyt.currentAdvisor.getAdvisorAvatarUrl()));
        __out.push('">\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div class="business-item-middle">\n      <p class="font-15 font-semi-bold m-b-0">');
    
      __out.push(__sanitize(this.business ? this.business.legal_name : "Personal"));
    
      __out.push('</p>\n      <p class="font-12 m-b-0 in-gray-important">');
    
      __out.push(__sanitize(this.upload_email));
    
      __out.push('</p>\n      <ul class="display-flex m-b-0">\n        ');
    
      ref2 = this.participants;
      for (i = 0, len = ref2.length; i < len; i++) {
        participant = ref2[i];
        __out.push('\n        <li class="user-avatar-container">\n          <div class="business__userpic m-t-5 m-r-5">\n            ');
        if (participant.user_avatar) {
          __out.push('\n            <img src="https://');
          __out.push(__sanitize(configData.bucketName));
          __out.push('.s3.amazonaws.com/');
          __out.push(__sanitize(participant.user_avatar.s3_object_key));
          __out.push('" class="business__userpic-img block-wrapper">\n            ');
        } else {
          __out.push('\n            <i class="clients__client-status-icon icon icon-smile in-blue-400 font-20"></i>\n            ');
        }
        __out.push('\n          </div>\n        </li>\n        ');
      }
    
      __out.push('\n        <li class="more-participants-wrapper hidden">\n          <div class="round-number font-10 font-semi-bold m-t-5"></div>\n        </li>\n      </ul>\n    </div>\n    <div class="business-item-right">\n      <p class="font-16 font-bold m-b-0">');
    
      __out.push(__sanitize(this.total_inbox_count));
    
      __out.push('</p>\n      <p class="font-12">Docs</p>\n    </div>\n  </div>\n</a>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}