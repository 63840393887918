import React from 'react';

import { TBankAccountReconciliationStatus } from '@src/types/bank_account_reconciliations';

import { Button } from '@src/components/ui_v2/buttons';

interface ReconcileButtonProps {
  name: string;
  disabled: boolean;
  onDone: (data: any) => void;
}

const ReconcileButton = ({ name, disabled, onDone }: ReconcileButtonProps) => {
  const reconcileModal = () => {
    onDone('reconciled' as TBankAccountReconciliationStatus);
  };

  return (
    <Button disabled={ disabled } variant="primary" onClick={ reconcileModal }>
      Mark as Reconciled
    </Button>
  );
};

export default React.memo(ReconcileButton);
