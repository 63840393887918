import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { WarningIcon } from '@src/components/utils/fa_icons';

interface IBankAccountSelectFieldProps {
  error?: string,
  onSelect: () => void;
}

const BankAccountSelectField = ({
  error,
  onSelect,
}: IBankAccountSelectFieldProps) => {
  return (
    <>
      <Button
        bsStyle="link"
        className="pointer font-bold display-flex p-l-0"
        onClick={ onSelect }
      >
        Select an account
      </Button>
      {
        error && (
          <p className="in-red-400 main-input-hint font-10">
            <WarningIcon />
            {' '}
            { error }
          </p>
        )
      }
    </>
  );
};

export default BankAccountSelectField;
