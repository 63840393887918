import React, { useCallback } from 'react';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useSaveReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface ISaveBudgetProps extends IUseModalProps { }

const SaveBudget = ({
  isOpen,
  onCancel,
  onDone,
}: ISaveBudgetProps): JSX.Element => {
  const budget = useReportServiceBudgetContext();
  const business = useBusinessContext();
  const save = useSaveReportServiceBudget();

  const handleSuccess = useCallback(() => {
    onDone();
    Backbone.history.navigate(`/businesses/${business.id}/reports/budgets`, { trigger: true });
  }, [business.id, onDone]);

  const { mutate } = save;

  const handleSubmit = useCallback((method) => {
    mutate(
      { budgetId: budget.id, method },
      { onSuccess: handleSuccess },
    );
  }, [budget.id, mutate, handleSuccess]);

  return (
    <>
      <MutationStatus mutation={ save } />
      <Modal
        className="modal-position-middle"
        show={ isOpen }
        title="Save before closing"
      >
        <Modal.Body>
          <p className="p-b-0 font-16 in-black text-center">
            {` ${window.Docyt.Common.Constants.Messages.BUDGET_SAVE_MSG1}`}
          </p>
          <p className="p-b-0 font-16 in-black text-center">
            {` ${window.Docyt.Common.Constants.Messages.BUDGET_SAVE_MSG2}`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton onClick={ onCancel } />
          <Button
            className="btn-blue-on-hover m-r-15"
            fontVariant="semi-bold"
            title="Close without saving"
            onClick={ () => handleSubmit('discard') }
          />
          <Button
            bsColor="blue"
            fontVariant="semi-bold"
            title="Save and Close"
            onClick={ () => handleSubmit('publish') }
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useSaveBudgetModal = makeUseModal(SaveBudget);

export {
  useSaveBudgetModal,
  SaveBudget as default,
};
