import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetReportBySlug } from '@src/hooks/queries/report_service/custom_reports';

import ReportDetail from './report_detail';

interface IReportDetailPageProps {
  slug: string,
}

const ReportDetailPage = ({
  slug,
}: IReportDetailPageProps): JSX.Element | null => {
  const business = useBusinessContext();

  const { data: report } = useGetReportBySlug({
    slug,
    businessId: business.id,
  });

  if (report) {
    if (!localStorage.getItem('business_report_id')) {
      localStorage.setItem('business_report_id', report.id);
    } else if (localStorage.getItem('business_report_id') !== report.id) {
      localStorage.removeItem(`multiValue-${localStorage.getItem('business_report_id')}`);
      localStorage.removeItem(`budgetsSelectValue-${localStorage.getItem('business_report_id')}`);
      localStorage.removeItem(`forecastSelectValue-${localStorage.getItem('business_report_id')}`);
      localStorage.setItem('business_report_id', report.id);
    }

    return (
      <ReportDetail businessId={ business.id } report={ report } />
    );
  }

  return null;
};

export default ReportDetailPage;
