import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { useReconciliationCenterExceptionMessagesCollection } from './hooks';
import ExceptionMessagesTable from './table';

const ExceptionMessages = () => {
  const business = useBusinessContext()
  const collection = useReconciliationCenterExceptionMessagesCollection({ businessId: business.id });

  return (
    <DetailsRegion className="p-l-150 p-r-150">
      <DetailsRegion.Header title={ `Following chart of accounts could not be imported from ${business.name} Quickbooks accounts.` } />
      <Section.Provider section={ collection.section }>
        <TableSection>
          <ExceptionMessagesTable collection={ collection } />
        </TableSection>
      </Section.Provider>
    </DetailsRegion>
  );
};

export default React.memo(ExceptionMessages);
