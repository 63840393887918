import ActionsDropdownCell from './actions_dropdown_cell';
import AmountCell from './amount_cell';
import AmountHCell from './amount_hcell';
import Body from './body';
import Cell from './cell';
import DateCell from './date_cell';
import DateTimeCell from './date_time_cell';
import EmptyHCell from './empty_hcell';
import Foot from './foot';
import HCell from './hcell';
import Head from './head';
import IconCell from './icon_cell';
import InputCell from './input_cell';
import LabelCell from './label_cell';
import NumberCell from './number_cell';
import NumberHCell from './number_hcell';
import Row from './row';
import SelectAllHCell from './select_all_hcell';
import SelectItemCell from './select_item_cell';
import Table from './table';
import TextCell from './text_cell';
import UserCell from './user_cell';

export default Object.assign(Table, {
  ActionsDropdownCell,
  AmountCell,
  AmountHCell,
  Body,
  Cell,
  DateCell,
  DateTimeCell,
  EmptyHCell,
  Foot,
  HCell,
  Head,
  IconCell,
  UserCell,
  InputCell,
  LabelCell,
  NumberCell,
  NumberHCell,
  Row,
  SelectAllHCell,
  SelectItemCell,
  TextCell,
});

export { ITableProps } from './table';
