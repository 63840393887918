import React from 'react';

import { SelectAllCheckbox } from '@src/components/utils/items_selector';

import HCell, { ITableHeaderCellNotSortableProps } from './hcell';

interface ISelectTableAllItemHCellProps extends
  Omit<ITableHeaderCellNotSortableProps, 'children'>
{
  inputProps?: React.ComponentPropsWithoutRef<typeof SelectAllCheckbox>,
}

/**
 * @visibleName Table.SelectAllHCell
 */
const SelectTableAllItemHCell = ({
  inputProps,
  ...cellProps
}: ISelectTableAllItemHCellProps) => {
  return (
    <HCell widthPercent={ 3 } { ...cellProps }>
      <SelectAllCheckbox { ...inputProps } />
    </HCell>
  );
};

export default React.memo(SelectTableAllItemHCell);
