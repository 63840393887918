module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td>\n  <div class="checkbox issue-checkbox">\n    <input type="checkbox" class="form-control issue-checkbox-input" data-type="checkbox" data-issue-index="');
    
      __out.push(__sanitize(this.issueIndex));
    
      __out.push('">\n    <label class="font-20"></label>\n  </div>\n</td>\n<td class="');
    
      __out.push(__sanitize(this.document_id ? 'document-clickable pointer' : ''));
    
      __out.push('">');
    
      __out.push(__sanitize(this.document_name));
    
      __out.push('</td>\n<td class="');
    
      __out.push(__sanitize(this.document_id ? 'document-clickable pointer' : ''));
    
      __out.push('">');
    
      __out.push(__sanitize(this.item_detail ? this.item_detail : '--'));
    
      __out.push('</td>\n<td class="');
    
      __out.push(__sanitize(this.document_id ? 'document-clickable pointer' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.synchronized_date ? moment(this.synchronized_date).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '--'));
    
      __out.push('\n  ');
    
      if (this.synchronized_item_type === 'ServiceDocument' && this.paid_at) {
        __out.push('\n  <p class="m-b-0">(Paid on ');
        __out.push(__sanitize(moment(this.paid_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push(')</p>\n  ');
      }
    
      __out.push('\n</td>\n<td class="description-column ');
    
      __out.push(__sanitize(this.document_id ? 'document-clickable pointer' : ''));
    
      __out.push('">');
    
      __out.push(__sanitize(this.sync_status === 'started' ? '--' : this.error_description));
    
      __out.push('</td>\n<td class="text-right amount-column ');
    
      __out.push(__sanitize(this.document_id ? 'document-clickable pointer' : ''));
    
      __out.push('">');
    
      __out.push(__sanitize(this.amount ? parseFloat(this.amount).toFixed(2) : '--'));
    
      __out.push('</td>\n<td class="pointer description-tooltip-js" data-toggle="tooltip" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.sync_status === 'started' ? 'QuickBooks sync has been initiated' : this.error_description));
    
      __out.push('">\n  ');
    
      if (this.sync_status === 'started' || this.sync_status === null) {
        __out.push('\n    <span class="icon icon-qbo-off font-24"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-qbo-error font-24"></span>\n  ');
      }
    
      __out.push('\n</td>\n<td>\n  <div class="relative pointer">\n    <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="issue-');
    
      __out.push(__sanitize(this.issueIndex));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <i class=\'icon icon-3-dots font-20\'></i>\n    </button>\n    <div class="issue-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="issue-');
    
      __out.push(__sanitize(this.issueIndex));
    
      __out.push('-action-dropdown-btn">\n      <a class="dropdown-item font-16 resync-issue-js">\n        <span class="icon icon-qbo-on font-24"></span>\n        <span class="item-text">Re-sync</span>\n      </a>\n      ');
    
      if (this.document_id) {
        __out.push('\n      <a href="/document/');
        __out.push(__sanitize(this.document_id));
        __out.push('" class="dropdown-item font-16 document-view-details-js">\n        <i class="icon icon-note font-24"></i>\n        <span class="item-text">View Details</span>\n      </a>\n      ');
      }
    
      __out.push('\n      ');
    
      if (this.synchronized_item_type === 'Vendor') {
        __out.push('\n        <a href="/businesses/');
        __out.push(__sanitize(this.business_id));
        __out.push('/vendor_service/payees/');
        __out.push(__sanitize(this.synchronized_item_id));
        __out.push('" class="dropdown-item font-16 vendor-view-details-js">\n          <i class="icon icon-note font-24"></i>\n          <span class="item-text">View Details</span>\n        </a>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}