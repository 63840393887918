import React, { useCallback } from 'react';

import { useMarkAsNoAvailableBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_informations';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface IMarkAsNoAvailableActionProps {
  businessId: TID,
  state: IStatementState,
  chartOfAccountId: TID,
}

const MarkAsNoAvailableAction = ({
  businessId,
  state,
  chartOfAccountId,
}: IMarkAsNoAvailableActionProps): JSX.Element => {
  const markAsNoAvailable = useMarkAsNoAvailableBalanceSheetStatement();
  const { mutate } = markAsNoAvailable;

  const handleMarkAsNoAvailable = useCallback(() => {
    const firstDate = new Date(state.month);
    const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
    mutate({
      businessId,
      chartOfAccountId,
      date: formatDate(lastDate, API_DATE_FORMAT),
    });
  }, [businessId,
    mutate,
    chartOfAccountId,
    state,
  ]);

  return (
    <>
      <MutationStatus mutation={ markAsNoAvailable } />
      <ActionsDropdown.Action
        title="Mark as 'No Document Available'"
        onClick={ handleMarkAsNoAvailable }
      />
    </>
  );
};

export default MarkAsNoAvailableAction;
