import React, { useMemo, forwardRef } from 'react';

import { TID } from '@src/types/common';
import { IQuickbooksCustomer } from '@src/types/quickbooks_customers';

import Form from '@src/components/ui/form';

type TOption = {
  name: string,
  value: string,
}

interface ICustomerSelectProps {
  customerId: TID,
  quickbooksCustomers: IQuickbooksCustomer[]
}

const CustomerSelect = forwardRef<HTMLSelectElement, ICustomerSelectProps>(({
  customerId,
  quickbooksCustomers,
  ...props
}: ICustomerSelectProps, ref): JSX.Element => {
  const options = useMemo(() => {
    const customers: TOption[] = [];
    quickbooksCustomers.forEach((customer) => {
      if (customer.id !== customerId) {
        customers.push({
          value: `${customer.id}`,
          name:  customer.name,
        });
      }
    });
    return customers;
  }, [quickbooksCustomers, customerId]);

  return (
    <Form.SelectField
      { ...props }
      ref={ ref }
      hideLabel
      options={ options }
      placeholder="Select Customer"
    />
  );
});

CustomerSelect.displayName = 'CustomerSelect';

export default CustomerSelect;
