import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IDocument, TDocumentsSortColumn } from '@src/types/documents';
import { ISorting } from '@src/types/sorting';
import { IStandardDocumentField } from '@src/types/standard_documents';

import Table from '@src/components/ui/table';

import Item from './item';

interface IDocumentListProps {
  documents: IDocument[],
  unEncryptedStandardDocumentFields: IStandardDocumentField[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TDocumentsSortColumn>,
}

const DocumentList = ({
  documents,
  unEncryptedStandardDocumentFields,
  query,
  sorting,
}: IDocumentListProps): JSX.Element => {
  return (
    <Table
      infiniteQuery={ query }
      wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell
            columnName={ `field_id_${unEncryptedStandardDocumentFields[0]?.fieldId}` }
            sorting={ sorting }
            widthPercent={ 33 }
          >
            { unEncryptedStandardDocumentFields[0]?.name }
          </Table.HCell>
          <Table.HCell
            columnName={ `field_id_${unEncryptedStandardDocumentFields[1]?.fieldId}` }
            sorting={ sorting }
            widthPercent={ 33 }
          >
            { unEncryptedStandardDocumentFields[1]?.name }
          </Table.HCell>
          <Table.HCell
            columnName="file_name"
            sorting={ sorting }
          >
            Filename
          </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          documents.map((document) => {
            return (
              <Item
                key={ document.id }
                document={ document }
                unEncryptedStandardDocumentFields={ unEncryptedStandardDocumentFields }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default DocumentList;
