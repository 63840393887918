import React, { useCallback, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBusinesses } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';

import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';
import IconSelectInput, { IIconSelectInputProps, TIconOption }
  from '@src/components/ui/form/inputs/react_select/icon_select_input';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

interface IBusinessFieldProps extends
  Omit<
    IIconSelectInputProps,
    'isMulti' | 'options' | 'value' | 'onChange'
  >,
  Omit<IFormFieldWrapperProps, 'children'>
{
  value?: IBusiness['id'],
  onChange: (value?: IBusiness['id']) => void,
}

const BusinessField = ({
  error,
  hideLabel,
  hint,
  label,
  mark,
  value,
  onChange,
  ...selectProps
}: IBusinessFieldProps): JSX.Element => {
  const query = useGetBusinesses({
    includeEmployedBusiness: true,
    simple:                  true,
  });

  const businesses = useMemo(() => {
    const businessesPages = query.data?.pages || [];
    return flatten(businessesPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const options: TIconOption[] = useMemo(() => {
    return (businesses ?? []).map((business) => ({
      value: String(business.id),
      label: business.name,
      icon:  business.avatarUrl ? (
        <img
          alt="Business avatar"
          className="small-avatar"
          src={ business.avatarUrl }
        />
      ) : (
        <BusinessDefaultAvatarIcon fontSize={ 25 } />
      ),
    }));
  }, [businesses]);

  const selectedOption = options.find((o) => o.value === String(value));

  const handleChange = useCallback((option: TIconOption | null) => {
    onChange(option ? Number(option.value) : undefined);
  }, [onChange]);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <IconSelectInput
        isSearchable
        options={ options }
        widthPercent={ 100 }
        { ...selectProps }
        value={ selectedOption }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

BusinessField.displayName = 'BusinessField';

export default BusinessField;
