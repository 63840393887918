import React from 'react';

import TableContainer from './table_container';

interface IFilterProps extends
  React.ComponentPropsWithoutRef<typeof TableContainer>
{
  children: React.ReactNode,
}

const Filter = ({
  children,
  ...containerProps
}: IFilterProps): JSX.Element => {
  return (
    <TableContainer { ...containerProps }>
      { children }
    </TableContainer>
  );
};

export default Filter;
