import loadjs from 'loadjs';

const loadGoogleTagManager = (googleAnalyticsId?: string): Promise<void> => {
  return loadjs(
    `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
    { returnPromise: true },
  ).then(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) { window.dataLayer.push(...args); }
    gtag('js', new Date());
    gtag('config', googleAnalyticsId);
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error on loading Google Tag Manager: ', error);
  });
};

export { loadGoogleTagManager };
