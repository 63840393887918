module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class=\'modal-add-password select-standard-document-page\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>Add Standard Document Profile</h4>\n        </div>\n        <div class=\'modal-body\'>\n          <div class=\'main-input__wrap\'>\n            <div class=\'relative\'>\n              <label class="font-16">Payee Name:</label>\n              <input class=\'login-form__input main-input m-t-10\' type=\'text\' disabled value="');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('">\n            </div>\n          </div>\n          <div class=\'main-input__wrap\'>\n            <div class=\'relative\'>\n              <label class="font-16">Profile Name:</label>\n              <input class=\'login-form__input main-input m-t-10\' id="profile-name" placeholder=\'Profile Name\' type=\'text\'>\n            </div>\n            <p class=\'main-input__hint in-red-400\' id="profile-name-invalid">\n              Please enter correct name\n            </p>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button submit-btn" data-color="blue" data-style="expand-right"><span class="ladda-label">Add</span></button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}