import React from 'react';

import AccountClassInput from '@src/components/common_v2/inputs/account_class_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from '@src/components/ui_v2/form/fields/utils';

interface IAccountClassFieldProps extends
  React.ComponentPropsWithoutRef<typeof AccountClassInput>, IWrapperProps {
}

const AccountClassField = ({
  ...props
}: IAccountClassFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <AccountClassInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default React.memo(AccountClassField);
