import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const TrashIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="trash"
    />
  );
};

TrashIcon.displayName = 'TrashIcon';

export default TrashIcon;
