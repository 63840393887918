import React from 'react';

import { useReportContext } from '@src/hooks/contexts/report_context';
import { IBusiness } from '@src/types/businesses';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import CopyMappingAction from './copy_mapping/copy_mapping_action';
import ResetDefaultMappingAction from './reset_default_mapping/reset_default_mapping_action';

interface IEditMappingActionsDropdownProps {
  currentBusiness: IBusiness,
  templateId: string,
}

const EditMappingActionsDropdown = ({
  currentBusiness,
  templateId,
}: IEditMappingActionsDropdownProps): JSX.Element => {
  const report = useReportContext();

  return (
    <ActionsDropdown
      className="display-inline-flex"
      pull="right"
      titleClassName="in-grey-800 display-flex"
    >
      <CopyMappingAction
        currentBusiness={ currentBusiness }
        templateId={ templateId }
      />
      {
        report.enabledDefaultMapping && (
          <ResetDefaultMappingAction />
        )
      }
    </ActionsDropdown>
  );
};

export default EditMappingActionsDropdown;
