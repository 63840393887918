import React, { useCallback, useMemo } from 'react';

import sumBy from 'lodash/sumBy';

import { useCreateRevenueReconciliation } from '@src/hooks/queries/revenue_reconciliations';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { getPostReconciliationParams } from '@src/components/revenue_center/reconcile/actions_view/utils';
import {
  IReconcileRSDCollection,
  ITSDCollected,
} from '@src/components/revenue_center/reconcile/hooks';
import { totalAmountForPaymentProcessor } from '@src/components/revenue_center/utils';
import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import { usePaymentProcessorContext } from '../../payment_processor_provider';

import styles from './styles.module.scss';

interface IRevenueCenterReconcileActionsViewProps {
  revenueSystem: IRevenueSystem,
  rsdCollection: IReconcileRSDCollection,
  tsdCollection: ITSDCollected,
}

const RevenueCenterReconcileActionsView = ({
  revenueSystem,
  rsdCollection,
  tsdCollection,
}: IRevenueCenterReconcileActionsViewProps) => {
  const paymentProcessor = usePaymentProcessorContext();

  const createRevenueReconciliation = useCreateRevenueReconciliation();
  const { mutate } = createRevenueReconciliation;
  const { selectedRecords: selectedRevenues, resetSelector: resetRevenueSelector } = rsdCollection;
  const { selectedRecords: selectedIncomes, resetSelector: resetTxnSelector } = tsdCollection;

  const totalRevenueAmount = useMemo(() => {
    return totalAmountForPaymentProcessor(
      selectedRevenues,
      paymentProcessor!.id,
    );
  }, [selectedRevenues, paymentProcessor]);
  const totalIncomeAmount = useMemo(() => {
    return sumBy(selectedIncomes, (r) => parseFloat(r.amount));
  }, [selectedIncomes]);
  const diffAmount = totalRevenueAmount - totalIncomeAmount;

  const handleCancelClick = useCallback(() => {
    window.Docyt.vent.trigger('close:reconcile:page');
  }, []);

  const handleReconcile = useCallback(() => {
    mutate(
      getPostReconciliationParams(
        revenueSystem,
        paymentProcessor,
        selectedRevenues,
        selectedIncomes,
      ),
      {
        onSuccess: () => {
          resetRevenueSelector();
          resetTxnSelector();
        },
      },
    );
  }, [mutate, revenueSystem, paymentProcessor,
    selectedRevenues, selectedIncomes, resetRevenueSelector, resetTxnSelector]);

  const isReconcileDisabled = useMemo(() => {
    if (selectedIncomes.length === 0) return true;

    return selectedIncomes.findIndex((txn) => txn.revenueReconciliationItem) >= 0;
  }, [selectedIncomes]);

  return (
    <>
      <MutationStatus mutation={ createRevenueReconciliation } successMessage="Reconciled between revenues and incomes successfully." />
      <div className={ styles['bottom-menu'] }>
        <div className={ styles['total-information'] }>
          <span className={ styles['selected-total-information'] }>
            Revenue Collected from
            { ' ' }
            { paymentProcessor?.name }
          </span>
          <span>{ currencyLocaleFormatter(totalRevenueAmount) }</span>
          <span className={ styles['selected-total-information'] }>Transactions from Bank Account</span>
          <span>{ currencyLocaleFormatter(totalIncomeAmount) }</span>
        </div>
        <div className={ styles['actions-list'] }>
          <Button
            variant="link"
            onClick={ handleCancelClick }
          >
            Cancel
          </Button>
          <span className={ (diffAmount === 0.0) ? styles['diff-information-same'] : styles['diff-information-not-same'] }>
            Difference:
            { ' ' }
            { currencyLocaleFormatter(totalRevenueAmount - totalIncomeAmount) }
          </span>
          <Button
            disabled={ isReconcileDisabled }
            variant="primary"
            onClick={ handleReconcile }
          >
            Reconcile
          </Button>
        </div>
      </div>
    </>
  );
};

export default RevenueCenterReconcileActionsView;
