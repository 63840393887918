import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { useRevenueSystemContext } from '@src/components/revenue_center/revenue_system_provider';
import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import DocFilesDownloadList from './doc_files_list';
import RunFilesDownloadList from './run_files_list';

interface IDownloadReportModalProps extends IUseModalProps {
  revenueReport: IRevenueServiceDocument,
}

const DownloadReportModal = ({
  isOpen,
  onDone,
  revenueReport,
}: IDownloadReportModalProps) => {
  const useRevenueSystem = useRevenueSystemContext();
  const { integrationRunId } = revenueReport;

  return (
    <Modal
      captureClick
      show={ isOpen }
      title="View Downloaded Files"
    >
      <Modal.Body>
        { isOpen && (
          <div>
            { (integrationRunId && useRevenueSystem.integrationId) ? (
              <RunFilesDownloadList
                integrationId={ useRevenueSystem.integrationId }
                integrationRunId={ integrationRunId }
              />
            ) : (
              <DocFilesDownloadList document={ revenueReport.document! } />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={ onDone }>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useDownloadReportModal = makeUseModal(DownloadReportModal);

export {
  useDownloadReportModal,
  DownloadReportModal as default,
};
