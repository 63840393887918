import React, { useCallback } from 'react';

import startsWith from 'lodash/startsWith';

interface LinkProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> {
  href: string,
  newWindow?: boolean,
  trigger?: boolean,
}

const AppLink = ({
  children,
  href,
  newWindow,
  trigger = true,
  ...props
}: LinkProps) => {
  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (newWindow) {
      const url = startsWith(href, 'http') ? href : `${window.location.origin}${href}`;
      window.open(url);
    } else {
      Backbone.history.navigate(href, { trigger });
    }
  }, [href, trigger, newWindow]);

  return (
    <a
      href={ href }
      onClick={ handleClick }
      { ...props }
    >
      { children }
    </a>
  );
};

export default AppLink;
