import React, { useCallback, useMemo } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetBusinessChartOfAccount } from '@src/hooks/queries/business_chart_of_accounts';
import { getBusinessChartOfAccounts, IGetBusinessChartOfAccountsParams } from '@src/requests/business_chart_of_accounts';
import { IBusinessChartOfAccount } from '@src/types/business_chart_of_accounts';
import { TID } from '@src/types/common';

import {
  AsyncPaginateIconSelectInput,
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IBusinessChartOfAccountInputProps extends
  Omit<IIconSelectInputProps, 'value' | 'onChange'>
{
  businessId?: TID,
  simple?: boolean,
  isDisabled?: boolean,
  queryParams?: Pick<
    IGetBusinessChartOfAccountsParams,
    'accountingClassId' | 'searchAccType' | 'searchClassification' | 'withoutAccType'
  >,
  value?: TID | null,
  onChange?: (value: TID | null, option: TIconOption | null) => void,
}

const accountIcon = (account: IBusinessChartOfAccount): React.ReactElement => {
  if (account.qboId === '0') {
    return <WarningIcon className="in-red-400" />;
  }

  if (account.qboId != null) {
    return <QBOOnIcon />;
  }

  return <QBOOffIcon />;
};

const accountLabel = (account: IBusinessChartOfAccount): string => {
  let template = `${account.accType}: `;

  if (account.parentId) {
    template += `${account.parentName} ► ${account.name}`;
  } else {
    template += account.name;
  }

  if (account.number) {
    template = `${account.number}: ${template}`;
  }

  return template;
};

const BusinessChartOfAccountInput = ({
  businessId,
  simple = true,
  isDisabled,
  queryParams,
  value,
  onChange,
  ...props
}: IBusinessChartOfAccountInputProps): JSX.Element => {
  const coaQuery = useGetBusinessChartOfAccount({
    businessId,
    chartOfAccountId: value || undefined,
  });

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    if (!onChange) return;

    onChange(item?.value ? Number(item.value) : null, item);
  }, [onChange]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, undefined> =
    useCallback((query) => {
      return getBusinessChartOfAccounts({
        // When there is no `businessId` the field is disabled and function will not be called.
        businessId: businessId!,
        search:     query,
        simple,
        ...queryParams,
      }).then((data) => {
        const newOptions = data.collection.map((account) => ({
          icon:  accountIcon(account),
          label: accountLabel(account),
          value: String(account.id),
        }));

        return {
          hasMore: false,
          options: newOptions,
        };
      });
    }, [businessId, queryParams, simple]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!coaQuery.data) return undefined;
    const account = coaQuery.data.chartOfAccount;
    if (coaQuery.data.chartOfAccount.id !== value) return undefined;

    return {
      icon:  accountIcon(account),
      label: accountLabel(account),
      value: String(account.id),
    };
  }, [value, coaQuery.data]);

  return (
    <AsyncPaginateIconSelectInput
      defaultOptions
      cacheUniqs={ [businessId, queryParams] }
      debounceTimeout={ 300 }
      isDisabled={ isDisabled || !businessId }
      isLoading={ coaQuery.isLoading }
      { ...props }
      loadOptions={ handleSource }
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default React.memo(BusinessChartOfAccountInput);
