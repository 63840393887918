import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import { useEditBudgetModal } from '@src/components/business_reports/budgets/modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';

interface IBudgetItemEditActionProps {
  budget: IReportServiceBudget,
}

const BudgetItemEditAction = ({
  budget,
}: IBudgetItemEditActionProps) => {
  const business = useBusinessContext();
  const edit = useEditBudgetModal();

  return (
    <>
      <edit.Component { ...edit.props } budget={ budget } businessId={ business.id } />
      <ActionsDropdown.Action
        icon={ <EditIcon fontSize={ 18 } /> }
        title="Edit"
        onSelect={ edit.open }
      />
    </>
  );
};

export default BudgetItemEditAction;
