import { IVendorPayment } from '@src/types/vendor_payments';

import { PaymentStatus, PaymentMethodLabel } from './options';

const formatPaymentMethod = (paymentMethod: string): string => {
  const paymentMethodMap: { [key: string]: string } = {
    docyt_ach:           PaymentMethodLabel.PAYMENT_DOCYT_ACH,
    docyt_check:         PaymentMethodLabel.PAYMENT_DOCYT_CHECK,
    bank_check:          PaymentMethodLabel.PAYMENT_BANK_CHECK,
    bank_account:        PaymentMethodLabel.PAYMENT_BANK_ACCOUNT,
    advanced_settlement: PaymentMethodLabel.PAYMENT_ADVANCED_SETTLEMENT,
    credit_card:         PaymentMethodLabel.PAYMENT_CREDIT_CARD,
    cash:                PaymentMethodLabel.PAYMENT_CASH,
    paid_externally:     PaymentMethodLabel.PAYMENT_PAID_EXTERNALLY,
    vendor_credit:       PaymentMethodLabel.PAYMENT_VENDOR_CREDIT,
  };

  return paymentMethodMap[paymentMethod] || paymentMethod
    .split('_')
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ');
};

const paymentStatusVariant = (payment: IVendorPayment): string => {
  const warningStatuses = [
    PaymentStatus.PAYMENT_CREATED,
    PaymentStatus.PAYMENT_MAILED,
    PaymentStatus.PAYMENT_IN_TRANSIT,
    PaymentStatus.PAYMENT_IN_LOCAL_AREA,
    PaymentStatus.PAYMENT_PROCESSING,
    PaymentStatus.PAYMENT_REROUTED,
    PaymentStatus.PAYMENT_RETURNED_TO_SENDER,
  ];

  const successStatuses = [
    PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY,
    PaymentStatus.PAYMENT_CLEARED,
    PaymentStatus.PAYMENT_COMPLETED,
  ];

  const failedStatuses = [
    PaymentStatus.PAYMENT_CANCELLED,
    PaymentStatus.PAYMENT_VOIDED,
    PaymentStatus.PAYMENT_FAILED,
  ];

  if (warningStatuses.includes(payment.paymentStatus)) {
    return 'warning';
  }
  if (successStatuses.includes(payment.paymentStatus)) {
    return 'success';
  }
  if (failedStatuses.includes(payment.paymentStatus)) {
    return 'failed';
  }

  return '';
};

export {
  formatPaymentMethod,
  paymentStatusVariant,
};
