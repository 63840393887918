import { array, object, string } from 'yup';

import { IWidget } from '@src/types/dashboards';

const dashboardValidation = object({
  name:    string().trim().required('Dashboard Name is required.'),
  widgets: array().test((value, validationContext) => {
    const { createError } = validationContext;

    if (value?.length === 0) {
      return createError({ message: 'You need to select at least one widget.' });
    }
    return true;
  }),
});

interface IDashboardInput {
  name: string,
  widgets: IWidget[],
}

export {
  IDashboardInput,
  dashboardValidation,
};
