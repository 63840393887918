import React from 'react';

import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IQueryStatusProps {
  query: UseQueryResult<any, Error> | UseInfiniteQueryResult<any, Error>
}

const QueryStatus = ({
  query,
}: IQueryStatusProps): JSX.Element | null => {
  if (query.status === 'loading') return <Spinner />;
  if ('isFetchingNextPage' in query && query.isFetchingNextPage) return <Spinner />;
  if ('isFetching' in query && query.isFetching) return <Spinner />;

  if (query.isError) {
    return (
      <ErrorNotification
        error={ query.error }
      />
    );
  }

  return null;
};

export default QueryStatus;
