import React, { useCallback } from 'react';

import { TAccountType, TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import {
  FormFieldWrapper,
  IFormFieldWrapperProps,
} from '@src/components/ui/form';
import Text from '@src/components/ui/text';

import { IQBOData } from '../schema';
import { useSelectChartOfAccountSelector } from './chart_of_account_selector';

import styles from '../styles.module.scss';

interface IAccountSettingsChartOfAccountsFieldProps extends
  Omit<IFormFieldWrapperProps, 'children'>
{
  accountType: TAccountType,
  paymentAccountId?: TID,
  value: IQBOData | undefined,
  onChange: (value: IQBOData) => void,
}

const AccountSettingsChartOfAccountsField = ({
  accountType,
  error,
  hint,
  label,
  mark,
  paymentAccountId,
  value,
  onChange,
}: IAccountSettingsChartOfAccountsFieldProps) => {
  const handleSelectorDone = useCallback((qbo: IQBOData) => {
    onChange({
      id:   qbo.id,
      name: qbo.name,
    });
  }, [onChange]);

  const selector = useSelectChartOfAccountSelector({ onDone: handleSelectorDone });

  return (
    <>
      <selector.Component
        { ...selector.props }
        accountType={ accountType }
        paymentAccountId={ paymentAccountId }
        qbo={ value }
      />
      <FormFieldWrapper
        className={ styles['chart-of-accounts-field'] }
        error={ error }
        hint={ hint }
        label={ label }
        mark={ mark }
      >
        <div className="value-container">
          <Text className="value">
            { value?.name || 'Select Chart of Accounts' }
          </Text>
          <Button
            bsStyle="link"
            className="pointer font-bold"
            onClick={ selector.open }
          >
            Edit
          </Button>
        </div>
      </FormFieldWrapper>
    </>
  );
};

export default AccountSettingsChartOfAccountsField;
