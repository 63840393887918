import { object, string } from 'yup';

import { TID } from '@src/types/common';

const confirmValidation = object({
  confirmName: string().trim().matches(/^CONFIRM$/, { message: 'Please type "CONFIRM" if you want to proceed' }),
});

interface IVerifyBankFeedConfirmFormValues {
  confirmName: TID,
}

export {
  IVerifyBankFeedConfirmFormValues,
  confirmValidation,
};
