module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="accounts-receivable-container">\n  <img src="');
    
      __out.push(__sanitize(configData.receivable_images.GET_STARTED));
    
      __out.push('" class="pull-left activation-image m-t-60" />\n  <div class="activation-home-content-region">\n    <h2 class="font-26 font-semi-bold m-b-15">Get paid from your customers in a faster, more secure way</h2>\n    <p class="font-16 m-b-30">\n      Docyt\'s Accounts Receivable lets you keep track of all the payments you receive from your customers. View reports on you received payments and chat directly with your customers.\n    </p>\n    <a class="btn btn-blue btn-activate">Get Started</a>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}