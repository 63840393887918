module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="invoice-clickable pointer">\n  <div class="settlement-column-wrapper">\n    ');
    
      if (this.business_avatar_url) {
        __out.push('\n    <img class="m-r-16" src="');
        __out.push(__sanitize(this.business_avatar_url));
        __out.push('"/>\n    ');
      } else {
        __out.push('\n    <span class=\'icon-business-default-avatar font-24 m-r-16\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n    ');
      }
    
      __out.push('\n    <span class="font-15 in-dark-250">');
    
      __out.push(__sanitize(this.business_name || '---'));
    
      __out.push('</span>\n  </div>\n</td>\n<td class="invoice-clickable pointer">\n  <div class="settlement-column-wrapper">\n    ');
    
      if (this.vendor_avatar_url) {
        __out.push('\n    <img class="m-r-16" src="');
        __out.push(__sanitize(this.vendor_avatar_url));
        __out.push('"/>\n    ');
      } else {
        __out.push('\n    <i class="icon icon-smile in-orange-500 font-24 m-r-16"></i>\n    ');
      }
    
      __out.push('\n    <span class="font-14 in-black">');
    
      __out.push(__sanitize(this.vendor_name || '---'));
    
      __out.push('</span>\n  </div>\n</td>\n<td class="relative pointer">\n  ');
    
      if (this.is_document_uploaded) {
        __out.push('\n  <i class="font-24 icon icon-preview relative m-l-3 preview-clickable"></i>\n  ');
      }
    
      __out.push('\n</td>\n<td class="font-14 in-black invoice-clickable pointer">\n  ');
    
      __out.push(__sanitize(this.invoice_number || '---'));
    
      __out.push('\n</td>\n<td class="font-14 in-grey-1050 invoice-clickable pointer">\n  ');
    
      __out.push(__sanitize(this.invoice_date ? moment(this.invoice_date).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '---'));
    
      __out.push('\n</td>\n<td class="font-14 in-grey-1050 invoice-clickable pointer">\n  ');
    
      __out.push(__sanitize(this.paid_at ? moment(this.paid_at).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '---'));
    
      __out.push('\n</td>\n<td class="font-15 font-bold in-brown-700">\n<div class="settlement-action-column relative"></div>\n</td>\n<td>\n  <div class="relative">\n    <div class="chat-icon-wrapper">\n      <a href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat">\n        <span class="font-30 ');
    
      __out.push(__sanitize(this.unread_messages_count === 0 ? 'icon-chat-empty' : 'icon-chat-filled'));
    
      __out.push('"></span>\n      </a>\n    </div>\n    ');
    
      if (this.unread_messages_count > 0) {
        __out.push('\n    <span class="font-12 font-bold in-white unread-message-count">\n      ');
        __out.push(__sanitize(this.unread_messages_count < 10 ? this.unread_messages_count : '9+'));
        __out.push('\n    </span>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}