import { array, object } from 'yup';

import { TAmount } from '@src/types/common';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';

interface IAddAdjustmentValues {
  adjustmentAmount?: TAmount,
  categorySplits?: ICategorySplit[],
}

const adjustmentValidation = object({
  categorySplits: array().label('Category')
    .test(
      'required',
      'Category is a required field',
      (value: ICategorySplit[] | undefined) => {
        return value !== undefined && value.length > 0;
      },
    ),
});

export {
  IAddAdjustmentValues,
  adjustmentValidation,
};
