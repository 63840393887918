import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import AmountField from './fields/amount_field';
import CheckBoxCollectionField from './fields/check_box_collection_field';
import CheckboxField from './fields/checkbox_field';
import DateField from './fields/date_field';
import MonthSelectField from './fields/month_select_field';
import PercentField from './fields/percent_field';
import RadioCollectionField from './fields/radio_collection_field';
import SelectField from './fields/select_field';
import TextAreaField from './fields/text_area_field';
import TextField from './fields/text_field';

import styles from './styles.module.scss';

interface IFormProps extends
  React.ComponentPropsWithoutRef<'form'>,
  IUIStyleProps
{
  children: React.ReactNode,
  id?: string,
  onSubmit: () => void
}

const Form = ({
  children,
  id,
  onSubmit,
  ...props
}: IFormProps) => {
  const [classes, formProps] = uiStyleProps(
    'form',
    props,
    styles.form,
  );

  return (
    <form
      className={ classes }
      id={ id }
      { ...formProps }
      onSubmit={ onSubmit }
    >
      { children }
    </form>
  );
};

Form.AmountField = AmountField;
Form.CheckboxField = CheckboxField;
Form.DateField = DateField;
Form.MonthSelectField = MonthSelectField;
Form.PercentField = PercentField;
Form.RadioCollectionField = RadioCollectionField;
Form.SelectField = SelectField;
Form.TextAreaField = TextAreaField;
Form.TextField = TextField;
Form.CheckBoxCollectionField = CheckBoxCollectionField;

export {
  IFormProps,
  Form as default,
};
