import React from 'react';

import { useReportContext } from '@src/hooks/contexts/report_context';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import { useResetDefaultMappingModal } from './reset_default_mapping_modal';

const ResetDefaultMappingAction = (): JSX.Element => {
  const report = useReportContext();
  const modal = useResetDefaultMappingModal();

  return (
    <>
      <modal.Component
        { ...modal.props }
        reportId={ report.id }
      />
      <ActionsDropdown.Action
        name="Reset to Default mapping"
        onClick={ modal.open }
      />
    </>
  );
};

export default ResetDefaultMappingAction;
