import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { IAccountBigType } from '@src/types/account_types';
import { TID } from '@src/types/common';

import AddDepartment from '@src/components/common/add_department_modal/add_department';
import {
  AccountBigTypeField,
  AccountSubTypeField,
  AccountTypeCategoryField,
} from '@src/components/common/form_fields/account_types';
import Form from '@src/components/ui/form';

import { addChartOfAccountValidation, IAddChartOfAccountData } from '../schema';

interface IAddChartOfAccountFormProps {
  businessId: TID
  forceAccountType?: IAccountBigType['code'],
  formId: string,
  onSubmit: (data: IAddChartOfAccountData) => void,
}

const showParentCategoryOptions = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' },
];

const AddChartOfAccountForm = ({
  businessId,
  forceAccountType,
  formId,
  onSubmit,
}: IAddChartOfAccountFormProps) => {
  const form = useForm<IAddChartOfAccountData>({
    defaultValues: {
      accountType: forceAccountType,
      hasParent:   'false',
    },
    mode:     'onBlur',
    resolver: yupResolver(addChartOfAccountValidation),
  });

  const accountType = form.watch('accountType');
  const subType = form.watch('subType');
  const hasParent = form.watch('hasParent');

  const { setValue } = form;
  useEffect(() => {
    setValue('subType', undefined);
  }, [accountType, setValue]);
  useEffect(() => {
    setValue('parentId', undefined);
  }, [subType, setValue]);

  const accountTypeControl = useController({ name: 'accountType', control: form.control });
  const subTypeControl = useController({ name: 'subType', control: form.control });
  const parentControl = useController({ name: 'parentId', control: form.control });
  const departmentControl = useController({ name: 'mappingClassIds', control: form.control });

  return (
    <Form id={ formId } onSubmit={ form.handleSubmit(onSubmit) }>
      <AccountBigTypeField
        businessId={ businessId }
        error={ form.formState.errors.accountType?.message }
        isDisabled={ Boolean(forceAccountType) }
        label="Category Type *"
        placeholder="Select Account Type"
        { ...omit(accountTypeControl.field, 'ref') }
      />

      <AccountSubTypeField
        accountType={ accountType }
        businessId={ businessId }
        error={ form.formState.errors.subType?.message }
        label="Detail Type *"
        placeholder="Select Detail Type"
        { ...omit(subTypeControl.field, 'ref') }
      />

      <Form.TextField
        error={ form.formState.errors.name?.message }
        label="Category Name *"
        placeholder="E.g. Food Supplies"
        { ...form.register('name') }
      />

      <Form.TextField
        label="Category Code"
        placeholder="E.g. 124040"
        { ...form.register('number') }
      />

      <Form.RadioCollectionField
        label="Is sub-account *"
        options={ showParentCategoryOptions }
        { ...form.register('hasParent') }
      />

      <div style={ { height: '20px' } } />

      { hasParent === 'true' && (
        <AccountTypeCategoryField
          businessId={ businessId }
          error={ form.formState.errors.parentId?.message }
          label="Parent Category *"
          placeholder="Select Parent Type"
          subType={ subType }
          { ...omit(parentControl.field, 'ref') }
        />
      ) }

      <AddDepartment
        businessId={ businessId }
        { ...omit(departmentControl.field, 'ref') }
      />
    </Form>
  );
};

export default AddChartOfAccountForm;
