module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, item, j, k, l, len, len1, len10, len11, len2, len3, len4, len5, len6, len7, len8, len9, m, month, n, o, p, processor, q, r, ref, ref1, ref10, ref11, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, s, sub_item, t;
    
      if (this.filterType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] || this.filterType === Docyt.Common.Constants.MATCH_TYPES['EXPENSE_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="');
        __out.push(__sanitize(this.filterType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] && this.hideInvoicedatePicker ? 'col-xs-3' : 'col-xs-2'));
        __out.push(' small">\n    <div class="form-group">\n      <div class="transaction-vendors-region"></div>\n    </div>\n  </div>\n  <div class="col-xs-3 small">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref = this.calendarMonth;
        for (i = 0, len = ref.length; i < len; i++) {
          month = ref[i];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  ');
        if (this.filterType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] && !this.hideInvoicedatePicker) {
          __out.push('\n  <div id="invoiceDateGroup" class="col-xs-3 small"></div>\n  ');
        }
        __out.push('\n  <div class="');
        __out.push(__sanitize(this.filterType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] && !this.hideInvoicedatePicker ? 'col-xs-2' : 'col-xs-3'));
        __out.push(' small">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="');
        __out.push(__sanitize(this.filterType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] && this.hideInvoicedatePicker ? 'col-xs-3' : 'col-xs-2'));
        __out.push(' small">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" placeholder="Min $" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  ');
        if (this.filterType === Docyt.Common.Constants.MATCH_TYPES['EXPENSE_TYPE']) {
          __out.push('\n    <div class="col-xs-1 small p-l-0 p-r-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
          __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
          __out.push('"/>\n      </div>\n    </div>\n  ');
        }
        __out.push('\n  ');
        if (this.includeUnverified && !this.isDocument) {
          __out.push('\n    <div class="form-group col-xs-12 small p-l-32">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Transaction Description" value="');
          __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
          __out.push('"/>\n    </div>\n  ');
        }
        __out.push('\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['INCOME_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <select class="processor-selector-js form-control" placeholder="Payment Processor">\n        <option value=""></option>\n        ');
        ref1 = this.globalPaymentProcessors;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          processor = ref1[j];
          __out.push('\n        <option value="');
          __out.push(__sanitize(processor.id));
          __out.push('" ');
          if (this.filter.processor_id === processor.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(processor.name));
          __out.push('</option>\n        ');
        }
        __out.push('\n      </select>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref2 = this.calendarMonth;
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          month = ref2[k];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <select class="type-selector-js form-control" placeholder="Type">\n        <option value=""></option>\n        <option value="revenue" ');
        if (this.revenue_type === 'revenue') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Income</option>\n        <option value="revenue_reversal" ');
        if (this.revenue_type === 'revenue_reversal') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Revenue Reversal</option>\n        <option value="refund" ');
        if (this.revenue_type === 'refund') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Refund</option>\n        <option value="investment" ');
        if (this.revenue_type === 'investment') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Investment</option>\n        <option value="other_income" ');
        if (this.revenue_type === 'other_income') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Other Income</option>\n      </select>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" autocomplete="off" placeholder="$ Min" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" autocomplete="off" placeholder="$ Max" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" autocomplete="off" placeholder="$ Amount" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 p-l-0 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['EQUITY_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref3 = this.calendarMonth;
        for (l = 0, len3 = ref3.length; l < len3; l++) {
          month = ref3[l];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <input type="text" class="form-control" id="transactionType" value="All" readonly autocomplete="off" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false"/>\n    <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="transactionType">\n      ');
        ref4 = this.typeFilterData;
        for (m = 0, len4 = ref4.length; m < len4; m++) {
          item = ref4[m];
          __out.push('\n        ');
          if (item.type === 'all') {
            __out.push('\n          <li><a tabindex="-1" class="transaction-type-filter" data-type="');
            __out.push(__sanitize(item.type));
            __out.push('" data-value="');
            __out.push(__sanitize(item.value));
            __out.push('">');
            __out.push(__sanitize(item.value));
            __out.push('</a></li>\n          <li class="divider"></li>\n        ');
          } else {
            __out.push('\n          <li class="dropdown-submenu">\n            <a tabindex="-1">');
            __out.push(__sanitize(item.value));
            __out.push('</a>\n            <ul class="dropdown-menu col-xs-12">\n              ');
            ref5 = item.sub_items;
            for (n = 0, len5 = ref5.length; n < len5; n++) {
              sub_item = ref5[n];
              __out.push('\n                <li><a tabindex="-1" class="transaction-type-filter" data-type="');
              __out.push(__sanitize(item.type));
              __out.push('" data-subtype="');
              __out.push(__sanitize(sub_item.type));
              __out.push('" data-value="');
              __out.push(__sanitize(sub_item.value));
              __out.push('">');
              __out.push(__sanitize(sub_item.value));
              __out.push('</a></li>\n              ');
            }
            __out.push('\n              <li><a tabindex="-1" class="transaction-type-filter" data-type="');
            __out.push(__sanitize(item.type));
            __out.push('" data-subtype="all" data-value="');
            __out.push(__sanitize(item.value));
            __out.push('">All</a></li>\n            </ul>\n          </li>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </ul>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <input type="text" class="form-control input-category input-category-control typeahead" id="transactionCategory" placeholder="Category" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.category ? this.filter.category.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" autocomplete="off" placeholder="$ Min" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" autocomplete="off" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-2 small">\n    <div class="form-group">\n      <div class="transaction-vendors-region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2 small">\n    <div class="form-group">\n      <input type="text" class="form-control input-category input-category-control typeahead" id="transactionCategory" placeholder="Category" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.category ? this.filter.category.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref6 = this.calendarMonth;
        for (o = 0, len6 = ref6.length; o < len6; o++) {
          month = ref6[o];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 small">\n    <div class="form-group">\n      <input type="text" class="form-control" id="transactionDescription" placeholder="Description" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" placeholder="$ Min" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 p-l-0 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.getAllVisibleTransactions().length > 0 && this.collection.getAllVisibleTransactions().length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref7 = this.calendarMonth;
        for (p = 0, len7 = ref7.length; p < len7; p++) {
          month = ref7[p];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group relative">\n      <div id="from_payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group relative">\n      <div id="to_payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" placeholder="$ Min" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-3 p-l-0 p-r-0">\n    <div class="col-xs-3 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchTransfersFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All Transactions'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-3 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-3 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-3 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.SETTING_TYPES['VENDOR_TYPE']) {
        __out.push('\n  <div class="col-xs-4 small">\n    <div class="form-group">\n      <div class="transaction-vendors-region"></div>\n    </div>\n  </div>\n  <div class="col-xs-4 p-l-0">\n    <div class="form-group">\n      <select class="type-selector-js push-to-qbo form-control" placeholder="Type">\n        <option value="all" selected>All</option>\n        <option value="on">ON</option>\n        <option value="off">OFF</option>\n      </select>\n    </div>\n  </div>\n  <div class="col-xs-4 p-l-0">\n    <div class="form-group">\n      <select class="type-selector-js auto-verify form-control" placeholder="Type">\n        <option value="all" selected>All</option>\n        <option value="on">ON</option>\n        <option value="off">OFF</option>\n      </select>\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['PAYROLL_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.getAllVisibleTransactions().length > 0 && this.collection.getAllVisibleTransactions().length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref8 = this.calendarMonth;
        for (q = 0, len8 = ref8.length; q < len8; q++) {
          month = ref8[q];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="form-group">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" autocomplete="off" placeholder="$ Min" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" autocomplete="off" placeholder="$ Max" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" autocomplete="off" placeholder="$ Amount" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 p-l-0 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['CHARGEBACK_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.getAllVisibleTransactions().length > 0 && this.collection.getAllVisibleTransactions().length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref9 = this.calendarMonth;
        for (r = 0, len9 = ref9.length; r < len9; r++) {
          month = ref9[r];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  <div class="col-xs-4">\n    <div class="form-group">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-3">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" autocomplete="off" placeholder="$ Min" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" autocomplete="off" placeholder="$ Max" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" autocomplete="off" placeholder="$ Amount" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  <div class="col-xs-2 p-l-0 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['LOAN_PAYMENTS_TYPE']) {
        __out.push('\n  <div class="check-all-transaction relative" style="width:30px; padding-left: 8px;">\n    <div class="checkbox pointer checkbox-primary">\n      <input class="pointer" type="checkbox" ');
        __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
        __out.push('>\n      <label class="pointer"></label>\n    </div>\n  </div>\n  <div class="col-xs-2 p-l-0 loan-date-input-group">\n  <div class="input-group transaction-date-input-group">\n    <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="Start Date" autocomplete="off"/>\n    <span class="input-group-addon">-</span>\n    <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="End Date" autocomplete="off"/>\n    <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-caret-down font-14"></span>\n    </a>\n    <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n      <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n      <li class="divider"></li>\n      <li class="dropdown-submenu">\n        <a tabindex="-1" class="">Calendar Month</a>\n        <ul class="dropdown-menu col-xs-12">\n          ');
        ref10 = this.calendarMonth;
        for (s = 0, len10 = ref10.length; s < len10; s++) {
          month = ref10[s];
          __out.push('\n            <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n          ');
        }
        __out.push('\n        </ul>\n      </li>\n      <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n      <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n      <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n      <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n    </ul>\n  </div>\n  </div>\n  <div class="col-xs-2 loan-filter-group">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2 loan-filter-group">\n    <div class="form-group">\n      <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
        __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2 loan-filter-group">\n    <div class="form-group">\n      <input type="text" class="form-control input-principal-category input-category-control typeahead" id="transactionPrincipalCategory" placeholder="Principal Category" value="');
        __out.push(__sanitize(this.filter.principal_category ? this.filter.principal_category.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2 loan-filter-group">\n    <div class="form-group">\n      <input type="text" class="form-control input-interest-category input-category-control typeahead" id="transactionInterestCategory" placeholder="Interest Category" value="');
        __out.push(__sanitize(this.filter.interest_category ? this.filter.interest_category.trim() : ''));
        __out.push('"/>\n    </div>\n  </div>\n  <div class="col-xs-2 loan-filter-group">\n  <div class="input-group transaction-amount-input-group">\n    <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" placeholder="$ Min" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.principal_amount_min ? this.filter.principal_amount_min : ''));
        __out.push('"/>\n    <span class="input-group-addon range-filter hidden">-</span>\n    <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" placeholder="$ Max" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.principal_amount_max ? this.filter.principal_amount_max : ''));
        __out.push('"/>\n    <input class="form-control" id="transactionAmount" placeholder="$ Amount" autocomplete="off" value="');
        __out.push(__sanitize(this.filter.principal_amount ? this.filter.principal_amount : ''));
        __out.push('"/>\n    <a class="dropdown-toggle pointer amount-select-toggle" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-caret-down font-14"></span>\n    </a>\n    <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n      <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n      <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n    </ul>\n  </div>\n  </div>\n  <div class="col-xs-2 p-r-0">\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-4 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="verifiedFilter" autocomplete="off" readonly value="');
        __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
        __out.push('"/>\n      </div>\n    </div>\n    <div id="qboFilterGroup" class="col-xs-4 p-l-0">\n    </div>\n  </div>\n');
      } else {
        __out.push('\n  ');
        if (this.filterType !== Docyt.Common.Constants.TRANSACTION_TYPES['FLAGGED_TYPE']) {
          __out.push('\n    <div class="check-all-transaction-group relative sortable" style="width:30px; padding-left: 8px;">\n      <a class="dropdown-toggle" type="button" id="check-all-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <div class="checkbox pointer checkbox-primary">\n          <input class="pointer" type="checkbox" ');
          __out.push(__sanitize(this.collection.length > 0 && this.collection.length === this.collection.getSelectedTransactions().length ? 'checked' : ''));
          __out.push('>\n          <label class="pointer"></label>\n        </div>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-left font-24" role="menu" aria-labelledby="check-all-action-dropdown-btn">\n        <li class="pointer select-all-expense">\n          <div class="checkbox m-l-10 m-t-5 m-b-0 checkbox-primary"><input class="pointer" type="checkbox" ');
          __out.push(__sanitize(this.collection.selectionType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE'] ? 'checked' : ''));
          __out.push('><label></label></div>\n          <p>Select All Expense Transactions</p>\n        </li>\n        <li class="pointer select-all-income">\n          <div class="checkbox m-l-10 m-t-5 m-b-0 checkbox-primary"><input class="pointer" type="checkbox" ');
          __out.push(__sanitize(this.collection.selectionType === Docyt.Common.Constants.TRANSACTION_TYPES['INCOME_TYPE'] ? 'checked' : ''));
          __out.push('><label></label></div>\n          <p>Select All Income Transactions</p>\n        </li>\n        <li class="pointer select-all">\n          <div class="checkbox m-l-10 m-t-5 m-b-0 checkbox-primary"><input class="pointer" type="checkbox" ');
          __out.push(__sanitize(this.collection.selectionType === Docyt.Common.Constants.TRANSACTION_TYPES['ALL_TYPE'] ? 'checked' : ''));
          __out.push('><label></label></div>\n          <p>Select All Transactions</p>\n        </li>\n      </ul>\n    </div>\n  ');
        }
        __out.push('\n  <div class="col-xs-2">\n    <div class="input-group transaction-date-input-group">\n      <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n      <a class="dropdown-toggle pointer" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n        <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n        <li class="divider"></li>\n        <li class="dropdown-submenu">\n          <a tabindex="-1" class="">Calendar Month</a>\n          <ul class="dropdown-menu col-xs-12">\n            ');
        ref11 = this.calendarMonth;
        for (t = 0, len11 = ref11.length; t < len11; t++) {
          month = ref11[t];
          __out.push('\n              <li><a tabindex="-1" data-id="');
          __out.push(__sanitize(this.calendarMonth.indexOf(month)));
          __out.push('" class="date-calendar-month">');
          __out.push(__sanitize(month.format('MMM Y')));
          __out.push('</a></li>\n            ');
        }
        __out.push('\n          </ul>\n        </li>\n        <li><a tabindex="-1" class="date-ytd">Year to Date (YTD)</a></li>\n        <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n        <li><a tabindex="-1" class="date-qtd">Quarter to Date (QTD)</a></li>\n        <li><a tabindex="-1" class="date-last-year">Last Year</a></li>\n      </ul>\n    </div>\n  </div>\n  ');
        if (this.filterType !== Docyt.Common.Constants.TRANSACTION_TYPES['FLAGGED_TYPE']) {
          __out.push('\n    <div class="col-xs-2">\n      <div class="form-group">\n        <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
          __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
          __out.push('"/>\n      </div>\n    </div>\n  ');
        } else {
          __out.push('\n    <div class="col-xs-4">\n      <div class="form-group">\n        <input type="text" class="form-control transaction-description-picker" id="transactionDescription" placeholder="Description" value="');
          __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
          __out.push('"/>\n      </div>\n    </div>\n  ');
        }
        __out.push('\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div class="transaction-vendors-region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="form-group">\n      <div id="payment_account_filter_region"></div>\n    </div>\n  </div>\n  <div class="col-xs-2">\n    <div class="input-group transaction-amount-input-group">\n      <input type="text" class="form-control range-filter hidden" id="transactionMinAmount" autocomplete="off" placeholder="$ Min" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.gte : ''));
        __out.push('"/>\n      <span class="input-group-addon range-filter hidden">-</span>\n      <input type="text" class="form-control range-filter hidden" id="transactionMaxAmount" autocomplete="off" placeholder="$ Max" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.lte : ''));
        __out.push('"/>\n      <input class="form-control" id="transactionAmount" autocomplete="off" placeholder="$ Amount" value="');
        __out.push(__sanitize(this.filter.amount ? this.filter.amount.val : ''));
        __out.push('"/>\n      <a class="dropdown-toggle pointer" type="button" id="amount-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="amount-select-menu-btn">\n        <li class="filter-by-amount-js"><a tabindex="-1">Filter by Exact Amount</a></li>\n        <li class="filter-by-range-js"><a tabindex="-1">Filter by Range</a></li>\n       </ul>\n    </div>\n  </div>\n  ');
        if (this.filterType === Docyt.Common.Constants.TRANSACTION_TYPES['FLAGGED_TYPE']) {
          __out.push('\n    <div class="col-xs-2 p-l-0">\n      <div class="form-group">\n        <div class="flagged-state-region"></div>\n      </div>\n    </div>\n  ');
        } else {
          __out.push('\n    <div class="col-xs-2 p-l-0">\n      <div class="form-group">\n        <input type="text" class="form-control typeahead" id="matchExpensesFilter" autocomplete="off" readonly value="');
          __out.push(__sanitize(this.filter.abc ? this.filter.abc.trim() : 'All'));
          __out.push('"/>\n      </div>\n    </div>\n    <div class="col-xs-2">\n      <div class="filter-link">\n        <a class="pointer reset-transactions-filter">Reset</a>\n      </div>\n    </div>\n  ');
        }
        __out.push('\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}