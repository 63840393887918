import React, { FC } from 'react';

import { ISimpleChartOfAccount } from '@src/types/simple_chart_of_accounts';

import DeleteBtn from '@src/components/chart_of_accounts/delete_btn';
import EditBtn from '@src/components/chart_of_accounts/edit_btn';

interface Props {
  chartOfAccount: ISimpleChartOfAccount
}

const Component: FC<Props> = ({ chartOfAccount }) => {
  return (
    <>
      <EditBtn businessId={ chartOfAccount.businessId } id={ chartOfAccount.id } />
      <DeleteBtn businessId={ chartOfAccount.businessId } chartOfAccount={ chartOfAccount } />
    </>
  );
};

export default Component;
