import React from 'react';

import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import ItemActions from './item_actions';
import TrashItemActions from './trash_item_actions';

interface IBusinessInboxListItemActionsProps {
  document: IInboxServiceDocument,
}

const BusinessInboxListItemActions = ({
  document,
}: IBusinessInboxListItemActionsProps): JSX.Element => {
  return (
    <ActionsDropdown>
      {
        document.deletedAt
          ? <TrashItemActions document={ document } />
          : <ItemActions document={ document } />
      }
    </ActionsDropdown>
  );
};

export default BusinessInboxListItemActions;
