import React, { memo } from 'react';

import classNames from 'classnames';

import { IRenderChannelPreviewProps } from '../../types';

import styles from '../../styles.module.scss';

interface IChannelListItem extends IRenderChannelPreviewProps {
    currentChannelUrl?: string;
}

const ChannelListItem = (
  {
    channel,
    currentChannelUrl,
  }
  :IChannelListItem,
) => {
  const chatItemParent = classNames(styles['chat-list-item'], {
    active: currentChannelUrl === channel?.url,
  });

  return (
    <div className={ chatItemParent }>
      <span>{channel?.name}</span>
      {channel?.unreadMessageCount > 0 && (
      <span className={ styles['unread-message-count'] }>
        {channel?.unreadMessageCount}
      </span>
      )}
    </div>
  );
};

export default memo(ChannelListItem);
