module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!this.sunsetClientCentralEnabled) {
        __out.push('\n  ');
        if (!this.isOnboarding) {
          __out.push('\n    <div class="item-divider display-flex justify-content-between">\n      ');
          if (this.canCreate) {
            __out.push('\n      <div class="m-t-20 m-b-23 flex-1">\n        <h1 class="font-24 font-bold m-t-0 m-b-0">');
            __out.push(__sanitize(this.business.display_name));
            __out.push('</h1>\n        <span class="font-12">');
            __out.push(__sanitize(this.business.legal_name));
            __out.push('</span>\n      </div>\n      ');
          } else {
            __out.push('\n      <h1 class="font-24 font-bold m-t-20 m-b-23 flex-1">');
            __out.push(__sanitize(this.business.legal_name));
            __out.push('</h1>\n      ');
          }
          __out.push('\n      <div class="display-flex align-items-center">\n        <span class="m-r-10 in-black">Status');
          if (!this.canCreate) {
            __out.push(__sanitize(':'));
          }
          __out.push('</span>\n        <div class="status-dropdown-wrapper relative">\n          <button class="btn dropdown-toggle btn-status-toggle ');
          if (!this.canCreate) {
            __out.push(__sanitize('disabled'));
          }
          __out.push('" data-toggle="dropdown">\n            <span class="status-name status-name-js">Active</span>\n            <i class="fa fa-caret-down font-12"></i>\n          </button>\n          <ul class="dropdown-menu">\n            <li class="dropdown-item in-blue-650 onboarding-item-js">Onboarding</li>\n            <li class="dropdown-item in-green-1000 in-progress-item-js">Active</li>\n            <li class="dropdown-item in-red-600 blocked-item-js">Blocked</li>\n          </ul>\n        </div>\n      </div>\n    </div>\n  ');
        }
        __out.push('\n');
      }
    
      __out.push('\n');
    
      if (!this.isOnboarding) {
        __out.push('\n  ');
        if (this.business.has_qbo_connection_history && !this.business.qbo_connected) {
          __out.push('\n    <div class="qbo-status-label text-left display-flex align-items-center m-t-10">\n      <div class="m-l-10 qbo-status-icon-column"></div>\n      <label class="report-status-label m-b-0"> ');
          __out.push(__sanitize(I18n.t('management.qbo_disconnected', {
            business_name: this.business.display_name
          })));
          __out.push(' </label>\n      <a class=\'m-l-auto m-r-20 pointer reconnect-qbo-js\'>Reconnect</a>\n    </div>\n  ');
        }
        __out.push('\n');
      }
    
      __out.push('\n<div id="bookkeeping-blocked-info-region"></div>\n<div class="bookkeeping-status-container">\n  <div id="bookkeeping-statuses-region"></div>\n  <div id="flagged-documents-region"></div>\n</div>\n<div id="document-requests-region"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}