import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import ButtonToggle from './dropdown_button_toggle';
import Item from './dropdown_item';
import LinkToggle from './dropdown_link_toggle';
import Menu from './dropdown_menu';
import Submenu from './dropdown_submenu';

interface IDropdownProps extends React.ComponentPropsWithoutRef<'div'>, IUIStyleProps {}

const Dropdown = ({
  children,
  ...props
}: IDropdownProps): JSX.Element => {
  const [classes, divProps] = uiStyleProps('dropdown', props, 'btn-group');

  return (
    <div className={ classes } { ...divProps }>
      { children }
    </div>
  );
};

Dropdown.Item = Item;
Dropdown.LinkToggle = LinkToggle;
Dropdown.ButtonToggle = ButtonToggle;
Dropdown.Menu = Menu;
Dropdown.Submenu = Submenu;

export default Dropdown;
