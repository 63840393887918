import { TTextColumn, TModel } from './types';

const CollectionTableTextColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TTextColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableTextColumn;
