import React, { useCallback, useMemo, useEffect } from 'react';

import { useAccountSubTypes } from '@src/hooks/queries/account_types';
import { IAccountBigType, IAccountSubType } from '@src/types/account_types';
import { TID } from '@src/types/common';

import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';
import ReactSelectInput, { IReactSelectInputProps } from '@src/components/ui/form/inputs/react_select/react_select_input';

interface IAccountSubTypeFieldProps extends
  Omit<
    IReactSelectInputProps<IAccountSubType, false>,
    'isMulti' | 'options' | 'value' | 'onChange'
  >,
  Omit<IFormFieldWrapperProps, 'children'>
{
  accountType?: IAccountBigType['code']
  businessId: TID;
  value?: IAccountSubType['subtypeCode'],
  onChange: (value?: IAccountSubType['subtypeCode']) => void,
}

const getOptionLabel = (type: IAccountSubType) => type.subtypeName;
const getOptionValue = (type: IAccountSubType) => type.subtypeCode;

const AccountSubTypeField = ({
  accountType,
  businessId,
  error,
  hideLabel,
  hint,
  label,
  mark,
  value,
  onChange,
  ...selectProps
}: IAccountSubTypeFieldProps): JSX.Element => {
  const query = useAccountSubTypes({ businessId, code: accountType });
  const accountTypes = useMemo(() => {
    return query.data?.subTypes ?? [];
  }, [query.data]);

  useEffect(() => {
    if (!value) onChange(accountTypes[0]?.subtypeCode);
  }, [accountTypes, onChange, value]);

  const selectedAccountType = useMemo(() => {
    if (!value) return accountTypes[0];

    return accountTypes.find((at) => at.subtypeCode === value);
  }, [value, accountTypes]);

  const handleChange = useCallback((at: IAccountSubType | null) => {
    onChange(at?.subtypeCode);
  }, [onChange]);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <ReactSelectInput<IAccountSubType, false>
        isSearchable
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        isDisabled={ !accountType }
        options={ accountTypes }
        widthPercent={ 100 }
        { ...selectProps }
        value={ selectedAccountType }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

AccountSubTypeField.displayName = 'AccountSubTypeField';

export default AccountSubTypeField;
