import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateBusinessProfile } from '@src/hooks/queries/businesses';

import BusinessProvider from '@src/components/providers/business_provider';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './edit_profile_modal_form';
import { IBusinessProfileInput } from './schema';

interface IEditProfileModalProps extends IUseModalProps {
  businessId: number,
}

const EditProfileModal = ({
  businessId,
  isOpen,
  onCancel,
  onDone,
}: IEditProfileModalProps) => {
  const updateProfile = useUpdateBusinessProfile();

  const { mutate } = updateProfile;
  const handleSubmit = useCallback((values: IBusinessProfileInput) => {
    mutate(
      {
        businessId,
        business: values,
      },
      {
        onSuccess: () => {
          window.Docyt.vent.trigger('business_profile:update:success');
          onDone();
        },
      },
    );
  }, [onDone, mutate, businessId]);

  return (
    <>
      <MutationStatus mutation={ updateProfile } successMessage="Updated profile successfully." />
      <Modal.Form
        formId="edit_profile_form"
        proceedTitle="Save"
        show={ isOpen }
        title="Edit Business Profile"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <div className="business_profile_modal_container">
            <BusinessProvider businessId={ businessId }>
              <Form
                formId={ formId }
                onSubmit={ handleSubmit }
              />
            </BusinessProvider>
          </div>
        )}
      </Modal.Form>
    </>
  );
};

const useEditProfileModal = makeUseModal(EditProfileModal);

export {
  IEditProfileModalProps,
  useEditProfileModal,
  EditProfileModal as default,
};
