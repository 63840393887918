import React from 'react';

const MetricDetailsList = (): JSX.Element => {
  return (
    <div>
      {/* Metric content here */}
    </div>
  );
};

export default MetricDetailsList;
