module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var budget, filter, forecast, i, j, k, len, len1, len2, ref, ref1, ref2;
    
      __out.push('<div class="main-wrapper width-100-percent">\n  <div class="dropdown-toggle permission-dropdown-control column-select-menu-btn" id="column-setting-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <span>Customize</span>\n    <span class="fa fa-caret-down font-14"></span>\n  </div>\n  <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="column-setting-dropdown-btn">\n    ');
    
      ref = this.reportFilters;
      for (i = 0, len = ref.length; i < len; i++) {
        filter = ref[i];
        __out.push('\n      ');
        if (this.showFilter(filter)) {
          __out.push('\n        ');
          if (filter.id === "budgets_comparison") {
            __out.push('\n          <li class="font-14 dropdown-submenu">\n            <div class="checkbox pointer checkbox-primary dropdown-item dropdown-submenu-owner-item">\n              <input class="pointer" type="checkbox" id=\'checkbox-budget-comparison\' ');
            if (this.selectedBudgetId) {
              __out.push('checked');
            }
            __out.push('>\n              <label for=\'checkbox-budget-comparison\' class="pointer disabled">');
            __out.push(__sanitize(filter.name));
            __out.push('</label>\n            </div>\n            <ul class="dropdown-menu filter-dropdown-menu">\n              ');
            ref1 = this.budgets;
            for (j = 0, len1 = ref1.length; j < len1; j++) {
              budget = ref1[j];
              __out.push('\n              <li>\n                <div class="dropdown-item checkbox pointer checkbox-primary">\n                  <input class="pointer budget-item" type="checkbox" id="checkbox-budget-');
              __out.push(__sanitize(budget.id));
              __out.push('" ');
              if (this.selectedBudgetId === budget.id) {
                __out.push('checked');
              }
              __out.push(' data-id="');
              __out.push(__sanitize(budget.id));
              __out.push('">\n                  <label for="checkbox-budget-');
              __out.push(__sanitize(budget.id));
              __out.push('" class="pointer">');
              __out.push(__sanitize(budget.name));
              __out.push(' - ');
              __out.push(__sanitize(budget.year));
              __out.push('</label>\n                </div>\n              </li>\n              ');
            }
            __out.push('\n            </ul>\n          </li>\n        ');
          } else if (filter.id === "forecast_comparison") {
            __out.push('\n          <li class="font-14 dropdown-submenu" style="border-top: none;">\n            <div class="checkbox pointer checkbox-primary dropdown-item dropdown-submenu-owner-item">\n              <input class="pointer" type="checkbox" id=\'checkbox-forecast-comparison\' ');
            if (this.selectedForecastId) {
              __out.push('checked');
            }
            __out.push('>\n              <label for=\'checkbox-forecast-comparison\' class="pointer disabled">');
            __out.push(__sanitize(filter.name));
            __out.push('</label>\n            </div>\n            <ul class="dropdown-menu filter-dropdown-menu">\n              ');
            ref2 = this.forecasts;
            for (k = 0, len2 = ref2.length; k < len2; k++) {
              forecast = ref2[k];
              __out.push('\n              <li>\n                <div class="dropdown-item checkbox pointer checkbox-primary">\n                  <input class="pointer forecast-item" type="checkbox" id="checkbox-forecast-');
              __out.push(__sanitize(forecast.id));
              __out.push('" ');
              if (this.selectedForecastId === forecast.id) {
                __out.push('checked');
              }
              __out.push(' data-id="');
              __out.push(__sanitize(forecast.id));
              __out.push('">\n                  <label for="checkbox-forecast-');
              __out.push(__sanitize(forecast.id));
              __out.push('" class="pointer">');
              __out.push(__sanitize(forecast.name));
              __out.push(' - ');
              __out.push(__sanitize(forecast.year));
              __out.push('</label>\n                </div>\n              </li>\n              ');
            }
            __out.push('\n            </ul>\n          </li>\n        ');
          } else {
            __out.push('\n          <li>\n            <div class="dropdown-item checkbox pointer checkbox-primary">\n              <input class="pointer" type="checkbox" id=\'');
            __out.push(__sanitize(filter.id));
            __out.push('\' ');
            if (this.columnFilters[filter.id]) {
              __out.push('checked');
            }
            __out.push(' />\n              <label for=\'');
            __out.push(__sanitize(filter.id));
            __out.push('\' class="pointer">');
            __out.push(__sanitize(filter.name));
            __out.push(' </label>\n            </div>\n          </li>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </ul>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}