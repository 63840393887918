module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="flex-wrapper relative m-t-5" style="width: 100px;">\n  <div class="checkbox checkbox-primary m-l-15 m-r-15">\n    <input type="checkbox" id="related-document-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" checked class="check-related-document pointer">\n    <label for="related-document-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n  <span class="icon-inbox-document font-36"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n</td>\n<td class="flex-wrapper relative" style="width:250px;">\n  <div>\n    <p class="font-16 font-semi-bold m-l-15 m-t-0 m-b-0">\n      ');
    
      __out.push(__sanitize(this.original_file_name || this.original_file_key));
    
      __out.push('\n    </p>\n    <p class="font-16 font-semi-bold m-l-15 m-t-0 m-b-0 text-muted">\n      ');
    
      __out.push(__sanitize(this.filesize ? this.filesize : '-'));
    
      __out.push('\n    </p>\n  <div>\n</td>\n<td class="inbox-created-at-column relative m-t-5 text-right">\n  <a class=\'cancel cancel-link pointer remove-document-btn m-t-5 pull-left font-16 font-semi-bold\'>Remove</a>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}