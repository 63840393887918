import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { IManagementGroup } from '@src/types/management_groups';

import ManagementGroupInput from '@src/components/common_v2/inputs/management_group_input';
import Wrapper, { IFormFieldWrapperProps } from '@src/components/ui/form/fields/form_field_wrapper';
import { IReactSelectInputProps } from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface IManagementGroupFieldProps extends
  Omit<
    IReactSelectInputProps<IManagementGroup, false>,
    'isMulti' | 'options' | 'value' | 'onChange'
  >,
  Omit<IFormFieldWrapperProps, 'children'>
{
  value?: IManagementGroup['id'],
  onChange: (value?: IManagementGroup['id'] | null) => void,
}

const ManagementGroupField = ({
  error,
  hideLabel,
  hint,
  label,
  mark,
  value,
  onChange,
}: IManagementGroupFieldProps): JSX.Element => {
  const handleChange = useCallback((v: TID | null) => {
    onChange(v === 0 ? undefined : v);
  }, [onChange]);

  return (
    <Wrapper error={ error } hideLabel={ hideLabel } hint={ hint } label={ label } mark={ mark }>
      <ManagementGroupInput
        className="management-group-picker"
        value={ value }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

ManagementGroupField.displayName = 'ManagementGroupField';

export default ManagementGroupField;
