module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!this.client_advanced_mode) {
        __out.push('\n<div class="document-field-item document-field-name">');
        __out.push(__sanitize(this.name));
        __out.push('</div>\n');
      }
    
      __out.push('\n<a class="pointer line-height-26" id="split-account-dropdown-btn">\n  ');
    
      if (this.serviceDocumentSplits.length !== 1) {
        __out.push('\n    ');
        __out.push(__sanitize(this.serviceDocumentSplits.length));
        __out.push(' Splits\n  ');
      } else {
        __out.push('\n    1 Split\n  ');
      }
    
      __out.push('\n</a>\n<div class="dropdown-menu dropdown-menu-right p-t-10 p-b-10" id=\'chart-of-account-split-region\'>\n</div>\n<div class="fade in modal-backdrop z-index-1-important"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}