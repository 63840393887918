import React from 'react';

import { makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IMessageModalProps {
  children: () => React.ReactNode,
  title: string,
  isOpen: boolean,
  onDone: () => void,
  onCancel: () => void,
}

const MessageModal = ({
  children,
  title,
  isOpen,
  onDone,
  onCancel,
}: IMessageModalProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        { isOpen && children() }
      </Modal.Body>
      <Modal.Footer>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <Button
            bsSize="xlarge"
            bsStyle="link"
            fontSize={ 16 }
            title="Cancel"
            onClick={ onCancel }
          />
          <Button
            bsSize="xlarge"
            bsStyle="primary"
            fontSize={ 16 }
            title="Calculate"
            onClick={ onDone }
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const useMessageModal = makeUseModal(MessageModal);

export {
  IMessageModalProps,
  MessageModal,
  useMessageModal as default,
};
