import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { ITransactionServiceDocument, TActionType } from '@src/types/transaction_service_documents';

import Modal from '@src/components/ui/modal';

import DeleteRestoreForm from './action_form';

interface IDeleteRestoreModalProps extends IUseModalProps {
  actionType : TActionType,
  transaction: ITransactionServiceDocument,
}

const DeleteRestoreModal = ({
  actionType,
  transaction,
  isOpen,
  onCancel,
  onDone,
}: IDeleteRestoreModalProps): JSX.Element => {
  return (
    <Modal.Standard
      danger={ actionType !== 'restore' }
      dialogClassName="delete-transactions-modal"
      proceedTitle={ actionType === 'restore' ? 'Restore' : 'Delete' }
      show={ isOpen }
      title={ actionType === 'restore' ? 'Restore Transaction' : 'Delete Transaction' }
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => (
        <DeleteRestoreForm
          actionType={ actionType }
          transaction={ transaction }
        />
      )}
    </Modal.Standard>
  );
};

const useDeleteRestoreActionModal = makeUseModal(DeleteRestoreModal);

export {
  IDeleteRestoreModalProps,
  DeleteRestoreModal,
  useDeleteRestoreActionModal as default,
};
