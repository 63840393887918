import React, { useCallback } from 'react';

import { useDeleteNoRevenueDate } from '@src/hooks/queries/no_revenue_dates';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { useRevenueSystemContext } from '@src/components/revenue_center/revenue_system_provider';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { CrossIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IUndoMarkNoRevenueDayActionProps {
  report: IRevenueServiceDocument,
}

const UndoMarkNoRevenueDayAction = ({
  report,
}: IUndoMarkNoRevenueDayActionProps) => {
  const revenueSystem = useRevenueSystemContext();
  const mutation = useDeleteNoRevenueDate();
  const { mutate } = mutation;

  const handleOnClick = useCallback((e) => {
    e.stopPropagation();

    mutate(
      {
        revenueSystemId: revenueSystem.id,
        date:            report.startDate,
      },
    );
  }, [report, mutate, revenueSystem.id]);

  return (
    <>
      <MutationStatus mutation={ mutation } />
      <ActionsDropdown.Action
        icon={ <CrossIcon fontSize={ 20 } /> }
        title='Undo "No Revenue Day"'
        onClick={ handleOnClick }
      />
    </>
  );
};

export default UndoMarkNoRevenueDayAction;
