import { IChartOfAccount, IChartOfAccountData } from '@src/types/chart_of_accounts';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetAllChartOfAccountsForBusinessParams {
  businessId: TID,
  filter?: object,
  search?: string,
  page?: number,
  orderColumn?: string,
  orderDirection?: TOrderDirection,
  includeMappedClasses?: boolean,
  withDocumentCounts?: boolean
  chartOfAccountIds?: string[],
  onlyUnlinked?: boolean,
}

const getAllChartOfAccountsForBusiness = (
  params: IGetAllChartOfAccountsForBusinessParams,
): Promise<IChartOfAccount[]> => {
  return apiGet(
    '/api/v1/chart_of_accounts',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata.chartOfAccounts as IChartOfAccount[];
  });
};

interface IGetChartOfAccountParams {
  id?: TID,
  businessId: TID,
}

interface IGetChartOfAccountResponse {
  chartOfAccount: IChartOfAccount,
}

const getChartOfAccount = (
  params: IGetChartOfAccountParams,
): Promise<IGetChartOfAccountResponse> => {
  return apiGet(
    `/api/v1/chart_of_accounts/${params?.id}`,
    underscoreKeys(params),
  ).then((data) => {
    return {
      chartOfAccount: camelizeKeys(data.chart_of_account),
    } as IGetChartOfAccountResponse;
  });
};

interface IGetChartOfAccountsByIdsParams {
  chartOfAccountIds: TID[]
}

const getChartOfAccountsByIds = (
  params: IGetChartOfAccountsByIdsParams,
): Promise<IChartOfAccount[]> => {
  return apiPost(
    '/api/v1/chart_of_accounts/by_ids',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata.chartOfAccounts as IChartOfAccount[];
  });
};

interface ICreateChartOfAccountParams {
  businessId: TID,
  chartOfAccount: IChartOfAccountData,
}

interface ICreateChartOfAccountResponse {
  chartOfAccount: IChartOfAccount,
}

const createChartOfAccount = (
  params: ICreateChartOfAccountParams,
): Promise<ICreateChartOfAccountResponse> => {
  return apiPost('/api/v1/chart_of_accounts', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as ICreateChartOfAccountResponse);
};

export {
  ICreateChartOfAccountParams,
  ICreateChartOfAccountResponse,
  IGetAllChartOfAccountsForBusinessParams,
  IGetChartOfAccountsByIdsParams,
  IGetChartOfAccountParams,
  IGetChartOfAccountResponse,
  getAllChartOfAccountsForBusiness,
  getChartOfAccountsByIds,
  createChartOfAccount,
  getChartOfAccount,
};
