import React, { useState, useEffect } from 'react';

import axios from 'axios';

import styles from './styles.module.scss';

const ErrorNoti: React.FC<{ error: Error | null, duration?: number, title: string }> =
  ({ error, duration = 5000, title }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [hovering, setHovering] = useState(false);

    const defaultMsg = ['An unexpected error occurred'];
    const messages: string[] = axios.isAxiosError(error)
      ? (error?.response?.data?.errors || defaultMsg) : defaultMsg;

    useEffect(() => {
      let timer: number;

      if (isVisible && !hovering) {
        timer = window.setTimeout(() => setIsVisible(false), duration);
      }

      return () => clearTimeout(timer);
    }, [isVisible, hovering, duration]);

    if (!isVisible || !error) return null;

    return (
      <div
        className={ `toast-top-center ${styles['error-msg-container']}` }
        id="toast-container"
        onMouseEnter={ () => setHovering(true) }
        onMouseLeave={ () => setHovering(false) }
      >
        <div aria-live="assertive" className="toast toast-error">
          <div className="toast-title">{ title }</div>
          <div className="toast-message">
            {Object.values(messages).map((msg) => (
              <p key={ msg }>{msg}</p>
            ))}
          </div>
        </div>
      </div>
    );
  };

export default ErrorNoti;
