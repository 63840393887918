module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.hideSearchIcon) {
        __out.push('\n<input type="text" class="');
        __out.push(__sanitize(this.addClassName));
        __out.push(' input-vendor typeahead " placeholder="');
        __out.push(__sanitize(this.placeholder));
        __out.push('" autocomplete="off" value="');
        __out.push(__sanitize(this.vendorName));
        __out.push('"/>\n');
      } else {
        __out.push('\n<div class="vendor__typehead-wrapper">\n  <i class="vendor__typehead-icon icon icon-search"></i>\n  <input type="text" class="vendor__typehead-input ');
        __out.push(__sanitize(this.addClassName));
        __out.push(' input-vendor typeahead " placeholder="');
        __out.push(__sanitize(this.placeholder));
        __out.push('" autocomplete="off" value="');
        __out.push(__sanitize(this.vendorName));
        __out.push('"  ');
        if (this.isDisabled) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('/>\n</div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}