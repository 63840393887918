import React, { useCallback, useMemo, useReducer, useRef } from 'react';

import { TBulkMutation, IBulkMutationReturn } from '@src/hooks/queries/bulk_mutations';

import BulkActionModal, { IBulkActionModalProps } from './bulk_action_modal';

interface IBulkActionStatus<TRequestParams, TRequestResponse> {
  failedCount: number,
  status: 'init' | 'in_progress' | 'finished',
  successCount: number,
  totalCount: number,
  errors: [params: TRequestParams, error: string][],
  responses: [params: TRequestParams, response: TRequestResponse][],
}

type TBulkActionStatusAction<TRequestParams, TRequestResponse> = {
  type: 'close',
} | {
  type: 'start',
  totalCount: number,
} | {
  type: 'succeed_step',
  params: TRequestParams,
  response: TRequestResponse,
} | {
  type: 'fail_step',
  params: TRequestParams,
  error: Error,
} | {
  type: 'finish_mutation',
}

const initialState = <TRequestParams, TRequestResponse>(
): IBulkActionStatus<TRequestParams, TRequestResponse> => ({
    errors:       [],
    failedCount:  0,
    responses:    [],
    status:       'init',
    successCount: 0,
    totalCount:   0,
  });

const statusReducer = <TRequestParams, TRequestResponse>(
  state: IBulkActionStatus<TRequestParams, TRequestResponse>,
  action: TBulkActionStatusAction<TRequestParams, TRequestResponse>,
): IBulkActionStatus<TRequestParams, TRequestResponse> => {
  switch (action.type) {
    case 'close':
      return initialState();
    case 'start':
      return {
        ...initialState(),
        status:     'in_progress',
        totalCount: action.totalCount,
      };
    case 'succeed_step':
      return {
        ...state,
        responses: [
          ...state.responses,
          [action.params, action.response],
        ],
        successCount: state.successCount + 1,
      };
    case 'fail_step':
      return {
        ...state,
        failedCount: state.failedCount + 1,
        errors:      [
          ...state.errors,
          [action.params, action.error.message],
        ],
      };
    case 'finish_mutation':
      return {
        ...state,
        status: 'finished',
      };

    // no default
  }

  return state;
};

interface IUseBulkActionModalParams<TRequestParams, TRequestResponse> {
  closeOnSuccess?: boolean,
  mutation: TBulkMutation<TRequestParams, TRequestResponse>,
  onCancel?: () => void,
  onDone?: () => void,
}

interface IUseBulkActionModalReturn<TRequestParams, TRequestResponse> {
  Component: typeof BulkActionModal,
  props: Omit<IBulkActionModalProps<TRequestParams, TRequestResponse>, 'itemsTitle' | 'title'>,
  runMutation: (params: TRequestParams[]) => Promise<IBulkMutationReturn<TRequestResponse>>,
}

type TStatusReducer<TRequestParams, TRequestResponse> =
  React.Reducer<IBulkActionStatus<TRequestParams, TRequestResponse>,
    TBulkActionStatusAction<TRequestParams, TRequestResponse>>;

const useBulkActionModal = <TRequestParams, TRequestResponse = void>(
  {
    closeOnSuccess,
    mutation,
    onCancel,
    onDone,
  }: IUseBulkActionModalParams<TRequestParams, TRequestResponse>,
): IUseBulkActionModalReturn<TRequestParams, TRequestResponse> => {
  const [status, dispatch] = useReducer<TStatusReducer<TRequestParams, TRequestResponse>>(
    statusReducer,
    initialState<TRequestParams, TRequestResponse>(),
  );
  const cancelRef = useRef<boolean>(false);

  const { mutateAsync } = mutation;
  const handleRunMutation = useCallback((mutationParams: TRequestParams[]) => {
    dispatch({ type: 'start', totalCount: mutationParams.length });
    cancelRef.current = false;

    return mutateAsync(
      {
        params:          mutationParams,
        onStepProcessed: (response: TRequestResponse, params: TRequestParams) => {
          if (cancelRef.current) throw new Error('Canceled by user');

          dispatch({ type: 'succeed_step', params, response });
        },
        onStepFailed: (error: Error, params: TRequestParams) => {
          if (cancelRef.current) throw new Error('Canceled by user');

          dispatch({ type: 'fail_step', params, error });
        },
      },
      {
        onSuccess: (result) => {
          dispatch({ type: 'finish_mutation' });
          if (closeOnSuccess && result.errors.length === 0) {
            dispatch({ type: 'close' });
          }
        },
      },
    );
  }, [mutateAsync, closeOnSuccess]);

  const handleCancel = useCallback(() => {
    dispatch({ type: 'close' });
    cancelRef.current = true;

    if (onCancel) onCancel();
  }, [onCancel]);

  const handleDone = useCallback(() => {
    dispatch({ type: 'close' });

    if (onDone) onDone();
  }, [onDone]);

  const props = useMemo(() => ({
    isOpen:   status.status !== 'init',
    status,
    onCancel: handleCancel,
    onDone:   handleDone,
  }), [status, handleCancel, handleDone]);

  return useMemo(() => ({
    Component:   BulkActionModal,
    runMutation: handleRunMutation,
    props,
  }), [handleRunMutation, props]);
};

export {
  IBulkActionStatus,
  useBulkActionModal,
};
