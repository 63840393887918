import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ConfigureIcon } from '@src/components/utils/icomoon';

interface ConfigureIntegrationActionProps {
  externalSystemId: TID,
}

const ConfigureIntegrationAction = ({
  externalSystemId,
}: ConfigureIntegrationActionProps) => {
  const business = useBusinessContext();

  return (
    <ActionsDropdown.LinkAction
      href={ `/settings/integrations/${business.id}/external_systems/${externalSystemId}` }
      icon={ <ConfigureIcon fontSize={ 18 } /> }
      title="Configure"
    />
  );
};

export default React.memo(ConfigureIntegrationAction);
