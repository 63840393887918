import { apiGet } from '@src/requests/helpers';
import { ISubscription } from '@src/types/subscriptions';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

interface IGetSubscriptionsByBusinessIdsParams {
  businessIds: number[],
}

const getSubscriptionsByBusinessIds = (
  params: IGetSubscriptionsByBusinessIdsParams,
): Promise<ISubscription[]> => {
  return apiGet(
    '/billing/api/v1/subscriptions/by_business_ids',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    return camelizeKeys(data.subscriptions) as ISubscription[];
  });
};

export {
  IGetSubscriptionsByBusinessIdsParams,
  getSubscriptionsByBusinessIds,
};
