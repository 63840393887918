import React, { useCallback } from 'react';

import DropdownIconItem from '../dropdown/icon_item';
import { TIconOption } from '../inputs/types';

interface ISearchDropdownItemProp<TSearchDropdownIconOption extends TIconOption> {
  option: TSearchDropdownIconOption,
  onSelect: (value: TSearchDropdownIconOption) => void,
}

const SearchDropdownItem = <TSearchDropdownIconOption extends TIconOption> ({
  option,
  onSelect,
}: ISearchDropdownItemProp<TSearchDropdownIconOption>) => {
  const handleSelect = useCallback(() => {
    onSelect(option);
  }, [onSelect, option]);

  return (
    <DropdownIconItem
      icon={ option.icon }
      title={ option.label }
      onSelect={ handleSelect }
    />
  );
};

export default SearchDropdownItem;
