module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, len;
    
      __out.push('<div class="text-center update-profile-container m-t-14">\n  <div class="profile-content-wrapper">\n    ');
    
      if (this.dwollaCustomer.id && this.dwollaCustomer.shouldUploadControllerDocument()) {
        __out.push('\n      <div class="warning-content-wrapper text-left">\n        <div>The initial verification attempt failed because the information provided did not satisfy our verification check. Please upload a colored scan of the identifying document.</div>\n        ');
        if (this.dwollaCustomer.isFailedToVerifyController()) {
          __out.push('\n        <div class="m-t-20 in-red-800 font-semi-bold">Document upload failed. Reason: ');
          __out.push(__sanitize(this.dwollaCustomer.docFailedDescriptionForController()));
          __out.push(' </div>\n        ');
        }
        __out.push('\n        <div class="display-flex align-items-center m-t-20 m-b-20">\n          ');
        if (this.dwollaCustomer.isReviewingController()) {
          __out.push('\n            <div class="document-uploaded-wrapper">Document Uploaded. Pending Review. </div>\n          ');
        } else {
          __out.push('\n            <select class="input-document-type form-control m-r-20">\n              <option value="license">State issued driver’s license</option>\n              <option value="idCard">U.S. government-issued photo id card</option>\n              <option value="passport">Passport</option>\n            </select>\n            ');
          if (!this.isUploading) {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <button type="submit" class="btn btn-browse-js ');
            __out.push(__sanitize(this.dwollaCustomer.isFailedToVerifyController() ? 'btn-warning' : 'ladda-button'));
            __out.push(' fileinput-button width-200px" data-color="blue" data-style="expand-right">\n                ');
            if (this.dwollaCustomer.isFailedToVerifyController()) {
              __out.push('\n                Try Again\n                ');
            } else {
              __out.push('\n                Browse\n                ');
            }
            __out.push('\n                <input type="file" name="files[]" multiple="" class="document-file-input-js" accept=".jpeg, .jpg, .png">\n              </button>\n            </div>\n            ');
          } else {
            __out.push('\n            <div class="text-center document-input-wrapper">\n              <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n              <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                <div class=\'file-status-line\' style=\'width:0%\'></div>\n              </div>\n            </div>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        </div>\n        ');
        if (this.isFullReadMode) {
          __out.push('\n        <p><strong>US persons:</strong> A color scanned photo of the Controller’s identifying document can be specified as documentType: <code class="in-black">license</code> (state issued driver\'s license), or <code class="in-black">idCard</code> (U.S. government-issued photo id card).</p>\n        <p>Supported Document Examples:</p>\n        <ul>\n          <li>Non-expired State Issued Driver’s License/Identification Card</li>\n          <li>Non-expired US Passport</li>\n          <li>Federal Employment Authorization Card</li>\n          <li>US Visa</li>\n        </ul>\n        <p>Unsupported Document Examples:</p>\n        <ul><li>Military IDs</li><li>Expired government-issued IDs</li></ul>\n        <p><strong>Non-US persons:</strong> A color scanned photo of the Controller’s identifying document can be specified as documentType: <code class="in-black">passport</code>. Examples include:</p>\n        <ul>\n          <li>Non-expired Foreign Passport <em><strong>Note:</strong> Foreign Passports are only accepted when the individual does not have an ITIN or SSN and the user must alternatively enter the Passport number</em>.</li>\n        </ul>\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read less</span></div>\n        ');
        } else {
          __out.push('\n        <div class="in-blue-950 font-semi-bold"><span class="read-more-link-js pointer">Read more</span></div>\n        ');
        }
        __out.push('\n      </div>\n    ');
      }
    
      __out.push('\n    ');
    
      if (!this.isEditMode && this.dwollaCustomer.id) {
        __out.push('\n      <div class="profile-view-wrap">\n        <label class="">Name</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.controllerName()));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Title</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_title')));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Address</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.controllerAddress()));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Status</label>\n        ');
        if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["VERIFIED"]) {
          __out.push('\n        <span class="font-semi-bold in-green-500">Verified</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
          __out.push('\n        <span class="font-semi-bold in-orange-300">Retry</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["SUSPENDED"]) {
          __out.push('\n        <span class="font-semi-bold in-red-800">Verification Failed</span>\n        ');
        } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["DOCUMENT"]) {
          __out.push('\n        <span class="font-semi-bold in-orange-300">Please upload additional documents</span>\n        ');
        }
        __out.push('\n      </div>\n      <div class="display-flex align-items-center in-blue-950 font-semi-bold pointer edit-link-js ');
        __out.push(__sanitize(this.dwollaCustomer.isEditable() ? '' : 'hidden'));
        __out.push('">\n        <span class="font-20 m-t-6"><i class="icon icon-edit_account"></i></span>\n        <span class="font-16 m-l-12">Edit information</span>\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="text-left">Please provide additional information on one controller to successfully complete the registration for ACH payments.</div>\n      <div class="profile-input-wrap approvers-typeahead-wrapper">\n        <label class="text-left">Docyt User</label>\n        <input class="form-control user-input-js" type="text" placeholder="Select Docyt user" value="">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-t-20 m-b-20">\n        <label class="width-40-percent text-left">Controller Legal Name</label>\n        <div class="business-address-wrapper">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-first-name form-control" type="text" placeholder="First Name" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_first_name')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-last-name form-control" type="text" placeholder="Last Name" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_last_name')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Title</label>\n        <input class="input-title form-control" type="text" placeholder="Title in company e.g. Chief Financial Officer" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_title')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Date of Birth</label>\n        <input class="input-date-birth dropdown-toggle form-control" type="text" placeholder="MM-DD-YYYY" autocomplete="off" value="');
        __out.push(__sanitize(this.dateBirth));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n        <p class="calendar-js"><i class="icon icon-calendar-wall"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Address</label>\n        <input class="input-address-street form-control" placeholder="Address Line 1" type="text" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_address_line1')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent"></label>\n        <input class="input-address-street2 form-control" type="text" placeholder="Address Line 2 (optional)" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_address_line2')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent"></label>\n        <div class="business-address-wrapper">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-address-city form-control" type="text" placeholder="City" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_address_city')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap p-r-5">\n            <select class="input-address-state form-control" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n            ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          address_state = usaStateList[i];
          __out.push('\n              <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" ');
          if (this.dwollaCustomer.get('controller_address_state') === address_state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n            ');
        }
        __out.push('\n            </select>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-address-zip form-control" type="text" placeholder="Zip" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('controller_address_postal_code')));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      ');
        if (this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push('\n        ');
          if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
            __out.push('\n          <div class="profile-input-wrap m-b-10">\n            <label class="width-40-percent text-left">Full nine digits of SSN</label>\n            <input class="input-last-four form-control" type="text" placeholder="XXX-XX-XXXX">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        ');
          } else {
            __out.push('\n          <div class="profile-input-wrap m-b-10">\n            <label class="width-40-percent text-left">Last four digits of SSN</label>\n            <input class="input-last-four form-control" type="text" placeholder="XXXX">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        ');
          }
          __out.push('\n        <div class="profile-input-wrap m-b-20">\n          <label class="width-40-percent text-left"></label>\n          <div class="note-area">Note: Docyt does not save Social Security Numbers in the database and is only passing it over secure network to the service responsible for ACH transfers</div>\n        </div>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}