module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="payees-header m-t-20">\n  <div class="pull-right flex-wrapper header-input-area">\n    <input class="form-control m-t-8 payee-name-filter" placeholder="Filter by Payee Name">\n    <span class="font-25 in-grey-400 m-l-20 m-r-20 p-t-5">|</span>\n    <button class="btn blue-plus-btn big-plus-btn pull-right add-payee-btn">\n      <i class="icon icon-plus"></i>\n    </button>\n  </div>\n</div>\n<div class="table-responsive payees-table-wrapper service-detail-view">\n  <table class="table">\n  <thead>\n    <tr>\n      <th class="font-13 font-bold" width="50%">Payee</th>\n      <th class="font-13 font-bold" width="20%">Bills</th>\n      <th class="font-13 font-bold" width="30%">YTD Amount</th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n  </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}