import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown/actions_dropdown';

import CopyMappingAction from './copy_mapping/copy_mapping_action'
import ResetDefaultMappingAction from './reset_default_mapping/reset_default_mapping_action';
import { IEditMappingLaborProps } from '../hooks';

const BusinessReportsEditMappingCopyMappingDropdown = ({ data }: IEditMappingLaborProps) => {
  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <CopyMappingAction data={ data } />
      {
        data.report.enabledDefaultMapping && (
          <ResetDefaultMappingAction data={ data } />
        )
      }
    </ActionsDropdown>
  );
};

export default React.memo(BusinessReportsEditMappingCopyMappingDropdown);
