import { object, string } from 'yup';

export const vendorProfileEditValidation = object({
  name: string().trim().required('Display Name is required.'),
});

export interface IVendorProfileParams {
  name: string,
  preferredPaymentMethod?: string,
  website?: string,
  email?: string,
  phone?: string,
  fax?: string,
  eligibility1099?: boolean,
  useTaxes?: boolean,
  isAiInvoiceLineItemsExtraction?: boolean,
  imageUrl?: string,
  logo?: string,
  logoType?: string,
}

export const vendorLinkLedgerEditValidation = object({
  qboName: string().trim().required('Vendor is required.'),
});