import React from 'react';

import BlankBankingAccountImg from '@assets/images/banking_accounts/empty_banking_account.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const BankingAccountBlankIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Banking account icon"
      src={ BlankBankingAccountImg }
      { ...props }
    />
  );
};

export default BankingAccountBlankIcon;
