module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n    <div class="m-t-40 m-b-40 relative text-right header-panel m-l-auto m-r-auto">\n        <h1 class="font-40 font-bold text-center">AAHOA</h1>\n    </div>\n    <div class="background-white aaoha-main-panel m-l-auto m-r-auto">\n        <form class="aaoha-form" method="post" action="https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=083b722a-00ea-465a-9784-d309446b2188&env=na2">\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Full Name</label>\n                <input class="col-xs-8" name="ClientFullName" type="text" required>\n            </div>\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Company</label>\n                <input class="col-xs-8" name="ClientCompany" type="text" required>\n            </div>\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Email</label>\n                <input class="col-xs-8" name="ClientEmail" type="email" required>\n            </div>\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Cell Phone</label>\n                <input class="col-xs-8" name="ClientCellPhone" type="text" required>\n            </div>\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Address (Street)</label>\n                <input class="col-xs-8" name="ClientAddress1" type="text" required>\n            </div>\n            <div class="row m-b-20">\n                <label class="col-xs-4 padding-0">Address (City, State - ZIP)</label>\n                <input class="col-xs-8" name="ClientAddress2" type="text" required>\n            </div>\n            <div class="row text-right">\n                <button type="submit" class="btn btn-blue pointer">Submit</button>\n            </div>\n        </form>\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}