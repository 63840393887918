import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const QBOLinkIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="qbo-link"
    />
  );
};

QBOLinkIcon.displayName = 'QBOLinkIcon';

export default QBOLinkIcon;
