module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex-column width-750px m-l-auto m-r-auto in-black">\n  <div class="m-t-30">Select the categories below to map to the department ');
    
      __out.push(__sanitize(this.accountingClass.name));
    
      __out.push('.</div>\n  <div class="display-flex align-items-center">\n    <span class="font-16 font-semi-bold">Categories</span>\n    <div class="width-200px m-l-auto">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n          <span class="status-label-js"></span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n          <div class="bank-account-list">\n          <li class="status-all-js" data-value=\'all\'>\n            <div class=\'display-flex align-items-center\'>\n              <span class=\'text m-t-3 m-l-10\'>All Categories</span>\n            </div>\n          </li>\n          <li class="status-mapped-js" data-value=\'mapped\'>\n            <div class=\'display-flex align-items-center\'>\n              <span class=\'text m-t-3 m-l-10\'>Mapped Categories</span>\n            </div>\n          </li>\n          <li class="status-unmapped-js" data-value=\'unmapped\'>\n            <div class=\'display-flex align-items-center\'>\n              <span class=\'text m-t-3 m-l-10\'>Unmapped Categories</span>\n            </div>\n          </li>\n          </div>\n        </ul>\n      </div>\n    </div>\n    <div class="relative m-l-10">\n      <a class="btn dropdown-toggle" type="button" id="custom-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-ellipsis-h font-24 in-grey-800"></span>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="custom-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n        <li>\n          <a class="pointer reset-btn">\n            <div class=\'dropdown-menu-action-item in-red-400\'>\n              <div class=\'action-icon\'><i class="icon icon-undo font-16 action-icon"></i></div>\n              <span>Reset Mapping</span>\n            </div>\n          </a>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class="template-mapping-status-panel m-t-10 text-left">\n    <label class="template-mapping-status-label m-b-0"></label>\n  </div>\n  <div class="table-responsive transactions-table-wrapper m-t-12">\n    <div class="panel panel-default">\n      <div class="category-filter-container"></div>\n      <div class="category-list-container"></div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}