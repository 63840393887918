import React, { useCallback } from 'react';

import { useUpdateDataExport } from '@src/hooks/queries/data_exports';
import { IDataExport } from '@src/types/data_exports';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

import { useRenameModal } from './rename_modal';

interface IDataExportItemDeleteActionProps {
  dataExport: IDataExport,
}

const DataExportItemEditAction = ({
  dataExport,
}: IDataExportItemDeleteActionProps): JSX.Element => {
  const updateDataExport = useUpdateDataExport();

  const handleClick = useCallback((name?: string) => {
    updateDataExport.mutate({
      id: dataExport.id,
      dataExport: {
        exportFileName: name
      } },
    );
  }, [dataExport, updateDataExport]);

  const handleDataExportUpdate = useCallback((name: string) => {
    handleClick(name);
  }, [handleClick]);

  const renameModal = useRenameModal({
    onDone: handleDataExportUpdate,
  });

  return (
    <>
      <renameModal.Component
        dataExport={ dataExport }
        { ...renameModal.props }
      />
      <MutationStatus mutation={ updateDataExport } successMessage="Renamed successfully." />
      <ActionsDropdown.Action
        name="Rename"
        onClick={ renameModal.open }
      />
    </>
  );
};

export default DataExportItemEditAction;
