import { TID } from '@src/types/common';
import { IPaymentAccount, IPaymentAccountData } from '@src/types/payment_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetPaymentAccountsParams {
  businessId: TID
  noConsiderArchive: boolean
  excludeSystemAccounts?: boolean
  accountType?: string,
}

interface IGetReconciliationPaymentAccountsParams {
  businessId: TID
  reconciliationPaymentAccountId?: TID
  noConsiderArchive: boolean
  excludeSystemAccounts?: boolean
}

interface IGetPaymentAccountsResponse {
  paymentAccounts: IPaymentAccount[]
}

interface ICreatePaymentAccountParams {
  businessId: TID,
  financialInstitutionBankAccountId?: TID,
  paymentAccount:IPaymentAccountData,
}

interface ICreateEmployeeCardParams {
  businessId: TID,
  reconciliationPaymentAccountId: TID,
  paymentAccount: IPaymentAccountData
}

interface IUpdatePaymentAccountParams {
  id: TID,
  businessId: TID,
  paymentAccount: IPaymentAccountData,
}

interface IArchivePaymentAccountParams {
  id: TID
}

interface IDeletePaymentAccountParams {
  id: TID,
}

const getPaymentAccounts = (
  params: IGetPaymentAccountsParams,
): Promise<IGetPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/payment_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetPaymentAccountsResponse);
};

const getByReconciliationPaymentAccountId = (
  params: IGetReconciliationPaymentAccountsParams,
): Promise<IGetPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/payment_accounts/by_reconciliation_payment_account_id',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetPaymentAccountsResponse);
};

const createPaymentAccount = (
  params: ICreatePaymentAccountParams | ICreateEmployeeCardParams,
): Promise<void> => {
  return apiPost('/api/v1/payment_accounts', underscoreKeys(params));
};

const updatePaymentAccount = (
  params: IUpdatePaymentAccountParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/payment_accounts/${params.id}`,
    underscoreKeys(params),
  );
};

const archivePaymentAccount = (
  params: IArchivePaymentAccountParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/payment_accounts/${params.id}/archive_account`,
    underscoreKeys(params),
  );
};

/**
 * API call for the payment account, also referred to as an employee card.
 * @param params payment account id
 * @returns Promise
 */
const deletePaymentAccount = (
  params: IDeletePaymentAccountParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/payment_accounts/${params.id}`,
  );
};

export {
  IGetPaymentAccountsParams,
  IGetReconciliationPaymentAccountsParams,
  ICreatePaymentAccountParams,
  ICreateEmployeeCardParams,
  IArchivePaymentAccountParams,
  IUpdatePaymentAccountParams,
  IGetPaymentAccountsResponse,
  IDeletePaymentAccountParams,
  getPaymentAccounts,
  getByReconciliationPaymentAccountId,
  createPaymentAccount,
  updatePaymentAccount,
  archivePaymentAccount,
  deletePaymentAccount,
};
