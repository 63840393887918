import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITableBodyProps extends React.ComponentPropsWithoutRef<'tbody'>, IUIStyleProps {
}

const TableBody = ({
  children,
  ...props
}: ITableBodyProps) => {
  const [classes, tdProps] = uiStyleProps(
    undefined,
    props,
  );

  return (
    <tbody
      className={ classes }
      { ...tdProps }
    >
      { children }
    </tbody>
  );
};

export default TableBody;
