import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IShareIconProps extends Omit<IFaIconProps, 'icon'> {}

const ShareIcon = (props: IShareIconProps): JSX.Element => {
  return <FaIcon icon="share" { ...props } />;
};

export default ShareIcon;
