import React, { useMemo } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { IBusinessMetricValueByDate } from '@src/types/business_metric_value';
import { IMetric } from '@src/types/metrics';

import Form from '@src/components/ui_v2/form';
import Table from '@src/components/ui_v2/table';

import { IDimensionLabels } from './types';

interface IRowProps {
  value: IBusinessMetricValueByDate;
  index: number;
  dimensions: IMetric['dimensions'];
  dimensionLabels: IDimensionLabels;
  isEditMode: boolean;
}

const Row = ({ value, index, dimensions, dimensionLabels, isEditMode }: IRowProps): JSX.Element => {
  const { control } = useFormContext<{ values: IBusinessMetricValueByDate[] }>();
  const labels = useMemo(() => {
    return dimensions.map((dimension) => {
      const label = dimensionLabels[dimension.id]?.find((dimensionLabel: any) => value.dimensionLabelIds?.includes(dimensionLabel.id));
      return label ? label.label : '-';
    });
  }, [dimensions, dimensionLabels, value.dimensionLabelIds]);

  const { field } = useController({
    name: `values.${index}.value`,
    control,
  });

  return (
    <Table.Row key={ value.date }>
      {labels.map((label, idx) => (
        <Table.TextCell key={ dimensions[idx].id }>
          {label}
        </Table.TextCell>
      ))}
      {isEditMode ? (
        <Table.TextCell>
          <Form.NumberField
            label="Value"
            layout="table"
            { ...field }
          />
        </Table.TextCell>
      ) : (
        <Table.NumberCell number={ value.value } />
      )}
    </Table.Row>
  );
};

export default Row;
