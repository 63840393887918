import React, { useCallback } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReportPath } from '@src/routes';
import {
  IRevenueServiceDocument,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';

import CollectionTable from '@src/components/ui_v2/collection_table';

import BulkDeleteUnassignedDocumentsAction from './actions/bulk_delete_action';
import MenuAction from './actions/menu_action';
import VerifyRevenueReportAction from './actions/verify_revenue_report_action';
import { IUnassignedRSDCollection } from './hooks';

interface IUnassignedRevenueTableProps {
  collection: IUnassignedRSDCollection,
}

const verifyAction = (report: IRevenueServiceDocument) => {
  return <VerifyRevenueReportAction report={ report } />;
};

const menuAction = (report: IRevenueServiceDocument) => {
  return <MenuAction report={ report } />;
};

const UnassignedRevenueTable = ({
  collection,
}: IUnassignedRevenueTableProps) => {
  const revenueService = useRevenueServiceContext();

  const handleRowClick = useCallback((report: IRevenueServiceDocument) => {
    Backbone.history.navigate(
      revenueServiceReportPath(revenueService.businessId, report.id),
      { trigger: true },
    );
  }, [revenueService.businessId]);

  return (
    <CollectionTable<
        IRevenueServiceDocument,
        TRevenueServiceDocumentsSortColumn
      >
      showSelect
      bulkActions={ (
        <BulkDeleteUnassignedDocumentsAction
          selectedRecords={ collection.selectedRecords }
        />
      ) }
      noDataMessage="No reports found"
      query={ collection.query }
      records={ collection.records }
      sorting={ collection.sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.TextColumn<
        IRevenueServiceDocument,
        TRevenueServiceDocumentsSortColumn
      >
        name="filename"
        sortColumn="filename"
        title="Name"
        value={ (document) => document.filename }
        width="60%"
      />
      <CollectionTable.TextColumn<
        IRevenueServiceDocument,
        TRevenueServiceDocumentsSortColumn
      >
        name="uploader"
        sortColumn="uploader"
        title="Uploader"
        value={ (document) => document.uploaderName }
        width="20%"
      />
      <CollectionTable.DateColumn<
          IRevenueServiceDocument,
          TRevenueServiceDocumentsSortColumn
        >
        name="created_at"
        sortColumn="created_at"
        title="Uploaded on"
        value={ (document) => document.createdAt }
        width="20%"
      />
      <CollectionTable.ButtonColumn<IRevenueServiceDocument>
        name="assign"
        title="Verify"
        value={ verifyAction }
        width="100px"
      />
      <CollectionTable.ActionsColumn<IRevenueServiceDocument>
        name="actions"
        title="Actions"
        value={ menuAction }
      />
    </CollectionTable>
  );
};

export default React.memo(UnassignedRevenueTable);
