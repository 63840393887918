import React from 'react';

import classNames from 'classnames';

import { TFilterData } from '@src/types/filter';

import { SelectPickerInput, ISelectPickerInputProps } from '@src/components/ui/form';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface ISelectPickerFilterFieldProps<TFilter extends TFilterData> extends
  Omit<ISelectPickerInputProps, 'name' | 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  name: keyof TFilter,
}

const SelectPickerFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: ISelectPickerFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const wrapperClasses = classNames('height-100-percent', { filtered: value });

  return (
    <Wrapper { ...wrapperProps } className={ wrapperClasses }>
      <SelectPickerInput
        value={ value }
        onChange={ update }
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default SelectPickerFilterField;
