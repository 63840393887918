import { TBankFeedTransactionServiceDocumentQuery, TBankFeedTransactionServiceDocumentColumn } from '@src/types/bank_statements';
import { TID, TOrderDirection } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetBankFeedTransactionServiceDocumentsParams {
  businessId: TID,
  bankStatementId: TID,
  filter?: TBankFeedTransactionServiceDocumentQuery,
  page?: number,
  orderColumn?: TBankFeedTransactionServiceDocumentColumn,
  orderDirection?: TOrderDirection,
}

interface IGetBankFeedTransactionServiceDocumentsResponse {
  meta: {
    totalCount: number,
    hasEmployeeCards: boolean,
  },
  collection: ITransactionServiceDocument[],
}

const getBankFeedTransactionServiceDocuments = (
  params: IGetBankFeedTransactionServiceDocumentsParams,
): Promise<IGetBankFeedTransactionServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/bank_feed_transaction_service_documents',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.bankFeedTransactionServiceDocuments,
    } as IGetBankFeedTransactionServiceDocumentsResponse;
  });
};

interface IGetBankFeedBalanceParams {
  businessId: TID,
  bankStatementId?: TID,
}

interface IGetBankFeedInformationResponse {
  deposit: string,
  withdrawals: string,
  charges: string,
  payments: string,
  allSetBankStatement: boolean,
  lastVerifiedStatementTransactionDate: string,
  lastTransactionDate: string
}

const getBankFeedInformation = (
  params: IGetBankFeedBalanceParams,
): Promise<IGetBankFeedInformationResponse> => {
  return apiGet(
    '/api/v1/bank_feed_transaction_service_documents/information',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      bankFeedBalance: cdata.bankFeedBalance,
    } as IGetBankFeedInformationResponse;
  });
};

export {
  IGetBankFeedTransactionServiceDocumentsParams,
  IGetBankFeedTransactionServiceDocumentsResponse,
  IGetBankFeedBalanceParams,
  IGetBankFeedInformationResponse,
  getBankFeedTransactionServiceDocuments,
  getBankFeedInformation,
};
