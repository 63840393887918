import { string, number, object } from 'yup';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TDate, TID } from '@src/types/common';

interface IBalanceSheetStatementDetailsFormValues {
  balanceSheetStatement?: IBalanceSheetStatement
  file?: File,
  chartOfAccountId?: TID,
  date?: TDate,
  description?: string,
}

const statementValidation = object({
  chartOfAccountId: number().label('Account').required(),
  date:             string().required('Statement Date is required.'),
});

export {
  IBalanceSheetStatementDetailsFormValues,
  statementValidation,
};
