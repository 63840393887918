import React, { useMemo, useCallback } from 'react';

import { IDocument, IUnencryptedDocumentField } from '@src/types/documents';
import { IStandardDocumentField } from '@src/types/standard_documents';

import useDocumentFieldsModal from '@src/components/common/document_field_modal/document_field_modal';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Table from '@src/components/ui/table';

import DeleteAction from './delete_action/delete_action';

interface IDocumentListItemProps {
  document: IDocument,
  unEncryptedStandardDocumentFields: IStandardDocumentField[],
}

const DocumentListItem = ({
  document,
  unEncryptedStandardDocumentFields,
}: IDocumentListItemProps): JSX.Element => {
  const unencryptedFields = useMemo(() => {
    if (document.unencryptedDocumentFields === null
      || document.unencryptedDocumentFields === undefined
      || unEncryptedStandardDocumentFields.length < 1) {
      return [];
    }

    const fields: IUnencryptedDocumentField[] = [];
    const unencryptedDocumentFields = document.unencryptedDocumentFields;
    unencryptedDocumentFields.forEach((unencryptedDocumentField) => {
      if (unEncryptedStandardDocumentFields.length > 0
        && unencryptedDocumentField.fieldId === unEncryptedStandardDocumentFields[0].fieldId) {
        fields[0] = unencryptedDocumentField;
      }

      if (unEncryptedStandardDocumentFields.length > 1
        && unencryptedDocumentField.fieldId === unEncryptedStandardDocumentFields[1].fieldId) {
        fields[1] = unencryptedDocumentField;
      }
    });

    return fields;
  }, [document.unencryptedDocumentFields, unEncryptedStandardDocumentFields]);

  const havePages = useMemo(() => {
    if (document.standardDocument === null || document.standardDocument?.withPages === null) {
      return true;
    }

    return document.standardDocument?.withPages;
  }, [document.standardDocument]);

  const documentFieldsModal = useDocumentFieldsModal();
  const handleDocumentClick = useCallback(() => {
    if (havePages) {
      Backbone.history.navigate(`document/${document.id}`, { trigger: true });
    } else {
      documentFieldsModal.open();
    }
  }, [document.id, documentFieldsModal, havePages]);

  return (
    <>
      <documentFieldsModal.Component
        { ...documentFieldsModal.props }
        document={ document }
        title={ document.standardDocumentName }
      />
      <Table.Row>
        <Table.Cell
          pointer
          widthPercent={ 33 }
          onClick={ handleDocumentClick }
        >
          { unencryptedFields[0]?.value }
        </Table.Cell>
        <Table.Cell
          pointer
          widthPercent={ 33 }
          onClick={ handleDocumentClick }
        >
          { unencryptedFields[1]?.value }
        </Table.Cell>
        <Table.Cell
          pointer
          onClick={ handleDocumentClick }
        >
          { document.finalFileKey || document.originalFileKey }
        </Table.Cell>
        <Table.Cell>
          <ActionsDropdown>
            <DeleteAction document={ document } />
          </ActionsDropdown>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default DocumentListItem;
