import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';
import Table from '@src/components/ui/table';

import { IBankAccountData } from '../../schema';
import SubItem from './sub_item';

import styles from '../../styles.module.scss';

interface IFinancialInstitutionConnectionCenterTableItemProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
  onSelected: (bankAccount: IBankAccountData) => void,
}

const FinancialInstitutionConnectionCenterTableItem = ({
  financialInstitutionConnection,
  onSelected,
}: IFinancialInstitutionConnectionCenterTableItemProps) => {
  return (
    <>
      <Table.Row>
        <Table.Cell className={ styles['financial-institution-name-section'] }>
          <div className="display-flex align-items-center">
            <FinancialInstitutionAvatar
              logo={ financialInstitutionConnection.financialInstitution.logo }
            />
            <span className="m-l-10">{ financialInstitutionConnection.financialInstitution.name }</span>
          </div>
        </Table.Cell>
        <Table.Cell>
          {
            financialInstitutionConnection.isActive
              ? (<span className={ styles['connected-banner'] }>Connected</span>)
              : (<span className={ styles['disconnected-banner'] }>Disconnected</span>)
          }
        </Table.Cell>
      </Table.Row>
      {
        financialInstitutionConnection.financialInstitutionBankAccounts
          .map((financialInstitutionBankAccount) => (
            <SubItem
              key={ financialInstitutionConnection.id }
              financialInstitutionBankAccount={ financialInstitutionBankAccount }
              financialInstitutionConnection={ financialInstitutionConnection }
              onSelected={ onSelected }
            />
          ))
      }
    </>
  );
};

export default React.memo(FinancialInstitutionConnectionCenterTableItem);
