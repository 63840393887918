import React from 'react';

import Text from '@src/components/ui/text';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import { useHelpModal } from './help_modal';

const AchTransactionsHeader = (): JSX.Element => {
  const helpModal = useHelpModal();

  return (
    <>
      <DetailsRegion.Header
        divider="horizontal"
        title="ACH Transactions"
      >
        <Text fontSize={ 14 }>Standard ACH Transfer</Text>
        <DocytSupportIcon onClick={ helpModal.open } />
      </DetailsRegion.Header>
      <helpModal.Component
        { ...helpModal.props }
      />
    </>
  );
};

export default AchTransactionsHeader;
