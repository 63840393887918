import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { BotNotificationIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

const ContextSummary: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className={ styles['context-summary'] }>
      <Button className={ styles['context-header'] } onClick={ onClick }>

        <BotNotificationIcon fontSize={ 22 } />
        <span>Ask Docyt AI</span>
      </Button>
    </div>
  );
};

export default ContextSummary;
