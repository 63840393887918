import React, { useCallback, useMemo } from 'react';

import { useGetAccountingClasses } from '@src/hooks/queries/accounting_classes';
import { TID } from '@src/types/common';

import {
  ISelectInputProps,
  SelectInput,
  StaticInput,
  TOption,
} from '@src/components/ui_v2/inputs';

interface IAccountClassInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  /**
   * If there is only 1 options was returned by request, then auto select this option.
   */
  autoSelect?: boolean,
  businessId?: TID,
  chartOfAccountId?: TID,
  isDisabled?: boolean,
  /**
   * If there is no options then show "Not available" label instead of empty select
   */
  showNotAvailable?: boolean,
  value?: TID | null,
  onChange?: (value: TID | null, option: TOption | null) => void,
}

const AccountClassInput = ({
  autoSelect,
  businessId,
  chartOfAccountId,
  isDisabled,
  showNotAvailable,
  value,
  onChange,
  ...props
}: IAccountClassInputProps): JSX.Element => {
  const query = useGetAccountingClasses(
    { businessId, chartOfAccountId },
    {
      onSuccess: (data) => {
        if (value) return;
        if (!onChange) return;
        if (!autoSelect) return;
        if (data.collection.length !== 1 || !data.collection[0]) return;

        const accountClass = data.collection[0];

        onChange(
          accountClass.id,
          {
            label: accountClass.name,
            value: String(accountClass.id),
          },
        );
      },
    },
  );

  const handleChange = useCallback((newValue: TOption | null) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null, newValue);
  }, [onChange]);

  const options = useMemo(() => {
    return query.data?.collection?.map((accountClass) => ({
      label: accountClass.name,
      value: String(accountClass.id),
    })) || [];
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;

    return options.find((acc) => acc.value === String(value)) || null;
  }, [value, options]);

  if (showNotAvailable && query.isSuccess && options.length === 0) {
    return (
      <StaticInput size={ props.size } value="Not available" />
    );
  }

  return (
    <SelectInput
      isDisabled={ isDisabled || !businessId }
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(AccountClassInput);
