import React from 'react';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface ComponentProps {
  show: boolean;
  close: () => void;
}

const Component: React.FC<ComponentProps> = ({ show, close }) => {
  return (
    <Modal show={ show }>
      <Modal.Body>
        <div className={ styles['modal-header'] }>How to Review Additional Uncleared Balance</div>
        <div className={ styles['modal-content'] }>
          <ol>
            <li>Select the transactions in the left and right table to match.</li>
            <li>Transactions with only zero difference can be matched.</li>
            <li>Once you match the entries, go to next page to acknowledge transactions.</li>
            <li>
              To acknowledge a non-zero difference transactions,
              { ' ' }
              please populate acknowledgement notes.
            </li>
          </ol>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={ close }>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Component;
