import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAddressBook, useSuggestedAddressBook } from '@src/hooks/queries/vendor_address_book';
import {
  ISugestedAddressResponse,
} from '@src/requests/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import AddressBookModalForm from '../form/address_book_form';
import { IBusinessAddressBookInput } from '../form/schema';

import { useConfirmAddressBookModal } from '.';

import styles from './styles.module.scss';

interface IAddressActionProps extends IUseModalProps {
  vendor: IBusinessVendor;
  businessId: TID;
  formId: string;
  isEdit?: boolean;
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  addressInput?: IBusinessAddressBookInput,
  address?: IAddress;
}

const AddressBookModal = ({
  formId,
  vendor,
  businessId,
  isOpen,
  isEdit = false,
  onCancel,
  onDone,
  setVendorData,
  addressInput,
  address,
}: IAddressActionProps) => {
  const queryClient = useQueryClient();
  const addAddressBook = useAddressBook();
  const suggestedAddressBook = useSuggestedAddressBook();
  const confirmAddressBookModal = useConfirmAddressBookModal();

  const { mutate: addAddress } = addAddressBook;
  const { mutate: suggestedAddress } = suggestedAddressBook;

  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [addressBookInput, setAddressBookInput] = useState<IBusinessAddressBookInput
  | null>(null);
  const [suggestedAddressResponse, setSuggestedAddressResponse] = useState<ISugestedAddressResponse
  | null>(null);
  const [isEditAddress, setIsEditAddress] = useState<boolean>(isEdit);
  const [isAddAddressModal, setIsAddAddressModal] = useState<boolean | undefined>(undefined);

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleSubmit = useCallback(
    (data: IBusinessAddressBookInput) => {
      setErrorMessage(undefined);
      setAddressBookInput(data);
      setIsEditAddress(false);
      setIsAddAddressModal(true);
      suggestedAddress(
        {
          ...data,
          businessId,
          vendorId: vendor.id,
        },
        {
          onSuccess: (response) => {
            setSuggestedAddressResponse(response);
            if (response.deliverability === 'deliverable') {
              addAddress(
                {
                  ...data,
                  businessId,
                  vendorId: vendor.id,
                },
                {
                  onSuccess: (addAddressresponse) => {
                    queryClient.invalidateQueries(QueryKey.vendorAddresses);

                    const newAddress = addAddressresponse.address;
                    const updatedVendor = {
                      ...vendor,
                      addresses: [...(vendor.addresses || []), newAddress],
                    };

                    setVendorData(updatedVendor);
                    setSuccessMessage('Address has been successfully added.');
                    onDone();
                  },
                  onError: handleError,
                },
              );
              onDone();
            } else {
              onDone();
              confirmAddressBookModal.open();
            }
          },
          onError: handleError,
        },
      );
    },
    [addAddress, suggestedAddress, businessId, vendor,
      handleError, queryClient, setVendorData, confirmAddressBookModal, onDone],
  );

  return (
    <>
      {(addAddressBook.isLoading) && <Spinner />}
      {(suggestedAddressBook.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Cannot Authorize Address" />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      ) }
      { !isEditAddress && (
      <confirmAddressBookModal.Component
        setVendorData={ setVendorData }
        title={ (
          <p className={ styles['modal-title'] }>
            Confirm Address
          </p>
        ) }
        { ...confirmAddressBookModal.props }
        address={ address as IAddress }
        addressInput={ addressBookInput as IBusinessAddressBookInput }
        isAddAddressModal={ isAddAddressModal }
        suggestedAddress={ suggestedAddressResponse as ISugestedAddressResponse }
        vendor={ vendor }
      />
      )}

      <Modal
        className={ styles['modal-address-book'] }
        show={ isOpen }
        title={ (
          <p className={ styles['vendor-profile-modal-title'] }>
            Add New Address
          </p>
        ) }
      >
        <Modal.Body>
          <AddressBookModalForm
            addressInput={ addressInput }
            businessId={ businessId }
            formId={ formId }
            setIsFormValid={ setIsFormValid }
            vendorId={ vendor.id }
            onSubmit={ handleSubmit }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            disabled={ !isFormValid }
            form={ formId }
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useAddressBookModal = makeUseModal(AddressBookModal);

export {
  useAddressBookModal,
  AddressBookModal as default,
};
