import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate, getUTCTimezone } from '@src/utils/date_helpers';

import ChartOfAccountField from '@src/components/common_v2/form_fields/chart_of_account_field';
import Form from '@src/components/ui/form';

import FileView from '../balance_sheet_statement_file_view';
import { IBalanceSheetStatementDetailsFormValues, statementValidation } from '../schema';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IBalanceSheetStatementDetailsFormProps {
  businessId: TID,
  balanceSheetStatement: IBalanceSheetStatement,
  formId?: string,
  isVerifiedStatement: boolean,
  onSubmit: SubmitHandler<IBalanceSheetStatementDetailsFormValues>,
  onChange?: (values: IBalanceSheetStatementDetailsFormValues) => void,
}

const BalanceSheetStatementDetailsForm = ({
  businessId,
  balanceSheetStatement,
  formId,
  isVerifiedStatement,
  onSubmit,
  onChange,
}: IBalanceSheetStatementDetailsFormProps): JSX.Element => {
  const {
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IBalanceSheetStatementDetailsFormValues>({
    defaultValues: {
      balanceSheetStatement,
      chartOfAccountId: balanceSheetStatement.chartOfAccount?.id,
      date:             balanceSheetStatement.date,
    },
    resolver: yupResolver(statementValidation),
  });

  const dateController = useController({ name: 'date', control });
  const accountController = useController({ name: 'chartOfAccountId', control });
  const descriptionController = useController({ name: 'description', control });

  const chartOfAccountId = watch('chartOfAccountId');
  const date = watch('date');

  const firstDate = getUTCTimezone(new Date(balanceSheetStatement.month));
  const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);

  useEffect(() => {
    if (onChange && isDirty) onChange(getValues());
  }, [getValues, onChange, chartOfAccountId, date, isDirty]);

  return (
    <Form className="display-flex width-100-percent" id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ styles['upload-file-container'] }>
        { balanceSheetStatement.statementFileUrl && (
          <FileView
            fileName={ balanceSheetStatement?.name }
            fileUrl={ balanceSheetStatement?.statementFileUrl }
          />
        )}
      </div>
      <div className={ styles['form-container'] }>
        <div className={ styles['form-header'] }>
          <span className="font-15 font-bold">Document Details</span>
        </div>
        <div className={ styles['statement-detail-form'] }>
          <ChartOfAccountField
            accountType="balance_sheet"
            businessId={ businessId }
            error={ errors.chartOfAccountId?.message }
            isDiabled={ isVerifiedStatement }
            label="Balance Sheet Account"
            labelClass="width-28-percent"
            { ...accountController.field }
          />
          <Form.DateField
            disabled={ isVerifiedStatement }
            error={ errors.date?.message }
            label="Date"
            options={ {
              startDate: getUTCTimezone(new Date(formatDate(firstDate, API_DATE_FORMAT))),
              endDate:   getUTCTimezone(new Date(formatDate(lastDate, API_DATE_FORMAT))),
            } }
            placeholder="Select Date"
            { ...dateController.field }
          />
          <Form.TextField
            disabled={ isVerifiedStatement }
            error={ errors.description?.message }
            label="Description"
            placeholder="Enter Description"
            { ...descriptionController.field }
          />
        </div>
      </div>
    </Form>
  );
};

export {
  IBalanceSheetStatementDetailsFormProps,
  IBalanceSheetStatementDetailsFormValues,
  BalanceSheetStatementDetailsForm as default,
};
