import React from 'react';

import { UseQueryResult } from 'react-query';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface INoScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  query?: UseQueryResult<any, Error> | UseQueryResult<any, Error>[],
  wrapperClassName?: string,
}

const NoScrollTable = ({
  children,
  query,
  wrapperClassName,
  ...tableProps
}: INoScrollTableProps) => {
  return (
    <div className={ wrapperClassName }>
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus query={ query } />
    </div>
  );
};

export default React.memo(NoScrollTable);
