import { useMutation, useQueryClient } from 'react-query';

import {
  IWithdrawAmountParams,
  postWithdrawAmount,
} from '@src/requests/withdraw_amount';

const WITHDRAW_QUERY = 'withdraw';

const useWithdrawAmount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IWithdrawAmountParams>(
    postWithdrawAmount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(WITHDRAW_QUERY);
      },
    },
  );
};

export {
  useWithdrawAmount,
};
