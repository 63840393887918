import { useMutation, useQuery } from 'react-query';

import {
  ISplitTransactionsParams,
  IUndoSplitTransactionsParams,
  IGetRelatedTransactionParams,
  IGetResultantTransactionParams,
  postSplitTransactions,
  postUndoSplitTransactions,
  getRelatedTransactions,
  getResultantTransaction,
} from '@src/requests/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

const useSplitTransactions = () => {
  return useMutation<void, Error, ISplitTransactionsParams>(postSplitTransactions);
};

const useUndoSplitTransactions = () => {
  return useMutation<void, Error, IUndoSplitTransactionsParams>(postUndoSplitTransactions);
};

const RELATED_TRANSACTIONS_QUERY = 'related_transactions';
const RESULTANT_TRANSACTION_QUERY = 'resultant_transaction';

const useGetRelatedTransactions = (params: IGetRelatedTransactionParams) => {
  return useQuery<ITransactionServiceDocument[], Error>(
    [RELATED_TRANSACTIONS_QUERY, params],
    () => getRelatedTransactions(params.transactionId),
  );
};

const useGetResultantTransaction = (params: IGetResultantTransactionParams) => {
  return useQuery<ITransactionServiceDocument[], Error>(
    [RESULTANT_TRANSACTION_QUERY, params],
    () => getResultantTransaction(params.transactionId),
  );
};

export {
  useSplitTransactions,
  useUndoSplitTransactions,
  useGetRelatedTransactions,
  useGetResultantTransaction,
};
