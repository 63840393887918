import React from 'react';

import {
  useGetRelatedTransactions,
  useGetResultantTransaction,
} from '@src/hooks/queries/split_transactions';
import { TID } from '@src/types/common';

import QueryStatus from '@src/components/utils/query_status';

import RelatedTransactionsList from './related_transactions_details/list';
import ResultantTransactionList from './resultant_transaction_details/list';

interface ISplitTransactionsFormProps {
  transactionId: TID,
}

const SplitTransactionsForm = ({ transactionId }: ISplitTransactionsFormProps): JSX.Element => {
  const relatedTransactionsQuery = useGetRelatedTransactions({ transactionId });
  const resultantTransactionQuery = useGetResultantTransaction({ transactionId });

  return (
    <>
      <QueryStatus query={ relatedTransactionsQuery } />
      <QueryStatus query={ resultantTransactionQuery } />
      <div style={ { margin: '0 -20px' } }>
        <span>The split action on the following transactions will be reversed.</span>
        <div className="related_transactions">
          <RelatedTransactionsList
            relatedTransactions={ relatedTransactionsQuery?.data || [] }
          />
        </div>
        <span>The resultant transaction would be following:</span>
        <div className="resultant transactions">
          <ResultantTransactionList
            resultantTransactions={ resultantTransactionQuery?.data || [] }
          />
        </div>
        <span>Are you sure you want to continue?</span>
      </div>
    </>
  );
};

export default SplitTransactionsForm;
