import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import isEqual from 'lodash/isEqual';

import { ItemsSelectorContext } from './provider';
import { initItemsSelectorState, itemsSelectorReducer } from './reducer';
import { IItemSelector } from './types';

interface IUseCreateItemSelectorParams<Type> {
  allItems: Type[],
}

const useCreateItemSelector = <Type>({
  allItems,
}: IUseCreateItemSelectorParams<Type>): IItemSelector<Type> => {
  const [state, dispatch] = useReducer(
    itemsSelectorReducer,
    allItems,
    initItemsSelectorState,
  );

  useEffect(() => {
    dispatch({ type: 'setAll', all: allItems });
  }, [allItems]);

  const { all, selected } = state;

  const mark = useCallback((item: Type, checked: boolean) => {
    dispatch({ type: 'mark', item, checked });
  }, []);

  const isSelected = useCallback((item: Type) => {
    return selected.includes(item);
  }, [selected]);

  const isAllSelected = useCallback(() => {
    if (all.length === 0) return false;

    return isEqual(selected.sort(), all.sort());
  }, [selected, all]);

  const markAll = useCallback((checked: boolean) => {
    dispatch({ type: 'markAll', checked });
  }, []);

  const toggleMark = useCallback((item: Type) => {
    dispatch({ type: 'toggleMark', item });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'reset' });
  }, []);

  const itemsSelector: IItemSelector<Type> = useMemo(() => {
    return {
      selected: selected as Type[],
      isAllSelected,
      isSelected,
      mark,
      markAll,
      toggleMark,
      reset,
    };
  }, [selected, isAllSelected, isSelected, mark, markAll, toggleMark, reset]);

  return itemsSelector;
};

const useItemsSelector = <Type>(): IItemSelector<Type> => {
  const itemsSelector = useContext(ItemsSelectorContext);

  if (!itemsSelector) {
    throw new Error('"useItemsSelector" must be used within <ItemSelectorProvider />!');
  }

  return itemsSelector;
};

export {
  useCreateItemSelector,
  useItemsSelector,
};
