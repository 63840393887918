import { TDateColumn, TModel } from './types';

const CollectionTableDateColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TDateColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableDateColumn;
