import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { TID } from '@src/types/common';

import BankingAccountField from '@src/components/common/form_fields/banking_account_field';
import { IForwardToBankStatementsFormValues } from '@src/components/common/forward_document/types';
import Form from '@src/components/ui/form';

import { forwardToBankStatementsValidation } from './schema';

interface IForwardToBankStatementsFormProps {
  businessId: TID;
  formId?: string,
  onSubmit: SubmitHandler<IForwardToBankStatementsFormValues>,
  onChange?: (values: IForwardToBankStatementsFormValues) => void,
}

const ForwardToBankStatementsForm = ({
  businessId,
  formId,
  onSubmit,
}: IForwardToBankStatementsFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForwardToBankStatementsFormValues>({
    resolver: yupResolver(forwardToBankStatementsValidation),
  });

  const dateController = useController({ name: 'date', control });
  const accountController = useController({ name: 'reconciliationPaymentAccountId', control });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <BankingAccountField
        noConsiderArchive
        businessId={ businessId }
        error={ errors.reconciliationPaymentAccountId?.message }
        { ...accountController.field }
      />
      <Form.DateField
        error={ errors.date?.message }
        label="Statement Date"
        placeholder="Select Date"
        { ...dateController.field }
      />
    </Form>
  );
};

export {
  IForwardToBankStatementsFormProps,
  IForwardToBankStatementsFormValues,
  ForwardToBankStatementsForm as default,
};
