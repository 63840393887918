module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="past-transactions">\n  <div aria-controls="past-transactions-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#past-transactions-collapse" data-toggle="collapse" aria-expanded="true">\n    <div>\n      <i class="fa fa-caret-down m-r-5 m-t-10"></i>\n      <span class="font-bold">PAST TRANSACTIONS</span>\n    </div>\n    <div class="relative inline-block">\n      <div><a tabindex="-1" class="view-all-past-transactions" href="/businesses/');
    
      __out.push(__sanitize(this.business.id));
    
      __out.push('/reconciliation_center/expenses">View all</a></div>\n    </div>\n  </div>\n</div>\n<div aria-labelledby="past-transactions" class="p-t-10 collapse in background-white document-detail-sidebar-section-body" id="past-transactions-collapse" aria-expanded="true" style="">\n  <table class="table past-transactions-table-view bg-white m-b-0"> \n    <thead>\n      <tr>\n        <th class="relative text-left">Date</th>\n        <th class="relative text-left">Dept | Category</th>\n        <th class="relative text-left">Amount</th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}