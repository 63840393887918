import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAssignMetricsReportDate } from '@src/hooks/queries/metrics/metrics_reports';
import { TDate, TUUID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IAssignDateFormValues } from './form/assign_date_form';

interface IAssignDate extends IUseModalProps {
  metricsReportId: TUUID
  preSelectedDate?: TDate
}

const FORM_ID = 'ASSIGN_DATE';

const AssignDateModal = (
  {
    isOpen,
    onCancel,
    onDone,
    metricsReportId,
    preSelectedDate,
  }:IAssignDate,
) => {
  const mutation = useAssignMetricsReportDate();

  const { mutate: updateAssignDate } = mutation;
  const handleSubmit = useCallback((values: IAssignDateFormValues) => {
    updateAssignDate(
      {
        id:         metricsReportId,
        reportDate: values.date,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [updateAssignDate, metricsReportId, onDone]);

  return (
    <>
      <MutationStatus
        mutation={ mutation }
      />
      <Modal.Form
        formId={ FORM_ID }
        proceedTitle="Done"
        show={ isOpen }
        title="Select Date"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <>
            <p>Select the date</p>
            <Form
              formId={ formId }
              preSelectedDate={ preSelectedDate }
              onSubmit={ handleSubmit }
            />
          </>
        )}
      </Modal.Form>
    </>
  );
};

const useAssignDateModal = makeUseModal(AssignDateModal);

export { useAssignDateModal, AssignDateModal as default };
