import React, { useCallback, useState } from 'react';

import { useMarkAsNoAvailableBankStatement } from '@src/hooks/queries/bank_statements';
import { useDebouncedCallback } from '@src/hooks/utils';
import { getTransactionServiceDocumentsForBankStatement } from '@src/requests/transaction_service_documents';
import { IBankStatement } from '@src/types/bank_statements';
import { TDate, TID } from '@src/types/common';

import { formatDate, API_DATE_FORMAT } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { NoSimIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useStartDateErrorModal } from '../../bank_statement_details/error_modals/start_date_error_modal';
import { useValidationErrorModal } from '../../bank_statement_details/error_modals/validation_error_modal';

interface IMarkAsNoAvailableActionProps {
  bankStatement: IBankStatement,
  rcPaymentAccountId: TID,
  businessId: TID,
}

const MarkAsNoAvailableAction = ({
  bankStatement,
  rcPaymentAccountId,
  businessId,
}: IMarkAsNoAvailableActionProps) => {
  const [closingDate, setClosingDate] = useState<TDate | undefined>(undefined);
  const markAsNoAvailable = useMarkAsNoAvailableBankStatement();
  const { mutate } = markAsNoAvailable;

  const startDateErrorModal = useStartDateErrorModal();
  const { openWithValue: openStartDateErrorModal } = startDateErrorModal;

  const validationErrorModal = useValidationErrorModal();
  const { open: openValidationError } = validationErrorModal;

  const handleDone = useCallback((date?: TDate) => {
    mutate({
      businessId,
      reconciliationPaymentAccountId: rcPaymentAccountId,
      date:                           date || closingDate,
    }, {
      onError: (response) => {
        if (response.message
            === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_START_DATE_ERROR_MSG) {
          openStartDateErrorModal({
            rcPaymentAccountId,
            bankStatement,
          });
        }

        if (response.message
          === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG) {
          openValidationError();
        }
      },
    });
  }, [mutate,
    businessId,
    bankStatement,
    rcPaymentAccountId,
    closingDate,
    openStartDateErrorModal,
    openValidationError]);

  const handleSource = useDebouncedCallback((startDate, endDate) => {
    getTransactionServiceDocumentsForBankStatement({
      reconciliationPaymentAccountId: rcPaymentAccountId,
      startingDate:                   startDate,
      closingDate:                    endDate,
    }).then((_res) => {
      handleDone(endDate);
    });
  }, [], 300);

  const handleSetEndDate = useCallback((startDate: TDate, endDate: TDate) => {
    setClosingDate(endDate);
    handleSource(startDate, endDate);
  }, [handleSource]);

  const firstDate = new Date(bankStatement.month);
  const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
  const startingDate = bankStatement.startingDate
    ? bankStatement.startingDate
    : String(formatDate(firstDate, API_DATE_FORMAT));

  const startDate = startingDate;
  const endDate = String(formatDate(lastDate, API_DATE_FORMAT));

  return (
    <>
      <MutationStatus mutation={ markAsNoAvailable } />
      <startDateErrorModal.Component
        businessId={ businessId }
        needUpdate={ false }
        { ...startDateErrorModal.props }
      />
      <validationErrorModal.Component
        { ...validationErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG_TITLE }
      />
      <ActionsDropdown.Action
        icon={ <NoSimIcon fontSize={ 18 } /> }
        name='Mark as "No Statement Available"'
        onClick={ () => handleSetEndDate(startDate, endDate)  }
      />
    </>
  );
};

export default MarkAsNoAvailableAction;
