import React from 'react';

import { TServiceCode } from '@src/types/services';

import {
  AccountsPayableIcon,
  AccountsReceivableServiceIcon,
  BankCircleIcon,
  CategoryIcon,
  ReceiptBoxIcon,
  ReconciliationCircleIcon,
  RevenueChartIcon,
} from '@src/components/utils/icomoon';
import AccountsReceivableIcon from '@src/components/utils/icomoon/accounts_receivable_icon';
import CorporateCardsIcon from '@src/components/utils/icomoon/corporate_cards_icon';
import OpenItemsIcon from '@src/components/utils/icomoon/open_items_icon';
import {
  ExpenseIcon,
  FinancialReportsIcon,
  InboxIcon,
  VendorIcon,
  MetricsIcon,
} from '@src/components/utils/img_icons';

interface IServiceIconProps {
  serviceCode: TServiceCode,
}

const ServiceIcon = ({
  serviceCode,
}: IServiceIconProps): JSX.Element | null => {
  switch (serviceCode) {
    case window.Docyt.Common.Constants.SERVICE_TYPES.VENDORS:
      return <VendorIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORTS:
      return <ExpenseIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.CORPORATE_CARDS:
      return <CorporateCardsIcon className="service-icon-font" fontSize={ 12 } />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.BILL_PAY:
      return <AccountsPayableIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.FINANCIAL_ACCOUNTS:
      return <BankCircleIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNTING:
      return <CategoryIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.OPEN_ITEMS:
      return <OpenItemsIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.DASHBOARD:
      return <FinancialReportsIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VAULT:
      return <InboxIcon className="service-icon-font font-size-36" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE:
      return <FinancialReportsIcon className="service-icon-font" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORT_SERVICE:
      return <ExpenseIcon className="service-icon-font" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VENDOR_SERVICE:
      return <VendorIcon className="service-icon-font" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE:
      return <InboxIcon className="service-icon-font" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.AP_SERVICE:
      return <AccountsPayableIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECEIPT_SERVICE:
      return <ReceiptBoxIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE:
      return <RevenueChartIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.BANKING_ACCOUNT_SERVICE:
      return <BankCircleIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECONCILIATION_SERVICE:
      return <ReconciliationCircleIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.AR_SERVICE:
      return <AccountsReceivableServiceIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE:
      return <MetricsIcon className="icon-title service-icon-font vertical-align-middle" />;
    case window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNT_RECEIVABLES:
      return <AccountsReceivableIcon className="service-icon-font font-size-36" />;
    default:
      return null;
  }
};

export default ServiceIcon;
