import React from 'react';

interface IFormProps extends React.ComponentPropsWithoutRef<'form'> {
  children: React.ReactNode,
  id?: string,
  onSubmit: () => void
}

const Form = ({
  children,
  id,
  onSubmit,
  ...props
}: IFormProps) => {
  return (
    <form
      id={ id }
      { ...props }
      onSubmit={ onSubmit }
    >
      { children }
    </form>
  );
};

export default Form;
