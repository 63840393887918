import React from 'react';

import ImgIcon, { IImgIconProps } from './img_icons/img_icon';

interface IPaymentProcessorProps extends IImgIconProps {
  paymentProcessorCode: string,
}

const PaymentProcessorIcon = ({
  paymentProcessorCode,
  size,
  ...props
}: IPaymentProcessorProps): JSX.Element => {
  const ppImage = window.configData.payment_processor_images[paymentProcessorCode]
    || window.configData.payment_processor_images.default;

  return (
    <ImgIcon
      alt={ paymentProcessorCode }
      size={ size }
      src={ ppImage }
      { ...props }
    />
  );
};

export default PaymentProcessorIcon;
