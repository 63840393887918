import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBalanceSheetStatements } from '@src/hooks/queries/balance_sheet_informations';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { TStatementDetailsSortColumn } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';
import { formatFullMonth, API_DATE_FORMAT, endOfMonthDate, formatDate, formatApiYear } from '@src/utils/date_helpers';

import { IStatementDetailsFilter } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_details/filter/types';
import List from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_document_details/list';

import { useBalanceSheetStatementUploadModal } from '../balance_sheet_statement_modal/upload_modal/upload_modal';

interface IBalanceSheetInformationDocumentDetailsProps {
  businessId: TID,
  chartOfAccountId: TID,
  year: TYear,
  months: number,
}

const DefaultSorting: ISortingParams<TStatementDetailsSortColumn> = {
  orderColumn:    'file_name',
  orderDirection: 'asc',
};

const BalanceSheetInformationDocumentDetails = ({
  businessId,
  chartOfAccountId,
  year,
  months,
}: IBalanceSheetInformationDocumentDetailsProps): JSX.Element => {
  const filter = useFilter<IStatementDetailsFilter>({
    initData: {
      year:  year ? formatApiYear(new Date(parseInt(year, 10), 0, 1)) : formatApiYear(new Date()),
      month: months,
    },
  });

  const selectedMonthStr = formatFullMonth(new Date(parseInt(year, 10), months - 1, 1));
  const sorting = useSorting<TStatementDetailsSortColumn>(
    { defaultSorting: DefaultSorting },
  );

  const statementQuery = useGetBalanceSheetStatements({
    businessId,
    chartOfAccountId,
    filter: filter.data,
  });

  const balanceSheetStatementPages = useMemo(() => {
    return statementQuery.data?.pages || [];
  }, [statementQuery.data?.pages]);

  const balanceSheetStatements = useMemo(() => {
    return flatten(balanceSheetStatementPages.map((p) => p.collection));
  }, [balanceSheetStatementPages]);

  const chartOfAccountName = useMemo(() => {
    return balanceSheetStatementPages[0]?.meta.chartOfAccountName;
  }, [balanceSheetStatementPages]);

  const uploadModal = useBalanceSheetStatementUploadModal();

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
        preSelectedChartOfAccountId={ chartOfAccountId }
        preSelectedDate={ formatDate(endOfMonthDate(new Date(selectedMonthStr)), API_DATE_FORMAT) }
      />
      <List
        balanceSheetStatements={ balanceSheetStatements }
        businessId={ businessId }
        chartOfAccountId={ chartOfAccountId }
        chartOfAccountName={ chartOfAccountName }
        filter={ filter }
        months={ months }
        query={ statementQuery }
        sorting={ sorting }
        year={ year }
        onCloseDetailsModal={ () => statementQuery.refetch() }
        onUploadFile={ uploadModal.open }
      />
    </>
  );
};

export default BalanceSheetInformationDocumentDetails;
