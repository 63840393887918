module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider">\n  <div class="documents-cat-header font-14 font-semi-bold in-blue-400">\n    <a class="m-l-6" href="/businesses/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/reports?category=');
    
      __out.push(__sanitize(this.templateInfo.get('category')));
    
      __out.push('"> ');
    
      __out.push(__sanitize(Docyt.Common.Constants.REPORT_CATEGORY_NAMES[this.templateInfo.get('category')]));
    
      __out.push('</a>\n    <div class="client__breadcrumbs-item font-18 in-grey-600"></div>\n  </div>\n  <div class="display-flex align-items-center">\n    <a class="font-bold font-26 width-300px" href="/businesses/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/reports/');
    
      __out.push(__sanitize(this.report.get('slug')));
    
      __out.push('"> ');
    
      __out.push(__sanitize(this.report.get('name')));
    
      __out.push(' </a>\n    ');
    
      if (this.startDate && this.endDate) {
        __out.push('\n      <div class="wrap-ellipsis font-bold font-22 m-l-auto m-r-auto m-t-5">\n        ');
        __out.push(__sanitize('Total'));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('item_name')));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('value_type')));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('name')));
        __out.push('\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="wrap-ellipsis font-bold font-22 m-l-auto m-r-auto m-t-5">\n        ');
        __out.push(__sanitize(this.MONTH_SHORT_NAMES[this.itemAccountValue.get('report_month') - 1]));
        __out.push(' ');
        __out.push(__sanitize(this.itemAccountValue.get('report_year')));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('item_name')));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('value_type')));
        __out.push(' / ');
        __out.push(__sanitize(this.itemAccountValue.get('name')));
        __out.push('\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div id="lime-item-table"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}