module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade change-phone-modal\'>\n  <div class=\'modal-dialog\' style=\'width: 810px;\'>\n    <div class=\'modal-content\'>\n      <div class=\'custom-modal\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>Change Phone Number</h4>\n        </div>\n        ');
    
      if (this.currentPage === 'current_password') {
        __out.push('\n        <div class=\'modal-body\'>\n          <h1 class="font-20 text-center">Enter Password</h1>\n          <div class="m-l-auto m-r-auto m-t-20 width-300px">\n            <input type="password" class="form-control password-input" placeholder="Enter Password">\n            <p class="main-input__hint in-red-400 invalid-current-password-js"><i class="fa fa-warning"></i>Invalid Password.</p>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <button class="btn ladda-button submit-password-btn" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Continue</span>\n          </button>\n        </div>\n        ');
      } else if (this.currentPage === 'confirm_code') {
        __out.push('\n        <div class=\'modal-body\'>\n          <h1 class="font-20 text-center">Enter Secret Code</h1>\n          <p class="text-center">Enter the secret code we ');
        __out.push(__sanitize(this.sentText));
        __out.push(' you at ');
        __out.push(__sanitize(this.secretCodeTo));
        __out.push('.</p>\n          <div class="m-l-auto m-r-auto m-t-20 width-300px">\n            <input type="text" class="form-control secret-code-input" placeholder="Security Code">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i>Code is missing.</p>\n            <a class="pointer resend-secret-code-link pull-right">Resend Code</a>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <button class="btn ladda-button confirm-secret-code-btn" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Continue</span>\n          </button>\n        </div>\n        ');
      } else if (this.currentPage === 'new_phone_input') {
        __out.push('\n        <div class=\'modal-body\'>\n          <h1 class="font-20 text-center">Enter New Phone Number</h1>\n          <div class="m-t-25 text-center">\n            <input type="text" class="form-control phone-input" placeholder="Phone">\n          </div>\n          <p class="main-input__hint in-red-400 phone-invalid-js text-center"><i class="fa fa-warning"></i>Please input the phone number.</p>\n        </div>\n        <div class=\'modal-footer\'>\n          <button class="btn ladda-button send-phone-token-btn" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Continue</span>\n          </button>\n        </div>\n        ');
      } else if (this.currentPage === 'confirm_phone') {
        __out.push('\n        <div class=\'modal-body\'>\n          <h1 class="font-20 text-center">Verify New Phone Number</h1>\n          <p class="text-center">Enter the security code we just texted you at ');
        __out.push(__sanitize(this.phone));
        __out.push('.</p>\n          <div class="m-l-auto m-r-auto width-300px">\n            <input type="text" class="form-control phone-security-code-input" placeholder="Security Code">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i>Code is missing.</p>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <button class="btn ladda-button update-phone-btn" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Submit</span>\n          </button>\n        </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}