import React, { useCallback, useEffect, useState } from 'react';

import { IBankStatement } from '@src/types/bank_statements';

import FileView from '@src/components/banking_accounts/bank_statements/bank_statement_details/bank_statement_file_view';

import DropInput from './bank_statement_drop_input';

import styles from '../styles.module.scss';

interface IBankStatementUploadViewProps {
  bankStatement?: IBankStatement,
  error?: string,
  isFileUploading?: boolean,
  onUploaded: (data: any) => void,
}

const BankStatementUploadView = ({
  bankStatement,
  error,
  isFileUploading,
  onUploaded,
}: IBankStatementUploadViewProps): JSX.Element => {
  const [fileUrl, setFileUrl] = useState<any>(bankStatement?.statementFileUrl);
  const [fileName, setFileName] = useState<string | undefined>(bankStatement?.name);
  
  const handleUploadfile = useCallback((file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      setFileUrl(e?.target?.result);
    };
    setFileName(file.name);
    onUploaded(file);
  }, [onUploaded]);

  useEffect(() => {
    setFileUrl(bankStatement?.statementFileUrl)
    setFileName(bankStatement?.name)
  }, [bankStatement?.name, bankStatement?.statementFileUrl])
  
  return (
    <div className={ styles['upload-file-container'] }>
      {
        fileUrl ? (
          <FileView fileName={ fileName } fileUrl={ fileUrl } />
        ) : (
          <DropInput
            bankStatement={ bankStatement }
            error={ error }
            isFileUploading={ isFileUploading }
            onUploaded={ handleUploadfile }
          />
        )
      }
    </div>
  );
};

export default BankStatementUploadView;
