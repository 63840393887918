module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="invoice-detail-column relative">\n  <div class="checkbox checkbox-primary m-l-15 m-r-15">\n      <input type="checkbox" id="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" class="check-invoice-document pointer" data-id="');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" ');
    
      if (this.checked) {
        __out.push(__sanitize("checked"));
      }
    
      __out.push('>\n      <label for="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="invoice-detail-column relative">\n  <div class="inline-flex-wrapper m-l-10">\n      <div class="header__userpic inline-block m-r-10">\n        <img src="');
    
      __out.push(__sanitize(this.vendor_avatar_url));
    
      __out.push('" class="header__userpic-img">\n      </div>\n      <div class="p-t-10">\n        <p class="font-15 m-b-0 font-semi-bold">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</p>\n      </div>\n  </div>\n</td>\n<td class="invoice-detail-column invoice-status-panel-wrapper relative">\n  <span class="invoice-status-panel font-bold ');
    
      __out.push(__sanitize(this.label_state));
    
      __out.push('">');
    
      __out.push(__sanitize(this.check_status));
    
      __out.push('</span>\n</td>\n<td class="invoice-detail-column relative">\n  ');
    
      if (this.unread_messages_count > 0) {
        __out.push('\n  <i class="font-24 icon icon-unread relative m-l-3 unread-messages-count"><span>');
        __out.push(__sanitize(this.unread_messages_count));
        __out.push('</span></i>\n  ');
      } else {
        __out.push('\n  <!-- For Icon Read -->\n  <svg width="24px" height="24px" viewBox="0 0 24 24" class="m-l-3">\n      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n          <g id="03-Business---02-APS-Queue-01" transform="translate(-591.000000, -566.000000)" fill-rule="nonzero" stroke="#4DA1FF" stroke-width="2">\n              <g id="Group-10" transform="translate(140.000000, 368.000000)">\n                  <g id="Group-9" transform="translate(451.000000, 198.000000)">\n                      <path d="M2,20.8357864 L6.78578644,16.05 L22.7,16.05 C23.0002153,16.05 23.25,15.8002153 23.25,15.5 L23.25,1.55 C23.25,1.24978475 23.0002153,1 22.7,1 L2.55,1 C2.24978475,1 2,1.24978475 2,1.55 L2,20.8357864 Z" id="Shape"></path>\n                  </g>\n              </g>\n          </g>\n      </g>\n  </svg>\n  ');
      }
    
      __out.push('\n</td>\n<td class="invoice-detail-column relative">\n  <span class="font-14 vertical-align-top p-l-10">');
    
      __out.push(__sanitize(this.invoice_number));
    
      __out.push('</span>\n</td>\n<td class="invoice-detail-column relative">\n  <span class="font-14 vertical-align-top p-l-10">');
    
      __out.push(__sanitize(this.account_number));
    
      __out.push('</span>\n</td>\n<td class="invoice-detail-column relative">\n  <span class="font-14 vertical-align-top p-l-10">');
    
      __out.push(__sanitize(this.invoice_date_formated));
    
      __out.push('</span>\n</td>\n<td class="invoice-detail-column relative">\n  <span class="font-14 vertical-align-top p-l-10">\n    ');
    
      if (this.invoice_due_date) {
        __out.push('\n        ');
        if (this.due_dated) {
          __out.push('\n        <span class="in-red-400">Overdue by ');
          __out.push(__sanitize(this.invoice_due_date_formated));
          __out.push('</span>\n        ');
        } else {
          __out.push('\n        ');
          __out.push(__sanitize(this.invoice_due_date_formated));
          __out.push('\n        ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </span>\n</td>\n<td class="invoice-amount-column relative text-right">\n    <button class="btn dropdown-toggle font-15 font-bold invoice-amount-button ');
    
      __out.push(__sanitize(this.action_button_class));
    
      __out.push('" type="button" id="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      ');
    
      __out.push(__sanitize(this.display_amount));
    
      __out.push('\n      <span class="fa fa-angle-down m-l-5"></span>\n    </button>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}