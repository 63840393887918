import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionIcon } from '@src/components/utils/icomoon';

import Item from './item';

import styles from '../styles.module.scss';

interface IFinancialInstitutionContentProps {
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  onConnectNewFinancialInstitution: () => void,
  onSelectFinancialInstitution: (item: IFinancialInstitutionConnection) => void,
}

const FinancialInstitutionContent = ({
  financialInstitutionConnections,
  onConnectNewFinancialInstitution,
  onSelectFinancialInstitution,
}: IFinancialInstitutionContentProps): JSX.Element => {
  return (
    <>
      {
        financialInstitutionConnections.length > 0 && (
          <span>
            Select an already connected financial institution to add a new banking account
            { ' ' }
            in that financial institution.
          </span>
        )
      }
      <div className={ styles['connect-modal-content'] }>
        {
          financialInstitutionConnections.map((financialInstitutionConnection) => {
            return (
              <Item
                key={ financialInstitutionConnection.id }
                financialInstitutionConnection={ financialInstitutionConnection }
                onSelectItem={ onSelectFinancialInstitution }
              />
            );
          })
        }
      </div>
      <div className={ styles['connect-new-financial-institution'] }>
        {
          financialInstitutionConnections.length > 0 && (
            <div className="or-divider">
              <span>OR</span>
            </div>
          )
        }
        <a
          className="new-connect-btn pointer"
          role="button"
          tabIndex={ -1 }
          onClick={ onConnectNewFinancialInstitution }
        >
          <FinancialInstitutionIcon fontSize={ 18 } />
          <span>Connect to a New Financial Institution</span>
        </a>
      </div>
    </>
  );
};

export default FinancialInstitutionContent;
