import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import QBOStatusIcon from '@src/components/common/qbo_status_icon';

interface ITransactionQBOActionProps {
  transaction: ITransactionServiceDocument,
}

const TransactionQBOAction = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transaction,
}: ITransactionQBOActionProps) => {
  const classes = uiStyleClassNames(
    'display-inline-flex qbo-status-icon-column',
    {
      pull:         'left',
      widthPercent: 20,
    },
  );

  return (
    <div className={ classes }>
      <QBOStatusIcon linkToQBO model={ transaction } />
    </div>
  );
};

export default TransactionQBOAction;
