import React, { useCallback } from 'react';

import { TMonth } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { formatMonth } from '@src/utils/date_helpers';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { MonthInput } from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface IMonthFilterField<TFilter extends TFilterData> extends
Omit<React.ComponentPropsWithoutRef<typeof MonthInput>, 'name' | 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  name: keyof TFilter,
}

const MonthFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: IMonthFilterField<TFilter>) => {
  const [value, update] = useFilterField<TFilter, TMonth>(name);

  const handleChange = useCallback((month: TMonth) => {
    update(month || undefined);
  }, [update]);

  const [wrapperProps] = useFilterFieldProps(props);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatMonth(value) }
    >
      <MonthInput
        value={ value }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(MonthFilterField);
