import React, { useCallback, useState } from 'react';

import { searchVendorCustomers } from '@src/requests/adjustment_entries';
import { TEntityObj } from '@src/types/adjustment_entries';
import { TID } from '@src/types/common';

import { AsyncPaginateIconSelectInput } from '@src/components/ui_v2/inputs';

interface IVendorCustomerProps
{
  businessId: TID,
  handleSelected: (item: TEntityObj | null) => void,
  initValue: TEntityObj | null
}

const PER_PAGE = 50;

const VendorCustomerInput = ({
  businessId,
  handleSelected,
  initValue,
}: IVendorCustomerProps) => {
  const [selectedItem, setSelectedItem] = useState<TEntityObj | null>(initValue || null);

  const onChange = (item: TEntityObj | null) => {
    setSelectedItem(item);
    handleSelected(item);
  };

  const handleSource = useCallback(
    (query, options, { page }) => {
      return searchVendorCustomers({ businessId, search: query, searchQuery: query, page, perPage: PER_PAGE }).then(
        data => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          const newOptions = data.collection.map(item => ({
            label: item.name,
            value: String(item.id),
            helper: item.displayType,
            type: item.type,
            hideIcon: true,
          }));

          return {
            hasMore,
            options: newOptions,
            additional: {
              page: page + 1,
            },
          };
        }
      );
    },
    [businessId]
  );

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(selectedItem?.label as string);
      }
    }
  }, [selectedItem]);

  return (
    <AsyncPaginateIconSelectInput
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      loadOptions={ handleSource }
      placeholder="Add Name"
      value={ selectedItem }
      onChange={ onChange }
      onKeyDown={ handleKeyDown }
    />
  );
};

export default VendorCustomerInput;
