import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  uploadAvatar,
  IUploadAvatarParams,
} from '@src/requests/business_avatar';
import { IAvatar } from '@src/types/avatars';

const useUploadAvatar = () => {
  const queryClient = useQueryClient();

  return useMutation<IAvatar, Error, IUploadAvatarParams>(
    uploadAvatar,
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([QueryKey.business, params.avatarParams.advisorId]);
      },
    },
  );
};

export {
  useUploadAvatar,
};
