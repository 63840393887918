import React from 'react';

import LockDashboardsImg from '@assets/images/dashboards/locked.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const DashboardsLockedIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Dashboards locked icon"
      src={ LockDashboardsImg }
      { ...props }
    />
  );
};

export default DashboardsLockedIcon;
