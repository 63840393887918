module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title font-28 font-bold in-black">Change Bookkeeping Start Date</h4>\n            </div>\n            <div class="modal-body">\n                <div class="form-group">\n                    <label class="form-label font-16 font-semi-bold">Date</label>\n                    <input class="form-control font-14 edit-date-js" autocomplete="off">\n                </div>\n            </div>\n            <div class="modal-footer">\n                <a class=\'cancel-link cancel pointer font-bold font-18\'>Cancel</a>\n                <button type="submit" class="btn btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}