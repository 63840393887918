import { useQuery, useQueryClient, useMutation } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getVendorsW9Info,
  putVendorW9Info,
  uploadW9InfoFile,
  putVendorW9InfoFile,
  deleteVendorW9InfoFile,
  IFetchW9InfoParams,
  IUpdateW9InfoParams,
  IUploadW9InfoFileParams,
  IUpdateW9InfoFileParams,
  IUploadW9InfoFileResponse,
} from '@src/requests/vendor_w9_info';
import { IW9InfoResponse } from '@src/types/w9_info';

const useGetVendorW9Info = (vendorId: any) => {
  return useQuery<IW9InfoResponse, Error>(
    [QueryKey.vendorW9Info, vendorId],
    () => getVendorsW9Info(vendorId),
  );
};

const usePutVendorW9Info = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateW9InfoParams>(
    putVendorW9Info,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorW9Info);
      },
    },
  );
};

const useUploadW9InfoFile = () => {
  return useMutation<IUploadW9InfoFileResponse, Error, IUploadW9InfoFileParams>(
    uploadW9InfoFile,
  );
};

const usePutW9InfoFile = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateW9InfoFileParams>(
    putVendorW9InfoFile,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorW9Info);
      },
    },
  );
};

const useDeleteW9InfoFile = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IFetchW9InfoParams>(
    deleteVendorW9InfoFile,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorW9Info);
      },
    },
  );
};

export {
  useGetVendorW9Info,
  usePutVendorW9Info,
  useUploadW9InfoFile,
  usePutW9InfoFile,
  useDeleteW9InfoFile,
};
