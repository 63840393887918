import React, { useState } from 'react';

import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { SplitBillIcon } from '@src/components/utils/icomoon';

import LinkAction from './link_document_request_action';
import UnlinkAction from './unlink_document_request_action';

interface IBusinessInboxItemDocumentRequestActionProps {
  business: IBusiness,
  document: IInboxServiceDocument,
}

const BusinessInboxItemDocumentRequestAction = ({
  business,
  document,
}: IBusinessInboxItemDocumentRequestActionProps) => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  if (!document.documentRequestId) {
    return (
      <LinkAction business={ business } document={ document } />
    );
  }

  return (
    <>
      <ActionsDropdown.Action
        href={ sunsetClientCentralEnabled
          ? documentRequestsPath(business.id)
          : clientCenteralPath(business.id) }
        icon={ <SplitBillIcon fontSize={ 16 } /> }
        name="View Document Request"
      />
      <UnlinkAction document={ document } />
    </>
  );
};

export default BusinessInboxItemDocumentRequestAction;
