import React from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { TDateRange } from '@src/types/common';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { TChartType } from '@src/types/dashboards/widgets';

import ChartView from '@src/components/business_dashboards/widgets/charts/chart_view';

interface IComparisonViewProps {
  businesses?: IBusinessSimple[],
  chartType?: TChartType,
  scale?: string,
  dataSets: IDataSet[],
  dateRange: TDateRange,
}

const ComparisonView = ({
  businesses,
  chartType,
  scale,
  dataSets,
  dateRange,
}: IComparisonViewProps): JSX.Element => {
  return (
    <ChartView
      businesses={ businesses }
      chartType={ chartType }
      dataSets={ dataSets }
      dateRange={ dateRange }
      scale={ scale }
    />
  );
};

export default ComparisonView;
