import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { formatDate } from '@src/utils/date_helpers';
import { capitalize } from '@src/utils/transform_keys';

const ACH_TRANSACTION_PENDING_STATUS_TITLE = 'processing';
const ACH_TRANSACTION_PROCESSED_STATUS_TITLE = 'completed';
const ACH_TRANSACTION_CANCELLED_STATUS_TITLE = 'cancelled';
const ACH_TRANSACTION_FAILED_STATUS_TITLE = 'failed';

const getDwollaCreatedAt = (
  document: IAchTransaction,
): string => {
  return formatDate(document.dwollaCreatedAt, 'h:mma MMM DD, YYYY');
};

const statusValue = (status: string) => {
  switch (status) {
    case 'pending':
      return ACH_TRANSACTION_PENDING_STATUS_TITLE;
    case 'processed':
      return ACH_TRANSACTION_PROCESSED_STATUS_TITLE;
    case 'cancelled':
      return ACH_TRANSACTION_CANCELLED_STATUS_TITLE;
    case 'failed':
      return ACH_TRANSACTION_FAILED_STATUS_TITLE;
    default:
      return status;
  }
};

const statusVariant = (document: IAchTransaction) => {
  let variant = '';
  if (document.status === 'pending') {
    variant = 'warning';
  } else if (document.status === 'processed') {
    variant = 'success';
  } else if (document.status === 'cancelled') {
    variant = 'normal';
  } else if (document.status === 'failed') {
    variant = 'failed';
  }
  return variant;
};

const achTypeValue = (value?: string) => {
  if (!value) return null;

  return `${capitalize(value)} ACH`;
};

export {
  getDwollaCreatedAt,
  statusValue,
  statusVariant,
  achTypeValue,
};
