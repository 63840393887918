import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const MissingStatementIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="missing-statement"
      pathsNumber={ 3 }
    />
  );
});

MissingStatementIcon.displayName = 'MissingStatementIcon';

export default MissingStatementIcon;
