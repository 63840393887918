import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import { useDeleteModal } from '../profile/modal';

import styles from '../styles.module.scss';

interface IAchActionProps {
  vendor: IBusinessVendor;
  handleDeleteBank: () => void;
}

const AchBankAction = ({
  vendor,
  handleDeleteBank,
}: IAchActionProps) => {
  const deleteModal = useDeleteModal();

  return (
    <>
      <ActionsDropdown className={ styles['action-dropdown-bg'] }>
        <ActionsDropdown.Action
          danger
          className={ styles['dropdown-item'] }
          icon={ <TrashcanIcon fontSize={ 18 } /> }
          title="Delete Bank Info"
          onClick={ deleteModal.open }
        />
      </ActionsDropdown>

      <deleteModal.Component
        handleDeleteBank={ handleDeleteBank }
        text={ (
          <div className="text-left">
            <p className={ styles['modal-content'] }>
              Are you sure you want to delete the bank account info from
              this payee? This action cannot be undone.
            </p>
          </div>
        ) }
        title={ (
          <p className={ styles['modal-title'] }>
            Delete Bank Info
          </p>
        ) }
        vendor={ vendor }
        { ...deleteModal.props }
      />
    </>
  );
};

export default React.memo(AchBankAction);
