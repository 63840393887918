import React, { useMemo } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TUserRole } from '@src/types/users';

import Dropdown from '@src/components/ui/dropdown';
import { AngleDownIcon } from '@src/components/utils/fa_icons';
import { useItemsSelector } from '@src/components/utils/items_selector';

import DocytAchAction from './docyt_ach_action';
import DocytCheckAction from './docyt_check_action';
import MarkAsPaidBankAction from './mark_as_paid_bank_action';
import MarkAsPaidCheckAction from './mark_as_paid_check_action';
import MarkAsPaidSettlementAction from './mark_as_paid_settlement_action';

interface IBulkActionsProps {
  userRole?: TUserRole,
}

const BulkActions = ({
  userRole,
}: IBulkActionsProps): JSX.Element => {
  const selector = useItemsSelector<IAccountsPayableServiceDocument>();

  const invoiceIds = useMemo(() => {
    return selector.selected.map((d) => d.id) || [];
  }, [selector]);

  const { canPayViaAch, canPayViaDocyt, canMarkPaid } = useMemo(() => {
    let canPayViaAchFlag = false;
    let canPayViaDocytFlag = false;
    let canMarkPaidFlag = false;

    if (selector.selected.length !== 0) {
      const isSameState = (selector.selected)
        .filter(
          (item) => !window.configData.ap_invoice_payable_states.includes(item.state),
        ).length === 0;
      const vendorId = selector.selected[0].vendor?.id;
      const isSameVendor = (selector.selected)
        .filter((item) => item.vendor?.id !== vendorId).length === 0;

      if (isSameState && isSameVendor) {
        if (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER') {
          canPayViaAchFlag = true;
          canPayViaDocytFlag = true;
        }
        if (userRole !== 'UPLOADER') {
          canMarkPaidFlag = true;
        }
      }
    }

    return {
      canPayViaAch:   canPayViaAchFlag,
      canPayViaDocyt: canPayViaDocytFlag,
      canMarkPaid:    canMarkPaidFlag,
    };
  }, [selector.selected, userRole]);

  return (
    <Dropdown
      className="bulk-action-container"
    >
      <Dropdown.ButtonToggle
        className="btn-blue-on-hover"
        disabled={ selector.selected.length === 0
          || !(canPayViaDocyt || canPayViaAch || canMarkPaid) }
        fontSize={ 15 }
        fontVariant="bold"
      >
        Bulk Actions
        <AngleDownIcon ml={ 5 } />
      </Dropdown.ButtonToggle>
      <Dropdown.Menu
        className="toggle-action-dropdown"
      >
        {
          canPayViaDocyt && (
            <DocytCheckAction invoiceIds={ invoiceIds } />
          )
        }
        {
          canPayViaAch && (
            <DocytAchAction invoiceIds={ invoiceIds } />
          )
        }
        {
          canMarkPaid && (
            <>
              <MarkAsPaidCheckAction invoiceIds={ invoiceIds } />
              <MarkAsPaidBankAction invoiceIds={ invoiceIds } />
              <MarkAsPaidSettlementAction invoiceIds={ invoiceIds } />
            </>
          )
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export {
  IBulkActionsProps,
  BulkActions,
};
