import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ArchiveVendorIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="vendor-archive"
      pathsNumber={ 11 }
    />
  );
};

export default ArchiveVendorIcon;
