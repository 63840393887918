import { useMemo } from 'react';

import { Section } from '@src/constants/sections';
import {
  useGetRelatedDocumentsByAchTransaction,
} from '@src/hooks/queries/documents';
import { useSorting } from '@src/hooks/url_params';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TID, TSection } from '@src/types/common';
import { IDocument, TDocumentsSortColumn } from '@src/types/documents';
import { ISorting, ISortingParams } from '@src/types/sorting';

interface IUseAchRelatedDocumentsCollectionParams {
  businessId: TID,
  serviceId: TID,
  document: IAchTransaction,
}

interface IAchRelatedDocumentsCollection {
  records: IDocument[],
  section: TSection,
  sorting: ISorting<TDocumentsSortColumn>,
}

const defaultSorting: ISortingParams<TDocumentsSortColumn> = {};

const useAchRelatedDocumentsCollection = ({
  businessId,
  serviceId,
  document,
}: IUseAchRelatedDocumentsCollectionParams): IAchRelatedDocumentsCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AchTransactionDocuments,
    };
  }, [businessId]);

  const sorting = useSorting<TDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetRelatedDocumentsByAchTransaction({ serviceId, document });

  const records = query.data?.collection || [];

  return {
    records,
    section,
    sorting,
  };
};

export {
  IAchRelatedDocumentsCollection,
  useAchRelatedDocumentsCollection,
};
