/* eslint-disable no-shadow */
import { TDate, TDateTime, TUUID } from '../common';

export type TMetricReportsSortColumn = 'start_date' | 'display_total_metrics_report' | string;

export interface IMetricsReport {
  id: TUUID,
  createdAt: TDateTime,
  error?: string | null,
  fileUuid: TUUID,
  filename: string,
  metricServiceId: TUUID,
  reportDate?: TDate,
  status: MetricReportStatusEnum,
  updatedAt: TDateTime,
  uploaderName?: string,
}

export enum MetricReportStatusEnum {
    SUCCESS = 'success',
    DATE_ASSIGNED = 'date_assigned',
    DATE_UNASSIGNED = 'date_unassigned',
    FAILED = 'failed',
    PENDING = 'pending',
    PROCESSING = 'processing',
    QUEUED = 'queued',
}
