import React, { useCallback } from 'react';

import classNames from 'classnames';

import { CaretIcon } from '@src/components/utils/fa_icons';

import { useDropdown } from './hooks';

import styles from './styles.module.scss';

interface IDropdownInputIndicatorProps extends React.ComponentPropsWithoutRef<'button'> {
  children?: React.ReactNode
  onClick?: () => void;
}

const DropdownInputIndicator = ({
  children,
  className,
  onClick,
  ...props
}: IDropdownInputIndicatorProps) => {
  const { toggle } = useDropdown() || {};

  const classes = classNames(styles['dropdown-input-indicator'], className);

  const handleClick = useCallback((e) => {
    e.preventDefault();

    if (onClick) {
      onClick();
      return;
    }

    if (toggle) toggle();
  }, [onClick, toggle]);

  return (
    <button
      className={ classes }
      type="button"
      onClick={ handleClick }
      { ...props }
    >
      { children || <CaretIcon variant="down" /> }
    </button>
  );
};

export default React.memo(DropdownInputIndicator);
