import React from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';

import FlaggedTransactionContext from './flagged_transaction_context';
import UnFlaggedTransactionContext from './unflagged_transaction_context';

const ViewTransactionContext: React.FC = () => {
  const { data } = useTCDContext();

  if (data.isFlaggedOrHasMessage) {
    return <FlaggedTransactionContext />;
  }
  return <UnFlaggedTransactionContext />;
};

export default ViewTransactionContext;
