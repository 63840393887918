import React from 'react';

import { TID } from '@src/types/common';
import { IReconciliationStatus } from '@src/types/service_statuses';

import Table from '@src/components/ui/table';

import styles from '../styles.module.scss';

interface IReconciliationStatusProps {
  clientBusinessId: TID;
  status?: IReconciliationStatus,
}

const ReconciliationStatus = ({
  clientBusinessId,
  status,
}: IReconciliationStatusProps) => {
  let content;
  if (!status || status.countQueued === 0) {
    content = (
      <span className={ styles['non-status'] }>-</span>
    );
  } else {
    content = (
      <a
        className={ styles['client-business-status'] }
        href={ `/businesses/${clientBusinessId}/reconciliation_center` }
        role="button"
        tabIndex={ 0 }
      >
        { status.countQueued }
        { ' ' }
        { status.countQueued === 1 ? 'Transaction' : 'Transactions' }
      </a>
    );
  }
  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 10 }
    >
      { content }
    </Table.Cell>
  );
};

export default ReconciliationStatus;
