import { apiPost } from '@src/requests/helpers';

const showLinkPlaidWindow = (linkToken: string) => {
  const plaidURL = `/plaids/new?link_token=${linkToken}`;
  window.open(
    plaidURL,
    'popUpWindow',
    'height=700, width=800, left=100, top=100, resizable=yes, scrollbars=yes,'
      + 'toolbar=yes, menubar=no, location=no, directories=no, status=yes',
  );
};

const openPlaidConnectWindow = (product: string, businessId: number, isSingleSelect = false) => {
  const params = { product, business_id: businessId, is_single_select: false };
  if (isSingleSelect) {
    params.is_single_select = true;
  }

  apiPost(
    '/api/v1/plaid_items/generate_link_token',
    params,
  ).then((data) => {
    window.open(
      `/plaids/new?link_token=${data.link_token}`,
      'popUpWindow',
      'height=700, width=800, left=100, top=100, resizable=yes, scrollbars=yes, toolbar=yes,'
      + ' menubar=no, location=no, directories=no, status=yes',
    );
  });
};

export {
  showLinkPlaidWindow,
  openPlaidConnectWindow,
};
