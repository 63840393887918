import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IReportsFileResponse } from '@src/requests/report_service/custom_reports';
import {
  IGetReportServiceBudgetItemsParams,
  IGetReportServiceBudgetItemsResponse,
  IUpdateBudgetItemParams,
  IBudgetsFileParams,
  getReportServiceBudgetItems,
  updateBudgetItem,
  exportBudget, ICalculateBudgetItemParams, calculateBudgetItem,
  ICalculateValuesPerMonthItemParams,
  calculateValuesPerMonthItem,
  showPerMonthItem,
  ICalculateValuesPerMonthBudgetItemParams,
} from '@src/requests/report_service/report_service_budget_items';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';

import {
  createUseGetInfiniteCollection,
  updateItemsInInfiniteCollection,
} from '../infinite_collection_queries';

const useGetReportServiceBudgetItems = createUseGetInfiniteCollection<
  IReportServiceBudgetItem,
  IGetReportServiceBudgetItemsParams,
  IGetReportServiceBudgetItemsResponse
>({
  queryKey: QueryKey.reportServiceBudgetItems,
  request:  getReportServiceBudgetItems,
});

const useUpdateReportServiceBudgetItem = (invalidateQuery = false) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateBudgetItemParams>(
    updateBudgetItem,
    {
      onSuccess: () => {
        if(invalidateQuery) queryClient.invalidateQueries(QueryKey.reportServiceBudgetItems);
      },
    },
  );
};

const useCalculateReportServiceBudgetItem = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ICalculateBudgetItemParams>(
    calculateBudgetItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reportServiceBudgetItems);
      },
    },
  );
};

const useCalculateReportServiceValuesPerMonthItem = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ICalculateValuesPerMonthItemParams>(
    calculateValuesPerMonthItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reportServiceBudgetItems);
      },
    },
  );
};

const useShowPerMonthItem = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ICalculateValuesPerMonthBudgetItemParams>(
    showPerMonthItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reportServiceBudgetItems);
      },
    },
  );
};

const useExportBudgetAsExcel = () => {
  return useMutation<IReportsFileResponse, Error, IBudgetsFileParams>(exportBudget);
};

export {
  useGetReportServiceBudgetItems,
  useExportBudgetAsExcel,
  useUpdateReportServiceBudgetItem,
  useShowPerMonthItem,
  useCalculateReportServiceBudgetItem,
  useCalculateReportServiceValuesPerMonthItem,
};
