module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, possible_match, ref, ref1;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header text-center">\n        <span class="font-28 font-bold m-l-15">Possible Payment Match</span>\n      </div>\n\n      <div class="modal-body possible-match-modal table-responsive">\n        <p>Following are the potential payment options available for <span class="font-semi-bold">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</span>.</p>\n\n        <h3>Invoice Details</h3>\n        <table class="table">\n          <thead>\n            <tr>\n              <th style="width: 40%" class="font-13 font-semi-bold">Vendor</th>\n              <th style="width: 20%" class="font-13 font-semi-bold">Invoice ID</th>\n              <th style="width: 20%" class="font-13 font-semi-bold">Date</th>\n              <th style="width: 20%" class="font-13 font-semi-bold">Amount</th>\n            </tr>\n          </thead>\n          <tbody>\n            <tr>\n              <td>\n                <div class="display-flex">\n                  <div class="header__userpic inline-block m-t-5">\n                    <img src="');
    
      __out.push(__sanitize(this.vendor_avatar_url));
    
      __out.push('" class="header__userpic-img">\n                  </div>\n                  <div class="">\n                    <p class="m-l-8 m-t-15 vertical-align-top">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</p>\n                  </div>\n                </div>\n              </td>\n              <td>\n                <a class="pointer font-semi-bold clickable-invoice">');
    
      __out.push(__sanitize(this.invoice.get('document_docyt_id')));
    
      __out.push('</a>\n              </td>\n              <td><span class="text-muted">');
    
      __out.push(__sanitize(moment(this.invoice.get('invoice_date')).format('MM/DD/YYYY')));
    
      __out.push('</span></td>\n              <td><span class="font-bold">');
    
      __out.push(__sanitize(parseFloat(this.invoice.get('amount')).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</span></td>\n            </tr>\n          </tbody>\n        </table>\n\n        <h3>Transaction Details</h3>\n        <table class="table">\n          <thead>\n            <tr>\n              <th style="width: 40%" class="font-13 font-semi-bold">Description</th>\n              <th style="width: 20%" class="font-13 font-semi-bold">Transaction ID</th>\n              <th style="width: 10%" class="font-13 font-semi-bold">Date</th>\n              <th style="width: 20%" class="font-13 font-semi-bold">Payment Account</th>\n              <th style="width: 10%" class="font-13 font-semi-bold">Amount</th>\n            </tr>\n          </thead>\n          <tbody>\n            ');
    
      ref = this.possible_matches;
      for (i = 0, len = ref.length; i < len; i++) {
        possible_match = ref[i];
        __out.push('\n              <tr>\n                <td>\n                  ');
        if (this.possible_matches.length > 1) {
          __out.push('\n                    <div class="checkbox checkbox-primary checkbox-circle m-t-0 m-b-0">\n                      <input\n                        name="possible-match-radio"\n                        id="possible-match-');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                        type="radio"\n                        value="');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                        class="possible-match-radio hidden"\n                        ');
          if (((ref1 = this.selected_possible_match) != null ? ref1.possible_match_id : void 0) === possible_match.possible_match_id) {
            __out.push(__sanitize('checked'));
          }
          __out.push('\n                      />\n                      <label\n                        class="forward-to-service-checkbox-label"\n                        for="possible-match-');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                      >\n                        ');
          __out.push(__sanitize(possible_match.description));
          __out.push('\n                      </label>\n                    </div>\n                  ');
        } else {
          __out.push('\n                    ');
          __out.push(__sanitize(possible_match.description));
          __out.push('\n                  ');
        }
        __out.push('\n                </td>\n                <td><a class="pointer font-semi-bold clickable-txn" data-docyt-id="');
        __out.push(__sanitize(possible_match.docyt_id));
        __out.push('">');
        __out.push(__sanitize(possible_match.docyt_id));
        __out.push('</a></td>\n                <td><span class="text-muted">');
        __out.push(__sanitize(possible_match.transaction_date));
        __out.push('</span></td>\n                <td><span class="text-muted">');
        __out.push(__sanitize(possible_match.payment_account_name));
        __out.push('</span></td>\n                <td><span class="font-bold">');
        __out.push(__sanitize(parseFloat(possible_match.amount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        })));
        __out.push('</span></td>\n              </tr>\n            ');
      }
    
      __out.push('\n          </tbody>\n        </table>\n        ');
    
      if (this.possible_matches.length > 1) {
        __out.push('\n          <input name="possible-match-radio" type="radio" value="" class="hidden" ');
        if (this.selected_possible_match == null) {
          __out.push(__sanitize('checked'));
        }
        __out.push(' />\n        ');
      }
    
      __out.push('\n      </div>\n\n      <div class="modal-footer">\n        <a class=\'cancel-btn pointer cancel-link modal-link-btn pull-left\'>Close</a>\n        <button type="button" ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' class="btn btn-default not-a-match-btn ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push('">Not A Match</button>\n        <button type="button" ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' class="btn btn-blue mark-as-paid-btn ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push('">Mark As Paid</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}