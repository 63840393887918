import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import RelatedJournalEntriesSection from './related_journal_entries_section';

interface IRelatedJournalEntriesProps {
  documentId: TID,
}

const RelatedJournalEntries = ({ documentId }: IRelatedJournalEntriesProps): JSX.Element => {
  return (
    <Root>
      <RelatedJournalEntriesSection documentId={ documentId } />
    </Root>
  );
};

export default React.memo(RelatedJournalEntries);
