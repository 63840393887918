import { createContext, useContext } from 'react';

import { IMetric } from '@src/types/metrics';

const BusinessMetricContext = createContext<IMetric | null>(null);

const useBusinessMetricContext = (): IMetric => {
  const value = useContext(BusinessMetricContext);

  if (!value) {
    throw new Error('"useBusinessMetricContext" must be used within <BusinessMetricProvider />');
  }

  return value;
};

export {
  BusinessMetricContext,
  useBusinessMetricContext,
};
