import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IFilter } from '@src/types/filter';

import {
  AccountTypeSelectFilterField,
  AccountingClassSelectFilterField,
} from '@src/components/common/filter_fields';
import Filter from '@src/components/ui/filter';

import { IBudgetItemsFilter } from './types';

interface IBudgetItemsFilterProps {
  filter: IFilter<IBudgetItemsFilter>;
}

const BudgetItemsFilter = ({ filter }: IBudgetItemsFilterProps) => {
  const business = useBusinessContext();

  return (
    <Filter<IBudgetItemsFilter> className="budget-filters display-flex width-100-percent" filter={ filter }>
      <Filter.TextField
        className="width-20-percent"
        name="chartOfAccountDisplayName"
        placeholder="Chart of Account"
      />
      <AccountTypeSelectFilterField
        accountTypes="P&L"
        className="transaction-filter-status width-15-percent"
        title="Type"
      />
      <AccountingClassSelectFilterField
        className="transaction-filter-status width-15-percent"
        classes={ business.accountingClasses }
        title="Department"
      />
      <Filter.CheckboxField
        className="checkbox-filter checkbox-primary m-t-10 m-r-20"
        label="Hide blank rows"
        name="hideBlank"
      />
    </Filter>
  );
};

export default React.memo(BudgetItemsFilter);
