import { TMongoID } from '@src/types/common';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetDataSetsWithValuesParams {
  dashboardId: TMongoID,
  widgetId: TMongoID,
  from: string,
  to: string,
}

interface IGetDataSetsWithValuesResponse {
  meta: {
    criticalNumber: number,
  },
  dataSets: IDataSet[],
}

const getDataSetsWithValues = (
  params: IGetDataSetsWithValuesParams,
): Promise<IGetDataSetsWithValuesResponse> => {
  return apiGet(
    '/dashboards/api/v1/data_sets/with_values_by_widget_id.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetDataSetsWithValuesResponse);
};

export {
  IGetDataSetsWithValuesParams,
  IGetDataSetsWithValuesResponse,
  getDataSetsWithValues,
};
