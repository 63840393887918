import React, { FC } from 'react';

import { TID } from '@src/types/common';

import useEditModal from '@src/components/chart_of_accounts/edit_btn/edit_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';

const EditBtn: FC<{ id: TID, businessId: TID }> = ({ id, businessId }) => {
  const editModal = useEditModal();

  // Using Drawer directly with ActionsDropdown will cause layout issues.
  return (
    <>
      <ActionsDropdown.LinkAction
        href="#"
        icon={ <EditIcon fontSize={ 16 } /> }
        title="Edit"
        onClick={ editModal.open }
      />
      <editModal.Component
        { ...editModal.props }
        businessId={ businessId }
        id={ id }
      />
    </>
  );
};

export default EditBtn;
