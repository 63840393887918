import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const FinancialInstitutionIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="financial-institution"
      pathsNumber={ 14 }
    />
  );
};

export default FinancialInstitutionIcon;
