import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import styles from './styles.module.scss';

interface IAuthorizationAddressProps extends IUseModalProps {
  icon?: JSX.Element,
  text: JSX.Element | string,
  title: JSX.Element | string,
}

const AddressAuthorizationModal = ({
  icon,
  isOpen,
  text,
  title,
  onCancel,
}: IAuthorizationAddressProps): JSX.Element => {
  return (
    <Modal className={ styles['modal-authorization'] } show={ isOpen } title={ title }>
      <Modal.Body>
        {icon && (
          <div className="text-center delete-icon-wrapper">
            <div className="inline-block relative">
              {icon}
            </div>
          </div>
        )}
        <p className="p-b-0 text-center font-18">{text}</p>
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <Button
          className="btn-outline"
          variant="link"
          onClick={ onCancel }
        />
        <Button
          className="btn-primary"
          variant="primary"
          onClick={ onCancel }
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useAddressAuthorizationModal = makeUseModal(AddressAuthorizationModal);
export {
  IAuthorizationAddressProps,
  useAddressAuthorizationModal,
  AddressAuthorizationModal as default,
};
