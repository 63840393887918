import React from 'react';

import { TID } from '@src/types/common';

import BankStatements from '@src/components/reconciliation_center/month_end_documents/bank_statements/bank_statements';

import ReviewedView from './reviewed';

interface IReviewedSectionProps {
  businessId: TID,
}

const ReviewedSection = ({
  businessId,
}: IReviewedSectionProps): JSX.Element => {
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  if (bankStatementsDocRequestEnabled) {
    return (
      <BankStatements businessId={ businessId } />
    );
  }

  return (
    <ReviewedView businessId={ businessId } />
  );
};

export default ReviewedSection;
