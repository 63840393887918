import React from 'react';

import newPhotoLogoImg from '@assets/images/revenue_service/deposit-tracking-ftu.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const DepositTrackingFtuLogo = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Revenue Center Logo"
      src={ newPhotoLogoImg }
      { ...props }
    />
  );
};

export default DepositTrackingFtuLogo;
