import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAddMetric } from '@src/hooks/queries/metrics';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IAddMetricsFormValues } from './add_metrics_form';

interface IAddMetricsProps extends IUseModalProps {
}

const AddMetrics = ({
  isOpen,
  onCancel,
  onDone,
}: IAddMetricsProps) => {
  const addMetrics = useAddMetric();
  const business = useBusinessContext();
  const { mutate } = addMetrics;

  const handleSubmit = useCallback((values: IAddMetricsFormValues) => {
    mutate(
      {
        ...values,
        businessId:         business.id,
        inputDataMode:      'Integration',
        standardIndustryId: business.standardCategoryId,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [business, onDone, mutate]);

  return (
    <>
      <MutationStatus mutation={ addMetrics } />
      <Modal.Form
        proceedTitle="Continue"
        show={ isOpen }
        title="Add New Metrics"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <Form
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        ) }
      </Modal.Form>
    </>
  );
};

const useAddMetricsModal = makeUseModal(AddMetrics);

export {
  IAddMetricsProps,
  useAddMetricsModal,
  AddMetrics as default,
};
