/* eslint-disable import/order */
import React, { useCallback, useRef } from 'react';

import ChatScreen from './chat';
import ChatInput from './chat_input';

import { useAiChatBotContext } from '../context/ai_chat_context';
import { useMessageListener } from '../hooks/use_message_listener';

import styles from '../styles.module.scss';

const ChatBody = () => {
  const { activeThreadId, activeChannelID } = useAiChatBotContext();
  const autoScrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    autoScrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [autoScrollRef]);

  const { message, addMessage, loader, setLoader } = useMessageListener({
    scrollToBottom,
  });

  return (
    <div className={ styles['chat-content-section'] }>
      <div className={ styles['chat-message-container'] }>
        <div className={ styles['chat-top-section'] }>
          <ChatScreen
            ref={ autoScrollRef }
            activeChannelID={ activeChannelID }
            activeThreadId={ activeThreadId }
            addMessage={ addMessage }
            loader={ loader }
            message={ message }
          />
        </div>
        <ChatInput
          loader={ loader }
          setLoader={ setLoader }
        />
      </div>
    </div>
  );
};

export default ChatBody;
