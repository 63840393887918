import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ManualTransactionIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="manually-added-txn"
      pathsNumber={ 2 }
    />
  );
};

export default ManualTransactionIcon;
