module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table expense-table-view bg-white" style="table-layout: fixed;">\n  <colgroup span="11">\n    <col width="30"></col>\n    <col width="40"></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n    <col width="50"></col>\n  </colgroup>\n  <thead>\n    <tr>\n      <th class="relative" style="width: 30px;"></th>\n      <th></th>\n      <th colspan="1" class="pointer relative sortable sorted-desc" data-orderby="payment_processor_id" data-orderdir="ASC">Payment Processor</th>\n      <th colspan="1" class="pointer relative sortable sorted-desc" data-orderby="payment_account" data-orderdir="ASC">Account</th>\n      <th colspan="1" class="pointer relative sortable" data-orderby="transaction_date" data-orderdir="ASC">Date</th>\n      <th colspan="1" class="pointer relative sortable" data-orderby="description" data-orderdir="ASC">Description</th>\n      <th colspan="1" class="pointer relative sortable" data-orderby="transaction_type" data-orderdir="ASC">Type</th>\n      <th colspan="1" class="pointer relative sortable" data-orderby="amount" data-orderdir="ASC" style="width: 100px;">Amount</th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n      <th class="relative" style="width: 50px;"></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}