module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-lg\'>\n    <div class=\'modal-content\'>\n      <!-- Main First Page Begin -->\n      <div class=\'modal-notification-process main-select-page\'>\n        <div class=\'modal-header\'>\n          <div class="pull-left invite-creator-avatar">\n            ');
    
      if (this.avatar) {
        __out.push('\n              <img src="');
        __out.push(__sanitize(this.avatar));
        __out.push('" class=\'avatar\'>\n            ');
      } else {
        __out.push('\n              <i class="icon icon-smile"></i>\n            ');
      }
    
      __out.push('\n          </div>\n          <button class=\'close\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>\n            Invitation to join ');
    
      __out.push(__sanitize(this.business_name));
    
      __out.push('\n          </h4>\n          <h5 class=\'modal-sub-title text-center\'>');
    
      __out.push(__sanitize(this.email));
    
      __out.push(' | ');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</h5>\n          <p class="text-center">You have been invited by ');
    
      __out.push(__sanitize(this.full_name));
    
      __out.push(' to join ');
    
      __out.push(__sanitize(this.business_name));
    
      __out.push(' as ');
    
      __out.push(__sanitize(this.recipient_type));
    
      __out.push('</p>\n        </div>\n        <div class=\'modal-body\'>\n          <div class=\'main-input__wrap\'>\n            <button class=\'select-invite-option-button select-accept-js\' type=\'button\'>\n              <p class="process-description">\n                <i class="icon icon-accept-invite invite-icon"></i>\n                <span>Accept invite and add ');
    
      __out.push(__sanitize(this.name));
    
      __out.push(' as a new contact.</span>\n              </p>\n            </button>\n            <button class=\'select-invite-option-button select-reject-js\' type=\'button\'>\n              <p class="process-description">\n                <i class="icon icon-reject-invite invite-icon"></i>\n                <span>Reject invite.</span>\n                <i class="fa fa-spinner fa-pulse rejecting-spinner"></i>\n              </p>\n            </button>\n            <a class="select-spam-js in-blue-400 pointer">I do not know ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('. This looks like spam.</a>\n          </div>\n        </div>\n      </div>\n      <!-- Main First page End -->\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}