import React from 'react';

import Root from '@src/components/root';

import RegisterBody from '../components/register_body';

interface RegisterBodyParms {
  email: string;
  password: string;
  ssoToken: string;
}
export interface IRegisterProps {
  verifySSOIdentity: (
    body: RegisterBodyParms,
    callBackFun: () => void,
    errorHandler: (message: string, bool: boolean) => void
  ) => void;
}

const Register = ({ verifySSOIdentity }: IRegisterProps) => {
  return (
    <Root>
      <RegisterBody verifySSOIdentity={ verifySSOIdentity } />
    </Root>
  );
};

export default Register;
