import React from 'react';

import Root from '@src/components/root';

import DetailsPage from './client_dashboard_details_page';

const ClientDashboard = (): JSX.Element => {
  return (
    <Root>
      <DetailsPage />
    </Root>
  );
};

export default ClientDashboard;
