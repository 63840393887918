import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmDeleteDocumentModalParams extends IUseModalParams {
  document: IInboxServiceDocument,
}

interface IUseConfirmDeleteDocumentModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
}

const ConfirmDeleteDocumentTitle = ({ document }: { document: IInboxServiceDocument }) => (
  <>
    { document.name }
    { ' ' }
    will be moved to Trash.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmDeleteDocumentModal = ({
  document,
  ...modalParams
}: IUseConfirmDeleteDocumentModalParams): IUseConfirmDeleteDocumentModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'danger',
      confirmTitle: 'Delete',
      icon:         <DeleteDocumentIcon />,
      text:         (
        <ConfirmDeleteDocumentTitle document={ document } />
      ),
      title: `Delete ${document.name}?`,
    },
  };
};

export {
  useConfirmDeleteDocumentModal,
};
