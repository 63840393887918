import React, { forwardRef, useCallback } from 'react';

import NumberFormat from 'react-number-format';

import { TRichAmount } from './types';
import { amountInputProps } from './utils';

import styles from './styles.module.scss';

interface IRichAmountRangeControlsProps {
  value: TRichAmount | undefined,
  onChange: (value: TRichAmount | undefined) => void,
}

const updateValue = (
  value: TRichAmount | undefined,
  field: 'gte' | 'lte',
  newValue: string,
): TRichAmount | undefined => {
  if (!value && !newValue) return undefined;

  const updated = { ...value, val: undefined, [field]: newValue };
  if (!updated.gte && !updated.lte) return undefined;

  return updated;
};

const RichAmountRangeControls = forwardRef(({
  value,
  onChange,
}: IRichAmountRangeControlsProps, ref) => {
  const handleMinChange = useCallback((minValue) => {
    onChange(updateValue(value, 'gte', minValue?.value));
  }, [value, onChange]);

  const handleMaxChange = useCallback((maxValue) => {
    onChange(updateValue(value, 'lte', maxValue?.value));
  }, [value, onChange]);

  return (
    <>
      <NumberFormat
        { ...amountInputProps }
        className={ styles['rich-amount-control'] }
        getInputRef={ ref }
        placeholder="$ 0.00"
        value={ value?.gte || '' }
        onValueChange={ handleMinChange }
      />
      <span>–</span>
      <NumberFormat
        { ...amountInputProps }
        className={ styles['rich-amount-control'] }
        placeholder="$ 0.00"
        value={ value?.lte || '' }
        onValueChange={ handleMaxChange }
      />
    </>
  );
});

RichAmountRangeControls.displayName = 'RichAmountRangeControls';

export default React.memo(RichAmountRangeControls);
