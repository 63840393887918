module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, len, len1, line, ref, ref1;
    
      __out.push('<div class="transaction-detail-view">\n  ');
    
      if (!this.is_multiple_document) {
        __out.push('\n    <div class="view-function qbo-only">\n      ');
        if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['DISABLED']) {
          __out.push('\n        <div class="p-t-7"><span class="icon-qbo-block font-24 vertical-align-middle m-r-10 qb-block-tooltip-js" data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
          __out.push(__sanitize(this.qb_block_tooltip));
          __out.push('"></span>View in QuickBooks</div>\n      ');
        } else if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['SYNCED']) {
          __out.push('\n        <a class="view-qbo p-t-7" href="');
          __out.push(__sanitize(this.qbo_link_url ? this.qbo_link_url.qbo_link : '#'));
          __out.push('" target="_blank"><span class="icon-qbo-on vertical-align-middle font-22 m-r-10"></span>View in QuickBooks</a>\n      ');
        } else if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['STARTED']) {
          __out.push('\n        <div class="p-t-7"><span class="icon-qbo-off font-22 vertical-align-middle m-r-10"></span>View in QuickBooks</div>\n      ');
        } else {
          __out.push('\n        <div class="p-t-7"><span class="icon-qbo-error font-22 vertical-align-middle m-r-10"></span>View in QuickBooks</div>\n      ');
        }
        __out.push('\n    </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
        __out.push('\n    ');
        if (this.is_multiple_document) {
          __out.push('\n      <div class="m-t-10">\n        <div class="document-field-item document-field-name">From</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">To</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n    ');
        } else {
          __out.push('\n      <div id="transfer-from">\n        <div aria-controls="transfer-from-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#transfer-from-collapse" data-toggle="collapse" aria-expanded="true">\n          <div>\n            <i class="fa fa-caret-down m-r-5 m-t-10"></i>\n            <span class="font-bold">From</span>\n          </div>\n          <div class="relative inline-block">\n            <a class="btn dropdown-toggle" type="button" id="transaction-from-');
          __out.push(__sanitize(this.id));
          __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n              <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n            </a>\n            ');
          if (parseFloat(this.amount) < 0 || parseFloat(this.amount) > 0 && this.matchedDocument) {
            __out.push('\n            <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="transaction-from-');
            __out.push(__sanitize(this.id));
            __out.push('-action-dropdown-btn">\n              <li><a tabindex="-1" href="/document/');
            __out.push(__sanitize(this.fromDocumentId));
            __out.push('"><i class="fa fa-file-text-o m-l-3 m-r-18"></i> <span>View Transaction Detail</span></a></li>\n              <li><a tabindex="-1" class="transaction-from-send-message-btn"><span class="icon-chat-round m-r-18"></span> Send a Message</a></li>\n            </ul>\n            ');
          }
          __out.push('\n          </div>\n        </div>\n      </div>\n      <div aria-labelledby="transfer-from" class="p-l-10 p-r-10 collapse in background-white document-detail-sidebar-section-body" id="transfer-from-collapse" aria-expanded="true" style="">\n        ');
          if (parseFloat(this.amount) < 0) {
            __out.push('\n          <div>\n            <div class="document-field-item document-field-name">Date</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Description</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.description));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Account</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.reconciliation_payment_account ? this.reconciliation_payment_account.name : ''));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Category</div>\n            <div class="document-field-item document-field-value">\n              ');
            if (this.ledger_category_names) {
              __out.push('\n              ');
              __out.push(__sanitize(this.ledger_category_names));
              __out.push('\n              ');
            } else {
              __out.push('\n              ');
              __out.push(__sanitize(this.chart_of_account ? this.chart_of_account.name : '-'));
              __out.push('\n              ');
            }
            __out.push('\n            </div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Amount</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Verified By</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.verified_by ? this.verified_by : 'Automatically'));
            __out.push('</div>\n          </div>\n        ');
          } else if (this.matchedDocument) {
            __out.push('\n          <div>\n            <div class="document-field-item document-field-name">Date</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(moment(this.matchedDocument.date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Description</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.description));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Account</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.account_vendor_name));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Category</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.coa ? this.matchedDocument.coa.name : '-'));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Amount</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(this.matchedDocument.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Verified By</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.verified_by ? this.verified_by : 'Automatically'));
            __out.push('</div>\n          </div>\n        ');
          } else {
            __out.push('\n          <span class="font-15 pointer in-blue-400 inline-block p-l-20 m-t-20 m-b-20 add-transaction"><span class="icon-plus-circle m-r-18"></span>Add Transaction</span>\n        ');
          }
          __out.push('\n      </div>\n      <div id="transfer-to">\n        <div aria-controls="transfer-to-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#transfer-to-collapse" data-toggle="collapse" aria-expanded="true">\n          <div>\n            <i class="fa fa-caret-down m-r-5 m-t-10"></i>\n            <span class="font-bold">To</span>\n          </div>\n          <div class="relative inline-block">\n            <a class="btn dropdown-toggle" type="button" id="transaction-to-');
          __out.push(__sanitize(this.id));
          __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n              <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n            </a>\n            ');
          if (parseFloat(this.amount) > 0 || parseFloat(this.amount) < 0 && this.matchedDocument) {
            __out.push('\n              <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="transaction-to-');
            __out.push(__sanitize(this.id));
            __out.push('-action-dropdown-btn">\n                <li><a tabindex="-1" href="/document/');
            __out.push(__sanitize(this.toDocumentId));
            __out.push('"><i class="fa fa-file-text-o m-l-3 m-r-18"></i> <span>View Transaction Detail</span></a></li>\n                <li><a tabindex="-1" class="transaction-to-send-message-btn"><span class="icon-chat-round m-r-18"></span> Send a Message</a></li>\n              </ul>\n            ');
          }
          __out.push('\n          </div>\n        </div>\n      </div>\n      <div aria-labelledby="transfer-to" class="p-l-10 p-r-10 collapse in background-white document-detail-sidebar-section-body" id="transfer-to-collapse" aria-expanded="true" style="">\n        ');
          if (parseFloat(this.amount) > 0) {
            __out.push('\n          <div>\n            <div class="document-field-item document-field-name">Date</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Description</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.description));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Account</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.reconciliation_payment_account ? this.reconciliation_payment_account.name : ''));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Category</div>\n            <div class="document-field-item document-field-value">\n              ');
            if (this.ledger_category_names) {
              __out.push('\n              ');
              __out.push(__sanitize(this.ledger_category_names));
              __out.push('\n              ');
            } else {
              __out.push('\n              ');
              __out.push(__sanitize(this.chart_of_account ? this.chart_of_account.name : '-'));
              __out.push('\n              ');
            }
            __out.push('\n            </div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Amount</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Verified By</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.verified_by ? this.verified_by : 'Automatically'));
            __out.push('</div>\n          </div>\n        ');
          } else if (this.matchedDocument) {
            __out.push('\n          <div>\n            <div class="document-field-item document-field-name">Date</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(moment(this.matchedDocument.date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Description</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.description));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Account</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.account_vendor_name));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Category</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.matchedDocument.coa ? this.matchedDocument.coa.name : '-'));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Amount</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(this.matchedDocument.amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n          </div>\n          <div>\n            <div class="document-field-item document-field-name">Verified By</div>\n            <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.verified_by ? this.verified_by : 'Automatically'));
            __out.push('</div>\n          </div>\n        ');
          } else {
            __out.push('\n          <span class="font-15 pointer in-blue-400 inline-block p-l-20 m-t-20 m-b-20 add-transaction"><span class="icon-plus-circle m-r-18"></span>Add Transaction</span>\n        ');
          }
          __out.push('\n      </div>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    <div class="view-title font-bold">TRANSACTION</div>\n    ');
        if (this.is_multiple_document) {
          __out.push('\n      <div class="view-function">\n        <div class="disabled"><span class="icon-bank-circle-o vertical-align-middle font-35 m-r-10"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>View Transaction</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Date</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Description</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Account</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['LOAN_PAYMENTS_TYPE']) {
            __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Principal Category</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div class="document-field-row">\n          <div class="document-field-item document-field-name">Interest Department</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Interest Category</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Principal Amount</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Interest Amount</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n      ');
          }
          __out.push('\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['CHARGEBACK_TYPE']) {
            __out.push('\n        ');
            if (this.tab === 'resolved') {
              __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Status</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Chargeback Won</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Chargeback Lost</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        ');
            } else {
              __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Status</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        ');
            }
            __out.push('\n      ');
          }
          __out.push('\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['EQUITY_TYPE']) {
            __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Type</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Category</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n      ');
          }
          __out.push('\n      <div>\n        <div class="document-field-item document-field-name">Amount</div>\n        <div class="document-field-item document-field-value">Multiple Selected</div>\n      </div>\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
            __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Transaction Type</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Vendor</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Category</div>\n          <div class="document-field-item document-field-value">Multiple Selected</div>\n        </div>\n      ');
          }
          __out.push('\n    ');
        } else {
          __out.push('\n      <div class="view-function">\n        <a class="pointer view-transaction-detail"><span class="icon-bank-circle-o vertical-align-middle font-35 m-r-10"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>View Transaction</a>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Date</div>\n        <div class="document-field-item document-field-value">');
          __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
          __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Description</div>\n        <div class="document-field-item document-field-value">');
          __out.push(__sanitize(this.description));
          __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Account</div>\n        <div class="document-field-item document-field-value">');
          __out.push(__sanitize(this.payment_account_name));
          __out.push('</div>\n      </div>\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['LOAN_PAYMENTS_TYPE']) {
            __out.push('\n      <div>\n        <div class="document-field-item document-field-name">Principal Category</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.principalCategory));
            __out.push('</div>\n      </div>\n      <div class="document-field-row">\n        <div class="document-field-item document-field-name">Interest Department</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.interestClass));
            __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Interest Category</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.interestCategory));
            __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Principal Amount</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(-this.principal_amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Interest Amount</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(parseFloat(-this.interest_amount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2
            })));
            __out.push('</div>\n      </div>\n      ');
          }
          __out.push('\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['EQUITY_TYPE']) {
            __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Type</div>\n          <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.sub_type_value));
            __out.push('</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Category</div>\n          <div class="document-field-item document-field-value">\n            ');
            if (this.category) {
              __out.push('\n              ');
              ref = this.category.split(';');
              for (i = 0, len = ref.length; i < len; i++) {
                line = ref[i];
                __out.push('\n                <p>');
                __out.push(__sanitize(line));
                __out.push('</p>\n              ');
              }
              __out.push('\n            ');
            } else {
              __out.push('\n              -\n            ');
            }
            __out.push('\n          </div>\n        </div>\n      ');
          }
          __out.push('\n      <div>\n        <div class="document-field-item document-field-name">Amount</div>\n        <div class="document-field-item document-field-value">');
          __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2
          })));
          __out.push('</div>\n      </div>\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['CHARGEBACK_TYPE']) {
            __out.push('\n        ');
            if (this.tab === 'resolved') {
              __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Status</div>\n          <div class="document-field-item document-field-value">Resolved</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Chargeback Won</div>\n          <div class="document-field-item document-field-value">');
              __out.push(__sanitize(parseFloat(this.chargeback_won_amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2
              })));
              __out.push('</div>\n        </div>\n        <div>\n          <div class="document-field-item document-field-name">Chargeback Lost</div>\n          <div class="document-field-item document-field-value">');
              __out.push(__sanitize(parseFloat(this.chargeback_lost_amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2
              })));
              __out.push('</div>\n        </div>\n        ');
            } else {
              __out.push('\n        <div>\n          <div class="document-field-item document-field-name">Status</div>\n          <div class="document-field-item document-field-value">Outstanding</div>\n        </div>\n        ');
            }
            __out.push('\n      ');
          }
          __out.push('\n      ');
          if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
            __out.push('\n      <div>\n        <div class="document-field-item document-field-name">Transaction Type</div>\n        <div class="document-field-item document-field-value">');
            __out.push(__sanitize(this.transaction_type === 'refund' ? 'Vendor refund' : 'Expense'));
            __out.push('</div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Vendor</div>\n        <div class="document-field-item document-field-value">\n          ');
            if (this.ledger_vendor) {
              __out.push('\n            ');
              __out.push(__sanitize(this.ledger_vendor.name));
              __out.push('\n          ');
            } else if (this.vendor) {
              __out.push('\n            ');
              __out.push(__sanitize(this.vendor.name));
              __out.push('\n          ');
            } else {
              __out.push('\n            -\n          ');
            }
            __out.push('\n        </div>\n      </div>\n      <div>\n        <div class="document-field-item document-field-name">Category</div>\n        <div class="document-field-item document-field-value">\n          ');
            if (this.ledger_category_names) {
              __out.push('\n          ');
              __out.push(__sanitize(this.ledger_category_names));
              __out.push('\n          ');
            } else {
              __out.push('\n            ');
              if (this.category) {
                __out.push('\n              ');
                ref1 = this.category.split(';');
                for (j = 0, len1 = ref1.length; j < len1; j++) {
                  line = ref1[j];
                  __out.push('\n                <p>');
                  __out.push(__sanitize(line));
                  __out.push('</p>\n              ');
                }
                __out.push('\n            ');
              } else {
                __out.push('\n              -\n            ');
              }
              __out.push('\n          ');
            }
            __out.push('\n        </div>\n      </div>\n      ');
          }
          __out.push('\n      <div>\n        <div class="document-field-item document-field-name">Verified By</div>\n        <div class="document-field-item document-field-value">');
          __out.push(__sanitize(this.verified_by_name ? this.verified_by_name : 'Automatically'));
          __out.push('</div>\n      </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}