import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAccountsReceivablePaymentsResponse } from '@src/requests/accounts_receivable_payments';
import {
  IAccountsReceivablePayment,
  TAccountsReceivablePaymentsSortColumn,
} from '@src/types/accounts_receivable_payments';
import { ISorting } from '@src/types/sorting';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { SmileIcon } from '@src/components/utils/icomoon';

import Filter from './filter';

interface IAccountsReceivablePaymentsTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAccountsReceivablePaymentsResponse, Error>,
  records: IAccountsReceivablePayment[],
  sorting: ISorting<TAccountsReceivablePaymentsSortColumn>,
}

const customerAvatar = () => {
  return <SmileIcon inColor="blue-400" />;
};

const paymentQBOStatus = (payment: IAccountsReceivablePayment) => {
  return <QBOStatusIcon fontSize={ 20 } model={ payment } />;
};

const AccountsReceivablePaymentsTable = ({
  infiniteQuery,
  records,
  sorting,
}: IAccountsReceivablePaymentsTableProps) => {
  const handleRowClick = useCallback((payment: IAccountsReceivablePayment) => {
    Backbone.history.navigate(`/document/${payment.documentId}`, { trigger: true });
  }, []);

  return (
    <CollectionTable<IAccountsReceivablePayment, TAccountsReceivablePaymentsSortColumn>
      filter={ <Filter /> }
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.TextColumn<
          IAccountsReceivablePayment,
          TAccountsReceivablePaymentsSortColumn
        >
        icon={ customerAvatar }
        name="customer"
        sortColumn="customer"
        title="Customer"
        value={ (payment) => payment.customerName }
        width="50%"
      />
      <CollectionTable.DateColumn<
          IAccountsReceivablePayment,
          TAccountsReceivablePaymentsSortColumn
        >
        name="payment_date"
        sortColumn="payment_date"
        title="Payment Date"
        value={ (payment) => payment.paymentDate }
        width="50%"
      />
      <CollectionTable.AmountColumn<
          IAccountsReceivablePayment,
          TAccountsReceivablePaymentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Balance Amount"
        value={ (payment) => payment.amount }
        width="140px"
      />
      <CollectionTable.IconColumn<IAccountsReceivablePayment>
        name="qbo_status"
        title="QBO Status"
        value={ paymentQBOStatus }
      />
      {/*
        TODO: Add actions
        <CollectionTable.ActionsColumn<IAccountsReceivablePayment>
        name="actions"
        title="Actions"
        value={ actions }
      /> */}
    </CollectionTable>
  );
};

export default React.memo(AccountsReceivablePaymentsTable);
