import { TID } from '@src/types/common';
import { IRevenueService } from '@src/types/revenue_services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetRevenueServiceByBusinessIdParams {
  businessId: TID,
}

interface IGetRevenueServiceByBusinessIdResponse {
  revenueService: IRevenueService,
}

const getRevenueServiceByBusinessId = (
  params: IGetRevenueServiceByBusinessIdParams,
): Promise<IGetRevenueServiceByBusinessIdResponse> => {
  return apiGet(
    '/api/v1/revenue_services/by_business_id',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetRevenueServiceByBusinessIdResponse);
};

export {
  IGetRevenueServiceByBusinessIdParams,
  IGetRevenueServiceByBusinessIdResponse,
  getRevenueServiceByBusinessId,
};
