import React from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import { statusValue } from '@src/components/accounts_payable/ach_transactions/utils';

import AchStatusStep from './status_step';

import styles from './styles.module.scss';

type TAchStatusProps = {
  document: IAchTransaction,
  onCancelInvoice?: () => void,
  showTitle?: boolean,
}

const AchStatus = ({
  document,
  onCancelInvoice,
  showTitle = true,
}: TAchStatusProps) => {
  const content = () => {
    const currentStep = statusValue(document.status);
    switch (currentStep) {
      case 'processing':
        return (
          <>
            <AchStatusStep
              isChecked
              document={ document }
              step={ currentStep }
            />
            <AchStatusStep
              document={ document }
              step="completed"
            />
          </>
        );
      case 'failed':
        return (
          <>
            <AchStatusStep
              document={ document }
              step={ currentStep }
            />
            <AchStatusStep
              document={ document }
              step="failed-completed"
              onCancelInvoice={ onCancelInvoice }
            />
          </>
        );
      case 'completed':
        return (
          <>
            <AchStatusStep
              isActivated
              isChecked
              document={ document }
              step="processing"
            />
            <AchStatusStep
              isChecked
              document={ document }
              step={ currentStep }
            />
          </>
        );
      case 'cancelled':
        return (
          <>
            <AchStatusStep
              document={ document }
              step={ currentStep }
            />
            <AchStatusStep
              document={ document }
              step="completed"
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {
        showTitle && (
          <div className={ styles['status-title'] }>STATUS</div>
        )
      }
      <AchStatusStep
        isActivated
        isChecked
        document={ document }
        step="created"
      />
      { content() }
    </div>
  );
};

export default AchStatus;
