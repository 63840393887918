module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1, ref2, ref3;
    
      __out.push('<td class="invoice-detail-column relative">\n  <i class=\'font-20 icon icon-drag-drop drag-clickable cursor-pointer\'></i>\n</td>\n');
    
      if (this.service_name === Docyt.Common.Constants.SERVICE_NAMES['AP_SERVICE']) {
        __out.push('\n  <td class="invoice-detail-column relative">\n    <div class="inline-flex-wrapper m-l-10">\n        <div class="header__userpic inline-block m-r-10">\n          <img src="');
        __out.push(__sanitize((ref = this.vendor) != null ? ref.image_url : void 0));
        __out.push('" class="header__userpic-img">\n        </div>\n        <div class="p-t-10">\n          <p class="font-15 m-b-0 font-semi-bold">');
        __out.push(__sanitize((ref1 = this.vendor) != null ? ref1.name : void 0));
        __out.push('</p>\n        </div>\n    </div>\n  </td>\n  <td class="relative pointer">\n    ');
        if (this.is_document_uploaded) {
          __out.push('\n      <i class="font-24 icon icon-preview relative m-l-3 preview-clickable"></i>\n    ');
        }
        __out.push('\n  </td>\n  <td class="invoice-detail-column invoice-status-panel-wrapper relative">\n    <span class="invoice-status-panel font-bold ');
        __out.push(__sanitize(this.label_state));
        __out.push('">');
        __out.push(__sanitize(this.check_status));
        __out.push('</span>\n  </td>\n  <td class="invoice-detail-column invoice-chart-of-account-column relative">\n    ');
        if (this.chart_of_account_display_name) {
          __out.push('\n    <p>');
          __out.push(__sanitize(this.chart_of_account_display_name));
          __out.push('</p>\n    ');
        }
        __out.push('\n  </td>\n  <td class="invoice-detail-column relative">\n    <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize(this.invoice_number));
        __out.push('</span>\n  </td>\n  <td class="invoice-detail-column relative">\n    <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize(this.account_number));
        __out.push('</span>\n  </td>\n  <td class="invoice-detail-column relative">\n    <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize(this.invoice_date_formated));
        __out.push('</span>\n  </td>\n  <td class="invoice-detail-column relative">\n    <span class="font-14 vertical-align-top p-l-10">\n      ');
        if (this.invoice_due_date_formated) {
          __out.push('\n        ');
          if (this.due_dated) {
            __out.push('\n        <span class="in-red-400">Overdue by ');
            __out.push(__sanitize(this.invoice_due_date_formated));
            __out.push('</span>\n        ');
          } else {
            __out.push('\n        ');
            __out.push(__sanitize(this.invoice_due_date_formated));
            __out.push('\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </span>\n  </td>\n  <td class="invoice-amount-column relative text-right">\n      <button class="btn dropdown-toggle font-15 font-bold invoice-amount-button ');
        __out.push(__sanitize(this.action_button_class));
        __out.push('" type="button" id="invoice-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        ');
        __out.push(__sanitize(this.display_amount));
        __out.push('\n        <span class="fa fa-angle-down m-l-5"></span>\n      </button>\n  </td>\n');
      } else if (this.service_name === Docyt.Common.Constants.SERVICE_NAMES['RECEIPT_SERVICE']) {
        __out.push('\n  <td class="receipt-avatar-column flex-wrapper relative receipt-clickable pointer">\n    <div class="header__userpic inline-block m-l-10">\n      <img src="');
        __out.push(__sanitize(this.vendor_avatar));
        __out.push('" class="header__userpic-img">\n    </div>\n    <p class="payee-name-truncate font-15 inline-block m-l-15 m-t-10 vertical-align-top" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize((ref2 = this.vendor) != null ? ref2.name : void 0));
        __out.push('</p>\n  </td>\n  <td class="relative pointer">\n    ');
        if (this.is_document_uploaded) {
          __out.push('\n      <i class="font-24 icon icon-preview relative m-l-3 preview-clickable"></i>\n    ');
        }
        __out.push('\n  </td>  \n  <td class="receipt-detail-column invoice-status-panel-wrapper relative receipt-clickable pointer">\n      <span class="invoice-status-panel font-bold ');
        __out.push(__sanitize(this.label_state));
        __out.push('">');
        __out.push(__sanitize(this.check_status));
        __out.push('</span>\n  </td>\n  <td class="receipt-detail-column receipt-chart-of-account-column relative">\n    ');
        if (this.chart_of_account_name) {
          __out.push('\n    <p>\n      ');
          __out.push(__sanitize(this.chart_of_account_parent_name));
          __out.push('\n      <i class="fa fa-caret-right"></i>\n      ');
          __out.push(__sanitize(this.chart_of_account_name));
          __out.push('\n    </p>\n    ');
        }
        __out.push('\n  </td>\n  <td class="receipt-detail-column receipt-payment-account-column pointer">\n    <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize((ref3 = this.payment_account) != null ? ref3.account_name : void 0));
        __out.push('</span>\n  </td>\n  <td class="receipt-detail-column relative receipt-clickable pointer">\n    <span class="font-14 p-l-10">');
        __out.push(__sanitize(this.receipt_date_string));
        __out.push('</span>\n  </td>\n  <td class="receipt-amount-column relative text-right">\n    <button class="btn dropdown-toggle font-15 font-bold invoice-amount-button ');
        __out.push(__sanitize(this.action_button_class));
        __out.push('" type="button" id="receipt-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      ');
        __out.push(__sanitize(this.display_amount));
        __out.push('\n      <span class="fa fa-angle-down m-l-5"></span>\n    </button>  \n  </td>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}