import React, { useCallback } from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import { useBankStatementDetailsModal } from '@src/components/banking_accounts/bank_statements/bank_statement_details/details_modal';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  BankStatementApprovedInactive,
  BankStatementMissing,
  BankStatementRequested,
  BankStatementUploaded,
  BankStatementVerifiedInactive,
} from '@src/components/utils/icomoon';

import styles from '../../../styles.module.scss';

interface IUploadedStatusItemProps {
  businessId: TID,
  state: IReviewedBankStatementState,
  onCloseModal: () => void;
}

const UploadedStatusItem = ({
  businessId,
  state,
  onCloseModal,
}: IUploadedStatusItemProps) => {
  const detailModal = useBankStatementDetailsModal();
  const { openWithValue } = detailModal;

  const uploadedTooltip = (
    <div className="uploaded-status">
      <div className={ styles['state-status'] }>
        <div className={ styles['state-info'] }>
          <hr className={ styles['missing-requested-progress-line'] } />
          <BankStatementMissing fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className="font-12">Statement missing</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['requested-uploaded-progress-green-line'] } />
          <BankStatementRequested fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.requestedAt ? formatDate(state.requestedAt) : '--'}</span>
          <span className="font-12">Statement requested</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-approved-progress-disabled-line'] } />
          <BankStatementUploaded fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.uploadedAt ? formatDate(state.uploadedAt) : '--'}</span>
          <span className="font-12">Statement uploaded</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['approved-verified-progress-disabled-line'] } />
          <BankStatementApprovedInactive fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Statement approved</span>
        </div>
        <div className={ styles['state-info'] }>
          <BankStatementVerifiedInactive fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Bank feed verified</span>
        </div>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ uploadedTooltip } tooltipClassName={ styles['state-item'] }>
      <BankStatementUploaded fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const handleClick = useCallback(() => {
    if (!state.docytId) return;

    openWithValue({
      docytId: state.docytId,
    });
  }, [state.docytId, openWithValue]);

  return (
    <>
      <detailModal.Component
        { ...detailModal.props }
        businessId={ businessId }
        onCloseModal={ onCloseModal }
      />
      <Button
        className={ styles['status-item-button'] }
        variant="primary"
        onClick={ handleClick }
      >
        { content }
      </Button>
    </>
  );
};

export default UploadedStatusItem;
