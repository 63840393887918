import { TID } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IDownloadPrintableChecksParams {
  serviceId: TID,
  ids: number[],
}

const downloadPrintableChecks = (
  params: IDownloadPrintableChecksParams,
): Promise<void> => {
  return apiGet(
    '/api/v1/service_payment_transactions/download_printable_checks',
    underscoreKeys(params),
  );
};

export {
  IDownloadPrintableChecksParams,
  downloadPrintableChecks,
};
