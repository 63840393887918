import React, { useCallback, useState } from 'react';

import flatten from 'lodash/flatten';

import { useVendorsServiceContext } from '@src/hooks/contexts/vendors_service_context';
import { useGetBusinessGlobalVendors } from '@src/hooks/queries/business_vendors';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { TBusinessVendorsSortColumn } from '@src/types/business_vendors';
import { ISortingParams } from '@src/types/sorting';

import CheckCircleIcon from '@src/components/utils/icomoon/check_circle_large_icon';
import { ItemsSelectorProvider } from '@src/components/utils/items_selector';
import HideMyVendorCheckBox from '@src/components/vendors/details/hide_my_vendors_checkbox';

import BusinessGlobalVendorList from './docyt_network_list';
import { DocytNetworkFilter } from './filter';
import { IBusinessVendorsFilter } from './filter/types';
import { DocytNetworkHeader } from './header';

const DefaultSorting: ISortingParams<TBusinessVendorsSortColumn> = {
  orderColumn:    'vendor',
  orderDirection: 'asc',
};

const DocytNetworkDetails = (): JSX.Element => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [hiddenResult, setHiddenResult] = useState(false);
  const vendorsService = useVendorsServiceContext();
  const filter = useFilter<IBusinessVendorsFilter>({});
  const sorting = useSorting<TBusinessVendorsSortColumn>({ defaultSorting: DefaultSorting });
  const vendorsQuery = useGetBusinessGlobalVendors({
    businessId: vendorsService!.businessId,
    filter:     filter.data,
    ...sorting.data,
  });

  const vendorPages = vendorsQuery.data?.pages || [];
  const vendors = flatten(vendorPages.map((p) => p.collection));
  const selectableVendors = vendors.filter((vendor) => !vendor.addedToBusiness);

  const onBulkActionFinished = useCallback((bulkCount: number) => {
    setSelectedCount(bulkCount);
    setHiddenResult(false);
  }, []);

  const handleResultClick = useCallback((e) => {
    e.preventDefault();

    setHiddenResult(true);
  }, []);

  return (
    <ItemsSelectorProvider allItems={ selectableVendors }>
      <div className="m-l-40 m-r-40 m-b-20">
        <DocytNetworkHeader
          onBulkActionFinished={ onBulkActionFinished }
        />
        <ul className="pointer vendors-bulk-action-result-container m-t-20 m-b-5" hidden={ (selectedCount < 1) || hiddenResult } role="banner" onClick={ handleResultClick }>
          <div className="font-16 result-item-wrapper success-result-item text-center">
            <CheckCircleIcon className="m-t-3" />
            <span className="success-result m-l-10">
              { (selectedCount > 1) && `${selectedCount} vendors ` }
              { (selectedCount === 1) && '1 vendor ' }
              successfully added to My Vendors List
              {' '}
            </span>
          </div>
        </ul>
        <HideMyVendorCheckBox filter={ filter } />
        <div className="payment-table-wrapper">
          <DocytNetworkFilter filter={ filter } />
          <BusinessGlobalVendorList
            query={ vendorsQuery }
            sorting={ sorting }
            vendors={ vendors }
          />
        </div>
      </div>
    </ItemsSelectorProvider>
  );
};

export {
  DocytNetworkDetails as default,
};
