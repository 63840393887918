import React from 'react';

import Body from './body';
import Footer from './footer';
import Form from './form';
import Root, { IModalProps } from './root';
import Standard from './standard';

const Modal = (props: IModalProps): JSX.Element => <Root { ...props } />;

Modal.Body = Body;
Modal.Footer = Footer;
Modal.Form = Form;
Modal.Standard = Standard;

export {
  IModalProps,
  Modal as default,
};
