import { object, string } from 'yup';

const businessProfileValidation = object({
  legalName:     string().trim().required('Business Name is required.'),
  email:         string().email().required('Email must be a valid email.'),
  addressStreet: string().trim().required('Street is required.'),
  addressCity:   string().trim().required('City is required.'),
  addressZip:    string().trim().required('Zip code is required.'),
});

interface IBusinessProfileInput {
  displayName: string,
  email: string,
  name: string,
  legalName: string,
  addressCity: string,
  addressCountry: string,
  addressState: string,
  addressStreet: string,
  addressStreet2: string,
  addressZip: string,
  phone: string,
  avatarUrl: string,
}

export {
  IBusinessProfileInput,
  businessProfileValidation,
};
