import React, { useCallback } from 'react';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { TextInput } from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface ITextFieldFieldProps<TFilter extends TFilterData> extends
  Omit<React.ComponentPropsWithoutRef<typeof TextInput>, 'name' | 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  inputClassName?: string
  label?: string,
  name: keyof TFilter,
  showTooltip?: boolean,
}

const TextFilterField = <TFilter extends TFilterData>({
  label,
  name,
  showTooltip = true,
  ...props
}: ITextFieldFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string>(name);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      update(e.currentTarget.value);
    },
    [update]
  );

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  return (
    <Wrapper
      { ...wrapperProps }
      showTooltip={ showTooltip }
      tooltip={ value }
      tooltipLabel={ `${label}: ` }
    >
      <TextInput
        value={ value || '' }
        onChange={ handleChange }
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default TextFilterField;
