export const chatChannelCreationMessage = {
  errorCreating: {
    message: 'Error creating conversation',
    title:   'Chat',
  },
};

export const welcomeMessage = {
  title:   'Welcome to Docyt!',
  message: 'How can I help you today?',
};
