module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div></div>\n');
    
      if (this.invoice_type === 'settlement') {
        __out.push('\n  <div class="counter-wrapper">\n    <div class="count-group">\n      <label class="count-label">Total Count</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.summary_info.get('count_valid_paying')));
        __out.push('</span>\n    </div>\n    <div class="count-group">\n      <label class="count-label display-flex">Total Invoices</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_service_documents'))));
        __out.push('</span>\n    </div>\n  </div>\n');
      } else {
        __out.push('\n  <div class="queue-counter-wrapper">\n    <div class="count-group-border">\n      <label class="count-label">Uncleared Payments</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_uncleared_payments'))));
        __out.push('</span>\n    </div>\n    ');
        if (this.leader_balance_enabled) {
          __out.push('\n    <div class="count-group-border ledger-balance-tooltip-js" data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
          __out.push(__sanitize(this.ledger_balance_tooltip));
          __out.push('">\n      <label class="count-label">Bank Ledger Balance</label>\n      <span class="count-amount">');
          __out.push(__sanitize(this.currencyFormater(this.summary_info.get('ledger_balance'))));
          __out.push('</span>\n    </div>\n    ');
        }
        __out.push('\n    <div class="count-group">\n      <label class="count-label">Ready for Credit: (');
        __out.push(__sanitize(this.summary_info.get('count_ready_for_credit')));
        __out.push(')</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_ready_for_credit'))));
        __out.push('</span>\n    </div>\n    <label class="count-amount">+</label>\n    <div class="count-group">\n      <label class="count-label">Needs Approval / Ready to Pay: (');
        __out.push(__sanitize(this.summary_info.get('count_valid_paying')));
        __out.push(')</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_valid_paying'))));
        __out.push('</span>\n    </div>\n    <label class="count-amount">+</label>\n    <div class="count-group">\n      <label class="count-label">Settlement Items: (');
        __out.push(__sanitize(this.summary_info.get('count_settlement_items')));
        __out.push(')</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_settlement_items'))));
        __out.push('</span>\n    </div>\n    <label class="count-amount">=</label>\n    <div class="count-group-border">\n      <label class="count-label display-flex">Invoices (');
        __out.push(__sanitize(this.summary_info.get('count_service_documents')));
        __out.push(')</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_service_documents'))));
        __out.push('</span>\n    </div>\n    <div class="count-group">\n      <label class="count-label">Expense reports (');
        __out.push(__sanitize(this.summary_info.get('count_expense_report')));
        __out.push(')</label>\n      <span class="count-amount">');
        __out.push(__sanitize(this.currencyFormater(this.summary_info.get('amount_expense_report'))));
        __out.push('</span>\n    </div>\n  </div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}