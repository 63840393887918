module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="payment-account-item ');
    
      __out.push(__sanitize(!this.reconciliation_payment_account_id && this.payment_accounts_count > 1 ? 'has-employee-cards' : ''));
    
      __out.push('">\n  ');
    
      if (!this.is_being_disconnected) {
        __out.push('\n  <div class="payment-account-item-action-wrapper"></div>\n  ');
      }
    
      __out.push('\n  <div class="payment-account-icon-wrapper">\n  ');
    
      if (this.account_type === 'bank') {
        __out.push('\n    ');
        if (!this.is_archived) {
          __out.push('\n    <span class="icon icon-bank-account payment-account-primary-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n    </span>\n    <span class="icon icon-bank-account payment-account-employee-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n    </span>\n    ');
        } else {
          __out.push('\n    <span class="icon icon-bank-account-disabled payment-account-primary-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    <span class="icon icon-bank-account-disabled payment-account-employee-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    ');
        if (!this.is_archived) {
          __out.push('\n    <span class="icon icon-credit-card-account payment-account-primary-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    <span class="icon icon-credit-card-account payment-account-employee-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    ');
        } else {
          __out.push('\n    <span class="icon icon-credit-card-account-disabled payment-account-primary-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    <span class="icon icon-credit-card-account-disabled payment-account-employee-icon font-50">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  </div>\n  <div class="account-name-container ');
    
      __out.push(__sanitize(this.hasPlaidError ? 'account-name-downed-container' : ''));
    
      __out.push('">\n    <h2 class="payment-account-name" title="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</h2>\n    ');
    
      if (this.hasPlaidError) {
        __out.push('\n    <span class="icon icon-warning-triangle font-30"></span>\n    ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (this.reconciliation_payment_account_id || (this.payment_accounts_count === 1) || this.is_archived) {
        __out.push('\n  <p class="payment-account-no">');
        __out.push(__sanitize(this.accNo));
        __out.push('</p>\n  ');
      } else {
        __out.push('\n  <p class="payment-account-no">\n    ');
        if (this.accNo) {
          __out.push('\n    <span>');
          __out.push(__sanitize(this.accNo));
          __out.push(', </span>\n    ');
        }
        __out.push('\n    <span> +');
        __out.push(__sanitize(this.payment_accounts_count - 1));
        __out.push(' Employee cards</span>\n  </p>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_archived) {
        __out.push('\n  <span class="archived-date">Archived on ');
        __out.push(__sanitize(this.archived_at));
        __out.push('</span>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.is_being_disconnected) {
        __out.push('\n    <p class="text-center text-danger font-12">Disconnection in progress</p>\n  ');
      } else if (!this.is_archived) {
        __out.push('\n    <h4 class="payment-account-total-amount">');
        __out.push(__sanitize(this.totalAmount));
        __out.push('</h4>\n    <p class="payment-account-transaction-count">');
        __out.push(__sanitize(this.tranxDocsCnt));
        __out.push(' Transactions</p>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}