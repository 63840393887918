import React from 'react';

import { IStatementState } from '@src/types/balance_sheet_statements';
import { formatDate } from '@src/utils/date_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  MissingStatementIcon,
  StatementUploadedIcon,
} from '@src/components/utils/icomoon';
import NotReconciledIcon from '@src/components/utils/icomoon/not_reconciled';
import WaitingForClientIcon from '@src/components/utils/icomoon/waiting-for-client';

import styles from '../../styles.module.scss';

interface IUploadedStatusItemProps {
  state: IStatementState,
  onClickItem: () => void;
}

const UploadedStatusItem = ({
  state,
  onClickItem,
}: IUploadedStatusItemProps) => {
  const uploadedTooltip = (
    <div className="uploaded-status">
      <div className={ styles['state-status'] }>
        <div className={ styles['state-info'] }>
          <hr className={ styles['missing-requested-progress-line'] } />
          <MissingStatementIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className="font-12">Documents missing</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['requested-uploaded-progress-green-line'] } />
          <WaitingForClientIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.requestedAt ? formatDate(state.requestedAt) : '--'}</span>
          <span className="font-12">Documents requested</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-verified-progress-green-line'] } />
          <StatementUploadedIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.uploadedAt ? formatDate(state.uploadedAt) : '--'}</span>
          <span className="font-12">Documents uploaded</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-verified-progress-disabled-line'] } />
          <NotReconciledIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className={ styles['disabled-font-style'] }>Documents verified</span>
        </div>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ uploadedTooltip } tooltipClassName={ styles['state-item'] }>
      <StatementUploadedIcon fontSize={ 20 } />
    </Tooltip.Hover>
  );

  return (
    <Button
      className={ styles['status-item-button'] }
      variant="link"
      onClick={ onClickItem }
    >
      { content }
    </Button>
  );
};

export default UploadedStatusItem;
