import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { AppLink } from '@src/components/ui_v2/buttons';
import { ChatIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IInvoiceChatProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
}

const InvoiceChat = ({ accountsPayableServiceDocument }: IInvoiceChatProps) => {
  const hasMessages = accountsPayableServiceDocument.unreadMessagesCount > 0;
  const icon = (
    <>
      <ChatIcon
        fontSize={ 26 }
        variant={ hasMessages ? 'filled' : 'empty' }
      />

      {hasMessages && (
        <span className={ styles['unread-messages-count'] }>
          { accountsPayableServiceDocument.unreadMessagesCount < 10 ? accountsPayableServiceDocument.unreadMessagesCount : '9+' }
        </span>
      )}
    </>
  );

  return (
    <AppLink
      className={ hasMessages ? 'relative' : '' }
      href={ `/document/${accountsPayableServiceDocument.documentId}/chat` }
    >
      {icon}
    </AppLink>
  );
};

export default React.memo(InvoiceChat);
