import React from 'react';

import { IBusinessVendor, TVendorMergeType } from '@src/types/business_vendors';

import { ReviewMergeTypes } from '@src/components/vendors/views/review_merge_vendor_types';

import DescriptionInfo from './review_merge_vendor_description';
import MergeTypeInput from './review_merge_vendor_type_input';

interface IReviewMergeVendorFormProps {
  vendor: IBusinessVendor,
  globalVendor?: IBusinessVendor,
  mergeType: TVendorMergeType,
  onChangeMergeType: (value: TVendorMergeType) => void,
}

const ReviewMergeVendorForm = ({
  vendor,
  globalVendor,
  mergeType,
  onChangeMergeType,
}: IReviewMergeVendorFormProps): JSX.Element => {
  return (
    <>
      <DescriptionInfo
        globalVendor={ globalVendor }
        localVendor={ vendor }
      />
      {
        vendor.mergeableId && (
          <div className="merge-option-select-section display-flex">
            {
              ReviewMergeTypes.map(({ label, value }) => (
                <MergeTypeInput
                  key={ value }
                  label={ label }
                  mergeType={ mergeType }
                  value={ value }
                  onChange={ onChangeMergeType }
                />
              ))
            }
          </div>
        )
      }
    </>
  );
};

export {
  IReviewMergeVendorFormProps,
  ReviewMergeVendorForm as default,
};
