import React from 'react';

import Table from '@src/components/ui_v2/table';

import { TColumn, TModel } from './types';

interface ICollectionTableValueCellProps<Model extends TModel, TSortColumn extends string = never> {
  column: TColumn<Model, TSortColumn>
  object: Model,
}

const CollectionTableValueCell = <Model extends TModel, TSortColumn extends string = never>({
  column,
  object,
}: ICollectionTableValueCellProps<Model, TSortColumn>) => {
  if (column.hidden) return null;

  if (column.type === 'amount') {
    return (
      <Table.AmountCell
        amount={ column.value(object) }
        icon={ column.icon && column.icon(object) }
        tooltip={ column.tooltip && column.tooltip(object) }
        tooltipProps={ column.tooltipProps }
      />
    );
  }

  if (column.type === 'date') {
    return (
      <Table.DateCell date={ column.value(object) } />
    );
  }

  if (column.type === 'icon') {
    return (
      <Table.IconCell
        tooltip={ column.tooltip && column.tooltip(object) }
      >
        { column.value(object) }
      </Table.IconCell>
    );
  }

  if (column.type === 'label') {
    return (
      <Table.LabelCell
        title={ column.value(object) }
        tooltip={ column.tooltip && column.tooltip(object) }
        variant={ column.variant && column.variant(object) }
      />
    );
  }

  if (column.type === 'actions') {
    return (
      <Table.ActionsDropdownCell fixed={ column.fixed }>
        { column.value(object) }
      </Table.ActionsDropdownCell>
    );
  }

  if (column.type === 'button') {
    return (
      <Table.Cell>
        { column.value(object) }
      </Table.Cell>
    );
  }

  if (column.type === 'select_item') {
    return (
      <Table.SelectItemCell
        inputType={ column.inputType }
        item={ column.value(object) }
      />
    );
  }

  return (
    <Table.TextCell
      hideValueTooltip={ column.hideValueTooltip }
      icon={ column.icon && column.icon(object) }
      subtitle={ column.subtitle && column.subtitle(object) }
      tooltip={ column.tooltip && column.tooltip(object) }
    >
      { column.value(object) }
    </Table.TextCell>
  );
};

export default React.memo(CollectionTableValueCell) as typeof CollectionTableValueCell;
