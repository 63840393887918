import React, { useCallback, useMemo } from 'react';

import { useGetDocytAccountingAccountManager } from '@src/hooks/queries/business_users';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';

import styles from '../styles.module.scss';

type TContactAccountManagerFormProps = {
  businessId?: TID,
}
const ContactAccountManagerForm = ({
  businessId,
}: TContactAccountManagerFormProps) => {
  const query = useGetDocytAccountingAccountManager({
    businessId,
  });

  const docytAccountingAccountManager = useMemo(() => {
    return query.data?.docytAccountingAccountManager;
  }, [query.data]);

  const handleDone = useCallback(() => {
    window.open(docytAccountingAccountManager?.hubspotMeetingUrl, '_blank');
  }, [docytAccountingAccountManager?.hubspotMeetingUrl]);

  if (!docytAccountingAccountManager) {
    return (
      <ActionsDropdown.LinkAction
        backboneNavigate={ false }
        className={ styles['settings-item'] }
        href="mailto:support@docyt.com"
        title="Contact Us"
      />
    );
  }

  return (
    <>
      <span className={ styles['settings-section'] }>Contact Your Account Manager</span>
      <div className={ styles['contact-form'] }>
        <div className={ styles['account-manager-info'] }>
          <div className={ styles['account-manager-name'] }>
            <span>{ docytAccountingAccountManager?.parsedFullname }</span>
          </div>
          <a
            className="pointer font-bold"
            href={ `mailto:${docytAccountingAccountManager.email}` }
          >
            { docytAccountingAccountManager?.email }
          </a>
          <span>{ docytAccountingAccountManager?.phone}</span>
        </div>
        <div className={ styles['schedule-meeting'] }>
          <Button
            className={ styles['schedule-button'] }
            variant="primary"
            onClick={ handleDone }
          >
            Schedule a Meeting
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContactAccountManagerForm;
