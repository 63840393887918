import React from 'react';

import {
  IUIStyleProps,
  uiStyleProps,
} from '@src/utils/ui_style_helpers';

interface IDropdownLinkToggleProps
  extends IUIStyleProps, React.ComponentPropsWithoutRef<'a'>
{
  disabled?: boolean,
  noButton?: boolean,
}

const DropdownLinkToggle = ({
  children,
  disabled,
  noButton,
  ...props
}: IDropdownLinkToggleProps): JSX.Element => {
  const [classes, anchorProps] = uiStyleProps(
    'dropdown-toggle',
    props,
    {
      disabled,
      btn: !noButton,
    },
  );

  return (
    <a
      aria-expanded="false"
      aria-haspopup="true"
      className={ classes }
      data-container="body"
      data-toggle="dropdown"
      type="button"
      { ...anchorProps }
    >
      { children }
    </a>
  );
};

export default DropdownLinkToggle;
