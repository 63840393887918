import React, { useCallback, useMemo, useState } from 'react';

import { IBusinessVendor, TVendorMergeType } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';

import Footer from './review_merge_vendor_footer';
import Form from './review_merge_vendor_form';

interface IReviewMergeVendorsModalProps {
  vendor: IBusinessVendor,
  globalVendor?: IBusinessVendor,
  show?: boolean,
  onCancel: () => void,
  onContinue: (value: TVendorMergeType) => void,
}

const ReviewMergeVendorModal = ({
  vendor,
  globalVendor,
  show,
  onCancel,
  onContinue,
}: IReviewMergeVendorsModalProps): JSX.Element => {
  const [mergeType, setMergeType] = useState<TVendorMergeType>('enable_merge');
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const canContinue = useMemo(() => {
    return isSelected;
  }, [isSelected]);

  const handleChangeMergeType = useCallback((value: TVendorMergeType) => {
    setMergeType(value);
  }, []);

  const handleChecked = useCallback(() => {
    setIsSelected((current) => !current);
  }, []);

  const handleConfirmClick = useCallback(() => {
    if (mergeType === 'enable_merge' && !canContinue) {
      return;
    }
    onContinue(mergeType);
  }, [onContinue, mergeType, canContinue]);

  return (
    <Modal.Standard
      dialogClassName="review-merge-vendors-modal select-multi-item-area"
      isProceedDisabled={ !canContinue && (mergeType === 'enable_merge') }
      proceedTitle="Next"
      show={ show }
      title="Merge Vendor Profiles ‣ Validate Info"
      onCancel={ onCancel }
      onProceed={ handleConfirmClick }
    >
      {() => (
        <>
          <Form
            globalVendor={ globalVendor }
            mergeType={ mergeType }
            vendor={ vendor }
            onChangeMergeType={ handleChangeMergeType }
          />
          { (mergeType === 'enable_merge')
            && (
            <Footer
              globalVendor={ globalVendor }
              isSelected={ isSelected }
              onChecked={ handleChecked }
            />
            )}
        </>
      )}
    </Modal.Standard>
  );
};

export {
  ReviewMergeVendorModal as default,
};
