module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class=\'font-24 font-bold text-center\'>\n          ');
    
      __out.push(__sanitize(this.isOpen ? 'Mark as Reviewed' : 'Re-Open Issue'));
    
      __out.push('\n        </h4>\n      </div>\n      <div class="modal-body">\n        <p class="font-16">\n          ');
    
      __out.push(__sanitize(this.isOpen ? 'Do you wish to mark this transaction as reviewed?' : 'Do you wish to re-open issue?'));
    
      __out.push('\n        </p>\n\n        <div class="checkbox checkbox-primary checkbox-singleline">\n          <input type="checkbox" id="dont-ask-again-checkbox" class="pointer" />\n          <label for="dont-ask-again-checkbox">Don\'t ask this again</label>\n        </div>\n      </div>\n\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel pointer font-semi-bold\' id=\'cancel-btn\'>No</a>\n        <button type=\'button\' id=\'confirm-btn\' class="btn btn-danger ladda-button" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}