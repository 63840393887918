import { useState } from "react";

import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

import { IPlaidMetadata } from "../types/financial_institution_connection"

interface IUsePlaidService {
	handleOnSuccess(public_token: string, metadata: IPlaidMetadata): void,
	handleOnExit(err: string): void
}

export default function usePlaidService({
	handleOnSuccess, handleOnExit
}: IUsePlaidService, generatedLinkToken?: string | null | undefined) {

	const [linkToken, setLinkToken] = useState<string | null>(generatedLinkToken ?? null)

	const config: PlaidLinkOptions = {
		// eslint-disable-next-line camelcase
		onSuccess: (public_token: string, metadata) => { 
			handleOnSuccess(public_token, { 
				// eslint-disable-next-line camelcase
				publicToken: public_token,
				account_id: (metadata as any).account_id as string,
				type:'plaid'
			})
		},
		onExit: (err: any) => {
			if(err){
				handleOnExit(err?.display_message ?? ' ')
			}			
		},
		token: linkToken,
	};

	const { ready, open, exit } = usePlaidLink(config)

	return {
		setLinkToken,
		open, ready, exit
	}
}