module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var account, business, business_profile, document, i, integration, integrations, j, k, l, len, len1, len2, len3, len4, len5, len6, len7, len8, len9, m, n, o, p, q, r, ref, ref1, ref10, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    
      __out.push('<div class="p-b-50 text-center dashboard-tasks-container">\n  <div class="m-t-20 text-center dashboard-updated-panel">\n    <p class="dashboard-updates-panel refresh-view-panel pointer">\n      <span>Dashboard has been updated. Click here to reload</span>\n    </p>\n  </div>\n  ');
    
      if (this.email_status !== 'verified') {
        __out.push('\n    <div class="business-status-card">\n      <div class="border-bottom-gray p-b-10 text-left">\n        <p class="display-table-row m-b-0">\n          <i class="display-table-cell fa fa-wrench font-28 in-blue-900"></i>\n          <span class="p-l-10 display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n            Settings: Personal Profile\n          </span>\n        </p>\n      </div>\n      <div class="p-b-10 text-left">\n        ');
        if (this.email_not_entered) {
          __out.push('\n          <p class="font-16 m-t-10 m-b-10 font-semi-bold">Update Recovery Email</p>\n          <p class="font-14 m-t-10 m-b-5">A verified email is the method of last resort to recover your account and data.</p>\n          <p class="font-14">Please update and verify your email to secure your account.</p>\n          <div class="m-b-10 text-center">\n            <a class="font-16 font-semi-bold" href="/settings">Update Email</a>\n          </div>\n        ');
        } else {
          __out.push('\n          <p class="font-16 m-t-10 m-b-10 font-semi-bold in-red-400">Email address is not verified.</p>\n          <div class="">\n            <a class="font-16 font-semi-bold pull-right" href="/settings">Edit</a>\n            <span class="font-14 font-semi-bold">');
          __out.push(__sanitize(Docyt.currentAdvisor.get('unverified_email') || Docyt.currentAdvisor.get('email')));
          __out.push('</span>\n          </div>\n          <p class="">\n            We have sent you a verification email. Please click the link in the email to verify your email address.\n          </p>\n          <div class="m-b-10 text-center email-verification-link-div">\n            <a class="font-16 font-semi-bold pointer resend-email-verification-link">Resend Verification Email</a>\n          </div>\n          <div class="m-b-10 text-center remaining-seconds-div in-grey-900 font-16">\n            <span>Resend Verification Email in 00:</span><span class="remaining-seconds-span"></span>\n          </div>\n        ');
        }
        __out.push('\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n  ');
    
      ref = this.invited_businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n    <div class="clearfix font-semi-bold m-t-10 in-white padding-10 invited-business-wrapper ');
        if (!this.business_dashboard) {
          __out.push(__sanitize('personal-dashboard'));
        }
        __out.push('">\n      <div class="display-flex">\n        <div class="font-14">Invite to join ');
        __out.push(__sanitize(business.get('display_name')));
        __out.push('</div>\n        <div class="m-l-auto pointer view-invitation-js" data-value="');
        __out.push(__sanitize(business.get('invitation_id')));
        __out.push('">View</div>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (this.business_dashboard) {
        __out.push('\n    ');
        if (this.flagged_businesses.length > 0) {
          __out.push('\n    <div class="setup-businesses-card">\n        <div class="p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n                <i class="display-inline-flex icon-flag font-28 in-blue-900 p-r-10"></i>\n                <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n                    Review Open Items\n                </span>\n            </p>\n        </div>\n        <div class="clearfix">\n            <table class="business-status-table business-flagged-table">\n                <tbody>\n                ');
          ref1 = this.flagged_businesses;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            business = ref1[j];
            __out.push('\n                <tr class="business-row">\n                    <td class="avatar-column">\n                        <div class="small-avatar-wrapper">\n                            ');
            if ((ref2 = this.getBusinessInfo(business.business_id)) != null ? ref2.image_url : void 0) {
              __out.push('\n                            <img class="border-gray" src="');
              __out.push(__sanitize(this.getBusinessInfo(business.business_id).image_url));
              __out.push('">\n                            ');
            } else {
              __out.push('\n                            <img src="');
              __out.push(__sanitize(configData.dashboard_images.BUSINESS_CLIENTS));
              __out.push('">\n                            ');
            }
            __out.push('\n                        </div>\n                    </td>\n                    <td class="business-name-column">\n                        ');
            __out.push(__sanitize(business.legal_name));
            __out.push('\n                    </td>\n                    <td class="action-column">\n                      <div class="display-flex-column">\n                        ');
            if (business.reconciliation_status && business.reconciliation_status.count_opened > 0) {
              __out.push('\n                        <a class="font-13 font-semi-bold pointer pull-right text-right" href="/businesses/');
              __out.push(__sanitize(business.business_id));
              __out.push('/open_items/flagged_transactions">\n                        Review ');
              __out.push(__sanitize(business.reconciliation_status.count_opened));
              __out.push(' ');
              __out.push(__sanitize(business.reconciliation_status.count_opened > 1 ? 'Transactions' : 'Transaction'));
              __out.push('\n                        </a>\n                        ');
            }
            __out.push('\n                        ');
            if (business.accounts_payable_status && business.accounts_payable_status.count_starred > 0) {
              __out.push('\n                        <a class="font-13 font-semi-bold pointer pull-right text-right" href="/businesses/');
              __out.push(__sanitize(business.business_id));
              __out.push('/accounts_payable/queue?type=starred">\n                        Review ');
              __out.push(__sanitize(business.accounts_payable_status.count_starred));
              __out.push(' ');
              __out.push(__sanitize(business.accounts_payable_status.count_starred > 1 ? 'Invoices' : 'Invoice'));
              __out.push('\n                        </a>\n                        ');
            }
            __out.push('\n                        ');
            if (business.receipt_box_status && business.receipt_box_status.count_starred > 0) {
              __out.push('\n                        <a class="font-13 font-semi-bold pointer pull-right text-right" href="/businesses/');
              __out.push(__sanitize(business.business_id));
              __out.push('/receipt_box/queue?type=starred">\n                        Review ');
              __out.push(__sanitize(business.receipt_box_status.count_starred));
              __out.push(' ');
              __out.push(__sanitize(business.receipt_box_status.count_starred > 1 ? 'Receipts' : 'Receipt'));
              __out.push('\n                        </a>\n                        ');
            }
            __out.push('\n                        ');
            if (business.document_request_service_role) {
              __out.push('\n                          ');
              if (business.count_document_requests > 0) {
                __out.push('\n                            <a class="font-13 font-semi-bold pointer pull-right text-right" href="/businesses/');
                __out.push(__sanitize(business.business_id));
                __out.push('/open_items/document_requests">\n                              Upload ');
                __out.push(__sanitize(business.count_document_requests));
                __out.push(' ');
                __out.push(__sanitize(business.count_document_requests > 1 ? 'Documents' : 'Document'));
                __out.push('\n                            </a>\n                          ');
              }
              __out.push('\n                        ');
            }
            __out.push('\n                      </div>\n                    </td>\n                </tr>\n                ');
          }
          __out.push('\n                </tbody>\n            </table>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    ');
        if (this.mergeable_businesses.length > 0) {
          __out.push('\n    <div class="setup-businesses-card">\n        <div class="p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n                <i class="display-inline-flex icon-vendor-service font-18 vertical-align-middle in-blue-900 p-r-10"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span></i>\n                <span class="display-table-cell font-16 font-bold vertical-align-top">\n                    Vendors: Review Merge Options\n                </span>\n            </p>\n        </div>\n        <div class="clearfix">\n            <table class="business-status-table business-flagged-table">\n                <tbody>\n                ');
          ref3 = this.mergeable_businesses;
          for (k = 0, len2 = ref3.length; k < len2; k++) {
            business = ref3[k];
            __out.push('\n                <tr class="business-row">\n                    <td class="avatar-column">\n                        <div class="small-avatar-wrapper">\n                            ');
            if ((ref4 = this.getBusinessInfo(business.business_id)) != null ? ref4.image_url : void 0) {
              __out.push('\n                            <img class="border-gray" src="');
              __out.push(__sanitize(this.getBusinessInfo(business.business_id).image_url));
              __out.push('">\n                            ');
            } else {
              __out.push('\n                            <img src="');
              __out.push(__sanitize(configData.dashboard_images.BUSINESS_CLIENTS));
              __out.push('">\n                            ');
            }
            __out.push('\n                        </div>\n                    </td>\n                    <td class="business-name-column">\n                        ');
            __out.push(__sanitize(business.legal_name));
            __out.push('\n                    </td>\n                    <td class="action-column">\n                      <div class="display-flex-column">\n                        ');
            if (business.vendor_service_status && business.vendor_service_status.count_mergeable > 0) {
              __out.push('\n                        <a class="font-13 font-semi-bold pointer pull-right text-right" href="/businesses/');
              __out.push(__sanitize(business.business_id));
              __out.push('/vendor_service?merge_available=true">\n                        ');
              __out.push(__sanitize(business.vendor_service_status.count_mergeable));
              __out.push(' ');
              __out.push(__sanitize(business.vendor_service_status.count_mergeable > 1 ? 'Vendors' : 'Vendor'));
              __out.push('\n                        </a>\n                        ');
            }
            __out.push('\n                      </div>\n                    </td>\n                </tr>\n                ');
          }
          __out.push('\n                </tbody>\n            </table>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    ');
        if (this.setup_businesses.length > 0) {
          __out.push('\n    <div class="setup-businesses-card">\n        <div class="border-bottom-gray p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n                <i class="display-inline-flex icon-cog font-28 in-blue-900 p-r-10"></i>\n                <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n                    Setup Business\n                </span>\n            </p>\n        </div>\n        <div class="clearfix">\n            <table class="business-status-table">\n                <tbody>\n                ');
          ref5 = this.setup_businesses;
          for (l = 0, len3 = ref5.length; l < len3; l++) {
            business = ref5[l];
            __out.push('\n                <tr class="business-row">\n                    <td class="avatar-column">\n                        <div class="small-avatar-wrapper">\n                            ');
            if (business.image_url) {
              __out.push('\n                            <img class="border-gray" src="');
              __out.push(__sanitize(business.image_url));
              __out.push('">\n                            ');
            } else {
              __out.push('\n                            <img src="');
              __out.push(__sanitize(configData.dashboard_images.BUSINESS_CLIENTS));
              __out.push('">\n                            ');
            }
            __out.push('\n                        </div>\n                    </td>\n                    <td class="business-name-column">\n                        ');
            __out.push(__sanitize(business.legal_name));
            __out.push('\n                    </td>\n                    <td class="action-column">\n                      ');
            if (business.is_business_partner) {
              __out.push('\n                        <a type=\'button\' class="btn btn-blue finish-business-btn" href="/setup-business/');
              __out.push(__sanitize(business.id));
              __out.push('">Finish Setup</a>\n                      ');
            }
            __out.push('\n                    </td>\n                </tr>\n                ');
          }
          __out.push('\n                </tbody>\n            </table>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    ');
        if (this.unconnected_businesses.length > 0) {
          __out.push('\n    <div class="setup-businesses-card">\n        <div class="border-bottom-gray p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n                <span class="display-inline-flex font-28 in-blue-900 p-r-10"><span class="icon-qbo-off"></span></span>\n                <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n                    Disconnected QuickBooks\n                </span>\n            </p>\n        </div>\n        <div class="clearfix">\n            <table class="business-status-table">\n                <tbody>\n                ');
          ref6 = this.unconnected_businesses;
          for (m = 0, len4 = ref6.length; m < len4; m++) {
            business = ref6[m];
            __out.push('\n                <tr class="business-row">\n                    <td class="avatar-column">\n                        <div class="small-avatar-wrapper">\n                            ');
            if (business.image_url) {
              __out.push('\n                            <img class="border-gray" src="');
              __out.push(__sanitize(business.image_url));
              __out.push('">\n                            ');
            } else {
              __out.push('\n                            <img src="');
              __out.push(__sanitize(configData.dashboard_images.BUSINESS_CLIENTS));
              __out.push('">\n                            ');
            }
            __out.push('\n                        </div>\n                    </td>\n                    <td class="business-name-column">\n                        ');
            __out.push(__sanitize(business.display_name || business.legal_name));
            __out.push('\n                    </td>\n                    <td class="action-column">\n                        <a class="font-16 font-semi-bold pointer pull-right" href="/setup-business/');
            __out.push(__sanitize(business.business_id));
            __out.push('?step=2">Connect</a>\n                    </td>\n                </tr>\n                ');
          }
          __out.push('\n                </tbody>\n            </table>\n        </div>\n    </div>\n    ');
        }
        __out.push('\n    ');
        ref7 = this.deactivated_acc_businesses;
        for (n = 0, len5 = ref7.length; n < len5; n++) {
          business_profile = ref7[n];
          __out.push('\n    <div class="business-status-card m-l-auto m-r-auto m-t-20">\n        <div class="border-bottom-gray p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n                <i class="display-table-cell fa fa-warning font-28 in-red-400 p-r-10"></i>\n                <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n              Disconnected Accounts: ');
          __out.push(__sanitize(business_profile.display_name || business_profile.name));
          __out.push('\n            </span>\n            </p>\n        </div>\n        ');
          ref8 = business_profile.deactivated_accounts;
          for (o = 0, len6 = ref8.length; o < len6; o++) {
            account = ref8[o];
            __out.push('\n        <div class="m-t-20 m-b-20 text-left clearfix">\n            <button class="btn btn-blue pull-right reconnect-plaid-account-btn" data-account_id="');
            __out.push(__sanitize(account.reconciliation_payment_account_id));
            __out.push('">Connect</button>\n            <div class="">\n                <p class="font-14 in-red-400 m-b-0">');
            __out.push(__sanitize(account.type === Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE ? 'Credit Card' : 'Bank Account'));
            __out.push(' Disconnected</p>\n                <p class="font-12 in-grey-950 m-b-0">');
            __out.push(__sanitize(account.name));
            __out.push(' ');
            __out.push(__sanitize(account.last4));
            __out.push('</p>\n            </div>\n        </div>\n        ');
          }
          __out.push('\n    </div>\n    ');
        }
        __out.push('\n    ');
        if (this.disconnectedIntegrations.length > 0) {
          __out.push('\n      ');
          ref9 = this.businesses;
          for (p = 0, len7 = ref9.length; p < len7; p++) {
            business = ref9[p];
            __out.push('\n          ');
            integrations = _.where(this.disconnectedIntegrations, {
              business_id: business.id
            });
            __out.push('\n          ');
            if (integrations.length > 0) {
              __out.push('\n            <div class="business-status-card m-l-auto m-r-auto m-t-20">\n              <div class="border-bottom-gray p-b-10 text-left">\n                <p class="display-table-row m-b-0">\n                  <i class="display-table-cell fa fa-warning font-28 in-red-400 p-r-10"></i>\n                  <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n                  Disconnected Revenue System: ');
              __out.push(__sanitize(business.display_name || business.name));
              __out.push('\n                </span>\n                </p>\n              </div>\n              ');
              for (q = 0, len8 = integrations.length; q < len8; q++) {
                integration = integrations[q];
                __out.push('\n              <div class="m-t-20 m-b-20 text-left clearfix">\n                <a class="font-16 btn btn-blue font-semi-bold pointer pull-right" href="/settings/integrations/');
                __out.push(__sanitize(business.id));
                __out.push('/integrations/');
                __out.push(__sanitize(integration.id));
                __out.push('">Connect</a>\n                <div>\n                  <p class="font-14 in-red-400 m-b-0">Revenue System Disconnected</p>\n                  <p class="font-12 in-grey-950 m-b-0">');
                __out.push(__sanitize(integration.external_system_name));
                __out.push('</p>\n                </div>\n              </div>\n              ');
              }
              __out.push('\n            </div>\n          ');
            }
            __out.push('\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    ');
        if (this.isAdminInAnyBusiness) {
          __out.push('\n      <div class="text-center m-t-20">\n        <a class="pointer add-business-btn font-18"><i class="icon icon-plus m-r-10"></i> Add another business</a>\n      </div>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    ');
        if (this.documents) {
          __out.push('\n      ');
          ref10 = this.documents;
          for (r = 0, len9 = ref10.length; r < len9; r++) {
            document = ref10[r];
            __out.push('\n        <div class="business-status-card">\n          <div class="border-bottom-gray p-b-10 text-left">\n            <p class="display-table-row m-b-0">\n              <i class="display-table-cell fa fa-warning font-28 in-red-400 p-r-10"></i>\n              <span class="display-table-cell font-16 font-bold p-t-2 vertical-align-top">\n                  Personal: ');
            __out.push(__sanitize(document.standard_folder_name));
            __out.push(' / ');
            __out.push(__sanitize(document.standard_document_name));
            __out.push('\n              </span>\n            </p>\n          </div>\n          <div class="m-t-20 m-b-20 text-left clearfix">\n            <a class="pull-right font-16 pointer" href="/document/');
            __out.push(__sanitize(document.id));
            __out.push('">View Document</a>\n            <div class="">\n              ');
            if (document.is_expired) {
              __out.push('\n                <p class="font-14 in-red-400 m-b-0">Document expired</p>\n              ');
            } else {
              __out.push('\n                <p class="font-14 in-red-400 m-b-0">Document expiring soon</p>\n              ');
            }
            __out.push('\n              <p class="font-12 in-grey-950 m-b-0">');
            __out.push(__sanitize(document.name));
            __out.push('</p>\n            </div>\n          </div>\n        </div>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    <div class="clearfix m-t-20 p-t-10 p-b-10 p-l-10 p-r-10 personal-header-business-pan max-width-600px m-l-auto m-r-auto">\n      <div class="col-sm-12 col-md-8">\n        <p class="font-24 in-white m-b-0 text-left">Own a business?</p>\n        <p class="font-14 m-b-0 in-white transparent-70 text-left">Organize your business on Docyt. Never worry about audits, accounting and taxes.</p>\n      </div>\n      <div class="col-sm-12 col-md-4">\n        <button type="button" class="btn btn-add-business add-business-btn m-t-20 pull-right font-14 in-blue-900">\n          <i class="icon icon-o-plus"></i>\n          <span class="m-l-5">Add Business</span>\n        </button>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}