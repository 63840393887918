import React from 'react';

const ComplianceHeader = () => {
  return (
    <>
      <h2 className="font-26 font-bold m-b-0 m-t-0">Compliance Policy</h2>
      <hr className="m-t-13" />
    </>
  );
};

export default ComplianceHeader;
