import { TActionsColumn, TModel } from './types';

const CollectionTableActionsColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TActionsColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableActionsColumn;
