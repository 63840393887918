import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  createBankAccountFromPlaid, createCreditCard,
  getBankAccountsByBusinessIds,
  getCreditCardsByBusinessIds, ICreateBankAccountFromPlaidParams, ICreateCreditCardParams,
} from '@src/requests/billing_payment_methods';
import { IBillingBankAccount, IBillingCreditCard } from '@src/types/billing_payment_methods';

const useCreateBankAccountFromPlaid = () => {
  const queryClient = useQueryClient();

  return useMutation<
    any,
    Error,
    ICreateBankAccountFromPlaidParams
    >(
      createBankAccountFromPlaid,
      {
        onSuccess: () => {
          return queryClient.invalidateQueries(QueryKey.billingBankAccounts);
        },
      },
    );
};

const useCreateCreditCard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    any,
    Error,
    ICreateCreditCardParams
    >(
      createCreditCard,
      {
        onSuccess: () => {
          return queryClient.invalidateQueries(QueryKey.billingCreditCards);
        },
      },
    );
};

const useGetBillingBankAccountsByBusinessIds = (businessIds: number[]) => {
  return useQuery<IBillingBankAccount[], Error>({
    queryKey: QueryKey.billingBankAccounts,
    queryFn:  () => getBankAccountsByBusinessIds({
      businessIds,
    }),
  });
};

const useGetBillingCreditCardsByBusinessIds = (businessIds: number[]) => {
  return useQuery<IBillingCreditCard[], Error>({
    queryKey: QueryKey.billingCreditCards,
    queryFn:  () => getCreditCardsByBusinessIds({
      businessIds,
    }),
  });
};

export {
  useCreateBankAccountFromPlaid,
  useCreateCreditCard,
  useGetBillingBankAccountsByBusinessIds,
  useGetBillingCreditCardsByBusinessIds,
};
