import React from 'react';

import styles from './styles.module.scss';

interface IBottomModalBodyProps {
  children: React.ReactNode,
}

const BottomModalBody = ({
  children,
}: IBottomModalBodyProps) => {
  return (
    <div className={ styles['bottom-modal-body'] }>
      { children }
    </div>
  );
};

export default BottomModalBody;
