import React from 'react';

import financialReportsImg from '@assets/images/financial_reports/FinancialReports@2x.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const FinancialReportsIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Financial reports icon"
      src={ financialReportsImg }
      { ...props }
    />
  );
};

export default FinancialReportsIcon;
