module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, payer, ref;
    
      __out.push('<div class="authorized-payer-container">\n    ');
    
      if (this.is_ready) {
        __out.push('\n    <div class="authorized-payer-wrapper">\n        <h2 class="font-24 font-semi-bold"><a class="font-16 in-blue-950" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>Authorized Signatory</h2>\n        <hr/>\n        <div class="table-responsive payers-table-wrapper service-detail-view">\n            <table class="table m-b-0">\n                <tbody>\n                ');
        ref = this.payers;
        for (i = 0, len = ref.length; i < len; i++) {
          payer = ref[i];
          __out.push('\n                <tr>\n                    <td class="width-10-percent">\n                        <div class="middle-avatar-wrapper m-l-auto m-r-auto">\n                            ');
          if (payer.image_url) {
            __out.push('\n                            <img src="');
            __out.push(__sanitize(payer.image_url));
            __out.push('">\n                            ');
          } else {
            __out.push('\n                            <i class="icon icon-smile in-blue-400 font-38"></i>\n                            ');
          }
          __out.push('\n                        </div>\n                    </td>\n                    <td class="">\n                        <span class="font-14 font-semi-bold">');
          __out.push(__sanitize(payer.name));
          __out.push('</span>\n                    </td>\n                    <td class="relative">\n                        ');
          if (this.can_remove_bank_admin) {
            __out.push('\n                        <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="payer-');
            __out.push(__sanitize(payer.id));
            __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n                            <i class=\'icon icon-3-dots font-20\'></i>\n                        </button>\n                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="payer-');
            __out.push(__sanitize(payer.id));
            __out.push('-action-dropdown-btn">\n                            <a class="dropdown-item delete-payer-js in-red-400 pointer display-flex" data-id="');
            __out.push(__sanitize(payer.id));
            __out.push('">\n                                <i class="icon icon-new-trash font-18"></i>\n                                <span class="font-14 font-semi-bold m-l-5">Remove</span>\n                            </a>\n                        </div>\n                        ');
          }
          __out.push('\n                    </td>\n                </tr>\n                ');
        }
        __out.push('\n                <tr class="add-payer-row">\n                    <td>\n                        <div class="middle-avatar-wrapper m-l-auto m-r-auto add-payer-js">\n                            <i class="icon icon-o-plus font-38 in-blue-900"></i>\n                        </div>\n                    </td>\n                    <td>\n                        <a class="pointer font-14 font-semi-bold add-payer-js">Add Team Member</a>\n                    </td>\n                    <td></td>\n                </tr>\n                </tbody>\n            </table>\n        </div>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="authorized-payer-container">\n        <div class="authorized-payer-wrapper">\n            <h2 class="font-24 font-semi-bold"><a class="font-16 in-blue-950" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>Authorized Signatory</h2>\n            <hr/>\n            <div class="m-t-30 m-b-20">\n                ');
        if (this.has_own_signature) {
          __out.push('\n                <div class="reuse-signature-panel">\n                    <div class="relative m-r-5 m-l-5">\n                        <canvas width="110" height="80" class="img-digital-signature m-r-20"></canvas>\n                        <div class="page-spin-loader">\n                            <i class="fa fa-spin fa-spinner font-38"></i>\n                        </div>\n                    </div>\n                    <p class="m-r-20">Signature already exists for <b>');
          __out.push(__sanitize(this.signatory));
          __out.push('</b>.<br>You can reuse your signature or scan the QR code below to add a new signature.</p>\n                    <button class="btn btn-blue font-16 font-semi-bold m-r-20 reuse-signature-btn">Reuse Signature</button>\n                    <button class="font-24 close">&times;</button>\n                </div>\n                ');
        }
        __out.push('\n                <div class="digital-signature-panel">\n                    <img class="digital-signature-qr-code width-100-percent" src="">\n                </div>\n                <div class="text-center">\n                    <p class="font-14 m-t-20">Scan QR code to add signature that will be used on bank checks.<br>\n                        This signature should be identical to the one on file with your bank.\n                    </p>\n                    <div class="text-center">\n                        <div class="display-inline-block text-left">\n                            <p class="m-t-20">Step 1: Scan QR code with your phone and follow the link.</p>\n                            <p class="m-t-10">Step 2: Add digital signature on the drawpad that shows up.</p>\n                            <p class="m-t-10">Step 3: Tap the Save button.</p>\n                            <p class="m-t-10">Step 4: <a class="font-semi-bold pointer refresh-page-js">Click here to Refresh.</a></p>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}