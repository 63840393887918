import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

const calculateAmount =
  (
    item: IAdjustmentEntry,
    reconciliationPaymentAccount: IReconciliationPaymentAccount,
    balanceSheetAccount: IChartOfAccount,
  ) => {
    let amount = Number(item.amount);

    if (reconciliationPaymentAccount) {
      const isCreditCard =
        reconciliationPaymentAccount.accountType
        === window.Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE;

      const targetLines =
        item.lines?.filter((i) => i.chartOfAccount?.id === balanceSheetAccount?.id) || [];
      const debits = targetLines.reduce((acc, current) => acc + (Number(current.debits) || 0), 0);
      const credits = targetLines.reduce((acc, current) => acc + (Number(current.credits) || 0), 0);

      amount = isCreditCard ? credits - debits : debits - credits;
    }

    return amount;
  };

const useLineAmount = (item: IAdjustmentEntry) => {
  const { reconciliationPaymentAccount,
    balanceSheetAccount } = useBankAccountReconciliationContext();

  const amount = calculateAmount(item, reconciliationPaymentAccount, balanceSheetAccount);

  return amount;
};

const useTotalAmount = (adjustmentEntries: IAdjustmentEntry[]) => {
  const { reconciliationPaymentAccount,
    balanceSheetAccount } = useBankAccountReconciliationContext();

  const totalAmount = adjustmentEntries.reduce((acc, item) => {
    const amount = calculateAmount(item, reconciliationPaymentAccount, balanceSheetAccount);
    return acc + amount;
  }, 0);

  return totalAmount;
};

export { useLineAmount, useTotalAmount };
