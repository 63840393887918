module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<ul class="nav nav-sidebar">\n  <li class="');
    
      __out.push(__sanitize(this.step === 1 ? 'active' : ''));
    
      __out.push('">\n    <a class="">\n      <div>\n        <img src="');
    
      __out.push(__sanitize(configData.images.shop_image));
    
      __out.push('">\n      </div>\n      <span class="nav-item-text">Step 1: Create Your Vendor Profile</span>\n      ');
    
      if (this.business.id) {
        __out.push('\n      <i class="icon-check m-l-auto m-r-0 in-green-1000"></i>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === 2 ? 'active' : ''));
    
      __out.push('">\n    <a class="">\n      <i class="icon-send display-table">\n        <span class="icon-send"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      </i>\n      <span class="nav-item-text">Step 2: Verify Email</span>\n      ');
    
      if (this.business.get('is_email_verified')) {
        __out.push('\n      <i class="icon-check m-l-auto m-r-0 in-green-1000"></i>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === 3 ? 'active' : ''));
    
      __out.push('">\n    <a class="">\n      <i class="icon-bank-transactions-src display-table">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>\n      </i>\n      <span class="nav-item-text">Step 3: Get Paid by ACH</span>\n      ');
    
      if (this.ach_configured) {
        __out.push('\n      <i class="icon-check m-l-auto m-r-0 in-green-1000"></i>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.step === 4 ? 'active' : ''));
    
      __out.push('">\n    <a class="">\n      <div class="">\n        <i class="icon-ach-document display-table m-l-2"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>\n      </div>\n      <span class="nav-item-text">Step 4: W9 Information</span>\n    </a>\n  </li>\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}