import React from 'react';

import { CloseIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IMultiReactSelectedValueProps {
  label: string,
  value: string | number,
  onRemoveItem: (value: string | number) => void,
}

const MultiReactSelectedValue = ({
  label,
  value,
  onRemoveItem,
}: IMultiReactSelectedValueProps) => {
  return (
    <div className={ styles['multi-react-selected-values'] }>
      <span>{ label }</span>
      <CloseIcon
        pointer
        fontSize={ 26 }
        inColor="grey-1050"
        pl={ 3 }
        onClick={ () => onRemoveItem(value) }
      />
    </div>
  );
};

export default React.memo(MultiReactSelectedValue);
