import { useCallback, useEffect, useState } from 'react';

import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { uniqBy } from 'lodash';
import { UserMessage } from 'sendbird';

import toastr from '@lib/toastr';
import { camelizeKeys } from '@src/utils/transform_keys';

import { useAiChatBotContext } from '../context/ai_chat_context';
import { IMessageMetaData, IMessageSchema } from '../types';

interface IUseMessageListenerProps {
  scrollToBottom: () => void;
}
export const useMessageListener = ({
  scrollToBottom,
}: IUseMessageListenerProps) => {
  const context = useSendbirdStateContext();

  const [message, setMessage] = useState<IMessageSchema[]>();
  const [loader, setLoader] = useState(false);
  const {
    activeThreadId,
    activeChannelID,
    setActiveThreadid,
    sendbirdInstance,
  } = useAiChatBotContext();

  useEffect(() => {
    setMessage(undefined);
  }, [activeChannelID]);

  useEffect(() => {
    let timer : any;
    if (loader) {
      timer = setTimeout(() => {
        setLoader(false);
      }, window.configData.ai_chatbot.message_timeout ?? 60000);
    }
    return () => clearTimeout(timer);
  }, [loader]);

  const getMessageToSet = (
    currentMessage: UserMessage,
    foundMessage: undefined | IMessageSchema,
  ): IMessageSchema | undefined => {
    if (window.configData.ai_chatbot.sendbird_bot_id === currentMessage.sender?.userId && foundMessage) {
      setLoader(false);
      return {
        ...foundMessage,
        answer:                 String(currentMessage.message),
        channelAnswerMessageId: String(currentMessage.messageId),
      };
    }
    if (window.configData.ai_chatbot.sendbird_bot_id !== currentMessage.sender?.userId) {
      return {
        id:                       String(currentMessage.messageId),
        channelQuestionMessageId: String(currentMessage.messageId),
        question:                 String(currentMessage.message),
        answer:                   '',
        channelAnswerMessageId:   '',
      };
    }
    setLoader(false);
    return undefined;
  };

  const onMessageReceived = useCallback((_: any, mess: UserMessage) => {
    const parsedMetaData = mess.data
      ? (camelizeKeys(JSON.parse(mess.data)) as IMessageMetaData)
      : undefined;
    if (parsedMetaData?.chatThreadId && !activeThreadId) {
      setActiveThreadid(parsedMetaData.chatThreadId);
    } else if (activeThreadId && activeThreadId !== parsedMetaData?.chatThreadId) {
      return;
    }
    const foundMessage = message
      ?.find((item) => item.channelQuestionMessageId === parsedMetaData?.channelQuestionMessageId);
    const messageToSet = getMessageToSet(mess, foundMessage);
    if (messageToSet?.id) {
      setMessage((pre) => {
        if (pre) {
          const index = pre.findIndex((item) => item.id === messageToSet.id);
          if (index !== -1) {
            pre[index] = messageToSet;
            return [...pre];
          }
          return [messageToSet, ...pre];
        }
        return [messageToSet];
      });
      scrollToBottom();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, scrollToBottom, activeThreadId, activeChannelID]);

  useEffect(() => {
    sendbirdInstance.connect(
      String(context.config.userId),
      context.config.accessToken,
      (user, error) => {
        if (error) {
          toastr.error(error.message, 'Chat');
          return;
        }
        const channelHandler = new sendbirdInstance.ChannelHandler();
        channelHandler.onMessageReceived = onMessageReceived;
        sendbirdInstance.addChannelHandler(activeChannelID ?? '', channelHandler);
      },
    );

    return () => sendbirdInstance.removeChannelHandler(activeChannelID ?? '');
  }, [activeChannelID, context.config, onMessageReceived, sendbirdInstance]);

  useEffect(() => {
    if (message && message?.length > 20) return;
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [message, scrollToBottom]);

  const addMessage = useCallback((history: IMessageSchema[], isFirstPage: boolean) => {
    if (isFirstPage) {
      setMessage(history);
    } else {
      setMessage((pre) => {
        if (pre) {
          const uniqueList = uniqBy([...history, ...pre], 'id');
          return uniqueList;
        }
        return history;
      });
    }
  }, []);
  return { message, addMessage, loader, setLoader };
};
