import { TMongoID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IReportDataUpdatedFayeEvent } from '@src/types/docyt_events/report_data_updated';

import { useFayeChannel } from './common';

export const useUpdateReportDataFayeChannel = (
  reportId: TMongoID,
  callback: TFayeEventCallback<IReportDataUpdatedFayeEvent>,
) => {
  useFayeChannel(
    `/advanced-report-${reportId}`,
    callback,
  );
};
