import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { flatten } from 'lodash';

import { useGetReconciliationPaymentAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';
import { IPaymentAccount, ISelectedAccountData } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import Dropdown from '@src/components/ui/dropdown/dropdown';
import {
  FormFieldWrapper,
  IFormFieldWrapperProps,
} from '@src/components/ui/form';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Option from './option';

import styles from './styles.module.scss';

interface IPaymentAccountSelectProps extends
  Omit<IFormFieldWrapperProps, 'children'>
{
  businessId: TID,
  className?: string,
  selectedAccountData: ISelectedAccountData,
  onAccountSelected: (account: ISelectedAccountData) => void,
}

const BlankOptionName = 'All Accounts';

const PaymentAccountSelect = ({
  businessId,
  className,
  selectedAccountData,
  onAccountSelected,
  error,
  hint,
  label,
  mark,
}: IPaymentAccountSelectProps) => {
  const accountQuery = useGetReconciliationPaymentAccounts({
    businessId,
    noConsiderArchive: true,
  });

  const accounts = useMemo(() => {
    return accountQuery.data?.reconciliationPaymentAccounts || [];
  }, [accountQuery.data?.reconciliationPaymentAccounts]);

  const handleAllSelect = useCallback(() => {
    onAccountSelected({
      reconciliationPaymentAccountId: undefined,
      paymentAccountId:               undefined,
      accountType:                    undefined,
    });
  }, [onAccountSelected]);

  const handleRPASelect = useCallback((account: IReconciliationPaymentAccount) => {
    onAccountSelected({
      reconciliationPaymentAccountId: account.id,
      paymentAccountId:               undefined,
      accountType:                    account.accountType,
    });
  }, [onAccountSelected]);

  const handlePaymentAccountSelect = useCallback((account: IPaymentAccount) => {
    onAccountSelected({
      reconciliationPaymentAccountId: undefined,
      paymentAccountId:               account.id,
      accountType:                    account.accountType,
    });
  }, [onAccountSelected]);

  const selectedAccount = useMemo(() => {
    const reconciliationPaymentAccountId = selectedAccountData?.reconciliationPaymentAccountId;
    if (reconciliationPaymentAccountId) {
      return accounts.find((a) => a.id === reconciliationPaymentAccountId);
    }

    const paymentAccountId = selectedAccountData?.paymentAccountId;
    if (paymentAccountId) {
      const paymentAccounts = flatten(accounts.map((a) => a.paymentAccounts));

      return paymentAccounts.find((a) => a.id === paymentAccountId);
    }

    return undefined;
  }, [accounts, selectedAccountData]);

  const mainClass = classNames(
    className,
    'form-control nowrap flex-container align-items-center',
  );

  return (
    <FormFieldWrapper
      error={ error }
      hint={ hint }
      label={ label }
      mark={ mark }
    >
      <Dropdown
        className="display-flex relative transaction-filter-banking-account"
      >
        <div className={ mainClass }>
          {
            !selectedAccount && BlankOptionName
          }
          {
            selectedAccount && selectedAccount.accountOwner && (
              <div className="display-flex justify-content-between width-100-percent">
                <span>{ selectedAccount.name }</span>
                <span className={ styles['employee-account-owner-name'] }>{ selectedAccount.accountOwner }</span>
              </div>
            )
          }
          {
            selectedAccount && !selectedAccount.accountOwner && (
              selectedAccount.name
            )
          }
        </div>
        <Dropdown.LinkToggle
          noButton
          pointer
          className="date-select-toggle range-select-menu-btn"
          mb={ 6 }
        >
          <CaretIcon fontSize={ 14 } variant="down" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu pointer alignment="right" className={ styles['account-dropdown-menu'] } fontSize={ 24 }>
          <Dropdown.Item className="nowrap" onSelect={ handleAllSelect }>
            { BlankOptionName }
          </Dropdown.Item>

          {
            accounts.map((account) => (
              <Option
                key={ account.id }
                account={ account }
                onPaymentAccountSelect={ handlePaymentAccountSelect }
                onReconciliationPaymentAccountSelect={ handleRPASelect }
              />
            ))
          }
        </Dropdown.Menu>
      </Dropdown>
    </FormFieldWrapper>
  );
};

export default React.memo(PaymentAccountSelect);
