import { useMemo } from 'react';

import { useQuery, UseQueryOptions } from 'react-query';

import { getStandardDocuments } from '@src/requests/standard_documents';
import { TID } from '@src/types/common';
import { IStandardDocument, IStandardFolderWithDocuments } from '@src/types/standard_documents';

const useGetStandardDocuments = (
  businessId: TID,
  ownDocuments: boolean = false,
  options: UseQueryOptions<IStandardDocument[], Error> = {},
) => {
  return useQuery<IStandardDocument[], Error>(
    ['standard_documents', businessId, ownDocuments],
    () => getStandardDocuments(businessId, ownDocuments),
    options,
  );
};

const useGetStandardDocumentsByFolders = (businessId: number) => {
  const query = useGetStandardDocuments(businessId);

  const standardFolders: IStandardFolderWithDocuments[] = useMemo(() => {
    if (!query.data) return [];

    return query.data.reduce((folders, document) => {
      let folder: IStandardFolderWithDocuments | undefined;
      folder = folders.find((f) => f.id === document.standardFolderId);

      if (!folder) {
        folder = {
          id:        document.standardFolderId,
          name:      document.standardFolderName,
          documents: [],
        };

        folders.push(folder);
      }

      folder.documents.push(document);

      return folders;
    }, [] as IStandardFolderWithDocuments[]);
  }, [query.data]);

  return { ...query, data: standardFolders };
};

export {
  useGetStandardDocuments,
  useGetStandardDocumentsByFolders,
};
