import Body from './full_page_body';
import Footer from './full_page_footer';
import FooterFiller from './full_page_footer_filler';
import FooterHeader from './full_page_footer_header';
import Header from './full_page_header';
import FullPageModal from './full_page_modal';
import SideMenu from './full_page_side_menu'

export default Object.assign(FullPageModal, {
  Header,
  Body,
  Footer,
  FooterHeader,
  FooterFiller,
  SideMenu,
});
