import React from 'react';

import { TID } from '@src/types/common';
import { IInboxStatus } from '@src/types/service_statuses';

import Table from '@src/components/ui/table';

import styles from '../styles.module.scss';

interface IInboxStatusProps {
  clientBusinessId: TID;
  status?: IInboxStatus,
}

const InboxStatus = ({
  clientBusinessId,
  status,
}: IInboxStatusProps) => {
  let content;
  if (!status || status.unreadDocs === 0) {
    content = (
      <span className={ styles['non-status'] }>-</span>
    );
  } else {
    content = (
      <a
        className={ styles['client-business-status'] }
        href={ `/businesses/${clientBusinessId}/business_inbox` }
        role="button"
        tabIndex={ 0 }
      >
        { status.unreadDocs }
        { ' ' }
        { status.unreadDocs === 1 ? 'Doc' : 'Docs' }
      </a>
    );
  }

  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 10 }
    >
      { content }
    </Table.Cell>
  );
};

export default InboxStatus;
