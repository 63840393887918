import React, { useMemo, ReactNode, Children } from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import FooterFiller from './full_page_footer_filler';
import FooterHeader from './full_page_footer_header';

import styles from './styles.module.scss';

interface IFullPageFooterProps {
    cancelTitle?: string;
    proceedTitle?: string;
    isProceedDisabled?: boolean;
    onCancel: () => void;
    onProceed: () => void;
    children: ReactNode;
  }

const FullPageFooter = ({
  cancelTitle = 'Cancel',
  proceedTitle = 'Proceed',
  isProceedDisabled = false,
  onCancel,
  onProceed,
  children,
}: IFullPageFooterProps) => {
  const [headerContent, fillerContent] = useMemo(() => {
    let header = null;
    let filler = null;

    Children.forEach(children, (child) => {
      if (child && typeof child === 'object' && 'type' in child) {
        if (child.type === FooterHeader) {
          header = child.props.children;
        } else if (child.type === FooterFiller) {
          filler = child.props.children;
        } else {
          throw new Error(
            'Only "FullPageModal.FooterHeader" and "FullPageModal.FooterFiller" components are allowed as children of "FullPageModal.Footer"',
          );
        }
      }
    });

    return [header, filler];
  }, [children]);

  return (
    <div className={ styles.footer }>
      { headerContent }
      <div className={ styles['footer-actions'] }>
        <Button variant="link" onClick={ onCancel }>
          {cancelTitle}
        </Button>
        { fillerContent }
        <Button
          disabled={ isProceedDisabled }
          variant="primary"
          onClick={ onProceed }
        >
          {proceedTitle}
        </Button>
      </div>
    </div>
  );
};

export default FullPageFooter;
