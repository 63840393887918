import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateMultiEntityReport } from '@src/hooks/queries/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from '../forms/rename_report';
import { IRenameReportFormValues } from '../forms/schema';

interface IRenameReportModalProps extends IUseModalProps {
  item: IMultiBusinessReportDetail
}

const RenameReportModal = ({
  item,
  isOpen,
  onCancel,
  onDone,
}: IRenameReportModalProps) => {
  const updateReport = useUpdateMultiEntityReport();
  const { mutate } = updateReport;

  const handleDone = useCallback((data: IRenameReportFormValues) => {
    mutate(
      {
        ...item,
        name: data.name,
      },
      {
        onSuccess: () => onDone(),
      },
    );
  }, [onDone, item, mutate]);

  return (
    <>
      <Modal.Form
        proceedTitle="Save"
        show={ isOpen }
        title="Rename Advanced Report"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            formId={ formId }
            item={ item }
            onSubmit={ handleDone }
          />
        )}
      </Modal.Form>
      <MutationStatus mutation={ updateReport } />
    </>
  );
};

const useRenameReportModal = makeUseModal(RenameReportModal);

export { IRenameReportModalProps, useRenameReportModal, RenameReportModal as default };
