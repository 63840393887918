import React, { useCallback, useMemo } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import toastr from '@lib/toastr';
import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import {
  useCreateBankAccountFromPlaid,
  useCreateCreditCard,
  useGetBillingBankAccountsByBusinessIds,
  useGetBillingCreditCardsByBusinessIds,
} from '@src/hooks/queries/billing_payment_methods';
import { useGetSubscriptionsByBusinessIds } from '@src/hooks/queries/subscriptions';
import {
  ICreateBankAccountFromPlaidParams,
  ICreateCreditCardParams,
} from '@src/requests/billing_payment_methods';
import { IBillingBankAccount, IBillingCreditCard } from '@src/types/billing_payment_methods';
import { TID } from '@src/types/common';
import {
  ISelfOnboardingBusiness,
  ISelfOnboardingInvitation,
} from '@src/types/self_onboarding_invitation';
import { ISubscription } from '@src/types/subscriptions';

import { Button } from '@src/components/ui/buttons';
import SideView from '@src/components/ui/side_view';
import Table from '@src/components/ui/table/table';

import BillingInfoListItem from './billing_info_item';

import styles from './styles.module.scss';

const stripePromise = loadStripe(window.configData.stripePublishableKey || '');

interface IBillingInfoListProps {
  invitation: ISelfOnboardingInvitation,
}

const getBusinessSubscription = (
  subscriptions: ISubscription[],
  businessId: TID,
): ISubscription | undefined => {
  return subscriptions.find((subscription) => subscription.businessId === businessId);
};

const getBankAccount = (
  bankAccounts: IBillingBankAccount[],
  businessId: TID,
  subscription?: ISubscription,
): IBillingBankAccount | undefined => {
  if (subscription?.paymentMethodType === 'BankAccount') {
    return bankAccounts.find((bankAccount) => bankAccount.id === subscription.paymentMethodId);
  }

  return bankAccounts.find((account) => Number(account.businessId) === businessId);
};

const getCreditCard = (
  creditCards: IBillingCreditCard[],
  businessId: TID,
  subscription?: ISubscription,
): IBillingCreditCard | undefined => {
  if (subscription?.paymentMethodType === 'CreditCard') {
    return creditCards.find((creditCard) => creditCard.id === subscription.paymentMethodId);
  }

  return creditCards.find((account) => Number(account.businessId) === businessId);
};

const getBillingInfoItem = (
  business: ISelfOnboardingBusiness,
  subscriptions: ISubscription[],
  bankAccounts: IBillingBankAccount[],
  creditCards: IBillingCreditCard[],
  onAddNewAccount: (newAccount: ICreateBankAccountFromPlaidParams | ICreateCreditCardParams) => void,
): JSX.Element => {
  const subscription = getBusinessSubscription(subscriptions, business.id);
  let bankAccount = getBankAccount(bankAccounts, business.id, subscription);
  let creditCard = getCreditCard(creditCards, business.id, subscription);

  if (!subscription && bankAccount && creditCard) {
    if (bankAccount.createdAt > creditCard.createdAt) {
      creditCard = undefined;
    } else {
      bankAccount = undefined;
    }
  }
  return (
    <BillingInfoListItem
      key={ business.id }
      bankAccount={ bankAccount }
      business={ business }
      creditCard={ creditCard }
      onAddNewAccount={ onAddNewAccount }
    />
  );
};

const BillingInfoList = ({
  invitation,
}: IBillingInfoListProps) => {
  const createBankAccount = useCreateBankAccountFromPlaid();
  const createCreditCard = useCreateCreditCard();
  const businessIds = invitation.businesses.map((p) => p.id);
  const subscriptionsQuery = useGetSubscriptionsByBusinessIds(businessIds);
  const bankAccountsQuery = useGetBillingBankAccountsByBusinessIds(businessIds);
  const creditCardsQuery = useGetBillingCreditCardsByBusinessIds(businessIds);
  const subscriptions = useMemo(() => subscriptionsQuery.data || [], [subscriptionsQuery]);
  const bankAccounts = useMemo(() => bankAccountsQuery.data || [], [bankAccountsQuery]);
  const creditCards = useMemo(() => creditCardsQuery.data || [], [creditCardsQuery]);

  const { mutateAsync: mutateBankAccount } = createBankAccount;
  const { mutateAsync: mutateCreditCad } = createCreditCard;

  const handleAddNewAccount = useCallback(async (
    account: ICreateBankAccountFromPlaidParams | ICreateCreditCardParams
  ) => {
    window.Docyt.vent.trigger('show:spinner');
    try {
      if ('plaidPublicToken' in account) {
        await mutateBankAccount(account);
      } else {
        await mutateCreditCad(account);
      }
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to add: ${errorMessage}`, 'Error');
    }
    window.Docyt.vent.trigger('hide:spinner');
  }, [mutateBankAccount, mutateCreditCad]);

  const handleSubmit = useCallback(() => {
    Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_CONNECT_QBO}`, { trigger: true });
  }, []);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_REQUEST_DOCUMENT}`,
      { trigger: true },
    );
  }, []);

  return (
    <Elements stripe={ stripePromise }>
      <div className={ styles['self-onboarding-qbo-container'] }>
        <SideView.Provider>
          <div className="display-flex-column width-100-percent height-100-percent">
            <div className="tasks-container">
              <h2>Time to streamline billing!</h2>
              <p className="font-11 in-grey-1050">
                Add your preferred payment method for your Docyt plans to ensure seamless experience.
                To stop being billed, you need to cancel the current subscription.
              </p>
              <div className="tasks-view">
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.HCell
                        className={ styles['title-cell'] }
                        textAlign="left"
                        widthPercent={ 40 }
                      >
                        Business Name
                      </Table.HCell>
                      <Table.HCell
                        className={ styles['title-cell'] }
                        textAlign="right"
                        widthPercent={ 60 }
                      >
                        Billing Method
                      </Table.HCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {
                      invitation.businesses.map((business) => (
                        getBillingInfoItem(
                          business,
                          subscriptions,
                          bankAccounts,
                          creditCards,
                          handleAddNewAccount,
                        )
                      ))
                    }
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div className="setup-client-footer">
              <a
                className="cancel-link pointer"
                role="button"
                tabIndex={ -1 }
                onClick={ onBack }
              >
                Previous
              </a>
              <Button
                className="pull-right bg-purple-1000 in-white width-180px"
                data-color="$purple-1000"
                onClick={ handleSubmit }
              >
                Next
              </Button>
            </div>
          </div>
          <SideView.Render />
        </SideView.Provider>
      </div>
    </Elements>
  );
};

export default BillingInfoList;
