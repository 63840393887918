import React from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Table from '@src/components/ui/table';

import UploadedStatusItem from './items/uploaded_status_item';
import VerifiedStatusItem from './items/verified_status_item';
import DeleteRequestStatementAction from '../balance_sheet_statement_state/items/actions/delete_request_statement_action';

import styles from './styles.module.scss';

interface IStatementDetailsTableItemProps {
  balanceSheetStatement: IBalanceSheetStatement,
  bankStatementsDocRequestEnabled: boolean,
  chartOfAccountId: TID,
  businessId: TID,
  onCloseDetailsModal?: () => void,
}

const StatementDetailsTableItem = ({
  balanceSheetStatement,
  bankStatementsDocRequestEnabled,
  businessId,
  onCloseDetailsModal,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  chartOfAccountId,
  /* eslint-disable @typescript-eslint/no-unused-vars */

}: IStatementDetailsTableItemProps) => {
  const BANK_STATEMENT_STATES = window.Docyt.Common.Constants.BANK_STATEMENT_STATES;
  let stateContent;
  let fileNameContent = (<span>--</span>);
  if (balanceSheetStatement.state === 'verified') {
    stateContent = (<span className={ styles['verified-state'] }>Verified</span>);

    fileNameContent = (
      <VerifiedStatusItem
        businessId={ businessId }
        state={ balanceSheetStatement }
      />
    );
  } else if (balanceSheetStatement.state === 'not_available') {
    stateContent = (<span className={ styles['not-available-state'] }>No Document Available</span>);
  } else if (bankStatementsDocRequestEnabled
    && balanceSheetStatement.state === BANK_STATEMENT_STATES.UPLOADED) {
    stateContent = (<span className={ styles['uploaded-state'] }>Uploaded</span>);
    fileNameContent = (
      <UploadedStatusItem
        businessId={ businessId }
        state={ balanceSheetStatement }
        onCloseModal={ onCloseDetailsModal }
      />
    );
  } else if (bankStatementsDocRequestEnabled
    && balanceSheetStatement.state === BANK_STATEMENT_STATES.REQUESTED) {
    stateContent = (<span className={ styles['requested-state'] }>Requested</span>);
  } else {
    stateContent = (<span className={ styles['requested-state'] }>Missing</span>);
  }

  return (
    <Table.Row className={ styles['table-row'] }>
      <Table.Cell pointer>
        { fileNameContent }
      </Table.Cell>
      { bankStatementsDocRequestEnabled && (
      <Table.Cell>
        { balanceSheetStatement.uploadedByName }
      </Table.Cell>
      )}
      <Table.Cell>
        { balanceSheetStatement.description }
      </Table.Cell>
      <Table.Cell>
        { balanceSheetStatement.verifiedByName }
      </Table.Cell>
      <Table.Cell>
        { stateContent }
      </Table.Cell>
      <Table.Cell className="bank-statement-actions-column">
        <ActionsDropdown>
          {
            balanceSheetStatement.state === BANK_STATEMENT_STATES.REQUESTED
              && (
                <DeleteRequestStatementAction
                  businessId={ businessId }
                  state={ balanceSheetStatement }
                />
              )
          }
        </ActionsDropdown>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(StatementDetailsTableItem);
