import React, { useCallback } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ICancelModalButtonProps extends
  React.ComponentPropsWithoutRef<'a'>,
  IUIStyleProps
{
  title?: string,
  onClick: () => void;
}

/**
 * Button that is used as "Cancel" button for modal dialog. Usually you should not use
 * this component directly. Use higher level [Modal.Standard](#/Modal?id=modal_standard) or
 * [Modal.Form](#/Modal?id=modal_form) components instead.
 */
const CancelModalButton = ({
  title = 'Cancel',
  onClick,
  ...props
}: ICancelModalButtonProps) => {
  const [classes, anchorProps] = uiStyleProps('cancel-link', props);

  const handleCancelClick = useCallback((e) => {
    e.preventDefault();

    onClick();
  }, [onClick]);

  return (
    <a
      className={ classes }
      role="button"
      tabIndex={ 0 }
      onClick={ handleCancelClick }
      { ...anchorProps }
    >
      { title }
    </a>
  );
};

export default CancelModalButton;
