import { useQuery } from 'react-query';

import {
  getQuickbooksPaymentAccounts,
  IGetQuickbooksPaymentAccountsParams,
  IGetQuickbooksPaymentAccountsResponse,
} from '@src/requests/quickbooks_payment_accounts';

const QUICKBOOKS_PAYMENT_ACCOUNTS_QUERY = 'quickbooks_payment_accounts';

const useGetQuickbooksPaymentAccounts = (params: IGetQuickbooksPaymentAccountsParams) => {
  return useQuery<IGetQuickbooksPaymentAccountsResponse, Error>(
    [QUICKBOOKS_PAYMENT_ACCOUNTS_QUERY, params],
    () => getQuickbooksPaymentAccounts(params),
  );
};

export {
  QUICKBOOKS_PAYMENT_ACCOUNTS_QUERY,
  useGetQuickbooksPaymentAccounts,
};
