import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetQuickbooksPaymentAccounts } from '@src/hooks/queries/quickbooks_payment_accounts';
import { TAccountType, TID } from '@src/types/common';
import { IQuickbooksPaymentAccounts } from '@src/types/quickbooks_payment_accounts';

import { ErrorNotification } from '@src/components/ui/notification';
import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { PlusIcon, QBOOnIcon } from '@src/components/utils/icomoon';

import { IQBOData } from '../schema';
import { useAddChartOfAccount } from './add_chart_of_account';

import styles from '../styles.module.scss';

interface IChartOfAccountSelectorProps extends Omit<IUseModalProps, 'onDone'> {
  accountType?: TAccountType,
  paymentAccountId?: TID,
  qbo: IQBOData | undefined,
  onDone: (qbo: IQBOData) => void,
}

const ChartOfAccountSelector = ({
  accountType,
  isOpen,
  paymentAccountId,
  qbo,
  onCancel,
  onDone,
}: IChartOfAccountSelectorProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IQBOData | undefined>(qbo);
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

  const addChartOfAccount = useAddChartOfAccount();

  const business = useBusinessContext();

  const query = useGetQuickbooksPaymentAccounts({
    businessId: business.id,
    paymentAccountId,
  });

  const quickbooksAccounts = query.data?.accounts || [];

  const handleClick = useCallback((account: IQuickbooksPaymentAccounts) => {
    setSelectedAccount(account);
  }, []);

  const handleDone = useCallback(() => {
    if (!selectedAccount) {
      setShowWarningMessage(true);
      return;
    }

    setShowWarningMessage(false);
    onDone(selectedAccount);
  }, [selectedAccount, onDone]);

  const forceAccountType = useMemo(() => {
    if (accountType === window.Docyt.Common.Constants.ACCOUNT_TYPES.BANK_ACCOUNT_TYPE) {
      return window.configData.account_types.big_types.BANK.code;
    }

    return window.configData.account_types.big_types.CREDIT_CARD.code;
  }, [accountType]);

  return (
    <SideView.Standard
      isOpen={ isOpen }
      title="Select Account"
      onCancel={ onCancel }
      onDone={ handleDone }
    >
      { () => (
        <div className={ styles['banking-accounts-category-list'] }>
          <addChartOfAccount.Component
            forceAccountType={ forceAccountType }
            { ...addChartOfAccount.props }
          />
          { query.isLoading && <Spinner /> }
          { query.isError && <ErrorNotification message="Could not complete this request. Your QuickBooks is not connected." /> }
          { showWarningMessage && <ErrorNotification message="Please Select Chart of Account" title="Warning!" /> }
          <ul>
            <li className={ styles['chart-of-account-item'] } data-id="" data-name="">
              <a
                role="button"
                tabIndex={ -1 }
                onClick={ addChartOfAccount.open }
              >
                <PlusIcon fontSize={ 20 } variant="o" />
                <span>Link As New Account</span>
              </a>
            </li>
            {
              quickbooksAccounts.map((quickbooksAccount) => {
                const selectionClasses = classNames(
                  'selection-status',
                  { selected: selectedAccount?.id === quickbooksAccount.id },
                );

                return (
                  <li
                    key={ quickbooksAccount.id }
                    className={ styles['chart-of-account-item'] }
                    onClick={ () => handleClick(quickbooksAccount) }
                  >
                    <div className={ selectionClasses } />
                    <QBOOnIcon fontSize={ 20 } />
                    <span className="account-name">{ quickbooksAccount.name }</span>
                  </li>
                );
              })
            }
          </ul>
        </div>
      ) }
    </SideView.Standard>
  );
};

const MemoizedChartOfAccountSelector = React.memo(ChartOfAccountSelector);

const useSelectChartOfAccountSelector = makeUseModal(MemoizedChartOfAccountSelector);

export {
  useSelectChartOfAccountSelector,
  MemoizedChartOfAccountSelector as default,
};
