import React from 'react';

import ChatCentralNoChatIcon from '@src/components/utils/img_icons/chat_central_no_chat_icon';

import styles from '../../styles.module.scss';

const NoChatBody = () => {
  return (
    <div className={ styles['no-chat-body-container'] }>
      <div>
        <ChatCentralNoChatIcon />
        <p>Oops! No chats in here</p>
      </div>
    </div>
  );
};
export default NoChatBody;
