import React from 'react';

import UnavailableDashboardImg from '@assets/images/dashboards/no-data-large.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const DashboardsUnavailableIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Dashboards unavailable icon"
      src={ UnavailableDashboardImg }
      { ...props }
    />
  );
};

export default DashboardsUnavailableIcon;
