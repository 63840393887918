import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMoveDocumentsToTrash } from '@src/hooks/queries/documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { useConfirmRestoreDocumentModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { RotateLeftIcon } from '@src/components/utils/fa_icons';
import MutationStatus from '@src/components/utils/mutation_status';

interface IBusinessInboxItemDeleteActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemDeleteAction = ({
  document,
}: IBusinessInboxItemDeleteActionProps): JSX.Element => {
  const business = useBusinessContext();
  const moveToTrash = useMoveDocumentsToTrash();

  const { mutate } = moveToTrash;
  const handleConfirm = useCallback(() => {
    mutate({ documentIds: [document.id], value: false });
  }, [document.id, mutate]);

  const confirm = useConfirmRestoreDocumentModal({
    business,
    document,
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ moveToTrash } />
      <confirm.Component { ...confirm.props } />
      <ActionsDropdown.Action
        icon={ <RotateLeftIcon /> }
        name="Restore"
        onClick={ confirm.open }
      />
    </>
  );
};

export default BusinessInboxItemDeleteAction;
