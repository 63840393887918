module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<a class="btn dropdown-toggle font-15 font-bold in-grey-800" type="button" id="revenue-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  <span class="fa fa-ellipsis-h m-l-5"></span>\n</a>\n<ul class="revenue-action-dropdown dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="revenue-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n  ');
    
      if (this.state !== 'verifying') {
        __out.push('\n  <li><a tabindex="-1" class="view-detail-btn"><span class="icon-queue font-18 m-r-18"></span> View/Edit Detail</a></li>\n  ');
      }
    
      __out.push('\n  <li><a tabindex="-1" class="send-message-btn"><span class="icon-chat-round m-r-18"></span> Send Message</a></li>\n  ');
    
      if (this.state === 'verifying') {
        __out.push('\n  <li><a tabindex="-1" class="verify-btn"><span class="icon-thumbs-up font-20 m-r-18"></span> Verify</a></li>\n  ');
      }
    
      __out.push('\n  <li><a tabindex="-1" class="forward-btn"><span class="icon-forward-o font-20 m-r-18"></span> Forward</a></li>\n  <li role="separator" class="divider"></li>\n  <li><a tabindex="-1" class="destroy-btn"><span class="icon-destroy font-20 m-r-18"></span> Delete Report</a></li>\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}