import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import omit from 'lodash/omit';
import { useController, UseFormReturn, useWatch } from 'react-hook-form';

import { amountToPercent, percentToAmount } from '@src/utils/currency';

import AccountClassField from '@src/components/common_v2/form_fields/account_class_field';
import ChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';
import BusinessSelectField from '@src/components/common_v2/form_fields/receivable_account_payable_service_field';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import Table from '@src/components/ui_v2/table';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import { ICategorySplitsData } from './schema';

interface ICategorySplitFieldsProps {
  form: UseFormReturn<ICategorySplitsData>
  index: number,
  isReadonly?: boolean,
  isBusinessReadonly?: boolean,
  remove: (idx: number) => void,
}

const CategorySplitFields = ({
  form,
  index,
  isReadonly,
  isBusinessReadonly,
  remove,
}: ICategorySplitFieldsProps) => {
  const handleRemove = useCallback(() => {
    remove(index);
  }, [index, remove]);

  const { control, formState: { errors }, getValues, register, setValue } = form;

  const businessControl = useController({ control, name: `splits.${index}.businessId` });
  const accountClassControl = useController({ control, name: `splits.${index}.accountingClassId` });
  const chartOfAccountControl = useController({ control, name: `splits.${index}.chartOfAccountId` });
  const amountControl = useController({ control, name: `splits.${index}.amount` });
  const percentageControl = useController({ control, name: `splits.${index}.percentage` });

  // If accounting class is selected but chart of account was blank, then filter
  // chart of accounts by accounting class.
  // If chart of account is selected but accounting class was blank, then filter
  // accounting classes by chart of account.
  const [filterField, setFilterField] = useState<'accountingClass' | 'chartOfAccount' | undefined>(undefined);

  const businessId = useWatch({ control, name: `splits.${index}.businessId` }) || undefined;
  const accountingClassId = useWatch({ control, name: `splits.${index}.accountingClassId` }) || undefined;
  const chartOfAccountId = useWatch({ control, name: `splits.${index}.chartOfAccountId` }) || undefined;

  useEffect(() => {
    setFilterField((filter) => {
      if (!filter && accountingClassId) return 'accountingClass';
      if (filter === 'accountingClass' && !accountingClassId) return undefined;

      return filter;
    });
  }, [accountingClassId]);

  useEffect(() => {
    setFilterField((filter) => {
      if (!filter && chartOfAccountId) return 'chartOfAccount';
      if (filter === 'chartOfAccount' && !chartOfAccountId) return undefined;

      return filter;
    });
  }, [chartOfAccountId]);

  const chartOfAccountQueryParams = useMemo(() => {
    if (filterField !== 'accountingClass') return undefined;

    return { accountingClassId };
  }, [accountingClassId, filterField]);

  // Save selected account class and chart of account names. It's required to
  // show selected category name in the upper form.
  const { onChange: businessOnChange } = businessControl.field;
  const handleBusinessChange = useCallback((id, option) => {
    businessOnChange(id);
    setValue(`splits.${index}.businessName`, option?.label);
  }, [businessOnChange, setValue, index]);

  const { onChange: accountClassOnChange } = accountClassControl.field;
  const handleAccountClassChange = useCallback((id, option) => {
    accountClassOnChange(id);
    setValue(`splits.${index}.accountingClassName`, option?.label);
  }, [accountClassOnChange, setValue, index]);

  const { onChange: chartOfAccountOnChange } = chartOfAccountControl.field;
  const handleChartOfAccountChange = useCallback((id, option) => {
    chartOfAccountOnChange(id);
    setValue(`splits.${index}.chartOfAccountName`, option?.label);
  }, [chartOfAccountOnChange, setValue, index]);

  // When amount of percentage field lost focus and was changed, then recalculate
  // another field based on the changed. Because these fields depends on each other
  // changes and setting field value fires change event, it becomes tricky to synchronize
  // these changes and not make a change-loop.
  const isAmountInFocus = useRef<boolean>(false);
  const isPercentageInFocus = useRef<boolean>(false);
  const isAmountChanged = useRef<boolean>(false);
  const isPercentageChanged = useRef<boolean>(false);

  const { onChange: amountOnChange, onBlur: amountOnBlur } = amountControl.field;
  const handleAmountFocus = useCallback(() => {
    isAmountInFocus.current = true;
    isPercentageInFocus.current = false;
  }, []);

  const handleAmountChange = useCallback((value) => {
    amountOnChange(value);

    if (isAmountInFocus.current) isAmountChanged.current = true;
  }, [amountOnChange]);

  const handleAmountOnBlur = useCallback(() => {
    amountOnBlur();

    if (isAmountInFocus.current && isAmountChanged.current) {
      const adjAmount = getValues('adjustmentAmount');
      const amount = getValues(`splits.${index}.amount`);
      setValue(`splits.${index}.percentage`, amountToPercent(amount, adjAmount));
    }

    isAmountInFocus.current = false;
    isAmountChanged.current = false;
  }, [amountOnBlur, getValues, index, setValue]);

  const handleAmountClear = useCallback(() => {
    setValue(`splits.${index}.percentage`, null);
  }, [setValue, index]);

  const { onChange: percentageOnChange, onBlur: percentageOnBlur } = percentageControl.field;
  const handlePercentageFocus = useCallback(() => {
    isPercentageInFocus.current = true;
    isAmountInFocus.current = false;
  }, []);

  const handlePercentageChange = useCallback((value) => {
    percentageOnChange(value);

    if (isPercentageInFocus.current) isPercentageChanged.current = true;
  }, [percentageOnChange]);

  const handlePercentageOnBlur = useCallback(() => {
    percentageOnBlur();

    if (isPercentageInFocus.current && isPercentageChanged.current) {
      const adjAmount = getValues('adjustmentAmount');
      const percentage = getValues(`splits.${index}.percentage`);
      setValue(`splits.${index}.amount`, percentToAmount(percentage, adjAmount));
    }

    isPercentageInFocus.current = false;
    isPercentageChanged.current = false;
  }, [getValues, index, percentageOnBlur, setValue]);

  const handlePercentageClear = useCallback(() => {
    setValue(`splits.${index}.amount`, null);
  }, [setValue, index]);

  return (
    <Table.Row>
      <Table.InputCell>
        <BusinessSelectField
          businessId={ businessId! }
          // When there is no `businessId` the field is disabled and function will not be called.
          error={ errors.splits?.[index]?.businessId?.message }
          isDisabled={ isReadonly || isBusinessReadonly }
          label="Business"
          layout="table"
          placeholder="Select Business…"
          onChange={ handleBusinessChange }
          { ...omit(businessControl.field, ['ref', 'onChange']) }
        />
      </Table.InputCell>
      <Table.InputCell>
        <AccountClassField
          autoSelect
          showNotAvailable
          businessId={ businessId }
          chartOfAccountId={ filterField === 'chartOfAccount' ? chartOfAccountId : undefined }
          error={ errors.splits?.[index]?.accountingClassId?.message }
          isDisabled={ isReadonly }
          label="Department"
          layout="table"
          placeholder="Select Department…"
          onChange={ handleAccountClassChange }
          { ...omit(accountClassControl.field, ['ref', 'onChange']) }
        />
      </Table.InputCell>
      <Table.InputCell>
        <ChartOfAccountField
          businessId={ businessId }
          error={ errors.splits?.[index]?.chartOfAccountId?.message }
          isDisabled={ isReadonly }
          label="Chart of Account"
          layout="table"
          placeholder="Select Chart of Account…"
          queryParams={ chartOfAccountQueryParams }
          onChange={ handleChartOfAccountChange }
          { ...omit(chartOfAccountControl.field, ['ref', 'onChange']) }
        />
      </Table.InputCell>
      <Table.InputCell>
        <Form.AmountField
          disabled={ isReadonly }
          error={ errors.splits?.[index]?.amount?.message }
          label="Amount"
          layout="table"
          onBlur={ handleAmountOnBlur }
          onChange={ handleAmountChange }
          onClear={ handleAmountClear }
          onFocus={ handleAmountFocus }
          { ...omit(amountControl.field, ['onBlur', 'onChange']) }
        />
      </Table.InputCell>
      <Table.InputCell>
        <Form.PercentageField
          disabled={ isReadonly }
          error={ errors.splits?.[index]?.percentage?.message }
          label="Percentage"
          layout="table"
          onBlur={ handlePercentageOnBlur }
          onChange={ handlePercentageChange }
          onClear={ handlePercentageClear }
          onFocus={ handlePercentageFocus }
          { ...omit(percentageControl.field, ['onBlur', 'onChange']) }
        />
      </Table.InputCell>
      <Table.InputCell>
        <Form.TextField
          disabled={ isReadonly }
          error={ errors.splits?.[index]?.memo?.message }
          label="Memo"
          layout="table"
          { ...register(`splits.${index}.memo`) }
        />
      </Table.InputCell>
      <Table.IconCell>
        {
          !isReadonly && (
            <Button variant="link" onClick={ handleRemove }>
              <TrashcanIcon fontSize={ 20 } />
            </Button>
          )
        }
      </Table.IconCell>
    </Table.Row>
  );
};

export default React.memo(CategorySplitFields);
