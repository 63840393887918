import React from 'react';

import Text from '@src/components/ui/text';
import Table from '@src/components/ui_v2/table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface StepProps {
  tooltip: string,
  children: React.ReactNode,
}

const Step = ({ children, tooltip }: StepProps) => {
  return (
    <Table.Row>
      <Table.Cell className={ styles['step-row'] } colSpan={ 6 }>
        <Text as="h3">
          { children }
          <Tooltip.Hover as="span" content={ tooltip }>
            <DocytSupportIcon className={ styles['step-row-icon'] } />
          </Tooltip.Hover>
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(Step);
