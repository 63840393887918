import React from 'react';

import { useReconciliationAccountOptions } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';

import MultiSelect, { IMultiSelectProps } from '@src/components/ui_v2/inputs/multi_select/multi_select';

interface IMultiBankAccountsInputProps extends
  Omit<IMultiSelectProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  value: string[],
  onChange: (value: string[]) => void,
}

const MultiBankAccountsInput = ({
  businessId,
  value,
  onChange,
  ...props
}: IMultiBankAccountsInputProps): JSX.Element => {
  const bankingAccountOptions = useReconciliationAccountOptions(businessId);

  return (
    <MultiSelect
      { ...props }
      options={ bankingAccountOptions }
      value={ value }
      onChange={ onChange }
    />
  );
};
export default React.memo(MultiBankAccountsInput);
