import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { HistoryIcon } from '@src/components/utils/icomoon';

interface IntegrationHistoryActionProps {
  integrationId: TID,
}

const IntegrationHistoryAction = ({
  integrationId,
}: IntegrationHistoryActionProps) => {
  const business = useBusinessContext();
  return (
    <ActionsDropdown.LinkAction
      href={ `/settings/integrations/${business.id}/integrations/${integrationId}` }
      icon={ <HistoryIcon fontSize={ 18 } /> }
      title="History"
    />
  );
};

export default React.memo(IntegrationHistoryAction);
