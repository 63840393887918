import React, { useCallback, useMemo } from 'react';

import { useGetPaymentAccounts } from '@src/hooks/queries/payment_accounts';
import { TID } from '@src/types/common';

import {
  ISelectInputProps,
  SelectInput,
} from '@src/components/ui_v2/inputs';

interface IPaymentAccountInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  accountType?: string,
  value?: TID | null,
  onChange?: (value?: TID | null) => void,
}

const PaymentAccountInput = ({
  businessId,
  accountType,
  value,
  onChange,
  ...props
}: IPaymentAccountInputProps) => {
  const query = useGetPaymentAccounts({
    businessId,
    accountType,
    noConsiderArchive:     true,
    excludeSystemAccounts: true,
  }, Boolean(accountType));

  const handleChange = useCallback((newValue) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null);
  }, [onChange]);

  const options = useMemo(() => {
    return query.data?.paymentAccounts.map((paymentAccount) => ({
      value: String(paymentAccount.id),
      label: paymentAccount.name,
    })) || [];
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return options.find((paymentAccount) => paymentAccount.value === String(value)) || null;
  }, [value, options]);

  return (
    <SelectInput
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(PaymentAccountInput);
