import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { ISplitTransactionServiceDocumentFayeEvent } from '@src/types/docyt_events/split_transaction_service_document';

import { useFayeChannel } from './common';

export const useSplitTransactionServiceDocumentFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<ISplitTransactionServiceDocumentFayeEvent>,
) => {
  useFayeChannel(
    `/split_transaction_service_document-${businessId}`,
    callback,
  );
};
