import React, { useCallback } from 'react';

import { useSyncBusinessVendorWithQbo } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import {
  ArchiveVendorIcon,
  UnarchiveVendorIcon,
  EditAccountIcon,
  EditIcon,
  QBOOnIcon,
  TrashcanIcon,
} from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useConfirmDeleteModal, useConfirmArchiveModal } from '../profile/modal';

import styles from '../styles.module.scss';

interface IProfileActionProps {
  editLinkLedger: boolean,
  editProfile: boolean,
  vendor: IBusinessVendor,
  setEditProfile: React.Dispatch<React.SetStateAction<boolean>>,
  setEditLinkLedger: React.Dispatch<React.SetStateAction<boolean>>,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>,
}

const ProfileAction = ({
  editLinkLedger,
  editProfile,
  vendor,
  setEditProfile,
  setVendorData,
  setEditLinkLedger,
}: IProfileActionProps) => {
  const isArchive = !!vendor.archivedAt;
  const deleteModal = useConfirmDeleteModal();
  const archiveModal = useConfirmArchiveModal();
  const syncBusinessVendorWithQbo = useSyncBusinessVendorWithQbo();
  const { mutate: reSyncMutate } = syncBusinessVendorWithQbo;

  const handleReSync = useCallback(() => {
    reSyncMutate({
      vendorId:   vendor.id,
      businessId: vendor.businessId,
    });
  }, [vendor, reSyncMutate]);

  return (
    <>
      <ActionsDropdown className={ styles['action-dropdown-bg'] } classNameMenus={ styles.submenus }>
        { !vendor.isGlobal && (
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <EditIcon fontSize={ 18 } /> }
            title="Edit Vendor Profile"
            onSelect={ () => {
              if (editLinkLedger) setEditProfile(false);
            } }
          />
        )}
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <EditAccountIcon fontSize={ 18 } /> }
          title="Edit Link to Ledger"
          onSelect={ () => {
            if (editProfile) setEditLinkLedger(false);
          } }
        />

        {
          (
            !vendor.qboId
            || (
              vendor.qboError
              && vendor.qboError !== ''
              && vendor.syncStatus !== window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED
            )
          ) && vendor.syncStatus !== window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED
          && (
            <ActionsDropdown.Action
              className={ styles['dropdown-item'] }
              icon={ <QBOOnIcon fontSize={ 18 } /> }
              title="Re-sync"
              onSelect={ handleReSync }
            />
          )
        }

        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ isArchive
            ? <UnarchiveVendorIcon fontSize={ 18 } />
            : <ArchiveVendorIcon fontSize={ 18 } /> }
          title={ isArchive ? 'Unarchive Vendor' : 'Archive Vendor' }
          onClick={ archiveModal.open }
        />
        <ActionsDropdown.Action
          danger
          className={ styles['dropdown-item'] }
          icon={ <TrashcanIcon fontSize={ 18 } /> }
          title="Remove Vendor"
          onClick={ deleteModal.open }
        />
      </ActionsDropdown>
      <MutationStatus
        mutation={ syncBusinessVendorWithQbo }
        successMessage="Resyncing item with QuickBooks. This will take a few minutes."
        successTitle="INFO"
      />

      <archiveModal.Component
        setVendorData={ setVendorData }
        text={ (
          <div className="text-left">
            <div className={ styles['modal-content'] }>
              <p>{`Are you sure you want to ${isArchive ? 'unarchive' : 'archive'} ${vendor.name}?`}</p>
              {!isArchive && (
              <p>
                It will stop showing on your vendor list but all the existing invoices,
                transactions, and receipts will still exist.
              </p>
              )}
            </div>
          </div>
  ) }
        title={ (
          <div className={ styles['modal-title'] }>
            {isArchive ? <span>Unarchive Vendor</span> : <span>Archive Vendor</span>}
          </div>
  ) }
        vendor={ vendor }
        { ...archiveModal.props }
      />

      <deleteModal.Component
        text={ (
          <div className="text-left">
            <div className={ styles['modal-content'] }>
              <p>Are you sure you want to remove this vendor/payee?</p>
            </div>
          </div>
        ) }
        title={ (
          <p className={ styles['modal-title'] }>
            Remove Vendor
          </p>
        ) }
        vendor={ vendor }
        { ...deleteModal.props }
      />
    </>
  );
};

export default React.memo(ProfileAction);
