module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, invoice, len, ref, ref1;
    
      __out.push('<div class=\'modal fade\'>\n    <div class=\'modal-dialog edit-invoice-modal select-multi-item-area\'>\n        <div class=\'modal-content\'>\n            <div class="modal-header">\n                <h1 class="modal-title text-center font-semi-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n            </div>\n            <div class=\'modal-body font-14\'>\n                <p>');
    
      __out.push(__sanitize(this.description));
    
      __out.push('</p>\n                <p>');
    
      __out.push(__sanitize(this.question));
    
      __out.push('</p>\n                <table class="table font-bold">\n                  <thead>\n                    <tr>\n                      <th class="font-bold width-20-percent">Vendor Name</th>\n                      <th class="font-bold width-40-percent">Category</th>\n                      <th class="font-bold width-20-percent">Date</th>\n                      <th class="font-bold width-20-percent">Amount</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ');
    
      ref = this.invoices;
      for (i = 0, len = ref.length; i < len; i++) {
        invoice = ref[i];
        __out.push('\n                      <tr>\n                        <td>');
        __out.push(__sanitize((ref1 = invoice.get('vendor')) != null ? ref1.name : void 0));
        __out.push('</td>\n                        <td>\n                          ');
        if (invoice.get('chart_of_account')) {
          __out.push('\n                            ');
          __out.push(__sanitize(invoice.get('chart_of_account').display_name));
          __out.push('\n                          ');
        } else if (invoice.get('service_document_splits_count') === 1) {
          __out.push('\n                            - ');
          __out.push(__sanitize(invoice.get('service_document_splits_count')));
          __out.push(' split -\n                          ');
        } else if (invoice.get('service_document_splits_count') > 1) {
          __out.push('\n                            - ');
          __out.push(__sanitize(invoice.get('service_document_splits_count')));
          __out.push(' splits -\n                          ');
        }
        __out.push('\n                        </td>\n                        <td>');
        __out.push(__sanitize(invoice.get('invoice_date') ? moment(invoice.get('invoice_date')).format(Docyt.Common.Constants['US_DATE_FORMAT']) : ''));
        __out.push('</td>\n                        <td>');
        __out.push(__sanitize(this.currencyFormater(invoice.get('invoice_amount'))));
        __out.push('</td>\n                      </tr>\n                    ');
      }
    
      __out.push('\n                  </tbody>\n                </table>\n            </div>\n            <div class=\'modal-footer\'>\n              <a class=\'cancel-link pointer cancel-js\'>Cancel</a>\n              <button type=\'button\' class="btn btn-danger ladda-button pull-right confirm-js" tabindex="10" data-color="');
    
      __out.push(__sanitize(this.button_color));
    
      __out.push('" data-style="expand-right" data-integration-test="modal-confirm">\n                <span class="ladda-label font-semi-bold">');
    
      __out.push(__sanitize(this.button_text));
    
      __out.push('</span>\n              </button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}