import React from 'react';

import Section from '@src/components/utils_v2/section';

import { useFinancialInsightsEmailSettingsListCollection } from './hooks';
import Table from './table';

import styles from '../styles.module.scss';

const FinancialInsightsEmailSettingsList = () => {
  const collection = useFinancialInsightsEmailSettingsListCollection();

  return (
    <div className={ styles['settings-list-container'] }>
      <Section.Provider section={ collection.section }>
        <Table
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </Section.Provider>
    </div>
  );
};

export default React.memo(FinancialInsightsEmailSettingsList);
