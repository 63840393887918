module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="transaction-list-region" class="transaction-list-region">\n  <div>\n    <div class="clearfix">\n      <h2 class="font-bold font-26 pull-left">Loan Payments</h2>\n    </div>\n    <div class="filter-link pull-right inline-block m-r-20">\n      <a class="pointer edit-transactions">Uncategorize</a>\n    </div>\n    <hr/>\n    <div class="display-flex align-items-center justify-content-end">\n      <div class="filter-link inline-block m-r-20 p-b-10">\n        <a class="pointer reset-transactions-filter disabled">Reset Filters<span class="filtered-count-panel hidden"></span></a>\n      </div>\n      <div class="counter-container"></div>\n    </div>\n    <div class="table-responsive transactions-table-wrapper">\n      <div class="panel panel-default">\n        <div class="transaction-filter-container"></div>\n        <div class="loan-payments-items-table"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div id="transaction-right-side-region" class="transaction-right-side-region hidden"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}