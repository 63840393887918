import { IBusiness } from '@src/types/businesses';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountingFirmsResponse {
  businesses: IBusiness[],
}

const getAccountingFirms = (): Promise<IGetAccountingFirmsResponse> => {
  return apiGet(
    '/api/v1/client_management/get_accounting_firms',
  ).then((data) => camelizeKeys(data) as IGetAccountingFirmsResponse);
};

export {
  IGetAccountingFirmsResponse,
  getAccountingFirms,
};
