module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<p class="m-l-10 m-b-0 font-semi-bold">Flagged Items</p>\n<div class="display-flex align-items-center justify-content-between">\n<a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/accounts_payable/queue?type=starred" class="flagged-document">\n  <span class="icon-accounts-payable font-36">\n    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span>\n  </span>\n  ');
    
      __out.push(__sanitize(this.count_invoices));
    
      __out.push(' Invs\n</a>\n<a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/receipt_box/queue?type=starred" class="flagged-document">\n  <span class="icon-receipt-box font-36">\n    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n  </span>\n  ');
    
      __out.push(__sanitize(this.count_receipts));
    
      __out.push(' Rcpts\n</a>\n<a href="');
    
      __out.push(__sanitize(this.transactionURL));
    
      __out.push('" class="flagged-document">\n  <span class="icon-accounts-receivable font-36">\n    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span>\n  </span>\n  <div>\n  <div>');
    
      __out.push(__sanitize(this.count_opened_transactions));
    
      __out.push(' Open Txns</div>\n  <div>');
    
      __out.push(__sanitize(this.count_resolved_transactions));
    
      __out.push(' Reviewed Txns</div>\n  </div>\n</a>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}