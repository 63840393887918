module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref;
    
      __out.push('<header class=\'header header-docyt-id\'>\n  <div class="docyt-id-header">\n    <div class="header-title-and-menu">\n      <div class="pull-left">\n        <div class="header-icon">\n          ');
    
      if (this.service_document) {
        __out.push('\n            <i class="svg-icon-accounts-payable font-30"></i>\n          ');
      } else if (this.receipt_service_document) {
        __out.push('\n            <i class="svg-icon-receipt-document font-30"></i>\n          ');
      } else if (this.transaction_service_document) {
        __out.push('\n            <i class="svg-icon-revenue font-30"></i>\n          ');
      } else if (this.revenue_service_document) {
        __out.push('\n            <i class="svg-icon-revenue font-30"></i>\n          ');
      } else if (this.inbox_service_document) {
        __out.push('\n            <img src="');
        __out.push(__sanitize(configData.business_inbox_images['INBOX_SERVICE']));
        __out.push('" style="width: 30px" />\n          ');
      } else {
        __out.push('\n            <span class="icon-Docyt-Bot-Icon1x font-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class="header-text">\n          <span class="header-breadcrumb font-16">\n            ');
    
      if (this.documentBusinessName) {
        __out.push('\n              ');
        __out.push(__sanitize(this.documentBusinessName));
        __out.push(' /\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.individualPageTitle) {
        __out.push('\n              ');
        __out.push(__sanitize(this.individualPageTitle));
        __out.push(' /\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.individualPageSubTitle) {
        __out.push('\n              ');
        __out.push(__sanitize(this.individualPageSubTitle));
        __out.push(' /\n            ');
      }
    
      __out.push('\n          </span>\n          <span class="invoice-status-panel badge font-14 docyt-id-number">');
    
      __out.push(__sanitize(this.documentDocytId));
    
      __out.push('</span>\n          <div class="copy-tooltip-container">\n            <a class="copy-url-button pointer inline-block vertical-align-middle" data-clipboard-text="');
    
      __out.push(__sanitize(this.documentURL));
    
      __out.push('">\n              <span class="icon icon-copy-icon font-24"></span>\n            </a>\n          </div>\n        </div>\n      </div>\n      <div id="header-menu-container" class="header-action">\n        <a class="pointer pull-right m-t-5 m-l-10 close-individual-page-btn"><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n        ');
    
      if (this.service_document || this.receipt_service_document || this.transaction_service_document || this.revenue_service_document || this.expense_reports_service_document || this.inbox_service_document) {
        __out.push('\n          <div class="m-l-10 pull-right service-document-action-region relative"></div>\n        ');
      } else {
        __out.push('\n          ');
        if (this.documentRequestName) {
          __out.push('\n          <a class="pull-left document-request-tooltip p-t-10 m-r-50 pointer" data-toggle="tooltip" data-placement="bottom" title="Linked to Document Request: ');
          __out.push(__sanitize(this.documentRequestName));
          __out.push('">\n            <span class="font-24 icon icon-unread"></span>\n          </a>\n          ');
        }
        __out.push('\n          <div class="dropdown pull-right">\n            <button class="btn btn-secondary dropdown-toggle header-icon-button m-l-10" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              <span class="icon icon-more-icon font-22"></span>\n            </button>\n            <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">\n              ');
        if (this.document && !this.document.get('source') === configData.document_sources["UPS"]) {
          __out.push('\n              <a class="dropdown-item font-16 pointer export-document-pdf-js">\n                <i class="icon icon-download m-r-10 m-t-3"></i>\n                <span>Export as PDF</span>\n              </a>\n              ');
        }
        __out.push('\n              <a class="dropdown-item font-16 pointer report-document-js">\n                <i class="fa fa-wrench m-r-10 m-t-3"></i>\n                <span>Report an Issue</span>\n              </a>\n              <a class="dropdown-item font-16 pointer delete-document-js">\n                <i class="icon icon-trashcan m-r-10 m-t-3"></i>\n                <span>Delete</span>\n              </a>\n            </div>\n          </div>\n        ');
      }
    
      __out.push('\n        ');
    
      if (!this.inbox_service_document) {
        __out.push('\n          <div id="qbo-document-region-new" class="pull-right"></div>\n        ');
      }
    
      __out.push('\n        ');
    
      if (this.service_document || this.receipt_service_document || this.transaction_service_document || this.revenue_service_document) {
        __out.push('\n          <div class="header-separator pull-right"></div>\n        ');
      }
    
      __out.push('\n        ');
    
      if (!this.inbox_service_document) {
        __out.push('\n          <div class="document-status-container pull-right">\n            ');
        if ((ref = this.document) != null ? ref.get('deleted_at') : void 0) {
          __out.push('\n              <p class="inline-block vertical-align-middle">\n                <span class="invoice-status-panel badge font-14 in_trash">In Trash</span>\n              </p>\n            ');
        } else if (this.service_document) {
          __out.push('\n              <p class="inline-block vertical-align-middle">\n                <a class="invoice-status-tooltip" data-toggle="tooltip" data-placement="bottom" title="');
          __out.push(__sanitize(this.service_document.get('payment_transaction_error')));
          __out.push('">\n                  <span class="invoice-status-panel badge font-14 ');
          __out.push(__sanitize(this.service_document.get('label_state')));
          __out.push('">');
          __out.push(__sanitize(this.service_document.get('check_status')));
          __out.push('</span>\n                </a>\n              </p>\n            ');
        } else if (this.receipt_service_document) {
          __out.push('\n              <p class="inline-block vertical-align-middle">\n                <span class="invoice-status-panel badge font-14 ');
          __out.push(__sanitize(this.receipt_service_document.get('label_state')));
          __out.push('">');
          __out.push(__sanitize(this.receipt_service_document.get('check_status')));
          __out.push('\n              </p>\n            ');
        } else if (this.transaction_service_document) {
          __out.push('\n              <p class="inline-block vertical-align-middle">\n                <span class="invoice-status-panel badge font-14 ');
          __out.push(__sanitize(this.transaction_service_document.get('label_tate')));
          __out.push('">');
          __out.push(__sanitize(this.transaction_service_document.get('label_state')));
          __out.push('\n              </p>\n            ');
        } else if (this.revenue_service_document) {
          __out.push('\n              <p class="inline-block vertical-align-middle">\n                <span class="invoice-status-panel badge font-14 ');
          __out.push(__sanitize(this.revenue_service_document.get('state')));
          __out.push('">');
          __out.push(__sanitize(this.revenue_service_document.get('state')));
          __out.push('\n              </p>\n            ');
        }
        __out.push('\n          </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}