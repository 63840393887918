import { object, string } from 'yup';

interface IAddReportTypeValues {
  name: string
}

const addReportTypeValidation = object({
  name: string().trim().label('Revenue Mapping Name').required(),
});

export {
  addReportTypeValidation,
  IAddReportTypeValues,
};
