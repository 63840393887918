import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ConfigureIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="configure"
      pathsNumber={ 1 }
    />
  );
};

export default ConfigureIcon;
