import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getDashboards,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  IGetDashboardsParams,
  IGetDashboardsResponse,
  ICreateDashboardParams,
  ICreateDashboardResponse,
  IUpdateDashboardParams,
  IUpdateDashboardResponse,
  IDeleteDashboardParams,
} from '@src/requests/dashboards/dashboards';

const useGetDashboards = (params: IGetDashboardsParams) => {
  return useQuery<IGetDashboardsResponse, Error>(
    [QueryKey.dashboards, params],
    () => getDashboards(params),
  );
};

const useCreateDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDashboardResponse, Error, ICreateDashboardParams>(
    createDashboard,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.dashboards);
      },
    },
  );
};

const useUpdateDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<IUpdateDashboardResponse, Error, IUpdateDashboardParams>(
    updateDashboard,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.dashboards);
        queryClient.invalidateQueries(QueryKey.dashboardWidgets);
      },
    },
  );
};

const useDestroyDashboard = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteDashboardParams>(
    deleteDashboard,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.dashboards);
      },
    },
  );
};

export {
  useGetDashboards,
  useCreateDashboard,
  useUpdateDashboard,
  useDestroyDashboard,
};
