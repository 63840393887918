import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import Table from '@src/components/ui/table';

import { IBankAccountData } from '../../schema';
import Item from './item';

import styles from '../../styles.module.scss';

interface IFinancialInstitutionConnectionTableProps {
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  onSelected: (bankAccount: IBankAccountData) => void,
}

const FinancialInstitutionConnectionTable = ({
  financialInstitutionConnections,
  onSelected,
}: IFinancialInstitutionConnectionTableProps) => {
  return (
    <Table
      className={ styles['finacial-institution-connection-center-table'] }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Body>
        {
          financialInstitutionConnections.map((financialInstitutionConnection) => (
            <Item
              key={ financialInstitutionConnection.id }
              financialInstitutionConnection={ financialInstitutionConnection }
              onSelected={ onSelected }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(FinancialInstitutionConnectionTable);
