import React, { useCallback } from 'react';

import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import Dropdown from '@src/components/ui/dropdown';

import styles from '../../styles.module.scss';

interface ITransactionsFilterPaymentAccountSelectOptionProps {
  account: IReconciliationPaymentAccount,
  onPaymentAccountSelect: (account: IPaymentAccount) => void,
  onReconciliationPaymentAccountSelect: (account: IReconciliationPaymentAccount) => void,
}

const accountHasChildren = (account: IReconciliationPaymentAccount): boolean => {
  if (account.paymentAccounts.length === 0) return false;
  if (account.paymentAccounts.length > 1) return true;

  return account.defaultPaymentAccountId !== account.paymentAccounts[0].id;
};

const accountTitle = (account: IReconciliationPaymentAccount | IPaymentAccount): string => {
  return `${account.name}${account.isArchived ? ' (Archived)' : ''}`;
};

const TransactionsFilterPaymentAccountSelectOption = ({
  account,
  onPaymentAccountSelect,
  onReconciliationPaymentAccountSelect,
}: ITransactionsFilterPaymentAccountSelectOptionProps): JSX.Element => {
  const handleOptionSelect = useCallback(() => {
    onReconciliationPaymentAccountSelect(account);
  }, [onReconciliationPaymentAccountSelect, account]);

  if (!accountHasChildren(account)) {
    return (
      <Dropdown.Item className="nowrap" onSelect={ handleOptionSelect }>
        { accountTitle(account) }
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown.Submenu
      title={ accountTitle(account) }
      onSelect={ handleOptionSelect }
    >
      <Dropdown.Menu className={ styles['employee-dropdown-menu'] }>
        {
          account.paymentAccounts.map((paymentAccount) => (
            <Dropdown.Item
              key={ paymentAccount.id }
              className="nowrap"
              onSelect={ () => onPaymentAccountSelect(paymentAccount) }
            >
              { accountTitle(paymentAccount) }
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown.Submenu>
  );
};

export default TransactionsFilterPaymentAccountSelectOption;
