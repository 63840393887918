import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';
import { IBusiness } from '@src/types/businesses';

import { RestoreDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmRestoreDocumentsModalParams extends IUseModalParams {
  business?: IBusiness,
  documentIds: number[] | 'all',
  itemName?: string,
  serviceName?: string,
}

interface IUseConfirmRestoreDocumentsModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
  itemName?: string,
  serviceName?: string,
}

const ConfirmRestoreDocumentsTitle = (
  { business, documentIds, itemName = 'documents', serviceName }:
    { business?: IBusiness, itemName?: string,
      documentIds: number[] | 'all', serviceName?: string },
) => (
  <>
    { documentIds === 'all' ? 'All' : documentIds.length }
    { ' ' }
    { itemName.toLowerCase() }
    { ' ' }
    will be restored to
    { ' ' }
    { serviceName || business?.name || 'Personal' }
    { ' ' }
    { serviceName ? '' : 'Inbox' }
    .
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmRestoreDocumentsModal = ({
  business,
  documentIds,
  itemName = 'Document',
  serviceName,
  ...modalParams
}: IUseConfirmRestoreDocumentsModalParams): IUseConfirmRestoreDocumentsModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  let realItemName = itemName;
  if (documentIds.length > 1 && ['Document', 'Expense'].includes(itemName)) {
    realItemName = `${itemName}s`;
  }
  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'primary',
      confirmTitle: 'Restore',
      icon:         <RestoreDocumentIcon />,
      text:         (
        <ConfirmRestoreDocumentsTitle
          business={ business }
          documentIds={ documentIds }
          itemName={ realItemName }
          serviceName={ serviceName }
        />
      ),
      title: `Restore ${documentIds === 'all' ? '' : `${documentIds.length} `}${realItemName}?`,
    },
  };
};

export {
  useConfirmRestoreDocumentsModal,
};
