import React from 'react';

import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';

import styles from '../../styles.module.scss';

interface IAddTransactionsResultProps {
  status: IBulkActionStatus<any, any>,
}

const AddTransactionsResult = ({
  status,
}: IAddTransactionsResultProps) => {
  return (
    <div className={ styles.result }>
      <p className="p-b-0">
        <span className={ styles['success-count'] }>{ status.successCount }</span>
        {' '}
        { status.successCount === 1 ? 'transaction was' : 'transactions were' }
        {' '}
        created successfully
      </p>
      {
        status.failedCount > 0 && (
          <p className="p-b-0">
            <span className={ styles['failed-count'] }>{ status.failedCount }</span>
            {' '}
            { status.failedCount === 1 ? 'transaction has' : 'transactions have' }
            {' '}
            failed with the following errors:
            <ol>
              { status.errors.map((error, i) => (
                // There is no anything that may be used as key
                // eslint-disable-next-line react/no-array-index-key
                <li key={ i }>{ error[1] }</li>
              )) }
            </ol>
          </p>
        )
      }
    </div>
  );
};

export default React.memo(AddTransactionsResult);
