import React from 'react';

import castArray from 'lodash/castArray';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Text from '@src/components/ui/text';

import Breadcrumb from './breadcrumb';
import { IBreadcrumb } from './types';

import styles from './styles.module.scss';

interface IDetailsPageHeaderProps extends IUIStyleProps {
  breadcrumbs?: IBreadcrumb | IBreadcrumb[];
  children?: React.ReactNode;
  divider?: 'horizontal' | 'vertical' | 'both';
  title: React.ReactNode;
}

const DetailsPageTwoLineHeader = ({
  breadcrumbs,
  children,
  title,
  divider = 'vertical',
  ...props
}: IDetailsPageHeaderProps): JSX.Element => {
  const [classes] = uiStyleProps(
    styles['details-region-two-line-header'],
    props,
    styles[`details-region-divider-${divider}`]
  );
  const crumbs = breadcrumbs && castArray(breadcrumbs);

  return (
    <div className={classes}>
      <div className={styles['first-line']}>
        <Text as="h2">
          {crumbs &&
            crumbs.map((crumb, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Breadcrumb key={i} breadcrumb={crumb} />
            ))}
        </Text>
      </div>
      <div className={styles['second-line']}>
        <Text as="h1">{title}</Text>
        {children && <div className={styles.actions}>{children}</div>}
      </div>
    </div>
  );
};

export default React.memo(DetailsPageTwoLineHeader);
