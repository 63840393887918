import moment from 'moment';

export const newExportFormatDate = (lastDownloadedAt: string) => {
  const hoursAgo = moment().diff(moment(lastDownloadedAt), 'hours');

  if (hoursAgo < 1) {
    const minutesAgo = moment().diff(moment(lastDownloadedAt), 'minutes');

    return `${minutesAgo} minutes ago`;
  }

  if (hoursAgo >= 1 && hoursAgo < 24) {
    return `${hoursAgo} hours ago`;
  }

  return moment(lastDownloadedAt).format('MM/DD/YYYY');
};
