import React, { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { IReport, ITemplate } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';

import Table from '@src/components/ui_v2/table';
import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import { BusinessReportsExportSelectTableItemHCell, ReportsExportTableSelectItemCell } from '../common';

interface IBusinessCustomReportsProps {
  reports?:   IReport[],
  templates:  ITemplate[],
  category:   string,
  type:       string,
  slug:       string,
  query:      UseQueryResult<IReport[], Error>
}

const BusinessCustomReports = ({
  reports,
  templates,
  category,
  type,
  slug,
  query
}: IBusinessCustomReportsProps) => {
  const selectedReports = useMemo(() => {
    return reports?.filter((report: IReport) => {
      if (type === 'report') {
        return report.slug === slug;
      }

      return templates.find((template: ITemplate) => (
        template.id === report.templateId && template.category === category
      ));
    });
  }, [reports, type, templates, slug, category]);


  const maxColumnValues = useMemo(() => {
    let maxValue = 0;
    let index = 0;

    if (selectedReports && selectedReports.length !== 0) {
      selectedReports.forEach((report: IReport, key: number) => {
        if (report.ptdColumns && report.ptdColumns.length > maxValue) {
          maxValue = report.ptdColumns.length;
          index = key;
        }
  
        return report;
      });
  
      if (selectedReports[index] && selectedReports[index].ptdColumns) {
          return [...selectedReports[index].ptdColumns, ...selectedReports[index].ytdColumns];
      }
    }

    return [];
  }, [selectedReports]);

  const renderCheckBoxCell = (report: IReport, col: IReportColumn) => {
    const columns = [...report.ptdColumns, ...report.ytdColumns];
    const findCol = columns.find((c: IReportColumn) => c.name === col.name);

    return (
      <ReportsExportTableSelectItemCell key={ col.id } disabled={ !findCol } item={ report } />
    )
  }

  return (
    <ItemsSelectorProvider allItems={ selectedReports as IReport[] }>
      <div className="custom-reports-table-wrapper">
        <Table query={ query }>
          <Table.Head>
            <Table.Row>
              <Table.HCell>
                Reports
              </Table.HCell>

              {maxColumnValues.map((col: IReportColumn) => (
                <BusinessReportsExportSelectTableItemHCell key={ col.id } title={ col.name } />
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {selectedReports?.map((report: IReport) => (
              <Table.Row key={ report.id }>
                <Table.Cell>{ report.name }</Table.Cell>

                { maxColumnValues.map((col: IReportColumn) => (
                  <React.Fragment key={ col.id }>{ renderCheckBoxCell(report, col) }</React.Fragment>
                )) }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </ItemsSelectorProvider>
  );
}

export default BusinessCustomReports;