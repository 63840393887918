module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, len;
    
      __out.push('<div class="person-content m-t-50">\n  <div class="modal-header">\n    <h4 class="modal-title font-28 font-bold m-t-40 m-b-40">Add Personal Info</h4>\n  </div>\n  <div class="person-body">\n    <div class="text-left update-profile-container">\n      <div class="profile-content-wrapper">\n        <div class="profile-input-wrap m-b-8">\n          <span> This information will be used for verification to activate ACH payments to your bank account.</span>\n        </div>\n        <div class="profile-input-wrap font-bold">\n          <span> Full Name </span>\n        </div>\n        <div class="profile-input-wrap m-b-8">\n          <div class="address-wrapper">\n            <div class="profile-input-wrap p-r-5">\n              <input class="input-first-name form-control" type="text" placeholder="First name" value="');
    
      __out.push(__sanitize(this.first_name));
    
      __out.push('">\n              <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <input class="input-last-name form-control" type="text" placeholder="Last name" value="');
    
      __out.push(__sanitize(this.last_name));
    
      __out.push('">\n            </div>\n          </div>\n        </div>\n        <div class="profile-input-wrap font-bold">\n          <span> Email </span>\n        </div>\n        <div class="profile-input-wrap m-b-8">\n          <input class="input-email  form-control" type="text" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('" placeholder="email@website.com">\n          <p class="valid-hint-js m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n        </div>\n        <div class="profile-input-wrap font-bold">\n          <span> Phone Number </span>\n        </div>\n        <div class="profile-input-wrap m-b-8">\n          <input type="tel" name="phone" class="input-phone  form-control" value="');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('" placeholder="Phone Number">\n          <p class="phone-valid-hint-js m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n        </div>\n        <div class="profile-input-wrap font-bold">\n          <span> Address </span>\n        </div>\n        <div class="profile-input-wrap">\n          <input class="input-address-street  form-control" placeholder="Address Line 1 (Street number, street name)" type="text" value="');
    
      __out.push(__sanitize(this.address1));
    
      __out.push('">\n          <p class="valid-hint-js m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n        </div>\n        <div class="profile-input-wrap">\n          <input class="input-address-street2  form-control" type="text" placeholder="Address Line 2 (Apartment, floor, suite, building number)" value="');
    
      __out.push(__sanitize(this.address2));
    
      __out.push('">\n        </div>\n        <div class="profile-input-wrap">\n          <div class="address-wrapper">\n            <div class="profile-child-input-wrap p-r-5">\n              <input class="input-address-city  form-control" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.city));
    
      __out.push('">\n              <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-child-input-wrap p-r-5">\n              <select class="input-address-state form-control">\n                ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n                ');
        if (address_state.abbreviation === this.state) {
          __out.push('\n                <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" selected>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                ');
        } else {
          __out.push('\n                <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('">');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                ');
        }
        __out.push('\n                ');
      }
    
      __out.push('\n              </select>\n            </div>\n            <div class="profile-child-input-wrap">\n              <input class="input-address-zip  form-control" type="text" placeholder="Zip" value="');
    
      __out.push(__sanitize(this.postal_code));
    
      __out.push('">\n              <p class="valid-hint-js m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="person-footer">\n    <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n    <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save & Next</span></button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}