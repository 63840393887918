import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Details from './details';

interface IRelatedTransactionsProps {
  transactionId: TID,
}

const RelatedTransactionSection = ({
  transactionId,
}: IRelatedTransactionsProps): JSX.Element => {
  return (
    <Root>
      <Details transactionId={ transactionId } />
    </Root>
  );
};

export default RelatedTransactionSection;
