import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ImportBudgetIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="data-import-budget"
    />
  );
};

export default ImportBudgetIcon;
