module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, ref, ref1, year;
    
      __out.push('<div class="display-flex align-items-center justify-content-between">\n  <div class="bookkeeping-year-select">\n    <div class="font-semi-bold">Bookkeeping Status</div>\n    <select class="selectpicker bookkeeping-year m-t-5" name="year" title="Select year">\n      ');
    
      for (year = i = ref = this.curYear, ref1 = this.startYear - 1; i > ref1; year = i += -1) {
        __out.push('\n      <option value="');
        __out.push(__sanitize(year));
        __out.push('" ');
        __out.push(__sanitize(year === this.year ? 'selected' : ''));
        __out.push('>');
        __out.push(__sanitize(year));
        __out.push('</option>\n      ');
      }
    
      __out.push('\n    </select>\n  </div>\n  <div id="bookkeeping-status-list">\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}