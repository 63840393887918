module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<button class="btn dropdown-toggle font-15 font-bold ');
    
      __out.push(__sanitize(this.button_text ? 'expense-reports-amount-button' : 'header-icon-button'));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.button_class));
    
      __out.push('" type="button" id="expense-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  ');
    
      if (this.button_text) {
        __out.push('\n    ');
        __out.push(__sanitize(this.button_text));
        __out.push('\n    <span class="fa fa-angle-down m-l-5"></span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-more-icon font-22"></span>\n  ');
      }
    
      __out.push('\n</button>\n<div class="expense-queue-dropdown dropdown-menu m-l-auto m-r-0" aria-labelledby="expense-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n  <a class="dropdown-item font-14 edit-btn" ');
    
      __out.push(__sanitize((this.state === configData.expense_report_states['PROCESSING'] || this.state === configData.expense_report_states['REJECTED']) && this.viewType === 'item' ? '' : 'hidden'));
    
      __out.push('>\n    <i class="fa fa-pencil-square-o"></i> <span>Edit Expense</span>\n  </a>\n  <button class="dropdown-item font-14 submit-btn ');
    
      __out.push(__sanitize(this.document_state === 'converting' ? 'in-grey-400' : ''));
    
      __out.push('" type="button" ');
    
      __out.push(__sanitize(this.state === configData.expense_report_states['PROCESSING'] ? '' : 'hidden'));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.document_state === 'converting' ? 'disabled' : ''));
    
      __out.push('>\n    <i class="icon icon-submit"></i> <span>Submit Expense</span>\n  </button>\n  <button class="dropdown-item font-14 submit-btn" type="button" ');
    
      __out.push(__sanitize(this.state === configData.expense_report_states['REJECTED'] ? '' : 'hidden'));
    
      __out.push('>\n    <i class="icon icon-submit"></i> <span>Resubmit Expense</span>\n  </button>\n  <a class="dropdown-item font-14 new-section" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat" ');
    
      __out.push(__sanitize(this.viewType === 'item' ? '' : 'hidden'));
    
      __out.push('>\n    <i class="fa fa-comment-o"></i> <span>Send Message</span>\n  </a>\n  <button class="dropdown-item font-14 destroy-btn in-red-600" type="button" ');
    
      __out.push(__sanitize((this.state === configData.expense_report_states['PROCESSING'] || this.state === configData.expense_report_states['REJECTED']) ? '' : 'hidden'));
    
      __out.push('>\n    <i class="icon icon-trashcan"></i> <span>Remove Expense</span>\n  </button>\n  <button class="dropdown-item font-14 retract-btn" type="button" ');
    
      __out.push(__sanitize((this.state === configData.expense_report_states['SUBMITTED'] && this.expense_status !== 'Reimbursed') ? '' : 'hidden'));
    
      __out.push('>\n    <i class="icon icon-move-outstanding-icon"></i> <span>Retract Submission</span>\n  </button>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}