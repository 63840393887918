import React from 'react';

import last from 'lodash/last';

import TotalAmount from '@src/components/revenue_center/reconcile/total_amount';
import TableSection from '@src/components/ui_v2/table_section';

import { ITSDCollected } from '../hooks';
import Table from './table';

interface IReconcileIncomeListProps {
  collection: ITSDCollected,
  paymentProcessorName: string,
}

const ReconcileIncomeList = ({
  collection,
  paymentProcessorName,
}: IReconcileIncomeListProps) => {
  const pages = collection.query.data?.pages || [];
  const totalAmount = last(pages)?.meta?.totalAmount || 0;

  return (
    <TableSection>
      <TableSection.Footer>
        <TableSection.Caption>
          Deposit Transactions from
          { ' ' }
          {paymentProcessorName}
        </TableSection.Caption>
        <TotalAmount amount={ totalAmount } />
      </TableSection.Footer>
      <Table
        collection={ collection }
        paymentProcessorName={ paymentProcessorName }
      />
    </TableSection>
  );
};

export default React.memo(ReconcileIncomeList);
