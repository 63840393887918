import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import map from 'lodash/map';

import {
  CustomFilterKeys,
  TAmountFilter,
  TAmountFilterKey,
  TCustomFilterKey,
  TCustomFilterValue,
  TDateFilter,
  TDateFilterKey,
  TFilterData,
  TFilterKey,
} from '@src/types/filter';

type TPlainFilterItem = { key: string, value: string };
type TPlainFilter = Array<TPlainFilterItem>;

const SkippedFilterKeys = [
  'transaction_date_start_string',
  'transaction_date_end_string',
  'transaction_dispute_date_string',
  'transaction_resolved_date_string',
];

const keyRegex = /([^[\]]+)\[([^\]]+)\]/;

const parseNestedURLFilterValue = (
  filterData: TFilterData,
  paramName: string,
  value: string,
) => {
  const match = keyRegex.exec(paramName);
  if (!match) return;

  const key = match[1];
  const subKey = match[2];
  if (!key || !subKey) return;

  filterData[key] = {
    ...(<object>filterData[<TFilterKey>key]),
    [subKey]: decodeURIComponent(value),
  };
};

const parseURLFilterValue = (
  filterData: TFilterData,
  paramName: string,
  value: string | undefined,
) => {
  if (!value) return;

  switch (paramName) {
    case 'transaction_date_start':
      filterData.transaction_date = filterData.transaction_date || {};
      (<TDateFilter>filterData.transaction_date).gte = value;
      break;
    case 'transaction_date_end':
      filterData.transaction_date = filterData.transaction_date || {};
      (<TDateFilter>filterData.transaction_date).lte = value;
      break;
    case 'created_at_start':
      filterData.created_at = filterData.created_at || {};
      (<TDateFilter>filterData.created_at).gte = value;
      break;
    case 'created_at_end':
      filterData.created_at = filterData.created_at || {};
      (<TDateFilter>filterData.created_at).lte = value;
      break;
    case 'amount_val':
      filterData.amount = filterData.amount || {};
      (<TAmountFilter>filterData.amount).val = value;
      break;
    case 'amount_min':
      filterData.amount = filterData.amount || {};
      (<TAmountFilter>filterData.amount).gte = value;
      break;
    case 'amount_max':
      filterData.amount = filterData.amount || {};
      (<TAmountFilter>filterData.amount).lte = value;
      break;
    case 'transaction_type':
      filterData.transaction_type = [decodeURIComponent(value)];
      break;
    case 'excluded':
      filterData.excluded = value === 'true';
      break;
    default:
      if (paramName.includes('[')) {
        parseNestedURLFilterValue(filterData, paramName, value);
      } else {
        filterData[<TFilterKey>paramName] = decodeURIComponent(value);
      }
  }
};

const getPlainFilterItemFromDateFilter = (
  filterKey: TFilterKey,
  dateFilterKey: TDateFilterKey,
  value?: TDateFilter[TDateFilterKey],
): TPlainFilterItem | undefined => {
  if (!value) return undefined;

  let key: string = filterKey;

  if (dateFilterKey === 'gte') {
    key = `${key}_start`;
  } else {
    key = `${key}_end`;
  }

  return { key, value };
};

const getPlainFilterItemFromAmountFilter = (
  amountFilterKey: TAmountFilterKey,
  value?: TAmountFilter[TAmountFilterKey],
): TPlainFilterItem | undefined => {
  if (!value) return undefined;

  let key: string;

  if (amountFilterKey === 'val') {
    key = 'amount_val';
  } else if (amountFilterKey === 'gte') {
    key = 'amount_min';
  } else {
    key = 'amount_max';
  }

  return { key, value };
};

const getPlainFilterItemFromCustom = (
  filterKey: TCustomFilterKey,
  value: TCustomFilterValue,
): TPlainFilter | undefined => {
  if (filterKey === 'transaction_type') {
    if (!isArray(value)) return undefined; // wrong type
    const type = value as string[];
    if (!type || type.length === 0) return undefined;

    return [{ key: filterKey, value: type[0] }];
  }

  if (['transaction_date', 'invoice_date', 'created_at'].includes(filterKey)) {
    if (!isObject(value)) return undefined; // wrong type;

    return compact(map(value, (val, subFilterKey) => getPlainFilterItemFromDateFilter(
      filterKey,
      subFilterKey as TDateFilterKey,
      val,
    )));
  }

  if (filterKey === 'amount') {
    if (!isObject(value)) return undefined; // wrong type;

    return compact(map(value, (val, subFilterKey) => getPlainFilterItemFromAmountFilter(
      subFilterKey as TAmountFilterKey,
      val,
    )));
  }

  return undefined;
};

const getPlainFilterItemFromNested = (
  filterKey: string,
  value: object,
): TPlainFilter | undefined => {
  return map(value, (v, k) => ({ key: `${filterKey}[${k}]`, value: v }));
};

const getPlainFilterFromFilter = (
  filterData: TFilterData,
): TPlainFilter => {
  return flatten(compact(map(filterData, (value, filterKey) => {
    if (SkippedFilterKeys.includes(filterKey)) return undefined;
    if (!value) return undefined;

    if (includes(CustomFilterKeys, filterKey)) {
      return getPlainFilterItemFromCustom(
        filterKey as TCustomFilterKey,
        value as TCustomFilterValue,
      );
    }

    // Trying to nest any object type to support all filter values with [gte], [lte], [val], etc
    if (isObject(value)) return getPlainFilterItemFromNested(filterKey, value);

    if (!isString(value)) return undefined; // wrong value type

    return { key: filterKey, value };
  })));
};

const getSearchParamsFromFilter = (filterData: TFilterData | undefined): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (!filterData) return searchParams;

  getPlainFilterFromFilter(filterData).forEach(({ key, value }) => {
    searchParams.set(key, value);
  });

  return searchParams;
};

const parseSearchParams = (searchParams: URLSearchParams): TFilterData | undefined => {
  let filterData: TFilterData | undefined;

  searchParams.forEach((value, key) => {
    if (key.endsWith('_order')) return;

    filterData = filterData || {};
    parseURLFilterValue(filterData, key, value);
  });

  return filterData;
};

export {
  getSearchParamsFromFilter,
  parseSearchParams,
};
