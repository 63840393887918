import React, { useCallback, useMemo } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import {
  useGetReceivableAccountsPayableServices,
} from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { IBusiness } from '@src/types/businesses';

import { IconSelectInput, TIconOption } from '@src/components/ui/form';
import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import { IForwardDocumentFormValues } from './forward_document_form';

interface IForwardDocumentBusinessProps {
  currentBusiness: IBusiness,
  field: ControllerRenderProps<IForwardDocumentFormValues, 'businessId'>,
}

type TIconOptionWithFilterLable = TIconOption & { filterLabel: string};

const ForwardDocumentBusinessInput = ({
  currentBusiness,
  field: { value, onChange },
}: IForwardDocumentBusinessProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data: businesses,
  } = useGetReceivableAccountsPayableServices({ businessId: currentBusiness.id });
  const options: TIconOptionWithFilterLable[] = useMemo(() => {
    return (businesses ?? []).map((business) => ({
      value:       String(business.businessId),
      label:       business.businessName,
      filterLabel: `${business.businessName} ${business.businessDisplayName}`,
      icon:        business.businessAvatarUrl ? (
        <img
          alt="Business avatar"
          className="small-avatar"
          src={ business.businessAvatarUrl }
        />
      ) : (
        <BusinessDefaultAvatarIcon fontSize={ 25 } />
      ),
    }));
  }, [businesses]);

  const selectedOption = options.find((o) => o.value === String(value));

  const handleChange = useCallback((item: TIconOptionWithFilterLable | null) => {
    onChange(Number(item?.value));
  }, [onChange]);

  const filterOptions = <TOption extends TIconOptionWithFilterLable = TIconOptionWithFilterLable>(
    option: { label: string; value: string| undefined, data: TOption },
    inputValue: string,
  ) => {
    if (option.data.filterLabel) {
      return option.data.filterLabel.toLowerCase().includes(inputValue.toLowerCase());
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <>
      { isLoading && <Spinner /> }
      { isError && <ErrorNotification error={ error } /> }

      <div className="flex-wrapper item-divider p-t-5 p-b-25 p-r-20 p-l-20">
        <label htmlFor="forward_to_business_id">Select Business to forward</label>
        <IconSelectInput
          filterOption={ filterOptions }
          fontSize={ 16 }
          id="forward_to_business_id"
          ml="auto"
          options={ options }
          placeholder="Search Business"
          value={ selectedOption }
          widthPercent={ 50 }
          onChange={ handleChange }
        />
      </div>
    </>
  );
};

export default ForwardDocumentBusinessInput;
