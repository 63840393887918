import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';

interface IConfirmAddProps extends IUseModalProps {
  confirmTitle: string,
  text: JSX.Element,
  vendors: IGlobalVendorWithBusinessInfo[],
  title: string,
}

const ConfirmAdd = ({
  confirmTitle,
  isOpen,
  text,
  title,
  vendors,
  onCancel,
  onDone,
}: IConfirmAddProps): JSX.Element => {
  return (
    <Modal.Standard
      proceedTitle={ confirmTitle }
      show={ isOpen }
      title={ title }
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => (
        <>
          <p className="p-b-20 font-16 in-black">{ text }</p>
          <div className="docyt-network-list-wrapper">
            {
              vendors.map((vendor) => {
                return (
                  <div key={ vendor.id } className="display-flex item-divider m-t-10">
                    <img
                      alt="Vendor avatar"
                      className="small-avatar"
                      src={ vendor.imageUrl }
                    />
                    <p className="font-16 inline-block m-l-15 width-40-percent">
                      { vendor.name }
                    </p>
                    <p className="font-16 inline-block m-l-15">
                      { vendor.docytNetworkId }
                    </p>
                  </div>
                );
              })
            }
          </div>
        </>
      )}
    </Modal.Standard>
  );
};

const useConfirmAddModal = makeUseModal(ConfirmAdd);

export {
  IConfirmAddProps,
  useConfirmAddModal,
  ConfirmAdd as default,
};
