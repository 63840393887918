import React, { memo } from 'react';

import Markdown from 'markdown-to-jsx';

import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { AiChatLogoIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IChatMessageItemProps {
  question: string;
  answer?: string;
  loader: boolean;
}

const ChatMessageItem = ({ question, answer, loader } : IChatMessageItemProps) => {
  const handleLinkClick = (event : any) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(event.target.href, '_blank');
  };

  return (
    <div className={ styles['chat-message'] }>
      <div className={ styles['chat-message-question'] }>{question}</div>
      <div className={ styles['chat-message-answer-container'] }>
        <div className={ styles['chat-message-answer-logo'] }>
          <AiChatLogoIcon />
          {' '}
        </div>
        {(!answer && loader) ? (
          <span>
            <SpinnerIcon spin />
          </span>
        ) : answer && (
          <Markdown
            className={ styles['chat-message-answer'] }
            options={ {
              overrides: {
                a: {
                  component: 'a',
                  props:     {
                    target:  '_blank',
                    onClick: handleLinkClick,
                  },
                },
              },
            } }
          >
            {answer}
          </Markdown>
        )}
      </div>
    </div>
  );
};

export default memo(ChatMessageItem);
