import React from 'react';

import { IReport } from '@src/types/report_service/report';
import { IReportItemAccountValue } from '@src/types/report_service/report_item_value_account';
import { currencyFormater3 } from '@src/utils/currency';

import Table from '@src/components/ui/table';

interface IMultiEntityItemAccountValueListItemProps {
  value: IReportItemAccountValue,
  businessItemAccountValues: IReportItemAccountValue[],
  reports: IReport[],
  itemIdentifier: string,
  from: string,
  to: string,
  multiBusinessReportId: string,
}

const MultiEntityItemAccountValueListItem = ({
  value,
  businessItemAccountValues,
  reports,
  itemIdentifier,
  from,
  to,
  multiBusinessReportId,
}: IMultiEntityItemAccountValueListItemProps): JSX.Element => {
  const businessAccountValues = businessItemAccountValues.filter(
    (itemAccountValue) => value.name === itemAccountValue.name,
  );

  return (
    <Table.Row>
      <Table.Cell
        textAlign="left"
      >
        <span>
          {value.name}
        </span>
      </Table.Cell>
      <Table.Cell
        textAlign="center"
      >
        <span>
          {currencyFormater3(value.value)}
        </span>
      </Table.Cell>
      {
        businessAccountValues.map((businessAccountValue, index) => {
          return (
            <Table.Cell
              key={ `${businessAccountValue.name}-${businessAccountValue.id}-${businessAccountValue.reportId}` }
              pointer
              textAlign="center"
            >
              { businessAccountValue.dynamicReport
                ? (
                  <span>
                    {currencyFormater3(businessAccountValue.value)}
                  </span>
                )
                : (
                  <a
                    className="in-blue-900"
                    href={ `/multi_entity_reports/custom_reports/${multiBusinessReportId}/reports/${reports[index].id}/item_value_detail?item_id=${itemIdentifier}&chart_of_account_id=${businessAccountValue.chartOfAccountId}&accounting_class_id=${businessAccountValue.accountingClassId}&start_date=${from}&end_date=${to}` }
                  >
                    {currencyFormater3(businessAccountValue.value)}
                  </a>
                ) }
            </Table.Cell>
          );
        })
      }
    </Table.Row>
  );
};

export default MultiEntityItemAccountValueListItem;
