import { TOrderDirection, TID, TMongoID } from '@src/types/common';
import {
  IReportServiceBudget,
  TReportServiceBudgetsSortColumn,
} from '@src/types/report_service/report_service_budgets';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';

type TSaveBudgetMethod = 'publish' | 'discard'

interface IGetReportServiceBudgetsParams {
  reportServiceId: TID,
  orderColumn?: TReportServiceBudgetsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
}

interface IGetReportServiceBudgetsResponse {
  meta: {
    totalCount: number,
  },
  collection: IReportServiceBudget[],
}

interface IAddReportServiceBudgetParams {
  reportServiceId: TID,
  name: string,
  year: number,
  standardCategoryId: number,
  type: string,
}

interface IAddReportServiceBudgetResponse {
  budget: IReportServiceBudget,
}

interface IDeleteReportServiceBudgetParams {
  id: TID,
}

interface IEditReportServiceBudgetResponse {
  budget: IReportServiceBudget,
}

interface IEditReportServiceBudgetParams {
  id: TID,
  name: string,
  year: number,
  type: string,
}

interface ISaveReportServiceBudgetParams {
  budgetId: TID,
  method: TSaveBudgetMethod,
}

interface ISaveReportServiceBudgetResponse { }

interface IImportBudgetParams {
  budgetId: string,
  fileToken: string,
}

interface IImportBudgetResponse {
  success: boolean
}

interface IUploadBudgetParams {
  file: File,
  onUploadProgress: (progressEvent: any) => void
}

interface IUploadBudgetResponse {
  token: string
}

const getReportServiceBudgets = (
  params: IGetReportServiceBudgetsParams,
): Promise<IGetReportServiceBudgetsResponse> => {
  return apiGet(
    '/reports/api/v1/report_comparers',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta: {
        totalCount: cdata.total,
      },
      collection: cdata.budgets as IReportServiceBudget[],
    };
  });
};

const getReportServiceBudget = (id: TMongoID): Promise<IReportServiceBudget> => {
  return apiGet(`/reports/api/v1/report_comparers/${id}`)
    .then((data) => camelizeKeys(data.budget) as IReportServiceBudget);
};

const postReportServiceBudget = (
  params: IAddReportServiceBudgetParams,
): Promise<IAddReportServiceBudgetResponse> => {
  return apiPost(
    '/reports/api/v1/report_comparers',
    underscoreKeys(params),
  ).then((data) => ({ budget: data.budget }));
};

const deleteReportServiceBudget = (
  params: IDeleteReportServiceBudgetParams,
): Promise<void> => {
  return apiDelete(
    `/reports/api/v1/report_comparers/${params.id}`,
  );
};

const putEditReportServiceBudget = (
  params: IEditReportServiceBudgetParams,
): Promise<IEditReportServiceBudgetResponse> => {
  return apiPut(
    `/reports/api/v1/report_comparers/${params.id}`,
    underscoreKeys(params),
  ).then((data) => ({ budget: data.budget }));
};

const putSaveReportServiceBudget = (
  params: ISaveReportServiceBudgetParams,
): Promise<ISaveReportServiceBudgetResponse> => {
  return apiPut(
    `/reports/api/v1/report_comparers/${params.budgetId}/${params.method}`,
    {},
  );
};

const uploadBudget = (
  params: IUploadBudgetParams,
): Promise<IUploadBudgetResponse> => {
  const formData = new FormData();

  if (params.file) {
    formData.append('file', params.file);
    formData.append('original_file_name', params.file.name);
  }

  return apiPost(
    '/storage/api/v1/files.json',
    formData,
    params.onUploadProgress,
  );
};

const importBudget = (
  params: IImportBudgetParams,
): Promise<IImportBudgetResponse> => {
  return apiPost(
    '/reports/api/v1/import_budgets',
    underscoreKeys(params),
  );
};

export {
  IGetReportServiceBudgetsParams,
  IGetReportServiceBudgetsResponse,
  IAddReportServiceBudgetParams,
  IAddReportServiceBudgetResponse,
  IDeleteReportServiceBudgetParams,
  IEditReportServiceBudgetResponse,
  IEditReportServiceBudgetParams,
  ISaveReportServiceBudgetParams,
  ISaveReportServiceBudgetResponse,
  IImportBudgetParams,
  IImportBudgetResponse,
  IUploadBudgetParams,
  IUploadBudgetResponse,
  getReportServiceBudgets,
  getReportServiceBudget,
  postReportServiceBudget,
  deleteReportServiceBudget,
  putEditReportServiceBudget,
  putSaveReportServiceBudget,
  importBudget,
  uploadBudget,
};
