import React, { useCallback, useEffect, useState } from 'react';

import { useGetBusinessVendor } from '@src/hooks/queries/vendors';
import { IBusinessVendor, TVendorMergeType } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

import ReviewChangesModal from '@src/components/vendors/views/review_changes_modal';
import ReviewMergeVendorModal from '@src/components/vendors/views/review_merge_vendor_modal';

interface IMergeVendorWizardProps {
  businessId: number,
  mergeableId: TID,
  vendor: IBusinessVendor,
  show: boolean,
  onFinish: (value: TVendorMergeType) => void,
  onCancel: () => void,
}

const MergeVendorWizard = ({
  businessId,
  mergeableId,
  vendor,
  show,
  onFinish,
  onCancel,
}: IMergeVendorWizardProps): JSX.Element => {
  const [step, setStep] = useState<null | 'reviewVendor' | 'reviewChanges'>(show ? 'reviewVendor' : null);

  const vendorQuery = useGetBusinessVendor({
    businessId,
    vendorId: mergeableId,
  });

  useEffect(() => {
    setStep(show ? 'reviewVendor' : null);
  }, [show]);

  const handleContinue = useCallback((value: TVendorMergeType) => {
    if (value === 'enable_merge') {
      setStep('reviewChanges');
    } else {
      onFinish(value);
      setStep(null);
    }
  }, [onFinish]);

  const handleReviewChangesCancel = useCallback(() => {
    setStep(null);
    onCancel();
  }, [onCancel]);

  const handleReviewChangesDone = useCallback(() => {
    onFinish('enable_merge');
    setStep(null);
  }, [onFinish]);

  return (
    <>
      <ReviewMergeVendorModal
        globalVendor={ vendorQuery.data }
        show={ step === 'reviewVendor' }
        vendor={ vendor }
        onCancel={ onCancel }
        onContinue={ handleContinue }
      />
      <ReviewChangesModal
        globalVendor={ vendorQuery.data }
        show={ step === 'reviewChanges' }
        vendor={ vendor }
        onCancel={ handleReviewChangesCancel }
        onDone={ handleReviewChangesDone }
      />
    </>
  );
};

export {
  MergeVendorWizard as default,
};
