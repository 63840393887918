import React from 'react';

import classNames from 'classnames';

import { TSection } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import { useClearFilter, useFilterCounter } from '@src/components/ui_v2/filter';

import styles from './styles.module.scss';

interface ITableSectionClearFilterProps extends
  React.ComponentPropsWithoutRef<typeof Button>
{
  hideCounter?: boolean,
  section?: TSection,
}

const TableSectionClearFilter = ({
  className,
  hideCounter,
  title = 'Clear',
  section,
  ...props
}: ITableSectionClearFilterProps) => {
  const filterCounter = useFilterCounter(section);
  const clear = useClearFilter(section);
  const hasValue = filterCounter > 0;

  const classes = classNames(
    styles['clear-filter-button'],
    className,
    { 'has-value': hasValue },
  );

  return (
    <Button
      className={ classes }
      disabled={ !hasValue }
      tabIndex={ 0 }
      variant="link"
      { ...props }
      onClick={ clear }
    >
      { title }
      { !hideCounter && hasValue && (
        <>
          { ' ' }
          <span className={ styles['filter-counter'] }>
            {filterCounter}
          </span>
        </>
      ) }
    </Button>
  );
};

export default TableSectionClearFilter;
