import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getDataSetsWithValues,
  IGetDataSetsWithValuesParams,
  IGetDataSetsWithValuesResponse,
} from '@src/requests/dashboards/data_sets';

const useGetDataSetsWithValues = (params: IGetDataSetsWithValuesParams) => {
  return useQuery<IGetDataSetsWithValuesResponse, Error>(
    [QueryKey.dashboardDataSets, params],
    () => getDataSetsWithValues(params),
  );
};

export {
  useGetDataSetsWithValues,
};
