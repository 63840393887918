module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var checklist_item, i, len, ref;
    
      __out.push('<div class="document-detail-sidebar-section-header">\n  <span class="font-bold">CHECKLIST</span>\n  <a class="display-flex align-items-center pointer new-item-js">\n    <i class="icon icon-plus"></i>\n    <span class="m-l-5">New Item</span>\n  </a>\n</div>\n<div class="checklist-detail-view p-l-12 p-r-10">\n  <div class="checklist-item-filter-wrapper">\n    <input type="text" class="form-control date-picker filter-date" placeholder="Start Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter_date));
    
      __out.push('" />\n  </div>\n  <div class="checklist-item-add-wrapper hidden">\n    <input class="form-control font-12 edit-item-area" placeholder="Enter checklist item">\n    <div class="checklist-item-action-wrapper">\n      <button type=\'button\' class="btn btn-info ladda-button btn-add-item" data-color="blue" data-style="expand-right"><span class="ladda-label">Add</span></button>\n      <a class="btn-close pointer">\n        <i class="icon icon-reject-invite in-grey-1050 font-28"></i>\n      </a>\n    </div>\n  </div>\n  <div class="table-responsive no-border">\n    ');
    
      if (this.checklist_items.length === 0) {
        __out.push('\n    <p class="m-l-7 in-grey-1050">Add some items to your checklist.</p>\n    ');
      } else {
        __out.push('\n    <table class="table">\n      <tbody>\n        ');
        ref = this.checklist_items;
        for (i = 0, len = ref.length; i < len; i++) {
          checklist_item = ref[i];
          __out.push('\n        <tr>\n          <td>\n            <div class="checklist-checkbox">\n              <div class="checkbox checkbox-primary">\n                <input type="checkbox" class="pointer check-item-js" data-item-id="');
          __out.push(__sanitize(checklist_item.id));
          __out.push('" ');
          __out.push(__sanitize(checklist_item.is_checked ? 'checked' : ''));
          __out.push('>\n                <label></label>\n              </div>\n              <p class="');
          __out.push(__sanitize(checklist_item.is_checked ? 'in-grey-700' : void 0));
          __out.push('">');
          __out.push(__sanitize(checklist_item.name));
          __out.push('</p>\n            </div>\n            ');
          if (checklist_item.is_checked) {
            __out.push('\n            <span class="checklist-item-date m-l-25 m-t-6 in-grey-700"><span>');
            __out.push(__sanitize(this.dateForItem(checklist_item)));
            __out.push('</span>  |  <span>');
            __out.push(__sanitize(checklist_item.user_fullname));
            __out.push('</span></span>\n            ');
          }
          __out.push('\n          </td>\n          <td width="50px">\n            ');
          if (!checklist_item.is_checked) {
            __out.push('\n            <div class="checklist-item-trash-wrapper">\n              <a class="pointer delete-item-js" data-item-id="');
            __out.push(__sanitize(checklist_item.id));
            __out.push('">\n                <i class="icon icon-trashcan font-16"></i>\n              </a>\n            </div>\n            ');
          }
          __out.push('\n          </td>\n        </tr>\n        ');
        }
        __out.push('\n      </tbody>\n    </table>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}