/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react';

import pluralize from 'pluralize';

import { useBulkDeleteMetricsReport } from '@src/hooks/queries/metrics/metrics_reports';
import { IMetricsReport } from '@src/types/metrics/metrics_reports';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

interface IBulkDeleteMetricReportActionProps {
  selectedRecords: IMetricsReport[],
}

const BulkDeleteMetricReportAction = ({
  selectedRecords,
}: IBulkDeleteMetricReportActionProps) : JSX.Element => {
  const moveToTrash = useBulkDeleteMetricsReport();

  const documentIds = useMemo(() => {
    return selectedRecords
      .map((metricReport) => metricReport.id)
      .filter((id): id is string => id !== undefined);
  }, [selectedRecords]);

  const isDisabled = documentIds.length === 0;

  const bulkAction = useBulkActionModal({
    mutation: moveToTrash,
  });

  const { status } = bulkAction.props;

  const confirm = useConfirmDeleteModal({
    onDone: useCallback(() => {
      bulkAction.runMutation(documentIds.map((dataId) => ({ metricsReportId: dataId })));
    }, [bulkAction, documentIds]),
  });

  return (
    <>
      <confirm.Component
        { ...confirm.props }
        confirmTitle="Proceed"
        icon={ undefined }
        text={ (
          <div className="text-center">
            <p>
              Are you sure you want to delete the selected
              {' '}
              {pluralize('file', documentIds.length)}
              ?
            </p>
          </div>
        ) }
        title={ `Delete ${pluralize('File', documentIds.length)}` }
      />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="file"
        successResultText="deleted successfully"
        title={ documentIds.length === 0
          ? `Delete ${pluralize('File', status.successCount)}`
          : `Delete ${pluralize('File', documentIds.length)}` }
      />
      <ActionsDropdown.Action
        disabled={ isDisabled }
        icon={ <DeleteDocumentIcon fontSize={ 20 } /> }
        title="Delete"
        onSelect={ !isDisabled ? confirm.open : undefined }
      />
    </>
  );
};

export default BulkDeleteMetricReportAction;
