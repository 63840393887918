import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useRenameInboxServiceDocument } from '@src/hooks/queries/inbox_service_documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';
import { getFilenameExtension, getFilenameWithoutExtension } from '@src/utils/filename_helpers';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IRenameDocumentFormValues } from './rename_document_form';

interface IRenameDocumentProps extends IUseModalProps {
  document: IInboxServiceDocument,
}

const RenameDocument = ({
  document,
  isOpen,
  onCancel,
  onDone,
}: IRenameDocumentProps) => {
  const rename = useRenameInboxServiceDocument();

  const { mutate } = rename;
  const handleSubmit = useCallback((values: IRenameDocumentFormValues) => {
    mutate(
      {
        documentId: document.id,
        name:       `${values.name}.${getFilenameExtension(document.name)}`,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [document, onDone, mutate]);

  const originalName = getFilenameWithoutExtension(document.name);

  return (
    <>
      <MutationStatus mutation={ rename } />
      <Modal.Form
        proceedTitle="Save"
        show={ isOpen }
        title="Rename Document"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            formId={ formId }
            originalName={ originalName }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useRenameDocumentModal = makeUseModal(RenameDocument);

export {
  IRenameDocumentProps,
  useRenameDocumentModal,
  RenameDocument as default,
};
