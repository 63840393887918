import React, { useCallback } from 'react';

import isEqual from 'lodash/isEqual';
import { ControllerRenderProps } from 'react-hook-form';

import { useUniqueDomId } from '@src/hooks/dom';
import { IWidget } from '@src/types/dashboards';

import { IDashboardInput } from './schema';

interface ISelectAllWidgetCheckboxProps
{
  widgets: IWidget[],
  field: ControllerRenderProps<IDashboardInput, 'widgets'>,
}

const SelectAllWidgetCheckbox = ({
  widgets,
  field: { value, onChange },
}: ISelectAllWidgetCheckboxProps): JSX.Element => {
  const inputId = useUniqueDomId('widget_selector_');

  const handleChange = useCallback((e) => {
    if (!e.target.checked) {
      onChange([]);
    } else {
      onChange(widgets);
    }
  }, [onChange, widgets]);

  const isAllSelected = () => {
    if (widgets.length === 0) return false;

    return isEqual(
      value.map((item) => item.identifier).sort(),
      widgets.map((item) => item.identifier).sort(),
    );
  };

  return (
    <div className="checkbox checkbox-primary">
      <input
        checked={ isAllSelected() }
        className="pointer"
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
      />
      <label
        aria-label="Select"
        htmlFor={ inputId }
      >
        Select All
      </label>
    </div>
  );
};

export default SelectAllWidgetCheckbox;
