import React, { useCallback, useState } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Tooltip from '@src/components/ui/tooltip';
import { CopyIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface CopyClipboardProps extends Omit<React.ComponentPropsWithoutRef<'span'>, 'title'>, IUIStyleProps {
  copyUrl: string,
}

const CopyClipboard = ({
  copyUrl,
  ...props
}: CopyClipboardProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [classes, spanProps] = uiStyleProps(styles.clipboard, props);

  const handleCopyClipboard = useCallback(() => {
    setIsCopied(true);
    navigator.clipboard.writeText(copyUrl);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, [copyUrl]);

  let content;

  if (isCopied) {
    content = (
      <Tooltip
        className={ classes }
        mr={ 10 }
        title="Copied!"
        { ...spanProps }
      >
        <CopyIcon
          pointer
          fontSize={ 20 }
          ml={ 10 }
          onClick={ handleCopyClipboard }
        />
      </Tooltip>
    );
  } else {
    content = (
      <CopyIcon
        pointer
        fontSize={ 20 }
        ml={ 10 }
        onClick={ handleCopyClipboard }
      />
    );
  }

  return (
    <div className={ styles['copy-clipboard'] }>
      { content }
    </div>
  );
};

export default CopyClipboard;
