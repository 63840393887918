module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountClass, i, j, len, len1, payment_processor, ref, ref1, ref2, ref3;
    
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n      <div class="modal-content">\n        <div class="modal-header">\n          <h4 class="modal-title">');
    
      __out.push(__sanitize(this.selection ? 'Edit Item' : 'New Item'));
    
      __out.push('</h4>\n        </div>\n        <div class="modal-body">\n          <div class="document-fields">\n            ');
    
      if (this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_PAYMENT_PROCESSORS_ADJUSTMENT']) {
        __out.push('\n              <div class="field-input-container">\n                <div class="document-field-item document-field-name">Payment Processor:</div>\n                <div class="document-field-item document-field-value">\n                  <select class="payment-processor-select selectpicker" data-dropup-auto="false" title="Select Payment Processor" ');
        __out.push(__sanitize(this.selection ? 'disabled' : ''));
        __out.push('>\n                    ');
        ref = this.payment_processors;
        for (i = 0, len = ref.length; i < len; i++) {
          payment_processor = ref[i];
          __out.push('\n                      <option value="');
          __out.push(__sanitize(payment_processor.id));
          __out.push('" ');
          if (this.selectedPPId === payment_processor.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(payment_processor.name));
          __out.push('</option>\n                    ');
        }
        __out.push('\n                  </select>\n                </div>\n              </div>\n            ');
      }
    
      __out.push('\n            ');
    
      if (((ref1 = this.selection) != null ? ref1.get('section') : void 0) !== 'payment_processors') {
        __out.push('\n            <div class="field-input-container">\n              <div class="document-field-item document-field-name">Chart of account:</div>\n              <div class="document-field-item document-field-value">\n                ');
        if (this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_PAYMENT_PROCESSORS_ADJUSTMENT']) {
          __out.push('\n                  <input class="form-control chart-of-account-input-js" placeholder="Select Chart of Account" value="');
          if (this.selection) {
            __out.push(__sanitize((ref2 = this.selection.get('chart_of_account')) != null ? ref2.name : void 0));
          }
          __out.push('" autocomplete="off" ');
          __out.push(__sanitize(this.selection ? 'disabled' : ''));
          __out.push('/>\n                ');
        } else {
          __out.push('\n                  <input class="form-control chart-of-account-input-js" placeholder="Select Chart of Account" value="');
          if (this.selection) {
            __out.push(__sanitize(this.selection.get('display_name')));
          }
          __out.push('" autocomplete="off" ');
          __out.push(__sanitize(this.selection ? 'disabled' : ''));
          __out.push('/>\n                ');
        }
        __out.push('\n              </div>\n            </div>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_CATEGORY'] || this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_PAYMENT_PROCESSORS_ADJUSTMENT']) {
        __out.push('\n              <div class="field-input-container">\n                <div class="document-field-item document-field-name">Department:</div>\n                <div class="document-field-item document-field-value">\n                  <select class="account-class-select selectpicker" data-dropup-auto="false" title="Enter Department" ');
        if (!(this.accountClasses && this.accountClasses.length > 0)) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n                    ');
        ref3 = this.accountClasses;
        for (j = 0, len1 = ref3.length; j < len1; j++) {
          accountClass = ref3[j];
          __out.push('\n                      <option value="');
          __out.push(__sanitize(accountClass.id));
          __out.push('" ');
          if (this.selectedAccountClassId === accountClass.id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(accountClass.name));
          __out.push('</option>\n                    ');
        }
        __out.push('\n                  </select>\n                </div>\n              </div>\n            ');
      }
    
      __out.push('\n            <div class="field-input-container">\n              <div class="document-field-item document-field-name">Line item:</div>\n              <div class="document-field-item document-field-value">\n                <input class="form-control report-line-item-input" type="text" value="');
    
      __out.push(__sanitize(this.reportLineItem));
    
      __out.push('">\n              </div>\n            </div>\n            <div class="field-input-container">\n              <div class="document-field-item document-field-name">Entry Type:</div>\n              <div class="document-field-item document-field-value">\n                <select class="entry-type-select selectpicker" data-dropup-auto="false" title="Select Entry Type">\n                  ');
    
      if (this.isDebit === null) {
        __out.push('\n                    <option/>\n                  ');
      }
    
      __out.push('\n                  <option value="debit" ');
    
      if (this.isDebit === true) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Debit</option>\n                  <option value="credit" ');
    
      if (this.isDebit === false) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Credit</option>\n                </select>\n              </div>\n            </div>\n            ');
    
      if (this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['TAX']) {
        __out.push('\n              <div class="field-input-container">\n                <div class="document-field-item document-field-name">Tax Agency:</div>\n                <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n                  <div class="vendors-region width-100-percent"></div>\n                </div>\n              </div>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-info btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}