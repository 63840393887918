import React from 'react';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { CustomReportsIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IBlankPageProps {
  onOpenCreateNewReportModal: () => void,
}

const BlankPage = ({
  onOpenCreateNewReportModal,
}: IBlankPageProps) => {
  return (
    <div className={ styles['empty-content'] }>
      <CustomReportsIcon className={ styles['custom-report-empty-img'] } />
      <Text fontSize={ 16 } inColor="black" mt={ 50 }>
        No Advanced Reports Available. Click the button below to add a new advanced report.
      </Text>
      <Button
        className="m-t-25"
        variant="primary"
        onClick={ onOpenCreateNewReportModal }
      >
        Add New Advanced Report
      </Button>
    </div>
  );
};

export default BlankPage;
