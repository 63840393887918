import { TID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards/dashboards';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';

interface IGetDashboardsParams {
  businessId: TID,
}

interface IGetDashboardsResponse {
  collection: IDashboard[],
}

interface ICreateDashboardParams {
  businessId: TID,
  name: string,
  templateIds?: string[],
}

interface ICreateDashboardResponse {
  dashboard: IDashboard,
}

interface IUpdateDashboardParams {
  id: string,
  name: string,
  templateIds?: string[],
}

interface IUpdateDashboardResponse {
  dashboard: IDashboard,
}

interface IDeleteDashboardParams {
  id: string,
}

const getDashboards = (
  params: IGetDashboardsParams,
): Promise<IGetDashboardsResponse> => {
  return apiGet(
    '/dashboards/api/v1/dashboards.json',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.dashboards as IDashboard[],
    };
  });
};

const createDashboard = (
  params: ICreateDashboardParams,
): Promise<ICreateDashboardResponse> => {
  return apiPost(
    '/dashboards/api/v1/dashboards.json',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateDashboardResponse);
};

const updateDashboard = (
  params: IUpdateDashboardParams,
): Promise<IUpdateDashboardResponse> => {
  return apiPut(
    `/dashboards/api/v1/dashboards/${params.id}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IUpdateDashboardResponse);
};

const deleteDashboard = (
  params: IDeleteDashboardParams,
): Promise<void> => {
  return apiDelete(
    `/dashboards/api/v1/dashboards/${params.id}`,
  );
};

export {
  IGetDashboardsParams,
  IGetDashboardsResponse,
  ICreateDashboardParams,
  ICreateDashboardResponse,
  IUpdateDashboardParams,
  IUpdateDashboardResponse,
  IDeleteDashboardParams,
  getDashboards,
  createDashboard,
  updateDashboard,
  deleteDashboard,
};
