import { TID } from '@src/types/common';
import { IDwollaCustomer } from '@src/types/dwolla_customer';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetDwollaCustomersByBusinessParams {
  businessId: TID,
}

interface IGetDwollaCustomersByVendorParams {
  businessId: TID,
  vendorId?: TID,
}

const getDwollaCustomersByBusiness = (
  params: IGetDwollaCustomersByBusinessParams,
): Promise<IDwollaCustomer> => {
  return apiGet(
    '/api/v1/dwolla_customers/by_business',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data.dwolla_customer) as IDwollaCustomer);
};

const getDwollaCustomersByVendor = (
  params: IGetDwollaCustomersByVendorParams,
): Promise<IDwollaCustomer> => {
  return apiGet(
    '/api/v1/dwolla_customers/by_vendor',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data.dwolla_customer) as IDwollaCustomer);
};

export {
  IGetDwollaCustomersByBusinessParams,
  IGetDwollaCustomersByVendorParams,
  getDwollaCustomersByBusiness,
  getDwollaCustomersByVendor,
};
