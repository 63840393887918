import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IBankStatement,
  TBankStatementsSortColumn,
} from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './pending_review_table_item';

interface IBankStatementPendingReviewTableProps {
  businessId: TID
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBankStatementsSortColumn>,
  bankStatements: IBankStatement[],
  onCloseModal: () => void,
}

const BankStatementPendingReviewTable = ({
  businessId,
  query,
  sorting,
  bankStatements,
  onCloseModal,
}: IBankStatementPendingReviewTableProps) => {
  return (
    <Table
      className="banking-transactions-table"
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TBankStatementsSortColumn>
            columnName="name"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Name
          </Table.HCell>
          <Table.HCell<TBankStatementsSortColumn>
            columnName="statement_date"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Statement Date
          </Table.HCell>
          <Table.HCell<TBankStatementsSortColumn>
            columnName="banking_account"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Banking Account
          </Table.HCell>
          <Table.HCell<TBankStatementsSortColumn>
            columnName="ending_balance"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Ending Balance
          </Table.HCell>
          <Table.HCell<TBankStatementsSortColumn>
            className="bank-statement-actions-column"
            widthPercent={ 5 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          bankStatements.map((bankStatement) => (
            <Item
              key={ bankStatement.id }
              bankStatement={ bankStatement }
              businessId={ businessId }
              onCloseModal={ onCloseModal }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(BankStatementPendingReviewTable);
