import React, { useCallback, useMemo } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { currencyLocaleFormatter } from '@src/utils/currency';
import { underscoreKeys } from '@src/utils/transform_keys';

import { canMarkAsPaid } from '@src/components/accounts_payable/invoice_queue/utils';
import Tooltip from '@src/components/ui_v2/tooltip';
import { Cash2Icon } from '@src/components/utils/icomoon';

interface IPossibleMatchProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
}

const PossibleMatch = ({ accountsPayableServiceDocument }: IPossibleMatchProps) => {
  const handlePossibleMatchClick = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:possible_match', [underscoreKeys(accountsPayableServiceDocument)]);
  }, [accountsPayableServiceDocument]);

  const hasPossibleMatch = useMemo(() => {
    return (
      accountsPayableServiceDocument.possibleMatches 
      && accountsPayableServiceDocument.possibleMatches.length > 0 
      && canMarkAsPaid(accountsPayableServiceDocument)
    );
  }, [accountsPayableServiceDocument]);

  const tooltipTitle = useMemo(() => {
    const possibleMatches = accountsPayableServiceDocument.possibleMatches;
    if (!possibleMatches || possibleMatches?.length === 0 || !canMarkAsPaid(accountsPayableServiceDocument)) return undefined;

    const possibleMatch = possibleMatches[0];
    let title = [
      possibleMatch.paymentAccountName,
      possibleMatch.transactionDate,
      currencyLocaleFormatter(possibleMatch.amount),
      possibleMatch.description,
    ].join(' | ');
    if (possibleMatches.length > 1) title += ' and #{possibleMatches.length - 1} more...';

    return title;
  }, [accountsPayableServiceDocument]);

  return (
    <Tooltip.Hover content={ tooltipTitle }>
      { hasPossibleMatch && <Cash2Icon pointer fontSize={ 18 } onClick={ handlePossibleMatchClick } />}
    </Tooltip.Hover>
  );
};

export default React.memo(PossibleMatch);
