import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import {
  DocumentedIcon,
  DocumentIcon,
  NoDocumentNeededIcon,
} from '@src/components/utils/icomoon';

interface ITransactionDocumentIconProps {
  document: ITransactionServiceDocument,
}

const TransactionDocumentIcon = ({
  document,
}: ITransactionDocumentIconProps) => {
  if (document.hasDocuments) {
    return <DocumentedIcon fontSize={ 22 } />;
  }

  if (document.noDocumentRequired) {
    return <NoDocumentNeededIcon fontSize={ 22 } />;
  }

  return <DocumentIcon color="grey" fontSize={ 18 } />;
};

export default React.memo(TransactionDocumentIcon);
