import { string, object } from 'yup';

export interface IAcknowledgeFormValues {
  note?: string,
}

export const noteValidate = (validationRequired: boolean) => object({
  note: string().trim().label('Note')
    .test(
      'required',
      'Add notes to continue',
      (value: string | undefined) => {
        return !validationRequired || (value !== undefined && value.length > 0);
      },
    ),
});
