import * as ld from 'launchdarkly-js-client-sdk';

const checkGlobalFeatureEnabled = (): void => {
  const client: ld.LDClient = ld.initialize(window.configData.launch_darkly.client_side_id, { key: 'global' });

  window.configData.launch_darkly_enabled_global_features = [];
  window.configData.launch_darkly_enabled_features_for_business = [];
  window.configData.launch_darkly_enabled_features_for_user = [];
  window.configData.launch_darkly_enabled_global_btf_service_api_list = [];
  
  client.on('ready', () => {
    // initialization succeeded, flag values are now available
    const reportBTFEnvKey = `reports-btf-service-api-list-${window.configData.env}`
    window.configData.launch_darkly_enabled_global_btf_service_api_list = client.variation(reportBTFEnvKey, []);

    window.configData.launch_darkly_global_features.forEach((globalFeature) => {
      const flagValue = client.variation(globalFeature, true) as boolean;
      const style = document.createElement('style');
      if (flagValue) {
        window.configData.launch_darkly_enabled_global_features.push(globalFeature);
        style.innerHTML = `[data-global-feature-toggle="${globalFeature}"] { display: block !important; }`;
      } else {
        style.innerHTML = `[data-global-feature-toggle="${globalFeature}"] { display: none !important; }`;
      }
      document.head.appendChild(style);
    });
    window.Docyt.initializePendo();
  });
};

export {
  checkGlobalFeatureEnabled,
};
