module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<table class="table table-responsive">\n  <thead>\n    <tr>\n      <th class="font-14 font-bold width-5-percent"></th>\n      <th class="font-14 font-bold relative pointer sortable sorted-desc width-25-percent" data-orderby="client_business" data-orderdir="ASC">Client</th>\n      <th class="font-14 font-bold relative pointer sortable width-15-percent" data-orderby="service_business" data-orderdir="">Client of Business</th>\n      <th class="font-14 font-bold width-25-percent">Assigned Team Members</th>\n      <th class="font-14 font-bold">Business admins</th>\n      <th class="font-14 font-bold" width="2%"></th>\n      <th class="font-14 font-bold" width="3%"></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}