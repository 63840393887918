module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, ref, split;
    
      __out.push('<div class="invoice-right-side-region split-multiple-region">\n  <div class="invoice-split-pan relative p-l-10 p-r-10">\n    ');
    
      ref = this.splits;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        split = ref[index];
        __out.push('\n    <div class="m-b-10 m-r-25 row split-amount-input-row relative p-l-10 p-r-10 display-inline-flex">\n      <div class=\'width-100px m-l-10 m-r-10\'>\n        <input class=\'form-control invoice-date-input\' type=\'text\' placeholder=\'Invoice Date\' autocomplete=\'off\' value=\'');
        __out.push(__sanitize(split.invoice_date));
        __out.push('\'>\n      </div>\n      <div class=\'width-100px m-r-10\'>\n        <input class=\'form-control invoice-number-input\' type=\'text\' placeholder=\'Invoice #\' value=\'');
        __out.push(__sanitize(split.invoice_number));
        __out.push('\'>\n      </div>\n      <div class=\'width-100px m-r-10 amount-input-wrapper relative\'>\n        <input class=\'form-control amount-input p-l-15\' type=\'text\' placeholder=\'Amount\' value=\'');
        __out.push(__sanitize(split.amount));
        __out.push('\'>\n      </div>\n      <div class=\'invoice-split-remove-btn-wrapper\'>\n        <button class="btn blue-plus-btn font-12 remove-invoice-split-btn m-r-5" data-index="');
        __out.push(__sanitize(index));
        __out.push('"  type="button" ');
        __out.push(__sanitize(index < 2 ? 'disabled' : void 0));
        __out.push('>\n          <i class="fa fa-trash"></i>\n        </button>\n      </div>\n    </div>\n    ');
      }
    
      __out.push('\n    <div class="m-b-10 m-r-30 row text-right">\n      <a class="pointer font-14 font-semi-bold add-invoice-split-btn m-r-10">Add row</a>\n    </div>\n    <div class="divider"></div>\n  </div>\n  <div class="container-fluid">\n    <div class=\'font-15 in-brown-700 m-t-5 p-l-15 p-r-50 row\'>\n      <div class=\'col-sm-10 text-right\'>\n        Invoice Total\n      </div>\n      <div class=\'col-sm-2 text-right invoice-total-div font-bold\'>\n        ');
    
      __out.push(__sanitize(this.invoice_total));
    
      __out.push('\n      </div>\n    </div>\n    <div class=\'font-15 in-brown-700 m-t-5 p-l-15 p-r-50 row\'>\n      <div class=\'col-sm-10 text-right\'>\n        Statement Amount\n      </div>\n      <div class=\'col-sm-2 text-right total-amount-div font-bold\'>\n        ');
    
      __out.push(__sanitize(this.total_splits));
    
      __out.push('\n      </div>\n    </div>\n    <div class=\'font-15 in-brown-700 m-t-5 p-l-15 p-r-50 row\'>\n      <div class=\'col-sm-10 text-right\'>\n        Difference\n      </div>\n      <div class=\'col-sm-2 text-right difference-total-div font-bold\'>\n        ');
    
      __out.push(__sanitize(this.difference));
    
      __out.push('\n      </div>\n    </div>\n  </div>\n  <div class="font-15 in-brown-700 m-t-20 p-l-15 p-r-15">\n    <button type="button" class="btn btn-blue split-invoice-btn width-100-percent m-r-15">Continue</button>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}