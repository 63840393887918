import React, { useMemo } from 'react';

import { useGetAccountingFirms } from '@src/hooks/queries/accounting_firms';

import MyPracticeMainView from '@src/components/my_practice/details/my_practice_main_view';
import QueryStatus from '@src/components/utils/query_status';

const MyPracticeDetails = (): JSX.Element => {
  const accountingFirmsQuery = useGetAccountingFirms();

  const accountingFirms = useMemo(() => {
    return accountingFirmsQuery.data?.businesses || [];
  }, [accountingFirmsQuery.data]);

  return (
    <>
      <QueryStatus query={ accountingFirmsQuery } />
      {
        (accountingFirms.length > 0) && <MyPracticeMainView accountingFirms={ accountingFirms } />
      }
    </>
  );
};

export {
  MyPracticeDetails as default,
};
