import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const UncategorizedIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="unread"
    />
  );
};

export default UncategorizedIcon;
