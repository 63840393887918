import React from 'react';

import { NumberOfIntialLoaderItemChatList } from '../../constant';

import styles from '../../styles.module.scss';

const Loader = () => {
  return (
    <div className={ styles['channel-list-skeleton'] }>
      {[...Array(NumberOfIntialLoaderItemChatList)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ i } className={ styles['skeleton-list'] }>
          <div className={ styles['list-line'] } />
          <div className={ styles['list-circle'] } />
        </div>
      ))}
    </div>
  );
};
export default Loader;
