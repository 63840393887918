import React, { useCallback, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBankStatements } from '@src/hooks/queries/bank_statements';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { IBankStatement, TBankStatementsSortColumn } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';

import { useBankStatementDetailsModal } from '../bank_statement_details/details_modal';
import { useBankStatementUploadModal } from '../bank_statement_details/upload_modal';
import BlankPage from './blank_pending_review_page';
import { IBankStatementsFilter } from './filter/types';
import List from './list';

interface IPendingReviewProps {
  businessId: TID,
}

const DefaultSorting: ISortingParams<TBankStatementsSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const PendingReview = ({
  businessId,
}: IPendingReviewProps): JSX.Element => {
  const filter = useFilter<IBankStatementsFilter>({});
  const sorting = useSorting<TBankStatementsSortColumn>({ defaultSorting: DefaultSorting });

  const bankStatementQuery = useGetBankStatements({
    businessId,
    filter: filter.data,
    ...sorting.data,
  });

  const bankStatementPages = useMemo(() => {
    return bankStatementQuery.data?.pages || [];
  }, [bankStatementQuery.data?.pages]);

  const bankStatements = flatten(bankStatementPages.map((p) => p.collection));

  const noFilteredCount = useMemo(() => {
    return bankStatementPages[0]?.meta.noFilteredCount || 0;
  }, [bankStatementPages]);

  const detailModal = useBankStatementDetailsModal();
  const { openWithValue } = detailModal;

  const handleUploadedFile = useCallback((bankStatement?: IBankStatement) => {
    if (bankStatement?.id === undefined) return;

    openWithValue({
      docytId: bankStatement.docytId,
    });
  }, [openWithValue]);

  const uploadModal = useBankStatementUploadModal({ onDone: handleUploadedFile });

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
      />
      <detailModal.Component
        { ...detailModal.props }
        businessId={ businessId }
        onCloseModal={ () => bankStatementQuery.refetch() }
      />
      {
        noFilteredCount === 0 && !bankStatementQuery.isLoading
          ? (
            <BlankPage onUploadClick={ uploadModal.open } />
          ) : (
            <List
              bankStatements={ bankStatements }
              businessId={ businessId }
              filter={ filter }
              query={ bankStatementQuery }
              sorting={ sorting }
              onCloseModal={ () => bankStatementQuery.refetch() }
              onUploadFile={ uploadModal.open }
            />
          )
      }
    </>
  );
};

export default PendingReview;
