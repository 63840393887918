import React, { useCallback } from 'react';

import { useDestroyBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

interface IDeleteRequestStatementActionProps {
  state: IStatementState,
  businessId: TID,
}

const DeleteRequestStatementAction = ({
  state,
  businessId,
}: IDeleteRequestStatementActionProps): JSX.Element => {
  const deleteDocumentRequest = useDestroyBalanceSheetStatement();
  const { mutate } = deleteDocumentRequest;

  const handleConfirm = useCallback(() => {
    if (!state.id) return;

    mutate(
      { businessId, id: state.id },
    );
  }, [businessId, state.id, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <confirmModal.Component
        text="Are you sure want to delete this document request?"
        title="Delete Document Request"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        title="Delete Request"
        onClick={ confirmModal.open }
      />
    </>
  );
};

export default DeleteRequestStatementAction;
