import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ReconciliationSummaryIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="reconciliation-summary"
      pathsNumber={ 4 }
    />
  );
};

export default ReconciliationSummaryIcon;
