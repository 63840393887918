import React from 'react';

import { IFilter, TFilterData } from '@src/types/filter';

import { FilterContext } from './hooks';

interface FilterProviderProps <TFilter extends TFilterData> {
  children: React.ReactNode,
  filter: IFilter<TFilter>,
}

const FilterProvider = <TFilter extends TFilterData>({
  children,
  filter,
}: FilterProviderProps<TFilter>) => {
  return (
    <FilterContext.Provider value={ filter }>
      { children }
    </FilterContext.Provider>
  );
};

export {
  FilterProviderProps,
  FilterProvider as default,
};
