import { useEffect, useLayoutEffect } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';

import { useSideView } from './hooks';
import { TSideViewContent } from './types';

interface ISideViewContentProps {
  children: TSideViewContent,
  isOpen: boolean,
  isRoot?: boolean,
}

const SideViewContent = ({
  children,
  isOpen,
  isRoot,
}: ISideViewContentProps) => {
  const viewId = useUniqueDomId('side_view_');

  const { closeSideView, showSideView } = useSideView();

  useEffect(() => {
    if (isOpen) {
      showSideView({ content: children, id: viewId }, isRoot);
      return;
    }

    closeSideView(viewId);
  }, [isOpen, isRoot, viewId, children, showSideView, closeSideView]);

  useLayoutEffect(() => {
    return () => {
      closeSideView(viewId);
    };
  }, [closeSideView, viewId]);

  return null;
};

export default SideViewContent;
