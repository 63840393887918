import React, { useCallback } from 'react';

import {
  useDestroyFinancialInstitutionConnection,
  useDisconnectFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CrossRedIcon, RemoveFromQueueIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDisconnectActionProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
  status?: string,
}

const DisconnectAction = ({
  financialInstitutionConnection,
  status,
}: IDisconnectActionProps): JSX.Element | null => {
  const destroy = useDestroyFinancialInstitutionConnection();
  const disconnect = useDisconnectFinancialInstitutionConnection();
  const { mutate: destroyFinancialConnection } = destroy;
  const { mutate: disconnectFinancialConnection } = disconnect;

  const handleDone = useCallback(() => {
    if (status === 'inActive') {
      destroyFinancialConnection({ id: financialInstitutionConnection.id });
      return;
    }
    disconnectFinancialConnection({ id: financialInstitutionConnection.id });
  }, [
    status,
    financialInstitutionConnection.id,
    disconnectFinancialConnection,
    destroyFinancialConnection,
  ]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleDone,
  });

  return (
    <>
      <MutationStatus
        mutation={ destroy }
        successMessage={
          status === 'inActive' ? 'Connection removed successfully' : 'Disconnected successfully'
        }
      />
      <confirmModal.Component
        { ...confirmModal.props }
        confirmStyle="primary"
        confirmTitle="Yes"
        text={ (
          <div className="text-left">
            {
              financialInstitutionConnection.isActive
              && financialInstitutionConnection.hasMappedBankAccount && (
                <p>
                  Bank accounts associated with this institution will also get disconnected.
                </p>
              )
            }
            <p>Are you sure you want to continue?</p>
          </div>
        ) }
        title={ status === 'inActive' ? 'Remove Account' : 'Disconnect Account' }
      />
      <ActionsDropdown.Action
        icon={ status === 'inActive' ? <RemoveFromQueueIcon fontSize={ 18 } /> : <CrossRedIcon fontSize={ 18 } /> }
        name={ status === 'inActive' ? 'Remove' : 'Disconnect' }
        onClick={ confirmModal.open }
      />
    </>
  );
};

export default DisconnectAction;
