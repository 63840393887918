import React, { ChangeEvent, ComponentPropsWithoutRef } from 'react';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import { TextInput } from '@src/components/ui_v2/inputs';
import { useRecoilState } from 'recoil';
import { searchColumnValueType } from './atoms';

import Wrapper from './filter_field_wrapper';

interface ITextNormalFiledProps
  extends Omit<ComponentPropsWithoutRef<typeof TextInput>, 'name' | 'value' | 'onChange' | 'className'>,
    IFilterFieldUIProps {
  inputClassName?: string;
  label?: string;
}

const TextFilterField = ({ ...props }: ITextNormalFiledProps) => {
  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const [value, setValue] = useRecoilState(searchColumnValueType);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Wrapper {...wrapperProps}>
      <TextInput value={value || ''} onChange={handleChange} {...inputProps} />
    </Wrapper>
  );
};

export default TextFilterField;
