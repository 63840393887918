import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const NewExpenseReportIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="new-expense-report"
      pathsNumber={ 10 }
    />
  );
};

export default NewExpenseReportIcon;
