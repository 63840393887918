import React, { useCallback } from 'react';

import { useDestroyManagementGroup } from '@src/hooks/queries/management_groups';
import { IManagementGroup } from '@src/types/management_groups';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import useMessageModal from '@src/components/common/message_modal/message_modal';
import ActionsDropdown from '@src/components/ui/actions_dropdown/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '../styles.module.scss';

interface IDeleteManagementGroupActionProps {
  managementGroup: IManagementGroup,
}

const DeleteManagementGroupAction = ({
  managementGroup,
}: IDeleteManagementGroupActionProps) => {
  const deleteManagementGroup = useDestroyManagementGroup();
  const failedMessageModal = useMessageModal();

  const handleConfirm = useCallback(() => {
    if (managementGroup.businesses?.length > 0) {
      failedMessageModal.open();
      return;
    }
    deleteManagementGroup.mutate({ id: managementGroup.id });
  }, [managementGroup, deleteManagementGroup, failedMessageModal]);

  const confirm = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  const handleOpen = useCallback(() => {
    if (managementGroup.businesses?.length > 0) {
      failedMessageModal.open();
    } else {
      confirm.open();
    }
  }, [managementGroup, confirm, failedMessageModal]);

  return (
    <>
      <MutationStatus mutation={ deleteManagementGroup } successMessage="Deleted successfully." />
      <confirm.Component
        { ...confirm.props }
        text={ window.Docyt.Common.Constants.Messages.REMOVE_MANAGEMENT_GROUP_CONFIRM_MSG }
        title="Remove Management Group"
      />
      <failedMessageModal.Component
        { ...failedMessageModal.props }
        title={ `Cannot Delete ${managementGroup.name}` }
      >
        {() => (
          <div className="font-16 in-red">
            <p className="p-b-0">
              { managementGroup.name }
              { ' ' }
              cannot be deleted because the following businesses are assigned to it:
            </p>
            <ul className={ styles['group-businesses-list'] }>
              {
                managementGroup && managementGroup.businesses.map((business) => (
                  <li key={ business.id }>
                    {' '}
                    { business.name }
                    {' '}
                  </li>
                ))
              }
            </ul>
            <p className="p-b-0">
              Assign the above businesses to another management group to delete
              { ' ' }
              { managementGroup.name }
              .
            </p>
          </div>
        )}
      </failedMessageModal.Component>
      <ActionsDropdown.DangerAction
        name="Delete"
        onClick={ handleOpen }
      />
    </>
  );
};

export default DeleteManagementGroupAction;
