import React from 'react';

import { SelectItemCheckbox } from '@src/components/utils/items_selector';

import Cell, { ITableCellProps } from './cell';

interface ISelectTableItemCellProps<Type> extends Omit<ITableCellProps, 'children'> {
  item: Type,
  checkedLabel?: React.ReactNode,
  uncheckedLabel?: React.ReactNode,
  inputProps?: Omit<React.ComponentPropsWithoutRef<typeof SelectItemCheckbox>, 'item'>,
}

/**
 * @visibleName Table.SelectItemCell
 */
const SelectTableItemCell = <Type, >({
  item,
  checkedLabel,
  uncheckedLabel,
  inputProps = {},
  ...cellProps
}: ISelectTableItemCellProps<Type>) => {
  return (
    <Cell { ...cellProps }>
      <SelectItemCheckbox
        item={ item }
        { ...inputProps }
        checkedLabel={ checkedLabel }
        uncheckedLabel={ uncheckedLabel }
      />
    </Cell>
  );
};

export default React.memo(SelectTableItemCell);
