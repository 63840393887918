import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

interface IFieldWithTooltipWrapperProps extends
  React.ComponentPropsWithoutRef<typeof Tooltip.Hover>, IUIStyleProps
{
  children: React.ReactNode,
  showTooltip?: boolean,
  tooltipLabel?: string,
  tooltip: React.ReactNode | undefined,
}

const FieldWithTooltipWrapper = ({
  children,
  showTooltip,
  tooltip,
  tooltipLabel,
  ...props
}: IFieldWithTooltipWrapperProps): JSX.Element => {
  const [classes, tooltipProps] = uiStyleProps(styles['filter-group'], props);

  return (
    <Tooltip.Hover
      isControl
      className={ classes }
      content={ tooltip }
      label={ tooltipLabel }
      showTooltip={ showTooltip }
      { ...tooltipProps }
    >
      { children }
    </Tooltip.Hover>
  );
};

const MemoizedFieldWithTooltipWrapper = React.memo(FieldWithTooltipWrapper);

export {
  IFieldWithTooltipWrapperProps,
  MemoizedFieldWithTooltipWrapper as default,
};
