module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="m-t-20 m-b-20">\n    <img src="');
    
      __out.push(__sanitize(configData.images['referral']));
    
      __out.push('" width="300px" height="300px">\n</div>\n<h3 class="font-semi-bold font-24 m-b-20">Pay it forward and get rewarded</h3>\n<p>Earn up to $200 for each friend you refer to Docyt.<br/>There\'s no limit to how much you can earn.</p>\n<p>We\'ll also give your friend their first month free!<br/>You\'ll earn the referral bonus after they\'ve used Docyt for two months.</p>\n<p>Invite your friends to sign up for a demo, and ask them to mention your name.</p>\n<h5 class="m-t-30 font-semi-bold font-16">Referral link:</h5>\n<div class="copy-referral">\n    <a class="referral-text font-16">https://www.docyt.com/referral</a>\n    <a class="copy-link">Copy Link</a>\n</div>\n<p class="m-t-30 m-b-10 font-12 in-gray-important">Disclaimer: Referral compensation varies based on the plan purchased and number of entities. This is a limited time offer and subject to change.</p>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}