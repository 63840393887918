import React from 'react';

import { TID } from '@src/types/common';

import AccountsPayableServiceProvider from '@src/components/providers/accounts_payable_service_provider';
import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import InvoiceQueueRouter from './invoice_queue/router';

interface IInvoiceQueuePageProps {
  businessId: TID,
}

const InvoiceQueuePage = ({
  businessId,
}: IInvoiceQueuePageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <AccountsPayableServiceProvider businessId={ businessId }>
          <InvoiceQueueRouter />
        </AccountsPayableServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default InvoiceQueuePage;
