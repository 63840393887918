module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=right-side-title-view>\n  ');
    
      if (this.type === Docyt.Common.Constants.TRANSACTION_TYPES['FLAGGED_TYPE']) {
        __out.push('\n    <div class="display-flex">\n      <div class="m-t-35 p-l-100 m-l-auto m-r-auto">\n        <ul class="nav sub-header-menu">\n          <li class="m-r-25 ');
        __out.push(__sanitize(this.activeTab === 'data' ? 'active' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.dataTabVisible ? '' : 'hidden'));
        __out.push('"><a class="pointer header-item" data-id="data">DATA</a></li>\n          <li class="m-r-25 ');
        __out.push(__sanitize(this.activeTab === 'chat' ? 'active' : ''));
        __out.push('"><a class="pointer header-item" data-id="chat">CHAT</a></li>\n        </ul>\n      </div>\n      <a id="close-document"><span class="pointer icon-not-a-chargeback-icon font-25 m-r-20 m-t-45 pull-right"></span></a>\n    </div>\n  ');
      } else {
        __out.push('\n    <div class="clearfix">\n      <div class="display-flex align-items-center font-bold font-20 pull-left m-l-20 m-t-45">\n        <span class=\'m-r-10\'>');
        __out.push(__sanitize(this.title));
        __out.push('</span>\n        ');
        if (this.type === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE'] && this.isSingleDocument) {
          __out.push('\n          ');
          if (this.hasMatched) {
            __out.push('\n            <span class="transaction-status-panel font-bold verified text-nowrap">Matched</span>\n          ');
          } else {
            __out.push('\n            <span class="transaction-status-panel font-bold unmatched text-nowrap">Unmatched</span>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n      <a id="close-document"><span class="pointer icon-not-a-chargeback-icon font-25 m-r-20 m-t-45 pull-right"></span></a>\n    </div>\n    ');
        if (this.isSingleDocument) {
          __out.push('\n      <div class="m-l-auto m-r-auto clearfix">\n        <ul class="nav sub-header-menu">\n          <li class="m-r-25 ');
          __out.push(__sanitize(this.activeTab === 'data' ? 'active' : ''));
          __out.push('"><a class="pointer header-item" data-id="data">DATA</a></li>\n          <li class="m-r-25 ');
          __out.push(__sanitize(this.activeTab === 'chat' ? 'active' : ''));
          __out.push('"><a class="pointer header-item" data-id="chat">CHAT</a></li>\n        </ul>\n      </div>\n    ');
        }
        __out.push('\n  </div>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}