import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ITableSectionProps extends React.ComponentPropsWithoutRef<'section'> {
  caption?: string,
}

const TableSection = ({
  children,
  className,
  ...props
}: ITableSectionProps) => {
  const classes = classNames(styles['table-section'], className);

  return (
    <section className={ classes } { ...props }>
      { children }
    </section>
  );
};

export default TableSection;
