import React, { useCallback } from 'react';

interface IDropdownSubmenuProps extends Omit<React.ComponentPropsWithoutRef<'li'>, 'title'> {
  onSelect?: () => void;
  title: React.ReactNode,
}

const DropdownSubmenu = ({
  children,
  onSelect,
  title,
}: IDropdownSubmenuProps) => {
  const handleClick = useCallback((e) => {
    e.preventDefault();

    if (onSelect) onSelect();
  }, [onSelect]);

  return (
    <li className="dropdown-submenu">
      <a role="button" tabIndex={ -1 } type="button" onClick={ handleClick }>
        <div className="nowrap ">
          { title }
        </div>
      </a>
      { children }
    </li>
  );
};

export default DropdownSubmenu;
