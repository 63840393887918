import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';

import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmDeleteDocumentsModalParams extends IUseModalParams {
  documentIds: number[],
}

interface IUseConfirmDeleteDocumentsModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
}

const ConfirmDeleteDocumentsTitle = ({ documentIds }: { documentIds: number[] }) => (
  <>
    { documentIds.length }
    { ' ' }
    documents will be moved to Trash.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmDeleteDocumentsModal = ({
  documentIds,
  ...modalParams
}: IUseConfirmDeleteDocumentsModalParams): IUseConfirmDeleteDocumentsModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'danger',
      confirmTitle: 'Delete',
      icon:         <DeleteDocumentIcon />,
      text:         <ConfirmDeleteDocumentsTitle documentIds={ documentIds } />,
      title:        `Delete ${documentIds.length} Documents?`,
    },
  };
};

export {
  useConfirmDeleteDocumentsModal,
};
