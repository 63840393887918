module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1;
    
      __out.push('<header class="header header--in">\n  <div class="header-text">\n    ');
    
      if (this.prevMonth) {
        __out.push('\n      <a href="/businesses/');
        __out.push(__sanitize(this.business_id));
        __out.push('/reconciliation_center/month_end_reconciliation?year=');
        __out.push(__sanitize(this.prevYear));
        __out.push('&month=');
        __out.push(__sanitize(this.prevMonth));
        __out.push('&pa=');
        __out.push(__sanitize(sessionStorage.getItem('pa_id')));
        __out.push('" class="m-t-2">\n        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n          <path d="M8.375 1.25L1.625 8L8.375 14.75" stroke="#242B33" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        </svg>\n      </a>\n    ');
      } else {
        __out.push('\n      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n        <path d="M8.375 1.25L1.625 8L8.375 14.75" stroke="#C0C6CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n      </svg>\n    ');
      }
    
      __out.push('\n    <span class="m-l-30 m-r-30 font-semi-bold font-20">\n      ');
    
      __out.push(__sanitize(this.MONTH_FULL_NAMES[this.curMonth - 1]));
    
      __out.push('\n      ');
    
      __out.push(__sanitize(this.curYear));
    
      __out.push('\n    </span>\n    ');
    
      if (this.nextMonth) {
        __out.push('\n      <a href="/businesses/');
        __out.push(__sanitize(this.business_id));
        __out.push('/reconciliation_center/month_end_reconciliation?year=');
        __out.push(__sanitize(this.nextYear));
        __out.push('&month=');
        __out.push(__sanitize(this.nextMonth));
        __out.push('&pa=');
        __out.push(__sanitize(sessionStorage.getItem('pa_id')));
        __out.push('" class="m-t-2">\n        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n          <path d="M1.625 1.25L8.375 8L1.625 14.75" stroke="#242B33" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        </svg>\n      </a>\n    ');
      } else {
        __out.push('\n      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n          <path d="M1.625 1.25L8.375 8L1.625 14.75" stroke="#C0C6CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n      </svg>\n    ');
      }
    
      __out.push('\n  </div>\n\n  ');
    
      if (['in-progress', 'broken'].includes((ref = this.last_status) != null ? ref.status : void 0)) {
        __out.push('\n    <div class="pull-right" style="background-color: rgba(255, 149, 0, 0.12); color: #F39201; border-radius: 5px; padding: 4px 8px; font-weight: 600; margin-top: -4px; margin-right: 16px;">In progress</div>\n  ');
      } else if (((ref1 = this.last_status) != null ? ref1.status : void 0) === 'reconciled') {
        __out.push('\n    <div class="pull-right" style="background-color: rgba(0, 184, 135, 0.12); color: #00B887; border-radius: 5px; padding: 4px 8px; font-weight: 600; margin-top: -4px; margin-right: 16px;">Complete</div>\n  ');
      }
    
      __out.push('\n\n  <div class="header-action pull-right">\n    <a class="pointer pull-right m-t-5 m-l-10 close-individual-page-btn"><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}