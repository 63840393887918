import React, { useState } from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import ManagementGroupInput from '../common_v2/inputs/management_group_input';

type onChangeParam = TID | null | undefined

interface ManagementGroupInputWrapperProps {
	onChange: (value?: onChangeParam) => void,
}

const ManagementGroupsDropdownItem = ({ onChange }: ManagementGroupInputWrapperProps) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);

  const handleOnChange = (value: onChangeParam) => {
    setSelectedValue(value || 0);
    onChange(value);
  };

  return (
    <Root>
      <ManagementGroupInput value={ selectedValue } onChange={ handleOnChange } />
    </Root>
  );
};

export default React.memo(ManagementGroupsDropdownItem);
