import { createContext, useContext } from 'react';

import { IPopover } from '@src/hooks/popover';

const DropdownContext = createContext<IPopover | null>(null);

const useDropdown = (): IPopover | null => {
  return useContext(DropdownContext);
};
export {
  DropdownContext,
  useDropdown,
};
