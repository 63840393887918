import React, { useCallback } from 'react';

import { relatedDocumentPath } from '@src/routes';
import { IRelatedDocument, TRelatedDocumentsSortColumn } from '@src/types/related_documents';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

interface IRelatedDocumentsTableProps {
  records: IRelatedDocument[],
  sorting: ISorting<TRelatedDocumentsSortColumn>,
}

const RelatedDocumentsTable = ({
  records,
  sorting,
}: IRelatedDocumentsTableProps) => {
  const handleRowClick = useCallback((relatedDocument: IRelatedDocument) => {
    Backbone.history.navigate(
      relatedDocumentPath(relatedDocument.documentRelative.docytId),
      { trigger: true },
    );
  }, []);

  return (
    <CollectionTable<
      IRelatedDocument,
      TRelatedDocumentsSortColumn
    >
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.TextColumn<IRelatedDocument>
        name="docyt_id"
        title="Document ID"
        value={ (document) => document.documentRelative.docytId }
        width="120px"
      />
      <CollectionTable.TextColumn<IRelatedDocument>
        name="business_name"
        title="Business"
        value={ (document) => document.relativeBusinessName }
      />
      <CollectionTable.AmountColumn<IRelatedDocument>
        name="invoice_amount"
        title="Amount"
        value={ (document) => document.relativeDocumentAmount || '-' }
        width="150px"
      />
    </CollectionTable>
  );
};

export default React.memo(RelatedDocumentsTable);
