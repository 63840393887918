import React, { useCallback, useMemo } from 'react';

import flatMapDeep from 'lodash/flatMapDeep';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import {
  DropdownInput,
  TOption,
  formatSelectOption,
} from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface IDropdownFilterFieldProps<TFilter extends TFilterData> extends
  Omit<React.ComponentPropsWithoutRef<typeof DropdownInput>, 'value' | 'onChange' | 'className'>,
  IFilterFieldUIProps
{
  label?: string,
  name: keyof TFilter,
}

const formatTooltip = (option: TOption | undefined): JSX.Element | null => {
  if (!option) return null;

  return formatSelectOption(option);
};

const DropdownFilterField = <TFilter extends TFilterData>({
  label,
  name,
  options,
  ...props
}: IDropdownFilterFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedOption: TOption | null) => {
    update(selectedOption?.value || undefined);
  }, [update]);

  const currentOption = useMemo(() => {
    if (!value) return undefined;

    return flatMapDeep(options, (opt) => {
      if (opt.children) return opt.children;

      return opt;
    }).find((o) => o.value === value) as TOption;
  }, [value, options]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption) }
      tooltipLabel={ label && `${label}: ` }
    >
      <DropdownInput
        { ...inputProps }
        options={ options }
        value={ currentOption || null }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(DropdownFilterField);
