import React from 'react';

import { IBusiness } from '@src/types/businesses';

import { useCopyMappingModal } from '@src/components/business_reports/custom_reports/edit_mapping_actions/copy_mapping/copy_mapping_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import { IEditMappingLaborProps } from '../../hooks';

const BusinessReportsEditMappingCopyMappingAction = ({ data }: IEditMappingLaborProps) => {
  const modal = useCopyMappingModal();

  return (
    <>
      <modal.Component
        { ...modal.props }
        currentBusiness={ data.business as IBusiness }
        tarReportId={ data.report.id as string }
        templateId={ data.report.templateId as string }
      />
      <ActionsDropdown.Action
        title="Copy mapping from other Business"
        onSelect={ modal.open }
      />
    </>
  );
};

export default React.memo(BusinessReportsEditMappingCopyMappingAction);
