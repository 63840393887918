import { object, string, number } from 'yup';

import { TID } from '@src/types/common';

interface IMarkAsPaidFormValues {
  paymentAccountId: TID,
  estimatedDate: string,
  balanceChartOfAccountId: TID,
  amount: string,
  memo: string,
}

const PaybyCashValidation = object({
  estimatedDate: string().required('Estimated Date is required.'),
});

const PaybyBankAccountValidation = object({
  paymentAccountId: number().required('Payment Account is required.'),
  estimatedDate:    string().required('Estimated Date is required.'),
});

const PaybyAdvancedSettlementValidation = object({
  estimatedDate:           string().required('Date is required.'),
  balanceChartOfAccountId: number().required('Balance Sheet Account is required.'),
});

export {
  IMarkAsPaidFormValues,
  PaybyCashValidation,
  PaybyBankAccountValidation,
  PaybyAdvancedSettlementValidation,
};
