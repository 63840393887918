module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business_admin, business_note, i, j, len, len1, ref, ref1;
    
      __out.push('<div class="document-detail-sidebar-section-header">\n  <span class="font-bold">INFO</span>\n  <a href="');
    
      __out.push(__sanitize(this.profile_link));
    
      __out.push('" class="display-flex align-items-center pointer profile-link-js">\n    <span class="icon-edit_account font-15"></span>\n    <span class="m-l-8">View Full Profile</span>\n  </a>\n</div>\n<div class="p-l-12 p-r-10 background-white">\n  <div class="table-responsive no-border">\n    <table class="table">\n      <tbody>\n        <tr>\n          <td width="140px">Display Name</td>\n          <td>');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('</di>\n        </tr>\n        <tr>\n          <td width="140px">Business Name</td>\n          <td>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</di>\n        </tr>\n        <tr>\n          <td width="140px">Email</td>\n          <td>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</di>\n        </tr>\n        <tr>\n          <td width="140px">Phone Number</td>\n          <td>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</di>\n        </tr>\n        <tr>\n          <td width="140px">Address</td>\n          <td>');
    
      __out.push(__sanitize(this.address_street));
    
      if (this.address_street_2) {
        __out.push(__sanitize(', '));
      }
    
      __out.push(__sanitize(this.address_street_2));
    
      __out.push(__sanitize(', ' + this.address_city));
    
      if (this.address_state) {
        __out.push(__sanitize(', '));
      }
    
      __out.push(__sanitize(this.address_state));
    
      if (this.address_zip) {
        __out.push(__sanitize(', '));
      }
    
      __out.push(__sanitize(this.address_zip));
    
      if (this.address_country) {
        __out.push(__sanitize(', '));
      }
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('</td>\n        </tr>\n      </tbody>\n    </table>\n  </div>\n</div>\n<div class="document-detail-sidebar-section-header">\n  <span class="font-bold">BUSINESS ADMINS</span>\n</div>\n<div class="p-l-12 p-r-10 background-white">\n  <div class="table-responsive no-border">\n    <table class="table">\n      <tbody>\n        ');
    
      ref = this.business_admins;
      for (i = 0, len = ref.length; i < len; i++) {
        business_admin = ref[i];
        __out.push('\n        <tr>\n          <td>\n            <div class="display-flex align-items-center">\n              <span class="avatar-wrapper ');
        if (!business_admin.user.avatar || !business_admin.user.avatar.image_url) {
          __out.push(__sanitize('icon-wrapper'));
        }
        __out.push('">\n                ');
        if (business_admin.user.avatar && business_admin.user.avatar.image_url) {
          __out.push('\n                <img src="');
          __out.push(__sanitize(business_admin.user.avatar.image_url));
          __out.push('" class="service-icon-font"/>\n                ');
        } else {
          __out.push('\n                <i class="icon icon-smile in-blue-400 font-26"></i>\n                ');
        }
        __out.push('\n              </span>\n              <span class="m-l-14">');
        __out.push(__sanitize(business_admin.user.parsed_fullname));
        __out.push('</span>\n            </div>\n          </td>\n        </tr>\n        ');
      }
    
      __out.push('\n      </tbody>\n    </table>\n  </div>\n</div>\n<div class="document-detail-sidebar-section-header">\n  <span class="font-bold">CLIENT NOTES</span>\n  <a class="display-flex align-items-center pointer new-note-js">\n    <i class="icon icon-plus"></i>\n    <span class="m-l-5">New Note</span>\n  </a>\n</div>\n<div class="client-notes-view p-l-12 p-r-10">\n  <div class="business-note-add-wrapper hidden">\n    <textarea class="form-control edit-note-area" placeholder="Add notes"></textarea>\n    <div class="note-action-wrapper">\n      <button type="button" class="font-18 cancel">Cancel</button>\n      <button type=\'button\' class="btn btn-info ladda-button btn-save-note" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n    </div>\n  </div>\n  <div class="table-responsive">\n    <table class="table">\n      <tbody>\n        ');
    
      ref1 = this.business_notes;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        business_note = ref1[j];
        __out.push('\n        <tr>\n          <td>\n            <p>');
        __out.push(this.textForNote(business_note));
        __out.push('</p>\n            <span class="business-note-date"><span>');
        __out.push(__sanitize(this.dateForNote(business_note)));
        __out.push('</span>  |  <span>');
        __out.push(__sanitize(business_note.user_fullname));
        __out.push('</span></span>\n          </td>\n          <td width="50px">\n            <div class="dropdown-wrapper relative">\n              <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="business-note-');
        __out.push(__sanitize(business_note.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n                <i class=\'icon icon-3-dots font-20\'></i>\n              </button>\n              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="business-note-');
        __out.push(__sanitize(business_note.id));
        __out.push('-action-dropdown-btn">\n                <li class="dropdown-item edit-note-js" data-note-id="');
        __out.push(__sanitize(business_note.id));
        __out.push('">\n                  <i class="icon icon-edit font-16 m-r-10"></i>\n                  <span>Edit Note</span>\n                </li>\n                <li class="dropdown-item delete-note-js" data-note-id="');
        __out.push(__sanitize(business_note.id));
        __out.push('">\n                  <i class="icon icon-trashcan in-red-400 font-16 m-r-14"></i>\n                  <span class="in-red-400">Delete Note</span>\n                </li>\n              </ul>\n            </div>\n          </td>\n        </tr>\n        ');
      }
    
      __out.push('\n      </tbody>\n    </table>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}