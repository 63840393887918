import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateAddressBook, useSuggestedAddressBook } from '@src/hooks/queries/vendor_address_book';
import {
  ISugestedAddressResponse,
} from '@src/requests/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import AddressBookModalForm from '../form/address_book_form';
import { IUpdateAddressBookInput } from '../form/schema';

import { useConfirmAddressBookModal } from '.';

import styles from './styles.module.scss';

interface IAddressEditProps extends IUseModalProps {
  vendor: IBusinessVendor;
  businessId: number;
  formId: string;
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  address: IAddress;
  isEdit?: boolean;
  addressInput?: IUpdateAddressBookInput,
}

const AddressEditBookModal = ({
  formId,
  vendor,
  businessId,
  isOpen,
  onCancel,
  onDone,
  setVendorData,
  address,
  isEdit = false,
  addressInput,
}: IAddressEditProps) => {
  const queryClient = useQueryClient();
  const updateAddressBook = useUpdateAddressBook();
  const suggestedAddressBook = useSuggestedAddressBook();
  const confirmAddressBookModal = useConfirmAddressBookModal();

  const { mutate: updateAddress } = updateAddressBook;
  const { mutate: suggestedAddress } = suggestedAddressBook;

  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [addressBookInput, setAddressBookInput] = useState<IUpdateAddressBookInput
  | null>(null);
  const [suggestedAddressResponse, setSuggestedAddressResponse] = useState<ISugestedAddressResponse
  | null>(null);
  const [isEditAddress, setIsEditAddress] = useState<boolean>(isEdit);
  const [isAddAddressModal, setisAddAddressModal] = useState<boolean | undefined>(undefined);

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleUpdateAddress = useCallback(
    (data: IUpdateAddressBookInput) => {
      setErrorMessage(undefined);
      setAddressBookInput(data);
      setIsEditAddress(false);
      setisAddAddressModal(false);
      suggestedAddress(
        {
          ...data,
          businessId,
          vendorId: vendor.id,
        },
        {
          onSuccess: (response) => {
            setSuggestedAddressResponse(response);
            if (response.deliverability === 'deliverable') {
              updateAddress(
                {
                  ...data,
                  businessId,
                  vendorId:  vendor.id,
                  addressId: address.id,
                },
                {
                  onSuccess: (editResponse) => {
                    queryClient.invalidateQueries(QueryKey.vendorAddresses);

                    const newAddress = editResponse.address;
                    const updatedVendor = {
                      ...vendor,
                      addresses: [...(vendor.addresses || []), newAddress],
                    };

                    setVendorData(updatedVendor);
                    setSuccessMessage('Address has been successfully updated.');
                    onDone();
                  },
                  onError: handleError,
                },
              );
              onDone();
            } else {
              onDone();
              confirmAddressBookModal.open();
            }
          },
          onError: handleError,
        },
      );
    },
    [updateAddress, businessId, vendor, handleError, queryClient,
      setVendorData, onDone, address, confirmAddressBookModal, suggestedAddress],
  );

  return (
    <>
      {(updateAddressBook.isLoading) && <Spinner />}
      {(suggestedAddressBook.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Cannot Authorize Address" onHidden={ () => setErrorMessage(undefined) } />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      )}
      { !isEditAddress && (
        <confirmAddressBookModal.Component
          setVendorData={ setVendorData }
          title={ (
            <p className={ styles['modal-title'] }>
              Confirm Address
            </p>
        ) }
          { ...confirmAddressBookModal.props }
          address={ address }
          addressInput={ addressBookInput as IUpdateAddressBookInput }
          isAddAddressModal={ isAddAddressModal }
          suggestedAddress={ suggestedAddressResponse as ISugestedAddressResponse }
          vendor={ vendor }
        />
      )}
      <Modal
        className={ styles['modal-address-book'] }
        show={ isOpen }
        title={ (
          <p className={ styles['vendor-profile-modal-title'] }>
            Edit Address
          </p>
        ) }
      >
        <Modal.Body>
          <AddressBookModalForm
            address={ address }
            addressInput={ addressInput }
            businessId={ businessId }
            formId={ formId }
            setIsFormValid={ setIsFormValid }
            vendorId={ vendor.id }
            onSubmit={ handleUpdateAddress }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            disabled={ !isFormValid }
            form={ formId }
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useAddressEditBookModal = makeUseModal(AddressEditBookModal);

export {
  IAddressEditProps,
  useAddressEditBookModal,
  AddressEditBookModal as default,
};
