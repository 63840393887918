module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, len, len1, paymentProcessor, rcPaymentAccount, ref, ref1;
    
      __out.push('<div class="right-side-condition-view">\n  <div class="view-title font-bold m-t-10">CONDITIONS</div>\n  <div class="document-fields">\n    <div class="field-input-container m-t-10">\n      <div class="document-field-item document-field-name m-t-7">Banking Account</div>\n      <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n        <select id="payment-method" class="form-control selectpicker" title="Select Banking Account">\n          <option value="all" ');
    
      if (!this.rule_condition.reconciliation_payment_account_id) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>All Banking Accounts</option>\n          ');
    
      ref = this.rcPaymentAccounts;
      for (i = 0, len = ref.length; i < len; i++) {
        rcPaymentAccount = ref[i];
        __out.push('\n          <option value="');
        __out.push(__sanitize(rcPaymentAccount.id));
        __out.push('" ');
        __out.push(__sanitize(this.rule_condition.reconciliation_payment_account_id === rcPaymentAccount.id ? 'selected' : ''));
        __out.push('>');
        __out.push(__sanitize(rcPaymentAccount.get('name')));
        __out.push('</option>\n          ');
      }
    
      __out.push('\n        </select>\n      </div>\n    </div>\n    <hr class="m-t-10 m-b-10"/>\n    <div class="field-input-container m-t-10">\n      <div class="document-field-item document-field-name m-t-7">Transaction Type</div>\n      <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n        <select id="transaction-type" class="form-control selectpicker" title="Select Transaction">\n          <option value="all" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'all' ? 'selected' : ''));
    
      __out.push('>All Types</option>\n          <option value="transfer" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'transfer' ? 'selected' : ''));
    
      __out.push('>Bank Transfer</option>\n          <option value="expense" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'expense' ? 'selected' : ''));
    
      __out.push('>Expense</option>\n          <option value="payroll" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'payroll' ? 'selected' : ''));
    
      __out.push('>Payroll Charges</option>\n          <option value="chargeback" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'chargeback' ? 'selected' : ''));
    
      __out.push('>Chargeback</option>\n          <option value="revenue_reversal" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'revenue_reversal' ? 'selected' : ''));
    
      __out.push('>Revenue Reversal</option>\n          <option value="customer_claims" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'customer_claims' ? 'selected' : ''));
    
      __out.push('>Customer Claims</option>\n          <option value="equity_withdrawal" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'equity_withdrawal' ? 'selected' : ''));
    
      __out.push('>Equity Withdrawal</option>\n          <option value="asset_purchase" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'asset_purchase' ? 'selected' : ''));
    
      __out.push('>Asset Withdrawals</option>\n          <option value="liability_payments" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'liability_payments' ? 'selected' : ''));
    
      __out.push('>Liability Payments</option>\n          <option value="loan_payments" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'loan_payments' ? 'selected' : ''));
    
      __out.push('>Loan Payments</option>\n          <option value="revenue" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'revenue' ? 'selected' : ''));
    
      __out.push('>Income</option>\n          <option value="refund" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'refund' ? 'selected' : ''));
    
      __out.push('>Vendor Refunds</option>\n          <option value="other_income" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'other_income' ? 'selected' : ''));
    
      __out.push('>Other Income</option>\n          <option value="equity_investment" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'equity_investment' ? 'selected' : ''));
    
      __out.push('>Equity Investment</option>\n          <option value="loan_proceeds" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'loan_proceeds' ? 'selected' : ''));
    
      __out.push('>Loan Proceeds</option>\n          <option value="other_receivables" ');
    
      __out.push(__sanitize(this.rule_condition.transaction_type === 'other_receivables' ? 'selected' : ''));
    
      __out.push('>Other Receivables</option>\n        </select>\n      </div>\n    </div>\n    ');
    
      if (this.paymentProcessorAvailable) {
        __out.push('\n      <div class="field-input-container m-t-10">\n        <hr class="m-t-10 m-b-10"/>\n        <div class="document-field-item document-field-name m-t-7">Payment Processor</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n          <select id="payment-processor" class="form-control selectpicker" title="Select Payment Processor">\n            <option value="all" ');
        __out.push(__sanitize(this.payment_processor === 'all' ? 'selected' : ''));
        __out.push('>All Payment Processors</option>\n            ');
        ref1 = this.globalPaymentProcessors;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          paymentProcessor = ref1[j];
          __out.push('\n            <option value= ');
          __out.push(__sanitize(paymentProcessor.id));
          __out.push(' ');
          __out.push(__sanitize(this.payment_processor === paymentProcessor.id ? 'selected' : ''));
          __out.push('>');
          __out.push(__sanitize(paymentProcessor.name));
          __out.push('</option>\n            ');
        }
        __out.push('\n          </select>\n        </div>\n      </div>\n    ');
      }
    
      __out.push('\n    <div class="field-input-container m-t-10">\n      <div class="document-field-item document-field-name m-t-7">Vendor</div>\n      <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n        <input type="text" class="form-control input-vendor typeahead" id="transactionVendor" value="');
    
      __out.push(__sanitize(this.vendor ? this.vendor.name : ''));
    
      __out.push('" placeholder="Any Vendor" autocomplete="off"/>\n      </div>\n    </div>\n    <hr class="m-t-10 m-b-10"/>\n    <div class="field-input-container m-t-10">\n      <div class="document-field-item document-field-name m-t-7">has</div>\n      <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n        <select id="condition-type" class="form-control selectpicker" title="Select Condition">\n          <option value="less" ');
    
      __out.push(__sanitize(this.rule_condition.amount_limit === 'less' ? 'selected' : ''));
    
      __out.push('>Amount Less than</option>\n          <option value="equal" ');
    
      __out.push(__sanitize(this.rule_condition.amount_limit === 'equal' ? 'selected' : ''));
    
      __out.push('>Amount Equals to</option>\n          <option value="greater" ');
    
      __out.push(__sanitize(this.rule_condition.amount_limit === 'greater' ? 'selected' : ''));
    
      __out.push('>Amount Greater than</option>\n          <option value="any" ');
    
      __out.push(__sanitize(this.rule_condition.amount_limit === 'any' ? 'selected' : ''));
    
      __out.push('>Any Transaction</option>\n        </select>\n      </div>\n    </div>\n    <div class="field-input-container m-t-10">\n      <div class="document-field-item document-field-name m-t-7"></div>\n      <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n        <input type="text" class="form-control" id="amount" value="');
    
      if (this.rule_condition.amount) {
        __out.push(__sanitize(this.rule_condition.amount));
      }
    
      __out.push('" placeholder="Enter Amount" autocomplete="off"/>\n        <span>Use positive amounts for all types of transactions</span>\n      </div>\n    </div>\n    ');
    
      if (this.availableCategory) {
        __out.push('\n      ');
        if (this.editingCategory) {
          __out.push('\n        <div class="field-input-container m-t-10">\n          <div class="document-field-item document-field-name m-t-7">Category</div>\n          <div class="document-field-item document-field-value category-typeahead-wrapper">\n            <input type="text" class="form-control input-category typeahead" value="');
          __out.push(__sanitize(this.chart_of_account ? this.chart_of_account.display_name : ''));
          __out.push('" placeholder="Any Category" autocomplete="off"/>\n          </div>\n        </div>\n      ');
        } else {
          __out.push('\n        <span class="font-15 pointer in-blue-400 inline-block p-l-20 add-condition-js m-t-10"><span class="icon-plus-circle m-r-18"></span>Add another condition</span>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}