const convertTime = (minutes: number) => {
  const hours = minutes / 60;
  if (hours < 1) {
    return `${minutes} minutes`;
  } if (hours < 8) {
    const remainingHour = Number(Math.floor(hours).toFixed(0));
    const remainingMins = Number((minutes % 60).toFixed(0));
    return `${remainingHour} hour${remainingHour > 1 ? 's' : ''} ${remainingMins} min${remainingMins > 1 ? 's' : ''}`;
  }
  const days = Math.floor(hours / 8);
  const remainingHours = Number((hours % 8).toFixed(0));
  return days === 0
    ? `${remainingHours} hours`
    : `${days} day${days > 1 ? 's' : ''} ${remainingHours > 0 ? `${remainingHours} hours` : ''}`;
};

export { convertTime };
