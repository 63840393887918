import React from 'react';

import { IStatementState } from '@src/types/balance_sheet_statements';
import { formatDate } from '@src/utils/date_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  MissingStatementIcon,
  StatementUploadedIcon,
} from '@src/components/utils/icomoon';
import ReconciledIcon from '@src/components/utils/icomoon/reconciled';
import WaitingForClientIcon from '@src/components/utils/icomoon/waiting-for-client';

import styles from '../../styles.module.scss';

interface IVerifiedStatusItemProps {
  state: IStatementState,
  onClickItem: () => void;
}

const VerifiedStatusItem = ({
  state,
  onClickItem,
}: IVerifiedStatusItemProps) => {
  if (!state.docytId) return null;

  const verifiedTooltip = (
    <div className="approved-status">
      <div className={ styles['state-status'] }>
        <div className={ styles['state-info'] }>
          <hr className={ styles['missing-requested-progress-line'] } />
          <MissingStatementIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className="font-12">Document missing</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['requested-uploaded-progress-green-line'] } />
          <WaitingForClientIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.requestedAt ? formatDate(state.requestedAt) : '--'}</span>
          <span className="font-12">Document requested</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-verified-progress-green-line'] } />
          <StatementUploadedIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.uploadedAt ? formatDate(state.uploadedAt) : '--'}</span>
          <span className="font-12">Document uploaded</span>
        </div>
        <div className={ styles['state-info'] }>
          <ReconciledIcon fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.verifiedAt ? formatDate(state.verifiedAt) : '--'}</span>
          <span className="font-12">Document verified</span>
        </div>
      </div>
      <div className={ styles['state-details'] }>
        <span>
          Status:
          { ' ' }
          <span className={ styles['sub-details'] }>All Documents has been verified</span>
        </span>
        <br />
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ verifiedTooltip } tooltipClassName={ styles['state-item'] }>
      <ReconciledIcon fontSize={ 20 } />
    </Tooltip.Hover>
  );

  return (
    <Button
      className={ styles['status-item-button'] }
      variant="primary"
      onClick={ onClickItem }
    >
      { content }
    </Button>
  );
};

export default VerifiedStatusItem;
