import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateReport } from '@src/hooks/queries/report_service/custom_reports';
import { IChartOfAccount } from '@src/types/report_service/report';

import Modal from '@src/components/ui/modal';
import { useItemsSelector } from '@src/components/utils/items_selector';

import AcceptedAccountsModalBody from './accepted_accounts_modal_body';

interface IAcceptedAccountsModalProps extends IUseModalProps {
  reportId: string,
  chartOfAccounts: IChartOfAccount[],
}

const AcceptedAccountsModal = ({
  reportId,
  chartOfAccounts,
  isOpen,
  onCancel,
  onDone,
}: IAcceptedAccountsModalProps): JSX.Element => {
  const updateReport = useUpdateReport();
  const { selected } = useItemsSelector<IChartOfAccount>();

  const handleDone = useCallback(() => {
    updateReport.mutate({
      reportId,
      acceptedChartOfAccountIds: selected.map((chartOfAccount) => chartOfAccount.id),
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:accept_account_type_action:success', selected.map((chartOfAccount) => chartOfAccount.id));
        onDone();
      },
    });
  }, [selected, updateReport, reportId, onDone]);

  return (
    <Modal.Standard
      dialogClassName="accept-account-type-modal"
      proceedTitle="Done"
      show={ isOpen }
      title="Edit Accepted Account Types"
      onCancel={ onCancel }
      onProceed={ handleDone }
    >
      {() => (
        <AcceptedAccountsModalBody
          chartOfAccounts={ chartOfAccounts }
        />
      )}
    </Modal.Standard>
  );
};

const useAcceptedAccountsModal = makeUseModal<
  typeof AcceptedAccountsModal
>(AcceptedAccountsModal);

export {
  IAcceptedAccountsModalProps,
  useAcceptedAccountsModal,
  AcceptedAccountsModal as default,
};
