import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUploadDocument } from '@src/hooks/queries/documents';
import { IBusiness } from '@src/types/businesses';

import { CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { NewDocumentIcon } from '@src/components/utils/img_icons';
import MutationStatus from '@src/components/utils/mutation_status';

import Dropzone from './upload_document_dropzone';
import Input from './upload_document_input';

interface IUploadDocumentProps extends IUseModalProps {
  business: IBusiness,
}

const UploadDocument = ({
  business,
  isOpen,
  onCancel,
  onDone,
}: IUploadDocumentProps): JSX.Element => {
  const upload = useUploadDocument();

  const { mutate } = upload;
  const handleFilesSelected = useCallback((files: File[]) => {
    files.forEach((file) => {
      mutate({
        documentParams: {
          document: {
            originalFileName: file.name,
            fileContentType:  file.type,
          },
          businessId:    business.id,
          inboxDocument: true,
        },
        file,
      }, {
        onSuccess: onDone,
      });
    });
  }, [mutate, onDone, business]);

  return (
    <>
      <MutationStatus mutation={ upload } />
      <Modal dialogClassName="upload-file-modal" show={ isOpen } title="Upload File">
        <Modal.Body>
          <Dropzone isUploading={ upload.isLoading } onFilesSelected={ handleFilesSelected }>
            {
              !upload.isLoading
                ? (
                  <div className="text-center">
                    <NewDocumentIcon />
                    <p className="m-t-20 font-16">Drag and drop the inbox file here.</p>
                    <p className="m-t-10 m-b-10 font-16">OR</p>
                    <Input onFilesSelected={ handleFilesSelected } />
                  </div>
                ) : (
                  <div className="text-center p-t-70">
                    <p className="file-upload-status-text font-18">Uploading...</p>
                  </div>
                )
            }
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton onClick={ onCancel } />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useUploadDocumentModal = makeUseModal(UploadDocument);

export {
  IUploadDocumentProps,
  useUploadDocumentModal,
  UploadDocument as default,
};
