module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="client__mes-person-card message-item p-l-16 ');
    
      __out.push(__sanitize(!this.time_message ? 'p-t-16' : void 0));
    
      __out.push('">\n  <div class="client__mes-person-card-edit">\n    <i class="fa fa-caret-down triangle-item"></i>\n\n    <div class="client__mes-menu-bar context-menu" style="display: none;">\n      ');
    
      if (this.is_editable) {
        __out.push('\n        <a class="edit-message"><i class="icon icon-edit"></i>Edit message</a>\n        <a class="delete-message"><i class="icon icon-trashcan"></i>Delete message</a>\n      ');
      } else {
        __out.push('\n        <a class="mark-unread-message">Mark as Unread</a>\n      ');
      }
    
      __out.push('\n    </div>\n\n  </div>\n\n  <div class="client__mes-avatar-wrapper">\n  ');
    
      if (!this.time_message) {
        __out.push('\n    ');
        if (this.avatarUrl) {
          __out.push('\n      <div class="client__mes-userpic">\n        <img src="');
          __out.push(__sanitize(this.avatarUrl));
          __out.push('" class=\'header__userpic-img\'</img>\n      </div>\n    ');
        } else {
          __out.push('\n      <i class=\'client__nav-client-info-img-icon icon icon-smile in-blue-400 avatar-message\'></i>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (!this.time_message) {
        __out.push('\n    <span class="client__mes-sender-name font-semi-bold">');
        __out.push(__sanitize(this.sender_name));
        __out.push('</span>\n    <span class="client__mes-sender-time">');
        __out.push(__sanitize(this.timestamp));
        __out.push('</span>\n  ');
      }
    
      __out.push('\n\n  <div class="client__mes-content">\n    <p class="client__mes-content-text"></p>\n    <div class="edit-message-form" style="display: none;">\n      <div class="inputor added-input edit-message-input" contentEditable="true" style="overflow-y:auto">');
    
      __out.push(__sanitize(this.text));
    
      __out.push('</div>\n      <button class="cancel-edit-message main-rounded-btn">Cancel</button>\n      <button class="submit-update-message main-rounded-btn">Update message</button>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}