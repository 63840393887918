import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { WarningIcon } from '@src/components/utils/fa_icons';

import styles from '../styles.module.scss';

interface IFormFieldWrapperProps {
  children: React.ReactNode,
  className?: string,
  error?: string,
  hiddenLabel?: boolean,
  hint?: string,
  label: string | React.ReactNode,
  layout?: 'normal' | 'table',
  labelClass?: string,
  suffix?: React.ReactNode,
  valueProps?: ComponentPropsWithoutRef<'div'>,
}

const FormFieldWrapper = ({
  children,
  className,
  error,
  hiddenLabel,
  hint,
  label,
  layout = 'normal',
  labelClass,
  suffix,
  valueProps,
}: IFormFieldWrapperProps): JSX.Element => {
  const wrapperClasses = classNames(
    styles['form-field'],
    styles[`form-field-${layout}`],
    className,
    {
      [styles.error]: Boolean(error),
    },
  );

  const labelClasses = classNames(
    labelClass,
    styles['form-field-label'],
    { [styles['form-field-hidden-label']]: hiddenLabel || layout === 'table' },
  );
  const valueClasses = classNames(styles['form-field-value'], valueProps?.className);

  return (
    <div className={ wrapperClasses }>
      <label className={ labelClasses }>
        { label }
      </label>
      <div { ...valueProps } className={ valueClasses }>
        { children }
        {
          error && (
            <p className={ styles['form-field-error'] }>
              <WarningIcon />
              {' '}
              { error }
            </p>
          )
        }
        {
          hint && (
            <p className={ styles['form-field-hint'] }>{ hint }</p>
          )
        }
      </div>
      { suffix && (
        <div className={ styles['form-field-suffix'] }>{ suffix }</div>
      ) }
    </div>
  );
};

const MemoizedFormFieldWrapper = React.memo(FormFieldWrapper);

export {
  IFormFieldWrapperProps,
  MemoizedFormFieldWrapper as default,
};
