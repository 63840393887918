import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCopyReportMapping } from '@src/hooks/queries/report_service/custom_reports';
import { IBusiness } from '@src/types/businesses';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { TCopyMappingFormValues } from './copy_mapping_form';

interface ICopyMappingModalProps extends IUseModalProps {
  currentBusiness: IBusiness
  templateId: string
  tarReportId: string
}

const CopyMappingModal = ({
  currentBusiness,
  templateId,
  tarReportId,
  isOpen,
  onCancel,
  onDone,
}: ICopyMappingModalProps): JSX.Element => {
  const copyReportMapping = useCopyReportMapping();

  const handleDone = useCallback((data: TCopyMappingFormValues) => {
    if (!data.businessId || !data.reportId) { return; }

    copyReportMapping.mutate({
      currentBusinessId:  data.businessId,
      srcReportId: data.reportId,
      tarReportId,
      templateId,
      srcReportServiceId: 0,
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:copy_mapping_aciton:success');
        onDone();
      },
    });
  }, [copyReportMapping, onDone, tarReportId, templateId]);

  return (
    <>
      <MutationStatus
        mutation={ copyReportMapping }
        successMessage="The mapping has been successfully copied."
      />
      <Modal.Form
        dialogClassName="copy-mapping-modal"
        proceedTitle="Copy Mapping"
        show={ isOpen }
        title="Copy mapping from other Business"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            currentBusiness={ currentBusiness }
            formId={ formId }
            templateId={ templateId }
            onSubmit={ handleDone }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useCopyMappingModal = makeUseModal<typeof CopyMappingModal>(CopyMappingModal);

export {
  ICopyMappingModalProps,
  useCopyMappingModal,
  CopyMappingModal as default,
};
