import React, { forwardRef } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

import ClearIndicator from '../clear_indicator';

import styles from './styles.module.scss';

interface IDateRangeInnerInput extends React.ComponentPropsWithoutRef<'input'> {
  onClear: () => void;
  hideClear?: boolean;
}

const DateRangeInnerInput = forwardRef<HTMLInputElement, IDateRangeInnerInput>(({
  onClear,
  hideClear = false,
  ...inputProps
}: IDateRangeInnerInput, ref) => (
  <>
    <input ref={ ref } { ...inputProps } />
    {
      !hideClear && inputProps.value ? (
        <ClearIndicator
          className={ styles['date-range-clear-icon'] }
          onClick={ onClear }
        />
      ) : (
        <Dropdown.InputIndicator
          className={ styles['date-range-dropdown-icon'] }
          // Dropdown is managed by `react-datepicker` library and is showed on input focus
          // This indicator here just to show that input has dropdown
          tabIndex={ -1 }
        />
      )
    }
  </>
));

DateRangeInnerInput.displayName = 'DateRangeInnerInput';

export default DateRangeInnerInput;
