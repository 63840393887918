module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="invoice-queue-status-container well text-center font-semi-bold in-white in-blue-650 hidden">\n  ');
    
      __out.push(this.statusMessage);
    
      __out.push('\n</div>\n');
    
      if (this.forPayee) {
        __out.push('\n<div class="m-t-30">Below are the invoices that belong to the payment accounts that you have access to </div>\n');
      }
    
      __out.push('\n<div class="table-responsive invoices-table-wrapper invoice-queue-list service-detail-view documents-drop-zone overflow-visible">\n    <table class="table">\n    <thead>\n        <tr>\n            <th width="2%"></th>\n            <th width="2%"></th>\n            ');
    
      if (this.forPayee) {
        __out.push('\n              <th width="20%" class="font-13 font-bold">Invoice #</th>\n            ');
      } else {
        __out.push('\n              <th width="16%" class="sortable font-13 font-bold" data-orderby="merchant" data-orderdir="ASC">Payee</th>\n            ');
      }
    
      __out.push('\n            <th class="font-13 font-bold width-60px"></th>\n            <th width="12%" class="font-13 font-bold">Status</th>\n            <th></th>\n            ');
    
      if (!this.forPayee) {
        __out.push('\n            <th class="font-13 font-bold invoice-unread-messages-count-column-header"></th>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.isAccountantMember || this.canShowCategorization) {
        __out.push('\n            <th width="14%" class="sortable font-13 font-bold" data-orderby="category" data-orderdir="ASC">Chart Of Account</th>\n            ');
      }
    
      __out.push('\n            ');
    
      if (!this.forPayee) {
        __out.push('\n              <th width="7%" class="sortable font-13 font-bold" data-orderby="invoice_number" data-orderdir="ASC">Invoice #</th>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.type === 'paid') {
        __out.push('\n              <th width="7%" class="sortable font-13 font-bold" data-orderby="invoice_date" data-orderdir="ASC">Invoice Date</th>\n            ');
      }
    
      __out.push('\n            ');
    
      if (!this.forPayee && this.type === 'paid') {
        __out.push('\n              <th width="7%" class="sortable font-13 font-bold" data-orderby="account_number" data-orderdir="ASC">Account #</th>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.type === 'paid') {
        __out.push('\n            <th width="11%" class="sortable font-13 font-bold text-nowrap" data-orderby="payment_mode" data-orderdir="ASC">Payment Method</th>\n            <th width="7%" class="sortable font-13 font-bold" data-orderby="payment_date" data-orderdir="ASC">Payment Date</th>\n            ');
      } else {
        __out.push('\n            <th width="9%" class="sortable font-13 font-bold" data-orderby="invoice_date" data-orderdir="ASC">Bill Date</th>\n            <th width="9%" class="sortable font-13 font-bold" data-orderby="due_date" data-orderdir="ASC">Due In</th>\n            <th width="7%" class="sortable font-13 font-bold" data-orderby="uploader_name" data-orderdir="ASC">Uploader</th>\n            ');
      }
    
      __out.push('\n            <th width="50px"></th>\n            <th class="sortable font-13 font-bold invoice-amount-column-header text-center" data-orderby="invoice_amount" data-orderdir="ASC">Amount</th>\n        </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}