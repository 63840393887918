import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateChartOfAccounts } from '@src/hooks/queries/chart_of_accounts';
import { IAccountBigType } from '@src/types/account_types';

import SideView from '@src/components/ui/side_view';
import MutationStatus from '@src/components/utils/mutation_status';

import { IAddChartOfAccountData } from '../schema';
import Form from './add_chart_of_account_form';

interface IAddChartOfAccountProps extends IUseModalProps {
  forceAccountType?: IAccountBigType['code'],
}

const AddChartOfAccount = ({
  isOpen,
  forceAccountType,
  onCancel,
  onDone,
}: IAddChartOfAccountProps) => {
  const business = useBusinessContext();
  const create = useCreateChartOfAccounts();

  const { mutate } = create;
  const handleSubmit = useCallback((data: IAddChartOfAccountData) => {
    const coaData = {
      businessId:      business.id,
      accType:         data.accountType,
      subTypeCode:     data.subType,
      name:            data.name,
      number:          data.number,
      parentId:        data.hasParent === 'true' ? data.parentId : undefined,
      mappingClassIds: data.mappingClassIds,
    };

    mutate(
      {
        businessId:     business.id,
        chartOfAccount: coaData,
      },
      {
        onSuccess: () => {
          onDone();
        },
      },
    );
  }, [business, mutate, onDone]);

  return (
    <SideView.Form
      isOpen={ isOpen }
      title="Add Chart of Account"
      onCancel={ onCancel }
    >
      { ({ formId }) => (
        <>
          <MutationStatus
            mutation={ create }
            successMessage={ window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_CREATED }
          />
          <Form
            businessId={ business.id }
            forceAccountType={ forceAccountType }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        </>
      ) }
    </SideView.Form>
  );
};

const MemoizedAddChartOfAccount = React.memo(AddChartOfAccount);

const useAddChartOfAccount = makeUseModal(MemoizedAddChartOfAccount);

export {
  useAddChartOfAccount,
  MemoizedAddChartOfAccount as default,
};
