import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { getFormattedInvoiceDueDate } from '@src/components/accounts_payable/invoice_queue/utils';

interface IInvoiceDueDateProps {
  document: IAccountsPayableServiceDocument,
}

const InvoiceDueDate = ({
  document,
}: IInvoiceDueDateProps) => {
  const formattedDate = getFormattedInvoiceDueDate(document.invoiceDueDate);
  if (!formattedDate.dueDate) return null;

  const overDue = `Overdue by ${formattedDate.dueDate}`;

  return (
    <div>
      {
        !formattedDate.dueDated && (
        <span>
          { formattedDate.dueDate.split(' ').slice(1).join(' ') }
        </span>
        )
      }
      {
        formattedDate.dueDated && (
          <span className="text-nowrap in-red-400">
            {overDue}
          </span>
        )
      }
    </div>
  );
};

export default React.memo(InvoiceDueDate);
