import React, { useCallback } from 'react';

import { TAccountType } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import Table from '@src/components/ui/table';
import { BankAccountIcon } from '@src/components/utils/icomoon';
import CorporateCardsIcon from '@src/components/utils/icomoon/corporate_cards_icon';

import { IAccountData } from './schema';

import styles from '../../styles.module.scss';

interface AccountCenterTableItemProps {
  account: IReconciliationPaymentAccount,
  onSelected: (bankAccount: IAccountData) => void,
  accountType: TAccountType
}

const AccountCenterTableItem = ({
  account,
  onSelected,
  accountType,
}: AccountCenterTableItemProps) => {
  const handleClickBankAccount = useCallback(() => {
    onSelected({
      name:  account.name,
      id:    account.id,
      last4: account.last4,
    });
  }, [account, onSelected]);

  return (
    <Table.Row className={ styles['accounts-item'] }>
      <Table.Cell onClick={ handleClickBankAccount }>
        <div className="display-flex align-items-center">
          {accountType === 'credit_card' ? <CorporateCardsIcon fontSize={ 28 } /> : <BankAccountIcon fontSize={ 28 } variant="normal" />}
          <span className="m-l-10">{ account.name }</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <span>{ account.last4 }</span>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(AccountCenterTableItem);
