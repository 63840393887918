import React, { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import pluralize from 'pluralize';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useDebouncedCallback } from '@src/hooks/utils';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from '../../styles.module.scss';

interface IChartOfAccountSplitsViewProps {
  accountsPayableServiceDocument: IAccountsPayableServiceDocument;
  onEdit: () => void;
}

const ChartOfAccountSplitsView = ({ accountsPayableServiceDocument, onEdit }: IChartOfAccountSplitsViewProps) => {
  const business = useBusinessContext();
  const [tooltip, setTooltip] = useState<React.ReactNode | undefined>(undefined);

  const isCategoryReadonly = accountsPayableServiceDocument.recurringBillId !== null;
  const serviceLineItemsCount = accountsPayableServiceDocument.serviceDocumentLineItems?.length || 0;
  const filedValue = accountsPayableServiceDocument.chartOfAccount?.displayName;
  const beforeBookDate = moment(accountsPayableServiceDocument.invoiceDate) < moment(business.reconciliationStartDate);

  const serviceDocumentSplitsCount = useMemo(() => {
    const serviceDocumentSplits = accountsPayableServiceDocument.serviceDocumentSplits || [];
    return accountsPayableServiceDocument.serviceDocumentSplitsCount || serviceDocumentSplits.length;
  }, [accountsPayableServiceDocument]);

  const predictedChartOfAccountLineItem =
      serviceDocumentSplitsCount === 1 && accountsPayableServiceDocument.serviceDocumentLineItems
        ? accountsPayableServiceDocument.serviceDocumentLineItems[0]?.predictedChartOfAccountName
        : '';
  const value = predictedChartOfAccountLineItem || filedValue;

  const count = useMemo(
    () => (serviceDocumentSplitsCount === 0 ? serviceLineItemsCount : serviceDocumentSplitsCount),
    [serviceDocumentSplitsCount, serviceLineItemsCount]
  );

  const selectCategory = useMemo(() => {
    if (value) {
      return 'Edit';
    }

    return 'Select Category';
  }, [value]);

  const handleMouseOver = useDebouncedCallback(() => {
    if (beforeBookDate) {
      setTooltip(
        <span>
          Action cannot be performed as this
          <br />
          pertains to a date before the book
          <br />
          lock date.
        </span>
      );
    }
  }, [], 300);

  const renderChartOfAccountItem = useCallback(() => {
    if (count === 0) {
      if (predictedChartOfAccountLineItem) {
        return (
          <div>
            <Tooltip.Hover className={ styles['inline-grid'] } content={ predictedChartOfAccountLineItem }>
              <div className={ styles['table-text-cell-text'] }>{ predictedChartOfAccountLineItem }</div>
            </Tooltip.Hover>
            <br />
            <Tooltip.Hover className={ styles['inline-grid'] } content={ tooltip }>
              <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
                { beforeBookDate ? (
                  <Button variant="link">Edit</Button>
                ) : (
                  <Button variant="link" onClick={ onEdit }>Edit</Button>
                ) }
              </div>
            </Tooltip.Hover>
          </div>
        )
      } 
      
      if (serviceLineItemsCount > 1) {
        return (
          <Tooltip.Hover className={ styles['inline-grid'] } content={ tooltip }>
            <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
              { beforeBookDate ? (
                <Button variant="link">
                  { `${serviceLineItemsCount} ${pluralize('Split', serviceLineItemsCount)}` }
                </Button>
              ) : (
                <Button variant="link" onClick={ onEdit }>
                  { `${serviceLineItemsCount} ${pluralize('Split', serviceLineItemsCount)}` }
                </Button>
              ) }
            </div>
          </Tooltip.Hover>
        )
      }

      return (
        <div>
          { value && (
            <>
              <Tooltip.Hover className={ styles['inline-grid'] } content={ value }>
                <div className={ styles['table-text-cell-text'] }>{ value }</div>
              </Tooltip.Hover>
              <br />
            </>
          ) }
          
          <Tooltip.Hover className={ styles['inline-grid'] } content={ tooltip }>
            <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
              { beforeBookDate ? (
                <Button variant="link">{ selectCategory }</Button>
              ) : (
                <Button variant="link" onClick={ onEdit }>{ selectCategory }</Button>
              ) }
            </div>
          </Tooltip.Hover>
        </div>
      )
    }

    if (!isCategoryReadonly) {
      return (
        <Tooltip.Hover className={ styles['inline-grid'] } content={ tooltip }>
          <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
            { beforeBookDate ? (
              <Button variant="link">
                { `${count} ${pluralize('Split', count)}` }
              </Button>
            ) : (
              <Button variant="link" onClick={ onEdit }>
                { `${count} ${pluralize('Split', count)}` }
              </Button>
            ) }
          </div>
        </Tooltip.Hover>
      );
    }

    return (
      <Tooltip.Hover className={ styles['inline-grid'] } content={ tooltip }>
        <div onFocus={ handleMouseOver } onMouseOver={ handleMouseOver }>
          { beforeBookDate ? (
            <Button variant="link">View Category</Button>
          ) : (
            <Button variant="link" onClick={ onEdit }>View Category</Button>
          ) }
        </div>
      </Tooltip.Hover>
    );
  }, [
    tooltip,
    handleMouseOver,
    predictedChartOfAccountLineItem,
    isCategoryReadonly,
    serviceLineItemsCount,
    count,
    onEdit,
    beforeBookDate,
    selectCategory,
    value
  ]);

  return (
    <div>{ renderChartOfAccountItem() }</div>
  );
};

export default React.memo(ChartOfAccountSplitsView);
