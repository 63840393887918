import React from 'react';

import { PlusIcon } from '@src/components/utils/icomoon';

import { useAddTransaction } from '../add_transaction';

const AddTransactionAction = () => {
  const addTransaction = useAddTransaction();

  return (
    <>
      <addTransaction.Component { ...addTransaction.props } />
      <button
        className="btn btn-blue font-semi-bold font-16 display-flex align-items-center"
        disabled={ addTransaction.props.isOpen }
        type="submit"
        onClick={ addTransaction.open }
      >
        <PlusIcon mr={ 10 } />
        Add Transaction
      </button>
    </>
  );
};

export default AddTransactionAction;
