import React from 'react';

import classNames from 'classnames';
import { SubmitHandler, useForm, useController } from 'react-hook-form';

import Form, { YearPickerInput } from '@src/components/ui/form';

interface IEditBudgetFormValues {
  name: string,
  year: number,
  type: string,
}

interface IEditBudgetFormProps {
  originalName?: string,
  originalYear?: number,
  originalType?: string,
  formId: string,
  onSubmit: SubmitHandler<IEditBudgetFormValues>,
}

const EditBudgetForm = ({
  originalName,
  originalYear,
  formId,
  onSubmit,
}: IEditBudgetFormProps): JSX.Element => {
  const templateOptions = (
    ['Budget', 'Forecast'].map((template) => {
      const disabled = false;
      return { value: template, label: template, disabled };
    })
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useForm<IEditBudgetFormValues>({
    defaultValues: {
      name: originalName,
      year: originalYear,
      type: templateOptions.find((option) => !option.disabled)?.value,
    },
  });

  const inputClasses = classNames(
    'form-control',
    {
      'border-danger': errors.name,
    },
  );

  const year = originalYear ?? new Date().getFullYear();

  const {
    field: { onChange, onBlur, value },
  } = useController({
    name:         'year',
    control,
    rules:        { required: true },
    defaultValue: year,
  });

  if (formId === 'edit_budget_form') {
    return (
      <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
        <div className="display-flex align-items-center justify-content-between">
          <label className="form-label font-14" htmlFor="budget-name">Name</label>
          <input
            id="budget-name"
            { ...register('name', { required: true }) }
            className={ inputClasses }
            placeholder="Enter name for budget"
            type="text"
          />
        </div>
        <div className="display-flex align-items-center justify-content-between m-t-10">
          <label className="form-label font-14" htmlFor="period-select">Period</label>
          <YearPickerInput
            options={ {
              startDate: new Date(`${year - 1}/01/01`),
              endDate:   new Date(`${year + 1}/12/31`),
            } }
            placeholder="Year"
            value={ String(value) }
            onBlur={ onBlur }
            onChange={ onChange }
          />
        </div>
      </Form>
    );
  }
  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className="display-flex align-items-center justify-content-between">
        <label className="form-label font-14" htmlFor="budget-name">Name</label>
        <input
          id="budget-name"
          { ...register('name', { required: true }) }
          className={ inputClasses }
          placeholder="Enter name for budget"
          type="text"
        />
      </div>
      <div className="display-flex align-items-center justify-content-between m-t-10">
        <label className="form-label font-14" htmlFor="period-select">Period</label>
        <YearPickerInput
          options={ {
            startDate: new Date(`${year - 1}/01/01`),
            endDate:   new Date(`${year + 1}/12/31`),
          } }
          placeholder="Year"
          value={ String(value) }
          onBlur={ onBlur }
          onChange={ onChange }
        />
      </div>
      <Form.RadioCollectionField
        vertical
        error={ errors.type?.message }
        label="Report Type"
        options={ templateOptions }
        { ...register('type') }
      />
    </Form>
  );
};

export {
  IEditBudgetFormProps,
  IEditBudgetFormValues,
  EditBudgetForm as default,
};
