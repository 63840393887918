import React from 'react';

import NoChatIcon from '@assets/images/no_chat.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const ChatCentralNoChatIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="No Chat Icon"
      src={ NoChatIcon }
      { ...props }
    />
  );
};

export default ChatCentralNoChatIcon;
