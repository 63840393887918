import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn:  window.configData?.sso?.redirect_sign_in_path,
          redirectSignOut: window.configData?.sso?.redirect_sign_out_path,
          domain:          window.configData?.sso?.sso_cognito_domain,
          providers:       ['Google'],
          scopes:          ['email', 'openid'],
          responseType:    'code',
        },
      },
      userPoolId:       window.configData?.sso?.sso_cognito_user_pool_id,
      userPoolClientId: window.configData?.sso?.sso_cognito_user_pool_client_id,
    },
  },
});

export { default as LoginButton } from './view/login_button_root';
export { default as Register } from './view/register';
