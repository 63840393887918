module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="month-end-reconciliation">\n  <div class="clearfix m-b-20">\n    <h2 class="m-b-5 m-t-1 font-bold font-18 pull-left">Month-End Close</h2>\n  </div>\n\n  <div class="text-center m-t-30">\n    <img src="');
    
      __out.push(__sanitize(configData.reconciliation_center_images.MONTH_END));
    
      __out.push('" style="width: 180px; height: 180px;" />\n  </div>\n\n  <div class="text-center m-t-20">\n    <p class="font-14" style="font-weight: 400">\n      Docyt provides robust reconciliation and book-closing workflow. Save up to 80%<br /> of your time on month-end reconciliation, get things ready for tax-time, find and<br /> fix general ledger duplicates easily, and keep your bookkeeping real-time.\n    </p>\n  </div>\n\n  <div class="text-center m-t-30">\n    <button type=\'button\' class="btn btn-blue m-t-10 btn-start-month-end-reconciliation">Start Reconciliation</button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}