import React, { ComponentPropsWithoutRef, useCallback, useRef } from 'react';

import classNames from 'classnames';
import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';

import { TMonth } from '@src/types/common';
import {
  DATEFNS_MONTH_FORMAT,
  formatApiMonth,
  parseApiMonth,
} from '@src/utils/date_helpers';

import DateInnerInput from './date_inner_input';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';

interface IMonthInputProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  datePickerProps?: Omit<
    ComponentPropsWithoutRef<typeof DatePicker>,
    'showMonthYearPicker' | 'onChange' | 'customInput' | 'placeholderText' | 'calendarContainer' | 'selected'
  >,
  value?: TMonth,
  placeholder?: string,
  onChange?: (value: TMonth) => void,
}

const MonthInput = ({
  datePickerProps,
  placeholder = 'MM/YYYY',
  value,
  onChange,
}: IMonthInputProps) => {
  const classes = classNames(styles['date-range-input']);
  const pickerRef = useRef<DatePicker>(null);

  const handleChange = useCallback((date: Date | null) => {
    if (!onChange) return;

    const apiDate = formatApiMonth(date);

    if (value === apiDate) return;

    onChange(apiDate);
  }, [value, onChange]);

  // I need to place this component here because there is no another way to pass
  // additional properties to the container because we pass only container function
  // and it's rendered inside of date picker.
  const DataRangeContainer = useCallback(({
    children,
    className,
  }: CalendarContainerProps) => {
    return (
      <div className={ styles['date-input-container'] }>
        <div className={ styles['date-input-calendar'] }>
          <CalendarContainer className={ className }>
            { children }
          </CalendarContainer>
        </div>
      </div>
    );
  }, []);

  const handleClear = useCallback(() => {
    handleChange(null);
  }, [handleChange]);

  let parsedDate = parseApiMonth(value);
  if (!(parsedDate instanceof Date) || Number.isNaN(parsedDate.getTime())) {
    parsedDate = undefined;
  }

  return (
    <DatePicker
      ref={ pickerRef }
      showMonthYearPicker
      autoComplete="off"
      calendarContainer={ DataRangeContainer }
      className={ classes }
      customInput={ <DateInnerInput onClear={ handleClear } /> }
      dateFormat={ DATEFNS_MONTH_FORMAT }
      placeholderText={ placeholder }
      popperClassName={ styles['date-input-popper'] }
      selected={ parsedDate }
      onChange={ handleChange }
      { ...datePickerProps }
    />
  );
};

export default React.memo(MonthInput);
