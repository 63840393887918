import { atom } from 'recoil';

import { Family } from '@src/constants/atom_keys';

import { IFormValues } from './schema';

const addTransactionFieldsAtom = atom<IFormValues | null>({
  key:     Family.AddTransactionFieldsState,
  default: null,
});

export {
  addTransactionFieldsAtom,
};
