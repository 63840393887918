import React from 'react';

import classNames from 'classnames';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import InfiniteRegionScrollTable from '@src/components/ui/table/infinite_region_scroll_table';

import InfiniteScrollTable from './infinite_scroll_table';
import NoScrollTable from './no_scroll_table';

import styles from './styles.module.scss';

interface ITableProps extends
  React.ComponentPropsWithoutRef<'table'>,
  IUIStyleProps
{
  query?: UseInfiniteQueryResult<any, Error>
  | UseQueryResult<any, Error>
  | UseInfiniteQueryResult<any, Error>[]
  | UseQueryResult<any, Error>[],
  isRegionScroll?: boolean,
  sticky?: boolean,
  wrapperClassName?: string,
}

const Table = ({
  query,
  isRegionScroll,
  sticky,
  wrapperClassName,
  ...props
}: ITableProps) => {
  const [classes, tableProps] = uiStyleProps(
    styles.table,
    props,
    { [styles['table-sticky-header']]: sticky || isRegionScroll },
  );
  const wrapperClasses = classNames(wrapperClassName, {
    [styles['table-region-container']]: isRegionScroll,
  });

  const commonProps = {
    ...tableProps,
    className:        classes,
    wrapperClassName: wrapperClasses,
  };

  if (query && 'fetchNextPage' in query) {
    if (isRegionScroll) {
      return (
        <InfiniteRegionScrollTable
          { ...commonProps }
          { ...props }
          infiniteQuery={ query }
        />
      );
    }
    return (
      <InfiniteScrollTable
        { ...commonProps }
        { ...props }
        infiniteQuery={ query }
      />
    );
  }

  return <NoScrollTable query={ query } { ...props } { ...commonProps } />;
};

export {
  ITableProps,
  Table as default,
};
