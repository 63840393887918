import React from 'react';

import { useGetIntegrationsRunDownloadUrls } from '@src/hooks/queries/integrations/runs';
import { TID } from '@src/types/common';

import { AppLink } from '@src/components/ui_v2/buttons';
import QueryStatus from '@src/components/utils/query_status';

import styles from './styles.module.scss';

interface IRunFilesDownloadListProps {
  integrationId: TID,
  integrationRunId: TID,
}

const RunFilesDownloadList = ({
  integrationId,
  integrationRunId,
}: IRunFilesDownloadListProps) => {
  const query = useGetIntegrationsRunDownloadUrls({ integrationId, integrationRunId });
  const records = query.data?.collection || [];
  const filesCount = records.length;

  return (
    <div className={ styles['download-list'] }>
      <QueryStatus query={ query } />
      <h1>
        {
          query.data && (filesCount > 0
            ? `There are ${filesCount} report for the Selected day.`
            : 'No files available for download.')
        }
      </h1>
      <ul>
        { records.map((r) => (
          <li key={ r.objectKey }>
            <span className={ styles.filename }>{ r.objectKey }</span>
            <span className={ styles.link }>
              <AppLink newWindow href={ r.objectUrl }>Download</AppLink>
            </span>
          </li>
        )) }
      </ul>
    </div>
  );
};

export default RunFilesDownloadList;
