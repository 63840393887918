import Body from './body';
import Content from './content';
import Header from './header';
import Provider from './provider';
import Render from './render';
import Standard from './standard';

export default {
  Body,
  Content,
  Header,
  Provider,
  Render,
  Standard,
};
