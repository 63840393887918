module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<div class="transaction-detail-view">\n  <div class="view-title font-bold">DOCUMENTS</div>\n  ');
    
      if (!this.no_document_required || this.has_documents) {
        __out.push('\n    <table class="table matched-document-table-view-header bg-white m-b-0"> \n      <thead>\n        <tr>\n          <th class="relative text-left">Document ID</th>\n          <th class="relative text-center">NAME</th>\n          <th class="relative text-right">');
        if (ref = this.transaction_type, indexOf.call(Docyt.Common.Constants['EXPENSE_TRANSACTION_TYPES'], ref) >= 0) {
          __out.push(__sanitize('Amount'));
        }
        __out.push('</th>\n        </tr>\n      </thead>\n    </table>\n    <div class="matched-document-table-view">\n      <table class="table matched-document-table-view-body bg-white"> \n        <tbody>\n        </tbody>\n      </table>\n    </div>\n    ');
        if (!this.no_document_required) {
          __out.push('\n      <span class="font-15 pointer in-blue-400 inline-block p-l-20 add-document p-t-5"><span class="icon-plus-circle m-r-18"></span>Add Document</span>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n  <div class="document-note-container">\n    <button class="btn-remove-no-required">\n      No document needed\n      <span class="icon-cross-icon m-l-7 font-14 in-white"></span>\n    </button>\n    ');
        if (this.document_note) {
          __out.push('\n    <hr class="m-t-15 m-b-15"/>\n    <div class="font-14 in-grey-1050">Note added by ');
          __out.push(__sanitize(this.document_note_owner));
          __out.push('</div>\n    <div class="m-t-7 font-14 in-black">');
          __out.push(__sanitize(this.document_note));
          __out.push('</div>\n    ');
        }
        __out.push('\n  </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}