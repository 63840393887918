module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, pay_method, ref;
    
      __out.push('<div class="modal fade">\n    <div class="modal-dialog business-starter-plan-select-modal select-multi-item-area">\n      <div class="modal-content">\n        <div class="modal-header">\n          <button class=\'close modal-cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class="modal-title">\n            ');
    
      __out.push(__sanitize(this.title));
    
      __out.push('\n          </h4>\n        </div>\n        <div class="modal-body">\n          <div class="business-multi-select border-bottom-gray">\n            <div class="checkbox m-t-0 m-b-0">\n              <input type="checkbox" class="styled form-control input-element all" data-type="checkbox" ');
    
      if (this.all_method_enabled && this.main_bank_access) {
        __out.push(__sanitize('checked="true"'));
      }
    
      __out.push(' ');
    
      if (!this.main_bank_access) {
        __out.push(__sanitize('disabled="true"'));
      }
    
      __out.push('>\n              <label class="font-16 relative width-100-percent">\n                <div class="inline-flex width-100-percent">\n                  <div class="display-table-cell">\n                    <img src="');
    
      __out.push(__sanitize(configData.images['all_payment_accounts']));
    
      __out.push('">\n                  </div>\n                  <div class="display-table-cell p-l-10 vertical-align-middle width-70-percent">\n                    <h5 class="font-16 m-t-0 m-b-0 font-bold business-name-field text-left">\n                      All Payment Accounts\n                    </h5>\n                  </div>\n                </div>\n              </label>\n            </div>\n          </div>\n          ');
    
      ref = this.all_pay_methods;
      for (i = 0, len = ref.length; i < len; i++) {
        pay_method = ref[i];
        __out.push('\n          <div class="business-multi-select border-bottom-gray">\n            <div class="checkbox m-t-0 m-b-0">\n              <input type="checkbox" class="styled form-control input-element account" data-accmain="');
        __out.push(__sanitize(pay_method.main_bank));
        __out.push('" data-type="checkbox" data-accid="');
        __out.push(__sanitize(pay_method.payment_account_id));
        __out.push('" data-acctype="');
        __out.push(__sanitize(pay_method.payment_account_type));
        __out.push('" ');
        if (pay_method.is_checked) {
          __out.push(__sanitize('checked="true"'));
        }
        __out.push(' ');
        if (!pay_method.is_selectable) {
          __out.push(__sanitize('disabled="true"'));
        }
        __out.push('>\n              <label class="font-16 relative width-100-percent">\n                <div class="inline-flex width-100-percent">\n                  <div class="display-table-cell">\n                    ');
        if (pay_method.payment_account_type === "CreditCardPaymentAccount") {
          __out.push('\n                    <span class="icon icon-credit-card-account font-40">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n                    </span>\n                    ');
        } else if (pay_method.payment_account_type === "BankPaymentAccount") {
          __out.push('\n                    <span class="icon icon-bank-account font-40">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n                    </span>\n                    ');
        } else if (pay_method.payment_account_type === "BusinessServiceCashAccount") {
          __out.push('\n                    <span class="icon icon-cash-account font-40">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n                    </span>\n                    ');
        }
        __out.push('\n                  </div>\n                  <div class="display-table-cell p-l-10 vertical-align-middle width-70-percent">\n                    <h5 class="font-16 m-t-0 m-b-0 font-bold business-name-field text-left">\n                      ');
        __out.push(__sanitize(pay_method.name || pay_method.payment_type));
        __out.push('\n                    </h5>\n                  </div>\n                  <div class="display-table-cell p-l-10 vertical-align-middle width-30-percent">\n                    <h5 class="font-14 m-t-0 m-b-0 in-grey-500 business-name-field text-left">\n                      ');
        __out.push(__sanitize(pay_method.last4));
        __out.push('\n                    </h5>\n                  </div>\n                </div>\n              </label>\n            </div>\n          </div>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-blue btn-submit ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}