import React, { useCallback } from 'react';

import { useDeleteReport } from '@src/hooks/queries/report_service/custom_reports';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useDeleteReportModal } from '../modals/delete_report';

import styles from '../styles.module.scss';

interface IDeleteReportActionProps {
  report: IReport,
  category: TReportCategory,
}

const DeleteReportAction = ({
  report,
  category,
}: IDeleteReportActionProps): JSX.Element => {
  const deleteReport = useDeleteReport();

  const handleDeleteReport = useCallback(() => {
    deleteReport.mutate(report.id);
  }, [deleteReport, report.id]);
  const deleteReportModal = useDeleteReportModal({ onDone: handleDeleteReport });

  return (
    <>
      { category !== 'basic' && (
        <ActionsDropdown.Action
          danger
          className={ styles['dropdown-item'] }
          icon={ <TrashcanIcon fontSize={ 18 } /> }
          title="Delete Report"
          onSelect={ deleteReportModal.open }
        />
      )}
      <MutationStatus mutation={ deleteReport } />
      <deleteReportModal.Component
        { ...deleteReportModal.props }
        report={ report }
      />
    </>
  );
};

export default DeleteReportAction;
