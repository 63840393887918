import React, { useMemo } from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { QBOOffIcon, QBOBlockIcon, QBOOnIcon, QBOErrorIcon } from '@src/components/utils/icomoon';

const qboStatusOptions: TIconOption[] = [
  {
    icon:  <QBOOffIcon fontSize={ 18 } />,
    label: 'Push to QuickBooks Pending',
    value: 'started',
  },
  {
    icon:  <QBOBlockIcon fontSize={ 18 } />,
    label: 'Push to QuickBooks Disabled',
    value: 'disabled',
  },
  {
    icon:  <QBOOnIcon fontSize={ 18 } />,
    label: 'Pushed to QuickBooks',
    value: 'synced',
  },
  {
    icon:  <QBOErrorIcon fontSize={ 18 } />,
    label: 'Error',
    value: 'failed',
  },
];

interface IQBOSyncFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.IconSelectField>, 'options' | 'name'>
{
  name?: string,
}

const QBOSyncFilterField = ({
  ...props
}: IQBOSyncFilterFieldProps): JSX.Element => {
  const name = useMemo(() => {
    return props.name ?? 'qbo_sync';
  }, [props.name]);

  return (
    <Filter.IconSelectField
      isSearchable={ false }
      name={ name }
      options={ qboStatusOptions }
      placeholder="Select QuickBooks Status..."
      { ...props }
    />
  );
};

export default React.memo(QBOSyncFilterField);
