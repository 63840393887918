import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useBulkMoveDocumentsToTrash } from '@src/hooks/queries/documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmRestoreDocumentsModal } from '@src/components/common/confirm_delete';
import { LinkButton } from '@src/components/ui/buttons';

interface IBusinessInboxRestoreActionProps {
  documentIds: number[],
  onDone: () => void,
}

const BusinessInboxRestoreAction = ({
  documentIds,
  onDone,
}: IBusinessInboxRestoreActionProps) => {
  const business = useBusinessContext();

  const moveToTrash = useBulkMoveDocumentsToTrash();
  const bulkAction = useBulkActionModal({
    mutation: moveToTrash,
    onDone,
  });

  const { runMutation } = bulkAction;
  const handleConfirm = useCallback(() => {
    runMutation(documentIds.map((id) => ({
      documentIds: [id],
      value:       false,
    })));
  }, [documentIds, runMutation]);

  const confirm = useConfirmRestoreDocumentsModal({
    business,
    documentIds,
    onDone: handleConfirm,
  });

  return (
    <>
      <confirm.Component { ...confirm.props } />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Documents"
        title="Restore Documents"
      />
      <LinkButton
        disabled={ documentIds.length === 0 }
        fontSize={ 16 }
        fontVariant="semi-bold"
        title="Restore"
        onClick={ confirm.open }
      />
    </>
  );
};

export default BusinessInboxRestoreAction;
