import React, { useMemo } from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useGetDashboards } from '@src/hooks/queries/dashboards/dashboards';
import { TID, TMongoID } from '@src/types/common';

import NoAccessView from '@src/components/business_dashboards/dashboard/dashboard_no_access_view';
import QueryStatus from '@src/components/utils/query_status';

import BlankDashboardsPage from './blank_dashboards_page';
import DashboardMainPage from './dashboard_main_page';

import styles from './styles.module.scss';

interface IDashboardPageProps {
  businessId: TID,
  dashboardId: TMongoID | null,
  widgetId: TMongoID | null,
}

const DashboardPage = ({
  businessId,
  dashboardId,
  widgetId,
}: IDashboardPageProps): JSX.Element => {
  const { data: info } = useGetBusinessServicesInfo(businessId);
  const dashboardsQuery = useGetDashboards({ businessId });
  const dashboards = useMemo(() => {
    return dashboardsQuery.data?.collection || [];
  }, [dashboardsQuery.data?.collection]);
  const currentDashboard = dashboards.find((dashboard) => dashboard.id === dashboardId);

  const noDashboardView = () => {
    return (
      info?.isBusinessAdmin ? (
        !dashboardId && (
          <BlankDashboardsPage
            businessId={ businessId }
            businessServiceInfo={ info }
          />
        )
      ) : (
        <NoAccessView />
      )
    );
  };

  return (
    <div className={ styles['dashboard-container'] }>
      <QueryStatus query={ dashboardsQuery } />
      { currentDashboard ? (
        <DashboardMainPage
          businessServiceInfo={ info }
          currentDashboard={ currentDashboard }
          dashboards={ dashboards }
          widgetId={ widgetId }
        />
      ) : (
        noDashboardView()
      )}
    </div>
  );
};

export default DashboardPage;
