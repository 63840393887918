import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBalanceSheetInformations } from '@src/hooks/queries/balance_sheet_informations';
import { useSorting, useURLParams } from '@src/hooks/url_params';
import { TStatementSortColumn } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';
import { formatApiYear } from '@src/utils/date_helpers';

import { IStatementViewFilter } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/filter/types';
import List from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/list';

import { useBalanceSheetStatementUploadModal } from './balance_sheet_statement_modal/upload_modal/upload_modal';

interface IStatementProps {
  businessId: TID,
}

const DefaultSorting: ISortingParams<TStatementSortColumn> = {
  orderColumn:    'account_name',
  orderDirection: 'asc',
};

const BalanceSheetInformation = ({
  businessId,
}: IStatementProps): JSX.Element => {
  const { filter } = useURLParams<
    IStatementViewFilter,
    TStatementSortColumn
  >({
    businessId,
    section:        window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_TYPE,
    defaultSorting: DefaultSorting,
    filterInitData: { year: formatApiYear(new Date()) },
  });
  const sorting = useSorting<TStatementSortColumn>({ defaultSorting: DefaultSorting });

  const months = useMemo(() => {
    const filteredYear = filter.data?.year;
    if (!filteredYear) return 12;

    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    if (String(currentYear) !== filteredYear) return 12;

    return currentMonth;
  }, [filter.data?.year]);

  const query = useGetBalanceSheetInformations({
    businessId,
    months,
    monthEndDocument: true,
    filter:           filter.data,
    ...sorting.data,
  });

  const statements = useMemo(() => {
    const statementsPages = query.data?.pages || [];
    return flatten(statementsPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const uploadModal = useBalanceSheetStatementUploadModal();

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
      />
      <List
        businessId={ businessId }
        filter={ filter }
        months={ months }
        query={ query }
        sorting={ sorting }
        statements={ statements }
        onUploadFile={ uploadModal.open }
      />
    </>
  );
};

export default BalanceSheetInformation;
