import React, { useCallback } from 'react';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useRevenueSystemContext } from '../../revenue_system_provider';

interface IAddRevenueReportActionProps {
  report: IRevenueServiceDocument,
}

const AddRevenueReportAction = ({
  report,
}: IAddRevenueReportActionProps) => {
  const revenueSystem = useRevenueSystemContext();
  const handleOnClick = useCallback(() => {
    window.Docyt.vent.trigger('revenue:add_report_clicked', report.startDate, revenueSystem.revenueReportTypeId);
  }, [report.startDate, revenueSystem.revenueReportTypeId]);

  return (
    <ActionsDropdown.Action
      icon={ <PlusIcon fontSize={ 20 } variant="o" /> }
      title="Add Revenue Report"
      onClick={ handleOnClick }
    />
  );
};

export default AddRevenueReportAction;
