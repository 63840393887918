import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const OpenInNewIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="open_in_new"
    />
  );
};

export default OpenInNewIcon;
