import React from 'react';

import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IReportItem } from '@src/types/report_service/report_item';
import { camelizeKeys } from '@src/utils/transform_keys';

import BusinessProvider from '@src/components/providers/business_provider';
import ReportsServiceProvider from '@src/components/providers/reports_service_provider';
import Root from '@src/components/root';

import EditMapping from './edit_mapping';

interface IBusinessReportsEditMappingLaborProps {
  businessId: TID,
  items: IReportItem[]
  report: IReport,
  parentId: string,
  childId: string,
}

const BusinessReportsEditMappingLabor = ({
  businessId,
  items,
  report,
  parentId,
  childId
}: IBusinessReportsEditMappingLaborProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReportsServiceProvider businessId={ businessId }>
          <EditMapping
            childId={ childId }
            items={ camelizeKeys(items) as IReportItem[] }
            parentId={ parentId }
            report={ camelizeKeys(report) as IReport }
          />
        </ReportsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BusinessReportsEditMappingLabor;
