import React from 'react';

import { TID } from '@src/types/common';

import BalanceSheetInformations from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_informations';

interface IBalanceSheetInformationBodyProps {
  businessId: TID,
}

const BalanceSheetInformationBody = ({
  businessId,
}: IBalanceSheetInformationBodyProps): JSX.Element => {
  return (
    <BalanceSheetInformations businessId={ businessId } />
  );
};

export default BalanceSheetInformationBody;
