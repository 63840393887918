import React from 'react';

import { SmileIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IVendorWithAvatar {
  imageUrl: string | undefined | null,
}

interface IVendorAvatarProps {
  vendor: IVendorWithAvatar | null,
  /**
   * Avatar size with measurement unit.
   * @example
   * '24px' | '1rem'
   * @default '25px'
   * */
  size?: string,
}

const VendorAvatar = ({
  size = styles['small-avatar-size'],
  vendor,
}: IVendorAvatarProps) => {
  return (
    <div className="small-avatar" style={ { width: size, height: size, fontSize: size } }>
      {
        vendor?.imageUrl ? (
          <img
            alt=""
            src={ vendor.imageUrl }
          />
        ) : (
          <SmileIcon inColor="orange-500" />
        )
      }
    </div>
  );
};

export default React.memo(VendorAvatar);
