import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import { ChatIcon } from '@src/components/utils/icomoon';

interface Props {
  transaction: ITransactionServiceDocument,
}

const TransactionChatAction = ({
  transaction,
}: Props) => {
  const classes = uiStyleClassNames(
    'display-inline-flex',
    {
      pull:         'left',
      widthPercent: 25,
    },
  );

  const hasMessages = transaction.messageCount > 0;

  return (
    <div className={ classes }>
      <a
        className="relative transaction-action"
        href={ `/document/${transaction.documentId}/chat` }
      >
        <ChatIcon fontSize={ 26 } variant={ hasMessages ? 'filled' : 'empty' } />
        {
          hasMessages && (
            <span className="unread-messages-count">
              { transaction.messageCount < 10 ? transaction.messageCount : '9+' }
            </span>
          )
        }
      </a>
    </div>
  );
};

export default TransactionChatAction;
