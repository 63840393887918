import React, { FC } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useDeleteAdjustmentEntry } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import Spinner from '@src/components/ui/spinner';
import TrashIcon from '@src/components/utils/icomoon/trash';

interface Props {
  adjustmentEntry: IAdjustmentEntry
}

const Component: FC<Props> = ({ adjustmentEntry }) => {
  const business = useBusinessContext();
  const { mutate: deleteMutate, isLoading } = useDeleteAdjustmentEntry();
  const queryClient = useQueryClient();

  const deleteAdjustmentEntry = () => {
    const params = { id: adjustmentEntry.id, businessId: business.id };

    deleteMutate(params, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKey.adjustmentEntries);
        toastr.success('Successfully deleted', 'Delete Successful');
      },
      onError: () => {
        toastr.error('Failed to delete journal entry.', 'Error');
      },
    });
  };

  const confirm = useConfirmDeleteModal({
    onDone: deleteAdjustmentEntry,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <confirm.Component
        confirmTitle="Delete"
        text="Are you sure you want to delete this journal entry? Once deleted, this will be permanently removed from the ledger."
        title="Delete Journal Entry"
        { ...confirm.props }
      />
      <TrashIcon onClick={ confirm.open } />
    </>
  );
};

export default Component;
