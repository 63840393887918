import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { EditIcon } from '@src/components/utils/icomoon';

interface IEditBusinessMetricValuesActionProps {
  onEditData: () => void,
}

const EditBusinessMetricValuesAction = ({
  onEditData,
}: IEditBusinessMetricValuesActionProps) => {
  return (
    <ActionsDropdown.Action
      icon={ <EditIcon /> }
      name="Edit"
      onClick={ onEditData }
    />
  );
};

export default EditBusinessMetricValuesAction;
