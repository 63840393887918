import React, { forwardRef } from 'react';

import PercentageInput from '@src/components/ui_v2/inputs/percentage_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IPercentageFieldProps extends
  React.ComponentPropsWithoutRef<typeof PercentageInput>,
  Omit<IWrapperProps, 'suffix'>
{
}

const PercentageField = forwardRef<HTMLInputElement, IPercentageFieldProps>(({
  ...props
}: IPercentageFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <PercentageInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

PercentageField.displayName = 'PercentageField';

export default PercentageField;
