import React, { useCallback } from 'react';

import Action from './action';

interface ILinkActionItemProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Action>, 'onSelect'>
{
  href: string,
  trigger?: boolean,
  backboneNavigate?: boolean,
}

const LinkAction = ({
  href,
  trigger = true,
  backboneNavigate = true,
  ...props
}: ILinkActionItemProps): JSX.Element => {
  const handleSelect = useCallback(() => {
    if (backboneNavigate) {
      Backbone.history.navigate(href, { trigger });
    } else {
      window.open(href, '_blank');
    }
  }, [backboneNavigate, href, trigger]);

  return (
    <Action onSelect={ handleSelect } { ...props } />
  );
};

export default LinkAction;
