module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="');
    
      if (this.isTeamMember) {
        __out.push(__sanitize('client-member-content'));
      }
    
      __out.push('">\n<div class="business-divider m-t-40 ');
    
      __out.push(__sanitize(!this.isTeamMember ? 'p-b-15' : 'p-b-35'));
    
      __out.push('">\n  <div class="team-large-avatar-wrapper ');
    
      if (!this.isTeamMember) {
        __out.push(__sanitize('m-l-20'));
      }
    
      __out.push('">\n    ');
    
      if ((!this.isTeamMember && this.business_contact.image_url) || (this.isTeamMember && this.user_avatar_url)) {
        __out.push('\n      <img src="');
        __out.push(__sanitize(!this.isTeamMember ? this.business_contact.image_url : this.user_avatar_url));
        __out.push('">\n    ');
      } else {
        __out.push('\n      ');
        if (!this.isTeamMember) {
          __out.push('\n      <span class=\'icon-business-default-avatar font-78\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
        } else {
          __out.push('\n      <i class="icon icon-smile in-blue-400 font-78"></i>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="m-l-30 workspace-content">\n    <label class="font-semi-bold font-24 vertical-align-middle workspace-content user-name-label">\n    ');
    
      __out.push(__sanitize(!this.isTeamMember ? this.business_contact.name : this.userName));
    
      __out.push('\n    </label>\n    ');
    
      if (this.isMember && !_.isEmpty(this.client_title)) {
        __out.push('\n    <div class="font-16 in-grey-1050">');
        __out.push(__sanitize(this.client_title));
        __out.push('</div>\n    ');
      }
    
      __out.push('\n  </div>\n  <!-- Remove Dropdown Menu Begin -->\n  <div class="header__item-wrapper pull-right ');
    
      __out.push(__sanitize(!this.isMember && this.business_contact.contact_item && this.business_contact.contact_item.type === '' ? 'hidden' : ''));
    
      __out.push('" id=\'user-dropdown\'>\n    <div class=\'team-removepic font-20\' id=\'user-icon\'>\n      <i class="icon icon-3-dots" />\n    </div>\n    <div class=\'header__dropdown-menu-wrapper team-business-menu\'>\n      <ul class=\'header__dropdown-menu m-b-0\'>\n        ');
    
      if (this.business_contact.contact_item && this.business_contact.contact_item.type === 'Invitation') {
        __out.push('\n          <li>\n            <button type="button" class=\'retract-invite-js font-14 font-bold in-red-400 m-r-30\'>\n              <icon class="icon icon-thumbs-down m-l-5 m-r-5"></icon>\n              Retract Invitation\n            </button>\n          </li>\n        ');
      } else {
        __out.push('\n          ');
        if (this.isMember) {
          __out.push('\n            <li>\n              <button type="button" class=\'remove-business-js font-14 font-bold in-red-400 m-r-30\'>\n                <icon class="icon icon-thumbs-down m-l-5 m-r-5"></icon>\n                Remove From Client\n              </button>\n            </li>\n          ');
        } else {
          __out.push('\n            ');
          if (this.isClient) {
            __out.push('\n              <li>\n                <button type="button" class=\'remove-business-js font-14 font-bold in-red-400 m-r-30\'>\n                  <icon class="icon icon-thumbs-down m-l-5 m-r-5"></icon>\n                  Remove client\n                </button>\n              </li>\n            ');
          } else {
            __out.push('\n              <li>\n                <button type="button" class=\'remove-business-js font-14 font-bold in-red-400 m-r-30\'>\n                  <icon class="icon icon-thumbs-down m-l-5 m-r-5"></icon>\n                  Remove from business\n                </button>\n              </li>\n              <li>\n                <button type="button" class=\'resend-invite-js font-14 font-bold in-black m-r-30\'>\n                  <icon class="icon icon-merge-invite m-l-5 m-r-5"></icon>\n                  Re-send invite\n                </button>\n              </li>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n      </ul>\n    </div>\n  </div>\n  <!-- Remove Dropdown Menu End -->\n</div>\n<!--Resent notification Start-->\n');
    
      if (this.business_contact.contact_item && this.business_contact.contact_item.type === 'Invitation') {
        __out.push('\n<div class="alert alert-info invite-notify-panel">\n  <a href="javascript:;" class="resend-invite-btn">Resend Invitation</a>\n  <span class="icon-mail-out font-16 m-r-10"></span>\n  <span class="font-14">An email invitation has been sent to ');
        __out.push(__sanitize(this.userName));
        __out.push('. ');
        __out.push(__sanitize(this.firstName));
        __out.push(' needs to accept the invitation to join this business.<span>\n</div>\n');
      }
    
      __out.push('\n<!--Resent notification End-->\n\n');
    
      if (this.business_contact.contact_item && this.business_contact.contact_item.type === '') {
        __out.push('\n<div class="add-business-wrapper">\n  <p class="font-16">');
        __out.push(__sanitize(this.userName));
        __out.push(' is not added to this business.</p>\n  <p class="font-16">Click the button below to add ');
        __out.push(__sanitize(this.userName));
        __out.push(' to ');
        __out.push(__sanitize(this.business_contact.name));
        __out.push('.</p>\n  <button type=\'button\' class="btn ladda-button add-busines-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Add to Business</span></button>\n</div>\n');
      } else {
        __out.push('\n');
        if (this.isTeamMember) {
          __out.push('\n<div class="p-t-10 item-divider m-r-0">\n  <div class="font-18 font-semi-bold m-t-20 m-b-25">Profile Info</div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Email</label>\n    <div class = "col-md-7 height-100-percent">');
          __out.push(__sanitize(this.userEmail));
          __out.push('</div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Phone Number</label>\n    <div class = "col-md-7 height-100-percent">');
          __out.push(__sanitize(this.userPhone));
          __out.push('</div>\n  </div>\n</div>\n');
        }
        __out.push('\n<div class="');
        __out.push(__sanitize(!this.isTeamMember ? 'm-l-20' : 'p-t-10 item-divider'));
        __out.push(' m-r-0">\n  ');
        if (this.isClient || this.isMember) {
          __out.push('\n  <div class="row m-t-20 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Role</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-client-role-js">');
          __out.push(__sanitize(this.client_role === 'accountant' ? 'Account Manager' : 'Staff (Hidden from clients)'));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n          <li class="client-role-item-js ');
          if (this.client_role === 'accountant') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="accountant">Account Manager</li>\n          <li class="client-role-item-js ');
          if (this.client_role === 'staff') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="staff">Staff (Hidden from clients)</li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
          if (this.client_role === 'accountant') {
            __out.push('\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Title (Visible to Client)</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-title-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-client-title-js">');
            __out.push(__sanitize(this.client_title));
            __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-title-dropdown-btn">\n          <li class="client-title-item-js ');
            if (this.client_title === 'CFO') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="CFO">CFO</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Controller') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Controller">Controller</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Partner') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Partner">Partner</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Manager') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Manager">Manager</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Staff') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Staff">Staff</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'BookKeeper') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="BookKeeper">BookKeeper</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Intern') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Intern">Intern</li>\n          <li class="client-title-item-js ');
            if (this.client_title === 'Sr. BookKeeper') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="Sr. BookKeeper">Sr. BookKeeper</li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
          }
          __out.push('\n  ');
        } else {
          __out.push('\n    <div class="row business-role-wrapper">\n        <label class="col-md-5 font-16 m-t-12">');
          __out.push(__sanitize(!this.isTeamMember ? 'Business Role' : 'Role'));
          __out.push('</label>\n        <div class = "col-md-7 height-100-percent">\n            <div class="permission-dropdown-wrapper">\n              <div class="dropdown-toggle permission-dropdown-control ');
          __out.push(__sanitize(this.business_contact.contact_item.type === 'Invitation' ? 'disabled' : ''));
          __out.push('" id="business-role-dropdown-btn" data-toggle="dropdown">\n                <span class="sel-business-role-js">');
          __out.push(__sanitize(this.business_contact.contact_item.type === 'BusinessPartner' || (this.business_contact.contact_item.type === 'Invitation' && this.business_contact.invitation_type === 'BusinessPartner') ? 'Business Admin' : 'Employee'));
          __out.push('</span>\n                <span class="fa fa-caret-down"></span>\n              </div>\n              <ul class="dropdown-menu" aria-labelledby="business-role-dropdown-btn">\n                <li class="business-role-item-js ');
          if (this.business_contact.contact_item.type === 'BusinessPartner') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="BusinessPartner">Business Admin</li>\n                <li class="business-role-item-js ');
          if (this.business_contact.contact_item.type === 'Employee') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="Employee">Employee</li>\n              </ul>\n            </div>\n        </div>\n    </div>\n    <div class="row business-role-wrapper">\n        <label class="col-md-5 font-16 m-t-12">User Management</label>\n        <div class = "col-md-7 height-100-percent">\n          <div class="permission-dropdown-wrapper">\n            <div class="dropdown-toggle permission-dropdown-control ');
          if (this.business_contact.contact_item.type === 'Invitation' || this.business_contact.contact_item.type === 'BusinessPartner') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="user-management-dropdown-btn" data-toggle="dropdown">\n              <span class="sel-user-management-js">');
          __out.push(__sanitize(this.employeeManagementStatus || this.business_contact.contact_item.type === 'BusinessPartner' || (this.business_contact.contact_item.type === 'Invitation' && this.business_contact.invitation_type === 'BusinessPartner') ? 'Active' : 'Not Active'));
          __out.push('</span>\n              <span class="fa fa-caret-down"></span>\n            </div>\n            ');
          if (this.business_contact.contact_item.type === 'Employee') {
            __out.push('\n            <ul class="dropdown-menu" aria-labelledby="user-management-dropdown-btn">\n              <li class="user-management-item-js ');
            if (this.employeeManagementStatus) {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="active">Active</li>\n              <li class="user-management-item-js ');
            if (!this.employeeManagementStatus) {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="not_active">Not Active</li>\n            </ul>\n            ');
          }
          __out.push('\n          </div>\n        </div>\n    </div>\n  ');
        }
        __out.push('\n  ');
        if (this.isBusinessAdmin || this.isActivatedEmployeeManagement || Docyt.currentAdvisor.get('id') === this.userId) {
          __out.push('\n  <div class="font-18 font-semi-bold ');
          __out.push(__sanitize(!this.isTeamMember ? 'm-t-20 m-b-20' : 'm-t-63 m-b-25'));
          __out.push('">Permissions</div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Accounts Payable</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ap_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ap-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-account-js">');
          __out.push(__sanitize(this.capitalize(this.ap_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ap-role-dropdown-btn">\n          <li class="account-role-item-js ');
          if (this.ap_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="account-role-item-js ');
          if (this.ap_role === 'UPLOADER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="UPLOADER">\n            Uploader\n            <span class="service-roles">Upload/Edit</span>\n          </li>\n          <li class="account-role-item-js ');
          if (this.ap_role === 'VERIFIER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VERIFIER">\n            Verifier\n            <span class="service-roles">Upload/Edit, Verify</span>\n          </li>\n          <li class="account-role-item-js ');
          if (this.ap_role === 'APPROVER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="APPROVER">\n            Approver\n            <span class="service-roles">Upload/Edit, Verify, Approve</span>\n          </li>\n          <li class="account-role-item-js ');
          if (this.ap_role === 'PAYER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="PAYER">\n            Payer\n            <span class="service-roles">Upload/Edit, Verify, Pay</span>\n          </li>\n          <li class="account-role-item-js ');
          if (this.ap_role === 'APPROVER_AND_PAYER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="APPROVER_AND_PAYER">\n            Approver And Payer\n            <span class="service-roles">Upload/Edit, Verify, Approve, Pay</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Receipt Box</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.rb_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="rb-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-receipt-js">');
          __out.push(__sanitize(this.rb_role === 'VERIFIER' ? 'Approver' : this.capitalize(this.rb_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="rb-role-dropdown-btn">\n          <li class="receipt-role-item-js ');
          if (this.rb_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="receipt-role-item-js ');
          if (this.rb_role === 'UPLOADER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="UPLOADER">\n            Uploader\n            <span class="service-roles">Upload/Edit</span>\n          </li>\n          <li class="receipt-role-item-js ');
          if (this.rb_role === 'VERIFIER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VERIFIER">\n            Approver\n            <span class="service-roles">Upload/Edit, Approve</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Business Reports</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.rp_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="rp-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-report-js">');
          __out.push(__sanitize(this.rp_role === 'BOOK_KEEPER' ? 'Admin' : this.capitalize(this.rp_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="rp-role-dropdown-btn">\n          <li class="report-role-item-js ');
          if (this.rp_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="report-role-item-js ');
          if (this.rp_role === 'VIEWER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">View</span>\n          </li>\n          <li class="report-role-item-js ');
          if (this.rp_role === 'BOOK_KEEPER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="BOOK_KEEPER">\n            Admin\n            <span class="service-roles">View, Upload/Edit, Verify</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Banking Accounts</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ba_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ba-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-bank-js">');
          __out.push(__sanitize(this.capitalize(this.ba_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ba-role-dropdown-btn">\n          <li class="bank-role-item-js ');
          if (this.ba_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="bank-role-item-js ');
          if (this.ba_role === 'VIEWER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">View</span>\n          </li>\n          <li class="bank-role-item-js ');
          if (this.ba_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">View, Upload/Edit, Verify</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Revenue Center</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.rv_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="rv-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-revenue-js">');
          __out.push(__sanitize(this.capitalize(this.rv_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="rv-role-dropdown-btn">\n          <li class="revenue-role-item-js ');
          if (this.rv_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="revenue-role-item-js ');
          if (this.rv_role === 'UPLOADER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="UPLOADER">\n            Uploader\n            <span class="service-roles">View, Upload/Edit</span>\n          </li>\n          <li class="revenue-role-item-js ');
          if (this.rv_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">View, Upload/Edit, Verify, Admin</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Reconciliation Center</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.rc_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="rc-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-reconciliation-js">');
          __out.push(__sanitize(this.capitalize(this.rc_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="rc-role-dropdown-btn">\n          <li class="reconciliation-role-item-js ');
          if (this.rc_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="reconciliation-role-item-js ');
          if (this.rc_role === 'ACCOUNTANT') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ACCOUNTANT">\n            Accountant\n            <span class="service-roles">View, Reconcile</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20 ');
          __out.push(__sanitize(this.hiddenWhenDisabled('accounts_receivable')));
          __out.push('">\n    <label class="col-md-5 font-16 m-t-12">Accounts Receivable</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ar_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ar-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-receivable-js">');
          __out.push(__sanitize(this.capitalize(this.ar_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ar-role-dropdown-btn">\n          <li class="receivable-role-item-js ');
          if (this.ar_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="receivable-role-item-js ');
          if (this.ar_role === 'VIEWER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">View</span>\n          </li>\n          <li class="receivable-role-item-js ');
          if (this.ar_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">View, Upload/Edit</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Business Mailroom</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ib_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ma-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-inbox-js">');
          __out.push(__sanitize(this.capitalize(this.ib_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ma-role-dropdown-btn">\n          <li class="inbox-role-item-js ');
          if (this.ib_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="inbox-role-item-js ');
          if (this.ib_role === 'UPLOADER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="UPLOADER">\n            Uploader\n            <span class="service-roles">Uploader</span>\n          </li>\n          <li class="inbox-role-item-js ');
          if (this.ib_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">Admin</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Vendors</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.vd_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="vd-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-vendor-js">');
          __out.push(__sanitize(this.capitalize(this.vd_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="vd-role-dropdown-btn">\n          <li class="vendor-role-item-js ');
          if (this.vd_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="vendor-role-item-js ');
          if (this.vd_role === 'VIEWER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">View</span>\n          </li>\n          <li class="vendor-role-item-js ');
          if (this.vd_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">View, Edit</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Vault</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.vt_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ma-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-vault-js">');
          __out.push(__sanitize(this.capitalize(this.vt_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ma-role-dropdown-btn">\n          <li class="vault-role-item-js ');
          if (this.vt_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="vault-role-item-js ');
          if (this.vt_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">Admin</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Expense Reports</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ep_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="vd-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-expense-js">');
          __out.push(__sanitize(this.capitalize(this.ep_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="vd-role-dropdown-btn">\n          <li class="expense-role-item-js ');
          if (this.ep_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="expense-role-item-js ');
          if (this.ep_role === 'PARTICIPANT') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="PARTICIPANT">\n            Participant\n            <span class="service-roles">Participant</span>\n          </li>\n          <li class="expense-role-item-js ');
          if (this.ep_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">Participant, Admin</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Metrics</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.ms_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ms-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-metrics-js">');
          __out.push(__sanitize(this.capitalize(this.ms_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ms-role-dropdown-btn">\n          <li class="metrics-role-item-js ');
          if (this.ms_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="metrics-role-item-js ');
          if (this.ms_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">View, Edit</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Tasks</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
          if (this.tk_role === 'None') {
            __out.push(__sanitize('disabled-control'));
          }
          __out.push('" id="ma-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-tasks-js">');
          __out.push(__sanitize(this.capitalize(this.tk_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ma-role-dropdown-btn">\n          <li class="tasks-role-item-js ');
          if (this.tk_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="None">None</li>\n          <li class="tasks-role-item-js ');
          if (this.tk_role === 'VIEWER') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">Viewer</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
          if (!this.isClient && !this.isMember) {
            __out.push('\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Dashboard</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
            if (this.ds_role === 'None') {
              __out.push(__sanitize('disabled-control'));
            }
            __out.push('" id="ma-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-dashboard-js">');
            __out.push(__sanitize(this.capitalize(this.ds_role)));
            __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ma-role-dropdown-btn">\n          <li class="dashboard-role-item-js ');
            if (this.ds_role === 'None') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="None">None</li>\n          <li class="dashboard-role-item-js ');
            if (this.ds_role === 'VIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">Viewer</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Flagged Transactions</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
            if (this.ft_role === 'None') {
              __out.push(__sanitize('disabled-control'));
            }
            __out.push('" id="ft-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-flagged-transactions-js">');
            __out.push(__sanitize(this.capitalize(this.ft_role)));
            __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ft-role-dropdown-btn">\n          <li class="flagged-transactions-item-js ');
            if (this.ft_role === 'None') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="None">None</li>\n          <li class="flagged-transactions-item-js ');
            if (this.ft_role === 'VIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">Viewer</span>\n          </li>\n          <li class="flagged-transactions-item-js ');
            if (this.ft_role === 'REVIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="REVIEWER">\n            Reviewer\n            <span class="service-roles">Reviewer</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
          }
          __out.push('\n   <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Data Exports</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control" id="vd-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-data-export-js">');
          __out.push(__sanitize(this.capitalize(this.de_role)));
          __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="vd-role-dropdown-btn">\n          <li class="data-export-role-item-js ');
          if (this.de_role === 'STANDARD' || this.de_role === 'None') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="STANDARD">\n            Standard\n            <span class="service-roles">Standard</span>\n          </li>\n          <li class="data-export-role-item-js ');
          if (this.de_role === 'ADMIN') {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="ADMIN">\n            Admin\n            <span class="service-roles">Admin</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
          if (!this.isClient && !this.isMember) {
            __out.push('\n  <div class="row m-t-5 m-b-20">\n    <label class="col-md-5 font-16 m-t-12">Document Requests</label>\n    <div class = "col-md-7 height-100-percent">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control ');
            if (this.doc_role === 'None') {
              __out.push(__sanitize('disabled-control'));
            }
            __out.push('" id="ma-role-dropdown-btn" data-toggle="dropdown">\n          <span class="sel-doc-js">');
            __out.push(__sanitize(this.capitalize(this.doc_role)));
            __out.push('</span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu service-roles-menu" aria-labelledby="ma-role-dropdown-btn">\n          <li class="doc-role-item-js ');
            if (this.doc_role === 'None') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="None">None</li>\n          <li class="doc-role-item-js ');
            if (this.doc_role === 'VIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="VIEWER">\n            Viewer\n            <span class="service-roles">Viewer</span>\n          </li>\n          <li class="doc-role-item-js ');
            if (this.doc_role === 'REVIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="REVIEWER">\n            Reviewer\n            <span class="service-roles">View, Edit</span>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n  ');
            if (!(this.isClient || this.isMember)) {
              __out.push('\n    <div class="row m-t-5 m-b-20">\n      <label class="col-md-5 font-16 m-t-12">Categorization</label>\n      <div class = "col-md-7 height-100-percent">\n        <div class="permission-dropdown-wrapper">\n          <div class="dropdown-toggle permission-dropdown-control" id="ca-role-dropdown-btn" data-toggle="dropdown">\n            <span class="sel-categorization-js">');
              __out.push(__sanitize(this.capitalize(this.ca_role)));
              __out.push('</span>\n            <span class="fa fa-caret-down"></span>\n          </div>\n          <ul class="dropdown-menu service-roles-menu" aria-labelledby="ca-role-dropdown-btn">\n            <li class="categorization-role-item-js ');
              if (this.ca_role === 'NOT_ACTIVE') {
                __out.push(__sanitize('selected'));
              }
              __out.push('" data-value="NOT_ACTIVE">\n              Not Active\n              <span class="service-roles">Not Active</span>\n            </li>\n            <li class="categorization-role-item-js ');
              if (this.ca_role === 'ACTIVE') {
                __out.push(__sanitize('selected'));
              }
              __out.push('" data-value="ACTIVE">\n              Active\n              <span class="service-roles">Active</span>\n            </li>\n          </ul>\n        </div>\n      </div>\n    </div>\n  ');
            }
            __out.push('\n  ');
          }
          __out.push('\n  ');
          if (this.isChatCentralFeatureEnabled) {
            __out.push('\n    <div class="row m-t-5 m-b-20">\n      <label class="col-md-5 font-16 m-t-12">Business Chats</label>\n      <div class = "col-md-7 height-100-percent">\n        <div class="permission-dropdown-wrapper">\n          <div class="dropdown-toggle permission-dropdown-control ');
            if (this.cc_role === 'None') {
              __out.push(__sanitize('disabled-control'));
            }
            __out.push('" id="ft-role-dropdown-btn" data-toggle="dropdown">\n            <span class="sel-chat-central-js">');
            __out.push(__sanitize(this.capitalize(this.cc_role)));
            __out.push('</span>\n            <span class="fa fa-caret-down"></span>\n          </div>\n          <ul class="dropdown-menu service-roles-menu" aria-labelledby="ft-role-dropdown-btn">\n            <li class="chat-central-role-item-js ');
            if (this.cc_role === 'None') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="None">None</li>\n            <li class="chat-central-role-item-js ');
            if (this.cc_role === 'VIEWER') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="VIEWER">\n              Viewer\n              <span class="service-roles">Viewer</span>\n            </li>\n            <li class="chat-central-role-item-js ');
            if (this.cc_role === 'ADMIN') {
              __out.push(__sanitize('selected'));
            }
            __out.push('" data-value="ADMIN">\n              Admin\n              <span class="service-roles">Admin</span>\n            </li>\n          </ul>\n        </div>\n      </div>\n    </div>\n  ');
          }
          __out.push('\n  ');
        }
        __out.push('\n</div>\n<div class="row m-t-5 m-b-20 payment-accounts-container"></div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}