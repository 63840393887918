import React, { useCallback, useState } from 'react';

import { useRequestBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import { IRequestDocumentFormValues } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_document_details/actions/request_document/form';
import { useRequestDocumentModal } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_document_details/actions/request_document/modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface IRequestStatementActionProps {
  businessId: TID,
  state: IStatementState,
  chartOfAccountId: TID,
}

const RequestStatementAction = ({
  businessId,
  state,
  chartOfAccountId,
}: IRequestStatementActionProps): JSX.Element => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
  string | boolean | undefined>(
    LDFeatureValueForUser(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ),
  );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  const requestBalanceSheetStatement = useRequestBalanceSheetStatement();
  const { mutate } = requestBalanceSheetStatement;

  const redirectToClientCenteral = useCallback(() => {
    Backbone.history.navigate(
      sunsetClientCentralEnabled
        ? documentRequestsPath(businessId) : clientCenteralPath(businessId),
      { trigger: true },
    );
  }, [businessId, sunsetClientCentralEnabled]);

  const handleRequestStatement = useCallback((values: IRequestDocumentFormValues) => {
    const firstDate = new Date(state.month);
    const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
    mutate({
      businessId,
      chartOfAccountId,
      date:        formatDate(lastDate, API_DATE_FORMAT),
      description: values.description,
    }, {
      onSuccess: () => redirectToClientCenteral(),
    });
  }, [businessId, chartOfAccountId, mutate, redirectToClientCenteral, state.month]);

  const requestDocumentModal = useRequestDocumentModal({
    onDone: handleRequestStatement,
  });

  return (
    <>
      <MutationStatus mutation={ requestBalanceSheetStatement } />
      <requestDocumentModal.Component
        chartOfAccountName={ state?.chartOfAccountName }
        month={ state?.month }
        { ...requestDocumentModal.props }
      />
      <ActionsDropdown.Action
        title="Request Document"
        onClick={ requestDocumentModal.open }
      />
    </>
  );
};

export default RequestStatementAction;
