module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, i, len;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog person-edit-form-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">Edit W-9 Form Info</h4>\n      </div>\n      <div class="modal-body">\n        <div class="text-center">\n          <div class="profile-content-wrapper">\n            <div class="profile-input-wrap m-b-20">\n              <label class="">Legal Entity Name</label>\n              <input class="input-entity-name form-control" type="text" placeholder="Legal Entity Name" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Legal Entity Type</label>\n              <select class="form-control width-70-percent input-entity-type">\n                <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["SOLE"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Sole Proprietorship</option>\n                <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["CORP"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["CORP"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Corporation</option>\n                <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["LLC"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["LLC"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>LLC</option>\n                <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["PARTNER"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["PARTNER"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Partnership</option>\n              </select>\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="">Legal Entity Address</label>\n              <input class="input-address-street form-control" placeholder="Address Line 1" type="text" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_line1')));
    
      __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n            </div>\n            <div class="profile-input-wrap">\n              <label class=""></label>\n              <div class="business-address-wrapper">\n                <div class="p-r-5 relative">\n                  <input class="input-address-city form-control" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_city')));
    
      __out.push('">\n                  <p class="valid-hint-js  in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="p-r-5">\n                  <select class="input-address-state form-control">\n                    ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n                    ');
        if (address_state.abbreviation === this.w9_address.get('address_state')) {
          __out.push('\n                    <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" selected>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                    ');
        } else {
          __out.push('\n                    <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('">');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                    ');
        }
        __out.push('\n                    ');
      }
    
      __out.push('\n                  </select>\n                </div>\n                <div class="relative">\n                  <input class="input-address-zip form-control" type="text" placeholder="Zip" value="');
    
      __out.push(__sanitize(this.w9_address.get('address_zip')));
    
      __out.push('">\n                  <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n              </div>\n            </div>\n            <div class="profile-input-wrap">\n              <label class="label-tax-id">Taxpayer Identification Number (TIN)</label>\n              <input class="input-tax-id form-control" type="text" placeholder="" value="');
    
      __out.push(__sanitize(this.taxId));
    
      __out.push('">\n              <p class="valid-hint-js in-red-400"><i class="fa fa-warning in-red-400"></i></p>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n        <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}