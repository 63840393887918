import React, { useCallback, useEffect, useState } from 'react';

import { ISelfOnboardingBusiness } from '@src/types/self_onboarding_invitation';
import { openQBOConnectWindow } from '@src/utils/qbo_connect';

import Table from '@src/components/ui/table';
import QboConnectStatusIcon from '@src/components/utils/icomoon/qbo_connect_status';

interface IConnectQboItemProps {
  business: ISelfOnboardingBusiness,
}

const ConnectQboListItem = ({
  business,
}: IConnectQboItemProps): JSX.Element => {
  const [qboConnected, setQboConnected] = useState<boolean>(business.isQboConnected);
  const handleConnect = useCallback(() => {
    openQBOConnectWindow(business.id);
  }, [business.id]);

  useEffect(() => {
    window.Docyt.vent.on('business:quickbooks:connected', (businessId: number) => {
      if (businessId !== business.id) return;
      setQboConnected(true);
    });

    return () => {
      window.Docyt.vent.off('business:quickbooks:connected');
    };
  }, [business.id, setQboConnected]);

  return (
    <Table.Row>
      <Table.Cell widthPercent={ 60 }>
        { business.displayName }
      </Table.Cell>
      <Table.Cell widthPercent={ 40 }>
        {
          qboConnected ? (
            <div className="action-column in-green-1000">
              <QboConnectStatusIcon fontSize={ 28 } mr={ 5 } variant="connected" />
              Connected
            </div>
          ) : (
            <a
              className="action-column pointer in-purple-1000"
              role="button"
              tabIndex={ -1 }
              onClick={ handleConnect }
            >
              <QboConnectStatusIcon fontSize={ 28 } mr={ 5 } variant="disconnected" />
              Connect QBO
            </a>
          )
        }
      </Table.Cell>
    </Table.Row>
  );
};

export default ConnectQboListItem;
