import React, { useCallback } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { IAccountingClass } from '@src/types/accounting_class';

import Modal from '@src/components/ui/modal';

import Form, { IAddDepartmentFormValue } from './add_department_form';

interface IAddDepartmentProps {
  accountingClasses: IAccountingClass[],
  isOpen: boolean,
  onCancel: () => void,
  onDone: (value: IAddDepartmentFormValue) => void,
  mappedIds: string[]
}

const AddDepartmentModal = ({
  accountingClasses,
  isOpen,
  onDone,
  onCancel,
  mappedIds,
}: IAddDepartmentProps): JSX.Element => {
  const handleSubmit = useCallback((value: IAddDepartmentFormValue) => {
    onDone(value);
  }, [onDone]);
  return (
    <Modal.Form
      proceedTitle="Map Departments"
      show={ isOpen }
      title="Map Departments to Category"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          accountingClasses={ accountingClasses }
          formId={ formId }
          mappedIds={ mappedIds.map((i) => Number(i)) }
          onSubmit={ handleSubmit }
        />
      )}
    </Modal.Form>
  );
};

const useAddDepartmentModal = makeUseModal(AddDepartmentModal);

export default useAddDepartmentModal;
