import React from 'react';

import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { PlusIcon, BankStatementsPendingReviewBlankIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IBlankPendingReviewPageProps {
  onUploadClick: () => void,
}

const BlankPendingReviewPage = ({
  onUploadClick,
}: IBlankPendingReviewPageProps) => {
  return (
    <>
      <DetailsPageTitle
        breadcrumbs={ {
          title: 'Bank Statements',
        } }
        title="Pending Review"
      />
      <div className={ styles['blank-pending-review-page'] }>
        <div className={ styles['blank-pending-review-body'] }>
          <BankStatementsPendingReviewBlankIcon mb={ 20 } />
          <span>
            No Statements to show here. Upload your first
            <br />
            statement by clicking the upload button below.
          </span>
          <Button
            bsColor="blue"
            className="font-semi-bold"
            onClick={ onUploadClick }
          >
            <PlusIcon mr={ 10 } />
            Upload
          </Button>
        </div>
      </div>
    </>
  );
};

export default BlankPendingReviewPage;
