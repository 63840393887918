import React, { useState, useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import { useFilter } from '@src/hooks/url_params';
import { TFilterData } from '@src/types/filter';
import { IChartOfAccount } from '@src/types/report_service/report';

import Filter from '@src/components/ui/filter';
import Text from '@src/components/ui/text';
import {
  SelectAllCheckbox,
  SelectItemCheckbox,
} from '@src/components/utils/items_selector';

import AccountTypeMultiSelector from './accounts_multi_selector';

interface IAcceptedAccountsModalBodyProps {
  chartOfAccounts: IChartOfAccount[],
}

interface IAccountDetailTypeFilter extends TFilterData {
  name: string,
}

interface IChartOfAccountFilter extends TFilterData {
  name: string,
}

const AcceptedAccountsModalBody = ({
  chartOfAccounts,
}: IAcceptedAccountsModalBodyProps): JSX.Element => {
  const [selectedType, setSelectedType] = useState<null | string>(null);
  const [selectedDetailType, setSelectedDetailType] = useState<null | string>(null);
  const filter = useFilter<IAccountDetailTypeFilter>({});
  const chartOfAccountFilter = useFilter<IChartOfAccountFilter>({});

  const bigTypes = useMemo(() => (
    uniqBy(chartOfAccounts, 'accountType').map(((chartOfAccount) => chartOfAccount.accountType))
  ), [chartOfAccounts]);

  const items = useMemo(() => (
    chartOfAccounts.filter((chartOfAccount) => (chartOfAccount.accountType === selectedType && chartOfAccount.accountDetailType.toLowerCase().includes(filter.data ? filter.data.name.toLowerCase() : '')))
  ), [chartOfAccounts, filter.data, selectedType]);

  const chartOfAccountItems = useMemo(() => (
    chartOfAccounts.filter((chartOfAccount) => (chartOfAccount.accountType === selectedType && chartOfAccount.accountDetailType === selectedDetailType && chartOfAccount.name && chartOfAccount.name.toLowerCase().includes(chartOfAccountFilter.data ? chartOfAccountFilter.data.name.toLowerCase() : '')))
  ), [chartOfAccounts, selectedType, selectedDetailType, chartOfAccountFilter.data]);

  const getDetailTypes = (detailType: string) => {
    return chartOfAccounts.filter((chartOfAccount) => (
      chartOfAccount.accountType === selectedType
        && chartOfAccount.accountDetailType === detailType
        && chartOfAccount.name));
  };

  return (
    <div className="display-flex">
      <div className="account-type-list">
        <div className="account-type-list-header">
          <Text className="font-semi-bold">Account Types</Text>
        </div>
        <div className="account-type-list-body">
          <div className="display-flex align-items-center account-type-item bottom-divider">
            <SelectAllCheckbox />
            <Text>Select All</Text>
          </div>
          {
            bigTypes.map((type, index) => (
              <div
                key={ type }
                className="display-flex align-items-center justify-content-between account-type-item p-r-20"
                role="button"
                tabIndex={ index }
                onClick={ () => setSelectedType(type) }
              >
                <AccountTypeMultiSelector
                  partialCheckEnabled
                  items={ chartOfAccounts.filter(
                    (chartOfAccount) => chartOfAccount.accountType === type,
                  ) }
                  label={ type }
                  uniqKey="accountDetailType"
                />
                <span className={ `fa ${selectedType === type ? 'fa-caret-right' : 'fa-caret-down'}` } />
              </div>
            ))
          }
        </div>
      </div>
      <div className="account-type-list">
        <div className="account-type-list-header">
          <Text className="font-semi-bold">Detail Types</Text>
        </div>
        <div className="account-type-list-body">
          {
            selectedType ? (
              <>
                <Filter<IAccountDetailTypeFilter> className="account-type-name-filter" filter={ filter }>
                  <Filter.TextField
                    name="name"
                    placeholder="Search by Name of Detail Type"
                    widthPercent={ 100 }
                  />
                </Filter>
                <div className="account-details-type-list">
                  <div
                    className="display-flex align-items-center account-type-item bottom-divider"
                    role="button"
                    tabIndex={ 0 }
                  >
                    <AccountTypeMultiSelector
                      items={ items }
                      label="Select All"
                      uniqKey="accountDetailType"
                    />
                  </div>
                  {
                    uniqBy(items, 'accountDetailType').map((item, index) => (
                      <div
                        key={ item.accountDetailType }
                        className="display-flex align-items-center justify-content-between account-type-item"
                        role="button"
                        tabIndex={ index }
                        onClick={ () => setSelectedDetailType(item.accountDetailType) }
                      >
                        <AccountTypeMultiSelector
                          partialCheckEnabled
                          items={ getDetailTypes(item.accountDetailType) }
                          label={ item.accountDetailType }
                        />
                        <span className={ `fa ${selectedDetailType === item.accountDetailType ? 'fa-caret-right' : 'fa-caret-down'}` } />
                      </div>
                    ))
                  }
                </div>
              </>
            ) : (
              <div className="height-100-percent display-flex justify-content-center align-items-center">
                <Text>Choose accounts type to view details type</Text>
              </div>
            )
          }
        </div>
      </div>
      <div className="account-type-list">
        <div className="account-type-list-header">
          <Text className="font-semi-bold">Chart of Accounts</Text>
        </div>
        <div className="account-type-list-body">
          {
            selectedDetailType ? (
              <>
                <Filter<IChartOfAccountFilter> className="account-type-name-filter" filter={ chartOfAccountFilter }>
                  <Filter.TextField
                    name="name"
                    placeholder="Search by Chart of Accounts"
                    widthPercent={ 100 }
                  />
                </Filter>
                <div className="chart-of-accounts-list">
                  <div
                    className="display-flex align-items-center chart-of-account-item bottom-divider"
                    role="button"
                    tabIndex={ 0 }
                  >
                    <AccountTypeMultiSelector
                      items={ chartOfAccountItems }
                      label="Select All"
                    />
                  </div>
                  {
                    chartOfAccountItems.map((item, index) => (
                      <div
                        key={ item.name }
                        className="display-flex align-items-center justify-content-between chart-of-account-item"
                        role="button"
                        tabIndex={ index }
                      >
                        <SelectItemCheckbox
                          checkedLabel={ item.name }
                          item={ item }
                          uncheckedLabel={ item.name }
                        />
                      </div>
                    ))
                  }
                </div>
              </>
            ) : (
              <div className="height-100-percent display-flex justify-content-center align-items-center">
                <Text>Choose detail type to view chart of accounts</Text>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default AcceptedAccountsModalBody;
