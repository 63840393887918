import { TDate, TID, TOrderDirection, TUUID } from '@src/types/common';
import { IMetricsReport, TMetricReportsSortColumn } from '@src/types/metrics/metrics_reports';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPut } from '../helpers';

interface IGetMetricsReportsParams {
  businessId: TID,
  orderColumn?: TMetricReportsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
  perPage?: number,
}

interface IGetMetricsReportsResponse {
  meta: {
    totalCount: number,
  },
  collection: IMetricsReport[]
}

const getMetricsReports = (
  params: IGetMetricsReportsParams,
): Promise<IGetMetricsReportsResponse> => {
  return apiGet(
    '/metrics/api/v1/metrics_reports',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta:       cdata.meta,
      collection: cdata.metricsReports as IMetricsReport[],
    };
  });
};

interface IAssignMetricsReportDateParams {
  id: TUUID
  reportDate: TDate,
}

interface IAssignMetricsReportDateResponse {
  data: IGetMetricsReportsResponse
}

const assignMetricReportDate = ({
  id,
  ...params
} : IAssignMetricsReportDateParams): Promise<IAssignMetricsReportDateResponse> => {
  return apiPut(
    `/metrics/api/v1/metrics_reports/${id}/date`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IAssignMetricsReportDateResponse;
  });
};

interface IDeleteMetricsReportParams {
  metricsReportId: TUUID
}

const deleteMetricsReport = (
  params: IDeleteMetricsReportParams,
): Promise<void> => {
  return apiDelete(
    `/metrics/api/v1/metrics_reports/${params.metricsReportId}`,
  );
};

export {
  assignMetricReportDate,
  IAssignMetricsReportDateParams,
  IAssignMetricsReportDateResponse,
  getMetricsReports,
  IGetMetricsReportsParams,
  IGetMetricsReportsResponse,
  deleteMetricsReport,
  IDeleteMetricsReportParams,
};
