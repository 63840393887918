import {
  IBusinessMetricValue,
  TBusinessMetricValueSortColumn,
  IBusinessMetricValueByDate,
} from '@src/types/business_metric_value';
import { TDate, TOrderDirection, TUUID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetBusinessMetricValuesParams {
  businessMetricId?: TUUID,
  from: string | undefined,
  to: string,
  filters?: object,
  orderColumn?: TBusinessMetricValueSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
}

interface IGetBusinessMetricValuesResponse {
  meta: {
    totalCount: number,
    availableCount: number,
  },
  collection: IBusinessMetricValue[],
}

interface IValueByDateParams {
  date?: TDate,
  businessMetricId: TUUID,
}

const metricValueByDate = (
  params: IValueByDateParams,
): Promise<IBusinessMetricValueByDate[]> => {
  return apiGet(
    `metrics/api/v1/values/${params.date}`,
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata.values as IBusinessMetricValueByDate[];
  });
};

interface ICreateBusinessMetricBatchValuesParams {
  businessMetricId?: TUUID,
  values: IBusinessMetricValue[],
}

const getBusinessMetricValues = (
  params: IGetBusinessMetricValuesParams,
): Promise<IGetBusinessMetricValuesResponse> => {
  return apiGet(
    'metrics/api/v1/values',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.values as IBusinessMetricValue[],
    };
  });
};

const createBusinessMetricBatchValues = (
  params: ICreateBusinessMetricBatchValuesParams,
): Promise<void> => {
  return apiPost(
    'metrics/api/v1/values/create_batch',
    underscoreKeys(params),
  );
};

export {
  IGetBusinessMetricValuesParams,
  IGetBusinessMetricValuesResponse,
  ICreateBusinessMetricBatchValuesParams,
  IValueByDateParams,
  metricValueByDate,
  getBusinessMetricValues,
  createBusinessMetricBatchValues,
};
