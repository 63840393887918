import React, { useCallback, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';

import DescriptionInfo from './review_changes_description';
import Footer from './review_changes_footer';

import styles from './styles.module.scss';

interface IReviewMergeVendorsModalProps {
  vendor: IBusinessVendor,
  globalVendor?: IBusinessVendor,
  show?: boolean,
  onCancel: () => void,
  onDone: () => void,
}

const ReviewChangesModal = ({
  vendor,
  globalVendor,
  show,
  onCancel,
  onDone,
}: IReviewMergeVendorsModalProps): JSX.Element => {
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleChecked = useCallback(() => {
    setIsSelected((current) => !current);
  }, []);

  const handleMergeClick = useCallback(() => {
    if (!isSelected) {
      return;
    }
    onDone();
  }, [onDone, isSelected]);

  return (
    <Modal.Standard
      dialogClassName="review-changes-modal select-multi-item-area"
      isProceedDisabled={ !isSelected }
      proceedTitle="Merge"
      show={ show }
      title={ (
        <div>
          <span className={ styles['secondary-title'] }>Merge Vendor</span>
          <span> ‣ Validate Info</span>
        </div>
      ) }
      onCancel={ onCancel }
      onProceed={ handleMergeClick }
    >
      {() => (
        <>
          <DescriptionInfo
            globalVendor={ globalVendor }
            localVendor={ vendor }
          />
          <Footer
            globalVendor={ globalVendor }
            isSelected={ isSelected }
            onChecked={ handleChecked }
          />
        </>
      )}
    </Modal.Standard>
  );
};

const useReviewMergeVendorsModal = makeUseModal(ReviewChangesModal);

export {
  useReviewMergeVendorsModal,
  ReviewChangesModal as default,
};
