import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ITableSectionPanelProps extends React.ComponentPropsWithoutRef<'div'> {
  height?: string,
}

const TableSectionPanel = ({
  children,
  className,
  height,
  style,
  ...props
}: ITableSectionPanelProps): JSX.Element => {
  const classes = classNames(styles.panel, className);
  const stl = { ...style || {}, height };

  return (
    <div className={ classes } { ...props } style={ stl }>
      { children }
    </div>
  );
};

export default TableSectionPanel;
