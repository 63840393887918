import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const BankStatementApprovedInactive = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="bank-statement-approved-hover"
      pathsNumber={ 2 }
    />
  );
});

BankStatementApprovedInactive.displayName = 'BankStatementApprovedInactive';

export default BankStatementApprovedInactive;
