import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateDataAddressResponse,
  IAddressBookParams,
  IUpdateAddressBookParams,
  IAddressAuthorizationParams,
  ILedgerAddressParams,
  IRemoveAddressBookParams,
  postAddressBook,
  postRemoveAddressBook,
  putUpdateAddressBook,
  putLedgerAddress,
  changeAddressAuthorization,
  getSuggestedAddressBook,
  ISugestedAddressResponse,
  getAddressVendor,
  IAddressResponse,
} from '@src/requests/vendor_address_book';

const useAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IAddressBookParams>(
    postAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorAddresses);
      },
    },
  );
};

const useUpdateAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IUpdateAddressBookParams>(
    putUpdateAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorAddresses);
      },
    },
  );
};

const useAddressAuthorization = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IAddressAuthorizationParams>(
    changeAddressAuthorization,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorAddresses);
      },
    },
  );
};

const useLedgerAddress = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, ILedgerAddressParams>(
    putLedgerAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorAddresses);
      },
    },
  );
};

const useRemoveAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateDataAddressResponse, Error, IRemoveAddressBookParams>(
    postRemoveAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorAddresses);
      },
    },
  );
};

const useSuggestedAddressBook = () => {
  const queryClient = useQueryClient();

  return useMutation<ISugestedAddressResponse, Error, IAddressBookParams>(
    getSuggestedAddressBook,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.sugestedAddress);
      },
    },
  );
};

const useGetBusinessVendorAddress = (params: any) => {
  return useQuery<IAddressResponse, Error>(
    [QueryKey.vendorAddresses, params],
    () => getAddressVendor(params),
  );
};

export {
  useAddressBook,
  useUpdateAddressBook,
  useAddressAuthorization,
  useLedgerAddress,
  useGetBusinessVendorAddress,
  useRemoveAddressBook,
  useSuggestedAddressBook,
};
