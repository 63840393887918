import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import Alert from '@src/components/ui_v2/alerts/alert';
import { TOption } from '@src/components/ui_v2/inputs';

import DetailInfo from './detail_info';

interface IDetailTabProps {
  isAuthorizedPayer: boolean,
  serviceDocument?: IAccountsPayableServiceDocument,
  document?: IDocument,
  paymentType: TOption | null;
  onSelectType: (paymentType: TOption | null) => void;
}

const DetailTab = ({
  isAuthorizedPayer,
  serviceDocument,
  document,
  paymentType,
  onSelectType,
}: IDetailTabProps) => {
  return (
    <>
      {
        (paymentType?.label !== serviceDocument?.preferredPaymentMethod) && (
          <Alert variant="info">
            { 'The selected payment method is different than this Vendor\'s preferred payment method.' }
          </Alert>
        )
      }
      <DetailInfo
        document={ document }
        isAuthorizedPayer={ isAuthorizedPayer }
        paymentType={ paymentType }
        serviceDocument={ serviceDocument }
        onSelectType={ onSelectType }
      />
    </>
  );
};

export default React.memo(DetailTab);
