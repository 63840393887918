import React from 'react';

import { TFilterData } from '@src/types/filter';

import {
  IFilterFieldUIProps,
  FilterFieldWrapper,
  useFilterField,
  useFilterFieldProps,
} from '@src/components/ui/filter';
import { SelectInput, ISelectInputProps } from '@src/components/ui/form';

const plOptions = [
  { value: '', name: 'All Types' },
  {
    value: window.configData.pl_acc_types.INCOME,
    name:  'Income',
  },
  {
    value: window.configData.pl_acc_types.OTHER_INCOME,
    name:  'Other Income',
  },
  {
    value: window.configData.pl_acc_types.COST_OF_GOODS_SOLD,
    name:  'Cost of Goods Sold',
  },
  {
    value: window.configData.pl_acc_types.EXPENSE,
    name:  'Expense',
  },
  {
    value: window.configData.pl_acc_types.OTHER_EXPENSE,
    name:  'Other Expense',
  },
];

interface ISelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<ISelectInputProps, 'name' | 'value' | 'onChange' | 'className' | 'options'>,
  IFilterFieldUIProps
{
  name?: keyof TFilter,
  accountTypes?: 'P&L',
}

const AccountTypeSelectFilterField = <TFilter extends TFilterData>({
  name = 'accountType',
  accountTypes = 'P&L',
  ...props
}: ISelectFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props, { filtered: value });

  return (
    <FilterFieldWrapper { ...wrapperProps }>
      <SelectInput
        options={ accountTypes === 'P&L' ? plOptions : [] }
        value={ value || '' }
        onChange={ update }
        { ...inputProps }
      />
    </FilterFieldWrapper>
  );
};

export default React.memo(AccountTypeSelectFilterField);
