import React, { useCallback, useRef } from 'react';

import classNames from 'classnames';

import { TYear } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import {
  YearPickerInput,
  IDatePickerInputRef,
} from '@src/components/ui/form';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface IYearFilterFieldProps<TFilter extends TFilterData> extends
  Omit<React.ComponentPropsWithoutRef<typeof YearPickerInput>, 'className' | 'name' | 'value' | 'onChange' >,
  IFilterFieldUIProps
{
  name: keyof TFilter,
  className?: string,
}

const YearFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: IYearFilterFieldProps<TFilter>) => {
  const inputRef = useRef<IDatePickerInputRef>(null);

  const { update, value } = useFilterField<TFilter, TYear>(name);

  const handleClickCaret = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, []);

  const [wrapperProps, inputProps] = useFilterFieldProps(props, 'year-field');
  const inputClasses = classNames('input-group', value ? 'filtered' : '');

  return (
    <Wrapper { ...wrapperProps }>
      <div className={ inputClasses }>
        <YearPickerInput
          ref={ inputRef }
          value={ value }
          onChange={ update }
          { ...inputProps }
        />
        <span
          aria-hidden="true"
          className="input-group-addon"
          role="button"
          onClick={ handleClickCaret }
        >
          <CaretIcon variant="down" />
        </span>
      </div>
    </Wrapper>
  );
};

export default YearFilterField;
