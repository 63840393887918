import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Details from './details';

interface IMatchedItemsProps {
  documentId: TID,
}

const MatchedItemSection = ({
  documentId,
}: IMatchedItemsProps): JSX.Element => {
  return (
    <Root>
      <Details documentId={ documentId } />
    </Root>
  );
};

export default MatchedItemSection;
