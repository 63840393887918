import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const EqualSignCircle = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="equal-sign-circle"
      pathsNumber={ 3 }
    />
  );
};

export default EqualSignCircle;
