import React, { useCallback, useState } from 'react';

import Dropzone from 'react-dropzone';
import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useUploadW9InfoFile, usePutW9InfoFile } from '@src/hooks/queries/vendor_w9_info';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { VendorW9Icon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IW9InfoDropInputProps {
  vendorId: TID,
  businessId: TID,
  modalMode?: string
}

const allowedFileFormat = window.configData.allowed_file_upload_format;

const W9InfoDropInput = ({
  vendorId,
  businessId,
  modalMode,
}: IW9InfoDropInputProps): JSX.Element => {
  const queryClient = useQueryClient();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const uploadW9InfoFile = useUploadW9InfoFile();
  const updateVendorW9InfoFile = usePutW9InfoFile();

  const { mutate: uploadFile } = uploadW9InfoFile;
  const { mutate: updateW9InfoFile } = updateVendorW9InfoFile;

  const handleError = useCallback((response) => {
    setErrorMsg(response?.response?.data?.errors[0]);
  }, []);

  const handleFileSelected = useCallback((files) => {
    const file = files[0];

    if (!allowedFileFormat.includes(file.name.split('.').pop().toLowerCase())) {
      setErrorMsg('Please select PDF file');
      return;
    }

    uploadFile({
      file,
    }, {
      onSuccess: (data) => {
        updateW9InfoFile(
          {
            businessId,
            vendorId,
            w9FileToken: data?.token,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(QueryKey.vendorW9Info);
            },
            onError: handleError,
          },
        );
      },
    });
  }, [uploadFile, handleError, businessId, queryClient, updateW9InfoFile, vendorId]);

  return (
    <>
      {(uploadW9InfoFile.isLoading) && <Spinner />}
      {(updateVendorW9InfoFile.isLoading) && <Spinner />}
      {errorMsg
        && <ErrorNotification message={ errorMsg } title="Alert" onHidden={ () => setErrorMsg(undefined) } />}
      <div className={ modalMode === 'view' ? styles['full-upload-file-container'] : styles['upload-file-container'] }>
        {
          uploadW9InfoFile.isLoading ? (
            <div className={ styles['progress-status'] }>
              <p className="file-upload-status-text font-18">
                Uploading...
              </p>
            </div>
          ) : (
            <Dropzone multiple onDrop={ handleFileSelected }>
              {({ getRootProps, getInputProps }) => (
                <section className={ styles['drop-zone-section'] }>
                  <div className={ styles['drop-zone-section-details'] } { ...getRootProps() }>
                    <VendorW9Icon fontSize={ 60 } />
                    <p className={ styles['drop-zone-text'] }>Drag and drop W9 form here.</p>
                    <p className={ styles['drop-zone-sub-text'] }>OR</p>
                    <Button
                      variant="outline"
                    >
                      Select from your computer
                      <input { ...getInputProps() } />
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          )
        }
      </div>
    </>
  );
};

export default W9InfoDropInput;
