module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1;
    
      __out.push('<td class="receipt-detail-column relative pointer">\n    <div class="checkbox checkbox-primary m-l-15 m-r-15">\n        <input type="checkbox" id="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" class="check-trash-document" data-document-id="');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('">\n        <label for="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n    </div>\n</td>\n<td class="receipt-avatar-column flex-wrapper relative receipt-clickable pointer">\n    <div class="header__userpic inline-block m-l-10">\n        <img src="');
    
      __out.push(__sanitize(this.vendor_avatar));
    
      __out.push('" class="header__userpic-img">\n    </div>\n    <p class="payee-name-truncate font-15 inline-block m-l-15 m-t-10 vertical-align-top" data-toggle="tooltip" data-placement="bottom">');
    
      __out.push(__sanitize((ref = this.vendor) != null ? ref.name : void 0));
    
      __out.push('</p>\n</td>\n<td class="receipt-detail-column receipt-chart-of-account-column relative">\n    ');
    
      if (this.category.split(';').length > 1) {
        __out.push('\n    <a>');
        __out.push(__sanitize(this.category.split(';').length));
        __out.push(' Splits</a>\n    ');
      } else {
        __out.push('\n    ');
        if (this.chart_of_account_name) {
          __out.push('\n    <p>\n        ');
          if (this.chart_of_account_parent_name) {
            __out.push('\n        ');
            __out.push(__sanitize(this.chart_of_account_parent_name));
            __out.push('\n        <i class="fa fa-caret-right"></i>\n        ');
          }
          __out.push('\n        ');
          __out.push(__sanitize(this.chart_of_account_name));
          __out.push('\n    </p>\n    ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n</td>\n<td class="receipt-detail-column receipt-payment-account-column receipt-clickable pointer">\n    <span class="font-14 vertical-align-top p-l-10">');
    
      __out.push(__sanitize((ref1 = this.payment_account) != null ? ref1.account_name : void 0));
    
      __out.push('</span>\n</td>\n<td class="receipt-detail-column relative receipt-clickable pointer">\n    <span class="font-14 p-l-10">');
    
      __out.push(__sanitize(this.receipt_date_string));
    
      __out.push('</span>\n</td>\n<td class="receipt-amount-column relative text-right">\n    <div class="dropdown-toggle font-15 font-bold pointer receipt-trash-amount-button receipt-amount-button" id="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        ');
    
      __out.push(__sanitize(this.display_amount));
    
      __out.push('\n        <span class="fa fa-angle-down m-l-5"></span>\n    </div>\n    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="receipt-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n        <a class="dropdown-item font-14 m-t-5 inline-flex-wrapper pointer restore-link">\n            <i class="icon icon-restore font-24 m-r-6"></i>\n            <p class="m-b-0 p-t-3">Restore</p>\n        </a>\n        <a class="dropdown-item font-14 m-t-5 inline-flex-wrapper pointer delete-link in-red-400">\n            <i class="icon icon-new-trash font-20 m-r-10"></i>\n            <p class="m-b-0 p-t-1">Delete Permanently</p>\n        </a>\n    </div>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}