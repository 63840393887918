import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import Table from '@src/components/ui/table';

import ApprovedStatusItem from './items/approved_status_item';
import MissingStatusItem from './items/missing_status_item';
import NotAvailableStatusItem from './items/not_available_status_item';
import RequestedStatusItem from './items/requested_status_item';
import UploadedStatusItem from './items/uploaded_status_item';
import VerifiedStatusItem from './items/verified_status_item';

interface IBankStatementStateItemProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  state: IReviewedBankStatementState,
  onCloseUploadModal: () => void,
}

const BankStatementStateItem = ({
  businessId,
  rcPaymentAccountId,
  state,
  onCloseUploadModal,
}: IBankStatementStateItemProps): JSX.Element => {
  let content;

  if (state.state === 'requested') {
    content = (
      <RequestedStatusItem state={ state } />
    );
  } else if (state.state === 'uploaded') {
    content = (
      <UploadedStatusItem
        businessId={ businessId }
        state={ state }
        onCloseModal={ onCloseUploadModal }
      />
    );
  } else if (state.state === 'approved') {
    content = (
      <ApprovedStatusItem businessId={ businessId } state={ state } />
    );
  } else if (state.state === 'verified') {
    content = (
      <VerifiedStatusItem businessId={ businessId } state={ state } />
    );
  } else if (state.state === 'not_available') {
    content = (
      <NotAvailableStatusItem businessId={ businessId } state={ state } />
    );
  } else {
    content = (
      <MissingStatusItem
        businessId={ businessId }
        rcPaymentAccountId={ rcPaymentAccountId }
        state={ state }
      />
    );
  }

  return (
    <Table.Cell 
      style={ { textAlign: 'center' } }
    >
      { content }
    </Table.Cell>
  );
};

export default BankStatementStateItem;
