import React from 'react';

import { IJournalEntry, IJournalEntryLine } from '@src/types/journal_entries';
import { amountToNumber } from '@src/utils/amount_helper';

import Table from '@src/components/ui/table';

import styles from './styles.module.scss';

const formatAmount = (amount: any) => {
  if (amount) {
    const amountNumber = amountToNumber(amount);
    return amountNumber.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  return '--';
};

const qboLink = (entry: IJournalEntry) => {
  if (entry.imported) {
    return (
      <a className="display-inline-flex in-green-600 align-items-center" href={ entry.qboLink } rel="noreferrer" target="_blank">
        <span className="icon-qbo-on font-18" />
        <span className="font-16 m-l-10 m-r-10">View in QuickBooks</span>
      </a>
    );
  }

  return (
    <span className="display-inline-flex in-grey-700 align-items-center" title={ entry.qboError }>
      <span className="icon-qbo-error font-18" />
      <span className="font-16 m-l-10 m-r-10">View in QuickBooks</span>
    </span>
  );
};

const EntryLine: React.FC<{ line: IJournalEntryLine }> = ({ line }) => {
  return (
    <Table.Row>
      <Table.Cell>{line.accountName || '--'}</Table.Cell>
      <Table.Cell>{line.description || '--'}</Table.Cell>
      <Table.Cell>{line.entityName || '--'}</Table.Cell>
      <Table.Cell>{line.departmentName || '--'}</Table.Cell>
      <Table.Cell>{formatAmount(line.debits)}</Table.Cell>
      <Table.Cell>{formatAmount(line.credits)}</Table.Cell>
    </Table.Row>
  );
};

const Entry: React.FC<{ entry: IJournalEntry }> = ({ entry }) => {
  return (
    <div className={ styles.entry }>
      <div className={ styles['entry-header'] }>
        <div className={ styles['entry-header__business_name'] }>
          { entry.businessName }
        </div>
        <div className={ styles['entry-header__date'] }>
          { entry.date }
        </div>
        <div className={ styles['entry-header__link'] }>
          { qboLink(entry) }
        </div>
      </div>
      <div className={ styles['entry-lines'] }>
        <Table className={ styles.table } wrapperClassName="business-inbox-table-wrapper inbox-sortable-table">
          <Table.Head>
            <Table.Row>
              <Table.HCell>Account</Table.HCell>
              <Table.HCell>Description</Table.HCell>
              <Table.HCell>Name</Table.HCell>
              <Table.HCell>Department</Table.HCell>
              <Table.HCell>Debits</Table.HCell>
              <Table.HCell>Credits</Table.HCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            { entry.lines.map((line) => <EntryLine key={ `${line.accountName}-${line.debits}-${line.credits}` } line={ line } />) }
          </Table.Body>
          <Table.Foot>
            <Table.Row className={ styles['table-footer'] }>
              <Table.Cell colSpan={ 4 }>Total</Table.Cell>
              <Table.Cell>{formatAmount(entry.debitsTotal)}</Table.Cell>
              <Table.Cell>{formatAmount(entry.creditsTotal)}</Table.Cell>
            </Table.Row>
          </Table.Foot>
        </Table>
      </div>
    </div>
  );
};

export default Entry;
