import React from 'react';

import BriefNoDataImg from '@assets/images/dashboards/no-data-small.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const WidgetBriefNoDataIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Widget in brief view no data icon"
      src={ BriefNoDataImg }
      { ...props }
    />
  );
};

export default WidgetBriefNoDataIcon;
