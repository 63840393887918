import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetPracticeInformations } from '@src/hooks/queries/practice_informations';
import { useSorting } from '@src/hooks/url_params';
import {
  TPracticeInformationsSortColumn,
} from '@src/types/practice_informations';
import { ISortingParams } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IMyPracticeListProps {
  filterParams: any,
}

const DefaultSorting: ISortingParams<TPracticeInformationsSortColumn> = {
  orderColumn:    'business_name',
  orderDirection: 'asc',
};

const MyPracticeList = ({
  filterParams,
}: IMyPracticeListProps): JSX.Element => {
  const sorting = useSorting<TPracticeInformationsSortColumn>({ defaultSorting: DefaultSorting });
  const informationQuery = useGetPracticeInformations({
    ...filterParams,
    ...sorting.data,
  });

  const informations = useMemo(() => {
    const informationPages = informationQuery.data?.pages || [];

    return flatten(informationPages.map((p) => p.collection));
  }, [informationQuery.data]);

  return (
    <Table isRegionScroll infiniteQuery={ informationQuery } wrapperClassName="practice-table-wrapper">
      <Table.Head>
        <Table.Row className="bg-grey-180">
          <Table.HCell<TPracticeInformationsSortColumn>
            className="border-right-black"
            columnName="business_name"
            sorting={ sorting }
            widthPercent={ 18 }
          >
            Business
          </Table.HCell>
          <Table.HCell<TPracticeInformationsSortColumn>
            className="border-right-black"
            columnName="member_name"
            sorting={ sorting }
            widthPercent={ 18 }
          >
            My Team
          </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Jan </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Feb </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Mar </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Apr </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> May </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Jun </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Jul </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Aug </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Sep </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Oct </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Nov </Table.HCell>
          <Table.HCell className="bookkeeping-status-item"> Dec </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          informations.map((information) => {
            return (
              <Item
                key={ `${information.id}-${information.accountingFirmBusinessId}` }
                information={ information }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default MyPracticeList;
