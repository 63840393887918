import React from 'react';

import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import Delete from './permanent_delete_action';
import Restore from './restore_action';

interface IBusinessInboxTrashItemActionsProps {
  document: IInboxServiceDocument,
}

const BusinessInboxTrashItemActions = ({
  document,
}: IBusinessInboxTrashItemActionsProps): JSX.Element => {
  return (
    <>
      <Restore document={ document } />
      <Delete document={ document } />
    </>
  );
};

export default BusinessInboxTrashItemActions;
