import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const BankStatementVerifiedInactive = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="bank-statement-verified-hover"
      pathsNumber={ 3 }
    />
  );
});

BankStatementVerifiedInactive.displayName = 'BankStatementVerifiedInactive';

export default BankStatementVerifiedInactive;
