import { useQuery } from 'react-query';

import { getMetricsServiceByBusinessId } from '@src/requests/metrics_services';
import { IMetricsService } from '@src/types/services';

const METRICS_SERVICE_QUERY = 'metrics_service';

const useGetMetricsServiceByBusinessId = (businessId: number) => {
  return useQuery<IMetricsService, Error>(
    [METRICS_SERVICE_QUERY, businessId],
    () => getMetricsServiceByBusinessId(businessId),
    {
      enabled: !!businessId,
    },
  );
};

export {
  METRICS_SERVICE_QUERY,
  useGetMetricsServiceByBusinessId,
};
