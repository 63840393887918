import React from 'react';

import { SendbirdProvider } from '@sendbird/uikit-react/SendbirdProvider';

import { useCreateSessionToken } from '@src/hooks/queries/ai_chat/ai_chatbot';

import QueryStatus from '@src/components/utils/query_status';

import ChatContainer from './components/chat_container';
import AiChatBotProvider from './provider/ai_chat_provider';

const AiChatbotSession = () => {
  const userId = window.Docyt.currentAdvisor.get('id');
  const sessionTokenQuery = useCreateSessionToken();

  return (
    <>
      <QueryStatus query={ sessionTokenQuery } />
      {sessionTokenQuery.data?.sessionToken && (
        <SendbirdProvider
          accessToken={ sessionTokenQuery.data?.sessionToken }
          appId={ window.configData.ai_chatbot.app_id }
          uikitOptions={ {
            groupChannel: {
              enableTypingIndicator: true,
            },
          } }
          userId={ String(userId) }
        >
          <AiChatBotProvider>
            <ChatContainer />
          </AiChatBotProvider>
        </SendbirdProvider>
      )}
    </>
  );
};

export default AiChatbotSession;
