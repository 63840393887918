import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useAddToBusiness } from '@src/hooks/queries/business_vendors';
import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import Dropdown from '@src/components/ui/dropdown';
import ClaimBusinessIcon from '@src/components/utils/icomoon/claim_business';
import MutationStatus from '@src/components/utils/mutation_status';
import { useConfirmAddVendorsModal } from '@src/components/vendors/views/confirm_add_to_my_vendors';

interface IAddToMyVendorsActionProps {
  vendors: IGlobalVendorWithBusinessInfo[],
  onFinished: (bulkCount: number) => void,
}

const AddToMyVendorsAction = ({
  vendors,
  onFinished,
}: IAddToMyVendorsActionProps): JSX.Element => {
  const business = useBusinessContext();
  const addToBusiness = useAddToBusiness();

  const { mutate } = addToBusiness;
  const handleConfirm = useCallback(() => {
    const vendorIds = vendors.map((d) => d.id) || [];
    mutate(
      { vendorIds, businessId: business.id },
      { onSuccess: () => onFinished(vendorIds.length) },
    );
  }, [business.id, vendors, mutate, onFinished]);

  const confirm = useConfirmAddVendorsModal({
    vendors,
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ addToBusiness } />
      <confirm.Component { ...confirm.props } />
      <Dropdown.Item onSelect={ confirm.open }>
        <ClaimBusinessIcon mr={ 22 } />
        Add to My Vendors
      </Dropdown.Item>
    </>
  );
};

export default AddToMyVendorsAction;
