import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IReviewedStatementDetailsFilter } from './types';

interface IPendingReviewFilterProps {
  filter: IFilter<IReviewedStatementDetailsFilter>;
}

const PendingReviewFilter = ({
  filter,
}: IPendingReviewFilterProps) => {
  return (
    <Filter<IReviewedStatementDetailsFilter> filter={ filter }>
      <Filter.YearField
        name="year"
        options={ { endDate: new Date() } }
        placeholder="Year"
        widthPercent={ 12 }
      />
    </Filter>
  );
};

export default React.memo(PendingReviewFilter);
