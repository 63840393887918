module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, possible_match, ref, ref1;
    
      __out.push('<div class="possible-match-right-side table-responsive">\n  <h2 class="font-18 font-bold m-t-10">\n    <i class="icon icon-cash-2 m-r-3" />\n    Possible Payment Match\n  </h2>\n\n  <p>The following transaction is a potential match to this invoice. Would you like to mark this invoice as paid and match with this transaction?</p>\n\n  <h3>Transaction Details</h3>\n\n  <table class="table">\n    <thead>\n      <tr>\n        <th class="font-13 font-semi-bold">Description</th>\n        <th class="font-13 font-semi-bold">Docyt ID</th>\n        <th class="font-13 font-semi-bold">Date</th>\n        <th class="font-13 font-semi-bold">Amount</th>\n      </tr>\n    </thead>\n    <tbody>\n      ');
    
      ref = this.possible_matches;
      for (i = 0, len = ref.length; i < len; i++) {
        possible_match = ref[i];
        __out.push('\n        <tr>\n          <td>\n            ');
        if (this.possible_matches.length > 1) {
          __out.push('\n              <div class="checkbox checkbox-primary checkbox-circle m-t-0 m-b-0">\n                <input\n                  name="possible-match-radio"\n                  id="possible-match-');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                  type="radio"\n                  value="');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                  class="possible-match-radio hidden"\n                  ');
          if (((ref1 = this.selected_possible_match) != null ? ref1.possible_match_id : void 0) === possible_match.possible_match_id) {
            __out.push(__sanitize('checked'));
          }
          __out.push('\n                />\n                <label\n                  class="forward-to-service-checkbox-label"\n                  for="possible-match-');
          __out.push(__sanitize(possible_match.possible_match_id));
          __out.push('"\n                >\n                  ');
          __out.push(__sanitize(possible_match.description));
          __out.push('\n                </label>\n              </div>\n            ');
        } else {
          __out.push('\n              ');
          __out.push(__sanitize(possible_match.description));
          __out.push('\n            ');
        }
        __out.push('\n          </td>\n          <td><a class="pointer font-semi-bold nowrap font-12 clickable-txn" data-docyt-id="');
        __out.push(__sanitize(possible_match.docyt_id));
        __out.push('">');
        __out.push(__sanitize(possible_match.docyt_id));
        __out.push('</a></td>\n          <td>');
        __out.push(__sanitize(possible_match.transaction_date));
        __out.push('</td>\n          <td><span class="font-bold">');
        __out.push(__sanitize(parseFloat(possible_match.amount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        })));
        __out.push('</span></td>\n        </tr>\n      ');
      }
    
      __out.push('\n\n      ');
    
      if (this.possible_matches.length > 1) {
        __out.push('\n        <input name="possible-match-radio" type="radio" value="" class="hidden" ');
        if (this.selected_possible_match == null) {
          __out.push(__sanitize('checked'));
        }
        __out.push(' />\n      ');
      }
    
      __out.push('\n    </tbody>\n  </table>\n\n  <div class="display-flex justify-content-between">\n    ');
    
      if (this.selected_possible_match != null) {
        __out.push('<a class="pointer m-t-8 not-a-match-btn">Not A Match</a>');
      } else {
        __out.push('<span class="m-t-8 muted" style="cursor: not-allowed;">Not A Match</span>');
      }
    
      __out.push('\n    <button type="button" ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' class="btn btn-default mark-as-paid-btn ');
    
      if (this.selected_possible_match == null) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push('">Mark As Paid</button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}