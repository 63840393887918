module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade missing-transactions-modal">\n  <div class="modal-dialog modal-lg modal-xlg missing-transactions-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header header">\n        <button class=\'close close-link\' type=\'button\'>\n          <span>&times;</span>\n        </button>\n        <h1 class="modal-title font-28 font-bold">Missing Transactions</h1>\n      </div>\n      <div class="modal-body">\n        <p class="font-16">\n          One or more transactions might be missing from this report, which can result in incorrect data.\n          Please find below the list of transactions that are currently not part of this report.\n        </p>\n        ');
    
      if (!(this.report.get('template_id') === 'departmental_report' || this.report.get('template_id') === 'vendor_report' || this.report.get('template_id') === 'general_ledger_report') || !this.report.get('edit_mapping_disabled')) {
        __out.push('\n          <div class="custom-report-missing-transactions-detail-container">\n            <div class="main-container table-item-value-wrapper">\n              <table class="table">\n              <thead>\n                <tr>\n                  <th width="40%"><span class="m-l-auto m-r-auto font-bold">Potential Issues</span></th>\n                  <th width="40%"><span class="m-l-auto m-r-auto font-bold">How to Fix</span></th>\n                  <th width="20%"></th>\n                </tr>\n              </thead>\n              <tbody>\n                  <tr>\n                    <td>A valid class/chart of account combination was not mapped to a report line item.</td>\n                    <td>Look for unmapped chart of accounts in the mapping tool.</td>\n                    <td><a class="font-14 pointer edit-mapping-js close-link" href="/businesses/');
        __out.push(__sanitize(this.business_id));
        __out.push('/reports/');
        __out.push(__sanitize(this.report.get('slug')));
        __out.push('/edit_map">View Mapping</a></td>\n                  </tr>\n                <tr>\n                  <td>An invalid class/chart of account combination was mapped to a report line item.</td>\n                  <td>Click on the transaction row to open the transaction in the ledger and change the transaction class/chart of account to a valid combination.</td>\n                  <td></td>\n                </tr>\n              </tbody>\n              </table>\n            </div>\n          </div>\n        ');
      }
    
      __out.push('\n        <p class="font-16 font-bold missing-transactions-title">Missing Transactions</p>\n        <div class="" id="missing-transactions-region">\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}