import React, { useCallback } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';
import { TFilterData } from '@src/types/filter';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface ICheckboxFilterFieldProps<TFilter extends TFilterData> extends IFilterFieldUIProps {
  label: string,
  name: keyof TFilter,
}

const CheckboxFilterField = <TFilter extends TFilterData>({
  label,
  name,
  ...props
}: ICheckboxFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, boolean>(name);

  const fieldId = useUniqueDomId('filter-');

  const handleChange = useCallback((e) => {
    update(e.target.checked);
  }, [update]);

  const [wrapperProps] = useFilterFieldProps(props, 'checkbox');

  return (
    <Wrapper { ...wrapperProps }>
      <input
        checked={ Boolean(value) }
        className="pointer"
        id={ fieldId }
        type="checkbox"
        onChange={ handleChange }
      />
      <label
        aria-label="Select all"
        htmlFor={ fieldId }
      >
        { label }
      </label>
    </Wrapper>
  );
};

export default CheckboxFilterField;
