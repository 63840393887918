import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import Table from '@src/components/ui/table';

interface IJournalEntryListItemProps {
  adjustmentEntry: any,
  transactionId: TID
}

const JournalEntryListItem = ({
  adjustmentEntry,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transactionId,
}: IJournalEntryListItemProps): JSX.Element => {
  const handleAmountClick = useCallback(() => {
    window.open(adjustmentEntry?.qbo_link?.qbo_link, '_blank');
  }, [adjustmentEntry]);

  return (
    <Table.Row>
      {/* <Table.Cell
        pointer
        className="in-blue-900"
        onClick={ handleDocumentClick }
      >
        { adjustmentEntry.id }
      </Table.Cell> */}
      <Table.DateCell pointer date={ adjustmentEntry.entry_date } />
      <Table.Cell pointer>
        { adjustmentEntry?.chart_of_account?.display_name }
      </Table.Cell>
      <Table.AmountCell pointer amount={ adjustmentEntry.amount } className="in-blue-900" onClick={ handleAmountClick } />
    </Table.Row>
  );
};

export default JournalEntryListItem;
