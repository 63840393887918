import React from 'react';

import { TID } from '@src/types/common';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import Dropdown from '@src/components/ui/dropdown';
import { EllipsisIcon } from '@src/components/utils/fa_icons';
import { useItemsSelector } from '@src/components/utils/items_selector';

import Delete from './trash_actions/permanent_delete_action';
import DeleteAll from './trash_actions/permanent_delete_all_action';
import Restore from './trash_actions/restore_action';
import RestoreAll from './trash_actions/restore_all_action';

const BusinessInboxTrashHeader = (): JSX.Element => {
  const selector = useItemsSelector<TID>();

  return (
    <DetailsPageTitle title="Trash">
      <Restore documentIds={ selector.selected } onDone={ selector.reset } />
      <Delete documentIds={ selector.selected } onDone={ selector.reset } />
      <Dropdown>
        <Dropdown.LinkToggle
          className="in-gray-important"
          fontSize={ 16 }
          fontVariant="semi-bold"
        >
          <EllipsisIcon variant="horizontal" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu alignment="right">
          <RestoreAll />
          <DeleteAll />
        </Dropdown.Menu>
      </Dropdown>
    </DetailsPageTitle>
  );
};

export default React.memo(BusinessInboxTrashHeader);
