import React, { useCallback, useState } from 'react';

import { useUnmarkNoStatementAvailableBankStatement } from '@src/hooks/queries/bank_statements';
import { getBankStatementByDocytId } from '@src/requests/bank_statements';
import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { apiDateToMonth } from '@src/utils/date_helpers';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import { BankStatementNoStatement } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '../../../styles.module.scss';

interface INotAvailableStatusItemProps {
  businessId: TID,
  state: IReviewedBankStatementState,
}

const NotAvailableStatusItem = ({
  businessId,
  state,
}: INotAvailableStatusItemProps) => {
  const [hasNextVerifiedStatements, setHasNextVerifiedStatements] = useState<boolean>(false);
  const [hasNextApprovedStatements, setHasNextApprovedStatements] = useState<boolean>(false);
  const notAvailableTooltip = (
    <div className="not-available-status">
      <div className={ styles['state-status'] }>
        <BankStatementNoStatement fontSize={ 20 } />
        <span className={ styles['statement-state'] }>No Statement Available</span>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ notAvailableTooltip } tooltipClassName={ styles['missing-state-item'] }>
      <BankStatementNoStatement fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['status-item-button'] }>
      { content }
    </Dropdown.ToggleButton>
  );

  const unmarkNoStatementAvailable = useUnmarkNoStatementAvailableBankStatement();
  const { mutate } = unmarkNoStatementAvailable;

  const handleConfirm = useCallback(() => {
    if (!state.id) return;

    mutate(
      { id: state.id },
    );
  }, [state?.id, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  const handleClickAction = useCallback(() => {
    getBankStatementByDocytId(businessId, state.docytId).then((res) => {
      setHasNextVerifiedStatements(res.bankStatement?.hasNextVerifiedStatements || false);
      setHasNextApprovedStatements(res.bankStatement?.hasNextApprovedStatements || false);

      confirmModal.open();
    });
  }, [businessId, confirmModal, state.docytId]);

  return (
    <>
      <MutationStatus mutation={ unmarkNoStatementAvailable } successMessage="Un-marked 'No Statement Available' successfully" />
      <confirmModal.Component
        text={
          hasNextVerifiedStatements || hasNextApprovedStatements ? (
            <span>
              Bank feed has been verified beyond this statement.
              { ' ' }
              If you Un-mark &quot;No Statement&quot; available for
              { ' ' }
              { `${apiDateToMonth(state.date)}` }
              , all statements beyond this month will need reverification.
            </span>
          ) : (
            <span>
              Are you sure you want to proceed and unmark
              { ' ' }
              &quot;No Statement &quot; available this statement?
            </span>
          )
        }
        title={
          hasNextVerifiedStatements || hasNextApprovedStatements
            ? 'Critical Warning' : 'Un-mark "No Statement Available"'
        }
        { ...confirmModal.props }
      />
      <ActionsDropdown toggleButton={ toggleButton }>
        <ActionsDropdown.Action
          title="Unmark 'No Statement Available'"
          onClick={ handleClickAction }
        />
      </ActionsDropdown>
    </>
  );
};

export default NotAvailableStatusItem;
