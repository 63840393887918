module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, j, k, len, len1, len2, member_service, ref, ref1, ref2, team_member;
    
      __out.push('<td colspan="7">\n  <table class="client-row-table">\n    <colgroup span="7">\n      <col width="5%"></col>\n      <col width="25%"></col>\n      <col width="15%"></col>\n      <col width="25%"></col>\n      <col></col>\n      <col width="2%"></col>\n      <col width="3%"></col>\n    </colgroup>\n    <tr class="client-detail-row">\n      <td class="pointer toggle-column">\n        <i class="members-toggle fa fa-angle-down in-grey-1050 font-bold font-20"></i>\n      </td>\n      <td class="pointer display-flex align-items-center toggle-column">\n        <span class="avatar-wrapper">\n          ');
    
      if (this.image_url) {
        __out.push('\n          <img class="business-img" src="');
        __out.push(__sanitize(this.image_url));
        __out.push('"/>\n          ');
      } else {
        __out.push('\n          <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n          ');
      }
    
      __out.push('\n        </span>\n        <span class="m-l-22 font-14 in-blue-900 client-name">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n      </td>\n      <td class="font-14 in-grey-1100 toggle-column pointer">\n       ');
    
      __out.push(__sanitize(this.accounting_firm_business_name));
    
      __out.push('\n      </td>\n      <td class="toggle-column pointer">\n        <div class="display-flex">\n          ');
    
      ref = this.team_members;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        team_member = ref[index];
        __out.push('\n          ');
        if (index < 5) {
          __out.push('\n            ');
          if (team_member.avatar_url) {
            __out.push('\n            <img class="user-img m-r-5" src="');
            __out.push(__sanitize(team_member.avatar_url));
            __out.push('"/>\n            ');
          } else {
            __out.push('\n            <i class="icon icon-smile in-blue-400 font-36 vertical-align-middle m-r-5"></i>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n          ');
      }
    
      __out.push('\n          ');
    
      if (this.moreMemberCount > 0) {
        __out.push('\n          <div class="more-member-circle">\n            +');
        __out.push(__sanitize(this.moreMemberCount));
        __out.push('\n          </div>\n          ');
      }
    
      __out.push('\n        </div>\n      </td>\n      <td class="font-14 in-grey-1100 toggle-column pointer">\n        ');
    
      __out.push(__sanitize(this.business_admins ? this.business_admins : ''));
    
      __out.push('\n      </td>\n      <td class="toggle-column pointer">\n        <span class="');
    
      __out.push(__sanitize(this.qbo_connected ? 'icon-qbo-on' : 'icon-qbo-off'));
    
      __out.push(' font-20"></span>\n      </td>\n      <td>\n        <div class="relative">\n          <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="client-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n            <i class=\'icon icon-3-dots font-20\'></i>\n          </button>\n          <div class="client-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="client-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n            ');
    
      if (!this.archived_at) {
        __out.push('\n              <a href="/setup-client-business/');
        __out.push(__sanitize(this.id));
        __out.push('?accounting_firm_business_id=');
        __out.push(__sanitize(this.accounting_firm_business_id));
        __out.push('" class="dropdown-item business-setup-item font-14">\n                <i class=\'icon icon-setup font-24\'></i>\n                <span class="m-l-8">Business Setup</span>\n              </a>\n              <a class="dropdown-item font-14 pointer archive-client-js">\n                <span>Archive Client</span>\n              </a>\n            ');
      } else {
        __out.push('\n            <a class="dropdown-item font-14 pointer unarchive-client-js">\n              <span>Unarchive Client</span>\n            </a>\n            <a class="dropdown-item font-14 pointer delete-client-js">\n              <span class="in-red-100">Delete Client</span>\n            </a>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </td>\n    </tr>\n    ');
    
      ref1 = this.team_members;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        team_member = ref1[j];
        __out.push('\n    <tr class="hidden member-expand-row ');
        if (this.is_accounting_firm_admin && !this.archived_at) {
          __out.push(__sanitize('pointer'));
        }
        __out.push('" data-member-id="');
        __out.push(__sanitize(team_member.id));
        __out.push('">\n       <td></td>\n       <td class="member-name-column">\n         <div class="member-name-wrapper">\n           ');
        if (team_member.avatar_url) {
          __out.push('\n           <img class="user-img" src="');
          __out.push(__sanitize(team_member.avatar_url));
          __out.push('"/>\n           ');
        } else {
          __out.push('\n           <i class="icon icon-smile in-blue-400 font-36 vertical-align-middle"></i>\n           ');
        }
        __out.push('\n         <span class="font-16 m-l-22 in-black">');
        __out.push(__sanitize(team_member.name));
        __out.push('</span>\n         </div>\n       </td>\n       <td>\n         <span class="font-14 in-grey-1100">');
        __out.push(__sanitize(team_member.role));
        __out.push('</span>\n       </td>\n       <td>\n         <div class="service-roles-wrapper">\n         ');
        ref2 = team_member.services;
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          member_service = ref2[k];
          __out.push('\n           <div class="font-11 service-role-box m-r-10 m-b-5">');
          __out.push(__sanitize(member_service));
          __out.push('</div>\n         ');
        }
        __out.push('\n         </div>\n       </td>\n       <td></td>\n       <td></td>\n       <td><div class="last-column-wrapper"></div></td>\n    </tr>\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.team_members.length === 0) {
        __out.push('\n    <tr class="hidden empty-members-expand-row">\n        <td></td>\n        <td class="empty-member-column">\n          <p class="font-12">No team members currently assigned.</p>\n          ');
        if (this.is_accounting_firm_admin) {
          __out.push('\n          <a class="m-t-8 font-bold font-14 pointer assign-member-js">Assign Team Member</a>\n          ');
        }
        __out.push('\n        </td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n    </tr>\n    ');
      }
    
      __out.push('\n  </table>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}