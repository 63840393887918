module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="confirm-start-import-date-modal">\n  <div class="modal fade" tabindex="-1" role="dialog">\n    <div class="modal-dialog modal-md">\n      <div class="modal-content">\n        <div class="modal-header">\n          <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">Confirm import starting date</h4>\n        </div>\n        <div class="modal-body modern-body">\n          <div class="panel-fields">\n            <p class="text-center m-t-0 font-14">This account already has transactions in Docyt and the last transaction date is:<br>');
    
      __out.push(__sanitize(moment(this.last_transaction_date).format("MM/DD/YYYY")));
    
      __out.push('</p>\n            <p class="text-center m-t-16 m-b-32 font-14">To avoid duplicates, we recommend the import starting date to be after the last transaction date</p>\n            <div class="form-group">\n              <label class="field-label">Starting Date: </label>\n              <div class="field-input">\n                <input class="form-control input-field start-import-date-picker" placeholder="MM/DD/YYYY" autocomplete="off" />\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link m-t-8 pull-left font-bold pointer\'>Go Back</a>\n          <button type=\'button\' class="btn btn-blue ladda-button btn-save" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="modal-backdrop fade in"></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}