import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import BusinessesView from './businesses_view';

interface IBusinessesViewPageProps {
  businessId: TID,
  vendorId: TID,
}

const BusinessesViewPage = ({
  businessId,
  vendorId,
}: IBusinessesViewPageProps): JSX.Element | null => {
  return (
    <Root>
      <BusinessesView
        businessId={ businessId }
        vendorId={ vendorId }
      />
    </Root>
  );
};

export default BusinessesViewPage;
