import React from 'react';

import classNames from 'classnames';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { useDebouncedCallback } from '@src/hooks/utils';
import { getDocumentRequests } from '@src/requests/document_requests';
import { IBusiness } from '@src/types/businesses';
import { IDocumentRequest } from '@src/types/document_requests';

import { AsyncSelectInput } from '@src/components/ui/form';

import { ILinkDocumentRequestFormValues } from './link_document_request_form';

interface IForwardDocumentBusinessProps {
  business: IBusiness,
  field: ControllerRenderProps<ILinkDocumentRequestFormValues, 'documentRequest'>,
  fieldState: ControllerFieldState,
}

const getOptionLabel = (item: IDocumentRequest) => item.name;

const LinkDocumentRequestInput = ({
  business,
  field: { value, onChange },
  fieldState,
}: IForwardDocumentBusinessProps): JSX.Element => {
  const handleSource = useDebouncedCallback((query, callback) => {
    getDocumentRequests({ businessId: business.id, searchQuery: query })
      .then(callback);
  }, [business], 300);

  const inputClasses = classNames({
    'border-danger': fieldState.error,
  });

  return (
    <div className="display-flex align-items-center justify-content-between">
      <label className="form-label" htmlFor="document_request">Document Request</label>
      <AsyncSelectInput
        defaultOptions
        className={ inputClasses }
        getOptionLabel={ getOptionLabel }
        id="document_request"
        loadOptions={ handleSource }
        placeholder="Search Document Request"
        value={ value }
        widthPercent={ 100 }
        onChange={ onChange }
      />
    </div>
  );
};

export default LinkDocumentRequestInput;
