import React from 'react';

import classNames from 'classnames';

import { Button } from '@src/components/ui_v2/buttons';
import { TOption } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

export interface ITabItmeProps {
    activeTab: TOption | null;
    tab: TOption;
    onTabChange: (item: TOption) => void;
}
const TabItem = ({
  tab,
  onTabChange,
  activeTab,
}:ITabItmeProps) => {
  const moduleHeaderClass = classNames(styles['module-header-menu'], {
    active: activeTab?.value === tab.value,
  });

  return (
    <Button
      className={ classNames([
        styles['module-header'],
        styles['btn-group'],
      ]) }
      variant="link"
      onClick={ () => {
        onTabChange(tab);
      } }
    >
      <span className={ moduleHeaderClass } title={ tab.label }>
        {tab.label}
      </span>
    </Button>
  );
};

export default React.memo(TabItem);
