import React from 'react';

import { useGetDocumentFields } from '@src/hooks/queries/document_fields';
import { TID } from '@src/types/common';

import Item from './document_field_item';

interface IDocumentFieldsContentProps {
  documentId: TID,
}

const DocumentFieldsContent = ({
  documentId,
}: IDocumentFieldsContentProps): JSX.Element => {
  const query = useGetDocumentFields({
    documentId,
  });

  const documentFields = query.data?.documentFields || [];

  return (
    <>
      {
        documentFields.map((documentField) => {
          return (
            <Item
              key={ documentField.id }
              documentField={ documentField }
            />
          );
        })
      }
    </>
  );
};

export default DocumentFieldsContent;
