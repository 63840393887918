module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog upload-csv-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">Upload CSV</h4>\n      </div>\n      <div class="modal-body">\n        <div class="update-csv-container">\n          <div class="csv-drop-zone relative">\n            ');
    
      if (!this.isUploading) {
        __out.push('\n            <div class="text-center">\n              <span class="icon-csv-file font-68"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n              <p class="m-t-20 font-16">Drag and drop the chart of account CSV here.</p>\n              <p class="m-t-10 m-b-10 font-16">OR</p>\n              <button class="btn btn-blue-on-hover fileinput-button font-16">\n                Select from your computer\n                <input type="file" name="files[]" class="csv-file-input" accept=".csv">\n              </button>\n            </div>\n            ');
      } else {
        __out.push('\n            <div class="text-center p-t-70">\n              <p class="file-upload-status-text font-18">Uploading...</p>\n            </div>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel\'>OK</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n<div class=\'batch-create-coas\'></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}