import React from 'react';

import classNames from 'classnames';
import { UseInfiniteQueryResult } from 'react-query';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import InfiniteRegionScrollTable from '@src/components/ui/table/infinite_region_scroll_table';

import AmountCell from './amount_cell';
import Body from './body';
import Cell from './cell';
import CurrencyAmountCell from './currency_amount_cell';
import DateCell from './date_cell';
import DateTimeCell from './date_time_cell';
import Foot from './foot';
import HCell from './hcell';
import Head from './head';
import InfiniteScrollTable from './infinite_scroll_table';
import NoScrollTable from './no_scroll_table';
import Row from './row';
import SelectAllHCell from './select_all_hcell';
import SelectItemCell from './select_item_cell';

import styles from './styles.module.scss';

interface ITableProps extends
  React.ComponentPropsWithoutRef<'table'>,
  IUIStyleProps
{
  infiniteQuery?: UseInfiniteQueryResult<any, Error>,
  isRegionScroll?: boolean,
  wrapperClassName?: string,
}

/**
 * Show data in table view
 */
const Table = ({
  infiniteQuery,
  isRegionScroll,
  wrapperClassName,
  ...props
}: ITableProps) => {
  const [classes, tableProps] = uiStyleProps(
    'table',
    props,
    styles.table,
  );
  const wrapperClasses = classNames('table-responsive', wrapperClassName);

  const commonProps = {
    ...tableProps,
    className:        classes,
    wrapperClassName: wrapperClasses,
  };

  if (infiniteQuery) {
    if (isRegionScroll) {
      return (
        <InfiniteRegionScrollTable
          { ...props }
          { ...commonProps }
          infiniteQuery={ infiniteQuery }
        />
      );
    }
    return (
      <InfiniteScrollTable
        { ...props }
        { ...commonProps }
        infiniteQuery={ infiniteQuery }
      />
    );
  }

  return <NoScrollTable { ...props } { ...commonProps } />;
};

Table.AmountCell = AmountCell;
Table.Body = Body;
Table.Cell = Cell;
Table.CurrencyAmountCell = CurrencyAmountCell;
Table.DateCell = DateCell;
Table.DateTimeCell = DateTimeCell;
Table.Foot = Foot;
Table.HCell = HCell;
Table.Head = Head;
Table.Row = Row;
Table.SelectAllHCell = SelectAllHCell;
Table.SelectItemCell = SelectItemCell;

export {
  ITableProps,
  Table as default,
};
