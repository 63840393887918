import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDocuments } from '@src/hooks/queries/documents';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import Dropdown from '@src/components/ui/dropdown/dropdown';

interface IBusinessInboxDownloadActionProps {
  selectedIds: number[],
}

const BusinessInboxDownloadAction = ({
  selectedIds,
}: IBusinessInboxDownloadActionProps): JSX.Element => {
  const downloadDocuments = useDownloadDocuments();

  const { mutate } = downloadDocuments;
  const handleDownload = useCallback((type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentIds: selectedIds, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, data.fileName) },
    );
  }, [mutate, selectedIds]);

  const handleOriginalFileSelect = useCallback(() => {
    handleDownload('original');
  }, [handleDownload]);

  const handleExtractedPdfSelect = useCallback(() => {
    handleDownload('extracted');
  }, [handleDownload]);

  return (
    <Dropdown>
      <Dropdown.LinkToggle
        disabled={ selectedIds.length === 0 }
        fontSize={ 16 }
        fontVariant="semi-bold"
      >
        Download
      </Dropdown.LinkToggle>
      <Dropdown.Menu>
        <Dropdown.Item onSelect={ handleOriginalFileSelect }>
          Original File
        </Dropdown.Item>
        <Dropdown.Item onSelect={ handleExtractedPdfSelect }>
          Extracted PDF
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BusinessInboxDownloadAction;
