import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import Form from '@src/components/ui/form';

import { renameReportValidation, IRenameReportFormValues } from './schema';

import styles from '../styles.module.scss';

interface IRenameReportFormProps {
  item: IMultiBusinessReportDetail,
  formId?: string,
  onSubmit: SubmitHandler<IRenameReportFormValues>,
}

const RenameReportForm = ({
  item,
  formId,
  onSubmit,
}: IRenameReportFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRenameReportFormValues>({
    defaultValues: {
      name: item.name,
    },
    resolver: yupResolver(renameReportValidation),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Enter a new name for the report:"
        placeholder="Enter Name of Report"
        wrapperClassName={ styles['rename-report-modal-form'] }
        { ...register('name') }
      />
    </Form>
  );
};

export default React.memo(RenameReportForm);
