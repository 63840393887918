import React, { FC, useMemo } from 'react';

import { currencyLocaleFormatter } from '@src/utils/currency';

import {
  ICollection,
  useSetCollectionSelected,
} from '@src/components/reconciliation_center/month_end/drawers/additional_uncleared_balance/hooks';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import Table from './table';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface ComponentProps {
  collection: ICollection,
  showSelect?: boolean,
  handleCheckboxClick?: (id: unknown) => void,
  rowSelectable: boolean,
  showUnmatchedOnly?: boolean
}

const Component: FC<ComponentProps> =
  ({ collection, showSelect = false, handleCheckboxClick, rowSelectable, showUnmatchedOnly }) => {
    const pages = collection.query.data?.pages || [];
    const totalAmount = pages[0]?.meta?.totalAmount;

    useSetCollectionSelected(collection, !!showUnmatchedOnly);

    const records = useMemo(() => {
      return showUnmatchedOnly
        ? collection.records.filter((i) => i.matchedDocumentIds.length === 0)
        : collection.records;
    }, [showUnmatchedOnly, collection.records]);

    return (
      <Section.Provider section={ collection.section }>
        <TableSection>
          <div className={ styles['table-section-header'] }>
            <span className={ styles['table-section-title'] }>
              Excluded and Not Pushed to Ledger Transactions
            </span>
            <span className={ styles['table-section-total'] }>
              Total:
              { ' ' }
              <span className={ styles['table-section-total-value'] }>
                { currencyLocaleFormatter(totalAmount) }
              </span>
            </span>
          </div>
          <Table
            handleCheckboxClick={ handleCheckboxClick }
            infiniteQuery={ collection.query }
            records={ records }
            rowSelectable={ rowSelectable }
            showSelect={ showSelect }
            sorting={ collection.sorting }
          />
        </TableSection>
      </Section.Provider>
    );
  };

export default Component;
