import React from 'react';

import { useUpdateBankAccountReconciliation } from '@src/hooks/queries/bank_account_reconciliations';
import {
  TBankAccountReconciliationItemStatus,
  IBankAccountReconciliationItem,
} from '@src/types/bank_account_reconciliations';

import MutationStatus from '@src/components/utils/mutation_status';

import AcknowledgeButton from './acknowledge';
import UnacknowledgeButton from './unacknowledge';
import useAcknowledge from './use_acknowledge';

interface AcknowledgeActionProps {
  baReconciliationItem: IBankAccountReconciliationItem,
  noteRequired: boolean,
  closeDrawer?: () => void,
  total?: string
  state?: string | null
}

const AcknowledgeAction = (
  { baReconciliationItem, noteRequired, closeDrawer, total, state }: AcknowledgeActionProps,
) => {
  const updateBankAccountReconciliation = useUpdateBankAccountReconciliation();

  const { handleAcknowledge: handleModalDone } = useAcknowledge(baReconciliationItem);

  if (baReconciliationItem.status === 'acknowledged' as TBankAccountReconciliationItemStatus) {
    return (
      <>
        <MutationStatus mutation={ updateBankAccountReconciliation } />
        <UnacknowledgeButton onDone={ handleModalDone } />
      </>
    );
  }

  return (
    <>
      <MutationStatus mutation={ updateBankAccountReconciliation } />
      <AcknowledgeButton
        baReconciliationItem={ baReconciliationItem }
        closeDrawer={ closeDrawer }
        noteRequired={ noteRequired }
        total={ total }
        onDone={ handleModalDone }
        state={ state }
      />
    </>
  );
};

export default React.memo(AcknowledgeAction);
