import { useQuery, UseQueryOptions } from 'react-query';
import { Optional } from 'utility-types';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetWithMappingAccountingClassesParams,
  IGetWithMappingAccountingClassesResponse,
  getWithMappingAccountingClasses,
  IGetAccountingClassesParams,
  IGetAccountingClassesResponse,
  getAccountingClasses,
} from '@src/requests/accounting_classes';

const useGetAccountingClasses = (
  params: Optional<IGetAccountingClassesParams, 'businessId'>,
  opts?: Omit<UseQueryOptions<IGetAccountingClassesResponse, Error>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<IGetAccountingClassesResponse, Error>(
    [QueryKey.accountingClasses, params],
    () => getAccountingClasses(params as IGetAccountingClassesParams),
    {
      enabled: Boolean(params.businessId),
      ...opts,
    },
  );
};

const useGetWithMappingAccountingClasses = (params: IGetWithMappingAccountingClassesParams) => {
  return useQuery<IGetWithMappingAccountingClassesResponse, Error>(
    [QueryKey.accountingClassesWithMappings, params],
    () => getWithMappingAccountingClasses(params),
  );
};

export {
  useGetAccountingClasses,
  useGetWithMappingAccountingClasses,
};
