import React from 'react';

import { IChartOfAccount } from '@src/types/chart_of_accounts';

import { QBOOnIcon, UnableToSyncIcon } from '@src/components/utils/icomoon/';

interface IChartOfAccountAvatarProps {
  item: IChartOfAccount,
}

const ChartOfAccountAvatar = ({
  item,
}: IChartOfAccountAvatarProps) => {
  if (item.qboId && item.qboStatus === window.Docyt.Common.Constants.QBO_SYNC_STATUS.SYNCED) {
    return (
      <QBOOnIcon fontSize={ 20 } />
    );
  }

  return (
    <UnableToSyncIcon className="in-red-600" fontSize={ 20 } />
  );
};

export default ChartOfAccountAvatar;
