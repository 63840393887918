import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { getTransactionTypeClass, getTransactionType } from '@src/components/banking_accounts/transactions/utils';

interface ITransactionTypeProps {
  document: ITransactionServiceDocument,
}

const TransactionType = ({
  document,
}: ITransactionTypeProps) => {
  return (
    <span className={ `transaction-type-badge ${getTransactionTypeClass(document)}` }>
      { getTransactionType(document) }
    </span>
  );
};

export default React.memo(TransactionType);
