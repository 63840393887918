import { useMemo } from 'react';

import { flatten } from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetBusinessVendors } from '@src/hooks/queries/business_vendors';
import { useSorting } from '@src/hooks/url_params';
import { IGetBusinessVendorsResponse } from '@src/requests/business_vendors';
import {
  IBusinessVendor,
  TSelectMergeableVendorSortColumn,
  ISelectMergeableVendorFilter,
} from '@src/types/business_vendors';
import { TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface ISelectMergeableVendorParams {
  businessVendor: IBusinessVendor,
}

interface ISelectMergeableVendorCollection {
  query: UseInfiniteQueryResult<IGetBusinessVendorsResponse, Error>,
  records: IBusinessVendor[],
  section: TSection,
  sorting: ISorting<TSelectMergeableVendorSortColumn>,
  selectedRecord?: IBusinessVendor,
}

const defaultSorting: ISortingParams<TSelectMergeableVendorSortColumn> = {
  orderColumn:    'vendor',
  orderDirection: 'desc',
};

const filterToSearch = (
  filterData: ISelectMergeableVendorFilter | undefined,
): string | undefined => {
  if (!filterData) return undefined;

  return filterData.vendor;
};

const useSelectMergeableVendorCollection = ({
  businessVendor,
}: ISelectMergeableVendorParams): ISelectMergeableVendorCollection => {
  const section = useMemo(() => {
    return {
      section: Section.SelectMergeableVendor,
    };
  }, []);

  const filterData = useFilterData(section);

  const search = useMemo(() => filterToSearch(filterData), [filterData]);

  const sorting = useSorting<TSelectMergeableVendorSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetBusinessVendors({
    businessId: businessVendor.businessId,
    search,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection))
      .filter((item) => item.id !== businessVendor.id);
  }, [businessVendor.id, query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecord = useMemo(() => {
    if (selectedIds.length === 0) return undefined;

    return records.find((r) => r.id === selectedIds[0]);
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecord,
  };
};

export {
  ISelectMergeableVendorCollection,
  useSelectMergeableVendorCollection,
};
