import React from 'react';

import customReportsImg from '@assets/images/financial_reports/custom-reports-illustration@2x.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const CustomReportsIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Custom reports icon"
      src={ customReportsImg }
      { ...props }
    />
  );
};

export default CustomReportsIcon;
