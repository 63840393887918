module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, pp, ref;
    
      __out.push('<div class=\'clients__search payment-processor-search-container\'>\n  <i class=\'clients__search-icon icon icon-search\'></i>\n  <input class=\'clients__search-input text-payment-processor\' placeholder=\'Search and add Payment Processors\' type=\'text\' value="">\n</div>\n<div class="selected-pp-list">\n  ');
    
      ref = this.selectedList;
      for (i = 0, len = ref.length; i < len; i++) {
        pp = ref[i];
        __out.push('\n  <p class="line-height-32 border-bottom-gray p-b-5">\n    ');
        __out.push(__sanitize(pp.name));
        __out.push('\n    <button type="button" data-id="');
        __out.push(__sanitize(pp.id));
        __out.push('" class="close btn-unselect-payment-processor font-30" aria-label="Close">\n      <span aria-hidden="true">&times;</span>\n    </button>\n  </p>\n  ');
      }
    
      __out.push('\n  ');
    
      if (!this.selectedList.length) {
        __out.push('\n  <p class="text-muted selected-pp-description">Selected Payment Processors will appear here.</p>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}