import React from 'react';

import { IGetTemplatesResponse } from '@src/requests/multi_entity_reports';
import { IBusiness } from '@src/types/businesses';

import Modal from '@src/components/ui/modal';

import { ICreateNewReportFormValues } from '../../forms/schema';
import SelectBusinessesForm from '../../forms/select_businesses';
import SelectReportTypeForm from '../../forms/select_report_type';

interface ICreateReportModalProps {
  businesses: IBusiness[],
  name: string,
  templateId: string,
  templates: IGetTemplatesResponse[],
  show: boolean,
  view: 'reportType' | 'business',
  onCancel: () => void,
  onSubmit: (data: ICreateNewReportFormValues) => void,
}

const CreateReportModal = ({
  businesses,
  name,
  templateId,
  templates,
  show,
  view,
  onCancel,
  onSubmit,
}: ICreateReportModalProps) => {
  return (
    <Modal.Form
      cancelTitle={ view === 'reportType' ? 'Cancel' : 'Back' }
      dialogClassName="width-700px"
      proceedTitle={ view === 'reportType' ? 'Continue' : 'Create Report' }
      show={ show }
      title={ view === 'reportType' ? 'Create New Advanced Report: Select Report Type' : 'Create New Advanced Report: Select Businesses' }
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <>
          { view === 'reportType' && (
            <SelectReportTypeForm
              formId={ formId }
              name={ name }
              templateId={ templateId }
              templates={ templates }
              onSubmit={ onSubmit }
            />
          ) }

          { view === 'business' && (
            <SelectBusinessesForm
              businesses={ businesses }
              formId={ formId }
              name={ name }
              templateId={ templateId }
              onSubmit={ onSubmit }
            />
          ) }
        </>
      )}
    </Modal.Form>
  );
};

export default React.memo(CreateReportModal);
