import React from 'react';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';

import Table from '@src/components/ui/table';

import Item from './item';
import MetricItem from './metric_item';

interface IBudgetDetailsTableBodyProps {
  chartOfAccounts: IChartOfAccount[],
  budgetItems: IReportServiceBudgetItem[],
  chartOfAccountData: IReportServiceBudgetItem[],
  budgetFormulas: IReportServiceBudgetFormula[],
  formulaEnabled: string | boolean | undefined,
  onChange: (item: IReportServiceBudgetItem, month: number, value: number, itemIndex: number, type: string) => void,
  onSetDraft: (value: boolean) => void,
  isSelectDropOpen:string,
  setIsSelectDropOpen:(value: string) => void
}

const BudgetDetailsTableBody = ({
  budgetItems,
  chartOfAccountData,
  budgetFormulas,
  formulaEnabled,
  onChange,
  onSetDraft,
  chartOfAccounts,
  isSelectDropOpen,
  setIsSelectDropOpen,
}: IBudgetDetailsTableBodyProps): JSX.Element => {
  return (
    <Table.Body>
      {
        budgetItems.find((item) => item.standardMetricId)
        && (
          <Table.Row>
            <Table.Cell fontVariant="semi-bold">METRICS</Table.Cell>
            <Table.Cell />
            <Table.Cell className="table-divider" />
          </Table.Row>
        )
      }
      {
        budgetItems.filter((filterItem) => filterItem.standardMetricId).map((item, index) => (
          <MetricItem
            key={ item.id }
            budgetFormulas={ budgetFormulas }
            formulaEnabled={ formulaEnabled }
            item={ item }
            onChange={ (item, month, value) => onChange(item, month, value, index, 'standardMetric') }
            onSetDraft={ onSetDraft }
          />
        ))
      }
      {
        chartOfAccountData.length > 0
        && (
          <Table.Row style={ { borderTop: '2px solid rgba(0, 0, 0, 0.12)' } }>
            <Table.Cell fontVariant="semi-bold">CHART OF ACCOUNTS</Table.Cell>
            <Table.Cell />
            <Table.Cell className="table-divider" />
          </Table.Row>
        )
      }
      {
        chartOfAccountData.map((item, index) => (
          <Item
            key={ item.id }
            budgetFormulas={ budgetFormulas }
            chartOfAccount={ chartOfAccounts }
            formulaEnabled={ formulaEnabled }
            isSelectDropOpen={ isSelectDropOpen.toString() === item?.id.toString() }
            item={ item }
            setIsSelectDropOpen={ setIsSelectDropOpen }
            onChange={ (item, month, value) => onChange(item, month, value, index, 'chartOfAccount' ) }
            onSetDraft={ onSetDraft }
          />
        ))
      }
    </Table.Body>
  );
};

export default BudgetDetailsTableBody;
