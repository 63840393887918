import React from 'react';

import Filter from '@src/components/ui_v2/filter';

const statusOptions = [
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Waiting',
    value: 'waiting',
  },
  {
    label: 'Running',
    value: 'running',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'No Change',
    value: 'nochange',
  },
];

const typeOptions = [
  {
    label: 'Auto Refresh',
    value: 'mid_night',
  },
  {
    label: 'Webhook',
    value: 'webhook',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Force Refresh',
    value: 'force_refresh',
  },
];

const ReportRefreshLogFilter = () => {
  return (
    <Filter.TableContainer>
      <Filter.DateField
        name="dateRange"
        periodType="monthly"
      />
      <Filter.SelectField
        name="status"
        options={ statusOptions }
        placeholder="All Status"
      />
      <Filter.SelectField
        name="type"
        options={ typeOptions }
        placeholder="All Types"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(ReportRefreshLogFilter);
