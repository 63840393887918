import React, { useCallback, useMemo } from 'react';

import { IAccountingClass } from '@src/types/accounting_classes';

import { FilterFieldWrapper } from '@src/components/ui/filter';
import { SelectPickerInput } from '@src/components/ui/form';

interface IAccountingClassesProps {
  accountingClasses: IAccountingClass[],
  selectAccountingClassValue: string,
}

const BlankOption = { name: 'All', value: 'all' };

const AccountingClassFilterField = ({
  accountingClasses,
  selectAccountingClassValue,
}: IAccountingClassesProps): JSX.Element => {
  const selectValue = selectAccountingClassValue || '';

  const handleAccountingClassSelect = useCallback((selectedValue?: string) => {
    window.Docyt.vent.trigger('accounting_class:selected', selectedValue);
  }, []);

  const options = useMemo(() => {
    if (!accountingClasses) return [BlankOption];

    return [
      BlankOption,
      ...accountingClasses.map((accountingClass) => ({
        name:  accountingClass.name,
        value: String(accountingClass.id),
      })),
    ];
  }, [accountingClasses]);

  return (
    <FilterFieldWrapper>
      <SelectPickerInput
        options={ options }
        title="Select Department"
        value={ selectValue }
        onChange={ handleAccountingClassSelect }
      />
    </FilterFieldWrapper>
  );
};

export default AccountingClassFilterField;
