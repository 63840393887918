import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const AddressLocationIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="address-location"
      pathsNumber={ 9 }
    />
  );
};

export default AddressLocationIcon;
