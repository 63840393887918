import { useMemo } from 'react';

import { flatten } from 'lodash';

import { Section } from '@src/constants/sections';
import { useGetManagementGroupsWithNotificationSetting } from '@src/hooks/queries/management_groups';
import { useSorting } from '@src/hooks/url_params';
import { TSection } from '@src/types/common';
import { IManagementGroup, TManagementGroupSettingsSortColumn } from '@src/types/management_groups';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useInitItemsSelector, useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IManagementGroupSettingsCollection {
  records: IManagementGroup[],
  section: TSection,
  sorting: ISorting<TManagementGroupSettingsSortColumn>,
  selectedRecords: IManagementGroup[],
}

const defaultSorting: ISortingParams<TManagementGroupSettingsSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'desc',
};

const useManagementGroupSettingsCollection = (): IManagementGroupSettingsCollection => {
  const section = useMemo(() => {
    return {
      section: Section.ManagementGroupSettings,
    };
  }, []);

  const sorting = useSorting<TManagementGroupSettingsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetManagementGroupsWithNotificationSetting({});

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const ids = useMemo(() => {
    return records.map((object) => object.id);
  }, [records]);

  const initSelectedIds = useMemo(() => {
    return records.filter((r) => (r.kpiNotificationEnabled === true))
      .map((object) => object.id);
  }, [records]);

  useInitItemsSelector(ids, section, initSelectedIds);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    records,
    section,
    selectedRecords,
    sorting,
  };
};

export {
  IManagementGroupSettingsCollection,
  useManagementGroupSettingsCollection,
};
