import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import { DefaultAvatarIcon } from '@src/components/utils/icomoon/';

interface IUserAvatarProps extends Omit<IUIStyleProps, 'fontSize'> {
  /** Avatar object with `avatarUrl` field. May be blank. */
  avatar?: {
    avatarUrl?: string;
  },
  /** Size in pixels */
  size?: IUIStyleProps['fontSize'],
}

/**
 * Renders user's avatar or default one if user doesn't have an avatar.
 */
const UserAvatar = ({
  avatar,
  size,
  ...props
}: IUserAvatarProps) => {
  if (!avatar?.avatarUrl) {
    return (
      <DefaultAvatarIcon
        fontSize={ size }
        { ...props }
      />
    );
  }

  const [classes, imgProps] = uiStyleProps(undefined, props);

  return (
    <img
      alt=""
      className={ classes }
      src={ avatar.avatarUrl }
      style={ {
        borderRadius: '50%',
        height:       size,
        width:        size,
      } }
      { ...imgProps }
    />
  );
};

export default UserAvatar;
