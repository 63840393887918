import React from 'react';

import Root from '@src/components/root';

import AddDataExportModal from './add_data_export_modal/add_data_export_modal';

interface IPAddDataExportPageProps {
  onDone: () => void,
  onCancel: () => void,
}

const AddDataExportPage = ({
  onDone,
  onCancel,
}: IPAddDataExportPageProps): JSX.Element => {
  const hasPermittedBusinesses = window.Docyt.currentAdvisor.get('has_permitted_businesses_for_data_export');

  return (
    <Root>
      {hasPermittedBusinesses && (
        <AddDataExportModal
          isOpen
          onCancel={ onCancel }
          onDone={ onDone }
        />
      )}
    </Root>
  );
};

export default AddDataExportPage;
