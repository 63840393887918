module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, quickbooksAccount, ref;
    
      __out.push('<ul>\n  <li class="chart-of-account-item" data-id="" data-name=""><a><i class="font-20 icon icon-o-plus"></i><span>Link As New Account</span></a></li>\n  ');
    
      ref = this.quickbooksAccounts;
      for (i = 0, len = ref.length; i < len; i++) {
        quickbooksAccount = ref[i];
        __out.push('\n  <li class="chart-of-account-item" data-id="');
        __out.push(__sanitize(quickbooksAccount.id));
        __out.push('" data-name="');
        __out.push(__sanitize(quickbooksAccount.name));
        __out.push('">\n    <div class="selection-status" data-id="');
        __out.push(__sanitize(quickbooksAccount.id));
        __out.push('"></div>\n    <i class="icon icon-qbo-on font-20"></i>\n    <span class="account-name">');
        __out.push(__sanitize(quickbooksAccount.name));
        __out.push('</span>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}