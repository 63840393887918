import { TIconColumn, TModel } from './types';

const CollectionTableIconColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TIconColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableIconColumn;
