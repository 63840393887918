import React, { useCallback, useMemo } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useBulkMarkAsPaidServiceDocument } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetPaymentAccounts } from '@src/hooks/queries/payment_accounts';
import { TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import BulkActionResult from '@src/components/accounts_payable/review_payment/bulk_payment_modals/bulk_action_result';
import { bulkPaymentModalTitle, paymentMode } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';
import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';
import Modal from '@src/components/ui/modal';

import { splitInvoices } from '../../utils/regroup_invoices_helper';
import Form from './mark_as_paid_form/mark_as_paid_form';
import { IMarkAsPaidFormValues } from './schema';

type TOpenValue = {
  type: string,
  serviceDocumentIds: Array<Array<number>>
  totalAmount: number,
  limitCountToPay: number,
}

interface IMarkAsPaidModalProps extends IUseModalProps<TOpenValue> {
  businessId: TID
}

const markAsPaidResult = (status: IBulkActionStatus<any, any>) => (
  <BulkActionResult status={ status } />
);

const MarkAsPaidModal = ({
  businessId,
  isOpen,
  openValue,
  onDone,
  onCancel,
}: IMarkAsPaidModalProps) => {
  const { type, serviceDocumentIds, totalAmount, limitCountToPay } = openValue || {};

  const accountType = paymentMode(type);

  const paymentAccountQuery = useGetPaymentAccounts({
    businessId,
    accountType,
    noConsiderArchive: true,
  }, type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH);

  const paymentAccounts = useMemo(() => {
    return paymentAccountQuery.data?.paymentAccounts || [];
  }, [paymentAccountQuery.data?.paymentAccounts]);

  const markAsPaidServiceDocument = useBulkMarkAsPaidServiceDocument();
  const bulkAction = useBulkActionModal({
    mutation: markAsPaidServiceDocument,
  });

  const { runMutation } = bulkAction;

  const handleMarkAsPaidByAccount = useCallback((data: IMarkAsPaidFormValues) => {
    if (!type || !serviceDocumentIds) return;
    const params: any = [];
    serviceDocumentIds.forEach((ids: Array<number>) => {
      if (limitCountToPay && ids.length > limitCountToPay) {
        const chunkedIds = splitInvoices(ids, limitCountToPay);
        chunkedIds.forEach((chunkedId: Array<number>) => {
          params.push({
            paidAt:             formatDate(data.estimatedDate),
            paymentMode:        accountType,
            serviceDocumentIds: chunkedId,
            paymentAccountId:   type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH
              ? paymentAccounts[0].id : data.paymentAccountId,
          });
        });
      } else {
        params.push({
          paidAt:             formatDate(data.estimatedDate),
          paymentMode:        accountType,
          serviceDocumentIds: ids,
          paymentAccountId:   type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH
            ? paymentAccounts[0].id : data.paymentAccountId,
        });
      }
    });

    runMutation(params);
  }, [accountType, limitCountToPay, paymentAccounts, runMutation, serviceDocumentIds, type]);

  const handleMarkAsPaidByAdvancedSettlementItem = useCallback((
    data: IMarkAsPaidFormValues,
  ) => {
    if (!type || !serviceDocumentIds) return;
    const params: any = [];
    serviceDocumentIds.forEach((ids: Array<number>) => {
      if (limitCountToPay && ids.length > limitCountToPay) {
        const chunkedIds = splitInvoices(ids, limitCountToPay);
        chunkedIds.forEach((chunkedId: Array<number>) => {
          params.push({
            paidAt:                  formatDate(data.estimatedDate),
            balanceChartOfAccountId: data.balanceChartOfAccountId,
            ignoreCheckQboSynced:    true,
            memo:                    data.memo,
            paymentMode:             accountType,
            serviceDocumentIds:      chunkedId,
          });
        });
      } else {
        params.push({
          paidAt:                  formatDate(data.estimatedDate),
          balanceChartOfAccountId: data.balanceChartOfAccountId,
          ignoreCheckQboSynced:    true,
          memo:                    data.memo,
          paymentMode:             accountType,
          serviceDocumentIds:      ids,
        });
      }
    });

    runMutation(params);
  }, [accountType, limitCountToPay, runMutation, serviceDocumentIds, type]);

  const handleSubmit = useCallback((
    data: IMarkAsPaidFormValues,
  ) => {
    document.querySelector('.bulk-mark-as-paid-modal')?.classList.add('hide');
    if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_ADVANCE_SETTLEMENT) {
      handleMarkAsPaidByAdvancedSettlementItem(data);
    } else {
      handleMarkAsPaidByAccount(data);
    }
  }, [handleMarkAsPaidByAccount,
    handleMarkAsPaidByAdvancedSettlementItem,
    type]);

  const handleBulkDone = useCallback(() => {
    document.querySelector('.bulk-mark-as-paid-modal')?.classList.remove('hide');
    onDone();
  }, [onDone]);

  if (type === undefined) return null;

  return (
    <>
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Payments"
        needTitleSuffix={ false }
        result={ markAsPaidResult }
        title="Processing Payment.."
        onDone={ handleBulkDone }
      />
      <Modal.Form
        className="bulk-mark-as-paid-modal"
        proceedTitle="Mark as Paid"
        show={ isOpen }
        title={ bulkPaymentModalTitle(type) }
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <Form
            businessId={ businessId }
            formId={ formId }
            totalAmount={ totalAmount }
            type={ type }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const MemoizedMarkAsPaidModal = React.memo(MarkAsPaidModal);
const useMarkAsPaidModal = makeUseModal<
 typeof MemoizedMarkAsPaidModal, TOpenValue>(MemoizedMarkAsPaidModal);

export {
  IMarkAsPaidModalProps,
  useMarkAsPaidModal,
  MarkAsPaidModal as default,
};
