import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useSorting } from '@src/hooks/url_params';
import { IGetSimpleChartOfAccountsResponse, useGetSimpleChartOfAccounts } from '@src/requests/simple_chart_of_accounts';
import { TSection } from '@src/types/common';
import { ISimpleChartOfAccount, TISimpleChartOfAccountsSortColumn } from '@src/types/simple_chart_of_accounts';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useFilterData } from '@src/components/ui_v2/filter';

interface ICollection {
  query: UseInfiniteQueryResult<IGetSimpleChartOfAccountsResponse, Error>,
  records: ISimpleChartOfAccount[],
  section: TSection,
  sorting: ISorting<TISimpleChartOfAccountsSortColumn>,
}

const defaultSorting: ISortingParams<TISimpleChartOfAccountsSortColumn> = {
  orderColumn:    'id',
  orderDirection: 'desc',
};

const useSimpleChartOfAccounts = (): ICollection => {
  const section = useMemo(() => {
    return { section: 'simple_chart_of_accounts' };
  }, []);

  const filterData = useFilterData(section);
  const filterParams = useMemo(() => {
    return { filter: filterData };
  }, [filterData]);

  const sorting = useSorting<TISimpleChartOfAccountsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetSimpleChartOfAccounts({
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((it) => it.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  useSimpleChartOfAccounts,
};
