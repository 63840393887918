module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var doc, field, i, j, len, len1, ref, ref1;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <!-- Document Select Page Begin -->\n      <div class=\'modal-add-password select-standard-document-page ');
    
      __out.push(__sanitize(this.current_page === this.SELECT_STANDARD_DOCUMENT_PAGE ? '' : 'hidden'));
    
      __out.push('\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>Add Document - Password</h4>\n        </div>\n        <div class=\'modal-body\'>\n          ');
    
      ref = this.standard_documents;
      for (i = 0, len = ref.length; i < len; i++) {
        doc = ref[i];
        __out.push('\n            <div class="standard-document-field pointer" data-document-id="');
        __out.push(__sanitize(doc.id));
        __out.push('">\n              <div class="avatar-field">\n                <img src="');
        __out.push(__sanitize(doc.icon_url));
        __out.push('" class="avatar ">\n              </div>\n              <div class="text-field">\n                <h3 class="font-16">');
        __out.push(__sanitize(doc.name));
        __out.push('</h3>\n                <p class="font-12 in-grey-800">\n                  <span>');
        __out.push(__sanitize(doc.description));
        __out.push('</span>\n                </p>\n              </div>\n            </div>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <!-- Document Select Page End -->\n      <!-- Input Form Page Begin -->\n      <div class=\'modal-add-password document-form-page ');
    
      __out.push(__sanitize(this.current_page === this.DOCUMENT_FORM_PAGE ? '' : 'hidden'));
    
      __out.push('\'>\n        <div class=\'modal-header\'>\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class=\'modal-title\'>Add Document - Password</h4>\n          ');
    
      if (this.document) {
        __out.push('\n          <h5 class=\'modal-title\'>');
        __out.push(__sanitize(this.document.name));
        __out.push('</h5>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-body\'>\n          ');
    
      if (this.document) {
        __out.push('\n          ');
        ref1 = this.document.standard_document_fields;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          field = ref1[j];
          __out.push('\n          <div class="form-group-wrapper main-input-wrap">\n            <label class="input-label" for="">');
          __out.push(__sanitize(field.name));
          __out.push('</label>\n            <input type="');
          __out.push(__sanitize(field.name.toUpperCase() === "PASSWORD" ? 'password' : 'text'));
          __out.push('" class="main-input" value="" id="new_password_document_field_');
          __out.push(__sanitize(field.id));
          __out.push('">\n            <p class="main-input__hint in-red-400"><i class="fa fa-warning">');
          __out.push(__sanitize(field.name));
          __out.push(' is missing.</i></p>\n          </div>\n          ');
        }
        __out.push('\n          ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-footer\'>\n          ');
    
      if (!this.standard_document) {
        __out.push('\n            <a class=\'back-link back settings-cancel-btn pull-left\'>Back</a>\n          ');
      }
    
      __out.push('\n          <button type=\'button\' class="btn btn-info ladda-button pull-right create-password-btn" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n      <!-- Input Form Page End -->\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}