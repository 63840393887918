import React, { useCallback } from 'react';

import some from 'lodash/some';
import { components, StylesConfig } from 'react-select';

import { ReactSelectInput } from '@src/components/ui/form';

const options: TMonthOption[] = [
  { value: 0, label: 'All months' },
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

type TMonthOption = {
  value: number,
  label: string,
}

interface IMonthSelectInputProps
{
  value: TMonthOption[],
  onChange: (value: TMonthOption[]) => void
}

const Option = React.memo((props: any) => (
  <components.Option { ...props }>
    <div className="checkbox checkbox-primary">
      <input
        readOnly
        checked={ props.isSelected }
        className="pointer form-control"
        id={ `month-select-option-${props.value}` }
        type="checkbox"
      />
      <label htmlFor={ `month-select-option-${props.value}` }>{ props.label }</label>
    </div>
  </components.Option>
));

const ValueContainer = React.memo((props: any) => (
  <components.ValueContainer { ...props }>
    <div>
      { props.getValue().length === 13 && 'All Months' }
      { props.getValue().length === 1 && props.getValue()[0].label }
      { ![0, 1, 13].includes(props.getValue().length) && 'Multiple months' }
    </div>
    { props.children }
  </components.ValueContainer>
));

const MultiValue = React.memo(() => (null));

Option.displayName = 'Option';
ValueContainer.displayName = 'ValueContainer';
MultiValue.displayName = 'MultiValue';

const customStyles: StylesConfig = {
  container: (provided) => (
    {
      ...provided,
      borderRadius: 4,
      marginLeft:   -3,
    }
  ),
  option: (provided, { isFocused }) => (
    {
      ...provided,
      backgroundColor: isFocused ? '#B2D4FF' : 'transparent',
      color:           'black',
      cursor:          'pointer',
    }
  ),
  menu: (provided) => (
    {
      ...provided,
      marginTop: 0,
    }
  ),
};

const MonthSelectInput = ({
  value,
  onChange,
  ...props
}: IMonthSelectInputProps) => {
  const handleSelect = useCallback((newValue, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      if (actionMeta.option.value === 0
        || (!some(newValue, { value: 0 }) && newValue.length === 12)
      ) {
        onChange(options);
      } else {
        onChange(newValue);
      }
    } else if (actionMeta.action === 'deselect-option') {
      if (actionMeta.option.value === 0) {
        onChange([]);
      } else if (some(newValue, { value: 0 })) {
        const newOptions = newValue.filter((option: TMonthOption) => option.value !== 0);
        onChange(newOptions);
      } else {
        onChange(newValue);
      }
    }
  }, [onChange]);

  return (
    <ReactSelectInput
      { ...props }
      hideIndicators
      isMulti
      closeMenuOnSelect={ false }
      components={ {
        Option,
        MultiValue,
        ValueContainer,
      } }
      hideSelectedOptions={ false }
      isClearable={ false }
      isSearchable={ false }
      options={ options }
      placeholder="Select Months"
      styles={ customStyles }
      value={ value }
      onChange={ handleSelect }
    />
  );
};

export {
  TMonthOption,
  IMonthSelectInputProps,
  options as monthSelectOptions,
  MonthSelectInput as default,
};
