import React, { useCallback } from 'react';

import classNames from 'classnames';

import DropdownCheckboxItem from '../../dropdown/checkbox_item';
import { TOption } from '../types';

import styles from '../styles.module.scss';

interface DropdownInputItemProps {
  selected:   TOption[],
  option:     TOption,
  disabled?:  boolean,
  onSelect:   (checked: boolean, value: TOption) => void,
}

const Item = ({
  selected,
  option,
  disabled,
  onSelect,
}: DropdownInputItemProps) => {
  const classes = classNames(
    option.value === 'all' ? styles['border-bottom-line'] : styles['border-bottom-none'],
  );

  const handleSelect = useCallback((checked: boolean) => {
    onSelect(checked, option);
  }, [option, onSelect]);
  
  return (
    <DropdownCheckboxItem
      autoHide={ false }
      checked={ selected.includes(option) }
      className={ classes }
      disabled={ disabled }
      value={ option.value }
      onSelect={ handleSelect }
    >
      {option.label}
    </DropdownCheckboxItem>
  );
};

export default Item;
