import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { useUpdateBankAccountReconciliation } from '@src/hooks/queries/bank_account_reconciliations';
import { IUpdateBankAccountReconciliationResponse } from '@src/requests/bank_account_reconciliations';
import { TBankAccountReconciliationStatus } from '@src/types/bank_account_reconciliations';

import ReconciliationReportDownloadAction from '@src/components/reconciliation_center/month_end/reconcile_action/download_action';
import DownloadPDF from '@src/components/reconciliation_center/month_end/reconcile_action/download_pdf';
import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import ReconcileButton from './reconcile';

import styles from '../styles.module.scss';

const ReconcileAction = () => {
  const reconciliationPaymentAccount = useReconciliationPaymentAccountContext();

  const baReconciliation = useBankAccountReconciliationContext();

  const updateBankAccountReconciliation = useUpdateBankAccountReconciliation();
  const { mutate } = updateBankAccountReconciliation;

  const handleModalDone = useCallback((
    status: TBankAccountReconciliationStatus,
  ) => {
    mutate(
      { id: baReconciliation.id, status },
      {
        onSettled: (resp?: IUpdateBankAccountReconciliationResponse) => {
          if (!resp) return;

          window.Docyt.vent.trigger(
            'month_end:ba_reconciliation:status_change',
            resp.baReconciliation.status,
          );
        },
      },
    );
  }, [
    baReconciliation,
    mutate,
  ]);

  if (baReconciliation.status === 'reconciled' as TBankAccountReconciliationStatus) {
    if (baReconciliation.documentId > 0) {
      return (
        <>
          <Button disabled className={ classNames(styles['reconciled-button']) } variant="success">
            Reconciled
          </Button>
          <ReconciliationReportDownloadAction documentId={ baReconciliation.documentId } />
        </>
      );
    }
    return (
      <>
        <Button disabled className={ classNames(styles['reconciled-button']) } variant="success">
          Reconciled
        </Button>
        <DownloadPDF />
      </>
    );
  }

  return (
    <>
      <MutationStatus mutation={ updateBankAccountReconciliation } />
      <ReconcileButton
        disabled={ baReconciliation.status !== 'acknowledged' as TBankAccountReconciliationStatus }
        name={ reconciliationPaymentAccount.name }
        onDone={ handleModalDone }
      />
    </>
  );
};

export default React.memo(ReconcileAction);
