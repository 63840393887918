module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="onboarding-business-client-header">\n  <a class="" href="/">\n    <img src="');
    
      __out.push(__sanitize(configData.logoURL));
    
      __out.push('" class="width-80px-important">\n  </a>\n</div>\n<div class="setup-business-container">\n  <div class="container">\n    <div class="setup-business-card">\n      <div class="setup-business-side-menu">\n        <ul class="nav nav-sidebar">\n          <li class="active">\n            <a class="">\n              <div class="">\n                <img src="');
    
      __out.push(__sanitize(configData.images.shop_image));
    
      __out.push('">\n              </div>\n              <span class="nav-item-text">Add Business Details</span>\n            </a>\n          </li>\n        </ul>\n      </div>\n      <div class="setup-business-panel"></div>\n    </div>\n  </div>\n</div>\n<div class="setup-business-footer">\n  <div class="container">\n    <button type=\'button\' class="btn btn-blue finish-setup-js pull-right">Finish Setup</button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}