import { useMemo, useCallback } from 'react';

import { reduce } from 'lodash';
import omit from 'lodash/omit';
import reverse from 'lodash/reverse';
import { UseQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useRevenueSystemFayeChannel } from '@src/hooks/faye/revenue_system';
import { useGetRevenueServiceDocuments } from '@src/hooks/queries/revenue_service_documents';
import { useGetRevenueDeposits } from '@src/hooks/queries/revenue_systems';
import { useSorting } from '@src/hooks/url_params';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { TID, TSection } from '@src/types/common';
import { IPaymentProcessor } from '@src/types/payment_processors';
import {
  IRevenueServiceDocument, IRevenueServiceDocumentsFilter, IRevenueServiceDocumentsQueryFilter,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import {
  IRevenueDeposit,
  IRevenueSystem,
} from '@src/types/revenue_systems';
import { ISorting, ISortingParams } from '@src/types/sorting';
import {
  apiDaysForMonth,
  endOfMonthApiDate,
  formatApiDate,
  formatApiMonth,
  parseApiMonth,
  startOfMonthApiDate,
} from '@src/utils/date_helpers';

import { usePaymentProcessorsForRevenueReportType } from '@src/components/revenue_center/revenue_capture/hooks';
import { emptyRSD } from '@src/components/revenue_center/utils';
import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseRevenueDepositCollectionParams {
  revenueSystem: IRevenueSystem,
}

interface IUseDepositTrackingRSDCollectionParams {
  revenueService: IRevenueService,
  revenueSystem: IRevenueSystem,
}

export interface IDepositTrackingRSDCollection {
  query: UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
  records: IRevenueServiceDocument[],
  section: TSection,
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
}

const defaultSorting: ISortingParams<TRevenueServiceDocumentsSortColumn> = {
  orderColumn:    'end_date',
  orderDirection: 'desc',
};

const defaultFilter = {
  month: formatApiMonth(new Date()),
};

const filterDataToQuery = (
  data: IRevenueServiceDocumentsFilter,
  allPaymentProcessors: IPaymentProcessor[],
): IRevenueServiceDocumentsQueryFilter => {
  const queryData: IRevenueServiceDocumentsQueryFilter = omit(data, 'month');

  if (data?.month) {
    const date = parseApiMonth(data.month);

    queryData.endDate = {
      gte: startOfMonthApiDate(date),
      lte: endOfMonthApiDate(date),
    };
  }

  queryData.state = 'verified';

  const ppCodes = allPaymentProcessors.map((pp) => pp.code);
  return reduce(queryData, (result, value, key) => {
    if (!ppCodes.includes(key)) return { ...result, [key]: value };

    return {
      ...result,
      paymentProcessors: [
        ...(result.paymentProcessors || []),
        {
          code: key,
          value,
        },
      ],
    } as IRevenueServiceDocumentsQueryFilter;
  }, {} as IRevenueServiceDocumentsQueryFilter);
};

const recordsWithMissedDays = (
  revenueServiceId: TID,
  records: IRevenueServiceDocument[],
  filter: IRevenueServiceDocumentsFilter | undefined,
  sortingData: ISortingParams<TRevenueServiceDocumentsSortColumn>,
): IRevenueServiceDocument[] => {
  if (Object.keys(filter || {}).filter((key) => key !== 'month').length > 0) return records;

  let days = apiDaysForMonth(filter?.month, { untilToday: true });

  if (sortingData.orderColumn === 'end_date') {
    if (sortingData.orderDirection === 'desc') {
      days = reverse(days);
    }

    return days.map((date) => {
      const document = records.find((d) => d.endDate === date);

      return document || emptyRSD(revenueServiceId, date, []);
    });
  }

  const existDays = records.map((r) => r.endDate);

  if (sortingData.orderDirection === 'asc') {
    const filteredDates = days.filter((d) => !existDays.includes(d));
    return [
      ...filteredDates.map((date) => emptyRSD(revenueServiceId, date, [])),
      ...records,
    ];
  }

  return [
    ...records,
    ...reverse(days).filter((d) => !existDays.includes(d))
      .map((date) => emptyRSD(revenueServiceId, date, [])),
  ];
};

const useRevenueDepositCollection = ({
  revenueSystem,
}: IUseRevenueDepositCollectionParams): IRevenueDeposit[] => {
  const filterData = useFilterData(undefined, defaultFilter);
  const date = filterData?.month ? parseApiMonth(filterData?.month) : new Date();
  const query = useGetRevenueDeposits({
    id:        revenueSystem.id,
    startDate: startOfMonthApiDate(date),
    endDate:   endOfMonthApiDate(date),
  });

  return useMemo(() => {
    return query.data?.revenueDeposits || [];
  }, [query.data?.revenueDeposits]);
};

const useDepositTrackingRSDCollection = ({
  revenueService,
  revenueSystem,
}: IUseDepositTrackingRSDCollectionParams): IDepositTrackingRSDCollection => {
  const section = useMemo(() => {
    return {
      revenueSystemId: revenueSystem.id,
      section:         Section.DepositTracking,
    };
  }, [revenueSystem]);
  const revenueReportType = useMemo(() => {
    return revenueService.revenueReportTypes.find((item) => {
      return item.id === revenueSystem.revenueReportTypeId;
    });
  }, [revenueService.revenueReportTypes, revenueSystem.revenueReportTypeId]);
  const allPaymentProcessors = usePaymentProcessorsForRevenueReportType(
    revenueReportType,
    revenueService,
  );

  const filterData = useFilterData(section, defaultFilter);
  const filterQuery = useMemo(() => {
    return filterDataToQuery(
      filterData,
      allPaymentProcessors,
    );
  }, [filterData, allPaymentProcessors]);

  const sorting = useSorting<TRevenueServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetRevenueServiceDocuments({
    revenueServiceId: revenueService.id,
    revenueSystemId:  revenueSystem.id,
    filter:           filterQuery,
    ...sorting.data,
  });

  const { refetch: refetchRevenueServiceDocuments } = query;
  const handleEventReceived = useCallback(() => {
    refetchRevenueServiceDocuments();
  }, [refetchRevenueServiceDocuments]);

  useRevenueSystemFayeChannel(revenueSystem.id, handleEventReceived);

  const records: IRevenueServiceDocument[] = useMemo(() => {
    if (query.isLoading) return [];

    // Start date has wrong format in the RevenueServiceDocument API
    const fixedDays = (query?.data?.collection || []).map((d) => ({
      ...d,
      startDate: formatApiDate(d.startDate),
      rowKey:    `${formatApiDate(d.startDate)}-${d.id}`,
      endDate:   formatApiDate(d.endDate),
    })) || [];

    return recordsWithMissedDays(
      revenueService.id,
      fixedDays,
      filterData,
      sorting.data,
    );
  }, [filterData, query.data?.collection, query.isLoading, revenueService.id, sorting.data]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  useRevenueDepositCollection,
  useDepositTrackingRSDCollection,
};
