module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="expense-clickable pointer">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.chart_of_account.number));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.accounting_class ? this.accounting_class.name : ''));
    
      __out.push('</td>\n<td class="amount-column relative text-right">');
    
      __out.push(__sanitize(this.chart_of_account.display_name));
    
      __out.push('</td>\n<td class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right font-14" aria-labelledby="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <a class="dropdown-item pointer line-height-36 display-flex align-items-center edit-category-js">\n      <i class="icon icon-edit font-16"></i>\n      <span class="m-l-5">Edit Category</span>\n    </a>\n    <a class="dropdown-item pointer in-red-400 line-height-36 display-flex align-items-center delete-category-js">\n      <i class="icon icon-trashcan font-16"></i>\n      <span class="m-l-7">Delete Category Mapping</span>\n    </a>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}