import React from 'react';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';

import { useDownloadReportModal } from '../common/download_report_modal';

interface IDownloadRevenueReportButtonProps {
  report: IRevenueServiceDocument,
}

const DownloadRevenueReportButton = ({
  report,
}: IDownloadRevenueReportButtonProps) => {
  const modal = useDownloadReportModal();

  return (
    <>
      <modal.Component
        revenueReport={ report }
        { ...modal.props }
      />
      <Button
        prefixIcon={ <DownloadDocumentIcon color="blue" fontSize={ 20 } variant="thin" /> }
        variant="link"
        onClick={ modal.open }
      >
        Download Revenue Report
      </Button>
    </>
  );
};

export default DownloadRevenueReportButton;
