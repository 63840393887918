import React, { useCallback, useMemo, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetCreditCardAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TAccountType, TID } from '@src/types/common';

import SideView from '@src/components/ui/side_view';
import Table from '@src/components/ui/table';

import Item from './item';
import { IAccountData } from './schema';

import styles from '../../styles.module.scss';

interface IBankAccountProps extends Omit<IUseModalProps, 'onDone'> {
  bankAccount?: IAccountData,
  businessId?: TID,
  accountType: TAccountType
  onDone: (selectedBankAccount: IAccountData) => void,
}

const BankAccount = ({
  isOpen,
  bankAccount,
  accountType,
  businessId,
  onCancel,
  onDone,
}: IBankAccountProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IAccountData | undefined>(bankAccount);

  const accountQuery = useGetCreditCardAccounts({
    businessId,
    accountType,
  });

  // credit card accounts or the bank accounts of the selected business
  const accounts = useMemo(() => {
    return accountQuery.data?.reconciliationPaymentAccounts || [];
  }, [accountQuery.data?.reconciliationPaymentAccounts]);

  const handleClick = useCallback((account: IAccountData) => {
    setSelectedAccount(account);
    onDone(account);
  }, [onDone]);

  const handleDone = useCallback(() => {
    if (!selectedAccount) return;

    onDone(selectedAccount);
  }, [onDone, selectedAccount]);

  return (
    <SideView.Standard
      hideActions
      isOpen={ isOpen }
      title={ `Select ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}` }
      onCancel={ onCancel }
      onDone={ handleDone }
    >
      { () => (
        <div className={ styles['financial-institution-connection-center-list'] }>
          <Table
            className={ styles['finacial-institution-connection-center-table'] }
            wrapperClassName="banking-transactions-table-wrapper"
          >
            <Table.Head>
              <Table.Row className={ styles['accounts-item-header'] }>
                <Table.Cell className={ styles['accounts-item-header-cell'] }>
                  <span>{accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}</span>
                </Table.Cell>
                <Table.Cell className={ styles['accounts-item-header-cell'] }>
                  <span>Last 4 Digits</span>
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
                accounts.map((account) => (
                  <Item
                    key={ account.id }
                    account={ account }
                    accountType={ accountType }
                    onSelected={ handleClick }
                  />
                ))
              }
            </Table.Body>
          </Table>
        </div>
      ) }
    </SideView.Standard>
  );
};

const MemoizedBankAccount = React.memo(BankAccount);

const useSelectAccount = makeUseModal(MemoizedBankAccount);

export {
  useSelectAccount,
  MemoizedBankAccount as default,
};
