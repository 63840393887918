module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.original_w9_file_name) {
        __out.push('\n  <div class="display-flex justify-content-between align-items-center p-t-20 p-b-20  font-14">\n    <span class="font-bold">');
        __out.push(__sanitize(this.original_w9_file_name));
        __out.push('</span>\n    <div class="display-flex align-items-center">\n      <span class="p-r-10">');
        __out.push(__sanitize(this.file_updated_date));
        __out.push('</span>\n      <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="w9-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class=\'icon icon-3-dots font-20\'></i>\n      </button>\n      <div class="pointer document-action-dropdown dropdown-menu dropdown-menu-right" style="top: 50px; right: -1px;" aria-labelledby="w9-action-dropdown-btn">\n        <div class="dropdown-item font-14 download-w9-form">\n          <i class="icon icon-download"></i> <span>Download W-9 Form</span>\n        </div>\n        <div class="dropdown-item font-14 remove-w9-form in-red-400">\n          <i class="icon icon-trashcan"></i> <span>Remove W-9 Form</span>\n        </div>\n      </div>\n    </div>\n  </div>\n');
      }
    
      __out.push('\n<div style="');
    
      __out.push(__sanitize(this.original_w9_file_name ? 'height: 92vh;' : this.isShowDocBar ? 'height: calc(100% - 50px);' : 'height: 100%;'));
    
      __out.push('">\n  ');
    
      if (this.thumbsMode) {
        __out.push('\n    <iframe class="pdf-viewer" src="pdfjs/web/viewer-thumbs.html#pagemode=thumbs" allowfullscreen="" webkitallowfullscreen=""></iframe>\n  ');
      } else {
        __out.push('\n    <iframe class="pdf-viewer" src="pdfjs/web/viewer.html" allowfullscreen="" webkitallowfullscreen=""></iframe>\n  ');
      }
    
      __out.push('\n</div>\n<div class="invoice-documents-bar"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}