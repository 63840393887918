import React from 'react';

import { ReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useGetReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { TMongoID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IReportServiceBudgetProviderProps {
  budgetId: TMongoID,
  children: React.ReactNode,
}

const ReportServiceBudgetProvider = ({
  budgetId,
  children,
}: IReportServiceBudgetProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetReportServiceBudget(budgetId);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Budget loaded" />;

  return (
    <ReportServiceBudgetContext.Provider value={ data }>
      { children }
    </ReportServiceBudgetContext.Provider>
  );
};

export default ReportServiceBudgetProvider;
