/* eslint-disable import/order */
import React, { memo, useCallback, useState } from 'react';

import { TextInput } from '@src/components/ui_v2/inputs';

import AiChatSendButton from './chat_input_send_button';

import { useAiChatBotContext } from '../context/ai_chat_context';

import styles from '../styles.module.scss';

interface IChatInputProps {
  loader: boolean;
  setLoader: (value: boolean) => void;
}

const ChatInput = ({
  loader,
  setLoader,
}: IChatInputProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { startConversation, activeChannelID,
    sendUserMessage } = useAiChatBotContext();

  const handleMessageSend = useCallback(async (message: string) => {
    if (activeChannelID && message) {
      setLoader(true);
      await sendUserMessage(message);
      setInputValue('');
    } else if (message) {
      setLoader(true);
      startConversation({
        message,
      });
      setInputValue('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startConversation, activeChannelID, sendUserMessage]);

  const handleInputChange = useCallback((e: any) => {
    if (!activeChannelID && e.target.value.length === 1) {
      startConversation({});
    }
    setInputValue(e.target.value);
  }, [activeChannelID, startConversation]);

  const handleSuffixClick = useCallback(() => {
    handleMessageSend(inputValue);
  }, [inputValue, handleMessageSend]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      handleMessageSend(inputValue);
    }
  }, [inputValue, handleMessageSend]);

  return (
    <div className={ styles['chat-bottom-section'] }>
      <div className={ styles['chat-send-container'] }>
        <TextInput
          hideClear
          autoComplete="off"
          className={ styles['form-control'] }
          disabled={ loader }
          placeholder="Ask a question..."
          suffixIcon={
            <AiChatSendButton isActive={ !!inputValue } onClick={ handleSuffixClick } />
          }
          type="text"
          value={ inputValue }
          onChange={ handleInputChange }
          onKeyDown={ handleKeyDown }
        />
      </div>
    </div>
  );
};

export default memo(ChatInput);
