import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ISideViewActionsProps {
  children: React.ReactNode,
}

const SideViewActions = ({
  children,
}: ISideViewActionsProps) => {
  const classes = classNames('banking-accounts-right-side-action-view', styles.actions);

  return (
    <div className={ styles['actions-container'] } id="banking-accounts-right-side-action-region">
      <div className={ classes }>
        { children }
      </div>
    </div>
  );
};

export default SideViewActions;
