import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { TMongoID } from '@src/types/common';
import { IChartOfAccount } from '@src/types/report_service/report';

import ReportProvider from '@src/components/providers/report_provider';
import Root from '@src/components/root';

import AcceptedAccounts from './accepted_accounts';
import EditMappingActionGroup from './edit_mapping_actions_dropdown';

interface IEditMappingActionsProps {
  reportId: TMongoID,
  chartOfAccounts: IChartOfAccount[],
  currentBusiness: IBusiness,
  templateId: string,
  isBusinessAdmin: boolean,
  isReportServiceAdmin: boolean,
}

const EditMappingActions = ({
  reportId,
  chartOfAccounts,
  currentBusiness,
  templateId,
  isBusinessAdmin,
  isReportServiceAdmin,
}: IEditMappingActionsProps): JSX.Element => {
  return (
    <Root>
      <ReportProvider reportId={ reportId }>
        <AcceptedAccounts
          chartOfAccounts={ chartOfAccounts }
          isBusinessAdmin={ isBusinessAdmin }
          isReportServiceAdmin={ isReportServiceAdmin }
        />
        <EditMappingActionGroup
          currentBusiness={ currentBusiness }
          templateId={ templateId }
        />
      </ReportProvider>
    </Root>
  );
};

export default EditMappingActions;
