import React, { useCallback, useState } from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import useConfirmModal from '@src/components/reconciliation_center/all_transactions/bulk_actions/confirm_modal';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { CaretIcon } from '@src/components/utils/fa_icons';

import CrossIcon from '../../../utils/icomoon/cross_icon';
import DocytSupportIcon from '../../../utils/icomoon/docyt_support';
import MarkReviewedIcon from '../../../utils/icomoon/mark-reviewed';
import ReOpenIcon from '../../../utils/icomoon/re_open';

import styles from '../styles.module.scss';

const BulkActions = ({ selectedRecords, markAll, isAllSelected }: {
  selectedRecords: ITransactionServiceDocument[];
  isAllSelected: () => boolean;
  markAll: (checked: boolean) => void;
}) => {
  const confirmModal = useConfirmModal();
  const [updateToFlaggedState, setUpdateToFlaggedState] = useState<null | string>(null);

  const handleChange = useCallback((checked) => {
    markAll(checked);
  }, [markAll]);

  const flaggedStates = selectedRecords.map((i) => i.flaggedState);
  const allSame = flaggedStates.length > 0 && flaggedStates.every((val, _, arr) => val === arr[0]);
  const disabled = flaggedStates.length === 0 || !allSame;
  const selectedState = flaggedStates[0];

  const openConfirmModal = (targetFlaggedState: string | null) => {
    confirmModal.open();
    setUpdateToFlaggedState(targetFlaggedState);
  };

  return (
    <div className={ styles['bulk-actions-container'] }>
      <CheckboxInput checked={ isAllSelected() } onChange={ handleChange } />
      <ActionsDropdown
        alignment="left"
        title={ <CaretIcon variant="down" /> }
        titleClassName={ disabled ? 'disabled in-black' : 'in-black' }
      >
        {allSame && selectedState === null && (
          <ActionsDropdown.Action
            icon={ <DocytSupportIcon fontSize={ 18 } /> }
            name="Flag for Client Review"
            onClick={ () => openConfirmModal('open') }
          />
        )}
        {allSame && selectedState === 'open' && (
          <>
            <ActionsDropdown.Action
              icon={ <MarkReviewedIcon fontSize={ 18 } /> }
              name="Mark as Reviewed"
              onClick={ () => openConfirmModal('resolved') }
            />
            <ActionsDropdown.Action
              icon={ <CrossIcon fontSize={ 18 } /> }
              name="Close Issue"
              onClick={ () => openConfirmModal(null) }
            />
          </>
        )}
        {allSame && selectedState === 'resolved' && (
          <>
            <ActionsDropdown.Action
              icon={ <CrossIcon fontSize={ 18 } /> }
              name="Close Issue"
              onClick={ () => openConfirmModal(null) }
            />
            <ActionsDropdown.Action
              icon={ <ReOpenIcon fontSize={ 18 } /> }
              name="Re-open Issue"
              onClick={ () => openConfirmModal('open') }
            />
          </>
        )}
      </ActionsDropdown>

      <confirmModal.Component
        selectedRecords={ selectedRecords }
        updateToFlaggedState={ updateToFlaggedState }
        { ...confirmModal.props }
      />
    </div>
  );
};

export default BulkActions;
