module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog mark-as-paid-invoice-modal\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title m-l-10">Apply "Mark as Paid" rule?</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <div class="row m-t-10">\n              <p>This invoice matches a "Mark as Paid" rule with following payment account</p>\n            </div>\n            <div class="row m-t-10">\n              <div class="col-sm-8">\n                <div class="header__userpic inline-block m-l-10">\n                  ');
    
      if (this.payment_mode === 'credit_card') {
        __out.push('\n                    <i class="font-36 icon icon-creditcard"></i>\n                  ');
      } else {
        __out.push('\n                    <i class="font-36 icon icon-check-card"></i>\n                  ');
      }
    
      __out.push('\n                </div>\n                <div class="font-15 inline-block m-l-15 vertical-align-top">\n                  <p class="m-b-0">');
    
      __out.push(__sanitize(this.account_name));
    
      __out.push('</p>\n                  <p class="m-b-0">');
    
      __out.push(__sanitize(this.account_number));
    
      __out.push('</p>\n                </div>\n              </div>\n            </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <button type=\'button\' class="btn btn-danger ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Accept Rule</span></button>\n          <a class=\'cancel-link cancel pointer\'>Ignore Rule</a>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}