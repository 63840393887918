import { TID } from '@src/types/common';
import { IReportServiceBudgetItemValue } from '@src/types/report_service/report_service_budget_item_values';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { IBudgetItemsFilter } from '@src/components/business_reports/budgets/filter';

import { apiGet, apiPost } from '../helpers';

interface IGetReportServiceBudgetItemsParams {
  budgetId: TID,
  filter?: IBudgetItemsFilter,
  page?: number,
}

interface IGetReportServiceBudgetItemsResponse {
  meta: {
    totalCount: number,
    monthTotalAmounts: number[],
  },
  collection: IReportServiceBudgetItem[],
}

interface IUpdateBudgetItemParams {
  id: TID,
  budgetId: TID,
  budgetItemValues: IReportServiceBudgetItemValue[],
  reportComparerItemFormulaMonthlyValues?: IReportServiceBudgetItemValue[],
}

interface ICalculateBudgetItemParams {
  id: TID,
  budgetId: TID,
  formulaId: string,
  formulaInput: number,
  formulaChartOfAccountId?: number | null;
}

interface ICalculateValuesPerMonthItemParams {
  id: TID,
  budgetId: TID,
  formulaId: string,
  formulaInput: number,
  formulaChartOfAccountId?: number | null;
  month: number;
}

interface ICalculateValuesPerMonthBudgetItemParams {
  budgetItemId: TID,
  budgetId: TID,
}

interface IBudgetsFileParams {
  startDate: string,
  endDate: string,
  budgetID: string,
  filter: object
}

interface IBudgetsFileResponse {
}

const getReportServiceBudgetItems = (
  params: IGetReportServiceBudgetItemsParams,
): Promise<IGetReportServiceBudgetItemsResponse> => {
  return apiGet(
    '/reports/api/v1/report_comparer_items',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta: {
        totalCount:        cdata.total,
        monthTotalAmounts: cdata.meta.monthTotalAmounts,
      },
      collection: cdata.budgetItems as IReportServiceBudgetItem[],
    };
  });
};

const updateBudgetItem = (params: IUpdateBudgetItemParams): Promise<void> => {
  return apiPost(
    'reports/api/v1/report_comparer_items/upsert',
    underscoreKeys(params),
  );
};

const calculateBudgetItem = (params: ICalculateBudgetItemParams): Promise<void> => {
  return apiPost(
    'reports/api/v1/report_comparer_items/calculate',
    underscoreKeys(params),
  );
};

const calculateValuesPerMonthItem = (params: ICalculateValuesPerMonthItemParams): Promise<void> => {
  return apiPost(
    '/reports/api/v1/report_comparer_items/calculate_month_value',
    underscoreKeys(params),
  );
};

const exportBudget = (params: IBudgetsFileParams): Promise<IBudgetsFileResponse> => {
  return apiPost(
    '/reports/api/v1/export_budgets',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IBudgetsFileResponse);
};

const showPerMonthItem = (params: ICalculateValuesPerMonthBudgetItemParams): Promise<void> => {
  return apiGet(
    `/reports/api/v1/report_comparer_items/${params.budgetItemId}?budget_id=${params.budgetId}`,
  ).then((data) => camelizeKeys(data) as any);
};

export {
  IGetReportServiceBudgetItemsParams,
  IGetReportServiceBudgetItemsResponse,
  IUpdateBudgetItemParams,
  ICalculateBudgetItemParams,
  ICalculateValuesPerMonthItemParams,
  getReportServiceBudgetItems,
  updateBudgetItem,
  calculateBudgetItem,
  calculateValuesPerMonthItem,
  showPerMonthItem,
  ICalculateValuesPerMonthBudgetItemParams,
  IBudgetsFileParams,
  IBudgetsFileResponse,
  exportBudget,
};
