import React, { FC } from 'react';

import { CheckMarkIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface WizardProps {
  step: number;
}

const STEPS: { [key: number]: string } = {
  1: 'Match Transactions',
  2: 'Acknowledge Difference',
};

const Wizard: FC<WizardProps> = ({ step }) => {
  const steps = Object.keys(STEPS).map((key) => {
    const stepNumber = parseInt(key, 10);
    const stepName = STEPS[stepNumber];
    const isActive = stepNumber === step;
    const isCompleted = stepNumber < step;

    const classNames = [];
    classNames.push(styles['wizard-step']);
    if (isActive) classNames.push(styles.active);
    if (isCompleted) classNames.push(styles.completed);

    return (
      <div key={ key } className={ classNames.join(' ') }>
        <div className={ styles['wizard-step-counter'] }>{ isCompleted ? <CheckMarkIcon color="white" /> : stepNumber }</div>
        <div className={ styles['wizard-step-name'] }>
          Step
          { ' ' }
          { stepNumber }
          :
          { ' ' }
          { stepName }
        </div>
      </div>
    );
  });

  return (
    <div className={ styles['wizard-wrapper'] }>
      <div className={ styles.wizard }>
        { steps }
      </div>
    </div>
  );
};

export default Wizard;
