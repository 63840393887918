import React from 'react';

import { BankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useGetBankStatement } from '@src/hooks/queries/bank_statements';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IBankStatementProviderProps {
  businessId: TID,
  bankStatementId: TID,
  children: React.ReactNode,
}

const BankStatementProvider = ({
  businessId,
  bankStatementId,
  children,
}: IBankStatementProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetBankStatement(businessId, bankStatementId);
  if (isLoading) {
    return <Spinner localSpinnerId="detail-region" />;
  }
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Bank Statement loaded" />;

  return (
    <BankStatementContext.Provider value={ data.bankStatement! }>
      { children }
    </BankStatementContext.Provider>
  );
};

export default BankStatementProvider;
