import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import Body from './pending_review/pending_review';

interface IPendingReviewPageProps {
  businessId: TID,
}

const PendingReviewPage = ({
  businessId,
}: IPendingReviewPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <Body businessId={ businessId } />
      </BusinessProvider>
    </Root>
  );
};

export default PendingReviewPage;
