import React, { useCallback, useMemo } from 'react';

import { flatMapDeep } from 'lodash';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import { DropdownInput, formatSelectOption, TOption } from '@src/components/ui_v2/inputs';

interface ITransactionTypeFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof DropdownInput>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  transactionTypeName?: string,
}

export interface TSubTransactionTypeOption extends TFilterData {
  transaction_type: string | undefined,
}

const formatTooltip = (option: TOption | null): JSX.Element | null => {
  if (!option) return null;

  return formatSelectOption(option);
};

const AllValue = 'all';

const TransactionTypeOptions: TOption[] = [
  { value: AllValue, label: 'All Transactions' },
  {
    value: window.configData.transaction_types.ASSET_PURCHASE,
    label: 'Asset Withdrawal',
  },
  {
    value: window.configData.transaction_types.CHARGEBACK,
    label: 'Chargeback',
  },
  {
    value: window.configData.transaction_types.CUSTOMER_CLAIMS,
    label: 'Customer Claims',
  },
  {
    value: window.configData.transaction_types.EQUITY_INVESTMENT,
    label: 'Equity Investment',
  },
  {
    value: window.configData.transaction_types.EQUITY_WITHDRAWAL,
    label: 'Equity Withdrawal',
  },
  {
    value: window.configData.transaction_types.EXPENSE,
    label: 'Expense',
  },
  {
    value: window.configData.transaction_types.LIABILITY_PAYMENTS,
    label: 'Liability Payments',
  },
  {
    value: window.configData.transaction_types.LOAN_PAYMENTS,
    label: 'Loan Payments',
  },
  {
    value: window.configData.transaction_types.LOAN_PROCEEDS,
    label: 'Loan Proceeds',
  },
  {
    value: window.configData.transaction_types.OTHER_INCOME,
    label: 'Other Income',
  },
  {
    value: window.configData.transaction_types.OTHER_RECEIVABLES,
    label: 'Other Receivables',
  },
  {
    value: window.configData.transaction_types.PAYROLL,
    label: 'Payroll Charges',
  },
  {
    value: window.configData.transaction_types.REVENUE,
    label: 'Revenue',
  },
  {
    value: window.configData.transaction_types.REVENUE_REVERSAL,
    label: 'Revenue Reversal',
  },
  {
    value: window.configData.transaction_types.TRANSFER,
    label: 'Transfer',
  },
  {
    value: window.configData.transaction_types.UNVERIFIED,
    label: 'Uncategorized Transactions',
  },
  {
    value: window.configData.transaction_types.REFUND,
    label: 'Vendor Refunds',
  },
];

const TransactionTypeFilterField = <TFilter extends TFilterData>({
  transactionTypeName = 'transaction_type',
  ...props
}: ITransactionTypeFilterFieldProps): JSX.Element => {
  const [txnType, updateTxnType] = useFilterField<TFilter, string[] | null>(
    transactionTypeName,
  );

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedValue?: TOption | null) => {
    let newTransactionType: string[] | undefined;

    switch (selectedValue?.value) {
      case 'all':
      case undefined:
        newTransactionType = undefined;
        break;
      default:
        newTransactionType = selectedValue ? [selectedValue.value] : undefined;
        break;
    }

    updateTxnType(newTransactionType);
  }, [updateTxnType]);

  const currentOption = useMemo(() => {
    return flatMapDeep(TransactionTypeOptions, (opt) => {
      return opt;
    }).find((opt) => {
      return txnType && opt.value === txnType[0];
    });
  }, [txnType]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption || null) }
      tooltipLabel="Type: "
    >
      <DropdownInput
        placeholder="Select Transaction Type..."
        { ...inputProps }
        options={ TransactionTypeOptions }
        value={ currentOption || null }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(TransactionTypeFilterField);
