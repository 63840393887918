import { useMemo } from 'react';

import { Section } from '@src/constants/sections';
import { useGetLimitedAccountsPayableServiceDocuments } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useSorting } from '@src/hooks/url_params';
import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

interface IUsePaymentsCollectionParams {
  businessId: TID,
  serviceId: TID,
  vendorId?: TID,
}

interface IPaymentsCollection {
  records: IAccountsPayableServiceDocument[],
  section: TSection,
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
}

const defaultSorting: ISortingParams<TAccountsPayableServiceDocumentsSortColumn> = {
  orderColumn:    'invoice_date',
  orderDirection: 'desc',
};

const usePaymentsCollection = ({
  businessId,
  serviceId,
  vendorId,
}: IUsePaymentsCollectionParams): IPaymentsCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsPayablePastPayments,
    };
  }, [businessId]);

  const sorting = useSorting<TAccountsPayableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetLimitedAccountsPayableServiceDocuments({
    serviceId,
    filters: {
      queueType: 'paid',
      vendorId,
    },
    orderColumn:    'invoice_date',
    orderDirection: 'desc',
    perPage:        5,
  });

  const records = query.data?.collection || [];

  return {
    records,
    section,
    sorting,
  };
};

export {
  IPaymentsCollection,
  usePaymentsCollection,
};
