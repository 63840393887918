import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IStatementDetailsFilter } from './types';

interface IStatementFilterProps {
  filter: IFilter<IStatementDetailsFilter>;
}

const StatementFilter = ({
  filter,
}: IStatementFilterProps) => {
  return (
    <Filter<IStatementDetailsFilter> filter={ filter }>
      <Filter.YearField
        name="year"
        options={ { endDate: new Date() } }
        placeholder="Year"
        widthPercent={ 12 }
      />
    </Filter>
  );
};

export default React.memo(StatementFilter);
