import React, { forwardRef, useCallback, useRef, useState } from 'react';

import classNames from 'classnames';
import { mergeRefs } from 'react-merge-refs';

import InputIndicatorWrapper from './input_indicator_wrapper';

import styles from './styles.module.scss';

interface ITextAreaInputProps extends Omit<React.ComponentPropsWithoutRef<'textarea'>, 'size'> {
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, ITextAreaInputProps>(({
  className,
  value,
  onChange,
  ...inputProps
}: ITextAreaInputProps, ref): JSX.Element => {
  const [internalValue, setInternalValue] = useState(undefined);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const classes = classNames(
    styles['text-input'],
    className,
  );

  const handleClear = useCallback(() => {
    if (!inputRef.current) return;

    const setValue = Object?.getOwnPropertyDescriptor(Object.getPrototypeOf(inputRef.current), 'value')?.set;
    if (!setValue) return;
    const event = new Event('input', { bubbles: true });

    setValue.call(inputRef.current, '');
    inputRef.current.dispatchEvent(event);
  }, []);

  // Sometime this input is used as uncontrolled input. In this case we have to keep
  // internal value ourself
  const handleChange = useCallback((e) => {
    setInternalValue(e.currentTarget.value);

    if (onChange) onChange(e);
  }, [onChange]);

  return (
    <InputIndicatorWrapper
      hasValue={ Boolean(value || internalValue) }
      onClear={ handleClear }
    >
      <textarea
        ref={ mergeRefs([inputRef, ref]) }
        className={ classes }
        value={ value }
        onChange={ handleChange }
        { ...inputProps }
      />
    </InputIndicatorWrapper>
  );
});

TextAreaInput.displayName = 'TextAreaInput';

export default React.memo(TextAreaInput);
