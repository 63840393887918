import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import SettlementItems from './settlement';

interface IAccountsPayableSettlementPageProps {
  businessId: TID,
  serviceId: TID,
  type: string,
}

const AccountsPayableSettlementPage = ({
  businessId,
  serviceId,
  type,
}: IAccountsPayableSettlementPageProps): JSX.Element => {
  return (
    <Root>
      <SettlementItems
        businessId={ businessId }
        serviceId={ serviceId }
        type={ type }
      />
    </Root>
  );
};

export default AccountsPayableSettlementPage;
