import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IBusinessInboxItemDownloadActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemDownloadAction = ({
  document,
}: IBusinessInboxItemDownloadActionProps): JSX.Element => {
  const downloadDocument = useDownloadDocument();

  const { mutate } = downloadDocument;
  const handleDownload = useCallback((type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentId: document.id, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, data.fileName) },
    );
  }, [mutate, document.id]);

  const handleOriginalFileSelect = useCallback(() => {
    handleDownload('original');
  }, [handleDownload]);

  const handleExtractedPdfSelect = useCallback(() => {
    handleDownload('extracted');
  }, [handleDownload]);

  return (
    <>
      <MutationStatus mutation={ downloadDocument } />
      <ActionsDropdown.Submenu
        icon={ <DownloadDocumentIcon fontSize={ 14 } /> }
        name="Download"
      >
        <ActionsDropdown.Action
          name="Original File"
          onClick={ handleOriginalFileSelect }
        />
        <ActionsDropdown.Action
          name="Extracted PDF"
          onClick={ handleExtractedPdfSelect }
        />
      </ActionsDropdown.Submenu>
    </>
  );
};

export default BusinessInboxItemDownloadAction;
