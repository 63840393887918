module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountOwner, i, len, ref;
    
      __out.push('<h3 class="right-side-subtitle">Debit Card</h3>\n<hr />\n<div class="document-field">\n  <div class="document-field-item document-field-name">Bank Name</div>\n  <div class="document-field-item document-field-value">\n    <input class="bank-name-input form-control" type="text" value="" placeholder="Bank Name - XXXX" />\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Bank Name is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Debit Card No.<div class="optional-field-mark">(last 4 digits)</div></div>\n  <div class="document-field-item document-field-value">\n    <input class="account-number-input int-document-field form-control" type="text" value="" placeholder="XXXX" maxlength="4"/>\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Debit Card Number is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">CVV #<div class="optional-field-mark">(optional)</div></div>\n  <div class="document-field-item document-field-value">\n    <input class="cvv-input int-document-field form-control" type="text" value="" placeholder="CVV #">\n  </div>\n</div>\n');
    
      if (this.rcPaymentAccount) {
        __out.push('\n<div class="document-field">\n  <div class="document-field-item document-field-name">Account Owner<div class="optional-field-mark">(optional)</div></div>\n  <div class="document-field-item document-field-value account-owner-select-wrapper">\n    <select class="selectpicker account-owner-select" title="Account Owner (Optional)">\n      <option selected>None</option>\n      ');
        ref = this.accountOwners;
        for (i = 0, len = ref.length; i < len; i++) {
          accountOwner = ref[i];
          __out.push('\n      <option value=');
          __out.push(__sanitize(accountOwner));
          __out.push(' ');
          if (this.account_owner === accountOwner) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(accountOwner));
          __out.push('</option>\n      ');
        }
        __out.push('\n    </select>\n  </div>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}