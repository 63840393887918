import React, { FC } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetSimpleChartOfAccountsResponse } from '@src/requests/simple_chart_of_accounts';
import { ISimpleChartOfAccount, TISimpleChartOfAccountsSortColumn } from '@src/types/simple_chart_of_accounts';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

import Actions from './actions';
import Filter from './filter';

interface Props {
  infiniteQuery: UseInfiniteQueryResult<IGetSimpleChartOfAccountsResponse, Error>,
  records: ISimpleChartOfAccount[],
  sorting: ISorting<TISimpleChartOfAccountsSortColumn>,
}

const formatBusinesses = (chartOfAccount: ISimpleChartOfAccount) => {
  const businessChartOfAccounts = chartOfAccount.businessChartOfAccounts;

  if (!businessChartOfAccounts) return '';

  if (businessChartOfAccounts.length === 0) {
    return '';
  }

  if (businessChartOfAccounts.length === 1) {
    return businessChartOfAccounts[0].businessName;
  }

  return `${businessChartOfAccounts[0].businessName} + ${businessChartOfAccounts.length - 1} more`;
};

const Component: FC<Props> =
  ({ records, sorting, infiniteQuery }) => {
    const actions = (chartOfAccount: ISimpleChartOfAccount) => {
      return <Actions chartOfAccount={ chartOfAccount } />;
    };

    return (
      <CollectionTable<ISimpleChartOfAccount, TISimpleChartOfAccountsSortColumn>
        isRegionScroll
        showSelect
        filter={ <Filter /> }
        height="90vh"
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.TextColumn<
          ISimpleChartOfAccount,
          TISimpleChartOfAccountsSortColumn
        >
          name="account"
          sortColumn="account"
          title="Account"
          value={ (it) => `${it.displayName}` }
        />

        <CollectionTable.TextColumn<
          ISimpleChartOfAccount,
          TISimpleChartOfAccountsSortColumn
        >
          name="account_type"
          sortColumn="account_type"
          title="Account Type"
          value={ (it) => it.accTypeName }
        />

        <CollectionTable.TextColumn<
          ISimpleChartOfAccount,
          TISimpleChartOfAccountsSortColumn
        >
          name="detail_type"
          sortColumn="detail_type"
          title="Detail Type"
          value={ (it) => it.subType }
        />

        <CollectionTable.TextColumn<
          ISimpleChartOfAccount,
          TISimpleChartOfAccountsSortColumn
        >
          name="business"
          title="Business"
          tooltip={ (it) => it.businessChartOfAccounts?.map((item) => item.businessName).join(', ') }
          value={ (it) => formatBusinesses(it) }
        />

        <CollectionTable.ActionsColumn<ISimpleChartOfAccount>
          name="actions"
          title="Actions"
          value={ actions }
        />
      </CollectionTable>
    );
  };

export default Component;
