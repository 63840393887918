import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IWarningIconProps extends Omit<IFaIconProps, 'icon'> {}

const WarningIcon = (props: IWarningIconProps): JSX.Element => {
  return <FaIcon icon="warning" { ...props } />;
};

export default WarningIcon;
