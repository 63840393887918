import React, { useCallback } from 'react';

import { formatDate } from '@src/utils/date_helpers';

import Dropdown from '@src/components/ui_v2/dropdown';

import { TPeriod } from './types';

import styles from './styles.module.scss';

interface IDateRangePeriodProps {
  period: TPeriod,
  onSelected: (period: TPeriod) => void,
}

const DateRangePeriod = ({
  period,
  onSelected,
}: IDateRangePeriodProps) => {
  const handleSelect = useCallback(() => {
    onSelected(period);
  }, [period, onSelected]);

  return (
    <Dropdown.Item
      className={ styles['date-range-period'] }
      onSelect={ handleSelect }
    >
      <Dropdown.ItemLabel>
        { period.label }
      </Dropdown.ItemLabel>
      {
        (period.startDate || period.endDate) && (
          <Dropdown.ItemHelper>
            { [formatDate(period.startDate), formatDate(period.endDate)].filter(Boolean).join(' - ') }
          </Dropdown.ItemHelper>
        )
      }
    </Dropdown.Item>
  );
};

export default DateRangePeriod;
