import React from 'react';

import classNames from 'classnames';

import { TAmount } from '@src/types/common';
import { negateAmount } from '@src/utils/amount_helper';
import { currencyLocaleFormatter } from '@src/utils/currency';

import Tooltip from '@src/components/ui_v2/tooltip';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ITableAmountCellProps extends Omit<ITableCellProps, 'children'> {
  amount: TAmount | number | undefined | null,
  emptyValue?: React.ReactNode,
  formatter?: (amount: TAmount | number | undefined | null) => string,
  icon?: React.ReactNode,
  negativeValue?: boolean,
  tooltip?: React.ReactNode,
  tooltipProps?: Omit<React.ComponentPropsWithoutRef<typeof Tooltip.Hover>, 'content' | 'children'>,
}

const TableAmountCell = ({
  amount,
  emptyValue = '—',
  formatter,
  className,
  icon,
  negativeValue = false,
  tooltip,
  tooltipProps,
  ...cellProps
}: ITableAmountCellProps) => {
  // eslint-disable-next-line max-len
  const value = formatter ? formatter(negativeValue ? negateAmount(amount) : amount) : currencyLocaleFormatter(amount);
  const classes = classNames(styles['table-amount-cell'], className);

  let cellContent = (
    <>
      { icon }
      { ' ' }
      { amount ? value : emptyValue }
    </>
  );

  if (tooltip) {
    cellContent = (
      <Tooltip.Hover
        content={ tooltip }
        { ...tooltipProps }
      >
        { cellContent }
      </Tooltip.Hover>
    );
  }

  return (
    <Cell
      className={ classes }
      { ...cellProps }
    >
      { cellContent }
    </Cell>
  );
};

export default TableAmountCell;
