/* eslint-disable max-len */

import React, { useCallback } from 'react';

import { rcMonthEndDocmentsOtherDocumentsStatementsDetailsPath } from '@src/routes';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import Table from '@src/components/ui/table';

import MissingStatusItem from './items/missing_status_item';
import NotAvailableStatusItem from './items/not_available_status_item';
import RequestedStatusItem from './items/requested_status_item';
import UploadedStatusItem from './items/uploaded_status_item';
import VerifiedStatusItem from './items/verified_status_item';

interface IBalanceSheetStatementStateItemProps {
  businessId: TID,
  chartOfAccountId: TID,
  state: IStatementState,
}

const BalanceSheetStatementStateItem = ({
  businessId,
  chartOfAccountId,
  state,
}: IBalanceSheetStatementStateItemProps): JSX.Element => {
  let content;

  const month = new Date(state.month).getMonth() + 1;

  const handleClickItem = useCallback(() => {
    const redirectPath = rcMonthEndDocmentsOtherDocumentsStatementsDetailsPath(
      businessId,
      chartOfAccountId,
      state.year,
      month,
    );
    Backbone.history.navigate(redirectPath, { trigger: true });
  }, [businessId, chartOfAccountId, state.year, month]);

  if (state.state === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.REQUESTED) {
    content = (
      <RequestedStatusItem
        state={ state }
        onClickItem={ handleClickItem }
      />
    );
  } else if (state.state === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.UPLOADED) {
    content = (
      <UploadedStatusItem
        state={ state }
        onClickItem={ handleClickItem }
      />
    );
  } else if (state.state === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.VERIFIED) {
    content = (
      <VerifiedStatusItem state={ state } onClickItem={ handleClickItem } />
    );
  } else if (state.state === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.NOT_AVAILABLE) {
    content = (
      <NotAvailableStatusItem businessId={ businessId } state={ state } />
    );
  } else {
    content = (
      <MissingStatusItem
        businessId={ businessId }
        chartOfAccountId={ chartOfAccountId }
        state={ state }
      />
    );
  }

  return (
    <Table.Cell
      style={ { textAlign: 'center' } }
    >
      { content }
    </Table.Cell>
  );
};

export default BalanceSheetStatementStateItem;
