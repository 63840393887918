import React, { useEffect, useState } from 'react';

import { ICreateChartOfAccountParams, useCreateChartOfAccount } from '@src/requests/simple_chart_of_accounts';

import useProgressModal from './progress_modal';
import useResultModal from './result_modal';

const MultipleCreateCoas: React.FC<{ rows: ICreateChartOfAccountParams[] }> = ({ rows }) => {
  const { mutateAsync } = useCreateChartOfAccount();
  const progressModal = useProgressModal();
  const resultModal = useResultModal();
  const [doneCount, setDoneCount] = useState(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const createCoas = async () => {
    progressModal.open();
    const updatePromises = rows.map((row, index) => mutateAsync(row)
      .catch((error) => {
        setErrorMessages((prevErrors) => [...prevErrors, `Row ${index + 1} - ${rows[index].name} : ${error.message}`]);
      })
      .finally(() => setDoneCount((count) => count + 1)));
    await Promise.all(updatePromises);
    progressModal.props.onDone();
    resultModal.open();
  };

  useEffect(() => {
    createCoas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ rows.length }
        { ...progressModal.props }
      />
      <resultModal.Component
        errors={ errorMessages }
        totalCount={ rows.length }
        { ...resultModal.props }
      />
    </>
  );
};

export default MultipleCreateCoas;
