import { useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getBusinessChartOfAccounts,
  IGetBusinessChartOfAccountsResponse,
  IGetBusinessChartOfAccountsParams,
  IGetBusinessChartOfAccountParams,
  IGetBusinessChartOfAccountResponse,
  getBusinessChartOfAccount,
} from '@src/requests/business_chart_of_accounts';

const useGetBusinessChartOfAccounts = (params: IGetBusinessChartOfAccountsParams) => {
  return useQuery<IGetBusinessChartOfAccountsResponse, Error>(
    [QueryKey.chartOfAccounts, params],
    () => getBusinessChartOfAccounts(params),
  );
};

const useGetBusinessChartOfAccount = (params: Partial<IGetBusinessChartOfAccountParams>) => {
  const queryClient = useQueryClient();

  return useQuery<IGetBusinessChartOfAccountResponse, Error>(
    [QueryKey.chartOfAccount, params],
    () => getBusinessChartOfAccount(params as IGetBusinessChartOfAccountParams),
    {
      enabled:     Boolean(params.chartOfAccountId) && Boolean(params.businessId),
      initialData: () => {
        const accounts = queryClient.getQueryData<IGetBusinessChartOfAccountsResponse>(
          [QueryKey.chartOfAccounts, { businessId: params.businessId }],
        );
        const account = accounts?.collection?.find((acc) => acc.id === params.chartOfAccountId);
        if (!account) return undefined;

        return { chartOfAccount: account };
      },
    },
  );
};

export {
  useGetBusinessChartOfAccounts,
  useGetBusinessChartOfAccount,
};
