import React from 'react';

import BlankDashboardsImg from '@assets/images/dashboards/empty.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const DashboardsEmptyIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Dashboards empty icon"
      src={ BlankDashboardsImg }
      { ...props }
    />
  );
};

export default DashboardsEmptyIcon;
