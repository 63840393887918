import React, { forwardRef } from 'react';

import RangeControls from './range_controls';
import { TRichAmount, TRichAmountType } from './types';
import ValueControl from './value_control';

interface IRichAmountControlProps {
  currentType: TRichAmountType,
  value?: TRichAmount,
  valueProps?: Omit<React.ComponentPropsWithoutRef<typeof ValueControl>, 'value' | 'onChange'>,
  onChange: (value: TRichAmount | undefined) => void,
}

const RichAmountControl = forwardRef(({
  currentType,
  value,
  valueProps,
  onChange,
}: IRichAmountControlProps, ref) => {
  if (currentType === 'range') {
    return (
      <RangeControls
        ref={ ref }
        value={ value }
        onChange={ onChange }
      />
    );
  }

  return (
    <ValueControl
      { ...valueProps }
      ref={ ref }
      value={ value }
      onChange={ onChange }
    />
  );
});

RichAmountControl.displayName = 'RichAmountControl';

export default RichAmountControl;
