module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md add-business-client-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">Add Business Client</h4>\n      </div>\n      <div class="modal-body modern-body">\n        <div class="">\n          <p class="m-t-0 font-16">Which business of yours are you adding this client to?</p>\n          <select class="form-control business-select">\n            ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n            <option value="');
        __out.push(__sanitize(business.id));
        __out.push('"\n              data-content="\n                <div class=\'display-inline-flex p-t-3\'>\n                  <div class=\'small-avatar-wrapper border-gray m-r-10\'>\n                    ');
        if (business.image_url) {
          __out.push('\n                      <img src=\'');
          __out.push(__sanitize(business.image_url));
          __out.push('\'>\n                    ');
        } else {
          __out.push('\n                    <span class=\'icon-business-default-avatar font-22\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                    ');
        }
        __out.push('\n                  </div>\n                  <span class=\'font-14 m-t-2\'>');
        __out.push(__sanitize(business.display_name || business.legal_name));
        __out.push('</span>\n                </div>\n              "\n            ></option>\n            ');
      }
    
      __out.push('\n          </select>\n          <p class=\'main-input__hint in-red-400 business-invalid\'>\n            You have to select business.\n          </p>\n        </div>\n        <div class="m-t-20">\n          <p class="m-t-0 font-16">Enter a display name (visible to client)</p>\n          <input class="form-control business-display-name-input" type="text" placeholder="Business Location Name">\n          <p class=\'main-input__hint in-red-400 name-invalid\'>\n            You have to input valid business name.\n          </p>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue business-save-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}