import { object, string } from 'yup';

import { TAmount, TID } from '@src/types/common';

interface IQBOData {
  id: string,
  name: string,
}

interface IBankAccountData {
  financialInstitutionId: TID,
  financialInstitutionName: string,
  financialInstitutionLogo: string,
  bankAccountId: TID,
  bankAccountName: string,
  bankAccountLast4: string,
}

interface ISetupAccountData {
  cvv?: string,
  last4?: string,
  name?: string,
  plaidPublicToken?: string,
  plaidAccountId?: string,
  routingNumber?: string,
  startBalance?: TAmount,
  startImportDate?: string,
  qbo?: IQBOData,
  bankAccount?: IBankAccountData,
  accountMode?: 'auto' | 'manual'
}

const setupAccountValidation = object({
  name:  string().trim().required('Account Name is required.'),
  last4: string().test((value, validationContext) => {
    const { createError,
      parent: {
        bankAccount,
      } } = validationContext;

    if (bankAccount && bankAccount.bankAccountLast4 === '') return true;
    if (value === '') return createError({ message: 'Last 4 digits is required' });
    if (value?.length !== 4) return createError({ message: 'Account number must 4 digits' });
    if (!/^[0-9]+$/.test(value)) return createError({ message: 'Account number must number' });

    return true;
  }),
  startImportDate: string().required('Starting Date is required.'),
  bankAccount:     object()
    .when(
      'accountMode',
      { is: 'auto', then: (schema) => schema.shape({ bankAccountId: string().required('Bank Account is required.') }) },
    ),
});

export {
  ISetupAccountData,
  setupAccountValidation,
};
