import React, { ComponentPropsWithoutRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';
import range from 'lodash/range';
import moment from 'moment';

import { TDate } from '@src/types/common';
import {
  API_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT,
  endOfLastYearApiDate,
  formatApiDate,
  formatMonth,
  startOfLastYearApiDate,
  startOfMonthApiDate,
  startOfQuarterApiDate,
  startOfYearApiDate,
  todayApiDate,
} from '@src/utils/date_helpers';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown';
import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from '../styles.module.scss';

interface IDateRangeValue {
  startDate?: TDate,
  endDate?: TDate,
}

interface IDateRangeInputProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value?: IDateRangeValue,
  startPlaceholder?: string,
  endPlaceholder?: string,
  onChange?: (value: IDateRangeValue) => void,
}

const initDatePicker = (
  element: HTMLInputElement | null,
  onChange: (val: string | undefined) => void,
) => {
  if (!element) return;

  const datePicker = $(element).datepicker({
    format:         DATEPICKER_DATE_FORMAT,
    ignoreReadonly: true,
    autoclose:      true,
  });
  datePicker.on('changeDate', (e) => {
    onChange(formatApiDate(e.date));
  }).on('clearDate', () => {
    onChange(undefined);
  }).on('hide.bs.modal', (e) => {
    e.stopPropagation();
  });
};

const getDatePickerValue = (element: HTMLInputElement | null): TDate | undefined => {
  if (!element) return undefined;

  const date = $(element).datepicker('getDate');
  if (!date) return undefined;

  return formatApiDate(date);
};

const setDatePickerValue = (element: HTMLInputElement | null, value: TDate | undefined) => {
  if (!element) return;

  const date = value ? moment(value, API_DATE_FORMAT).toDate() : undefined;
  $(element).datepicker('update', date);
};

const DateRangeInput = ({
  value,
  startPlaceholder,
  endPlaceholder,
  onChange,
  ...props
}: IDateRangeInputProps) => {
  const [classes, divProps] = uiStyleProps('input-group', props, styles['date-range-input']);

  const startDateInputRef = useRef<HTMLInputElement>(null);
  const endDateInputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(() => {
    if (!onChange) return;

    const startDate = getDatePickerValue(startDateInputRef.current);
    const endDate = getDatePickerValue(endDateInputRef.current);
    if (value?.startDate === startDate && value?.endDate === endDate) return;

    onChange({ startDate, endDate });
  }, [value?.startDate, value?.endDate, onChange]);

  useLayoutEffect(() => {
    initDatePicker(startDateInputRef.current, handleChange);
    initDatePicker(endDateInputRef.current, handleChange);
  }, [handleChange]);

  useEffect(() => {
    setDatePickerValue(startDateInputRef.current, value?.startDate);
  }, [value?.startDate]);

  useEffect(() => {
    setDatePickerValue(endDateInputRef.current, value?.endDate);
  }, [value?.endDate]);

  const handleAllTimeSelect = useCallback(() => {
    if (onChange) onChange({});
  }, [onChange]);

  const calendarMonth = useMemo(() => {
    const todaysDate = new Date();
    return range(12).map((i) => moment(todaysDate).endOf('month').subtract(i, 'months'));
  }, []);

  const handleCalendarMonthSelect = useCallback((month: number) => {
    if (!onChange) return;

    const selectedMonth = calendarMonth[month];
    onChange({
      startDate: formatApiDate(selectedMonth.startOf('month')),
      endDate:   formatApiDate(selectedMonth.endOf('month')),
    });
  }, [onChange, calendarMonth]);

  const handleYTDSelect = useCallback(() => {
    if (!onChange) return;

    onChange({
      startDate: startOfYearApiDate(),
      endDate:   todayApiDate(),
    });
  }, [onChange]);

  const handleMTDSelect = useCallback(() => {
    if (!onChange) return;

    onChange({
      startDate: startOfMonthApiDate(),
      endDate:   todayApiDate(),
    });
  }, [onChange]);

  const handleQTDSelect = useCallback(() => {
    if (!onChange) return;

    onChange({
      startDate: startOfQuarterApiDate(),
      endDate:   todayApiDate(),
    });
  }, [onChange]);

  const handleLastYearSelect = useCallback(() => {
    if (!onChange) return;

    onChange({
      startDate: startOfLastYearApiDate(),
      endDate:   endOfLastYearApiDate(),
    });
  }, [onChange]);

  const rangeGteFilterClass = classNames(
    'form-control date-picker',
    { filtered: (value?.startDate) },
  );
  const rangeLteFilterClass = classNames(
    'form-control date-picker',
    { filtered: (value?.endDate) },
  );

  return (
    <div className={ classes } { ...divProps }>
      <input
        ref={ startDateInputRef }
        autoComplete="off"
        className={ rangeGteFilterClass }
        placeholder={ startPlaceholder || 'Start Date' }
        type="text"
      />
      <span className="input-group-addon">-</span>
      <input
        ref={ endDateInputRef }
        autoComplete="off"
        className={ rangeLteFilterClass }
        placeholder={ endPlaceholder || 'End Date' }
        type="text"
      />
      <Dropdown.LinkToggle noButton pointer className="date-select-toggle range-select-menu-btn">
        <CaretIcon fontSize={ 14 } variant="down" />
      </Dropdown.LinkToggle>
      <Dropdown.Menu pointer alignment="left" className="col-xs-12" fontSize={ 24 }>
        <Dropdown.Item onSelect={ handleAllTimeSelect }>All Time</Dropdown.Item>
        <Dropdown.Item divider className="divider" />
        <Dropdown.Submenu title="Calendar Month">
          <Dropdown.Menu className="col-xs-12">
            {
              calendarMonth.map((month) => (
                <Dropdown.Item
                  key={ calendarMonth.indexOf(month) }
                  onSelect={ () => handleCalendarMonthSelect(calendarMonth.indexOf(month)) }
                >
                  { formatMonth(month) }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown.Submenu>
        <Dropdown.Item onSelect={ handleYTDSelect }>Year to Date (YTD)</Dropdown.Item>
        <Dropdown.Item onSelect={ handleMTDSelect }>Month to Date (MTD)</Dropdown.Item>
        <Dropdown.Item onSelect={ handleQTDSelect }>Quarter to Date (QTD)</Dropdown.Item>
        <Dropdown.Item onSelect={ handleLastYearSelect }>Last Year</Dropdown.Item>
      </Dropdown.Menu>
    </div>
  );
};

export {
  IDateRangeValue,
  DateRangeInput as default,
};
