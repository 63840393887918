import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IQboConnectStatusIconProps extends IIcomoonIconProps {
  variant?: 'connected' | 'disconnected',
}

const QboConnectStatusIcon = ({
  variant,
  ...props
}: IQboConnectStatusIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ `qbo-${variant}-icon` }
      pathsNumber={ 2 }
    />
  );
};

export default QboConnectStatusIcon;
