import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetSearchResults } from '@src/hooks/queries/search/search';
import { useInfiniteScroll } from '@src/hooks/scroll';
import { IGlobalSearchParams } from '@src/types/global_search/global_search_requests';

import { Button } from '@src/components/ui_v2/buttons';
import SolidLoader from '@src/components/utils/img_icons/loader/solid_loader';
import LocalQueryStatus from '@src/components/utils/local_query_status';

import MessageSearchResults from './message_search_results';
import SearchResultItem from './results_item';

import styles from './styles.module.scss';

interface ISearchResultsProps {
  requestBody: IGlobalSearchParams;
  isFullScreen: boolean;
  onShowFullScreen: () => void;
}

const SearchResults = ({
  isFullScreen,
  onShowFullScreen,
  requestBody,
}: ISearchResultsProps) => {
  const noFilterApplied = useMemo(() => {
    return Object.keys(requestBody).length < 3;
  }, [requestBody]);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const query = useGetSearchResults(requestBody, {
    enabled: !(Object.keys(requestBody).length < 3),
  });

  const searchResults = useMemo(() => {
    const searchResultsPages = query.data?.pages || [];
    return flatten(searchResultsPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const foundCount = query.data?.pages[0].meta.totalCount || 0;

  useInfiniteScroll({
    elementRef: wrapperRef,
    query,
  });

  if (query.isLoading) {
    return (
      <div className={ styles['search-list-container'] }>
        <div className={ styles['global-search-result-loader'] }>
          <SolidLoader />
        </div>
      </div>
    );
  }

  if (noFilterApplied) {
    return (
      <div className={ styles['search-list-container'] }>
        <MessageSearchResults
          isStartSearch
          description="Search by vendor, account, amount, description or date"
          title="Start Searching"
        />
      </div>
    );
  }

  if (!searchResults?.length) {
    return (
      <div className={ styles['search-list-container'] }>
        <LocalQueryStatus noSpinner query={ query } />
        <MessageSearchResults
          description="Please Refine Your Search or Adjust Filters for Better Matches"
          title="No Results Found"
        />
      </div>
    );
  }

  return (
    <div className={ styles['search-list-container'] }>
      <LocalQueryStatus noSpinner query={ query } />
      <div className={ styles['list-title'] }>
        showing
        {' '}
        {searchResults?.length}
        {' '}
        out of
        {' '}
        {foundCount}
        {' '}
        results found
      </div>
      <div className={ styles['message-list'] }>
        <div ref={ isFullScreen ? wrapperRef : null }>
          {searchResults.map((item) => (
            <SearchResultItem { ...item } key={ item.docytId } />
          ))}
        </div>
        {query.isFetching && (
          <div className={ styles['global-search-result-loader'] }>
            <SolidLoader />
          </div>
        )}
      </div>
      {searchResults?.length < foundCount && (
        <div className={ styles['bottom-btn'] }>
          <Button
            className={ styles['btn-with-image'] }
            size="compact"
            suffixIcon={ <i className="icon icon-more-info" /> }
            variant="link"
            onClick={ onShowFullScreen }
          >
            View All
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(SearchResults);
