import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAddReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { IAddReportServiceBudgetResponse } from '@src/requests/report_service/report_service_budgets';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IEditBudgetFormValues } from '../form/edit_budget';

const FORM_ID = 'add_budget_form';

interface IAddBudgetModalProps extends IUseModalProps {
  businessId: TID,
  reportServiceId: TID,
  standardCategoryId: number,
}

const AddBudgetModal = ({
  businessId,
  reportServiceId,
  standardCategoryId,
  isOpen,
  onCancel,
}: IAddBudgetModalProps) => {
  const add = useAddReportServiceBudget();

  const { mutate } = add;
  const handleSubmit = useCallback((values: IEditBudgetFormValues) => {
    mutate(
      {
        reportServiceId,
        name: values.name,
        year: values.year,
        standardCategoryId,
        type: values.type,
      },
      {
        onSuccess: (budgetResponse: IAddReportServiceBudgetResponse) => {
          Backbone.history.navigate(`/businesses/${businessId}/reports/budgets/${budgetResponse.budget.id}`, { trigger: true });
        },
      },
    );
  }, [reportServiceId, businessId, mutate]);

  return (
    <>
      <MutationStatus mutation={ add } />
      <Modal
        className="modal-position-middle"
        show={ isOpen }
        title="Add Budget/Forecast"
      >
        <Modal.Body>
          {
            isOpen && (
              <Form
                formId={ FORM_ID }
                onSubmit={ handleSubmit }
              />
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-link modal-link-btn"
            fontSize={ 16 }
            title="Cancel"
            onClick={ onCancel }
          />
          <Button
            bsColor="blue"
            fontSize={ 16 }
            fontVariant="semi-bold"
            form={ FORM_ID }
            title="Continue"
            type="submit"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useAddBudgetModal = makeUseModal(AddBudgetModal);

export {
  IAddBudgetModalProps,
  useAddBudgetModal,
  AddBudgetModal as default,
};
