import React, { useCallback } from 'react';

import { useMarkDocumentsAsRead } from '@src/hooks/queries/documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { EnvelopeIcon } from '@src/components/utils/fa_icons';

interface IBusinessInboxItemMarkAsReadActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemMarkAsReadAction = ({
  document,
}: IBusinessInboxItemMarkAsReadActionProps) => {
  const isViewed = Boolean(document.viewedAt);

  const markAsRead = useMarkDocumentsAsRead();

  const { mutate } = markAsRead;
  const handleSelect = useCallback(() => {
    mutate({ value: !isViewed, documentIds: [document.id] });
  }, [isViewed, document.id, mutate]);

  return (
    <ActionsDropdown.Action
      icon={ <EnvelopeIcon fontSize={ 14 } /> }
      name={ isViewed ? 'Mark as Unread' : 'Mark as Read' }
      onClick={ handleSelect }
    />
  );
};

export default BusinessInboxItemMarkAsReadAction;
