import React, { forwardRef, useCallback } from 'react';

import { TDate, TYear } from '@src/types/common';
import {
  apiDateToYear,
  apiYearToDate,
  YEAR_PICKER_DATE_FORMAT,
} from '@src/utils/date_helpers';

import DatePickerInput, { IDatePickerInputProps, IDatePickerInputRef } from './datepicker_input';

interface IYearPickerInputProps extends Omit<IDatePickerInputProps, 'value' | 'onChange'> {
  value: TYear | undefined,
  onChange?: (value: TYear | undefined) => void;
}

const YearPickerInput = forwardRef<IDatePickerInputRef, IYearPickerInputProps>((
  {
    options,
    value,
    onChange,
    ...props
  }: IYearPickerInputProps,
  ref,
) => {
  const handleChange = useCallback((newValue: TDate | undefined) => {
    if (!onChange) return;

    onChange(apiDateToYear(newValue));
  }, [onChange]);

  return (
    <DatePickerInput
      ref={ ref }
      { ...props }
      options={ {
        format:      YEAR_PICKER_DATE_FORMAT,
        minViewMode: 'years',
        ...options,
      } }
      value={ apiYearToDate(value) }
      onChange={ handleChange }
    />
  );
});

YearPickerInput.displayName = 'YearPickerInput';

export {
  IYearPickerInputProps,
  YearPickerInput as default,
};
