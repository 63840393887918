import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IManagementGroup } from '@src/types/management_groups';

import Table from '@src/components/ui/table';

import ManagementGroupsTableItem from './management_groups_table_item';

import styles from './styles.module.scss';

interface IManagementGroupsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  managementGroups: IManagementGroup[],
}

const ManagementGroupsTable = ({
  query,
  managementGroups,
}: IManagementGroupsTableProps) => {
  return (
    <Table
      className={ styles['management-groups-table'] }
      infiniteQuery={ query }
      wrapperClassName="management-groups-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell
            widthPercent={ 45 }
          >
            Management Group
          </Table.HCell>
          <Table.HCell
            widthPercent={ 50 }
          >
            Businesses
          </Table.HCell>
          <Table.HCell
            widthPercent={ 5 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          managementGroups.map((managementGroup) => (
            <ManagementGroupsTableItem
              key={ managementGroup.id }
              managementGroup={ managementGroup }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(ManagementGroupsTable);
