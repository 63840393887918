/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { currencyFormater3 } from '@src/utils/currency';

import EmployeeCardActionMenu from '@src/components/banking_accounts/accounts/employee_cards/action/action_menu';
import CardIcon from '@src/components/banking_accounts/accounts/employee_cards/card_tile/card_icon';

interface CardTileProps {
  employeeCardItem: IPaymentAccount,
  currentSelectedEmployeeCard: IPaymentAccount | undefined,
  reconciliationPaymentAccount?: IReconciliationPaymentAccount,
  isPrimary: boolean,
  handleCardClick(employeeCardItem: IPaymentAccount): void,
  refetchEmployeeCard(): void
}

const CardTile = ({
  currentSelectedEmployeeCard,
  employeeCardItem,
  isPrimary,
  reconciliationPaymentAccount,
  handleCardClick,
  refetchEmployeeCard,
}: CardTileProps): JSX.Element => {
  // This variable is declared to handle the static disconnection scenario for this component.
  // Once it is handled by the API, it will become dynamic.
  const isBeingDisconnected: boolean = false;

  return (
    <div className="employee-card-item-wrapper">
      <div className="employee-card-item-action-wrapper">
        <EmployeeCardActionMenu
          employeeCardItem={ employeeCardItem }
          refetchEmployeeCard={ refetchEmployeeCard }
        />
      </div>
      <div onClick={ () => handleCardClick(employeeCardItem) }>
        <div className={ `employee-card-item ${isPrimary ? 'employee-card-primary' : ''} ${(currentSelectedEmployeeCard?.id === employeeCardItem.id) ? 'employee-card-selected' : ''} ` }>
          {isPrimary && (
            <div className="employee-card-primary-badge">
              Primary
            </div>
          )}

          <div className="payment-account-icon-wrapper">
            <CardIcon accountType={ employeeCardItem.accountType } isArchived={ employeeCardItem.isArchived } />
          </div>
          <div className="account-name-container ">
            <h2 className="employee-card-name" title={ employeeCardItem.name }>{employeeCardItem.name}</h2>
          </div>
          <p className="payment-account-no">
            **** **** ****
            {' '}
            {employeeCardItem.last4}
          </p>
          {employeeCardItem.isArchived && (
            <span className="archived-date">
              Archived on
              {employeeCardItem.archivedAt}
            </span>
          )}
          {(isBeingDisconnected) ? (
            <p className="text-center text-danger font-12">Disconnection in progress</p>
          ) : (
            !employeeCardItem.isArchived && (
              <>
                <h4 className="payment-account-total-amount">{reconciliationPaymentAccount?.totalAmount ? currencyFormater3(reconciliationPaymentAccount?.totalAmount) : '$0.00'}</h4>
                <p className="payment-account-transaction-count"> Transactions</p>
              </>
            )
          )}

        </div>
      </div>
    </div>

  );
};

export default CardTile;
