import { QueryKey } from '@src/constants/query_keys';
import { IReportRefreshLogsParams, getReportRefreshLogsByReportId } from '@src/requests/report_service/report_refresh_logs';
import { IReportRefreshLogData } from '@src/types/report_service/report_refresh_logs';

import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

interface IReportRefreshLogResponse {
  meta: {
    totalCount: number;
  };
  collection: IReportRefreshLogData[];
}

const useGetReportRefreshLogs = createUseGetInfiniteCollection<
  any,
  IReportRefreshLogsParams,
  IReportRefreshLogResponse
>({
  queryKey: QueryKey.reportRefreshLogs,
  request:  getReportRefreshLogsByReportId,
});

export { useGetReportRefreshLogs };
