import React, { useCallback, useMemo } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useMergeToGlobalVendor, useNoMergeToGlobalVendor } from '@src/hooks/queries/vendors';
import { IBusinessVendor, TVendorMergeType } from '@src/types/business_vendors';

import MutationStatus from '@src/components/utils/mutation_status';

import Wizard from './merge_vendor_wizard';

type TOpenValue = {
  mergeableVendor?: IBusinessVendor,
}

interface IMergeVendorProps extends IUseModalProps<TOpenValue> {
  businessId: number,
  vendor: IBusinessVendor,
  onNoMergedVendor?: () => void
  onMergedVendor?: () => void
}

const MergeVendor = ({
  businessId,
  vendor,
  isOpen,
  openValue,
  onCancel,
  onNoMergedVendor,
  onMergedVendor,
  onDone,
}: IMergeVendorProps): JSX.Element | null => {
  const { mergeableVendor } = openValue || {};

  const mergeableId = useMemo(() => {
    return vendor.mergeableId || mergeableVendor?.id;
  }, [vendor, mergeableVendor]);

  const mergeToGlobalVendor = useMergeToGlobalVendor();
  const noMergeToGlobalVendor = useNoMergeToGlobalVendor();

  const { mutate: mergeToVendor } = mergeToGlobalVendor;
  const { mutate: noMergeToVendor } = noMergeToGlobalVendor;
  const handleFinish = useCallback((mergeType: TVendorMergeType) => {
    if (mergeType === 'enable_merge') {
      if (!mergeableId) return;

      mergeToVendor(
        {
          businessId,
          vendorId:          vendor.id,
          mergeableVendorId: mergeableId,
        },
        {
          onSuccess: () => {
            if (onMergedVendor) onMergedVendor();
            onDone();
          },
          onError: () => onCancel(),
        },
      );
    } else {
      noMergeToVendor(
        {
          businessId,
          vendorId: vendor.id,
        },
        {
          onSuccess: () => {
            if (onNoMergedVendor) onNoMergedVendor();
            onDone();
          },
          onError: () => onCancel(),
        },
      );
    }
  }, [mergeableId, mergeToVendor, businessId, vendor.id,
    onMergedVendor, onDone, noMergeToVendor, onNoMergedVendor, onCancel]);

  if (vendor.isMerging) return null;

  return (
    <>
      <MutationStatus mutation={ mergeToGlobalVendor } successMessage="Payee has been successfully merged." />
      <MutationStatus mutation={ noMergeToGlobalVendor } successMessage="Flag for merge has been cleared." />
      {
        mergeableId && (
          <Wizard
            businessId={ businessId }
            mergeableId={ mergeableId }
            show={ isOpen }
            vendor={ vendor }
            onCancel={ onCancel }
            onFinish={ handleFinish }
          />
        )
      }
    </>
  );
};

const MemoizedMergeVendorModal = React.memo(MergeVendor);
const useMergeVendorModal = makeUseModal<
  typeof MemoizedMergeVendorModal, TOpenValue>(MemoizedMergeVendorModal);

export {
  IMergeVendorProps,
  useMergeVendorModal,
  MergeVendor as default,
};
