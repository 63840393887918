import React from 'react';

import map from 'lodash/map';

import { TUUID } from '@src/types/common';

import Table from '@src/components/ui_v2/table';

import Items from './related_deposits_items';

interface IUnreconcileRelatedDepositListProps {
  revenueReconciliationIds: TUUID[],
  paymentProcessorName: string,
}

const UnreconcileRelatedDepositList = ({
  revenueReconciliationIds,
  paymentProcessorName,
}: IUnreconcileRelatedDepositListProps): JSX.Element => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 30 }>
            Date
          </Table.HCell>
          <Table.HCell widthPercent={ 40 }>
            Payment Processor
          </Table.HCell>
          <Table.AmountHCell widthPercent={ 30 }>
            Amount
          </Table.AmountHCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          map(revenueReconciliationIds, (id) => (
            <Items
              key={ id }
              paymentProcessorName={ paymentProcessorName }
              revenueReconciliationId={ id }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(UnreconcileRelatedDepositList);
