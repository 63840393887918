import React, { useCallback } from 'react';

import versionJSON from '@config/version.json';
import { useAppVersion } from '@src/hooks/queries/app_version';

import Banner from './banner';

const internalVersion = versionJSON.version;
const CHECK_INTERVAL = 300; // 5 minutes

const VersionChecker = (): JSX.Element | null => {
  const query = useAppVersion({ refetchInterval: CHECK_INTERVAL * 1000 });

  const handleClick = useCallback(() => {
    window.location.reload();
  }, []);

  if (!query.data) return null; // If no version fetched we do nothing
  if (query.data === internalVersion) return null; // Versions are same, do nothing.

  return <Banner onRefreshClick={ handleClick } />;
};

export default VersionChecker;
