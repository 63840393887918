module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var column, columnIndex, i, index, j, len, len1, ref, ref1, report_data;
    
      __out.push('<div class=\'head-column\'><span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span></div>\n<div class="main-box height-100-percent">\n  ');
    
      ref = this.report_datas.models;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        report_data = ref[index];
        __out.push('\n    <div class="multiple-monthly-report-box column-border-right">\n      ');
        ref1 = this.filteredColumns;
        for (columnIndex = j = 0, len1 = ref1.length; j < len1; columnIndex = ++j) {
          column = ref1[columnIndex];
          __out.push('\n        ');
          if (columnIndex === 0 && this.hasLink(report_data, index - 1)) {
            __out.push('\n          <a class=\'report-line-item general-column pointer\' id=\'');
            __out.push(__sanitize(index));
            __out.push('\'>\n            ');
            __out.push(__sanitize(this.businessColumnValue(report_data, column, index - 1)));
            __out.push('\n          </a>\n        ');
          } else {
            __out.push('\n          <div class="general-column">');
            __out.push(__sanitize(this.businessColumnValue(report_data, column, index - 1)));
            __out.push('</div>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </div>  \n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}