import React from 'react';

import { IStandardDocument, IStandardFolderWithDocuments } from '@src/types/standard_documents';

interface ISelectDtandardDocumentInputFolderProps {
  children: (document: IStandardDocument) => JSX.Element,
  folder: IStandardFolderWithDocuments,
}

const SelectDtandardDocumentInputFolder = ({
  children,
  folder,
}: ISelectDtandardDocumentInputFolderProps) => {
  return (
    <div key={ folder.id } className="standard-folder-standard-documents-wrapper">
      <p className="bg-grey-150 font-13 font-bold m-b-0 p-b-5 p-l-10 p-t-5">
        { folder.name }
      </p>
      { folder.documents.map(children) }
    </div>
  );
};

export default SelectDtandardDocumentInputFolder;
