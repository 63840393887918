import React from 'react';

import styles from './styles.module.scss';

interface IAddConditionProp {
    title: string | null;
    selectedCondition: string;
    handleAddCondition: (condition: string) => void;
    conditions: string[];
}

const Conditions = ({
  title,
  selectedCondition,
  handleAddCondition,
  conditions,
}: IAddConditionProp) => {
  return (
    <div className="approvers-dropdown-wrapper relative editing">
      <button
        aria-expanded="false"
        aria-haspopup="true"
        className={ `pointer ${styles['compliance-menu-toggle-btn']}` }
        data-container="body"
        data-toggle="dropdown"
        id="rule-"
        type="button"
      >
        {title || selectedCondition}
      </button>
      <div
        aria-labelledby="rule-"
        className="dropdown-menu dropdown-menu-right dropdown-menu-round-border"
      >
        {conditions.map((condition) => (
          <button
            key={ condition }
            className="dropdown-item font-14 any-approvers-operator-js pointer"
            type="button"
            onClick={ () => handleAddCondition(condition) }
          >
            <span className="font-14">{condition}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Conditions;
