import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TOption } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

const StatusOptions: TOption[] = [
  {
    value: 'processed',
    label: 'Processed',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
  {
    value: 'failed',
    label: 'Failed',
  },
];

const AchTransactionsFilter = () => {
  return (
    <Filter.TableContainer className={ styles['table-filter'] }>
      <Filter.TextField
        label="Payee"
        name="receiverName"
        placeholder="Search payee"
      />
      <Filter.DateField
        name="dwollaCreatedAt"
      />
      <Filter.SelectField
        label="Status"
        name="status"
        options={ StatusOptions }
        placeholder="Status"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(AchTransactionsFilter);
