import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { SubmitHandler, useForm, useController } from 'react-hook-form';

import { TID } from '@src/types/common';

import PaymentAccountsField from '@src/components/common_v2/form_fields/payment_accounts_field';
import Form from '@src/components/ui/form';

import { paymentMode } from '../../../utils/review_payment_utils';
import { IMarkAsPaidFormValues, PaybyBankAccountValidation } from '../schema';

import styles from '../../styles.module.scss';

interface IPayByBankAccountFormProps {
  formId?: string,
  businessId: TID,
  type: string,
  onSubmit: SubmitHandler<IMarkAsPaidFormValues>,
}

const PayByBankAccountForm = ({
  formId,
  businessId,
  type,
  onSubmit,
}: IPayByBankAccountFormProps): JSX.Element => {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<IMarkAsPaidFormValues>({
    resolver: yupResolver(PaybyBankAccountValidation),
  });

  const estimatedDateController = useController({ name: 'estimatedDate', control });
  const paymentAccountController = useController({ name: 'paymentAccountId', control });

  return (
    <Form className={ styles['buld-mark-as-paid-form'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.DateField
        error={ errors.estimatedDate?.message }
        label="Estimated Date of Payment"
        { ...estimatedDateController.field }
      />
      <PaymentAccountsField
        accountType={ paymentMode(type) }
        businessId={ businessId }
        error={ errors.paymentAccountId?.message }
        label="Payment Account"
        labelClass="width-38-percent"
        { ...omit(paymentAccountController.field, 'ref') }
      />
    </Form>
  );
};

export {
  IPayByBankAccountFormProps,
  PayByBankAccountForm as default,
};
