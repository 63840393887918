import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

const createEmailVerificaiton = (): Promise<void> => {
  return apiPost(
    '/api/v1/docyt_check_email_verifications',
    underscoreKeys({}),
  );
};

const resendEmailVerificaiton = (): Promise<void> => {
  return apiPost(
    '/api/v1/docyt_check_email_verifications/resend_email',
    underscoreKeys({}),
  );
};

interface IConfirmEmailVerificationParams {
  accessCode: string,
}

const confirmEmailVerification = (
  params: IConfirmEmailVerificationParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/docyt_check_email_verifications/confirm_email',
    underscoreKeys(params),
  );
};

export {
  IConfirmEmailVerificationParams,
  createEmailVerificaiton,
  resendEmailVerificaiton,
  confirmEmailVerification,
};
