module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class ="header-wrapper">\n  <span class="font-18 font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</span>\n  <a class="close-view m-l-auto in-grey-900">\n    <span class="pointer icon-not-a-chargeback-icon font-25"></span>\n  </a>\n</div>\n<div class ="content-wrapper">\n  <div class="inline-flex-wrapper width-100-percent">\n    <label class="inline-wrapper p-t-3 width-140px" for="">Category Name</label>\n    <input type="text" class="inline-wrapper form-control name-js" placeholder="Category Name" value="');
    
      __out.push(__sanitize(this.expenseCategory.get('name')));
    
      __out.push('">\n  </div>\n  <p class="in-red-400 m-t-5 main-input__hint p-l-145 name-invalid-js"><i class="fa fa-warning"></i> Name is required.</p>\n  <div class="font-semi-bold m-t-25">Map category from QuickBooks</div>\n  ');
    
      if (this.accountingClasses.length > 0) {
        __out.push('\n  <div class="inline-flex-wrapper width-100-percent sub-type-select-wrapper m-t-15">\n    <label class="inline-wrapper p-t-3 width-140px" for="">Department</label>\n    <input type="text" class="inline-wrapper form-control class-input-js" placeholder="Select Department" value="');
        if (this.expenseCategory.get('accounting_class')) {
          __out.push(__sanitize(this.expenseCategory.get('accounting_class').name));
        }
        __out.push('" ');
        if (this.expenseCategory.id) {
          __out.push(__sanitize('readonly'));
        }
        __out.push('>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="inline-flex-wrapper width-100-percent sub-type-select-wrapper m-t-15">\n    <label class="inline-wrapper p-t-3 width-140px" for="">Category</label>\n    <input type="text" class="inline-wrapper form-control chart-of-account-input-js" placeholder="Select QuickBooks Category" ');
    
      if (this.expenseCategory.id) {
        __out.push(__sanitize('readonly'));
      }
    
      __out.push('>\n  </div>\n  <p class="in-red-400 m-t-5 main-input__hint p-l-145 category-invalid-js"><i class="fa fa-warning"></i> QuickBooks category is required.</p>\n</div>\n<div class="footer-wrapper">\n  <button type=\'button\' class="btn btn-blue ladda-button add-js width-100-percent" tabindex="10" data-color="blue" data-style="expand-right">\n    <span class="ladda-label font-semi-bold">Save</span>\n  </button>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}