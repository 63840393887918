import React from 'react';

import { ReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetReportServiceByBusinessId } from '@src/hooks/queries/report_service/report_services';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IReportsServiceProviderProps {
  businessId: number,
  children: React.ReactNode,
  isGlobalNavigationEnabled?: boolean
}

const ReportsServiceProvider = ({
  businessId,
  isGlobalNavigationEnabled,
  children,
}: IReportsServiceProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetReportServiceByBusinessId(businessId);

  if (isLoading) {
    return isGlobalNavigationEnabled ? null : <Spinner />;
  };
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Reports Service loaded" />;

  return (
    <ReportServiceContext.Provider value={ data }>
      { children }
    </ReportServiceContext.Provider>
  );
};

export default ReportsServiceProvider;
