import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ClientCentralIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="client-central"
    />
  );
};

export default ClientCentralIcon;
