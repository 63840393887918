import { useEffect, useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetCompliancePolicies } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { useGetBusinessVendorsCollection } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import {
  IVendor,
  TIBusinessVendorsSortColumn,
} from '@src/types/business_vendors';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useClearFilter } from '@src/components/ui_v2/filter';
import {
  useInitItemsSelector,
  useItemsSelector,
} from '@src/components/utils_v2/items_selector';

interface IUseCollectionParams {
    businessId: TID;
    search: string;
    selectedRecordsId?: TID;
}

interface ICollection {
    query: UseInfiniteQueryResult<ISearchBusinessVendorResponse, Error>;
    records: IVendor[];
    section: TSection;
    sorting: ISorting<TIBusinessVendorsSortColumn>;
    selectedRecords: IVendor[];
}

interface ISearchBusinessVendorResponse {
    vendors: IVendor[];
}

const defaultSorting: ISortingParams<TIBusinessVendorsSortColumn> = {
  orderColumn:    'id',
  orderDirection: 'desc',
};

const useGetBusinessVendorCollection = ({
  businessId,
  search,
  selectedRecordsId,
}: IUseCollectionParams): ICollection => {
  const section = useMemo(() => {
    return { businessId, search, section: 'business_vendors' };
  }, [businessId, search]);

  const clearFilter = useClearFilter(section);
  useEffect(() => clearFilter(), [clearFilter]);

  const sorting = useSorting<TIBusinessVendorsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetBusinessVendorsCollection({
    businessId,
    search,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const ids = useMemo(() => {
    return records.map((object) => object.id);
  }, [records]);

  const initSelectedIds = useMemo(() => {
    return records
      .filter((r) => selectedRecordsId === r.id)
      .map((object) => object.id);
  }, [records, selectedRecordsId]);

  useInitItemsSelector(ids, section, initSelectedIds);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
  };
};

const useGetCompliancePolicy = (businessId: TID, policyType : string) => {
  const query = useGetCompliancePolicies({ businessId, policyType });
  return query.data?.policy;
};

export {
  ISearchBusinessVendorResponse,
  useGetCompliancePolicy,
  useGetBusinessVendorCollection,
  ICollection,
};
