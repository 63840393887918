import React from 'react';

import { Location } from 'react-router-dom';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import RevenueServiceProvider from '@src/components/providers/revenue_service_provider';
import Root from '@src/components/root';

import RevenueCenterRouter from './router';

interface IRevenueCenterPageProps {
  businessId: TID,
  location?: Location,
}

const RevenueCenterPage = ({
  businessId,
  location,
}: IRevenueCenterPageProps): JSX.Element => {
  return (
    <Root location={ location }>
      <BusinessProvider businessId={ businessId }>
        <RevenueServiceProvider businessId={ businessId }>
          <RevenueCenterRouter />
        </RevenueServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default RevenueCenterPage;
