module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var column, i, index, itemValue, j, k, len, len1, len2, ref, ref1, ref2;
    
      __out.push('<div class=\'item-box\'>\n  ');
    
      if (this.total) {
        __out.push('\n    <div class=\'head-column p-l-18 font-bold\'><span>Total</span></div>\n  ');
      } else {
        __out.push('\n    <div class=\'head-column p-l-18\'><span>');
        __out.push(__sanitize(this.name));
        __out.push('</span></div>\n  ');
      }
    
      __out.push('\n  <div class="main-box height-100-percent">\n    ');
    
      if (this.showMultipleReportDatas) {
        __out.push('\n      <div class="multiple-monthly-report-box">\n        ');
        ref = this.itemValues.models;
        for (index = i = 0, len = ref.length; i < len; index = ++i) {
          itemValue = ref[index];
          __out.push('\n          ');
          if (this.total) {
            __out.push('\n            <span class=\'general-column font-bold\'>');
            __out.push(__sanitize(this.monthlyItemValue(itemValue)));
            __out.push('</span>\n          ');
          } else if (this.hasMonthlyColumnValue(itemValue)) {
            __out.push('\n            ');
            if (this.hasLink(null, itemValue)) {
              __out.push('\n              <a href=\'');
              __out.push(__sanitize(this.hrefMonthlyColumnValue(itemValue)));
              __out.push('\' class=\'general-column\'>');
              __out.push(__sanitize(this.monthlyColumnValue(itemValue)));
              __out.push('</a>\n            ');
            } else {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.monthlyColumnValue(itemValue)));
              __out.push('</span>\n            ');
            }
            __out.push('\n          ');
          } else {
            __out.push('\n            <a class=\'general-column\'>-</a>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="monthly-report-box">\n        ');
        ref1 = this.ptdColumns.models;
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          column = ref1[j];
          __out.push('\n          ');
          if (this.total) {
            __out.push('\n            <span class=\'general-column font-bold\'>');
            __out.push(__sanitize(this.columnItemValue(column)));
            __out.push('</span>\n          ');
          } else if (this.hasValue(column)) {
            __out.push('\n            ');
            if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.VARIANCE) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL_PER_METRIC) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.PERCENTAGE) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type').includes('budget')) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type').includes('forecast')) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (this.hasLink(column)) {
              __out.push('\n              <a href=\'');
              __out.push(__sanitize(this.hrefLink(column)));
              __out.push('\' class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</a>\n            ');
            } else {
              __out.push('              \n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            }
            __out.push('\n          ');
          } else {
            __out.push('\n            <a class=\'general-column\'>-</a>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n      <div class="yearly-report-box ');
        if (this.invisibleYtdColumns) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('">\n        ');
        ref2 = this.ytdColumns.models;
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          column = ref2[k];
          __out.push('\n          ');
          if (this.total) {
            __out.push('\n            <span class=\'general-column font-bold\'>');
            __out.push(__sanitize(this.columnItemValue(column)));
            __out.push('</span>\n          ');
          } else if (this.hasValue(column)) {
            __out.push('\n            ');
            if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.VARIANCE) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL_PER_METRIC) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type') === Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.PERCENTAGE) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type').includes('budget')) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (column.get('type').includes('forecast')) {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            } else if (this.hasLink(column)) {
              __out.push('\n              <a href=\'');
              __out.push(__sanitize(this.hrefLink(column)));
              __out.push('\' class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</a>\n            ');
            } else {
              __out.push('\n              <span class=\'general-column\'>');
              __out.push(__sanitize(this.columnValue(column)));
              __out.push('</span>\n            ');
            }
            __out.push('\n          ');
          } else {
            __out.push('\n            <a class=\'general-column\'>-</a>\n          ');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}