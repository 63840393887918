import React, { createContext, useContext } from 'react';

import { TransactionContextData } from '@src/types/bank_account_reconciliations';

type TCDContextValue = {
  data: TransactionContextData;
  setData: React.Dispatch<React.SetStateAction<TransactionContextData>>;
};

const TCDContext = createContext<TCDContextValue | null>(null);

const useTCDContext = () => <TCDContextValue>useContext(TCDContext);

export {
  TCDContext,
  useTCDContext,
};
