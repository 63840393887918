import { createContext, useContext } from 'react';

import { IInboxService } from '@src/types/services';

const InboxServiceContext = createContext<IInboxService | null>(null);

// force convert to `IInboxService` type without null because childs of provider are
// rendered only then service present.
const useInboxServiceContext = () => <IInboxService>useContext(InboxServiceContext);

export {
  InboxServiceContext,
  useInboxServiceContext,
};
