import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

interface IProgressModalProps extends IUseModalProps {
  doneCount: number;
  totalCount: number;
  editModal?: boolean;
}

const ProgressModal = ({
  isOpen,
  doneCount,
  totalCount,
  editModal,
}: IProgressModalProps): JSX.Element => {
  const percent = Math.floor((doneCount * 100) / totalCount);
  return (
    <Modal
      show={ isOpen }
      title={ editModal ? 'Edit Chart of Account' : 'Add Chart of Account' }
    >
      <Modal.Body>
        <div>
          <p className="p-b-0 text-center m-b-20">
            { editModal ? 'Editing' : 'Add'}
            {' '}
            Chart of Account for
            { ' ' }
            <b>
              { doneCount }
              /
              { totalCount }
            </b>
            { ' ' }
            business(es)
          </p>
          <div className="progress">
            <div className="progress-bar" style={ { width: `${percent}%` } } />
          </div>
          <p className="p-b-0 text-center">
            Do not close this window or click the Back button on your browser.
            It will stop the process.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const useProgressModal = makeUseModal(ProgressModal);

export default useProgressModal;
