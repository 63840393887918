module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, state;
    
      __out.push('<div class="invoices-filter-container">\n    <div class="m-l-10 pull-left relative">\n      <div class="checkbox pointer checkbox-primary">\n        <input class="check-all-js pointer" type="checkbox">\n        <label class="pointer"></label>\n      </div>\n    </div>\n    <div class="form-group filter-group invoice-filter-status">\n      <div class="permission-dropdown-wrapper">\n        <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n          <span class="flag-label-js"></span>\n          <span class="fa fa-caret-down"></span>\n        </div>\n        <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n          <div class="bank-account-list">\n          <li class="flag-all-js" data-value=\'all\'>\n            <div class=\'display-flex align-items-center\'>\n              <i class=\'\'></i>\n              <span class=\'text m-t-3 m-l-28\'>All</span>\n            </div>\n          </li>\n          <li class="flag-off-js" data-value=\'unflagged\'>\n            <div class=\'display-flex align-items-center\'>\n              <i class=\'font-18 fa fa-star-o in-dark-100 flag-selected\'></i>\n              <span class=\'text m-t-3 m-l-10\'>Unstarred</span>\n            </div>\n          </li>\n          <li class="flag-on-js" data-value=\'flagged\'>\n            <div class=\'display-flex align-items-center\'>\n              <i class=\'font-18 fa fa-star in-vivid-orange\'></i>\n              <span class=\'text m-t-3 m-l-10\'>Starred</span>\n            </div>\n          </li>\n          </div>\n        </ul>\n      </div>\n    </div>\n\n    <div class="filter-group invoice-filter-payee">\n      <div class="vendors-region"></div>\n    </div>\n\n    ');
    
      if (this.type === 'queued') {
        __out.push('\n    <div class="form-group filter-group invoice-filter-status">\n        <select class="form-control filter-status-select">\n            ');
        ref = Docyt.Common.Constants.INVOICE_FILTER_AVAILABLE_STATES;
        for (i = 0, len = ref.length; i < len; i++) {
          state = ref[i];
          __out.push('\n            <option value="');
          __out.push(__sanitize(state['key']));
          __out.push('" ');
          if (this.filter.status && this.filter.status === state['key']) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(state['label']));
          __out.push('</option>\n            ');
        }
        __out.push('\n        </select>\n    </div>\n    ');
      }
    
      __out.push('\n\n    <div class="filter-group invoice-filter-inv-number">\n      <input type="text" class="form-control filter-inv-number" placeholder="Invoice#" value="');
    
      __out.push(__sanitize(this.filter.invoice_number));
    
      __out.push('" />\n    </div>\n    <div id="dateFilterGroup" class="invoice-filter-date-range transaction-filter-date-range"></div>\n    <div id="qboFilterGroup" class="invoice-filter-qbo-status">\n    </div>\n    <div id="amountFilterGroup" class="transaction-filter-amount-range invoice-filter-amount-range" style="width: 19%"></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}