import React, { useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { IAccountingClass } from '@src/types/accounting_class';

import Form from '@src/components/ui/form';

import styles from '../add_btn/styles.module.scss';

interface IOption {
  value: string;
  label: string;
}

interface IAddDepartmentFormValue {
  mappingClassIds: string[];
}

interface IAddDepartmentFormProps {
  accountingClasses: IAccountingClass[];
  formId?: string;
  onSubmit: SubmitHandler<IAddDepartmentFormValue>;
  mappedIds: number[];
}

const CheckboxCollection: React.FC<{
  options: IOption[];
  mappingClassIds: string[];
  setValue: (fieldName: any, value: any) => void;
}> = ({ options, mappingClassIds, setValue }) => {
  const handleCheckboxChange = (value: string) => {
    const newValues = mappingClassIds.includes(value)
      ? mappingClassIds.filter((id) => id !== value)
      : [...mappingClassIds, value];
    setValue('mappingClassIds', newValues);
  };

  return (
    <div className={ styles.checkboxs }>
      {options.map((option) => (
        <label key={ option.value }>
          <input
            checked={ mappingClassIds.includes(option.value) }
            type="checkbox"
            value={ option.value }
            onChange={ () => handleCheckboxChange(option.value) }
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

const AddDepartmentForm = ({
  accountingClasses,
  formId,
  onSubmit,
  mappedIds,
}: IAddDepartmentFormProps): JSX.Element => {
  const {
    handleSubmit,
    setValue,
    watch,
  } = useForm<IAddDepartmentFormValue>({
    defaultValues: {
      mappingClassIds: mappedIds.map((id) => String(id)),
    },
  });

  const mappingClassIds = watch('mappingClassIds');
  const AccountingClassOptions = useMemo<IOption[]>(() => {
    return accountingClasses.map((accountingClass) => ({
      value: String(accountingClass.id),
      label: accountingClass.name,
    }));
  }, [accountingClasses]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <span>Select Department to map to category</span>
      {
        AccountingClassOptions.length > 0 ? (
          <CheckboxCollection
            mappingClassIds={ mappingClassIds }
            options={ AccountingClassOptions }
            setValue={ setValue }
          />
        ) : <div>No data</div>
      }

    </Form>
  );
};

export {
  IAddDepartmentFormProps,
  IAddDepartmentFormValue,
  AddDepartmentForm as default,
};
