import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IBankStatement,
  TReviewedStatementDetailsSortColumn,
} from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from '../styles.module.scss';

interface IReviewedStatementDetailsTableProps {
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementDetailsSortColumn>,
  bankStatements: IBankStatement[],
  rcPaymentAccountId: TID,
  businessId: TID,
  bankStatementsDocRequestEnabled: boolean,
  onCloseDetailsModal?: () => void,
}

const ReviewedStatementDetailsTable = ({
  query,
  sorting,
  bankStatements,
  rcPaymentAccountId,
  businessId,
  bankStatementsDocRequestEnabled,
  onCloseDetailsModal,
}: IReviewedStatementDetailsTableProps) => {
  const values = useMemo(() => {
    switch (sorting.data.orderColumn) {
      case 'month':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort(
            (a, b) => ((new Date(a.month) > new Date(b.month)) ? 1 : -1),
          );
        }
        return bankStatements.sort(
          (a, b) => ((new Date(a.month) > new Date(b.month)) ? -1 : 1),
        );
      case 'statement_date':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort(
            (a, b) => ((a.date > b.date) ? 1 : -1),
          );
        }
        return bankStatements.sort(
          (a, b) => ((a.date > b.date) ? -1 : 1),
        );
      case 'status':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort((a, b) => a.state.localeCompare(b.state));
        }
        return bankStatements.sort((a, b) => b.state.localeCompare(a.state));
      case 'closing_balance':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort(
            (a:any, b:any) => a.closingBalance - b.closingBalance,
          );
        }
        return bankStatements.sort(
          (a: any, b: any) => b.closingBalance - a.closingBalance,
        );
      case 'uploaded_by':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort((a, b) => a.uploadedByName.localeCompare(b.uploadedByName));
        }
        return bankStatements.sort((a, b) => b.uploadedByName.localeCompare(a.uploadedByName));
      case 'approved_by':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort((a, b) => a.approvedByName.localeCompare(b.approvedByName));
        }
        return bankStatements.sort((a, b) => b.approvedByName.localeCompare(a.approvedByName));

      case 'verified_by':
        if (sorting.data.orderDirection === 'asc') {
          return bankStatements.sort((a, b) => a.verifiedByName.localeCompare(b.verifiedByName));
        }
        return bankStatements.sort((a, b) => b.verifiedByName.localeCompare(a.verifiedByName));
      default:
        return bankStatements;
    }
  }, [bankStatements, sorting.data.orderColumn, sorting.data.orderDirection]);
  return (
    <Table
      className={ styles['bank-statement-reviewed-details-table'] }
      infiniteQuery={ query }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="month"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Month
          </Table.HCell>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="statement_date"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Statement Date
          </Table.HCell>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="status"
            sorting={ sorting }
            widthPercent={ 25 }
          >
            Status
          </Table.HCell>
          { bankStatementsDocRequestEnabled && (
            <Table.HCell<TReviewedStatementDetailsSortColumn>
              columnName="uploaded_by"
              sorting={ sorting }
              widthPercent={ 10 }
            >
              Uploaded By
            </Table.HCell>
          ) }
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="approved_by"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Approved By
          </Table.HCell>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="verified_by"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Verified By
          </Table.HCell>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            columnName="closing_balance"
            sorting={ sorting }
            widthPercent={ 10 }
          >
            Statement Balance
          </Table.HCell>
          <Table.HCell<TReviewedStatementDetailsSortColumn>
            className="bank-statement-actions-column"
            widthPercent={ 5 }
          />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          values.map((bankStatement) => (
            <Item
              key={ bankStatement.id }
              bankStatement={ bankStatement }
              bankStatementsDocRequestEnabled={ bankStatementsDocRequestEnabled }
              businessId={ businessId }
              rcPaymentAccountId={ rcPaymentAccountId }
              onCloseDetailsModal={ onCloseDetailsModal }
            />
          ))
        }
      </Table.Body>
    </Table>
  );
};

export default React.memo(ReviewedStatementDetailsTable);
