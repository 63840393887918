import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { omit } from 'lodash';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import BusinessField from '@src/components/common/form_fields/business_field';
import Form from '@src/components/ui/form';

import { IAddBusinessClientFormValues, confirmValidation } from './schema';

import styles from '../styles.module.scss';

interface IAddBusinessClientFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IAddBusinessClientFormValues>,
}

const AddBusinessClientForm = ({
  formId,
  onSubmit,
}: IAddBusinessClientFormProps): JSX.Element => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IAddBusinessClientFormValues>({
    resolver: yupResolver(confirmValidation),
  });

  const businessIdController = useController({ name: 'businessId', control });

  return (
    <Form className={ styles['add-business-client-form'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <span className={ styles['add-business-client-note'] }>Which business of yours are you adding this client to?</span>
      <BusinessField
        hideLabel
        error={ errors.businessId?.message }
        placeholder="Select Business"
        { ...omit(businessIdController.field, 'ref') }
      />
      <span className={ styles['add-business-client-note'] }>Enter a display name (visible to client)</span>
      <Form.TextField
        hideLabel
        error={ errors.name?.message }
        placeholder="Business Location Name"
        wrapperClassName="width-100-percent"
        { ...register('name') }
      />
    </Form>
  );
};

export {
  IAddBusinessClientFormProps,
  IAddBusinessClientFormValues,
  AddBusinessClientForm as default,
};
