module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td width="30%">\n  <div class="m-l-0 font-16 cell-content-wrapper">\n    <span>');
    
      __out.push(__sanitize(this.first_name + " " + this.last_name));
    
      __out.push('</span>\n  </div>\n</td>\n<td width="20%">\n  <div class="m-l-auto m-r-auto in-grey-950 display-flex align-items-center height-100-percent">\n    <span>');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</span>\n  </div>\n</td>\n<td width="30%">\n  <div class="cell-content-wrapper">\n    ');
    
      if (this.status === configData.dwolla_beneficial_owner_statuses["VERIFIED"]) {
        __out.push('\n    <label class="font-semi-bold text-left in-green-1000">Verified</label>\n    ');
      } else if (this.status === configData.dwolla_beneficial_owner_statuses["INCOMPLETE"]) {
        __out.push('\n    <label class="font-semi-bold text-left in-grey-950">Pending Verification</label>\n    ');
      } else {
        __out.push('\n    <label class="font-semi-bold text-left in-orange-300 display-flex align-items-center">\n      <i class="icon icon-qbo-error in-orange-300 font-20"></i>\n      <span class="m-l-10">Upload additional documents</span>\n    </label>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="view-option-cell">\n  <div class="cell-content-wrapper">\n    ');
    
      if (this.status === configData.dwolla_beneficial_owner_statuses["DOCUMENT"]) {
        __out.push('\n    <a class="btn btn-blue review-user-js width-120px m-l-auto m-r-0">Review</a>\n    ');
      } else {
        __out.push('\n    <a class="pointer font-16 font-semi-bold review-user-js m-l-auto m-r-0">View</a>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td width="40px">\n  <div class="cell-content-wrapper">\n    <div class="more-option-cell">\n      <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="beneficial-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class="icon icon-3-dots font-20"></i>\n      </button>\n      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="beneficial-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n        <a class="dropdown-item remove-user-js in-red-400 pointer display-flex align-items-center m-t-5 m-b-5">\n          <i class="icon icon-trashcan font-24"></i>\n          <span class="font-16 m-l-15">Remove</span>\n        </a>\n      </div>\n    </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}