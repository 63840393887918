import React, { useCallback, useMemo } from 'react';

import { GroupBase, StylesConfig, components } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetChartOfAccount } from '@src/hooks/queries/chart_of_accounts';
import { getAllChartOfAccountsForBusiness } from '@src/requests/chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import { AsyncSelectPaginateInput } from '@src/components/ui/form';

interface IChartOfAccountSelectorProps
{
  accountType?: string,
  businessId: TID,
  value?: TID,
  isDiabled?: boolean,
  onChange: (id: TID) => void,
}

const formatOptionLabel = (item: IChartOfAccount): JSX.Element => {
  return (
    <span className="m-t-2 m-l-10">{ item.name }</span>
  );
};

const getOptionLabel = (item: IChartOfAccount) => item.name;
const getOptionValue = (item: IChartOfAccount) => String(item.id);

const SingleValue = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue { ...props }>
      { data.name }
    </components.SingleValue>
  );
};

const customStyles: StylesConfig<IChartOfAccount, any> = {
  option: (provided) => (
    {
      ...provided,
      padding: 0,
    }
  ),

  menu: (provided) => (
    {
      ...provided,
      minWidth: '300px',
      width:    '300px',
      margin:   '2px 0 0',
      zIndex:   1000,
    }
  ),

  menuList: (provided) => (
    {
      ...provided,
      padding: '0 0 2px',
    }
  ),
};

const ChartOfAccountInput = ({
  accountType,
  businessId,
  isDiabled = false,
  value,
  onChange,
  ...props
}: IChartOfAccountSelectorProps) => {
  const chartOfAccountQuery = useGetChartOfAccount({
    businessId,
    id: value,
  });

  const handleSource: LoadOptions<IChartOfAccount, GroupBase<IChartOfAccount>, any> =
    useCallback((query, options, { page }) => {
      if (!businessId) return { options: [] };

      return getAllChartOfAccountsForBusiness({
        businessId,
        search:         query,
        filter:         { accountType },
        orderColumn:    'account_name',
        orderDirection: 'asc',
        page,
      }).then((data) => {
        const hasMore = data.length > 0;

        return {
          hasMore,
          options:    data,
          additional: {
            page: page + 1,
          },
        };
      });
    }, [accountType, businessId]);

  const handleChange = useCallback((item) => {
    onChange(item.id);
  }, [onChange]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;
    if (!chartOfAccountQuery.data) return undefined;

    return chartOfAccountQuery.data.chartOfAccount;
  }, [chartOfAccountQuery.data, value]);

  return (
    <AsyncSelectPaginateInput
      { ...props }
      hideIndicators
      isClearable
      additional={ {
        page: 1,
      } }
      components={ {
        SingleValue,
      } }
      debounceTimeout={ 300 }
      formatOptionLabel={ formatOptionLabel }
      getOptionLabel={ getOptionLabel }
      getOptionValue={ getOptionValue }
      id="chart_of_account_input"
      isDisabled={ isDiabled }
      loadOptions={ handleSource }
      placeholder="Select Account"
      styles={ customStyles }
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default ChartOfAccountInput;
