import React, { useCallback, useMemo } from 'react';

import { IReviewedBankStatementState, TBankStatementState, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID, TYear } from '@src/types/common';
import { getUTCTimezone } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import StatementStateList from '../bank_statement_state/bank_statement_state_list';

interface IBankStatementPendingReviewTableItemProps {
  businessId: TID
  reviewedBankStatement: IReviewedBankStatementsData,
  year?: TYear,
}

const BankStatementPendingReviewTableItem = ({
  businessId,
  reviewedBankStatement,
  year,
}: IBankStatementPendingReviewTableItemProps) => {
  const handleClickReviewedItem = useCallback(() => {
    Backbone.history.navigate(`/businesses/${businessId}/banking_accounts/reviewed/${reviewedBankStatement.id}?year=${year}`, { trigger: true });
  }, [businessId, reviewedBankStatement.id, year]);

  const states = useMemo(() => {
    let result: Array<IReviewedBankStatementState> = [];

    for (let i = 0; i < 12; i += 1) {
      const bState = reviewedBankStatement.bankStatements.find(
        (b) => getUTCTimezone(new Date(b.date)).getMonth() === i,
      );
      if (bState != null) {
        result = [
          ...result,
          {
            month:         i + 1,
            state:         bState.state as TBankStatementState,
            statementDate: bState.date,
            approvedAt:    bState.approvedAt,
            verifiedAt:    bState.verifiedAt,
            createdAt:     bState.createdAt,
          }];
      } else {
        result = [...result, { month: i + 1, state: 'missing' }];
      }
    }
    return result;
  }, [reviewedBankStatement.bankStatements]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        style={ { color: '#0080FF' } }
        widthPercent={ 20 }
        onClick={ handleClickReviewedItem }
      >
        { reviewedBankStatement.name }
      </Table.Cell>
      <Table.AmountCell
        pointer
        amount={ reviewedBankStatement.currentBalance }
        style={ { paddingRight: '30px' } }
        widthPercent={ 10 }
        onClick={ handleClickReviewedItem }
      />
      <Table.Cell pointer style={ { paddingLeft: '25px' } } widthPercent={ 13 } onClick={ handleClickReviewedItem }>
        {
          reviewedBankStatement.missingStatementsCount
            ? (reviewedBankStatement.missingStatementsCount)
            : ('-')
        }
      </Table.Cell>

      <StatementStateList
        states={ states }
      />
    </Table.Row>
  );
};

export default React.memo(BankStatementPendingReviewTableItem);
