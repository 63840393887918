import React, { useCallback, useState, useMemo } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useSplitTransactions, useUndoSplitTransactions } from '@src/hooks/queries/split_transactions';
import { ISplitTransaction } from '@src/types/split_transactions';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import SplitModalTitle from './split_transaction_details/split_modal_title';
import SplitForm from './split_transaction_details/split_transaction_form';
import { useSplitTransactionErrorModal } from './split_transactions_error_modal';
import UndoSplitForm from './undo_split_transaction_details/undo_split_transaction_form';

interface ISplitTransactionsProps extends IUseModalProps {
  transaction: ITransactionServiceDocument,
  forSplit: boolean,
}

const SplitTransactions = ({
  transaction,
  forSplit,
  isOpen,
  onCancel,
  onDone,
}: ISplitTransactionsProps): JSX.Element => {
  const onSplit = useSplitTransactions();
  const onUndoSplit = useUndoSplitTransactions();
  const [splittedTransactions, setSplittedTransactions] = useState<ISplitTransaction[]>([]);
  const [differenceAmount, setDifferenceAmount] = useState<number>(0);
  const [hasBlankItems, setHasBlankItems] = useState<boolean>(false);
  const transactionDateInReconciledModal = useSplitTransactionErrorModal();
  const { openWithValue: openErrorModal } = transactionDateInReconciledModal;

  const canSplit = useMemo(() => {
    const index = splittedTransactions.findIndex(
      (item: ISplitTransaction) => item.amount === 0 || item.memo === '',
    );
    if (index === -1) {
      return true;
    }
    return false;
  }, [splittedTransactions]);

  const handleError = useCallback((response) => {
    if (response?.response?.data?.errors[0]
      === window.Docyt.Common.Constants.Messages.BANKING_ACCOUNT_TRANSACTION_DATE_IN_RECONCILED) {
      transactionDateInReconciledModal.open();
      openErrorModal({});
    }
  }, [openErrorModal, transactionDateInReconciledModal]);

  const handleProceed = useCallback(() => {
    if (forSplit && !canSplit) {
      setHasBlankItems(true);
      return;
    }

    if (transaction.bankStatement?.state === 'verified') {
      openErrorModal({
        message: (
          <span>
            { window.Docyt.Common.Constants.Messages.SPLIT_VERIFIED_TRANSACTION_ERROR_MSG }
          </span>
        ),
        title: 'Warning!',
      });
      return;
    }
    if (forSplit) {
      onSplit.mutate(
        {
          transactionId: transaction.id,
          splittedTransactions,
        },
        { onSuccess: onDone, onError: handleError },
      );
    } else {
      onUndoSplit.mutate(
        {
          transactionId: transaction.id,
        },
        { onSuccess: onDone, onError: handleError },
      );
    }
  }, [onSplit,
    onUndoSplit,
    forSplit,
    splittedTransactions,
    transaction,
    canSplit,
    openErrorModal,
    onDone,
    handleError]);

  const handleGetSplittedTransactions = useCallback((
    transactions: ISplitTransaction[],
    difference: number,
  ) => {
    setSplittedTransactions(transactions);
    setDifferenceAmount(difference);
  }, []);

  return (
    <>
      <MutationStatus mutation={ onSplit } successMessage="Splitting has started and will be finished soon." />
      <MutationStatus mutation={ onUndoSplit } successMessage="Undo splitting has started and will be finished soon." />
      <transactionDateInReconciledModal.Component
        { ...transactionDateInReconciledModal.props }
        transaction={ transaction }
      />
      <Modal.Standard
        dialogClassName="split-transactions-modal"
        isProceedDisabled={ forSplit && differenceAmount !== 0 }
        proceedTitle={ forSplit ? 'Split Transaction' : 'Undo Split' }
        show={ isOpen }
        title={ forSplit ? <SplitModalTitle /> : 'Undo Split Transaction' }
        onCancel={ onCancel }
        onProceed={ handleProceed }
      >
        {() => (
          forSplit
            ? (
              <SplitForm
                hasBlankItems={ hasBlankItems }
                transaction={ transaction }
                onSplitted={ handleGetSplittedTransactions }
              />
            )
            : (
              <UndoSplitForm
                transactionId={ transaction.id }
              />
            )
        )}
      </Modal.Standard>
    </>
  );
};

const useSplitTransactionsModal = makeUseModal(SplitTransactions);

export {
  ISplitTransactionsProps,
  SplitTransactions,
  useSplitTransactionsModal as default,
};
