import React from 'react';

import { ErrorNotification } from '@src/components/ui/notification';

import AISuggestion from './ai_suggestion';
import ContextExpand from './context_expand';
import ContextSummary from './context_summary';
import { useAIContextLogic } from './use_ai_context_logic';

import styles from './styles.module.scss';

const UnFlaggedTransactionContext = () => {
  const { aiContext, expand, openExpand, closeExpand, isLoading, error } = useAIContextLogic();

  const renderContent = () => {
    if (!aiContext) {
      if (isLoading) return <span className={ styles['ai-cursor'] }>|</span>;
      if (error) return <ErrorNotification error={ error } />;
    }

    return (
      <AISuggestion content={ aiContext } />
    );
  };

  if (expand) {
    return (
      <ContextExpand onClick={ closeExpand }>
        <div className={ styles['context-title'] }>
          From AI
        </div>
        { renderContent() }
      </ContextExpand>
    );
  }

  return <ContextSummary onClick={ openExpand } />;
};

export default UnFlaggedTransactionContext;
