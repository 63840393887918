import React from 'react';

import { groupInvoicesProp } from '@src/components/accounts_payable/review_payment/utils/regroup_invoices_helper';
import Table from '@src/components/ui/table';

import Item from './table_item';
import ItemTotalAmount from './table_item_total_amount';

import styles from '../styles.module.scss';

interface IReviewPaymentTableProps {
  groupedServiceDocuments: groupInvoicesProp,
}

const ReviewPaymentTable = ({
  groupedServiceDocuments,
}: IReviewPaymentTableProps) => {
  const groupedInvoices:any = [];

  Object.keys(groupedServiceDocuments).forEach((key) => {
    const invoices = groupedServiceDocuments[key];
    invoices.forEach((invoice, index) => {
      groupedInvoices.push(
        <Item
          key={ invoice.id }
          index={ index }
          rowSpan={ invoices.length + 1 }
          serviceDocument={ invoice }
        />,
      );
    });
    groupedInvoices.push(
      <ItemTotalAmount
        serviceDocuments={ invoices }
      />,
    );
  });

  return (
    <Table
      className={ `banking-transactions-table ${styles['review-payment-table']}` }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 20 } />
          <Table.HCell widthPercent={ 15 }>Invoice no</Table.HCell>
          <Table.HCell widthPercent={ 10 }>Status</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Account no</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Bill Date</Table.HCell>
          <Table.HCell widthPercent={ 10 }>Due In</Table.HCell>
          <Table.HCell widthPercent={ 15 }>
            <span className="pull-right">Amount</span>
          </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        { groupedInvoices }
      </Table.Body>
    </Table>
  );
};

export default React.memo(ReviewPaymentTable);
