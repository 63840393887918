import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

interface IReviewChangesFooterProps {
  globalVendor?: IBusinessVendor,
  isSelected: boolean,
  onChecked: () => void,
}

const ReviewChangesFooter = ({
  globalVendor,
  isSelected,
  onChecked,
}: IReviewChangesFooterProps) => {
  if (!globalVendor) return null;

  return (
    <div className="review-confirm-description">
      Are you sure you want to continue?
      <div className="checkbox checkbox-primary p-l-10 m-t-16">
        <input
          checked={ Boolean(isSelected) }
          className="pointer"
          id="by-check-box"
          type="checkbox"
          onChange={ onChecked }
        />
        <label className="font-12 m-l-10" htmlFor="by-check-box">
          {
            globalVendor.isClaimed
              ? (
                <span>
                  I confirm that I have validated the Docyt ID
                  of this vendor and would like to add him to my vendor list.
                </span>
              )
              : (
                <span>
                  I confirm that I have validated this Vendors&apos; information and
                  I would like to add them to &quot;My Vendors&quot; list.
                </span>
              )
          }
        </label>
      </div>
    </div>
  );
};

export default React.memo(ReviewChangesFooter);
