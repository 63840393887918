import React from 'react';

import { TServiceType } from '@src/types/services';

import {
  AccountsPayableIcon,
  ReceiptBoxIcon,
  RevenueChartIcon,
  BankStatementsIcon,
  OtherDocumentsIcon,
} from '@src/components/utils/icomoon';
import { InboxIcon } from '@src/components/utils/img_icons';

type TForwardDocumentService = {
  icon: React.FunctionComponent,
  label: string,
  props?: object,
  value: TServiceType,
}

export const BankStatementItem: TForwardDocumentService = {
  icon:  BankStatementsIcon,
  label: 'Bank Statements',
  value: 'BankStatement',
};

export const BalanceSheetStatementItem: TForwardDocumentService = {
  icon:  OtherDocumentsIcon,
  label: 'Other Balance Sheet Accounts',
  value: 'BalanceSheetDocument',
};

export const ForwardDocumentServices: TForwardDocumentService[] = [
  {
    icon:  AccountsPayableIcon,
    label: 'Accounts Payable',
    value: 'AccountsPayableAdvisor',
  },
  {
    icon:  ReceiptBoxIcon,
    label: 'Receipt Box',
    value: 'ReceiptService',
  },
  {
    icon:  InboxIcon,
    label: 'Business Mailroom',
    props: { style: { width: '42px' } },
    value: 'InboxService',
  },
  {
    icon:  RevenueChartIcon,
    label: 'Revenue Center',
    value: 'RevenueService',
  },
  BankStatementItem,
  BalanceSheetStatementItem,
];
