module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var category, i, len, ref;
    
      __out.push('<div>\n  <div class="clearfix">\n    <h2 class="m-b-5 font-bold font-26 pull-left">Revenue Reports</h2>\n    <button type=\'button\' class="btn btn-info ladda-button btn-add-report m-t-15 pull-right" data-color="blue" data-style="expand-right" style="display: none;"><span class="ladda-label">Add Revenue Report</span></button>\n  </div>\n  <hr/>\n  <div class="revenue-month-filter-wrapper">\n    <input type="text" class="form-control date-picker filter-month" placeholder="Current Month" autocomplete="off" value="');
    
      __out.push(__sanitize(this.calendarMonth));
    
      __out.push('" />\n  </div>\n  <div class="table-responsive revenue-report-table-wrapper">\n    ');
    
      if (this.collection.length <= 0) {
        __out.push('\n    <div class="text-center add-revenue-report-container">\n      <p><span class="icon-revenue-reports font-80"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span></p>\n      <p class="font-20 font-semi-bold">Add a Revenue Report</p>\n      <p>\n        Upload a Revenue Report from your computer to get started.<br/>\n        Or just forward your revenue report to <a href="mailto:');
        __out.push(__sanitize(this.rv_service.get('upload_email')));
        __out.push('">');
        __out.push(__sanitize(this.rv_service.get('upload_email')));
        __out.push('</a>.<br/>\n        You can find your forwarded revenue reports in Unverfied Reports.\n      </p>\n      <p><button type=\'button\' class="btn btn-info ladda-button btn-add-report" data-color="blue" data-style="expand-right"><span class="ladda-label">Add Revenue Report</span></button></p>\n    </div>\n    ');
      }
    
      __out.push('\n    <div class="panel panel-default">\n      <div class="revenue-filter-container padding-15">\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="input-group transaction-date-input-group">\n              <input type="text" class="form-control transaction-date-picker" id="transactionStartDate" placeholder="From" autocomplete="off"/>\n              <span class="input-group-addon">-</span>\n              <input type="text" class="form-control transaction-date-picker" id="transactionEndDate" placeholder="To" autocomplete="off"/>\n              <a class="dropdown-toggle pointer text-center" type="button" id="date-select-menu-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n                <span class="fa fa-caret-down font-14"></span>\n              </a>\n              <ul class="pointer dropdown-menu dropdown-menu-right font-24 col-xs-12" role="menu" aria-labelledby="date-select-menu-btn">\n                <li><a tabindex="-1" class="date-all-time">All Time</a></li>\n                <li><a tabindex="-1" class="date-mtd">Month to Date (MTD)</a></li>\n              </ul>\n            </div>\n          </div>\n        </div>\n      </div>\n      <table class="table bg-white">\n        <thead>\n          <tr>\n            <th class="pointer relative sortable revenue-month-column-header sorted-asc" data-orderby="day" data-orderdir="ASC">Day</th>\n            <th class="pointer relative sortable revenue-total-column-header" data-orderby="total" data-orderdir="ASC">Total Revenue</th>\n            ');
    
      ref = this.topCategories;
      for (i = 0, len = ref.length; i < len; i++) {
        category = ref[i];
        __out.push('\n              <th class="pointer relative sortable revenue-category-');
        __out.push(__sanitize(category.revenue_service_category_id));
        __out.push('-column-header" data-orderby="category-');
        __out.push(__sanitize(category.revenue_service_category_id));
        __out.push('" data-orderdir="ASC">');
        __out.push(__sanitize(category.revenue_category_name));
        __out.push('</th>\n            ');
      }
    
      __out.push('\n            <th class="pointer relative" style="width: 150px;">Category Breakdown</th>\n            <th class="pointer relative" style="width: 150px;">Money Breakdown</th>\n            <th class="pointer relative" style="width: 50px;"></th>\n            <th class="pointer relative" style="width: 50px;"></th>\n          </tr>\n        </thead>\n        <tbody>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}