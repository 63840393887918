import React, { useCallback } from 'react';

import { components, GroupBase, StylesConfig } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getAllChartOfAccountsForBusiness } from '@src/requests/chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import { ChartOfAccountAvatar } from '@src/components/ui/avatars';
import { AsyncSelectPaginateInput } from '@src/components/ui/form';

interface IChartOfAccountSelectorProps
{
  accountType?: string,
  businessId: TID,
  handleSelected: (items: any) => void,
}

const formatOptionLabel = (item: IChartOfAccount): JSX.Element => {
  return (
    <div
      className="display-inline-flex width-100-percent"
      style={ { padding: '8px 12px' } }
    >
      <ChartOfAccountAvatar item={ item } />
      <span className="m-t-2 m-l-10">{ item.displayName }</span>
    </div>
  );
};

const getOptionLabel = (item: IChartOfAccount) => item.displayName;
const getOptionValue = (item: IChartOfAccount) => String(item.id);

const SingleValue = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue { ...props }>
      { data.displayName }
    </components.SingleValue>
  );
};

const customStyles: StylesConfig<IChartOfAccount, any> = {
  option: (provided) => (
    {
      ...provided,
      padding: 0,
    }
  ),

  menu: (provided) => (
    {
      ...provided,
      minWidth: '400px',
      width:    '400px',
      margin:   '2px 0 0',
    }
  ),

  menuList: (provided) => (
    {
      ...provided,
      padding: '0 0 2px',
    }
  ),
};

const ChartOfAccountSelector = ({
  accountType,
  businessId,
  handleSelected,
  ...props
}: IChartOfAccountSelectorProps) => {
  const handleSource: LoadOptions<IChartOfAccount, GroupBase<IChartOfAccount>, any> =
    useCallback((query, options, { page }) => {
      if (!businessId) return { options: [] };

      return getAllChartOfAccountsForBusiness({
        businessId,
        search: query,
        filter: { accountType },
        page,
      }).then((data) => {
        const hasMore = data.length > 0;

        return {
          hasMore,
          options:    data,
          additional: {
            page: page + 1,
          },
        };
      });
    }, [accountType, businessId]);

  return (
    <AsyncSelectPaginateInput
      { ...props }
      hideIndicators
      isClearable
      isMulti
      additional={ {
        page: 1,
      } }
      components={ {
        SingleValue,
      } }
      debounceTimeout={ 300 }
      formatOptionLabel={ formatOptionLabel }
      getOptionLabel={ getOptionLabel }
      getOptionValue={ getOptionValue }
      id="multi_chart_of_account_select"
      loadOptions={ handleSource }
      styles={ customStyles }
      onChange={ handleSelected }
    />
  );
};

export default ChartOfAccountSelector;
