import React from 'react';

import { Outlet, useParams } from 'react-router-dom';

import { useShowMetric } from '@src/hooks/queries/metrics';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

import { BusinessMetricContext } from './business_metric_context';

const BusinessMetricProvider = (): JSX.Element | null => {
  const { businessMetricId } = useParams();

  const {
    isLoading,
    isError,
    error,
    data,
  } = useShowMetric({ id: businessMetricId });

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Business Metric is loaded" />;

  return (
    <BusinessMetricContext.Provider value={ data }>
      <Outlet />
    </BusinessMetricContext.Provider>
  );
};

export default BusinessMetricProvider;
