import React from 'react';

import inboxServiceImg from '@assets/images/business_inbox/business-inbox-icon.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const InboxIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Inbox icon"
      src={ inboxServiceImg }
      { ...props }
    />
  );
};

export default InboxIcon;
