import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetBusinessMetricValuesParams,
  IGetBusinessMetricValuesResponse,
  ICreateBusinessMetricBatchValuesParams,
  getBusinessMetricValues,
  createBusinessMetricBatchValues,
  IValueByDateParams,
  metricValueByDate,
} from '@src/requests/business_metric_values';
import { IBusinessMetricValue, IBusinessMetricValueByDate } from '@src/types/business_metric_value';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const useGetBusinessMetricsValue = createUseGetInfiniteCollection<
  IBusinessMetricValue,
  IGetBusinessMetricValuesParams,
  IGetBusinessMetricValuesResponse
>({
  queryKey: QueryKey.businessMetricValues,
  request:  getBusinessMetricValues,
});

const useMetricValueByDate = (params: IValueByDateParams) => {
  return useQuery<IBusinessMetricValueByDate[], Error>(
    [QueryKey.businessMetricValuesByDate, params],
    () => metricValueByDate(params),
  );
};

const useCreateBusinessMetricBatchValues = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ICreateBusinessMetricBatchValuesParams>(
    createBusinessMetricBatchValues,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.businessMetricValues);
      },
    },
  );
};

export {
  useGetBusinessMetricsValue,
  useMetricValueByDate,
  useCreateBusinessMetricBatchValues,
};
