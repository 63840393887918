import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IFileTextIconProps extends Omit<IFaIconProps, 'icon'> {
  variant?: 'o',
}

const FileTextIcon = ({
  variant,
  ...props
}: IFileTextIconProps) => {
  return (
    <FaIcon
      icon={ variant === 'o' ? 'file-text-o' : 'file-text' }
      { ...props }
    />
  );
};

export default FileTextIcon;
