import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const useDebouncedEffect = (
  effect: EffectCallback,
  deps: DependencyList,
  delay: number,
) => {
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      effect();
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

const useDebouncedCallback = (
  callback: (...args: any[]) => void,
  deps: DependencyList,
  delay: number,
) => {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  return useCallback((...args) => {
    const later = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      callback(...args);
    };

    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeout.current = setTimeout(later, delay);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay, ...(deps || [])]);
};

type TUseStateCallback<T> = (s: T) => void;

function useStateCallback<T>(
  initialState: T,
): [T, (state: T | ((oldState: T) => T), cb?: TUseStateCallback<T>) => void] {
  const [state, setState] = useState(initialState);
  // init mutable ref container for callbacks
  const cbRef = useRef<TUseStateCallback<T> | undefined>(undefined);

  const setStateCallback = useCallback((
    newState: T | ((oldState: T) => T),
    cb?: TUseStateCallback<T>,
  ) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(newState);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `undefined` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}
export {
  useDebouncedCallback,
  useDebouncedEffect,
  useStateCallback,
};
