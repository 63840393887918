import React from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { QueueIcon } from '@src/components/utils/icomoon';

interface IViewCheckActionProps {
  checkId: TID,
}

const ViewCheckAction = ({
  checkId,
}: IViewCheckActionProps) => {
  return (
    <ActionsDropdown.Action
      href={ `/document/${checkId}` }
      icon={ <QueueIcon fontSize={ 18 } /> }
      name="View Check"
    />
  );
};

export default ViewCheckAction;
