import React, { useCallback } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import { useUniqueDomId } from '@src/hooks/dom';
import { IWidget } from '@src/types/dashboards';

import { IDashboardInput } from './schema';

interface ISelectWidgetCheckboxProps
{
  widget: IWidget,
  field: ControllerRenderProps<IDashboardInput, 'widgets'>,
}

const SelectWidgetCheckbox = ({
  widget,
  field: { value, onChange },
}: ISelectWidgetCheckboxProps): JSX.Element => {
  const inputId = useUniqueDomId('widget_selector_');

  const handleChange = useCallback((e) => {
    if (!e.target.checked) {
      const index = value.map((item) => item.identifier).indexOf(widget.identifier);
      value.splice(index, 1);
      onChange(value);
    } else {
      onChange(value.concat([widget]));
    }
  }, [value, widget, onChange]);

  return (
    <div className="checkbox checkbox-primary">
      <input
        checked={ value.filter((item) => item.name === widget.name).length !== 0 }
        className="pointer"
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
      />
      <label
        aria-label="Select"
        htmlFor={ inputId }
      >
        { widget.name }
      </label>
    </div>
  );
};

export default SelectWidgetCheckbox;
