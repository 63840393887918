import React from 'react';

import last from 'lodash/last';
import sumBy from 'lodash/sumBy';
import { InfiniteData } from 'react-query';

import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';

import Text from '@src/components/ui/text';

interface ITransactionsPaginationInfoProps {
  data?: InfiniteData<IGetTransactionServiceDocumentsResponse>
}

const TransactionsPaginationInfo = ({
  data,
}: ITransactionsPaginationInfoProps): JSX.Element | null => {
  if (!data) return null;

  const pages = data.pages || [];
  const count = sumBy(pages, (p) => p.collection.length);
  const totalCount = last(pages)?.meta?.totalCount || 0;

  return (
    <Text as="p" ml={ 15 } mt={ 10 } textAlign="right">
      Showing
      { ' ' }
      <Text fontVariant="bold">{ count }</Text>
      { ' ' }
      out of
      { ' ' }
      <Text fontVariant="bold">{ totalCount }</Text>
      { ' ' }
      items
    </Text>
  );
};

export default React.memo(TransactionsPaginationInfo);
