module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var frequency, i, j, len, len1, method, ref, ref1;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog modal-lg payment-rule-modal-dialog recurring-payment-modal relative">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="modal-title font-28 font-bold">\n            ');
    
      __out.push(__sanitize(!this.id ? 'Create' : 'Edit'));
    
      __out.push(' Recurring Payment\n         </h1>\n      </div>\n      <div class="modal-body">\n        <div class="rule-form-group m-t-12">\n          <label class="form-label font-16">Payee/Receiver</label>\n          <div class="form-inline relative vendor-typeahead-wrapper p-r-60">\n            <div class="vendors-region width-100-percent"></div>\n          </div>\n        </div>\n        <p class="in-red-400 m-t-10 m-b-10 p-r-60 text-right" id="input-vendor-warning"><i class="fa fa-warning m-r-5"></i>Please select vendor.</p>\n        <div class="rule-form-group m-t-12">\n          <label class="form-label font-16">Amount</label>\n          <div class="form-inline p-r-60">\n            <input class="form-control font-16" id="amount-input" value="');
    
      __out.push(__sanitize(this.amount || ''));
    
      __out.push('" placeholder="Amount in USD" autocomplete="off"/>\n          </div>\n        </div>\n        <p class="in-red-400 m-t-10 m-b-10 p-r-60 text-right" id="input-amount-warning"><i class="fa fa-warning m-r-5"></i>Please select amount.</p>\n        <div class="rule-form-group m-t-12 relative">\n          <label class="form-label font-16">Expense Category</label>\n          <div class="chart-of-account-field">\n            ');
    
      if (this.chart_of_account_display_name && this.recurring_bill_accounts.length < 2) {
        __out.push('\n            <span class="font-16 m-r-32 in-black">');
        __out.push(__sanitize(this.chart_of_account_display_name));
        __out.push('</span>\n            ');
      }
    
      __out.push('\n            <a class="form-action font-16 font-semi-bold split-action %>" id="split-action-btn">\n              ');
    
      if (this.recurring_bill_accounts.length === 0) {
        __out.push('\n              ');
        __out.push(__sanitize(this.chart_of_account_display_name ? 'Edit' : 'Select Category'));
        __out.push('\n              ');
      } else if (this.recurring_bill_accounts.length === 1) {
        __out.push('\n              ');
        __out.push(__sanitize(this.chart_of_account_display_name ? 'Edit' : '1 Split'));
        __out.push('\n              ');
      } else {
        __out.push('\n              ');
        __out.push(__sanitize(this.recurring_bill_accounts.length));
        __out.push(' Splits\n              ');
      }
    
      __out.push('\n            </a>\n            <p class="in-red-400 m-l-auto m-b-0 p-r-60" id="input-category-warning"><i class="fa fa-warning m-r-5"></i>Please select category.</p>\n          </div>\n        </div>\n        <div class="rule-form-group m-t-12">\n          <label class="form-label font-16">Payment Method</label>\n          <div class="form-inline p-r-60 relative">\n            <select class="selectpicker form-control font-16" id="payment-method-select" title="Select Payment Method">\n            ');
    
      ref = Docyt.Common.Constants.RECURRING_PAYMENT_METHODS;
      for (i = 0, len = ref.length; i < len; i++) {
        method = ref[i];
        __out.push('\n              <option value="');
        __out.push(__sanitize(method['key']));
        __out.push('" ');
        __out.push(__sanitize(this.payment_method === method['key'] ? 'selected' : ''));
        __out.push('>');
        __out.push(__sanitize(method['label']));
        __out.push('</option>\n            ');
      }
    
      __out.push('\n            </select>\n          </div>\n        </div>\n        <div class="rule-form-group m-t-12 memo-input-wrapper">\n          <label class="form-label font-16">Check Memo</label>\n          <div class="form-inline p-r-60">\n            <input class="form-control font-16" id="memo-input" value="');
    
      __out.push(__sanitize(this.memo || ''));
    
      __out.push('" placeholder="Check Memo" autocomplete="off"/>\n          </div>\n        </div>\n        <div class="rule-form-group m-t-12 add-address-wrapper ');
    
      if (this.address) {
        __out.push(__sanitize('address-group'));
      }
    
      __out.push('">\n          <label class="form-label font-16">Payment Address</label>\n          <div class="form-inline">\n            ');
    
      if (!this.address) {
        __out.push('\n            <a class="pointer font-16 font-semi-bold" id="add-address-js">Add Address</a>\n            ');
      } else {
        __out.push('\n            <div class="in-black">\n                <p class="font-15">');
        __out.push(__sanitize(this.address.name));
        __out.push(' ');
        __out.push(__sanitize(this.address.company));
        __out.push('</p>\n                <p class="font-15">');
        __out.push(__sanitize(this.address.address_line1));
        __out.push('</p>\n                <p class="font-15">');
        __out.push(__sanitize(this.address.address_line2));
        __out.push('</p>\n                <p class="font-15">');
        __out.push(__sanitize(this.address.address_city));
        __out.push(' ');
        __out.push(__sanitize(this.address.address_state));
        __out.push(' ');
        __out.push(__sanitize(this.address.address_zip));
        __out.push('</p>\n                <p class="font-15">');
        __out.push(__sanitize(this.address.address_country));
        __out.push('</p>\n            </div>\n            ');
      }
    
      __out.push('\n          </div>\n          <p class="in-red-400 m-l-auto m-b-0 p-r-60" id="select-address-warning"><i class="fa fa-warning m-r-5"></i>Please select address.</p>\n          ');
    
      if (this.address) {
        __out.push('\n          <a class="form-action font-16 font-semi-bold" id="address-edit-btn">Edit</a>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class="rule-form-group m-t-12">\n          <label class="form-label font-16">Note</label>\n          <div class="form-inline p-r-60">\n            <input class="form-control font-16" id="note-input" value="');
    
      __out.push(__sanitize(this.note || ''));
    
      __out.push('" placeholder="Note" autocomplete="off"/>\n          </div>\n        </div>\n        <div class="explanation-block m-t-38 p-t-24">\n          <div class="rule-form-group">\n            <label class="form-label font-16">Date</label>\n            <div class="form-inline p-r-60 relative">\n              <input class="form-control font-16" id="rule-date-input" value="');
    
      __out.push(__sanitize(this.next_delivery_date || ''));
    
      __out.push('" placeholder="Select Date" autocomplete="off"/>\n            </div>\n          </div>\n          <p class="in-red-400 m-t-10 m-b-10 p-r-60 text-right" id="input-date-warning"><i class="fa fa-warning m-r-5"></i>Please select date.</p>\n          <div class="rule-form-group m-t-14">\n            <label class="form-label"></label>\n            <div class="form-inline m-r-60 relative">\n              <select class="selectpicker form-control font-16" id="frequency-select" title="Frequency">\n              ');
    
      ref1 = this.frequencyList;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        frequency = ref1[j];
        __out.push('\n                <option class="font-15" value="');
        __out.push(__sanitize(frequency.value));
        __out.push('" ');
        if (frequency.value === this.currFrequency) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(frequency.name));
        __out.push('</option>\n              ');
      }
    
      __out.push('\n              </select>\n            </div>\n          </div>\n          <p class="in-red-400 m-t-10 m-b-10 p-r-60 text-right" id="select-frequency-warning"><i class="fa fa-warning m-r-5"></i>Please select frequency.</p>\n          <h3 class="font-13 font-bold m-t-38 m-b-0">Note about dates and shipping</h3>\n          <p class="font-13 line-height-20 m-t-20 m-b-0">It may take 5-7 business days for your check to reach an address in continental United States. Please adjust the payment date accordingly, if you need the physical check to arrive at merchant address by a certain date.</p>\n        </div>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer font-18\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js">\n            <span class="ladda-label">');
    
      __out.push(__sanitize(!this.id ? 'Activate' : 'Save'));
    
      __out.push(' Recurring Payment</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}