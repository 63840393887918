import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IChatIconProps extends IIcomoonIconProps {
  variant?: 'empty' | 'filled' | 'round',
}

const ChatIcon = ({
  variant = 'empty',
  ...props
}: IChatIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ `chat-${variant}` }
    />
  );
};

export default ChatIcon;
