import React from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import styles from '../navigation_styles.module.scss'

interface IServiceSubMenuProps {
  className?: any,
  isHidden?: boolean,
  serviceUrl?: string,
  title: string,
  children: React.ReactNode,
  launchDarklyFeature?: TLaunchDarklyFeature,
  hasBorderBottom?: boolean,
  hasWiderSubmenuList?: boolean
}

const ServiceSubMenu = ({
  className,
  isHidden,
  serviceUrl,
  title,
  children,
  launchDarklyFeature,
  hasBorderBottom = false,
  hasWiderSubmenuList = false
}: IServiceSubMenuProps) => {
  if (isHidden) return null;

  const itemClasses = classNames(
    className,
    'dropdown-submenu relative',
  );
  const titleClasses = classNames(
    'm-l-18 m-t-8 m-b-8',
  );

  return (
    <li className={ itemClasses } data-global-feature-toggle={ launchDarklyFeature }>
      {isEmpty(serviceUrl) ? (
        <div className={ `submenu-title ${styles['new-submenu-title']} ${hasBorderBottom ? styles['border-bottom'] : ''}` }>
          <span className={ titleClasses }>{ title }</span>
        </div>
      ) : (
        <a className="submenu-title" href={ serviceUrl }>
          <span className={ titleClasses }>{ title }</span>
        </a>
      )}
      <ul className={ `dropdown-menu submenu-list ${hasWiderSubmenuList ? 'wider-submenu-list' : ''}` } role="menu">
        { children }
      </ul>
    </li>
  );
};

export default ServiceSubMenu;
