import React from 'react';

import { currencyLocaleFormatter } from '@src/utils/currency';

import styles from './styles.module.scss';

interface IResolveRevenueReportFooter {
  credit: number;
  debit: number;
}

const ResolveRevenueReportFooter = ({
  credit,
  debit,
}: IResolveRevenueReportFooter) => {
  return (
    <div className={ styles['total-information'] }>
      <span className={ styles['selected-total-information'] }>Totals</span>
      <span className={ `${styles['debit-total']}` }>
        {currencyLocaleFormatter(debit)}
      </span>
      <span className={ `${styles['credit-total']}` }>
        {currencyLocaleFormatter(credit)}
      </span>
    </div>
  );
};

export default ResolveRevenueReportFooter;
