module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="receipt-queue-status-container well text-center font-semi-bold in-white in-blue-650 hidden">\n  ');
    
      __out.push(this.statusMessage);
    
      __out.push('\n</div>\n');
    
      if (this.forVendor) {
        __out.push('\n<div class="m-t-30">Below are the receipts that belong to the payment accounts that you have access to </div>\n');
      }
    
      __out.push('\n<div class="table-responsive receipts-table-wrapper receipts-sortable-table service-detail-view documents-drop-zone">\n    <table class="table">\n    <thead>\n        <tr>\n          <th width="2%"></th>\n          <th width="3%"></th>\n          ');
    
      if (!this.forVendor) {
        __out.push('\n            <th class="sortable font-13 font-bold" data-orderby="merchant" data-orderdir="ASC">Merchant</th>\n          ');
      }
    
      __out.push('\n            <th class="font-13 font-bold width-60px"></th>\n            <th></th>\n          ');
    
      if (this.tab === 'unverified') {
        __out.push('\n            <th class="font-13 font-bold">Status</th>\n          ');
      }
    
      __out.push('\n          ');
    
      if (this.isAccountantMember || this.canShowCategorization) {
        __out.push('\n            <th class="sortable font-13 font-bold" data-orderby="category" data-orderdir="ASC">Chart Of Account</th>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.forPaymentAccount) {
        __out.push('\n            <th class="sortable font-13 font-bold" data-orderby="payment_account" data-orderdir="ASC">Payment Account</th>\n          ');
      }
    
      __out.push('\n            <th class="sortable font-13 font-bold receipt-date-column-header" data-orderby="receipt_date" data-orderdir="ASC">Receipt Date</th>\n            <th class="sortable font-13 font-bold" data-orderby="uploader" data-orderdir="ASC">Uploader</th>\n          ');
    
      if (this.forPaymentAccount || this.forVendor) {
        __out.push('\n            <th class="font-13 font-bold">Status</th>\n          ');
      }
    
      __out.push('\n            <th width="50px"></th>\n            <th class="sortable font-13 font-bold receipt-amount-column-header text-center ');
    
      __out.push(__sanitize(this.forPaymentAccount || this.forVendor ? 'width-180px' : 'width-130px'));
    
      __out.push('" data-orderby="amount" data-orderdir="ASC">Amount</th>\n            <th class="font-13 font-bold receipt-unread-messages-count-column-header"></th>\n        </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}