import React from 'react';

import { useSideView } from './hooks';
import Region from './region';

import styles from './styles.module.scss';

interface ISideViewRenderProps {
  size?: 'normal' | 'compact',
}

const SideViewRender = ({
  size = 'normal',
}: ISideViewRenderProps): JSX.Element | null => {
  const { hasContent, views } = useSideView();

  if (!hasContent) return null;

  return (
    <Region size={ size }>
      {
        views.map((view) => (
          <div
            key={ view.id }
            className={ styles.content }
          >
            { view.content }
          </div>
        ))
      }
    </Region>
  );
};

export default React.memo(SideViewRender);
