import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icons/fa_icon';

interface IArrowIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'right' | 'left',
}

const AngleIcon = ({
  variant,
  ...props
}: IArrowIconProps): JSX.Element => {
  return <FaIcon icon={ `angle-${variant}` } { ...props } />;
};

export default AngleIcon;
