import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';

import { budgetsSelectAddValueType, forecastSelectAddValueType, hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table/table';
import TableSection from '@src/components/ui_v2/table_section';

import Body from './body';
import Header from './header';
import { IReportItemValuesCollection, useGenerateReportOtherDetailTableData } from '../../hooks';
import { useReportOtherDetailHideZeroRows } from '../../tableHooks';
import Filter from '../filter';

interface IReportOtherDetailTableProps {
  collection: IReportItemValuesCollection,
  report: IReport,
  configurations: IReportConfiguration[],
  comparerIds: string[],
  itemIdentifier: IReportItem
}

const ReportOtherDetailTable = ({ collection, report, configurations, comparerIds, itemIdentifier }: IReportOtherDetailTableProps) => {
  const budgetsComparison = useRecoilValue(budgetsSelectAddValueType);
  const forecastComparison = useRecoilValue(forecastSelectAddValueType);
  const hideZeroRows = useRecoilValue(hideZeroRowsType);
  const data = useGenerateReportOtherDetailTableData(report, configurations, budgetsComparison?.[0], forecastComparison?.[0]);

  const items = useMemo(() => {
    let id = 1;
    const retData: IItemAccount[] = [];

    for (let i = 0; i < collection.itemAccountValues.length; i += 1) {
      const item = collection.itemAccountValues[i];
      const find = retData.find((f: IItemAccount) => f.chartOfAccountId === item.chartOfAccountId && f.accountingClassId === item.accountingClassId);

      if (!find) {
        item.id = `item-${id}`
        retData.push(item);
        id += 1;
      }
    }

    retData.push({
      id: '-1',
      accountingClassId: -1,
      chartOfAccountId: -1,
      columnId: '-1',
      columnType: 'actual',
      itemId: '-1',
      itemName: 'Total',
      reportDataId: '-1',
      value: 0,
      valueType: '',
      name: 'Total',
      reportMonth: 12,
      reportYear: 2023,
      createdAt: '',
      updatedAt: '',
      show: true,
      search: true
    });

    return retData;
  }, [collection.itemAccountValues]);

  useReportOtherDetailHideZeroRows(
    report,
    hideZeroRows,
    items,
    data.multiMonthColumns,
    data.ptdColumns,
    data.ytdColumns,
    collection.itemAccountValues,
    data.headerTopRowColumns
  )
  

  return (
    <TableSection.Panel
      filter={ (
        <Filter
          comparerIds={ comparerIds }
          configurations={ configurations }
          isShownCustomize={ data.isShownCustomize }
          report={ report }
        />
      ) }
    >
      <Table isRegionScroll sticky query={ collection.query } wrapperClassName="table-region-container">
        <Header
          headerBottomRowColumns={ data.headerBottomRowColumns }
          headerMiddleRowColumns={ data.headerMiddleRowColumns }
          headerTopRowColumns={ data.headerTopRowColumns }
          multiMonthColumns={ data.multiMonthColumns }
          ptdColumns={ data.ptdColumns }
          report={ report }
          ytdColumns={ data.ytdColumns }
        />
        <Body
          headerTopRowColumns={ data.headerTopRowColumns }
          itemAccountValues={ collection.itemAccountValues }
          itemIdentifier={ itemIdentifier }
          itemValues={ collection.itemValues }
          items={ items }
          multiMonthColumns={ data.multiMonthColumns }
          ptdColumns={ data.ptdColumns }
          report={ report }
          ytdColumns={ data.ytdColumns }
        />
      </Table>
    </TableSection.Panel>
  );
};

export default ReportOtherDetailTable;
