import { useQuery } from 'react-query';

import {
  getAccountingFirms,
  IGetAccountingFirmsResponse,
} from '@src/requests/accounting_firms';

const ACCOUNTING_FIRMS_QUERY = 'accounting_firms';

const useGetAccountingFirms = () => {
  return useQuery<IGetAccountingFirmsResponse, Error>(
    [ACCOUNTING_FIRMS_QUERY + window.localStorage.getItem('oauth_access_token')],
    () => getAccountingFirms(),
  );
};

export {
  useGetAccountingFirms,
};
