module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, ref, year;
    
      __out.push('<div class="m-l-40 m-r-40 m-b-20">\n  <div class="item-divider p-t-15 p-b-15">\n    <span class="font-bold font-26 m-t-20 m-b-20">1099 Reports</span>\n    <div class="vendors-container vendors_1099_data_export pull-right hidden">\n      <button class="data-export-btn btn btn-blue font-semi-bold font-16">Data Export</button>\n    </div>\n  </div>\n  <div class="no-vendors-container vendors-1099-container">\n    <img src="');
    
      __out.push(__sanitize(configData.vendor_images['ELIGIBILITY_1099_REPORT']));
    
      __out.push('" class=\'width-200px m-t-80 m-b-60\'>\n    <span class="font-18" style="text-align:center"> Vendors that are being tracked for 1099 <br> generation will be listed here.</span>\n  </div>\n  <div class="vendors-container pull-right display-flex align-items-center m-t-15 hidden">\n    <span class="font-14 m-r-13">Select Year</span>\n    <div class="m-r-32">\n      <select class="selectpicker data-export-year-select" name="year" title="Select year">\n        ');
    
      for (year = i = ref = this.year; i > 2013; year = i += -1) {
        __out.push('\n        <option value="');
        __out.push(__sanitize(year));
        __out.push('" ');
        __out.push(__sanitize(year === this.year ? 'selected' : ''));
        __out.push('>');
        __out.push(__sanitize(year));
        __out.push('</option>\n        ');
      }
    
      __out.push('\n      </select>\n    </div>\n    <div class="filter-link inline-block">\n      <a class="pointer clear-filter disabled">Clear Filters<span class="filtered-count-panel hidden"></span></a>\n    </div>\n  </div>\n  <div class="vendors-container payment-table-wrapper m-t-65 hidden">\n    <div class="payment-filters vendor-filters">\n      <div class="filter-group width-25-percent">\n        <input type="text" class="form-control filter-name" placeholder="Name">\n      </div>\n      <div class="filter-group width-25-percent vendor-filter-legal-name">\n        <input type="text" class="form-control filter-legal-name" placeholder="Legal name" />\n      </div>\n      <div class="filter-group width-25-percent vendor-filter-email">\n        <input type="text" class="form-control filter-email" placeholder="Email" />\n      </div>\n      <div class="filter-group width-25-percent vendor-filter-zero-total-amount-hidden checkbox pointer checkbox-primary inline-block m-l-20 m-r-10 m-b-5">\n        <input class="pointer" type="checkbox" id="checkbox-hide-zero" ');
    
      __out.push(__sanitize(this.isZeroTotalAmountsHidden ? 'checked' : ''));
    
      __out.push('>\n        <label for="checkbox-hide-zero">Hide Vendors with $0 Total Amount</label>\n      </div>\n    </div>\n    <div id="eligibility-vendor-list-region"></div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}