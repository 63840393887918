import { array, number, object } from 'yup';

import { TAmount, TID } from '@src/types/common';

interface ICategorySplit {
  accountingClassId?: TID | null,
  accountingClassName?: string | null,
  amount?: TAmount | null,
  businessId?: TID | null,
  businessName?: string | null,
  chartOfAccountId?: TID | null,
  chartOfAccountName?: string | null,
  memo?: string | null,
  percentage?: number | null,
}

interface ICategorySplitsData {
  adjustmentAmount: TAmount,
  splits: ICategorySplit[],
  totalAmount: TAmount | null,
  differenceAmount: TAmount | null,
}

const defaultCategorySplit = (businessId: TID) => {
  return {
    businessId,
  };
};

const categorySplitsValidation = object({
  splits: array(object({
    amount: number().label('Amount')
      .transform((v) => (Number.isNaN(v) || v === 0 ? undefined : v))
      .required(),
    chartOfAccountId: number().label('Chart of Account').nullable().required(),
    businessId:       number().label('Business').nullable().required(),
  })),
  differenceAmount: number()
    .label('Difference')
    .transform((v) => (Number.isNaN(v) ? undefined : v))
    .test(
      'difference',
      'Difference must be 0',
      (v) => v === 0,
    ),
});

export {
  ICategorySplit,
  ICategorySplitsData,
  categorySplitsValidation,
  defaultCategorySplit,
};
