import React, { useMemo } from 'react';

import { flatten } from 'lodash';
import omit from 'lodash/omit';

import { useGetReportServiceQBOLineItemDetailsQuery } from '@src/hooks/queries/report_service/report_service_qbo_line_item_details';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import QueryStatus from '@src/components/utils/query_status';

import List from './list';

interface IMultiEntityItemValueDetailsProps {
  itemIdentifier: string,
  chartOfAccountId: TID,
  from: string,
  to: string,
  accountingClassId: TID | null,
  reportId: string,
  business: IBusiness,
}

const MultiEntityItemValueDetails = ({
  itemIdentifier,
  chartOfAccountId,
  from,
  to,
  accountingClassId,
  reportId,
  business,
}: IMultiEntityItemValueDetailsProps): JSX.Element => {
  const params = {
    reportId,
    itemIdentifier,
    chartOfAccountId,
    from,
    to,
    accountingClassId,
  };
  const queryParams = !accountingClassId ? omit(params, 'accountingClassId') : params;
  const valuesQuery = useGetReportServiceQBOLineItemDetailsQuery(queryParams);

  const lineItemDetailsPages = useMemo(() => {
    return valuesQuery.data?.pages || [];
  }, [valuesQuery.data?.pages]);

  const lineItemDetails = flatten(lineItemDetailsPages.map((p) => p.collection));

  return (
    <>
      <QueryStatus query={ valuesQuery } />
      <List
        business={ business }
        lineItemDetails={ lineItemDetails }
        query={ valuesQuery }
      />
    </>
  );
};

export {
  IMultiEntityItemValueDetailsProps,
  MultiEntityItemValueDetails as default,
};
