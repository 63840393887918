import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateMetric } from '@src/hooks/queries/metrics';
import { TDate } from '@src/types/common';
import { IMetric } from '@src/types/metrics';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './change_start_date_form';

interface IChangeStartDateProps extends IUseModalProps {
  metric: IMetric
}

const ChangeStartDateModal = ({
  metric,
  isOpen,
  onCancel,
  onDone,
}: IChangeStartDateProps) => {
  const [startDate, setStartDate] = useState<TDate | undefined>(undefined);
  const updateStartDate = useUpdateMetric();
  const business = useBusinessContext();
  const { mutate } = updateStartDate;

  const handleSubmit = useCallback(() => {
    if (!startDate) return;

    mutate(
      {
        startDate,
        id:         metric.id,
        businessId: business.id,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [mutate, onDone, startDate, metric, business]);

  return (
    <>
      <MutationStatus mutation={ updateStartDate } />
      <Modal.Standard
        proceedTitle="Done"
        show={ isOpen }
        title="Change Start Date"
        onCancel={ onCancel }
        onProceed={ handleSubmit }
      >
        {() => (
          <Form metric={ metric } onChangedStartDate={ setStartDate } />
        )}
      </Modal.Standard>
    </>
  );
};

const useChangeStartDateModal = makeUseModal(ChangeStartDateModal);

export {
  IChangeStartDateProps,
  useChangeStartDateModal,
  ChangeStartDateModal as default,
};
