import React from 'react';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { IExpenseReportsTrashCollection } from './hooks';
import Table from './table';

import styles from './styles.module.scss';

interface IExpenseReportsTrashListProps {
  collection: IExpenseReportsTrashCollection,
}

const ExpenseReportsTrashList = ({
  collection,
}: IExpenseReportsTrashListProps) => {
  return (
    <Section.Provider section={ collection.section }>
      <TableSection className={ styles['trash-table-section'] }>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(ExpenseReportsTrashList);
