import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  createExportPortfolioReportConfig,
  createExportReportConfig,
  getExportPortfolioReportConfig,
  getExportReportConfig,
  getPortfolioReportConfigurations,
  getReportConfigurations,
  updateExportPortfolioReportConfig,
  updateExportReportConfig,
} from '@src/requests/report_service/report_configurations';
import {
  IExportPortfolioReportConfiguration,
  IExportReportConfiguration,
} from '@src/types/data_exports';
import {
  IPortfolioReportExportConfigParams,
  IReportConfiguration,
  IReportExportConfigParams,
} from '@src/types/report_service/report_configurations';

/** Single Report Configuration */

const useGetReportConfigurations = () => {
  return useQuery<IReportConfiguration[], Error>(
    [QueryKey.reportConfigurations],
    getReportConfigurations,
    {
      staleTime: Infinity,
    },
  );
};

const useCreateReportExportConfiguration = () => {
  return useMutation<IExportReportConfiguration, Error, IReportExportConfigParams>(createExportReportConfig);
};

const useUpdateReportExportConfiguration = () => {
  return useMutation<IExportReportConfiguration, Error, IReportExportConfigParams>(updateExportReportConfig);
};

const useGetReportExportConfiguration = (businessId: number) => {
  return useQuery<IExportReportConfiguration, Error>(
    [QueryKey.reportExportConfig, { businessId }],
    () => getExportReportConfig(businessId),
    {
      enabled: !!businessId,
    },
  );
};

/** Multiple Report Configuration */

const useGetPortfolioReportConfigurations = () => {
  return useQuery<IReportConfiguration[], Error>(
    [QueryKey.portfolioReportConfigurations],
    getPortfolioReportConfigurations,
    {
      staleTime: Infinity,
    },
  );
};

const useCreatePortfolioReportExportConfiguration = () => {
  return useMutation<IExportPortfolioReportConfiguration, Error, IPortfolioReportExportConfigParams>(createExportPortfolioReportConfig);
};

const useUpdatePortfolioReportExportConfiguration = () => {
  return useMutation<IExportPortfolioReportConfiguration, Error, IPortfolioReportExportConfigParams>(updateExportPortfolioReportConfig);
};

const useGetPortfolioReportExportConfiguration = () => {
  return useQuery<IExportPortfolioReportConfiguration, Error>(
    [QueryKey.portfolioReportExportConfig],
    () => getExportPortfolioReportConfig(),
  );
};

export {
  useGetReportConfigurations,
  useGetPortfolioReportConfigurations,
  useCreateReportExportConfiguration,
  useUpdateReportExportConfiguration,
  useGetReportExportConfiguration,
  useCreatePortfolioReportExportConfiguration,
  useUpdatePortfolioReportExportConfiguration,
  useGetPortfolioReportExportConfiguration,
};
