import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { IInputCommonProps } from './types';

import styles from './styles.module.scss';

interface IStaticInputProps extends IInputCommonProps, React.ComponentPropsWithoutRef<'span'> {
  value?: string | number;
}

const StaticInput = forwardRef<HTMLSpanElement, IStaticInputProps>(({
  children,
  className,
  size = 'normal',
  value,
  ...props
}: IStaticInputProps, ref): JSX.Element => {
  const classes = classNames(
    styles['static-input'],
    className,
    { [styles['input-compact']]: size === 'compact' },
  );

  return (
    <span
      ref={ ref }
      className={ classes }
      { ...props }
    >
      { value || children }
    </span>
  );
});

StaticInput.displayName = 'StaticInput';

export default StaticInput;
