module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var bankAccount, creditCard, i, j, k, l, len, len1, len2, len3, ref, ref1, ref2, ref3;
    
      if (!this.subscription) {
        __out.push('\n<div class="billing-not-activated client-plan-select-wrapper">\n  <div class="billing-method-body" id="billing-stripe-card-region">\n    <p class="font-14">Select the billing account</p>\n    <div class="billing-to-business-section m-t-20">\n      <label class="billing-info-label">Who should be billed for this account?</label>\n      <div class="billing-select-business-section inline-block">\n        <div>\n          <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n            <input type="radio" id="radioBillToAccounting" name="bill_business" class="pointer radio-bill-from radio-bill-to-business" value="accounting_firm" ');
        if (this.billFrom === 'accounting_firm') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' />\n            <label for="radioBillToAccounting">');
        __out.push(__sanitize(this.accountingFirm.get('name')));
        __out.push(' (Accounting Firm)</label>\n          </div>\n        </div>\n        <div class="m-t-10">\n          <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n            <input type="radio" id="radioBillToBusiness" name="bill_business" class="pointer radio-bill-from radio-bill-to-business" value="business" ');
        if (this.billFrom === 'business') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' />\n            <label for="radioBillToBusiness">');
        __out.push(__sanitize(this.name));
        __out.push(' (Client)</label>\n          </div>\n        </div>\n      </div>\n    </div>\n    ');
        if (this.billFrom === 'accounting_firm') {
          __out.push('\n    <div class="accounting-firm-credit-cards-section m-t-28">\n      <label class="billing-info-label m-t-8">Select Payment Method</label>\n      <select class="selectpicker credit-card-list" name="accounting_firm_credit_card" title="Select Payment Method" ');
          if (this.billFrom === 'business' || this.billFrom === '') {
            __out.push(__sanitize('disabled'));
          }
          __out.push('>\n        ');
          ref = this.accountingFirmCards;
          for (i = 0, len = ref.length; i < len; i++) {
            creditCard = ref[i];
            __out.push('\n        <option class="credit-card-item" value="');
            __out.push(__sanitize(creditCard.id));
            __out.push('" ');
            if (this.creditCard && this.creditCard.id === creditCard.id) {
              __out.push(__sanitize('selected'));
            }
            __out.push(' data-content="<div class=\'credit-card-icon-wrapper\'><span class=\'icon icon-credit-card-account\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span></span></div><p class=\'credit-card-no\'>**** **** **** ');
            __out.push(__sanitize(creditCard.last4));
            __out.push('</p>">**** **** **** ');
            __out.push(__sanitize(creditCard.last4));
            __out.push('</option>\n        ');
          }
          __out.push('\n        ');
          ref1 = this.accountingFirmBankAccounts;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            bankAccount = ref1[j];
            __out.push('\n        <option class="credit-card-item" value="');
            __out.push(__sanitize(bankAccount.id));
            __out.push('" ');
            if (this.bankAccount && this.bankAccount.id === bankAccount.id) {
              __out.push(__sanitize('selected'));
            }
            __out.push(' data-content="<div class=\'credit-card-icon-wrapper\'><span class=\'icon-financial-institution\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span></div><p class=\'credit-card-no\'>***** ');
            __out.push(__sanitize(bankAccount.last4));
            __out.push('</p>">***** ');
            __out.push(__sanitize(bankAccount.last4));
            __out.push('</option>\n        ');
          }
          __out.push('\n        <option class="credit-card-item add-accounting-firm-card" value="-1" data-content="<span class=\'icon icon-plus-circle\'></span><p class=\'credit-card-no\'>Add New Payment Method</p>">Add New Payment Method</option>\n      </select>\n    </div>\n    ');
        }
        __out.push('\n    ');
        if (this.billFrom !== '') {
          __out.push('\n    <div>\n      <label class="billing-info-label"></label>\n      ');
          if (this.billFrom === 'business') {
            __out.push('\n      <ul class="credit-cards-list inline-block width-50-percent">\n      ');
            ref2 = this.businessCards;
            for (k = 0, len2 = ref2.length; k < len2; k++) {
              creditCard = ref2[k];
              __out.push('\n      <li class="credit-card-item">\n        <a class="credit-card-item-link" data-id="');
              __out.push(__sanitize(creditCard.id));
              __out.push('">\n          <div class="credit-card-selection-status ');
              __out.push(__sanitize(this.creditCard && creditCard.id === this.creditCard.id ? 'selected' : ''));
              __out.push('" data-id="');
              __out.push(__sanitize(creditCard.id));
              __out.push('"></div>\n          <div class="credit-card-icon-wrapper">\n            <span class="icon icon-credit-card-account">\n              <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n            </span>\n          </div>\n          <p class="credit-card-no">**** **** **** ');
              __out.push(__sanitize(creditCard.last4));
              __out.push('</p>\n        </a>\n      </li>\n      ');
            }
            __out.push('\n      ');
            ref3 = this.businessBankAccounts;
            for (l = 0, len3 = ref3.length; l < len3; l++) {
              bankAccount = ref3[l];
              __out.push('\n      <li class="credit-card-item">\n        <a class="bank-account-item-link" data-id="');
              __out.push(__sanitize(bankAccount.id));
              __out.push('">\n          <div class="credit-card-selection-status ');
              __out.push(__sanitize(this.bankAccount && bankAccount.id === this.bankAccount.id ? 'selected' : ''));
              __out.push('" data-id="');
              __out.push(__sanitize(bankAccount.id));
              __out.push('"></div>\n          <div class="credit-card-icon-wrapper">\n            <span class="icon-financial-institution"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n          </div>\n          <p class="credit-card-no">***** ');
              __out.push(__sanitize(bankAccount.last4));
              __out.push('</p>\n        </a>\n      </li>\n      ');
            }
            __out.push('\n      <li class="credit-card-item add-credit-card-item">\n        <a class="add-credit-card-link">Add New Payment Method</a>\n      </li>\n      ');
          }
          __out.push('\n    </div>\n    ');
        }
        __out.push('\n  </div>\n  <button class="btn btn-blue font-16 font-semi-bold m-t-40 submit-js" ');
        if (!this.paymentMethodChanged || this.active_subscription) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>Save</button>\n</div>\n');
      } else {
        __out.push('\n<div class="display-flex">\n  <div class="billing-method-body width-80-percent">\n    <p class="font-14">Select the billing account</p>\n    <div class="p-l-20">\n      <div class="accounting-firm-credit-cards-section width-80-percent p-t-15">\n        <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n          <input type="radio" name="bill_from" class="pointer radio-bill-from radio-bill-to-accounting-firm" value="accounting_firm" ');
        if (this.billFrom === 'accounting_firm') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' ');
        if (this.billFrom === 'business') {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n          <label for="radioBillToAccountingFirm">Accounting Firm: ');
        __out.push(__sanitize(this.accountingFirm.get('display_name')));
        __out.push('</label>\n        </div>\n        ');
        if (this.billFrom === 'accounting_firm') {
          __out.push('\n        <div class="credit-cards-wrapper p-r-20">\n          ');
          if (this.creditCard) {
            __out.push('\n          <span class="pull-left m-t-15">**** **** **** ');
            __out.push(__sanitize(this.creditCard.last4));
            __out.push('</span>\n          ');
          } else if (this.bankAccount) {
            __out.push('\n          <span class="pull-left m-t-15">***** ');
            __out.push(__sanitize(this.bankAccount.last4));
            __out.push('</span>\n          ');
          }
          __out.push('\n          <a class="edit-accounting-firm-card-link pull-right">Edit</a>\n        </div>\n        ');
        }
        __out.push('\n      </div>\n      <div class="business-credit-cards-section width-80-percent display-table p-t-20">\n        ');
        if (this.billFrom === 'business') {
          __out.push('\n        <p class="switch-card-description font-bold">The client account is being billed. If you want to switch to the accounting firm billing, please contact customer support.</p>\n        ');
        } else {
          __out.push('\n        <p class="switch-card-description font-bold">The accounting firm account is being billed. Billing method can only be changed by the accounting firm.</p>\n        ');
        }
        __out.push('\n        <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n          <input type="radio" name="bill_from" class="pointer radio-bill-from radio-bill-to-business" value="business" ');
        if (this.billFrom === 'business') {
          __out.push(__sanitize('checked'));
        }
        __out.push(' ');
        if (this.billFrom === 'accounting_firm') {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n          <label for="radioBillToBusiness">Client: ');
        __out.push(__sanitize(this.display_name));
        __out.push('</label>\n        </div>\n        ');
        if (this.billFrom === 'business') {
          __out.push('\n        <div class="credit-cards-wrapper p-r-20">\n          ');
          if (this.creditCard) {
            __out.push('\n          <span class="pull-left m-t-15">**** **** **** ');
            __out.push(__sanitize(this.creditCard.last4));
            __out.push('</span>\n          ');
          } else if (this.bankAccount) {
            __out.push('\n          <span class="pull-left m-t-15">***** ');
            __out.push(__sanitize(this.bankAccount.last4));
            __out.push('</span>\n          ');
          }
          __out.push('\n          <a class="edit-business-card-link pull-right">Edit</a>\n        </div>\n        ');
        }
        __out.push('\n      </div>\n    </div>\n  </div>\n  <div class="billing-receipts-download-region m-t-95"></div>\n</div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}