import React from 'react';

import classNames from 'classnames';

import { TBankAccountReconciliationItemStatus } from '@src/types/bank_account_reconciliations';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';

import styles from '../styles.module.scss';

interface UnacknowledgeButtonProps {
  onDone: (data: any) => void,
}

const UnacknowledgeButton = ({ onDone }: UnacknowledgeButtonProps) => {
  const unacknowledgeModal = useConfirmDeleteModal({
    onDone: () => onDone('created' as TBankAccountReconciliationItemStatus),
  });

  const toggleButton = (
    <Dropdown.ToggleButton className={ classNames(styles['acknowledged-toggle-button'], styles['text-arrow-down'], 'acknowledge-button') }>
      Acknowledged
    </Dropdown.ToggleButton>
  );
  return (
    <>
      <unacknowledgeModal.Component
        confirmStyle="primary"
        confirmTitle="Confirm"
        text="Are you sure you want to retract this acknowledgement?"
        title="Retract Acknowledgement"
        { ...unacknowledgeModal.props }
      />
      <ActionsDropdown toggleButton={ toggleButton }>
        <ActionsDropdown.Action onSelect={ unacknowledgeModal.open }>
          Retract acknowledgement
        </ActionsDropdown.Action>
      </ActionsDropdown>
    </>
  );
};

export default React.memo(UnacknowledgeButton);
