const getDefaultDataProperty = (activeThreadId: string | undefined) => {
  // this is to be changed when the actual BE calls are added
  const tempData = { user_id: String(window.Docyt.currentAdvisor.get('id')), chat_thread_id: '' };
  if (activeThreadId) {
    tempData.chat_thread_id = activeThreadId;
  }
  const data = JSON.stringify(tempData);
  return data;
};

export {
  getDefaultDataProperty,
};
