import React from 'react';

import { TBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

interface ReopenButtonProps {
  onDone: (data: any) => void,
}

const ReopenButton = ({ onDone }: ReopenButtonProps) => {
  const reopenModal = useConfirmDeleteModal({
    onDone: () => onDone('in-progress' as TBookkeepingStatus),
  });

  return (
    <>
      <reopenModal.Component
        confirmStyle="primary"
        confirmTitle="Re-open"
        text="Are you sure you want to re-open month?"
        title="Re-open Month"
        { ...reopenModal.props }
      />
      <ActionsDropdown>
        <ActionsDropdown.Action onSelect={ reopenModal.open }>
          Re-open Month
        </ActionsDropdown.Action>
      </ActionsDropdown>
    </>
  );
};

export default React.memo(ReopenButton);
