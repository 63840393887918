import { TID } from '@src/types/common';
import { IExportPortfolioReportConfiguration } from '@src/types/data_exports';
import {
  IPortfolioReportExportConfigParams,
  IReportConfiguration,
  IReportExportConfigParams,
} from '@src/types/report_service/report_configurations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { IExportReportConfiguration } from './custom_reports';
import { apiGet, apiPatch, apiPost } from '../helpers';

/** Single Report Configuration */
const getReportConfigurations = (): Promise<IReportConfiguration[]> => {
  return apiGet('/reports/api/v1/configurations/report_configuration')
    .then((data) => camelizeKeys(data.configurations) as IReportConfiguration[]);
};

const createExportReportConfig = (params: IReportExportConfigParams):
  Promise<IExportReportConfiguration> => {
  return apiPost(
    '/reports/api/v1/export_report_configurations',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IExportReportConfiguration);
};

const updateExportReportConfig = (params: IReportExportConfigParams):
  Promise<IExportReportConfiguration> => {
  return apiPatch(
    `/reports/api/v1/export_report_configurations/${params.configId}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IExportReportConfiguration);
};

const getExportReportConfig = (businessId: TID): Promise<IExportReportConfiguration> => {
  return apiPost(
    '/reports/api/v1/export_report_configurations/search',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data) as IExportReportConfiguration);
};

/** Portfolio Report Configuration */
const getPortfolioReportConfigurations = (): Promise<IReportConfiguration[]> => {
  return apiGet('/reports/api/v1/configurations/multi_business_report_configuration')
    .then((data) => camelizeKeys(data.configurations) as IReportConfiguration[]);
};

const createExportPortfolioReportConfig = (params: IPortfolioReportExportConfigParams):
  Promise<IExportPortfolioReportConfiguration> => {
  return apiPost(
    '/reports/api/v1/multi_business_report_export_configs',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IExportPortfolioReportConfiguration);
};

const updateExportPortfolioReportConfig = (params: IPortfolioReportExportConfigParams):
  Promise<IExportPortfolioReportConfiguration> => {
  return apiPatch(
    `/reports/api/v1/multi_business_report_export_configs/${params.configId}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IExportPortfolioReportConfiguration);
};

const getExportPortfolioReportConfig = (): Promise<IExportPortfolioReportConfiguration> => {
  return apiPost(
    '/reports/api/v1/multi_business_report_export_configs/search',
    underscoreKeys({}),
  ).then((data) => camelizeKeys(data) as IExportPortfolioReportConfiguration);
};

export {
  getReportConfigurations,
  createExportReportConfig,
  updateExportReportConfig,
  getExportReportConfig,
  getPortfolioReportConfigurations,
  createExportPortfolioReportConfig,
  updateExportPortfolioReportConfig,
  getExportPortfolioReportConfig,
};
