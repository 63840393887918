import React from 'react';

import Rules from './rules';

const CompliancePolicy = () => {
  return (
    <Rules />
  );
};

export default React.memo(CompliancePolicy);
