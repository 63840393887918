import React, { forwardRef } from 'react';

import { CalendarIcon } from '@src/components/utils/fa_icons';

import ClearIndicator from '../clear_indicator';
import InputSuffixIcon from '../suffix_icon';

import styles from './styles.module.scss';

interface IDateInnerInput extends React.ComponentPropsWithoutRef<'input'> {
  onClear: () => void;
}

const DateInnerInput = forwardRef<HTMLInputElement, IDateInnerInput>(({
  onClear,
  ...inputProps
}: IDateInnerInput, ref) => (
  <>
    <input ref={ ref } { ...inputProps } />
    {
      inputProps.value ? (
        <ClearIndicator
          className={ styles['date-range-clear-icon'] }
          onClick={ onClear }
        />
      ) : (
        <InputSuffixIcon absolute icon={ <CalendarIcon /> } />
      )
    }
  </>
));

DateInnerInput.displayName = 'DateInnerInput';

export default DateInnerInput;
