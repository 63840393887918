module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="breadcrumb-container">\n  ');
    
      if (this.rcPaymentAccount) {
        __out.push('\n    <div class="breadcrumb">\n      <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('">Banking Accounts</a></span>\n      <div class="client__breadcrumbs-item in-grey-600 font-20"></div>\n      <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/accounts">Accounts</a></span>\n      <div class="client__breadcrumbs-item in-grey-600 font-20"></div>\n      <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/accounts/');
        __out.push(__sanitize(this.rcPaymentAccount.id));
        __out.push('/employee_cards">Employee Cards</a></span>\n      <h3 class="m-t-15 m-b-0">\n        ');
        if (this.rcPaymentAccount.get('account_type') === 'bank') {
          __out.push('\n          <span class="icon icon-bank-account payment-account-primary-icon font-40">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n          </span>\n        ');
        } else {
          __out.push('\n          <span class="icon icon-credit-card-account payment-account-primary-icon font-40">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n          </span>\n        ');
        }
        __out.push('\n        <span class="font-26 font-bold" style="vertical-align: super;">');
        __out.push(__sanitize(this.rcPaymentAccount.get('name')));
        __out.push('</span>\n      </h3>\n    </div>\n  ');
      } else if (this.type) {
        __out.push('\n    <div class="display-flex">\n      <span class="font-26 font-bold">');
        __out.push(__sanitize(this.type ? 'Chart of Accounts' : 'Accounts'));
        __out.push('</span>\n      <ul class="nav sub-header-menu font-semi-bold">\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.type === 'categories' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('?type=categories">All Chart of Accounts</a>\n        </li>\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.type === 'banking_accounts' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('?type=banking_accounts">\n            Bank & Credit Card Accounts\n            ');
        if (this.unMappedBankAccountsCount > 0) {
          __out.push('\n              <span class="icon-unable-to-sync in-red"><span class="path1"></span><span class="path2"></span></span>\n            ');
        }
        __out.push('\n          </a>\n        </li>\n      </ul>\n      <div style="width: 225px;" />\n    </div>\n  ');
      } else {
        __out.push('\n    <div class="display-flex">\n      <span class="font-24 font-bold">Accounts</span>\n      <ul class="nav sub-header-menu font-semi-bold">\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.tab === 'active' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/accounts">ACTIVE</a>\n        </li>\n        <li class="m-r-48 ');
        __out.push(__sanitize(this.tab === 'archived' ? 'active' : ''));
        __out.push('">\n          <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/accounts?archived=true">ARCHIVED</a>\n        </li>\n      </ul>\n      ');
        if (this.tab === 'active') {
          __out.push('\n        <button class="btn btn-blue-on-hover font-16 pull-right re-sync-all-btn">Re-sync All</button>\n        <button class="btn btn-add-document font-23 pull-right add-payment-account-btn"><i class="icon icon-plus"></i></button>\n      ');
        }
        __out.push('\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}