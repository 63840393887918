import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountOwnersResponse {
  accountOwners: any,
}

const getAccountOwners = (rcPaymentAccountId?: number): Promise<IGetAccountOwnersResponse> => {
  return apiGet(
    `/api/v1/reconciliation_payment_accounts/${rcPaymentAccountId}/account_owners`,
    underscoreKeys({ rcPaymentAccountId }),
  ).then((data) => {
    return {
      accountOwners: camelizeKeys(data.account_owners),
    } as IGetAccountOwnersResponse;
  });
};

export {
  getAccountOwners,
  IGetAccountOwnersResponse,
};
