import React, { forwardRef } from 'react';

import AmountInput, { IAmountInputProps } from '../inputs/amount_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface IAmountFieldProps extends
  IAmountInputProps,
  Omit<IFormFieldWrapperProps, 'children'>
{
}

/**
 * Field to input money amount value
 *
 * @visibleName Form.AmountField
 */
const AmountField = forwardRef<HTMLInputElement, IAmountFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: IAmountFieldProps, ref): JSX.Element => {
  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <AmountInput
        ref={ ref }
        { ...props }
      />
    </Wrapper>
  );
});

AmountField.displayName = 'AmountField';

export default AmountField;
