import React, { useMemo } from 'react';

import * as sanitizeHtmlLib from 'sanitize-html';

interface ISantizedHtmlProps<C extends React.ElementType> {
  as?: C,
  html: string,
  options?: sanitizeHtmlLib.IOptions,
}

type TSantizedHtmlProps<C extends React.ElementType> = ISantizedHtmlProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof ISantizedHtmlProps<C>>

const defaultOptions = {
  allowedTags: ['b', 'em', 'strong'],
};

const SantizedHtml = <C extends React.ElementType = 'span'>({
  as: component,
  html,
  options,
  ...props
}: TSantizedHtmlProps<C>): JSX.Element => {
  const Component = component || 'span';

  const opts = useMemo(() => {
    return { ...defaultOptions, ...options };
  }, [options]);

  return (
    <Component
      { ...props }
      dangerouslySetInnerHTML={ { __html: sanitizeHtmlLib(html, opts) } }
    />
  );
};

export default SantizedHtml;
