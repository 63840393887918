import React from 'react';

import MyPracticeDetails from '@src/components/my_practice/details/my_practice_details';
import Root from '@src/components/root';

const MyPracticePage = (): JSX.Element => {
  return (
    <Root>
      <div id="detail-region">
        <MyPracticeDetails />
      </div>
    </Root>
  );
};

export default MyPracticePage;
