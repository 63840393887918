module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">Download Consolidated Report</h4>\n      </div>\n      <div class="modal-body">\n        <div class="report-type-container display-flex-column m-t-48 m-b-8">\n          <div class="display-flex align-items-center p-l-50 p-r-50">\n            <div class="font-16 m-r-20 text-align-right" style="width: 100%;">Select Period:</div>\n            <input type="text" class="form-control date-picker report-start-date" placeholder="Start Date" autocomplete="off" />\n            <span class="m-r-10" />\n            <input type="text" class="form-control date-picker report-end-date" placeholder="End Date" autocomplete="off" />\n          </div>\n          <div class="display-flex align-items-center p-l-50 p-r-50 m-t-25">\n            <div class="font-16 m-r-20 text-align-right" style="width: 42%;">Industry:</div>\n            <span class="font-bold" style="width: 100%">Hotel</span>\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class="cancel-link cancelDownload" role="button">Cancel</a>\n        <button type="button" class="btn btn-blue continue-btn">Continue</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}