import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const HandRightAlt = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="hand-right-alt"
    />
  );
};

HandRightAlt.displayName = 'HandRightAlt';

export default HandRightAlt;
