const getFilenameExtension = (fileName: string | null): string | null => {
  if (!fileName) return null;

  return fileName.substring(fileName.lastIndexOf('.') + 1);
};

const getFilenameWithoutExtension = (fileName: string | null | undefined): string | undefined => {
  if (!fileName) return undefined;

  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

const truncatedFileName = (fileName: string, maxLength: number) => {
  if (!fileName) return undefined;

  const extention = getFilenameExtension(fileName);
  const fileNameWithoutExtension = getFilenameWithoutExtension(fileName);

  return `${fileNameWithoutExtension?.slice(0, maxLength)}...${extention}`;
};

export {
  getFilenameExtension,
  getFilenameWithoutExtension,
  truncatedFileName,
};
