import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IReport } from '@src/types/report_service/report';

import Modal from '@src/components/ui/modal';

interface IDeleteReportProps extends IUseModalProps {
  report: IReport,
}

const DeleteReportModal = ({
  report,
  isOpen,
  onCancel,
  onDone,
}: IDeleteReportProps): JSX.Element => {
  return (
    <Modal.Standard
      danger
      proceedTitle="Proceed"
      show={ isOpen }
      title="Delete Report"
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => (
        <div className="text-center font-16">
          <b>{ report.name }</b>
          &nbsp;will be permanently deleted and removed from any portfolio report.
          <br />
          Are you sure you want to continue?
        </div>
      )}
    </Modal.Standard>
  );
};

const useDeleteReportModal = makeUseModal(DeleteReportModal);

export {
  IDeleteReportProps,
  useDeleteReportModal,
  DeleteReportModal as default,
};
