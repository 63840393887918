import React from 'react';

import { useTCDContext } from '@src/hooks/contexts/transation_context_data_context';
import { useGetTransactionAIContext } from '@src/hooks/queries/bank_account_reconciliations';
import { ITransactionAIContextResponse } from '@src/requests/bank_account_reconciliations';
import { TransactionContextData } from '@src/types/bank_account_reconciliations';

import useTransactionContext from './use_transaction_context';

export const useAIContextLogic = () => {
  const { data: tcData } = useTCDContext();
  const fetchFunction = useGetTransactionAIContext;

  const onSuccess = (
    response: ITransactionAIContextResponse,
    setData: React.Dispatch<React.SetStateAction<TransactionContextData>>,
  ) => {
    setData((pre: TransactionContextData) => ({ ...pre, aiContext: response.context }));
    sessionStorage.setItem(`vtc_${tcData.id}_ac`, response.context);
  };

  const getContextData = (data: TransactionContextData) => ({
    context:       data.aiContext,
    requestParams: {
      transactionId:   data.id,
      businessId:      data.businessId,
      transactionDesc: data.transactionDesc,
    },
  });

  const {
    expand,
    openExpand,
    closeExpand,
    isLoading,
    error,
  } = useTransactionContext(fetchFunction, onSuccess, getContextData);
  const { aiContext } = tcData;

  return { aiContext, expand, openExpand, closeExpand, isLoading, error };
};
