import React, { useCallback, useState } from 'react';

import {
  ICreateBankAccountFromPlaidParams,
  ICreateCreditCardParams,
} from '@src/requests/billing_payment_methods';
import { IBillingBankAccount, IBillingCreditCard } from '@src/types/billing_payment_methods';
import { ISelfOnboardingBusiness } from '@src/types/self_onboarding_invitation';

import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';
import { BankAccountIcon, CreditCardIcon } from '@src/components/utils/icomoon';

import NewAccount from './new_account';

import styles from './styles.module.scss';

interface IBillingInfoItemProps {
  business: ISelfOnboardingBusiness,
  bankAccount?: IBillingBankAccount,
  creditCard?: IBillingCreditCard,
  onAddNewAccount: (newAccount: ICreateBankAccountFromPlaidParams | ICreateCreditCardParams) => void,
}

const BillingInfoListItem = ({
  business,
  bankAccount,
  creditCard,
  onAddNewAccount,
}: IBillingInfoItemProps): JSX.Element => {
  const [isAddAccount, setIsAddAccount] = useState<boolean>(false);
  const [newAccount, setNewAccount] =
    useState<ICreateBankAccountFromPlaidParams | ICreateCreditCardParams | undefined>(undefined);
  const handleAddNewAccount = useCallback((account) => {
    setIsAddAccount(false);
    onAddNewAccount(account);
    setNewAccount(account);
  }, [business.id, onAddNewAccount, setNewAccount]);
  const last4 = bankAccount?.last4 || creditCard?.last4 || newAccount?.last4;

  return (
    <>
      <Table.Row>
        <Table.Cell>
          { business.displayName }
        </Table.Cell>
        <Table.Cell>
          <div className={ styles['billing-action'] }>
            {
              last4 && (
                <>
                  {
                    (bankAccount || newAccount && ('plaidPublicToken' in newAccount)) ? (
                      <BankAccountIcon fontSize={ 18 } variant="normal" />
                    ) : (
                      <CreditCardIcon fontSize={ 18 } variant="normal" />
                    )
                  }
                  <span className="m-l-10">
                    **** **** ****
                    { ' ' }
                    { last4 }
                  </span>
                </>
              )
            }
            <Button
              className="action-column"
              variant="link"
              onClick={ () => setIsAddAccount(true) }
            >
              { last4 ? 'Edit' : 'Add Billing Info' }
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
      {
        isAddAccount && (
          <NewAccount
            business={ business }
            onAddNewAccount={ handleAddNewAccount }
            onCancel={ () => setIsAddAccount(false) }
          />
        )
      }
    </>
  );
};

export default BillingInfoListItem;
