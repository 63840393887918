import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IUpdateCustomReportServiceParams,
  ICustomReportServiceResponse,
  getCustomReportService,
  putUpdateCustomReportService,
} from '@src/requests/report_service/custom_report_service';
import { ICustomReportService } from '@src/types/report_service/custom_report_service';

const useGetCustomReportService = (businessId: number) => {
  return useQuery<ICustomReportService, Error>(
    [QueryKey.customReportService, businessId],
    () => getCustomReportService(businessId),
    {
      staleTime: Infinity,
      enabled:   !!businessId,
    },
  );
};

const useUpdateCustomReportService = () => {
  const queryClient = useQueryClient();

  return useMutation<ICustomReportServiceResponse, Error, IUpdateCustomReportServiceParams>(
    putUpdateCustomReportService,
    {
      onSettled: () => {
        return queryClient.invalidateQueries(QueryKey.customReportService);
      },
    },
  );
};

export {
  useGetCustomReportService,
  useUpdateCustomReportService,
};
