import React from 'react';

import {
  components,
  GroupBase,
  StylesConfig,
} from 'react-select';
import Select, { AsyncProps } from 'react-select/async';

import { uiStyleProps, IUIStyleProps } from '@src/utils/ui_style_helpers';

import {
  CommonSelectProps,
  ReactSelectControl,
  ReactSelectDropdownIndicator,
  ReactSelectStyles, WideMenuStyles,
} from './helpers';

interface IAsyncSelectInputProps<TOption, TIsMulti extends boolean = false> extends
  AsyncProps<TOption, TIsMulti, GroupBase<TOption>>,
  IUIStyleProps,
  CommonSelectProps
{}

const AsyncSelectInput = <TOption, TIsMulti extends boolean = false>({
  components: componentsProp = {},
  hideIndicators,
  wideMenu,
  ...props
}: IAsyncSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  return (
    <Select
      className={ classes }
      components={ {
        Control:             ReactSelectControl,
        DropdownIndicator:   ReactSelectDropdownIndicator,
        IndicatorsContainer: hideIndicators ? () => null : components.IndicatorsContainer,
        ...componentsProp,
      } }
      styles={ {
        ...ReactSelectStyles as StylesConfig<TOption, TIsMulti>,
        ...(wideMenu ? WideMenuStyles : {}) as StylesConfig<TOption, TIsMulti>,
      } }
      { ...selectProps }
    />
  );
};

export {
  IAsyncSelectInputProps,
  AsyncSelectInput as default,
};
