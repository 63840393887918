import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown';

interface IActionsDropdownActionProps extends IUIStyleProps {
  /** Action icon. */
  icon?: JSX.Element,
  /** If passed then action will work as usual link and redirects user to provided url. */
  href?: string,
  /** Action name (title). */
  name: string,
  /** Callback to be fired when user selects this action. */
  onClick?: () => void,
}

/**
 * Action item for `ActionsDropdown` component.
 * @visibleName ActionsDropdown.Action
 */
const ActionsDropdownAction = ({
  icon,
  href,
  onClick,
  name,
  ...props
}: IActionsDropdownActionProps): JSX.Element => {
  const [classes] = uiStyleProps('display-flex-important align-items-center', props);
  return (
    <Dropdown.Item className={ classes } href={ href } onSelect={ onClick }>
      {
        icon && (
          <div className="display-flex-important align-items-center width-20px m-r-18">
            {icon}
          </div>
        )
      }
      { name }
    </Dropdown.Item>
  );
};

export default ActionsDropdownAction;
