import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetDataExports } from '@src/hooks/queries/data_exports';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { TDataExportsSortColumn } from '@src/types/data_exports';
import { ISortingParams } from '@src/types/sorting';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import DataExportList from './data_export_list';
import { DataExportFilter } from '../filter';
import { IDataExportsFilter } from '../filter/types';
import { DataExportHeader } from '../header';

const DefaultSorting: ISortingParams<TDataExportsSortColumn> = {
  orderColumn:    'created_at',
  orderDirection: 'desc',
};

const DataExportDetails = (): JSX.Element => {
  const filter = useFilter<IDataExportsFilter>({});
  const sorting = useSorting<TDataExportsSortColumn>({ defaultSorting: DefaultSorting });

  const dataExportQuery = useGetDataExports({ filter: filter.data,
    ...sorting.data });

  const dataExports = useMemo(() => {
    const dataExportPages = dataExportQuery.data?.pages || [];
    return flatten(dataExportPages.map((p) => p.collection));
  }, [dataExportQuery.data?.pages]);

  return (
    <ItemsSelectorProvider allItems={ dataExports }>
      <div className="m-b-20">
        <DataExportHeader />
        <div className="data-export-table-wrapper">
          <DataExportFilter filter={ filter } />
          <DataExportList
            dataExports={ dataExports }
            query={ dataExportQuery }
            sorting={ sorting }
          />
        </div>
      </div>
    </ItemsSelectorProvider>
  );
};

export {
  DataExportDetails as default,
};
