import { IAccountingClass } from '@src/types/accounting_class';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountingClassesParams {
  businessId: TID,
  chartOfAccountId?: TID,
}

interface IGetAccountingClassesResponse {
  askClass: boolean,
  collection: IAccountingClass[],
}

const getAccountingClasses = ({
  businessId,
  ...params
}: IGetAccountingClassesParams): Promise<IGetAccountingClassesResponse> => {
  return apiGet(
    `/api/v1/businesses/${businessId}/accounting_classes`,
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      askClass:   cdata.askClass,
      collection: cdata.accountingClasses as IAccountingClass[],
    };
  });
};

interface IGetWithMappingAccountingClassesResponse {
  accountingClasses: IAccountingClass[],
}

interface IGetWithMappingAccountingClassesParams {
  businessId: TID,
}

const getWithMappingAccountingClasses = (
  params: IGetWithMappingAccountingClassesParams,
): Promise<IGetWithMappingAccountingClassesResponse> => {
  return apiGet(
    `/api/v1/businesses/${params.businessId}/accounting_classes/with_mapping`,
  ).then((data) => {
    return camelizeKeys(data) as IGetWithMappingAccountingClassesResponse;
  });
};

export {
  IGetAccountingClassesParams,
  IGetAccountingClassesResponse,
  IGetWithMappingAccountingClassesParams,
  IGetWithMappingAccountingClassesResponse,
  getAccountingClasses,
  getWithMappingAccountingClasses,
};
