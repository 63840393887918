/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react';

import classNames from 'classnames';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';

import ClearIndicator from '../clear_indicator';
import { TOption } from '../types';

import styles from '../styles.module.scss';


interface IInnerInputProps {
  placeholder?: string,
  value:        TOption[],
  disabled?:    boolean,
  onClear:      () => void,
}

const InnerInput = ({
  placeholder,
  value,
  disabled,
  onClear,
}: IInnerInputProps) => {
  const {
    isVisible,
    showOnClickAttributes,
    showWithKeyboardAttributes,
  } = useDropdown() || {};

  const inputClasses = classNames(
    value.length > 0 ? styles['dropdown-input-value'] : styles['dropdown-input-placeholder'],
  );

  return (
    <>
      <div
        aria-expanded={ isVisible ? 'true' : 'false' }
        aria-haspopup="true"
        className={ inputClasses }
        role="button"
        { ...showOnClickAttributes }
        { ...showWithKeyboardAttributes }
      >
        { value.length > 0 ? `${value.length} Selected` : placeholder }
      </div>
      {
        value.length > 0 && !disabled 
        ? (
          <ClearIndicator onClick={ onClear } />
        ) 
        : (
          <Dropdown.InputIndicator tabIndex={ -1 } />
        )
      }
    </>

  );
}

export default InnerInput;
