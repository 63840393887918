import React from 'react';

import { TID } from '@src/types/common';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Header from './header';
import { useExpenseReportsTrashCollection } from './hooks';
import List from './list';

import styles from './styles.module.scss';

interface IExpenseReportsTrashProps {
  businessId: TID,
}

const ExpenseReportsTrash = ({
  businessId,
}: IExpenseReportsTrashProps) => {
  const collection = useExpenseReportsTrashCollection({ businessId });

  return (
    <div className={ styles['expense-reports-trash-container'] }>
      <DetailsRegion>
        <Header selectedRecords={ collection.selectedRecords } />
        <List collection={ collection } />
      </DetailsRegion>
    </div>
  );
};

export default React.memo(ExpenseReportsTrash);
