import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const RemoveFromQueueIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="remove-from-queue"
    />
  );
};

export default RemoveFromQueueIcon;
