import React from 'react';

import { accountsPayableSettingsQueuePath, achTransactionsPath, approvalAuditControlsPath, checkRegisterPath, invoiceQueuePath, paymentMethodSetupPath, paymentRulesQueuePath, settlementItemsPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: TID
}

const BillPaySidebarMenuItems = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.BILL_PAY }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ invoiceQueuePath(businessId) }
          title="Invoice Queue"
        />
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href={ checkRegisterPath(businessId) }
          title="Check Register"
        />
        <ServiceSidebar.Item
          href={ achTransactionsPath(businessId) }
          title="ACH Register"
        />
        <ServiceSidebar.Item
          href={ settlementItemsPath(businessId) }
          title="Inter-entity Settlements"
        />
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href={ approvalAuditControlsPath(businessId) }
          title="Approval & Audit Controls"
        />
        <ServiceSidebar.Item
          href={ paymentRulesQueuePath(businessId) }
          title="Payment Rules"
        />
        <ServiceSidebar.Item
          href={ paymentMethodSetupPath(businessId) }
          title="Payment Method Setup"
        />
        <ServiceSidebar.Item
          href={ accountsPayableSettingsQueuePath(businessId) }
          title="Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default BillPaySidebarMenuItems;
