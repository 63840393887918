import React from 'react';

import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import Table from '@src/components/ui_v2/table';

interface IIntercompanyProps {
  adjustmentEntries: IAdjustmentEntry[];
}

const openQboLink = (adjustmentEntry: IAdjustmentEntry) => {
  if (adjustmentEntry.qboLink?.qboLink) window.open(adjustmentEntry.qboLink.qboLink, '_blank');
};

const Intercompany = ({ adjustmentEntries }: IIntercompanyProps) => {
  return (
    <Table className="related-journal-entries-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell>Journal ID</Table.HCell>
          <Table.HCell>Business</Table.HCell>
          <Table.AmountHCell widthPercent={ 15 }>Amount</Table.AmountHCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {adjustmentEntries.map((entry) => (
          <Table.Row key={ entry.id }>
            <Table.Cell pointer className="in-blue-900" onClick={ () => openQboLink(entry) }>
              {entry.docytId}
            </Table.Cell>
            <Table.Cell>{entry.businessName}</Table.Cell>
            <Table.AmountCell amount={ entry.amount } className="font-bold" />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(Intercompany);
