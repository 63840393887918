module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, idx, payment_account, ref;
    
      __out.push('<li class="');
    
      __out.push(__sanitize((this.receipt.get('payment_account') && this.receipt.get('payment_account').id === this.business.get('inter_company_settlement_payment_account_id')) ? 'active' : ''));
    
      __out.push('">\n  <a class="receipt-payment-dropdown-item" data-pos="-1">\n    <span class="icon icon-paid font-30 p-r-8"></span>\n    Advanced Settlement...\n  </a>\n</li>\n');
    
      for (idx = i = 0, ref = this.data.length; 0 <= ref ? i < ref : i > ref; idx = 0 <= ref ? ++i : --i) {
        __out.push('\n');
        payment_account = this.data.at(idx);
        __out.push('\n<li class="');
        __out.push(__sanitize((this.receipt.get('payment_account') && this.receipt.get('payment_account').id === payment_account.id) ? 'active' : ''));
        __out.push('">\n  <a class="receipt-payment-dropdown-item" data-pos="');
        __out.push(__sanitize(idx));
        __out.push('">\n    ');
        if (payment_account.get("card_type") === "credit_card") {
          __out.push('\n    <span class="icon icon-credit-card-account font-22">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n    </span>\n    ');
        } else if (payment_account.get("card_type") === "bank_check") {
          __out.push('\n    <span class="icon icon-bank-account font-22">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n    </span>\n    ');
        } else if (payment_account.get("card_type") === "cash") {
          __out.push('\n    <span class="icon icon-cash-account font-22">\n      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n    </span>\n    ');
        }
        __out.push('\n    ');
        __out.push(__sanitize(payment_account.get("card_type") === "cash" ? "Cash" : payment_account.get("name") || payment_account.get("qbo_name")));
        __out.push('\n  </a>\n</li>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}