module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="transaction-list-region" class="transaction-list-region">\n  <div>\n    <div class="clearfix">\n      <h2 class="font-bold font-26 pull-left">Uncleared Documents</h2>\n      <button type="button" class="btn btn-blue btn-accept-document-match m-r-20" disabled>Match</button>\n    </div>\n    <hr/>\n    <p class="m-b-20 font-16">\n        Docyt was not able to match these documents with any transactions. Select documents from the left table and transactions from the right table to match.\n    </p>\n    <div class="form-group">\n      <div class="checkbox include-unverified checkbox-primary inline-block m-r-20 m-b-0 m-t-5">\n        <input type="checkbox" class="pointer check-include-unverified" ');
    
      __out.push(__sanitize(this.includeUnverified ? 'checked' : ''));
    
      __out.push('>\n        <label>Include Uncategorized Transactions</label>\n      </div>\n    </div>\n    <div id="unreconciled-document-table" class="table-responsive transactions-table-wrapper p-r-20">\n      <div class="unreconciled-document">\n        <div class="display-flex">\n          <span class="font-18 font-bold">Uncleared Documents</span>\n          <span class="font-bold m-l-auto clear-selected-documents in-blue-400 pointer">Reset Filters <span class="filtered-count-panel documents-filter-count-panel hidden"></span></span>\n        </div>\n        <div class="panel panel-default">\n          <div class="transaction-filter-container">\n          </div>\n          <div class="transaction-list-container ');
    
      __out.push(__sanitize(this.includeUnverified ? 'include-unverify' : ''));
    
      __out.push('"></div>\n          <div class="transaction-list-footer"></div>\n        </div>\n      </div>\n      <div class="unreconciled-expense">\n        <div class="display-flex">\n          <span class="font-18 font-bold inline-block">Expense transactions</span>\n          <span class="font-bold m-l-auto clear-selected-transactions in-blue-400 pointer">Reset Filters <span class="filtered-count-panel transactions-filter-count-panel hidden"></span></span>\n        </div>\n        <div class="panel panel-default">\n          <div class="transaction-filter-container">\n          </div>\n          <div class="transaction-list-container"></div>\n          <div class="transaction-list-footer"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div id="transaction-right-side-region" class="transaction-right-side-region hidden"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}