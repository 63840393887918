import { array, object, string } from 'yup';

interface IAddBusinessInput {
  index: number,
  businessName: string,
  einLetterFile: File,
  businessLicenseFile: File,
  driverLicenseFile: File,
}

interface IFormValues {
  newBusinesses: IAddBusinessInput[]
}

const businessesSchema = object({
  newBusinesses: array().of(object({
    businessName: string().trim().required('Business Name is required.'),
  })),
});

export {
  IAddBusinessInput,
  IFormValues,
  businessesSchema,
};
