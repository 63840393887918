import React from 'react';

import { TID } from '@src/types/common';
import { API_DATE_FORMAT, endOfMonthDate, formatDate } from '@src/utils/date_helpers';

import { useBankStatementUploadModal } from '@src/components/banking_accounts/bank_statements/bank_statement_details/upload_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

interface IUploadStatementActionProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  month: number | string,
}

const UploadStatementAction = ({
  businessId,
  rcPaymentAccountId,
  month,
}: IUploadStatementActionProps): JSX.Element => {
  const selectedMonthStr = month;
  const uploadModal = useBankStatementUploadModal();

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
        preSelectedDate={ formatDate(endOfMonthDate(new Date(selectedMonthStr)), API_DATE_FORMAT) }
        preSelectedRcPaymentAccountId={ rcPaymentAccountId }
      />
      <ActionsDropdown.Action
        title="Upload Statement"
        onClick={ uploadModal.open }
      />
    </>
  );
};

export default UploadStatementAction;
