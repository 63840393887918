import React, { useCallback, useState, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useArchiveVendor, useUnarchiveVendor } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';
import { camelizeKeys } from '@src/utils/transform_keys';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

interface IArchiveConfirmProps extends IUseModalProps {
  icon?: JSX.Element;
  text: JSX.Element | string;
  title: JSX.Element | string;
  vendor: IBusinessVendor;
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>;
}

const ArchiveConfirm = ({
  vendor,
  icon,
  isOpen,
  text,
  title,
  onDone,
  onCancel,
  setVendorData,
}: IArchiveConfirmProps): JSX.Element => {
  const archivedVendor = useArchiveVendor();
  const unArchivedVendor = useUnarchiveVendor();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const isArchive = !!vendor.archivedAt;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const { mutate: archiveVendor, isLoading: isArchiving } = archivedVendor;
  const { mutate: unArchiveVendor, isLoading: isUnarchiving } = unArchivedVendor;

  const handleArchiveConfirm = useCallback(() => {
    archiveVendor(
      {
        vendorId:   vendor.id,
        businessId: vendor.businessId,
      },
      {
        onSuccess: (response) => {
          const vendorData = (response as unknown as { vendor: IBusinessVendor }).vendor;
          setVendorData(camelizeKeys(vendorData) as IBusinessVendor);
          setSuccessMessage('Vendor successfully archived.');
          onDone();
        },
        onError: handleError,
      },
    );
  }, [archiveVendor, handleError, onDone, setVendorData, vendor.businessId, vendor.id]);

  const handleUnarchiveConfirm = useCallback(() => {
    unArchiveVendor(
      {
        vendorId:   vendor.id,
        businessId: vendor.businessId,
      },
      {
        onSuccess: (response) => {
          const vendorData = (response as unknown as { vendor: IBusinessVendor }).vendor;
          setVendorData(camelizeKeys(vendorData) as IBusinessVendor);
          setSuccessMessage('Vendor successfully unarchived.');
          onDone();
        },
        onError: handleError,
      },
    );
  }, [handleError, onDone, setVendorData, unArchiveVendor, vendor.businessId, vendor.id]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(undefined);
        onCancel();
      }, 3000);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [successMessage, onCancel]);

  return (
    <>
      {(isArchiving || isUnarchiving) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } />}
      {successMessage && <SuccessNotification message={ successMessage } />}
      <Modal className="modal-v2" show={ isOpen } title={ title }>
        <Modal.Body>
          {icon && (
            <div className="text-center delete-icon-wrapper">
              <div className="inline-block relative">{icon}</div>
            </div>
          )}
          <p className="p-b-0 text-center font-18">{text}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button className="btn-outline" variant="link" onClick={ onCancel }>
            Cancel
          </Button>
          <Button
            className="btn-primary"
            variant="primary"
            onClick={ isArchive ? handleUnarchiveConfirm : handleArchiveConfirm }
          >
            {isArchive ? 'Unarchive' : 'Archive'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useConfirmArchiveModal = makeUseModal(ArchiveConfirm);

export { IArchiveConfirmProps, useConfirmArchiveModal, ArchiveConfirm as default };
