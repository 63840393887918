import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { IBusinessUser } from '@src/types/business_users';
import { IDashboard } from '@src/types/dashboards/dashboards';

import { ShareDashboardIcon } from '@src/components/utils/icomoon';

import ActionsMenu from '../actions/actions_menu';
import ShareAction from '../actions/share_action';

import styles from './styles.module.scss';

interface IDashboardHeaderProps {
  currentDashboard: IDashboard,
  businessServiceInfo?: IBusinessServicesInfo,
  onShareDone: (accessibleUsers: IBusinessUser[]) => void,
}

const DashboardHeader = ({
  currentDashboard,
  businessServiceInfo,
  onShareDone,
}: IDashboardHeaderProps): JSX.Element => {
  const business = useBusinessContext();
  return (
    <div className={ styles['dashboard-header'] }>
      {
        businessServiceInfo?.isBusinessAdmin && (
          <>
            <ShareAction
              businessId={ currentDashboard.businessId }
              currentDashboard={ currentDashboard }
              prefixIcon={ <ShareDashboardIcon fontSize={ 20 } /> }
              serviceProviderBusinessId={ business.serviceProviderBusiness?.id || null }
              onDone={ onShareDone }
            />
            <ActionsMenu
              businessServiceInfo={ businessServiceInfo }
              currentDashboard={ currentDashboard }
            />
          </>
        )
      }
    </div>
  );
};

export default DashboardHeader;
