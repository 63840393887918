import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLocalStorage } from '@src/hooks/storage';
import {
  IBankAccountReconciliationItem,
} from '@src/types/bank_account_reconciliations';

import AcknowledgeStatement from '@src/components/reconciliation_center/month_end/acknowledge_statement';
import { Button } from '@src/components/ui_v2/buttons';
import Table from '@src/components/ui_v2/table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ItemProps {
  data: {
    title: string;
    total: string;
    thisMonth: string;
    carriedOver: string;
    downloadType: string;
    downloadParams: object;
    downloadColumnMappings?: [string, string][];
    drawer?: React.ReactElement;
    titleAmountColor?: string;
    showDownloadIcon?: boolean;
    emptyValue?: boolean;
    tooltip?: string;
    customThisMonth?: React.ReactElement;
    item: IBankAccountReconciliationItem;
    noteRequired?: boolean;
    showAckButton?: boolean;
  };
}
const StatementEndingBalanceItem = ({ data }: ItemProps) => {
  const {
    title,
    total,
    thisMonth,
    carriedOver,
    drawer,
    titleAmountColor,
    tooltip,
    emptyValue = false,
    customThisMonth,
    item,
    noteRequired = false,
    showAckButton = true,
  } = data;

  const { id } = useBusinessContext();
  const breakup = useLocalStorage(`${title}_breakup_toggle_${id}`, false);
  const breakupValue = breakup.storedValue;
  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  let tooltipIcon = <span />;
  if (tooltip) {
    tooltipIcon = (
      <Tooltip.Hover as="span" content={ tooltip }>
        <DocytSupportIcon className={ styles['tooltip-icon'] } />
      </Tooltip.Hover>
    );
  }

  const thisMonthPart = customThisMonth || (
    <div className={ styles['item-detail'] }>
      <span>This Month</span>
      <span>{ emptyValue ? '-' : thisMonth }</span>
    </div>
  );

  return (
    <div className={ styles['item-container'] }>
      <div className={ styles['item-title'] }>
        <div className={ styles['item-title-left'] }>
          <Button
            className={ breakupValue ? styles['text-arrow-expanded'] : styles['text-arrow-collapsed'] }
            variant="link"
            onClick={ handleBreakup }
          >
            { title }
          </Button>
          <div className={ styles['item-amount'] }>
            <span style={ { color: emptyValue ? 'black' : titleAmountColor } }>{ emptyValue ? '-' : total }</span>
            { drawer }
            { tooltipIcon }
          </div>
        </div>

        <div className={ styles['item-title-right'] }>
          <Table.DateCell date={ item.acknowledgedOn } emptyValue="—" />
          <Table.UserCell emptyValue="—" user={ item.acknowledgedBy } />
          <Table.Cell>
            { showAckButton
                && (
                <AcknowledgeStatement item={ item } noteRequired={ noteRequired } total={ total } />
                ) }
          </Table.Cell>
        </div>

      </div>

      {
        breakupValue && (
          <div className={ styles['item-details'] }>
            { thisMonthPart }
            <div className={ styles['item-detail'] }>
              <span>Carried Over</span>
              <span>{ carriedOver }</span>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default StatementEndingBalanceItem;
