import React, { forwardRef } from 'react';

import TextAreaInput from '@src/components/ui_v2/inputs/text_area_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface ITextAreaFieldProps extends React.ComponentPropsWithoutRef<typeof TextAreaInput>,
IWrapperProps {
}

const TextAreaField = forwardRef<HTMLTextAreaElement, ITextAreaFieldProps>(({
  ...props
}: ITextAreaFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <TextAreaInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
