import difference from 'lodash/difference';

import { TItemsSelectorAction, TItemsSelectorState } from './types';

const initItemsSelectorState = <Type>(all: Type[]): TItemsSelectorState<Type> => {
  return {
    all,
    selected: [],
  };
};

const itemsSelectorReducer = <Type>(
  state: TItemsSelectorState<Type>,
  action: TItemsSelectorAction<Type>,
): TItemsSelectorState<Type> => {
  const { selected, all } = state;

  switch (action.type) {
    case 'mark': {
      if (!action.checked) {
        return {
          ...state,
          selected: selected.filter((i) => i !== action.item),
        };
      }
      if (state.selected.includes(action.item)) return state;

      return { ...state, selected: selected.concat([action.item]) };
    }
    case 'markAll': {
      if (action.checked) return { ...state, selected: all };

      return { ...state, selected: [] };
    }
    case 'toggleMark': {
      if (state.selected.includes(action.item)) {
        return {
          ...state,
          selected: selected.filter((i) => i !== action.item),
        };
      }

      return { ...state, selected: selected.concat([action.item]) };
    }
    case 'setAll': {
      if (difference(selected, action.all).length > 0) {
        // If new all array doesn't include selected items,
        // then selected is not valid anymore and should be cleared
        return { ...state, all: action.all, selected: [] };
      }

      return { ...state, all: action.all };
    }
    case 'reset':
      return initItemsSelectorState(all);

    // no default
  }

  return state;
};

export {
  initItemsSelectorState,
  itemsSelectorReducer,
};
