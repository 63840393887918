import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useDeleteMultiEntityReport } from '@src/hooks/queries/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDeleteReportProps extends IUseModalProps {
  item: IMultiBusinessReportDetail,
}

const DeleteReportModal = ({ item, isOpen, onCancel, onDone }: IDeleteReportProps) => {
  const deleteReport = useDeleteMultiEntityReport();
  const { mutate } = deleteReport;

  const handleDone = useCallback(() => {
    mutate(item.id, {
      onSuccess: () => onDone(),
    });
  }, [onDone, item.id, mutate]);

  return (
    <>
      <Modal.Standard
        danger
        proceedTitle="Proceed"
        show={ isOpen }
        title="Delete Report"
        onCancel={ onCancel }
        onProceed={ handleDone }
      >
        {() => (
          <div className="text-center font-16">
            <b>{ item.name }</b>
            &nbsp;will be permanently deleted and removed from any portfolio report.
            <br />
            Are you sure you want to continue?
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ deleteReport } />
    </>
  );
};

const useDeleteReportModal = makeUseModal(DeleteReportModal);

export { IDeleteReportProps, useDeleteReportModal, DeleteReportModal as default };
