import React from 'react';

import { TID, TYear } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import ReviewedDetails from './reviewed/reviewed_details/reviewed_details';

interface IReviewedDetailsPageProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  year: TYear,
}

const ReviewedDetailsPage = ({
  businessId,
  rcPaymentAccountId,
  year,
}: IReviewedDetailsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReviewedDetails
          businessId={ businessId }
          rcPaymentAccountId={ rcPaymentAccountId }
          year={ year }
        />
      </BusinessProvider>
    </Root>
  );
};

export default ReviewedDetailsPage;
