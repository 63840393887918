import {
  IBusinessVendorsFilter,
  TBusinessVendorsSortColumn,
  IBusinessVendor,
  IGlobalVendorWithBusinessInfo,
} from '@src/types/business_vendors';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { uploadImageFileToS3 } from './aws';
import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetBusinessVendorsParams {
  businessId: TID,
  search?: string,
  filter?: IBusinessVendorsFilter,
  orderColumn?: TBusinessVendorsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
  perPage?: number,
}

interface IGetBusinessVendorsResponse {
  meta: {
    totalCount: number,
  },
  collection: IBusinessVendor[],
}

interface IGlobalVendorWithBusinessInfosResponse {
  meta: {
    totalCount: number,
  },
  collection: IGlobalVendorWithBusinessInfo[],
}

interface IAddToBusinessParams {
  businessId: TID,
  vendorIds: number[],
}

interface IRemoveFromBusinessParams {
  businessId: TID,
  vendorId: TID,
}

export interface IBusinessVendorParams extends IBusinessVendor {
  vendor?: IBusinessVendor,
}

export interface IQboVendorsResponse {
  id: string,
  name: string,
  address: string,
}

const getBusinessVendors = (
  params: IGetBusinessVendorsParams,
): Promise<IGetBusinessVendorsResponse> => {
  return apiGet(
    '/api/v1/business_vendors/with_multiple_businesses?exclude_individual_vendor=true',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.businessVendors,
    } as IGetBusinessVendorsResponse;
  });
};

const getBusinessGlobalVendors = (
  params: IGetBusinessVendorsParams,
): Promise<IGlobalVendorWithBusinessInfosResponse> => {
  return apiGet(
    '/api/v1/vendors/global_vendors?exclude_individual_vendor=true&with_business_information=true',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.vendors,
    } as IGlobalVendorWithBusinessInfosResponse;
  });
};

const postAddToBusiness = (
  params: IAddToBusinessParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/business_vendors/link_to_business',
    underscoreKeys({ ...params }),
  );
};

const deleteFromBusiness = (
  params: IRemoveFromBusinessParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/business_vendors/${params.vendorId}`,
    underscoreKeys({
      ...params,
    }),
  );
};

interface IGetBusinessVendorParams {
  businessId: number,
  vendorId: number,
}

const getBusinessVendor = (
  params: IGetBusinessVendorParams,
): Promise<IBusinessVendor> => {
  return apiGet(
    `/api/v1/business_vendors/${params.vendorId}`,
    underscoreKeys({ businessId: params.businessId }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data.vendor);
    return {
      ...cdata,
      docytId: (cdata.docytId || cdata.docytNetworkId),
    };
  });
};

export const updateBusinessVendor = (params: IBusinessVendorParams): Promise<IBusinessVendor> => {
  return apiPut(
    `/api/v1/business_vendors/${params.id}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data?.vendor) as IBusinessVendor);
}

export const uploadVendorProfileLogo = (file: File): Promise<string> => {
  return apiGet(
    'api/v1/vendors/generate_logo_key',
  ).then((data) => {
    return uploadImageFileToS3({
      s3ObjectKey: data.key,
      file,
      bucketName: window.configData.vendor_logos_bucket
    }).then(({ s3ObjectKey }) => s3ObjectKey);
  });
}

export const syncBusinessVendorWithQbo = (params: { vendorId: number, businessId: number }): Promise<void> => {
  return apiPut(
    `api/v1/business_vendors/${params.vendorId}/synchronize_with_qbo`,
    underscoreKeys({ businessId: params.businessId }),
  );
}

export const getQboVendors = (params: { businessId: number, search: string }): Promise<IQboVendorsResponse[]> => {
  return apiGet(
    'api/v1/quickbooks_vendors/search',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data?.qbo_vendors) as IQboVendorsResponse[]);
}

interface ISearchBusinessVendorsParams {
  businessId: number,
  search?: string,
  page?: number,
  perPage?: number,
}

interface ISearchBusinessVendorsResponse {
  meta: {
    totalCount: number,
  },
  collection: IBusinessVendor[],
}

const searchBusinessVendors = (
  params: ISearchBusinessVendorsParams,
): Promise<ISearchBusinessVendorsResponse> => {
  return apiGet(
    '/api/v1/business_vendors/search',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.vendors,
    } as ISearchBusinessVendorsResponse;
  });
};

export {
  IGetBusinessVendorParams,
  IGetBusinessVendorsParams,
  IGetBusinessVendorsResponse,
  IGlobalVendorWithBusinessInfosResponse,
  IAddToBusinessParams,
  IRemoveFromBusinessParams,
  ISearchBusinessVendorsParams,
  ISearchBusinessVendorsResponse,
  getBusinessVendors,
  getBusinessGlobalVendors,
  postAddToBusiness,
  deleteFromBusiness,
  getBusinessVendor,
  searchBusinessVendors,
};
