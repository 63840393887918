import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { IBusinessVendor } from '@src/types/business_vendors';

import Form from '@src/components/ui_v2/form';

import {
  SelectMergeableVendorValidation,
  ISelectMergeableVendorFormValues,
} from './schema';
import SelectMergeableVendor from './select_mergeable_vendor/select_mergeable_vendor';
import VendorDetailsView from './vendor_details_view';

import styles from './styles.module.scss';

interface ISelectMergeableVendorModalFormProps {
  businessVendor: IBusinessVendor,
  formId?: string,
  onSubmit: SubmitHandler<ISelectMergeableVendorFormValues>,
}

const SelectMergeableVendorModalForm = ({
  businessVendor,
  formId,
  onSubmit,
}: ISelectMergeableVendorModalFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
  } = useForm<ISelectMergeableVendorFormValues>({
    resolver: yupResolver(SelectMergeableVendorValidation),
  });

  const selectedVendorController = useController({ name: 'mergeableVendor', control });

  const handleSubmitError = useCallback((err) => {
    if (!err.mergeableVendor) return;

    toastr.error(
      err.mergeableVendor.message,
      'Something wrong',
    );
  }, []);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit, handleSubmitError) }>
      <div className={ styles['select-mergeable-vendor-container'] }>
        <div className={ styles['vendor-details'] }>
          <VendorDetailsView businessVendor={ businessVendor } />
        </div>
        <SelectMergeableVendor
          businessVendor={ businessVendor }
          field={ selectedVendorController.field }
        />
      </div>
    </Form>
  );
};

export {
  ISelectMergeableVendorModalFormProps,
  SelectMergeableVendorModalForm as default,
};
