module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, k, l, m, ref, ref1, ref2, ref3, x;
    
      if (!(this.uploaded_keys.length > 0)) {
        __out.push('\n<div class="document-result-instruction">\n  <p class="font-24">Add a statement on left to begin</p>\n</div>\n');
      } else {
        __out.push('\n<div class="document-result-wrapper">\n  <h2 class="font-24 font-semi-bold">Extraction Result</h2>\n  <div class="form-group">\n    <div class="checkbox">\n      <input type="checkbox" id="chk_img_process">\n      <label for="chk_img_process">\n        Smart processing\n      </label>\n    </div>\n  </div>\n  <p class="m-t-10"><button type="button" class="btn btn-sm btn-add-new-hint"><i class="fa fa-plus"></i> Add new hint</button></p>\n  <div class="hints-container">\n    <div class="row-hint m-b-10">\n      <div class="form-inline form-group">\n        <label for="inputDateFormat1">Date Format</label>\n        <input type="text" class="form-control m-l-10" id="inputDateFormat1" size="30">\n      </div>\n      <div class="form-inline form-group">\n        <div class="checkbox" data-toggle="tooltip" data-placement="right" title="Check if date has any spaces inside">\n          <input type="checkbox" id="space1">\n          <label for="space1">\n            Space\n          </label>\n        </div>\n      </div>\n      <button type="button" class="close btn-remove-hint" aria-label="Close">\n        <span aria-hidden="true">&times;</span>\n      </button>\n    </div>\n  </div>\n  <div class="clearfix">\n    <div class="pull-left">\n      Supported date formats:\n    </div>\n    <div class="pull-left m-l-10">\n      <p><small class="text-muted">mm/dd/yyyy - 04/30/2019</small></p>\n      <p><small class="text-muted">yy-mm-dd - 19/04/30</small></p>\n      <p><small class="text-muted">bdd - Apr 30</small></p>\n      <p><small class="text-muted">bdd,yyyy - Apr 30, 2019</small></p>\n    </div>\n  </div>\n  <div class="m-b-20">\n    <label for="selColumnCount" class="m-r-40">Columns: </label>\n    <select class="selectpicker" id="selColumnCount" data-width="100px">\n      ');
        for (x = i = 1, ref = this.maxColumnCount; 1 <= ref ? i <= ref : i >= ref; x = 1 <= ref ? ++i : --i) {
          __out.push('\n      <option value="');
          __out.push(__sanitize(x));
          __out.push('" ');
          __out.push(__sanitize(x === this.defaultColumnCount ? 'selected' : ''));
          __out.push('>');
          __out.push(__sanitize(x));
          __out.push('</option>\n      ');
        }
        __out.push('\n    </select>\n  </div>\n  <div class="extractor-columns-container">\n    <div class="flex-row">\n      ');
        for (x = j = 1, ref1 = this.maxColumnCount; 1 <= ref1 ? j <= ref1 : j >= ref1; x = 1 <= ref1 ? ++j : --j) {
          __out.push('\n      <div class="flex-item ');
          __out.push(__sanitize(x > this.defaultColumnCount ? 'hidden' : ''));
          __out.push('">\n        <div class="form-group m-b-0">\n          <select class="selectpicker form-control select-data-type" title="Select Data Type">\n            <option value="date">Date</option>\n            <option value="string">String</option>\n            <option value="currency">Currency</option>\n          </select>\n        </div>\n      </div>\n      ');
        }
        __out.push('\n    </div>\n    <div class="flex-row hidden">\n      ');
        for (x = k = 1, ref2 = this.maxColumnCount; 1 <= ref2 ? k <= ref2 : k >= ref2; x = 1 <= ref2 ? ++k : --k) {
          __out.push('\n      <div class="flex-item ');
          __out.push(__sanitize(x > this.defaultColumnCount ? 'hidden' : ''));
          __out.push('">\n        <div class="form-group">\n          <select class="selectpicker form-control" title="Select Data Format">\n            <option>MM / DD / YY</option>\n            <option>DD / MM / YY</option>\n            <option>MM / DD</option>\n          </select>\n        </div>\n      </div>\n      ');
        }
        __out.push('\n    </div>\n  </div>\n\n  <div class="extractor-table-container">\n    ');
        for (x = l = 1; l <= 15; x = ++l) {
          __out.push('\n    <div class="flex-row">\n      ');
          for (x = m = 1, ref3 = this.maxColumnCount; 1 <= ref3 ? m <= ref3 : m >= ref3; x = 1 <= ref3 ? ++m : --m) {
            __out.push('\n      <div class="flex-item ');
            __out.push(__sanitize(x > this.defaultColumnCount ? 'hidden' : ''));
            __out.push('">\n        <div class="form-group m-b-0">\n          <input type="text" class="form-control input-cell">\n        </div>\n      </div>\n      ');
          }
          __out.push('\n    </div>\n    ');
        }
        __out.push('\n  </div>\n\n  <div class="m-t-25">\n    <button class="btn btn-blue btn-download-csv font-18 font-semi-bold"><i class="fa fa-download"></i> Download CSV</button>\n    <button class="btn btn-blue-on-hover pull-right btn-add-rows font-18 font-semi-bold">Add 5 Rows</button>\n  </div>\n</div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}