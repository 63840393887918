import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { Button } from '@src/components/ui_v2/buttons';
import Dropdown from '@src/components/ui_v2/dropdown';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useAddBusinessClientModal } from './add_business_client/add_business_client_modal';
import { useAddInviteClientModal } from './add_invite_client/add_invite_client_modal';

import styles from './styles.module.scss';

interface IHeaderProps {
  searchQuery?: string,
  onSearched: (value: string) => void,
}

const Header = ({
  searchQuery,
  onSearched,
}: IHeaderProps): JSX.Element => {
  const addBusinessClientModal = useAddBusinessClientModal();
  const addInviteClientModal = useAddInviteClientModal();

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['add-client-dropdown-btn'] }>
      <Button
        className={ styles['add-client-business-btn'] }
        variant="primary"
      >
        <PlusIcon />
      </Button>
    </Dropdown.ToggleButton>
  );
  return (
    <>
      <addBusinessClientModal.Component
        { ...addBusinessClientModal.props }
      />
      <addInviteClientModal.Component
        { ...addInviteClientModal.props }
      />
      <div className={ styles['header-region'] }>
        <SearchInput
          value={ searchQuery }
          onChange={ onSearched }
        />
        <div className={ styles['horizontal-divider'] } />
        <ActionsDropdown toggleButton={ toggleButton }>
          <ActionsDropdown.Action
            title="Add Business"
            onClick={ addBusinessClientModal.open }
          />
          <ActionsDropdown.Action
            title="Invite Client"
            onClick={ addInviteClientModal.open }
          />
        </ActionsDropdown>
      </div>
    </>
  );
};

export default Header;
