import { useCallback, useEffect } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { TSection } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import { useSection } from '@src/components/utils_v2/section';

import { filterCounter, filterData } from './atoms';

type TUseFilterFieldReturn<TField> =
  [TField | undefined, (value: TField | undefined) => void]

const useFilterData = <TFilter extends TFilterData>(
  sectionParam?: TSection,
  defaultValue?: TFilter,
): TFilter => {
  const section = useSection(sectionParam);

  const [data, setData] = useRecoilState(filterData(section));

  useEffect(() => {
    if (!defaultValue) return;
    if (data && !isEmpty(data)) return;

    setData(defaultValue);
  }, [defaultValue, data, setData]);

  return data as TFilter;
};

const useFilterField = <TFilter extends TFilterData, TField>(
  fieldName: keyof TFilter,
  sectionParam?: TSection,
): TUseFilterFieldReturn<TField> => {
  const section = useSection(sectionParam);

  const [data, setData] = useRecoilState(filterData(section));

  const updateField = useCallback((newValue: TField | undefined) => {
    setData((oldData) => {
      const newData = newValue ? ({ [fieldName]: newValue } as unknown as TFilter) : {};

      const mergeData: TFilterData = {
        ...(omit(oldData, fieldName) || {}),
        ...newData,
      };

      if (isEqual(oldData, mergeData)) return oldData;

      return mergeData;
    });
  }, [fieldName, setData]);

  const value = get(data, fieldName) as unknown as (TField | undefined);

  return [value, updateField];
};

const useFilterCounter = (
  sectionParam?: TSection,
): number => {
  const section = useSection(sectionParam);

  return useRecoilValue(filterCounter(section));
};

const useClearFilter = (
  sectionParam?: TSection,
) => {
  const section = useSection(sectionParam);

  return useResetRecoilState(filterData(section));
};

export {
  TUseFilterFieldReturn,
  useFilterCounter,
  useFilterData,
  useFilterField,
  useClearFilter,
};
