import React, { useMemo } from 'react';

import { useGetReconciliationPaymentAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';

import Filter from '@src/components/ui_v2/filter';

const BlankOption = { label: '', value: '' };

interface IBankingAccountFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.DropdownField>, 'options'>
{
  blankLabel?: string,
  businessId: TID,
  includeBlank?: boolean,
  /**
   * Use `name` field of option instead of `id` as value.
   */
  useNameValue?: boolean,
}

const BankingAccountFilterField = ({
  blankLabel,
  businessId,
  includeBlank,
  useNameValue,
  ...props
}: IBankingAccountFieldProps): JSX.Element => {
  const reconciliationAccountsQuery = useGetReconciliationPaymentAccounts({
    businessId,
    noConsiderArchive: true,
  });

  const blankOption = useMemo(() => {
    if (!blankLabel) return BlankOption;

    return { label: blankLabel, value: BlankOption.value };
  }, [blankLabel]);

  const bankingAccountOptions = useMemo(() => {
    const reconciliationPaymentAccounts =
      reconciliationAccountsQuery.data?.reconciliationPaymentAccounts;

    const opts = includeBlank ? [blankOption] : [];

    if (!reconciliationPaymentAccounts) { return opts; }

    return [
      ...opts,
      ...reconciliationPaymentAccounts.map((recAccount) => ({
        label: recAccount.name,
        value: useNameValue ? recAccount.name : String(recAccount.id),
      })),
    ];
  }, [
    reconciliationAccountsQuery.data?.reconciliationPaymentAccounts,
    blankOption,
    includeBlank,
    useNameValue,
  ]);

  return (
    <Filter.DropdownField
      label="Account"
      placeholder="Select Account…"
      { ...props }
      options={ bankingAccountOptions }
    />
  );
};

export default React.memo(BankingAccountFilterField);
