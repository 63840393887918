import React from 'react';

import { ISearchResultItem } from '@src/types/global_search/global_search_requests';

import { AppLink } from '@src/components/ui_v2/buttons';
import SantizedHtml from '@src/components/utils/santized_html';

import SearchResultIcon from './search_result_icon';

import styles from './styles.module.scss';

const SearchResultItem = ({
  docytId,
  title,
  businessName,
  searchTypeDto,
  date,
  amount,
  status,
  actionUrl,
  searchAdditionalDtoList,
}: ISearchResultItem) => {
  return (
    <AppLink newWindow className={ styles['content-list'] } href={ actionUrl }>
      <div className={ styles['list-left'] }>
        <div
          className={ [
            styles['middle-avatar-wrapper'],
            styles['border-gray'],
          ].join(' ') }
        >
          <SearchResultIcon iconName={ searchTypeDto.key } />
        </div>
        <div className={ styles['content-container'] }>
          <p className={ styles.title }>
            <SantizedHtml html={ docytId ?? ' -- ' } />
            {' : '}
            <SantizedHtml html={ title ?? ' -- ' } />
          </p>
          <p className={ styles.text }>
            <SantizedHtml html={ businessName ?? ' -- ' } />
            {searchTypeDto.value && (
              <span className={ styles['text-divider'] }>•</span>
            )}
            <SantizedHtml html={ searchTypeDto.value ?? ' -- ' } />
            {date && <span className={ styles['text-divider'] }>•</span>}
            <SantizedHtml html={ date ?? ' -- ' } />
          </p>
          <span className={ styles['add-tag-container'] }>
            {searchAdditionalDtoList
              && searchAdditionalDtoList.map((item) => (
                <p key={ item.value } className={ styles['tag-container'] }>
                  <SantizedHtml html={ item.key ?? ' -- ' } />
                  <SantizedHtml html={ item.value ?? ' -- ' } />
                </p>
              ))}
          </span>
        </div>
      </div>
      <div className={ styles['list-right'] }>
        <SantizedHtml as="p" className={ styles.title } html={ amount ? `${amount}` : '--' } />
        <SantizedHtml as="p" className={ styles.text } html={ `${status ?? '--'}` } />
      </div>
    </AppLink>
  );
};

export default React.memo(SearchResultItem);
