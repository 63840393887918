import React from 'react';

import RangeFormatter from './range_formatter';
import { TRichAmount } from './types';
import ValueFormatter from './value_formatter';

interface IRichAmountFormatterProps {
  value?: TRichAmount,
}

const RichAmountFormatter = ({
  value,
}: IRichAmountFormatterProps): JSX.Element | null => {
  if (value?.val) {
    return <ValueFormatter value={ value } />;
  }

  if (value?.gte || value?.lte) {
    return <RangeFormatter value={ value } />;
  }

  return null;
};

export default RichAmountFormatter;
