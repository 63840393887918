import React, { FC } from 'react';

import { useGetAdjustmentEntry } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { TID } from '@src/types/common';

import Table from '@src/components/reconciliation_center/journal_entries/header/table';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import useJournalDataHandling from './use_journal_data_handling';

import styles from '@src/components/reconciliation_center/journal_entry/styles.module.scss';

const EditContent: FC<{id: TID, close: () => void}> = ({ id, close }) => {
  const { businessId, additionalParams, onSuccess, dateRange } = useJournalDataHandling();
  const { data, isFetching } = useGetAdjustmentEntry(id, businessId);
  const adjustmentEntry = data?.adjustmentEntry as IAdjustmentEntry;

  if (isFetching) {
    return <div className={ styles['loading-container'] }><SpinnerIcon spin fontSize={ 30 } /></div>;
  }

  return (
    <Table
      additionalParams={ additionalParams }
      close={ close }
      dateRange={ dateRange }
      editData={ adjustmentEntry }
      onSuccess={ onSuccess }
    />
  );
};

export default EditContent;
