import React from 'react';

import moment from 'moment';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import AskDocytAI from '@src/components/reconciliation_center/view_transaction_context/askDocytAI';
import { TabItem } from '@src/components/ui_v2/tabs';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import Expense from './transactionType/expense';

import styles from '../styles.module.scss';

interface ISidebarProps {
  onClose: () => void;
  rowData: ITransactionServiceDocument;
  businessId: number;
  isLoading: boolean;
}

const Sidebar = ({
  onClose,
  rowData,
  businessId,
  isLoading,
}: ISidebarProps) => {
  const transactionContextData = {
    id:                    rowData.id,
    businessId,
    documentId:            rowData.documentId,
    isFlaggedOrHasMessage: !!rowData.flaggedState || !!rowData.transactionContextMessage,
    transactionDesc:       rowData.description,
    aiContext:             rowData.descriptionExplained || '',
    clientContext:         rowData.transactionContextMessage || '',
  };

  return (
    <div className={ styles['sidebar-container'] }>
      <div className={ styles['sidebar-header'] }>
        <span>Categorize Transactions</span>
        <NotAChargebackIcon pointer fontSize={ 20 } onClick={ onClose } />
      </div>
      <div className={ styles['sidebar-body'] }>
        {isLoading ? (
          <div className={ styles['loading-container'] }>
            <div className={ styles['loading-spinner'] } />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div className={ styles['sidebar-tabs'] }>
              <TabItem
                activeTab={ { label: 'Data', value: 'data' } }
                tab={ { label: 'Data', value: 'data' } }
                onTabChange={ () => {} }
              />
              <TabItem
                activeTab={ { label: 'Data', value: 'data' } }
                tab={ { label: 'Chat', value: 'chat' } }
                onTabChange={ () => {} }
              />
            </div>
            <div className={ styles['sidebar-chat-content'] }>Chat Tab Content</div>
            <div className={ styles['sidebar-data-content'] }>
              <div className={ styles['sidebar-common-content'] }>
                <div className={ styles['sidebar-data'] }>
                  <div>Date</div>
                  <input
                    disabled
                    type="text"
                    value={ moment(rowData.transactionDate).format('MM/DD/YYYY') }
                  />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Amount</div>
                  <input disabled type="text" value={ rowData.amount } />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Description</div>
                  <input disabled type="text" value={ rowData.description } />
                </div>
                <AskDocytAI transactionContextData={ transactionContextData } />
              </div>
              <Expense businessId={ businessId } rowData={ rowData } />
              <div className={ styles['sidebar-footer'] }>
                <button type="button">Categorize Transaction</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
