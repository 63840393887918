import React, { useMemo } from 'react';

import { TSection } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import Filter, { useClearFilter, useFilterCounter } from '@src/components/ui_v2/filter';
import {
  TOption,
} from '@src/components/ui_v2/inputs';
import Section from '@src/components/utils_v2/section';

import { changeKeyInArrayOfObject, transformObjectArray } from '../helper';
import FilterItemWrapper from './filter_item_wrapper';

import styles from '../styles.module.scss';

interface IGlobalSearchFilterProps {
  statusList?: TOption[];
  businessList?: TOption[];
  amountError: boolean;
  section: TSection
}

const GlobalSearchFilter = ({
  businessList,
  statusList,
  amountError,
  section,
}: IGlobalSearchFilterProps) => {
  const clearFilter = useClearFilter(section);
  const counter = useFilterCounter(section);

  const businessOpts = useMemo(() => {
    return changeKeyInArrayOfObject(
      [
        ['businessId', 'value'],
        ['businessName', 'label'],
      ],
      businessList,
    ) as TOption[];
  }, [businessList]);

  const statusOpts = useMemo(() => {
    return transformObjectArray(statusList) as TOption[];
  }, [statusList]);

  return (
    <Section.Provider section={ section }>
      <Filter.SearchContainer className={ styles['filter-container'] }>
        <FilterItemWrapper className={ styles['input-label-group'] } label="Filter by Business">
          <Filter.OutsideSelectFilterField
            isClearable
            isMulti
            isSearchable
            controlShouldRenderValue={ false }
            name="businessIdList"
            options={ businessOpts }
          />
        </FilterItemWrapper>
        {statusList?.length && (
        <FilterItemWrapper className={ styles['input-label-group'] } label="Filter by Status">
          <Filter.SelectField
            isClearable
            isSearchable
            name="status"
            options={ statusOpts }
            placeholder="All"
          />
        </FilterItemWrapper>
        )}
        <FilterItemWrapper className={ styles['input-label-group'] } label="Filter by Date">
          <Filter.DateField
            name="dateRangeDto"
          />
        </FilterItemWrapper>
        <FilterItemWrapper
          className={ [styles['input-label-group'], styles['range-input-container']].join(' ') }
          label="Filter by Amount"
        >
          <Filter.AmountField
            isInvalid={ amountError }
            name="amountRangeDto"
          />
        </FilterItemWrapper>
        <div className={ styles['filter-btn'] }>
          <Button disabled={ !counter } variant="link" onClick={ clearFilter }>
            Clear Filters
          </Button>
        </div>
      </Filter.SearchContainer>
    </Section.Provider>
  );
};

export default React.memo(GlobalSearchFilter);
