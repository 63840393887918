import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { UseInfiniteQueryResult } from 'react-query';

import { useGetCombinedServiceDocuments } from '@src/hooks/queries/combined_service_documents';
import { useGetTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetCombinedServiceDocumentsResponse } from '@src/requests/combined_service_documents';
import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import {
  ICombinedServiceDocument,
  ICombinedServiceDocumentFilter,
  ICombinedServiceDocumentQueryFilter,
  TCombinedServiceDocumentsSortColumn,
} from '@src/types/combined_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';
import { amountFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseCSDCollectionParams {
  businessId: TID,
}

interface ICSDCollection {
  query: UseInfiniteQueryResult<IGetCombinedServiceDocumentsResponse, Error>,
  records: ICombinedServiceDocument[],
  section: TSection,
  sorting: ISorting<TCombinedServiceDocumentsSortColumn>,
  selectedRecords: ICombinedServiceDocument[],
}

const csdDefaultSorting: ISortingParams<TCombinedServiceDocumentsSortColumn> = {
  orderColumn:    'vendor_id',
  orderDirection: 'desc',
};

const csdFilterToQuery = (
  filterData: ICombinedServiceDocumentFilter | undefined,
): ICombinedServiceDocumentQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, 'amount');

  return {
    ...query,
    ...amountFilterToQuery(filterData.amount) || {},
  };
};

const useCSDCollection = ({
  businessId,
}: IUseCSDCollectionParams): ICSDCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: window.Docyt.Common.Constants.DOCUMENT_TYPES.COMBINED_TYPE,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => {
    return csdFilterToQuery(filterData);
  }, [filterData]);

  const sorting = useSorting<TCombinedServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: csdDefaultSorting,
  });

  const query = useGetCombinedServiceDocuments({
    businessId,
    reconciliation:                true,
    reconciliationScope:           false,
    withoutPettyCash:              true,
    withoutInterSettlementAccount: true,
    withoutMatches:                true,
    filter:                        filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.documentId));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    sorting,
  };
};

interface IUseTSDCollectionParams {
  businessId: TID,
  includeUnverified: boolean,
}

interface ITSDCollection {
  query: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  section: TSection,
  selectedRecords: ITransactionServiceDocument[],
  resetSelector: () => void,
  sorting: ISorting<TTransactionServiceDocumentsSortColumn>,
}

const tsdDefaultSorting: ISortingParams<TTransactionServiceDocumentsSortColumn> = {
  orderColumn:    'vendor_id',
  orderDirection: 'desc',
};

const transactionTypes = ['expense', 'check', 'refund'];

const useTSDCollection = ({
  businessId,
  includeUnverified,
}: IUseTSDCollectionParams): ITSDCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: window.Docyt.Common.Constants.MATCH_TYPES.EXPENSE_TYPE,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const sorting = useSorting<TTransactionServiceDocumentsSortColumn>({
    section:        section.section,
    defaultSorting: tsdDefaultSorting,
  });

  const query = useGetTransactionServiceDocuments({
    businessId,
    excluded:            false,
    transactionType:     transactionTypes,
    reconciliationScope: false,
    includeUnverified,
    filter:              filterData,
    withMatches:         true,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    // Backend may return duplicate records.
    return uniqBy(flatten(pages.map((p) => p.collection)), (r) => r.id);
  }, [query?.data?.pages]);

  const { selected: selectedIds, reset: resetSelector } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    selectedRecords,
    resetSelector,
    sorting,
  };
};

export {
  ICSDCollection,
  ITSDCollection,
  useCSDCollection,
  useTSDCollection,
};
