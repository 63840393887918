import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { ChartOfAccountFilterField } from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

interface Props {
}

const Component: FC<Props> = () => {
  const business = useBusinessContext();
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('docyt_id') && (
      <Filter.TextField
        label="Journal Entry ID"
        name="docyt_id"
        placeholder="Journal Entry ID"
      />
      )) }
      { (visibleColumnNames.includes('entry_date') && (
      <Filter.DateField
        name="entry_date"
      />
      )) }
      <ChartOfAccountFilterField
        businessId={ business.id }
        name="chart_of_account_id"
        placeholder="Chart of Account"
      />
      { (visibleColumnNames.includes('debits') && (
      <Filter.AmountField
        name="debits"
        valueProps={ { fixedDecimalScale: false, placeholder: "Debits" } }
      />
      )) }
      { (visibleColumnNames.includes('credits') && (
      <Filter.AmountField
        name="credits"
        valueProps={ { fixedDecimalScale: false, placeholder: "Credits" } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
