import { TOrderDirection, TID } from '@src/types/common';
import {
  IInboxServiceDocument,
  TInboxServiceDocumentFlagState,
  IInboxServiceDocumentsFilter,
  TInboxServiceDocumentsSortColumn,
} from '@src/types/inbox_service_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPut } from './helpers';

interface IDeleteMultipleServiceDocumentsParams {
  inboxServiceId: TID,
  documentIds: number[],
}

interface IGetInboxServiceDocumentsParams {
  inboxServiceId: TID,
  inTrash?: boolean,
  filters?: IInboxServiceDocumentsFilter,
  orderColumn?: TInboxServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
}

interface IGetInboxServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IInboxServiceDocument[],
}

interface IEmptyTrashInboxServiceDocumentParams {
  serviceId: TID,
  isRestore: boolean,
}

interface IRenameInboxServiceDocumentParams {
  documentId: TID,
  name: string,
}

interface IRenameInboxServiceDocumentResponse {
  name: string,
}

interface ISetInboxServiceDocumentFlagParams {
  documentId: TID,
  flagState: TInboxServiceDocumentFlagState | undefined,
}

interface ISetInboxServiceDocumentFlagResponse {
  flagState: TInboxServiceDocumentFlagState,
}

const getInboxServiceDocuments = (
  params: IGetInboxServiceDocumentsParams,
): Promise<IGetInboxServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/inbox_service_documents',
    underscoreKeys({
      ...params,
      inTrash: params.inTrash ? 'true' : undefined,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta: {
        totalCount: cdata.total,
      },
      collection: cdata.documents as IInboxServiceDocument[],
    };
  });
};

const putEmptyTrashInboxServiceDocuments = (
  params: IEmptyTrashInboxServiceDocumentParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/inbox_service_documents/empty_trash',
    underscoreKeys({
      inboxServiceId: params.serviceId,
      restore:        params.isRestore ? 'true' : undefined,
    }),
  );
};

const putRenameInboxServiceDocument = (
  params: IRenameInboxServiceDocumentParams,
): Promise<IRenameInboxServiceDocumentResponse> => {
  return apiPut(
    '/api/v1/inbox_service_documents/set_name',
    underscoreKeys(params),
  ).then((data) => ({ name: data.name }));
};

const putSetInboxServiceDocumentFlag = (
  params: ISetInboxServiceDocumentFlagParams,
): Promise<ISetInboxServiceDocumentFlagResponse> => {
  return apiPut(
    '/api/v1/inbox_service_documents/set_flag',
    underscoreKeys(params),
  ).then((data) => ({ flagState: data.flag_state }));
};

const deleteMultipleInboxServiceDocuments = (
  params: IDeleteMultipleServiceDocumentsParams,
): Promise<void> => {
  return apiDelete(
    '/api/v1/inbox_service_documents/delete_multiple',
    underscoreKeys(params),
  );
};

export {
  IDeleteMultipleServiceDocumentsParams,
  IGetInboxServiceDocumentsParams,
  IGetInboxServiceDocumentsResponse,
  IEmptyTrashInboxServiceDocumentParams,
  IRenameInboxServiceDocumentParams,
  IRenameInboxServiceDocumentResponse,
  ISetInboxServiceDocumentFlagParams,
  ISetInboxServiceDocumentFlagResponse,
  deleteMultipleInboxServiceDocuments,
  getInboxServiceDocuments,
  putEmptyTrashInboxServiceDocuments,
  putRenameInboxServiceDocument,
  putSetInboxServiceDocumentFlag,
};
