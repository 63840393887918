import { QueryKey } from '@src/constants/query_keys';
import {
  IGetOnboardingStatusClientBusinessesParams,
  IGetOnboardingStatusClientBusinessesResponse,
  getOnboardingStatusClientBusinesses,
} from '@src/requests/onboarding_statuses';
import { IClientBusiness } from '@src/types/clients';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetOnboardingStatusClientBusinesses = createUseGetInfiniteCollection<
  IClientBusiness,
  IGetOnboardingStatusClientBusinessesParams,
  IGetOnboardingStatusClientBusinessesResponse
>({
  queryKey: QueryKey.onboardingStatusClientBusinesses,
  request:  getOnboardingStatusClientBusinesses,
});

export {
  useGetOnboardingStatusClientBusinesses,
};
