import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useEditReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { IEditReportServiceBudgetResponse } from '@src/requests/report_service/report_service_budgets';
import { TID } from '@src/types/common';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IEditBudgetFormValues } from '../form/edit_budget';

const FORM_ID = 'edit_budget_form';

interface IEditBudgetModalProps extends IUseModalProps {
  businessId: TID,
  budget: IReportServiceBudget,
}

const EditBudgetModal = ({
  businessId,
  budget,
  isOpen,
  onCancel,
}: IEditBudgetModalProps) => {
  const edit = useEditReportServiceBudget();

  const { mutate } = edit;
  const handleSubmit = useCallback((values: IEditBudgetFormValues) => {
    mutate(
      {
        id:   budget.id,
        name: `${values.name}`,
        year: values.year,
        type: `${values.type}`,
      },
      {
        onSuccess: (budgetResponse: IEditReportServiceBudgetResponse) => {
          Backbone.history.navigate(`/businesses/${businessId}/reports/budgets/${budgetResponse.budget.id}`, { trigger: true });
        },
      },
    );
  }, [budget, businessId, mutate]);

  const originalName = budget.name;
  const originalYear = budget.year;
  const originalType = budget.type;

  return (
    <>
      <MutationStatus mutation={ edit } />
      <Modal
        className="modal-position-middle"
        show={ isOpen }
        title={ `Edit ${budget.type}` }
      >
        <Modal.Body>
          {
            isOpen && (
              <Form
                formId={ FORM_ID }
                originalName={ originalName }
                originalYear={ originalYear }
                originalType={ originalType }
                onSubmit={ handleSubmit }
              />
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-link modal-link-btn"
            fontSize={ 16 }
            title="Cancel"
            onClick={ onCancel }
          />
          <Button
            bsColor="blue"
            fontSize={ 16 }
            fontVariant="semi-bold"
            form={ FORM_ID }
            title="Continue"
            type="submit"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useEditBudgetModal = makeUseModal(EditBudgetModal);

export {
  IEditBudgetModalProps,
  useEditBudgetModal,
  EditBudgetModal as default,
};
