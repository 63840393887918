module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, member, ref;
    
      __out.push('<ul class="p-t-0 member-sidebar-nav">\n  ');
    
      ref = this.members;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        member = ref[index];
        __out.push('\n  <li class="pointer">\n    <div class="member-item-wrapper member-item-js ');
        if (this.memberId === member.id) {
          __out.push(__sanitize('active'));
        }
        __out.push('" id="');
        __out.push(__sanitize(member.id));
        __out.push('">\n      <div class="team-setting-header">\n          <div class="avatar-wrapper">\n              ');
        if (member.avatar && member.avatar.avatar_url) {
          __out.push('\n              <img src="');
          __out.push(__sanitize(member.avatar.avatar_url));
          __out.push('" class=\'service-icon-font\'>\n              ');
        } else {
          __out.push('\n              <i class=\'icon icon-smile in-blue-400 service-icon-font\'></i>\n              ');
        }
        __out.push('\n          </div>\n          <span class="nav-item-text">');
        __out.push(__sanitize(member.parsed_fullname));
        __out.push('</span>\n          <span class="nav-item-number in-grey-1100 font-10-important">\n          ');
        __out.push(__sanitize(member.permission_type));
        __out.push('\n          </span>\n      </div>\n    </div>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}