import React from 'react';

import { IBankStatement } from '@src/types/bank_statements';

import Table from '@src/components/ui_v2/table';

import { amountFormatter } from './utils';

import styles from './styles.module.scss';

interface RowsProps {
  bankStatement: IBankStatement,
  children: React.ReactNode,
}

const WithSecondLevelRows = ({ bankStatement, children }: RowsProps) => {
  const isCreditCard =
    bankStatement.reconciliationPaymentAccount.accountType
      === window.Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE;
  return (
    <>
      {children}

      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Beginning Balance</Table.TextCell>
        <Table.AmountCell amount={ bankStatement.beginningBalance } formatter={ amountFormatter } />
        <Table.Cell colSpan={ 5 } />
      </Table.Row>

      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>
          { isCreditCard ? '+ Charges' : '- Withdrawals' }
        </Table.TextCell>
        <Table.AmountCell
          amount={ bankStatement.debits }
          formatter={ amountFormatter }
          negativeValue={ isCreditCard }
        />
        <Table.Cell colSpan={ 5 } />
      </Table.Row>

      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>
          { isCreditCard ? '- Payments' : '+ Deposits' }
        </Table.TextCell>
        <Table.AmountCell
          amount={ bankStatement.credits }
          formatter={ amountFormatter }
          negativeValue={ isCreditCard }
        />
        <Table.Cell colSpan={ 5 } />
      </Table.Row>

      <Table.Row className={ styles['row-second-level'] }>
        <Table.TextCell>Ending Balance</Table.TextCell>
        <Table.AmountCell
          amount={ String(bankStatement.closingBalance) }
          formatter={ amountFormatter }
        />
        <Table.Cell colSpan={ 5 } />
      </Table.Row>
    </>
  );
};

export default React.memo(WithSecondLevelRows);
