import React from 'react';

import { useReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { TDate } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import ReconcileAction from './reconcile_action';

import styles from './styles.module.scss';

interface HeaderProps {
  startDate: TDate,
  endDate: TDate,
}

const MonthEndHeader = ({ startDate, endDate }: HeaderProps) => {
  const reconciliationPaymentAccount = useReconciliationPaymentAccountContext();

  return (
    <DetailsRegion.Header
      className={ styles.header }
      divider="horizontal"
      subtitle="Review all the items to mark this account as reconciled."
      title={ reconciliationPaymentAccount.name }
    >
      <div className={ styles['statement-ending-date'] }>
        <span>Statement Period:</span>
        <br />
        { formatDate(startDate) }
        { ' to ' }
        { formatDate(endDate) }
      </div>
      <ReconcileAction />
    </DetailsRegion.Header>
  );
};

export default React.memo(MonthEndHeader);
