import React from 'react';

import { useGetAccountsPayableServiceSummary } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';
import { IAccountsPayableService } from '@src/types/services';
import { currencyFormater2 } from '@src/utils/currency';

import Text from '@src/components/ui/text';

import { BulkActions } from './actions/bulk_actions';

import styles from './styles.module.scss';

interface ISettlementsCounterInfoProps {
  businessId: TID,
  accountsPayableService?: IAccountsPayableService,
  type: string,
}

const SettlementsCounterInfo = ({
  type,
  businessId,
  accountsPayableService,
}: ISettlementsCounterInfoProps): JSX.Element | null => {
  const { data: serviceSummary } = useGetAccountsPayableServiceSummary(businessId, 'settlement');

  return (
    <div className={ styles['counters-container'] }>
      <div className={ styles['action-col'] }>
        {
          (type === 'settle_queued'
            && (
            <BulkActions
              userRole={ accountsPayableService?.businessServiceRole }
            />
            )
          )
        }
      </div>
      <div className="counter-wrapper">
        <div className="count-group">
          <Text as="label" className="count-label">
            Total Count
          </Text>
          <Text className="count-amount">
            { serviceSummary?.countValidPaying || 0 }
          </Text>
        </div>
        <div className="count-group">
          <Text as="label" className="count-label">
            Total Invoices
          </Text>
          <Text className="count-amount">
            { currencyFormater2(serviceSummary?.amountServiceDocuments || 0) }
          </Text>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SettlementsCounterInfo);
