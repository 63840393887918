import React, { createContext, useContext, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetCategorizedMetrics } from '@src/hooks/queries/metrics';
import { IMetric } from '@src/types/metrics';
import { apiMonthToDate, endOfMonthApiDate, apiDateToMonth, getUTCTimezone } from '@src/utils/date_helpers';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface CategorizedMetricContextType {
  businessMetrics: IMetric[];
  laborMetrics: IMetric[];
}

const CategorizedMetricContext = createContext<CategorizedMetricContextType | null>(null);

const useCategorizedMetricContext = () => {
  const value = useContext(CategorizedMetricContext);

  if (!value) {
    throw new Error('"useCategorizedMetricContext" must be used within <CategorizedMetricProvider />');
  }

  return value;
};

interface ICategorizedMetricProviderProps {
  children: React.ReactNode
}

const CategorizedMetricProvider = ({
  children }: ICategorizedMetricProviderProps) => {
  const business = useBusinessContext();
  const selectedMonthStr = apiDateToMonth(new Date().toISOString()) || new Date().toISOString();

  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetCategorizedMetrics({
    businessId: business.id,
    standardIndustryId: business.standardCategoryId,
    from: apiMonthToDate(selectedMonthStr),
    to: endOfMonthApiDate(getUTCTimezone(new Date(selectedMonthStr)))
  });

  const value = useMemo(() => {
    if (!data) return { businessMetrics: [], laborMetrics: [] };

    return {
      businessMetrics: data.businessMetrics,
      laborMetrics: data.laborMetrics,
    };
  }, [data]);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;

  return (
    <CategorizedMetricContext.Provider value={ value }>
      {children}
    </CategorizedMetricContext.Provider>
  );
};

export {
  useCategorizedMetricContext,
  CategorizedMetricProvider as default,
};