import React from 'react';

import { IMetric } from '@src/types/metrics';

import ChangeStartDate from './change_start_date_action';

interface IMetricItemActionsProps {
  metric: IMetric,
}

const MetricItemActions = ({
  metric,
}: IMetricItemActionsProps): JSX.Element => {
  return (
    <ChangeStartDate metric={ metric } />
  );
};

export default MetricItemActions;
