module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accounting_firm, i, len, ref;
    
      ref = this.accounting_firms;
      for (i = 0, len = ref.length; i < len; i++) {
        accounting_firm = ref[i];
        __out.push('\n<h3 class="font-16 font-semi-bold m-l-15 m-b-20">');
        __out.push(__sanitize(accounting_firm.name));
        __out.push('</h3>\n<div class="checkbox checkbox-primary checkbox-circle checkbox-singleline m-b-20">\n    <input type="radio" id="radioEnable" name="authorized_type" class="pointer radio-authorized-type" value="1" data-id="');
        __out.push(__sanitize(accounting_firm.id));
        __out.push('" ');
        if (accounting_firm.is_authorized_to_pay) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n    <label for="radioEnable">Authorize to generate ACH payments and Docyt Checks.</label>\n</div>\n<div class="checkbox checkbox-primary checkbox-circle checkbox-singleline m-b-30">\n    <input type="radio" id="radioDisable" name="authorized_type" class="pointer radio-authorized-type" value="0" data-id="');
        __out.push(__sanitize(accounting_firm.id));
        __out.push('" ');
        if (!accounting_firm.is_authorized_to_pay) {
          __out.push(__sanitize('checked'));
        }
        __out.push('>\n    <label for="radioDisable">Do not authorize to generate ACH payments and Docyt Checks.</label>\n</div>\n');
        if (accounting_firm.is_authorized_to_pay) {
          __out.push('\n<div class="accounting-firm-note">\n    <p>I, ');
          __out.push(__sanitize(Docyt.currentAdvisor.get('full_name')));
          __out.push(', authorize ');
          __out.push(__sanitize(accounting_firm.name));
          __out.push(', with offices at ');
          __out.push(__sanitize([accounting_firm.address_street, accounting_firm.address_street_2, accounting_firm.address_city, accounting_firm.address_state, accounting_firm.address_country].filter(Boolean).join(' ').trim()));
          __out.push(', to generate electronic ACH transfers and physical checks from my account for payment of payable invoices and related financial obligations (and, if necessary, electronically credit such account to correct erroneous debits).</p>\n</div>\n');
          if (this.accounting_firms.length > 1) {
            __out.push('<hr />');
          }
          __out.push('\n');
        }
        __out.push('\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}