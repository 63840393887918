import React from 'react';

import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import Delete from './delete_action';
import Edit from './edit_action';
import SetAsDefault from './set_default_action';

interface IBudgetItemActionsProps {
  budget: IReportServiceBudget,
  setAsDefault: (defaultBudgetId: string) => void,
}

const BudgetItemActions = ({
  budget,
  setAsDefault,
}: IBudgetItemActionsProps): JSX.Element => {
  return (
    <ActionsDropdown className="text-left">
      <Edit budget={ budget } />
      <SetAsDefault budget={ budget } setAsDefault={ setAsDefault } />
      <Delete budget={ budget } />
    </ActionsDropdown>
  );
};

export default BudgetItemActions;
