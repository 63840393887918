import React from 'react';

import styles from './styles.module.scss';

const Separator = () => {
  return (
    <hr className={ styles.separator } />
  );
};

export default Separator;
