import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useUniqueDomId } from '@src/hooks/dom';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import { useItemsSelector } from './hooks';

import styles from './styles.module.scss';

interface IItemsSelectorSelectAllCheckboxProps extends
  Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'onChange' | 'checked' | 'id' | 'value' | 'type'
  >,
  IUIStyleProps
{}

const ItemsSelectorSelectAllCheckbox = <Type, >({
  ...props
}: IItemsSelectorSelectAllCheckboxProps) => {
  const classes = classNames(styles.checkbox, 'checkbox checkbox-primary');
  const [inputClasses, inputProps] = uiStyleProps('pointer', props);

  const inputId = useUniqueDomId('item_selector_');

  const { markAll, isAllSelected } = useItemsSelector<Type>();

  const handleChange = useCallback((e) => {
    markAll(e.target.checked);
  }, [markAll]);

  return (
    <div className={ classes }>
      <input
        checked={ isAllSelected() }
        className={ inputClasses }
        id={ inputId }
        title="Select all"
        type="checkbox"
        onChange={ handleChange }
        { ...inputProps }
      />
      <label
        aria-label="Select all"
        htmlFor={ inputId }
      />
    </div>
  );
};

export default ItemsSelectorSelectAllCheckbox;
