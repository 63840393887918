import React, { useCallback, useMemo } from 'react';

import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import { components, StylesConfig } from 'react-select';

import { TOption } from '@src/components/ui_v2/inputs';
import ReactSelectInput from '@src/components/ui_v2/inputs/react_select/react_select_input';
import { FiltersIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IBusinessesDropdownProps
{
  value: TOption[],
  options: TOption[],
  onChange: (value: TOption[]) => void,
}

const MultiValue = React.memo(() => (null));

MultiValue.displayName = 'MultiValue';

const customStyles: StylesConfig = {
  control: (provided, { isDisabled, isFocused }) => (
    {
      ...provided,
      'position':     'relative',
      'padding':      styles['input-control-padding'],
      'background':   isDisabled ? styles['input-control-disabled-background'] : styles['input-control-background'],
      'borderRadius': styles['input-control-border-radius'],
      'border':       isFocused ? styles['input-control-hover-border'] : styles['input-control-border'],
      'boxShadow':    'none',
      '&:hover':      {
        border: styles['input-control-hover-border'],
      },
    }
  ),
  valueContainer: (provided) => (
    {
      ...provided,
      padding: styles['input-value-container-padding'],
    }
  ),
};

const BusinessesDropdown = ({
  options,
  value,
  onChange,
  ...props
}: IBusinessesDropdownProps) => {
  const isAllSelected = useMemo(() => {
    if (options.length === 0) return false;

    return isEqual(
      value.filter((item) => item.value !== '*').map((item) => item.value).sort(),
      options.map((item) => item.value).sort(),
    );
  }, [value, options]);

  const customOption = (optionProps: any) => (
    <components.Option { ...optionProps }>
      <div className="checkbox checkbox-primary">
        <input
          readOnly
          checked={ optionProps.isSelected || isAllSelected }
          className="pointer form-control"
          id={ `multi-select-option-${optionProps.value}` }
          type="checkbox"
        />
        <label htmlFor={ `multi-select-option-${optionProps.value}` }>{ optionProps.label }</label>
      </div>
    </components.Option>
  );

  const customControl = (controlProps: any) => (
    <components.Control { ...controlProps }>
      <div className={ styles['dropdown-button-container'] }>
        <FiltersIcon fontSize={ 20 } />
        All businesses
      </div>
      <span className={ styles['items-count'] }>
        { value.filter((option: TOption) => option.value !== '*').length }
      </span>
      { controlProps.children }
    </components.Control>
  );

  customOption.displayName = 'Option';
  customControl.displayName = 'Control';

  const handleSelect = useCallback((newValue, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      if (actionMeta.option.value === '*'
        || (!some(newValue, { value: '*' }) && newValue.length === options.length - 1)) {
        onChange(options);
      } else {
        onChange(newValue);
      }
    } else if (actionMeta.action === 'deselect-option') {
      if (actionMeta.option.value === '*') {
        onChange([]);
      } else if (some(newValue, { value: '*' })) {
        const newOptions = newValue.filter((option: TOption) => option.value !== '*');
        onChange(newOptions);
      } else {
        onChange(newValue);
      }
    }
  }, [onChange, options]);

  return (
    <ReactSelectInput
      { ...props }
      isMulti
      closeMenuOnSelect={ false }
      components={ {
        Option:              customOption,
        Control:             customControl,
        IndicatorsContainer: () => null,
        Placeholder:         () => null,
        MultiValue,
      } }
      hideSelectedOptions={ false }
      isClearable={ false }
      isSearchable={ false }
      options={ options }
      styles={ customStyles }
      value={ value }
      onChange={ handleSelect }
    />
  );
};

export default React.memo(BusinessesDropdown);
