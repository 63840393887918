import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IAchTransaction, TAchStatusStep } from '@src/types/accounts_payable/ach_transactions';
import { capitalize } from '@src/utils/transform_keys';

import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon, ExclamationIcon } from '@src/components/utils/icomoon';

import {
  getDwollaCreatedAt,
  statusValue,
} from '../utils';

import styles from './styles.module.scss';

type TAchStatusStepProps = {
  step: TAchStatusStep,
  isActivated?: boolean,
  isChecked?: boolean,
  document: IAchTransaction,
  onCancelInvoice?: () => void,
}

const AchStatusStep = ({
  step,
  isActivated,
  isChecked,
  document,
  onCancelInvoice,
}: TAchStatusStepProps) => {
  const stepClasses = classNames(
    styles.step,
    styles[`step-${step}`],
    { [styles.activated]: isActivated },
    { [styles.checked]: isChecked },
  );

  const stepCircleCheckMark = () => {
    if (isChecked) {
      return (
        <CheckGreenIcon className={ styles['check-mark-icon'] } />
      );
    }
    if (['failed', 'cancelled'].includes(step)) {
      return (
        <ExclamationIcon className={ styles['check-mark-icon'] } />
      );
    }

    return null;
  };

  const stepSubtitleContent = () => {
    if (step === 'created') {
      return getDwollaCreatedAt(document);
    } if (step === 'failed') {
      return (
        <span>
          ACH Transfer has failed and the invoice will need to be cancelled.
          This will move the invoice back to the invoice queue.
        </span>
      );
    }

    return null;
  };

  const handleCancelInvoice = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onCancelInvoice) onCancelInvoice();
  }, [onCancelInvoice]);

  const stepTextWrapperContent = () => {
    if (step === 'failed-completed') {
      return (
        <Button
          className={ styles['step-button'] }
          variant="primary"
          onClick={ handleCancelInvoice }
        >
          Cancel Invoice
        </Button>
      );
    }

    return (
      <>
        <div className={ styles['step-title'] }>
          { capitalize(statusValue(step)) }
        </div>
        {
          (['created', 'failed'].includes(step)) && (
            <div className={ styles['step-subtitle'] }>
              { stepSubtitleContent() }
            </div>
          )
        }
      </>
    );
  };

  return (
    <div className={ stepClasses }>
      <div className={ styles['step-content'] }>
        <span className={ styles['step-circle'] }>
          { stepCircleCheckMark() }
        </span>
        <div className={ styles['step-text-wrapper'] }>
          { stepTextWrapperContent()}
        </div>
      </div>
    </div>
  );
};

export default AchStatusStep;
