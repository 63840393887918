import { createContext, useContext } from 'react';

import { IReconciliationCenter } from '@src/types/reconciliation_centers';

const ReconciliationCenterContext = createContext<IReconciliationCenter | null>(null);

const useReconciliationCenterContext = (): IReconciliationCenter => {
  const value = useContext(ReconciliationCenterContext);

  if (!value) {
    throw new Error('"useReconciliationCenterContext" must be used within <ReconciliationCenterProvider />');
  }

  return value;
};

export {
  ReconciliationCenterContext,
  useReconciliationCenterContext,
};
