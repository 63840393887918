import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import { IBusinessesInput } from '@src/components/vendors/my_vendors/views/businesses/modal/schema';

import Form from './business_form';

interface IEditLinkedBusinessesModalProps extends Omit<IUseModalProps, 'onDone'> {
  linkedBusinessIds: TID[],
  onDone: (businesses: IBusiness[]) => void,
  coaId?: TID,
  editModel: boolean
}

const LinkedBusinessModal = ({
  linkedBusinessIds,
  isOpen,
  onCancel,
  onDone,
  coaId,
  editModel,
}: IEditLinkedBusinessesModalProps): JSX.Element => {
  const handleSubmit = useCallback((values: IBusinessesInput) => {
    onDone(values.businesses);
    onCancel();
  }, [onCancel, onDone]);

  return (
    <Modal.Form
      dialogClassName="edit-linked-businesses-modal"
      formId="linked_businesses_form"
      proceedTitle={ editModel ? 'Save changes' : 'Add' }
      show={ isOpen }
      title={ editModel ? 'Edit Linked Businesses' : 'Add Business(es)' }
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          coaId={ coaId }
          formId={ formId }
          linkedBusinessIds={ linkedBusinessIds }
          onSubmit={ handleSubmit }
        />
      )}
    </Modal.Form>
  );
};

const useLinkedBusinessesModal = makeUseModal(LinkedBusinessModal);

export default useLinkedBusinessesModal;
