import React from 'react';

import { TID } from '@src/types/common';

import CollapseSection from '@src/components/ui/form/collapse_section';

import List from './list';

interface IJournalEntryDetailsProps {
  transactionId: TID;
  adjustmentEntry: any;
}

const RelatedTransactionDetails = ({
  transactionId,
  adjustmentEntry,
}: IJournalEntryDetailsProps): JSX.Element => {
  return (
    <CollapseSection title="JOURNAL ENTRIES">
      <List adjustmentEntry={ adjustmentEntry } transactionId={ transactionId } />
    </CollapseSection>
  );
};

export { IJournalEntryDetailsProps, RelatedTransactionDetails as default };
