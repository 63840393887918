import React, { useCallback } from 'react';

import { getAccountsPayableServiceDocument } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { WrenchIcon } from '@src/components/utils/icomoon';

type TReportIssueActionProps = {
  document: IAchTransaction,
}

const ReportIssueAction = ({
  document,
}: TReportIssueActionProps) => {
  const handleSelect = useCallback(() => {
    if (!document.serviceDocumentIds) return;

    getAccountsPayableServiceDocument(document.serviceDocumentIds[0])
      .then((res) => {
        const documentId = res.documentId;
        window.Docyt.vent.trigger('ap_service:document:report:issue', documentId);
      });
  }, [document.serviceDocumentIds]);

  return (
    <ActionsDropdown.Action
      icon={ <WrenchIcon fontSize={ 20 } /> }
      title="Report an Issue"
      onSelect={ handleSelect }
    />
  );
};

export default ReportIssueAction;
