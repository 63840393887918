import { array, object, string } from 'yup';

import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TDateRange, TID } from '@src/types/common';
import { TDataExportType } from '@src/types/data_exports';

import { TOption } from '@src/components/ui_v2/inputs';

interface IAddDataExportModalFormValues {
  date: TDateRange,
  exportType: TDataExportType,
  includeOption: string,
  businessId: TID,
  multiBusinesses: TOption[],
  multiChartOfAccounts: IChartOfAccount[],
}

const addDataExportValidation = object({
  exportType: string().trim().required('Export type is required.'),
  businessId: string().test((value, validationContext) => {
    const { createError,
      parent: {
        exportType,
      } } = validationContext;

    if ([window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT,
      window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF,
    ].includes(exportType)) return true;

    if (value === undefined) return createError({ message: 'Business is required' });
    return true;
  }),
  date: object()
    .when(
      'exportType',
      {
        is:        window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT,
        then:      (schema) => schema,
        otherwise: (schema) => schema.shape({ startDate: string().required('Date is required.') }),
      },
    ),
  multiBusinesses: array().test((value, validationContext) => {
    const { createError,
      parent: {
        exportType,
      } } = validationContext;

    if ([window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT,
      window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF,
    ].includes(exportType) && (!value || value.length === 0)) {
      return createError({ message: 'You need to select at least one Business.' });
    }
    return true;
  }),
  multiChartOfAccounts: array().test((value, validationContext) => {
    const { createError,
      parent: {
        exportType,
      } } = validationContext;

    // eslint-disable-next-line max-len
    if (exportType !== window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.PL_CHART_OF_ACCOUNTS_XLS) return true;

    if (!value || value.length === 0) {
      return createError({ message: 'You need to select at least one Chart of Account.' });
    }
    return true;
  }),
});

export {
  IAddDataExportModalFormValues,
  addDataExportValidation,
};
