import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, useController } from 'react-hook-form';
import { object, string } from 'yup';

import { TDate } from '@src/types/common';

import Form from '@src/components/ui/form';

interface IAddMetricsFormValues {
  name: string,
  startDate: TDate,
  frequency: string,
  dataType: string,
}

const addMetricValidation = object({
  name:      string().trim().required('Metric Name is required.'),
  startDate: string().trim().required('Start Date is required.'),
  frequency: string().trim().required('Frequency is required.'),
  dataType:  string().trim().required('Data Type is required.'),
});

const frequencyOptions = [
  { name: 'Daily', value: 'Daily' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Monthly', value: 'Monthly' },
];

const dataTypeOptions = [
  { name: 'Integer', value: 'integer' },
  { name: 'Float', value: 'float' },
];

interface IAddMetricsFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IAddMetricsFormValues>,
}

const AddMetricsForm = ({
  formId,
  onSubmit,
}: IAddMetricsFormProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddMetricsFormValues>({
    defaultValues: {
      name:      '',
      startDate: '',
      frequency: 'daily',
      dataType:  'integer',
    },
    resolver: yupResolver(addMetricValidation),
  });

  const startDateController = useController({
    name: 'startDate',
    control,
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        { ...register('name') }
        error={ errors.name?.message }
        label="Metrics Name"
        placeholder="Enter Metrics Name"
      />
      <Form.DateField
        error={ errors.startDate?.message }
        label="Start Date"
        placeholder="Select Start Date"
        { ...startDateController.field }
      />
      <Form.SelectField
        { ...register('frequency') }
        label="Frequency"
        options={ frequencyOptions }
        placeholder="Select Frequency"
      />
      <Form.SelectField
        { ...register('dataType') }
        label="Data Type"
        options={ dataTypeOptions }
        placeholder="Select Data Type"
      />
    </Form>
  );
};

export {
  IAddMetricsFormProps,
  IAddMetricsFormValues,
  AddMetricsForm as default,
};
