import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { TAccountType, TTransactionImportType } from '@src/types/common';
import { API_DATE_FORMAT, formatDate, startOfMonthDate } from '@src/utils/date_helpers';

import ClickTooltip from '@src/components/common_v2/click_tooltip/click_tooltip';
import Form from '@src/components/ui/form';

import ChartOfAccountsField from '../settings/chart_of_accounts/chart_of_accounts_field';
import BankAccountsField from '../settings/financial_institution_bank_accounts/bank_accounts_field';
import { ISetupAccountData, setupAccountValidation } from './schema';

import styles from '../styles.module.scss';

interface ISetupAccountFormProps {
  accountMode: TTransactionImportType,
  accountType: TAccountType,
  business: IBusiness,
  formId: string,
  onSubmit: (data: ISetupAccountData) => void;
}

const SetupAccountForm = ({
  accountMode,
  accountType,
  business,
  formId,
  onSubmit,
}: ISetupAccountFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    setFocus,
  } = useForm<ISetupAccountData>({
    defaultValues: {
      startImportDate: formatDate(startOfMonthDate(new Date()), API_DATE_FORMAT),
      accountMode,
    },
    mode:     'onBlur',
    resolver: yupResolver(setupAccountValidation),
  });
  const [showTooltip, setShowTooltip] = useState<boolean|undefined>(undefined);

  const qboController = useController({ name: 'qbo', control });
  const startBalanceController = useController({ name: 'startBalance', control });
  const startImportDateController = useController({ name: 'startImportDate', control });
  const bankAccountController = useController({ name: 'bankAccount', control });

  const handleClearStartingBalance = useCallback((agree: boolean) => {
    setFocus('startBalance');
    if (!agree) {
      setValue('startBalance', '');
    }
    setShowTooltip(false);
  }, [setValue, setFocus]);

  useEffect(() => {
    if (showTooltip === undefined
      && accountType === 'credit_card' && Number(startBalanceController.field.value) < 0) {
      setShowTooltip(true);
    }
  }, [accountType, showTooltip, startBalanceController.field.value]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Account Name"
        placeholder="Account Name - XXXX"
        { ...register('name') }
      />
      <Form.TextField
        error={ errors.last4?.message }
        label={ accountType === 'bank' ? 'Last 4 digits' : 'Credit Card Number' }
        mark={ accountType === 'bank' ? '' : '(last 4 digits)' }
        maxLength={ 4 }
        placeholder="XXXX"
        { ...register('last4') }
      />
      {
        accountType !== 'credit_card' && accountMode !== 'auto' && (
          <Form.TextField
            label="Routing Number"
            mark="(optional)"
            placeholder="Routing Number"
            { ...register('routingNumber') }
          />
        )
      }
      {
        accountType === 'credit_card' && accountMode !== 'auto' && (
          <Form.TextField
            label="CVV #"
            mark="(optional)"
            placeholder="CVV #"
            { ...register('cvv') }
          />
        )
      }
      <ChartOfAccountsField
        accountType={ accountType }
        error={ errors.qbo?.id?.message }
        label="Chart of Account"
        value={ qboController.field.value }
        onChange={ qboController.field.onChange }
      />
      <Form.DateField
        error={ errors.startImportDate?.message }
        label="Starting Date"
        placeholder="Starting Date"
        { ...startImportDateController.field }
      />
      <ClickTooltip
        content={ (
          <Form.AmountField
            label="Starting Balance"
            placeholder="Starting Balance (Optional)"
            { ...startBalanceController.field }
          />
        ) }
        showTooltip={ showTooltip }
        title={
          window.Docyt.Common.Constants.Messages.BANKING_CREDIT_START_BALANCE_CONFIRM_MSG
        }
        onClick={ handleClearStartingBalance }
      />
      { accountType === 'credit_card' && (
        <span className={ styles['credit-card-hint'] }>
          { window.Docyt.Common.Constants.Messages.BANKING_CREDIT_CARD_HINT }
        </span>
      ) }
      { accountMode === 'auto' && (<hr />) }
      { accountMode === 'auto' && (
        <BankAccountsField
          businessId={ business.id }
          error={ errors.bankAccount?.bankAccountId?.message }
          value={ bankAccountController.field.value }
          onChange={ bankAccountController.field.onChange }
        />
      )}
    </Form>
  );
};

export default SetupAccountForm;
