import { useCallback, useEffect, useMemo } from 'react';

import { flatten } from 'lodash';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetAchTransactions } from '@src/hooks/queries/accounts_payable/ach_transactions';
import { useSorting } from '@src/hooks/url_params';
import { IGetAchTransactionsResponse } from '@src/requests/accounts_payable/ach_transactions';
import { IAchTransaction, TAchTransactionsSortColumn } from '@src/types/accounts_payable/ach_transactions';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseAchTransactionsCollectionParams {
  businessId: TID,
}

interface IAchTransactionsCollection {
  query: UseInfiniteQueryResult<IGetAchTransactionsResponse, Error>,
  records: IAchTransaction[],
  section: TSection,
  sorting: ISorting<TAchTransactionsSortColumn>,
}

const achTransactionsDefaultSorting: ISortingParams<TAchTransactionsSortColumn> = {
  orderColumn:    'receiver_name',
  orderDirection: 'desc',
};

const useAchTransactionsCollection = ({
  businessId,
}: IUseAchTransactionsCollectionParams): IAchTransactionsCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsPayableAchTransactions,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const sorting = useSorting<TAchTransactionsSortColumn>({
    section:        section.section,
    defaultSorting: achTransactionsDefaultSorting,
  });

  const query = useGetAchTransactions({
    businessId,
    filters: filterData,
    ...sorting.data,
  });

  const { refetch: refetchAchTransactions } = query;

  const handleInvoiceStateChanged = useCallback(() => {
    refetchAchTransactions();
  }, [refetchAchTransactions]);

  useEffect(() => {
    window.Docyt.vent.on('invoice:state:changed', handleInvoiceStateChanged);

    return () => {
      window.Docyt.vent.off('invoice:state:changed');
    };
  }, [handleInvoiceStateChanged]);

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IAchTransactionsCollection,
  useAchTransactionsCollection,
};
