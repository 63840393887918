module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog modal-lg modal-xlg relative invoice-resolve-duplication-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="font-24 font-bold m-l-auto m-r-auto m-t-5">Resolve Duplicate Invoice</h1>\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n      </div>\n      <div class="modal-body">\n        <div class="clients-drop-zone height-100-percent">\n          <div class="client-main-pane pdf-client-document-wrapper left-document-view-wrapper">\n            <div id="duplicate-document-view-region"></div>\n            <div class="invoice-actions-region row">\n                <div class="col-sm-12 col-md-7 text-center m-t-minus-5">\n                  <div class="display-flex">\n                    <div class="header__userpic inline-block m-l-10 m-t-5">\n                      <img src="');
    
      __out.push(__sanitize(this.duplicated_service_document.get('vendor_avatar_url')));
    
      __out.push('" class="header__userpic-img">\n                    </div>\n                    <div class="">\n                      <p class="font-20 m-l-15 m-b-0 vertical-align-top">');
    
      __out.push(__sanitize(this.duplicated_service_document.get('vendor_name')));
    
      __out.push('</p>\n                      <p class="m-l-15 text-left m-b-0">\n                        <span class="font-16 in-grey-900">Inv: ');
    
      __out.push(__sanitize(this.duplicated_service_document.get('invoice_number')));
    
      __out.push('</span>\n                        <span class="font-16 in-grey-900">| ');
    
      __out.push(__sanitize(this.duplicated_service_document.get('invoice_date')));
    
      __out.push('</span>\n                      </p>\n                    </div>\n                  </div>\n                </div>\n                <div class="col-sm-12 col-md-5">\n                    ');
    
      if (this.isPaid) {
        __out.push('\n                    <button class="btn dropdown-toggle pull-right text-center width-150px invoice-paid-button paid duplicated-invoice-link" type="button">\n                      <span class="font-15 font-bold">');
        __out.push(__sanitize(this.paid_info));
        __out.push('</span>\n                      <br/>\n                      <span class="font-12">');
        __out.push(__sanitize(this.button_text));
        __out.push('</span>\n                    </button>\n                    ');
      } else {
        __out.push('\n                    <button class="btn dropdown-toggle font-15 font-bold invoice-amount-button pull-right width-150px ');
        __out.push(__sanitize(this.button_class));
        __out.push(' duplicated-invoice-link" type="button">\n                      ');
        __out.push(__sanitize(this.button_text));
        __out.push('\n                    </button>\n                    ');
      }
    
      __out.push('\n                </div>\n            </div>\n          </div>\n          <div class="client-main-pane pdf-client-document-wrapper right-document-view-wrapper">\n            <div id="original-document-view-region"></div>\n            <div class="invoice-actions-region row">\n              <div class="col-sm-12 col-md-4 text-center">\n                <a class="pointer text-center font-14 invoice-chat-action-link" href="/document/');
    
      __out.push(__sanitize(this.document.get('id')));
    
      __out.push('/chat">\n                  <i class="icon icon-chat-round font-24"></i><br>\n                  CHAT\n                </a>\n              </div>\n              <div class="col-sm-12 col-md-4 text-center">\n                <a class="pointer text-center font-14 verify-invoice-btn">\n                  <i class="icon icon-check font-24"></i><br>\n                  NOT A DUPLICATE\n                </a>\n              </div>\n              <div class="col-sm-12 col-md-4 text-center">\n                <a class="pointer text-center font-14 in-red-400 delete-duplicated-invoice-btn">\n                  <i class="icon icon-trashcan font-24"></i><br>\n                  DELETE DUPLICATE\n                </a>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\'clients-drop-zone\'>\n          <div class="client-main-pane pdf-client-document-wrapper">\n            <div id=\'invoice-details-region\' ></div>\n          </div>\n          <div id="invoice-document-right-side-region" class="client-document-right-side-region"></div>\n        </div>\n      </div>\n    </div>\n    <div id="vendor-add-side-region">\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}