module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div>\n  <div class="clearfix">\n    <h2 class="m-b-5 font-bold font-26 pull-left">Summary</h2>\n    <button type=\'button\' class="btn btn-info ladda-button btn-add-report m-t-15 pull-right" data-color="blue" data-style="expand-right"><span class="ladda-label">Add Revenue Report</span></button>\n  </div>\n  <hr/>\n  <div class="revenue-summary-chart-container">\n    <h3 class="font-18 font-semi-bold m-l-10 pull-left">Revenue By Month</h3>\n    <div class="pull-right m-r-15 m-t-15">\n      <p class="m-b-0 text-muted">YTD Revenue</p>\n      <p class="m-b-0 font-18 text-right"><strong>');
    
      __out.push(__sanitize(this.ytd_revenue));
    
      __out.push('</strong></p>\n    </div>\n    <div style="clear: both; height: 200px; padding-left: 10px;">\n      <canvas id="revenue-month-chart"></canvas>\n    </div>\n    <div class="relative" style="height: 7px;">\n      <span class="fa fa-caret-down font-24 show-coa-detail-btn hidden"></span>\n    </div>\n    <hr/>\n    <h3 class="font-18 font-semi-bold m-l-10 pull-left">Revenue by Chart of Account  |  <span class="label-selected-month">YTD</span></h3>\n    <a class="pointer btn-close-category-chart font-36 pull-right m-r-15 hidden" style="color: #423C3E;"><span aria-hidden="true">&times;</span></a>\n    <div class="category-chart-container" style="clear: both; padding-left: 10px;">\n      <canvas id="revenue-by-category-chart"></canvas>\n    </div>\n  </div>\n  ');
    
      if (this.business.get('split_revenue_receivables')) {
        __out.push('\n  <div class="clearfix m-t-30 hidden">\n    <h3 class="font-18 font-semi-bold m-l-10 pull-left">Receivables per Payment Processor</h3> \n  </div>\n  <div class="m-t-10 bg-white-rows receivables-payment-processor-region hidden">\n  </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}