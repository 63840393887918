module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="p-l-20 p-r-20 p-t-20 display-flex align-items-center item-divider relative font-14">\n  <h4 class=\'font-18 font-semi-bold text-left p-b-10\'>Multiple Months</h4>\n  <div class="dropdown pull-right m-l-auto">\n    <button class="btn btn-secondary dropdown-toggle header-icon-button m-l-10" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n      <span class="icon icon-more-icon font-22"></span>\n    </button>\n    <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">\n      <a class="dropdown-item pointer in-red-400 delete-accrual-js">\n        <i class="icon icon-trashcan m-r-10 m-t-3"></i>\n        <span>Cancel</span>\n      </a>\n    </div>\n  </div>\n</div>\n<div class="display-flex-column m-t-25 m-l-20 m-r-20">\n  <div class="display-flex align-items-center">\n    <label class="inline-wrapper p-t-3 p-l-5 width-140px" for="">Period Start Date</label>\n    <input type="text" class="inline-wrapper form-control start-date-js" value="');
    
      __out.push(__sanitize(this.accrual_start_date));
    
      __out.push('" autocomplete="off">\n  </div>\n  <p class="in-red-400 m-t-5 main-input__hint p-l-145 start-invalid-js"><i class="fa fa-warning"></i> Start Date is required.</p>\n  <div class="display-flex align-items-center m-t-10">\n    <label class="inline-wrapper p-t-3 p-l-5 width-140px">Period End Date</label>\n    <input type="text" class="inline-wrapper form-control end-date-js" value="');
    
      __out.push(__sanitize(this.accrual_end_date));
    
      __out.push('" autocomplete="off">\n  </div>\n  <p class="in-red-400 m-t-5 main-input__hint p-l-145 end-invalid-js"><i class="fa fa-warning"></i> End Date is required.</p>\n</div>\n<div class="display-flex align-items-center m-l-20 m-r-20">\n  <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n    <input type="radio" id="radioAuto" name="schedule" class="pointer radio-schdule-type schedule-auto-js" ');
    
      if (this.isAutoSchedule) {
        __out.push('checked');
      }
    
      __out.push('>\n    <label for="radioAuto">Automatic Schedule</label>\n  </div>\n  <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n    <input type="radio" id="radioManual" name="schedule" class="pointer radio-schdule-type schedule-manual-js" ');
    
      if (!this.isAutoSchedule) {
        __out.push('checked');
      }
    
      __out.push('>\n    <label for="radioManual">Custom Schedule</label>\n  </div>\n</div>\n<div class="entry-list-region" id="entry-list-region"></div>\n<div class="display-flex align-items-center width-100-percent m-t-auto m-b-20 p-l-20 p-r-20">\n  <div class=\'text-center\'>\n    <a class=\'cancel settings-cancel-btn\'>Go back</a>\n  </div>\n  <div class=\'m-l-auto text-center\'>\n    <button type=\'button\' class="btn btn-info ladda-button width-260px save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}