module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="request-detail-section">\n  ');
    
      if (this.isBankStatementRequest) {
        __out.push('\n    <div class="detail-group">\n      <label>Document Type</label>\n      <span>');
        __out.push(__sanitize(this.bank_statement.type));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Bank Account</label>\n      <span>');
        __out.push(__sanitize(this.bank_statement.reconciliation_payment_account.name));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Statement Date</label>\n      <span>');
        __out.push(__sanitize(moment(this.bank_statement.date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('</span>\n    </div>\n  ');
      } else if (this.isBalanceSheetStatementRequest) {
        __out.push('\n    <div class="detail-group">\n      <label>Document Type</label>\n      <span>Other Balance Sheet Account</span>\n    </div>\n    <div class="detail-group">\n      <label>Balance Sheet Account</label>\n      <span>');
        __out.push(__sanitize(this.balance_sheet_statement.chart_of_account_name));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Statement Date</label>\n      <span>');
        __out.push(__sanitize(moment(this.balance_sheet_statement.date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Description</label>\n      <span>');
        __out.push(__sanitize(this.balance_sheet_statement.description));
        __out.push('</span>\n    </div>\n  ');
      } else {
        __out.push('\n     <div class="detail-group">\n      <label>Date</label>\n      <span>');
        __out.push(__sanitize(moment(this.created_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Request Name</label>\n      <span>');
        __out.push(__sanitize(this.name));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Description</label>\n      <span>');
        __out.push(__sanitize(this.description));
        __out.push('</span>\n    </div>\n    <div class="detail-group">\n      <label>Type</label>\n      <span>Mailroom</span>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n<h2 class="document-section-title">DOCUMENT</h2>\n<div id="document-list-region"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}