module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="multi-entity-container">\n  <div class="display-flex item-divider p-b-10 p-t-10"> \n    <div class="service-main-title pull-left">\n      <h2>Advanced Reports</h2>\n    </div>\n    <div class="btn-container">\n      <button class="btn btn-add-document pull-right">\n        <i class="icon icon-plus font-20"></i>\n      </button>\n      <div class="m-r-15 inline-block m-l-auto consolidated-report-download-btn">\n        <a class="pointer relative display-inline-flex">\n          <button\n            class="pointer dropdown-toggle action-menu-toggle-btn pull-right"\n            type="button" id="bank-action-dropdown-btn"\n            data-toggle="dropdown" data-container="body"\n            aria-haspopup="true" aria-expanded="false"\n          >\n            <i class=\'icon icon-3-dots font-20\'></i>\n          </button>\n          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="bank-action-dropdown-btn" style="margin: 0">\n            <button class="dropdown-item font-14 export-report">\n              <i class="icon icon-Export-as-Excel font-18"></i> <span>Download Consolidated Report</span>\n            </button>\n          </div>\n        </a>\n      </div>\n    </div>\n  </div>\n  <div id="export-data-banner" class="m-t-10"></div>\n  <div class="reports-table-container"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}