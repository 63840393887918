import React from 'react';

interface IFullPageFooterFillerProp {
  children?: React.ReactNode;
}

const FullPageFooterFiller = ({ children }: IFullPageFooterFillerProp): JSX.Element | null => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{ children }</>;
};

export default FullPageFooterFiller;
