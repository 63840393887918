import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { WarningIcon } from '@src/components/utils/fa_icons';

import styles from '../../styles.module.scss';

interface IAccountSelectFieldProps {
  error?: string,
  onSelect: () => void
}

const AccountSelectField = ({
  error,
  onSelect,
}: IAccountSelectFieldProps) => {
  return (
    <>
      <Button
        bsStyle="link"
        className={ styles['select-account-btn'] }
        onClick={ onSelect }
      >
        Select
      </Button>
      {
        error && (
          <p className={ styles['select-account-error'] }>
            <WarningIcon />
            {' '}
            { error }
          </p>
        )
      }
    </>
  );
};

export default AccountSelectField;
