import React from 'react';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import Cell, { ITableCellProps } from '@src/components/ui_v2/table/cell';

interface ISelectTableItemCellProps<Type> extends Omit<ITableCellProps, 'children'> {
  item: Type,
  disabled?: boolean
}

const ReportsExportTableSelectItemCell = <Type, >({
  disabled,
  ...cellProps
}: ISelectTableItemCellProps<Type>) => {
  return (
    <Cell { ...cellProps }>
      <CheckboxInput
        small
        checked={ !disabled }
        disabled={ disabled }
      />
    </Cell>
  );
}

export default ReportsExportTableSelectItemCell;