import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const GrayPhone = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="grey-phone"
    />
  );
};

export default GrayPhone;
