module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog address-modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header item-divider">\n        <h4 class="font-28 font-bold text-center">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body">\n          <div class="">\n              <div class="account-type__data-area p-r-10 p-l-10">\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Businesses</label>\n                  <div class="multiselect-business-region width-100-percent"></div>\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Receiver Type</label>\n                  <select class="form-control select-type-wrapper">\n                    <option value="business" ');
    
      if ('business' === this.type) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Business</option>\n                    <option value="individual" ');
    
      if ('individual' === this.type) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push(' >Individual</option>\n                  </select>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap business-type-js">\n                  <label class="form-label width-140px font-15" for="">Receiver Business Name</label>\n                  <input type="text" class="acount-type__input form-control input-business-name-js" placeholder="Enter Business Name" value = "');
    
      __out.push(__sanitize(this.business_name));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap individual-type-js">\n                  <label class="form-label width-140px font-15" for="">Name</label>\n                  <div class="city-state-zip m-r-10 relative">\n                    <input type="text" class="acount-type__input form-control input-first-name-js" placeholder="First Name" value = "');
    
      __out.push(__sanitize(this.first_name));
    
      __out.push('">\n                    <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                  </div>\n                  <div class="city-state-zip relative">\n                    <input type="text" class="acount-type__input form-control input-las-name-js" placeholder="Last Name" value = "');
    
      __out.push(__sanitize(this.last_name));
    
      __out.push('">\n                    <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                  </div>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Receiver Bank Name</label>\n                  <input type="text" class="acount-type__input form-control input-bank-name-js" placeholder="Enter Bank Name" value = "');
    
      __out.push(__sanitize(this.bank_name));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Receiver Email Address</label>\n                  <input type="text" class="acount-type__input form-control input-email-js" placeholder="Enter Vendor’s Email Address" value = "');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Account Number</label>\n                  <input type="password" class="acount-type__input form-control input-account-js" oncopy="return false;" onpaste="return false;" ondrop="return false;" autocomplete="off" placeholder="Enter Bank Account Number" value = "');
    
      __out.push(__sanitize(this.account_number));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                  <div class="enter-account-tools">\n                    <i class="fa fa-solid fa-eye show-account-number-btn pointer"></i>\n                    <i class="fa fa-solid fa-eye-slash hide-account-number-btn disabled pointer"></i>\n                  </div>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Re-Enter Account Number</label>\n                  <div class="width-100-percent">\n                    <div>\n                      <div class="re-acount-type__input-wrap main-input-wrap">\n                        <input type="password" class="acount-type__input form-control input-confirm-account-js" oncopy="return false;" onpaste="return false;" ondrop="return false;" autocomplete="off" placeholder="Re-Enter Bank Account Number" value = "');
    
      __out.push(__sanitize(this.account_number));
    
      __out.push('">\n                        <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                        <div class="enter-account-tools">\n                          <i class="fa fa-solid fa-eye show-re-account-number-btn pointer"></i>\n                          <i class="fa fa-solid fa-eye-slash hide-re-account-number-btn disabled pointer"></i>\n                          <i class="fa fa-solid fa-check matched-js in-green-600 disabled"></i>\n                          <i class="fa fa-solid fa-times-circle unmatched-js in-red-100 disabled"></i>\n                        </div>\n                      </div>\n                      <p class="in-red-400 account-dont-match">\n                        Account Number doesn\'t match\n                      </p>\n                    </div>\n                  </div>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Routing Number</label>\n                  <input type="text" class="acount-type__input form-control input-routing-js" placeholder="Enter Bank Routing Number" value = "');
    
      __out.push(__sanitize(this.route_number));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for=""></label>\n                  <div>\n                    <div class="checkbox m-t-0 m-b-10">\n                      <input type="checkbox" class="styled form-control pointer input-element confirm-authorization-js" data-type="checkbox" ');
    
      __out.push(__sanitize(this.eligibility_1099 ? 'checked' : ''));
    
      __out.push('>\n                      <label>\n                        <p>By checking this box, you confirm you have received written authorization from this vendor to:</p>\n                      </label>\n                    </div>\n                    <div class="p-l-35">\n                      <div class="notice-item m-b-3">\n                        <p>•</p>\n                        <p class="txt">Collect, use, and share the user\'s personal identifying and banking account information with Docyt, Inc.</p>\n                      </div>\n                      <div class="notice-item">\n                        <p>•</p>\n                        <p class="txt">Originate transfers to this bank account.</p>\n                      </div>\n                    </div>\n                    <div class="p-l-25">\n                      <div class="notice-item">\n                        <p class="txt other-txt-size">You must be able to provide a copy of this authorization to Docyt, Inc. at any time upon request.</p>\n                      </div>\n                    </div>\n                  </div>\n                </div>\n              </div>\n          </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue bank-save-btn" ');
    
      if (!this.eligibility_1099) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push('>Save</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}