import React, { useCallback } from 'react';

import { useDownloadDataExport } from '@src/hooks/queries/data_exports';
import { dataExportPath } from '@src/routes';
import { IDataExport } from '@src/types/data_exports';
import { downloadFile } from '@src/utils/download_file';

import { DownloadDocumentIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '@src/components/header/styles.module.scss';

type TDataExportItemActionProps = {
  dataExport: IDataExport,
}
const DataExportItemAction = ({
  dataExport,
}: TDataExportItemActionProps) => {
  const download = useDownloadDataExport();

  const { mutate } = download;

  const handleIconClick = useCallback(() => {
    mutate(
      { dataExportId: dataExport.id },
      {
        onSuccess: (data) => {
          downloadFile(data.exportedFileUrl);
        },
      },
    );
  }, [mutate, dataExport]);

  const handleTitleClick = useCallback(() => {
    if (dataExport.state === 'processing') return;

    Backbone.history.navigate(dataExportPath(), { trigger: true });
  }, [dataExport.state]);

  return (
    <div className="display-flex align-items-center justify-content-between">
      <MutationStatus mutation={ download } />
      <div
        className={ styles['data-export-item-title'] }
        role="navigation"
        onClick={ handleTitleClick }
      >
        { dataExport.exportFileName }
      </div>
      {
        (dataExport.state === 'processing') ? (
          <div className={ styles['download-spinner'] } />
        ) : (
          <DownloadDocumentIcon
            fontSize={ 14 }
            onClick={ handleIconClick }
          />
        )
      }
    </div>
  );
};

export default DataExportItemAction;
