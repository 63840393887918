import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { TID } from '@src/types/common';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import Text from '@src/components/ui/text';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

interface IReconciliationReportDownloadActionProps {
  documentId: TID
}

const ReconciliationReportDownloadAction = ({
  documentId,
}: IReconciliationReportDownloadActionProps): JSX.Element => {
  const downloadDocument = useDownloadDocument();
  const { mutate } = downloadDocument;
  const handleDownload = useCallback((type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentId, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, data.fileName) },
    );
  }, [mutate, documentId]);

  const handleOriginalFileSelect = useCallback(() => {
    handleDownload('original');
  }, [handleDownload]);

  const tooltipContent = <Text as="p">Download</Text>;

  return (
    <>
      <MutationStatus mutation={ downloadDocument } />
      <Tooltip.Hover content={ tooltipContent } tooltipClassName={ styles['note-tooltip'] }>
        <DownloadDocumentIcon pointer fontSize={ 14 } inColor="black" onClick={ handleOriginalFileSelect } />
      </Tooltip.Hover>
    </>
  );
};

export default ReconciliationReportDownloadAction;
