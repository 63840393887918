module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<td class="pointer text-left download-document">\n  ');
    
      __out.push(__sanitize(this.docyt_id));
    
      __out.push('\n</td>\n<td class="text-center">\n  ');
    
      __out.push(__sanitize(this.document_name || this.original_file_name || this.original_file_key || this.computed_final_filename));
    
      __out.push('\n</td>\n<td class="text-right">\n  ');
    
      if (ref = this.transaction_type, indexOf.call(Docyt.Common.Constants['EXPENSE_TRANSACTION_TYPES'], ref) >= 0) {
        __out.push('\n    ');
        if (this.display_amount) {
          __out.push('\n      ');
          __out.push(__sanitize(this.display_amount));
          __out.push('\n    ');
        } else {
          __out.push('\n      &mdash;\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    <span class="icon-trashcan font-18 m-r-18 vertical-align-middle-important pointer remove-document"></span>\n  ');
      }
    
      __out.push('\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}