import React from 'react';

import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';

interface IRenameDocumentFormValues {
  name: string,
}

interface IRenameDocumentFormProps {
  originalName?: string,
  formId?: string,
  onSubmit: SubmitHandler<IRenameDocumentFormValues>,
}

const RenameDocumentForm = ({
  originalName,
  formId,
  onSubmit,
}: IRenameDocumentFormProps): JSX.Element => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<IRenameDocumentFormValues>();

  const inputClasses = classNames(
    'form-control',
    {
      'border-danger': errors.name,
    },
  );

  return (
    <form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <input
        { ...register('name', { required: true }) }
        className={ inputClasses }
        defaultValue={ originalName }
        type="text"
      />
    </form>
  );
};

export {
  IRenameDocumentFormProps,
  IRenameDocumentFormValues,
  RenameDocumentForm as default,
};
