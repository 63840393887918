module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="documents-cat-header payees-header border-bottom-gray">\n    ');
    
      if (!this.from_vendors_service) {
        __out.push('\n    <a class="font-20 in-blue-400 text-decoration-blank" href = "');
        __out.push(__sanitize(this.homeURL));
        __out.push('">\n      Accounts Payable\n    </a>\n    <div class="client__breadcrumbs-item in-grey-600"></div>\n    <a class="font-20 in-blue-400 text-decoration-blank m-l-6" href = "');
        __out.push(__sanitize(this.homeURL));
        __out.push('/payees">\n      Payees\n    </a>\n    ');
      }
    
      __out.push('\n    <div class="client__breadcrumbs-item in-grey-600"></div>\n    <div class="dropdown show m-t-10">\n      ');
    
      if (this.tab === 'profile') {
        __out.push('\n        <button class="btn dropdown-toggle non-outline font-24 font-bold header-icon-button pull-right" style="margin-top: 12px" type="button" id="payee-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n          <span class="icon icon-more-icon in-grey-800"></span>\n        </button>\n      ');
      }
    
      __out.push('\n      <div class="pull-right header-input-area m-t-9">\n        <div class="display-inline-flex m-t-10">\n            ');
    
      if (this.is_linked_to_businesses && this.businesses_tab_enabled) {
        __out.push('\n            <div class="m-r-16">\n              <span class="icon-multi-business font-24 multi-business-tooltip-js" data-html="true" data-toggle="tooltip" data-placement="bottom" title="This vendor is linked to multiple businesses"></span>\n            </div>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.qbo_disabled && !this.qbo_id) {
        __out.push('\n              <a class="display-inline-flex not-synced-link" data-toggle="tooltip" data-placement="bottom" title="Transaction not pushed to ledger">\n                <span class="icon-qbo-block font-22"></span>\n                <span class="font-16 m-l-10 m-r-10">View in QuickBooks</span>\n              </a>\n            ');
      } else if (this.hasQboError && !this.qbo_started) {
        __out.push('\n              <a class="pointer display-inline-flex not-synced-link" data-toggle="tooltip" data-placement="bottom" title="');
        __out.push(__sanitize(this.qbo_error));
        __out.push('">\n                <span class="icon-qbo-error font-22"></span>\n                <span class="font-16 m-l-10 m-r-10">View in QuickBooks</span>\n              </a>\n            ');
      } else {
        __out.push('\n              ');
        if (this.qbo_synced && !this.qbo_started) {
          __out.push('\n                <a class="display-inline-flex in-green-600" href="');
          __out.push(__sanitize(this.qbo_link));
          __out.push('" target="_blank">\n                  <span class="icon-qbo-on font-22"></span>\n                  <span class="font-16 m-l-10 m-r-10">View in QuickBooks</span>\n                </a>\n              ');
        } else {
          __out.push('\n                <a class="pointer display-inline-flex not-synced-link" data-toggle="tooltip" data-placement="bottom" title="');
          __out.push(__sanitize(!this.qbo_started ? Docyt.Common.Constants.Tooltips['VENDOR_QUICKBOOKS_STARTED'] : Docyt.Common.Constants.Tooltips['VENDOR_QUICKBOOKS_INITIATED']));
          __out.push('">\n                  <span class="icon icon-qbo-off font-22"></span>\n                  <span class="font-16 m-l-10 m-r-10">View in QuickBooks</span>\n                </a>\n              ');
        }
        __out.push('\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.tab === 'bank') {
        __out.push('\n            <a class="pointer display-inline-flex">\n              <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="bank-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n                <i class=\'icon icon-3-dots font-20\'></i>\n              </button>\n              <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="bank-action-dropdown-btn">\n                ');
        if (this.dwollaFundingSource.id) {
          __out.push('\n                <div class="dropdown-item font-14 remove-bank-js">\n                  <i class="icon icon-trashcan"></i> <span>Delete Bank Account Info</span>\n                </div>\n                ');
        } else {
          __out.push('\n                <div class="dropdown-item font-14 add-bank-js">\n                  <i class="icon icon-business"></i> <span>Add Bank Account Info</span>\n                </div>\n                ');
        }
        __out.push('\n              </div>\n            </a>\n            ');
      }
    
      __out.push('\n        </div>\n        ');
    
      if (this.tab === 'addresses') {
        __out.push('\n          <span class="font-25 in-grey-400 m-l-20 m-r-20 p-t-5">|</span>\n          <button class="btn blue-plus-btn big-plus-btn pull-right add-address-btn">\n            <i class="icon icon-plus"></i>\n          </button>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="padding-0 background-transparent pull-left display-flex align-items-center">\n        <div class="background-white middle-avatar-wrapper inline-block m-r-15">\n            <img src="');
    
      __out.push(__sanitize(this.image_url));
    
      __out.push('">\n        </div>\n        <div class=\'payee-name-span font-30\' data-toggle="tooltip" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n          ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n        </div>\n        ');
    
      if (this.archived_at) {
        __out.push('\n          <div class="invoice-status-panel archived font-12 font-semi-bold height-100-percent m-l-10">Archived</div>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="invoice-queue-dropdown dropdown-menu pull-right" aria-labelledby="payee-dropdown-btn">\n          ');
    
      if (!this.is_claimed) {
        __out.push('\n          <a class="dropdown-item font-16 pointer edit-payee-link">\n            <i class="icon icon-vendors"></i>\n            ');
        if (!this.from_vendors_service) {
          __out.push('\n            <span>Edit Payee Profile</span>\n            ');
        } else {
          __out.push('\n            <span>Edit Vendor Profile</span>\n            ');
        }
        __out.push('\n          </a>\n          ');
      }
    
      __out.push('\n          <a class="dropdown-item font-16 pointer edit-link-ledger">\n            <i class="icon icon-edit-account"></i>\n            <span>Edit Link to Ledger</span>\n          </a>\n          ');
    
      if ((!this.qbo_id || this.hasQboError) && !this.qbo_started) {
        __out.push('\n            <a class="dropdown-item font-16 pointer re-sync-js">\n              <i class="icon-qbo-on font-25"></i>\n              <span>Re-sync</span>\n            </a>\n          ');
      }
    
      __out.push('\n          ');
    
      if (!this.archived_at) {
        __out.push('\n          <a class="dropdown-item font-16 pointer archive-payee-link">\n            <i class="icon-account-archive font-18-important m-r-5"></i>\n            ');
        if (!this.from_vendors_service) {
          __out.push('\n            <span>Archive Payee</span>\n            ');
        } else {
          __out.push('\n            <span>Archive Vendor</span>\n            ');
        }
        __out.push('\n          </a>\n          ');
      } else {
        __out.push('\n          <a class="dropdown-item font-16 pointer unarchive-payee-link">\n            <i class="icon-account-unarchive font-18-important m-r-5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></i>\n            ');
        if (!this.from_vendors_service) {
          __out.push('\n            <span>Unarchive Payee</span>\n            ');
        } else {
          __out.push('\n            <span>Unarchive Vendor</span>\n            ');
        }
        __out.push('\n          </a>\n          ');
      }
    
      __out.push('\n          <a class="dropdown-item font-16 in-red-400 pointer remove-payee-link">\n            <i class="icon-trashcan m-r-5"></i>\n            ');
    
      if (!this.from_vendors_service) {
        __out.push('\n            <span>Remove Payee</span>\n            ');
      } else {
        __out.push('\n            <span>Remove Vendor</span>\n            ');
      }
    
      __out.push('\n          </a>\n      </div>\n      <ul class="nav sub-header-menu invoices-sub-menu">\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'payments' ? 'active' : ''));
    
      __out.push('">\n          <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/payees/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('/invoices">\n              PAYMENTS\n          </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'addresses' ? 'active' : ''));
    
      __out.push('">\n          <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/payees/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('/addresses">\n              ADDRESS BOOK\n          </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'profile' ? 'active' : ''));
    
      __out.push('">\n          <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/payees/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">\n              PROFILE\n          </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'bank' ? 'active' : ''));
    
      __out.push('">\n          <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/payees/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('/ach">\n              ACH\n          </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'w9_info' ? 'active' : ''));
    
      __out.push('">\n          <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/payees/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('/w9_info">\n              W-9 INFO\n          </a>\n        </li>\n        ');
    
      if (this.businesses_tab_enabled && this.is_global === false) {
        __out.push('\n        <li class="m-r-25 ');
        __out.push(__sanitize(this.tab === 'businesses' ? 'active' : ''));
        __out.push('">\n          <a class="" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/payees/');
        __out.push(__sanitize(this.id));
        __out.push('/businesses">\n              BUSINESSES\n          </a>\n        </li>\n        ');
      }
    
      __out.push('\n      </ul>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}