import { useQuery, UseQueryOptions, useQueryClient, useMutation } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getAccountsPayableServiceSummary,
  getAccountsPayableServiceByBusinessId,
  getReceivableAccountsPayableServices,
  payAccountsPaymentServiceViaAch,
  IGetReceivableAccountsPayableServicesParams,
  IPayAccountsPaymentServiceViaAchParams,
  ICreateCheckParams,
  createCheck,
  payViaAch,
  IPayViaAchParams,
} from '@src/requests/accounts_payable/accounts_payable_services';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IReceivableAccountsPayableService } from '@src/types/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';
import { IServiceSummary } from '@src/types/service_summary';
import { IAccountsPayableService } from '@src/types/services';

import { IBulkMutationParams, IBulkMutationReturn, makeBulkRequest } from '../bulk_mutations';

const useGetAccountsPayableServiceSummary = (businessId: TID, invoiceType: string) => {
  return useQuery<IServiceSummary, Error>(
    [QueryKey.accountPayableServiceSummary, businessId],
    () => getAccountsPayableServiceSummary(businessId, invoiceType),
  );
};

const useGetAccountsPayableServiceByBusinessId = (businessId: TID) => {
  return useQuery<IAccountsPayableService, Error>(
    [QueryKey.accountsPayableServices, businessId],
    () => getAccountsPayableServiceByBusinessId(businessId),
  );
};

const useGetReceivableAccountsPayableServices = (
  params: IGetReceivableAccountsPayableServicesParams,
  opts?: UseQueryOptions<IReceivableAccountsPayableService[], Error>,
) => {
  return useQuery<IReceivableAccountsPayableService[], Error, IReceivableAccountsPayableService[]>(
    [QueryKey.receivableAccountPayableServices, params],
    () => getReceivableAccountsPayableServices(params),
    opts,
  );
};

const usePayAccountsPaymentServiceViaAch = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IPayAccountsPaymentServiceViaAchParams>(
    payAccountsPaymentServiceViaAch,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.accountsPayableServiceDocuments);
      },
    },
  );
};

const useBulkCreateCheck = () => {
  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<ICreateCheckParams>
  >(
    makeBulkRequest(createCheck),
    {
      onSettled: (res) => {
        if (!res?.responses || res?.responses.length === 0) return;

        res.responses.forEach((response: any) => {
          response.service_documents.forEach((
            serviceDocument: IAccountsPayableServiceDocument,
          ) => {
            window.Docyt.vent.trigger('invoice:state:changed', serviceDocument);
          });
        });
      },
    },
  );
};

const useBulkPayViaAch = () => {
  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IPayViaAchParams>
  >(
    makeBulkRequest(payViaAch),
    {
      onSettled: (res) => {
        if (!res?.responses || res?.responses.length === 0) return;

        res.responses.forEach((response: any) => {
          response.service_documents.forEach((
            serviceDocument: IAccountsPayableServiceDocument,
          ) => {
            window.Docyt.vent.trigger('invoice:state:changed', serviceDocument);
          });
        });
      },
    },
  );
};

export {
  useGetAccountsPayableServiceSummary,
  useGetAccountsPayableServiceByBusinessId,
  useGetReceivableAccountsPayableServices,
  usePayAccountsPaymentServiceViaAch,
  useBulkCreateCheck,
  useBulkPayViaAch,
};
