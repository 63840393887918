import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import useOutsideClick from '@src/hooks/outside_click';
import {
  useGetGlobalSearchFilterParams,
} from '@src/hooks/queries/search/search';
import { IGetGlobalSearchFilterParams } from '@src/requests/search/search';
import { TSection } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import {
  IGlobalSearchParams,
} from '@src/types/global_search/global_search_requests';
import { invalidDateRange } from '@src/utils/date_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import { useFilterData, useClearFilter, useFilterField } from '@src/components/ui_v2/filter';
import { TOption } from '@src/components/ui_v2/inputs';
import { TRichAmount } from '@src/components/ui_v2/inputs/rich_amount_input/types';
import LogoIcon from '@src/components/utils/icomoon/logo';

import { SEARCH_RESULT_PAGE_SIZE, section } from './constant';
import GlobalSearchFilter from './filter';
import { TAccountTypesAllowed } from './global_search';
import { checkAmountRange, toggleBodyScroll } from './helper';
import SearchResults from './search_results';
import SearchSelectInput from './search_select_input';

import styles from './styles.module.scss';

interface IGlobalHeaderSearchProps {
  accountType: TAccountTypesAllowed;
}

const GlobalHeaderSearch = ({ accountType }: IGlobalHeaderSearchProps) => {
  const searchRef = React.useRef<HTMLDivElement>(null);
  const [showSearchPannel, setShowSearchPannel] =
    React.useState<boolean>(false);
  const [activeSearchType, setActiveSearchType] = React.useState<TOption | null>(null);
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

  const filterArgs:IGetGlobalSearchFilterParams = {
    accountType,
  };

  if (activeSearchType?.value) {
    filterArgs.searchType = activeSearchType.value;
  }

  const { data: filterData } = useGetGlobalSearchFilterParams(filterArgs);

  const filter = useFilterData(section);
  const [value, update] = useFilterField<TFilterData, TSection>('status', section);
  const clearFilter = useClearFilter(section);
  const isAmountInvalid = useMemo(() => {
    return checkAmountRange(filter?.amountRangeDto as TRichAmount);
  }, [filter]);

  const requestBody = useMemo(() => {
    let args: IGlobalSearchParams = {
      accountType,
      pageSize: SEARCH_RESULT_PAGE_SIZE,
    };
    if (activeSearchType?.value) {
      args.searchType = activeSearchType.value;
    }
    if (searchQuery) {
      args.searchQuery = searchQuery;
    }
    if (filter) {
      args = { ...args, ...filter };
    }
    // Check if date is invalid then update it
    if (invalidDateRange(args.dateRangeDto)) {
      delete args.dateRangeDto;
    }
    // Check if amount is invalid then update it
    if (checkAmountRange(args.amountRangeDto)) {
      delete args.amountRangeDto;
    }
    // check if user select a all tab remove the status param from the request payload
    if (args.searchType === 'all') {
      delete args.status;
    }
    return args;
  }, [activeSearchType, searchQuery, filter, accountType]);

  const handleShow = React.useCallback(() => {
    setShowSearchPannel(true);
  }, []);

  const handleHide = React.useCallback(() => {
    setShowSearchPannel(false);
    setIsFullScreen(false);
    setActiveSearchType(null);
    clearFilter();
    toggleBodyScroll(true);
  }, [clearFilter]);

  useOutsideClick({
    ref:              searchRef,
    callback:         handleHide,
    exclueClassCheck: 'fa fa-remove',
    excludedTagName:  'HTML',
  });

  const onFullScreen = React.useCallback(() => {
    setIsFullScreen(true);
    toggleBodyScroll(false);
  }, []);

  const handleSearchTabClick = (selectedActiveTab:TOption | null) => {
    setActiveSearchType((prevState) => {
      if (prevState?.value !== selectedActiveTab?.value) {
        if (value) {
          update(undefined);
        }
      }
      return selectedActiveTab;
    });
  };

  return (
    <div
      ref={ searchRef }
      className={ [
        styles['general-search'],
        isFullScreen ? styles['full-screen'] : '',
        showSearchPannel ? styles['show-panel'] : '',
      ].join(' ') }
    >
      <Link className={ styles['header-logo'] } title="Home" to="/">
        <LogoIcon fontSize={ 18 } />
      </Link>
      <SearchSelectInput
        isFullScreen={ isFullScreen }
        searchQuery={ searchQuery }
        searchTypeList={ filterData?.searchTypeList }
        selectedSearchType={ activeSearchType }
        show={ showSearchPannel }
        onSearchQueryChanged={ setSearchQuery }
        onSearchTypeChanged={ handleSearchTabClick }
        onShow={ handleShow }
      />
      {showSearchPannel && (
        <div
          className={ [styles['search-menu'], styles['absolute-top']].join(' ') }
        >
          <SearchResults
            isFullScreen={ isFullScreen }
            requestBody={ requestBody }
            onShowFullScreen={ onFullScreen }
          />
          <GlobalSearchFilter
            amountError={ isAmountInvalid }
            businessList={ filterData?.businessList }
            section={ section }
            statusList={ filterData?.statusList }
          />
        </div>
      )}
      {isFullScreen && (
        <Button className={ styles['close-btn'] } variant="link" onClick={ handleHide }>
          <span className="icon icon-not-a-chargeback-icon" />
        </Button>
      )}
    </div>
  );
};

export default React.memo(GlobalHeaderSearch);
