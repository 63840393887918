import { To, Path, Location } from 'react-router-dom';

const parsePath = (path: string): Partial<Path> => {
  const parsedPath: Partial<Path> = {};

  if (path) {
    let pathname = path;

    const hashIndex = pathname.indexOf('#');
    if (hashIndex >= 0) {
      parsedPath.hash = pathname.substr(hashIndex);
      pathname = pathname.substr(0, hashIndex);
    }

    const searchIndex = pathname.indexOf('?');
    if (searchIndex >= 0) {
      parsedPath.search = pathname.substr(searchIndex);
      pathname = pathname.substr(0, searchIndex);
    }

    if (pathname) {
      parsedPath.pathname = pathname;
    }
  }

  return parsedPath;
};

const createLocation = (to: To): Readonly<Location> => {
  return {
    pathname: '',
    search:   '',
    hash:     '',
    key:      '',
    ...(typeof to === 'string' ? parsePath(to) : to),
    state:    null,
  };
};

const createPath = ({
  pathname = '/',
  search = '',
  hash = '',
}: Partial<Path>) => {
  let path = pathname;

  if (search && search !== '?') {
    path += search.charAt(0) === '?' ? search : `?${search}`;
  }
  if (hash && hash !== '#') {
    path += hash.charAt(0) === '#' ? hash : `#${hash}`;
  }

  return path;
};

function createHref(to: To) {
  return typeof to === 'string' ? to : createPath(to);
}

function invariant(value: any, message?: string) {
  if (value === false || value === null || typeof value === 'undefined') {
    throw new Error(message);
  }
}

function createURL(to: To): URL {
  // window.location.origin is "null" (the literal string value) in Firefox
  // under certain conditions, notably when serving from a local HTML file
  // See https://bugzilla.mozilla.org/show_bug.cgi?id=878297
  const base =
    window.location.origin !== 'null'
      ? window.location.origin
      : window.location.href;

  const href = typeof to === 'string' ? to : createPath(to);
  invariant(
    base,
    `No window.location.(origin|href) available to create URL for href: ${href}`,
  );
  return new URL(href, base);
}

export {
  createLocation,
  createHref,
  createURL,
};
