module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade modal-delete ');
    
      __out.push(__sanitize(this.modalClass));
    
      __out.push(' ');
    
      if (this.hasMember) {
        __out.push(__sanitize('member-delete-modal'));
      }
    
      __out.push('" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body">\n        ');
    
      if (this.iconTemplate) {
        __out.push('\n        <div class="text-center delete-icon-wrapper m-t-30 m-b-30">\n          <div class="inline-block relative">\n          ');
        if (this.iconTemplate === 'delete-document') {
          __out.push('\n            <span class="icon-delete-document"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n          ');
        } else if (this.iconTemplate === 'restore-document') {
          __out.push('\n            <span class="icon-restore-document"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n          ');
        } else if (this.iconTemplate === 'delete-participant') {
          __out.push('\n            ');
          if (this.userAvatar && this.userAvatar.s3_object_key) {
            __out.push('\n            <img class="img-avatar" src="https://');
            __out.push(__sanitize(configData.bucketName));
            __out.push('.s3.amazonaws.com/');
            __out.push(__sanitize(this.userAvatar.s3_object_key));
            __out.push('">\n            <span class="icon-search-clear font-40"></span>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n          </div>\n        </div>\n        ');
      }
    
      __out.push('\n        ');
    
      if (this.hasMember && this.avatarUrl) {
        __out.push('\n        <div class="member-avatar-wrapper">\n            <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('"/>\n            <div class="close-icon-box">\n              <i class="icon icon-reject-invite in-white font-bold font-36"></i>\n            </div>\n        </div>\n        ');
      }
    
      __out.push('\n        <p class="p-b-0 text-center font-16 in-black">');
    
      __out.push(this.text);
    
      __out.push('</p>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id=\'cancel-btn\' class="font-18 cancel-link cancel modal-link-btn">Cancel</button>\n        <button type="button" id=\'delete-btn\' class="btn ');
    
      __out.push(__sanitize(this.btnClass));
    
      __out.push(' btn-xlarge font-16">');
    
      __out.push(__sanitize(this.confirmText));
    
      __out.push('</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}