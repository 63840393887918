import { QueryKey } from '@src/constants/query_keys';
import {
  getAccountsReceivablePayments,
  IGetAccountsReceivablePaymentsParams,
  IGetAccountsReceivablePaymentsResponse,
} from '@src/requests/accounts_receivable_payments';
import { IAccountsReceivablePayment } from '@src/types/accounts_receivable_payments';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetAccountsReceivablePayments = createUseGetInfiniteCollection<
  IAccountsReceivablePayment,
  IGetAccountsReceivablePaymentsParams,
  IGetAccountsReceivablePaymentsResponse
>({
  queryKey: QueryKey.accountsReceivablePayments,
  request:  getAccountsReceivablePayments,
});

export {
  useGetAccountsReceivablePayments,
};
