module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <h4 class="modal-title font-28 font-bold in-black">Forwarding Emails</h4>\n            </div>\n            <div class="modal-body">\n                <div class="form-group">\n                    <label class="form-label">Business Mailroom</label>\n                    <div class="email-control-group">\n                        <span class="email-prefix">');
    
      __out.push(__sanitize(configData.ib_upload_email_prefix));
    
      __out.push('-</span>\n                        <input class="form-control inbox-control inbox-email-js">\n                        <span class="email-suffix">@');
    
      __out.push(__sanitize(configData.docyt_io_domain));
    
      __out.push('</span>\n                    </div>\n                </div>\n                <div class="form-group">\n                    <label class="form-label">Accounts Payable</label>\n                    <div class="email-control-group ');
    
      if (!this.ap_service_ready) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('">\n                        <span class="email-prefix">');
    
      __out.push(__sanitize(configData.ap_upload_email_prefix));
    
      __out.push('-</span>\n                        <input class="form-control accounts-email-js">\n                        <span class="email-suffix">@');
    
      __out.push(__sanitize(configData.docyt_io_domain));
    
      __out.push('</span>\n                    </div>\n                    ');
    
      if (!this.ap_service_ready) {
        __out.push('\n                    <label class="form-control-label">Service Not Active</label>\n                    ');
      }
    
      __out.push('\n                </div>\n                <div class="form-group">\n                    <label class="form-label">Receipt Box</label>\n                    <div class="email-control-group ');
    
      if (!this.rp_service_ready) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('">\n                        <span class="email-prefix">');
    
      __out.push(__sanitize(configData.rp_upload_email_prefix));
    
      __out.push('-</span>\n                        <input class="form-control receipt-email-js">\n                        <span class="email-suffix">@');
    
      __out.push(__sanitize(configData.docyt_io_domain));
    
      __out.push('</span>\n                    </div>\n                    ');
    
      if (!this.rp_service_ready) {
        __out.push('\n                    <label class="form-control-label">Service Not Active</label>\n                    ');
      }
    
      __out.push('\n                </div>\n                <div class="form-group">\n                    <label class="form-label">Revenue Center</label>\n                    <div class="email-control-group">\n                        <span class="email-prefix">');
    
      __out.push(__sanitize(configData.revenue_upload_email_prefix));
    
      __out.push('-</span>\n                        <input class="form-control revenue-email-js">\n                        <span class="email-suffix">@');
    
      __out.push(__sanitize(configData.docyt_io_domain));
    
      __out.push('</span>\n                    </div>\n                </div>\n            </div>\n            <div class="modal-footer">\n                <a class=\'cancel-link cancel pointer font-bold font-18\'>Cancel</a>\n                <button type="submit" class="btn btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}