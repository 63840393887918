import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getReportByIdentifier, getReportItems } from '@src/requests/report_service/report_items';
import { TMongoID } from '@src/types/common';
import { IReportItem } from '@src/types/report_service/report_item';

const useGetReportItems = (reportId: TMongoID) => {
  return useQuery<IReportItem[], Error>(
    [QueryKey.reportItems, reportId],
    () => getReportItems(reportId),
    {
      enabled: Boolean(reportId),
    },
  );
};

const useGetReportByIdentifier = (reportId: string, reportIdentifier: string) => {
  return useQuery<IReportItem, Error>(
    [QueryKey.reportIdentifierItem, reportId, reportIdentifier],
    () => getReportByIdentifier(reportId, reportIdentifier),
    {
      enabled: Boolean(reportId) && Boolean(reportIdentifier),
    },
  );
}

export {
  useGetReportItems,
  useGetReportByIdentifier
};
