import React from 'react';

import { currencyLocaleFormatter } from '@src/utils/currency';

import styles from './styles.module.scss';

interface ITotalAmountProps {
  amount: number,
}

const TotalAmount = ({
  amount,
}: ITotalAmountProps) => {
  return (
    <span className={ styles['total-amount-wrapper'] }>
      Total:
      { ' ' }
      <span className={ styles['total-amount-detail'] }>
        { currencyLocaleFormatter(amount) }
      </span>
    </span>
  );
};

export default TotalAmount;
