/* eslint-disable import/order */
import React, { createContext, useContext } from 'react';

import { IRevenueSystem } from '@src/types/revenue_systems';
import { Outlet, useParams } from 'react-router-dom';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServicePath } from '@src/routes';

const RevenueSystemContext = createContext<IRevenueSystem | null>(null);

const useRevenueSystemContext = (): IRevenueSystem => {
  const value = useContext(RevenueSystemContext);

  if (!value) {
    throw new Error('"useRevenueSystemContext" must be used within <RevenueSystemProvider />');
  }

  return value;
};

const RevenueSystemProvider = (): JSX.Element | null => {
  const service = useRevenueServiceContext();
  const { revenueSystemId } = useParams();
  const rSystem = service.revenueSystems.find((rs) => rs.id === revenueSystemId);

  if (!rSystem) { // Wrong revenueSystemId passed, redirect to the Revenue Service root path
    Backbone.history.navigate(
      revenueServicePath(service.businessId),
      { trigger: true },
    );
    return null;
  }

  return (
    <RevenueSystemContext.Provider value={ rSystem }>
      <Outlet />
    </RevenueSystemContext.Provider>
  );
};

export {
  useRevenueSystemContext,
  RevenueSystemProvider as default,
};
