import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IReport } from '@src/types/report_service/report';

import Form from '@src/components/ui/form';

import { renameReportValidation, IRenameReportFormValues } from './schema';

import styles from '../styles.module.scss';

interface IRenameReportFormProps {
  formId?: string,
  report: IReport,
  onSubmit: SubmitHandler<IRenameReportFormValues>,
}

const RenameReportForm = ({
  formId,
  report,
  onSubmit,
}: IRenameReportFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRenameReportFormValues>({
    defaultValues: {
      name: report.name,
    },
    resolver: yupResolver(renameReportValidation),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Enter a new name for the report:"
        placeholder="Enter Name of Report"
        wrapperClassName={ styles['rename-report-modal-form'] }
        { ...register('name') }
      />
    </Form>
  );
};

export {
  IRenameReportFormProps,
  IRenameReportFormValues,
  RenameReportForm as default,
};
