import React, { useCallback } from 'react';

import { TDateFilter, TFilterData } from '@src/types/filter';

import { DateRangeInput, IDateRangeValue } from '@src/components/ui/form';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface ITransactionsFilterDateInputProps<TFilter extends TFilterData> extends
  IFilterFieldUIProps
{
  name: keyof TFilter,
  startPlaceholder?: string,
  endPlaceholder?: string
}

const DateFilterField = <TFilter extends TFilterData>({
  name,
  startPlaceholder,
  endPlaceholder,
  ...props
}: ITransactionsFilterDateInputProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, TDateFilter>(name);

  const handleChange = useCallback((dateRange: IDateRangeValue) => {
    update({
      gte: dateRange.startDate,
      lte: dateRange.endDate,
    });
  }, [update]);

  const [wrapperProps] = useFilterFieldProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <DateRangeInput
        endPlaceholder={ endPlaceholder }
        startPlaceholder={ startPlaceholder }
        value={ {
          startDate: value?.gte,
          endDate:   value?.lte,
        } }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(DateFilterField);
