import { object, string } from 'yup';

interface ICreateNewReportFormValues {
  name: string,
  templateId: string,
}

interface IRenameReportFormValues {
  name: string,
}

const createReportValidation = object({
  name:       string().trim().required('Please input name of report'),
  templateId: string().nullable().required('Please select type of report'),
});

const renameReportValidation = object({
  name: string().trim().required('Please input name of report'),
});

export {
  ICreateNewReportFormValues,
  IRenameReportFormValues,
  createReportValidation,
  renameReportValidation,
};
