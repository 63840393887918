import React, { useEffect } from 'react';

import { useReportContext } from '@src/hooks/contexts/report_context';
import { IChartOfAccount } from '@src/types/report_service/report';

import { Button } from '@src/components/ui/buttons';
import { useItemsSelector } from '@src/components/utils/items_selector';

import { useAcceptedAccountsModal } from './accepted_accounts_modal';

interface IAcceptedAccountsActionProps {
  chartOfAccounts: IChartOfAccount[],
  isBusinessAdmin: boolean,
  isReportServiceAdmin: boolean,
}

const AcceptedAccountsAction = ({
  chartOfAccounts,
  isBusinessAdmin,
  isReportServiceAdmin,
}: IAcceptedAccountsActionProps): JSX.Element => {
  const report = useReportContext();
  const { mark } = useItemsSelector<IChartOfAccount>();
  const modal = useAcceptedAccountsModal();

  useEffect(() => {
    chartOfAccounts.forEach((chartOfAccount) => {
      if (report.acceptedChartOfAccountIds.includes(chartOfAccount.id)) {
        mark(chartOfAccount, true);
      }
    });
  }, [chartOfAccounts, mark, report.acceptedChartOfAccountIds]);

  return (
    <>
      <modal.Component
        { ...modal.props }
        chartOfAccounts={ chartOfAccounts }
        reportId={ report.id }
      />
      <Button
        bsColor="blue"
        className="btn-blue-on-hover p-l-15 p-t-4 p-r-15 p-b-4"
        disabled={ !isBusinessAdmin && !isReportServiceAdmin }
        onClick={ modal.open }
      >
        Edit
        <span className="fa fa-angle-down m-l-5 font-16" />
      </Button>
    </>
  );
};

export default AcceptedAccountsAction;
