import React, { useCallback } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

import { TPeriod } from './types';

import styles from './styles.module.scss';

interface IDateRangeMonthPeriodProps {
  period: TPeriod,
  onSelected: (period: TPeriod) => void,
}

const DateRangeMonthPeriod = ({
  period,
  onSelected,
}: IDateRangeMonthPeriodProps) => {
  const handleSelect = useCallback(() => {
    onSelected(period);
  }, [period, onSelected]);

  return (
    <Dropdown.Item
      className={ styles['date-range-period'] }
      title={ period.label }
      onSelect={ handleSelect }
    />
  );
};

export default DateRangeMonthPeriod;
