import React from 'react';

import {
  portfolioDashboardPath,
  multiEntityReportsPath,
} from '@src/routes';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';

import styles from '../header/navigation_styles.module.scss';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
}

const NewPortfolioDropDownList = ({
  launchDarklyFeature,
}: IServiceMenuProps) => {
  const pathToDropdownItemMap: { [key: string]: string } = {
    [portfolioDashboardPath()]: window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD,
    [multiEntityReportsPath()]: window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS,
  };

  const currentPath = window.location.pathname;
  const title = pathToDropdownItemMap[currentPath] || window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE;

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        isGlobalNavigationEnabled
        className="font-16 font-bold"
        focusToMyClient={ null }
        showSelect={ false }
        title={ title }
      >
        <li className="p-t-8 p-b-4">
          <span className={ `m-l-18 m-b-16 ${styles['dropdown-item-title']}` }>
            { window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase() }
          </span>
        </li>
        <ServiceItem
          serviceUrl={ portfolioDashboardPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.NEW_KPI_DASHBOARD }
        />
        <ServiceItem
          serviceUrl={ multiEntityReportsPath() }
          title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
        />
      </ModuleHeaderMenu>
    </div>
  );
};

export default NewPortfolioDropDownList;
