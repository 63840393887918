import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import {
  useCreateFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { useGetManagementGroupDefaultAggregator } from '@src/hooks/queries/management_groups';
import { LDFeatureForUserEnabled } from '@src/utils/config';

import Modal from '@src/components/ui/modal';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import ConnectNewFinancialInstitutionForm from './connect_new_financial_institution_form';
import { IConnectNewFinancialInstitutionData } from './schema';
import { useConnectAggregatorAccount } from '../hooks';

interface IConnectNewFinancialInstitutionModalProps {
  show: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const ConnectNewFinancialInstitutionModal = ({
  show,
  onDone,
  onCancel,
}: IConnectNewFinancialInstitutionModalProps): JSX.Element => {
  const { mutate: getManagementGroupDefaultAggregator, isLoading } = useGetManagementGroupDefaultAggregator();

  const create = useCreateFinancialInstitutionConnection();
  const { mutate: createFinancialInstitution } = create;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    createFinancialInstitution({ financialInstitutionConnection: data }, {
      onSuccess: onDone,
    });
  }, [createFinancialInstitution, onDone]);

  const {
    connectAggregator,
    generateLinkMutation,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  const handleSubmit = useCallback((data: IConnectNewFinancialInstitutionData) => {
    LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((isQuiltEnabled) => {
      if (isQuiltEnabled) {
        getManagementGroupDefaultAggregator({
          managementGroupId: Number(data.managementGroupId),
        }, {
          onSuccess: (response) => {
            connectAggregator(data, response.managementGroupDefaultAggregator.defaultAggregator, isQuiltEnabled);
          },
          onError: (error) => {
            toastr.error(error.message, 'Error');
          },
        });
      } else {
        connectAggregator(data);
      }
    });
  }, [connectAggregator]);

  return (
    <>
      {isLoading && <Spinner /> }
      <MutationStatus mutation={ generateLinkMutation } />
      <MutationStatus mutation={ create } successMessage="New Financial Institution Connection was created successfully" />
      <Modal.Form
        className="connect-financial-institution-modal"
        proceedTitle="Continue"
        show={ show }
        title="Select Management Group"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <ConnectNewFinancialInstitutionForm
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

export default ConnectNewFinancialInstitutionModal;
