module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="item-divider p-b-30">\n    <h2 class="service-main-title">My Businesses</h2>\n</div>\n<div class="setting-business-content m-t-30">\n    ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n    <a href="/setup-business/');
        __out.push(__sanitize(business.id));
        __out.push('" class="business-item-wrapper display-table p-l-15 p-r-15 p-t-15 p-b-15 m-b-15 pointer in-black">\n        <div class="display-table-cell business-avatar-wrapper">\n            <div class="middle-avatar-wrapper">\n            ');
        if (business.avatar) {
          __out.push('\n            <img src="https://');
          __out.push(__sanitize(configData.bucketName));
          __out.push('.s3.amazonaws.com/');
          __out.push(__sanitize(business.avatar.s3_object_key));
          __out.push('">\n            ');
        } else {
          __out.push('\n            <span class=\'icon-business-default-avatar font-50\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n            ');
        }
        __out.push('\n            </div>\n        </div>\n        <div class="display-table-cell vertical-align-middle p-l-30">\n            <span class="font-18">');
        __out.push(__sanitize(business.legal_name));
        __out.push('</span>\n        </div>\n        ');
        if (business.issues === 1) {
          __out.push('\n        <div class="display-table-cell vertical-align-middle text-right">\n            <span class="business-issue-wrapper bg-red-100 in-white font-14">');
          __out.push(__sanitize(business.issues));
          __out.push(' issue</span>\n        </div>\n        ');
        }
        __out.push('\n    </a>\n    ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}