import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IFolderOpneIconProps extends Omit<IFaIconProps, 'icon'> {
  variant?: 'o',
}

const FolderOpenIcon = ({
  variant,
  ...props
}: IFolderOpneIconProps) => {
  return (
    <FaIcon
      icon={ variant === 'o' ? `folder-open-${variant}` : 'folder-open' }
      { ...props }
    />
  );
};

export default FolderOpenIcon;
