import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IDataExportData, TDataExportColumnMappings } from '@src/types/data_exports';
import { formatDate } from '@src/utils/date_helpers';

import { ExportCSVAction } from '@src/components/common/actions/export_csv';

interface IExportTransactionsCSVActionProps {
  exportParams: IDataExportData['exportParams'],
}

const columnMappings = (): TDataExportColumnMappings => {
  return [
    ['transaction_date', 'Date'],
    ['description', 'Transaction Description'],
    ['transaction_type', 'Transaction Type'],
    ['employee_card_name', 'Banking Account'],
    ['imported_at', 'Imported At'],
    ['amount', 'Amount'],
  ];
};

const ExportTransactionsCSVAction = ({
  exportParams,
}: IExportTransactionsCSVActionProps) => {
  const business = useBusinessContext();

  return (
    <ExportCSVAction
      atomKey="bankingAccountTransactions"
      businessId={ business.id }
      columnMappings={ columnMappings() }
      exportName={ `Banking Account export ${formatDate(new Date())}` }
      exportParams={ exportParams }
    />
  );
};

export default ExportTransactionsCSVAction;
