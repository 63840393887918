import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TFilterData } from '@src/types/filter';

import {
  PaymentAccountFilterField,
  VendorFilterField,
} from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

interface UncategorizedTransactionsFilterProps {
  queryParams: TFilterData,
}

const UncategorizedTransactionsFilter: FC<UncategorizedTransactionsFilterProps> = () => {
  const business = useBusinessContext();
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('transaction_date') && (
      <Filter.DateField
        name="transaction_date"
      />
      )) }
      { (visibleColumnNames.includes('description') && (
      <Filter.TextField
        label="Description"
        name="description"
        placeholder="Description"
      />
      )) }
      { (visibleColumnNames.includes('vendor') && (
      <VendorFilterField
        businessId={ business.id }
        name="vendorId"
        placeholder="Vendor"
      />
      )) }
      { (visibleColumnNames.includes('account') && (
      <PaymentAccountFilterField
        businessId={ business.id }
        placeholder="Account"
      />
      )) }
      { (visibleColumnNames.includes('amount') && (
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(UncategorizedTransactionsFilter);
