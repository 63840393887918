import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IRevenueServiceDocumentUpsertedFayeEvent } from '@src/types/docyt_events/revenue_service_document_upserted';
import { IRevenueSystemUpsertedFayeEvent } from '@src/types/docyt_events/revenue_system_upserted';

import { useFayeChannel } from './common';

export const useRevenueServiceFayeChannel = (
  businessId: TID | null,
  callback: TFayeEventCallback<
    IRevenueSystemUpsertedFayeEvent | IRevenueServiceDocumentUpsertedFayeEvent
  >,
) => {
  useFayeChannel(
    businessId ? `/business/${businessId}/revenue-service` : null,
    callback,
  );
};
