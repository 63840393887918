import React, { useCallback } from 'react';

import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import { AngleDownIcon } from '@src/components/utils/fa_icons';
import { useItemsSelector } from '@src/components/utils/items_selector';
import AddToMyVendorsAction
  from '@src/components/vendors/details/list_item/docyt_network_item_actions/add_to_my_vendors_action';

interface IBulkActionsProps {
  onBulkActionFinished: (bulkCount: number) => void,
}

const BulkActions = ({
  onBulkActionFinished,
}: IBulkActionsProps): JSX.Element => {
  const { selected, reset } = useItemsSelector<IGlobalVendorWithBusinessInfo>();

  const handleFinished = useCallback((bulkCount: number) => {
    reset();
    onBulkActionFinished(bulkCount);
  }, [reset, onBulkActionFinished]);

  return (
    <div className="bulk-action-container dropdown">
      <button
        aria-expanded="false"
        aria-haspopup="true"
        className="btn dropdown-toggle font-15 font-bold btn-blue-on-hover"
        data-container="body"
        data-toggle="dropdown"
        disabled={ selected.length === 0 }
        id="bulk-action-dropdown-btn"
        type="button"
      >
        Bulk Actions
        <AngleDownIcon ml={ 5 } />
      </button>
      <div
        aria-labelledby="bulk-action-dropdown-btn"
        className="bulk-action-drop-down dropdown-menu dropdown-menu-right"
      >
        <AddToMyVendorsAction vendors={ selected } onFinished={ handleFinished } />
      </div>
    </div>
  );
};

export {
  IBulkActionsProps,
  BulkActions,
};
