import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { DocumentedIcon, DocumentIcon, NoDocumentNeededIcon } from '@src/components/utils/icomoon';

const documentTypeOptions: TIconOption[] = [
  {
    icon:  <DocumentIcon color="grey" fontSize={ 18 } />,
    label: 'Undocumented Transactions',
    value: 'undocumented',
  },
  {
    icon:  <NoDocumentNeededIcon fontSize={ 22 } />,
    label: 'No Document Needed',
    value: 'no_documented',
  },
  {
    icon:  <DocumentedIcon fontSize={ 22 } />,
    label: 'Documented Transactions',
    value: 'documented',
  },
];

interface IDocumentTypeFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.IconSelectField>, 'options' | 'name'>
{
}

const DocumentTypeFilterField = ({
  ...props
}: IDocumentTypeFilterFieldProps): JSX.Element => {
  return (
    <Filter.IconSelectField
      isSearchable={ false }
      name="has_related_document"
      options={ documentTypeOptions }
      placeholder="Select Documentation Status..."
      { ...props }
    />
  );
};

export default React.memo(DocumentTypeFilterField);
