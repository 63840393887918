import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, useController } from 'react-hook-form';
import { object, string } from 'yup';

import ManagementGroupField from '@src/components/common/form_fields/management_group_field';
import Form from '@src/components/ui/form';

import { IConnectNewFinancialInstitutionData } from './schema';

import styles from '../styles.module.scss';

const validation = object({
  managementGroupId: string().trim().required('Management Group is required.'),
});

interface IConnectNewFinancialInstitutionFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IConnectNewFinancialInstitutionData>,
}

const ConnectNewFinancialInstitutionForm = ({
  formId,
  onSubmit,
}: IConnectNewFinancialInstitutionFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IConnectNewFinancialInstitutionData>({
    defaultValues: {
      managementGroupId: undefined,
    },
    resolver: yupResolver(validation),
  });

  const managementGroupController = useController({ name: 'managementGroupId', control });

  return (
    <Form
      className="add-transaction-form"
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <div className={ styles['new-connect-modal-content'] }>
        <span className="font-bold">
          Select a Management Group to add the Financial Institution to
        </span>
        <span>
          Only the admins in banking accounts of the businesses in the selected Management Group
          { ' ' }
          will have access to this financial institution.
        </span>

        <div className={ styles['select-management-group'] }>
          <ManagementGroupField
            error={ errors.managementGroupId?.message }
            label="Management Group"
            placeholder="Select Management Group"
            { ...managementGroupController.field }
          />
        </div>
      </div>
    </Form>
  );
};

export {
  IConnectNewFinancialInstitutionFormProps,
  ConnectNewFinancialInstitutionForm as default,
};
