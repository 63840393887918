import React, { useCallback } from 'react';

import { useUpdateCustomReportService } from '@src/hooks/queries/report_service/custom_report_service';
import { ICustomReportServiceResponse } from '@src/requests/report_service/custom_report_service';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { MarkReviewedIcon } from '@src/components/utils/icomoon';

interface IBudgetItemSetAsDefaultActionProps {
  budget: IReportServiceBudget,
  setAsDefault: (defaultBudgetId: string) => void,
}

const BudgetItemSetAsDefaultAction = ({
  budget,
  setAsDefault,
}: IBudgetItemSetAsDefaultActionProps) => {
  const setDefault = useUpdateCustomReportService();

  const { mutate } = setDefault;
  const handleSelect = useCallback(() => {
    mutate(
      {
        reportServiceId: budget.reportServiceId,
        defaultBudgetId: budget.id,
      },
      {
        onSuccess: (data: ICustomReportServiceResponse) => {
          setAsDefault(data.reportService.defaultBudgetId);
        },
      },
    );
  }, [budget.id, budget.reportServiceId, mutate, setAsDefault]);

  if (budget.type === 'Budget') {
    return (
      <ActionsDropdown.Action
        icon={ <MarkReviewedIcon fontSize={ 18 } /> }
        title="Set as default"
        onSelect={ handleSelect }
      />
    );
  }
  return (
    <ActionsDropdown.Action
      icon={ <MarkReviewedIcon fontSize={ 18 } /> }
      style={ { display: 'none' } }
      title="Set as default"
      onSelect={ handleSelect }
    />
  );
};

export default BudgetItemSetAsDefaultAction;
