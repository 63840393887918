import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import {
  useGetBankAccountReconciliationDocuments,
  useGetPushedOutsideBankAccountReconciliationDocuments,
} from '@src/hooks/queries/bank_account_reconciliation_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetBankAccountReconciliationDocumentsResponse } from '@src/requests/bank_account_reconciliation_documents';
import {
  IBankAccountReconciliationDocument,
  TIBankAccountReconciliationDocumentSortColumn,
} from '@src/types/bank_account_reconciliation_document';
import { TID, TSection } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useClearFilter, useFilterData } from '@src/components/ui_v2/filter';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseCollectionParams {
  businessId: TID,
  params: TFilterData,
}

interface ICollection {
  query: UseInfiniteQueryResult<IGetBankAccountReconciliationDocumentsResponse, Error>,
  records: IBankAccountReconciliationDocument[],
  section: TSection,
  sorting: ISorting<TIBankAccountReconciliationDocumentSortColumn>,
  selectedRecords: IBankAccountReconciliationDocument[],
  selectedIds: any[],
  setSelected: (selected: any[]) => void
}

const defaultSorting: ISortingParams<TIBankAccountReconciliationDocumentSortColumn> = {
  orderColumn:    'transaction_date',
  orderDirection: 'desc',
};

const useExcludedAndNotPushedCollection = ({
  businessId,
  params,
}: IUseCollectionParams): ICollection => {
  const section = useMemo(() => {
    return { businessId, section: 'excluded-and-not-pushed' };
  }, [businessId]);

  const clearFilter = useClearFilter(section);
  useEffect(() => clearFilter(), [clearFilter]);

  const filterData = useFilterData(section, params);
  const filterParams = useMemo(() => {
    return { filter: filterData };
  }, [filterData]);

  const sorting = useSorting<TIBankAccountReconciliationDocumentSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetBankAccountReconciliationDocuments({
    businessId,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds, setSelected } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((it) => selectedIds.includes(it.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
    selectedIds,
    setSelected,
  };
};

const usePushedOutsideCollection = ({
  businessId,
  params,
}: IUseCollectionParams): ICollection => {
  const section = useMemo(() => {
    return { businessId, section: 'pushed-outside' };
  }, [businessId]);

  const clearFilter = useClearFilter(section);
  useEffect(() => clearFilter(), [clearFilter]);

  const filterData = useFilterData(section, params);
  const filterParams = useMemo(() => {
    return { filter: filterData };
  }, [filterData]);

  const sorting = useSorting<TIBankAccountReconciliationDocumentSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetPushedOutsideBankAccountReconciliationDocuments({
    businessId,
    ...filterParams,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  const { selected: selectedIds, setSelected } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((it) => selectedIds.includes(it.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
    selectedIds,
    setSelected,
  };
};

const useIsMountedRef = () => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
};

const useHandleCheckboxClick =
  (
    primaryCollection: ICollection,
    secondaryCollection: ICollection,
    setDeselectedItemGroups: React.Dispatch<React.SetStateAction<number[][]>>,
  ) => {
    const handleCheckboxClick = useCallback((clickedItemId) => {
      const clickedItem = primaryCollection.records.find((i) => i.id === clickedItemId);

      if (!clickedItem) return;
      const isSelected = primaryCollection.selectedIds.includes(clickedItemId);

      if (isSelected) {
        if (clickedItem.matchedDocumentIds.length > 0) {
          // Sort matched IDs for a reliable comparison
          const clickedItemMatchedIdsSorted = clickedItem.matchedDocumentIds.sort().toString();

          // Identify all items to deselect based on matching criteria
          const allItemsToDeselect = primaryCollection.selectedRecords
            .filter(
              (record) => record.matchedDocumentIds.sort().toString()
                === clickedItemMatchedIdsSorted,
            )
            .map((record) => record.id);

          // Deselect items in the primary collection
          const newPrimarySelected = primaryCollection.selectedIds
            .filter((id) => !allItemsToDeselect.includes(id));
          primaryCollection.setSelected(newPrimarySelected);

          // Deselect matched items in the secondary collection
          const newSecondarySelected = secondaryCollection.selectedIds
            .filter((id) => !clickedItem.matchedDocumentIds.includes(id));
          secondaryCollection.setSelected(newSecondarySelected);

          setDeselectedItemGroups([allItemsToDeselect, clickedItem.matchedDocumentIds]);
        } else {
          primaryCollection.setSelected(
            primaryCollection.selectedIds.filter((i) => i !== clickedItemId),
          );
        }
      } else {
        // Select the item in the primary collection if not already selected
        primaryCollection.setSelected([...primaryCollection.selectedIds, clickedItemId]);
      }
    }, [primaryCollection, secondaryCollection, setDeselectedItemGroups]);

    return handleCheckboxClick;
  };

const useSetCollectionSelected = (collection: ICollection, showUnmatchedOnly: boolean) => {
  const { records, setSelected } = collection;
  useEffect(() => {
    const selectedIds = records
      .filter((i) => i.matchedDocumentIds.length > 0)
      .map((i) => i.id);
    setSelected(selectedIds);
  }, [records, setSelected, showUnmatchedOnly]);
};

export {
  ICollection,
  useExcludedAndNotPushedCollection,
  usePushedOutsideCollection,
  useIsMountedRef,
  useHandleCheckboxClick,
  useSetCollectionSelected,
};
