import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const GoBusinessHomeIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="go-business-home"
      pathsNumber={ 2 }
    />
  );
};

export default GoBusinessHomeIcon;
