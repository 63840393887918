import React, { useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { UseQueryResult } from 'react-query';

import { IBusinessMetricValueByDate } from '@src/types/business_metric_value';
import { IMetric } from '@src/types/metrics';

import Table from '@src/components/ui_v2/table';

import Row from './row';
import { IDimensionLabels } from './types';

interface IBusinessMetricDimensionTableProps {
  businessMetric: IMetric,
  query: UseQueryResult<any, Error>,
  isEditMode: boolean,
  hideInactive: boolean,
}

const BusinessMetricDimensionTable = ({
  businessMetric,
  query,
  isEditMode,
  hideInactive,
}: IBusinessMetricDimensionTableProps): JSX.Element => {
  const { watch } = useFormContext<{ values: IBusinessMetricValueByDate[] }>();
  const editingValues = watch('values');

  const dimensions = businessMetric.dimensions;
  const dimensionLabels = useMemo(() => {
    return businessMetric.dimensionLabels.reduce((acc, label) => {
      acc[label.dimensionId] = acc[label.dimensionId] || [];
      acc[label.dimensionId].push(label);
      return acc;
    }, {} as IDimensionLabels);
  }, [businessMetric.dimensionLabels]);

  const businessMetricValues = useMemo(() => {
    return hideInactive && !isEditMode
      ? editingValues?.filter((item) => {
          const value = item.value as number | string | null;
          return value !== 0 && value !== '0' && value !== null;
        })
      : editingValues;
  }, [editingValues, hideInactive, isEditMode]);

  return (
    <>
      <Table query={ query }>
        <Table.Head>
          <Table.Row>
            {dimensions.map((dimension) => (
              <Table.HCell key={ dimension.id }>
                {dimension.name}
              </Table.HCell>
            ))}
            <Table.NumberHCell>{ businessMetric.name }</Table.NumberHCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {businessMetricValues?.map((value, index) => (
            <Row
              key={ value.date }
              dimensionLabels={ dimensionLabels }
              dimensions={ dimensions }
              index={ index }
              isEditMode={ isEditMode }
              value={ value }
            />
          ))}
        </Table.Body>
      </Table>
      <div>
        {businessMetricValues?.length === 0 && (
          <div className="text-center p-t-25 p-b-25" style={ { width: '100%' } }>
            <span>No data available</span>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(BusinessMetricDimensionTable);
