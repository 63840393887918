import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import {
  IGetInboxServiceDocumentsParams,
  IRenameInboxServiceDocumentParams,
  getInboxServiceDocuments,
  putRenameInboxServiceDocument,
  IRenameInboxServiceDocumentResponse,
  IEmptyTrashInboxServiceDocumentParams,
  putEmptyTrashInboxServiceDocuments,
  IDeleteMultipleServiceDocumentsParams,
  deleteMultipleInboxServiceDocuments,
  ISetInboxServiceDocumentFlagResponse,
  ISetInboxServiceDocumentFlagParams,
  putSetInboxServiceDocumentFlag,
  IGetInboxServiceDocumentsResponse,
} from '@src/requests/inbox_service_documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { IBulkMutationReturn, IBulkMutationParams, makeBulkRequest } from './bulk_mutations';
import { INBOX_SERVICE_QUERY } from './inbox_services';
import {
  createUseGetInfiniteCollection,
  updateItemsInInfiniteCollection,
} from './infinite_collection_queries';

const INBOX_SERVICE_DOCUMENTS_QUERY = 'inbox_service_documents';

const useGetInboxServiceDocuments = createUseGetInfiniteCollection<
  IInboxServiceDocument,
  IGetInboxServiceDocumentsParams,
  IGetInboxServiceDocumentsResponse
>({
  queryKey: INBOX_SERVICE_DOCUMENTS_QUERY,
  request:  getInboxServiceDocuments,
});

const useEmptyTrashInboxServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IEmptyTrashInboxServiceDocumentParams>(
    putEmptyTrashInboxServiceDocuments,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INBOX_SERVICE_QUERY);
        queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY);
      },
    },
  );
};

const useRenameInboxServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<IRenameInboxServiceDocumentResponse, Error, IRenameInboxServiceDocumentParams>(
    putRenameInboxServiceDocument,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return updateItemsInInfiniteCollection<
              IInboxServiceDocument,
              IGetInboxServiceDocumentsResponse
            >(
              data,
              [params.documentId],
              { name: response.name },
            );
          },
        );
      },
    },
  );
};

const useSetInboxServiceDocumentFlag = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ISetInboxServiceDocumentFlagResponse,
    Error,
    ISetInboxServiceDocumentFlagParams
  >(
    putSetInboxServiceDocumentFlag,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return updateItemsInInfiniteCollection<
              IInboxServiceDocument,
              IGetInboxServiceDocumentsResponse
            >(
              data,
              [params.documentId],
              { flagState: response.flagState },
            );
          },
        );
      },
    },
  );
};

const useBulkDeleteInboxServiceDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IDeleteMultipleServiceDocumentsParams>
  >(
    makeBulkRequest(deleteMultipleInboxServiceDocuments),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(INBOX_SERVICE_QUERY)
          .then(() => queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY));
      },
    },
  );
};

const useDeleteInboxServiceDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteMultipleServiceDocumentsParams>(
    deleteMultipleInboxServiceDocuments,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(INBOX_SERVICE_QUERY);
        queryClient.invalidateQueries(INBOX_SERVICE_DOCUMENTS_QUERY);
      },
    },
  );
};

export {
  INBOX_SERVICE_DOCUMENTS_QUERY,
  useBulkDeleteInboxServiceDocuments,
  useDeleteInboxServiceDocuments,
  useEmptyTrashInboxServiceDocument,
  useGetInboxServiceDocuments,
  useRenameInboxServiceDocument,
  useSetInboxServiceDocumentFlag,
};
