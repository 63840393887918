import React, { useMemo } from 'react';

import castArray from 'lodash/castArray';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

import { ErrorNotification } from '@src/components/ui/notification';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface LocalQueryStatusProps {
  query?: UseInfiniteQueryResult<any, Error>
    | UseQueryResult<any, Error>
    | UseInfiniteQueryResult<any, Error>[]
    | UseQueryResult<any, Error>[],
  noSpinner?: boolean,
}

const LocalQueryStatus = ({
  query,
  noSpinner,
}: LocalQueryStatusProps): JSX.Element | null => {
  const isLoading = useMemo(() => {
    if (!query) return false;

    return castArray(query).some((q) => {
      if (!q) return false;

      if (q.isLoading || q.isFetching || q.isRefetching) {
        return true;
      }

      if ('isFetchingNextPage' in q) return q.isFetchingNextPage;

      return false;
    });
  }, [query]);

  const error = useMemo(() => {
    if (!query) return null;

    const queries = castArray(query);

    return queries.find((q) => q?.isError)?.error;
  }, [query]);

  if (isLoading && !noSpinner) {
    return (
      <div className={ styles['local-query-status'] }>
        <SpinnerIcon spin size="3x" />
        <span className={ styles.label }>Loading...</span>
      </div>
    );
  }

  if (error) {
    return <ErrorNotification error={ error } />;
  }

  return null;
};

export default LocalQueryStatus;
