import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import detailStyles from './styles.module.scss';

const NameColumn: FC<{document: IAdjustmentEntry}> = ({ document }) => {
  const business = useBusinessContext();
  const { docytId } = document;
  const open = () => {
    Backbone.history.navigate(
      `/businesses/${business.id}/reconciliation_center/journal_entry?docyt_id=${docytId}`,
      { trigger: true },
    );
  };

  return (
    <button className={ detailStyles['custom-button'] } type="button" onClick={ open }>
      { docytId }
    </button>
  );
};

export default NameColumn;
