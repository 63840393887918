module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content modal-invite-vendor\'>\n      <div class="modal-header">\n        <h1 class="modal-title font-bold">Invite Vendor to Docyt</h1>\n      </div>\n      ');
    
      if (this.step === 1) {
        __out.push('\n      <div class=\'modal-body\'>\n        <div class="m-t-10">\n          <label class="m-b-10" for="upload-email-input">Provide email to invite vendor to Docyt.</label>\n          <div class="display-flex align-items-center">\n            <div class="upload-email-prefix width-200px">Vendor Email</div>\n            <input type="text" id="email-input" class="form-control" autocomplete="off" placeholder="Vendor’s Email Address" value="');
        __out.push(__sanitize(this.email));
        __out.push('">\n          </div>\n          <p class="text-left in-red-400 email-name-warning m-t-10 m-b-0">\n            <i class="fa fa-warning m-r-5"></i>Please input valid email.\n          </p>\n        </div>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Continue</span></button>\n      </div>\n      ');
      } else {
        __out.push('\n      <div class="item-divider p-b-15 p-l-30">\n        <span class="font-bold font-16 m-r-10">To:</span>\n        <span class="font-14">');
        __out.push(__sanitize(this.email));
        __out.push('</span>\n      </div>\n      <div class="item-divider p-t-15 p-l-30 p-r-30">\n        <p>Hi ');
        __out.push(__sanitize(this.vendor.get('name')));
        __out.push(',</p>\n        <p>');
        __out.push(__sanitize(this.business.get('display_name')));
        __out.push(' (');
        __out.push(__sanitize(this.business.get('legal_name')));
        __out.push(') is working to pay one of your recent invoices using Docyt, a software that generates secure Check and ACH payments. Accept this invitation to get paid quicker through Docyt ACH. This will require a few quick steps to verify your W9 and ACH information so that we can process payments securely.</p>\n        <p>Docyt is a free service that provides fast and secure ACH Payments, Docyt will ensure your bank information will not be visible to anyone. This profile will enable you to reconcile your ACH transactions and track them to their specific invoice information. Help cut your business steps by reducing paperwork and receiving your payments quicker. </p>\n        <p>You can read more about Docyt’s security policies <a class="font-bold" href="https://docyt.com/security/" target="_blank">here</a>. If you have more questions you can contact Docyt directly at <a class="font-bold" href="mailto:support@docyt.com">support@docyt.com</a>.</p>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Send Invitation</span></button>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}