module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header text-center">\n        <span class="font-28 font-bold m-l-15">Unusual Categorization Detected</span>\n      </div>\n      <div class="modal-body">\n        <p>This transaction is being categorized differently than past similar transactions.</p>\n\n        <p style="text-align: center">');
    
      __out.push(__sanitize(this.transaction.get('transaction_date')));
    
      __out.push(' &emsp; ');
    
      __out.push(__sanitize(this.transaction.get('description')));
    
      __out.push(' &emsp; ');
    
      __out.push(__sanitize(parseFloat(this.transaction.get('amount')).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</p>\n\n        <p>What would you like Auto-pilot to do for future similar transactions?</p>\n\n        <div role="button" tabindex="1">\n          <div class="checkbox checkbox-primary checkbox-circle">\n            <input\n              name="user-pattern-radio"\n              id="user-pattern-update"\n              type="radio"\n              value="update"\n              class="user-pattern-radio hidden"\n              ');
    
      if (this.action === 'update') {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('\n            />\n            <label\n              class="forward-to-service-checkbox-label"\n              for="user-pattern-update"\n            >\n              <p><b>Learn the new categorization:</b> Start auto-categorizing as ');
    
      __out.push(__sanitize(this.updatedPattern));
    
      __out.push('\n            </label>\n          </div>\n        </div>\n        <div role="button" tabindex="2">\n          <div class="checkbox checkbox-primary checkbox-circle">\n            <input\n              name="user-pattern-radio"\n              id="user-pattern-keep"\n              type="radio"\n              value="keep"\n              class="user-pattern-radio hidden"\n              ');
    
      if (this.action === 'keep') {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('\n            />\n            <label\n              class="forward-to-service-checkbox-label"\n              for="user-pattern-keep"\n            >\n              <p><b>Do not learn the new categorization:</b> Keep auto-categorizing as ');
    
      __out.push(__sanitize(this.existingPattern));
    
      __out.push('\n            </label>\n          </div>\n        </div>\n        <div role="button" tabindex="3">\n          <div class="checkbox checkbox-primary checkbox-circle">\n            <input\n              name="user-pattern-radio"\n              id="user-pattern-ignore"\n              type="radio"\n              value="ignore"\n              class="user-pattern-radio hidden"\n              ');
    
      if (this.action === 'ignore') {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('\n            />\n            <label\n              class="forward-to-service-checkbox-label"\n              for="user-pattern-ignore"\n            >\n              <p><b>Stop auto-categorizing similar transactions:</b> I will manually categorize them</p>\n            </label>\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel pointer m-t-8 pull-left\'>Cancel</a>\n        <button type="button" class="btn btn-blue confirm-btn">Confirm</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}