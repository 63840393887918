import { object, string } from 'yup';

const ssoRegisterValidation = object({
  email:    string().email().required('Email must be a valid email.'),
  password: string().required('Password is required.'),
  ssoToken: string().required('SSO token is required.'),
});

interface ISSORegisterFormValues {
  email: string;
  password: string;
  ssoToken: string;
}

export { ISSORegisterFormValues, ssoRegisterValidation };
