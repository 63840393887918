import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const HandRight = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="hand-right"
    />
  );
};

export default HandRight;
