module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n  <div class="header-simple-title-section">\n    <div class="m-l-auto m-r-auto text-decoration-blank client-setting-header in-black m-t-20 m-b-20">\n      <span class="font-20 text-decoration-blank m-r-10">\n        Client Management\n      </span>\n      <div class="client__breadcrumbs-item font-30 m-r-10"></div>\n      <a class="employee-avatar-with-border">\n        ');
    
      if (this.businessAvatar) {
        __out.push('\n          <img src="');
        __out.push(__sanitize(this.businessAvatar));
        __out.push('" class=\'clients__userpic-img\'>\n        ');
      } else {
        __out.push('\n        <span class=\'icon-business-default-avatar font-38\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n        ');
      }
    
      __out.push('\n      </a>\n      <span class="font-24 font-bold m-l-10">\n        ');
    
      __out.push(__sanitize(this.businessName));
    
      __out.push('\n      </span>\n    </div>\n    <a class="font-40 in-grey-900 pointer close-page-btn">\n      <i class="icon icon-reject-invite font-bold" />\n    </a>\n  </div>\n  <div class="header-menu-section">\n    <ul class="nav sub-header-menu app-header-sub-menu client-detail-header-menu">\n      <li class="');
    
      __out.push(__sanitize(this.detailType === 'profile' ? 'active' : ''));
    
      __out.push('">\n        <a class="" href="/settings/client_management/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/profile"> BUSINESS PROFILE </a>\n      </li>\n      <li class="');
    
      __out.push(__sanitize(this.detailType === 'billing' ? 'active' : ''));
    
      __out.push('">\n        <a class="" href="/settings/client_management/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/billing"> BILLING </a>\n      </li>\n      ');
    
      if (this.isAdmin) {
        __out.push('\n      <li class="');
        __out.push(__sanitize(this.detailType === 'team_members' ? 'active' : ''));
        __out.push('">\n        <a class="" href="/settings/client_management/');
        __out.push(__sanitize(this.businessId));
        __out.push('/team_members"> ACCOUNTING TEAM </a>\n      </li>\n      ');
      }
    
      __out.push('\n      <li class="');
    
      __out.push(__sanitize(this.detailType === 'team_management' ? 'active' : ''));
    
      __out.push('">\n        <a class="" href="/settings/client_management/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/team_management"> BUSINESS EMPLOYEES </a>\n      </li>\n    </ul>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}