import React from 'react';

import { useCreateItemSelector } from './hooks';
import { IItemSelector } from './types';

interface IItemSelectorProviderProps<Type> {
  children: React.ReactNode,
  allItems: Type[],
}

const ItemsSelectorContext =
  React.createContext<IItemSelector<any> | null>(null);

const ItemSelectorProvider = <Type, >({
  allItems,
  children,
}: IItemSelectorProviderProps<Type>): JSX.Element => {
  const itemsSelector = useCreateItemSelector<Type>({ allItems });

  return (
    <ItemsSelectorContext.Provider value={ itemsSelector }>
      { children }
    </ItemsSelectorContext.Provider>
  );
};

const MemoizedItemSelectorProvider = React.memo(ItemSelectorProvider);

export {
  ItemsSelectorContext,
  MemoizedItemSelectorProvider as default,
};
