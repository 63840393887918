import React, { useCallback, useLayoutEffect, useRef } from 'react';

import styles from './styles.module.scss';

interface ISideViewRegionProps {
  children: React.ReactNode;
}

const SideViewRegion = ({
  children,
}: ISideViewRegionProps): JSX.Element => {
  const regionRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!regionRef.current) return;

    const rect = regionRef.current.getBoundingClientRect();

    regionRef.current.style.minHeight = `calc(100vh - ${rect.top}px`;
  }, []);

  useLayoutEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <div
      ref={ regionRef }
      className={ styles['right-side-view-region'] }
    >
      { children }
    </div>
  );
};

export default React.memo(SideViewRegion);
