import React, { memo, useCallback, useState } from 'react';

import Root from '@src/components/root';

import ChatCentralSession from './chat_central_session';
import ChannelListView from './components/channel_list/list';
import ChatBody from './components/chat_container/chat_body';
import { SendableMessageType } from './types';

import styles from './styles.module.scss';

const ChatCentralHomeView = () => {
  const [currentChannelUrl, setCurrentChannelUrl] = useState<string>();
  const [parentMessage, setParentMessage] = useState<SendableMessageType | null>(null);

  const setChannelUrl = useCallback((channelUrl: string) => {
    setCurrentChannelUrl(channelUrl);
    setParentMessage(null);
  }, []);

  return (
    <Root>
      <div className={ styles['chat-central-container'] }>
        <ChatCentralSession>
          <ChannelListView
            currentChannelUrl={ currentChannelUrl }
            setChannelUrl={ setChannelUrl }
          />
          <ChatBody
            channelUrl={ currentChannelUrl ?? '' }
            parentMessage={ parentMessage }
            setCurrentChannelUrl={ setChannelUrl }
            setParentMessage={ setParentMessage }
          />
        </ChatCentralSession>
      </div>
    </Root>
  );
};

export default memo(ChatCentralHomeView);
