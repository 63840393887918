import React, { useCallback, useMemo } from 'react';

import { flatMapDeep } from 'lodash';

import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import { DropdownInput, formatSelectOption, TOption } from '@src/components/ui_v2/inputs';

interface IInvoiceTypesFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof DropdownInput>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  invoiceTypesName?: string,
}

const TYPE_OPTIONS: TOption[] = [
  {
    label: 'Bill',
    value: 'bill',
  },
  {
    label: 'Reimbursement',
    value: 'reimbursement'
  }
];

const formatTooltip = (option: TOption | null): JSX.Element | null => {
  if (!option) return null;

  return formatSelectOption(option);
};

const InvoiceTypesFilterField = <TFilter extends TFilterData>({
  invoiceTypesName = 'types',
  ...props
}: IInvoiceTypesFilterFieldProps) => {
  const [types, updateTypes] = useFilterField<TFilter, string | null>(invoiceTypesName);
  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedValue?: TOption | null) => {
    updateTypes(selectedValue?.value || undefined);
  }, [updateTypes]);

  const currentOption = useMemo(() => {
    return flatMapDeep(TYPE_OPTIONS, (opt) => {
      return opt;
    }).find((opt) => {
      return types && opt.value === types;
    });
  }, [types]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption || null) }
      tooltipLabel="Type: "
    >
      <DropdownInput
        placeholder="Select Status..."
        { ...inputProps }
        options={ TYPE_OPTIONS }
        value={ currentOption || null }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(InvoiceTypesFilterField);
