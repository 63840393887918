import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import ImageDropInput from './image_drop_input';

interface IImageSelectionModalProps extends Omit<IUseModalProps, 'onDone'> {
  title: string,
  onDone: (files: File[]) => void
}

const ImageSelectionModal = ({
  title,
  isOpen,
  onCancel,
  onDone,
}: IImageSelectionModalProps): JSX.Element => {
  const handleFileSelected = useCallback((files) => {
    onDone(files);
  }, [onDone]);

  return (
    <Modal dialogClassName="upload-file-modal" show={ isOpen } title={ title }>
      <Modal.Body>
        { isOpen && (
          <ImageDropInput onFileSelected={ handleFileSelected } />
        )}
      </Modal.Body>
      <Modal.Footer>
        <CancelModalButton onClick={ onCancel } />
      </Modal.Footer>
    </Modal>
  );
};

const useImageSelectionModal = makeUseModal(ImageSelectionModal);

export {
  IImageSelectionModalProps,
  useImageSelectionModal,
  ImageSelectionModal as default,
};
