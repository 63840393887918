module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="setup-business-step-section m-t-50">\n  <div class="main-panel">\n    <h2 class="panel-title">Add Business Info</h2>\n    <p class="panel-description">\n      This is required to setup your business\n    </p>\n    <div class="text-left profile-content-wrapper font-14">\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Business Display Name</label>\n        <label class="width-60-percent text-left in-grey-1050">');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('</label>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Account Holder’s Legal Name</label>\n        <div class="address-wrapper width-60-percent">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-first-name form-control" type="text" placeholder="First name" value="');
    
      __out.push(__sanitize(this.dwollaCustomer.get('first_name')));
    
      __out.push('">\n            <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-last-name form-control" type="text" placeholder="Last name" value="');
    
      __out.push(__sanitize(this.dwollaCustomer.get('last_name')));
    
      __out.push('">\n            <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Account Holder’s Email</label>\n        <input class="input-email form-control width-60-percent" type="email" placeholder="example@company.com" value="');
    
      __out.push(__sanitize(this.dwollaCustomer.get('email')));
    
      __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning in-red-400"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Legal Entity Name</label>\n        <input class="input-entity-name form-control width-60-percent" type="text" placeholder="Enter your formal business name" value="');
    
      __out.push(__sanitize(this.dwollaCustomer.get('business_name')));
    
      __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning in-red-400"></i></p>\n      </div>\n      <hr class="border-gray m-t-30 m-b-30 width-100-percent">\n      <div class="checkbox m-t-0 m-b-0 text-left font-12">\n        <input id="checkterm" type="checkbox" class="styled pointer accept-term-js" data-type="checkbox" ');
    
      __out.push(__sanitize(this.dwollaCustomer.get('is_dwolla_terms_accepted') ? 'checked' : ''));
    
      __out.push('>\n        <label for="checkterm">By checking this box you agree to our partner Dwolla\'s <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/tos/">Terms and Conditions</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a></label>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}