import React, { useCallback } from 'react';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useSaveReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';

import { Button } from '@src/components/ui/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import { useSaveBudgetModal } from '../modal';

interface IBudgetDetialFooterProps {
  isDraft: boolean,
  onSetDraft: (value: boolean) => void,
}

const BudgetDetailFooter = ({
  isDraft,
  onSetDraft,
}: IBudgetDetialFooterProps) => {
  const budget = useReportServiceBudgetContext();
  const business = useBusinessContext();
  const save = useSaveReportServiceBudget();
  const saveBudgetModal = useSaveBudgetModal();

  const { mutate } = save;
  const onSave = useCallback(() => {
    mutate(
      { budgetId: budget.id, method: 'publish' },
      { onSuccess: () => onSetDraft(false) },
    );
  }, [budget.id, mutate, onSetDraft]);

  const handleClose = useCallback(() => {
    if (isDraft) {
      saveBudgetModal.open();
    } else {
      Backbone.history.navigate(`/businesses/${business.id}/reports/budgets`, { trigger: true });
    }
  }, [business.id, isDraft, saveBudgetModal]);

  return (
    <div className="report-budget-detail-footer">
      <saveBudgetModal.Component { ...saveBudgetModal.props } />
      <MutationStatus mutation={ save } successMessage="Saved successfully." />
      <a
        className="pointer cancel-link m-t-8 pull-left"
        role="button"
        tabIndex={ -1 }
        onClick={ handleClose }
      >
        Close
      </a>
      <div className="display-flex align-items-center">
        <Button
          bsColor="blue"
          disabled={ !isDraft }
          title="Save"
          onClick={ onSave }
        />
      </div>
    </div>
  );
};

export default BudgetDetailFooter;
