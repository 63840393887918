import React, { useCallback } from 'react';

import FullPageModal from '@src/components/ui_v2/full_page_modal';

import styles from './styles.module.scss';

const ReportTypes = () => {
  const handleCancelClick = useCallback(() => {
    window.Docyt.vent.trigger('close:report_type_layout:page');
  }, []);

  const handleProceedClick = () => {
    window.Docyt.vent.trigger('close:report_type_layout:page');
  };

  return (
    <FullPageModal>
      <FullPageModal.Header
        divider="horizontal"
        title="Mapping"
        onCancel={ handleCancelClick }
      />
      <FullPageModal.Body>
        <p>Please provide mapping of report Line items with respective Chart of Accounts</p>
      </FullPageModal.Body>
      <FullPageModal.Footer
        isProceedDisabled={ false }
        proceedTitle="Done"
        onCancel={ handleCancelClick }
        onProceed={ handleProceedClick }
      >
        <FullPageModal.FooterFiller>
          <span className={ styles['footer-mapping-modal'] } />
        </FullPageModal.FooterFiller>
      </FullPageModal.Footer>
    </FullPageModal>
  );
};

export default ReportTypes;
