import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TUUID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import { useAiChatBotContext } from '../context/ai_chat_context';

import styles from '../styles.module.scss';

interface IStartChatThreadProps {
    message?: string;
    isNewThread?: boolean;
    buttonText: string;
    prefixIcon?: React.ReactElement;
    channelId?: string;
    isActive?: boolean;
    threadId?: TUUID;
}

const StartChatThread = ({
  message,
  buttonText,
  prefixIcon,
  isNewThread = false,
  channelId,
  isActive = false,
  threadId,
}: IStartChatThreadProps) => {
  const buttonClass = classNames(styles['chat-btn'], {
    'chat-thread-btn': isNewThread,
    'active':          isActive,
  });
  const { startConversation, startNewThread } = useAiChatBotContext();

  const handleOnButtonClick = useCallback(() => {
    if (!channelId && !threadId && !message) {
      startNewThread();
      return;
    }
    startConversation({
      message,
      channelId,
      chatThreadId: threadId,
    });
  }, [startConversation, message, channelId, threadId, startNewThread]);

  return (
    <Button
      className={ buttonClass }
      prefixIcon={ prefixIcon }
      variant="link"
      onClick={ handleOnButtonClick }
    >
      {buttonText}
    </Button>
  );
};

export default StartChatThread;
