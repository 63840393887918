import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getBankFeedTransactionServiceDocuments,
  IGetBankFeedTransactionServiceDocumentsResponse,
  IGetBankFeedTransactionServiceDocumentsParams,
  getBankFeedInformation,
  IGetBankFeedInformationResponse,
} from '@src/requests/bank_feed_transaction_service_documents';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetBankFeedTransactionServiceDocuments = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetBankFeedTransactionServiceDocumentsParams,
  IGetBankFeedTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.bankFeedTransactionServiceDocument,
  request:  getBankFeedTransactionServiceDocuments,
});

const useGetBankFeedInformation = (businessId: TID, bankStatementId?: TID, enabled?: boolean) => {
  return useQuery<IGetBankFeedInformationResponse, Error>(
    [QueryKey.bankFeedInformation, { businessId, bankStatementId }],
    () => getBankFeedInformation({ businessId, bankStatementId }),
    {
      enabled: enabled && Boolean(bankStatementId),
    },
  );
};

export {
  useGetBankFeedTransactionServiceDocuments,
  useGetBankFeedInformation,
};
