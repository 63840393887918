import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface ICreditCardIconProps extends IIcomoonIconProps {
  variant: 'simple' | 'normal',
}

const CreditCardIcon = ({
  variant,
  ...props
}: ICreditCardIconProps) => {
  let iconName = 'credit-card-account';
  if (variant === 'simple') {
    iconName = 'credit-card';
  }

  return (
    <Icomoon
      { ...props }
      iconName={ iconName }
      pathsNumber={ 5 }
    />
  );
};

export default CreditCardIcon;
