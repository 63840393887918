import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';

import Details from './details';

interface IMultiEntityItemValueDetailPageProps {
  itemIdentifier: string,
  chartOfAccountId: TID,
  from: string,
  to: string,
  accountingClassId: TID | null,
  reportId: string,
  business: any,
}

const MultiEntityItemValueDetailPage = ({
  itemIdentifier,
  chartOfAccountId,
  from,
  to,
  accountingClassId,
  reportId,
  business,
}: IMultiEntityItemValueDetailPageProps): JSX.Element => {
  return (
    <Root>
      <div id="detail-region">
        <Details
          accountingClassId={ accountingClassId }
          business={ camelizeKeys(business) as IBusiness }
          chartOfAccountId={ chartOfAccountId }
          from={ from }
          itemIdentifier={ itemIdentifier }
          reportId={ reportId }
          to={ to }
        />
      </div>
    </Root>
  );
};

export default MultiEntityItemValueDetailPage;
