import React from 'react';

import { IBusinessUser } from '@src/types/business_users';
import { TID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards';

import { ContactIcon } from '@src/components/utils/icomoon';
import {
  DashboardsLockedIcon,
  DashboardsUnlockedIcon,
} from '@src/components/utils/img_icons';

import ShareAction from '../actions/share_action';

import styles from './styles.module.scss';

interface IDashboardNoPermissionViewProps {
  currentDashboard: IDashboard,
  managementGroupId?: TID,
  businessId?: TID,
  serviceProviderBusinessId?: TID | null,
  isLoading: boolean,
  onShareDone: (accessibleUsers: IBusinessUser[]) => void,
}

const DashboardNoPermissionView = ({
  currentDashboard,
  managementGroupId,
  businessId,
  serviceProviderBusinessId,
  isLoading,
  onShareDone,
}: IDashboardNoPermissionViewProps) => {
  return (
    <div className={ styles['dashboard-no-permission'] }>
      {
        isLoading ? (
          <div className={ styles['empty-wrapper'] }>
            <div className={ styles['empty-image'] }>
              <DashboardsUnlockedIcon />
            </div>
            <p className={ styles['empty-title'] }>
              Please wait...
            </p>
            <p className={ styles['empty-text'] }>
              The data will appear here shortly.
            </p>
            <div className={ styles['dashboard-loading'] }>
              <span>Loading Dashboard</span>
              <ContactIcon fontSize={ 20 } />
            </div>
          </div>
        ) : (
          <div className={ styles['empty-wrapper'] }>
            <div className={ styles['empty-image'] }>
              <DashboardsLockedIcon />
            </div>
            <p className={ styles['empty-title'] }>
              You don’t have access to this dashboard yet
            </p>
            <p className={ styles['empty-text'] }>
              The data will appear here once you add yourself to this dashboard.
            </p>
            <ShareAction
              businessId={ businessId }
              currentDashboard={ currentDashboard }
              managementGroupId={ managementGroupId }
              serviceProviderBusinessId={ serviceProviderBusinessId }
              title="Add yourself to the dashboard"
              onDone={ onShareDone }
            />
          </div>
        )
      }
    </div>
  );
};

export default DashboardNoPermissionView;
