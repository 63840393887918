import React, { useCallback, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

interface IDeleteBusinessProps extends IUseModalProps {
  businessName: string,
}

const DestroyBusinessModal = ({
  businessName,
  isOpen,
  onCancel,
  onDone,
}: IDeleteBusinessProps): JSX.Element => {
  const [nameMatched, setNameMatched] = useState(false);
  const handleChange = useCallback((e) => {
    setNameMatched(e.target.value === businessName);
  }, [businessName]);

  return (
    <Modal.Standard
      danger
      className="modal-position-middle"
      isProceedDisabled={ !nameMatched }
      proceedTitle="Proceed"
      show={ isOpen }
      title="Delete Business"
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => (
        <>
          <p className="p-b-0 font-16 in-black">
            {` Please, type “${businessName}” in the box below to confirm the deletion:`}
          </p>
          <input
            className="form-control"
            placeholder={ `Type “${businessName}” to delete the business` }
            style={ { width: '300px' } }
            type="text"
            onChange={ handleChange }
          />
        </>
      )}
    </Modal.Standard>
  );
};

const useDestroyBusinessModal = makeUseModal(DestroyBusinessModal);

export {
  IDeleteBusinessProps,
  DestroyBusinessModal,
  useDestroyBusinessModal as default,
};
