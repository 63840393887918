import React from 'react';

import {
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuProps,
} from 'react-select';

import { CaretIcon, RemoveIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

const ReactSelectClearIndicator = <TOption, TIsMulti extends boolean = false>({
  ...props
}: ClearIndicatorProps<TOption, TIsMulti>) => {
  return (
    <components.ClearIndicator { ...props }>
      <RemoveIcon />
    </components.ClearIndicator>
  );
};

const ReactSelectDropdownIndicator = <TOption, TIsMulti extends boolean = false>({
  ...props
}: DropdownIndicatorProps<TOption, TIsMulti>) => {
  return (
    <components.DropdownIndicator { ...props }>
      <CaretIcon variant="down" />
    </components.DropdownIndicator>
  );
};

const ReactSelectMenuWithFooter = <TOption, TIsMulti extends boolean = false>({
  children,
  menuFooter,
  ...props
}: MenuProps<TOption, TIsMulti> & { menuFooter?: React.ReactNode }) => {
  return (
    <components.Menu { ...props }>
      { children }
      { menuFooter && (
        <div className={ styles['select-menu-footer'] }>
          { menuFooter }
        </div>
      ) }
    </components.Menu>
  );
};

export {
  ReactSelectClearIndicator,
  ReactSelectDropdownIndicator,
  ReactSelectMenuWithFooter,
};
