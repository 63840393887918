module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="document-owners">\n  <div class="background-white" id="document-detail-sidebar-owners-accordion">\n    <div aria-controls="document-detail-sidebar-owners-accordion-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#document-detail-sidebar-owners-accordion-collapse" data-toggle="collapse" aria-expanded="true">\n      <i class="fa fa-caret-down m-r-5"></i>\n      <span class="font-bold">Schedule</span>\n    </div>\n  </div>\n  <div aria-labelledby="document-detail-sidebar-businesses-accordion" class="p-l-10 p-r-10 collapse in background-white document-detail-sidebar-section-body" id="document-detail-sidebar-owners-accordion-collapse" aria-expanded="true" style="">\n    <div class="table-responsive padding-5">\n      <table class="table m-b-5">\n        <thead>\n        <tr>\n          <th class="font-13 font-semi-bold">Date</th>\n          <th class="font-13 font-semi-bold">Chart of Account</th>\n          <th class="font-13 font-semi-bold text-right">Amount</th>\n        </tr>\n        </thead>\n        <tbody>\n        </tbody>\n      </table>\n    </div>\n  </div>\n  ');
    
      if (!this.isAutoSchedule) {
        __out.push('\n  <div class=\'display-flex-column line-height-25\'>\n    <div class=\'display-flex\'>\n      <div class=\'m-l-auto\'>Total Amount:</div>\n      <div class=\'m-l-30 width-120px\'>$');
        __out.push(__sanitize(this.totalAmount));
        __out.push('</div>\n    </div>\n    <div class=\'display-flex\'>\n      <div class=\'m-l-auto\'>Invoice Amount:</div>\n      <div class=\'m-l-30 width-120px\'>$');
        __out.push(__sanitize(this.invoiceAmount));
        __out.push('</div>\n    </div>\n    <div class=\'display-flex\'>\n      <div class=\'m-l-auto\'>Difference:</div>\n      <div class=\'m-l-30 width-120px ');
        if (this.diffAmount !== '0.00') {
          __out.push('in-red-600');
        }
        __out.push('\'>\n        ');
        if (this.totalAmount < this.invoiceAmount) {
          __out.push('\n          -$');
          __out.push(__sanitize(this.diffAmount));
          __out.push('\n        ');
        } else {
          __out.push('\n          $');
          __out.push(__sanitize(this.diffAmount));
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}