import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import AchTransactions from './ach_transactions';

interface IAchTransactionsPageProps {
  businessId: TID,
  serviceId: TID,
}

const AchTransactionsPage = ({
  businessId,
  serviceId,
}: IAchTransactionsPageProps): JSX.Element => {
  return (
    <Root>
      <AchTransactions
        businessId={ businessId }
        serviceId={ serviceId }
      />
    </Root>
  );
};

export default AchTransactionsPage;
