module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business_name, i, idx, len, ref;
    
      __out.push('<div class="container">\n  <div class="m-t-50 m-b-30 relative m-l-auto m-r-auto">\n    <h1 class="font-24 font-bold text-center">Add Your Businesses</h1>\n  </div>\n  <div class="padding-35 background-white m-l-auto m-r-auto businesses-setup-panel">\n    ');
    
      ref = this.businesses;
      for (idx = i = 0, len = ref.length; i < len; idx = ++i) {
        business_name = ref[idx];
        __out.push('\n      <div class="form-group-wrapper">\n        <input class="form-control business-name-input" type="text" placeholder="Business Location ');
        __out.push(__sanitize(idx + 1));
        __out.push('" data-index="');
        __out.push(__sanitize(idx));
        __out.push('" value="');
        __out.push(__sanitize(business_name));
        __out.push('">\n      </div>\n    ');
      }
    
      __out.push('\n    <div class="form-group-wrapper business-name-input-invalid hidden text-center">\n      <p class="in-red-400"><i class="fa fa-warning"></i> You have to input at least 1 business name.</p>\n    </div>\n    <div class="text-center">\n      <a class="pointer font-14 font-semi-bold add-business-btn">+ Add More Business Locations</a>\n    </div>\n    <div class="text-center m-t-20">\n      <button type=\'button\' class="btn btn-blue ladda-button finish-btn" data-color="blue" data-style="expand-right"><span class="ladda-label">Done</span></button>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}