import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetDocumentRelatedDocuments } from '@src/hooks/queries/documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetDocumentRelatedDocumentsResponse } from '@src/requests/documents';
import { TID, TSection } from '@src/types/common';
import { IRelatedDocument, TRelatedDocumentsSortColumn } from '@src/types/related_documents';
import { ISorting, ISortingParams } from '@src/types/sorting';

interface IUseRelatedDocumentsCollectionParams {
  documentId: TID,
}

interface IRelatedDocumentsCollection {
  query: UseInfiniteQueryResult<IGetDocumentRelatedDocumentsResponse, Error>,
  records: IRelatedDocument[],
  section: TSection,
  sorting: ISorting<TRelatedDocumentsSortColumn>,
}

const defaultSorting: ISortingParams<TRelatedDocumentsSortColumn> = {
  orderColumn:    'document_id',
  orderDirection: 'desc',
};

const useRelatedDocumentsCollection = ({
  documentId,
}: IUseRelatedDocumentsCollectionParams): IRelatedDocumentsCollection => {
  const section = useMemo(() => {
    return {
      documentId,
      section: Section.AccountsReceivablePayments,
    };
  }, [documentId]);

  const sorting = useSorting<TRelatedDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetDocumentRelatedDocuments({ id: documentId });

  const records = useMemo(() => {
    const documentsPages = query.data?.pages || [];
    return flatten(documentsPages.map((p) => p.collection));
  }, [query.data]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IRelatedDocumentsCollection,
  useRelatedDocumentsCollection,
};
