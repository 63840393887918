import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import BulkActionProgress from '@src/components/common/bulk_action_modal/bulk_action_progress';
import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';

interface IProgressModallProps extends IUseModalProps {
  doneCount: number;
  totalCount: number;
}

const ProgressModal = ({
  isOpen,
  doneCount,
  totalCount,
}: IProgressModallProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title="Update Transactions in Progress"
    >
      <Modal.Body>
        <Text as="div">
          <BulkActionProgress
            doneCount={ doneCount }
            itemsTitle="Transactions"
            totalCount={ totalCount }
          />
        </Text>
      </Modal.Body>
    </Modal>
  );
};

const useProgressModal = makeUseModal(ProgressModal);

export default useProgressModal;
