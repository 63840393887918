import React from 'react';

import metricsServiceImg from '@assets/images/metrics_service/metrics.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const MetricsIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Vendor icon"
      src={ metricsServiceImg }
      { ...props }
    />
  );
};

export default MetricsIcon;
