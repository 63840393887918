module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="text-center update-profile-container m-t-14">\n  <div class="profile-content-wrapper">\n    ');
    
      if (!this.isEditMode && this.dwollaCustomer.id) {
        __out.push('\n      <div class="profile-view-wrap">\n        <label class="">Business Owner Legal Name</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.name()));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Email</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.get('email')));
        __out.push('</span>\n      </div>\n      <div class="profile-view-wrap">\n        <label class="">Phone Number</label>\n        <span class="in-grey-950">');
        __out.push(__sanitize(this.dwollaCustomer.get('phone')));
        __out.push('</span>\n      </div>\n      <div class="display-flex align-items-center in-blue-950 font-semi-bold pointer edit-link-js ');
        __out.push(__sanitize(this.dwollaCustomer.isEditable() ? '' : 'hidden'));
        __out.push('">\n        <span class="font-20 m-t-6"><i class="icon icon-edit_account"></i></span>\n        <span class="font-16 m-l-12">Edit information</span>\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="text-left">This information will be used for verification to activate ACH payments to your bank account. Wrong information may permanently disable ACH access.</div>\n      <div class="profile-input-wrap approvers-typeahead-wrapper">\n        <label class="text-left">Docyt User</label>\n        <input class="form-control user-input-js" type="text" placeholder="Select Docyt user" value="">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-t-20 m-b-20">\n        <label class="width-40-percent text-left">Business Owner Legal Name</label>\n        <div class="business-address-wrapper">\n          <div class="profile-input-wrap p-r-5">\n            <input class="input-first-name form-control" type="text" placeholder="First Name" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('first_name')));
        __out.push('">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <input class="input-last-name form-control" type="text" placeholder="Last Name" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('last_name')));
        __out.push('">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        </div>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Email</label>\n        <input class="input-email form-control" type="text" placeholder="example@company.com" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('email')));
        __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Phone Number</label>\n        <input type="tel" name="phone" class="input-phone form-control" value="');
        __out.push(__sanitize(this.dwollaCustomer.get('phone')));
        __out.push('" placeholder="Phone Number">\n        <p class="phone-valid-hint-js"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap m-b-20">\n        <label class="width-40-percent text-left">Date of Birth</label>\n        <input class="input-date-birth dropdown-toggle form-control" type="text" placeholder="MM-DD-YYYY" autocomplete="off" value="');
        __out.push(__sanitize(this.dateBirth));
        __out.push('" ');
        if (!this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>\n        <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n        <p class="calendar-js"><i class="icon icon-calendar-wall"></i></p>\n      </div>\n      ');
        if (this.dwollaCustomer.isCreatingOrRetrying()) {
          __out.push('\n        ');
          if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
            __out.push('\n          <div class="profile-input-wrap m-b-20">\n            <label class="width-40-percent text-left">Full nine digits of SSN</label>\n            <input class="input-last-four form-control" type="text" placeholder="XXX-XX-XXXX">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        ');
          } else {
            __out.push('\n          <div class="profile-input-wrap m-b-10">\n            <label class="width-40-percent text-left">Last four digits of SSN</label>\n            <input class="input-last-four form-control" type="text" placeholder="XXXX">\n            <p class="valid-hint-js"><i class="fa fa-warning"></i></p>\n          </div>\n        ');
          }
          __out.push('\n        <div class="profile-input-wrap m-b-20">\n          <label class="width-40-percent text-left"></label>\n          <div class="note-area">Note: Docyt does not save Social Security Numbers in the database and is only passing it over secure network to the service responsible for ACH transfers</div>\n        </div>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}