import React, { FC, useState } from 'react';

import { InvalidateQueryFilters, useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useMatchBankAccountReconciliationDocuments,
  useUnmatchBankAccountReconciliationDocuments,
} from '@src/hooks/queries/bank_account_reconciliations';
import { IBankAccountReconciliationDocument } from '@src/types/bank_account_reconciliation_document';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { Button } from '@src/components/ui_v2/buttons';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { HandRightAlt } from '@src/components/utils/icomoon';

import Alert from './alert';
import ExcludedAndNotPushed from './excluded_and_not_pushed';
import { ICollection, useHandleCheckboxClick } from './hooks';
import LearnMoreModal from './learn_more_modal';
import PushedOutside from './pushed_outside';
import { STEP_ACKNOWLEDGE } from './wizard_step';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface StepReviewProps {
  pushedOutsideCollection: ICollection,
  excludedAndNotPushedCollection: ICollection,
  closeDrawer?: () => void,
  setStep: (step: number) => void,
  showUnmatchedOnly: boolean,
  setShowUnmatchedOnly: (showUnmatchedOnly: boolean) => void,
}

const StepReview: FC<StepReviewProps> = ({
  pushedOutsideCollection,
  excludedAndNotPushedCollection,
  closeDrawer,
  setStep,
  showUnmatchedOnly,
  setShowUnmatchedOnly,
}) => {
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [deselectedItemGroups, setDeselectedItemGroups] = useState<number[][]>([]);
  const { mutateAsync, isLoading } = useMatchBankAccountReconciliationDocuments();
  const { mutateAsync: unmatchMutateAsync,
    isLoading: unmatchIsLoading } = useUnmatchBankAccountReconciliationDocuments();
  const queryClient = useQueryClient();
  const business = useBusinessContext();
  const handleLeftTableCheckboxClick =
    useHandleCheckboxClick(
      pushedOutsideCollection,
      excludedAndNotPushedCollection,
      setDeselectedItemGroups,
    );

  const handleRightTableCheckboxClick =
    useHandleCheckboxClick(
      excludedAndNotPushedCollection,
      pushedOutsideCollection,
      setDeselectedItemGroups,
    );

  const reducer = (acc: number, item: IBankAccountReconciliationDocument) => acc + parseFloat(item.amount || '0');
  const selectedPushedOutsideAmount = pushedOutsideCollection.selectedRecords.reduce(reducer, 0);

  // eslint-disable-next-line max-len
  const selectedExcludedAndNotPushedAmount = excludedAndNotPushedCollection.selectedRecords.reduce(reducer, 0);

  // eslint-disable-next-line max-len
  const difference = parseFloat((selectedPushedOutsideAmount - selectedExcludedAndNotPushedAmount).toFixed(2));
  const isMatchable = difference === 0
    && selectedPushedOutsideAmount !== 0
    && selectedExcludedAndNotPushedAmount !== 0;

  const match = async () => {
    const sourceDocumentIds = pushedOutsideCollection.selectedRecords
      .filter((i) => i.matchedDocumentIds.length === 0).map((i) => i.id);
    const targetDocumentIds = excludedAndNotPushedCollection.selectedRecords
      .filter((i) => i.matchedDocumentIds.length === 0).map((i) => i.id);
    try {
      await mutateAsync({ sourceDocumentIds, targetDocumentIds, businessId: business.id });
      await Promise.all([
        queryClient.invalidateQueries(pushedOutsideCollection.section as InvalidateQueryFilters),
        queryClient.invalidateQueries(
          excludedAndNotPushedCollection.section as InvalidateQueryFilters,
        ),
      ]);
      toastr.success('Items matched successfully!', 'Success');
    } catch (error) {
      toastr.error((error as Error).message || 'Failed to match items.', 'Error');
    }
  };

  const showUnmatchButton = deselectedItemGroups.length > 0;
  const rowSelectable = !showUnmatchButton;
  const showDifference = pushedOutsideCollection.selectedRecords.length > 0
    && excludedAndNotPushedCollection.selectedRecords.length > 0;

  const unmatch = async () => {
    try {
      await unmatchMutateAsync(
        { sourceDocumentIds: deselectedItemGroups[0],
          targetDocumentIds: deselectedItemGroups[1],
          businessId:        business.id },

      );
      await Promise.all([
        queryClient.invalidateQueries(pushedOutsideCollection.section as InvalidateQueryFilters),
        queryClient.invalidateQueries(
          excludedAndNotPushedCollection.section as InvalidateQueryFilters,
        ),
      ]);
      toastr.success('Items unmatched successfully!', 'Success');
      setDeselectedItemGroups([]);
    } catch (error) {
      toastr.error((error as Error).message || 'Failed to unmatch items.', 'Error');
    }
  };

  const cancel = (e: any) => {
    e.preventDefault();
    closeDrawer?.();
  };

  return (
    <>
      <div className={ styles.review }>
        <div className={ styles['review-content'] }>
          <div className={ styles['review-toolbar'] }>
            <div className={ styles['review-toolbar-tip'] }>
              <HandRightAlt fontSize={ 16 } />
              { ' ' }
              Validate the uncleared balance by matching transactions in
              { ' ' }
              the left table with the right table.
              { ' ' }
              <Button className={ styles['ml-6'] } variant="link" onClick={ () => setShowLearnMore(true) }>
                Learn more
              </Button>
            </div>
            <div className={ styles['review-toolbar-tools'] }>
              <CheckboxInput
                checked={ showUnmatchedOnly }
                title="Show unmatched transactions only"
                onChange={ setShowUnmatchedOnly }
              />
            </div>
          </div>
          <div className={ styles['alert-container'] }>
            { difference !== 0
              && <Alert>Transactions with non-zero difference can not be matched.</Alert> }
          </div>
          <div className={ styles['review-tables'] }>
            <div className={ styles['review-table'] }>
              <PushedOutside
                showSelect
                collection={ pushedOutsideCollection }
                handleCheckboxClick={ handleLeftTableCheckboxClick }
                rowSelectable={ rowSelectable }
                showUnmatchedOnly={ showUnmatchedOnly }
              />
            </div>
            <div className={ styles['review-table'] }>
              <ExcludedAndNotPushed
                showSelect
                collection={ excludedAndNotPushedCollection }
                handleCheckboxClick={ handleRightTableCheckboxClick }
                rowSelectable={ rowSelectable }
                showUnmatchedOnly={ showUnmatchedOnly }
              />
            </div>
          </div>
        </div>
        <div className={ styles['review-footer'] }>
          <div>
            <Button variant="link" onClick={ cancel }>
              Cancel
            </Button>
          </div>
          <div className={ styles['review-summary'] }>
            <span>
              Selected on left:
              { ' ' }
              { currencyLocaleFormatter(selectedPushedOutsideAmount) }
            </span>
            <span>
              Selected on right:
              { ' ' }
              { currencyLocaleFormatter(selectedExcludedAndNotPushedAmount) }
            </span>
            {
              showDifference && (
                <span className={ difference !== 0 ? styles.warning : styles.success }>
                  Difference:
                  { ' ' }
                  { currencyLocaleFormatter(difference) }
                </span>
              )
            }
          </div>
          <div>
            <Button className={ styles['mr-12'] } variant="link" onClick={ () => setStep(STEP_ACKNOWLEDGE) }>
              Next
            </Button>

            {showUnmatchButton ? (
              <Button variant="primary" onClick={ unmatch }>
                { unmatchIsLoading ? <SpinnerIcon spin /> : 'Unmatch'}
              </Button>
            ) : (
              <Button disabled={ !isMatchable || isLoading } variant="primary" onClick={ match }>
                { isLoading ? <SpinnerIcon spin /> : 'Match' }
              </Button>
            )}
          </div>
        </div>
      </div>
      <LearnMoreModal close={ () => setShowLearnMore(false) } show={ showLearnMore } />
    </>
  );
};

export default StepReview;
