import React, { useState } from 'react';

import { basicReportsPath, businessSettingsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import ServiceItem from '../module_header_item';

interface IRegularReportServiceItemsProps {
  businessId: TID,
  businessInfo: IBusinessServicesInfo | undefined,
}

const RegularReportServiceItems = ({
  businessId,
  businessInfo: info,
}: IRegularReportServiceItemsProps) => {

  const [featureFlagforCashBasis, setFeatureFlagforCashBasis] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.BUSINESS_REPORTS_CASH_BASIS_ENABLED,
      String(businessId),
    ),
  );

  if (featureFlagforCashBasis === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.BUSINESS_REPORTS_CASH_BASIS_ENABLED,
      String(businessId),
    ).then((res) => {
      setFeatureFlagforCashBasis(res);
    });
  }

  if (!info?.isReportAdminUser) {
    return null;
  }

  return (
    <ServiceItem
      serviceUrl={
        info?.reportServiceReady
          ? basicReportsPath(businessId)
          : businessSettingsPath(businessId)
        }
      title={
        featureFlagforCashBasis
          ? 'Cash Basis'
          : 'Basic'
      }
    />
  );
};

export default RegularReportServiceItems;
