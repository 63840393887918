module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="payee-bank-container m-t-30">\n  ');
    
      if (this.dwollaFundingSource.id) {
        __out.push('\n    <div class="font-16">\n      <div class="">\n        <span class="font-bold font-20 width-200px display-inline-flex">Bank Account Info </span>\n      </div>\n      <div class="m-t-20">\n        <span class="font-bold width-200px display-inline-flex">Name: </span>\n        <span class="width-200px display-inline-flex">');
        __out.push(__sanitize(this.dwollaFundingSource.get('name') ? this.dwollaFundingSource.get('name') : 'Information not available'));
        __out.push('</span>\n      </div>\n      <div class="m-t-20">\n        <span class="font-bold width-200px display-inline-flex">Account #: </span>\n        <span class="width-200px display-inline-flex">**** **** **** ');
        __out.push(__sanitize(this.dwollaFundingSource.get('last4')));
        __out.push('</span>\n      </div>\n      <div class="m-t-20">\n        <span class="font-bold width-200px display-inline-flex">Routing #: </span>\n        <span class="width-200px display-inline-flex">');
        __out.push(__sanitize(this.dwollaFundingSource.get('routing_number')));
        __out.push('</span>\n      </div>\n    </div>\n  ');
      } else {
        __out.push('\n    <div class="m-t-50 empty-content">\n      <i class="icon icon-bank-transactions-src bank-empty-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></i>\n      <h4 class="font-14 font-bold m-t-20 m-b-20 in-grey-1050">\n        No bank account information available\n      </h4>\n      <button type="button" class="btn btn-blue btn-add-bank">Add Bank Account Info</button>\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}