import React, { useCallback, useState, useEffect } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';
import { IReportServiceBudgetItem, TItemChangeCallback } from '@src/types/report_service/report_service_budget_items';

import Table from '@src/components/ui/table';

interface IMetricsInputCellProps extends Omit<NumberFormatProps, 'value' | 'onChange'> {
  currentFormula: IReportServiceBudgetFormula,
  formulaEnabled: string | boolean | undefined,
  item: IReportServiceBudgetItem,
  month: number,
  onChange: TItemChangeCallback,
  onSetDraft: (value: boolean) => void,
}

const MetricsInputCell = ({
  currentFormula,
  formulaEnabled,
  item,
  month,
  onChange,
  onSetDraft,
}: IMetricsInputCellProps): JSX.Element => {
  const [value, setValue] = useState<number>(item.budgetItemValues[month].value || 0);

  useEffect(() => {
    setValue((prev) => {
      if (item.budgetItemValues[month].value !== prev) return item.budgetItemValues[month].value;
      return prev;
    });
  }, [item, month]);

  const handleBlur = useCallback(() => {
    if (item.budgetItemValues[month].value !== value) onChange(item, month, value);
  }, [item, month, onChange, value]);

  const handleChange = useCallback((values) => {
    onSetDraft(true);
    setValue(values.floatValue ?? 0);
  }, [onSetDraft]);

  if (currentFormula.monthlyInputAutoCalculation && formulaEnabled) {
    return (
      <Table.CurrencyAmountCell
        value={ item.budgetItemValues[month].value }
        hidePrefix
      />
    );
  }

  const handleKeyPressNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
      handleBlur();
      e.preventDefault();
    }
  }

  return (
    <Table.Cell>
      <NumberFormat
        key={ `${item.id}-${month}` }
        allowEmptyFormatting
        fixedDecimalScale
        isNumericString
        thousandSeparator
        className="budget-item-input"
        decimalScale={ 0 }
        inputMode="numeric"
        value={ value }
        onBlur={ handleBlur }
        onKeyPress={ handleKeyPressNumber }
        onValueChange={ handleChange }
      />
    </Table.Cell>
  );
};

export default MetricsInputCell;
