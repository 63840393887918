import React, { useMemo } from 'react';

import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import sortedUniq from 'lodash/sortedUniq';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetChartOfAccountsByIds } from '@src/hooks/queries/chart_of_accounts';
import { useGetReportServiceBudgetItems } from '@src/hooks/queries/report_service/report_service_budget_items';
import { useFilter } from '@src/hooks/url_params';
import { IAccountingClass } from '@src/types/accounting_classes';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';
import { MONTHS } from '@src/utils/date_helpers';

import Filter, { IBudgetItemsFilter } from '../filter';
import Table from './table';

interface IBudgetDetailsListProps {
  isDraft: boolean,
  onSetDraft: (value: boolean) => void,
  onCreateExportData: (result: boolean) => void,
  onSuccessUpload: (token: string) => void,
}

const getBudgetItemsParamsFromFilter = (filterData: IBudgetItemsFilter | undefined) => {
  const except = [];
  const temp = { ...filterData };

  if (!temp.accountType) {
    temp.accountType = 'profit_loss';
  } else {
    if (temp.accountingClassId?.length === 0) except.push('accountingClassId');
    if (temp.accountType?.length === 0) temp.accountType = 'profit_loss';
  }

  const filterParams = omit(temp || {}, except);
  return { filter: filterParams };
};

const dataInitialize = (
  budgetItems: IReportServiceBudgetItem[],
  chartOfAccounts: IChartOfAccount[],
  accountingClasses: IAccountingClass[],
) => (
  budgetItems.map((budgetItem) => {
    if (budgetItem.budgetItemValues?.length === 0) {
      MONTHS.forEach((month, index) => {
        budgetItem.budgetItemValues.push({ id: index, month: index + 1, value: 0 });
      });
    }
    chartOfAccounts.forEach((chartOfAccount) => {
      if (chartOfAccount.id === budgetItem.chartOfAccountId) {
        budgetItem.displayName = chartOfAccount.displayName;
        budgetItem.accTypeName = chartOfAccount.accTypeName;
      }
    });
    accountingClasses.forEach((accountingClass) => {
      if (accountingClass.id === budgetItem.accountingClassId) {
        budgetItem.accountingClassName = accountingClass.name;
      }
    });
    return budgetItem;
  })
);

const BudgetDetailsList = ({
  isDraft,
  onSetDraft,
  onCreateExportData,
  onSuccessUpload,
}: IBudgetDetailsListProps): JSX.Element => {
  const business = useBusinessContext();
  const budget = useReportServiceBudgetContext();

  const filter = useFilter<IBudgetItemsFilter>({});

  const filterParams = useMemo(() => {
    return getBudgetItemsParamsFromFilter(filter.data);
  }, [filter.data]);

  const budgetItemsQuery = useGetReportServiceBudgetItems({ budgetId: budget.id, ...filterParams });
  const { budgetItemsData, monthTotalAmounts, chartOfAccountIds } = useMemo(() => {
    const budgetItemsPages = budgetItemsQuery.data?.pages || [];
    return {
      budgetItemsData:   flatten(budgetItemsPages.map((p) => p.collection)),
      monthTotalAmounts: budgetItemsPages[0]?.meta.monthTotalAmounts || Array(12).fill(0),
      chartOfAccountIds: compact(
        sortedUniq(
          flatten(
            budgetItemsPages.map((p) => p.collection),
          ).map((budgetItem) => budgetItem.chartOfAccountId),
        ),
      ),
    };
  }, [budgetItemsQuery.data?.pages]);

  const chartOfAccountsQuery = useGetChartOfAccountsByIds({ chartOfAccountIds });
  const chartOfAccounts = useMemo(() => {
    return chartOfAccountsQuery.data || [];
  }, [chartOfAccountsQuery.data]);

  return (
    <>
      <Filter filter={ filter } />
      <span className={ `draft-budget-badge ${!isDraft && 'invisible'}` }>UNSAVED DRAFT</span>
      <Table
        budgetItemsData={
          dataInitialize(budgetItemsData, chartOfAccounts, business.accountingClasses)
        }
        chartOfAccounts={ chartOfAccounts }
        filterParams={ filterParams.filter }
        isDraft={ isDraft }
        monthTotalAmounts={
          budgetItemsQuery.isRefetching ? [...monthTotalAmounts] : monthTotalAmounts
        }
        query={ budgetItemsQuery }
        onCreateExportData={ onCreateExportData }
        onSetDraft={ onSetDraft }
        onSuccessUpload={ onSuccessUpload }
      />
    </>
  );
};

export default BudgetDetailsList;
