import React from 'react';

import Form from './form';
import { ISignUpFormValues } from './schema';

import styles from './styles.module.scss';

interface ISignUpPageProps {
  email: string,
  onSignUp: (data: ISignUpFormValues) => void;
}

const SignUpPage = ({
  email,
  onSignUp,
}: ISignUpPageProps) => {
  return (
    <div className={ styles['self-onboarding-sign-up-container'] }>
      <div className={ styles['sign-up-title'] }>
        <h1 className="font-24 font-bold text-center">Sign in</h1>
      </div>
      <div className="background-white sign-in-main-panel m-l-auto m-r-auto">
        <Form
          email={ email }
          formId="sign-up-form"
          onSubmit={ onSignUp }
        />
      </div>
    </div>
  );
};

export default SignUpPage;
