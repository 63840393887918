import React from 'react';

import { TDate } from '@src/types/common';
import { IMetric } from '@src/types/metrics';

import { DatePickerInput } from '@src/components/ui/form';

interface IChangeStartDateFormProps {
  metric: IMetric,
  onChangedStartDate: (value: TDate | undefined) => void,
}

const ChangeStartDateForm = ({
  metric,
  onChangedStartDate,
}: IChangeStartDateFormProps): JSX.Element => {
  return (
    <div className="change_start_date_form">
      <span>
        Change the start date for the metric
        { ' ' }
        <b>Website conversion rate</b>
      </span>
      <div className="display-flex align-items-center p-t-25 p-b-10">
        <span className="title width-40-percent m-r-10">Start Date</span>
        <DatePickerInput
          value={ metric.startDate }
          onChange={ onChangedStartDate }
        />
      </div>
    </div>
  );
};

export {
  IChangeStartDateFormProps,
  ChangeStartDateForm as default,
};
