module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n  <div class="service-detail-view team-profile-area m-l-auto m-r-auto">\n    <div class="m-b-25">\n      <label class="font-16 font-bold in-black">Profile Info</label>\n      <a class="font-14 font-bold pointer pull-right edit-profile-js ');
    
      __out.push(__sanitize(this.is_external ? '' : 'hidden'));
    
      __out.push('">Edit Profile</a>\n    </div>\n    <div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Email</label>\n        <span class="col-md-8 font-14 color-quota-pages">');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</span>\n      </div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Phone Number</label>\n        <span class="col-md-8 font-14 color-quota-pages">');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n      </div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Address</label>\n        <span class="col-md-8 font-14 color-quota-pages"> </span>\n      </div>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}