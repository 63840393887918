import React from 'react';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import styles from './styles.module.scss';

interface IW9InfoViewProps {
  fileUrl: string | Uint8Array,
  modalMode?: string,
}

const W9InfoView = ({
  fileUrl,
  modalMode,
}: IW9InfoViewProps): JSX.Element => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className={ modalMode === 'view' ? styles['full-view-container'] : styles['view-file-container'] }>
      <Viewer
        withCredentials
        fileUrl={ fileUrl }
        plugins={ [defaultLayoutPluginInstance] }
        theme={ {
          theme: 'dark',
        } }
      />
    </div>
  );
};

export default W9InfoView;
