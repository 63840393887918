module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var country, i, j, k, len, len1, len2, method, ref, ref1, ref2, state;
    
      __out.push('<div class="main-view-area">\n    <div class="p-l-20 p-r-20 m-t-20 p-b-10 item-divider">\n      <a class=\'cancel m-t-10 pull-right pointer\' ');
    
      if (this.editLinkLedger) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('><span>Close</span></a>\n      <a class="cancel pointer pull-right m-t-5 m-l-10 close-icon-btn" ');
    
      if (!this.editLinkLedger) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n      <h4 class=\'font-26 font-bold\'>');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n    </div>\n  ');
    
      if (this.step === 1) {
        __out.push('\n    <div class="p-l-20 p-r-20 p-t-10 p-b-10 item-divider">\n      <div class="main-input__wrap">\n        <div class="profile-input-wrap">\n          <span class="main-search-icon icon-search"></span>\n          <div class="vendors-region width-100-percent p-l-40"></div>\n          <p class="valid-hint-js  in-red-400 sign-up-valid-hint" style="display: none;"><i class="fa fa-warning"></i></p>\n        </div>\n      </div>\n    </div>\n    <div class="display-flex-column text-center font-14 m-l-20 m-r-20 m-t-10">\n      <span> Search a vendor from Docyt Business Network </span>\n      <span class="m-t-10"> OR </span>\n      <button type="button" class="btn btn-blue-on-hover font-16 font-semi-bold create-new-vendor-js m-t-10">Create New Vendor</button>\n      <a class=\'import-link-js pointer font-16 font-semi-bold m-t-10\'>or Import From Ledger</a>\n    </div>\n  ');
      } else if (this.step === 2) {
        __out.push('\n    <div class=\'content-area\'>\n      <!-- Vendor Avatar Upload Area Begin -->\n      <div class="add-photo-wrapper m-b-30 m-t-30 text-center ');
        __out.push(__sanitize(this.image_url ? 'avatar-exist' : ''));
        __out.push('" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <img class="personal-logo" src="');
        __out.push(__sanitize(this.image_url));
        __out.push('">\n        <button class="font-bold fileinput-button">\n          Add Payee Logo\n          <input type="file" name="files[]" class="logo-file-input">\n        </button>\n        <p class="main-input__hint in-red-400 logo-invalid">\n          File format is not supported.\n        </p>\n      </div>\n      <!-- Vendor Avatar Upload Area End -->\n      <div class="p-l-20 p-r-20">\n        <div class="inline-flex-wrapper width-100-percent">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Display Name</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.displayName));
        __out.push(' </span>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Docyt ID</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.docyt_network_id || '-'));
        __out.push(' </span>\n        </div>\n      </div>\n      <!-- Vendor Payment Information Begin -->\n      <div aria-controls="vendors-sidebar-info-accordion-collapse-p" class="p-r-24 p-l-24 m-t-10 vendors-sidebar-section-header" data-target="#vendors-sidebar-info-accordion-collapse-p" data-toggle="collapse" aria-expanded="true" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <i class="fa fa-caret-down m-r-5"></i>\n        <span class="font-bold">PAYEE PAYMENT INFORMATION</span>\n      </div>\n      <div aria-labelledby="vendors-sidebar-info-accordion" class="p-r-20 p-l-20 collapse in" id="vendors-sidebar-info-accordion-collapse-p" aria-expanded="true" style="">\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Business Name</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.business_name || '-'));
        __out.push(' </span>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Bank Account</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(((ref = this.dwolla_funding_source) != null ? ref.last4 : void 0) ? "*********" + ((ref1 = this.dwolla_funding_source) != null ? ref1.last4 : void 0) : '-'));
        __out.push(' </span>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Business Address</label>\n          <div class="in-black p-t-6">\n            <p class="font-15">');
        __out.push(__sanitize(this.address_info || '-'));
        __out.push('</p>\n          </div>\n        </div>\n      </div>\n      <!-- Vendor Payment Information End -->\n      <div aria-controls="vendors-sidebar-info-accordion-collapse" class="p-r-24 p-l-24 m-t-10 vendors-sidebar-section-header" data-target="#vendors-sidebar-info-accordion-collapse" data-toggle="collapse" aria-expanded="true" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <i class="fa fa-caret-down m-r-5"></i>\n        <span class="font-bold">PAYEE CONTACT INFORMATION</span>\n      </div>\n      <div aria-labelledby="vendors-sidebar-info-accordion" class="p-r-20 p-l-20 collapse in" id="vendors-sidebar-info-accordion-collapse" aria-expanded="true" style="">\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Website</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.website || '-'));
        __out.push(' </span>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Email</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.email || '-'));
        __out.push(' </span>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper p-r-15 width-140px p-t-6" for="">Phone</label>\n          <span class="p-t-6"> ');
        __out.push(__sanitize(this.phone || '-'));
        __out.push(' </span>\n        </div>\n      </div>\n      <div class="p-r-20 p-l-20">\n        <div class="checkbox m-t-20 m-b-0" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n          <input type="checkbox" class="pointer verify-information-js" id="verify-information" data-type="checkbox">\n          <label for="verify-information">I have verified the information of this vendor and I am selecting to add this Vendor to the "My Vendors" list.</label>\n        </div>\n      </div>\n    </div>\n    <div class=\'border-top-gray p-r-10 p-l-10 p-t-10 m-t-30 text-center\'>\n      <button type=\'button\' class="btn btn-info ladda-button add-global-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Add Vendor</span></button>\n    </div>\n  ');
      } else {
        __out.push('\n    <div class=\'content-area\'>\n      <!-- Vendor Avatar Upload Area Begin -->\n      <div class="add-photo-wrapper m-b-30 m-t-30 text-center ');
        __out.push(__sanitize(this.image_url ? 'avatar-exist' : ''));
        __out.push('" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <img class="personal-logo" src="');
        __out.push(__sanitize(this.image_url));
        __out.push('">\n        <button class="font-bold fileinput-button">\n          Add Payee Logo\n          <input type="file" name="files[]" class="logo-file-input">\n        </button>\n        <p class="main-input__hint in-red-400 logo-invalid">\n          File format is not supported.\n        </p>\n      </div>\n      <!-- Vendor Avatar Upload Area End -->\n      <div class="p-l-10 p-r-10">\n        <div ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n          <div class="inline-flex-wrapper width-100-percent">\n            <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Display Name</label>\n            <input type="text" class="inline-wrapper form-control vendor-name-js" placeholder="Small, Simpler Name" value = "');
        __out.push(__sanitize(this.name));
        __out.push('">\n          </div>\n        </div>\n        <p class="main-input__hint in-red-400 p-l-140" id=\'invalid_name\'><i class="fa fa-warning"></i> Name is required.</p>\n        <hr class="vendor_sidebar_line" ');
        if (!this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('></hr>\n        <div class="profile-input-wrap approvers-typeahead-wrapper m-t-10" ');
        if (!this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6">Vendor in Ledger</label>\n          <div class="qbo-vendors-region inline-flex-wrapper width-100-percent">\n          </div>\n        </div>\n        <p class="main-input__hint in-red-400 p-l-140" id=\'invalid_vendor\' ');
        if (!this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('><i class="fa fa-warning"></i> Vendor is required.</p>\n      </div>\n      <!-- Vendor Payment Area Begin -->\n      ');
        if (!this.editLinkLedger) {
          __out.push('\n      <div class="bg-grey-150 p-r-10 p-l-10 m-t-10"><p class="font-bold">Payee Payment Information</p></div>\n      <div class="p-r-10 p-l-10">\n        <div class="inline-flex-wrapper width-100-percent">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px" for="">Preferred Payment Method</label>\n          <select class="acount-type__input form-control selectpicker vendor-preferred-payment-js" title="Select Payment Method" >\n            ');
          ref2 = Docyt.Common.Constants.PREFERRED_PAYMENT_METHODS;
          for (i = 0, len = ref2.length; i < len; i++) {
            method = ref2[i];
            __out.push('\n            <option value="');
            __out.push(__sanitize(method));
            __out.push('" ');
            __out.push(__sanitize(this.preferred_payment_method === method ? 'selected' : ''));
            __out.push('>');
            __out.push(__sanitize(method));
            __out.push('</option>\n            ');
          }
          __out.push('\n          </select>\n        </div>\n      </div>\n      ');
        }
        __out.push('\n      <!-- Vendor Payment Address Area Begin -->\n      ');
        if (!this.onlyVendorInfo) {
          __out.push('\n        ');
          if (this.editingAddress) {
            __out.push('\n          <div class="bg-grey-150 p-r-10 p-l-10 m-t-10" ');
            if (this.editLinkLedger) {
              __out.push(__sanitize('hidden'));
            }
            __out.push('><p class="font-bold">Payee Payment Address Information</p></div>\n          <div class="p-r-10 p-l-10" ');
            if (this.editLinkLedger) {
              __out.push(__sanitize('hidden'));
            }
            __out.push('>\n            <div class="inline-flex-wrapper width-100-percent">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Check Payable To</label>\n              <input type="text" class="acount-type__input form-control vendor-check-payable-js" placeholder="Name on the payment checks" value = "');
            __out.push(__sanitize(this.address.name));
            __out.push('">\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Department</label>\n              <input type="text" class="acount-type__input form-control vendor-department-js" placeholder="Optional" value = "');
            __out.push(__sanitize(this.address.company));
            __out.push('">\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Address</label>\n              <input type="text" class="acount-type__input form-control vendor-address1-js" placeholder="Street" value = "');
            __out.push(__sanitize(this.address.address_line1));
            __out.push('">\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for=""></label>\n              <input type="text" class="acount-type__input form-control vendor-address2-js" placeholder="Street (line 2) or Suite Number" value = "');
            __out.push(__sanitize(this.address.address_line2));
            __out.push('">\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for=""></label>\n              <div class="city-state-zip m-r-10">\n                <input type="text" class="acount-type__input form-control vendor-city-js" placeholder="City" value = "');
            __out.push(__sanitize(this.address.address_city));
            __out.push('">\n              </div>\n              <div class="city-state-zip">\n                ');
            if (this.address.address_country === 'US') {
              __out.push('\n                <select class="acount-type__input form-control vendor-state-js" placeholder="State" >\n                  ');
              for (j = 0, len1 = usaStateList.length; j < len1; j++) {
                state = usaStateList[j];
                __out.push('\n                  <option value="');
                __out.push(__sanitize(state.abbreviation));
                __out.push('" ');
                if (this.address.address_state === state.abbreviation) {
                  __out.push(__sanitize('selected'));
                }
                __out.push('>');
                __out.push(__sanitize(state.text));
                __out.push('</option>\n                  ');
              }
              __out.push('\n                </select>\n                ');
            } else {
              __out.push('\n                <input type="text" class="acount-type__input form-control vendor-state-js" placeholder="State" value = "');
              __out.push(__sanitize(this.address.address_state));
              __out.push('">\n                ');
            }
            __out.push('\n              </div>\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for=""></label>\n              <input type="text" class="acount-type__input form-control vendor-zip-js" placeholder="ZIP" value = "');
            __out.push(__sanitize(this.address.address_zip));
            __out.push('">\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for=""></label>\n              <select class="acount-type__input form-control vendor-country-js" placeholder="Country" >\n                ');
            for (k = 0, len2 = countryList.length; k < len2; k++) {
              country = countryList[k];
              __out.push('\n                <option value="');
              __out.push(__sanitize(country.code));
              __out.push('" ');
              if (this.address.address_country === country.code) {
                __out.push(__sanitize('selected'));
              }
              __out.push('>');
              __out.push(__sanitize(country.text));
              __out.push('</option>\n                ');
            }
            __out.push('\n              </select>\n            </div>\n            <div class="inline-flex-wrapper width-100-percent m-t-10" relative">\n              <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for=""></label>\n              <div class="checkbox checkbox-inline in-green-600" style="width: 100%">\n                <input type="checkbox" class="styled authorized-address-js" id="checkbox-authorized-address">\n                <label for="checkbox-authorized-address">Add as Authorized Address</label>\n              </div>\n            </div>\n          </div>\n        ');
          } else {
            __out.push('\n          <div class="inline-flex-wrapper width-100-percent m-t-10 p-l-10" ');
            if (this.editLinkLedger) {
              __out.push(__sanitize('hidden'));
            }
            __out.push('>\n            <label class="inline-wrapper p-r-15 text-right width-140px">Mailing Address</label>\n            <label class="font-bold in-blue-400 edit-address-js pointer">Edit Mailing Address</label>\n          </div>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n      <!-- Vendor Payment Address Area End -->\n      <div class="bg-grey-150 p-r-10 p-l-10 m-t-10"><p class="font-bold" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>Payee Contact Information</p></div>\n      <div class="p-r-10 p-l-10" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <div class="inline-flex-wrapper width-100-percent">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Website</label>\n          <input type="text" class="acount-type__input form-control vendor-website-js" placeholder="www.website.com"  value = "');
        __out.push(__sanitize(this.website));
        __out.push('">\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Email</label>\n          <input type="text" class="acount-type__input form-control vendor-email-js" placeholder="vendor@email.com"  value = "');
        __out.push(__sanitize(this.email));
        __out.push('">\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Phone</label>\n          <input type="text" class="acount-type__input form-control vendor-phone-js" placeholder="Phone Number"  value = "');
        __out.push(__sanitize(this.phone));
        __out.push('">\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px p-t-6" for="">Fax</label>\n          <input type="text" class="acount-type__input form-control vendor-fax-js" placeholder="Fax Number"  value = "');
        __out.push(__sanitize(this.fax));
        __out.push('">\n        </div>\n      </div>\n      <!-- Vendor Tax Information Area Start -->\n      <div class="bg-grey-150 p-r-10 p-l-10 m-t-10"><p class="font-bold" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>Tax Information</p></div>\n      <div class="p-r-10 p-l-10" ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px" for=""></label>\n          <div class="checkbox m-t-0 m-b-0">\n            <input type="checkbox" class="pointer input-element eligibility-1099-js" id="track-vendor-1099" data-type="checkbox" ');
        __out.push(__sanitize(this.eligibility_1099 ? 'checked' : ''));
        __out.push('>\n            <label for="track-vendor-1099">Track Vendor for 1099</label>\n          </div>\n        </div>\n        <div class="inline-flex-wrapper width-100-percent m-t-10">\n          <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px" for=""></label>\n          <div class="checkbox m-t-0 m-b-0">\n            <input type="checkbox" class="pointer input-element use-taxes-js" id="track-vendor-use-taxes" data-type="checkbox" ');
        __out.push(__sanitize(this.use_taxes ? 'checked' : ''));
        __out.push('>\n            <label for="track-vendor-use-taxes">Track Vendor for Use Taxes</label>\n          </div>\n        </div>\n      </div>\n      ');
        if (this.canAccessLineItemsExtraction) {
          __out.push('\n        <div class="bg-grey-150 p-r-10 p-l-10 m-t-10"><p class="font-bold" ');
          if (this.editLinkLedger) {
            __out.push(__sanitize('hidden'));
          }
          __out.push(' >Other Info</p></div>\n        <div class="p-r-10 p-l-10" ');
          if (this.editLinkLedger) {
            __out.push(__sanitize('hidden'));
          }
          __out.push('>\n          <div class="inline-flex-wrapper width-100-percent m-t-10">\n            <label class="inline-wrapper in-grey-800 p-r-15 text-right width-140px" for=""></label>\n            <div class="checkbox m-t-0 m-b-0">\n              <input type="checkbox" class="pointer input-element eligibility-line-items-extraction-js" id="line_items_detection" data-type="checkbox" ');
          __out.push(__sanitize(this.is_ai_invoice_line_items_extraction ? 'checked' : ''));
          __out.push('>\n              <label for="line_items_detection">AI Invoice Line Items Detection</label>\n              <p style="font-size: 1.2rem; color: #666; margin-top: 5px;">This will help extract the line items from an invoice automatically. You will be able to edit the data. Docyt AI will learn from manually input data.</p>\n            </div>\n          </div>\n        </div>\n      ');
        }
        __out.push('  \n\n      <!-- Vendor Tax Info Area End -->\n\n      \n    </div>\n    <div class=\'p-r-10 p-l-10 m-t-30 text-center\' ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n      <button type=\'button\' class="btn btn-info ladda-button vendor-continue-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save Payee</span></button>\n    </div>\n    <div class=\'p-r-10 p-l-10 m-t-20 m-b-50 text-center\' ');
        if (this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n      <a class=\'cancel settings-cancel-btn\'>Cancel</a>\n    </div>\n    <div class="vendor-bottom-view" ');
        if (!this.editLinkLedger) {
          __out.push(__sanitize('hidden'));
        }
        __out.push('>\n      <button id="btn-submit" class="btn btn-info ladda-button vendor-continue-js" tabindex="10" data-color="blue"">Save</button>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n<div class="second-view-region height-100-percent">\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}