import React from 'react';

import classNames from 'classnames';

import HCell, { TTableHeaderCellProps } from './hcell';

import styles from './styles.module.scss';

const TableEmptyHCell = <TSortColumn extends string = never>({
  className,
  ...props
}: TTableHeaderCellProps<TSortColumn>) => {
  const classes = classNames(styles['table-empty-header-cell'], className);

  return (
    <HCell<TSortColumn>
      className={ classes }
      { ...props }
    />
  );
};

export default TableEmptyHCell;
