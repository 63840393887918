import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IDropdownItemHelperProps extends React.ComponentPropsWithoutRef<'span'> {}

const DropdownItemHelper = ({
  children,
  className,
  ...props
}: IDropdownItemHelperProps) => {
  const classes = classNames(styles['dropdown-menu-item-helper'], className);

  return (
    <span className={ classes } { ...props }>
      { children }
    </span>
  );
};

export default DropdownItemHelper;
