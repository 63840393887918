/* global __WebpackModuleApi */

import sortBy from 'lodash/sortBy';

const modulesContext = require.context('@assets_js/docyt-client/common', true, /.*\.coffee$|.*\.js$/);

const importAll = (reqContext: __WebpackModuleApi.RequireContext) => {
  sortBy(reqContext.keys()).forEach(reqContext);
};

importAll(modulesContext);
