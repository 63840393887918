import React, { FC } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetSingleJournalEntries } from '@src/hooks/queries/joural_entries';

import Text from '@src/components/ui/text';
import Tooltip from '@src/components/ui_v2/tooltip';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

const SingleJournalEntryDownload: FC<{id: number}> = ({ id }) => {
  const business = useBusinessContext();
  const businessId = business.id;
  const { mutateAsync, isLoading } = useGetSingleJournalEntries();
  const params = {
    businessId,
    id,
  };

  const handleClick = async () => {
    try {
      const data = await mutateAsync(params);
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'JournalEntries.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      toastr.error('Failed to download CSV.', 'Error');
    }
  };

  const tooltipContent = <Text as="p">JounralEntry.csv</Text>;

  if (isLoading) {
    return <SpinnerIcon spin fontSize={ 14 } />;
  }

  return (
    <div className={ styles.downloadcsvcontainer }>
      <Tooltip.Hover content={ tooltipContent } tooltipClassName={ styles['note-tooltip'] }>
        <DownloadDocumentIcon pointer fontSize={ 14 } inColor="black" onClick={ handleClick } />
      </Tooltip.Hover>
    </div>
  );
};

export default SingleJournalEntryDownload;
