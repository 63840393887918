import React from 'react';

import { JournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { TID } from '@src/types/common';

import Root from '@src/components/root';

import JournalEntriesContainer from './journal_entries_container';

const MainPage: React.FC<{ businessId: TID }> = ({ businessId }) => {
  return (
    <Root>
      <JournalEntriesContext.Provider value={ businessId }>
        <JournalEntriesContainer />
      </JournalEntriesContext.Provider>
    </Root>
  );
};

export default MainPage;
