import React, { useCallback } from 'react';

import _ from 'lodash';

import toastr from '@lib/toastr';
import { useDeletePaymentAccount } from '@src/hooks/queries/payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface IEmployeeCardDeleteActionProps {
	employeeCardItem: IPaymentAccount,
	refetchEmployeeCard(): void
}

const EmployeeCardDeleteAction = ({
  employeeCardItem,
  refetchEmployeeCard,
}: IEmployeeCardDeleteActionProps): JSX.Element | null => {
  const deletePaymentAccount = useDeletePaymentAccount();
  const { mutate: deletePaymentAccountMutate } = deletePaymentAccount;

  const handleOnConfirm = useCallback(() => {
    deletePaymentAccountMutate({
      id: employeeCardItem.id,
    }, {
      onSuccess: () => {
        refetchEmployeeCard();
        toastr.success('Account has been deleted successfully', 'Success');
      },
      onError: ({ response }) => {
        const errorMessageArray = response?.data.errors;
        if (_.isArray(errorMessageArray)) {
          toastr.error(errorMessageArray[0], 'Something went wrong');
        }
      },
    });
  }, [deletePaymentAccountMutate, employeeCardItem.id, refetchEmployeeCard]);

  const ConfirmModal = useConfirmDeleteModal({
    onDone: handleOnConfirm,
  });

  return (
    <>
      <ConfirmModal.Component
        { ...ConfirmModal.props }
        confirmStyle="danger"
        confirmTitle="Proceed"
        text={ (
          <>
            Are you sure you want to delete
            {' '}
            <strong>{employeeCardItem.name}</strong>
            {' '}
            account?
          </>
				) }
        title="Delete Account"
      />
      <ActionsDropdown.DangerAction
        className="employee-card-action-item"
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        name="Delete Account"
        onClick={ () => ConfirmModal.open() }
      />
    </>
  );
};

export default EmployeeCardDeleteAction;
