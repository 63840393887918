import React, { useCallback, useState } from 'react';

import { useForwardToBankStatements } from '@src/hooks/queries/documents';
import { IBusiness } from '@src/types/businesses';

import { IForwardToBankStatementsFormValues } from '@src/components/common/forward_document/types';
import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';

import Form from './forward_to_bank_statements_form';

interface IForwardToBankStatementsModalProps {
  business: IBusiness,
  documentIds: number[],
  show?: boolean,
  onCancel: () => void,
  onContinue: (data: IForwardToBankStatementsFormValues) => void,
}

const ForwardToBankStatementsModal = ({
  business,
  documentIds,
  show,
  onCancel,
  onContinue,
}: IForwardToBankStatementsModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const { mutate: forwardToBankStatements } = useForwardToBankStatements();

  const handleSubmit = useCallback((data: IForwardToBankStatementsFormValues) => {
    if (documentIds.length > 1) {
      setErrorMsg(
        window.Docyt.Common.Constants.Messages.FORWARD_DOCUMENT_TO_BANK_STATEMENTS_ERROR_MSG,
      );
      return;
    }

    forwardToBankStatements(
      {
        id:                             documentIds[0],
        businessId:                     business.id,
        reconciliationPaymentAccountId: data.reconciliationPaymentAccountId,
        date:                           data.date,
      },
      {
        onSuccess: () => { onContinue(data); },
        onError:   (error) => { setErrorMsg(error.message); },
      },
    );
  }, [business.id, documentIds, forwardToBankStatements, onContinue]);

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <Modal.Form
        dialogClassName="forward-to-bank-statement-modal"
        proceedTitle="Continue"
        show={ show }
        title="Forward to Bank Statements"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <Form
            businessId={ business.id }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        ) }
      </Modal.Form>
    </>
  );
};

export {
  IForwardToBankStatementsFormValues,
  ForwardToBankStatementsModal as default,
};
