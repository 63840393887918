module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog preview-modal modal-md width-80-percent\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <div class="font-bold m-l-10 modal-title">\n            <p class="display-inline-flex font-16 m-b-0 m-t-7 vertical-align-top pull-left">\n              <span class="vender-name">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</span>\n            </p>\n            <button class="btn bg-white btn-zoom-plus pull-right"><i class=\'font-20 icon icon-o-plus cursor-pointer\'></i></button>\n            <select class="selectpicker select-zoom pull-right border-white" data-width="110px">\n              <option value="page-fit" selected="selected">Fit To Page</option>\n              <option value="0.25">25%</option>\n              <option value="0.5">50%</option>\n              <option value="0.75">75%</option>\n              <option value="1">100%</option>\n              <option value="1.25">125%</option>\n              <option value="1.5">150%</option>\n              <option value="2">200%</option>\n              <option value="3">300%</option>\n              <option value="4">400%</option>\n            </select>\n            <button class="btn bg-white btn-zoom-minus pull-right"><i class=\'font-20 icon icon-substract cursor-pointer\'></i></button>\n            <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n              <span aria-hidden="true">&times;</span>\n            </button>            \n          </div>\n        </div>\n        <div class=\'modal-body\'>\n          ');
    
      if (this.is_downloaded) {
        __out.push('\n            <img class="img-document" src="');
        __out.push(__sanitize(this.img_url));
        __out.push('">\n          ');
      } else {
        __out.push('\n            <div class="update-document-container">\n              <div class="document-title-on-load">Your document is being downloaded</div>\n              <div class="document-preview-on-load">\n                <div class="placeholder-loading loading-document-title m-l-auto m-r-auto"></div>\n                <div class="placeholder-loading loading-document-line large m-t-10"></div>\n                <div class="placeholder-loading loading-document-line huge m-t-10"></div>\n                <div class="placeholder-loading loading-document-line large m-t-10"></div>\n                <div class="display-flex m-t-10">\n                  <div class="display-flex width-70-percent">\n                    <div class="placeholder-loading loading-document-line xxshort"></div>\n                    <div class="placeholder-loading loading-document-line xlarge m-l-auto"></div>\n                  </div>\n                  <div class="display-flex width-70-percent">\n                    <div class="placeholder-loading loading-document-line short m-l-auto m-r-0"></div>\n                  </div>\n                </div>\n                <div class="placeholder-loading loading-document-line xhuge m-t-10 m-l-auto"></div>\n                <div class="placeholder-loading loading-document-line xxhuge m-t-10"></div>\n                <div class="placeholder-loading loading-document-line xxlarge m-t-10"></div>\n              </div>\n            </div>\n          ');
      }
    
      __out.push('\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}