import React from 'react';

import classNames from 'classnames';

import toastr from '@lib/toastr';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';

import useAcknowledge from '@src/components/reconciliation_center/month_end/acknowledge_action/use_acknowledge';
import { Button } from '@src/components/ui_v2/buttons';

import { useAcknowledgeModal } from './modals';

import styles from '../styles.module.scss';

interface AcknowledgeButtonProps {
  baReconciliationItem: IBankAccountReconciliationItem,
  noteRequired: boolean,
  onDone: (data: any) => void,
  closeDrawer?: () => void,
  total?: string
  state?: string | null
}

const AcknowledgeButton = (
  { baReconciliationItem, noteRequired, onDone, closeDrawer, total, state }: AcknowledgeButtonProps,
) => {
  const acknowledgeModal = useAcknowledgeModal({ onDone });
  const { handleAcknowledge } = useAcknowledge(baReconciliationItem);

  const itemAcknowledged = baReconciliationItem.status === 'acknowledged';

  const acknowledge = async () => {
    await handleAcknowledge(itemAcknowledged ? 'created' : 'acknowledged');
    closeDrawer?.();
  };
  const verifyAcknowledge = () => {
    if (state === 'Unverified') {
      toastr.error('Please verify the bank statement or mark it as "No Statement Available" first before acknowledging it', 'Error');
    } else if (state === 'No Statement Available' || (total === '$0.00' && state == null)) {
      acknowledge();
    } else {
      acknowledgeModal.open();
    }
  };

  return (
    <>
      <acknowledgeModal.Component
        baReconciliationItem={ baReconciliationItem }
        closeDrawer={ closeDrawer }
        noteRequired={ noteRequired }
        total={ total }
        { ...acknowledgeModal.props }
      />
      <Button
        className={ classNames(styles['acknowledge-button'], 'acknowledge-button') }
        variant="ghost"
        onClick={ verifyAcknowledge }
      >
        Acknowledge
      </Button>
    </>
  );
};

export default React.memo(AcknowledgeButton);
