import React from 'react';

import useDestroyBusinessModal from '@src/components/business/destroy_business_modal';

interface IDeleteBusinessActionProps {
  businessName: string,
  onDeleteConfirmed: () => void,
}

const DeleteBusinessAction = ({
  businessName,
  onDeleteConfirmed,
}: IDeleteBusinessActionProps) => {
  const alert = useDestroyBusinessModal({
    onDone: onDeleteConfirmed,
  });

  return (
    <>
      <alert.Component
        { ...alert.props }
        businessName={ businessName }
      />
      <a
        className="font-16 pointer danger-link"
        role="button"
        tabIndex={ -1 }
        onClick={ alert.open }
      >
        Delete Business
      </a>
    </>
  );
};

export default DeleteBusinessAction;
