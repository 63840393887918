module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<ul class="p-t-0 team-sidebar-nav">\n  ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n  ');
        if (this.isPayment && (business.contact_item.type === '' || business.contact_item.type === 'Invitation')) {
          continue;
        }
        __out.push('\n  <li>\n    <div class="business-item-wrapper business-item-js ');
        __out.push(__sanitize(this.tab === business.id ? 'active' : ''));
        __out.push('" id="');
        __out.push(__sanitize(business.id));
        __out.push('">\n      <div class="team-setting-header p-t-10 p-b-10 ');
        __out.push(__sanitize(business.contact_item && (business.contact_item.type === '' || business.contact_item.type === 'Invitation') ? 'business-inactive' : ''));
        __out.push('">\n          <div class="avatar-wrapper">\n            ');
        if (business.image_url) {
          __out.push('\n            <img src="');
          __out.push(__sanitize(business.image_url));
          __out.push('" class=\'service-icon-font\'>\n            ');
        } else {
          __out.push('\n            <span class=\'icon-business-default-avatar service-icon-font\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n            ');
        }
        __out.push('\n          </div>\n        ');
        if (this.isClient) {
          __out.push('\n          <div>\n            <div class="nav-item-text">');
          __out.push(__sanitize(business.legal_name));
          __out.push('</div>\n            <div class="nav-item-text" style="font-size:12px; color:#AAA;">');
          __out.push(__sanitize(business.display_name));
          __out.push('</div>\n          </div>\n        ');
        } else {
          __out.push('\n          <span class="nav-item-text">');
          __out.push(__sanitize(business.legal_name));
          __out.push('</span>\n          ');
          if (business.contact_item.type === "Invitation") {
            __out.push('<span class="nav-item-number sent-invite font-11-important">Invitation&nbsp;Sent</span>');
          }
          __out.push('\n          ');
          if (business.contact_item.type === "") {
            __out.push('<span class="nav-item-number inactive font-11-important">Inactive</span>');
          }
          __out.push('\n        ');
        }
        __out.push('\n      </div>\n    </div>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}