module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'clients__status-filter form-group\'>\n    <div class="status-filter-dropdown-wrapper relative">\n      <button class="btn dropdown-toggle btn-filter-toggle form-controller" data-toggle="dropdown">\n        <span class="filter-name status-name-js ');
    
      if (this.filter_status) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.filter_status || 'Status'));
    
      __out.push('</span>\n        <i class="fa fa-caret-down font-12"></i>\n      </button>\n      <ul class="dropdown-menu">\n        <li class="dropdown-item blocked-status-js">Blocked</li>\n        <li class="dropdown-item active-status-js">Active</li>\n        <li class="dropdown-item all-status-js">All</li>\n      </ul>\n    </div>\n</div>\n<div class=\'clients__role-filter form-group\'>\n    <div class="status-filter-dropdown-wrapper relative">\n      <button class="btn dropdown-toggle btn-filter-toggle form-controller" data-toggle="dropdown">\n        <span class="filter-name role-name-js ');
    
      if (this.filter_role) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.filter_role || 'Role'));
    
      __out.push('</span>\n        <i class="fa fa-caret-down font-12"></i>\n      </button>\n      <ul class="dropdown-menu">\n        <li class="dropdown-item account-manager-role-js">Account Manager</li>\n        <li class="dropdown-item staff-role-js">Staff</li>\n        <li class="dropdown-item all-role-js">All</li>\n      </ul>\n    </div>\n</div>\n<div class=\'clients__search form-group\'>\n  <input class=\'clients__search-input form-control\' placeholder="Search Clients" type=\'text\' value="');
    
      __out.push(__sanitize(this.filter_search));
    
      __out.push('">\n  <i class=\'clients__search-icon icon icon-search\'></i>\n</div>\n<div class="business-list-container"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}