import React from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TID } from '@src/types/common';

import { Accordion } from '@src/components/ui_v2/accordion';
import Section from '@src/components/utils_v2/section';

import { useAchRelatedDocumentsCollection } from './hooks';
import Table from './related_documents_table';

import styles from '../styles.module.scss';

interface IRelatedDocumentsProps {
  businessId: TID,
  serviceId: TID,
  document: IAchTransaction,
}

const RelatedDocuments = ({
  businessId,
  serviceId,
  document,
}: IRelatedDocumentsProps) => {
  const collection = useAchRelatedDocumentsCollection({
    businessId,
    serviceId,
    document,
  });

  return (
    <div className={ styles['related-documents'] }>
      <Accordion
        size="compact"
        title="Related Documents"
      >
        { () => (
          <div className={ styles['related-documents-content'] }>
            <Section.Provider section={ collection.section }>
              <Table
                records={ collection.records }
                sorting={ collection.sorting }
              />
            </Section.Provider>
          </div>
        ) }
      </Accordion>
    </div>
  );
};

export default React.memo(RelatedDocuments);
