/* eslint-disable no-param-reassign */
import React from 'react';

import { startCase } from 'lodash';

import { IMetricsReport, MetricReportStatusEnum, TMetricReportsSortColumn } from '@src/types/metrics/metrics_reports';

import CollectionTable from '@src/components/ui_v2/collection_table';

import AssignDateAction from './actions/assign_date_action';
import BulkDeleteUnassignedDocumentsAction from './actions/bulk_delete_action';
import { IMetricsReportCollection } from './hooks';

interface IMetricsReportTableProps {
  collection: IMetricsReportCollection,
  assignDateStatus?: MetricReportStatusEnum | null
}

const assignDateAction = (document: IMetricsReport) => {
  return (
    <AssignDateAction
      metricReportStatus={ document.status }
      metricsReportId={ document.id }
      preSelectedDate={ document.reportDate }
    />
  );
};

const statusLabelVariant = (document: MetricReportStatusEnum) => {
  switch (document) {
    case MetricReportStatusEnum.SUCCESS:
    case MetricReportStatusEnum.DATE_ASSIGNED:
      return 'success';
    case MetricReportStatusEnum.FAILED:
    case MetricReportStatusEnum.DATE_UNASSIGNED:
      return 'failed';
    case MetricReportStatusEnum.PENDING:
    case MetricReportStatusEnum.PROCESSING:
    case MetricReportStatusEnum.QUEUED:
      return 'normal';
    default:
      return 'warning';
  }
};

const statusLabelText = (document: MetricReportStatusEnum) => {
  switch (document) {
    case MetricReportStatusEnum.SUCCESS:
    case MetricReportStatusEnum.DATE_ASSIGNED:
      return 'success';
    case MetricReportStatusEnum.FAILED:
    case MetricReportStatusEnum.DATE_UNASSIGNED:
      return 'failed';
    case MetricReportStatusEnum.PENDING:
    case MetricReportStatusEnum.PROCESSING:
    case MetricReportStatusEnum.QUEUED:
      return 'processing';
    default:
      return document;
  }
};

const MetricsReportTable = ({
  collection,
  assignDateStatus,
}: IMetricsReportTableProps) => {
  return (
    <CollectionTable<
        IMetricsReport,
        TMetricReportsSortColumn
      >
      showSelect
      bulkActions={ (
        <BulkDeleteUnassignedDocumentsAction
          selectedRecords={ collection.selectedRecords }
        />
      ) }
      noDataMessage="No reports found"
      query={ collection.query }
      records={ collection.records }
      sorting={ collection.sorting }
    >
      <CollectionTable.TextColumn<
        IMetricsReport,
        TMetricReportsSortColumn
      >
        name="filename"
        sortColumn="filename"
        title="Name"
        value={ (document) => document.filename }
        width="50%"
      />
      <CollectionTable.TextColumn<
        IMetricsReport,
        TMetricReportsSortColumn
      >
        name="uploader_name"
        sortColumn="uploader_name"
        title="Uploader"
        value={ (document) => document.uploaderName }
        width="20%"
      />
      <CollectionTable.DateColumn<
        IMetricsReport,
        TMetricReportsSortColumn
      >
        name="created_at"
        sortColumn="created_at"
        title="Uploaded on"
        value={ (document) => document.createdAt }
        width="20%"
      />
      <CollectionTable.LabelColumn<
        IMetricsReport,
        TMetricReportsSortColumn
      >
        name="status"
        title="Status"
        tooltip={ (document) => (
          (document.status === MetricReportStatusEnum.FAILED
          || document.status === MetricReportStatusEnum.DATE_UNASSIGNED)
          && document.error
        ) }
        value={ (document) => startCase(statusLabelText(assignDateStatus || document.status)) }
        variant={ (document) => statusLabelVariant(assignDateStatus || document.status) }
        width="20%"
      />
      <CollectionTable.ButtonColumn<any>
        name="id"
        title="Assign Date"
        value={ (document) => assignDateAction(document) }
        width="20%"
      />
    </CollectionTable>
  );
};

export default React.memo(MetricsReportTable);
