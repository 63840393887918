import React, { useCallback, useState, useEffect } from 'react';

import { useQueryClient } from 'react-query';

import { CountryOptions } from '@src/constants/country_options';
import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAddressBook, useUpdateAddressBook } from '@src/hooks/queries/vendor_address_book';
import {
  ISugestedAddressResponse,
} from '@src/requests/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TAddressKeys } from '@src/types/vendor';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import { IBusinessAddressBookInput, IUpdateAddressBookInput } from '../form/schema';

import { useAddressBookModal, useAddressEditBookModal } from '.';

import styles from './styles.module.scss';

interface IConfirmAddressProps extends IUseModalProps {
  title: JSX.Element | string,
  vendor: IBusinessVendor,
  addressInput: IBusinessAddressBookInput | IUpdateAddressBookInput,
  suggestedAddress: ISugestedAddressResponse,
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  address: IAddress;
  isAddAddressModal?: boolean;
}

const ConfirmAddressBookModal = ({
  isOpen,
  title,
  onCancel,
  onDone,
  vendor,
  addressInput,
  suggestedAddress,
  address,
  isAddAddressModal,
  setVendorData,
}: IConfirmAddressProps): JSX.Element => {
  const queryClient = useQueryClient();
  const addAddressBook = useAddressBook();
  const updateAddressBook = useUpdateAddressBook();
  const addAddressBookModal = useAddressBookModal();
  const addressEditBookModal = useAddressEditBookModal();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState('entered');

  const { mutate: addAddress } = addAddressBook;
  const { mutate: updateAddress } = updateAddressBook;

  const isDeliverable = suggestedAddress?.deliverability !== 'undeliverable'
  && suggestedAddress?.deliverability !== 'no_match';

  const getCountryName = (countryCode : any) => {
    return (
      CountryOptions.find(
        (country) => country.value === countryCode,
      )?.name || countryCode
    );
  };

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value);
  };

  const getAddressField = useCallback(
    (field: TAddressKeys) => (
      selectedAddress === 'entered' ? addressInput?.address[field] : suggestedAddress?.[field]
    ),
    [selectedAddress, addressInput, suggestedAddress],
  );

  const handleSubmit = useCallback(
    () => {
      setErrorMessage(undefined);
      if (isAddAddressModal) {
        addAddress(
          {
            address: {
              addressCity:    getAddressField('addressCity'),
              addressCountry: getAddressField('addressCountry'),
              addressLine1:   getAddressField('addressLine1'),
              addressState:   getAddressField('addressState'),
              addressZip:     getAddressField('addressZip'),
              addressLine2:   addressInput?.address.addressLine2,
              name:           addressInput?.address.name,
              company:        addressInput?.address.company,
              isAuthorized:   addressInput?.address?.isAuthorized,
              businessId:     addressInput?.address.businessId,
              vendorId:       addressInput?.address.vendorId,
              department:     addressInput?.address.department,
            },
            businessId: vendor.businessId,
            vendorId:   vendor.id,
          },
          {
            onSuccess: (response) => {
              queryClient.invalidateQueries(QueryKey.vendorAddresses);

              const newAddress = response.address;
              const updatedVendor = {
                ...vendor,
                addresses: [...(vendor.addresses || []), newAddress],
              };

              setVendorData(updatedVendor);
              setSuccessMessage('Address has been successfully added.');
              onDone();
            },
            onError: handleError,
          },
        );
      } else {
        updateAddress(
          {
            address: {
              addressCity:     getAddressField('addressCity'),
              addressCountry:  getAddressField('addressCountry'),
              addressLine1:    getAddressField('addressLine1'),
              addressState:    getAddressField('addressState'),
              addressZip:      getAddressField('addressZip'),
              name:            addressInput?.address.name ?? address?.name,
              company:         addressInput?.address.company ?? address?.company,
              isAuthorized:    addressInput?.address.isAuthorized ?? address?.isAuthorized,
              addressLine2:    addressInput?.address.addressLine2 ?? address?.addressLine2,
              department:      addressInput?.address.department ?? address?.department,
              businessId:      addressInput?.address.businessId,
              vendorId:        addressInput?.address.vendorId,
              addressableId:   address?.addressableId,
              id:              address?.id,
              addressableType: address?.addressableType,
              createdAt:       address?.createdAt,
              isGlobal:        address?.isGlobal,
              isLobVerified:   address?.isLobVerified,
              updatedAt:       address?.updatedAt,
              isVerified:      address?.isVerified,
              createdBy:       address?.createdBy,
              addedBy:         address?.addedBy,
              addedOn:         address?.addedOn,
            },
            businessId: vendor.businessId,
            vendorId:   vendor.id,
            addressId:  address?.id,
          },
          {
            onSuccess: (editResponse) => {
              queryClient.invalidateQueries(QueryKey.vendorAddresses);

              const newAddress = editResponse.address;
              const updatedVendor = {
                ...vendor,
                addresses: [...(vendor.addresses || []), newAddress],
              };

              setVendorData(updatedVendor);
              setSuccessMessage('Address has been successfully updated.');
              onDone();
            },
            onError: handleError,
          },
        );
      }
    },
    [addAddress, vendor, setVendorData, onDone, handleError,
      queryClient, addressInput, getAddressField, address, updateAddress, isAddAddressModal],
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedAddress(isDeliverable ? 'suggested' : 'entered');
    }
  }, [isOpen, isDeliverable]);

  return (
    <>
      {(addAddressBook.isLoading) && <Spinner />}
      {(updateAddressBook.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Something went wrong" onHidden={ () => setErrorMessage(undefined) } />}
      {successMessage && (
        <SuccessNotification
          message={ successMessage }
          onHidden={ () => setSuccessMessage(undefined) }
        />
      )}
      <Modal className={ styles['modal-address-book'] } show={ isOpen } title={ title }>
        <Modal.Body>
          <p className={ styles['modal-content'] }>
            {' '}
            {isDeliverable
              ? 'We have slightly modified the address entered. If correct please use the suggested address to ensure accurate delivery.'
              : 'We were not able to verify the address entered. Please review it before you confirm.'}
          </p>
          <div className={ styles['toggle-option'] }>
            <input
              className={ styles['toggle-option-input'] }
              defaultChecked={ !isDeliverable }
              id="entered"
              name="address"
              type="radio"
              value="entered"
              onChange={ handleRadioChange }
            />
            <label className={ styles['toggle-option-label'] } htmlFor="entered">
              <span>Entered Address</span>
              <span
                className={ styles['toggle-option-edit-text'] }
                role="button"
                tabIndex={ 0 }
                onClick={ () => {
                  if (isAddAddressModal) {
                    addAddressBookModal.open();
                  } else {
                    addressEditBookModal.open();
                  }
                  onCancel();
                } }
              >
                Edit
              </span>
            </label>
            <p className={ styles['toggle-option-address-text'] }>
              { `${addressInput?.address.addressLine1}${addressInput?.address.addressLine2 ? ` ${addressInput?.address.addressLine2}` : ''}, ${addressInput?.address.addressCity}, 
              ${addressInput?.address.addressState}, ${addressInput?.address.addressZip},`}
              <br />
              {getCountryName(addressInput?.address.addressCountry)}
            </p>
          </div>
          {isDeliverable && (
          <div className={ styles['toggle-option'] }>
            <input
              className={ styles['toggle-option-input'] }
              defaultChecked={ isDeliverable }
              id="suggested"
              name="address"
              type="radio"
              value="suggested"
              onChange={ handleRadioChange }
            />
            <label className={ styles['toggle-option-label'] } htmlFor="suggested">
              <span>Suggested Address</span>
            </label>
            <p className={ styles['toggle-option-address-text'] }>
              { `${suggestedAddress?.addressLine1}${suggestedAddress?.addressLine2 ? ` ${suggestedAddress?.addressLine2}` : ''}, ${suggestedAddress?.addressCity}, 
              ${suggestedAddress?.addressState}, ${suggestedAddress?.addressZip},`}
              <br />
              {getCountryName(suggestedAddress?.addressCountry)}
            </p>
          </div>
          )}

        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            title="Cancel"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            className="btn-primary"
            variant="primary"
            onClick={ handleSubmit }
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <addAddressBookModal.Component
        formId="address_book_form"
        setVendorData={ setVendorData }
        { ...addAddressBookModal.props }
        isEdit
        address={ address }
        addressInput={ addressInput }
        businessId={ vendor.businessId }
        vendor={ vendor }
      />
      <addressEditBookModal.Component
        address={ address }
        formId="edit_address_form"
        setVendorData={ setVendorData }
        { ...addressEditBookModal.props }
        isEdit
        addressInput={ addressInput as IUpdateAddressBookInput }
        businessId={ vendor.businessId }
        vendor={ vendor }
      />
    </>
  );
};

const useConfirmAddressBookModal = makeUseModal(ConfirmAddressBookModal);
export {
  IConfirmAddressProps,
  useConfirmAddressBookModal,
  ConfirmAddressBookModal as default,
};
