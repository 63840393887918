module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, item, len, ref;
    
      __out.push('<td class="p-l-15 p-r-5 font-14 vertical-align-middle-important">\n  ');
    
      if (this.type === 'metric') {
        __out.push('\n    ');
        __out.push(__sanitize(this.display_name));
        __out.push('\n  ');
      } else if (this.chart_of_account) {
        __out.push('\n    ');
        ref = this.chart_of_account.display_name.split("-");
        for (i = 0, len = ref.length; i < len; i++) {
          item = ref[i];
          __out.push('\n      ');
          if (this.chart_of_account.display_name.split("-").indexOf(item) > 0) {
            __out.push('\n        <i class=\'fa fa-caret-right\'></i>\n      ');
          }
          __out.push('\n      ');
          __out.push(__sanitize(item));
          __out.push('\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    ');
        __out.push(__sanitize(this.display_name));
        __out.push('\n  ');
      }
    
      __out.push('\n</td>\n<td class="vertical-align-middle-important">\n  ');
    
      if (this.accountClasses.length > 0 && this.accounting_class_id) {
        __out.push('\n    ');
        __out.push(__sanitize(this.getAccountClassName(this.accounting_class_id).name));
        __out.push('\n  ');
      } else if (this.type === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_PAYMENT_PROCESSORS_ADJUSTMENT'] && this.accountClasses.length > 0) {
        __out.push('\n    -\n  ');
      }
    
      __out.push('\n</td>\n<td class="vertical-align-middle-important">\n  ');
    
      __out.push(__sanitize(this.line_item_name));
    
      __out.push('\n</td>\n');
    
      if (this.type !== Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['REVENUE_METRIC']) {
        __out.push('\n  <td class="vertical-align-middle-important">\n    ');
        if (this.is_debit === true) {
          __out.push('\n      Debit\n    ');
        } else if (this.is_debit === false) {
          __out.push('\n      Credit\n    ');
        }
        __out.push('\n  </td>\n  ');
        if (this.section === Docyt.Common.Constants.REVENUE_CATEGORY_TYPE['TAX']) {
          __out.push('\n    <td class="vertical-align-middle-important">\n      ');
          __out.push(__sanitize(this.vendor_name));
          __out.push('\n    </td>\n  ');
        }
        __out.push('\n  <td class="pointer" style="width:50px">\n    <div class="relative">\n      <a class="btn dropdown-toggle" type="button" id="category-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n      </a>\n      <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="category-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n        <li><a tabindex="-1" class="pointer edit-report-line"><span class="icon-edit font-18 m-l-5 m-r-15 vertical-align-middle-important"></span>Edit</a></li>\n        <li><a tabindex="-1" class="btn-delete-pp btn-delete-action"><span class="icon-destroy font-24 m-r-10 vertical-align-middle-important"></span>Delete</a></li>\n      </ul>\n    </div>\n  </td>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}