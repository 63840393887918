import React, { useCallback } from 'react';

import { documentPath } from '@src/routes';
import {
  IAccountsPayableServiceDocument,
  TAccountsPayableServiceDocumentsSortColumn,
} from '@src/types/accounts_payable/accounts_payable_service_documents';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

interface IPastPaymentsTableProps {
  records: IAccountsPayableServiceDocument[],
  sorting: ISorting<TAccountsPayableServiceDocumentsSortColumn>,
}

const PastPaymentsTable = ({
  records,
  sorting,
}: IPastPaymentsTableProps) => {
  const handleRowClick = useCallback((invoice: IAccountsPayableServiceDocument) => {
    Backbone.history.navigate(documentPath(invoice.documentId), { trigger: true });
  }, []);

  return (
    <CollectionTable<
      IAccountsPayableServiceDocument,
      TAccountsPayableServiceDocumentsSortColumn
    >
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.DateColumn<IAccountsPayableServiceDocument>
        name="invoice_date"
        title="Date"
        value={ (invoice) => invoice.invoiceDate }
        width="100px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument>
        name="invoice_number"
        title="Invoice #"
        value={ (invoice) => invoice.invoiceNumber }
        width="100px"
      />
      <CollectionTable.TextColumn<IAccountsPayableServiceDocument>
        name="payment_mode"
        title="Paid by"
        value={ (invoice) => invoice.paymentMode.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }
      />
      <CollectionTable.AmountColumn<IAccountsPayableServiceDocument>
        name="invoice_amount"
        title="Amount"
        value={ (invoice) => invoice.invoiceAmount }
        width="100px"
      />
    </CollectionTable>
  );
};

export default React.memo(PastPaymentsTable);
