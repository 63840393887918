module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, value;
    
      __out.push('<div class=\'head-column\'>\n  ');
    
      if (this.itemValues.hasLink) {
        __out.push('\n    <a class="report-child-line-item pointer">');
        __out.push(__sanitize(this.name));
        __out.push('</a>\n  ');
      } else {
        __out.push('\n    <span>');
        __out.push(__sanitize(this.name));
        __out.push('</span>\n  ');
      }
    
      __out.push('\n</div>\n<div class="main-box height-100-percent">\n  ');
    
      ref = this.itemValues.values;
      for (i = 0, len = ref.length; i < len; i++) {
        value = ref[i];
        __out.push('\n    ');
        if (value.type === 'devide_column') {
          __out.push('\n      <div class="column-border-right"></div>\n    ');
        } else {
          __out.push('\n      <div class="monthly-report-box">\n        ');
          if (value.hasLink) {
            __out.push('\n          <a href=\'');
            __out.push(__sanitize(value.hrefLink));
            __out.push('\' class=\'general-column\'>');
            __out.push(__sanitize(value.value));
            __out.push('</a>\n        ');
          } else {
            __out.push('\n          <div class="general-column">');
            __out.push(__sanitize(value.value));
            __out.push('</div>\n        ');
          }
          __out.push('\n      </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}