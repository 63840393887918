import React, { useMemo } from 'react';

import { useGetMultiBusinessReportDetail } from '@src/hooks/queries/report_service/multi_business_report';

import Section from '@src/components/utils_v2/section';

import MultiReportsExportContent from './content';
import MultiReportsExportFooter from './footer';
import MultiReportsExportHeader from './header';

const MultiReportsExportDetails = ({ multiReportId }: { multiReportId: string }) => {
  const query = useGetMultiBusinessReportDetail(multiReportId);

  const multiReport = useMemo(() => query.data, [query.data]);

  return (
    <Section.Provider section={ { businessId: Number(multiReportId), section: 'multi-reports-export', skipURLParams: true } }>
      <div className="reports-export multi-report">
        <MultiReportsExportHeader multiReportId={ multiReportId } />
        <MultiReportsExportContent multiReport={ multiReport } query={ query } />
        <MultiReportsExportFooter multiReportId={ multiReportId } />
      </div>
    </Section.Provider>
  );
}

export default MultiReportsExportDetails;
