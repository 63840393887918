module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<h2 class="font-26 font-bold m-b-0 m-t-0">Settings</h2>\n<hr class="m-t-25 m-b-25">\n<div class="form-inline m-t-40">\n  <div class="form-group font-16">\n    <input type="checkbox" id="auto_numbers_checkbox" class="auto_numbers_checkbox" name="auto_numbers_checkbox" value="enable">\n    <label for="auto_numbers_checkbox"> Enable invoice numbers generation</label>\n  </div>\n</div>\n<p class="font-12 forwarding-email-desc m-t-20">When checked, we will automatically generate invoice numbers for all invoices that are created through an integration.</p>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}