import { useEffect, useState } from 'react';

import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import { InfiniteData, UseQueryOptions, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { IModel, TID } from '@src/types/common';

import { useDebouncedEffect } from '../utils';
import { DummyInfiniteData } from './helpers';

interface IResponseWithPageInfo<T> {
  collection: Array<T>,
  meta: {
    totalCount: number,
  },
}

const removeItemsFromInfiniteCollection = <
  TModel extends IModel,
  TResponse extends IResponseWithPageInfo<TModel>
>(data: InfiniteData<TResponse> | undefined, itemIds: TID[]): InfiniteData<TResponse> => {
  if (!data) return DummyInfiniteData;

  return {
    ...data,
    pages: data.pages.map((pageData: TResponse) => ({
      ...pageData,
      collection: pageData.collection.filter((item) => !itemIds.includes(item.id)),
    })),
  };
};

const updateItemsInInfiniteCollection = <
  TModel extends IModel,
  TResponse extends IResponseWithPageInfo<TModel>
>(data: InfiniteData<TResponse> | undefined, itemIds: TID[], attrs: Partial<TModel>):
InfiniteData<TResponse> => {
  if (!data) return DummyInfiniteData;

  return {
    ...data,
    pages: data.pages.map((pageData) => ({
      ...pageData,
      collection: pageData.collection.map((item) => {
        if (!itemIds.includes(item.id)) return item;

        return {
          ...item,
          ...attrs,
        };
      }),
    })),
  };
};

interface ICreateUseGetInfiniteCollectionParams
<
  TModel extends object,
  TParams extends object,
  TResponse extends IResponseWithPageInfo<TModel>,
>
{
  queryKey: string,
  request: (params: TParams, opts?: UseQueryOptions<TResponse, Error>) => Promise<TResponse>,
}

const createUseGetInfiniteCollection =
<
  TModel extends object,
  TParams extends object,
  TResponse extends IResponseWithPageInfo<TModel>,
>({ queryKey, request }
  : ICreateUseGetInfiniteCollectionParams<TModel, TParams, TResponse>) => {
  return (params: TParams, opts = {}) => {
    // Debounce params, so the query will be debounced too
    const [debouncedParams, setDebouncedParams] = useState(params);
    const client = useQueryClient();

    useDebouncedEffect(() => {
      setDebouncedParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, values(params), window.SEARCH_DEBOUNCE_INTERVAL);

    // If some of params are changes we don't want to refetch all pages that are cached
    // and want to load only the first page. So we remove all previous queried pages.
    useEffect(() => {
      client.removeQueries(queryKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, ...values(debouncedParams)]);

    return useInfiniteQuery<TResponse, Error>(
      [queryKey, debouncedParams],
      ({ pageParam = 1 }) => request({ ...debouncedParams, page: pageParam }),
      {
        ...opts,
        getNextPageParam: (lastPage, pages) => {
          const loadedCount = sumBy(pages, (p) => p.collection.length);

          if (lastPage.meta.totalCount <= loadedCount) return undefined;
          if (lastPage.collection.length === 0) return undefined;

          return pages.length + 1;
        },
      },
    );
  };
};

interface ITimeStampProps {
  timestamp?: string,
}

const createUseGetInfiniteCollectionBasedOnLastTimestamp =
<
TModel extends ITimeStampProps,
  TParams extends object,
  TResponse extends IResponseWithPageInfo<TModel>,
>({ queryKey, request }
  : ICreateUseGetInfiniteCollectionParams<TModel, TParams, TResponse>) => {
  return (params: TParams, opts = {}) => {
    // Debounce params, so the query will be debounced too
    const [debouncedParams, setDebouncedParams] = useState(params);
    const client = useQueryClient();

    useDebouncedEffect(() => {
      setDebouncedParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, values(params), window.SEARCH_DEBOUNCE_INTERVAL);

    // If some of params are changes we don't want to refetch all pages that are cached
    // and want to load only the first page. So we remove all previous queried pages.
    useEffect(() => {
      client.removeQueries(queryKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, ...values(debouncedParams)]);

    return useInfiniteQuery<TResponse, Error>(
      [queryKey, debouncedParams],
      ({ pageParam = undefined }) => request({ ...debouncedParams, timestamp: pageParam }),
      {
        ...opts,
        getNextPageParam: (lastPage, pages) => {
          const loadedCount = sumBy(pages, (p) => p.collection?.length || 0);

          if (lastPage.meta.totalCount <= loadedCount) return undefined;
          if (!lastPage.collection?.length || lastPage.collection?.length === 0) return undefined;
          const lastItem = lastPage.collection.length;
          return lastPage.collection[lastItem - 1]?.timestamp;
        },
      },
    );
  };
};
interface ICreateUseDestroyFromInfiniteCollectionParams<TParams extends object> {
  queryKey: string,
  request: (params: TParams) => Promise<void>,
}

interface IDestroyParams {
  id: TID,
}

const createUseDestroyFromInfiniteCollection =
<
  TModel extends IModel,
  TParams extends IDestroyParams,
  TResponse extends IResponseWithPageInfo<TModel>,
>({ queryKey, request }: ICreateUseDestroyFromInfiniteCollectionParams<TParams>) => {
  return () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, TParams>(
      request,
      {
        onSuccess: (response, params) => {
          queryClient.setQueriesData(
            queryKey,
            (data?: InfiniteData<TResponse>): InfiniteData<TResponse> => {
              return removeItemsFromInfiniteCollection(
                data,
                [params.id],
              );
            },
          );
        },
      },
    );
  };
};

export {
  IResponseWithPageInfo,
  createUseGetInfiniteCollection,
  createUseGetInfiniteCollectionBasedOnLastTimestamp,
  createUseDestroyFromInfiniteCollection,
  removeItemsFromInfiniteCollection,
  updateItemsInInfiniteCollection,
};
