import React from 'react';

import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import { useCreateDashboardModal } from '../create_dashboard/create_dashboard_modal';

interface IDashboardCreateActionProps {
  businessId: TID,
  businessServiceInfo?: IBusinessServicesInfo,
}

const DashboardCreateAction = ({
  businessId,
  businessServiceInfo,
}: IDashboardCreateActionProps): JSX.Element => {
  const createDashboard = useCreateDashboardModal();

  return (
    <>
      <Button
        variant="primary"
        onClick={ createDashboard.open }
      >
        Create Dashboard
      </Button>
      <createDashboard.Component
        { ...createDashboard.props }
        businessId={ businessId }
        standardCategoryId={ businessServiceInfo?.standardCategoryId }
      />
    </>
  );
};

export default DashboardCreateAction;
