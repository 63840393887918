import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ICaretIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'down' | 'right' | 'up',
}

const CaretIcon = ({
  variant,
  ...props
}: ICaretIconProps): JSX.Element => {
  return <FaIcon icon={ `caret-${variant}` } { ...props } />;
};

export default CaretIcon;
