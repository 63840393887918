import React from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';

import Item from './portfolio_item';

import styles from '../styles.module.scss';

interface IPortfolioListProps {
  action: string,
  businesses: IBusiness[],
  multiBusinessReports: IMultiBusinessReportDetail[],
  setAction: React.Dispatch<React.SetStateAction<string>>,
}

const PortfolioList = ({
  action,
  businesses,
  multiBusinessReports,
  setAction,
}: IPortfolioListProps) => {
  return (
    <div className={ styles['multi-reports-list-container'] }>
      { multiBusinessReports.map((item: IMultiBusinessReportDetail) => (
        <Item
          key={ item.id }
          action={ action }
          businesses={ businesses }
          item={ item }
          setAction={ setAction }
        />
      )) }
    </div>
  );
};

export default React.memo(PortfolioList);
