import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IBusinessVendorsFilter } from './types';

interface IDocytNetworkFilterProps {
  filter: IFilter<IBusinessVendorsFilter>,
}

const AchStatusOptions = [
  { value: '', name: 'All' },
  { value: 'active', name: 'ACH Active' },
  { value: 'not_active', name: 'ACH not Active' },
];

const DocytNetworkFilter = ({
  filter,
}: IDocytNetworkFilterProps): JSX.Element => {
  return (
    <Filter<IBusinessVendorsFilter> filter={ filter }>
      <Filter.TextField
        name="vendor"
        placeholder="Search vendor by name"
        widthPercent={ 33 }
      />
      <Filter.TextField
        name="docytId"
        placeholder="Docyt ID"
        widthPercent={ 33 }
      />
      <Filter.SelectField
        name="achStatus"
        options={ AchStatusOptions }
        widthPercent={ 33 }
      />
      <Filter.ResetButton />
    </Filter>
  );
};

export default React.memo(DocytNetworkFilter);
