import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import { useEditMappingUnlinkModal } from '../actions/unlink_modal';
import { IEditMappingLaborProps } from '../hooks';

import styles from '../styles.module.scss';

interface IBusinessReportsEditMappingDepartmentItemProps extends IEditMappingLaborProps {
  dimensionLabel: string,
}

const BusinessReportsEditMappingDepartmentItem = ({
  dimensionLabel,
  parentId,
  childId,
  data,
}: IBusinessReportsEditMappingDepartmentItemProps) => {
  const modal = useEditMappingUnlinkModal();

  return (
    <>
      <modal.Component
        { ...modal.props }
        data={ data }
        dimensionLabel={ dimensionLabel }
        itemId={ childId ? childId as string : parentId as string }
      />
      <div className={ styles['department-item'] }>
        <p className={ styles.title }>{ dimensionLabel }</p>
        <Button variant="link" onClick={ modal.open }>Unmap</Button>
      </div>
    </>
  );
};

export default React.memo(BusinessReportsEditMappingDepartmentItem);
