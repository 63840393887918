import React, { } from 'react';

import Text from '@src/components/ui/text';
import { AddBudgetIcon } from '@src/components/utils/icomoon';

import AddBudgetReportAction from './add_budget_action';

import styles from './styles.module.scss';

const BlankPage = () => {
  return (
    <div className={ styles['empty-content'] }>
      <AddBudgetIcon />
      <Text fontSize={ 16 } inColor="black" mt={ 50 }>
        A budget helps you control business financials and improve decision-making.
      </Text>
      <Text className="m-b-25" fontSize={ 16 } inColor="black">
        Meet your profit objectives by creating a budget.
      </Text>
      <AddBudgetReportAction />
    </div>
  );
};

export default BlankPage;
