import React from 'react';

import { IRevenueSystem } from '@src/types/revenue_systems';

import { IDepositTrackingRSDCollection } from '@src/components/revenue_center/deposit_tracking/hooks';
import TableSection from '@src/components/ui_v2/table_section/table_section';

import Table from './revenues_table';

interface IDepositTrackingRevenuesProps {
  revenueSystem: IRevenueSystem,
  collection: IDepositTrackingRSDCollection,
}

const DepositTrackingRevenues = ({
  revenueSystem,
  collection,
}: IDepositTrackingRevenuesProps) => {
  return (
    <TableSection>
      <Table
        query={ collection.query }
        records={ collection.records }
        revenueSystem={ revenueSystem }
        sorting={ collection.sorting }
      />
    </TableSection>
  );
};

export default React.memo(DepositTrackingRevenues);
