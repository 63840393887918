module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, j, k, l, len, len1, len2, len3, line, ref, ref1, ref2, ref3;
    
      ref = this.same_line_prefix;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        line = ref[index];
        __out.push('\n<div class="m-t-10">\n    <label>');
        if (index === 0) {
          __out.push('Left Text:');
        }
        __out.push('</label>\n    <input class="proximity-values" data-type="same_line_prefix" value="');
        __out.push(__sanitize(line.value));
        __out.push('" data-id = "');
        __out.push(__sanitize(line.id));
        __out.push('">\n    ');
        if (index === this.same_line_prefix.length - 1) {
          __out.push('\n    <button class="btn btn-primary btn-add-line-js btn-sm" data-type="same_line_prefix">+</button>\n    ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n\n');
    
      ref1 = this.same_line_suffix;
      for (index = j = 0, len1 = ref1.length; j < len1; index = ++j) {
        line = ref1[index];
        __out.push('\n<div class="m-t-10">\n    <label>');
        if (index === 0) {
          __out.push('Right Text:');
        }
        __out.push('</label>\n    <input class="proximity-values" data-type="same_line_suffix" value="');
        __out.push(__sanitize(line.value));
        __out.push('" data-id = "');
        __out.push(__sanitize(line.id));
        __out.push('">\n    ');
        if (index === this.same_line_suffix.length - 1) {
          __out.push('\n    <button class="btn btn-primary btn-add-line-js btn-sm" data-type="same_line_suffix">+</button>\n    ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n\n');
    
      ref2 = this.above_text;
      for (index = k = 0, len2 = ref2.length; k < len2; index = ++k) {
        line = ref2[index];
        __out.push('\n<div class="m-t-10">\n    <label>');
        if (index === 0) {
          __out.push('Top Text:');
        }
        __out.push('</label>\n    <input class="proximity-values" data-type="above_text" value="');
        __out.push(__sanitize(line.value));
        __out.push('" data-id = "');
        __out.push(__sanitize(line.id));
        __out.push('">\n    ');
        if (index === this.above_text.length - 1) {
          __out.push('\n    <button class="btn btn-primary btn-add-line-js btn-sm" data-type="above_text">+</button>\n    ');
        }
        __out.push('\n</div>\n');
      }
    
      __out.push('\n\n');
    
      ref3 = this.below_text;
      for (index = l = 0, len3 = ref3.length; l < len3; index = ++l) {
        line = ref3[index];
        __out.push('\n<div class="m-t-10">\n    <label>');
        if (index === 0) {
          __out.push('Bottom Text:');
        }
        __out.push('</label>\n    <input class="proximity-values" data-type="below_text" value="');
        __out.push(__sanitize(line.value));
        __out.push('" data-id = "');
        __out.push(__sanitize(line.id));
        __out.push('">\n    ');
        if (index === this.below_text.length - 1) {
          __out.push('\n    <button class="btn btn-primary btn-add-line-js btn-sm" data-type="below_text">+</button>\n    ');
        }
        __out.push('\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}