import React from 'react';

import { TMongoID } from '@src/types/common';

import ReportServiceBudgetProvider from '@src/components/providers/budget_provider';

import BudgetDetail from './budget_detail';

interface IBudgetDetailPageProps {
  budgetId: TMongoID,
}

const BudgetDetailPage = ({
  budgetId,
}: IBudgetDetailPageProps): JSX.Element => {
  return (
    <ReportServiceBudgetProvider budgetId={ budgetId }>
      <BudgetDetail />
    </ReportServiceBudgetProvider>
  );
};

export default BudgetDetailPage;
