import React from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IRevenueSystem } from '@src/types/revenue_systems';

import { Button } from '@src/components/ui_v2/buttons';

import { useActivateDepositTrackingModal } from './activate_modal';

interface IActivateDepositTrackingProps {
  revenueSystem: IRevenueSystem,
}

const ActivateDepositTracking = ({
  revenueSystem,
}: IActivateDepositTrackingProps) => {
  const revenueService = useRevenueServiceContext();
  const addSystemModal = useActivateDepositTrackingModal();

  return (
    <>
      <addSystemModal.Component
        revenueService={ revenueService }
        revenueSystem={ revenueSystem }
        { ...addSystemModal.props }
      />
      <Button variant="primary" onClick={ addSystemModal.open }>
        Activate Deposit Tracking
      </Button>
    </>
  );
};

export default React.memo(ActivateDepositTracking);
