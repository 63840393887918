/* eslint-disable import/order */
import React, { memo, forwardRef } from 'react';

import StartChatThread from './start_chat_thread';

import { useAiChatBotContext } from '../context/ai_chat_context';
import { IThreadListResponse } from '../types';

import styles from '../styles.module.scss';

interface IChatListSectionsProps {
  header: string;
  list: IThreadListResponse[];
  prefixIcon?: React.ReactElement;
  infiniteScrollRef?: React.ForwardedRef<HTMLDivElement>;
}

const ChatListSections = forwardRef<HTMLDivElement, IChatListSectionsProps>(
  (props, ref) => {
    const { header, list, prefixIcon, infiniteScrollRef } = props;
    const { activeThreadId } = useAiChatBotContext();
    return (
      <div ref={ ref } className={ styles['chat-btn-section'] }>
        <h4 className={ styles['chat-list-heading'] }>{header}</h4>
        <div ref={ infiniteScrollRef }>
          {list?.map((item) => (
            <StartChatThread
              key={ item.id + item.summary }
              buttonText={ item.summary }
              channelId={ item?.channelId }
              isActive={ item.id === activeThreadId && item.id !== '' }
              message={ item.message }
              prefixIcon={ prefixIcon }
              threadId={ item.id }
            />
          ))}
        </div>
      </div>
    );
  },
);

ChatListSections.displayName = 'ChatListSections';

export default memo(ChatListSections);
