import React, { useEffect } from 'react';

interface IUseOutsideClickParams {
  ref: React.RefObject<HTMLDivElement>;
  callback(): void;
  exclueClassCheck?: string;
  excludedTagName?: string;
}

const useOutsideClick = ({
  ref,
  callback,
  exclueClassCheck,
  excludedTagName,
}: IUseOutsideClickParams) => {
  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (ref?.current && !ref?.current.contains(evt.target)) {
        if (excludedTagName && evt.target.tagName === excludedTagName) return;
        if (exclueClassCheck && evt.target.className === exclueClassCheck) return;
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [exclueClassCheck, callback, ref]);
};

export default useOutsideClick;
