import { TID } from '@src/types/common';
import { ICustomReportService } from '@src/types/report_service/custom_report_service';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut } from '../helpers';

interface IUpdateCustomReportServiceParams {
  reportServiceId: TID,
  defaultBudgetId: TID,
}

interface ICustomReportServiceResponse {
  reportService: ICustomReportService,
}

const getCustomReportService = (businessId: number): Promise<ICustomReportService> => {
  return apiGet(`/reports/api/v1/report_services/by_business_id?business_id=${businessId}`)
    .then((data) => camelizeKeys(data.report_service) as ICustomReportService);
};

const putUpdateCustomReportService = (
  params: IUpdateCustomReportServiceParams,
): Promise<ICustomReportServiceResponse> => {
  return apiPut(
    '/reports/api/v1/report_services/update_default_budget',
    underscoreKeys(params),
  ).then((data) => (
    {
      reportService: camelizeKeys(data.report_service),
    } as ICustomReportServiceResponse
  ));
};

export {
  ICustomReportServiceResponse,
  IUpdateCustomReportServiceParams,
  getCustomReportService,
  putUpdateCustomReportService,
};
