module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, ref1, status_data;
    
      __out.push('<div class="display-flex align-items-center justify-content-between">\n');
    
      ref = this.statusesData;
      for (i = 0, len = ref.length; i < len; i++) {
        status_data = ref[i];
        __out.push('\n  <div class="bookkeeping-status-dropdown-wrapper">\n    <div class="dropdown-toggle bookkeeping-status-control" id="bookkeeping-status-dropdown-btn" data-toggle="dropdown">\n      <p>');
        __out.push(__sanitize(this.monthForStatus(status_data)));
        __out.push('</p>\n      ');
        if (status_data.bookkeeping_status === void 0) {
          __out.push('\n      <div class="font-16 icon-not-reconciled" data-toggle="tooltip" data-placement="bottom" title="Reconciliation Not Started"></div>\n      ');
        } else if (status_data.bookkeeping_status.status === Docyt.Common.Constants.BOOKKEEPING_STATUS.RECONCILED) {
          __out.push('\n      <div class="font-16 icon-Group-1" data-toggle="tooltip" data-placement="bottom" title="Reconciled"><span class="path1"></span><span class="path2"></span></div>\n      ');
        } else if (status_data.bookkeeping_status.status === Docyt.Common.Constants.BOOKKEEPING_STATUS.RECONCILIATION_IN_PROGRESS) {
          __out.push('\n      <div class="font-16 icon-Progress" data-toggle="tooltip" data-placement="bottom" title="Reconciliation In Progress"></div>\n      ');
        } else if (status_data.bookkeeping_status.status === Docyt.Common.Constants.BOOKKEEPING_STATUS.RECONCILIATION_WAITING) {
          __out.push('\n      <div class="font-16 icon-waiting-for-client" data-toggle="tooltip" data-placement="bottom" title="Waiting for Client"><span class="path1"></span><span class="path2"></span></div>\n      ');
        } else if (status_data.bookkeeping_status.status === Docyt.Common.Constants.BOOKKEEPING_STATUS.RECONCILIATION_NOT_STARTED) {
          __out.push('\n      <div class="font-16 icon-not-reconciled" data-toggle="tooltip" data-placement="bottom" title="Reconciliation Not Started"></div>\n      ');
        } else if (status_data.bookkeeping_status.status === 'disabled') {
          __out.push('\n      <div class="font-12 font-bold na-status-label">NA</div>\n      ');
        }
        __out.push('\n    </div>\n    ');
        if (((ref1 = status_data.bookkeeping_status) != null ? ref1.status : void 0) !== 'disabled') {
          __out.push('\n    <ul class="dropdown-menu" aria-labelledby="bookkeeping-status-dropdown-btn">\n      <li class="pointer reconciled-js" data-value=');
          __out.push(__sanitize(status_data.month));
          __out.push('>\n        <div class=\'display-flex align-items-center\'>\n          <span class="font-24 icon-Group-1"><span class="path1"></span><span class="path2"></span></span>\n          <span class=\'font-12 m-t-3 m-l-10\'>Reconciled</span>\n        </div>\n      </li>\n      <li class="pointer in-progress-js" data-value=');
          __out.push(__sanitize(status_data.month));
          __out.push('>\n        <div class=\'display-flex align-items-center\'>\n          <span class="font-24 icon-Progress"></span>\n          <span class=\'font-12 m-t-3 m-l-10\'>Reconciliation In Progress</span>\n        </div>\n      </li>\n      <li class="pointer waiting-js" data-value=');
          __out.push(__sanitize(status_data.month));
          __out.push('>\n        <div class=\'display-flex align-items-center\'>\n          <span class="font-24 icon-waiting-for-client"><span class="path1"></span><span class="path2"></span></span>\n          <span class=\'font-12 m-t-3 m-l-10\'>Waiting for Client</span>\n        </div>\n      </li>\n      <li class="pointer not-started-js" data-value=');
          __out.push(__sanitize(status_data.month));
          __out.push('>\n        <div class=\'display-flex align-items-center\'>\n          <span class="font-24 icon-not-reconciled"></span>\n          <span class=\'font-12 m-t-3 m-l-10\'>Reconciliation Not Started</span>\n        </div>\n      </li>\n    </ul>\n    ');
        }
        __out.push('\n  </div>\n');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}