/* eslint-disable eqeqeq */
import React from 'react';

import {
  arServiceCreditMemoPath,
  arServiceCustomersPath,
  arServicePath,
  arServicePaymentsPath,
  arServiceSettingsPath,
  arServiceVendorProfilePath,
  businessSettingsPath,
  dailyReportsPath,
  monthlyReportsPath,
  revenueCenterPath,
  revenueCenterSettingsPath,
  unveirifiedReportsPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { isFeatureEnabled } from '@src/utils/config';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

interface IServiceMenuProps {
  businessId: TID,
  info: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean
}

const RevenueReconciliationItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient
}: IServiceMenuProps) => {
  const canVisibleRevenueService = info.isBusinessAdmin || info.canAccessRevenueService;
  const canVisibleArService = info.isBusinessAdmin || info.canAccessAccountsReceivableService;
  if (!canVisibleRevenueService && !canVisibleArService) return null;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.REVENUE_ACCOUNTING }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.REVENUE_ACCOUNTING }
    >
      {
        canVisibleRevenueService && (
          <ServiceSubMenu
            serviceUrl={
              info.revenueServiceReady
                ? revenueCenterPath(businessId)
                : businessSettingsPath(businessId)
            }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.REVENUE_SERVICE }
          >
            <ServiceItem
              disabledByLDFeature
              businessId={ businessId }
              ldBusinessFeature={ window.Docyt.Common.Constants.INTEGRATIONS_REVENUE_SYSTEM_FLAG }
              serviceUrl={
                info.revenueServiceReady
                  ? revenueCenterPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Summary"
            />
            <ServiceItem
              disabledByLDFeature
              businessId={ businessId }
              ldBusinessFeature={ window.Docyt.Common.Constants.INTEGRATIONS_REVENUE_SYSTEM_FLAG }
              serviceUrl={
                info.revenueServiceReady
                  ? monthlyReportsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Monthly Reports"
            />
            <ServiceItem
              disabledByLDFeature
              businessId={ businessId }
              ldBusinessFeature={ window.Docyt.Common.Constants.INTEGRATIONS_REVENUE_SYSTEM_FLAG }
              serviceUrl={
                info.revenueServiceReady
                  ? dailyReportsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Daily Reports"
            />
            <ServiceItem
              disabledByLDFeature
              businessId={ businessId }
              ldBusinessFeature={ window.Docyt.Common.Constants.INTEGRATIONS_REVENUE_SYSTEM_FLAG }
              serviceUrl={
                info.revenueServiceReady
                  ? unveirifiedReportsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Unverified Reports"
            />
            <ServiceItem
              businessId={ businessId }
              ldBusinessFeature={ window.Docyt.Common.Constants.INTEGRATIONS_REVENUE_SYSTEM_FLAG }
              serviceUrl={
                info.revenueServiceReady
                  ? revenueCenterPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Revenue Center"
            />
            <ServiceItem
              serviceUrl={
                info.revenueServiceReady
                  ? revenueCenterSettingsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Settings"
            />
          </ServiceSubMenu>
        )
      }
      {
        canVisibleArService && (
          <ServiceSubMenu
            isHidden={ !isFeatureEnabled('accounts_receivable') }
            serviceUrl={ arServicePath(businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.AR_SERVICE }
          >
            <ServiceItem
              serviceUrl={ arServicePath(businessId) }
              title="Invoices"
            />
            <ServiceItem
              serviceUrl={ arServicePaymentsPath(businessId) }
              title="Payments"
            />
            <ServiceItem
              serviceUrl={ arServiceCreditMemoPath(businessId) }
              title="Credit Memo"
            />
            <ServiceItem
              serviceUrl={ arServiceCustomersPath(businessId) }
              title="Customers"
            />
            <ServiceItem
              serviceUrl={ arServiceVendorProfilePath(businessId) }
              title="My Vendor Profile"
            />
            <ServiceItem
              serviceUrl={ arServiceSettingsPath(businessId) }
              title="Settings"
            />
          </ServiceSubMenu>
        )
      }
    </ModuleHeaderMenu>
  );
};

export default RevenueReconciliationItem;
