module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.isCaresActPage) {
        __out.push('\n<header class=\'header\'>\n  <div class="header-menu header-login">\n    <a class=\'header-logo-sign-up\' href=\'/\'>\n      <img src="');
        __out.push(__sanitize(configData.logoURL));
        __out.push('" class="width-80px-important">\n    </a>\n  </div>\n  <div class="relative header-covid">\n    <span class="font-24 font-bold">CARES Act Calculator</span>\n  </div>\n</header>\n');
      } else {
        __out.push('\n<header class=\'header header--in\'>\n  <div class="header-menu header-login">\n    <a class=\'header-logo-sign-up\' href=\'/\'>\n      ');
        if (window.app_logo_url) {
          __out.push('\n      <img src="');
          __out.push(__sanitize(window.app_logo_url));
          __out.push('" class="width-80px-important">\n      ');
        } else {
          __out.push('\n      <img src="');
          __out.push(__sanitize(configData.logoURL));
          __out.push('" class="width-80px-important">\n      ');
        }
        __out.push('\n    </a>\n  </div>\n</header>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}