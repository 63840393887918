import React from 'react';

import duplicatedTransactionTopImg from '@assets/images/banking_accounts/duplicated-transaction-top.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const DuplicatedTransactionTopIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Expense icon"
      src={ duplicatedTransactionTopImg }
      { ...props }
    />
  );
};

export default DuplicatedTransactionTopIcon;
