module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var credit_card, i, len, ref;
    
      __out.push('<div class="modal fade">\n  <div class="modal-dialog billing-credit-card-select-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n        <h4 class="modal-title">Select Existing Credit Card</h4>\n      </div>\n      <div class="modal-body modern-body">\n        ');
    
      ref = this.credit_cards;
      for (i = 0, len = ref.length; i < len; i++) {
        credit_card = ref[i];
        __out.push('\n        <div class="credit-card-selector pointer" data-card-id="');
        __out.push(__sanitize(credit_card.id));
        __out.push('">\n          <div class="card-number-div">\n            <div class="middle-avatar-wrapper">\n              <span class="icon icon-credit-card-account font-20">\n                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n              </span>\n            </div>\n            <span class="font-14">**** **** **** ');
        __out.push(__sanitize(credit_card.last4));
        __out.push('</span>\n          </div>\n          <div class="button-region">\n            <button type=\'button\' class="btn btn-blue select-card-btn" data-id="');
        __out.push(__sanitize(credit_card.id));
        __out.push('">Select</button>\n          </div>\n        </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}