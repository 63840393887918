import React, { useCallback } from 'react';

import { getAccountsPayableServiceDocument } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ChatIcon } from '@src/components/utils/icomoon';

type TSendMessageActionProps = {
  document: IAchTransaction,
}

const SendMessageAction = ({
  document,
}: TSendMessageActionProps) => {
  const handleSelect = useCallback(() => {
    if (!document.serviceDocumentIds) return;

    getAccountsPayableServiceDocument(document.serviceDocumentIds[0])
      .then((res) => {
        const documentId = res.documentId;
        Backbone.history.navigate(`/document/${documentId}/chat`, { trigger: true });
      });
  }, [document.serviceDocumentIds]);

  return (
    <ActionsDropdown.Action
      icon={ <ChatIcon fontSize={ 17 } variant="round" /> }
      title="Send Message"
      onSelect={ handleSelect }
    />
  );
};

export default SendMessageAction;
