module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">\n  <div class="avatar-wrapper">\n    ');
    
      if (this.avatar_url) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(this.avatar_url));
        __out.push('"/>\n    ');
      } else {
        __out.push('\n    <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</td>\n<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.issues_count));
    
      __out.push('</td>\n<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.qbo_company_name ? this.qbo_company_name : '--'));
    
      __out.push('</td>\n<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.last_qbo_synced_at ? moment(this.last_qbo_synced_at).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '--'));
    
      __out.push('</td>\n<td class="');
    
      if (this.qbo_connected) {
        __out.push(__sanitize('detail-row'));
      }
    
      __out.push('">\n  <div class="m-l-10 qbo-status-icon-column"></div>\n</td>\n<td>\n  <div class="relative pointer">\n    <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="business-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <i class=\'icon icon-3-dots font-20\'></i>\n    </button>\n    ');
    
      if (this.qbo_connected) {
        __out.push('\n    <div class="business-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="business-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">  \n      <a class="dropdown-item font-16 resync-business-js">\n        <span class="icon icon-qbo-on font-24"></span>\n        <span class="item-text">Re-sync</span>\n      </a>\n      <a href="/quickbooks_center/');
        __out.push(__sanitize(this.id));
        __out.push('" class="dropdown-item font-16">\n        <i class="icon icon-qbo-error in-red-600 font-24"></i>\n        <span class="item-text">View Open Issues</span>\n      </a>\n      <a class="dropdown-item font-16 disconnect-qbo-js ');
        if (!this.has_admin_role) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('">\n        <i class="icon icon-reject-invite in-red-600 font-40"></i>\n        <span class="in-red-600 item-text">Disconnect from QuickBooks</span>\n      </a>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="business-action-dropdown dropdown-menu dropdown-menu-right qbo-connect-large" aria-labelledby="business-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n      <a class="dropdown-item qbo-connect-link-medium connect-qbo-js"></a>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}