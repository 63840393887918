import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetDwollaCustomersByBusinessParams,
  IGetDwollaCustomersByVendorParams,
  getDwollaCustomersByBusiness,
  getDwollaCustomersByVendor,
} from '@src/requests/dwolla_customer';
import { IDwollaCustomer } from '@src/types/dwolla_customer';

const useGetDwollaCustomersByBusiness = (
  params: IGetDwollaCustomersByBusinessParams,
  enabled?: boolean,
) => {
  return useQuery<IDwollaCustomer, Error>(
    [QueryKey.dwollaCustomersByBusiness, params],
    () => getDwollaCustomersByBusiness(params),
    {
      enabled: enabled === undefined || enabled,
    },
  );
};

const useGetDwollaCustomersByVendor = (params: IGetDwollaCustomersByVendorParams) => {
  return useQuery<IDwollaCustomer, Error>(
    [QueryKey.dwollaCustomersByVendor, params],
    () => getDwollaCustomersByVendor(params),
    {
      enabled: Boolean(params.vendorId),
    },
  );
};

export {
  useGetDwollaCustomersByBusiness,
  useGetDwollaCustomersByVendor,
};
