import { UseMutationResult } from 'react-query';

interface IBulkMutationParams<TRequestParams, TRequestResponse = void> {
  params: TRequestParams[],
  onStepFailed: (error: Error, params: TRequestParams) => void,
  onStepProcessed: (response: TRequestResponse, params: TRequestParams) => void,
}

type TBulkMutation<TRequestParams, TRequestResponse = void> = UseMutationResult<
  IBulkMutationReturn<TRequestResponse>,
  Error,
  IBulkMutationParams<TRequestParams, TRequestResponse>
>

interface IBulkMutationReturn<TRequestResponse = void> {
  responses: TRequestResponse[],
  errors: Error[],
}

type TMakeBulkRequestReturn <TRequestParams, TRequestResponse> = (
  params: IBulkMutationParams<TRequestParams, TRequestResponse>
) => Promise<IBulkMutationReturn<TRequestResponse>>

const makeBulkRequest = <TRequestParams, TRequestResponse = void>(
  request: (p: TRequestParams) => Promise<TRequestResponse>,
): TMakeBulkRequestReturn<TRequestParams, TRequestResponse> => {
  return (
    args: IBulkMutationParams<TRequestParams, TRequestResponse>,
  ): Promise<IBulkMutationReturn<TRequestResponse>> => {
    // Stack requests to execute one by one sequentially
    // Pass resolved promise as initial value for `reduce` and
    // request promises are chained on the initial promise.
    // Returned promise will be resolved when all request promises in the chain will be resolved.
    return args.params.reduce(
      (promise, requestParams) => {
        return promise.then((result: IBulkMutationReturn<TRequestResponse>) => {
          // Run request and return promise 9add it to the chain
          return request(requestParams).then((response: TRequestResponse) => {
            // Request is succeeded
            args.onStepProcessed(response, requestParams);
            // Collect responses
            return {
              ...result,
              responses: [...result.responses, response],
            };
          }).catch((error) => {
            // Catch and collect request error
            args.onStepFailed(error, requestParams);
            return {
              ...result,
              errors: [...result.errors, error],
            };
          });
        });
      },
      Promise.resolve({
        responses: [],
        errors:    [],
      } as IBulkMutationReturn<TRequestResponse>),
    );
  };
};

export {
  IBulkMutationParams,
  IBulkMutationReturn,
  TBulkMutation,
  makeBulkRequest,
};
