import React, { FC, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';

import Tooltip from '@src/components/ui_v2/tooltip';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import useLinkedBusinessesModal from './linked_business_modal';

import styles from '../styles.module.scss';

const AddBusiness: FC<{businesses?: IBusiness[]}> = ({ businesses }) => {
  const editModel = Boolean(businesses);
  const [selected, setSelected] = useState<IBusiness[]>(businesses || []);
  const linkedBusinesses = useLinkedBusinessesModal();
  const { watch, setValue, formState: { errors, isSubmitted } } = useFormContext();

  const handleDone = (arr: IBusiness[]) => {
    setSelected(arr);
    setValue('businessIds', arr.map((i) => i.id), { shouldValidate: isSubmitted });
  };

  const linkedBusinessIds = selected.map((i) => i.id);

  const isSubAccount = watch('isSubAccount');
  const parentCategory = watch('parentCategory');
  const disabled = isSubAccount === 'true' && !parentCategory;

  const btnText = editModel ? 'Edit' : 'Add';

  let props = {};
  if (isSubAccount === 'true' && parentCategory) {
    props = { coaId: parentCategory };
  }

  return (
    <>
      <div>
        <div className={ styles['business-title'] }>
          <span>Business(es)</span>
          {
            disabled ? (
              <Tooltip.Hover content="Please select parent category first">
                <button className={ styles['add-business-btn'] } disabled={ disabled } type="button">{btnText}</button>
              </Tooltip.Hover>
            ) : <button className={ styles['add-business-btn'] } type="button" onClick={ linkedBusinesses.open }>{btnText}</button>
          }
        </div>

        <div className={ styles['business-listing-data'] }>
          {selected.map((i) => (
            <div key={ i.id } className={ styles['business-item'] }>
              {
                (i.avatarUrl || i.avatar?.avatarUrl)
                  ? <img alt="Business avatar" className="small-avatar" src={ i.avatarUrl || i.avatar?.avatarUrl } />
                  : <BusinessDefaultAvatarIcon fontSize={ 25 } />
              }
              <span>
                { i.displayName }
              </span>
            </div>
          ))}
        </div>

        {
          errors.businessIds && (
            <p className={ styles['form-field-error'] }>
              <WarningIcon />
              {' '}
              { errors.businessIds.message }
            </p>
          )
        }
      </div>
      <linkedBusinesses.Component
        { ...linkedBusinesses.props }
        { ...props }
        editModel={ editModel }
        linkedBusinessIds={ linkedBusinessIds }
        onDone={ handleDone }
      />
    </>

  );
};

export default AddBusiness;
