module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td>\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.image_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.image_url));
        __out.push(' class=\'small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <span class="m-l-14">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n  ');
    
      if (this.hasNoW9Information) {
        __out.push('\n  <span class="has-no-w9-information-error"></span>\n  ');
      }
    
      __out.push('\n  </div>\n</td>\n<td>');
    
      __out.push(__sanitize(this.legal_entity_name));
    
      __out.push('</td>\n<td>');
    
      __out.push(__sanitize(this.address));
    
      __out.push('</td>\n<td>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('<br>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</td>\n');
    
      if (this.tin) {
        __out.push('\n<td>*****');
        __out.push(__sanitize(this.tin));
        __out.push('</td>\n');
      } else {
        __out.push('\n<td></td>\n');
      }
    
      __out.push('\n<td>');
    
      __out.push(__sanitize(this.transactions));
    
      __out.push('</td>\n<td>');
    
      __out.push(__sanitize(this.ach));
    
      __out.push('</td>\n<td>');
    
      __out.push(__sanitize(this.check));
    
      __out.push('</td>\n<td>');
    
      __out.push(__sanitize(this.total));
    
      __out.push('</td>\n<td class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right vendor-action-menu">\n    <a href="');
    
      __out.push(__sanitize(this.vendorProfileURL));
    
      __out.push('" class="dropdown-item view-vendor-profile-js pointer display-flex align-items-center font-14">\n      <span class="icon-Payee-profile width-20px"></span>\n      <span class="m-l-10">View Vendor Profile</span>\n    </a>\n    <div class="dropdown-item download-w9-document-js pointer display-flex align-items-center font-14">\n      <span class="icon-W9-info width-20px"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="m-l-10">Download W-9 Document</span>\n    </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}