import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ITimesIconProps extends Omit<IFaIconProps, 'icon'> {}

const TimesIcon = (props: ITimesIconProps) => {
  return (
    <FaIcon
      icon="times"
      { ...props }
    />
  );
};

export default TimesIcon;
