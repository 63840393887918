import { TBankAccountReconciliationItemType } from '@src/types/bank_account_reconciliations';

interface ItemProp {
  title: string,
  tooltip?: string,
  transactionType?: string,
}

type ItemProps = { [k in TBankAccountReconciliationItemType]: ItemProp }

const Values: ItemProps = {
  bank_feed_verification: {
    title:   'Verify Bank Feed',
    tooltip: 'This step ensures that the bank feed in Docyt has exactly the same set of transactions as seen in bank statement. Even live bank feed could miss transactions or have duplicate transactions due to technical issues on bank side with bank connections',
  },
  uncategorized_transactions: {
    title:           'Resolve Uncategorized Transactions',
    tooltip:         'Docyt pushes a transaction in your accounting software only after it has been categorized. If your uncategorized transaction counter is non-zero then your accounting software ledger balance will not match your bank statement ending balance.',
    transactionType: 'Uncategorized Transactions',
  },
  flagged_transactions: {
    title:           'Resolve Flagged Transactions',
    tooltip:         'Flagged transactions are ones where the accountant is requesting more information from the business/department owner. Some of these transactions maybe common with uncategorized transactions as the accountant may not have enough information to properly categorize the transaction. It is a good practice to keep this counter down to zero.',
    transactionType: 'Flagged Transactions',
  },
  unmatched_transfers: {
    title:           'Resolve Unmatched Transfers',
    tooltip:         'Sometimes due to transaction descriptions, the credit and debit transactions during inter-bank transfers may not get associated automatically. This may result in incorrect reconciliation. Make sure that there are no unmatched transfers.',
    transactionType: 'Unmatched Transfers',
  },
  uncleared_bill_payments: {
    title:           'Acknowledge Uncleared Expense and Revenue',
    tooltip:         'This contains the list of paid invoices or receipts that have not cleared from the bank. They might need to be voided or deleted if they are not expected to clear anymore.',
    transactionType: 'Uncleared Expense and Revenue',
  },
  uncleared_transfers: {
    title:           'Acknowledge Uncleared Transfers',
    tooltip:         'This list displays transfers that have not yet cleared the bank in this period. Typically, this occurs when a payment is reflected in your bank account a few days after it was initially deposited.',
    transactionType: 'Uncleared Transfers',
  },
  excluded_transactions: {
    title:           'Acknowledge Excluded Transactions',
    tooltip:         'Documents provide a financial record of each event or activity in the business, and therefore ensure the accuracy and completeness of transactions. It also required to support your tax returns.',
    transactionType: 'Excluded Transactions',
  },
  not_pushed_transactions: {
    title:           'Acknowledge Transactions not Pushed to Ledger',
    tooltip:         "When the accounting software is receiving data from other third party tools (example bill.com, Expensify, payroll systems), you may want such transactions to be skipped from Docyt's categorization process. This avoids duplicates getting into the accounting software. The settings area in Docyt's Reconciliation Center module allows you to create advanced rules to skip relevant transactions. Make sure during your review of these transactions, that the third party software is actually sending transactions correctly in the accounting software.",
    transactionType: 'Transactions not Pushed to Ledger',
  },
  undocumented_transactions: {
    title:   'Undocumented Transactions',
    tooltip: 'This step is optional but highly recommended. It ensures that you acknowledge all undocumented transactions. Docyt recommends that you document all your bank and credit card transactions.',
  },
  difference: {
    title: 'Difference',
  },
  uncleared_bill_payments_carried_over: {
    title:           'Acknowledge Uncleared Expense and Revenue Carried Over',
    tooltip:         'This contains the list of paid invoices or receipts that have not cleared from the bank in last period and carrried over to this period. They might need to be voided or deleted if they are not expected to clear anymore.',
    transactionType: 'Uncleared Expense and Revenue',
  },
  uncleared_transfers_carried_over: {
    title:           'Acknowledge Uncleared Transfers Carried Over',
    tooltip:         'This list displays transfers that have not yet cleared the bank in last period and carrried over to this period. Typically, this occurs when a payment is reflected in your bank account a few days after it was initially deposited.',
    transactionType: 'Uncleared Transfers',
  },
  difference_carried_over: {
    title: 'Difference Carried Over',
  },
  additional_uncleared_balance: {
    title: 'Additional Uncleared Balance',
  },
  additional_uncleared_balance_carried_over: {
    title: 'Additional Uncleared Balance Carried Over',
  },
  pushed_by_docyt_transactions: {
    title: 'Transactions pushed to GL by Docyt',
  },
};

export default Values;
