/* eslint-disable import/order */
import React, { memo } from 'react';

import StartChatThread from './start_chat_thread';
import { FAQ, welcomeMessage } from '../constants';

import styles from '../styles.module.scss';

const NoChatBody =
() => {
  return (
    <div className={ styles['chat-no-message'] }>
      <div className={ styles['no-message-content'] }>
        <h2 className={ styles['title-text'] }>
          <strong>{welcomeMessage.title}</strong>
          {welcomeMessage.message}
        </h2>
      </div>
      <div className={ styles['quick-chat-buttons'] }>
        {FAQ.map((item) => (
          <StartChatThread
            key={ item.summary }
            buttonText={ item.summary }
            message={ item.message }

          />
        ))}
      </div>
    </div>
  );
};

export default memo(NoChatBody);
