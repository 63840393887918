import React from 'react';

import styles from './styles.module.scss';

interface ISideViewErrorProps {
  children: React.ReactNode,
}

const SideViewError = ({
  children,
}: ISideViewErrorProps) => {
  return (
    <h3 className={ styles.error }>
      { children }
    </h3>
  );
};

export default SideViewError;
