import React, { useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useBulkDestroyRevenueReconciliation } from '@src/hooks/queries/revenue_reconciliations';
import { TUUID } from '@src/types/common';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import Modal from '@src/components/ui/modal';
import { useItemsSelector } from '@src/components/utils_v2/items_selector';

import RelatedDepositsList from './related_deposits_list';

interface IBulkUnreconcileModalProps extends IUseModalProps {
  revenueReconciliationIds: TUUID[],
  paymentProcessorName: string,
}

const BulkUnreconcileModal = ({
  revenueReconciliationIds,
  isOpen,
  onCancel,
  onDone,
  paymentProcessorName,
}: IBulkUnreconcileModalProps): JSX.Element => {
  const bulkDestroy = useBulkDestroyRevenueReconciliation();
  const { reset: resetSelection } = useItemsSelector();
  const queryClient = useQueryClient();

  const handleDone = useCallback(() => {
    // If invalidate these queries before closing the modal (e.g. in the mutation
    // onSettled callback), then data will be reloaded, revenueReconciliationItem
    // from loaded data disappear and modal will be closed before showing results.
    queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
    queryClient.invalidateQueries(QueryKey.revenueServiceDocuments);

    resetSelection();
    onDone();
  }, [resetSelection, onDone, queryClient]);

  const bulkAction = useBulkActionModal({
    mutation: bulkDestroy,
    onDone:   handleDone,
  });
  const { runMutation } = bulkAction;

  const handleProceed = useCallback(() => {
    const destroyParams = revenueReconciliationIds.map((id) => ({ revenueReconciliationId: id }));
    runMutation(destroyParams);
  }, [runMutation, revenueReconciliationIds]);

  return (
    <>
      <Modal.Standard
        dialogClassName="modal-confirm"
        proceedTitle="Unreconcile"
        show={ isOpen }
        title="Unreconcile the deposit"
        onCancel={ onCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="related_transactions">
            <p>
              Are you sure you want to unreconcile the deposit?
              The following revenue matches with the selected deposit
            </p>
            <RelatedDepositsList
              paymentProcessorName={ paymentProcessorName }
              revenueReconciliationIds={ revenueReconciliationIds }
            />
          </div>
        )}
      </Modal.Standard>
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="deposits"
        title="Unreconcile Deposits"
      />
    </>
  );
};

const useBulkUnreconcileModal = makeUseModal(BulkUnreconcileModal);

export {
  IBulkUnreconcileModalProps,
  BulkUnreconcileModal,
  useBulkUnreconcileModal as default,
};
