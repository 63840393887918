import React from 'react';

import { uiStyleProps, IUIStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface ISearchFilterContainerProps extends
  React.ComponentPropsWithoutRef<'div'>,
  IUIStyleProps
{
  children: React.ReactNode,
}

const SearchFilterContainer = ({
  children,
  ...props
}: ISearchFilterContainerProps): JSX.Element => {
  const [classes, divProps] = uiStyleProps(styles['search-filter-container'], props);

  return (
    <div className={ classes } { ...divProps }>
      { children }
    </div>
  );
};

export default SearchFilterContainer;
