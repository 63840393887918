import React from 'react';

import pluralize from 'pluralize';

import { IBulkActionStatus } from './bulk_action_content';

import styles from './styles.module.scss';

interface IBulkActionResultProps<TRequestParams, TRequestResponse> {
  status: IBulkActionStatus<TRequestParams, TRequestResponse>,
  successResultText?: string
}

const BulkActionResult = <TRequestParams, TRequestResponse>({
  status: {
    failedCount,
    errors,
    successCount,
  },
  successResultText,
}: IBulkActionResultProps<TRequestParams, TRequestResponse>) => {
  return (
    <div className={ styles.result }>
      {successResultText
        ? (
          <p className="p-b-0">
            <span className={ styles['success-count'] }>{ successCount }</span>
            {' '}
            { pluralize('report', successCount) }
            {' '}
            { pluralize('was', successCount) }
            {' '}
            {successResultText}
          </p>
        ) : (
          <p className="p-b-0">
            <span className={ styles['success-count'] }>{ successCount }</span>
            {' '}
            { pluralize('object', successCount) }
            {' '}
            were changed successfully
          </p>
        )}
      {
        failedCount > 0 && (
          <>
            <p className="p-b-0">
              <span className={ styles['failed-count'] }>{ failedCount }</span>
              {' '}
              { pluralize('object', failedCount) }
              {' '}
              have failed with the following errors:
            </p>
            <ol>
              { errors.map(([, error], i) => (
                // There is no anything that may be used as key
                // eslint-disable-next-line react/no-array-index-key
                <li key={ i }>{ error }</li>
              )) }
            </ol>
          </>
        )
      }
    </div>
  );
};

export default BulkActionResult;
