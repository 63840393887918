module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.activeTab === 'CONTACTS') {
        __out.push('\n<a class="font-14 m-t-15 pointer pull-right add-employee-js business-header-btn"><i class="clients__action-icon icon icon-plus"></i> Add Employee</a>\n');
      }
    
      __out.push('\n');
    
      if (this.activeTab === 'CONTACTS' || this.isIndividualTabActive) {
        __out.push('\n<div class="m-t-2 contact-search-input-wrapper">\n<i class="fa fa-search font-20"></i>\n<input class="form-control contact-search-input" placeholder="Search by name, email or phone number...">\n</div>\n');
      }
    
      __out.push('\n');
    
      if (this.activeTab === 'DOCUMENTS') {
        __out.push('\n<div class="vault-top-menu">\n  <h2 class="font-bold font-22">Vault</h2>\n  <button class="btn btn-add-document font-22">\n    <i class="icon icon-plus"></i>\n  </button>\n</div>\n');
      }
    
      __out.push('\n<ul class="nav sub-header-menu m-b-30 business-section-header-menu">\n  ');
    
      if (this.isSupport) {
        __out.push('\n  <li class="dropdown ');
        __out.push(__sanitize(this.isSupportTabActive ? 'active' : ''));
        __out.push('">\n    <a class="dropdown-toggle pointer" data-toggle="dropdown">\n        SUPPORT\n        <span class="caret"></span>\n    </a>\n    <ul class="dropdown-menu">\n      <li><a href="/invoice_profiles">Invoice Profiles</a></li>\n      <li><a href="/ap_checks">Ap Checks</a></li>\n      <li><a href="/vendors">Vendors</a></li>\n      <li><a href="/transaction_extractor">Transaction Extractor</a></li>\n    </ul>\n  </li>\n  ');
      }
    
      __out.push('\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}