module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="flex-wrapper pointer person-avatar-wrapper">\n  <div class="person-avatar-without-border m-r-15">\n    ');
    
      if (this.avatarUrl) {
        __out.push('\n      <div class="avatar">\n        <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'clients__userpic-img\'>\n      </div>\n    ');
      } else {
        __out.push('\n      <i class=\'icon icon-smile in-blue-400\'></i>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="title-information">\n    <h2 class="font-32 font-light m-t-25 text-left">');
    
      __out.push(__sanitize(this.parsed_fullname));
    
      __out.push('</h2>\n  </div>\n</div>\n<ul class="nav sub-header-menu m-b-30 padding-20">\n  <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab === 'INFO' ? 'active' : ''));
    
      __out.push('"><a href="');
    
      __out.push(__sanitize(this.detailsUrl));
    
      __out.push('">INFO</a></li>\n  <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab === 'DOCUMENTS' ? 'active' : ''));
    
      __out.push('""><a href="');
    
      __out.push(__sanitize(this.documentsUrl));
    
      __out.push('">DOCUMENTS</a></li>\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}