import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ITableSectionFooterProps<T extends React.ElementType> {
  as?: T,
}

const TableSectionFooter = <T extends React.ElementType = 'footer'>({
  as,
  children,
  className,
  ...props
}: ITableSectionFooterProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof ITableSectionFooterProps<T>>,
) => {
  const classes = classNames(styles['table-section-footer'], className);

  const Component = as || 'footer';

  return (
    <Component className={ classes } { ...props }>
      { children }
    </Component>
  );
};

export default TableSectionFooter;
