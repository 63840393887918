import React from 'react';

import { IChartOfAccount } from '@src/types/chart_of_accounts';

import Table from '@src/components/ui/table';
import TableBody from '@src/components/ui/table/body';
import TableHead from '@src/components/ui/table/head';
import TableRow from '@src/components/ui/table/row';
import TableCell from '@src/components/ui_v2/table/cell';

import styles from './styles.module.scss';

interface IChartOfAccountProps {
  selectedItem: string | null | undefined,
  setOpenChartOfAccount: (value: boolean) => void,
  handleChangeFormula: any,
  chartOfAccount: any,
  coaForMonth: boolean
}

const ChartOfAccounts = ({
  chartOfAccount,
  selectedItem,
  setOpenChartOfAccount,
  handleChangeFormula,
  coaForMonth,
}: IChartOfAccountProps) => {
  const setCoaValue = (coa: IChartOfAccount) => {
    const value = coaForMonth ? 'chart_of_account_for_month' : 'chart_of_account';
    const section = coaForMonth ? 'MONTH' : 'year';
    const selectedCoa = { value, section };
    handleChangeFormula(selectedCoa, coa);
    return setOpenChartOfAccount(false);
  };

  return (
    <div>
      <div>
        <p>
          The budget for
          <strong>
            {' '}
            { selectedItem }
            {' '}
          </strong>
          will be calculated by the
          <strong> percentage of a chart of account. </strong>
          <br />
          Select a chart account below to continue.
        </p>
      </div>
      <div className={ styles['import-budget-modal'] }>
        <Table>
          <TableHead>
            <TableRow>
              <th>Code</th>
              <th>Chart of Account</th>
              <th>Account Type</th>
              <th>Detail Type</th>
            </TableRow>
          </TableHead>
          <TableBody>
            {chartOfAccount.map((coa: IChartOfAccount) => (
              <TableRow
                key={ coa.id }
                className="cursor-pointer"
                style={ { transition: 'box-shadow 0.3s' } }
                onClick={ () => setCoaValue(coa) }
              >
                <TableCell>{ coa.id }</TableCell>
                <TableCell>
                  { coa.id }
                  :
                  { coa.displayName }
                </TableCell>
                <TableCell>{ coa.accTypeName }</TableCell>
                <TableCell>{ coa.subTypeCode }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ChartOfAccounts;
