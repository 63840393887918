import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { HeaderHelpCenter } from '@src/components/utils/icomoon';

import Actions from './actions';

interface IHelpCenterProps {
  businessId?: TID,
  isClientView: boolean,
}

const HelpCenterPage = ({
  businessId,
  isClientView,
}: IHelpCenterProps): JSX.Element => {
  return (
    <Root>
      <ActionsDropdown
        className="header-item-dropdown"
        title={ <HeaderHelpCenter fontSize={ 24 } /> }
        titleClassName="header__notifications"
      >
        <Actions
          businessId={ businessId }
          isClientView={ isClientView }
        />
      </ActionsDropdown>
    </Root>
  );
};

export default HelpCenterPage;
