import React from 'react';

import { FinancialReportsIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

const DashboardSideMenuHeader = (): JSX.Element => {
  return (
    <div className="service-header">
      <FinancialReportsIcon
        className={ styles['side-menu-icon'] }
      />
      <span className="font-16 m-l-12 font-bold">Dashboard</span>
      <span className={ styles['beta-tag'] }>Beta</span>
    </div>
  );
};

export default DashboardSideMenuHeader;
