import React, { forwardRef } from 'react';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import PercentInput, { IPercentInputProps } from '../inputs/percent_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface IPercentFieldProps extends
  IPercentInputProps,
  Omit<IFormFieldWrapperProps, 'children'>,
  IUIStyleProps
{
}

/**
 * Field to input percent value
 *
 * @visibleName Form.PercentField
 */
const PercentField = forwardRef<HTMLInputElement, IPercentFieldProps>(({
  error,
  hint,
  label,
  mark,
  ...props
}: IPercentFieldProps, ref): JSX.Element => {
  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <PercentInput
        ref={ ref }
        { ...props }
      />
    </Wrapper>
  );
});

PercentField.displayName = 'PercentField';

export default PercentField;
