import React, { useCallback, useState } from 'react';

import { useForwardToBalanceSheetStatements } from '@src/hooks/queries/documents';
import { IBusiness } from '@src/types/businesses';

import { IForwardToBalanceSheetStatementsFormValues } from '@src/components/common/forward_document/types';
import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';

import Form from './form';

interface IForwardToBalanceSheetStatementsModalProps {
  business: IBusiness,
  documentIds: number[],
  show?: boolean,
  onCancel: () => void,
  onContinue: (data: IForwardToBalanceSheetStatementsFormValues) => void,
}

const ForwardToBalanceSheetStatementsModal = ({
  business,
  documentIds,
  show,
  onCancel,
  onContinue,
}: IForwardToBalanceSheetStatementsModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const { mutate: forwardToBalanceSheetStatements } = useForwardToBalanceSheetStatements();

  const handleSubmit = useCallback((data: IForwardToBalanceSheetStatementsFormValues) => {
    if (documentIds.length > 1) {
      setErrorMsg(
        window.Docyt.Common.Constants.Messages
          .FORWARD_DOCUMENT_TO_BALANCE_SHEET_STATEMENTS_ERROR_MSG,
      );
      return;
    }

    forwardToBalanceSheetStatements(
      {
        id:               documentIds[0],
        businessId:       business.id,
        chartOfAccountId: data.chartOfAccountId,
        date:             data.date,
      },
      {
        onSuccess: () => { onContinue(data); },
        onError:   (error) => { setErrorMsg(error.message); },
      },
    );
  }, [business.id, documentIds, forwardToBalanceSheetStatements, onContinue]);

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <Modal.Form
        dialogClassName="forward-to-balance-sheet-statement-modal"
        proceedTitle="Continue"
        show={ show }
        title="Forward to Balance Sheet Documents"
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <Form
            businessId={ business.id }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        ) }
      </Modal.Form>
    </>
  );
};

export {
  IForwardToBalanceSheetStatementsFormValues,
  ForwardToBalanceSheetStatementsModal as default,
};
