import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { addReportTypeValidation, IAddReportTypeValues } from './schema';

interface IAddReportTypeFormProps {
  formId: string
  onSubmit: (data: IAddReportTypeValues) => void;
}

const AddReportTypeForm = ({ formId, onSubmit }: IAddReportTypeFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IAddReportTypeValues>({
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(addReportTypeValidation),
  });

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.TextField
        error={ errors.name?.message }
        label="Name"
        placeholder="Type Here"
        { ...register('name') }
      />
    </Form>
  );
};

export default AddReportTypeForm;
