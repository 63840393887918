import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import { getNotificationPreference, getFinancialInsights, updateNotificationPreference, updateUserSettings, upsertNotificationPreferences } from '@src/requests/financial_insight_service_documents';
import { IFinancialInsightsCollection, INotificationPreference, IUpsertNotificationParams, IUpdateUserSettings, IGetFinancialInsightsResponse } from '@src/types/financial_insight';

const useGetNotificationPreference = () => {
  return useQuery<INotificationPreference, Error>(
    [QueryKey.notificationPreference],
    () => getNotificationPreference(),
  );
};

const useUpdateNotificationPreference = () => {
  return useMutation<void, Error, INotificationPreference>(
    updateNotificationPreference,
  );
};

const useUpsertNotificationPreferences = () => {
  return useMutation<void, Error, IUpsertNotificationParams>(
    upsertNotificationPreferences,
  );
};

const useUpdateUserSettings = () => {
  return useMutation<void, Error, IUpdateUserSettings>(
    updateUserSettings,
  );
};

const useGetFinancialInsights = createUseGetInfiniteCollection<
    IFinancialInsightsCollection,
    any,
    IGetFinancialInsightsResponse
>({
  queryKey: QueryKey.financialInsights,
  request:  getFinancialInsights,
});

export {
  useUpsertNotificationPreferences,
  useGetNotificationPreference,
  useUpdateNotificationPreference,
  useUpdateUserSettings,
  useGetFinancialInsights,
};
