import { IBalanceSheetInformation } from '@src/types/balance_sheet_information';
import {
  IBalanceSheetStatement,
  IStatementsFilter,
  TStatementSortColumn,
} from '@src/types/balance_sheet_statements';
import { TID, TDate, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetBalanceSheetInformationsParams {
  businessId: TID,
  months?: number,
  monthEndDocument?: boolean,
  filter?: IStatementsFilter,
  page?: number,
  orderColumn?: TStatementSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetBalanceSheetInformationsResponse {
  meta: {
    totalCount: number,
  },
  collection: IBalanceSheetInformation[],
}

interface IBalanceSheetStatementParams {
  id: TID,
  businessId: TID,
}

interface IMarkAsNoAvailableBalanceSheetStatementParams {
  businessId: TID,
  chartOfAccountId: TID,
  date?: string,
}

interface IGetBalanceSheetStatementsResponse {
  meta: {
    totalCount: number,
    startImportDate: TDate,
    chartOfAccountName: string,
  },
  collection: IBalanceSheetStatement[],
}

interface IGetBalanceSheetStatementsParams {
  businessId: TID,
  chartOfAccountId?: TID,
  filter?: IStatementsFilter,
  page?: number,
  orderColumn?: TStatementSortColumn,
  orderDirection?: TOrderDirection,
}

const getBalanceSheetInformations = (
  params: IGetBalanceSheetInformationsParams,
): Promise<IGetBalanceSheetInformationsResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_informations',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.balanceSheetStatements,
    } as IGetBalanceSheetInformationsResponse;
  });
};

const postMarkAsNoAvailableBalanceSheetStatement = (
  params: IMarkAsNoAvailableBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/mark_as_no_available',
    underscoreKeys(params),
  );
};

const postUnmarkNoStatementAvailable = (
  params: IBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/balance_sheet_statements/${params.id}/unmark_no_statement_available`,
    underscoreKeys(params),
  );
};

const getBalanceSheetStatements = (
  params: IGetBalanceSheetStatementsParams,
): Promise<IGetBalanceSheetStatementsResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_statements',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.balanceSheetStatements,
    } as IGetBalanceSheetStatementsResponse;
  });
};

export {
  IGetBalanceSheetInformationsParams,
  IGetBalanceSheetInformationsResponse,
  getBalanceSheetInformations,
  IMarkAsNoAvailableBalanceSheetStatementParams,
  postMarkAsNoAvailableBalanceSheetStatement,
  IBalanceSheetStatementParams,
  postUnmarkNoStatementAvailable,
  getBalanceSheetStatements,
  IGetBalanceSheetStatementsParams,
  IGetBalanceSheetStatementsResponse,
};
