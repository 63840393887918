import React, { useMemo } from 'react';

import { useGetVendorBusinessEmailVerified, useGetReceivableFundingSource } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import Table from '@src/components/ui/table';
import { CircleCheckGreenIcon } from '@src/components/utils/icomoon';
import { getVendorAddressText, notAvailableText } from '@src/components/vendors/utils';

import styles from './styles.module.scss';

interface IReviewMergeVendorTableProps {
  localVendor: IBusinessVendor,
  globalVendor: IBusinessVendor,
}

const ReviewMergeVendorTable = ({
  localVendor,
  globalVendor,
}: IReviewMergeVendorTableProps) => {
  const emailQuery = useGetVendorBusinessEmailVerified(
    globalVendor.id,
  );
  const isEmailVerified = useMemo(() => {
    return emailQuery.data?.verified;
  }, [emailQuery]);
  const fundingSourceQuery = useGetReceivableFundingSource(
    globalVendor.id,
  );
  const dwollaFundingSource = useMemo(() => {
    return fundingSourceQuery.data;
  }, [fundingSourceQuery]);

  const localVendorAddressText = getVendorAddressText(localVendor);
  const globalVendorAddressText = getVendorAddressText(globalVendor);

  return (
    <Table
      fontSize={ 12 }
      wrapperClassName={ styles['review-merge-vendor-table-wrapper'] }
    >
      <Table.Body>
        <Table.Row>
          <Table.Cell widthPercent={ 20 }>Name</Table.Cell>
          <Table.Cell widthPercent={ 30 }>
            <div><b>{ localVendor.name }</b></div>
            {
              globalVendor.isClaimed && (
                <div className="font-11">
                  {localVendor.docytId ? `@${localVendor.docytId}` : notAvailableText}
                </div>
              )
            }
          </Table.Cell>
          <Table.Cell widthPercent={ 30 }>
            <div><b>{ globalVendor.name }</b></div>
            {
              globalVendor.isClaimed && (
                <div className="font-11">
                  {globalVendor.docytId ? `@${globalVendor.docytId}` : notAvailableText}
                </div>
              )
            }
          </Table.Cell>
          <Table.Cell widthPercent={ 20 }>
            {
              localVendor.name === globalVendor.name
              && localVendor.docytId && globalVendor.docytId
              && localVendor.docytId === globalVendor.docytId
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Phone</Table.Cell>
          <Table.Cell>
            { localVendor.phone ? localVendor.phone : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            { globalVendor.phone ? globalVendor.phone : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            {
              localVendor.phone && globalVendor.phone
              && localVendor.phone === globalVendor.phone
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email</Table.Cell>
          <Table.Cell>
            { localVendor.email ? localVendor.email : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            { globalVendor.email ? globalVendor.email : notAvailableText }
            { isEmailVerified && (
              <CircleCheckGreenIcon
                className="m-l-4 vertical-align-middle"
              />
            )}
          </Table.Cell>
          <Table.Cell>
            {
              localVendor.email && globalVendor.email
              && localVendor.email === globalVendor.email
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Website</Table.Cell>
          <Table.Cell>
            { localVendor.website ? localVendor.website : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            { globalVendor.website ? globalVendor.website : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            {
              localVendor.website && globalVendor.website
              && localVendor.website === globalVendor.website
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Address</Table.Cell>
          <Table.Cell>
            {
              localVendorAddressText || notAvailableText
            }
          </Table.Cell>
          <Table.Cell>
            {
              globalVendorAddressText || notAvailableText
            }
          </Table.Cell>
          <Table.Cell>
            {
              localVendorAddressText && globalVendorAddressText
              && localVendorAddressText === globalVendorAddressText
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Banking Acc No.</Table.Cell>
          <Table.Cell>
            { localVendor.dwollaFundingSource?.last4
              ? `xxxxxxxx${localVendor.dwollaFundingSource?.last4}`
              : notAvailableText }
          </Table.Cell>
          <Table.Cell>
            { dwollaFundingSource?.last4
              ? `xxxxxxxx${dwollaFundingSource?.last4}`
              : notAvailableText }
            {(globalVendor.achStatus === 'activated') && (
              <CircleCheckGreenIcon
                className="m-l-4 vertical-align-middle"
              />
            )}
          </Table.Cell>
          <Table.Cell>
            {
              localVendor.dwollaFundingSource?.last4 && dwollaFundingSource?.last4
              && localVendor.dwollaFundingSource?.last4 === dwollaFundingSource?.last4
                ? <div className="matched-status matched">Matched</div>
                : <div className="matched-status unmatched">Unmatched</div>
            }
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default React.memo(ReviewMergeVendorTable);
