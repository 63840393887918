import React from 'react';

import { ChatIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IChatCounterProps extends
  Omit<React.ComponentPropsWithoutRef<typeof ChatIcon>, 'variant'>
{
  messagesCount?: number,
}

const ChatCounter = ({
  messagesCount,
  ...props
}: IChatCounterProps) => {
  const icon = !messagesCount || messagesCount === 0
    ? <ChatIcon variant="empty" { ...props } />
    : <ChatIcon variant="filled" { ...props } />;

  const displayMessagesCount = messagesCount || 0;

  return (
    <span className={ styles['chat-counter'] }>
      { icon }
      { displayMessagesCount > 0 && (
        <span className={ styles['chat-counter-unread'] }>
          { displayMessagesCount > 9 ? '9+' : displayMessagesCount }
        </span>
      ) }
    </span>
  );
};

export default ChatCounter;
