import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';
import { IBusiness } from '@src/types/businesses';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { RestoreDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmRestoreDocumentModalParams extends IUseModalParams {
  business?: IBusiness,
  document: IInboxServiceDocument
}

interface IUseConfirmRestoreDocumentModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
}

const ConfirmRestoreDocumentTitle = (
  { business, document }: { business?: IBusiness, document: IInboxServiceDocument },
) => (
  <>
    { document.name }
    { ' ' }
    will be restored to
    { ' ' }
    { business?.name || 'Personal' }
    { ' ' }
    Inbox.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmRestoreDocumentModal = ({
  business,
  document,
  ...modalParams
}: IUseConfirmRestoreDocumentModalParams): IUseConfirmRestoreDocumentModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'primary',
      confirmTitle: 'Restore',
      icon:         <RestoreDocumentIcon />,
      text:         (
        <ConfirmRestoreDocumentTitle business={ business } document={ document } />
      ),
      title: `Restore ${document.name}?`,
    },
  };
};

export {
  useConfirmRestoreDocumentModal,
};
