module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="expense-clickable pointer">\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.vendor_avatar_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.vendor_avatar_url));
        __out.push(' class=\'small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <span class="m-l-14">');
    
      __out.push(__sanitize(this.vendor_name));
    
      __out.push('</span>\n  </div>\n</td>\n');
    
      if (this.type === 'submitted' || this.type === 'reimbursed') {
        __out.push('\n<td class="expense-clickable relative pointer">\n  <span class="invoice-status-panel font-bold ');
        __out.push(__sanitize(this.invoice_state ? this.invoice_state : 'processing'));
        __out.push('">');
        __out.push(__sanitize(this.expense_status));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n<td class="expense-clickable relative pointer">\n  ');
    
      if (this.expense_category) {
        __out.push(__sanitize(this.expense_category.name));
      }
    
      __out.push('\n</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.payment_date ? moment(this.payment_date).format(Docyt.Common.Constants['US_DATE_FORMAT']) : ''));
    
      __out.push('</td>\n');
    
      if (this.type === 'reimbursed') {
        __out.push('\n<td class="expense-clickable pointer">');
        __out.push(__sanitize(this.payment_date ? moment(this.paid_at).format(Docyt.Common.Constants['US_DATE_FORMAT']) : ''));
        __out.push('</td>\n<td class="expense-clickable pointer">\n  ');
        if (this.payment_mode === configData.ap_payment_modes['DOCYT_ACH']) {
          __out.push('\n    Docyt ACH\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['DOCYT_CHECK']) {
          __out.push('\n    Docyt Check\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['BANK_CHECK']) {
          __out.push('\n    Bank Check\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['BANK_ACCOUNT']) {
          __out.push('\n    Bank Account\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['ADVANCED_SETTLEMENT']) {
          __out.push('\n    Advanced Settlement\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['CREDIT_CARD']) {
          __out.push('\n    Credit Card\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['CASH']) {
          __out.push('\n    Cash\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['PAID_EXTERNALLY']) {
          __out.push('\n    Externally\n  ');
        }
        __out.push('\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.type === 'uploaded') {
        __out.push('\n<td class="expense-clickable relative pointer">\n  <span class="expense-status-panel badge font-12 rejected">');
        __out.push(__sanitize(this.state === configData.expense_report_states['REJECTED'] ? 'Rejected' : ''));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n<td class="amount-column relative text-right font-bold">');
    
      __out.push(__sanitize(this.amountString));
    
      __out.push('</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}