import React from 'react';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { ITSDCollection } from '../hooks';
import SelectedAmount from '../selected_amount';
import Table from './table';

interface IMatchDocumentsExpensesListProps {
  collection: ITSDCollection,
  includeUnverified?: boolean,
}

const MatchDocumentsExpensesList = ({
  collection,
  includeUnverified,
}: IMatchDocumentsExpensesListProps) => {
  return (
    <Section.Provider section={ collection.section }>
      <TableSection>
        <TableSection.Caption>Expense Transactions</TableSection.Caption>
        <TableSection.Footer>
          <TableSection.PaginationInfo infiniteQuery={ collection.query } />
          <SelectedAmount selectedRecords={ collection.selectedRecords } />
          <TableSection.ClearFilter />
        </TableSection.Footer>
        <Table
          includeUnverified={ includeUnverified }
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(MatchDocumentsExpensesList);
