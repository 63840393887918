import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CountryOptions } from '@src/constants/country_options';
import { UsaStateOptions } from '@src/constants/usa_state_options';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

import Form from '@src/components/ui/form';

import ProfileAvatar from './profile_avatar';
import { businessProfileValidation, IBusinessProfileInput } from './schema';

interface IEditProfileModalFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IBusinessProfileInput>,
}

const EditProfileModalForm = ({
  formId,
  onSubmit,
}: IEditProfileModalFormProps): JSX.Element => {
  const business = useBusinessContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IBusinessProfileInput>({
    defaultValues: {
      displayName:    business.displayName,
      email:          business.email,
      name:           business.legalName,
      legalName:      business.legalName,
      addressCity:    business.addressCity,
      addressCountry: business.addressCountry,
      addressState:   business.addressState,
      addressStreet:  business.addressStreet,
      addressStreet2: business.addressStreet2,
      addressZip:     business.addressZip,
      phone:          business.phone,
    },
    resolver: yupResolver(businessProfileValidation),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className="update-profile-container text-center">
        <ProfileAvatar
          business={ business }
        />
        <Form.TextField
          { ...register('displayName') }
          label="Display Name"
        />
        <Form.TextField
          { ...register('legalName') }
          error={ errors.legalName?.message }
          label="Business Name"
        />
        <Form.TextField
          { ...register('email') }
          error={ errors.email?.message }
          label="Email"
        />
        <Form.TextField
          { ...register('phone') }
          label="Phone Number"
        />
        <Form.TextField
          { ...register('addressStreet') }
          error={ errors.addressStreet?.message }
          label="Street"
          placeholder="Street"
        />
        <Form.TextField
          id="address-street2"
          { ...register('addressStreet2') }
          placeholder="Street (Continued)"
        />
        <div className="profile-input-wrap">
          <div className="width-30-percent font-13" />
          <div className="business-address-wrapper" style={ { display: 'flex' } }>
            <div className="profile-input-wrap p-r-5">
              <Form.TextField
                { ...register('addressCity') }
                hideLabel
                error={ errors.addressCity?.message }
                placeholder="City"
              />
            </div>
            {
              watch('addressCountry') === 'US' ? (
                <div className="profile-input-wrap p-r-5">
                  <Form.SelectField
                    { ...register('addressState') }
                    hideLabel
                    options={ UsaStateOptions }
                  />
                </div>
              ) : (
                <div className="profile-input-wrap p-r-5">
                  <Form.TextField
                    { ...register('addressState') }
                    hideLabel
                    placeholder="State"
                  />
                </div>
              )
            }
            <div className="profile-input-wrap">
              <Form.TextField
                { ...register('addressZip') }
                hideLabel
                error={ errors.addressZip?.message }
                placeholder="Zip"
              />
            </div>
          </div>
        </div>
        <Form.SelectField
          { ...register('addressCountry') }
          options={ CountryOptions }
        />
      </div>
    </Form>
  );
};

export {
  IEditProfileModalFormProps,
  EditProfileModalForm as default,
};
