import React from 'react';

import { UseQueryResult } from 'react-query';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import TableSection from '@src/components/ui_v2/table_section';

import Table from './table';

interface IResolveRevenueReportProps {
  query: UseQueryResult<IRevenueServiceDocument, Error>;
}

const ResolveRevenueReportItems = ({
  query,

}: IResolveRevenueReportProps): JSX.Element => {
  return (
    <TableSection.Panel>
      <Table
        query={ query }
      />
    </TableSection.Panel>
  );
};

export default React.memo(ResolveRevenueReportItems);
