import React from 'react';

import { TID } from '@src/types/common';
import { IAccountsPayableStatus } from '@src/types/service_statuses';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';
import { UnapprovedIcon, UnpaidIcon, UnverifiedIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IAccountsPayableStatusProps {
  clientBusinessId: TID;
  status?: IAccountsPayableStatus,
}

const AccountsPayableStatus = ({
  clientBusinessId,
  status,
}: IAccountsPayableStatusProps) => {
  let content;
  let tooltip = '';
  if (status && status.countUnverified > 0) {
    tooltip += "<div class='accounts-payable-dashboard-tooltip unverified-tooltip'><div class='icon icon-unverified'><span class='path1'></span><span class='path2'></span><span class='path3'></span></div>"
      + `<span class='badge-counter'>${status.countUnverified} unverified</span></div>`;
  }
  if (status && status.countApproval > 0) {
    tooltip += "<div class='accounts-payable-dashboard-tooltip unapproved-tooltip'><div class='icon icon-unapproved'><span class='path1'></span><span class='path2'></span><span class='path3'></span></div>"
      + `<span class='badge-counter'>${status.countApproval} unapproved</span></div>`;
  }
  if (status && status.countReadyForPay > 0) {
    tooltip += "<div class='accounts-payable-dashboard-tooltip unpaid-tooltip'><div class='icon icon-unpaid'><span class='path1'></span><span class='path2'></span><span class='path3'></span></div>"
      + `<span class='badge-counter'>${status.countReadyForPay} unpaid</span></div>`;
  }
  if (!status || status.countQueued === 0) {
    content = (
      <span className={ styles['non-status'] }>-</span>
    );
  } else {
    content = (
      <>
        <a
          className={ styles['client-business-status'] }
          href={ `/businesses/${clientBusinessId}/accounts_payable/queue?type=queued` }
          role="button"
          tabIndex={ 0 }
        >
          { status.countQueued }
          { ' ' }
          { status.countQueued === 1 ? 'Invoice' : 'Invoices' }
        </a>
        <Tooltip
          as="p"
          className={ styles['accounts-payable-dashboard-tooltip'] }
          mr={ 10 }
          title={ tooltip }
        >
          <div className={ styles['accounts-payable-dashboard-badge-wrapper'] }>
            {
              status.countUnverified > 0 && (
                <div className={ styles['unverified-counts-badge'] }>
                  <UnverifiedIcon fontSize={ 10 } mt={ 1 } />
                  <span className={ styles['badge-counter'] }>{ status.countUnverified }</span>
                </div>
              )
            }

            {
              status.countApproval > 0 && (
                <div className={ styles['unapproved-counts-badge'] }>
                  <UnapprovedIcon fontSize={ 10 } mt={ 1 } />
                  <span className={ styles['badge-counter'] }>{ status.countApproval }</span>
                </div>
              )
            }
            {
              status.countReadyForPay > 0 && (
                <div className={ styles['unpaid-counts-badge'] }>
                  <UnpaidIcon fontSize={ 10 } mt={ 1 } />
                  <span className={ styles['badge-counter'] }>{ status.countReadyForPay }</span>
                </div>
              )
            }
          </div>
        </Tooltip>
      </>
    );
  }
  return (
    <Table.Cell
      pointer
      className={ styles['accounts-payable-status'] }
      widthPercent={ 10 }
    >
      { content }
    </Table.Cell>
  );
};

export default AccountsPayableStatus;
