import React, { useMemo } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { IAccountingClass } from '@src/types/accounting_class';
import { TID } from '@src/types/common';

import Form from '@src/components/ui/form';

interface IAddDepartmentFormValue {
  mappingClassIds: TID[],
}

interface IAddDepartmentFormProps {
  accountingClasses: IAccountingClass[];
  formId?: string,
  onSubmit: SubmitHandler<IAddDepartmentFormValue>,
}

const AddDepartmentForm = ({
  accountingClasses,
  formId,
  onSubmit,
}: IAddDepartmentFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
  } = useForm<IAddDepartmentFormValue>();

  const AccountingClassOptions = useMemo(() => {
    return [
      ...accountingClasses.map((accountingClass) => ({
        value: String(accountingClass.id),
        label: accountingClass.name,
      })),
    ];
  }, [accountingClasses]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <span>Select Department to map to category</span>
      <Form.CheckBoxCollectionField
        { ...register('mappingClassIds') }
        label=""
        options={ AccountingClassOptions }
      />
    </Form>
  );
};

export {
  IAddDepartmentFormProps,
  IAddDepartmentFormValue,
  AddDepartmentForm as default,
};
