import React, { forwardRef } from 'react';

import DropdownInput from '@src/components/ui_v2/inputs/dropdown_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IDropdownFieldProps extends
  React.ComponentPropsWithoutRef<typeof DropdownInput>,
  IWrapperProps
{
}

const DropdownField = forwardRef<HTMLDivElement, IDropdownFieldProps>(({
  ...props
}: IDropdownFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <div ref={ ref }>
      <Wrapper { ...wrapperProps }>
        <DropdownInput
          { ...inputProps }
        />
      </Wrapper>
    </div>
  );
});

DropdownField.displayName = 'DropdownField';

export default DropdownField;
