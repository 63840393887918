import React from 'react';

import ReactSelect, { IReactSelectInputProps } from './react_select/react_select_input';
import { formatSelectOption } from './react_select/select_option';
import { TOption } from './types';

interface ISelectInputProps extends
  Omit<IReactSelectInputProps<TOption>, 'value' | 'onChange'>
{
  options: TOption[],
  value?: TOption | null,
  onChange?: (value: TOption | null) => void,
}

const SelectInput = ({
  options,
  ...inputProps
}: ISelectInputProps): JSX.Element => {
  return (
    <ReactSelect
      { ...inputProps }
      formatOptionLabel={ formatSelectOption }
      options={ options }
    />
  );
};

export {
  ISelectInputProps,
  SelectInput as default,
};
