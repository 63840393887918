import React from 'react';

import {
  TBookkeepingStatus,
} from '@src/types/practice_informations';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';
import InProgressIcon from '@src/components/utils/icomoon/in_progress';
import NotReconciledIcon from '@src/components/utils/icomoon/not_reconciled';
import ReconciledIcon from '@src/components/utils/icomoon/reconciled';
import WaitingForClientIcon from '@src/components/utils/icomoon/waiting-for-client';

interface IBookkeepingStatusItemProps {
  status: TBookkeepingStatus,
}

const BookkeepingStatusItem = ({
  status,
}: IBookkeepingStatusItemProps): JSX.Element => {
  let content;
  if (status === 'reconciled') {
    content = (
      <Tooltip
        as={ ReconciledIcon }
        fontSize={ 16 }
        title="Reconciled"
      />
    );
  } else if (status === 'in-progress') {
    content = (
      <Tooltip
        as={ InProgressIcon }
        fontSize={ 16 }
        title="Reconciliation In Progress"
      />
    );
  } else if (status === 'waiting-for-client') {
    content = (
      <Tooltip
        as={ WaitingForClientIcon }
        fontSize={ 16 }
        title="Waiting for Client"
      />
    );
  } else {
    content = (
      <Tooltip
        as={ NotReconciledIcon }
        fontSize={ 16 }
        title="Reconciliation Not Started"
      />
    );
  }

  return (
    <Table.Cell className="pointer text-center">
      { content }
    </Table.Cell>
  );
};

export default BookkeepingStatusItem;
