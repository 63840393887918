import React, { useCallback, useMemo } from 'react';

import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReport } from '@src/types/report_service/report';

import { IReportData } from '@src/types/report_service/report_data';

import { API_DATE_FORMAT } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import { useFilterData } from '@src/components/ui_v2/filter';
import { UnableToSyncIcon } from '@src/components/utils/icomoon/';
import { useSection } from '@src/components/utils_v2/section';

import { TFilterData } from '../hooks';

interface IUnincludedTransactionsBannerProps {
  report: IReport,
  reportDatas: IReportData[],
}

const UnincludedTransactionsBanner = ({
  report,
  reportDatas,
}: IUnincludedTransactionsBannerProps): JSX.Element | null => {
  const business = useBusinessContext();
  const section = useSection();
  const filterData: TFilterData = useFilterData(section);
  const datas = useMemo(() => {
    return reportDatas.filter((reportData) => Boolean(reportData.unincludedTransactionsCount));
  }, [reportDatas]);

  const startDate = filterData?.reportDataRange?.gte !== '' ? moment(filterData?.reportDataRange?.gte)?.startOf('month')?.format(API_DATE_FORMAT) : '';
  const endDate = filterData?.reportDataRange?.lte !== '' ? moment(filterData?.reportDataRange?.lte)?.endOf('month')?.format(API_DATE_FORMAT) : '';

  const handleClick = useCallback(() => {
    if (startDate !== '' && endDate !== '') {
      window.Docyt.vent.trigger('open:unincluded_transactions_modal', business.id, report, startDate, endDate);
    }
  }, [business.id, startDate, endDate, report]);

  if (datas.length !== 0) {
    return (
      <div className="report-status-panel warning">
        <UnableToSyncIcon className="m-r-3 in-red" />
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_MISSING_TRANSACTIONS}
        </Text>
        
        { startDate !== '' && endDate !== '' && (
          <a
            className="m-l-auto pointer"
            role="button"
            tabIndex={ 0 }
            onClick={ handleClick }
          >
            Learn More
          </a>
        ) }
      </div>
    );
  }
  return null;
};

export default UnincludedTransactionsBanner;
