import React, { useCallback, useMemo } from 'react';

import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useVerifyRevenueServiceDocument } from '@src/hooks/queries/revenue_service_documents';
import { currencyLocaleFormatter } from '@src/utils/currency';
import { formatFullMonthDay } from '@src/utils/date_helpers';

import ResolveRevenueReportFooter from '@src/components/revenue_center/resolve_revenue_report/resolve_revenue_report_footer';
import Alert from '@src/components/ui_v2/alerts/alert';
import FullPageModal from '@src/components/ui_v2/full_page_modal';
import { WarningTriangleIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import DownloadRevenueReportButton from './download_report_button';
import useRevenueReportLineItems from './hooks';
import ResolveRevenueReportItems from './revenue_resolve_items';
import { IReportLineItems } from './schema';

import styles from './styles.module.scss';

const ResolveRevenueReportPage = (): JSX.Element | null => {
  const revenueService = useRevenueServiceContext();

  const { revenueServiceDocumentId } = useParams();

  const form = useForm<IReportLineItems>({
    defaultValues: {
      lineItems: [],
      rsdId:     null,
    },
    mode: 'onBlur',
  });

  const onSuccess = useCallback((newRsdId, items) => {
    const currentRsdId = form.getValues('rsdId');
    if (newRsdId === currentRsdId) return;
    form.reset({
      lineItems: items,
      rsdId:     newRsdId,
    });
  }, [form]);

  const { query, report, reportDate } = useRevenueReportLineItems({
    rsdId: Number(revenueServiceDocumentId),
    revenueService,
    onSuccess,
  });

  const { control } = form;

  const lineItems = useWatch({ control, name: 'lineItems' });

  const totals = useMemo(() => {
    const totalDebit = sumBy(lineItems, (item) => {
      const debitValue = item.debitValue === '-' ? '0' : item.debitValue;
      return round(parseFloat(debitValue || '0'), 2);
    });
    const totalCredit = sumBy(lineItems, (item) => {
      const creditValue = item.creditValue === '-' ? '0' : item.creditValue;
      return round(parseFloat(creditValue || '0'), 2);
    });
    const differenceAmount = round((totalCredit - totalDebit), 2);

    return { totalDebit, totalCredit, differenceAmount };
  }, [lineItems]);

  const isBalanced = totals.differenceAmount === 0;

  const handleCancelClick = useCallback(() => {
    window.Docyt.vent.trigger('close:resolve:page');
  }, []);

  const mutation = useVerifyRevenueServiceDocument();
  const { mutate } = mutation;

  const handleProceedClick = () => {
    const params = {
      id:   Number(revenueServiceDocumentId),
      data: { verifyZeroRevenueReceivables: true },
    };

    mutate(params, {
      onSuccess: () => {
        window.Docyt.vent.trigger('close:resolve:page');
      },
    });
  };

  return (
    <>
      <MutationStatus mutation={ mutation } successMessage="Successfully verified" successTitle="Status" />
      <FullPageModal>
        <FullPageModal.Header
          divider="horizontal"
          title={ `Resolve Issue - Revenue Report ${formatFullMonthDay(reportDate)}` }
          onCancel={ handleCancelClick }
        >
        { report && <DownloadRevenueReportButton report={ report } /> }
      </FullPageModal.Header>
        <FullPageModal.Body>
          <Alert
            selfClose
            icon={ <WarningTriangleIcon fontSize={ 20 } mr={ 10 } /> }
            variant="error"
          >
            The revenue report has an imbalance and needs correction.
          </Alert>
          <FormProvider { ...form }>
            <ResolveRevenueReportItems
              query={ query }
            />
          </FormProvider>
        </FullPageModal.Body>

        <FullPageModal.Footer
          isProceedDisabled={ !isBalanced }
          proceedTitle="Resolve"
          onCancel={ handleCancelClick }
          onProceed={ handleProceedClick }
        >
          <FullPageModal.FooterHeader>
            <ResolveRevenueReportFooter
              credit={ totals.totalCredit }
              debit={ totals.totalDebit }
            />
          </FullPageModal.FooterHeader>

          <FullPageModal.FooterFiller>
            <span className={ isBalanced ? styles['diff-information-same'] : styles['diff-information-not-same'] }>
              Difference:
              {' '}
              {' '}
              {currencyLocaleFormatter(totals.differenceAmount)}
            </span>
          </FullPageModal.FooterFiller>
        </FullPageModal.Footer>
      </FullPageModal>
    </>

  );
};

export default React.memo(ResolveRevenueReportPage);
