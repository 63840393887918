import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { TDate, TID } from '@src/types/common';
import { IMetric } from '@src/types/metrics';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import BlankItem from './blank_item';
import Item from './item';

interface IBusinessMetricValuesListProps {
  businessMetric: IMetric,
  businessMetricValues: IBusinessMetricValue[],
  hasValues: boolean,
  isEditMode: boolean,
  businessId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBusinessMetricValueSortColumn>,
  onValueChange: (date: TDate, value?: number) => void,
  onAddValues: () => void,
}

const BusinessMetricValuesList = ({
  businessMetric,
  businessMetricValues,
  hasValues,
  isEditMode,
  query,
  sorting,
  onValueChange,
  onAddValues,
  businessId,
}: IBusinessMetricValuesListProps): JSX.Element => {
  return (
    <Table
      infiniteQuery={ query }
      style={ hasValues ? { marginBottom: '20px' } : { marginBottom: '0' } }
      wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TBusinessMetricValueSortColumn>
            columnName="date"
            sorting={ sorting }
            widthPercent={ 40 }
          >
            Date
          </Table.HCell>
          <Table.HCell<TBusinessMetricValueSortColumn>
            columnName="value"
            sorting={ sorting }
            widthPercent={ 50 }
          >
            { businessMetric.name }
          </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          hasValues
            ? (
              businessMetricValues.map((businessMetricValue) => {
                return (
                  <Item
                    key={ businessMetricValue.date }
                    businessId={ businessId }
                    businessMetric={ businessMetric }
                    businessMetricValue={ businessMetricValue }
                    hasGranularData={ businessMetric.dimensions.length > 0 }
                    isEditMode={ isEditMode }
                    onValueChange={ onValueChange }
                  />
                );
              })
            )
            : (
              <BlankItem
                businessMetricName={ businessMetric.name }
                onAddValues={ onAddValues }
              />
            )
        }
      </Table.Body>
    </Table>
  );
};

export default BusinessMetricValuesList;
