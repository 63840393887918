import React from 'react';

import { rcChartOfAccountsPath, rcLedgerSyncingCentrePath, rcSettingsPath } from '@src/routes';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: number
}

const AccountingSidebarMenuItems = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNTING }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ rcChartOfAccountsPath(businessId) }
          title="Chart Of Accounts"
        />
        <ServiceSidebar.Item
          href=""
          title="Departments"
        />
        <ServiceSidebar.Item
          href={ rcLedgerSyncingCentrePath(businessId) }
          title="General Ledger Sync Center"
        />
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href={ rcSettingsPath(businessId) }
          title="Docyt AI Settings"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default AccountingSidebarMenuItems;
