import React, { useState } from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import AddBusinessClient from './add_business_client';

import styles from '../styles.module.scss';

const BlankPage = (): JSX.Element => {
  const [showWidget, setShowWidget] = useState<boolean>(true);

  return (
    <>
      <div className="dashboard-onboarding-introduce-view-container text-center">
        {
          showWidget && (
            <div className="videos-card">
              <div className="card-action-wrapper">
                <ActionsDropdown>
                  <ActionsDropdown.Action
                    name="Hide Widget"
                    onClick={ () => setShowWidget((prev) => !prev) }
                  />
                </ActionsDropdown>
              </div>
              <h2 className="card-header">Welcome to Docyt</h2>
              <p className="card-description">
                Provide your clients with a powerful digital back-office that
                { ' ' }
                replaces 3-5 different software with just 1 integrated tool.
                { ' ' }
                Watch the video below to see how Docyt works.
              </p>
              <div className="card-video-wrapper">
                <iframe height="280" src={ window.configData.onboarding_accountant_video_embed_url } title="test" width="500" />
              </div>
            </div>
          )
        }
        <p className={ styles['add-client-get-started'] }>Add your clients to get started.</p>
      </div>
      <AddBusinessClient />
    </>
  );
};

export default BlankPage;
