import React from 'react';

import { TID } from '@src/types/common';
import { IMetric } from '@src/types/metrics';
import { apiMonthToDate, getUTCTimezone } from '@src/utils/date_helpers';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import { MonthPickerInput } from '@src/components/ui/form';

interface IMetricsServiceHeaderProps {
  businessId: TID,
  businessMetric: IMetric,
  selectedMonthStr?: string,
  onDateChanged: (value: string | undefined) => void
}

const BusinessMetricValuesHeader = ({
  businessId,
  businessMetric,
  selectedMonthStr,
  onDateChanged,
}: IMetricsServiceHeaderProps): JSX.Element => {
  const metricStartFirstDate = apiMonthToDate(businessMetric.startDate);
  const endDate = new Date();
  const startDate = metricStartFirstDate
    ? getUTCTimezone(new Date(metricStartFirstDate))
    : getUTCTimezone(new Date(endDate.getFullYear(), endDate.getMonth(), 1));
  return (
    <DetailsPageTitle
      badge="Manual/CSV"
      breadcrumbs={ {
        href: `/businesses/${businessId}/metrics_service`, title: 'Industry Metrics',
      } }
      title={ businessMetric.name }
    >
      <MonthPickerInput
        options={ { startDate } }
        value={ selectedMonthStr }
        onChange={ onDateChanged }
      />
    </DetailsPageTitle>
  );
};

export default React.memo(BusinessMetricValuesHeader);
