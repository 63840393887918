import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITableFootProps extends React.ComponentPropsWithoutRef<'tfoot'>, IUIStyleProps {
}

const TableFoot = ({
  children,
  ...props
}: ITableFootProps) => {
  const [classes, tdProps] = uiStyleProps(
    undefined,
    props,
  );

  return (
    <tfoot
      className={ classes }
      { ...tdProps }
    >
      { children }
    </tfoot>
  );
};

export default TableFoot;
