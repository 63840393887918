import React, { FC } from 'react';

import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import Table from '@src/components/reconciliation_center/journal_entries/header/table';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';

import useJournalDataHandling from './use_journal_data_handling';

import styles from '../styles.module.scss';

const AddBtn: FC<{text?: string}> = ({ text = 'Add' }) => {
  const { isOpen, open, close } = useDrawer();
  const { additionalParams, onSuccess, balanceSheetAccount, dateRange } = useJournalDataHandling();

  const defaultRows = balanceSheetAccount ? [
    {
      account:         balanceSheetAccount.id,
      accountObj:      balanceSheetAccount,
      type:            balanceSheetAccount.accType === 'Accounts Payable' ? 'Vendor' : 'QuickbooksCustomer',
      hasAccountValue: true,
    }, {}]
    : undefined;

  return (
    <>
      <button className={ styles['add-btn'] } type="button" onClick={ open }>{text}</button>
      <JournalDrawer drawerControl={ { isOpen, open, close } } title="Add Journal Entry">
        <Table
          additionalParams={ additionalParams }
          close={ close }
          dateRange={ dateRange }
          defaultRows={ defaultRows }
          onSuccess={ onSuccess }
        />
      </JournalDrawer>
    </>
  );
};

export default AddBtn;
