import { useCallback, useEffect } from 'react';

import { GetCurrentUserSSOToken, SSOSignOut } from '../helpers';
import { LoginButtonProps } from '../view/login_button_root';

export const useVerifySSOLogin = ({ ssoLogin }: LoginButtonProps) => {
  const handleError = useCallback(async () => {
    await SSOSignOut();
  }, []);

  useEffect(() => {
    (async () => {
      const ssoToken = await GetCurrentUserSSOToken();
      if (ssoToken) {
        ssoLogin(ssoToken, SSOSignOut, handleError);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
