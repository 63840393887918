import React from 'react';

import { IBusinessBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';
import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import Action from './action';

interface EntryPointProps {
  businessId: TID,
  bookkeepingStatus: IBusinessBookkeepingStatus,
  isAcknowledged: boolean,
  isReconciled: boolean,
}

const EntryPoint = ({ businessId, ...props }: EntryPointProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <Action { ...props } />
      </BusinessProvider>
    </Root>
  );
};

export default EntryPoint;
