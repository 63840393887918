import React, { useCallback } from 'react';

import { useMoveDocumentsToTrash } from '@src/hooks/queries/documents';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { useConfirmDeleteDocumentModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { NewTrashIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IBusinessInboxItemDeleteActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemDeleteAction = ({
  document,
}: IBusinessInboxItemDeleteActionProps): JSX.Element => {
  const moveToTrash = useMoveDocumentsToTrash();

  const { mutate } = moveToTrash;
  const handleConfirm = useCallback(() => {
    mutate(
      { documentIds: [document.id], value: true },
    );
  }, [document.id, mutate]);

  const confirm = useConfirmDeleteDocumentModal({
    document,
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ moveToTrash } successMessage="Deleted successfully." />
      <confirm.Component { ...confirm.props } />
      <ActionsDropdown.DangerAction
        icon={ <NewTrashIcon /> }
        name="Delete"
        onClick={ confirm.open }
      />
    </>
  );
};

export default BusinessInboxItemDeleteAction;
