import React, { useState } from 'react';

import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { W9VendorOffIcon, EditIcon } from '@src/components/utils/icomoon';

import { useW9InfoUploadModal } from '../modals/w9_modal/w9_info_upload_modal';

import styles from '../../styles.module.scss';

interface IW9InfoActionProps {
  vendor: IW9InfoResponse;
  businessId: TID,
  id: TID,
}

const W9InfoAction = ({
  vendor,
  businessId,
  id,
}: IW9InfoActionProps) => {
  const w9InfoUploadModal = useW9InfoUploadModal();
  const [modalMode, setModalMode] = useState<'edit' | 'view'>();

  const openEditW9Modal = () => {
    setModalMode('edit');
    w9InfoUploadModal.open();
  };

  const openViewW9Modal = () => {
    setModalMode('view');
    w9InfoUploadModal.open();
  };

  return (
    <>
      <ActionsDropdown className={ styles['action-dropdown-bg'] } classNameMenus={ styles.submenus }>
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <EditIcon fontSize={ 18 } /> }
          title="Edit Info"
          onClick={ openEditW9Modal }
        />
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <W9VendorOffIcon fontSize={ 20 } /> }
          title="View W9 Form"
          onClick={ openViewW9Modal }
        />
      </ActionsDropdown>
      <w9InfoUploadModal.Component
        { ...w9InfoUploadModal.props }
        businessId={ businessId }
        id={ id }
        modalMode={ modalMode }
        vendor={ vendor }
      />
    </>
  );
};

export default React.memo(W9InfoAction);
