module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<p class="m-b-20">Note: Do not use personal Credit/Debit cards for business expenses.</p>\n<button class="btn btn-blue secure-add-account-btn add-secure-bank-account-btn">\n  <span class="icon icon-plaid font-20"></span>Securely Add Bank Account\n</button>\n<button class="btn btn-blue secure-add-account-btn add-secure-credit-card-account-btn">\n  <span class="icon icon-plaid font-20"></span>Securely Add Credit Card Account\n</button>\n<a class="pointer manual-add-account-link add-manual-bank-account-btn" style="margin-top: 30px;">Add Bank Account Manually</a>\n<hr style="margin: 0;">\n<a class="pointer manual-add-account-link add-manual-credit-card-account-btn">Add Credit Card Account Manually</a>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}