module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, line, ref;
    
      __out.push('<td class="pointer text-left">\n  ');
    
      __out.push(__sanitize(moment(this.transaction_date, 'YYYY-MM-DD').format('MM/DD/YY')));
    
      __out.push('\n</td>\n<td class="text-left">\n  ');
    
      if (this.category) {
        __out.push('\n    ');
        ref = this.category.split(';');
        for (i = 0, len = ref.length; i < len; i++) {
          line = ref[i];
          __out.push('\n      <p>');
          __out.push(__sanitize(line));
          __out.push('</p>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    -\n  ');
      }
    
      __out.push('\n</td>\n<td class="text-left">\n  <a class="pointer past-transaction-detail">');
    
      __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</a>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}