module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header text-center">\n        <span class="font-28 font-bold m-l-15">Add Adjustment Journal Entry</span>\n      </div>\n      <div class="modal-body adjustment-body">\n        <p class="p-b-0 text-left">Document amount is not the same as transaction amount. Add an adjustment entry to match the document with transaction.</p>\n        <div class="m-t-30">\n          <div class="document-field-item document-field-name inline-block width-120px">Adjustment Amount</div>\n          <div class="document-field-item document-field-value inline-block m-l-50">');
    
      __out.push(__sanitize(parseFloat(this.adjustment_amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</div>\n        </div>\n        <div class="field-input-container m-t-10">\n          <div class="document-field-item document-field-name inline-block width-120px">Category</div>\n          <div class="document-field-item document-field-value vendor-typeahead-wrapper inline-block m-l-50">\n            ');
    
      if (this.category_splits && this.category_splits.length > 0) {
        __out.push('\n              <a class="pointer line-height-26 inline-block" id="split-account-dropdown-btn">');
        __out.push(__sanitize(this.category_splits.length));
        __out.push(' Split</a>\n            ');
      } else {
        __out.push('\n              <input class="input-category typeahead form-control inline-block width-260px" type="text" value="" placeholder="Select Category" />\n              <a class="pointer line-height-26 inline-block m-l-10" id="split-account-dropdown-btn">Split</a>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type="button" class="btn btn-blue match-btn ');
    
      __out.push(__sanitize((this.category_splits && this.category_splits.length > 0) || this.categoryId ? '' : 'disabled'));
    
      __out.push('">Match</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}