import { useMemo } from 'react';

import { IStandardFolderWithDocuments } from '@src/types/standard_documents';

const useStandardDocumentsByFolderFilter = (
  folders: IStandardFolderWithDocuments[],
  query: string | undefined,
): IStandardFolderWithDocuments[] => {
  return useMemo(() => {
    if (!query) return folders;

    const normalizedQuery = query.toLowerCase();

    return folders.reduce<IStandardFolderWithDocuments[]>((result, folder) => {
      if (folder.name.toLowerCase().indexOf(normalizedQuery) >= 0) {
        result.push(folder);
      } else {
        const filteredFolder = {
          ...folder,
          documents: folder
            .documents
            .filter((d) => d.name.toLowerCase().indexOf(normalizedQuery) >= 0),
        };

        if (filteredFolder.documents.length > 0) {
          result.push(filteredFolder);
        }
      }

      return result;
    }, [] as IStandardFolderWithDocuments[]);
  }, [folders, query]);
};

export { useStandardDocumentsByFolderFilter };
