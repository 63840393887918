import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface IDeleteInvoiceActionProps {
  invoiceId: TID,
}

const DeleteInvoiceAction = ({
  invoiceId,
}: IDeleteInvoiceActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:delete:invoice', invoiceId);
  }, [invoiceId]);

  return (
    <ActionsDropdown.DangerAction
      icon={ <TrashcanIcon fontSize={ 18 } /> }
      name="Delete Invoice"
      onClick={ handleSelect }
    />
  );
};

export default DeleteInvoiceAction;
