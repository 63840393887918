module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="border-bottom-gray m-b-20 clearfix m-l-5 m-r-5">\n  ');
    
      if (this.activeTab === 'contacts') {
        __out.push('\n  <a class="font-14 m-t-15 pointer pull-right add-contact-js m-t-30"><i class="clients__action-icon icon icon-plus"></i> Add Contact</a>\n  <!--<div class="m-t-2 contact-search-input-wrapper">-->\n    <!--<i class="fa fa-search font-20"></i>-->\n    <!--<input class="form-control contact-search-input" placeholder="Search by name, email or phone number...">-->\n  <!--</div>-->\n  ');
      } else {
        __out.push('\n  <button class="btn btn-add-document font-23 pull-right m-t-10">\n    <i class="icon icon-plus"></i>\n  </button>\n  ');
      }
    
      __out.push('\n  <ul class="m-b-0 m-t-25 nav sub-header-menu personal-header-menu">\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab !== 'contacts' ? 'active' : ''));
    
      __out.push('"><a href="/my_documents">DOCUMENTS</a></li>\n  </ul>\n</div>\n');
    
      if (Docyt.currentAdvisor.get('businesses_count') < 1) {
        __out.push('\n<div class="clearfix m-b-20 m-t-0 m-l-5 m-r-5 p-t-10 p-b-10 p-l-10 p-r-10 personal-header-business-pan">\n  <div class="col-sm-12 col-md-8">\n    <p class="font-28 in-white m-b-0">Own a business?</p>\n    <p class="font-20 m-b-0 in-white transparent-70">Organize your business on Docyt. Never worry about audits, accounting and taxes.</p>\n  </div>\n  <div class="col-sm-12 col-md-4">\n    <button type="button" class="btn btn-add-business m-t-17 pull-right font-14 in-blue-900">\n      <i class="icon icon-o-plus"></i>\n      <span class="m-l-5">Add Business</span>\n    </button>\n    <!--<button type="button" class="close inline-block">-->\n      <!--<span aria-hidden="true">&times;</span>-->\n    <!--</button>-->\n  </div>\n</div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}