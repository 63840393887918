import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';

import { TIconOption } from '../inputs/types';

import styles from './styles.module.scss';

interface IDropdownInnerInputProps {
  placeholder?: TIconOption,
  value?: TIconOption | null,
}

const DropdownInnerInput = ({
  value,
  placeholder,
}: IDropdownInnerInputProps) => {
  const {
    isVisible,
    toggle,
  } = useDropdown() || {};

  const classes = classNames(
    value ? styles['dropdown-input-value'] : styles['dropdown-input-placeholder'],
  );

  const iconItemClass = classNames(
    value ? styles['dropdown-icon-item'] : styles['placeholder-item'],
  );

  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (toggle) toggle();
  }, [toggle]);

  return (
    <>
      <div
        aria-expanded={ isVisible ? 'true' : 'false' }
        aria-haspopup="true"
        className={ classes }
        role="button"
        tabIndex={ 0 }
      >
        <Dropdown.IconItem
          className={ iconItemClass }
          icon={ value ? value.icon : placeholder?.icon }
          title={ value ? value?.label : placeholder?.label }
          onClick={ handleClick }
        />
      </div>
      <Dropdown.InputIndicator tabIndex={ -1 } />
    </>
  );
};

export default DropdownInnerInput;
