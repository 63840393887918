import React from 'react';

import { vendorInvoicesPath } from '@src/routes';
import { TID } from '@src/types/common';

import { Accordion } from '@src/components/ui_v2/accordion';
import { AppLink } from '@src/components/ui_v2/buttons';
import Section from '@src/components/utils_v2/section';

import { usePaymentsCollection } from './hooks';
import Table from './past_payments_table';

interface IPastPaymentsProps {
  businessId: TID,
  serviceId: TID,
  vendorId: TID,
}

const PastPayments = ({
  businessId,
  serviceId,
  vendorId,
}: IPastPaymentsProps) => {
  const collection = usePaymentsCollection(
    {
      businessId,
      serviceId,
      vendorId,
    },
  );
  const path = vendorInvoicesPath(businessId, vendorId);

  return (
    <Accordion
      suffix={ <AppLink newWindow href={ path }>View All</AppLink> }
      title="Past Payments"
    >
      { () => (
        <Section.Provider section={ collection.section }>
          <Table
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </Section.Provider>
      ) }
    </Accordion>
  );
};

export default React.memo(PastPayments);
