import React from 'react';

import { IRevenueSystem } from '@src/types/revenue_systems';

import { useRevenueDepositCollection } from '@src/components/revenue_center/deposit_tracking/hooks';
import CarouselView from '@src/components/ui_v2/carousel/carousel_view';

import RevenueDepositItem from './item';

interface RevenueDepositListViewProps {
  revenueSystem: IRevenueSystem,
}

const RevenueDepositListView = ({
  revenueSystem,
}: RevenueDepositListViewProps) => {
  const revenueDeposits = useRevenueDepositCollection({ revenueSystem });

  return (
    <CarouselView
      defaultChildWidth={ 500 }
      height={ 72 }
    >
      {
        revenueDeposits.map((depositedInformation) => (
          <RevenueDepositItem
            key={ depositedInformation.id }
            depositedInformation={ depositedInformation }
          />
        ))
      }
    </CarouselView>
  );
};

export default RevenueDepositListView;
