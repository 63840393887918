import React from 'react';

import styles from './styles.module.scss';

interface IDetailsFieldWrapperProps {
  children: React.ReactNode,
  label: string,
}

const DetailsFieldWrapper = ({
  children,
  label,
}: IDetailsFieldWrapperProps) => {
  return (
    <div className={ styles.filed }>
      <label className={ styles.label }>
        { label }
      </label>
      <div className={ styles.value }>
        { children }
      </div>
    </div>
  );
};

export default React.memo(DetailsFieldWrapper);
