module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <span class="clients__client-status-icon icon-warning pull-left icon-header">\n            <span class="path1"></span><span class="path2"></span>\n        </span>\n        <button class=\'close\' type=\'button\'>\n          <span>&times;</span>\n        </button>\n        <h4 class="modal-title">Warning</h4>\n      </div>\n      <div class="modal-body padding-30-80">\n        <p class="text-align font-16">You are changing your ');
    
      __out.push(__sanitize(this.field_name));
    
      __out.push('. Do you want to continue?</p>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id=\'cancel-change\' class="cancel-link cancel modal-link-btn">Cancel</button>\n        <button type="button" id=\'confirm-change\' class="main-btn bg-red-400">Confirm & Change</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}