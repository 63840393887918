module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header text-center">\n        <span class="icon-bank-circle-o icon-title font-56 vertical-align-middle"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>\n        <label class="modal-title vertical-align-middle font-28 font-bold">Review Account Information</label>\n      </div>\n      <div class="modal-body">\n        <p class="font-16 font-bold">Almost done! Just one more step…</p>\n        <p class="p-b-0 text-left font-16 m-t-10 m-b-15">The last 4 digits of the connected account number are different from the ones entered by you.</p>\n        <ul>\n          <li class="font-16">Received from the bank: <label class="in-black">');
    
      __out.push(__sanitize(this.last_four_digits_from_plaid));
    
      __out.push('</label></li>\n          <li class="font-16">Entered by you: <label class="in-red-400">');
    
      __out.push(__sanitize(this.last_four_digits_from_input));
    
      __out.push('</label></li>\n        </ul>\n        <p class="p-b-0 text-left font-16 m-t-15 m-b-30">Would you like to update the last 4 digits of the account number?</p>\n      </div>\n      <div class="modal-footer">\n        <button type="button" class="btn btn-blue ladda-button replace-btn" tabindex="10" data-style="expand-right"><span class="ladda-label">Replace with ');
    
      __out.push(__sanitize(this.last_four_digits_from_plaid));
    
      __out.push('</span></button>\n        <a class="cancel-link cancel pointer font-semi-bold cancel-btn">Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}