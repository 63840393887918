import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const WarningTriangleWhiteIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="warning-triangle-white"
      pathsNumber={ 1 }
    />
  );
};

export default WarningTriangleWhiteIcon;
