module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="servicemenu-region"></div>\n');
    
      if (this.isGlobalnavigationNewUi === false) {
        __out.push('\n<ul class="nav nav-sidebar">\n  ');
        if (this.is_activated) {
          __out.push('\n  <li class="');
          __out.push(__sanitize(this.tab === 'expense' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('">\n      <span class="in-purple-account icon-check-recharge service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n      <span class="nav-item-text">My Expenses</span>\n    </a>\n  </li>\n  ');
        } else {
          __out.push('\n  <li class="');
          __out.push(__sanitize(this.tab === 'activate' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/activate?step=1">\n      <span class="icon-settings-green in-purple-account service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">My Expenses</span>\n    </a>\n  </li>\n  ');
        }
        __out.push('\n  ');
        if (this.role === 'ADMIN') {
          __out.push('\n  <li class="');
          __out.push(__sanitize(this.tab === 'employees' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/employees">\n      <img class="service-icon-font" src="');
          __out.push(__sanitize(configData.expense_images['EXPENSE_SERVICE_EMPLOYEES']));
          __out.push('">\n      <span class="nav-item-text">Employee Expenses</span>\n    </a>\n  </li>\n  <li class="');
          __out.push(__sanitize(this.tab === 'category' ? 'active' : ''));
          __out.push('">\n    <a href="');
          __out.push(__sanitize(this.homeURL));
          __out.push('/category_management">\n      <img src="');
          __out.push(__sanitize(configData.expense_images['EXPENSE_CATEGORY']));
          __out.push('" class="service-icon-font">\n      <span class="nav-item-text">Category Management</span>\n    </a>\n  </li>\n  ');
        }
        __out.push('\n  <li class="');
        __out.push(__sanitize(this.tab === 'settings' ? 'active' : ''));
        __out.push('">\n    ');
        if (this.is_activated) {
          __out.push('\n    ');
          this.url = this.homeURL + "/settings";
          __out.push('\n    ');
        } else {
          __out.push('\n    ');
          this.url = this.homeURL + "/settings/employee_accounts";
          __out.push('\n    ');
        }
        __out.push('\n    <a href="');
        __out.push(__sanitize(this.url));
        __out.push('">\n      <span class="icon-settings-green in-green-500 service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Settings</span>\n      ');
        if (!this.funding_source_exists) {
          __out.push('\n      <i class="icon icon-unable-to-sync in-red-600 font-12"><span class="path1"></span><span class="path2"></span></i>\n      ');
        }
        __out.push('\n    </a>\n  </li>\n</ul>\n');
      }
    
      __out.push('\n');
    
      if (this.isGlobalnavigationNewUi) {
        __out.push('\n  <div id="servicesidebar-menu-region"></div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}