import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { ITransactionsFilter } from './types';

interface ITransactionsFilterProps {
  filter: IFilter<ITransactionsFilter>;
}

const TransactionsFilter = ({
  filter,
}: ITransactionsFilterProps) => {
  return (
    <Filter<ITransactionsFilter> filter={ filter }>
      <Filter.DateField name="transaction_date" widthPercent={ 40 } />
    </Filter>
  );
};

export default React.memo(TransactionsFilter);
