import { IBankAccountReconciliationDocument } from '@src/types/bank_account_reconciliation_document';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetBankAccountReconciliationDocumentsResponse {
  meta: {
    totalAmount: string,
    totalCount: number,
  },
  collection: IBankAccountReconciliationDocument[],
}

interface IGetBankAccountReconciliationDocumentsParams {}

// eslint-disable-next-line max-len
const getBankAccountReconciliationDocuments = (params: IGetBankAccountReconciliationDocumentsParams): Promise<IGetBankAccountReconciliationDocumentsResponse> => {
  const url = '/api/v1/bank_account_reconciliation_documents';

  return apiGet(url, { ...underscoreKeys(params) }).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.bankAccountReconciliationDocuments,
    } as IGetBankAccountReconciliationDocumentsResponse;
  });
};

export {
  IGetBankAccountReconciliationDocumentsResponse,
  getBankAccountReconciliationDocuments,
  IGetBankAccountReconciliationDocumentsParams,
};
