import { array, object } from 'yup';

import { IBusiness } from '@src/types/businesses';

const businessesValidation = object({
  businesses: array().test((value, validationContext) => {
    const { createError } = validationContext;

    if (value?.length === 0) {
      return createError({ message: 'You need to select at least one item.' });
    }
    return true;
  }),
});

interface IBusinessesInput {
  businesses: IBusiness[],
}

export {
  IBusinessesInput,
  businessesValidation,
};
