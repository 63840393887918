import React, { useState, useCallback } from 'react';

import Dropzone from 'react-dropzone';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';

import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { NewDocumentIcon } from '@src/components/utils/img_icons';
import { fileSizeInMB } from '@src/components/utils/bytes_to_mb';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

interface IBalanceSheetStatementDropInputProps {
  balanceSheetStatement?: IBalanceSheetStatement,
  error?: string,
  isFileUploading?: boolean,
  onUploaded: (data: File) => void,
}

const allowedFileFormat = window.configData.allowed_file_upload_format;
const allowedFileSize = window.configData.allowed_file_upload_size;

const BalanceSheetStatementDropInput = ({
  balanceSheetStatement,
  error,
  isFileUploading,
  onUploaded,
}: IBalanceSheetStatementDropInputProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(error);

  const handleFileSelected = useCallback((files) => {
    if (files.length > 1) {
      setErrorMsg('You are able to upload one file at a time.');
      return;
    }
    const file = files[0];
    if (balanceSheetStatement && balanceSheetStatement.state
      !== window.Docyt.Common.Constants.BANK_STATEMENT_STATES.UPLOADED) {
      setErrorMsg(`You can not upload file because status of statement is ${balanceSheetStatement.state}.`);
      return;
    }
    if (file.size > allowedFileSize) {
      setErrorMsg(`File size should be less than ${fileSizeInMB(allowedFileSize)}MB.`);
      return;
    }
    if (!allowedFileFormat.includes(file.name.split('.').pop().toLowerCase())) {
      setErrorMsg(`File you uploaded is not supported. You can only upload in one of these formats: ${allowedFileFormat.join(', ')}`);
      return;
    }

    onUploaded(file);
  }, [balanceSheetStatement, onUploaded]);

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <div className="height-100-percent">
        {
          isFileUploading ? (
            <div className={ styles['progress-status'] }>
              <p className="file-upload-status-text font-18">
                Uploading...
              </p>
            </div>
          ) : (
            <>
              {
                error && (
                  <p className="in-red-400 main-input-hint upload-validation-error">
                    <WarningIcon mr={ 10 } />
                    {' '}
                    { error }
                  </p>
                )
              }
              <Dropzone multiple={ false } onDrop={ handleFileSelected }>
                {({ getRootProps, getInputProps }) => (
                  <section className={ styles['drop-zone-section'] }>
                    <div className={ styles['drop-zone-section-details'] } { ...getRootProps() }>
                      <NewDocumentIcon />
                      <p className="m-t-20 font-16">Drag and drop a statement here.</p>
                      <p className="m-t-10 m-b-10 font-16">OR</p>
                      <Button
                        bsStyle="blue-on-hover"
                        className="fileinput-button"
                        fontSize={ 16 }
                      >
                        Select from your computer
                        <input { ...getInputProps() } />
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
            </>
          )
        }
      </div>
    </>
  );
};

export default BalanceSheetStatementDropInput;
