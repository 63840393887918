import React from 'react';

import classNames from 'classnames';

import { IInputCommonProps } from './types';

import styles from './styles.module.scss';

interface IInputSuffixIconProps<T extends React.ElementType> {
  absolute?: boolean;
  as?: T,
  icon?: React.ReactNode,
  size?: IInputCommonProps['size'];
}

type TInputSuffixIconProps<T extends React.ElementType> =
  IInputSuffixIconProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof IInputSuffixIconProps<T> | 'children'>
const InputSuffixIcon = <T extends React.ElementType = 'span'>({
  absolute,
  as,
  className,
  icon,
  size,
  ...props
}: TInputSuffixIconProps<T>) => {
  const classes = classNames(
    styles['input-suffix-icon'],
    {
      [styles['input-suffix-icon-absolute']]: absolute,
      [styles['input-suffix-icon-compact']]:  size === 'compact',
    },
    className,
  );

  const Component = as || 'span';

  return (
    <Component
      className={ classes }
      { ...props }
    >
      { icon }
    </Component>
  );
};

export default InputSuffixIcon;
