import React from 'react';

import { TOption } from '@src/components/ui_v2/inputs';
import { TabItem } from '@src/components/ui_v2/tabs';

import styles from './styles.module.scss';

interface ICategoryTabberProps {
  searchTypeList: TOption[];
  onTabChange: (item: TOption) => void;
  activeTab: TOption | null;
}

const CategoryTabber = ({
  searchTypeList,
  onTabChange,
  activeTab,
}:ICategoryTabberProps) => {
  return (
    <div className={ styles['service-menu-page'] }>
      <div className={ styles['service-menu-page-inner'] }>
        {searchTypeList?.map((searchItem) => (
          <TabItem
            key={ searchItem.value }
            activeTab={ activeTab ?? searchTypeList[0] }
            tab={ searchItem }
            onTabChange={ onTabChange }
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(CategoryTabber);
