module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, country, i, j, len, len1;
    
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <button class=\'close cancel\' type=\'button\'>\n                    <span>&times;</span>\n                </button>\n                <h4 class="modal-title font-28 font-bold">Edit Business Profile</h4>\n            </div>\n            <div class="modal-body">\n                <div class="update-avatar-container">\n                    <div class="profile-picture-drop-zone relative m-t-27 p-t-50 p-b-50">\n                        ');
    
      if (!this.isUploading) {
        __out.push('\n                        <div class="text-center">\n                            <img class="change-avatar-img-wrapper" src="');
        __out.push(__sanitize(configData.images['new_photo_logo']));
        __out.push('">\n                            <p class="m-t-20 font-20 text-center">Drag and drop a photo here.</p>\n                            <p class="m-t-10 m-b-10 font-20 text-center">OR</p>\n                            <button class="btn btn-blue-on-hover fileinput-button font-16">\n                                Select from your computer\n                                <input type="file" name="files[]" class="profile-picture-file-input">\n                            </button>\n                        </div>\n                        ');
      } else {
        __out.push('\n                        <div class="text-center p-t-70">\n                            <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n                            <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                                <div class=\'file-status-line\' style=\'width:0%\'></div>\n                            </div>\n                        </div>\n                        ');
      }
    
      __out.push('\n                    </div>\n                </div>\n                <div class="text-center update-profile-container">\n                    <div>\n                        <div class="profile-avatar-wrapper m-l-auto m-r-auto">\n                            ');
    
      if (this.avatar) {
        __out.push('\n                            <img class="avatar-img-wrapper" src="');
        __out.push(__sanitize(this.avatar));
        __out.push('">\n                            ');
      } else {
        __out.push('\n                            <span class=\'icon-business-default-avatar font-98\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                            ');
      }
    
      __out.push('\n                        </div>\n                    </div>\n                    <a class="change-avatar-link">Change Profile Picture</a>\n                    <div class="profile-content-wrapper">\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13 text-left">Display Name</label>\n                            <input class="input-display-name font-15" type="text" value="');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('">\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13 text-left">Business Name</label>\n                            <input class="input-name font-15" type="text" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n                            <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13 text-left">Email</label>\n                            <input class="input-email font-15" type="text" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n                            <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13 text-left">Phone Number</label>\n                            <input class="input-phone font-15" type="text" value="');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('">\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13 text-left">Address</label>\n                            <input class="input-address-street font-15" placeholder="Street" type="text" value="');
    
      __out.push(__sanitize(this.address_street));
    
      __out.push('">\n                            <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13"></label>\n                            <input class="input-address-street2 font-15" type="text" placeholder="Street (Continued)" value="');
    
      __out.push(__sanitize(this.address_street_2));
    
      __out.push('">\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13"></label>\n                            <div class="business-address-wrapper">\n                                <div class="profile-input-wrap p-r-5">\n                                    <input class="input-address-city font-15" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('">\n                                    <p class="valid-hint-js business-info m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n                                </div>\n                                ');
    
      if (this.address_country === 'US') {
        __out.push('\n                                <div class="profile-input-wrap p-r-5">\n                                    <select class="input-address-state font-15 form-control">\n                                    ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          address_state = usaStateList[i];
          __out.push('\n                                        ');
          if (address_state.abbreviation === this.address_state) {
            __out.push('\n                                        <option value="');
            __out.push(__sanitize(address_state.abbreviation));
            __out.push('" selected>');
            __out.push(__sanitize(address_state.text));
            __out.push('</option>\n                                        ');
          } else {
            __out.push('\n                                        <option value="');
            __out.push(__sanitize(address_state.abbreviation));
            __out.push('">');
            __out.push(__sanitize(address_state.text));
            __out.push('</option>\n                                        ');
          }
          __out.push('\n                                    ');
        }
        __out.push('\n                                    </select>\n                                </div>\n                                ');
      } else {
        __out.push('\n                                <div class="profile-input-wrap p-r-5">\n                                    <input class="input-address-state font-15" type="text" placeholder="State" value="');
        __out.push(__sanitize(this.address_state));
        __out.push('">\n                                    <p class="valid-hint-js business-info m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n                                </div>\n                                ');
      }
    
      __out.push('\n                                <div class="profile-input-wrap">\n                                    <input class="input-address-zip font-15" type="text" placeholder="Zip" value="');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n                                    <p class="valid-hint-js business-info m-t-1 m-r-3 in-red-400"><i class="fa fa-warning"></i></p>\n                                </div>\n                            </div>\n                        </div>\n                        <div class="profile-input-wrap m-b-20">\n                            <label class="width-30-percent font-13"></label>\n                            <select class="form-control select-country-wrapper">\n                                ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n                                    ');
        if (this.address_country && this.address_country !== "") {
          __out.push('\n                                    <option value="');
          __out.push(__sanitize(country.code));
          __out.push('" ');
          if (country.code === this.address_country) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(country.text));
          __out.push('</option>\n                                    ');
        } else {
          __out.push('\n                                    <option value="');
          __out.push(__sanitize(country.code));
          __out.push('" ');
          if (country.code === 'US') {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(country.text));
          __out.push('</option>\n                                    ');
        }
        __out.push('\n                                ');
      }
    
      __out.push('\n                            </select>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class="modal-footer">\n                <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n                <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}