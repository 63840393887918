module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, transaction;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-lg transactions-select-modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header modal-modern">\n        ');
    
      if (this.isTransaction) {
        __out.push('\n          <h1 class="modal-title font-semi-bold">Add Transactions</h1>\n        ');
      } else {
        __out.push('\n          <h1 class="modal-title font-semi-bold">Add Documents</h1>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class=\'\'>\n        <div class="transaction-filter-container"></div>\n        <div class="transaction-list-region">\n          ');
    
      if (this.transactions.length > 0) {
        __out.push('\n            <table class="table">\n              <tbody>\n                ');
        ref = this.transactions;
        for (i = 0, len = ref.length; i < len; i++) {
          transaction = ref[i];
          __out.push('\n                  ');
          if (!(this.paymentAccountId && transaction.reconciliation_payment_account && this.paymentAccountId === transaction.reconciliation_payment_account.id)) {
            __out.push('\n                    <tr class="transaction-item" data-id="');
            __out.push(__sanitize(transaction.id || transaction.service_document_id));
            __out.push('">\n                      <td class="transaction-select-checkbox-column">\n                        <div class="checkbox checkbox-primary m-l-15 m-r-15">\n                          <input type="checkbox" class="pointer" ');
            __out.push(__sanitize(transaction.is_checked ? 'checked' : ''));
            __out.push(' ');
            __out.push(__sanitize((this.paymentAccountId && transaction.reconciliation_payment_account && this.paymentAccountId === transaction.reconciliation_payment_account.id) ? 'disabled' : ''));
            __out.push('>\n                          <label></label>\n                        </div>\n                      </td>\n                      <td class="transaction-item-date-column">\n                        <p>\n                          ');
            if (transaction.qbo_id) {
              __out.push('\n                            <span class="icon-qbo-on font-20"></span>\n                          ');
            } else {
              __out.push('\n                            <span class="icon-qbo-off font-20"></span>\n                          ');
            }
            __out.push('\n\n                          ');
            if (this.isTransaction) {
              __out.push('\n                            <span class="m-l-10">');
              __out.push(__sanitize(moment(transaction.transaction_date).format('D MMMM YYYY')));
              __out.push('</span>\n                          ');
            } else if (transaction.vendor) {
              __out.push('\n                            <span class="m-l-10">');
              __out.push(__sanitize(transaction.vendor.name));
              __out.push('</span>\n                          ');
            }
            __out.push('\n                        </p>\n                      </td>\n                      ');
            if (!this.isTransaction && !transaction.received_at) {
              __out.push('\n                      <td>\n                        <span class="m-l-10">');
              __out.push(__sanitize(transaction.invoice_number));
              __out.push('</span>\n                      </td>\n                      ');
            }
            __out.push('\n                      <td class="">\n                        <span>\n                          ');
            if (this.isTransaction) {
              __out.push('\n                            ');
              __out.push(__sanitize(transaction.description));
              __out.push('\n                          ');
            } else if (transaction.document_type === 'Receipt') {
              __out.push('\n                            ');
              __out.push(__sanitize(moment(transaction.received_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
              __out.push('\n                          ');
            } else {
              __out.push('\n                            ');
              __out.push(__sanitize(moment(transaction.invoice_date, 'MM/DD/YYYY').format(Docyt.Common.Constants['US_DATE_FORMAT'])));
              __out.push('\n                          ');
            }
            __out.push('\n                        </span>\n                      </td>\n                      ');
            if (this.isTransaction) {
              __out.push('\n                        <td class="">\n                          <span>\n                              ');
              __out.push(__sanitize(transaction.reconciliation_payment_account ? transaction.reconciliation_payment_account.name : ''));
              __out.push('\n                          </span>\n                        </td>\n                      ');
            }
            __out.push('\n                      <td class="text-right">\n                        <span class="font-14 font-semi-bold">\n                          ');
            if (this.isTransaction) {
              __out.push('\n                            ');
              __out.push(__sanitize(parseFloat(transaction.amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2
              })));
              __out.push('\n                          ');
            } else {
              __out.push('\n                            ');
              __out.push(__sanitize(parseFloat(transaction.amount_verified).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2
              })));
              __out.push('\n                          ');
            }
            __out.push('\n                        </span>\n                      </td>\n                    </tr>\n                  ');
          }
          __out.push('\n                ');
        }
        __out.push('\n              </tbody>\n            </table>\n          ');
      } else {
        __out.push('\n            <div class="m-t-100 text-center">\n              <p class="font-28 font-semi-bold">There are no transactions to be matched</p>\n              <p class="font-18 padding-24 m-l-12 m-r-12">The bank feed is missing the counter transaction either due to delay in the feed or because the counter bank account has not been added to Docyt</p>\n            </div>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <div class="modal-footer">\n        <button type="button" class="cancel-link cancel modal-link-btn">Back</button>\n        ');
    
      if (this.isTransaction) {
        __out.push('\n          <button type="button" class="btn btn-blue btn-add-transaction ');
        __out.push(__sanitize(!this.isTransactionChecked ? 'disabled' : ''));
        __out.push('">Add Transactions</button>\n        ');
      } else {
        __out.push('\n          <button type="button" class="btn btn-blue btn-add-transaction">Add Documents</button>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}