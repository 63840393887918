import React from 'react';

import Filter from '@src/components/ui_v2/filter';

import styles from '../styles.module.scss';

const SelectMergeableVendorFilter = () => {
  return (
    <Filter.TableContainer className={ styles['table-filter'] }>
      <Filter.TextField
        name="vendor"
        placeholder="Search Vendor"
        showTooltip={ false }
        size="compact"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(SelectMergeableVendorFilter);
