import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import VerifyBankFeedModal from './bank_statement_details/verify_bank_feed/verify_bank_feed_modal';

interface IReviewedDetailsVerifyPageProps {
  businessId: TID,
  docytId: string,
  isAdmin: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const ReviewedDetailsVerifyPage = ({
  businessId,
  docytId,
  isAdmin,
  onDone,
  onCancel,
}: IReviewedDetailsVerifyPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <VerifyBankFeedModal
          isOpen
          businessId={ businessId }
          docytId={ docytId }
          isAdmin={ isAdmin }
          onCancel={ onCancel }
          onDone={ onDone }
        />
      </BusinessProvider>
    </Root>
  );
};

export default ReviewedDetailsVerifyPage;
