import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { TAccountType } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import AccountOwnerField from '@src/components/common/form_fields/account_owner_field';
import Form from '@src/components/ui/form';

import { ISetupNewEployeeCardInputData, setupNewEployeeCardInputDataValidation } from './schema';

interface IEmployeeCardFormProps {
  accountType: TAccountType,
  business: IBusiness,
  formId: string,
  rcPaymentAccount: IReconciliationPaymentAccount | undefined
  onSubmit: (data: ISetupNewEployeeCardInputData) => void;
}

const EmployeeCardForm = ({
  accountType,
  formId,
  rcPaymentAccount,
  onSubmit,
}: IEmployeeCardFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ISetupNewEployeeCardInputData>({
    mode:     'onBlur',
    resolver: yupResolver(setupNewEployeeCardInputDataValidation),
  });

  const accountOwnerController = useController({ name: 'accountOwner', control });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Bank Name"
        placeholder="Bank Name - XXXX"
        { ...register('name') }
      />
      <Form.TextField
        error={ errors.last4?.message }
        label={ accountType === 'bank' ? 'Debit Card No.' : 'Credit Card No.' }
        mark="(last 4 digits)"
        maxLength={ 4 }
        placeholder="XXXX"
        { ...register('last4') }
      />
      <Form.TextField
        error={ errors.cvv?.message }
        label="CVV #"
        mark="(optional)"
        placeholder="CVV #"
        { ...register('cvv') }
      />
      <AccountOwnerField
        rcPaymentAccountId={ rcPaymentAccount?.id }
        { ...accountOwnerController.field }
      />
    </Form>
  );
};

export default EmployeeCardForm;
