/* eslint-disable react/jsx-no-useless-fragment */

import React, { useCallback, useMemo, useState } from 'react';

import moment from 'moment';

import { useExportReportData } from '@src/hooks/queries/report_service/custom_reports';
import { TDateFilter } from '@src/types/filter';
import { API_DATE_FORMAT } from '@src/utils/date_helpers';

import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { useFilterData } from '@src/components/ui_v2/filter';
import { useSection } from '@src/components/utils_v2/section';

const MultiReportsExportFooter = ({ multiReportId }: { multiReportId: string }) => {
  const section = useSection();
  const filterData = useFilterData(section);

  const filterDate = useMemo(() => (
    filterData?.reportDataExport as any
  ), [filterData]);
  const downloadReport = useExportReportData();
  const { mutate } = downloadReport;

  const [loading, setLoading] = useState<boolean>(false);

  const dateRange: TDateFilter | undefined = useMemo(() => {
    if (filterDate && filterDate.gte !== '' && filterDate.lte !== '') {
      return {
        gte: moment(filterDate.gte).startOf('month').format(API_DATE_FORMAT),
        lte: moment(filterDate.lte).endOf('month').format(API_DATE_FORMAT),
      }
    }

    return undefined;
  }, [filterDate]);

  const handleClick = useCallback(() => {
    if (dateRange) {
      setLoading(true);

      const param = {
        export_type:              'multi_entity',
        multi_business_report_id: multiReportId,
        start_date:               dateRange.gte as string,
        end_date:                 dateRange.lte as string,
        filter: {
          is_zero_rows_hidden:    false,
          last_year:              false,
          per_available_room:     false,
          per_occupied_room:      false
        }
      }

      mutate(param, 
        { onSuccess: () => setLoading(false),
          onError: () => setLoading(false)
        },
      );
    }
    
  }, [dateRange, multiReportId, mutate]);

  return (
    <>
      {loading 
        ? (<Spinner />)
        : (
          <div className="reports-export-footer multi-reports-footer">
            <div className="action-content">
              <a className="pointer text-color" role="button" tabIndex={ -1 }>
                Save Details
              </a>
              {!dateRange ? (
                <Button disabled variant="primary">Export</Button>
              ) : (
                <Button variant="primary" onClick={ handleClick }>Export</Button>
              )}
            </div>
          </div>
        )}
    </>
  );
}

export default MultiReportsExportFooter;