import React, { FC } from 'react';

import { TFilterData } from '@src/types/filter';

import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { InsideThisMonthIcon, OutsideThisMonthIcon, MissingInGLIcon } from '@src/components/utils/icomoon';

interface Props {
  queryParams: TFilterData,
}

const Component: FC<Props> = () => {
  const visibleColumnNames = useVisibleColumnNames();
  const statusOptions: TIconOption[] = [
    {
      icon:  <InsideThisMonthIcon fontSize={ 18 } />,
      label: 'In this month',
      value: 'inside_this_period',
    },
    {
      icon:  <OutsideThisMonthIcon fontSize={ 18 } />,
      label: 'Outside this month',
      value: 'outside_this_period',
    },
    {
      icon:  <MissingInGLIcon fontSize={ 18 } />,
      label: 'Missing in GL',
      value: 'missing_in_qbo',
    },
  ];

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('transaction_date') && (
      <Filter.DateField
        name="transaction_date"
      />
      )) }

      { (visibleColumnNames.includes('transaction_type') && (
      <Filter.TextField
        label="Txn Type"
        name="transaction_type"
        placeholder="Txn Type"
      />
      )) }

      { (visibleColumnNames.includes('name') && (
      <Filter.TextField
        label="Name"
        name="name"
        placeholder="Name"
      />
      )) }

      { (visibleColumnNames.includes('memo') && (
      <Filter.TextField
        label="Memo"
        name="memo"
        placeholder="Memo"
      />
      )) }

      { (visibleColumnNames.includes('split') && (
      <Filter.TextField
        label="Split"
        name="split"
        placeholder="Split"
      />
      )) }

      { (visibleColumnNames.includes('amount') && (
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
      )) }

      <Filter.IconSelectField
        isSearchable={ false }
        name="status"
        options={ statusOptions }
        placeholder="Select Status"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
