module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="setup-business-step-section setup-business-qbo-section ');
    
      if (this.for_onboarding) {
        __out.push(__sanitize('setup-client-qbo-section'));
      }
    
      __out.push('">\n  <div class="main-panel">\n    <h2 class="panel-title">');
    
      __out.push(__sanitize(!this.for_onboarding ? 'Connect QuickBooks' : 'Connect to Accounting Software'));
    
      __out.push('</h2>\n    <p class="panel-description">\n      ');
    
      if (!this.for_onboarding) {
        __out.push('\n      Each business should have its own QuickBooks account. Contact us if you don\'t have one.\n      ');
      } else {
        __out.push('\n      Docyt can automatically update a connected QuickBooks Online account.\n      ');
      }
    
      __out.push('\n    </p>\n    <div class="panel-fields">\n      <div class="form-group">\n        <label class="field-label bookkeeping-field-label">Bookkeeping Start Date</label>\n        <div class="field-input">\n          <div class="relative">\n            <input class="form-control bookkeeping-start-date-input" placeholder="MM/DD/YYYY" value="');
    
      __out.push(__sanitize(this.bookkeeping_start_date_str));
    
      __out.push('" readonly />\n            <a class="edit-bookkeeping-date-link edit-bookkeeping-date-js">Edit</a>\n          </div>\n          ');
    
      if (this.for_onboarding) {
        __out.push('\n          <div class="m-t-5">Docyt will start posting data to QuickBooks after this date.</div>\n          ');
      }
    
      __out.push('\n        </div>\n      </div>\n      <div class="form-group connect-form-group">\n        <label class="field-label">');
    
      __out.push(__sanitize(!this.for_onboarding ? 'Link QuickBooks' : 'Link to Accounting Software'));
    
      __out.push('</label>\n        <div class="field-input qbo-connected">\n          <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="qbo-disconnect-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n            <i class=\'icon icon-3-dots font-20\'></i>\n          </button>\n          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="qbo-disconnect-action-dropdown-btn">\n            <a class="dropdown-item font-16 pointer disconnect-qbo-js">\n              <span class=\'in-red-400\'>Disconnect</span>\n            </a>\n          </div>\n          <p class="qbo-connect-status">Connected</p>\n        </div>\n        <div class="field-input qbo-disconnected">\n          <a class="btn-connect-qbo qbo-connect-link-medium"></a>\n        </div>\n      </div>\n      ');
    
      if (this.isforceRefreshEnabled) {
        __out.push('\n        <div class="form-group connect-form-group">\n          <label class="field-label">Refresh report data</label>\n          <div class="field-input">\n            <button class="btn btn-info btn-save ladda-button refresh-report-link btn-refresh-reports ');
        if (this.status === 'running' || this.status === 'waiting') {
          __out.push(__sanitize('disabled'));
        }
        __out.push('" type="button">Refresh</button>\n            <span class="icon icon-info-circle" data-toggle="tooltip" data-placement="bottom" title="Force Refresh all reports of your business."></span>\n          </div>\n          <label class="field-label refresh-status">');
        __out.push(__sanitize(this.statusMessage));
        __out.push('</label>\n        </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}