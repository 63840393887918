import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { CheckCardIcon } from '@src/components/utils/icomoon';

interface IDocytCheckActionProps {
  invoiceIds: TID[],
}

const DocytCheckAction = ({
  invoiceIds,
}: IDocytCheckActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:docyt:check', invoiceIds);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <CheckCardIcon fontSize={ 18 } /> }
      name="Generate Physical Docyt Check"
      onClick={ handleSelect }
    />
  );
};

export default DocytCheckAction;
