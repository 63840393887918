import React from 'react';

import { TMongoID } from '@src/types/common';
import { TReportCategory } from '@src/types/report_service/report';

import BudgetsListPage from './budgets';
import BudgetDetailPage from './budgets/details';
import { ReportsListPage, ReportDetailPage, ReportOtherDetailPage } from './custom_reports';
import BusinessReportsExport from './export';
import ReportRefreshLogs from './refresh_logs/report_refresh_logs';

interface IBusinessReportsRouterProps {
  slug: string,
  budgetId: TMongoID,
  category: TReportCategory,
  reportId?: TMongoID,
  page?: string,
  itemIdentifier?: string,
  type?: string
}

const BusinessReportsRouter = ({
  slug,
  budgetId,
  category,
  page,
  itemIdentifier,
  type,
  reportId,
}: IBusinessReportsRouterProps): JSX.Element | null => {
  if (page === 'report-detail') {
    return <ReportDetailPage slug={ slug } />;
  }

  if (page === 'budgets-list') {
    return <BudgetsListPage />;
  }

  if (page === 'budget-detail') {
    return <BudgetDetailPage budgetId={ budgetId } />;
  }

  if (page === 'report-other-detail') {
    return <ReportOtherDetailPage itemIdentifier={ itemIdentifier as string } slug={ slug } />
  }
  
  if (page === 'report-export') {
    return <BusinessReportsExport category={ category } slug={ slug } type={ type as string } />;
  }

  if (page === 'report-refresh-logs' && reportId) {
    return <ReportRefreshLogs reportId={ reportId } />;
  }

  return <ReportsListPage category={ category } />;
};

export default React.memo(BusinessReportsRouter);
