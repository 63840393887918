import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

import { TID, TSection } from '@src/types/common';
import { TAmountFilter, TDateFilter, TFilterData } from '@src/types/filter';

import { getSearchParamsFromFilter, parseSearchParams } from './filter/url_helpers';

/**
 * Check if stored filter is associated with current business id
 * @param businessId - id of current business
 * @returns - `true` if stored filter is for the current business
 */
const isCurrentBusinessFilter = (businessId: TID) => {
  const serializedData = localStorage.getItem('filter');
  if (!serializedData) return false;

  const oldBusinessId = JSON.parse(serializedData)?.business_id;
  if (!oldBusinessId) return false;

  return oldBusinessId === String(businessId);
};

const restoreFilterFromStorage = ({
  businessId,
  section,
}: TSection): TFilterData | undefined => {
  if (businessId && !isCurrentBusinessFilter(businessId)) return undefined;

  const serializedFilter = localStorage.getItem(section);
  if (!serializedFilter) return undefined;

  let filterData: TFilterData | undefined;
  const storedFilter = JSON.parse(serializedFilter);
  forEach(storedFilter, (value, key) => {
    if (key.endsWith('_order')) return;
    if (filterData && !isEmpty(filterData[key])) return;

    filterData = filterData || {};
    filterData[key] = value;
  });

  return filterData;
};

const storeFilterToStorage = (
  { businessId, section }: TSection,
  filterData: TFilterData | undefined,
) => {
  const storedData = JSON.parse(localStorage.getItem(section) || '{}');

  // Restore sorting params
  let newData = pickBy(storedData, (_, k) => k.endsWith('_order'));

  // Add filter params if needed;
  if (filterData) {
    newData = {
      ...newData,
      ...filterData,
    };
  }

  // If filter data are empty then remove data from storage and set empty search params
  if (isEmpty(newData)) {
    localStorage.removeItem(section);
    localStorage.removeItem('filter');

    return;
  }

  // Store new filter data (together with sorting) in local storage
  if (businessId) {
    localStorage.setItem('filter', JSON.stringify({ business_id: String(businessId) }));
  }
  localStorage.setItem(section, JSON.stringify(newData));
};

const buildURLParamName = (section: TSection) => `filter_${section.section}`;

const overrideFromURLParams = (section: TSection, initialData: TFilterData): TFilterData => {
  const paramName = buildURLParamName(section);
  const searchParams = new URLSearchParams(window.location.search);

  if (!searchParams.get(paramName)) return initialData;

  const filterParams = new URLSearchParams(
    decodeURIComponent(searchParams.get(paramName)!.toString()),
  );
  const searchData = parseSearchParams(filterParams);
  if (searchParams.get('filter_strategy') === 'merge') return { ...initialData, ...searchData };
  return searchData || initialData;
};

const reflectToURLParams = (section: TSection, filterData: TFilterData) => {
  const paramName = buildURLParamName(section);
  const searchParams = new URLSearchParams(window.location.search);
  let filterParams = new URLSearchParams();
  if (searchParams.get(paramName)) {
    filterParams = new URLSearchParams(
      decodeURIComponent(searchParams.get(paramName)!.toString()),
    );
  }

  const newFilterParams = getSearchParamsFromFilter(filterData);
  if (newFilterParams.toString() !== filterParams.toString()) {
    if (newFilterParams.toString() === '') {
      searchParams.delete(paramName);
      searchParams.delete('filter_strategy');
    } else {
      searchParams.set(paramName, newFilterParams.toString());
    }
    let url = window.location.pathname;
    if (searchParams.toString() !== '') {
      url += `?${searchParams}`;
    }
    Backbone.history.navigate(url, { replace: true });
  }
};

const amountFilterToQuery = (
  amountFilter: TAmountFilter | undefined,
  prefix: string = 'amount',
  suffix: string = 'val',
): object | undefined => {
  if (!amountFilter) return undefined;

  if (amountFilter.val) {
    return {
      [`${prefix}_${suffix}`]: amountFilter.val,
    };
  }

  if (amountFilter.gte || amountFilter.lte) {
    return {
      [`${prefix}_min`]: amountFilter.gte,
      [`${prefix}_max`]: amountFilter.lte,
    };
  }

  return undefined;
};

const dateFilterToQuery = (
  dateFilter: TDateFilter | undefined,
  prefix: string = 'date',
): object | undefined => {
  if (!dateFilter) return undefined;

  return {
    [`${prefix}Start`]: dateFilter.gte,
    [`${prefix}End`]:   dateFilter.lte,
  };
};

export {
  restoreFilterFromStorage,
  storeFilterToStorage,
  overrideFromURLParams,
  reflectToURLParams,
  amountFilterToQuery,
  dateFilterToQuery,
};
