import React from 'react';

import { IChartOfAccount } from '@src/types/report_service/report';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import AcceptedAccountsAction from './accepted_accounts_action';
import AcceptedAccountsList from './accepted_accounts_list';

interface IAcceptedAccountsProps {
  chartOfAccounts: IChartOfAccount[],
  isBusinessAdmin: boolean,
  isReportServiceAdmin: boolean,
}

const AcceptedAccounts = ({
  chartOfAccounts,
  isBusinessAdmin,
  isReportServiceAdmin,
}: IAcceptedAccountsProps): JSX.Element => {
  return (
    <ItemsSelectorProvider allItems={ chartOfAccounts }>
      <div className="accept-account-type-region display-flex align-items-center justify-content-between width-100-percent p-l-20 p-t-5 p-r-20 p-b-5">
        <AcceptedAccountsList chartOfAccounts={ chartOfAccounts } />
        <AcceptedAccountsAction
          chartOfAccounts={ chartOfAccounts }
          isBusinessAdmin={ isBusinessAdmin }
          isReportServiceAdmin={ isReportServiceAdmin }
        />
      </div>
    </ItemsSelectorProvider>
  );
};

export default AcceptedAccounts;
