import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Action from './open_items_sidebar_action';
import OpenItemsSidebarMenuItems from './open_items_sidebar_menu_items';

interface IOpenItemsSidebarProps {
  businessId: TID,
  isGlobalnavigationNewUi?: boolean
}

const OpenItemsSidebar = ({
  businessId,
  isGlobalnavigationNewUi = false,
}: IOpenItemsSidebarProps): JSX.Element => {
  return (
    <Root>
      {isGlobalnavigationNewUi ? (
        <OpenItemsSidebarMenuItems
          businessId={ businessId }
        />
      ) : (
        <Action
          businessId={ businessId }
        />
      )}
    </Root>
  );
};

export default OpenItemsSidebar;
