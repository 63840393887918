module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, state;
    
      __out.push('<div class="setup-business-step-section setup-ar-section">\n  <div class="main-panel">\n    <div class="profile-header">\n      <h2 class="panel-title">W9 Information</h2>\n      <p class="panel-description">\n        To be in compliance with federal laws, some additional information about your business is needed.\n      </p>\n    </div>\n    <div class="profile-body">\n      <!-- Legal Info -->\n      <div class="profile-input-wrap">\n        <label>Legal Entity Name</label>\n        <input type="text" class="profile-input width-60-percent form-control input-entity-name" placeholder="Legal Entity Name" value = "');
    
      __out.push(__sanitize(this.dwollaCustomer.get('business_name')));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Legal Entity Type</label>\n        <select class="profile-input width-60-percent form-control input-entity-type" placeholder="Select Business Type">\n          ');
    
      if (!this.businessType) {
        __out.push('\n          <option></option>\n          ');
      }
    
      __out.push('\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["SOLE"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Sole Proprietorship</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["CORP"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["CORP"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Corporation</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["LLC"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["LLC"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>LLC</option>\n          <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["PARTNER"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["PARTNER"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Partnership</option>\n        </select>\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <!-- Legal Entity Address -->\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Legal Entity Address</label>\n        <input type="text" class="profile-input width-60-percent form-control input-address-street" placeholder="Street" value = "');
    
      __out.push(__sanitize(this.w9_address.get('address_line1') || this.address_street));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <input type="text" class="address-input form-control input-address-city" placeholder="City" value = "');
    
      __out.push(__sanitize(this.w9_address.get('address_city') || this.address_city));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n        <select class="address-input form-control input-address-state" placeholder="State" >\n          ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        state = usaStateList[i];
        __out.push('\n          <option value="');
        __out.push(__sanitize(state.abbreviation));
        __out.push('" ');
        if ((this.w9_address.get('address_state') || this.address_state) === state.abbreviation) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(state.text));
        __out.push('</option>\n          ');
      }
    
      __out.push('\n        </select>\n        <input type="text" class="address-input form-control input-address-zip" placeholder="Zip Code" value = "');
    
      __out.push(__sanitize(this.w9_address.get('address_zip') || this.address_zip));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <!-- Tax Number -->\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left label-ein-id">Taxpayer Identification Number (TIN)</label>\n        <input type="text" class="profile-input width-60-percent form-control input-ein-id" placeholder="Enter 9 digits TIN" value = "');
    
      __out.push(__sanitize(this.ein));
    
      __out.push('">\n        <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div>\n      <!-- SSN Number -->\n      <div class="profile-ssn-wrap">\n        <div class="width-40-percent text-center">or</div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent text-left label-ssn-id">Social Security Number (SSN)</label>\n          <input type="text" class="profile-input width-60-percent form-control input-ssn-id" placeholder="Enter 9 digits SSN" value = "');
    
      __out.push(__sanitize(this.ein_number));
    
      __out.push('">\n          <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}