import { IClientBusiness, TClientBusinessesSortColumn } from '@src/types/clients';
import { TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetOnboardingStatusClientBusinessesParams {
  page?: string,
  perPage?: number,
  searchQuery?: string,
  orderColumn?: TClientBusinessesSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetOnboardingStatusClientBusinessesResponse {
  meta: {
    totalCount: number,
    searchCount: number,
  },
  collection: IClientBusiness[],
}

const getOnboardingStatusClientBusinesses = (
  params: IGetOnboardingStatusClientBusinessesParams,
): Promise<IGetOnboardingStatusClientBusinessesResponse> => {
  return apiGet(
    '/api/v1/onboarding_status/client_businesses',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount:  cdata.meta.totalCount,
        searchCount: cdata.meta.searchCount,
      },
      collection: cdata.clientBusinesses as IClientBusiness[],
    };
  });
};

export {
  IGetOnboardingStatusClientBusinessesParams,
  IGetOnboardingStatusClientBusinessesResponse,
  getOnboardingStatusClientBusinesses,
};
