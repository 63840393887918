import React from 'react';

import classNames from 'classnames';

import Panel from '@src/components/ui_v2/panel';

import styles from './styles.module.scss';

interface ITableSectionPanelProps extends React.ComponentPropsWithoutRef<typeof Panel> {
  filter?: React.ReactNode,
  tableSettings?: React.ReactNode,
  hideBorder?: boolean,
}

const TableSectionPanel = ({
  children,
  className,
  filter,
  tableSettings,
  hideBorder,
  ...props
}: ITableSectionPanelProps): JSX.Element => {
  const classes = classNames(
    styles['table-section-panel'],
    className,
    {
      [styles['table-section-panel-no-border']]: hideBorder,
    },
  );

  return (
    <Panel className={ classes } { ...props }>
      { filter && (
        <div className={ styles['table-section-panel-filter'] }>
          { filter }
          { tableSettings }
        </div>
      ) }
      { children }
    </Panel>
  );
};

export default TableSectionPanel;
