import React from 'react';

import { TQBOSyncStatus } from '@src/types/common';
import { IQBOLink } from '@src/types/qbo_links';

import Tooltip from '@src/components/ui/tooltip';
import { QBOBlockIcon, QBOErrorIcon, QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IQBOStatusModel {
  qboSyncStatus: TQBOSyncStatus,
  documentQboSyncStatus?: TQBOSyncStatus,
  qboLink?: IQBOLink,
}

interface IQBOStatusIconProps {
  linkToQBO?: boolean,
  model: IQBOStatusModel,
  showIndividualSyncStatus?: boolean
}

const QBOStatusIcon = ({
  linkToQBO,
  model,
  showIndividualSyncStatus,
}: IQBOStatusIconProps) => {
  let qboSyncStatus = model.qboSyncStatus;
  if (showIndividualSyncStatus) qboSyncStatus = model.documentQboSyncStatus || model.qboSyncStatus;

  if (qboSyncStatus === window.Docyt.Common.Constants.QBO_SYNC_STATUS.DISABLED) {
    return (
      <Tooltip
        as={ QBOBlockIcon }
        fontSize={ 22 }
        title={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_BLOCK }
      />
    );
  }

  if (qboSyncStatus === window.Docyt.Common.Constants.QBO_SYNC_STATUS.SYNCED) {
    return <QBOOnIcon fontSize={ 20 } mr={ 10 } />;
  }

  if (qboSyncStatus === window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED) {
    return (
      <Tooltip
        as={ QBOOffIcon }
        fontSize={ 20 }
        mr={ 10 }
        title={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_STARTED }
      />
    );
  }

  return (
    <Tooltip
      as={ QBOErrorIcon }
      fontSize={ 20 }
      mr={ 10 }
      title={ model.qboLink?.qboError || '' }
    />
  );
};

export default QBOStatusIcon;
