module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, ref, status;
    
      __out.push('<div class="status-steps-wrapper">\n  <div id="check-document-status-sidebar-accordion">\n    <div aria-controls="check-document-status-sidebar-accordion-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#check-document-status-sidebar-accordion-collapse" data-toggle="collapse" aria-expanded="true">\n      <i class="fa fa-caret-down m-r-5"></i>\n      <span class="font-bold">CHECK STATUS</span>\n    </div>\n  </div>\n  <div aria-labelledby="check-document-status-sidebar-accordion" class="p-l-10 p-r-10 collapse in document-detail-sidebar-section-body" id="check-document-status-sidebar-accordion-collapse" aria-expanded="true">\n    <div class="steps">\n      ');
    
      ref = this.tracking_status;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        status = ref[index];
        __out.push('\n        <div class="step ');
        if (index <= this.active_status_index) {
          __out.push(__sanitize('step-success'));
        }
        __out.push(' ');
        if (index === this.active_status_index) {
          __out.push(__sanitize(this.step_active_attr));
        }
        __out.push('">\n          <div class="step-name-wrapper">\n            <span class="step-circle"></span>\n            <span class="step-text">');
        __out.push(__sanitize(status.name));
        __out.push('</span>\n          </div>\n          <span class="step-date">');
        __out.push(__sanitize(this.checkStatusDate(index)));
        __out.push('</span>\n        </div>\n      ');
      }
    
      __out.push('\n    </div>\n    ');
    
      if (this.active_status) {
        __out.push('\n      <p class="status-detail">');
        __out.push(__sanitize(this.active_status.detail));
        __out.push('</p>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}