import React, { useCallback, useMemo, useState } from 'react';

import { components, GroupBase, StylesConfig } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { searchBusinessVendors } from '@src/requests/business_vendors';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import { VendorAvatar } from '@src/components/ui/avatars';
import { useFilterField } from '@src/components/ui/filter';
import Wrapper from '@src/components/ui/filter/filter_field_wrapper';
import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { AsyncSelectPaginateInput, IAsyncSelectPaginateInputProps } from '@src/components/ui/form';
import { PlusIcon } from '@src/components/utils/fa_icons';

interface IVendorSelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<IAsyncSelectPaginateInputProps<IBusinessVendor, any, boolean>, 'name' | 'value' | 'onChange' | 'className' | 'loadOptions'>,
  IFilterFieldUIProps
{
  name: keyof TFilter,
  businessId: TID,
}

const formatOptionLabel = (vendor: IBusinessVendor): JSX.Element => {
  const role = vendor.individualUserId ? 'Employee' : 'Vendor';

  if (vendor.vendorId === -1) {
    return (
      <div
        className="display-inline-flex width-100-percent border-top-gray"
        style={ { color: '#2e90e5', padding: '8px 12px' } }
      >
        <PlusIcon className="m-t-3 p-l-13 p-r-13" />
        <span>Create New Vendor</span>
      </div>
    );
  }

  return (
    <div
      className="display-inline-flex width-100-percent"
      style={ { padding: '8px 12px' } }
    >
      <VendorAvatar vendor={ vendor } />
      <span className="m-t-2 m-l-10">{ vendor.name }</span>
      <span className="m-t-2 m-l-auto m-r-0 width-60px in-grey-500 text-right">{ role }</span>
    </div>
  );
};

const getOptionLabel = (vendor: IBusinessVendor) => vendor.name;
const getOptionValue = (vendor: IBusinessVendor) => String(vendor.vendorId);

const SingleValue = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue { ...props }>
      { data.vendorId === -1 ? '' : data.name }
    </components.SingleValue>
  );
};

const customStyles: StylesConfig<IBusinessVendor, any> = {
  option: (provided) => (
    {
      ...provided,
      padding: 0,
    }
  ),
  menu: (provided) => (
    {
      ...provided,
      minWidth: '400px',
      width:    '400px',
      margin:   '2px 0 0',
    }
  ),
  menuList: (provided) => (
    {
      ...provided,
      padding: '0 0 2px',
    }
  ),
};

const VendorSelectFilterField = <TFilter extends TFilterData>({
  name,
  businessId,
  ...props
}: IVendorSelectFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string | null>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const [lastOptions, setLastOptions] = useState<IBusinessVendor[]>([]);

  const handleChange = useCallback((vendor) => {
    update(vendor && vendor.vendorId > 0 ? String(vendor.vendorId) : null);
  }, [update]);

  const handleSource: LoadOptions<IBusinessVendor, GroupBase<IBusinessVendor>, any> =
    useCallback((query, options, { page }) => {
      return searchBusinessVendors({ businessId, search: query, page })
        .then((data) => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          setLastOptions([...options as IBusinessVendor[], ...data.collection]);
          return {
            options:    data.collection,
            hasMore,
            additional: {
              page: page + 1,
            },
          };
        });
    }, [businessId]);

  const selectedItem = useMemo(() => {
    return lastOptions.find((i) => i.vendorId === value);
  }, [lastOptions, value]);

  return (
    <Wrapper { ...wrapperProps }>
      <AsyncSelectPaginateInput
        { ...inputProps }
        defaultOptions
        isClearable
        additional={ {
          page: 1,
        } }
        components={ {
          SingleValue,
        } }
        debounceTimeout={ 300 }
        formatOptionLabel={ formatOptionLabel }
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        id="vendor_select"
        loadOptions={ handleSource }
        styles={ customStyles }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(VendorSelectFilterField);
