import React from 'react';

import BankPaymentMethodImg from '@assets/images/billing/bank_account.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const BankPaymentMethodIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Bank Payment Method icon"
      src={ BankPaymentMethodImg }
      { ...props }
    />
  );
};

export default BankPaymentMethodIcon;
