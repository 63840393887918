import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

const getBusinessServicesInfo = (businessId: number): Promise<IBusinessServicesInfo> => {
  return apiGet(
    `/api/v1/businesses/${businessId}/services_info.json`,
  ).then((data) => camelizeKeys(data.business_services_information) as IBusinessServicesInfo);
};

export {
  getBusinessServicesInfo,
};
