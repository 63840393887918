import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IAngleUpIconProps extends Omit<IFaIconProps, 'icon'> {}

const AngleUpIcon = (props: IAngleUpIconProps): JSX.Element => {
  return <FaIcon icon="angle-up" { ...props } />;
};

export default AngleUpIcon;
