import React from 'react';

import { TID } from '@src/types/common';

import LikedBusinessesView from './linked_businesses_view';

import styles from './styles.module.scss';

interface IBusinessesViewProps {
  businessId: TID,
  vendorId: TID,
}

const BusinessesView = ({
  businessId,
  vendorId,
}: IBusinessesViewProps): JSX.Element | null => {
  return (
    <div className={ styles['linked-businesses-container'] }>
      <div>
        <span>
          Selecting edit will allow you to link this vendor&apos;s information
          to the selected businesses.
          Additionally all edits and changes to this vendor will pushed across
          all selected businesses.
        </span>
      </div>
      <LikedBusinessesView
        businessId={ businessId }
        vendorId={ vendorId }
      />
    </div>
  );
};

export default BusinessesView;
