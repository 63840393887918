import { IUserManagementGroupSettingValue } from '@src/types/user_management_group_settings';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

interface IUpdateManagementGroupSettingsParams {
  settingValues: IUserManagementGroupSettingValue[],
}

const updateManagementGroupSettings = (
  params: IUpdateManagementGroupSettingsParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/user_management_group_settings/upsert',
    underscoreKeys(params),
  );
};

export {
  IUpdateManagementGroupSettingsParams,
  updateManagementGroupSettings,
};
