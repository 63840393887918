module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-md modal-no-body-padding payment-account-select-modal\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title font-28 font-bold text-center">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <label class="m-t-20 m-b-30 m-l-30 m-r-30">Advanced settlement lets you settle an invoice or receipt without paying by moving the amount to a balance sheet account</label>\n            <div class="m-b-10 ');
    
      __out.push(__sanitize(this.isInvoice ? '' : 'hidden'));
    
      __out.push('">\n                <label class="form-label text-right" for="date-input">Date</label>\n                <div class="form-inline relative">\n                  <input type="text" id="date-input" class="form-control bg-grey-300" autocomplete="off">\n                </div>\n            </div>\n            <div class="m-b-10">\n                <label class="form-label text-right">Balance Sheet Account</label>\n                <div class="form-inline relative vendor-typeahead-wrapper">\n                    <input type="text" class="inline-wrapper form-control chart-of-account-input-js" placeholder="Select Category">\n                </div>\n            </div>\n            <div class="m-b-10">\n                <label class="form-label text-right">Amount</label>\n                <div class="form-inline relative">\n                  <label class="form-label">US$: ');
    
      __out.push(__sanitize(this.amount));
    
      __out.push('</label>\n                </div>\n            </div>\n            <div class="m-b-20">\n                <label class="form-label text-right">Memo</label>\n                <div class="form-inline relative">\n                  <input type="text" class="form-control bg-grey-300 memo-input" placeholder="Enter Memo">\n                </div>\n            </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Confirm</span>\n          </button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}