import React, { useCallback, useEffect, useMemo } from 'react';

import flatMapDeep from 'lodash/flatMapDeep';

import {
  useReconciliationAccountOptions,
} from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import MultiBankAccountsInput from '@src/components/common_v2/inputs/multi_bank_accounts_input';
import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import MultiSelect from '@src/components/ui_v2/inputs/multi_select';

interface IMultiBankAccountsFilterFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof MultiSelect>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  businessId: TID,
  dropDownField?: string,
}

const MultiBankAccountsFilterField = <TFilter extends TFilterData>({
  businessId,
  dropDownField = 'dropdown_field',
  ...props
}: IMultiBankAccountsFilterFieldProps) => {

  const [rpaValueObj, updateRpaObj] = useFilterField<TFilter, { [key: number]: string }>(
    dropDownField,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parentIds, updateParentIds] = useFilterField<TFilter, { [key: number]: string }>(
    'reconciliation_payment_account_id',
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [childId, updateChildIds] = useFilterField<TFilter, { [key: number]: string }>(
    'payment_account_id',
  );

  const rpaValue = useMemo(() => {
    return rpaValueObj ? Object.values(rpaValueObj) : [];
  }, [rpaValueObj]);
  const bankingAccountOptions = useReconciliationAccountOptions(businessId);

  const handleChange = useCallback((selectedOptions: string[]) => {
    const childSelectedIds = bankingAccountOptions.reduce((acc, account) => {
      if (account.children) {
        const { children } = account;
        const selectedChildren = children.filter((child) => selectedOptions.includes(child.value));
        acc.push(...selectedChildren.map((child) => child.value));
      }
      return acc;
    }, [] as Array<string>);

    const parentSelection = selectedOptions.filter((x) => !childSelectedIds.includes(x));

    const updateAllSelected = selectedOptions.reduce((obj, value, index) => {
      obj[index] = value;
      return obj;
    }, {} as { [key: number]: string });

    const updateParents = parentSelection.reduce((obj, value, index) => {
      obj[index] = value;
      return obj;
    }, {} as { [key: number]: string });

    const updatedChild = childSelectedIds.reduce((obj, value) => {
      obj.push(value);
      return obj;
    }, [] as string[]);

    updateChildIds(updatedChild);
    updateParentIds(updateParents);

    updateRpaObj(updateAllSelected);
  }, [updateRpaObj, bankingAccountOptions]);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const flatOptions = useMemo(() => flatMapDeep(bankingAccountOptions, (opt) => ('children' in opt && opt.children ? [opt, ...opt.children] : opt)), [bankingAccountOptions]);

  const hoverContent = useMemo(() => {
    if (!rpaValue || rpaValue.length === 0) return null;
    const selectedOptions = flatOptions.filter((opt) => rpaValue.includes(opt.value));
    return (
      <div>
        <div>Selected Items:</div>
        { selectedOptions.map((opt) => <div key={ opt.value }>{ opt.label }</div>) }
      </div>
    );
  }, [flatOptions, rpaValue]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ hoverContent }
    >
      <MultiBankAccountsInput
        { ...inputProps }
        businessId={ businessId }
        value={ rpaValue || [] }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(MultiBankAccountsFilterField);
