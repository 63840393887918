module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container reconciliation-center-activation-container">\n  <div class="padding-15 background-white m-l-auto m-r-auto businesses-setup-panel m-t-50">\n    <h2 class="font-24 font-semi-bold border-bottom-gray p-b-15 text-center m-t-0">Activate Reconciliation Center</h2>\n    <p>The following services are needed for Reconciliation Center to work properly.<br/>Please activate all of them to activate Reconciliation Center.</p>\n    <ul>\n      <li>\n        <div class="line-height-60 border-bottom-gray">\n          ');
    
      if (this.rp_service_ready) {
        __out.push('\n          <span class="pull-right" style="color: #00B887">&#10003; &nbsp; Active</span>\n          ');
      } else {
        __out.push('\n          <a href="/businesses/');
        __out.push(__sanitize(this.id));
        __out.push('/setup/receipt_box" class="pull-right">Activate</a>\n          ');
      }
    
      __out.push('\n          <span class="icon-receipt-box font-60 vertical-align-middle"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n          <span class="font-16 vertical-align-middle m-l-15">Receipt Box</span>\n        </div>\n      </li>\n      <li>\n        <div class="line-height-60 border-bottom-gray">\n          <span class="pull-right" style="color: #00B887">&#10003; &nbsp; Active</span>\n          <span class="icon-bank font-40 m-l-10 m-r-10 vertical-align-middle"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span></span>\n          <span class="font-16 vertical-align-middle m-l-15">Banking Accounts</span>\n        </div>\n      </li>\n    </ul>\n    <p><button type=\'button\' class="btn btn-info btn-block m-t-10 btn-activate-reconciliation-center" ');
    
      if (!this.rp_service_ready) {
        __out.push(' disabled="disabled" ');
      }
    
      __out.push('><span class="ladda-label">Activate Reconciliation Center</span></button></p>\n  </div>\n  <div class="p-b-25 text-center m-l-auto m-r-auto">\n    <a class="font-18 go-back-btn" href="/businesses/');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">I\'ll Setup Reconciliation Center Later</a>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}