import React from 'react';

import Dropdown from '@src/components/ui/dropdown';

/**
 * Actions dropdown menu items divider.
 * @visibleName ActionsDropdown.Divider
 */
const ActionsDropdownDivider = (): JSX.Element => {
  return (
    <Dropdown.Item divider />
  );
};

export default ActionsDropdownDivider;
