import React from 'react';

import { TWidgetViewCategory } from '@src/components/management_group_dashboard/types';
import { AggregateIcon, ComparisonIcon } from '@src/components/utils/icomoon';

import WidgetHeaderItem from './widget_header_item';

import styles from '../../styles.module.scss';

interface IWidgetHeaderProps {
  currentView: TWidgetViewCategory,
  onChange: (value: string) => void,
}

const WidgetHeader = ({
  currentView,
  onChange,
}: IWidgetHeaderProps): JSX.Element => {
  return (
    <div className={ styles['widget-header'] }>
      <WidgetHeaderItem
        identifier="aggregate"
        isActivated={ currentView === 'aggregate' }
        prefixIcon={ (
          <AggregateIcon
            fontSize={ 20 }
            variant={ currentView === 'aggregate' ? 'active' : undefined }
          />
        ) }
        title="Aggregate View"
        onClick={ onChange }
      />
      <WidgetHeaderItem
        identifier="comparison"
        isActivated={ currentView === 'comparison' }
        prefixIcon={ (
          <ComparisonIcon
            fontSize={ 20 }
            variant={ currentView === 'comparison' ? 'active' : undefined }
          />
        ) }
        title="Comparison View"
        onClick={ onChange }
      />
    </div>
  );
};

export default WidgetHeader;
