/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { IW9InfoResponse } from '@src/types/w9_info';

import { PlusIcon, Vendor1099Icon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface ITaxInfoProps {
  vendor: IW9InfoResponse,
}

const Reports1099 = ({
  vendor,
}: ITaxInfoProps) => {
  const [dataVendor, setDataVendor] = useState<IW9InfoResponse>(vendor);

  useEffect(() => {
    setDataVendor(vendor);
  }, [vendor]);

  return (
    <div className={ styles['section-content-container'] }>
      <div className={ styles.header }>
        <div className={ styles.item }>
          <span className={ styles.title }>1099 Reports</span>
        </div>
      </div>
      <div className={ styles['section-content'] }>
        <div className={ styles['no-data-container'] }>
          <Vendor1099Icon fontSize={ 60 } />
          <h4 className={ styles['no-data-label'] }>
            No 1099 reports generated yet.
          </h4>
          <span className={ styles['no-data-description'] }>
            This will be generated by the end of Jan 31st. Make sure W-9 info is
          </span>
          <span className={ styles['no-data-description'] }>
            updated before generating 1099 reports.
          </span>
          <span style={ { marginTop: '52px' } } />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Reports1099);
