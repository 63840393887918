import React from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import DeleteReportAction from './delete_report_action';
import EditBusinessAction from './edit_business_action';
import RenameReportAction from './rename_report_action';
import UpdateReportAction from './update_report_action';

interface IPortfoliListItemActionProps {
  action: string,
  businesses: IBusiness[],
  item: IMultiBusinessReportDetail,
  setAction: React.Dispatch<React.SetStateAction<string>>,
}

const PortfoliListItemAction = ({
  action,
  businesses,
  item,
  setAction,
}: IPortfoliListItemActionProps) => {
  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <RenameReportAction item={ item } />
      <EditBusinessAction
        action={ action }
        businesses={ businesses }
        item={ item }
        setAction={ setAction }
      />
      <UpdateReportAction item={ item } />
      <DeleteReportAction item={ item } />
    </ActionsDropdown>
  );
};

export default React.memo(PortfoliListItemAction);
