import React, { useCallback } from 'react';

import { UseQueryResult } from 'react-query';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { revenueServiceReportPath } from '@src/routes';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { IRevenueServiceDocument, TRevenueServiceDocumentsSortColumn } from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { ISorting } from '@src/types/sorting';

import { amountForPaymentProcessor, reconciledForPaymentProcessor } from '@src/components/revenue_center/utils';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { FileTextIcon } from '@src/components/utils/fa_icons';

import StatusIcon from '../action_icons/status_icon';
import Filter from './filter';

interface IReconcileRevenueTableProps {
  query: UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
  records: IRevenueServiceDocument[],
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
  currentPaymentProcessor: IPaymentProcessor,
}

const statusIcon = (document: IRevenueServiceDocument, paymentProcessor: IPaymentProcessor) => {
  return <StatusIcon document={ document } paymentProcessor={ paymentProcessor } />;
};
const actions = (revenueService: IRevenueService, revenue: IRevenueServiceDocument) => {
  return (
    <ActionsDropdown.LinkAction
      disabled={ !revenue.document }
      href={ revenueServiceReportPath(revenueService.businessId, revenue.id) }
      icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
      title="View Revenue Report"
    />
  );
};

const ReconcileRevenueTable = ({
  query,
  records,
  sorting,
  currentPaymentProcessor,
}: IReconcileRevenueTableProps) => {
  const revenueService = useRevenueServiceContext();
  const handleRowClick = useCallback((revenue: IRevenueServiceDocument) => {
    if (!revenue.document) return;
    Backbone.history.navigate(
      revenueServiceReportPath(revenueService.businessId, revenue.id),
      { trigger: true },
    );
  }, [revenueService.businessId]);
  const handleIsRowSelectable = useCallback((revenue: IRevenueServiceDocument) => {
    return !reconciledForPaymentProcessor(revenue, currentPaymentProcessor.id);
  }, [currentPaymentProcessor]);
  return (
    <CollectionTable<IRevenueServiceDocument, TRevenueServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ <Filter paymentProcessor={ currentPaymentProcessor } /> }
      height="750px"
      idField="rowKey"
      isRowSelectable={ handleIsRowSelectable }
      query={ query }
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.DateColumn<IRevenueServiceDocument, TRevenueServiceDocumentsSortColumn>
        name="end_date"
        sortColumn="end_date"
        title="Date"
        value={ (revenue) => revenue.endDate }
        width="50%"
      />
      <CollectionTable.AmountColumn<IRevenueServiceDocument, TRevenueServiceDocumentsSortColumn>
        name="amount"
        sortColumn="amount"
        title={ `${currentPaymentProcessor.name} Amount` }
        value={ (revenue) => {
          return amountForPaymentProcessor(revenue, currentPaymentProcessor.id);
        } }
        width="50%"
      />
      <CollectionTable.IconColumn<IRevenueServiceDocument>
        name="status"
        title="Status"
        value={ (revenue) => {
          return statusIcon(revenue, currentPaymentProcessor);
        } }
      />
      <CollectionTable.ActionsColumn<IRevenueServiceDocument>
        name="actions"
        title="Actions"
        value={ (document) => actions(revenueService, document) }
      />
    </CollectionTable>
  );
};

export default React.memo(ReconcileRevenueTable);
