import React, { useCallback, useMemo, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetBalanceSheetStatementByDocytId, useVerifyBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { updateBalanceSheetStatement } from '@src/requests/balance_sheet_statements';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import MutationStatus from '@src/components/utils/mutation_status';

import DetailForm, { IBalanceSheetStatementDetailsFormValues } from './details_form';
import Title from './details_modal_title';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

const formId = 'balance_sheet_document_verify_modal';

type TOpenValue = {
  docytId: string,
}

interface IBalanceSheetStatementVerifyModalProps extends IUseModalProps<TOpenValue> {
  businessId: TID,
  isOpen: boolean,
  onCancel: () => void,
  onCloseModal?: () => void,
  onDone: (balanceSheetStatement?: IBalanceSheetStatement) => void,
}

const BalanceSheetStatementVerifyModal = ({
  businessId,
  isOpen,
  openValue,
  onDone,
  onCancel,
  onCloseModal,
}: IBalanceSheetStatementVerifyModalProps) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const { docytId } = openValue || {};

  const getBankStatementByDocytIdQuery =
    useGetBalanceSheetStatementByDocytId(businessId, docytId, isOpen);
  const balanceSheetStatement = getBankStatementByDocytIdQuery.data?.balanceSheetStatement;

  const isVerifiedStatement = useMemo(() => {
    return balanceSheetStatement?.state
            === window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.VERIFIED;
  }, [balanceSheetStatement?.state]);

  const verify = useVerifyBalanceSheetStatement();
  const { mutate: verifyBalanceSheetStatement } = verify;

  const handleSubmit = useCallback(() => {
    if (!balanceSheetStatement || isVerifiedStatement) return;

    verifyBalanceSheetStatement({
      businessId,
      id: balanceSheetStatement.id,
    }, {
      onSuccess: () => onDone(),
    });
  }, [balanceSheetStatement, isVerifiedStatement, verifyBalanceSheetStatement, businessId, onDone]);

  const handleChange = useCallback((value: IBalanceSheetStatementDetailsFormValues) => {
    if (!balanceSheetStatement) return;

    updateBalanceSheetStatement({
      businessId,
      id:               balanceSheetStatement.id,
      chartOfAccountId: value.chartOfAccountId,
      date:             value.date,
      description:      value.description,
    }).catch((error) => {
      if (error?.response?.data?.errors[0] === undefined) return;

      setErrorMsg(error?.response?.data?.errors[0]);
    });
  }, [balanceSheetStatement, businessId]);

  const handleCancel = useCallback(() => {
    if (onCloseModal) onCloseModal();

    onCancel();
  }, [onCancel, onCloseModal]);

  if (!balanceSheetStatement) return null;

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <MutationStatus mutation={ verify } />
      <Modal
        showClose
        className={ styles['balance-sheet-statement-upload-modal'] }
        show={ isOpen }
        title={ (
          <Title
            balanceSheetStatement={ balanceSheetStatement }
            businessId={ businessId }
            title={ isVerifiedStatement ? 'Reviewed' : 'Pending Review' }
            onActionSuccess={ onCloseModal }
          />
        ) }
        onCancel={ handleCancel }
      >
        <Modal.Body>
          { isOpen && (
            <DetailForm
              balanceSheetStatement={ balanceSheetStatement }
              businessId={ businessId }
              formId={ formId }
              isVerifiedStatement={ isVerifiedStatement }
              onChange={ handleChange }
              onSubmit={ handleSubmit }
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ handleCancel }
          />
          <Button
            bsColor="blue"
            className={ styles['save-and-close-btn'] }
            disabled={ isVerifiedStatement }
            form={ formId }
            type="submit"
          >
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MemoizedBalanceSheetStatementVerifyModal = React.memo(BalanceSheetStatementVerifyModal);
const useBalanceSheetStatementVerifyModal = makeUseModal<
  typeof MemoizedBalanceSheetStatementVerifyModal,
    TOpenValue>(MemoizedBalanceSheetStatementVerifyModal);

export {
  IBalanceSheetStatementVerifyModalProps,
  useBalanceSheetStatementVerifyModal,
  BalanceSheetStatementVerifyModal as default,
};
