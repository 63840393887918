import React, { useCallback } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

import { TColumn, TModel } from './types';

interface ICollectionTableSettingsItemProps<
  Model extends TModel, TSortColumn extends string = never
> {
  column: TColumn<Model, TSortColumn>,
  onToggle?: (column: TColumn<Model, TSortColumn>, checked: boolean) => void,
}

const CollectionTableSettingsItem = <Model extends TModel, TSortColumn extends string = never>({
  column,
  onToggle,
}: ICollectionTableSettingsItemProps<Model, TSortColumn>) => {
  const handleSelect = useCallback((checked) => {
    if (!onToggle) return;

    onToggle(column, checked);
  }, [column, onToggle]);

  if (column.type === 'actions' || column.type === 'icon') return null;

  return (
    <Dropdown.CheckboxItem
      checked={ !column.hidden }
      value={ column.name }
      onSelect={ handleSelect }
    >
      { column.title }
    </Dropdown.CheckboxItem>
  );
};

export default CollectionTableSettingsItem;
