import React, { useMemo } from 'react';

import sortedUniq from 'lodash/sortedUniq';
import { UseInfiniteQueryResult } from 'react-query';

import { rcMonthEndDocmentsOtherDocumentsPath } from '@src/routes';
import { IBalanceSheetStatement, TStatementDetailsSortColumn } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';
import { formatApiDate, formatMonth, getUTCTimezone, formatApiYear } from '@src/utils/date_helpers';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IStatementDetailsFilter } from './filter';
import Table from './table';

import styles from './styles.module.scss';

interface IStatementDetailsListProps {
  businessId: TID,
  filter: IFilter<IStatementDetailsFilter>;
  balanceSheetStatements: IBalanceSheetStatement[],
  chartOfAccountId: TID,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TStatementDetailsSortColumn>,
  chartOfAccoutName: string,
  onCloseDetailsModal?: () => void,
  onUploadFile: () => void,
}

const StatementDetailsList = ({
  businessId,
  filter,
  balanceSheetStatements,
  chartOfAccountId,
  query,
  sorting,
  chartOfAccoutName,
  onCloseDetailsModal,
  onUploadFile,
}: IStatementDetailsListProps): JSX.Element => {
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  const formatNames = (
    names: string[],
  ): string => {
    return names.filter(Boolean).join(',');
  };

  const formatState = (
    states: string[],
  ): string => {
    let statementState = 'missing';
    if (states.every((state) => state === 'requested')) {
      statementState = 'requested';
    } else if (states.every((state) => state === 'verified')) {
      statementState = 'verified';
    } else if (states.every((state) => ['uploaded', 'verified'].includes(state))) {
      statementState = 'uploaded';
    } else if (states.every((state) => ['uploaded', 'verified', 'requested'].includes(state))) {
      statementState = 'requested';
    } else if (states.some((state) => state === 'not_available')) {
      statementState = 'not_available';
    }
    return statementState;
  };

  const values: any = useMemo(() => {
    const endDate = filter.data?.year
      ? new Date(parseInt(filter.data?.year, 10), 11, 31) : new Date();

    const nextDate = filter.data?.year
      ? new Date(parseInt(filter.data?.year, 10), 0, 1) : new Date();

    const days = [];

    for (let i = nextDate; i <= endDate; nextDate.setMonth(nextDate.getMonth() + 1)) {
      days.push(formatApiDate(nextDate));
    }
    return days.map((d) => {
      const index = balanceSheetStatements.findIndex(
        (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
           === formatMonth(getUTCTimezone(new Date(d))),
      );

      if (index === -1) {
        return {
          month:          formatMonth(getUTCTimezone(new Date(d))),
          state:          '',
          uploadedByName: '',
          verifiedByName: '',
        };
      }
      const statements = balanceSheetStatements.filter(
        (s) => formatMonth(getUTCTimezone(new Date(s.date)))
          === formatMonth(getUTCTimezone(new Date(d))),
      );
      return {
        month:          formatMonth(getUTCTimezone(new Date(d))),
        state:          formatState(sortedUniq(statements.map((st) => st.state))),
        uploadedByName: formatNames(sortedUniq(statements.map((st) => st.uploadedByName))),
        verifiedByName: formatNames(sortedUniq(statements.map((st) => st.verifiedByName))),
      };
    });
  }, [filter.data?.year, balanceSheetStatements]);

  const year = filter.data?.year
    ? formatApiYear(new Date(parseInt(filter.data?.year, 10), 0, 1))
    : formatApiYear(new Date());

  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        breadcrumbs={ [
          { href: rcMonthEndDocmentsOtherDocumentsPath(businessId), title: 'Other Documents' },
        ] }
        title={ chartOfAccoutName }
      >
        <Button
          className={ styles['upload-statement-button'] }
          prefixIcon={ <PlusIcon className="font-bold" fontSize={ 14 } /> }
          variant="primary"
          onClick={ onUploadFile }
        >
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10">
        <Filter
          filter={ filter }
        />
        <Table
          balanceSheetStatements={ values }
          bankStatementsDocRequestEnabled={ bankStatementsDocRequestEnabled }
          businessId={ businessId }
          chartOfAccountId={ chartOfAccountId }
          query={ query }
          sorting={ sorting }
          year={ year }
          onCloseDetailsModal={ onCloseDetailsModal }
        />
      </div>
    </div>
  );
};

export default StatementDetailsList;
