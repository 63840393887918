import {
  IAccountsReceivableServiceDocument,
  IAccountsReceivableServiceDocumentsQueryFilter,
  TAccountsReceivableServiceDocumentsSortColumn,
  TAccountsReceivableServiceDocumentState,
} from '@src/types/accounts_receivable_service_documents';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetAccountsReceivableServiceDocumentsParams {
  businessId: TID,
  filter?: IAccountsReceivableServiceDocumentsQueryFilter,
  orderColumn?: TAccountsReceivableServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
  type?: TAccountsReceivableServiceDocumentState,
}

interface IGetAccountsReceivableServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IAccountsReceivableServiceDocument[],
}

const getAccountsReceivableServiceDocuments = (
  params: IGetAccountsReceivableServiceDocumentsParams,
): Promise<IGetAccountsReceivableServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/accounts_receivable_service_documents',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.accountsReceivableServiceDocuments as IAccountsReceivableServiceDocument[],
    };
  });
};

export {
  IGetAccountsReceivableServiceDocumentsParams,
  IGetAccountsReceivableServiceDocumentsResponse,
  getAccountsReceivableServiceDocuments,
};
