module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="payment-account-item">\n  <a class="">\n    <p class="m-t-36 m-b-23">\n      ');
    
      if (this.card_type === "credit_card") {
        __out.push('\n      <span class="icon icon-credit-card-account font-50">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span>\n      </span>\n      ');
      } else if (this.card_type === "bank_check") {
        __out.push('\n      <span class="icon icon-bank-account font-50">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span>\n      </span>\n      ');
      } else if (this.card_type === "cash") {
        __out.push('\n      <span class="icon icon-cash-account font-50">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n      </span>\n      ');
      }
    
      __out.push('\n    </p>\n    <p class="payment-account-name">\n      <span>');
    
      __out.push(__sanitize(this.card_type === "cash" ? "CASH ACCOUNT" : this.name));
    
      __out.push('&nbsp;</span>\n    </p>\n    <p class="payment-account-cardnum">');
    
      if (this.card_type !== "cash") {
        __out.push('**** **** **** ');
        __out.push(__sanitize(this.last4));
      }
    
      __out.push('&nbsp;</p>\n  </a>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}