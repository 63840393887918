import React, { useMemo, forwardRef } from 'react';

import { useGetAccountOwner } from '@src/hooks/queries/account_owner';
import { TID } from '@src/types/common';

import Form from '@src/components/ui/form';

const BlankOption = { name: 'None', value: '' };

interface IAccountOwnerFieldProps {
  rcPaymentAccountId?: TID,
}

const AccountOwnerField = forwardRef<HTMLSelectElement, IAccountOwnerFieldProps>(({
  rcPaymentAccountId,
  ...props
}: IAccountOwnerFieldProps, ref): JSX.Element => {
  const query = useGetAccountOwner(rcPaymentAccountId);

  const accountOwners = useMemo(() => {
    return query.data?.accountOwners || [];
  }, [query.data]);

  const options = useMemo(() => {
    if (!accountOwners) { return [BlankOption]; }
    return [BlankOption,
      ...accountOwners.map((owner: string) => ({
        name:  owner,
        value: owner,
      })),
    ];
  }, [accountOwners]);

  return (
    <Form.SelectField
      { ...props }
      ref={ ref }
      label="Account Owner"
      mark="(optional)"
      options={ options }
      placeholder="Select Account Owner"
    />
  );
});

AccountOwnerField.displayName = 'AccountOwnerField';

export default AccountOwnerField;
