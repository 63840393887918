import React from 'react';

import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { DashboardsEmptyIcon } from '@src/components/utils/img_icons';

import CreateAction from '../actions/create_action';

import styles from './styles.module.scss';

interface IBlankDashboardsPageProps {
  businessId: TID,
  businessServiceInfo?: IBusinessServicesInfo,
}

const BlankDashboardsPage = ({
  businessId,
  businessServiceInfo,
}: IBlankDashboardsPageProps) => {
  return (
    <div className={ styles['dashboard-empty-container'] }>
      <div className={ styles['empty-wrapper'] }>
        <div className={ styles['empty-image'] }>
          <DashboardsEmptyIcon />
        </div>
        <p className={ styles['empty-title'] }>
          Welcome to the Dashboard
        </p>
        <p className={ styles['empty-text'] }>
          Get started by creating your first dashboard.
        </p>
        <div className={ styles['empty-actions'] }>
          <CreateAction
            businessId={ businessId }
            businessServiceInfo={ businessServiceInfo }
          />
        </div>
      </div>
    </div>
  );
};

export default BlankDashboardsPage;
