import React, { useCallback } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';

import Table from '@src/components/ui/table';

interface IFormulaValueCellProps extends Omit<NumberFormatProps, 'value' | 'onChange'> {
  currentFormula: IReportServiceBudgetFormula,
  onChange: (value: number) => void,
  initValue?: number,
}

const MonthValueCell = ({
  currentFormula,
  onChange,
  initValue,
}: IFormulaValueCellProps): JSX.Element => {
  const handleBlur = useCallback((e) => {
    onChange(e.target.value || 0.0);
  }, [onChange]);

  const symbolType = currentFormula.formulaInputConfiguration.type;

  const handleKeyPressNumber = useCallback((e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      handleBlur(e);
      e.preventDefault();
    }
  }, [handleBlur]);

  return (
    <Table.Cell>
      <NumberFormat
        allowEmptyFormatting
        fixedDecimalScale
        isNumericString
        className="budget-item-input"
        decimalScale={ 1 }
        inputMode="numeric"
        prefix={symbolType === 'currency' ? '$' : ''}
        suffix={symbolType === 'percent' ? '%' : ''}
        value={ initValue }
        onBlur={ handleBlur }
        onKeyPress={ handleKeyPressNumber }
      />
    </Table.Cell>
  );
};

export default MonthValueCell;
