import React from 'react';

import {
  IUIStyleProps,
  uiStyleProps,
} from '@src/utils/ui_style_helpers';

interface ILinkButtonProps extends React.ComponentPropsWithoutRef<'a'>, IUIStyleProps {
  children?: React.ReactNode,
  disabled?: boolean,
  title: string,
}

const LinkButton = ({
  children,
  disabled,
  title,
  ...props
}: ILinkButtonProps): JSX.Element => {
  const [classes, buttonProps] = uiStyleProps(
    'btn',
    props,
    { disabled },
  );

  return (
    <a className={ classes } { ...buttonProps }>
      { title || children }
    </a>
  );
};

export default LinkButton;
