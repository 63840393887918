import React from 'react';

import { TAmountFilter, TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { RichAmountInput, RichAmountFormatter } from '@src/components/ui_v2/inputs';

import Wrapper from './field_with_tooltip_wrapper';
import { useFilterField } from './hooks';

interface AmountFilterFieldProps<TFilter extends TFilterData> extends
  Omit<React.ComponentPropsWithoutRef<typeof RichAmountInput>, 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  name: keyof TFilter,
}

const AmountFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: AmountFilterFieldProps<TFilter>) => {
  const [value, update] = useFilterField<TFilter, TAmountFilter>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ value && <RichAmountFormatter value={ value } /> }
      tooltipLabel={ value?.val && 'Amount: ' }
    >
      <RichAmountInput
        { ...inputProps }
        value={ value }
        onChange={ update }
      />
    </Wrapper>
  );
};

export default React.memo(AmountFilterField);
