import { useCallback } from 'react';

import { UseQueryOptions, useMutation, useQuery } from 'react-query';
import { IRevenueServiceDocumentCategoryValue } from 'src/types/revenue_service_document_category_values';

import { QueryKey } from '@src/constants/query_keys';
import { useRevenueServiceDocumentsFayeChannel } from '@src/hooks/faye/revenue_service_documents';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import {
  IGetRevenueServiceDocumentsParams,
  IGetRevenueServiceDocumentsResponse,
  IPutRevenueServiceDocumentParams,
  IPutRevenueServiceDocumentResponse,
  IVerifyRevenueServiceDocumentParams,
  IUpsertRevenueServiceDocumentCategoryValuesParams,
  getRevenueServiceDocuments,
  putRevenueServiceDocument,
  verifyRevenueServiceDocument,
  getRevenueServiceDocument,
  upsertRevenueServiceDocumentCategoryValues,
} from '@src/requests/revenue_service_documents';
import { TID } from '@src/types/common';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

const useGetInfiniteRSD = createUseGetInfiniteCollection<
  IRevenueServiceDocument,
  IGetRevenueServiceDocumentsParams,
  IGetRevenueServiceDocumentsResponse
  >({
    queryKey: QueryKey.revenueServiceDocuments,
    request:  getRevenueServiceDocuments,
  });

const useGetInfiniteRevenueServiceDocument = (params: IGetRevenueServiceDocumentsParams) => {
  const query = useGetInfiniteRSD(params);

  const { refetch } = query;
  const handleEventReceived = useCallback(() => {
    refetch();
  }, [refetch]);

  useRevenueServiceDocumentsFayeChannel(params.revenueServiceId, handleEventReceived);

  return query;
};

const useGetRevenueServiceDocuments = (params: IGetRevenueServiceDocumentsParams) => {
  const query = useQuery<IGetRevenueServiceDocumentsResponse, Error>(
    [QueryKey.revenueServiceDocuments, params],
    () => getRevenueServiceDocuments(params),
  );

  const { refetch } = query;
  const handleEventReceived = useCallback(() => {
    refetch();
  }, [refetch]);

  useRevenueServiceDocumentsFayeChannel(params.revenueServiceId, handleEventReceived);

  return query;
};

const useUpsertRevenueServiceDocumentCategoryValues = () => {
  return useMutation<
    IRevenueServiceDocumentCategoryValue,
    Error,
    IUpsertRevenueServiceDocumentCategoryValuesParams
  >(
    upsertRevenueServiceDocumentCategoryValues,
  );
};

const useUpdateRevenueServiceDocument = () => {
  return useMutation<
    IPutRevenueServiceDocumentResponse,
    Error,
    IPutRevenueServiceDocumentParams
  >(
    putRevenueServiceDocument,
  );
};

const useVerifyRevenueServiceDocument = () => {
  return useMutation<
    IPutRevenueServiceDocumentResponse,
    Error,
    IVerifyRevenueServiceDocumentParams
  >(
    verifyRevenueServiceDocument,
  );
};

const useGetRevenueServiceDocument = (
  id: TID,
  opts?: Omit<UseQueryOptions<IRevenueServiceDocument, Error>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<IRevenueServiceDocument, Error>(
    [QueryKey.revenueServiceDocument, id],
    () => getRevenueServiceDocument(id),
    opts,
  );
};

export {
  useGetRevenueServiceDocuments,
  useGetInfiniteRevenueServiceDocument,
  useUpdateRevenueServiceDocument,
  useGetRevenueServiceDocument,
  useUpsertRevenueServiceDocumentCategoryValues,
  useVerifyRevenueServiceDocument,
};
