import React, { createContext, useCallback, useContext } from 'react';

import get from 'lodash/get';

import { IFilter, TFilterData } from '@src/types/filter';

const FilterContext = createContext<object | null>(null);

const useFilterContext = <TFilter extends TFilterData>() => {
  const context = useContext<IFilter<TFilter> | null>(
    FilterContext as unknown as React.Context<IFilter<TFilter> | null>,
  );
  if (!context) {
    throw new Error('"useFilter" must be used within <Filter /> or <Filter.Provider />!');
  }

  return context;
};

const useFilterField = <TFilter extends TFilterData, TField>(
  name: keyof TFilter,
) => {
  const { data, update } = useFilterContext<TFilter>();

  const updateField = useCallback((newValue: TField | undefined) => {
    update({ [name]: newValue } as unknown as TFilter);
  }, [name, update]);

  const value = get(data, name) as unknown as (TField | undefined);

  return { update: updateField, value };
};

export {
  FilterContext,
  useFilterContext,
  useFilterField,
};
