module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<a class="pull-left p-t-10 header-previous-link ');
    
      if (!this.previous_id) {
        __out.push(__sanitize('disabled-clickable'));
      }
    
      __out.push('"><span class="m-r-10"><</span>Previous</a>\n<div class="m-t-5 header-separator pull-left"></div>\n<a class="pull-left p-t-10 m-r-10 header-next-link ');
    
      if (!this.next_id) {
        __out.push(__sanitize('disabled-clickable'));
      }
    
      __out.push('">Next<span class="m-l-10">></span></a>\n');
    
      if (this.document_request) {
        __out.push('\n<a class="pull-left document-request-tooltip p-t-10 m-l-20 pointer" data-toggle="tooltip" data-placement="bottom" title="Linked to Document Request: ');
        __out.push(__sanitize(this.document_request.name));
        __out.push('">\n  <span class="font-20 icon icon-document-linked"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n</a>\n');
      } else {
        __out.push('\n<div class="pull-left p-t-4 m-l-20 relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="document-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="document-action-dropdown-btn">\n    <a class="dropdown-item pointer font-14 link-document-js">Link to Document Request</a>\n  </div>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}