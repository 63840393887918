import React from 'react';

import { TQBOSyncStatus } from '@src/types/common';
import { IQBOLink } from '@src/types/qbo_links';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Tooltip from '@src/components/ui_v2/tooltip';
import { QBOBlockIcon, QBOErrorIcon, QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IQBOStatusModel {
  qboSyncStatus?: TQBOSyncStatus|string,
  documentQboSyncStatus?: TQBOSyncStatus,
  qboLink?: IQBOLink,
  qboError?: string|null,
}

interface IQBOStatusIconProps extends IUIStyleProps {
  linkToQBO?: boolean,
  model: IQBOStatusModel,
  showIndividualSyncStatus?: boolean
}

const isDisabled = (document: IQBOStatusModel, status?: string) => {
  const STATUS_DISABLED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.DISABLED;
  return status === STATUS_DISABLED;
};

const isStarted = (document: IQBOStatusModel, status?: string) => {
  const STATUS_STARTED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED;
  return status === STATUS_STARTED;
};

const isSynced = (document: IQBOStatusModel, status?: string) => {
  const STATUS_SYNCED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.SYNCED;
  return status === STATUS_SYNCED || !!document.qboLink?.qboLink;
};

const isErrored = (document: IQBOStatusModel) => {
  return !!document.qboError || !!document.qboLink?.qboError;
};

const QBOStatusIcon = ({
  linkToQBO,
  model,
  showIndividualSyncStatus,
  ...styleProps
}: IQBOStatusIconProps) => {
  let qboSyncStatus = model.qboSyncStatus;
  if (showIndividualSyncStatus) qboSyncStatus = model.documentQboSyncStatus || model.qboSyncStatus;

  if (isSynced(model, qboSyncStatus)) {
    if (linkToQBO) {
      return (
        <a
          href={ model.qboLink?.qboLink || '#' }
          rel="noreferrer"
          target="_blank"
        >
          <QBOOnIcon { ...styleProps } />
        </a>
      );
    }

    return <QBOOnIcon { ...styleProps } />;
  }

  if (isDisabled(model, qboSyncStatus)) {
    return (
      <Tooltip.Hover content={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_BLOCK }>
        <QBOBlockIcon { ...styleProps } />
      </Tooltip.Hover>
    );
  }

  if (isErrored(model)) {
    return (
      <Tooltip.Hover content={ model.qboLink?.qboError || model.qboError || '' }>
        <QBOErrorIcon { ...styleProps } />
      </Tooltip.Hover>
    );
  }

  if (isStarted(model, qboSyncStatus)) {
    return (
      <Tooltip.Hover content={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_STARTED }>
        <QBOOffIcon { ...styleProps } />
      </Tooltip.Hover>
    );
  }

  return (
    <Tooltip.Hover content={ model.qboLink?.qboError || '' }>
      <QBOErrorIcon { ...styleProps } />
    </Tooltip.Hover>
  );
};

export default QBOStatusIcon;
