module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="no-box-shadow">\n  <div class="m-l-auto m-r-auto p-t-16 p-b-16 text-center">\n    <div class="text-center inline-flex-wrapper">\n      <div class="dropdown">\n        <button class="background-transparent border-gray btn dropdown-toggle font-16 p-t-10 p-b-10 p-l-25 p-r-25" type="button" id="messaging-filter-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n          ');
    
      __out.push(__sanitize(this.people_desc));
    
      __out.push('\n          <span class="fa fa-caret-down in-grey-600"></span>\n        </button>\n        <div class="messaging-filter-dropdown-menu dropdown-menu p-t-10 p-b-10" aria-labelledby="messaging-filter-dropdown-btn">\n          ');
    
      if (this.filterKeys.type === 'document' || this.filterKeys.type === 'internal') {
        __out.push('\n          <a class="dropdown-item block-wrapper font-16 type-people-js">\n            <i class="m-r-10 align-middle icon icon-All-Users-Icon1x in-blue-400 font-36 inline-flex-wrapper">\n              <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span>\n              <span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span>\n              <span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span>\n              <span class="path31"></span><span class="path32"></span><span class="path33"></span><span class="path34"></span><span class="path35"></span><span class="path36"></span><span class="path37"></span><span class="path38"></span>\n            </i>\n            <span>People only Messages</span>\n          </a>\n          <a class="dropdown-item block-wrapper m-t-10 type-bot-js">\n            <i class="m-r-10 align-middle icon icon-Docyt-Bot-Icon1x in-blue-400 font-36"><span class="path1"></span><span class="path2"><span class="path3"><span class="path4"></span><span class="path5"></span><span class="path6"><span class="path7"><span class="path8"></span></i>\n            <span>Docyt Bot only Messages</span>\n          </a>\n          ');
      }
    
      __out.push('\n          <a class="dropdown-item block-wrapper m-t-10 type-all-js">\n            <i class="m-r-10 align-middle icon icon-All-Message-Icon1x in-blue-400 font-36"><span class="path1"></span><span class="path2"><span class="path3"><span class="path4"></span></i>\n            <span>All messages</span>\n          </a>\n          <div class="no-box-shadow m-t-10"></div>\n          <a class="dropdown-item block-wrapper m-t-10 type-custom-js">\n            <i class="m-r-10 align-middle icon icon-3-dots in-blue-400 font-36"></i>\n            <span>Custom</span>\n          </a>\n        </div>\n      </div>\n      <div class="dropdown m-l-20">\n        <input class="date_gte height-100-percent value-edit-input background-transparent border-gray dropdown-toggle p-l-10 p-t-10 p-b-10" type="text" placeholder="From Date" value=');
    
      __out.push(__sanitize(this.filterKeys.gte));
    
      __out.push('>\n      </div>\n      <div class="dropdown">\n        <input class="date_lte height-100-percent value-edit-input background-transparent border-gray dropdown-toggle p-l-10 p-t-10 p-b-10" type="text" placeholder="To Date" value=');
    
      __out.push(__sanitize(this.filterKeys.lte));
    
      __out.push('>\n      </div>\n      ');
    
      if (this.filterKeys.type === 'document') {
        __out.push('\n      <div class="dropdown m-l-20">\n        <input class="form-control height-100-percent document-chat-id-input p-t-10 p-b-10" placeholder="Search By Document Id" value=');
        __out.push(__sanitize(this.filterKeys.hash));
        __out.push('>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}