import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IDocumentIconProps extends Omit<IIcomoonIconProps, 'color'> {
  color?: 'grey'
}

const DocumentIcon = ({
  color,
  ...props
}: IDocumentIconProps) => {
  let iconName = 'document';
  if (color) {
    iconName = `${iconName}_${color}`;
  }

  return (
    <Icomoon
      { ...props }
      iconName={ iconName }
      pathsNumber={ 2 }
    />
  );
};

export default DocumentIcon;
