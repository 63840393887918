import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { IBankStatement } from '@src/types/bank_statements';
import { TID, TDate } from '@src/types/common';

import BankingAccountField from '@src/components/common/form_fields/banking_account_field';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Form from '@src/components/ui/form';

import DeleteAction from '../actions/delete_action';
import { IBankStatementDetailsFormValues, statementValidation } from './schema';
import UploadView from './upload_view';

import styles from '../styles.module.scss';

interface IBankStatementDetailsFormProps {
  businessId: TID,
  bankStatement?: IBankStatement,
  formId?: string,
  preSelectedRcPaymentAccountId?: TID,
  preSelectedDate?: TDate,
  isFileUploading?: boolean,
  onSubmit: SubmitHandler<IBankStatementDetailsFormValues>,
  onChange?: (values: IBankStatementDetailsFormValues) => void,
}

const BankStatementDetailsForm = ({
  businessId,
  bankStatement,
  formId,
  preSelectedRcPaymentAccountId,
  preSelectedDate,
  isFileUploading,
  onSubmit,
  onChange,
}: IBankStatementDetailsFormProps): JSX.Element => {
  const {
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IBankStatementDetailsFormValues>({
    defaultValues: {
      bankStatement,
      reconciliationPaymentAccountId: preSelectedRcPaymentAccountId
                                        || bankStatement?.reconciliationPaymentAccount?.id,
      date:           preSelectedDate || bankStatement?.date,
      closingBalance: bankStatement?.closingBalance,
    },
    resolver: yupResolver(statementValidation),
  });

  const statementDateController = useController({ name: 'date', control });
  const accountController = useController({ name: 'reconciliationPaymentAccountId', control });
  const closingBalanceController = useController({ name: 'closingBalance', control });

  const bankStatementData = watch('bankStatement');
  const reconciliationPaymentAccountId = watch('reconciliationPaymentAccountId');
  const date = watch('date');

  const { onBlur: closingBalanceOnBlur } = closingBalanceController.field;
  const handleClosingBalanceBlur = useCallback(() => {
    closingBalanceOnBlur();
    if (onChange) onChange(getValues());
  }, [closingBalanceOnBlur, getValues, onChange]);

  useEffect(() => {
    setValue('bankStatement', bankStatement);
    if (preSelectedRcPaymentAccountId) {
      setValue('reconciliationPaymentAccountId', preSelectedRcPaymentAccountId);
    } else if (bankStatement?.reconciliationPaymentAccount?.id) {
      setValue('reconciliationPaymentAccountId', bankStatement?.reconciliationPaymentAccount?.id);
    }

    if (preSelectedDate) {
      setValue('date', preSelectedDate);
    } else {
      setValue('date', bankStatement?.date);
    }

    if (bankStatement?.closingBalance) {
      setValue('closingBalance', bankStatement?.closingBalance);
    } else {
      setValue('closingBalance', '');
    }
  }, [bankStatement, setValue, preSelectedRcPaymentAccountId, preSelectedDate]);

  useEffect(() => {
    if (onChange) onChange(getValues());
  }, [getValues, onChange, reconciliationPaymentAccountId, date]);

  return (
    <Form className="display-flex width-100-percent" id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <UploadView
        bankStatement={ bankStatement }
        error={ errors.bankStatement?.id?.message }
        isFileUploading={ isFileUploading }
        onUploaded={ (value) => setValue('file', value) }
      />
      <div className={ styles['form-container'] }>
        <div className={ styles['form-header'] }>
          <span className="font-15 font-bold">Bank Statement Detail</span>
          {
            bankStatementData && formId === 'bank_statement_upload_form' && (
              <ActionsDropdown>
                <DeleteAction bankStatement={ bankStatementData } onDeleted={ reset } />
              </ActionsDropdown>
            )
          }
        </div>
        <div className={ styles['statement-detail-form'] }>
          <BankingAccountField
            noConsiderArchive
            businessId={ businessId }
            error={ errors.reconciliationPaymentAccountId?.message }
            { ...accountController.field }
          />
          <Form.DateField
            error={ errors.date?.message }
            label="Statement Date"
            placeholder="Select Date"
            { ...statementDateController.field }
          />
          <Form.AmountField
            fixedDecimalScale
            className="closing-balance-input"
            error={ errors.closingBalance?.message }
            label="Closing Balance"
            placeholder="Enter Amount"
            onBlur={ handleClosingBalanceBlur }
            { ...omit(closingBalanceController.field, 'onBlur') }
          />
        </div>
      </div>
    </Form>
  );
};

export {
  IBankStatementDetailsFormProps,
  IBankStatementDetailsFormValues,
  BankStatementDetailsForm as default,
};
