import React, { useMemo } from 'react';

import omit from 'lodash/omit';

import { useFilter } from '@src/hooks/url_params';
import { IBusiness } from '@src/types/businesses';
import { formatApiYear } from '@src/utils/date_helpers';

import Filter, { IMyPracticeFilter } from './filter';
import List from './list';

interface IMyPracticeMainViewProps {
  accountingFirms: IBusiness[],
}

const getPracticeInformationsParamsFromFilter = (filterData: IMyPracticeFilter | undefined) => {
  const filterParams = omit(
    filterData || {},
    ['onlyBlocked', 'accountingFirmBusinessId', 'bookkeepingYear'],
  );

  return {
    bookkeepingYear:          filterData?.bookkeepingYear,
    accountingFirmBusinessId: filterData?.accountingFirmBusinessId,
    filter:                   {
      status: filterData?.onlyBlocked ? 'blocked' : undefined,
      ...filterParams,
    },
  };
};

const MyPracticeMainView = ({
  accountingFirms,
}: IMyPracticeMainViewProps): JSX.Element => {
  const filter = useFilter<IMyPracticeFilter>({
    initData: {
      bookkeepingYear:          formatApiYear(new Date()),
      accountingFirmBusinessId: String(accountingFirms[0]?.id),
    },
  });

  const filterParams = useMemo(() => {
    return getPracticeInformationsParamsFromFilter(filter.data);
  }, [filter.data]);

  return (
    <div className="practice-list-table-wrapper m-t-30">
      <Filter
        accountingFirms={ accountingFirms }
        filter={ filter }
      />
      <List
        filterParams={ filterParams }
      />
    </div>
  );
};

export {
  MyPracticeMainView as default,
};
