module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'vendor-detail-info relative font-14\'>\n  <div class=\'font-bold font-20 m-t-20 p-b-30\'>Profile Info</div>\n  <div class=\'\'>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Name</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Website</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <a href=');
    
      __out.push(__sanitize(this.website_url));
    
      __out.push(' target="_blank">');
    
      __out.push(__sanitize(this.website));
    
      __out.push('</a>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Email</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <a href=\'mailto:');
    
      __out.push(__sanitize(this.email));
    
      __out.push('\'>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</a>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Phone Number</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Fax</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.fax));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Check Payable to</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.address.get('name')));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Department</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.address.get('company')));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label vertical-align-top\'>\n        <span>Address</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.address.addressInfo()));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>QBO Match</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span class="');
    
      __out.push(__sanitize(this.qbo_name ? 'icon-qbo-on' : ''));
    
      __out.push('"></span>\n        <span>');
    
      __out.push(__sanitize(this.qbo_name));
    
      __out.push('</span>\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>ACH Status</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        ');
    
      if (this.ach_status === 'activated') {
        __out.push('\n          <span class="invoice-status-panel font-bold approval"> ACH Active </span>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <div>AI Invoice Line items Detection\n          <div class="icon icon-unverified inline-block m-l-3 ai-line-item-detection" data-toggle="tooltip" data-html="true" data-placement="bottom" title="" data-original-title="This will help extract the line items from an invoice automatically. You will be able to edit the data. Docyt AI will learn from manually input data.">\n            <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n          </div>\n        </div>\n      </div>\n      <div  width-100-percent\'>\n        ');
    
      if (this.can_access_ai_invoice_line_items_extraction) {
        __out.push('\n          ');
        if (this.is_ai_invoice_line_items_extraction) {
          __out.push('\n            <span><i style="color: green;" class="fas fa-check"></i> ');
          __out.push(__sanitize("Active"));
          __out.push('</span>\n          ');
        } else {
          __out.push('\n            <span> ');
          __out.push(__sanitize("Inactive"));
          __out.push('</span>\n          ');
        }
        __out.push('\n        ');
      } else {
        __out.push('\n          <a class= \'show-activate-line-items-modal\' style="cursor: pointer;"> Click to activate</a>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div class=\'flex-wrapper p-b-20\'>\n      <div class=\'form-label\'>\n        <span>Preferred Payment Method</span>\n      </div>\n      <div class=\'width-100-percent\'>\n        <span>');
    
      __out.push(__sanitize(this.preferred_payment_method));
    
      __out.push('</span>\n      </div>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}