import { useQuery } from 'react-query';

import { getSubscriptionsByBusinessIds } from '@src/requests/subscriptions';
import { ISubscription } from '@src/types/subscriptions';

const SUBSCRIPTIONS_QUERY = 'subscriptions';

const useGetSubscriptionsByBusinessIds = (businessIds: number[]) => {
  return useQuery<ISubscription[], Error>({
    queryKey: SUBSCRIPTIONS_QUERY,
    queryFn:  () => getSubscriptionsByBusinessIds({
      businessIds,
    }),
  });
};

export {
  useGetSubscriptionsByBusinessIds,
};
