import React from 'react';

import {
  IBookkeepingStatus,
} from '@src/types/practice_informations';

import BookkeepingStatusItem from '@src/components/my_practice/details/list_item/bookkeeping_status_item';

interface IBookkeepingStatusListProps {
  statuses: IBookkeepingStatus[],
}

const BookkeepingStatusList = ({
  statuses,
}: IBookkeepingStatusListProps): JSX.Element => {
  return (
    <>
      {
        statuses.map((status) => {
          return (
            <BookkeepingStatusItem
              key={ status.month }
              status={ status.status }
            />
          );
        })
      }
    </>
  );
};

export default BookkeepingStatusList;
