import React, { FC } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IVendor,
  TIBusinessVendorsSortColumn,
} from '@src/types/business_vendors';
import { TSection } from '@src/types/common';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';
import Section from '@src/components/utils_v2/section';

import styles from './styles.module.scss';

interface ISearchBusinessVendorResponse {
    vendors: IVendor[];
}
interface TableProps {
    infiniteQuery: UseInfiniteQueryResult<ISearchBusinessVendorResponse, Error>;
    records: IVendor[];
    sorting: ISorting<TIBusinessVendorsSortColumn>;
    section: TSection;
}

const SideViewVendorTable: FC<TableProps> = ({
  infiniteQuery,
  records,
  sorting,
  section,
}) => {
  const imageDisplay = (name: string, imageUrl: string) => {
    return (
      <div className="display-flex align-items-center">
        <div className="avatar-wrapper">
          {imageUrl === null ? (
            <i className="icon icon-smile in-blue-400 font-33" />
          ) : (
            <img alt={ name } src={ imageUrl } />
          )}
        </div>
      </div>
    );
  };

  return (
    <Section.Provider section={ section }>
      <CollectionTable<IVendor, TIBusinessVendorsSortColumn>
        hideSectionBorder
        isRegionScroll
        className={ styles['vendor-item'] }
        height="100%"
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.IconColumn<IVendor>
          name="avatar"
          title=""
          value={ (v: IVendor) => imageDisplay(v.name, v.imageUrl) }
        />
        <CollectionTable.TextColumn<IVendor>
          name="Vendor Name"
          title=""
          value={ (v) => v.name }
        />
        <CollectionTable.SelectItemColumn<IVendor>
          inputType="radio"
          name="vendor_selected"
          title=""
          value={ (v) => v.id }
        />
      </CollectionTable>
    </Section.Provider>
  );
};

export default SideViewVendorTable;
