import React, { useMemo } from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';
import { MergeIcon } from '@src/components/utils/icomoon';

import { useSelectMergeableVendorModal } from '../select_mergeable_vendor_modal/select_mergeable_vendor_modal';

interface IMergeVendorActionProps {
  vendor: object,
}

const MergeVendorAction = ({
  vendor,
}: IMergeVendorActionProps): JSX.Element | null => {
  const merge = useSelectMergeableVendorModal({});

  const businessVendor = useMemo(() => {
    return camelizeKeys(vendor) as IBusinessVendor;
  }, [vendor]);

  return (
    <Root>
      <merge.Component
        { ...merge.props }
        businessVendor={ businessVendor }
      />
      {
        !businessVendor.mergeableId && !businessVendor.isGlobal && (
          <div
            className="dropdown-item pointer display-flex font-14"
            role="button"
            tabIndex={ -1 }
            onClick={ merge.open }
          >
            <MergeIcon fontSize={ 18 } />
            <span className="m-l-10">Merge Vendor</span>
          </div>
        )
      }
    </Root>
  );
};

export default MergeVendorAction;
