import React, { forwardRef } from 'react';

import StaticInput from '@src/components/ui_v2/inputs/static_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IStaticFieldProps extends
  React.ComponentPropsWithoutRef<typeof StaticInput>,
  IWrapperProps
{
}

const StaticField = forwardRef<HTMLSpanElement, IStaticFieldProps>(({
  ...props
}: IStaticFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <StaticInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

StaticField.displayName = 'StaticField';

export default StaticField;
