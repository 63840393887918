import { useQuery } from 'react-query';

import { getInboxServiceByBusinessId } from '@src/requests/inbox_services';
import { IInboxService } from '@src/types/services';

const INBOX_SERVICE_QUERY = 'inbox_service';

const useGetInboxServiceByBusinessIdQuery = (businessId: number) => {
  return useQuery<IInboxService, Error>(
    [INBOX_SERVICE_QUERY, businessId],
    () => getInboxServiceByBusinessId(businessId),
    {
      enabled: !!businessId,
    },
  );
};

export {
  INBOX_SERVICE_QUERY,
  useGetInboxServiceByBusinessIdQuery,
};
