import React from 'react';

import { ReportContext } from '@src/hooks/contexts/report_context';
import { useGetReportById } from '@src/hooks/queries/report_service/custom_reports';
import { TMongoID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IReportProviderProps {
  reportId: TMongoID,
  children: React.ReactNode,
}

const ReportProvider = ({
  reportId,
  children,
}: IReportProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetReportById(reportId);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Report loaded" />;

  return (
    <ReportContext.Provider value={ data }>
      { children }
    </ReportContext.Provider>
  );
};

export default ReportProvider;
