module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, ref, service_document_account;
    
      __out.push('<div class="chart-of-account-split-panel ');
    
      __out.push(__sanitize(this.split_by_percentage === 1 ? 'split-mode-percentage' : ''));
    
      __out.push(' relative p-l-10 p-r-10">\n  ');
    
      ref = this.service_document_splits;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        service_document_account = ref[index];
        __out.push('\n    <div class="m-b-10 m-r-25 row split-amount-input-row relative">\n      <div class=\'col-sm-5\'>\n        <input class=\'form-control receivable-business-input\' type=\'text\' placeholder=\'Business\' value=\'');
        __out.push(__sanitize(service_document_account.receivable_business_name));
        __out.push('\' disabled="disabled">\n      </div>\n      <div class=\'col-sm-5 p-l-0\'>\n        <input class=\'form-control chart-of-account-input\' type=\'text\' placeholder=\'Category\' value=\'');
        __out.push(__sanitize(service_document_account.name));
        __out.push('\' readonly="readonly">\n      </div>\n      <div class=\'col-sm-2 p-l-0 amount-input-wrapper relative\'>\n        <input class=\'form-control amount-input p-l-15 font-bold\' disabled="disabled" type=\'text\' value=\'\'>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n  <div class="divider"></div>\n</div>\n<div class="font-15 in-brown-700 m-t-20 p-l-15 p-r-15">\n  <a class="pointer m-l-15 m-t-7 pull-right cancel">Cancel</a>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}