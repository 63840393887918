import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetAccountsReceivableServiceDocuments } from '@src/hooks/queries/accounts_receivable_service_documents';
import { useSorting } from '@src/hooks/url_params';
import {
  IGetAccountsReceivableServiceDocumentsParams,
  IGetAccountsReceivableServiceDocumentsResponse,
} from '@src/requests/accounts_receivable_service_documents';
import {
  IAccountsReceivableServiceDocument,
  IAccountsReceivableServiceDocumentsFilter,
  IAccountsReceivableServiceDocumentsQueryFilter,
  TAccountsReceivableServiceDocumentsSortColumn,
} from '@src/types/accounts_receivable_service_documents';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUseInvoicesCollectionParams {
  businessId: TID,
  type: IGetAccountsReceivableServiceDocumentsParams['type'],
}

interface IInvoicesCollection {
  query: UseInfiniteQueryResult<IGetAccountsReceivableServiceDocumentsResponse, Error>,
  records: IAccountsReceivableServiceDocument[],
  section: TSection,
  sorting: ISorting<TAccountsReceivableServiceDocumentsSortColumn>,
}

const defaultSorting: ISortingParams<TAccountsReceivableServiceDocumentsSortColumn> = {
  orderColumn:    'bill_date',
  orderDirection: 'desc',
};

const filterToQuery = (
  filterData: IAccountsReceivableServiceDocumentsFilter | undefined,
): IAccountsReceivableServiceDocumentsQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, 'bill_date');

  return {
    ...query,
    ...dateFilterToQuery(filterData.invoiceDate, 'billDate') || {},
  };
};

const useInvoicesCollection = ({
  businessId,
  type,
}: IUseInvoicesCollectionParams): IInvoicesCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsReceivableInvoices,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const filterQuery = useMemo(() => filterToQuery(filterData), [filterData]);

  const sorting = useSorting<TAccountsReceivableServiceDocumentsSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetAccountsReceivableServiceDocuments({
    businessId,
    filter: filterQuery,
    type,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IInvoicesCollection,
  useInvoicesCollection,
};
