import { useMemo } from 'react';

import classNames, { Argument } from 'classnames';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

interface IFilterFieldUIProps {
  className?: IUIStyleProps['className'],
  widthPercent?: IUIStyleProps['widthPercent'],
}

type TUseFilterFieldPropsReturn<Type> = [
  IFilterFieldUIProps,
  Omit<Type, keyof IFilterFieldUIProps>
];

const useFilterFieldProps = <Type extends IFilterFieldUIProps>(
  props: Type,
  ...args: Argument[]
): TUseFilterFieldPropsReturn<Type> => {
  const wrapperProps: IFilterFieldUIProps = useMemo(() => {
    const tempProps = pick(
      props,
      [
        'className',
        'widthPercent',
      ],
    );

    const classes = classNames(tempProps.className, args);

    return {
      ...tempProps,
      className: classes,
    };
  }, [props, args]);

  const restProps = useMemo(() => {
    return omit(
      props,
      [
        'className',
        'widthPercent',
      ],
    );
  }, [props]);

  return [
    wrapperProps,
    restProps,
  ];
};

export {
  IFilterFieldUIProps,
  useFilterFieldProps,
};
