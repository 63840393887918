import { TID } from '@src/types/common';
import { ISelectOption, TResource } from '@src/types/select_options';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetSelectOptionsParams {
  businessId?: TID;
  q?: string;
  page?: number;
  order?: string;
  resource: TResource;
  selected?: string;
}

interface IGetSelectOptionsResponse {
  collection: ISelectOption[];
  meta: {
    totalCount: number;
  };
}

const getSelectOptions = (params: IGetSelectOptionsParams): Promise<IGetSelectOptionsResponse> => {
  return apiGet('/api/v1/select_options', underscoreKeys(params)).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return { ...cdata, collection: cdata.selectOptions } as IGetSelectOptionsResponse;
  });
};

export {
  getSelectOptions,
  IGetSelectOptionsParams,
  IGetSelectOptionsResponse,
};
