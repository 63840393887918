module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="relative receipt-modal-right-menu-title">\n  <h1 class="font-24 font-bold p-b-10 text-center">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n  <a class="pointer close-link font-40"><span aria-hidden="true">&times;</span></a>\n</div>\n<div class="">\n  <ul class="nav sub-header-menu">\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab === 'data' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="data">DATA</a></li>\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab === 'chat' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="chat">CHAT</a></li>\n    <li class="m-r-25 ');
    
      __out.push(__sanitize(this.activeTab === 'activity' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="activity">ACTIVITY</a></li>\n  </ul>\n</div>\n<div class="receipt-right-side-region main-right-menu-region clearfix">\n</div>\n');
    
      if (this.activeTab === 'data') {
        __out.push('\n<div class="receipt-right-side-footer">\n    <div class="text-center m-t-30">\n        ');
        if (this.purpose === 'verify') {
          __out.push('\n        <button type="button" class="btn btn-blue mark-as-verified-next-btn">\n            Approve Receipt - Review Next\n        </button>\n        ');
        }
        __out.push('\n    </div>\n    <div class="text-center m-t-10 p-b-30">\n        <a class="pointer close-modal-link m-r-100">Cancel</a>\n        <a class="pointer skip-and-next-btn">Skip</a>\n    </div>\n</div>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}