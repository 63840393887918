import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useLinkDocumentToRequest } from '@src/hooks/queries/document_requests';
import { IBusiness } from '@src/types/businesses';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { ILinkDocumentRequestFormValues } from './link_document_request_form';

interface ILinkDocumentRequestProps extends IUseModalProps {
  business: IBusiness
  document: IInboxServiceDocument,
}

const LinkDocumentRequest = ({
  business,
  document,
  isOpen,
  onCancel,
  onDone,
}: ILinkDocumentRequestProps) => {
  const link = useLinkDocumentToRequest();

  const { mutate } = link;
  const handleSubmit = useCallback((values: ILinkDocumentRequestFormValues) => {
    mutate(
      {
        documentId:      document.id,
        documentRequest: values.documentRequest,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [document, onDone, mutate]);

  return (
    <>
      <MutationStatus mutation={ link } />
      <Modal.Form
        className="link-document-request-modal"
        proceedTitle="Add Document to Request"
        show={ isOpen }
        title="Link to Document Request"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <>
            <p className="m-b-21">
              The document
              { ' ' }
              { document.name }
              { ' ' }
              will be linked to the below selected document request.
            </p>
            <Form
              business={ business }
              formId={ formId }
              onSubmit={ handleSubmit }
            />
          </>
        )}
      </Modal.Form>
    </>
  );
};

const useLinkDocumentRequestModal = makeUseModal(LinkDocumentRequest);

export {
  ILinkDocumentRequestProps,
  useLinkDocumentRequestModal,
  LinkDocumentRequest as default,
};
