import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const CsvIcon: React.FC<IIcomoonIconProps> = (props) => {
  return (
    <Icomoon
      { ...props }
      iconName="csv_icon"
      pathsNumber={ 5 }
    />
  );
};
export default React.memo(CsvIcon);
