import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import FinItemView from './fin_item_view';

import styles from './styles.module.scss';

interface IFinListViewProps {
  financialInstitutionConnections: IFinancialInstitutionConnection[],
}

const FinListView = ({
  financialInstitutionConnections,
}: IFinListViewProps) => {
  return (
    <div className={ styles['fin-connection-list'] }>
      <span className="m-t-20 m-b-20 in-grey-1050">
        Connect all your business bank accounts for Docyt to fetch daily transactions effortlessly.
      </span>
      {
        financialInstitutionConnections.map((financialInstitutionConnection) => (
          <FinItemView
            key={ financialInstitutionConnection.id }
            financialInstitutionConnection={ financialInstitutionConnection }
          />
        ))
      }
    </div>
  );
};

export default React.memo(FinListView);
