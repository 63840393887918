import React from 'react';

import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';

import AcknowledgeAction from './acknowledge_action';
import NoteTooltip from './note_tooltip';

import styles from './styles.module.scss';

interface AcknowledgeStatementProps {
  item: IBankAccountReconciliationItem;
  noteRequired?: boolean;
  closeDrawer?: () => void;
  total?: string
}

const AcknowledgeStatement =
({ item, noteRequired = false, closeDrawer, total }: AcknowledgeStatementProps) => {
  return (
    <div className={ styles['acknowledge-diff'] }>
      { item.note && (
        <div className={ styles['acknowledge-diff-note'] }><NoteTooltip item={ item } /></div>) }
      <div className={ styles['acknowledge-diff-action'] }>
        <AcknowledgeAction
          baReconciliationItem={ item }
          closeDrawer={ closeDrawer }
          noteRequired={ noteRequired }
          total={ total }
        />
      </div>
    </div>
  );
};

export default React.memo(AcknowledgeStatement);
