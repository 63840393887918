module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n    <div class="header-simple-title-section">\n        <div class="m-l-auto m-r-auto text-decoration-blank team-setting-header in-black m-t-20 m-b-20">\n      <span class="font-20 text-decoration-blank m-r-10">\n        Team Management\n      </span>\n            <div class="client__breadcrumbs-item font-30 m-r-10 p-b-7"></div>\n            <a class="employee-avatar-with-border">\n                ');
    
      if (this.employeeAvatar) {
        __out.push('\n                <img src="');
        __out.push(__sanitize(this.employeeAvatar));
        __out.push('" class=\'clients__userpic-img\'>\n                ');
      } else {
        __out.push('\n                <i class=\'icon icon-smile in-orange-500\' ></i>\n                ');
      }
    
      __out.push('\n            </a>\n            <span class="font-24 font-bold m-l-10">\n        ');
    
      __out.push(__sanitize(this.employeeName));
    
      __out.push('\n      </span>\n        </div>\n        <a class="font-40 in-grey-900 pointer close-page-btn">\n            <i class="icon icon-reject-invite font-bold" />\n        </a>\n    </div>\n    <div class="header-menu-section">\n        <ul class="nav sub-header-menu app-header-sub-menu">\n            <li class="m-r-25 ');
    
      __out.push(__sanitize(this.memberDetailType === 'profile' ? 'active' : ''));
    
      __out.push('">\n                <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('?type=profile"> PROFILE </a>\n            </li>\n            <li class="m-r-25 ');
    
      __out.push(__sanitize(this.memberDetailType === 'business' ? 'active' : ''));
    
      __out.push('">\n                <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('?type=business"> BUSINESSES </a>\n            </li>\n            ');
    
      if (this.clients_visible_status) {
        __out.push('\n            <li class="m-r-25 ');
        __out.push(__sanitize(this.memberDetailType === 'client' ? 'active' : ''));
        __out.push('">\n                <a class="" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('?type=client"> CLIENTS </a>\n            </li>\n            ');
      }
    
      __out.push('\n            <li class="m-r-25 ');
    
      __out.push(__sanitize(this.memberDetailType === 'paymentmethod' ? 'active' : ''));
    
      __out.push('">\n                <a class="" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('?type=paymentmethod"> PAYMENT METHODS </a>\n            </li>\n        </ul>\n    </div>\n</header>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}