module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1, ref2,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<td>\n  ');
    
      if (this.vendor && this.vendor.image_url) {
        __out.push('\n    <div class=\'small-avatar inline-block vertical-align-middle m-l-15\'><img src=\'');
        __out.push(__sanitize(this.vendor.image_url));
        __out.push('\'></div>\n  ');
      } else {
        __out.push('\n    ');
        if (ref = this.rule_condition.transaction_type, indexOf.call(Docyt.Common.Constants.EXPENSE_TRANSACTION_TYPES, ref) >= 0) {
          __out.push('\n      <span class="icon-expenses-nav font-28 m-l-15"></span>\n    ');
        } else if (ref1 = this.rule_condition.transaction_type, indexOf.call(Docyt.Common.Constants.REVENUE_TRANSACTION_TYPES, ref1) >= 0) {
          __out.push('\n      <span class="icon-revenue-nav font-28 m-l-15"></span>\n    ');
        } else if (this.rule_condition.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
          __out.push('\n      <span class="icon-bank-transfers-nav font-28 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n    ');
        } else if (this.rule_condition.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['PAYROLL_TYPE']) {
          __out.push('\n      <span class="icon-payroll-liability-nav font-28 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>\n    ');
        } else if (ref2 = this.rule_condition.transaction_type, indexOf.call(Docyt.Common.Constants.BALANCE_SHEET_TRANSACTION_TYPES, ref2) >= 0) {
          __out.push('\n      <span class="icon-equity-liability-assets font-28 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n    ');
        } else if (this.rule_condition.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['CHARGEBACK_TYPE']) {
          __out.push('\n      <span class="icon-chargeback-nav font-28 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n    ');
        } else if (this.rule_condition.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['LOAN_PAYMENTS_TYPE']) {
          __out.push('\n      <span class="icon-loan-payments font-28 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n    ');
        } else {
          __out.push('\n      <span class="icon-money-exchange font-24 m-l-15"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</td>\n<td class=\'rule-description\'>\n</td>\n<td class="relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="rule-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="rule-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <a class="dropdown-item edit-rule pointer display-flex">\n      <i class="icon icon-edit font-18"></i>\n      <span class="font-14 m-l-10">Edit Setting</span>\n    </a>\n    <a class="dropdown-item delete-rule in-red-400 pointer display-flex">\n      <i class="icon icon-trashcan font-18"></i>\n      <span class="font-14 m-l-10">Delete Setting</span>\n    </a>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}