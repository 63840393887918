import { TID } from '@src/types/common';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';
import { LDFeatureForUserEnabled } from '@src/utils/config';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut, apiDelete } from './helpers';

interface ICreateFinancialInstitutionConnectionParams {
  financialInstitutionConnection: {
    managementGroupId?: TID,
    plaidPublickToken?: string,
    plaidAccountId?: string,
  }
}

interface IUpdateFinancialInstitutionConnectionParams {
  id?: TID,
  financialInstitutionConnection: {
    managementGroupId?: TID,
    plaidPublickToken?: string,
    plaidAccountId?: string,
  }
}

interface IFinancialConnectionParams {
  id: TID,
}

interface IGetFinancialInstitutionConnectionParams {
  filter?: object,
  businessId?: TID,
}

interface IGetFinancialInstitutionConnectionResponse {
  meta: {
    noFilteredCount: number,
  },
  financialInstitutionConnections: IFinancialInstitutionConnection[],
}

interface IGetFinancialInstitutionConnectionResponseInfinite {
  meta: {
    noFilteredCount: number,
    totalCount: number
  },
  collection: IFinancialInstitutionConnection[],
}

const financialInstitutionApiPath = '/api/v1/financial_institution_connections';

const apiEndPoint = (featureFlag:boolean):string => {
  if (featureFlag) {
    return `/banking${financialInstitutionApiPath}`;
  }
  return financialInstitutionApiPath;
};

const getFinancialInstitutionConnection = (
  params: IGetFinancialInstitutionConnectionParams,
): Promise<IGetFinancialInstitutionConnectionResponse> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiGet(
      apiEndPoint(featureFlag),
      underscoreKeys(params),
    ).then((data) => {
      const cdata = <any>camelizeKeys(data);
      return {
        ...cdata,
        collection: cdata.financialInstitutionConnections,
      } as IGetFinancialInstitutionConnectionResponse;
    });
  });
};

const getFinancialInstitutionConnectionInfinite = (
  params: IGetFinancialInstitutionConnectionParams,
): Promise<IGetFinancialInstitutionConnectionResponseInfinite> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiGet(
      apiEndPoint(featureFlag),
      underscoreKeys(params),
    ).then((data) => {
      const cdata = <any>camelizeKeys(data);
      return {
        meta: cdata.meta,
        collection: cdata.financialInstitutionConnections,
      } as IGetFinancialInstitutionConnectionResponseInfinite;
    });
  });
};

const createFinancialInstitutionConnection = (
  params: ICreateFinancialInstitutionConnectionParams,
): Promise<void> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiPost(
      apiEndPoint(featureFlag),
      underscoreKeys(params),
    );
  });
};

const updateFinancialInstitutionConnection = (
  params: IUpdateFinancialInstitutionConnectionParams,
): Promise<void> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiPut(
      `${apiEndPoint(featureFlag)}/${params.id}`,
      underscoreKeys(params),
    );
  });
};

const deleteFinancialInstitutionConnection = (
  params: IFinancialConnectionParams,
): Promise<void> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiDelete(
      `${apiEndPoint(featureFlag)}/${params.id}`,
    );
  });
};

const disconnectFinancialInstitutionConnection = (
  params: IFinancialConnectionParams,
): Promise<void> => {
  return LDFeatureForUserEnabled(window.Docyt.Common.Constants.QUILTT_INTEGRATION, String(window.Docyt.currentAdvisor.id)).then((value) => {
    return value;
  }).then((featureFlag) => {
    return apiPost(
      `${apiEndPoint(featureFlag)}/${params.id}/disconnect`,
      underscoreKeys(params),
    );
  });
};

export {
  ICreateFinancialInstitutionConnectionParams,
  IUpdateFinancialInstitutionConnectionParams,
  IGetFinancialInstitutionConnectionParams,
  IFinancialConnectionParams,
  IGetFinancialInstitutionConnectionResponse,
  IGetFinancialInstitutionConnectionResponseInfinite,
  getFinancialInstitutionConnection,
  getFinancialInstitutionConnectionInfinite,
  createFinancialInstitutionConnection,
  updateFinancialInstitutionConnection,
  deleteFinancialInstitutionConnection,
  disconnectFinancialInstitutionConnection,
};
