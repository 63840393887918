import React, { useCallback, useMemo } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getManagementGroupDashboards } from '@src/requests/dashboards/management_group_dashboards';
import { TMongoID } from '@src/types/common';
import { IManagementGroupDashboard } from '@src/types/dashboards/dashboards';

import {
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { AsyncPaginateReactSelectInput } from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface IManagementGroupDashboardInputProps extends
  Omit<IIconSelectInputProps, 'onChange'>
{
  dashboard?: IManagementGroupDashboard,
  onChange?: (value: TMongoID | undefined) => void,
}

const perPage = 20;

const ManagementGroupDashboardInput = ({
  dashboard,
  onChange,
  ...props
}: IManagementGroupDashboardInputProps): JSX.Element => {
  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    if (!onChange) return;

    onChange(item?.value);
  }, [onChange]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
    useCallback((query, options, { page }) => {
      return getManagementGroupDashboards({
        filters: { managementGroupName: query },
        page,
        perPage,
      }).then((data) => {
        const hasMore = options.length + data.collection.length < data.meta.totalCount;
        const newOptions = data.collection.map((item) => ({
          label: item.managementGroupName,
          value: item.id,
        }));

        return {
          hasMore,
          options:    newOptions,
          additional: {
            page: page + 1,
          },
        };
      });
    }, []);

  const selectedItem = useMemo(() => {
    if (!dashboard) return undefined;

    return {
      label: dashboard.managementGroupName,
      value: dashboard.id,
    };
  }, [dashboard]);

  return (
    <AsyncPaginateReactSelectInput
      defaultOptions
      hideClear
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      { ...props }
      loadOptions={ handleSource }
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default React.memo(ManagementGroupDashboardInput);
