import React, { ComponentPropsWithoutRef } from 'react';

import { TID } from '@src/types/common';

import Tooltip from '@src/components/ui_v2/tooltip';

import CountAvatar from './count_avatar';
import UserAvatar from './user_avatar';

const DEFAULT_VISIBLE_COUNT = 5;
const DEFAULT_AVATAR_SIZE = 30;

interface IUserWithAvatar {
  id: TID,
  parsedFullname: string,
  avatar?: ComponentPropsWithoutRef<typeof UserAvatar>['avatar'],
}

interface IUserAvatarListProps {
  /** Size of avatar */
  size?: ComponentPropsWithoutRef<typeof UserAvatar>['size'],
  /**
   * Array of users to render. Should include fields `parsedFullname` and `avatar`->`avatarUrl`.
   */
  users: IUserWithAvatar[],
  /** Number of avatars to render */
  visibleCount?: number;
}

/**
 * Show list of user avatars. Render only first `visibleCount` avatars.
 */
const UserAvatarsList = ({
  size = DEFAULT_AVATAR_SIZE,
  users,
  visibleCount = DEFAULT_VISIBLE_COUNT,
}: IUserAvatarListProps): JSX.Element => {
  const isOverCount = users.length > visibleCount;
  const userArray = isOverCount ? users.slice(0, visibleCount) : users;

  return (
    <Tooltip.Hover
      className="display-inline-flex"
      content={ userArray.map((member) => member.parsedFullname).join(', ') }
    >
      <div className="display-flex align-items-center p-t-5 p-b-5">
        {
          userArray.map((user) => (
            <UserAvatar key={ user.id } avatar={ user.avatar } mr={ 5 } size={ size } />
          ))
        }
        { isOverCount && (
          <CountAvatar count={ users.length - visibleCount } size={ size } />
        )}
      </div>
    </Tooltip.Hover>
  );
};

export default React.memo(UserAvatarsList);
