import React, { useCallback } from 'react';

import classNames from 'classnames';
import snakeCase from 'lodash/snakeCase';
import { ControllerRenderProps } from 'react-hook-form';

import { IForwardDocumentFormValues } from './forward_document_form';

interface IForwardDocumentServiceInputProps {
  disabled: boolean,
  field: ControllerRenderProps<IForwardDocumentFormValues, 'forwardToService'>,
  hasDivider?: boolean,
  icon: React.FC<{ className: string }>,
  iconProps?: object,
  label: string,
  value: string,
}

const ForwardDocumentServiceInput = ({
  disabled,
  field: { name: fieldName, value: fieldValue, onChange },
  hasDivider,
  icon: Icon,
  iconProps,
  label,
  value,
}: IForwardDocumentServiceInputProps): JSX.Element => {
  const inputId = `forward_to_service_${snakeCase(value)}`;
  const classes = classNames(
    'service-clickable p-t-15 p-b-15',
    { 'item-divider': hasDivider },
  );
  const styles = disabled ? { cursor: 'not-allowed' } : {};

  const handleSelect = useCallback(() => {
    if (disabled) return;

    onChange(value);
  }, [onChange, value, disabled]);

  return (
    <li
      className={ classes }
      role="presentation"
      style={ styles }
      onClick={ handleSelect }
    >
      <Icon
        className="service-trademark font-40 vertical-align-middle-important"
        { ...iconProps }
      />
      <label
        className="m-l-10"
        htmlFor={ inputId }
        style={ styles }
      >
        { label }
      </label>
      <div className="checkbox checkbox-primary checkbox-circle pull-right">
        <input
          checked={ fieldValue === value }
          disabled={ disabled }
          id={ inputId }
          name={ fieldName }
          type="radio"
          value={ value }
          onChange={ handleSelect }
        />
        <label
          aria-label={ label }
          className="forward-to-service-checkbox-label"
          htmlFor={ inputId }
        />
      </div>
    </li>
  );
};

export default ForwardDocumentServiceInput;
