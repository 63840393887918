import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ErrorCircle = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="error_circle"
    />
  );
};

export default ErrorCircle;
