module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class=\'client__docs-cell client__docs-li-title open-document\' style=\'padding-bottom: 20px !important;\'>\n  <p class=\'client__docs-li-text-document-field category-name-box\'>\n    ');
    
      if (this.data_type !== "url") {
        __out.push('\n      ');
        if (this.name.toUpperCase() === "PASSWORD") {
          __out.push('\n      <label class="password-field hidden">');
          __out.push(__sanitize(this.name));
          __out.push(': <span class=\'document-field-value\'>');
          __out.push(__sanitize(this.value));
          __out.push('</span></label>\n      <a class="show-password-link font-bold">Show Password</a>\n      ');
        } else {
          __out.push('\n      <label>');
          __out.push(__sanitize(this.name));
          __out.push(': <span class=\'document-field-value\'>');
          __out.push(__sanitize(this.value));
          __out.push('</span></label>\n      ');
        }
        __out.push('\n    ');
      } else {
        __out.push('\n      <label>');
        __out.push(__sanitize(this.name));
        __out.push(': <span class=\'document-field-value\'><a href="http://');
        __out.push(__sanitize(this.value));
        __out.push('">');
        __out.push(__sanitize(this.value));
        __out.push('</a></span></label>\n    ');
      }
    
      __out.push('\n  </p>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}