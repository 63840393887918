import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TDate } from '@src/types/common';

import Modal from '@src/components/ui/modal';

import Form from './confirm_modal_form';

import styles from './styles.module.scss';

interface IVerifyBankFeedConfirmModalProps extends IUseModalProps {
  lastTransactionDate?: TDate,
}

const VerifyBankFeedConfirmModal = ({
  isOpen,
  lastTransactionDate,
  onCancel,
  onDone,
}: IVerifyBankFeedConfirmModalProps) => {
  return (
    <Modal.Form
      className={ styles['verify-bank-feed-confirm-modal'] }
      proceedTitle="Continue"
      show={ isOpen }
      title="Warning"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          formId={ formId }
          lastTransactionDate={ lastTransactionDate }
          onSubmit={ onDone }
        />
      )}
    </Modal.Form>
  );
};

const useVerifyBankFeedConfirmModal = makeUseModal(VerifyBankFeedConfirmModal);

export {
  IVerifyBankFeedConfirmModalProps,
  useVerifyBankFeedConfirmModal,
  VerifyBankFeedConfirmModal as default,
};
