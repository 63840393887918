import React from 'react';

import Root from '@src/components/root';

import { useEditProfileModal } from '../edit_profile/edit_profile_modal';

interface IEditProfileActionProps {
  businessId: number,
}

const EditProfileAction = ({
  businessId,
}: IEditProfileActionProps) => {
  const editProfile = useEditProfileModal();

  return (
    <Root>
      <editProfile.Component
        { ...editProfile.props }
        businessId={ businessId }
      />
      <a
        className="font-14 font-bold pointer"
        role="button"
        tabIndex={ -1 }
        onClick={ editProfile.open }
      >
        Edit Profile
      </a>
    </Root>
  );
};

export default EditProfileAction;
