import React from 'react';

import { TID } from '@src/types/common';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IJournalEntryItemListProps {
  transactionId: TID,
  adjustmentEntry: any,
}

const JournalEntryItemList = ({
  adjustmentEntry,
  transactionId,
}: IJournalEntryItemListProps): JSX.Element => {
  return (
    <Table wrapperClassName="related-transactions-table">
      <Table.Head>
        <Table.Row>
          <Table.HCell>Date</Table.HCell>
          <Table.HCell>Chart of Account</Table.HCell>
          <Table.HCell textAlign="right" widthPercent={ 15 }>Amount</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {adjustmentEntry && (
        <Item
          key={ adjustmentEntry.id }
          adjustmentEntry={ adjustmentEntry }
          transactionId={ transactionId }
        />
        )}
      </Table.Body>
    </Table>
  );
};

export default JournalEntryItemList;
