import React from 'react';

import { IQBOLink } from '@src/types/qbo_links';

import Tooltip from '@src/components/ui_v2/tooltip';
import { QBOErrorIcon, QBOOnIcon } from '@src/components/utils/icomoon';

interface IQboLinkProps {
  entry?: IQBOLink;
}

const QboLink = ({ entry }: IQboLinkProps) => {
  return (
    <Tooltip.Hover content="View in QuickBooks">
      { entry?.qboLink ? (
        <a
          aria-label="View in QuickBooks"
          href={ entry.qboLink }
          rel="noreferrer"
          target="_blank"
        >
          <QBOOnIcon />
        </a>
      ) : <QBOErrorIcon />}
    </Tooltip.Hover>
  );
};

export default React.memo(QboLink);
