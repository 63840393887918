module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var categoryTemplate, i, len, ref;
    
      __out.push('<div class="class-category-template-wrapper">\n  <div class="dropdown-toggle class-template-dropdown-control" id="class-template-dropdown-btn" data-toggle="dropdown">\n    <span class="template-label-js"></span>\n    <span class="fa fa-caret-down"></span>\n  </div>\n  <ul class="dropdown-menu" aria-labelledby="class-template-dropdown-btn">\n    ');
    
      ref = this.templates;
      for (i = 0, len = ref.length; i < len; i++) {
        categoryTemplate = ref[i];
        __out.push('\n    <li class="template-item-js" data-id=\'');
        __out.push(__sanitize(categoryTemplate.id));
        __out.push('\'>\n      <div class=\'display-flex align-items-center\'>\n        <span class=\'text m-t-3\'>');
        __out.push(__sanitize(categoryTemplate.get('name')));
        __out.push('</span>\n        <i class=\'icon icon-trashcan hover-action-item m-l-auto\'></i>\n      </div>\n    </li>\n    ');
      }
    
      __out.push('\n    <li class="create-template-btn create-template-js">\n      <div class=\'display-flex align-items-center\'>\n        <i class=\'font-14 fa fa-plus\'></i>\n        <span class=\'text m-t-3 m-l-10\'>Create Mapping Template</span>\n      </div>\n    </li>\n  </ul>\n  <a class="template-item-href" href="/businesses/');
    
      __out.push(__sanitize(this.business.id));
    
      __out.push('/class_mappings/');
    
      __out.push(__sanitize(this.currentId));
    
      __out.push('">\n  </a>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}