import React from 'react';

import { MetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { useGetMetricsServiceByBusinessId } from '@src/hooks/queries/metrics_services';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IMetricsServiceProviderProps {
  businessId: number,
  children: React.ReactNode,
}

const MetricsServiceProvider = ({
  businessId,
  children,
}: IMetricsServiceProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetMetricsServiceByBusinessId(businessId);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorNotification error={ error } />;
  if (!data) return <ErrorNotification message="No Metrics Service loaded" />;

  return (
    <MetricsServiceContext.Provider value={ data }>
      { children }
    </MetricsServiceContext.Provider>
  );
};

export default MetricsServiceProvider;
