import { apiGet } from '@src/requests/helpers';
import { underscoreKeys } from '@src/utils/transform_keys';

interface IGetQRCodeForSelfOnboardingInvitationParams {
  selfOnboardingInvitationId: number,
}

const getQRCodeForSelfOnboardingInvitation = (
  params: IGetQRCodeForSelfOnboardingInvitationParams,
): Promise<string> => {
  return apiGet(
    '/api/v1/digital_signatures/qrcode_for_self_onboarding',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const blob = new Blob([data.svg], { type: 'image/svg+xml' });
    return window.URL.createObjectURL(blob);
  });
};

export {
  IGetQRCodeForSelfOnboardingInvitationParams,
  getQRCodeForSelfOnboardingInvitation,
};
