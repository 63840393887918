module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, index, len, ref;
    
      __out.push('<div class="multi-enity-container">\n  <div class="display-flex item-divider m-b-16 p-b-16">\n    <div class="multi-entity-configuration-detail-header display-flex align-items-center font-24 font-bold pull-left m-t-20">\n      <a href="multi_entity_reports/reports" class="pointer font-16">Reports  /</a>\n      <span class="m-l-7 flex-container">');
    
      __out.push(__sanitize(this.configuration.getFullName()));
    
      __out.push('</span>\n      <div class="line-height-30 display-flex text-center pointer business-item-avatars business-items">\n        ');
    
      ref = this.businesses;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        business = ref[index];
        __out.push('\n          ');
        if (index < 5) {
          __out.push('\n            ');
          if (business.avatar) {
            __out.push('\n              <div class="middle-avatar-wrapper border-gray business-item-avatar multi-report-business-item-avatar">\n                <img src="');
            __out.push(__sanitize(business.avatar.avatar_url));
            __out.push('">\n              </div>\n            ');
          } else {
            __out.push('\n              <span class=\'icon-business-default-avatar font-30 business-item-avatar\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n            ');
          }
          __out.push('\n          ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n        ');
    
      if (this.businesses.length > 5) {
        __out.push('\n          <span class=\'icon-business-default-avatar business-item-avatar text-center display-flex font-30\'><span class=\'path1\'></span><span class="count">+');
        __out.push(__sanitize(this.businesses.length - 5));
        __out.push('</span></span>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n  <div class="display-flex font-semi-bold font-16 in-blue-900">\n    <div class="btn-year back-js pointer ');
    
      __out.push(__sanitize(this.currentYear <= this.firstYear ? 'hidden' : ''));
    
      __out.push('">\n      <span class="icon-arrow-right back m-t-5 m-l-auto"></span>\n      <span class="m-l-8 m-r-auto">');
    
      __out.push(__sanitize(this.currentYear - 1));
    
      __out.push('</span>\n    </div>\n    <div class="btn-year m-l-auto next-js pointer ');
    
      __out.push(__sanitize(this.currentYear >= this.lastYear ? 'hidden' : ''));
    
      __out.push('">\n      <span class="m-l-auto">');
    
      __out.push(__sanitize(this.currentYear + 1));
    
      __out.push('</span>\n      <span class="icon-arrow-right m-b-5 m-l-8 m-r-auto"></span>\n    </div>\n  </div>\n  <div class="table-responsive reports-table-wrapper m-t-15">\n    <table class="table reports-table management-reports-table">\n      <thead>\n        <tr class="report-table-headers">\n          <th class="pointer relative sortable reports-month-column-header sorted-asc" data-orderby="month" data-orderdir="ASC">Period</th>\n          ');
    
      if (this.configuration.get('report_type') === "profit_loss") {
        __out.push('\n          <th class="pointer relative sortable reports-value-column-header">Income</th>\n          <th class="pointer relative sortable reports-value-column-header">Expense</th>\n          <th class="pointer relative sortable reports-updated-column-header">Net Profit</th>\n          ');
      } else {
        __out.push('\n          <th class="pointer relative sortable reports-value-column-header">Assets</th>\n          <th class="pointer relative sortable reports-value-column-header">Liablilities</th>\n          <th class="pointer relative sortable reports-updated-column-header">Equity</th>\n          ');
      }
    
      __out.push('\n          <th class="reports-option-column-header"></th>\n        </tr>\n      </thead>\n      <tbody>\n      </tbody>\n    </table>\n  </div>\n  <div class="reports-table-container"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}