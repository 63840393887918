import React, { useCallback } from 'react';

import { useDestroyDocument } from '@src/hooks/queries/documents';
import { IDocument } from '@src/types/documents';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDocumentDeleteActionProps {
  document: IDocument,
}

const DocumentDeleteAction = ({
  document,
}: IDocumentDeleteActionProps): JSX.Element | null => {
  const destroy = useDestroyDocument();

  const { mutate: destroyDocument } = destroy;
  const handleConfirm = useCallback(() => {
    destroyDocument({ id: document.id });
  }, [destroyDocument, document.id]);

  const destroyModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus
        mutation={ destroy }
        successMessage="Deleted successfully"
      />
      <destroyModal.Component
        text="Are you sure you want to proceed and delete this document?"
        title="Remove Document"
        { ...destroyModal.props }
      />
      <ActionsDropdown.DangerAction
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        name="Delete"
        onClick={ destroyModal.open }
      />
    </>
  );
};

export default DocumentDeleteAction;
