import React from 'react';

import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import W9InfoDropInput from './w9_info_drop_input';
import W9InfoView from './w9_info_file_view';

interface IW9InfoUploadViewProps {
  w9Info?: IW9InfoResponse,
  vendorId: TID,
  businessId: TID,
  modalMode?: string
}

const W9InfoUploadView = ({
  w9Info,
  vendorId,
  businessId,
  modalMode,
}: IW9InfoUploadViewProps): JSX.Element => {
  return w9Info?.w9FileUrl ? (
    <W9InfoView fileUrl={ w9Info?.w9FileUrl } modalMode={ modalMode } />
  ) : (
    <W9InfoDropInput
      businessId={ businessId }
      modalMode={ modalMode }
      vendorId={ vendorId }
    />
  );
};

export default W9InfoUploadView;
