import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const LedgerSyncingCentre = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="ledger-syncing-center"
      pathsNumber={ 3 }
    />
  );
});

LedgerSyncingCentre.displayName = 'LedgerSyncingCentre';

export default LedgerSyncingCentre;
