module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.current_step === 1) {
        __out.push('\n<span class="font-18 font-semi-bold in-blue-400 pointer m-t-10 cancel-import-js">Cancel</span>\n<label class="font-16 m-t-10 m-r-15">');
        __out.push(__sanitize(this.selected_count));
        __out.push(' Vendors Selected</label>\n<button type=\'submit\' class="btn btn-primary ladda-button continue-import-js" data-color="blue" data-style="expand-right" tabindex="3"><span class="ladda-label font-16 font-semi-bold">Continue</span></button>\n');
      } else if (this.current_step === 2) {
        __out.push('\n<span class="font-18 font-semi-bold in-blue-400 pointer m-t-10 back-import-js">Back</span>\n<button type=\'submit\' class="btn btn-primary ladda-button finish-import-js" data-color="blue" data-style="expand-right" tabindex="3"><span class="ladda-label font-16 font-semi-bold">Finish Importing</span></button>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}