module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex align-items-center m-b-30">\n  <span class="font-20 font-bold">W-9 Information</span>\n  ');
    
      if (this.hasW9Info) {
        __out.push('\n  <a class="font-semi-bold m-l-auto pointer edit-info-link font-14">Edit Information</a>\n  ');
      }
    
      __out.push('\n</div>\n<div id="w9-info-detail-field">\n  ');
    
      if (!this.hasW9Info) {
        __out.push('\n    <span class="display-inline-block m-b-10 font-14">There is no W-9 information added to this vendor.</span>\n    <br>\n    <a class="font-semi-bold m-l-auto pointer add-info-link font-14">Add Information</a>\n  ');
      } else {
        __out.push('\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Legal Name: </span>\n    <span>');
        __out.push(__sanitize(this.w9_legal_name));
        __out.push('</span>\n  </p>\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Legal Business Name: </span>\n    <span>');
        __out.push(__sanitize(this.w9_name));
        __out.push('</span>\n  </p>\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Email: </span>\n    <span>');
        __out.push(__sanitize(this.w9_email));
        __out.push('</span>\n  </p>\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Phone: </span>\n    <span>');
        __out.push(__sanitize(this.w9_phone));
        __out.push('</span>\n  </p>\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Legal Entity Type: </span>\n    <span>');
        __out.push(__sanitize(this.w9_business_type));
        __out.push('</span>\n  </p>\n  <p class="font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Legal Entity Address: </span>\n    <span>');
        __out.push(__sanitize(this.w9_address.address_line1));
        __out.push(' ');
        __out.push(__sanitize(this.w9_address.address_line2));
        __out.push('</span>\n  </p>\n  <p class="font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36"></span>\n    <span>');
        __out.push(__sanitize(this.w9_address.address_city));
        __out.push(', ');
        __out.push(__sanitize(this.w9_address.address_state));
        __out.push(', ');
        __out.push(__sanitize(this.w9_address.address_zip));
        __out.push('</span>\n  </p>\n  <p class="m-b-30 font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36"></span>\n    <span>');
        __out.push(__sanitize(this.w9_address_country));
        __out.push('</span>\n  </p>\n  <p class="font-14">\n    <span class="font-bold display-inline-flex width-150px m-r-36">Taxpayer Identification<br> Number(TIN): </span>\n    ');
        if (this.w9_ssn) {
          __out.push('\n    <span>Social Security: *****');
          __out.push(__sanitize(this.w9_ssn));
          __out.push('</span>\n    ');
        } else if (this.w9_ein) {
          __out.push('\n    <span>Business EIN: *****');
          __out.push(__sanitize(this.w9_ein));
          __out.push('</span>\n    ');
        }
        __out.push('\n  </p>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}