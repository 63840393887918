module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td></td>\n<td class="vertical-align-middle-important">\n  <div class="checkbox checkbox-primary checkbox-circle">\n    <input type="radio" class="pointer vendor-select-radio" name="select-vendor-radio-');
    
      __out.push(__sanitize(this.qbo_vendor.get('id')));
    
      __out.push('" id="qbo-vendor-checkbox-');
    
      __out.push(__sanitize(this.qbo_vendor.get('id')));
    
      __out.push('" value="Qbo_Vendor" ');
    
      if (this.business_vendors_count === 0) {
        __out.push(__sanitize('checked disabled'));
      }
    
      __out.push(' >\n    <label class="qbo-vendor-select-label ');
    
      if (this.business_vendors_count === 0) {
        __out.push(__sanitize('qbo-vendor-select-grey-label'));
      }
    
      __out.push('" for="qbo-vendor-checkbox-');
    
      __out.push(__sanitize(this.qbo_vendor.get('id')));
    
      __out.push('"></label>\n  </div>\n</td>\n<td class="vertical-align-middle-important">\n  <label class="in-black font-bold">');
    
      __out.push(__sanitize(this.qbo_vendor.get('name')));
    
      __out.push('</label><br>\n  <label>');
    
      __out.push(__sanitize(this.qbo_vendor.get('address')));
    
      __out.push('</label>\n</td>\n<td class="vertical-align-middle-important">\n  ');
    
      if (this.business_vendors_count > 0) {
        __out.push('\n  <div class="checkbox checkbox-primary checkbox-circle">\n    <input type="radio" class="pointer vendor-select-radio" name="select-vendor-radio-');
        __out.push(__sanitize(this.qbo_vendor.get('id')));
        __out.push('" id="business-vendor-checkbox-');
        __out.push(__sanitize(this.qbo_vendor.get('id')));
        __out.push('" value="Business_Vendor" checked >\n    <label class="business-vendor-select-label" for="business-vendor-checkbox-');
        __out.push(__sanitize(this.qbo_vendor.get('id')));
        __out.push('"></label>\n  </div>\n  ');
      }
    
      __out.push('\n</td>\n<td class="vertical-align-middle-important">\n  ');
    
      if (this.business_vendors_count > 0 && this.business_vendors) {
        __out.push('\n    <div class="display-flex align-items-center">\n      ');
        if (this.business_vendors[this.choosed_dropdown_index].image_url !== null) {
          __out.push('\n      <img src="');
          __out.push(__sanitize(this.business_vendors[this.choosed_dropdown_index].image_url));
          __out.push('" class=\'small-avatar-wrapper\'>\n      ');
        } else {
          __out.push('\n      <img src="');
          __out.push(__sanitize(configData.vendor_images['VENDOR_SERVICE']));
          __out.push('" class=\'small-avatar-wrapper\'>\n      ');
        }
        __out.push('\n      <div class="m-l-14 width-100-percent">\n        <label class="pointer in-blue-400 font-bold vendor-profile-link">');
        __out.push(__sanitize(this.business_vendors[this.choosed_dropdown_index].name));
        __out.push('</label><br>\n        <label>');
        __out.push(__sanitize(this.address));
        __out.push('</label>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n</td>\n<td class="vertical-align-middle-important">\n  ');
    
      if (this.business_vendors_count > 1) {
        __out.push('\n  <div class="dropdown">\n    <span class="pointer fa-caret-down font-20 show-vendor-matches dropdown-toggle" id="dropdown-vendor-matches" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></span>\n    <div class="dropdown-menu vendor-matches-list" aria-labelledby="dropdown-vendor-matches">\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}