import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TID } from '@src/types/common';

import SideView from '@src/components/ui_v2/side_view';

import DetailsInfo from './details_info';
import RelatedDocuments from './related_documents/related_documents';
import Tracking from './tracking';

import styles from './styles.module.scss';

interface IAchDetailsSideViewProps extends IUseModalProps<TOpenValue> {
}

type TOpenValue = {
  businessId: TID,
  serviceId: TID,
  document: IAchTransaction,
}

const title = (name: string) => {
  return (
    <div className={ styles.title }>
      { name }
    </div>
  );
};

const AchDetailsSideView = ({
  isOpen,
  openValue,
  onCancel,
}: IAchDetailsSideViewProps): JSX.Element => {
  const { businessId, serviceId, document } = openValue || {};

  return (
    <SideView.Provider>
      <SideView.Standard
        isRoot
        isOpen={ isOpen }
        title={ title('DETAILS') }
        onCancel={ onCancel }
      >
        {
          () => (
            <div className={ styles.body }>
              <DetailsInfo document={ document } />
              {
                businessId && serviceId && document && (
                  <>
                    <RelatedDocuments
                      businessId={ businessId }
                      document={ document }
                      serviceId={ serviceId }
                    />
                    <Tracking
                      document={ document }
                    />
                  </>
                )
              }
            </div>
          )
        }
      </SideView.Standard>
      <SideView.Render size="compact" />
    </SideView.Provider>
  );
};

const MemoizedAchDetailsSideView = React.memo(AchDetailsSideView);

const useAchDetailsSideView =
  makeUseModal<typeof MemoizedAchDetailsSideView, TOpenValue>(MemoizedAchDetailsSideView);

export {
  useAchDetailsSideView,
  MemoizedAchDetailsSideView as default,
};
