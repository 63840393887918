import React, { useCallback } from 'react';

import { useBulkMoveDocumentsToTrash } from '@src/hooks/queries/documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteDocumentsModal } from '@src/components/common/confirm_delete';
import { LinkButton } from '@src/components/ui/buttons';

interface IBusinessInboxDeleteActionProps {
  documentIds: number[],
  onDone: () => void,
}

const BusinessInboxDeleteAction = ({
  documentIds,
  onDone,
}: IBusinessInboxDeleteActionProps): JSX.Element => {
  const moveToTrash = useBulkMoveDocumentsToTrash();
  const bulkAction = useBulkActionModal({
    mutation: moveToTrash,
    onDone,
  });

  const { runMutation } = bulkAction;
  const handleConfirm = useCallback(() => {
    const params = documentIds.map((documentId) => ({
      documentIds: [documentId],
      value:       true,
    }));

    runMutation(params);
  }, [runMutation, documentIds]);

  const confirm = useConfirmDeleteDocumentsModal({
    documentIds,
    onDone: handleConfirm,
  });

  return (
    <>
      <confirm.Component { ...confirm.props } />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Documents"
        title="Delete Documents"
      />
      <div className="btn-group">
        <LinkButton
          className="delete-documents-btn"
          disabled={ documentIds.length === 0 }
          fontSize={ 16 }
          fontVariant="semi-bold"
          title="Delete"
          onClick={ confirm.open }
        />
      </div>
    </>
  );
};

export default BusinessInboxDeleteAction;
