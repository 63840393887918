import {
  IGetPracticeInformationsParams,
  getPracticeInformations,
  IGetPracticeInformationsResponse,
} from '@src/requests/practice_informations';
import { IPracticeInformation } from '@src/types/practice_informations';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const PRACTICE_INFORMATIONS_QUERY = 'practice_informations';

const useGetPracticeInformations = createUseGetInfiniteCollection<
  IPracticeInformation,
  IGetPracticeInformationsParams,
  IGetPracticeInformationsResponse
>({
  queryKey: PRACTICE_INFORMATIONS_QUERY,
  request:  getPracticeInformations,
});

export {
  PRACTICE_INFORMATIONS_QUERY,
  useGetPracticeInformations,
};
