import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import HeaderCell from './header_cell';
import { TColumn, TModel } from './types';

interface ICollectionTableHeaderProps<Model extends TModel, TSortColumn extends string = never> {
  bulkActions?: React.ComponentPropsWithoutRef<typeof Table.SelectAllHCell>['bulkActions'],
  columns: TColumn<Model, TSortColumn>[],
  showSelect?: boolean,
  stickySelectColumn?: boolean,
  sorting: ISorting<TSortColumn>,
}

const CollectionTableHeader = <Model extends TModel, TSortColumn extends string = never>({
  bulkActions,
  columns,
  showSelect,
  sorting,
  stickySelectColumn,
}: ICollectionTableHeaderProps<Model, TSortColumn>) => {
  return (
    <Table.Head>
      <Table.Row>
        { showSelect && (
          <Table.SelectAllHCell bulkActions={ bulkActions } sticky={ stickySelectColumn } />
        ) }
        { columns.map((column) => (
          <HeaderCell<Model, TSortColumn>
            key={ String(column.name) }
            column={ column }
            sorting={ sorting }
          />
        )) }
      </Table.Row>
    </Table.Head>
  );
};

export default CollectionTableHeader;
