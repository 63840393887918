import Body from './body';
import BottomModal from './bottom_modal';
import Footer from './footer';
import Form from './form_modal';
import StandardFooter from './standard_footer';
import Standard from './standard_modal';
import SubmitFormFooter from './submit_form_footer';

export default Object.assign(BottomModal, {
  Body,
  Footer,
  Form,
  Standard,
  StandardFooter,
  SubmitFormFooter,
});
