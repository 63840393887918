import React, { forwardRef } from 'react';

import TextInput from '@src/components/ui_v2/inputs/text_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface ITextFieldProps extends React.ComponentPropsWithoutRef<typeof TextInput>, IWrapperProps {
}

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(({
  ...props
}: ITextFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <TextInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

TextField.displayName = 'TextField';

export default TextField;
