import React from 'react';

import { TID } from '@src/types/common';

import { Accordion } from '@src/components/ui_v2/accordion';
import Section from '@src/components/utils_v2/section';

import { useRelatedDocumentsCollection } from './hooks';
import Table from './related_documents_table';

interface IRelatedDocumentsProps {
  documentId: TID,
}

const RelatedDocuments = ({
  documentId,
}: IRelatedDocumentsProps) => {
  const collection = useRelatedDocumentsCollection({ documentId });

  return (
    <Accordion
      title="Related Documents"
    >
      { () => (
        <Section.Provider section={ collection.section }>
          <Table
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </Section.Provider>
      ) }
    </Accordion>
  );
};

export default React.memo(RelatedDocuments);
