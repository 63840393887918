import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IDummyIconProps extends Omit<IFaIconProps, 'icon'> {}

const DummyIcon = (props: IDummyIconProps): JSX.Element => {
  return <FaIcon icon="fw" { ...props } />;
};

export default DummyIcon;
