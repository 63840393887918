module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade transaction-add-document">\n  <div class="modal-dialog modal-lg modal-xlg transaction-add-document-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header" style="margin-bottom: 0; padding-top: 5px; padding-bottom: 10px;">\n        <div class=".transaction-add-document-special-header">\n          <a class="pointer close close-link font-40"><span aria-hidden="true">&times;</span></a>\n          <h1 class="font-24 font-bold m-l-auto m-r-auto text-center">Add Document</h1>\n        </div>\n      </div>\n      <div class="modal-body display-flex">\n        <div class="select-document-zone">\n          <div class=\'client-drop-zone ');
    
      __out.push(__sanitize(this.related_documents ? "no-drop" : ""));
    
      __out.push(' p-t-25\'>\n            <p class="font-20 font-bold p-l-30 p-b-0 m-b-0">From Your Computer</p>\n            <div id=\'document-drop-region\' ></div>\n            <div id=\'current-document-region\'></div>\n          </div>\n          <div class=\'client-business-mailroom-zone ');
    
      __out.push(__sanitize(this.related_documents ? "no-drop" : ""));
    
      __out.push(' p-l-30 p-t-25 p-r-30\'>\n            <p class="font-20 font-bold">From Business Mailroom</p>\n            <div id="business-mailroom-region"></div>\n          </div>\n        </div>\n        <div class="client-document-preview-zone p-t-25">\n          <p class="font-20 font-bold p-l-30 p-b-0 m-b-0">Preview</p>\n          <div id=\'document-preview-region\' ></div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel cancel-link settings-cancel-btn m-t-8 pull-left font-18 font-semi-bold\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue upload-document-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Upload Document</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}