import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';
import { useMergeVendorModal } from '@src/components/vendors/views/merge_vendor';

import { ISelectMergeableVendorFormValues } from './schema';
import Form from './select_mergeable_vendor_modal_form';

import styles from './styles.module.scss';

interface ISelectMergeableVendorModalProps extends IUseModalProps {
  businessVendor: IBusinessVendor,
}

const SelectMergeableVendorModal = ({
  businessVendor,
  isOpen,
  onCancel,
  onDone,
}: ISelectMergeableVendorModalProps) => {
  const mergeVendorModal = useMergeVendorModal({});

  const { openWithValue: openMergeVendorModal } = mergeVendorModal;

  const handleSubmit = useCallback((values: ISelectMergeableVendorFormValues) => {
    openMergeVendorModal({
      mergeableVendor: values.mergeableVendor,
    });
    onDone();
  }, [onDone, openMergeVendorModal]);

  return (
    <>
      <mergeVendorModal.Component
        { ...mergeVendorModal.props }
        businessId={ businessVendor.businessId }
        vendor={ businessVendor }
      />
      <Modal.Form
        dialogClassName="select-multi-item-area"
        formId="merge_vendor_form"
        proceedTitle="Next"
        show={ isOpen }
        title={ (
          <div>
            <span>Merge Vendor ‣ </span>
            <span className={ styles['secondary-title'] }>Validate Info</span>
          </div>
        ) }
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            businessVendor={ businessVendor }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useSelectMergeableVendorModal = makeUseModal(SelectMergeableVendorModal);

export {
  ISelectMergeableVendorModalProps,
  useSelectMergeableVendorModal,
  SelectMergeableVendorModal as default,
};
