import React from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { IAchTransactionsCollection } from './hooks';
import Table from './table';

import styles from './styles.module.scss';

interface IAchTransactionsListProps {
  collection: IAchTransactionsCollection,
  onRowClick: (document: IAchTransaction) => void,
}

const AchTransactionsList = ({
  collection,
  onRowClick,
}: IAchTransactionsListProps) => {
  return (
    <Section.Provider section={ collection.section }>
      <TableSection className={ styles['ach-transactions-table-section'] }>
        <Table
          infiniteQuery={ collection.query }
          records={ collection.records }
          sorting={ collection.sorting }
          onRowClick={ onRowClick }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default React.memo(AchTransactionsList);
