import React from 'react';

import classNames from 'classnames';

import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

interface ISubItemProps {
  href: string,
  title: string,
  launchDarklyFeature?: TLaunchDarklyFeature,
}

const SubItem = ({
  href,
  title,
  launchDarklyFeature,
}: ISubItemProps): JSX.Element | null => {
  const titleClasses = classNames(
    'font-14 m-t-8 m-b-8',
  );

  return (
    <li data-global-feature-toggle={ launchDarklyFeature }>
      <a className="submenu-title" href={ href }>
        <span className={ titleClasses }>{ title }</span>
      </a>
    </li>
  );
};

export default SubItem;
