import { TOption } from '@src/components/ui_v2/inputs';
import { TRichAmount } from '@src/components/ui_v2/inputs/rich_amount_input/types';

type TObjectWithKey = Record<string, any>;

export const changeKeyInArrayOfObject = (
  keyPairs: [string, string][],
  array?: TObjectWithKey[],
): TObjectWithKey[] => {
  if (array && array.length) {
    const response = array.map((obj) => {
      keyPairs.forEach(([oldKey, newKey]) => {
        if (Object.prototype.hasOwnProperty.call(obj, oldKey)) {
          obj[newKey] = obj[oldKey];
          delete obj[oldKey];
        }
      });
      return obj;
    });
    return response;
  }
  return [];
};

export const transformObject = (inputObject: any) => {
  return {
    value: inputObject.key,
    label: inputObject.value,
  };
};

export const transformObjectArray = (inputObjectArray: any) => {
  return inputObjectArray?.map(transformObject);
};

export const checkAmountRange = (amountValue?: TRichAmount): boolean => {
  if (amountValue && (amountValue.gte || amountValue.lte)) {
    if (!amountValue.gte || !amountValue.lte) {
      return true;
    }
    return Number(amountValue?.lte) < Number(amountValue?.gte);
  }
  return false;
};

export const checkIfStatusIsValid = (status: string, statusOptions?: TOption[]):boolean => {
  return !!statusOptions?.find((option) => option.value === status);
};

export const toggleBodyScroll = (scroll: boolean) => {
  document.body.style.overflow = scroll ? 'auto' : 'hidden';
};
