module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'display-flex align-items-center item-divider p-t-15 p-b-15 p-l-25 p-r-10\'>\n  <div class="font-18 font-semi-bold">Add Payee</div>\n  <i class="icon icon-reject-invite font-40 font-bold m-l-auto in-grey-900 pointer close-btn"></i>\n</div>\n<div class="item-divider font-14 p-t-20 p-b-20 p-l-25 p-r-25">\n  ');
    
      if (this.isDeliverable) {
        __out.push('\n    We have slightly modified the address entered. If correct, please use the suggested address to ensure accurate delivery.\n  ');
      } else {
        __out.push('\n    We were not able to verify the address entered. <b>Please review it before confirming</b>\n  ');
      }
    
      __out.push('\n</div>\n<div class="payment-addresses-list item-divider">\n    <div class="radio radio-inline suggest-address-radio">\n      <input type="radio" class="styled form-control pointer input-element entered-address-js" name="payment-address">\n      <label>\n        <div class="p-t-5 font-semi-bold display-flex in-black">Entered Address</div>\n        <div>');
    
      __out.push(__sanitize(this.enteredAddress.address_line1));
    
      __out.push('</div>\n        ');
    
      if (this.enteredAddress.address_line2) {
        __out.push('<div class="">');
        __out.push(__sanitize(this.enteredAddress.address_line2));
        __out.push('</div>');
      }
    
      __out.push('\n        <div><span>');
    
      __out.push(__sanitize(this.enteredAddress.address_city));
    
      __out.push('</span><span class="m-l-8">');
    
      __out.push(__sanitize(this.enteredAddress.address_state));
    
      __out.push('</span><span class="m-l-8">');
    
      __out.push(__sanitize(this.enteredAddress.address_zip));
    
      __out.push('</span></div>\n        <div>');
    
      __out.push(__sanitize(this.enteredAddress.address_country));
    
      __out.push('</div>\n      </label>\n      <a class=\'m-l-auto pointer font-semi-bold edit-address-btn\'>\n        <span>Edit Address</span>\n      </a>\n    </div>\n  ');
    
      if (this.isDeliverable) {
        __out.push('\n    <div class="item-divider"></div>\n    <div class="radio radio-inline suggest-address-radio">\n      <input type="radio" class="styled form-control pointer input-element suggested-address-js" name="payment-address">\n      <label>\n        <div class="p-t-5 font-semi-bold in-black">Suggested Address</div>\n        <div class="line-height-20">');
        __out.push(__sanitize(this.suggestedAddress.address_line1));
        __out.push('</div>\n        ');
        if (this.suggestedAddress.address_line2) {
          __out.push('<div class="line-height-20">');
          __out.push(__sanitize(this.suggestedAddress.address_line2));
          __out.push('</div>');
        }
        __out.push('\n        <div class="line-height-20"><span>');
        __out.push(__sanitize(this.suggestedAddress.address_city));
        __out.push('</span><span class="m-l-8">');
        __out.push(__sanitize(this.suggestedAddress.address_state));
        __out.push('</span><span class="m-l-8">');
        __out.push(__sanitize(this.suggestedAddress.address_zip));
        __out.push('</span></div>\n        <div class="line-height-20">');
        __out.push(__sanitize(this.suggestedAddress.address_country));
        __out.push('</div>\n      </label>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n<div class="m-t-auto item-divider"></div>\n<div class="text-center p-t-15 p-b-15 p-l-25 p-r-25">\n  <button type=\'button\' class="width-100-percent btn btn-blue font-semi-bold confirm-js">Confirm</button>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}