import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Action from '@src/components/ui/actions_dropdown/action';

interface IActionsDropdownDangerActionProps extends IUIStyleProps {
  /** Action icon. */
  icon?: JSX.Element,
  /** If passed then action will work as usual link and redirects user to provided url. */
  href?: string,
  /** Action name (title). */
  name: string,
  /** Callback to be fired when user selects this action. */
  onClick?: () => void,
}

/**
 * Same as [Action](#/Actions%20Dropdown?id=action) but for "danger" actions,
 * for example for deleting item. Shown with "danger" style.
 * @visibleName ActionsDropdown.DangerAction
 */
const ActionsDropdownDangerAction = ({
  icon,
  href,
  onClick,
  name,
  ...props
}: IActionsDropdownDangerActionProps): JSX.Element => {
  const [classes] = uiStyleProps(
    'display-flex-important align-items-center destroy-perma-btn danger-link',
    props,
  );

  return (
    <Action
      className={ classes }
      href={ href }
      icon={
        icon && (
          <div className="display-flex-important align-items-center width-20px m-r-18">
            {icon}
          </div>
        )
      }
      name={ name }
      onClick={ onClick }
    />
  );
};

export default ActionsDropdownDangerAction;
