import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';

interface IDocytAchNotActiveModalProps extends IUseModalProps {
  businessId: TID,
}

const DocytAchNotActiveModal = ({
  businessId,
  isOpen,
  onCancel,
}: IDocytAchNotActiveModalProps) => {
  const handleProceed = useCallback(() => {
    Backbone.history.navigate(`/businesses/${businessId}/accounts_payable/settings/ach_or_check`, { trigger: true });
  }, [businessId]);

  return (
    <Modal.Standard
      proceedTitle="Active"
      show={ isOpen }
      title="Docyt ACH not Active"
      onCancel={ onCancel }
      onProceed={ handleProceed }
    >
      {() => (
        <div className="text-center">
          { window.Docyt.Common.Constants.Messages.BUSINESS_FUNDING_SOURCE_NOT_FOUND }
        </div>
      )}
    </Modal.Standard>
  );
};

const useDocytAchNotActiveModal = makeUseModal(DocytAchNotActiveModal);

export {
  IDocytAchNotActiveModalProps,
  useDocytAchNotActiveModal,
  DocytAchNotActiveModal as default,
};
