import React, { useCallback } from 'react';

import { useBulkResolveDuplicates } from '@src/hooks/queries/transaction_service_documents';
import { IResolveDuplicateParams } from '@src/requests/transaction_service_documents';

import { useResolveDuplicatesModal } from '@src/components/banking_accounts/transactions/resolve_duplicates';
import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';
import { DuplicatedTransactionTopIcon } from '@src/components/utils/img_icons';

import ResolveDuplicatesResult from './resolve_duplicates/resolve_duplicates_result';

interface ITransactionsDuplicatedInfoProps {
  duplicatedCount: number,
  businessId: number,
  onResolved: () => void
}

const resolveDuplicatesResult = (status: IBulkActionStatus<any, any>) => (
  <ResolveDuplicatesResult status={ status } />
);

const TransactionsDuplicatedInfo = ({
  duplicatedCount,
  businessId,
  onResolved,
}: ITransactionsDuplicatedInfoProps): JSX.Element | null => {
  const bulkResolveDuplicate = useBulkResolveDuplicates();

  const bulkAction = useBulkActionModal({
    mutation: bulkResolveDuplicate,
    onDone:   onResolved,
  });

  const { runMutation } = bulkAction;

  const handleConfirm = useCallback((resolveDuplicateparams: IResolveDuplicateParams[]) => {
    runMutation(resolveDuplicateparams);
  }, [runMutation]);

  const resolveDuplicate = useResolveDuplicatesModal();

  return (
    <>
      <resolveDuplicate.Component
        { ...resolveDuplicate.props }
        businessId={ businessId }
        onDone={ handleConfirm }
      />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="transactions"
        result={ resolveDuplicatesResult }
        title="Resolving Duplicate Transactions"
      />
      <div className="banking-accounts-duplicated-info">
        <div className="display-flex align-items-center">
          <DuplicatedTransactionTopIcon fontSize={ 18 } pr={ 10 } />
          <span>
            { duplicatedCount }
            { ' ' }
            { duplicatedCount === 1 ? 'transaction has' : 'transactions have'}
            { ' ' }
            been flagged as potential duplicates. Review them to resolve the duplicates.
          </span>
        </div>
        <a
          className="font-semi-bold"
          role="button"
          tabIndex={ -1 }
          onClick={ resolveDuplicate.open }
        >
          Review Duplicates
        </a>
      </div>
    </>
  );
};

export default React.memo(TransactionsDuplicatedInfo);
