import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { useInvoicesCollection } from './hooks';
import Table from './table';
import { TAccountsReceivableInvoicesTab } from './types';

// import styles from './styles.module.scss';

interface IAccountsReceivableInvoicesProps {
  tab?: TAccountsReceivableInvoicesTab,
}

const AccountsReceivableInvoices = ({
  tab,
}: IAccountsReceivableInvoicesProps) => {
  const business = useBusinessContext();

  const collection = useInvoicesCollection({
    businessId: business.id,
    type:       tab || 'completed',
  });

  return (
    <DetailsRegion>
      <DetailsRegion.Header title="Invoices" />
      <Section.Provider section={ collection.section }>
        <TableSection>
          <Table
            infiniteQuery={ collection.query }
            records={ collection.records }
            sorting={ collection.sorting }
          />
        </TableSection>
      </Section.Provider>
    </DetailsRegion>
  );
};

export default React.memo(AccountsReceivableInvoices);
