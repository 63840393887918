import React, { useMemo } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  useGetSimilarTransactionServiceDocuments,
  useRemoveNoAutoVerifyRules,
} from '@src/hooks/queries/transaction_service_documents';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface ISimilarTransactionsModal extends IUseModalProps {
  id: TID;
  businessId: TID;
}

const SimilarTransactionsModal = ({
  id,
  businessId,
  isOpen,
  onCancel,
}: ISimilarTransactionsModal) => {
  const verifyTransaction = () => window.Docyt.vent.trigger('transaction:verifyTransaction');

  const query = useGetSimilarTransactionServiceDocuments({
    businessId,
    state:        'unverified',
    similarIds:   [id],
    noAutoVerify: true,
  }, {
    staleTime: 0,
    cacheTime: 0,
    onSuccess: (data) => {
      const transactions = data?.transactionServiceDocuments || [];
      if (transactions.length === 0) {
        verifyTransaction();
      }
    },
  });

  const { mutateAsync } = useRemoveNoAutoVerifyRules();

  const transactions = useMemo(() => {
    return query.data?.transactionServiceDocuments || [];
  }, [query.data]);

  const handleCancel = () => {
    onCancel();
    verifyTransaction();
  };

  const handleConfirm = async () => {
    try {
      const { message } = await mutateAsync({ businessId, similarIds: [id] });
      handleCancel();
      toastr.success(message, 'Success');
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      const errorMessages = Object.values(errors);
      const errorMessageString = errorMessages.join(' ');
      toastr.error(errorMessageString, 'Error');
    }
  };

  const linkToSetting = () => {
    window.open(`/businesses/${businessId}/reconciliation_center/settings`);
  };

  if (transactions.length === 0) return null;

  const count = transactions.length;

  return (
    <Modal
      show={ isOpen }
      title="Auto-Categorize Similar Transactions"
    >
      <Modal.Body>
        <div className={ styles['ai-settings-panel'] }>
          <div>
            There are
            {' '}
            {count}
            {' '}
            similar transactions which couldn’t be auto-categorize as per the
            <button type="button" onClick={ linkToSetting }>Docyt AI Settings.</button>
          </div>
          Would you like Docyt AI to auto-categorize these
          {' '}
          {count}
          {' '}
          transactions in the same way?
        </div>

        <div className={ styles['confirm-panel'] }>
          Confirming this will remove the Docyt AI condition ‘Do not auto-categorize’ for all upcoming similar transactions.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={ styles.footer }>
          <Button variant="link" onClick={ handleCancel }>
            No
          </Button>
          <Button variant="primary" onClick={ handleConfirm }>
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const useSimilarTransactionsModal = makeUseModal(SimilarTransactionsModal);

export default useSimilarTransactionsModal;
