module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, payment_account, ref;
    
      __out.push('<div class="modal fade invoice-action-modal">\n  <div class="modal-dialog modal-lg select-payment-accounts-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="font-24 font-bold m-l-auto m-r-auto m-t-27">Select Payment Accounts</h1>\n      </div>\n      <div class="modal-body payment-account-modal-body">\n        <p class="font-16 m-l-auto m-r-auto">Docyt will send daily request receipt reminder to users who have access to the payment accounts selected below.</p>\n        ');
    
      ref = this.payment_accounts;
      for (i = 0, len = ref.length; i < len; i++) {
        payment_account = ref[i];
        __out.push('\n          <div class="display-flex align-items-center">\n            <div class="checkbox pointer checkbox-primary">\n              <input id="chk_pa_');
        __out.push(__sanitize(payment_account.id));
        __out.push('" data-id="');
        __out.push(__sanitize(payment_account.id));
        __out.push('" class="check-js pointer" type="checkbox" ');
        __out.push(__sanitize(this.selected_ids[payment_account.id.toString()] ? 'checked' : ''));
        __out.push('>\n              <label class="pointer"></label>\n            </div>\n            <label class="col-md-7 font-16 m-b-0 pointer" for="chk_pa_');
        __out.push(__sanitize(payment_account.id));
        __out.push('">');
        __out.push(__sanitize(payment_account.name));
        __out.push('</label>\n            ');
        if (payment_account.last4) {
          __out.push('\n              <label class="col-md-5 font-16 m-b-0 pointer" for="chk_pa_');
          __out.push(__sanitize(payment_account.id));
          __out.push('">XXXX XXXX XXXX ');
          __out.push(__sanitize(payment_account.last4));
          __out.push('</label>\n            ');
        }
        __out.push('\n          </div>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class=\'modal-footer fixed-modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button pull-right add-accounts-js width-180px" tabindex="10" data-color="blue" data-style="expand-right">Add Accounts</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}