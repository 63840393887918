import React, { useMemo } from 'react';

import sumBy from 'lodash/sumBy';
import pluralize from 'pluralize';

import { currencyLocaleFormatter } from '@src/utils/currency';

import { TModel } from '@src/components/ui_v2/collection_table/types';

import styles from './styles.module.scss';

interface IMatchDocumentsSelectedAmountProps<Model extends TModel> {
  amountField?: keyof Model,
  selectedRecords: Model[],
}

const MatchDocumentsSelectedAmount = <Model extends TModel>({
  amountField = 'amount',
  selectedRecords,
}: IMatchDocumentsSelectedAmountProps<Model>) => {
  const amount = useMemo(() => {
    return sumBy(selectedRecords, (r) => parseFloat(r[amountField]));
  }, [selectedRecords, amountField]);

  return (
    <span className={ styles['selected-amount'] }>
      Selected Amount:
      { ' ' }
      <span className={ styles['selected-amount-value'] }>
        { currencyLocaleFormatter(amount) }
      </span>
      { ' ' }
      (
      { selectedRecords.length }
      { ' ' }
      { pluralize('Item', selectedRecords.length) }
      )
    </span>
  );
};

export default MatchDocumentsSelectedAmount;
