import { TSelectItemColumn, TModel } from './types';

const CollectionTableSelectItemColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TSelectItemColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableSelectItemColumn;
