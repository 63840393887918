module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="profile-title-view p-l-10 p-r-10">\n  <label class="font-18 font-bold m-r-auto">Docyt Business Network Profile</label>\n  <span class="pointer icon-not-a-chargeback-icon vendor-profile-close-js font-25"></span>\n</div>\n<div class="p-t-30 p-b-30 p-l-20 p-r-20 text-center">\n  ');
    
      if (this.image_url !== null) {
        __out.push('\n  <img class="m-auto width-80px-important height-80px-important" src="');
        __out.push(__sanitize(this.image_url));
        __out.push('" style="border-radius: 50px;" ><br>\n  ');
      } else {
        __out.push('\n  <img class="m-auto width-80px-important height-80px-important" src="');
        __out.push(__sanitize(configData.vendor_images['VENDOR_SERVICE']));
        __out.push('" style="border-radius: 50px;" ><br>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.ach_status === 'activated') {
        __out.push('\n  <label class="font-bold in-blue-400 p-l-10 p-r-10 m-t-5" style="background: #eaf5fc; border-radius: 4px;">ACH Active</label>\n  ');
      }
    
      __out.push('\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Display Name</label>\n  <label>');
    
      __out.push(__sanitize(this.name !== null && this.name !== '' ? this.name : '-'));
    
      __out.push('</label>\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Docyt ID</label>\n  <label>');
    
      __out.push(__sanitize(this.docyt_id !== null ? this.docyt_id : '-'));
    
      __out.push('</label>\n</div>\n<div class="profile-partition-title p-l-10 p-r-10 font-bold">Payee Payment Address Information</div>\n<div class="p-l-10 p-r-10 p-t-20 p-b-10">\n  <label class="profile-attr-title">Check Payable To</label>\n  ');
    
      if (this.addresses && this.addresses[0] && this.addresses[0].name !== '') {
        __out.push('\n  <label>');
        __out.push(__sanitize(this.addresses[0].name));
        __out.push('</label>\n  ');
      } else {
        __out.push('\n  <label>-</label>\n  ');
      }
    
      __out.push('\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Department</label>\n  ');
    
      if (this.addresses && this.addresses[0] && this.addresses[0].company !== '') {
        __out.push('\n  <label>');
        __out.push(__sanitize(this.addresses[0].company));
        __out.push('</label>\n  ');
      } else {
        __out.push('\n  <label>-</label>\n  ');
      }
    
      __out.push('\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title" style="vertical-align: top;">Address</label>\n  ');
    
      if (this.addresses && this.addresses[0]) {
        __out.push('\n  <label style="width: 200px;">');
        __out.push(__sanitize(this.addresses[0].address_line1));
        __out.push(', <br>');
        __out.push(__sanitize(this.addresses[0].address_city));
        __out.push(',  ');
        __out.push(__sanitize(this.addresses[0].address_state));
        __out.push(', ');
        __out.push(__sanitize(this.addresses[0].address_zip));
        __out.push(', <br>');
        __out.push(__sanitize(this.addresses[0].address_country));
        __out.push('</label>\n  ');
      } else {
        __out.push('\n  <label>-</label>\n  ');
      }
    
      __out.push('\n</div>\n<div class="profile-partition-title p-l-10 p-r-10 font-bold">Payee Contact Information</div>\n<div class="p-l-10 p-r-10 p-t-20 p-b-10">\n  <label class="profile-attr-title">Website</label>\n  <label>');
    
      __out.push(__sanitize(this.website !== null && this.website !== '' ? this.website : '-'));
    
      __out.push('</label>\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Email</label>\n  <label>');
    
      __out.push(__sanitize(this.email !== null && this.email !== '' ? this.email : '-'));
    
      __out.push('</label>\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Phone</label>\n  <label>');
    
      __out.push(__sanitize(this.phone !== null && this.phone !== '' ? this.phone : '-'));
    
      __out.push('</label>\n</div>\n<div class="p-l-10 p-r-10 p-t-10 p-b-10">\n  <label class="profile-attr-title">Fax</label>\n  <label>');
    
      __out.push(__sanitize(this.fax !== null && this.fax !== '' ? this.fax : '-'));
    
      __out.push('</label>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}