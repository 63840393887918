import { object, string } from 'yup';

export interface ICreateNewReportFormValues {
  name: string,
  templateId: string,
  reportServiceIds: number[],
}

export interface IRenameReportFormValues {
  name: string,
}

export const createReportValidation = object({
  name:       string().trim().required('Please input name of report'),
  templateId: string().nullable().required('Please select type of report'),
});

export const renameReportValidation = object({
  name: string().trim().required('Please input name of report'),
});

export interface IUpdateBusinessFormValues {
  id: string,
  reportServiceIds: number[],
}
