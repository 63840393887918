import React, { useEffect, useState } from 'react';

import { getManagementGroupDashboard } from '@src/requests/dashboards/management_group_dashboards';
import { portfolioDashboardPath } from '@src/routes';
import { TMongoID } from '@src/types/common';
import { IManagementGroupDashboard } from '@src/types/dashboards/dashboards';

import { ErrorNotification } from '@src/components/ui/notification';

import { DashboardDetails } from './details';
import { DashboardSideMenu } from './side_menu';

import styles from './styles.module.scss';

interface IDashboardMainPageProps {
  dashboardId: TMongoID,
  widgetId?: string,
}

const DashboardMainPage = ({
  dashboardId,
  widgetId,
}: IDashboardMainPageProps): JSX.Element => {
  const [managementGroupDashboard, setManagementGroupDashboard] =
    useState<IManagementGroupDashboard | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    getManagementGroupDashboard({
      id: dashboardId,
    }).then((res) => {
      setManagementGroupDashboard(res.dashboard);
    }).catch((error) => {
      if (error?.response?.status === 403) {
        localStorage.removeItem('management-group-dashboard-id');
        Backbone.history.navigate(portfolioDashboardPath(), { trigger: true });
        return;
      }
      setErrorMsg(error.message);
    });
  }, [dashboardId]);

  return (
    <div className={ styles['main-container'] }>
      {
        managementGroupDashboard && managementGroupDashboard.businesses
          && (
            <>
              { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
              <DashboardSideMenu
                dashboard={ managementGroupDashboard }
                widgetId={ widgetId }
              />
              <DashboardDetails
                businesses={ managementGroupDashboard.businesses }
                dashboard={ managementGroupDashboard }
                widgetId={ widgetId }
              />
            </>
          )
      }
    </div>
  );
};

export default DashboardMainPage;
