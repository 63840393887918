import React, { ComponentPropsWithoutRef } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';

import Body from '../body';
import Root, { IModalProps } from '../root';
import FormFooter from './form_footer';

interface IFormModalChildrenParams {
  formId: string,
}

interface IFormModalProps extends Omit<ComponentPropsWithoutRef<typeof FormFooter>, 'formId'> {
  /** Content of modal dialog. Should include a form with DOM id provided as parameter */
  children: (params: IFormModalChildrenParams) => React.ReactNode,
  className?: IModalProps['className'],
  dialogClassName?: IModalProps['dialogClassName'],
  /** form DOM id to use. If not set, then uniq generated id will be used */
  formId?: string;
  /** Show modal. Usually just pass `isOpen` prop provided by `makeUseModal` wrapper. */
  show?: IModalProps['show'],
  /** Show close button at modal header */
  showClose?: IModalProps['showClose'],
  /** Modal dialog title */
  title: IModalProps['title'],
  isProceedDisabled?: boolean,
}

/**
 * Modal dialog that contain a form that is submitted by clicking on dialog's "Proceed" button.
 *
 * If modal doesn't have form to submit, then use [Modal.Standard](#/Modal/StandardModal)
 * instead.
 * @visibleName Modal.Form
 */
const FormModal = ({
  children,
  className,
  dialogClassName,
  formId,
  show,
  showClose,
  isProceedDisabled,
  title,
  ...footerProps
}: IFormModalProps) => {
  const uniqueFormId = useUniqueDomId('modal_form_');

  return (
    <Root
      className={ className }
      dialogClassName={ dialogClassName }
      show={ show }
      showClose={ showClose }
      title={ title }
      { ...footerProps }
    >
      <Body>
        { show && children({ formId: formId ?? uniqueFormId }) }
      </Body>
      <FormFooter
        formId={ formId ?? uniqueFormId }
        isProceedDisabled={ isProceedDisabled }
        { ...footerProps }
      />
    </Root>
  );
};

export default FormModal;
