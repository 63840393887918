import React from 'react';

import { ITransactionServiceDocument, TActionType } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

interface IDeleteRestoreFormProps {
  transaction: ITransactionServiceDocument,
  actionType: TActionType,
}

const DeleteRestoreForm = ({
  transaction,
  actionType,
}: IDeleteRestoreFormProps): JSX.Element => {
  let endActionMessage: string;

  switch (actionType) {
    case 'moveToTrash':
      endActionMessage = 'will be deleted and moved to trash.';
      break;
    case 'delete':
      endActionMessage = 'will be deleted permanently from Docyt.';
      break;
    case 'restore':
      endActionMessage = 'will be restored and moved to transactions in Banking Accounts.';
      break;

    // no default
  }

  return (
    <div style={ { margin: '0 -20px' } }>
      <span className="font-bold">
        Following transaction
        {' '}
        { endActionMessage }
      </span>
      <div className="deleted_transactions">
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.HCell widthPercent={ 25 }>Date</Table.HCell>
              <Table.HCell widthPercent={ 40 }>Description</Table.HCell>
              <Table.HCell widthPercent={ 20 }>Account</Table.HCell>
              <Table.HCell widthPercent={ 15 }>Amount</Table.HCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.DateCell date={ transaction.transactionDate } />
              <Table.Cell>
                { transaction.description }
              </Table.Cell>
              <Table.Cell>
                { transaction.paymentAccountName }
              </Table.Cell>
              <Table.AmountCell amount={ transaction.amount } />
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <span className="font-bold">Are you sure you want to continue?</span>
    </div>
  );
};

export default DeleteRestoreForm;
