module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (this.is_bank_statement_request || this.is_balance_sheet_statement_request) {
        __out.push('\n  <td>\n    <span class="font-semi-bold">');
        __out.push(__sanitize(this.docyt_id));
        __out.push('</span>\n  </td>\n  <td>\n    ');
        __out.push(__sanitize(this.name));
        __out.push('\n  </td>\n');
      } else {
        __out.push('\n  <td>\n    <a href="/document/');
        __out.push(__sanitize(this.id));
        __out.push('" class="font-semi-bold">');
        __out.push(__sanitize(this.docyt_id));
        __out.push('</a>\n  </td>\n  <td>\n    ');
        __out.push(__sanitize(this.document_name || this.original_file_name || this.original_file_key || this.computed_final_filename));
        __out.push('\n  </td>\n  <td>\n    ');
        if (this.is_opened) {
          __out.push('\n    <a class="pointer remove-document-js"><span class="icon-trashcan in-grey-1050 font-16"></span></a>\n    ');
        }
        __out.push('\n  </td>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}