module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-lg business-picker-modal-dialog\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title pull-left m-l-10 font-bold">Select Business:</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <div class="flex-wrapper">\n                <label class="font-15 font-bold p-t-5 m-r-15">Business:</label>\n                <select class="form-control business-select width-50-percent-fixed">\n                ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n                    <option value="');
        __out.push(__sanitize(business.id));
        __out.push('">');
        __out.push(__sanitize(business.legal_name));
        __out.push('</option>\n                ');
      }
    
      __out.push('\n                </select>\n            </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}