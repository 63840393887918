import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getReportServiceBudgetFormulas, IGetReportServiceBudgetFormulasParams,
} from '@src/requests/report_service/report_service_budget_formulas';
import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';

const useGetReportServiceBudgetFormulas = (params: IGetReportServiceBudgetFormulasParams) => {
  return useQuery<IReportServiceBudgetFormula[], Error>(
    [QueryKey.reportServiceBudgetFormulas, params],
    () => getReportServiceBudgetFormulas(params),
  );
};

export {
  useGetReportServiceBudgetFormulas,
};
