import React from 'react';

import { IRevenueSystem } from '@src/types/revenue_systems';

import Panel from '@src/components/ui_v2/panel';
import { DepositTrackingFtuLogo } from '@src/components/utils/img_icons';

import Activate from './activate';

interface IDepositTrackingLandingProps {
  revenueSystem: IRevenueSystem,
}

const DepositTrackingLanding = ({
  revenueSystem,
}: IDepositTrackingLandingProps) => {
  return (
    <Panel.Landing
      image={ <DepositTrackingFtuLogo /> }
      text={
        'Reconcile earned revenue with daily deposits.'
        + ' Avoid revenue loss due to merchant processor mistakes.'
        + ' Simply check the merchant processor’s daily deposit report'
        + ' against your bank deposits to ensure the amounts match.'
      }
    >
      <Activate revenueSystem={ revenueSystem } />
    </Panel.Landing>
  );
};

export default React.memo(DepositTrackingLanding);
