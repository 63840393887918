import React from 'react';

import BlankImg from '@assets/images/multi_entity/group-5@2x.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const MultiEntityEmptyIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Multi entity portfolio empty icon"
      src={ BlankImg }
      { ...props }
    />
  );
};

export default MultiEntityEmptyIcon;
