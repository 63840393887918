import React from 'react';

import { TFilterData } from '@src/types/filter';

import { Button } from '@src/components/ui/buttons';

import Wrapper from './filter_field_wrapper';
import { useFilterContext } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

import styles from './styles.module.scss';

interface IResetFilterButtonProps extends
  React.ComponentPropsWithoutRef<typeof Button>,
  IFilterFieldUIProps
{
  showFilteredCount?: boolean,
}

const ResetFilterButton = <TFilter extends TFilterData>({
  title = 'Reset',
  showFilteredCount,
  ...props
}: IResetFilterButtonProps) => {
  const { filteredCount, clear } = useFilterContext<TFilter>();

  const [wrapperProps, buttonProps] = useFilterFieldProps(props, 'reset');

  return (
    <Wrapper { ...wrapperProps }>
      <Button
        bsStyle="link"
        type="button"
        { ...buttonProps }
        onClick={ clear }
      >
        { title }
      </Button>
      { showFilteredCount && (filteredCount > 0) && (<span className={ styles['filtered-count-panel'] }>{filteredCount}</span>) }
    </Wrapper>
  );
};

export default ResetFilterButton;
