import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown/dropdown';
import { useFilterContext, FilterFieldWrapper } from '@src/components/ui/filter';
import { CaretIcon } from '@src/components/utils/fa_icons';

import { IMyPracticeFilter } from './types';

interface IMyPracticeFilterPaymentAccountSelectProps {
  className?: string,
  widthPercent?: IUIStyleProps['widthPercent'],
}

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const StatusOptions = [
  { value: '', name: 'All Clients', childOptions: [] },
  { value: 'reviewed', name: 'Clients with reviewed items', childOptions: [] },
  { value: 'onboarding', name: 'Clients with onboarding status', childOptions: [] },
  { value: 'blocked', name: 'Blocked clients', childOptions: [] },
  { value: 'no_member_assigned', name: 'Clients with no team members assigned', childOptions: [] },
  { value:        'unreconciled',
    name:         'Clients with selected unreconciled month',
    childOptions: [
      { value: 'unreconciled', name: 'All months', month: undefined },
      { value: 'unreconciled', name: 'January', month: '1' },
      { value: 'unreconciled', name: 'February', month: '2' },
      { value: 'unreconciled', name: 'March', month: '3' },
      { value: 'unreconciled', name: 'April', month: '4' },
      { value: 'unreconciled', name: 'May', month: '5' },
      { value: 'unreconciled', name: 'June', month: '6' },
      { value: 'unreconciled', name: 'July', month: '7' },
      { value: 'unreconciled', name: 'August', month: '8' },
      { value: 'unreconciled', name: 'September', month: '9' },
      { value: 'unreconciled', name: 'October', month: '10' },
      { value: 'unreconciled', name: 'November', month: '11' },
      { value: 'unreconciled', name: 'December', month: '12' }] },
];

const MyPracticeFilterStatusSelect = ({
  className,
  widthPercent,
}: IMyPracticeFilterPaymentAccountSelectProps) => {
  const { data, update } = useFilterContext<IMyPracticeFilter>();

  const handleSelectStatus = useCallback((status: string | undefined, month?: string) => {
    update({
      status,
      unreconciledMonth: month,
    });
  }, [update]);

  let selectedName: string | undefined = 'All Clients';
  if (data?.status) {
    StatusOptions?.forEach((option) => {
      if (option.value === data.status) {
        if (option.value === 'unreconciled') {
          if (data?.unreconciledMonth) {
            selectedName = monthNames[Number(data.unreconciledMonth) - 1];
          } else {
            selectedName = 'All months';
          }
        } else {
          selectedName = option.name;
        }
      }
    });
  }

  const wrapperClassNames = classNames('height-100-percent', className);
  const classes = classNames(
    'display-flex relative transaction-filter-banking-account',
    { filtered: data?.status },
  );

  return (
    <FilterFieldWrapper className={ wrapperClassNames } widthPercent={ widthPercent }>
      <Dropdown
        className={ classes }
      >
        <div className="form-control nowrap flex-container align-items-center">
          { selectedName }
        </div>
        <Dropdown.LinkToggle
          noButton
          pointer
          className="date-select-toggle range-select-menu-btn"
          mb={ 6 }
        >
          <CaretIcon fontSize={ 14 } variant="down" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu pointer alignment="left" className="col-xs-12 wide" fontSize={ 24 }>
          {
            StatusOptions.map((option) => {
              if (option.childOptions.length === 0) {
                return (
                  <Dropdown.Item
                    key={ option.value }
                    className="nowrap"
                    onSelect={ () => handleSelectStatus(option.value) }
                  >
                    { option.name }
                  </Dropdown.Item>
                );
              }

              return (
                <Dropdown.Submenu
                  key={ option.value }
                  title={ option.name }
                >
                  <Dropdown.Menu>
                    {
                      option.childOptions.map((childOption) => (
                        <Dropdown.Item
                          key={ childOption.value + childOption.month }
                          className="nowrap"
                          onSelect={
                            () => handleSelectStatus(childOption.value, childOption.month)
                          }
                        >
                          { childOption.name }
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown.Submenu>
              );
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    </FilterFieldWrapper>
  );
};

export default React.memo(MyPracticeFilterStatusSelect);
