import React, { FC, useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAdjustmentEntriesResponse } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry, TIAdjustmentEntrySortColumn } from '@src/types/adjustment_entries';
import { IBankAccountReconciliation } from '@src/types/bank_account_reconciliations';
import { ISorting } from '@src/types/sorting';

import NameColumn from '@src/components/reconciliation_center/journal_entry/name_column';
import { buildItemTypesFromArray } from '@src/components/reconciliation_center/month_end/utils';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { EditIcon } from '@src/components/utils/icomoon';

import DeleteIcon from './delete_icon';
import Filter from './filter';

interface TableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAdjustmentEntriesResponse, Error>,
  records: IAdjustmentEntry[],
  sorting: ISorting<TIAdjustmentEntrySortColumn>,
  reconciliation?: IBankAccountReconciliation
}

const trashIcon = (adjustmentEntry: IAdjustmentEntry) => {
  if (adjustmentEntry.source?.type === 'Normal') {
    return <DeleteIcon adjustmentEntry={ adjustmentEntry } />;
  }
  return null;
};

const editIcon = (adjustmentEntry: IAdjustmentEntry) => {
  return <EditIcon fontSize={ 16 } id={ adjustmentEntry.id.toString() } />;
};

const formatCategories = (categories: string[]) => {
  if (categories.length === 0) {
    return '';
  }

  if (categories.length === 1) {
    return categories[0];
  }

  return `${categories[0]} + ${categories.length - 1} more`;
};

const renderNameColumn = (document: IAdjustmentEntry) => <NameColumn document={ document } />;

const Table: FC<TableProps> =
  ({ records, sorting, infiniteQuery, reconciliation }) => {
    const items = useMemo(
      () => buildItemTypesFromArray(reconciliation?.bankAccountReconciliationItems || []),
      [reconciliation?.bankAccountReconciliationItems],
    );

    const difference = items.difference;
    const acknowledged = !difference || difference.status === 'acknowledged';

    return (
      <CollectionTable<IAdjustmentEntry, TIAdjustmentEntrySortColumn>
        isRegionScroll
        filter={ <Filter /> }
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="docyt_id"
          sortColumn="docyt_id"
          title="Journal Entry ID"
          value={ renderNameColumn }
        />

        <CollectionTable.DateColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="entry_date"
          sortColumn="entry_date"
          title="Date"
          value={ (document) => document.entryDate }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="category"
          title="Category"
          tooltip={ (it) => it.categories?.join(', ') }
          value={ (document) => formatCategories(document.categories || []) }
        />

        <CollectionTable.AmountColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="debits"
          sortColumn="debits"
          title="Debits"
          value={ (document) => document.debits }
        />

        <CollectionTable.AmountColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="credits"
          sortColumn="credits"
          title="Credits"
          value={ (document) => document.credits }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="edit"
          title="Edit"
          value={ (adjustmentEntry) => (acknowledged ? '' : editIcon(adjustmentEntry)) }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="delete"
          title="Delete"
          value={ (adjustmentEntry) => trashIcon(adjustmentEntry) }
        />
      </CollectionTable>
    );
  };

export default Table;
