import React, { useCallback, useMemo } from 'react';

import { useAccountAllBigTypes } from '@src/hooks/queries/account_types';
import { IAccountBigType } from '@src/types/account_types';

import ReactSelectInput from '@src/components/ui/form/inputs/react_select/react_select_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';

interface IAccountBigTypeFieldProps
{
  value?: IAccountBigType['code'],
  onChange: (value: IAccountBigType | null) => void,
  error?: string
}

const getOptionLabel = (type: IAccountBigType) => type.name;
const getOptionValue = (type: IAccountBigType) => type.code;

const AccountType = (props: IAccountBigTypeFieldProps): JSX.Element => {
  const { value, onChange, error } = props;

  const query = useAccountAllBigTypes();
  const accountTypes = useMemo(() => {
    return query.data?.accountTypes ?? [];
  }, [query.data]);

  const selectedAccountType = useMemo(() => {
    if (!value) return null;

    return accountTypes.find((at) => at.code === value);
  }, [value, accountTypes]);

  const handleChange = useCallback((accountType: IAccountBigType | null) => {
    onChange(accountType);
  }, [onChange]);

  return (
    <Wrapper error={ error } label="Account Type">
      <ReactSelectInput<IAccountBigType, false>
        isSearchable
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        options={ accountTypes }
        placeholder="Select Account Type"
        value={ selectedAccountType }
        widthPercent={ 100 }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default AccountType;
