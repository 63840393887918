import React, { useState, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useRemoveAddressBook } from '@src/hooks/queries/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import styles from './styles.module.scss';

interface IDeleteAddressBookProps extends IUseModalProps {
  confirmTitle?: JSX.Element | string,
  text: JSX.Element | string,
  title: JSX.Element | string,
  vendor: IBusinessVendor;
  address: IAddress
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
}

const DeleteConfirm = ({
  confirmTitle = 'Delete',
  vendor,
  address,
  isOpen,
  text,
  title,
  onDone,
  onCancel,
  setVendorData,
}: IDeleteAddressBookProps): JSX.Element => {
  const queryClient = useQueryClient();
  const removeAddressBook = useRemoveAddressBook();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const { mutate: removeAddress } = removeAddressBook;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
    onDone();
  }, [onDone]);

  const handleRemoveAddressBook = useCallback(() => {
    setErrorMessage(undefined);
    removeAddress(
      {
        addressId:  address.id,
        vendorId:   vendor.id,
        businessId: vendor.businessId,
      },
      {
        onSuccess: (response) => {
          queryClient.invalidateQueries(QueryKey.businessVendors);

          const newAddress = response.address;
          const updatedVendor = {
            ...vendor,
            addresses: [...(vendor.addresses || []), newAddress],
          };
          setVendorData(updatedVendor);
          setSuccessMessage('Address has been successfully deleted.');
          onDone();
        },
        onError: handleError,
      },
    );
  }, [removeAddress, address.id, vendor, handleError, queryClient, setVendorData, onDone]);

  return (
    <>
      {(removeAddressBook.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Something went wrong" onHidden={ () => setErrorMessage(undefined) } />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      )}

      <Modal className={ styles['modal-delete'] } show={ isOpen } title={ title }>
        <Modal.Body>
          <p className="p-b-0 text-center font-18">{text}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            className="btn-destructive"
            variant="destructive"
            onClick={ handleRemoveAddressBook }
          >
            { confirmTitle }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useDeleteAddressBookModal = makeUseModal(DeleteConfirm);
export {
  IDeleteAddressBookProps,
  useDeleteAddressBookModal,
  DeleteConfirm as default,
};
