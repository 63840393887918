import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const UpgradeIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="upgrade-icon"
      pathsNumber={ 10 }
    />
  );
};

export default UpgradeIcon;
