module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade invoice-action-modal">\n  <div class="modal-dialog modal-lg modal-xlg invoice-action-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header">\n        <div class="invoice-special-header">\n          <h1 class="font-24 font-bold m-l-auto m-r-auto m-t-27">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n        </div>\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n      </div>\n      <div class="modal-body bg-grey-150 scrollable-invoice-modal-body">\n        <p class="font-16 m-l-auto m-r-auto p-t-30 text-center width-700px">');
    
      __out.push(__sanitize(this.text));
    
      __out.push('</p>\n        <div class="merge-documents-region p-l-100 p-r-100">\n        </div>\n      </div>\n      <div class=\'modal-footer fixed-modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button pull-right merge-js width-180px" tabindex="10" data-color="blue" data-style="expand-right">Merge</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}