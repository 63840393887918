import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TAmount } from '@src/types/common';

import Modal from '@src/components/ui/modal';

import Form from './form';
import { IAddAdjustmentValues } from './schema';

interface IMatchDocumentAddAdjustmentModalProps extends Omit<IUseModalProps, 'onDone'> {
  adjustmentAmount: TAmount,
  onDone: (data: IAddAdjustmentValues) => void,
}

const MatchDocumentAddAdjustmentModal = ({
  adjustmentAmount,
  isOpen,
  onDone,
  ...props
}: IMatchDocumentAddAdjustmentModalProps) => {
  const handleSubmit = useCallback((data: IAddAdjustmentValues) => {
    onDone(data);
  }, [onDone]);

  return (
    <Modal.Form
      proceedTitle="Add"
      show={ isOpen }
      title="Add Adjustment Journal Entry"
      { ...props }
    >
      { ({ formId }) => (
        <Form
          adjustmentAmount={ adjustmentAmount }
          formId={ formId }
          onSubmit={ handleSubmit }
        />
      ) }
    </Modal.Form>
  );
};

const useAddAdjustmentModal = makeUseModal(MatchDocumentAddAdjustmentModal);

export {
  useAddAdjustmentModal,
  MatchDocumentAddAdjustmentModal as default,
};
