module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'accounts-receivable-container font-14\'>\n  <div class="font-14 font-bold verification-result-wrapper">\n    <span class="icon icon-check_circle_large"></span>\n    <span class=\'verification-result-text m-l-10\' style="flex: 1">We have successfully updated your new contact information.</span>\n    <a class="cancel pointer pull-right close-icon-btn"><span class="icon icon-not-a-chargeback-icon"></span></a>\n  </div>\n  <div class="display-flex align-items-center border-bottom-gray p-b-15">\n    <div class="employee-avatar-with-border">\n      <div class="avatar">\n      ');
    
      if (this.business.get('avatar_url')) {
        __out.push('\n        <img src="');
        __out.push(__sanitize(this.business.get('avatar_url')));
        __out.push('" class=\'clients__userpic-img\'>\n      ');
      } else {
        __out.push('\n        <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
      }
    
      __out.push('\n      </div>\n    </div>\n    <div class=\'m-l-12 display-flex-column\'>\n      <span class="font-26 font-bold">');
    
      __out.push(__sanitize(this.business.get('display_name')));
    
      __out.push('</span>\n      ');
    
      if (this.ar_service.get('docyt_network_id')) {
        __out.push('\n      <span>@');
        __out.push(__sanitize(this.ar_service.get('docyt_network_id')));
        __out.push('</span>\n      ');
      }
    
      __out.push('\n    </div>\n    ');
    
      if (this.vendor_configured) {
        __out.push('\n    <div class="inline-block m-t-10 m-l-auto">\n      <a class="pointer relative display-inline-flex">\n        <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="bank-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n          <i class=\'icon icon-3-dots font-20\'></i>\n        </button>\n        <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="bank-action-dropdown-btn">\n          <div class="dropdown-item font-14 edit-vendor-js">\n            <i class="icon icon-edit"></i> <span>Edit My Vendor Profile</span>\n          </div>\n        </div>\n      </a>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (!this.vendor_configured) {
        __out.push('\n  <div class=\'ach-warning-view m-t-20\'>\n    <div class=\'align-items-center display-flex in-red m-b-12\'>\n      <i class="font-16 icon-unable-to-sync in-red"><span class="path1"></span><span class="path2"></span></i>\n      <span class="font-semi-bold m-l-10">Vendor Profile has not been Created</span>\n    </div>\n    <div class=\'m-b-12\'>\n      Please add the ACH Banking Information and the W-9 Information to complete the Vendor Profile.\n    </div>\n    <div class=\'text-right\'>\n      <a class="font-16 font-semi-bold pointer goto-profile-setup-js">Complete Profile Setup</a>\n    </div>\n  </div>\n  ');
      }
    
      __out.push('\n  <div class=\'font-bold font-20 m-t-20 p-b-10\'> Profile Info </div>\n  <div class=\'p-b-20\'> Keep your Vendor profile up to date so that other users can easily contact and pay you. </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Name</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Website</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.website));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Email</span>\n    </div>\n    <div>\n      <a href=\'mailto:');
    
      __out.push(__sanitize(this.email));
    
      __out.push('\'>');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</a>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Phone Number</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Fax</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.fax));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Check Payable to</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.address.get('name')));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent\'>\n      <span>Department</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.address.get('company')));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper p-b-20\'>\n    <div class=\'width-30-percent vertical-align-top\'>\n      <span>Address</span>\n    </div>\n    <div>\n      <span>');
    
      __out.push(__sanitize(this.address.addressInfo()));
    
      __out.push('</span>\n    </div>\n  </div>\n  <div class=\'flex-wrapper\'>\n    <div class=\'width-30-percent\'>\n      <span>ACH Status</span>\n    </div>\n    <div>\n      ');
    
      if (this.ach_configured) {
        __out.push('\n        <span class="invoice-status-panel font-bold approval"> ACH Active </span>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n  <div class=\'font-bold font-20 m-t-25\'> Addresses </div>\n  <div class="table-responsive addresses-table-wrapper address-list-view">\n    <div class="display-flex p-t-10 p-b-10">\n      <div class="width-30-percent m-l-20">\n        <div class="relative">\n          <input class="input-name-js form-control" type="text" placeholder="Name" autocomplete="off"/>\n        </div>\n      </div>\n      <div class="width-70-percent m-l-10 m-r-20">\n        <div class="relative">\n          <input class="input-address-js form-control" type="text" placeholder="Address" autocomplete="off"/>\n        </div>\n      </div>\n    </div>\n    <table class="table item-divider">\n      <thead>\n        <tr>\n          <th class="sortable sorted-desc font-13 font-bold" data-orderby="name" data-orderdir="ASC" width="30%">Name</th>\n          <th class="sortable font-13 font-bold" data-orderby="address_info" data-orderdir="ASC" width="70%">Address</th>\n          <th class="font-13 font-bold text-right"></th>\n        </tr>\n      </thead>\n      <tbody>\n      </tbody>\n    </table>\n    <span class="font-15 font-bold pointer in-blue-400 inline-block p-l-20 add-address-btn"><span class="font-bold icon-plus-circle m-r-18"></span>Add Mailing Address</span>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}