import React from 'react';

import classNames from 'classnames';

import { AppLink } from '@src/components/ui_v2/buttons';

import { IBreadcrumb } from './types';

import styles from './styles.module.scss';

interface DetailsPageBreadcrumbProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'href'> {
  breadcrumb: IBreadcrumb,
}

const DetailsPageBreadcrumb = ({
  breadcrumb,
  className,
  ...props
}: DetailsPageBreadcrumbProps): JSX.Element => {
  const classes = classNames(styles.breadcrumb, className);

  return (
    breadcrumb.href ? (
      <AppLink className={ classes } { ...props } href={ breadcrumb.href }>
        { breadcrumb.title }
      </AppLink>
    ) : (
      <span className={ classes } { ...props }>
        { breadcrumb.title }
      </span>
    )
  );
};

export default DetailsPageBreadcrumb;
