module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountingClass, i, len, ref, ref1, ref2,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<div class="modal fade z-max-index" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">Map Departments to Category</h4>\n      </div>\n      <div class="modal-body">\n        <div class="report-type-container display-flex-column m-t-30">\n          <label class="font-14 in-grey-600">Select Departments to map to category</label>\n          <div class="display-flex-column m-l-14">\n            ');
    
      ref = this.accountingClasses;
      for (i = 0, len = ref.length; i < len; i++) {
        accountingClass = ref[i];
        __out.push('\n              <div class="checkbox checkbox-primary">\n                <input type="checkbox" id=');
        __out.push(__sanitize(accountingClass.id));
        __out.push(' class="pointer" ');
        __out.push(__sanitize((ref1 = accountingClass.id, indexOf.call(this.idsWithAllMapped, ref1) >= 0) ? 'checked disabled' : (ref2 = accountingClass.id, indexOf.call(this.idsWithMapped, ref2) >= 0) ? 'checked' : ''));
        __out.push('>\n                <label for=');
        __out.push(__sanitize(accountingClass.id));
        __out.push('>');
        __out.push(__sanitize(accountingClass.name));
        __out.push('</label>\n              </div>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type="button" class="btn btn-blue proceed-btn">Map Departments</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}