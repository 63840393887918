module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref, ref1, ref2,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push(' transaction-select-checkbox-column">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" class="pointer check-transaction" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n    <label></label>\n  </div>\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  ');
    
      if (this.vendor && this.vendor.image_url) {
        __out.push('\n    <div class=\'small-avatar inline-block vertical-align-middle\'><img src=\'');
        __out.push(__sanitize(this.vendor.image_url));
        __out.push('\'></div>\n  ');
      } else {
        __out.push('\n    <i class="icon icon-smile in-gray-important font-25 vertical-align-middle"></i>\n  ');
      }
    
      __out.push('\n</td>\n<td class="p-l-0 document-clickable pointer item-');
    
      __out.push(__sanitize(this.id));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  <span class="m-l-10 font-semi-bold ');
    
      __out.push(__sanitize(this.vendor ? '' : 'text-muted'));
    
      __out.push('">');
    
      __out.push(__sanitize(this.vendor ? this.vendor.name : 'Unknown Vendor'));
    
      __out.push('</span>\n  <br/>\n  <div class="transaction-description ');
    
      __out.push(__sanitize(this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE'] ? 'one-line' : ''));
    
      __out.push(' text-muted m-l-10" data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.document_description_tooltip));
    
      __out.push('">');
    
      __out.push(__sanitize(this.description));
    
      __out.push('</div>\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  <span class="text-muted">\n    ');
    
      if (this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE']) {
        __out.push('\n      ');
        if (this.actual_payment_date) {
          __out.push('\n        ');
          __out.push(__sanitize(moment(this.actual_payment_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
          __out.push('\n      ');
        }
        __out.push('\n    ');
      } else {
        __out.push('\n      ');
        __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('\n    ');
      }
    
      __out.push('\n  </span>\n\n  ');
    
      if (this.is_invoice && this.invoice_date) {
        __out.push('<br />\n    <span class="check-number">(Invoice Date: ');
        __out.push(__sanitize(moment(this.invoice_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push(')</span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="document-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.employee_card_name && this.employee_card_name !== this.payment_account_name ? [this.payment_account_name, this.employee_card_name].join(' / ') : this.payment_account_name));
    
      __out.push('\n  ');
    
      if (this.check_number) {
        __out.push('\n    <br/><span class="check-number">(Check #');
        __out.push(__sanitize(this.check_number));
        __out.push(')</span>\n  ');
      }
    
      __out.push('\n</td>\n<td class="document-clickable pointer text-right ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  <span class="font-16 font-semi-bold">\n    ');
    
      if (this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE']) {
        __out.push('\n      ');
        __out.push(__sanitize(parseFloat(this.amount_verified).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        })));
        __out.push('\n    ');
      } else {
        __out.push('\n      ');
        __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        })));
        __out.push('\n    ');
      }
    
      __out.push('\n  </span>\n</td>\n');
    
      if (this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE']) {
        __out.push('\n<td class="document-clickable pointer text-right ');
        __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
        __out.push('" style="padding: 3px 0 0 3px;">\n  ');
        if (this.has_documents) {
          __out.push('\n    <span class="icon-documented font-22 m-l-2"></span>\n  ');
        } else if (this.no_document_required) {
          __out.push('\n    <span class="icon-no_document_needed font-22 m-l-2"></span>\n  ');
        } else {
          __out.push('\n    <span class="icon-document_grey font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
        }
        __out.push('\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.itemType === Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE']) {
        __out.push('\n  ');
        if (this.document_type === 'Invoice') {
          __out.push('\n    <td class="');
          __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
          __out.push(' ');
          __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
          __out.push(' document-detail pointer vertical-align-middle-important">\n      <img src="');
          __out.push(__sanitize(configData.images.ap_service_logo));
          __out.push('" class=\'width-30px\'>\n    </td>\n  ');
        } else {
          __out.push('\n    <td class="');
          __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
          __out.push(' ');
          __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
          __out.push(' document-detail pointer vertical-align-middle-important" style="padding: 3px 0 0 3px;">\n      <span class="icon icon-receipt-document font-40"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>\n    </td>\n  ');
        }
        __out.push('\n');
      } else {
        __out.push('\n  <td class="item-');
        __out.push(__sanitize(this.id));
        __out.push(' ');
        __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
        __out.push(' document-detail pointer vertical-align-middle-important" style="padding: 3px 0 0 3px;">\n    ');
        if (this.state === 'unverified') {
          __out.push('\n      <span class="icon-unverified font-18 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Uncategorized"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n    ');
        } else if (ref = this.transaction_type, indexOf.call(Docyt.Common.Constants.EXPENSE_TRANSACTION_TYPES, ref) >= 0) {
          __out.push('\n      <span class="icon-expenses-nav font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Expense"></span>\n    ');
        } else if (ref1 = this.transaction_type, indexOf.call(Docyt.Common.Constants.REVENUE_TRANSACTION_TYPES, ref1) >= 0) {
          __out.push('\n      <span class="icon-revenue-nav font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Revenue"></span>\n    ');
        } else if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
          __out.push('\n      <span class="icon-bank-transfers-nav font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Transfer"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n    ');
        } else if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['PAYROLL_TYPE']) {
          __out.push('\n      <span class="icon-payroll-liability-nav font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Payroll"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>\n    ');
        } else if (ref2 = this.transaction_type, indexOf.call(Docyt.Common.Constants.BALANCE_SHEET_TRANSACTION_TYPES, ref2) >= 0) {
          __out.push('\n      <span class="icon-equity-liability-assets font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Equity"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n    ');
        } else if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['CHARGEBACK_TYPE']) {
          __out.push('\n      <span class="icon-chargeback-nav font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Chargeback"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n    ');
        } else if (this.transaction_type === Docyt.Common.Constants.TRANSACTION_TYPES['LOAN_PAYMENTS_TYPE']) {
          __out.push('\n      <span class="icon-loan-payments font-28 m-l-15 transaction-type" data-toggle="tooltip" data-html="true" data-placement="bottom" title="Loan Payments"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n    ');
        }
        __out.push('\n  </td>\n');
      }
    
      __out.push('\n<td class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_matched ? 'document_verified' : ''));
    
      __out.push('">\n  <div class="relative">\n    <a class="btn dropdown-toggle" type="button" id="transaction-');
    
      __out.push(__sanitize(this.itemType));
    
      __out.push('-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n    </a>\n    <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="transaction-');
    
      __out.push(__sanitize(this.itemType));
    
      __out.push('-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n      <li><a tabindex="-1" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat" class="send-message-btn"><span class="icon-chat-round m-r-18"></span> Send a Message</a></li>\n      <li><a tabindex="-1" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('" class="view-transaction-detail"><i class="fa fa-file-text-o m-l-3 m-r-18"></i> <span>View ');
    
      __out.push(__sanitize(this.itemType !== Docyt.Common.Constants.DOCUMENT_TYPES['COMBINED_TYPE'] ? 'Transaction' : void 0));
    
      __out.push(' Detail</span></a></li>\n      ');
    
      if (this.itemType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE'] && this.state !== 'verified') {
        __out.push('\n        <li><a tabindex="-1" class="pointer uncategorized-transaction-detail"><i class="fa fa-check-circle-o font-16 m-l-2 m-r-18"></i> <span>Categorize Transaction</span></a></li>\n      ');
      }
    
      __out.push('\n    </ul>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}