import React, { useMemo } from 'react';

import { useCreatePopover, IPopoverParams, mergePopoverOpts } from '@src/hooks/popover';

import { ReactSelectMenuClassName } from '@src/components/ui_v2/inputs/react_select/utils';

import { TooltipContext } from './hooks';

import dropdownStyles from '@src/components/ui_v2/dropdown/styles.module.scss';
import dateInputStyles from '@src/components/ui_v2/inputs/date_input/styles.module.scss';

import styles from './styles.module.scss';

interface ITooltipProviderProps {
  children: React.ReactNode,
  popoverParams?: IPopoverParams,
}

const tooltipPopperOptions: IPopoverParams['opts'] = {
  placement: 'bottom',
  modifiers: [
    { name: 'offset', options: { offset: [0, Number(styles['tooltip-arrow-size-clear'])] } },
  ],
};

const conflictSelectors: string[] = [
  // React select menu element class
  `.${ReactSelectMenuClassName}`,
  // Date input menu
  `.${dateInputStyles['date-input-popper']}`,
  // Visible dropdown menu
  `.${dropdownStyles['dropdown-menu-visible']}`,
];

const hasConflict = (reference: HTMLElement): boolean => {
  const matchedSelector = conflictSelectors.find((selector) => {
    return reference.querySelector(selector);
  });

  return Boolean(matchedSelector);
};

const TooltipProvider = ({
  children,
  popoverParams = {},
}: ITooltipProviderProps) => {
  const popoverOpts = useMemo(() => {
    return {
      hasConflict,
      ...popoverParams,
      opts: mergePopoverOpts(tooltipPopperOptions, popoverParams.opts || {}),
    };
  }, [popoverParams]);

  const popover = useCreatePopover(popoverOpts);

  return (
    <TooltipContext.Provider value={ popover }>
      { children }
    </TooltipContext.Provider>
  );
};

export default React.memo(TooltipProvider);
