import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import InboxServiceProvider from '@src/components/providers/inbox_service_provider';
import Root from '@src/components/root';

import Details from './details';
import Sidemenu from './sidemenu';

interface IBusinessInboxDetailsPageProps {
  businessId: TID,
  isTrash?: boolean,
}

const BusinessInboxDetailsPage = ({
  businessId,
  isTrash,
}: IBusinessInboxDetailsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <InboxServiceProvider businessId={ businessId }>
          <div id="sidemenu-region">
            <Sidemenu />
          </div>
          <div id="detail-region">
            <Details isTrash={ isTrash } />
          </div>
        </InboxServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BusinessInboxDetailsPage;
