import React from 'react';
import { SubmitHandler, useController, useForm } from 'react-hook-form';
import Form from '@src/components/ui/form';
import { IDataExport } from '@src/types/data_exports';

import './index.scss';

interface IDataExportUpdateFormValues {
  exportFileName: string,
}
interface IDataExportUpdateFormProps {
  dataExport: IDataExport,
  formId?: string,
  onSubmit: SubmitHandler<IDataExportUpdateFormValues>,
}
const RenameForm = ({
  dataExport,
  formId,
  onSubmit,
}: IDataExportUpdateFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
  } = useForm<IDataExportUpdateFormValues>({
    defaultValues: {
      exportFileName: dataExport.exportFileName.split('.')[0],
    },
  });

  const exportFileNameController = useController({ name: 'exportFileName', control });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) } className='rename-export-name-form'>
      <h2>Export Name</h2>
      <Form.TextField
        placeholder="Export Name"
        { ...exportFileNameController.field }
      />
    </Form>
  );
};
export {
  IDataExportUpdateFormProps,
  IDataExportUpdateFormValues,
  RenameForm as default,
};