import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { businessSettingsStepPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Modal from '@src/components/ui/modal/modal';

interface IActivateInvoiceModalProps extends IUseModalProps {
  businessId: number;
}

const ActivateInvoiceModal = ({
  isOpen,
  onCancel,
  onDone,
  businessId,
}: IActivateInvoiceModalProps) => {
  return (
    <Modal.Standard
      proceedTitle="Upgrade to Activate"
      show={ isOpen }
      title="AI Invoice Line Item Detection"
      onCancel={ onCancel }
      onProceed={ () => {
        onDone();
        backboneNavigateToUrl(businessSettingsStepPath(businessId, 7));
      } }
    >
      {() => (
        <div className="display-flex-column width-100-percent align-items-center">
          <img
            alt=""
            className="width-10-percent"
            src={ window.configData.images.upgrade_icon }
          />
          <p className="width-100-percent m-t-10">
            The feature facilitates automatic extraction of line items
            <b> (departments, categories and amounts)</b>
&nbsp; while providing flexibility for manual adjustments.
            <br />
            Upgrade to our advanced plan to save you efforts
            and let Docyt AI do it for you in no time.
          </p>
        </div>
      )}
    </Modal.Standard>
  );
};

const useActivateInvoiceModal = makeUseModal(ActivateInvoiceModal);

export {
  IActivateInvoiceModalProps,
  useActivateInvoiceModal,
  ActivateInvoiceModal as default,
};
