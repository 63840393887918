import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { formatDate } from '@src/utils/date_helpers';

import styles from './styles.module.scss';

const NameInfo: FC<{data: IAdjustmentEntry}> = ({ data }) => {
  const business = useBusinessContext();
  const { fromQbo, qboId, docytId } = data;

  const idText = fromQbo ? `QBO-JE-${qboId}` : docytId;

  return (
    <div className={ styles['name-info'] }>
      <span>{idText}</span>
      <span className={ styles['split-line'] } />
      <span>{ business.name }</span>
      <span className={ styles['split-line'] } />
      <span>{formatDate(data.entryDate, 'MM/DD/YYYY')}</span>
    </div>
  );
};
export default NameInfo;
