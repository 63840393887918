import React from 'react';

import { Button } from '@src/components/ui/buttons';
import GoogleLoginIcon from '@src/components/utils/icomoon/google-login';

import { SSOSignIn } from './helpers';
import { useVerifySSOLogin } from './hooks/useVerifySSOLogin';
import { LoginButtonProps } from './view/login_button_root';

import styles from './styles.module.scss';

const LoginButton = ({ ssoLogin }: LoginButtonProps) => {
  useVerifySSOLogin({
    ssoLogin,
  });
  if (window.configData?.sso?.sso_cognito_domain) {
    return (
      <div className={ styles['sso-button-container'] }>
        <div className={ styles['sso-button-divider'] }>
          <span>Or With</span>
        </div>
        <Button className={ styles['sso-button'] } type="button" onClick={ SSOSignIn }>
          <GoogleLoginIcon fontSize={ 30 } />
          {' '}
          <span className={ styles.text }>Sign in with Google</span>
        </Button>
      </div>
    );
  }
  return null;
};

export default LoginButton;
