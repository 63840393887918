import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './item';

interface IRelatedTransactionsListProps {
  relatedTransactions: ITransactionServiceDocument[],
}

const RelatedTransactionsList = ({
  relatedTransactions,
}: IRelatedTransactionsListProps): JSX.Element => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 20 }>Date</Table.HCell>
          <Table.HCell widthPercent={ 25 }>Memo</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Type</Table.HCell>
          <Table.HCell widthPercent={ 20 }>Account</Table.HCell>
          <Table.HCell widthPercent={ 15 }>Amount</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          relatedTransactions.map((relatedTransaction) => {
            return (
              <Item
                key={ relatedTransaction.id }
                relatedTransaction={ relatedTransaction }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default RelatedTransactionsList;
