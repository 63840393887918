module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider p-b-30">\n  <div class="m-t-0">\n    <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/integrations">Integrations</a></span>\n    <div class="client__breadcrumbs-item in-grey-600 font-20"></div>\n    <span><a class="font-16 in-blue-400 text-decoration-blank" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/integrations/');
    
      __out.push(__sanitize(this.business.get('id')));
    
      __out.push('/external_systems">');
    
      __out.push(__sanitize(this.businessName));
    
      __out.push('</a></span>\n  </div>\n  <h2 class="service-main-title m-t-0">Configure your ');
    
      __out.push(__sanitize(this.external_system.get('description')));
    
      __out.push(' Integration</h2>\n</div>\n<div class="setting-business-content m-t-30">\n  <div>\n    ');
    
      if (this.integrationType === 'email') {
        __out.push('\n    <div class="form-group jsonform-error-revenue_report_type_id">\n      <label for="jsonform-96-elt-revenue_report_type_id">Email Address</label>\n      <div class="controls">\n        <input type="text" class="form-control" name="email-address" id="jsonform-63-elt-email-address" aria-label="Email Address" readonly value="');
        __out.push(__sanitize(this.emailAddress));
        __out.push('">\n      </div>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="form-buttons m-b-10">\n      <button id="showPassword" class="btn btn-default">Show Password</button>\n      <button id="hidePassword" class="btn btn-default">Hide Password</button>\n    </div>\n    ');
      }
    
      __out.push('\n    <form id="integration-form"></form>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}