import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetReportBySlug } from '@src/hooks/queries/report_service/custom_reports';

import ReportOtherDetail from './report_other_detail';

interface IReportOtherDetailPage {
  slug: string,
  itemIdentifier: string
}

const ReportOtherDetailPage = ({ slug, itemIdentifier }: IReportOtherDetailPage): JSX.Element | null => {
  const business = useBusinessContext();

  const { data: report } = useGetReportBySlug({
    slug,
    businessId: business.id,
  });

  if (report) {
    return (
      <ReportOtherDetail
        businessId={ business.id }
        itemIdentifier={ itemIdentifier }
        report={ report }
      />
    );
  }

  return <div />;
};

export default React.memo(ReportOtherDetailPage);
