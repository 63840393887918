module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog p-t-150">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-24 font-bold">');
    
      __out.push(__sanitize(this.id ? 'Update' : 'Create'));
    
      __out.push(' Document Request</h4>\n      </div>\n      <div class="modal-body">\n        <div class="display-flex align-items-center justify-content-between">\n          <label class="form-label font-14">Request Name</label>\n          <input class="form-control font-12 in-black request-name-js" placeholder="Enter a name for document request" autocomplete="off" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('" />\n        </div>\n        <div class="display-flex justify-content-between m-t-8">\n          <label class="form-label m-t-8 font-14">Description</label>\n          <textarea class="form-control modal-textarea font-12 in-black request-description-js" placeholder="Enter description (Optional)" autocomplete="off">');
    
      __out.push(__sanitize(this.description));
    
      __out.push('</textarea>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class="cancel-link cancel settings-cancel-btn font-16">Cancel</a>\n        <button type="button" class="btn btn-info ladda-button btn-create font-16 font-bold" data-color="blue" data-style="expand-right"><span class="ladda-label">');
    
      __out.push(__sanitize(this.id ? 'Save' : 'Create'));
    
      __out.push('</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}