import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateBalanceSheetStatementParams,
  ICreateBalanceSheetStatementResponse,
  IDeleteBalanceSheetStatementParams,
  IGetBalanceSheetStatementResponse,
  IRequestBalanceSheetStatementParams,
  IUnverifyBalanceSheetStatementParams,
  IUpdateBalanceSheetStatementParams,
  IVerifyalanceSheetStatementParams,
  createBalanceSheetStatement,
  deleteBalanceSheetStatement,
  getBalanceSheetStatementByDocytId,
  postRequestBalanceSheetStatement,
  postUnverifyBalanceSheetStatement,
  updateBalanceSheetStatement,
  verifyRequestBalanceSheetStatement,
} from '@src/requests/balance_sheet_statements';
import { TID } from '@src/types/common';

const useCreateBalanceSheetStatement = () => {
  const queryClient = useQueryClient();

  return useMutation<
  ICreateBalanceSheetStatementResponse, Error, ICreateBalanceSheetStatementParams>(
    createBalanceSheetStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useUpdateBalanceSheetStatement = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, IUpdateBalanceSheetStatementParams >(
    updateBalanceSheetStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useDestroyBalanceSheetStatement = (fromDetails?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteBalanceSheetStatementParams>(
    deleteBalanceSheetStatement,
    {
      onSuccess: () => {
        if (fromDetails) return;
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useRequestBalanceSheetStatement = () => {
  return useMutation<void, Error, IRequestBalanceSheetStatementParams>(
    postRequestBalanceSheetStatement,
  );
};

const useGetBalanceSheetStatementByDocytId =
  (businessId?: TID, docytId?: string, enabled?: boolean) => {
    return useQuery<IGetBalanceSheetStatementResponse, Error>(
      [QueryKey.balanceSheetStatementByDocytId, businessId, docytId],
      () => getBalanceSheetStatementByDocytId(businessId, docytId),
      {
        enabled: enabled && Boolean(businessId),
      },
    );
  };

const useVerifyBalanceSheetStatement = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, IVerifyalanceSheetStatementParams >(
    verifyRequestBalanceSheetStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useUnverifyBalanceSheetStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IUnverifyBalanceSheetStatementParams>(
    postUnverifyBalanceSheetStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

export {
  useCreateBalanceSheetStatement,
  useUpdateBalanceSheetStatement,
  useRequestBalanceSheetStatement,
  useVerifyBalanceSheetStatement,
  useGetBalanceSheetStatementByDocytId,
  useDestroyBalanceSheetStatement,
  useUnverifyBalanceSheetStatement,
};
