import React from 'react';

import styles from './styles.module.scss';

interface ISideViewFooterProps {
  children: React.ReactNode,
}

const SideViewFooter = ({
  children,
}: ISideViewFooterProps) => {
  return (
    <div className={ styles.footer }>
      { children }
    </div>
  );
};

export default SideViewFooter;
