import React from 'react';

import Root from '@src/components/root';

import MultiReportsExportDetails from './details/multi_reports_export_details';

const MultiReportsExport = ({ multiReportId }: { multiReportId: string }) => {
  return (
    <Root>
      <MultiReportsExportDetails multiReportId={ multiReportId } />
    </Root>
  );
}

export default MultiReportsExport;
