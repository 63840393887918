import { TColumn, TModel } from './types';

const CollectionTableColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: TColumn<Model, TSortColumn>) => {
  return null;
};

export default CollectionTableColumn;
