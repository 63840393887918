import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IEnvelopeCustomIconProps extends IIcomoonIconProps {
  variant: 'brown',
}
const EnvelopeCustomIcon = ({
  variant,
  ...props
}: IEnvelopeCustomIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ `envelope-${variant}` }
      pathsNumber={ 2 }
    />
  );
};

export default EnvelopeCustomIcon;
