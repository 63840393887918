module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-inline-flex width-25-percent pull-left ');
    
      __out.push(__sanitize((this.has_documents || this.no_document_required) ? 'p-t-7 p-l-4' : 'p-t-5'));
    
      __out.push('">\n  ');
    
      if (this.has_documents || this.no_document_required) {
        __out.push('\n    <span class="icon-document font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
      } else {
        __out.push('\n    <span class="icon-document_grey font-22"><span class="path1"></span><span class="path2"></span></span>\n  ');
      }
    
      __out.push('\n</div>\n<div class="display-inline-flex width-25-percent pull-left">\n  <a class="relative transaction-action" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat">\n    ');
    
      if (this.message_count === 0) {
        __out.push('\n    <span class="font-26 icon icon-chat-empty"></span>\n    ');
      } else {
        __out.push('\n    <span class="font-26 icon icon-chat-filled"></span><span class="unread-messages-count">');
        __out.push(__sanitize(this.message_count < 10 ? this.message_count : '9+'));
        __out.push('</span>\n    ');
      }
    
      __out.push('\n  </a>\n</div>\n<div class="display-inline-flex width-25-percent pull-left qbo-status-icon-column"></div>\n<div class="display-inline-flex width-25-percent pull-left">\n  <a class="btn dropdown-toggle in-grey-800 transaction-action" type="button" id="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <span class="icon icon-3-dots font-24"></span>\n  </a>\n  <ul class="transaction-dropdown dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <li><a tabindex="-1" class="goto-reconciliation-center" href="/businesses/');
    
      __out.push(__sanitize(this.businessId));
    
      __out.push('/reconciliation_center/');
    
      __out.push(__sanitize(this.reconciliation_center_module_url));
    
      __out.push('"><i class="icon icon-recurring font-18 m-r-18"></i>Go to Reconciliation Center</a></li>\n    <li><a tabindex="-1" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('"><i class="icon icon-note font-18 m-r-18"></i>Transaction Details</a></li>\n    ');
    
      if (this.imported_type === 'manual') {
        __out.push('\n    <li><a tabindex="-1" class="pointer remove-js danger-link"><i class="icon icon-trashcan font-18 m-r-18"></i>Delete Transaction</a></li>\n    ');
      }
    
      __out.push('\n  </ul>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}