module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business_notification_preference_account, i, len, ref;
    
      __out.push('<div>\n  <h1>Notifications</h1>\n  <div class="setting-option-wrapper">\n    <div class="setting-info-wrapper">\n      <p class="setting-title font-14">Auto reply to sender of documents</p>\n      <p class="setting-info font-14">When active, Docyt will automatically reply to senders to acknowledge that a document has been received and processed.</p>\n    </div>\n    <div class="setting-control-wrapper">\n      <select class="form-control permission-form-control select-auto-reply-js ');
    
      if (!this.auto_reply) {
        __out.push(__sanitize('disabled-control'));
      }
    
      __out.push('">\n        <option value="ENABLED" ');
    
      if (this.auto_reply) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Enabled</option>\n        <option value="DISABLED" ');
    
      if (!this.auto_reply) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Disabled</option>\n      </select>\n    </div>\n  </div>\n  <div class="setting-option-wrapper">\n    <div class="setting-info-wrapper">\n      <p class="setting-title font-14">Daily Reminders</p>\n      <p class="setting-info font-14">Remind users to upload receipts and invoices for undocumented transactions, review flagged transactions and invoices.</p>\n    </div>\n    <div class="setting-control-wrapper">\n      <select class="form-control permission-form-control select-daily-remind-js ');
    
      if (!this.daily_remind) {
        __out.push(__sanitize('disabled-control'));
      }
    
      __out.push('">\n        <option value="ENABLED" ');
    
      if (this.daily_remind) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Enabled</option>\n        <option value="DISABLED" ');
    
      if (!this.daily_remind) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Disabled</option>\n      </select>\n    </div>\n  </div>\n  ');
    
      if (this.is_business_admin) {
        __out.push('\n    <div class="setting-option-wrapper">\n      <div class="setting-info-wrapper">\n        <p class="setting-title font-14">When Docyt Checks are Issued</p>\n        <p class="setting-info font-14">Docyt will send notification to business owner whenever docyt checks are issued.</p>\n      </div>\n      <div class="setting-control-wrapper">\n        <select class="form-control permission-form-control select-docyt-checks-js ');
        if (!this.docyt_checks_issued) {
          __out.push(__sanitize('disabled-control'));
        }
        __out.push('">\n          <option value="ENABLED" ');
        if (this.docyt_checks_issued) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Enabled</option>\n          <option value="DISABLED" ');
        if (!this.docyt_checks_issued) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Disabled</option>\n        </select>\n      </div>\n    </div>\n    <div class="setting-missing-wrapper">\n      <h1>Request missing documentation</h1>\n      <div class="setting-option-wrapper">\n        <div class="setting-info-wrapper">\n          <p class="setting-title font-14">Notifications</p>\n          <p class="setting-info font-14">Request Documentation</p>\n        </div>\n        <div class="setting-control-wrapper">\n          <select class="form-control permission-form-control receiver-role-js ');
        if (this.receiver_role_type === null) {
          __out.push(__sanitize('disabled-control'));
        }
        __out.push('">\n            <option value="" disabled selected>Select User Roles</option>\n            <option value="type_business_admin" ');
        if (this.receiver_role_type === 'type_business_admin') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Business Admin</option>\n            <option value="type_employee" ');
        if (this.receiver_role_type === 'type_employee') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>Employees</option>\n            <option value="type_all" ');
        if (this.receiver_role_type === 'type_all') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>All</option>\n          </select>\n        </div>\n      </div>\n      <div class="setting-payment-wrapper m-t-20">\n        <p class="setting-title font-14">Payment Accounts</p>\n        <p class="setting-info font-14">Add the accounts you want to receive notifications for. Only user who have access to the payment account will be notified.</p>\n      </div>\n      <div class="payment-accounts-table-wrapper m-t-20 ');
        __out.push(__sanitize(this.for_onboarding_setup ? 'onboarding-scrollable' : 'bank-service-scrollable'));
        __out.push('">\n        <table class="table payment-accounts-table m-b-0">\n          <thead>\n            <tr>\n              <th width="60%">Account Name</th>\n              <th width="40%">Last Digits</th>\n            </tr>\n          </thead>\n          <tbody class="payment-account-list">\n            ');
        ref = this.business_notification_preference_accounts;
        for (i = 0, len = ref.length; i < len; i++) {
          business_notification_preference_account = ref[i];
          __out.push('\n              <tr>\n                <td class="pointer clickable-js">');
          __out.push(__sanitize(business_notification_preference_account.payment_account.name));
          __out.push('</td>\n                <td class="pointer clickable-js">');
          __out.push(__sanitize(business_notification_preference_account.payment_account.last4));
          __out.push('</td>\n              </tr>\n            ');
        }
        __out.push('\n            <tr>\n              <td>\n                <a class="pointer add-account-btn font-14"><i class="icon icon-o-plus m-r-10"></i> Add Account</a>\n              </td>\n              <td>\n              </td>\n            </tr>\n          </tbody>\n        </table>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}