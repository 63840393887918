import React, { useCallback } from 'react';

import { UseMutationResult } from 'react-query';

import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IMutationStatusProps {
  hideSpinner?: boolean,
  mutation: UseMutationResult<any, Error, any>,
  successMessage?: string,
  successTitle?: string,
}

const MutationStatus = ({
  hideSpinner,
  mutation: { error, reset, status },
  successMessage,
  successTitle,
}: IMutationStatusProps): JSX.Element | null => {
  const ignoreErrorMessage = [
    'Transaction date is in a reconciled month',
    'All the statements verified after',
    'will be unverified and transactions removed',
    'Statement date is before start import date of account',
    window.Docyt.Common.Constants.Messages.BANKING_STATEMENT_VERIFIED_IN_MSG,
    window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG,
  ];

  const handleNotificationHidden = useCallback(() => {
    reset();
  }, [reset]);

  switch (status) {
    case 'loading':
      if (hideSpinner) return null;
      return <Spinner />;
    case 'error':
      if (error?.message && ignoreErrorMessage.find((v) => error?.message.includes(v))) return null;
      return (
        <ErrorNotification
          error={ error }
          onHidden={ handleNotificationHidden }
        />
      );
    case 'success':
      if (!successMessage) return null;
      return (
        <SuccessNotification
          message={ successMessage }
          title={ successTitle }
          onHidden={ handleNotificationHidden }
        />
      );
    default:
      return null;
  }
};

export default MutationStatus;
