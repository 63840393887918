import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Actions from './actions';
import { getTransactionType, getTransactionTypeClass } from './utils';

interface IBankingAccountTransactionsTableItemProps {
  businessId: TID,
  transaction: ITransactionServiceDocument,
  isAdmin: boolean,
  isTrash: boolean
}

const BankingAccountTransactionsTableItem = ({
  businessId,
  transaction,
  isAdmin,
  isTrash,
}: IBankingAccountTransactionsTableItemProps) => {
  const handleTransactionClick = useCallback(() => {
    if (isTrash) return;

    Backbone.history.navigate(`/document/${transaction.documentId}`, { trigger: true });
  }, [isTrash, transaction.documentId]);

  return (
    <Table.Row>
      <Table.SelectItemCell item={ transaction } />
      <Table.DateCell
        pointer
        date={ transaction.transactionDate }
        onClick={ handleTransactionClick }
      />
      <Table.Cell pointer onClick={ handleTransactionClick }>
        { transaction.description }
      </Table.Cell>
      <Table.Cell pointer onClick={ handleTransactionClick }>
        <span className={ `transaction-type-badge ${getTransactionTypeClass(transaction)}` }>
          { getTransactionType(transaction) }
        </span>
      </Table.Cell>
      <Table.Cell pointer onClick={ handleTransactionClick }>
        { transaction.employeeCardName }
      </Table.Cell>
      <Table.AmountCell
        pointer
        amount={ transaction.amount }
        fontVariant="bold"
        style={ { paddingRight: '30px' } }
        onClick={ handleTransactionClick }
      />
      {
        isAdmin && (
          <Table.Cell className="transaction-actions-column">
            <Actions businessId={ businessId } isTrash={ isTrash } transaction={ transaction } />
          </Table.Cell>
        )
      }
    </Table.Row>
  );
};

export default React.memo(BankingAccountTransactionsTableItem);
