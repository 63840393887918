import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetBusinessUsersParams,
  IGetBusinessUsersResponse,
  IGetDocytAccountingAccountManagerParams,
  IGetDocytAccountingAccountManagerResponse,
  getBusinessAllUsers,
  getBusinessUsers,
  getDocytAccountingAccountManager,
} from '@src/requests/business_users';

const useGetBusinessAllUsers = (params: IGetBusinessUsersParams) => {
  return useQuery<IGetBusinessUsersResponse, Error>(
    [QueryKey.businessUsers, params],
    () => getBusinessAllUsers(params),
  );
};

const useGetBusinessUsers = (params: IGetBusinessUsersParams) => {
  return useQuery<IGetBusinessUsersResponse, Error>(
    [QueryKey.businessUsers, params],
    () => getBusinessUsers(params),
  );
};

const useGetDocytAccountingAccountManager = (params: IGetDocytAccountingAccountManagerParams) => {
  return useQuery<IGetDocytAccountingAccountManagerResponse, Error>(
    [QueryKey.docytAccountingBusinessUsers, params],
    () => getDocytAccountingAccountManager(params),
  );
};

export {
  useGetBusinessAllUsers,
  useGetBusinessUsers,
  useGetDocytAccountingAccountManager,
};
