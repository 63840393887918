import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { CSSTransition } from 'react-transition-group';

import { DataExportInProgressIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IExportExportBannerProps {
  endElementSelector: string,
  startPosition: { x: number, y: number },
}

const ExportAnimation = ({
  endElementSelector = '.icon-settings-cog',
  startPosition,
}: IExportExportBannerProps) => {
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [enableEndElement, setEnableEndElement] = useState<boolean>(false);

  const [endElementNodeRef, setEndElementNodeRef] = useState<
    MutableRefObject<HTMLElement | null> | null>(null);
  const iconRef = useRef(null);
  const iconElementRef = useRef<HTMLElement | null>(null);
  const endElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    endElementRef.current = document.querySelector(endElementSelector);
    iconElementRef.current = document.getElementById('data-export-icon');
    setShowIcon(true);
    setEndElementNodeRef(endElementRef);

    // moving export icon
    if (!iconElementRef.current) return;
    const endElementClientRect = endElementRef.current?.getBoundingClientRect();

    const endPosition = { x: endElementClientRect?.left || 0, y: endElementClientRect?.top || 0 };

    const deltaX = endPosition.x - startPosition.x;
    const deltaY = endPosition.y - startPosition.y;

    iconElementRef.current.style.transition = 'transform 1.5s ease-in-out';
    iconElementRef.current.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
  }, [endElementSelector, startPosition.x, startPosition.y]);

  return (
    <div className={ styles['export-animation-container'] }>
      <CSSTransition
        classNames="animation-icon"
        in={ showIcon }
        nodeRef={ iconRef }
        timeout={ 2000 }
        onEnter={ () => setShowIcon(false) }
        onExited={ () => setEnableEndElement(true) }
      >
        <div ref={ iconRef }>
          <DataExportInProgressIcon
            id="data-export-icon"
            style={ { left: `${startPosition.x}px`, top: `${startPosition.y}px` } }
          />
        </div>
      </CSSTransition>
      <CSSTransition
        classNames="end-element"
        in={ enableEndElement }
        nodeRef={ endElementNodeRef }
        timeout={ 3000 }
        onEnter={ () => setEnableEndElement(false) }
      >
        <div />
      </CSSTransition>
    </div>
  );
};

export default ExportAnimation;
