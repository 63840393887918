import React, { forwardRef } from 'react';

import AmountInput from '@src/components/ui_v2/inputs/amount_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IAmountFieldProps extends
  React.ComponentPropsWithoutRef<typeof AmountInput>,
  Omit<IWrapperProps, 'suffix'>
{
}

const AmountField = forwardRef<HTMLInputElement, IAmountFieldProps>(({
  ...props
}: IAmountFieldProps, ref): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <AmountInput
        ref={ ref }
        { ...inputProps }
      />
    </Wrapper>
  );
});

AmountField.displayName = 'AmountField';

export default AmountField;
