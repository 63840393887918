import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { useLinkDocumentRequestModal } from '@src/components/common/link_document_request';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { DocumentRequestIcon } from '@src/components/utils/icomoon';

interface IBusinessInboxItemLinkDocumentRequestActionProps {
  business: IBusiness,
  document: IInboxServiceDocument,
}

const BusinessInboxItemLinkDocumentRequestAction = ({
  business,
  document,
}: IBusinessInboxItemLinkDocumentRequestActionProps) => {
  const linkDocument = useLinkDocumentRequestModal();

  return (
    <>
      <linkDocument.Component
        { ...linkDocument.props }
        business={ business }
        document={ document }
      />
      <ActionsDropdown.Action
        icon={ <DocumentRequestIcon fontSize={ 22 } /> }
        name="Link to Document Request"
        onClick={ linkDocument.open }
      />
    </>
  );
};

export default BusinessInboxItemLinkDocumentRequestAction;
