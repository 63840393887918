import React, { useState, useCallback, useEffect } from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import {
  EditIcon,
  PlusIcon,
  TrashcanIcon,
} from '@src/components/utils/icomoon';

import Conditions from './conditions';
import {
  IAccountsPayableCompliancePolicyRule,
  IAccountsPayablePolicy,
  IInitialData,
} from './types';
import { operatorValue } from './utils';

import styles from './styles.module.scss';

interface IAdvanceRuleItemViewProps {
    handleDeleteTransaction: () => void;
    handleAddEditClick: (data: IInitialData | null) => void;
    handleClickDone: (policyType : string) => void;
    initialData: IInitialData | null;
    onConditionSelected: (condition: string, open: boolean) => void;
    handleOnTransactionDeleted: (id: number) => void;
    openSideview: (condition: string, open: boolean) => void;
    handleVendorDeleted: () => void;
    approverPolicy: IAccountsPayablePolicy | undefined;
}

const AddAdvanceRuleItemView = ({
  handleDeleteTransaction,
  handleAddEditClick,
  handleClickDone,
  initialData,
  onConditionSelected,
  handleOnTransactionDeleted,
  openSideview,
  handleVendorDeleted,
  approverPolicy,
}: IAdvanceRuleItemViewProps) => {
  const APPROVER_FOR_UPLOADER = 'approval_for_uploader';
  const TRANSACTION_AMOUNT = 'Transaction Amount';
  const VENDOR = 'Vendor';
  const ADD_CONDITION = 'Add Condition';
  const ADD_ADDITIONAL_CONDITION = 'Add Additional Condition';
  const [selectedCondition, setSelectedCondition] =
        useState<string>(ADD_CONDITION);
  const [conditions, setConditions] = useState<string[]>([]);

  const handleAddCondition = useCallback(
    (rule: string) => {
      setSelectedCondition(rule);
      onConditionSelected(rule, true);
    },
    [onConditionSelected],
  );

  useEffect(() => {
    if (!initialData) return;
    const items = [];
    if (!initialData.amount) {
      items.push(TRANSACTION_AMOUNT);
    }
    if (!initialData.vendor) {
      items.push(VENDOR);
    }
    setConditions(items);
  }, [initialData]);

  const editAddAdvanceRule = useCallback(() => {
    return (
      <tr>
        <td className="relative flex-100">
          {initialData && initialData.amount && (
            <div className="m-r-15 rule-condition-wrapper editing">
              <div className="small-rule-logo bg-green-900">
                <span className="font-18 font-semi-bold in-white">
                  $
                </span>
              </div>
              <span className="font-14 m-l-19">
                {`${
                  operatorValue[initialData.amountOperator]
                } $${initialData.amount}`}
              </span>
              <div className={ styles['advance-rule-icon'] }>
                <EditIcon
                  className="edit-icon pointer m-r-10"
                  fontSize={ 14 }
                  onClick={ () => openSideview(TRANSACTION_AMOUNT, true) }
                />
                <TrashcanIcon
                  className="trashcan-icon pointer"
                  fontSize={ 14 }
                  onClick={ handleDeleteTransaction }
                />
              </div>
            </div>
          )}

          {initialData && initialData.vendor && (
          <div className="m-r-15 rule-condition-wrapper editing">
            <div className="small-rule-logo ">
              {initialData.vendor.imageUrl ? (
                <img
                  alt={ initialData.vendor.name }
                  className="vertical-align-top"
                  src={ initialData.vendor.imageUrl }
                />
              ) : (
                <i className="icon icon-smile in-blue-400 font-10" />
              )}
            </div>
            <span className="font-14 m-l-19">
              {initialData.vendor.name}
            </span>
            <div className={ styles['advance-rule-icon'] }>
              <EditIcon
                className="edit-icon pointer m-r-10"
                fontSize={ 14 }
                onClick={ () => openSideview(VENDOR, true) }
              />
              <TrashcanIcon
                className="trashcan-icon pointer"
                fontSize={ 14 }
                onClick={ handleVendorDeleted }
              />
            </div>
          </div>
          )}

          {conditions.length > 0 && (
          <Conditions
            conditions={ conditions }
            handleAddCondition={ handleAddCondition }
            selectedCondition={ selectedCondition }
            title={
                                          conditions.length === 2
                                            ? ADD_CONDITION
                                            : ADD_ADDITIONAL_CONDITION
                  }
          />
          )}
        </td>
        <td>
          <div className={ styles['edit-icon'] }>
            <Button variant="primary" onClick={ () => { handleClickDone(APPROVER_FOR_UPLOADER); } }>
              Done
            </Button>
          </div>
        </td>
      </tr>
    );
  }, [
    conditions,
    initialData,
    handleDeleteTransaction,
    handleAddCondition,
    selectedCondition,
    handleVendorDeleted,
    handleClickDone,
    openSideview,
  ]);

  const addNewRule = useCallback(() => {
    return (
      <td className="relative" colSpan={ 3 }>
        <div className="add-link-wrapper">
          <Button
            className="add-rule-link add-special-rule-js"
            variant="link"
            onClick={ () => handleAddEditClick(null) }
          >
            <PlusIcon fontSize={ 14 } variant="o" />
            <span className="m-l-14 font-14 font-semi-bold">
              Add Rule
            </span>
          </Button>
        </div>
      </td>
    );
  }, [handleAddEditClick]);

  const renderAdvanceRule = useCallback(
    (rule) => {
      return rule?.vendor || rule?.amount ? (
        <>
          <td className="relative flex-100">
            {rule?.vendor?.name && (
            <div className="rule-condition-wrapper width-50-percent">
              <div className="small-rule-logo">
                {rule?.vendor?.imageUrl ? (
                  <img
                    alt={ rule?.vendor?.name }
                    className="vertical-align-top"
                    src={ rule?.vendor?.imageUrl }
                  />
                ) : (
                  <i className="icon icon-smile in-blue-400 font-10" />
                )}
              </div>
              <span className="font-14 m-l-10">
                {rule?.vendor?.name}
              </span>
            </div>
            )}

            {rule?.amount && (
            <div className="rule-condition-wrapper">
              <div className="small-rule-logo bg-green-900">
                <span className="font-18 font-semi-bold in-white">
                  $
                </span>
              </div>
              <span className="font-14 m-l-10">
                Transaction Amount:&nbsp;
                {operatorValue[rule.amountOperator]}
                &nbsp;
                $
                {rule?.amount}
              </span>
            </div>
            )}
          </td>
          <td aria-label="actions">
            <div className={ styles['edit-icon'] }>
              <EditIcon
                className="edit-icon pointer m-r-10"
                fontSize={ 18 }
                onClick={ () => {
                  handleAddEditClick(rule);
                } }
              />
              <TrashcanIcon
                className="trashcan-icon pointer"
                fontSize={ 18 }
                onClick={ () => handleOnTransactionDeleted(rule.id) }
              />
            </div>
          </td>
        </>
      ) : null;
    },
    [handleOnTransactionDeleted, handleAddEditClick],
  );

  return (
    <>
      {approverPolicy?.accountsPayableCompliancePolicyRules
                && approverPolicy?.accountsPayableCompliancePolicyRules.length
                    > 0
                && approverPolicy.accountsPayableCompliancePolicyRules.map(
                  (rule: IAccountsPayableCompliancePolicyRule) => {
                    if (
                      initialData
                            && Number(initialData?.id) === rule.id
                    ) {
                      return editAddAdvanceRule();
                    }
                    return <tr key={ rule.id }>{renderAdvanceRule(rule)}</tr>;
                  },
                )}

      {initialData && initialData.id === 'new' ? (
        editAddAdvanceRule()
      ) : (
        <tr>{addNewRule()}</tr>
      )}
    </>
  );
};

export default AddAdvanceRuleItemView;
