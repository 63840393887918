module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="flex-1">\n  <div class=\'form-control transaction-field transaction-min-amount-field\'>');
    
      __out.push(__sanitize(this.min_amount ? '$' + this.min_amount : 'Min $'));
    
      __out.push('</div>\n  <input type="text" class="form-control transaction-min-amount hidden" placeholder="Min $" value="');
    
      __out.push(__sanitize(this.min_amount));
    
      __out.push('" />\n</div>\n<span class="input-group-addon transaction-addon">-</span>\n<div class="flex-1">\n  <div class=\'form-control transaction-field transaction-max-amount-field\'>');
    
      __out.push(__sanitize(this.max_amount ? '$' + this.max_amount : 'Max $'));
    
      __out.push('</div>\n  <input type="text" class="form-control transaction-max-amount hidden" placeholder="Max $" value="');
    
      __out.push(__sanitize(this.max_amount));
    
      __out.push('" />\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}