import React from 'react';

import { Routes, Route } from 'react-router-dom';

import InvoiceQueue from './invoice_queue';

const InvoiceQueueRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/accounts_payable/new_queue">
        <Route element={ <InvoiceQueue /> } path=":queueType" />
        <Route index element={ <InvoiceQueue /> } />
      </Route>
    </Routes>
  );
};

export default React.memo(InvoiceQueueRouter);
