import React from 'react';

import Root from '@src/components/root';

import List from './list';

const Component = () => {
  return (
    <Root>
      <List />
    </Root>
  );
};

export default Component;
