module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var action, i, len, ref,
        indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };
    
      __out.push('<div class="right-side-action-view">\n  <div class="view-title font-bold m-t-10">ACTIONS</div>\n  <div class="document-fields">\n    ');
    
      ref = this.actions;
      for (i = 0, len = ref.length; i < len; i++) {
        action = ref[i];
        __out.push('\n      <div class="field-input-container m-t-10">\n        <div class="document-field-item document-field-name m-t-7">then</div>\n        <div class="document-field-item document-field-value vendor-typeahead-wrapper">\n          <select data-id="');
        __out.push(__sanitize(this.actions.indexOf(action)));
        __out.push('" class="action-type form-control selectpicker" title="Select Action">\n            <option value="no_auto_verify" ');
        if (action === 'no_auto_verify') {
          __out.push(__sanitize('selected'));
        }
        __out.push(' ');
        if (indexOf.call(this.actions, 'no_auto_verify') >= 0) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>Do not auto-categorize</option>\n            <option value="no_push_qbo" ');
        if (action === 'no_push_qbo') {
          __out.push(__sanitize('selected'));
        }
        __out.push(' ');
        if (indexOf.call(this.actions, 'no_push_qbo') >= 0) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>Do not push to Ledger</option>\n            <option value="no_document_needed" ');
        if (action === 'no_document_needed') {
          __out.push(__sanitize('selected'));
        }
        __out.push(' ');
        if (indexOf.call(this.actions, 'no_document_needed') >= 0) {
          __out.push(__sanitize('disabled'));
        }
        __out.push('>No Document Needed</option>\n          </select>\n        </div>\n      </div>\n      <span data-id="');
        __out.push(__sanitize(this.actions.indexOf(action)));
        __out.push('" class="font-15 pointer in-red-400 pull-right m-r-10 delete-action">Delete</span>\n      <hr class="m-t-40 m-b-10"/>\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.actions.length !== 3) {
        __out.push('\n      <span class="font-15 pointer in-blue-400 inline-block p-l-20 add-action m-t-10"><span class="icon-plus-circle m-r-18"></span>Add another action</span>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}