import { useCallback, useState } from 'react';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { TOrderDirection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';

const parseSearchParams = <TSortColumn extends string>(
  searchParams: URLSearchParams,
): ISortingParams<TSortColumn> | undefined => {
  let sortingParams: ISortingParams<TSortColumn> | undefined;

  searchParams.forEach((value, key) => {
    if (!key.endsWith('_order')) return;

    sortingParams = {
      orderColumn:    key.replace('_order', '') as any as TSortColumn,
      orderDirection: value as TOrderDirection,
    };
  });

  return sortingParams;
};

const restoreSortingFromStorage = <TSortColumn extends string>(
  section: string,
): ISortingParams<TSortColumn> | undefined => {
  const serializedData = localStorage.getItem(section);
  if (!serializedData) return undefined;

  let data: ISortingParams<TSortColumn> | undefined;
  const storedData = JSON.parse(serializedData);
  forEach(storedData, (value, key) => {
    if (!key.endsWith('_order')) return;

    data = {
      orderColumn:    key.replace('_order', '') as any as TSortColumn,
      orderDirection: value,
    };
  });

  return data;
};

const storeSortingToStorage = <TSortColumn extends string>(
  section: string,
  data: ISortingParams<TSortColumn> | undefined,
) => {
  const storedData = JSON.parse(localStorage.getItem(section) || '{}');

  // Remove old sorting keys
  const newData = omitBy(storedData, (_, k) => k.endsWith('_order'));

  // Add new sorting keys if needed
  if (data) {
    newData[`${data.orderColumn}_order`] = data.orderDirection;
  }

  // If filter data are empty then remove data from storage and set empty search params
  if (isEmpty(newData)) {
    localStorage.removeItem(section);

    return;
  }

  // Store new sorting data (together with filter) in local storage
  localStorage.setItem(section, JSON.stringify(newData));
};

interface IUseSortingParams <TSortColumn extends string>{
  section?: string,
  defaultSorting: ISortingParams<TSortColumn>,
  initSearchParams?: URLSearchParams,
}

const useSorting = <TSortColumn extends string>({
  section,
  defaultSorting,
  initSearchParams,
}: IUseSortingParams<TSortColumn>): ISorting<TSortColumn> => {
  const [data, setData] = useState<ISortingParams<TSortColumn>>(() => {
    if (initSearchParams && initSearchParams.toString().length > 0) {
      return parseSearchParams(initSearchParams) || defaultSorting;
    }

    if (section) {
      return restoreSortingFromStorage(section) || defaultSorting;
    }

    return defaultSorting;
  });

  const toggleColumSort = useCallback((column: TSortColumn) => {
    setData((oldData) => {
      let newData: ISortingParams<TSortColumn>;
      if (oldData?.orderColumn === column) {
        const newDirection = oldData?.orderDirection === 'desc' ? 'asc' : 'desc';

        newData = {
          orderColumn:    column,
          orderDirection: newDirection,
        };
      } else {
        newData = {
          orderColumn:    column,
          orderDirection: 'desc',
        };
      }

      if (section) storeSortingToStorage(section, newData);

      return newData;
    });
  }, [section]);

  const getColumnSortDirection = useCallback((column: TSortColumn) => {
    if (column !== data?.orderColumn) return undefined;

    return data.orderDirection;
  }, [data]);

  const clear = useCallback(() => {
    setData(() => {
      if (section) storeSortingToStorage(section, undefined);

      return defaultSorting;
    });
  }, [section, defaultSorting]);

  return {
    data,
    getColumnSortDirection,
    toggleColumSort,
    clear,
  };
};

export {
  useSorting,
};
