import { atomFamily } from 'recoil';

import { Family } from '@src/constants/atom_keys';
import { TSection } from '@src/types/common';

import { TItemsSelectorState } from './types';

const itemsSelector = atomFamily<TItemsSelectorState, TSection>({
  key:     Family.ItemsSelector,
  default: {
    all:      [],
    selected: [],
  },
});

export {
  itemsSelector,
};
