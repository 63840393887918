module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="member-select-item border-bottom-gray">\n    <div class="checkbox m-t-0 m-b-0">\n        <input type="checkbox" class="styled form-control input-element" data-type="checkbox" data-member="');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">\n        <label class="font-16 relative width-100-percent">\n        <div class="display-table-row">\n          <div class="display-table-cell">\n            ');
    
      if (this.avatar && this.avatar.avatar_url) {
        __out.push('\n            <div class="middle-avatar-wrapper border-gray">\n              <img src="');
        __out.push(__sanitize(this.avatar.avatar_url));
        __out.push('">\n            </div>\n            ');
      } else {
        __out.push('\n            <i class="icon icon-smile in-blue-400 font-40"></i>\n            ');
      }
    
      __out.push('\n          </div>\n          <div class="display-table-cell p-l-20 vertical-align-middle">\n            <h5 class="font-16 font-bold m-t-0 m-b-0 font-bold text-left">\n              ');
    
      __out.push(__sanitize(this.parsed_fullname));
    
      __out.push('\n            </h5>\n          </div>\n        </div>\n        </label>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}