import React, { FC } from 'react';

import { TID } from '@src/types/common';

import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import { EditIcon as IcomoonEditIcon } from '@src/components/utils/icomoon';

import EditContent from './edit_content';

const EditIcon: FC<{id: TID}> = ({ id }) => {
  const { isOpen, open, close } = useDrawer();

  return (
    <>
      <IcomoonEditIcon pointer onClick={ open } />
      <JournalDrawer drawerControl={ { isOpen, open, close } } title="Edit Journal Entry">
        <EditContent close={ close } id={ id } />
      </JournalDrawer>
    </>
  );
};

export default EditIcon;
