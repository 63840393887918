module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="item-divider p-b-10">\n    <h2 class="font-bold font-24">Summary</h2>\n</div>\n<div class="service-detail-view m-t-50">\n    <h2 class="m-l-40 font-18 font-bold m-b-25">\n        Accounts payable by month\n        <span class="fa fa-chevron-right font-14 pointer pull-right next-month-btn"></span>\n        <span class="fa fa-chevron-left font-14 pointer pull-right prev-month-btn"></span>\n    </h2>\n    <canvas class="month-detail-view" width="1000" height="250"></canvas>\n    <div class="relative">\n        <span class="fa fa-caret-down font-24 pointer show-coa-detail-btn"></span>\n    </div>\n    <div class="coa-detail-view-wrapper m-t-50">\n        <button class="close close-coa-detail-btn" type="button" data-index="0"><span>×</span></button>\n        <canvas class="coa-detail-view" width="1000" height="250"></canvas>\n    </div>\n</div>\n<div class="service-detail-view m-t-50">\n    <div class="">\n        <div class="m-l-40 font-18 font-bold m-b-25 m-t-25">\n            <span class="vendor-detail-header">Payee Payment Details</span>\n            <div class="dropdown show pull-right m-r-10">\n              <button class="btn btn-default dropdown-toggle" type="button" id="select-period-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n                ');
    
      __out.push(__sanitize(this.period_label));
    
      __out.push('\n              </button>\n              <div class="dropdown-menu" aria-labelledby="select-period-dropdown">\n                <button class="dropdown-item font-16 period-select-btn" type="button" data-period="yearly_vendor_detail">YTD</button>\n                <button class="dropdown-item font-16 period-select-btn" type="button" data-period="quarterly_vendor_detail">This Quarter</button>\n                <button class="dropdown-item font-16 period-select-btn" type="button" data-period="monthly_vendor_detail">This Month</button>\n              </div>\n            </div>\n        </div>\n    </div>\n    <canvas class="vendor-detail-view"></canvas>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}