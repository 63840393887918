module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!this.client_advanced_mode) {
        __out.push('\n<div class="document-address">\n  <div class="document-field-item document-field-name">Payment Account</div>\n');
      }
    
      __out.push('\n  <div class="document-field-item document-field-value string">\n    <div class="dropdown relative">\n      <a class="pointer dropdown-toggle display-table select-payment-account-link width-100-percent ');
    
      __out.push(__sanitize(this.payment_account ? 'in-black' : ''));
    
      __out.push('" id="dropdownPaymentAccountMenu');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n        ');
    
      if (this.payment_account) {
        __out.push('\n          ');
        if (this.payment_account.id === this.business.get('inter_company_settlement_payment_account_id')) {
          __out.push('\n            Advanced Settlement...\n          ');
        } else {
          __out.push('\n            ');
          __out.push(__sanitize(this.payment_account.name));
          __out.push('\n          ');
        }
        __out.push('\n        ');
      } else {
        __out.push('\n          <span class="in-grey-600">Select Payment Account</span>\n        ');
      }
    
      __out.push('\n      </a>\n      ');
    
      if (this.editable) {
        __out.push('\n      ');
        if (!this.client_advanced_mode && this.payment_account) {
          __out.push('\n      <a class="pointer pull-right in-grey-1000 deselect-payment-account-link">\n        <span class="fa fa-times-circle-o font-16"></span>\n      </a>\n      ');
        }
        __out.push('\n      <ul class="dropdown-menu payment-account-dropdown-menu" aria-labelledby="dropdownPaymentAccountMenu');
        __out.push(__sanitize(this.id));
        __out.push('">\n        <li><i class="fa fa-spinner fa-spin"></i></li>\n      </ul>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n');
    
      if (!this.client_advanced_mode) {
        __out.push('\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}