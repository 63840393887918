import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useBulkUpdateTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Dropdown from '@src/components/ui/dropdown';
import { AngleDownIcon } from '@src/components/utils/fa_icons';
import { CrossIcon, MoveItemIcon } from '@src/components/utils/icomoon';
import { useItemsSelector } from '@src/components/utils/items_selector';
import MutationStatus from '@src/components/utils/mutation_status';

const BulkActions = (): JSX.Element => {
  const { selected: transactions } = useItemsSelector<ITransactionServiceDocument>();
  const bulkUpdate = useBulkUpdateTransactionServiceDocuments();
  const bulkAction = useBulkActionModal({
    mutation: bulkUpdate,
  });
  const { runMutation } = bulkAction;
  const handleClick = useCallback(() => {
    const verifiedTransactions = transactions.filter((transaction) => (
      transaction.state === window.Docyt.Common.Constants.TRANSACTION_STATES.VERIFIED
      && !transaction.excluded
    ));
    if (verifiedTransactions.length !== 0) {
      toastr.error(window.Docyt.Common.Constants.Messages.TRANSACTION_EXCLUDE_ERROR_MSG, '');
    } else {
      const updateParams = transactions.map((transaction) => ({
        id:       transaction.id,
        excluded: !transaction.excluded,
      }));
      runMutation(updateParams);
    }
  }, [runMutation, transactions]);

  return (
    <>
      <MutationStatus mutation={ bulkUpdate } />
      <Dropdown
        className="bulk-action-container"
      >
        <Dropdown.ButtonToggle
          className="btn-blue-on-hover"
          disabled={ transactions.length === 0 }
          fontSize={ 15 }
          fontVariant="bold"
        >
          Bulk Actions
          <AngleDownIcon ml={ 5 } />
        </Dropdown.ButtonToggle>
        {
          transactions.length > 0 && (
            <Dropdown.Menu
              className="toggle-action-dropdown"
            >
              <ActionsDropdown.Action
                icon={ transactions[0].excluded ? (
                  <MoveItemIcon fontSize={ 18 } />
                ) : (
                  <CrossIcon fontSize={ 18 } />
                ) }
                name={ transactions[0].excluded ? 'Move to Transactions' : 'Exclude Transactions' }
                onClick={ handleClick }
              />
            </Dropdown.Menu>
          )
        }
      </Dropdown>
    </>
  );
};

export {
  BulkActions,
};
