import React from 'react';

import Select, {
  components,
  Props,
  StylesConfig,
} from 'react-select';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import {
  CommonSelectProps,
  ReactSelectControl,
  ReactSelectDropdownIndicator,
  ReactSelectStyles,
  WideMenuStyles,
} from './helpers';

interface IReactSelectInputProps<TOption, TIsMulti extends boolean = false> extends
  Props<TOption, TIsMulti>,
  CommonSelectProps
{}

const ReactSelectInput = <TOption, TIsMulti extends boolean = false>({
  components: componentsProp = {},
  hideIndicators,
  styles,
  wideMenu,
  ...props
}: IReactSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);

  return (
    <Select
      className={ classes }
      components={ {
        Control:             ReactSelectControl,
        DropdownIndicator:   ReactSelectDropdownIndicator,
        IndicatorsContainer: hideIndicators ? () => null : components.IndicatorsContainer,
        ...componentsProp,
      } }
      styles={ {
        ...ReactSelectStyles as StylesConfig<TOption, TIsMulti>,
        ...(wideMenu ? WideMenuStyles : {}) as StylesConfig<TOption, TIsMulti>,
        ...styles,
      } }
      { ...selectProps }
    />
  );
};

export {
  IReactSelectInputProps,
  ReactSelectInput as default,
};
