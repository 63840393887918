import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TID } from '@src/types/common';

import ForwardToBalanceSheetStatementsModal from '@src/components/common/forward_document/forward_to_balance_sheet_statements_modal/modal';

interface IForwardToBalanceSheetStatementProps {
  documentIds: TID[],
  show: boolean,
  onCancel: () => void,
  onContinue: () => void,
}

const ForwardToBalanceSheetDocument = ({
  documentIds,
  show,
  onCancel,
  onContinue,
}: IForwardToBalanceSheetStatementProps) => {
  const business = useBusinessContext();

  return (
    <ForwardToBalanceSheetStatementsModal
      business={ business }
      documentIds={ documentIds }
      show={ show }
      onCancel={ onCancel }
      onContinue={ onContinue }
    />
  );
};

export default ForwardToBalanceSheetDocument;
