import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface IFilterFieldWrapperProps extends React.ComponentPropsWithoutRef<'div'>, IUIStyleProps {
  children: React.ReactNode,
}

const FilterFieldWrapper = ({
  children,
  ...props
}: IFilterFieldWrapperProps): JSX.Element => {
  const [classes, divProps] = uiStyleProps(styles['filter-group'], props);

  return (
    <div className={ classes } { ...divProps }>
      { children }
    </div>
  );
};

const MemoizedFilterFieldWrapper = React.memo(FilterFieldWrapper);

export {
  IFilterFieldWrapperProps,
  MemoizedFilterFieldWrapper as default,
};
