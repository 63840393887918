import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';

const HelpModal = ({
  isOpen,
  onDone,
}: IUseModalProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
    >
      <Modal.Body>
        <Text as="div" className="m-t-30" fontSize={ 20 } fontVariant="bold">Standard ACH transfer processing time</Text>
        <Text as="div" className="m-t-10" fontSize={ 14 }>
          Docyt&apos;s standard transfer processing time is 4-6 business days.
          This means that it can take up to 6 business days for a transfer to be
          processed and for funds to be deposited into the recipient&apos;s account.
          This timeframe applies to transfers made between Docyt accounts, as
          well as transfers from a Docyt account to a recipient bank account.
        </Text>
        <Text as="div" className="m-t-20" fontSize={ 20 } fontVariant="bold">Next-day ACH transfer processing time</Text>
        <Text as="div" className="m-t-10" fontSize={ 14 }>
          If you need to transfer funds more quickly, you can opt for Dwolla&apos;s
          next-day transfer service. This service allows for transfers to be processed
          and deposited into the recipient&apos;s account in two to four business days.
          There is an additional fee for this service (To upgrade please contact your
          customer success specialist here.)
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={ onDone }
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useHelpModal = makeUseModal(HelpModal);

export {
  useHelpModal,
  HelpModal as default,
};
