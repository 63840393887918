import { TID } from '@src/types/common';

import { apiPut } from './helpers';

interface IAchDocumentConfirmMicroDepositParams {
  business_id: TID,
  amount1: string,
  amount2: string,
  workflow: string,
}

const achDocumentConfirmMicroDeposit = (
  params: IAchDocumentConfirmMicroDepositParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/accounts_payable_ach_documents/confirm_micro_deposits`,
    params);
};

export {
  IAchDocumentConfirmMicroDepositParams,
  achDocumentConfirmMicroDeposit,
};
