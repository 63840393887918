import React from 'react';

import { IClientBusiness } from '@src/types/clients';

import Table from '@src/components/ui/table';

import Actions from './actions';
import AccountManagers from './items/account_managers';
import AccountsPayableStatus from './items/accounts_payable_status';
import BusinessAdmins from './items/business_admins';
import ClientBusinessAvatar from './items/client_business_avatar';
import ClientBusinessInfo from './items/client_business_info';
import InboxStatus from './items/inbox_status';
import ReceiptBoxStatus from './items/receipt_box_status';
import ReconciliationStatus from './items/reconciliation_status';

import styles from './styles.module.scss';

interface IClientBusinessTableRowProps {
  clientBusiness: IClientBusiness,
}

const ClientBusinessTableRow = ({
  clientBusiness,
}: IClientBusinessTableRowProps) => {
  return (
    <Table.Row>
      <ClientBusinessAvatar clientBusiness={ clientBusiness } />
      <ClientBusinessInfo clientBusiness={ clientBusiness } />
      <InboxStatus
        clientBusinessId={ clientBusiness.clientBusinessId }
        status={ clientBusiness.inboxStatus }
      />
      <AccountsPayableStatus
        clientBusinessId={ clientBusiness.clientBusinessId }
        status={ clientBusiness.accountsPayableStatus }
      />
      <ReceiptBoxStatus
        clientBusinessId={ clientBusiness.clientBusinessId }
        status={ clientBusiness.receiptBoxStatus }
      />
      <ReconciliationStatus
        clientBusinessId={ clientBusiness.clientBusinessId }
        status={ clientBusiness.reconciliationStatus }
      />
      <BusinessAdmins businessAdmins={ clientBusiness.clientBusinessPartners } />
      <AccountManagers accountMangers={ clientBusiness.accountManagers } />
      <Table.Cell className={ styles['action-column'] }>
        <Actions clientBusiness={ clientBusiness } />
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(ClientBusinessTableRow);
