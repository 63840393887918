import React, { useMemo } from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useGetDashboards } from '@src/hooks/queries/dashboards/dashboards';
import { businessDashboardPath } from '@src/routes';

import { useCreateDashboardModal } from '@src/components/business_dashboards/create_dashboard/create_dashboard_modal';
import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';
import AddCircleIcon from '@src/components/utils/icomoon/add_circle';

interface IProps {
  businessId: number
}

const DashboardSidebarMenuItems = ({ businessId }: IProps) => {
  const createDashboard = useCreateDashboardModal();
  const { data: info } = useGetBusinessServicesInfo(businessId);
  const dashboardsQuery = useGetDashboards({ businessId });
  const dashboards = useMemo(() => {
    return dashboardsQuery.data?.collection || [];
  }, [dashboardsQuery.data?.collection]);

  return (
    <>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.DASHBOARD }
          />
        ) }
      >
        {dashboards.map((dashboard) => (
          <ServiceSidebar.Item
            key={ dashboard.id }
            href={ businessDashboardPath(businessId, dashboard.id) }
            title={ dashboard.name }
          />
        ))}
        <ServiceSidebar.Separator />
        <ServiceSidebar.Item
          href=""
          prefixIcon={ <AddCircleIcon fontSize={ 18 } /> }
          title="Add New Dashboard"
          onClick={ createDashboard.open }
        />
      </ServiceSidebar>
      <createDashboard.Component
        { ...createDashboard.props }
        businessId={ businessId }
        standardCategoryId={ info?.standardCategoryId }
      />
    </>
  );
};

export default DashboardSidebarMenuItems;
