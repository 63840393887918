import { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useGetBusinessLaborReportDepartment } from '@src/hooks/queries/report_service/report_datas';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IReportItem } from '@src/types/report_service/report_item';

import { TOption } from '@src/components/ui_v2/inputs';

export interface IEditMappingLaborQueryDataProps {
  business?: IBusiness;
  items: IReportItem[],
  report: IReport,
  serviceInfo: IBusinessServicesInfo | undefined,
  departments: TOption[] | undefined
}

export interface IEditMappingLaborProps {
  data: IEditMappingLaborQueryDataProps,
  parentId?: string,
  childId?: string,
  businessId?: TID,
}

export const useQueryData = (
  report: IReport,
  items: IReportItem[],
): IEditMappingLaborQueryDataProps => {
  const business = useBusinessContext();
  const { data: serviceInfo } = useGetBusinessServicesInfo(business.id);

  const departmentQuery = useGetBusinessLaborReportDepartment(
    {
      businessId: Number(business?.id),
      dimension_code: 'department'
    }
  );

  const departments = useMemo(() => {
    return departmentQuery?.data?.map((v: { label: string }) => {
      return {
        label: v.label,
        value: v.label,
        isDisabled: false
      }
    })
  }, [departmentQuery?.data]);

  
  return {
    business,
    items,
    report,
    serviceInfo,
    departments,
  };
};
