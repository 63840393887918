module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="authorized-payer-container">\n    <div class="add-authorized-payer">\n        <h2 class="font-24 font-semi-bold m-t-0"><a class="font-16 in-blue-950" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>Authorized Signatory</h2>\n        <hr class="width-100-percent" />\n        <p class="font-16 m-t-20 m-b-20">\n            Authorized signatory can issue payments using the business bank account on behalf of ');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push(' and has the power to manage permissions, enable and revoke access to pay by Docyt Check or ACH.\n        </p>\n        <div class="m-b-20">\n            <div class="checkbox checkbox-primary checkbox-circle p-l-0">\n                <input type="radio" id="radioBankAdmin" name="bank_admin_mode" class="pointer radio-bank-admin" value="1" checked>\n                <label for="radioBankAdmin">I am an authorized signatory on the bank account that will be used to process bill payments</label>\n            </div>\n            <div class="checkbox checkbox-primary checkbox-circle p-l-0">\n                <input type="radio" id="radioInvite" name="bank_admin_mode" class="pointer" value="0" ');
    
      __out.push(__sanitize(this.is_accounting_firm_user ? '' : 'disabled'));
    
      __out.push('>\n                <label for="radioInvite">Invite the authorized payer</label>\n            </div>\n        </div>\n        <button class="btn btn-blue width-150px font-16 font-semi-bold btn-activate-js">Continue</button>\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}