import React, { useMemo } from 'react';

import { TInboxServiceDocumentFlagState } from '@src/types/inbox_service_documents';

import { StarIcon, TStarIconType } from '@src/components/utils/fa_icons';
import { FlagRedIcon } from '@src/components/utils/icomoon';

interface IFlagState {
  Component: typeof StarIcon | typeof FlagRedIcon,
  flagState?: TInboxServiceDocumentFlagState,
  props: React.ComponentProps<typeof StarIcon> | Omit<React.ComponentProps<typeof FlagRedIcon>, 'type'>,
}

const FLAG_STATES: IFlagState[] = [
  {
    Component: StarIcon,
    flagState: undefined,
    props:     { type: 'outline' as TStarIconType },
  },
  {
    Component: StarIcon,
    flagState: 'blue_star',
    props:     { type: 'blue' as TStarIconType },
  },
  {
    Component: StarIcon,
    flagState: 'yellow_star',
    props:     { type: 'yellow' as TStarIconType },
  },
  {
    Component: StarIcon,
    flagState: 'red_star',
    props:     { type: 'red' as TStarIconType },
  },
  {
    Component: FlagRedIcon,
    flagState: 'red_flag',
    props:     {},
  },
];

const useInboxServiceDocumentFlag = (flagState?: TInboxServiceDocumentFlagState) => {
  return useMemo(() => {
    const flagIndex = flagState
      ? window.configData.inbox_service_document_flag_states.indexOf(flagState) + 1
      : 0;
    let nextFlagIndex = flagIndex + 1;
    if (nextFlagIndex + 1 > FLAG_STATES.length) nextFlagIndex = 0;

    return {
      flag:     FLAG_STATES[flagIndex],
      nextFlag: FLAG_STATES[nextFlagIndex],
    };
  }, [flagState]);
};

export {
  useInboxServiceDocumentFlag,
};
