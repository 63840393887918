import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';

import styles from '../styles.module.scss';



interface IFinancialInstitutionItemProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
  onSelectItem: (item: IFinancialInstitutionConnection) => void,
}

const FinancialInstitutionItem = ({
  financialInstitutionConnection,
  onSelectItem,
}: IFinancialInstitutionItemProps): JSX.Element => {
  return (
    <div
      className={ styles['financial-insitution-item'] }
      role="button"
      tabIndex={ -1 }
      onClick={ () => onSelectItem(financialInstitutionConnection) }
    >
      <div className="display-flex align-items-center">
        <FinancialInstitutionAvatar
          logo={ financialInstitutionConnection.financialInstitution.logo }
        />
        <span className="m-l-10">{ financialInstitutionConnection.financialInstitution.name }</span>
      </div>
      <span className={ styles['management-group-name'] }>
        { financialInstitutionConnection.managementGroup.name }
      </span>
    </div>
  );
};

export default FinancialInstitutionItem;
