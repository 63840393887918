import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TFilterData } from '@src/types/filter';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { FilterFieldWrapper, useFilterContext } from '@src/components/ui/filter';
import { SelectPickerInput } from '@src/components/ui/form';

import { TTransactionsFilterTransactionType } from './types';

interface ITransactionsFilterTypeSelectProps {
  className?: string,
  widthPercent?: IUIStyleProps['widthPercent'],
}

const AllValue = 'all';

const Options = [
  { value: AllValue, name: 'All Transactions' },
  {
    value: window.configData.transaction_types.ASSET_PURCHASE,
    name:  'Asset Withdrawal',
  },
  {
    value: window.configData.transaction_types.CHARGEBACK,
    name:  'Chargeback',
  },
  {
    value: window.configData.transaction_types.CUSTOMER_CLAIMS,
    name:  'Customer Claims',
  },
  {
    value: window.configData.transaction_types.EQUITY_INVESTMENT,
    name:  'Equity Investment',
  },
  {
    value: window.configData.transaction_types.EQUITY_WITHDRAWAL,
    name:  'Equity Withdrawal',
  },
  {
    value: window.configData.transaction_types.EXPENSE,
    name:  'Expense',
  },
  {
    value: window.configData.transaction_types.LIABILITY_PAYMENTS,
    name:  'Liability Payments',
  },
  {
    value: window.configData.transaction_types.LOAN_PAYMENTS,
    name:  'Loan Payments',
  },
  {
    value: window.configData.transaction_types.LOAN_PROCEEDS,
    name:  'Loan Proceeds',
  },
  {
    value: window.configData.transaction_types.OTHER_INCOME,
    name:  'Other Income',
  },
  {
    value: window.configData.transaction_types.OTHER_RECEIVABLES,
    name:  'Other Receivables',
  },
  {
    value: window.configData.transaction_types.PAYROLL,
    name:  'Payroll Charges',
  },
  {
    value: window.configData.transaction_types.REVENUE,
    name:  'Revenue',
  },
  {
    value: window.configData.transaction_types.REVENUE_REVERSAL,
    name:  'Revenue Reversal',
  },
  {
    value: window.configData.transaction_types.TRANSFER,
    name:  'Transfer',
  },
  {
    value: window.configData.transaction_types.UNVERIFIED,
    name:  'Uncategorized Transactions',
  },
  {
    value: window.configData.transaction_types.REFUND,
    name:  'Vendor Refunds',
  },
];

const TransactionsFilterTypeSelect = <TFilter extends TFilterData>({
  className,
  widthPercent,
}: ITransactionsFilterTypeSelectProps) => {
  const { update, data } = useFilterContext<TFilter>();

  const transactionType: TTransactionsFilterTransactionType =
    data?.transaction_type as TTransactionsFilterTransactionType;

  const selectValue = transactionType && transactionType[0];

  const handleTransactionTypeChange = useCallback((selectedValue?: string) => {
    let newTransactionType: TTransactionsFilterTransactionType;

    switch (selectedValue) {
      case 'all':
      case undefined:
        newTransactionType = undefined;
        break;
      default:
        newTransactionType = [selectedValue];
        break;
    }

    update({ transaction_type: newTransactionType } as unknown as TFilter);
  }, [update]);

  const classes = classNames(
    'transaction-filter-status',
    className,
    { filtered: data?.transaction_type },
  );

  return (
    <FilterFieldWrapper className={ classes } widthPercent={ widthPercent }>
      <SelectPickerInput
        options={ Options }
        title="Transaction Type"
        value={ selectValue }
        onChange={ handleTransactionTypeChange }
      />
    </FilterFieldWrapper>
  );
};

export default TransactionsFilterTypeSelect;
