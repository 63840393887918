import React from 'react';

import Filter from '@src/components/ui_v2/filter';

const stateOptions = [
  {
    label: 'Balanced',
    value: 'balanced',
  },
  {
    label: 'Unbalanced',
    value: 'unbalanced',
  },
  {
    label: 'Missing',
    value: 'missing',
  },
  {
    label: 'No Revenue Day',
    value: 'no_revenue_day',
  },
];

const RevenueCaptureFilter = () => {
  return (
    <Filter.TableContainer>
      <Filter.MonthField name="month" />
      <Filter.AmountField
        name="totalRevenue"
        valueProps={ { fixedDecimalScale: false, placeholder: 'Revenue Amount' } }
      />
      <Filter.SelectField
        name="status"
        options={ stateOptions }
        placeholder="Status"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(RevenueCaptureFilter);
