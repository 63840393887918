import React from 'react';

import { TID, TAmount } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import WithdrawAmountAction from './withdraw_amount_action';

interface IDocytWalletBalanceActionsProps {
  businessId: TID,
  dwollaBalance: TAmount,
  bankAccountName: string,
}

const DocytWalletBalanceActions = ({
  businessId,
  dwollaBalance,
  bankAccountName,
}: IDocytWalletBalanceActionsProps) => {
  return (
    <ActionsDropdown>
      <WithdrawAmountAction
        bankAccountName={ bankAccountName }
        businessId={ businessId }
        dwollaBalance={ dwollaBalance }
      />
    </ActionsDropdown>
  );
};

export default DocytWalletBalanceActions;
