import React, { FC, useCallback, useMemo } from 'react';

import { GroupBase, MultiValue, MultiValueProps, SingleValue, components } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetSelectOptions } from '@src/hooks/queries/select_options';
import { getSelectOptions } from '@src/requests/select_options';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { TResource } from '@src/types/select_options';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import {
  IIconSelectInputProps,
  TOption,
} from '@src/components/ui_v2/inputs';
import { AsyncPaginateReactSelectInput } from '@src/components/ui_v2/inputs/react_select/react_select_input';

interface Props<TFilter extends TFilterData> extends Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps {
  name: keyof TFilter;
  businessId?: TID;
  resource: TResource;
  label?: string;
  multiple?: boolean;
}

const formatTooltip = (option: TOption | TOption[] | undefined): JSX.Element | null => {
  if (!option) return null;

  const options = Array.isArray(option) ? option : [option];
  const text = options.map((it) => it.label).join(', ');
  return <span>{ text }</span>;
};

interface MoreSelectedBadgeProps {
  items: any[];
}

const MoreSelectedBadge: FC<MoreSelectedBadgeProps> = ({ items }) => {
  const style = {
    marginLeft:   'auto',
    background:   'rgba(0, 0, 0, 0);',
    borderRadius: '4px',
    fontSize:     '11px',
    padding:      '3px',
    order:        99,
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={ style } title={ title }>
      {label}
    </div>
  );
};

// eslint-disable-next-line max-len
const MultiValueComponent = <IsMulti extends boolean, Group extends GroupBase<TOption>>(props: MultiValueProps<TOption, IsMulti, Group>) => {
  const { getValue, index } = props;
  const maxToShow = 1;
  const overflow = getValue().slice(maxToShow).map((it) => it.label);

  if (index < maxToShow) return <components.MultiValue { ...props } />;
  if (index === maxToShow) return <MoreSelectedBadge items={ overflow } />;
  return null;
};

// eslint-disable-next-line max-len
const ResourceFilterField = <TFilter extends TFilterData>({ name, resource, businessId, placeholder, multiple, ...props }: Props<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string | null>(name);
  const [wrapperProps] = useFilterFieldProps(props);

  const handleChange = useCallback((item: SingleValue<TOption> | MultiValue<TOption>) => {
    const newValue = Array.isArray(item) ? item.map((it) => it.value).join(',') : (item as TOption)?.value;
    update(newValue);
  }, [update]);

  const collection = useGetSelectOptions({ businessId, selected: value || '', resource });

  const handleSource: LoadOptions<TOption, GroupBase<TOption>, any> =
    useCallback((query, options, { page }) => {
      return getSelectOptions({ businessId, q: query, resource })
        .then((data) => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          const newOptions = data.collection.map((it) => ({
            label: it.text,
            value: String(it.value),
          }));

          return {
            hasMore,
            options:    newOptions,
            additional: {
              page: page + 1,
            },
          };
        });
    }, [businessId, resource]);

  const selectedItem = useMemo(() => {
    if (!value) return undefined;

    const selectedOptions = collection.data?.collection || [];

    if (multiple) {
      return selectedOptions.map((it) => ({ label: it.text, value: String(it.value) }));
    }

    const selectedOption = selectedOptions[0];
    if (!selectedOption) return undefined;

    return {
      label: String(selectedOption.text),
      value: String(selectedOption.value),
    };
  }, [value, multiple, collection.data]);

  return (
    <Wrapper { ...wrapperProps } tooltip={ formatTooltip(selectedItem) }>
      <AsyncPaginateReactSelectInput
        defaultOptions
        additional={ {
          page: 1,
        } }
        components={ { MultiValue: MultiValueComponent } }
        debounceTimeout={ 300 }
        isLoading={ collection.isLoading }
        isMulti={ multiple }
        loadOptions={ handleSource }
        placeholder={ placeholder }
        styles={ {
          valueContainer: (provided) => ({ ...provided, paddingTop: 0, paddingBottom: 0 }),
        } }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(ResourceFilterField);
