import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IComparisonIconProps extends IIcomoonIconProps {
  variant?: 'active',
}

const ComparisonIcon = ({
  variant,
  ...props
}: IComparisonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ variant ? `comparison-${variant}` : 'comparison' }
      pathsNumber={ 2 }
    />
  );
};

export default ComparisonIcon;
