import React, { useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { TransitionStatus } from 'react-transition-group';

interface IModalDialogProps {
  children: React.ReactNode,
  className?: string,
  dialogClassName?: string,
  showClose?: boolean,
  closeIcon?: React.ReactNode,
  state: TransitionStatus,
  title?: React.ReactNode,
  captureClick?: boolean,
  onCancel?: () => void,
}

const ModalDialog = ({
  children,
  className,
  dialogClassName,
  showClose,
  closeIcon,
  state,
  title,
  captureClick = false,
  onCancel,
}: IModalDialogProps) => {
  const modalClasses = classNames('modal fade', { in: state === 'entered' }, className);
  const dialogClasses = classNames('modal-dialog', dialogClassName);
  const backClasses = classNames('modal-backdrop fade', { in: state === 'entered' });

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      // Remove the modal-open class from the body if no modal is open. 
      if(document.getElementsByClassName('modal fade in').length === 0){
        document.body.classList.remove('modal-open');
      }
    };
  });

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <>
      <div
        aria-labelledby="modal_title"
        className={ modalClasses }
        role="dialog"
        tabIndex={ -1 }
        onClick={ captureClick ? handleClick : undefined }
      >
        <div className={ dialogClasses }>
          <div className="modal-content">
            { title && (
              <div className="modal-header">
                <h1
                  className="modal-title font-28 font-bold"
                  id="modal_title"
                >
                  { title }
                </h1>
                {
                  showClose && (
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={ onCancel }>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )
                }
                { closeIcon && (
                  <button className="close-icon" type="button" onClick={ onCancel }>
                    { closeIcon }
                  </button>
                )}
              </div>
            )}
            { children }
          </div>
        </div>
      </div>
      <div className={ backClasses } />
    </>
  );
};

export {
  IModalDialogProps,
  ModalDialog as default,
};
