import React, { useCallback } from 'react';

import { useMoveToTrashTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { MoveItemIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import useRestoreTransactionModal from './delete_restore_action/action_modal';
import useUnverifyBankStatementModal from './unverify_bank_statement_modal';

interface ITransactionRestoreActionProps {
  businessId: TID,
  transaction: ITransactionServiceDocument,
}

const TransactionRestoreAction = ({
  businessId,
  transaction,
}: ITransactionRestoreActionProps): JSX.Element | null => {
  const restore = useMoveToTrashTransactionServiceDocument();
  const { mutate } = restore;

  const unverifyBankStatementModal = useUnverifyBankStatementModal();

  const handleError = useCallback((response) => {
    if (response?.response?.data?.errors[0]
      === window.Docyt.Common.Constants.Messages.BANKING_STATEMENT_VERIFIED_IN_MSG) {
      unverifyBankStatementModal.open();
    }
  }, [unverifyBankStatementModal]);

  const handleConfirm = useCallback(() => {
    mutate(
      { value: 'false', document_ids: [transaction.documentId] },
      { onError: handleError },
    );
  }, [mutate, transaction.documentId, handleError]);

  const restoreModal = useRestoreTransactionModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ restore } successMessage="Restored successfully" />
      <restoreModal.Component
        { ...restoreModal.props }
        actionType="restore"
        transaction={ transaction }
      />
      <unverifyBankStatementModal.Component
        { ...unverifyBankStatementModal.props }
        businessId={ businessId }
        transaction={ transaction }
      />
      <ActionsDropdown.Action
        icon={ <MoveItemIcon fontSize={ 24 } mr={ 18 } /> }
        name="Restore Transaction"
        onClick={ restoreModal.open }
      />
    </>
  );
};

export default TransactionRestoreAction;
