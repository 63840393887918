import React from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { IDocumentRequest } from '@src/types/document_requests';

import DocumentRequestInput from './link_document_request_input';

interface ILinkDocumentRequestFormValues {
  documentRequest: IDocumentRequest,
}

interface ILinkDocumentRequestFormProps {
  business: IBusiness
  formId?: string,
  onSubmit: SubmitHandler<ILinkDocumentRequestFormValues>,
}

const LinkDocumentRequestForm = ({
  business,
  formId,
  onSubmit,
}: ILinkDocumentRequestFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
  } = useForm<ILinkDocumentRequestFormValues>();

  return (
    <form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Controller
        control={ control }
        name="documentRequest"
        render={ ({ field, fieldState }) => (
          <DocumentRequestInput business={ business } field={ field } fieldState={ fieldState } />
        ) }
        rules={ { required: true } }
      />
    </form>
  );
};

export {
  ILinkDocumentRequestFormProps,
  ILinkDocumentRequestFormValues,
  LinkDocumentRequestForm as default,
};
