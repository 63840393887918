import React, { useCallback } from 'react';

import { documentPath } from '@src/routes';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { UnreconcileIcon } from '@src/components/utils/icomoon';

import useUnreconcileModal from '../unreconcile/unreconcile_modal';

type TReconcileDocumentsIncomesListActionsProps = {
  document: ITransactionServiceDocument,
  paymentProcessorName: string,
}

const ReconcileDocumentsIncomesListActions = ({
  document,
  paymentProcessorName,
}: TReconcileDocumentsIncomesListActionsProps) => {
  const unreconcile = useUnreconcileModal();
  const handleUnreconcile = useCallback(() => {
    unreconcile.open();
  }, [unreconcile]);
  return (
    <>
      {
        document.revenueReconciliationItem
        && (
        <unreconcile.Component
          { ...unreconcile.props }
          paymentProcessorName={ paymentProcessorName }
          revenueReconciliationId={ document.revenueReconciliationItem.revenueReconciliationId }
        />
        )
      }
      <ActionsDropdown.LinkAction
        href={ documentPath(document.documentId) }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Transaction Detail"
      />
      {
        document.revenueReconciliationItem
        && (
        <ActionsDropdown.Action
          icon={ <UnreconcileIcon fontSize={ 20 } /> }
          title="Unreconcile"
          onClick={ handleUnreconcile }
        />
        )
      }
    </>
  );
};

export default ReconcileDocumentsIncomesListActions;
