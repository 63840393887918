import React, { useState } from 'react';

import classNames from 'classnames';

import { rcBankAccountsPath, rcChartOfAccountsPath } from '@src/routes';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';
import { BankingAccountBlankIcon } from '@src/components/utils/img_icons';

import NewAccount from './new_account/new_account';

import styles from './styles.module.scss';

interface IBlankAccountsPageProps {
  businessId?: TID;
  type?: string;
}

const BlankAccountsPage = ({ businessId, type }: IBlankAccountsPageProps) => {
  const classCategory = classNames(
    'm-r-48',
    {'active': type === 'categories'}
  );

  const classAccount = classNames(
    'm-r-48',
    {'active': type === 'banking_accounts'}
  );

  const [isAddAccount, setIsAddAccount] = useState<boolean>(false);

  return (
    <>
      <div className={ styles['blank-account-header'] }>
        <p className={ styles.title }>{ type ? 'Chart of Accounts' : 'Accounts' }</p>

        { type && (
          <ul className="nav sub-header-menu font-semi-bold">
            <li className={ classCategory }>
              <a href={ rcChartOfAccountsPath(businessId as TID) }>All Chart of Accounts</a>
            </li>
            <li className={ classAccount }>
              <a href={ rcBankAccountsPath(businessId as TID) }>Bank & Credit Card Accounts</a>
            </li>
          </ul>
        ) }

        <div className={ styles['right-width'] } />
      </div>
      <div className={ styles['blank-account-body'] }>
        <BankingAccountBlankIcon fontSize={ 24 } mb={ 20 } />
        <span>You do not have any banking accounts assigned to this business yet.</span>
        <span>
          Get started by clicking on the button below to add accounts from
          { ' ' }
          your connected financial institutions.
        </span>
        <span>
          Or visit
          { ' ' }
          <a
            className="font-bold pointer"
            href={ `/financial_institution_connection_center/${businessId}` }
            role="button"
            tabIndex={ 0 }
          >
            Financial Connections
          </a>
          { ' ' }
          to add new financial institutions.
        </span>
        {
          isAddAccount ? (
            <NewAccount
              isBlankPage
              onCancel={ () => setIsAddAccount(false) }
              onDone={ () => setIsAddAccount(false) }
            />
          ) : (
            <Button
              bsColor="blue"
              onClick={ () => setIsAddAccount(true) }
            >
              <PlusIcon mr={ 10 } />
              Add Account
            </Button>
          )
        }
      </div>
    </>
  );
};

export default BlankAccountsPage;
