module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="bookkeeping-status-info-wrapper ');
    
      if (this.bookkeeping_status === 'in-progress') {
        __out.push(__sanitize(hidden));
      }
    
      __out.push('">\n  <div class="status-info-block">\n    <span class="info-subject">Bookkeeping status:</span>\n    <span class="info-description">Blocked by ');
    
      __out.push(__sanitize(this.blocked_by));
    
      __out.push(' on ');
    
      if (this.blocked_at) {
        __out.push(__sanitize(moment(this.blocked_at).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
      }
    
      __out.push('</span>\n  </div>\n  <div class="status-info-block">\n      <span class="info-subject">Description:</span>\n      <span class="info-description blocked-explanation-js">');
    
      __out.push(__sanitize(this.blocked_description));
    
      __out.push('</span>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}