import React from 'react';
import styles from './styles.module.scss';

interface IIFullPageSideMenuPropsProps {
  children: React.ReactNode;
}

const IFullPageSideMenuProps = ({
  children,
}: IIFullPageSideMenuPropsProps) => {

  return (
    <div className={ styles['full-page-sidemenu'] } id="sidemenu-region">
      { children }
    </div>
  );
};

export default React.memo(IFullPageSideMenuProps);
