// Create a constructor that uses `Promise` as its super and does the `super` call
// via `Reflect.construct`
const DocytPromise = function(executor) {
  return Reflect.construct(Promise, [executor], DocytPromise);
};
Object.setPrototypeOf(DocytPromise, Promise);
// Create the prototype, add methods to it
DocytPromise.prototype = Object.create(Promise.prototype);
DocytPromise.prototype.constructor = DocytPromise;
DocytPromise.prototype.setFromMultipleRequest = function() {
  this.from_multiple_request = true;
};
DocytPromise.prototype.setOptions = function(request_options, arrival_options, xhr) {
  this.request_options = request_options;
  this.arrival_options = arrival_options;
  this.xhr = xhr;
};
DocytPromise.prototype.failureHandler = function(response){
  console.error("Error occurred: ", response);
  if(response instanceof Docyt.Common.DocytRequestError && this.failure_callback){
    this.failure_callback(response);
  }
};
DocytPromise.prototype.catch = function(failure_callback) {
  this.failure_callback = failure_callback;
  const failureHandler = this.failureHandler.bind(this);
  return Promise.prototype.catch.apply(this, [failureHandler]);
};

DocytPromise.prototype.done = DocytPromise.prototype.then;
DocytPromise.prototype.success = DocytPromise.prototype.then;
DocytPromise.prototype.complete = DocytPromise.prototype.then;
DocytPromise.prototype.error = DocytPromise.prototype.catch;

/*** EXPORTS FROM exports-loader ***/
export {
  DocytPromise
};
