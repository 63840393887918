import React, { useCallback } from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';

import { TSubOption, TOption } from '../types';

interface DropdownInputItemProps {
  currentValue: TOption | null,
  option: TSubOption,
  onSelect: (options: TOption) => void,
}

const DropdownInputItem = ({
  currentValue,
  option,
  onSelect,
}: DropdownInputItemProps) => {
  const handleSelect = useCallback(() => {
    onSelect(option as TOption);
  }, [onSelect, option]);

  if (!option.children) {
    return (
      <Dropdown.Item
        isSelected={ currentValue?.value === option.value }
        onSelect={ handleSelect }
      >
        <Dropdown.ItemLabel>{ option.label }</Dropdown.ItemLabel>
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown.Submenu
      isSelected={ currentValue?.value === option.value }
      title={ option.label }
      onSelect={ handleSelect }
    >
      { option.children.map((opt) => (
        <DropdownInputItem
          key={ opt.value }
          currentValue={ currentValue }
          option={ opt }
          onSelect={ onSelect }
        />
      )) }
    </Dropdown.Submenu>
  );
};

export default DropdownInputItem;
