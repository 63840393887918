import React from 'react';

import { MultiEntityEmptyIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

const PortfolioBlankPage = () => {
  return (
    <div className={ styles['list-empty-container'] }>
      <div className={ styles['empty-container'] }>
        <MultiEntityEmptyIcon className={ styles['empty-img'] } />
        <p className={ styles['empty-txt'] }>
          Add a Portfolio Report and have better insights
          <br />
          of your businesses’ performance.
        </p>
      </div>
    </div>
  );
};

export default React.memo(PortfolioBlankPage);
