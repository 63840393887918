import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { SubmitHandler, useForm, useController } from 'react-hook-form';

import { TID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import BusinessChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';
import Form from '@src/components/ui/form';

import { IMarkAsPaidFormValues, PaybyAdvancedSettlementValidation } from '../schema';

import styles from '../../styles.module.scss';

interface IPayByAdvancedSettelmentFormProps {
  formId?: string,
  businessId: TID,
  totalAmount?: number,
  onSubmit: SubmitHandler<IMarkAsPaidFormValues>,
}

const PayByAdvancedSettelmentForm = ({
  formId,
  businessId,
  totalAmount,
  onSubmit,
}: IPayByAdvancedSettelmentFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<IMarkAsPaidFormValues>({
    defaultValues: {
      estimatedDate: formatDate(new Date(), API_DATE_FORMAT),
      amount:        `US$: ${totalAmount}`,
    },
    resolver: yupResolver(PaybyAdvancedSettlementValidation),
  });

  const dateController = useController({ name: 'estimatedDate', control });
  const balanceSheetAccountController = useController({ name: 'balanceChartOfAccountId', control });

  return (
    <>
      <label className="m-b-30">
        Advanced settlement lets you settle an invoice or receipt
        { ' ' }
        without paying by moving the amount to a balance sheet account
      </label>
      <Form className={ styles['buld-mark-as-paid-form'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
        <Form.DateField
          error={ errors.estimatedDate?.message }
          label="Date"
          { ...dateController.field }
        />
        <BusinessChartOfAccountField
          businessId={ businessId }
          error={ errors.balanceChartOfAccountId?.message }
          label="Balance Sheet Account"
          labelClass="width-38-percent"
          queryParams={ { searchAccType: 'balance_sheet' } }
          simple={ false }
          { ...omit(balanceSheetAccountController.field, 'ref') }
        />
        <Form.TextField
          disabled
          className="background-white"
          label="Amount"
          { ...register('amount') }
        />
        <Form.TextField
          label="Memo"
          placeholder="Enter Memo"
          { ...register('memo') }
        />
      </Form>
    </>
  );
};

export {
  IPayByAdvancedSettelmentFormProps,
  PayByAdvancedSettelmentForm as default,
};
