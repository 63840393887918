module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n      <div class="modal-content">\n        <div class="modal-header">\n          <h4 class="modal-title">Edit Books Lock Date</h4>\n        </div>\n        <div class="modal-body">\n          <div class="clearfix">\n            <div class="row m-t-20 m-b-30">\n              <label class="col-md-6 p-t-8">Books Lock Date</label>\n              <div class="col-md-6">\n                <input class="form-control start-date-input text-center" type="text" value="');
    
      __out.push(__sanitize(this.startDate));
    
      __out.push('">\n              </div>\n            </div>\n          </div>\n          <div class="clearfix">\n            <p class="text-left in-gray-important">Docyt will only surface the transactions after this date. Invoices or receipts prior to this date cannot be paid, approved, or categorized.</p>\n          </div>\n          ');
    
      if (this.isPrioDate) {
        __out.push('\n            <div class="clearfix">\n              <p class="border-gray"></p>\n              <p class="text-left m-t-10">Since the entered date is earlier than the current Books Lock Date, <span class="closing-date-highlight-text">Docyt AI Auto Pilot setting will be turned off</span> and Docyt will stop automatically categorizing transactions and pushing them to General Ledger.</p>\n              <input class="pointer auto-verification-check" type="checkbox">\n              <label class="pointer"></label>\n              <span class="m-l-10">Keep Auto Pilot setting ON</span>\n            </div>\n          ');
      }
    
      __out.push('\n          <p class="main-input__hint in-red-400 start-date-error text-center"></p>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-info btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}