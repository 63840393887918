import React, { useMemo } from 'react';

import sumBy from 'lodash/sumBy';

import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';

import styles from './styles.module.scss';

interface IBulkActionResultProps {
  status: IBulkActionStatus<any, any>,
}

const BulkActionResult = ({
  status,
}: IBulkActionResultProps) => {
  const successCount = useMemo(() => {
    return sumBy(status.responses, (response) => {
      return response[1].service_documents
        ? response[1].service_documents.length
        : response[1].accounts_payable_service_documents.length;
    });
  }, [status.responses]);

  const failedCount = useMemo(() => {
    if (status.errors.length === 0) {
      return 0;
    }
    return sumBy(
      status.errors,
      (e) => e[0]?.attachmentServiceDocuments?.length || e[0]?.serviceDocumentIds?.length,
    );
  }, [status.errors]);

  const errorList = useMemo(() => {
    if (status.errors.length === 0) {
      return [];
    }
    const errorObj: { invoiceIds: any; msg: string; }[] = [];
    status.errors.forEach((error) => {
      const matchedError = errorObj.find((obj) => obj.msg === error[1]);
      const invoiceIds = error[0]?.attachmentServiceDocuments || error[0]?.serviceDocumentIds;
      if (matchedError) {
        matchedError.invoiceIds =
          [...matchedError.invoiceIds, ...invoiceIds];
      } else {
        errorObj.push({ invoiceIds, msg: error[1] });
      }
    });
    return errorObj.map((error) => {
      return `${error.invoiceIds.length} ${error.msg}`;
    });
  }, [status.errors]);

  return (
    <div className={ styles.result }>
      <p className="p-b-0">
        <span className={ styles['success-count'] }>{ successCount }</span>
        {' '}
        { successCount === 1 ? 'invoices was' : 'invoices were' }
        {' '}
        paid successfully
      </p>
      {
        status.failedCount > 0 && (
          <p className="p-b-0">
            <span className={ styles['failed-count'] }>{ failedCount }</span>
            {' '}
            { failedCount === 1 ? 'invoice has' : 'invoices have' }
            {' '}
            failed with the following errors:
            <ul>
              { errorList.map((error, i) => (
                // There is no anything that may be used as key
                // eslint-disable-next-line react/no-array-index-key
                <li key={ i }>{ error }</li>
              )) }
            </ul>
          </p>
        )
      }
    </div>
  );
};

export default React.memo(BulkActionResult);
