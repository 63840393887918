import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITableRowProps extends React.ComponentPropsWithoutRef<'tr'>, IUIStyleProps {
}

/**
 * @visibleName Table.Row
 */
const TableRow = ({
  children,
  ...props
}: ITableRowProps) => {
  const [classes, tdProps] = uiStyleProps(
    undefined,
    props,
  );

  return (
    <tr
      className={ classes }
      { ...tdProps }
    >
      { children }
    </tr>
  );
};

export default TableRow;
