import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const VendorW9Icon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="w9-info-vendor"
      pathsNumber={ 5 }
    />
  );
};

export default VendorW9Icon;
