module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <button class=\'close cancel\' type=\'button\'>\n                    <span>&times;</span>\n                </button>\n                <h4 class="modal-title font-28 font-bold">Update Password</h4>\n            </div>\n            <div class="modal-body web-password-content">\n                ');
    
      if (this.current_page === 'current_password') {
        __out.push('\n                <div class="current-password-wrapper text-center main-container-js">\n                    <p class="font-20 p-b-20">Please enter your current password</p>\n                    <input type="password" class="input-wrapper font-20 text-center current-password-js">\n                    <p class="m-t-5 m-l-100 text-left in-red-400 invalid-current-password-js"><i class="fa fa-warning"></i> Invalid Password</p>\n                </div>\n                ');
      }
    
      __out.push('\n                ');
    
      if (this.current_page === 'secret_code') {
        __out.push('\n                <div class="security-code-wrapper text-center main-container-js">\n                    <h2 class="font-20 font-bold p-b-10">Enter Security Code</h2>\n                    <p class="font-16 p-b-10">\n                        Enter the security code we ');
        __out.push(__sanitize(this.sentText));
        __out.push('\n                        <br>\n                        you at ');
        __out.push(__sanitize(this.secretCodeTo));
        __out.push('\n                    </p>\n                    <div class="input-group">\n                        <span class="input-group-addon"><i class="fa fa-key"></i></span>\n                        <input type="text" class="form-control font-16 secret-code-input" placeholder="Security Code">\n                    </div>\n                    <p class="m-t-5 m-l-100 text-left in-red-400 invalid-secret-code-js"><i class="fa fa-warning"></i> Invalid Secret Code</p>\n                    <div class="text-right m-r-75">\n                        <a class="m-t-10 pointer resend-email-js">Resend Code</a>\n                    </div>\n                </div>\n                ');
      }
    
      __out.push('\n                ');
    
      if (this.current_page === 'change_password') {
        __out.push('\n                <div class="new-password-wrapper text-center main-container-js">\n                    <p class="font-20 p-b-20">Please enter new password</p>\n                    <div class="input-group p-b-20">\n                        <span class="input-group-addon"><i class="fa fa-key"></i></span>\n                        <input type="password" class="form-control font-16 new-password-input" placeholder="New Password">\n                    </div>\n                    <div class="input-group">\n                        <span class="input-group-addon"><i class="fa fa-key"></i></span>\n                        <input type="password" class="form-control font-16 confirm-password-input" placeholder="Confirm New Password">\n                    </div>\n                    <p class="m-t-5 m-l-100 text-left in-red-400 invalid-new-password-js"><i class="fa fa-warning"></i> Invalid Secret Code</p>\n                </div>\n                ');
      }
    
      __out.push('\n            </div>\n            <div class="modal-footer">\n                <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n                <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Continue</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}