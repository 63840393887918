import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const VerifyManualIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="verify-manual-combine"
      pathsNumber={ 4 }
    />
  );
};

export default VerifyManualIcon;
