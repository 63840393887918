import React, { useCallback } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { PaidIcon } from '@src/components/utils/icomoon';

interface IMarkAsPaidBankActionProps {
  invoiceIds: TID[],
}

const MarkAsPaidBankAction = ({
  invoiceIds,
}: IMarkAsPaidBankActionProps) => {
  const handleSelect = useCallback(() => {
    window.Docyt.vent.trigger('ap_service:document:mark_as_paid:bank', invoiceIds);
  }, [invoiceIds]);

  return (
    <ActionsDropdown.Action
      icon={ <PaidIcon fontSize={ 18 } /> }
      name="Mark as Paid with Bank Account"
      onClick={ handleSelect }
    />
  );
};

export default MarkAsPaidBankAction;
