module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-table-cell p-l-10 p-r-10 p-t-10 p-b-10 first-notification-column">\n  <div class="middle-avatar-wrapper border-gray">\n  ');
    
      if (this.notify_creator) {
        __out.push('\n    ');
        if (this.avatarUrl) {
          __out.push('\n        <img src="');
          __out.push(__sanitize(this.avatarUrl));
          __out.push('">\n    ');
        } else {
          __out.push('\n        <i class="icon icon-smile in-blue-400 font-48"></i>\n    ');
        }
        __out.push('\n  ');
      } else {
        __out.push('\n    <img src="');
        __out.push(configData.icons.BOT);
        __out.push('"></img>\n  ');
      }
    
      __out.push('\n  </div>\n</div>\n<div class="display-table-cell relative vertical-align-middle p-l-10 p-r-10 second-notification-column">\n    <h5 class="font-14 m-t-0 m-b-0 text-left ');
    
      __out.push(__sanitize(this.unread ? 'font-bold' : ''));
    
      __out.push('">\n       ');
    
      __out.push(__sanitize(this.message));
    
      __out.push('\n    </h5>\n    <h6 class="font-12 in-grey-900 m-t-5 m-b-0 text-left">\n      ');
    
      if (this.notify_creator) {
        __out.push('\n        ');
        __out.push(__sanitize(this.notify_creator.full_name));
        __out.push('\n      ');
      } else {
        __out.push('\n        Docyt Bot\n      ');
      }
    
      __out.push('\n    </h6>\n    <div class=\'notification-time-wrapper notification-date\'>\n      <p class="font-12 in-grey-900 m-b-0">');
    
      __out.push(__sanitize($.timeago(this.created_at)));
    
      __out.push('</p>\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}