import React from 'react';

import { TDateRange } from '@src/types/common';
import { API_DATE_FORMAT, formatDate, getUTCTimezone } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import { AppLink } from '@src/components/ui_v2/buttons';
import { LaunchIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IFullViewStateBanner {
  lastUpdatedDate: string,
  reportUrl: string,
  dateRange: TDateRange,
}

const FullViewStateBanner = ({
  lastUpdatedDate,
  reportUrl,
  dateRange,
}: IFullViewStateBanner): JSX.Element => {
  const launchUrl = () => {
    const startDate = formatDate(getUTCTimezone(new Date(dateRange.startDate!)), API_DATE_FORMAT);
    const endDate = formatDate(getUTCTimezone(new Date(dateRange.endDate!)), API_DATE_FORMAT);

    return `${reportUrl}?start_date=${startDate}&end_date=${endDate}`;
  };

  return (
    <div className={ styles['widget-status-panel'] }>
      <Text>
        { `Data based on the report last updated on: ${lastUpdatedDate}` }
      </Text>
      <AppLink newWindow href={ launchUrl() }>
        View Report
        <LaunchIcon ml={ 4 } />
      </AppLink>
    </div>
  );
};

export default FullViewStateBanner;
