import { ITransactionServiceDocument, IDuplicateOriginTransaction } from '@src/types/transaction_service_documents';

const getTransactionType = (
  transaction: ITransactionServiceDocument | IDuplicateOriginTransaction,
): string => {
  if (transaction.excluded) return 'Excluded';
  if (transaction.state !== 'verified') return 'Uncategorized';

  switch (transaction.transactionType) {
    case window.configData.transaction_types.EXPENSE:
    case window.configData.transaction_types.CHECK:
      return 'Expense';
    case window.configData.transaction_types.TRANSFER:
      return 'Transfer';
    case window.configData.transaction_types.PAYROLL:
      return 'Payroll Charges';
    case window.configData.transaction_types.CHARGEBACK:
      return 'Chargeback';
    case window.configData.transaction_types.REVENUE_REVERSAL:
      return 'Revenue Reversal';
    case window.configData.transaction_types.CUSTOMER_CLAIMS:
      return 'Customer Claims';
    case window.configData.transaction_types.EQUITY_WITHDRAWAL:
      return 'Equity Withdrawal';
    case window.configData.transaction_types.ASSET_PURCHASE:
      return 'Asset Withdrawal';
    case window.configData.transaction_types.LIABILITY_PAYMENTS:
      return 'Liability Payments';
    case window.configData.transaction_types.LOAN_PAYMENTS:
      return 'Loan Payments';
    case window.configData.transaction_types.REVENUE:
      return 'Revenue';
    case window.configData.transaction_types.REFUND:
      return 'Vendor Refunds';
    case window.configData.transaction_types.OTHER_INCOME:
      return 'Other Income';
    case window.configData.transaction_types.LOAN_PROCEEDS:
      return 'Loan Proceeds';
    case window.configData.transaction_types.EQUITY_INVESTMENT:
      return 'Equity Investment';
    case window.configData.transaction_types.OTHER_RECEIVABLES:
      return 'Other Receivables';
    default:
      return 'Uncategorized';
  }
};

const getTransactionTypeClass = (
  transaction: ITransactionServiceDocument | IDuplicateOriginTransaction,
) => {
  if (transaction.excluded || transaction.state !== 'verified') {
    return 'transaction-type-badge-dark';
  }

  if (!transaction.transactionType) return 'transaction-type-badge-dark';

  if (
    [
      window.configData.transaction_types.EXPENSE,
      window.configData.transaction_types.CHECK,
      window.configData.transaction_types.TRANSFER,
      window.configData.transaction_types.PAYROLL,
      window.configData.transaction_types.LIABILITY_PAYMENTS,
      window.configData.transaction_types.LOAN_PAYMENTS,
    ].indexOf(transaction.transactionType) >= 0 && Number(transaction.amount) < 0) {
    return 'transaction-type-badge-info';
  }

  return 'transaction-type-badge-success';
};

const reconciliationCenterModuleUrl = (transaction: ITransactionServiceDocument) => {
  if (transaction.state !== 'verified') return 'transactions?type=unverified';

  switch (transaction.transactionType) {
    case window.configData.transaction_types.EXPENSE:
    case window.configData.transaction_types.REFUND:
    case window.configData.transaction_types.CHECK:
    case window.configData.transaction_types.CUSTOMER_CLAIMS:
      return 'expenses';
    case window.configData.transaction_types.REVENUE:
    case window.configData.transaction_types.REVENUE_REVERSAL:
    case window.configData.transaction_types.INVESTMENT:
    case window.configData.transaction_types.OTHER_INCOME:
      return 'revenue';
    case window.configData.transaction_types.TRANSFER:
      return 'bank_transfers';
    case window.configData.transaction_types.PAYROLL:
      return 'payroll';
    case window.configData.transaction_types.EQUITY_INVESTMENT:
    case window.configData.transaction_types.EQUITY_WITHDRAWAL:
    case window.configData.transaction_types.LOAN_PROCEEDS:
    case window.configData.transaction_types.OTHER_RECEIVABLES:
    case window.configData.transaction_types.ASSET_PURCHASE:
    case window.configData.transaction_types.LIABILITY_PAYMENTS:
      return 'equity';
    case window.configData.transaction_types.LOAN_PAYMENTS:
      return 'loan_payments';
    case window.configData.transaction_types.CHARGEBACK:
      return transaction.chargebackStatus === 'unresolved'
        ? 'chargeback?type=outstanding'
        : 'chargeback?type=resolved';
    default:
      return 'transactions';
  }
};

export {
  getTransactionType,
  getTransactionTypeClass,
  reconciliationCenterModuleUrl,
};
