import React, { useMemo } from 'react';

import { IBusinessSimple } from '@src/types/businesses';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { TViewOption } from '@src/types/dashboards/widgets';

import { getDataSetColor, getDataSetLabel } from '../charts/chart_helpers';

import styles from './styles.module.scss';

export type TFooterInfo = {
  id: number,
  label?: string,
  backgroundColor?: string,
}

interface IFullViewFooterProps {
  businesses?: IBusinessSimple[],
  viewOption?: TViewOption,
  dataSets: IDataSet[],
}

const FullViewFooter = ({
  businesses,
  viewOption,
  dataSets,
}: IFullViewFooterProps) => {
  const footerInfos = useMemo(() => {
    const resultData: TFooterInfo[] = [];
    if (viewOption) {
      viewOption.dataSets.forEach((dataSetId, index) => {
        const dataSet = dataSets.find((data) => data.identifier === dataSetId);
        resultData.push({
          id:              index,
          label:           dataSet?.label,
          backgroundColor: dataSet?.color,
        });
      });
    } else if (businesses) {
      dataSets.forEach((dataSet, index) => {
        resultData.push({
          id:              index,
          label:           getDataSetLabel(dataSet, businesses),
          backgroundColor: getDataSetColor(index),
        });
      });
    }

    return resultData;
  }, [viewOption, dataSets, businesses]);

  return (
    <ul className={ styles['full-view-footer'] }>
      {
        footerInfos.map((info) => (
          <li key={ info.id }>
            <span
              style={ { background: `${info.backgroundColor}` } }
            />
            <p>{ info.label }</p>
          </li>
        ))
      }
    </ul>
  );
};

export default FullViewFooter;
