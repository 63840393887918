import React from 'react';

import { useUniqueDomId } from '@src/hooks/dom';

import Body from './body';
import BottomModal, { IBottomModalProps } from './bottom_modal';
import SubmitFormFooter, { IBottomModalSubmitFormFooterProps } from './submit_form_footer';

interface IBottomFormModalChildrenParams {
  formId: string,
}

interface IBottomFormModalProps extends Omit<IBottomModalSubmitFormFooterProps, 'formId' | 'children'> {
  children: (params: IBottomFormModalChildrenParams) => React.ReactNode,
  className?: IBottomModalProps['className'],
  dialogClassName?: IBottomModalProps['dialogClassName'],
  footer?: IBottomModalSubmitFormFooterProps['children'],
  formId?: string;
  isOpen?: IBottomModalProps['isOpen'],
  title: IBottomModalProps['title'],
}

const BottomFormModal = ({
  children,
  className,
  dialogClassName,
  footer,
  formId,
  isOpen,
  title,
  ...footerProps
}: IBottomFormModalProps) => {
  const uniqueFormId = useUniqueDomId('modal_form_');

  return (
    <BottomModal
      className={ className }
      dialogClassName={ dialogClassName }
      isOpen={ isOpen }
      title={ title }
      { ...footerProps }
    >
      <Body>
        { isOpen && children({ formId: formId ?? uniqueFormId }) }
      </Body>
      <SubmitFormFooter
        formId={ formId ?? uniqueFormId }
        { ...footerProps }
      >
        { footer }
      </SubmitFormFooter>
    </BottomModal>
  );
};

export default BottomFormModal;
