import React from 'react';

import { IFilter, TFilterData } from '@src/types/filter';

import AmountField from './amount_filter_field';
import CheckboxField from './checkbox_filter_field';
import Container from './container';
import DateField from './date_filter_field';
import IconSelectField from './icon_select_filter_field';
import Provider, { FilterProviderProps } from './provider';
import ResetButton from './reset_button';
import SelectField from './select_filter_field';
import SelectPickerField from './selectpicker_filter_field';
import TextField from './text_filter_field';
import YearField from './year_filter_field';

interface IFilterProps<TFilter extends TFilterData> extends
  React.ComponentPropsWithoutRef<typeof Container>,
  FilterProviderProps<TFilter>
{
  children: React.ReactNode,
  filter: IFilter<TFilter>,
}

const Filter = <TFilter extends TFilterData>({
  children,
  filter,
  ...containerProps
}: IFilterProps<TFilter>): JSX.Element => {
  return (
    <Container { ...containerProps }>
      <Provider filter={ filter }>
        { children }
      </Provider>
    </Container>
  );
};

Filter.AmountField = AmountField;
Filter.CheckboxField = CheckboxField;
Filter.Container = Container;
Filter.DateField = DateField;
Filter.IconSelectField = IconSelectField;
Filter.Provider = Provider;
Filter.ResetButton = ResetButton;
Filter.SelectField = SelectField;
Filter.SelectPickerField = SelectPickerField;
Filter.TextField = TextField;
Filter.YearField = YearField;

export default Filter;
