import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateRevenueSystem } from '@src/hooks/queries/revenue_systems';
import { IRevenueService } from '@src/types/revenue_services';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAddRevenueSystemValues } from './schema';

interface IAddRevenueSystemModalProps extends IUseModalProps {
  revenueService: IRevenueService,
}

const AddRevenueSystemModal = ({
  isOpen,
  onDone,
  revenueService,
  ...props
}: IAddRevenueSystemModalProps) => {
  const createRevenueSystem = useCreateRevenueSystem();

  const { mutate } = createRevenueSystem;
  const handleSubmit = useCallback((data: IAddRevenueSystemValues) => {
    mutate({
      revenueServiceId: revenueService.id,
      data,
    }, {
      onSuccess: onDone,
    });
  }, [mutate, revenueService.id, onDone]);

  return (
    <Modal.Form
      cancelTitle="Back"
      proceedTitle="Add"
      show={ isOpen }
      title="Add Revenue System"
      { ...props }
    >
      { ({ formId }) => (
        <>
          <MutationStatus mutation={ createRevenueSystem } />
          <Form
            businessId={ revenueService.businessId }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        </>
      ) }
    </Modal.Form>
  );
};

const useAddRevenueSystemModal = makeUseModal(AddRevenueSystemModal);

export {
  useAddRevenueSystemModal,
  AddRevenueSystemModal as default,
};
