import React, { useState, useCallback, useMemo } from 'react';

import { components, GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getManagementGroups } from '@src/requests/management_groups';
import { TFilterData } from '@src/types/filter';
import { IManagementGroup } from '@src/types/management_groups';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { FilterFieldWrapper, useFilterField } from '@src/components/ui/filter';
import { useFilterFieldProps } from '@src/components/ui/filter/utils';
import { AsyncSelectPaginateInput } from '@src/components/ui/form';

interface IBusinessFilterSelectProps {
  managementGroup?: IManagementGroup,
  className?: string,
  widthPercent?: IUIStyleProps['widthPercent'],
}

const BlankOption: IManagementGroup = { id: 0, name: 'All', businesses: [] };

const formatOptionLabel = (item: IManagementGroup): JSX.Element => {
  return (
    <span className="m-t-2 m-l-10">{ item.name }</span>
  );
};

const getOptionLabel = (type: IManagementGroup) => type.name;
const getOptionValue = (type: IManagementGroup) => String(type.id);

const SingleValue = (props: any) => {
  const { data } = props;

  return (
    <components.SingleValue { ...props }>
      { data.id === -1 ? '' : data.name }
    </components.SingleValue>
  );
};

const BusinessFilterSelect = <TFilter extends TFilterData>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  managementGroup,
  className,
  widthPercent,
  ...props
}: IBusinessFilterSelectProps) => {
  const { update, value } = useFilterField<TFilter, string>('management_group_id');
  const inputProps = useFilterFieldProps(props);

  const [lastOptions, setLastOptions] = useState<IManagementGroup[]>([]);

  const handleSource: LoadOptions<IManagementGroup, GroupBase<IManagementGroup>, any> =
    useCallback((query, options, { page }) => {
      return getManagementGroups({ page, filter: { name: query } })
        .then((data) => {
          const hasMore = options.length + data.collection.length < data.meta.totalCount;

          setLastOptions([...options as IManagementGroup[], ...data.collection]);
          return {
            options:    [BlankOption, ...data.collection],
            hasMore,
            additional: {
              page: page + 1,
            },
          };
        });
    }, []);

  const selectedItem = useMemo(() => {
    if (!value || value === '0') return BlankOption;

    return lastOptions.find((i) => String(i.id) === value);
  }, [lastOptions, value]);

  const handleChange = useCallback((item) => {
    if (!item) return;
    const filterId:string | undefined = item.id !== 0 ? String(item.id) : undefined;
    update(filterId);
  }, [update]);

  return (
    <FilterFieldWrapper className={ className } widthPercent={ widthPercent }>
      <AsyncSelectPaginateInput
        { ...inputProps }
        defaultOptions
        hideIndicators
        isClearable
        additional={ {
          page: 1,
        } }
        components={ {
          SingleValue,
        } }
        debounceTimeout={ 300 }
        formatOptionLabel={ formatOptionLabel }
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        id="management_group_select_input"
        loadOptions={ handleSource }
        value={ selectedItem }
        onChange={ handleChange }
      />
    </FilterFieldWrapper>
  );
};

export default BusinessFilterSelect;
