import isArray from 'lodash/isArray';
import { AtomEffect, atomFamily, DefaultValue, selectorFamily } from 'recoil';

import { Family } from '@src/constants/atom_keys';
import { tableHiddenColumnsStorageKey } from '@src/constants/storage_keys';
import { TSection } from '@src/types/common';

import { TColumn, TModel } from './types';

type TColumnsState = TColumn<TModel, string>[];

const restoreHiddenColumnsFromStorage = (section: TSection): string[] | undefined => {
  const serializedColumns = localStorage.getItem(tableHiddenColumnsStorageKey(section));
  if (!serializedColumns) return undefined;

  const columns = JSON.parse(serializedColumns);
  if (!columns) return undefined;

  if (!isArray(columns)) return undefined;

  return columns as string[];
};

const storeHiddenColumnsToStorage = (
  section: TSection,
  columns: string[] | undefined,
) => {
  if (!columns || columns.length === 0) {
    localStorage.removeItem(tableHiddenColumnsStorageKey(section));
    return;
  }

  localStorage.setItem(tableHiddenColumnsStorageKey(section), JSON.stringify(columns));
};

const makeSyncHiddenColumnsWithStorageEffect = (
  section: TSection,
): AtomEffect<string[]> => {
  return ({ setSelf, trigger, onSet }) => {
    // Initialize atom value to the remote storage state
    if (trigger === 'get') { // Avoid expensive initialization
      setSelf(restoreHiddenColumnsFromStorage(section) || []);
    }

    onSet((data) => {
      storeHiddenColumnsToStorage(section, data);
    });
  };
};

const hiddenColumns = atomFamily<string[], TSection>({
  key:     Family.CollectionTableHiddenColumns,
  default: [],
  effects: (section) => [
    makeSyncHiddenColumnsWithStorageEffect(section),
  ],
});

const tableColumns = atomFamily<TColumnsState, TSection>({
  key:     Family.CollectionTableColumns,
  default: [],
});

const tableColumnsWithHidden = selectorFamily<TColumnsState, TSection>({
  key: Family.CollectionTableColumnsWithHidden,
  get: (section) => ({ get }) => {
    const hiddenColumnsData = get(hiddenColumns(section));
    const columnsData = get(tableColumns(section));

    return columnsData.map((column) => {
      if (!hiddenColumnsData.includes(column.name)) return column;

      return {
        ...column,
        hidden: true,
      };
    });
  },
  set: (section) => ({ set }, newColumnsData) => {
    if (newColumnsData instanceof DefaultValue) return;

    const hiddenColumnsData = newColumnsData.filter((c) => c.hidden).map((c) => c.name);

    set(tableColumns(section), newColumnsData);
    set(hiddenColumns(section), hiddenColumnsData);
  },
});

export {
  hiddenColumns,
  tableColumns,
  tableColumnsWithHidden,
};
