/* eslint-disable import/order */
import React, { useMemo, useRef, forwardRef } from 'react';
import { InfiniteData } from 'react-query';

import { flatten } from 'lodash';

import styles from '../styles.module.scss';

import { useGetMessageHistory } from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';

import { IGlobalMessageHistoryResponse, IMessageSchema } from '../types';
import ChatMessageItem from './chat_message_item';
import NoChatBody from './no_chat_body';

interface IChatProps {
  addMessage:(pre: IMessageSchema[], isFirstPage: boolean) => void
  message: IMessageSchema[] | undefined;
  activeThreadId?: string;
  activeChannelID?: string;
  loader: boolean;
}

const Chat = forwardRef<HTMLDivElement, IChatProps>((props, ref) => {
  const { addMessage, message, activeThreadId, activeChannelID, loader } = props;
  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const query = useGetMessageHistory({ chatThreadId: activeThreadId }, {
    enabled:   !!activeThreadId,
    onSuccess: (data: InfiniteData<IGlobalMessageHistoryResponse>) => {
      const messageResponse = data?.pages || [];
      const preMessage = flatten(messageResponse.map((p) => p.collection));
      if (preMessage && preMessage.length > 0 && preMessage[0]) {
        addMessage(preMessage, messageResponse.length === 1);
      }
    },
  });

  useInfiniteScroll({
    elementRef:   infiniteScrollRef,
    query,
    isTopReached: true,
  });

  const reversedMessage = useMemo(() => {
    if (!message) return [];
    return message.slice().reverse();
  }, [message]);

  if (!activeChannelID && !activeThreadId) {
    return <NoChatBody />;
  }

  return (
    <div className={ styles['chat-message-scroll-outer'] }>
      <div ref={ infiniteScrollRef } className={ styles['chat-message-scroll-inner'] }>
        {reversedMessage?.map((item) => (
          <ChatMessageItem
            key={ item.id }
            answer={ item.answer }
            loader={ loader }
            question={ item.question }
          />
        ))}
      </div>
      <div ref={ ref } />
    </div>
  );
});

Chat.displayName = 'Chat';

export default Chat;
