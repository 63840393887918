import React from 'react';

import classNames from 'classnames';

import Tooltip from '@src/components/ui_v2/tooltip';

import Cell, { ITableCellProps } from './cell';
import Content from './text_cell_content';

import styles from './styles.module.scss';

interface ITableTextCellProps extends ITableCellProps {
  icon?: React.ReactNode,
  hideValueTooltip?: boolean,
  tooltip?: string | React.ReactNode,
  subtitle?: React.ReactNode,
}

const TableTextCell = ({
  children,
  className,
  hideValueTooltip,
  tooltip,
  icon,
  subtitle,
  ...props
}: ITableTextCellProps) => {
  const classes = classNames(styles['table-text-cell'], className);

  let cellContent = (
    <Content
      className={ styles['table-text-cell-content'] }
      icon={ icon }
      subtitle={ subtitle }
    >
      { children }
    </Content>
  );

  if (tooltip) {
    cellContent = (
      <Tooltip.Hover content={ tooltip }>
        { cellContent }
      </Tooltip.Hover>
    );
  } else if (!hideValueTooltip) {
    cellContent = (
      <Tooltip.Hover
        content={ (
          <Content
            className={ styles['table-text-cell-tooltip'] }
            icon={ icon }
            subtitle={ subtitle }
          >
            { children }
          </Content>
        ) }
      >
        { cellContent }
      </Tooltip.Hover>
    );
  }

  return (
    <Cell className={ classes } { ...props }>
      { cellContent }
    </Cell>
  );
};

const MemoizedTableTextCell = React.memo(TableTextCell);

export {
  ITableTextCellProps,
  MemoizedTableTextCell as default,
};
