import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IInboxServiceDocument,
  TInboxServiceDocumentsSortColumn,
} from '@src/types/inbox_service_documents';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IBusinessInboxListProps {
  documents: IInboxServiceDocument[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TInboxServiceDocumentsSortColumn>,
}

const BusinessInboxList = ({
  documents,
  query,
  sorting,
}: IBusinessInboxListProps): JSX.Element => {
  return (
    <Table
      infiniteQuery={ query }
      wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.SelectAllHCell />
          <Table.HCell<TInboxServiceDocumentsSortColumn>
            columnName="document_name"
            sorting={ sorting }
            style={ { paddingLeft: '117px' } }
          >
            Document Name
          </Table.HCell>
          <Table.HCell aria-label="Status" widthPercent={ 5 } />
          <Table.HCell<TInboxServiceDocumentsSortColumn>
            className="text-right"
            columnName="uploaded"
            sorting={ sorting }
            style={ { paddingRight: '30px' } }
            widthPercent={ 15 }
          >
            Uploaded
          </Table.HCell>
          <Table.HCell aria-label="Document request" widthPercent={ 10 } />
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          documents.map((document) => {
            return (
              <Item
                key={ document.id }
                document={ document }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default BusinessInboxList;
