import { TID } from '@src/types/common';
import {
  IMatchedItem,
} from '@src/types/matched_items';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetMatchedItemsParams {
    documentId: TID,
}

const getMatchedItems = (id: number): Promise<IMatchedItem[]> => {
  return apiGet(`/api/v1/documents/${id}/related_matched_items`).then((data) => camelizeKeys(data) as IMatchedItem[]);
};

export {
  IGetMatchedItemsParams,
  getMatchedItems,
};
