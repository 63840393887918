import React from 'react';

import { IRevenueService } from '@src/types/revenue_services';

import { Button } from '@src/components/ui_v2/buttons';

import { useAddRevenueSystemModal } from './add_system_modal';

interface IAddRevenueSystemProps {
  revenueService: IRevenueService
}

const AddRevenueSystem = ({
  revenueService,
}: IAddRevenueSystemProps): JSX.Element => {
  const addSystemModal = useAddRevenueSystemModal();

  return (
    <>
      <addSystemModal.Component
        revenueService={ revenueService }
        { ...addSystemModal.props }
      />
      <Button
        variant="link"
        onClick={ addSystemModal.open }
      >
        Add Manually
      </Button>
    </>
  );
};

export default AddRevenueSystem;
