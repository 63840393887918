import React from 'react';

import { IPaymentProcessor } from '@src/types/payment_processors';
import { daysBetweenTwoDates } from '@src/utils/date_helpers';

import Filter from '@src/components/ui_v2/filter';

interface IReconcileRevenueFilterProps {
  paymentProcessor: IPaymentProcessor,
}

const maxPeriodDays = 60;

const filterDateCallback = (startDate: Date, endDate: Date) => {
  return daysBetweenTwoDates(startDate, endDate) <= maxPeriodDays;
};

const ReconcileRevenueFilter = ({
  paymentProcessor,
}: IReconcileRevenueFilterProps) => {
  return (
    <Filter.TableContainer>
      <Filter.DateField
        hidePeriods
        filterDate={ filterDateCallback }
        name="endDate"
      />
      <Filter.AmountField
        name={ paymentProcessor.code }
        valueProps={ { placeholder: `${paymentProcessor.name} Amount` } }
      />
    </Filter.TableContainer>
  );
};

export default React.memo(ReconcileRevenueFilter);
