import React from 'react';

import { Button, CancelModalButton } from '@src/components/ui/buttons';

import Footer from '../footer';

interface IModalSubmitFormFooterProps {
  cancelTitle?: string,
  formId: string,
  proceedTitle?: string,
  isProceedDisabled?: boolean,
  onCancel: () => void,
}

const ModalSubmitFormFooter = ({
  cancelTitle = 'Cancel',
  formId,
  proceedTitle = 'Proceed',
  isProceedDisabled,
  onCancel,
}: IModalSubmitFormFooterProps): JSX.Element => {
  return (
    <Footer>
      <CancelModalButton
        title={ cancelTitle }
        onClick={ onCancel }
      />
      <Button
        bsColor="blue"
        disabled={ isProceedDisabled }
        form={ formId }
        type="submit"
      >
        { proceedTitle }
      </Button>
    </Footer>
  );
};

const MemoizedModalSubmitFormFooter = React.memo(ModalSubmitFormFooter);

export {
  IModalSubmitFormFooterProps,
  MemoizedModalSubmitFormFooter as default,
};
