module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, country, i, j, len, len1;
    
      __out.push('<div class="person-edit-form-modal">\n  <div class="w9-info-modal-content">\n    <div class="padding-20">\n      <a class=\'cancel m-t-10 pull-right pointer\'>\n        <span>Close</span>\n      </a>\n      <h4 class="font-28 font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n    </div>\n    <div class="w9-info-modal-body">\n      <div class="text-center">\n        <div class="profile-content-wrapper">\n          <div class="profile-input-wrap m-b-20">\n            <label id="w9-info-title">Legal Name</label>\n            <input class="input-legal-name form-control" type="text" placeholder="Enter Legal Name" value="');
    
      __out.push(__sanitize(this.w9_legal_name));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap m-b-20">\n            <label id="w9-info-title">Legal Business Name</label>\n            <input class="input-entity-name form-control" type="text" placeholder="Enter Legal Business Name" value="');
    
      __out.push(__sanitize(this.w9_name));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap m-b-20">\n            <label id="w9-info-title">Email</label>\n            <input class="input-email-address form-control" type="text" placeholder="Enter Email Address" value="');
    
      __out.push(__sanitize(this.w9_email));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap m-b-20">\n            <label id="w9-info-title">Phone</label>\n            <input class="input-phone-number form-control" type="tel" name="phone" placeholder="Enter Phone Number" value="');
    
      __out.push(__sanitize(this.w9_phone));
    
      __out.push('">\n            <p class="phone-valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <label id="w9-info-title">Legal Entity Type</label>\n            <select class="form-control width-70-percent input-entity-type">\n              <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["SOLE"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Sole Proprietorship</option>\n              <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["CORP"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["CORP"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Corporation</option>\n              <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["LLC"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["LLC"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>LLC</option>\n              <option value=');
    
      __out.push(__sanitize(configData.dwolla_customer_business_types["PARTNER"]));
    
      __out.push(' ');
    
      if (this.businessType === configData.dwolla_customer_business_types["PARTNER"]) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Partnership</option>\n            </select>\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <label id="w9-info-title">Legal Entity Address</label>\n            <input class="input-address-street form-control" placeholder="Street" type="text" value="');
    
      __out.push(__sanitize(this.w9_address ? this.w9_address.address_line1 : ''));
    
      __out.push('">\n            <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n          </div>\n          <div class="profile-input-wrap">\n            <label id="w9-info-title"></label>\n            <div class="business-address-wrapper">\n              <div class="p-r-5 relative">\n                <input class="input-address-city form-control" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.w9_address ? this.w9_address.address_city : ''));
    
      __out.push('">\n                <p class="valid-hint-js  in-red-400"><i class="fa fa-warning"></i></p>\n              </div>\n              <div class="p-r-5">\n                <select class="input-address-state form-control">\n                  ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n                  ');
        if (this.w9_address && address_state.abbreviation === this.w9_address.address_state) {
          __out.push('\n                  <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('" selected>');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                  ');
        } else {
          __out.push('\n                  <option value="');
          __out.push(__sanitize(address_state.abbreviation));
          __out.push('">');
          __out.push(__sanitize(address_state.text));
          __out.push('</option>\n                  ');
        }
        __out.push('\n                  ');
      }
    
      __out.push('\n                </select>\n              </div>\n              <div class="relative">\n                <input class="input-address-zip form-control" type="text" placeholder="Zip Code" value="');
    
      __out.push(__sanitize(this.w9_address ? this.w9_address.address_zip : ''));
    
      __out.push('">\n                <p class="valid-hint-js in-red-400"><i class="fa fa-warning"></i></p>\n              </div>\n            </div>\n          </div>\n          <div class="profile-input-wrap">\n            <label id="w9-info-title"></label>\n            <select class="acount-type__input form-control address-country-js width-70-percent"  placeholder="Country" disabled>\n              ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n              <option value="');
        __out.push(__sanitize(country.code));
        __out.push('" ');
        if (country.code === 'US') {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(country.text));
        __out.push('</option>\n              ');
      }
    
      __out.push('\n            </select>\n          </div>\n          <div class="profile-input-wrap">\n            <label id="label-tax-id">Taxpayer Identification Number (TIN)</label>\n            <div id="w9_tin">\n              <div class=\'m-b-15\'>\n                <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline display-flex m-b-15">\n                  <input type="radio" id="radioSSN" name="include_header" class="pointer radio-TIN" value="true" ');
    
      if (this.ssn_tin_type) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n                  <label for="radioSSN">Business Owner\'s SSN</label>\n                </div>\n                <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline display-flex">\n                  <input type="radio" id="radioEIN" name="include_header" class="pointer radio-TIN" value="false" ');
    
      if (!this.ssn_tin_type) {
        __out.push(__sanitize('checked'));
      }
    
      __out.push('>\n                  <label for="radioEIN">Business EIN</label>\n                </div>\n              </div>\n              <input class="input-tax-id form-control" type="text" placeholder="">\n              <p class="valid-hint-js in-red-400 m-t-40"><i class="fa fa-warning in-red-400"></i></p>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="w9-info-modal-footer">\n      <div class=\'p-r-10 p-l-10 text-center\'>\n        <button type=\'button\' class="btn btn-info ladda-button btn-save" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n      <div class=\'p-r-10 p-l-10 m-t-20 m-b-50 text-center\'>\n        <a class=\'cancel settings-cancel-btn\'>Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}