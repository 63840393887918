import React, { FC } from 'react';

import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

interface Props {
}

const Component: FC<Props> = () => {
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('transaction_date') && (
      <Filter.DateField
        name="transaction_date"
      />
      )) }
      { (visibleColumnNames.includes('memo') && (
      <Filter.TextField
        label="Description"
        name="memo"
        placeholder="Description"
      />
      )) }
      { (visibleColumnNames.includes('name') && (
      <Filter.TextField
        label="Name"
        name="name"
        placeholder="Name"
      />
      )) }
      { (visibleColumnNames.includes('amount') && (
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false, placeholder: '$ Amount' } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
