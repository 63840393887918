import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IDownloadDocumentIconProps extends IIcomoonIconProps {
  variant?: 'thin',
}

const DownloadDocumentIcon = ({
  variant,
  ...props
}: IDownloadDocumentIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ variant ? `download-document-${variant}` : 'download-document' }
      pathsNumber={ 2 }
    />
  );
};

export default DownloadDocumentIcon;
