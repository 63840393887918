// import { IVendorServiceResponse } from '@src/types/business_vendors';
import { IVendorsService, IBussinessVendorService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IFetchVendorParams {
  role: 'admin',
}

const getVendorsServiceByBusinessId = (businessId: number): Promise<IVendorsService> => {
  return apiGet(
    '/api/v1/vendor_services/by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.vendor_service) as IVendorsService);
};

const getVendorService = (
  params: IFetchVendorParams,
): Promise<IBussinessVendorService> => {
  return apiGet(
    '/api/v1/vendor_services',
    underscoreKeys({ ...params }),
  ).then((data) => camelizeKeys(data) as IBussinessVendorService);
};

export {
  getVendorService,
  getVendorsServiceByBusinessId,
};
