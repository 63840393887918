module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var contact, i, len, ref;
    
      __out.push('<div class=\'modal fade\'>\n    <div class=\'modal-dialog invite-bank-admin-modal\'>\n        <div class=\'modal-content\'>\n            <div class="modal-header">\n                <h1 class="modal-title font-bold">Invite Authorized Signatory</h1>\n            </div>\n            <div class=\'modal-body m-b-10\'>\n                <div class="bank-admin-dropdown permission-dropdown-wrapper bg-grey-300">\n                    <div class="dropdown-toggle permission-dropdown-control" id="contact-dropdown-btn" data-toggle="dropdown">\n                        <span class="sel-dropdown-item ');
    
      if (!this.selected_user) {
        __out.push(__sanitize('in-grey-1700'));
      }
    
      __out.push('">');
    
      __out.push(__sanitize(this.selected_user ? this.selected_user.name : 'Enter name of Authorized Signatory'));
    
      __out.push('</span>\n                        <span class="fa fa-caret-down"></span>\n                    </div>\n                    <ul class="dropdown-menu scroll-menu" aria-labelledby="contact-dropdown-btn">\n                        ');
    
      ref = this.business_admins;
      for (i = 0, len = ref.length; i < len; i++) {
        contact = ref[i];
        __out.push('\n                        ');
        if (contact.business_partner_id) {
          __out.push('\n                        <li class="contact-dropdown-item display-flex align-items-center ');
          if (this.selected_user && this.selected_user.user_id === contact.user_id) {
            __out.push(__sanitize('selected'));
          }
          __out.push('" data-value="');
          __out.push(__sanitize(contact.user_id));
          __out.push('">\n                            ');
          if (contact.image_url) {
            __out.push('\n                            <div class="employee-avatar-with-border display-flex m-r-30">\n                                <img src="');
            __out.push(__sanitize(contact.image_url));
            __out.push('" class=\'clients__userpic-img\'>\n                            </div>\n                            ');
          } else {
            __out.push('\n                            <span class=\'business-status-icon icon-business-default-avatar m-r-30\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                            ');
          }
          __out.push('\n                            <span>');
          __out.push(__sanitize(contact.name));
          __out.push('</span>\n                        </li>\n                        ');
        }
        __out.push('\n                        ');
      }
    
      __out.push('\n                    </ul>\n                </div>\n                <div class="display-table m-t-10 width-100-percent">\n                    <input type="text" id="email-input" class="form-control bg-grey-300" placeholder="Email" value="');
    
      if (this.selected_user) {
        __out.push(__sanitize(this.selected_user.email));
      }
    
      __out.push('">\n                </div>\n                <div class="display-table m-t-10 width-100-percent">\n                    <textarea id="message-textarea" class="form-control bg-grey-300" rows="13" placeholder="Your Message">');
    
      __out.push(__sanitize(this.message_template));
    
      __out.push('</textarea>\n                </div>\n            </div>\n            <div class=\'modal-footer\'>\n                <a class=\'cancel-link cancel pointer font-18\'>Cancel</a>\n                <button type=\'button\' class="btn btn-blue font-16 font-semi-bold ');
    
      if (!this.selected_user) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' send-invitation-js"><span class="ladda-label">Send Invitation</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}