import React from 'react';

import BusinessProvider from '@src/components/providers/business_provider';
import MetricsServiceProvider from '@src/components/providers/metrics_service_provider';
import Root from '@src/components/root';

import MetricsServiceLandingPage from './metric_service_landing_page'

interface IMetricsServiceDetailsPageProps {
  businessId: number,
  isIndustryMetrics: boolean,
}

const MetricsServiceDetailsPage = ({
  businessId,
  isIndustryMetrics,
}: IMetricsServiceDetailsPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <MetricsServiceProvider businessId={ businessId }>
          <MetricsServiceLandingPage businessId={ businessId } isIndustryMetrics={ isIndustryMetrics } />
        </MetricsServiceProvider>
      </BusinessProvider>
    </Root>
  );
};

export default MetricsServiceDetailsPage;
