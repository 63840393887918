import React, { useCallback } from 'react';

import { IMatchedItem } from '@src/types/matched_items';

import Table from '@src/components/ui/table';

interface IMatchedListItemProps {
  matchedItem: IMatchedItem,
}

const MatchedListItem = ({
  matchedItem,
}: IMatchedListItemProps): JSX.Element => {
  const handleDocumentClick = useCallback(() => {
    window.open(`/document/${matchedItem.docytId}`, '_blank');
  }, [matchedItem]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        className="flex-wrapper in-blue-900"
        onClick={ handleDocumentClick }
      >
        { matchedItem.docytId }
      </Table.Cell>
      <Table.AmountCell
        absolute
        pointer
        amount={ matchedItem.amount }
      />
    </Table.Row>
  );
};

export default MatchedListItem;
