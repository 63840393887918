import React from 'react';

interface IFilterItemWrapperProps extends React.ComponentPropsWithoutRef<'div'> {
    children?: React.ReactNode;
    label: string;
  }

const FilterItemWrapper = (
  { children, label, ...props }
    : IFilterItemWrapperProps,
): JSX.Element | null => {
  return (
    <div { ...props }>
      <label>{label}</label>
      { children }
    </div>
  );
};

export default FilterItemWrapper;
