import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import DetailSideMenu from './invoice_detail_side_menu';

interface IInvoiceDetailSideMenuPageProps {
  serviceId: TID,
  businessId: TID,
  serviceDocumentId: TID,
  documentId: TID,
  activeTab: string,
  email: string,
}

const InvoiceDetailSideMenuPage = ({
  serviceId,
  businessId,
  serviceDocumentId,
  documentId,
  activeTab,
  email,
}: IInvoiceDetailSideMenuPageProps): JSX.Element => {
  return (
    <Root>
      <DetailSideMenu
        activeTab={ activeTab }
        businessId={ businessId }
        documentId={ documentId }
        email={ email }
        serviceDocumentId={ serviceDocumentId }
        serviceId={ serviceId }
      />
    </Root>
  );
};

export default InvoiceDetailSideMenuPage;
