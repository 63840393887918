import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBusinesses } from '@src/hooks/queries/businesses';
import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IDataExportsFilter } from './types';

interface IDataExportFilterProps {
  filter: IFilter<IDataExportsFilter>,
}

const ExportTypes = [
  { value: '', name: 'All Types' },
  { value: 'all_invoices', name: 'ALL INVOICES' },
  { value: 'check_register', name: 'CHECK REGISTER' },
  { value: 'expense_transactions', name: 'EXPENSE TRANSACTIONS' },
  { value: 'complete_vendors_report', name: 'COMPLETE VENDORS REPORT' },
  { value: 'invoices_pdf', name: 'INVOICES PDF' },
  { value: 'receipts_pdf', name: 'RECEIPTS PDF' },
  { value: 'revenue_reports_pdf', name: 'REVENUE REPORTS PDF' },
  { value: 'checks_pdf', name: 'CHECKS PDF' },
  { value: 'pl_chart_of_accounts_xls', name: 'PL CHART OF ACCOUNTS XLS' },
  { value: 'journal_entries_iif', name: 'JOURNAL ENTRIES IIF' },
  { value: 'printable_checks', name: 'PRINTABLE CHECKS' },
];

const DataExportFilter = ({
  filter,
}: IDataExportFilterProps): JSX.Element => {
  const dataExportRevampV1Enabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.DATA_EXPORT_REVAMP_V_1_FLAG);

  const query = useGetBusinesses({
    includeEmployedBusiness: true,
    simple:                  true,
  });
  const businesses = useMemo(() => {
    const businessesPages = query.data?.pages || [];
    let businessData = flatten(businessesPages.map((p) => p.collection))
      .map((business) => ({ name: business.legalName, value: `${business.id}` }));

    businessData.unshift({ value: '', name: 'All Businesses' });

    return businessData;
  }, [query.data?.pages]);

  return (
    <Filter<IDataExportsFilter> filter={ filter }>
      <Filter.TextField
        name="exportFileName"
        placeholder="Export Name"
        widthPercent={ dataExportRevampV1Enabled ? 30 : 33 }
      />
      <Filter.SelectPickerField
        name="exportType"
        options={ ExportTypes }
        title="Export Type"
        widthPercent={ 33 }
      />
      {dataExportRevampV1Enabled && (
        <Filter.SelectPickerField
        name="businessId"
        options={ businesses }
        title="All Businesses"
        widthPercent={ 33 }
      />
      )}
      <Filter.ResetButton />
    </Filter>
  );
};

export default React.memo(DataExportFilter);
