import React from 'react';

interface IServiceSidebarProps {
  children: React.ReactNode,
  serviceMenu?: React.ReactNode,
}

const ServiceSidebar = ({
  children,
  serviceMenu,
}: IServiceSidebarProps) => {
  return (
    <>
      { serviceMenu && (
        <div id="servicemenu-region">
          { serviceMenu }
        </div>
      ) }
      <ul className="nav nav-sidebar">
        { children }
      </ul>
    </>
  );
};

export default React.memo(ServiceSidebar);
