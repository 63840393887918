import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useInviteUserToUploadDocument } from '@src/hooks/queries/self_onboarding_invitations';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IAddInviteClientValues } from './add_invite_client_form';

import styles from '../styles.module.scss';

const AddInviteClientModal = ({
  isOpen,
  onCancel,
  onDone,
}: IUseModalProps) => {
  const sendInvitation = useInviteUserToUploadDocument();
  const handleSubmit = useCallback((value: IAddInviteClientValues) => {
    sendInvitation.mutate(
      {
        email:          value.email,
        firstName:      value.firstName,
        lastName:       value.lastName,
        welcomeMessage: value.welcomeMessage,
      },
      {
        onSuccess: onDone,
      },
    );
  }, [sendInvitation, onDone]);
  return (
    <>
      <MutationStatus
        mutation={ sendInvitation }
        successMessage="Sent invitation to the client successfully."
      />
      <Modal.Form
        proceedTitle="Send Invitation"
        show={ isOpen }
        title={ (
          <div
            className={ styles['modal-header-title'] }
          >
            <p className={ styles.h1 }>New Client</p>
            <p className={ styles.h4 }>All required</p>
          </div>
        ) }
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useAddInviteClientModal = makeUseModal(AddInviteClientModal);

export {
  useAddInviteClientModal,
  AddInviteClientModal as default,
};
