import React from 'react';

import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { useRenameDocumentModal } from '@src/components/common/rename_document';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { RenameDocumentIcon } from '@src/components/utils/icomoon';

interface IBusinessInboxItemRenameActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemRenameAction = ({
  document,
}: IBusinessInboxItemRenameActionProps) => {
  const rename = useRenameDocumentModal();

  return (
    <>
      <rename.Component { ...rename.props } document={ document } />
      <ActionsDropdown.Action
        icon={ <RenameDocumentIcon /> }
        name="Rename"
        onClick={ rename.open }
      />
    </>
  );
};

export default BusinessInboxItemRenameAction;
