import React, {useMemo} from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServicePathRevenueCapturePath } from '@src/routes';

import NewSystem from './new_system';

import { sortRevenueSystems } from '@src/components/revenue_center/utils'

const RevenueCenterRoot = (): JSX.Element | null => {
  const service = useRevenueServiceContext();

  const sortedRevenueSystems = useMemo(() => {
    return sortRevenueSystems(service.revenueSystems);
  }, [service.revenueSystems]);

  if (sortedRevenueSystems.length > 0) {
    Backbone.history.navigate(
      revenueServicePathRevenueCapturePath(service.businessId, sortedRevenueSystems[0].id),
      { trigger: true },
    );
    return null;
  }

  return <NewSystem />;
};

export default React.memo(RevenueCenterRoot);
