export const PaymentMethodLabel = {
  PAYMENT_DOCYT_ACH:           'ACH',
  PAYMENT_DOCYT_CHECK:         'Check',
  PAYMENT_BANK_CHECK:          'Manual Check',
  PAYMENT_BANK_ACCOUNT:        'Bank Account',
  PAYMENT_ADVANCED_SETTLEMENT: 'Advanced Settlement',
  PAYMENT_CREDIT_CARD:         'Credit Card',
  PAYMENT_CASH:                'Cash',
  PAYMENT_PAID_EXTERNALLY:     'Bank Account',
  PAYMENT_VENDOR_CREDIT:       'Vendor Credit',
};
