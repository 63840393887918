module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header text-center">\n        <span class="font-28 font-bold m-l-15">');
    
      __out.push(__sanitize(this.title + ' in Progress'));
    
      __out.push('</span>\n      </div>\n      <div class="modal-body">\n        <p class="p-b-0 text-center m-b-20"><b>');
    
      __out.push(__sanitize(this.pos + '/' + this.total));
    
      __out.push('</b> ');
    
      __out.push(__sanitize(this.itemName));
    
      __out.push('</p>\n        <div class="progress">\n          <div class="progress-bar" style="width:');
    
      __out.push(__sanitize(this.percent));
    
      __out.push('%">\n          </div>\n        </div>\n        <p class="p-b-0 text-center">Do not close this window or click the Back button on your browser. It will stop the progress.</p>\n      </div>\n      <div class="modal-footer text-center">\n        <a class=\'stop btn btn-red\'>Stop</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}