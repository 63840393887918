import React, { ReactNode } from 'react';

interface IHeaderRegionActionsAreaProps {
  children: ReactNode;
}

const HeaderRegionActionsArea = ({ children }: IHeaderRegionActionsAreaProps): JSX.Element => {
  return (
    <div>
      {children}
    </div>
  );
};

export default React.memo(HeaderRegionActionsArea);
