module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<button class="btn main-rounded-btn dump-config-js ladda-button" data-style="expand-right">Save Configs</button>\n<div class=\'clients__search\'>\n  <i class=\'clients__search-icon icon icon-search\'></i>\n  <input class=\'clients__search-input\' placeholder=\'Find by Name\' type=\'text\'>\n</div>\n<div class=\'clients__search m-r-20\'>\n    <select class="select-invoice-state form-control font-16">\n        <option value="all">All Profiles</option>\n        <option value="completed">Completed Profiles</option>\n        <option value="uncompleted">Uncompleted Profiles</option>\n    </select>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}