import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';
import { currencyFormater } from '@src/utils/currency';
import { formatDate } from '@src/utils/date_helpers';

interface ISplitTransactionDetailsProps {
  transaction: ITransactionServiceDocument,
}

const SplitTransactionDetails = ({
  transaction,
}: ISplitTransactionDetailsProps): JSX.Element => {
  return (
    <div className="transaction-details border-bottom-gray p-b-10">
      <div className="transaction-date display-flex m-b-5">
        <span className="title width-20-percent font-bold">Date</span>
        <span className="detail">{ formatDate(transaction.transactionDate) }</span>
      </div>
      <div className="transaction-description display-flex m-b-5">
        <span className="title width-20-percent font-bold">Description</span>
        <span className="detail">{ transaction.description }</span>
      </div>
      <div className="transaction-type display-flex m-b-5">
        <span className="title width-20-percent font-bold">Type</span>
        <span className="detail">{ transaction.transactionType }</span>
      </div>
      <div className="transaction-account display-flex m-b-5">
        <span className="title width-20-percent font-bold">Account</span>
        <span className="detail">{ transaction.paymentAccountName }</span>
      </div>
      <div className="transaction-amount display-flex">
        <span className="title width-20-percent font-bold">Amount</span>
        <span className="detail">{ currencyFormater(transaction.amount) }</span>
      </div>
    </div>
  );
};

export {
  ISplitTransactionDetailsProps,
  SplitTransactionDetails as default,
};
