import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

interface IResultantTransactionProps {
  resultantTransaction: ITransactionServiceDocument,
}

const ResultantTransactionListItem = ({
  resultantTransaction,
}: IResultantTransactionProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.DateCell date={ resultantTransaction.transactionDate } />
      <Table.Cell>
        { resultantTransaction.description }
      </Table.Cell>
      <Table.Cell>
        Uncategorized
      </Table.Cell>
      <Table.Cell>
        { resultantTransaction.paymentAccountName }
      </Table.Cell>
      <Table.AmountCell amount={ resultantTransaction.amount } />
    </Table.Row>
  );
};

export default ResultantTransactionListItem;
