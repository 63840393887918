import React, { ReactNode } from 'react';

import { AppLink } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface DetailsPageBreadcrumbProps {
  href?: string,
  children: ReactNode;
}

const DetailsPageBreadcrumb = ({
  href,
  children,
}: DetailsPageBreadcrumbProps): JSX.Element => {
  return (
    href ? (
      <AppLink className={ styles.breadcrumb } href={ href }>
        { children }
      </AppLink>
    ) : (
      <span className={ styles.breadcrumb }>
        { children }
      </span>
    )
  );
};

export default React.memo(DetailsPageBreadcrumb);
