import React, { useCallback, useEffect, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { useCreateBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TDate, TID } from '@src/types/common';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import DetailForm, { IBalanceSheetStatementDetailsFormValues } from './details_form';

import styles from '@src/components/banking_accounts/bank_statements/styles.module.scss';

const formId = 'bank_statement_upload_form';

interface IBalanceSheetStatementUploadModalProps {
  businessId: TID,
  balanceSheetStatement?: IBalanceSheetStatement,
  isOpen: boolean,
  preSelectedChartOfAccountId?: TID,
  preSelectedDate?: TDate,
  onCancel: () => void,
  onDone: (balanceSheetStatement?: IBalanceSheetStatement) => void,
}

const BalanceSheetStatementUploadModal = ({
  businessId,
  balanceSheetStatement,
  preSelectedChartOfAccountId,
  preSelectedDate,
  isOpen,
  onDone,
  onCancel,
}: IBalanceSheetStatementUploadModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [approveAction, setApproveAction] = useState<boolean>(false);

  const create = useCreateBalanceSheetStatement();

  const { mutate: createBalanceSheetStatement } = create;

  const handleDone = useCallback(() => {
    if (approveAction) {
      // To do: this will be implemented in the other ticket.
    } else {
      onDone();
    }
  }, [approveAction, onDone]);

  const handleSubmit = useCallback((value: IBalanceSheetStatementDetailsFormValues) => {
    if (!value?.chartOfAccountId || !value?.date) return;
    if (!value?.file) {
      setErrorMsg('Please Upload Balance Sheet Statement.');
      return;
    }

    createBalanceSheetStatement({
      businessId,
      chartOfAccountId: value.chartOfAccountId,
      date:             value.date,
      file:             value.file,
      description:      value.description,
    }, {
      onSuccess: () => handleDone(),
    });
  }, [createBalanceSheetStatement, businessId, handleDone]);

  useEffect(() => {
    setApproveAction(false);
  }, [isOpen]);

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
      <MutationStatus mutation={ create } />
      <Modal
        showClose
        className={ styles['balance-sheet-statement-upload-modal'] }
        show={ isOpen }
        title="Upload Document"
        onCancel={ onCancel }
      >
        <Modal.Body>
          { isOpen && (
            <DetailForm
              balanceSheetStatement={ balanceSheetStatement }
              businessId={ businessId }
              formId={ formId }
              isFileUploading={ create.isLoading }
              preSelectedChartOfAccountId={ preSelectedChartOfAccountId }
              preSelectedDate={ preSelectedDate }
              onSubmit={ handleSubmit }
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ onCancel }
          />
          {
            approveAction && (
              <Button
                bsColor="blue"
                className={ styles['save-and-approve-btn'] }
                form={ formId }
                type="submit"
              >
                <ThumbsUpIcon fontSize={ 18 } />
                <span className="m-l-10">Save and Verify</span>
              </Button>
            )
          }
          <div className={ styles['save-and-close-btn'] }>
            <Button
              bsColor="blue"
              form={ formId }
              type="submit"
              onClick={ () => setApproveAction(false) }
            >
              Save & Close
            </Button>
            <Button bsColor="blue" className={ styles['toggle-btn'] } onClick={ () => setApproveAction((prev) => !prev) }>
              <CaretIcon
                fontSize={ 14 }
                variant={ approveAction ? 'up' : 'down' }
              />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useBalanceSheetStatementUploadModal = makeUseModal(BalanceSheetStatementUploadModal);

export {
  IBalanceSheetStatementUploadModalProps,
  useBalanceSheetStatementUploadModal,
  BalanceSheetStatementUploadModal as default,
};
