import { TID, TOrderDirection } from '@src/types/common';
import {
  IExpenseReportsServiceDocument,
  TExpenseReportsServiceDocumentsSortColumn,
} from '@src/types/expense_reports/expense_reports_service_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetExpenseReportsServiceDocumentsParams {
  businessId: TID,
  type?: string,
  filter?: object,
  perPage?: number,
  orderColumn?: TExpenseReportsServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetExpenseReportsServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IExpenseReportsServiceDocument[],
}

const getExpenseReportsServiceDocuments = (
  params: IGetExpenseReportsServiceDocumentsParams,
): Promise<IGetExpenseReportsServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/expense_reports_service_documents',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.expenseReportsServiceDocuments as IExpenseReportsServiceDocument[],
    };
  });
};

export {
  IGetExpenseReportsServiceDocumentsParams,
  IGetExpenseReportsServiceDocumentsResponse,
  getExpenseReportsServiceDocuments,
};
