import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import Footer from './footer';

interface IBottomModalSubmitFormFooterProps {
  cancelTitle?: string,
  children?: React.ReactNode,
  formId: string,
  isSubmitDisabled?: boolean,
  submitTitle?: string,
  onCancel: () => void,
}

const BottomModalSubmitFormFooter = ({
  cancelTitle = 'Cancel',
  children,
  formId,
  isSubmitDisabled,
  submitTitle = 'Done',
  onCancel,
}: IBottomModalSubmitFormFooterProps): JSX.Element => {
  return (
    <Footer>
      <Button
        variant="link"
        onClick={ onCancel }
      >
        { cancelTitle }
      </Button>
      { children }
      <Button
        disabled={ isSubmitDisabled }
        form={ formId }
        type="submit"
        variant="primary"
      >
        { submitTitle }
      </Button>
    </Footer>
  );
};

const MemoizedBottomModalSubmitFormFooter = React.memo(BottomModalSubmitFormFooter);

export {
  IBottomModalSubmitFormFooterProps,
  MemoizedBottomModalSubmitFormFooter as default,
};
