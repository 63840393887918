import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ReportBasicIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="basic-report"
      pathsNumber={ 3 }
    />
  );
};

export default ReportBasicIcon;
