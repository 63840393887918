import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Trash from './trash';

interface IExpenseReportsTrashPageProps {
  businessId: TID,
}

const ExpenseReportsTrashPage = ({
  businessId,
}: IExpenseReportsTrashPageProps): JSX.Element => {
  return (
    <Root>
      <Trash
        businessId={ businessId }
      />
    </Root>
  );
};

export default ExpenseReportsTrashPage;
