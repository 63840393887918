import axios from 'axios';

import { ITokens } from '@src/types/tokens';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

const postRefreshToken = (refreshToken: string): Promise<ITokens> => {
  return axios({
    baseURL: window.configData.api_server_host_url,
    method:  'POST',
    url:     '/auth/api/v1/oauth/token',
    data:    underscoreKeys({
      refreshToken,
      clientId:     window.configData.web_app_client_id,
      clientSecret: window.configData.web_app_client_secret,
      grantType:    'refresh_token',
    }),
    headers: {
      'Accept':       'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    return camelizeKeys(response.data) as ITokens;
  });
};

export { postRefreshToken };
