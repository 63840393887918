import React, { useCallback, useMemo } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import {
  useCreateFinancialInstitutionConnection,
  useGetFinancialInstitutionConnection,
} from '@src/hooks/queries/financial_institution_connection';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import {
  IConnectNewFinancialInstitutionData,
  // eslint-disable-next-line max-len
} from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/connect_financial_institution/schema';
import {
  useConnectAggregatorAccount,
  // eslint-disable-next-line max-len
} from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/hooks';
import { Button } from '@src/components/ui/buttons';
import { BankPaymentMethodIcon } from '@src/components/utils/img_icons';

import FinListView from './fin_list_view';

import styles from './styles.module.scss';

interface IAuthorizedSignatoryViewProps {
  invitation: ISelfOnboardingInvitation,
}

const FinDetailsView = ({
  invitation,
}: IAuthorizedSignatoryViewProps) => {
  const handleSubmit = useCallback(() => {
    Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_KYC_DOCUMENTS}`, { trigger: true });
  }, []);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_CONNECT_QBO}`,
      { trigger: true },
    );
  }, []);

  const query = useGetFinancialInstitutionConnection({
    filter: { management_group_id: invitation.managementGroupId },
  });

  const financialInstitutionConnections = useMemo(() => {
    return query.data?.financialInstitutionConnections || [];
  }, [query.data]);

  const create = useCreateFinancialInstitutionConnection();
  const { mutate: createFinancialInstitution } = create;

  const connectNewFinancialInstitution = useCallback((
    data: IConnectNewFinancialInstitutionData,
  ) => {
    createFinancialInstitution({ financialInstitutionConnection: data });
  }, [createFinancialInstitution]);

  const {
    connectAggregator,
  } = useConnectAggregatorAccount({ onAggregatorConnected: connectNewFinancialInstitution });

  if (financialInstitutionConnections.length === 0) {
    return (
      <div className={ styles['details-view'] }>
        <div className={ styles['connect-fin-empty'] }>
          <BankPaymentMethodIcon mt={ 80 } size="150px" />
          <span className="m-t-10">Connect all your business bank accounts for Docyt to fetch daily</span>
          <span className="m-t-5 m-b-50">transactions effortlessly.</span>
          <Button
            className="pull-right bg-purple-1000 in-white width-280px"
            data-color="$purple-1000"
            onClick={ () => connectAggregator({ managementGroupId: invitation.managementGroupId }) }
          >
            + Connect Financial Institution
          </Button>
        </div>
        <div className="setup-client-footer">
          <a
            className="cancel-link pointer"
            role="button"
            tabIndex={ -1 }
            onClick={ onBack }
          >
            Previous
          </a>
          <Button
            className="pull-right bg-purple-1000 in-white width-180px"
            data-color="$purple-1000"
            onClick={ handleSubmit }
          >
            Next
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={ styles['details-view'] }>
      <div className={ styles['fin-connection-list-container'] }>
        <FinListView financialInstitutionConnections={ financialInstitutionConnections } />
      </div>
      <div className="setup-client-footer">
        <a
          className="cancel-link pointer"
          role="button"
          tabIndex={ -1 }
          onClick={ onBack }
        >
          Previous
        </a>
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Next
        </Button>
        <Button
          className="pull-right bg-purple-1000 in-white width-280px"
          data-color="$purple-1000"
          mr={ 10 }
          onClick={ () => connectAggregator({ managementGroupId: invitation.managementGroupId }) }
        >
          + Connect Financial Institution
        </Button>
      </div>
    </div>
  );
};

export default FinDetailsView;
