import { useMutation } from 'react-query';

import {
  IInviteUserToUploadDocumentParams,
  IRemoveSpreadsheetParams,
  IRemoveSpreadsheetResponse,
  IUploadKYCDocumentsParams,
  IUploadSpreadsheetParams,
  IUploadSpreadsheetResponse,
  inviteUserToUploadDocument,
  removeSpreadsheet,
  uploadKYCDocuments,
  uploadSpreadsheet,
} from '@src/requests/self_onboarding_invitations';

import { IBulkMutationParams, IBulkMutationReturn, makeBulkRequest } from './bulk_mutations';

const useInviteUserToUploadDocument = () => {
  return useMutation<void, Error, IInviteUserToUploadDocumentParams>(
    inviteUserToUploadDocument,
  );
};

const useUploadSpreadsheet = () => {
  return useMutation<IUploadSpreadsheetResponse, Error, IUploadSpreadsheetParams>(
    uploadSpreadsheet,
  );
};

const useRemoveSpreadsheet = () => {
  return useMutation<IRemoveSpreadsheetResponse, Error, IRemoveSpreadsheetParams>(
    removeSpreadsheet,
  );
};

const useBulkUploadKYCDocuments = () => {
  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IUploadKYCDocumentsParams>
  >(
    makeBulkRequest(uploadKYCDocuments),
  );
};

export {
  useInviteUserToUploadDocument,
  useUploadSpreadsheet,
  useRemoveSpreadsheet,
  useBulkUploadKYCDocuments,
};
