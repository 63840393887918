import { TID } from '@src/types/common';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetRevenueReportTypesParams {
  businessId: TID,
  onlyActive?: boolean,
}

interface IGetRevenueReportTypesResponse {
  collection: IRevenueReportType[],
}

const getRevenueReportTypes = (
  params: IGetRevenueReportTypesParams,
): Promise<IGetRevenueReportTypesResponse> => {
  return apiGet(
    '/api/v1/revenue_report_types',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.revenueReportTypes as IRevenueReportType[],
    };
  });
};

interface ICreateRevenueReportTypeParams {
  revenueId: TID
  name:string
  frequency: string
}
interface ICreateRevenueReportTypeResponse {
  revenueReportType: IRevenueReportType
}
const createRevenueReportType = (
  params: ICreateRevenueReportTypeParams,
) : Promise<ICreateRevenueReportTypeResponse> => {
  return apiPost(
    `/api/v1/revenue_services/${params.revenueId}/revenue_report_types`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as ICreateRevenueReportTypeResponse;
  });
};

export {
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  getRevenueReportTypes,
  ICreateRevenueReportTypeParams,
  ICreateRevenueReportTypeResponse,
  createRevenueReportType,
};
