import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ISortArrowIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'down' | 'left' | 'right' | 'up';
}

const SortArrowIcon = ({ variant, ...props }: ISortArrowIconProps) => {
  return (
    <div>
      <FaIcon icon={ `sort-${variant}` } { ...props } />
    </div>
  );
};

export default SortArrowIcon;
