import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  useBulkForwardDocumentsToService,
} from '@src/hooks/queries/documents';
import { IBusiness } from '@src/types/businesses';
import { TServiceType } from '@src/types/services';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';

import Wizard from './forward_document_wizard';
import { IForwardDocumentData } from './types';

interface IForwardDocumentProps extends IUseModalProps {
  business: IBusiness,
  currentServiceType: TServiceType,
  documentIds: number[],
}

const ForwardDocument = ({
  business,
  currentServiceType,
  documentIds,
  isOpen,
  onCancel,
  onDone,
}: IForwardDocumentProps): JSX.Element => {
  const forwardToService = useBulkForwardDocumentsToService();

  const forwardToServiceModal = useBulkActionModal({ mutation: forwardToService, onDone });

  const { runMutation: runForwardToService } = forwardToServiceModal;
  const handleDone = useCallback((data: IForwardDocumentData) => {
    if (data.forwardToService === 'BankStatement') {
      onDone();
    } else if (data.forwardToService === 'BalanceSheetDocument') {
      onDone();
    } else {
      runForwardToService(documentIds.map((id) => ({
        documentIds: [id],
        businessId:  data.businessId,
        serviceType: data.forwardToService,
      })));
    }
  }, [documentIds, onDone, runForwardToService]);

  return (
    <>
      <forwardToServiceModal.Component
        { ...forwardToServiceModal.props }
        itemsTitle="Documents"
        title="Forward Documents"
      />
      <Wizard
        business={ business }
        currentServiceType={ currentServiceType }
        documentIds={ documentIds }
        show={ isOpen }
        onCancel={ onCancel }
        onDone={ handleDone }
      />
    </>
  );
};

const useForwardDocumentModal = makeUseModal(ForwardDocument);

export {
  IForwardDocumentProps,
  useForwardDocumentModal,
  ForwardDocument as default,
};
