import React from 'react';

import flatten from 'lodash/flatten';

import { useGetMatchedItems } from '@src/hooks/queries/matched_items';
import { TID } from '@src/types/common';

import CollapseSection from '@src/components/ui/form/collapse_section';
import LocalQueryStatus from '@src/components/utils/local_query_status';

import List from './list';

interface IMatchedItemDetailsProps {
  documentId: TID
}

const MatchedItemDetails = ({ documentId }: IMatchedItemDetailsProps): JSX.Element => {
  const documentsQuery = useGetMatchedItems({ documentId });

  return (
    <>
      <LocalQueryStatus query={ documentsQuery } />
      <CollapseSection title="MATCHED ITEMS">
        <List
          matchedItems={ flatten(documentsQuery?.data || []) }
        />
      </CollapseSection>
    </>
  );
};

export {
  IMatchedItemDetailsProps,
  MatchedItemDetails as default,
};
