/* eslint-disable max-len */
import React, { FC } from 'react';

import { TID } from '@src/types/common';
import { ISimpleChartOfAccount } from '@src/types/simple_chart_of_accounts';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { useDropdown } from '@src/components/ui_v2/dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import useDeleteModal from './modal';

interface DeleteBtnProps {
  businessId: TID
  chartOfAccount: ISimpleChartOfAccount
}

const DeleteBtn: FC<DeleteBtnProps> = ({ chartOfAccount }) => {
  const modal = useDeleteModal();
  const dropdown = useDropdown();

  const click = async () => {
    modal.open();
    dropdown?.hide();
  };

  return (
    <>
      <modal.Component
        chartOfAccount={ chartOfAccount }
        { ...modal.props }
      />
      <ActionsDropdown.LinkAction
        href="#"
        icon={ <TrashcanIcon fontSize={ 16 } style={ { color: 'red' } } /> }
        title="Delete"
        onClick={ click }
      />
    </>
  );
};

export default React.memo(DeleteBtn);
