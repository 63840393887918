import React from 'react';

import { docytBusinessNetworkPath, myVendorsPath, vendors1099ReportsPath } from '@src/routes';
import { TID } from '@src/types/common';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

interface IProps {
  businessId: TID
}

const VendorsSidebarMenuItems = ({ businessId }: IProps) => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.VENDORS }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ myVendorsPath(businessId) }
          title="All Vendors"
        />
        <ServiceSidebar.Item
          href={ vendors1099ReportsPath(businessId) }
          title="1099 Reports"
        />
        <ServiceSidebar.Item
          href={ docytBusinessNetworkPath(businessId) }
          title="Docyt Business Network"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default VendorsSidebarMenuItems;
