import React, { useCallback, useEffect, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import {
  useApproveBankStatement,
  useCreateBankStatement,
} from '@src/hooks/queries/bank_statements';
import { useDebouncedCallback } from '@src/hooks/utils';
import { getDuplicatedBankStatement } from '@src/requests/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID, TDate } from '@src/types/common';

import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner/spinner';
import { CaretIcon } from '@src/components/utils/fa_icons';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import DetailForm, { IBankStatementDetailsFormValues } from './details_form';
import { useResolveDuplicateStatementModal } from './duplicate_bank_statement/duplicate_statement_modal';
import { useStartDateErrorModal } from './error_modals/start_date_error_modal';
import { useValidationErrorModal } from './error_modals/validation_error_modal';

import styles from '../styles.module.scss';

const formId = 'bank_statement_upload_form';

interface IBankStatementUploadModalProps {
  businessId: TID,
  bankStatement?: IBankStatement,
  isOpen: boolean,
  preSelectedRcPaymentAccountId?: TID,
  preSelectedDate?: TDate,
  onCancel: () => void,
  onDone: (bankStatement?: IBankStatement) => void,
}

const BankStatementUploadModal = ({
  businessId,
  bankStatement,
  preSelectedRcPaymentAccountId,
  preSelectedDate,
  isOpen,
  onDone,
  onCancel,
}: IBankStatementUploadModalProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [approveAction, setApproveAction] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);

  const approve = useApproveBankStatement();
  const create = useCreateBankStatement();

  const { mutate: createBankStatement } = create;
  const { mutate: approveBankStatement } = approve;

  const handleDone = useCallback((id?: TID) => {
    if (approveAction) {
      if (id === undefined) return;
      approveBankStatement({ id }, { onSuccess: () => onDone() });
    } else {
      onDone();
    }
  }, [approveAction, approveBankStatement, onDone]);

  const resolveDuplicatedStatement = useResolveDuplicateStatementModal({ onDone });
  const { openWithValue: openResolveDuplicateStatementModal } = resolveDuplicatedStatement;

  const startDateErrorModal = useStartDateErrorModal();
  const { openWithValue: openStartDateErrorModal } = startDateErrorModal;

  const validationErrorModal = useValidationErrorModal();
  const { open: openValidationError } = validationErrorModal;

  const handleError = useDebouncedCallback((value) => {
    getDuplicatedBankStatement({
      businessId,
      reconciliationPaymentAccountId: value.reconciliationPaymentAccountId,
      date:                           value.date,
    }).then((res) => {
      const originBankStatement = res.bankStatement;
      if (originBankStatement && originBankStatement.state !== 'not_available') {
        openResolveDuplicateStatementModal({
          originBankStatement,
          statementDate:                  value.date,
          reconciliationPaymentAccountId: value.reconciliationPaymentAccountId,
          closingBalance:                 value.closingBalance,
          duplicateStatementFile:         value.file,
        });
      }
      setIsDuplicated(false);
    }).catch(() => {
      setIsDuplicated(false);
    });
  }, [], 300);

  const handleSubmit = useCallback((value: IBankStatementDetailsFormValues) => {
    if (!value.file) {
      setErrorMsg('Please Upload Bank Statement.');
      return;
    }

    createBankStatement({
      businessId,
      file:                           value.file,
      reconciliationPaymentAccountId: value.reconciliationPaymentAccountId,
      date:                           value.date,
      closingBalance:                 value.closingBalance,
    }, {
      onSuccess: () => handleDone(value.bankStatement?.id),
      onError:   (response) => {
        if (response.message
          === window.Docyt.Common.Constants.Messages
            .BANK_STATEMENT_UPLOAD_SAME_MONTH_ERROR_MSG) {
          handleError(value);
          setIsDuplicated(true);
        }

        if (value.bankStatement && response.message
          === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_START_DATE_ERROR_MSG) {
          openStartDateErrorModal({
            rcPaymentAccountId: value.reconciliationPaymentAccountId,
            closingBalance:     value.closingBalance,
            bankStatement:      value.bankStatement,
          });
        }

        if (value.bankStatement && response.message
          === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG) {
          openValidationError();
        }
      },
    });
  }, [createBankStatement,
    businessId,
    handleDone,
    handleError,
    openStartDateErrorModal,
    openValidationError,
  ]);

  useEffect(() => {
    setApproveAction(false);
  }, [isOpen]);

  return (
    <>
      {errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } />}
      <MutationStatus mutation={ create } />
      <MutationStatus mutation={ approve } />
      {isDuplicated && <Spinner />}
      <resolveDuplicatedStatement.Component
        businessId={ businessId }
        { ...resolveDuplicatedStatement.props }
      />
      <startDateErrorModal.Component
        businessId={ businessId }
        { ...startDateErrorModal.props }
      />
      <validationErrorModal.Component
        { ...validationErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG_TITLE }
      />
      <Modal
        showClose
        className={ styles['bank-statement-upload-modal'] }
        show={ isOpen }
        title="Upload Bank Statement"
        onCancel={ onCancel }
      >
        <Modal.Body>
          {isOpen && (
            <DetailForm
              bankStatement={ bankStatement }
              businessId={ businessId }
              formId={ formId }
              preSelectedDate={ preSelectedDate }
              preSelectedRcPaymentAccountId={ preSelectedRcPaymentAccountId }
              onSubmit={ handleSubmit }
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ onCancel }
          />
          {
            approveAction && (
              <Button
                bsColor="blue"
                className={ styles['save-and-approve-btn'] }
                form={ formId }
                type="submit"
              >
                <ThumbsUpIcon fontSize={ 18 } />
                <span className="m-l-10">Save and Approve</span>
              </Button>
            )
          }
          <div className={ styles['save-and-close-btn'] }>
            <Button
              bsColor="blue"
              form={ formId }
              type="submit"
              onClick={ () => setApproveAction(false) }
            >
              Save & Close
            </Button>
            <Button bsColor="blue" className={ styles['toggle-btn'] } onClick={ () => setApproveAction((prev) => !prev) }>
              <CaretIcon
                fontSize={ 14 }
                variant={ approveAction ? 'up' : 'down' }
              />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useBankStatementUploadModal = makeUseModal(BankStatementUploadModal);

export {
  IBankStatementUploadModalProps,
  useBankStatementUploadModal,
};
