import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useExportBudgetAsExcel } from '@src/hooks/queries/report_service/report_service_budget_items';
import { apiYearToDate, endOfLastYearApiDate } from '@src/utils/date_helpers';

import Dropdown from '@src/components/ui/dropdown';
import { EllipsisIcon } from '@src/components/utils/fa_icons';
import { ImportBudgetIcon, ExportBudgetIcon } from '@src/components/utils/icomoon';
import { useItemsSelector } from '@src/components/utils/items_selector';

import { useImportBudgetModal } from '../modal';

interface IAutoFillMenuProps {
  filterParams: any,
  onSetDraft: (value: boolean) => void,
  onCreateExportData: (result: boolean) => void,
  onSuccessUpload: (token: string) => void,
}

const AutoFillMenu = ({
  filterParams,
  onSetDraft,
  onCreateExportData,
  onSuccessUpload,
}: IAutoFillMenuProps): JSX.Element => {
  const selector = useItemsSelector();
  const downloadReports = useExportBudgetAsExcel();
  const { mutate } = downloadReports;
  const budget = useReportServiceBudgetContext();
  const importModal = useImportBudgetModal();

  const handleExport = () => {
    const startDateStr = apiYearToDate(`${budget.year}`) || `${budget.year}`;
    const endDateStr = endOfLastYearApiDate(new Date(`${budget.year + 1}`));

    if (filterParams.accountingClassId === '') {
      delete filterParams.accountingClassId;
    }

    mutate(
      // eslint-disable-next-line max-len
      { startDate: startDateStr, endDate: endDateStr, budgetID: budget.id.toString(), filter: filterParams },
      { onSuccess: () => onCreateExportData(true) },
    );
  };

  return (
    <div className="auto-fill-menu">
      <importModal.Component
        { ...importModal.props }
        successUpload={ onSuccessUpload }
        budget={ budget }
      />
      <Dropdown className="report-budget-export-action">
        <Dropdown.LinkToggle>
          <EllipsisIcon fontSize={ 18 } mt={ 5 } variant="horizontal" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu
          alignment="right"
        >
          <Dropdown.Item className="budget-action-button" onClick={ importModal.open }>
            <ImportBudgetIcon fontSize={ 22 } mr={ 14 } />
            Import
          </Dropdown.Item>
          <Dropdown.Item className="budget-action-button" onClick={ handleExport }>
            <ExportBudgetIcon fontSize={ 22 } mr={ 14 } />
            Export as excel
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AutoFillMenu;
