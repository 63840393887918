import React, { useEffect, useState } from 'react';

import { IVendor } from '@src/types/business_vendors';
import { TAmount, TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import { TextInput, AmountInput } from '@src/components/ui_v2/inputs';

import { useGetBusinessVendorCollection } from './hooks';
import SideViewVendorTable from './sideview_vendor_table';
import { IInitialData, ITransaction } from './types';
import { operatorValue } from './utils';

interface ISideViewProps {
    businessId: TID;
    initialData: IInitialData | null;
    selectedCondition: string;
    handleTransactionSelected: (transaction: ITransaction) => void;
    closeSideView: () => void;
    onVendorSelected: (vendor: IVendor) => void;
}

const Sideview = ({
  businessId,
  initialData,
  selectedCondition,
  handleTransactionSelected,
  closeSideView,
  onVendorSelected,
}: ISideViewProps) => {
  const [search, setSearch] = useState<string>('');
  const [transactionData, setTransactionData] = useState<ITransaction>({
    operator: initialData?.amountOperator || '',
    amount:   initialData?.amount || '',
  });
  const [vendorData, setVendorData] = useState<IVendor | undefined>();
  const collection = useGetBusinessVendorCollection({
    businessId,
    search,
    selectedRecordsId: initialData?.vendor?.id,
  });

  const handleVendorSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTransactionAmount = (e: TAmount | null) => {
    setTransactionData({ ...transactionData, amount: e });
  };

  const handleOperatorSelection = (selectedOperator: string) => {
    setTransactionData({ ...transactionData, operator: selectedOperator });
  };

  useEffect(() => {
    setVendorData(collection.selectedRecords[0]);
  }, [collection.selectedRecords]);

  const handleAddCondition = () => {
    if (vendorData && selectedCondition === 'Vendor') {
      onVendorSelected(vendorData);
    } else {
      handleTransactionSelected(transactionData);
    }
  };

  return (
    <div className="accounts-payable-rules-right-side-view">
      <div className="rules-content-wrapper">
        <div className="header-wrapper">
          <h1 className="font-18 font-bold">
            Add Condition:&nbsp;
            {selectedCondition}
          </h1>
          <a
            aria-label="Close view"
            className="close-view"
            role="button"
            tabIndex={ 0 }
            onClick={ closeSideView }
          >
            <span className="pointer icon-not-a-chargeback-icon font-25 in-black" />
          </a>
        </div>

        {selectedCondition === 'Vendor' ? (
          <div
            className="flex-full-height-container"
            id="rules-detail-region"
          >
            <div className="accounts-payable-rules-approvers-view rules-detail-setting-view">
              <div className="search-box-wrapper">
                <TextInput
                  className="form-control font-14 input-search-vendor-js"
                  placeholder="Search"
                  onChange={ handleVendorSearch }
                />
              </div>
              <div className="vendors-list-container">
                <SideViewVendorTable
                  infiniteQuery={ collection.query }
                  records={ collection.records }
                  section={ collection.section }
                  sorting={ collection.sorting }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="accounts-payable-rules-amount-condition-view">
            <div className="flex-full-height-containerr">
              <div className="amount-controls-wrapper">
                <span className="m-t-34 m-r-39">
                  Transaction Amount
                </span>
                <div className="flex-full-width">
                  <div className="m-t-22 relative">
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="dropdown-toggle select-condition-js"
                      data-container="body"
                      data-toggle="dropdown"
                      id="amount-condition-dropdown-btn"
                      type="button"
                    >
                      {
                        operatorValue[
                          transactionData.operator
                        ]
                    }
                    </button>
                    <div
                      aria-labelledby="amount-condition-dropdown-btn"
                      className="dropdown-menu dropdown-menu-round-border"
                    >
                      <span
                        className="dropdown-item font-14 more-than-js pointer"
                        role="button"
                        tabIndex={ 0 }
                        onClick={ () => handleOperatorSelection(
                          'greater_than',
                        ) }
                      >
                        More than
                      </span>
                      <span
                        className="dropdown-item font-14 less-than-js pointer"
                        role="button"
                        tabIndex={ 0 }
                        onClick={ () => handleOperatorSelection(
                          'less_than',
                        ) }
                      >
                        Less than
                      </span>
                      <span
                        className="dropdown-item font-14 equal-to-js pointer"
                        role="button"
                        tabIndex={ 0 }
                        onClick={ () => handleOperatorSelection(
                          'equal_to',
                        ) }
                      >
                        Equal to
                      </span>
                    </div>
                  </div>
                  <div className="m-t-12 relative">
                    <AmountInput
                      className="form-control"
                      placeholder="Amount"
                      value={ transactionData.amount }
                      onChange={ handleTransactionAmount }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="action-wrapper">
        <Button
          className="btn btn-blue ladda-button add-js"
          style={ { padding: '12px 36%' } }
          variant="primary"
          onClick={ () => handleAddCondition() }
        >
          <span className="ladda-label font-16 font-semi-bold">
            Add Condition
          </span>
        </Button>
      </div>
    </div>
  );
};

export default Sideview;
