import React from 'react';

import { useResetRecoilState } from 'recoil';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import { useVerifyBankFeedModal } from '@src/components/banking_accounts/bank_statements/bank_statement_details/verify_bank_feed/verify_bank_feed_modal';
import { addTransactionFieldsAtom } from '@src/components/banking_accounts/transactions/add_transaction/atoms';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  BankStatementApproved,
  BankStatementMissing,
  BankStatementRequested,
  BankStatementUploaded,
  BankStatementVerified,
} from '@src/components/utils/icomoon';

import styles from '../../../styles.module.scss';

interface IVerifiedStatusItemProps {
  businessId: TID,
  state: IReviewedBankStatementState,
}

const VerifiedStatusItem = ({
  businessId,
  state,
}: IVerifiedStatusItemProps) => {
  const resetAddTransactionFieldsAtom = useResetRecoilState(addTransactionFieldsAtom);

  const verifyBankFeedModal = useVerifyBankFeedModal({
    onDone:   resetAddTransactionFieldsAtom,
    onCancel: resetAddTransactionFieldsAtom,
  });

  if (!state.docytId) return null;

  const verifiedTooltip = (
    <div className="approved-status">
      <div className={ styles['state-status'] }>
        <div className={ styles['state-info'] }>
          <hr className={ styles['missing-requested-progress-line'] } />
          <BankStatementMissing fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>--</span>
          <span className="font-12">Statement missing</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['requested-uploaded-progress-green-line'] } />
          <BankStatementRequested fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.requestedAt ? formatDate(state.requestedAt) : '--'}</span>
          <span className="font-12">Statement requested</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['uploaded-approved-progress-green-line'] } />
          <BankStatementUploaded fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.uploadedAt ? formatDate(state.uploadedAt) : '--'}</span>
          <span className="font-12">Statement uploaded</span>
        </div>
        <div className={ styles['state-info'] }>
          <hr className={ styles['approved-verified-progress-green-line'] } />
          <BankStatementApproved fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.approvedAt ? formatDate(state.approvedAt) : '--'}</span>
          <span className="font-12">Statement approved</span>
        </div>
        <div className={ styles['state-info'] }>
          <BankStatementVerified fontSize={ 20 } mb={ 10 } />
          <span className={ styles['disabled-font-style'] }>{state.verifiedAt ? formatDate(state.verifiedAt) : '--'}</span>
          <span className="font-12">Bank feed verified</span>
        </div>
      </div>
      <div className={ styles['state-details'] }>
        <span>
          Status:
          { ' ' }
          <span className={ styles['sub-details'] }>Bank feed has been verified</span>
        </span>
        <br />
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ verifiedTooltip } tooltipClassName={ styles['state-item'] }>
      <BankStatementVerified fontSize={ 20 } />
    </Tooltip.Hover>
  );

  return (
    <>
      <verifyBankFeedModal.Component
        { ...verifyBankFeedModal.props }
        businessId={ businessId }
        docytId={ state.docytId }
      />
      <Button
        className={ styles['status-item-button'] }
        variant="primary"
        onClick={ verifyBankFeedModal.open }
      >
        { content }
      </Button>
    </>
  );
};

export default VerifiedStatusItem;
