import { string, number, object } from 'yup';

import { IBankStatement } from '@src/types/bank_statements';
import { TDate, TID } from '@src/types/common';

interface IBankStatementDetailsFormValues {
  bankStatement?: IBankStatement
  file?: File
  reconciliationPaymentAccountId?: TID,
  closingBalance?: string,
  date?: TDate,
}

interface IBankFeedTransactionsValues {
  ids: TID[],
}

const statementValidation = object({
  reconciliationPaymentAccountId: number().label('Account').required(),
  closingBalance:                 string().nullable().required('Closing Balance is required.'),
  date:                           string().required('Statement Date is required.'),
});

export {
  IBankStatementDetailsFormValues,
  IBankFeedTransactionsValues,
  statementValidation,
};
