import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

import { ISignUpFormValues, signUpValidation } from './schema';

import styles from './styles.module.scss';

interface IPayByBankAccountFormProps {
  formId?: string,
  email: string,
  onSubmit: SubmitHandler<ISignUpFormValues>,
}

const PayByBankAccountForm = ({
  formId,
  email,
  onSubmit,
}: IPayByBankAccountFormProps): JSX.Element => {
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ISignUpFormValues>({
    defaultValues: { email },
    resolver:      yupResolver(signUpValidation),
  });

  const handleDirectToSignInPage = useCallback(() => {
    Backbone.history.navigate('/', { trigger: true });
  }, []);

  return (
    <Form className={ styles['self-onboarding-sign-up-form'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ styles['sign-up-body'] }>
        <span className="display-flex p-l-10 p-b-10 font-16 font-bold">Create a password to secure the account</span>
        <Form.TextField
          hiddenLabel
          hideClear
          className={ styles['form-value'] }
          error={ errors.email?.message }
          label="Email"
          placeholder="Email"
          prefixIcon={ <span className="sign-up-mail-icon icon-mail" /> }
          { ...register('email') }
        />
        <Form.TextField
          hiddenLabel
          hideClear
          className={ styles['form-value'] }
          error={ errors.password?.message }
          label="Password"
          placeholder="Password"
          prefixIcon={ <span className="sign-up-key-icon icon-password-key-icon font-20" /> }
          type="password"
          { ...register('password') }
        />
        <Form.TextField
          hiddenLabel
          hideClear
          className={ styles['form-value'] }
          error={ errors.passwordConfirmation?.message }
          label="Confirm Password"
          placeholder="Confirm Password"
          prefixIcon={ <span className="sign-up-key-icon icon-password-key-icon font-20" /> }
          type="password"
          { ...register('passwordConfirmation') }
        />
        <p className={ styles['sign-in-note'] }>
          By continuing with your email id/mobile number, you accept
          { ' ' }
          <a href="https://www.docyt.com/terms/" rel="noreferrer" target="_blank">
            Docyt&apos;s Terms
          </a>
          { ' ' }
          &
          { ' ' }
          <a href="https://www.docyt.com/privacy/" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <div className={ styles['sign-up-footer'] }>
        <Button
          variant="link"
          onClick={ handleDirectToSignInPage }
        >
          Sign In
        </Button>
        <Button
          type="submit"
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </Form>
  );
};

export {
  IPayByBankAccountFormProps,
  PayByBankAccountForm as default,
};
