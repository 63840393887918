import React, { ComponentPropsWithoutRef } from 'react';

import { useRecoilState } from 'recoil';

import { dataExportPath } from '@src/routes';

import Alert from '@src/components/ui/alert';
import { DataExportInProgressIcon } from '@src/components/utils/icomoon';

import { isExportCSVCreatedFamily } from './atoms';

interface IExportCSVBannerProps extends Omit<ComponentPropsWithoutRef<typeof Alert>, 'children' | 'bsStyle'> {
  /**
   * Unique key for export CSV state. It should match the key passed to the pairing `ExportCSVAtom`.
   */
  atomKey: string,
}

const ExportCSVBanner = ({
  atomKey,
  ...props
}: IExportCSVBannerProps): JSX.Element | null => {
  const [isCreated] = useRecoilState(isExportCSVCreatedFamily(atomKey));

  if (!isCreated) return null;

  return (
    <Alert
      bsStyle="info"
      icon={ <DataExportInProgressIcon relative fontSize={ 18 } mr={ 8 } style={ { top: '3px' } } /> }
      { ...props }
    >
      Data export in-progress. When ready, you&apos;ll be notified and able to
      download it in the
      { ' ' }
      <a href={ dataExportPath() }>data export</a>
      { ' ' }
      section.
    </Alert>
  );
};

export default ExportCSVBanner;
