import { createContext, useContext } from 'react';

import { TSection } from '@src/types/common';

const SectionContext = createContext<TSection | null>(null);

const useSection = (section?: TSection): TSection => {
  const value = useContext(SectionContext);

  if (section) return section;
  if (!value) {
    throw new Error('"useSection" must be used within <SectionProvider /> or `section` param provided!');
  }

  return value;
};

export {
  SectionContext,
  useSection,
};
