import React from 'react';

import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';
import { formatDate } from '@src/utils/date_helpers';

import Text from '@src/components/ui/text';
import Tooltip from '@src/components/ui_v2/tooltip';
import { NoteTabletIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface NoteTooltipProps {
  item: IBankAccountReconciliationItem,
}

const NoteTooltip = ({ item }: NoteTooltipProps) => {
  const noteTooltipContent = (
    <>
      <Text as="p">{ item.note }</Text>
      <Text as="p" className={ styles['note-tooltip-author'] }>
        { item.acknowledgedBy
          && (
          <>
            { item.acknowledgedBy.parsedFullname }
            { ' ' }
            |
            { ' ' }
          </>
          )}
        { formatDate(item.acknowledgedOn) }
      </Text>
    </>
  );

  return (
    <Tooltip.Hover content={ noteTooltipContent } tooltipClassName={ styles['note-tooltip'] }>
      <NoteTabletIcon fontSize={ 16 } />
    </Tooltip.Hover>
  );
};

export default React.memo(NoteTooltip);
