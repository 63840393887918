import React from 'react';

import classNames from 'classnames';

import { AppLink } from '@src/components/ui_v2/buttons';

import styles from '../styles.module.scss';

interface IDashboardSideMenuItemProps {
  url: string,
  title: string,
  active: boolean,
}

const DashboardSideMenuItem = ({
  url,
  title,
  active,
}: IDashboardSideMenuItemProps) => {
  const classes = classNames(
    styles['side-menu-item'],
    {
      [styles['side-menu-item-active']]: active,
    },
  );
  return (
    <li className={ classes }>
      <AppLink className={ styles['side-menu-item-link'] } href={ url }>
        { title }
      </AppLink>
    </li>
  );
};

export default DashboardSideMenuItem;
