import React, { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IMuitiReportDetailColumn } from '@src/types/report_service/multi_report_detail';
import { IReport } from '@src/types/report_service/report';

import { ReportsExportTableSelectItemCell, BusinessReportsExportSelectTableItemHCell } from '@src/components/business_reports/common';
import Table from '@src/components/ui_v2/table';
import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

interface IBusinessMultiCustomReportsProps {
  multiReport:  IMultiBusinessReportDetail | undefined,
  query:        UseQueryResult<IMultiBusinessReportDetail, Error>
}

const BusinessMultiCustomReports = ({ multiReport, query }: IBusinessMultiCustomReportsProps) => {
  const colums = useMemo(() => (
    multiReport?.columns
  ), [multiReport]);

  const reports = useMemo(() => (
    multiReport?.reports
  ), [multiReport]);


  return (
    <ItemsSelectorProvider allItems={ reports as IReport[] }>
      <div className="custom-reports-table-wrapper">
        <Table query={ query }>
          <Table.Head>
            <Table.Row>
              <Table.HCell>
                Reports
              </Table.HCell>

              {colums?.map((col: IMuitiReportDetailColumn) => (
                <BusinessReportsExportSelectTableItemHCell key={ col.name } title={ col.name } />
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {reports?.map((report: IReport) => (
              <Table.Row key={ report.id }>
                <Table.Cell>{ report.name }</Table.Cell>

                { colums?.map((col: IMuitiReportDetailColumn) => (
                  <ReportsExportTableSelectItemCell key={ col.name } item={ report } />
                )) }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </ItemsSelectorProvider>
  );
}

export default BusinessMultiCustomReports;