import React from 'react';

import { IMetric } from '@src/types/metrics';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import ItemActions from './item_actions';

interface IMetricListItemActionsProps {
  metric: IMetric,
}

const MetricListItemActions = ({
  metric,
}: IMetricListItemActionsProps): JSX.Element => {
  return (
    <ActionsDropdown>
      <ItemActions metric={ metric } />
    </ActionsDropdown>
  );
};

export default MetricListItemActions;
