import React, { useMemo } from 'react';

import { IAccountingClass } from '@src/types/accounting_classes';
import { TFilterData } from '@src/types/filter';

import {
  IFilterFieldUIProps,
  FilterFieldWrapper,
  useFilterField,
  useFilterFieldProps,
} from '@src/components/ui/filter';
import { SelectInput, ISelectInputProps } from '@src/components/ui/form';

interface ISelectFilterFieldProps<TFilter extends TFilterData> extends
  Omit<ISelectInputProps, 'name' | 'value' | 'onChange' | 'className' | 'options'>,
  IFilterFieldUIProps
{
  name?: keyof TFilter,
  classes: IAccountingClass[],
}

const AccountingClassSelectFilterField = <TFilter extends TFilterData>({
  name = 'accountingClassId',
  classes,
  ...props
}: ISelectFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, string>(name);

  const [wrapperProps, inputProps] = useFilterFieldProps(props, { filtered: value });

  const options = useMemo(() => {
    let accountingClasses = classes.map((accountingClass) => ({
      value: `${accountingClass.id}`,
      name:  accountingClass.name,
    }));
    accountingClasses = [{ value: '', name: 'All Departments' }, ...accountingClasses];
    return accountingClasses;
  }, [classes]);

  return (
    <FilterFieldWrapper { ...wrapperProps }>
      <SelectInput
        options={ options }
        value={ value || '' }
        onChange={ update }
        { ...inputProps }
      />
    </FilterFieldWrapper>
  );
};

export default React.memo(AccountingClassSelectFilterField);
