import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITableHeadProps extends React.ComponentPropsWithoutRef<'thead'>, IUIStyleProps {
}

/**
 * @visibleName Table.Head
 */
const TableHead = ({
  children,
  ...props
}: ITableHeadProps) => {
  const [classes, tdProps] = uiStyleProps(
    undefined,
    props,
  );

  return (
    <thead
      className={ classes }
      { ...tdProps }
    >
      { children }
    </thead>
  );
};

export default TableHead;
