import React from 'react';

import Text from '@src/components/ui/text/text';

import EmailNotifications from './email_notifications';
import FinancialInsights from './financial_insights';

const Notifications = () => {
  return (
    <div className="m-t-50">
      <Text className="font-28">Notifications</Text>
      <div className="width-100-percent m-t-24 m-b-24">
        <FinancialInsights />
        <EmailNotifications />
      </div>
    </div>
  );
};

export default Notifications;
