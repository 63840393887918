import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IMetricsFilter } from './types';

interface IMetricsFilterProps {
  filter: IFilter<IMetricsFilter>;
}

const MetricsFilter = ({
  filter,
}: IMetricsFilterProps): JSX.Element => {
  return (
    <Filter<IMetricsFilter> filter={ filter }>
      <Filter.TextField
        name="name"
        placeholder="Metrics Name"
        widthPercent={ 40 }
      />
      <Filter.DateField
        name="lastUpdatedAt"
        widthPercent={ 20 }
      />
      <Filter.DateField
        name="startDate"
        widthPercent={ 20 }
      />
      <Filter.TextField
        hidden
        name="missedEntries"
        placeholder="Missed Entries"
      />
      <Filter.ResetButton />
    </Filter>
  );
};

export default React.memo(MetricsFilter);
