import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import RuleMatchedContainer from './rule_matched_container';

const EntryPoint: React.FC<{ id: TID }> = ({ id }) => {
  return (
    <Root>
      <RuleMatchedContainer id={ id } />
    </Root>
  );
};
export default EntryPoint;
