import React from 'react';

import classNames from 'classnames';

import Tooltip from '@src/components/ui_v2/tooltip';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ITableIconCellProps extends ITableCellProps {
  tooltip?: React.ReactNode,
  customTooltip?: React.ReactNode,
  children?: React.ReactNode,
}

const TableIconCell = ({
  className,
  children,
  tooltip,
  customTooltip,
  ...cellProps
}: ITableIconCellProps) => {
  const classes = classNames(styles['table-icon-cell'], className);

  let cellContent = (
    <div className={ styles['table-icon-cell-content'] }>
      { children }
    </div>
  );

  if (tooltip) {
    cellContent = (
      <Tooltip.Hover content={ tooltip }>
        { cellContent }
      </Tooltip.Hover>
    );
  }

  return (
    <Cell
      className={ classes }
      { ...cellProps }
    >
      { customTooltip || cellContent }
    </Cell>
  );
};

export default TableIconCell;
