import { useQuery, useMutation } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getAnalytics, putAverageBookkeepingRate } from '@src/requests/ai_analytics/ai_analytics';

import {
  IAnalyticsParams,
  IAnalyticsResponse,
  IAverageBookkeepingRateParams,
} from '@src/components/ai_analytics/types';

const useGetAnalytics = (
  params: IAnalyticsParams,
  shouldFetch: boolean = true,
) => {
  return useQuery<IAnalyticsResponse, Error>(
    [QueryKey.AiAnalytics, params],
    () => getAnalytics(params),
    {
      enabled: shouldFetch,
    },
  );
};

const usePutAverageBookkeepingRate = () => {
  return useMutation<void, Error, IAverageBookkeepingRateParams>(putAverageBookkeepingRate);
};

export { useGetAnalytics, usePutAverageBookkeepingRate };
