import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useGetInboxServiceDocuments } from '@src/hooks/queries/inbox_service_documents';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { IInboxServiceDocumentsFilter, TInboxServiceDocumentsSortColumn } from '@src/types/inbox_service_documents';
import { ISortingParams } from '@src/types/sorting';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import Filter, { IInboxDocumentsFilter } from './filter';
import Header from './header';
import List from './list';

interface IBusinessInboxDetailsProps {
  isTrash?: boolean,
}

const DefaultSorting: ISortingParams<TInboxServiceDocumentsSortColumn> = {
  orderColumn:    'uploaded',
  orderDirection: 'desc',
};

const getInboxParamsFromFilter = (
  filterData: IInboxDocumentsFilter | undefined,
): IInboxServiceDocumentsFilter | undefined => {
  if (!filterData || isEmpty(filterData)) return undefined;
  const filterParams = omit(filterData || {}, 'inboxDate');

  if (filterData?.inboxDate) {
    filterParams.inboxDateStart = filterData.inboxDate.gte;
    filterParams.inboxDateEnd = filterData.inboxDate.lte;
  }

  return filterParams;
};

const BusinessInboxDetails = ({
  isTrash,
}: IBusinessInboxDetailsProps): JSX.Element => {
  const inboxService = useInboxServiceContext();
  const filter = useFilter<IInboxDocumentsFilter>({});
  const sorting = useSorting<TInboxServiceDocumentsSortColumn>({ defaultSorting: DefaultSorting });

  const filterParams = useMemo(() => {
    return getInboxParamsFromFilter(filter.data);
  }, [filter.data]);

  const documentsQuery = useGetInboxServiceDocuments({
    inboxServiceId: inboxService.id,
    inTrash:        isTrash,
    filters:        filterParams,
    ...sorting.data,
  });

  const documentPages = documentsQuery.data?.pages || [];
  const documents = flatten(documentPages.map((p) => p.collection));
  const documentIds = flatten(documents).map((d) => d.id);

  return (
    <ItemsSelectorProvider allItems={ documentIds }>
      <Header
        isTrash={ Boolean(isTrash) }
      />
      <div className="inbox-list-table-wrapper m-t-30">
        <Filter
          filter={ filter }
        />
        <List
          documents={ documents }
          query={ documentsQuery }
          sorting={ sorting }
        />
      </div>
    </ItemsSelectorProvider>
  );
};

export {
  IBusinessInboxDetailsProps,
  BusinessInboxDetails as default,
};
