import React from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';
import TableSection from '@src/components/ui_v2/table_section';
import { FilterIcon } from '@src/components/utils/icomoon';

import ClearFilterItem from './settings_clear_filter_item';
import Item from './settings_item';
import { TColumn, TModel } from './types';

interface ICollectionTableSettingsProps<Model extends TModel, TSortColumn extends string = never> {
  columns: TColumn<Model, TSortColumn>[],
  hasFilter?: boolean,
  onToggleColumn?: (column: TColumn<Model, TSortColumn>, checked: boolean) => void,
}

const CollectionTableSettings = <Model extends TModel, TSortColumn extends string = never>({
  columns,
  hasFilter,
  onToggleColumn,
}: ICollectionTableSettingsProps<Model, TSortColumn>) => {
  return (
    <TableSection.Settings>
      { hasFilter && <ClearFilterItem /> }
      <Dropdown.Submenu
        icon={ <FilterIcon /> }
        title="Show/Hide Columns"
      >
        { columns.map((column) => (
          <Item
            key={ column.name }
            column={ column }
            onToggle={ onToggleColumn }
          />
        )) }
      </Dropdown.Submenu>
    </TableSection.Settings>
  );
};

export default React.memo(CollectionTableSettings) as typeof CollectionTableSettings;
