import React from 'react';

import { TID, TAmount } from '@src/types/common';

import Root from '@src/components/root';

import Actions from './actions';

interface IDocytWalletBalanceActionProps {
  businessId: TID,
  dwollaBalance: TAmount,
  bankAccountName: string,
}

const DocytWalletBalanceAction = ({
  businessId,
  dwollaBalance,
  bankAccountName,
}: IDocytWalletBalanceActionProps) => {
  return (
    <Root>
      <Actions
        bankAccountName={ bankAccountName }
        businessId={ businessId }
        dwollaBalance={ dwollaBalance }
      />
    </Root>
  );
};

export default DocytWalletBalanceAction;
