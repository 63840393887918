import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { currencyFormater3 } from '@src/utils/currency';
import { formatDate } from '@src/utils/date_helpers';

import { getInvoiceStatusLabel, getInvoiceStatusClass } from '@src/components/accounts_payable/invoice_queue/utils';

import InvoiceDueDate from './invoice_due_date';

import style from '../styles.module.scss';

interface IReviewPaymentTableItemProps {
  serviceDocument: IAccountsPayableServiceDocument,
  index: number
  rowSpan: number
}

const invoiceDueDate = ((serviceDocument: IAccountsPayableServiceDocument) => {
  return <InvoiceDueDate document={ serviceDocument } />;
});

const ReviewPaymentTableItem = ({
  serviceDocument,
  index,
  rowSpan,
}: IReviewPaymentTableItemProps) => {
  const status = getInvoiceStatusLabel(serviceDocument.state);
  const statusClass = getInvoiceStatusClass(serviceDocument, status);

  const verifyingInvoice =
    serviceDocument.state === window.configData.ap_invoice_states.DOCUMENT_STATE_VERIFYING;
  const greyClassStyle = `${style['opacity-033']} in-grey-1100`;

  let address = '';
  if (serviceDocument?.vendorAddress) {
    address = [
      serviceDocument?.vendorAddress.addressLine2 ? `${serviceDocument?.vendorAddress.addressLine1}, ${serviceDocument?.vendorAddress.addressLine2}` : serviceDocument?.vendorAddress.addressLine1,
      serviceDocument?.vendorAddress.addressCity,
      serviceDocument?.vendorAddress.addressState,
      serviceDocument?.vendorAddress.addressCountry,
      serviceDocument?.vendorAddress.addressZip,
    ].join(',');
  }

  return (
    <tr>
      { index === 0 && (
        <td className={ style['vendor-name'] } rowSpan={ rowSpan }>
          <div className="display-flex-column">
            <span className="font-bold font-16">{ serviceDocument.vendor?.name }</span>
            <span>{ address }</span>
          </div>
        </td>
      ) }
      <td className={ index === 0 ? style['first-row'] : '' }>{serviceDocument.invoiceNumber}</td>
      <td className={ index === 0 ? style['first-row'] : '' }>
        <span className={ `invoice-status-panel font-bold text-nowrap ${statusClass}` }>
          {status}
        </span>
      </td>
      <td className={ index === 0 ? style['first-row'] : '' }>
        { serviceDocument.accountNumber }
      </td>
      <td className={ index === 0 ? style['first-row'] : '' }>
        { formatDate(serviceDocument.invoiceDate) }
      </td>
      <td className={ index === 0 ? style['first-row'] : '' }>
        { invoiceDueDate(serviceDocument) }
      </td>
      <td className={ index === 0 ? style['first-row'] : '' }>
        <span className={ `pull-right ${verifyingInvoice && greyClassStyle}` }>
          {currencyFormater3(serviceDocument.invoiceAmount)}
        </span>
      </td>
    </tr>
  );
};

export default React.memo(ReviewPaymentTableItem);
