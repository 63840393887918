module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, or_hint, ref;
    
      __out.push('<div class="client__settings-panel-heading" id="standard_document_profile-big-accordion-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">\n    <div aria-controls="standard_document_profile-accordion-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" class="client__settings-panel-title background-transparent collapsed" data-target="#standard_document_profile-accordion-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" data-toggle="collapse" aria-expanded="true">\n      <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n    </div>\n</div>\n<div aria-labelledby="standard_document_profile-big-accordion-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" class="collapse bg-grey-150" id="standard_document_profile-accordion-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('" aria-expanded="true" style="">\n  ');
    
      ref = this.or_field_hints;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        or_hint = ref[index];
        __out.push('\n  <div class="client__settings-panel-heading" id="or-field-hint-big-accordion-');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n      <div aria-controls="or-field-hint-accordion-');
        __out.push(__sanitize(or_hint.id));
        __out.push('" class="background-transparent font-12 p-b-5 p-t-5 text-center pointer" data-target="#or-field-hint-accordion-');
        __out.push(__sanitize(or_hint.id));
        __out.push('" data-toggle="collapse" aria-expanded="true">\n        <span>Hint ');
        __out.push(__sanitize(index + 1));
        __out.push('</span>\n        <a class="pointer pull-right m-r-10 delete-hint-js" data-index="');
        __out.push(__sanitize(index));
        __out.push('">\n          <i class="fa fa-close"></i>\n        </a>\n      </div>\n  </div>\n  <div aria-labelledby="or-field-hint-big-accordion-');
        __out.push(__sanitize(or_hint.id));
        __out.push('" class="collapse background-white padding-20" id="or-field-hint-accordion-');
        __out.push(__sanitize(or_hint.id));
        __out.push('" aria-expanded="true" style="">\n    ');
        if (this.data_type_enabled) {
          __out.push('\n    <div class="m-t-10">\n        <label>Data Type:</label>\n        <select class="data-type-input" data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n          <option value="date" ');
          __out.push(__sanitize(or_hint.hint_data_type === 'date' ? "selected" : ""));
          __out.push('>date</option>\n          <option value="date_terms" ');
          __out.push(__sanitize(or_hint.hint_data_type === 'date_terms' ? "selected" : ""));
          __out.push('>date_terms</option>\n        </select>\n    </div>\n    <div class="m-t-10 field-hint-value-input-row ');
          __out.push(__sanitize(or_hint.hint_data_type === 'date_terms' ? '' : ' hidden'));
          __out.push('">\n        <label>Value:</label>\n        <input class="field-hint-value-input" value="');
          __out.push(__sanitize(or_hint.hint_value));
          __out.push('" data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n    </div>\n    ');
        }
        __out.push('\n    <div class="m-t-10">\n        <label>Coordinates:</label>\n        <input class="width-60px field-hint-start-x" disabled value="');
        __out.push(__sanitize(or_hint.coordinates.x_1));
        __out.push('" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n        <input class="width-60px field-hint-start-y" disabled value="');
        __out.push(__sanitize(or_hint.coordinates.y_1));
        __out.push('" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n    </div>\n    <div class="m-t-10">\n        <label class="in-white">Coordinates:</label>\n        <input class="width-60px field-hint-end-x" disabled value="');
        __out.push(__sanitize(or_hint.coordinates.x_2));
        __out.push('" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n        <input class="width-60px field-hint-end-y" disabled value="');
        __out.push(__sanitize(or_hint.coordinates.y_2));
        __out.push('" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n    </div>\n    <div class="m-t-10">\n        <label>Extracted Value:</label>\n        <input class="extracted-values"  data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">\n        <button class="btn btn-primary btn-sm pull-right m-t-18 extract-standard-document-profile-btn ladda-button" data-style="zoom-in" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('"><span class="ladda-label">Extract</span></button>\n    </div>\n    <div class="m-t-10">\n        <button class="btn btn-primary btn-sm use-box-btn" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">Use Box</button>\n        <button class="btn btn-primary btn-sm show-box-btn" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">Show Box</button>\n        <button class="btn btn-primary btn-sm clear-box-btn" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('">Clear Fields</button>\n    </div>\n    <div class="proximity-area" id="proximity-area-');
        __out.push(__sanitize(or_hint.id));
        __out.push('"></div>\n    ');
        if (this.special_char_required) {
          __out.push('\n    <div class="m-t-10">\n        <label>Length:</label>\n        <input class="accepted-length-values" value="');
          __out.push(__sanitize(or_hint.accepted_length));
          __out.push('" data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n    </div>\n    ');
        }
        __out.push('\n    <div class="m-t-10">\n        <label>Use Last Occurrence:</label>\n        <input class="accepted-use-last-value" type="checkbox" data-hint-id="');
        __out.push(__sanitize(or_hint.id));
        __out.push('" ');
        __out.push(__sanitize(or_hint.use_last === 1 ? "checked" : ""));
        __out.push('>\n    </div>\n    ');
        if (this.special_char_required) {
          __out.push('\n    <div class="m-t-10">\n        <label>Special Chars:</label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value="digit" type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('digit') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>digit</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value="alphabet" type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('alphabet') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>alphabet</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value="space" type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('space') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>space</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value="-" type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('-') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>dash</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value="." type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('.') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>dot</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value=\'\\\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('\\') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>backslash</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value=\'_\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('_') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>underscore</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n        <label></label>\n        <label class="checkbox-wrapper m-b-1">\n            <input class="accepted-chars-input" data-value=\':\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf(':') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n            <span>colon</span>\n        </label>\n    </div>\n    <div class="m-t-10">\n      <label></label>\n      <label class="checkbox-wrapper m-b-1">\n        <input class="accepted-chars-input" data-value=\'*\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('*') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n        <span>asterisk</span>\n      </label>\n    </div>\n    <div class="m-t-10">\n      <label></label>\n      <label class="checkbox-wrapper m-b-1">\n          <input class="accepted-chars-input" data-value=\'#\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('#') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n          <span>number sign</span>\n      </label>\n    </div>\n    <div class="m-t-10">\n      <label></label>\n      <label class="checkbox-wrapper m-b-1">\n          <input class="accepted-chars-input" data-value=\'/\' type="checkbox" ');
          __out.push(__sanitize(or_hint.accepted_chars.indexOf('/') >= 0 ? "checked" : ""));
          __out.push(' data-hint-id="');
          __out.push(__sanitize(or_hint.id));
          __out.push('">\n          <span>slash</span>\n      </label>\n    </div>\n    ');
        }
        __out.push('\n  </div>\n  ');
      }
    
      __out.push('\n  <div class="background-white p-b-10 p-t-10 text-center">\n      <button class="btn btn-primary add-new-or-hint">Add New</button>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}