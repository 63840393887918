import React, { useCallback } from 'react';

import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useBulkDeleteInboxServiceDocuments } from '@src/hooks/queries/inbox_service_documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteDocumentsPermanentlyModal } from '@src/components/common/confirm_delete';
import { LinkButton } from '@src/components/ui/buttons';

interface IBusinessInboxPermanentDeleteActionProps {
  documentIds: number[],
  onDone: () => void,
}

const BusinessInboxPermanentDeleteAction = ({
  documentIds,
  onDone,
}: IBusinessInboxPermanentDeleteActionProps) => {
  const service = useInboxServiceContext();

  const deleteDocuments = useBulkDeleteInboxServiceDocuments();
  const bulkActionModal = useBulkActionModal({
    mutation: deleteDocuments,
    onDone,
  });

  const { runMutation } = bulkActionModal;
  const handleConfirm = useCallback(() => {
    runMutation(documentIds.map((id) => ({
      documentIds:    [id],
      inboxServiceId: service.id,
    })));
  }, [documentIds, service.id, runMutation]);

  const confirm = useConfirmDeleteDocumentsPermanentlyModal({
    documentIds,
    onDone: handleConfirm,
  });

  return (
    <>
      <confirm.Component { ...confirm.props } />
      <bulkActionModal.Component
        { ...bulkActionModal.props }
        itemsTitle="Documents"
        title="Delete Documents"
      />
      <LinkButton
        disabled={ documentIds.length === 0 }
        fontSize={ 16 }
        fontVariant="semi-bold"
        style={ documentIds.length > 0 ? { color: '#EF3A3A' } : {} }
        title="Delete"
        onClick={ confirm.open }
      />
    </>
  );
};

export default BusinessInboxPermanentDeleteAction;
