import React, { useCallback } from 'react';

import { clientInfoPath } from '@src/routes';
import { IClientBusiness } from '@src/types/clients';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Table from '@src/components/ui/table';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

const clientAvatar = (
  clientBusiness: IClientBusiness,
) => {
  if (clientBusiness.imageUrl === null) {
    return <BusinessDefaultAvatarIcon fontSize={ 48 } />;
  }

  return (
    <div className="display-flex middle-avatar-wrapper border-gray">
      <img
        alt="Avatar"
        src={ clientBusiness.imageUrl }
      />
    </div>
  );
};

interface IClientBusinessAvatarProps {
  clientBusiness: IClientBusiness;
}

const ClientBusinessAvatar = ({
  clientBusiness,
}: IClientBusinessAvatarProps) => {
  const handleClick = useCallback(() => {
    backboneNavigateToUrl(
      clientInfoPath(clientBusiness.clientBusinessId, clientBusiness.businessId),
    );
  }, [clientBusiness.businessId, clientBusiness.clientBusinessId]);

  return (
    <Table.Cell
      pointer
      className={ styles['avatar-column'] }
      widthPercent={ 5 }
      onClick={ handleClick }
    >
      { clientAvatar(clientBusiness) }
    </Table.Cell>
  );
};

export default ClientBusinessAvatar;
