import React from 'react';

import Drawer from 'react-modern-drawer';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TID } from '@src/types/common';

import Content from '@src/components/chart_of_accounts/edit_btn/content';
import Modal from '@src/components/ui/modal';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/chart_of_accounts/add_btn/styles.module.scss';

interface IEditModalProps extends IUseModalProps {
  businessId: TID,
  id: TID
}

const EditModal = (props: IEditModalProps) => {
  const { id, businessId, isOpen, onDone } = props;
  return (
    <Modal.Standard
      className={ styles['modal-container'] }
      show={ isOpen }
      title=""
      onCancel={ onDone }
      onProceed={ () => {} }
    >
      {() => (
        <Drawer
          key={ id }
          open
          direction="right"
          size="30vw"
          onClose={ onDone }
        >
          <div className={ styles.container }>
            <div className={ styles.header }>
              <div>Edit Category</div>
              <NotAChargebackIcon pointer fontSize={ 20 } onClick={ onDone } />
            </div>

            <div className={ styles['form-container'] }>
              <Content key={ id } businessId={ businessId } close={ onDone } id={ id } />
            </div>
          </div>
        </Drawer>
      )}
    </Modal.Standard>
  );
};

const useEditModal = makeUseModal(EditModal);

export default useEditModal;
