import React from 'react';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import BankStatementProvider from '@src/components/providers/bank_statement_provider';
import ReconciliationPaymentAccountProvider from '@src/components/providers/reconciliation_payment_account_provider';

import Table from './table';

interface IWrapperProps {
  reconciliationPaymentAccount: IReconciliationPaymentAccount,
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[],
}

const Wrappers = ({ reconciliationPaymentAccount, reconciliationPaymentAccounts }: IWrapperProps) => {
  const { bankStatementId } = useBankAccountReconciliationContext();
  const { id: businessId } = useBusinessContext();

  let contents = <Table reconciliationPaymentAccounts={ reconciliationPaymentAccounts } />;
  if (bankStatementId) {
    contents = (
      <BankStatementProvider bankStatementId={ bankStatementId } businessId={ businessId }>
        <Table reconciliationPaymentAccounts={ reconciliationPaymentAccounts } />
      </BankStatementProvider>
    );
  }

  return (
    <ReconciliationPaymentAccountProvider
      reconciliationPaymentAccount={ reconciliationPaymentAccount }
    >
      { contents }
    </ReconciliationPaymentAccountProvider>
  );
};

export default React.memo(Wrappers);
