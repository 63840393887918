module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n  <div class="header-menu">\n    <a class=\'header-logo-initial-view\' href=\'/\'>\n      <img src="');
    
      __out.push(__sanitize(configData.logoURL));
    
      __out.push('" class="width-60px-important">\n    </a>\n  </div>\n</header>\n<div class="container onboarding-user-initial-view-container">\n  <div class="m-t-50 relative text-right header-panel m-l-auto m-r-auto">\n    <h1 class="font-24 font-semi-bold text-center">');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('first_name')));
    
      __out.push(', Welcome to Docyt!</h1>\n  </div>\n  <div class="text-center m-l-auto m-r-auto">\n    <h3 class="font-20 m-t-0 m-b-0">Tell us more about how you wish to use Docyt.</h3>\n    <div class="m-t-50">\n      <img src="');
    
      __out.push(__sanitize(configData.images.shop_image));
    
      __out.push('" class="width-120px">\n    </div>\n    <div class="user-type-selection-list">\n      <div class="radio blue-radio">\n        <input type="radio" name="user-type" id="business-user-radio" class="pointer" tabindex="0" value="');
    
      __out.push(__sanitize(configData.business_user_onboarding_status));
    
      __out.push('" checked>\n        <label for="business-user-radio" class="pointer">\n          <span class="font-16 font-semi-bold">Business Owner / Partner</span>\n          <br>\n          <span class="font-14">Use Docyt for your own business</span>\n        </label>\n      </div>\n      <div class="radio blue-radio">\n        <input type="radio" name="user-type" id="account-user-radio" class="pointer" tabindex="1" value="">\n        <label for="account-user-radio" class="pointer">\n          <span class="font-16 font-semi-bold">Accounting / Tax / Bookkeeping Firm</span>\n          <br>\n          <span class="font-14">Use Docyt for your clients and your firm</span>\n        </label>\n      </div>\n      <div class="radio blue-radio">\n        <input type="radio" name="user-type" id="employee-user-radio" class="pointer" tabindex="2" value="');
    
      __out.push(__sanitize(configData.individual_user_onboarding_status));
    
      __out.push('">\n        <label for="employee-user-radio" class="pointer">\n          <span class="font-16 font-semi-bold">Employee / Personal</span>\n          <br>\n          <span class="font-14">If your employer uses Docyt</span>\n        </label>\n      </div>\n      <div class="radio blue-radio">\n        <input type="radio" name="user-type" id="vendor-user-radio" class="pointer" tabindex="3" value="');
    
      __out.push(__sanitize(configData.receivable_only_user_onboarding_status));
    
      __out.push('">\n        <label for="vendor-user-radio" class="pointer">\n          <span class="font-16 font-semi-bold">Receivables Only Account</span>\n          <br>\n          <span class="font-14">If you are getting paid by a Docyt user and want to track invoices.<br/>You can upgrade to a paid Docyt Account anytime.</span>\n        </label>\n      </div>\n      <button type=\'button\' class="btn btn-blue continue-account-btn ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Next</span></button>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}