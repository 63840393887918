import React from 'react';

import classNames from 'classnames';

import { Button } from '@src/components/ui/buttons';
import Text from '@src/components/ui/text';
import { CloseIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IFullPageHeaderProps {
  children?: React.ReactNode,
  title: React.ReactNode,
  subtitle?: React.ReactNode,
  divider?: 'horizontal' | 'vertical' | 'both',
  onCancel?: () => void,
}

const FullPageHeader = ({
  children,
  title,
  onCancel,
  subtitle,
  divider = 'both',
}: IFullPageHeaderProps): JSX.Element => {
  const classes = classNames(
    styles.header,
    divider && styles[`header-divider-${divider}`],
  );
  return (
    <div className={ classes }>
      <div className={ styles.title }>
        <Text as="h2">
          { title }
          { subtitle && <Text as="p" className={ styles.subtitle }>{ subtitle }</Text> }
        </Text>
      </div>
      <div className={ styles['header-actions'] }>
        { children }
        <Button className="close" title="" onClick={ onCancel }>
          <CloseIcon fontSize={ 38 } fontVariant="bold" />
        </Button>
      </div>
    </div>
  );
};

export default FullPageHeader;
