import React from 'react';

import classNames from 'classnames';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';

import { currencyLocaleFormatter } from '@src/utils/currency';

import { ICategorySplitsData } from './schema';

import styles from './styles.module.scss';

interface ICategorySplitsSummaryProps {
  form: UseFormReturn<ICategorySplitsData>,
  title?: string
}

const CategorySplitsSummary = ({
  form,
  title = 'Adjustment entry',
}: ICategorySplitsSummaryProps) => {
  const { control } = form;

  const adjustmentAmount = useWatch({ control, name: 'adjustmentAmount' });
  const totalAmount = useWatch({ control, name: 'totalAmount' });
  const differenceAmount = useWatch({ control, name: 'differenceAmount' });

  const { errors: differenceAmountErrors } = useFormState({ control, name: 'differenceAmount' });

  const differenceClasses = classNames(
    styles.amount,
    { [styles.error]: Boolean(differenceAmountErrors.differenceAmount) },
  );

  return (
    <div className={ styles.summary }>
      <span className={ styles.amount }>
        Total amount:
        { ' ' }
        { currencyLocaleFormatter(totalAmount) }
      </span>
      <span className={ styles.amount }>
        { title }
        {' '}
        amount:
        { ' ' }
        { currencyLocaleFormatter(adjustmentAmount) }
      </span>
      <span className={ differenceClasses }>
        Difference:
        { ' ' }
        { currencyLocaleFormatter(differenceAmount) }
      </span>
    </div>
  );
};

export default React.memo(CategorySplitsSummary);
