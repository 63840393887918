import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { Transition } from 'react-transition-group';

import Dialog, { IBottomModalDialogProps } from './dialog';

interface IBottomModalProps extends Omit<IBottomModalDialogProps, 'state'> {
  isOpen?: boolean,
  region?: string,
}

const BottomModal = ({
  isOpen,
  region = 'modal-region',
  ...props
}: IBottomModalProps): JSX.Element => {
  const modalRegionRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    modalRegionRef.current = document.getElementById(region);
  }, [region]);

  return (
    <Transition
      mountOnEnter
      unmountOnExit
      in={ isOpen }
      timeout={ 150 }
    >
      {
        (state) => {
          if (!modalRegionRef.current) return null;

          return ReactDOM.createPortal(
            <Dialog state={ state } { ...props } />,
            modalRegionRef.current,
          );
        }
      }
    </Transition>
  );
};

export {
  IBottomModalProps,
  BottomModal as default,
};
