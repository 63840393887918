module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="modal fade modal-middle" tabindex="-1" role="dialog">\n  <div class="modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body select-business-modal-body">\n        <ul>\n          ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n          <li class="li-link-business">\n            <a class="link-business pointer" data-business-id="');
        __out.push(__sanitize(business.business_id || business.id));
        __out.push('">\n              <div class="middle-avatar-wrapper border-gray m-r-22">\n                ');
        if (business && business.avatar) {
          __out.push('\n                <img src="https://');
          __out.push(__sanitize(configData.bucketName));
          __out.push('.s3.amazonaws.com/');
          __out.push(__sanitize(business.avatar.s3_object_key));
          __out.push('">\n                ');
        } else {
          __out.push('\n                <span class=\'icon-business-default-avatar font-48\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                ');
        }
        __out.push('\n              </div>\n              <p class="font-16 font-bold m-b-0 in-black">');
        __out.push(__sanitize(business.legal_name));
        __out.push('</p>\n            </a>\n          </li>\n          ');
      }
    
      __out.push('\n        </ul>\n      </div>\n      <div class="modal-footer">\n        <button type="button" id=\'done-btn\' class="btn btn-blue btn-xlarge font-16">Done</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}