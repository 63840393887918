import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IPlusIconProps extends IIcomoonIconProps {
  variant?: 'o',
}

const PlusIcon = ({
  variant,
  ...props
}: IPlusIconProps) => {
  let iconName = 'plus';
  if (variant === 'o') {
    iconName = 'o-plus';
  }

  return (
    <Icomoon
      { ...props }
      iconName={ iconName }
    />
  );
};

export default PlusIcon;
