import React from 'react';

import { Control, FieldErrors, useController } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';
import { CloseIcon, UnverifiedIcon } from '@src/components/utils/icomoon';

import { IFormValues } from './schema';

import styles from '../styles.module.scss';

interface IAddBusinessFieldProps {
  control: Control<IFormValues, object>,
  index: number,
  errors: FieldErrors<IFormValues>,
  onRemove: () => void,
}

const AddBusinessField = ({
  control,
  index,
  errors,
  onRemove,
}: IAddBusinessFieldProps): JSX.Element => {
  const businessNameController = useController({ name: `newBusinesses.${index}.businessName`, control });
  const einLetterController = useController({ name: `newBusinesses.${index}.einLetterFile`, control });
  const businessLicenseController = useController({ name: `newBusinesses.${index}.businessLicenseFile`, control });
  const driverLicenseController = useController({ name: `newBusinesses.${index}.driverLicenseFile`, control });

  return (
    <div className={ styles['modal-form-details'] }>
      <CloseIcon onClick={ onRemove } />
      <Form.TextField
        hiddenLabel
        className={ styles['add-business-input'] }
        error={ errors.newBusinesses && errors.newBusinesses[index]?.businessName?.message }
        label="Business Name"
        placeholder="Enter name for Business"
        { ...businessNameController.field }
      />
      <Form.FileField
        className={ styles['upload-file-input'] }
        label={ (
          <>
            EIN Letter
            <UnverifiedIcon fontSize={ 14 } ml={ 2 } />
          </>
        ) }
        labelClass={ styles['upload-file-label'] }
        valueProps={ { className: styles['upload-file-value'] } }
        { ...einLetterController.field }
      />
      <Form.FileField
        className={ styles['upload-file-input'] }
        label={ (
          <>
            Business License
            <UnverifiedIcon fontSize={ 14 } ml={ 2 } />
          </>
        ) }
        labelClass={ styles['upload-file-label'] }
        valueProps={ { className: styles['upload-file-value'] } }
        { ...businessLicenseController.field }
      />
      <Form.FileField
        className={ styles['upload-file-input'] }
        label={ (
          <>
            Authorized Signatory Driver&apos;s License
            <UnverifiedIcon fontSize={ 14 } ml={ 2 } />
          </>
        ) }
        labelClass={ styles['upload-file-label'] }
        valueProps={ { className: styles['upload-file-value'] } }
        { ...driverLicenseController.field }
      />
    </div>
  );
};

export {
  IAddBusinessFieldProps,
  AddBusinessField as default,
};
