import { QueryKey } from '@src/constants/query_keys';
import {
  IGetExpenseReportsServiceDocumentsParams,
  IGetExpenseReportsServiceDocumentsResponse,
  getExpenseReportsServiceDocuments,
} from '@src/requests/expense_reports/expense_reports_service_documents';
import { IExpenseReportsServiceDocument } from '@src/types/expense_reports/expense_reports_service_documents';

import {
  createUseGetInfiniteCollection,
} from '../infinite_collection_queries';

const useGetExpenseReportsServiceDocuments = createUseGetInfiniteCollection<
  IExpenseReportsServiceDocument,
  IGetExpenseReportsServiceDocumentsParams,
  IGetExpenseReportsServiceDocumentsResponse
>({
  queryKey: QueryKey.ExpenseReportsServiceDocuments,
  request:  getExpenseReportsServiceDocuments,
});

export {
  useGetExpenseReportsServiceDocuments,
};
