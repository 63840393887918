import { useMutation } from 'react-query';

import {
  IUpdateManagementGroupSettingsParams,
  updateManagementGroupSettings,
} from '@src/requests/user_management_group_settings';

const useUpdateManagementGroupSettings = () => {
  return useMutation<void, Error, IUpdateManagementGroupSettingsParams>(
    updateManagementGroupSettings,
  );
};

export {
  useUpdateManagementGroupSettings,
};
