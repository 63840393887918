import { QueryKey } from '@src/constants/query_keys';
import {
  getAccountsReceivableServiceDocuments,
  IGetAccountsReceivableServiceDocumentsParams,
  IGetAccountsReceivableServiceDocumentsResponse,
} from '@src/requests/accounts_receivable_service_documents';
import { IAccountsReceivableServiceDocument } from '@src/types/accounts_receivable_service_documents';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const useGetAccountsReceivableServiceDocuments = createUseGetInfiniteCollection<
  IAccountsReceivableServiceDocument,
  IGetAccountsReceivableServiceDocumentsParams,
  IGetAccountsReceivableServiceDocumentsResponse
>({
  queryKey: QueryKey.accountsReceivableServiceDocuments,
  request:  getAccountsReceivableServiceDocuments,
});

export {
  useGetAccountsReceivableServiceDocuments,
};
