import React, { FC, ReactNode, useState } from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { CloseIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface ComponentProps {
  children: ReactNode;
}

const Component: FC<ComponentProps> = ({ children }) => {
  const [close, setClose] = useState<boolean>(false);

  if (close) return null;

  return (
    <div className={ styles.alert }>
      { children }

      <Button className={ styles['alert-close'] } variant="link" onClick={ () => setClose(true) }>
        <CloseIcon fontSize={ 30 } inColor="black" />
      </Button>
    </div>
  );
};

export default Component;
