import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getReconciliationPaymentAccounts,
  IGetReconciliationPaymentAccountsParams,
  IGetReconciliationPaymentAccountsResponse,
  putReconciliationPaymentAccount,
  IPutReconciliationPaymentAccountParams,
  IPutReconciliationPaymentAccountResponse,
  IGetCreditCardsParams,
  getCreditCardAccounts,
  IGetCreditCardsResponse,
  IGetReconciliationPaymentAccountByIdParams,
  IGetReconciliationPaymentAccountByIdResponse,
  getReconciliationPaymentAccountById,
} from '@src/requests/reconciliation_payment_accounts';
import { TID } from '@src/types/common';

import { accountsToOptions } from '@src/components/common_v2/filter_fields/payment_account_filter_field';

const DummyRPAResponse = {
  reconciliationPaymentAccounts: [],
  meta:                          {
    archivedReconciliationPaymentAccountDocumentsCount: 0,
    archivedReconciliationPaymentAccountsCount:         0,
    nonSystemReconciliationPaymentAccounts:             0,
  },
};

const useGetReconciliationPaymentAccounts = (params: IGetReconciliationPaymentAccountsParams) => {
  return useQuery<IGetReconciliationPaymentAccountsResponse, Error>(
    [QueryKey.reconciliationPaymentAccounts, params],
    () => getReconciliationPaymentAccounts(params),
  );
};

const useGetCreditCardAccounts = (params: IGetCreditCardsParams) => {
  return useQuery<IGetCreditCardsResponse, Error>(
    [QueryKey.reconciliationPaymentAccounts, params],
    () => getCreditCardAccounts(params),
  );
};

const useGetReconciliationPaymentAccountById = (params: IGetReconciliationPaymentAccountByIdParams) => {
  return useQuery<IGetReconciliationPaymentAccountByIdResponse, Error>(
    [QueryKey.reconciliationPaymentAccounts, params],
    () => getReconciliationPaymentAccountById(params),
  );
};

const useUpdateReconciliationPaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IPutReconciliationPaymentAccountResponse,
    Error,
    IPutReconciliationPaymentAccountParams
  >(
    putReconciliationPaymentAccount,
    {
      onSuccess: (response, params) => {
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
        queryClient.setQueriesData(
          QueryKey.reconciliationPaymentAccounts,
          (
            data?: IGetReconciliationPaymentAccountsResponse,
          ): IGetReconciliationPaymentAccountsResponse => {
            if (!data) return DummyRPAResponse;

            return {
              ...data,
              reconciliationPaymentAccounts: data.reconciliationPaymentAccounts.map((account) => {
                if (account.id !== params.accountId) return account;

                return response.reconciliationPaymentAccount;
              }),
            };
          },
        );
      },
    },
  );
};

// These changes are implemented to ensure that both MultiBankAccountsFilterField
// (for tooltip content, similar to other filter components) and MultiBankAccountsInput
// (for select's options) have access to the account options. By leveraging
// react-query's caching mechanism, this setup efficiently avoids redundant API calls,
// even when the hook is utilized in two distinct components.
const useReconciliationAccountOptions = (businessId: TID) => {
  const reconciliationAccountsQuery = useGetReconciliationPaymentAccounts({
    businessId,
    noConsiderArchive: true,
  });

  const bankingAccountOptions = useMemo(() => {
    return accountsToOptions(reconciliationAccountsQuery.data?.reconciliationPaymentAccounts || []);
  }, [reconciliationAccountsQuery.data?.reconciliationPaymentAccounts]);

  return bankingAccountOptions;
};

export {
  useGetReconciliationPaymentAccounts,
  useGetReconciliationPaymentAccountById,
  useUpdateReconciliationPaymentAccount,
  useGetCreditCardAccounts,
  useReconciliationAccountOptions,
};
