import { TID, TMongoID } from '@src/types/common';
import { IManagementGroupDashboard } from '@src/types/dashboards/dashboards';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetManagementGroupDashboardsParams {
  filters?: object,
  page?: number,
  perPage?: number,
}

interface IGetManagementGroupDashboardsResponse {
  meta: {
    totalCount: number,
  },
  collection: IManagementGroupDashboard[],
}

interface IGetManagementGroupDashboardParams {
  id: TMongoID,
}

interface IGetDashboardByManagementGroupIdParams {
  managementGroupId: TID,
}

interface IGetManagementGroupDashboardResponse {
  dashboard: IManagementGroupDashboard,
}

const getManagementGroupDashboards = (
  params: IGetManagementGroupDashboardsParams,
): Promise<IGetManagementGroupDashboardsResponse> => {
  return apiGet(
    '/dashboards/api/v1/management_group_dashboards.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.dashboards,
    } as IGetManagementGroupDashboardsResponse;
  });
};

const getManagementGroupDashboard = (
  params: IGetManagementGroupDashboardParams,
): Promise<IGetManagementGroupDashboardResponse> => {
  return apiGet(
    `/dashboards/api/v1/management_group_dashboards/${params.id}`,
  ).then((data) => camelizeKeys(data) as IGetManagementGroupDashboardResponse);
};

const getDashboardByManagementGroupId = (
  params: IGetDashboardByManagementGroupIdParams,
): Promise<IGetManagementGroupDashboardResponse> => {
  return apiGet(
    '/dashboards/api/v1/management_group_dashboards/by_management_group_id.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetManagementGroupDashboardResponse);
};

export {
  IGetManagementGroupDashboardsParams,
  IGetManagementGroupDashboardsResponse,
  IGetManagementGroupDashboardParams,
  IGetManagementGroupDashboardResponse,
  IGetDashboardByManagementGroupIdParams,
  getManagementGroupDashboards,
  getManagementGroupDashboard,
  getDashboardByManagementGroupId,
};
