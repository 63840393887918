import React from 'react';

import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';

import AISuggestion from './ai_suggestion';
import { useAIContextLogic } from './use_ai_context_logic';

import styles from './styles.module.scss';

const ViewAIContext: React.FC = () => {
  const { aiContext, expand, openExpand, isLoading, error } = useAIContextLogic();

  if (expand) {
    if (!aiContext) {
      if (isLoading) return <span className={ styles['ai-cursor'] }>|</span>;
      if (error) return <ErrorNotification error={ error } />;
    }

    return (
      <div className={ styles['context-from-ai'] }>
        <div className={ styles['context-title'] }>
          From AI
        </div>
        <AISuggestion content={ aiContext } />
      </div>
    );
  }

  return (
    <div className={ styles['context-from-ai'] }>
      <Button className={ styles['context-header'] } onClick={ openExpand }>
        Ask AI
      </Button>
    </div>
  );
};

export default ViewAIContext;
