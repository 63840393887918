module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="accounts-payable-settings-detail">\n  <div class="display-flex justify-content-between align-items-center">\n    <h2 class="font-24 font-semi-bold"><a class="font-16 in-blue-950" href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>ACH Payments</h2>\n    ');
    
      if (this.dwollaCustomer && this.dwollaCustomer.id) {
        __out.push('\n      <a class="font-16 font-semi-bold pointer pull-right reset-ach-info m-t-10">Reset Docyt ACH Setup</a>\n    ');
      }
    
      __out.push('\n  </div>\n  <hr/>\n  <div class="font-16 withdraw-result-wrapper">\n    <span class="icon icon-check_circle_large"></span>\n    ');
    
      if (this.dwollaCustomer && this.dwollaCustomer.get('dwolla_funding_source')) {
        __out.push('\n      <span class=\'m-l-10\' style="flex: 1">An amount of <b>');
        __out.push(__sanitize(this.currencyFormater(this.withdraw_amount)));
        __out.push('</b> was withdrawn from your Docyt Wallet to your ACH bank account <b>');
        __out.push(__sanitize(this.dwollaCustomer.get('dwolla_funding_source').name));
        __out.push('</b>.<br>Your current wallet balance is <b>');
        __out.push(__sanitize(this.currencyFormater(this.dwollaCustomer.get('dwolla_balance'))));
        __out.push('</b>.</span>\n    ');
      }
    
      __out.push('\n    <a class="cancel pointer pull-right m-r-10 m-t-8 close-icon-btn"><span class="icon icon-not-a-chargeback-icon"></span></a>\n  </div>\n');
    
      if (this.dwollaCustomer) {
        __out.push('\n    ');
        if (this.dwollaCustomer.id && !this.dwollaCustomer.isCertified()) {
          __out.push('\n    <div class="error-reconnect-panel display-flex align-items-center">\n      <span class="vertical-align-middle m-l-15 m-r-20 font-16"> Certification needed. Please certify that the provided information is complete and correct. </span>\n      <a type="button" class="btn pointer font-16 font-semi-bold btn-certify-js p-l-30 p-r-30">Certify</a>\n    </div>\n    ');
        }
        __out.push('\n    <div class="ach-setting-item-wrapper display-table in-black">\n      <div class="display-table-cell display-flex align-items-center">\n        <div class="middle-avatar-wrapper">\n          <i class="icon icon-business1 font-40 display-flex"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span>\n          <span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span></i>\n        </div>\n        <span class="font-18 m-l-30">Business info</span>\n      </div>\n      <div class="display-table-cell vertical-align-middle width-50-percent">\n        <div class="display-flex align-items-center width-100-percent">\n        ');
        if (this.dwollaCustomer.id) {
          __out.push('\n          ');
          if (this.dwollaCustomer.get('is_verified')) {
            __out.push('\n            <div class="in-green-1000 font-14 font-semi-bold">Verified</div>\n            <a href="businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/accounts_payable/ach/setup_business" class="pointer font-16 font-semi-bold btn-business-setup m-l-auto m-r-0">View</a>\n          ');
          } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["PENDING"]) {
            __out.push('\n            <div class="in-grey-1050 business-status-cell">\n              <span class="font-14 font-semi-bold">Pending review</span>\n              <span class="font-12">It typically takes 1 business day to finish this review process</span>\n            </div>\n            <a href="businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/accounts_payable/ach/setup_business" class="pointer font-16 font-semi-bold btn-business-setup m-l-auto m-r-0">View</a>\n          ');
          } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["DOCUMENT"]) {
            __out.push('\n            <div class="in-orange-300 font-14 font-semi-bold"><i class="icon icon-qbo-error m-r-8"></i>Upload additional documents</div>\n            <a href="businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/accounts_payable/ach/setup_business" class="btn btn-blue btn-business-setup width-120px m-l-auto m-r-0">View</a>\n          ');
          } else if (this.dwollaCustomer.get('status') === configData.dwolla_customer_statuses["RETRY"]) {
            __out.push('\n            <div class="in-orange-300 font-14 font-semi-bold">Needs additional information</div>\n            <a href="businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/accounts_payable/ach/setup_business" class="btn btn-blue btn-business-setup width-120px m-l-auto m-r-0">View</a>\n          ');
          } else {
            __out.push('\n            <div class="in-red-600 font-14"><i class="fa fa-warning"></i> Verification failed.</div>\n            <a href="businesses/');
            __out.push(__sanitize(this.business_id));
            __out.push('/accounts_payable/ach/setup_business" class="btn btn-blue btn-business-setup width-120px m-l-auto m-r-0">View</a>\n          ');
          }
          __out.push('\n        ');
        } else {
          __out.push('\n          <a href="businesses/');
          __out.push(__sanitize(this.business_id));
          __out.push('/accounts_payable/ach/setup_business" class="btn btn-blue btn-business-setup width-120px m-l-auto m-r-0">Start</a>\n        ');
        }
        __out.push('\n        </div>\n      </div>\n    </div>\n    <div id="beneficial-owners-item-region"></div>\n    ');
        if (this.dwollaCustomer.id) {
          __out.push('\n      <div class="ach-setting-item-wrapper display-table p-l-15 p-r-15 p-t-15 p-b-15 m-b-15 pointer in-black">\n        <div class="display-table-cell display-flex align-items-center">\n          <div class="middle-avatar-wrapper">\n          <i class="icon icon-bank font-40 display-flex"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span>\n          <span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span></i>\n          </div>\n          <span class="font-18 m-l-30">ACH Verification</span>\n        </div>\n        <div class="display-table-cell vertical-align-middle text-right width-50-percent">\n          ');
          if (this.dwollaCustomer.get('is_verified')) {
            __out.push('\n            ');
            if (this.dwollaCustomer.get('is_verified_funding_source')) {
              __out.push('\n              <div class="display-flex align-items-center width-100-percent">\n                <div class="in-green-1000 font-14 font-semi-bold">Verified</div>\n                <a href="businesses/');
              __out.push(__sanitize(this.business_id));
              __out.push('/accounts_payable/settings/ach/ach_verification" class="pointer font-16 font-semi-bold btn-business-setup m-l-auto m-r-0">View</a>\n              </div>\n            ');
            } else if (this.dwollaCustomer.get('dwolla_funding_source')) {
              __out.push('\n              <div class="display-flex align-items-center width-100-percent">\n                <div class="in-orange-400 font-14 font-semi-bold">Unverified</div>\n                <a href="businesses/');
              __out.push(__sanitize(this.business_id));
              __out.push('/accounts_payable/settings/ach/ach_verification" class="pointer font-16 font-semi-bold btn-business-setup m-l-auto m-r-0">View</a>\n              </div>\n            ');
            } else {
              __out.push('\n              <a href="businesses/');
              __out.push(__sanitize(this.business_id));
              __out.push('/accounts_payable/settings/ach/ach_verification" type="button" class="btn btn-blue btn-verify-bank-account width-120px">Connect</a>\n            ');
            }
            __out.push('\n          ');
          } else {
            __out.push('\n            <a type="button" class="btn btn-blue width-120px" disabled>Connect</a>\n          ');
          }
          __out.push('\n        </div>\n      </div>\n      ');
          if (this.dwollaCustomer.get('is_verified')) {
            __out.push('\n      <div class="ach-setting-item-wrapper display-table p-l-15 p-r-15 p-t-15 p-b-15 m-b-15 pointer in-black">\n        <div class="display-table-cell display-flex align-items-center">\n          <div class="middle-avatar-wrapper">\n          <i class="icon in-pink-300 icon-check-recharge font-40 display-flex"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>\n          </div>\n          <span class="font-18 m-l-30">Docyt Wallet Balance</span>\n        </div>\n        <div class="display-table-cell vertical-align-middle text-right width-50-percent">\n          <span class="font-16 font-semi-bold m-l-auto m-r-0">');
            __out.push(__sanitize(this.currencyFormater(this.dwollaCustomer.get('dwolla_balance'))));
            __out.push('</span>\n        </div>\n        <div class="display-table-cell vertical-align-middle pull-right">\n          <div id="docyt-wallet-balance-region"></div>\n        </div>\n      </div>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n');
      } else {
        __out.push('\n  <div class="expense-reports-container">\n    <img src="');
        __out.push(__sanitize(configData.expense_images.EXPENSE_ACH));
        __out.push('" class="pull-left activation-image m-t-60" />\n    <div class="activation-home-content-region">\n      <h2 class="font-28 font-semi-bold m-b-15">ACH payment, the easy way</h2>\n      <p class="font-16 m-b-30">Activate ACH payments to pay vendors and employees in an easy and convenient way.</p>\n      <a class="btn btn-blue btn-activate">Get Started</a>\n    </div>\n    <table>\n      <tbody>\n      </tbody>\n    </table>\n  </div>\n');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}