import React from 'react';

import flatten from 'lodash/flatten';

import { useGetRelatedTransactions } from '@src/hooks/queries/split_transactions';
import { TID } from '@src/types/common';

import CollapseSection from '@src/components/ui/form/collapse_section';
import QueryStatus from '@src/components/utils/query_status';

import List from './list';

interface IRelatedTransactionDetailsProps {
  transactionId: TID
}

const RelatedTransactionDetails = ({
  transactionId,
}: IRelatedTransactionDetailsProps): JSX.Element => {
  const relatedTransactionsQuery = useGetRelatedTransactions({ transactionId });

  return (
    <>
      <QueryStatus query={ relatedTransactionsQuery } />
      <CollapseSection title="RELATED TRANSACTIONS">
        <List
          relatedTransactions={ flatten(relatedTransactionsQuery?.data || []) }
          transactionId={ transactionId }
        />
      </CollapseSection>
    </>
  );
};

export {
  IRelatedTransactionDetailsProps,
  RelatedTransactionDetails as default,
};
