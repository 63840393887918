import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getTemplates, IGetTemplateResponse, IGetTemplatesParams } from '@src/requests/dashboards/templates';

const useGetTemplates = (params: IGetTemplatesParams) => {
  return useQuery<IGetTemplateResponse, Error>(
    [QueryKey.dashboardTemplates, params],
    () => getTemplates(params),
  );
};

export {
  useGetTemplates,
};
