module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('  <td class="width-20-percent">\n    <div>\n      <div class="relative">\n        <div class="business-select business-typeahead-wrapper display-flex align-items-center" id="select-business-dropdown">\n          <input class="form-control font-14 business-input-js business-select-field" ');
    
      if (this.is_readonly || this.is_business_readonly || this.is_tx_type_not_expense) {
        __out.push(__sanitize('disabled'));
      }
    
      __out.push(' placeholder="Select Business" autocomplete="off" data-business_id="');
    
      __out.push(__sanitize(this.business.business_id));
    
      __out.push('" value="');
    
      __out.push(__sanitize(this.business.business_name));
    
      __out.push('"/>\n        </div>\n      </div>\n    </div>\n  </td>\n\n  <td class="width-20-percent">\n    <div class="form-inline display-flex vendor-typeahead-wrapper relative width-100-percent">\n      <input class="form-control input-box font-14 class-input-js width-100-percent" placeholder="Select Department" autocomplete="off"/>\n      <span class="class-label-js hidden no-data-label">Not available</span>\n    </div>\n  </td>\n\n  <td class="width-25-percent">\n    <div class="form-inline vendor-typeahead-wrapper relative width-100-percent">\n      <input class="form-control input-box font-14 chart-of-account-input-js width-100-percent" placeholder="Select Chart of Account" autocomplete="off"/>\n    </div>\n  </td>\n\n  <td class="width-10-percent">\n    <div class="form-inline amount-input-wrapper width-100-percent ');
    
      __out.push(__sanitize(this.split_by_percentage !== 0 ? 'percent-val' : 'currency-val'));
    
      __out.push('">\n      <input class="form-control input-box font-14 amount-input-js width-100-percent text-right" placeholder="');
    
      __out.push(__sanitize(this.split_by_percentage !== 0 ? '' : 'Amount'));
    
      __out.push('" value="');
    
      __out.push(__sanitize(this.split_by_percentage === 0 ? this.amount : this.percentage));
    
      __out.push('" autocomplete="off"/>\n    </div>\n  </td>\n\n  <td class="width-25-percent">\n    <input class="form-control font-14 memo-input-js" placeholder="Add description" autocomplete="off"/>\n  </td>\n\n  <td class="');
    
      __out.push(__sanitize((!this.is_readonly || this.can_split_paid_invoice) ? 'width-120px' : ''));
    
      __out.push('">\n    ');
    
      if (!this.is_readonly || this.can_split_paid_invoice) {
        __out.push('\n    <span class="font-14 btn-remove-split delete-split-js pointer" >\n      <i class="icon icon-trashcan"></i>\n    </span>\n    ');
      }
    
      __out.push('\n  </td>\n\n\n\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}