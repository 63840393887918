import React from 'react';

import styles from './styles.module.scss';

interface ISideViewContentProps {
  children: React.ReactNode,
}

const SideViewContent = ({
  children,
}: ISideViewContentProps) => {
  return (
    <div className={ styles.content }>
      { children }
    </div>
  );
};

export default SideViewContent;
