import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';

import { useUniqueDomId } from '@src/hooks/dom';
import { IChartOfAccount } from '@src/types/report_service/report';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import { useItemsSelector } from '@src/components/utils/items_selector';

interface IItemsSelectorSelectItemCheckboxProps extends
  Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'onChange' | 'checked' | 'id' | 'value' | 'type'
  >,
  IUIStyleProps
{
  items: IChartOfAccount[],
  partialCheckEnabled?: boolean,
  label?: React.ReactNode,
  uniqKey?: string
}

const ItemsSelectorSelectItemCheckbox = ({
  items,
  label,
  partialCheckEnabled,
  uniqKey,
  ...props
}: IItemsSelectorSelectItemCheckboxProps) => {
  const classes = classNames('checkbox checkbox-primary');
  const [inputClasses, inputProps] = uiStyleProps('pointer', props);

  const inputId = useUniqueDomId('item_selector_');

  const { mark, isSelected } = useItemsSelector();

  const handleChange = useCallback((e) => {
    items.forEach((item) => {
      mark(item, e.target.checked);
    });
  }, [items, mark]);

  const info = useMemo(() => {
    const uniqItems = uniqKey ? uniqBy(items, uniqKey) : items;
    let selectedItems = items.filter((item) => isSelected(item) && item.name);
    selectedItems = uniqKey ? uniqBy(selectedItems, uniqKey) : selectedItems;
    
    const totalBigType = uniqKey === 'accountDetailType' ? items.length : 0;
    const totalSelectedBigType = uniqKey === 'accountDetailType' ? items.filter((item) => isSelected(item) && item.name).length : 0;
    
    const isPartialEnabled = partialCheckEnabled && selectedItems.length !== 0;
    const partialCheckedClass = isPartialEnabled && (selectedItems.length !== uniqItems.length || totalBigType > totalSelectedBigType)
    
    return {
      isChecked: isPartialEnabled || (!partialCheckEnabled && selectedItems.length === uniqItems.length),
      label: isPartialEnabled ? `${label} (${selectedItems.length}/${uniqItems.length})` : label,
      classes: partialCheckedClass ? `${classes} partial-checked` : classes,
    };
  }, [uniqKey, items, partialCheckEnabled, label, classes, isSelected]);

  return (
    <div className={ info.classes }>
      <input
        checked={ info.isChecked }
        className={ inputClasses }
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
        { ...inputProps }
      />
      <label
        aria-label="Select"
        htmlFor={ inputId }
      >
        { info.label }
      </label>
    </div>
  );
};

export default ItemsSelectorSelectItemCheckbox;
