import { QueryKey } from '@src/constants/query_keys';
import {
  getCombinedServiceDocuments,
  IGetCombinedServiceDocumentsParams,
  IGetCombinedServiceDocumentsResponse,
} from '@src/requests/combined_service_documents';
import { ICombinedServiceDocument } from '@src/types/combined_service_documents';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetCombinedServiceDocuments = createUseGetInfiniteCollection<
  ICombinedServiceDocument,
  IGetCombinedServiceDocumentsParams,
  IGetCombinedServiceDocumentsResponse
>({
  queryKey: QueryKey.combinedServiceDocumentsQuery,
  request:  getCombinedServiceDocuments,
});

export {
  useGetCombinedServiceDocuments,
};
