module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex align-items-center m-r-10">\n  <div class="avatar-wrapper">\n    ');
    
      if (this.avatar && this.avatar.avatar_url) {
        __out.push('\n    <img src="');
        __out.push(__sanitize(this.avatar.avatar_url));
        __out.push('"/>\n    ');
      } else {
        __out.push('\n    <i class="icon icon-smile in-blue-400 font-33"></i>\n    ');
      }
    
      __out.push('\n  </div>\n  <span class="font-14 m-l-14">');
    
      __out.push(__sanitize(this.parsed_fullname));
    
      __out.push('</span>\n</div>\n<div class="checkbox checkbox-primary ');
    
      __out.push(__sanitize(this.is_all_checked ? 'checkbox-disabled' : ''));
    
      __out.push('">\n  <input type="checkbox" class="pointer check-approver-js" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push('>\n  <label></label>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}