import React from 'react';

import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  BankStatementMissing,
} from '@src/components/utils/icomoon';

import MarkAsNoAvailableAction from './actions/mark_as_no_available_action';
import RequestStatementAction from './actions/request_statement_action';
import UploadStatementAction from './actions/upload_statement_action';

import styles from '../../../styles.module.scss';

interface IMissingStatusItemProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  state: IReviewedBankStatementState,
}

const MissingStatusItem = ({
  businessId,
  rcPaymentAccountId,
  state,
}: IMissingStatusItemProps): JSX.Element => {
  const missingTooltip = (
    <div className="not-available-status">
      <div className={ styles['state-status'] }>
        <BankStatementMissing fontSize={ 20 } />
        <span className={ styles['statement-state'] }>Statement Missing</span>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ missingTooltip } tooltipClassName={ styles['missing-state-item'] }>
      <BankStatementMissing fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['status-item-button'] }>
      { content }
    </Dropdown.ToggleButton>
  );
  return (
    <ActionsDropdown toggleButton={ toggleButton }>
      <UploadStatementAction
        businessId={ businessId }
        month={ state.month }
        rcPaymentAccountId={ rcPaymentAccountId }
      />
      <RequestStatementAction
        businessId={ businessId }
        rcPaymentAccountId={ rcPaymentAccountId }
        state={ state }
      />
      <MarkAsNoAvailableAction
        businessId={ businessId }
        rcPaymentAccountId={ rcPaymentAccountId }
        state={ state }
      />
    </ActionsDropdown>
  );
};

export default MissingStatusItem;
