import { createContext, useContext } from 'react';

import { IReportService } from '@src/types/services';

const ReportServiceContext = createContext<IReportService | null>(null);

const useReportServiceContext = () => <IReportService>useContext(ReportServiceContext);

export {
  ReportServiceContext,
  useReportServiceContext,
};
