import { createContext, useContext } from 'react';

import { IMetricsService } from '@src/types/services';

const MetricsServiceContext = createContext<IMetricsService | null>(null);

const useMetricsServiceContext = () => <IMetricsService>useContext(MetricsServiceContext);

export {
  MetricsServiceContext,
  useMetricsServiceContext,
};
