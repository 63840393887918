import React from 'react';

import { WidgetBriefNoDataIcon } from '@src/components/utils/img_icons';

import styles from './styles.module.scss';

const BriefNoDataView = () => {
  return (
    <div className={ styles['brief-no-data'] }>
      <div className={ styles['no-data-wrapper'] }>
        <div className={ styles['no-data-image'] }>
          <WidgetBriefNoDataIcon />
        </div>
        <p className={ styles['no-data-title'] }>
          No data found
        </p>
      </div>
    </div>
  );
};

export default BriefNoDataView;
