import React from 'react';

import NumberFormat from 'react-number-format';

import { TRichAmount } from './types';
import { amountFormatterProps } from './utils';

import styles from './styles.module.scss';

interface IRichAmountRangeFormatterProps {
  value: TRichAmount | undefined,
}

const RichAmountRangeFormatter = ({
  value,
}: IRichAmountRangeFormatterProps) => {
  return (
    <span className={ styles['rich-amount-range-formatter'] }>
      <span className={ styles['rich-amount-range-formatter-min'] }>
        { value?.gte && (
          <>
            Min:
            { ' ' }
            <NumberFormat
              { ...amountFormatterProps }
              value={ value?.gte || '' }
            />
          </>
        ) }
      </span>
      { value?.lte && value?.gte && ' – ' }
      <span className={ styles['rich-amount-range-formatter-max'] }>
        { value?.lte && (
          <>
            Max:
            { ' ' }
            <NumberFormat
              { ...amountFormatterProps }
              value={ value?.lte || '' }
            />
          </>
        ) }
      </span>
    </span>
  );
};

export default React.memo(RichAmountRangeFormatter);
