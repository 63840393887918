module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog person-edit-form-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-28 font-bold">Delete ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body font-16">\n        <p class="m-t-22">Are you sure you want to delete ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('?</p>\n        <p><b>All the chart of accounts linked to ');
    
      __out.push(__sanitize(this.name));
    
      __out.push(' will be unlinked.</b> To maintain the sactity of the report, you’ll need to take one of the following actions:</p>\n        <ul class="tips-desc m-l-16">\n          <li>Point the accounts being unlinked to another parent category of SAME type; OR</li>\n          <li>Merge the accounts being unlinked with another account.</li>\n        </ul>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n        <button type="submit" class="btn btn-red btn-delete" data-color="blue" data-style="expand-right"><span class="ladda-label">Delete</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}