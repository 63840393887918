module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="m-t-50 m-l-80 m-r-80">\n  <h2 class="font-20 font-semi-bold">Starting Docyt Check Number</h2>\n  <p class="border-bottom-gray p-b-20">\n    Please enter the starting Docyt check number. It cannot be changed later.\n  </p>\n  <div class="text-left profile-content-wrapper font-14">\n    <div class="profile-input-wrap m-t-30 m-b-10">\n      <label class="width-50-percent text-left">Starting Docyt Check No.</label>\n      <input class="input-start-check-number form-control" type="number" placeholder="Enter Starting Docyt Check No." value="');
    
      __out.push(__sanitize(this.last_check_number + 1));
    
      __out.push('">\n    </div>\n    <div class="profile-input-wrap">\n      <label class="width-50-percent"></label>\n      <p class=\'main-input__hint width-100-percent text-left in-red-400 m-l-5 invalid-start-check-number\'><i class="fa fa-warning m-r-5"></i>Please input the start check nubmer.</p>\n    </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}