module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="flex-wrapper relative pointer">\n  <div class="header__userpic inline-block m-l-10">\n    <img src="');
    
      __out.push(__sanitize(this.image_url));
    
      __out.push('" class="header__userpic-img">\n  </div>\n  <p class="payee-name-truncate font-15 inline-block m-l-15 m-t-10 vertical-align-top" data-toggle="tooltip" data-placement="bottom">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</p>\n</td>\n<td class="pointer">\n  <div class="font-15 in-red-400 p-t-10 p-l-10">\n  ');
    
      if (this.count_invoices > 0) {
        __out.push('\n    ');
        __out.push(__sanitize(this.count_invoices));
        __out.push('\n  ');
      } else {
        __out.push('\n    -\n  ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="pointer">\n  ');
    
      if (this.is_accessible_all_payment_accounts) {
        __out.push('\n    <div class="font-18 p-t-10 p-l-10">\n    ');
        if (this.YTD_invoices_amount > 0) {
          __out.push('\n    ');
          __out.push(__sanitize(this.ytd_amount_string));
          __out.push('\n    ');
        } else {
          __out.push('\n    -\n    ');
        }
        __out.push('\n    </div>\n  ');
      } else {
        __out.push('\n    <div class="merchant-no-access">No Access</div>\n  ');
      }
    
      __out.push('\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}