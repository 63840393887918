import React, { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useUpdateReport } from '@src/hooks/queries/report_service/custom_reports';
import { IBusinessUser } from '@src/types/business_users';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import { useEditUsersModal } from '@src/components/common/edit_users_modal/edit_users_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { reportUpdatingState } from '../../atoms';

import styles from '../styles.module.scss';

interface IEditAccessActionProps {
  report: IReport,
  category: TReportCategory,
  users: IBusinessUser[],
}

const EditAccessAction = ({
  report,
  category,
  users,
}: IEditAccessActionProps): JSX.Element => {
  const business = useBusinessContext();
  const reportService = useReportServiceContext();
  const updateReport = useUpdateReport();
  const setReportUpdatingState = useSetRecoilState(reportUpdatingState);

  const handleEditUsers = useCallback((editedUsers: IBusinessUser[]) => {
    updateReport.mutate({
      reportId: report.id,
      report:   { userIds: editedUsers.map((user) => user.id) },
    }, {
      onSuccess: (data) => {
        setReportUpdatingState(`${data.name}: Successfully granted access to users.`);
      },
    });
  }, [report.id, setReportUpdatingState, updateReport]);
  const editUsersModal = useEditUsersModal({ onDone: handleEditUsers });

  return (
    <>
      { reportService.userRole.role === window.Docyt.Common.Constants.REPORT_SERVICE_ROLE.BOOK_KEEPER && category !== 'basic' && (
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <ThumbsUpIcon fontSize={ 18 } /> }
          title="Edit Access"
          onSelect={ editUsersModal.open }
        />
      )}
      <MutationStatus mutation={ updateReport } />
      <editUsersModal.Component
        { ...editUsersModal.props }
        businessId={ business.id }
        editUsers={ users }
        reportName={ report.name }
        serviceProviderBusinessId={ business.serviceProviderBusiness?.id || null }
        title="Edit Access to Report"
      />
    </>
  );
};

export default EditAccessAction;
