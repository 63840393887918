import React, { useCallback, useEffect, useMemo, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetReports } from '@src/hooks/queries/report_service/custom_reports';
import { useGetReportConfigurations, useGetReportExportConfiguration, useUpdateReportExportConfiguration } from '@src/hooks/queries/report_service/report_configurations';
import { businessReportExportPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IReportExportConfigParams } from '@src/types/report_service/report_configurations';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ExportAsExcelIcon, SettingsCogIcon } from '@src/components/utils/icomoon';

import { useDownloadConsolidatedReportModal } from '../modals/download_consolidated_report';
import { useDownloadReportConfigurationModal } from '../modals/download_report_configuration';

import styles from '../styles.module.scss';

interface IReportsListHeaderActionsProps {
  businessId: TID,
  category: string,
  onCreateExportData: (value: boolean) => void,
  reports: IReport[]
}

const ReportsListHeaderActions = ({
  businessId,
  category,
  onCreateExportData,
}: IReportsListHeaderActionsProps): JSX.Element => {
  const [isEditSetting, setIsEditSetting] = useState(false);
  const [updateData, setUpdateData] = useState<IReportExportConfigParams>();
  const business = useBusinessContext();
  const reportService = useReportServiceContext();

  const { mutate } = useUpdateReportExportConfiguration();

  const reportConfig = useGetReportExportConfiguration(businessId);
  const reportConfigData = useMemo(() => reportConfig.data, [reportConfig.data]);

  const handleUpdate = () => {
    if (updateData) {
      mutate(
        updateData,
        {
          onSuccess: () => {
            reportConfig.refetch();
            toastr.success(
              'Settings updated successfully', // message
              'Report Export Setting', // title
            );
          },
        },
      );
    }
  };

  const confirmModal = useConfirmDeleteModal({
    onDone: handleUpdate,
  });
  const modal = useDownloadConsolidatedReportModal();
  const configModal = useDownloadReportConfigurationModal();

  const hideExportSetting = localStorage.getItem(`hideExportSetting-${business.id}`) === 'true';
  const [isHideModalEnabled, setIsHideModalEnabled] = useState(hideExportSetting);
  const [showCustomizeOption, setShowCustomizeOption] = useState(false);

  useEffect(() => {
    if (reportConfigData) {
      setShowCustomizeOption(true);
    }
  }, [reportConfigData]);

  const reportQuery = useGetReports(reportService.id);
  const reports = useMemo(() => reportQuery.data || [], [reportQuery.data]);

  const reportConfigurationsQuery = useGetReportConfigurations();
  const configurations = useMemo(() => reportConfigurationsQuery.data || [], [reportConfigurationsQuery.data]);

  const handleSubmit = (state: boolean) => {
    reportConfig.refetch();
    setIsHideModalEnabled(state);
    setShowCustomizeOption(true);
  };

  const handleSelect = useCallback(() => {
    Backbone.history.navigate(
      businessReportExportPath(businessId, category, 'consolidated_report'),
      { trigger: true },
    );
  }, [businessId, category]);

  const onUpdateConfig = (data: IReportExportConfigParams) => {
    setUpdateData(data);
    confirmModal.open();
  };

  return (
    <>
      <ActionsDropdown className={ `${styles['actions-dropdown']} m-r-15 m-t-5` }>
        <ActionsDropdown.Action
          className={ styles['dropdown-item'] }
          icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
          title="Download Excel"
          onSelect={ () => {
            const isReportsExportEnabled = window.configData.launch_darkly_enabled_global_features
              .includes(window.Docyt.Common.Constants.BUSINESS_REPORTS_DOWNLOAD_ENABLED);

            if (isReportsExportEnabled) {
              handleSelect();
            } else if (!isHideModalEnabled) {
              setIsEditSetting(false);
              configModal.open();
            } else {
              modal.open();
            }
          } }
        />
        {showCustomizeOption && (
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <SettingsCogIcon fontSize={ 18 } /> }
            style={ { minWidth: 200 } }
            title="Customize"
            onSelect={ () => {
              setIsEditSetting(true);
              configModal.open();
            } }
          />
        )}
      </ActionsDropdown>
      <confirmModal.Component
        { ...confirmModal.props }
        confirmStyle="primary"
        confirmTitle="Update"
        text={ (
          <div>
            <p>Updating the configuration will override the existing settings for reports download.</p>
          </div>
        ) }
        title="Are you sure you want to update?"
      />
      <modal.Component
        { ...modal.props }
        reportConfigData={ reportConfigData }
        reportServiceId={ reportService.id }
        standardCategory={ business?.standardCategory?.name || '' }
        onCreateExportData={ (state) => onCreateExportData(state) }
      />
      {/* eslint-disable react/jsx-sort-props */}
      <configModal.Component
        { ...configModal.props }
        category={ category }
        configurations={ configurations }
        isEdit={ isEditSetting }
        reportConfigData={ reportConfigData }
        reportServiceId={ reportService.id }
        reports={ reports }
        showConfigModal={ !isHideModalEnabled }
        handleSubmit={ (state) => handleSubmit(state) }
        onCreateExportData={ (state) => onCreateExportData(state) }
        onUpdate={ (data) => onUpdateConfig(data) }
      />
    </>
  );
};

export default ReportsListHeaderActions;
