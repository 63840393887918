module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, item, len, ref;
    
      __out.push('<div class="flex-wrapper">\n    <div class="standard-document-sample-lists text-center">\n        ');
    
      ref = this.standard_document_profile_sample_pages;
      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];
        __out.push('\n          <span class="sample-list-item font-16 pointer m-t-5 ');
        __out.push(__sanitize(this.curPageNumber === item.page_num ? "active" : ""));
        __out.push('" data-id="');
        __out.push(__sanitize(item.id));
        __out.push('">\n            ');
        __out.push(__sanitize("Sample Image" + item.standard_document_profile_sample_id + "(" + item.page_num + ")"));
        __out.push('\n            <a class="pull-right remove-sample-image" data-id="');
        __out.push(__sanitize(item.id));
        __out.push('"><i class="fa fa-trash"></i></a>\n          </span>\n        ');
      }
    
      __out.push('\n    </div>\n    <div class="standard-document-sample-image">\n        ');
    
      if (this.image_url) {
        __out.push('\n          <div>\n            <canvas class = "standard-document-profile-canvas"></canvas>\n          </div>\n        ');
      } else {
        __out.push('\n        <div aria-labelledby=\'collapse-edition-sample-image-btn\' class=\'standard-document-profile-sample-image-uploader\' id=\'collapse-edition-sample-image\'>\n            <div class=\'client__settings-edition-content\'>\n              <div class=\'collapsed-field-desc text-center\'>\n                <span>Drag\'n\'drop your avatar here.</span>\n                <br>Or\n                <label class="in-blue-400 pointer">Click here.<input type="file" class="hidden image-file-input"></label>\n                <i class="fa fa-spin fa-spinner loading-spinner hidden"></i>\n              </div>\n              <div class=\'client__setting-form-error\' id=\'image-invalid-error\'>\n                <span>Please drop an image or pdf file.</span>\n              </div>\n            </div>\n        </div>\n        ');
      }
    
      __out.push('\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}