import React, { useCallback } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface IDropdownItemProps extends React.ComponentPropsWithoutRef<'a'>, IUIStyleProps {
  divider?: false,
  onSelect?: () => void;
}

interface IDropdownItemDividerProps {
  children?: undefined,
  className?: string,
  divider: true,
  onSelect?: undefined,
}

const DropdownItem = ({
  children,
  divider,
  onSelect,
  ...props
}: IDropdownItemProps | IDropdownItemDividerProps): JSX.Element => {
  const handleClick = useCallback((e) => {
    e.preventDefault();

    if (onSelect) onSelect();
  }, [onSelect]);

  if (divider) {
    const [classes] = uiStyleProps('item-divider', props);

    return <li className={ classes } role="separator" />;
  }

  const [classes, anchorProps] = uiStyleProps(undefined, props);

  return (
    <li>
      <a
        className={ classes }
        role="button"
        tabIndex={ -1 }
        type="button"
        onClick={ handleClick }
        { ...anchorProps }
      >
        <div className="display-flex align-items-center">
          { children }
        </div>
      </a>
    </li>
  );
};

export default DropdownItem;
