import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';

import BusinessField from '@src/components/common_v2/form_fields/business_field';
import DataExportTypeField from '@src/components/common_v2/form_fields/data_export_type_field';
import MultiBusinessesField from '@src/components/common_v2/form_fields/multi_businesses_field';
import MultiChartOfAccountsField from '@src/components/common_v2/form_fields/multi_chart_of_accounts_field';
import Form from '@src/components/ui_v2/form';

import { IAddDataExportModalFormValues, addDataExportValidation } from './schema';

import styles from './styles.module.scss';

interface IAddDataExportModalFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IAddDataExportModalFormValues>,
}

const showIncludeOptions = [
  { value: window.Docyt.Common.Constants.DATA_EXPORT_INCLUDE_OPTIONS.REVENUE_ONLY, label: 'Include daily revenue report only' },
  { value: window.Docyt.Common.Constants.DATA_EXPORT_INCLUDE_OPTIONS.REVENUE_AND_INCOME, label: 'Include daily revenue report and bank depoists' },
];

const AddDataExportModalForm = ({
  formId,
  onSubmit,
}: IAddDataExportModalFormProps): JSX.Element => {
  const [showCOASelection, setShowCOASelection] = useState<boolean>(false);
  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
    handleSubmit,
  } = useForm<IAddDataExportModalFormValues>({
    defaultValues: {
      includeOption: window.Docyt.Common.Constants.DATA_EXPORT_INCLUDE_OPTIONS.REVENUE_ONLY,
    },
    resolver: yupResolver(addDataExportValidation),
  });

  const dateController = useController({ name: 'date', control });
  const exportTypeController = useController({ name: 'exportType', control });
  const businessIdController = useController({ name: 'businessId', control });
  const multiBusinessesController = useController({ name: 'multiBusinesses', control });
  const multiChartOfAccountsController = useController({ name: 'multiChartOfAccounts', control });

  useEffect(() => {
    if (exportTypeController.field.value
      === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF) {
      setValue('includeOption', window.Docyt.Common.Constants.DATA_EXPORT_INCLUDE_OPTIONS.REVENUE_ONLY);
    }
  }, [exportTypeController.field.value, setValue]);

  const handleSubmitError = useCallback((err) => {
    let errorMessage;
    if (err.multiBusinesses) {
      errorMessage = err.multiBusinesses.message;
    } else if (err.multiChartOfAccounts) {
      errorMessage = err.multiChartOfAccounts.message;
    }

    if (!errorMessage) return;

    toastr.error(errorMessage, 'Something wrong');
  }, []);

  const businessId = watch('businessId');

  const handleChange = useCallback((value) => {
    setValue('multiChartOfAccounts', []);
    setValue('businessId', value);
    if (showCOASelection) {
      setShowCOASelection(false);
      setTimeout(() => {
        setShowCOASelection(true);
      }, 300);
    } else {
      setShowCOASelection(true);
    }
  }, [setValue, showCOASelection]);

  const businessDataExportFilter = { forServiceRole: { serviceType: 'DataExportService', serviceRole: 'ADMIN' } };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit, handleSubmitError) }>
      <span className="display-flex m-b-20">Select the type of data export and we will notify you once we have the data ready to download.</span>
      <DataExportTypeField
        error={ errors.exportType?.message }
        label="Export Type"
        placeholder="Select Export Type"
        { ...omit(exportTypeController.field, 'ref') }
      />
      { exportTypeController.field.value
        === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF && (
          <Form.RadioField
            label="Include Option"
            labelClass={ styles['include-option-label'] }
            options={ showIncludeOptions }
            { ...register('includeOption') }
          />
      )}
      { (exportTypeController.field.value
        === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT
        || exportTypeController.field.value
        === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF) ? (
          <MultiBusinessesField
            label="Business"
            { ...omit(multiBusinessesController.field, 'ref') }
            forDataExport
            filter={ businessDataExportFilter }
          />
        ) : (
          <BusinessField
            error={ errors.businessId?.message }
            label="Business"
            { ...omit(businessIdController.field, 'ref') }
            forDataExport
            filter={ businessDataExportFilter }
            onChange={ handleChange }
          />
        ) }
      { businessId && showCOASelection && exportTypeController.field.value
      === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.PL_CHART_OF_ACCOUNTS_XLS && (
        <MultiChartOfAccountsField
          businessId={ businessId }
          label="Chart of Accounts"
          { ...omit(multiChartOfAccountsController.field, 'ref') }
        />
      )}
      { exportTypeController.field.value
        === window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT ? (
          <div className={ styles['complete-vendors-list-description'] }>
            You can only select businesses in which you have admin role in Vendors service.
            <br />
            You can review and make changes in the Businesses section of your profile.
            <br />
            If you don&apos;t have access to it, please contact your business admin.
          </div>
        ) : (
          <Form.DateRangeField
            error={ errors.date?.startDate?.message }
            label="Date Range"
            placeholder="Start Date - End Date"
            { ...dateController.field }
          />
        )}
    </Form>
  );
};

export {
  IAddDataExportModalFormProps,
  AddDataExportModalForm as default,
};
