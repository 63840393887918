import React from 'react';

import styles from '../styles.module.scss';

interface ISearchResultProps {
  searchCount?: number,
  searchQuery?: string,
}

const SearchResult = ({
  searchCount,
  searchQuery,
}: ISearchResultProps): JSX.Element => {
  return (
    <h2 className={ styles['searched-result'] }>
      { searchCount }
      {' '}
      Search
      {' '}
      { searchCount === 1 ? 'Result' : 'Results' }
      {' '}
      for
      {' '}
      &quot;
      { searchQuery }
      &quot;
    </h2>
  );
};

export default SearchResult;
