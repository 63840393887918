import React from 'react';

interface IBulkActionProgressProps {
  doneCount: number,
  itemsTitle: string,
  totalCount: number,
}

const BulkActionProgress = ({
  doneCount,
  itemsTitle,
  totalCount,
}: IBulkActionProgressProps) => {
  const percent = Math.floor((doneCount * 100) / totalCount);

  return (
    <>
      <p className="p-b-0 text-center m-b-20">
        <b>
          { doneCount }
          /
          { totalCount }
        </b>
        { ' ' }
        { itemsTitle }
      </p>
      <div className="progress">
        <div className="progress-bar" style={ { width: `${percent}%` } } />
      </div>
      <p className="p-b-0 text-center">
        Do not close this window or click the Back button on your browser.
        It will stop the progress.
      </p>
    </>
  );
};

export default BulkActionProgress;
