import React from 'react';

import { TID, TAmount } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import { useWithdrawAmountModal } from '../withdraw_amount_modal';

interface IWithdrawAmountActionProps {
  businessId: TID,
  dwollaBalance: TAmount,
  bankAccountName: string,
}

const WithdrawAmountAction = ({
  businessId,
  dwollaBalance,
  bankAccountName,
}: IWithdrawAmountActionProps) => {
  const withdraw = useWithdrawAmountModal();

  return (
    <>
      <withdraw.Component
        { ...withdraw.props }
        bankAccountName={ bankAccountName }
        businessId={ businessId }
        dwollaBalance={ dwollaBalance }
      />
      <ActionsDropdown.Action
        name="Withdraw to Bank Account"
        onClick={ withdraw.open }
      />
    </>
  );
};

export default WithdrawAmountAction;
