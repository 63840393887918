import { useState } from 'react';

import uniqueId from 'lodash/uniqueId';

const useUniqueDomId = (prefix: string): string => {
  const [inputId] = useState(uniqueId(prefix));

  return inputId;
};

export {
  useUniqueDomId,
};
