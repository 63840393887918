/* eslint-disable eqeqeq */
import React, { useMemo } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { arServicePath, arServiceCustomersPath, arServicePaymentsPath, arServiceSettingsPath, revenueServicePathUnassignedPath, revenueServicePathAddSystemPath, revenueServiceSettingsPath, arServiceVendorProfilePath, revenueServicePathRevenueCapturePath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { sortRevenueSystems } from '@src/components/revenue_center/utils'
import AddCircleIcon from '@src/components/utils/icomoon/add_circle';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';


import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const RevenueItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled
}: IServiceMenuProps) => {
  const service = useRevenueServiceContext();

  const canVisibleArService = info.isBusinessAdmin || info.canAccessAccountsReceivableService;
  const sortedRevenueSystems = useMemo(() => {
    return sortRevenueSystems(service.revenueSystems);
  }, [service.revenueSystems]);

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REVENUE }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REVENUE }
    >
      <li className='p-t-8'>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REVENUE.toUpperCase() }
        </span>
      </li>
      {canVisibleArService && (
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.ACCOUNT_RECEIVABLES }
        >
          <ServiceItem
            serviceUrl={ arServicePath(businessId) }
            title="Invoices"
          />
          <ServiceItem
            serviceUrl={ arServiceCustomersPath(businessId) }
            title="Customers"
          />
          <ServiceItem
            hasBorderBottom
            serviceUrl={ arServicePaymentsPath(businessId) }
            title="Payments"
          />
          <ServiceItem
            serviceUrl={ arServiceSettingsPath(businessId) }
            title="Settings"
          />
        </ServiceSubMenu>
      )}
      <ServiceSubMenu
        hasBorderBottom
        hasWiderSubmenuList
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.POINT_OF_SALE_RECEIVABLES }
      >
        {
          sortedRevenueSystems.map((system, index) => (
            <ServiceItem
              key={ system.id }
              hasBorderBottom={ index === sortedRevenueSystems.length - 1 }
              serviceUrl={ revenueServicePathRevenueCapturePath(businessId, system.id) }
              title={ system.name }
            />
          ))
        }
        <ServiceItem
          hasBorderBottom
          serviceUrl={ revenueServicePathUnassignedPath(businessId) }
          title="For Review"
        />
        <ServiceItem
          hasBorderBottom
          serviceUrl={ revenueServicePathAddSystemPath(businessId) }
          title={ (
            <div className={ `${styles['icon-with-text']}` }>
              <span>
                <AddCircleIcon fontSize={ 18 } />
                <span className={ `${styles['sub-menu-text']}` }>Add New Revenue System</span>
              </span>
            </div>
          ) }
        />
        <ServiceItem
          serviceUrl={ revenueServiceSettingsPath(businessId) }
          title="Settings"
        />
      </ServiceSubMenu>
      {canVisibleArService && (
        <ServiceItem
          serviceUrl={ arServiceVendorProfilePath(businessId) }
          title="Business Network Profile"
        />
      )}
    </ModuleHeaderMenu>
  );
};

export default RevenueItem;
