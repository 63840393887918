module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="archive-date-modal">\n    <div class="modal fade" tabindex="-1" role="dialog">\n        <div class="modal-dialog modal-md">\n            <div class="modal-content width-550px">\n                <div class="modal-header">\n                    <h4 class="font-28 font-bold m-t-0 m-b-0 text-center">\n                        <span class="icon-qbo-error font-28 vertical-align-middle"></span>\n                        <span class="vertical-align-middle">Cannot ');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</span>\n                    </h4>\n                </div>\n                <div class="modal-body modern-body">\n                    <div class="panel-fields">\n                        <p class="m-t-0 font-14">This ');
    
      __out.push(__sanitize(this.document_type));
    
      __out.push(' cannot be ');
    
      __out.push(__sanitize(this.action_type));
    
      __out.push(' as the ');
    
      __out.push(__sanitize(this.action_date_title));
    
      __out.push(' is dated before the Books Lock Date');
    
      __out.push(__sanitize(this.payment_account_start_date_str === '' ? '' : ' or the Payment Account start date'));
    
      __out.push('.</p>\n                        <div class="form-group">\n                            <label class="field-label">Books Lock Date: </label>\n                            <div class="field-input">\n                                <input class="form-control width-200px pull-right" placeholder="MM/DD/YYYY" value="');
    
      __out.push(__sanitize(this.reconciliation_closing_date_str));
    
      __out.push('" />\n                            </div>\n                        </div>\n                        ');
    
      if (this.payment_account_start_date_str !== "") {
        __out.push('\n                            <div class="form-group">\n                                <label class="field-label">Payment Account start date: </label>\n                                <div class="field-input">\n                                    <input class="form-control width-200px pull-right" placeholder="MM/DD/YYYY" value="');
        __out.push(__sanitize(this.payment_account_start_date_str));
        __out.push('" />\n                                </div>\n                            </div>\n                        ');
      }
    
      __out.push('\n                        <div class="form-group">\n                            <label class="field-label">');
    
      __out.push(__sanitize(this.action_date_title.charAt(0).toUpperCase() + this.action_date_title.slice(1)));
    
      __out.push(': </label>\n                            <div class="field-input">\n                                <input class="form-control width-200px pull-right" placeholder="MM/DD/YYYY" value="');
    
      __out.push(__sanitize(this.action_date_str));
    
      __out.push('" />\n                            </div>\n                        </div>\n                        <p class="warning-note-label">Books Lock Date is the date of closing books on Docyt. Reconciliation center will only surface transactions after this date in the uncategorized transactions section. Prior to this date, Invoices cannot be paid and receipt cannot be approved.</p>\n                    </div>\n                </div>\n                <div class="modal-footer">\n                    <button type=\'button\' class="btn btn-blue cancel">Done</button>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="modal-backdrop fade in"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}