import { TAmount, TDate } from './common';

export type TFilterKey = string;

export type TDateFilter = {
  gte?: TDate,
  lte?: TDate,
};
export type TDateFilterKey = keyof TDateFilter;

export type TAmountFilter = {
  gte?: TAmount,
  lte?: TAmount,
  val?: TAmount,
}
export type TAmountFilterKey = keyof TAmountFilter;

export const CustomFilterKeys = <const>['amount', 'transaction_date', 'transaction_type', 'invoice_date', 'created_at'];
export type TCustomFilterValue = TAmountFilter | TDateFilter | string[] | number[];
export type TCustomFilterKey = typeof CustomFilterKeys[number];

export type TFilterData = Partial<
  Record<
    TFilterKey,
    string | TCustomFilterValue | boolean | number | number[] | undefined
  >
>;

export interface IFilter<TFilter extends TFilterData> {
  readonly data?: TFilter,
  readonly update: (data: TFilter) => void,
  readonly clear: () => void,
  readonly filteredCount: number,
}
