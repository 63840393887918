import React, { useCallback } from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { getUTCTimezone } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Table from '@src/components/ui/table';

import ApproveAction from '../actions/approve_action';
import DeleteAction from '../actions/delete_action';
import { useBankStatementDetailsModal } from '../bank_statement_details/details_modal';

interface IBankStatementPendingReviewTableItemProps {
  businessId: TID,
  bankStatement: IBankStatement,
  onCloseModal: () => void,
}

const BankStatementPendingReviewTableItem = ({
  bankStatement,
  businessId,
  onCloseModal,
}: IBankStatementPendingReviewTableItemProps) => {
  const detailModal = useBankStatementDetailsModal();
  const { openWithValue } = detailModal;

  const handleApproveStatement = useCallback(() => {
    openWithValue({
      docytId: bankStatement.docytId,
    });
  }, [bankStatement.docytId, openWithValue]);

  return (
    <>
      <detailModal.Component
        { ...detailModal.props }
        businessId={ businessId }
        onCloseModal={ onCloseModal }
      />
      <Table.Row>
        <Table.Cell
          pointer
          style={ { color: '#0080FF' } }
          onClick={ handleApproveStatement }
        >
          { bankStatement.name }
        </Table.Cell>
        {
          bankStatement.date ? (
            <Table.DateCell
              pointer
              date={ String(getUTCTimezone(new Date(bankStatement.date))) }
              onClick={ handleApproveStatement }
            />
          ) : (
            <Table.Cell pointer onClick={ handleApproveStatement }>
              -
            </Table.Cell>
          )
        }
        <Table.Cell pointer onClick={ handleApproveStatement }>
          {
            bankStatement.reconciliationPaymentAccount
              ? (bankStatement.reconciliationPaymentAccount.name)
              : ('-')
          }
        </Table.Cell>
        {
          bankStatement.closingBalance ? (
            <Table.AmountCell
              pointer
              amount={ bankStatement.closingBalance }
              fontVariant="bold"
              style={ { paddingRight: '30px' } }
              onClick={ handleApproveStatement }
            />
          ) : (
            <Table.Cell
              pointer
              fontVariant="bold"
              style={ { paddingRight: '30px', textAlign: 'right' } }
              onClick={ handleApproveStatement }
            >
              -
            </Table.Cell>
          )
        }
        <Table.Cell className="transaction-actions-column">
          <ActionsDropdown>
            <ApproveAction onApproveBankStatement={ handleApproveStatement } />
            <DeleteAction bankStatement={ bankStatement } />
          </ActionsDropdown>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default React.memo(BankStatementPendingReviewTableItem);
