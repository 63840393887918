module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (!(this.uploaded_keys.length > 0)) {
        __out.push('\n<div class="document-upload-container">\n  <div class="text-center before-upload-container">\n    <p class="font-18">Drag and drop a statement here.</p>\n    <p class="font-18">OR</p>\n    <p>\n      <button class="btn fileinput-button font-16">\n        Select from your computer\n        <input type="file" name="files[]" class="document-file-input">\n      </button>\n    </p>\n  </div>\n  <div class="text-center uploading-container hidden">\n    <p class="font-20">Uploading Statement...</p>\n    <div class="progress" style="height: 4px;">\n      <div class="progress-bar in-blue-900" role="progressbar" aria-valuenow="70"\n           aria-valuemin="0" aria-valuemax="100" style="width:70%; border-radius: 3.5px;">\n        <span class="sr-only">70% Complete</span>\n      </div>\n    </div>\n  </div>\n</div>\n');
      } else {
        __out.push('\n<div class="transaction-actions-container">\n  <p class="m-b-0">\n    <a class="btn btn-circle btn-default m-r-5" id="btnPrevPage" title="Previous page"><i class="fa fa-angle-left"></i></a>\n    <a class="btn btn-circle btn-default m-r-5" id="btnNextPage" title="Next page"><i class="fa fa-angle-right"></i></a>\n    <button class="btn btn-default btn-zoom-minus"><i class="fa fa-minus"></i></button>\n    <select class="selectpicker select-zoom" data-width="100px">\n      <option value="0.25">25%</option>\n      <option value="0.5">50%</option>\n      <option value="0.75">75%</option>\n      <option value="1" selected="selected">100%</option>\n      <option value="1.25">125%</option>\n      <option value="1.5">150%</option>\n      <option value="2">200%</option>\n      <option value="3">300%</option>\n      <option value="4">400%</option>\n    </select>\n    <button class="btn btn-default btn-zoom-plus"><i class="fa fa-plus"></i></button>\n    <button class="font-14 btn btn-blue btn-select-rows pull-right">Select Rows</button>\n    <button class="font-14 btn btn-blue-on-hover btn-done pull-right hidden">Done</button>\n  </p>\n</div>\n<div class="transaction-image-container">\n  <div class="transaction-image-wrapper">\n    <img class="transaction-image" src="');
        __out.push(__sanitize(this.imageSrc));
        __out.push('" />\n  </div>\n  <button class="btn btn-blue btn-export-data font-18 font-semi-bold hidden">Export Data</button>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}