import { TOrderDirection, TID } from '@src/types/common';
import {
  IDataExport,
  IDataExportData,
  TDataExportsSortColumn,
  IDataExportsFilter,
} from '@src/types/data_exports';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetDataExportsParams {
  filter?: IDataExportsFilter,
  orderColumn?: TDataExportsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
  perPage?: number,
}

interface IGetDataExportsResponse {
  meta: {
    totalCount: number,
  },
  collection: IDataExport[],
}

const getDataExports = (
  params: IGetDataExportsParams,
): Promise<IGetDataExportsResponse> => {
  return apiGet(
    '/api/v1/data_exports',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta: {
        totalCount: cdata.total,
      },
      collection: cdata.dataExports as IDataExport[],
    };
  });
};

interface ICreateDataExportParams {
  businessIds: TID[],
  dataExport: IDataExportData,
}

interface ICreateDataExportResponse {
  dataExport: IDataExport[],
}

const createDataExport = (
  params: ICreateDataExportParams,
): Promise<ICreateDataExportResponse> => {
  return apiPost(
    '/api/v1/data_exports',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateDataExportResponse);
};

interface IUpdateDataExportParams {
  id: TID,
  dataExport: IDataExportData,
}

interface IUpdateDataExportResponse {
  dataExport: IDataExport[],
}

const updateDataExport = (
  params: IUpdateDataExportParams,
): Promise<IUpdateDataExportResponse> => {
  return apiPut(`/api/v1/data_exports/${params.id}`, underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IUpdateDataExportResponse);
};

interface IDeleteDataExportParams {
  dataExportId: TID,
}

interface IDownloadDataExportParams {
  dataExportId: TID,
}

const deleteDataExport = (
  params: IDeleteDataExportParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/data_exports/${params.dataExportId}`,
  );
};

interface IDownloadDataExportResponse {
  exportedFileUrl: string,
}

const downloadDataExport = (
  params: IDownloadDataExportParams,
): Promise<IDownloadDataExportResponse> => {
  return apiGet(
    `/api/v1/data_exports/${params.dataExportId}/exported_file_url`,
  ).then((data) => camelizeKeys(data) as IDownloadDataExportResponse);
};

interface IGetRecentDataExportsResponse {
  dataExports: IDataExport[],
}

const getRecentDataExports = (): Promise<IGetRecentDataExportsResponse> => {
  return apiGet(
    '/api/v1/data_exports/recent_exports',
  ).then((data) => camelizeKeys(data) as IGetRecentDataExportsResponse);
};

export {
  IGetDataExportsParams,
  IGetDataExportsResponse,
  ICreateDataExportParams,
  ICreateDataExportResponse,
  IUpdateDataExportParams,
  IUpdateDataExportResponse,
  IDeleteDataExportParams,
  IDownloadDataExportParams,
  IDownloadDataExportResponse,
  IGetRecentDataExportsResponse,
  getDataExports,
  createDataExport,
  updateDataExport,
  deleteDataExport,
  downloadDataExport,
  getRecentDataExports,
};
