import { createContext, useContext } from 'react';

import { TID } from '@src/types/common';

const JournalEntriesContext = createContext<TID>(0);

const useJournalEntriesContext = () => useContext(JournalEntriesContext);

export {
  JournalEntriesContext,
  useJournalEntriesContext,
};
