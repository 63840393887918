import React from 'react';

import { BulkActions } from './bulk_actions';

const DataExportHeader = (): JSX.Element => {
  return (
    <BulkActions />
  );
};

export default DataExportHeader;
