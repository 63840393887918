import React, { useCallback } from 'react';

import Table from '@src/components/ui_v2/table';
import { useItemSelector } from '@src/components/utils_v2/items_selector';

import { TColumn, TModel } from './types';
import ValueCell from './value_cell';

interface ICollectionTableRowProps<Model extends TModel, TSortColumn extends string = never> {
  columns: TColumn<Model, TSortColumn>[],
  idField: keyof Model,
  isRowClickable?: (object: Model) => boolean,
  isRowInactive?: (object: Model) => boolean,
  isRowSelectable?: (object: Model) => boolean,
  object: Model,
  showSelect?: boolean
  stickySelectColumn?: boolean,
  onClick?: (object: Model) => void,
  onCheckboxClick?: (object: unknown) => void
}

const CollectionTableRow = <Model extends TModel, TSortColumn extends string = never>({
  columns,
  idField,
  isRowClickable,
  isRowInactive,
  isRowSelectable,
  object,
  showSelect,
  stickySelectColumn,
  onClick,
  onCheckboxClick,
}: ICollectionTableRowProps<Model, TSortColumn>) => {
  const { isSelected, toggleMark } = useItemSelector();

  const objectId = object[idField];

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(object);
    } else if (showSelect && (!isRowSelectable || isRowSelectable(object))) {
      toggleMark(objectId);
    }
  }, [object, objectId, toggleMark, showSelect, onClick, isRowSelectable]);

  const isClickable = isRowClickable !== undefined ? isRowClickable(object) : undefined;
  const isInactive = isRowInactive !== undefined ? isRowInactive(object) : undefined;

  return (
    <Table.Row
      isClickable={ isClickable }
      isInactive={ isInactive }
      selected={ isSelected(objectId) }
      onClick={ showSelect || onClick ? handleClick : undefined }
    >
      {showSelect && (
        <Table.SelectItemCell
          inputProps={ { disabled: isRowSelectable && !isRowSelectable(object) } }
          item={ objectId }
          sticky={ stickySelectColumn }
          onCheckboxClick={ onCheckboxClick }
        />
      )}
      { columns.map((column) => (
        <ValueCell<Model, TSortColumn>
          key={ String(column.name) }
          column={ column }
          object={ object }
        />
      )) }
    </Table.Row>
  );
};

const MemoizedCollectionTableRow = React.memo(CollectionTableRow) as typeof CollectionTableRow;

export {
  ICollectionTableRowProps,
  MemoizedCollectionTableRow as default,
};
