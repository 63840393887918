import React from 'react';

import toastr from '@lib/toastr';
import { downloadFromS3 } from '@src/requests/aws';

import { Button } from '@src/components/ui/buttons';

import ImportJournal from './import_journal';
import List from './list';

import styles from './styles.module.scss';

const TEMPLATE_S3_KEY = 'journal-entries/template.csv';

const downloadTemplate = () => downloadFromS3({ s3ObjectKey: TEMPLATE_S3_KEY }).then((data) => {
  const blob = new Blob([data.fileData], { type: 'text/csv' });
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = window.URL.createObjectURL(blob);
  link.download = 'journal-entries-template.csv';
  link.click();
  link.remove();
}).catch(() => toastr.error('Template Download Failed', 'The template could not be downloaded'));

const JournalEntriesContainer: React.FC = () => {
  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        <ImportJournal />
        <Button className={ styles['button-secondary'] } onClick={ downloadTemplate }>
          Download Template
        </Button>
      </div>
      <List />
    </div>
  );
};

export default JournalEntriesContainer;
