import React, { useCallback, useState } from 'react';

import { UseQueryResult } from 'react-query';

import toastr from '@lib/toastr';
import { IGetPaymentAccountsResponse } from '@src/requests/payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import CardTile from '@src/components/banking_accounts/accounts/employee_cards/card_tile/tile';
import { useEmployeeSettingSideView } from '@src/components/banking_accounts/accounts/settings/employee_settings';

interface EmployeeCardProps {
  employeeCardsQueryAdapter: UseQueryResult<IGetPaymentAccountsResponse, Error>
  rcPaymentAccount: IReconciliationPaymentAccount | undefined,
}

const EmployeeCards = ({
  employeeCardsQueryAdapter,
  rcPaymentAccount,
}: EmployeeCardProps): JSX.Element => {
  const [
    currentSelectedEmployeeCard,
    setCurrentSelectedEmployeeCard,
  ] = useState<IPaymentAccount | undefined>(undefined);

  const { refetch: refetchEmployeeCard } = employeeCardsQueryAdapter;

  // Adjust handleOnClose to also set isEmployeeSettingsOpen to false
  const handleEmployeeCardSettingOnDone = useCallback(() => {
    refetchEmployeeCard().then(() => {
      toastr.success('Employee Card has been updated successfully', 'Success');
      setCurrentSelectedEmployeeCard(undefined);
    });
  }, [refetchEmployeeCard]);

  const handleEmployeeCardSettingOnCancel = useCallback(() => {
    setCurrentSelectedEmployeeCard(undefined);
  }, []);

  const {
    openWithValue,
    Component: EmployeeSettingSideView,
    props: EmployeeSettingSideViewProps,
  } = useEmployeeSettingSideView({
    onDone:   handleEmployeeCardSettingOnDone,
    onCancel: handleEmployeeCardSettingOnCancel,
  });

  // Adjust handleEmployeeCardClick to also set isEmployeeSettingsOpen to true
  const handleEmployeeCardClick = (employeeCardItem: IPaymentAccount) => {
    setCurrentSelectedEmployeeCard(() => {
      openWithValue({
        paymentAccount: employeeCardItem,
      });
      return employeeCardItem;
    });
  };

  return (
    <>
      <EmployeeSettingSideView
        { ...EmployeeSettingSideViewProps }
      />
      <div className="payment-account-list row">
        {employeeCardsQueryAdapter.data?.paymentAccounts.map((paymentAccount) => (
          <CardTile
            key={ `${rcPaymentAccount?.id}-${paymentAccount.id}` }
            currentSelectedEmployeeCard={ currentSelectedEmployeeCard }
            employeeCardItem={ paymentAccount }
            handleCardClick={ handleEmployeeCardClick }
            isPrimary={ rcPaymentAccount?.defaultPaymentAccountId === paymentAccount.id }
            refetchEmployeeCard={ refetchEmployeeCard }
          />
        ))}
      </div>
    </>
  );
};

export default EmployeeCards;
