module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<button class="btn btn-blue-on-hover dropdown-toggle font-14 font-semi-bold p-b-10 p-l-16 p-r-16 p-t-10" type="button" id="bulk-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n  Bulk Action\n  <span class="fa fa-caret-down"></span>\n</button>\n\n<div class="dropdown-menu" aria-labelledby="bulk-dropdown-btn">\n  ');
    
      if (this.allFlaged) {
        __out.push('\n    <div class="bulk-action-item review-transaction-js">\n      <i class="icon icon-check_circle_large m-l-3 m-r-18"></i> <span>Mark as Reviewed</span>\n    </div>\n  ');
      } else {
        __out.push('\n    <div class="bulk-action-item flag-transaction-js">\n      ');
        if (this.canReopenAll) {
          __out.push('\n        <i class="icon icon-re-open m-l-3 m-r-18"></i> <span>Re-Open Issue</span>\n      ');
        } else {
          __out.push('\n        <i class="icon icon-docyt-support m-l-3 m-r-18"></i> <span>Flag for Client Review</span>\n      ');
        }
        __out.push('\n    </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (!this.allUnflaged) {
        __out.push('\n    <div class="bulk-action-item close-transaction-js">\n      <i class="icon icon-cross-icon m-l-3 m-r-18"></i> <span>Close Issue</span>\n    </div>\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (!(this.noDocumentRequired || this.hasDocuments)) {
        __out.push('\n    <div class="bulk-action-item no-document-required">\n      <i class="icon-mark-as-matched vertical-align-middle font-18 m-r-18"></i> <span>No document needed</span>\n    </div>\n  ');
      } else if (!this.isSingleDocument) {
        __out.push('\n    <div class="bulk-action-item no-document-required">\n      <i class="icon-mark-as-matched vertical-align-middle font-18 m-r-18"></i> <span>No document needed</span>\n    </div>\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (this.transactionType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE'] || this.transactionType === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
        __out.push('\n    ');
        if (this.matchedDocuments && this.matchedDocuments.length && this.isSingleDocument) {
          __out.push('\n      <div class="bulk-action-item unmatch-document">\n        <span class="icon-cross-icon vertical-align-middle font-18 m-r-18"></span>Unmatch Documents</a>\n      </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (this.allVerified) {
        __out.push('\n    <div class="bulk-action-item unverify-js">\n      <i class="icon-cross-icon vertical-align-middle font-18 m-r-18"></i> <span>Uncategorize</span>\n    </div>\n    ');
        if (this.transactionType === Docyt.Common.Constants.TRANSACTION_TYPES['TRANSFER_TYPE']) {
          __out.push('\n    <div class="bulk-action-item match-as-transfer-js p-l-12">\n      <i class="icon icon-bank-transfers-nav font-24 m-r-15"></i><span>Match as Bank Transfer</span>\n    </div>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (this.transactionType !== Docyt.Common.Constants.TRANSACTION_TYPES['FLAGGED_TYPE']) {
        __out.push('\n    <div class="bulk-action-item export-transaction">\n      <i class="icon icon-download m-l-3 m-r-18"></i> <span>Export as CSV</span>\n    </div>\n  ');
      }
    
      __out.push('\n\n  ');
    
      if (this.transactionType === Docyt.Common.Constants.TRANSACTION_TYPES['EXPENSE_TYPE'] && this.isReCategorisationEnable) {
        __out.push('\n    <div class="bulk-action-item re-categorize">\n      <i class="icon icon-pencil command m-l-3 m-r-18"></i> <span>Recategorize</span>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}