import React, { useCallback } from 'react';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import Modal from '@src/components/ui/modal';
import Form, { IDataExportUpdateFormValues } from './rename_form';
import { IDataExport } from '@src/types/data_exports';

interface IDataExportUpdateProps extends Omit<IUseModalProps, 'onDone'> {
  dataExport: IDataExport,
  onDone: (exportFileName: string) => void,
}

const MarkAsNoAvailable = ({
  dataExport,
  isOpen,
  onCancel,
  onDone,
}: IDataExportUpdateProps) => {
  const handleSubmit = useCallback((value: IDataExportUpdateFormValues) => {
    onDone(value.exportFileName);
  }, [onDone]);

  return (
    <Modal.Form
      show={ isOpen }
      title="Rename"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          dataExport={ dataExport }
          formId={ formId }
          onSubmit={ handleSubmit }
        />
      )}
    </Modal.Form>
  );
};

const useRenameModal = makeUseModal(MarkAsNoAvailable);

export {
  IDataExportUpdateProps,
  useRenameModal,
  MarkAsNoAvailable as default,
};
