import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmDeleteDocumentPermanentlyModalParams extends IUseModalParams {
  document: IInboxServiceDocument,
}

interface IUseConfirmDeleteDocumentPermanentlyModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
}

const ConfirmDeleteDocumentPermanentlyTitle = (
  { document }: { document: IInboxServiceDocument },
) => (
  <>
    { document.name }
    { ' ' }
    will be deleted permanently.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmDeleteDocumentPermanentlyModal = (
  {
    document,
    ...modalParams
  }: IUseConfirmDeleteDocumentPermanentlyModalParams,
): IUseConfirmDeleteDocumentPermanentlyModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'danger',
      confirmTitle: 'Delete',
      icon:         <DeleteDocumentIcon />,
      text:         <ConfirmDeleteDocumentPermanentlyTitle document={ document } />,
      title:        `Delete ${document.name} Permanently?`,
    },
  };
};

export {
  useConfirmDeleteDocumentPermanentlyModal,
};
