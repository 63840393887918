import axios from 'axios';

const getAppVersion = (): Promise<string> => {
  return axios
    .get(`/version.json?${new Date().getTime()}`) // Adding the current timestamp to prevent caching
    .then((response) => response.data?.version);
};

export {
  getAppVersion,
};
