import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DeleteDocumentIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="delete-document"
      pathsNumber={ 11 }
    />
  );
};

export default DeleteDocumentIcon;
