import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUpdateBusinessProfile } from '@src/hooks/queries/businesses';
import { TID } from '@src/types/common';

import ManagementGroupInput from '@src/components/common_v2/inputs/management_group_input';
import DetailsPageTitle from '@src/components/ui/details_page_title/details_page_title';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from './styles.module.scss';

const EditManagementGroups = (): JSX.Element => {
  const business = useBusinessContext();
  const updateProfile = useUpdateBusinessProfile();

  const { mutate } = updateProfile;

  const handleManagementGroupChange = useCallback((selectedValue?: TID | null) => {
    mutate(
      {
        businessId: business.id,
        business:   { managementGroupId: Number(selectedValue) },
      },
    );
  }, [mutate, business]);

  return (
    <div className={ styles['edit-management-group-container'] }>
      <MutationStatus mutation={ updateProfile } successMessage="Updated management group successfully." />
      <DetailsPageTitle title="Management Group" />
      <div className="font-16 m-t-30">
        A management group is a group of businesses owned by the same partners.
        Select a management group from the list of below available groups or
        { ' ' }
        <a
          className="pointer font-bold"
          href="/settings/management_groups/new"
        >
          create a new one.
        </a>
      </div>
      <div className="display-flex align-items-center m-t-30">
        <span className="font-16">Select Management Group</span>
        <ManagementGroupInput
          className="management-group-picker"
          value={ business.managementGroupId }
          onChange={ handleManagementGroupChange }
        />
      </div>
    </div>
  );
};

export default EditManagementGroups;
