import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IWrenchIconProps extends Omit<IFaIconProps, 'icon'> {}

const WrenchIcon = (props: IWrenchIconProps): JSX.Element => {
  return <FaIcon icon="wrench" { ...props } />;
};

export default WrenchIcon;
