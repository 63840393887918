module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, id;
    
      __out.push('<div class="container">\n    <div class="m-t-40 m-b-40 relative text-right header-panel m-l-auto m-r-auto">\n        <h1 class="font-24 font-bold text-center">Sign in</h1>\n    </div>\n    <div class="background-white sign-in-main-panel m-l-auto m-r-auto">\n        <form class="activate-account-form">\n            <div class="padding-50">\n                <div class="row relative m-t-10">\n                    <p class="font-14 m-b-40 center">To activate your web account, validate your mobile account PIN below, and create a Web Password, that is different from your PIN.</p>\n                    <div class="main-input__wrap">\n                        <div class="form-group-wrapper">\n                            <label class="font-14 font-semi-bold width-50-percent">Verify Mobile Account PIN</label>\n                            <div class="inline-flex-wrapper pin-inputs-wrapper">\n                                ');
    
      for (id = i = 0; i <= 5; id = ++i) {
        __out.push('\n                                <input class="main-input pin-input m-r-10" type="password" placeholder="" data-index="');
        __out.push(__sanitize(id));
        __out.push('" maxlength="1">\n                                ');
      }
    
      __out.push('\n                            </div>\n                        </div>\n                        <p class="main-input__hint in-red-400 pin-invalid">PIN is invalid.</p>\n                    </div>\n                    <div class="main-input__wrap m-t-30">\n                        <div class="form-group-wrapper">\n                            ');
    
      if (this.email !== "") {
        __out.push('\n                            <label class="font-14 font-semi-bold width-50-percent">Account Username(Email)</label>\n                            <input class="main-input width-50-percent email-input-js" type="email" placeholder="Email" disabled value="');
        __out.push(__sanitize(this.email));
        __out.push('">\n                            <a class="block-wrapper m-t-5 pointer edit-email-link" data-toggle="tooltip" data-placement="right" title="This email id was setup in your Docyt mobile app. You can edit it from the mobile app, by going to Settings > Email. This can be changed later too.">\n                                Edit Email\n                            </a>\n                            ');
      } else {
        __out.push('\n                            <label class="font-14 font-semi-bold width-50-percent">Account Username(Email)</label>\n                            <input class="main-input width-50-percent email-input-js" type="email" placeholder="Email" value="');
        __out.push(__sanitize(this.unverified_email));
        __out.push('">\n                            ');
      }
    
      __out.push('\n                        </div>\n                        <p class="main-input__hint in-red-400 email-invalid">Please enter correct email</p>\n                        <p class="main-input__hint in-red-400 email-exists">This email is already in use</p>\n                    </div>\n                    <div class="main-input__wrap">\n                        <div class="form-group-wrapper">\n                            <label class="font-14 font-semi-bold width-50-percent">Create Web Password</label>\n                            <input class="main-input width-50-percent password-input-js" type="password" placeholder="Number, Letters, 8+ characters">\n                        </div>\n                        <p class="main-input__hint in-red-400 password-too-short">Password too short</p>\n                    </div>\n                    <div class="main-input__wrap">\n                        <div class="form-group-wrapper">\n                            <label class="font-14 font-semi-bold width-50-percent">Confirm Password</label>\n                            <input class="main-input password-confirmation-input width-50-percent" type="password" placeholder="Confirm Password">\n                        </div>\n                        <p class="main-input__hint in-red-400 password-dont-match">Password doesn\'t match</p>\n                    </div>\n                    <div class="main-input__wrap">\n                        <div class="text-center">\n                            <button type=\'submit\' class="btn btn-primary m-t-30 ladda-button submit-sign-up-js continue-js" data-color="blue" data-style="expand-right"><span class="ladda-label">Continue</span></button>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}