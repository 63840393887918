import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IPageContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  sidemenu?: React.ReactNode,
  rightSideView?: React.ReactNode,
}

const PageContainer = ({
  children,
  className,
  sidemenu,
  rightSideView,
}: IPageContainerProps) => {
  const classes = classNames(styles['page-container'], className);

  return (
    <div className={ classes }>
      { sidemenu && (
        <div id="sidemenu-region">{ sidemenu }</div>
      ) }
      <div className={ styles.details }>
        { children }
      </div>
      { rightSideView && (
        <div className={ styles['right-side-view'] }>{ rightSideView }</div>
      ) }
    </div>
  );
};

export default React.memo(PageContainer);
