import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetGlobalSearchFilterParams,
  IGlobalSearchResponse,
  getSearchFilter,
  getSearchResults,
} from '@src/requests/search/search';
import {
  IGlobalSearchFilterResponse,
  IGlobalSearchParams,
  ISearchResultItem,
} from '@src/types/global_search/global_search_requests';

import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

const useGetGlobalSearchFilterParams = (
  params: IGetGlobalSearchFilterParams,
) => {
  return useQuery<IGlobalSearchFilterResponse, Error>(
    [QueryKey.globalSearchFilter, params],
    () => getSearchFilter(params),
    {
      keepPreviousData: true,
    },
  );
};

const useGetSearchResults = createUseGetInfiniteCollection<
ISearchResultItem,
IGlobalSearchParams,
IGlobalSearchResponse
>({
  queryKey: QueryKey.globalSearch,
  request:  getSearchResults,
});

export { useGetGlobalSearchFilterParams, useGetSearchResults };
