import React from 'react';

import financialInstitutionConnectionBlankImg from '@assets/images/Financial_institution_connection_center/financial-institution-connection-blank.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const FinancialInstitutionConnectionBlankIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Expense icon"
      src={ financialInstitutionConnectionBlankImg }
      { ...props }
    />
  );
};

export default FinancialInstitutionConnectionBlankIcon;
