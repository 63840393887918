import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { IGetCombinedServiceDocumentsResponse } from '@src/requests/combined_service_documents';
import { ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn } from '@src/types/combined_service_documents';
import { ISorting } from '@src/types/sorting';
import { formatDate } from '@src/utils/date_helpers';

import { VendorAvatar } from '@src/components/ui/avatars';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { ReceiptDocumentIcon } from '@src/components/utils/icomoon';
import { ApServiceLogoIcon } from '@src/components/utils/img_icons';

import MatchDocumentsFilter from '../filter';
import Action from './actions';

interface IMatchDocumentsCombinedTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetCombinedServiceDocumentsResponse, Error>,
  records: ICombinedServiceDocument[],
  sorting: ISorting<TCombinedServiceDocumentsSortColumn>,
}

const vendorAvatar = (document: ICombinedServiceDocument) => {
  return <VendorAvatar size="100%" vendor={ document.vendor } />;
};

const documentType = (document: ICombinedServiceDocument) => {
  const icon = document.documentType === 'Invoice'
    ? <ApServiceLogoIcon size="100%" />
    : <ReceiptDocumentIcon fontSize={ 40 } />;

  return (
    <AppLink newWindow href={ `/document/${document.documentId}` }>
      { icon }
    </AppLink>
  );
};


const MatchDocumentsCombinedTable = ({
  infiniteQuery,
  records,
  sorting,
}: IMatchDocumentsCombinedTableProps) => {

  const business = useBusinessContext();

  const actions = (document: ICombinedServiceDocument) => {
    return <Action document={ document } disabledRow={document.actualPaymentDate < business.reconciliationStartDate}/>;
  };

  const handleIsRowSelectable = useCallback((document: ICombinedServiceDocument) => {
    return business.reconciliationStartDate == null || document.actualPaymentDate >= business.reconciliationStartDate
  }, []);

  return (
    <CollectionTable<ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ <MatchDocumentsFilter listType="combined" /> }
      isRowSelectable={ handleIsRowSelectable }
      height="750px"
      idField="documentId"
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn>
        icon={ vendorAvatar }
        name="vendor_id"
        sortColumn="vendor_id"
        title="Payee"
        value={ (document) => document.vendor?.name ?? 'Unknown Vendor' }
        width="50%"
      />
      <CollectionTable.TextColumn<ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn>
        name="transaction_date"
        sortColumn="transaction_date"
        subtitle={ (document) => document.invoiceDate && document.documentType === 'Invoice' && `Invoice: ${formatDate(document.invoiceDate)}` }
        title="Date"
        value={ (document) => (document.documentType === 'Invoice' ? `Paid: ${formatDate(document.actualPaymentDate)}` : formatDate(document.actualPaymentDate)) }
        width="100px"
      />
      <CollectionTable.TextColumn<ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn>
        name="payment_account"
        sortColumn="payment_account"
        subtitle={ (document) => document.checkNumber && `(Check #${document.checkNumber})` }
        title="Account"
        value={ (document) => document.paymentAccountName }
        width="50%"
      />
      <CollectionTable.AmountColumn<ICombinedServiceDocument, TCombinedServiceDocumentsSortColumn>
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (document) => document.amountVerified }
      />
      <CollectionTable.IconColumn<ICombinedServiceDocument>
        name="document_type"
        title="Document type"
        value={ documentType }
      />
      <CollectionTable.ActionsColumn<ICombinedServiceDocument>
        name="actions"
        title="Actions"
        value={ actions }
      />
    </CollectionTable>
  );
};

export default React.memo(MatchDocumentsCombinedTable);
