import React, { forwardRef } from 'react';

import {
  IUIStyleProps,
  uiStyleProps,
} from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface IButtonProps extends React.ComponentPropsWithoutRef<'button'>, IUIStyleProps {
  /** Button color. */
  bsColor?: 'blue',
  /** Button size. */
  bsSize?: 'xlarge',
  /** Button style. */
  bsStyle?: 'danger' | 'info' | 'primary' | 'blue-on-hover' | 'link',
  /** Button type. This prop passed directly to the `button` element. */
  type?: 'submit' | 'reset' | 'button' | undefined,
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(({
  bsColor,
  bsSize,
  bsStyle,
  children,
  title,
  type = 'button',
  ...props
}: IButtonProps, ref): JSX.Element => {
  const [classes, buttonProps] = uiStyleProps(
    styles.btn,
    props,
    'btn',
    [
      bsColor && `btn-${bsColor}`,
      bsStyle && `btn-${bsStyle}`,
      bsSize && `btn-${bsSize}`,
    ],
  );

  return (
    <button
      ref={ ref }
      className={ classes }
      // eslint-disable-next-line react/button-has-type
      type={ type }
      { ...buttonProps }
    >
      { title || children }
    </button>
  );
});

Button.displayName = 'Button';

export {
  IButtonProps,
  Button as default,
};
