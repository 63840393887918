import React, { useCallback } from 'react';

import { useBulkMarkDocumentsAsRead } from '@src/hooks/queries/documents';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import Dropdown from '@src/components/ui/dropdown';
import { EnvelopeIcon } from '@src/components/utils/fa_icons';
import { MailIcon } from '@src/components/utils/icomoon';

interface IBusinessInboxMarkAsReadActionParams {
  documentIds: number[],
}

const BusinessInboxMarkAsReadAction = ({
  documentIds,
}: IBusinessInboxMarkAsReadActionParams): JSX.Element => {
  const markMutation = useBulkMarkDocumentsAsRead();
  const bulkMarkAsRead = useBulkActionModal({ mutation: markMutation });
  const bulkMarkAsUnread = useBulkActionModal({ mutation: markMutation });

  const { runMutation: runMarkAsRead } = bulkMarkAsRead;
  const onMarkAsRead = useCallback(() => {
    const mutationParams = documentIds.map((id) => ({
      documentIds: [id],
      value:       true,
    }));

    runMarkAsRead(mutationParams);
  }, [runMarkAsRead, documentIds]);

  const { runMutation: runMarkAsUnread } = bulkMarkAsUnread;
  const onMarkAsUnread = useCallback(() => {
    const mutationParams = documentIds.map((id) => ({
      documentIds: [id],
      value:       false,
    }));

    runMarkAsUnread(mutationParams);
  }, [runMarkAsUnread, documentIds]);

  return (
    <>
      <bulkMarkAsRead.Component
        { ...bulkMarkAsRead.props }
        itemsTitle="Documents"
        title="Mark Documents as Read"
      />
      <bulkMarkAsUnread.Component
        { ...bulkMarkAsUnread.props }
        itemsTitle="Documents"
        title="Mark Documents as Unread"
      />
      <Dropdown>
        <Dropdown.LinkToggle
          disabled={ documentIds.length === 0 }
          fontSize={ 16 }
          fontVariant="semi-bold"
        >
          Mark
        </Dropdown.LinkToggle>
        <Dropdown.Menu>
          <Dropdown.Item onSelect={ onMarkAsRead }>
            <MailIcon fontSize={ 16 } mr={ 22 } />
            { ' ' }
            Mark as Read
          </Dropdown.Item>
          <Dropdown.Item onSelect={ onMarkAsUnread }>
            <EnvelopeIcon fontSize={ 20 } mr={ 22 } />
            { ' ' }
            Mark as Unread
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export {
  IBusinessInboxMarkAsReadActionParams,
  BusinessInboxMarkAsReadAction as default,
};
