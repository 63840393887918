import React from 'react';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import MonthSelectInput, { IMonthSelectInputProps } from '../inputs/react_select/month_select_input';
import Wrapper, { IFormFieldWrapperProps } from './form_field_wrapper';

interface IMonthSelectFieldProps extends
  IMonthSelectInputProps,
  Omit<IFormFieldWrapperProps, 'children'>,
  IUIStyleProps
{
}

/**
 * Field to select month.
 *
 * @visibleName Form.MonthSelectField
 */
const MonthSelectField = ({
  error,
  hint,
  label,
  mark,
  ...props
}: IMonthSelectFieldProps): JSX.Element => {
  return (
    <Wrapper error={ error } hint={ hint } label={ label } mark={ mark }>
      <MonthSelectInput
        { ...props }
      />
    </Wrapper>
  );
};

MonthSelectField.displayName = 'MonthSelectField';

export default MonthSelectField;
