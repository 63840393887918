import React, { useCallback } from 'react';

import { rcMonthEndDocmentsOtherDocumentsStatementsDetailsPath } from '@src/routes';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { formatMonth } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Table from '@src/components/ui/table';

import styles from './styles.module.scss';

interface IStatementDetailsTableItemProps {
  balanceSheetStatement: IBalanceSheetStatement,
  bankStatementsDocRequestEnabled: boolean,
  chartOfAccountId: TID,
  businessId: TID,
  year: TYear,
  onCloseDetailsModal?: () => void,
}

const StatementDetailsTableItem = ({
  balanceSheetStatement,
  bankStatementsDocRequestEnabled,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  chartOfAccountId,
  businessId,
  year,
  onCloseDetailsModal,
  /* eslint-disable @typescript-eslint/no-unused-vars */

}: IStatementDetailsTableItemProps) => {
  const month = new Date(balanceSheetStatement.month).getMonth() + 1;

  let stateContent;
  if (balanceSheetStatement.state === 'verified') {
    stateContent = (<span className={ styles['verified-state'] }>Documents Verified</span>);
  } else if (balanceSheetStatement.state === 'not_available') {
    stateContent = (<span className={ styles['not-available-state'] }>No Document Available</span>);
  } else if (bankStatementsDocRequestEnabled
    // eslint-disable-next-line max-len
    && balanceSheetStatement.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.UPLOADED) {
    stateContent = (<span className={ styles['uploaded-state'] }>Documents Uploaded</span>);
  } else if (bankStatementsDocRequestEnabled
    // eslint-disable-next-line max-len
    && balanceSheetStatement.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.REQUESTED) {
    stateContent = (<span className={ styles['requested-state'] }>Documents Requested</span>);
  } else {
    stateContent = (<span className={ styles['requested-state'] }>Documents Missing</span>);
  }

  const handleClickItem = useCallback(() => {
    const redirectPath = rcMonthEndDocmentsOtherDocumentsStatementsDetailsPath(
      businessId,
      chartOfAccountId,
      year,
      month,
    );
    Backbone.history.navigate(redirectPath, { trigger: true });
  }, [businessId, chartOfAccountId, year, month]);

  return (
    <Table.Row className={ styles['table-row'] }>
      <Table.Cell
        pointer
        style={ { color: '#0080FF' } }
        widthPercent={ 20 }
        onClick={ handleClickItem }
      >
        { balanceSheetStatement.month ? formatMonth(balanceSheetStatement.month) : '--' }
      </Table.Cell>
      { bankStatementsDocRequestEnabled && (
      <Table.Cell
        pointer
      >
        { balanceSheetStatement.uploadedByName ? balanceSheetStatement.uploadedByName : '--' }
      </Table.Cell>
      )}
      <Table.Cell
        pointer
      >
        { balanceSheetStatement.verifiedByName ? balanceSheetStatement.verifiedByName : '--' }
      </Table.Cell>
      <Table.Cell pointer>
        { stateContent }
      </Table.Cell>
      <Table.Cell className="bank-statement-actions-column">
        <ActionsDropdown />

      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(StatementDetailsTableItem);
