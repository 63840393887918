module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, month, ref;
    
      __out.push('<input type="text" class="form-control date-picker general-filter-start-date" placeholder="Start ');
    
      __out.push(__sanitize(this.filter_type === 'settlement' ? this.filter_by : 'Date'));
    
      __out.push('" autocomplete="off" value="');
    
      __out.push(__sanitize(this.startDate));
    
      __out.push('" />\n<span class="input-group-addon">-</span>\n<input type="text" class="form-control date-picker general-filter-end-date" placeholder="End ');
    
      __out.push(__sanitize(this.filter_type === 'settlement' ? this.filter_by : 'Date'));
    
      __out.push('" autocomplete="off" value="');
    
      __out.push(__sanitize(this.endDate));
    
      __out.push('" />\n<a class="dropdown-toggle date-select-toggle range-select-menu-btn pointer" type="button" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  <span class="fa fa-caret-down font-14"></span>\n</a>\n<ul class="pointer dropdown-menu dropdown-menu-left font-24 col-xs-12" role="menu">\n  <li><a tabindex="-1" class="general-date-all-time">All Time</a></li>\n  <li class="divider"></li>\n  <li class="dropdown-submenu">\n    <a tabindex="-1" class="">Calendar Month</a>\n    <ul class="dropdown-menu col-xs-12">\n      ');
    
      ref = this.calendarMonth;
      for (i = 0, len = ref.length; i < len; i++) {
        month = ref[i];
        __out.push('\n        <li><a tabindex="-1" data-id="');
        __out.push(__sanitize(this.calendarMonth.indexOf(month)));
        __out.push('" class="general-date-calendar-month">');
        __out.push(__sanitize(month.format('MMM Y')));
        __out.push('</a></li>\n      ');
      }
    
      __out.push('\n    </ul>\n  </li>\n  <li><a tabindex="-1" class="general-date-ytd">Year to Date (YTD)</a></li>\n  <li><a tabindex="-1" class="general-date-mtd">Month to Date (MTD)</a></li>\n  <li><a tabindex="-1" class="general-date-qtd">Quarter to Date (QTD)</a></li>\n  <li><a tabindex="-1" class="general-date-last-year">Last Year</a></li>\n</ul>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}