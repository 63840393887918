module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="nav-item p-l-20 p-t-8 p-b-8 bg-grey-1350">\n  <span class="inline-block in-black font-semi-bold">');
    
      __out.push(__sanitize(this.report.get('name')));
    
      __out.push('</span>\n</div>\n<div class=\'display-flex align-items-center item-divider hidden\'>\n  <div class="checkbox checkbox-primary checkbox-circle checkbox-singleline">\n    <input type="radio" id="hidden_item" name="radio-period" class="pointer radio-item" value="for_non_select" checked>\n  </div>\n</div>\n<div class="report-item-list mapping-item-list"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}