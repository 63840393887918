import React, { FC } from 'react';

import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';
import { currencyLocaleFormatter } from '@src/utils/currency';

import useAcknowledge from '@src/components/reconciliation_center/month_end/acknowledge_action/use_acknowledge';
import AcknowledgeStatement from '@src/components/reconciliation_center/month_end/acknowledge_statement';
import { amountFormatterX } from '@src/components/reconciliation_center/month_end/utils';
import { Button } from '@src/components/ui_v2/buttons';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { EqualSignCircle, HandRightAlt, MinusSignCircle } from '@src/components/utils/icomoon';

import ExcludedAndNotPushed from './excluded_and_not_pushed';
import { ICollection } from './hooks';
import PushedOutside from './pushed_outside';
import { STEP_MATCH } from './wizard_step';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface StepAcknowledgeProps {
  pushedOutsideCollection: ICollection,
  excludedAndNotPushedCollection: ICollection,
  setStep: (step: number) => void,
  items: IBankAccountReconciliationItemTypes,
  closeDrawer?: () => void
}

const StepAcknowledge: FC<StepAcknowledgeProps> = ({
  pushedOutsideCollection,
  excludedAndNotPushedCollection,
  setStep,
  items,
  closeDrawer,
}) => {
  const pushedOutsideTotalAmount = parseFloat(pushedOutsideCollection.query.data?.pages[0]?.meta?.totalAmount || '0');

  // eslint-disable-next-line max-len
  const excludedAndNotPushedTotalAmount = parseFloat(excludedAndNotPushedCollection.query.data?.pages[0]?.meta?.totalAmount || '0');

  const { handleAcknowledge, isLoading } = useAcknowledge(items.additionalUnclearedBalance);

  const itemAcknowledged = items.additionalUnclearedBalance?.status === 'acknowledged';
  const btnText = itemAcknowledged ? 'Retract acknowledgement' : 'Acknowledge';

  const acknowledge = async () => {
    await handleAcknowledge(itemAcknowledged ? 'created' : 'acknowledged');
    closeDrawer?.();
  };
  const adnUnclearedBalance = parseFloat(items.additionalUnclearedBalance?.amount || '0');
  const adnUnclearedBalanceCarriedOver = parseFloat(items.additionalUnclearedBalanceCarriedOver?.amount || '0');
  const additionalUnclearedBalanceTotal = adnUnclearedBalance + adnUnclearedBalanceCarriedOver;
  return (
    <div className={ styles.review }>
      <div className={ styles['review-content'] }>
        <div className={ styles['review-toolbar'] }>
          <div className={ styles['review-toolbar-tip'] }>
            <HandRightAlt fontSize={ 16 } />
            { ' ' }
            Acknowledge the difference of unmatched transactions.
          </div>
        </div>
        <div className={ styles['review-tables'] }>
          <div className={ styles['review-table'] }>
            <PushedOutside
              showSelect
              collection={ pushedOutsideCollection }
              rowSelectable={ false }
            />
          </div>
          <div className={ styles['review-table'] }>
            <ExcludedAndNotPushed
              showSelect
              collection={ excludedAndNotPushedCollection }
              rowSelectable={ false }
            />
          </div>
        </div>
      </div>
      <div className={ styles['review-footer'] }>
        <div>
          {
            !itemAcknowledged && (
              <Button variant="link" onClick={ () => setStep(STEP_MATCH) }>
                Back
              </Button>
            )
          }
        </div>
        <div className={ styles['acknowledge-summary'] }>
          <div>
            <div>
              Transactions Pushed to GL Outside Docyt
            </div>
            <div className={ styles['acknowledge-summary-amount'] }>
              { currencyLocaleFormatter(pushedOutsideTotalAmount) }
            </div>
          </div>
          <div>
            <MinusSignCircle fontSize={ 32 } />
          </div>
          <div>
            <div>
              Excluded and Not Pushed to Ledger Transactions
            </div>
            <div className={ styles['acknowledge-summary-amount'] }>
              { currencyLocaleFormatter(excludedAndNotPushedTotalAmount) }
            </div>
          </div>
          <div>
            <EqualSignCircle fontSize={ 32 } />
          </div>
          <div>
            <div>
              Additional Uncleared Balance
            </div>
            <div className={ styles['acknowledge-summary-amount'] }>
              {
                // eslint-disable-next-line max-len
                currencyLocaleFormatter(pushedOutsideTotalAmount - excludedAndNotPushedTotalAmount)
              }
            </div>
          </div>
        </div>
        <div>
          {
            additionalUnclearedBalanceTotal === 0 ? (
              <Button disabled={ isLoading } variant="ghost" onClick={ acknowledge }>
                { isLoading ? <SpinnerIcon spin /> : btnText }
              </Button>
            ) : (
              <AcknowledgeStatement
                closeDrawer={ closeDrawer }
                item={ items.additionalUnclearedBalance }
                noteRequired={ false }
                total={ amountFormatterX(additionalUnclearedBalanceTotal) }
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default StepAcknowledge;
