import React from 'react';

import styles from './styles.module.scss';

interface ITableTextCellContentProps extends React.ComponentPropsWithoutRef<'div'> {
  icon?: React.ReactNode,
  subtitle?: React.ReactNode,
}

const TableTextCellContent = ({
  children,
  icon,
  subtitle,
  ...props
}: ITableTextCellContentProps) => {
  return (
    <div { ...props }>
      { icon && (
        <div className={ styles['table-text-cell-icon'] }>
          { icon }
        </div>
      ) }
      { (children || subtitle) && (
        <div className={ styles['table-text-cell-text'] }>
          { children }
          { subtitle && <span className={ styles['table-text-cell-subtitle'] }>{ subtitle }</span> }
        </div>
      ) }
    </div>
  );
};

export default TableTextCellContent;
