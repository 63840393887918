import {
  IBalanceSheetStatement,
} from '@src/types/balance_sheet_statements';
import { TDate, TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface ICreateBalanceSheetStatementParams {
  businessId: TID,
  file: File,
  chartOfAccountId: TID,
  date?: TDate,
  description?: string,
}

interface ICreateBalanceSheetStatementResponse {
  balanceSheetStatement: IBalanceSheetStatement,
}

const createBalanceSheetStatement = (
  params: ICreateBalanceSheetStatementParams,
): Promise<ICreateBalanceSheetStatementResponse> => {
  const formData = new FormData();
  formData.append('business_id', String(params.businessId));
  formData.append('chart_of_account_id', String(params.chartOfAccountId));
  formData.append('date', String(params.date));
  formData.append('description', String(params.description));
  if (params.file) {
    formData.append('file', params.file);
  }
  return apiPost(
    '/api/v1/balance_sheet_statements',
    formData,
  ).then((data) => camelizeKeys(data) as ICreateBalanceSheetStatementResponse);
};

interface IUpdateBalanceSheetStatementParams {
  id: TID,
  businessId: TID,
  chartOfAccountId?: TID,
  date?: TDate,
  description?: string,
}

const updateBalanceSheetStatement = (
  params: IUpdateBalanceSheetStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/balance_sheet_statements/${params.id}`,
    underscoreKeys(params),
  );
};

interface IGetBalanceSheetStatementResponse {
  balanceSheetStatement: IBalanceSheetStatement,
}

const getBalanceSheetStatementByDocytId = (
  businessId?: TID,
  docytId?: string,
): Promise<IGetBalanceSheetStatementResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_statements/by_docyt_id',
    underscoreKeys({ businessId, docytId }),
  ).then((data) => {
    return {
      balanceSheetStatement: camelizeKeys(data.balance_sheet_statement),
    } as IGetBalanceSheetStatementResponse;
  });
};

interface IRequestBalanceSheetStatementParams {
  businessId: TID,
  chartOfAccountId: TID,
  date: TDate,
  description?: string,
}

const postRequestBalanceSheetStatement = (
  params: IRequestBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/request_statement',
    underscoreKeys(params),
  );
};

interface IVerifyalanceSheetStatementParams {
  id: TID,
  businessId: TID,
}

const verifyRequestBalanceSheetStatement = (
  params: IVerifyalanceSheetStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/balance_sheet_statements/${params.id}/verify`,
    underscoreKeys(params),
  );
};

interface IDeleteBalanceSheetStatementParams {
  id: TID;
  state?: string;
  confirm?: boolean;
  businessId: TID;
}

const deleteBalanceSheetStatement = (
  params: IDeleteBalanceSheetStatementParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/balance_sheet_statements/${params.id}`,
    underscoreKeys(params),
  );
};

interface IUnverifyBalanceSheetStatementParams {
  id?: TID,
  businessId?: TID,
}

const postUnverifyBalanceSheetStatement = (
  params: IUnverifyBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/unverify',
    underscoreKeys(params),
  );
};

export {
  ICreateBalanceSheetStatementParams,
  ICreateBalanceSheetStatementResponse,
  IUpdateBalanceSheetStatementParams,
  IRequestBalanceSheetStatementParams,
  IVerifyalanceSheetStatementParams,
  IGetBalanceSheetStatementResponse,
  IDeleteBalanceSheetStatementParams,
  IUnverifyBalanceSheetStatementParams,
  createBalanceSheetStatement,
  updateBalanceSheetStatement,
  getBalanceSheetStatementByDocytId,
  postRequestBalanceSheetStatement,
  verifyRequestBalanceSheetStatement,
  deleteBalanceSheetStatement,
  postUnverifyBalanceSheetStatement,
};
