module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var device, i, len, ref;
    
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n        <div class="modal-content">\n            <div class="modal-header">\n                <button class=\'close cancel\' type=\'button\'>\n                    <span>&times;</span>\n                </button>\n                <h4 class="modal-title font-28 font-bold">Edit Devices</h4>\n            </div>\n            <div class="modal-body m-b-20">\n                <div class="p-t-10 p-b-10 p-l-10 p-r-10 font-14">\n                    ');
    
      ref = this.devices;
      for (i = 0, len = ref.length; i < len; i++) {
        device = ref[i];
        __out.push('\n                    ');
        if (device.confirmed_at) {
          __out.push('\n                    <div class="row m-l-0 m-r-0 p-t-15 p-b-15 item-divider">\n                        <label class="col-md-5">');
          __out.push(__sanitize(device.name));
          __out.push('</label>\n                        <span class="col-md-5">Added on ');
          __out.push(__sanitize(device.confirmed_at_string));
          __out.push('</span>\n                        <a class="col-md-2 pointer in-red text-right device-remove-js" data-id="');
          __out.push(__sanitize(device.id));
          __out.push('">Remove</a>\n                    </div>\n                    ');
        }
        __out.push('\n                    ');
      }
    
      __out.push('\n                </div>\n            </div>\n            <div class="modal-footer">\n                <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}