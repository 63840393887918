import React, { useCallback, useMemo } from 'react';

import { useAccountAllSubTypes } from '@src/hooks/queries/account_types';
import { IAccountBigType, IAccountSubType } from '@src/types/account_types';

import ReactSelectInput from '@src/components/ui/form/inputs/react_select/react_select_input';
import Wrapper from '@src/components/ui_v2/form/fields/form_field_wrapper';

interface IAccountSubTypeFieldProps {
  accountType?: IAccountBigType['code']
  value?: IAccountSubType['subtypeCode'],
  onChange: (value: IAccountSubType | null) => void,
  error?: string
}

const getOptionLabel = (type: IAccountSubType) => type.subtypeName;
const getOptionValue = (type: IAccountSubType) => type.subtypeCode;

const DetailType = ({
  accountType,
  value,
  onChange,
  error,
}: IAccountSubTypeFieldProps): JSX.Element => {
  const query = useAccountAllSubTypes({ code: accountType });

  const accountTypes = useMemo(() => {
    return query.data?.subTypes ?? [];
  }, [query.data]);

  const selectedAccountType = useMemo(() => {
    if (!value) return null;

    return accountTypes.find((at) => at.subtypeCode === value);
  }, [value, accountTypes]);

  const handleChange = useCallback((at: IAccountSubType | null) => {
    onChange(at);
  }, [onChange]);

  return (
    <Wrapper error={ error } label="Detail Type">
      <ReactSelectInput<IAccountSubType, false>
        isSearchable
        getOptionLabel={ getOptionLabel }
        getOptionValue={ getOptionValue }
        options={ accountTypes }
        placeholder="Select Detail Type"
        value={ selectedAccountType }
        widthPercent={ 100 }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default DetailType;
