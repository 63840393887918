module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex align-items-center border-bottom-gray p-b-20">\n  <span class="font-26 font-bold">W-9 Form</span>\n  <a class=\'font-16 font-semi-bold m-l-auto pointer edit-info-link ');
    
      if (this.ar_service.get('receivable_dwolla_customer') === null) {
        'disabled';
      } else {
        '';
      }
    
      __out.push('\'>Edit</a>\n</div>\n<div class="m-t-25">\n  <p class="font-16">Docyt will use the following information for W-9 form to be in compliance with federal laws.</p>\n</div>\n<div class="m-t-20">\n  <p>\n    <span class="font-semi-bold width-300px display-inline-flex">Legal Entity Name: </span>\n    <span>');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n  </p>\n  <p class="m-t-20">\n    <span class="font-semi-bold width-300px display-inline-flex">Legal Entity Type: </span>\n    <span>\n      ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push('\n      Sole Proprietorship\n      ');
      } else if (this.businessType === configData.dwolla_customer_business_types["CORP"]) {
        __out.push('\n      Corporation\n      ');
      } else if (this.businessType === configData.dwolla_customer_business_types["LLC"]) {
        __out.push('\n      LLC\n      ');
      } else if (this.businessType === configData.dwolla_customer_business_types["PARTNER"]) {
        __out.push('\n      Partnership\n      ');
      }
    
      __out.push('\n    </span>\n  </p>\n  <p class="m-t-20">\n    <span class="font-semi-bold width-300px display-inline-flex">Legal Entity Address: </span>\n    <span>');
    
      __out.push(__sanitize(this.address));
    
      __out.push('</span>\n  </p>\n  <p class="m-t-20">\n    <span class="font-semi-bold width-300px display-inline-flex">\n      ');
    
      if (this.businessType === configData.dwolla_customer_business_types["SOLE"]) {
        __out.push('\n        Social Security Number:\n      ');
      } else {
        __out.push('\n        Taxpayer Identification Number (TIN):\n      ');
      }
    
      __out.push('\n    </span>\n    <span>');
    
      __out.push(__sanitize(this.taxId));
    
      __out.push('</span>\n  </p>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}