import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDBusinessFeatureQuery, useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import NewServiceMenu from '@src/components/ui_v2/service_sidebar';
import {
  BusinessInboxIcon,
  BusinessInboxTrashIcon,
} from '@src/components/utils/icomoon';

const BusinessInboxSidemenu = () => {
  const business = useBusinessContext();
  const { data: globalNavigationBusinessEnabled } = useLDBusinessFeatureQuery(business.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);
  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.GLOBAL_NAVIGATION);

  const { data: info } = useGetBusinessServicesInfo(business.id);
  const service = useInboxServiceContext();

  const homeURL = `/businesses/${business.id}/business_inbox`;
  const isGlobalNavigationEnabled: boolean = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));
  const isGlobalnavigationNewUi: boolean = isGlobalNavigationEnabled && !info?.isAccountingFirmUser;
  const serviceCode = isGlobalnavigationNewUi ? window.Docyt.Common.Constants.SERVICE_TYPES.VAULT : window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE;

  if (isGlobalnavigationNewUi) {
    return (
      <NewServiceMenu
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ serviceCode }
          />
      ) }
      >
        <NewServiceMenu.Item
          href={ homeURL }
          title="Inbox"
        />
        <NewServiceMenu.Item
          href={ `${homeURL}/trash` }
          title="Trash"
        />
      </NewServiceMenu>
    );
  }

  return (
    <ServiceSidebar
      serviceMenu={ (
        <ServiceMenu
          service={ service }
          serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE }
        />
      ) }
    >
      <ServiceSidebar.Item
        badge={ service.totalInboxCount }
        href={ homeURL }
        icon={ <BusinessInboxIcon /> }
        title="Inbox"
      />
      <ServiceSidebar.Item
        badge={ service.deletedInboxCount }
        href={ `${homeURL}/trash` }
        icon={ <BusinessInboxTrashIcon /> }
        title="Trash"
      />
    </ServiceSidebar>
  );
};

export default BusinessInboxSidemenu;
