/* eslint-disable eqeqeq */
import React from 'react';

import { businessSettingsPath, clientBusinessSettingsPath, clientInfoPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  focusToMyClient: boolean,
  info?: IBusinessServicesInfo,
}

const SettingsItem = ({
  businessId,
  activeItem,
  focusToMyClient,
  info,
}: IServiceMenuProps) => {
  if (focusToMyClient) {
    return (
      <ModuleHeaderMenu
        active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.SETTINGS }
        focusToMyClient={ focusToMyClient }
        title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.SETTINGS }
      >
        {
          info?.accountingFirmBusinessId && (
            <>
              <ServiceItem
                serviceUrl={ clientBusinessSettingsPath(businessId, info.accountingFirmBusinessId) }
                title={ window.Docyt.Common.Constants.SERVICE_NAMES.BUSINESS_SETTINGS }
              />
              <ServiceItem
                serviceUrl={ clientInfoPath(businessId, info.accountingFirmBusinessId) }
                title={ window.Docyt.Common.Constants.SERVICE_NAMES.BUSIENSS_INFO }
              />
            </>
          )
        }
      </ModuleHeaderMenu>
    );
  }

  return (
    <ModuleHeaderMenu
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.SETTINGS }
      url={ businessSettingsPath(businessId) }
    />
  );
};

export default SettingsItem;
