module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex align-items-center p-t-10 p-l-62 p-r-20">\n  <ul class="nav sub-header-menu">\n    <li class="m-r-15 ');
    
      __out.push(__sanitize(this.activeTab === 'detail' ? 'active' : ''));
    
      __out.push(' ');
    
      if (this.purpose !== 'pay') {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('"><a class="pointer header-item" data-id="detail">DETAIL</a></li>\n    <li class="m-r-15 ');
    
      __out.push(__sanitize(this.activeTab === 'data' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="data">DATA</a></li>\n    <li class="m-r-15 ');
    
      __out.push(__sanitize(this.activeTab === 'chat' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="chat">CHAT</a></li>\n    <li class="m-r-15 ');
    
      __out.push(__sanitize(this.activeTab === 'activity' ? 'active' : ''));
    
      __out.push('"><a class="pointer header-item" data-id="activity">ACTIVITY</a></li>\n  </ul>\n</div>\n<div class="invoice-right-side-region main-right-menu-region">\n</div>\n');
    
      if (this.activeTab === 'detail' || this.activeTab === 'data' && this.purpose !== 'pay') {
        __out.push('\n<div class="invoice-right-side-footer">\n    <div class="text-center m-t-20">\n        ');
        if (this.purpose === 'verify') {
          __out.push('\n        <button type="button" class="btn btn-blue mark-as-verified-next-btn">\n            Mark as Verified - Review Next\n        </button>\n        ');
        } else if (this.purpose === 'verify_approve') {
          __out.push('\n        <button type="button" class="btn btn-blue verify-approve-next-btn">\n            Verify and Approve - Review Next\n        </button>\n        ');
        } else if (this.purpose === 'approve') {
          __out.push('\n        <button type="button" class="btn btn-blue approve-next-btn">\n            Approve - Review Next\n        </button>\n        ');
        } else if (this.purpose === 'pay') {
          __out.push('\n        <button type=\'button\' class="btn btn-blue generate-check-next-btn width-300px">Generate Check</button>\n        ');
        }
        __out.push('\n    </div>\n    <div class="text-center m-t-10 p-b-50">\n        <a class="pointer close-modal-link m-r-100">Cancel</a>\n        <a class="pointer skip-and-next-btn">Skip</a>\n    </div>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}