import React from 'react';

import { CorporateCardsIcon, BankAccountIcon } from '@src/components/utils/icomoon';

interface ICardIconProps {
  accountType: string | undefined,
  isArchived: boolean | undefined,
  size?: number
}

const CardIcon = ({
  accountType, isArchived = false, size = 50,
}: ICardIconProps): JSX.Element => {
  const disabled: string = isArchived ? '-disabled' : '';
  const type:string = accountType === 'bank' ? `icon-bank-account${disabled}` : `icon-credit-card-account${disabled}`;

  return (accountType === 'bank') ? (
    <BankAccountIcon className={ type } fontSize={ size } variant="normal" />
  ) : (
    <CorporateCardsIcon className={ type } fontSize={ size } />
  );
};

export default CardIcon;
