import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const BankTransactionIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="bank-transactions-src"
      pathsNumber={ 7 }
    />
  );
};

export default BankTransactionIcon;
