import React from 'react';

import DetailsPageTitle from '@src/components/ui/details_page_title';

import AddMetric from './metrics_action/add_metric_action';

interface IMetricsServiceHeaderProps {
  isIndustryMetrics?: boolean,
}

const MetricsServiceHeader = ({
  isIndustryMetrics,
}: IMetricsServiceHeaderProps): JSX.Element => {
  return (
    <DetailsPageTitle title={ isIndustryMetrics ? 'Industry Metrics' : 'Labor Metrics' }>
      <div hidden>
        <AddMetric />
      </div>
    </DetailsPageTitle>
  );
};

export default React.memo(MetricsServiceHeader);
