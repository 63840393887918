import React from 'react';

import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { MultiBusinessEditIcon } from '@src/components/utils/icomoon';

import { useBusinessModal } from '../modals/edit_business';

import styles from '../styles.module.scss';

interface IEditBusinessActionProps {
  action: string,
  businesses: IBusiness[],
  item: IMultiBusinessReportDetail,
  setAction: React.Dispatch<React.SetStateAction<string>>,
}

const EditBusinessAction = ({ action, businesses, item, setAction }: IEditBusinessActionProps) => {
  const modal = useBusinessModal();

  return (
    <>
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <MultiBusinessEditIcon fontSize={ 18 } /> }
        title="Edit Businesses"
        onSelect={ () => {
          modal.open();
          setAction('edit');
        } }
      />
      <modal.Component
        { ...modal.props }
        action={ action }
        businesses={ businesses }
        item={ item }
      />
    </>
  );
};

export default React.memo(EditBusinessAction);
