import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IAngleDownIconProps extends Omit<IFaIconProps, 'icon'> {}

const AngleDownIcon = (props: IAngleDownIconProps): JSX.Element => {
  return <FaIcon icon="angle-down" { ...props } />;
};

export default AngleDownIcon;
