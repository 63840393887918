module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="rules-main-region" class="rules-main-region">\n  <div class="width-80px-important text-right pull-right relative">\n    <p></p>\n  </div>\n  <h2 class="font-26 font-bold m-b-0 m-t-0">Docyt AI configurations</h2>\n  <hr class="m-t-13">\n  <div class="clearfix m-b-30">\n    <div class="row m-b-5">\n      <div class="form-inline">\n        <div class="form-group">\n          <p class="inline-block font-16">Books Lock Date</p>\n          <div class=\'icon icon-unverified inline-block m-l-10 font-16 closing-date-tooltip-js\' data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.closing_date_tooltip));
    
      __out.push('">\n            <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n          </div>\n          <span class="m-l-30">');
    
      __out.push(__sanitize(this.formattedStartDate));
    
      __out.push('</span>\n        </div>\n        <a class="pull-right pointer closing-date">Edit</a>\n      </div>\n    </div>\n\n    <div class="row m-b-5">\n      <div class="form-inline">\n        <div class="form-group">\n          <div class="form-label inline-block width-180px">\n            <p class="inline-block font-16">Auto Pilot</p>\n            <div class=\'icon icon-unverified inline-block m-l-10 font-16 verification-tooltip-js\' data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.verification_tooltip));
    
      __out.push('">\n              <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n            </div>\n          </div>\n          <select id="auto-verification" class="form-control inline-block select-verification-js ');
    
      __out.push(__sanitize(this.autoVerification ? 'on' : 'off'));
    
      __out.push('" placeholder="Type">\n            <option value="off" ');
    
      if (!this.autoVerification) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>OFF</option>\n            <option value="on" ');
    
      if (this.autoVerification) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>ON</option>\n          </select>\n        </div>\n      </div>\n    </div>\n  </div>\n  <hr class="m-t-13">\n  <div class="clearfix m-b-30">\n    <div class="row m-b-5 m-t-7">\n      <div class="form-inline">\n        <div class="form-group">\n          <label class="font-16"><b>Docyt AI AutoPilot settings</b></label>\n          <div class=\'icon icon-unverified inline-block m-l-10 font-16 custom-rules-tooltip-js\' data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.custom_rules_tooltip));
    
      __out.push('">\n            <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n          </div>\n        </div>\n        <a class="pull-right pointer add-custom-rule">Add</a>\n      </div>\n    </div>\n    <div id="custom-rules-list-region" class=""></div>\n  </div>\n</div>\n<div id="custom-rules-right-side-region" class="hidden"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}