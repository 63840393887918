import React from 'react';

import { isArray } from 'lodash';
import moment from 'moment';

import { IReportRefreshLogData, TReportRefreshLogSortColumns } from '@src/types/report_service/report_refresh_logs';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

import Filter from './filter';

interface IReportRefreshLogTableProps {
  records: IReportRefreshLogData[],
  sorting: ISorting<TReportRefreshLogSortColumns>,
}

const RefreshLogsTable = ({
  records,
  sorting,
}: IReportRefreshLogTableProps) => {
  const renderMonthRange = (range: string[] | string) => {
    if(isArray(range)){
      const monthYears = range.map((item: string) => moment(item).format('MMM YYYY'));
      return <span>{monthYears.join(' ; ')}</span>;
    }
    return <span>{range}</span>
  };

  const getDateColumnValue = (dateTime: string) => {
    if (!dateTime) return '-';
    return moment(dateTime).format('MMM Do, YYYY, hh:mm A');
  };

  const renderStatus = (status: string) => {
    switch (status) {
      case 'completed':
        return <span className="completed-text">Completed</span>;
      case 'waiting':
        return <span className="waiting-text">Waiting</span>;
      case 'failed':
        return <span className="failed-text">Failed</span>;
      case 'running':
        return <span className="in-progress-text">In Progress</span>;
      case 'nochange':
        return <span className="no-change-text">No Change</span>;
      default:
        return <span />;
    }
  };

  return (
    <CollectionTable<
      IReportRefreshLogData,
      TReportRefreshLogSortColumns
    >
      filter={ <Filter /> }
      idField="jobId"
      noDataMessage="No reports found"
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<IReportRefreshLogData>
        hideValueTooltip
        name="jobId"
        title="Job ID"
        value={ (refreshLog) => refreshLog.jobId }
        width="20%"
      />
      <CollectionTable.TextColumn<IReportRefreshLogData, TReportRefreshLogSortColumns>
        name="job_start_time"
        sortColumn="jobStartTime"
        title="Start Date"
        value={ (refreshLog) => getDateColumnValue(refreshLog.jobStartTime) }
        width="11%"
      />
      <CollectionTable.TextColumn<IReportRefreshLogData, TReportRefreshLogSortColumns>
        name="jobEndTime"
        sortColumn="jobEndTime"
        title="End Date"
        value={ (refreshLog) => getDateColumnValue(refreshLog.jobEndTime) }
        width="11%"
      />
      <CollectionTable.TextColumn<IReportRefreshLogData, TReportRefreshLogSortColumns>
        hideValueTooltip
        name="status"
        sortColumn="status"
        title="Status"
        value={ (refreshLog) => renderStatus(refreshLog.status) }
        width="11%"
      />
      <CollectionTable.TextColumn<IReportRefreshLogData, TReportRefreshLogSortColumns>
        hideValueTooltip
        name="jobTypeTitle"
        sortColumn="jobTypeTitle"
        title="Type"
        value={ (refreshLog) => refreshLog.jobTypeTitle }
        width="16%"
      />
      <CollectionTable.TextColumn<IReportRefreshLogData>
        name="periods"
        title="Periods"
        value={ (refreshLog) => renderMonthRange(refreshLog.monthsInRange) }
        width="11%"
      />
    </CollectionTable>
  );
};

export default React.memo(RefreshLogsTable);
