import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { TAmountFilter, TFilterData } from '@src/types/filter';

import Dropdown from '@src/components/ui/dropdown';
import AmountInput from '@src/components/ui/form/inputs/amount_input';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Wrapper from './filter_field_wrapper';
import { useFilterField } from './hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from './utils';

interface AmountFilterFieldProps<TFilter extends TFilterData> extends IFilterFieldUIProps {
  name: keyof TFilter,
}

type TFilterType = 'amount' | 'range';

/**
 * Filter field to amount values
 */
const AmountFilterField = <TFilter extends TFilterData>({
  name,
  ...props
}: AmountFilterFieldProps<TFilter>) => {
  const { update, value } = useFilterField<TFilter, TAmountFilter>(name);

  const [type, setType] = useState<TFilterType>(() => {
    if (value?.val) return 'amount';

    if (value?.gte || value?.lte) return 'range';

    return 'amount';
  });

  const handleMinChange = useCallback((minValue) => {
    update({
      gte: minValue,
      lte: value?.lte,
    });
  }, [value, update]);

  const handleMaxChange = useCallback((maxValue) => {
    update({
      gte: value?.gte,
      lte: maxValue,
    });
  }, [value, update]);

  const handleValChange = useCallback((val) => {
    update({ val });
  }, [update]);

  const handleAmountSelect = useCallback(() => {
    setType('amount');
  }, [setType]);

  const handleRangeSelect = useCallback(() => {
    setType('range');
  }, [setType]);

  const [wrapperProps] = useFilterFieldProps(props, 'amount-field');

  const rangeGteFilterClass = classNames(
    'range-filter',
    { filtered: value?.gte },
  );
  const rangeLteFilterClass = classNames(
    'range-filter',
    { filtered: value?.lte },
  );
  const filterClass = classNames(
    'range-filter',
    { filtered: value?.val },
  );

  return (
    <Wrapper { ...wrapperProps }>
      <div className="input-group">
        {
          type === 'range' && (
            <>
              <AmountInput
                autoComplete="off"
                className={ rangeGteFilterClass }
                placeholder="$ Min"
                value={ value?.gte || '' }
                onChange={ handleMinChange }
              />
              <span className="input-group-addon range-filter">-</span>
              <AmountInput
                autoComplete="off"
                className={ rangeLteFilterClass }
                placeholder="$ Max"
                value={ value?.lte || '' }
                onChange={ handleMaxChange }
              />
            </>
          )
        }
        {
          type === 'amount' && (
            <AmountInput
              autoComplete="off"
              className={ filterClass }
              placeholder="$ Amount"
              value={ value?.val || '' }
              onChange={ handleValChange }
            />
          )
        }
        <Dropdown.LinkToggle noButton className="amount-select-menu-btn">
          <CaretIcon fontSize={ 14 } variant="down" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu pointer alignment="right" className="col-xs-12" fontSize={ 24 }>
          <Dropdown.Item onSelect={ handleAmountSelect }>Filter by Exact Amount</Dropdown.Item>
          <Dropdown.Item onSelect={ handleRangeSelect }>Filter by Range</Dropdown.Item>
        </Dropdown.Menu>
      </div>
    </Wrapper>
  );
};

export default AmountFilterField;
