import React from 'react';

import classNames from 'classnames';

import Dropdown, { useDropdown } from '@src/components/ui_v2/dropdown';

import ClearIndicator from '../clear_indicator';

import styles from '../styles.module.scss';

interface IDropdownInnerInputProps {
  placeholder?: string,
  value: string[],
  onClear: () => void,
  isDisabled?: boolean,
  showSelected?: boolean,
}

const Input = ({
  placeholder,
  value,
  onClear,
  isDisabled,
  showSelected
}: IDropdownInnerInputProps) => {
  const {
    isVisible,
    showOnClickAttributes,
    showWithKeyboardAttributes,
  } = useDropdown() || {};

  const selected = value.length > 0;

  const inputClasses = classNames(
    selected ? styles['dropdown-input-value'] : styles['dropdown-input-placeholder'],
    isDisabled && styles['disabled-dropdown']
  );

  const selectedValue = value.length === 1 && showSelected ? value[0] : `${value.length} Selected`;
  const placeholderValue = selected ? selectedValue : placeholder;

  return (
    <>
      <div
        aria-expanded={ isVisible ? 'true' : 'false' }
        aria-haspopup="true"
        className={ inputClasses }
        role="button"
        tabIndex={ 0 }
        { ...showOnClickAttributes }
        { ...showWithKeyboardAttributes }
      >
        { placeholderValue }
      </div>
      {!isDisabled &&
        ( selected ? (
          <ClearIndicator onClick={ onClear } />
        ) : (
          <Dropdown.InputIndicator tabIndex={ -1 } />
        )
      )}
    </>

  );
};

export default Input;
