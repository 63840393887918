module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="step-line ');
    
      __out.push(__sanitize(this.is_active_step ? 'active' : this.step_passed ? 'step-passed' : 'not-passed'));
    
      __out.push('">\n  ');
    
      if (this.qbo_connected) {
        __out.push('\n    <span class="icon icon-success">\n      <span class="path1"></span><span class="path2"></span>\n    </span>\n  ');
      } else {
        __out.push('\n    <span class="icon icon-warning in-red-400"></span>\n  ');
      }
    
      __out.push('\n</div>\n<div class="setup-business-detail-content">\n  ');
    
      if (this.is_active_step) {
        __out.push('\n    <h3 class="panel-sub-title">Connect to QuickBooks</h3>\n    <p class="panel-sub-header">If you have an Intuit QuickBooks Online(QBO) subscription, then Docyt can sync all data, and your chart of accounts with it.\n      Simply connect by clicking the button below, and sign in using your QuickBooks credentials.\n    </p>\n    <div class="m-t-30 p-b-20">\n      <button class="btn btn-green font-16 btn-connect-qbo" type="button">\n        <i class="font-16 icon icon-quickbooks-logo m-r-5 vertical-align-middle"><span class="path1"></span><span class="path2"></span></i>\n        Connect to QuickBooks\n      </button>\n      <a class="pointer font-16 m-l-20 skip-qbo-connect-link">Skip</a>\n    </div>\n  ');
      } else {
        __out.push('\n    <span class="step-pre-header">Connect to QuickBooks</span>\n  ');
      }
    
      __out.push('\n  ');
    
      if (!this.qbo_connected && this.step_passed) {
        __out.push('\n    <div class="pull-right">\n      <a class="pointer font-semi-bold activate-step">Connect</a>\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}