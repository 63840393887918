import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TID } from '@src/types/common';
import {
  IMetric,
  TMetricSortColumn,
} from '@src/types/metrics';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './item';

interface IIndustryMetricsListProps {
  businessId: TID,
  industryMetrics: IMetric[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TMetricSortColumn>,
}

const BusinessInboxList = ({
  businessId,
  industryMetrics,
  query,
  sorting,
}: IIndustryMetricsListProps): JSX.Element => {
  return (
    <Table
      infiniteQuery={ query }
      wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell<TMetricSortColumn>
            columnName="name"
            sorting={ sorting }
            widthPercent={ 40 }
          >
            Metrics Name
          </Table.HCell>
          <Table.HCell<TMetricSortColumn>
            columnName="last_updated_at"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Last Update
          </Table.HCell>
          <Table.HCell<TMetricSortColumn>
            columnName="start_date"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Start Date
          </Table.HCell>
          <Table.HCell<TMetricSortColumn>
            columnName="frequency"
            sorting={ sorting }
            widthPercent={ 20 }
          >
            Frequency
          </Table.HCell>
          <Table.HCell<TMetricSortColumn>
            hidden
            columnName="missed_entries"
            sorting={ sorting }
            widthPercent={ 15 }
          >
            Missed Entries
          </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          industryMetrics.map((metric) => {
            return (
              <Item
                key={ metric.id }
                businessId={ businessId }
                metric={ metric }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default BusinessInboxList;
