import React from 'react';

import { TID, TMongoID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import DashboardPage from './dashboard/dashboard_page';

interface IBusinessDashboardProps {
  businessId: TID,
  dashboardId: TMongoID,
  widgetId: TMongoID,
}

const BusinessDashboardsPage = ({
  businessId,
  dashboardId,
  widgetId,
}: IBusinessDashboardProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <DashboardPage
          businessId={ businessId }
          dashboardId={ dashboardId }
          widgetId={ widgetId }
        />
      </BusinessProvider>
    </Root>
  );
};

export default BusinessDashboardsPage;
