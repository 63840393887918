import React, { ReactNode } from 'react';

interface IFullpageFooterHeaderProp {
    children?: ReactNode;
  }

const FullPageFooterHeader = ({ children }: IFullpageFooterHeaderProp): JSX.Element | null => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default FullPageFooterHeader;
