import React from 'react';

interface IModalFooterProps {
  children: React.ReactNode,
  className?: string,
}

const ModalFooter = ({
  children,
  className = 'modal-footer',
}: IModalFooterProps): JSX.Element => {
  return (
    <div className={ className }>
      { children }
    </div>
  );
};

export default ModalFooter;
