module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="authorized-payer-container">\n    <div class="authorized-payer-wrapper authorized-signatory-wrapper">\n        ');
    
      if (this.is_ready) {
        __out.push('\n        <p class="m-b-10 font-16 font-semi-bold in-green-1000">E-signature successfully added.</p>\n        <p class="font-16">');
        __out.push(__sanitize(this.signatory));
        __out.push(' has been added as authorized signatory.</p>\n        <hr class="m-t-30" />\n        <div class="display-flex justify-content-between align-items-center m-t-30">\n            <div class="middle-avatar-wrapper">\n                ');
        if (this.signatory_avatar) {
          __out.push('\n                <img src="');
          __out.push(__sanitize(this.signatory_avatar));
          __out.push('">\n                ');
        } else {
          __out.push('\n                <i class="icon icon-smile in-blue-400 font-50"></i>\n                ');
        }
        __out.push('\n            </div>\n            <span class="width-40-percent font-14 font-semi-bold">');
        __out.push(__sanitize(this.signatory));
        __out.push('</span>\n            <span class="width-50-percent in-grey-1050">Authorized Signatory</span>\n        </div>\n        ');
      } else {
        __out.push('\n        ');
        if (this.has_own_signature) {
          __out.push('\n        <div class="reuse-signature-panel">\n            <div class="relative m-r-5 m-l-5">\n                <canvas width="110" height="80" class="img-digital-signature m-r-20"></canvas>\n                <div class="page-spin-loader">\n                    <i class="fa fa-spin fa-spinner font-38"></i>\n                </div>\n            </div>\n            <p class="m-r-20">Signature already exists for <b>');
          __out.push(__sanitize(this.signatory));
          __out.push('</b>.<br>You can reuse your signature or scan the QR code below to add a new signature.</p>\n            <button class="btn btn-blue font-16 font-semi-bold m-r-20 reuse-signature-btn">Reuse Signature</button>\n            <button class="font-24 close">&times;</button>\n        </div>\n        ');
        }
        __out.push('\n        <div class="digital-signature-panel">\n            <img class="digital-signature-qr-code width-100-percent" src="">\n        </div>\n        <div class="text-center">\n            <p class="font-14 m-t-20">Scan QR code to add signature that will be used on bank checks.<br>\n                This signature should be identical to the one on file with your bank.\n            </p>\n            <div class="text-center">\n                <div class="display-inline-block text-left">\n                    <p class="m-t-20">Step 1: Scan QR code with your phone and follow the link.</p>\n                    <p class="m-t-10">Step 2: Add digital signature on the drawpad that shows up.</p>\n                    <p class="m-t-10">Step 3: Tap the Save button.</p>\n                    <p class="m-t-10">Step 4: <a class="font-semi-bold pointer refresh-page-js">Click here to Refresh.</a></p>\n                </div>\n            </div>\n        </div>\n        ');
      }
    
      __out.push('\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}