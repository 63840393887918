import React, { useCallback, useMemo, useState } from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessMetricsValue } from '@src/hooks/queries/business_metric_value';
import { useURLParams } from '@src/hooks/url_params';
import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { ISortingParams } from '@src/types/sorting';
import { apiMonthToDate, endOfMonthApiDate, apiDateToMonth, getUTCTimezone, formatNumber } from '@src/utils/date_helpers';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import Body from './business_metric_value_body';
import { IBusinessMetricValuesFilter } from './filter';
import Header from './header';
import { useBusinessMetricContext } from '../business_metric_context';

const DefaultSorting: ISortingParams<TBusinessMetricValueSortColumn> = {
  orderColumn:    'date',
  orderDirection: 'asc',
};

const BusinessMetricValuesDetails = (): JSX.Element => {
  const business = useBusinessContext();
  const businessMetric = useBusinessMetricContext();

  const [selectedMonthStr, setSelectedMonthStr] = useState<string>(
    apiDateToMonth(new Date().toISOString()) || new Date().toISOString(),
  );

  const { filter, sorting } = useURLParams<
    IBusinessMetricValuesFilter,
    TBusinessMetricValueSortColumn
  >({
    businessId:     business.id,
    section:        window.Docyt.Common.Constants.METRIC_TYPES.BUSINESS_METRIC_VALUE,
    defaultSorting: DefaultSorting,
  });

  const filterParams = useMemo(() => {
    return isEmpty(filter.data) ? undefined : filter.data;
  }, [filter.data]);

  const businessMetricValuesQuery = useGetBusinessMetricsValue({
    businessMetricId: businessMetric.id,
    from:             apiMonthToDate(selectedMonthStr),
    to:               endOfMonthApiDate(getUTCTimezone(new Date(selectedMonthStr))),
    filters:          filterParams,
  });

  const valuePages = useMemo(() => {
    return businessMetricValuesQuery.data?.pages || [];
  }, [businessMetricValuesQuery]);

  const getCorrectedData = useCallback((monthValues: IBusinessMetricValue[]) => {
    const lastDay = moment(monthValues[0].date).endOf('month').format('D');
    const currentMonth = moment(monthValues[0].date).month() + 1; // month start from 0 index
    const currentYear = moment(monthValues[0].date).year();
    if (monthValues.length !== Number(lastDay)) {
      for (let i = 0; i < Number(lastDay); i += 1) {
        const dayNumber = i + 1;
        const currentDate = monthValues[i]?.date ? moment(monthValues[i].date).format('D') : 0;
        if (Number(currentDate) !== dayNumber) {
          const data = { date: `${currentYear}-${formatNumber(currentMonth)}-${formatNumber(dayNumber)}`, value: 0 };
          monthValues.splice(i, 0, data);
        }
      }
    }
    return monthValues;
  }, []);

  const businessMetricValues = useMemo(() => {
    const monthValues = flatten(valuePages.map((p) => p.collection));
    if (monthValues.length > 0) {
      return getCorrectedData(monthValues);
    }
    return monthValues;
  }, [valuePages, getCorrectedData]);

  const businessMetricValueDates = useMemo(() => {
    return flatten(businessMetricValues).map((d) => d.date);
  }, [businessMetricValues]);

  const hasValues = useMemo(() => {
    if (valuePages.length === 0) return false;

    return valuePages[0].meta.totalCount > 0;
  }, [valuePages]);

  const handleDateChanged = useCallback((value) => {
    setSelectedMonthStr(value);
    sorting.clear();
  }, [sorting]);

  return (
    <ItemsSelectorProvider allItems={ businessMetricValueDates }>
      {
        businessMetric && (
          <>
            <Header
              businessId={ business.id }
              businessMetric={ businessMetric }
              selectedMonthStr={ selectedMonthStr }
              onDateChanged={ handleDateChanged }
            />
            <Body
              businessId={ business.id }
              businessMetric={ businessMetric }
              businessMetricValues={ businessMetricValues }
              hasValues={ hasValues }
              query={ businessMetricValuesQuery }
              selectedMonthStr={ selectedMonthStr }
              sorting={ sorting }
            />
          </>
        )
      }
    </ItemsSelectorProvider>
  );
};

export default BusinessMetricValuesDetails;
