import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getChatThreadById, getListOfThreads, getMessageHistory, createSessionToken } from '@src/requests/ai_chat/ai_chatbot';
import { TUUID } from '@src/types/common';

import {
  IMessageSchema,
  IThreadListResponse,
  ICreateSessionTokenResponse,
  IThreadListParams,
  IGlobalThreadListResponse,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse,
} from '@src/components/ai_chatbot/types';

import { createUseGetInfiniteCollectionBasedOnLastTimestamp } from '../infinite_collection_queries';

const useGetListOfThreads = createUseGetInfiniteCollectionBasedOnLastTimestamp<
  IThreadListResponse,
  IThreadListParams,
  IGlobalThreadListResponse
>({
  queryKey: QueryKey.AiChatThreads,
  request:  getListOfThreads,
});

const useGetThreadById = (chatThreadId: TUUID) => {
  return useQuery<IThreadListResponse, Error>(
    [QueryKey.AiChatThreadById, chatThreadId],
    () => getChatThreadById(chatThreadId),
    {
      enabled: !!chatThreadId,
    },
  );
};

const useGetMessageHistory = createUseGetInfiniteCollectionBasedOnLastTimestamp<
  IMessageSchema,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse
>({
  queryKey: QueryKey.AIChatThreadMessages,
  request:  getMessageHistory,
});

const useCreateSessionToken = () => {
  return useQuery<ICreateSessionTokenResponse, Error>({
    queryFn: () => createSessionToken(),
  });
};

export {
  useGetListOfThreads,
  useGetMessageHistory,
  useGetThreadById,
  useCreateSessionToken,
  IMessageHistoryParams,
  IGlobalMessageHistoryResponse,
  IThreadListParams,
  IGlobalThreadListResponse,
};
