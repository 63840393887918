import { useState, useEffect } from 'react';

import { TID } from '@src/types/common';
import {
  LDFeatureForBusinessEnabled,
  LDFeatureValueForBusiness,
} from '@src/utils/config';

const useBusinessFeatureFlag = (featureFlag: string, businessId: TID) => {
  const businessIdStr = String(businessId);
  const [flagEnabled, setFlagEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(featureFlag, businessIdStr),
  );

  useEffect(() => {
    if (flagEnabled === undefined) {
      LDFeatureForBusinessEnabled(featureFlag, businessIdStr).then((res) => {
        setFlagEnabled(res);
      });
    }
  }, [flagEnabled, featureFlag, businessIdStr]);

  return flagEnabled;
};

export default useBusinessFeatureFlag;
