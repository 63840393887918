export const FAQ = [
  {
    summary: 'Approval needed receipts',
    message: 'Approval needed receipts',
  },
  {
    summary: 'Bills pending approval',
    message: 'Bills pending approval',
  },
  {
    summary: 'What is Docyt Check?',
    message: 'What is Docyt Check?',
  },
  {
    summary: 'Show me unverified transactions for this month',
    message: 'Show me unverified transactions for this month',
  },
];



export const fakeResponseMarkDown = [
  `7 bills need your approval across your businesses.

[Click here to view](https://docyt.com/) the bills.`,
  `
There are 5 transactions across 2 businesses you made with Uber on 2nd January 2024.

## Sample heading

**Hampton Inn SF**

- $4.87 | Uber-receipts3555paidonline432 $5.22
- Uber-receipts4342paidonline433 $6.20
- Uber-receipts9822paidonline434

**Holiday Residency SF**

- $3.45 | Uber-receipts3982paidonline342 $5.39
- Uber-receipts0927paidonline343

[Click here to view](https://www.docyt.com/) the transactions.
`,
  `7 out of 12 utility bills from all of your businesses have been paid.

  - Hampton Inn SF: 3 paid, 2 ready to pay
  - Holiday Inn: 1 needs approval, 2 paid
  - Ramada SF: 1 unverified, 1 needs approval,  and 1 paid
  - Radisson SF: 1 paid

 [Click here to view](https://www.docyt.com/) a list of bills and their payment statuses under the chart of account “Expenses ▸ Utilities”.`,
];
