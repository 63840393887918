module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, index, len, ref;
    
      __out.push('<div class="multi-report-item-container font-16">\n  <div class="p-l-30 flex-container line-height-60 text-center pointer item-js">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</div>\n  <div class="line-height-30 display-flex text-center pointer business-item-avatars business-items">\n    ');
    
      ref = this.businesses;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        business = ref[index];
        __out.push('\n      ');
        if (index < 5) {
          __out.push('\n        ');
          if (business.avatar) {
            __out.push('\n          <div class="middle-avatar-wrapper border-gray business-item-avatar multi-report-business-item-avatar">\n            <img src="');
            __out.push(__sanitize(business.avatar.avatar_url));
            __out.push('">\n          </div>\n        ');
          } else {
            __out.push('\n          <span class=\'icon-business-default-avatar font-30 business-item-avatar\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.businesses.length > 5) {
        __out.push('\n      <span class=\'icon-business-default-avatar business-item-avatar text-center display-flex font-30\'><span class=\'path1\'></span><span class="count">+');
        __out.push(__sanitize(this.businesses.length - 5));
        __out.push('</span></span>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="font-12 m-r-200 multi-entity-update-label">Updated ');
    
      __out.push(__sanitize(this.last_updated_date));
    
      __out.push('</div>\n  <div class="relative">\n    <a class="btn dropdown-toggle" type="button" id="multi-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-ellipsis-h font-24 in-grey-800"></span>\n    </a>\n    <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="multi-report-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n      <li>\n        <a tabindex="-1" class="multi-entity-action rename-btn"><i class="icon icon-edit m-r-12"></i> <span>Rename Report</span></a>\n      </li>\n      <li>\n        <a tabindex="-1" class="multi-entity-action edit-btn"><i class="icon icon-multi-business-edit m-r-12"></i> <span>Edit Businesses</span></a>\n      </li>\n      <li>\n        <a tabindex="-1" class="multi-entity-action update-btn"><i class="icon icon-update-report m-r-12"></i> <span>Update Report</span></a>\n      </li>\n      <li>\n        <a tabindex="-1" class="multi-entity-action destroy-btn in-red-600"><i class="icon icon-trashcan m-r-12"></i> <span>Delete Report</span></a>\n      </li>\n    </ul>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}