import React, { useState, useMemo } from 'react';

import { TCDContext } from '@src/hooks/contexts/transation_context_data_context';
import { TransactionContextData } from '@src/types/bank_account_reconciliations';

import ViewTransactionContext from './view_transaction_context';

const AskDocytAI: React.FC<{ transactionContextData: TransactionContextData }> =
    ({ transactionContextData }) => {
      const aiContext = sessionStorage.getItem(`vtc_${transactionContextData.id}_ac`) || '';
      const clientContext = sessionStorage.getItem(`vtc_${transactionContextData.id}_cc`) || '';
      transactionContextData.aiContext = transactionContextData.aiContext || aiContext;
      transactionContextData.clientContext = transactionContextData.clientContext || clientContext;
      const [data, setData] = useState<TransactionContextData>(transactionContextData);

      const contextValue = useMemo(() => ({
        data,
        setData,
      }), [data, setData]);

      return (
        <TCDContext.Provider value={ contextValue }>
          <ViewTransactionContext />
        </TCDContext.Provider>
      );
    };

export default AskDocytAI;
