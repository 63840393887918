import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetQuickbooksPaymentAccounts } from '@src/hooks/queries/quickbooks_payment_accounts';
import { useUpdateReconciliationPaymentAccount } from '@src/hooks/queries/reconciliation_payment_accounts';
import { IQuickbooksPaymentAccounts } from '@src/types/quickbooks_payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { formatDate } from '@src/utils/date_helpers';

import { ErrorNotification } from '@src/components/ui/notification';
import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { PlusIcon, QBOOnIcon } from '@src/components/utils/icomoon';

import { useAddChartOfAccount } from './add_chart_of_account';
import { IQBOData } from '../schema';

import styles from '../styles.module.scss';


interface ISelectAccountSelectorProps {
  account: IReconciliationPaymentAccount,
  onDone: () => void,
  onCancel: () => void,
}

const SelectAccountSelector = ({ account, onDone, onCancel }: ISelectAccountSelectorProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IQBOData | undefined>({
    id: account.qboId as string,
    name: account.qboName as string,
  });
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const business = useBusinessContext();
  const addChartOfAccount = useAddChartOfAccount();
  const update = useUpdateReconciliationPaymentAccount();
  const { mutate } = update;
  const query = useGetQuickbooksPaymentAccounts({
    businessId: business.id,
    ...(account.qboId ? { paymentAccountId: account.defaultPaymentAccountId } : {}),
  });
  const quickbooksAccounts = useMemo(() => query.data?.accounts || [], [query]);

  const forceAccountType = useMemo(() => {
    if (account.accountType === window.Docyt.Common.Constants.ACCOUNT_TYPES.BANK_ACCOUNT_TYPE) {
      return window.configData.account_types.big_types.BANK.code;
    }

    return window.configData.account_types.big_types.CREDIT_CARD.code;
  }, [account]);

  const handleClick = useCallback((acc: IQuickbooksPaymentAccounts) => {
    setSelectedAccount(acc);
  }, [setSelectedAccount]);

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, [setErrorMessage]);

  const handleDone = useCallback(() => {
    if (!selectedAccount) {
      setShowWarningMessage(true);
      return;
    }

    setShowWarningMessage(false);
    mutate(
      {
        accountId: account.id,
        businessId: business.id,
        data: {
          name: account.name,
          qboId: selectedAccount.id,
          qboName: selectedAccount.name,
          startImportDate: formatDate(account.startImportDate, 'YYYY/MM/DD'),
          startBalance: account.startBalance,
          transactionImportType: account.transactionImportType,
          last4: account.financialInstitutionBankAccount
            ? account.financialInstitutionBankAccount.last4
            : account.last4,
          ...(account.financialInstitutionBankAccount
            ? { financialInstitutionBankAccountId: account.financialInstitutionBankAccount.id }
            : {}),
        },
      },
      {
        onSuccess: onDone,
        onError: handleError,
      }
    );
  }, [selectedAccount, setShowWarningMessage, onDone, mutate, handleError, account, business.id]);

  return (
    <SideView.Standard
      isOpen
      hideActions={ query.isError }
      title="Select Account"
      onCancel={ onCancel }
      onDone={ handleDone }
    >
      { () => (
        <div className={ styles['banking-accounts-category-list'] }>
          <addChartOfAccount.Component
            forceAccountType={ forceAccountType }
            { ...addChartOfAccount.props }
          />
          { query.isLoading && <Spinner /> }
          { query.isError && <ErrorNotification message="Could not complete this request. Your QuickBooks is not connected." /> }
          { showWarningMessage && <ErrorNotification message="Please Select Chart of Account" title="Warning!" /> }
          { errorMessage && <ErrorNotification message={ errorMessage } title="Account could not be updated." /> }
          <ul>
            <li className={ styles['chart-of-account-item'] } data-id="" data-name="">
              <a
                role="button"
                tabIndex={ -1 }
                onClick={ addChartOfAccount.open }
              >
                <PlusIcon fontSize={ 20 } variant="o" />
                <span>Link As New Account</span>
              </a>
            </li>
            {
              quickbooksAccounts.map((quickbooksAccount) => {
                const selectionClasses = classNames(
                  'selection-status',
                  { selected: selectedAccount?.id === quickbooksAccount.id },
                );

                return (
                  <li
                    key={ quickbooksAccount.id }
                    className={ styles['chart-of-account-item'] }
                    onClick={ () => handleClick(quickbooksAccount) }
                  >
                    <div className={ selectionClasses } />
                    <QBOOnIcon fontSize={ 20 } />
                    <span className="account-name">{ quickbooksAccount.name }</span>
                  </li>
                );
              })
            }
          </ul>
        </div>
      ) }
    </SideView.Standard>
  );
};

export default React.memo(SelectAccountSelector);
