import React, { useCallback } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';
import { TSubOption } from '@src/components/ui_v2/inputs/types';

import BusinessReportsMultiSelectInput from './input';
import BusinessReportsMultiSelectItem from './item';

import styles from '@src/components/ui_v2/inputs/styles.module.scss';

interface IBusinessReportsMultiSelectProps {
  options:                TSubOption[],
  value:                  string[],
  placeholder?:           string,
  budgetsSelectValue?:    string[],
  forecastSelectValue?:   string[],
  kind?:                  string,
  onChange:               (value: string[]) => void,
  onBudgetsSelectChange?: (value: string[]) => void,
  onForecastSelectChange?: (value: string[]) => void
}

const BusinessReportsMultiSelect = ({
  options,
  value,
  placeholder = "Select",
  budgetsSelectValue,
  forecastSelectValue,
  kind,
  onChange,
  onBudgetsSelectChange,
  onForecastSelectChange,
  ...props
}: IBusinessReportsMultiSelectProps) => {
  const containerClasses = classNames(styles['dropdown-input']);

  const handleSelect = useCallback((checked: boolean, optionValue: string, itemKind?: string) => {
    if (itemKind === 'budgets_comparison') {
      const newValue = checked ? [optionValue] : [];
      if (onBudgetsSelectChange) onBudgetsSelectChange(newValue);
    } else if (itemKind === 'forecast_comparison') {
      const newValue = checked ? [optionValue] : [];
      if (onForecastSelectChange) onForecastSelectChange(newValue);
    } else {
      const newValue = checked ? [...value, optionValue] : value.filter((val) => val !== optionValue);
      onChange(newValue);
    }
  }, [value, onChange, onBudgetsSelectChange, onForecastSelectChange]);

  const handleSubMenuSelect = useCallback((checked: boolean, option: TSubOption) => {
    const filterVal = option?.children ? option.children?.map((val) => val.value) : [];
    let newValue: string[] = [];
    if(checked){
      newValue = [...value, ...filterVal]
    } else {
      const temp = [...value];
      filterVal.forEach((val) => {
        const index = temp.indexOf(val);
        if(index !== -1){
          temp.splice(index, 1);
        }
      })
      newValue = [...temp];
    }
    onChange(newValue);
  }, [value, onChange]);

  const selectedValue = kind === 'reportDetail' || kind === 'reportOtherDetail' ? [] : value

  return (
    <Dropdown.Provider className={ containerClasses }>
      <BusinessReportsMultiSelectInput
        placeholder={ placeholder }
        { ...props }
        value={ selectedValue }
      />
      <Dropdown.Menu>
        {options.map((option: TSubOption) => (
          <BusinessReportsMultiSelectItem
            key={ option.value }
            budgetsSelectValue={ budgetsSelectValue }
            childrenKind={ option.value }
            forecastSelectValue={ forecastSelectValue }
            kind={ kind }
            option={ option }
            selected={ value }
            onSelect={ handleSelect }
            onSubMenuSelect={ handleSubMenuSelect }
          />
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
}

export default BusinessReportsMultiSelect;
