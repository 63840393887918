import React, { useCallback, useEffect, useState } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { IReportServiceBudgetFormula } from '@src/types/report_service/report_service_budget_formulas';

import { Button } from '@src/components/ui/buttons';
import Form from '@src/components/ui/form';
import Table from '@src/components/ui/table';

import styles from './styles.module.scss';

interface IFormulaValueCellProps extends Omit<NumberFormatProps, 'value' | 'onChange'> {
  currentFormula: IReportServiceBudgetFormula,
  onChange: (value: number) => void,
  initValue?: number,
  onEdit?: () => void;
}

const FormulaValueCell = ({
  currentFormula,
  onChange,
  initValue,
  onEdit,
}: IFormulaValueCellProps): JSX.Element => {
  const handleBlur = useCallback((e) => {
    if (Number(e.target.value) !== initValue) onChange(e.target.value || 0.0);
  }, [onChange]);
  
  const [value, setValue] = useState<number | undefined>(initValue);
  
  useEffect(() => {
    setValue(initValue)
  }, [initValue]);

  const handleChange = useCallback((val) => {
    setValue(val);
  }, [setValue]);

  const handlePercentageBlur = useCallback((e) => {
    if (e.target.value !== `${initValue}%`) onChange(value || 0);
  }, [onChange, value]);

  const handleKeyPressNumber = useCallback((e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      handleBlur(e);
      e.preventDefault();
    }
  }, [handleBlur]);

  const handleKeyPressPercentage = useCallback((e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      handleBlur(e.target.value);
      e.preventDefault();
    }
  }, [handleBlur]);

  if ((!currentFormula.monthlyInputAutoCalculation)
  || String(currentFormula.id) === window.Docyt.Common.Constants.CUSTOM_ID
  || String(currentFormula.id) === window.Docyt.Common.Constants.ADR_FORMULA_ID) {
    return (
      <Table.Cell className="table-divider text-right">
        {(String(currentFormula.id) === window.Docyt.Common.Constants.ADR_FORMULA_ID
        || String(currentFormula.id) === window.Docyt.Common.Constants.CUSTOM_ID) ? (
          <span className="m-r-10" />
          ) : (
            <Button
              className={ styles['modal-edit-btn'] }
              fontSize={ 16 }
              title="Edit"
              onClick={ onEdit }
            />
          )}
      </Table.Cell>
    );
  }

  return (
    <Table.Cell className="table-divider">
      { (currentFormula.id === 'last_year_actual_increase' || currentFormula.id === 'chart_of_account') ? (
        <Form.PercentField
          className="budget-item-input"
          placeholder="%"
          value={ value }
          onBlur={ handlePercentageBlur }
          onChange={ handleChange }
          onKeyPress={ handleKeyPressPercentage }
        />
      ) : (
        <NumberFormat
          allowEmptyFormatting
          fixedDecimalScale
          isNumericString
          className="budget-item-input"
          decimalScale={ 0 }
          inputMode="numeric"
          value={ initValue }
          onBlur={ handleBlur }
          onKeyPress={ handleKeyPressNumber }
        />
      )}
    </Table.Cell>
  );
};

export default FormulaValueCell;
