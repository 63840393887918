import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TReviewedStatementSortColumn, IReviewedBankStatementsData } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IReviewedStatementViewFilter } from './filter';
import Table from './table';

import styles from '../styles.module.scss';

const sortedRevievedBankStatement = (
  sorting: ISorting<TReviewedStatementSortColumn>,
  reviewedBankStatements: IReviewedBankStatementsData[],
) => {
  switch (sorting.data.orderColumn) {
    case 'banking_account_name':
      if (sorting.data.orderDirection === 'asc') {
        return reviewedBankStatements.sort(
          (a, b) => ((a.name > b.name) ? 1 : -1),
        );
      }
      return reviewedBankStatements.sort(
        (a, b) => ((a.name > b.name) ? -1 : 1),
      );
    case 'current_balance':
      if (sorting.data.orderDirection === 'asc') {
        return reviewedBankStatements.sort(
          (a: any, b: any) => a.currentBalance - b.currentBalance,
        );
      }
      return reviewedBankStatements.sort(
        (a: any, b: any) => b.currentBalance - a.currentBalance,
      );
    case 'missing_statements':
      if (sorting.data.orderDirection === 'asc') {
        return reviewedBankStatements.sort(
          (a: any, b: any) => a.missingStatementsCount - b.missingStatementsCount,
        );
      }
      return reviewedBankStatements.sort(
        (a: any, b: any) => b.missingStatementsCount - a.missingStatementsCount,
      );
    default:
      return reviewedBankStatements;
  }
};

interface IReviewedStatementsListProps {
  businessId: TID,
  filter: IFilter<IReviewedStatementViewFilter>;
  reviewedBankStatements: IReviewedBankStatementsData[],
  months: number,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TReviewedStatementSortColumn>,
  onUploadFile: () => void,
  onCloseUploadModal: () => void,
}

const ReviewedStatementsList = ({
  businessId,
  filter,
  reviewedBankStatements,
  months,
  query,
  sorting,
  onUploadFile,
  onCloseUploadModal,
}: IReviewedStatementsListProps): JSX.Element => {
  const bankStatements = useMemo(() => {
    return sortedRevievedBankStatement(sorting, reviewedBankStatements);
  }, [reviewedBankStatements, sorting]);

  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        title="Bank Statements"
      >
        <Button
          className={ styles['upload-statement-button'] }
          prefixIcon={ <PlusIcon className="font-bold" fontSize={ 14 } /> }
          variant="primary"
          onClick={ onUploadFile }
        >
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10">
        <Filter
          filter={ filter }
        />
        <Table
          businessId={ businessId }
          months={ months }
          query={ query }
          reviewedBankStatements={ bankStatements }
          sorting={ sorting }
          year={ filter.data?.year }
          onCloseUploadModal={ onCloseUploadModal }
        />
      </div>
    </div>
  );
};

export default ReviewedStatementsList;
