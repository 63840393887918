import React, { useMemo } from 'react';

import { TOption } from '@src/components/ui_v2/inputs';
import ReactSelectInput from '@src/components/ui_v2/inputs/react_select/react_select_input';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import CategoryTabber from './category_selection_tabber';
import { transformObjectArray } from './helper';

import styles from './styles.module.scss';

interface ISearchSelectInputProp {
  searchTypeList?: TOption[];
  selectedSearchType: TOption | null;
  onSearchTypeChanged(value: TOption | null): void;
  onSearchQueryChanged(value: string): void;
  searchQuery: string;
  isFullScreen: boolean;
  show: boolean;
  onShow(): void;
}

const SearchSelectInput = ({
  searchTypeList,
  selectedSearchType,
  onSearchTypeChanged,
  isFullScreen,
  onSearchQueryChanged,
  searchQuery,
  onShow,
  show,
}: ISearchSelectInputProp): JSX.Element => {
  const onSelectChange = React.useCallback(
    (value: TOption | null) => {
      onSearchTypeChanged(value);
      onShow();
    },
    [onShow, onSearchTypeChanged],
  );

  const searchTypeOpts = useMemo(() => {
    return transformObjectArray(searchTypeList) as TOption[];
  }, [searchTypeList]);

  return (
    <div className={ [
      styles['general-search'],
      show && !isFullScreen ? styles['show-pannel'] : '',
    ].join(' ') }
    >
      {!isFullScreen && show && (
        <div className={ styles['dropdwon-outer-selector'] }>
          <ReactSelectInput
            alwaysShowIndicator
            className={ styles['border-normal-input'] }
            isClearable={ false }
            isSearchable={ false }
            options={ searchTypeOpts }
            placeholder="All"
            value={ selectedSearchType }
            onChange={ onSelectChange }
          />
        </div>
      )}
      <div className={ isFullScreen ? styles['full-search-continer'] : '' }>
        <div className={ styles['search-outer'] } onFocus={ onShow }>
          <SearchInput
            placeholder="Search here..."
            value={ searchQuery }
            onChange={ onSearchQueryChanged }
            onFocus={ onShow }
          />
        </div>
      </div>
      {isFullScreen && (
        <CategoryTabber
          activeTab={ selectedSearchType }
          searchTypeList={ searchTypeOpts }
          onTabChange={ onSelectChange }
        />
      )}
    </div>
  );
};

export default React.memo(SearchSelectInput);
