import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  DocumentTypeFilterField,
  PaymentAccountFilterField,
  VendorFilterField,
} from '@src/components/common_v2/filter_fields';
import Filter from '@src/components/ui_v2/filter';

import { TTransactionsListType } from './types';

interface MatchDocumentsFilterProps {
  includeUnverified?: boolean,
  listType: TTransactionsListType,
}

const MatchDocumentsFilter = ({
  includeUnverified,
  listType,
}: MatchDocumentsFilterProps) => {
  const business = useBusinessContext();

  return (
    <Filter.TableContainer>
      <VendorFilterField
        businessId={ business.id }
        name="vendorId"
      />
      <Filter.DateField
        name="transaction_date"
      />
      <PaymentAccountFilterField
        businessId={ business.id }
        placeholder="All Accounts"
      />
      <Filter.AmountField
        name="amount"
        valueProps={ { fixedDecimalScale: false } }
      />
      { listType === 'expenses' && <DocumentTypeFilterField /> }
      { includeUnverified && listType === 'expenses' && (
        <Filter.TextField
          label="Transaction Description"
          name="description"
          placeholder="Transaction Description"
        />
      ) }
    </Filter.TableContainer>
  );
};

export default React.memo(MatchDocumentsFilter);
