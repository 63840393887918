module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var category, j, len, ref;
    
      __out.push('<td class="flex-wrapper relative document-clickable pointer">\n  <p class="font-16 font-semi-bold inline-block m-t-10 vertical-align-top">\n    ');
    
      if (this.revenue_service_documents.length) {
        __out.push('\n    <a href="javascript:void(0);" class="btn-view-reports">');
        __out.push(__sanitize(this.date_str));
        __out.push('</a>\n      <span class="label-document-count p-l-5 p-r-5 m-l-10">');
        __out.push(__sanitize(this.revenue_service_documents.length));
        __out.push('</span>\n      ');
        if (this.revenue_service_documents.some(function(rsd) {
          return rsd.state === 'modifying';
        })) {
          __out.push('\n      <span class="font-12 font-semi-bold badge progress-bar-danger">Modified</span>\n      ');
        }
        __out.push('\n    ');
      } else {
        __out.push('\n    <span>');
        __out.push(__sanitize(this.date_str));
        __out.push('</span>\n    ');
      }
    
      __out.push('\n  </p>\n</td>\n<td class="document-clickable font-semi-bold">\n  ');
    
      __out.push(__sanitize(this.revenue_service_documents.length ? '$' + parseFloat(this.revenue_service_documents.reduce((function(total, item) {
        return total + parseFloat(item.display_total_revenue);
      }), 0)).toLocaleString() : ''));
    
      __out.push('\n</td>\n');
    
      ref = this.topCategories;
      for (j = 0, len = ref.length; j < len; j++) {
        category = ref[j];
        __out.push('\n<td class="document-clickable font-semi-bold">\n  ');
        if (this.revenue_service_documents.length) {
          __out.push('\n    ');
          __out.push(__sanitize('$' + parseFloat(this.all_revenue_service_document_category_values.filter((function(_this) {
            return function(i) {
              return i.revenue_report_type_category_id === category.revenue_service_category_id;
            };
          })(this)).reduce((function(total, item) {
            return total + parseFloat(item.display_value);
          }), 0)).toLocaleString()));
          __out.push('\n  ');
        }
        __out.push('\n</td>\n');
      }
    
      __out.push('\n<td class="category-breakdown-cell">\n  <div class="revenue-category-chart"></div>\n</td>\n<td class="money-breakdown-cell">\n  ');
    
      if (this.revenue_service_documents.length) {
        __out.push('\n  <div class="payment-processor-chart"></div>\n  ');
      } else {
        __out.push('\n  <button class="btn btn-blue-on-hover btn-add-report font-semi-bold" data-date="');
        __out.push(__sanitize(this.date_str));
        __out.push('" type="button">Upload Report</button>\n  ');
      }
    
      __out.push('\n</td>\n<td class="relative document-clickable pointer text-right">\n  ');
    
      if (this.revenue_service_documents.length) {
        __out.push('\n  <i class="font-20 icon icon-chat-round relative m-l-3 unread-messages-count"></i>\n  ');
      }
    
      __out.push('\n</td>\n<td class="revenue-action-column relative text-right pointer">\n  ');
    
      if (this.revenue_service_documents.length) {
        __out.push('\n  <a class="btn dropdown-toggle font-15 font-bold in-grey-800" type="button" id="revenue-report-');
        __out.push(__sanitize(this.month));
        __out.push('-');
        __out.push(__sanitize(this.year));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <span class="fa fa-ellipsis-h m-l-5"></span>\n  </a>\n  <ul class="revenue-action-dropdown dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="revenue-report-');
        __out.push(__sanitize(this.month));
        __out.push('-');
        __out.push(__sanitize(this.year));
        __out.push('-action-dropdown-btn">\n    <li><a tabindex="-1" class="btn-view-reports"><span class="icon-queue font-18 m-r-18"></span> View Reports</a></li>\n    <li><a tabindex="-1" class="btn-add-report" data-date="');
        __out.push(__sanitize(this.date_str));
        __out.push('"><span class="icon-o-plus font-18 m-r-18"></span> Add Report</a></li>\n  </ul>\n  ');
      }
    
      __out.push('\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}