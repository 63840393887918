import React from 'react';

import { TID } from '@src/types/common';
import { IReceiptBoxStatus } from '@src/types/service_statuses';

import Table from '@src/components/ui/table';

import styles from '../styles.module.scss';

interface IReceiptBoxStatusProps {
  clientBusinessId: TID;
  status?: IReceiptBoxStatus,
}

const ReceiptBoxStatus = ({
  clientBusinessId,
  status,
}: IReceiptBoxStatusProps) => {
  let content;
  if (!status || status.countQueued === 0) {
    content = (
      <span className={ styles['non-status'] }>-</span>
    );
  } else {
    content = (
      <a
        className={ styles['client-business-status'] }
        href={ `/businesses/${clientBusinessId}/receipt_box/queue?type=unverified` }
        role="button"
        tabIndex={ 0 }
      >
        { status.countQueued }
        { ' ' }
        Unverified
      </a>
    );
  }
  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 10 }
    >
      { content }
    </Table.Cell>
  );
};

export default ReceiptBoxStatus;
