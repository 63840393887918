import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetBusinessVendorsResponse } from '@src/requests/business_vendors';
import { IBusinessVendor, TSelectMergeableVendorSortColumn } from '@src/types/business_vendors';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';
import { useItemSelector } from '@src/components/utils_v2/items_selector';

import SelectMergeableVendorFilter from './filter';

interface ISelectMergeableVendorTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetBusinessVendorsResponse, Error>,
  records: IBusinessVendor[],
  sorting: ISorting<TSelectMergeableVendorSortColumn>,
}

const SelectMergeableVendorTable = ({
  infiniteQuery,
  records,
  sorting,
}: ISelectMergeableVendorTableProps) => {
  const { setSelected } = useItemSelector();

  const handleRowClick = useCallback((businessVendor: IBusinessVendor) => {
    setSelected(businessVendor.id);
  }, [setSelected]);

  return (
    <CollectionTable<IBusinessVendor, TSelectMergeableVendorSortColumn>
      isRegionScroll
      filter={ (
        <SelectMergeableVendorFilter />
      ) }
      height="356px"
      query={ infiniteQuery }
      records={ records }
      showSettings={ false }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.SelectItemColumn<IBusinessVendor>
        inputType="radio"
        name="selectedVendorFlag"
        title="Selected Vendor Flag"
        value={ (businessVendor) => businessVendor.id }
      />
      <CollectionTable.TextColumn<IBusinessVendor>
        hideValueTooltip
        name="vendor"
        title="Vendor"
        value={ (businessVendor) => businessVendor.name }
        width="30%"
      />
      <CollectionTable.TextColumn<IBusinessVendor>
        hideValueTooltip
        name="phoneNumber"
        title="Phone Number"
        value={ (businessVendor) => businessVendor.phone }
        width="20%"
      />
      <CollectionTable.TextColumn<IBusinessVendor>
        hideValueTooltip
        name="emailId"
        title="Email ID"
        value={ (businessVendor) => businessVendor.email }
        width="30%"
      />
      <CollectionTable.TextColumn<IBusinessVendor>
        hideValueTooltip
        name="bankingAccount"
        title="Banking Account"
        value={ (businessVendor) => {
          return businessVendor.dwollaFundingSource?.last4
            ? `xxxx ${businessVendor.dwollaFundingSource?.last4}`
            : '';
        } }
        width="20%"
      />
    </CollectionTable>
  );
};

export default React.memo(SelectMergeableVendorTable);
