import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetBankStatements } from '@src/hooks/queries/bank_statements';
import { useFilter, useSorting } from '@src/hooks/url_params';
import { TReviewedStatementDetailsSortColumn } from '@src/types/bank_statements';
import { TID, TYear } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';
import { formatApiYear } from '@src/utils/date_helpers';

import { IReviewedStatementDetailsFilter } from './filter/types';
import List from './list';

interface IReviewedStatementDetailsProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  year: TYear,
}

const DefaultSorting: ISortingParams<TReviewedStatementDetailsSortColumn> = {
  orderColumn:    'month',
  orderDirection: 'asc',
};

const ReviewedStatementDetails = ({
  businessId,
  rcPaymentAccountId,
  year,
}: IReviewedStatementDetailsProps): JSX.Element => {
  const filter = useFilter<IReviewedStatementDetailsFilter>({
    initData: {
      year: year ? formatApiYear(new Date(parseInt(year, 10), 0, 1)) : formatApiYear(new Date()),
    },
  });
  const sorting = useSorting<TReviewedStatementDetailsSortColumn>(
    { defaultSorting: DefaultSorting },
  );

  const reviewedStatementQuery = useGetBankStatements({
    businessId,
    reconciliationPaymentAccountId: rcPaymentAccountId,
    filter:                         filter.data,
  });

  const bankStatementPages = useMemo(() => {
    return reviewedStatementQuery.data?.pages || [];
  }, [reviewedStatementQuery.data?.pages]);

  const reviewedBankStatements = useMemo(() => {
    return flatten(bankStatementPages.map((p) => p.collection));
  }, [bankStatementPages]);

  const startImportDate = useMemo(() => {
    return bankStatementPages[0]?.meta.startImportDate;
  }, [bankStatementPages]);

  const reconciliationPaymentAccountName = useMemo(() => {
    return bankStatementPages[0]?.meta.reconciliationPaymentAccountName;
  }, [bankStatementPages]);

  return (
    <List
      businessId={ businessId }
      filter={ filter }
      query={ reviewedStatementQuery }
      rcPaymentAccountId={ rcPaymentAccountId }
      rcPaymentAccountName={ reconciliationPaymentAccountName }
      reviewedBankStatements={ reviewedBankStatements }
      sorting={ sorting }
      startImportDate={ startImportDate }
      onCloseDetailsModal={ () => reviewedStatementQuery.refetch() }
    />
  );
};

export default ReviewedStatementDetails;
