import { getBusinessAllUsers } from '@src/requests/business_users';
import { IBusinessUser, IBusinessUsersFilter } from '@src/types/business_users';
import { TID } from '@src/types/common';
import { IAdvisor } from '@src/types/users';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut } from './helpers';

interface IGetManagementGroupMembersParams {
  managementGroupId: TID
  filter?: IBusinessUsersFilter,
}

interface IGetManagementGroupMembersResponse {
  users: IBusinessUser[],
}

interface IGetAllUsersParams {
  managementGroupId?: TID
  businessId?: TID
  filter?: IBusinessUsersFilter,
  serviceProviderBusinessId?: TID | null,
}

interface IGetAllUsersResponse {
  users: IBusinessUser[],
}

const getManagementGroupMembers = (
  params: IGetManagementGroupMembersParams,
): Promise<IGetManagementGroupMembersResponse> => {
  return apiGet(
    '/api/v1/users/management_group_members',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetManagementGroupMembersResponse);
};

const getAllUsers = (
  params: IGetAllUsersParams,
): Promise<IGetAllUsersResponse> => {
  if (params.businessId) {
    return getBusinessAllUsers({
      businessId:                params.businessId,
      filter:                    params.filter,
      serviceProviderBusinessId: params.serviceProviderBusinessId,
    }).then((response) => {
      return {
        users: response.businessUsers,
      };
    });
  }
  return apiGet(
    '/api/v1/users/management_group_members',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetAllUsersResponse);
};

interface IGetNeedReauthenticationResponse {
  needReauthentication: boolean
}

const getNeedReauthentication = (): Promise<IGetNeedReauthenticationResponse> => {
  return apiGet(
    '/api/v1/users/need_reauthentication',
  ).then((data) => {
    return {
      needReauthentication: data.need_reauthentication,
    } as IGetNeedReauthenticationResponse;
  });
};

interface IUpdateUserSettingsParams {
  financialInsightsEmailFrequency: string,
}

const updateUserSettings = (
  params: IUpdateUserSettingsParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/users/update_settings',
    underscoreKeys({ user: params }),
  );
};

interface IGetCurrentAdvisorsResponse {
  advisor: IAdvisor,
}

const getCurrentAdvisor = (): Promise<IGetCurrentAdvisorsResponse> => {
  return apiGet(
    '/api/web/v1/advisor/current_advisor',
  ).then((data) => camelizeKeys(data) as IGetCurrentAdvisorsResponse);
};

export {
  IGetManagementGroupMembersParams,
  IGetManagementGroupMembersResponse,
  IGetAllUsersParams,
  IGetAllUsersResponse,
  IUpdateUserSettingsParams,
  IGetCurrentAdvisorsResponse,
  getManagementGroupMembers,
  getAllUsers,
  getNeedReauthentication,
  updateUserSettings,
  getCurrentAdvisor,
};
