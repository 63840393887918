import React, { forwardRef, useCallback } from 'react';

import { TDate, TMonth } from '@src/types/common';
import {
  apiDateToMonth,
  apiMonthToDate,
  MONTH_PICKER_DATE_FORMAT,
} from '@src/utils/date_helpers';

import DatePickerInput, { IDatePickerInputProps, IDatePickerInputRef } from './datepicker_input';

interface IMonthPickerInputProps extends Omit<IDatePickerInputProps, 'value' | 'onChange'> {
  value: TMonth | undefined,
  onChange?: (value: TMonth | undefined) => void;
}

const MonthPickerInput = forwardRef<IDatePickerInputRef, IMonthPickerInputProps>((
  {
    options,
    value,
    onChange,
    ...props
  }: IMonthPickerInputProps,
  ref,
) => {
  const handleChange = useCallback((newValue: TDate | undefined) => {
    if (!onChange) return;

    onChange(apiDateToMonth(newValue));
  }, [onChange]);

  return (
    <DatePickerInput
      ref={ ref }
      { ...props }
      options={ {
        format:      MONTH_PICKER_DATE_FORMAT,
        minViewMode: 'months',
        ...options,
      } }
      value={ apiMonthToDate(value) }
      onChange={ handleChange }
    />
  );
});

MonthPickerInput.displayName = 'MonthPickerInput';

export {
  IMonthPickerInputProps,
  MonthPickerInput as default,
};
