import React, { useCallback, useEffect, useState } from 'react';

import { snakeCase } from 'lodash';
import moment from 'moment';

import { industryOptions } from '@src/constants/industry_options';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useExportReportAsExcel } from '@src/hooks/queries/report_service/custom_reports';
import { useCreatePortfolioReportExportConfiguration } from '@src/hooks/queries/report_service/report_configurations';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IExportPortfolioReportConfiguration } from '@src/types/data_exports';
import { IMultiReportDetailBusiness } from '@src/types/report_service/multi_report_detail';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { API_DATE_FORMAT, apiMonthToDate, monthToApiEndDate, monthToApiStartDate } from '@src/utils/date_helpers';

import { MonthPickerInput } from '@src/components/ui/form';
import Modal from '@src/components/ui/modal';
import { CheckboxInput, RadioInput, SelectInput, TOption } from '@src/components/ui_v2/inputs';
import MultiSelect from '@src/components/ui_v2/inputs/multi_select/multi_select';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDownloadPortFolioReportConfigurationProps extends IUseModalProps {
  isEdit: boolean;
  configurations: IReportConfiguration[];
  multiBusinessReports: IMultiBusinessReportDetail[];
  reportConfigData?: IExportPortfolioReportConfiguration;
  showConfigModal: boolean;
  onCreateExportData: (result: boolean) => void;
  onUpdate: (data: any) => void;
  handleSubmit: (isChecked: boolean) => void;
}

const DownloadPortfolioReportConfiguration = ({
  isOpen,
  multiBusinessReports,
  isEdit,
  reportConfigData,
  configurations,
  showConfigModal,
  onCancel,
  onDone,
  onCreateExportData,
  onUpdate,
  handleSubmit,
}: IDownloadPortFolioReportConfigurationProps): JSX.Element => {
  const [refreshKey, setRefreshKey] = useState(0);
  const currentDate = new Date();

  const createConfig = useCreatePortfolioReportExportConfiguration();
  const downloadReports = useExportReportAsExcel();

  const [filteredIndustryOptions, setFilteredIndustryOptions] = useState<TOption[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<TOption | null>(null);

  const [selectedIndustryBusiness, setSelectedIndustryBusiness] = useState<TOption[]>([]);
  const [industryBusinessData, setIndustryBusinessData] = useState<TOption[]>([]);

  const [selectedReportList, setSelectedReportList] = useState<TOption[]>([]);
  const [reportListData, setReportListData] = useState<TOption[]>([]);

  const [reportColumns, setReportColumns] = useState<TOption[]>([]);

  const [columnState, setColumnState] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filteredColumn, setFilteredColumn] = useState<TOption[]>([]);

  const [startDateStr, setStartDateStr] = useState<string>('');
  const [endDateStr, setEndDateStr] = useState<string>('');

  const [isChecked, setIsChecked] = useState<boolean>(true);

  const [businesses, setBusinesses] = useState<IMultiReportDetailBusiness[]>([]);

  useEffect(() => {
    if (multiBusinessReports) {
      const tempBusiness: IMultiReportDetailBusiness[] = [];
      const tempBusinessIndustry: number[] = [];
      multiBusinessReports.forEach((reports) => {
        reports.businesses.forEach((business: IMultiReportDetailBusiness) => {
          if (tempBusiness.findIndex((data: IMultiReportDetailBusiness) => data.id === business.id) === -1) {
            tempBusiness.push(business);
          }
          if (!tempBusinessIndustry.find((val) => val === business.standardCategoryId)) {
            tempBusinessIndustry.push(business.standardCategoryId);
          }
        });
      });
      setBusinesses(tempBusiness);
      const filterIndustry = industryOptions.filter((ind) => tempBusinessIndustry.includes(Number(ind.value)));
      setFilteredIndustryOptions(filterIndustry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiBusinessReports, setBusinesses, setFilteredIndustryOptions]);

  /**
   * Desc: Method to get Selected Column value from filtered colum list array
   * @param data
   * @returns
   */
  const filterSelectedColumns = (data: TOption[]) => {
    return selectedColumns.filter(
      (col) => data.findIndex((c) => c.value === col) > -1,
    );
  };

  const handleOnChangeReport = (reportIds: string[], isPreSelect = false) => {
    const mappingColumn = configurations.filter((config) => config.id === 'multi_business_report_columns');
    const configColumns = mappingColumn[0]?.columns || [];

    const selectedReports = multiBusinessReports.filter((report) => {
      return reportIds.indexOf(report.id) > -1;
    });
    const reportsColumns: IReportColumn[] = [];
    selectedReports.forEach((item) => {
      if (item?.columns.length > 0) {
        item?.columns.forEach((itemCol) => {
          if (reportsColumns.findIndex((col) => col.id === itemCol.id) === -1) {
            reportsColumns.push(itemCol);
          }
        });
      }
    });

    const existConfigColumn =
      configColumns.filter((col) => {
        return reportsColumns.findIndex((item) => (
          col.type === item.type
          && col.range === item.range
          && col.year === item.year)) > -1;
      }) || [];
    const data = existConfigColumn.map((col) => {
      return { label: col.name, value: col.id };
    });
    const tempColumnData = data.filter((rep) => !rep.label.includes('Budget') && !rep.label.includes('Forecast'));
    setReportColumns(tempColumnData);
    if (data.length === 0) {
      setSelectedColumns([]);
    } else if (isPreSelect && reportConfigData) {
      const tempCol = reportConfigData?.filterConfig?.columns.map((col) => snakeCase(col));
      setSelectedColumns(tempCol);
    } else {
      const tempCol = filterSelectedColumns(data);
      setSelectedColumns(tempCol);
    }
  };

  const handleOnChangeSelectedReport = (options: TOption[]) => {
    setSelectedReportList(options);
    const reportIds = options.map((val) => val.value);
    handleOnChangeReport(reportIds);
  };

  const handleOnChangeIndustry = (options: TOption | null) => {
    setSelectedIndustry(options);

    const indusBusiness = businesses.filter((business) => {
      return options?.value === `${business.standardCategoryId}`;
    });

    const tempBusiness = indusBusiness.map((item) => { return { label: item.displayName, value: `${item.id}` }; });
    setIndustryBusinessData(tempBusiness);
    setSelectedIndustryBusiness([]);
    handleOnChangeSelectedReport([]);
    return tempBusiness;
  };

  const handleOnChangeBusiness = (options: TOption[]) => {
    handleOnChangeSelectedReport([]);
    setSelectedIndustryBusiness(options);
    const businessArr = options.map((item) => Number(item.value));
    const tempReportList = [];
    for (let i = 0; i < multiBusinessReports.length; i += 1) {
      const report = multiBusinessReports[i];

      for (let j = 0; j < report.businesses.length; j += 1) {
        const business = report.businesses[j];
        if (businessArr.includes(business.id) && tempReportList.findIndex((item) => item.value === report.id) === -1) {
          tempReportList.push({ label: report.name, value: report.id });
        }
      }
    }
    setReportListData(tempReportList);
    return tempReportList;
  };

  const resetFields = () => {
    setSelectedIndustry(null);

    setIndustryBusinessData([]);
    setSelectedIndustryBusiness([]);

    setReportListData([]);
    setSelectedReportList([]);

    setFilteredColumn([]);
    setSelectedColumns([]);
  };

  useEffect(() => {
    if (isEdit && reportConfigData) {
      const currentIndustry = industryOptions.filter(
        (item) => item.value === String(reportConfigData.industryId),
      );
      if (currentIndustry.length > 0) {
        const bData = handleOnChangeIndustry(currentIndustry[0]);
        const currentBusiness = bData?.filter(
          (item) => reportConfigData.filterConfig.businessIds.includes(Number(item.value)),
        ) || [];
        if (currentBusiness.length > 0) {
          const rData = handleOnChangeBusiness(currentBusiness);
          const preSelectedReports = rData?.filter((report: TOption) => {
            return reportConfigData.filterConfig.reports.includes(report.value);
          }) || [];
          setSelectedReportList(preSelectedReports);
          const tempIds = preSelectedReports.map((item) => item.value);
          handleOnChangeReport(tempIds, true);
        }
      }

      setIsChecked(!showConfigModal);
    }
    return () => {
      resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportConfigData, businesses, showConfigModal, isEdit, refreshKey]);

  useEffect(() => {
    let filteredCol = [...reportColumns];
    if (columnState === '$') {
      filteredCol = reportColumns.filter((item) => item.label.includes('$'));
    } else if (columnState === '%') {
      filteredCol = reportColumns.filter((item) => item.label.includes('%'));
    }
    setFilteredColumn(filteredCol);
    const tempCol = filterSelectedColumns(filteredCol);
    if (columnState !== '') {
      setSelectedColumns(tempCol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState, reportColumns]);

  const onStartDateChange = useCallback(
    (value) => {
      if (!value) return;

      const startDate = monthToApiStartDate(value);
      setStartDateStr(startDate);

      if (endDateStr && moment(startDate).isAfter(moment(endDateStr))) {
        setEndDateStr(startDate);
      }
    },
    [setStartDateStr, endDateStr, setEndDateStr],
  );

  const onEndDateChange = useCallback(
    (value) => {
      if (!value) return;

      const endDate = monthToApiEndDate(value);
      setEndDateStr(endDate);

      if (startDateStr && moment(endDate).isBefore(moment(startDateStr))) {
        setStartDateStr(endDate);
      }
    },
    [setEndDateStr, startDateStr, setStartDateStr],
  );

  const handleCancel = useCallback(() => {
    resetFields();
    setColumnState('');
    setStartDateStr('');
    setEndDateStr('');
    setRefreshKey((pre) => pre + 1);
    onCancel();
  }, [onCancel]);

  const isDisabled =
    (!isEdit && (!startDateStr || !endDateStr))
    || !selectedIndustry
    || selectedIndustryBusiness.length === 0
    || selectedReportList.length === 0
    || selectedColumns.length === 0;

  const downloadReport = () => {
    const startDate = apiMonthToDate(startDateStr) || startDateStr;
    const endDate = moment(endDateStr).endOf('month').format(API_DATE_FORMAT);
    downloadReports.mutate(
      {
        startDate,
        endDate,
        exportType:  'multi_consolidated_report',
        reportIds:   selectedReportList.map((item) => item.value),
        columns:     selectedColumns,
        businessIds: selectedIndustryBusiness.map((item) => Number(item.value)),
      },
      { onSuccess: () => onCreateExportData(true) },
    );
    setStartDateStr('');
    setEndDateStr('');
    handleSubmit(isChecked);
    onDone();
  };

  const handleProceed = () => {
    if (isDisabled) return;

    if (isEdit || !reportConfigData) {
      if (isChecked) {
        localStorage.setItem('hidePortfolioExportSetting', 'true');
      } else {
        localStorage.removeItem('hidePortfolioExportSetting');
      }
    }

    const reportSetting = {
      industryId:  Number(selectedIndustry?.value),
      reportIds:   selectedReportList.map((item) => item.value),
      columnIds:   selectedColumns,
      businessIds: selectedIndustryBusiness.map((item) => Number(item.value)),
    };

    if (isEdit) {
      onUpdate({ ...reportSetting, configId: reportConfigData?.id });
      handleSubmit(isChecked);
      onDone();
    } else if (!isEdit && reportConfigData) {
      downloadReport();
    } else {
      createConfig.mutate(reportSetting, { onSuccess: () => downloadReport() });
    }
  };

  /* eslint-disable-next-line no-nested-ternary */
  const columnInputPlaceholder = selectedReportList.length > 0 ? (filteredColumn.length > 0 ? 'Select Columns' : 'No Column Available With Selected Report') : 'Select Report First';

  return (
    <>
      <Modal.Standard
        isProceedDisabled={ isDisabled }
        proceedTitle={ isEdit ? 'Update' : 'Download' }
        show={ isOpen }
        title={ isEdit ? 'Report Settings' : 'Settings Preview' }
        onCancel={ handleCancel }
        onProceed={ handleProceed }
      >
        {() => (
          <div className="download-report-setting">
            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Industry:</span>
              <SelectInput
                className="export-input"
                options={ filteredIndustryOptions }
                placeholder="Select Industry"
                value={ selectedIndustry }
                onChange={ handleOnChangeIndustry }
              />
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Business(s):</span>
              <div className="export-input">
                <MultiSelectAddItem
                  addItem
                  menuClassName="dropdown-menu-fix-height"
                  options={ industryBusinessData }
                  placeholder="Select Business"
                  value={ selectedIndustryBusiness }
                  onChange={ handleOnChangeBusiness }
                />
              </div>
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Select Business Report(s):</span>
              <div className="export-input">
                <MultiSelectAddItem
                  addItem
                  menuClassName="dropdown-menu-fix-height"
                  options={ reportListData }
                  placeholder="Select Reports"
                  value={ selectedReportList }
                  onChange={ handleOnChangeSelectedReport }
                />
              </div>
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Column Type:</span>
              <div className="check-content inputRow">
                <RadioInput
                  small
                  checked={ columnState === '$' }
                  className="m-r-30"
                  title="$ only"
                  onChange={ () => setColumnState('$') }
                />
                <RadioInput
                  small
                  checked={ columnState === '%' }
                  className="m-r-30"
                  title="% only"
                  onChange={ () => setColumnState('%') }
                />
                <RadioInput
                  small
                  checked={ columnState === 'both' }
                  title="Both"
                  onChange={ () => setColumnState('both') }
                />
              </div>
            </div>

            <div className="inputRow m-b-15">
              <span className="inputTitle">Columns:</span>
              <MultiSelect
                className="export-input"
                menuClassName="dropdown-menu-fix-height"
                options={ filteredColumn }
                placeholder={ columnInputPlaceholder }
                value={ selectedColumns }
                onChange={ setSelectedColumns }
              />
            </div>

            {!isEdit && (
              <div className="inputRow m-b-15">
                <span className="inputTitle">Select Period:</span>
                <div className="date-input-row">
                  <MonthPickerInput
                    options={ { endDate: currentDate, format: 'M yyyy' } }
                    placeholder="Start Date"
                    value={ startDateStr }
                    onChange={ onStartDateChange }
                  />
                  <span className="m-r-10" />
                  <MonthPickerInput
                    options={ { endDate: currentDate, format: 'M yyyy' } }
                    placeholder="End Date"
                    value={ endDateStr }
                    onChange={ onEndDateChange }
                  />
                </div>
              </div>
            )}

            {(isEdit || !reportConfigData) && (
              <div className="inputRow">
                <CheckboxInput checked={ isChecked } onChange={ setIsChecked } />
                <span className="m-l-12">Don&apos;t show this again</span>
              </div>
            )}
          </div>
        )}
      </Modal.Standard>
      <MutationStatus mutation={ downloadReports } />
    </>
  );
};

const useDownloadPortfolioReportConfigurationModal = makeUseModal(
  DownloadPortfolioReportConfiguration,
);

export {
  IDownloadPortFolioReportConfigurationProps,
  DownloadPortfolioReportConfiguration as default,
  useDownloadPortfolioReportConfigurationModal,
};
