import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import {
  IIconSelectInputProps,
} from '@src/components/ui_v2/inputs';

import BusinessChartOfAccountInput from '../../inputs/payments_chart_of_account_input';

interface Props<TFilter extends TFilterData> extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  name: keyof TFilter,
  businessId: TID,
}

const ChartOfAccountFilterField = <TFilter extends TFilterData>({
  name,
  businessId,
}: Props<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string | null>(name);

  const handleChange = useCallback((id: number|null) => {
    update(id?.toString());
  }, [update]);

  return (
    <BusinessChartOfAccountInput
      businessId={ businessId }
      placeholder="Chart of Accounts"
      value={ Number(value) }
      onChange={ handleChange }
    />
  );
};

export default React.memo(ChartOfAccountFilterField);
