module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<h3 class="right-side-subtitle">Bank Account</h3>\n<hr />\n<div class="document-field">\n  <div class="document-field-item document-field-name">Bank Name</div>\n  <div class="document-field-item document-field-value">\n    <input class="bank-name-input form-control" type="text" value="" placeholder="Bank Name - XXXX" />\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Bank Name is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Account No.<div class="optional-field-mark">(last 4 digits)</div></div>\n  <div class="document-field-item document-field-value">\n    <input class="account-number-input int-document-field form-control" type="text" value="" placeholder="XXXX" maxlength="4"/>\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Account Number is required.</p>\n</div>\n');
    
      if (this.mode !== 'auto') {
        __out.push('\n<div class="document-field">\n  <div class="document-field-item document-field-name">Routing Number<div class="optional-field-mark">(optional)</div></div>\n  <div class="document-field-item document-field-value">\n    <input class="routing-number-input int-document-field form-control" type="text" value="" placeholder="Routing Number" />\n  </div>\n</div>\n');
      }
    
      __out.push('\n');
    
      if (!this.rcPaymentAccount) {
        __out.push('\n<div class="document-field">\n  <div class="document-field-item document-field-name">Chart of Account</div>\n  <div class="document-field-item document-field-value" style="background: transparent;">\n    <span class="pull-left font-14 text-grey m-t-10 p-t-0 chart-of-account-name-label" type="text" readonly>Select Chart of Accounts</span>\n    <span class="pull-right font-16 font-bold m-t-10"><a class="pointer select-chart-of-account-btn">Edit</a></span>\n  </div>\n  <p class="in-red-400 main-input__hint" style="padding-top: 0;"><i class="fa fa-warning"></i> Chart of Account is required.</p>\n</div>\n<div class="document-field">\n  <div class="document-field-item document-field-name">Starting Date</div>\n  <div class="document-field-item document-field-value">\n    <input class="starting-date-input form-control" type="text" value="" placeholder="Starting Date" autocomplete="off" />\n  </div>\n  <p class="in-red-400 main-input__hint"><i class="fa fa-warning"></i> Starting Date is required.</p>\n</div>\n');
      }
    
      __out.push('\n<div class="document-field">\n  <div class="document-field-item document-field-name">Starting Balance</div>\n  <div class="document-field-item document-field-value">\n    <input class="starting-balance-input form-control" type="number" value="');
    
      if (this.rcPaymentAccount) {
        __out.push(__sanitize(this.rcPaymentAccount.get('start_balance')));
      }
    
      __out.push('" placeholder="Starting Balance (Optional)" />\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}