module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var ref;
    
      __out.push('<div class="documents-cat-header text-center m-t-20">\n    <h3 class="pull-left font-24 font-bold">Invoice Queue</h3>\n    <div class="pull-right">\n        ');
    
      if ((ref = configData.features) != null ? ref.import_invoices_from_qbo : void 0) {
        __out.push('\n        <button class="import-invoices-btn btn btn-blue m-r-5 font-semi-bold font-16">\n            Import from QuickBooks\n        </button>\n        ');
      }
    
      __out.push('\n        <button class="add-invoice-btn btn btn-blue font-semi-bold font-16">\n            <i class="icon icon-plus m-r-5 font-semi-bold font-14"></i>\n            Add Invoice\n        </button>\n    </div>\n    <ul class="nav sub-header-menu p-t-5">\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'queued' ? 'active' : ''));
    
      __out.push('">\n            <a class="queued-invoices-link" href="');
    
      __out.push(__sanitize(this.queueURL));
    
      __out.push('">\n                QUEUED (');
    
      __out.push(__sanitize(this.service.get('count_queued_invoices')));
    
      __out.push(')\n            </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'paid' ? 'active' : ''));
    
      __out.push('">\n            <a class="paid-invoices-link" href="');
    
      __out.push(__sanitize(this.paidURL));
    
      __out.push('">\n                PAID (');
    
      __out.push(__sanitize(this.service.get('count_paid_invoices')));
    
      __out.push(')\n            </a>\n        </li>\n        <li class="m-r-25 ');
    
      __out.push(__sanitize(this.tab === 'stopped' ? 'active' : ''));
    
      __out.push('">\n            <a class="stopped-invoices-link" href="');
    
      __out.push(__sanitize(this.stoppedURL));
    
      __out.push('">\n                STOPPED (');
    
      __out.push(__sanitize(this.service.get('count_stopped_invoices')));
    
      __out.push(')\n            </a>\n        </li>\n    </ul>\n    <hr class="m-t-5" />\n</div>\n<div class=\'pointer new-data-reload-container hidden\'>\n    <a class=\'pointer new-data-link\'>New data available. Click here to reload.</a>\n</div>\n<div id="export-data-banner"></div>\n<ul class="pointer bulk-action-result-container m-t-5 m-b-5">\n    <div class="font-16 result-item-wrapper success-result-item">\n        <i class="icon icon-check_circle_large"></i>\n        <span class=\'success-result m-l-4\'>2 Invoices were verified</span>\n    </div>\n    <hr class="m-t-10 m-b-10 sparation-line" />\n    <div class="font-16 result-item-wrapper fail-result-item">\n        <i class="icon icon-alert in-red-400"></i>\n        <span class=\'fail-result m-l-4 in-red-400\'>1 invoice could not be verified because of missing details.</span>\n    </div>\n</ul>\n<div class="display-flex">\n    <div id="summary-region"></div>\n</div>\n<div class="display-flex">\n    <div id="bulk-action-region"></div>\n    <div id="review-filter-region" class="m-l-10"></div>\n    <div id="counter-region" class="m-l-auto"></div>\n</div>\n<div class="invoices-queue-table-wrapper">\n    <div id="filter-region"></div>\n    <div id="invoices-region"></div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}