import { useContext } from 'react';

import { SideViewContext } from './context';
import { ISideViewContextValue } from './types';

const useSideView = (): ISideViewContextValue => {
  const sideView = useContext(SideViewContext);

  if (!sideView) {
    throw new Error('useSideView must be used within a SideView.Provider');
  }

  return sideView;
};

export {
  useSideView,
};
