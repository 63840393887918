module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="adjustment-journal-entry">\n  <div aria-controls="adjustment-journal-entry-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#adjustment-journal-entry-collapse" data-toggle="collapse" aria-expanded="true">\n    <div>\n      <i class="fa fa-caret-down m-r-5 m-t-10"></i>\n      <span class="font-bold">JOURNAL ENTRIES</span>\n    </div>\n    <div class="relative inline-block">\n      ');
    
      if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['DISABLED']) {
        __out.push('\n        <div class=""><span class="icon-qbo-block font-24 vertical-align-middle m-r-10 qb-block-tooltip-js" data-toggle="tooltip" data-html="true" data-placement="bottom" title=""></span>View in QuickBooks</div>\n      ');
      } else if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['SYNCED']) {
        __out.push('\n        <a class="view-qbo" href="');
        __out.push(__sanitize(this.qbo_link_url ? this.qbo_link_url.qbo_link : '#'));
        __out.push('" target="_blank"><span class="icon-qbo-on vertical-align-middle font-22 m-r-10"></span>View in QuickBooks</a>\n      ');
      } else if (this.qbo_sync_status === Docyt.Common.Constants.QBO_SYNC_STATUS['STARTED']) {
        __out.push('\n        <div class=""><span class="icon-qbo-off font-22 vertical-align-middle m-r-10"></span>View in QuickBooks</div>\n      ');
      } else {
        __out.push('\n        <div class=""><span class="icon-qbo-error font-22 vertical-align-middle m-r-10"></span>View in QuickBooks</div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>\n<div aria-labelledby="adjustment-journal-entry" class="p-t-10 collapse in background-white document-detail-sidebar-section-body" id="adjustment-journal-entry-collapse" aria-expanded="true" style="">\n  <div>\n    <div class="document-field-item document-field-name">Adjustment amount</div>\n    <div class="document-field-item document-field-value">');
    
      __out.push(__sanitize(parseFloat(this.adjustment_entry.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</div>\n  </div>\n  <div>\n    <div class="document-field-item document-field-name">Category</div>\n    <div class="document-field-item category-field-value document-field-value">\n      <div class="line-height-26" id="split-account-dropdown-btn">\n        ');
    
      if (this.adjustment_entry.service_document_splits && this.adjustment_entry.service_document_splits.length >= 1) {
        __out.push('\n          ');
        __out.push(__sanitize(this.adjustment_entry.service_document_splits.length));
        __out.push(' Splits\n        ');
      } else {
        __out.push('\n          ');
        __out.push(__sanitize(this.adjustment_entry.balance_sheet_account ? this.adjustment_entry.balance_sheet_account.display_name : '-'));
        __out.push('\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}