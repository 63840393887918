import React from 'react';

import moment from 'moment';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { IBankAccountReconciliationItemTypes } from '@src/types/bank_account_reconciliations';

import Drawers from '@src/components/reconciliation_center/month_end/drawers';
import { amountFormatterX, getReconciliationDateRange, isVerified } from '@src/components/reconciliation_center/month_end/utils';

import AdjustmentJournalEntry from './adjustment_journal_entry';
import StatementEndingBalanceItem from './statement_ending_balance_item';
import UnclearedBalanceDetail from './uncleared_balance_detail';

import styles from './styles.module.scss';

// from src/components/reconciliation_center/month_end/summary.tsx
const StatementEndingBalance: React.FC<{
  items: IBankAccountReconciliationItemTypes,
}> =
  ({ items }) => {
    const baReconciliation = useBankAccountReconciliationContext();
    const bankStatement = useBankStatementContext();

    const dateRange = getReconciliationDateRange(baReconciliation, bankStatement);
    const endDate = moment(dateRange[1]).format('MMM D, YYYY');

    const {
      unclearedBillPayments: { amount: unclearedBillPaymentsAmount = '0' },
      unclearedBillPaymentsCarriedOver: { amount: unclearedBillPaymentsCarriedOverAmount = '0' },
      unclearedTransfers: { amount: unclearedTransfersAmount = '0' },
      unclearedTransfersCarriedOver: { amount: unclearedTransfersCarriedOverAmount = '0' },
      difference: { amount: differenceAmount = '0' },
      differenceCarriedOver: { amount: differenceCarriedOverAmount = '0' },
      additionalUnclearedBalance: { amount: additionalUnclearedBalanceAmount = '0' },
      additionalUnclearedBalanceCarriedOver: { amount: additionalUnclearedBalanceCarriedOverAmount = '0' },
    } = items;

    const diff = differenceAmount ? parseFloat(differenceAmount) : 0;
    const totalDiff = diff + parseFloat(differenceCarriedOverAmount);

    const isVerifiedStatement = isVerified(bankStatement);
    const showAckButton = true;

    const statementEndingBalanceItems = [
      {
        title:          'Uncleared Expense and Revenue',
        // eslint-disable-next-line max-len
        total:          amountFormatterX(parseFloat(unclearedBillPaymentsAmount) + parseFloat(unclearedBillPaymentsCarriedOverAmount)),
        thisMonth:      amountFormatterX(parseFloat(unclearedBillPaymentsAmount)),
        // eslint-disable-next-line max-len
        carriedOver:    amountFormatterX(parseFloat(unclearedBillPaymentsCarriedOverAmount)),
        item:           items.unclearedBillPayments,
        // eslint-disable-next-line max-len
        drawer:         <Drawers.UnclearedBillPayments items={ items } />,
        downloadType:   'uncleared_bill_payments',
        downloadParams: {
          id:      baReconciliation.id,
          item_id: [
            items.unclearedBillPayments?.id,
            items.unclearedBillPaymentsCarriedOver?.id,
          ],
        },
      },
      {
        title:          'Uncleared Transfers',
        // eslint-disable-next-line max-len
        total:          amountFormatterX(parseFloat(unclearedTransfersAmount) + parseFloat(unclearedTransfersCarriedOverAmount)),
        thisMonth:      amountFormatterX(parseFloat(unclearedTransfersAmount)),
        // eslint-disable-next-line max-len
        carriedOver:    amountFormatterX(parseFloat(unclearedTransfersCarriedOverAmount)),
        item:           items.unclearedTransfers,
        drawer:         <Drawers.UnclearedTransfers items={ items } />,
        downloadType:   'uncleared_transfers',
        downloadParams: {
          id:      baReconciliation.id,
          item_id: [
            items.unclearedTransfers?.id,
            items.unclearedTransfersCarriedOver?.id,
          ],
        },
      },
      {
        title:          'Additional Uncleared Balance',
        // eslint-disable-next-line max-len
        total:          amountFormatterX(parseFloat(additionalUnclearedBalanceAmount) + parseFloat(additionalUnclearedBalanceCarriedOverAmount)),
        thisMonth:      amountFormatterX(additionalUnclearedBalanceAmount),
        carriedOver:    amountFormatterX(additionalUnclearedBalanceCarriedOverAmount),
        drawer:         <Drawers.AdditionalUnclearedBalance items={ items } />,
        downloadType:   'additional_uncleared_balance',
        downloadParams: {
          id:      baReconciliation.id,
          item_id: [
            items.additionalUnclearedBalance?.id,
            items.additionalUnclearedBalanceCarriedOver?.id,
          ],
        },
        downloadColumnMappings: [
          ['category', 'Category'],
          ['transaction_date', 'Date'],
          ['transaction_type', 'Txn Type'],
          ['docyt_id', 'Ref Num'],
          ['description', 'Name [Class]'],
          ['vendor.name', 'Vendor'],
          ['memo', 'Memo'],
          ['service_document_splits', 'Split'],
          ['amount', 'Amount'],
        ] as [string, string][],
        customThisMonth: <UnclearedBalanceDetail items={ items } />,
        item:            items.additionalUnclearedBalance,
      },
      {
        title:            'Difference',
        total:            amountFormatterX(totalDiff),
        thisMonth:        amountFormatterX(diff),
        carriedOver:      amountFormatterX(differenceCarriedOverAmount),
        showDownloadIcon: false,
        downloadType:     'transactions_pushed_by_outside',
        downloadParams:   { id: baReconciliation.id },
        titleAmountColor: totalDiff === 0 ? '#08a37a' : '#ff4848',
        emptyValue:       !isVerifiedStatement,
        tooltip:          isVerifiedStatement ? 'Difference = Ending balance in GL - (Statement ending balance + Uncleared Expense and Revenue + uncleared transfers + additional uncleared balance)' : '',
        item:             items.difference,
        noteRequired:     true,
        showAckButton,
      },
    ];

    return (
      <>
        <tr>
          <td className={ styles.container } colSpan={ 7 }>
            <div className={ styles['items-container'] }>
              <div className={ styles.title }>
                Statement ending balance as of
                {' '}
                { endDate }
                <span>{ isVerifiedStatement ? amountFormatterX(bankStatement?.closingBalance) : '-' }</span>
              </div>

              {
                statementEndingBalanceItems.map(
                  (i) => <StatementEndingBalanceItem key={ i.title } data={ i } />,
                )
              }

              <AdjustmentJournalEntry />
            </div>
          </td>
        </tr>

        <tr>
          <td className={ styles.container } colSpan={ 7 }>
            <div className={ styles['items-container'] }>
              <div className={ `${styles.title} ${styles['bottom-title']}` }>
                Ending balance in GL as of
                {' '}
                { endDate }
                <span>{ amountFormatterX(baReconciliation.endingBalance) }</span>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

export default StatementEndingBalance;
