import React from 'react';

import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  components,
  ControlProps,
  DropdownIndicatorProps,
  StylesConfig,
} from 'react-select';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from '../../styles.module.scss';

interface CommonSelectProps extends IUIStyleProps {
  wideMenu?: boolean,
  hideIndicators?: boolean
  rightAlignMenu?: boolean
}

const ReactSelectStyles: StylesConfig = {
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: styles['grey-470'],
    margin:          0,
  }),
  control: (provided, state) => ({
    // Omit some styles because I want styles from `.form-control` to be applied
    ...omit(provided, [
      'backgroundColor',
      'borderColor',
      'borderStyle',
      'borderWidth',
      'boxShadow',
      'minHeight',
      'outline',
      '&:hover',
    ]),
    padding:     0,
    borderColor: state.menuIsOpen ? styles['dark-100'] : 'transparent',
    boxShadow:   state.menuIsOpen ? styles['form-control-shadow'] : 'none',
  }),
};

const ReactSelectDropdownIndicator = <TOption, TIsMulti extends boolean = false>({
  className,
  ...props
}: DropdownIndicatorProps<TOption, TIsMulti>) => {
  const classes = classNames(styles['dropdown-indicator'], className);

  return (
    <components.DropdownIndicator className={ classes } { ...props }>
      <CaretIcon variant="down" />
    </components.DropdownIndicator>
  );
};

const ReactSelectControl = <TOption, TIsMulti extends boolean = false>({
  className,
  ...props
}:ControlProps<TOption, TIsMulti>) => {
  const classes = classNames('form-control', className);

  return <components.Control className={ classes } { ...props } />;
};

const WideMenuStyles: StylesConfig = {
  menu: (provided) => ({
    ...provided,
    width:    'max-content',
    minWidth: '100%',
  }),
};

export {
  CommonSelectProps,
  ReactSelectControl,
  ReactSelectDropdownIndicator,
  ReactSelectStyles,
  WideMenuStyles,
};
