import React from 'react';

import { Controller, SubmitHandler, useController, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { TServiceType } from '@src/types/services';

import { ErrorNotification } from '@src/components/ui/notification';

import BusinessInput from './forward_document_business_input';
import ServiceInput from './forward_document_service_input';
import { ForwardDocumentServices, BankStatementItem, BalanceSheetStatementItem } from './forward_document_services';

interface IForwardDocumentFormValues {
  forwardToService: TServiceType,
  businessId: TID,
}

interface IForwardDocumentFormProps {
  business: IBusiness
  currentServiceType: TServiceType,
  formId?: string,
  onSubmit: SubmitHandler<IForwardDocumentFormValues>,
}

const ForwardDocumentForm = ({
  business,
  currentServiceType,
  formId,
  onSubmit,
}: IForwardDocumentFormProps): JSX.Element => {
  const defaultSelectedService = ForwardDocumentServices
    .filter((s) => s.value !== currentServiceType)[0]?.value;

  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  const bankStatementIndex = ForwardDocumentServices.findIndex(
    (arrayItem) => arrayItem.label === BankStatementItem.label
      && arrayItem.value === BankStatementItem.value,
  );
  if (bankStatementIndex > -1 && !bankStatementsDocRequestEnabled) {
    ForwardDocumentServices.splice(bankStatementIndex, 1);
  }

  const balanceSheetStatementsDocRequestEnabled = window.configData
    .launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG);

  const balanceSheetStatementIndex = ForwardDocumentServices.findIndex(
    (arrayItem) => arrayItem.label === BalanceSheetStatementItem.label
      && arrayItem.value === BalanceSheetStatementItem.value,
  );
  if (balanceSheetStatementIndex > -1 && !balanceSheetStatementsDocRequestEnabled) {
    ForwardDocumentServices.splice(balanceSheetStatementIndex, 1);
  }

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IForwardDocumentFormValues>({
    defaultValues: {
      forwardToService: defaultSelectedService,
      businessId:       business.id,
    },
  });

  const selectedBusinessId = watch('businessId');

  const disableCurrentService = business && (business.id === selectedBusinessId);
  const serviceController = useController({
    name:  'forwardToService',
    control,
    rules: { required: true },
  });

  return (
    <form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {
        errors.businessId
          ? <ErrorNotification message="Please Select Business To Forward" title="Warning!" />
          : null
      }
      <Controller
        control={ control }
        name="businessId"
        render={ ({ field }) => (
          <BusinessInput currentBusiness={ business } field={ field } />
        ) }
        rules={ { required: true } }
      />
      <div className="p-r-20 p-l-20">
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <label className="p-t-10">Select Service to forward</label>
        <ul>
          {
            ForwardDocumentServices.map(({ icon, label, props, value }, i) => (
              <ServiceInput
                key={ value }
                disabled={ disableCurrentService && value === currentServiceType }
                field={ serviceController.field }
                hasDivider={ i + 1 < ForwardDocumentServices.length }
                icon={ icon }
                iconProps={ props }
                label={ label }
                value={ value }
              />
            ))
          }
        </ul>
      </div>
    </form>
  );
};

export {
  IForwardDocumentFormProps,
  IForwardDocumentFormValues,
  ForwardDocumentForm as default,
};
