import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TIconOption } from '@src/components/ui_v2/inputs';
import { QuestionMarkOrangeIcon, SuccessIcon } from '@src/components/utils/icomoon';

const flaggedStateOptions: TIconOption[] = [
  {
    icon:  <QuestionMarkOrangeIcon fontSize={ 18 } />,
    label: 'Flagged for Client Review',
    value: 'open',
  },
  {
    icon:  <SuccessIcon fontSize={ 18 } />,
    label: 'Reviewed',
    value: 'resolved',
  },
];

interface IFlaggedStatusFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof Filter.IconSelectField>, 'options' | 'name'>
{
}

const FlaggedStatusFilterField = ({
  ...props
}: IFlaggedStatusFieldProps): JSX.Element => {
  return (
    <Filter.IconSelectField
      isSearchable={ false }
      name="flagged_state"
      options={ flaggedStateOptions }
      placeholder="Select Flagged Status…"
      { ...props }
    />
  );
};

export default React.memo(FlaggedStatusFilterField);
