import { array, object, string } from 'yup';

import { TAmount } from '@src/types/common';
import { ISelectedAccountData } from '@src/types/payment_accounts';

interface IAddTransactionInput {
  index: number,
  transactionDate: string,
  description: string,
  amount: TAmount,
  amountType: string,
  selectedAccount: ISelectedAccountData,
}

interface IFormValues {
  newTransactions: IAddTransactionInput[]
}

const defaultValues = {
  newTransactions: [
    {
      transactionDate: '',
      description:     '',
      amountType:      '',
      amount:          '',
      selectedAccount: {
        reconciliationPaymentAccountId: undefined,
        paymentAccountId:               undefined,
      },
    },
  ],
};

const transactionsSchema = object({
  newTransactions: array().of(object({
    transactionDate: string().trim().required('Transaction Date is required.'),
    description:     string().trim().required('Description is required.'),
    amountType:      string().trim().required('Type is required.'),
    amount:          string().trim().required('Amount is required.'),
    selectedAccount: object().test((value, validationContext) => {
      const { createError } = validationContext;

      if (value.reconciliationPaymentAccountId === undefined
        && value.paymentAccountId === undefined) {
        return createError({ message: 'Account is required' });
      }

      return true;
    }),
  })),
});

export {
  IAddTransactionInput,
  IFormValues,
  ISelectedAccountData,
  transactionsSchema,
  defaultValues,
};
