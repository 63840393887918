import React, { useCallback, useState } from 'react';

import { makeUseModal } from '@src/hooks/modal';
import { useGetDwollaCustomersByBusiness } from '@src/hooks/queries/dwolla_customers';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';
import { currencyFormater3 } from '@src/utils/currency';

import { useDocytAchNotActiveModal } from '@src/components/accounts_payable/docyt_ach_not_active_modal';
import { totalAmount } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';
import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import { useMarkAsPaidModal } from './bulk_payment_modals/mark_as_paid/mark_as_paid_modal';
import { usePayByDocytAchModal } from './bulk_payment_modals/pay_by_docyt_ach/pay_by_docyt_ach_modal';
import { usePayByDocytCheckModal } from './bulk_payment_modals/pay_by_docyt_check_modal';
import ReviewPaymentTable from './review_payment_table/table';
import { groupByAccountNumber } from './utils/regroup_invoices_helper';

import styles from './styles.module.scss';

interface IReviewPaymentModalProps {
  businessId: TID,
  apServiceId: TID,
  isOpen: boolean,
  type: string,
  serviceDocuments: IAccountsPayableServiceDocument[],
  limitCountToPay: number,
  onDone: () => void,
  onCancel: () => void,
}

const ReviewPaymentModal = ({
  businessId,
  apServiceId,
  isOpen,
  type,
  serviceDocuments,
  limitCountToPay,
  onDone,
  onCancel,
}: IReviewPaymentModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const amount = totalAmount(serviceDocuments);
  const groupedServiceDocuments = groupByAccountNumber(serviceDocuments);
  const serviceDocumentIds = Object.values(groupedServiceDocuments).map(
    (arr) => arr.map((obj) => obj.id),
  );

  const vendorAddressIds = Object.values(groupedServiceDocuments).map(
    (arr) => arr.map((obj) => obj.vendorAddressId),
  );

  const markAsPaidModal = useMarkAsPaidModal({
    onDone: () => onDone(),
  });
  const { openWithValue: openMarkAsPaidModal } = markAsPaidModal;

  const payByDocytCheckModal = usePayByDocytCheckModal({
    onDone: () => onDone(),
  });
  const { openWithValue: openPayByDocytcheckModal } = payByDocytCheckModal;

  const { data: businessDwollaCustomer } = useGetDwollaCustomersByBusiness(
    { businessId },
    type === window.Docyt.Common.Constants.BULK_PAY_BY_DOCYT_ACH,
  );

  const payByDocytAchModal = usePayByDocytAchModal({
    onDone: () => onDone(),
  });
  const { openWithValue: openPayByDocytAchModal } = payByDocytAchModal;

  const docytAchNotActiveModal = useDocytAchNotActiveModal({
    onDone: () => onDone(),
  });
  const { open: openDocytAchActiveModal } = docytAchNotActiveModal;

  const handleDone = useCallback(() => {
    if ([window.Docyt.Common.Constants.BULK_PAY_BY_DOCYT_CHECK,
      window.Docyt.Common.Constants.BULK_PAY_BY_SELF_PRINT_CHECK].includes(type)) {
      openPayByDocytcheckModal({
        type,
        serviceDocumentIds,
        vendorAddressIds,
        limitCountToPay,
      });
    } else if (type === window.Docyt.Common.Constants.BULK_PAY_BY_DOCYT_ACH) {
      if (businessDwollaCustomer?.dwollaFundingSource === null) {
        openDocytAchActiveModal();
      } else {
        openPayByDocytAchModal({
          type,
          serviceDocumentIds,
          groupedServiceDocuments,
          senderBankAccountName: businessDwollaCustomer?.dwollaFundingSource.name,
          totalAmount:           amount,
          limitCountToPay,
        });
      }
    } else {
      openMarkAsPaidModal({
        type,
        serviceDocumentIds,
        totalAmount: amount,
        limitCountToPay,
      });
    }
  }, [type, openPayByDocytcheckModal, serviceDocumentIds, vendorAddressIds,
    limitCountToPay, businessDwollaCustomer?.dwollaFundingSource, openDocytAchActiveModal,
    openPayByDocytAchModal, groupedServiceDocuments, amount, openMarkAsPaidModal]);

  return (
    <>
      { errorMessage && <ErrorNotification message={ errorMessage } title="Warning!" onHidden={ () => setErrorMessage(undefined) } /> }
      <markAsPaidModal.Component
        businessId={ businessId }
        { ...markAsPaidModal.props }
      />
      <payByDocytCheckModal.Component
        apServiceId={ apServiceId }
        businessId={ businessId }
        { ...payByDocytCheckModal.props }
      />
      <docytAchNotActiveModal.Component
        businessId={ businessId }
        { ...docytAchNotActiveModal.props }
      />
      <payByDocytAchModal.Component
        businessId={ businessId }
        { ...payByDocytAchModal.props }
      />
      <Modal
        className={ styles['review-payment-modal'] }
        closeIcon={ <NotAChargebackIcon fontSize={ 24 } /> }
        show={ isOpen }
        title="Review Payment"
        onCancel={ onCancel }
      >
        <Modal.Body>
          { isOpen && (
            <div className="display-flex-column width-100-percent">
              <p className="width-100-percent text-center in-grey-1100 m-t-20">
                Only &apos;Ready to pay&apos; invoices will be considered for the payment.
              </p>
              <div className="banking-accounts-table-wrapper">
                <ReviewPaymentTable groupedServiceDocuments={ groupedServiceDocuments } />
              </div>
            </div>
          ) }
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ onCancel }
          />
          <div className="display-inline-flex align-items-center">
            <div className="m-r-10">
              <span className="in-grey-1100">Total Payable Amount:</span>
              { ' ' }
              <span className="font-bold in-black">{currencyFormater3(amount)}</span>
            </div>
            <Button
              bsColor="blue"
              onClick={ handleDone }
            >
              Continue
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useReviewPaymentModal = makeUseModal(ReviewPaymentModal);

export {
  IReviewPaymentModalProps,
  useReviewPaymentModal,
  ReviewPaymentModal as default,
};
