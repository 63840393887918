import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TUserRole } from '@src/types/users';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import CancelCheckAction from './cancel_check_action';
import CancelPayAction from './cancel_pay_action';
import DeleteInvoiceAction from './delete_invoice_action';
import DocytAchAction from './docyt_ach_action';
import DocytCheckAction from './docyt_check_action';
import MarkAsPaidBankAction from './mark_as_paid_bank_action';
import MarkAsPaidCheckAction from './mark_as_paid_check_action';
import MarkAsPaidSettlementAction from './mark_as_paid_settlement_action';
import ReportIssueAction from './report_issue_action';
import SendMessageAction from './send_message_action';
import ViewCheckAction from './view_check_action';
import VoidCheckAction from './void_check_action';

interface ISettlementActionsMenuProps {
  serviceDocument: IAccountsPayableServiceDocument,
  userRole?: TUserRole,
}

const SettlementActionsMenu = ({
  serviceDocument,
  userRole,
}: ISettlementActionsMenuProps) => {
  let canCancelCheck = false;
  let canCancelPay = false;
  let canVoidCheck = false;
  let canDelete = false;

  if (serviceDocument.state === 'paid') {
    if (serviceDocument.paymentMode === 'docyt_check' || serviceDocument.paymentMode === 'bank_check' || serviceDocument.paymentMode === 'advanced_settlement' || serviceDocument.paymentMode === 'bank_account') {
      if (userRole !== 'UPLOADER') {
        canCancelCheck = true;
        canVoidCheck = true;
        if (serviceDocument.paymentMode === 'docyt_check') {
          canCancelCheck = false;
          if (serviceDocument.cancellable) {
            canVoidCheck = false;
          }
        } else {
          canVoidCheck = false;
        }

        if (userRole === 'APPROVER' || userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER') {
          canDelete = true;
        }
      }
    } else if (userRole !== 'UPLOADER') {
      canCancelPay = true;
    }
  }

  if (serviceDocument.state === 'do_not_pay') {
    canDelete = true;
  }

  if (serviceDocument.paymentMode === 'docyt_ach' && serviceDocument.servicePaymentTransactionStatus === 'success') {
    canCancelPay = false;
  }

  if (serviceDocument.paymentMode === 'docyt_check' && serviceDocument.servicePaymentTransactionStatus === 'processing') {
    canCancelCheck = false;
    canCancelPay = false;
    canVoidCheck = false;
  }

  return (
    <ActionsDropdown widthPercent={ 20 }>
      <SendMessageAction documentId={ serviceDocument.documentId } />
      {
        (serviceDocument.state === 'ready_for_payment'
          && (userRole === 'PAYER' || userRole === 'APPROVER_AND_PAYER')) && (
          <>
            <DocytCheckAction invoiceIds={ [serviceDocument.id] } />
            <DocytAchAction invoiceIds={ [serviceDocument.id] } />
          </>
        )
      }
      {
        (userRole !== 'UPLOADER' && window.configData.ap_invoice_payable_states.includes(serviceDocument.state)) && (
          <>
            <MarkAsPaidCheckAction invoiceIds={ [serviceDocument.id] } />
            <MarkAsPaidBankAction invoiceIds={ [serviceDocument.id] } />
            <MarkAsPaidSettlementAction invoiceIds={ [serviceDocument.id] } />
          </>
        )
      }
      {
        serviceDocument.checkId && (
          <ViewCheckAction checkId={ serviceDocument.checkId } />
        )
      }
      {
        canCancelCheck && (
          <CancelCheckAction invoiceId={ serviceDocument.id } />
        )
      }
      {
        canCancelPay && (
          <CancelPayAction invoiceId={ serviceDocument.id } />
        )
      }
      {
        canVoidCheck && (
          <VoidCheckAction invoiceId={ serviceDocument.id } />
        )
      }

      {
        window.Docyt.checkDocytUserByEmail('docyt.com') && (
          <ReportIssueAction documentId={ serviceDocument.documentId } />
        )
      }
      
      {
        canDelete && (
          <DeleteInvoiceAction invoiceId={ serviceDocument.id } />
        )
      }
    </ActionsDropdown>
  );
};

export default SettlementActionsMenu;
