/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './styles.module.scss';

interface RevenueCaptureHeaderTableProps {
  integrationId: TID | undefined | null;
  revenueSystemName: string;
}
const maxLength = 35;
const charsInterval = 3;
const currentFont = 18;
const maxReducingFont = 5;
const getFontSize = (headerTxt: string | number | null | undefined) => {
  const headerLength = `${headerTxt}`.length;
  if (!headerTxt) {
    return currentFont;
  }
  if (headerLength > maxLength) {
    const difference = headerLength - maxLength;
    const reducingFont = difference / charsInterval;

    if (reducingFont > maxReducingFont) {
      return null;
    }
    return Math.round(currentFont - reducingFont);
  }
  return currentFont;
};

const renderTextWithTooltip = (
  lengthText: number,
  fontSize: number | null,
  text: string,
  emailIntegration?: boolean,
  email?: boolean,
) => {
  let maxLengthToUse = maxLength;
  if (emailIntegration && fontSize == null) {
    maxLengthToUse = 21;
  }
  return lengthText <= maxLengthToUse ? (
    text
  ) : fontSize == null ? (
    <Text className={ email ? 'in-blue-900' : '' } fontSize={ email ? 13 : 18 } inColor="black">
      {text.substring(0, maxLengthToUse)}
      ...
    </Text>
  ) : (
    <Text fontSize={ email ? 13 : fontSize || 18 }>
      {text}
    </Text>
  );
};

const RevenueCaptureHeaderTable = ({
  integrationId,
  revenueSystemName,
}: RevenueCaptureHeaderTableProps) => {
  const business = useBusinessContext();

  const integration = useGetIntegration(
    { integrationId, businessId: business.id },
    { enabled: !!integrationId },
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const handleCopyEmail = useCallback(() => {
    setShowTooltip(true);
    setTimeout(() => {
      navigator.clipboard.writeText(`${integration?.data?.emailAddress}`)
        .then(() => {
          setTimeout(() => {
            setShowTooltip(false);
          }, 1500);
        })
        .catch((error) => {
          console.error('Failed to copy email address:', error);
          setShowTooltip(false);
        });
    }, 1500);
  }, [integration?.data?.emailAddress]);

  const getHeaderPullText = () => {
    if (!integration.data?.externalSystemConfig?.location) {
      return revenueSystemName;
    }
    if (!Number.isNaN(Number(integration.data?.externalSystemConfig?.location))) {
      return `Property ID/ Store ID : ${integration.data?.externalSystemConfig?.location}`;
    }
    return integration.data?.externalSystemConfig?.location;
  };

  const headerPullText = getHeaderPullText();
  const fontSizeRevenueSystemName = getFontSize(revenueSystemName);
  const fontSizeLocation = getFontSize(headerPullText);
  const fontSizeEmail = getFontSize(`${revenueSystemName}${integration?.data?.emailAddress}`);
  return (
    <>
      {!integration?.data && (
      <Tooltip.Hover
        content={ revenueSystemName }
        style={ { cursor: 'pointer' } }
      >
        {renderTextWithTooltip(
          revenueSystemName.length,
          fontSizeRevenueSystemName,
          revenueSystemName,
        )}
      </Tooltip.Hover>
      )}
      {integration.data?.integrationType === 'pull' && (
      <Tooltip.Hover
        content={ headerPullText }
        style={ { cursor: 'pointer' } }
      >
        {renderTextWithTooltip(
          headerPullText.toString().length,
          fontSizeLocation,
          headerPullText.toString(),
        )}
      </Tooltip.Hover>
      )}
      {integration.data?.integrationType === 'email' && (
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <Tooltip.Hover
            content={ revenueSystemName }
            style={ { cursor: 'pointer', display: 'flex' } }
          >
            {renderTextWithTooltip(`${revenueSystemName}${integration?.data?.emailAddress}`.length, fontSizeEmail, revenueSystemName, true)}
          </Tooltip.Hover>
          <div className={ styles['divider-email'] } />
          <Text as="h2" className={ styles['header-font-13'] }>
            <Tooltip.Hover
              isControl
              content="Email copied to clipboard"
              showTooltip={ showTooltip }
            >
              <Button className={ styles['without-gap'] } variant="link" onClick={ handleCopyEmail }>
                {renderTextWithTooltip(`${revenueSystemName}${integration?.data?.emailAddress}`.length, fontSizeEmail, `${integration?.data?.emailAddress}`, true, true)}
              </Button>
            </Tooltip.Hover>
          </Text>
        </div>
      )}
    </>
  );
};
export default React.memo(RevenueCaptureHeaderTable);
