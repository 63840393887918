/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { IW9InfoResponse } from '@src/types/w9_info';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon, VendorContractIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface ITaxInfoProps {
  vendor: IW9InfoResponse,
}

const VendorContracts = ({
  vendor,
}: ITaxInfoProps) => {
  const [dataVendor, setDataVendor] = useState<IW9InfoResponse>(vendor);

  useEffect(() => {
    setDataVendor(vendor);
  }, [vendor]);

  return (
    <div className={ styles['section-content-container'] }>
      <div className={ styles.header }>
        <div className={ styles.item }>
          <span className={ styles.title }>Vendor Contracts</span>
        </div>
      </div>
      <div className={ styles['section-content'] }>
        <div className={ styles['no-data-container'] }>
          <VendorContractIcon fontSize={ 60 } />
          <h4 className={ styles['no-info-txt'] }>
            No vendor contracts added yet.
          </h4>
          <Button
            prefixIcon={ <PlusIcon fontSize={ 14 } /> }
            variant="outline"
          >
            Add Vendor Contract
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VendorContracts);
