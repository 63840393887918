import React from 'react';

import classNames from 'classnames';

import FaIcon, { IFaIconProps } from './fa_icon';

type TStarIconType = 'outline' | 'blue' | 'yellow' | 'red';

interface IStarIconProps extends Omit<IFaIconProps, 'icon' | 'color'> {
  type?: TStarIconType,
}

const StarIcon = ({
  className,
  type = 'outline',
  ...props
}: IStarIconProps): JSX.Element | null => {
  let icon: string;
  let color = null;

  switch (type) {
    case 'outline':
      icon = 'star-o';
      color = 'in-dark-100';
      break;
    case 'blue':
      icon = 'star';
      color = 'in-blue-900';
      break;
    case 'yellow':
      icon = 'star';
      color = 'in-vivid-orange';
      break;
    case 'red':
      icon = 'star';
      color = 'in-bright-red';
      break;
    default:
      return null; // wrong type
  }
  const classes = classNames(color, className);

  return (
    <FaIcon
      className={ classes }
      icon={ icon }
      { ...props }
    />
  );
};

export {
  TStarIconType,
  StarIcon as default,
};
