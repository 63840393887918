import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import Root from '@src/components/root';

import ReviewPaymentModal from './review_payment_modal';

interface IPReviewsPaymentPageProps {
  businessId: TID,
  apServiceId: TID,
  type: string,
  serviceDocuments: IAccountsPayableServiceDocument[],
  limitCountToPay: number,
  onDone: () => void,
  onCancel: () => void,
}

const ReviewsPaymentPage = ({
  businessId,
  apServiceId,
  type,
  serviceDocuments,
  limitCountToPay,
  onDone,
  onCancel,
}: IPReviewsPaymentPageProps): JSX.Element => {
  return (
    <Root>
      <ReviewPaymentModal
        isOpen
        apServiceId={ apServiceId }
        businessId={ businessId }
        limitCountToPay={ limitCountToPay }
        serviceDocuments={ serviceDocuments }
        type={ type }
        onCancel={ onCancel }
        onDone={ onDone }
      />
    </Root>
  );
};

export default ReviewsPaymentPage;
