module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="report-name">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</div>\n<div class="flex-1">\n  <span>From: ');
    
      __out.push(__sanitize(this.start_date ? moment(this.start_date).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '-'));
    
      __out.push('</span>\n  <span>To: ');
    
      __out.push(__sanitize(this.end_date ? moment(this.end_date).format(Docyt.Common.Constants['US_DATE_FORMAT']) : '-'));
    
      __out.push('</span>\n</div>\n<div class="flex-1">\n  <div class="business-names-truncate" data-toggle="tooltip" data-placement="bottom">');
    
      __out.push(__sanitize(this.business_names));
    
      __out.push('</div>\n</div>\n<div class="file-type flex-1">\n  ');
    
      if (this.file_type === Docyt.Common.Constants.DATA_EXPORT_TYPES.EXCEL) {
        __out.push('\n    Excel\n  ');
      } else if (this.file_type === Docyt.Common.Constants.DATA_EXPORT_TYPES.IIF) {
        __out.push('\n    IIF\n  ');
      } else {
        __out.push('\n    PDF\n  ');
      }
    
      __out.push('\n</div>\n<div class="report-action-wrapper relative p-l-10">\n    <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="report-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <i class=\'icon icon-3-dots font-20\'></i>\n    </button>\n    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="report-action-dropdown-btn">\n        <a class="dropdown-item font-14 pointer download-report-js">\n            Download\n        </a>\n        <a class="dropdown-item font-14 pointer delete-report-js">\n            Delete\n        </a>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}