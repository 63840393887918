import { TButtonColumn, TModel } from './types';

const CollectionTableButtonColumn = <
  Model extends TModel,
  TSortColumn extends string = never
>(_props: Omit<TButtonColumn<Model, TSortColumn>, 'type'>) => {
  return null;
};

export default CollectionTableButtonColumn;
