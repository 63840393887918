import { useMutation } from 'react-query';

import {
  IAddAchBankParams,
  postAchBankAccount,
} from '@src/requests/vendor_ach_bank_account';

const useCreateAchBankAccount = () => {
  return useMutation<void, Error, IAddAchBankParams>(
    postAchBankAccount,
  );
};

export {
  useCreateAchBankAccount,
};
