import React from 'react';

import DetailsPageTitle from '@src/components/ui/details_page_title';

import AddManagementGroupAction from './actions/add_management_group_action';

interface IManagementGroupsHeaderProps {
  showNew: boolean,
}

const ManagementGroupsHeader = ({
  showNew,
}: IManagementGroupsHeaderProps) => {
  return (
    <DetailsPageTitle title="Management Group">
      <AddManagementGroupAction showNew={ showNew } />
    </DetailsPageTitle>
  );
};

export default ManagementGroupsHeader;
