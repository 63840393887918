import React from 'react';

import { IDataExport } from '@src/types/data_exports';

import Dropdown from '@src/components/ui/dropdown';
import { AngleDownIcon } from '@src/components/utils/fa_icons';
import { useItemsSelector } from '@src/components/utils/items_selector';

import BulkDeleteDataExportsAction from '../list_item/data_export_item_actions/bulk_delete_action';
import DownloadDataExportsAction from '../list_item/data_export_item_actions/download_data_exports_action';

const BulkActions = (): JSX.Element => {
  const { selected } = useItemsSelector<IDataExport>();

  return (
    <Dropdown
      className="bulk-action-container"
      style={ { position: 'absolute', top: '20px' } }
    >
      <Dropdown.ButtonToggle
        className="btn-blue-on-hover"
        disabled={ selected.length === 0 }
        fontSize={ 15 }
        fontVariant="bold"
      >
        Bulk Actions
        <AngleDownIcon ml={ 5 } />
      </Dropdown.ButtonToggle>
      {
        selected.length > 0 && (
          <Dropdown.Menu
            className="toggle-action-dropdown"
          >
            <BulkDeleteDataExportsAction dataExports={ selected } />
            <DownloadDataExportsAction dataExports={ selected } />

          </Dropdown.Menu>
        )
      }
    </Dropdown>
  );
};

export {
  BulkActions,
};
