import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';
import { camelizeKeys, underscoreKeys, trimNumKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut, apiPost, apiDelete } from './helpers';

interface IFetchW9InfoParams {
  businessId: TID,
  vendorId: TID,
}

interface IUpdateW9InfoParams {
  addressCity: string;
  addressCountry: string;
  addressLine1: string;
  addressState: string;
  addressZip: string;
  businessId: TID;
  id: TID;
  w9BusinessType: string;
  w9Ein: string;
  w9Email: string;
  w9LegalName: string;
  w9Name: string;
  w9Phone: string;
  w9Ssn: string;
  w9SignedDate: string;
}

interface IUploadW9InfoFileParams {
  file: File,
}

interface IUpdateW9InfoFileParams {
  businessId: TID;
  vendorId: TID;
  w9FileToken: string;
}

interface IUploadW9InfoFileResponse {
  token: string
}

const getVendorsW9Info = (
  params: IFetchW9InfoParams,
): Promise<IW9InfoResponse> => {
  return apiGet(
    '/api/v1/business_vendors/w9_info',
    underscoreKeys({ ...params }),
  ).then((data) => camelizeKeys(data.business_vendor) as IW9InfoResponse);
};

const putVendorW9Info = (
  params: IUpdateW9InfoParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/business_vendors/update_w9_info',
    trimNumKeys(params),
  );
};

const uploadW9InfoFile = (
  params: IUploadW9InfoFileParams,
): Promise<IUploadW9InfoFileResponse> => {
  const formData = new FormData();

  if (params.file) {
    formData.append('file', params.file);
    formData.append('original_file_name', params.file.name);
  }

  return apiPost(
    '/storage/api/v1/files.json',
    formData,
  );
};

const putVendorW9InfoFile = (
  params: IUpdateW9InfoFileParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/business_vendors/update_w9_info',
    trimNumKeys(params),
  );
};

const deleteVendorW9InfoFile = (
  params: IFetchW9InfoParams,
): Promise<void> => {
  return apiDelete(
    '/api/v1/business_vendors/destroy_w9_file',
    trimNumKeys(params),
  );
};

export {
  getVendorsW9Info,
  putVendorW9Info,
  uploadW9InfoFile,
  putVendorW9InfoFile,
  deleteVendorW9InfoFile,
  IUpdateW9InfoParams,
  IUploadW9InfoFileParams,
  IUpdateW9InfoFileParams,
  IUploadW9InfoFileResponse,
  IFetchW9InfoParams,
};
