module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog modal-lg payment-rule-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="modal-title font-28 font-bold">\n            ');
    
      __out.push(__sanitize(!this.id ? 'Add' : 'Edit'));
    
      __out.push(' Mark as Paid Rule\n        </h1>\n      </div>\n      <div class="modal-body">\n        <div class="rule-form-group m-t-12">\n          <label class="form-label font-16">Payee/Receiver</label>\n          <div class="form-inline relative vendor-typeahead-wrapper">\n            <div class="vendors-region width-100-percent"></div>\n          </div>\n        </div>\n        <p class="in-red-400 input-vendor-warning m-t-10 m-b-10 text-right"><i class="fa fa-warning m-r-5"></i>Please select vendor.</p>\n        <div class="rule-form-group m-t-12 approvers-typeahead-wrapper">\n          <label class="form-label font-16">Payment Account</label>\n          <div class="form-inline relative">\n            <div class="payment-accounts-region"></div>\n          </div>\n        </div>\n        <p class="in-red-400 input-account-warning m-t-10 m-b-10 text-right"><i class="fa fa-warning m-r-5"></i>Please select account.</p>\n        <div class="explanation-block p-t-28 m-t-33">\n          <h3 class="font-14 font-bold m-t-0 m-b-0">AUTHORIZATION</h3>\n          <p class="font-12 m-t-20 m-b-0">By activating this “Mark as Paid” rule, you acknowledge that all invoices from the above specified Payee have already been paid using the specified Payment Account. Docyt will automatically mark all invoices from this Payee as “Paid” and update your accounting books.</p>\n          <p class="font-12 m-t-30 m-b-0">You will still be able to make any changes to any invoice, including removing the “Paid” status, and bringing it back in Payment Queue to pay it.</p>\n        </div>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer font-18\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js">\n            <span class="ladda-label">');
    
      __out.push(__sanitize(!this.id ? 'Create Rule' : 'Edit Rule'));
    
      __out.push('</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}