import React from 'react';

import { IUseModalParams, IUseModalReturn } from '@src/hooks/modal';

import { DeleteDocumentIcon } from '@src/components/utils/icomoon';

import ConfirmDialog, { useConfirmDeleteModal, IConfirmDeleteProps } from './confirm_delete';

interface IUseConfirmDeleteDocumentsPermanentlyModalParams extends IUseModalParams {
  documentIds: number[] | 'all',
  itemName?: string,
}

interface IUseConfirmDeleteDocumentsPermanentlyModalReturn extends IUseModalReturn {
  Component: typeof ConfirmDialog,
  props: IConfirmDeleteProps,
}

const ConfirmDeleteDocumentsPermanentlyTitle = (
  { documentIds, itemName = 'documents' }:
    { documentIds: number[] | 'all', itemName?: string },
) => (
  <>
    { documentIds === 'all' ? 'All' : documentIds.length }
    { ' ' }
    { itemName.toLowerCase() }
    { ' ' }
    will be deleted permanently.
    <br />
    Are you sure you want to continue?
  </>
);

const useConfirmDeleteDocumentsPermanentlyModal = (
  {
    documentIds,
    itemName = 'Document',
    ...modalParams
  }: IUseConfirmDeleteDocumentsPermanentlyModalParams,
): IUseConfirmDeleteDocumentsPermanentlyModalReturn => {
  const modal = useConfirmDeleteModal(modalParams);

  let realItemName = itemName;
  if (documentIds.length > 1 && ['Document', 'Expense'].includes(itemName)) {
    realItemName = `${itemName}s`;
  }

  const title = documentIds === 'all'
    ? 'Delete Permanently?'
    : `Delete ${documentIds.length} ${realItemName} Permanently?`;

  return {
    ...modal,
    props: {
      ...modal.props,
      confirmStyle: 'danger',
      confirmTitle: 'Delete',
      icon:         <DeleteDocumentIcon />,
      text:         (
        <ConfirmDeleteDocumentsPermanentlyTitle
          documentIds={ documentIds }
          itemName={ realItemName }
        />
      ),
      title,
    },
  };
};

export {
  useConfirmDeleteDocumentsPermanentlyModal,
};
