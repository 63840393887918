import React from 'react';

import { multiEntityReportsPath } from '@src/routes';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import { MultiCustomReportIcon } from '@src/components/utils/icomoon';

const Sidemenu = ({ index }: { index: string }) => {
  return (
    <ServiceSidebar>
      <ServiceSidebar.Item
        active={ index === 'custom_reports' }
        href={ multiEntityReportsPath() }
        icon={ <MultiCustomReportIcon /> }
        title="Advanced Reports"
      />
    </ServiceSidebar>
  );
};

export default Sidemenu;
