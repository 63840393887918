import React from 'react';

import Item from './item';
import ItemIcon from './item_icon';
import ItemLabel from './item_label';

interface IDropdownIconItem extends React.ComponentPropsWithoutRef<typeof Item> {
  icon?: React.ReactNode,
}

const DropdownIconItem = ({
  children,
  icon,
  title,
  ...props
}: IDropdownIconItem) => {
  return (
    <Item { ...props }>
      <ItemIcon>
        { icon }
      </ItemIcon>
      <ItemLabel title={ title }>
        { children }
      </ItemLabel>
    </Item>
  );
};

export default DropdownIconItem;
