module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div id="transactionDateFilterRegion"></div>\n<div class="filter-group transaction-filter-description">\n  <input type="text" class="form-control filter-description" placeholder="Transaction Description" value="');
    
      __out.push(__sanitize(this.filter.description ? this.filter.description.trim() : ''));
    
      __out.push('">\n</div>\n<div class="filter-group transaction-filter-status">\n  <select class="form-control filter-transaction-type selectpicker" title="Transaction Type">\n    <option value="all">All Transactions</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.ASSET_PURCHASE));
    
      __out.push('">Asset Withdrawal</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.CHARGEBACK));
    
      __out.push('">Chargeback</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.CUSTOMER_CLAIMS));
    
      __out.push('">Customer Claims</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.EQUITY_INVESTMENT));
    
      __out.push('">Equity Investment</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.EQUITY_WITHDRAWAL));
    
      __out.push('">Equity Withdrawal</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.EXPENSE));
    
      __out.push('">Expense</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.LIABILITY_PAYMENTS));
    
      __out.push('">Liability Payments</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.LOAN_PAYMENTS));
    
      __out.push('">Loan Payments</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.LOAN_PROCEEDS));
    
      __out.push('">Loan Proceeds</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.OTHER_INCOME));
    
      __out.push('">Other Income</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.OTHER_RECEIVABLES));
    
      __out.push('">Other Receivables</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.PAYROLL));
    
      __out.push('">Payroll Charges</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.REVENUE));
    
      __out.push('">Revenue</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.REVENUE_REVERSAL));
    
      __out.push('">Revenue Reversal</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.TRANSFER));
    
      __out.push('">Transfer</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.UNVERIFIED));
    
      __out.push('">Uncategorized Transactions</option>\n    <option value="excluded" ');
    
      if (this.filter && this.filter.excluded) {
        __out.push(__sanitize('selected'));
      }
    
      __out.push('>Excluded</option>\n    <option value="');
    
      __out.push(__sanitize(configData.transaction_types.REFUND));
    
      __out.push('">Vendor Refunds</option>\n  </select>\n</div>\n<div class="filter-group transaction-filter-banking-account">\n  <div id=\'payment-account-filter-region\'></div>\n</div>\n<div id="importedAtFilterRegion"></div>\n<div class="input-group filter-group transaction-filter-amount-range">\n  <div class="amount-filter-region"></div>\n</div>\n<div class="filter-group width-5-percent relative">\n  <input type="text" class="form-control filter-matched-txns typeahead matched-typeahead" readonly value="');
    
      __out.push(__sanitize(this.matched_value));
    
      __out.push('" />\n</div>\n<div class="qbo-connection-filter-region filter-group width-5-percent relative">\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}