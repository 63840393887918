import React from 'react';

import { Routes, Route } from 'react-router-dom';

import BusinessMetricProvider from './business_metric_provider';
import DetailsV2 from './details_v2';
import Root from './root';

const MetricsServiceValuesRouterV2 = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/metrics_service">
        <Route element={ <BusinessMetricProvider /> } path=":businessMetricId">
          <Route element={ <DetailsV2 /> } path="values" />
        </Route>
        <Route index element={ <Root /> } />
      </Route>
    </Routes>
  );
};

export default React.memo(MetricsServiceValuesRouterV2);
