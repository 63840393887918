import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IMetricsReportUpdatedFayeEvent } from '@src/types/docyt_events/metrics_report';

import { useFayeChannel } from './common';

export const useMetricsReportFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<IMetricsReportUpdatedFayeEvent>,
) => {
  useFayeChannel(
    `/metrics-service/${businessId}/metric-reports`,
    callback,
  );
};
