import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useAddManagementGroup, useUpdateManagementGroup } from '@src/hooks/queries/management_groups';
import { IManagementGroup } from '@src/types/management_groups';

import { IManagementGroupInput } from '@src/components/management_groups/modals/schema';
import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import EditManagementGroupModalForm from './edit_management_group_modal_form';

import styles from '../styles.module.scss';

interface IManagementGroupModalProps extends IUseModalProps {
  managementGroup?: IManagementGroup,
}

const EditManagementGroupModal = ({
  managementGroup,
  isOpen,
  onCancel,
  onDone,
}: IManagementGroupModalProps) => {
  const addManagementGroup = useAddManagementGroup();
  const editManagementGroup = useUpdateManagementGroup();

  let title = 'Create New Management Group';
  if (managementGroup) {
    title = 'Edit Management Group';
  }

  const handleProceed = useCallback((values: IManagementGroupInput) => {
    if (managementGroup) {
      editManagementGroup.mutate(
        {
          id:   managementGroup.id,
          name: values.name,
        },
        {
          onSuccess: () => {
            onDone();
          },
        },
      );
      return;
    }
    addManagementGroup.mutate(
      {
        name: values.name,
      },
      {
        onSuccess: () => {
          onDone();
        },
      },
    );
  }, [onDone, addManagementGroup, editManagementGroup, managementGroup]);

  return (
    <>
      {
        !managementGroup
        && (
        <MutationStatus
          mutation={ addManagementGroup }
          successMessage="Created new management group successfully."
        />
        )
      }
      {
        managementGroup
        && (
        <MutationStatus
          mutation={ editManagementGroup }
          successMessage="Updated the management group successfully."
        />
        )
      }
      <Modal.Form
        className={ styles['management-group-modal'] }
        formId="edit_management_group_form"
        proceedTitle="Save"
        show={ isOpen }
        title={ title }
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <EditManagementGroupModalForm
            formId={ formId }
            managementGroup={ managementGroup }
            onSubmit={ handleProceed }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useEditManagementGroupModal = makeUseModal(EditManagementGroupModal);

export {
  IManagementGroupModalProps,
  useEditManagementGroupModal,
  EditManagementGroupModal as default,
};
