import { TID } from '@src/types/common';
import { IDocumentField } from '@src/types/document_field';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetDocumentFieldsParams {
  documentId: TID,
}

interface IGetDocumentFieldsResponse {
  documentFields: IDocumentField[],
}

const getDocumentFields = (
  params: IGetDocumentFieldsParams,
): Promise<IGetDocumentFieldsResponse> => {
  return apiGet(
    '/api/v1/document_fields',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetDocumentFieldsResponse);
};

export {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
};
