import React from 'react';

import styles from '../../../styles.module.scss';

interface IBalanceInfoProps {
  totalCount: number,
  selectedCount: number,
}

const BalanceInfo = ({
  totalCount,
  selectedCount,
}: IBalanceInfoProps): JSX.Element => {
  return (
    <div className={ styles['selected-transactions-result'] }>
      <div className="display-flex">
        <span className="m-r-20">Transactions from Bank Feed:</span>
        <span className="font-bold">{ totalCount }</span>
      </div>
      <div className="display-flex">
        <span className="m-r-20">Selected Transactions:</span>
        <span className="font-bold">{ selectedCount }</span>
      </div>
    </div>
  );
};

export default BalanceInfo;
