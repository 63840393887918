module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="expense-header">\n  <h1 class="font-24 font-bold pull-left width-100-percent m-t-20 m-b-20">Category Management</h1>\n</div>\n<div class="m-t-25">Select which categories will be available to your employees</div>\n<div class="reports-table-container m-t-20 payment-table-wrapper">\n  <table class="table banking-accounts-table categories-table">\n    <thead>\n      <tr>\n        <th class="sortable width-30-percent" data-orderby="name" data-orderdir="DESC">Category Name</th>\n        <th class="sortable width-18-percent" data-orderby="code" data-orderdir="ASC">Code</th>\n        <th class="sortable width-20-percent" data-orderby="class" data-orderdir="ASC">Department</th>\n        <th class="sortable width-35-percent text-right" style="padding-right: 30px !important" data-orderby="qbo_cat" data-orderdir="ASC">QuickBooks Category</th>\n        <th class="actions-column"></th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n    <tfoot>\n      <tr>\n        <td>\n          <div class="display-flex align-items-center m-t-12 m-l-12 in-blue-900 font-semi-bold pointer add-category-js">\n            <i class="icon icon-o-plus font-20"></i>\n            <span class="m-l-14">Add Category</span>\n          </div>\n        </td>\n        <td></td>\n        <td></td>\n        <td></td>\n        <td></td>\n      </tr>\n    </tfoot>\n  </table>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}