module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="setup-business-step-section setup-ar-section">\n  <div class="main-panel">\n    <div class="profile-header">\n      <h2 class="panel-title">Get Paid by ACH</h2>\n      <p class="panel-description">\n        Your bank account needs to be connected to be able to receive payments.\n      </p>\n    </div>\n    <div class="profile-body ');
    
      __out.push(__sanitize(!this.fundingSource.get('is_verified') ? 'border-bottom-gray' : ''));
    
      __out.push('">\n      ');
    
      if (this.dwollaCustomer.get('has_funding_source')) {
        __out.push('\n      <p class="m-t-20 m-b-20">\n        ');
        if (this.fundingSource.get('is_verified')) {
          __out.push('\n        Your account is connected to the bank account below.\n        ');
        } else {
          __out.push('\n        Your bank account is connected but is pending verification.\n        ');
        }
        __out.push('\n      </p>\n      <div class="profile-input-wrap">\n        <label class="font-semi-bold">Bank:</label>\n        <span class="profile-input">');
        __out.push(__sanitize(this.fundingSource.get('name')));
        __out.push('</span>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="font-semi-bold">Account #:</label>\n        <span class="profile-input">**** **** ****');
        __out.push(__sanitize(this.fundingSource.get('account_last4')));
        __out.push('</span>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="font-semi-bold">Routing #:</label>\n        <span class="profile-input">');
        __out.push(__sanitize(this.fundingSource.get('routing_number')));
        __out.push('</span>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="font-semi-bold">Status:</label>\n        ');
        if (this.fundingSource.get('is_verified')) {
          __out.push('\n        <span class="profile-input font-semi-bold in-green-550">Verified</span>\n        ');
        } else {
          __out.push('\n        <span class="profile-input font-semi-bold in-yellow-400">Unverified</span>\n        ');
        }
        __out.push('\n      </div>\n      <div class="profile-input-wrap">\n        <label class="font-semi-bold">Connected since:</label>\n        <span class="profile-input">');
        __out.push(__sanitize(this.fundingSource.connectedSince()));
        __out.push('</span>\n      </div>\n      ');
      } else {
        __out.push('\n      <div class="profile-input-wrap">\n        <label>Account Holder\'s Legal Name</label>\n        <input type="text" class="account-holder-input form-control holder-fname-js" placeholder="First Name" value = "');
        __out.push(__sanitize(this.dwollaCustomer.get('first_name') || Docyt.currentAdvisor.get('first_name')));
        __out.push('">\n        <input type="text" class="account-holder-input form-control holder-lname-js" placeholder="Last Name" value = "');
        __out.push(__sanitize(this.dwollaCustomer.get('last_name') || Docyt.currentAdvisor.get('last_name')));
        __out.push('">\n        <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Account Holder\'s Email</label>\n        <input type="email" class="profile-input width-60-percent form-control holder-email-js" placeholder="email@website.com" value = "');
        __out.push(__sanitize(this.dwollaCustomer.get('email') || this.email));
        __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning in-red-400"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Legal Entity Name</label>\n        <input type="text" class="profile-input width-60-percent form-control entity-name-js" placeholder="Enter your formal business name" value = "');
        __out.push(__sanitize(this.dwollaCustomer.get('business_name') || this.name));
        __out.push('">\n        <p class="valid-hint-js"><i class="fa fa-warning in-red-400"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label>Bank Account</label>\n        <button class="profile-save-btn btn btn-blue-on-hover add-bank-auto-js">Add New bank Account</button>\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <a class="font-14 font-semi-bold pointer add-bank-manual-js">Setup New Bank Account Manually</a>\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <div class="display-flex align-items-center m-b-30">\n          <span class="icon icon-lockedalt"></span>\n          <span class="font-10 font-semi-bold m-l-6 in-green-700">All connections are encrypted</span>\n        </div>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n    ');
    
      if (this.dwollaCustomer.get('has_funding_source') && !this.fundingSource.get('is_verified')) {
        __out.push('\n    <div class="m-t-30 p-b-20 font-14 border-bottom-gray">\n      <p class="m-b-30">We have sent two small fractional dollar transactions to your bank account. It takes about a day for these transactions to appear in your account. Enter the values for these two transactions below.</p>\n      <div class="display-flex justify-content-between">\n        <input type="number" class="form-control text-center width-260px amount-first" placeholder="0.00">\n        <input type="number" class="form-control text-center width-260px m-l-20 amount-second" placeholder="0.00">\n      </div>\n      <div class="font-12 display-flex align-items-center m-t-5 in-red-600 warning-wrapper">\n        <span class="icon-warning-triangle"></span>\n        <span class="inline-block nav-item-text">The entered amounts could not be verified. Please make sure you entered the correct amounts.</span>\n      </div>\n    </div>\n    <div class="display-flex align-items-center width-100-percent m-t-20">\n      <button class="btn btn-blue font-semi-bold verify-trans-js m-t-auto"> Verify Transactions </button>\n      <div class="m-l-auto">\n        <a class="font-bold pointer exit-setup-js">Exit manual connection</a>\n      </div>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="checkbox m-t-30 font-14">\n      <input id="checkterm" type="checkbox" class="styled pointer accept-term-js" data-type="checkbox" ');
        __out.push(__sanitize(this.dwollaCustomer.get('is_dwolla_terms_accepted') ? 'checked' : ''));
        __out.push('>\n      <label for="checkterm">By checking this box, you agree to our partner Dwolla\'s <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/tos/" class="font-bold">Terms and Conditions</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.dwolla.com/legal/privacy/" class="font-bold">Privacy Policy</a></label>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}