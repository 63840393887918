import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IAccountsPayableRelatedJournal } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { TOption } from '@src/components/ui_v2/inputs';
import HeaderRegion from '@src/components/ui_v2/layout/header_region';

import Accrued from './accrued';
import AdvancedSettlement from './advance_settlement';
import Intercompany from './intercompany';

interface IJournalTabsProps {
  relatedJournal: IAccountsPayableRelatedJournal,
}

const JournalTabs = ({ relatedJournal }: IJournalTabsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TOption | null>(null);

  const onTabChange = useCallback((e: React.MouseEvent<HTMLAnchorElement>, tab: TOption) => {
    e.preventDefault();
    setActiveTab({ ...tab });
  }, []);

  const tabs: TOption[] = useMemo(() => {
    const options: TOption[] = [];
    const intercompanyEnabled = window.configData
      .launch_darkly_enabled_global_features
      .includes(window.Docyt.Common.Constants.INTERCOMPANY_AUTOMATION_FLAG);

    if (intercompanyEnabled && relatedJournal.intercompanyAdjustmentEntries.length > 0) {
      options.push({ label: 'Intercompany', value: 'intercompany' });
    }
    if (relatedJournal.accrualAdjustmentEntries.length > 0) {
      options.push({ label: 'Accrued', value: 'accrued' });
    }
    if (relatedJournal.adjustmentEntry) {
      options.push({ label: 'Advanced Settlement', value: 'advance_settlement' });
    }
    return options;
  }, [relatedJournal]);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab((prev) => prev || tabs[0]);
    }
  }, [tabs]);

  return (
    <>
      <div className="related-journal-entries-tabs">
        <HeaderRegion>
          <HeaderRegion.Tabs>
            { tabs.map((tab: TOption) => (
              <HeaderRegion.Tab
                key={ tab.value }
                active={ activeTab?.value === tab.value }
                href={ window.location.pathname + window.location.search }
                title={ tab.label }
                onTabSelected={ (e) => onTabChange(e, tab) }
              />
            )) }
          </HeaderRegion.Tabs>
        </HeaderRegion>
      </div>
      {activeTab ? (
        <div className="related-journal-entries-tabs-content">
          {activeTab.value === 'intercompany' && <Intercompany adjustmentEntries={ relatedJournal.intercompanyAdjustmentEntries } />}
          {activeTab.value === 'accrued' && <Accrued adjustmentEntries={ relatedJournal.accrualAdjustmentEntries } />}
          {activeTab.value === 'advance_settlement' && relatedJournal.adjustmentEntry && <AdvancedSettlement adjustmentEntry={ relatedJournal.adjustmentEntry } />}
        </div>
      ) : null}
    </>
  );
};

export default React.memo(JournalTabs);
