/* eslint-disable eqeqeq */
import React from 'react';

import { clientBusinessDashboard, dashboardPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ModuleHeaderMenu from '../module_header_menu';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  focusToMyClient: boolean,
  info?: IBusinessServicesInfo,
}

const DashboardItem = ({
  businessId,
  activeItem,
  focusToMyClient,
  info,
}: IServiceMenuProps) => {
  let url;
  if (focusToMyClient) {
    url = info?.accountingFirmBusinessId
      ? clientBusinessDashboard(businessId, info?.accountingFirmBusinessId)
      : '';
  } else {
    url = dashboardPath(businessId);
  }
  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DASHBOARD }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DASHBOARD }
      url={ url }
    />
  );
};

export default DashboardItem;
