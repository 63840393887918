import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IInboxDocumentsFilter } from './types';

interface IBusinessInboxFilterProps {
  filter: IFilter<IInboxDocumentsFilter>;
}

const StatusOptions = [
  { value: '', name: 'All States' },
  { value: 'blue_star', name: 'Blue Star' },
  { value: 'yellow_star', name: 'Yellow Star' },
  { value: 'red_star', name: 'Red Star' },
  { value: 'red_flag', name: 'Red Flag' },
];

const BusinessInboxFilter = ({
  filter,
}: IBusinessInboxFilterProps): JSX.Element => {
  return (
    <Filter<IInboxDocumentsFilter> filter={ filter }>
      <Filter.SelectField
        name="status"
        options={ StatusOptions }
        widthPercent={ 20 }
      />
      <Filter.TextField
        name="name"
        placeholder="Filter By Name"
        widthPercent={ 50 }
      />
      <Filter.DateField
        name="inboxDate"
        widthPercent={ 30 }
      />
      <Filter.ResetButton />
    </Filter>
  );
};

export default React.memo(BusinessInboxFilter);
