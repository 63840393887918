import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { revenueServicePathRevenueCapturePath } from '@src/routes';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import Label from '@src/components/ui_v2/labels/label';
import Tooltip from '@src/components/ui_v2/tooltip';
import { AllTransactionsIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ISubmenuRevenueSystemItemProps {
  revenueSystem: IRevenueSystem,
  revenueReportType?: IRevenueReportType,
}

const SubmenuRevenueSystemItem = ({
  revenueSystem,
  revenueReportType,
}: ISubmenuRevenueSystemItemProps) => {
  const business = useBusinessContext();

  const reportTypeName = revenueReportType?.name;

  const reportType = reportTypeName ? (
    <Tooltip.Hover className={ styles['report-type-container'] } content={ reportTypeName }>
      <span className={ styles['revenue-system-report-type'] }>{ reportTypeName }</span>
    </Tooltip.Hover>
  ) : null;

  return (
    <ServiceSidebar.SubMenu
      current={ revenueSystem.id }
      href={ revenueServicePathRevenueCapturePath(business.id, revenueSystem.id) }
      icon={ <AllTransactionsIcon variant="purple" /> }
      subTitle={ (
        <>
          { revenueSystem.isArchived && <Label variant="muted">Archived</Label> }
          { reportType }
        </>
      ) }
      title={ revenueSystem.name }
    />
  );
};

export default SubmenuRevenueSystemItem;
