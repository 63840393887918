import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { useJournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { useGetJournals } from '@src/hooks/queries/joural_entries';
import { useURLParams } from '@src/hooks/url_params';
import { IJournalFilter, TJournalSortColumn } from '@src/types/journal_entries';
import { ISortingParams } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import Item from './item';
import ListFilter from './list_filter';

import styles from './styles.module.scss';

const DefaultSorting: ISortingParams<TJournalSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const getParamsFromFilter = (
  filterData: IJournalFilter | undefined,
): IJournalFilter | undefined => {
  if (!filterData || isEmpty(filterData)) return undefined;
  const filterParams = omit(filterData || {});

  return filterParams;
};

const List: React.FC = () => {
  const businessId = useJournalEntriesContext();

  const { filter, sorting } = useURLParams<
    IJournalFilter,
    TJournalSortColumn
  >({
    businessId,
    section:        window.Docyt.Common.Constants.JOURNAL_TYPES.JOURNAL,
    defaultSorting: DefaultSorting,
  });

  const filterParams = useMemo(() => {
    return getParamsFromFilter(filter.data);
  }, [filter.data]);

  const journalsQuery = useGetJournals({
    filters: filterParams,
    ...sorting.data,
    businessId,
  });

  const journals = useMemo(() => {
    const journalPages = journalsQuery.data?.pages || [];
    return flatten(journalPages.map((p) => p.collection));
  }, [journalsQuery]);

  return (
    <div className={ styles.list }>

      <ListFilter filter={ filter } />

      <Table
        infiniteQuery={ journalsQuery }
        wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
      >
        <Table.Head>
          <Table.Row>
            <Table.HCell<TJournalSortColumn>
              columnName="name"
              sorting={ sorting }
              widthPercent={ 22 }
            >
              Journal Name
            </Table.HCell>
            <Table.HCell<TJournalSortColumn>
              columnName="created_at"
              sorting={ sorting }
              widthPercent={ 22 }
            >
              Import Date
            </Table.HCell>

            <Table.HCell<TJournalSortColumn>
              columnName="management_group_id"
              sorting={ sorting }
              widthPercent={ 22 }
            >
              Management Group
            </Table.HCell>

            <Table.HCell<TJournalSortColumn>
              columnName="description"
              sorting={ sorting }
              widthPercent={ 22 }
            >
              Notes
            </Table.HCell>
            <Table.HCell aria-label="Actions" widthPercent={ 12 } />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {
            journals.map((i) => <Item key={ i.id } i={ i } />)
          }
        </Table.Body>
      </Table>
    </div>
  );
};

export default List;
