module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="width-80px-important text-right pull-right relative">\n  <p></p>\n</div>\n<h2 class="font-26 font-bold m-b-0 m-t-0">Roles and Permissions</h2>\n<hr class="m-t-13">\n<div class="clearfix m-b-30">\n  <div class="width-100-percent">\n    <span class="font-semi-bold font-20">Viewers</span>\n    <span class="badge font-normal bg-grey-180 in-grey-1100 m-r-5 pull-right font-12 m-t-7">View</span>\n  </div>\n  <div class="m-t-0 m-b-0 width-100-percent">\n    <div id="viewers-region" class="">\n    </div>\n  </div>\n</div>\n<div class="clearfix m-b-30">\n  <div class="width-100-percent">\n    <span class="font-semi-bold font-20">Accountants</span>\n    <span class="badge font-normal bg-grey-180 in-grey-1100 m-r-5 pull-right font-12 m-t-7">Reconcile</span>\n    <span class="badge font-normal bg-grey-180 in-grey-1100 m-r-5 pull-right font-12 m-t-7">View</span>\n  </div>\n  <div class="m-t-0 m-b-0 width-100-percent">\n    <div id="accountants-region" class="">\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}