import React from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { formatDate, getUTCTimezone } from '@src/utils/date_helpers';

import styles from '../../styles.module.scss';

interface IStatementInfoProps {
  bankStatement?: IBankStatement,
}

const StatementInfo = ({
  bankStatement,
}: IStatementInfoProps): JSX.Element => {
  return (
    <div className={ styles['statement-info-form'] }>
      <h2 className="font-bold m-t-1 m-b-10">Statement Info</h2>
      <div className={ styles['statement-info-item'] }>
        <span className="width-30-percent in-grey-1100">Bank Account</span>
        <span>{ bankStatement?.reconciliationPaymentAccount?.name }</span>
      </div>
      <div className={ styles['statement-info-item'] }>
        <span className="width-30-percent in-grey-1100">Statement Date</span>
        {
          bankStatement && (
            <span>{ formatDate(getUTCTimezone(new Date(bankStatement.date))) }</span>
          )
        }
      </div>
    </div>
  );
};

export default StatementInfo;
