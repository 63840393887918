import { IInboxService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

const getInboxServiceByBusinessId = (businessId: number): Promise<IInboxService> => {
  return apiGet(
    '/api/v1/inbox_services/get_by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.inbox_service) as IInboxService);
};

export {
  getInboxServiceByBusinessId,
};
