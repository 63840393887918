import { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetFinancialInsights } from '@src/hooks/queries/financial_insights';
import { useSorting } from '@src/hooks/url_params';
import { TSection } from '@src/types/common';
import { IFinancialInsightsEmailSortColumn, IFinancialInsightsCollection, IDashboardItem, IBaseBusinessItem, IFinancialInsightsCollectionParams } from '@src/types/financial_insight';
import { ISortingParams } from '@src/types/sorting';

const defaultSorting: ISortingParams<IFinancialInsightsEmailSortColumn> = {
  orderColumn:    'id',
  orderDirection: 'desc',
};

const useGetFinancialInsightsCollection = ({
  sect,
}: IFinancialInsightsCollectionParams): IFinancialInsightsCollection => {
  const section : TSection = useMemo(() => {
    return { section: sect };
  }, [sect]);

  const sorting = useSorting<IFinancialInsightsEmailSortColumn>({
    section: section.section,
    defaultSorting,
  });

  const query = useGetFinancialInsights({});

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(
      pages.map((p) => p.collection.map((item : IBaseBusinessItem) => {
        // eslint-disable-next-line max-len
        const defaultOption = item.dashboards.find((it : IDashboardItem) => it.financialInsightEmailNotificationEnabled);
        const noneOption = { dashboardId: 'none', dashboardName: 'None', widgetIdentifiers: [], financialInsightEmailNotificationEnabled: false };
        return {
          ...item,
          dashboards: [...item.dashboards, noneOption],
          default:    defaultOption || noneOption,
        };
      })),
    );
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  defaultSorting,
  useGetFinancialInsightsCollection,
};
