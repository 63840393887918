import React, { forwardRef, useCallback } from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { TNumber } from '@src/types/common';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

interface INumberInputProps extends Omit<NumberFormatProps, 'value' | 'onChange'>
{
  value?: TNumber | undefined,
  onChange: (value: TNumber | undefined) => void,
}

const NumberInput = forwardRef<HTMLInputElement, INumberInputProps>(({
  onChange,
  ...props
}: INumberInputProps, ref) => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  const handleChange = useCallback((values) => {
    if (onChange) onChange(values.value);
  }, [onChange]);

  return (
    <NumberFormat
      fixedDecimalScale
      isNumericString
      thousandSeparator
      className={ classes }
      getInputRef={ ref }
      inputMode="numeric"
      { ...inputProps }
      onValueChange={ handleChange }
    />
  );
});

NumberInput.displayName = 'NumberInput';
const MemoizedNumberInput = React.memo(NumberInput);

export {
  INumberInputProps,
  MemoizedNumberInput as default,
};
