import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ThumbsUpIcon } from '@src/components/utils/icomoon';

interface IApproveActionProps {
  onApproveBankStatement: () => void,
}

const ApproveAction = ({
  onApproveBankStatement,
}: IApproveActionProps) => {
  return (
    <ActionsDropdown.Action
      icon={ <ThumbsUpIcon fontSize={ 18 } /> }
      name="Approve"
      onClick={ onApproveBankStatement }
    />
  );
};

export default ApproveAction;
