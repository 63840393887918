module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<ul class="nav nav-sidebar">\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'network' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings">\n      <span class="icon-docyt_network service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n      <span class="nav-item-text">Docyt ID</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'bank_account' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/bank_account">\n      <span class="icon icon-bank service-icon-font inline-flex-wrapper">\n        <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span>\n        <span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span>\n      </span>\n      <span class="nav-item-text">Bank Account</span>\n      ');
    
      if (!this.dwollaCustomer.get('has_funding_source')) {
        __out.push('\n      <i class="icon icon-unable-to-sync in-red-600 font-12"><span class="path1"></span><span class="path2"></span></i>\n      ');
      }
    
      __out.push('\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'w9_form' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/w9_form">\n      <span class="icon-payers service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></span>\n      <span class="nav-item-text">W-9 Form</span>\n    </a>\n  </li>\n  <li class="');
    
      __out.push(__sanitize(this.tab === 'invoices' ? 'active' : ''));
    
      __out.push('">\n    <a href="');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/settings/invoices">\n      <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n      <span class="nav-item-text">Invoices</span>\n    </a>\n  </li>\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}