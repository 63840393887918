module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header">\n        <h1 class="modal-title font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n      </div>\n      <div class=\'modal-body m-b-10\'>\n        <div class="display-table m-t-40 text-center width-100-percent">\n          <label class="display-table-cell m-b-10 m-r-15 width-180px" for="rule-amount-input">If amount is greater than</label>\n          <div class="display-table-cell p-l-15 relative">\n            <input type="text" id="rule-amount-input" class="form-control bg-grey-300" placeholder="Enter Amount">\n          </div>\n        </div>\n        <p class="in-red-400 input-amount-warning m-t-10 m-b-10 pull-right"><i class="fa fa-warning m-r-5"></i>Please input valid amount.</p>\n        <div class="display-table m-t-10 text-center width-100-percent">\n          <label class="display-table-cell m-b-10 m-r-15 width-180px" for="rule-approver-input">Bill has to be approved by</label>\n          <div class="display-table-cell p-l-15 relative approvers-typeahead-wrapper">\n            <input type="text" id="rule-approver-input" class="form-control bg-grey-300" placeholder="Select Approver">\n          </div>\n        </div>\n        <p class="in-red-400 input-approver-warning m-t-10 m-b-10 pull-right"><i class="fa fa-warning m-r-5"></i>Please select approver.</p>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}