import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const NewBankStatementIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="new-bank-statement"
      pathsNumber={ 6 }
    />
  );
};

export default NewBankStatementIcon;
