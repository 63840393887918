module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="m-l-20 m-r-20 m-t-20">\n  <a class="m-t-12 pull-right pointer font-14 font-semi-bold clear-address-js ');
    
      __out.push(__sanitize(this.address_id ? '' : 'inactive'));
    
      __out.push('">\n    <span>Clear Address</span>\n  </a>\n  <a class="cancel font-28 in-black pointer">⇦</a>\n</div>\n<div class="m-t-30 p-l-50 payment-addresses-list p-r-50">\n</div>\n<div class="text-left p-l-50 p-t-20">\n  <a class=\'add-address-js font-14 font-semi-bold in-blue-400 pointer text-decoration-blank\'>Add New Payment Address</a>\n</div>\n<div class="text-center m-t-80">\n  <button type=\'button\' class="btn btn-blue save-js disabled">Select Vendor Payment Address</button>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}