import React, { useCallback, useMemo } from 'react';

import { useGetLinkedBusinesses } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import EditLinkedBusinessesAction from './actions/edit_linked_businesses_action';

import styles from './styles.module.scss';

interface ILikedBusinessesViewProps {
  businessId: TID,
  vendorId: TID,
}

const businessAvatar = (
  business: IBusiness,
) => {
  if (business.avatarUrl === null) {
    return (
      <BusinessDefaultAvatarIcon
        className="m-r-10"
        fontSize={ 24 }
      />
    );
  }

  return (
    <img
      alt="Avatar"
      className={ styles['business-avatar'] }
      src={ business.avatarUrl }
    />
  );
};

const LikedBusinessesView = ({
  businessId,
  vendorId,
}: ILikedBusinessesViewProps): JSX.Element | null => {
  const query = useGetLinkedBusinesses({
    id: businessId,
    vendorId,
  });

  const businesses = useMemo(() => {
    if (!query.data?.businesses) return [];

    return query.data?.businesses;
  }, [query.data?.businesses]);

  const handleDone = useCallback(() => {
    query.refetch();
  }, [query]);

  return (
    <div className={ styles.pane }>
      <div className={ styles.header }>
        <span className="font-semi-bold">Businesses</span>
        <EditLinkedBusinessesAction
          businessId={ businessId }
          linkedBusinesses={ businesses }
          vendorId={ vendorId }
          onDone={ handleDone }
        />
      </div>
      <ul>
        {
          businesses.map((business) => (
            <li
              key={ business.id }
              className={ styles.item }
            >
              { businessAvatar(business) }
              <span>{ business.name }</span>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default LikedBusinessesView;
