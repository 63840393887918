import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IGetAccountsReceivableServiceDocumentsResponse,
} from '@src/requests/accounts_receivable_service_documents';
import {
  IAccountsReceivableServiceDocument,
  TAccountsReceivableServiceDocumentsSortColumn,
} from '@src/types/accounts_receivable_service_documents';
import { ISorting } from '@src/types/sorting';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { ImageAvatar } from '@src/components/ui/avatars';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import ChatCounter from '@src/components/utils/chat_counter';

import Filter from './filter';

interface IAccountsReceivableInvoicesTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAccountsReceivableServiceDocumentsResponse, Error>,
  records: IAccountsReceivableServiceDocument[],
  sorting: ISorting<TAccountsReceivableServiceDocumentsSortColumn>,
}

const customerAvatar = (invoice: IAccountsReceivableServiceDocument) => {
  return <ImageAvatar avatarUrl={ invoice.customerImageUrl } size="100%" />;
};

const invoiceChat = (invoice: IAccountsReceivableServiceDocument) => {
  return (
    <AppLink href={ `/document/${invoice.documentId}/chat` }>
      <ChatCounter messagesCount={ invoice.unreadMessageCount } />
    </AppLink>
  );
};

const invoiceQBOStatus = (invoice: IAccountsReceivableServiceDocument) => {
  return <QBOStatusIcon fontSize={ 20 } model={ invoice } />;
};

const AccountsReceivableInvoicesTable = ({
  infiniteQuery,
  records,
  sorting,
}: IAccountsReceivableInvoicesTableProps) => {
  const handleRowClick = useCallback((invoice: IAccountsReceivableServiceDocument) => {
    Backbone.history.navigate(`/document/${invoice.documentId}`, { trigger: true });
  }, []);

  return (
    <CollectionTable<
        IAccountsReceivableServiceDocument,
        TAccountsReceivableServiceDocumentsSortColumn
      >
      filter={ <Filter /> }
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.TextColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        icon={ customerAvatar }
        name="customer"
        sortColumn="customer"
        title="Customer"
        value={ (invoice) => invoice.displayName }
        width="100%"
      />
      <CollectionTable.TextColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        name="invoice_number"
        sortColumn="invoice_number"
        title="Invoice Number"
        value={ (invoice) => invoice.invoiceNumber }
        width="120px"
      />
      <CollectionTable.DateColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        name="invoice_date"
        sortColumn="bill_date"
        title="Invoice Date"
        value={ (invoice) => invoice.invoiceDate }
        width="120px"
      />
      <CollectionTable.LabelColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        name="status"
        // TODO: add sorting
        title="Status"
        // TODO: Change to status
        value={ (invoice) => invoice.state }
        width="120px"
      />
      <CollectionTable.AmountColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        name="unpaid_amount"
        // TODO: add sorting
        title="Unpaid Amount"
        // TODO: Change to unpaid amount
        value={ (invoice) => invoice.amount }
        width="120px"
      />
      <CollectionTable.AmountColumn<
          IAccountsReceivableServiceDocument,
          TAccountsReceivableServiceDocumentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (invoice) => invoice.amount }
        width="120px"
      />
      <CollectionTable.IconColumn<IAccountsReceivableServiceDocument>
        name="chat"
        title="Chat"
        value={ invoiceChat }
      />
      <CollectionTable.IconColumn<IAccountsReceivableServiceDocument>
        name="qbo_status"
        title="QBO Status"
        value={ invoiceQBOStatus }
      />
    </CollectionTable>
  );
};

export default React.memo(AccountsReceivableInvoicesTable);
