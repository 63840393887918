module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n    <h1 class="share_sign-up-title">Complete your profile</h1>\n    <div class="share-sign-up-container">\n        <div class="row add-credentials-wrapper">\n            <div class="login-form__fieldset relative">\n                <div class="photo-wrapper m-b-8 text-center">\n                    <img class="personal-logo" src="');
    
      __out.push(__sanitize(this.avatarUrl));
    
      __out.push('">\n                </div>\n                <div class="main-input__wrap text-center m-b-40">\n                    <button class="file-input-wrap">\n                        Add Profile Picture\n                        <input type="file" name="files[]" class="logo-file-input">\n                    </button>\n                </div>\n                <div class="main-input__wrap">\n                    <div class="form-group-wrapper">\n                        <label class="input-title-wrap">Full Name*</label>\n                        <input class="form-control user-info-input name-input first-name-input" type="text" placeholder="First Name" value="');
    
      __out.push(__sanitize(this.first_name));
    
      __out.push('">\n                        <p class="first-name-valid-hint-js  in-red-400 sign-up-valid-hint first-name-valid-hint"><i class="fa fa-warning"></i></p>\n                        <input class="form-control user-info-input name-input last-name-input" type="text" placeholder="Last Name" value="');
    
      __out.push(__sanitize(this.last_name));
    
      __out.push('">\n                        <p class="last-name-valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class="main-input__hint invalid-name-and-phone-hint in-red-400 name-invalid">\n                      Your name is required\n                    </p>\n                </div>\n                <div class="main-input__wrap">\n                    <div class="form-group-wrapper">\n                        <label class="input-title-wrap phone-number-input-title">Phone Number</label>\n                        <input class="form-control user-info-input phone-number" type="tel" placeholder="Enter Phone Number">\n                        <p class="phone-valid-hint-js  in-red-400 sign-up-valid-hint"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <p class="main-input__hint invalid-name-and-phone-hint in-red-400 phone-invalid">\n                      Please enter valid phone number\n                    </p>\n                    <p class="main-input__hint invalid-name-and-phone-hint in-red-400 phone-exists">\n                      The phone number you are trying to sign up with belongs to an existing account.\n                    </p>\n                </div>\n            </div>\n            <div class="text-center">\n                <button type=\'submit\' class="create-account-submit-btn ladda-button btn-blue m-t-30 udpate-profile-js" data-color="blue" data-style="expand-right"><span class="ladda-label">Create Account</span></button>\n            </div>\n        </div>\n    </div>\n</div>\n\n\n\n\n\n\n\n\n\n\n\n\n\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}