import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import Header from './header';
import Table from './table';

const JournalEntries: React.FC<{ businessId: TID }> = ({ businessId }) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <DetailsRegion>
          <Header />
          <Table />
        </DetailsRegion>
      </BusinessProvider>
    </Root>
  );
};

export default JournalEntries;
