import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ICheckCircleIconProps extends Omit<IFaIconProps, 'icon'> {
  variant?: 'o',
}

const CheckCircleIcon = ({
  variant,
  ...props
}: ICheckCircleIconProps) => {
  return (
    <FaIcon
      icon={ variant === 'o' ? 'check-circle-o' : 'check-circle' }
      { ...props }
    />
  );
};

export default CheckCircleIcon;
