import React, { useCallback } from 'react';

import { Button } from '@src/components/ui/buttons';

interface IUploadDocumentInputProps {
  onFileSelected?: (file: File) => void,
  onFilesSelected?: (files: File[]) => void,
}

const UploadDocumentInput = ({
  onFileSelected,
  onFilesSelected,
}: IUploadDocumentInputProps) => {
  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length < 1) return;

    if (onFileSelected) onFileSelected(e.target.files[0]);
    if (onFilesSelected) onFilesSelected(Array.from(e.target.files));
  }, [onFileSelected, onFilesSelected]);

  return (
    <Button
      bsStyle="blue-on-hover"
      className="fileinput-button"
      fontSize={ 16 }
    >
      Select from your computer
      <input multiple accept=".pdf" type="file" onChange={ handleFileChange } />
    </Button>
  );
};

export default UploadDocumentInput;
