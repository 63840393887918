import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IGetSelectOptionsParams, IGetSelectOptionsResponse, getSelectOptions } from '@src/requests/select_options';

const useGetSelectOptions = (params:IGetSelectOptionsParams) => {
  return useQuery<IGetSelectOptionsResponse, Error>(
    [QueryKey.selectOptions, params],
    () => getSelectOptions(params as IGetSelectOptionsParams),
    {},
  );
};

export {
  useGetSelectOptions,
};
