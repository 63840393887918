import React from 'react';

import { JournalEntriesContext } from '@src/hooks/contexts/journal_entries_context';
import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Container from './container';

const DetailPage: React.FC<{ businessId: TID, journalId: TID }> = ({ businessId, journalId }) => {
  return (
    <Root>
      <JournalEntriesContext.Provider value={ businessId }>
        <Container journalId={ journalId } />
      </JournalEntriesContext.Provider>
    </Root>
  );
};

export default DetailPage;
