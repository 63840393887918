import React from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';

import styles from './styles.module.scss';

interface IDropdownActionProps extends React.ComponentPropsWithoutRef<typeof Dropdown.Item>
{
  danger?: boolean,
  icon?: JSX.Element,
}

const DropdownAction = ({
  children,
  className,
  danger,
  icon,
  title,
  ...props
}: IDropdownActionProps): JSX.Element => {
  const classes = classNames(className, {
    [styles['actions-dropdown-danger-action']]: danger,
  });

  return (
    <Dropdown.Item className={ classes } { ...props }>
      {
        icon && (
          <Dropdown.ItemIcon>
            { icon }
          </Dropdown.ItemIcon>
        )
      }
      <Dropdown.ItemLabel>
        { title || children }
      </Dropdown.ItemLabel>
    </Dropdown.Item>
  );
};

const MemoizedDropdownAction = React.memo(DropdownAction);

export {
  IDropdownActionProps,
  MemoizedDropdownAction as default,
};
