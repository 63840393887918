import React, { useCallback, useEffect, useRef } from 'react';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

type TOption = {
  name: string,
  value: string | number,
}

interface ISelectPickerInputProps extends Omit<React.ComponentPropsWithoutRef<'select'>, 'onChange'> {
  options: TOption[],
  value?: string,
  onChange: (value?: string) => void,
}

const SelectPickerInput = ({
  options,
  value,
  onChange,
  ...props
}: ISelectPickerInputProps) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const [classes, inputProps] = uiStyleProps(
    'form-control selectpicker',
    props,
  );

  useEffect(() => {
    if (!inputRef?.current) return;

    const input = $(inputRef.current);
    input.selectpicker('refresh');
    input.selectpicker('val', value || '');
  }, [inputRef, value, options]);

  const handleChange = useCallback((e: React.FormEvent<HTMLSelectElement>) => {
    onChange(e.currentTarget.value);
  }, [onChange]);

  return (
    <select
      ref={ inputRef }
      className={ classes }
      { ...inputProps }
      onChange={ handleChange }
    >
      {
        options.map((option) => (
          <option
            key={ option.value }
            value={ option.value }
          >
            { option.name }
          </option>
        ))
      }
    </select>
  );
};

export {
  ISelectPickerInputProps,
  SelectPickerInput as default,
};
