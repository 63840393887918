import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IArrowIconProps extends Omit<IFaIconProps, 'icon'> {
  variant: 'right' | 'up' | 'down',
}

const ArrowIcon = ({
  variant,
  ...props
}: IArrowIconProps): JSX.Element => {
  return <FaIcon icon={ `arrow-${variant}` } { ...props } />;
};

export default ArrowIcon;
