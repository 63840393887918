module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n  <div class="modal-dialog edit-invoice-modal">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="modal-title text-center font-semi-bold">\n          Add Document\n        </h1>\n      </div>\n      <div class="modal-body" style="padding: 0 30px 30px;">\n        <div class="document-drop-zone relative">\n          <div class="text-center width-100-percent p-t-30 p-b-30">\n            ');
    
      if (!this.isUploading) {
        __out.push('\n              <i class="icon icon-data-export-budget action-icon font-30"></i>\n              <p class="m-t-30 font-14 text-center">Drag and drop files here.</p>\n              <p class="m-t-10 font-14 text-center">OR</p>\n              <button class="btn btn-blue-on-hover fileinput-button font-14">\n                Select from your computer\n                <input type="file" name="files[]" class="document-file-input">\n              </button>\n            ');
      } else {
        __out.push('\n              <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n              <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                <div class=\'file-status-line\' style=\'width:0%\'></div>\n              </div>\n            ');
      }
    
      __out.push('\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class="cancel-link cancel pointer display-flex justify-content-end width-100-percent">Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}