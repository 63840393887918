import React from 'react';

import Filter from '@src/components/ui_v2/filter';
import { TOption } from '@src/components/ui_v2/inputs';

const StateOptions: TOption[] = [
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
  },
];

const AccountsReceivableInvoicesFilter = () => {
  return (
    <Filter.TableContainer>
      <Filter.TextField
        label="Customer"
        name="customer"
        placeholder="Customer"
      />
      <Filter.TextField
        label="Invoice Number"
        name="invoiceNumber"
        placeholder="Invoice Number"
      />
      <Filter.DateField
        name="invoiceDate"
      />
      <Filter.SelectField
        label="Status"
        name="state"
        options={ StateOptions }
        placeholder="Status"
      />
      <Filter.AmountField
        name="amount"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(AccountsReceivableInvoicesFilter);
