import React, { useCallback, useMemo } from 'react';

import {
  ISelectInputProps,
  IconSelectInput,
} from '@src/components/ui_v2/inputs';

interface IDataExportTypeInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  value?: string | null,
  onChange?: (value?: string | null) => void,
}

const DataExportTypeInput = ({
  value,
  onChange,
  ...props
}: IDataExportTypeInputProps) => {
  const handleChange = useCallback((newValue) => {
    if (!onChange) return;

    onChange(newValue ? newValue.value : null);
  }, [onChange]);

  const options = useMemo(() => {
    return [
      { label: 'Invoices (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.ALL_INVOICES, subTitle: 'Export a list of all Invoices for the selected period', hideIcon: true },
      { label: 'Invoices (PDF)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.INVOICES_PDF, subTitle: 'Export PDFs of all invoices for the selected period', hideIcon: true },
      { label: 'Receipts (PDF)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.RECEIPTS_PDF, subTitle: 'Export PDFs of all receipts for the selected period', hideIcon: true },
      { label: 'Checks (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.CHECK_REGISTER, subTitle: 'Export a list of all checks for the selected period', hideIcon: true },
      { label: 'Checks (PDF)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.CHECKS_PDF, subTitle: 'Export PDFs of all checks for the selected period', hideIcon: true },
      { label: 'Revenue Reports (PDF)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.REVENUE_REPORTS_PDF, subTitle: 'Export PDFs of all revenue reports for the selected period', hideIcon: true },
      { label: 'Complete Vendors List (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.COMPLETE_VENDORS_REPORT, subTitle: 'Export a list of complete vendor list for the selected period', hideIcon: true },
      { label: 'P&L Chart of Accounts (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.PL_CHART_OF_ACCOUNTS_XLS, subTitle: 'Export a list of all P&L chart of accounts for the selected period', hideIcon: true },
      { label: 'Expense Transactions (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.EXPENSE_TRANSACTIONS, subTitle: 'Export a list of all expense transactions for the selected period', hideIcon: true },
      { label: 'Revenue Accounting (QB Desktop)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.JOURNAL_ENTRIES_IIF, subTitle: 'Export the revenue accounting file supported on Quickbooks Desktop', hideIcon: true },
      { label: 'General Ledger Report (Excel)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.QBO_GL_XLS, subTitle: 'Export General Ledger Report for the selected period', hideIcon: true },
      { label: 'General Ledger Report (PDF)', value: window.Docyt.Common.Constants.DATA_EXPORT_REPORT_TYPES.QBO_GL_PDF, subTitle: 'Export General Ledger Report PDF for the selected period', hideIcon: true }
    ];
  }, []);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    return options.find((type) => type.value === String(value)) || null;
  }, [value, options]);

  return (
    <IconSelectInput
      { ...props }
      options={ options }
      value={ selectedOption }
      onChange={ handleChange }
    />
  );
};

export default React.memo(DataExportTypeInput);
