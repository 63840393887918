import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { Transition } from 'react-transition-group';

import Dialog, { IModalDialogProps } from './dialog';

import './styles.module.scss';

interface IModalProps extends Omit<IModalDialogProps, 'state'> {
  show?: boolean,
  region?: string,
}

const findOrCreateModalContainer = (region: string): HTMLElement => {
  let regionEl = document.getElementById(region);
  if (regionEl) return regionEl;

  regionEl = document.createElement('div');
  regionEl.id = region;
  document.body.appendChild(regionEl);

  return regionEl;
};

/**
 * Root modal dialog component.
 *
 * Use it only to build your custom modal dialog. For typical modal dialog use
 * [Modal.Standard](#/Modal/FormModal) or
 * [Modal.Form](#/Modal/StandardModal) component.
 * @visibleName Modal
 */
const ModalRoot = ({
  show,
  region = 'modal-region',
  ...props
}: IModalProps): JSX.Element => {
  const modalRegionRef = useRef<HTMLElement | null>(null);

  return (
    <Transition
      mountOnEnter
      unmountOnExit
      in={ show }
      timeout={ 150 }
    >
      {
        (state) => {
          if (!modalRegionRef.current) {
            modalRegionRef.current = findOrCreateModalContainer(region);
          }

          return ReactDOM.createPortal(
            <Dialog state={ state } { ...props } />,
            modalRegionRef.current,
          );
        }
      }
    </Transition>
  );
};

export {
  IModalProps,
  ModalRoot as default,
};
