import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useResolveDuplicateBankStatement } from '@src/hooks/queries/bank_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TAmount, TDate, TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { CheckMarkIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './duplicate_statement_form';

import styles from './styles.module.scss';

type TOpenValue = {
  originBankStatement: IBankStatement,
  statementDate?: TDate,
  reconciliationPaymentAccountId?: TID,
  closingBalance?: TAmount,
  duplicateStatementFile: File,
}

const DeleteDuplicatedStatement = 'delete_duplicated_statement';

interface IDuplicateStatementProps extends Omit<IUseModalProps<TOpenValue>, 'onDone'> {
  businessId: TID,
  onDone: (bankStatement?: IBankStatement) => void,
}

const DuplicateStatement = ({
  businessId,
  isOpen,
  openValue,
  onCancel,
  onDone,
}: IDuplicateStatementProps) => {
  const {
    originBankStatement,
    statementDate,
    reconciliationPaymentAccountId,
    closingBalance,
    duplicateStatementFile,
  } = openValue || {};

  const resolveDuplicate = useResolveDuplicateBankStatement();
  const { mutate } = resolveDuplicate;

  const handleResolveDuplicate = useCallback(() => {
    if (!duplicateStatementFile) return;

    mutate(
      {
        businessId,
        reconciliationPaymentAccountId,
        originBankStatementId: originBankStatement?.id,
        date:                  statementDate,
        file:                  duplicateStatementFile,
        closingBalance,
      },
      {
        onSuccess: () => { onDone(); },
      },
    );
  }, [
    mutate,
    businessId,
    reconciliationPaymentAccountId,
    originBankStatement?.id,
    closingBalance,
    statementDate,
    duplicateStatementFile,
    onDone,
  ]);

  const confirmModal = useConfirmDeleteModal({
    onDone: () => handleResolveDuplicate(),
  });

  const handleDone = useCallback((type?: string) => {
    if (type === DeleteDuplicatedStatement) {
      onDone();
      return;
    }

    if (originBankStatement?.state === 'verified') {
      confirmModal.open();
    } else {
      handleResolveDuplicate();
    }
  }, [confirmModal, handleResolveDuplicate, originBankStatement?.state, onDone]);

  return (
    <>
      <MutationStatus mutation={ resolveDuplicate } />
      <confirmModal.Component
        text={ window.Docyt.Common.Constants.Messages.RESOLVE_DUPLICATED_STATEMENT_CONFIRM_MSG }
        title="Warning"
        { ...confirmModal.props }
      />
      <Modal
        showClose
        className={ styles['resolve-possible-duplicate-statement-modal'] }
        show={ isOpen }
        title="Resolve Possible Duplicate"
        onCancel={ onCancel }
      >
        <Modal.Body>
          { isOpen && (
            <Form
              closingBalance={ closingBalance }
              duplicateStatementFile={ duplicateStatementFile }
              originBankStatement={ originBankStatement }
              statementDate={ statementDate }
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            title="Back"
            onClick={ onCancel }
          />
          <div>
            <Button
              bsStyle="link"
              onClick={ () => handleDone() }
            >
              <div className="display-flex align-items-center">
                <CheckMarkIcon pr={ 5 } />
                Use this Statement
              </div>
            </Button>
            <Button
              bsStyle="danger"
              onClick={ () => handleDone(DeleteDuplicatedStatement) }
            >
              Delete Duplicate
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MemoizedDuplicateStatementModal = React.memo(DuplicateStatement);
const useResolveDuplicateStatementModal = makeUseModal<
 typeof MemoizedDuplicateStatementModal, TOpenValue>(MemoizedDuplicateStatementModal);

export {
  IDuplicateStatementProps,
  useResolveDuplicateStatementModal,
  DuplicateStatement as default,
};
