import React, { memo } from 'react';

import classNames from 'classnames';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { AiChatSendButtonIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IAiChatSendButtonProps extends
  React.ComponentPropsWithoutRef<'span'>,
  IUIStyleProps
{
  isActive: boolean;
}

const AiChatSendButton = ({
  isActive,
  ...props
}: IAiChatSendButtonProps) => {
  const buttonClass = classNames(styles['suffix-icon-container'], {
    active: isActive,
  });

  return (
    <span className={ buttonClass } { ...props }>
      <AiChatSendButtonIcon />
    </span>
  );
};

export default memo(AiChatSendButton);
