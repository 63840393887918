import { IBusinessUser, IBusinessUsersFilter } from '@src/types/business_users';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetBusinessUsersParams {
  businessId: TID
  filter?: IBusinessUsersFilter,
  serviceProviderBusinessId?: TID | null,
}

interface IGetBusinessUsersResponse {
  businessUsers: IBusinessUser[]
}

const getBusinessAllUsers = (
  params: IGetBusinessUsersParams,
): Promise<IGetBusinessUsersResponse> => {
  return apiGet(
    '/api/v1/business_users/business_all_members',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetBusinessUsersResponse);
};

const getBusinessUsers = (
  params: IGetBusinessUsersParams,
): Promise<IGetBusinessUsersResponse> => {
  return apiGet(
    '/api/v1/business_users',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetBusinessUsersResponse);
};

interface IGetDocytAccountingAccountManagerParams {
  businessId?: TID
}

interface IGetDocytAccountingAccountManagerResponse {
  docytAccountingAccountManager: IBusinessUser
}

const getDocytAccountingAccountManager = (
  params: IGetDocytAccountingAccountManagerParams,
): Promise<IGetDocytAccountingAccountManagerResponse> => {
  return apiGet(
    '/api/v1/business_users/docyt_accounting_account_manager',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetDocytAccountingAccountManagerResponse);
};

export {
  IGetBusinessUsersParams,
  IGetBusinessUsersResponse,
  IGetDocytAccountingAccountManagerParams,
  IGetDocytAccountingAccountManagerResponse,
  getBusinessAllUsers,
  getBusinessUsers,
  getDocytAccountingAccountManager,
};
