import React, { useState, useEffect } from 'react';

import { Button } from '@src/components/ui/buttons';

import styles from './styles.module.scss';

const AISuggestion: React.FC<{ content: string }> = ({ content }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [more, setMore] = useState(false);

  const showMore = () => setMore(true);
  const hasEllipsis = content.length > 130;

  const displayContent = hasEllipsis ? content.slice(0, 130) : content;

  useEffect(() => {
    if (isTypingComplete) return () => {};

    let textIndex = 0;

    const wordInterval = setInterval(() => {
      if (textIndex < displayContent.length) {
        setDisplayedText((prevText) => prevText + displayContent[textIndex]);
        textIndex += 1;
      } else {
        setIsTypingComplete(true);
        clearInterval(wordInterval);
      }
    }, 50);

    return () => clearInterval(wordInterval);
  }, [displayContent, isTypingComplete]);

  const ellipsisSign = hasEllipsis && !more && (
  <Button className={ styles['ellipsis-sign'] } type="button" onClick={ showMore }>
    …more
  </Button>
  );

  const showContent = more ? content : displayedText;

  return (
    <span>
      { showContent }
      { isTypingComplete ? ellipsisSign : <span className={ styles['ai-cursor'] }>|</span> }
    </span>
  );
};

export default AISuggestion;
