import React from 'react';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IReportItem } from '@src/types/report_service/report_item';

import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import Table from './table';
import { useReportOtherDetailDataCollection } from '../tableHooks';

import styles from '../styles.module.scss';

interface IListProps {
  report: IReport,
  configurations: IReportConfiguration[],
  comparerIds: string[],
  itemIdentifier: IReportItem,
}

const List = ({ report, configurations, comparerIds, itemIdentifier }: IListProps) => {
  const collection = useReportOtherDetailDataCollection(report, itemIdentifier?.identifier);

  return (
    <Section.Provider section={ collection.section }>
      <TableSection className={ styles['report-data-table-section'] }>
        <Table 
          collection={ collection }
          comparerIds={ comparerIds }
          configurations={ configurations }
          itemIdentifier={ itemIdentifier }
          report={ report }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default List;
