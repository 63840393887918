import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import {
  useGetInfiniteRevenueServiceDocument,
} from '@src/hooks/queries/revenue_service_documents';
import { useSorting } from '@src/hooks/url_params';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { TSection } from '@src/types/common';
import {
  IRevenueServiceDocument,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { ISorting, ISortingParams } from '@src/types/sorting';

import { useItemsSelector } from '@src/components/utils_v2/items_selector';

interface IUseUnassignedRSDCollectionParams {
  revenueService: IRevenueService
}

interface IUnassignedRSDCollection {
  query: UseInfiniteQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
  records: IRevenueServiceDocument[],
  section: TSection,
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
  selectedRecords: IRevenueServiceDocument[],
}

const defaultSorting: ISortingParams<TRevenueServiceDocumentsSortColumn> = {
  orderColumn:    'created_at',
  orderDirection: 'desc',
};

const useUnassignedRSDCollection = ({
  revenueService,
}: IUseUnassignedRSDCollectionParams): IUnassignedRSDCollection => {
  const section = useMemo(() => {
    return {
      revenueServiceId: revenueService.id,
      section:          Section.UnassignedRevenue,
    };
  }, [revenueService]);

  const sorting = useSorting<TRevenueServiceDocumentsSortColumn>({
    defaultSorting,
  });

  const query = useGetInfiniteRevenueServiceDocument({
    revenueServiceId: revenueService.id,
    unassigned:       true,
    ...sorting.data,
  });

  const records: IRevenueServiceDocument[] = useMemo(() => {
    const pages = query.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query.data?.pages]);

  const { selected: selectedIds } = useItemsSelector(section);

  const selectedRecords = useMemo(() => {
    return records.filter((r) => selectedIds.includes(r.id));
  }, [selectedIds, records]);

  return {
    query,
    records,
    section,
    sorting,
    selectedRecords,
  };
};

export {
  IUnassignedRSDCollection,
  useUnassignedRSDCollection,
};
