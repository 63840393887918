import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Button from '@src/components/ui/buttons/button';
import Modal from '@src/components/ui/modal';

interface IConfirmDeleteProps extends IUseModalProps {
  confirmTitle?: string,
  confirmStyle?: 'danger' | 'primary',
  icon?: JSX.Element,
  text: JSX.Element | string,
  title: string,
}

const ConfirmDelete = ({
  confirmTitle = 'Proceed',
  confirmStyle = 'danger',
  icon,
  isOpen,
  text,
  title,
  onCancel,
  onDone,
}: IConfirmDeleteProps): JSX.Element => {
  return (
    <Modal
      captureClick
      className="modal-delete"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        {
          icon && (
            <div className="text-center delete-icon-wrapper m-t-30 m-b-30">
              <div className="inline-block relative">
                { icon }
              </div>
            </div>
          )
        }
        <p className="p-b-0 text-center font-16 in-black">{ text }</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link cancel modal-link-btn"
          fontSize={ 18 }
          title="Cancel"
          onClick={ onCancel }
        />
        <Button
          bsSize="xlarge"
          bsStyle={ confirmStyle }
          fontSize={ 16 }
          title={ confirmTitle }
          onClick={ onDone }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useConfirmDeleteModal = makeUseModal(ConfirmDelete);

export {
  IConfirmDeleteProps,
  useConfirmDeleteModal,
  ConfirmDelete as default,
};
