module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="person-content m-t-50">\n  <div class="modal-header">\n    <h4 class="modal-title font-28 font-bold m-t-40 m-b-40">Connect Bank Account</h4>\n  </div>\n  <div class="person-body">\n    <div class="text-left update-profile-container">\n      <div class="profile-content-wrapper font-14">\n        ');
    
      if (this.fundingSource.id) {
        __out.push('\n        <div class="profile-input-wrap m-b-10">\n          <span> Your bank account is connected. This will be used to receive payments through ACH. </span>\n        </div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent font-bold text-left">Bank:</label>\n          <label class="width-50-percent text-left">');
        __out.push(__sanitize(this.fundingSource.get('name')));
        __out.push('</label>\n        </div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent font-bold text-left">Account #:</label>\n          <label class="width-50-percent text-left">**** **** **** ');
        __out.push(__sanitize(this.fundingSource.get('account_last4')));
        __out.push('</label>\n        </div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent font-bold text-left">Routing #:</label>\n          <label class="width-50-percent text-left">');
        __out.push(__sanitize(this.fundingSource.get('routing_number')));
        __out.push('</label>\n        </div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent font-bold text-left">Status:</label>\n          ');
        if (this.fundingSource.get('is_verified')) {
          __out.push('\n          <label class="width-50-percent font-bold text-left in-green-500">Verified</label>\n          ');
        } else {
          __out.push('\n          <label class="width-50-percent font-bold text-left in-red-400">Unverified</label>\n          ');
        }
        __out.push('\n        </div>\n        <div class="profile-input-wrap">\n          <label class="width-40-percent font-bold text-left">Connected since:</label>\n          <label class="width-50-percent text-left">');
        __out.push(__sanitize(this.fundingSource.connectedSince()));
        __out.push('</label>\n        </div>\n        ');
      } else {
        __out.push('\n        <div class="profile-input-wrap m-b-28">\n          <span> Connect your personal bank account to start receiving payments through ACH.</span>\n        </div>\n        <div class="profile-input-wrap font-bold">\n          <button type=\'button\' class="font-14 btn btn-blue secure-add-bank-account-btn width-100-percent">Connect Bank Account</button>\n        </div>\n        <div class="font-10 profile-input-wrap font-bold">\n          <a class="in-green-700" href="">\n            <span class="icon-lockedalt"></span>\n            <span class="inline-block nav-item-text">All connections are encrypted</span>\n          </a>\n        </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n  <div class="person-footer">\n    <a class=\'font-16 cancel-link cancel font-bold pointer\'>Go back</a>\n    <button type="submit" class="font-14 btn btn-red btn-finish ladda-button" data-color="blue" data-style="expand-right" ');
    
      if (!this.fundingSource.id) {
        __out.push('disabled');
      }
    
      __out.push('><span class="ladda-label">Finish</span></button>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}