import { FC, ReactElement } from 'react';

interface WizardStepProps {
  show: boolean;
  children: ReactElement | null;
}

const WizardStep: FC<WizardStepProps> = ({ show, children }) => {
  return show ? children : null;
};

const STEP_MATCH = 1;
const STEP_ACKNOWLEDGE = 2;

export {
  WizardStep as default,
  STEP_MATCH,
  STEP_ACKNOWLEDGE,
};
