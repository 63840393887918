module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="payment-filters">\n  <div class="filter-group payment-filter-customer">\n    <input type="text" class="form-control filter-customer" placeholder="Customer">\n  </div>\n  <div class="input-group filter-group payment-filter-date-range">\n    <input type="text" class="form-control date-picker" placeholder="From Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.transaction_date_start));
    
      __out.push('" data-filterkey="transaction_date_start"/>\n    <span class="input-group-addon">-</span>\n    <input type="text" class="form-control date-picker" placeholder="To Date" autocomplete="off" value="');
    
      __out.push(__sanitize(this.filter.transaction_date_end));
    
      __out.push('" data-filterkey="transaction_date_end"/>\n  </div>\n  <div class="input-group filter-group payment-filter-amount-range">\n    <input type="number" class="form-control filter-min-amount" placeholder="Min Amount" value="');
    
      __out.push(__sanitize(this.filter.amount_min));
    
      __out.push('" />\n    <span class="input-group-addon">-</span>\n    <input type="number" class="form-control filter-max-amount" placeholder="Max Amount" value="');
    
      __out.push(__sanitize(this.filter.amount_max));
    
      __out.push('" />\n  </div>\n</div>\n<table class="table banking-accounts-table data-table">\n  <thead>\n    <tr>\n      <th class="sortable width-40-percent" style="padding-left: 50px !important" data-orderby="customer" data-orderdir="ASC">Customer</th>\n      <th class="sortable width-20-percent" data-orderby="transaction_date" data-orderdir="ASC">Transaction Date</th>\n      <th class="sortable width-20-percent text-right" style="padding-right: 30px !important" data-orderby="amount" data-orderdir="ASC">Amount</th>\n      <th class="actions-column width-20-percent" colspan="2"></th>\n    </tr>\n  </thead>\n  <tbody>\n  </tbody>\n</table>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}