module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="main-view-area">\n  <div class="m-l-20 m-r-20 m-t-20">\n    <a class="cancel pointer pull-right m-t-5 m-l-10 close-icon-btn"><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n    <h4 class=\'font-26 font-bold\'>Edit Customer</h4>\n  </div>\n  <div class=\'content-area\'>\n    <div class="p-l-10 p-r-10">\n      <hr class="vendor_sidebar_line"></hr>\n      <div class="profile-input-wrap approvers-typeahead-wrapper m-t-10">\n        <label class="inline-wrapper in-grey-800 p-r-15 text-right width-200px p-t-6">Customer in Ledger</label>\n        <div class="qbo-vendors-region inline-flex-wrapper width-80-percent">\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="vendor-bottom-view">\n    <button id="btn-submit" class="btn btn-info ladda-button vendor-continue-js" tabindex="10" data-color="blue"">Save</button>\n  </div>\n</div> \n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}