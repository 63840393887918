module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<h2 class="font-26 font-bold">\n    Trash\n    ');
    
      if (this.reportsData.length) {
        __out.push('\n    <div class="width-80px-important text-right pull-right relative">\n      <a class="pointer" id="trash-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class=\'icon icon-dots font-5\'></i>\n      </a>\n      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="trash-action-dropdown-btn">\n        <a class="dropdown-item font-14 font-semi-bold m-t-5 inline-flex-wrapper pointer select-all-link">\n          <i class="icon icon-lists font-20 m-r-10"></i>\n          <p class="m-b-0 p-t-3">Select All</p>\n        </a>\n        <a class="dropdown-item font-14 font-semi-bold m-t-5 inline-flex-wrapper pointer restore-selected-link">\n          <i class="icon icon-restore font-24 m-r-6"></i>\n          <p class="m-b-0 p-t-3">Restore Selected</p>\n        </a>\n        <a class="dropdown-item font-14 font-semi-bold m-t-5 inline-flex-wrapper pointer delete-selected-link in-red-400">\n          <i class="icon icon-new-trash font-20 m-r-10"></i>\n          <p class="m-b-0 p-t-3">Delete Selected</p>\n        </a>\n      </div>\n    </div>\n    ');
      }
    
      __out.push('\n</h2>\n<hr/>\n');
    
      if (this.reportsData.length) {
        __out.push('\n<div class="panel panel-default revenue-report-table-wrapper">\n  <table class="table bg-white">\n    <thead>\n      <tr>\n        <th class="pointer relative" style="width: 50px;"></th>\n        <th class="pointer relative sortable revenue-month-column-header" data-orderby="desc" data-orderdir="ASC">Description</th>\n        <th class="pointer relative sortable revenue-month-column-header" data-orderby="month" data-orderdir="ASC">Month</th>\n        <th class="pointer relative sortable revenue-total-column-header" data-orderby="total" data-orderdir="ASC">Total Revenue</th>\n        <th class="pointer relative" style="width: 50px;"></th>\n      </tr>\n    </thead>\n    <tbody>\n    </tbody>\n  </table>\n</div>\n');
      } else {
        __out.push('\n<div class="revenue-trash-empty-block">\n  <i class="icon icon-Trash-image inline-flex-wrapper">\n    <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>\n    <span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span>\n  </i>\n  <span class="font-18 m-t-20">Trash is Empty. Your deleted revenue reports will appear here.<span>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}