import React, { useCallback, useMemo, useState } from 'react';

import { clientBusinessPath, clientBusinessSettingsPath, clientBusinessSettingsStepPath, clientCenteralPath, documentRequestsPath } from '@src/routes';
import { IClientBusiness } from '@src/types/clients';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';

import styles from '../styles.module.scss';

interface IClientBusinessInfoProps {
  clientBusiness: IClientBusiness;
}

const ClientBusinessInfo = ({
  clientBusiness,
}: IClientBusinessInfoProps) => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  const handleClick = useCallback(() => {
    backboneNavigateToUrl(
      clientBusinessSettingsPath(clientBusiness.clientBusinessId, clientBusiness.businessId),
    );
  }, [clientBusiness.businessId, clientBusiness.clientBusinessId]);

  const isSetupDone = useMemo(() => {
    const setupConfig = clientBusiness.setupConfig || {};
    return clientBusiness.hasQboConnectionHistory
    && setupConfig.profileDone
    && setupConfig.bankingAccountsDone
    && setupConfig.paymentMethodsDone
    && setupConfig.businessTeamDone
    && setupConfig.accountingTeamDone
    && setupConfig.subscriptionPlanDone
    && setupConfig.billingDone
    && setupConfig.documentCollectionDone
    && setupConfig.notificationsAndSettingsDone;
  }, [clientBusiness.hasQboConnectionHistory, clientBusiness.setupConfig]);

  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 30 }
    >
      <div className={ styles['client-business-info'] }>
        <div className={ styles['client-name-wrapper'] }>
          <div className={ styles['client-name-column'] }>
            <a
              className={ styles['client-business-display-name'] }
              href={
                clientBusinessPath(
                  String(clientBusiness.businessId),
                  String(clientBusiness.clientBusinessId),
                )
              }
              role="button"
              tabIndex={ 0 }
            >
              { clientBusiness.displayName }
            </a>
            <a
              className={ styles['client-business-name'] }
              href={
                clientBusinessPath(
                  String(clientBusiness.businessId),
                  String(clientBusiness.clientBusinessId),
                )
              }
              role="button"
              tabIndex={ 0 }
            >
              { clientBusiness.name }
            </a>
          </div>
          {
            clientBusiness.isReviewed && (
              <a
                className="reviewed-status-badge"
                href={
                  sunsetClientCentralEnabled
                    ? documentRequestsPath(clientBusiness.clientBusinessId)
                    : clientCenteralPath(clientBusiness.clientBusinessId)
                }
                role="button"
                tabIndex={ 0 }
              >
                Reviewed
              </a>
            )
          }
          {
            clientBusiness.bookkeepingStatusBlocked && (
              <a
                className="blocked-status-badge"
                href={
                  sunsetClientCentralEnabled
                    ? documentRequestsPath(clientBusiness.clientBusinessId)
                    : clientCenteralPath(clientBusiness.clientBusinessId)
                 }
                role="button"
                tabIndex={ 0 }
              >
                BLOCKED
              </a>
            )
          }
          {
            clientBusiness.needsUpdateBilling && (
              <a
                className="update-billing-badge"
                href={
                  clientBusinessSettingsStepPath(
                    clientBusiness.clientBusinessId,
                    clientBusiness.businessId,
                    9,
                  )
                }
                role="button"
                tabIndex={ 0 }
              >
                Update Billing
              </a>
            )
          }
        </div>
        {
          !isSetupDone && (
            <Button
              className={ styles['finish-setup-btn'] }
              variant="primary"
              onClick={ handleClick }
            >
              Finish Setup
            </Button>
          )
        }
      </div>
    </Table.Cell>
  );
};

export default ClientBusinessInfo;
