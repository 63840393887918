module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var address_state, entity_type, i, index, j, k, len, len1, len2, ref, ref1, standard_category;
    
      __out.push('<form onsubmit="return false;">\n    <div class="profile-input-wrap m-t-30 m-b-10">\n        <label class="width-50-percent text-left">Legal Entity Name</label>\n        <input class="input-entity-name form-control" type="text" placeholder="Enter Legal Entity Name" value="');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n    </div>\n    <div class="profile-input-wrap">\n        <label class="width-50-percent"></label>\n        <p class=\'main-input__hint width-100-percent text-left in-red-400 m-l-5 invalid-name\'><i class="fa fa-warning m-r-5"></i>Please input the business name.</p>\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left">Phone Number</label>\n        <input type="tel" name="phone" class="input-phone form-control" value="" placeholder="Enter Phone Number">\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left">Email</label>\n        <input class="input-email form-control" type="text" placeholder="email@example.com" value="');
    
      __out.push(__sanitize(this.email));
    
      __out.push('">\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left">Legal Entity Address</label>\n        <input class="input-address-street form-control" placeholder="Address Line 1" type="text" value="');
    
      __out.push(__sanitize(this.address_street));
    
      __out.push('">\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left"></label>\n        <input class="input-address-street2 form-control" type="text" placeholder="Address Line 2" value="');
    
      __out.push(__sanitize(this.address_street_2));
    
      __out.push('">\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left"></label>\n        <div class="business-address-wrapper">\n            <div class="profile-input-wrap p-r-5">\n                <input class="input-address-city form-control" type="text" placeholder="City" value="');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('">\n            </div>\n            <div class="profile-input-wrap p-r-5">\n                <select class="input-address-state form-control">\n                    ');
    
      for (i = 0, len = usaStateList.length; i < len; i++) {
        address_state = usaStateList[i];
        __out.push('\n                    <option value="');
        __out.push(__sanitize(address_state.abbreviation));
        __out.push('" ');
        if (this.address_state === address_state.abbreviation) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(address_state.text));
        __out.push('</option>\n                    ');
      }
    
      __out.push('\n                </select>\n            </div>\n            <div class="profile-input-wrap">\n                <input class="input-address-zip form-control" type="text" placeholder="Zip Code" value="');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n            </div>\n        </div>\n    </div>\n    <div class="profile-input-wrap">\n        <label class="width-50-percent"></label>\n        <p class=\'main-input__hint width-100-percent text-left in-red-400 m-l-5 invalid-address\'><i class="fa fa-warning m-r-5"></i>Please input the business address.</p>\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left">Legal Entity Type</label>\n        <select class="form-control business-entity-type-select" placeholder="Select Legal Entity Type">\n            ');
    
      ref = configData.business_entity_types;
      for (j = 0, len1 = ref.length; j < len1; j++) {
        entity_type = ref[j];
        __out.push('\n            <option value="');
        __out.push(__sanitize(entity_type));
        __out.push('" ');
        __out.push(__sanitize(entity_type === this.entity_type ? 'selected' : ''));
        __out.push('\n            data-content="\n            <div class=\'bootstrap-select-normal-item\'>');
        __out.push(__sanitize(entity_type));
        __out.push('</div>\n            ">\n            </option>\n            ');
      }
    
      __out.push('\n        </select>\n    </div>\n    <div class="profile-input-wrap m-b-10">\n        <label class="width-50-percent text-left">Business Type</label>\n        <select class="form-control font-15 business-type-select" placeholder="Select Business Type">\n            ');
    
      if (this.standard_categories) {
        __out.push('\n            ');
        ref1 = this.standard_categories;
        for (index = k = 0, len2 = ref1.length; k < len2; index = ++k) {
          standard_category = ref1[index];
          __out.push('\n            <option value="');
          __out.push(__sanitize(standard_category.id));
          __out.push('" ');
          __out.push(__sanitize(standard_category.id === this.standard_category_id ? 'selected' : ''));
          __out.push('\n            data-content="\n            <div class=\'bootstrap-select-normal-item\'>');
          __out.push(__sanitize(standard_category.name));
          __out.push('</div>\n            ">\n            </option>\n            ');
        }
        __out.push('\n            ');
      }
    
      __out.push('\n        </select>\n    </div>\n    <div class="profile-input-wrap">\n        <label class="width-50-percent"></label>\n        <p class=\'main-input__hint width-100-percent text-left in-red-400 m-l-5 invalid-business-type\'><i class="fa fa-warning m-r-5"></i>Please input the business type.</p>\n    </div>\n    <div class="profile-input-wrap m-b-20">\n        <label class="width-50-percent text-left">EIN Number</label>\n        <input class="input-ein-number form-control" type="text" placeholder="EIN Number" value="');
    
      __out.push(__sanitize(this.ein_number));
    
      __out.push('">\n    </div>\n</form>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}