/* global IDatePickerOptions */

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';

import { TDate } from '@src/types/common';
import {
  DATEPICKER_DATE_FORMAT,
  formatApiDate,
  parseApiDate,
} from '@src/utils/date_helpers';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

interface IDatePickerInputProps extends
  Omit<React.ComponentPropsWithoutRef<'input'>, 'value' | 'onChange'>
{
  options?: Partial<IDatePickerOptions>
  value?: TDate | undefined,
  placeholder? : string,
  onChange?: (value: TDate | undefined) => void,
}

interface IDatePickerInputRef {
  focus: () => void
}

const initDatePicker = (
  element: HTMLInputElement | null,
  onChange: () => void,
  opts: Partial<IDatePickerOptions> = {},
) => {
  if (!element) return;

  const datePicker = $(element).datepicker({
    format:         DATEPICKER_DATE_FORMAT,
    ignoreReadonly: true,
    autoclose:      true,
    ...opts,
  });

  datePicker
    .on('changeDate', onChange)
    .on('hide.bs.modal', (e) => {
      e.stopPropagation();
    });
};

const getDatePickerValue = (element: HTMLInputElement | null): TDate | undefined => {
  if (!element) return undefined;

  const date = $(element).datepicker('getDate');
  if (!date) return undefined;

  return formatApiDate(date);
};

const setDatePickerValue = (element: HTMLInputElement | null, value: TDate | undefined) => {
  if (!element) return;

  const date = parseApiDate(value);
  $(element).datepicker('setDate', date);
};

const DatePickerInput = forwardRef<IDatePickerInputRef, IDatePickerInputProps>(({
  options = {},
  value,
  placeholder,
  onBlur,
  onChange,
  ...props
}: IDatePickerInputProps, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const [classes, inputProps] = uiStyleProps(
    'form-control date-picker',
    props,
  );

  const handleChange = useCallback(() => {
    if (!onChange) return;

    onChange(getDatePickerValue(inputRef.current));
  }, [onChange]);

  const handleBlur = useCallback((e) => {
    if (onChange) {
      onChange(getDatePickerValue(inputRef.current));
    }
    if (onBlur) onBlur(e);
  }, [onBlur, onChange]);

  useLayoutEffect(() => {
    initDatePicker(
      inputRef.current,
      handleChange,
      options,
    );
  }, [handleChange, options]);

  useEffect(() => {
    setDatePickerValue(inputRef.current, value ? String(value) : undefined);
  }, [value]);

  // This handle is required to prevent warning in console
  const fakeHandleChange = useCallback(() => {}, []);

  return (
    <input
      ref={ inputRef }
      autoComplete="off"
      className={ classes }
      placeholder={ placeholder }
      type="text"
      onBlur={ handleBlur }
      onChange={ fakeHandleChange }
      { ...inputProps }
    />
  );
});

DatePickerInput.displayName = 'DatePickerInput';

export {
  IDatePickerInputProps,
  IDatePickerInputRef,
  DatePickerInput as default,
};
