import React from 'react';

import Root from '@src/components/root';

import FinancialInsightsEmail from './financial_insights_email';
import FinancialInsightsEmailSettingsList from './settings_list/list';

const FinancialInsightsEmailPage = () => {
  return (
    <Root>
      <FinancialInsightsEmail />
      <div className="item-divider width-100-percent m-t-12 m-b-12" />
      <FinancialInsightsEmailSettingsList />
    </Root>
  );
};

export default FinancialInsightsEmailPage;
