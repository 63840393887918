import React, { useCallback, useMemo } from 'react';

import flatten from 'lodash/flatten';
import last from 'lodash/last';

import { useGetSubTransactionServiceDocuments } from '@src/hooks/queries/transaction_service_documents';
import { IResolveDuplicateParams } from '@src/requests/transaction_service_documents';
import { TID } from '@src/types/common';
import { IResolveDuplicatedTransactionData } from '@src/types/transaction_service_documents';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import DuplicatedTransactionsTable from './duplicated_transactions_table';

interface IDuplicatedTransactionProps {
  businessId: TID,
  onChange: (
    resolveDuplicateParams: IResolveDuplicateParams[]) => void,
}

const DuplicatedTransactions = ({
  businessId,
  onChange,
}: IDuplicatedTransactionProps) => {
  const filterParams = useMemo(() => {
    return {
      businessId,
      withoutScope:                   true,
      withDuplicateOriginTransaction: true,
      filter:                         { createdType: 'is_duplicated' },
    };
  }, [businessId]);

  const transactionsQuery = useGetSubTransactionServiceDocuments(filterParams);

  const transactionPages = useMemo(() => {
    return transactionsQuery.data?.pages || [];
  }, [transactionsQuery.data]);

  const transactions = useMemo(() => {
    return flatten(transactionPages.map((p) => p.collection));
  }, [transactionPages]);

  const totalCount = useMemo(() => {
    return last(transactionPages)?.meta?.totalCount || 0;
  }, [transactionPages]);
  const ids = useMemo(() => {
    return flatten(transactions).map((t) => t.id);
  }, [transactions]);

  const handleSelect = useCallback((duplicateIds: TID[]) => {
    const notDuplicateIds = ids.filter((item) => !duplicateIds.includes(item));

    const duplicateParams = duplicateIds.map((duplicateId) => {
      const param: IResolveDuplicatedTransactionData = {
        id:      duplicateId,
        docytId: transactions.filter((transaction) => transaction.id === duplicateId)[0]?.docytId,
        type:    'Duplicate',
      };
      return { resolveDuplication: param };
    });

    const notDuplicateParams = notDuplicateIds.map((notId) => {
      const param: IResolveDuplicatedTransactionData = {
        id:      notId,
        docytId: transactions.filter((transaction) => transaction.id === notId)[0]?.docytId,
        type:    'Not Duplicate',
      };
      return { resolveDuplication: param };
    });

    onChange([...duplicateParams, ...notDuplicateParams]);
  }, [ids, onChange, transactions]);

  return (
    <ItemsSelectorProvider allItems={ ids }>
      <div className="page-main resolve-duplication-container">
        <div className="page-main-body">
          <div className="p-b-10">
            <span>
              Below
              {' '}
              { totalCount }
              {' '}
              transactions have been flagged by Docyt as duplicates.
              Review them and uncheck the transactions that are not duplicates.
              Resolving duplicates will delete the potential duplicate copy of the selected
              transactions and unselected transactions will be marked as not duplicates.
            </span>
          </div>
          <div className="banking-accounts-table-wrapper">
            <DuplicatedTransactionsTable
              query={ transactionsQuery }
              transactions={ transactions }
              onSelected={ handleSelect }
            />
          </div>
        </div>
      </div>
    </ItemsSelectorProvider>
  );
};

export default React.memo(DuplicatedTransactions);
