import { TID, TMongoID } from '@src/types/common';
import { underscoreKeys } from '@src/utils/transform_keys';

import { apiPost } from '../helpers';

interface IUpdateDashboardUsersParams {
  dashboardId: TMongoID,
  userIds: TID[],
}

const updateDashboardUsers = (
  params: IUpdateDashboardUsersParams,
): Promise<void> => {
  return apiPost(
    '/dashboards/api/v1/dashboard_users/upsert.json',
    underscoreKeys(params),
  );
};

export {
  IUpdateDashboardUsersParams,
  updateDashboardUsers,
};
