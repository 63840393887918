import React from 'react';

import styles from './styles.module.scss';

interface ISideViewSubtitleProps {
  children: React.ReactNode,
}

const SideViewSubtitle = ({
  children,
}: ISideViewSubtitleProps) => {
  return (
    <h3 className={ styles.subtitle }>
      { children }
    </h3>
  );
};

export default SideViewSubtitle;
