import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { IDocument } from '@src/types/documents';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from './styles.module.scss';

interface IDocFilesDownloadListProps {
  document: IDocument
}

const DocFilesDownloadList = ({
  document,
}: IDocFilesDownloadListProps) => {
  const downloadDocument = useDownloadDocument();

  const { mutate } = downloadDocument;
  const handleDownload = useCallback((fileName, type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentId: document.id, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, fileName) },
    );
  }, [mutate, document.id]);

  const handleOriginalFileSelect = useCallback(() => {
    handleDownload(document.originalFileName, 'original');
  }, [handleDownload, document.originalFileName]);

  const handleExtractedPdfSelect = useCallback(() => {
    handleDownload(document.finalFileKey, 'extracted');
  }, [handleDownload, document.finalFileKey]);

  return (
    <div>
      <MutationStatus mutation={ downloadDocument } />
      <div className={ styles['download-list'] }>
        <h1>
          {
            document.finalFileKey
              ? 'There are 2 reports attached for the Selected day.'
              : 'No files available for download.'
          }
        </h1>
        { document.finalFileKey
          && (
          <ul>
            <li>
              <span className={ styles.filename }>{ document.originalFileName }</span>
              <span className={ styles.link }>
                <Button variant="link" onClick={ handleOriginalFileSelect }>Download</Button>
              </span>
            </li>
            <li>
              <span className={ styles.filename }>{ document.finalFileKey }</span>
              <span className={ styles.link }>
                <Button variant="link" onClick={ handleExtractedPdfSelect }>Download</Button>
              </span>
            </li>
          </ul>
          ) }
      </div>
    </div>
  );
};

export default DocFilesDownloadList;
