import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const BankCircleIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="bank-circle-o"
      pathsNumber={ 14 }
    />
  );
};

export default BankCircleIcon;
