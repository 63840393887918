import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IReportData } from '@src/types/report_service/report_data';
import { IReportItem } from '@src/types/report_service/report_item';

import { searchColumnValueType } from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table';

import Row from './row';
import { useReportItemsBySearchName } from '../../tableHooks';

interface IParentTotalItem {
  itemId: string;
  name: string;
  childItemId: string;
  childItemName: string;
}

interface ITableBodyProps {
  report: IReport;
  items: IReportItem[];
  reportData: IReportData[];
  multiMonthColumns: IReportColumn[];
  ptdColumns: IReportColumn[];
  ytdColumns: IReportColumn[];
  hideZeroRows: boolean;
  budgetsComparison: string;
  forecastComparison: string;
}

const TableBody = ({
  report,
  items,
  reportData,
  multiMonthColumns,
  ptdColumns,
  ytdColumns,
  hideZeroRows,
  budgetsComparison,
  forecastComparison,
}: ITableBodyProps): JSX.Element => {
  const reportItems = useMemo(() => {
    return items.sort((a, b) => a.order - b.order || a.name.localeCompare(b.name));
  }, [items]);
  
  const [parentTotalData, setParentTotalData] = useState<IParentTotalItem[]>([]);
  const searchValue = useRecoilValue(searchColumnValueType);
  useReportItemsBySearchName(reportItems, searchValue);

  const collectParentItem = useCallback((reportDataItem: IReportItem[]) => {
    const totalData: IParentTotalItem[] = [];
    const getData = (reportItem: IReportItem[]) => {
      reportItem.forEach((item) => {
        if (item.childItems.length !== 0) {
          const totalItem: IReportItem[] = [];
          const totalRowName = report.version === 2 ? `Total For ${item.name}` : `Total ${item.name}`;

          for (let i = 0; i < item.childItems.length; i += 1) {
            const row = item.childItems[i];
            
            if (report.version === 2) {
              if (row.aggregation === 'sum' && row.name === totalRowName) {
                totalItem.push(row);
              }
            } else if ((row.totals || row?.showOnMinimized) && row.name === totalRowName) {
              totalItem.push(row);
            }
          }

          totalData.push({
            itemId:        item?.id,
            name:          item?.name,
            childItemId:   totalItem[0]?.id,
            childItemName: totalItem[0]?.name,
          });

          getData(item.childItems);
        }
      });
    };

    getData(reportDataItem);
    setParentTotalData(totalData);
  }, [report.version]);

  useEffect(() => {
    collectParentItem(reportItems);
  }, [collectParentItem, reportItems]);

  // To update the content with items
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setCounter((count) => count + 1);
  }, [reportItems]);

  return (
    <Table.Body key={ `tbody-${counter}` }>
      {reportItems.map((item: IReportItem) => (
        <React.Fragment key={ item.identifier }>
          {item.show && item.search && (
            <Row
              budgetsComparison={ budgetsComparison }
              forecastComparison={ forecastComparison }
              hideZeroRows={ hideZeroRows }
              item={ item }
              multiMonthColumns={ multiMonthColumns }
              parentTotalData={ parentTotalData }
              ptdColumns={ ptdColumns }
              report={ report }
              reportData={ reportData }
              searchValue={ searchValue }
              ytdColumns={ ytdColumns }
            />
          )}
        </React.Fragment>
      ))}
    </Table.Body>
  );
};

export default TableBody;
