import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';

import BusinessChartOfAccountInput from '@src/components/common_v2/inputs/business_chart_of_account_input';
import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import {
  IIconSelectInputProps,
} from '@src/components/ui_v2/inputs';

interface Props<TFilter extends TFilterData> extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  name: keyof TFilter,
  businessId: TID,
}

const ChartOfAccountFilterField = <TFilter extends TFilterData>({
  name,
  businessId,
}: Props<TFilter>) => {
  const [value, update] = useFilterField<TFilter, string | null>(name);

  const handleChange = useCallback((id: number|null, _option: any) => {
    update(id?.toString());
  }, [update]);

  const styles = {
    menu:  (provided: any) => ({ ...provided, minWidth: '400px', zIndex: 1000 }),
    input: (provided: any) => ({ ...provided, minWidth: '350px', margin: 0, fontSize: '14px', paddingTop: 0 }),
  };

  return (
    <BusinessChartOfAccountInput
      businessId={ businessId }
      placeholder="Select Chart of Account"
      styles={ styles }
      value={ Number(value) }
      onChange={ handleChange }
    />
  );
};

export default React.memo(ChartOfAccountFilterField);
