import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IDropdownItemLabelProps extends Omit<React.ComponentPropsWithoutRef<'span'>, 'title'> {
  title?: React.ReactNode,
}

const DropdownItemLabel = ({
  children,
  className,
  title,
  ...props
}: IDropdownItemLabelProps) => {
  const classes = classNames(styles['dropdown-menu-item-label'], className);

  return (
    <span className={ classes } { ...props }>
      { title || children }
    </span>
  );
};

export default DropdownItemLabel;
