/* global BlobPropertyBag */

const createAndDownloadBlobFile = (body: any, fileName: string, opts?: BlobPropertyBag) => {
  const blob = new Blob([body], opts);
  // Browsers that support HTML5 download attribute
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const downloadFile = (fileUrl: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileUrl.split('/').pop() || 'file');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export {
  createAndDownloadBlobFile,
  downloadFile,
};
