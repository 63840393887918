import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IBulkMutationParams,
  IBulkMutationReturn,
  makeBulkRequest,
} from '@src/hooks/queries/bulk_mutations';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import {
  IGetBusinessVendorsParams,
  IGetBusinessVendorsResponse,
  getBusinessVendors,
  getBusinessGlobalVendors,
  IAddToBusinessParams,
  postAddToBusiness,
  deleteFromBusiness,
  IRemoveFromBusinessParams,
  IGlobalVendorWithBusinessInfosResponse,
} from '@src/requests/business_vendors';
import {
  IBusinessVendor,
  IGlobalVendorWithBusinessInfo,
} from '@src/types/business_vendors';

const useGetBusinessVendors = createUseGetInfiniteCollection<
  IBusinessVendor,
  IGetBusinessVendorsParams,
  IGetBusinessVendorsResponse
  >({
    queryKey: QueryKey.businessVendors,
    request:  getBusinessVendors,
  });

const useGetBusinessGlobalVendors = createUseGetInfiniteCollection<
  IGlobalVendorWithBusinessInfo,
  IGetBusinessVendorsParams,
  IGlobalVendorWithBusinessInfosResponse
  >({
    queryKey: QueryKey.businessGlobalVendors,
    request:  getBusinessGlobalVendors,
  });

const useAddToBusiness = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IAddToBusinessParams>(
    postAddToBusiness,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorService);
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

const useBulkAddToBusiness = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IAddToBusinessParams>
    >(
      makeBulkRequest(postAddToBusiness),
      {
        onSettled: () => {
          return queryClient.invalidateQueries(QueryKey.vendorService)
            .then(() => {
              queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
              window.Docyt.vent.trigger('business_vendors:information:updated');
            });
        },
      },
    );
};

const useAddToLinkedBusiness = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    IAddToBusinessParams
    >(
      postAddToBusiness,
      {
        onSettled: () => {
          return queryClient.invalidateQueries(QueryKey.vendorService)
            .then(() => {
              queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
              window.Docyt.vent.trigger('business_vendors:information:updated');
            });
        },
      },
    );
};

const useRemoveFromBusiness = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IRemoveFromBusinessParams>(
    deleteFromBusiness,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.vendorService);
        queryClient.invalidateQueries(QueryKey.businessGlobalVendors);
      },
    },
  );
};

export {
  useGetBusinessVendors,
  useGetBusinessGlobalVendors,
  useAddToBusiness,
  useBulkAddToBusiness,
  useRemoveFromBusiness,
  useAddToLinkedBusiness,
};
