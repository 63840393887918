import React from 'react';

import { ReconciliationPaymentAccountContext } from '@src/hooks/contexts/reconciliation_payment_account_context';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

interface IReconciliationPaymentAccountProviderProps {
  reconciliationPaymentAccount: IReconciliationPaymentAccount,
  children: React.ReactNode,
}

const ReconciliationPaymentAccountProvider = ({
  reconciliationPaymentAccount,
  children,
}: IReconciliationPaymentAccountProviderProps): JSX.Element | null => {
  return (
    <ReconciliationPaymentAccountContext.Provider value={ reconciliationPaymentAccount }>
      { children }
    </ReconciliationPaymentAccountContext.Provider>
  );
};

export default ReconciliationPaymentAccountProvider;
