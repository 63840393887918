import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const NonUploadedStatementIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="non-uploaded-statement"
      pathsNumber={ 3 }
    />
  );
});

NonUploadedStatementIcon.displayName = 'NonUploadedStatementIcon';

export default NonUploadedStatementIcon;
