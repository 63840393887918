import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Button from '@src/components/ui/buttons/button';
import Modal from '@src/components/ui/modal';

interface IConfirmLastFourProps extends IUseModalProps {}

const ConfirmLastFour = ({
  isOpen,
  onCancel,
  onDone,
}: IConfirmLastFourProps): JSX.Element => {
  return (
    <Modal
      className="confirm-last4-modal"
      region="second-modal-region"
      show={ isOpen }
      title="Warning!"
    >
      <Modal.Body>
        <p className="p-b-0 text-center font-16 in-black">
          { window.Docyt.Common.Constants.Messages.BANKING_ACCOUNT_LAST_FOUR_DIGITS_ERROR_MSG }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link cancel modal-link-btn"
          fontSize={ 18 }
          title="Cancel"
          onClick={ onCancel }
        />
        <Button
          bsSize="xlarge"
          bsStyle="primary"
          fontSize={ 16 }
          title="Proceed"
          onClick={ onDone }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useConfirmLastFourModal = makeUseModal(ConfirmLastFour);

export {
  IConfirmLastFourProps,
  useConfirmLastFourModal,
  ConfirmLastFour as default,
};
