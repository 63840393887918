import React, { forwardRef } from 'react';

import styles from '../styles.module.scss';

type TOption = {
  label: string,
  value: string,
  disabled?: boolean,
}

interface IRadioCollectionInputProps extends React.ComponentPropsWithoutRef<'input'> {
  options: TOption[],
  vertical?: boolean,
}

const RadioCollectionInput = forwardRef<HTMLInputElement, IRadioCollectionInputProps>(({
  options,
  vertical,
  ...inputProps
}: IRadioCollectionInputProps, ref): JSX.Element => {
  return (
    <div className={ `${styles['radio-collection-input']} ${vertical && styles['flex-direction-column']}` }>
      { options.map((option) => (
        <div key={ option.value } className="checkbox checkbox-primary checkbox-circle">
          <input
            ref={ ref }
            disabled={ option.disabled }
            id={ `radio_collection_${option.value}` }
            type="radio"
            value={ option.value }
            { ...inputProps }
          />
          <label htmlFor={ `radio_collection_${option.value}` }>
            { option.label }
          </label>
        </div>
      )) }
    </div>
  );
});

RadioCollectionInput.displayName = 'RadioCollectionInput';

export default React.memo(RadioCollectionInput);
