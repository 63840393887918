import React from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { UseQueryResult } from 'react-query';

import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import Table from '@src/components/ui_v2/table';

import { IReportLineItems } from './schema';
import Item from './table_item';

interface IResolveRevenueReportTableProps {
  query: UseQueryResult<IRevenueServiceDocument, Error>;
}

const ResolveRevenueReportTable = ({
  query,
}: IResolveRevenueReportTableProps) => {
  const { control } = useFormContext<IReportLineItems>();
  const { fields: itemFields } = useFieldArray({ control, name: 'lineItems' });

  return (
    <form>
      <Table query={ query }>
        <Table.Head>
          <Table.Row>
            <Table.HCell
              width="25%"
            >
              Report Line Items
            </Table.HCell>
            <Table.HCell
              width="25%"
            >
              Account Name
            </Table.HCell>
            <Table.HCell
              width="22%"
            >
              Department
            </Table.HCell>
            <Table.HCell
              width="14%"
            >
              Debit
            </Table.HCell>
            <Table.HCell
              width="14%"
            >
              Credit
            </Table.HCell>
          </Table.Row>

        </Table.Head>
        <Table.Body>
          {
            itemFields.map((item, index) => (
              <Item
                key={ item.revenueReportTypeCategoryId }
                index={ index }
                item={ item }
              />
            ))
          }
        </Table.Body>
      </Table>
    </form>
  );
};

export default React.memo(ResolveRevenueReportTable);
