import React, { useCallback } from 'react';

import { useDeleteDataExport } from '@src/hooks/queries/data_exports';
import { IDataExport } from '@src/types/data_exports';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDataExportItemDeleteActionProps {
  dataExport: IDataExport,
}

const DataExportItemDeleteAction = ({
  dataExport,
}: IDataExportItemDeleteActionProps): JSX.Element => {
  const destroy = useDeleteDataExport();

  const { mutate } = destroy;
  const handleConfirm = useCallback(() => {
    mutate(
      { dataExportId: dataExport.id },
    );
  }, [dataExport, mutate]);

  const confirm = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ destroy } successMessage="Deleted successfully." />
      <confirm.Component
        { ...confirm.props }
        text={ window.Docyt.Common.Constants.Messages.REMOVE_DATA_EXPORT_CONFIRM_MSG }
        title="Remove Data Export"
      />
      <ActionsDropdown.DangerAction
        name="Delete"
        onClick={ confirm.open }
      />
    </>
  );
};

export default DataExportItemDeleteAction;
