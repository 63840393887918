import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IManagementGroup } from '@src/types/management_groups';

import {
  IManagementGroupInput,
  managementGroupValidation,
} from '@src/components/management_groups/modals/schema';
import Form from '@src/components/ui/form/form';

import styles from '../styles.module.scss';

interface IManagementGroupModalProps {
  managementGroup?: IManagementGroup,
  formId?: string,
  onSubmit: SubmitHandler<IManagementGroupInput>,
}

const EditManagementGroupModalForm = ({
  managementGroup,
  formId,
  onSubmit,
}: IManagementGroupModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IManagementGroupInput>({
    defaultValues: {
      name: managementGroup?.name,
    },
    resolver: yupResolver(managementGroupValidation),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ styles['management-group-form'] }>
        <Form.TextField
          { ...register('name') }
          autoFocus
          autoComplete="off"
          error={ errors.name?.message }
          label="Management Group Name"
          maxLength={ 80 }
          placeholder="Enter Management Group Name"
        />
      </div>
    </Form>
  );
};

export {
  IManagementGroupModalProps,
  EditManagementGroupModalForm as default,
};
