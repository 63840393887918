import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';

import Payments from './payments';

interface IAccountsReceivablePaymentsPageProps {
  businessId: TID,
}

const AccountsReceivablePaymentsPage = ({
  businessId,
}: IAccountsReceivablePaymentsPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <Payments />
      </BusinessProvider>
    </Root>
  );
};

export default AccountsReceivablePaymentsPage;
