import React, { Children, useMemo } from 'react';

import classNames from 'classnames';

import ActionsArea from './actions_area';
import Breadcrumbs from './breadcrumbs';
import Tabs from './tabs';
import Title from './title';

import styles from './styles.module.scss';

interface IHeaderRegionProps extends React.ComponentPropsWithoutRef<'div'> {}

const HeaderRegion = ({
  children,
  className,
  ...props
}: IHeaderRegionProps) => {
  const classes = classNames(styles['header-region'], className);

  const [tabsContent, breadcrumbsContent, actionsAreaContent, titleContent] = useMemo(() => {
    let tabs = null;
    let breadcrumbs = null;
    let actionsArea = null;
    let title = null;

    Children.forEach(children, child => {
      if (child && typeof child === 'object' && 'type' in child) {
        if (child.type === Tabs) {
          tabs = child;
        } else if (child.type === Breadcrumbs) {
          breadcrumbs = child;
        } else if (child.type === ActionsArea) {
          actionsArea = child;
        } else if (child.type === Title) {
          title = child;
        } else {
          throw new Error(
            'Only "HeaderRegion.Tabs", "HeaderRegion.Breadcrumbs", "HeaderRegion.ActionsArea", "HeaderRegion.Title" and "HeaderRegion.BulkActions" components are allowed as children of "HeaderRegion"'
          );
        }
      }
    });

    if (tabs && title) {
      throw new Error(
        '"HeaderRegion.Tabs" and "HeaderRegion.Title" components cannot be used at the same.'
      );
    }

    return [tabs, breadcrumbs, actionsArea, title];
  }, [children]);

  return (
    <div className={ classes } { ...props }>
      { breadcrumbsContent && (
        <div className={ styles['breadcrumbs-content'] }>
          {breadcrumbsContent}
        </div>
      ) }
      
      { (tabsContent || actionsAreaContent || titleContent) && (
        <div className={ styles['action-content'] }>
          { titleContent }
          { tabsContent }
          { actionsAreaContent }
        </div>
      ) }
    </div>
  );
};

export default React.memo(HeaderRegion);
