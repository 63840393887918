import React, { useCallback, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ISideViewRegionProps {
  children: React.ReactNode;
  size?: 'normal' | 'compact',
}

const SideViewRegion = ({
  children,
  size = 'normal',
}: ISideViewRegionProps): JSX.Element => {
  const regionRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!regionRef.current) return;

    const rect = regionRef.current.getBoundingClientRect();

    regionRef.current.style.minHeight = `calc(100vh - ${rect.top}px`;
  }, []);

  useLayoutEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  const classes = classNames(
    styles['right-side-view-region'],
    { [styles['right-side-view-region-compact']]: size === 'compact' },
  );

  return (
    <div
      ref={ regionRef }
      className={ classes }
    >
      { children }
    </div>
  );
};

export default React.memo(SideViewRegion);
