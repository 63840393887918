import React from 'react';

import Filter from '@src/components/ui_v2/filter';

const AccountsReceivablePaymentsFilter = () => {
  return (
    <Filter.TableContainer>
      <Filter.TextField
        label="Customer"
        name="customer"
        placeholder="Customer"
      />
      <Filter.DateField name="paymentDate" />
      <Filter.AmountField name="amount" />
    </Filter.TableContainer>
  );
};

export default React.memo(AccountsReceivablePaymentsFilter);
