module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var approver, i, index, len, ref;
    
      __out.push('<td class="relative">\n  <div class="rule-condition-wrapper ');
    
      __out.push(__sanitize(this.is_editing && this.rule_type !== 'basic_rule' && this.hasRuleCondition ? 'editing' : ''));
    
      __out.push('">\n    ');
    
      if (this.rule_type === 'new_rule' && this.is_editing || this.rule_type !== 'new_rule') {
        __out.push('\n      ');
        if (this.hasRuleCondition) {
          __out.push('\n        ');
          if (this.currentRuleType !== 'department_rule') {
            __out.push('\n        <div class="small-rule-logo ');
            __out.push(__sanitize(this.currentRuleType !== 'amount_rule' ? '' : 'bg-green-900'));
            __out.push('">\n          ');
            if (this.currentRuleType === 'vendor_rule') {
              __out.push('\n            ');
              if (this.vendorAvatarUrl) {
                __out.push('\n            <img src="');
                __out.push(__sanitize(this.vendorAvatarUrl));
                __out.push('" class="vertical-align-top">\n            ');
              } else {
                __out.push('\n            <i class="icon icon-smile in-blue-400 font-10"></i>\n            ');
              }
              __out.push('\n          ');
            } else {
              __out.push('\n            ');
              if (this.currentRuleType === 'basic_rule') {
                __out.push('\n              <span class="icon-accounts-payable font-30"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span></span>\n            ');
              } else {
                __out.push('\n              <span class="font-18 font-semi-bold in-white">$</span>\n            ');
              }
              __out.push('\n          ');
            }
            __out.push('\n        </div>\n        <span class="font-14 m-l-19">\n          ');
            if (this.currentRuleType === 'basic_rule') {
              __out.push('\n          All Invoices ( Excluding matches from advanced approval rules )\n          ');
            } else if (this.currentRuleType === 'vendor_rule') {
              __out.push('\n          ');
              __out.push(__sanitize(this.vendor.name));
              __out.push('\n          ');
            } else {
              __out.push('\n          ');
              __out.push(__sanitize(this.amountCondition));
              __out.push('\n          ');
            }
            __out.push('\n        </span>\n        ');
          } else {
            __out.push('\n        <span class="font-14">\n          ');
            __out.push(__sanitize(this.accounting_class.name));
            __out.push('\n        </span>\n        ');
          }
          __out.push('\n        ');
          if (this.is_editing && this.rule_type !== 'basic_rule') {
            __out.push('\n        <a class="font-14 font-semi-bold m-l-21 pointer edit-condition-js">Edit</a>\n        ');
          }
          __out.push('\n      ');
        } else {
          __out.push('\n        <div class="relative">\n          <button class="btn-add-rule-detail dropdown-toggle" id="rule-condition-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">Add Condition</button>\n          <div class="dropdown-menu dropdown-menu-round-border" aria-labelledby="rule-condition-dropdown-btn">\n            <a class="dropdown-item font-14 add-vendor-condition-js pointer">Vendor</a>\n            <a class="dropdown-item font-14 add-amount-condition-js pointer">Transaction Amount</a>\n            <a class="dropdown-item font-14 add-department-condition-js pointer">Department</a>\n          </div>\n        </div>\n      ');
        }
        __out.push('\n    ');
      } else {
        __out.push('\n      <div class="add-link-wrapper">\n        <a class="add-rule-link add-special-rule-js">\n          <i class="icon icon-o-plus font-20 font-semi-bold"></i>\n          <span class="m-l-14 font-14 font-semi-bold">Add Special Rule</span>\n        </a>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td>\n  ');
    
      if (this.rule_type === 'new_rule' && (this.approvers && this.approvers.length > 0 || this.approvers_selected) || this.rule_type !== 'new_rule') {
        __out.push('\n  <div class="select-approvers-wrapper ');
        __out.push(__sanitize(this.is_editing ? 'editing' : ''));
        __out.push(' ');
        __out.push(__sanitize(this.approvers && this.approvers.length > 0 ? 'has-approvers' : ''));
        __out.push('">\n    ');
        if (!this.approvers || this.approvers.length === 0) {
          __out.push('\n    <span class="font-14">All Approvers</span>\n    ');
        } else {
          __out.push('\n      ');
          ref = this.approvers;
          for (index = i = 0, len = ref.length; i < len; index = ++i) {
            approver = ref[index];
            __out.push('\n        ');
            if (index < 5) {
              __out.push('\n        <div class="avatar-wrapper">\n          ');
              if (approver.avatar && approver.avatar.avatar_url) {
                __out.push('\n          <img src="');
                __out.push(__sanitize(approver.avatar.avatar_url));
                __out.push('"/>\n          ');
              } else {
                __out.push('\n          <i class="icon icon-smile in-blue-400 font-33"></i>\n          ');
              }
              __out.push('\n        </div>\n        ');
            }
            __out.push('\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    ');
        if (this.is_editing) {
          __out.push('\n    <a class="font-14 font-semi-bold m-l-23 pointer edit-approvers-js">Edit</a>\n    ');
        }
        __out.push('\n  </div>\n  ');
      } else {
        __out.push('\n    ');
        if (this.is_editing) {
          __out.push('\n      <a class="btn-add-rule-detail add-approvers-js">Add Approvers</a>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</td>\n<td>\n  <div class="approvers-dropdown-wrapper relative  ');
    
      __out.push(__sanitize(this.is_editing ? 'editing' : ''));
    
      __out.push('">\n    ');
    
      if (this.rule_type === 'new_rule' && this.is_editing || this.rule_type !== 'new_rule') {
        __out.push('\n    <button class="pointer dropdown-toggle approvers-menu-toggle-btn" type="button" id="rule-');
        __out.push(__sanitize(this.id));
        __out.push('-approver-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      ');
        __out.push(__sanitize(this.basic_operator === 'and' ? 'All' : 'Any'));
        __out.push(' selected approvers\n    </button>\n    <div class="dropdown-menu dropdown-menu-right dropdown-menu-round-border" aria-labelledby="rule-');
        __out.push(__sanitize(this.id));
        __out.push('-approver-dropdown-btn">\n      <a class="dropdown-item font-14 any-approvers-operator-js pointer">\n        <span class="font-14">Any selected approvers</span>\n      </a>\n      <a class="dropdown-item font-14 all-approvers-operator-js pointer">\n        <span class="font-14">All selected approvers</span>\n      </a>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="relative">\n  ');
    
      if (!(this.is_editing || this.rule_type === 'new_rule')) {
        __out.push('\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="rule-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="rule-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n    <a class="dropdown-item font-14 edit-rule-js pointer display-flex">\n      <i class="fa fa-edit font-20"></i>\n      <span class="font-14 m-l-5">Edit Rule</span>\n    </a>\n    ');
        if (this.rule_type !== 'basic_rule') {
          __out.push('\n    <a class="dropdown-item font-14 delete-rule-js in-red-400 pointer display-flex">\n      <i class="icon icon-new-trash font-18"></i>\n      <span class="font-14 m-l-5">Delete Rule</span>\n    </a>\n    ');
        }
        __out.push('\n  </div>\n  ');
      } else {
        __out.push('\n    ');
        if (this.is_editing) {
          __out.push('\n    <button type=\'button\' class="btn btn-blue ladda-button done-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label font-16 font-semi-bold">Done</span></button>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}