import moment from 'moment';
import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { getReconciliationDateRange } from '@src/components/reconciliation_center/month_end/utils';

const useJournalDataHandling = () => {
  const business = useBusinessContext();
  const businessId = business.id;
  const baReconciliation = useBankAccountReconciliationContext();
  const queryClient = useQueryClient();
  const bankStatement = useBankStatementContext();
  const [startDate, endDate] = getReconciliationDateRange(baReconciliation, bankStatement);

  const additionalParams = {
    reconciliationId:        baReconciliation.id,
    balanceSheetAccountId:   baReconciliation.reconciliationPaymentAccountId,
    balanceSheetAccountType: 'ReconciliationPaymentAccount',
  };

  const onSuccess = async () => {
    const section = [QueryKey.bankAccountReconciliation, baReconciliation.id];
    await queryClient.invalidateQueries(section);
  };

  const dateRange = [moment(startDate).toDate(), moment(endDate).toDate()];

  return { businessId,
    onSuccess,
    additionalParams,
    balanceSheetAccount: baReconciliation.balanceSheetAccount,
    dateRange };
};

export default useJournalDataHandling;
