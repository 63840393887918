import React from 'react';

import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import DeleteAction from './delete_action';
import RestoreAction from './restore_action';

interface ITransactionActionsMenuProps {
  businessId: TID,
  transaction: ITransactionServiceDocument,
}

const TransactionActionsMenu = ({
  businessId,
  transaction,
}: ITransactionActionsMenuProps) => {
  return (
    <ActionsDropdown widthPercent={ 25 }>
      <RestoreAction businessId={ businessId } transaction={ transaction } />
      <DeleteAction isTrash transaction={ transaction } />
    </ActionsDropdown>
  );
};

export default TransactionActionsMenu;
