import React from 'react';

import { useEditFinancialInsightsModal } from '../edit_modal/edit_modal';

interface IEditFinancialInsightsEmailActionProps
{
  frequency: string,
}

const EditFinancialInsightsEmailAction = ({
  frequency,
}: IEditFinancialInsightsEmailActionProps) => {
  const edit = useEditFinancialInsightsModal();

  return (
    <div>
      <edit.Component
        { ...edit.props }
        frequency={ frequency }
      />
      <a
        className="font-14 font-bold pointer"
        role="button"
        tabIndex={ -1 }
        onClick={ edit.open }
      >
        Edit
      </a>
    </div>
  );
};

export default EditFinancialInsightsEmailAction;
