import React from 'react';

import { TID } from '@src/types/common';

import { IMarkAsPaidFormValues } from '@src/components/accounts_payable/review_payment/bulk_payment_modals/mark_as_paid/schema';

import PayByAdvancedSettelmentForm from './advanced_settlement_form';
import PayByBankAccountForm from './bank_account_form';
import PayByCashForm from './cash_form';

interface IMarkAsPaidFormProps {
  formId?: string,
  businessId: TID,
  totalAmount?: number,
  type?: string,
  onSubmit: (data: IMarkAsPaidFormValues) => void,
}

const MarkAsPaidForm = ({
  formId,
  businessId,
  type,
  totalAmount,
  onSubmit,
}: IMarkAsPaidFormProps) => {
  if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH) {
    return (
      <PayByCashForm
        formId={ formId }
        onSubmit={ onSubmit }
      />
    );
  }

  if (type && [window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CREDIT_CARD,
    window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_BANK_ACCOUNT].includes(type)) {
    return (
      <PayByBankAccountForm
        businessId={ businessId }
        formId={ formId }
        type={ type }
        onSubmit={ onSubmit }
      />
    );
  }

  if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_ADVANCE_SETTLEMENT) {
    return (
      <PayByAdvancedSettelmentForm
        businessId={ businessId }
        formId={ formId }
        totalAmount={ totalAmount }
        onSubmit={ onSubmit }
      />
    );
  }

  return null;
};

export {
  IMarkAsPaidFormProps,
  MarkAsPaidForm as default,
};
