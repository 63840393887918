import React, { forwardRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITextInputProps extends
  React.ComponentPropsWithoutRef<'input'>,
  IUIStyleProps
{
}

const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(({
  defaultValue,
  ...props
}: ITextInputProps, ref): JSX.Element => {
  const [classes, inputProps] = uiStyleProps('form-control', props);

  return (
    <input
      ref={ ref }
      className={ classes }
      defaultValue={ defaultValue }
      { ...inputProps }
    />
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
