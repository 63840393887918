module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, id;
    
      __out.push('<div class="setup-business-step-section setup-ar-section">\n  <div class="main-panel">\n    <div class="profile-header">\n      <div class="align-items-center display-flex justify-content-between">\n        <h2 class="panel-title">Verify Email Address</h2>\n        ');
    
      if (!this.is_email_verified) {
        __out.push('\n        <a class="font-14 font-semi-bold pointer edit-email-js">Edit Email</a>\n        ');
      }
    
      __out.push('\n      </div>\n      <p class="panel-description">\n        ');
    
      if (!this.is_email_verified) {
        __out.push('\n        Enter the 6-digit verification code that has been sent to <br/><b>');
        __out.push(__sanitize(this.email));
        __out.push('</b>\n        ');
      }
    
      __out.push('\n      </p>\n    </div>\n    <div class="profile-body">\n      ');
    
      if (!(this.is_email_verified && this.email_access_token)) {
        __out.push('\n      <div class="sign-in-main-panel">\n        <div class="form-group-wrapper email-confirm-code-input">\n          ');
        for (id = i = 0; i <= 5; id = ++i) {
          __out.push('\n            <input class="form-control email-confirm-code-input-item code-input" type="number" data-index="');
          __out.push(__sanitize(id));
          __out.push('" maxlength="1" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">\n          ');
        }
        __out.push('\n        </div>\n        <div class="email-confirm-code-input-wrap">\n          <p class=\'main-input__hint in-red-400\' id="invalid-code">You’ve entered an invalid verification code. Please try again.</p>\n          <a class="font-14 font-semi-bold pointer resend-code-js resend-verification-code-link">Resend Code</a>\n        </div>\n      </div>\n      ');
      } else {
        __out.push('\n      <div class="add-photo-wrapper text-center">\n        <span class="icon icon-success font-24"><span class="path1"></span><span class="path2"></span></span>\n        <p>Your email has been verified.<br/>Proceed to add ACH information to connect to your bank account.</p>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}