module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'header__notifications-item\'>\n  ');
    
      if (this.notifiable && this.entityUrl) {
        __out.push('\n    <a href="');
        __out.push(__sanitize(this.entityUrl));
        __out.push('" class="header__notifications-item-link"></a>\n  ');
      }
    
      __out.push('\n  <div class=\'header__notifications-icon\'>\n    <img src="');
    
      __out.push(configData.icons.BOT);
    
      __out.push('"></img>\n  </div>\n  <div class=\'header__notifications-message\'>\n    <p class=\'header__notifications-message-about\'>\n      ');
    
      __out.push(__sanitize(this.message));
    
      __out.push('\n    </p>\n    <p class=\'header__notifications-message-whom\'>\n      DocytBot\n    </p>\n  </div>\n  <div class=\'header__notifications-date\'>\n    <p>');
    
      __out.push(__sanitize($.timeago(this.created_at)));
    
      __out.push('</p>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}