import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetDocuments } from '@src/hooks/queries/documents';
import { useSorting } from '@src/hooks/url_params';
import { TID } from '@src/types/common';
import { TDocumentsSortColumn } from '@src/types/documents';
import { ISortingParams } from '@src/types/sorting';
import { IStandardDocument, IStandardDocumentField } from '@src/types/standard_documents';

import List from './list_item/list';

interface IDocumentsTableProps {
  businessId?: TID,
  standardDocument: IStandardDocument,
  unEncryptedStandardDocumentFields: IStandardDocumentField[],
}
const DefaultSorting: ISortingParams<TDocumentsSortColumn> = {};

const DocumentsTable = ({
  businessId,
  standardDocument,
  unEncryptedStandardDocumentFields,
}:IDocumentsTableProps): JSX.Element => {
  const sorting = useSorting<TDocumentsSortColumn>({ defaultSorting: DefaultSorting });
  const documentsQuery = useGetDocuments({
    businessId,
    ownDocuments:       !businessId,
    standardDocumentId: standardDocument.id,
    ...sorting.data,
  });

  const documents = useMemo(() => {
    const documentPages = documentsQuery.data?.pages || [];
    return flatten(documentPages.map((p) => p.collection));
  }, [documentsQuery.data?.pages]);

  return (
    <div className="documents-table-wrapper service-detail-view">
      <List
        documents={ documents }
        query={ documentsQuery }
        sorting={ sorting }
        unEncryptedStandardDocumentFields={ unEncryptedStandardDocumentFields }
      />
    </div>
  );
};

export {
  IDocumentsTableProps,
  DocumentsTable as default,
};
