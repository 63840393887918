import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const NoteTabletIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="note-tablet"
      pathsNumber={ 1 }
    />
  );
});

NoteTabletIcon.displayName = 'NoteTabletIcon';

export default NoteTabletIcon;
