import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import Dropdown from '@src/components/ui/dropdown';
import { PayeeProfileIcon } from '@src/components/utils/icomoon';

interface IDocytNetworkItemViewProfileActionProps {
  vendor: IGlobalVendorWithBusinessInfo,
}

const DocytNetworkItemViewProfileAction = ({
  vendor,
}: IDocytNetworkItemViewProfileActionProps): JSX.Element => {
  const business = useBusinessContext();

  const handleConfirm = useCallback(() => {
    Backbone.history.navigate(
      `/businesses/${business.id}/vendor_service/payees/${vendor.id}`,
      { trigger: true },
    );
  }, [vendor.id, business.id]);

  return (
    <Dropdown.Item onSelect={ handleConfirm }>
      <PayeeProfileIcon mr={ 22 } />
      View Vendor Profile
    </Dropdown.Item>
  );
};

export default DocytNetworkItemViewProfileAction;
