import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DocytLogoIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="docyt-logo-1"
      pathsNumber={ 6 }
    />
  );
};

export default DocytLogoIcon;
