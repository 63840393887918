module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'header__notifications-spinner\' id=\'notification-spinner\'>\n  <i class=\'fa fa-spinner fa-pulse fa-4x fa-fw\'></i>\n</div>\n<div><a class="btn pull-right mark-all-as-read">Mark all as read</a></div>\n<div class="notifications-list display-table">\n</div>\n');
    
      if (Docyt.currentAdvisor.get('notifications_count') > 0) {
        __out.push('\n<div class="p-b-16 p-t-16 text-center all-notifications-link">\n    <a class="font-14 font-bold" href="/my_notifications">\n        View All Notifications (');
        __out.push(__sanitize(Docyt.currentAdvisor.get('notifications_count')));
        __out.push(')\n    </a>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}