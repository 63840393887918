import { TOption } from '@src/components/ui_v2/inputs';

import { PaymentMethodLabel } from './payment_method_label';
import { PaymentStatus } from './payment_status_variant';

const vendorCredit = 'vendor_credit';
const successPayment = 'success';
const processedForDelivery = 'Processed for Delivery';

export const PaymentMethodOptions: TOption[] = [
  {
    value: window.configData.ap_payment_modes.DOCYT_ACH,
    label: PaymentMethodLabel.PAYMENT_DOCYT_ACH,
  },
  {
    value: window.configData.ap_payment_modes.DOCYT_CHECK,
    label: PaymentMethodLabel.PAYMENT_DOCYT_CHECK,
  },
  {
    value: window.configData.ap_payment_modes.CASH,
    label: PaymentMethodLabel.PAYMENT_CASH,
  },
  {
    value: window.configData.ap_payment_modes.CREDIT_CARD,
    label: PaymentMethodLabel.PAYMENT_CREDIT_CARD,
  },
  {
    value: window.configData.ap_payment_modes.BANK_CHECK,
    label: PaymentMethodLabel.PAYMENT_BANK_CHECK,
  },
  {
    value: window.configData.ap_payment_modes.ADVANCED_SETTLEMENT,
    label: PaymentMethodLabel.PAYMENT_ADVANCED_SETTLEMENT,
  },
  {
    value: window.configData.ap_payment_modes.PAID_EXTERNALLY,
    label: PaymentMethodLabel.PAYMENT_BANK_ACCOUNT,
  },
  {
    value: vendorCredit,
    label: PaymentMethodLabel.PAYMENT_VENDOR_CREDIT,
  },
];

export const PaymentStatusOptions: TOption[] = [
  {
    value: PaymentStatus.PAYMENT_PROCESSING,
    label: PaymentStatus.PAYMENT_PROCESSING,
  },
  {
    value: successPayment,
    label: PaymentStatus.PAYMENT_COMPLETED,
  },
  {
    value: PaymentStatus.PAYMENT_CANCELLED,
    label: PaymentStatus.PAYMENT_CANCELLED,
  },
  {
    value: PaymentStatus.PAYMENT_CREATED,
    label: PaymentStatus.PAYMENT_CREATED,
  },
  {
    value: PaymentStatus.PAYMENT_MAILED,
    label: PaymentStatus.PAYMENT_MAILED,
  },
  {
    value: PaymentStatus.PAYMENT_IN_TRANSIT,
    label: PaymentStatus.PAYMENT_IN_TRANSIT,
  },
  {
    value: PaymentStatus.PAYMENT_IN_LOCAL_AREA,
    label: PaymentStatus.PAYMENT_IN_LOCAL_AREA,
  },
  {
    value: processedForDelivery,
    label: PaymentStatus.PAYMENT_PROCESSED_FOR_DELIVERY,
  },
  {
    value: PaymentStatus.PAYMENT_CLEARED,
    label: PaymentStatus.PAYMENT_CLEARED,
  },
];

export const DocumentTypeOptions: TOption[] = [
  {
    value: 'invoices',
    label: 'Invoices',
  },
  {
    value: 'receipts',
    label: 'Receipts',
  },
  {
    value: 'transactions',
    label: 'Undocumented Transactions',
  },
];
