module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title"></h4>\n      </div>\n      <div class="modal-body">\n        <p class="p-b-0 text-center font-16 m-t-40 m-b-30"></p>\n      </div>\n      <div class="modal-footer">\n        <button type=\'button\' id=\'confirm-alert-btn\' class="btn btn-danger ladda-button" tabindex="10" data-color="');
    
      __out.push(__sanitize(this.isDanger ? 'red' : 'blue'));
    
      __out.push('" data-style="expand-right"><span class="ladda-label">Proceed</span></button>\n        <a class=\'cancel-link cancel pointer font-semi-bold\' id=\'cancel-alert-btn\'>Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}