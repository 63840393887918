import React, { useCallback } from 'react';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { Button } from '@src/components/ui/buttons';
import { CloseIcon } from '@src/components/utils/icomoon';

import { useSaveBudgetModal } from '../modal';

interface IBudgetDetialHeaderProps {
  isDraft: boolean,
}

const BudgetDetailHeader = ({
  isDraft,
}: IBudgetDetialHeaderProps) => {
  const budget = useReportServiceBudgetContext();
  const business = useBusinessContext();
  const saveBudgetModal = useSaveBudgetModal();

  const handleClick = useCallback(() => {
    if (isDraft) {
      saveBudgetModal.open();
    } else {
      Backbone.history.navigate(`/businesses/${business.id}/reports/budgets`, { trigger: true });
    }
  }, [business.id, isDraft, saveBudgetModal]);

  return (
    <div className="report-budget-detail-header header-simple display-flex justify-content-center">
      <saveBudgetModal.Component { ...saveBudgetModal.props } />
      <h2 className="font-25 font-bold">
        {budget.type}
        &nbsp;&nbsp;|&nbsp;&nbsp;FY&nbsp;
        {budget.year}
      </h2>
      <Button className="close-page-btn" title="" onClick={ handleClick }>
        <CloseIcon />
      </Button>
    </div>
  );
};

export default BudgetDetailHeader;
