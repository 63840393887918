import React from 'react';

import { TBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';
import { TDate } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon } from '@src/components/utils/icomoon';

interface CompleteButtonProps {
  date: TDate,
  onDone: (data: any) => void,
}

const CompleteButton = ({ date, onDone }: CompleteButtonProps) => {
  const completeModal = useConfirmDeleteModal({
    onDone: () => onDone('reconciled' as TBookkeepingStatus),
  });

  const text = (
    <>
      <CheckGreenIcon />
      No ledger sync errors.
      <br />
      <CheckGreenIcon />
      All bank accounts reconciled.
      <br />
      <CheckGreenIcon />
      All credit cards reconciled.
    </>
  );

  return (
    <>
      <completeModal.Component
        confirmStyle="primary"
        confirmTitle="Mark as Complete"
        text={ text }
        title={ `Complete Books for ${date}` }
        { ...completeModal.props }
      />
      <Button variant="primary" onClick={ completeModal.open }>
        Mark as Complete
      </Button>
    </>
  );
};

export default React.memo(CompleteButton);
