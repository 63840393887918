import { useCallback, useMemo, useState } from 'react';

type TDefaultModalOnDone = () => void;

interface IUseModalParams<TOnDone extends Function = TDefaultModalOnDone> {
  onCancel?: () => void,
  onDone?: TOnDone,
}

interface IUseModalProps<TValue extends object | undefined = undefined> {
  isOpen: boolean,
  openValue: TValue | undefined,
  onCancel: () => void,
  onDone: () => void,
}

interface IUseModalReturn<TValue extends object | undefined = undefined> {
  open: () => void,
  openWithValue: (value: TValue) => void,
  props: IUseModalProps<TValue>
}

const useModal = <
  TOnDone extends Function = TDefaultModalOnDone,
  TValue extends object | undefined = undefined,
>({
    onCancel,
    onDone,
  }: IUseModalParams<TOnDone> = {}): IUseModalReturn<TValue> => {
  const [openValue, setOpenValue] = useState<TValue | boolean | undefined>(undefined);

  const handleOpen = useCallback(() => {
    setOpenValue(true);
  }, []);

  const handleOpenWithValue = useCallback((value: TValue) => {
    setOpenValue(value);
  }, []);

  const handleCancel = useCallback(() => {
    setOpenValue(undefined);

    if (onCancel) onCancel();
  }, [onCancel]);

  const handleDone = useCallback((...params) => {
    setOpenValue(undefined);

    if (onDone) onDone(...params);
  }, [onDone]);

  const props = useMemo(() => ({
    isOpen:    Boolean(openValue),
    openValue: typeof openValue === 'boolean' ? undefined : openValue,
    onCancel:  handleCancel,
    onDone:    handleDone,
  }), [openValue, handleCancel, handleDone]);

  return useMemo(() => ({
    open:          handleOpen,
    openWithValue: handleOpenWithValue,
    props,
  }), [handleOpen, handleOpenWithValue, props]);
};

const makeUseModal = <
  ComponentType, TValue extends object | undefined = undefined
>(component: ComponentType) => {
  return <
    TOnDone extends Function = TDefaultModalOnDone,
  >(
    params: IUseModalParams<TOnDone> = {},
  ): IUseModalReturn<TValue> & { Component: ComponentType } => {
    const modal = useModal<TOnDone, TValue>(params);

    return {
      Component: component,
      ...modal,
    };
  };
};

export {
  IUseModalParams,
  IUseModalProps,
  IUseModalReturn,
  TDefaultModalOnDone,
  makeUseModal,
  useModal,
};
