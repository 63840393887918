import React, { useMemo } from 'react';

import {
  useGetReceivableAccountsPayableServices,
} from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import { FilterFieldWrapper, useFilterField } from '@src/components/ui/filter';
import { useFilterFieldProps } from '@src/components/ui/filter/utils';
import { SelectInput } from '@src/components/ui/form';

interface IBusinessFilterSelectProps {
  businessId: TID,
  className?: string,
  widthPercent?: IUIStyleProps['widthPercent'],
}

const BusinessFilterSelect = <TFilter extends TFilterData>({
  businessId,
  className,
  widthPercent,
  ...props
}: IBusinessFilterSelectProps) => {
  const { update, value } = useFilterField<TFilter, string>('settlement_business_id');
  const inputProps = useFilterFieldProps(props);

  const { data: businesses } = useGetReceivableAccountsPayableServices({ businessId });
  const options = useMemo(() => {
    return [
      { value: '', name: 'All Businesses' },
      ...(businesses ?? []).map((business) => ({
        value: String(business.businessId),
        name:  business.businessName,
      })),
    ];
  }, [businesses]);

  return (
    <FilterFieldWrapper className={ className } widthPercent={ widthPercent }>
      <SelectInput
        options={ options }
        value={ value || '' }
        onChange={ update }
        { ...inputProps }
      />
    </FilterFieldWrapper>
  );
};

export default BusinessFilterSelect;
