import React from 'react';

import { IManagementGroup, TFinancialInsightsEmailSettingsSortColumn } from '@src/types/management_groups';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

interface IFinancialInsightsEmailSettingsTableProps {
  records: IManagementGroup[],
  sorting: ISorting<TFinancialInsightsEmailSettingsSortColumn>,
}

const FinancialInsightsEmailSettingsTable = ({
  records,
  sorting,
}: IFinancialInsightsEmailSettingsTableProps) => {
  return (
    <CollectionTable<
      IManagementGroup,
      TFinancialInsightsEmailSettingsSortColumn
    >
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<IManagementGroup>
        hideValueTooltip
        name="name"
        title="Name"
        value={ (managementGroup) => managementGroup.name }
        width="90%"
      />
      <CollectionTable.TextColumn<IManagementGroup>
        hideValueTooltip
        name="kpi_notification_enabled"
        title="Notification Flag"
        value={
          (managementGroup) => (managementGroup.kpiNotificationEnabled ? 'Enabled' : 'Disabled')
        }
        width="10%"
      />
    </CollectionTable>
  );
};

export default React.memo(FinancialInsightsEmailSettingsTable);
