import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { SplitTransactionIcon } from '@src/components/utils/icomoon';

import useSplitTransactionsModal from '../split_transaction/split_transactions_modal';

interface ITransactionSplitActionProps {
  transaction: ITransactionServiceDocument,
}

const TransactionSplitAction = ({
  transaction,
}: ITransactionSplitActionProps): JSX.Element | null => {
  const split = useSplitTransactionsModal();
  if (transaction.parentBankTransactionId || transaction.bcsParentBankTransactionId) return null;

  return (
    <>
      <split.Component
        { ...split.props }
        forSplit
        transaction={ transaction }
      />
      <ActionsDropdown.Action
        icon={ <SplitTransactionIcon fontSize={ 18 } /> }
        name="Split Transaction"
        onClick={ split.open }
      />
    </>
  );
};

export default TransactionSplitAction;
