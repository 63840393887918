module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="member-select-item border-bottom-gray">\n  <div class="checkbox m-t-0 m-b-0">  \n    <input type="checkbox" class="styled form-control input-element" data-type="checkbox" data-business="');
    
      __out.push(__sanitize(this.id));
    
      __out.push('">\n    <label class="font-16 relative width-100-percent business-checkbox-label">\n    <div class="display-flex">\n      <div class="display-table-cell">\n        ');
    
      if (this.avatar) {
        __out.push('\n        <div class="middle-avatar-wrapper border-gray">\n          <img src="');
        __out.push(__sanitize(this.avatar.avatar_url));
        __out.push('">\n        </div>\n        ');
      } else {
        __out.push('\n        <span class=\'icon-business-default-avatar font-40\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="p-l-20" style="align-self: center">\n        <h5 class="font-16 font-bold m-t-0 m-b-0 font-bold text-left">\n          ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('\n        </h5>\n      </div>\n      <div class="display-flex align-items-center justify-content-end p-r-65 business-support-view hidden" style="flex: 1">\n        <span class="font-12 m-r-3 disable"> Report not active </span>\n        <span class="icon icon-docyt-support font-16 custom-tooltip z-index-1-important">\n          <span class="path1"></span><span class="path2"></span>\n          <span class="tooltiptext">\n            ');
    
      __out.push(__sanitize(Docyt.Common.Constants.Messages['CUSTOM_REPORT_NO_MAPPED']));
    
      __out.push('\n          </span>\n        </span>\n      </div>\n    </div>\n    </label>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}