module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class=\'font-24 font-bold text-center\'>Match as Bank Transfer</h4>\n      </div>\n      <div class="modal-body">\n        <p class="font-16">The following transactions have been selected and will be matched as bank transfers:</p>\n\n        <table class="table">\n          <thead>\n            <tr>\n              <th>Date</th>\n              <th>Description</th>\n              <th>Account</th>\n              <th>Amount</th>\n            </tr>\n          </thead>\n          <tbody>\n            <tr>\n              <td>');
    
      __out.push(__sanitize(moment(this.transactions[0].get('transaction_date')).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(this.transactions[0].get('description')));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(this.transactions[0].get('payment_account_name')));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(parseFloat(this.transactions[0].get('amount')).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</td>\n            </tr>\n            <tr>\n              <td>');
    
      __out.push(__sanitize(moment(this.transactions[1].get('transaction_date')).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(this.transactions[1].get('description')));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(this.transactions[1].get('payment_account_name')));
    
      __out.push('</td>\n              <td>');
    
      __out.push(__sanitize(parseFloat(this.transactions[1].get('amount')).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</td>\n            </tr>\n          </tbody>\n        </table>\n      </div>\n      <div class="modal-footer">\n        <button type=\'button\' id=\'confirm-btn\' class="btn btn-danger ladda-button" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Confirm</span></button>\n        <a class=\'cancel-link cancel pointer font-semi-bold\' id=\'cancel-btn\'>Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}