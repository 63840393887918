import React from 'react';

import Dropdown from '@src/components/ui_v2/dropdown';
import { useClearFilter, useFilterCounter } from '@src/components/ui_v2/filter';
import { UndoIcon } from '@src/components/utils/icomoon';

const CollectionTableSettingsClearFilterItem = () => {
  const filterCounter = useFilterCounter();
  const clearFilter = useClearFilter();

  return (
    <Dropdown.IconItem
      disabled={ filterCounter === 0 }
      icon={ <UndoIcon fontSize={ 20 } /> }
      onSelect={ clearFilter }
    >
      Clear Filter
    </Dropdown.IconItem>
  );
};

export default CollectionTableSettingsClearFilterItem;
