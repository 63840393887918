import React from 'react';

import Root from '@src/components/root';

import Details from './details';

interface IGlobalHeaderProps {
  focusToMyClient: boolean,
}

const GlobalHeader = ({
  focusToMyClient,
}: IGlobalHeaderProps) => {
  return (
    <Root>
      <Details focusToMyClient={ focusToMyClient } />
    </Root>
  );
};

export default GlobalHeader;
