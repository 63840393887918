module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade modal-middle">\n  <div class="modal-dialog modal-send-reports">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h1 class="font-28 font-bold text-center m-l-auto m-r-auto">Email Reports</h1>\n      </div>\n      <div class="modal-body scrollable-invoice-modal-body">\n        <div class="relative">\n          <label class="form-label font-16">Send reports for</label>\n          <select class="form-control pull-right" id="month-selector">\n          </select>\n          <select class="form-control pull-right" id="year-selector">\n          </select>\n        </div>\n      </div>\n      <div class="modal-footer fixed-modal-footer">\n        <a class="cancel-link cancel pointer font-16" id="cancel-btn">Cancel</a>\n        <button type=\'button\' class="btn btn-info btn-blue font-16" id="send-btn" tabindex="10" data-color="blue">Send Report</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}