module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="expense-header">\n  <div class="expense-header-menu">\n    <a class="font-14" href="/businesses/');
    
      __out.push(__sanitize(this.business.id));
    
      __out.push('/expense_reports/employees">Employee Expenses  /</a>\n    <div class="display-flex align-items-center font-24">\n      ');
    
      if (this.serviceRole.get('avatar_url')) {
        __out.push('\n      <img src=');
        __out.push(__sanitize(this.serviceRole.get('avatar_url')));
        __out.push(' class=\'small-avatar-wrapper\'>\n      ');
      } else {
        __out.push('\n      <i class="icon icon-smile in-blue-400 line-height-0"></i>\n      ');
      }
    
      __out.push('\n      <span class="m-l-14 font-bold">');
    
      __out.push(__sanitize(this.serviceRole.get('full_name')));
    
      __out.push('</span>\n    </div>\n  </div>\n  <ul class="nav sub-header-menu expense-sub-menu font-semi-bold">\n    <li class="m-r-48 ');
    
      __out.push(__sanitize(this.tab === 'submitted' ? 'active' : ''));
    
      __out.push('">\n      <a href="');
    
      __out.push(__sanitize(this.submittedURL));
    
      __out.push('">\n        SUBMITTED (');
    
      __out.push(__sanitize(this.serviceRole.get('submitted_count')));
    
      __out.push(')\n      </a>\n    </li>\n    <li class="m-r-48 ');
    
      __out.push(__sanitize(this.tab === 'reimbursed' ? 'active' : ''));
    
      __out.push('">\n      <a href="');
    
      __out.push(__sanitize(this.reimbursedURL));
    
      __out.push('">\n        REIMBURSED (');
    
      __out.push(__sanitize(this.serviceRole.get('reimbursed_count')));
    
      __out.push(')\n      </a>\n    </li>\n  </ul>\n</div>\n<div class="reports-table-container"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}