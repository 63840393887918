import { createContext, useContext } from 'react';

import { IRevenueService } from '@src/types/revenue_services';

const RevenueServiceContext = createContext<IRevenueService | null>(null);

const useRevenueServiceContext = (): IRevenueService => {
  const value = useContext(RevenueServiceContext);

  if (!value) {
    throw new Error('"useRevenueServiceContext" must be used within <RevenueServiceProvider />');
  }

  return value;
};

export {
  RevenueServiceContext,
  useRevenueServiceContext,
};
