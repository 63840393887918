import { object, string } from 'yup';

const signUpValidation = object({
  email:    string().email().required('Email must be a valid email.'),
  password: string().test((value, validationContext) => {
    const { createError } = validationContext;

    if (!value) return createError({ message: 'Password is required' });
    if (!window.PASSWORD_REGEXP.test(value)) return createError({ message: 'Please enter more symbols' });
    return true;
  }),
  passwordConfirmation: string().test((value, validationContext) => {
    const { createError,
      parent: {
        password,
      } } = validationContext;

    if (!value || password !== value) return createError({ message: 'Please enter correct password' });
    return true;
  }),
});

interface ISignUpFormValues {
  email: string,
  password: string,
  passwordConfirmation: string,
}

export {
  ISignUpFormValues,
  signUpValidation,
};
