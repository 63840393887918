import React, { useMemo } from 'react';

import classNames from 'classnames';
import last from 'lodash/last';
import sumBy from 'lodash/sumBy';
import pluralize from 'pluralize';
import { UseInfiniteQueryResult } from 'react-query';

import { IResponseWithPageInfo } from '@src/hooks/queries/infinite_collection_queries';

import styles from './styles.module.scss';

interface ITableSectionPaginationInfoProps<T extends React.ElementType> {
  as?: T,
  infiniteQuery: UseInfiniteQueryResult<IResponseWithPageInfo<any>, Error>,
}

type TTableSectionPaginationInfoProps<T extends React.ElementType> =
  ITableSectionPaginationInfoProps<T>
  & Omit<React.ComponentPropsWithoutRef<T>, keyof ITableSectionPaginationInfoProps<T>>;

const TableSectionPaginationInfo = <T extends React.ElementType = 'span'>({
  as,
  className,
  infiniteQuery,
  ...props
}: TTableSectionPaginationInfoProps<T>): JSX.Element | null => {
  const [count, totalCount] = useMemo(() => {
    const pages = infiniteQuery.data?.pages || [];

    return [
      sumBy(pages, (p) => p.collection.length),
      last(pages)?.meta?.totalCount || 0,
    ];
  }, [infiniteQuery.data?.pages]);

  const classes = classNames(styles['table-section-pagination-info'], className);

  const Component = as || 'span';

  return (
    <Component className={ classes } { ...props }>
      { `Showing ${count} out of ${totalCount} ${pluralize('item', totalCount)}` }
    </Component>
  );
};

export default TableSectionPaginationInfo;
