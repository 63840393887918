import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import VersionChecker from './version_checker';

const queryClient = new QueryClient();

const VersionGuard = (): JSX.Element => {
  return (
    <QueryClientProvider client={ queryClient }>
      <React.StrictMode>
        <VersionChecker />
      </React.StrictMode>
    </QueryClientProvider>
  );
};

export default VersionGuard;
