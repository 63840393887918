import React from 'react';

import { TDate } from '@src/types/common';

import Table from '@src/components/ui_v2/table';

interface IUnreconcileRelatedDepositsListItemProps {
  amount: number,
  date: TDate,
  paymentProcessorName: string,
}

const UnreconcileRelatedDepositsListItem = ({
  amount,
  date,
  paymentProcessorName,
}: IUnreconcileRelatedDepositsListItemProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.DateCell date={ date } />
      <Table.Cell>
        { paymentProcessorName }
      </Table.Cell>
      <Table.AmountCell amount={ amount } />
    </Table.Row>
  );
};

export default UnreconcileRelatedDepositsListItem;
