import React from 'react';

import { RevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useGetRevenueServiceByBusinessId } from '@src/hooks/queries/revenue_services';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

interface IRevenueServiceProviderProps {
  businessId: number,
  children: React.ReactNode,
  isGlobalNavigationEnabled?: boolean
}

const RevenueServiceProvider = ({
  businessId,
  isGlobalNavigationEnabled,
  children,
}: IRevenueServiceProviderProps): JSX.Element | null => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetRevenueServiceByBusinessId({ businessId });

  // wrapping this loading effect under a feature flag since the api revenue items are called in the new global navigation header as well
  // so to avoid double spinner effects returning null when featre falg is on so that only one loading spinner appears in the UI when new header loads.
  if (isLoading) {
    return isGlobalNavigationEnabled ? null : <Spinner />;
  };
  if (isError) return <ErrorNotification error={ error } />;
  if (!data?.revenueService) return <ErrorNotification message="No Revenue Service loaded" />;

  return (
    <RevenueServiceContext.Provider value={ data.revenueService }>
      { children }
    </RevenueServiceContext.Provider>
  );
};

export default RevenueServiceProvider;
