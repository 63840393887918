import React from 'react';

import EditBusinessMetricValues from './edit_action';

interface IBusinessMetricValueActionsProps {
  onEditData: () => void,
}

const BusinessMetricValueActions = ({
  onEditData,
}: IBusinessMetricValueActionsProps): JSX.Element => {
  return (
    <EditBusinessMetricValues
      onEditData={ onEditData }
    />
  );
};

export default BusinessMetricValueActions;
