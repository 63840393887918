import React, { useCallback, useMemo, useReducer } from 'react';

import { SideViewContext } from './context';
import { ISideView, TSideViewId } from './types';

type TSideViewAction = {
  type: 'show',
  isRoot?: boolean,
  view: ISideView,
} | {
  type: 'close',
  viewId: TSideViewId,
};

const addOrReplaceView = (
  views: ISideView[],
  view: ISideView,
  isRoot?: boolean,
): ISideView[] => {
  let found = false;
  const newViews = views.map((v) => {
    if (v.id !== view.id) return v;

    found = true;
    return view;
  });

  if (found) return newViews;
  if (isRoot) return [view];

  return [...views, view];
};

const removeView = (views: ISideView[], viewId: TSideViewId): ISideView[] => {
  return views.filter((v) => v.id !== viewId);
};

const sideViewReducer = (
  state: ISideView[],
  action: TSideViewAction,
): ISideView[] => {
  switch (action?.type) {
    case 'show': {
      return addOrReplaceView(state, action.view, action.isRoot);
    }
    case 'close':
      return removeView(state, action.viewId);

    // no default
  }

  return state;
};

interface ISideViewProvider {
  children: React.ReactNode,
}

const SideViewProvider = ({
  children,
}: ISideViewProvider): JSX.Element => {
  const [views, dispatch] = useReducer(sideViewReducer, []);

  const showSideView = useCallback((view: ISideView, isRoot?: boolean) => {
    dispatch({ type: 'show', isRoot, view });
  }, []);

  const closeSideView = useCallback((viewId: TSideViewId) => {
    dispatch({ type: 'close', viewId });
  }, []);

  const sideViewValue = useMemo(() => ({
    hasContent: views.length > 0,
    views,
    showSideView,
    closeSideView,
  }), [views, showSideView, closeSideView]);

  return (
    <SideViewContext.Provider value={ sideViewValue }>
      <div className="display-flex">
        { children }
      </div>
    </SideViewContext.Provider>
  );
};

export default SideViewProvider;
