import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

import {
  ISSORegisterFormValues,
  SSOSignOut,
  ssoRegisterValidation,
} from '../helpers';
import { IRegisterProps } from '../view/register';

import styles from '../styles.module.scss';

const RegisterBody = ({ verifySSOIdentity }: IRegisterProps) => {
  const [searchParams] = useSearchParams();
  const ssoToken = searchParams.get('token') ?? '';
  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    setError,
  } = useForm<ISSORegisterFormValues>({
    defaultValues: { ssoToken },
    resolver:      yupResolver(ssoRegisterValidation),
  });

  const handleError = useCallback(async (message: string, isLogut: boolean = true) => {
    setError('password', { message });
    if (isLogut) {
      await SSOSignOut();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitHandler = handleSubmit(async (bodyData) => {
    await verifySSOIdentity(bodyData, SSOSignOut, handleError);
  });

  return (
    <div className={ styles['sso-register-container'] }>
      <div className={ styles['register-title'] }>
        <h1 className="font-24 font-bold text-center">
          Verify Your Docyt Identity
        </h1>
        <p className={ styles['register-subtitle'] }>You only need to do this once to link your corporate account with Docyt Identity.</p>
      </div>
      <div className="background-white sign-in-main-panel m-l-auto m-r-auto">
        <Form
          className={ styles['sso-register-form'] }
          onSubmit={ handleSubmitHandler }
        >
          <div className={ styles['register-body'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-value'] }
              error={ errors.email?.message }
              label="Email"
              placeholder="Email"
              prefixIcon={ <span className="sign-up-mail-icon icon-mail" /> }
              { ...register('email') }
            />
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-value'] }
              error={ errors.password?.message }
              label="Password"
              placeholder="Password"
              prefixIcon={
                <span className="sign-up-key-icon icon-password-key-icon font-20" />
              }
              type="password"
              { ...register('password') }
            />
          </div>
          <div className={ styles['register-footer'] }>
            <Button disabled={ isSubmitting } type="submit" variant="primary">
              {isSubmitting ? 'Verifying...' : 'Verify'}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterBody;
