import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const Vendor1099Icon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="report-vendor"
      pathsNumber={ 7 }
    />
  );
};

export default Vendor1099Icon;
