import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useActivateRevenueSystemDepositTracking } from '@src/hooks/queries/revenue_systems';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './activate_form';
import { IActivateDepositTrackingValues } from './schema';

interface IActivateDepositTrackingModalProps extends IUseModalProps {
  revenueService: IRevenueService,
  revenueSystem: IRevenueSystem,
}

const ActivateDepositTrackingModal = ({
  isOpen,
  onDone,
  revenueService,
  revenueSystem,
  ...props
}: IActivateDepositTrackingModalProps) => {
  const activate = useActivateRevenueSystemDepositTracking();

  const { mutate } = activate;
  const handleSubmit = useCallback((data: IActivateDepositTrackingValues) => {
    mutate({
      revenueServiceId: revenueService.id,
      revenueSystemId:  revenueSystem.id,
      data,
    }, {
      onSuccess: onDone,
    });
  }, [mutate, revenueService.id, revenueSystem.id, onDone]);

  return (
    <Modal.Form
      proceedTitle="Activate"
      show={ isOpen }
      title="Activate Deposit Tracking"
      { ...props }
    >
      { ({ formId }) => (
        <>
          <MutationStatus mutation={ activate } />
          <Form
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        </>
      ) }
    </Modal.Form>
  );
};

const useActivateDepositTrackingModal = makeUseModal(ActivateDepositTrackingModal);

export {
  useActivateDepositTrackingModal,
  ActivateDepositTrackingModal as default,
};
