import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useWithdrawAmount } from '@src/hooks/queries/withdraw_amount';
import { TID, TAmount } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form, { IWithdrawAmountFormValues } from './withdraw_amount_form';

interface IWithdrawAmountProps extends IUseModalProps {
  businessId: TID,
  dwollaBalance: TAmount,
  bankAccountName: string,
}

const WithdrawAmount = ({
  businessId,
  dwollaBalance,
  bankAccountName,
  isOpen,
  onCancel,
  onDone,
}: IWithdrawAmountProps) => {
  const withdraw = useWithdrawAmount();

  const { mutate } = withdraw;
  const handleSubmit = useCallback((values: IWithdrawAmountFormValues) => {
    mutate(
      {
        businessId,
        amount: values.amount,
      },
      {
        onSuccess: () => {
          window.Docyt.vent.trigger('ach:withdraw:success', values.amount);
          onDone();
        },
      },
    );
  }, [businessId, onDone, mutate]);

  return (
    <>
      <MutationStatus mutation={ withdraw } />
      <Modal.Form
        proceedTitle="Withdraw"
        show={ isOpen }
        title="Withdraw to Bank Account"
        onCancel={ onCancel }
      >
        {({ formId }) => (
          <Form
            bankAccountName={ bankAccountName }
            dwollaBalance={ dwollaBalance }
            formId={ formId }
            onSubmit={ handleSubmit }
          />
        )}
      </Modal.Form>
    </>
  );
};

const useWithdrawAmountModal = makeUseModal(WithdrawAmount);

export {
  IWithdrawAmountProps,
  useWithdrawAmountModal,
  WithdrawAmount as default,
};
