import React from 'react';

import { Router, Navigator, To, NavigateOptions, Location } from 'react-router-dom';

import { createHref, createLocation, createURL } from './utils';

interface IBackboneRouterProps {
  children: React.ReactNode,
  location?: Location,
}

const navigator: Navigator = {
  createHref,
  encodeLocation: (to) => {
    // Encode a Location the same way window.location would
    const url = createURL(to);
    return {
      pathname: url.pathname,
      search:   url.search,
      hash:     url.hash,
    };
  },
  go:   window.history.go,
  push: (to: To, state?: any, opts?: NavigateOptions) => {
    const location = createLocation(to);

    Backbone.history.navigate(location.pathname, { trigger: true, ...opts });
  },
  replace: (to: To, state?: any, opts?: NavigateOptions) => {
    const location = createLocation(to);

    Backbone.history.navigate(location.pathname, { trigger: true, replace: true, ...opts });
  },
};

const BackboneRouter = ({ children, location }: IBackboneRouterProps): JSX.Element => {
  return (
    <Router
      location={ location || window.location }
      navigator={ navigator }
    >
      { children }
    </Router>
  );
};

export default BackboneRouter;
