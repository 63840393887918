import React from 'react';

import { IUseModalProps } from '@src/hooks/modal';

import Body from './body';
import Content from './content';
import Header from './header';

interface IStandardSideViewProps {
  children: () => React.ReactNode,
  isOpen: IUseModalProps['isOpen'],
  isRoot?: boolean,
  title: React.ReactNode,
  onCancel: IUseModalProps['onCancel'],
}

const StandardSideView = ({
  children,
  isOpen,
  isRoot,
  title,
  onCancel,
}: IStandardSideViewProps): JSX.Element => {
  return (
    <Content isOpen={ isOpen } isRoot={ isRoot }>
      <Header title={ title } onClose={ onCancel } />
      <Body onClosed={ onCancel }>
        { children() }
      </Body>
    </Content>
  );
};

export default React.memo(StandardSideView);
