import React, { useEffect } from 'react';

import { useController, useFormContext } from 'react-hook-form';

import { useUpsertRevenueServiceDocumentCategoryValues } from '@src/hooks/queries/revenue_service_documents';
import { useDebouncedCallback } from '@src/hooks/utils';
import { IRevenueServiceDocumentLineItem } from '@src/types/revenue_service_document_line_items';

import Form from '@src/components/ui_v2/form';
import Table from '@src/components/ui_v2/table';
import MutationStatus from '@src/components/utils/mutation_status';

import { IReportLineItems } from './schema';
import getIndexFromName from './utils';

interface IResolveRevenueReportTableProps {
  item: IRevenueServiceDocumentLineItem;
  index: number;
}

const DEBOUNCE_DELAY = 500; // 0.5 seconds

const ResolveRevenueReportTableItem = ({
  item,
  index,
}: IResolveRevenueReportTableProps) => {
  const mutation = useUpsertRevenueServiceDocumentCategoryValues();
  const { mutate } = mutation;

  const { control, watch } = useFormContext<IReportLineItems>();

  const creditValue = useController({
    control,
    name:         `lineItems.${index}.creditValue`,
    defaultValue: item.creditValue || '0.00',
  });
  const debitValue = useController({
    control,
    name:         `lineItems.${index}.debitValue`,
    defaultValue: item.debitValue || '0.00',
  });

  const debouncedUpsert = useDebouncedCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
    DEBOUNCE_DELAY,
  );

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name !== debitValue.field.name && name !== creditValue.field.name) return;

      const indeks = getIndexFromName(name);

      if (indeks === null) return;
      if (!values?.lineItems || indeks >= values.lineItems.length) return;

      const lineItem = values.lineItems[indeks];
      const debitValueCurrent = lineItem?.debitValue;
      const creditValueCurrent = lineItem?.creditValue;

      let valueToUpdate = lineItem?.isDebit ? debitValueCurrent : creditValueCurrent;
      valueToUpdate = valueToUpdate === '' ? '0.00' : valueToUpdate;

      if (!valueToUpdate || valueToUpdate === '-') return;

      debouncedUpsert({
        revenueReportTypeCategoryId: lineItem?.revenueReportTypeCategoryId,
        revenueServiceDocumentId:    lineItem?.revenueServiceDocumentId,
        [item.useNetChange]:          valueToUpdate,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, debouncedUpsert, debitValue, creditValue, item, index]);

  return (
    <>
      <MutationStatus mutation={ mutation } hideSpinner />
      <Table.Row>
        <Table.Cell>{item.lineItemName}</Table.Cell>
        <Table.Cell>{item.chartOfAccountName}</Table.Cell>
        <Table.Cell>{item.accountingClassName}</Table.Cell>
        <Table.InputCell>
          <Form.AmountField { ...debitValue.field } disabled={ !item.isDebit } label="Debit" layout="table" />
        </Table.InputCell>
        <Table.InputCell>
          <Form.AmountField { ...creditValue.field } disabled={ item.isDebit } label="Credit" layout="table" />
        </Table.InputCell>
      </Table.Row>
    </>
  );
};

export default React.memo(ResolveRevenueReportTableItem);
