import React from 'react';

import { SmileIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IImageAvatarProps {
  avatarUrl: string | null | undefined,
  /**
   * Avatar size with measurement unit.
   * @example
   * '24px' | '1rem'
   * @default '25px'
   * */
  size?: string,
}

const ImageAvatar = ({
  avatarUrl,
  size = styles['small-avatar-size'],
}: IImageAvatarProps) => {
  return (
    <div className="small-avatar" style={ { width: size, height: size, fontSize: size } }>
      {
        avatarUrl ? (
          <img
            alt=""
            src={ avatarUrl }
          />
        ) : (
          <SmileIcon
            className="in-orange-500"
          />
        )
      }
    </div>
  );
};

export default React.memo(ImageAvatar);
