import React, { useCallback, useMemo } from 'react';

import { useGetReportAvailableBusinesses } from '@src/hooks/queries/report_service/custom_reports';
import { IGetTemplatesResponse } from '@src/requests/multi_entity_reports';
import { IBusiness } from '@src/types/businesses';

import CreateReportModal from './create_report_modal';
import { ICreateNewReportFormValues } from '../../forms/schema';

interface ICreateReportWizardProps {
  businesses: IBusiness[],
  templates: IGetTemplatesResponse[],
  show: boolean,
  name: string,
  templateId: string,
  step: 'reportType' | 'business',
  setName: React.Dispatch<React.SetStateAction<string>>,
  setTemplateId: React.Dispatch<React.SetStateAction<string>>,
  setStep: React.Dispatch<React.SetStateAction<'reportType' | 'business'>>,
  onCancel: () => void,
  onDone: (data: ICreateNewReportFormValues) => void,
}

const CreateReportWizard = ({
  businesses,
  templates,
  show,
  name,
  templateId,
  step,
  setName,
  setTemplateId,
  setStep,
  onCancel,
  onDone,
}: ICreateReportWizardProps) => {
  const availableBusinessesQuery = useGetReportAvailableBusinesses(templateId);
  const availableBusinesses = useMemo(
    () => availableBusinessesQuery.data || [],
    [availableBusinessesQuery.data],
  );
  const businessData = useMemo(() => {
    const tmpBusinesses = [...businesses];

    return tmpBusinesses.map((business: IBusiness) => {
      const find = availableBusinesses.find((f: IBusiness) => f.id === business.id);

      if (find) {
        business.availableStatus = true;
      } else {
        business.availableStatus = false;
      }

      return business;
    });
  }, [availableBusinesses, businesses]);

  const handleSubmit = useCallback((data: ICreateNewReportFormValues) => {
    if (step === 'reportType') {
      setStep('business');
      setName(data.name);
      setTemplateId(data.templateId);
    } else {
      onDone({
        name,
        reportServiceIds: data.reportServiceIds,
        templateId,
      });
    }
  }, [step, setStep, onDone, name, templateId, setName, setTemplateId]);

  const handleCancel = useCallback(() => {
    if (step === 'reportType') {
      setName('');
      setTemplateId('');
      onCancel();
    } else {
      setStep('reportType');
    }
  }, [step, onCancel, setStep, setName, setTemplateId]);

  return (
    <CreateReportModal
      businesses={ businessData }
      name={ name }
      show={ show }
      templateId={ templateId }
      templates={ templates }
      view={ step }
      onCancel={ handleCancel }
      onSubmit={ handleSubmit }
    />
  );
};

export default React.memo(CreateReportWizard);
