module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="receivable-clickable pointer">\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.customer_image_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.customer_image_url));
        __out.push(' class=\'small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <span class="m-l-14">');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('</span>\n  </div>\n</td>\n<td class="receivable-clickable pointer">');
    
      __out.push(__sanitize(this.invoice_number));
    
      __out.push('</td>\n<td class="receivable-clickable pointer">');
    
      if (this.invoice_date) {
        __out.push(__sanitize(moment(this.invoice_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
      }
    
      __out.push('</td>\n');
    
      if (this.type === "completed") {
        __out.push('\n<td class="receivable-clickable pointer">');
        if (this.paid_date) {
          __out.push(__sanitize(moment(this.paid_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        }
        __out.push('</td>\n<td class="receivable-clickable pointer">\n  ');
        if (this.payment_mode === configData.ap_payment_modes['DOCYT_ACH']) {
          __out.push('\n    Docyt ACH\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['DOCYT_CHECK']) {
          __out.push('\n    Docyt Check\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['BANK_CHECK']) {
          __out.push('\n    Bank Check\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['BANK_ACCOUNT']) {
          __out.push('\n    Bank Account\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['ADVANCED_SETTLEMENT']) {
          __out.push('\n    Advanced Settlement\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['CREDIT_CARD']) {
          __out.push('\n    Credit Card\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['CASH']) {
          __out.push('\n    Cash\n  ');
        } else if (this.payment_mode === configData.ap_payment_modes['PAID_EXTERNALLY']) {
          __out.push('\n    Externally\n  ');
        }
        __out.push('\n</td>\n');
      } else {
        __out.push('\n<td class="receivable-clickable pointer">');
        __out.push(__sanitize(this.cancelled_date));
        __out.push('</td>\n');
      }
    
      __out.push('\n<td class="text-right font-bold receivable-clickable pointer" style="padding-right: 30px;">');
    
      __out.push(__sanitize(this.amountString));
    
      __out.push('</td>\n<td>\n  <div class="display-inline-flex pull-left">\n    <a class="relative transaction-action" href="/document/');
    
      __out.push(__sanitize(this.document_id));
    
      __out.push('/chat">\n      ');
    
      if (this.unread_message_count === 0) {
        __out.push('\n      <span class="font-26 icon icon-chat-empty"></span>\n      ');
      } else {
        __out.push('\n      <span class="font-26 icon icon-chat-filled"></span><span class="unread-messages-count">');
        __out.push(__sanitize(this.unread_message_count < 10 ? this.unread_message_count : '9+'));
        __out.push('</span>\n      ');
      }
    
      __out.push('\n    </a>\n  </div>\n</td>\n<td class="qbo-status-icon-column pointer vertical-align-middle-important width-50px"></td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}