import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const MissingInGLIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="missing-in-gl"
    />
  );
});

MissingInGLIcon.displayName = 'MissingInGLIcon';

export default MissingInGLIcon;
