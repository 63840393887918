import { useMemo } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import { UseInfiniteQueryResult } from 'react-query';

import { Section } from '@src/constants/sections';
import { useGetAccountsReceivablePayments } from '@src/hooks/queries/accounts_receivable_payments';
import { useSorting } from '@src/hooks/url_params';
import { IGetAccountsReceivablePaymentsResponse } from '@src/requests/accounts_receivable_payments';
import {
  IAccountsReceivablePayment,
  IAccountsReceivablePaymentQueryFilter,
  IAccountsReceivablePaymentsFilter,
  TAccountsReceivablePaymentsSortColumn,
} from '@src/types/accounts_receivable_payments';
import { TID, TSection } from '@src/types/common';
import { ISorting, ISortingParams } from '@src/types/sorting';
import { dateFilterToQuery } from '@src/utils/filter';

import { useFilterData } from '@src/components/ui_v2/filter';

interface IUsePaymentsCollectionParams {
  businessId: TID,
}

interface IPaymentsCollection {
  query: UseInfiniteQueryResult<IGetAccountsReceivablePaymentsResponse, Error>,
  records: IAccountsReceivablePayment[],
  section: TSection,
  sorting: ISorting<TAccountsReceivablePaymentsSortColumn>,
}

const paymentsDefaultSorting: ISortingParams<TAccountsReceivablePaymentsSortColumn> = {
  orderColumn:    'payment_date',
  orderDirection: 'desc',
};

const filterToQuery = (
  filterData: IAccountsReceivablePaymentsFilter | undefined,
): IAccountsReceivablePaymentQueryFilter | undefined => {
  if (!filterData) return undefined;

  const query = omit(filterData, 'paymentDate');

  return {
    ...query,
    ...dateFilterToQuery(filterData.paymentDate, 'paymentDate') || {},
  };
};

const usePaymentsCollection = ({
  businessId,
}: IUsePaymentsCollectionParams): IPaymentsCollection => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsReceivablePayments,
    };
  }, [businessId]);

  const filterData = useFilterData(section);
  const filterQuery = useMemo(() => filterToQuery(filterData), [filterData]);

  const sorting = useSorting<TAccountsReceivablePaymentsSortColumn>({
    section:        section.section,
    defaultSorting: paymentsDefaultSorting,
  });

  const query = useGetAccountsReceivablePayments({
    businessId,
    filter: filterQuery,
    ...sorting.data,
  });

  const records = useMemo(() => {
    const pages = query?.data?.pages || [];
    return flatten(pages.map((p) => p.collection));
  }, [query?.data?.pages]);

  return {
    query,
    records,
    section,
    sorting,
  };
};

export {
  IPaymentsCollection,
  usePaymentsCollection,
};
