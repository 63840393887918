import React from 'react';

import { DashboardsUnavailableIcon, StartSearching } from '@src/components/utils/img_icons';

import styles from './styles.module.scss';

interface IMessageSearchResultsProps {
  title: string;
  description: string;
  isStartSearch?: boolean;
}

const MessageSearchResults = ({
  title,
  description,
  isStartSearch = false,
}: IMessageSearchResultsProps) => {
  return (
    <div className={ styles['empty-wrapper'] }>
      <div className={ styles['empty-image'] }>
        {isStartSearch ? (
          <StartSearching />
        ) : (
          <DashboardsUnavailableIcon />
        )}
      </div>
      <p className={ styles['empty-title'] }>{title}</p>
      <p className={ styles['empty-text'] }>{description}</p>
    </div>
  );
};

export default React.memo(MessageSearchResults);
