import React, { useCallback, useRef } from 'react';

import { mergeRefs } from 'react-merge-refs';

import { useCreatePopover } from '@src/hooks/popover';

import { DropdownContext } from './hooks';
import { focusFirstMenuItem } from './utils';

interface IDropdownProps<T extends React.ElementType> {
  as?: T,
  isFocusFirstMenuItem?: boolean,
  children: React.ReactNode,
}

const DropdownProvider = <T extends React.ElementType = 'div'>({
  as,
  isFocusFirstMenuItem = true,
  children,
  ...props
}: IDropdownProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof IDropdownProps<T>>) => {
  const popoverRef = useRef(null);

  const handleShow = useCallback(() => {
    if (isFocusFirstMenuItem) focusFirstMenuItem(popoverRef.current);
  }, [isFocusFirstMenuItem]);

  const popover = useCreatePopover({
    onShow: handleShow,
  });

  const Component = as || 'div';

  return (
    <DropdownContext.Provider value={ popover }>
      <Component ref={ mergeRefs([popover.referenceRef, popoverRef]) } { ...props }>
        { children }
      </Component>
    </DropdownContext.Provider>
  );
};

export default React.memo(DropdownProvider);
