/* eslint-disable max-len */
import React, { FC, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useDeleteChartOfAccount } from '@src/requests/simple_chart_of_accounts';
import { ISimpleBusinessChartOfAccount, ISimpleChartOfAccount } from '@src/types/simple_chart_of_accounts';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IDeleteModalProps extends IUseModalProps {
  chartOfAccount: ISimpleChartOfAccount;
}

interface IDeleteProgressProps {
  total: number;
  finished: number;
}

interface IDeleteDetailProps {
  chartOfAccount: ISimpleChartOfAccount;
}

interface IDeleteFailureProps {
  total: number;
  finished: number;
  message: string;
  failures: ISimpleBusinessChartOfAccount[];
}

const DeleteProgress: FC<IDeleteProgressProps> = ({ total, finished }) => {
  const percent = Math.floor((finished * 100) / total);

  return (
    <div>
      <p className="p-b-0 text-center m-b-20">
        Chart of Account(s) deleted from
        { ' ' }
        { finished }
        /
        { total }
        { ' ' }
        business(es).
      </p>

      <div className="progress">
        <div className="progress-bar" style={ { width: `${percent}%` } } />
      </div>

      <p className="p-b-0 text-center">Do not close this window or click Back button. The progress will be stopped.</p>
    </div>
  );
};

const DeleteDetail: FC<IDeleteDetailProps> = ({ chartOfAccount }) => {
  return (
    <div>
      <p>The Chart of Account(s) you are attempting to delete is currently linked to:</p>
      <ol className={ styles['business-list'] }>
        { chartOfAccount.businessChartOfAccounts?.map((it) => (<li key={ it.id }>{ it.businessName }</li>)) }
      </ol>
      <p>Deleting this Chart of Account(s) would also delete the Chart of Account(s) from the business(es).</p>
      <p>If necessary, consider unlinking the Chart of Account(s) from the business(es) before proceeding with deletion.</p>
      <p>Please review your deletion request and ensure it aligns with your intended actions.</p>
    </div>
  );
};

const DeleteFailure: FC<IDeleteFailureProps> = ({ finished, total, message, failures }) => {
  return (
    <div>
      <p title={ message }>
        Chart of Account(s) deleted from
        { ' ' }
        { finished }
        /
        { total }
        {' '}
        business(es). Please check Chart of Account(s) for linked business(s).
      </p>
      <ol className={ styles['business-list'] }>
        { failures.map((it) => (<li key={ it.id }>{ it.businessName }</li>)) }
      </ol>
      <div className="alert alert-warning" role="alert">
        { message }
      </div>
    </div>
  );
};

const DeleteModal: FC<IDeleteModalProps> = ({ isOpen, onCancel, chartOfAccount }) => {
  const { mutateAsync: deleteChartOfAccount } = useDeleteChartOfAccount();
  const queryClient = useQueryClient();
  const [isDeleting, setIsDeleting] = useState(false);
  const [finished, setFinished] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [failures, setFailures] = useState<ISimpleBusinessChartOfAccount[]>([]);

  let content = <DeleteDetail chartOfAccount={ chartOfAccount } />;
  if (isDeleting) content = <DeleteProgress finished={ finished } total={ chartOfAccount.businessChartOfAccounts.length } />;
  if (errorMessage) content = <DeleteFailure failures={ failures } finished={ finished } message={ errorMessage } total={ chartOfAccount.businessChartOfAccounts.length } />;

  let title = 'Delete Chart of Account(s)';
  if (isDeleting) title = 'Deletion of Chart of Accounts in Progress';
  if (errorMessage) title = "Deletion couldn't complete";

  const cancel = () => {
    onCancel();
    setTimeout(() => {
      setErrorMessage('');
      setIsDeleting(false);
    }, 500);
  };

  const onDelete = async () => {
    const failedBusinessesChartOfAccounts: ISimpleBusinessChartOfAccount[] = [];
    let message = '';
    setIsDeleting(true);

    const actions = chartOfAccount.businessChartOfAccounts.map((businessChartOfAccount) => {
      const businessId = businessChartOfAccount.businessId;
      const chartOfAccountId = businessChartOfAccount.chartOfAccountId;

      return deleteChartOfAccount({ id: chartOfAccountId, businessId })
        .then(() => {
          setFinished((prev) => prev + 1);
        }).catch((e: any) => {
          failedBusinessesChartOfAccounts.push(businessChartOfAccount);
          message = e.message || 'An unknown error occurred';
        });
    });

    await Promise.all(actions);

    if (failedBusinessesChartOfAccounts.length > 0) {
      setFailures(failedBusinessesChartOfAccounts);
      setIsDeleting(false);
      setErrorMessage(message);
    } else {
      toastr.success(`Chart of Account(s) are deleted from ${chartOfAccount.businessChartOfAccounts.length}/${chartOfAccount.businessChartOfAccounts.length} business(es).`, 'Success!');
      await queryClient.invalidateQueries(QueryKey.simpleChartOfAccounts);
    }
  };

  return (
    <Modal
      closeIcon={ <NotAChargebackIcon fontSize={ 24 } /> }
      show={ isOpen }
      title={ title }
      onCancel={ cancel }
    >
      <Modal.Body>
        { content }
      </Modal.Body>

      { !errorMessage
        && (
        <Modal.Footer>
          <div className={ styles.footer }>
            <Button variant="link" onClick={ cancel }>
              Cancel
            </Button>

            <Button variant="destructive" onClick={ onDelete }>
              Delete
            </Button>
          </div>
        </Modal.Footer>
        )}
    </Modal>
  );
};

const useDeleteModal = makeUseModal(DeleteModal);
export default useDeleteModal;
