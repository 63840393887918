import React, { useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetReviewedBankStatements } from '@src/hooks/queries/bank_statements';
import { useSorting, useURLParams } from '@src/hooks/url_params';
import { TReviewedStatementSortColumn } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';
import { formatApiYear } from '@src/utils/date_helpers';

import { useBankStatementUploadModal } from '../../bank_statement_details/upload_modal';
import { IReviewedStatementViewFilter } from './filter/types';
import List from './list';

interface IReviewedStatementProps {
  businessId: TID,
}

const DefaultSorting: ISortingParams<TReviewedStatementSortColumn> = {
  orderColumn:    'banking_account_name',
  orderDirection: 'asc',
};

const ReviewedStatement = ({
  businessId,
}: IReviewedStatementProps): JSX.Element => {
  const { filter } = useURLParams<
    IReviewedStatementViewFilter,
    TReviewedStatementSortColumn
  >({
    businessId,
    section:        window.Docyt.Common.Constants.REVIEWED_BANK_STATEMENT_TYPE,
    defaultSorting: DefaultSorting,
    filterInitData: { year: formatApiYear(new Date()) },
  });
  const sorting = useSorting<TReviewedStatementSortColumn>({ defaultSorting: DefaultSorting });

  const query = useGetReviewedBankStatements({
    businessId,
    filter: filter.data,
  });

  const reviewedBankStatements = useMemo(() => {
    const reviewedBankStatementsPages = query.data?.pages || [];
    return flatten(reviewedBankStatementsPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const uploadModal = useBankStatementUploadModal();

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
      />
      <List
        businessId={ businessId }
        filter={ filter }
        query={ query }
        reviewedBankStatements={ reviewedBankStatements }
        sorting={ sorting }
        onUploadFile={ uploadModal.open }
      />
    </>
  );
};

export default ReviewedStatement;
