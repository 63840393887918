import React from 'react';

import AddBtn from '@src/components/chart_of_accounts/add_btn';
import { useSimpleChartOfAccounts } from '@src/components/chart_of_accounts/hooks';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import Table from './table';

import styles from './styles.module.scss';

const List = () => {
  const collection = useSimpleChartOfAccounts();

  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        <div className={ styles.title }>Chart of Accounts</div>
        <div className={ styles.toolbar }>
          <AddBtn />
        </div>
      </div>

      <div className="body">
        <Section.Provider section={ collection.section }>
          <TableSection>
            <Table
              infiniteQuery={ collection.query }
              records={ collection.records }
              sorting={ collection.sorting }
            />
          </TableSection>
        </Section.Provider>
      </div>
    </div>
  );
};

export default List;
