import React from 'react';

import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';

import styles from './styles.module.scss';

interface IFinItemViewProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
}

const FinItemView = ({
  financialInstitutionConnection,
}: IFinItemViewProps) => {
  return (
    <div className={ styles['fin-connection-item'] }>
      <div className="display-flex align-items-center">
        <FinancialInstitutionAvatar
          logo={ financialInstitutionConnection.financialInstitution.logo }
        />
        <span className="m-l-10">
          { financialInstitutionConnection.financialInstitution.name }
        </span>
      </div>
      {
        financialInstitutionConnection.financialInstitutionBankAccounts
          .map((financialInstitutionBankAccount) => (
            <div key={ financialInstitutionBankAccount.id } className="account-item">
              { financialInstitutionBankAccount.name }
              { ' ' }
              **** **** ****
              { ' ' }
              { financialInstitutionBankAccount.last4 }
            </div>
          ))
      }
    </div>
  );
};

export default React.memo(FinItemView);
