import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useSetBankStatementToTransactionServiceDocuments } from '@src/hooks/queries/bank_statements';
import { IBankStatement, TBankFeedTransactionServiceDocumentColumn } from '@src/types/bank_statements';
import { TDate, TID } from '@src/types/common';
import { ISorting } from '@src/types/sorting';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Result from './result';
import Item from './table_item';

import styles from '../../../styles.module.scss';

interface ITransactionsListProps {
  bankStatement: IBankStatement,
  transactions: ITransactionServiceDocument[];
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBankFeedTransactionServiceDocumentColumn>,
  selectedTransactionsCount: number;
  hasEmployeeCards: boolean,
  allSetBankStatement: boolean,
  businessId: TID,
  startDate?: TDate,
  endDate?: TDate,
  isCreditCard: boolean,
}
const TransactionsList = ({
  businessId,
  bankStatement,
  transactions,
  query,
  sorting,
  selectedTransactionsCount,
  hasEmployeeCards,
  allSetBankStatement,
  startDate,
  endDate,
  isCreditCard,
}: ITransactionsListProps): JSX.Element => {
  const setBankStatement = useSetBankStatementToTransactionServiceDocuments();
  const { mutate: setStatement } = setBankStatement;

  const handleChange = useCallback((e) => {
    const selected = e.target.checked;
    const tsds = transactions.filter((tsd) => tsd.bankStatement?.state !== 'verified');
    setStatement({
      businessId,
      id:             bankStatement.id,
      transactionIds: tsds.map((t) => t.id),
      from:           startDate,
      to:             endDate,
      selected,
    });
  }, [bankStatement.id, businessId, endDate, setStatement, startDate, transactions]);

  return (
    <>
      <Table
        className={ styles['bank-feed-transactions-table'] }
        infiniteQuery={ query }
        wrapperClassName="banking-accounts-table-wrapper bank-feed-transactions-table-wrapper"
      >
        <Table.Head>
          <Table.Row>
            <Table.HCell widthPercent={ 8 }>
              {
                setBankStatement.isLoading ? (
                  <div className="checkbox-loader checkbox-item" />
                ) : (
                  <div className="checkbox checkbox-primary checkbox-item">
                    <input
                      checked={ allSetBankStatement }
                      disabled={ bankStatement?.state
                        === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED }
                      title="Select"
                      type="checkbox"
                      onChange={ handleChange }
                    />
                    <label
                      aria-label="Select"
                      htmlFor={ String(allSetBankStatement) }
                    />
                  </div>
                )
              }
            </Table.HCell>
            <Table.HCell<TBankFeedTransactionServiceDocumentColumn>
              columnName="transaction_date"
              sorting={ sorting }
              widthPercent={ 15 }
            >
              Date
            </Table.HCell>
            {
              hasEmployeeCards && (
                <Table.HCell<TBankFeedTransactionServiceDocumentColumn>
                  columnName="employee_card_name"
                  sorting={ sorting }
                  widthPercent={ 40 }
                >
                  Employee Card
                </Table.HCell>
              )
            }
            <Table.HCell<TBankFeedTransactionServiceDocumentColumn>
              columnName="description"
              sorting={ sorting }
              widthPercent={ hasEmployeeCards ? 10 : 40 }
            >
              Description
            </Table.HCell>
            <Table.HCell<TBankFeedTransactionServiceDocumentColumn>
              columnName="amount"
              sorting={ sorting }
              widthPercent={ hasEmployeeCards ? 10 : 25 }
            >
              { hasEmployeeCards ? 'Amount' : 'Transaction Amount' }
            </Table.HCell>
            <Table.HCell<TBankFeedTransactionServiceDocumentColumn>
              widthPercent={ 15 }
            />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {
            transactions.map((transaction) => (
              <Item
                key={ transaction.id }
                bankStatement={ bankStatement }
                hasEmployeeCards={ hasEmployeeCards }
                isCreditCard={ isCreditCard }
                transaction={ transaction }
              />
            ))
          }
        </Table.Body>
      </Table>
      <Result selectedCount={ selectedTransactionsCount } totalCount={ transactions.length } />
    </>
  );
};

export default TransactionsList;
