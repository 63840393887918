import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateNoRevenueDateParams,
  ICreateNoRevenueDateResponse,
  IDeleteNoRevenueDateParams,
  IDeleteNoRevenueDateResponse,
  IGetNoRevenueDateParams,
  IGetNoRevenueDatesResponse,
  createNoRevenueDate,
  deleteNoRevenueDate,
  getNoRevenueDates,
} from '@src/requests/no_revenue_dates';

const useCreateNoRevenueDate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateNoRevenueDateResponse,
    Error,
    ICreateNoRevenueDateParams
  >(
    createNoRevenueDate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.noRevenueDates);
      },
    },
  );
};

const useDeleteNoRevenueDate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IDeleteNoRevenueDateResponse,
    Error,
    IDeleteNoRevenueDateParams
  >(
    deleteNoRevenueDate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.noRevenueDates);
      },
    },
  );
};

const useGetNoRevenueDates = (
  params: IGetNoRevenueDateParams,
) => {
  return useQuery<IGetNoRevenueDatesResponse, Error>(
    [QueryKey.noRevenueDates, params],
    () => getNoRevenueDates(params),
  );
};

export {
  useCreateNoRevenueDate,
  useDeleteNoRevenueDate,
  useGetNoRevenueDates,
};
