import React from 'react';

import moment from 'moment';

import toastr from '@lib/toastr';
import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useGetBankAccountReconciliationPDF } from '@src/hooks/queries/bank_account_reconciliations';

import { getReconciliationDateRange } from '@src/components/reconciliation_center/month_end/utils';
import Text from '@src/components/ui/text';
import Tooltip from '@src/components/ui_v2/tooltip';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import DownloadDocumentIcon from '../../../utils/icomoon/download_document';

import styles from '@src/components/reconciliation_center/month_end/styles.module.scss';

const DownloadPDF = () => {
  const baReconciliation = useBankAccountReconciliationContext();
  const bankStatement = useBankStatementContext();
  const [startDate] = getReconciliationDateRange(baReconciliation, bankStatement);
  const { mutateAsync, isLoading } = useGetBankAccountReconciliationPDF();

  const pdfName = `${moment(startDate).format('MMMYY')}_Reconciliation_Report.pdf`;

  const handleClick = async () => {
    try {
      const data = await mutateAsync(baReconciliation.id);
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', pdfName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      const errorMessage = (error as Error).message || 'Failed to download PDF.'
      toastr.error(errorMessage, 'Error');
    }
  };

  const tooltipContent = <Text as="p">{ pdfName }</Text>;

  if (isLoading) return <SpinnerIcon spin fontSize={ 14 } />;

  return (
    <Tooltip.Hover content={ tooltipContent } tooltipClassName={ styles['note-tooltip'] }>
      <DownloadDocumentIcon pointer fontSize={ 14 } inColor="black" onClick={ handleClick } />
    </Tooltip.Hover>
  );
};

export default DownloadPDF;
