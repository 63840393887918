import React, { useState } from 'react';

import { clientBusinessSettingsPath, clientCenteralPath, clientManagementPath, documentRequestsPath } from '@src/routes';
import { IClientBusiness } from '@src/types/clients';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ClientCentralIcon, SetupIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IBusinessInboxListItemActionsProps {
  clientBusiness: IClientBusiness,
}

const BusinessInboxListItemActions = ({
  clientBusiness,
}: IBusinessInboxListItemActionsProps): JSX.Element => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  return (
    <ActionsDropdown>
      <ActionsDropdown.LinkAction
        className={ styles['action-menu'] }
        href={ sunsetClientCentralEnabled
          ? documentRequestsPath(clientBusiness.clientBusinessId)
          : clientCenteralPath(clientBusiness.clientBusinessId) }
        icon={ <ClientCentralIcon fontSize={ 18 } /> }
        title="View in Client Central"
      />
      <ActionsDropdown.LinkAction
        className={ styles['action-menu'] }
        href={ clientManagementPath() }
        icon={ <img alt="client_management" className={ styles['img-client-management'] } src={ window.configData.dashboard_images.CLIENT_MANAGEMENT } /> }
        title="Go to Client Management"
      />
      <ActionsDropdown.LinkAction
        className={ styles['action-menu'] }
        href={
          clientBusinessSettingsPath(clientBusiness.clientBusinessId, clientBusiness.businessId)
        }
        icon={ <SetupIcon fontSize={ 28 } /> }
        title="Business Settings"
      />
    </ActionsDropdown>
  );
};

export default BusinessInboxListItemActions;
