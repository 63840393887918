import React, { useCallback } from 'react';

import classNames from 'classnames';

import { IStandardDocument } from '@src/types/standard_documents';

interface ISelectStandardDocumentInputItemProps {
  document: IStandardDocument,
  selected?: boolean,
  onSelect: (document: IStandardDocument) => void,
}

const SelectStandardDocumentInputItem = ({
  document,
  selected,
  onSelect,
}: ISelectStandardDocumentInputItemProps): JSX.Element => {
  const handleClick = useCallback((e) => {
    e.preventDefault();

    onSelect(document);
  }, [document, onSelect]);

  const classes = classNames(
    'pointer border-bottom-gray font-16 m-b-0 m-l-20 m-r-20 p-b-10 p-t-10 standard-document-item',
    { active: selected },
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <p
      key={ document.id }
      className={ classes }
      onClick={ handleClick }
    >
      { document.name }
    </p>
  );
};

export default SelectStandardDocumentInputItem;
