import React from 'react';

import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface IBalanceSheetStatementViewProps {
  fileName?: string,
  fileUrl: string | Uint8Array,
  showStatementName?: boolean,
  className?: string,
}

const BalanceSheetStatementView = ({
  fileName,
  fileUrl,
  showStatementName = true,
  className = 'height-100-percent',
}: IBalanceSheetStatementViewProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className={ className }>
      { showStatementName && <span className="font-bold font-18">{ fileName }</span> }
      <Viewer
        withCredentials
        fileUrl={ fileUrl }
        plugins={ [defaultLayoutPluginInstance] }
        theme={ {
          theme: 'dark',
        } }
      />
    </div>
  );
};

export default BalanceSheetStatementView;
