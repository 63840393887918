import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const ThreeDotsIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="3-dots"
    />
  );
};

export default ThreeDotsIcon;
