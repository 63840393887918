import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DefaultAvatarIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="default-avatar"
      pathsNumber={ 4 }
    />
  );
};

export default DefaultAvatarIcon;
