import React from 'react';

import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  MissingStatementIcon,
} from '@src/components/utils/icomoon';

import MarkAsNoAvailableAction from './actions/mark_as_no_available_action';
import RequestStatementAction from './actions/request_statement_action';
import UploadStatementAction from './actions/upload_statement_action';

import styles from '../../styles.module.scss';

interface IMissingStatusItemProps {
  businessId: TID,
  chartOfAccountId: TID,
  state: IStatementState,
}

const MissingStatusItem = ({
  businessId,
  chartOfAccountId,
  state,
}: IMissingStatusItemProps): JSX.Element => {
  const missingTooltip = (
    <div className="not-available-status">
      <div className={ styles['state-status'] }>
        <MissingStatementIcon fontSize={ 20 } />
        <span className={ styles['statement-state'] }>Document Missing</span>
      </div>
    </div>
  );

  const content = (
    <Tooltip.Hover className={ styles['statement-item'] } content={ missingTooltip } tooltipClassName={ styles['missing-state-item'] }>
      <MissingStatementIcon fontSize={ 20 } />
    </Tooltip.Hover>
  );

  const toggleButton = (
    <Dropdown.ToggleButton className={ styles['status-item-button'] }>
      { content }
    </Dropdown.ToggleButton>
  );
  return (
    <ActionsDropdown toggleButton={ toggleButton }>
      <UploadStatementAction
        businessId={ businessId }
        chartOfAccountId={ chartOfAccountId }
        month={ state.month }
      />
      <RequestStatementAction
        businessId={ businessId }
        chartOfAccountId={ chartOfAccountId }
        state={ state }
      />
      <MarkAsNoAvailableAction
        businessId={ businessId }
        chartOfAccountId={ chartOfAccountId }
        state={ state }
      />
    </ActionsDropdown>
  );
};

export default MissingStatusItem;
