import { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { useGetRevenueServiceDocument } from '@src/hooks/queries/revenue_service_documents';
import { TDate, TID } from '@src/types/common';
import { IRevenueServiceDocumentLineItem } from '@src/types/revenue_service_document_line_items';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';

import { calculateValue } from '@src/components/revenue_center/resolve_revenue_report/helpers';

interface IResolveRevenueReportDataProps {
  rsdId: TID,
  revenueService: IRevenueService,
  onSuccess: (rsdId: TID, items: IRevenueServiceDocumentLineItem[], reportDate: TDate) => void;
}

interface IRevenueReportLineItemsResponse {
  items: IRevenueServiceDocumentLineItem[];
  report?: IRevenueServiceDocument,
  query: UseQueryResult<IRevenueServiceDocument, Error>;
  rsdId: TID,
  reportDate?: TDate,
}

const transformToLineItems = (
  data: IRevenueServiceDocument,
  revService: IRevenueService,
): IRevenueServiceDocumentLineItem[] => {
  const revenueServiceDocumentCategoryValues = data.revenueServiceDocumentCategoryValues;
  const revenueReportTypeCategories = revService.revenueReportTypes
    .find((type) => type.id === data.revenueReportTypeId)?.revenueReportTypeCategories;

  if (!revenueReportTypeCategories) return [];

  const items: (IRevenueServiceDocumentLineItem)[] = revenueReportTypeCategories.map((category) => {
    const revenueServiceDocumentCategoryValue =
      revenueServiceDocumentCategoryValues.find((value) => {
        return value.revenueReportTypeCategoryId === category.id;
      });
    const matchedCategory =
      revService.revenueServiceCategories.find((c) => c.id === category.revenueServiceCategoryId);

    if (!matchedCategory) return null;
    return {
      revenueServiceDocumentId:              data.id,
      revenueServiceCategoryId:              matchedCategory.id,
      revenueServiceDocumentCategoryValueId: revenueServiceDocumentCategoryValue?.id || null,
      revenueReportTypeCategoryId:           category.id,
      chartOfAccountId:                      matchedCategory.chartOfAccountId,
      paymentProcessorId:                    matchedCategory.paymentProcessorId,
      accountingClassName:                   matchedCategory.accountingClass?.name,
      accountingClassId:                     matchedCategory.accountingClassId,
      lineItemName:                          category.lineItemName,
      chartOfAccountName:                    matchedCategory.chartOfAccount?.displayName || null,
      isDebit:                               matchedCategory.isDebit || matchedCategory.isDebit === null || matchedCategory.isDebit === undefined,
      debitValue:                            calculateValue(matchedCategory.isDebit, matchedCategory.section, revenueServiceDocumentCategoryValue),
      creditValue:                           calculateValue(!matchedCategory.isDebit, matchedCategory.section, revenueServiceDocumentCategoryValue),
      useNetChange:                           matchedCategory.section === 'other_ledgers' || revenueServiceDocumentCategoryValue?.netChange ? "net_change" : "value"
    };
  }).filter((item) => item !== null) as IRevenueServiceDocumentLineItem[];

  return items;
};

const useRevenueReportLineItems = ({
  rsdId,
  revenueService,
  onSuccess,
}: IResolveRevenueReportDataProps): IRevenueReportLineItemsResponse => {
  const query = useGetRevenueServiceDocument(rsdId, {
    onSuccess: (data) => {
      if (!data || !data.endDate) return;
      const items = transformToLineItems(data, revenueService);
      onSuccess(rsdId, items, data.endDate);
    },
  });

  const records: IRevenueServiceDocumentLineItem[] = useMemo(() => {
    if (!query.data) return [];
    return transformToLineItems(query.data, revenueService);
  }, [query.data, revenueService]);

  return {
    items:      records,
    report:     query.data,
    query,
    rsdId,
    reportDate: query.data?.endDate,
  };
};

export default useRevenueReportLineItems;
