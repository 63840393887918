import React, { useCallback } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import Table from '@src/components/ui/table/table';

import { doLater } from '../utils';
import MicroDepositItem from './micro_deposit_item';

import styles from './styles.module.scss';

interface IMicroDepositListProps {
  invitation: ISelfOnboardingInvitation,
}

const MicroDepositList = ({
  invitation,
}: IMicroDepositListProps) => {
  const handleDoLater = useCallback(() => {
    doLater(invitation.onboardingInformation);
  }, [invitation.onboardingInformation]);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_AUTHORIZED_SIGN}`,
      { trigger: true },
    );
  }, []);

  return (
    <div className={ styles['self-onboarding-deposit-container'] }>
      <div className="tasks-container">
        <h2>Verify Operating Bank Account</h2>
        <p className="font-11 in-grey-1050">
          Check your below account(s) for micro-deposits from Docyt.
          Once verified, you&apos;re all set for seamless ACH and check payments at Docyt.
        </p>
        <div className="tasks-view">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HCell widthPercent={ 18 }>Business Name</Table.HCell>
                <Table.HCell widthPercent={ 30 }>Operating Bank Account</Table.HCell>
                <Table.HCell widthPercent={ 12 }>Last4</Table.HCell>
                <Table.HCell widthPercent={ 15 }>Deposit 1</Table.HCell>
                <Table.HCell widthPercent={ 15 }>Deposit 2</Table.HCell>
                <Table.HCell />
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
              invitation.businesses.map((business) => (
                <MicroDepositItem key={ business.id } business={ business } />
              ))
            }
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="setup-client-footer">
        <a
          className="cancel-link pointer"
          role="button"
          tabIndex={ -1 }
          onClick={ onBack }
        >
          Previous
        </a>
        <a
          className="cancel-link pointer pull-right"
          role="button"
          tabIndex={ -1 }
          onClick={ handleDoLater }
        >
          I&apos;ll do later
        </a>
      </div>
    </div>
  );
};

export default MicroDepositList;
