/* global gapi */

import loadjs from 'loadjs';

const loadGoogleApi = (googleClientId?: string, googleApiKey?: string): Promise<[void, void]> => {
  const mapsLoading = loadjs(
    `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
    { returnPromise: true },
  ).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error on loading Google Maps API: ', error);
  });

  const gapiLoading = loadjs('https://apis.google.com/js/client:platform.js?onload=google_start', {
    returnPromise: true,
    before:        () => {
      // eslint-disable-next-line func-names
      window.google_start = function () {
        gapi.load('auth2', () => {
          window.google_auth2 = gapi.auth2.init({
            client_id: googleClientId,
            scope:     'https://www.googleapis.com/auth/contacts.readonly',
          });
        });
      };
    },
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error on loading Google API: ', error);
  });

  return Promise.all([mapsLoading, gapiLoading]);
};

export { loadGoogleApi };
