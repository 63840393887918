import {
  ICombinedServiceDocument,
  ICombinedServiceDocumentQueryFilter,
  TCombinedServiceDocumentsSortColumn,
} from '@src/types/combined_service_documents';
import { TID, TOrderDirection } from '@src/types/common';
import { camelizeKeys, false2Undefined, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetCombinedServiceDocumentsParams {
  businessId: TID,
  orderColumn?: TCombinedServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
  reconciliation: boolean,
  reconciliationScope: boolean,
  withoutPettyCash: boolean,
  withoutInterSettlementAccount: boolean,
  withoutMatches: boolean,
  filter?: ICombinedServiceDocumentQueryFilter,
}

interface IGetCombinedServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: ICombinedServiceDocument[],
}

const getCombinedServiceDocuments = (
  params: IGetCombinedServiceDocumentsParams,
): Promise<IGetCombinedServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/combined_service_documents',
    underscoreKeys(
      false2Undefined(
        params,
        [
          'reconciliation',
          'reconciliationScope',
          'withoutPettyCash',
          'withoutInterSettlementAccount',
          'withoutMatches',
        ],
      ),
    ),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta:       cdata.meta,
      collection: cdata.combinedServiceDocuments as ICombinedServiceDocument[],
    };
  });
};

export {
  IGetCombinedServiceDocumentsParams,
  IGetCombinedServiceDocumentsResponse,
  getCombinedServiceDocuments,
};
