import React from 'react';

import { useFormContext } from 'react-hook-form';

import { FormValues } from '@src/types/adjustment_entries';

import styles from './styles.module.scss';

const TableFooter = () => {
  const { formState: { errors, isSubmitted } } = useFormContext<FormValues>();

  return (
    <div>
      <div className={ `${styles['table-row']} ${styles['table-sum-row']}` } />
      {isSubmitted && errors.totalAmount && (
        <p className={ `${styles['error-message']} ${styles['total-amount-error-message']}` }>
          {errors.totalAmount.message}
        </p>
      )}
      {isSubmitted && errors.totalLines && (
        <p className={ `${styles['error-message']} ${styles['total-amount-error-message']}` }>
          {errors.totalLines.message}
        </p>
      )}
    </div>

  );
};

export default TableFooter;
