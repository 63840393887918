import React, { useCallback, useState } from 'react';

import Container from './container';
import Control from './control';
import { TRichAmount, TRichAmountType } from './types';

interface RichAmountInputProps {
  value: TRichAmount | undefined,
  isInvalid?: boolean;
  valueProps?: React.ComponentPropsWithoutRef<typeof Control>['valueProps'],
  onChange: (value: TRichAmount | undefined) => void;
}

const hasValue = (type: TRichAmountType, value: TRichAmount | undefined): boolean => {
  if (!value) return false;
  if (type === 'range') return Boolean(value.gte) || Boolean(value.lte);

  return Boolean(value.val);
};

const RichAmountInput = ({
  value,
  valueProps,
  onChange,
  isInvalid = false,
}: RichAmountInputProps) => {
  const [type, setType] = useState<TRichAmountType>(() => {
    if (value?.val) return 'amount';

    if (value?.gte || value?.lte) return 'range';

    return 'amount';
  });

  const [isFocus, setFocus] = useState<boolean>(false);

  const handleClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const handleTypeSelected = useCallback((newType: TRichAmountType) => {
    setType(newType);
    setFocus(true);
  }, []);

  const handleAmountInputRef = useCallback((input) => {
    if (!isFocus) return;
    if (!input) return;

    input.focus();
  }, [isFocus]);

  return (
    <Container
      currentType={ type }
      hasValue={ hasValue(type, value) }
      isInvalid={ isInvalid }
      onClear={ handleClear }
      onTypeSelected={ handleTypeSelected }
    >
      <Control
        ref={ handleAmountInputRef }
        currentType={ type }
        value={ value }
        valueProps={ valueProps }
        onChange={ onChange }
      />
    </Container>
  );
};

export default React.memo(RichAmountInput);
