module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="dropdown-toggle disabled" id="bulk-actions-dropdown-btn" data-toggle="dropdown">\n  <span class="font-bold">Bulk Actions</span>\n  <span class="fa fa-caret-down"></span>\n</div>\n<ul class="dropdown-menu" aria-labelledby="add-import-vendors-dropdown-btn">\n  ');
    
      if (this.is_all_eligibility) {
        __out.push('\n  <li class="remove-tracking-clickable">\n    <span class="icon-1099-icon-deactive font-20"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>\n    Remove tracking for 1099 Generation\n  </li>\n  ');
      } else {
        __out.push('\n  <li class="set-tracking-clickable">\n    <span class="icon-1099-icon font-24 m-r-5 vertical-align-middle-important"></span>\n    Track to Generate 1099\n  </li>\n  ');
      }
    
      __out.push('\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}