import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateReconciliationPaymentAccount } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TAccountType, TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { WarningTriangleWhiteIcon } from '@src/components/utils/icomoon';

import NewAccountForm from './new_account_form';
import { ISetupAccountData } from './schema';

interface ISetupAccountProps extends IUseModalProps {
  accountType: TAccountType,
  financialConnectionBankAccount: TID,
  managementGroupId: TID,
  refetchFinancialConenctionList: () => void
}

const SetupAccount = ({
  accountType,
  financialConnectionBankAccount,
  managementGroupId,
  isOpen,
  onCancel,
  onDone,
  refetchFinancialConenctionList,
}: ISetupAccountProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const update = useUpdateReconciliationPaymentAccount();
  const { mutate } = update;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleDone = useCallback(() => {
    refetchFinancialConenctionList();
    toastr.success(`${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'} has been added successfully.`, 'Success');
    onDone();
  }, [onDone, refetchFinancialConenctionList, accountType]);

  const handleCreateMapping = useCallback((data?: ISetupAccountData) => {
    if (data === undefined) return;

    mutate({
      accountId:     data.reconciliationPaymentAccount.id,
      source:        'fic_listing',
      isMultiEntity: data.isMultiEntity === 'true',
      businessId:    data.businessId,
      data:          {
        // TODO: hard coded values for QBO for api to work, will be changed later as per product discussion
        // qboId:                             String(112),
        // qboName:                           'Auto Matching',
        name:                              data.reconciliationPaymentAccount.name,
        startImportDate:                   formatDate(data.startImportDate, 'YYYY/MM/DD'),
        plaidStartImportDate:              formatDate(data.startImportDate, 'YYYY/MM/DD'),
        aggregatorStartImportDate:         formatDate(data.startImportDate, 'YYYY/MM/DD'),
        transactionImportType:             'auto',
        financialInstitutionBankAccountId: financialConnectionBankAccount,
        last4:                             data.reconciliationPaymentAccount.last4,
      },
    }, {
      onSuccess: handleDone,
      onError:   handleError,
    });
  }, [mutate, handleDone, handleError, financialConnectionBankAccount]);

  const handleSubmit = useCallback((data: ISetupAccountData) => {
    if (!accountType) return;

    handleCreateMapping(data);
  }, [accountType, handleCreateMapping]);

  const title = `Map ${accountType === 'credit_card' ? 'Credit Card' : 'Bank account'}`;
  return (
    <SideView.Form
      doneTitle={ title }
      isOpen={ isOpen }
      title={ title }
      onCancel={ onCancel }
    >
      { ({ formId }) => (
        <div
          className="map-bank-account-or-credit-card-view"
        >
          {
              update.isLoading && <Spinner />
            }
          {
              errorMessage && (
                <SideView.Error>
                  <WarningTriangleWhiteIcon fontSize={ 18 } />
                  <div className="error-message">
                    <span>Account could not be mapped.</span>
                    <span>{ errorMessage }</span>
                  </div>
                </SideView.Error>
              )
            }
          <NewAccountForm
            accountType={ accountType }
            formId={ formId }
            managementGroupId={ managementGroupId }
            onSubmit={ handleSubmit }
          />
        </div>
      ) }
    </SideView.Form>
  );
};

const MemoizedSetupAccount = React.memo(SetupAccount);
const useSetupAccount = makeUseModal(MemoizedSetupAccount);

export {
  useSetupAccount,
  SetupAccount as default,
};
