import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

import Form, { IRequestDocumentFormValues } from './form';

interface IRequestDocumentModalProps extends Omit<IUseModalProps, 'onDone'> {
  chartOfAccountName?: string,
  month?: string | number,
  onDone: (values: IRequestDocumentFormValues) => void,
}

const RequestDocumentModal = ({
  chartOfAccountName,
  month,
  isOpen,
  onCancel,
  onDone,
}: IRequestDocumentModalProps) => {
  const handleSubmit = useCallback((values: IRequestDocumentFormValues) => {
    onDone(values);
  }, [onDone]);
  return (
    <Modal.Form
      proceedTitle="Submit"
      show={ isOpen }
      title="Request Document"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <Form
          chartOfAccountName={ chartOfAccountName }
          formId={ formId }
          month={ month }
          onSubmit={ handleSubmit }
        />
      )}
    </Modal.Form>
  );
};

const useRequestDocumentModal = makeUseModal(RequestDocumentModal);

export {
  IRequestDocumentModalProps,
  useRequestDocumentModal,
  RequestDocumentModal as default,
};
