import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IConfirmEmailVerificationParams,
  createEmailVerificaiton,
  resendEmailVerificaiton,
  confirmEmailVerification,
} from '@src/requests/docyt_check_email_verifications';

const useCreateEmailVerificaiton = () => {
  return useMutation<void, Error>(
    createEmailVerificaiton,
  );
};

const useResendEmailVerificaiton = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error>(
    resendEmailVerificaiton,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.docytCheckEmailVerifications);
      },
    },
  );
};

const useConfirmEmailVerification = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IConfirmEmailVerificationParams>(
    confirmEmailVerification,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.docytCheckEmailVerifications);
      },
    },
  );
};

export {
  useCreateEmailVerificaiton,
  useResendEmailVerificaiton,
  useConfirmEmailVerification,
};
