import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import Form from '@src/components/ui/form';

interface IRequestDocumentFormValues {
  chartOfAccountName: string,
  month: string | number,
  description?: string,
}

interface IRequestDocumentFormProps {
  chartOfAccountName?: string,
  month?: string | number,
  formId?: string,
  onSubmit: SubmitHandler<IRequestDocumentFormValues>,
}

const RequestDocumentForm = ({
  chartOfAccountName,
  month,
  formId,
  onSubmit,
}: IRequestDocumentFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
  } = useForm<IRequestDocumentFormValues>();

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        disabled
        label="Balance Sheet Account"
        value={ chartOfAccountName }
      />
      <Form.TextField
        disabled
        label="Month"
        value={ month }
      />
      <Form.TextField
        label="Description"
        placeholder="Enter Description"
        { ...register('description') }
      />
    </Form>
  );
};

export {
  IRequestDocumentFormProps,
  IRequestDocumentFormValues,
  RequestDocumentForm as default,
};
