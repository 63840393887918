import React from 'react';

import newPhotoLogoImg from '@assets/images/revenue_service/revenue_center_logo.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const RevenueCenterLogo = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Revenue Center Logo"
      src={ newPhotoLogoImg }
      { ...props }
    />
  );
};

export default RevenueCenterLogo;
