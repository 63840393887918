import React, { ChangeEvent, forwardRef, useCallback } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';
import { truncatedFileName } from '@src/utils/filename_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import { SwapUploadIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IFileInputProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange' | 'size' | 'value'> {
  value?: File,
  accept?: string,
  onChange?: (file?: File) => void,
}

const FileInput = forwardRef<HTMLInputElement, IFileInputProps>(({
  id,
  value,
  accept = '*',
  onChange,
  ...props
}: IFileInputProps, ref) => {
  const inputId = useUniqueDomId('file_input_');

  const handleFileSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  }, [onChange]);

  const handleClear = useCallback(() => {
    if (!onChange) return;

    onChange(undefined);
  }, [onChange]);

  return (
    <div>
      { value ? (
        <div className={ styles['uploaded-file-wrap'] }>
          <span className={ styles['uploaded-file-name'] }>
            { truncatedFileName(value.name, 10) }
          </span>
          <Button
            className={ styles['remove-file-button'] }
            variant="link"
            onClick={ handleClear }
          >
            <span>&times;</span>
          </Button>
        </div>
      ) : (
        <label className={ styles['file-input-wrap'] } htmlFor={ id || inputId }>
          <input
            ref={ ref }
            accept={ accept }
            className={ styles['file-input'] }
            id={ id || inputId }
            type="file"
            onChange={ handleFileSelected }
            { ...props }
          />
          <SwapUploadIcon fontSize={ 16 } mr={ 5 } />
          Upload
        </label>
      )}
    </div>
  );
});

FileInput.displayName = 'FileInput';

export default React.memo(FileInput);
