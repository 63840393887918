module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n  <div class="m-t-40 m-b-40 relative text-right header-panel m-l-auto m-r-auto">\n    <h1 class="font-24 font-bold text-center">Sign in</h1>\n  </div>\n  <div class="background-white sign-in-main-panel m-l-auto m-r-auto">\n    <form class="login-form-sign-in">\n      <div class="padding-50">\n          ');
    
      if (!this.isEmailSelected) {
        __out.push('\n          <p class=\'main-input__hint in-red-400\' id="invalid-phone"><i class="fa fa-warning m-r-15"></i>This phone number is not a valid phone number.</p>\n          <p class=\'main-input__hint in-red-400\' id="invalid-phone-number"><i class="fa fa-warning m-r-15"></i>This phone is not registered.</p>\n          <div class="row relative m-t-10 m-b-30">\n              <input type="tel" name="phone" class="phone-input login-form__input main-input" placeholder="Enter Phone Number" tabindex="2">\n              <a class="font-14 font-semi-bold pointer" id="sign-in-email">Sign in using email</a>\n          </div>\n          ');
      } else {
        __out.push('\n          <p class=\'main-input__hint in-red-400\' id="invalid-email"><i class="fa fa-warning m-r-15"></i>There is no account with this email.</p>\n          <p class=\'main-input__hint in-red-400\' id="web-app-setuped"><i class="fa fa-warning m-r-15"></i>You have already setupted web account. Please sign in with your email.</p>\n          <div class="row relative m-t-10 m-b-30">\n              <input type="email" name="email" class="email-input login-form__input main-input m-b-10"  placeholder="Enter Email" tabindex="2">\n              <a class="font-14 font-semi-bold pointer" id="sign-in-phone">Sign in using phone number</a>\n          </div>\n          ');
      }
    
      __out.push('\n      </div>\n      <p class="sign-in-note">By providing your Phone number/Email and clicking Next, you accept <a href="https://www.docyt.com/terms/" target="_blank">Docyt\'s Terms</a> & <a href="https://www.docyt.com/privacy/" target="_blank">Privacy Policy</a>.</p>\n      <div class="sign-in-action-panel">\n          <a class="sign-up-button" href="/sign_up">Create New Account</a>\n          <button type=\'submit\' class="btn btn-primary pull-right continue-js ladda-button" data-color="blue" data-style="expand-right" tabindex="3"><span class="ladda-label font-semi-bold">Next</span></button>\n      </div>\n    </form>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}