import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { EmailFrequencyOptions } from '@src/constants/email_frequency_options';

import EmailFrequencyField from '@src/components/common_v2/form_fields/email_frequency_field';
import ManagementGroupSettings
  from '@src/components/settings/global/notifications/financial_insights_email/edit_modal/management_group_settings/management_group_settings';
import Form from '@src/components/ui_v2/form';

import {
  financialInsightsValidation,
  IFinancialInsightsInput,
} from './schema';

import styles from '../styles.module.scss';

interface IEditFinancialInsightsModalFormProps {
  formId?: string,
  onSubmit: SubmitHandler<IFinancialInsightsInput>,
  frequency: string,
}

const EditFinancialInsightsModalForm = ({
  formId,
  onSubmit,
  frequency,
}: IEditFinancialInsightsModalFormProps): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFinancialInsightsInput>({
    defaultValues: {
      frequency,
      selectedGroups: [],
    },
    resolver: yupResolver(financialInsightsValidation),
  });

  const frequencyController = useController({ name: 'frequency', control });
  const selectedGroupsController = useController({ name: 'selectedGroups', control });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ styles['edit-financial-insights-container'] }>
        <div className={ styles.frequency }>
          <EmailFrequencyField
            error={ errors.frequency?.message }
            label="Frequency"
            labelClass="width-50-percent"
            options={ EmailFrequencyOptions }
            { ...omit(frequencyController.field, 'ref') }
          />
        </div>
        <div className={ styles['management-group-settings'] }>
          <ManagementGroupSettings
            field={ selectedGroupsController.field }
          />
        </div>
      </div>
    </Form>
  );
};

export {
  IEditFinancialInsightsModalFormProps,
  EditFinancialInsightsModalForm as default,
};
