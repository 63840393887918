import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const VendorContractIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="vendor-contract"
      pathsNumber={ 8 }
    />
  );
};

export default VendorContractIcon;
