import React, { useCallback } from 'react';

import { useMoveToTrashTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import useDeleteTransactionErrorModalModal from '@src/components/banking_accounts/transactions/delete_transaction_error_modal';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDeleteActionProps {
  transaction: ITransactionServiceDocument,
}

const DeleteAction = ({
  transaction,
}: IDeleteActionProps): JSX.Element | null => {
  const moveToTrash = useMoveToTrashTransactionServiceDocument();
  const { mutate: moveToTrashTransactionServiceDocument } = moveToTrash;

  const deleteTransactionErrorModal = useDeleteTransactionErrorModalModal();

  const handleError = useCallback((response) => {
    if (response?.response?.data?.errors[0]
      === window.Docyt.Common.Constants.Messages.TRANSACTION_DELETE_ERROR_MSG) {
      deleteTransactionErrorModal.open();
    }
  }, [deleteTransactionErrorModal]);

  const handleDone = useCallback(() => {
    moveToTrashTransactionServiceDocument(
      { value: 'true', document_ids: [transaction.documentId] },
      { onError: handleError },
    );
  }, [moveToTrashTransactionServiceDocument, handleError, transaction.documentId]);

  const modal = useConfirmDeleteModal({
    onDone: handleDone,
  });

  return (
    <>
      <MutationStatus mutation={ moveToTrash } successMessage="Moved to Trash successfully" />
      <deleteTransactionErrorModal.Component
        { ...deleteTransactionErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.TRANSACTION_DELETE_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANKING_STATEMENT_VERIFIED_IN_MSG }
      />
      <modal.Component
        text={ window.Docyt.Common.Constants.Messages.BANKING_DELETE_BANK_FEED_TRANSACTION_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANKING_DELETE_BANK_FEED_TRANSACTION }
        { ...modal.props }
      />
      <ActionsDropdown.DangerAction
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        name="Delete from Bank Feed"
        onClick={ modal.open }
      />
    </>
  );
};

export default DeleteAction;
