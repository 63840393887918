import React, { useCallback } from 'react';

import { bankingAccountsPath, accountsPath, employeeCardsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { Button } from '@src/components/ui_v2/buttons';
import Breadcrumb from '@src/components/ui_v2/layout/header_region/breadcrumb';
import Breadcrumbs from '@src/components/ui_v2/layout/header_region/breadcrumbs';
import { PlusIcon } from '@src/components/utils/fa_icons';
import { BankAccountIcon, CorporateCardsIcon } from '@src/components/utils/icomoon';

import { useSetupNewEmployeeCard } from '../new_employee_card/new_employee_card';

interface IPageHeaderProps {
  businessId: TID
  rcPaymentAccount: IReconciliationPaymentAccount | undefined
  refetchEmployeeCards(): void
}

const PageHeader = ({ rcPaymentAccount, businessId, refetchEmployeeCards }: IPageHeaderProps) => {
  const setupNewEmployeeCard = useSetupNewEmployeeCard();

  const handleAddNewEmployeeCardButtonClick = useCallback(() => {
    setupNewEmployeeCard.openWithValue({
      accountType: rcPaymentAccount?.accountType === 'bank' ? 'bank' : 'credit_card',
      rcPaymentAccount,
    });
  }, [rcPaymentAccount, setupNewEmployeeCard]);

  // Called post adding a card, it refetches cards and closes modal.
  const handleOnDone = () => {
    refetchEmployeeCards(); // Refetch the list of employee cards to include the newly added card.
    setupNewEmployeeCard.props.onCancel();
  };

  return (
    <>
      <setupNewEmployeeCard.Component
        { ...setupNewEmployeeCard.props }
        onDone={ handleOnDone }
      />
      <div className="page-header-wrapper">
        <div className="breadcrumb-container">
          <div className="breadcrumb">
            {/* Breadcrums */}
            <Breadcrumbs>
              <Breadcrumb
                href={ bankingAccountsPath(businessId) }
              >
                {' '}
                Banking Accounts
                <span className="breadcrum-divider" />
              </Breadcrumb>
              <Breadcrumb
                href={ accountsPath(businessId) }
              >
                {' '}
                Accounts
                <span className="breadcrum-divider" />
              </Breadcrumb>
              {rcPaymentAccount?.id && (
                <Breadcrumb
                  href={ employeeCardsPath(businessId, rcPaymentAccount.id) }
                >
                  Employee Cards
                </Breadcrumb>
              )}
            </Breadcrumbs>
          </div>
        </div>
        <div className="header-add-employee-card-action-wrapper">
          <div className="page-header-title-container">
            <div className="header-icon">
              {(rcPaymentAccount?.accountType === 'bank') ? (
                <BankAccountIcon fontSize={ 40 } variant="normal" />
              ) : (
                <CorporateCardsIcon fontSize={ 40 } />
              )}
            </div>
            <div className="header-title">
              {rcPaymentAccount?.name}
            </div>
          </div>
          <Button variant="primary" onClick={ handleAddNewEmployeeCardButtonClick }>
            <PlusIcon />
            Add Employee Card
          </Button>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
