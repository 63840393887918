import { QueryKey } from '@src/constants/query_keys';
import { IGetBankAccountReconciliationDocumentsParams, IGetBankAccountReconciliationDocumentsResponse, getBankAccountReconciliationDocuments } from '@src/requests/bank_account_reconciliation_documents';
import { IBankAccountReconciliationDocument } from '@src/types/bank_account_reconciliation_document';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const useGetBankAccountReconciliationDocuments = createUseGetInfiniteCollection<
  IBankAccountReconciliationDocument,
  IGetBankAccountReconciliationDocumentsParams,
  IGetBankAccountReconciliationDocumentsResponse
>({
  queryKey: QueryKey.bankAccountReconciliationDocumentsQuery,
  request:  getBankAccountReconciliationDocuments,
});

const useGetPushedOutsideBankAccountReconciliationDocuments = createUseGetInfiniteCollection<
  IBankAccountReconciliationDocument,
  IGetBankAccountReconciliationDocumentsParams,
  IGetBankAccountReconciliationDocumentsResponse
>({
  queryKey: QueryKey.bankAccountReconciliationPushedOutside,
  request:  getBankAccountReconciliationDocuments,
});

export {
  useGetBankAccountReconciliationDocuments,
  useGetPushedOutsideBankAccountReconciliationDocuments,
};
