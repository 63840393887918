import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

import BankStatements from './bank_statements/bank_statements';

interface IBankStatementsPageProps {
  businessId: TID,
}

const BankStatementsPage = ({
  businessId,
}: IBankStatementsPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          <BankStatements businessId={ businessId } />
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default BankStatementsPage;
