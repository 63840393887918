import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { TDate } from '@src/types/common';

import Form from '@src/components/ui_v2/form';

import { statementValidation } from './schema';

interface IAssignDateFormValues {
  date: TDate
}

interface IAssignDateFormProps {
  formId: string,
  preSelectedDate?: TDate,
  onSubmit: SubmitHandler<IAssignDateFormValues>
  onChange?: (values: IAssignDateFormValues) => void,
}

const AssignDateForm = ({ formId, preSelectedDate, onSubmit }: IAssignDateFormProps) => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IAssignDateFormValues>({
    defaultValues: {
      date: preSelectedDate,
    },
    resolver: yupResolver(statementValidation),
  });

  const statementDateController = useController({ name: 'date', control });

  useEffect(() => {
    if (preSelectedDate) {
      setValue('date', preSelectedDate);
    } else {
      setValue('date', '');
    }
  }, [setValue, preSelectedDate]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.DateField
        error={ errors.date?.message }
        label="Date"
        placeholder="Select Date"
        { ...statementDateController.field }
      />
    </Form>

  );
};

export { IAssignDateFormProps, IAssignDateFormValues, AssignDateForm as default };
