import React, { useCallback, useMemo, useState } from 'react';

import { useUpdateDashboardUsers } from '@src/hooks/queries/dashboards/dashboard_users';
import { IBusinessUser } from '@src/types/business_users';
import { IBusinessSimple } from '@src/types/businesses';
import { IManagementGroupDashboard } from '@src/types/dashboards/dashboards';

import NoPermissionView from '@src/components/business_dashboards/dashboard/dashboard_no_permission_view';
import { TOption } from '@src/components/ui_v2/inputs';

import ContainerWrapper from './details_container_wrapper';
import { Header } from './header';

import styles from '../styles.module.scss';

interface IDashboardDetailsProps {
  dashboard: IManagementGroupDashboard,
  businesses: IBusinessSimple[],
  widgetId?: string,
}

const DashboardDetails = ({
  dashboard,
  businesses,
  widgetId,
}: IDashboardDetailsProps): JSX.Element => {
  const options = useMemo(() => {
    const businessOptions: TOption[] = [];
    businesses.forEach((business) => {
      businessOptions.push({
        value: String(business.id),
        label: business.name || '',
      });
    });

    return [{ value: '*', label: 'Select All' }, ...businessOptions];
  }, [businesses]);
  const [selectedOptions, setSelectedOptions] = useState<TOption[]>(options);

  const updateUsers = useUpdateDashboardUsers();

  const { mutate: updateDashboardUsers } = updateUsers;

  const handleShareDone = useCallback((accessibleUsers: IBusinessUser[]) => {
    updateDashboardUsers(
      {
        dashboardId: dashboard.id,
        userIds:     accessibleUsers.map((user) => user.id),
      },
      {
        onSuccess: () => {
          dashboard.dashboardUserIds = accessibleUsers.map((user) => user.id);
        },
      },
    );
  }, [dashboard, updateDashboardUsers]);

  const handleChange = useCallback((selectedValue: TOption[]) => {
    setSelectedOptions(selectedValue);
  }, []);

  const selectedBusinesses = useMemo(() => {
    const selectedIds = selectedOptions.filter((option: TOption) => option.value !== '*')
      .map((option) => Number(option.value));
    return businesses.filter((business) => selectedIds.includes(business.id));
  }, [selectedOptions, businesses]);

  return (
    <div className={ styles.details }>
      <Header
        dashboard={ dashboard }
        options={ options }
        selectedOptions={ selectedOptions }
        onChange={ handleChange }
        onShareDone={ handleShareDone }
      />
      {
        dashboard.hasAccess
          ? (
            <ContainerWrapper
              businesses={ selectedBusinesses }
              dashboard={ dashboard }
              widgetId={ widgetId }
            />
          )
          : (
            <NoPermissionView
              currentDashboard={ dashboard }
              isLoading={ updateUsers.isLoading }
              managementGroupId={ dashboard.managementGroupId }
              onShareDone={ handleShareDone }
            />
          )
      }
    </div>
  );
};

export default DashboardDetails;
