import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IBankAccountIconProps extends IIcomoonIconProps {
  variant: 'simple' | 'normal',
}
const BankAccountIcon = ({
  variant,
  ...props
}: IBankAccountIconProps) => {
  let iconName = `bank-account-${variant}`;
  if (variant === 'normal') {
    iconName = 'bank-account';
  }

  return (
    <Icomoon
      { ...props }
      iconName={ iconName }
      pathsNumber={ 11 }
    />
  );
};

export default BankAccountIcon;
