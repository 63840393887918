import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IIntegrationRunStatusUpdatedFayeEvent } from '@src/types/docyt_events/integration_run_status_updated';

import { useFayeChannel } from './common';

export const useIntegrationRunsChannel = (
  integrationId: TID,
  callback: TFayeEventCallback<IIntegrationRunStatusUpdatedFayeEvent>,
  enabled: boolean = true,
) => {
  useFayeChannel(
    `/integration/${integrationId}/runs`,
    callback,
    enabled,
  );
};
