module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-md modal-no-body-padding payment-account-select-modal\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title font-28 font-bold text-center">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h1>\n        </div>\n        <div class=\'modal-body m-b-20\'>\n            ');
    
      if (!this.pay_by_vendor_credit && this.invoice_ids.length === 1) {
        __out.push('\n            <div class="m-t-10">\n                <label class="m-l-20 form-label" for="invoice-date-input">Invoice Date</label>\n                <div class="form-inline relative">\n                  <input type="text" id="invoice-date-input" class="form-control bg-grey-300" value="');
        __out.push(__sanitize(moment(this.invoice_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('" readonly>\n                </div>\n            </div>\n            <div class="m-t-10">\n                <label class="m-l-20 form-label" for="due-date-input">Due Date</label>\n                <div class="form-inline relative">\n                  <input type="text" id="due-date-input" class="form-control bg-grey-300" value="');
        __out.push(__sanitize(moment(this.due_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
        __out.push('" readonly>\n                </div>\n            </div>\n            ');
      }
    
      __out.push('\n            <div class="m-t-10">\n                <label class="m-l-20 form-label" for="estimated-date-input">Estimated Date of Payment</label>\n                <div class="form-inline relative">\n                  <input type="text" id="estimated-date-input" class="form-control bg-grey-300" autocomplete="off">\n                  <p class="valid-hint-js in-red-400" style="position:absolute; top:0; right:10px"><i class="fa fa-warning"></i></p>\n                </div>\n            </div>\n            ');
    
      if (!(this.pay_by_cash || this.pay_by_vendor_credit)) {
        __out.push('\n            <div class="m-t-10">\n                <label class="m-l-20 form-label">Payment Account</label>\n                <div class="form-inline relative">\n                  <div class="payment-account-filter-region form-control padding-0"></div>\n                </div>\n            </div>\n            ');
      }
    
      __out.push('\n            ');
    
      if (this.pay_by_check) {
        __out.push('\n            <div class="m-t-10">\n                <label class="m-l-20 form-label">Check Number</label>\n                <div class="form-inline relative">\n                  <input type="text" class="form-control bg-grey-300 check-number-input" placeholder="Enter Check Number">\n                </div>\n                <p class="main-input__hint in-red-400 text-right p-r-95 invalid-check-number">Please input check number.</p>\n            </div>\n            <div class="text-center border-top-gray m-l-20 m-r-20 m-t-10 p-t-10">\n              <div class="m-l-30 m-r-30">\n                <img class="width-100-percent" src="');
        __out.push(__sanitize(configData.dashboard_images['MANUAL_CHECK']));
        __out.push('">\n              </div>\n            </div>\n            ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right" ');
    
      __out.push(__sanitize(this.pay_by_cash || this.pay_by_vendor_credit || this.payment_account.id ? '' : 'disabled'));
    
      __out.push('>\n            <span class="ladda-label">Mark as Paid</span>\n          </button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}