import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const StatementUploadedIcon = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="uploaded-statement"
      pathsNumber={ 4 }
    />
  );
});

StatementUploadedIcon.displayName = 'StatementUploadedIcon';

export default StatementUploadedIcon;
