import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { useForwardDocumentModal } from '@src/components/common/forward_document';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { ShareIcon } from '@src/components/utils/fa_icons';

interface IBusinessInboxItemForwardActionProps {
  document: IInboxServiceDocument,
}

const BusinessInboxItemForwardAction = ({
  document,
}: IBusinessInboxItemForwardActionProps) => {
  const business = useBusinessContext();
  const service = useInboxServiceContext();

  const forward = useForwardDocumentModal();

  return (
    <>
      <forward.Component
        { ...forward.props }
        business={ business }
        currentServiceType={ service.type }
        documentIds={ [document.id] }
      />
      <ActionsDropdown.Action
        icon={ <ShareIcon /> }
        name="Forward"
        onClick={ forward.open }
      />
    </>
  );
};

export default BusinessInboxItemForwardAction;
