import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import TableInfo from './review_merge_vendor_table';

interface IReviewMergeVendorDescriptionProps {
  localVendor: IBusinessVendor,
  globalVendor?: IBusinessVendor,
}

const ReviewMergeVendorDescription = ({
  localVendor,
  globalVendor,
}: IReviewMergeVendorDescriptionProps): JSX.Element | null => {
  if (!globalVendor) return null;

  return (
    <div className="font-14">
      <div className="review-description">
        <div className="merge-hint">
          {
            globalVendor.isClaimed
              ? (
                <span>
                  This vendor has verified their ACH and W-9 information with Docyt.
                  Merging with this vendor will use the vendors payment information for all
                  payments to this vendor going forward.
                  Please review the information below and determine if you would like to merge.
                </span>
              )
              : (
                <span>
                  Merging with this vendor will use the following information when paying
                  the vendor going forward.
                  Please review the information below and determine if you would like to merge.
                </span>
              )
          }
        </div>
      </div>
      <div className="display-flex font-semi-bold p-b-10">
        <div className="width-20-percent" />
        <div className="width-30-percent">Vendor</div>
        <div className="width-50-percent">Merge to</div>
      </div>
      <TableInfo
        globalVendor={ globalVendor }
        localVendor={ localVendor }
      />
    </div>
  );
};

export default React.memo(ReviewMergeVendorDescription);
