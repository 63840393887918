import { apiGet, apiPost } from '@src/requests/helpers';
import { IBillingBankAccount, IBillingCreditCard } from '@src/types/billing_payment_methods';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

interface ICreateBankAccountFromPlaidParams {
  businessId: number,
  plaidPublicToken: string,
  plaidAccountId: string,
  last4: string,
}

interface ICreateCreditCardParams {
  businessId: number,
  stripeToken: string,
  zip: string,
  last4: string,
  brand: string,
  cardId: string,
  expYear: number,
  expMonth: number,
}

interface IGetPaymentMethodsByBusinessIdsParams {
  businessIds: number[],
}

const getBankAccountsByBusinessIds = (
  params: IGetPaymentMethodsByBusinessIdsParams,
): Promise<IBillingBankAccount[]> => {
  return apiGet(
    '/billing/api/v1/bank_accounts/by_business_ids',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    return camelizeKeys(data.bank_accounts) as IBillingBankAccount[];
  });
};

const getCreditCardsByBusinessIds = (
  params: IGetPaymentMethodsByBusinessIdsParams,
): Promise<IBillingCreditCard[]> => {
  return apiGet(
    '/billing/api/v1/credit_cards/by_business_ids',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    return camelizeKeys(data.credit_cards) as IBillingCreditCard[];
  });
};

const createBankAccountFromPlaid = (
  params: ICreateBankAccountFromPlaidParams,
): Promise<any> => {
  return apiPost(
    '/billing/api/v1/bank_accounts/create_from_plaid',
    { bank_account: underscoreKeys(params) },
  );
};

const createCreditCard = (
  params: ICreateCreditCardParams,
): Promise<any> => {
  return apiPost(
    '/billing/api/v1/credit_cards',
    { credit_card: underscoreKeys(params) },
  );
};

export {
  ICreateBankAccountFromPlaidParams,
  ICreateCreditCardParams,
  IGetPaymentMethodsByBusinessIdsParams,
  getBankAccountsByBusinessIds,
  getCreditCardsByBusinessIds,
  createBankAccountFromPlaid,
  createCreditCard,
};
