module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, k, l, month, quarterly, ref, ref1, ref2, ref3, year;
    
      __out.push('<div class=\'display-flex align-items-center padding-10\'>\n  <div class="filter-group filter-name m-l-10 m-r-10">\n    <div id="filter-accounting-class-region" class="filter-accounting-class-region ');
    
      if (!this.isDepartmentReport) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('"></div>\n    ');
    
      if (!this.isDepartmentReport) {
        __out.push('\n      <input type="text" class="form-control filter-name-js" placeholder="Search by Name" />\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="m-r-10">\n    <div class="filter-input-group">\n      <input type="text" class="filter-date-input date-picker form-control filter-start-date" placeholder="From" autocomplete="off" value="');
    
      __out.push(__sanitize(this.startDuration));
    
      __out.push('"/>\n      <span class="input-group-addon">-</span>\n      <input type="text" class="filter-date-input date-picker form-control filter-end-date" placeholder="To" autocomplete="off" value="');
    
      __out.push(__sanitize(this.endDuration));
    
      __out.push('"/>\n      ');
    
      if (!this.isDaily) {
        __out.push('\n        <a class="btn dropdown-toggle range-select-menu-btn pointer" type="button" id="filter-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n          <span class="fa fa-caret-down font-14"></span>\n        </a>\n        <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="filter-dropdown-btn">\n          <li class="font-14 dropdown-submenu">\n            <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n              <span>Calendar month</span>\n            </a>\n            <ul class="dropdown-menu filter-dropdown-menu">\n              ');
        for (year = i = ref = this.startYear, ref1 = this.endYear; ref <= ref1 ? i <= ref1 : i >= ref1; year = ref <= ref1 ? ++i : --i) {
          __out.push('\n                <li class="font-14 dropdown-submenu">\n                  <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n                    <span>');
          __out.push(__sanitize(year));
          __out.push('</span>\n                  </a>\n                  <ul class="dropdown-menu filter-dropdown-menu">\n                    ');
          for (month = j = 0; j <= 11; month = ++j) {
            __out.push('\n                      <li>\n                        <a class="dropdown-item font-14 monthly-dropdown-item-toggle pointer" type="button" data-prefix="');
            __out.push(__sanitize(year));
            __out.push('" data-value="');
            __out.push(__sanitize(month));
            __out.push('">\n                          ');
            __out.push(__sanitize(this.MONTH_FULL_NAMES[month]));
            __out.push('\n                        </a>\n                      </li>\n                    ');
          }
          __out.push('\n                  </ul>\n                </li>\n              ');
        }
        __out.push('\n            </ul>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 pointer year-to-date-btn">\n              <span>Year to date</span>\n            </a>\n          </li>\n          <li class="font-14 dropdown-submenu">\n            <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n              <span>Quarterly</span>\n            </a>\n            <ul class="dropdown-menu filter-dropdown-menu">\n              ');
        for (year = k = ref2 = this.startYear, ref3 = this.endYear; ref2 <= ref3 ? k <= ref3 : k >= ref3; year = ref2 <= ref3 ? ++k : --k) {
          __out.push('\n                <li class="font-14 dropdown-submenu">\n                  <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n                    <span>');
          __out.push(__sanitize(year));
          __out.push('</span>\n                  </a>\n                  <ul class="dropdown-menu filter-dropdown-menu">\n                    ');
          for (quarterly = l = 0; l <= 3; quarterly = ++l) {
            __out.push('\n                      <li>\n                        <a class="dropdown-item font-14 quarterly-dropdown-item-toggle pointer" type="button"  data-prefix="');
            __out.push(__sanitize(year));
            __out.push('" data-value="');
            __out.push(__sanitize(quarterly));
            __out.push('">\n                          <span>');
            __out.push(__sanitize(this.QUARTERS[quarterly].label));
            __out.push('</span>\n                        </a>\n                      </li>\n                    ');
          }
          __out.push('\n                  </ul>\n                </li>\n              ');
        }
        __out.push('\n            </ul>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 mark-as-paid-by-cash-btn last-year-btn pointer" type="button">\n              <span>Last Year</span>\n            </a>\n          </li>\n          <li>\n            <a class="dropdown-item font-14 mark-as-paid-by-cash-btn past-twelve-btn pointer" type="button">\n              <span>Past 12 months</span>\n            </a>\n          </li>\n        </ul>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n  <div class="permission-dropdown-wrapper column-setting-wrapper width-150px ');
    
      if (this.hideColumnFilter) {
        __out.push(__sanitize('hidden'));
      }
    
      __out.push('"></div>\n  <div class=\'m-l-auto\'></div>\n  <div class="zero-rows-hidden checkbox pointer checkbox-primary inline-block m-l-20 m-r-10 m-b-5">\n    <input class="pointer" type="checkbox" id="checkbox-hide-zero" ');
    
      __out.push(__sanitize(this.isZeroRowsHidden ? 'checked' : ''));
    
      __out.push('>\n    <label for="checkbox-hide-zero">Hide zero rows</label>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}