import React from 'react';

import { DateRangeInput } from '@src/components/ui_v2/inputs/date_input';

import Wrapper from './form_field_wrapper';
import { IWrapperProps, useFieldWrapperProps } from './utils';

interface IDateRangeFieldProps extends
  React.ComponentPropsWithoutRef<typeof DateRangeInput>,
  Omit<IWrapperProps, 'suffix'>
{
}

const DateRangeField = ({
  ...props
}: IDateRangeFieldProps): JSX.Element => {
  const [wrapperProps, inputProps] = useFieldWrapperProps(props);

  return (
    <Wrapper { ...wrapperProps }>
      <DateRangeInput
        { ...inputProps }
      />
    </Wrapper>
  );
};

export default DateRangeField;
