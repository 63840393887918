module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var filter, i, len, ref;
    
      __out.push('<div class="main-wrapper width-100-percent">\n  <div class="dropdown-toggle permission-dropdown-control column-select-menu-btn" id="column-setting-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <span>Customize</span>\n    <span class="fa fa-caret-down font-14"></span>\n  </div>\n  <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="column-setting-dropdown-btn">\n    ');
    
      ref = this.reportFilters;
      for (i = 0, len = ref.length; i < len; i++) {
        filter = ref[i];
        __out.push('\n      ');
        if (this.showFilter(filter)) {
          __out.push('\n        <li>\n          <div class="dropdown-item checkbox pointer checkbox-primary">\n            <input class="pointer" type="checkbox" id=\'');
          __out.push(__sanitize(filter.id));
          __out.push('\' ');
          if (this.columnFilters[filter.id]) {
            __out.push('checked');
          }
          __out.push(' />\n            <label for=\'');
          __out.push(__sanitize(filter.id));
          __out.push('\' class="pointer">');
          __out.push(__sanitize(filter.name));
          __out.push(' </label>\n          </div>\n        </li>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </ul>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}