import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { TID } from '@src/types/common';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';

import {
  addRevenueSystemValidation,
  IAddRevenueSystemValues,
} from './schema';

interface IAddRevenueSystemAdjustmentProps {
  businessId: TID,
  formId: string,
  onSubmit: (data: IAddRevenueSystemValues) => void;
}

const AddRevenueSystemAdjustment = ({
  businessId,
  formId,
  onSubmit,
}: IAddRevenueSystemAdjustmentProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IAddRevenueSystemValues>({
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(addRevenueSystemValidation),
  });

  const dateControl = useController({ control, name: 'startDate' });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.TextField
        error={ errors.name?.message }
        label="Revenue System"
        placeholder="Enter Revenue System name"
        { ...register('name') }
      />
      <Form.DateField
        error={ errors.startDate?.message }
        label="Start Date"
        { ...omit(dateControl.field, 'ref') }
      />
      <RevenueReportTypeField
        businessId={ businessId }
        error={ errors.revenueReportTypeId?.message }
        label="Revenue Mapping"
        { ...omit(rrtControl.field, 'ref') }
      />
    </Form>
  );
};

export default React.memo(AddRevenueSystemAdjustment);
