import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { businessSettingsStepPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Modal from '@src/components/ui/modal';
import { UpgradeIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IUpgradeAccountsModalProps extends IUseModalProps {
}

const UpgradeAccountModal = ({
  isOpen,
  onCancel,
  onDone,
}: IUpgradeAccountsModalProps): JSX.Element => {
  const { id: businessId } = useBusinessContext();
  const handleUpgradeToActivate = useCallback(() => {
    onDone();
    backboneNavigateToUrl(businessSettingsStepPath(businessId, 7));
  }, [businessId, onDone]);

  const upgradeIconClasses = classNames(
    'modal-body-icon',
    styles['upgrade-icon'],
  );

  return (
    <Modal.Standard
      proceedTitle="Upgrade to Activate"
      show={ isOpen }
      title="Compliance Rules"
      onCancel={ onCancel }
      onProceed={ handleUpgradeToActivate }
    >
      {() => (
        <div className="display-flex-column width-100-percent">
          <div className={ upgradeIconClasses }>
            <UpgradeIcon />
          </div>
          <p className="width-100-percent text-center m-t-10">
            Upgrade to meet the evolving needs of your business with our
            <b> Exclusive Feature: Compliance Rules</b>
            . Elevate your control
            over invoice approvals, payments, and vendor restrictions like never before!
          </p>
        </div>
      )}
    </Modal.Standard>
  );
};

const useUpgradeAccountsModal = makeUseModal<
  typeof UpgradeAccountModal
>(UpgradeAccountModal);

export {
  IUpgradeAccountsModalProps,
  useUpgradeAccountsModal,
  UpgradeAccountModal as default,
};
