import React from 'react';

import classNames from 'classnames';

import { TIconOption, TOption } from '../types';

import styles from './styles.module.scss';

const formatSelectOption = (
  { label }: TOption,
): JSX.Element => {
  return (
    <span className={ styles['select-option-label'] }>{ label }</span>
  );
};

const formatIconSelectOption = (
  { icon, label, helper, subTitle, hideIcon = false, tooltip = false }: TIconOption,
  { context }: { context: 'menu' | 'value' },
): JSX.Element => {
  const classes = classNames(
    { [styles['icon-select-option-icon']]: !tooltip },
    { [styles['icon-select-option-tooltip-icon']]: tooltip }
  );

  return (
    <>
      { !hideIcon && <span className={ classes }>{ icon }</span> }
      { context === 'menu' && subTitle ? (
        <div className={ styles['icon-select-option'] }>
          <span className={ styles['icon-select-option-label'] }>{ label }</span>
          <span className={ styles['icon-select-option-subtitle'] }>{ subTitle }</span>
        </div>
      ) : (
        <span className={ styles['icon-select-option-label'] }>{ label }</span>
      ) }
      { context === 'menu' && helper && (
        <span className={ styles['icon-select-option-helper'] }>{ helper }</span>
      ) }
    </>
  );
};

export {
  formatSelectOption,
  formatIconSelectOption,
};
