import { apiGet, apiPost, apiPut } from '@src/requests/helpers';
import { IGetFinancialInsightsParams, IGetFinancialInsightsResponse, INotificationPreference, IUpdateUserSettings, IUpsertNotificationParams } from '@src/types/financial_insight';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

const getFinancialInsights = (params: IGetFinancialInsightsParams):
  Promise<IGetFinancialInsightsResponse> => {
  return apiGet(
    'dashboards/api/v1/businesses_with_dashboards.json',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.total_count,
      },
      collection: cdata.businessDashboardData as any,
    };
  });
};

const getNotificationPreference = async (): Promise<INotificationPreference> => {
  return apiGet('/api/v1/notification_preference').then((response) => {
    const data = <any>camelizeKeys(response);
    return data;
  });
};

const updateNotificationPreference = (params: INotificationPreference): Promise<any> => {
  return apiPut('/api/v1/notification_preference', underscoreKeys(params));
};

const updateUserSettings = (params: IUpdateUserSettings): Promise<void> => {
  return apiPut(
    '/api/v1/users/update_settings',
    underscoreKeys({ user: params }),
  );
};

const upsertNotificationPreferences = (
  params: IUpsertNotificationParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/upsert_preferences',
    underscoreKeys(params),
  );
};

export {
  getFinancialInsights,
  getNotificationPreference,
  upsertNotificationPreferences,
  updateNotificationPreference,
  updateUserSettings,
};
