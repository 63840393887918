import React from 'react';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { BankCircleIcon } from '@src/components/utils/icomoon';

import DeleteAction from '../actions/delete_action';
import UnapproveAction from '../actions/unapprove_action';
import UnverifyAction from '../actions/unverify_action';

import styles from '../styles.module.scss';

interface IDetailsModalTitleProps {
  bankStatement?: IBankStatement,
  businessId: TID,
  fromDetails?: boolean,
  title?: string,
  withCopyUrl?: boolean,
  enableDeleteAction?: boolean,
  onDeleted?: () => void,
  onUnapproved?: () => void,
}

const DetailsModalTitle = ({
  bankStatement,
  businessId,
  title = 'Pending Review',
  withCopyUrl = true,
  fromDetails = true,
  enableDeleteAction = true,
  onDeleted,
  onUnapproved,
}: IDetailsModalTitleProps): JSX.Element => {
  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  let copyUrl;
  if (withCopyUrl) {
    const allowedStates: Array<IBankStatement['state']> = [
      window.Docyt.Common.Constants.BANK_STATEMENT_STATES.APPROVED,
      window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED
    ];
    const copyUrlLDPath = bankStatementsDocRequestEnabled ? '/reconciliation_center/month_end_documents/' : '/banking_accounts/pending_review/';
    const bankStatementStatePath = (() => {
      if (bankStatement?.state && allowedStates.includes(bankStatement.state)) {
        return 'bank_statements';
      }
      if (bankStatementsDocRequestEnabled) {
        return 'pending_bank_statements';
      }
      return '';
    })();
    copyUrl = `${window.location.origin}/businesses/${businessId}${copyUrlLDPath}${bankStatementStatePath}/${bankStatement?.docytId}`;
  }

  let badge;
  if (bankStatement?.state === 'approved') {
    badge = (
      <span className={ styles['approved-state-badge'] }>
        Statement Approved
      </span>
    );
  } else if (bankStatement?.state === 'verified') {
    badge = (
      <span className={ styles['verified-state-badge'] }>
        Statement Verified
      </span>
    );
  } else {
    badge = (
      <span className={ styles['unapproved-state-badge'] }>
        Unapproved
      </span>
    );
  }
  return (
    <div>
      <DetailsPageTitle
        isSmallTitle
        badge={ withCopyUrl ? bankStatement?.docytId : null }
        breadcrumbs={
          [
            { title: bankStatement?.businessName },
            { title: 'Banking Accounts' },
            { title: 'Bank Statement' },
          ]
        }
        className={ styles['bank-statement-details-title'] }
        copyUrl={ copyUrl }
        markIcon={ <BankCircleIcon className={ styles['bank-circle-icon'] } /> }
        title={ title }
      >
        <>
          { badge }
          {
            bankStatement && enableDeleteAction && (
              <ActionsDropdown>
                { bankStatement?.state === 'approved' && <UnapproveAction bankStatement={ bankStatement } onUnapproved={ onUnapproved } /> }
                { bankStatement?.state === 'verified' && <UnverifyAction bankStatement={ bankStatement } businessId={ businessId } /> }
                <DeleteAction
                  bankStatement={ bankStatement }
                  fromDetails={ fromDetails }
                  onDeleted={ onDeleted }
                />
              </ActionsDropdown>
            )
          }
        </>
      </DetailsPageTitle>
    </div>
  );
};

export default DetailsModalTitle;
