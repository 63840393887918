module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div>\n    <a class="font-16 in-blue-400 text-decoration-blank" href = "');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('">\n      Accounts Payable\n    </a>\n    <div class="client__breadcrumbs-item in-grey-600"></div>\n    <a class="font-16 in-blue-400 text-decoration-blank" href = "');
    
      __out.push(__sanitize(this.homeURL));
    
      __out.push('/rules">\n      Payment Rules\n    </a>\n    <div class="client__breadcrumbs-item in-grey-600"></div>\n</div>\n<div class="payment-rule-header">\n    <div class="display-flex align-items-center">\n        <div class="rule-logo-wrapper flex-center-wrapper m-r-15">\n            ');
    
      if (this.vendor_avatar_url) {
        __out.push('\n            <img class="rule-logo" src="');
        __out.push(__sanitize(this.vendor_avatar_url));
        __out.push('">\n            ');
      } else {
        __out.push('\n            <i class="icon icon-smile in-orange-500 font-40"></i>\n            ');
      }
    
      __out.push('\n        </div>\n        <div>\n            <p class="rule-name font-36 m-b-9">');
    
      __out.push(__sanitize(this.vendor.name));
    
      __out.push('</p>\n            <div class="display-flex align-items-center">\n                <i class="icon-calendar font-16 m-r-10"></i>\n                <span class="rule-amount-description font-14 font-semi-bold">$');
    
      __out.push(__sanitize(this.amount));
    
      __out.push(' ');
    
      __out.push(__sanitize(Docyt.Utils.getFrequencyPeriod(this.frequency)));
    
      __out.push('</span>\n            </div>\n        </div>\n    </div>\n    <div class="relative rule-header-action-wrapper">\n      <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <i class="icon icon-setup font-40 rule-header-action-icon"></i>\n      </button>\n      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="role-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n        <a class="dropdown-item edit-rule-js pointer display-flex">\n          <i class="fa fa-edit font-20"></i>\n          <span class="font-14 m-l-5">Edit Payment Rule</span>\n        </a>\n        <a class="dropdown-item delete-rule-js in-red-400 pointer display-flex">\n          <i class="icon icon-new-trash font-18"></i>\n          <span class="font-14 m-l-5">Delete Payment Rule</span>\n        </a>\n      </div>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}