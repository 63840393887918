import { IVendor } from '@src/types/business_vendors';
import { IDwollaFundingSource } from '@src/types/dwolla_funding_sources';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut, apiDelete } from './helpers';

interface IMergeToGlobalVendorParams {
  businessId: number,
  vendorId: number,
  mergeableVendorId: number,
}

interface IArchiveVendor {
  businessId: number,
  vendorId: number,
}

interface IUnArchiveVendor {
  businessId: number,
  vendorId: number,
}

interface IRemoveVendor {
  businessId: number,
  vendorId: number,
}

interface INoMergeToGlobalVendorParams {
  businessId: number,
  vendorId: number,
}

interface IRemoveAchBankParams {
  businessId: number,
  vendorId: number,
}

interface INoMergeToGlobalVendorResponse {
  vendor: IVendor,
}

interface IVendorBusinessEmailVerifiedResponse {
  verified: boolean,
}

const getVendor = (id: number): Promise<IVendor> => {
  return apiGet(`/api/v1/vendors/${id}`)
    .then((data) => camelizeKeys(data.vendor) as IVendor);
};

const getVendorBusinessEmailVerified = (
  id: number,
): Promise<IVendorBusinessEmailVerifiedResponse> => {
  return apiGet(
    '/api/v1/vendors/check_business_email_verified',
    underscoreKeys({ id }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      verified: cdata.verified,
    };
  });
};

const getReceivableFundingSource = (
  id: number,
): Promise<IDwollaFundingSource> => {
  return apiGet(
    '/api/v1/vendors/receivable_funding_source',
    underscoreKeys({ id }),
  ).then((data) => camelizeKeys(data.dwolla_funding_source) as IDwollaFundingSource);
};

const putMergeToGlobalVendor = (
  params: IMergeToGlobalVendorParams,
): Promise<void> => {
  const requestParams = {
    businessId:  params.businessId,
    mergeableId: params.mergeableVendorId,
  };

  return apiPut(
    `/api/v1/business_vendors/${params.vendorId}/merge_to_global`,
    underscoreKeys(requestParams),
  );
};

const postRemoveAchBank = (
  params: IRemoveAchBankParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/business_vendors/unlink_from_ach_bank',
    underscoreKeys({ ...params }),
  );
};

const postNoMergeToGlobalVendor = (
  params: INoMergeToGlobalVendorParams,
): Promise<INoMergeToGlobalVendorResponse> => {
  return apiPost(
    `/api/v1/vendors/${params.vendorId}/clear_flag_for_merge`,
    underscoreKeys(params),
  ).then((data) => {
    return {
      vendor: camelizeKeys(data.vendor) as IVendor,
    };
  });
};

const putArchiveVendor = (
  params: IArchiveVendor,
): Promise<void> => {
  return apiPut(
    `/api/v1/business_vendors/${params.vendorId}/archive`,
    underscoreKeys({ ...params }),
  );
};

const putUnarchiveVendor = (
  params: IArchiveVendor,
): Promise<void> => {
  return apiPut(
    `/api/v1/business_vendors/${params.vendorId}/unarchive`,
    underscoreKeys({ ...params }),
  );
};

const deleteFromVendor = (
  params: IRemoveVendor,
): Promise<void> => {
  return apiDelete(
    `/api/v1/business_vendors/${params.vendorId}`,
    underscoreKeys({
      ...params,
    }),
  );
};

export {
  IArchiveVendor,
  IUnArchiveVendor,
  IRemoveVendor,
  IMergeToGlobalVendorParams,
  INoMergeToGlobalVendorParams,
  IRemoveAchBankParams,
  INoMergeToGlobalVendorResponse,
  IVendorBusinessEmailVerifiedResponse,
  getVendor,
  getVendorBusinessEmailVerified,
  getReceivableFundingSource,
  putMergeToGlobalVendor,
  putArchiveVendor,
  putUnarchiveVendor,
  deleteFromVendor,
  postNoMergeToGlobalVendor,
  postRemoveAchBank,
};
