import React, { useCallback } from 'react';

import isEqual from 'lodash/isEqual';
import { ControllerRenderProps } from 'react-hook-form';

import { useUniqueDomId } from '@src/hooks/dom';
import { IBusiness } from '@src/types/businesses';

import business from '@src/components/utils/icomoon/business';

import { IBusinessesInput } from './schema';

import styles from './styles.module.scss';

interface ISelectAllBusinessCheckboxProps
{
  businesses: IBusiness[],
  defaultBusinesses: IBusiness[],
  field: ControllerRenderProps<IBusinessesInput, 'businesses'>,
  onCheckedChange?: (isChecked: boolean) => void;
}

const SelectAllBusinessCheckbox = ({
  businesses,
  defaultBusinesses,
  field: { value, onChange },
  onCheckedChange,
}: ISelectAllBusinessCheckboxProps): JSX.Element => {
  const inputId = useUniqueDomId('business_selector_');

  const handleChange = useCallback((e) => {
    if (!e.target.checked) {
      onChange(defaultBusinesses);
    } else {
      onChange(businesses);
    }
    onCheckedChange?.(e.target.checked);
  }, [onChange, businesses, defaultBusinesses, onCheckedChange]);

  const isAllSelected = () => {
    if (business.length === 0) return false;

    return isEqual(
      value.map((item) => item.id).sort(),
      businesses.map((item) => item.id).sort(),
    );
  };

  return (
    <div className={ styles['checkbox-business-container'] }>
      <input
        checked={ isAllSelected() }
        className="pointer"
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
      />
      <label
        aria-label="Select"
        htmlFor={ inputId }
      >
        Select All
      </label>
    </div>
  );
};

export default SelectAllBusinessCheckbox;
