import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface DetailsPageBadgeProps extends Omit<React.ComponentPropsWithoutRef<'span'>, 'title'>, IUIStyleProps {
  title: React.ReactNode,
}

const DetailsPageBadge = ({
  title,
  ...props
}: DetailsPageBadgeProps): JSX.Element => {
  const [classes, spanProps] = uiStyleProps(styles.badge, props);

  return (
    <span className={ classes } { ...spanProps }>
      { title }
    </span>
  );
};

export default DetailsPageBadge;
