import React, { useCallback } from 'react';

import { ControllerRenderProps } from 'react-hook-form';

import { useGetStandardDocumentsByFolders } from '@src/hooks/queries/standard_documents';
import { useStandardDocumentsByFolderFilter } from '@src/hooks/select_standard_document';
import { IBusiness } from '@src/types/businesses';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';

import { ISelectStandardDocumentFormFormValues } from './select_standard_document_form';
import InputFolder from './select_standard_document_input_folder';
import InputItem from './select_standard_document_input_item';

interface ISelectStandardDocumentInputProps {
  business: IBusiness,
  field: ControllerRenderProps<ISelectStandardDocumentFormFormValues, 'documentId'>,
  query: string,
}

const SelectStandardDocumentInput = ({
  business,
  field: { value, onChange },
  query,
}: ISelectStandardDocumentInputProps): JSX.Element => {
  const {
    isLoading,
    isError,
    error,
    data,
  } = useGetStandardDocumentsByFolders(business.id);

  const handleDocumentSelect = useCallback((document) => {
    onChange(document.id);
  }, [onChange]);

  const folders = useStandardDocumentsByFolderFilter(data, query);

  return (
    <>
      { isLoading && <Spinner /> }
      { isError && <ErrorNotification error={ error } /> }

      <div className="standard-documents-list border-top-gray">
        {
          folders.map((folder) => (
            <InputFolder
              key={ folder.id }
              folder={ folder }
            >
              { (document) => (
                <InputItem
                  key={ document.id }
                  document={ document }
                  selected={ document.id === value }
                  onSelect={ handleDocumentSelect }
                />
              )}
            </InputFolder>
          ))
        }
      </div>
    </>
  );
};

export default SelectStandardDocumentInput;
