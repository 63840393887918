import { uploadImageFileToS3 } from '@src/requests/aws';
import { IAvatar } from '@src/types/avatars';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiPost, apiPut } from './helpers';

interface IBusinessAvatarParams {
  advisorId: number,
  avatarType: string,
}

interface IUploadAvatarParams {
  avatarParams: IBusinessAvatarParams,
  file: File,
}

interface ICompleteAvatarParams {
  advisorId: number,
  avatar: IAvatar,
  avatarType: string,
  id: number,
  s3ObjectKey: string,
}

const postBusinessAvatar = (params: IBusinessAvatarParams): Promise<IAvatar> => {
  return apiPost(
    `/api/web/v1/advisor/${params.advisorId}/avatar`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.avatar) as IAvatar);
};

const putCompleteAvatarUpload = (
  params: ICompleteAvatarParams,
): Promise<IAvatar> => {
  return apiPut(
    `/api/web/v1/advisor/${params.advisorId}/avatar/complete_upload`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.avatar) as IAvatar);
};

const uploadAvatar = (params: IUploadAvatarParams): Promise<IAvatar> => {
  return postBusinessAvatar(params.avatarParams)
    .then((avatar) => {
      return uploadImageFileToS3({
        s3ObjectKey: `avatar-${avatar.id}-${params.file.name}`,
        file:        params.file,
        bucketName:  window.configData.bucketName,
      }).then(({ s3ObjectKey }) => {
        return putCompleteAvatarUpload(
          {
            advisorId:  params.avatarParams.advisorId,
            avatar,
            avatarType: params.avatarParams.avatarType,
            id:         avatar.id,
            s3ObjectKey,
          },
        );
      });
    });
};

export {
  IUploadAvatarParams,
  uploadAvatar,
};
