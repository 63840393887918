import React, { useCallback, useMemo } from 'react';

import { SubmitHandler, useController, useForm } from 'react-hook-form';

import { IBusiness } from '@src/types/businesses';

import { ErrorNotification } from '@src/components/ui/notification';

import BusinessItem from './business_item';
import { ICreateNewReportFormValues } from './schema';

import styles from '../styles.module.scss';

interface ISelectBusinessesFormProps {
  formId: string,
  name: string,
  templateId: string,
  businesses: IBusiness[],
  onSubmit: SubmitHandler<ICreateNewReportFormValues>,
}

type BusinessItemType = {
  avatar: string,
  label: string,
  value: number,
  availableStatus: boolean,
}

const SelectBusinessesForm = ({
  formId,
  name,
  templateId,
  businesses,
  onSubmit,
}: ISelectBusinessesFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateNewReportFormValues>({
    defaultValues: {
      name,
      templateId,
      reportServiceIds: [],
    },
  });

  const businessItemController = useController({
    name:  'reportServiceIds',
    control,
    rules: { required: true },
  });

  const businessData = useMemo(() => {
    return businesses.map((business: IBusiness) => (
      {
        avatar:          business.avatarUrl,
        label:           business.displayName,
        value:           business.id,
        availableStatus: !!business?.availableStatus,
      }
    )) as BusinessItemType[];
  }, [businesses]);

  const handleSelect = useCallback((checked: boolean, val: string | undefined) => {
    let tmpData = businessItemController.field.value;

    if (checked) {
      const find = tmpData.find((f: number) => f === Number(val));

      if (!find) {
        tmpData.push(Number(val));
      }
    } else {
      tmpData = tmpData.filter((f: number) => f !== Number(val));
    }

    businessItemController.field.onChange(tmpData);
  }, [businessItemController]);

  return (
    <form className={ styles['height-500px-with-scroll'] } id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      { errors.reportServiceIds ? <ErrorNotification message="Please Select Businesses" title="Warning!" /> : null }

      <ul>
        { businessData.map((item: BusinessItemType) => (
          <BusinessItem
            key={ item.value }
            active={ item.availableStatus }
            avatar={ item.avatar }
            field={ businessItemController.field }
            label={ item.label }
            value={ item.value }
            onSelect={ handleSelect }
          />
        )) }
      </ul>
    </form>
  );
};

export default React.memo(SelectBusinessesForm);
