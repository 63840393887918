import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { EmailFrequencyOptions } from '@src/constants/email_frequency_options';
import { IBusinessItem, IBusinessUpdatedItem, IDashboardItem, IFrequency, IUpdateFrequencyFormData } from '@src/types/financial_insight';

import EmailFrequencyField from '@src/components/common_v2/form_fields/email_frequency_field';
import Modal from '@src/components/ui/modal';
import Form from '@src/components/ui_v2/form';

import FinancialInsightsTable from './financial_insights_table';
import { useGetFinancialInsightsCollection } from './hooks';

import styles from './styles.module.scss';

interface IFinancialInsightsEditProps {
  updatedData : IBusinessUpdatedItem[];
  frequency : IFrequency;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (values : IUpdateFrequencyFormData) => void,
  onChange: (row : IBusinessItem, option : IDashboardItem) => void,
  onClear: () => void
}

const FinancialInsightsEdit = ({
  updatedData,
  frequency,
  isOpen,
  onCancel,
  onSubmit,
  onChange,
  onClear,
}: IFinancialInsightsEditProps): JSX.Element => {
  const collection = useGetFinancialInsightsCollection({
    sect: 'financial_insights_popup',
  });

  const financialInsightsValidation = object({
    frequency: string().nullable().required('Frequency is required.'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors }, setValue,
  } = useForm<IUpdateFrequencyFormData>({
    defaultValues: {
      frequency,
    },
    resolver: yupResolver(financialInsightsValidation),
  });
  const frequencyController = useController({ name: 'frequency', control });

  useEffect(() => {
    setValue('frequency', frequency as IFrequency);
  }, [frequency, setValue]);

  return (
    <Modal.Form
      dialogClassName={ styles['edit-financial-insights-modal'] }
      formId="edit_financial_insights_form"
      proceedTitle="Save"
      show={ isOpen }
      title="Financial Insights"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <div className={ styles['edit-financial-insights-modal-container'] }>
          <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
            <div className={ styles['edit-financial-insights-container'] }>

              <div className={ styles.frequency }>
                <EmailFrequencyField
                  error={ errors.frequency?.message }
                  label="Frequency"
                  labelClass="width-50-percent"
                  options={ EmailFrequencyOptions }
                  { ...omit(frequencyController.field, 'ref') }
                />
              </div>

              <div className={ `${styles['width-95-percent']} item-divider  m-b-8` } />

              <FinancialInsightsTable
                isPopup
                query={ collection.query }
                records={ collection.records }
                section={ collection.section }
                sorting={ collection.sorting }
                updatedData={ updatedData }
                onChange={ onChange }
                onClear={ onClear }
              />
            </div>
          </Form>
        </div>
      )}
    </Modal.Form>
  );
};
export default React.memo(FinancialInsightsEdit);
