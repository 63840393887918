import React from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITextProps<C extends React.ElementType> extends IUIStyleProps {
  as?: C,
}

type TTextProps<C extends React.ElementType> = ITextProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof ITextProps<C>>

const Text = <C extends React.ElementType = 'span'>({
  children,
  as: component,
  ...props
}: TTextProps<C>): JSX.Element => {
  const [classes, elementProps] = uiStyleProps(
    undefined,
    props,
  );

  const Component = component || 'span';

  return (
    <Component className={ classes } { ...elementProps }>
      { children }
    </Component>
  );
};

export default Text;
