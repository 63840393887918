import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IDeleteTransactionErrorModalProps extends IUseModalProps {
  title: string,
  message: string,
}

const DeleteTransactionErrorModal = ({
  title,
  message,
  isOpen,
  onCancel,
}: IDeleteTransactionErrorModalProps): JSX.Element => {
  const handleConfirm = useCallback((e) => {
    e.preventDefault();

    onCancel();
  }, [onCancel]);

  return (
    <Modal
      dialogClassName="delete-transaction-error-modal"
      region="second-modal-region"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        <span>
          { message }
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsColor="blue"
          title="Ok"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useDeleteTransactionErrorModalModal = makeUseModal(DeleteTransactionErrorModal);

export {
  IDeleteTransactionErrorModalProps,
  DeleteTransactionErrorModal,
  useDeleteTransactionErrorModalModal as default,
};
