module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var employee_card, i, j, k, len, len1, len2, payment_account, rc_payment_account, ref, ref1, ref2;
    
      if (this.type === 'transactions') {
        __out.push('\n  <div class="employee-card-filter-section">\n    <input class="form-control filter-banking-account" id ="paymentAccount" placeholder="Account" autocomplete="off" value="');
        __out.push(__sanitize(this.selectedAccountName));
        __out.push('" readonly />\n    <a class="dropdown-toggle range-select-menu-btn pointer" type="button" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-caret-down font-14"></span>\n    </a>\n    <ul class="pointer dropdown-menu dropdown-menu-left employee-card-dropdown font-24 col-xs-12" role="menu">\n      <li><a tabindex="-1" data-value="all" class="reconciliation-payment-account">All Accounts</a></li>\n      ');
        ref = this.payment_accounts;
        for (i = 0, len = ref.length; i < len; i++) {
          rc_payment_account = ref[i];
          __out.push('\n        ');
          if (rc_payment_account.payment_accounts.length > 1) {
            __out.push('\n          <li class="dropdown-submenu">\n            <a tabindex="-1" data-value="');
            __out.push(__sanitize(rc_payment_account.id));
            __out.push('" class="reconciliation-payment-account">');
            __out.push(__sanitize(this.rcPaymentAccountName(rc_payment_account)));
            __out.push('</a>\n            <ul class="dropdown-menu col-xs-12">\n              ');
            ref1 = rc_payment_account.payment_accounts;
            for (j = 0, len1 = ref1.length; j < len1; j++) {
              employee_card = ref1[j];
              __out.push('\n                <li><a tabindex="-1" data-value="');
              __out.push(__sanitize(employee_card.id));
              __out.push('" class="employee-card">');
              __out.push(__sanitize(employee_card.name));
              __out.push('</a></li>\n              ');
            }
            __out.push('\n            </ul>\n          </li>\n        ');
          } else {
            __out.push('\n          <li><a tabindex="-1" data-value="');
            __out.push(__sanitize(rc_payment_account.id));
            __out.push('" class="reconciliation-payment-account">');
            __out.push(__sanitize(this.rcPaymentAccountName(rc_payment_account)));
            __out.push('</a></li>\n        ');
          }
          __out.push('\n      ');
        }
        __out.push('\n    </ul>\n  </div>\n');
      } else {
        __out.push('\n  <select class="form-control input-payment-account selectpicker" id ="paymentAccount" title="');
        __out.push(__sanitize(this.account_title));
        __out.push('">\n    ');
        if (this.type !== 'ap_payment_account_selector') {
          __out.push('\n    <option value=\'all\'>All Accounts</option>\n    ');
        }
        __out.push('\n    ');
        ref2 = this.payment_accounts;
        for (k = 0, len2 = ref2.length; k < len2; k++) {
          payment_account = ref2[k];
          __out.push('\n      ');
          if (this.type === 'ap_payment_account_selector') {
            __out.push('\n      <option value="');
            __out.push(__sanitize(payment_account.id));
            __out.push('" ');
            if (payment_account.id === this.payment_account.id) {
              __out.push(__sanitize('selected'));
            }
            __out.push('>');
            __out.push(__sanitize(payment_account.get('name')));
            __out.push(__sanitize(payment_account.get('is_archived') ? ' (Archived)' : void 0));
            __out.push('</option>\n      ');
          } else if (this.type === 'banking_account') {
            __out.push('\n      <option value="');
            __out.push(__sanitize(payment_account.id));
            __out.push('" ');
            if (parseInt(this.filter.payment_account_id) === payment_account.id) {
              __out.push(__sanitize('selected'));
            }
            __out.push('>');
            __out.push(__sanitize(payment_account.get('name')));
            __out.push(__sanitize(payment_account.get('is_archived') ? ' (Archived)' : void 0));
            __out.push('</option>\n      ');
          } else if (this.type === 'from_payment_account') {
            __out.push('\n      <option value="');
            __out.push(__sanitize(payment_account.name));
            __out.push('" ');
            if (this.filter.from_account === payment_account.name) {
              __out.push(__sanitize('selected'));
            }
            __out.push('>');
            __out.push(__sanitize(payment_account.name));
            __out.push(__sanitize(payment_account.is_archived ? ' (Archived)' : void 0));
            __out.push('</option>\n      ');
          } else if (this.type === 'to_payment_account') {
            __out.push('\n      <option value="');
            __out.push(__sanitize(payment_account.name));
            __out.push('" ');
            if (this.filter.to_account === payment_account.name) {
              __out.push(__sanitize('selected'));
            }
            __out.push('>');
            __out.push(__sanitize(payment_account.name));
            __out.push(__sanitize(payment_account.is_archived ? ' (Archived)' : void 0));
            __out.push('</option>\n      ');
          } else {
            __out.push('\n      <option value="');
            __out.push(__sanitize(payment_account.name));
            __out.push('" ');
            if (this.filter.payment_account === payment_account.name) {
              __out.push(__sanitize('selected'));
            }
            __out.push('>');
            __out.push(__sanitize(payment_account.name));
            __out.push(__sanitize(payment_account.is_archived ? ' (Archived)' : void 0));
            __out.push('</option>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n  </select>\n');
      }
    
      __out.push('\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}