module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="checkbox issue-checkbox">\n  <input type="checkbox" class="form-control check-all-issue-js" data-type="checkbox">\n  <label class="font-20"></label>\n</div>\n<div class="form-group">\n  <select class="form-control document-picker filter-document-js" autocomplete="off">\n    <option><span>All</span></option>\n    <option><span>Vendor</span></option>\n    <option><span>Chart of Account</span></option>\n    <option><span>Invoice</span></option>\n    <option><span>Receipt</span></option>\n    <option><span>Revenue</span></option>\n    <option><span>Transaction</span></option>\n    <option><span>Accounts Receivable Invoice</span></option>\n    <option><span>Accounts Receivable Payment</span></option>\n    <option><span>QuickBooks Item</span></option>\n    <option><span>QuickBooks Customer</span></option>\n    <option><span>Adjustment Entry</span></option>\n  </select>\n</div>\n<div class="form-group">\n  <input type="text" class="form-control filter-input filter-detail-js" placeholder="Detail" autocomplete="off"/>\n</div>\n<div class="form-group date-group">\n  <div class="datepicker-group relative">\n    <input type="text" class="form-control start-date-js" placeholder="Start Date" autocomplete="off"/>\n    <span class="m-b-2">&mdash;</span>\n    <input type="text" class="form-control end-date-js" placeholder="End Date" autocomplete="off"/>\n  </div>\n  <div class="date-icon">\n    <i class="fa fa-bars font-16 in-grey-1050"></i>\n  </div>\n</div>\n<div class="form-group amount-group">\n  <input type="number" class="form-control filter-input p-r-10 min-amount-js" placeholder="Min Amount" autocomplete="off"/>\n  <span class="m-b-2">&mdash;</span>\n  <input type="number" class="form-control filter-input p-l-10 max-amount-js" placeholder="Max Amount" autocomplete="off"/>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}