import React, { useLayoutEffect } from 'react';

import styles from './styles.module.scss';

interface ISideViewBodyProps {
  children: React.ReactNode,
  onClosed?: () => void;
}

const SideViewBody = ({
  children,
  onClosed,
}: ISideViewBodyProps) => {
  useLayoutEffect(() => {
    return () => {
      if (onClosed) onClosed();
    };
  }, [onClosed]);

  return (
    <div className={ styles.body }>
      { children }
    </div>
  );
};

export default SideViewBody;
