import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getQboVendors, IQboVendorsResponse } from '@src/requests/business_vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import {
  AsyncPaginateIconSelectInput,
  TIconOption,
} from '@src/components/ui_v2/inputs';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { QBOOnIcon } from '@src/components/utils/icomoon';

import { vendorLinkLedgerEditValidation } from '../edit_profile/schema';

import styles from '../../styles.module.scss';

interface IVendorLinkLedgerEditProps {
  formId: string,
  vendor: IBusinessVendor,
  onSubmit: (data: { qboName: string, qboId: string }) => void,
}

const VendorLinkLedgerEdit = ({
  formId,
  vendor,
  onSubmit,
}: IVendorLinkLedgerEditProps) => {
  const [selectedOption, setSelectedOption] = useState<TIconOption | undefined>(undefined);
  const [searchData, setSearchData] = useState<TIconOption[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ qboName: string, qboId: string }>({
    mode:     'onBlur',
    resolver: yupResolver(vendorLinkLedgerEditValidation),
  });

  const qboNameController = useController({
    name: 'qboName',
    control,
  });

  const qboIdController = useController({
    name: 'qboId',
    control,
  });

  useEffect(() => {
    const find = searchData.find((f:TIconOption) => f.value === vendor.qboId);
    setSelectedOption(find);

    if (find) {
      qboNameController.field.onChange(find.label);
      qboIdController.field.onChange(find.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedOption, searchData, vendor]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> = useCallback(
    async (query) => {
      try {
        const data = await getQboVendors({ businessId: vendor?.businessId, search: query });
        const newOptions = data.map((opt: IQboVendorsResponse) => ({
          label:    opt.name,
          subTitle: opt.address,
          icon:     <QBOOnIcon fontSize={ 20 } />,
          value:    opt.id,
        }));

        setSearchData(newOptions);
        return {
          options: newOptions,
        };
      } catch {
        return {
          options: [],
        };
      }
    },
    [vendor?.businessId, setSearchData],
  );

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    setSelectedOption(item as TIconOption);
    qboIdController.field.onChange(item?.value);
    qboNameController.field.onChange(item?.label);
  }, [setSelectedOption, qboIdController, qboNameController]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <div>
        <div className={ styles['form-content'] }>
          <p className={ styles['label-field'] }>Vendor in Ledger</p>
          <div className={ styles['value-field'] }>
            <AsyncPaginateIconSelectInput
              defaultOptions
              debounceTimeout={ 300 }
              loadOptions={ handleSource }
              placeholder="Select"
              styles={ {
                control: (provided) => ({
                  ...provided,
                  'background':   'rgb(0 0 0 / 4%)',
                  'borderRadius': '6px',
                  'border':       'none',
                  'cursor':       'pointer',
                  'color':        '#242b33',
                  'outline':      'none',
                  'borderColor':  '#242b33',
                  'boxShadow':    'none',
                  '&:hover':      {
                    borderColor: '#242b33',
                  },
                }),
              } }
              value={ selectedOption }
              onChange={ handleChange }
            />
          </div>
        </div>
        <div className={ styles['form-content'] }>
          <p className={ styles['label-field'] } />
          <div className={ styles['value-field'] }>
            { errors.qboName?.message && (
            <span className={ styles['form-field-error'] }>
              <WarningIcon />
              {' '}
              { errors.qboName?.message }
            </span>
            ) }
          </div>
        </div>
      </div>
      <div className={ styles['form-btn-content'] }>
        <Button
          className={ styles['form-btn'] }
          form={ formId }
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default React.memo(VendorLinkLedgerEdit);
