import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getPaymentAccounts,
  getByReconciliationPaymentAccountId,
  createPaymentAccount,
  updatePaymentAccount,
  IGetPaymentAccountsParams,
  IGetReconciliationPaymentAccountsParams,
  ICreatePaymentAccountParams,
  IUpdatePaymentAccountParams,
  IArchivePaymentAccountParams,
  IGetPaymentAccountsResponse,
  IDeletePaymentAccountParams,
  deletePaymentAccount,
  archivePaymentAccount,
  ICreateEmployeeCardParams,
} from '@src/requests/payment_accounts';

const PAYMENT_ACCOUNTS_QUERY = 'payment_accounts';

const useGetPaymentAccounts = (params: IGetPaymentAccountsParams, enabled: boolean) => {
  return useQuery<IGetPaymentAccountsResponse, Error>(
    [PAYMENT_ACCOUNTS_QUERY, params],
    () => getPaymentAccounts(params),
    {
      enabled,
    },
  );
};

const useByReconciliationPaymentAccountId = (params: IGetReconciliationPaymentAccountsParams, enabled: boolean) => {
  return useQuery<IGetPaymentAccountsResponse, Error>(
    [PAYMENT_ACCOUNTS_QUERY, params],
    () => getByReconciliationPaymentAccountId(params),
    {
      enabled,
    },
  );
};

const useCreatePaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    ICreatePaymentAccountParams
  >(
    createPaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reconciliationPaymentAccounts);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

const useCreateEmployeeCard = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    ICreateEmployeeCardParams
  >(
    createPaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.paymentAccountEmployeeCard);
      },
    },
  );
};

const useUpdatePaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdatePaymentAccountParams>(
    updatePaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reconciliationPaymentAccounts);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

const useArchivePaymentAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error & AxiosError, IArchivePaymentAccountParams>(
    archivePaymentAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.reconciliationPaymentAccounts);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

const useDeletePaymentAccount = () => {
  return useMutation<void, Error & AxiosError, IDeletePaymentAccountParams>(
    deletePaymentAccount,
    {
      onSuccess: () => {
        window.Docyt.vent.trigger('banking_accounts:information:updated');
        window.Docyt.vent.trigger('banking_accounts:payment_accounts:created');
      },
    },
  );
};

export {
  useGetPaymentAccounts,
  useCreatePaymentAccount,
  useCreateEmployeeCard,
  useByReconciliationPaymentAccountId,
  useUpdatePaymentAccount,
  useArchivePaymentAccount,
  useDeletePaymentAccount,
};
