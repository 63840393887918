import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useResetDefaultMapping } from '@src/hooks/queries/report_service/custom_reports';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

interface IResetDefaultMappingModalProps extends IUseModalProps {
  reportId: string
}

const ResetDefaultMappingModal = ({
  reportId,
  isOpen,
  onCancel,
  onDone,
}: IResetDefaultMappingModalProps): JSX.Element => {
  const resetDefaultMapping = useResetDefaultMapping();

  const handleDone = useCallback(() => {
    resetDefaultMapping.mutate({ reportId }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('custom:report:reset_default_mapping_aciton:success');
        onDone();
      },
    });
  }, [resetDefaultMapping, onDone, reportId]);

  return (
    <>
      <MutationStatus
        mutation={ resetDefaultMapping }
        successMessage="The mapping has been successfully reset with default."
      />
      <Modal.Standard
        proceedTitle="Yes"
        show={ isOpen }
        title="Reset to Default mapping"
        onCancel={ onCancel }
        onProceed={ handleDone }
      >
        {() => (
          <p className="p-b-0 font-16 in-black text-center">
            Are you sure about&nbsp;
            <b>&quot;Resetting to default mapping&quot;</b>
            ?
          </p>
        )}
      </Modal.Standard>
    </>
  );
};

const useResetDefaultMappingModal = makeUseModal<typeof ResetDefaultMappingModal>(
  ResetDefaultMappingModal,
);

export {
  IResetDefaultMappingModalProps,
  useResetDefaultMappingModal,
  ResetDefaultMappingModal as default,
};
