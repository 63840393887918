import React, { useMemo, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useBulkDownloadDataExports } from '@src/hooks/queries/data_exports';
import { IDataExport } from '@src/types/data_exports';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';

type DataExportBulkActionsProps = {
  dataExports: IDataExport[],
};

const DownloadDataExportsAction = ({
  dataExports,
}: DataExportBulkActionsProps) : JSX.Element => {
  const downloadDataExport = useBulkDownloadDataExports();

  const dataExportIds = useMemo(() => {
    return dataExports
      .map((rsd) => rsd?.id)
      .filter((id): id is number => id !== undefined);
  }, [dataExports]);

  const isDisabled = dataExportIds.length === 0;

  const queryClient = useQueryClient();

  const bulkAction = useBulkActionModal({
    mutation: downloadDataExport,
    onDone:   useCallback(() => {
      queryClient.invalidateQueries(QueryKey.revenueServiceDocuments);
      queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
    }, [queryClient]),
  });

  const confirm = useConfirmDeleteModal({
    onDone: useCallback(() => {
      bulkAction.runMutation(dataExportIds.map((dataExportId) => ({ dataExportId })));
    }, [bulkAction, dataExportIds]),
  });

  return (
    <>
      <confirm.Component
        { ...confirm.props }
        confirmTitle="Proceed"
        icon={ undefined }
        text={ (
          <div className="text-center">
            <p>
              Are you sure you want to download
              <b>
                {' '}
                { dataExportIds.length }
              </b>
            </p>
            <p>
              data exports?
            </p>
          </div>
        ) }
        title="Download Data Export"
      />
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Data Export"
        title="Download exports"
      />
      <ActionsDropdown.Action
        disabled={ isDisabled }
        icon={ <DownloadDocumentIcon fontSize={ 20 } /> }
        title="Download"
        onClick={ !isDisabled ? confirm.open : undefined }
      />
    </>
  );
};

export default DownloadDataExportsAction;
