import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateEmployeeCard } from '@src/hooks/queries/payment_accounts';
import { TAccountType } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { WarningTriangleWhiteIcon } from '@src/components/utils/icomoon';

import EmployeeCardForm from './employee_card_form';
import { ISetupNewEployeeCardInputData } from './schema';

type TOpenValue = {
  accountType: TAccountType | undefined,
  rcPaymentAccount: IReconciliationPaymentAccount | undefined
}

const SetupNewEmployeeCard = ({
  openValue,
  isOpen,
  onCancel,
  onDone,
}: IUseModalProps<TOpenValue>): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const business = useBusinessContext();

  const createEmployeeCardAdapter = useCreateEmployeeCard();
  const { mutate } = createEmployeeCardAdapter;

  const { accountType } = openValue || {};

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleDone = useCallback(() => {
    toastr.success('Employee card created successfully.', 'Success');
    onDone();
  }, [onDone]);

  const handleCreateAccount = useCallback((data?: ISetupNewEployeeCardInputData) => {
    if (data === undefined || !openValue?.rcPaymentAccount) return;
    mutate({
      businessId:                     business.id,
      reconciliationPaymentAccountId: openValue?.rcPaymentAccount?.id,
      paymentAccount:                 {
        last4:        data.last4,
        accountOwner: data.accountOwner,
        accountType:  accountType === 'bank' ? 'bank' : 'credit_card',
        name:         data.name,
      },
    }, {
      onSuccess: handleDone,
      onError:   handleError,
    });
  }, [openValue?.rcPaymentAccount, mutate, business.id, handleDone, handleError, accountType]);

  const handleSubmit = useCallback((data: ISetupNewEployeeCardInputData) => {
    if (!data) return;
    handleCreateAccount(data);
  }, [handleCreateAccount]);

  if (createEmployeeCardAdapter.isLoading) {
    return <Spinner />;
  }

  return (
    <SideView.Form
      doneTitle="Done"
      isOpen={ isOpen }
      title="Add Employee Card"
      onCancel={ onCancel }
    >
      {({ formId }) => (
        <div
          className="document-fields setup-manual-account-view setup-manual-bank-account-view"
        >
          {
              errorMessage && (
                <SideView.Error>
                  <WarningTriangleWhiteIcon fontSize={ 18 } />
                  <div className="error-message">
                    <span>Employee card could not be added.</span>
                    <span>{errorMessage}</span>
                  </div>
                </SideView.Error>
              )
							}
          <SideView.Subtitle>
            {accountType === 'bank' ? 'Debit Card' : 'Credit Card'}
          </SideView.Subtitle>
          <div>
            <EmployeeCardForm
              accountType={ openValue?.accountType === 'bank' ? 'bank' : 'credit_card' }
              business={ business }
              formId={ formId }
              rcPaymentAccount={ openValue?.rcPaymentAccount }
              onSubmit={ handleSubmit }
            />
          </div>
        </div>
      )}
    </SideView.Form>
  );
};

const MemoizedSetupAccount = React.memo(SetupNewEmployeeCard);
const useSetupNewEmployeeCard = makeUseModal<typeof MemoizedSetupAccount, TOpenValue>(MemoizedSetupAccount);

export {
  useSetupNewEmployeeCard,
  SetupNewEmployeeCard as default,
};
