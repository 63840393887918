import React from 'react';

import Dropzone from 'react-dropzone';

import { Button } from '@src/components/ui/buttons';
import { NewDocumentIcon } from '@src/components/utils/img_icons';

interface IImageDropInputProps {
  onFileSelected: (files: File[]) => void,
}

const ImageDropInput = ({
  onFileSelected,
}: IImageDropInputProps): JSX.Element => {
  return (
    <Dropzone onDrop={ onFileSelected }>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="text-center" { ...getRootProps() }>
            <NewDocumentIcon />
            <p className="m-t-20 font-16">Drag and drop a photo here.</p>
            <p className="m-t-10 m-b-10 font-16">OR</p>
            <Button
              bsStyle="blue-on-hover"
              className="fileinput-button"
              fontSize={ 16 }
            >
              Select from your computer
              <input { ...getInputProps() } />
            </Button>

          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default ImageDropInput;
