import React from 'react';

import ListHeader from './list_header';
import TrashHeader from './trash_header';

interface IBusinessInboxHeaderProps {
  isTrash?: boolean,
}

const BusinessInboxHeader = ({
  isTrash,
}: IBusinessInboxHeaderProps): JSX.Element => {
  if (isTrash) {
    return <TrashHeader />;
  }

  return <ListHeader />;
};

export default React.memo(BusinessInboxHeader);
