module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="sidebar-business-item-wrapper ');
    
      if (this.id === this.businessId) {
        __out.push(__sanitize('active'));
      }
    
      __out.push(' business-item-js">\n    <div class="avatar-wrapper">\n      ');
    
      if (this.avatar_url) {
        __out.push('\n      <img class="service-icon-font" src="');
        __out.push(__sanitize(this.avatar_url));
        __out.push('"/>\n      ');
      } else {
        __out.push('\n      <span class=\'icon-business-default-avatar service-icon-font\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n      ');
      }
    
      __out.push('\n    </div>\n    <span class="nav-item-text">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n    <span class="nav-item-number">');
    
      __out.push(__sanitize(this.issues_count));
    
      __out.push('</span>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}