/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';
import {
  formatFullMonthDayYear,
} from '@src/utils/date_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon, VendorW9Icon } from '@src/components/utils/icomoon';

import W9InfoAction from './actions/w9_info_action';
import { useW9InfoUploadModal } from './modals/w9_modal/w9_info_upload_modal';

import styles from '../styles.module.scss';

interface ITaxInfoProps {
  vendor: IW9InfoResponse,
  businessId: TID,
  id: TID,
}

const W9Info = ({
  vendor,
  businessId,
  id,
}: ITaxInfoProps) => {
  const [dataVendor, setDataVendor] = useState<IW9InfoResponse>(vendor);

  const w9InfoUploadModal = useW9InfoUploadModal();

  const hasW9Info = vendor?.w9Address || vendor?.w9BusinessType || vendor?.w9Ssn || vendor?.w9Ein
  || vendor?.w9LegalName || vendor?.w9Email || vendor?.w9Phone || vendor?.w9SignedDate;

  let tinDisplay = '-';

  if (vendor.w9Ssn) {
    tinDisplay = `*****${vendor.w9Ssn}`;
  } else if (vendor.w9Ein) {
    tinDisplay = `*****${vendor.w9Ein}`;
  }

  useEffect(() => {
    setDataVendor(vendor);
  }, [vendor]);

  return (
    <div className={ styles['section-content-container'] }>
      <div className={ styles.header }>
        <div className={ styles.item }>
          <span className={ styles.title }>W9 Info</span>
        </div>
        {hasW9Info && (<W9InfoAction businessId={ businessId } id={ id } vendor={ vendor } />)}
      </div>
      <div className={ styles['section-content'] }>
        {hasW9Info ? (
          <div className={ styles.content }>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Legal Name</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9Name ? vendor.w9Name : '-' }</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Legal Business Name</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9LegalName ? vendor.w9LegalName : '-' }</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Email</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9Email ? vendor.w9Email : '-' }</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Phone Number</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9Phone ? vendor.w9Phone : '-' }</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Legal Entity Type</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9BusinessType ? vendor.w9BusinessType : '-' }</p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Legal Entity Address</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>
                  { vendor.w9Address ? `${vendor.w9Address?.addressLine1}, ${vendor.w9Address?.addressCity},
                ${vendor.w9Address?.addressState}, ${vendor.w9Address?.addressCountry}, ${vendor.w9Address?.addressZip}` : '-' }
                </p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>Taxpayer Indentification Number (TIN)</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>
                  {tinDisplay}
                </p>
              </div>
            </div>
            <div className={ styles.item }>
              <div className={ styles['label-item'] }>
                <p>W9 Signed Date</p>
              </div>
              <div className={ styles['value-item'] }>
                <p>{ vendor.w9SignedDate ? formatFullMonthDayYear(vendor.w9SignedDate) : '-' }</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={ styles['no-data-container'] }>
            <VendorW9Icon fontSize={ 60 } />
            <h4 className={ styles['no-data-label'] }>
              No W-9 information added to this vendor yet.
            </h4>
            <span className={ styles['no-data-description'] }>
              The information is used to fill out Form 1099 and is used for
              independent
            </span>
            <span className={ styles['no-data-description'] }>
              contractors, freelancers, or unrelated vendors
            </span>
            <Button
              className={ styles['no-data-button'] }
              prefixIcon={ <PlusIcon fontSize={ 14 } /> }
              variant="outline"
              onClick={ w9InfoUploadModal.open }
            >
              Add W9 Info
            </Button>
          </div>
        )}
      </div>
      <w9InfoUploadModal.Component
        { ...w9InfoUploadModal.props }
        businessId={ businessId }
        id={ id }
        vendor={ vendor }
      />
    </div>
  );
};

export default React.memo(W9Info);
