import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';
import { ShareIcon } from '@src/components/utils/fa_icons';
import { DocumentLinkedIcon, InboxDocumentIcon, UnreadIcon } from '@src/components/utils/icomoon';

import Actions from './actions';
import FlagState from './flag_state';

interface IBusinessInboxListItemProps {
  document: IInboxServiceDocument,
}

const BusinessInboxListItem = ({
  document,
}: IBusinessInboxListItemProps): JSX.Element => {
  const business = useBusinessContext();

  const handleDocumentClick = useCallback(() => {
    if (business) {
      Backbone.history.navigate(
        `/businesses/${business.id}/business_inbox/document/${document.id}`,
        { trigger: true },
      );
    }
  }, [business, document]);

  return (
    <Table.Row>
      <Table.SelectItemCell item={ document.id } />
      <Table.Cell
        pointer
        className="flex-wrapper"
        onClick={ handleDocumentClick }
      >
        {
          business && <FlagState document={ document } />
        }
        <span className={ `font-24 font-bold in-blue-900 m-r-15 ${document.viewedAt ? 'invisible' : ''}` }>
          &bull;
        </span>
        <InboxDocumentIcon className="font-36" />
        <p className="font-16 font-semi-bold inline-block m-l-15 m-t-10 vertical-align-top">
          { document.name }
          {
            document.isForwarded
            && (
              <span className="forwarded-tag in-grey-500 font-10 font-normal border-gray padding-3 m-l-10">
                <ShareIcon />
                { ' ' }
                Forwarded
              </span>
            )
          }
        </p>
      </Table.Cell>
      <Table.Cell
        pointer
        textAlign="right"
      >
        {
          document.unreadDocumentMessages > 0 && (
            <UnreadIcon
              fontSize={ 24 }
              ml={ 3 }
              title={ String(document.unreadDocumentMessages) }
            />
          )
        }
      </Table.Cell>
      <Table.DateCell
        relative
        date={ document.createdAt }
        fontSize={ 12 }
        pl={ 10 }
        style={ { color: '#667786', paddingRight: '30px' } }
        textAlign="right"
      />
      <Table.Cell className="text-right">
        {
          document.documentRequestId
          && (
            <Tooltip
              className="pointer"
              title={ `Linked to Document Request: ${document.documentRequestName}` }
            >
              <DocumentLinkedIcon className="font-20" />
            </Tooltip>
          )
        }
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Actions document={ document } />
      </Table.Cell>
    </Table.Row>
  );
};

export default BusinessInboxListItem;
