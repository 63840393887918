module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n    <div class=\'modal-dialog edit-invoice-modal\'>\n        <div class=\'modal-content\'>\n            <div class="modal-header">\n                <h1 class="font-24 font-semi-bold m-t-0 m-b-0 text-center">\n                    <span class="icon-block font-24 vertical-align-middle m-r-10"></span>\n                    <span class="vertical-align-middle">Cannot Release Payment</span>\n                </h1>\n            </div>\n            <div class=\'modal-body\'>\n                <p class="m-t-0 font-16">Payment cannot be released for this invoice as the invoice has not been approved by the authorized signatory.</p>\n            </div>\n            <div class=\'modal-footer\'>\n                <button type=\'button\' class="btn btn-blue ladda-button cancel" tabindex="10" data-color="blue" data-style="expand-right" data-integration-test="modal-confirm">\n                    <span class="ladda-label">Close</span>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}