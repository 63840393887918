import { useMemo } from 'react';

import classNames, { Argument } from 'classnames';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { IInputCommonProps } from '@src/components/ui_v2/inputs/types';

import { IFormFieldWrapperProps } from './form_field_wrapper';

type IWrapperProps = Omit<IFormFieldWrapperProps, 'children'>;

type TUseFieldWrapperProps<Type extends IInputCommonProps> = [
  IWrapperProps,
  Omit<Type, keyof IWrapperProps>,
];

const WrapperProps: (keyof IWrapperProps)[] = [
  'error',
  'hiddenLabel',
  'hint',
  'label',
  'layout',
  'suffix',
  'valueProps',
];

const useFieldWrapperProps = <Type extends IWrapperProps & IInputCommonProps>(
  props: Type,
  ...args: Argument[]
): TUseFieldWrapperProps<Type> => {
  const wrapperProps: IWrapperProps = useMemo(() => {
    const tempProps = pick(props, WrapperProps);

    const classes = classNames(tempProps.className, args);

    return {
      ...tempProps,
      className: classes,
    };
  }, [props, args]);

  const fieldProps = useMemo(() => {
    const tempProps = omit(props, WrapperProps);

    return {
      size: props.layout === 'table' ? 'compact' : 'normal',
      ...tempProps,
    };
  }, [props]);

  return [
    wrapperProps,
    fieldProps,
  ];
};

export {
  IWrapperProps,
  useFieldWrapperProps,
};
