import { useQuery } from 'react-query';

import { getQRCodeForSelfOnboardingInvitation } from '@src/requests/digital_signatures';

const QR_CODE_QUERY = 'qr_code';

const useGetQRCodeForSelfOnboardingInvitation = (selfOnboardingInvitationId: number) => {
  return useQuery<string, Error>({
    queryKey: QR_CODE_QUERY,
    queryFn:  () => getQRCodeForSelfOnboardingInvitation({
      selfOnboardingInvitationId,
    }),
  });
};

export {
  useGetQRCodeForSelfOnboardingInvitation,
};
