module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header">\n        <h1 class="modal-title font-bold">Rename Report</h1>\n      </div>\n      <div class=\'modal-body\'>\n        <div class="m-t-10">\n          <label class="m-b-10" for="upload-name-input">Enter a new name for the report:</label>\n          <div class="input-wrapper relative report-name-input-wrapper">\n            <input type="text" id="report-name-input" class="form-control report-name-input" value="');
    
      __out.push(__sanitize(this.configuration.get('report_name')));
    
      __out.push('">\n            <span class="report-name-suffix in-grey-1400">- ');
    
      __out.push(__sanitize(this.configuration.getSuffix()));
    
      __out.push('</span>\n          </div>\n          <p class="text-left in-red-400 input-name-warning hidden m-t-10 m-b-0">\n            <i class="fa fa-warning m-r-5"></i>Please input valid name.\n          </p>\n        </div>\n      </div>\n      <div class=\'modal-footer\'>\n        <a class=\'cancel-link cancel pointer\'>Cancel</a>\n        <button type=\'button\' class="btn btn-info ladda-button save-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}