import React, { useCallback } from 'react';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import Table from '@src/components/ui/table';

import { IBankAccountData } from '../../schema';

import styles from '../../styles.module.scss';

interface IfinancialInstitutionBankAccountCenterTableItemProps {
  financialInstitutionBankAccount: IFinancialInstitutionBankAccount,
  financialInstitutionConnection: IFinancialInstitutionConnection,
  onSelected: (bankAccount: IBankAccountData) => void,
}

const FinancialInstitutionBankAccountCenterTableItem = ({
  financialInstitutionBankAccount,
  financialInstitutionConnection,
  onSelected,
}: IfinancialInstitutionBankAccountCenterTableItemProps) => {
  const handleClickBankAccount = useCallback(() => {
    onSelected({
      financialInstitutionLogo: financialInstitutionConnection.financialInstitution.logo,
      financialInstitutionName: financialInstitutionConnection.financialInstitution.name,
      bankAccountId:            financialInstitutionBankAccount.id,
      bankAccountName:          financialInstitutionBankAccount.name,
      bankAccountLast4:         financialInstitutionBankAccount.last4,
    });
  }, [financialInstitutionBankAccount, financialInstitutionConnection, onSelected]);

  return (
    <Table.Row
      className={ styles['financial-banking-accounts-item'] }
      onClick={ handleClickBankAccount }
    >
      <Table.Cell>
        { financialInstitutionBankAccount.last4 ? (
          `${financialInstitutionBankAccount.name} - ${financialInstitutionBankAccount.last4}`
        ) : (
          `${financialInstitutionBankAccount.name}`
        ) }
      </Table.Cell>
      <Table.Cell />
    </Table.Row>
  );
};

export default React.memo(FinancialInstitutionBankAccountCenterTableItem);
