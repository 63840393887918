import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import {
  IGlobalVendorWithBusinessInfo,
  TBusinessVendorsSortColumn,
} from '@src/types/business_vendors';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui/table';

import DocytNetworkItem from './list_item/docyt_network_item';

interface IBusinessVendorListProps {
  vendors: IGlobalVendorWithBusinessInfo[],
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBusinessVendorsSortColumn>,
}

const BusinessGlobalVendorList = ({
  vendors,
  sorting,
  query,
}: IBusinessVendorListProps): JSX.Element => {
  return (
    <Table
      className="table"
      infiniteQuery={ query }
      wrapperClassName="business-inbox-table-wrapper inbox-sortable-table"
    >
      <Table.Head>
        <Table.Row>
          <Table.SelectAllHCell />
          <Table.HCell<TBusinessVendorsSortColumn>
            columnName="vendor"
            sorting={ sorting }
            style={ { paddingLeft: '55px' } }
          >
            Vendors
          </Table.HCell>
          <Table.HCell<TBusinessVendorsSortColumn>
            columnName="docyt_id"
            sorting={ sorting }
            style={ { paddingLeft: '20px' } }
            widthPercent={ 25 }
          >
            Docyt ID
          </Table.HCell>
          <Table.HCell<TBusinessVendorsSortColumn>
            columnName="ach_status"
            sorting={ sorting }
            style={ { paddingLeft: '20px' } }
            widthPercent={ 25 }
          >
            Status
          </Table.HCell>
          <Table.HCell aria-label="Actions" widthPercent={ 5 } />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          vendors.map((vendor) => {
            return (
              <DocytNetworkItem
                key={ vendor.id }
                vendor={ vendor }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default BusinessGlobalVendorList;
