import React from 'react';

import { IClientBusinessPartner } from '@src/types/clients';

import Table from '@src/components/ui/table';
import Tooltip from '@src/components/ui/tooltip';

import styles from '../styles.module.scss';

interface IBusinessAdminsProps {
  businessAdmins: IClientBusinessPartner[];
}

const BusinessAdmins = ({
  businessAdmins,
}: IBusinessAdminsProps) => {
  let content;
  if (businessAdmins.length === 0) {
    content = '';
  } else if (businessAdmins.length > 1) {
    content = (
      <Tooltip
        as="span"
        className="pointer"
        title={ businessAdmins.map((user) => user.name).join(', ') }
      >
        { `${businessAdmins[0].name}, +${businessAdmins.length - 1} more` }
      </Tooltip>
    );
  } else {
    content = (
      <span>{ `${businessAdmins[0]?.name}` }</span>
    );
  }

  return (
    <Table.Cell
      pointer
      className={ styles['dashboard-table-column'] }
      widthPercent={ 8 }
    >
      { content }
    </Table.Cell>
  );
};

export default BusinessAdmins;
