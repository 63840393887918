import { TUUID } from '@src/types/common';
import { IRevenueReconciliation, IRevenueReconciliationData } from '@src/types/revenue_reconciliations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost } from './helpers';

interface IGetRevenueReconciliationParams {
  revenueReconciliationId: TUUID,
}

const getRevenueReconciliation = (
  params: IGetRevenueReconciliationParams,
): Promise<IRevenueReconciliation> => {
  return apiGet(
    `/api/v1/revenue_reconciliations/${params.revenueReconciliationId}`,
  ).then((data) => camelizeKeys(data.revenue_reconciliation) as IRevenueReconciliation);
};

interface IPostRevenueReconciliationParams {
  revenueReconciliation: IRevenueReconciliationData,
}

const postRevenueReconciliation = (params: IPostRevenueReconciliationParams): Promise<void> => {
  return apiPost(
    '/api/v1/revenue_reconciliations',
    underscoreKeys(params),
  );
};

interface IDeleteRevenueReconciliationParams {
  revenueReconciliationId: TUUID,
}

const deleteRevenueReconciliation = (params: IDeleteRevenueReconciliationParams): Promise<void> => {
  return apiDelete(
    `/api/v1/revenue_reconciliations/${params.revenueReconciliationId}`,
  );
};

export {
  IGetRevenueReconciliationParams,
  IPostRevenueReconciliationParams,
  IDeleteRevenueReconciliationParams,
  getRevenueReconciliation,
  postRevenueReconciliation,
  deleteRevenueReconciliation,
};
