import React, { useCallback } from 'react';

import classNames from 'classnames';

import { useUniqueDomId } from '@src/hooks/dom';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import { useItemsSelector } from './hooks';

import styles from './styles.module.scss';

interface IItemsSelectorSelectItemCheckboxProps<Type> extends
  Omit<
    React.ComponentPropsWithoutRef<'input'>,
    'onChange' | 'checked' | 'id' | 'value' | 'type'
  >,
  IUIStyleProps
{
  item: Type,
  checkedLabel?: React.ReactNode,
  uncheckedLabel?: React.ReactNode,
}

const ItemsSelectorSelectItemCheckbox = <Type, >({
  item,
  checkedLabel,
  uncheckedLabel,
  ...props
}: IItemsSelectorSelectItemCheckboxProps<Type>) => {
  const classes = classNames(styles.checkbox, 'checkbox checkbox-primary');
  const [inputClasses, inputProps] = uiStyleProps('pointer', props);

  const inputId = useUniqueDomId('item_selector_');

  const { mark, isSelected } = useItemsSelector<Type>();

  const handleChange = useCallback((e) => {
    mark(item, e.target.checked);
  }, [item, mark]);

  return (
    <div className={ classes }>
      <input
        checked={ isSelected(item) }
        className={ inputClasses }
        id={ inputId }
        title="Select"
        type="checkbox"
        onChange={ handleChange }
        { ...inputProps }
      />
      <label
        aria-label="Select"
        htmlFor={ inputId }
      >
        { isSelected(item) ? checkedLabel : uncheckedLabel}
      </label>
    </div>
  );
};

export default ItemsSelectorSelectItemCheckbox;
