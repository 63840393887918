import React, { forwardRef, useCallback } from 'react';

import classNames from 'classnames';

import { useUniqueDomId } from '@src/hooks/dom';

import styles from './styles.module.scss';

interface ICheckboxInputProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  hiddenTitle?: string,
  labelProps?: React.ComponentPropsWithoutRef<'label'>,
  small?: boolean,
  title?: string,
  intermediate?: boolean,
  onChange?: (checked: boolean, value: string | undefined) => void,
}

const CheckboxInput = forwardRef<HTMLInputElement, ICheckboxInputProps>(({
  hiddenTitle,
  id,
  labelProps,
  small,
  title,
  onChange,
  intermediate = false,
  ...props
}: ICheckboxInputProps, ref) => {
  const inputId = useUniqueDomId('checkbox_input_');

  const classes = classNames(styles['checkbox-input'], {
    [styles['checkbox-input-small']]: small,
    [styles['checkbox-with-text']]:   title && title.length > 0,
    [styles.intermediate]:            intermediate,
  });

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange(e.currentTarget.checked, e.currentTarget.value);
  }, [onChange]);

  const handleLabelClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className={ classes }>
      <input
        key={ `${props.value}-${props.checked}` }
        ref={ ref }
        className={ classes }
        id={ id || inputId }
        title={ hiddenTitle }
        type="checkbox"
        onChange={ handleChange }
        { ...props }
      />
      <label
        aria-label={ hiddenTitle }
        htmlFor={ id || inputId }
        { ...labelProps }
        onClick={ handleLabelClick }
      >
        { title }
      </label>
    </div>
  );
});

CheckboxInput.displayName = 'CheckboxInput';

export default React.memo(CheckboxInput);
