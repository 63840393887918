import React from 'react';

import { IW9InfoResponse } from '@src/types/w9_info';
import {
  formatFullMonthDayYear,
} from '@src/utils/date_helpers';

import styles from './styles.module.scss';

interface IW9InfoDetailsProps {
  w9Info: IW9InfoResponse,
  handleEditClick: () => void;
}

const W9InfoDetails = ({
  w9Info,
  handleEditClick,
}: IW9InfoDetailsProps): JSX.Element => {
  return (
    <div className={ styles['form-container'] }>
      <div className={ styles['form-header'] }>
        <span className={ styles['form-title'] }>Add W9 Info</span>
        <a
          className={ styles['info-edit'] }
          role="button"
          tabIndex={ 0 }
          onClick={ handleEditClick }
        >
          Edit
        </a>
      </div>
      <div className={ styles['form-content-container'] }>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Legal Name</p>
          <p className={ styles['info-value'] }>{w9Info?.w9Name ?? '-'}</p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Legal Business Name</p>
          <p className={ styles['info-value'] }>{w9Info?.w9LegalName ?? '-'}</p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Email</p>
          <p className={ styles['info-value'] }>{w9Info?.w9Email ?? '-'}</p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Phone</p>
          <p className={ styles['info-value'] }>{w9Info?.w9Phone ?? '-'}</p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Entity Type</p>
          <p className={ styles['info-value'] }>{w9Info?.w9BusinessType ?? '-' }</p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Legal Entity Address</p>
          <p className={ styles['info-value'] }>
            { w9Info.w9Address ? `${w9Info.w9Address?.addressLine1}, ${w9Info.w9Address?.addressCity},
                ${w9Info.w9Address?.addressState}, ${w9Info.w9Address?.addressCountry}, ${w9Info.w9Address?.addressZip}` : '-' }
          </p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>Taxpayer Identification Number (TIN)</p>
          <p className={ styles['info-value'] }>
            {w9Info?.w9Ssn && (
            <>
              <span>Business Owner’s SSN: </span>
              *****
              {w9Info.w9Ssn}
            </>
            )}
            {!w9Info?.w9Ssn && w9Info?.w9Ein && (
            <>
              <span>Business EIN: </span>
              *****
              {w9Info.w9Ein}
            </>
            )}
            {!w9Info?.w9Ssn && !w9Info?.w9Ein && ' - '}
          </p>
        </div>
        <div className={ styles['info-content'] }>
          <p className={ styles['info-label'] }>W9 Signed Date</p>
          <p className={ styles['info-value'] }>{w9Info?.w9SignedDate ? formatFullMonthDayYear(w9Info.w9SignedDate) : '-'}</p>
        </div>
      </div>
    </div>
  );
};

export {
  IW9InfoDetailsProps,
  W9InfoDetails as default,
};
