import React, { useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import AddAdjustment from './add_adjustment';
import CombinedList from './combined_list';
import ExpensesList from './expenses_list';
import { useCSDCollection, useTSDCollection } from './hooks';

import styles from './styles.module.scss';

const MatchDocuments = () => {
  const business = useBusinessContext();

  const [includeUnverified, setIncludeUnverified] = useState(true);

  const csdCollection = useCSDCollection({ businessId: business.id });
  const tsdCollection = useTSDCollection({ businessId: business.id, includeUnverified });

  return (
    <DetailsRegion>
      <DetailsRegion.Header
        help="Docyt was not able to match these documents with any transactions. Select documents from the left table and transactions from the right table to match."
        title="Uncleared Documents"
      >
        <CheckboxInput
          checked={ includeUnverified }
          title="Include Uncategorized Transactions"
          onChange={ setIncludeUnverified }
        />
        <AddAdjustment csdCollection={ csdCollection } tsdCollection={ tsdCollection } />
      </DetailsRegion.Header>
      <div className={ styles['tables-container'] }>
        <CombinedList collection={ csdCollection } />
        <ExpensesList collection={ tsdCollection } includeUnverified={ includeUnverified } />
      </div>
    </DetailsRegion>
  );
};

export default React.memo(MatchDocuments);
