import { useQueryClient, useMutation } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  updateBusinessBookkeepingStatus,
  IBusinessBookkeepingStatusUpdateParams,
} from '@src/requests/business_bookkeeping_statuses';
import { IBusinessBookkeepingStatus } from '@src/types/business_bookkeeping_statuses';

const useUpdateBusinessBookkeepingStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBusinessBookkeepingStatus,
    Error,
    IBusinessBookkeepingStatusUpdateParams
  >(
    updateBusinessBookkeepingStatus,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.bankAccountReconciliation);
        queryClient.invalidateQueries(QueryKey.calculateBankAccountReconciliation);
      },
    },
  );
};

export {
  useUpdateBusinessBookkeepingStatus,
};
