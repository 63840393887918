import React from 'react';

interface IModalBodyProps {
  children: React.ReactNode,
}

const ModalBody = ({
  children,
}: IModalBodyProps) => {
  return (
    <div className="modal-body">
      { children }
    </div>
  );
};

export default ModalBody;
