import React, { forwardRef } from 'react';

import Icomoon, { IIcomoonIconProps, TIcomoonRef } from './icomoon';

const BankStatementUploadedInactive = forwardRef<TIcomoonRef, IIcomoonIconProps>(({
  ...props
}: IIcomoonIconProps, ref) => {
  return (
    <Icomoon
      ref={ ref }
      { ...props }
      iconName="bank-statement-uploaded-hover"
      pathsNumber={ 3 }
    />
  );
});

BankStatementUploadedInactive.displayName = 'BankStatementUploadedInactive';

export default BankStatementUploadedInactive;
