import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useBulkPayViaAch } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { getNeedReauthentication } from '@src/requests/users';
import { TID } from '@src/types/common';
import { currencyFormater3 } from '@src/utils/currency';
import { formatDate } from '@src/utils/date_helpers';

import BulkActionResult from '@src/components/accounts_payable/review_payment/bulk_payment_modals/bulk_action_result';
import { groupInvoicesProp, splitInvoices } from '@src/components/accounts_payable/review_payment/utils/regroup_invoices_helper';
import { bulkPaymentModalTitle } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';
import { useVerifyIdentityModal } from '@src/components/accounts_payable/verifiy_identity_modal';
import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';
import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

import Table from './table';

import styles from '../../styles.module.scss';

type TOpenValue = {
  type: string;
  groupedServiceDocuments: groupInvoicesProp,
  serviceDocumentIds: Array<Array<number>>,
  senderBankAccountName?: string,
  totalAmount: number;
  limitCountToPay: number;
}

interface IPayByDocytAchModalProps extends IUseModalProps<TOpenValue> {
  businessId: TID;
}

const payByDocytCheckResult = (status: IBulkActionStatus<any, any>) => (
  <BulkActionResult status={ status } />
);

const PayByDocytAchModal = ({
  businessId,
  isOpen,
  openValue,
  onDone,
  onCancel,
}: IPayByDocytAchModalProps) => {
  const {
    type,
    serviceDocumentIds,
    groupedServiceDocuments,
    senderBankAccountName,
    totalAmount,
    limitCountToPay,
  } = openValue || {};

  const payViaAch = useBulkPayViaAch();
  const bulkAction = useBulkActionModal({
    mutation: payViaAch,
  });

  const { runMutation } = bulkAction;

  const handlePayByDocytCheck = useCallback(() => {
    if (!serviceDocumentIds) return;

    document.querySelector('.bulk-pay-by-docyt-ach-modal')?.classList.add('hide');

    const params: any = [];
    serviceDocumentIds.forEach((ids: Array<number>) => {
      if (limitCountToPay && ids.length > limitCountToPay) {
        const splitIds = splitInvoices(ids, limitCountToPay);
        splitIds.forEach((splitId: Array<number>) => {
          params.push({
            businessId,
            paidAt:             formatDate(new Date()),
            serviceDocumentIds: splitId,
          });
        });
      } else {
        params.push({
          businessId,
          paidAt:             formatDate(new Date()),
          serviceDocumentIds: ids,
        });
      }
    });

    runMutation(params);
  }, [serviceDocumentIds, runMutation, businessId]);

  const verifyIdentity = useVerifyIdentityModal({
    onDone: () => handlePayByDocytCheck(),
  });
  const { open: openVerifyIdentityModal } = verifyIdentity;

  const handleProceed = useCallback(() => {
    getNeedReauthentication()
      .then((res) => {
        if (res.needReauthentication) {
          openVerifyIdentityModal();
        } else {
          handlePayByDocytCheck();
        }
      });
  }, [handlePayByDocytCheck, openVerifyIdentityModal]);

  const handleBulkDone = useCallback(() => {
    document.querySelector('.bulk-pay-by-docyt-ach-modal')?.classList.remove('hide');
    onDone();
  }, [onDone]);

  if (!type) return null;

  return (
    <>
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Payments"
        needTitleSuffix={ false }
        result={ payByDocytCheckResult }
        title="Processing Payment.."
        onDone={ handleBulkDone }
      />
      <verifyIdentity.Component
        { ...verifyIdentity.props }
        email={ window.Docyt.currentAdvisor.get('email') }
      />
      <Modal
        showClose
        className={ `${styles['pay-by-ach-modal']}, bulk-pay-by-docyt-ach-modal` }
        show={ isOpen }
        title={ bulkPaymentModalTitle(type) }
        onCancel={ onCancel }
      >
        <Modal.Body>
          { isOpen && groupedServiceDocuments && (
            <div className="display-flex-column width-100-percent">
              <p className="width-100-percent m-t-20">
                <span className="font-bold">Sender Bank Account:</span>
                { ' ' }
                { senderBankAccountName }
              </p>
              <div className="banking-accounts-table-wrapper">
                <Table
                  businessId={ businessId }
                  groupedServiceDocuments={ groupedServiceDocuments }
                />
              </div>
            </div>
          ) }
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton
            onClick={ onCancel }
          />
          <div className="display-inline-flex align-items-center">
            <div className="m-r-10">
              <span className="in-grey-1100">Total Payable Amount:</span>
              { ' ' }
              <span className="font-bold in-black">{currencyFormater3(totalAmount || 0)}</span>
            </div>
            <Button
              bsColor="blue"
              onClick={ handleProceed }
            >
              Confirm & Pay
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MemoizedPayByDocytAchModal = React.memo(PayByDocytAchModal);
const usePayByDocytAchModal = makeUseModal<
 typeof MemoizedPayByDocytAchModal, TOpenValue>(MemoizedPayByDocytAchModal);

export {
  IPayByDocytAchModalProps,
  usePayByDocytAchModal,
  PayByDocytAchModal as default,
};
