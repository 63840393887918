import React from 'react';

import { IDataExport } from '@src/types/data_exports';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import DataExportItemDeleteAction from './data_export_item_actions/delete_action';
import DataExportItemDownloadAction from './data_export_item_actions/download_action';
import DataExportItemEditAction from './data_export_item_actions/edit_action';

interface IDataExportListItemActionProps {
  dataExport: IDataExport,
}

const DataExportListItemAction = ({
  dataExport,
}: IDataExportListItemActionProps): JSX.Element => {
  const dataExportRevampV1Enabled: boolean = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.DATA_EXPORT_REVAMP_V_1_FLAG);

  return (
    <ActionsDropdown>
      <DataExportItemDownloadAction dataExport={ dataExport } />
      {dataExportRevampV1Enabled && <DataExportItemEditAction dataExport={ dataExport } />}
      <DataExportItemDeleteAction dataExport={ dataExport } />
    </ActionsDropdown>
  );
};

export default DataExportListItemAction;
