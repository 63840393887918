import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface ICalendarProps extends Omit<IFaIconProps, 'icon'> {
  variant?: 'o' | 'plus-o' | 'times-o' | 'check-o' | 'minus-o',
}

const Calendar = ({
  variant,
  ...props
}: ICalendarProps): JSX.Element => {
  return (
    <FaIcon
      icon={ variant ? `calendar-${variant}` : 'calendar' }
      { ...props }
    />
  );
};

export default Calendar;
