import React from 'react';

import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';

import { IManagementGroupsFilter } from './types';

import styles from '../styles.module.scss';

interface IManagementGroupsFilterProps {
  filter: IFilter<IManagementGroupsFilter>;
}

const ManagementGroupsFilter = ({
  filter,
}: IManagementGroupsFilterProps) => {
  return (
    <Filter<IManagementGroupsFilter> className={ styles['filter-wrapper'] } filter={ filter }>
      <Filter.TextField
        name="name"
        placeholder="Search by Name"
      />
    </Filter>
  );
};

export default React.memo(ManagementGroupsFilter);
