import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import {
  BotNotificationIcon, VerifiedManuallyIcon 
} from '@src/components/utils/icomoon';

interface IStatusIconProps {
  document: ITransactionServiceDocument,
}

const StatusIcon = ({
  document,
}: IStatusIconProps) => {
  if (document.revenueReconciliationItem) {

    return (document.revenueReconciliationItem.isAutoReconciled)
    ? <BotNotificationIcon fontSize={ 22 } />
    : <VerifiedManuallyIcon fontSize={ 22 } />;
  }
  return null;
};

export default React.memo(StatusIcon);
