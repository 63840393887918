import React, { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useExportReportData } from '@src/hooks/queries/report_service/custom_reports';
import { IAdvancedReportsFileParams } from '@src/requests/report_service/custom_reports';
import { IReport } from '@src/types/report_service/report';
import { endOfMonthApiDate, formatApiDate, formatDate, parseApiDate, startOfMonthApiDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { budgetsSelectAddValueType, forecastSelectAddValueType, hideZeroRowsType, multiSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import { ExportAsPdfIcon } from '@src/components/utils/icomoon';

import { reportDataPeriodType, reportFilePreparing, reportFilterDateRange } from '../../atoms';

import styles from '../styles.module.scss';

interface IExportReportAsPdfActionProps {
  report: IReport,
}

const ExportReportAsPdfAction = ({
  report,
}: IExportReportAsPdfActionProps): JSX.Element => {
  const periodStatus = useRecoilValue(reportDataPeriodType);
  const setPdfFilePreparing = useSetRecoilState(reportFilePreparing);
  const hideZeroRows = useRecoilValue(hideZeroRowsType);
  const reportDateRange = useRecoilValue(reportFilterDateRange);
  const multiValue = useRecoilValue(multiSelectAddValueType);
  const budgetsSelectValue = useRecoilValue(budgetsSelectAddValueType);
  const forecastSelectValue = useRecoilValue(forecastSelectAddValueType);
  const downloadReports = useExportReportData();
  const { mutate } = downloadReports;

  const handleClick = useCallback(() => {
    let params: IAdvancedReportsFileParams;

    if (report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT) {
      params = {
        export_type:  'report',
        report_id:    report.id,
        start_date:   startOfMonthApiDate(parseApiDate(reportDateRange.gte)),
        end_date:     endOfMonthApiDate(parseApiDate(reportDateRange.lte)),
        file_type:    'pdf',
        filter: {
          is_zero_rows_hidden: hideZeroRows,
          accounting_class_id: ''
        }
      };
    } else {
      params = {
        export_type:  'report',
        report_id:    report.id,
        start_date:   startOfMonthApiDate(parseApiDate(reportDateRange.gte)),
        end_date:     endOfMonthApiDate(parseApiDate(reportDateRange.lte)),
        is_daily:     false,
        file_type:    'pdf',
        filter:       {}
      };
  
      if (periodStatus === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
        params.is_daily = true
        params.start_date = formatApiDate(formatDate(reportDateRange.gte));
        params.end_date = formatApiDate(formatDate(reportDateRange.lte));
        params.filter = {
          is_zero_rows_hidden: hideZeroRows
        }
      } else {
        params.filter = {
          is_zero_rows_hidden:  hideZeroRows,
          budgets_comparison:   false,
          forecast_comparison:  false,
          last_year:            multiValue.includes('last_year'),
          per_available_room:   multiValue.includes('per_available_room'),
          per_occupied_room:    multiValue.includes('per_occupied_room'),
          percentage_column:    multiValue.includes('percentage_column'),
          budget:               budgetsSelectValue.length !== 0 ? budgetsSelectValue[0] : null,
          forecast:             forecastSelectValue.length !== 0 ? forecastSelectValue[0] : null,
          ...(budgetsSelectValue.length !== 0 ? {[`checkbox-budget-${budgetsSelectValue[0]}`]: true} : {}),
          ...(forecastSelectValue.length !== 0 ? {[`checkbox-forecast-${forecastSelectValue[0]}`]: true} : {})
        }
      };
    }

    mutate(params, { onSuccess: () => setPdfFilePreparing(true) })
    
  }, [
    setPdfFilePreparing,
    mutate,
    report.templateId,
    periodStatus,
    budgetsSelectValue,
    forecastSelectValue,
    hideZeroRows,
    multiValue,
    report.id,
    reportDateRange?.gte,
    reportDateRange?.lte
  ]);

  return (
    <ActionsDropdown.Action
      className={ styles['dropdown-item'] }
      icon={ <ExportAsPdfIcon fontSize={ 18 } /> }
      title="Export as Pdf"
      onSelect={ handleClick }
    />
  );
};

export default ExportReportAsPdfAction;
