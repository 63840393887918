import React, { useRef } from 'react';

import isArray from 'lodash/isArray';
import { UseInfiniteQueryResult } from 'react-query';

import { useInfiniteScroll } from '@src/hooks/scroll';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface IInfiniteScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  wrapperClassName?: string,
infiniteQuery: UseInfiniteQueryResult<any, Error> | UseInfiniteQueryResult<any, Error>[],
}

const InfiniteScrollTable = ({
  children,
  infiniteQuery,
  wrapperClassName,
  ...tableProps
}: IInfiniteScrollTableProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useInfiniteScroll({
    elementRef: wrapperRef,
    query:      isArray(infiniteQuery) ? infiniteQuery[0] : infiniteQuery,
  });

  return (
    <div
      ref={ wrapperRef }
      className={ wrapperClassName }
    >
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus query={ infiniteQuery } />
    </div>
  );
};

export default React.memo(InfiniteScrollTable);
