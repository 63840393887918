import React from 'react';

import { IManagementGroup, TManagementGroupSettingsSortColumn } from '@src/types/management_groups';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';

interface IManagementGroupSettingsTableProps {
  records: IManagementGroup[],
  sorting: ISorting<TManagementGroupSettingsSortColumn>,
}

const ManagementGroupSettingsTable = ({
  records,
  sorting,
}: IManagementGroupSettingsTableProps) => {
  return (
    <CollectionTable<
      IManagementGroup,
      TManagementGroupSettingsSortColumn
    >
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<IManagementGroup>
        hideValueTooltip
        name="name"
        title="Name"
        value={ (managementGroup) => managementGroup.name }
        width="100%"
      />
      <CollectionTable.SelectItemColumn<IManagementGroup>
        name="kpi_notification_enabled"
        title="Notification Flag"
        value={ (managementGroup) => managementGroup.id }
      />
    </CollectionTable>
  );
};

export default React.memo(ManagementGroupSettingsTable);
