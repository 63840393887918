module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var country, i, j, len, len1, state;
    
      __out.push('<div class="setup-business-step-section setup-ar-section">\n  <div class="main-panel">\n    <div class="profile-header">\n      <h2 class="panel-title">Create Your Vendor Profile</h2>\n      <p class="panel-description">\n        By creating a vendor profile, your clients will be able to find you in the Docyt Business Network.\n      </p>\n    </div>\n    <div class="profile-body vendor-add-sidebar">\n      <!-- Vendor Avatar Upload Area Begin -->\n      <div class="add-photo-wrapper text-center ');
    
      __out.push(__sanitize(this.image_url ? 'avatar-exist' : ''));
    
      __out.push('">\n        <img class="personal-logo" src="');
    
      __out.push(__sanitize(this.image_url));
    
      __out.push('">\n        <button class="font-bold fileinput-button">\n          <span class="icon-booth font-30 in-grey-700"></span>\n          <p class="font-12">Add Logo</p>\n          <input type="file" name="files[]" class="logo-file-input">\n        </button>\n        <p class="valid-hint-js in-red-400 logo-invalid">\n          File format is not supported.\n        </p>\n      </div>\n      <!-- Vendor Display Name Begin -->\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Business Display Name</label>\n        <input type="text" class="profile-input width-60-percent form-control vendor-name-js" placeholder="Small, Simpler Name" value = "');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n        <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label class="width-40-percent text-left">Docyt ID</label>\n        <input type="text" class="profile-input width-60-percent form-control docyt-id-js" placeholder="Keep it simple letters and numbers only"\n          value = "');
    
      __out.push(__sanitize(this.arService.get('docyt_network_id') ? this.arService.get('docyt_network_id') : this.suggestedDocytNetworkId));
    
      __out.push('">\n        <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <span class="font-12 in-grey-1050">Docyt ID cannot be changed later.</span>\n      </div>\n      <!-- Vendor Payment Address Begin -->\n      <p class="sub-title">Payment Address Information</p>\n      <div class="profile-input-wrap">\n        <label>Check Payable To</label>\n        <input type="text" class="profile-input form-control check-payable-js" placeholder="Name on the payment checks" value = "');
    
      __out.push(__sanitize(this.address.name || Docyt.currentAdvisor.get('full_name')));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label>Department</label>\n        <input type="text" class="profile-input form-control department-js" placeholder="Optional" value = "');
    
      __out.push(__sanitize(this.address.company));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label>Address</label>\n        <input type="text" class="profile-input form-control address-street-js" placeholder="Street" value = "');
    
      __out.push(__sanitize(this.address_street));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <input type="text" class="address-input form-control address-city-js" placeholder="City" value = "');
    
      __out.push(__sanitize(this.address_city));
    
      __out.push('">\n        ');
    
      if (this.address_country === 'US') {
        __out.push('\n        <select class="address-input form-control address-state-js" placeholder="State" >\n          ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          state = usaStateList[i];
          __out.push('\n          <option value="');
          __out.push(__sanitize(state.abbreviation));
          __out.push('" ');
          if (this.address_state === state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(state.text));
          __out.push('</option>\n          ');
        }
        __out.push('\n        </select>\n        ');
      } else {
        __out.push('\n        <input type="text" class="address-input form-control address-state-js" placeholder="State" value = "');
        __out.push(__sanitize(this.address_state));
        __out.push('">\n        ');
      }
    
      __out.push('\n        <input type="text" class="address-input form-control address-zip-js" placeholder="Zip Code" value = "');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label></label>\n        <select class="profile-input form-control address-country-js" placeholder="Country" >\n          ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n          <option value="');
        __out.push(__sanitize(country.code));
        __out.push('" ');
        if (this.address_country === country.code) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(country.text));
        __out.push('</option>\n          ');
      }
    
      __out.push('\n        </select>\n      </div>\n      <!-- Vendor Business Contact Begin -->\n      <p class="sub-title">Business Contact Information</p>\n      <div class="profile-input-wrap">\n        <label>Website</label>\n        <input type="text" class="profile-input form-control web-site-js" placeholder="www.website.com" value = "');
    
      __out.push(__sanitize(this.website));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label>Email*</label>\n        <input type="text" class="profile-input form-control email-js" placeholder="vendor@email.com" value = "');
    
      __out.push(__sanitize(this.email || Docyt.currentAdvisor.get('email')));
    
      __out.push('">\n        <p class="valid-hint-js m-t-1 m-r-7 in-red-400"><i class="fa fa-warning"></i></p>\n      </div>\n      <div class="profile-input-wrap">\n        <label>Phone</label>\n        <input type="text" class="profile-input form-control phone-js" placeholder="Phone number" value = "');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('">\n      </div>\n      <div class="profile-input-wrap">\n        <label>Fax</label>\n        <input type="text" class="profile-input form-control fax-js" placeholder="Fax number" value = "');
    
      __out.push(__sanitize(this.fax));
    
      __out.push('">\n      </div>\n    </div>\n  </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}