import React from 'react';

import { TID } from '@src/types/common';

import { groupInvoicesProp } from '@src/components/accounts_payable/review_payment/utils/regroup_invoices_helper';
import { totalAmount } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';
import Table from '@src/components/ui/table';

import Item from './table_item';

import styles from '../styles.module.scss';

interface IReviewPaymentTableProps {
  businessId: TID,
  groupedServiceDocuments: groupInvoicesProp,
}

const ReviewPaymentTable = ({
  businessId,
  groupedServiceDocuments,
}: IReviewPaymentTableProps) => {
  const groupedInvoices:any = [];
  Object.keys(groupedServiceDocuments).forEach((key) => {
    const invoices = groupedServiceDocuments[key];
    groupedInvoices.push(
      <Item
        key={ invoices[0].id }
        amounts={ totalAmount(invoices) }
        businessId={ businessId }
        vendorId={ invoices[0].vendor?.id }
      />,
    );
  });

  return (
    <Table
      className={ `banking-transactions-table ${styles['pay-via-ach-table']}` }
      wrapperClassName="banking-transactions-table-wrapper"
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 20 }>Receipient</Table.HCell>
          <Table.HCell widthPercent={ 30 }>Recipient Bank Account</Table.HCell>
          <Table.HCell widthPercent={ 33 }>Recipient Address</Table.HCell>
          <Table.HCell widthPercent={ 17 }>
            <span className="pull-right">Amount</span>
          </Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        { groupedInvoices }
      </Table.Body>
    </Table>
  );
};

export default React.memo(ReviewPaymentTable);
