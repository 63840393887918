module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="invoice-detail-column checkbox-clickable relative pointer">\n  <div class="checkbox checkbox-primary">\n    <input type="checkbox" id="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox" ');
    
      __out.push(__sanitize(this.is_checked ? 'checked' : ''));
    
      __out.push(' class="check-invoice-document pointer">\n    <label for="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-checkbox"></label>\n  </div>\n</td>\n<td class="invoice-detail-column flag-panel pointer">\n  ');
    
      if (this.starred_by_id) {
        __out.push('\n  <i class=\'font-20 fa fa-star in-vivid-orange\'></i>\n  ');
      } else {
        __out.push('\n  <i class=\'font-20 fa fa-star-o in-dark-100 flag-selected\'></i>\n  ');
      }
    
      __out.push('\n</td>\n');
    
      if (this.forPayee) {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <span class="font-14 vertical-align-top p-l-10">');
        __out.push(__sanitize(this.invoice_number));
        __out.push('</span>\n</td>\n');
      } else {
        __out.push('\n<td class="invoice-avatar-column flex-wrapper relative invoice-clickable pointer">\n  <div class="header__userpic inline-block">\n    <img src="');
        __out.push(__sanitize(this.vendor_avatar_url));
        __out.push('" class="header__userpic-img">\n  </div>\n  ');
        if (this.is_imported) {
          __out.push('\n  <div class="payee-avatar-qbo-mark"><span class="icon icon-qbo-on font-16"></span></div>\n  ');
        }
        __out.push('\n  <p class="payee-name-truncate payee-name-field font-15 inline-block m-l-15 m-t-10 vertical-align-top" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.vendor_name));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n<td class="relative pointer">\n  ');
    
      if (this.is_document_uploaded) {
        __out.push('\n  <i class="font-24 icon icon-preview relative m-l-3 preview-clickable"></i>\n  ');
      }
    
      __out.push('\n</td>\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <a class="invoice-status-tooltip" data-toggle="tooltip" data-placement="bottom" title="');
    
      __out.push(__sanitize(this.payment_transaction_error));
    
      __out.push('">\n    <span class="invoice-status-panel font-bold ');
    
      __out.push(__sanitize(this.label_state));
    
      __out.push(' text-nowrap">');
    
      __out.push(__sanitize(this.check_status));
    
      __out.push('</span>\n  </a>\n</td>\n<td>\n  ');
    
      if (this.hasPossibleMatch) {
        __out.push('\n    <a class="pointer icon icon-cash-2 font-18 possible-match-icon" /></a>\n  ');
      }
    
      __out.push('\n</td>\n');
    
      if (!this.forPayee) {
        __out.push('\n<td class="relative pointer">\n  <a href="/document/');
        __out.push(__sanitize(this.document_id));
        __out.push('/chat">\n    ');
        if (this.unread_messages_count > 0) {
          __out.push('\n    <i class="font-24 icon icon-unread relative m-l-3 unread-messages-count"><span>');
          __out.push(__sanitize(this.unread_messages_count));
          __out.push('</span></i>\n    ');
        } else {
          __out.push('\n    <!-- For Icon Read -->\n    <svg width="24px" height="24px" viewBox="0 0 24 24" class="m-l-3">\n      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n        <g id="03-Business---02-APS-Queue-01" transform="translate(-591.000000, -566.000000)" fill-rule="nonzero" stroke="#4DA1FF" stroke-width="2">\n          <g id="Group-10" transform="translate(140.000000, 368.000000)">\n            <g id="Group-9" transform="translate(451.000000, 198.000000)">\n              <path d="M2,20.8357864 L6.78578644,16.05 L22.7,16.05 C23.0002153,16.05 23.25,15.8002153 23.25,15.5 L23.25,1.55 C23.25,1.24978475 23.0002153,1 22.7,1 L2.55,1 C2.24978475,1 2,1.24978475 2,1.55 L2,20.8357864 Z" id="Shape"></path>\n            </g>\n          </g>\n        </g>\n      </g>\n    </svg>\n    ');
        }
        __out.push('\n  </a>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.isAccountantMember || this.canShowCategorization) {
        __out.push('\n<td class="invoice-detail-column invoice-chart-of-account-column relative">\n  ');
        if (this.chart_of_account_display_name) {
          __out.push('\n  <p>');
          __out.push(__sanitize(this.chart_of_account_display_name));
          __out.push('</p>\n  ');
        }
        __out.push('\n</td>\n');
      }
    
      __out.push('\n');
    
      if (!this.forPayee) {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <p class="invoice-number-column over-flow-hidden font-14 p-l-10 m-b-0" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.invoice_number));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.type === 'paid') {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <p class="invoice-date-column font-14 p-l-10 m-b-0 text-nowrap" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.invoice_date_formated));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (!this.forPayee && this.type === 'paid') {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <p class="account-number-column over-flow-hidden font-14 p-l-10 m-b-0" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.account_number));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.type === 'paid') {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <p class="payment-method-column over-flow-hidden font-14 p-l-10 m-b-0 text-nowrap" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.payment_method));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n');
    
      if (this.type !== 'paid') {
        __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  <p class="invoice-date-column font-14 p-l-10 m-b-0 text-nowrap" data-toggle="tooltip" data-placement="bottom">');
        __out.push(__sanitize(this.invoice_date_formated));
        __out.push('</p>\n</td>\n');
      }
    
      __out.push('\n<td class="invoice-detail-column relative invoice-clickable pointer">\n  ');
    
      if (this.type === 'paid') {
        __out.push('\n    <span class="font-14 p-l-10 text-nowrap">');
        __out.push(__sanitize(this.payment_date_formated));
        __out.push('</span>\n  ');
      } else {
        __out.push('\n    ');
        if (this.invoice_due_date_formated) {
          __out.push('\n      ');
          if (this.due_dated) {
            __out.push('\n        <span class="font-14 in-red-400 p-l-10 text-nowrap">');
            __out.push(__sanitize(this.invoice_due_date_formated));
            __out.push('</span><br>\n        <span class="font-11 in-white m-l-10 font-bold overdue-badge">Overdue</span>\n      ');
          } else {
            __out.push('\n        <span class="font-14 p-l-10 text-nowrap">');
            __out.push(__sanitize(this.invoice_due_date_formated));
            __out.push('</span>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    <td class="invoice-detail-column relative invoice-clickable pointer">\n      <span class="font-14 uploader-name-truncate inline-block">');
        __out.push(__sanitize(this.uploader_name));
        __out.push('</span>\n    </td>\n  ');
      }
    
      __out.push('\n</td>\n<td class="qbo-status-icon-column ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' document-clickable pointer vertical-align-middle-important" style="width:50px"></td>\n<td class="invoice-amount-column max-width-130px relative text-right">\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}