module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var country, i, j, len, len1, state;
    
      __out.push('<div class="modal fade" tabindex="-1" role="dialog">\n  <div class="modal-dialog address-modal-dialog">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="modal-title font-bold">');
    
      __out.push(__sanitize(this.title));
    
      __out.push('</h4>\n      </div>\n      <div class="modal-body">\n          <div class="">\n              <div class="account-type__data-area p-r-10 p-l-10">\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Check Payable to</label>\n                  <input type="text" class="acount-type__input form-control address-name-js" placeholder="Name" value = "');
    
      __out.push(__sanitize(this.name));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Company</label>\n                  <input type="text" class="acount-type__input form-control address-company-js" placeholder="Optional" value = "');
    
      __out.push(__sanitize(this.company));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for="">Address</label>\n                  <input type="text" class="acount-type__input form-control address-street1-js" placeholder="Street" value = "');
    
      __out.push(__sanitize(this.address_line1));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for=""></label>\n                  <input type="text" class="acount-type__input form-control address-street2-js" placeholder="Street(Continued)" value = "');
    
      __out.push(__sanitize(this.address_line2));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                ');
    
      if (this.address_country === 'US') {
        __out.push('\n                  <div class="acount-type__input-wrap main-input-wrap">\n                    <label class="form-label width-140px font-15" for=""></label>\n                    <div class="city-state-zip m-r-10 relative">\n                      <input type="text" class="acount-type__input form-control address-city-js" placeholder="City" value = "');
        __out.push(__sanitize(this.address_city));
        __out.push('">\n                      <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                    </div>\n                    <div class="city-state-zip relative">\n                      <select class="acount-type__input form-control address-state-js" placeholder="State" >\n                        ');
        for (i = 0, len = usaStateList.length; i < len; i++) {
          state = usaStateList[i];
          __out.push('\n                        <option value="');
          __out.push(__sanitize(state.abbreviation));
          __out.push('" ');
          if (this.address_state === state.abbreviation) {
            __out.push(__sanitize('selected'));
          }
          __out.push('>');
          __out.push(__sanitize(state.text));
          __out.push('</option>\n                        ');
        }
        __out.push('\n                      </select>\n                      <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                    </div>\n                  </div>\n                ');
      } else {
        __out.push('\n                  <div class="acount-type__input-wrap main-input-wrap">\n                    <label class="form-label width-140px font-15" for=""></label>\n                    <input type="text" class="acount-type__input form-control address-city-js" placeholder="City" value = "');
        __out.push(__sanitize(this.address_city));
        __out.push('">\n                    <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                  </div>\n                  <div class="acount-type__input-wrap main-input-wrap">\n                    <label class="form-label width-140px font-15" for=""></label>\n                    <input type="text" class="acount-type__input form-control address-state-js" placeholder="State/ Providence/ Region" value = "');
        __out.push(__sanitize(this.address_state));
        __out.push('">\n                    <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                  </div>\n                ');
      }
    
      __out.push('\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for=""></label>\n                  <input type="text" class="acount-type__input form-control address-zip-js" placeholder="ZIP" value = "');
    
      __out.push(__sanitize(this.address_zip));
    
      __out.push('">\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                <div class="acount-type__input-wrap main-input-wrap">\n                  <label class="form-label width-140px font-15" for=""></label>\n                  <select class="acount-type__input form-control address-country-js" placeholder="Country" value="');
    
      __out.push(__sanitize(this.address_country));
    
      __out.push('">\n                    ');
    
      for (j = 0, len1 = countryList.length; j < len1; j++) {
        country = countryList[j];
        __out.push('\n                      <option value="');
        __out.push(__sanitize(country.code));
        __out.push('" ');
        if (this.address_country === country.code) {
          __out.push(__sanitize('selected'));
        }
        __out.push('>');
        __out.push(__sanitize(country.text));
        __out.push('</option>\n                    ');
      }
    
      __out.push('\n                  </select>\n                  <p class="main-input__hint in-red-400"><i class="fa fa-warning"></i></p>\n                </div>\n                ');
    
      if (!this.id) {
        __out.push('\n                  <div class="acount-type__input-wrap main-input-wrap">\n                    <label class="form-label width-140px font-15" for=""></label>\n                    <div class="checkbox checkbox-inline in-green-600">\n                      <input type="checkbox" class="styled authorized-address-js" id="checkbox-authorized-address" ');
        __out.push(__sanitize(this.is_authorized ? 'checked' : ''));
        __out.push('>\n                      <label for="checkbox-authorized-address">Add as Authorized Address</label>\n                    </div>\n                  </div>\n                ');
      }
    
      __out.push('\n              </div>\n          </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel settings-cancel-btn m-t-8 pull-left\'>Cancel</a>\n        <button type=\'button\' class="btn btn-blue address-save-btn">Save</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}