import React, { ReactNode } from 'react';

import { useUniqueDomId } from '@src/hooks/dom';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown';
import { ThreeDotsIcon } from '@src/components/utils/icomoon';

import Action from './action';
import DangerAction from './danger_action';
import DividerItem from './divider';
import LinkAction from './link_action';
import Submenu from './submenu';

interface IActionsDropdownProps extends IUIStyleProps {
  /** Dropdown alignment */
  alignment?: 'right' | 'left',
  labelId?: string,
  children?: ReactNode,
  /** Title of toggle button. */
  title?: React.ReactNode,
  titleClassName?: string,
}

/**
 * Root element for Actions Dropdown component.
 *
 * Based on [Dropdown](#/Dropdown?id=dropdown) component.
 */
const ActionsDropdown = ({
  alignment = 'right',
  children,
  title,
  titleClassName = 'in-grey-800',
  ...props
}: IActionsDropdownProps): JSX.Element => {
  const buttonId = useUniqueDomId('actions_dropdown_');

  return (
    <Dropdown { ...props }>
      <Dropdown.LinkToggle
        className={ titleClassName }
        fontSize={ 15 }
        fontVariant="bold"
        id={ buttonId }
      >
        { title || <ThreeDotsIcon fontSize={ 24 } /> }
      </Dropdown.LinkToggle>
      <Dropdown.Menu
        alignment={ alignment }
        aria-labelledby={ buttonId }
        className="toggle-action-dropdown"
        fontSize={ 24 }
      >
        { children }
      </Dropdown.Menu>
    </Dropdown>
  );
};

ActionsDropdown.Action = Action;
ActionsDropdown.DangerAction = DangerAction;
ActionsDropdown.Divider = DividerItem;
ActionsDropdown.LinkAction = LinkAction;
ActionsDropdown.Submenu = Submenu;

export default ActionsDropdown;
