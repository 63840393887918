import React from 'react';

import Root from '@src/components/root';

import GlobalHeaderSearch from './search';

export type TAccountTypesAllowed = 'accounting_firm' | 'regular';
interface IGlobalSearchProps {
  accountType: TAccountTypesAllowed,
}

const GlobalSearch = ({
  accountType,
}: IGlobalSearchProps) => {
  return (
    <Root>
      <GlobalHeaderSearch accountType={ accountType } />
    </Root>
  );
};

export default GlobalSearch;
