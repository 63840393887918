import React, { ReactNode } from 'react';

interface IHeaderRegionTitleProps {
  children: ReactNode;
}

const HeaderRegionTitle = ({ children }: IHeaderRegionTitleProps): JSX.Element => {
  return <div>{children}</div>;
};

export default React.memo(HeaderRegionTitle);
