import React, { useCallback } from 'react';

import Dropdown from '../../dropdown';
import DropdownCheckboxItem from '../../dropdown/checkbox_item';
import { TSubOption } from '../types';

interface DropdownInputItemProps {
  selected: string[],
  option: TSubOption,
  onSelect: (checked: boolean, value: string) => void,
}

const Item = ({
  selected,
  option,
  onSelect,
}: DropdownInputItemProps) => {
  const handleSelect = useCallback((checked: boolean) =>{
    onSelect(checked, option.value);
  }, [onSelect, option.value]);

  if (!option.children) {
    return (
      <DropdownCheckboxItem
        autoHide={ false }
        checked={ selected.includes(option.value) }
        value={ option.value }
        onSelect={ handleSelect }
      >
        {option.label}
      </DropdownCheckboxItem>
    );
  }

  return (
    <Dropdown.Submenu
      autoHide={ false }
      title={ option.label }
    >
      { option.children.map((opt: TSubOption) => (
        <Item
          key={ opt.value }
          option={ opt }
          selected={ selected }
          onSelect={ onSelect }
        />
      )) }
    </Dropdown.Submenu>
  );
};

export default Item;
