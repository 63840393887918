module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="document-businesses">\n  <div class="background-white" id="document-detail-sidebar-businesses-accordion">\n    <div aria-controls="document-detail-sidebar-businesses-accordion-collapse" class="text-left padding-5 pointer document-detail-sidebar-section-header" data-target="#document-detail-sidebar-businesses-accordion-collapse" data-toggle="collapse" aria-expanded="true">\n      <i class="fa fa-caret-down m-r-5"></i>\n      <span class="font-bold">Businesses</span>\n    </div>\n  </div>\n  <div aria-labelledby="document-detail-sidebar-businesses-accordion" class="p-l-10 p-r-10 collapse in background-white document-detail-sidebar-section-body" id="document-detail-sidebar-businesses-accordion-collapse" aria-expanded="true" style="">\n    <div class="document-businesses-list padding-5"></div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}