import { useQuery } from 'react-query';

import { getAccountOwners, IGetAccountOwnersResponse } from '@src/requests/account_owners';

const ACCOUNT_OWNER_QUERY = 'account_owner';

const useGetAccountOwner = (rcPaymentAccountId?: number) => {
  return useQuery<IGetAccountOwnersResponse, Error>(
    [ACCOUNT_OWNER_QUERY, rcPaymentAccountId],
    () => getAccountOwners(rcPaymentAccountId),
  );
};

export {
  ACCOUNT_OWNER_QUERY,
  useGetAccountOwner,
};
