import React, { useCallback, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import Button from '@src/components/ui/buttons/button';
import Modal from '@src/components/ui/modal';

interface IConfirmDeleteBudgetProps extends IUseModalProps {
  budget: IReportServiceBudget,
}

const ConfirmDeleteBudget = ({
  budget,
  isOpen,
  onCancel,
  onDone,
}: IConfirmDeleteBudgetProps): JSX.Element => {
  const [isDisabled, setIsDisabled] = useState(true);
  const handleChange = useCallback((e) => {
    if (e.target.value === 'DELETE') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [setIsDisabled]);
  const handleCancel = useCallback(() => {
    setIsDisabled(true);
    onCancel();
  }, [onCancel]);

  return (
    <Modal
      className="modal-position-middle"
      show={ isOpen }
      title={ `Delete ${budget.type}` }
    >
      <Modal.Body>
        <p className="p-b-0 font-16 in-black">
          <b>
            {`${budget.name} - ${budget.year}`}
          </b>
          {budget.type === 'Budget' && (
            ` ${window.Docyt.Common.Constants.Messages.BUDGET_DELETE_CONFIRM_MSG}`
          )}
          {budget.type === 'Forecast' && (
            ` ${window.Docyt.Common.Constants.Messages.FORECAST_DELETE_CONFIRM_MSG}`
          )}
        </p>
        <input
          className="form-control"
          placeholder="Type DELETE to delete the budget"
          style={ { width: '300px' } }
          type="text"
          onChange={ handleChange }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 18 }
          title="Cancel"
          onClick={ handleCancel }
        />
        <Button
          bsSize="xlarge"
          bsStyle="danger"
          disabled={ isDisabled }
          fontSize={ 16 }
          fontVariant="semi-bold"
          title="Delete"
          onClick={ onDone }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useConfirmDeleteBudgetModal = makeUseModal(ConfirmDeleteBudget);

export {
  IConfirmDeleteBudgetProps,
  useConfirmDeleteBudgetModal,
  ConfirmDeleteBudget as default,
};
