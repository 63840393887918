import React from 'react';

import classNames from 'classnames';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ITableInputCellProps extends ITableCellProps {
}

const TableInputCell = ({
  children,
  className,
  ...props
}: ITableInputCellProps) => {
  const classes = classNames(styles['table-input-cell'], className);

  return (
    <Cell className={ classes } { ...props }>
      { children }
    </Cell>
  );
};

const MemoizedTableInputCell = React.memo(TableInputCell);

export {
  ITableInputCellProps,
  MemoizedTableInputCell as default,
};
