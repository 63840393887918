import React, { ReactNode } from 'react';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui_v2/dropdown';
import { ThreeDotsIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IActionsDropDownProps extends IUIStyleProps {
  children?: ReactNode,
  className?: string,
  toggleButton?: ReactNode,
  classNameMenus?: string,
}

const ActionsDropdown = ({
  children,
  toggleButton,
  classNameMenus,
  ...props
}: IActionsDropDownProps): JSX.Element => {
  return (
    <Dropdown.Provider { ...props }>
      { toggleButton || (
        <Dropdown.ToggleButton className={ styles['actions-dropdown-toggle-button'] }>
          <ThreeDotsIcon />
        </Dropdown.ToggleButton>
      )}
      <Dropdown.Menu className={ classNameMenus }>
        { children }
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export default ActionsDropdown;
