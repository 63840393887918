import React, { useCallback } from 'react';

import { useUnverifyBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { IStatementState } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { UnverifyStatementIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IDeleteRequestStatementActionProps {
  statement: IStatementState,
  businessId: TID,
  onUnverified?: () => void,
}

const UnverifyStatementAction = ({
  statement,
  businessId,
  onUnverified,
}: IDeleteRequestStatementActionProps) => {
  const unverifyStatement = useUnverifyBalanceSheetStatement();
  const { mutate } = unverifyStatement;

  const handleConfirm = useCallback(() => {
    if (!statement.id) return;

    mutate(
      { businessId, id: statement.id },
      { onSuccess: onUnverified },
    );
  }, [statement.id, mutate, businessId, onUnverified]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ unverifyStatement } />
      <confirmModal.Component
        text="Are you sure want to unverify this document?"
        title="Unverify Document"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <UnverifyStatementIcon fontSize={ 18 } /> }
        title="Unverify"
        onClick={ confirmModal.open }
      />
    </>
  );
};

export default UnverifyStatementAction;
