import React from 'react';

import { IRevenueDeposit } from '@src/types/revenue_systems';
import { currencyFormater3 } from '@src/utils/currency';

import Tooltip from '@src/components/ui_v2/tooltip';
import PaymentProcessorIcon from '@src/components/utils/payment_processor_icon';

import styles from './styles.module.scss';

interface RevenueDepositItemProps {
  depositedInformation: IRevenueDeposit;
}

const RevenueDepositItem = ({
  depositedInformation,
}: RevenueDepositItemProps) => {
  return (
    <div className={ styles['item-container'] }>
      <Tooltip.Hover content={ depositedInformation.name }>
        <PaymentProcessorIcon
          paymentProcessorCode={ depositedInformation.code }
          size="36px"
        />
      </Tooltip.Hover>
      <div className={ styles['item-description'] }>
        <span>
          {'Total MTD Revenue: '}
          <span className="font-bold">{ currencyFormater3(depositedInformation.total) }</span>
        </span>
        <span>
          {'Cash Over or Short: '}
          <span className={ styles['item-delta'] }>
            <span className="font-14">
              {depositedInformation.delta > 0.0 ? '(' : ''}
            </span>
            { currencyFormater3(Math.abs(depositedInformation.delta)) }
            <span className="font-14">
              {depositedInformation.delta > 0.0 ? ')' : ''}
            </span>
          </span>
        </span>
        <span>
          {'Undeposited Revenue: '}
          <span className="font-bold">{ currencyFormater3(depositedInformation.undeposited) }</span>
        </span>
      </div>
    </div>
  );
};

export default RevenueDepositItem;
