import React from 'react';

import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import { getTransactionType } from '../../../utils';

interface IRelatedTransactionsProps {
  relatedTransaction: ITransactionServiceDocument,
}

const RelatedTransactionListItem = ({
  relatedTransaction,
}: IRelatedTransactionsProps): JSX.Element => {
  return (
    <Table.Row>
      <Table.DateCell date={ relatedTransaction.transactionDate } />
      <Table.Cell>
        { relatedTransaction.description }
      </Table.Cell>
      <Table.Cell>
        { getTransactionType(relatedTransaction) }
      </Table.Cell>
      <Table.Cell>
        { relatedTransaction.paymentAccountName }
      </Table.Cell>
      <Table.AmountCell amount={ relatedTransaction.amount } />
    </Table.Row>
  );
};

export default RelatedTransactionListItem;
