import React from 'react';

import { TMongoID } from '@src/types/common';
import { IDashboard } from '@src/types/dashboards/dashboards';

import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import BriefView from './brief_view/brief_view';
import FullView from './full_view/full_view';

import styles from './styles.module.scss';

interface IWidgetContainerProps {
  currentDashboard: IDashboard,
  widgetId: TMongoID | null,
}

const WidgetContainer = ({
  currentDashboard,
  widgetId,
}: IWidgetContainerProps) => {
  return (
    <DetailsRegion className={ styles['widgets-container-details'] }>
      {
        widgetId
          ? (
            <FullView
              dashboardId={ currentDashboard.id }
              widgetId={ widgetId }
              widgets={ currentDashboard.widgets }
            />
          )
          : (
            <BriefView
              businessId={ currentDashboard.businessId }
              dashboardId={ currentDashboard.id }
            />
          )
      }
    </DetailsRegion>
  );
};

export default WidgetContainer;
