import React, { useCallback } from 'react';

import { useDeleteReportServiceBudget } from '@src/hooks/queries/report_service/report_service_budgets';
import { IReportServiceBudget } from '@src/types/report_service/report_service_budgets';

import { useConfirmDeleteBudgetModal } from '@src/components/business_reports/budgets/modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { TrashcanIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IBudgetItemDeleteActionProps {
  budget: IReportServiceBudget,
}

const BudgetDeleteAction = ({
  budget,
}: IBudgetItemDeleteActionProps): JSX.Element | null => {
  const destroy = useDeleteReportServiceBudget();

  const handleConfirm = useCallback(() => {
    destroy.mutate({ id: budget.id });
  }, [budget.id, destroy]);

  const alert = useConfirmDeleteBudgetModal({
    onDone: handleConfirm,
  });

  return (
    <>
      <MutationStatus mutation={ destroy } successMessage="Deleted successfully." />
      <alert.Component
        budget={ budget }
        { ...alert.props }
      />
      <ActionsDropdown.Action
        danger
        icon={ <TrashcanIcon fontSize={ 18 } /> }
        title="Delete"
        onSelect={ alert.open }
      />
    </>
  );
};

export default BudgetDeleteAction;
