import React, { useCallback, useMemo } from 'react';

import flatMapDeep from 'lodash/flatMapDeep';

import { useGetReconciliationPaymentAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { IPaymentAccount } from '@src/types/payment_accounts';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter/utils';
import { useFilterField } from '@src/components/ui_v2/filter';
import Wrapper from '@src/components/ui_v2/filter/field_with_tooltip_wrapper';
import { DropdownInput, formatSelectOption, TOption } from '@src/components/ui_v2/inputs';

interface IBankingAccountFieldProps extends
  Omit<React.ComponentPropsWithoutRef<typeof DropdownInput>, 'options' | 'value' | 'onChange'>,
  IFilterFieldUIProps
{
  businessId: TID,
  reconciliationPaymentAccountName?: string,
  paymentAccountName?: string,
}

type TPaymentAccountOption = TOption & {
  type: 'reconciliation_payment_account' | 'payment_account';
}

export type TSubPaymentAccountOption = TPaymentAccountOption & {
  children?: TSubPaymentAccountOption[],
}

const accountHasChildren = (account: IReconciliationPaymentAccount): boolean => {
  if (account.paymentAccounts.length === 0) return false;
  if (account.paymentAccounts.length > 1) return true;

  return account.defaultPaymentAccountId !== account.paymentAccounts[0].id;
};

const accountLabel = (account: IReconciliationPaymentAccount | IPaymentAccount): string => {
  return `${account.name}${account.isArchived ? ' (Archived)' : ''}`;
};

export const accountsToOptions = (
  accounts: IReconciliationPaymentAccount[],
): TSubPaymentAccountOption[] => {
  return accounts.map((account) => {
    if (!accountHasChildren(account)) {
      return {
        label: accountLabel(account),
        type:  'reconciliation_payment_account',
        value: String(account.id),
      };
    }

    return {
      label:    accountLabel(account),
      type:     'reconciliation_payment_account',
      value:    String(account.id),
      children: account.paymentAccounts.map((paymentAccount) => ({
        label: accountLabel(paymentAccount),
        type:  'payment_account',
        value: String(paymentAccount.id),
      })),
    };
  });
};

const formatTooltip = (option: TOption | null): JSX.Element | null => {
  if (!option) return null;

  return formatSelectOption(option);
};

const PaymentAccountFilterField = <TFilter extends TFilterData>({
  businessId,
  reconciliationPaymentAccountName = 'reconciliation_payment_account_id',
  paymentAccountName = 'payment_account_id',
  ...props
}: IBankingAccountFieldProps): JSX.Element => {
  const [rpaValue, updateRpa] = useFilterField<TFilter, string | null>(
    reconciliationPaymentAccountName,
  );
  const [paValue, updatePa] = useFilterField<TFilter, string | null>(
    paymentAccountName,
  );

  const reconciliationAccountsQuery = useGetReconciliationPaymentAccounts({
    businessId,
    noConsiderArchive: true,
  });

  const bankingAccountOptions = useMemo(() => {
    const reconciliationPaymentAccounts =
      reconciliationAccountsQuery.data?.reconciliationPaymentAccounts;

    if (!reconciliationPaymentAccounts) return [];

    return accountsToOptions(reconciliationPaymentAccounts);
  }, [reconciliationAccountsQuery.data?.reconciliationPaymentAccounts]);

  const [wrapperProps, inputProps] = useFilterFieldProps(props);

  const handleChange = useCallback((selectedOption: TOption | null) => {
    if (!selectedOption) {
      updateRpa(undefined);
      updatePa(undefined);
      return;
    }

    const accountOption = selectedOption as TPaymentAccountOption;

    if (accountOption.type === 'reconciliation_payment_account') {
      updateRpa(selectedOption.value);
      updatePa(undefined);
    } else {
      updateRpa(undefined);
      updatePa(accountOption.value);
    }
  }, [updateRpa, updatePa]);

  const currentOption = useMemo(() => {
    if (!rpaValue && !paValue) return null;

    return flatMapDeep(bankingAccountOptions, (opt) => {
      if ('children' in opt && opt.children) return [opt, ...opt.children];

      return opt;
    }).find((opt) => {
      if (rpaValue) {
        return opt.type === 'reconciliation_payment_account' && opt.value === rpaValue;
      }

      return opt.type === 'payment_account' && opt.value === paValue;
    });
  }, [rpaValue, paValue, bankingAccountOptions]);

  return (
    <Wrapper
      { ...wrapperProps }
      tooltip={ formatTooltip(currentOption || null) }
      tooltipLabel="Account: "
    >
      <DropdownInput
        placeholder="Select Account…"
        { ...inputProps }
        options={ bankingAccountOptions }
        value={ currentOption || null }
        onChange={ handleChange }
      />
    </Wrapper>
  );
};

export default React.memo(PaymentAccountFilterField);
