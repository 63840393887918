import React from 'react';

import {
  IUIStyleProps,
  uiStyleProps,
} from '@src/utils/ui_style_helpers';

interface IDropdownMenuProps extends IUIStyleProps, React.ComponentPropsWithoutRef<'ul'> {
  alignment?: 'right' | 'left',
  children: React.ReactNode,
}

const DropdownMenu = ({
  alignment,
  children,
  ...props
}: IDropdownMenuProps): JSX.Element => {
  const [classes, ulProps] = uiStyleProps(
    'dropdown-menu',
    props,
    alignment && `dropdown-menu-${alignment}`,
  );

  return (
    <ul
      className={ classes }
      role="menu"
      { ...ulProps }
    >
      { children }
    </ul>
  );
};

export default DropdownMenu;
