module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="display-flex align-items-center border-bottom-gray p-b-20">\n  <span class="font-26 font-bold">Bank Account</span>\n  ');
    
      if (this.dwollaCustomer.get('has_funding_source')) {
        __out.push('\n  <a class="m-l-auto m-r-0 font-16 font-semi-bold in-red-600 disconnect-js pointer">Disconnect Bank Account</a>\n  ');
      }
    
      __out.push('\n</div>\n');
    
      if (this.dwollaCustomer.get('has_funding_source')) {
        __out.push('\n<div class="m-t-20">\n  <p class="font-16">\n    ');
        if (this.dwollaCustomer.get('business_type')) {
          __out.push('\n    Your bank account is connected. This will be used to send payments through ACH.\n    ');
        } else {
          __out.push('\n    Your bank account is connected. This will be used to receive payments through ACH.\n    ');
        }
        __out.push('\n  </p>\n</div>\n<div class="m-t-10 font-14">\n  <p>\n    <span class="font-bold width-200px display-inline-flex">Bank: </span>\n    <span>');
        __out.push(__sanitize(this.fundingSource.get('name')));
        __out.push('</span>\n  </p>\n  <p>\n    <span class="font-bold width-200px display-inline-flex">Account #: </span>\n    <span>**** **** **** ');
        __out.push(__sanitize(this.fundingSource.get('last4')));
        __out.push('</span>\n  </p>\n  <p>\n    <span class="font-bold width-200px display-inline-flex">Routing #: </span>\n    <span>');
        __out.push(__sanitize(this.fundingSource.get('routing_number')));
        __out.push('</span>\n  </p>\n  <p>\n    <span class="font-bold width-200px display-inline-flex">Status: </span>\n    ');
        if (this.fundingSource.get('is_verified')) {
          __out.push('\n    <span class="in-green-600 font-bold">Verified</span>\n    ');
        } else {
          __out.push('\n    <span class="in-red-400">Not Verified</span>\n    ');
        }
        __out.push('\n  </p>\n  <p>\n    <span class="font-bold width-200px display-inline-flex">Connected since: </span>\n    <span>');
        __out.push(__sanitize(this.fundingSource.connectedSince()));
        __out.push('</span>\n  </p>\n</div>\n');
      } else {
        __out.push('\n<div class="m-t-20">\n  <p class="font-16">\n    Connect your personal bank account to start receiving payments through ACH.\n  </p>\n</div>\n<div class="m-t-10">\n  <button type=\'button\' class="font-14 btn btn-blue secure-add-bank-account-btn width-300px">Connect Bank Account</button>\n</div>\n<div class="font-10 m-t-8">\n  <a class="in-green-700" href="">\n    <span class="icon-lockedalt"></span>\n    <span class="inline-block nav-item-text">All connections are encrypted</span>\n  </a>\n</div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}