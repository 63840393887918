import React from 'react';

import Root from '@src/components/root';

import Notifications from './notifications';

const NotificationViewPage = () => {
  return (
    <Root>
      <Notifications />
    </Root>
  );
};

export default NotificationViewPage;
