/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React, { useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { TSection } from '@src/types/common';
import { IBusinessItem, IBusinessUpdatedItem, IDashboardItem, IFinancialInsightsEmailSortColumn, IGetFinancialInsightsResponse } from '@src/types/financial_insight';
import { ISorting } from '@src/types/sorting';

import CollectionTable from '@src/components/ui_v2/collection_table';
import Section from '@src/components/utils_v2/section';

import FinancialInsightsDropdown from './financial_insights_dropdown';

import styles from './styles.module.scss';

interface IFinancialInsightsTableProps {
  updatedData : IBusinessUpdatedItem[];
  onChange: (row : IBusinessItem, option : IDashboardItem) => void;
  onClear: () => void;
  isPopup: boolean;
  query: UseInfiniteQueryResult<IGetFinancialInsightsResponse, Error>;
  records: IBusinessItem[];
  section: TSection;
  sorting: ISorting<IFinancialInsightsEmailSortColumn>;
}

const FinancialInsightsTable = ({
  updatedData,
  onChange,
  onClear,
  isPopup,
  query,
  records,
  sorting,
  section,
} : IFinancialInsightsTableProps) : JSX.Element => {
  const getDefaultActiveDashboard = useCallback((values : IBusinessItem) => {
    if (values && values.default) {
      return values.default.dashboardName;
    }
    return null;
  }, []);

  return (
    <Section.Provider section={ section }>
      <CollectionTable<IBusinessItem, IFinancialInsightsEmailSortColumn>
        hideSectionBorder
        isRegionScroll
        className={ isPopup ? styles['financial-insights-table-popup'] : styles['financial-insights-table'] }
        height={ isPopup ? '320px' : '185px' }
        idField="businessId"
        query={ query }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.TextColumn<IBusinessItem>
          name="businessName"
          title=""
          value={ (it: IBusinessItem) => <span className={ styles['text-business'] }>{it.businessName}</span> }
          width="5%"
        />

        {isPopup
          ? (
            <CollectionTable.TextColumn<IBusinessItem>
              hideValueTooltip
              name="name"
              title=""
              value={ (it: IBusinessItem) => {
                return (
                  <FinancialInsightsDropdown
                    className={ it.dashboards.length === 1 ? styles['dashboard-dropdown-disabled'] : styles['dashboard-dropdown'] }
                    row={ it }
                    updatedData={ updatedData }
                    onChange={ onChange }
                    onClear={ onClear }
                  />
                );
              } }
            />
          )
          : (
            <CollectionTable.TextColumn<IBusinessItem>
              name="name"
              title=""
              value={ (it: IBusinessItem) => <span className={ styles['text-dashboard'] }>{getDefaultActiveDashboard(it)}</span> }
            />
          )}
      </CollectionTable>
    </Section.Provider>
  );
};
export default React.memo(FinancialInsightsTable);
