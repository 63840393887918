import React from 'react';

import {
  IReconciliationCenterExceptionMessages,
  TReconciliationCenterExceptionMessagesSortColumn,
} from '@src/types/reconciliation_centers';

import CollectionTable from '@src/components/ui_v2/collection_table';

import { IReconciliationCenterExceptionMessagesCollection } from './hooks';

interface IExceptionMessagesTableProps {
  collection: IReconciliationCenterExceptionMessagesCollection,
}

const ExceptionMessagesTable = ({ collection }: IExceptionMessagesTableProps) => {
  return (
    <CollectionTable<IReconciliationCenterExceptionMessages, TReconciliationCenterExceptionMessagesSortColumn>
      noDataMessage="No message found"
      records={ collection.records }
      sorting={ collection.sorting }
    >
      <CollectionTable.TextColumn<
        IReconciliationCenterExceptionMessages,
        TReconciliationCenterExceptionMessagesSortColumn
      >
        name="name"
        title="Chart of Account"
        value={ document => document.name }
        width="35%"
      />
      <CollectionTable.TextColumn<
        IReconciliationCenterExceptionMessages,
        TReconciliationCenterExceptionMessagesSortColumn
      >
        name="message"
        title="Import Error"
        value={ document => document.message }
        width="65%"
      />
    </CollectionTable>
  );
};

export default React.memo(ExceptionMessagesTable);
