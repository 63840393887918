module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="date-td ');
    
      __out.push(__sanitize(this.enabled ? 'document-clickable pointer' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      if (this.group_similar_id) {
        __out.push('\n    <span class="icon-multiple-transactions multiple-transactions-tooltip-js" style="padding: 0 10px 0 20px" data-placement="bottom" title="This particular transaction is one of several similar ones that have been flagged for review. Reviewing this transaction will also mark similar ones as reviewed"></span>\n  ');
      } else {
        __out.push('\n    <span style="display: inline-block; width: 44px"></span>\n  ');
      }
    
      __out.push('\n  <span class="font-14 inline-block">\n    ');
    
      __out.push(__sanitize(moment(this.transaction_date).format(Docyt.Common.Constants['US_DATE_FORMAT'])));
    
      __out.push('\n  </span>\n</td>\n<td class="');
    
      __out.push(__sanitize(this.enabled ? 'document-clickable pointer' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.description));
    
      __out.push('\n</td>\n<td class="');
    
      __out.push(__sanitize(this.enabled ? 'document-clickable pointer' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.vendor ? this.vendor.name : '-'));
    
      __out.push('\n</td>\n<td class="');
    
      __out.push(__sanitize(this.enabled ? 'document-clickable pointer' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      __out.push(__sanitize(this.payment_account_name));
    
      __out.push('\n</td>\n<td class="');
    
      __out.push(__sanitize(this.enabled ? 'document-clickable pointer' : ''));
    
      __out.push(' ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push(' relative text-right">\n  <span class="font-16 font-semi-bold">');
    
      __out.push(__sanitize(parseFloat(this.amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      })));
    
      __out.push('</span>\n</td>\n<td\n  class="\n    ');
    
      __out.push(__sanitize((this.message_count > 0) ? 'document-chat-clickable pointer' : ''));
    
      __out.push('\n    ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('\n    tcm-tip-container"\n>\n  <div\n    class="tcm-tooltip-js"\n    title="');
    
      __out.push(__sanitize(this.transaction_context_message || 'Leave notes over chat'));
    
      __out.push('"\n    data-placement="bottom"\n  >\n    <input\n      type="text"\n      class="form-control transaction-context-input"\n      name="transaction-context-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('"\n      value="');
    
      __out.push(__sanitize(this.transaction_context_message));
    
      __out.push('"\n      placeholder="Type here..."\n    />\n  </div>\n</td>\n<td style="width:50px; text-align:center" class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      if (this.flagged_state === 'open') {
        __out.push('\n    <span class="icon-question-mark-orange font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
      } else if (this.flagged_state === 'resolved') {
        __out.push('\n    <span class="icon-success font-18"><span class="path1"></span><span class="path2"></span></span>\n  ');
      }
    
      __out.push('\n</td>\n<td style="width:50px; text-align:center" class="document-chat-clickable pointer ');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  ');
    
      if (this.message_count > 0) {
        __out.push('\n    <span><span class="icon-Chat-filled font-26"></span><span class="message-cnt-badge">');
        __out.push(__sanitize(this.message_count > 10 ? '9+' : this.message_count));
        __out.push('</span></span>\n  ');
      } else {
        __out.push('\n    <span class="icon-Chat-blank font-26"></span>\n  ');
      }
    
      __out.push('\n</td>\n<td style="width:50px" class="');
    
      __out.push(__sanitize(this.is_checked ? 'document_checked' : ''));
    
      __out.push('">\n  <div class="relative">\n    <a class="btn dropdown-toggle" type="button" id="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n      <span class="fa fa-ellipsis-h font-15 in-grey-800"></span>\n    </a>\n    <ul class="dropdown-menu dropdown-menu-right font-24" role="menu" aria-labelledby="transaction-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n      <li><a tabindex="-1" class="pointer send-message-btn"><span class="icon-chat-round m-r-18"></span> Send a Message</a></li>\n      ');
    
      if (this.flagged_state === 'open' && this.service.get('user_role').role === Docyt.Common.Constants.FLAGGED_TRANSACTIONS_ADVISOR_ROLE['REVIEWER']) {
        __out.push('\n        <li><a tabindex="-1" class="pointer review-transaction"><span class="icon-check_circle_large font-16 m-r-21 vertical-align-middle-important"></span>Mark as Reviewed</a></li>\n      ');
      }
    
      __out.push('\n      <li><a tabindex="-1" class="pointer view-transaction-detail"><i class="fa fa-file-text-o m-l-3 m-r-18"></i> <span>View Transaction Detail</span></a></li>\n      ');
    
      if (this.flagged_state === 'resolved' && this.service.get('user_role').role === Docyt.Common.Constants.FLAGGED_TRANSACTIONS_ADVISOR_ROLE['REVIEWER']) {
        __out.push('\n        <li><a tabindex="-1" class="pointer flag-transaction"><span class="icon-re-open font-16 m-l-2 m-r-19 vertical-align-middle-important"></span>Re-Open Issue</a></li>\n      ');
      }
    
      __out.push('\n    </ul>\n  </div>\n</td>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}