module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog add-payment-account-modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header modal-modern">\n        <h1 class="modal-title m-l-10 font-semi-bold">');
    
      __out.push(__sanitize(this.label));
    
      __out.push('</h1>\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n      </div>\n      <div class=\'modal-body text-center\'>\n        <p class="font-18 m-t-40 m-b-40">Note: Do not use personal Credit/Debit cards for business expenses.</p>\n        <p class="m-b-16"><a href="');
    
      __out.push(__sanitize(this.addCreditCardUrl));
    
      __out.push('" class="modal-link-btn cancel p-t-15 p-b-15">Add Credit Card</a></p>\n        <p class="m-b-16"><a href="');
    
      __out.push(__sanitize(this.addDebitCardUrl));
    
      __out.push('" class="modal-link-btn cancel p-t-15 p-b-15">Add Debit Card</a></p>\n        <p class="m-b-40"><a href="');
    
      __out.push(__sanitize(this.addBankUrl));
    
      __out.push('" class="modal-link-btn cancel p-t-15 p-b-15">Add Bank</a></p>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}