import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TID } from '@src/types/common';

import ForwardToBankStatementModal from '@src/components/common/forward_document/forward_to_bank_statements_modal/forward_to_bank_statements_modal';

interface IForwardToBankStatementProps {
  documentIds: TID[],
  show: boolean,
  onCancel: () => void,
  onContinue: () => void,
}
const ForwardToBankStatement = ({
  documentIds,
  show,
  onCancel,
  onContinue,
}: IForwardToBankStatementProps) => {
  const business = useBusinessContext();
  return (
    <ForwardToBankStatementModal
      business={ business }
      documentIds={ documentIds }
      show={ show }
      onCancel={ onCancel }
      onContinue={ onContinue }
    />
  );
};
export default ForwardToBankStatement;
