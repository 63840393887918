module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-middle\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="font-28 font-bold text-center m-t-0 m-b-0">Verify Identity</h1>\n        </div>\n        <div class=\'modal-body\'>\n          <div class="">\n            <h2 class="font-18 m-b-0 font-semi-bold">Enter Secret Code</h2>\n            <h3 class="font-14 m-t-5">Sent to your email ');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('email')));
    
      __out.push('</h3>\n            <input class="form-control bg-grey-300 security-code-input" placeholder="Security code">\n            <a class="font-14 font-semi-bold resend-code-link pointer">Resend code</a>\n          </div>\n          <div class="m-t-20">\n            <div class="note">\n              TIP: To keep your account secure, sign out of your account after you are done with payments.\n            </div>\n          </div>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button generate-payment-js" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Generate Payment</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}