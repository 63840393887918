import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useLedgerAddress } from '@src/hooks/queries/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';
import { camelizeKeys } from '@src/utils/transform_keys';

import Modal from '@src/components/ui/modal';
import { ErrorNotification, SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Button from '@src/components/ui_v2/buttons/button';

import styles from './styles.module.scss';

interface ILedgerAddressProps extends IUseModalProps {
  text: JSX.Element | string,
  title: JSX.Element | string,
  vendor: IBusinessVendor,
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  address: IAddress;
}

const LedgerAddressModal = ({
  isOpen,
  text,
  title,
  onCancel,
  onDone,
  vendor,
  setVendorData,
  address,
}: ILedgerAddressProps): JSX.Element => {
  const queryClient = useQueryClient();
  const addLedgerAddress = useLedgerAddress();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const { mutate: addLedger } = addLedgerAddress;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const handleSubmit = useCallback(
    () => {
      setErrorMessage(undefined);
      addLedger(
        {
          vendorId: vendor?.id,
          data:     {
            businessId: vendor?.businessId,
            vendor:     {
              ledgerAddressId: address?.id,
              syncWithQbo:     true,
            },
          },
        },
        {
          onSuccess: (response : any) => {
            queryClient.invalidateQueries(QueryKey.businessVendors);

            const vendorData = (response as unknown as { vendor: IBusinessVendor }).vendor;
            setVendorData(camelizeKeys(vendorData) as IBusinessVendor);
            setSuccessMessage('Address has been successfully set as ledger address.');
            onDone();
          },
          onError: handleError,
        },
      );
    },
    [addLedger, vendor, setVendorData, onDone, handleError, queryClient, address],
  );

  return (
    <>
      {(addLedgerAddress.isLoading) && <Spinner />}
      {errorMessage && <ErrorNotification message={ errorMessage } title="Something went wrong" onHidden={ () => setErrorMessage(undefined) } />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      )}
      <Modal className={ styles['modal-authorization'] } show={ isOpen } title={ title }>
        <Modal.Body>
          <p className="p-b-0 text-center font-18">{text}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            title="Cancel"
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            className="btn-primary"
            variant="primary"
            onClick={ handleSubmit }
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useLedgerAddressModal = makeUseModal(LedgerAddressModal);
export {
  ILedgerAddressProps,
  useLedgerAddressModal,
  LedgerAddressModal as default,
};
