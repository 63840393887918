import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const VendorDownloadFormIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="download-form"
    />
  );
};

export default VendorDownloadFormIcon;
