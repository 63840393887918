module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="expense-clickable pointer">\n  <div class="display-flex align-items-center">\n  ');
    
      if (this.avatar_url) {
        __out.push('\n  <img src=');
        __out.push(__sanitize(this.avatar_url));
        __out.push(' class=\'small-avatar-wrapper\'>\n  ');
      } else {
        __out.push('\n  <i class="icon icon-smile in-blue-400 font-24 line-height-0"></i>\n  ');
      }
    
      __out.push('\n  <span class="m-l-14">');
    
      __out.push(__sanitize(this.full_name));
    
      __out.push('</span>\n  </div>\n</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.submitted_count));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.reimbursed_count));
    
      __out.push('</td>\n<td class="expense-clickable relative pointer">');
    
      __out.push(__sanitize(this.submitAmount));
    
      __out.push('</td>\n<td class="amount-column relative text-right font-bold">');
    
      __out.push(__sanitize(this.reimburseAmount));
    
      __out.push('</td>\n<td>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}