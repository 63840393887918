module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, len, ref;
    
      __out.push('<div class="modal fade">\n    <div class="modal-dialog team-multi-select-modal select-multi-item-area">\n      <div class="modal-content">\n        <div class="modal-header">\n          <button class=\'close modal-cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class="modal-title">\n            ');
    
      __out.push(__sanitize(this.title));
    
      __out.push('\n          </h4>\n        </div>\n        <div class="modal-body">\n          ');
    
      ref = this.businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n          <div class="business-multi-select border-bottom-gray">\n            ');
        if (this.isClient) {
          __out.push('\n            <div class="checkbox m-t-0 m-b-0">\n              <input type="checkbox" class="styled form-control input-element" data-type="checkbox" data-business="');
          __out.push(__sanitize(business.id));
          __out.push('">\n              <label class="font-16 relative width-100-percent">\n                <div class="display-table-row">\n                  <div class="display-table-cell">\n                    <div class="middle-avatar-wrapper border-gray">\n                      ');
          if (business.image_url) {
            __out.push('\n                        <img src="');
            __out.push(__sanitize(business.image_url));
            __out.push('">\n                      ');
          } else {
            __out.push('\n                      <span class=\'icon-business-default-avatar font-48\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                      ');
          }
          __out.push('\n                    </div>\n                  </div>\n                  <div class="display-table-cell p-l-10 vertical-align-middle">\n                    <h5 class="font-16 m-t-0 m-b-0 font-bold business-name-field text-left">\n                      ');
          __out.push(__sanitize(business.legal_name));
          __out.push('\n                    </h5>\n                  </div>\n                </div>\n              </label>\n            </div>\n            ');
        } else {
          __out.push('\n            <div class="team-setting-header font-16 width-100-percent">\n              <div class="display-table-cell">\n                <div class="middle-avatar-wrapper border-gray">\n                  ');
          if (business.image_url) {
            __out.push('\n                    <img src="');
            __out.push(__sanitize(business.image_url));
            __out.push('">\n                  ');
          } else {
            __out.push('\n                  <span class=\'icon-business-default-avatar font-48\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n                  ');
          }
          __out.push('\n                </div>\n              </div>\n              <div class="display-table-cell p-l-10 m-r-auto vertical-align-middle">\n                <h5 class="font-16 m-t-0 m-b-0 font-bold business-name-field text-left">\n                  ');
          __out.push(__sanitize(business.legal_name));
          __out.push('\n                </h5>\n              </div>\n              <div class="display-table-cell width-140px p-l-10 m-r-0 vertical-align-middle">\n                <div class=\'category-size-wrapper width-140px selectize-wrapper\'>\n                  <select class=\'file-category sel-type-js\' data-business="');
          __out.push(__sanitize(business.id));
          __out.push('">\n                    <option value="None"><div class="item">None</div></option>\n                    <option value="Employee"><div class="item">Employee</div></option>\n                    <option value="Admin"><div class="item">Business Admin</div></option>\n                  </select>\n                </div>\n              </div>\n            </div>\n            ');
        }
        __out.push('\n          </div>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn\'>Cancel</a>\n          <button type="submit" class="btn btn-blue btn-submit ladda-button" data-color="blue" data-style="expand-right" disabled><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}