import { getDocument } from '@src/requests/documents';
import {
  IBankAccountReconciliation,
  TBankAccountReconciliationStatus,
  TBankAccountReconciliationItemStatus,
  IMatchedRule,
} from '@src/types/bank_account_reconciliations';
import { TID } from '@src/types/common';
import { IPaymentProcessor } from '@src/types/payment_processors';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut, apiPost, authorizedApiRequest } from './helpers';

interface IGetBankAccountReconciliationResponse {
  baReconciliation: IBankAccountReconciliation,
}

interface IUpdateBankAccountReconciliationResponse {
  baReconciliation: IBankAccountReconciliation,
}

interface IBankAccountReconciliationItemParam {
  itemType: string,
  status: TBankAccountReconciliationItemStatus,
  note?: string,
}

interface IBankAccountReconciliationUpdateParams {
  id: TID,
  status?: TBankAccountReconciliationStatus,
  bankAccountReconciliationItems?: IBankAccountReconciliationItemParam[],
}

interface IMatchedRuleResponse {
  matchedRules: IMatchedRule[];
}

interface IGetPaymentProcessorsParams {
  businessId: TID
}

interface IGetPaymentProcessorsResponse {
  paymentProcessors: IPaymentProcessor[]
}

interface ITransactionAIContextParams {
  businessId: number,
  transactionId: number,
  transactionDesc: string
}

interface ITransactionAIContextResponse {
  cached?: boolean,
  context: string,
  description: string
}

interface ITransactionClientContextParams {
  businessId: number,
  transactionId: number,
  chatMessages: IChatMessage[]
}

interface IChatMessage {
  id: number,
  text: string,
  senderName: string
}

interface ITransactionClientContextResponse {
  original: string,
  summarized: string
}

interface ITransactionMessagesResponse {
  chatMessages: IChatMessage[]
}

// type IGetBankAccountReconciliationsResponse = IGetBankAccountReconciliationResponse[];

const getBankAccountReconciliation =
(id: TID, shouldUpdate?: boolean): Promise<IGetBankAccountReconciliationResponse> => {
  const url = `/api/v1/bank_account_reconciliations/${id}`;

  return apiGet(url, { update: shouldUpdate }).then((data) => {
    return {
      baReconciliation: camelizeKeys(data.bank_account_reconciliation),
    } as IGetBankAccountReconciliationResponse;
  });
};

const calculateBankAccountReconciliation = (id: TID) => {
  const url = `/api/v1/bank_account_reconciliations/${id}/calculate`;

  return apiPost(url, {});
};

const updateBankAccountReconciliation = (
  { id, ...params }: IBankAccountReconciliationUpdateParams,
): Promise<IUpdateBankAccountReconciliationResponse> => {
  const url = `/api/v1/bank_account_reconciliations/${id}`;

  return apiPut(url, underscoreKeys(params)).then((data) => {
    return {
      baReconciliation: camelizeKeys(data.bank_account_reconciliation),
    } as IGetBankAccountReconciliationResponse;
  });
};

const getMatchedRlues =
    (id: TID): Promise<IMatchedRuleResponse> => {
      const url = `/api/v1/transaction_service_documents/${id}/matched_rules`;
      return apiGet(url).then((data) => {
        return {
          matchedRules: camelizeKeys(data.matched_rules),
        } as IMatchedRuleResponse;
      });
    };

const getPaymentProcessors = (
  params: IGetPaymentProcessorsParams,
): Promise<IGetPaymentProcessorsResponse> => {
  return apiGet(
    '/api/v1/payment_processors',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetPaymentProcessorsResponse);
};

const getTransactionAIContext =
  (params: ITransactionAIContextParams): Promise<ITransactionAIContextResponse> => {
    const url = '/docytbot/api/transaction/get_desc_context';

    return apiPost(url, underscoreKeys(params));
  };

const getTransactionMessages = async (documentId: TID):
    Promise<ITransactionMessagesResponse> => {
  const url = '/api/v1/messages';
  const { chatId } = await getDocument(documentId);
  const params = { chatId };
  return apiGet(url, underscoreKeys(params)).then((data) => {
    return {
      chatMessages: camelizeKeys(data.chat_messages),
    } as ITransactionMessagesResponse;
  });
};

const getTransactionClientContext =
  async ({ businessId, transactionId, chatMessages }: ITransactionClientContextParams):
  Promise<ITransactionClientContextResponse> => {
    const url = '/docytbot/api/transaction/summarize_conversations';

    if (chatMessages.length === 0) {
      return Promise.resolve({
        original:   '',
        summarized: '',
      });
    }
    const parseText = ({ senderName, text }: IChatMessage) => {
      let tmp = text;
      // Replace role tags with the role name
      tmp = tmp.replace(/\$\[role:(\w+)\]\$/g, (match, roleName) => `@${roleName}`);

      // Replace name tags with the user name
      tmp = tmp.replace(/\$\[uid:\d+,name:([^]+?)\]\$/g, (match, userName) => `@${userName}`);

      tmp = `${senderName}: ${tmp}`;
      return tmp;
    };
    const conversations = chatMessages.map((i) => parseText(i)).join('; ');

    const params = {
      businessId,
      transactionId,
      conversations,
    };
    return apiPost(url, underscoreKeys(params)).then((data) => data);
  };

const getBankAccountReconciliationPDF = async (id: TID): Promise<Blob> => {
  const url = `/api/v1/bank_account_reconciliations/${id}/download_pdf?current_time=${new Date()}`;
  return authorizedApiRequest({
    url,
    method:       'GET',
    responseType: 'blob',

  }).then((blob) => {
    return blob;
  });
};

interface IMatchDocumentsParams {
  sourceDocumentIds: number[];
  targetDocumentIds: number[];
  businessId: TID;
}

interface IMatchDocumentsResponse {
  message?: string;
  errors?: string[];
}

const matchBankAccountReconciliationDocuments = (params: IMatchDocumentsParams): Promise<IMatchDocumentsResponse> => {
  const url = '/api/v1/bank_account_reconciliation_documents/match';

  return apiPost(url, underscoreKeys(params));
};

const unmatchBankAccountReconciliationDocuments = (params: IMatchDocumentsParams): Promise<IMatchDocumentsResponse> => {
  const url = '/api/v1/bank_account_reconciliation_documents/unmatch';

  return apiPost(url, underscoreKeys(params));
};

const unReconcile = (id: TID) => {
  const url = `/api/v1/bank_account_reconciliations/${id}/un_reconcile`;

  return apiPut(url, {});
};

export {
  getBankAccountReconciliation,
  calculateBankAccountReconciliation,
  updateBankAccountReconciliation,
  getTransactionAIContext,
  getTransactionClientContext,
  getMatchedRlues,
  getPaymentProcessors,
  getTransactionMessages,
  IChatMessage,
  IGetBankAccountReconciliationResponse,
  IBankAccountReconciliationUpdateParams,
  IUpdateBankAccountReconciliationResponse,
  ITransactionAIContextResponse,
  ITransactionAIContextParams,
  ITransactionClientContextResponse,
  ITransactionClientContextParams,
  IMatchedRuleResponse,
  IGetPaymentProcessorsParams,
  IGetPaymentProcessorsResponse,
  ITransactionMessagesResponse,
  getBankAccountReconciliationPDF,
  matchBankAccountReconciliationDocuments,
  IMatchDocumentsParams,
  IMatchDocumentsResponse,
  unmatchBankAccountReconciliationDocuments,
  unReconcile,
};
