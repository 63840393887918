import React, { ReactNode } from 'react';

import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Dropdown from '@src/components/ui/dropdown';

interface IActionsDropdownSubmenuProps extends IUIStyleProps {
  /** Submenu icon. */
  icon?: JSX.Element,
  /** Submenu name (title). */
  name: string,
  /**
   * Actions that will be shown in this submenu.
   * Should be `ActionsDropdown.Action` or `ActionsDropdown.DangerAction`.
   */
  children?: ReactNode,
}

/**
 * Actions dropdown submenu that contains action items.
 * @visibleName ActionsDropdown.Submenu
 */
const ActionsDropdownSubmenu = ({
  icon,
  name,
  children,
}: IActionsDropdownSubmenuProps): JSX.Element => {
  return (
    <Dropdown.Submenu
      title={ (
        <div className="display-flex-important align-items-center">
          <div className="display-flex-important align-items-center width-20px m-r-18">
            { icon }
          </div>
          { name }
        </div>
      ) }
    >
      <Dropdown.Menu>
        { children }
      </Dropdown.Menu>
    </Dropdown.Submenu>
  );
};

export default ActionsDropdownSubmenu;
