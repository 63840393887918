import React, { useCallback } from 'react';

import { categoryReportsPath, viewReportPath } from '@src/routes';
import { TID } from '@src/types/common';

import { DocytLogoIcon, NotAChargebackIcon } from '@src/components/utils/icomoon';

interface IBusinessReportsExportHeaderProps {
  businessId: TID
  category:   string,
  slug?:      string
}

const BusinessReportsExportHeader = ({
  businessId,
  category,
  slug
}: IBusinessReportsExportHeaderProps) => {
  const handleClick = useCallback(() => {
    if (slug !== 'undefined') {
      Backbone.history.navigate(
        viewReportPath(businessId, slug as string),
        { trigger: true, replace: true },
      );
    } else {
      Backbone.history.navigate(
        categoryReportsPath(businessId, category),
        { trigger: true, replace: true },
      );
    }
  }, [businessId, category, slug]);

  return (
    <div className="reports-export-header">
      <DocytLogoIcon fontSize={ 20 } />
      <h2 className="header-title">Export Reports</h2>
      <NotAChargebackIcon pointer fontSize={ 24 } onClick={ handleClick } />
    </div>
  );
}

export default BusinessReportsExportHeader;